import React from "react";
import { TQuoteRateCreatedForm } from "../../../../../domain/codecs/form/QuoteRateForm";
import { onChangeForm } from "../../../../../shared/src/codecs/types/form";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { SpacingColumn } from "../../BuildingBlocks/SpacingColumn";
import CRMInputLabelAndErrorWrapComponent from "../../CRMInputLabelAndErrorWrapComponent/CRMInputLabelAndErrorWrapComponent";
import { CRMRadioListComponent } from "../../CRMInputs/CRMRadioListComponent/CRMRadioListComponent";
import { CRMTitleSubSection } from "../../CRMTitleSubSection/CRMTitleSubSection";

export const CRMQuoteRatesSingleViewEditModifiers = (props: React.PropsWithChildren<{
    form: TQuoteRateCreatedForm,
    onChange: (form: TQuoteRateCreatedForm) => void,
}>): JSX.Element => {
    const onChange = onChangeForm(props.form, props.onChange);

    return <SpacingColumn spacing={CRMSpacing.MEDIUM}>
        <CRMTitleSubSection>
            Modifiers
        </CRMTitleSubSection>

        {/* HAS VAT? */}
        <CRMInputLabelAndErrorWrapComponent label="Should VAT be added on top?">
            <CRMRadioListComponent
                value={props.form.edited.has_vat}
                options={[
                    {
                        value: false,
                        text: "No",
                    },
                    {
                        value: true,
                        text: "Yes",
                    },
                ]}
                onChange={onChange("has_vat")}
            />
        </CRMInputLabelAndErrorWrapComponent>

        {/* IS MULTIPLIED PER CLIENT? */}
        <CRMInputLabelAndErrorWrapComponent label="Should the fee be multiplied per client?">
            <CRMRadioListComponent
                value={props.form.edited.is_multiplied_per_client}
                options={[
                    {
                        value: false,
                        text: "No",
                    },
                    {
                        value: true,
                        text: "Yes",
                    },
                ]}
                onChange={onChange("is_multiplied_per_client")}
            />
        </CRMInputLabelAndErrorWrapComponent>
    </SpacingColumn>
};
