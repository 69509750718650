import React, { useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { useDropdown, IOption } from "../../../../hooks/UseDropdown";
import { SpacingRow } from "../../../BuildingBlocks/SpacingRow";
import { FrontOutsidePopupBlock } from "../../Complex/FrontOutsidePopupBlock/FrontOutsidePopupBlock";
import { FrontIcon } from "../FrontIcon/FrontIcon";

export interface IFrontDropdownProps <A extends string, B extends A>{
    inputRef?: React.RefObject<HTMLDivElement>;
    value: B;
    options: Array<IOption<A>>;
    onChange: (value: A) => void; 
    onBlur?: () => void;
};

export const FrontDropdown = <A extends string, B extends A>(props: React.PropsWithChildren<IFrontDropdownProps<A, B>>): JSX.Element => {

    const {
        ref,
        isOpen,
        toggleOpen,
        getFaceText,
    } = useDropdown<A, IOption<A>>(props.value, props.options, props.inputRef);

    const onChange = (option: IOption<A>) => {
        props.onChange(option.value);
        toggleOpen();
    }

    const isMobile = useMediaQuery({
        query: "(max-width: 599px)",
    });

    return (
        <div 
            className={`front-dropdown`}
            ref={ref}
            tabIndex={0} 
            onBlur={props.onBlur}
        >
            <div
                className="front-dropdown__face"
                onClick={toggleOpen}
            >
                <SpacingRow justifyContent="space-between">
                    <div className="front-dropdown__text">
                        {getFaceText()}
                    </div>

                    <FrontIcon
                        colour="neutral-ink"
                        iconName={isOpen ? "chevron-up" : "chevron-down"}
                        size="small"
                    />
                </SpacingRow>
            </div>

            {/* IF DESKTOP: OPTIONS */}
            <div className="front-dropdown__desktop-options-wrap">
                {isOpen &&
                    <Options
                        options={props.options}
                        onChange={onChange}
                    />
                }
            </div>
            
            {/* IF MOBILE: OPTIONS */}
            {isMobile &&
                <div className="front-dropdown__mobile-options-wrap">
                    <FrontOutsidePopupBlock 
                        isOpen={isOpen}
                        onClose={toggleOpen}
                    >
                        <Options
                            options={props.options}
                            onChange={onChange}
                        />
                    </FrontOutsidePopupBlock>
                </div>
            }
        </div>
    );
};


type TOptionsProps<A extends string> = {
    options: Array<IOption<A>>;
    onChange: (value: IOption<A>) => void;
}

export const Options = <A extends string>(props: TOptionsProps<A>): JSX.Element => {
    return (
        <div className="front-dropdown__options">
            {props.options.map((option, index) => (
                <div
                    key={index}
                    className={`front-dropdown__option`}
                    onClick={() => props.onChange(option)}
                >
                    {option.label}
                </div>
            ))}
        </div>
    )
}