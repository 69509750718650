import React from "react";
import * as User10 from "../../../../domain/models/User10";
import { CRMUniversalBulletPoints } from "../../components/CRMUniversalBulletPoints/CRMUniversalBulletPoints";
import { CRMLink } from "../../components/CRMLink/CRMLink";
import { CRMParagraph } from "../../components/Simple/CRMParagraph/CRMParagraph";
import { WeightMedium } from "../../components/WeightMedium/WeightMedium";
import { TContainerStateProps } from "../../state/TContainerStateProps";

class CRMListingPerformancePageInterestedPartiesWithoutOfferContainer extends React.Component<React.PropsWithChildren<TContainerStateProps>> {

    public render(): JSX.Element {
        return (
            <>
                {/* IF: NO INTERESTED PARTIES */}
                {this.props.state.activeData.crm.listingPerformance.interestedEnquiriesWithoutOffer.forms.length === 0 &&
                    <CRMParagraph>
                        <WeightMedium>
                            No interested parties, yet.
                        </WeightMedium>
                    </CRMParagraph>
                }

                {/* IF: HAS INTERESTED PARTIES */}
                {this.props.state.activeData.crm.listingPerformance.interestedEnquiriesWithoutOffer.forms.length > 0 &&
                    <CRMUniversalBulletPoints
                        points={this.props.state.activeData.crm.listingPerformance.interestedEnquiriesWithoutOffer.forms.map((form, index) => (
                            <CRMLink
                                key={index}
                                href={`/crm/enquiries/${form.view.id}`}
                                linkStyle="normal"
                            >
                                {User10.usersToNamesString(form.view.party.users, () => "Names Not Given")}
                            </CRMLink>
                        ))}
                    />
                }
            </>
        );
    }
}

export default CRMListingPerformancePageInterestedPartiesWithoutOfferContainer;
