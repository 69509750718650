import React from "react";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { Padding } from "../../BuildingBlocks/Padding";
import { SpacingRow } from "../../BuildingBlocks/SpacingRow";
import { CRMButtonIcon } from "../../CRMButtonIcon/CRMButtonIcon";
import { CRMInfoBoxSimpleColourComponent } from "../../CRMInfoBoxSimpleColourComponent/CRMInfoBoxSimpleColourComponent";

export const CRMInfoBoxSimpleColourWithEdit = (props: React.PropsWithChildren<{
    fullWidth: boolean,
    displayError: boolean,
    onEdit: () => void
}>): JSX.Element =>
    <CRMInfoBoxSimpleColourComponent
        fullWidth={props.fullWidth}
        displayError={props.displayError}
    >
        <Padding spacing={CRMSpacing.MEDIUM}>
            <SpacingRow
                spacing={CRMSpacing.MEDIUM}
                justifyContent="space-between"
                alignItems="center"
            >
                {/* LABEL */}
                <div>{props.children}</div>

                {/* EDIT */}
                <CRMButtonIcon
                    variant="secondary"
                    icon="pencil"
                    onClick={props.onEdit}
                />
            </SpacingRow>
        </Padding>
    </CRMInfoBoxSimpleColourComponent>;
