import React from "react";
import { TSimpleLegalEmailPerson } from "../../../../../domain/codecs/LegalEmail";
import { requireExhaustive } from "../../../../../shared/src/util";
import { CRMColors } from "../../../models/CRMColors";
import { CRMFontSizes } from "../../../models/CRMFontSizes";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { THexColor } from "../../../models/StringLiterals";
import { Background } from "../../BuildingBlocks/Background";
import { Border } from "../../BuildingBlocks/Border";
import { FontSignikaNegative } from "../../BuildingBlocks/FontSignikaNegative";
import { Padding } from "../../BuildingBlocks/Padding";
import { SpacingRow } from "../../BuildingBlocks/SpacingRow";
import { Text } from "../../BuildingBlocks/Text";
import { CRMIcon } from "../../CRMIcon/CRMIcon";
import { Uppercase } from "../../Uppercase/Uppercase";
import { WeightBold } from "../../WeightBold/WeightBold";
import { CRMFuzzySortSearchHighlight } from "../CRMFuzzySortSearchHighlight/CRMFuzzySortSearchHighlight";

type TEmailOutsiderGlancingLabelProps = {
    direction: "outbound" | "inbound";
    person: TSimpleLegalEmailPerson;
    searchTerm: string;
};

export const CRMEmailOutsiderGlancingLabel = (props: React.PropsWithChildren<TEmailOutsiderGlancingLabelProps>): JSX.Element => {

    const getColorBasedOnTitle = (): { background: THexColor, border: THexColor } => {

        switch (props.person.title) {
            case "client":
                return { 
                    background: CRMColors.HIGHLIGHTS_CALMING_PINK_11,
                    border: CRMColors.HIGHLIGHTS_CALMING_PINK_8,
                }
            case "conveyancer":
                return { 
                    background: CRMColors.PRIMARY_16,
                    border: CRMColors.PRIMARY_12,
                }
            
            case "other":
            case "unknown":
                return { 
                    background: CRMColors.HIGHLIGHTS_EASY_ORANGE_12,
                    border: CRMColors.HIGHLIGHTS_EASY_ORANGE_10
                }
            case "sail_staff":
                return { 
                    background: CRMColors.NEUTRAL_PAPER,
                    border: CRMColors.NEUTRAL_PAPER
                }
            default:
                return requireExhaustive(props.person.title);
        }
    }

    const hasName = () => !!props.person.first_name && !!props.person.last_name;

    const getName = (): JSX.Element => {
        if (hasName() && isShowingTitle()) {
            return <CRMFuzzySortSearchHighlight
                text={`(${props.person.first_name} ${props.person.last_name})`}
                searchText={props.searchTerm}
            />;
        }
        if (hasName() && !isShowingTitle()) {
            return <>
                <WeightBold>
                    <CRMFuzzySortSearchHighlight
                        text={props.person.address}
                        searchText={props.searchTerm}
                    />
                </WeightBold>
                <CRMFuzzySortSearchHighlight
                    text={`(${props.person.first_name} ${props.person.last_name})`}
                    searchText={props.searchTerm}
                />
            </>;
        }
        return <CRMFuzzySortSearchHighlight
            text={props.person.address}
            searchText={props.searchTerm}
        />;
    }

    const isShowingTitle = () => 
        // props.person.title !== "unknown" &   & 
        props.person.title !== "sail_staff"
    ;

    return (
        <Background
            color={getColorBasedOnTitle().background}
            borderRadius={`0px 50px 50px 0px`}
        >
            <Border
                type="left"
                definition={`6px solid ${getColorBasedOnTitle().border}`}
            >
                <Padding
                    type={isShowingTitle() ? "horizontal" : "left"}
                    spacing={CRMSpacing.SMALL}
                >
                    <SpacingRow
                        spacing={CRMSpacing.TINY}
                        alignItems="center"
                        height="28px"
                    >
                        <CRMIcon
                            iconName={props.direction === "outbound" ? "backward-arrow" : "forward-arrow"}
                            size={"20px"}
                            colour={"neutral-ink"}
                            title={`${props.direction} email`}
                        />

                        {isShowingTitle() &&
                            <Text
                                size={CRMFontSizes.X_SMALL}
                                lineHeight={2.2}
                                weight={700}
                            >
                                <FontSignikaNegative>
                                    <Uppercase>
                                        {props.person.title}
                                    </Uppercase>
                                </FontSignikaNegative>
                            </Text>
                        }
                        
                        <Text
                            size={CRMFontSizes.SMALL}
                            lineHeight={1}
                            weight={400}
                            margin={"-2px 0px 0px 0px"}
                        >
                            {getName()}
                        </Text>
                    </SpacingRow>
                </Padding>
            </Border>
        </Background>
    );
};
