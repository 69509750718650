import { TCodecSetState, TGetCodecState } from "../applyActions";
import { TGetState } from "../TGetState";
import { TSetState } from "../TSetState";
import { TDispatch } from "../TDispatch";
import { stateLens, TStateLens } from "../../../../domain/codecs/state";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { TPayloadActionType, TAllActionPayloadsByType } from "./TAction";
import { Routes } from "../router/routerRoutes";
import { TChangeRouteAction, TParamsObject, TQueryParamsObject, TRouteActionType } from "../router/routerTypes";

export const action = <T extends TPayloadActionType>(
    name: T,
    callback: (
        actions$: Observable<TAllActionPayloadsByType[T]["payload"]>,
        sL: TStateLens,
        set: TCodecSetState,
        get: TGetCodecState,
        activeRoute: Routes,
        dispatch: TDispatch
    ) => void
) => {
    return {
        type: name,
        run: (
            obs$: Observable<TAllActionPayloadsByType[T]>,
            getState: TGetState,
            setState: TSetState,
            dispatch: TDispatch,
            l: TStateLens,
            setCodecState: TCodecSetState
        ): void => {
            callback(obs$.pipe(
                map((a) => a.payload)
            ), l, setCodecState, () => getState().forms, getState().routes, dispatch);
        }
    };
};

type TRouteActionPayload<T extends TRouteActionType> = {
    params: TParamsObject<T>;
    queryStringParams: TQueryParamsObject<T>;
};

export const routeAction = <Name extends TRouteActionType>(name: Name, callback: (actions$: Observable<TRouteActionPayload<Name>>, stateLensArg: TStateLens, set: TCodecSetState, get: TGetCodecState) => void) => (
    {
        type: name,
        run: (
            obs$: Observable<TChangeRouteAction<Name>>,
            getState: TGetState,
            setState: TSetState,
            dispatch: TDispatch,
            l: TStateLens,
            setCodecState: TCodecSetState
        ): void => {
            callback(obs$, stateLens, setCodecState, () => getState().forms);
        },
    }
);
