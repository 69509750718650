import React from "react";
import { CRMColors } from "../../../models/CRMColors";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { Background } from "../../BuildingBlocks/Background";
import { SpacingRow } from "../../BuildingBlocks/SpacingRow";
import { CRMBlockActionPopout } from "../../CRM/CRMBlock/CRMBlockActionPopout/CRMBlockActionPopout";
import { CRMButtonIcon } from "../../CRMButtonIcon/CRMButtonIcon";
import { WeightBold } from "../../WeightBold/WeightBold";

type TCustomerSatisfactionSingleViewControlsProps = {
    onAddNote: () => void;
    onReAssign: () => void;
    onSetSentimentAsHappy: () => void;
    onSetSentimentAsSad: () => void;
    onMarkAsRaisedAsAFormalComplaint: () => void;
    onMarkAsRaisedWithTheObudsman: () => void;
    onDefer: () => void;
};

export const CRMCustomerSatisfactionSingleViewControls = (props: React.PropsWithChildren<TCustomerSatisfactionSingleViewControlsProps>): JSX.Element => {

    return (
        <Background
            color={CRMColors.NEUTRAL_PAPER}
            padding={`${CRMSpacing.TINY} ${CRMSpacing.MEDIUM}`}
        >
            <SpacingRow spacing={CRMSpacing.TINY}>
                <SpacingRow spacing={CRMSpacing.TINY}>
                    <CRMButtonIcon
                        title="Add note."
                        variant="quaternary"
                        icon="new-chat"
                        onClick={props.onAddNote}
                    />
                    
                    <CRMButtonIcon
                        title="Re-assign."
                        variant="quaternary"
                        icon="person"
                        onClick={props.onReAssign}
                    />

                    <CRMBlockActionPopout
                        icon="event-new"
                        title="Add event."
                        options={[
                            {
                                label: <span>Set sentiment as <WeightBold>Happy</WeightBold></span>,
                                onClick: props.onSetSentimentAsHappy,
                            },
                            {
                                label: <span>Set sentiment as <WeightBold>Unhappy</WeightBold></span>,
                                onClick: props.onSetSentimentAsSad,
                            },
                            {
                                label: "Mark as \"raised as a formal complaint\"",
                                onClick: props.onMarkAsRaisedAsAFormalComplaint
                            },
                            {
                                label: "Mark as \"raised with the obudsman\"",
                                onClick: props.onMarkAsRaisedWithTheObudsman
                            },
                            {
                                label: "Defer review unhappy satisfaction job",
                                onClick: props.onDefer
                            },
                        ]}
                    />
                </SpacingRow>
            </SpacingRow>
        </Background>
    );
};
