import React from "react";
import { FrontSpacing } from "../../../../models/FrontSpacing";
import { SpacingColumn } from "../../../BuildingBlocks/SpacingColumn";
import FrontParagraphSmall from "../FrontParagraphSmall/FrontParagraphSmall";

type TOnboardingFormParagraphsProps = {
    paragraphs: Array<JSX.Element>;
};

export const FrontOnboardingFormParagraphs = (props: React.PropsWithChildren<TOnboardingFormParagraphsProps>): JSX.Element => {

    return (
        <SpacingColumn spacing={FrontSpacing.SMALL_3}>
            {props.paragraphs.map((paragraph, index) => (
                <FrontParagraphSmall key={index}>
                    {paragraph}
                </FrontParagraphSmall>
            ))}
        </SpacingColumn>
    );
};
