import { array } from "fp-ts";
import React from "react";
import { CRMSpacingRow } from "../../components/CRMSpacingRow/CRMSpacingRow";
import { CRMButtonTertiary } from "../../components/CRMButtonTertiary/CRMButtonTertiary";
import { CRMCardOutside } from "../../components/CRMCardOutside/CRMCardOutside";
import { CRMCardOutsideWarning } from "../../components/CRMCardOutsideWarning/CRMCardOutsideWarning";
import CRMInputGeneralComponent from "../../components/CRMInputs/CRMInputGeneralComponent/CRMInputGeneralComponent";
import CRMInputLabelAndErrorWrapComponent from "../../components/CRMInputLabelAndErrorWrapComponent/CRMInputLabelAndErrorWrapComponent";
import CRMListingKeyInfoComponent from "../../components/CRMListingKeyInfoComponent/CRMListingKeyInfoComponent";
import { CRMPadding } from "../../components/Simple/CRMPadding/CRMPadding";
import { CRMParagraph } from "../../components/Simple/CRMParagraph/CRMParagraph";
import { CRMSoloColumn } from "../../components/CRMSoloColumn/CRMSoloColumn";
import { CRMSpacer } from "../../components/CRMSpacer/CRMSpacer";
import { CRMTitleSection } from "../../components/CRMTitleSection/CRMTitleSection";
import { CRMPageWrap } from "../../components/Simple/CRMPageWrap/CRMPageWrap";
import { CRMPageBody } from "../../components/Simple/CRMPageBody/CRMPageBody";
import { CRMHomesMenuBarContainer } from "../../containers/CRMHomesMenuBarContainer/CRMHomesMenuBarContainer";
import { TContainerStateProps } from "../../state/TContainerStateProps";
import { pipe } from "fp-ts/lib/function";


class CRMAddEnquiryPage extends React.Component<React.PropsWithChildren<TContainerStateProps>> {
    public render(): JSX.Element {
        const addNewEnquiryState = this.props.state.activeData.crm.addNewEnquiry;
        return (
            <CRMPageWrap>

                <CRMHomesMenuBarContainer {...this.props} />

                <CRMPageBody>
                    <CRMSoloColumn size="half">
                        <CRMPadding size="large">
                            <CRMCardOutside borderRounding="right" shadow={true}>
                                <CRMPadding size="large">
                                    <CRMTitleSection>Search Property To Add Enquiry To</CRMTitleSection>
                                    <CRMSpacer size="large" />
                                    <CRMInputLabelAndErrorWrapComponent
                                        label="Enter property name and select from matches."
                                        displayError={false}
                                        errorMessage=""
                                    >
                                        <CRMInputGeneralComponent
                                            inputType="text"
                                            displayError={false}
                                            onChange={(searchInput) => this.props.dispatch({
                                                type: "CRM_ADD_NEW_ENQUIRY_SEARCH_INPUT_CHANGED",
                                                payload: searchInput,
                                            })}
                                            value={this.props.state.activeData.crm.addNewEnquiry.searchInput}
                                            onPressEnterKey={() => undefined}
                                            autoFocus={true}
                                        />
                                    </CRMInputLabelAndErrorWrapComponent>
                                    <CRMSpacer size="medium" />
                                </CRMPadding>
                            </CRMCardOutside>
                            <CRMSpacer size="large"/>
                            {
                                addNewEnquiryState.searchResponseListings.forms.length === 0 && addNewEnquiryState.searchResponseListings.status !== "loading"
                                && <CRMCardOutsideWarning
                                    borderRounding="right"
                                    icon="warning-bubble"
                                    labelColour="highlights-pertinent-yellow-4"
                                >
                                    <CRMPadding size="large">
                                        <CRMParagraph>
                                            No results found
                                        </CRMParagraph>
                                    </CRMPadding>
                                </CRMCardOutsideWarning>
                            }
                            {
                                addNewEnquiryState.searchResponseListings.meta.available_count > addNewEnquiryState.searchResponseListings.meta.returned_count
                                && <>
                                    <CRMCardOutsideWarning
                                        borderRounding="right"
                                        icon="warning-bubble"
                                        labelColour="highlights-pertinent-yellow-4"
                                    >
                                        <CRMPadding size="large">
                                            <CRMParagraph>
                                                Too many results returned, please narrow your search
                                            </CRMParagraph>
                                        </CRMPadding>
                                    </CRMCardOutsideWarning>
                                    <CRMSpacer size="large"/>
                                </>
                            }
                            {
                                pipe(
                                    this.props.state.activeData.crm.addNewEnquiry.searchResponseListings.forms,
                                    array.map((l) =>
                                        <>
                                        <CRMCardOutside borderRounding="right" key={l.view.id} shadow={true}>
                                            <CRMPadding size="large">
                                                <CRMListingKeyInfoComponent listing={l.view}/>
                                                <CRMSpacer size="large" />
                                                <CRMSpacingRow
                                                    justifyContent="end"
                                                >
                                                    <CRMButtonTertiary
                                                        label="Create new party and enquiry"
                                                        onClick={() => this.props.dispatch({
                                                            type: "CRM_ADD_NEW_ENQUIRY_CREATE_PARTY_AND_ENQUIRY_FOR_LISTING",
                                                            payload: l.view.id,
                                                        })}
                                                    />
                                                </CRMSpacingRow>
                                            </CRMPadding>
                                        </CRMCardOutside>
                                        <CRMSpacer size="large"/>
                                        </>
                                    )
                                )
                            }
                        </CRMPadding>
                    </CRMSoloColumn>
                </CRMPageBody>
            </CRMPageWrap>
        );
    }
}

export default CRMAddEnquiryPage;
