import React from "react";
import { CRMTableRow } from "../../CRMTableRow/CRMTableRow";
import { SpacingRow } from "../../BuildingBlocks/SpacingRow";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { pipe } from "fp-ts/function";
import { CRMParagraph } from "../../Simple/CRMParagraph/CRMParagraph";
import { CRMColors } from "../../../models/CRMColors";
import { TProbateHelplineCallbackForm } from "../../../../../domain/codecs/form/ProbateHelplineCallbackForm";
import { array } from "fp-ts";
import { WeightMedium } from "../../WeightMedium/WeightMedium";

export const CRMProbateHelplineCallbackCompletedRow = (props: React.PropsWithChildren<{
    form: TProbateHelplineCallbackForm;
    onClick: () => void;
}>): JSX.Element => {
    const fullName =
        pipe(
            [
                props.form.children.first_name,
                props.form.children.last_name,
            ],
            array.filter((namePart) => namePart !== ""),
            (a) => a.join(" "),
        );

    return <CRMTableRow
        backgroundColour={CRMColors.NEUTRAL_PAPER}
        onClick={props.onClick}
        actions={[]}
    >
        <SpacingRow
            spacing={CRMSpacing.MEDIUM}
            justifyContent="space-between"
        >
            {/* NAME */}
            <CRMParagraph>
                <WeightMedium>
                    {fullName}
                </WeightMedium>
            </CRMParagraph>
        </SpacingRow>
    </CRMTableRow>;
};
