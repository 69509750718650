import { forkJoin, Observable, from, of } from "rxjs";
import * as CaseEnquiriesResponse1 from "../../../../../domain/models/CaseEnquiriesResponse1";
import * as CaseDocumentsResponse1 from "../../../../../domain/models/CaseDocumentsResponse1";
import * as DeleteEntitySuccessResponse from "../../../../../domain/models/DeleteEntitySuccessResponse";
import * as FirstPartyFetchResponse from "../../../../../domain/models/FirstPartyFetchResponse";
import * as CaseEnquiry1 from "../../../../../domain/models/CaseEnquiry1";
import * as CaseEnquiry5 from "../../../../../domain/models/CaseEnquiry5";
import * as CaseResponse1 from "../../../../../domain/models/CaseResponse1";
import * as rxjsOperators from "rxjs/operators";
import { option, array } from "fp-ts/lib";
import { pipe } from "fp-ts/lib/function";
import * as TForm from "../../../models/TForm";
import * as fetchWrapper from "../../../wrappers/fetch";
import * as util from "../../../util";
import { TCaseDocument6AndCaseDocumentForm3FormList, TCaseEnquiry5FormList, TCaseEnquiryStatusFilterForm, TCaseEnquiry1Form, TCaseEnquiry5Form, TCaseEnquiry5And1Form, TCase2ReadOnlyForm, TCaseEnquiryAttachmentFormList, TCaseEnquiryNote1FormList, TCaseEnquiryTimeline1ReadOnlyFormList } from "../../../models/TFormModels";
import * as CaseEnquiriesFilter1 from "../../../../../domain/models/CaseEnquiriesFilter1";
import { TActionObservable, TUnpackFormActionPayload } from "../../applyActions";
import * as TCRMLegalCaseEnquiries from "../../TCRMLegalCaseEnquiries";
import * as t from "io-ts";
import * as CaseEnquiryAttachments1 from "../../../../../domain/models/CaseEnquiryAttachments1";
import * as CaseEnquiryNote1 from "../../../../../domain/models/CaseEnquiryNote1";
import * as CaseDocumentsFilter1 from "../../../../../domain/models/CaseDocumentsFilter1";
import * as TCaseEnquiryTimeline1ReadOnlyForm from "../../../models/TCaseEnquiryTimeline1ReadOnlyForm";
import { TGetState } from "../../TGetState";
import { TSetState } from "../../TSetState";
import { TActionPayload, TActionsDefinitionsList } from "../TAction";
import { pipeSubscribed } from "../../../functions/lens/pipe";
import { setForm } from "../../../functions/lens/setForm";
import { toActionable } from "../../../functions/lens/toActionable";
import { setFormSubscribed } from "../../../functions/lens/setFormSubscribed";
import { submitForm } from "../../../functions/lens/submitForm";
import { submitFormWhere } from "../../../functions/lens/submitFormWhere";
import { reduceDataToStateUpdate } from "../../../functions/lens/reduceDataToStateUpdate";
import { setWhere } from "../../../functions/lens/setWhere";
import { setFormWhere } from "../../../functions/lens/setFormWhere";
import { set } from "../../../functions/lens/set";

export const actions: TActionsDefinitionsList = [
    {
        type: "CRM_CASE_ENQUIRIES_VIEW",
        run: (
            obs$: TActionObservable<"CRM_CASE_ENQUIRIES_VIEW", null>,
            getState: TGetState,
            setState: TSetState,
        ): void => {
            obs$.pipe(
                rxjsOperators.mergeMap(() =>
                    forkJoin([
                        makeEnquiriesRequest(setState, getState),
                        makeDocumentsRequest(setState, getState),
                        makeGetCaseRequest(setState, getState),
                    ]),
                ),
            ).subscribe();
        },
    },
    {
        type: "CRM_CASE_ENQUIRIES_FILTER_INPUT_CHANGE" as const,
        run: pipeSubscribed(
            setForm<"CRM_CASE_ENQUIRIES_FILTER_INPUT_CHANGE", TCaseEnquiryStatusFilterForm>()(
                ["activeData", "crm", "legalCaseEnquiries", "enquiryFilter"],
            ),
            toActionable<"CRM_CASE_ENQUIRIES_FILTER_INPUT_CHANGE", unknown, [TGetState, TSetState]>()(
                (_, getState, setState) => [getState, setState],
                ([getState, setState]) => makeEnquiriesRequest(setState, getState),
            )
        ),
    },
    {
        type: "CRM_CASE_ENQUIRY_ADD_ENQUIRY_CHANGE_DOCUMENT",
        run: setFormSubscribed<"CRM_CASE_ENQUIRY_ADD_ENQUIRY_CHANGE_DOCUMENT", TCaseEnquiry1Form>()(
            ["activeData", "crm", "legalCaseEnquiries", "addEnquiry"],
        ),
    },
    {
        type: "CRM_CASE_ENQUIRY_ADD_ENQUIRY_SUBMIT" as const,
        run: (
            obs$: Observable<TActionPayload<"CRM_CASE_ENQUIRY_ADD_ENQUIRY_SUBMIT", undefined>>,
            getState: TGetState,
            setState: TSetState,
        ) =>
            submitForm<"CRM_CASE_ENQUIRY_ADD_ENQUIRY_SUBMIT", undefined, TCRMLegalCaseEnquiries.C["addEnquiry"]["view"], TCRMLegalCaseEnquiries.C["addEnquiry"]["edit"], TCRMLegalCaseEnquiries.C["addEnquiry"]["ui"]>()(
                ["activeData", "crm", "legalCaseEnquiries", "addEnquiry"],
                () => `/v1/cases/${getState().routes.params.caseId}/enquiries`,
                "POST",
                () => option.none,
                undefined,
                () => {
                    forkJoin([
                        makeEnquiriesRequest(setState, getState),
                        makeDocumentsRequest(setState, getState),
                    ])
                    .pipe(
                        rxjsOperators.tap(() => {
                            setState(({ ...s }) => {
                                if (s.activeData.crm.legalCaseEnquiries.addEnquiry.status === "untouched") {
                                    s.activeData.crm.legalCaseEnquiries.addEnquiry = TForm.defaultTFormV2({}, CaseEnquiry1.newDefault(), {});
                                    s.activeData.crm.legalCaseEnquiries.addEnquiry.edit.reference_id = `E${s.activeData.crm.legalCaseEnquiries.enquiries.forms.length+1}`;
                                }
                                return s;
                            });
                        })
                    )
                    .subscribe();
                }
            )(obs$, getState, setState).subscribe(),
    },
    {
        type: "CRM_CASE_ENQUIRY_MARK_AS_SENT" as const,
        run: (
            obs$: Observable<TActionPayload<"CRM_CASE_ENQUIRY_MARK_AS_SENT", string>>,
            getState: TGetState,
            setState: TSetState,
        ) =>
            submitFormWhere<"CRM_CASE_ENQUIRY_MARK_AS_SENT", string, {}, {}, {}>()(
                ["activeData", "crm", "legalCaseEnquiries", "enquiries", "forms"],
                () => true,
                (f, enquiryId) => `/v1/cases/${getState().routes.params.caseId}/enquiries/${enquiryId}/mark-as-sent`,
                "PATCH",
                () => option.none,
                undefined,
                () => {
                    forkJoin([
                        makeEnquiriesRequest(setState, getState),
                        makeDocumentsRequest(setState, getState),
                    ])
                    .subscribe();
                }
            )(obs$, getState, setState).subscribe(),
    },
    {
        type: "CRM_CASE_ENQUIRY_MARK_AS_RESOLVED" as const,
        run: (
            obs$: Observable<TActionPayload<"CRM_CASE_ENQUIRY_MARK_AS_RESOLVED", string>>,
            getState: TGetState,
            setState: TSetState,
        ) =>
            submitFormWhere<"CRM_CASE_ENQUIRY_MARK_AS_RESOLVED", string, {}, {}, {}>()(
                ["activeData", "crm", "legalCaseEnquiries", "enquiries", "forms"],
                (form, enquiryId) => form.view.id === enquiryId,
                (f, enquiryId) => `/v1/cases/${getState().routes.params.caseId}/enquiries/${enquiryId}/resolve`,
                "PATCH",
                () => option.none,
                undefined,
                () => {
                    forkJoin([
                        makeEnquiriesRequest(setState, getState),
                        makeDocumentsRequest(setState, getState),
                    ])
                    .subscribe();
                }
            )(obs$, getState, setState).subscribe(),
    },
    {
        type: "CRM_CASE_ENQUIRY_DELETE" as const,
        run: (
            obs$: Observable<TActionPayload<"CRM_CASE_ENQUIRY_DELETE", string>>,
            getState: TGetState,
            setState: TSetState,
        ) => {
            obs$
            .pipe(
                rxjsOperators.mergeMap((a) => fetchWrapper.json<DeleteEntitySuccessResponse.T>({
                    requestParams: {
                        url: `${env.REACT_APP_API_URL}/v1/cases/${getState().routes.params.caseId}/enquiries/${a.payload}`,
                        method: "DELETE",
                        body: option.none,
                    },
                    expectedTypeCodec: DeleteEntitySuccessResponse.codec,
                    defaultResponse: DeleteEntitySuccessResponse.newDefault(),
                })()),
                rxjsOperators.mergeMap(() => forkJoin([
                    makeEnquiriesRequest(setState, getState),
                    makeDocumentsRequest(setState, getState),
                ]))
            ).subscribe();
        },
    },
    {
        type: "CRM_CASE_ENQUIRY_CLICK_ADD_ENQUIRY" as const,
        run: (
            obs$: Observable<TActionPayload<"CRM_CASE_ENQUIRY_CLICK_ADD_ENQUIRY", undefined>>,
            getState: TGetState,
            setState: TSetState,
        ) => {
            obs$.pipe(
                rxjsOperators.tap(() => {
                    setState(({ ...s }) => {
                        s.activeData.crm.legalCaseEnquiries.addEnquiryModal = "open";
                        s.activeData.crm.legalCaseEnquiries.addEnquiry.edit.reference_id = `E${s.activeData.crm.legalCaseEnquiries.enquiries.forms.length+1}`;
                        return s;
                    });
                })
            ).subscribe();
        },
    },
    {
        type: "CRM_CASE_ENQUIRY_CLICK_CLOSE_ADD_ENQUIRY_MODAL" as const,
        run: (
            obs$: Observable<TActionPayload<"CRM_CASE_ENQUIRY_CLICK_CLOSE_ADD_ENQUIRY_MODAL", undefined>>,
            getState: TGetState,
            setState: TSetState,
        ) => {
            obs$.pipe(
                rxjsOperators.tap(() => {
                    setState(({ ...s }) => {
                        s.activeData.crm.legalCaseEnquiries.addEnquiryModal = "closed";
                        return s;
                    });
                })
            ).subscribe();
        },
    },
    {
        type: "CRM_CASE_ENQUIRY_SUBMIT_ENQUIRIES_FOR_APPROVAL_BUTTON" as const,
        run: (
            obs$: Observable<TActionPayload<"CRM_CASE_ENQUIRY_SUBMIT_ENQUIRIES_FOR_APPROVAL_BUTTON", undefined>>,
            getState: TGetState,
            setState: TSetState,
        ) => {
            obs$.pipe(
                rxjsOperators.tap(() => {
                    setState(({ ...s }) => {
                        s.activeData.crm.legalCaseEnquiries.submitEnquiriesForApprovalModal = "open";
                        return s;
                    });
                })
            ).subscribe();
        },
    },
    {
        type: "CRM_CASE_ENQUIRY_CLOSE_SUBMIT_ENQUIRIES_MODAL" as const,
        run: (
            obs$: Observable<TActionPayload<"CRM_CASE_ENQUIRY_CLOSE_SUBMIT_ENQUIRIES_MODAL", undefined>>,
            getState: TGetState,
            setState: TSetState,
        ) => {
            obs$.pipe(
                rxjsOperators.tap(() => {
                    setState(({ ...s }) => {
                        s.activeData.crm.legalCaseEnquiries.submitEnquiriesForApprovalModal = "closed";
                        return s;
                    });
                })
            ).subscribe();
        },
    },
    {
        type: "CRM_CASE_SUBMIT_ENQUIRIES_FOR_APPROVAL" as const,
        run: (
            obs$: Observable<TActionPayload<"CRM_CASE_SUBMIT_ENQUIRIES_FOR_APPROVAL", undefined>>,
            getState: TGetState,
            setState: TSetState,
        ) => {
            obs$
            .pipe(
                rxjsOperators.mergeMap(() => fetchWrapper.json<DeleteEntitySuccessResponse.T>({
                    requestParams: {
                        url: `${env.REACT_APP_API_URL}/v1/cases/${getState().routes.params.caseId}/submit-enquiries-for-approval`,
                        method: "PATCH",
                        body: option.none,
                    },
                    expectedTypeCodec: DeleteEntitySuccessResponse.codec,
                    defaultResponse: DeleteEntitySuccessResponse.newDefault(),
                })()),
                rxjsOperators.tap(() => setState(({ ...s }) => {
                    s.activeData.crm.legalCaseEnquiries.submitEnquiriesForApprovalModal = "closed";
                    return s;
                })),
                rxjsOperators.mergeMap(() => forkJoin([
                    makeEnquiriesRequest(setState, getState),
                    makeDocumentsRequest(setState, getState),
                ]))
            ).subscribe();
        },
    },
    {
        type: "CRM_CASE_ENQUIRY_REOPEN" as const,
        run: (
            obs$: Observable<TActionPayload<"CRM_CASE_ENQUIRY_REOPEN", string>>,
            getState: TGetState,
            setState: TSetState,
        ) => {
            obs$
            .pipe(
                rxjsOperators.mergeMap((a) => fetchWrapper.json({
                    requestParams: {
                        url: `${env.REACT_APP_API_URL}/v1/cases/${getState().routes.params.caseId}/enquiries/${a.payload}/reopen`,
                        method: "PATCH",
                        body: option.none,
                    },
                    expectedTypeCodec: t.type({}),
                    defaultResponse: {data:{}, meta:{}},
                })()),
                rxjsOperators.mergeMap(() => forkJoin([
                    makeEnquiriesRequest(setState, getState),
                    makeDocumentsRequest(setState, getState),
                ]))
            ).subscribe();
        },
    },
    {
        type: "CRM_CASE_ENQUIRY_APPROVE" as const,
        run: (
            obs$: Observable<TActionPayload<"CRM_CASE_ENQUIRY_APPROVE", string>>,
            getState: TGetState,
            setState: TSetState,
        ) => {
            obs$
            .pipe(
                rxjsOperators.mergeMap((a) => fetchWrapper.json({
                    requestParams: {
                        url: `${env.REACT_APP_API_URL}/v1/cases/${getState().routes.params.caseId}/enquiries/${a.payload}/approve`,
                        method: "PATCH",
                        body: option.none,
                    },
                    expectedTypeCodec: t.type({}),
                    defaultResponse: {data:{}, meta:{}},
                })()),
                rxjsOperators.mergeMap(() => forkJoin([
                    makeEnquiriesRequest(setState, getState),
                    makeDocumentsRequest(setState, getState),
                ]))
            ).subscribe();
        },
    },
    {
        type: "CRM_CASE_ENQUIRY_MARK_AS_NOT_RELEVANT" as const,
        run: (
            obs$: Observable<TActionPayload<"CRM_CASE_ENQUIRY_MARK_AS_NOT_RELEVANT", string>>,
            getState: TGetState,
            setState: TSetState,
        ) => {
            obs$
            .pipe(
                rxjsOperators.mergeMap((a) => fetchWrapper.json({
                    requestParams: {
                        url: `${env.REACT_APP_API_URL}/v1/cases/${getState().routes.params.caseId}/enquiries/${a.payload}/mark-as-not-relevant`,
                        method: "PATCH",
                        body: option.none,
                    },
                    expectedTypeCodec: t.type({}),
                    defaultResponse: {data:{}, meta:{}},
                })()),
                rxjsOperators.mergeMap(() => forkJoin([
                    makeEnquiriesRequest(setState, getState),
                    makeDocumentsRequest(setState, getState),
                ]))
            ).subscribe();
        },
    },
    {
        type: "CRM_CASE_ENQUIRY_CLICK_EDIT_ENQUIRY" as const,
        run: (
            obs$: Observable<TActionPayload<"CRM_CASE_ENQUIRY_CLICK_EDIT_ENQUIRY", string>>,
            getState: TGetState,
            setState: TSetState,
        ) => {
            obs$.pipe(
                rxjsOperators.tap((action) => {
                    setState(({ ...s }) => {
                        s.activeData.crm.legalCaseEnquiries.editEnquiry =  pipe(
                            s.activeData.crm.legalCaseEnquiries.enquiries.forms,
                            array.findFirst<TCaseEnquiry5FormList["forms"][number]>((enquiry) => enquiry.view.id === action.payload),
                            option.fold<TCaseEnquiry5Form, TCaseEnquiry5And1Form>(
                                () => TForm.defaultTFormV2(CaseEnquiry5.newDefault(), CaseEnquiry1.newDefault(), {}),
                                (enquiry) => TForm.dataToForm<TCaseEnquiry5And1Form>(CaseEnquiry1.newDefault(), {})(enquiry.view),
                            ),
                        );
                        s.activeData.crm.legalCaseEnquiries.editEnquiryModal = "open";
                        return s;
                    });
                }),
            ).subscribe();
        },
    },
    {
        type: "CRM_CASE_ENQUIRY_EDIT_ENQUIRY_CHANGE_DOCUMENT",
        run: setFormSubscribed<"CRM_CASE_ENQUIRY_EDIT_ENQUIRY_CHANGE_DOCUMENT", TCaseEnquiry1Form>()(
            ["activeData", "crm", "legalCaseEnquiries", "editEnquiry"],
        ),
    },
    {
        type: "CRM_CASE_ENQUIRY_EDIT_ENQUIRY_SUBMIT" as const,
        run: (
            obs$: Observable<TActionPayload<"CRM_CASE_ENQUIRY_EDIT_ENQUIRY_SUBMIT", undefined>>,
            getState: TGetState,
            setState: TSetState,
        ) =>
            submitForm<"CRM_CASE_ENQUIRY_EDIT_ENQUIRY_SUBMIT", undefined, TCRMLegalCaseEnquiries.C["editEnquiry"]["view"], TCRMLegalCaseEnquiries.C["editEnquiry"]["edit"], TCRMLegalCaseEnquiries.C["editEnquiry"]["ui"]>()(
                ["activeData", "crm", "legalCaseEnquiries", "editEnquiry"],
                (a) => `/v1/cases/${getState().routes.params.caseId}/enquiries/${a.view.id}`,
                "PATCH",
                () => option.none,
                undefined,
                () => {
                    forkJoin([
                        makeEnquiriesRequest(setState, getState),
                        makeDocumentsRequest(setState, getState),
                    ])
                    .pipe(
                        rxjsOperators.tap(() => {
                            setState(({ ...s }) => {
                                if (s.activeData.crm.legalCaseEnquiries.editEnquiry.status === "untouched") {
                                    s.activeData.crm.legalCaseEnquiries.editEnquiryModal = "closed";
                                    s.activeData.crm.legalCaseEnquiries.editEnquiry = TForm.defaultTFormV2(CaseEnquiry5.newDefault(), CaseEnquiry1.newDefault(), {});
                                }
                                return s;
                            });
                        })
                    )
                    .subscribe();
                }
            )(obs$, getState, setState).subscribe(),
    },
    {
        type: "CRM_CASE_ENQUIRY_CLICK_CLOSE_EDIT_ENQUIRY_MODAL" as const,
        run: (
            obs$: Observable<TActionPayload<"CRM_CASE_ENQUIRY_CLICK_CLOSE_EDIT_ENQUIRY_MODAL", undefined>>,
            getState: TGetState,
            setState: TSetState,
        ) => {
            obs$.pipe(
                rxjsOperators.tap(() => {
                    setState(({ ...s }) => {
                        s.activeData.crm.legalCaseEnquiries.editEnquiryModal = "closed";
                        s.activeData.crm.legalCaseEnquiries.editEnquiry = TForm.defaultTFormV2(CaseEnquiry5.newDefault(), CaseEnquiry1.newDefault(), {});
                        return s;
                    });
                })
            ).subscribe();
        },
    },
    {
        type: "CRM_CASE_ENQUIRIES_ENQUIRY_ATTACHMENT_OPEN_MODAL" as const,
        run: (
            obs$: Observable<TActionPayload<"CRM_CASE_ENQUIRIES_ENQUIRY_ATTACHMENT_OPEN_MODAL", {resourceId: string}>>,
            getState: TGetState,
            setState: TSetState,
        ) =>
            obs$.subscribe((payload) =>
                reduceDataToStateUpdate<TActionPayload<"CRM_CASE_ENQUIRIES_ENQUIRY_ATTACHMENT_OPEN_MODAL", {resourceId: string}>>(setState)(
                    setWhere<TActionPayload<"CRM_CASE_ENQUIRIES_ENQUIRY_ATTACHMENT_OPEN_MODAL", {resourceId: string}>>()(
                        ["activeData", "crm", "legalCaseEnquiries", "enquiryAttachments", "forms"],
                        (form, p) => form.ui.enquiryId === p.payload.resourceId,
                        (form) => ({
                            ...form,
                            ui: {
                                ...form.ui,
                                isModalOpen: true,
                            },
                        })
                    ),
                )(payload),
            ),
    },
    {
        type: "CRM_CASE_ENQUIRIES_ENQUIRY_ATTACHMENT_CLOSE_MODAL" as const,
        run: (
            obs$: Observable<TActionPayload<"CRM_CASE_ENQUIRIES_ENQUIRY_ATTACHMENT_CLOSE_MODAL", {resourceId: string}>>,
            getState: TGetState,
            setState: TSetState,
        ) =>
            obs$.subscribe((payload) =>
                reduceDataToStateUpdate<TActionPayload<"CRM_CASE_ENQUIRIES_ENQUIRY_ATTACHMENT_CLOSE_MODAL", {resourceId: string}>>(setState)(
                    setWhere<TActionPayload<"CRM_CASE_ENQUIRIES_ENQUIRY_ATTACHMENT_CLOSE_MODAL", {resourceId: string}>>()(
                        ["activeData", "crm", "legalCaseEnquiries", "enquiryAttachments", "forms"],
                        (form, p) => form.ui.enquiryId === p.payload.resourceId,
                        (form) => ({
                            ...form,
                            ui: {
                                ...form.ui,
                                isModalOpen: false,
                            },
                        })
                    ),
                )(payload),
            ),
    },
    {
        type: "CRM_CASE_ENQUIRIES_ENQUIRY_ATTACHMENT_CHANGE" as const,
        run: (
            obs$: Observable<TActionPayload<"CRM_CASE_ENQUIRIES_ENQUIRY_ATTACHMENT_CHANGE", {resourceId: string; value: TCaseEnquiryAttachmentFormList["forms"][number]}>>,
            getState: TGetState,
            setState: TSetState,
        ) =>
            obs$.subscribe((payload) =>
                reduceDataToStateUpdate<TActionPayload<"CRM_CASE_ENQUIRIES_ENQUIRY_ATTACHMENT_CHANGE", {resourceId: string; value: TCaseEnquiryAttachmentFormList["forms"][number]}>>(setState)(
                    setWhere<TActionPayload<"CRM_CASE_ENQUIRIES_ENQUIRY_ATTACHMENT_CHANGE", {resourceId: string; value: TCaseEnquiryAttachmentFormList["forms"][number]}>>()(
                        ["activeData", "crm", "legalCaseEnquiries", "enquiryAttachments", "forms"],
                        (form, p) => form.ui.enquiryId === p.payload.resourceId,
                        (_, p) => p.payload.value,
                    ),
                )(payload),
            ),
    },
    {
        type: "CRM_CASE_ENQUIRIES_ENQUIRY_ATTACHMENT_SUBMIT" as const,
        run: (
            obs$: Observable<TActionPayload<"CRM_CASE_ENQUIRIES_ENQUIRY_ATTACHMENT_SUBMIT", string>>,
            getState: TGetState,
            setState: TSetState,
        ) =>
            submitFormWhere<"CRM_CASE_ENQUIRIES_ENQUIRY_ATTACHMENT_SUBMIT", string, {}, {}, {}>()(
                ["activeData", "crm", "legalCaseEnquiries", "enquiryAttachments", "forms"],
                (form, enquiryId) => form.ui.enquiryId === enquiryId,
                (form, enquiryId) => `/v1/cases/${getState().routes.params.caseId}/enquiries/${enquiryId}/attach-items`,
                "POST",
                CaseEnquiryAttachments1.validator,
                undefined,
                () => {
                    makeEnquiriesRequest(setState, getState).subscribe();
                }
            )(obs$, getState, setState).subscribe(),
    },
    {
        type: "CRM_CASE_ENQUIRIES_NEW_NOTE_CHANGE" as const,
        run: (
            obs$: Observable<TActionPayload<"CRM_CASE_ENQUIRIES_NEW_NOTE_CHANGE", {resourceId: string; value: TCaseEnquiryNote1FormList["forms"][number]}>>,
            getState: TGetState,
            setState: TSetState,
        ) =>
            obs$.subscribe((payload) =>
                reduceDataToStateUpdate<TActionPayload<"CRM_CASE_ENQUIRIES_NEW_NOTE_CHANGE", {resourceId: string; value: TCaseEnquiryNote1FormList["forms"][number]}>>(setState)(
                    setWhere<TActionPayload<"CRM_CASE_ENQUIRIES_NEW_NOTE_CHANGE", {resourceId: string; value: TCaseEnquiryNote1FormList["forms"][number]}>>()(
                        ["activeData", "crm", "legalCaseEnquiries", "enquiryNewNotes", "forms"],
                        (form, p) => form.ui.enquiryId === p.payload.resourceId,
                        (_, p) => p.payload.value,
                    ),
                )(payload),
            ),
    },
    {
        type: "CRM_CASE_ENQUIRIES_NEW_NOTE_SUBMIT" as const,
        run: (
            obs$: Observable<TActionPayload<"CRM_CASE_ENQUIRIES_NEW_NOTE_SUBMIT", string>>,
            getState: TGetState,
            setState: TSetState,
        ) =>
            submitFormWhere<"CRM_CASE_ENQUIRIES_NEW_NOTE_SUBMIT", string, {}, {}, {}>()(
                ["activeData", "crm", "legalCaseEnquiries", "enquiryNewNotes", "forms"],
                (form, enquiryId) => form.ui.enquiryId === enquiryId,
                (form, enquiryId) => `/v1/cases/${getState().routes.params.caseId}/enquiries/${enquiryId}/notes`,
                "POST",
                CaseEnquiryNote1.validator,
                undefined,
                (_, res) => res.tag === "204"
                    ? makeEnquiriesRequest(setState, getState).subscribe()
                    : undefined,
            )(obs$, getState, setState).subscribe(),
    },
    {
        type: "CRM_CASE_ENQUIRIES_ENQUIRY_TIMELINE_CHANGE" as const,
        run: (
            obs$: Observable<TActionPayload<"CRM_CASE_ENQUIRIES_ENQUIRY_TIMELINE_CHANGE", {value: TCaseEnquiryTimeline1ReadOnlyForm.T}>>,
            getState: TGetState,
            setState: TSetState,
        ) =>
            obs$.subscribe((payload) =>
                reduceDataToStateUpdate<TActionPayload<"CRM_CASE_ENQUIRIES_ENQUIRY_TIMELINE_CHANGE", {value: TCaseEnquiryTimeline1ReadOnlyForm.T}>>(setState)(
                    setWhere<TActionPayload<"CRM_CASE_ENQUIRIES_ENQUIRY_TIMELINE_CHANGE", {value: TCaseEnquiryTimeline1ReadOnlyForm.T}>>()(
                        ["activeData", "crm", "legalCaseEnquiries", "enquiryTimelines", "forms"],
                        (form, p) => form.view._tag === p.payload.value.view._tag && form.view.payload.id === p.payload.value.view.payload.id,
                        (_, p) => p.payload.value,
                    ),
                )(payload),
            ),
    },
    {
        type: "CRM_CASE_ENQUIRIES_ENQUIRY_TIMELINE_DELETE" as const,
        run: (
            obs$: Observable<TActionPayload<"CRM_CASE_ENQUIRIES_ENQUIRY_TIMELINE_DELETE", TCaseEnquiryTimeline1ReadOnlyForm.T>>,
            getState: TGetState,
            setState: TSetState,
        ) =>
            submitFormWhere<"CRM_CASE_ENQUIRIES_ENQUIRY_TIMELINE_DELETE", TCaseEnquiryTimeline1ReadOnlyForm.T, {}, {}, {}>()(
                ["activeData", "crm", "legalCaseEnquiries", "enquiryTimelines", "forms"],
                (form, payload) => form.view._tag === payload.view._tag && form.view.payload.id === payload.view.payload.id,
                (form) =>
                     pipe(
                        form,
                        TCaseEnquiryTimeline1ReadOnlyForm.fold(({
                            onCaseEnquiryNote5: (timelineNoteVariant) => `/v1/cases/${getState().routes.params.caseId}/enquiries/${timelineNoteVariant.ui.enquiryId}/notes/${timelineNoteVariant.view.payload.id}`,
                            onCaseEnquiryDocument3: (timelineDocumentVariant) => `/v1/cases/${getState().routes.params.caseId}/enquiries/${timelineDocumentVariant.ui.enquiryId}/attachments/documents/${timelineDocumentVariant.view.payload.id}`,
                            onLegalEmail4: (timelineEmailVariant) => `/v1/cases/${getState().routes.params.caseId}/enquiries/${timelineEmailVariant.ui.enquiryId}/attachments/emails/${timelineEmailVariant.view.payload.id}`,
                        })),
                    ),
                "DELETE",
                () => option.none,
                undefined,
                (_, res) => res.tag === "204"
                    ? makeEnquiriesRequest(setState, getState).subscribe()
                    : undefined,
            )(obs$, getState, setState).subscribe(),
    },
    {
        type: "CRM_CASE_ENQUIRIES_DOCUMENT_CHANGE" as const,
        run: (
            obs$: Observable<TActionPayload<"CRM_CASE_ENQUIRIES_DOCUMENT_CHANGE", TUnpackFormActionPayload<TCaseDocument6AndCaseDocumentForm3FormList["forms"][number]>>>,
            getState: TGetState,
            setState: TSetState,
        ) =>
            obs$.pipe(
                rxjsOperators.mergeMap((obs) =>
                    setFormWhere<"CRM_CASE_ENQUIRIES_DOCUMENT_CHANGE", TCaseDocument6AndCaseDocumentForm3FormList["forms"][number]>()(
                        ["activeData", "crm", "legalCaseEnquiries", "documents", "forms"],
                        (form, action) => form.view.id === action.resourceId,
                    )(of(obs), getState, setState)
                        .pipe(
                            rxjsOperators.mapTo(obs),
                        ),
                ),
                rxjsOperators.debounceTime(350),
                rxjsOperators.mergeMap((obs) =>
                    submitFormWhere<"CRM_CASE_ENQUIRIES_DOCUMENT_CHANGE", TUnpackFormActionPayload<TCaseDocument6AndCaseDocumentForm3FormList["forms"][number]>, TCaseDocument6AndCaseDocumentForm3FormList["forms"][number]["view"], TCaseDocument6AndCaseDocumentForm3FormList["forms"][number]["edit"], TCaseDocument6AndCaseDocumentForm3FormList["forms"][number]["ui"]>()(
                        ["activeData", "crm", "legalCaseEnquiries", "documents", "forms"],
                        (form, action) => form.view.id === action.resourceId,
                        (form) => `/v1/cases/${form.view.case_id}/documents/${form.view.id}`,
                        "PATCH",
                        () => option.none,
                        undefined,
                    )(of(obs), getState, setState),
                ),
            ).subscribe(),
    },
];

const makeEnquiriesRequest = (setState: TSetState, getState: TGetState): Observable<void> => {
    const filter = getState().activeData.crm.legalCaseEnquiries.enquiryFilter.edit.filter;
    return from(
        fetchWrapper.json<CaseEnquiriesResponse1.T>({
            requestParams: {
                url: `
                    ${env.REACT_APP_API_URL}/v1/cases/${getState().routes.params.caseId}/enquiries${
                        filter === "all" ? ""
                        : filter === "approved" ? `?filters=${encodeURIComponent(JSON.stringify(CaseEnquiriesFilter1.createForCasesEnquiriesStatuses(["approved_unsent", "approved_sent"])))}`
                        : `?filters=${encodeURIComponent(JSON.stringify(CaseEnquiriesFilter1.createForCasesEnquiriesStatus(filter)))}`

                    }
                `,
                method: "GET",
                body: option.none,
            },
            expectedTypeCodec: CaseEnquiriesResponse1.codec,
            defaultResponse: CaseEnquiriesResponse1.newDefault(),
        })(),
    ).pipe(
        rxjsOperators.tap(util.defaultCRMRequestErrorHandler),
        rxjsOperators.tap(
            reduceDataToStateUpdate<FirstPartyFetchResponse.T<CaseEnquiriesResponse1.T>>(setState)(
                set<FirstPartyFetchResponse.T<CaseEnquiriesResponse1.T>>()(
                    ["activeData", "crm", "legalCaseEnquiries", "enquiries"],
                    (formList, res) => TForm.requestToFormList<TCaseEnquiry5FormList["forms"][number]>(formList, res),
                ),
                set<FirstPartyFetchResponse.T<CaseEnquiriesResponse1.T>>()(
                    ["activeData", "crm", "legalCaseEnquiries", "enquiryAttachments"],
                    (formList, res): TCaseEnquiryAttachmentFormList => ({
                        ...formList,
                        status: "untouched",
                        forms:  pipe(
                            res.response.data,
                            array.map((enquiry) =>
                                TForm.defaultTFormV2(CaseEnquiryAttachments1.newDefault(), CaseEnquiryAttachments1.newDefault(), {
                                    enquiryId: enquiry.id,
                                    isModalOpen: false,
                                })
                            ),
                        ),
                    })
                ),
                set<FirstPartyFetchResponse.T<CaseEnquiriesResponse1.T>>()(
                    ["activeData", "crm", "legalCaseEnquiries", "enquiryNewNotes"],
                    (formList, res): TCaseEnquiryNote1FormList => ({
                        ...formList,
                        status: "untouched",
                        forms:  pipe(
                            res.response.data,
                            array.map((enquiry) =>
                                TForm.defaultTFormV2(CaseEnquiryNote1.newDefault(), CaseEnquiryNote1.newDefault(), {
                                    enquiryId: enquiry.id,
                                    isModalOpen: false,
                                })
                            ),
                        ),
                    })
                ),
                set<FirstPartyFetchResponse.T<CaseEnquiriesResponse1.T>>()(
                    ["activeData", "crm", "legalCaseEnquiries", "enquiryTimelines"],
                    (formList, res): TCaseEnquiryTimeline1ReadOnlyFormList => ({
                        ...formList,
                        status: "requiresSubmission",
                        forms:  pipe(
                            res.response.data,
                            array.map((enquiry) =>
                                 pipe(
                                    enquiry.timeline,
                                    array.map((timeline): TCaseEnquiryTimeline1ReadOnlyForm.T => ({
                                        ...TForm.defaultTFormV2(timeline, {}, {
                                            enquiryId: enquiry.id,
                                            isDeleteModalOpen: false,
                                        }),
                                        status: "requiresSubmission",
                                    })),
                                )
                            ),
                            array.flatten,
                        ),
                    })
                ),
            )
        ),
        rxjsOperators.mapTo(undefined),
    );
};

const makeDocumentsRequest = (setState: TSetState, getState: TGetState): Observable<void> =>
    from(
        fetchWrapper.json<CaseDocumentsResponse1.T>({
            requestParams: {
                url: `${env.REACT_APP_API_URL}/v1/cases/${getState().routes.params.caseId}/documents?filters=${encodeURIComponent(JSON.stringify(CaseDocumentsFilter1.createForReceivedDateNotNull()))}`,
                method: "GET",
                body: option.none,
            },
            expectedTypeCodec: CaseDocumentsResponse1.codec,
            defaultResponse: CaseDocumentsResponse1.newDefault(),
        })(),
    ).pipe(
        rxjsOperators.tap(util.defaultCRMRequestErrorHandler),
        rxjsOperators.tap(
            reduceDataToStateUpdate<FirstPartyFetchResponse.T<CaseDocumentsResponse1.T>>(setState)(
                set<FirstPartyFetchResponse.T<CaseDocumentsResponse1.T>>()(
                    ["activeData", "crm", "legalCaseEnquiries", "documents"],
                    (formList, res) => TForm.requestToFormList<TCaseDocument6AndCaseDocumentForm3FormList["forms"][number]>(formList, res),
                )
            )
        ),
        rxjsOperators.mapTo(undefined),
    );

const makeGetCaseRequest = (setState: TSetState, getState: TGetState): Observable<void> =>
    from(
        fetchWrapper.json<CaseResponse1.T>({
            requestParams: {
                url: `${env.REACT_APP_API_URL}/v1/cases/${getState().routes.params.caseId}`,
                method: "GET",
                body: option.none,
            },
            expectedTypeCodec: CaseResponse1.codec,
            defaultResponse: CaseResponse1.newDefault(),
        })(),
    ).pipe(
        rxjsOperators.tap(util.defaultCRMRequestErrorHandler),
        rxjsOperators.tap(
            reduceDataToStateUpdate<FirstPartyFetchResponse.T<CaseResponse1.T>>(setState)(
                set<FirstPartyFetchResponse.T<CaseResponse1.T>>()(
                    ["activeData", "crm", "legalCaseEnquiries", "caseForm"],
                    (form, res) => TForm.dataToForm<TCase2ReadOnlyForm>({}, {})(res.response.data),
                ),
            )
        ),
        rxjsOperators.mapTo(undefined),
    );
