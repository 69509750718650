import React from "react";
import { useCountdown } from "../../../hooks/useCountdown";
import { CRMColors } from "../../../models/CRMColors";
import { CRMProgressBar } from "../CRMProgressBar/CRMProgressBar";

type TCountdownBarProps = {
    duration: number;
    isPaused?: boolean;
    onCountdownComplete?: () => void;
};

export const CRMCountdownBar = (props: React.PropsWithChildren<TCountdownBarProps>): JSX.Element => {
    
    const { progress } = useCountdown({
        startingAmount: 100,
        durationInMs: props.duration,
        framesPerSecond: 8,
        isPaused: props.isPaused || false,
        onCountdownComplete: props.onCountdownComplete,
    });

    return (
        <CRMProgressBar
            height="6px"
            progress={progress}
            fullBarColor={CRMColors.HIGHLIGHTS_POSITIVE_GREEN_0}
            emptyBarColor={CRMColors.NEUTRAL_12}
        />
    );
};
