import React, { useState, useEffect } from "react";
import { SpacingColumn } from "../../components/BuildingBlocks/SpacingColumn";
import { SpacingRow } from "../../components/BuildingBlocks/SpacingRow";
import { CRMRadioTab } from "../../components/CRMRadioTab/CRMRadioTab";
import { CRMButtonIcon } from "../../components/CRMButtonIcon/CRMButtonIcon";
import { CRMFormButtonIconWithLabel } from "../../components/CRMFormButtonIconWithLabel/CRMFormButtonIconWithLabel";
import { CRMParagraph } from "../../components/Simple/CRMParagraph/CRMParagraph";
import { CRMPadding } from "../../components/Simple/CRMPadding/CRMPadding";
import { TextCenter } from "../../components/TextCenter/TextCenter";
import { WeightBold } from "../../components/WeightBold/WeightBold";
import { CRMCodecEditForm } from "../../components/CRMCodecEditForm/CRMCodecEditForm";
import CRMInputLabelAndErrorWrapComponent from "../../components/CRMInputLabelAndErrorWrapComponent/CRMInputLabelAndErrorWrapComponent";
import { CRMInputPhoneNumbers } from "../../components/CRMInputPhoneNumbers/CRMInputPhoneNumbers";
import { TContainerStateProps } from "../../state/TContainerStateProps";
import { castNewFormStatusToLegacy } from "../../util";
import { ListingSellerCompany } from "../../../../domain/codecs/Listing";
import { CRMHorizontalSeperatorLine } from "../../components/CRMHorizontalSeperatorLine/CRMHorizontalSeperatorLine";
import { CRMSpacer } from "../../components/CRMSpacer/CRMSpacer";
import { CRMAutosaveIndicatorWrap } from "../../components/CRM/CRMAutosaveIndicatorWrap/CRMAutosaveIndicatorWrap";
import { CRMCardInside } from "../../components/CRMCardInside/CRMCardInside";
import CRMInputGeneralComponent from "../../components/CRMInputs/CRMInputGeneralComponent/CRMInputGeneralComponent";
import { TListingSellerSuggestedSimilarUserForm, TListingSellerUserEditForm } from "../../../../domain/codecs/form/ListingSellerUserEditForm";
import { CRMSpacing } from "../../models/CRMSpacing";
import { Position } from "../../components/BuildingBlocks/Position";
import { CRMYesNoBooleanRadioFormInput } from "../../components/CRMFormInputs/CRMYesNoBooleanRadioFormInput";
import { doesErrorKeyExist } from "../../../../shared/src/codecs/errors";
import { CRMDateTimeOrNullYesNoRadioFormInput } from "../../components/CRMFormInputs/CRMDateTimeOrNullYesNoRadioFormInput";
import { CRMDeferInputWithoutLabelFormInput } from "../../components/CRMFormInputs/CRMDeferInputWithoutLabelFormInput";
import { CRMZIndex } from "../../models/CRMZIndex";
import { pipe } from "fp-ts/lib/function";
import { array, ord } from "fp-ts";
import { CRMClientSuggestion } from "../../components/CRMClientSuggestion/CRMClientSuggestion";
import { TUnpackEditable, editFormEditableValue } from "../../../../shared/src/codecs/types/formEditable";
import { Ord } from "fp-ts/lib/boolean";

export const CRMListingSellerPartyFormContainer = (props: TContainerStateProps): JSX.Element => {
    const [selectedMemberIndex, setSelectedMemberIndex] = useState(0);
    const [inputShowingSuggestions, setInputShowingSuggestions] = useState<"first_name" | "last_name" | "email" | "none">("none");
    const [suggestionInputInFocus, setSuggestionInputInFocus] = useState<"first_name" | "last_name" | "email" | "none">("none");

    const getSelectedPartyMember = (): TListingSellerUserEditForm => props.state.forms.listing_page.data.output.listing_seller_users[selectedMemberIndex];

    const updateListingSellerPartyFormValue = <K extends keyof TUnpackEditable<TListingSellerUserEditForm>>(key: K, value: TListingSellerUserEditForm["edited"][K]) => props.dispatch({
        type: "LISTING_PAGE_UPDATE_SELLER_USER",
        payload: props.state.forms.listing_page.data.output.listing_seller_users.map((member, index) => {
            if (index === selectedMemberIndex) {
                return {...editFormEditableValue(member, key, value)};
            }
            return member;
        })
    })

    useEffect(
        () => {
            if (selectedMemberIndex > props.state.forms.listing_page.data.output.listing_seller_users.length-1) {
                setSelectedMemberIndex(selectedMemberIndex-1 < 0 ? 0 : selectedMemberIndex-1);
            }
        },
        [props.state.forms.listing_page.data.output.listing_seller_users.length]
    );

    return (
        <div 
            onMouseLeave={() => setTimeout(() => 
                {setInputShowingSuggestions("none")}
                ,1000
            )}
            onMouseEnter={() => {
                if (inputShowingSuggestions === "none" && suggestionInputInFocus !== "none") {
                    setInputShowingSuggestions(suggestionInputInFocus);
                }
            }}
        >
            <CRMCardInside>
                <CRMPadding size="medium">
                    <CRMParagraph>
                        NB: Editing the client on all listings.
                    </CRMParagraph>
                </CRMPadding>
            </CRMCardInside>
            <CRMSpacer size="medium" />
            <SpacingRow
                spacing={CRMSpacing.LARGE}
                alignItems="flex-start"
                childSize="2fr 1fr"
            >
                <Position position="relative">
                        {/* ADD PARTY MEMBER BUTTON */}
                        {props.state.forms.listing_page.data.output.listing_seller_users.length === 0 &&
                            <CRMPadding size="tiny">
                                <TextCenter>
                                    <CRMParagraph>
                                        Add <WeightBold>a new person</WeightBold> to get started.
                                    </CRMParagraph>
                                </TextCenter>
                            </CRMPadding>
                        }

                        {/* USER FORM */}
                        {props.state.forms.listing_page.data.output.listing_seller_users.length > 0 && selectedMemberIndex <= props.state.forms.listing_page.data.output.listing_seller_users.length-1 &&
                            <SpacingColumn spacing={CRMSpacing.MEDIUM}>

                                {/* FIRST NAME */}
                                <Position position="relative">
                                    <CRMInputLabelAndErrorWrapComponent label="First name">
                                        <CRMInputGeneralComponent
                                            inputType="text"
                                            displayError={doesErrorKeyExist("edited.first_name", getSelectedPartyMember().validationErrors)}
                                            placeholder="John"
                                            value={getSelectedPartyMember().edited.first_name}
                                            onChange={(value) => updateListingSellerPartyFormValue("first_name", value)}
                                            onFocus={() => {
                                                setInputShowingSuggestions("first_name");
                                                setSuggestionInputInFocus("first_name");
                                            }}
                                            onBlur={() => {
                                                setSuggestionInputInFocus("none");
                                            }}
                                        />
                                    </CRMInputLabelAndErrorWrapComponent>
                                    {inputShowingSuggestions === "first_name" && <CRMListingSellerPartyMemberFormSuggestions
                                        member={getSelectedPartyMember()}
                                        onSwapMember={(f) => {
                                            props.dispatch({
                                                type: "LISTING_PAGE_SWAP_SELLER_PARTY_MEMBER",
                                                payload: f
                                            })
                                        }}
                                    />}
                                </Position>

                                {/* LAST NAME */}
                                <Position position="relative">
                                    <CRMInputLabelAndErrorWrapComponent label="Last name">
                                        <CRMInputGeneralComponent
                                            inputType="text"
                                            displayError={doesErrorKeyExist("edited.last_name", getSelectedPartyMember().validationErrors)}
                                            placeholder="Doe"
                                            value={getSelectedPartyMember().edited.last_name}
                                            onChange={(value) => updateListingSellerPartyFormValue("last_name", value)}
                                            onFocus={() => {
                                                setInputShowingSuggestions("last_name");
                                                setSuggestionInputInFocus("last_name");
                                            }}
                                            onBlur={() => {
                                                setSuggestionInputInFocus("none");
                                            }}
                                        />
                                    </CRMInputLabelAndErrorWrapComponent>
                                    {inputShowingSuggestions === "last_name" && <CRMListingSellerPartyMemberFormSuggestions
                                        member={getSelectedPartyMember()}
                                        onSwapMember={(f) => {
                                            props.dispatch({
                                                type: "LISTING_PAGE_SWAP_SELLER_PARTY_MEMBER",
                                                payload: f
                                            })
                                        }}
                                    />}
                                </Position>
                                
                                {/* EMAIL */}
                                <Position position="relative">
                                    <CRMInputLabelAndErrorWrapComponent label="Email">
                                        <CRMInputGeneralComponent
                                            inputType="email"
                                            displayError={doesErrorKeyExist("edited.email", getSelectedPartyMember().validationErrors)}
                                            placeholder="john@doe.com"
                                            value={getSelectedPartyMember().edited.email || ""}
                                            onChange={(value) => updateListingSellerPartyFormValue("email", value || null)}
                                            onFocus={() => {
                                                setInputShowingSuggestions("email");
                                                setSuggestionInputInFocus("email");
                                            }}
                                            onBlur={() => {
                                                setSuggestionInputInFocus("none");
                                            }}
                                        />
                                    </CRMInputLabelAndErrorWrapComponent>
                                    {inputShowingSuggestions === "email" && <CRMListingSellerPartyMemberFormSuggestions
                                        member={getSelectedPartyMember()}
                                        onSwapMember={(f) => {
                                            props.dispatch({
                                                type: "LISTING_PAGE_SWAP_SELLER_PARTY_MEMBER",
                                                payload: f
                                            })
                                        }}
                                    />}
                                </Position>
                                
                                {/* DESCRIPTION OF USER */}
                                <CRMInputLabelAndErrorWrapComponent label="Description of seller party member">
                                    <CRMInputGeneralComponent
                                        inputType="text"
                                        displayError={doesErrorKeyExist("edited.description_of_user", getSelectedPartyMember().validationErrors)}
                                        value={getSelectedPartyMember().edited.description_of_user}
                                        onChange={(value) => updateListingSellerPartyFormValue("description_of_user", value)}
                                    />
                                </CRMInputLabelAndErrorWrapComponent>

                                {/* LEGAL FIRST NAME */}
                                <CRMInputLabelAndErrorWrapComponent label="Legal first name">
                                    <CRMInputGeneralComponent
                                        inputType="text"
                                        displayError={doesErrorKeyExist("edited.homes_first_legal_name", getSelectedPartyMember().validationErrors)}
                                        value={getSelectedPartyMember().edited.homes_first_legal_name}
                                        onChange={(value) => updateListingSellerPartyFormValue("homes_first_legal_name", value)}
                                    />
                                </CRMInputLabelAndErrorWrapComponent>
                                
                                {/* LEGAL MIDDLE NAMES */}
                                <CRMInputLabelAndErrorWrapComponent label="Legal middle name(s)">
                                    <CRMInputGeneralComponent
                                        inputType="text"
                                        displayError={doesErrorKeyExist("edited.homes_middle_legal_name", getSelectedPartyMember().validationErrors)}
                                        value={getSelectedPartyMember().edited.homes_middle_legal_name}
                                        onChange={(value) => updateListingSellerPartyFormValue("homes_middle_legal_name", value)}
                                    />
                                </CRMInputLabelAndErrorWrapComponent>
                                
                                {/* LEGAL LAST NAME */}
                                <CRMInputLabelAndErrorWrapComponent label="Legal last name">
                                    <CRMInputGeneralComponent
                                        inputType="text"
                                        displayError={doesErrorKeyExist("edited.homes_last_legal_name", getSelectedPartyMember().validationErrors)}
                                        value={getSelectedPartyMember().edited.homes_last_legal_name}
                                        onChange={(value) => updateListingSellerPartyFormValue("homes_last_legal_name", value)}
                                    />
                                </CRMInputLabelAndErrorWrapComponent>

                                {/* IS LEGAL SELLER */}
                                <CRMYesNoBooleanRadioFormInput
                                    label="Is legal seller"
                                    displayError={doesErrorKeyExist("edited.is_legal_seller", getSelectedPartyMember().validationErrors)}
                                    model={getSelectedPartyMember().edited.is_legal_seller as boolean}
                                    onChange={(value) => updateListingSellerPartyFormValue("is_legal_seller", value)}
                                />
                                
                                {/* ID GOP SEEN & GOOD */}
                                <CRMDateTimeOrNullYesNoRadioFormInput
                                    label="ID or GOP seen and confirmed good"
                                    displayError={doesErrorKeyExist("edited.homes_id_or_gop_seen_and_confirmed_good", getSelectedPartyMember().validationErrors)}
                                    model={getSelectedPartyMember().edited.homes_id_or_gop_seen_and_confirmed_good}
                                    onChange={(value) => updateListingSellerPartyFormValue("homes_id_or_gop_seen_and_confirmed_good", value)}
                                />

                                {/* DEFER SIGN OFF */}
                                <CRMDeferInputWithoutLabelFormInput
                                    label=""
                                    displayError={doesErrorKeyExist("edited.homes_id_sign_off_deferred", getSelectedPartyMember().validationErrors)}
                                    model={getSelectedPartyMember().edited.homes_id_sign_off_deferred as string | null}
                                    onChange={(value) => updateListingSellerPartyFormValue("homes_id_sign_off_deferred", value)}
                                />

                                {/* IS DECEASED */}
                                <CRMYesNoBooleanRadioFormInput
                                    label="Is deceased"
                                    displayError={doesErrorKeyExist("edited.is_deceased", getSelectedPartyMember().validationErrors)}
                                    model={getSelectedPartyMember().edited.is_deceased as boolean}
                                    onChange={(value) => updateListingSellerPartyFormValue("is_deceased", value)}
                                />

                                <CRMInputLabelAndErrorWrapComponent label="Phone numbers">
                                    <CRMInputPhoneNumbers
                                        placeholder="Add phone number"
                                        phoneNumbers={props.state.forms.listing_page.data.output.listing_seller_users[selectedMemberIndex].children.phone_numbers}
                                        createPhoneNumberStatus={
                                            props.state.forms.listing_page.data.output.listing_seller_user_create.status === "untouched"
                                            ? "requiresSubmission"
                                            : props.state.forms.listing_page.data.output.listing_seller_user_create.status
                                        }
                                        showVerificationStatus={false}
                                        onAddNew={() =>
                                            props.dispatch({
                                                type: "LISTING_PAGE_ADD_PHONE_NUMBER_TO_SELLER_USER",
                                                payload: props.state.forms.listing_page.data.output.listing_seller_users[selectedMemberIndex].original.id
                                            })
                                        }
                                        onRemove={(phoneNumber) => props.dispatch({
                                            type: "LISTING_PAGE_REMOVE_PHONE_NUMBER_FROM_SELLER_USER",
                                            payload: phoneNumber
                                        })}
                                        onChange={(phoneNumbers) =>
                                            props.dispatch({
                                                type: "LISTING_PAGE_UPDATE_SELLER_USER_PHONE_NUMBER",
                                                payload: phoneNumbers
                                            })
                                        }
                                    />
                                </CRMInputLabelAndErrorWrapComponent>
                            </SpacingColumn>
                        }
                </Position>
                
                {/* MEMBER TABS */}
                <SpacingColumn spacing={CRMSpacing.MEDIUM}>
                    {props.state.forms.listing_page.data.output.listing_seller_users.map((member, index) => (
                        <CRMRadioTab
                            key={index}
                            isSelected={selectedMemberIndex === index}
                            label={`${member.edited.first_name} ${member.edited.last_name.charAt(0)}${member.edited.last_name !== "" ? "." : ""}`}
                            onClick={() => setSelectedMemberIndex(index)}
                            options={[
                                {
                                    label: "Remove from party",
                                    onClick: () => props.dispatch({
                                        type: "LISTING_PAGE_DELETE_SELLER",
                                        payload: member.children.delete_form
                                    }),
                                },
                            ]}
                        />
                    ))}
                    <CRMFormButtonIconWithLabel
                        formStatus={
                            castNewFormStatusToLegacy(props.state.forms.listing_page.data.output.listing_seller_user_create.status) === "untouched"
                            ? "requiresSubmission"
                            : castNewFormStatusToLegacy(props.state.forms.listing_page.data.output.listing_seller_user_create.status)
                        }
                        ButtonElement={(buttonProps) => <CRMButtonIcon variant="primary" {...buttonProps} />}
                        icon="person-add"
                        label="Add new person"
                        onClick={() => props.dispatch({
                            type: "LISTING_PAGE_ADD_SELLER"
                        })}
                    />
                </SpacingColumn>
            </SpacingRow>

            <CRMSpacer size="large" />
            <CRMHorizontalSeperatorLine />
            <CRMSpacer size="large" />

            <CRMAutosaveIndicatorWrap status={props.state.forms.listing_page.data.output.form.status}>
                <SpacingRow
                    spacing={CRMSpacing.LARGE}
                    alignItems="flex-start"
                    childSize="2fr 1fr"
                >
                    <div>
                        <CRMCodecEditForm
                            codec={ListingSellerCompany}
                            model={props.state.forms.listing_page.data.output.form.edited}
                            validationErrors={props.state.forms.listing_page.data.output.form.validationErrors}
                            onChange={(edited) => props.dispatch({
                                type: "LISTING_PREPARATION_FORM_CHANGE",
                                payload: {
                                    ...props.state.forms.listing_page.data.output.form,
                                    edited: {
                                        ...props.state.forms.listing_page.data.output.form.edited,
                                        ...edited,
                                    },
                                },
                            })}
                        />
                    </div>
                    <div></div>
                </SpacingRow>
            </CRMAutosaveIndicatorWrap>
        </div>
    );
};

const CRMListingSellerPartyMemberFormSuggestions = (props: {
    member: TListingSellerUserEditForm,
    onSwapMember: (form: TListingSellerSuggestedSimilarUserForm) => void
}): JSX.Element =>
    props.member.children.suggested_similar_users.length > 0
        ? <div
            style={{
                position: "absolute",
                top: "60px",
                zIndex: CRMZIndex.THIRD_Z,
                width: "300px",
            }} 
        >
            <SpacingColumn spacing={CRMSpacing.TINY}>
                {pipe(
                    props.member.children.suggested_similar_users,
                    // sorts so that users with null email address are suggested last
                    array.sortBy([ord.contramap((suggestedSimilarUser: TListingSellerSuggestedSimilarUserForm) => typeof suggestedSimilarUser.children.email === "string")(Ord)]),
                    array.reverse,
                    array.mapWithIndex((i, form) =>
                        <CRMClientSuggestion
                            context="listing-seller-party"
                            key={i}
                            form={form}
                            onUse={(f) => props.onSwapMember(f as TListingSellerSuggestedSimilarUserForm)}
                        />
                    ),
                )}
            </SpacingColumn>
        </div>
        : <></>;