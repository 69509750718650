import React from "react";

type TStrikethroughProps = {
};

export const Strikethrough = (props: React.PropsWithChildren<TStrikethroughProps>): JSX.Element => {
    return (
        <div style={{ textDecoration: "line-through" }}>
            {props.children}
        </div>
    );
};
