import React, { useState } from "react";
import { TCaseSignOffAsReadyForExchangeForm } from "../../../../domain/codecs/form/CaseForm";
import { TTransactionType1 } from "../../../../domain/codecs/TransactionType";
import { CRMButtonPrimary } from "../CRMButtonPrimary/CRMButtonPrimary";
import { CRMCaseSignOffAsReadyForExchangePopupComponent } from "./CRMCaseSignOffAsReadyForExchangePopupComponent";

export const CRMCaseSignOffAsReadyForExchangeComponent = (props: React.PropsWithChildren<{
    form: TCaseSignOffAsReadyForExchangeForm;
    transactionType: TTransactionType1,
    onSignOff: () => void;
    onCancel: () => void;
}>): JSX.Element => {
    const [isPopupOpen, setPopupOpen] = useState<boolean>(false);

    const openPopup = () => setPopupOpen(true);

    const closePopup = () => {
        setPopupOpen(false);
        props.onCancel();
    };

    return (
        <>
            {/* Button */}
            <div>
                <CRMButtonPrimary
                    fullWidth={false}
                    icon="done-all"
                    label="Sign off as ready for exchange"
                    onClick={openPopup}
                />
            </div>

            {/* Popup */}
            {isPopupOpen &&
                <CRMCaseSignOffAsReadyForExchangePopupComponent
                    form={props.form}
                    transactionType={props.transactionType}
                    onSignOff={props.onSignOff}
                    onClose={closePopup}
                />
            }
        </>
    );
};
