import React, { useState } from "react";
import { Editor, EditorState, Modifier, RichUtils, getDefaultKeyBinding } from 'draft-js';
import { convertFromHTML, convertToHTML } from "draft-convert";
import { pipe } from "fp-ts/lib/pipeable";
import { array } from "fp-ts";
import { SpacingRow } from "../../BuildingBlocks/SpacingRow";
import { CRMIcon } from "../../CRMIcon/CRMIcon";
import { Padding } from "../../BuildingBlocks/Padding";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { CRMEmptyPlaceholder } from "../../Simple/CRMEmptyPlaceholder/CRMEmptyPlaceholder";
import { CRMFontSizes } from "../../../models/CRMFontSizes";
import { CRMColors } from "../../../models/CRMColors";
import { WeightBold } from "../../WeightBold/WeightBold";
import { DraftJsAutocompleteWrapper, TKeyBindCallback } from './DraftJsAutocompleteWrapper/DraftJsAutocompleteWrapper';
import { CRMRichTextAreaButton, useRichTextAreaControls } from "../../CRMRichTextArea/CRMRichTextAreaControls";
import { TAutocompleteInsertProps, TAutocompleteListItemProps, TAutocompleteListProps } from "./DraftJsAutocompleteWrapper/Types";
import { CRMRichTextAreaEditorWrap, CRMRichTextAreaMainWrap } from "../../CRMRichTextArea/CRMRichTextArea";
import { TChatCommand, TChatCommandString } from "../../../../../domain/codecs/form/ChatsMessageForm";


export type TCommand = 
    TChatCommand 
    & { isExpandable?: boolean }
;

type TRoadblockRichTextAreaProps = {
    placeholder?: string;
    editorState: EditorState;
    commandList: Array<TCommand>;
    onChange: (state: EditorState, contentAsHTML: string) => void;
    onSubmit: (state: EditorState, contentAsHTML: string) => void;
};

export const CRMRoadblockRichTextArea = (props: React.PropsWithChildren<TRoadblockRichTextAreaProps>): JSX.Element => {

    const onChange = (editorState: EditorState) =>
        props.onChange(
            editorState, 
            TextAreaWithCommandsEditorContent__HTML(editorState.getCurrentContent())
        )
    ;

    const {
        isTextFormatBeingUsed,
        onToggleTextFormat,
        isBlockFormatBeingUsed,
        onToggleBlockFormat
    } = useRichTextAreaControls({
        contentState: props.editorState,
        onChange: onChange
    });

    const onMatchCommands = (commands: Array<TCommand>, numberOfCommandsShown: number) => 
        (filterText: string) => {
            if (filterText.length === 0) {
                return getCommandsAsUniqueActions(commands)
                    .filter((command) => doesCommandMatchFilterText(filterText, command))
            }

            return commands
                .filter((command) => doesCommandMatchFilterText(filterText, command))
                .slice(0, numberOfCommandsShown);
        }
    ;

    const getCommandsAsUniqueActions = (commands: Array<TCommand>): Array<TCommand> =>
        pipe(
            commands.map((command) => ({ 
                action: command.action, 
                isExpandable: !!command.target 
            })),
            array.uniq({ 
                equals: (a, b) => a.action === b.action && a.isExpandable === b.isExpandable 
            }),
        )
    ;

    const doesCommandMatchFilterText = (filterText: string, command: TCommand): boolean => {
        let commandAsString = TCommandToTCommandString(command);

        return commandAsString.indexOf(`#${filterText}`) > -1 
            || command.action.indexOf(filterText) > -1
            || !!command.target && command.target.toLowerCase().indexOf(filterText.toLowerCase()) > -1
    }

    const onInsertCommandFullyOrPartially: TKeyBindCallback<TCommand> = (given) => {
       
        if (given.suggestion?.isExpandable !== true) {
            given.event.preventDefault();
            return "add-entity";
        }

        if (given.suggestion?.isExpandable === true) {
            given.event.preventDefault();
            insertCommandActionNameAsTextIntoEditorState(given.suggestion);
            return null;
        }

        return null;
    }

    const insertCommandActionNameAsTextIntoEditorState = (suggestion: TCommand) => {        
        onChange(
            EditorState.push(
                props.editorState, 
                Modifier.insertText(
                    props.editorState.getCurrentContent(), 
                    props.editorState.getSelection(), 
                    `${suggestion.action}:`
                ), 
                'insert-fragment'
            )
        );
    }

    const handleKeyCommand = (command: string, eventEditorState: EditorState) => {
        const newState = RichUtils.handleKeyCommand(eventEditorState, command);

        if (newState) {
            onChange(newState); 
            return 'handled';
        }
        return 'not-handled';
    }

    const onSubmitText = (event) => {
        props.onSubmit(
            props.editorState, 
            TextAreaWithCommandsEditorContent__HTML(props.editorState.getCurrentContent())
        )
    }

    return (
        <CRMRichTextAreaMainWrap>
            <CRMRichTextAreaEditorWrap>
                <DraftJsAutocompleteWrapper<TCommand>
                    placeholder={props.placeholder}
                    editorState={props.editorState}
                    onChange={onChange} 
                    handleKeyCommand={handleKeyCommand}
                    onKeyBindTab={onInsertCommandFullyOrPartially}
                    onKeyBindRightArrow={onInsertCommandFullyOrPartially}
                    onKeyBindEnterDuringAutocomplete={onInsertCommandFullyOrPartially}
                    onKeyBindEnter={onSubmitText}
                    suggestionProfiles={[
                        {
                            prefix: '#',
                            name: 'COMMAND',
                            mutability: "IMMUTABLE",
                            maximumInsertions: 1,
                            hasUniqueInsertions: true,
                            insertedComponent: InsertedCommand,
                            listComponent: SuggestionList,
                            emptyListComponent: ListEmptyPlaceholder,
                            itemComponent: SuggestionItem,
                            // [MISSING FEATURE] 
                            // - We are missing a scroll function to the commands, so that we can support an infinite
                            //   ammount gracefully.
                            // - We cap here at 17 because in a column view
                            //   you wont be able to see more (as the rest will bleed out of the column)
                            onMatch: onMatchCommands(props.commandList, 17),
                            format: TCommandToTCommandString
                        },
                    ]}
                >
                    {/* The draft-js editor types do not treat it's props as optional
                        hence we use the ignore. 
                        We supply draft-js's props to the autocomplete wrapper, since thats how it's meant to work.
                        It has to be typed in the wierd (bellow) way or it wont work in a tsx file.
                    // @ts-ignore */}
                    <Editor />
                </DraftJsAutocompleteWrapper>
            </CRMRichTextAreaEditorWrap>

            {/* FORMATTING BUTTONS */}
            <SpacingRow spacing={"3px"}>
                <CRMRichTextAreaButton
                    title="Bold (keyboard shortcut = CTRL + B)"
                    icon="bold"
                    isEnabled={isTextFormatBeingUsed("BOLD")}
                    onClick={onToggleTextFormat("BOLD")} 
                />
                <CRMRichTextAreaButton
                    title="Italic (keyboard shortcut = CTRL + I)"
                    icon="italic"
                    isEnabled={isTextFormatBeingUsed("ITALIC")}
                    onClick={onToggleTextFormat("ITALIC")}
                />
                <CRMRichTextAreaButton
                    title="Underline (keyboard shortcut = CTRL + U)"
                    icon="underline"
                    isEnabled={isTextFormatBeingUsed("UNDERLINE")}
                    onClick={onToggleTextFormat("UNDERLINE")}
                />
                <CRMRichTextAreaButton
                    title="Bullet list"
                    icon="bullet-list"
                    isEnabled={isBlockFormatBeingUsed("unordered-list-item")}
                    onClick={onToggleBlockFormat("unordered-list-item")}
                />
                <CRMRichTextAreaButton
                    title="Numbered list"
                    icon="number-list"
                    isEnabled={isBlockFormatBeingUsed("ordered-list-item")}
                    onClick={onToggleBlockFormat("ordered-list-item")}
                />
            </SpacingRow>
        </CRMRichTextAreaMainWrap>
    );
};

const InsertedCommand = (props: TAutocompleteInsertProps) => (
    <span className="crm-roadblock-rich-text-area">
        {props.children}
    </span>
);

const SuggestionList = (props: TAutocompleteListProps) => (
    <div className="crm-roadblock-rich-text-area-list">
        <div className="crm-roadblock-rich-text-area-list__box">
            {props.children}
        </div>
    </div>
);

const ListEmptyPlaceholder = () => (
    <Padding spacing={CRMSpacing.MEDIUM}>
        <CRMEmptyPlaceholder 
            iconSize="70px"
            textGap="10px"
            fontSize={CRMFontSizes.SMALL}
            iconColor={CRMColors.NEUTRAL_8}
            fontColor={CRMColors.NEUTRAL_6}
        >
            <WeightBold>No matches</WeightBold>
        </CRMEmptyPlaceholder>
    </Padding>
);

const TCommandToTCommandString = (command: TCommand): TChatCommandString => 
    !!command.target ? `#${command.action}:${command.target}` : `#${command.action}`
;

const SuggestionItem = (props: TAutocompleteListItemProps<TCommand>) => (
    <div
        className={`
            crm-roadblock-rich-text-area-list__item
            crm-roadblock-rich-text-area-list__item--${props.current ? 'highlighted' : 'plain'}
        `} 
        onClick={props.onClick}
    >
        {TCommandToTCommandString(props.item)}
        
        {props.item.isExpandable === true &&
            <CRMIcon 
                iconName="arrow-right" 
                colour="neutral-ink" 
            />
        } 
    </div>
);

export const HTML__TextAreaWithCommandsEditorContent = convertFromHTML({
    htmlToEntity: (nodeName, node, createEntity) => {
        if (nodeName === 'sail-command') {
            return createEntity(
                'COMMAND',
                'IMMUTABLE',
                {}
            )
        }
        return undefined;
    },
})

export const TextAreaWithCommandsEditorContent__HTML = convertToHTML({
    entityToHTML: (entity, originalText) => {
        if (entity.type === "COMMAND") {
            // @ts-ignore
            return (<sail-command>{originalText}</sail-command>);
        }
        return originalText;
    }
});
