import React from "react";
import { array } from "fp-ts";
import { array as extArray } from "../../../../shared/src/utilByDomainGroupExport";
import { CRMSpacing } from "../../models/CRMSpacing";
import { SpacingColumn } from "../BuildingBlocks/SpacingColumn";
import { SpacingRow } from "../BuildingBlocks/SpacingRow";
import { CRMIcon } from "../CRMIcon/CRMIcon";
import { CRMParagraph } from "../Simple/CRMParagraph/CRMParagraph";
import { WeightBold } from "../WeightBold/WeightBold";
import { useOpenClose } from "../../hooks/UseOpenClose";
import { Absolute } from "../BuildingBlocks/Absolute";
import { CRMDropdownOption } from "../Simple/CRMDropdownOption/CRMDropdownOption";
import { CRMPopout } from "../Simple/CRMPopout/CRMPopout";
import { CursorPointer } from "../CursorPointer/CursorPointer";
import { pipe } from "fp-ts/lib/pipeable";
import { TextHightlight } from "../BuildingBlocks/TextHightlight";
import { CRMColors } from "../../models/CRMColors";
import { TextColor } from "../BuildingBlocks/TextColor";
import { Padding } from "../BuildingBlocks/Padding";
import { TAdminUserEditable } from "../../../../domain/codecs/formEditable/AdminUserEditable";
import { CRMFontSizes } from "../../models/CRMFontSizes";
import { SimpleGrid } from "../BuildingBlocks/SimpleGrid";
import { requireExhaustive } from "../../../../shared/src/util";
import { TInternalScope } from "../../../../domain/codecs/InternalScope";
import { TJobType } from "../../../../domain/JobType";

type TJobAssignmentInputProps = {
    description: string;
    type: TJobType;
    users: Array<TAdminUserEditable>;
    onChange: (users: Array<TAdminUserEditable>) => void; 
};

export const CRMJobAssignmentInput = (props: TJobAssignmentInputProps): JSX.Element => {

    const { ref, isOpen, toggleOpen } = useOpenClose();

    const doesUserHaveJob = (user: TAdminUserEditable) => user.edited.jobs.includes(props.type);

    const isJobUnasigned = () => pipe(
        props.users,
        array.filter((user) => user.edited.jobs.includes(props.type)),
        array.isEmpty
    );

    const toggleUserJob = (user: TAdminUserEditable): TAdminUserEditable => ({
        ...user,
        status: "requiresSubmission",
        edited: {
            ...user.edited,
            jobs: doesUserHaveJob(user) ?
                extArray.deleteValue(props.type, user.edited.jobs)
                : user.edited.jobs.concat(props.type)
        }
    });

    const getSuffix = (scope: TInternalScope) => 
    {
        switch (scope) {
            case "sail_legal_authoriser_staff":
            case "sail_legal_non_authoriser_staff":
                return "SL";
            case "admin":
            case "sail_homes_staff":
                return "SH";
            case "user":
                return "USER - SHOULD NOT APPEAR";
        }
        return requireExhaustive(scope)
    }
    

    const getUserName = (user: TAdminUserEditable): string => 
        `${user.original.first_name}. ${user.original.last_name.charAt(0)} (${getSuffix(user.original.user_role)})`
    ;

    return (
        <div
            ref={ref}
            className="crm-job-assignment-input"
            
        >
            {/* CONTENT */}
            <SpacingColumn
                spacing={CRMSpacing.MEDIUM}
                onClick={() => toggleOpen()}
            >
                {/* WHAT IS THE JOB? */}
                <CRMParagraph fontSize={CRMFontSizes.MED}>
                    <WeightBold>
                        {props.description}
                    </WeightBold>
                </CRMParagraph>

                {/* ASSIGNED USERS TO THE JOB */}
                <CursorPointer>
                    <SpacingRow
                        spacing={CRMSpacing.TINY}
                        alignItems="flex-start"
                        onClick={() => toggleOpen()}
                    >
                        {/* ICON: ASSIGN USERS */}
                        <CRMIcon
                            iconName="people"
                            colour="neutral-ink"
                            size="small"
                        />

                        {/* JOB IS UNASSIGNED: WARRNING MESSAGE */}
                        {isJobUnasigned() &&
                            <TextHightlight color={CRMColors.HIGHLIGHTS_INSTRUCTIONAL_BRICK_12}>
                                <Padding type="horizontal" spacing={CRMSpacing.TINY}>
                                    <CRMParagraph>
                                        <TextColor color={CRMColors.HIGHLIGHTS_INSTRUCTIONAL_BRICK_0}>
                                            <WeightBold>
                                                unassigned
                                            </WeightBold>
                                        </TextColor>
                                    </CRMParagraph>
                                </Padding>
                            </TextHightlight>
                        }
                        {!isJobUnasigned() &&
                            <CRMParagraph>
                                {props.users
                                    .filter((user) => user.edited.jobs.includes(props.type))
                                    .map(getUserName)
                                    .join(", ")
                                }
                            </CRMParagraph>
                        }
                    </SpacingRow>
                </CursorPointer>
            </SpacingColumn>

            {/* ASSIGN JOBS TO USERS: ARROW */}
            <Absolute top={CRMSpacing.MEDIUM} right={CRMSpacing.MEDIUM}>
                <CRMIcon
                    iconName={isOpen ? "up-arrow" : "down-arrow"}
                    colour="neutral-ink"
                    size="small"
                    onClick={() => toggleOpen()}
                />
            </Absolute>

            {/* POPOUT: ASSIGN JOB TO USERS MUTLI SELECT */}
            {isOpen &&
                <Absolute
                    top={CRMSpacing.X_LARGE}
                    right={CRMSpacing.MEDIUM}
                    zIndex={1}
                >
                    <CRMPopout>
                        <SimpleGrid templateColumns="1fr 1fr">
                            {props.users.map((user, key) => (
                                <CRMDropdownOption
                                    key={key}
                                    hasCheckbox={true}
                                    isSelected={user.edited.jobs.includes(props.type)}
                                    label={`${user.original.first_name} ${user.original.last_name} (${getSuffix(user.original.user_role)})`}
                                    onClick={(event) => {
                                        event.preventDefault();
                                        props.onChange(
                                            pipe(
                                                props.users,
                                                extArray.updateWhere<TAdminUserEditable>(
                                                    (updateUser) => updateUser.original.id === user.original.id,
                                                    (updateUser) => toggleUserJob(updateUser)
                                                )
                                            )
                                        )
                                    }}
                                />
                            ))}
                        </SimpleGrid>
                    </CRMPopout>
                </Absolute>
            }
        </div>
    );
};
