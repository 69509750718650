import React from "react";
import { TContainerStateProps } from "./../../state/TContainerStateProps";
import { CRMSpacingColumn } from "../../components/CRMSpacingColumn/CRMSpacingColumn";
import { CRMTitleSection } from "../../components/CRMTitleSection/CRMTitleSection";
import { CRMSpacer } from "../../components/CRMSpacer/CRMSpacer";
import { CRMAutosaveIndicatorWrap } from "../../components/CRM/CRMAutosaveIndicatorWrap/CRMAutosaveIndicatorWrap";
import { CRMCodecEditForm } from "../../components/CRMCodecEditForm/CRMCodecEditForm";
import { CaseMortgageOfferRetentionExplainedToClient, CaseMortgageOfferFundsRequestAndReceive, CaseMortgageOfferDetailsWithLeaseholdRequirementsChecked, CaseMortgageOfferMattersToReferWithDetails, CaseMortgageOfferMattersToRefer, CaseMortgageLenderAndBrokerContactInfo, CaseMortgageOfferDetails, CaseMortgageOfferDates } from "../../../../domain/codecs/Cases";
import { CRMTitleSubSection } from "../../components/CRMTitleSubSection/CRMTitleSubSection";
import { CRMHorizontalSeperatorLine } from "../../components/CRMHorizontalSeperatorLine/CRMHorizontalSeperatorLine";
import { array } from "fp-ts";
import { pipe } from "fp-ts/lib/function";

const CRMLegalCaseDetailsMortgageOfferContainer = (props: TContainerStateProps): JSX.Element => (
    <CRMAutosaveIndicatorWrap
        status={props.state.forms.case_details_page.data.output.details.status}
    >
        <CRMTitleSection>
            Mortgage offer
        </CRMTitleSection>
        <CRMSpacer size="large" />
        <CRMSpacingColumn spacing="medium">
            <CRMCodecEditForm
                codec={CaseMortgageLenderAndBrokerContactInfo}
                model={props.state.forms.case_details_page.data.output.details.edited}
                validationErrors={props.state.forms.case_details_page.data.output.details.validationErrors}
                onChange={(edited) => props.dispatch({
                    type: "LEGAL_CASE_DETAILS_CHANGE",
                    payload: {
                        ...props.state.forms.case_details_page.data.output.details,
                        edited: {
                            ...props.state.forms.case_details_page.data.output.details.edited,
                            ...edited,
                        }
                    }
                })}
                columns={1}
                showDeferDateLabels={true}
            />

            <CRMCodecEditForm
                codec={CaseMortgageOfferDates}
                model={props.state.forms.case_details_page.data.output.details.edited}
                validationErrors={props.state.forms.case_details_page.data.output.details.validationErrors}
                onChange={(edited) => props.dispatch({
                    type: "LEGAL_CASE_DETAILS_CHANGE",
                    payload: {
                        ...props.state.forms.case_details_page.data.output.details,
                        edited: {
                            ...props.state.forms.case_details_page.data.output.details.edited,
                            ...edited,
                        }
                    }
                })}
                columns={2}
                showDeferDateLabels={true}
            />

            <CRMCodecEditForm
                codec={pipe(
                    props.state.forms.case_details_page.data.output.properties,
                    array.filter((property) => property.tenure === "leasehold"),
                    (a) => a.length > 0
                        ? CaseMortgageOfferDetailsWithLeaseholdRequirementsChecked
                        : CaseMortgageOfferDetails
                )}
                model={props.state.forms.case_details_page.data.output.details.edited}
                validationErrors={props.state.forms.case_details_page.data.output.details.validationErrors}
                onChange={(edited) => props.dispatch({
                    type: "LEGAL_CASE_DETAILS_CHANGE",
                    payload: {
                        ...props.state.forms.case_details_page.data.output.details,
                        edited: {
                            ...props.state.forms.case_details_page.data.output.details.edited,
                            ...edited,
                        }
                    }
                })}
                columns={2}
                showDeferDateLabels={true}
            />

            {/* MATTERS TO REFER AND DETAILS */}
            <CRMCodecEditForm
                codec={props.state.forms.case_details_page.data.output.details.edited.mortgage_offer_there_are_matters_we_need_to_refer
                    ? CaseMortgageOfferMattersToReferWithDetails
                    : CaseMortgageOfferMattersToRefer
                }
                model={props.state.forms.case_details_page.data.output.details.edited}
                validationErrors={props.state.forms.case_details_page.data.output.details.validationErrors}
                onChange={(edited) => props.dispatch({
                    type: "LEGAL_CASE_DETAILS_CHANGE",
                    payload: {
                        ...props.state.forms.case_details_page.data.output.details,
                        edited: {
                            ...props.state.forms.case_details_page.data.output.details.edited,
                            ...edited,
                        }
                    }
                })}
                columns={2}
                showDeferDateLabels={true}
            />

            {/* DISPLAY EXPLAINING THE RETENTION TO THE CLIENT IF THERE IS A RETENTION */}
            {props.state.forms.case_details_page.data.output.details.edited.mortgage_offer_retention_amount !== null && props.state.forms.case_details_page.data.output.details.edited.mortgage_offer_retention_amount > 0 && <>
                <CRMHorizontalSeperatorLine />
                <CRMTitleSubSection>
                    Client feedback on mortgage retention
                </CRMTitleSubSection>
                <CRMCodecEditForm
                    codec={CaseMortgageOfferRetentionExplainedToClient}
                    model={props.state.forms.case_details_page.data.output.details.edited}
                    validationErrors={props.state.forms.case_details_page.data.output.details.validationErrors}
                    onChange={(edited) => props.dispatch({
                        type: "LEGAL_CASE_DETAILS_CHANGE",
                        payload: {
                            ...props.state.forms.case_details_page.data.output.details,
                            edited: {
                                ...props.state.forms.case_details_page.data.output.details.edited,
                                ...edited,
                            }
                        }
                    })}
                    columns={2}
                    showDeferDateLabels={true}
                />
            </>}

            <CRMHorizontalSeperatorLine />
            <CRMTitleSubSection>
                Request/Receive mortgage funds
            </CRMTitleSubSection>
            <CRMCodecEditForm
                codec={CaseMortgageOfferFundsRequestAndReceive}
                model={props.state.forms.case_details_page.data.output.details.edited}
                validationErrors={props.state.forms.case_details_page.data.output.details.validationErrors}
                onChange={(edited) => props.dispatch({
                    type: "LEGAL_CASE_DETAILS_CHANGE",
                    payload: {
                        ...props.state.forms.case_details_page.data.output.details,
                        edited: {
                            ...props.state.forms.case_details_page.data.output.details.edited,
                            ...edited,
                        }
                    }
                })}
                columns={2}
                showDeferDateLabels={true}
            />
        </CRMSpacingColumn>
    </CRMAutosaveIndicatorWrap>
);

export default CRMLegalCaseDetailsMortgageOfferContainer;