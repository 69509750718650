import { DateTime } from "luxon";
import { getDateDifferenceInDays } from "./getDateDifferenceInDays";
import { pipe } from "fp-ts/lib/pipeable";

export const dateToSimpleFormat = (date: string) => {
    try {
        const dateTime = DateTime.fromISO(date, { zone: "Europe/London" });
        const DaysApart = pipe(
            DateTime.local().toISO(),
            getDateDifferenceInDays(date)
        );
        
        return (
            // Future
            DaysApart > 396  ? "Over 1 year":
            DaysApart > 366  ? "1 year":
            DaysApart > 335  ? "11 months":
            DaysApart > 305  ? "10 months":
            DaysApart > 274  ? "9 months":
            DaysApart > 243  ? "8 months":
            DaysApart > 213  ? "7 months":
            DaysApart > 182  ? "6 months":
            DaysApart > 152  ? "5 months":
            DaysApart > 121  ? "4 months":
            DaysApart > 90   ? "3 months":
            DaysApart > 61   ? "2 months":
            DaysApart > 30   ? "1 month":
            DaysApart > 14   ? "2 weeks":
            DaysApart > 7   ? "1 week":
            DaysApart > 6   ? "6 days":
            DaysApart > 5   ? "5 days":
            DaysApart > 4   ? "4 days":
            DaysApart > 3   ? "3 days":
            DaysApart > 2   ? "2 days":
            DaysApart > 1   ? "1 day":

            // Today
            DaysApart > 0   ? "Today":

            // Past
            DaysApart < -395 ? "Over 1 year":
            DaysApart < -364 ? "1 year":
            DaysApart < -334 ? "11 months":
            DaysApart < -303 ? "10 months":
            DaysApart < -275 ? "9 months":
            DaysApart < -244 ? "8 months":
            DaysApart < -213 ? "7 months":
            DaysApart < -183 ? "6 months":
            DaysApart < -152 ? "5 months":
            DaysApart < -122 ? "4 months":
            DaysApart < -91  ? "3 months":
            DaysApart < -60  ? "2 months":
            DaysApart < -30  ? "1 month":
            DaysApart < -13  ? "2 weeks":
            DaysApart < -6   ? "1 week":
            DaysApart < -5   ? "6 days":
            DaysApart < -4   ? "5 days":
            DaysApart < -3   ? "4 days":
            DaysApart < -2   ? "3 days":
            DaysApart < -1   ? "2 days":
            DaysApart < 0   ? "1 day":

            dateTime.toFormat("d MMM")
        );
    } catch (e) {
        console.error("Could not parse datetime:", date);
        return "";
    }
}