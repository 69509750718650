import React from "react";
import { CRMInboxTable } from "../CRMInboxTable/CRMInboxTable";
import { 
    TTriageForm, 
    TTriageSimpleEmailForm,
    TDetailedEmailForm, 
    TTriageAssigneeActiveTypeVisible,
    TTriageEmailDisassociateFromCaseForm,
} from "../../../../../domain/codecs/form/TriageForm";
import { useTriage } from "../../../hooks/useTriage";
import { CRMCaseEmailSingleViewUnresolved } from "../CRMCaseEmailSingleViewUnresolved/CRMCaseEmailSingleViewUnresolved";
import { CRMBlockScrollCacheView, CRMBlockScrollResetView } from "../../CRM/CRMBlock/CRMBlockViews/CRMBlockViews";

type TCaseInboxProps = {
    unresolvedForm: TTriageForm;
    
    onDisassociate: (form: TTriageEmailDisassociateFromCaseForm) => void;
    onOpenEmail: (email: TTriageSimpleEmailForm, triageType: TTriageAssigneeActiveTypeVisible) => void;
    onReply: (form: TDetailedEmailForm) => void;
    onForward: (form: TDetailedEmailForm) => void;
    onLoadMore: (triageType: TTriageAssigneeActiveTypeVisible) => void;
    onSearchTermChange: (value: string) => void;
};

export const CRMCaseInbox = (props: React.PropsWithChildren<TCaseInboxProps>): JSX.Element => {
    const {
        isPresentEmailSetToOpen,
        onOpenEmail,
        onLoadMore,
        getTriageFormToUse,
        getOpenedEmail,
        isShowingNext,
        isShowingPrevious,
        closeSingleView,
        onDisassociate,
        onNext,
        onPrevious,
    } = useTriage<"UNRESOLVED">({
        ...props,
        triageTypeVisible: "UNRESOLVED",
        getTriageFormToUseCallback: () => props.unresolvedForm,

        onDisassociate: props.onDisassociate,
        onArchive: () => undefined,
        onAssignToPerson: () => undefined,
        onBulkArchive: () => undefined,
        onResolve: () => undefined,
        onAssignToCaseAndMe: () => undefined,
        onAssignToCaseAndStaff: () => undefined,
        onAssignToCaseAndHandler: () => undefined,
        onAssignToCaseAndResolve: () => undefined,
        onChangeSearchCaseQuery: () => undefined,
    });

    return (
        <>
            {/* EMAIL CARDS */}
            <CRMBlockScrollCacheView isShowing={!isPresentEmailSetToOpen()}>
                <CRMInboxTable
                    form={props.unresolvedForm}
                    mode="card"
                    onOpenEmail={onOpenEmail}
                    onLoadMore={onLoadMore}
                    onSearchTermChange={props.onSearchTermChange}
                />
            </CRMBlockScrollCacheView>

            {/* SINGLE EMAIL VIEW */}
            <CRMBlockScrollResetView isShowing={isPresentEmailSetToOpen()}>
                <CRMCaseEmailSingleViewUnresolved
                    count={getTriageFormToUse().children.counts.available_count}
                    email={getOpenedEmail()}
                    isShowingNext={isShowingNext()}
                    isShowingPrevious={isShowingPrevious()}
                    
                    onDisassociate={onDisassociate}
                    onClose={closeSingleView}
                    onNext={onNext}
                    onPrevious={onPrevious}

                    onReply={props.onReply}
                    onForward={props.onForward}
                />
            </CRMBlockScrollResetView>
        </>
    );
};
