import { TAnyCodec } from "../../../../shared/src/codecs/codec";
import { TForm, TFormCodec } from "../../../../shared/src/codecs/types/form";

export const resetFormChildren = <
    C extends TFormCodec<TAnyCodec, TAnyCodec>,
    F extends TForm<unknown, unknown, unknown>
>(codec: C) =>
    (form: F): F => ({
        ...form,
        children: codec.payload.children.newDefault(),
    });