import React, { useState } from "react";
import { FontQuicksand } from "../../../BuildingBlocks/FontQuicksand";
import { Text } from "../../../BuildingBlocks/Text";
import { WeightBold } from "../../../WeightBold/WeightBold";
import { CRMFontSizes } from "../../../../models/CRMFontSizes";
import { SpacingColumn } from "../../../BuildingBlocks/SpacingColumn";
import { CRMIcon } from "../../../CRMIcon/CRMIcon";
import { Absolute } from "../../../BuildingBlocks/Absolute";
import { CRMColors } from "../../../../models/CRMColors";
import { CRMSpacing } from "../../../../models/CRMSpacing";
import { SpacingRow } from "../../../BuildingBlocks/SpacingRow";
import { array } from "fp-ts";
import { pipe } from "fp-ts/lib/pipeable";
import { TBlocksParamsObject, TRouteActionType } from "../../../../state/router/routerTypes";
import { underscoreToNormalEveryWordCapitalized } from "../../../../../../shared/src/utilsByDomain/string/underscoreToNormalEveryWordCapitalized";

type TBlockChoicePopoutProps = {
    openingBlock: string;
    blocksOpened: TBlocksParamsObject<TRouteActionType>;
    mode: "replace-only" | "replace-and-add";
    onChange: (value: TBlockOpenInstruction) => void;
    onClose: () => void;
};

export type TBlockOpenInstruction = {
    location: number;
    name: string;
    type: "add" | "replace";
};

export const CRMBlockChoicePopout = (props: React.PropsWithChildren<TBlockChoicePopoutProps>): JSX.Element => {
    
    const isNumberOdd = (number: number) => number % 2 === 1;

    const OpenBlocks = props.blocksOpened || []; 
    const ColumnCount = OpenBlocks.length;
    const NumberOfNewColumnPlaces = (ColumnCount*2)+1;
    const Tiles: Array<TBlockOpenInstruction> = pipe(
        array.range(1, NumberOfNewColumnPlaces),
        array.map((location) => ({
            name: isNumberOdd(location) ? "" : underscoreToNormalEveryWordCapitalized(OpenBlocks[(location/2)-1].type),
            location: isNumberOdd(location) ? Math.floor((location+1)/2) : location/2,
            type: isNumberOdd(location) ? "add" : "replace"
        }))
    );


    const [selectionCopy, setSelectionCopy] = useState("Select column.");
    
    const onHoverReplace = (instruction: TBlockOpenInstruction) => setSelectionCopy(`Replace ${instruction.name}.`);

    return (
        <div className={`crm-block-choice-popout`}>
            <Absolute right={CRMSpacing.TINY} top={CRMSpacing.TINY}>
                <CRMIcon
                    iconName="close"
                    size={`16px`}
                    colour="hex"
                    hexColour={CRMColors.NEUTRAL_0}
                    onClick={props.onClose}
                />
            </Absolute>

            <SpacingColumn spacing={CRMSpacing.LARGE}>
                
                <FontQuicksand>
                    <Text 
                        size={CRMFontSizes.SMALL} 
                        align="center"
                        width="100%"
                    >
                        Opening <WeightBold>{props.openingBlock}</WeightBold> in:
                    </Text>
                </FontQuicksand>

                <SpacingRow 
                    spacing={CRMSpacing.MEDIUM}
                    justifyContent="center"
                >
                    {Tiles.map((instruction, key) => {
                        if (instruction.type === "replace") {
                            return (
                                <ReplaceColumnTile 
                                    key={key}
                                    place={instruction.location}
                                    onClick={() => props.onChange(instruction)}
                                    onHover={() => onHoverReplace(instruction)}
                                />
                            )
                        }

                        if (instruction.type === "add" && props.mode === "replace-and-add") {
                            return (
                                <NewColumnTile 
                                    key={key}
                                    onClick={() => props.onChange(instruction)}
                                />
                            );
                        }

                        return null;
                    })}
                </SpacingRow>

                <FontQuicksand>
                    <Text 
                        size={CRMFontSizes.SMALL} 
                        align="center"
                        width="100%"
                    >
                        {selectionCopy}
                    </Text>
                </FontQuicksand>
            </SpacingColumn>
        </div>
    );
};


type TReplaceColumnTileProps = {
    place: number;
    onClick: () => void;
    onHover: () => void;
}

const ReplaceColumnTile = (props: TReplaceColumnTileProps ) => {
    return (
        <div 
            className="crm-block-choice-popout__replace-tile"
            onClick={props.onClick}
            onMouseOver={props.onHover}
        >
            {props.place}
        </div>
    );
}

const NewColumnTile = (props: { onClick: () => void }) => {
    return (
        <div 
            className="crm-block-choice-popout__new-tile"
            onClick={props.onClick}
        >
            +
        </div>
    );
}