import React from "react";

type TPageBodyProps = {
    fullViewportWidth?: boolean; // for when there are >2 blocks showing and for case nav bar
    overflowAuto?: boolean; // for when there are >2 blocks showing
};

export const CRMPageBody = (props: React.PropsWithChildren<TPageBodyProps>): JSX.Element => {
    return (
        <div className="crm-page-body">
            <div className={`
                crm-page-body__body
                ${props.fullViewportWidth ? "crm-page-body__body--full-viewport-width" : ""}
                ${props.overflowAuto ? "crm-page-body__body--overflow-auto" : ""}
            `}>
                {props.children}
            </div>
        </div>
    );
};
