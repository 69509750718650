import React, { useEffect, useState } from "react";
import { FrontSpacing } from "../../../../models/FrontSpacing";
import { SpacingColumn } from "../../../BuildingBlocks/SpacingColumn";
import { FrontOnboardingTab } from "../../Simple/FrontOnboardingTab/FrontOnboardingTab";
import { TOnboardingCaseOverviewTab } from "../FrontOnboardingCaseOverview/FrontOnboardingCaseOverview";
import { CursorPointer } from "../../../CursorPointer/CursorPointer";
import { SpacingRow } from "../../../BuildingBlocks/SpacingRow";
import { TextColor } from "../../../BuildingBlocks/TextColor";
import { FrontIcon } from "../../Simple/FrontIcon/FrontIcon";
import { FrontColors } from "../../../../models/FrontColors";
import { AnimationPopout } from "../../../BuildingBlocks/AnimationPopout";
import { FrontFormSectionTitle } from "../../Simple/FrontFormSectionTitle/FrontFormSectionTitle";

export type TFrontOnboardingCaseOverviewStepsSummarySectionProps<A extends string> = {
    tabs: Array<TOnboardingCaseOverviewTab<A>>;
    isOpen: boolean;
    title?: string;
    selectedTab?: A;
    onChange: (tab: TOnboardingCaseOverviewTab<A>) =>  void;
};

export const FrontOnboardingCaseOverviewStepsSummarySection = <A extends string>(props: React.PropsWithChildren<TFrontOnboardingCaseOverviewStepsSummarySectionProps<A>>): JSX.Element => {

    const [areTabsOpen, setAreTabsOpen] = useState(props.isOpen);

    const isTabSelected = (tab: TOnboardingCaseOverviewTab<A>) => tab.value === props.selectedTab;

    const toggleTabs = () => setAreTabsOpen(!areTabsOpen);

    useEffect(() => {
        setAreTabsOpen(props.isOpen)
    }, [props.isOpen])

    return (
        <SpacingColumn spacing={FrontSpacing.SMALL_3}>
            
            {/* TITLE */}
            {props.title &&
                <CursorPointer onClick={toggleTabs}>
                    <SpacingRow spacing={FrontSpacing.SMALL_3}>
                        <FrontFormSectionTitle>
                            {props.title === "We still need you to:"
                                ? props.title
                                : <TextColor color={FrontColors.NEUTRAL_6}>
                                    {`${props.title} (${props.tabs.length})`}
                                </TextColor>
                            }
                        </FrontFormSectionTitle>
                        <FrontIcon
                            iconName={areTabsOpen ? "chevron-up" : "chevron-down"}
                            colour="neutral-6"
                            size="tiny"
                        />
                    </SpacingRow>
                </CursorPointer>
            }
            
            {/* TABS */}
            {areTabsOpen &&
                <AnimationPopout>
                    <SpacingColumn spacing={FrontSpacing.SMALL_2}>
                        {props.tabs.map((tab, index) => (
                            <FrontOnboardingTab
                                key={index}
                                {...tab}
                                mode="border"
                                isSelected={isTabSelected(tab)}
                                onClick={() => props.onChange(tab)}
                            />
                        ))}
                    </SpacingColumn>
                </AnimationPopout>
            }
        </SpacingColumn>
    );
};