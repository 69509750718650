import React, { useState } from "react";
import { FrontColors } from "../../../models/FrontColors";
import { FrontSpacing } from "../../../models/FrontSpacing";
import { Flex } from "../../BuildingBlocks/Flex";
import { FontQuicksand } from "../../BuildingBlocks/FontQuicksand";
import { FontSize } from "../../BuildingBlocks/FontSize";
import { MaxWidth } from "../../BuildingBlocks/MaxWidth";
import { SpacingColumn } from "../../BuildingBlocks/SpacingColumn";
import { TextAlign } from "../../BuildingBlocks/TextAlign";
import { TextColor } from "../../BuildingBlocks/TextColor";
import { Width } from "../../BuildingBlocks/Width";
import { WeightSemiBold } from "../../WeightSemiBold/WeightSemiBold";
import { FPInput } from "../Complex/FPInput/FPInput";
import { FPLandingTitle } from "../Simple/FPLandingTitle/FPLandingTitle";
import { FPSimpleLogo } from "../Simple/FPSimpleLogo/FPSimpleLogo";
import { TPublicPropertyReportAddressSearchForm } from "../../../../../domain/codecs/form/PublicPropertyReportForm";
import { onChangeForm } from "../../../../../shared/src/codecs/types/form";
import { SpacingRow } from "../../BuildingBlocks/SpacingRow";
import { FPInputButton } from "../Complex/FPInputButton/FPInputButton";
import { doesErrorCodeExist, doesErrorKeyExist } from "../../../../../shared/src/codecs/errors";
import { FPError } from "../Complex/FPError/FPError";
import { pipe } from "fp-ts/lib/function";
import { array } from "fp-ts";
import { WeightBold } from "../../WeightBold/WeightBold";
import { FPTextArea } from "../Complex/FPTextArea/FPTextArea";
import { FPInputDate } from "../Complex/FPInputDate/FPInputDate";

export const FPPropertyReportForm = (props: {
    selectedAddressId: null | number,
    form: TPublicPropertyReportAddressSearchForm,
    onChange: (form: TPublicPropertyReportAddressSearchForm) => void,
    onSubmit: () => void,
}): JSX.Element => {
    const [hasErrors, setHasErrors] = useState<boolean>(false);

    const deceasedName = props.form.edited.deceased_name;
    
    const dateOfDeath = props.form.edited.date_of_death;

    const introducerCaseRef = props.form.edited.introducer_external_reference_id;

    const introducerNotes = props.form.edited.introducer_notes;

    const buildingNumberOrName = props.form.edited.building_number_name;

    const postcode = props.form.edited.postcode;

    const firstName = props.form.edited.introducer.first_name;

    const lastName = props.form.edited.introducer.last_name;

    const email = props.form.edited.introducer.email;

    const phoneNumber = props.form.edited.introducer.phone_number;

    const noPropertyFoundError =
        doesErrorCodeExist("NoResults", props.form.validationErrors)
        || pipe(
            props.form.children.results,
            array.filter(({edited, children}) =>
                edited.id === props.selectedAddressId
                && children.response?._tag === "error"
                && (
                    children.response.code === "bg.invalid.property.search.criteria"
                    || children.response.code === "bg.properties.nopropertyfound"
                )
            )
        ).length > 0;

    const postcodeError =
        pipe(
            props.form.children.results,
            array.filter(({edited, children}) =>
                edited.id === props.selectedAddressId
                && children.response?._tag === "error"
                && children.response.code === "bg.postcode.invalid"
            )
        ).length > 0;

    const tooManyPropertiesFoundError =
        pipe(
            props.form.children.results,
            array.filter(({edited, children}) =>
                edited.id === props.selectedAddressId
                && children.response?._tag === "error"
                && children.response.code === "bg.properties.toomanyproperties"
            )
        ).length > 0;

    const landRegistryError =
        pipe(
            props.form.children.results,
            array.filter(({edited, children}) =>
                edited.id === props.selectedAddressId
                && children.response?._tag === "error"
                && children.response.code === "soapenv:SchemaFault"
            )
        ).length > 0;

    const deceasedNameHasError =
        hasErrors && deceasedName.trim() === ""
        || doesErrorKeyExist("edited.deceased_name", props.form.validationErrors);
    
    const dateOfDeathHasError =
        (hasErrors && (dateOfDeath === null || dateOfDeath.trim() === ""))
        || doesErrorKeyExist("edited.date_of_death", props.form.validationErrors);

    const buildingNumberOrNameHasError =
        hasErrors && buildingNumberOrName.trim() === ""
        || doesErrorKeyExist("edited.building_number_name", props.form.validationErrors)
        || tooManyPropertiesFoundError;

    const postcodeHasError =
        hasErrors && postcode.trim() === ""
        || doesErrorKeyExist("edited.postcode", props.form.validationErrors)
        || postcodeError;

    const firstNameHasError =
        hasErrors && firstName.trim() === ""
        || doesErrorKeyExist("edited.introducer.first_name", props.form.validationErrors);

    const lastNameHasError =
        hasErrors && lastName.trim() === ""
        || doesErrorKeyExist("edited.introducer.last_name", props.form.validationErrors);

    const emailHasError =
        (hasErrors && (email === null || email.trim() === ""))
        || doesErrorKeyExist("edited.introducer.email", props.form.validationErrors);

    const phoneNumberHasError =
        (hasErrors && (phoneNumber === null || phoneNumber.trim() === ""))
        || doesErrorKeyExist("edited.introducer.phone_number", props.form.validationErrors);

    const errorMessages =
        [
            ...(noPropertyFoundError ? ["We couldn't find a property for the address provided"] : []),
            ...(postcodeHasError ? ["That postcode isn't valid"] : []),
            ...(tooManyPropertiesFoundError ? ["We found more properties than we can actually show using that postcode, please edit the House/Flat number or name to limit the results"] : []),
            ...(landRegistryError ? ["Land Registry are having difficulties at the moment, please try again later"] : []),
            ...(deceasedNameHasError ? ["Please enter the name of the deceased"] : []),
            ...(dateOfDeathHasError ? ["Please enter the date of death of the deceased"] : []),
            ...(buildingNumberOrNameHasError ? ["Please enter the House/Flat number or name"] : []),
            ...(firstNameHasError ? ["Please enter your first name"] : []),
            ...(lastNameHasError ? ["Please enter your last name"] : []),
            ...(emailHasError ? ["Your email address does not look valid"] : []),
            ...(phoneNumberHasError ? ["Your phone number does not look valid"] : []),
        ];

    const onChange = onChangeForm(props.form, props.onChange);

    const onChangeIntroducer = (key: keyof typeof props.form.edited.introducer) =>
        (value: string | null | boolean) =>
            onChangeForm(props.form, props.onChange)("introducer")({
                ...props.form.edited.introducer,
                [key]: value,
            });

    const onSubmit = () => {
        if (
            deceasedName.trim() === ""
            || dateOfDeath === null
            || buildingNumberOrName.trim() === ""
            || postcode.trim() === ""
            || firstName.trim() === ""
            || lastName.trim() === ""
            || email === null
            || email.trim() === ""
            || phoneNumber === null
            || phoneNumber.trim() === ""
        ) {
            setHasErrors(true);
        } else if (props.form.status !== "submitting") {
            setHasErrors(false);
            props.onSubmit();
        }
    };

    return <SpacingColumn
        spacing={FrontSpacing.LARGE_1}
        justifyContent="center"
    >
        {/* LOGO */}
        <Flex justifyContent="center">
            <FPSimpleLogo width="95px" />
        </Flex>

        {/* HEADER */}
        <Flex justifyContent="center">
            <MaxWidth width="530px">
                <FPLandingTitle
                    size={60}
                    color={FrontColors.NEUTRAL_18}
                    align="center"
                >
                    The Probate Hub.
                </FPLandingTitle>
            </MaxWidth>
        </Flex>

        {/* SUBTITLE */}
        <Flex justifyContent="center">
            <MaxWidth width="530px">
                <Flex justifyContent="center">
                    <MaxWidth width="425px">
                        <FontQuicksand>
                            <FontSize
                                size={20}
                                lineHeight={1.2}
                            >
                                <WeightSemiBold>
                                    <TextColor color={FrontColors.NEUTRAL_18}>
                                        <TextAlign position="center">
                                            Instantly check the <WeightBold>deeds</WeightBold> to a property, arrange a free <WeightBold>HMRC compliant valuation</WeightBold> and get a <WeightBold>conveyancing quote</WeightBold>.
                                        </TextAlign>
                                    </TextColor>
                                </WeightSemiBold>
                            </FontSize>
                        </FontQuicksand>
                    </MaxWidth>
                </Flex>
            </MaxWidth>
        </Flex>

        {/* INPUTS */}
        <Flex justifyContent="center">
            <SpacingRow
                spacing={FrontSpacing.MEDIUM_1}
                alignItems="flex-start"
            >
                {/* PROPERTY DETAILS */}
                <Width width="335px">
                    <SpacingColumn spacing={FrontSpacing.SMALL_3}>
                        {/* DECEASED NAME */}
                        <FPInput
                            placeholder="Enter one of the deceased's name"
                            value={deceasedName}
                            coloursInverted={true}
                            autoFocus={true}
                            displayError={deceasedNameHasError}
                            onChange={onChange("deceased_name")}
                            onEnter={onSubmit}
                        />

                        {/* DECEASED DATE OF DEATH */}
                        <FPInputDate
                            placeholder="Enter the date of death"
                            value={dateOfDeath || ""}
                            coloursInverted={true}
                            autoFocus={true}
                            displayError={dateOfDeathHasError}
                            onChange={onChange("date_of_death")}
                            onEnter={onSubmit}
                        />

                        {/* BUILDING NUMBER OR NAME */}
                        <FPInput
                            placeholder="House/Flat number or name"
                            value={buildingNumberOrName}
                            coloursInverted={true}
                            displayError={buildingNumberOrNameHasError}
                            onChange={onChange("building_number_name")}
                            onEnter={onSubmit}
                        />

                        {/* POSTCODE */}
                        <FPInput
                            placeholder="Postcode"
                            value={postcode}
                            coloursInverted={true}
                            displayError={postcodeHasError}
                            onChange={(value) => onChange("postcode")(value.toUpperCase())}
                            onEnter={onSubmit}
                        />

                        {/* ERRORS */}
                        {errorMessages.length > 0 &&
                            <FPError
                                errorMessages={errorMessages}
                            />
                        }
                    </SpacingColumn>
                </Width>

                {/* REFERRER DETAILS */}
                <Width width="335px">
                    <SpacingColumn spacing={FrontSpacing.SMALL_3}>
                        {/* CASE REF */}
                        <FPInput
                            placeholder="Your case reference"
                            value={introducerCaseRef || ""}
                            coloursInverted={true}
                            displayError={false}
                            onChange={(value) =>
                                onChange("introducer_external_reference_id")(value || "")
                            }
                            onEnter={onSubmit}
                        />

                        {/* FIRST NAME */}
                        <FPInput
                            placeholder="Your first name"
                            value={firstName}
                            coloursInverted={true}
                            displayError={firstNameHasError}
                            onChange={onChangeIntroducer("first_name")}
                            onEnter={onSubmit}
                        />

                        {/* LAST NAME */}
                        <FPInput
                            placeholder="Your last name"
                            value={lastName}
                            coloursInverted={true}
                            displayError={lastNameHasError}
                            onChange={onChangeIntroducer("last_name")}
                            onEnter={onSubmit}
                        />

                        {/* EMAIL */}
                        <FPInput
                            placeholder="Your email"
                            value={email || ""}
                            coloursInverted={true}
                            displayError={emailHasError}
                            onChange={(value) =>
                                onChangeIntroducer("email")(value || null)
                            }
                            onEnter={onSubmit}
                        />

                        <SpacingRow
                            justifyContent="start"
                            alignItems="center"
                            childSize="1fr min-content"
                        >
                            {/* PHONE */}
                            <FPInput
                                flatEdge={true}
                                placeholder="Your phone number"
                                value={phoneNumber || ""}
                                coloursInverted={true}
                                displayError={phoneNumberHasError}
                                onChange={(value) =>
                                    onChangeIntroducer("phone_number")(value || null)
                                }
                                onEnter={onSubmit}
                            />

                            {/* SUBMIT */}
                            <FPInputButton
                                coloursInverted={true}
                                iconName="chevron-right"
                                onClick={onSubmit}
                            />
                        </SpacingRow>
                    </SpacingColumn>
                </Width>
            </SpacingRow>
        </Flex>

        <Width width="690px">
            {/* INTRODUCER NOTES */}
            <FPTextArea
                placeholder="Please write notes here to help us understand your client's situation."
                value={introducerNotes || ""}
                coloursInverted={true}
                displayError={false}
                onChange={(value) =>
                    onChange("introducer_notes")(value || "")
                }
            />
        </Width>
    </SpacingColumn>;
};
