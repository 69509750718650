import { WheelEvent, useRef } from "react";

type TScrollDirection = "left" | "right";
type TUseMouseWheelHorizontalScrollProps = {
    scrollAmount?: number;
}

export const useMouseWheelHorizontalScroll = <T extends HTMLElement>(props: TUseMouseWheelHorizontalScrollProps) => {

    const ElementRef = useRef<T>(null);
    const ScrollAmount = props.scrollAmount || 310;

    const onWheelMove = (event: WheelEvent<T>) => {
        var isTouchPad = isWheelEventFormATouchpad(event);
        if (!isTouchPad) {
            moveContent(getScrollDirectionFromWheelEvent(event))
        }
    };

    const moveContent = (scrollDirection: TScrollDirection) => {
        const element = ElementRef.current;
        if (!!element) {       
            element.scrollBy({
                top: 0,
                left: scrollDirection === "right" ? ScrollAmount : -ScrollAmount,
                behavior: 'auto'
            })
        }
    };

    const getScrollDirectionFromWheelEvent = (event: WheelEvent): TScrollDirection =>
        event.deltaX === 0 ? 
            event.deltaY > 0 ? "right" : "left": 
            event.deltaX > 0 ? "right" : "left"
    ;

    const isWheelEventFormATouchpad = (event: WheelEvent) => 
        !!event.nativeEvent["wheelDeltaY"]
            ? event.nativeEvent["wheelDeltaY"] === -3 * event.nativeEvent.deltaY 
            : event.nativeEvent.deltaMode === 0
    ;

    return {
        onWheelMove,
        ElementRef,
    }
}