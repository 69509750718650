import { tap, mergeMap, switchMap, delay, map, filter } from "rxjs/operators";
import { formOperation } from "../../wrappers/formOperation";
import { TActionsDefinitionsList } from "./TAction";
import { action, routeAction } from "./actionFunctions";
import { TJobsFormIO } from "../../../../domain/codecs/formIO/JobsIO";
import { TStateLens } from "../../../../domain/codecs/state";
import { TCodecSetState, TGetCodecState } from "../applyActions";
import { Observable } from "rxjs";
import { TStaffUser } from "../../../../domain/codecs/User";
import { array, option } from "fp-ts";
import { pipe } from "fp-ts/lib/pipeable";
import { booleanFold } from "../../../../shared/src/utilsByDomain/option/booleanFold";
import { TBrandPrefix, envCurrentBrand, getCurrentBrandHostnameAndEnv, getCurrentBrandPrefix } from "../../../../domain/models/Enviroment";
import { TJob1 } from "../../../../domain/codecs/Job";

const EnvBrandPrefix = getCurrentBrandPrefix("web", window.location.hostname);
const IsSailLegal = EnvBrandPrefix === "sl";
const roadblockPageLimit = 50;

export const actions: TActionsDefinitionsList = [
    // ON VIEW
    action("VIEW_JOBS", (obs$, lens, setState, getState) => {
        obs$.pipe(
            tap(() => setState(lens.jobs.data.status.set("loading"))),
            mergeMap(
                () => formOperation("GetJobs", getState().jobs.data),
                (payload, data) => {
                    return [payload, filterToEnviromentSpecificJobs(data)] as [string | undefined, TJobsFormIO]
                }
            ),
            tap(([payload, data]) =>
                setState(({ ...s }) => {
                    // [REFACTOR] Surley this can be neater and easier to reason about?
                    s.jobs.data = data;
                    s.jobs.case_filtered_by_user_id = "anyone";

                    if (
                        s.jobs.filter_has_been_changed_by_user === false 
                        && payload !== undefined
                        && isUserInStaffUserArray(data.output.filterable_case_users, payload)
                    ) {
                        s.jobs.case_filtered_by_user_id = payload;
                    }

                    return s;
                })
            ),
        ).subscribe();
    }),

    routeAction("VIEW_CRM_LISTING", (obs$, lens, set, get) => {
        obs$.pipe(
            $getJobs(lens, set, get),
        ).subscribe();
    }),
    routeAction("VIEW_CRM_ENQUIRY", (obs$, lens, set, get) => {
        obs$.pipe(
            $getJobs(lens, set, get),
        ).subscribe();
    }),
    action("LEGAL_CASE_DETAILS_VIEW", (obs$, lens, set, get) => {
        obs$.pipe(
            $getJobs(lens, set, get),
        ).subscribe();
    }),

    // NEW ROADBLOCKS/JOBS MERGER
    action("VIEW_ROADBLOCKS", (obs$, lens, setState, getState) => {
        obs$.pipe(
            $resetRoadblocks(lens, setState, getState),

            filter(() => getCurrentBrandHostnameAndEnv("web", window.location.hostname) === "saillegal"),
            tap(() => setState(lens.overlay.roadblocks.status.set("loading"))),
            $getRoabdlocks(lens, setState, getState),
        ).subscribe()
    }),

    action("FILTER_ROADBLOCKS", (obs$, lens, setState, getState) => {
        obs$.pipe(
            tap((form) => setState(lens.overlay.roadblocks.set(form))),
            filter(() => IsSailLegal),
            $resetRoadblocks(lens, setState, getState),
            tap(() => setState(lens.overlay.roadblocks.status.set("submitting"))),
            $getRoabdlocks(lens, setState, getState),
        ).subscribe()
    }),

    // [NOTE]
    // The jobs filter handles filter by type, handler and assignee
    // But only the change of the assignee requires a request.
    // The type and handler are done in the components.
    //
    // P.S The sooner we kill jobs the better.
    action("FILTER_JOBS", (obs$, lens, setState, getState) => {
        obs$.pipe(            
            tap((filter) => 
                setState(lens.jobs.data.input.user_id.set(filter.filter_by_user || filter.default_user_filter))
            ),
            tap((filter) => 
                setState(lens.jobs.case_filtered_by_user_id.set(filter.filter_by_handler || 'anyone'))
            ),
            
            map((filter) => [lens.jobs.filter.get()(getState()), filter]),
            tap(([oldFilter, newFilter]) => setState(lens.jobs.filter.set(newFilter))),
            // [NOTE]
            // This here check, is here to make sure we don't pointlessly request
            // jobs when the filter.type is changed.
            filter(([oldFilter, newFilter]) => oldFilter.filter_by_user !== newFilter.filter_by_user),
            $getJobs(lens, setState, getState),
        ).subscribe()
    }),
    
    action("LOAD_MORE_ROADBLOCKS", (obs$, lens, setState, getState) => {
        obs$.pipe(
            $incrementRoadblockOffset(lens, setState, getState),

            filter(() => getCurrentBrandHostnameAndEnv("web", window.location.hostname) === "saillegal"),
            tap(() => setState(lens.overlay.roadblocks.status.set("submitting"))),
            $getRoabdlocks(lens, setState, getState),
        ).subscribe()
    }),
];

const $getJobs = <T>(lens: TStateLens, setState: TCodecSetState, getState: TGetCodecState) => 
    (obs$: Observable<T>) =>
        obs$.pipe(
            tap(() => setState(lens.jobs.data.status.set("submitting"))),
            mergeMap(() => formOperation("GetJobs", lens.jobs.data.get()(getState()))),
            map(filterToEnviromentSpecificJobs),
            tap((response) => setState(lens.jobs.data.set(response))),
        )
;

const $resetRoadblocks = <T>(lens: TStateLens, setState: TCodecSetState, getState: TGetCodecState) => 
    (obs$: Observable<T>) =>
        obs$.pipe(
            tap(() => setState(lens.overlay.roadblocks.edited.offset.set(0))),
            tap(() => setState(lens.overlay.roadblocks.edited.limit.set(roadblockPageLimit))),
            tap(() => setState(lens.overlay.roadblocks.children.roadblocks.set([]))),
        )
;

const $incrementRoadblockOffset = <T>(lens: TStateLens, setState: TCodecSetState, getState: TGetCodecState) => 
    (obs$: Observable<T>) =>
        obs$.pipe(
            map(() => lens.overlay.roadblocks.edited.offset.get()(getState())),
            tap((currentOffset) => setState(lens.overlay.roadblocks.edited.offset.set(currentOffset + roadblockPageLimit))),
        )
;

const $getRoabdlocks = <T>(lens: TStateLens, setState: TCodecSetState, getState: TGetCodecState) => 
    (obs$: Observable<T>) =>
        obs$.pipe(
            switchMap(() => formOperation("GetRoadblocks", lens.overlay.roadblocks.get()(getState()))),
            map((response) => [response, lens.overlay.roadblocks.get()(getState())]),
            tap(([response, roadblocks]) => setState(lens.overlay.roadblocks.set({
                ...response,
                children: {
                    ...response.children,
                    roadblocks: roadblocks.children.roadblocks.concat(response.children.roadblocks)
                }
            }))),
            delay(300),
            tap(() => setState(lens.overlay.roadblocks.status.set("untouched"))),
        )
;

const isUserInStaffUserArray = (users: Array<TStaffUser>, id: string) =>
    pipe(
        users,
        array.findFirst((user) => user.id === id),
        booleanFold
    )
;

const isJobPrefixedBy = (prefix: TBrandPrefix) =>
    (job: TJob1) => {
        let regex = new RegExp(`^${prefix}_`);
        return job.type.match(regex) !== null
    }
;

const filterToEnviromentSpecificJobs = (form: TJobsFormIO): TJobsFormIO => {
    return {
        ...form,
        output: {
            ...form.output,
            jobs: form.output.jobs.filter(isJobPrefixedBy(EnvBrandPrefix))
        }
    }
}