import React from "react";
import Heading3 from "../components/OldFront/Simple/Heading3Component/Heading3";
import CRMAccordion from "../components/CRM/CRMAccordionComponent/CRMAccordion";
import CRMPartySituation from "../containers/CRMPartySituationContainer/CRMPartySituation";
import CRMPartyNotes from "../containers/CRMPartyNotesContainer/CRMPartyNotes";
import CRMPartySearchCriteria from "../containers/CRMPartySearchCriteriaContainer/CRMPartySearchCriteria";
import CRMPartyAddListingContainer from "../containers/CRMPartyAddListingContainer/CRMPartyAddListingContainer";
import CRMPartyViewListingsContainer from "../containers/CRMPartyViewListingsContainer/CRMPartyViewListingsContainer";
import CRMPartyUsersContainer from "../containers/CRMPartyUsersContainer/CRMPartyUsersContainer";
import { TContainerStateProps } from "../state/TContainerStateProps";
import { CRMHomesMenuBarContainer } from "../containers/CRMHomesMenuBarContainer/CRMHomesMenuBarContainer";

class CRMParty extends React.Component<React.PropsWithChildren<TContainerStateProps>> {
    public render(): JSX.Element {
        return (
            <div className="crmParty__page">
                <CRMHomesMenuBarContainer {...this.props} />

                <div className="crmParty__sectionsContainer">
                    <div className="crmParty__heading">
                        <Heading3
                            text="Party"
                        />
                    </div>
                    {/* NOTES */}
                    <CRMAccordion
                        title="Notes"
                        defaultsOpen={true}
                        formStatus={this.props.state.activeData.crm.party.notesForm.status}
                    >
                        <CRMPartyNotes
                            state={this.props.state}
                            dispatch={this.props.dispatch}
                        />
                    </CRMAccordion>
                    {/* USERS */}
                    <CRMPartyUsersContainer
                        state={this.props.state}
                        dispatch={this.props.dispatch}
                    />
                    {/* SITUATION */}
                    <CRMAccordion
                        title="Situation"
                        formStatus={this.props.state.activeData.crm.party.situationForm.status}
                    >
                        <CRMPartySituation
                            state={this.props.state}
                            dispatch={this.props.dispatch}
                        />
                    </CRMAccordion>
                    {/* SEARCH CRITERIA */}
                    <CRMAccordion
                        title="Search Criteria"
                        formStatus={this.props.state.activeData.crm.party.partySearchCriteriaForm.status}
                    >
                        <CRMPartySearchCriteria
                            state={this.props.state}
                            dispatch={this.props.dispatch}
                        />
                    </CRMAccordion>
                    {/* ADD PROPERTY */}
                    <CRMAccordion
                        title={`Selling (${this.props.state.activeData.crm.party.listings.response.data.length})`}
                    >
                        <CRMPartyViewListingsContainer
                            state={this.props.state}
                            dispatch={this.props.dispatch}
                        />
                    </CRMAccordion>

                    <CRMAccordion
                        title="Add property"
                        formStatus={this.props.state.activeData.crm.party.addListingForm.status}
                    >
                        <CRMPartyAddListingContainer
                            state={this.props.state}
                            dispatch={this.props.dispatch}
                        />
                    </CRMAccordion>
                </div>
            </div>
        );
    }
}

export default CRMParty;
