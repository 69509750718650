import React from "react";
import { DateTime } from "luxon";
import { TCRMFormInputWithoutCodec } from "./CRMFormInputTypes";
import CRMInputLabelAndErrorWrapComponent from "../CRMInputLabelAndErrorWrapComponent/CRMInputLabelAndErrorWrapComponent";
import { CRMRadioListComponent } from "../CRMInputs/CRMRadioListComponent/CRMRadioListComponent";

export const CRMDateWithoutTimeOrNullYesNoRadioFormInput: TCRMFormInputWithoutCodec<string | null> = (props) =>
    {
        const timeAsString = props.model ? DateTime.fromISO(props.model).setZone("Europe/London").toFormat("dd/MM/yyyy h:mma").toLowerCase() : undefined;
        return <CRMInputLabelAndErrorWrapComponent label={props.label} title={timeAsString}>
            <CRMRadioListComponent<boolean, boolean>
                value={props.model ? true : false}
                direction="row"
                options={[
                    {
                        value: true,
                        text: "Yes",
                    },
                    {
                        value: false,
                        text: "No",
                    },
                ]}
                onChange={(m) => props.onChange(m ? DateTime.utc().toISODate() : null)}
                disabled={props.disabled}
                displayError={props.displayError} />
        </CRMInputLabelAndErrorWrapComponent>;
    };