import React from "react";
import { CRMTableRow } from "../../CRMTableRow/CRMTableRow";
import { SpacingRow } from "../../BuildingBlocks/SpacingRow";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { pipe } from "fp-ts/function";
import { CRMParagraph } from "../../Simple/CRMParagraph/CRMParagraph";
import { CRMColors } from "../../../models/CRMColors";
import { TProbateHelplineCallbackForm } from "../../../../../domain/codecs/form/ProbateHelplineCallbackForm";
import { array } from "fp-ts";
import { WeightMedium } from "../../WeightMedium/WeightMedium";
import { DateTime } from "luxon";
import { CRMPill } from "../../Simple/CRMPill/CRMPill";
import { WeightSemiBold } from "../../WeightSemiBold/WeightSemiBold";
import { getAmountOfTimeInFutureFromDate } from "../../CRMDeferButtonComponent/CRMDeferButtonComponent";

export const waitTimeInSeconds = (createdAt: string) => DateTime.utc().toSeconds() - DateTime.fromISO(createdAt).toSeconds();

// If a wait is over 1 day
export const isLongWait = (createdAt: string) => waitTimeInSeconds(createdAt) > 86400;

export const CRMProbateHelplineCallbackNowRow = (props: React.PropsWithChildren<{
    form: TProbateHelplineCallbackForm;
    onClick: () => void;
}>): JSX.Element => {
    const fullName =
        pipe(
            [
                props.form.children.first_name,
                props.form.children.last_name,
            ],
            array.filter((namePart) => namePart !== ""),
            (a) => a.join(" "),
        );

    const waitAbbreviation = getAmountOfTimeInFutureFromDate(DateTime.fromISO(props.form.children.created_at), DateTime.utc());

    return <CRMTableRow
        backgroundColour={CRMColors.NEUTRAL_PAPER}
        onClick={props.onClick}
        actions={[]}
    >
        <SpacingRow
            spacing={CRMSpacing.MEDIUM}
            justifyContent="space-between"
        >
            {/* NAME */}
            <CRMParagraph>
                <WeightMedium>
                    {fullName}
                </WeightMedium>
            </CRMParagraph>

            {/* LONG WAIT BADGE */}
            {isLongWait(props.form.children.created_at) &&
                <CRMPill 
                    colour="highlights-instructional-brick-10"
                    mode="single"
                >
                    <WeightSemiBold>
                        Waiting {waitAbbreviation}
                    </WeightSemiBold>
                </CRMPill>
            }
        </SpacingRow>
    </CRMTableRow>;
};
