import React from "react";
import { CRMColors } from "../../../models/CRMColors";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { SpacingColumn } from "../../BuildingBlocks/SpacingColumn";
import { CRMParagraph } from "../../Simple/CRMParagraph/CRMParagraph";
import { WeightBold } from "../../WeightBold/WeightBold";
import { useHover } from "../../../hooks/useHover";
import { Relative } from "../../BuildingBlocks/Relative";
import { CursorPointer } from "../../CursorPointer/CursorPointer";
import { Background } from "../../BuildingBlocks/Background";
import { CRMIcon } from "../../CRMIcon/CRMIcon";
import { Absolute } from "../../BuildingBlocks/Absolute";
import { CRMParagraphBulletPoints } from "../../CRMBulletList/CRMParagraphBulletPoints";
import { Position } from "../../BuildingBlocks/Position";


export const CRMCloseChaseContinueCaseIconCardPopout = (props: React.PropsWithChildren<{
    onClick: () => void,
    notification: boolean,
    isInChaseMode: boolean,
}>): JSX.Element => {
    const [ref, isHovering] = useHover<HTMLDivElement>({});

    return <Relative
        width="fit-content"
        divRef={ref}
    >
        <CursorPointer onClick={props.onClick}>
            <Background
                color={CRMColors.NEUTRAL_PAPER}
                height="44px"
                width="44px"
                padding="12px"
                borderLeftDefinition={`1px solid ${CRMColors.NEUTRAL_8}`}
                boxShadowDefinition="0px 0px 2px 0px rgba(0, 0, 0, 0.25)"
            >
                <CRMIcon
                    iconName="pause"
                    size="medium"
                    colour="hex"
                    hexColour={CRMColors.NEUTRAL_INK} 
                />
            </Background>

            {props.notification &&
                <Position position="absolute" bottom="4px" right="4px" zIndex={100}>
                    <Background
                        color={CRMColors.NEUTRAL_PAPER}
                        borderRadius="10px"
                        borderDefinition={`solid 2px ${CRMColors.HIGHLIGHTS_CALMING_PINK_0}`}
                        padding="1px"
                    >
                        <Background
                            color={CRMColors.HIGHLIGHTS_CALMING_PINK_0}
                            borderRadius="10px"
                        >
                            <CRMIcon
                                iconName={
                                    props.isInChaseMode
                                        ? "chase"
                                        : "chase-stop"
                                }
                                size="tiny"
                                colour="hex"
                                hexColour={CRMColors.HIGHLIGHTS_CALMING_PINK_10}
                                
                            />
                        </Background>
                        </Background>
                </Position>
            }
        </CursorPointer>

        
             
        {/* CARD */}
        {isHovering &&
            <Absolute
                right="0"
                top="46px"
            >
                <Background
                    borderRadius="5px"
                    color={CRMColors.NEUTRAL_PAPER}
                    boxShadowDefinition="2px 2px 3px rgba(0, 0, 0, 0.25)"
                    width="360px"
                    padding={CRMSpacing.MEDIUM}
                >
                    <SpacingColumn spacing={CRMSpacing.TINY}>
                        {/* TITLE */}
                        <CRMParagraph>
                            <WeightBold>
                                CLOSE/CHASE/CONTINUE FORM
                            </WeightBold>
                        </CRMParagraph>

                        {/* DETAILS... */}
                        <SpacingColumn spacing={CRMSpacing.TINY}>
                            {props.isInChaseMode &&
                                <CRMParagraph>
                                    <WeightBold>This Case is in Chase Mode</WeightBold>
                                </CRMParagraph>
                            }
                            <CRMParagraph>
                                <WeightBold>For when you need to:</WeightBold>
                            </CRMParagraph>
                            <Background padding={`0px 0px 0px ${CRMSpacing.TINY}`}>
                                <CRMParagraphBulletPoints
                                    bulletColor={CRMColors.NEUTRAL_INK}
                                    bullets={[
                                        "Close the case",
                                        "Put into/take out of chase mode",
                                        "Reopen the case",
                                    ]}
                                />
                            </Background>
                        </SpacingColumn>
                    </SpacingColumn>
                </Background>
            </Absolute>
        }
    </Relative>
};
