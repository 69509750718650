import React from "react";
import { TCRMFormInputWithoutCodec } from "./CRMFormInputTypes";
import { CRMDeferInputWithoutLabelFormInput } from "./CRMDeferInputWithoutLabelFormInput";
import CRMInputLabelAndErrorWrapComponent from "../CRMInputLabelAndErrorWrapComponent/CRMInputLabelAndErrorWrapComponent";

export const CRMDeferInputWithLabelFormInput: TCRMFormInputWithoutCodec<string | null> = (props) =>
<CRMInputLabelAndErrorWrapComponent label={props.label}>
    <CRMDeferInputWithoutLabelFormInput
        {...props}
    />
</CRMInputLabelAndErrorWrapComponent>;
