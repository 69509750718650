import React from "react";

export const FontRoboto = (props: React.PropsWithChildren<{}>): JSX.Element => (
    <span
        style={{
            fontFamily: "Roboto Mono, monospace",
            display: "inline-flex",
        }}
    >
        {props.children}
    </span>
);
