import React from "react";
import { useResolutionStatus } from "../../../hooks/useResolutionStatus";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { CRMColors } from "../../../models/CRMColors";
import { AnimationPopout } from "../../BuildingBlocks/AnimationPopout";
import { Padding } from "../../BuildingBlocks/Padding";
import { Relative } from "../../BuildingBlocks/Relative";
import { SpacingColumn } from "../../BuildingBlocks/SpacingColumn";
import { SpacingRow } from "../../BuildingBlocks/SpacingRow";
import { CRMEmptyPlaceholder } from "../../Simple/CRMEmptyPlaceholder/CRMEmptyPlaceholder";
import { CRMTableContentPlaceholder } from "../../Simple/CRMTableContentPlaceholder/CRMTableContentPlaceholder";
import { CRMThinColoredButton } from "../../Simple/CRMThinColoredButton/CRMThinColoredButton";
import { WeightSemiBold } from "../../WeightSemiBold/WeightSemiBold";
import { CRMConfirmationCountdown } from "../CRMConfirmationCountdown/CRMConfirmationCountdown";
import { CRMBlockPopover } from "../../CRM/CRMBlock/CRMBlockPopover/CRMBlockPopover";
import { TMarketingCampaignForm, TMarketingCampaignsForm } from "../../../../../domain/codecs/form/MarketingCampaignForm";
import { CRMMarketingCampaignRow } from "../CRMMarketingCampaignRow/CRMMarketingCampaignRow";
import { Background } from "../../BuildingBlocks/Background";

type TAction = (form: TMarketingCampaignForm, index: number) => void;

type TIntroducerTableProps = {
    form: TMarketingCampaignsForm;
    onOpen: TAction;
    onEdit: TAction;
    onArchive:  (form: TMarketingCampaignForm) => void;
    onLoadMore: () => void;
};

type TResolutionStatus = 
    "none"
    | "archiving-campaign"
;

export const CRMMarketingCampaignTable = (props: React.PropsWithChildren<TIntroducerTableProps>): JSX.Element => {

    const {
        resolutionStatus,
        resolutionData,
        setResolution,
        resetResolution
    } = useResolutionStatus<TResolutionStatus, TMarketingCampaignForm>({
        defaultStatus: "none"
    })

    const isShowingLoadMore = () => (
        props.form.children.counts.available_count > props.form.children.marketing_campaigns.length
        && !isLoading()
    );
    
    const isLoading = () => (
        props.form.status === "loading"
        || props.form.status === "submitting"
    );

    const isEmpty = () => props.form.children.marketing_campaigns.length === 0;

    // ACTIONS
    const onBeginArchive = (introducer: TMarketingCampaignForm) => 
        () => setResolution("archiving-campaign")(introducer)
    ;

    const onCompleteArchive = () => {
        props.onArchive(resolutionData as TMarketingCampaignForm);
        resetResolution();
    }

    return (
        <Relative>
            <SpacingColumn spacing={CRMSpacing.LARGE}>
                <SpacingColumn>
                    {/* ROWS */}
                    {(!isLoading() && !isEmpty()) && 
                        props.form.children.marketing_campaigns.map((campaign, index) => (
                            <CRMMarketingCampaignRow
                                key={index}
                                marketingCampaign={campaign}
                                onClick={() => props.onOpen(campaign, index)}
                                onEdit={() => props.onEdit(campaign, index)}
                                onDelete={onBeginArchive(campaign)}
                            />       
                        ))
                    }

                    {/* LOADING BAR */}
                    {(isLoading() && !isEmpty()) &&
                        <CRMTableContentPlaceholder
                            rowCount={props.form.children.marketing_campaigns.length * 2}
                        />
                    }

                    {/* EMPTY */}
                    {(!isLoading() && isEmpty()) &&
                        <Background padding={`${CRMSpacing.X_LARGE} ${CRMSpacing.LARGE}`}>
                            <CRMEmptyPlaceholder
                                iconSize="70px"
                                textGap={CRMSpacing.TINY}
                            >
                                No campaigns have been created.
                            </CRMEmptyPlaceholder>
                        </Background>
                    }
                </SpacingColumn>

                {/* LOAD MORE */}
                {isShowingLoadMore() &&
                    <AnimationPopout>
                        <Background padding={`0px 0px ${CRMSpacing.LARGE} 0px`}>
                            <SpacingRow justifyContent="center">
                                <CRMThinColoredButton
                                    backgroundColor={CRMColors.NEUTRAL_2}
                                    fontColor={CRMColors.NEUTRAL_12}
                                    label={<WeightSemiBold>See more</WeightSemiBold>}
                                    onClick={props.onLoadMore}
                                    icon="down-arrow"
                                />
                            </SpacingRow>
                        </Background>
                    </AnimationPopout>
                }
            </SpacingColumn>
            
            {/* CONFIRMATION POPCARD - SINGLE ARCHIVE */}
            {resolutionStatus === "archiving-campaign" &&
                <CRMBlockPopover>
                    <CRMConfirmationCountdown
                        title={`Archiving campaign!`}
                        iconName="archive"
                        primaryButtonMode="done"
                        duration={5000}
                        onUndo={resetResolution}
                        onComplete={onCompleteArchive}
                    /> 
                </CRMBlockPopover>
            }
        </Relative>
    );
};
