import React from "react";
import { TLegalEmailAttachment1 } from "../../../../../domain/codecs/LegalEmail";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { CRMColors } from "../../../models/CRMColors";
import { InlineFlex } from "../../BuildingBlocks/InlineFlex";
import { SpacingRow } from "../../BuildingBlocks/SpacingRow";
import { Text } from "../../BuildingBlocks/Text";
import { Truncate } from "../../BuildingBlocks/Truncate";
import { CRMIcon, TIcon } from "../../CRMIcon/CRMIcon";
import { WeightRegular } from "../../WeightRegular/WeightRegular";
import { CRMPill } from "../CRMPill/CRMPill";

type TAttachmentProps = {
    attachment: TLegalEmailAttachment1;
};

export const isSmallImage = (attachment: TLegalEmailAttachment1): boolean =>
    (
        attachment.mime_type === "image/png"
        || attachment.mime_type === "image/jpeg"
        || attachment.mime_type === "image/jpg"
        || attachment.mime_type === "image/gif"
        || attachment.mime_type === "image/bmp"
        || attachment.mime_type === "image/heic"
        || attachment.mime_type === "image/tiff"
        || attachment.mime_type === "image/svg+xml"
    )
        && (attachment.file_size_bytes || 0) <= 51200; // 50kb

export const hasVirus = (attachment: TLegalEmailAttachment1): boolean =>
    attachment.virus_detected;

export const isRelatedToCase = (attachment: TLegalEmailAttachment1): boolean =>
    attachment.is_related_to_case;

export const CRMEmailAttachment = (props: React.PropsWithChildren<TAttachmentProps>): JSX.Element => (
    <InlineFlex>
        {
            hasVirus(props.attachment) ? <UnsafeAttachment {...props} />
            : isRelatedToCase(props.attachment) ? <RelatedToCaseAttachment {...props} />
            : isSmallImage(props.attachment) ? <SmallAttachment {...props} />
            : <NotRelatedToCaseAttachment {...props} />
        }
    </InlineFlex>
);


const RelatedToCaseAttachment = (props: React.PropsWithChildren<TAttachmentProps>) => {
    return (
        <CRMPill
            title={`${props.attachment.related_to_case_file_type}: ${props.attachment.name} (This file has already been uploaded to a case)`}
            onClick={() => window.open(
                `${env.REACT_APP_API_URL}/web/email-attachment-download?fileToken=${window.encodeURIComponent(props.attachment.download_token)}`, 
                "_blank"
            )}
            colour="neutral-paper"
            borderColour={CRMColors.NEUTRAL_6}
        >
            <PillBody icon="file-clip-tick">
                {props.attachment.related_to_case_file_type} <WeightRegular> - {props.attachment.name}</WeightRegular>
            </PillBody>
        </CRMPill>
    )
}


const NotRelatedToCaseAttachment = (props: React.PropsWithChildren<TAttachmentProps>) => {
    return (
        <CRMPill
            title={`New file (not uploaded to a case): ${props.attachment.name}`}
            onClick={() => window.open(
                `${env.REACT_APP_API_URL}/web/email-attachment-download?fileToken=${window.encodeURIComponent(props.attachment.download_token)}`, 
                "_blank"
            )}
            colour="primary-12"
        >
            <PillBody icon="file-clip-notification">
                {props.attachment.name}
            </PillBody>
        </CRMPill>
    )
}


const SmallAttachment = (props: React.PropsWithChildren<TAttachmentProps>) => {
    return (
        <CRMPill
            title={props.attachment.name}
            onClick={() => window.open(
                `${env.REACT_APP_API_URL}/web/email-attachment-download?fileToken=${window.encodeURIComponent(props.attachment.download_token)}`, 
                "_blank"
            )}
            colour="neutral-10"
            borderColour={CRMColors.NEUTRAL_8}
        >
            <PillBody icon="file-clip-notification">
                {props.attachment.name}
            </PillBody>
        </CRMPill>
    );
}

const UnsafeAttachment = (props: React.PropsWithChildren<TAttachmentProps>) => {
    return (
        <CRMPill
            title={`This file either has a virus or is encrypted, as such we have blocked it from being opened. ${props.attachment.name}`}
            colour="highlights-instructional-brick-12"
            borderColour={CRMColors.HIGHLIGHTS_INSTRUCTIONAL_BRICK_8}
        >
            <PillBody icon="error-outline">
                {props.attachment.name}
            </PillBody>
        </CRMPill>
    );
}


type TPillBodyProps = {
    icon: TIcon;
}

const PillBody = (props: React.PropsWithChildren<TPillBodyProps>) => {
    return (
        <SpacingRow spacing={CRMSpacing.TINY}>
            <CRMIcon
                iconName={props.icon}
                colour="neutral-ink"
                size="14px"
            />
            <Text 
                lineHeight={1.2}
                weight={500}
            >
                <Truncate maxWidth="300px">
                    {props.children}
                </Truncate>
            </Text>
        </SpacingRow>
    );
}
