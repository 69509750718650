import React from "react";
import { CRMIcon } from "../../CRMIcon/CRMIcon";
import { CRMColors } from "../../../models/CRMColors";
import { SpacingRow } from "../../BuildingBlocks/SpacingRow";
import { Background } from "../../BuildingBlocks/Background";
import { FontQuicksand } from "../../BuildingBlocks/FontQuicksand";
import { CRMFontSizes } from "../../../models/CRMFontSizes";
import { Text } from "../../BuildingBlocks/Text";
import { THexColor, TPixelSizeShortHand } from "../../../models/StringLiterals";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { Flex } from "../../BuildingBlocks/Flex";

type TRoadblockActionLogProps = {
    type?: "blue-pill" | "green-pill" | "yellow-pill" | "grey-pill" | "regular";
};

export const CRMRoadblockActionLog = (props: React.PropsWithChildren<TRoadblockActionLogProps>): JSX.Element => {

    const isPill = () => !!props.type && props.type !== "regular";

    const getPadding = (): TPixelSizeShortHand => 
        isPill() ? 
            `3px ${CRMSpacing.SMALL} 3px ${CRMSpacing.TINY}`
            : `0px ${CRMSpacing.SMALL} 0px ${CRMSpacing.TINY}`
    ;

    const getBackgroundColor = (): THexColor | undefined => {
        switch (props.type) {
            case "blue-pill":
                return CRMColors.PRIMARY_4;
            case "green-pill":
                return CRMColors.HIGHLIGHTS_POSITIVE_GREEN_2;
            case "yellow-pill":
                return CRMColors.HIGHLIGHTS_PERTINENT_YELLOW_4;
            case "grey-pill":
                return CRMColors.NEUTRAL_8;
            case "regular":
            default:
                return undefined
        }
    }
    
    const getTextColor = (): THexColor => {
        switch (props.type) {
            case "blue-pill":
                return CRMColors.NEUTRAL_PAPER;
            case "green-pill":
                return CRMColors.NEUTRAL_PAPER;
            case "yellow-pill":
                return CRMColors.NEUTRAL_0;
            case "grey-pill":
                return CRMColors.NEUTRAL_INK;
            case "regular":
            default:
                return CRMColors.PRIMARY_2
        }
    }

    const getTextWeight = () => isPill() ? 700 : 500;

    return (
        <Flex>
            <Background
                display="inline-flex"
                padding={getPadding()}
                color={getBackgroundColor()}
                borderRadius="8px"
            >
                <SpacingRow spacing={CRMSpacing.TINY}>
                    <CRMIcon
                        iconName="action"
                        colour="hex"
                        size="20px"
                        hexColour={getTextColor()}
                    />

                    <FontQuicksand>
                        <Text 
                            size={CRMFontSizes.SMALL_PLUS} 
                            color={getTextColor()}
                            weight={getTextWeight()}
                        >
                            {props.children}
                        </Text>
                    </FontQuicksand>
                </SpacingRow>
            </Background>
        </Flex>
    );
};
