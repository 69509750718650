import React from "react";
import { SpacingColumn } from "../../components/BuildingBlocks/SpacingColumn";
import { SpacingRow } from "../../components/BuildingBlocks/SpacingRow";
import { CRMCardOutside } from "../../components/CRMCardOutside/CRMCardOutside";
import { CRMListingSellerPartyFormContainer } from "../CRMListingSellerPartyFormContainer/CRMListingSellerPartyFormContainer";
import { CRMSpacing } from "../../models/CRMSpacing";
import { TContainerStateProps } from "../../state/TContainerStateProps";
import { CRMPadding } from "../../components/Simple/CRMPadding/CRMPadding";
import { CRMTitleSection } from "../../components/CRMTitleSection/CRMTitleSection";
import { CRMSpacingColumn } from "../../components/CRMSpacingColumn/CRMSpacingColumn";
import { CRMAutosaveIndicatorWrap } from "../../components/CRM/CRMAutosaveIndicatorWrap/CRMAutosaveIndicatorWrap";
import { array } from "fp-ts/lib";
import { pipe } from "fp-ts/lib/function";
import { FormStatus_highestPriority } from "../../../../shared/src/codecs/codec";
import { CRMListingIntroducerRefNotesCard } from "../../components/CRMListingIntroducerRefNotesCard/CRMListingIntroducerRefNotesCard";
import { FontSignikaNegative } from "../../components/BuildingBlocks/FontSignikaNegative";
import { Text } from "../../components/BuildingBlocks/Text";
import { WeightBold } from "../../components/WeightBold/WeightBold";
import { Uppercase } from "../../components/Uppercase/Uppercase";
import { CRMFontSizes } from "../../models/CRMFontSizes";

class CRMListingPageSellersContainer extends React.Component<React.PropsWithChildren<TContainerStateProps>> {
    public render(): JSX.Element {

        const introducerRef = () => this.props.state.forms.listing_page.data.output.read_only.introducer_external_reference_id;
        const introducerNotes = () => this.props.state.forms.listing_page.data.output.read_only.introducer_notes;
        
        return (
            <SpacingRow spacing={CRMSpacing.X_LARGE} childSize="1fr 1fr" alignItems="flex-start">
                <SpacingColumn spacing={CRMSpacing.LARGE}>
                <CRMCardOutside
                    borderRounding="right"
                    shadow={true}
                >
                    <CRMPadding size="large">
                        <CRMAutosaveIndicatorWrap
                            status={pipe(
                                [
                                    ...pipe(
                                        this.props.state.forms.listing_page.data.output.listing_seller_users,
                                        array.map((form) => form.status),
                                    ),
                                    ...pipe(
                                        this.props.state.forms.listing_page.data.output.listing_seller_users,
                                        array.map((form) => form.children.phone_numbers.map((ph) => ph.status)),
                                        array.flatten,
                                    )
                                ],
                                FormStatus_highestPriority,
                            )}
                        >
                            <CRMSpacingColumn spacing="large">
                                <CRMTitleSection>
                                    Sellers
                                </CRMTitleSection>
                                <CRMListingSellerPartyFormContainer
                                    state={this.props.state}
                                    dispatch={this.props.dispatch}
                                />
                            </CRMSpacingColumn>
                        </CRMAutosaveIndicatorWrap>
                    </CRMPadding>
                </CRMCardOutside>
                </SpacingColumn>

                {/* COLUMN - INTRODUCER DETAILS */}
                {(introducerRef() !== "" || introducerNotes() !== "") &&
                    <SpacingColumn spacing={CRMSpacing.MEDIUM}>
                        {/* Title */}
                        <FontSignikaNegative>
                            <Text size={CRMFontSizes.LARGE}>
                                <WeightBold>
                                    <Uppercase>
                                        Introducer Details
                                    </Uppercase>
                                </WeightBold>
                            </Text>
                        </FontSignikaNegative>

                        <CRMListingIntroducerRefNotesCard
                            introducerRef={introducerRef()}
                            introducerNotes={introducerNotes()}
                            dateOfDeath={this.props.state.forms.listing_page.data.output.form.edited.date_of_death || undefined}
                        />
                    </SpacingColumn>
                }
            </SpacingRow>
        );
    }
}

export default CRMListingPageSellersContainer;
