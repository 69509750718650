import { option, array } from "fp-ts";
import { Lens } from "monocle-ts";
import { TState, TUnpackArray } from "./lensBaseTypes";

interface ILensFindFirstWhere<S> {
    // 5 level lens path guard
    <
        K1 extends keyof S,
        K2 extends keyof S[K1],
        K3 extends keyof S[K1][K2],
        K4 extends keyof S[K1][K2][K3],
        K5 extends keyof S[K1][K2][K3][K4]
    >
    (
        path: [K1, K2, K3, K4, K5],
        whereCallback: (value: TUnpackArray<S[K1][K2][K3][K4][K5]>) => boolean
    ): option.Option<TUnpackArray<S[K1][K2][K3][K4][K5]>>;
    // 4 level lens path guard
    <
        K1 extends keyof S,
        K2 extends keyof S[K1],
        K3 extends keyof S[K1][K2],
        K4 extends keyof S[K1][K2][K3]
    >
    (
        path: [K1, K2, K3, K4],
        whereCallback: (value: TUnpackArray<S[K1][K2][K3][K4]>) => boolean
    ): option.Option<TUnpackArray<S[K1][K2][K3][K4]>>;
    // 3 level lens path guard
    <
        K1 extends keyof S,
        K2 extends keyof S[K1],
        K3 extends keyof S[K1][K2]
    >
    (
        path: [K1, K2, K3],
        whereCallback: (value: TUnpackArray<S[K1][K2][K3]>) => boolean
    ): option.Option<TUnpackArray<S[K1][K2][K3]>>;
    // 2 level lens path guard
    <
        K1 extends keyof S,
        K2 extends keyof S[K1]
    >
    (
        path: [K1, K2],
        whereCallback: (value: TUnpackArray<S[K1][K2]>) => boolean
    ): option.Option<TUnpackArray<S[K1][K2]>>;
    // 1 level lens path guard
    <K1 extends keyof S>
    (
        path: [K1],
        whereCallback: (value: TUnpackArray<S[K1]>) => boolean
    ): option.Option<TUnpackArray<S[K1]>>;
}

export const findFirstWhere = (givenState: TState): ILensFindFirstWhere<TState> =>
    // There is no way to create paramater overloads here so we set to any
    (lensPath: any, whereCallback: any) => { // eslint-disable-line
        const value = Lens.fromPath<TState>()(lensPath).get(givenState) as unknown;
        if (value instanceof Array && value.length !== undefined) {
            return array.findFirst(whereCallback)(value);
        }
        return option.none;
};
