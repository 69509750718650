import React from "react";
import { useDropdown, IOption } from "../../../../hooks/UseDropdown";
import { FrontSpacing } from "../../../../models/FrontSpacing";
import { SpacingRow } from "../../../BuildingBlocks/SpacingRow";
import { FrontIcon } from "../FrontIcon/FrontIcon";

interface IFrontDropdownProps <A extends string, B extends A>{
    value: Array<B>;
    options: Array<IOption<A>>;
    placeholder?: string;
    limitedFaceText?: boolean;
    onChange: (value: Array<A>) => void; 
};

export const FrontMultiDropdown = <A extends string, B extends A>(props: React.PropsWithChildren<IFrontDropdownProps<A, B>>): JSX.Element => {

    const {
        ref,
        isOpen,
        toggleOpen,
        getFaceText,
        getFaceTextWithCount,
        isOptionSelected,
        toggleSelectedValue,
        isAnythingSelected
    } = useDropdown<A, IOption<A>>(props.value, props.options);

    return (
        <div
            ref={ref}
            className={`front-dropdown`}
        >
            <div
                className="front-dropdown__face"
                onClick={toggleOpen}
            >
                <SpacingRow justifyContent="space-between">

                    {!isAnythingSelected() &&
                        <div className="front-dropdown__text front-dropdown__text--placeholder">
                            {props.placeholder || ""}
                        </div>
                    }
                    
                    {isAnythingSelected() &&
                        <div className="front-dropdown__text">
                            {props.limitedFaceText ? getFaceTextWithCount() : getFaceText()}
                        </div>
                    }

                    <FrontIcon
                        colour="neutral-ink"
                        iconName={isOpen ? "chevron-up" : "chevron-down"}
                        size="small"
                    />
                </SpacingRow>
            </div>

            {isOpen &&
                <div className="front-dropdown__options">
                    {props.options.map((option, index) => (
                        <div
                            key={index}
                            className={`front-dropdown__option`}
                            onClick={() => {
                                props.onChange(toggleSelectedValue(option.value));
                            }}
                        >
                            <SpacingRow spacing={FrontSpacing.SMALL_1}>
                                <FrontIcon
                                    iconName={isOptionSelected(option.value) ? "checked" : "unchecked"}
                                    colour="neutral-ink"
                                />
                                <span>{option.label}</span>
                            </SpacingRow>
                        </div>
                    ))}
                </div>
            }
        </div>
    );
};
