import React from "react";
import { IOption } from "./../../hooks/UseDropdown";
import { CRMDropdownOption } from "../Simple/CRMDropdownOption/CRMDropdownOption";
import { WeightBold } from "../WeightBold/WeightBold";
import { CRMDropdownBase } from "../CRMDropdownBase/CRMDropdownBase";
import { CRMDropdownOptionBox } from "../Simple/CRMDropdownOptionBox/CRMDropdownOptionBox";
import { CRMDropdownFace } from "../Simple/CRMDropdownFace/CRMDropdownFace";
import { TextColor } from "../BuildingBlocks/TextColor";
import { CRMColors } from "../../models/CRMColors";

interface ICRMMultiSelectDropdownProps<A extends string> {
    valuePrefix?: string;
    value: Array<A>;
    options: Array<IOption<A>>;
    onChange: (value: Array<A>) => void;
    placeholder?: string;
    displayError?: boolean;
    disabled?: boolean;
}

export const CRMMultiSelectDropdownComponent = <A extends string>(props: ICRMMultiSelectDropdownProps<A>): JSX.Element => {

    return (
        <CRMDropdownBase<A>
            FaceComponent={(faceProps) => (
                <CRMDropdownFace
                    isOpen={faceProps.isOpen}
                    toggleOpen={faceProps.toggleOpen}
                    disabled={faceProps.disabled}
                    displayError={faceProps.displayError}
                >
                    {faceProps.value.length === 0 &&
                        <TextColor color={CRMColors.NEUTRAL_2}>
                            {faceProps.placeholder || "Select Value"}
                        </TextColor>
                    }

                    {faceProps.value.length > 0 &&
                        <div>
                            <WeightBold>{faceProps.valuePrefix}</WeightBold> {faceProps.faceText}
                        </div>
                    }
                </CRMDropdownFace>
            )}
            OptionsComponent={(optionProps) => (
                <CRMDropdownOptionBox>
                    {props.options.map((selectOption, key) => (
                        <CRMDropdownOption
                            key={key}
                            hasCheckbox={true}
                            isSelected={optionProps.isOptionSelected(selectOption.value)}
                            label={selectOption.label}
                            onClick={() => props.onChange(optionProps.toggleSelectedValue(selectOption.value))}
                        />
                    ))}
                </CRMDropdownOptionBox>
            )}
            {...props}
        />
    );
};
