import React from "react";
import * as User4 from "../../../../domain/models/User4";
import * as UserPhoneNumber1 from "../../../../domain/models/UserPhoneNumber1";
import { CRMColumns } from "../CRMColumns/CRMColumns";
import { CRMIcon } from "../CRMIcon/CRMIcon";
import { CRMParagraphImportant } from "../Simple/CRMParagraphImportant/CRMParagraphImportant";
import { ord, array } from "fp-ts";
import { CRMParagraph } from "../Simple/CRMParagraph/CRMParagraph";
import { Ord } from "fp-ts/lib/number";
import { pipe } from "fp-ts/lib/function";
import { SpacingColumn } from "../BuildingBlocks/SpacingColumn";
import { CRMSpacing } from "../../models/CRMSpacing";

export class CRMSellerPersonComponent extends React.Component<{
    user: User4.T;
}> {
    public render (): JSX.Element {
        return (
            <SpacingColumn spacing={CRMSpacing.TINY}>
                {/* User's name */}
                <CRMColumns
                    padding="tiny"
                    wrapOnMobile={false}
                    alignItems="center"
                    columns={[
                        {
                            flex: 0,
                            content: <CRMIcon
                                iconName="people"
                                colour="neutral-ink"
                                size="medium"
                            />,
                        },
                        {
                            flex: 1,
                            content: <CRMParagraphImportant>
                                {this.props.user.first_name} {this.props.user.last_name}
                            </CRMParagraphImportant>,
                        },
                    ]}
                />

                {/* User's phone numbers */}
                {pipe(
                        this.props.user.phone_numbers,
                        array.sortBy([ord.contramap((phoneNumber: UserPhoneNumber1.T) =>
                            phoneNumber.primary_number ? 0 : 1,
                        )(Ord)]),
                        array.mapWithIndex((i, phoneNumber) =>
                            <CRMParagraph key={i}>
                                <span className={
                                    `crm-seller-person__phone-number-is-primary--${phoneNumber.primary_number}`
                                }>
                                    {phoneNumber.phone_number}
                                </span>
                            </CRMParagraph>
                        ),
                    )
                }

                {/* User's email address */}
                {this.props.user.email && <CRMParagraph>
                    {this.props.user.email}
                </CRMParagraph>}
            </SpacingColumn>
        );
    }
}
