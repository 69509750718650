import * as EnquiryPaymentMethod1 from "./EnquiryPaymentMethod1";
import * as util from "../../shared/src/util";

export type T =
    "Cash"
    | "Mortgage"
    | "Bridging loan"
    | "Other"
    | "Unknown";

export const fromEnquiryPaymentMethod1 = (p: EnquiryPaymentMethod1.T): T => {
    switch (p) {
        case "cash":
            return "Cash";
        case "mortgage":
            return "Mortgage";
        case "bridging_loan":
            return "Bridging loan";
        case "other":
            return "Other";
        case "unknown":
            return "Unknown";
        default:
            return util.requireExhaustive(p);
    }
};
