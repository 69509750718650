import * as t from "io-ts";
import * as PartySellingPropertyState from "./PartySellingPropertyState";
import * as EnquiryPaymentMethod1 from "./EnquiryPaymentMethod1";
import * as ListingEnquiriesOffer6 from "./ListingEnquiriesOffer6";
import * as BuyerOffer1 from "./BuyerOffer1";
import * as BuyerReadyHasProvidedSolictorDetails from "./BuyerReadyHasProvidedSolicitorDetails";

export const codec = t.union([
    t.literal("less_than_2_months"),
    t.literal("more_than_2_months"),
]);

type T = t.TypeOf<typeof codec>;

const calculateBuyerReadyTimeToComplete = (obj: {
    selling_property_state: PartySellingPropertyState.T;
    payment_method: EnquiryPaymentMethod1.T;
    mortgage_broker_has_vouched: boolean;
    has_provided_solicitor_details: BuyerReadyHasProvidedSolictorDetails.T;
}): T =>
    obj.has_provided_solicitor_details
    && obj.selling_property_state === "no_property_to_sell"
    && (
        obj.payment_method === "cash"
        || obj.mortgage_broker_has_vouched
    )
        ? "less_than_2_months"
        : "more_than_2_months";

export const fromListingEnquiriesOffer6 = (m: ListingEnquiriesOffer6.T): T => calculateBuyerReadyTimeToComplete({
    has_provided_solicitor_details: BuyerReadyHasProvidedSolictorDetails.fromListingEnquiriesOffer6(m),
    mortgage_broker_has_vouched: m.mortgage_broker_has_vouched,
    payment_method: m.payment_method,
    selling_property_state: m.selling_property_state,
});

export const fromBuyerOffer1 = (m: BuyerOffer1.T): T => calculateBuyerReadyTimeToComplete({
    has_provided_solicitor_details: BuyerReadyHasProvidedSolictorDetails.fromBuyerEnquiry1(m.enquiry),
    mortgage_broker_has_vouched: m.enquiry.mortgage_broker_has_vouched,
    payment_method: m.enquiry.payment_method,
    selling_property_state: m.party.selling_property_state,
});

