import React from "react";
import { FrontColors } from "../../../../models/FrontColors";
import { FrontSpacing } from "../../../../models/FrontSpacing";
import { Padding } from "../../../BuildingBlocks/Padding";
import { SpacingRow } from "../../../BuildingBlocks/SpacingRow";

type TBulletpointProps = {
    pointAdjust?: `${number}px`;
};

export const FrontBulletpoint = (props: React.PropsWithChildren<TBulletpointProps>): JSX.Element => {

    return (
        <SpacingRow
            spacing={FrontSpacing.SMALL_2}
            alignItems="flex-start"
        >
            <Padding type="top" spacing={props.pointAdjust || "8px"}>
                <div style={{
                    borderRadius: "100px",
                    width: "5px",
                    height: "5px",
                    backgroundColor: FrontColors.NEUTRAL_0, 
                }} />
            </Padding>

            {props.children}
        </SpacingRow>
    );
};
