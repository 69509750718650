import React from "react";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { CRMColors } from "../../../models/CRMColors";
import { Absolute } from "../../BuildingBlocks/Absolute";
import { BackgroundColour } from "../../BuildingBlocks/BackgroundColour";
import { Border } from "../../BuildingBlocks/Border";
import { Padding } from "../../BuildingBlocks/Padding";
import { Relative } from "../../BuildingBlocks/Relative";
import { Shape } from "../../BuildingBlocks/Shape";
import { SpacingColumn } from "../../BuildingBlocks/SpacingColumn";
import { ZIndex } from "../../BuildingBlocks/ZIndex";
import { CRMEmptyPlaceholder } from "../../Simple/CRMEmptyPlaceholder/CRMEmptyPlaceholder";

export const CRMTimelineWrapper = (props: React.PropsWithChildren<{
    entries: Array<JSX.Element>;
}>): JSX.Element => {

    const isEmpty = () => props.entries.length === 0;

    return (
        <Relative width="100%">

            {/* IS EMPTY - EMPTY PLACEHOLDER */}
            {isEmpty() &&
                <Padding spacing={CRMSpacing.LARGE}>
                    <CRMEmptyPlaceholder
                        iconSize="70px"
                        textGap={CRMSpacing.TINY}
                    >
                        All's quiet on the customer satisfaction front.
                    </CRMEmptyPlaceholder>
                </Padding>
            }

            {/* HAS ENTRIES - DISPLAY THE LINE */}
            {!isEmpty() &&
                <Absolute
                    top="0"
                    left={CRMSpacing.MEDIUM} height="100%"
                    zIndex={2}
                >
                    <Border
                        type="left"
                        definition={`solid 2px ${CRMColors.NEUTRAL_10}`}
                        height="100%"
                    />
                    <Shape
                        width="6px"
                        height="6px"
                        borderRadius="100px"
                        margin="-6px 0px 0px -2px"
                        color={CRMColors.NEUTRAL_10}
                    />
                </Absolute>
            }

            {/* HAS ENTRIES - TIMELINE ENTRIES */}
            {!isEmpty() &&
                <SpacingColumn spacing={CRMSpacing.MEDIUM}>
                    {props.entries.map((entry, key) =>
                        <ZIndex
                            key={key} 
                            zIndex={3}
                        >
                            <BackgroundColour colour={CRMColors.NEUTRAL_PAPER}>
                                <Padding type="top" spacing={CRMSpacing.TINY} />
                            </BackgroundColour>
                            
                            {entry}
                        </ZIndex>
                    )}
                </SpacingColumn>
            }
        </Relative>
    );
};
