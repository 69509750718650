import React, { useState } from "react";
import { TContainerStateProps } from "../../state/TContainerStateProps";
import { CRMPageWrap } from "../../components/Simple/CRMPageWrap/CRMPageWrap";
import { CRMLegalMenuBarContainer } from "../../containers/CRMLegalMenuBarContainer/CRMLegalMenuBarContainer"
import { Padding } from "../../components/BuildingBlocks/Padding";
import { SpacingRow } from "../../components/BuildingBlocks/SpacingRow";
import { CRMSpacing } from "../../models/CRMSpacing";
import { CRMIntroducersBlock } from "../../components/Complex/CRMIntroducersBlock/CRMIntroducersBlock";
import { CRMQuoteRatesBlock } from "../../components/Complex/CRMQuoteRatesBlock/CRMQuoteRatesBlock";
import { CRMCaseAutoAssignmentBlock } from "../../components/Complex/CRMCaseAutoAssignmentBlock/CRMCaseAutoAssignmentBlock";
import { pipe } from "fp-ts/lib/function";
import { array, option } from "fp-ts";
import { IntroducerForm } from "../../../../domain/codecs/form/IntroducerForm";
import { CRMBlockScrollCacheView } from "../../components/CRM/CRMBlock/CRMBlockViews/CRMBlockViews";
import { CRMBlock } from "../../components/CRM/CRMBlock/CRMBlock/CRMBlock";

const CRMLegalIntroducersPage = (props: TContainerStateProps): JSX.Element => {

    const [secondBlockChoice, setSecondBlockChoice] = useState<string>("rates");

    const shouldShowSecondBlock =
        sessionUser.user_role === "admin"
        && props.state.forms.introducers_block.introducer_id_open;

    const caseHandlers = props.state.forms.introducers_block.data.children.case_handlers;
    const caseAssistants = props.state.forms.introducers_block.data.children.case_assistants;

    const getOpenIntroducerForm = () =>
        pipe(
            props.state.forms.introducers_block.data.children.introducers,
            array.findFirst(({edited}) => edited.id === props.state.forms.introducers_block.introducer_id_open),
            option.fold(
                IntroducerForm.newDefault,
                (form) => form,
            )
        );

    return (
        <CRMPageWrap>
            {/* HEADER */}
            <CRMLegalMenuBarContainer {...props} />

            {/* BODY */}
            <Padding
                type="custom" 
                spacing={`${CRMSpacing.X_LARGE} ${CRMSpacing.X_LARGE} 0px`}
            >
                <SpacingRow
                    spacing={CRMSpacing.X_LARGE}
                    alignItems="flex-start"
                >
                    {/* BLOCK - INTRODUCERS */}
                    <CRMBlock
                        mode="fixed-option"
                        fixedOption={{
                            icon: "introducer",
                            title: "Introducers"
                        }}
                        optionGroups={[]}
                    >
                        <CRMIntroducersBlock
                            introducerIdOpen={props.state.forms.introducers_block.introducer_id_open}
                            form={props.state.forms.introducers_block.data}
                            onDeleteUploadAgreement={(payload) => props.dispatch({
                                type: "LEGAL_CASE_INTRODUCER_AGREEMENT_DELETE",
                                payload
                            })}
                            onUploadAgreement={(payload) => props.dispatch({
                                type: "LEGAL_CASE_INTRODUCER_AGREEMENT_UPLOAD",
                                payload
                            })}
                            onAddNewIntroducer={(payload) => props.dispatch({
                                type: "LEGAL_CASE_INTRODUCER_CREATE",
                                payload
                            })}
                            onArchive={(payload) => props.dispatch({
                                type: "LEGAL_CASE_INTRODUCER_DELETE",
                                payload
                            })}
                            onChange={(payload) => props.dispatch({
                                type: "LEGAL_CASE_INTRODUCER_CHANGE",
                                payload
                            })}
                            onSave={(payload) => props.dispatch({
                                type: "LEGAL_CASE_INTRODUCER_SAVE",
                                payload
                            })}
                            onLoadMore={() => props.dispatch({
                                type: "LEGAL_CASE_INTRODUCER_LOAD_MORE",
                                payload: null,
                            })}
                            onOpen={(payload) => props.dispatch({
                                type: "LEGAL_CASE_INTRODUCER_OPEN",
                                payload
                            })}
                            onClose={() => props.dispatch({
                                type: "LEGAL_CASE_INTRODUCER_CLOSE",
                                payload: null,
                            })}
                        />
                    </CRMBlock>

                    {/* BLOCK - QUOTE RATES / CASE AUTO ASSIGNMENT */}
                    {shouldShowSecondBlock &&
                        <CRMBlock
                            mode="select-options"
                            selectedOption={secondBlockChoice}
                            onChange={(name) => setSecondBlockChoice(name)}
                            optionGroups={[
                                {
                                    name: "Introducer's Blocks",
                                    options: {
                                        rates: {
                                            icon: "quote",
                                            title: "Rates",
                                        },
                                        auto_assignment: {
                                            icon: "person-tick",
                                            title: "Case Auto Assignment",
                                        }
                                    }
                                }
                            ]}
                        >
                            {secondBlockChoice === "rates" &&
                                <CRMQuoteRatesBlock
                                    form={props.state.forms.quote_rates_page.data}
                                    activeTypeVisible={props.state.forms.quote_rates_page.active_type_visible}
                                    onActiveTypeVisibleChange={(payload) => props.dispatch({
                                        type: "CRM_QUOTE_RATES_ACTIVE_TYPE_CHANGE",
                                        payload,
                                    })}
                                    onCreateNewRate={(payload) => props.dispatch({
                                        type: "CRM_QUOTE_RATES_CREATE",
                                        payload,
                                    })}
                                    onChangeNewRate={(payload) => props.dispatch({
                                        type: "CRM_QUOTE_RATES_CHANGE",
                                        payload,
                                    })}
                                    onSaveNewRate={(payload) => props.dispatch({
                                        type: "CRM_QUOTE_RATES_SAVE",
                                        payload,
                                    })}
                                    onToggleDeleteRateOnNextPublish={(payload) => props.dispatch({
                                        type: "CRM_QUOTE_RATES_TOGGLE_DELETE_ON_NEXT_PUBLISH",
                                        payload,
                                    })}
                                    onReplaceRate={(payload) => props.dispatch({
                                        type: "CRM_QUOTE_RATES_REPLACE",
                                        payload,
                                    })}
                                    onDeleteRate={(payload) => props.dispatch({
                                        type: "CRM_QUOTE_RATES_DELETE",
                                        payload,
                                    })}
                                    onPublishRateChanges={(payload) => props.dispatch({
                                        type: "CRM_QUOTE_RATES_PUBLISH",
                                        payload,
                                    })}
                                />
                            }
                            
                            {secondBlockChoice === "auto_assignment" &&
                                <CRMBlockScrollCacheView isShowing={true}>
                                    <CRMCaseAutoAssignmentBlock
                                        assignmentForms={getOpenIntroducerForm().children.case_auto_assignment_forms}
                                        caseHandlers={caseHandlers}
                                        caseAssistants={caseAssistants}
                                        onChange={(payload) => props.dispatch({
                                            type: "CRM_INTRODUCER_CASE_AUTO_ASSIGNMENT_CHANGE",
                                            payload,
                                        })}
                                    />
                                </CRMBlockScrollCacheView>
                            }
                        </CRMBlock>
                    }
                </SpacingRow>
            </Padding>
        </CRMPageWrap>
    );
};

export default CRMLegalIntroducersPage;