import React from "react";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { SpacingColumn } from "../../BuildingBlocks/SpacingColumn";
import { CRMTextContentPlaceholder } from "../CRMTextContentPlaceholder/CRMTextContentPlaceholder";
import { CRMSpacedContentPlaceholder } from "../CRMSpacedContentPlaceholder/CRMSpacedContentPlaceholder";
import { TPercentageSize, TPixelSize } from "../../../models/StringLiterals";

type TShapeTriple = [
    "text" | "image",
    number,
    TPercentageSize | TPixelSize
];

type TRichContentPlaceholderProps = {
    shape: Array<TShapeTriple>;
};

export const CRMRichContentPlaceholder = (props: React.PropsWithChildren<TRichContentPlaceholderProps>): JSX.Element => {

    return (
        <SpacingColumn spacing={CRMSpacing.LARGE}>
            {props.shape.map(([ type, count, size ], index) => {
                if (type === "text") {
                    return (
                        <CRMTextContentPlaceholder
                            key={index}
                            rowCount={count}
                            mode={size}
                        />
                    );
                }

                return (
                    <CRMSpacedContentPlaceholder
                        key={index}
                        count={count}
                        height={size}
                    />
                )
            })}
        </SpacingColumn>
    );
};
