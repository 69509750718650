import React from "react";
import { TContainerStateProps } from "../../state/TContainerStateProps";
import { CRMTitleSection } from "../../components/CRMTitleSection/CRMTitleSection";
import { CRMSpacer } from "../../components/CRMSpacer/CRMSpacer";
import { CRMAutosaveIndicatorWrap } from "../../components/CRM/CRMAutosaveIndicatorWrap/CRMAutosaveIndicatorWrap";
import { CasePostCompletion, CaseRegistrationSubmissionRequisitionsAndComplete, TCaseRegistrationSubmissionRequisitionsAndComplete } from "../../../../domain/codecs/Cases";
import { CRMCodecEditForm } from "../../components/CRMCodecEditForm/CRMCodecEditForm";
import { CRMFormErrorComponent } from "../../components/CRMFormErrorComponent/CRMFormErrorComponent";
import { doesErrorCodeExist } from "../../../../shared/src/codecs/errors";
import { CRMSpacingColumn } from "../../components/CRMSpacingColumn/CRMSpacingColumn";
import { SpacingColumn } from "../../components/BuildingBlocks/SpacingColumn";
import { CRMSpacing } from "../../models/CRMSpacing";
import CRMInputLabelAndErrorWrapComponent from "../../components/CRMInputLabelAndErrorWrapComponent/CRMInputLabelAndErrorWrapComponent";
import { CRMRadioListComponent } from "../../components/CRMInputs/CRMRadioListComponent/CRMRadioListComponent";
import { DateTime } from "luxon";
import { getFormattedDateStringFromString } from "../../../../domain/models/ISODateTime";

const CRMLegalCasePostCompletionContainer = (props: TContainerStateProps): JSX.Element => {

    const hiddenFields: Array<keyof TCaseRegistrationSubmissionRequisitionsAndComplete> = [
        ...props.state.forms.case_details_page.data.output.details.edited.registration_requisitions_exist === null
         ? [
            "registration_requisitions_details",
            "registration_requisitions_resolved",
            "resolve_requisition_defer"
        ] as const : [],
        ...props.state.forms.case_details_page.data.output.details.edited.registration_submission_cancelled === null
        ? [
            "registration_cancellation_details",
            "registration_resubmitted",
            "registration_resubmission_defer"
        ] as const : []
    ]

    return (
        <CRMAutosaveIndicatorWrap
            status={props.state.forms.case_details_page.data.output.details.status}
        >
            <CRMTitleSection>
                Post-Completion
            </CRMTitleSection>
            <CRMSpacer size="large" />
            <CRMCodecEditForm
                codec={CasePostCompletion}
                model={props.state.forms.case_details_page.data.output.details.edited}
                validationErrors={props.state.forms.case_details_page.data.output.details.validationErrors}
                columns={1}
                onChange={(edited) => {
                    props.dispatch({
                        type: "LEGAL_CASE_DETAILS_CHANGE",
                        payload: {
                            ...props.state.forms.case_details_page.data.output.details,
                            edited: {
                                ...props.state.forms.case_details_page.data.output.details.edited,
                                ...edited,
                            }
                        }
                    });
                } } />
            {doesErrorCodeExist("CaseLedgerHasNotBeenFinalised", props.state.forms.case_details_page.data.output.details.validationErrors) && <>
                <CRMSpacer size="medium" />
                <CRMFormErrorComponent
                    errorMessage="The case ledger has not yet been balanced/finalised." />
            </>}
            {doesErrorCodeExist("NotAllCaseLedgerCreditEntriesMarkedAsPaid", props.state.forms.case_details_page.data.output.details.validationErrors) && <>
                <CRMSpacer size="medium" />
                <CRMFormErrorComponent
                    errorMessage="Not all case ledger credit entries have been marked as paid. Update these in the ledger section." />
            </>}
            <CRMSpacer size="large" />
            <CRMSpacingColumn spacing="large">
                <SpacingColumn spacing={CRMSpacing.MEDIUM}>
                    {/* REGISTRATION SUBMITTED */}
                    <CRMInputLabelAndErrorWrapComponent
                        label="Registration submitted"
                        guidance={props.state.forms.case_details_page.data.output.details.edited.registration_submitted
                            ? `Submitted: ${getFormattedDateStringFromString(props.state.forms.case_details_page.data.output.details.edited.registration_submitted, "d MMM y")}`
                            : undefined}
                    >
                        <CRMRadioListComponent<boolean, boolean>
                            value={!!props.state.forms.case_details_page.data.output.details.edited.registration_submitted}
                            direction="row"
                            options={[
                                {
                                    value: true,
                                    text: "Yes",
                                },
                                {
                                    value: false,
                                    text: "No",
                                },
                            ]}
                            onChange={(registration_submitted) => props.dispatch({
                                type: "LEGAL_CASE_DETAILS_CHANGE",
                                payload: {
                                    ...props.state.forms.case_details_page.data.output.details,
                                    edited: {
                                        ...props.state.forms.case_details_page.data.output.details.edited,
                                        registration_submitted: registration_submitted
                                            ? DateTime.utc().toISO()
                                            : null,
                                    }
                                }
                            })} />
                    </CRMInputLabelAndErrorWrapComponent>
                </SpacingColumn>

                <CRMCodecEditForm
                    codec={CaseRegistrationSubmissionRequisitionsAndComplete}
                    model={props.state.forms.case_details_page.data.output.details.edited}
                    validationErrors={props.state.forms.case_details_page.data.output.details.validationErrors}
                    columns={1}
                    onChange={(edited) => props.dispatch({
                        type: "LEGAL_CASE_DETAILS_CHANGE",
                        payload: {
                            ...props.state.forms.case_details_page.data.output.details,
                            edited: {
                                ...props.state.forms.case_details_page.data.output.details.edited,
                                ...edited,
                            }
                        }
                    })}
                    showDeferDateLabels={true}
                    hideFields={hiddenFields} />
            </CRMSpacingColumn>
        </CRMAutosaveIndicatorWrap>
    );
};

export default CRMLegalCasePostCompletionContainer;