import React from "react";
import { CRMIcon, TIcon } from "../../../CRMIcon/CRMIcon";
import { CRMColors } from "../../../../models/CRMColors";
import { useHover } from "../../../../hooks/useHover";

type TEntityButtonProps = {
    icon: TIcon;
    onClick: () => void;
};

export const CRMEntityButton = (props: React.PropsWithChildren<TEntityButtonProps>): JSX.Element => {

    const [ref, isHovering] = useHover<HTMLDivElement>({});

    return (
        <div
            ref={ref}
            className={`
                crm-entity-button
                crm-entity-button--${isHovering ? "focused" : "blurred"}
            `}
            onClick={props.onClick}
        >
            <CRMIcon
                iconName={props.icon}
                size="18px"
                colour="hex"
                hexColour={isHovering ? CRMColors.NEUTRAL_PAPER : CRMColors.NEUTRAL_INK}
            />
        </div>
    );
};
