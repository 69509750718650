import React, { useState } from "react";
import { SpacingRow } from "../BuildingBlocks/SpacingRow";
import { 
    Editor, 
    EditorState, 
    RichUtils
} from 'draft-js';
import { CRMRichTextAreaButton, useRichTextAreaControls } from "./CRMRichTextAreaControls";


type TRichTextAreaProps = {
    placeholder?: string;
    contentState: EditorState,
    onChange: (value: EditorState) => void;
};

export const CRMRichTextArea = (props: React.PropsWithChildren<TRichTextAreaProps>): JSX.Element => {
    
    const {
        isTextFormatBeingUsed,
        onToggleTextFormat,
        isBlockFormatBeingUsed,
        onToggleBlockFormat
    } = useRichTextAreaControls({
        contentState: props.contentState,
        onChange: props.onChange,
    });

    const handleKeyCommand = (command: string, eventEditorState: EditorState) => {
        const newState = RichUtils.handleKeyCommand(eventEditorState, command);

        if (newState) {
            props.onChange(newState);
            return 'handled';
        }
        return 'not-handled';
    }

    return (
        <CRMRichTextAreaMainWrap>
            <CRMRichTextAreaEditorWrap>
                <Editor
                    placeholder={props.placeholder}
                    editorState={props.contentState}
                    onChange={props.onChange}
                    handleKeyCommand={handleKeyCommand}
                />
            </CRMRichTextAreaEditorWrap>

            {/* FORMATTING BUTTONS */}
            <SpacingRow spacing={"3px"}>
                <CRMRichTextAreaButton
                    title="Bold (keyboard shortcut = CTRL + B)"
                    icon="bold"
                    isEnabled={isTextFormatBeingUsed("BOLD")}
                    onClick={onToggleTextFormat("BOLD")} 
                />
                <CRMRichTextAreaButton
                    title="Italic (keyboard shortcut = CTRL + I)"
                    icon="italic"
                    isEnabled={isTextFormatBeingUsed("ITALIC")}
                    onClick={onToggleTextFormat("ITALIC")}
                />
                <CRMRichTextAreaButton
                    title="Underline (keyboard shortcut = CTRL + U)"
                    icon="underline"
                    isEnabled={isTextFormatBeingUsed("UNDERLINE")}
                    onClick={onToggleTextFormat("UNDERLINE")}
                />
                <CRMRichTextAreaButton
                    title="Bullet list"
                    icon="bullet-list"
                    isEnabled={isBlockFormatBeingUsed("unordered-list-item")}
                    onClick={onToggleBlockFormat("unordered-list-item")}
                />
                <CRMRichTextAreaButton
                    title="Numbered list"
                    icon="number-list"
                    isEnabled={isBlockFormatBeingUsed("ordered-list-item")}
                    onClick={onToggleBlockFormat("ordered-list-item")}
                />
            </SpacingRow>
        </CRMRichTextAreaMainWrap>
    );
};

export const CRMRichTextAreaMainWrap = (props: React.PropsWithChildren<{}>) => (
    <div className="crm-rich-text-area">
        {props.children}
    </div>
);

export const CRMRichTextAreaEditorWrap = (props: React.PropsWithChildren<{}>) => (
    <div className="crm-rich-text-area__editor">
        {props.children}
    </div>
);