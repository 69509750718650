import React, { CSSProperties } from "react";
import { CRMDeferPopout } from "../CrmDeferPopout/CRMDeferPopout";
import { useOpenClose } from "../../hooks/UseOpenClose";
import { DateTime } from "luxon";
import { CRMParagraph } from "../Simple/CRMParagraph/CRMParagraph";
import { WeightBold } from "../WeightBold/WeightBold";
import { CRMIcon } from "../CRMIcon/CRMIcon";
import { CRMSpacingRow } from "../CRMSpacingRow/CRMSpacingRow";
import { CursorPointer } from "../CursorPointer/CursorPointer";
import { CRMColors } from "../../models/CRMColors";
import { TextColour } from "../TextColour/TextColour";
import { isInvalidDate } from "../../../../shared/src/util";

type TCRMDeferButtonComponentProps = {
    value: string | null;
    onChange: (date: string | null) => void;
    displayError: boolean;
    popoutPosition?:
        "above-button"
        | "below-button-right"
        | "below-button-left";
    alignSelf?: CSSProperties["alignSelf"]
};

export const CRMDeferButtonComponent = (props: TCRMDeferButtonComponentProps): JSX.Element => {
    const {
        ref,
        isOpen,
        setIsOpen,
    } = useOpenClose();

    const dateTime = DateTime.fromISO(props.value || "");

    return (
        <div
            className={`crm-defer-button-component`}
            style={{position: "relative", alignSelf: props.alignSelf}}
        >
            <div 
                className={`
                    crm-defer-button-component__container
                    crm-defer-button-component__container--${props.displayError ? "error" : "no-defer"}
                `}
                onClick={() => setIsOpen(!isOpen)}
            >
                <CursorPointer>
                    <TextColour colour={getTextColourValueHex(props)}>
                        {isInvalidDate(props.value) &&
                            <CRMSpacingRow>
                                <CRMIcon iconName="snooze" colour={getTextColourValue(props)}/>
                                <CRMParagraph>
                                    Set defer date
                                </CRMParagraph>
                                <CRMIcon
                                    iconName={isOpen ? "arrow-up" : "arrow-down"}
                                    colour={getTextColourValue(props)}
                                />
                            </CRMSpacingRow>
                        }

                        {!isInvalidDate(props.value) &&
                            <CRMSpacingRow>
                                <CRMIcon iconName="snooze" colour={getTextColourValue(props)}/>
                                <CRMParagraph>
                                    <div>
                                        Deferred: <WeightBold>{getAmountOfTimeInFutureFromNow(dateTime)}</WeightBold>
                                    </div>
                                </CRMParagraph>
                                <div>
                                    <CRMIcon 
                                        size="small" 
                                        iconName={isOpen ? "arrow-up" : "arrow-down"}
                                        colour={getTextColourValue(props)}
                                    />
                                </div>
                                <div onClick={(e) => {
                                    e.stopPropagation();
                                    props.onChange(null)
                                }}>
                                    <CRMIcon size="small" iconName="delete" colour={getTextColourValue(props)} />
                                </div>
                            </CRMSpacingRow>
                        }
                    </TextColour>
                </CursorPointer>
            </div>

            { isOpen &&
                <div 
                    ref={ref}
                    className={`
                        crm-defer-button-component__popout 
                        crm-defer-button-component__popout--${
                            props.popoutPosition === "above-button" ? "display-above-button"
                            : props.popoutPosition === "below-button-right" ? "display-below-button-and-right"
                            : props.popoutPosition === "below-button-left" ? "display-below-button-and-left"
                            : "display-below-button-and-right"
                        }
                    `}
                >
                    <CRMDeferPopout onChange={(date) => {
                        props.onChange(date);
                        setIsOpen(false);
                    }} />
                </div>
            }
        </div>
    );
};

const getTextColourValue = (props: TCRMDeferButtonComponentProps) =>
    props.displayError
    ? "highlights-instructional-brick-0"
    : "neutral-ink";

const getTextColourValueHex = (props: TCRMDeferButtonComponentProps) =>
    props.displayError
    ? CRMColors.HIGHLIGHTS_INSTRUCTIONAL_BRICK_0
    : CRMColors.NEUTRAL_INK;

export const getAmountOfTimeInFutureFromNow = (dateTime: DateTime): string =>
    getAmountOfTimeInFutureFromDate(DateTime.utc(), dateTime);

export const getAmountOfTimeInFutureFromDate = (startDateTime: DateTime, endDateTime: DateTime): string => {
    const diff = endDateTime.diff(startDateTime);

    const mins = diff.shiftTo("minute").minutes;

    if (diff.shiftTo("minute").minutes < 59) {
        return `${Math.round(mins)} mins`;
    }

    const hours = Math.round(diff.shiftTo("hours").hours);
    if (hours < 24) {
        return `${hours}h`;
    }

    const days = Math.round(diff.shiftTo("days").days);
    if (days < 7) {
        return `${days}d`;
    }

    const weeks = Math.round(diff.shiftTo("week").weeks);
    return `${weeks}w`;
};
