import React, { ReactNode, ReactNodeArray } from "react";

interface IContentContainer{
    children: ReactNode | ReactNodeArray;
    className?: string;
}

class ContentContainer extends React.Component<React.PropsWithChildren<IContentContainer>> {
    public render (): JSX.Element {
        return (
            <div className={`contentContainer${ this.props.className ? " " + this.props.className : ""}`}>
                {this.props.children}
            </div>
        );
    }
}

export default ContentContainer;
