import React from "react";

export class CRMTitleForTextBox extends React.Component<React.PropsWithChildren<{}>> {
    public render (): JSX.Element {
        return (
            <span className="crm-title-for-text-box">
                {this.props.children}
            </span>
        );
    }
}
