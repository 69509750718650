import React from "react";
import { CRMTitleSection } from "../../components/CRMTitleSection/CRMTitleSection";
import { CRMSpacer } from "../../components/CRMSpacer/CRMSpacer";
import CRMInputLabelAndErrorWrapComponent from "../../components/CRMInputLabelAndErrorWrapComponent/CRMInputLabelAndErrorWrapComponent";
import { CRMInputCurrency } from "../../components/CRMInputCurrency/CRMInputCurrency";
import CRMTextAreaComponent from "../../components/CRMTextAreaComponent/CRMTextAreaComponent";
import { CRMSpacingRow } from "../../components/CRMSpacingRow/CRMSpacingRow";
import { CRMFormButton } from "../../components/CRMFormButton/CRMFormButton";
import { CRMButtonTertiary } from "../../components/CRMButtonTertiary/CRMButtonTertiary";
import { CRMEnquiriesPreviousOffersComponent } from "../../components/CRMEnquiriesPreviousOffersComponent/CRMEnquiriesPreviousOffersComponent";
import { array } from "fp-ts";
import { CRMButtonIconWithLabel } from "../../components/CRMButtonIconWithLabel/CRMButtonIconWithLabel";
import { CRMInputCheckboxComponent } from "../../components/CRMInputCheckboxComponent/CRMInputCheckboxComponent";
import { CRMNoticeBoxComponent } from "../../components/CRMNoticeBoxComponent/CRMNoticeBoxComponent";
import * as JsonError1 from "../../../../domain/models/JsonError1";
import * as TContainerStateProps from "../../state/TContainerStateProps";
import { pipe } from "fp-ts/lib/function";
import { CRMAutosaveIndicatorWrap } from "../../components/CRM/CRMAutosaveIndicatorWrap/CRMAutosaveIndicatorWrap";
import { FormStatus_highestPriority } from "../../../../shared/src/codecs/codec";
class CRMEnquiryAddAnOfferContainer extends React.Component<React.PropsWithChildren<TContainerStateProps.TContainerStateProps>> {
    public render(): JSX.Element {
        return (
            <CRMAutosaveIndicatorWrap status={
                pipe(
                    this.props.state.forms.listing_enquiry_page.children.enquiry_offers,
                    array.map((form) => form.status),
                    FormStatus_highestPriority,
                )
            }>
                <CRMTitleSection id="enquiries-add-an-offer">Offers</CRMTitleSection>
                <CRMSpacer size="large" />

                {/* Display the previous offers from this enquiry */}
                <CRMEnquiriesPreviousOffersComponent
                    offers={this.props.state.forms.listing_enquiry_page.children.enquiry_offers}
                    onChange={(payload) => this.props.dispatch({
                        type: "CRM_ENQUIRY_UPDATE_OFFER",
                        payload,
                    })}
                />

                {/* Display best & final offer made if an offer already exists or a new offer is being entered */}
                {(this.props.state.forms.listing_enquiry_page.children.enquiry_offers.length > 0 || this.props.state.activeData.crm.enquiry.createOfferFormVisible) && <>
                    <CRMSpacer size="medium" />
                    <CRMInputLabelAndErrorWrapComponent>
                        <CRMInputCheckboxComponent
                            label="This party has given their best & final offer"
                            checked={this.props.state.forms.listing_enquiry_page.children.enquiry.edited.final_offer_made}
                            onClick={() => this.props.dispatch({
                                type: "CRM_ENQUIRY_INPUT_CHANGE",
                                payload: {
                                    ...this.props.state.forms.listing_enquiry_page.children.enquiry,
                                    edited: {
                                        ...this.props.state.forms.listing_enquiry_page.children.enquiry.edited,
                                        final_offer_made: !this.props.state.forms.listing_enquiry_page.children.enquiry.edited.final_offer_made,
                                    }
                                },
                            })}
                        />
                    </CRMInputLabelAndErrorWrapComponent>
                </>}

                {/* Add new offer */}
                <CRMSpacer size="medium" />
                {!this.props.state.activeData.crm.enquiry.createOfferFormVisible && <CRMButtonIconWithLabel
                    label="Add new offer"
                    icon="money"
                    variant="primary"
                    onClick={() => this.props.dispatch({
                        type: "CRM_ENQUIRY_ADD_NEW_OFFER_CLICKED",
                    })}
                />}

                {/* New offer form */}
                {this.props.state.activeData.crm.enquiry.createOfferFormVisible && <>
                    <CRMInputLabelAndErrorWrapComponent
                        label="Amount"
                        displayError={JsonError1.doTargetKeysHaveInnerErrors(["amount"], this.props.state.activeData.crm.enquiry.createOfferForm.validationErrors)}
                        errorMessage="The amount must be set to a value above 0"
                    >
                        <CRMInputCurrency
                            value={this.props.state.activeData.crm.enquiry.createOfferForm.edit.amount || null}
                            currencySymbol="£"
                            displayError={JsonError1.doTargetKeysHaveInnerErrors(["amount"], this.props.state.activeData.crm.enquiry.createOfferForm.validationErrors)}
                            onChange={(value) => this.props.dispatch({
                                type: "CRM_ENQUIRY_NEW_OFFER_INPUT_CHANGED",
                                payload: {
                                    key: "amount",
                                    value: value || 0,
                                },
                            })}
                            onPressEnterKey={() => undefined}
                        />
                    </CRMInputLabelAndErrorWrapComponent>
                    <CRMSpacer size="medium" />
                    {/* If new offer exceeds the proof of funds we already have display a notice */}
                    {this.props.state.forms.listing_enquiry_page.children.enquiry.children.has_provided_proof_of_funds
                        && this.props.state.activeData.crm.enquiry.createOfferForm.edit.amount > (
                            (this.props.state.forms.listing_enquiry_page.children.enquiry.edited.funds_cash_verified_upto || 0)
                            + (this.props.state.forms.listing_enquiry_page.children.enquiry.edited.funds_dip_verified_upto || 0)
                        )
                        && <>
                            <CRMNoticeBoxComponent>
                                This new offer exceeds the total proof of funds we have received.
                            </CRMNoticeBoxComponent>
                            <CRMSpacer size="medium" />
                        </>}
                    <CRMInputLabelAndErrorWrapComponent
                        label="Conditions"
                        displayError={JsonError1.doTargetKeysHaveInnerErrors(["conditions"], this.props.state.activeData.crm.enquiry.createOfferForm.validationErrors)}
                        errorMessage={JsonError1.getInnerErrorsForTargetKeysErrorMessageString(["conditions"], this.props.state.activeData.crm.enquiry.createOfferForm.validationErrors)}
                    >
                        <CRMTextAreaComponent
                            value={this.props.state.activeData.crm.enquiry.createOfferForm.edit.conditions}
                            displayError={JsonError1.doTargetKeysHaveInnerErrors(["conditions"], this.props.state.activeData.crm.enquiry.createOfferForm.validationErrors)}
                            onChange={(value) => this.props.dispatch({
                                type: "CRM_ENQUIRY_NEW_OFFER_INPUT_CHANGED",
                                payload: {
                                    key: "conditions",
                                    value,
                                },
                            })}
                        />
                    </CRMInputLabelAndErrorWrapComponent>
                    <CRMSpacer size="medium" />
                    <CRMSpacingRow
                        justifyContent="end"
                    >
                        <CRMFormButton
                            formStatus={this.props.state.activeData.crm.enquiry.createOfferForm.status}
                            ButtonElement={CRMButtonTertiary}
                            label="Save new offer"
                            onClick={() => this.props.dispatch({
                                type: "CRM_ENQUIRY_NEW_OFFER_SUBMIT",
                            })}
                        />
                    </CRMSpacingRow>
                </>}
            </CRMAutosaveIndicatorWrap>
        );
    }
}

export default CRMEnquiryAddAnOfferContainer;
