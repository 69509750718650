import React from "react";
import { CRMFontSizes } from "../../../models/CRMFontSizes";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { SpacingColumn } from "../../BuildingBlocks/SpacingColumn";
import { CRMDropdownGroup, TDropdownGroupOptionProps } from "../../Complex/CRMDropdownGroup/CRMDropdownGroup";
import { CRMDropdownOptionBox } from "../CRMDropdownOptionBox/CRMDropdownOptionBox";
import { CRMEmptyPlaceholder } from "../CRMEmptyPlaceholder/CRMEmptyPlaceholder";
import { Background } from "../../BuildingBlocks/Background";
import { TRichFilterOption, TUseRichFilterDropdownProps, useRichFilterDropdown } from "../../../hooks/useRichFilterDropdown";

export type TRichFilterDropdownFaceProps = {
    inputRef: React.RefObject<HTMLInputElement>;
    isOpen: boolean;
    selected: Array<string>;
    placeholder?: string;
    searchText: string;    
    onSetSearchText: (value: string) => void;
    onOpen: () => void;
    onPressEnterKey?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
    onPressTabKey?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
    onPressEscape?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
    onPressBackspace?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
    onRemoveSelection: (value: string) => void;
    convertOptionValueToFaceText: (value: string) => string;
    disabled?: boolean;
};

type TEmailInputProps<T extends TRichFilterOption> = 
    TUseRichFilterDropdownProps<T> & 
    {
        placeholder?: string;
        disabled?: boolean;
        convertOptionValueToFaceText: (value: string) => string;
        FaceElement: (props: TRichFilterDropdownFaceProps) => JSX.Element;
        OptionElement: (props: TDropdownGroupOptionProps<T>) => JSX.Element;
    }
;

export const CRMRichFilterDropdown = <T extends TRichFilterOption>(props: React.PropsWithChildren<TEmailInputProps<T>>): JSX.Element => {

    const {
        ref,
        inputRef,
        isOpen,
        searchText,
        setSearchText,
        getSearchResultsByGroup,
        open,
        close,
        onPressSearchEnter,
        onToggleOptionSelection,
        deriveStatus,
        onEscape,
        onBackspace
    } = useRichFilterDropdown(props);

    return (
        <div
            ref={ref} 
            className={`
                crm-rich-filter-dropdown
                crm-rich-filter-dropdown--${props.disabled ? "disabled" : "enabled"}
            `}
            onClick={() => inputRef.current?.focus()}
        >
            {/* FACE */}
            <props.FaceElement
                inputRef={inputRef}
                isOpen={isOpen}
                selected={props.selected}
                placeholder={props.placeholder || ""}
                searchText={searchText}
                onSetSearchText={setSearchText}
                onOpen={open}
                onPressEnterKey={onPressSearchEnter}
                onPressTabKey={close}
                onPressEscape={onEscape}
                onPressBackspace={onBackspace}
                onRemoveSelection={onToggleOptionSelection}
                convertOptionValueToFaceText={props.convertOptionValueToFaceText}
                disabled={props.disabled}
            />

            {/* BODY */}
            {isOpen &&
                <CRMDropdownOptionBox>
                    <Background padding={CRMSpacing.MEDIUM}>
                        
                        {deriveStatus() === "no-match" &&
                            <CRMEmptyPlaceholder
                                iconSize="50px" 
                                textGap={"0px"}
                                fontSize={CRMFontSizes.SMALL}
                            >
                                No match
                            </CRMEmptyPlaceholder>
                        }

                        {(deriveStatus() === "matching" || deriveStatus() === "untouched") &&
                            <SpacingColumn spacing={CRMSpacing.LARGE}>
                                {getSearchResultsByGroup().map((group, key) => (
                                    <CRMDropdownGroup<T>
                                        key={key}
                                        searchText={searchText}
                                        group={group}
                                        onToggleOptionSelection={onToggleOptionSelection}
                                        OptionElement={props.OptionElement}
                                    />
                                ))}
                            </SpacingColumn>
                        }
                    </Background>
                </CRMDropdownOptionBox>
            }
        </div>
    );
};
