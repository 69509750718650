import React from "react";
import { TContainerStateProps } from "../../state/TContainerStateProps";
import { CRMJobsOverlay } from "../../components/CRMJobs/CRMJobsOverlay/CRMJobsOverlay";
import { getCurrentBrandHostnameAndEnv } from "../../../../domain/models/Enviroment";
import { TRoadblocksForm } from "../../../../domain/codecs/form/RoadblockForm";
import { TJobsFilter } from "../../../../domain/codecs/statePartials/JobsPartial";

const CRMJobsContainer = (props: TContainerStateProps): JSX.Element => {

    const onViewRoadblocks = () => props.dispatch({
        type: "VIEW_ROADBLOCKS",
        payload: props.state.forms.overlay.roadblocks
    })
    
    const onViewJobs = () => props.dispatch({
        type: "VIEW_JOBS",
        // This is not a very good way to do this but it is required because at the moment the state 
        // available in actions does not include the global part that has the current user's id on it
        payload: getCurrentBrandHostnameAndEnv("web", window.location.hostname) === "saillegal"
            ? props.state.global.user.id 
            : undefined
    });

    const onChangeRoadblocks = (payload: TRoadblocksForm) => props.dispatch({
        type: "FILTER_ROADBLOCKS",
        payload
    });

    const onLoadMoreRoadblocks = () => props.dispatch({
        type: "LOAD_MORE_ROADBLOCKS",
        payload: null
    });

    const onChangeJobsFilter = (payload: TJobsFilter) => props.dispatch({
        type: "FILTER_JOBS",
        payload
    })

    const onOpen = () => {
        onViewJobs();
        onViewRoadblocks();
    }

    return (
        <CRMJobsOverlay
            // Overlay
            onOpen={onOpen}

            // Roadblocks
            roadblocksForm={props.state.forms.overlay.roadblocks}
            onLoadMoreRoadblocks={onLoadMoreRoadblocks}
            onChangeRoadblocks={onChangeRoadblocks}

            // Jobs Filter
            jobsForm={props.state.forms.jobs.data}
            jobsFilter={props.state.forms.jobs.filter}
            onChangeJobs={onChangeJobsFilter}

            groupingSetting={
                props.state.routes.active === "VIEW_CRM_LISTING" ? 
                    {
                        groupBy: "listing", 
                        listingId: props.state.routes.params.listingId
                    }
                : props.state.routes.active === "VIEW_CRM_ENQUIRY" ? 
                    {
                        groupBy: "listing_enquiry", 
                        listingEnquiryId: props.state.routes.params.enquiryId
                    }
                : props.state.routes.active === "VIEW_CRM_LEGAL_CASE" ? 
                    {
                        groupBy: "case", 
                        caseId: props.state.routes.params.caseId
                    }
                : null
            }
        />
    )
}

export default CRMJobsContainer;