import * as t from "io-ts";
import * as QueryStringFilter1 from "./QueryStringFilter1";
import { T as QueryStringFilter2 } from "./QueryStringFilter2";
import * as QueryStringFilterMappings from "./QueryStringFilterMappings";
import * as QueryStringFilterString from "./QueryStringFilterString";
import * as QueryStringFilterFullText from "./QueryStringFilterFullText";
import * as QueryStringFilterNumber from "./QueryStringFilterNumber";
import * as QueryStringFilterBoolean from "./QueryStringFilterBoolean";
import * as QueryStringFilterDate from "./QueryStringFilterDate";
import * as util from "../../shared/src/util";
import { option } from "fp-ts/lib";
import { object } from "../../shared/src/validation/create/object";
import { isEnum } from "../../shared/src/validation/create/isEnum";
import { TValidationFunction } from "../../shared/src/validation/Function";
import { array } from "../../shared/src/validation/create/array";
import { inOrder } from "../../shared/src/validation/compose/inOrder";
import { required } from "../../shared/src/validation/basic/required";
import { string } from "../../shared/src/validation/basic/string";
import { stringIsoUtcDateTime } from "../../shared/src/validation/basic/stringIsoUtcDateTime";
import { decimal } from "../../shared/src/validation/basic/decimal";
import { boolean } from "../../shared/src/validation/basic/boolean";
import { validationErrorCodeConstants } from "../../shared/src/validation/ErrorCode";
import { objectCallback } from "../../shared/src/validation/create/objectCallback";

export const codec: t.Type<T> = t.recursion("codec", (self) =>
    t.type({
        type: t.literal("GROUP"),
        conditional: t.union([
            t.literal("AND"),
            t.literal("OR"),
        ]),
        filters: t.array(
            t.union([
                self,
                QueryStringFilter1.codec,
            ]),
        ),
    }),
);

export type T = {
    type: "GROUP";
    conditional: "AND" | "OR";
    filters: Array<QueryStringFilter2>;
};

export const is = (v: unknown): v is T =>
    util.isObject(v)
        && "type" in v
        && v.type === "GROUP"
        && "conditional" in v
        && "filters" in v;

export const createValidator = (filterMappings: QueryStringFilterMappings.T): TValidationFunction => {
    const validator = object({
        type: inOrder(
            required,
            isEnum([
                "GROUP",
            ]),
        ),
        conditional: inOrder(
            required,
            isEnum<T["conditional"]>(["AND", "OR"]),
        ),
        filters: inOrder(
            required,
            array((item) =>
                is(item) ? validator(item)
                : QueryStringFilter1.is(item) ? (
                    typeof filterMappings.string !== "undefined" && Object.keys(filterMappings.string).includes(item.property) ? createFilterValidator(Object.keys(filterMappings.string), QueryStringFilterString.operatorValues, object({
                        value: string,
                    }))
                    : typeof filterMappings.full_text !== "undefined" && Object.keys(filterMappings.full_text).includes(item.property) ? createFilterValidator(Object.keys(filterMappings.full_text), QueryStringFilterFullText.operatorValues, object({
                        value: string,
                    }))
                    : typeof filterMappings.number !== "undefined" && Object.keys(filterMappings.number).includes(item.property) ? createFilterValidator(Object.keys(filterMappings.number), QueryStringFilterNumber.operatorValues, object({
                        value: decimal,
                    }))
                    : typeof filterMappings.boolean !== "undefined" && Object.keys(filterMappings.boolean).includes(item.property) ? createFilterValidator(Object.keys(filterMappings.boolean), QueryStringFilterBoolean.operatorValues, object({
                        value: boolean,
                    }))
                    : typeof filterMappings.date !== "undefined" && Object.keys(filterMappings.date).includes(item.property) ? createFilterValidator(Object.keys(filterMappings.date), QueryStringFilterDate.operatorValues, objectCallback((val) =>
                        val.operator === "DATE_IS_NULL" || val.operator === "DATE_IS_NOT_NULL" ? option.none
                        : object({
                            value: inOrder(
                                string,
                                stringIsoUtcDateTime,
                            ),
                        })(val)
                    ))
                    : object({
                        property: inOrder(
                            required,
                            isEnum([
                                ...Object.keys(filterMappings.string || {}),
                                ...Object.keys(filterMappings.full_text || {}),
                                ...Object.keys(filterMappings.number || {}),
                                ...Object.keys(filterMappings.boolean || {}),
                                ...Object.keys(filterMappings.date || {}),
                            ]),
                        ),
                    })
                )(item)
                : option.some([[validationErrorCodeConstants.OBJECT_NOT_ONE_OF_EXPECTED_VALIDATION]])
            ),
        ),
    });
    return validator;
};

const createFilterValidator = (propertyValues: Array<string>, operatorValues: Array<string>, validationFunction: TValidationFunction): TValidationFunction =>
    inOrder(
        object({
            type: inOrder(
                required,
                isEnum([
                    "FILTER",
                ]),
            ),
            property: inOrder(
                required,
                isEnum(propertyValues),
            ),
            operator: inOrder(
                required,
                isEnum(operatorValues),
            ),
            value: required,
        }),
        validationFunction,
    );
