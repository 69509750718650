import React, { useEffect, useState } from "react";
import { array, option } from "fp-ts";
import { pipe } from "fp-ts/function";
import { Padding } from "../../components/BuildingBlocks/Padding";
import { SpacingColumn } from "../../components/BuildingBlocks/SpacingColumn";
import { SpacingRow } from "../../components/BuildingBlocks/SpacingRow";
import { Sticky } from "../../components/BuildingBlocks/Sticky";
import { CRMAddCaseLedgerItemCard } from "../../components/Complex/CRMAddCaseLedgerItemCard/CRMAddCaseLedgerItemCard";
import { CRMCardLedgerTotals } from "../../components/Complex/CRMCardLedgerTotals/CRMCardLedgerTotals";
import { CRMCaseLedgerTable } from "../../components/Complex/CRMCaseLedgerTable/CRMCaseLedgerTable";
import { CRMEmptyPlaceholder } from "../../components/Simple/CRMEmptyPlaceholder/CRMEmptyPlaceholder";
import { CRMSpacing } from "../../models/CRMSpacing";
import { TContainerStateProps } from "../../state/TContainerStateProps";
import { CRMParagraph } from "../../components/Simple/CRMParagraph/CRMParagraph";
import { WeightBold } from "../../components/WeightBold/WeightBold";
import { CRMFormButton } from "../../components/CRMFormButton/CRMFormButton";
import { castNewFormStatusToLegacy } from "../../../src/util";
import { CRMButtonQuaternary } from "../../components/CRMButtonQuaternary/CRMButtonQuaternary";
import { CRMCardOutsidePopupBasic } from "../../components/CRMCardOutsidePopupBasic/CRMCardOutsidePopupBasic";
import { CRMZIndex } from "../../models/CRMZIndex";
import { CRMMultipleDefersPopoutComponent } from "../../components/CRMMultipleDefersPopoutComponent/CRMMultipleDefersPopoutComponent";
import { CRMButtonTertiary } from "../../components/CRMButtonTertiary/CRMButtonTertiary";
import { TCaseLedgerEntryForm } from "../../../../domain/codecs/form/CaseLedgerEntryForm";
import { TDeferPopoutOption } from "../../components/CRMMultipleDefersPopoutComponent/CRMMultipleDefersPopoutComponent";
import { CRMLink } from "../../components/CRMLink/CRMLink";

export const CRMCaseLedgerContainer = (props: TContainerStateProps): JSX.Element => {

    const [markLedgerAsFinalPopupIsOpenState, setMarkLedgerAsFinalPopupIsOpenState] = useState<boolean>(false);

    useEffect(
        () => props.dispatch({
            type: "LEGAL_CASE_GET_LEDGER",
            payload: null
        }), 
        []
    );

    const caseID = props.state.forms.case_ledger_page.case_ledger.edited.case_id;
    const entries = props.state.forms.case_ledger_page.case_ledger.children.ledger_entries;
    const totalCredit = getTotalSumOfLedgerEntries(entries, "credit");
    const totalDebit = getTotalSumOfLedgerEntries(entries, "debit");
    const rollbackFormLegacyStatus = castNewFormStatusToLegacy(props.state.forms.case_ledger_page.rollback_ledger.status);
    const markAsFinalFormLegacyStatus = castNewFormStatusToLegacy(props.state.forms.case_ledger_page.mark_case_ledger_as_final.status);
    const hasASearchPack = pipe(
        props.state.forms.case_ledger_page.case_ledger.children.ledger_entries, 
        array.findFirst((entry) => entry.edited.type === "search_pack"), 
        option.isSome
    );

    const hasEntries = props.state.forms.case_ledger_page.case_ledger.children.ledger_entries.length > 0;
    const canAddToLedger = props.state.forms.case_details_page.data.output.details.edited.is_instructed !== null;
    const isDraftLedger = props.state.forms.case_ledger_page.case_ledger.children.ledger_finalised.ledger_finalised_at === null;
    const isFinalLedger = props.state.forms.case_ledger_page.case_ledger.children.ledger_finalised.ledger_finalised_at !== null;
    const isLedgerBalanced = totalCredit === totalDebit;
    const canRollbackLedger = isFinalLedger && (props.state.global.user.user_role === "admin" || props.state.global.user.user_role === "sail_legal_authoriser_staff");
    const canLedgerBeSubmitted = isDraftLedger && hasEntries && isLedgerBalanced;
    const canLedgerNotBeSubmitted = isFinalLedger || !isLedgerBalanced || !hasEntries;

    type TDeferOptionKeys = "finalise_case_ledger_defer" | "chase_payments_due_defer" | "payout_ledger_items_defer";
    const deferOptions: Array<TDeferPopoutOption<TDeferOptionKeys>> = [
        {
            iconName: "done-all",
            label: "Finalise Ledger",
            key: "finalise_case_ledger_defer",
            value: props.state.forms.case_details_page.data.output.details.edited.finalise_case_ledger_defer,
        },
        {
            iconName: "money",
            label: "Chase Payments Due",
            key: "chase_payments_due_defer",
            value: props.state.forms.case_details_page.data.output.details.edited.chase_payments_due_defer,
        },
        {
            iconName: "money",
            label: "Payout Debits",
            key: "payout_ledger_items_defer",
            value: props.state.forms.case_details_page.data.output.details.edited.payout_ledger_items_defer,
        }
    ];

    const getLabelForMarkAsFinalButton = (): string => {
        if (!hasEntries) {
            return "Add ledger entries and balance the ledger before marking as final";
        }

        if (hasEntries && !isLedgerBalanced) {
            return "The ledger cannot be marked as final until it is balanced";
        }

        if (hasEntries && isLedgerBalanced && !isDraftLedger) {
            return "The ledger has been marked as final. Entries can no longer be added/amended/deleted";
        }

        return "The ledger is balanced. If all entries are completed/no missing entries, click to mark the ledger as final. NB once clicked you will no longer be able to edit ledger entries";
    };

    const onDownloadClientAndAccountsInvoices = () => {
        // Open multiple downloads at once. Using iFrames to avoid having the browser block popups
        [
            `${env.REACT_APP_API_URL}/web/legal/cases/${caseID}/client-invoice`,
            `${env.REACT_APP_API_URL}/web/legal/cases/${caseID}/accounts-invoice`,
        ]
        .forEach(url => {
            let iframe = document.createElement('iframe');
            iframe.style.visibility = 'collapse';
            document.body.append(iframe);
        
            if (iframe.contentDocument !== null) {
                iframe.contentDocument.write(
                    `<form action="${url.replace(/\"/g, '"')}" method="GET"></form>`
                );
                iframe.contentDocument.forms[0].submit();
            }
        
            setTimeout(() => iframe.remove(), 2000);
        });
    };

    const onChangeDeferOption = (key: TDeferOptionKeys, value: string | null) => 
        props.dispatch({
            type: "LEGAL_CASE_DETAILS_CHANGE",
            payload: {
                ...props.state.forms.case_details_page.data.output.details,
                edited: {
                    ...props.state.forms.case_details_page.data.output.details.edited,
                    [key]: value,
                }
            }
        })
    ;

    const onMarkLedgerAsFinal = () => {
        props.dispatch({
            type: "LEGAL_CASE_MARK_LEDGER_AS_FINAL",
            payload: null,
        });
        setMarkLedgerAsFinalPopupIsOpenState(false)
    };

    return (
        <Padding type="vertical" spacing={CRMSpacing.X_LARGE}>
            <SpacingRow
                spacing={CRMSpacing.X_LARGE}
                childSize="2fr 1fr"
                alignItems="flex-start"
            >
                {!hasEntries &&
                    <CRMEmptyPlaceholder>
                        No ledger entries added yet.
                    </CRMEmptyPlaceholder>
                }

                {hasEntries &&
                    <SpacingColumn spacing={CRMSpacing.MEDIUM}>
                        <CRMCaseLedgerTable
                            entries={props.state.forms.case_ledger_page.case_ledger.children.ledger_entries}
                            ledgerIsFinal={isFinalLedger}
                            onDelete={(payload) => props.dispatch({
                                type: "LEGAL_CASE_DELETE_LEDGER_ITEM",
                                payload
                            })}
                            onChange={(payload) =>
                                props.dispatch({
                                    type: "LEGAL_CASE_UPDATE_LEDGER_ITEM_CHANGE",
                                    payload,
                                })
                            }
                        />

                        <CRMParagraph>
                            <WeightBold>
                                * VAT is included in the calculation of each item that contains it.
                            </WeightBold>
                        </CRMParagraph>

                    </SpacingColumn>
                }

                {/* COLUMN (RIGHT) - DEFER, ADD ENTRIES, DOWNLOAD, FINALIZE & ROLLBACK */}
                {/* 62px = all the bars + 16 (med) padding */}
                <Sticky top="62px">
                    <SpacingColumn spacing={CRMSpacing.MEDIUM}>
                        {/* INPUT - DEFERS */}
                        <div style={{zIndex: CRMZIndex.THIRD_Z}}>
                            <SpacingRow justifyContent="end">
                                <CRMMultipleDefersPopoutComponent
                                    defers={deferOptions}
                                    onChange={onChangeDeferOption}
                                    formStatus={props.state.forms.case_details_page.data.output.details.status}
                                />
                            </SpacingRow>
                        </div>

                        {/* FORM - ADD LEDGER ENTRY */}
                        {canAddToLedger &&
                            <CRMAddCaseLedgerItemCard
                                form={props.state.forms.case_ledger_page.create_case_ledger_item}
                                ledgerIsFinal={isFinalLedger}
                                onChange={(payload) => props.dispatch({
                                    type: "LEGAL_CASE_UPDATE_NEW_LEDGER_ITEM",
                                    payload
                                })}
                                onSubmit={(payload) => props.dispatch({
                                    type: "LEGAL_CASE_SUBMIT_NEW_LEDGER_ITEM",
                                    payload
                                })}
                            />
                        }

                        {/* TEXT - LEDGER SUMS */}
                        {hasEntries &&
                            <CRMCardLedgerTotals
                                debitInPence={totalDebit}
                                creditInPence={totalCredit}
                            />
                        }

                        {/* BUTTON - DOWNLOAD SEARCH INVOICE */}
                        {hasASearchPack && 
                            <SpacingRow justifyContent="end">
                                <CRMButtonQuaternary
                                    label="Search Invoice"
                                    icon="can-download"
                                    href={`${env.REACT_APP_API_URL}/web/legal/cases/${caseID}/search-invoice`}
                                    target="_blank"
                                />
                            </SpacingRow>
                        }

                        {/* BUTTON - DOWNLOAD CLIENT & ACCOUNTS INVOICES */}
                        <SpacingRow justifyContent="end">
                            <CRMButtonQuaternary
                                label={`${isDraftLedger ? "Draft " : ""} Client Invoice & Accounts Invoice`}
                                icon="can-download"
                                onClick={onDownloadClientAndAccountsInvoices}
                            />
                        </SpacingRow>

                        {/* BUTTON - DOWNLOAD COMPLETION STATEMENT */}
                        <SpacingRow justifyContent="end">
                            <CRMButtonQuaternary
                                label={`${isDraftLedger ? "Draft" : "Final"} Completion Statement`}
                                icon="can-download"
                                href={`${env.REACT_APP_API_URL}/web/legal/cases/${caseID}/completion-statement`}
                                target="_blank"
                            />
                        </SpacingRow>
                        
                        <SpacingRow justifyContent="end" spacing={CRMSpacing.MEDIUM}>
                            {/* BUTTON - MARK LEDGER AS FINAL */}
                            <CRMFormButton
                                formStatus={canLedgerBeSubmitted ? "requiresSubmission" : markAsFinalFormLegacyStatus}
                                ButtonElement={CRMButtonTertiary}
                                label="Mark Ledger As Final"
                                title={getLabelForMarkAsFinalButton()}
                                disabled={canLedgerNotBeSubmitted}
                                onClick={() => setMarkLedgerAsFinalPopupIsOpenState(true)}
                                icon="done-all"
                            />

                            {/* BUTTON - ROLLBACK LEDGER */}
                            {canRollbackLedger && 
                                <CRMFormButton
                                    formStatus={
                                        rollbackFormLegacyStatus === "untouched" || rollbackFormLegacyStatus === "success"
                                            ? "requiresSubmission"
                                            : rollbackFormLegacyStatus
                                    }
                                    ButtonElement={CRMButtonTertiary}
                                    label="Rollback"
                                    disabled={false}
                                    icon="repeat"
                                    onClick={() => props.dispatch({
                                        type: "LEGAL_CASE_ROLLBACK_LEDGER",
                                        payload: null,
                                    })}
                                />
                            }
                        </SpacingRow>
                        {props.state.forms.case_details_page.data.output.details.edited.klyant_matter_id &&
                            <SpacingRow justifyContent="end">
                                <CRMLink
                                    href={`${env.REACT_APP_API_URL}/web/klyant-ledger-redirect/${props.state.forms.case_details_page.data.output.details.edited.klyant_matter_id}`}
                                    linkStyle="normal"
                                    target="_blank"
                                >
                                    <WeightBold>Open Klyant Ledger (in new tab)</WeightBold>
                                </CRMLink>
                            </SpacingRow>
                        }
                    </SpacingColumn>
                </Sticky>
            </SpacingRow>

            {/* POPUP - MARK LEDGER AS FINAL */}
            <CRMCardOutsidePopupBasic
                isOpen={markLedgerAsFinalPopupIsOpenState}
                title="Mark case ledger as final"
                ctaText="Yes, proceed"
                onCTA={onMarkLedgerAsFinal}
                closeText="Cancel"
                onClose={() => setMarkLedgerAsFinalPopupIsOpenState(false)}
            >
                    <SpacingColumn spacing={CRMSpacing.MEDIUM}>
                        <CRMParagraph>
                            Are you sure you want to finalise the case ledger entries? This action cannot be undone - entries cannot be added/amended/deleted once submitted. 
                        </CRMParagraph>
                    </SpacingColumn>
            </CRMCardOutsidePopupBasic>
        </Padding>
    );
};

const getTotalSumOfLedgerEntries = (entries: Array<TCaseLedgerEntryForm>, flow: "credit" | "debit"): number => pipe(
    entries,
    array.reduce(
        0,
        (total, entry) => 
            entry.original.credit_or_debit === flow ?
                total + entry.original.vat_excluded_amount_gbp_pence + entry.original.vat_amount_gbp_pence
                : total
    )
);