import React from "react";
import { isFormButtonDisabled } from "../../models/TForm";
import * as TFormStatus from "../../models/TFormStatus";
import Button, { IButton } from "../OldFront/Simple/ButtonComponent/Button";
import Icon from "../OldFront/Simple/IconComponent/Icon";

export interface IFormButton extends IButton {
    status: TFormStatus.T;
}

const getButtonLabelBasedonStatus = (props: IFormButton): string | React.ReactNode => {
    if (props.status === "submitting") {
        return <span className="formButton__label-wrap">
            <div className="formButton__label-icon">
                <Icon
                    iconName="clock"
                    type="mouse"
                    disabled={false}
                    height={1.8}
                    width={1.8}
                />
            </div>
            <span className="formButton__label-text">{props.label}</span>
        </span>;
    }

    if (props.status === "success") {
        return <span className="formButton__label-wrap">
            <div className="formButton__label-icon">
                <Icon
                    iconName="tick"
                    type="mouse"
                    disabled={false}
                    height={2}
                    width={2}
                />
            </div>
            <span className="formButton__label-text">{props.label}</span>
        </span>;
    }

    if (props.status === "failure") {
        return <span className="formButton__label-wrap">
            <div className="formButton__label-icon">
                <Icon
                    iconName="cross"
                    type="mouse"
                    disabled={false}
                    height={1.2}
                    width={1.2}
                />
            </div>
            <span className="formButton__label-text">{props.label}</span>
        </span>;
    }

    return props.label;
};

export default class FormButton extends React.Component<React.PropsWithChildren<IFormButton>> {
    public render (): JSX.Element {
        return (
            <Button
                label={getButtonLabelBasedonStatus(this.props)}
                type={this.props.type}
                fullWidth={this.props.fullWidth}
                selectedState={this.props.selectedState}
                disabled={isFormButtonDisabled(this.props)}
                onClick={this.props.onClick}
            />
        );
    }
}
