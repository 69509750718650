import { pipe } from "fp-ts/lib/function";
import React from "react";
import { Address3_displayString, Address6_displayString } from "../../../../../domain/codecs/Address";
import { CompanyType_displayString } from "../../../../../domain/codecs/Cases";
import { TClientCompanyPartnershipForm } from "../../../../../domain/codecs/form/ClientCompanyPartnershipForm";
import { CRMFontSizes } from "../../../models/CRMFontSizes";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { SpacingColumn } from "../../BuildingBlocks/SpacingColumn";
import { CRMParagraph } from "../../Simple/CRMParagraph/CRMParagraph";
import { WeightBold } from "../../WeightBold/WeightBold";
import { WeightMedium } from "../../WeightMedium/WeightMedium";
import { WeightSemiBold } from "../../WeightSemiBold/WeightSemiBold";

export const CRMCompanyPartnerCompanyInformation = (props: React.PropsWithChildren<{
    form: TClientCompanyPartnershipForm
}>): JSX.Element => {

    return <SpacingColumn spacing={CRMSpacing.TINY}>
        <Item name="Name" value={props.form.original.name} />
        <Item name="Number" value={props.form.original.number} />
        <Item name="Type" value={props.form.original.type === "unknown" ? "" : CompanyType_displayString(props.form.original.type)} />
        <Item name="Address" value={pipe(
            {
                building_name: props.form.original.address_building_name,
                building_number: props.form.original.address_building_number,
                sub_building_name: props.form.original.address_sub_building_name,
                sub_building_number: props.form.original.address_sub_building_number,
                street_name: props.form.original.address_street_name,
                postcode: props.form.original.address_postcode,
                country: props.form.original.address_country,
                county: props.form.original.address_county,
                locality: props.form.original.address_locality,
                district: props.form.original.address_district,
                city_town: props.form.original.address_city_town,
            },
            Address3_displayString,
        )} />
    </SpacingColumn>;
};

const Item = (props: {name: string, value: string}): JSX.Element =>
    <CRMParagraph fontSize={CRMFontSizes.SMALL}>
        <WeightMedium>{props.name}:</WeightMedium>&nbsp;

        {/* THERE IS SOMETHING SET */}
        {props.value && <WeightBold>
            {props.value}
        </WeightBold>}

        {/* THERE ISN'T ANYTHING SET */}
        {props.value === "" && <WeightSemiBold>
            Not set
        </WeightSemiBold>}
    </CRMParagraph>;
