import { of, iif } from "rxjs";
import { map, concatAll } from "rxjs/operators";
import {  TActionObservable } from "../../state/applyActions";
import {TSetState as TSetState} from "../../state/TSetState";
import {TGetState as TGetState} from "../../state/TGetState";
import { TLensFunction } from "./lensBaseTypes";
import * as FirstPartyFetchResponse from "../../../../domain/models/FirstPartyFetchResponse";
import { TActionPayload, TPayloadActionType } from "../../state/actions/TAction";

export const catchEitherSubscribed =
    <A extends TPayloadActionType, T> (
        predicate: (action: TActionPayload<A, T>, request: FirstPartyFetchResponse.T<unknown>) => boolean,
        promiseLensFunction: TLensFunction<A, T, unknown>,
        catchLensFunction: TLensFunction<A, T, unknown>,
        successLensFunction: TLensFunction<A, T, unknown>,
    ) =>
        (obs$: TActionObservable<A, T>, getState: TGetState, setState: TSetState) =>
            obs$
                .pipe(
                    map((action) =>
                        promiseLensFunction(of(action), getState, setState)
                            .pipe(
                                map((request) =>
                                    iif(
                                        () => predicate(action, request as FirstPartyFetchResponse.T<unknown>),
                                        successLensFunction(of(action), getState, setState),
                                        catchLensFunction(of(action), getState, setState),
                                    )
                                ),
                                concatAll()
                            )
                    ),
                    concatAll()
                )
                .subscribe();
