import React from "react";
import { CRMIcon } from "../CRMIcon/CRMIcon";
import { useOpenClose } from "../../hooks/UseOpenClose";

type TTooltipProps = {
    linkText: string;
    children: JSX.Element;
};

export const CRMTooltip = (props: TTooltipProps): JSX.Element => {
    const {
        ref,
        isOpen,
        setIsOpen,
    } = useOpenClose();

    return (
        <div
            className="crm-tooltip"
            ref={ref}
        >
            <div
                className="crm-tooltip__text"
                onClick={() => setIsOpen(true)}
            >
                {props.linkText}&nbsp;
                <CRMIcon
                    iconName="tooltip-open"
                    size="tiny"
                    colour="neutral-ink"
                    inline={true}
                />
            </div>
            {isOpen && <div className="crm-tooltip__tooltip-container">
                {props.children}
            </div>}
        </div>
    );
};
