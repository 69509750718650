import { union } from "../../shared/src/codecs/types/union";
import { literal } from "../../shared/src/codecs/types/literal";
import { TTypeOfCodec } from "../../shared/src/codecs/codec";
import { requireExhaustive } from "../../shared/src/util";
import { pipe } from "fp-ts/lib/function";
import { ledgerTypeDictionary } from "./CaseLedger";
import { filterMap } from "fp-ts/lib/Array";
import { option } from "fp-ts";
import { toArray } from "fp-ts/lib/Record";

export const LegalMIReportType = union([
    literal("time-between-milestones-report"),
    literal("risk-report"),
    literal("coi-report"),
    literal("undertakings-report"),
    literal("bank-account-changes-report"),
    literal("post-completion-report"),
    literal("id-checks-report"),
    literal("late-payment-report"),
    literal("new-cases-report"),
    literal("milestones-report"),
    literal("fees-report"),
    literal("search-ledger-report"),
    literal("case-audit-report"),
    literal("completion-report"),
    literal("bsa-report"),
    literal("legal-referrals-report"),
    literal("property-referrals-report")
]);


export type TLegalMIReportType = TTypeOfCodec<typeof LegalMIReportType>;

// Returns [Name, Description, Help Text (optional)]
export const getReportTitleAndDescription = (report: TLegalMIReportType): [string, string, string | null] => {
    if (report === "risk-report") { return ["Risk Report", "Case open during the month, with risk grade and purchase method where appropriate", null] }
    else if (report === "coi-report") { return ["Conflict Of Interest Report", "Conflicts of interest that were open or resolved in the month", null] }
    else if (report === "undertakings-report") { return ["Undertakings Report", "Report on the non standard undertakings of cases that were open this month", null] }
    else if (report === "bank-account-changes-report") { return ["Bank Account Changes Report", "All bank account requests for verification within the month and the related case", null] }
    else if (report === "post-completion-report") { return ["Post Completion Report", "All cases that have completed and are either awaiting registration or registration was completed within the month", null] }
    else if (report === "id-checks-report") { return ["Id Checks Report", "All ID checks within the month (based on time they started the ID check with Stripe)", null] }
    else if (report === "late-payment-report") { return ["Late Payment Report", "All payments that are currently outstanding for cases created after the month start or where paid later than 5 days after completion and where paid during this month", null] }
    else if (report === "new-cases-report") { return ["New Cases Report", "All cases instructed during the selected month", null] }
    else if (report === "milestones-report") { return ["Milestones Report", "Average times to complete milestones of all cases instructed before the end of the month", null] }
    else if (report === "fees-report") { return ["Fees Report", "Breakdown of fees (all fees are excluding vat) for all cases that were open or completed during the month (Note: Does not take into account the cost to us of items like id checks)", null] }
    else if (report === "time-between-milestones-report") { return ["Time Between Milestones Report", "Time between milestones on a per case basis. Shows cases that where open during the month.", null] }
    else if (report === "search-ledger-report") { return ["Search Ledger Report", "All searches ordered in the month on cases that have searches ordered marked as true.", `Searches included [${
        pipe(
            ledgerTypeDictionary,
            toArray,
            filterMap(([, value]) => value.is_a_type_of_search ? option.some(value.displayName) : option.none)
        ).join(", ")
    }]`] }
    else if (report === "case-audit-report") { return ["Case Audit Report", "All audit reports up to the current date (including uncompleted reports)", null] }
    else if (report === "completion-report") { return ["Completion Report", "All cases completed in the month", null] }
    else if (report === "bsa-report") { return ["BSA Report", "All cases marked as affected by BSA. Doesn't filter by month, it's just all of them.", null] }
    else if (report === "legal-referrals-report") { return ["Legal Referrals Report", "Referral status of all cases. Doesn't filter by month, it's just all of them.", null] }
    else if (report === "property-referrals-report") { return ["Property Referrals Report", "Referral status of all properties. Doesn't filter by month, it's just all of them.", null] }
    else { return requireExhaustive(report); }
}