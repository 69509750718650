import React from "react";
import { FontQuicksand } from "../../../BuildingBlocks/FontQuicksand";
import { FontSize } from "../../../BuildingBlocks/FontSize";
import { FrontFontSizes } from "../../../../models/FrontFontSizes";

type TFrontCardTitleProps = {

};

export const FrontCardTitle = (props: React.PropsWithChildren<TFrontCardTitleProps>): JSX.Element => {
    return (
        <FontQuicksand>
            <FontSize size={FrontFontSizes.LARGE}>
                {props.children}
            </FontSize>
        </FontQuicksand>
    );
};
