import React from "react";
import { TContainerStateProps } from "./../../state/TContainerStateProps";
import { CRMSpacingColumn } from "../../components/CRMSpacingColumn/CRMSpacingColumn";
import { CRMTitleSection } from "../../components/CRMTitleSection/CRMTitleSection";
import { CRMSpacer } from "../../components/CRMSpacer/CRMSpacer";
import { CRMAutosaveIndicatorWrap } from "../../components/CRM/CRMAutosaveIndicatorWrap/CRMAutosaveIndicatorWrap";
import { CRMCodecEditForm } from "../../components/CRMCodecEditForm/CRMCodecEditForm";
import { CaseOutgoingPaymentsSellerSolicitor, CaseOutgoingPaymentsSailLegalInvoice } from "../../../../domain/codecs/Cases";
import { CRMTitleSubSection } from "../../components/CRMTitleSubSection/CRMTitleSubSection";
import { CRMHorizontalSeperatorLine } from "../../components/CRMHorizontalSeperatorLine/CRMHorizontalSeperatorLine";

const CRMLegalCaseDetailsOutgoingPaymentsContainer = (props: TContainerStateProps): JSX.Element => (
    <CRMAutosaveIndicatorWrap
        status={props.state.forms.case_details_page.data.output.details.status}
    >
        <CRMTitleSection>
            Outgoing payments
        </CRMTitleSection>
        <CRMSpacer size="large" />
        <CRMSpacingColumn spacing="medium">
            {/* PAYING THE SELLERS SOLICITOR */}
            {(
                props.state.forms.case_details_page.data.output.details.children.transaction_type === "purchase"
                || props.state.forms.case_details_page.data.output.details.children.transaction_type === "transfer"
            ) && (
                props.state.forms.case_details_page.data.output.details.children.purchase_payment_method === "cash"
                || props.state.forms.case_details_page.data.output.details.children.purchase_payment_method === "mortgage"
            ) && <>
                <CRMTitleSubSection>
                    To seller's solicitor
                </CRMTitleSubSection>
                <CRMCodecEditForm
                    codec={CaseOutgoingPaymentsSellerSolicitor}
                    model={props.state.forms.case_details_page.data.output.details.edited}
                    validationErrors={props.state.forms.case_details_page.data.output.details.validationErrors}
                    onChange={(edited) => props.dispatch({
                        type: "LEGAL_CASE_DETAILS_CHANGE",
                        payload: {
                            ...props.state.forms.case_details_page.data.output.details,
                            edited: {
                                ...props.state.forms.case_details_page.data.output.details.edited,
                                ...edited,
                            }
                        }
                    })}
                    columns={2}
                    showDeferDateLabels={true}
                />
                <CRMHorizontalSeperatorLine />
            </>}

            {/* PAYING SAIL LEGALS INVOICE */}
            <CRMTitleSubSection>
                To our office account
            </CRMTitleSubSection>
            <CRMCodecEditForm
                codec={CaseOutgoingPaymentsSailLegalInvoice}
                model={props.state.forms.case_details_page.data.output.details.edited}
                validationErrors={props.state.forms.case_details_page.data.output.details.validationErrors}
                onChange={(edited) => props.dispatch({
                    type: "LEGAL_CASE_DETAILS_CHANGE",
                    payload: {
                        ...props.state.forms.case_details_page.data.output.details,
                        edited: {
                            ...props.state.forms.case_details_page.data.output.details.edited,
                            ...edited,
                        }
                    }
                })}
            />
        </CRMSpacingColumn>
    </CRMAutosaveIndicatorWrap>
);

export default CRMLegalCaseDetailsOutgoingPaymentsContainer;