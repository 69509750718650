import React from "react";
import { IOption } from "../../../hooks/UseDropdown";
import { CRMIcon } from "../../CRMIcon/CRMIcon";
import UnstyledInputGeneralComponent from "../../UnstyledInputGeneralComponent/UnstyledInputGeneralComponent";
import { SpacingRow } from "../../BuildingBlocks/SpacingRow";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { CRMFontSizes } from "../../../models/CRMFontSizes";
import { WeightBold } from "../../WeightBold/WeightBold";
import { Background } from "../../BuildingBlocks/Background";
import { CRMEmptyPlaceholder } from "../CRMEmptyPlaceholder/CRMEmptyPlaceholder";
import { TFormStatus } from "../../../../../shared/src/codecs/codec";
import { CRMDropdownOptionBox } from "../CRMDropdownOptionBox/CRMDropdownOptionBox";
import { useFilterDropdown } from "../../../hooks/useFilterDropdown";
import { FuzzySearchHighlightedText } from "../../../hooks/useFuzzysort";
import { CRMLoadingBar } from "../CRMLoadingBar/CRMLoadingBar";
import { TextColor } from "../../BuildingBlocks/TextColor";
import { CRMColors } from "../../../models/CRMColors";
import { CRMDropdownOption } from "../CRMDropdownOption/CRMDropdownOption";
import { CRMDropdownFaceFlat } from "../CRMDropdownFaceFlat/CRMDropdownFaceFlat";

interface ICRMFilterDropdownProps<A extends string> {
    value: A;
    valuePrefix?: string;
    placeholder?: string;
    options: Array<IOption<A>>;
    keepOriginalSortOrder?: boolean;
    onChange: (value: A) => void;
    
    searchStatus?: TFormStatus;
    searchText?: string;
    preserveSearch?: boolean;
    onSetSearchText?: (value: string) => void;
    
    displayError?: boolean;
    disabled?: boolean;
}

export const CRMFilterDropdownFlat = <A extends string>(props: ICRMFilterDropdownProps<A>): JSX.Element => {

    const {
        dropdownRef,
        filterInputRef,
        isOpen,
        closeDropdown,
        toggleDropdown,
        getFaceText,
        getFaceStatus,
        getOptionBoxStatus,
        isOptionSelected,
        onSelectOption,
        onChangeFilterText,
        getOptions,
        isLoadingResults
    } = useFilterDropdown({
        value: props.value,
        options: props.options,
        filterText: props.searchText,
        filterStatus: props.searchStatus,
        onChange: props.onChange,
        preserveFilterText: props.preserveSearch,
        setFilterText: props.onSetSearchText,
    });

    const onClickFace = () => {
        if (props.searchText === "" || !isOpen) {
            toggleDropdown();
        }
    }

    return (
        <div
            ref={dropdownRef}
            className="crm-filter-dropdown-flat"
        >
            {/* FACE */}
            <CRMDropdownFaceFlat
                isOpen={isOpen}
                toggleOpen={onClickFace}
                displayError={props.displayError}
                disabled={props.disabled}
            >
                {getFaceStatus() === "open" &&
                    <SpacingRow spacing={CRMSpacing.TINY}>
                        <CRMIcon
                            iconName="search-list"
                            size="small"
                            colour="neutral-ink"
                        />
                        <UnstyledInputGeneralComponent
                            inputRef={filterInputRef}
                            className="crm-filter-dropdown-flat__face-input"
                            inputType="text"
                            value={props.searchText}
                            displayError={false}
                            onChange={(text) => {
                                onChangeFilterText(text);
                                if (text === "") {
                                    props.onChange("" as A)
                                };
                            }}
                            onPressTabKey={closeDropdown}
                        />
                    </SpacingRow>
                }

                {getFaceStatus() === "closed-selected" &&
                    <SpacingRow spacing={CRMSpacing.TINY}>
                        {!!props.valuePrefix &&
                            <WeightBold>{props.valuePrefix}</WeightBold> 
                        }
                        <Background padding={`0px ${CRMSpacing.LARGE} 0px 0px`}>
                            <span>{getFaceText()}</span>
                        </Background>
                    </SpacingRow>
                }

                {getFaceStatus() === "closed-empty" &&
                    <TextColor color={CRMColors.NEUTRAL_4}>
                        {props.placeholder || "Select Value"}
                    </TextColor>
                }
            </CRMDropdownFaceFlat>

            {/* OPTIONS */}
            {isOpen &&
                <CRMDropdownOptionBox>
                    {isLoadingResults() && 
                        <CRMLoadingBar duration={1000} />
                    }
                    
                    {getOptionBoxStatus() === "no-search-made" &&
                        <Background padding={CRMSpacing.MEDIUM}>
                            <CRMEmptyPlaceholder
                                iconName="search-list"
                                iconSize="50px" 
                                textGap={"0px"}
                                fontSize={CRMFontSizes.SMALL}
                            >
                                No search made yet
                            </CRMEmptyPlaceholder>
                        </Background>
                    }

                    {getOptionBoxStatus() === "searching-on-empty" &&
                        <Background padding={CRMSpacing.MEDIUM}>
                            <CRMEmptyPlaceholder
                                iconName="search-list"
                                iconSize="50px" 
                                textGap={"0px"}
                                fontSize={CRMFontSizes.SMALL}
                            >
                                Searching...
                            </CRMEmptyPlaceholder>
                        </Background>
                    }
                    
                    {getOptionBoxStatus() === "no-match" &&
                        <Background padding={CRMSpacing.MEDIUM}>
                            <CRMEmptyPlaceholder
                                iconSize="50px" 
                                textGap={"0px"}
                                fontSize={CRMFontSizes.SMALL}
                            >
                                No match
                            </CRMEmptyPlaceholder>
                        </Background>
                    }

                    {getOptionBoxStatus() === "match" && 
                        getOptions(props.keepOriginalSortOrder).map((result) => (
                            <CRMDropdownOption
                                key={result.obj.value}
                                isSelected={isOptionSelected(result.obj.value as A)}
                                label={
                                    <FuzzySearchHighlightedText
                                        result={result}
                                        className="crm-filter-dropdown-flat__option-highlight"
                                    />
                                }
                                onClick={() => onSelectOption({
                                    label: result.obj.label,
                                    value: result.obj.value as A
                                })}
                            />
                        ))
                    }
                </CRMDropdownOptionBox>
            }
        </div>
    );
};
