import React from "react";
interface IHeading6 {
    text: string;
    modifierName?: "camel"; // Useful for when text is on a different background colour than normal
}

class Heading6 extends React.Component<React.PropsWithChildren<IHeading6>> {
    public render (): JSX.Element {
        return (
            <h6 className={`heading6${this.props.modifierName ? " heading6--" + this.props.modifierName : ""}`}>{this.props.text}</h6>
        );
    }
}

export default Heading6;
