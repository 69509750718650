import * as t from "io-ts";
import * as ListingEnquiriesOffer6 from "./ListingEnquiriesOffer6";
import * as BuyerEnquiry1 from "./BuyerEnquiry1";

export const codec = t.boolean;

export type T = t.TypeOf<typeof codec>;

const hasProvidedSolicitorDetails = (
    obj: {
        conveyancer_organisation_name: string;
        conveyancer_name: string;
        conveyancer_phone_number: string | null;
        conveyancer_address: string;
        conveyancer_email_address: string | null;
    }
): T =>
    obj.conveyancer_organisation_name
    && obj.conveyancer_address
    && obj.conveyancer_name
    && obj.conveyancer_phone_number
    && obj.conveyancer_email_address ? true : false;

export const fromListingEnquiriesOffer6 = (m: ListingEnquiriesOffer6.T): T => hasProvidedSolicitorDetails({
    conveyancer_address: m.conveyancer_address,
    conveyancer_email_address: m.conveyancer_email_address,
    conveyancer_name: m.conveyancer_name,
    conveyancer_organisation_name: m.conveyancer_organisation_name,
    conveyancer_phone_number: m.conveyancer_phone_number,
});

export const fromBuyerEnquiry1 = (m: BuyerEnquiry1.T): T => hasProvidedSolicitorDetails({
    conveyancer_address: m.conveyancer_address,
    conveyancer_email_address: m.conveyancer_email_address,
    conveyancer_name: m.conveyancer_name,
    conveyancer_organisation_name: m.conveyancer_organisation_name,
    conveyancer_phone_number: m.conveyancer_phone_number,
});
