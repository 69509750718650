import React, { useState } from "react";
import { TFontWeightNumeric } from "../../../models/TCRMFontWeight";

export type THoverHighlightTextProps = {
    value: string;
    placeholder: string;
    onChange: (value: string) => void;
    weight?: TFontWeightNumeric;
    disabled?: boolean;
};
export const CRMHoverHighlightTextInput = (props: React.PropsWithChildren<THoverHighlightTextProps>): JSX.Element => {

    const [InitialValueCache] = useState(props.value);
    const [isFocused, setIsFocused] = useState(false);
    const InputRef = React.createRef<HTMLInputElement>();
    const IsEmpty = props.value.trim().length === 0;
    const MinWidth = `${props.placeholder.length * 8}px`;

    return (
        <div className={`crm-hover-highlight-text-input`}>
            <span
                ref={InputRef}
                className={`
                    crm-hover-highlight-text-input__field
                    crm-hover-highlight-text-input__field--${IsEmpty ? "empty" : "full"}
                    ${props.disabled ? "crm-hover-highlight-text-input__field--disabled" : ""}
                `}
                style={{
                    fontWeight: props.weight || 500,
                    minWidth: MinWidth
                }}
                contentEditable={!props.disabled}
                onKeyUp={(event) => props.disabled ? ({}) : props.onChange(event.currentTarget.outerText)}
                onBlur={() => setIsFocused(false)}
                onFocus={() => setIsFocused(true)}
            >
                {InitialValueCache}
            </span>
            
            {/* PLACEHOLDER */}
            <span
                className={`
                    crm-hover-highlight-text-input__placeholder
                    crm-hover-highlight-text-input__placeholder--${!isFocused && IsEmpty ? "showing" : "hidding"}
                `}
                style={{
                    width: MinWidth
                }}
            >
                {props.placeholder}
            </span>
        </div>
    );
};
