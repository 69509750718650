import React from "react";
import * as TFormModels from "../../models/TFormModels";
import { array, option } from "fp-ts";
import { CRMCaseDocumentCard } from "../CRMCaseDocumentCard/CRMCaseDocumentCard";
import * as util from "../../../../shared/src/util";
import { pipe } from "fp-ts/lib/function";
import { SpacingColumn } from "../BuildingBlocks/SpacingColumn";
import { CRMSpacing } from "../../models/CRMSpacing";
import * as CaseDocumentType1 from "../../../../domain/models/CaseDocumentType1";

type TLegalCaseDocumentGridProps = {
    mode: "view" | "add-new";
    title: string;
    documentFormList: TFormModels.TCaseDocument6AndCaseDocumentForm1FormList;
    documentFilesFormList: TFormModels.TCaseDocumentFile4AndCaseDocumentFileForm1FormList;
    activeUploads: Record<string, Record<string, "in_progress" | "complete" | "error">>
    onNewFileUploadChange: (documentId: string, files: File[]) => void;
    onDeleteDocument: (documentId: string) => void;
    onDueDateChange: (documentId: string, value: string) => void;
    onValidUntilChange: (documentId: string, value: string | null) => void;
    onReceivedDateChange: (documentId: string, value: string | null) => void;
    onDocumentAdditionalNameChange: (documentId: string, value: string) => void;
    onDocumentFromExternalSourceChange: (documentId: string, value: boolean) => void;
    onFileNameChange: (fileId: string, value: string) => void;
    onFileDelete: (fileId: string) => void;
    onDocumentTypeChange: (documentId: string, value: CaseDocumentType1.T) => void;
    onDocumentSharedWithClientChange: (documentId: string, value: boolean) => void;
    onDocumentSharedWithSolicitorChange: (documentId: string, value: boolean) => void;
};

export const CRMLegalCaseDocumentGrid = (props: TLegalCaseDocumentGridProps): JSX.Element => (
    <div className="crm-legal-case-document-grid">
        
        <SpacingColumn spacing={CRMSpacing.MEDIUM}>
            {/* Title */}
            {props.title && <div className="crm-legal-case-document-grid__title">
                {props.title} ({props.documentFormList.forms.length})
            </div>}

            {/* Grid container */}
            <div className={`
                crm-legal-case-document-grid__grid-container
                ${
                    props.mode === "view" ? 
                        "crm-legal-case-document-grid__grid-container--view"
                        : props.mode === "add-new" ? 
                            "crm-legal-case-document-grid__grid-container--add-new"
                            : util.requireExhaustive(props.mode)
                }
            `}>
                { pipe(
                    props.documentFormList.forms,
                    array.mapWithIndex((i, form) =>
                        <CRMCaseDocumentCard
                            key={form.view.id}
                            documentForm={form}
                            filesForms={
                                pipe(
                                    props.documentFilesFormList.forms,
                                    array.filter((fileForm) => fileForm.view.cases_document_id === form.view.id),
                                )
                            }
                            activeUploads={props.activeUploads[form.view.id] ?? ({})}
                            onDueDateChange={(value) => props.onDueDateChange(form.view.id, value)}
                            onValidUntilChange={(value) => props.onValidUntilChange(form.view.id, value)}
                            onReceivedDateChange={(value) => props.onReceivedDateChange(form.view.id, value)}
                            onFileNameChange={(fileId, value) => props.onFileNameChange(fileId, value)}
                            onFileDelete={props.onFileDelete}
                            onNewFileUploadChange={(files) => props.onNewFileUploadChange(form.view.id, files)}
                            onDeleteDocument={() => props.onDeleteDocument(form.view.id)}
                            onDocumentAdditionalNameChange={(value) => props.onDocumentAdditionalNameChange(form.view.id, value)}
                            onDocumentTypeChange={(value) => props.onDocumentTypeChange(form.view.id, value)}
                            onDocumentFromExternalSourceChange={(value) => props.onDocumentFromExternalSourceChange(form.view.id, value)}
                            onDocumentSharedWithClientChange={(value) => props.onDocumentSharedWithClientChange(form.view.id, value)}
                            onDocumentSharedWithSolicitorChange={(value) => props.onDocumentSharedWithSolicitorChange(form.view.id, value)}
                        />
                    )
                )}
            </div>
        </SpacingColumn>
    </div>
);
