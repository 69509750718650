import React, { ReactFragment } from "react";
import { TPrimary18, TPrimary6 } from "../../../../models/TFrontMainColours";
interface IFrontButtonMicro {
    label: string | ReactFragment;
    fullWidth?: boolean;
    colour?: TPrimary6 | TPrimary18;
    onClick: () => void;
}

class FrontButtonMicro extends React.Component<React.PropsWithChildren<IFrontButtonMicro>> {
    public render (): JSX.Element {
        return (
            <button
                className={`
                    front-button-micro
                    front-button-micro--colour-${this.props.colour || "neutral-18"}
                    front-button-micro--${this.props.fullWidth ? " full-width" : "auto"}
                `}
                onClick={() => this.props.onClick()}
                onKeyDown={(e) => (e.keyCode === 13) && this.props.onClick()}
            >
                {this.props.label}
            </button>
        );
    }
}

export default FrontButtonMicro;
