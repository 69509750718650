import React from "react";
import AnchorLink from "../OldFront/Simple/AnchorLinkComponent/AnchorLink";
import { CRMIcon } from "../CRMIcon/CRMIcon";

interface ICRMSectionLinkProps {
    href: string;
    onClick?: (e: React.MouseEvent) => void;
}

export class CRMSectionLink extends React.Component<React.PropsWithChildren<ICRMSectionLinkProps>> {
    public render (): JSX.Element {
        return (
            <AnchorLink url={this.props.href}
                onClick={
                    this.props.onClick !== undefined ?
                    (e) => {
                        e.preventDefault();
                        (this.props.onClick as (ev: React.MouseEvent) => void)(e);
                    }
                    : this.props.onClick
                }
                text={
                    <div className="crm-section-link">
                        <span className="crm-section-link__text">
                            {this.props.children}
                        </span>
                        <CRMIcon iconName="open" colour="neutral-ink" size="tiny"/>
                    </div>
                }
            />
        );
    }
}
