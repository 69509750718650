import React, { useEffect } from "react";
import { TContainerStateProps } from "./../../state/TContainerStateProps";
import { CRMCardOutside } from "../../components/CRMCardOutside/CRMCardOutside";
import { CRMSpacingColumn } from "../../components/CRMSpacingColumn/CRMSpacingColumn";
import { CRMPadding } from "../../components/Simple/CRMPadding/CRMPadding";
import { CRMLegalCaseDetailsSections, CRMLegalFormSections, TCRMLegalCaseDetailsSections } from "../../models/CRMFormSections";
import CRMLegalCaseDetailsPartyContainer from "../CRMLegalCaseDetailsPartyContainer/CRMLegalCaseDetailsPartyContainer";
import CRMLegalCaseDetailsRiskManagementContainer from "../CRMLegalCaseDetailsRiskManagementContainer/CRMLegalCaseDetailsRiskManagementContainer";
import CRMLegalCaseDetailsManagementCompanyDetailsContainer from "../CRMLegalCaseDetailsManagementCompanyDetailsContainer/CRMLegalCaseDetailsManagementCompanyDetailsContainer";
import CRMLegalCaseDetailsCaseMilestonesContainer from "../CRMLegalCaseDetailsCaseMilestonesContainer/CRMLegalCaseDetailsCaseMilestonesContainer";
import CRMLegalCaseDetailsExchangeAndCompleteContainer from "../CRMLegalCaseDetailsExchangeAndCompleteContainer/CRMLegalCaseDetailsExchangeAndCompleteContainer";
import CRMLegalCaseDetailsCaseManagementContainer from "../CRMLegalCaseDetailsCaseManagementContainer/CRMLegalCaseDetailsCaseManagementContainer";
import CRMLegalCaseDetailsClientBankAccountsContainer from "../CRMLegalCaseDetailsOtherSideClientContainer/CRMLegalCaseDetailsClientBankAccountsContainer";
import CRMLegalCasePostCompletionContainer from "../CRMLegalCasePostCompletionContainer/CRMLegalCasePostCompletionContainer";
import CRMLegalCaseDetailsSurveyContainer from "../CRMLegalCaseDetailsSurveyContainer/CRMLegalCaseDetailsSurveyContainer";
import CRMLegalCaseDetailsMortgageOfferContainer from "../CRMLegalCaseDetailsMortgageOfferContainer/CRMLegalCaseDetailsMortgageOfferContainer";
import CRMLegalCaseDetailsClientFundsContainer from "../CRMLegalCaseDetailsClientFundsContainer/CRMLegalCaseDetailsClientFundsContainer";
import CRMLegalCaseDetailsOutgoingPaymentsContainer from "../CRMLegalCaseDetailsOutgoingPaymentsContainer/CRMLegalCaseDetailsOutgoingPaymentsContainer";
import CRMLegalCaseDetailsSellerConveyancerBankAccountContainer from "../CRMLegalCaseDetailsSellerConveyancerBankAccountContainer/CRMLegalCaseDetailsSellerConveyancerBankAccountContainer";
import { CRMSailLegalAdHocJobsCard } from "../../components/CRMSailLegalAdHocJobsCard/CRMSailLegalAdHocJobsCard";
import CRMLegalCaseDetailsRelatedTransactionContainer from "../CRMLegalCaseDetailsRelatedTransactionContainer/CRMLegalCaseDetailsRelatedTransactionContainer";
import CRMLegalCaseDetailsVideoVerificationCallsContainer from "../CRMLegalCaseDetailsVideoVerificationCallsContainer/CRMLegalCaseDetailsVideoVerificationCallsContainer";
import CRMLegalCaseSdltContainer from "../CRMLegalCaseSdltContainer/CRMLegalCaseSdltContainer";
import CRMLegalCaseDetailsAuditContainer from "../CRMLegalCaseDetailsAuditContainer/CRMLegalCaseDetailsAuditContainer";
import CRMLegalCaseDetailsDualRepContainer from "../CRMLegalCaseDetailsDualRepContainer/CRMLegalCaseDetailsDualRepContainer";
import { CRMBottomControlBar } from "../../components/CRMBottomControlBar/CRMBottomControlBar";
import { CRMBlockFilterCheckboxes } from "../../components/Simple/CRMBlockFilterCheckboxes/CRMBlockFilterCheckboxes";
import { SpacingRow } from "../../components/BuildingBlocks/SpacingRow";
import { array } from "fp-ts";
import { pipe } from "fp-ts/lib/function";
import { arrayOfStringsToPipeString } from "../../../../shared/src/util";
import { MinHeight } from "../../components/BuildingBlocks/MinHeight";
import { CRMBlockScrollCacheView } from "../../components/CRM/CRMBlock/CRMBlockViews/CRMBlockViews";
import { TBlockContainerStateProps } from "../../state/TBlockContainerStateProps";

const canShowCaseManagement = (props: TContainerStateProps) =>
    props.state.global.user.user_role === "sail_legal_authoriser_staff"
        || props.state.global.user.user_role === "sail_legal_non_authoriser_staff"
        || props.state.global.user.user_role === "admin";

const canShowRiskManagement = (props: TContainerStateProps) =>
    props.state.forms.case_details_page.data.output.details.children.status !== "quote";
    
const canShowExchangeAndComplete = (props: TContainerStateProps) =>
    props.state.forms.case_details_page.data.output.details.children.status !== "property_report"
        && props.state.forms.case_details_page.data.output.details.children.status !== "quote";

const canShowPostCompletion = (props: TContainerStateProps) =>
    props.state.forms.case_details_page.data.output.details.edited.has_completed
        ? true : false;

const canShowManagementCompanyDetails = (props: TContainerStateProps) =>
    props.state.forms.case_details_page.data.output.details.children.has_management_company === true
        && props.state.forms.case_details_page.data.output.details.children.status !== "quote";

const canShowSellerConveyancerBankAccount = (props: TContainerStateProps) =>
    (
        props.state.forms.case_details_page.data.output.details.children.transaction_type === "purchase"
        || props.state.forms.case_details_page.data.output.details.children.transaction_type === "transfer"
    )
        && props.state.forms.case_details_page.data.output.details.children.status !== "quote";

const canShowRelatedTransaction = (props: TContainerStateProps) =>
    props.state.forms.case_details_page.data.output.details.children.transaction_type === "purchase" || 
        props.state.forms.case_details_page.data.output.details.children.transaction_type === "sale";

const canShowSurvey = (props: TContainerStateProps) =>
    props.state.forms.case_details_page.data.output.details.children.transaction_type === "purchase"
        && props.state.forms.case_details_page.data.output.details.children.status !== "quote";

const canShowMortgageOffer = (props: TContainerStateProps) =>
    (   
        props.state.forms.case_details_page.data.output.details.children.purchase_payment_method === "mortgage"
        || props.state.forms.case_details_page.data.output.details.children.transaction_type === "remortgage"
    )
    && props.state.forms.case_details_page.data.output.details.children.status !== "quote";

const canShowClientBankAccounts = (props: TContainerStateProps) =>
    (
        props.state.forms.case_details_page.data.output.details.children.transaction_type === "sale"
        || props.state.forms.case_details_page.data.output.details.children.transaction_type === "remortgage"
    )
    && props.state.forms.case_details_page.data.output.details.children.status !== "quote";

const canShowClientFunds = (props: TContainerStateProps) =>
    props.state.forms.case_details_page.data.output.details.children.status !== "quote"
    && (
        props.state.forms.case_details_page.data.output.details.children.transaction_type === "sale"
        || props.state.forms.case_details_page.data.output.details.children.transaction_type === "purchase"
        || props.state.forms.case_details_page.data.output.details.children.transaction_type === "transfer"
    );

const canShowOutgoingPayments = (props: TContainerStateProps) =>
    props.state.forms.case_details_page.data.output.details.children.status !== "quote";

const canShowVideoVerificationCalls = (props: TContainerStateProps) =>
    props.state.forms.case_details_page.data.output.details.children.status !== "quote";

const canShowSdlt = (props: TContainerStateProps) =>
    props.state.forms.case_details_page.data.output.details.children.status !== "quote"
    && (
        props.state.forms.case_details_page.data.output.details.children.transaction_type === "purchase"
        || props.state.forms.case_details_page.data.output.details.children.transaction_type === "transfer"
    )
    && (props.state.forms.case_details_page.data.output.details.children.price_pence || 0) >= 4000000;

const canShowAudit = (props: TContainerStateProps) =>
    props.state.forms.case_details_page.data.output.details.children.audit.children.selected_for_audit
    && (
        props.state.global.user.user_role === "admin"
        || props.state.global.user.user_role === "sail_legal_authoriser_staff"
    )
    && props.state.forms.case_details_page.data.output.details.children.is_instructed !== null
    && props.state.forms.case_details_page.data.output.details.children.status !== "quote"
    && props.state.forms.case_details_page.data.output.details.children.status !== "closed_unsuccessfully"
    && props.state.forms.case_details_page.data.output.details.children.status !== "closed_created_in_error"
;

const isShowingRiskManagement = (props: TBlockContainerStateProps) =>
    isDetailsSectionSelected("risk_management", props) 
    && canShowRiskManagement(props)
;

const isShowingExchangeAndComplete = (props: TBlockContainerStateProps) =>
    isDetailsSectionSelected("exchange_and_complete", props)
    && canShowExchangeAndComplete(props)
;

const isShowingPostCompletion = (props: TBlockContainerStateProps) =>
    isDetailsSectionSelected("post_completion", props)
    && canShowPostCompletion(props)
;

const isShowingManagementCompanyDetails = (props: TBlockContainerStateProps) =>
    isDetailsSectionSelected("management_company_details", props)
    && canShowManagementCompanyDetails(props)
;

const isShowingSellerConveyancerBankAccount = (props: TBlockContainerStateProps) =>
    isDetailsSectionSelected("seller_conveyancer_bank_account", props) 
    && canShowSellerConveyancerBankAccount(props)
;

const isShowingRelatedTransaction = (props: TBlockContainerStateProps) =>
    isDetailsSectionSelected("related_transaction", props) 
    && canShowRelatedTransaction(props)
;

const isShowingSurvey = (props: TBlockContainerStateProps) =>
    isDetailsSectionSelected("survey", props) 
    && canShowSurvey(props)
;

const isShowingMortgageOffer = (props: TBlockContainerStateProps) =>
    isDetailsSectionSelected("mortgage_offer", props) 
    && canShowMortgageOffer(props)
;

const isShowingClientBankAccounts = (props: TBlockContainerStateProps) =>
    isDetailsSectionSelected("client_bank_accounts", props) 
    && canShowClientBankAccounts(props)
;

const isShowingClientFunds = (props: TBlockContainerStateProps) =>
    isDetailsSectionSelected("client_funds", props) 
    && canShowClientFunds(props)
;

const isShowingOutgoingPayments = (props: TBlockContainerStateProps) =>
    isDetailsSectionSelected("outgoing_payments", props) 
    && canShowOutgoingPayments(props)
;

const isShowingVideoVerificationCalls = (props: TBlockContainerStateProps) =>
    isDetailsSectionSelected("video_verification_calls", props) 
    && canShowVideoVerificationCalls(props)
;

const isShowingSdlt = (props: TBlockContainerStateProps) =>
    isDetailsSectionSelected("stamp_duty_land_tax", props) 
    && canShowSdlt(props)
;

const isShowingAudit = (props: TBlockContainerStateProps) =>
    isDetailsSectionSelected("audit", props)
    && canShowAudit(props)
;

const isShowingDualRep = (props: TBlockContainerStateProps) =>
    isDetailsSectionSelected("dual_rep", props)
;

const isShowingCaseManagement = (props: TBlockContainerStateProps) =>
    isDetailsSectionSelected("case_management", props) && 
    canShowCaseManagement(props)
;

const isDetailsSectionSelected = (section: TCRMLegalCaseDetailsSections[number], props: TBlockContainerStateProps): boolean => 
    {
        const block = props.state.routes.blockParams.VIEW_CRM_LEGAL_CASE[props.index];
        return CRMLegalCaseDetailsSections.isDetailsSectionSelected(
            "visible_sections" in block.meta ? block.meta.visible_sections : undefined,
            section
        );
    };

const CRMLegalCaseDetailsContainer = React.memo((props: TBlockContainerStateProps): JSX.Element => {

    useEffect(
        () => {
            if (!props.isFirstLoad) {
                props.dispatch({
                    type: "LEGAL_CASE_DETAILS_VIEW",
                    payload: null,
                })
            }
        },
        []
    );

    const getVisibleSections = () => {
        const block = props.state.routes.blockParams.VIEW_CRM_LEGAL_CASE[props.index];
        return "visible_sections" in block.meta ? block.meta.visible_sections : undefined
    }

    return (
        <CRMBlockScrollCacheView isShowing={true}>
            <CRMCardOutside borderRounding="right">
                <MinHeight height="100vh">
                    <CRMPadding size="large">
                        <CRMSpacingColumn spacing="large">
                            {/* AD HOC JOBS */}
                            <CRMSailLegalAdHocJobsCard
                                createForm={props.state.forms.case_details_page.data.output.create_new_ad_hoc_job}
                                jobs={props.state.forms.case_details_page.data.output.ad_hoc_jobs}
                                onUpdateJobs={(payload) => props.dispatch({
                                    type: "LEGAL_CASE_UPDATE_AD_HOC_JOBS",
                                    payload,
                                })}
                                onUpdateNewAdhoc={(payload) => props.dispatch({
                                    type: "LEGAL_CASE_UPDATE_ADD_AD_HOC_JOB",
                                    payload,
                                })}
                                onSubmitNewAdhoc={(payload) => props.dispatch({
                                    type: "LEGAL_CASE_ADD_AD_HOC_JOB",
                                    payload,
                                })}
                            />
                            {/* SECTION - CASE MANAGEMENT */}
                            {isShowingCaseManagement(props) && 
                                <CRMLegalCaseDetailsCaseManagementContainer {...props} />
                            }
                            {/* SECTION - DUAL REP */}
                            {isShowingDualRep(props) &&
                                <CRMLegalCaseDetailsDualRepContainer {...props} />
                            }
                            {/* SECTION - RISK MANAGEMENT */}
                            {isShowingRiskManagement(props) &&
                                <CRMLegalCaseDetailsRiskManagementContainer {...props} />
                            }
                            {/* SECTION - CASE MILESTONES */}
                            {isDetailsSectionSelected("case_milestones", props) &&
                                <CRMLegalCaseDetailsCaseMilestonesContainer {...props} />
                            }
                            {/* SECTION - EXCHANGE & COMPLETE */}
                            {isShowingExchangeAndComplete(props) && 
                                <CRMLegalCaseDetailsExchangeAndCompleteContainer {...props} />
                            }
                            {/* SECTION - POST COMPLETION */}
                            {isShowingPostCompletion(props) && 
                                <CRMLegalCasePostCompletionContainer {...props} />
                            }
                            {/* SECTION - CLIENTS */}
                            {isDetailsSectionSelected("clients", props) &&
                                <CRMLegalCaseDetailsPartyContainer {...props} />
                            }
                            {/* SECTION - VIDEO VERIFICATION CALLS */}
                            {isShowingVideoVerificationCalls(props) &&
                                <CRMLegalCaseDetailsVideoVerificationCallsContainer {...props} />
                            }
                            {/* SECTION - MANAGEMENT COMPANY DETAILS */}
                            {isShowingManagementCompanyDetails(props) && 
                                <CRMLegalCaseDetailsManagementCompanyDetailsContainer {...props} />
                            }
                            {/* SECTION - CLIENT BANK ACCOUNTS */}
                            {isShowingClientBankAccounts(props) &&
                                <CRMLegalCaseDetailsClientBankAccountsContainer {...props} />
                            }
                            {/* SECTION - SELLER CONVEYANCER BANK ACCOUNT */}
                            {isShowingSellerConveyancerBankAccount(props) && 
                                <CRMLegalCaseDetailsSellerConveyancerBankAccountContainer {...props} />
                            }
                            {/* SECTION - CLIENT FUNDS */}
                            {isShowingClientFunds(props) && 
                                <CRMLegalCaseDetailsClientFundsContainer {...props} />
                            }
                            {/* SECTION - OUTGOING PAYMENTS */}
                            {isShowingOutgoingPayments(props) && 
                                <CRMLegalCaseDetailsOutgoingPaymentsContainer {...props} />
                            }
                            {/* SECTION - DETAILS SURVEY */}
                            {isShowingSurvey(props) && 
                                <CRMLegalCaseDetailsSurveyContainer {...props} />
                            }
                            {/* SECTION - DETAILS MORTGAGE OFFER */}
                            {isShowingMortgageOffer(props) &&
                                <CRMLegalCaseDetailsMortgageOfferContainer {...props} />
                            }
                            {/* SECTION - RELATED TRANSACTION */}
                            {isShowingRelatedTransaction(props) &&
                                <CRMLegalCaseDetailsRelatedTransactionContainer {...props} />
                            }
                            {/* SECTION - SDLT */}
                            {isShowingSdlt(props) &&
                                <CRMLegalCaseSdltContainer {...props} />
                            }
                            {/* SECTION - AUDIT */}
                            {isShowingAudit(props) &&
                                <CRMLegalCaseDetailsAuditContainer {...props} />
                            }
                        </CRMSpacingColumn>
                    </CRMPadding>
                </MinHeight>
            </CRMCardOutside>

            {/* CONTROL BAR WITH FILTER */}
            <CRMBottomControlBar>
                <SpacingRow justifyContent="end">
                    <CRMBlockFilterCheckboxes
                        options={pipe(
                            CRMLegalCaseDetailsSections.list,
                            array.filter((key) => key === "management_company_details" ? canShowManagementCompanyDetails(props) : true),
                            array.filter((key) => key === "risk_management" ? canShowRiskManagement(props) : true),
                            array.filter((key) => key === "exchange_and_complete" ? canShowExchangeAndComplete(props) : true),
                            array.filter((key) => key === "post_completion" ? canShowPostCompletion(props) : true),
                            array.filter((key) => key === "case_management" ? canShowCaseManagement(props) : true),
                            array.filter((key) => key === "survey" ? canShowSurvey(props) : true),
                            array.filter((key) => key === "mortgage_offer" ? canShowMortgageOffer(props) : true),
                            array.filter((key) => key === "seller_conveyancer_bank_account" ? canShowSellerConveyancerBankAccount(props) : true),
                            array.filter((key) => key === "related_transaction" ? canShowRelatedTransaction(props) : true),
                            array.filter((key) => key === "client_bank_accounts" ? canShowClientBankAccounts(props) : true),
                            array.filter((key) => key === "client_funds" ? canShowClientFunds(props) : true),
                            array.filter((key) => key === "outgoing_payments" ? canShowOutgoingPayments(props) : true),
                            array.filter((key) => key === "video_verification_calls" ? canShowVideoVerificationCalls(props) : true),
                            array.filter((key) => key === "stamp_duty_land_tax" ? canShowSdlt(props) : true),
                            array.filter((key) => key === "audit" ? canShowAudit(props) : true),
                        )}
                        optionsSelected={CRMLegalFormSections.sectionsStringToArray(getVisibleSections())}
                        onSelectedChange={(payload) => props.dispatch({
                            type: "UPDATE_LEGAL_CASE_VISIBLE_SECTIONS_FILTER",
                            payload: {sections: arrayOfStringsToPipeString(payload), index: props.index}
                        })}
                    />
                </SpacingRow>
            </CRMBottomControlBar>
        </CRMBlockScrollCacheView>
    );
});

export default CRMLegalCaseDetailsContainer;