import React from "react";
import { CRMFontSizes } from "../../../models/CRMFontSizes";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { CRMColors } from "../../../models/CRMColors";
import { TCRMMainColours } from "../../../models/TCRMMainColours";
import { FontSignikaNegative } from "../../BuildingBlocks/FontSignikaNegative";
import { SpacingRow } from "../../BuildingBlocks/SpacingRow";
import { CRMIcon, TIcon } from "../../CRMIcon/CRMIcon";
import { FontSize } from "../../BuildingBlocks/FontSize";
import { TextColor } from "../../BuildingBlocks/TextColor";
import { Uppercase } from "../../Uppercase/Uppercase";
import { WeightBold } from "../../WeightBold/WeightBold";

type TCRMPageTabProps = {
    isSelected: boolean;
    label: string;
    icon?: TIcon;
    iconColor?: TCRMMainColours;
    onClick: () => void; 
}

export const CRMPageTab = (props: TCRMPageTabProps): JSX.Element => {
    return (
        <div
            className={`
                crm-page-tab
                crm-page-tab--${props.isSelected ? "selected" : "unselected"}
            `}
            onClick={props.onClick}
        >
            <SpacingRow spacing={CRMSpacing.TINY}>
                <FontSignikaNegative>
                    <FontSize size={CRMFontSizes.MED}>
                        <TextColor color={props.isSelected ? CRMColors.NEUTRAL_INK : CRMColors.NEUTRAL_2}>
                            <Uppercase>
                                <WeightBold>
                                    {props.label}
                                </WeightBold>
                            </Uppercase>
                        </TextColor>
                    </FontSize>
                </FontSignikaNegative>
                {props.icon &&
                    <CRMIcon
                        iconName={props.icon}
                        colour={props.iconColor || "neutral-ink"}
                        size="small"
                    />
                }
            </SpacingRow>
        </div>
    );
}
