import React, { useEffect } from "react";
import { FrontColors } from "../../../../models/FrontColors";
import { FrontSpacing } from "../../../../models/FrontSpacing";
import { HorizontalLine } from "../../../BuildingBlocks/HorizontalLine";
import { MediaCompSwitch } from "../../../BuildingBlocks/MediaCompSwitch";
import { Padding } from "../../../BuildingBlocks/Padding";
import { SpacingColumn } from "../../../BuildingBlocks/SpacingColumn";
import { SpacingRow } from "../../../BuildingBlocks/SpacingRow";
import { WeightBold } from "../../../WeightBold/WeightBold";
import { WeightMedium } from "../../../WeightMedium/WeightMedium";
import { FrontIcon } from "../FrontIcon/FrontIcon";
import FrontParagraphSmall from "../FrontParagraphSmall/FrontParagraphSmall";

type TCondensedQuestionWrapProps = {
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
    label: string;
    summary: JSX.Element | string;
    isEditable?: boolean;
    onOpen?: () => void;
    onClose?: () => void;
};

export const FrontCondensedQuestionWrap = (props: React.PropsWithChildren<TCondensedQuestionWrapProps>): JSX.Element => {

    useEffect(
        () => {
            if (props.isOpen === true && typeof props.onOpen === "function") {
                props.onOpen();    
            }
            if (props.isOpen === false && typeof props.onClose === "function") {
                props.onClose();
            }
        },
        [props.isOpen]
    );

    return (
        <div className={`front-condensed-question-wrap`}>
            {/* IS CLOSED: SUMMARY */}
            {(!props.isOpen || props.isEditable === false) &&
                <div
                    className="front-condensed-question-wrap__summary"
                    onClick={() => props.setIsOpen(true)}
                >
                    <SpacingRow
                        spacing={FrontSpacing.SMALL_2}
                        alignItems="flex-start"
                    >
                        {/* TEXT */}
                        <MediaCompSwitch
                            DesktopComponent={SpacingRow}
                            desktopProps={{ spacing: FrontSpacing.SMALL_1, alignItems: "flex-start" }}
                            MobileComponent={SpacingColumn}
                            mobileProps={{ spacing: FrontSpacing.SMALL_1 }}
                            breakpoint="600"
                        >
                            <FrontParagraphSmall>
                                <WeightMedium>
                                    {props.label}:
                                </WeightMedium>
                            </FrontParagraphSmall>

                            <FrontParagraphSmall>
                                <WeightBold>
                                    {props.summary}
                                </WeightBold>
                            </FrontParagraphSmall>
                        </MediaCompSwitch>

                        {/* ICON */}
                        {props.isEditable === true &&
                            <Padding type="top" spacing={"4px"}>
                                <FrontIcon
                                    iconName="chevron-down"
                                    colour="neutral-ink"
                                    size="tiny"
                                />
                            </Padding>
                        }
                    </SpacingRow>
                </div>
            }

            {/* IS OPEN: CONTENT */}
            {(props.isOpen && props.isEditable !== false ) && <SpacingColumn spacing={FrontSpacing.MEDIUM_1}>
                {props.children}

                <HorizontalLine color={FrontColors.NEUTRAL_11} />
            </SpacingColumn>}
        </div>
    );
};
