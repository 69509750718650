import React from "react";
import { TContainerStateProps } from "../../state/TContainerStateProps";
import { CRMPageWrap } from "../../components/Simple/CRMPageWrap/CRMPageWrap";
import { CRMLegalMenuBarContainer } from "../../containers/CRMLegalMenuBarContainer/CRMLegalMenuBarContainer"
import { Padding } from "../../components/BuildingBlocks/Padding";
import { SpacingRow } from "../../components/BuildingBlocks/SpacingRow";
import { CRMSpacing } from "../../models/CRMSpacing";
import { CRMCaseAutoAssignmentBlock } from "../../components/Complex/CRMCaseAutoAssignmentBlock/CRMCaseAutoAssignmentBlock";
import { CRMBlock } from "../../components/CRM/CRMBlock/CRMBlock/CRMBlock";
import { CRMBlockScrollCacheView } from "../../components/CRM/CRMBlock/CRMBlockViews/CRMBlockViews";

const CRMLegalCaseAutoAssignmentPage = (props: TContainerStateProps): JSX.Element => {
    const pageForm = props.state.forms.case_assignment_page_form;

    return (
        <CRMPageWrap>
            {/* HEADER */}
            <CRMLegalMenuBarContainer {...props} />

            {/* BODY */}
            <Padding
                type="custom" 
                spacing={`${CRMSpacing.X_LARGE} ${CRMSpacing.X_LARGE} 0px`}
            >
                <SpacingRow
                    spacing={CRMSpacing.X_LARGE}
                    alignItems="flex-start"
                >
                    <CRMBlock
                        mode="fixed-option"
                        fixedOption={{
                            icon: "person-tick",
                            title: "Case Auto Assignment"
                        }}
                        optionGroups={[]}
                    >
                        <CRMBlockScrollCacheView isShowing={true}>
                            <CRMCaseAutoAssignmentBlock
                                assignmentForms={pageForm.children.case_auto_assignment_forms}
                                caseHandlers={pageForm.children.case_handlers}
                                caseAssistants={pageForm.children.case_assistants}
                                onChange={(payload) => props.dispatch({
                                    type: "CRM_CASE_AUTO_ASSIGNMENT_CHANGE",
                                    payload,
                                })}
                            />
                        </CRMBlockScrollCacheView>
                    </CRMBlock>
                </SpacingRow>
            </Padding>
        </CRMPageWrap>
    );
};

export default CRMLegalCaseAutoAssignmentPage;
