import { array, option, identity, record } from "fp-ts";
import { pipe } from "fp-ts/lib/pipeable";
import { DateTime } from "luxon";
import { pluralSingleText } from "../util";

// @ts-ignore
export const getDateRecencySortNumber = (dateA: string, dateB: string): number => new Date(dateB) - new Date(dateA);

// @ts-ignore
export const getDateDistanceSortNumber = (dateA: string, dateB: string): number => new Date(dateA) - new Date(dateB);

export const sortDatesByRecency = (dates: Array<string | null>): Array<string> => 
    pipe(
        dates,
        array.filter((date) => date !== null),
        (validDates) => validDates as Array<string>,
        (validDates) => validDates.sort(getDateRecencySortNumber),
    )
;

export const findMostRecentDate = (dates: Array<string | null>): string => 
    pipe(
        sortDatesByRecency(dates),
        array.head,
        option.fold(
            () => "",
            identity.flatten,
        )
    )
;

export const findMostDistantDate = (dates: Array<string | null>): string => 
    pipe(
        sortDatesByRecency(dates),
        array.last,
        option.fold(
            () => "",
            identity.flatten,
        )
    )
;

export const getTimeElapsedCopyText = (date: string): string =>
    pipe(
        date,
        getTimeElapsedInFormatedText((unit) => ` ${unit}`)
    )
;

export const getTimeUntilCopyText = (date: string): string =>
    pipe(
        date,
        getTimeUntilInFormatedText((unit) => ` ${unit}`)
    )
;

const getTimeUntilInFormatedText = (textFormater: (unit: TTimeUnitName) => string ) => 
    (date: string): string =>
        pipe(
            DateTime.local().toISO(),
            getDateDifferenceInFormatedText(
                DateTime.fromISO(date),
                textFormater,
            )
        )
;

const getTimeElapsedInFormatedText = (textFormater: (unit: TTimeUnitName) => string ) => 
    (date: string): string =>
        pipe(
            date,
            getDateDifferenceInFormatedText(
                DateTime.local(),
                textFormater,
            )
        )
;


export type TTimeUnitName = 
    "year"
    | "years"
    | "month"
    | "months"
    | "week"
    | "weeks"
    | "day"
    | "days"
    | "hour"
    | "hours"
    | "minute"
    | "minutes"
    | "moments"
;

export const getDateDifferenceInFormatedText = (dateToCompareAgainst: DateTime, textFormater: (unit: TTimeUnitName) => string ) =>
    (date: string): string =>
        pipe(
            date,
            getDetailsOfDateDifference(dateToCompareAgainst),
            (details) => {
                if (Math.abs(details.years) > 0) {
                    return `${details.years}${textFormater(pluralSingleText("year", "years")(details.years))}`
                }
                if (Math.abs(details.months) > 0) {
                    return `${details.months}${textFormater(pluralSingleText("month", "months")(details.months))}`
                }
                if (Math.abs(details.weeks) > 0) {
                    return `${details.weeks}${textFormater(pluralSingleText("week", "weeks")(details.weeks))}`
                }
                if (Math.abs(details.days) > 0) {
                    return `${details.days}${textFormater(pluralSingleText("day", "days")(details.days))}`
                }
                if (Math.abs(details.hours) > 0) {
                    return `${details.hours}${textFormater(pluralSingleText("hour", "hours")(details.hours))}`
                }
                if (Math.abs(details.minutes) > 0) {
                    return `${details.minutes}${textFormater(pluralSingleText("minute", "minutes")(details.minutes))}`
                }

                return textFormater("moments");
            }
        )
    ;

export const isDateInThePast = (date: string): boolean =>
    pipe(
        DateTime.local().toISO(),
        getDetailsOfDateDifference(DateTime.fromISO(date)),
        record.toArray,
        array.filter(([, unitValue]) => unitValue < 0),
        array.isNonEmpty
    )
;

type TTimeDifference = {
    years: number;
    months: number;
    weeks: number;
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
}

export const getDetailsOfDateDifference = (dateToCompareAgainst: DateTime) => 
    (date: string): TTimeDifference =>
        pipe(
            dateToCompareAgainst
                .diff(DateTime.fromISO(date), ["years", "months", "weeks", "days", "hours", "minutes", "seconds"])
                .toObject() as TTimeDifference
            ,
            record.map(Math.round),
        )
    ;

export const getTwoDigitDayAndMonth = (date: string) =>
    DateTime
        .fromISO(date)
        .toFormat("dd/MM")
;

export const getLeadingZeroDate = (date: string) =>
    DateTime
        .fromISO(date)
        .toFormat("dd/MM/yyyy")
;

export const formatDateCurried = (format?: string) => 
    (date: string) => 
        DateTime.fromISO(date)
            .setZone("Europe/London")
            .toFormat( format || "D")
;

export const formatDate = (date: string, format?: string) => formatDateCurried(format)(date);

export const isToday = (date: string) =>
    DateTime.fromISO(date).toISODate() === DateTime.utc().toISODate()
;


// Result examples:
// Today
// Tomorrow (1 Feb)
// Wednesday (3 Feb)
// Next Wednesday (10 Feb)
// 17 Feb
export const formatDateToDayOfWeekAndFull = (dateTime: string): string => {
    const isToday = DateTime.fromISO(dateTime).toISODate() === DateTime.utc().toISODate();

    const isTomorrow = DateTime.fromISO(dateTime).toISODate() === DateTime.utc().plus({days: 1}).toISODate();

    const isWithin7Days =
        DateTime.fromISO(dateTime).toSeconds() >= DateTime.utc().toSeconds()
        && DateTime.fromISO(dateTime).toSeconds() < DateTime.utc().plus({days: 7}).set({hour: 0, minute: 0, second: 0}).toSeconds();

    const is7To14Days =
        DateTime.fromISO(dateTime).toSeconds() >= DateTime.utc().plus({days: 7}).set({hour: 0, minute: 0, second: 0}).toSeconds()
        && DateTime.fromISO(dateTime).toSeconds() < DateTime.utc().plus({days: 14}).set({hour: 0, minute: 0, second: 0}).toSeconds();

    const dayOfWeek = () => DateTime.fromISO(dateTime).toFormat("EEEE");

    const dateFormatted = () => DateTime.fromISO(dateTime).toFormat("d MMM");

    return isToday ? "Today"
        : isTomorrow ? "Tomorrow"
        : isWithin7Days ? `${dayOfWeek()} (${dateFormatted()})`
        : is7To14Days ? `Next ${dayOfWeek()} (${dateFormatted()})`
        : dateFormatted();
};

export const dateTime = {
    sortDatesByRecency,
    findMostDistantDate,
    findMostRecentDate,
    getDetailsOfDateDifference,
    getTimeElapsedInFormatedText,
    getTimeElapsedCopyText,
    getTimeUntilCopyText,
    getTimeUntilInFormatedText,
    getDateDifferenceInFormatedText,
    isDateInThePast,
    formatDateCurried,
    formatDate,
}
    