import { Observable, timer } from "rxjs";
import { tap, flatMap, debounce, debounceTime, switchMap } from "rxjs/operators";
import { getQueryParams } from "../../../../shared/src/utilsByDomain/location";
import { formOperation } from "../../wrappers/formOperation";
import { TActionsDefinitionsList } from "./TAction";
import { action, routeAction } from "./actionFunctions";

export const actions: TActionsDefinitionsList = [
    routeAction("VIEW_AUTH_2FA", (obs$, lens, set, get) => {
        obs$.pipe(
            flatMap(() => formOperation("GetTwoFactorAuth", lens.buyer2FAPage.get()(get()))),
            tap((response) => set(lens.buyer2FAPage.set(response))),
        ).subscribe();
    }),
    action("2FA_SUBMIT_CODE", (obs$: Observable<string>, lens, setState, getState) => {
        obs$.pipe(
            tap((code) => setState(lens.buyer2FAPage.children.submit_auth_code.edited.auth_code.set(code))),
            debounceTime(300),
            tap(() => setState(lens.buyer2FAPage.children.submit_auth_code.status.set("submitting"))),
            switchMap(() => formOperation("SubmitTwoFactorAuthCode", lens.buyer2FAPage.children.submit_auth_code.get()(getState()))),
            tap((response) => {
                if (response.status === "success") {
                    window.location.href = decodeURIComponent(getQueryParams(window.location)["redirect_url"]);
                } else {
                    setState(lens.buyer2FAPage.children.submit_auth_code.set(response));
                }
            })
        ).subscribe();
    }),
    action("2FA_RESEND_CODE", (obs$: Observable<null>, lens, setState, getState) => {
        obs$.pipe(
            flatMap(() => formOperation("ResendTwoFactorAuthCode", lens.buyer2FAPage.children.resend_auth_code.get()(getState()))),
            tap((response) => setState(lens.buyer2FAPage.children.resend_auth_code.set(response))),
            debounce(() => timer(3000)),
            tap(() => setState(lens.buyer2FAPage.children.resend_auth_code.status.set("untouched"))),
        ).subscribe();
    }),
];
