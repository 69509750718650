import React from "react";
import { array } from "fp-ts";
import { TFrontPadding } from "../../models/TFrontPadding";

type TCRMButtonRow = {
    space?: TFrontPadding;
    justifyContent: "start" | "end" | "center" | "space-around" | "space-between" | "space-evenly";
};

export class FrontRow extends React.Component<React.PropsWithChildren<TCRMButtonRow>> {
    public render (): JSX.Element {
        return (
            <div
                className={`front-button-row front-button-row--${this.props.space || "small-1"}`}
                style={{
                    // stylelint-disable
                    justifyContent: this.props.justifyContent,
                    gridTemplateColumns: array.range(1, React.Children.count(this.props.children)).map(() => "auto").join(" "),
                    // stylelint-enable
                }}
            >
                {this.props.children}
            </div>
        );
    }
}
