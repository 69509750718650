import React from "react";
import { ShineShape } from "../../BuildingBlocks/ShineShape";
import { SpacingRow } from "../../BuildingBlocks/SpacingRow";
import { CRMColors } from "../../../models/CRMColors";
import { CRMSpacing } from "../../../models/CRMSpacing";

type TJobsFilterPlaceholderProps = {

};

export const CRMJobsFilterPlaceholder = (props: React.PropsWithChildren<TJobsFilterPlaceholderProps>): JSX.Element => {

    return (
        <SpacingRow spacing={CRMSpacing.SMALL}>
            <ShineShape
                backgroundColor={CRMColors.NEUTRAL_10} 
                shineColor={CRMColors.NEUTRAL_12}
                duration={2000}
                height={"20px"}
                width={"240px"}
            />
            <ShineShape
                backgroundColor={CRMColors.NEUTRAL_10} 
                shineColor={CRMColors.NEUTRAL_12}
                duration={2000}
                height={"20px"}
                width={"200px"}
            />
            <ShineShape
                backgroundColor={CRMColors.NEUTRAL_10} 
                shineColor={CRMColors.NEUTRAL_12}
                duration={2000}
                height={"20px"}
                width={"240px"}
            />
        </SpacingRow>
    );
};
