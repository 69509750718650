import React from "react";
import { array } from "fp-ts";
import { pipe } from "fp-ts/lib/pipeable";
import { DateTime } from "luxon";
import { TListingSignMovementEditForm } from "../../../../../domain/codecs/form/ListingSignMovementEditForm";
import { getTwoDigitDayAndMonth, getTimeUntilCopyText, isDateInThePast, getTimeElapsedCopyText } from "../../../../../shared/src/utilsByDomain/dateTime";
import { snakeCaseToCopyText } from "../../../../../shared/src/util";
import { array as extArray } from "../../../../../shared/src/utilsByDomain/array"
import { CRMSpacing } from "../../../models/CRMSpacing";
import { CRMColors } from "../../../models/CRMColors";
import { SpacingColumn } from "../../BuildingBlocks/SpacingColumn";
import { SpacingRow } from "../../BuildingBlocks/SpacingRow";
import { CRMButtonFlatColourIcon } from "../../CRMButtonFlatColourIcon/CRMButtonFlatColourIcon";
import { CRMParagraph } from "../../Simple/CRMParagraph/CRMParagraph";
import { CRMIcon } from "../../CRMIcon/CRMIcon";
import { CRMHorizontalSeperatorLine } from "../../CRMHorizontalSeperatorLine/CRMHorizontalSeperatorLine";
import { TextColour } from "../../TextColour/TextColour";
import { Uppercase } from "../../Uppercase/Uppercase";
import { WeightBold } from "../../WeightBold/WeightBold";
import { WeightSemiBold } from "../../WeightSemiBold/WeightSemiBold";
import { useOpenClose } from "../../../hooks/UseOpenClose";
import { Relative } from "../../BuildingBlocks/Relative";
import { Absolute } from "../../BuildingBlocks/Absolute";
import { CRMPopout } from "../../Simple/CRMPopout/CRMPopout";
import { CursorPointer } from "../../CursorPointer/CursorPointer";
import { editFormEditableValue } from "../../../../../shared/src/codecs/types/formEditable";
import { TListingSignMovementType } from "../../../../../domain/codecs/ListingSignMovement";
import { CRMPopoutOptions } from "../../Simple/CRMPopoutOptions/CRMPopoutOptions";
import { CRMCardOutsideSharpCorners } from "../../CRMCardOutsideSharpCorners/CRMCardOutsideSharpCorners";
import { CRMFormButtonWithParagraph } from "../../CRMFormButtonWithParagraph/CRMFormButtonWithParagraph";
import { castNewFormStatusToLegacy } from "../../../util";
import { CRMCardOutsidePopupBasic } from "../../CRMCardOutsidePopupBasic/CRMCardOutsidePopupBasic";
import CRMInputLabelAndErrorWrapComponent from "../../CRMInputLabelAndErrorWrapComponent/CRMInputLabelAndErrorWrapComponent";
import { TListingSignMovementCreateForm } from "../../../../../domain/codecs/form/ListingSignMovementCreateForm";
import { CRMDropdownComponent } from "../../CRMDropdownComponent/CRMDropdownComponent";
import { CRMInputCalendarComponent } from "../../CRMInputCalendarComponent/CRMInputCalendarComponent";
import { CRMReactCalendarWrapper } from "../../CRMReactCalendarWrapper/CRMReactCalendarWrapper";

type TSignMovementsProps = {
    movements: Array<TListingSignMovementEditForm>;
    onUpdateMovements: (movements: Array<TListingSignMovementEditForm>) => void;
    addMovementForm: TListingSignMovementCreateForm;
    onUpdateAddMovement: (movements: TListingSignMovementCreateForm) => void;
    onDelete: (movement: TListingSignMovementEditForm) => void;
    onAdd: (movement: TListingSignMovementCreateForm) => void;
};

const movementTypes: Array<TListingSignMovementType> = [
    "for_sale",
    "sold",
    "collection"
];

export const CRMSignMovements = (props: React.PropsWithChildren<TSignMovementsProps>): JSX.Element => {

    const addMovementPopup = useOpenClose();

    const updateMovement = (movement: TListingSignMovementEditForm) => 
        props.onUpdateMovements(
            pipe(
                props.movements,
                extArray.updateWhere(
                    (movementToUpdate) => movementToUpdate.original.id === movement.original.id,
                    () => movement,
                )
            )
        )
    ;

    const areAllMovementsComplete = (): boolean =>
        pipe(
            props.movements,
            array.filter((movement) => movement.edited.confirmed_install === null),
            array.isEmpty
        )
    ;

    return (
        <div>
             <SpacingColumn spacing={CRMSpacing.MEDIUM}>
                {/* MOVEMENTS */}
                {props.movements.length > 0 &&
                    <SpacingColumn spacing={CRMSpacing.TINY}>
                        {props.movements.map((movement, index) => (
                            <div key={index}>
                                {movement.edited.confirmed_install === null &&
                                    <PendingMovement
                                        movement={movement}
                                        onUpdate={updateMovement}
                                        onDelete={props.onDelete}
                                    />
                                }
                                {movement.edited.confirmed_install &&
                                    <CompletedMovement
                                        movement={movement}
                                    />
                                }
                            </div>
                        ))}
                    </SpacingColumn>
                }
                
                {areAllMovementsComplete() &&
                    <CRMFormButtonWithParagraph
                        formStatus={props.addMovementForm.status === "untouched" ?
                            "requiresSubmission" :
                            castNewFormStatusToLegacy(props.addMovementForm.status)
                        }
                        icon="add"
                        paragraph="Add movement"
                        onClick={addMovementPopup.toggleOpen}
                    />
                }
            </SpacingColumn>
            
            {/* CREATE MOVEMENT POPUP */}
            <CRMCardOutsidePopupBasic
                context="neutral"
                isOpen={addMovementPopup.isOpen}
                title="Add new sign movement"
                onClose={addMovementPopup.toggleOpen}
                ctaText="Add"
                onCTA={() => {
                    props.onAdd(props.addMovementForm);
                    addMovementPopup.toggleOpen();
                }}
                ctaDisabled={props.addMovementForm.edited.expected_install_date === null}
            >
                <SpacingColumn spacing={CRMSpacing.MEDIUM}>
                    {/* NEW SIGN MOVMENT TYPE */}
                    <CRMInputLabelAndErrorWrapComponent label="Type">
                        <CRMDropdownComponent
                            value={props.addMovementForm.edited.type}
                            options={movementTypes.map((movement) => ({
                                value: movement,
                                label: snakeCaseToCopyText(movement),
                            }))}
                            onChange={(type) => 
                                props.onUpdateAddMovement(
                                    editFormEditableValue(
                                        props.addMovementForm,
                                        "type",
                                        type
                                    )
                                )
                            }
                            displayError={false}
                        />
                    </CRMInputLabelAndErrorWrapComponent>

                    {/* NEW SIGN MOVEMENT EXPECTATION DATE */}
                    <CRMInputLabelAndErrorWrapComponent label="Expected install date">
                        <CRMInputCalendarComponent
                            dateType="date"
                            value={props.addMovementForm.edited.expected_install_date || ""}
                            displayError={false}
                            onChange={(date) =>
                                props.onUpdateAddMovement(
                                    editFormEditableValue(
                                        props.addMovementForm,
                                        "expected_install_date",
                                        date
                                    )
                                )
                            }
                            onPressEnterKey={() => null}
                        />
                    </CRMInputLabelAndErrorWrapComponent>
                </SpacingColumn>
            </CRMCardOutsidePopupBasic>

            
        </div>
    );
};

type TPendingMovement = {
    movement: TListingSignMovementEditForm;
    onUpdate: (movement: TListingSignMovementEditForm) => void;
    onDelete: (movement: TListingSignMovementEditForm) => void;
};

const PendingMovement = (props: TPendingMovement): JSX.Element => {

    const expectedDatePopout = useOpenClose();
    const typePopout = useOpenClose();
    const deleteMovementPopup = useOpenClose();

    const updateType = (type: TListingSignMovementType) => {
        props.onUpdate(
            editFormEditableValue(
                props.movement,
                "type",
                type
            )
        );
        typePopout.toggleOpen();
    };

    const updateExpectedDate = (date: string | null) => {
        props.onUpdate(
            editFormEditableValue(
                props.movement,
                "expected_install_date",
                date
            )
        );
        expectedDatePopout.toggleOpen();
    }

    const completeMovement = () => 
        props.onUpdate(
            editFormEditableValue(
                props.movement,
                "confirmed_install",
                DateTime.local().toISO()
            )
        )
    ;

    return (
        <div>
            <SpacingColumn spacing={CRMSpacing.TINY}>
                
                <CRMHorizontalSeperatorLine />

                <SpacingColumn spacing={CRMSpacing.MEDIUM}>

                    {/* CHANGE MOVEMENT TYPE & EXPECTED DATE */}
                    <SpacingColumn spacing={CRMSpacing.TINY}>
                        {/* CHANGE MOVEMENT TYPE */}
                        <div ref={typePopout.ref}>
                            <SpacingRow spacing={CRMSpacing.TINY}>
                                <CRMParagraph>
                                    Change to:
                                </CRMParagraph>

                                <CursorPointer onClick={typePopout.toggleOpen}>
                                    <CRMParagraph>
                                        <Uppercase>
                                            <WeightBold>
                                                {snakeCaseToCopyText(props.movement.edited.type)} 
                                            </WeightBold>
                                        </Uppercase> 
                                    </CRMParagraph>
                                </CursorPointer>

                                <Relative>
                                    <CRMIcon
                                        iconName={typePopout.isOpen ? "up-arrow" : "down-arrow"}
                                        size="small"
                                        colour="neutral-ink"
                                        onClick={typePopout.toggleOpen}
                                    />
                                    {typePopout.isOpen &&
                                        <Absolute top={CRMSpacing.LARGE} right="1px" zIndex={1}>
                                            <CRMPopout>
                                                <CRMCardOutsideSharpCorners>
                                                    <CRMPopoutOptions
                                                        options={movementTypes.map((type) => ({
                                                            label: snakeCaseToCopyText(type),
                                                            onClick: () => updateType(type)
                                                        }))}
                                                    />
                                                </CRMCardOutsideSharpCorners>
                                            </CRMPopout>
                                        </Absolute>
                                    }
                                </Relative>
                            </SpacingRow>
                        </div>
                        
                        {/* SET EXPECTED COMPLETION DATE */}
                        <CursorPointer>
                            
                            <div ref={expectedDatePopout.ref}>
                                <Relative>
                                    <SpacingRow spacing={CRMSpacing.TINY}>
                                        <CRMParagraph>
                                            Expected
                                        </CRMParagraph>

                                        <div onClick={expectedDatePopout.toggleOpen}>
                                            <CRMParagraph>
                                                <WeightBold>
                                                    {props.movement.edited.expected_install_date === null &&
                                                        "date not set"
                                                    }
                                                    {props.movement.edited.expected_install_date !== null && isDateInThePast(props.movement.edited.expected_install_date) &&
                                                        <TextColour colour={CRMColors.HIGHLIGHTS_INSTRUCTIONAL_BRICK_0}>
                                                            {getTimeElapsedCopyText(props.movement.edited.expected_install_date)} ago
                                                        </TextColour>
                                                    }
                                                    
                                                    {props.movement.edited.expected_install_date !== null && !isDateInThePast(props.movement.edited.expected_install_date) &&
                                                        `in ${getTimeUntilCopyText(props.movement.edited.expected_install_date)}`
                                                    } 
                                                </WeightBold> 
                                            </CRMParagraph>
                                        </div>
                                    
                                        <CRMIcon
                                            iconName={expectedDatePopout.isOpen ? "up-arrow" : "down-arrow"}
                                            size="small"
                                            colour="neutral-ink"
                                            onClick={expectedDatePopout.toggleOpen}
                                        />

                                        {expectedDatePopout.isOpen &&
                                            <Absolute top={CRMSpacing.LARGE} left="0px">
                                                <CRMPopout>
                                                    <CRMReactCalendarWrapper
                                                        onChange={updateExpectedDate} 
                                                    />
                                                </CRMPopout>
                                            </Absolute>
                                        }
                                    </SpacingRow>
                                </Relative>
                            </div>
                        </CursorPointer>
                    </SpacingColumn>
                    
                    {/* MARK MOVEMENT AS COMPLETE OR DELETE IT */}
                    <SpacingRow spacing={CRMSpacing.TINY}>
                        <CRMButtonFlatColourIcon
                            icon="done-outline"
                            size="small"
                            backgroundColor={CRMColors.PRIMARY_12}
                            onClick={completeMovement}
                        />
                        <CRMButtonFlatColourIcon
                            icon="delete"
                            size="small"
                            backgroundColor={CRMColors.NEUTRAL_PAPER}
                            onClick={deleteMovementPopup.toggleOpen}
                        />
                    </SpacingRow>
                </SpacingColumn>
            </SpacingColumn>

            {/* POPUP: CONFIRM MOVEMENT DELETE */}
            <CRMCardOutsidePopupBasic
                context="neutral"
                isOpen={deleteMovementPopup.isOpen}
                title="Delete movement?"
                onClose={deleteMovementPopup.toggleOpen}
                ctaText="Yes"
                onCTA={() => {
                    props.onDelete(props.movement);
                    deleteMovementPopup.toggleOpen();
                }}
            >
                <CRMParagraph>
                    Are you sure you want to delete this movement?
                </CRMParagraph>
            </CRMCardOutsidePopupBasic>
        </div>
    );
};

const CompletedMovement = (props: { movement: TListingSignMovementEditForm }): JSX.Element => (
    <TextColour colour={CRMColors.NEUTRAL_0}>
        <SpacingRow spacing="4px">
            <CRMParagraph>
                Changed to 
            </CRMParagraph>

            <CRMParagraph>
                <Uppercase>
                    <WeightSemiBold>
                        {snakeCaseToCopyText(props.movement.edited.type)} 
                    </WeightSemiBold>
                </Uppercase> 
            </CRMParagraph>

            <CRMParagraph>
                on <WeightBold>{getTwoDigitDayAndMonth(props.movement.edited.confirmed_install as string)}</WeightBold>
            </CRMParagraph>
        </SpacingRow>
    </TextColour>
)
