import React from "react";
import { CRMRadioComponent, TRadioValueType } from "../../CRMRadioComponent/CRMRadioComponent";

interface ICRMRadioListComponent<A extends TRadioValueType, B extends A> {
    options: Array<ICRMRadioOption<A>>;
    value: B | null; // I wanted to use option here but I couldn't get it to work - Tom 2021-04-28
    onChange: (value: A) => void;
    direction?: "column" | "row";
    disabled?: boolean;
    displayError?: boolean;
}

export interface ICRMRadioOption<A extends TRadioValueType> {
    value: A;
    text: string;
}

export class CRMRadioListComponent<A extends TRadioValueType, B extends A> extends React.Component<React.PropsWithChildren<ICRMRadioListComponent<A, B>>> {
    public render (): JSX.Element {
        return (
            <div className={`
                crm-radio-list
                crm-radio-list--direction-${this.props.direction || "row"}
            `}>
                {this.props.options.map((o) => (
                    <div
                        key={o.text}
                        className={`
                            crm-radio-list__radio-container
                            crm-radio-list__radio-container--direction-${this.props.direction || "row"}
                        `}>
                            <CRMRadioComponent
                                value={o.value}
                                checked={o.value === this.props.value}
                                onChange={() => this.props.onChange(o.value)}
                                text={o.text}
                                disabled={this.props.disabled}
                                displayError={this.props.displayError}
                            />
                    </div>
                ))}
            </div>
        );
    }
}
