import React from "react";
import { array as extArray } from "../../../../shared/src/utilByDomainGroupExport";
import { CRMFontSizes } from "../../models/CRMFontSizes";
import { FontSize } from "../BuildingBlocks/FontSize";
import { SpacingRow } from "../BuildingBlocks/SpacingRow";
import { CRMIcon, TIcon } from "../CRMIcon/CRMIcon";
import { pipe } from "fp-ts/lib/pipeable";

type TCRMTimelineProps = {
    steps: Array<{
        iconName: TIcon;
        altText: string;
        completed: boolean;
        tunnelText?: string;
    }>
};

export const CRMTimeline = (props: TCRMTimelineProps): JSX.Element => {

    const getIndexOfLastCompleteStep = (): number => pipe(
        props.steps,
        extArray.findFirstIndex((step) => !step.completed),
        (index) => index -1 
    );

    return (
        <SpacingRow>
            {props.steps.map((step, index) =>
                <SpacingRow key={index}>
                    {/* STEP ICON */}
                    <div
                        key={index}
                        className={`
                            crm-timeline__step 
                            crm-timeline__step--${step.completed ? "complete" : "incomplete"}
                        `}
                        title={step.altText}
                    >
                        <CRMIcon
                            iconName={step.iconName}
                            colour={step.completed ? "highlights-positive-green-2" : "neutral-4"}
                            size="small"
                        />
                    </div>

                    {/* INCOMPLETE STEP TUNNEL */}
                    {getIndexOfLastCompleteStep()=== index &&
                        <div className="crm-timeline__tunnel">
                            <FontSize size={CRMFontSizes.SMALL}>
                                {step.tunnelText}
                            </FontSize>
                        </div>
                    }

                    {/* STEP CONNECTION ARROW */}
                    {index !== getIndexOfLastCompleteStep() && index < props.steps.length-1 &&
                        <div className="crm-timeline__direction-arrow">
                            <CRMIcon
                                iconName="lined-arrow"
                                colour={step.completed ? "highlights-positive-green-2" : "neutral-4"}
                                size="medium"
                            />
                        </div>
                    }
                </SpacingRow>
            )}
        </SpacingRow>
    );
};
