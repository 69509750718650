import React, { useState } from "react";
import { TContainerStateProps } from "../../state/TContainerStateProps";
import { CRMMenuBar } from "../../components/Complex/CRMMenuBar/CRMMenuBar";
import { CRMColors } from "../../models/CRMColors";
import { CRMKPIs } from "../../components/Simple/CRMKPIs/CRMKPIs";

export const CRMHomesMenuBarContainer = (props: TContainerStateProps): JSX.Element => {
    const [kpisIsOpen, setKPISIsOpen] = useState<boolean>(false);
    
    const isSessionUserAdmin = (): boolean => props.state.global.user.user_role === "admin";

    return (
        <>
            <CRMMenuBar
                logoHref="/crm/search"
                colors={{
                    backgroundColor: CRMColors.PRIMARY_0,
                    logoBackgroundColor: CRMColors.PRIMARY_2,
                    logoIconColor: "primary-12",
                    hoverColor: CRMColors.PRIMARY_4,
                }}
                leftButtons={[
                    {
                        icon: "house-with-person",
                        label: "Make Enquiry",
                        href: "/crm/add-enquiry",
                    },
                    {
                        icon: "person-add",
                        label: "Add Party",
                        onClick: () => props.dispatch({type: "CRM_ADD_NEW_PARTY_BUTTON_CLICKED"})
                    },
                    {
                        icon: "house-and-building",
                        label: "View Listings",
                        href: "/crm/listings",
                    },
                    {
                        icon: "search-list",
                        label: "Search Listings",
                        href: "/crm/search",
                    },
                ]}
                rightButtons={[
                    {
                        icon: "speed",
                        label: "KPIs",
                        onClick: () => setKPISIsOpen(true)
                    },
                    {
                        icon: "settings",
                        label: "Settings",
                        href: "/crm/admin",
                        isDisabled: !isSessionUserAdmin()
                    },
                    {
                        icon: "logout",
                        label: "Logout",
                        href: `${env.REACT_APP_AUTH_URL}/web/logout`,
                        onClick: (e) => {
                            if (confirm("Confirm logout") !== true) {
                                e.preventDefault();
                            }
                        }
                    },
                ]}
            />

            {/* KPIS */}
            <CRMKPIs
                isOpen={kpisIsOpen}
                onClose={() => setKPISIsOpen(false)}
            />
        </>
    );
};
