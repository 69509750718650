import { array } from "fp-ts";
import { pipe } from "fp-ts/lib/function";
import React from "react";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { SimpleGrid } from "../../BuildingBlocks/SimpleGrid";
import { CRMInputCheckboxComponent } from "../../CRMInputCheckboxComponent/CRMInputCheckboxComponent";

export const CRMCheckboxList = <V extends string>(props: React.PropsWithChildren<{
    options: Array<{
        label: string,
        value: V,
        isChecked: boolean,
    }>,
    columnCount: number,
    onClick: (value: V) => void,
}>): JSX.Element =>
    <SimpleGrid
        templateColumns={pipe(
            array.makeBy(props.columnCount, () => "1fr"),
            (a) => a.join(" ")
        )}
        columnSpacing={CRMSpacing.TINY}
        rowSpacing={CRMSpacing.TINY}
    >
        {props.options.map((option, i) =>
            <CRMInputCheckboxComponent
                key={i}
                label={option.label}
                checked={option.isChecked}
                onClick={() => props.onClick(option.value)}
            />
        )}
    </SimpleGrid>;
