import React from "react";
import { Button } from "../../components/OldFront/Simple/ButtonComponent/Button";
import * as DisplayAddress from "../../../../domain/models/DisplayAddress";
import { pipe } from "fp-ts/lib/function";
import Modal from "../../components/OldFront/Complex/ModalComponent/Modal";
import Heading3 from "../../components/OldFront/Simple/Heading3Component/Heading3";
import BodyText from "../../components/OldFront/Simple/BodyTextComponent/BodyText";
import * as Listing6 from "../../../../domain/models/Listing6";
import * as PartialISODatetime from "../../../../domain/models/PartialISODatetime";
import * as JsonInnerError1 from "../../../../domain/models/JsonInnerError1";
import * as FirstPartyFetchResponse from "../../../../domain/models/FirstPartyFetchResponse";
import { IListingPageProps } from "../../pages/ListingPage/ListingPage";
import Icon from "../../components/OldFront/Simple/IconComponent/Icon";
import { array, option } from "fp-ts/lib";
import AnchorButton from "../../components/OldFront/Simple/AnchorButton/AnchorButton";
import * as constants from "../../../../domain/constants";
import InputText from "../../components/OldFront/Simple/Text/InputText";
import InputPhoneNumber from "../../components/OldFront/Simple/PhoneNumber/InputPhoneNumber";
import InputEmail from "../../components/OldFront/Simple/Email/InputEmail";
import { createChangeRouteAction } from "../../state/router/createChangeRouteAction";

export const render = (props: IListingPageProps, listingData: Listing6.T): React.ReactNode =>
    <Modal
        fullScreenOnMobile={true}
        onCloseClicked={() => props.dispatch(createChangeRouteAction("VIEW_LISTING", { listingId: listingData.id }, {}))}
    >
        <div className="listing__book-viewing-modal-heading-container">
            <Heading3 text="Book a viewing" />
        </div>
        <BodyText type="normal">
            <span className="listing__book-viewing-modal-address">
                {`At
                ${pipe(
                    DisplayAddress.fromListing6(listingData),
                    DisplayAddress.toShortString,
                )}`}
            </span>
        </BodyText>
        <div className="listing__viewing-slot-choices-text-container">
            <BodyText type="normal">
                Great, you've selected to view the property {props.state.activeData.bookViewing.viewingType === "" ? "" : props.state.activeData.bookViewing.viewingType === "online" ? "Online" : "In person"} at one of the following times:
            </BodyText>
        </div>
        <div className="listing__viewing-slot-choices-container">
            <div className="listing__viewing-slot-choices-and-edit-choices-container">
                <div className="listing__viewing-current-slot-choices-container">
                    <div className="listing__viewing-slot-choice-container">
                        <BodyText type="normal">
                            {PartialISODatetime.getDateString(props.state.activeData.bookViewing.requestedTimes[0])}
                        </BodyText>
                        <div className="listing__viewing-slot-choice-time">
                            <div className="listing__viewing-modal-icon-container">
                                <Icon
                                    iconName="square_selected"
                                    height={1.8}
                                    width={1.8}
                                    type="sloth"
                                />
                            </div>
                            <BodyText type="normal">
                                {PartialISODatetime.getTimeString(props.state.activeData.bookViewing.requestedTimes[0])}
                            </BodyText>
                        </div>
                    </div>
                    <div className="listing__viewing-slot-choice-container">
                        <BodyText type="normal">
                            {PartialISODatetime.getDateString(props.state.activeData.bookViewing.requestedTimes[1])}
                        </BodyText>
                        <div className="listing__viewing-slot-choice-time">
                            <div className="listing__viewing-modal-icon-container">
                                <Icon
                                    iconName="square_selected"
                                    height={1.8}
                                    width={1.8}
                                    type="sloth"
                                />
                            </div>
                            <BodyText type="normal">
                                {PartialISODatetime.getTimeString(props.state.activeData.bookViewing.requestedTimes[1])}
                            </BodyText>
                        </div>
                    </div>
                    <div className="listing__viewing-slot-choice-container">
                        <BodyText type="normal">
                            {PartialISODatetime.getDateString(props.state.activeData.bookViewing.requestedTimes[2])}
                        </BodyText>
                        <div className="listing__viewing-slot-choice-time">
                            <div className="listing__viewing-modal-icon-container">
                                <Icon
                                    iconName="square_selected"
                                    height={1.8}
                                    width={1.8}
                                    type="sloth"
                                />
                            </div>
                            <BodyText type="normal">
                                {PartialISODatetime.getTimeString(props.state.activeData.bookViewing.requestedTimes[2])}
                            </BodyText>
                        </div>
                    </div>
                </div>
                <div className="listing__viewing-edit-slot-choices-container">
                    <a className="listing__viewing-edit-slot-choices-link"
                        href="#"
                        onClick={() => props.dispatch(createChangeRouteAction("VIEW_LISTING_BOOK_VIEWING", { listingId: listingData.id }, {}))}
                    >
                        <div className="listing__viewing-edit-icon-text-container">
                            <div className="listing__viewing-modal-icon-container">
                                <Icon
                                    iconName="edit"
                                    height={1.8}
                                    width={1.8}
                                    type="sloth"
                                />
                            </div>
                            <span>Edit viewing dates/times</span>
                        </div>
                    </a>
                </div>
            </div>
            { pipe(
                props.state.activeData.bookViewing.bookViewingResponse.validationInnerErrors,
                array.findFirst((innerError) =>
                    innerError.error_code === "UtcDatetimeValidation"
                    || innerError.error_code === "FutureDatetimeValidation"
                    || innerError.error_code === "ArrayNotUniqueValidation"
                ),
                option.fold<JsonInnerError1.T, JSX.Element | string>(
                    () => "",
                    () => (
                        <div className="listing__book-viewing-error-message-edit-slots-container">
                            <BodyText type="error">
                                <div className="listing__book-viewing-error-messages-text">
                                    Something's not right with the date and time slots you chose. Please click on the edit link to go back 
                                </div>
                                <AnchorButton
                                    label="Book a viewing"
                                    type="puffin"
                                    fullWidth={true}
                                    url={constants.sailHomesBrandConstants("web").contact.phoneURL}
                                />
                            </BodyText>
                        </div>
                    ),
                ),
            )}
        </div>
        <div className="listing__book-viewing-form-personal-details">
            <div className="listing__book-viewing-form-input-container">
                <label className="listing__book-viewing-input-label"
                    htmlFor="firstName"
                >
                    What's your name?
                    {FirstPartyFetchResponse.hasValidationErrorsForTargetKeys(["firstName"], props.state.activeData.bookViewing.bookViewingResponse) ?
                        <span className="listing__book-viewing-error-message-label">
                            Please check you've entered a first and last name correctly 
                        </span>
                        : ""
                    }
                </label>
                <InputText
                    name="firstName"
                    placeholder="First name"
                    value={props.state.activeData.bookViewing.firstName}
                    displayError={FirstPartyFetchResponse.hasValidationErrorsForTargetKeys(["firstName"], props.state.activeData.bookViewing.bookViewingResponse)}
                    onChange={(payload) => props.dispatch({
                        type: "BOOK_VIEWING_FIRST_NAME_INPUT_CHANGED",
                        payload,
                    })}
                    onPressEnterKey={() => undefined}
                />
            </div>
            <div className="listing__book-viewing-form-input-container">
                <label className="listing__book-viewing-input-label"
                    htmlFor="lastName"
                >
                    {FirstPartyFetchResponse.hasValidationErrorsForTargetKeys(["lastName"], props.state.activeData.bookViewing.bookViewingResponse) ?
                        <span className="listing__book-viewing-error-message-label">
                            What's your name?
                        </span>
                        : ""
                    }
                </label>
                <InputText
                    name="lastName"
                    placeholder="Last name"
                    value={props.state.activeData.bookViewing.lastName}
                    displayError={FirstPartyFetchResponse.hasValidationErrorsForTargetKeys(["lastName"], props.state.activeData.bookViewing.bookViewingResponse)}
                    onChange={(payload) => props.dispatch({
                        type: "BOOK_VIEWING_LAST_NAME_NAME_INPUT_CHANGED",
                        payload,
                    })}
                    onPressEnterKey={() => undefined}
                />
            </div>
            <div className="listing__book-viewing-form-input-container">
                <label className="listing__book-viewing-input-label"
                    htmlFor="contactEmail"
                >
                    Email address
                    {FirstPartyFetchResponse.hasValidationErrorsForTargetKeys(["contactEmail"], props.state.activeData.bookViewing.bookViewingResponse) ?
                        <span className="listing__book-viewing-error-message-label">
                            Look's like there's an issue with the email address you've entered. Please check it's a valid email address 
                        </span>
                        : ""
                    }
                </label>
                <InputEmail
                    name="contactEmail"
                    placeholder="lindsayandsam@ouremail.com"
                    value={props.state.activeData.bookViewing.contactEmail}
                    displayError={FirstPartyFetchResponse.hasValidationErrorsForTargetKeys(["contactEmail"], props.state.activeData.bookViewing.bookViewingResponse)}
                    onChange={(payload) => props.dispatch({
                        type: "BOOK_VIEWING_CONTACT_EMAIL_INPUT_CHANGED",
                        payload,
                    })}
                    onPressEnterKey={() => undefined}
                />
            </div>
            <div className="listing__book-viewing-form-input-container">
                <label className="listing__book-viewing-input-label"
                    htmlFor="contactPhoneNumber"
                >
                    Contact number
                    {FirstPartyFetchResponse.hasValidationErrorsForTargetKeys(["contactPhoneNumber"], props.state.activeData.bookViewing.bookViewingResponse) ?
                        <span className="listing__book-viewing-error-message-label">
                            Please check you've entered your phone number correctly 
                        </span>
                        : ""}
                </label>
                <InputPhoneNumber
                    name="contactPhoneNumber"
                    placeholder="07000111222"
                    value={props.state.activeData.bookViewing.contactPhoneNumber}
                    displayError={FirstPartyFetchResponse.hasValidationErrorsForTargetKeys(["contactPhoneNumber"], props.state.activeData.bookViewing.bookViewingResponse)}
                    onChange={(payload) => props.dispatch({
                        type: "BOOK_VIEWING_CONTACT_PHONE_NUMBER_INPUT_CHANGED",
                        payload,
                    })}
                    onPressEnterKey={() => undefined}
                />
            </div>
        </div>
        <div className="listing__book-viewing-submit-form-container listing__book-viewing-submit-form-container--final-page">
            {props.state.activeData.bookViewing.bookViewingResponse.tag === FirstPartyFetchResponse.constants.UNEXPECTED &&
                <div className="listing__book-viewing-error-messages-container listing__book-viewing-error-messages-container--final-page">
                    <BodyText type="error">
                        <div className="listing__book-viewing-error-messages-text">
                            There has been an unexpected error
                        </div>
                    </BodyText>
                </div>
            }
            <div className="listing__book-viewing-submit-button-container listing__book-viewing-submit-button-container--final-page">
                <Button
                    label="Confirm details and request these viewing slots"
                    type="rooster"
                    fullWidth={true}
                    onClick={() => props.dispatch({type: "BOOK_VIEWING_CONFIRM_DETAILS_AND_VIEWING_SLOT_CHOICES_BUTTON_CLICKED"})}
                />
            </div>
        </div>
    </Modal>;
