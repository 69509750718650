import React from "react";
import ListingPage from "../pages/ListingPage/ListingPage";
import CRMSearchPage from "../pages/CRMSearchPage";
import CRMListingPage from "../pages/CRMListingPage";
import CRMListingPerformancePage from "../pages/CRMListingPerformancePage/CRMListingPerformancePage";
import CRMPartyPage from "../pages/CRMPartyPage";
import CRMListingsPage from "../pages/CRMListingsPage/CRMListingsPage";
import CRMEnquiryPage from "../pages/CRMEnquiryPage/CRMEnquiryPage";
import CRMAddEnquiryPage from "../pages/CRMAddEnquiryPage/CRMAddEnquiryPage";
import BuyerEnquiryPage from "../pages/BuyerEnquiryPage/BuyerEnquiryPage";
import ResendMagicLinkPage from "../pages/ResendMagicLinkPage/ResendMagicLinkPage";
import ResentMagicLinkPage from "../pages/ResentMagicLinkPage/ResentMagicLinkPage";
import NotFoundPage from "../pages/NotFoundPage/NotFoundPage";
import CRMLegalCasePage from "../pages/CRMLegalCasePage/CRMLegalCasePage";
import * as TContainerStateProps from "../state/TContainerStateProps";
import CRMTitleCheckCases from "../pages/CRMTitleCheckCases/CRMTitleCheckCases";
import { CRMAdminPage } from "../pages/CRMAdminPage";
import FrontEmailVerificationConfirmationPage from "../pages/FrontEmailVerificationConfirmationPage";
import Front2FAPage from "../pages/Front2FAPage";
import FrontOnboardingPage from "../pages/FrontOnboardingPage";
import { CRMLegalReportsPage } from "../pages/CRMLegalReportsPage/CRMLegalReportsPage";
import CRMLegalTriageInboxPage from "../pages/CRMLegalTriageInboxPage/CRMLegalTriageInboxPage";
import CRMLegalPrivateInboxPage from "../pages/CRMLegalInboxPage/CRMLegalPrivateInboxPage";
import CRMLegalIntroducersPage from "../pages/CRMLegalIntroducersPage/CRMLegalIntroducersPage";
import CRMLegalClientCompanyPartnersPage from "../pages/CRMLegalClientCompanyPartnersPage/CRMLegalClientCompanyPartnersPage";
import CRMProbateHelplineCallbackPage from "../pages/CRMProbateHelplineCallbackPage/CRMProbateHelplineCallbackPage";
import CRMLegalQuoteRatesPage from "../pages/CRMLegalQuoteRatesPage/CRMLegalQuoteRatesPage";
import CRMMarketingCampaignsPage from "../pages/CRMMarketingCampaignsPage/CRMMarketingCampaignsPage";
import CRMBlocksTestPage from "../pages/CRMBlocksTestPage/CRMBlocksTestPage";
import CRMLegalCaseAutoAssignmentPage from "../pages/CRMLegalCaseAutoAssignmentPage/CRMLegalCaseAutoAssignmentPage";
import { paths } from "../state/router/routerPaths";
import CRMJobsContainer from "./CRM/CRMJobsContainer";
import { FrontProbatePropertyReportContainer } from "./FrontProbatePropertyReportContainer/FrontProbatePropertyReportContainer";
import CRMLegalCasesPage from "../pages/CRMLegalCasesPage/CRMLegalCasesPage";

export const AppContainer = (props: TContainerStateProps.TContainerStateProps): JSX.Element => {

    const isACRMPage = paths[props.state.routes.active].pattern[0] === "crm";

    return (() => (
        <div>
            { (() => {
                switch (props.state.routes.active) {
                    case "VIEW_LISTING":
                    case "VIEW_LISTING_BOOK_VIEWING":
                        return <ListingPage
                            state={props.state}
                            dispatch={props.dispatch}
                            bookViewingModalOpen={props.state.routes.active === "VIEW_LISTING_BOOK_VIEWING"}
                        />;
                    case "VIEW_LISTING_BOOK_VIEWING_ADDITIONAL_QUESTIONS":
                        return <ListingPage
                            state={props.state}
                            dispatch={props.dispatch}
                            bookViewingModalOpen={props.state.routes.active === "VIEW_LISTING_BOOK_VIEWING_ADDITIONAL_QUESTIONS"}
                        />;
                    case "VIEW_LISTING_BOOK_VIEWING_SUCCESS":
                        return <ListingPage
                            state={props.state}
                            dispatch={props.dispatch}
                            bookViewingModalOpen={props.state.routes.active === "VIEW_LISTING_BOOK_VIEWING_SUCCESS"}
                        />;
                    case "VIEW_CRM_SEARCH":
                        return <CRMSearchPage
                            state={props.state}
                            dispatch={props.dispatch}
                        />;
                    case "VIEW_CRM_LISTING":
                        return <CRMListingPage
                            state={props.state}
                            dispatch={props.dispatch}
                        />;
                    case "VIEW_CRM_LISTING_PERFORMANCE":
                        return <CRMListingPerformancePage
                            state={props.state}
                            dispatch={props.dispatch}
                        />;
                    case "VIEW_CRM_LISTINGS":
                        return <CRMListingsPage
                            state={props.state}
                            dispatch={props.dispatch}
                        />;
                    case "VIEW_CRM_PARTY":
                        return <CRMPartyPage
                            state={props.state}
                            dispatch={props.dispatch}
                        />;
                    case "VIEW_CRM_ENQUIRY":
                        return <CRMEnquiryPage
                            state={props.state}
                            dispatch={props.dispatch}
                        />;
                    case "VIEW_CRM_ADD_ENQUIRY":
                        return <CRMAddEnquiryPage
                            state={props.state}
                            dispatch={props.dispatch}
                        />;
                    case "VIEW_CRM_LEGAL_CASE":
                        return <CRMLegalCasePage
                            state={props.state}
                            dispatch={props.dispatch}
                        />;
                    case "VIEW_CRM_LEGAL_TITLE_CHECKS":
                        return <CRMTitleCheckCases
                            state={props.state}
                            dispatch={props.dispatch}
                        />;
                    case "VIEW_CRM_LEGAL_REPORTS":
                        return <CRMLegalReportsPage
                            state={props.state}
                            dispatch={props.dispatch}
                        />;
                    case "VIEW_NOT_FOUND":
                        return <NotFoundPage
                            state={props.state}
                            dispatch={props.dispatch}
                        />;
                    case "VIEW_BUYER_ENQUIRY":
                        return <BuyerEnquiryPage
                            state={props.state}
                            dispatch={props.dispatch}
                        />;
                    case "VIEW_CLIENT_CASE":
                        return <FrontOnboardingPage
                            state={props.state}
                            dispatch={props.dispatch}
                        />;
                    case "VIEW_AUTH_2FA":
                        return <Front2FAPage
                            state={props.state}
                            dispatch={props.dispatch}
                        />;
                    case "VIEW_BUYER_EMAIL_VERIFICATION_CONFIRMATION":
                        return <FrontEmailVerificationConfirmationPage />;
                    case "VIEW_MAGIC_LINK_RESEND":
                        return <ResendMagicLinkPage
                            state={props.state}
                            dispatch={props.dispatch}
                        />;
                    case "VIEW_MAGIC_LINK_RESENT":
                        return <ResentMagicLinkPage
                            state={props.state}
                            dispatch={props.dispatch}
                        />;
                    case "VIEW_ADMIN":
                        return <CRMAdminPage
                            state={props.state}
                            dispatch={props.dispatch}
                        />;
                    case "VIEW_CRM_TRIAGE":
                        return <CRMLegalTriageInboxPage
                            state={props.state}
                            dispatch={props.dispatch}
                        />;
                    case "VIEW_CRM_LEGAL_INBOX":
                        return <CRMLegalPrivateInboxPage
                            state={props.state}
                            dispatch={props.dispatch}
                        />;
                    case "VIEW_CRM_LEGAL_INTRODUCERS":
                        return <CRMLegalIntroducersPage
                            state={props.state}
                            dispatch={props.dispatch}
                        />;
                    case "VIEW_CRM_LEGAL_CASE_AUTO_ASSIGNMENT":
                        return <CRMLegalCaseAutoAssignmentPage
                            state={props.state}
                            dispatch={props.dispatch}
                        />
                    case "VIEW_CRM_LEGAL_CLIENT_COMPANY_PARTNERS":
                        return <CRMLegalClientCompanyPartnersPage
                            state={props.state}
                            dispatch={props.dispatch}
                        />;
                    case "VIEW_CRM_PROBATE_HELPLINE_CALLBACKS":
                        return <CRMProbateHelplineCallbackPage
                            state={props.state}
                            dispatch={props.dispatch}
                        />;
                    case "VIEW_CRM_LEGAL_QUOTE_RATES":
                        return <CRMLegalQuoteRatesPage
                            state={props.state}
                            dispatch={props.dispatch}
                        />;
                    case "VIEW_CRM_LEGAL_MARKETING_CAMPAIGNS":
                        return <CRMMarketingCampaignsPage
                            state={props.state}
                            dispatch={props.dispatch}
                        />
                    case "VIEW_CRM_BLOCKS_TEST":
                        return <CRMBlocksTestPage
                            state={props.state}
                            dispatch={props.dispatch}
                            isFirstLoad={false}
                            onChangeBlock={() => undefined}
                        />
                    case "VIEW_PROBATE_PROPERTY_REPORT_PAGE":
                        return <FrontProbatePropertyReportContainer
                            state={props.state}
                            dispatch={props.dispatch}
                        />
                    case "VIEW_CRM_LEGAL_NEW_CASES":
                        return <CRMLegalCasesPage
                            state={props.state}
                            dispatch={props.dispatch}
                        />;
                    default:
                        return <div></div>;
                }
            })() }

            {isACRMPage && 
                <CRMJobsContainer {...props} />
            }
        </div>
    ))();
};

