import React from "react";
import CRMInputLabelAndErrorWrapComponent from "../CRMInputLabelAndErrorWrapComponent/CRMInputLabelAndErrorWrapComponent";
import { CRMRadioListComponent } from "../CRMInputs/CRMRadioListComponent/CRMRadioListComponent";
import { TCRMFormInputWithoutCodec } from "./CRMFormInputTypes";
import { CRMSpacing } from "../../models/CRMSpacing";
import { SpacingRow } from "../BuildingBlocks/SpacingRow";
import { CRMButtonIcon } from "../CRMButtonIcon/CRMButtonIcon";

export const CRMYesNoBooleanWithClearRadioFormInput: TCRMFormInputWithoutCodec<boolean | null> = (props) =>
    <CRMInputLabelAndErrorWrapComponent label={props.label}>
        <SpacingRow spacing={CRMSpacing.TINY}>
            <CRMRadioListComponent<boolean, boolean>
                value={props.model}
                direction="row"
                options={[
                    {
                        value: true,
                        text: "Yes",
                    },
                    {
                        value: false,
                        text: "No",
                    },
                ]}
                onChange={(val) => props.onChange(val)}
                disabled={props.disabled}
                displayError={props.displayError}
            />
            <CRMButtonIcon
                variant="tertiary"
                icon="backspace"
                disabledColour="neutral-4"
                disabled={props.model === null || props.disabled}
                onClick={() => props.onChange(null)}
            />
        </SpacingRow>
    </CRMInputLabelAndErrorWrapComponent>;