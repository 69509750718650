import React from "react";
import { TContainerStateProps } from "./../../state/TContainerStateProps";
import { CRMTitleSection } from "../../components/CRMTitleSection/CRMTitleSection";
import { CRMSpacer } from "../../components/CRMSpacer/CRMSpacer";
import { CRMAutosaveIndicatorWrap } from "../../components/CRM/CRMAutosaveIndicatorWrap/CRMAutosaveIndicatorWrap";
import { pipe } from "fp-ts/lib/function";
import { array } from "fp-ts";
import { FormStatus_highestPriority } from "../../../../shared/src/codecs/codec";
import { CRMCodecEditForm } from "../../components/CRMCodecEditForm/CRMCodecEditForm";
import { CasesDraftContractDetails } from "../../../../domain/codecs/CasesContractBlock";

const CRMLegalCaseContractMilestonesContainer = (props: TContainerStateProps): JSX.Element => {
    return <CRMAutosaveIndicatorWrap
            status={pipe(
                [
                    props.state.forms.contracts_block.children.details.status,
                    ...pipe(
                        props.state.forms.contracts_block.children.properties,
                        array.map((form) => form.status),
                    ),
                ],
                FormStatus_highestPriority,
            )}
        >
            <CRMTitleSection>
                Milestones
            </CRMTitleSection>
            <CRMSpacer size="large" />
            
            <CRMCodecEditForm
                codec={CasesDraftContractDetails}
                model={props.state.forms.contracts_block.children.details.edited}
                validationErrors={props.state.forms.contracts_block.children.details.validationErrors}
                onChange={(edited) => props.dispatch({
                    type: "LEGAL_CASE_CONTRACTS_BLOCK_CHANGE",
                    payload: {
                        ...props.state.forms.contracts_block.children.details,
                        edited: {
                            ...props.state.forms.contracts_block.children.details.edited,
                            ...edited,
                        }
                    }
                })}
                columns={2}
                showDeferDateLabels={true}
            />
    </CRMAutosaveIndicatorWrap>;
}

export default CRMLegalCaseContractMilestonesContainer;