import React from "react";
import { CRMIcon, TIcon } from "../../CRMIcon/CRMIcon";
import { SpacingRow } from "../../BuildingBlocks/SpacingRow";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { DateTime } from "luxon";
import { pipe } from "fp-ts/function";
import { ifTrueInject } from "../../../../../shared/src/utilsByDomain/array";
import { TAction } from "../../CRMTableRow/CRMTableRow";
import { isAFunctionCurried, isAPlainFunction, onCallIfExists } from "../../../functions/functions";
import { TSimpleLegalEmailWithPersonsForTriageForm } from "../../../../../domain/codecs/form/TriageForm";
import { CRMColors } from "../../../models/CRMColors";
import { Background } from "../../BuildingBlocks/Background";
import { Text } from "../../BuildingBlocks/Text";
import { CRMFontSizes } from "../../../models/CRMFontSizes";
import { Padding } from "../../BuildingBlocks/Padding";
import { SpacingColumn } from "../../BuildingBlocks/SpacingColumn";
import { useHover } from "../../../hooks/useHover";
import { CRMInputCheckboxComponent } from "../../CRMInputCheckboxComponent/CRMInputCheckboxComponent";
import { CRMTimelineControlsWrapper } from "../../CRMTimelineControlsWrapper/CRMTimelineControlsWrapper";
import { MaxWidth } from "../../BuildingBlocks/MaxWidth";
import { CRMEmailCardToFrom } from "../CRMEmailCardToFrom/CRMEmailCardToFrom";
import { CRMFuzzySortSearchHighlight } from "../../Simple/CRMFuzzySortSearchHighlight/CRMFuzzySortSearchHighlight";


export type TEmailCardProps = {
    email: TSimpleLegalEmailWithPersonsForTriageForm;
    isSelected?: boolean;
    searchTerm: string;
    onClick: () => void;
    onSelect?: () => void;
    onAssign?: () => void;
    onAssignRelated?: () => void;
    onArchive?: () => void;
    onDisassociate?: () => void;
    onReply?: () => void;
    onForward?: () => void;
};

export const CRMEmailCard = (props: React.PropsWithChildren<TEmailCardProps>): JSX.Element => {

    const [ref, isHovered] = useHover<HTMLDivElement>({});

    const getPrettySentAtDate = () => DateTime
        .fromISO(props.email.original.sent_at)
        .setZone("Europe/London")
        .toFormat("d LLL")
    ;

    const getActions = () => {
        return pipe(
            [] as Array<TAction>,
            ifTrueInject(
                isAFunctionCurried(props.onAssign),
                {
                    iconName: "file-box" as TIcon,
                    title: "Assign",
                    onClick: onCallIfExists(props.onAssign),
                }
            ),
            ifTrueInject(
                isAFunctionCurried(props.onAssignRelated),
                {
                    iconName: "done" as TIcon,
                    title: "Assign",
                    onClick: onCallIfExists(props.onAssignRelated),
                }
            ),
            ifTrueInject(
                isAFunctionCurried(props.onArchive),
                {
                    iconName: "archive" as TIcon,
                    title: "Archive",
                    onClick: onCallIfExists(props.onArchive),
                }
            ),
            ifTrueInject(
                isAFunctionCurried(props.onDisassociate),
                {
                    iconName: "unfile" as TIcon,
                    title: "Disassociate",
                    onClick: onCallIfExists(props.onDisassociate),
                }
            ),
            ifTrueInject(
                isAFunctionCurried(props.onReply),
                {
                    iconName: "reply" as TIcon,
                    title: "Reply",
                    onClick: onCallIfExists(props.onReply),
                }
            ),
            ifTrueInject(
                isAFunctionCurried(props.onForward),
                {
                    iconName: "forward-arrow" as TIcon,
                    title: "Forward",
                    onClick: onCallIfExists(props.onForward),
                }
            ),
        )
    }

    return (
        <div className="crm-email-card" ref={ref}>
            <CRMTimelineControlsWrapper
                top="10px"
                right="0px"
                backgroundColour={CRMColors.NEUTRAL_PAPER}
            >
                {isHovered && getActions().map((action, index) => (
                    <div key={index}>
                        <CRMIcon
                            iconName={action.iconName}
                            colour="neutral-ink"
                            size="medium"
                            title={action.title}
                            cursor="pointer"
                            onClick={action.onClick}
                        />
                    </div>
                ))}

                {isAPlainFunction(props.onSelect) && 
                    <CRMInputCheckboxComponent
                        checked={props.isSelected || false}
                        cursor="pointer"
                        size="medium"
                        onClick={props.onSelect}
                    />
                }
            </CRMTimelineControlsWrapper>

            <Background
                color={CRMColors.NEUTRAL_PAPER}
                padding={`${CRMSpacing.MEDIUM} 0px`}
                onClick={props.onClick}
            >
                <SpacingColumn spacing={CRMSpacing.MEDIUM}>
                    {/* TITLE */}
                    <MaxWidth width="80%">
                        <Padding type="horizontal" spacing={CRMSpacing.MEDIUM}>
                            <Text 
                                size={CRMFontSizes.SMALL} 
                                lineHeight={1.2}
                                weight={700}
                            >
                                <CRMFuzzySortSearchHighlight
                                    text={props.email.original.subject}
                                    searchText={props.searchTerm}
                                />
                            </Text>
                        </Padding>
                    </MaxWidth>
                    
                    <SpacingColumn spacing={CRMSpacing.SMALL}>
                        {/* FROM - TO STRIP */}
                        <CRMEmailCardToFrom 
                            email={props.email}
                            searchTerm={props.searchTerm}
                        />
                        
                        {/* SNIPPET */}
                        <Padding type="horizontal" spacing={CRMSpacing.MEDIUM}>
                            <Text 
                                size={CRMFontSizes.SMALL} 
                                lineHeight={1.4}
                                weight={400}
                            >
                                <CRMFuzzySortSearchHighlight
                                    text={props.email.original.snippet}
                                    searchText={props.searchTerm}
                                />
                            </Text>
                        </Padding>
                        
                        {/* SENT AT */}
                        <SpacingRow justifyContent="end">
                            <Padding type="horizontal" spacing={CRMSpacing.MEDIUM}>
                                <Text 
                                    size={CRMFontSizes.SMALL} 
                                    lineHeight={1.4}
                                    weight={400}
                                    align="right"
                                >
                                    {getPrettySentAtDate()}
                                </Text>
                            </Padding>
                        </SpacingRow>
                    </SpacingColumn>

                </SpacingColumn>
            </Background>
        </div>
    );
};
