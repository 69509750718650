import React from "react";
import CRMInputLabelAndErrorWrapComponent from "../CRMInputLabelAndErrorWrapComponent/CRMInputLabelAndErrorWrapComponent";
import CRMInputGeneralComponent from "../CRMInputs/CRMInputGeneralComponent/CRMInputGeneralComponent";
import { TCRMFormInputWithoutCodec } from "./CRMFormInputTypes";

export const CRMTextFormInput: TCRMFormInputWithoutCodec<string> = (props) => 
<CRMInputLabelAndErrorWrapComponent label={props.label}>
    <CRMInputGeneralComponent
        inputType={"text"}
        value={props.model}
        onChange={(value) => props.onChange(value)}
        displayError={props.displayError}
        disabled={props.disabled}
    />
</CRMInputLabelAndErrorWrapComponent>