import { tap, switchMap, map, filter, debounceTime } from "rxjs/operators";
import { formOperation } from "../../wrappers/formOperation";
import { TActionsDefinitionsList } from "./TAction";
import { action, routeAction } from "./actionFunctions";
import { forkJoin, from, Observable, of } from "rxjs";
import { TStateCodec } from "../../../../domain/codecs/state";
import { TCodecLens } from "../../../../shared/src/codecs/codecLens";
import { TCodecSetState, TGetCodecState } from "../applyActions";
import { ClientCompanyPartnershipPageForm, TClientCompanyPartnershipContractForm, TClientCompanyPartnershipContractUploadForm, TClientCompanyPartnershipCreateForm, TClientCompanyPartnershipDeleteForm, TClientCompanyPartnershipForm, TClientCompanyPartnershipUserForm, TClientCompanyPartnershipUserPhoneNumberCreateForm, TClientCompanyPartnershipUserPhoneNumberDeleteForm, TClientCompanyPartnershipUserPhoneNumberForm, TClientCompanyPartnershipUserPhoneNumberMakePrimaryForm } from "../../../../domain/codecs/form/ClientCompanyPartnershipForm";
import { pipe } from "fp-ts/lib/function";
import { array, option } from "fp-ts";

export const clientCompanyPartnersReload$ = (
    lens: TCodecLens<TStateCodec, TStateCodec>,
    set: TCodecSetState,
    get: TGetCodecState,
) =>
    from(
        formOperation("GetClientCompanyPartnerships", lens.client_company_partners_page.get()(get()))
    )
        .pipe(
            tap((response) => set(lens.client_company_partners_page.set(response))),
        );

export const actions: TActionsDefinitionsList = [
    routeAction("VIEW_CRM_LEGAL_CLIENT_COMPANY_PARTNERS", (obs$, lens, set, get) => {
        obs$.pipe(
            tap(() => set(lens.client_company_partners_page.set(ClientCompanyPartnershipPageForm.newDefault()))),
            switchMap(() => clientCompanyPartnersReload$(lens, set, get)),
        ).subscribe();
    }),
    action("CLIENT_COMPANY_PARTNER_CHANGE", (obs$: Observable<TClientCompanyPartnershipForm>, lens, set, get) => {
        obs$.pipe(
            tap((form) => set(lens.client_company_partners_page.children.client_company_partnership_forms.where((f) => f.edited.id === form.edited.id).set({
                ...form,
                status: "requiresSubmission",
            })))
        ).subscribe();
    }),
    action("CLIENT_COMPANY_PARTNER_SAVE", (obs$: Observable<TClientCompanyPartnershipForm>, lens, set, get) => {
        obs$.pipe(
            map((form): TClientCompanyPartnershipForm => ({
                ...form,
                status: "submitting",
            })),
            tap((form) => set(lens.client_company_partners_page.children.client_company_partnership_forms.where((f) => f.edited.id === form.edited.id).set(form))),
            switchMap((form) => formOperation("UpdateClientCompanyPartnership", form)),
            tap((response) => set(lens.client_company_partners_page.children.client_company_partnership_forms.where((f) => f.edited.id === response.edited.id).set(response))),
            filter(({status}) => status === "success"),
            debounceTime(1000),
            switchMap(() => clientCompanyPartnersReload$(lens, set, get)),
        ).subscribe();
    }),
    action("CLIENT_COMPANY_PARTNER_CREATE", (obs$: Observable<TClientCompanyPartnershipCreateForm>, lens, set, get) => {
        obs$.pipe(
            map((form): TClientCompanyPartnershipCreateForm => ({
                ...form,
                status: "submitting",
            })),
            tap((form) => set(lens.client_company_partners_page.children.create_form.set(form))),
            switchMap((form) => formOperation("CreateClientCompanyPartnership", form)),
            tap((response) => set(lens.client_company_partners_page.children.create_form.set(response))),
            filter(({status}) => status === "success"),
            switchMap(() => clientCompanyPartnersReload$(lens, set, get)),
            debounceTime(1000),
            tap(() => set(lens.client_company_partners_page.children.create_form.status.set("untouched"))),
        ).subscribe();
    }),
    action("CLIENT_COMPANY_PARTNER_DELETE", (obs$: Observable<TClientCompanyPartnershipDeleteForm>, lens, set, get) => {
        obs$.pipe(
            map((form): TClientCompanyPartnershipDeleteForm => ({
                ...form,
                status: "submitting",
            })),
            tap((form) => set(lens.client_company_partners_page.children.client_company_partnership_forms.where((f) => f.edited.id === form.edited.id).children.delete_form.set(form))),
            switchMap((form) => formOperation("DeleteClientCompanyPartnership", form)),
            tap((response) => set(lens.client_company_partners_page.children.client_company_partnership_forms.where((f) => f.edited.id === response.edited.id).children.delete_form.set(response))),
            filter(({status}) => status === "success"),
            switchMap(() => clientCompanyPartnersReload$(lens, set, get)),
        ).subscribe();
    }),
    action("CLIENT_COMPANY_PARTNER_ADDRESS_AUTOCOMPLETE", (obs$: Observable<TClientCompanyPartnershipForm>, lens, set, get) => {
        obs$.pipe(
            map((form): TClientCompanyPartnershipForm => ({
                ...form,
                status: "submitting",
            })),
            tap((form) => set(lens.client_company_partners_page.children.client_company_partnership_forms.where((f) => f.edited.id === form.edited.id).set(form))),
            switchMap((form) => formOperation("AutocompleteClientCompanyPartnershipAddress", form)),
            tap((response) => set(lens.client_company_partners_page.children.client_company_partnership_forms.where((f) => f.edited.id === response.edited.id).set(response))),
        ).subscribe();
    }),
    action("CLIENT_COMPANY_PARTNER_CONTRACT_UPLOAD", (obs$: Observable<TClientCompanyPartnershipContractUploadForm>, lens, set, get) => {
        obs$.pipe(
            map((form): TClientCompanyPartnershipContractUploadForm => ({
                ...form,
                status: "submitting",
            })),
            tap((form) => set(lens.client_company_partners_page.children.client_company_partnership_forms.where((f) => f.edited.id === form.input.id).children.upload_partnership_contract_form.set(form))),
            switchMap((form) => formOperation("UploadClientCompanyPartnershipContract", form)),
            tap((response) => set(lens.client_company_partners_page.children.client_company_partnership_forms.where((f) => f.edited.id === response.input.id).children.upload_partnership_contract_form.set(response))),
            filter(({status}) => status === "success"),
            switchMap(() => clientCompanyPartnersReload$(lens, set, get)),
        ).subscribe();
    }),
    action("CLIENT_COMPANY_PARTNER_CONTRACT_DELETE", (obs$: Observable<TClientCompanyPartnershipContractForm>, lens, set, get) => {
        obs$.pipe(
            map((form): TClientCompanyPartnershipContractForm => ({
                ...form,
                status: "submitting",
            })),
            tap((form) => set(lens.client_company_partners_page.children.client_company_partnership_forms.where((f) => f.edited.id === form.edited.id).children.partnership_contract_form.set(form))),
            switchMap((form) => formOperation("DeleteClientCompanyPartnershipContract", form)),
            tap((response) => set(lens.client_company_partners_page.children.client_company_partnership_forms.where((f) => f.edited.id === response.edited.id).children.partnership_contract_form.set(response))),
            filter(({status}) => status === "success"),
            switchMap(() => clientCompanyPartnersReload$(lens, set, get)),
        ).subscribe();
    }),
    action("CLIENT_COMPANY_PARTNER_USER_CHANGE", (obs$: Observable<TClientCompanyPartnershipUserForm>, lens, set, get) => {
        obs$.pipe(
            map((form): TClientCompanyPartnershipUserForm => ({
                ...form,
                status: "requiresSubmission",
            })),
            tap((form) => set(lens.client_company_partners_page.children.client_company_partnership_forms
                .where((f) => f.edited.id === form.children.client_company_id).children.user_forms
                .where((f) => f.edited.id === form.edited.id)
                .set(form)
            )),
        ).subscribe();
    }),
    action("CLIENT_COMPANY_PARTNER_USER_SAVE", (obs$: Observable<TClientCompanyPartnershipUserForm>, lens, set, get) => {
        obs$.pipe(
            map((form): TClientCompanyPartnershipUserForm => ({
                ...form,
                status: "submitting",
            })),
            tap((form) => set(lens.client_company_partners_page.children.client_company_partnership_forms
                .where((f) => f.edited.id === form.children.client_company_id).children.user_forms
                .where((f) => f.edited.id === form.edited.id)
                .set(form)
            )),
            switchMap((form) => formOperation("UpdateClientCompanyPartnershipUser", form)),
            tap((response) => set(lens.client_company_partners_page.children.client_company_partnership_forms
                .where((f) => f.edited.id === response.children.client_company_id).children.user_forms
                .where((f) => f.edited.id === response.edited.id)
                .set(response)
            )),
            filter(({status}) => status === "success"),
            debounceTime(1000),
            switchMap(() => clientCompanyPartnersReload$(lens, set, get)),
        ).subscribe();
    }),
    action("CLIENT_COMPANY_PARTNER_USER_DELETE", (obs$: Observable<TClientCompanyPartnershipUserForm>, lens, set, get) => {
        obs$.pipe(
            map((form): TClientCompanyPartnershipUserForm => ({
                ...form,
                status: "submitting",
            })),
            tap((form) => set(lens.client_company_partners_page.children.client_company_partnership_forms
                .where((f) => f.edited.id === form.children.client_company_id).children.user_forms
                .where((f) => f.edited.id === form.edited.id)
                .set(form)
            )),
            switchMap((form) => formOperation("DisassociateClientCompanyPartnershipUser", form)),
            tap((response) => set(lens.client_company_partners_page.children.client_company_partnership_forms
                .where((f) => f.edited.id === response.children.client_company_id).children.user_forms
                .where((f) => f.edited.id === response.edited.id)
                .set(response)
            )),
            filter(({status}) => status === "success"),
            debounceTime(1000),
            switchMap(() => clientCompanyPartnersReload$(lens, set, get)),
        ).subscribe();
    }),
    action("CLIENT_COMPANY_PARTNER_USER_PHONE_NUMBER_CHANGE", (obs$: Observable<TClientCompanyPartnershipUserPhoneNumberForm>, lens, set, get) => {
        obs$.pipe(
            map((form): TClientCompanyPartnershipUserPhoneNumberForm => ({
                ...form,
                status: "requiresSubmission",
            })),
            tap((form) => set(lens.client_company_partners_page.children.client_company_partnership_forms
                .where((f) => f.edited.id === form.children.client_company_id).children.user_forms
                .where((f) => f.edited.id === form.children.client_company_user_id).children.phone_number_forms
                .where((f) => f.edited.id === form.edited.id)
                .set(form)
            )),
        ).subscribe();
    }),
    action("CLIENT_COMPANY_PARTNER_USER_PHONE_NUMBER_CREATE", (obs$: Observable<TClientCompanyPartnershipUserPhoneNumberCreateForm>, lens, set, get) => {
        obs$.pipe(
            switchMap((form) =>
                from(formOperation("CreateClientCompanyPartnershipUserPhoneNumber", form))
                    .pipe(
                        // A full reload isn't what we want after creating a new phone number as there may still be unsaved phone number changes that
                        // we could end up accidentally overwriting.
                        // Instead just modify the phone number array to include the newly created form at the end of the array.
                        switchMap(() => formOperation("GetClientCompanyPartnerships", lens.client_company_partners_page.get()(get()))),
                        tap((response) => {
                            const phoneNumberFormsWithNewAppended =
                                [
                                    ...lens.client_company_partners_page.children.client_company_partnership_forms
                                        .where((f) => f.edited.id === form.children.client_company_id).children.user_forms
                                        .where((f) => f.edited.id === form.children.client_company_user_id).children.phone_number_forms
                                        .get()(get()),
                                    ...pipe(
                                        (response.children.client_company_partnership_forms
                                            .find((f) => f.edited.id === form.children.client_company_id)?.children.user_forms
                                            .find((f) => f.edited.id === form.children.client_company_user_id)?.children.phone_number_forms) || [],
                                        array.last,
                                        option.fold(
                                            () => [],
                                            (newForm) => [newForm],
                                        )
                                    )
                                ];

                            set(lens.client_company_partners_page.children.client_company_partnership_forms
                                .where((f) => f.edited.id === form.children.client_company_id).children.user_forms
                                .where((f) => f.edited.id === form.children.client_company_user_id).children.phone_number_forms
                                .set(phoneNumberFormsWithNewAppended)
                            );
                        })
                    )
            ),
        ).subscribe();
    }),
    action("CLIENT_COMPANY_PARTNER_USER_PHONE_NUMBER_MAKE_PRIMARY", (obs$: Observable<TClientCompanyPartnershipUserPhoneNumberMakePrimaryForm>, lens, set, get) => {
        obs$.pipe(
            map((form): TClientCompanyPartnershipUserPhoneNumberMakePrimaryForm => ({
                ...form,
                status: "submitting",
            })),
            tap((form) => set(lens.client_company_partners_page.children.client_company_partnership_forms
                .where((f) => f.edited.id === form.children.client_company_id).children.user_forms
                .where((f) => f.edited.id === form.children.client_company_user_id).children.phone_number_forms
                .where((f) => f.edited.id === form.edited.id).children.make_primary_form
                .set(form)
            )),
            switchMap((form) => formOperation("SetClientCompanyPartnershipUserPhoneNumberPrimary", form)),
            tap((response) => set(lens.client_company_partners_page.children.client_company_partnership_forms
                .where((f) => f.edited.id === response.children.client_company_id).children.user_forms
                .where((f) => f.edited.id === response.children.client_company_user_id).children.phone_number_forms
                .where((f) => f.edited.id === response.edited.id).children.make_primary_form
                .set(response)
            )),
            filter(({status}) => status === "success"),
            // Instead of re requesting the whole company like we normally would we will only patch the primary_number property, like above a full reload
            // would mean we could lose anything unsaved.
            tap((response) => set(lens.client_company_partners_page.children.client_company_partnership_forms
                .where((f) => f.edited.id === response.children.client_company_id).children.user_forms
                .where((f) => f.edited.id === response.children.client_company_user_id).children.phone_number_forms
                .set(pipe(
                    lens.client_company_partners_page.children.client_company_partnership_forms
                        .where((f) => f.edited.id === response.children.client_company_id).children.user_forms
                        .where((f) => f.edited.id === response.children.client_company_user_id).children.phone_number_forms.get()(get()),
                    array.map((f) => ({
                        ...f,
                        children: {
                            ...f.children,
                            primary_number: f.edited.id === response.edited.id,
                        }
                    })),
                ))
            )),
        ).subscribe();
    }),
    action("CLIENT_COMPANY_PARTNER_USER_PHONE_NUMBER_DELETE", (obs$: Observable<TClientCompanyPartnershipUserPhoneNumberDeleteForm>, lens, set, get) => {
        obs$.pipe(
            map((form): TClientCompanyPartnershipUserPhoneNumberDeleteForm => ({
                ...form,
                status: "submitting",
            })),
            tap((form) => set(lens.client_company_partners_page.children.client_company_partnership_forms
                .where((f) => f.edited.id === form.children.client_company_id).children.user_forms
                .where((f) => f.edited.id === form.children.client_company_user_id).children.phone_number_forms
                .where((f) => f.edited.id === form.edited.id).children.delete_form
                .set(form)
            )),
            switchMap((form) => formOperation("DeleteClientCompanyPartnershipUserPhoneNumber", form)),
            tap((response) => set(lens.client_company_partners_page.children.client_company_partnership_forms
                .where((f) => f.edited.id === response.children.client_company_id).children.user_forms
                .where((f) => f.edited.id === response.children.client_company_user_id).children.phone_number_forms
                .where((f) => f.edited.id === response.edited.id).children.delete_form
                .set(response)
            )),
            filter(({status}) => status === "success"),
            switchMap((response) =>
                forkJoin([
                    of(response),
                    from(formOperation("GetClientCompanyPartnerships", lens.client_company_partners_page.get()(get()))),
                ])
            ),
            tap(([deleteResponse, getCompanyPartnersResponse]) => {
                const phoneNumberForms =
                    lens.client_company_partners_page.children.client_company_partnership_forms
                        .where((f) => f.edited.id === deleteResponse.children.client_company_id).children.user_forms
                        .where((f) => f.edited.id === deleteResponse.children.client_company_user_id).children.phone_number_forms
                        .get()(get());

                const newPrimaryNumberId =
                    pipe(
                        (getCompanyPartnersResponse.children.client_company_partnership_forms
                            .find((f) => f.edited.id === deleteResponse.children.client_company_id)?.children.user_forms
                            .find((f) => f.edited.id === deleteResponse.children.client_company_user_id)?.children.phone_number_forms) || [],
                        array.findFirst(({children}) => children.primary_number),
                        option.fold(
                            () => "",
                            (f) => f.edited.id,
                        )
                    );

                const formIdToRemove = deleteResponse.edited.id;

                set(
                    lens.client_company_partners_page.children.client_company_partnership_forms
                        .where((f) => f.edited.id === deleteResponse.children.client_company_id).children.user_forms
                        .where((f) => f.edited.id === deleteResponse.children.client_company_user_id).children.phone_number_forms
                        .set(pipe(
                            phoneNumberForms,
                            array.filterMap((f) =>
                                f.edited.id === formIdToRemove
                                    ? option.none
                                    : option.some({
                                        ...f,
                                        children: {
                                            ...f.children,
                                            primary_number: f.edited.id === newPrimaryNumberId,
                                        },
                                    }),
                            ),
                        )),
                );
            }),
        ).subscribe();
    }),
];
