import React, { useState } from "react";
import { IOption } from "./UseDropdown";
import fuzzysort from "fuzzysort";
import { pipe } from "fp-ts/lib/function";

export interface IFuzzySortOption<A, M extends Record<string, unknown>> extends IOption<A> {
    meta?: M;
    richLabelFormatting?: (result: Fuzzysort.KeyResult<IFuzzySortOption<A, M>>, searchText: string) => JSX.Element;
};

export type TFuzzySearchStatus = "nothing-to-search" | "untouched" | "matching" | "no-match";

export const useFuzzysort = <V extends string, M extends Record<string, unknown>>(options: Array<IFuzzySortOption<V, M>>) => {

    const [searchText, setSearchText] = useState("");
    const [searchOptions, setSearchOptions] = useState(options);

    const getSearchOptions = () => searchOptions;

    const getSearchResults = (keepOriginalOrder?: boolean): Fuzzysort.KeyResults<IFuzzySortOption<V, M>> => {
        return fuzzySort({
            text: searchText,
            options: searchOptions
        }, keepOriginalOrder);
    };

    const isSearchTextEmpty = (): boolean => searchText.length < 1;

    const areThereOptionsToSearch = (): boolean => searchOptions.length > 0;

    const getResultsCount = (): number => getSearchResults().length;

    const getStatus = (): TFuzzySearchStatus => {
        if (!areThereOptionsToSearch()) {
            return "nothing-to-search";
        }

        if (areThereOptionsToSearch() && isSearchTextEmpty()) {
            return "untouched";
        }

        if (!isSearchTextEmpty() && getResultsCount() > 0) {
            return "matching";
        }

        return "no-match";
    }

    return {
        getSearchResults,
        searchText,
        setSearchOptions,
        getSearchOptions,
        setSearchText,
        getStatus,
        getResultsCount,
    };
}

export type TTypeFuzzySort<V extends string, M extends Record<string, unknown>> = {
    text: string;
    options: Array<IFuzzySortOption<V, M>>;
};

export const fuzzySort = <V extends string, M extends Record<string, unknown>>(
    search: TTypeFuzzySort<V, M>,
    keepOriginalOrder?: boolean
): Fuzzysort.KeyResults<IFuzzySortOption<V, M>> => { 
    
    // If there's no search text inputted by the user, but there are options/choices ready to match to
    // (e.g. suggested matching case results on an email in triage)
    // this passes those options forward in a format which matches the fuzzysort types
    // fuzzysort would otherwise return an empty object because of how it handles empty/no search text
    if (search.text.length < 1) {
        let results: Array<Fuzzysort.KeyResult<IFuzzySortOption<V, M>>> = new Array(search.options.length);
        if (search.options.length > 0) {
            search.options.map((option, index) => {
                var res = {
                    indexes: [],
                    score: 0,
                    target: option.label,
                    obj: option,
                } as Fuzzysort.KeyResult<IFuzzySortOption<V, M>>;
                results[index] = res;
            });
        };

        // annoyingly fuzzysort has an interface which extands an array and has a readonly 'total' property on it
        // but there's no constructor for this for typescript so we have to cast to any/unknown first
        // eslint-disable-next-line
        (results as any).total = results.length;
        return results as unknown as Fuzzysort.KeyResults<IFuzzySortOption<V, M>>;
    }

    // where we want to use the fuzzysort highlighting feature but DON'T want to reorder the search
    // e.g. the results have come from the database already ordered and we want to keep them in that order
    // Below we use fuzzysort.single and add each one to a results array so we can keep the same order
    if (search.text.length > 0 && keepOriginalOrder && search.options.length > 0) {
        let results: Array<Fuzzysort.KeyResult<IFuzzySortOption<V, M>>> = new Array(search.options.length);
        
        search.options.forEach((searchOption, index) => {

            let fuzzysortedOption = fuzzysort.single(
                search.text,
                searchOption.label as string,
            );

            if (fuzzysortedOption) {
                let res = {
                    indexes: fuzzysortedOption.indexes, 
                    score: fuzzysortedOption.score,
                    target: fuzzysortedOption.target,
                    obj: searchOption as IFuzzySortOption<V, M>
                } as Fuzzysort.KeyResult<IFuzzySortOption<V, M>>;
                results[index] = res;
            };
        });
        // eslint-disable-next-line
        (results as any).total = search.options.length;
        return results as unknown as Fuzzysort.KeyResults<IFuzzySortOption<V, M>>;
    }

    // this one is the straightforward badboy where we're happy for fuzzysort to sort the order 
    // and returns the right type for highlighting etc
    return pipe(
        fuzzysort.go<IFuzzySortOption<V, M>>(
            search.text,  
            search.options, 
            { 
                key: "label",
                threshold: -Infinity,
                allowTypo: false,
            }
        ),
        (results) => {
            return results
        }
    );
}

export const fuzzySortCurried = <V extends string, M extends Record<string, unknown>>(text: string) => 
    (options: Array<IFuzzySortOption<V, M>>): Fuzzysort.KeyResults<IFuzzySortOption<V, M>> =>
        fuzzySort<V, M>({ text, options })
;

interface IFuzzySearchHighlightedTextProps<V extends string, M extends Record<string, unknown>> {
    result: Fuzzysort.KeyResult<IFuzzySortOption<V, M>>;
    className: string;
}

export const FuzzySearchHighlightedText = <V extends string, M extends Record<string, unknown>>(props: IFuzzySearchHighlightedTextProps<V, M> ): JSX.Element => (
    <div
        dangerouslySetInnerHTML={{
            __html: 
                fuzzysort.highlight(props.result, `<span class="${props.className}">`, "</span>")
                || "" 
        }} 
    />
);
