import React, { useState } from "react";
import { TFormStatus } from "./../../../../shared/src/codecs/codec";
import { CRMSpacingColumn } from "../CRMSpacingColumn/CRMSpacingColumn";
import { CRMSpacingRow } from "../CRMSpacingRow/CRMSpacingRow";
import { CRMRadioTab } from "../CRMRadioTab/CRMRadioTab";
import { CRMButtonIcon } from "../CRMButtonIcon/CRMButtonIcon";
import { CRMFormButtonIconWithLabel } from "../CRMFormButtonIconWithLabel/CRMFormButtonIconWithLabel";
import { CRMParagraph } from "../Simple/CRMParagraph/CRMParagraph";
import { CRMPadding } from "../Simple/CRMPadding/CRMPadding";
import { TextCenter } from "../TextCenter/TextCenter";
import { WeightBold } from "../WeightBold/WeightBold";
import { useSelectedTab } from "../../hooks/useSelectedTab";
import { TCaseOtherSideMemberForm } from "../../../../domain/codecs/form/CaseOtherSideMemberForm";
import { CRMCodecEditForm } from "../CRMCodecEditForm/CRMCodecEditForm";
import { UserLegalNames, UserLegalCorrespondenceAddress1 } from "../../../../domain/codecs/User";
import { CRMCardOutsidePopupBasic } from "../CRMCardOutsidePopupBasic/CRMCardOutsidePopupBasic";
import { CRMTitleForCard } from "../CRMTitleForCard/CRMTitleForCard";
import { CRMButtonSecondary } from "../CRMButtonSecondary/CRMButtonSecondary";
import { CRMAutosaveIndicatorWrap } from "../CRM/CRMAutosaveIndicatorWrap/CRMAutosaveIndicatorWrap";
import { Country4, Country4ToDisplayName } from "../../../../domain/codecs/Country";
import { CRMGenericEditModalWithText } from "../CRMGenericEditModalWithText/CRMGenericEditModalWithText";
import { CRMInputLabelAndErrorWrapComponent } from "../CRMInputLabelAndErrorWrapComponent/CRMInputLabelAndErrorWrapComponent";
import { CRMSpacer } from "../CRMSpacer/CRMSpacer";
import { CRMDropdownComponent } from "../CRMDropdownComponent/CRMDropdownComponent";
import { enumToIDropdownOptions } from "../../functions/enumToIDropdownOptions";

type TCRMCaseOtherSideMembersFormProps = {
    members: Array<TCaseOtherSideMemberForm>;
    onAddMember: () => void;
    addMemberFormStatus: TFormStatus;
    onUpdateMember: (member: TCaseOtherSideMemberForm) => void;
    onDeleteMember: (member: TCaseOtherSideMemberForm) => void;
};

export const CRMCaseOtherSideMembersForm = (props: TCRMCaseOtherSideMembersFormProps): JSX.Element => {
    const {
        isSelectedTabIndex,
        setSelectedTabIndex,
        getSelectedTab,
        selectedTabIndex
    } = useSelectedTab<TCaseOtherSideMemberForm>(props.members);

    return (
        <CRMSpacingRow
            spacing="large"
            alignItems="flex-start"
            childSize={props.members.length === 0 ? "1fr 1fr" : "2fr 1fr"}
        >
            <>
                {/* ADD PARTY MEMBER BUTTON */}
                {props.members.length === 0 &&
                    <CRMPadding size="tiny">
                        <TextCenter>
                            <CRMParagraph>
                                Add the <WeightBold>other side's clients</WeightBold> to get started.
                            </CRMParagraph>
                        </TextCenter>
                    </CRMPadding>
                }
                {/* USER FORM */}
                {props.members.length > 0 && selectedTabIndex <= props.members.length-1 &&
                    <CRMSpacingColumn spacing="medium">
                        <CRMCodecEditForm
                            codec={UserLegalNames}
                            model={(getSelectedTab() as TCaseOtherSideMemberForm).edited}
                            validationErrors={(getSelectedTab() as TCaseOtherSideMemberForm).validationErrors}
                            onChange={(edited) => props.onUpdateMember({
                                ...getSelectedTab() as TCaseOtherSideMemberForm,
                                edited: {
                                    ...(getSelectedTab() as TCaseOtherSideMemberForm).edited,
                                    ...edited,
                                }
                            })}
                        />
                        <CRMInputLabelAndErrorWrapComponent label="Legal Correspondence Address">
                            <CRMGenericEditModalWithText
                                modalSize="large"
                                text={(() => {
                                    const address = [
                                        [
                                            (getSelectedTab() as TCaseOtherSideMemberForm).edited.legal_correspondence_sub_building_name,
                                            (getSelectedTab() as TCaseOtherSideMemberForm).edited.legal_correspondence_sub_building_number,
                                            (getSelectedTab() as TCaseOtherSideMemberForm).edited.legal_correspondence_building_name,
                                            (getSelectedTab() as TCaseOtherSideMemberForm).edited.legal_correspondence_building_number,
                                            (getSelectedTab() as TCaseOtherSideMemberForm).edited.legal_correspondence_street_name
                                        ].filter((s) => s !== "").join(" "),
                                        (getSelectedTab() as TCaseOtherSideMemberForm).edited.legal_correspondence_locality,
                                        (getSelectedTab() as TCaseOtherSideMemberForm).edited.legal_correspondence_district,
                                        (getSelectedTab() as TCaseOtherSideMemberForm).edited.legal_correspondence_city_town,
                                        (getSelectedTab() as TCaseOtherSideMemberForm).edited.legal_correspondence_postcode,
                                        (getSelectedTab() as TCaseOtherSideMemberForm).edited.legal_correspondence_county,
                                        (getSelectedTab() as TCaseOtherSideMemberForm).edited.legal_correspondence_country === "unknown" ?
                                        "" : Country4ToDisplayName((getSelectedTab() as TCaseOtherSideMemberForm).edited.legal_correspondence_country)
                                    ].filter((s) => s !== "" && s !== null).join(", ")

                                    return address === "" ? "No Address" : address;
                                })()}
                                body={
                                    (setIsOpen) => (
                                        <CRMPadding size="large">
                                            <CRMAutosaveIndicatorWrap
                                                status={(getSelectedTab() as TCaseOtherSideMemberForm).status}
                                            >
                                                <CRMTitleForCard>LEGAL CORRESPONDENCE ADDRESS</CRMTitleForCard>
                                                <CRMSpacer size="large" />

                                                <CRMSpacingColumn spacing="medium">
                                                    <CRMCodecEditForm
                                                        codec={UserLegalCorrespondenceAddress1}
                                                        model={(getSelectedTab() as TCaseOtherSideMemberForm).edited}
                                                        validationErrors={(getSelectedTab() as TCaseOtherSideMemberForm).validationErrors}
                                                        onChange={(edited) => props.onUpdateMember({
                                                            ...getSelectedTab() as TCaseOtherSideMemberForm,
                                                            edited: {
                                                                ...(getSelectedTab() as TCaseOtherSideMemberForm).edited,
                                                                ...edited,
                                                            }
                                                        })}
                                                        columns={2}
                                                    />

                                                    <CRMSpacingRow
                                                        spacing="medium"
                                                        alignItems="flex-start"
                                                        childSize="1fr 1fr"
                                                    >
                                                        <CRMInputLabelAndErrorWrapComponent label="Legal correspondence country">
                                                            <CRMDropdownComponent
                                                                value={(getSelectedTab() as TCaseOtherSideMemberForm).edited.legal_correspondence_country}
                                                                options={enumToIDropdownOptions(
                                                                    Country4.values,
                                                                    Country4ToDisplayName,
                                                                )}
                                                                displayError={false}
                                                                onChange={(legal_correspondence_country) => props.onUpdateMember({
                                                                    ...getSelectedTab() as TCaseOtherSideMemberForm,
                                                                    edited: {
                                                                        ...(getSelectedTab() as TCaseOtherSideMemberForm).edited,
                                                                        legal_correspondence_country,
                                                                    }
                                                                })}
                                                            />
                                                        </CRMInputLabelAndErrorWrapComponent>
                                                        <div></div>
                                                    </CRMSpacingRow>
                                                </CRMSpacingColumn>
                                                <CRMSpacer size="large" />
                                                
                                                {/* BUTTONS */}
                                                <CRMSpacingRow justifyContent="end">
                                                    <CRMButtonSecondary
                                                        label="Close"
                                                        onClick={() => setIsOpen(false)}
                                                    />
                                                </CRMSpacingRow>
                                            </CRMAutosaveIndicatorWrap>
                                        </CRMPadding>
                                    )
                                }
                            />
                        </CRMInputLabelAndErrorWrapComponent>
                    </CRMSpacingColumn>
                }
            </>
            {/* MEMBER TABS */}
            <CRMSpacingColumn spacing="medium">
                {props.members.map((member, index) =>
                    <CRMCaseOtherSideMemberFormTabAndPopup
                        key={index}
                        index={index}
                        member={member}
                        onDeleteMember={props.onDeleteMember}
                        isSelectedTab={isSelectedTabIndex(index)}
                        setSelectedTabIndex={() => setSelectedTabIndex(index)}
                    />
                )}
                <CRMFormButtonIconWithLabel
                    formStatus={props.addMemberFormStatus === "unauthorised" || props.addMemberFormStatus === "twoFactorRequired" ? "failure"
                        : props.addMemberFormStatus === "notFound" ? "failure"
                        : props.addMemberFormStatus}
                    ButtonElement={(buttonProps) => <CRMButtonIcon variant="primary" {...buttonProps} />}
                    icon="person-add"
                    label="Add new member"
                    onClick={props.onAddMember}
                />
            </CRMSpacingColumn>
        </CRMSpacingRow>
    );
};

const CRMCaseOtherSideMemberFormTabAndPopup = (props: {
    index: number;
    member: TCaseOtherSideMemberForm;
    onDeleteMember: (member: TCaseOtherSideMemberForm) => void;
    isSelectedTab: boolean;
    setSelectedTabIndex: () => void;
}): JSX.Element => {
    const [deletePopupIsOpen, setDeletePopupIsOpen] = useState<boolean>(false);
                    
    return <>
        {/* DELETE POPUP */}
        <CRMCardOutsidePopupBasic
            isOpen={deletePopupIsOpen}
            title="Are you sure?"
            context="important"
            onClose={() => setDeletePopupIsOpen(false)}
            closeText="Cancel"
            onCTA={() => {
                setDeletePopupIsOpen(false);
                props.onDeleteMember(props.member);
            }}
            ctaText="Yes"
        >
            <CRMParagraph>
                Are you sure you want to delete this client on the other side?
            </CRMParagraph>
        </CRMCardOutsidePopupBasic>

        {/* TAB */}
        <CRMRadioTab
            key={props.index}
            isSelected={props.isSelectedTab}
            label={`${props.member.edited.legal_first_legal_name} ${props.member.edited.legal_last_legal_name}`}
            onClick={() => props.setSelectedTabIndex()}
            options={[
                {
                    label: "Delete",
                    onClick: () => setDeletePopupIsOpen(true)
                }
            ]}
        />
    </>;
}