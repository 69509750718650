import * as QueryStringFilterGroup1 from "./QueryStringFilterGroup1";
import * as QueryStringFilterMappings from "./QueryStringFilterMappings";
import * as QueryStringFilterMappingFunction from "./QueryStringFilterMappingFunction";
import { array, nonEmptyArray } from "fp-ts/lib";
import * as QueryStringFilter1 from "../models/QueryStringFilter1";
import { pipe } from "fp-ts/lib/function";
import { TValidationFunction } from "../../shared/src/validation/Function";

export const filterMappings: QueryStringFilterMappings.T = {
    string: {
        user_id: QueryStringFilterMappingFunction.createForString("users.id"),
        email: QueryStringFilterMappingFunction.createForString("users.email"),
        first_name: QueryStringFilterMappingFunction.createForString("users.first_name"),
        last_name: QueryStringFilterMappingFunction.createForString("users.last_name"),
        phone_number: QueryStringFilterMappingFunction.createForPhoneNumber("users_phone_numbers.phone_number"),
    },
    boolean: {
        is_admin: QueryStringFilterMappingFunction.createForBoolean("(user_role = 'admin')"),
    },
};

export const validator: TValidationFunction = QueryStringFilterGroup1.createValidator(filterMappings);

export const create = (value: string): QueryStringFilterGroup1.T => ({
    type: "GROUP",
    conditional: "OR",
    filters:  pipe(
        value.trim().split(" "),
        array.map<string, Array<QueryStringFilter1.T>>((namePart) => [
            {
                type: "FILTER",
                property: "first_name",
                operator: "BEGINS_WITH",
                value: namePart,
            },
            {
                type: "FILTER",
                property: "last_name",
                operator: "BEGINS_WITH",
                value: namePart,
            },
        ]),
        array.flatten,
        (a) => array.getMonoid<QueryStringFilter1.T>().concat(a, [
            {
                type: "FILTER",
                property: "email",
                operator: "BEGINS_WITH",
                value,
            },
            {
                type: "FILTER",
                property: "phone_number",
                operator: "BEGINS_WITH",
                value,
            },
        ]),
    ),
});

export const createWhereUserIdNotAnyOf = (userIds: nonEmptyArray.NonEmptyArray<string>): QueryStringFilterGroup1.T => ({
    type: "GROUP",
    conditional: "AND",
    filters:  pipe(
        userIds,
        array.map((id) => ({
            type: "FILTER",
            property: "user_id",
            operator: "NOT_EQUALS_STRING",
            value: id,
        }))
    ),
});
