import * as t from "io-ts";
import * as JsonResponse1 from "./JsonResponse1";
import * as Case2 from "./Case2";

export const codec = JsonResponse1.createCodec(
    Case2.codec,
    t.type({}),
);

export type T = t.TypeOf<typeof codec>;

export const newDefault = (): T => ({
    data: Case2.newDefault(),
    meta: {},
});
