import React from "react";
import { CRMIcon, TIcon } from "../../../CRMIcon/CRMIcon";
import { CRMColors } from "../../../../models/CRMColors";
import { Background } from "../../../BuildingBlocks/Background";
import { CRMSpacing } from "../../../../models/CRMSpacing";
import { useHover } from "../../../../hooks/useHover";
import { THexColor } from "../../../../models/StringLiterals";
import { Relative } from "../../../BuildingBlocks/Relative";
import { Absolute } from "../../../BuildingBlocks/Absolute";
import { CRMBadge } from "../../../Complex/CRMBadge/CRMBadge";

type TBlockHeaderButtonProps = {
    icon: TIcon;
    isActive: boolean;
    backgroundColour?: THexColor | "transparent";
    iconColour?: THexColor;
    borderLeftColour?: THexColor | "none";
    badgeCount?: number;
    onClick: () => void;
};

export const CRMBlockHeaderButton = (props: React.PropsWithChildren<TBlockHeaderButtonProps>): JSX.Element => {
    const [ref, isHovering] = useHover<HTMLDivElement>({});

    const IsActive = isHovering || props.isActive;

    const backgroundColour = props.backgroundColour || CRMColors.NEUTRAL_PAPER;

    const iconColour = props.iconColour || CRMColors.NEUTRAL_INK;

    const borderLeftColour = props.borderLeftColour || CRMColors.NEUTRAL_8;

    const badgeCount = props.badgeCount || 0;

    return <Relative
        divRef={ref}
    >
        {/* BUTTON */}
        <Background
            display="flex"
            height={`44px`}
            padding={`0px ${CRMSpacing.SMALL}`}
            alignItems="center"
            color={
                IsActive ? CRMColors.PRIMARY_0
                : backgroundColour === "transparent" ? undefined
                : backgroundColour
            }
            borderLeftDefinition={
                borderLeftColour === "none"
                    ? undefined
                    : `1px solid ${borderLeftColour}`
            }
            cursor="pointer"
            transition="all 300ms"
            onClick={props.onClick}
        >
            <CRMIcon
                iconName={props.icon}
                colour="hex"
                hexColour={IsActive
                    ? CRMColors.NEUTRAL_PAPER
                    : iconColour
                }
            />
        </Background>

        {/* BADGE */}
        {badgeCount > 0 && !IsActive &&
            <Absolute
                bottom="-4px"
                right="-4px"
            >
                <CRMBadge
                    backgroundColour={CRMColors.PRIMARY_0}
                    fontColour={CRMColors.NEUTRAL_PAPER}
                >
                    {badgeCount}
                </CRMBadge>
            </Absolute>
        }
    </Relative>
};
