import React from "react";

type TAnchorButtonType =
    "rooster"
    | "otter"
    | "puffin"
    | "frog"
    | "fox"
    | "charizard"
;

type TButtonSelectedState =
    "on"
    | "off"
;

interface IAnchorButton {
    label: string | React.ReactNode;
    type: TAnchorButtonType;
    fullWidth?: boolean;
    selectedState?: TButtonSelectedState;
    disabled?: boolean;
    url: string;
    preventDefault?: boolean;
}

class AnchorButton extends React.Component<React.PropsWithChildren<IAnchorButton>> {
    public render (): JSX.Element {
        return (
            <a className={
                `buttonComponent` +
                (this.props.fullWidth ? " buttonComponent--full-width" : "")
            }
                href={this.props.url}
                onClick={(e) => this.props.preventDefault ? e.preventDefault() : null}
                onKeyDown={(e) => e.keyCode === 13 && this.props.preventDefault ? e.preventDefault() : null}
            >
                <div
                    className={
                        `buttonComponent__button` +
                        ` buttonComponent__button--${this.props.type}` +
                        (this.props.selectedState ? `-${this.props.selectedState}` : "-on") +
                        (this.props.disabled ? ` buttonComponent__button--${this.props.type}-disabled` : "")
                    }
                >
                    {this.props.label}
                </div>
            </a>
        );
    }
}

export default AnchorButton;
