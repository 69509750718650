import React from "react";
import { TClientCompanyPartnershipContractForm, TClientCompanyPartnershipContractUploadForm, TClientCompanyPartnershipForm, TClientCompanyPartnershipUserForm, TClientCompanyPartnershipUserPhoneNumberCreateForm, TClientCompanyPartnershipUserPhoneNumberDeleteForm, TClientCompanyPartnershipUserPhoneNumberForm, TClientCompanyPartnershipUserPhoneNumberMakePrimaryForm } from "../../../../../domain/codecs/form/ClientCompanyPartnershipForm";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { Padding } from "../../BuildingBlocks/Padding";
import { SpacingColumn } from "../../BuildingBlocks/SpacingColumn";
import { CRMTitleSubSection } from "../../CRMTitleSubSection/CRMTitleSubSection";
import { CRMCompanyPartnerAddressForm } from "../CRMCompanyPartnerAddressForm/CRMCompanyPartnerAddressForm";
import { CRMCompanyPartnerAMLAndKYCForm } from "../CRMCompanyPartnerAMLAndKYCForm/CRMCompanyPartnerAMLAndKYCForm";
import { CRMCompanyPartnerCompanyInformationForm } from "../CRMCompanyPartnerCompanyInformationForm/CRMCompanyPartnerCompanyInformationForm";
import { CRMCompanyPartnerContractForm } from "../CRMCompanyPartnerContractForm/CRMCompanyPartnerContractForm";
import { CRMCompanyPartnerPeople } from "../CRMCompanyPartnerPeople/CRMCompanyPartnerPeople";

export const CRMCompanyPartnerForm = (props: React.PropsWithChildren<{
    form: TClientCompanyPartnershipForm;
    onChange: (form: TClientCompanyPartnershipForm) => void;
    onUploadContract: (form: TClientCompanyPartnershipContractUploadForm) => void;
    onDeleteContract: (form: TClientCompanyPartnershipContractForm) => void;
    onAddressAutocomplete: (form: TClientCompanyPartnershipForm) => void;
    onChangeUser: (form: TClientCompanyPartnershipUserForm) => void;
    onSaveUser: (form: TClientCompanyPartnershipUserForm) => void;
    onDeleteUser: (form: TClientCompanyPartnershipUserForm) => void;
    onUserPhoneNumberChange: (form: TClientCompanyPartnershipUserPhoneNumberForm) => void;
    onUserNewPhoneNumber: (form: TClientCompanyPartnershipUserPhoneNumberCreateForm) => void;
    onUserPhoneNumberMakePrimary: (form: TClientCompanyPartnershipUserPhoneNumberMakePrimaryForm) => void;
    onUserPhoneNumberDelete: (form: TClientCompanyPartnershipUserPhoneNumberDeleteForm) => void;
}>): JSX.Element => {
    return (
        <Padding 
            width="100%" 
            type="bottom" 
            spacing={"60px"}
        >
            <SpacingColumn spacing={CRMSpacing.LARGE}>
                {/* PARTNERSHIP CONTRACT SECTION */}
                <SpacingColumn spacing={CRMSpacing.MEDIUM}>
                    <CRMTitleSubSection>
                        Partnership Contract
                    </CRMTitleSubSection>

                    <CRMCompanyPartnerContractForm
                        existingContractForm={props.form.children.partnership_contract_form}
                        uploadContractForm={props.form.children.upload_partnership_contract_form}
                        onUploadContract={props.onUploadContract}
                        onDeleteContract={props.onDeleteContract}
                    />
                </SpacingColumn>
                
                {/* COMPANY INFORMATION SECTION */}
                <SpacingColumn spacing={CRMSpacing.MEDIUM}>
                    <CRMTitleSubSection>
                        Company Information
                    </CRMTitleSubSection>

                    {/* BASIC COMPANY QUESTIONS: NAME, NUMBER, TYPE */}
                    <CRMCompanyPartnerCompanyInformationForm
                        form={props.form}
                        onChange={props.onChange}
                    />

                    {/* COMPANY ADDRESS */}
                    <CRMCompanyPartnerAddressForm
                        form={props.form}
                        onChange={props.onChange}
                        onAutocomplete={props.onAddressAutocomplete}
                    />
                </SpacingColumn>

                {/* AML & KYC */}
                <SpacingColumn spacing={CRMSpacing.MEDIUM}>
                    <CRMTitleSubSection>
                        AML & KYC
                    </CRMTitleSubSection>

                    <CRMCompanyPartnerAMLAndKYCForm
                        form={props.form}
                        onChange={props.onChange}
                    />
                </SpacingColumn>

                {/* PEOPLE */}
                <SpacingColumn spacing={CRMSpacing.MEDIUM}>
                    <CRMTitleSubSection>
                        People
                    </CRMTitleSubSection>

                    <CRMCompanyPartnerPeople
                        form={props.form}
                        onChange={props.onChangeUser}
                        onSave={props.onSaveUser}
                        onDelete={props.onDeleteUser}
                        onPhoneNumberChange={props.onUserPhoneNumberChange}
                        onNewPhoneNumber={props.onUserNewPhoneNumber}
                        onPhoneNumberMakePrimary={props.onUserPhoneNumberMakePrimary}
                        onPhoneNumberDelete={props.onUserPhoneNumberDelete}
                    />
                </SpacingColumn>
            </SpacingColumn>
        </Padding>
    );
};
