import * as t from "io-ts";
import * as PartySellingPropertyState from "./PartySellingPropertyState";
import * as EnquiryPaymentMethod1 from "./EnquiryPaymentMethod1";
import * as BuyerReadyHasProvidedSolicitorDetails from "./BuyerReadyHasProvidedSolicitorDetails";
import * as ListingEnquiriesOffer6 from "./ListingEnquiriesOffer6";
import * as BuyerOffer1 from "./BuyerOffer1";
import * as BuyerReadyHasProofOfFundsForCurrentOffer from "./BuyerReadyHasProofOfFundsForCurrentOffer";

export const codec = t.union([
    t.literal("low"),
    t.literal("medium"),
    t.literal("high"),
]);

type T = t.TypeOf<typeof codec>;

// Calculate proof of funds up to their offer
const calculateBuyerReadyRiskOfFallThrough = (obj: {
    selling_property_state: PartySellingPropertyState.T;
    payment_method: EnquiryPaymentMethod1.T;
    id_check_complete: boolean;
    has_proof_of_funds_for_current_offer: BuyerReadyHasProofOfFundsForCurrentOffer.T;
    has_provided_solicitor_details: BuyerReadyHasProvidedSolicitorDetails.T;
    mortgage_broker_has_vouched: boolean;
}): T =>
    // Low risk
    obj.has_proof_of_funds_for_current_offer
    && obj.id_check_complete
    && obj.has_provided_solicitor_details
    && (
        obj.payment_method === "cash"
        || obj.mortgage_broker_has_vouched
    )
    && obj.selling_property_state === "no_property_to_sell"
        ? "low"

    // Medium risk
    : obj.has_proof_of_funds_for_current_offer
    && (
        obj.id_check_complete
        || obj.has_provided_solicitor_details
    )
        ? "medium"

    // High risk
    : "high";

export const fromListingEnquiriesOffer6 = (m: ListingEnquiriesOffer6.T): T => calculateBuyerReadyRiskOfFallThrough({
    has_proof_of_funds_for_current_offer: BuyerReadyHasProofOfFundsForCurrentOffer.fromListingEnquiriesOffer6(m),
    has_provided_solicitor_details: BuyerReadyHasProvidedSolicitorDetails.fromListingEnquiriesOffer6(m),
    id_check_complete: m.id_check_complete,
    mortgage_broker_has_vouched: m.mortgage_broker_has_vouched,
    payment_method: m.payment_method,
    selling_property_state: m.selling_property_state,
});

export const fromBuyerOffer1 = (m: BuyerOffer1.T): T => calculateBuyerReadyRiskOfFallThrough({
    has_proof_of_funds_for_current_offer: BuyerReadyHasProofOfFundsForCurrentOffer.fromBuyerOffer1(m),
    has_provided_solicitor_details: BuyerReadyHasProvidedSolicitorDetails.fromBuyerEnquiry1(m.enquiry),
    id_check_complete: m.enquiry.id_check_complete,
    mortgage_broker_has_vouched: m.enquiry.mortgage_broker_has_vouched,
    payment_method: m.enquiry.payment_method,
    selling_property_state: m.party.selling_property_state,
});

