import React from "react";

type HorizontalLineProps = {
    color: string;
    height?: string;
};

export const HorizontalLine = (props: HorizontalLineProps): JSX.Element => {

    return (
        <div 
            style={{
                height: props.height || "1px",
                width: "100%",
                backgroundColor: props.color
            }}
        />
    );
};
