import { array, string } from "fp-ts";
import { pipe } from "fp-ts/function";
import { Ord } from "fp-ts/lib/Date";
import { contramap } from "fp-ts/lib/Ord";
import { DateTime } from "luxon";
import React, { useState } from "react";
import { TClientCaseVideoVerificationCallAvailableTimesForm } from "../../../../domain/codecs/form/ClientCaseForm";
import { IOption } from "../../hooks/UseDropdown";
import { FrontCondensedDropdownQuestion } from "../Front/Simple/FrontCondensedDropdownQuestion/FrontCondensedDropdownQuestion";
import { FrontCondensedRadioQuestions } from "../Front/Simple/FrontCondensedRadioQuestions/FrontCondensedRadioQuestions";

export const FrontBookVideoCallDateAndTimeSelection = (props: {
    form: TClientCaseVideoVerificationCallAvailableTimesForm,
    displayValidationErrorsIfPresent: boolean,
    onChange: (iso: string | null) => void,
    value: string | null;
}): JSX.Element => {
    const [selectedDate, setSelectedDate] = useState<string | null>(null);

    const getDateOptionLabelForIsoDate = (isoDate: string) => {
        const dCompare = DateTime.fromISO(isoDate).setZone("Europe/London").set({hour: 0, minute: 0, second: 0, millisecond: 0});
        const dNow = DateTime.utc().setZone("Europe/London").set({hour: 0, minute: 0, second: 0, millisecond: 0});
        return dCompare.toSeconds() === dNow.toSeconds() ? "Today"
            : dCompare.toSeconds() === dNow.plus({days: 1}).toSeconds() ? "Tomorrow"
            : dCompare.toFormat("ccc (LLL d)");
    }

    const getDateOptions = (): Array<IOption<string>> =>
        pipe(
            props.form.edited.available_times,
            array.map((iso) => DateTime.fromISO(iso).setZone("Europe/London").toISODate()),
            array.uniq(string.Eq),
            array.sortBy([contramap((isoDate: string) => DateTime.fromISO(isoDate).toJSDate())(Ord)]),
            array.map<string, IOption<string>>((isoDate) => ({
                value: isoDate,
                label: getDateOptionLabelForIsoDate(isoDate) 
            })),
        );

    const getTimeOptionsForSelectedDate = () =>
        selectedDate === null ? []
        : pipe(
            props.form.edited.available_times,
            array.filter((isoDateTime) => DateTime.fromISO(isoDateTime).setZone("Europe/London").toISODate() === selectedDate),
            array.uniq(string.Eq),
            array.sortBy([contramap((isoDateTime: string) => DateTime.fromISO(isoDateTime).toJSDate())(Ord)]),
            array.map<string, IOption<string>>((isoDateTime) => ({
                value: isoDateTime,
                label: DateTime.fromISO(isoDateTime).setZone("Europe/London").toFormat("h:mm a"),
            })),
        );

    return (
        <>
            <FrontCondensedRadioQuestions
                value={selectedDate}
                label="On which day?"
                displayValidationErrorsIfPresent={props.displayValidationErrorsIfPresent}
                options={getDateOptions()}
                onChange={(isoDate) => {
                    props.onChange(null);
                    setSelectedDate(isoDate);
                }}
            />

            {/* AT WHAT TIME? */}
            {selectedDate && <FrontCondensedDropdownQuestion
                label="At what time?"
                value={props.value || ""}
                options={getTimeOptionsForSelectedDate()}
                wrapInFormRow={true}
                displayValidationErrorsIfPresent={props.displayValidationErrorsIfPresent}
                onChange={(value) => props.onChange(value || null)}
            />}
        </>
    );
};
