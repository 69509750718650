import React from "react";

interface IMapProps {
    address: string;
    latLng?: [number, number];
}

class Map extends React.Component<React.PropsWithChildren<IMapProps>> {
    public render (): JSX.Element {
        return (
            <iframe
                src={
                    `https://www.google.com/maps/embed/v1/place?key=${
                        encodeURIComponent(env.REACT_APP_GOOGLE_API_KEY || "")
                    }&q=${
                        encodeURIComponent(this.props.address)
                    }&zoom=15${
                        this.props.latLng ? `&center=${this.props.latLng[0]},${this.props.latLng[1]}`
                        : ""
                    }`
                }
                width="100%"
                height="100%"
                frameBorder={0}
                className="mapComponent__frame"
            />
        );
    }
}

export default Map;
