import { tap, switchMap } from "rxjs/operators";
import { formOperation } from "../../wrappers/formOperation";
import { TActionsDefinitionsList } from "./TAction";
import { action, routeAction } from "./actionFunctions";
import { TTriageEmailMarkAsResolvedForm, TriageForm } from "../../../../domain/codecs/form/TriageForm";
import { Observable, forkJoin } from "rxjs";
import { 
    createTriageAssignToCaseAndHandler$,
    createTriageAssignToCaseAndMe$,
    createTriageAssignToCaseAndResolve$,
    createTriageAssignToCaseAndStaff$,
    createTriageLoadMore$,
    createTriageOpenEmail$,
    createTriageResolve$,
    createTriageSearchCase$,
    createTriageAssignToPerson$,
    triageReload$,
} from "./crmTriageActions";
import { requireExhaustive } from "../../../../shared/src/util";

export const actions: TActionsDefinitionsList = [
    routeAction("VIEW_CRM_LEGAL_INBOX", (obs$, lens, set, get) => {
        obs$.pipe(
            tap(() => set(lens.inbox_assignee_page.set({
                resolved: {
                    ...TriageForm.newDefault(),
                    edited: {
                        offset: 0,
                        limit: 10,
                    },
                },
                unresolved: {
                    ...TriageForm.newDefault(),
                    edited: {
                        offset: 0,
                        limit: 10,
                    },
                },
                confidential: {
                    ...TriageForm.newDefault(),
                    edited: {
                        offset: 0,
                        limit: 10,
                    },
                },
                untriaged: {
                    ...TriageForm.newDefault(),
                    edited: {
                        offset: 0,
                        limit: 10,
                    },
                },
                active_type_visible: "UNRESOLVED",
            }))),
            switchMap(() =>
                forkJoin([
                    formOperation("GetAssigneeResolvedEmails", lens.inbox_assignee_page.resolved.get()(get())),
                    formOperation("GetAssigneeUnresolvedEmails", lens.inbox_assignee_page.unresolved.get()(get())),
                    formOperation("GetConfidentialEmails", lens.inbox_assignee_page.confidential.get()(get())),
                    formOperation("GetUserUntriagedEmails", lens.inbox_assignee_page.untriaged.get()(get())),
                ])
            ),
            tap(([resolved, unresolved, confidential, untriaged]) => set(lens.inbox_assignee_page.set({
                resolved,
                unresolved,
                confidential,
                untriaged,
                active_type_visible: "UNRESOLVED",
            }))),
        ).subscribe();
    }),

    // ACTIVE TYPE VISIBLE CHANGE
    action("TRIAGE_ASSIGNEE_ACTIVE_TYPE_VISIBLE_CHANGE", (obs$, lens, setState, getState) => {
        obs$.pipe(
            tap((activeType) => setState(lens.inbox_assignee_page.active_type_visible.set(activeType))),
            switchMap((activeType) =>
                activeType === "UNRESOLVED" ? triageReload$(lens.inbox_assignee_page.unresolved, "GetAssigneeUnresolvedEmails", setState, getState)
                : activeType === "RESOLVED" ? triageReload$(lens.inbox_assignee_page.resolved, "GetAssigneeResolvedEmails", setState, getState)
                : activeType === "CONFIDENTIAL" ? triageReload$(lens.inbox_assignee_page.confidential, "GetConfidentialEmails", setState, getState)
                : activeType === "UNTRIAGED" ? triageReload$(lens.inbox_assignee_page.untriaged, "GetUserUntriagedEmails", setState, getState)
                : requireExhaustive(activeType),
            ),
        ).subscribe();
    }),

    // OPEN EMAIL
    action("TRIAGE_ASSIGNEE_UNRESOLVED_OPEN_EMAIL", (obs$, lens, setState, getState) => {
        createTriageOpenEmail$(obs$, lens.inbox_assignee_page.unresolved, setState, getState).subscribe();
    }),
    action("TRIAGE_ASSIGNEE_RESOLVED_OPEN_EMAIL", (obs$, lens, setState, getState) => {
        createTriageOpenEmail$(obs$, lens.inbox_assignee_page.resolved, setState, getState).subscribe();
    }),
    action("TRIAGE_ASSIGNEE_UNTRIAGED_OPEN_EMAIL", (obs$, lens, setState, getState) => {
        createTriageOpenEmail$(obs$, lens.inbox_assignee_page.untriaged, setState, getState).subscribe();
    }),
    action("TRIAGE_ASSIGNEE_CONFIDENTIAL_OPEN_EMAIL", (obs$, lens, setState, getState) => {
        createTriageOpenEmail$(obs$, lens.inbox_assignee_page.confidential, setState, getState)
            .pipe(
                // SET THE EMAIL AS READ
                tap((form) => setState(lens.inbox_assignee_page.confidential.children.emails.where((f) => f.edited.id === form.edited.id).children.is_read.set(true))),
            )
            .subscribe();
    }),

    // LOAD MORE
    action("TRIAGE_ASSIGNEE_UNRESOLVED_LOAD_MORE", (obs$, lens, setState, getState) => {
        createTriageLoadMore$(obs$, lens.inbox_assignee_page.unresolved, "GetAssigneeUnresolvedEmails", setState, getState).subscribe();
    }),
    action("TRIAGE_ASSIGNEE_RESOLVED_LOAD_MORE", (obs$, lens, setState, getState) => {
        createTriageLoadMore$(obs$, lens.inbox_assignee_page.resolved, "GetAssigneeResolvedEmails", setState, getState).subscribe();
    }),
    action("TRIAGE_ASSIGNEE_UNTRIAGED_LOAD_MORE", (obs$, lens, setState, getState) => {
        createTriageLoadMore$(obs$, lens.inbox_assignee_page.untriaged, "GetUserUntriagedEmails", setState, getState).subscribe();
    }),
    action("TRIAGE_ASSIGNEE_CONFIDENTIAL_LOAD_MORE", (obs$, lens, setState, getState) => {
        createTriageLoadMore$(obs$, lens.inbox_assignee_page.confidential, "GetConfidentialEmails", setState, getState).subscribe();
    }),

    // SEARCH CASE
    action("TRIAGE_ASSIGNEE_UNRESOLVED_SEARCH_CASE", (obs$, lens, setState) => {
        createTriageSearchCase$(obs$, lens.inbox_assignee_page.unresolved.children.emails, setState).subscribe();
    }),
    action("TRIAGE_ASSIGNEE_RESOLVED_SEARCH_CASE", (obs$, lens, setState) => {
        createTriageSearchCase$(obs$, lens.inbox_assignee_page.resolved.children.emails, setState).subscribe();
    }),
    action("TRIAGE_ASSIGNEE_UNTRIAGED_SEARCH_CASE", (obs$, lens, setState) => {
        createTriageSearchCase$(obs$, lens.inbox_assignee_page.untriaged.children.emails, setState).subscribe();
    }),

    // ASSIGN CASE AND ME
    action("TRIAGE_ASSIGNEE_UNRESOLVED_ASSIGN_TO_CASE_AND_ME", (obs$, lens, setState, getState) => {
        createTriageAssignToCaseAndMe$(obs$, lens.inbox_assignee_page.unresolved, setState, getState).subscribe();
    }),
    action("TRIAGE_ASSIGNEE_UNTRIAGED_ASSIGN_TO_CASE_AND_ME", (obs$, lens, setState, getState) => {
        createTriageAssignToCaseAndMe$(obs$, lens.inbox_assignee_page.untriaged, setState, getState).subscribe();
    }),

    // ASSIGN CASE AND OTHER STAFF
    action("TRIAGE_ASSIGNEE_UNRESOLVED_ASSIGN_TO_CASE_AND_STAFF", (obs$, lens, setState, getState) => {
        createTriageAssignToCaseAndStaff$(obs$, lens.inbox_assignee_page.unresolved, setState, getState).subscribe();
    }),
    action("TRIAGE_ASSIGNEE_UNTRIAGED_ASSIGN_TO_CASE_AND_STAFF", (obs$, lens, setState, getState) => {
        createTriageAssignToCaseAndStaff$(obs$, lens.inbox_assignee_page.untriaged, setState, getState).subscribe();
    }),

    // ASSIGN CASE AND HANDLER
    action("TRIAGE_ASSIGNEE_UNRESOLVED_ASSIGN_TO_CASE_AND_HANDLER", (obs$, lens, setState, getState) => {
        createTriageAssignToCaseAndHandler$(obs$, lens.inbox_assignee_page.unresolved, setState, getState).subscribe();
    }),
    action("TRIAGE_ASSIGNEE_UNTRIAGED_ASSIGN_TO_CASE_AND_HANDLER", (obs$, lens, setState, getState) => {
        createTriageAssignToCaseAndHandler$(obs$, lens.inbox_assignee_page.untriaged, setState, getState).subscribe();
    }),

    // ASSIGN CASE AND RESOLVE
    action("TRIAGE_ASSIGNEE_UNRESOLVED_ASSIGN_TO_CASE_AND_RESOLVE", (obs$, lens, setState, getState) => {
        createTriageAssignToCaseAndResolve$(obs$, lens.inbox_assignee_page.unresolved, setState, getState).subscribe();
    }),
    action("TRIAGE_ASSIGNEE_UNTRIAGED_ASSIGN_TO_CASE_AND_RESOLVE", (obs$, lens, setState, getState) => {
        createTriageAssignToCaseAndResolve$(obs$, lens.inbox_assignee_page.untriaged, setState, getState).subscribe();
    }),

    // ASSIGN TO PERSON
    action("TRIAGE_ASSIGNEE_UNRESOLVED_ASSIGN_TO_PERSON", (obs$, lens, setState, getState) => {
        createTriageAssignToPerson$(obs$, lens.inbox_assignee_page.unresolved, setState, getState).subscribe();
    }),
    action("TRIAGE_ASSIGNEE_UNTRIAGED_ASSIGN_TO_PERSON", (obs$, lens, setState, getState) => {
        createTriageAssignToPerson$(obs$, lens.inbox_assignee_page.untriaged, setState, getState).subscribe();
    }),

    // RESOLVE EMAIL
    action("TRIAGE_ASSIGNEE_RESOLVE", (obs$, lens, setState, getState) => {
        createTriageResolve$(obs$, lens.inbox_assignee_page.unresolved, setState, getState).subscribe();
    }),
    // RESOLVE EMAIL - UPDATE CHASE MODE FORM
    action("TRIAGE_ASSIGNEE_RESOLVE_FORM_UPDATE_CHASE_MODE_FORM", (obs$: Observable<TTriageEmailMarkAsResolvedForm>, lens, setState, getState) => {
        obs$.pipe(
            tap((payload) => setState(lens.inbox_assignee_page.unresolved.children.emails
                .where((f) => f.edited.id === payload.edited.email_id)
                .children.detailed_email_form.children.mark_as_resolved_form
                .set({
                    ...payload,
                    status: "requiresSubmission",
                }),
            ))
        ).subscribe()
    })
];
