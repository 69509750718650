import React from "react";
import { isAnyRecordCodec, isListOfStrings, TAnyCodec, TAnyListCodec, TAnyRecordCodec, TTypeOfNewDefault } from "../../../../shared/src/codecs/codec";
import CRMInputLabelAndErrorWrapComponent from "../CRMInputLabelAndErrorWrapComponent/CRMInputLabelAndErrorWrapComponent";
import { array, option, record } from "fp-ts";
import { pipe } from "fp-ts/lib/function";
import { CRMSpacingRow } from "../CRMSpacingRow/CRMSpacingRow";
import { CRMSpacer } from "../CRMSpacer/CRMSpacer";
import { CRMSpacingColumn } from "../CRMSpacingColumn/CRMSpacingColumn";
import { CRMTitleSubSection } from "../CRMTitleSubSection/CRMTitleSubSection";
import { TAnyIntersectionCodec } from "../../../../shared/src/codecs/types/intersection";
import { 
    isUnionOfLiterals,
    isUnionOfNullAndBoolean,
    isUnionOfNullAndCurrencyInteger,
    isUnionOfNullAndDate,
    isUnionOfNullAndDateTime,
    isUnionOfNullAndDecimal,
    isUnionOfNullAndDeferDateTime,
    isUnionOfNullAndInteger,
    isUnionOfNullAndOneOtherType,
    TAnyUnionCodec
} from "../../../../shared/src/codecs/types/union";
import { TAnyLiteralCodec } from "../../../../shared/src/codecs/types/literal";
import { doesErrorKeyExist, TError } from "../../../../shared/src/codecs/errors";
import { CRMMultiSelectDropdownComponent } from "../CRMMultiSelectDropdownComponent/CRMMultiSelectDropdownComponent";
import { requireExhaustive, snakeCaseToCopyText } from "../../../../shared/src/util";
import { CRMDateTimeOrNullYesNoRadioFormInput } from "../CRMFormInputs/CRMDateTimeOrNullYesNoRadioFormInput";
import { CRMTelFormInput } from "../CRMFormInputs/CRMTelFormInput";
import { CRMNumericStringFormInput } from "../CRMFormInputs/CRMNumericStringFormInput";
import { CRMEmailFormInput } from "../CRMFormInputs/CRMEmailFormInput";
import { CRMTextFormInput } from "../CRMFormInputs/CRMTextFormInput";
import { CRMTextAreaFormInput } from "../CRMFormInputs/CRMTextAreaFormInput";
import { CRMDecimalFormInput } from "../CRMFormInputs/CRMDecimalFormInput";
import { CRMPositiveDecimalFormInput } from "../CRMFormInputs/CRMPositiveDecimalFormInput";
import { CRMYesNoBooleanRadioFormInput } from "../CRMFormInputs/CRMYesNoBooleanRadioFormInput";
import { CRMCalendarDateWithoutTimeFormInput } from "../CRMFormInputs/CRMCalendarDateWithoutTimeFormInput";
import { CRMCalendarDateTimeFormInput } from "../CRMFormInputs/CRMCalendarDateTimeFormInput";
import { CRMDateWithoutTimeOrNullYesNoRadioFormInput } from "../CRMFormInputs/CRMDateWithoutTimeOrNullYesNoRadioFormInput";
import { CRMAdditiveArrayFormInput } from "../CRMFormInputs/CRMAdditiveArrayFormInput";
import { TCRMFormInput } from "../CRMFormInputs/CRMFormInputTypes";
import { CRMRadioButtonsFormInput } from "../CRMFormInputs/CRMRadioButtonsFormInput";
import { CRMDeferInputWithLabelFormInput } from "../CRMFormInputs/CRMDeferInputWithLabelFormInput";
import { CRMDeferInputWithoutLabelFormInput } from "../CRMFormInputs/CRMDeferInputWithoutLabelFormInput";
import { CRMYesNoBooleanWithClearRadioFormInput } from "../CRMFormInputs/CRMYesNoBooleanWithClearRadioFormInput";
import { CRMDropdownFormInput } from "../CRMFormInputs/CRMDropdownFormInput";
import { CRMCalendarDateTimeWithClearFormInput } from "../CRMFormInputs/CRMCalendarDateTimeWithClearFormInput";
import { CRMCalendarDateWithoutTimeWithClearFormInput } from "../CRMFormInputs/CRMCalendarDateWithoutTimeWithClearFormInput";
import { CRMCurrencyFormInput } from "../CRMFormInputs/CRMCurrencyFormInput";
import { Text } from "../BuildingBlocks/Text";
import { CRMPasswordFormInput } from "../CRMFormInputs/CRMPasswordFormInput";

type TCRMCodecEditFormComponentProps<C extends TAnyCodec> = {
    propName?: string;
    codec: C,
    model: TTypeOfNewDefault<C>,
    validationErrors: TError,
    onChange: (m: TTypeOfNewDefault<C>) => void,
    columns?: number;
    showDeferDateLabels?: boolean;
    disabled?: boolean;
    overrideComps?: {
        [K in keyof TTypeOfNewDefault<C>]?: TCRMFormInput<TTypeOfNewDefault<C>[K]>
    }
    hideFields?: Array<keyof TTypeOfNewDefault<C> extends string ? keyof TTypeOfNewDefault<C> : never>
};

const CRMCodecEditFormComponent = <C extends TAnyCodec>(props: TCRMCodecEditFormComponentProps<C>): JSX.Element => {
    return (
        <CRMSpacingColumn spacing="medium">
            {pipe(
                getListOfFormComponents({...props, showDeferDateLabels: props.showDeferDateLabels ?? false, inputDisabled: props.disabled}),
                array.chunksOf(props.columns || 1),
                array.mapWithIndex((index, components) =>
                    <CRMSpacingRow
                        key={index}
                        spacing="medium"
                        alignItems="flex-start"
                        childSize={getSpacingChildSizeByColumns(props.columns || 1)}
                    >
                        {
                            pipe(
                                components,
                                array.mapWithIndex((i, component) =>
                                    <React.Fragment key={i}>
                                        {component}
                                    </React.Fragment>
                                )
                            )
                        }
                    </CRMSpacingRow>
                ),
            )}
        </CRMSpacingColumn>
    );
}

export const CRMCodecEditForm = React.memo(CRMCodecEditFormComponent) as typeof CRMCodecEditFormComponent;

const getListOfFormComponents = <C extends TAnyCodec>(props: {
    propName?: string;
    codec: C,
    model: TTypeOfNewDefault<C>,
    validationErrors: TError,
    onChange: (m: TTypeOfNewDefault<C>) => void,
    showDeferDateLabels: boolean,
    inputDisabled?: boolean,
    overrideComps?: {
        [K in keyof TTypeOfNewDefault<C>]?: TCRMFormInput<TTypeOfNewDefault<C>[K]>
    },
    hideFields?: Array<string>
}): Array<JSX.Element> => {
    switch (props.codec.type) {
        // TEXT INPUT - GENERIC
        case "StringCodec":
        case "NonEmptyStringCodec":
        case "RegexCodec":
            return [
                <CRMTextFormInput
                    label={props.propName ? snakeCaseToCopyText(props.propName) : ""}
                    model={props.model as string}
                    onChange={props.onChange as (val: string) => void}
                    disabled={props.inputDisabled}
                    displayError={doesErrorKeyExist(`edited.${props.propName}`, props.validationErrors)}
                />
            ]
        case "PasswordCodec":
            return [
                <CRMPasswordFormInput
                    label={props.propName ? snakeCaseToCopyText(props.propName) : ""}
                    model={props.model as string}
                    onChange={props.onChange as (val: string) => void}
                    disabled={props.inputDisabled}
                    displayError={doesErrorKeyExist(`edited.${props.propName}`, props.validationErrors)}
                />
            ]
        case "PostcodeCodec":
            return [
                <CRMTextFormInput
                    label={props.propName ? snakeCaseToCopyText(props.propName) : ""}
                    model={props.model as string}
                    onChange={props.onChange as (val: string) => void}
                    disabled={props.inputDisabled}
                    displayError={doesErrorKeyExist(`edited.${props.propName}`, props.validationErrors)}
                />
            ]
        // TEXT INPUT NUMERIC
        case "DecimalCodec":
            return [
                <CRMDecimalFormInput
                    label={props.propName ? snakeCaseToCopyText(props.propName) : ""}
                    model={props.model as number}
                    onChange={props.onChange as (s: number | null) => void}
                    disabled={props.inputDisabled}
                    displayError={doesErrorKeyExist(`edited.${props.propName}`, props.validationErrors)}
                />
            ]
        case "PositiveDecimalCodec":
            return [
                <CRMPositiveDecimalFormInput
                    label={props.propName ? snakeCaseToCopyText(props.propName) : ""}
                    model={props.model as number}
                    onChange={props.onChange as (s: number | null) => void}
                    disabled={props.inputDisabled}
                    displayError={doesErrorKeyExist(`edited.${props.propName}`, props.validationErrors)}
                />
            ]
        case "CurrencyIntegerCodec":
            return [
                <CRMCurrencyFormInput 
                    label={props.propName ? snakeCaseToCopyText(props.propName) : ""}
                    model={props.model as number | null}
                    onChange={props.onChange as (v: number | null) => void}
                    disabled={props.inputDisabled}
                    displayError={doesErrorKeyExist(`edited.${props.propName}`, props.validationErrors)}
                />
            ]

        // TEXT INPUT - DISABLED
        case "LiteralCodec":
        case "UuidCodec":
            return [
                <CRMTextFormInput
                    label={props.propName ? snakeCaseToCopyText(props.propName) : ""}
                    model={props.model as string}
                    onChange={props.onChange as (val: string) => void}
                    disabled={true}
                    displayError={doesErrorKeyExist(`edited.${props.propName}`, props.validationErrors)}
                />
            ]

        // TEXT INPUT - EMAIL
        case "EmailCodec":
            return [
                <CRMEmailFormInput
                    label={props.propName ? snakeCaseToCopyText(props.propName) : ""}
                    model={props.model as string}
                    onChange={props.onChange as (val: string) => void}
                    disabled={props.inputDisabled}
                    displayError={doesErrorKeyExist(`edited.${props.propName}`, props.validationErrors)}
                />
            ]

        // TEXT INPUT - NUMBER
        case "IntegerCodec":
        case "PositiveIntegerCodec":
            return [
                <CRMNumericStringFormInput
                    label={props.propName ? snakeCaseToCopyText(props.propName) : ""}
                    model={(props.model as number) === 0 ? "" : (props.model as number).toString()}
                    onChange={(s: string) =>  props.onChange(s === "" ? 0 as TTypeOfNewDefault<C> : parseInt(s) as TTypeOfNewDefault<C>)}
                    disabled={props.inputDisabled}
                    displayError={doesErrorKeyExist(`edited.${props.propName}`, props.validationErrors)}
                />
            ]

        // TEXT INPUT - NUMBER
        case "PhoneNumberCodec":
            return [
                <CRMTelFormInput
                    label={props.propName ? snakeCaseToCopyText(props.propName) : ""}
                    model={props.model as string}
                    onChange={props.onChange as (val: string) => void}
                    disabled={props.inputDisabled}
                    displayError={doesErrorKeyExist(`edited.${props.propName}`, props.validationErrors)}
                />
            ]

        // TEXTAREA 
        case "LongStringCodec":
            return [
                <CRMTextAreaFormInput 
                    label={props.propName ? snakeCaseToCopyText(props.propName) : ""}
                    model={props.model as string}
                    onChange={props.onChange as (val: string) => void}
                    disabled={props.inputDisabled}
                    displayError={doesErrorKeyExist(`edited.${props.propName}`, props.validationErrors)}
                />
            ]
            
        // RADIO INPUT
        case "BooleanCodec":
            return [
                <CRMYesNoBooleanRadioFormInput
                    label={props.propName ? snakeCaseToCopyText(props.propName) : ""}
                    model={props.model as boolean}
                    onChange={props.onChange as (val: boolean) => void}
                    disabled={props.inputDisabled}
                    displayError={doesErrorKeyExist(`edited.${props.propName}`, props.validationErrors)}
                />
            ]

        // CALENDAR INPUT
        case "DateCodec":
            return [
                <CRMCalendarDateWithoutTimeFormInput
                    label={props.propName ? snakeCaseToCopyText(props.propName) : ""}
                    model={props.model as string}
                    onChange={props.onChange as (val: string) => void}
                    disabled={props.inputDisabled}
                    displayError={doesErrorKeyExist(`edited.${props.propName}`, props.validationErrors)}
                />
            ]
        
        // CALENDAR INPUT - LOCAL TIME
        case "DateTimeCodec":
            return [
                <CRMCalendarDateTimeFormInput
                    label={props.propName ? snakeCaseToCopyText(props.propName) : ""}
                    model={props.model as string}
                    onChange={props.onChange as (val: string) => void}
                    disabled={props.inputDisabled}
                    displayError={doesErrorKeyExist(`edited.${props.propName}`, props.validationErrors)}
                />
            ]

        case "DateOrNullAsBooleanCodec":
            return [
                <CRMDateWithoutTimeOrNullYesNoRadioFormInput 
                    label={props.propName ? snakeCaseToCopyText(props.propName) : ""}
                    model={props.model as string | null}
                    onChange={props.onChange as (val: string | null) => void}
                    disabled={props.inputDisabled}
                    displayError={doesErrorKeyExist(`edited.${props.propName}`, props.validationErrors)}
                />
            ]

        case "DateTimeOrNullAsBooleanCodec":
            return [<CRMDateTimeOrNullYesNoRadioFormInput
                label={props.propName ? snakeCaseToCopyText(props.propName) : ""}
                model={props.model as string | null}
                onChange={props.onChange as (val: string | null) => void}
                disabled={props.inputDisabled}
                displayError={doesErrorKeyExist(`edited.${props.propName}`, props.validationErrors)}
            />];

        case "RequiredCodec":
        case "PartialCodec":
        case "RequiredFlatOverloadedCodec":
            return renderRecordForm(
                props.propName,
                props.codec, 
                props.model, 
                props.onChange,
                props.validationErrors,
                props.showDeferDateLabels,
                props.inputDisabled,
                props.overrideComps,
                props.hideFields
            );
        
        case "ArrayCodec":
        case "UniqueArrayCodec":
        case "NonEmptyArrayCodec":
        case "FixedLengthArrayCodec":
            return renderArrayForm(
                props.propName || "",
                props.codec,
                props.model as Array<unknown>,
                props.onChange as (m: unknown[]) => void,
                props.validationErrors,
                props.showDeferDateLabels,
                props.inputDisabled,
            );
        
        case "OverloadCodec":
        case "CustomCodec":
            return [
                <CRMCodecEditForm
                    propName={props.propName}
                    codec={props.codec.payload.codec as C}
                    model={props.model}
                    validationErrors={props.validationErrors}
                    onChange={props.onChange}
                    disabled={props.inputDisabled}
                />
            ];

        case "IntersectionCodec":
            return renderIntersectionForm(
                props.propName,
                props.codec,
                props.model,
                props.onChange,
                props.validationErrors,
                props.showDeferDateLabels,
                props.inputDisabled,
            );
        
        case "UnionCodec":
            return renderUnionForm(
                props.propName,
                props.codec,
                props.model,
                props.onChange,
                props.validationErrors,
                props.showDeferDateLabels ?? false,
                props.inputDisabled,
            );
        // Probably should support
        case "DateMonthCodec":
        case "FutureDateTimeCodec":
        case "IntegerFromStringCodec":
        case "StringCappedCodec":
            return [<Text color="#FF0000">THIS CODEC IS NOT SUPPORTED IF NEEDED IMPLEMENT IN CRMCodecEditForm.tsx</Text>]
        // Intentionally not supporting
        case "DeferDateTimeCodec":
        case "BooleanFromStringCodec":
        case "MapCodec":
        case "RequiredFromJsonCodec":
        case "TryCatchCodec":
        case "ErrorCodeCodec":
        case "ExactNumberCodec":
        case "FalseCodec":
        case "FileIOCodec":
        case "FormCodec":
        case "FormEditableCodec":
        case "FormIOCodec":
        case "NullCodec":
        case "TrueCodec":
        case "TupleCodec":
        case "UndefinedCodec":
        case "UnknownCodec":
            return []; // LATER handle
        default: 
            return requireExhaustive(props.codec); 
    };
}

const renderRecordForm = <C extends TAnyRecordCodec, M extends TTypeOfNewDefault<C>>(
    propName: string | undefined,
    codec: C,
    model: M,
    onChange: (m: M) => void,
    validationErrors: TError,
    showDeferDateLabels: boolean,
    inputDisabled: boolean = false,
    overrideComps?: {
        [K in keyof M]?: TCRMFormInput<M[K]>
    },
    hideFields?: Array<string>
) =>
    pipe(
        codec.payload,
        (p) => Object.entries(p),
        (pairs) => pairs.map(([innerPropName, innerCodec]) => {

            if (hideFields && hideFields.includes(innerPropName)) {
                return [];
            }

            // Apply the component override for record types
            if (overrideComps && overrideComps[innerPropName]) {
                return [
                    overrideComps[innerPropName]({
                        onChange: (prop: unknown) => {
                            return onChange({
                                ...model as {},
                                [innerPropName]: prop
                            } as M);
                        },
                        model: model[innerPropName],
                        validationErrors,
                        disabled: inputDisabled,
                        label: snakeCaseToCopyText(innerPropName)
                    }) as JSX.Element
                ];
            }
            return getListOfFormComponents({
                propName: innerPropName,
                codec: innerCodec,
                model: (model as Record<string, TTypeOfNewDefault<typeof innerCodec>>)[innerPropName],
                validationErrors,
                onChange: (innerV) => onChange({
                    ...pipe(
                        codec.payload,
                        record.keys,
                        (keys) => pipe(
                            model as Record<string, unknown>,
                            record.filterWithIndex((i) => keys.includes(i)),
                        ),
                    ),
                    [innerPropName]: innerV
                } as M),
                showDeferDateLabels: showDeferDateLabels,
                inputDisabled,
            });
        }),
        array.flatten
    );

const renderIntersectionForm = <C extends TAnyIntersectionCodec, M extends TTypeOfNewDefault<C>>(
    propName: string | undefined,
    codec: C,
    model: M,
    onChange: (m: M) => void,
    validationErrors: TError,
    showDeferDateLabels: boolean,
    inputDisabled: boolean = false
) => {
    return isAnyRecordCodec(codec.payload[0])
        ? pipe(
            codec.payload.map((innerCodec) => getListOfFormComponents({
                codec: innerCodec,
                model: model,
                validationErrors,
                onChange: (v) => onChange(v as M),
                showDeferDateLabels: showDeferDateLabels,
                inputDisabled,
            })),
            array.flatten
        )
        : getListOfFormComponents({
            propName,
            codec: codec.payload[0],
            model: model,
            validationErrors,
            onChange: (v) => onChange(v as M),
            showDeferDateLabels: showDeferDateLabels,
            inputDisabled,
        });
}

const renderArrayForm = <C extends TAnyListCodec, M extends TTypeOfNewDefault<C>>(
    propName: string, 
    codec: C,
    model: M, 
    onChange: (m: M) => void,
    validationErrors: TError,
    showDeferDateLabels: boolean,
    inputDisabled: boolean = false
) => {
    if (codec.payload.codec.type === "UnionCodec" && isUnionOfLiterals(codec.payload.codec)) {
        return [(
            <CRMInputLabelAndErrorWrapComponent label={snakeCaseToCopyText(propName || "")}>
                <CRMMultiSelectDropdownComponent
                    options={codec.payload.codec.payload.map((innerCodec) => ({
                        value: (innerCodec as TAnyLiteralCodec).payload,
                        label: snakeCaseToCopyText((innerCodec as TAnyLiteralCodec).payload)
                    }))}
                    value={model as string[]}
                    onChange={(v) => onChange(v as M)}
                    displayError={doesErrorKeyExist(`edited.${propName}`, validationErrors)}
                    disabled={inputDisabled}
                />
            </CRMInputLabelAndErrorWrapComponent>
        )];
    }

    if (isListOfStrings(codec)) {
        return [
            <CRMAdditiveArrayFormInput 
                label={snakeCaseToCopyText(propName)}
                displayError={doesErrorKeyExist(`edited.${propName}`, validationErrors)}
                model={model as Array<string>}
                onChange={onChange as (value: Array<string>) => void}
                disabled={inputDisabled}
            />
        ]
    }

    return (
        (model as Array<unknown>).map((innerModel, i) => 
            <div key={i}>
                <CRMTitleSubSection>{`${propName} ${i + 1}`}</CRMTitleSubSection>
                <CRMSpacer size="medium" />
                <CRMSpacingColumn spacing="medium">
                    {
                        getListOfFormComponents({
                            codec: (codec as TAnyListCodec).payload.codec,
                            model: innerModel,
                            validationErrors,
                            onChange: (innerV) => {
                                const m = [...model];
                                m[i] = innerV;
                                onChange(m as M);
                            },
                            showDeferDateLabels: showDeferDateLabels,
                            inputDisabled,
                        })
                    }
                </CRMSpacingColumn>
            </div>
        )
    );
}

const renderUnionForm = <C extends TAnyUnionCodec, M extends TTypeOfNewDefault<C>>(
    propName: string | undefined, 
    codec: C, 
    model: M, 
    onChange: (m: M) => void,
    validationErrors: TError,
    showDeferDateLabels: boolean,
    inputDisabled: boolean = false
) => {
    if (isUnionOfLiterals(codec)) {
        return codec.payload.length >= 4 ?
        [
            <CRMDropdownFormInput
                label={propName ? snakeCaseToCopyText(propName) : ""}
                model={model as string}
                onChange={onChange as (val: string) => void}
                disabled={inputDisabled}
                displayError={doesErrorKeyExist(`edited.${propName}`, validationErrors)}
                codec={codec}
            />
        ]
        : [
            <CRMRadioButtonsFormInput 
                label={propName ? snakeCaseToCopyText(propName) : ""}
                model={model as string}
                onChange={onChange as (val: string) => void}
                disabled={inputDisabled}
                displayError={doesErrorKeyExist(`edited.${propName}`, validationErrors)}
                codec={codec}
            />
        ]
    }

    if (isUnionOfNullAndDecimal(codec)) {
        return pipe(
            codec.payload,
            array.findFirst(({type}) => type === "DecimalCodec"),
            option.fold(
                () => [
                    <CRMPositiveDecimalFormInput
                        label={propName ? snakeCaseToCopyText(propName) : ""}
                        model={model as number | null}
                        onChange={onChange as (s: number | null) => void}
                        disabled={inputDisabled}
                        displayError={doesErrorKeyExist(`edited.${propName}`, validationErrors)}
                    />
                ],
                () => [
                    <CRMDecimalFormInput
                        label={propName ? snakeCaseToCopyText(propName) : ""}
                        model={model as number | null}
                        onChange={onChange as (s: number | null) => void}
                        disabled={inputDisabled}
                        displayError={doesErrorKeyExist(`edited.${propName}`, validationErrors)}
                    />
                ]
            )
        );
    }

    if (isUnionOfNullAndDeferDateTime(codec)) {
        return showDeferDateLabels ? [
            <CRMDeferInputWithLabelFormInput
                label={propName ? snakeCaseToCopyText(propName) : ""}
                model={model  as string | null}
                onChange={onChange as (v: string | null) => void}
                disabled={false}
                displayError={doesErrorKeyExist(`edited.${propName}`, validationErrors)}
            />
        ] : [<CRMDeferInputWithoutLabelFormInput
            label={propName ? snakeCaseToCopyText(propName) : ""}
            model={model  as string | null}
            onChange={onChange as (v: string | null) => void}
            disabled={false}
            displayError={doesErrorKeyExist(`edited.${propName}`, validationErrors)}
        />]
    }

    if (isUnionOfNullAndDate(codec)) {
        return [
            <CRMCalendarDateWithoutTimeWithClearFormInput
                label={propName ? snakeCaseToCopyText(propName) : ""}
                model={model as string | null}
                onChange={onChange as (v: string | null) => void}
                disabled={inputDisabled}
                displayError={doesErrorKeyExist(`edited.${propName}`, validationErrors)}
            />
        ]
    }

    if (isUnionOfNullAndDateTime(codec)) {
        return [
            <CRMCalendarDateTimeWithClearFormInput 
                label={propName ? snakeCaseToCopyText(propName) : ""}
                model={model as string | null}
                onChange={onChange as (v: string | null) => void}
                disabled={inputDisabled}
                displayError={doesErrorKeyExist(`edited.${propName}`, validationErrors)}
            />
        ]
    }

    if (isUnionOfNullAndInteger(codec)) {
        return [
            <CRMTextFormInput
                label={propName ? snakeCaseToCopyText(propName) : ""}
                model={model === null ? "" : (model as number).toString()}
                onChange={(s: string) =>  onChange(s === "" ? null as M : parseInt(s) as M)}
                disabled={inputDisabled}
                displayError={doesErrorKeyExist(`edited.${propName}`, validationErrors)}
            />
        ]
        
    }

    if (isUnionOfNullAndCurrencyInteger(codec)) {
        return [
            <CRMCurrencyFormInput 
                label={propName ? snakeCaseToCopyText(propName) : ""}
                model={model as number | null}
                onChange={onChange as (v: number | null) => void}
                disabled={inputDisabled}
                displayError={doesErrorKeyExist(`edited.${propName}`, validationErrors)}
            />
        ]
    }

    if (isUnionOfNullAndBoolean(codec)) {
        return [<CRMYesNoBooleanWithClearRadioFormInput 
            label={propName ? snakeCaseToCopyText(propName) : ""}
            model={model as boolean | null}
            onChange={onChange as (v: boolean | null) => void}
            disabled={inputDisabled}
            displayError={doesErrorKeyExist(`edited.${propName}`, validationErrors)}
        />]
    }

    if (isUnionOfNullAndOneOtherType(codec)) {
        const notNullCodec = codec.payload.filter((c) => c.type !== "NullCodec")[0];
        return getListOfFormComponents({
            propName,
            codec: notNullCodec,
            model: model === null
                ? ""
                : model,
            validationErrors,
            onChange: (v) => onChange((v === "" ? null : v) as M),
            showDeferDateLabels: showDeferDateLabels,
            inputDisabled,
        });
    }
    
    return [];
}

const getSpacingChildSizeByColumns = (columns: number) => pipe(
    array.range(1, columns),
    array.map(() => "1fr"),
    (sizeArray) => sizeArray.join(" "), 
);
