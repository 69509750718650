import React from "react";
import { TDetailedEmailForm } from "../../../../../domain/codecs/form/TriageForm";
import { TDetailedLegalEmailForTriage } from "../../../../../domain/codecs/LegalEmail";
import { contains } from "../../../../../shared/src/utilsByDomain/array";
import { formatDate, getTimeElapsedCopyText } from "../../../../../shared/src/utilsByDomain/dateTime";
import { CRMColors } from "../../../models/CRMColors";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { TPixelSize } from "../../../models/StringLiterals";
import { Flex } from "../../BuildingBlocks/Flex";
import { Background } from "../../BuildingBlocks/Background";
import { Shape } from "../../BuildingBlocks/Shape";
import { ShineShape } from "../../BuildingBlocks/ShineShape";
import { SpacingColumn } from "../../BuildingBlocks/SpacingColumn";
import { SpacingRow } from "../../BuildingBlocks/SpacingRow";
import { CRMIcon } from "../../CRMIcon/CRMIcon";
import { CRMParagraph } from "../../Simple/CRMParagraph/CRMParagraph";
import { CRMPill } from "../../Simple/CRMPill/CRMPill";
import { WeightBold } from "../../WeightBold/WeightBold";
import { WeightSemiBold } from "../../WeightSemiBold/WeightSemiBold";
import { CRMEmailSingleViewBodyAttachments } from "../CRMEmailSingleViewBodyAttachments/CRMEmailSingleViewBodyAttachments";

type TFieldName = 
    "sent_at"
    | "subject"
    | "from"
    | "to"
    | "cc"
    | "bcc"
    | "attachments"
;

type TEmailMetadataProps = {
    email: TDetailedEmailForm;
    fieldsToShow?: Array<TFieldName>;
    padding?: TPixelSize;
};

export const CRMEmailMetadata = (props: React.PropsWithChildren<TEmailMetadataProps>): JSX.Element => {

    const hasDetailedEmail = () => !!props.email.children.email.id;

    const getDetailedEmail = () => props.email.children.email;

    const shouldFieldBeShown = (fieldName: TFieldName) => 
        !!props.fieldsToShow ?
            contains(fieldName)(props.fieldsToShow)
            : true
    ;

    const isTheEmailPossiblySpoofed = () => 
        getDetailedEmail().possibly_spoofed 
        || getDetailedEmail().from_address_user_verification_status === "unverified"
    ;

    const getSentAtText = () =>
        hasDetailedEmail() ? 
            <>{formatDate(getDetailedEmail().sent_at, "DD")}, {getTimeElapsedCopyText(getDetailedEmail().sent_at)} ago</>
            : ""
    ;

    if (!hasDetailedEmail()) {
        return (
            <Placeholder />
        );
    }

    return (
        <Background
            color={CRMColors.NEUTRAL_12}
            padding={props.padding || CRMSpacing.LARGE}
        >
            <SpacingColumn spacing={CRMSpacing.TINY}>

                {shouldFieldBeShown("sent_at") &&
                    <CRMParagraph>
                        <WeightBold>Sent:</WeightBold> {getSentAtText()} 
                    </CRMParagraph>
                }
                
                {shouldFieldBeShown("subject") &&
                    <CRMParagraph>
                        <WeightBold>Subject:</WeightBold> {getDetailedEmail().subject} 
                    </CRMParagraph>
                }
                
                {shouldFieldBeShown("from") &&
                    <CRMParagraph>
                        <WeightBold>From: </WeightBold>
                            {isTheEmailPossiblySpoofed() &&
                                <FromSpoofedEmail email={getDetailedEmail()} />
                            }

                            {!isTheEmailPossiblySpoofed() &&
                                getDetailedEmail().from_address
                            }
                    </CRMParagraph>
                }

                {shouldFieldBeShown("to") &&
                    <CRMParagraph>
                        <WeightBold>To:</WeightBold> {getDetailedEmail().to_addresses.join(", ")} 
                    </CRMParagraph>
                }   

                {shouldFieldBeShown("cc") && getDetailedEmail().cc_addresses.length > 0 &&
                    <CRMParagraph>
                        <WeightBold>Cc:</WeightBold> {getDetailedEmail().cc_addresses.join(", ")} 
                    </CRMParagraph>
                }

                {shouldFieldBeShown("bcc") && getDetailedEmail().bcc_addresses.length > 0 &&
                    <CRMParagraph>
                        <WeightBold>Bcc:</WeightBold> {getDetailedEmail().bcc_addresses.join(", ")} 
                    </CRMParagraph>
                }

                {shouldFieldBeShown("attachments") && getDetailedEmail().attachments.length > 0 &&
                    <Flex>
                        <CRMParagraph>
                            <WeightBold>Attachments:</WeightBold>&nbsp;
                        </CRMParagraph>

                        <CRMEmailSingleViewBodyAttachments
                            attachments={getDetailedEmail().attachments} 
                        />
                    </Flex>
                }
            </SpacingColumn>
        </Background>
    );
};


type TFromSpoofedEmail = {
    email: TDetailedLegalEmailForTriage;
}

const FromSpoofedEmail = (props: TFromSpoofedEmail) => {
 
    const getTitle = () => 
        props.email.possibly_spoofed ?
           "This email is sent from a possibly spoofed address" 
            : "This email address should be verified but is not"
    ;

    return (
        <CRMPill
            title={getTitle()} 
            colour={props.email.possibly_spoofed ? 
                "highlights-instructional-brick-10" 
                : "highlights-pertinent-yellow-6"
            }
        >
            <SpacingRow
                spacing={CRMSpacing.TINY}
                justifyContent="space-between"
            >
                <CRMIcon
                    iconName={
                        props.email.possibly_spoofed ? 
                            "email-alert" 
                            : "email-question"
                    }
                    colour="neutral-ink"
                    size="14px"
                />

                <WeightSemiBold>
                    {props.email.from_address}
                </WeightSemiBold>
            </SpacingRow>
        </CRMPill>
    );
}


const Placeholder = () => {
    return (
        <ShineShape
            backgroundColor={CRMColors.NEUTRAL_12} 
            shineColor={CRMColors.NEUTRAL_PAPER}
            duration={2000}
            width={"100%"}
        >
            <Background padding={CRMSpacing.MEDIUM}>
                <SpacingColumn spacing={CRMSpacing.MEDIUM}>
                    <PlaceholderLine width="100px"/>
                    <PlaceholderLine width="200px"/>
                    <PlaceholderLine width="150px"/>
                    <PlaceholderLine width="100px"/>
                </SpacingColumn>
            </Background>
        </ShineShape>
    );
}

type TPlaceholderLine = {
    width: TPixelSize;
}

const PlaceholderLine = (props: TPlaceholderLine) => {
    return (
        <SpacingRow
            spacing={CRMSpacing.TINY}
        >
            <Shape
                height="10px"
                width="50px"
                color={CRMColors.NEUTRAL_8}
            />

            <Shape
                height="10px"
                width={props.width}
                color={CRMColors.NEUTRAL_8}
            />
        </SpacingRow>
    );
}