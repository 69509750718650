import * as rxjs from "rxjs";
import * as rxjsOperators from "rxjs/operators";
import { TActionObservable } from "../../state/applyActions";
import {TSetState as TSetState} from "../../state/TSetState";
import {TGetState as TGetState} from "../../state/TGetState";
import { TLensFunction } from "./lensBaseTypes";
import { TPayloadActionType } from "../../state/actions/TAction";

export const pipe =
    <A extends TPayloadActionType, T> (...lensFunctions: Array<TLensFunction<A, T, unknown>>) =>
        (obs$: TActionObservable<A, T>, getState: TGetState, setState: TSetState) =>
            obs$
                .pipe(
                    rxjsOperators.map((action) =>
                        rxjs
                            .of(
                                ...lensFunctions.map((lensFunction) =>
                                    lensFunction(
                                        rxjs.of(action),
                                        getState,
                                        setState
                                    )
                                )
                            )
                            .pipe(rxjsOperators.concatAll())
                    ),
                    rxjsOperators.concatAll(),
                );

export const pipeSubscribed =
    <A extends TPayloadActionType, T> (...lensFunctions: Array<TLensFunction<A, T, unknown>>) =>
        (obs$: TActionObservable<A, T>, getState: TGetState, setState: TSetState) =>
            pipe<A, T>(...lensFunctions)(obs$, getState, setState)
                .subscribe();
