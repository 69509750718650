import { tap, switchMap, filter } from "rxjs/operators";
import { formOperation } from "../../wrappers/formOperation";
import { TActionsDefinitionsList } from "./TAction";
import { action, routeAction } from "./actionFunctions";
import { from, Observable } from "rxjs";
import { TStateCodec } from "../../../../domain/codecs/state";
import { TCodecLens } from "../../../../shared/src/codecs/codecLens";
import { TCodecSetState, TGetCodecState } from "../applyActions";
import { QuoteRatePageForm, TQuoteRateActiveTypeVisible, TQuoteRateCreatedForm } from "../../../../domain/codecs/form/QuoteRateForm";
import { TEmptyForm } from "../../../../domain/codecs/form/EmptyForm";
import { TUuidForm } from "../../../../domain/codecs/form/UuidForm";
import { TTypeOfNewDefault } from "../../../../shared/src/codecs/codec";
import { TFormOperationCodecs } from "../../../../domain/codecs/formOperationCodecs/formOperationCodecs";

export const reload$ = (
    lens: TCodecLens<TStateCodec, TStateCodec>,
    set: TCodecSetState,
    get: TGetCodecState,
): Observable<TTypeOfNewDefault<TFormOperationCodecs["GetQuoteRates"]>> => 
    from(formOperation("GetQuoteRates", lens.quote_rates_page.data.get()(get())))
        .pipe(
            tap((response) => set(lens.quote_rates_page.data.set(response))),
        );

export const actions: TActionsDefinitionsList = [
    routeAction("VIEW_CRM_LEGAL_QUOTE_RATES", (obs$, lens, set, get) => {
        obs$.pipe(
            tap(() => set(lens.quote_rates_page.data.set(QuoteRatePageForm.newDefault()))),
            switchMap(() => reload$(lens, set, get)),
        ).subscribe();
    }),
    action("CRM_QUOTE_RATES_ACTIVE_TYPE_CHANGE", (obs$: Observable<TQuoteRateActiveTypeVisible>, lens, set, get) => {
        obs$.pipe(
            tap((payload) => set(lens.quote_rates_page.active_type_visible.set(payload))),
        ).subscribe();
    }),
    action("CRM_QUOTE_RATES_CREATE", (obs$: Observable<TEmptyForm>, lens, set, get) => {
        obs$.pipe(
            tap(() => set(lens.quote_rates_page.data.children.create_rate_form.status.set("submitting"))),
            switchMap(() => formOperation("CreateQuoteRate", lens.quote_rates_page.data.children.create_rate_form.get()(get()))),
            tap((response) => set(lens.quote_rates_page.data.children.create_rate_form.set(response))),
            filter(({status}) => status === "success"),
            switchMap(() => reload$(lens, set, get)),
            tap(() => set(lens.quote_rates_page.data.children.create_rate_form.status.set("untouched"))),
        ).subscribe();
    }),
    action("CRM_QUOTE_RATES_CHANGE", (obs$: Observable<TQuoteRateCreatedForm>, lens, set, get) => {
        obs$.pipe(
            tap((form) => set(lens.quote_rates_page.data.children.rate_changes.where((f) => f.edited.id === form.edited.id).set({
                ...form,
                status: "requiresSubmission",
            }))),
        ).subscribe();
    }),
    action("CRM_QUOTE_RATES_SAVE", (obs$: Observable<TQuoteRateCreatedForm>, lens, set, get) => {
        obs$.pipe(
            tap((form) => set(lens.quote_rates_page.data.children.rate_changes.where((f) => f.edited.id === form.edited.id).set({
                ...form,
                status: "submitting",
            }))),
            switchMap((form) => formOperation("UpdateQuoteRate", form)),
            tap((response) => set(lens.quote_rates_page.data.children.rate_changes.where((f) => f.edited.id === response.edited.id).set(response))),
            filter(({status}) => status === "success"),
            switchMap(() => reload$(lens, set, get)),
        ).subscribe();
    }),
    action("CRM_QUOTE_RATES_TOGGLE_DELETE_ON_NEXT_PUBLISH", (obs$: Observable<TUuidForm>, lens, set, get) => {
        obs$.pipe(
            tap((form) => set(lens.quote_rates_page.data.children.live_rates.where((f) => f.id === form.edited.id).set_will_be_deleted_on_next_publish_form.set({
                ...form,
                status: "submitting",
            }))),
            switchMap((form) => formOperation("ToggleDeleteOnNextPublishQuoteRate", form)),
            tap((response) => set(lens.quote_rates_page.data.children.live_rates.where((f) => f.id === response.edited.id).set_will_be_deleted_on_next_publish_form.set(response))),
            filter(({status}) => status === "success"),
            tap(() => set(lens.quote_rates_page.active_type_visible.set("RATE_CHANGES"))),
            switchMap(() => reload$(lens, set, get)),
        ).subscribe();
    }),
    action("CRM_QUOTE_RATES_REPLACE", (obs$: Observable<TUuidForm>, lens, set, get) => {
        obs$.pipe(
            tap((form) => set(lens.quote_rates_page.data.children.live_rates.where((f) => f.id === form.edited.id).set_will_be_deleted_on_next_publish_and_replace_form.set({
                ...form,
                status: "submitting",
            }))),
            switchMap((form) => formOperation("SetDeleteOnNextPublishAndReplaceQuoteRate", form)),
            tap((response) => set(lens.quote_rates_page.data.children.live_rates.where((f) => f.id === response.edited.id).set_will_be_deleted_on_next_publish_and_replace_form.set(response))),
            filter(({status}) => status === "success"),
            tap(() => set(lens.quote_rates_page.active_type_visible.set("RATE_CHANGES"))),
            switchMap(() => reload$(lens, set, get)),
        ).subscribe();
    }),
    action("CRM_QUOTE_RATES_DELETE", (obs$: Observable<TUuidForm>, lens, set, get) => {
        obs$.pipe(
            switchMap((form) => formOperation("DeleteQuoteRate", form)),
            switchMap(() => reload$(lens, set, get)),
        ).subscribe();
    }),
    action("CRM_QUOTE_RATES_PUBLISH", (obs$: Observable<TEmptyForm>, lens, set, get) => {
        obs$.pipe(
            tap(() => set(lens.quote_rates_page.data.children.publish_rates_form.status.set("submitting"))),
            switchMap((form) => formOperation("PublishQuoteRates", form)),
            tap((response) => set(lens.quote_rates_page.data.children.publish_rates_form.set(response))),
            filter(({status}) => status === "success"),
            switchMap(() => reload$(lens, set, get)),
            tap(() => set(lens.quote_rates_page.active_type_visible.set("LIVE_RATES"))),
        ).subscribe();
    }),
];
