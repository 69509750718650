import React from "react";
import { WeightMedium } from "../../../WeightMedium/WeightMedium";

export class FrontFormErrorComponent extends React.Component<{
    errorMessage: string;
}> {
    public render (): JSX.Element {
        return (
            <div className="front-form-error-component">
                <WeightMedium>{this.props.errorMessage}</WeightMedium>
            </div>
        );
    }
}
