import React from "react";
import { FrontColors } from "../../../../models/FrontColors";
import { FrontFontSizes } from "../../../../models/FrontFontSizes";
import { FrontSpacing } from "../../../../models/FrontSpacing";
import { Background } from "../../../BuildingBlocks/Background";
import UnstyledInputGeneralComponent from "../../../UnstyledInputGeneralComponent/UnstyledInputGeneralComponent";

export const FPInput = (props: React.PropsWithChildren<{
    coloursInverted?: boolean;
    flatEdge?: boolean;
    value: string;
    placeholder?: string;
    autoFocus?: boolean;
    displayError?: boolean;
    onChange: (value: string) => void;
    onEnter: () => void;
}>): JSX.Element => {
    const coloursInverted = props.coloursInverted || false;

    const flatEdge = props.flatEdge || false;

    const placeholder = props.placeholder || "";

    const displayError = props.displayError || false;

    const inputBackgroundColour =
        coloursInverted
            ? FrontColors.NEUTRAL_18
            : FrontColors.PROBATE_0;

    const borderColour =
        coloursInverted
            ? FrontColors.NEUTRAL_18
            : FrontColors.PROBATE_0;

    const inputTextColour =
        displayError ? FrontColors.ERROR_FIREBRICK_10
        : coloursInverted ? FrontColors.NEUTRAL_0
        : FrontColors.NEUTRAL_13;

    const borderRadius =
        flatEdge
            ? "5px 0px 0px 5px"
            : "5px";

    return <Background
        display="flex"
        borderRadius={borderRadius}
        borderDefinition={`solid 2px ${borderColour}`}
        color={inputBackgroundColour}
        height="100%"
        minHeight="48px"
        padding={`0px ${FrontSpacing.SMALL_3}`}
        alignItems="center"
    >
        <UnstyledInputGeneralComponent
            inputType="text"
            value={props.value}
            displayError={false}
            autoFocus={props.autoFocus || false}
            placeholder={placeholder}
            className={`fs-exclude front-probate-input__input ${
                displayError ? "front-probate-input__input--error"
                : coloursInverted ? "front-probate-input__input--inverted"
                : ""
            }`}
            style={{
                width: "100%",
                height: "100%",
                WebkitAppearance: "none",
                appearance: "none",
                background: "none",
                outline: "none",
                border: "none",
                fontSize: FrontFontSizes.SMALL,
                fontFamily: "Roboto Slab",
                color: inputTextColour,
            }}
            onChange={props.onChange}
            onPressEnterKey={props.onEnter}
        />
    </Background>;
};
