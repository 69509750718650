import React from "react";
import { CRMNoticeBoxComponent } from "../CRMNoticeBoxComponent/CRMNoticeBoxComponent";
import * as Listing3 from "../../../../domain/models/Listing3";
import { array } from "fp-ts";
import { CRMSpacer } from "../CRMSpacer/CRMSpacer";
import { CRMTooltip } from "../CRMTooltip/CRMTooltip";
import * as User4 from "../../../../domain/models/User4";
import { CRMCardOutsideLabelled } from "../CRMCardOutsideLabelled/CRMCardOutsideLabelled";
import { CRMSellerPersonComponent } from "../CRMSellerPersonComponent/CRMSellerPersonComponent";
import { pipe } from "fp-ts/lib/function";
import { DateTime } from "luxon";
import { WeightBold } from "../WeightBold/WeightBold";

type TNoticeBoxViewingArrangementsProps = {
    listing: Listing3.T;
};

export const CRMNoticeBoxViewingArrangements = (props: TNoticeBoxViewingArrangementsProps): JSX.Element => {
    const getPersonTooltipContentForUser = (user: User4.T): JSX.Element =>
        <div className="crm-notice-box-viewing-arrangements__tooltip-container">
            <CRMCardOutsideLabelled
                labelColor="highlights-positive-green-6"
            >
                <CRMSellerPersonComponent
                    user={user}
                />
            </CRMCardOutsideLabelled>
        </div>;

    const getArrangementsToDisplay = (): Array<{
        title: string;
        message: string | JSX.Element;
    }> =>
        [
            ...props.listing.property_occupied
                ? [{
                    title: "Property occupied",
                    message: <>
                        You must check with the {
                            props.listing.occupier_user
                                ? <CRMTooltip linkText="occupier">
                                    {getPersonTooltipContentForUser(props.listing.occupier_user)}
                                </CRMTooltip>
                                : "occupier"
                        } after booking
                    </>,
                }]
                : [],
            ...props.listing.preferred_viewing_times
                ? [{
                    title: "Preferred viewing times",
                    message: props.listing.preferred_viewing_times,
                }]
                : [],
            ...props.listing.key_holder === "designated_person"
                ? [{
                    title: "Designated person is the key holder",
                    message: <>
                        Reserve the keys with this {
                            props.listing.key_holder_user
                                ? <CRMTooltip linkText="person">
                                    {getPersonTooltipContentForUser(props.listing.key_holder_user)}
                                </CRMTooltip>
                                : "person"
                        } after booking
                    </>,
                }]
                : [],
            ...props.listing.viewings_conducted_by === "designated_person"
                ? [{
                    title: "Designated person takes the viewings",
                    message: <>
                        Book the viewing with {
                            props.listing.viewings_conducted_by_user
                                ? <CRMTooltip linkText="them">
                                    {getPersonTooltipContentForUser(props.listing.viewings_conducted_by_user)}
                                </CRMTooltip>
                                : "them"
                        } after handling the enquiry
                    </>,
                }]
                : [],
            ...props.listing.viewings_conducted_by === "occupier"
                ? [{
                    title: "The occupier is taking the viewings",
                    message: <>
                        Check the {
                            props.listing.occupier_user
                                ? <CRMTooltip linkText="occupier">
                                    {getPersonTooltipContentForUser(props.listing.occupier_user)}
                                </CRMTooltip>
                                : "occupier"
                        } is happy to take this viewing after handling the enquiry
                    </>,
                }]
                : [],
            ...props.listing.viewings_can_be_booked_from !== null && DateTime.fromISO(props.listing.viewings_can_be_booked_from, {zone: "Europe/London"}).toUTC() > DateTime.local().toUTC()
                ? [{
                    title: "Property not ready for viewings",
                    message: <>
                        {`The property will be ready for viewings from `}
                        <WeightBold>
                            { DateTime.fromISO(props.listing.viewings_can_be_booked_from ?? "").setZone("Europe/London").toFormat("HH:mm") === "00:00"
                                ? ""
                                : DateTime.fromISO(props.listing.viewings_can_be_booked_from ?? "").setZone("Europe/London").toFormat("hh:mma, ")}
                            { DateTime.fromISO(props.listing.viewings_can_be_booked_from ?? "").setZone("Europe/London").toFormat("ccc d MMM")}
                        </WeightBold>
                    </>,
                }]
                : [],
        ];
    return (
        getArrangementsToDisplay().length > 0 ? <CRMNoticeBoxComponent>
            <div className="crm-notice-box-viewing-arrangements__container">
                { pipe(
                    getArrangementsToDisplay(),
                    array.mapWithIndex((i, arrangement) =>
                        <div key={i}>
                            <div className="crm-notice-box-viewing-arrangements__row--title">
                                - {arrangement.title}
                            </div>
                            <div className="crm-notice-box-viewing-arrangements__row--message">
                                {arrangement.message}
                            </div>
                            {i < getArrangementsToDisplay().length - 1 && <CRMSpacer size="medium" />}
                        </div>
                    ),
                )}
            </div>
        </CRMNoticeBoxComponent> : <></>
    );
};
