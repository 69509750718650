import * as t from "io-ts";
import { nullable } from "../../shared/src/validation/basic/nullable";
import { positiveDecimal } from "../../shared/src/validation/basic/postitiveDecimal";
import { string } from "../../shared/src/validation/basic/string";
import { stringIsoUtcDateTime } from "../../shared/src/validation/basic/stringIsoUtcDateTime";
import { or } from "../../shared/src/validation/compose/or";
import { object } from "../../shared/src/validation/create/object";
import * as OfferStatus from "./OfferStatus";

export const codec = t.partial({
    status: OfferStatus.codec,
    amount: t.number,
    shared_with_seller_at: t.union([t.string, t.null]),
    conditions: t.string,
    rejected_reason: t.string,
    withdrawn_reason: t.string,
});

export type T = t.TypeOf<typeof codec>;

export const validator = object({
    status: OfferStatus.validator,
    amount: positiveDecimal,
    shared_with_seller_at: or(
        stringIsoUtcDateTime,
        nullable,
    ),
    conditions: string,
    rejected_reason: string,
    withdrawn_reason: string,
});
