import React, { useEffect, useState } from "react";
import { TRoadblockCheckListItemAutomatic, TRoadblockCheckListItemManualForm, TRoadblockForm, TRoadblockSignOffForm } from "../../../../../domain/codecs/form/RoadblockForm";
import { array, option } from "fp-ts";
import { Background } from "../../BuildingBlocks/Background";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { Sticky } from "../../BuildingBlocks/Sticky";
import { CRMSingleViewHeader } from "../../Simple/CRMSingleViewHeader/CRMSingleViewHeader";
import { CRMLoadingBar } from "../../Simple/CRMLoadingBar/CRMLoadingBar";
import { CRMBottomControlBar } from "../../CRMBottomControlBar/CRMBottomControlBar";
import { SpacingColumn } from "../../BuildingBlocks/SpacingColumn";
import { Shape } from "../../BuildingBlocks/Shape";
import { CRMColors } from "../../../models/CRMColors";
import { CRMRoadblockChatButton } from "../CRMRoadblockChatButton/CRMRoadblockChatButton";
import { groupActionsIntoMinuteClusters } from "../CRMRoadblockMessages/CRMRoadblockMessagesGroupingLogic";
import { CRMRoadblockChecklistItemAutomatic } from "../CRMRoadblockChecklistItemAutomatic/CRMRoadblockChecklistItemAutomatic";
import { CRMRoadblockChecklistItemManual } from "../CRMRoadblockChecklistItemManual/CRMRoadblockChecklistItemManual";
import { Padding } from "../../BuildingBlocks/Padding";
import { Width } from "../../BuildingBlocks/Width";
import { FontQuicksand } from "../../BuildingBlocks/FontQuicksand";
import { Text } from "../../BuildingBlocks/Text";
import { CRMFontSizes } from "../../../models/CRMFontSizes";
import { CRMRoadblockStatusLabelFromForm } from "../CRMRoadblockStatusLabel/CRMRoadblockStatusLabel";
import { Ordering } from "fp-ts/lib/Ordering";
import { TSessionUser } from "../../../../../domain/codecs/User";
import { Underline } from "../../Underline/Underline";
import { WeightBold } from "../../WeightBold/WeightBold";
import { SpacingRow } from "../../BuildingBlocks/SpacingRow";
import { CRMButtonPrimary } from "../../CRMButtonPrimary/CRMButtonPrimary";
import { CRMFlatInputGeneral } from "../../CRMFlatInputGeneral/CRMFlatInputGeneral";
import { CRMIcon } from "../../CRMIcon/CRMIcon";
import { allMatchPredicate, updateWhere } from "../../../../../shared/src/utilsByDomain/array";
import { DateTime } from "luxon";
import { onChangeFormChildren } from "../../../../../shared/src/codecs/types/form";
import { CRMRoadblockStatusLabelPopoverCancelled } from "../CRMRoadblockStatusLabelPopover/CRMRoadblockStatusLabelPopoverCancelled";
import { CRMRoadblockStatusLabelPopoverBlocked } from "../CRMRoadblockStatusLabelPopover/CRMRoadblockStatusLabelPopoverBlocked";
import { CRMRoadblockStatusLabelPopoverResolved } from "../CRMRoadblockStatusLabelPopover/CRMRoadblockStatusLabelPopoverResolved";
import { MaxWidth } from "../../BuildingBlocks/MaxWidth";
import { CRMBlockHeaderButton } from "../../CRM/CRMBlock/CRMBlockHeaderButton/CRMBlockHeaderButton";
import { getRoadblockForm } from "../CRMRoadblocksBlock/CRMRoadblockToFormMap";
import { TBlockType } from "../../../state/router/routerTypes";
import { underscoreToNormalEveryWordCapitalized } from "../../../../../shared/src/utilsByDomain/string/underscoreToNormalEveryWordCapitalized";
import { CRMFormButton } from "../../CRMFormButton/CRMFormButton";
import { CRMErrorPlaceholder } from "../../Simple/CRMErrorPlaceholder/CRMErrorPlaceholder";
import { CRMRoadblockViewChecklistPlaceholder } from "../CRMRoadblockViewChecklistPlaceholder/CRMRoadblockViewChecklistPlaceholder";
import { pipe } from "fp-ts/lib/function";

type TRoadblockViewChecklistProps = {
    sessionUser: TSessionUser;
    form: TRoadblockForm;
    onOpenChat: () => void;
    onOpenForm: (target: TBlockType) => void;
    onClose: () => void;
    onChange: (form: TRoadblockForm) => void;
    onChangeManualItem: (form: TRoadblockCheckListItemManualForm) => void;
    onSignOff: (form: TRoadblockSignOffForm) => void;
};

type TFinalCheckItem = [string, boolean];

export const CRMRoadblockViewChecklist = (props: React.PropsWithChildren<TRoadblockViewChecklistProps>): JSX.Element => {

    const [signature, setSignature] = useState("");
    const [signoffCheckList, setSignoffCheckList] = useState<Array<TFinalCheckItem>>([]);

    const Status = props.form.children.details.status;
    const IsLoading = props.form.status === "loading";
    const HasErrored = props.form.status === "failure" || props.form.status === "validationError";
    const CheckMode = (Status === "awaiting_signoff" || Status === "resolved") && props.sessionUser.can_sign_off_roadblocks ? "final" : "first";
    const AreChecksDisabled = Status === "resolved";

    const RoadblockName = underscoreToNormalEveryWordCapitalized(props.form.children.details.roadblock_type);
    const RoadblockLinkedForm = getRoadblockForm(props.form.children.details.roadblock_type);
    const MessageCount = groupActionsIntoMinuteClusters(props.form.children.actions).length;
    const ChecklistAutomatic = sortAutomaticItems(props.form.children.checklist_items_automatic);
    const ChecklistManual = sortManualItems(props.form.children.checklist_items_manual);
    const AllItemsFinalChecked = pipe(
        signoffCheckList,
        allMatchPredicate(([id, isChecked]) => isChecked)
    );
    
    const UserFullName = `${props.sessionUser.first_name} ${props.sessionUser.last_name}`;
    const UserInitials = `${props.sessionUser.first_name[0]}.${props.sessionUser.last_name[0]}`;
    const IsSubmittingSignoff = props.form.children.signoff_form.status === "submitting";
    const IsSigned = signature === UserInitials;

    useEffect(
        () => setSignoffCheckList(pipe(
            props.form.children.checklist_items_manual,
            array.map((item) => item.edited),
            (manualItems) => [manualItems, props.form.children.checklist_items_automatic],
            array.flatten,
            array.map((item) => [
                item.id, 
                Status === "resolved" ? true : false
            ]),
        )),
        [
            props.form.children.checklist_items_automatic, 
            props.form.children.checklist_items_manual
        ]
    );

    const onChangeChildren = onChangeFormChildren(props.form, props.onChange);
    
    const updateManualCheckList = (item: TRoadblockCheckListItemManualForm): Array<TRoadblockCheckListItemManualForm> => pipe(
        props.form.children.checklist_items_manual,
        updateWhere<TRoadblockCheckListItemManualForm>(
            (updateItem) => updateItem.edited.id === item.edited.id,
            () => item
        )
    );

    const toggleItem = (value: boolean) => ({
        resolved_at: value ? DateTime.utc().toISO() : null,
        resolved_by: value ? props.sessionUser.id : null,
        resolved_by_name: value ? UserFullName : null,
    });

    const getToggledManualCheckListItem = (item: TRoadblockCheckListItemManualForm, value: boolean) => ({
        ...item,
        edited: {  ...item.edited,  ...toggleItem(value) },
    });

    const onFirstCheckManual = (item: TRoadblockCheckListItemManualForm, value: boolean) => {
        let newItem = getToggledManualCheckListItem(item, value);
        onChangeChildren("checklist_items_manual")(updateManualCheckList(newItem));
        props.onChangeManualItem(newItem);
    }

    const getFinalCheckAutomaticValue = (item: TRoadblockCheckListItemAutomatic) => pipe(
        signoffCheckList,
        array.findFirst(([id]) => id === item.id),
        option.fold(
            () => false,
            ([id, isChecked]) => isChecked
        )
    );
    
    const getFinalCheckManualValue = (item: TRoadblockCheckListItemManualForm) => pipe(
        signoffCheckList,
        array.findFirst(([id]) => id === item.edited.id),
        option.fold(
            () => false,
            ([id, isChecked]) => isChecked
        )
    );

    const onFinalCheckAutomatic = (item: TRoadblockCheckListItemAutomatic) => pipe(
        signoffCheckList,
        updateWhere(
            ([id]) => id === item.id,
            ([id, isChecked]) => [id, !isChecked] as TFinalCheckItem
        ),
        (checklist) => setSignoffCheckList(checklist)
    );
    
    const onFinalCheckManual = (item: TRoadblockCheckListItemManualForm) => pipe(
        signoffCheckList,
        updateWhere(
            ([id]) => id === item.edited.id,
            ([id, isChecked]) => [id, !isChecked] as TFinalCheckItem
        ),
        (checklist) => setSignoffCheckList(checklist)
    );

    const onSignOff = () => {
        props.onSignOff(props.form.children.signoff_form);
        setSignature("");
    }

    return (
        <>
            {/* HEADER */}
            <Sticky zIndex={1}>
                <CRMSingleViewHeader
                    title={RoadblockName}
                    onClick={props.onClose}
                >
                     <SpacingRow>
                        <Padding type="right" spacing={CRMSpacing.SMALL}>
                            <CRMRoadblockChatButton
                                messageCount={MessageCount}
                                onClick={props.onOpenChat}
                            />
                        </Padding>

                        {RoadblockLinkedForm !== null &&
                            <CRMBlockHeaderButton
                                icon="form-icon"
                                isActive={false}
                                onClick={() => props.onOpenForm(RoadblockLinkedForm)}
                            />
                        }
                    </SpacingRow>
                </CRMSingleViewHeader>
                
                {IsLoading ?
                    <CRMLoadingBar duration={2000} />
                    : <Shape height="1px" width="100%" color={CRMColors.NEUTRAL_PAPER} />
                }
            </Sticky>

            {IsLoading &&
                <div className="crm-roadblock-view-checklist__body">
                    <CRMRoadblockViewChecklistPlaceholder />
                </div>
            }

            {HasErrored &&
                <div className="crm-roadblock-view-checklist__body">
                    <CRMErrorPlaceholder
                        title="Something is not right."
                        buttonText="Back to case roadblocks"
                        onClick={props.onClose}
                    >
                        
                            The system was not able to get the roadblock data. <br/>
                            If this happens again let the development team know.
                    </CRMErrorPlaceholder>
                </div>
            }
            
            {/* CHECKLIST ITEMS */}
            {!IsLoading && !HasErrored &&
                <div className="crm-roadblock-view-checklist__body">
                    <SpacingColumn spacing={CRMSpacing.LARGE}>

                    {/* STATUS LABEL */}
                    <SpacingColumn spacing={CRMSpacing.TINY}>
                        <CRMRoadblockStatusLabelFromForm 
                            form={props.form} 
                        />

                            <MaxWidth width="460px">
                                {Status === "suspended" &&
                                    <CRMRoadblockStatusLabelPopoverCancelled 
                                        noBoxShadow={true}
                                    />
                                }

                                {/* BLOCKED */}
                                {Status === "blocked" &&
                                    <CRMRoadblockStatusLabelPopoverBlocked 
                                        form={props.form}
                                        noBoxShadow={true}
                                    />
                                }

                                {/* RESOLVED */}
                                {Status === "resolved" &&
                                    <CRMRoadblockStatusLabelPopoverResolved
                                        form={props.form}
                                        noBoxShadow={true}
                                    />
                                }
                            </MaxWidth>
                        </SpacingColumn>

                        {/* TITLE */}
                        {Status !== "resolved" &&
                            <FontQuicksand>
                                {CheckMode === "first" &&
                                    <Text size={CRMFontSizes.MED} weight={700}>
                                        This roadblock is resolved by:
                                    </Text>
                                }
                                
                                {CheckMode === "final" &&
                                    <Text size={CRMFontSizes.MED} weight={700}>
                                        The roadblock needs a final check before signoff.
                                    </Text>
                                }
                            </FontQuicksand>
                        }
                        
                        {/* CHECKLIST ITEMS */}
                        <Width width="460px">
                            <SpacingColumn spacing={CRMSpacing.LARGE}>
                                {/* ITEMS - AUTOMATIC */}
                                <SpacingColumn spacing={CRMSpacing.MEDIUM}>
                                    {ChecklistAutomatic.map((item, index) => (
                                        <CRMRoadblockChecklistItemAutomatic
                                            key={`${item.name}_${index}`}
                                            mode={CheckMode}
                                            title={item.name}
                                            isDisabled={AreChecksDisabled}
                                            resolvedBy={item.resolution_instruction}
                                            isCheckedFirst={item.resolved_at !== null}
                                            
                                            isCheckedFinal={getFinalCheckAutomaticValue(item)}
                                            onChangeFinalCheck={() => onFinalCheckAutomatic(item)}
                                        />
                                    ))}
                                </SpacingColumn>
                                
                                {/* SEPERATION LINE */}
                                <Shape width="100%" height="1px" color={CRMColors.NEUTRAL_10} />
                                
                                {/* ITEMS - MANUAL */}
                                <SpacingColumn spacing={CRMSpacing.MEDIUM}>
                                    {ChecklistManual.map((item, index) => (
                                        <CRMRoadblockChecklistItemManual
                                            key={`${item.edited.name}_${index}`}
                                            mode={CheckMode}
                                            title={item.edited.name}
                                            isDisabledFirstCheck={false}
                                            isDisabledFinalCheck={AreChecksDisabled}
                                            resolvedBy={item.edited.resolved_by_name}
                                            resolvedOn={item.edited.resolved_at}

                                            isCheckedFirst={item.edited.resolved_at !== null}
                                            onChangeFirstCheck={(value) => onFirstCheckManual(item, value)}
                                            
                                            isCheckedFinal={getFinalCheckManualValue(item)}
                                            onChangeFinalCheck={() => onFinalCheckManual(item)}
                                        />
                                    ))}
                                </SpacingColumn>
                            </SpacingColumn>
                        </Width>

                    </SpacingColumn>
                </div>
            }
            
            {/* BOTTOM BAR - SIGN OFF */}
            {CheckMode === "final" && Status !== "resolved" &&
                <CRMBottomControlBar>
                    <SpacingColumn>
                        {IsSubmittingSignoff ?
                            <CRMLoadingBar duration={2500} />
                            : <Shape height="1px" width="100%" color={CRMColors.NEUTRAL_PAPER} />
                        }

                        <Background
                            width="100%"
                            padding={`${CRMSpacing.LARGE} ${CRMSpacing.LARGE} ${CRMSpacing.MEDIUM}`}
                        >
                            <SpacingColumn spacing={CRMSpacing.MEDIUM}>
                                <Padding type="right" spacing="110px">
                                    <FontQuicksand>
                                        <Text size={CRMFontSizes.MED} weight={500} lineHeight={1.5}>
                                            I <Underline><WeightBold>{UserFullName} ({UserInitials})</WeightBold></Underline> declare 
                                            the above to be correct and sign off as complete.
                                        </Text>
                                    </FontQuicksand>
                                </Padding>
                            
                                <SpacingRow justifyContent="space-between">
                                    <SpacingRow spacing={CRMSpacing.SMALL}>
                                        <CRMFlatInputGeneral
                                            placeholder={`Type your initials here`}
                                            value={signature}
                                            onChange={setSignature}
                                            size="large"
                                        />
                                        <CRMIcon 
                                            iconName="signature"
                                            colour="hex"
                                            hexColour={CRMColors.NEUTRAL_2}
                                        />
                                    </SpacingRow>

                                    <CRMFormButton
                                        ButtonElement={CRMButtonPrimary}
                                        formStatus={props.form.children.signoff_form.status}
                                        label="Sign Off"
                                        disabled={!IsSigned || !AllItemsFinalChecked || IsSubmittingSignoff}
                                        onClick={onSignOff}
                                    />
                                </SpacingRow>
                            </SpacingColumn>
                        </Background>
                    </SpacingColumn>
                </CRMBottomControlBar>
            }
        </>
    );
};

const sortCheckItemByResolvedDate = (resolvedAtA: null | string, resolvedAtB: null | string) => {
    let aPriority = resolvedAtA === null ? 1 : 2;
    let bProprity = resolvedAtB === null ? 1 : 2;
    return aPriority-bProprity as Ordering;
}

const sortAutomaticItems = (items: Array<TRoadblockCheckListItemAutomatic>) => 
    items.sort((itemA, itemB) => sortCheckItemByResolvedDate(itemA.resolved_at, itemB.resolved_at))
;
    
const sortManualItems = (items: Array<TRoadblockCheckListItemManualForm>) => 
    items.sort((itemA, itemB) => sortCheckItemByResolvedDate(itemA.edited.resolved_at, itemB.edited.resolved_at))
;