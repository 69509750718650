import React, { CSSProperties } from "react";
import { CRMSpacing } from "../../models/CRMSpacing";
import { SpacingRow } from "../BuildingBlocks/SpacingRow";
import { CRMColumns } from "../CRMColumns/CRMColumns";
import { CRMIcon } from "../CRMIcon/CRMIcon";
import { CRMParagraph } from "../Simple/CRMParagraph/CRMParagraph";

export class CRMInputCheckboxComponent extends React.Component<{
    label?: string | JSX.Element;
    checked: boolean;
    cursor?: CSSProperties["cursor"];
    multiSelectActive?: boolean;
    size?: "small" | "medium";
    onClick: () => void;
}> {
    public render (): JSX.Element {
        return (
            <div
                className="crm-input-checkbox-component"
                onClick={this.props.onClick}
            >
                <SpacingRow
                    spacing={CRMSpacing.TINY}
                    alignItems="flex-start"
                >
                    
                    <CRMIcon
                        iconName={
                            this.props.multiSelectActive ? 
                                "checkbox-with-strikethrough"
                                : this.props.checked ? 
                                    "checkbox-checked" 
                                    : "checkbox-unchecked"
                        }
                        colour="neutral-ink"
                        size={this.props.size || "medium"}
                        cursor={this.props.cursor}
                    />

                    {this.props.label &&
                        <CRMParagraph>
                            {this.props.label}
                        </CRMParagraph>
                    }
                </SpacingRow>
            </div>
        );
    }
}
