import React from "react";
import { CRMColors } from "../../../models/CRMColors";
import { CRMFontSizes } from "../../../models/CRMFontSizes";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { Flex } from "../../BuildingBlocks/Flex";
import { SpacingColumn } from "../../BuildingBlocks/SpacingColumn";
import { TextColor } from "../../BuildingBlocks/TextColor";
import { CRMIcon } from "../../CRMIcon/CRMIcon";
import { CRMParagraph } from "../../Simple/CRMParagraph/CRMParagraph";
import { WeightBold } from "../../WeightBold/WeightBold";
import { WeightMedium } from "../../WeightMedium/WeightMedium";

export const CRMCaseNotAuthorizedDualRep = (): JSX.Element =>
    <SpacingColumn
        spacing={CRMSpacing.LARGE}
        justifyContent="center"
        alignItems="center"
    >
        {/* ICON */}
        <Flex justifyContent="center">
            <CRMIcon
                iconName="error-outline"
                size="110px"
                colour="neutral-4"
            />
        </Flex>

        {/* PARAGRAPHS */}
        <SpacingColumn
            spacing={CRMSpacing.MEDIUM}
            justifyContent="center"
            alignItems="center"
        >
            <CRMParagraph
                fontSize={CRMFontSizes.X_LARGE}
                justifyContent="center"
            >
                <WeightBold>
                    <TextColor color={CRMColors.NEUTRAL_0}>
                        You can't access this Dual Rep case.
                    </TextColor>
                </WeightBold>
            </CRMParagraph>

            <CRMParagraph
                fontSize={CRMFontSizes.MED}
                justifyContent="center"
            >
                <WeightMedium>
                    <TextColor color={CRMColors.NEUTRAL_0}>
                        You are the case handler or assistant on the other side of this Dual Rep case.
                    </TextColor>
                </WeightMedium>
            </CRMParagraph>
        </SpacingColumn>
    </SpacingColumn>;
