import React from "react";
import { FrontColors } from "../../../../models/FrontColors";
import { FrontSpacing } from "../../../../models/FrontSpacing";
import { Padding } from "../../../BuildingBlocks/Padding";
import { SpacingColumn } from "../../../BuildingBlocks/SpacingColumn";
import { TextColor } from "../../../BuildingBlocks/TextColor";
import { WeightBold } from "../../../WeightBold/WeightBold";
import { WeightMedium } from "../../../WeightMedium/WeightMedium";
import { FrontCardTitle } from "../../Simple/FrontCardTitle/FrontCardTitle";
import { FrontIcon, TIcon } from "../../Simple/FrontIcon/FrontIcon";
import FrontParagraphRegular from "../../Simple/FrontParagraphRegular/FrontParagraphRegular";

type TOnboardingInfoCardProps = {
    backgroundColour?: "green" | "blue";
    iconName?: TIcon;
    titleText?: string;
    paragraphs: Array<string>;
};

export const FrontOnboardingInfoCard = (props: React.PropsWithChildren<TOnboardingInfoCardProps>): JSX.Element => {

    return (
        <div className={`front-onboarding-info-card 
            front-onboarding-info-card--${props.backgroundColour ?? "green"}
        `}>
            <Padding type="right" spacing={FrontSpacing.MEDIUM_2}>
                <SpacingColumn spacing={FrontSpacing.MEDIUM_2}>
                    
                    {/* CARD TITLE */}
                    {props.titleText && 
                        <FrontCardTitle>
                            <TextColor color={FrontColors.NEUTRAL_18}>
                                <WeightBold> 
                                    {props.titleText}
                                </WeightBold>
                            </TextColor>
                        </FrontCardTitle>
                    }

                    {/* PARAGRAPHS */}
                    <SpacingColumn spacing={FrontSpacing.SMALL_3}>
                        {props.paragraphs.map((paragraph, index) =>
                            <FrontParagraphRegular>
                                <TextColor color={FrontColors.NEUTRAL_18}>
                                    <WeightMedium>
                                        <div key={index}>
                                            {paragraph}
                                        </div>
                                    </WeightMedium>
                                </TextColor>
                            </FrontParagraphRegular>
                        )}
                    </SpacingColumn>
                    
                    {props.children}
                </SpacingColumn>
                    

            </Padding>

            {/* ICON */}
            <div className="front-onboarding-info-card__icon">
                <FrontIcon
                    iconName={props.iconName ?? "done"}
                    colour="neutral-18"
                    size="large"
                />
            </div>
        </div>
    );
};