import React from "react";
import { ListingPropertySignsControls } from "../../../../domain/codecs/Listing";
import { CRMAutosaveIndicatorWrap } from "../../components/CRM/CRMAutosaveIndicatorWrap/CRMAutosaveIndicatorWrap";
import { CRMCodecEditForm } from "../../components/CRMCodecEditForm/CRMCodecEditForm";
import { CRMTitleSection } from "../../components/CRMTitleSection/CRMTitleSection";
import { CRMTitleSubSection } from "../../components/CRMTitleSubSection/CRMTitleSubSection";
import { SpacingColumn } from "../../components/BuildingBlocks/SpacingColumn";
import { CRMSpacing } from "../../models/CRMSpacing";
import { CRMSignMovements } from "../../components/Complex/CRMSignMovements/CRMSignMovements";
import { TContainerStateProps } from "../../state/TContainerStateProps";

export const CRMListingSignsContainer = (props: TContainerStateProps): JSX.Element => {

    return (
        <CRMAutosaveIndicatorWrap status={props.state.forms.listing_page.data.output.form.status}>
            <SpacingColumn spacing={CRMSpacing.LARGE}>
                <CRMTitleSection>
                    Signs
                </CRMTitleSection>

                {/* GENERAL FORMS */}
                <CRMCodecEditForm
                    codec={ListingPropertySignsControls}
                    model={props.state.forms.listing_page.data.output.form.edited}
                    validationErrors={props.state.forms.listing_page.data.output.form.validationErrors}
                    onChange={(edited) => props.dispatch({
                        type: "LISTING_PREPARATION_FORM_CHANGE",
                        payload: {
                            ...props.state.forms.listing_page.data.output.form,
                            edited: {
                                ...props.state.forms.listing_page.data.output.form.edited,
                                ...edited,
                            }
                        }
                    })}
                    showDeferDateLabels={true}
                />

                {/* IF SIGNS ARE BEING INSTALLED: SIGN MOVEMENTS SUB SECTION */}
                {!props.state.forms.listing_page.data.output.form.edited.sign_not_being_installed &&
                    <SpacingColumn spacing={CRMSpacing.MEDIUM}>
                        <CRMTitleSubSection>
                            Movements
                        </CRMTitleSubSection>

                        <CRMSignMovements
                            movements={props.state.forms.listing_page.data.output.sign_movements}
                            onUpdateMovements={(movements) => props.dispatch({
                                type: "LISTING_PAGE_SIGN_MOVEMENTS_CHANGE",
                                payload: movements
                            })}
                            onDelete={(movement) => props.dispatch({
                                type: "LISTING_PAGE_DELETE_SIGN_MOVEMENT",
                                payload: movement
                            })}
                            addMovementForm={props.state.forms.listing_page.create_sign_movement}
                            onUpdateAddMovement={(payload) => props.dispatch({
                                type: "LISTING_PAGE_ADD_SIGN_MOVEMENT_CHANGE",
                                payload
                            })}
                            onAdd={(form) => props.dispatch({
                                type: "LISTING_PAGE_ADD_SIGN_MOVEMENT",
                                payload: {
                                    ...form,
                                    edited: {
                                        ...form.edited,
                                        listing_id: props.state.forms.listing_page.data.output.form.original.id
                                    }
                                }
                            })}
                        />
                    </SpacingColumn>
                }
            </SpacingColumn>
        </CRMAutosaveIndicatorWrap>
    );
};
