import React, { createRef, useState } from "react";
import { array, either, identity, option } from "fp-ts";
import { pipe } from "fp-ts/lib/pipeable";
import { CRMRoadblockTableList } from "../CRMRoadblockTableList/CRMRoadblockTableList";
import { CRMBlockScrollCacheView, CRMBlockScrollResetView } from "../../CRM/CRMBlock/CRMBlockViews/CRMBlockViews";
import { RoadblockForm, TRoadblockCheckListItemManualForm, TRoadblockForm, TRoadblockSignOffForm, TRoadblocksForm2, TRoadblocksListItem2 } from "../../../../../domain/codecs/form/RoadblockForm";
import { CRMRoadblockViewChat } from "../CRMRoadblockViewChat/CRMRoadblockViewChat";
import { TChatsMessageForm } from "../../../../../domain/codecs/form/ChatsMessageForm";
import { useCallbackOnUniquePropChange } from "../../../hooks/useCallbackOnUniquePropChange";
import { TBlockType } from "../../../state/router/routerTypes";
import { CRMRoadblockViewChecklist } from "../CRMRoadblockViewChecklist/CRMRoadblockViewChecklist";
import { PlainUser_NameCopy, TSessionUser } from "../../../../../domain/codecs/User";

type TCRMRoadblockBlockProps = {
    sessionUser: TSessionUser;
    openRoadblockID?: string;
    roadblocksForm: TRoadblocksForm2;
    onOpen: (roadblock: TRoadblocksListItem2) => void;
    onClose: () => void;
    onChange: (form: TRoadblockForm) => void;
    onChangeManualItem: (form: TRoadblockCheckListItemManualForm) => void;
    onTypeMessage: (form: TChatsMessageForm) => void;
    onSubmitMessage: (form: TChatsMessageForm) => void;
    onOpenForm: (target: TBlockType) => void;
    onSignOff: (form: TRoadblockSignOffForm) => void;
}

export const CRMRoadblocksBlock = (props: TCRMRoadblockBlockProps): JSX.Element => {

    const SingleViewRef = createRef<HTMLDivElement>();
    const Roadblocks = props.roadblocksForm.children.roadblocks;
    
    const SessionUserName = pipe(
        PlainUser_NameCopy.decode(props.sessionUser),
        either.fold(() => "", identity.flatten)
    );
    
    pipe(
        [props.sessionUser.first_name, props.sessionUser.last_name],
        array.map((name) => name.trim()),
        array.filter((name) => name !== ""),
        (a) => a.join(" "),
    );

    const [openRoadblockIndex, setRoadblockIndex] = useState(-1);
    const [singleViewMode, setSingleViewMode] = useState<"chat" | "checklist">("checklist");

    useCallbackOnUniquePropChange(
        (status) => {
            if (status === "success") {
                openRoadblockCachedInTheUrl();
            }
        },
        props.roadblocksForm.status
    );

    const openRoadblockCachedInTheUrl = () => pipe(
        Roadblocks,
        array.findIndex((roadblock) => roadblock.id === props.openRoadblockID),
        option.fold(
            () => undefined,
            (index) => onOpen(Roadblocks[index])
        )
    );

    const onOpen = (roadblock: TRoadblocksListItem2) => {
        setRoadblockIndex(findRoadblockIndexByID(roadblock.id));
        setSingleViewMode("checklist");
        props.onOpen(roadblock);
    }

    const findRoadblockIndexByID = (id: string) => pipe(
        Roadblocks,
        array.findIndex((roadblock) => roadblock.id === id),
        option.fold(
            () => -1,
            identity.flatten
        )
    )

    const onClose = () => {
        props.onClose();
        setRoadblockIndex(-1);
    }

    const onOpenChat = () => setSingleViewMode("chat");

    const onOpenChecklist = () => setSingleViewMode("checklist");

    const isARoadblockOpen = () => openRoadblockIndex > -1;

    const getOpenRoadblockOption = (): option.None | option.Some<TRoadblocksListItem2> =>
        !!Roadblocks[openRoadblockIndex]
            ? option.some(Roadblocks[openRoadblockIndex])
            : option.none
    ;

    const getOpenRoadblockDetailed = () => pipe(
        getOpenRoadblockOption(),
        option.fold(
            () => RoadblockForm.newDefault(),
            (item) => item.detailed_roadblock_form
        )
    );

    return (
        <>
            {/* TABLE LIST */}
            <CRMBlockScrollCacheView isShowing={!isARoadblockOpen()}>
                <CRMRoadblockTableList
                    roadblocks={Roadblocks}
                    onClick={onOpen}
                />
            </CRMBlockScrollCacheView>
            
            {/* SINGLE VIEW - CHECKLIST */}
            <CRMBlockScrollResetView 
                elementRef={SingleViewRef}
                isShowing={isARoadblockOpen() && singleViewMode === "checklist"}
            >
                <CRMRoadblockViewChecklist
                    sessionUser={props.sessionUser}
                    form={getOpenRoadblockDetailed()}
                    onClose={onClose}
                    onOpenChat={onOpenChat}
                    onChange={props.onChange}
                    onChangeManualItem={props.onChangeManualItem}
                    onSignOff={props.onSignOff}
                    onOpenForm={props.onOpenForm}
                />
            </CRMBlockScrollResetView>
            
            {/* SINGLE VIEW - CHAT */}
            <CRMBlockScrollResetView 
                elementRef={SingleViewRef}
                isShowing={isARoadblockOpen() && singleViewMode === "chat"}
            >
                <CRMRoadblockViewChat
                    sessionUserName={SessionUserName}
                    parentRef={SingleViewRef}
                    form={getOpenRoadblockDetailed()}
                    onTypeMessage={props.onTypeMessage}
                    onSubmitMessage={props.onSubmitMessage}
                    onClose={onClose}
                    onOpenForm={props.onOpenForm}
                    onOpenCheckList={onOpenChecklist}
                />
            </CRMBlockScrollResetView>
        </>
    );
}
