import React from "react";
import { requireExhaustive } from "../../../../../../shared/src/util";
import { FrontColors } from "../../../../models/FrontColors";
import { FrontSpacing } from "../../../../models/FrontSpacing";
import { MaxWidth } from "../../../BuildingBlocks/MaxWidth";
import { SpacingColumn } from "../../../BuildingBlocks/SpacingColumn";
import { SpacingRow } from "../../../BuildingBlocks/SpacingRow";
import { WeightBold } from "../../../WeightBold/WeightBold";
import { FrontInfoBubble } from "../../Simple/FrontInfoBubble/FrontInfoBubble";
import FrontParagraphSmall from "../../Simple/FrontParagraphSmall/FrontParagraphSmall";
import { FrontVerificationIcon } from "../../Simple/FrontVerificationIcon/FrontVerificationIcon";

type TFrontVerifiedArticleProps = {
    label: string;
    value?: string;
    status: "unsubmitted" | "pending" | "rejected" | "approved";
    explanation?: JSX.Element;
};

export const FrontVerifiedArticle = (props: React.PropsWithChildren<TFrontVerifiedArticleProps>): JSX.Element => {

    const getBubbleColorBasedOnStatus = (): string => {
        switch (props.status) {
            case "unsubmitted": 
                return FrontColors.PRIMARY_22;
            case "pending": 
                return FrontColors.PERTINENT_CORAL_22;
            case "rejected": 
                return FrontColors.ERROR_FIREBRICK_22
            case "approved": 
                return FrontColors.POSITIVE_APPLE_22
            default:
                return requireExhaustive(props.status);
        }
    }

    return (
        <div>
            <SpacingColumn spacing={FrontSpacing.SMALL_1}>
                
                <SpacingRow spacing={FrontSpacing.SMALL_2}>
                    <FrontVerificationIcon 
                        status={props.status}
                    />
                    {props.value && 
                        <FrontParagraphSmall>
                            <WeightBold>{props.label}:</WeightBold> {props.value}
                        </FrontParagraphSmall>
                    }
                    {!props.value && 
                        <FrontParagraphSmall>
                            <WeightBold>{props.label}</WeightBold>
                        </FrontParagraphSmall>
                    }
                </SpacingRow>

                {props.status !== "approved" && props.explanation &&
                    <MaxWidth width="500px">
                        <FrontInfoBubble color={getBubbleColorBasedOnStatus()}>
                            {props.explanation}
                        </FrontInfoBubble>
                    </MaxWidth>
                }
            </SpacingColumn>
        </div>
    );
};
