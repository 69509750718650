import { Observable, from } from "rxjs";
import { delay, filter, map, switchMap, tap } from "rxjs/operators";
import { TStateLens } from "../../../../domain/codecs/state";
import { formOperation } from "../../wrappers/formOperation";
import { TCodecSetState, TGetCodecState } from "../applyActions";
import { routeAction, action } from "./actionFunctions";
import { TActionsDefinitionsList } from "./TAction";
import { foldForm } from "../../functions/form/foldForm";
import { constants } from "../../../../shared/src/regexes";
import { validationErrorCodeConstants } from "../../../../shared/src/validation/ErrorCode";
import { TChatsMessageForm } from "../../../../domain/codecs/form/ChatsMessageForm";
import { runAutomaticUpdates } from "./automaticBlockUpdate";
import { foldFormOnSuccess } from "../../functions/form/foldFormOnSuccess";

export const actions: TActionsDefinitionsList = [
    routeAction("VIEW_CRM_LEGAL_CASE", (obs$, lens, setState, getState) => {
        obs$.pipe(
            tap((action) => setState(lens.case_roadblocks_block.edited.case_id.set(action.params.caseId))),
        ).subscribe();
    }),
    action("LEGAL_CASE_GET_ROADBLOCKS", (obs$, lens, setState, getState) => {
        obs$.pipe(
            $loadRoadblocks(lens, setState, getState),
        ).subscribe();
    }),
    action("LEGAL_CASE_GET_ROADBLOCK", (obs$, lens, setState, getState) => {
        obs$.pipe(
            tap((form) => {
                setState(
                    lens.case_roadblocks_block.children.roadblocks
                    .where((roadblock) => roadblock.id === form.edited.id)
                    .detailed_roadblock_form.status
                    .set("loading")
                )
            }),
            switchMap((form) => 
                from(formOperation("GetRoadblock", form))
                .pipe(
                    tap(foldFormOnSuccess(
                        ({ status }) => setState(
                            lens.case_roadblocks_block.children.roadblocks
                            .where((roadblock) => roadblock.id === form.edited.id)
                            .detailed_roadblock_form.status
                            .set(status)
                        ),
                        (response) => setState(
                            lens.case_roadblocks_block.children.roadblocks
                            .where((roadblock) => roadblock.id === response.edited.id)
                            .detailed_roadblock_form
                            .set(response)
                        ),
                    ))
                )
            ),
        ).subscribe();
    }),
    
    action(
        "LEGAL_CASE_TYPE_CHAT_MESSAGE", 
        (observable$, lens, setState, getState) => 
            observable$
            .pipe(
                tap((form) => {
                    setState(
                        lens.case_roadblocks_block.children.roadblocks
                        .where((roadblock) => roadblock.detailed_roadblock_form.children.details.chats_id === form.edited.chats_id)
                        .detailed_roadblock_form.children.chat_message_form
                        .set(form)
                    )
                })
            )
            .subscribe()
    ),
    
    action(
        "LEGAL_CSAE_ROADBLOCK_SIGN_OFF",
        (obs$, lens, setState, getState) => 
            obs$.pipe(
                tap((form) => {
                    setState(
                        lens.case_roadblocks_block.children.roadblocks
                        .where((roadblock) => roadblock.id === form.edited.roadblock_id)
                        .detailed_roadblock_form.children.signoff_form.status
                        .set("loading")
                    )
                }),
                switchMap((form) => formOperation("SubmitRoadblockSignOff", form)),
                tap((response) => {
                    setState(
                        lens.case_roadblocks_block.children.roadblocks
                        .where((roadblock) => roadblock.id === response.edited.roadblock_id)
                        .detailed_roadblock_form.children.signoff_form
                        .set(response)
                    )
                }),
                delay(500),
                filter((form) => form.status === "success"),
                map((response) => 
                    lens.case_roadblocks_block.children.roadblocks
                        .where((roadblock) => roadblock.id === response.edited.roadblock_id)
                        .detailed_roadblock_form
                        .get()(getState())
                ),
                switchMap((form) => formOperation("GetRoadblock", form)),
                tap((response) => {
                    setState(
                        lens.case_roadblocks_block.children.roadblocks
                        .where((roadblock) => roadblock.id === response.edited.id)
                        .detailed_roadblock_form
                        .set(response)
                    )
                })
            )
            .subscribe()
    ),

    action(
        "LEGAL_CASE_ROADBLOCK_CHANGE", 
        (obs$, lens, setState, getState) => 
            obs$.pipe(
                tap((form) => setState(
                    lens.case_roadblocks_block.children.roadblocks
                        .where((roadblock) => roadblock.id === form.edited.id)
                        .detailed_roadblock_form
                        .set(form)
                ))
            )
            .subscribe()
    ),
    
    action(
        "LEGAL_CASE_UPDATE_ROADBLOCK_MANUAL_CHECK_LIST_ITEM", 
        (obs$, lens, setState, getState) => 
            obs$.pipe(
                tap((form) => {
                    setState(
                        lens.case_roadblocks_block.children.roadblocks
                        .where((roadblock) => roadblock.id === form.children.roadblock_id)
                        .detailed_roadblock_form.status
                        .set("submitting")
                    )
                }),
                switchMap((form) => formOperation("UpdateRoadblockChecklistItem", form)),

                filter((response) => response.status === "success"),
                map((response) => 
                    lens.case_roadblocks_block.children.roadblocks
                        .where((roadblock) => roadblock.id === response.children.roadblock_id)
                        .detailed_roadblock_form
                        .get()(getState())
                ),
                switchMap((form) => formOperation("GetRoadblock", form)),
                tap((response) => {
                    setState(
                        lens.case_roadblocks_block.children.roadblocks
                        .where((roadblock) => roadblock.id === response.edited.id)
                        .detailed_roadblock_form
                        .set(response)
                    )
                })
            )
            .subscribe()
    ),
    
    action(
        "LEGAL_CASE_SUBMIT_CHAT_MESSAGE", 
        (observable$, lens, setState, getState, routes, dispatch) => 
            observable$
            .pipe(
                // validate 
                map(foldForm<TChatsMessageForm, TChatsMessageForm>(
                    (form) => constants.LETTERS.test(form.edited.note_body.replace(constants.HTML, "")),
                    (form) => ({
                        ...form,
                        status: "validationError",
                        validationErrors: [
                            [validationErrorCodeConstants.NOTHING_TO_SEND_VALIDATION, ""]
                        ]
                    }),
                    (form) => ({
                        ...form,
                        status: "submitting",
                    }),
                )),
                tap((form) => setState(
                    lens.case_roadblocks_block.children.roadblocks
                    .where((roadblock) => roadblock.detailed_roadblock_form.children.details.chats_id === form.edited.chats_id)
                    .detailed_roadblock_form.children.chat_message_form
                    .set(form)
                )),
                
                // submit message
                filter((form) => form.status === "submitting"),
                switchMap((form) => 
                    from(formOperation("CreateChatMessage", form)).pipe(
                        tap((response) => {
                            setState(
                                lens.case_roadblocks_block.children.roadblocks
                                .where((roadblock) => roadblock.detailed_roadblock_form.children.details.chats_id === form.edited.chats_id)
                                .detailed_roadblock_form.children.chat_message_form
                                .set({
                                    ...form,
                                    status: response.status
                                })
                            )
                        }),
                        
                        // refresh roadblock
                        filter((response) => response.status === "success"),
                        map(() => 
                            lens.case_roadblocks_block.children.roadblocks
                            .where((roadblock) => roadblock.detailed_roadblock_form.children.details.chats_id === form.edited.chats_id)
                            .detailed_roadblock_form
                            .get()(getState())
                        ),
                        tap((form) => {
                            setState(
                                lens.case_roadblocks_block.children.roadblocks
                                .where((roadblock) => roadblock.id === form.edited.id)
                                .detailed_roadblock_form.status
                                .set("untouched")
                            )
                        }),
                        switchMap((form) => formOperation("GetRoadblock", form)),
                        tap((response) => {
                            setState(
                                lens.case_roadblocks_block.children.roadblocks
                                .where((roadblock) => roadblock.id === response.edited.id)
                                .detailed_roadblock_form
                                .set(response)
                            )
                        })
                    ),
                ),
                tap(() => runAutomaticUpdates(routes, dispatch, getState, "case_roadblocks"))
            )
            .subscribe()
    ),

];

const $loadRoadblocks = <T>(lens: TStateLens, setState: TCodecSetState, getState: TGetCodecState) => 
    (obs$: Observable<T>) =>
        obs$.pipe(
            tap(() => setState(lens.case_roadblocks_block.status.set("loading"))),
            switchMap(() => formOperation("GetCaseRoadblocks", lens.case_roadblocks_block.get()(getState()))),
            tap((roadblocks) => setState(lens.case_roadblocks_block.set({ ...roadblocks, status: "success" }))),
            delay(500),
            tap(() => setState(lens.case_roadblocks_block.status.set("untouched"))),
        )
;
