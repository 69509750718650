import React from "react";
interface IInputTextArea {
    placeholder?: string;
    name?: string;
    value?: string;
    displayError?: boolean;
    required?: boolean;
    className?: string;
    disabled?: boolean;
    onChange: (value: string) => void;
    onPressEnterKey: () => void;
}

export class InputTextArea extends React.Component<React.PropsWithChildren<IInputTextArea>> {
    public render (): JSX.Element {
        return (
            <textarea
                className={
                    "inputComponent__input inputComponent__input--textArea" +
                    (this.props.displayError ? " inputComponent__input--error " : " ") +
                    this.props.className
                }
                placeholder={this.props.placeholder}
                value={this.props.value}
                required={this.props.required}
                name={this.props.name}
                id={this.props.name}
                disabled={this.props.disabled}
                onChange={(e) => this.props.onChange(e.currentTarget.value)}
                onKeyDown={this.props.onPressEnterKey}
            />
        );
    }
}

export default InputTextArea;
