import React, { useEffect, useState } from "react";

export type TNumberMode = "pence" | "pound" | "percentage";

export const useNumberInput = ( 
    value: number | null,
    onChangeCallback: (v: number | null) => void,
    mode: TNumberMode
) => {

    const [isFocused, setIsFocused] = useState(false);
    const [faceText, setFaceText] = useState("");

    useEffect(
        () => {
            if (!isFocused) {
                setFaceText(getFaceText())
            }
        },
        [value]
    );

    const getFaceText = (): string => {
        
        if (value === null) {
            return "";
        }

        let amountDelimited = mode === "pence" ? value/100 : value;

        if (isNumberDecimal(amountDelimited)) {
            return amountDelimited.toFixed(2);
        }

        return amountDelimited.toString();
    }

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {

        let strippedAmountString = event.currentTarget.value.replaceAll(/[£,]/g, "");

        // Paste Detection
        // We have to extend the class as the react types don't include the inputType parameter
        // If it isn't their we can't tell anyway so we will just treat it like it isn't a paste 
        interface extendedNativeEvent extends Event {inputType?: unknown};
        const nativeEvent: extendedNativeEvent = event.nativeEvent;
        const fromPaste = "inputType" in nativeEvent && typeof nativeEvent.inputType === "string" && nativeEvent.inputType === "insertFromPaste" ? true : false;

        if (mode === "pound" && isValidMoneyInPoundsString(strippedAmountString)) {
            setFaceText(strippedAmountString);
            onChangeCallback(strippedAmountString === "" ? null : parseInt(strippedAmountString));
        }

        // We prevent typing in extra decimals, but if you paste we round the value to 2 dp
        if (mode === "pence" && isValidMoneyInPenceString(strippedAmountString) && (fromPaste === true || isMoneyStringAMaxTwoPointDecimal(strippedAmountString))) {
            const outputVal = strippedAmountString === "" ? null : Math.round(parseFloat(strippedAmountString) * 100);
            // Check if paste again so we only do the rounding on paste, this means we don't strip the decimal point while typing
            setFaceText(outputVal === null || !fromPaste ? strippedAmountString : (outputVal/100).toString());
            onChangeCallback(outputVal);
        }

        if (mode === "percentage" && isValidMoneyInPenceString(strippedAmountString) && (fromPaste === true || isMoneyStringAMaxTwoPointDecimal(strippedAmountString))) {
            const outputVal = strippedAmountString === "" ? null : parseFloat(strippedAmountString);
            // Check if paste again so we only do the rounding on paste, this means we don't strip the decimal point while typing
            setFaceText(outputVal === null || !fromPaste ? strippedAmountString : outputVal.toString());
            onChangeCallback(outputVal);
        }
    }

    const isNumberDecimal = (number: number):boolean =>
        number.toString().indexOf(".") > -1
    ;

    const isValidMoneyInPoundsString = (val: string): boolean =>
        RegExp(/^(\d)*$/).test(val)
    ;
    
    const isValidMoneyInPenceString = (val: string): boolean =>
        doesMoneyStringHaveExpectedCharacters(val) &&
        isMoneyStringDelimitedCorrectly(val)
    ;

    const doesMoneyStringHaveExpectedCharacters = (amount: string): boolean =>
        new RegExp(/^(\d|\.)*$/).test(amount)
    ;

    const isMoneyStringDelimitedCorrectly = (amount: string) =>
        amount.split(".").length < 3
    ;

    const isMoneyStringAMaxTwoPointDecimal = (amount: string) =>
        isNumberDecimal(parseFloat(amount)) ?
            amount.split(".")[1].length < 3 :
            true
    ;

    return {
        setIsFocused,
        faceText,
        onChange
    }
}