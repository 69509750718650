import React from "react";
import { IFuzzySortOption } from "../../../hooks/useFuzzysort";
import { CRMFilterSearchSelect, TOnChangeSearchTextFunction } from "../CRMFilterSearchSelect/CRMFilterSearchSelect";
import { IOption } from "../../../hooks/UseDropdown";
import { CRMFilterAndSelectTinyArrowOptionWrap } from "../../Simple/CRMFilterAndSelectTinyArrowOptionWrap/CRMFilterAndSelectTinyArrowOptionWrap";
import { CRMParagraph } from "../../Simple/CRMParagraph/CRMParagraph";
import { CRMFontSizes } from "../../../models/CRMFontSizes";
import { WeightMedium } from "../../WeightMedium/WeightMedium";
import { CRMFuzzySortSearchHighlight } from "../../Simple/CRMFuzzySortSearchHighlight/CRMFuzzySortSearchHighlight";
import { TFormStatus } from "../../../../../shared/src/codecs/codec";
import { TPixelSize } from "../../../models/StringLiterals";

type TPlainFilterSearchSelect<M extends Record<string, unknown>> = {
    searchStickyTopPosition?: TPixelSize;
    placeholder?: string;
    noMatchText?: string;
    keepOriginalSearchResultsOrder?: boolean;
    searchFormStatus?: TFormStatus;
    searchText?: string;
    onChangeSearchText?: TOnChangeSearchTextFunction;

    onClick: (value: string, option: IFuzzySortOption<string, M>) => void;
    options: Array<IOption<string>>;
};


export const CRMPlainFilterSearchSelect = (props: React.PropsWithChildren<TPlainFilterSearchSelect<{}>>): JSX.Element => {

    return (
        <CRMFilterSearchSelect<{}>
            searchStickyTopPosition={props.searchStickyTopPosition}
            searchPlaceholder={props.placeholder}
            noMatchesText={props.noMatchText}
            keepOriginalSearchResultsOrder={props.keepOriginalSearchResultsOrder}
            searchFormStatus={props.searchFormStatus}
            searchText={props.searchText}
            onChangeSearchText={props.onChangeSearchText}

            options={props.options.map((option) => ({
                value: option.value,
                label: option.label,
                richLabelFormatting: (result, searchText) => (
                    <CRMFilterAndSelectTinyArrowOptionWrap 
                        onClick={() => props.onClick(result.obj.value, result.obj)}
                    >
                        <CRMParagraph fontSize={CRMFontSizes.SMALL}>
                            <WeightMedium>
                                <CRMFuzzySortSearchHighlight
                                    text={result.obj.label.toString()}
                                    searchText={searchText}
                                />
                            </WeightMedium>
                        </CRMParagraph>
                    </CRMFilterAndSelectTinyArrowOptionWrap>
                )
            }))}
        />
    );
};