import React from "react";
import { CRMSpacingColumn } from "../../CRMSpacingColumn/CRMSpacingColumn";
import { CRMSpacingRow } from "../../CRMSpacingRow/CRMSpacingRow";
import { CRMRadioTab } from "../../CRMRadioTab/CRMRadioTab";
import { useSelectedTab } from "../../../hooks/useSelectedTab";
import { ICRMPopoutOption } from "../../Simple/CRMPopoutOptions/CRMPopoutOptions";
import { TIcon } from "../../CRMIcon/CRMIcon";

export type TCRMVerticalTab = {
    tabLabel: string;
    labelIcon?: TIcon;
    tabOptions: Array<ICRMPopoutOption>;
    content: JSX.Element;
};

export const CRMVerticalTabs = (props: {
    tabs: Array<TCRMVerticalTab>
    afterTabsElement?: JSX.Element;
    noTabsContent?: JSX.Element;
}): JSX.Element => {

    const {
        isSelectedTabIndex,
        setSelectedTabIndex,
        selectedTabIndex
    } = useSelectedTab<TCRMVerticalTab>(props.tabs);

    return (
        <CRMSpacingRow
            spacing="large"
            alignItems="flex-start"
            childSize={props.tabs.length === 0 ? "1fr 1fr" : "2fr 1fr"}
        >
            <>
                {/* NO TABS CONTENT */}
                {props.tabs.length === 0 && props.noTabsContent}
                {/* TABS CONTENT */}
                {props.tabs.length > 0 && selectedTabIndex <= props.tabs.length -1 && props.tabs[selectedTabIndex].content}
            </>
            {/* TABS */}
            <CRMSpacingColumn spacing="medium">
                {props.tabs.map((tab, index) => (
                    <CRMRadioTab
                        key={index}
                        isSelected={isSelectedTabIndex(index)}
                        label={tab.tabLabel}
                        onClick={() => setSelectedTabIndex(index)}
                        icon={tab.labelIcon}
                        options={tab.tabOptions}
                    />
                ))}
                {props.afterTabsElement}
            </CRMSpacingColumn>
        </CRMSpacingRow>
    );
};
