import React from "react";
import { CRMColors } from "../../../models/CRMColors";
import { CRMFontSizes } from "../../../models/CRMFontSizes";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { CRMZIndex } from "../../../models/CRMZIndex";
import { BackgroundColour } from "../../BuildingBlocks/BackgroundColour";
import { Border } from "../../BuildingBlocks/Border";
import { Padding } from "../../BuildingBlocks/Padding";
import { SpacingRow } from "../../BuildingBlocks/SpacingRow";
import { Sticky } from "../../BuildingBlocks/Sticky";
import { CRMIcon, TIcon } from "../../CRMIcon/CRMIcon";
import { CRMParagraph } from "../../Simple/CRMParagraph/CRMParagraph";
import { WeightBold } from "../../WeightBold/WeightBold";

type TPopoutHeaderProps = {
    icon?: TIcon;
    borderRadius?: string;
    onClick?: () => void;
};

export const CRMPopoutHeader = (props: React.PropsWithChildren<TPopoutHeaderProps>): JSX.Element => {

    return (
        <Sticky top="0px" zIndex={CRMZIndex.SECOND_Z}>
            <Border
                type="bottom" 
                definition={`1px solid ${CRMColors.NEUTRAL_8}`}
            >
                <BackgroundColour 
                    colour={CRMColors.NEUTRAL_PAPER}
                    onClick={props.onClick}
                    borderRadius={props.borderRadius}
                >
                    <Padding type="horizontal" spacing={CRMSpacing.MEDIUM}>
                        <SpacingRow minHeight="50px" spacing={CRMSpacing.TINY}>
                            {!!props.icon &&
                                <CRMIcon
                                    iconName={props.icon}
                                    colour="neutral-ink"
                                />
                            }
                            <CRMParagraph fontSize={CRMFontSizes.MED} lineHeight={1}>
                                <WeightBold>
                                    {props.children}
                                </WeightBold>
                            </CRMParagraph>
                        </SpacingRow>
                    </Padding>
                </BackgroundColour>
            </Border>
        </Sticky>
    );
};
