import React from "react";
import { Button } from "../../components/OldFront/Simple/ButtonComponent/Button";
import Modal from "../../components/OldFront/Complex/ModalComponent/Modal";
import Heading3 from "../../components/OldFront/Simple/Heading3Component/Heading3";
import BodyText from "../../components/OldFront/Simple/BodyTextComponent/BodyText";
import * as Listing6 from "../../../../domain/models/Listing6";
import { IListingPageProps } from "../../pages/ListingPage/ListingPage";
import { createChangeRouteAction } from "../../state/router/createChangeRouteAction";

export const render = (props: IListingPageProps, listingData: Listing6.T): React.ReactNode =>
    <Modal
        fullScreenOnMobile={true}
        onCloseClicked={() => props.dispatch(createChangeRouteAction("VIEW_LISTING", { listingId: listingData.id }, {}))}
    >
        <div className="listing__book-viewing-success-modal-container">
                <div className="listing__book-viewing-modal-heading-container">
                <Heading3 text="Viewing requested" />
            </div>
            <div className="listing__book-viewing-modal-body-text-container">
                <BodyText type="normal">
                    Your viewing request is confirmed. Keep an eye on your emails - as soon as the seller confirms your viewing, we'll email you the viewing time from the three you chose.
                </BodyText>
            </div>
            <div className="listing__book-viewing-back-to-listing-button-container">
                <div className="listing__book-viewing-back-to-listing-button">
                    <Button
                        label="Back to property listing"
                        type="rooster"
                        fullWidth={true}
                        onClick={() => props.dispatch(createChangeRouteAction("VIEW_LISTING", { listingId: listingData.id }, {}))}
                    />
                </div>
            </div>
        </div>
    </Modal>;
