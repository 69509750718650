import React from "react";
import { THexColor, TPercentageSize, TPixelSize } from "../../models/StringLiterals";

type TDashedBorderProps = {
    color?: THexColor;
    thickness?: TPixelSize;
    length?: number;
    gap?: number;
    radius?: TPixelSize;
    maxWidth?: TPixelSize | TPercentageSize;
};

export const DashedBorder = (props: React.PropsWithChildren<TDashedBorderProps> ) => {
    return (
        <div
            style={{
                position: "relative",
                maxWidth: props.maxWidth,
            }}
        >
            <svg
                style={{
                    position: "absolute",
                    top: "0px",
                    left: "0px",
                    height: "100%",
                    width: "100%",
                    borderRadius: props.radius || "0px",
                }}
                viewBox='0 0 300 100' 
                preserveAspectRatio='none'
            >
                <path  
                    d="M0,0 300,0 300,100 0,100z"
                    vector-effect="non-scaling-stroke"
                    fill="none"
                    stroke={props.color || "black"}
                    stroke-dasharray={`${props.length || "4"}, ${props.gap || "4"}`}
                    stroke-width={props.thickness || "1px"}
                />
            </svg>

            <div>
                {props.children}
            </div>
        </div>
    )
}