import { pipe } from "fp-ts/lib/function";
import { array as extArray } from "../../../shared/src/utilByDomainGroupExport"


type TFormSection<T> = {
    list: Array<T>;
    sectionsStringToArray: (string: string | undefined) => Array<T>;
    isDetailsSectionSelected: (selectedSections: string | undefined, matchingSection: T) => boolean;
    areSectionsSelected: (selectedSection: string | undefined, matchingSections: Array<T>) => boolean;
};

const sectionsStringToArray = <T extends string>(string: string | undefined): Array<T> =>
    string
    ? string.split("|") as Array<T>
    : []
;

export const formSection = <S extends Array<string>>(sections: S): TFormSection<S[number]> => ({
    list: sections,
    sectionsStringToArray,
    isDetailsSectionSelected: (selectedSections, matchingSection) =>
        !selectedSections 
        || sectionsStringToArray<S[number]>(selectedSections).includes(matchingSection)
    ,
    areSectionsSelected: (selectedSections, matchingSections) =>
        pipe(
            sectionsStringToArray<S[number]>(selectedSections),
            extArray.includesAny(matchingSections)
        ),
});