import React from "react";
import { TTriageEmailAssignToCaseAndUserForm, TTriageEmailAssignToCaseForm, TTriageSimpleEmailForm } from "../../../../../domain/codecs/form/TriageForm";
import { CRMColors } from "../../../models/CRMColors";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { Border } from "../../BuildingBlocks/Border";
import { MinHeight } from "../../BuildingBlocks/MinHeight";
import { Sticky } from "../../BuildingBlocks/Sticky";
import { CRMPopoutHeader } from "../../Simple/CRMPopoutHeader/CRMPopoutHeader";
import { CRMBlockPopover } from "../../CRM/CRMBlock/CRMBlockPopover/CRMBlockPopover";
import { WeightRegular } from "../../WeightRegular/WeightRegular";
import { CRMEmailMetadata } from "../CRMEmailMetadata/CRMEmailMetadata";
import { CRMTriageCaseAssignmentSearch, TAssignmentProps } from "../CRMTriageCaseAssignmentSearch/CRMTriageCaseAssignmentSearch";

type TAssignToCaseSectionPopoutProps = {
    email: TTriageSimpleEmailForm;
    onChangeSearchText: (value: string) => void;
    onAssignAndResolveWithNoAction: (props: TAssignmentProps<TTriageEmailAssignToCaseForm>) => void;
    onAssignToMe: (props: TAssignmentProps<TTriageEmailAssignToCaseAndUserForm>) => void;
    onAssignToStaff: (props: TAssignmentProps<TTriageEmailAssignToCaseAndUserForm>) => void;
    onAssignToHandler: (props: TAssignmentProps<TTriageEmailAssignToCaseAndUserForm>) => void;
    onClose: () => void;
};

export const CRMAssignToCaseSectionPopout = (props: React.PropsWithChildren<TAssignToCaseSectionPopoutProps>): JSX.Element => {

    return (
        <CRMBlockPopover
            padding={CRMSpacing.MEDIUM}
            height="720px" 
            maxHeight="720px"
        >
            <CRMPopoutHeader
                icon="arrow-left"
                onClick={props.onClose}
            >
                Assign to case
                {/* NUMBER OF SEARCH RESULTS */}
                <WeightRegular>
                    {props.email.children.detailed_email_form.children.assign_to_case_search_form.children.results.length > 0
                        ? ` (${props.email.children.detailed_email_form.children.assign_to_case_search_form.children.results.length} result${props.email.children.detailed_email_form.children.assign_to_case_search_form.children.results.length > 1 ? "s": ""})`
                        : ""
                    }
                </WeightRegular>
            </CRMPopoutHeader>
            
            <MinHeight height="570px">
                <CRMTriageCaseAssignmentSearch
                    searchStickyTopPosition="50px"
                    searchForm={props.email.children.detailed_email_form.children.assign_to_case_search_form}
                    onChangeSearchText={props.onChangeSearchText}
                    onAssignAndResolveWithNoAction={props.onAssignAndResolveWithNoAction}
                    onAssignToMe={props.onAssignToMe}
                    onAssignToStaff={props.onAssignToStaff}
                    onAssignToHandler={props.onAssignToHandler}
                />
            </MinHeight>

            <Sticky bottom="0px" zIndex={1}>
                <Border 
                    type="top" 
                    definition={`1px solid ${CRMColors.NEUTRAL_8}`}
                >
                    <CRMEmailMetadata
                        padding={CRMSpacing.MEDIUM}
                        email={props.email.children.detailed_email_form}
                        fieldsToShow={["subject", "from", "to", "cc"]}
                    />
                </Border>
            </Sticky>

            
        </CRMBlockPopover>
    );
};
