import React, { useState } from "react";
import { CRMIconBadge } from "../../CRMIconBadge/CRMIconBadge";

export const CRMSavebarAlertDrawer = (props: React.PropsWithChildren<{}>): JSX.Element => {
    const [isOpen, setIsOpen] = useState<boolean>(false);

    return (
        <>
            {/* DRAWER */}
            <div
                className={`
                    crm-savebar-alert-drawer__drawer-container
                    ${isOpen ? "" : "crm-savebar-alert-drawer__drawer-container--closed"}
                `}
            >
                <div className={`
                    crm-savebar-alert-drawer__drawer
                    ${isOpen ? "" : "crm-savebar-alert-drawer__drawer--closed"}
                `}>
                    {props.children}
                </div>
            </div>

            {/* ICON BADGE */}
            <div>
                <div
                    className={`
                        crm-savebar-alert-drawer__arrow-tag-container
                        ${isOpen ? "" : "crm-savebar-alert-drawer__arrow-tag-container--closed"}
                    `}
                >
                    <div className="crm-savebar-alert-drawer__arrow-tag">
                    </div>
                </div>
                <CRMIconBadge
                    icon="warning-bubble"
                    iconColour="neutral-ink"
                    badgeColour="highlights-pertinent-yellow-4"
                    onClick={() => setIsOpen(!isOpen)}
                />
            </div>
        </>
    );
};
