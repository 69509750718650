import { useState } from "react";
import { pipe } from "fp-ts/lib/pipeable";
import { array, option, identity } from "fp-ts";
import { array as extArray } from "../../../shared/src/utilsByDomain/array"

type TStep<S extends string> = [S, boolean];

export const useLinearStepProgression = <S extends string>(stepMap: Array<TStep<S>>) => {
    
    const [stepState, setStepState] = useState(stepMap);

    const setStepAsComplete = (stepName: S): void => {
        if (areAllStepsBeforeThisOneComplete(stepName)) {
            updateStep(stepName, true);
        }
    }

    const updateStep = (stepName: S, value: boolean): void =>
        setStepState(
            pipe(
                stepState,
                extArray.updateWhere(
                    (step) => step[0] === stepName,
                    () => [stepName, value]
                )
            )
        )
    ;

    const areAllStepsBeforeThisOneComplete = (stepName: S): boolean => {
        const previousStep = getPreviousStep(stepName);
        const completeSteps = getListOfCompleteSteps();
        
        return previousStep ?
            completeSteps.indexOf(previousStep[0]) > -1 : 
            true
        ; 
    }

    const getPreviousStep = (stepName: S): [S, boolean] | null => {
        const currentStepIndex = getStepIndex(stepName);
        const previousStepIndex = currentStepIndex - 1;
        return previousStepIndex < 0 ? 
            null : 
            stepState[previousStepIndex]
        ;
    }

    const getStepIndex = (stepName: S): number => pipe(
        stepState,
        array.findIndex((step) => stepName === step[0]),
        option.fold(
            () => -1,
            identity.flatten
        )
    );

    const getListOfCompleteSteps = (): Array<S> => pipe(
        stepState,
        array.reduce(
            {
                hasAnIncompleteStepBeenEncountered: false,
                completeStepNames: [] as Array<S>,
            },
            (sum, step) => {
                if (step[1] == true && sum.hasAnIncompleteStepBeenEncountered === false) {
                    return {
                        ...sum,
                        completeStepNames: sum.completeStepNames.concat(step[0])
                    };
                }
                return {
                    ...sum,
                    hasAnIncompleteStepBeenEncountered: true,
                }
            }
        ),
        (result) => result.completeStepNames,
    );

    return {
        setStepAsComplete,
        areAllStepsBeforeThisOneComplete
    }
}