import React from "react";

type TCRMCardOutsideMinor = {
    thickLeftBorder?: boolean;
    hideTopAndBottomBorder?: boolean;
};

export class CRMCardOutsideMinor extends React.Component<React.PropsWithChildren<TCRMCardOutsideMinor>> {
    public render (): JSX.Element {
        return (
            <div
                className={`
                    crm-card-outside-minor
                    crm-card-outside-minor--shadow
                    ${this.props.thickLeftBorder ? "crm-card-outside-minor--thick-left-border" : ""}
                    ${this.props.hideTopAndBottomBorder ? "crm-card-outside-minor--hide-top-bottom-border" : ""}
                `}
            >
                {this.props.children}
            </div>
        );
    }
}
