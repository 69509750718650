import { pipe } from "fp-ts/lib/function";
import React, {  } from "react";
import { TQuoteRateFeePropertyValue } from "../../../../../domain/codecs/QuoteRateFee";
import { CRMColors } from "../../../models/CRMColors";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { HorizontalLine } from "../../BuildingBlocks/HorizontalLine";
import { SpacingColumn } from "../../BuildingBlocks/SpacingColumn";
import { SpacingRow } from "../../BuildingBlocks/SpacingRow";
import { CRMIcon } from "../../CRMIcon/CRMIcon";
import { CRMInputCurrency } from "../../CRMInputCurrency/CRMInputCurrency";
import CRMInputLabelAndErrorWrapComponent from "../../CRMInputLabelAndErrorWrapComponent/CRMInputLabelAndErrorWrapComponent";
import { CRMParagraph } from "../../Simple/CRMParagraph/CRMParagraph";
import { CursorPointer } from "../../CursorPointer/CursorPointer";
import { WeightMedium } from "../../WeightMedium/WeightMedium";

export const CRMQuoteRatesSingleViewEditPropertyValueFeesVariableItem = (props: React.PropsWithChildren<{
    fee: TQuoteRateFeePropertyValue,
    displayError: boolean,
    canDelete: boolean,
    onChange: (transfer_value_fees: TQuoteRateFeePropertyValue) => void,
    onDelete: () => void,
}>): JSX.Element => {
    const onFeeChange = <A extends keyof TQuoteRateFeePropertyValue>(key: A) => (value: TQuoteRateFeePropertyValue[A]) =>
        pipe(
            {
                ...props.fee,
                [key]: value,
            },
            props.onChange,
        );

    return <SpacingColumn
        spacing={CRMSpacing.MEDIUM}
    >
        {/* GREATER THAN OR EQUAL TO */}
        <CRMInputLabelAndErrorWrapComponent label="When greater than or equal to:">
            <CRMInputCurrency
                mode="pence"
                value={props.fee.when_greater_than_or_equal_to_property_price_pence}
                onChange={onFeeChange("when_greater_than_or_equal_to_property_price_pence")}
            />
        </CRMInputLabelAndErrorWrapComponent>

        {/* LESS THAN */}
        <CRMInputLabelAndErrorWrapComponent
            label="And less than:"
            guidance="Leave empty for infinity."
        >
            <CRMInputCurrency
                mode="pence"
                value={props.fee.when_less_than_property_price_pence}
                onChange={onFeeChange("when_less_than_property_price_pence")}
            />
        </CRMInputLabelAndErrorWrapComponent>

        {/* FEE */}
        <CRMInputLabelAndErrorWrapComponent label="Then the fee is:">
            <CRMInputCurrency
                mode="pence"
                value={props.fee.fee_pence}
                displayError={props.displayError && props.fee.fee_pence === 0}
                onChange={(value) => onFeeChange("fee_pence")(value || 0)}
            />
        </CRMInputLabelAndErrorWrapComponent>

        {/* DELETE */}
        {props.canDelete &&
            <CursorPointer onClick={() => props.onDelete()}>
                <SpacingRow
                    spacing={CRMSpacing.TINY}
                    justifyContent="start"
                    alignItems="center"
                >
                    {/* ICON */}
                    <CRMIcon
                        iconName="delete"
                        colour="neutral-ink"
                        size="small"
                    />

                    {/* TEXT */}
                    <CRMParagraph>
                        <WeightMedium>
                            Delete this variation
                        </WeightMedium>
                    </CRMParagraph>
                </SpacingRow>
            </CursorPointer>
        }

        {/* LINE */}
        <HorizontalLine color={CRMColors.NEUTRAL_8} />
    </SpacingColumn>;
};
