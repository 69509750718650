import { record } from "fp-ts";
import { pipe } from "fp-ts/lib/function";
import React, { useState } from "react";
import { TCreateCaseQuoteUnfilledForm } from "../../../../../domain/codecs/form/CaseForm";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { Padding } from "../../BuildingBlocks/Padding";
import { Relative } from "../../BuildingBlocks/Relative";
import { SpacingRow } from "../../BuildingBlocks/SpacingRow";
import { CRMBottomControlBar } from "../../CRMBottomControlBar/CRMBottomControlBar";
import { CRMButtonPrimary } from "../../CRMButtonPrimary/CRMButtonPrimary";
import { CRMFormButton } from "../../CRMFormButton/CRMFormButton";
import { CRMPopoutHeader } from "../../Simple/CRMPopoutHeader/CRMPopoutHeader";
import { CRMTidyPopout } from "../../Simple/CRMTidyPopout/CRMTidyPopout";
import { CRMQuoteCreatePopoutForm } from "./CRMQuoteCreatePopoutForm";

export const CRMQuoteCreatePopout = (props: React.PropsWithChildren<{
    form: TCreateCaseQuoteUnfilledForm;
    onClose: () => void;
    onChange: (form: TCreateCaseQuoteUnfilledForm) => void;
    onSave: (form: TCreateCaseQuoteUnfilledForm) => void;
}>): JSX.Element => {
    const [displayError, setDisplayError] = useState<boolean>(false);

    const onSave = () => {
        if (
            props.form.edited.transaction_type === null
            || props.form.edited["property_price_pence--transfer-monetary-value"] === null
            || props.form.edited["property_price_pence--property-value"] === null
            || props.form.edited["property_price_pence--property-value"] === 0
            || props.form.edited.property_residential_commercial === null
            || props.form.edited.clients_ownership === null
            || props.form.edited.clients_property_count === null
            || props.form.edited.number_of_clients === null
            || pipe(
                props.form.edited.attributes,
                record.filter((value) => value === null),
                record.keys
            ).length > 0
        ) {
            setDisplayError(true);
        } else {
            setDisplayError(false);
            props.onSave(props.form);
        }
    };

    return (
        <CRMTidyPopout width="100%" height="730px">
            <Relative>
                <CRMPopoutHeader
                    icon="arrow-left"
                    onClick={props.onClose}
                >
                    New Quote
                </CRMPopoutHeader>

                <Padding spacing={CRMSpacing.LARGE}>
                    <CRMQuoteCreatePopoutForm
                        displayError={displayError}
                        form={props.form}
                        onChange={props.onChange}
                    />
                </Padding>

                <CRMBottomControlBar>
                    <Padding 
                        type="custom"
                        width="100%" 
                        spacing={`${CRMSpacing.TINY} ${CRMSpacing.MEDIUM}`}
                    >
                        <SpacingRow justifyContent="end">
                            <CRMFormButton
                                formStatus={props.form.status}
                                ButtonElement={CRMButtonPrimary}
                                label="Create"
                                onClick={onSave}
                            />
                        </SpacingRow>
                    </Padding>
                </CRMBottomControlBar>
            </Relative>
        </CRMTidyPopout>
    );
};
