import React from "react";
import { TContainerStateProps } from "../../state/TContainerStateProps";
import { CRMPageWrap } from "../../components/Simple/CRMPageWrap/CRMPageWrap";
import { CRMLegalMenuBarContainer } from "../../containers/CRMLegalMenuBarContainer/CRMLegalMenuBarContainer";
import { SpacingRow } from "../../components/BuildingBlocks/SpacingRow";
import { CRMComposeEmailForm } from "../../components/CRM/CRMComposeEmail/CRMComposeEmailForm/CRMComposeEmailForm";
import { Padding } from "../../components/BuildingBlocks/Padding";
import { CRMSpacing } from "../../models/CRMSpacing";
import { CRMTriageInbox } from "../../components/Complex/CRMTriageInbox/CRMTriageInbox";
import { CRMBlockScrollCacheView } from "../../components/CRM/CRMBlock/CRMBlockViews/CRMBlockViews";
import { CRMBlock } from "../../components/CRM/CRMBlock/CRMBlock/CRMBlock";

const CRMLegalTriageInboxPage = (props: TContainerStateProps): JSX.Element =>
    <CRMPageWrap>
        {/* HEADER */}
        <CRMLegalMenuBarContainer {...props} />

        {/* BODY */}
        <Padding
            type="custom" 
            spacing={`${CRMSpacing.X_LARGE} ${CRMSpacing.X_LARGE} 0px`}
        >
            <SpacingRow
                spacing={CRMSpacing.X_LARGE}
                alignItems="flex-start"
            >
                {/* BLOCK - TRIAGE */}
                <CRMBlock
                    mode="fixed-option"
                    startingWidth={700}
                    fixedOption={{
                        icon: "triangle",
                        title: "Triage"
                    }}
                    optionGroups={[]}
                >
                    <CRMTriageInbox
                        unassignedForm={props.state.forms.triage_page.unassigned}
                        expiredForm={props.state.forms.triage_page.expired}
                        triageTypeVisible={props.state.forms.triage_page.active_type_visible}
                        onChangeTriageTypeVisible={(payload) => props.dispatch({
                            type: "TRIAGE_ACTIVE_TYPE_VISIBLE_CHANGE",
                            payload,
                        })}
                        onChangeSearchCaseQuery={(payload, triageType) => props.dispatch({
                            type: `TRIAGE_${triageType}_SEARCH_CASE`,
                            payload,
                        })}
                        onOpenEmail={(payload, triageType) => props.dispatch({
                            type: `TRIAGE_${triageType}_OPEN_EMAIL`,
                            payload,
                        })}
                        onLoadMore={(triageType) => props.dispatch({
                            type: `TRIAGE_${triageType}_LOAD_MORE`,
                        })}
                        onArchive={(payload) => props.dispatch({
                            type: `TRIAGE_UNASSIGNED_ARCHIVE`,
                            payload
                        })}
                        onResolve={(payload) => props.dispatch({
                            type: `TRIAGE_EXPIRED_RESOLVE`,
                            payload
                        })}
                        onChangeChaseModeForm={(payload) => props.dispatch({
                            type: "TRIAGE_EXPIRED_RESOLVE_FORM_UPDATE_CHASE_MODE_FORM",
                            payload,
                        })}
                        onBulkArchive={(payload, triageType) => props.dispatch({
                            type: `TRIAGE_${triageType}_BULK_ARCHIVE`,
                            payload
                        })}
                        onAssignToPerson={(payload, triageType) => props.dispatch({
                            type: `TRIAGE_${triageType}_ASSIGN_TO_PERSON`,
                            payload
                        })}
                        onAssignToCaseAndMe={(payload, triageType) => props.dispatch({
                            type: `TRIAGE_${triageType}_ASSIGN_TO_CASE_AND_ME`,
                            payload
                        })}
                        onAssignToCaseAndStaff={(payload, triageType) => props.dispatch({
                            type: `TRIAGE_${triageType}_ASSIGN_TO_CASE_AND_STAFF`,
                            payload
                        })}
                        onAssignToCaseAndHandler={(payload, triageType) => props.dispatch({
                            type: `TRIAGE_${triageType}_ASSIGN_TO_CASE_AND_HANDLER`,
                            payload
                        })}
                        onAssignToCaseAndResolve={(payload, triageType) => props.dispatch({
                            type: `TRIAGE_${triageType}_ASSIGN_TO_CASE_AND_RESOLVE`,
                            payload
                        })}
                        onReply={(payload) => props.dispatch({
                            type: "CRM_COMPOSE_EMAIL_REPLY",
                            payload
                        })}
                        onForward={(payload) => props.dispatch({
                            type: "CRM_COMPOSE_EMAIL_FORWARD",
                            payload
                        })}
                    />
                </CRMBlock>

                {/* BLOCK - COMPOSE EMAIL */}
                <CRMBlock
                    mode="fixed-option"
                    fixedOption={{
                        icon: "send-email",
                        title: "Compose Email"
                    }}
                    optionGroups={[]}
                >
                    <CRMBlockScrollCacheView isShowing={true}>
                        <CRMComposeEmailForm
                            apiUrl={env.REACT_APP_API_URL}
                            closeMode="clear-only"
                            composingUser={sessionUser}
                            additionalSendToEmailAddresses={[]}
                            attachedEmail={props.state.forms.global_legal.attached_email}
                            onChangeSearchCaseValue={(payload) => props.dispatch({
                                type: "CRM_COMPOSE_EMAIL_TYPE_CASE_SEARCH_TEXT",
                                payload,
                            })}
                            form={props.state.forms.global_legal.compose_email}
                            onSelectCase={(payload) => props.dispatch({
                                type: "CRM_SET_CASE_FOR_COMPOSE_EMAIL_FORM",
                                payload,
                            })}
                            onChange={(payload) => props.dispatch({
                                type: "CRM_UPDATE_COMPOSE_EMAIL_FORM",
                                payload,
                            })}
                            onSubmit={(payload) => props.dispatch({
                                type: "CRM_SEND_NEW_EMAIL",
                                payload,
                            })}
                            onReply={(payload) => props.dispatch({
                                type: "CRM_SEND_REPLY_EMAIL",
                                payload,
                            })}
                            onForward={(payload) => props.dispatch({
                                type: "CRM_SEND_FORWARD_EMAIL",
                                payload,
                            })}
                            onClear={() => props.dispatch({
                                type: "CRM_RESET_COMPOSE_EMAIL_FORM",
                                payload: null,
                            })}
                        />
                    </CRMBlockScrollCacheView>
                </CRMBlock>
            </SpacingRow>
        </Padding>
    </CRMPageWrap>
;

export default CRMLegalTriageInboxPage;
