import React, { useState } from "react";
import { TContainerStateProps } from "./../../state/TContainerStateProps";
import { CRMTitleSection } from "../../components/CRMTitleSection/CRMTitleSection";
import { CRMSpacer } from "../../components/CRMSpacer/CRMSpacer";
import { CRMAutosaveIndicatorWrap } from "../../components/CRM/CRMAutosaveIndicatorWrap/CRMAutosaveIndicatorWrap";
import CRMInputLabelAndErrorWrapComponent from "../../components/CRMInputLabelAndErrorWrapComponent/CRMInputLabelAndErrorWrapComponent";
import { CRMDeferButtonComponent } from "../../components/CRMDeferButtonComponent/CRMDeferButtonComponent";
import { CRMSpacingColumn } from "../../components/CRMSpacingColumn/CRMSpacingColumn";
import { CRMSpacingRow } from "../../components/CRMSpacingRow/CRMSpacingRow";
import { pipe } from "fp-ts/lib/function";
import { array } from "fp-ts";
import { FormStatus_highestPriority } from "../../../../shared/src/codecs/codec";
import { CRMVerticalTabs } from "../../components/Complex/CRMVerticalTabs/CRMVerticalTabs";
import { CRMButtonIcon } from "../../components/CRMButtonIcon/CRMButtonIcon";
import { CRMPadding } from "../../components/Simple/CRMPadding/CRMPadding";
import { TextCenter } from "../../components/TextCenter/TextCenter";
import { CRMParagraph } from "../../components/Simple/CRMParagraph/CRMParagraph";
import { CRMFormButtonIconWithLabel } from "../../components/CRMFormButtonIconWithLabel/CRMFormButtonIconWithLabel";
import { WeightBold } from "../../components/WeightBold/WeightBold";
import { CRMDropdownComponent } from "../../components/CRMDropdownComponent/CRMDropdownComponent";
import { enumToIDropdownOptions } from "../../functions/enumToIDropdownOptions";
import { TransactionType1, TransactionType1_TransactionType4 } from "../../../../domain/codecs/TransactionType";
import { TCasePropertyForm } from "../../../../domain/codecs/form/CasePropertyForm";
import { CRMCardOutsidePopupBasic } from "../../components/CRMCardOutsidePopupBasic/CRMCardOutsidePopupBasic";
import { requireExhaustive } from "../../../../shared/src/util";
import { SpacingRow } from "../../components/BuildingBlocks/SpacingRow";
import { CRMSpacing } from "../../models/CRMSpacing";
import { CRMCaseDualRepSuggestions } from "../../components/Complex/CRMCaseDualRepSuggestions/CRMCaseDualRepSuggestions";
import { CRMRadioListComponent } from "../../components/CRMInputs/CRMRadioListComponent/CRMRadioListComponent";
import { CRMLegalCaseDetailsPropertyDetailsPropertyContainer } from "../CRMLegalCaseDetailsPropertyDetailsPropertyContainer/CRMLegalCaseDetailsPropertyDetailsPropertyContainer";
import { CRMCaseLandRegistryTitleSuggestions } from "../../components/Complex/CRMCaseLandRegistryTitleSuggestions/CRMCaseLandRegistryTitleSuggestions";

const CRMLegalCaseDetailsPropertyDetailsContainer = (props: TContainerStateProps): JSX.Element => {
    const [propertyToBeDeleted, setPropertyToBeDeleted] = useState<TCasePropertyForm | null>(null);
    
    const dispatchLegalCaseDetailsUpdate = <T extends unknown>(edited: T, formKey: keyof typeof props.state.forms.contracts_block.children.details.edited) => props.dispatch({
        type: "LEGAL_CASE_CONTRACTS_BLOCK_CHANGE",
        payload: {
            ...props.state.forms.contracts_block.children.details,
            edited: {
                ...props.state.forms.contracts_block.children.details.edited,
                [formKey]: edited,
            },
        }
    })

    const getAllDualRepSuggestions = () =>
        pipe(
            props.state.forms.contracts_block.children.properties,
            array.map((form) => form.children.dual_rep_suggestion_forms),
            array.flatten,
        );

    const shouldShowDualRepSuggestions = () => {
        const hasSuggestions = () =>
            getAllDualRepSuggestions().length > 0;

        const isAlreadyADualRepCase = (): boolean =>
            !!props.state.forms.contracts_block.children.dual_rep.linked_case_form;

        return !isAlreadyADualRepCase() && hasSuggestions();
    };

    const casePropertiesMightBeAffectedByBSA = () =>
        pipe(
            props.state.forms.contracts_block.children.properties,
            array.map((propertyForm) => propertyForm.edited),
            array.filter((propertyEdited) => 
                (propertyEdited.affected_by_building_safety_act === true
                    || propertyEdited.affected_by_building_safety_act === null
                    || propertyEdited.affected_by_building_safety_act === undefined
                )
                && propertyEdited.tenure === "leasehold"),
            (possibleBSAProperties) => possibleBSAProperties.length > 0 ? true : false
        );

    return <>
        <CRMAutosaveIndicatorWrap
            status={pipe(
                [
                    props.state.forms.contracts_block.children.details.status,
                    ...pipe(
                        props.state.forms.contracts_block.children.properties,
                        array.map((form) => form.status),
                    ),
                ],
                FormStatus_highestPriority,
            )}
        >
            <CRMTitleSection>
                Property Details
            </CRMTitleSection>
            <CRMSpacer size="large" />
            
            <CRMSpacingColumn spacing="medium">
                <CRMSpacingRow spacing="large" childSize="even-fill">
                    <CRMInputLabelAndErrorWrapComponent
                        label="Transaction type"
                    >
                        {/* TRANSACTION TYPE */}
                        <CRMDropdownComponent
                            value={props.state.forms.contracts_block.children.details.edited.transaction_type}
                            options={enumToIDropdownOptions(
                                TransactionType1.values,
                                TransactionType1_TransactionType4,
                            )}
                            onChange={(transaction_type) => dispatchLegalCaseDetailsUpdate(transaction_type, "transaction_type")}
                            displayError={false}
                            disabled={
                                props.state.forms.contracts_block.children.case_status === "quote" ? false
                                : (
                                    props.state.forms.contracts_block.children.case_status === "closed_created_in_error"
                                    || props.state.forms.contracts_block.children.case_status === "closed_successfully"
                                    || props.state.forms.contracts_block.children.case_status === "closed_unsuccessfully"
                                    || props.state.forms.contracts_block.children.case_status === "closed_awaiting_opening_new_case"
                                    || props.state.forms.contracts_block.children.case_status === "completed"
                                    || props.state.forms.contracts_block.children.case_status === "exchanged"
                                    || props.state.forms.contracts_block.children.case_status === "instructed"
                                    || props.state.forms.contracts_block.children.case_status === "ready_for_exchange"
                                    || props.state.forms.contracts_block.children.case_status === "property_report"
                                ) ? true
                                : requireExhaustive(props.state.forms.contracts_block.children.case_status)
                            }
                        />
                        
                    </CRMInputLabelAndErrorWrapComponent>

                    {/* DEFER GET PROPERTY DETAILS JOB */}
                    <CRMInputLabelAndErrorWrapComponent label="Defer get property details job">
                        <CRMDeferButtonComponent
                            value={props.state.forms.contracts_block.children.details.edited.get_property_details_job_defer}
                            onChange={(get_property_details_job_defer) => dispatchLegalCaseDetailsUpdate(get_property_details_job_defer, "get_property_details_job_defer")}
                            displayError={false}
                            popoutPosition="below-button-left"
                        />
                    </CRMInputLabelAndErrorWrapComponent>
                </CRMSpacingRow>

                <SpacingRow
                    spacing={CRMSpacing.MEDIUM}
                    childSize="1fr 1fr"
                    alignItems="flex-start"
                >
                    {/* IS THERE A MAN CO */}
                    <CRMInputLabelAndErrorWrapComponent
                        label="Is there a management company?"
                    >
                        <CRMSpacingRow spacing="tiny" childSize="auto auto">
                            <CRMRadioListComponent<boolean, boolean>
                                value={props.state.forms.contracts_block.children.details.edited.has_management_company}
                                direction="row"
                                options={[
                                    {
                                        value: true,
                                        text: "Yes",
                                    },
                                    {
                                        value: false,
                                        text: "No",
                                    },
                                ]}
                                onChange={(has_management_company) => dispatchLegalCaseDetailsUpdate(has_management_company, "has_management_company")}
                            />
                            <CRMButtonIcon
                                variant="tertiary"
                                icon="backspace"
                                disabledColour="neutral-4"
                                onClick={() => dispatchLegalCaseDetailsUpdate(null, "has_management_company")}
                            />
                        </CRMSpacingRow>
                    </CRMInputLabelAndErrorWrapComponent>
                </SpacingRow>

                {props.state.forms.contracts_block.children.details.edited.has_management_company
                    && casePropertiesMightBeAffectedByBSA()
                    && props.state.forms.contracts_block.children.is_instructed !== null
                    && <SpacingRow
                        spacing={CRMSpacing.MEDIUM}
                        childSize="1fr 1fr"
                        alignItems="flex-start"
                    >
                        <CRMInputLabelAndErrorWrapComponent
                            label={"Has the management company been contacted?"}
                        >
                            <CRMSpacingRow spacing="tiny" childSize="auto auto">
                                <CRMRadioListComponent<boolean, boolean>
                                    value={props.state.forms.contracts_block.children.details.edited.management_company_contacted}
                                    direction="row"
                                    options={[
                                        {
                                            value: true,
                                            text: "Yes",
                                        },
                                        {
                                            value: false,
                                            text: "No",
                                        },
                                    ]}
                                    onChange={(management_company_contacted) => dispatchLegalCaseDetailsUpdate(management_company_contacted, "management_company_contacted")}
                                />
                            </CRMSpacingRow>
                        </CRMInputLabelAndErrorWrapComponent>
                    </SpacingRow>
                    
                
                }

                {props.state.forms.contracts_block.children.properties.length === 0
                    // ADD FIRST PROPERTY INPUT
                    ? <CRMSpacingRow
                        spacing="large"
                        alignItems="flex-start"
                        childSize="even-fill"
                    >
                        <CRMPadding size="tiny">
                            <TextCenter>
                                <CRMParagraph>
                                    {props.state.forms.contracts_block.children.details.edited.transaction_type === "unknown" &&
                                        <>Set the transaction type before adding properties.</>
                                    }

                                    {props.state.forms.contracts_block.children.details.edited.transaction_type !== "unknown" &&
                                        <>Add <WeightBold>this case's properties</WeightBold> to get started.</>
                                    }
                                </CRMParagraph>
                            </TextCenter>
                        </CRMPadding>

                        <div>
                            <CRMFormButtonIconWithLabel
                                formStatus={
                                    props.state.forms.contracts_block.children.new_property.status === "unauthorised" || props.state.forms.contracts_block.children.new_property.status === "twoFactorRequired" ? "failure"
                                    : props.state.forms.contracts_block.children.new_property.status === "notFound" ? "failure"
                                    : props.state.forms.contracts_block.children.new_property.status
                                }
                                ButtonElement={(p) => <CRMButtonIcon
                                    variant="primary"
                                    {...p}
                                    disabled={props.state.forms.contracts_block.children.details.edited.transaction_type === "unknown"}
                                />}
                                icon="add"
                                label="Add new property"
                                onClick={() => props.dispatch({
                                    type: "LEGAL_CASE_ADD_PROPERTY",
                                })}
                            />
                        </div>
                    </CRMSpacingRow>

                    // TABS WHEN THERE ARE ALREADY PROPERTIES
                    : <CRMVerticalTabs
                        tabs={pipe(
                            props.state.forms.contracts_block.children.properties,
                            array.map((form) => ({
                                tabLabel: form.children.address_short_string,
                                content: <CRMLegalCaseDetailsPropertyDetailsPropertyContainer
                                    form={form}
                                    state={props.state}
                                    dispatch={props.dispatch}
                                />,
                                tabOptions: [{
                                    label: "Delete",
                                    onClick: () => setPropertyToBeDeleted(form)
                                }],
                            }))
                        )}
                        afterTabsElement={
                            <CRMFormButtonIconWithLabel
                                formStatus="requiresSubmission"
                                ButtonElement={(buttonProps) => <CRMButtonIcon variant="primary" {...buttonProps} />}
                                icon="add"
                                label="Add new property"
                                onClick={() => props.dispatch({
                                    type: "LEGAL_CASE_ADD_PROPERTY",
                                })}
                            />
                        }
                    />}
            </CRMSpacingColumn>
        </CRMAutosaveIndicatorWrap>

        {/* DELETE POPUP */}
        <CRMCardOutsidePopupBasic
            isOpen={propertyToBeDeleted ? true : false}
            title="Are you sure?"
            context="important"
            onClose={() => setPropertyToBeDeleted(null)}
            closeText="Cancel"
            onCTA={() => {
                if (propertyToBeDeleted) {
                    props.dispatch({
                        type: "LEGAL_CASE_DELETE_PROPERTY",
                        payload: propertyToBeDeleted,
                    });
                }
                setPropertyToBeDeleted(null);
            }}
            ctaText="Yes"
        >
            <CRMParagraph>
                Are you sure you want to permanently delete this property from the case?
            </CRMParagraph>
        </CRMCardOutsidePopupBasic>

        {/* DUAL REP SUGGESTIONS POPUP */}
        {shouldShowDualRepSuggestions() &&
            <CRMCaseDualRepSuggestions
                suggestionForms={getAllDualRepSuggestions()}
                onLinkToSuggestion={(payload) => props.dispatch({
                    type: "LEGAL_CASE_SUGGESTION_LINK_DUAL_REP",
                    payload,
                })}
            />
        }

        {/* LAND REGISTRY TITLE SUGGESTIONS */}
        {pipe(
            props.state.forms.contracts_block.children.properties,
            array.filter((form) =>
                form.children.land_registry_title_suggestions.length >= 2
                && form.edited.title_number === ""
            ),
            array.map((form) =>
                <CRMCaseLandRegistryTitleSuggestions
                    key={form.edited.id}
                    suggestions={form.children.land_registry_title_suggestions}
                    onSelect={(payload) => props.dispatch({
                        type: "LEGAL_CASE_SELECT_LAND_REGISTRY_TITLE_SUGGESTION",
                        payload,
                    })}
                />
            ),
        )}
    </>;
}

export default CRMLegalCaseDetailsPropertyDetailsContainer;