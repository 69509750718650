import { array } from "fp-ts";
import { pipe } from "fp-ts/lib/function";
import { Ord } from "fp-ts/lib/string";
import React from "react";
import { snakeCaseToCopyText } from "../../../../../shared/src/util";
import { CRMColors } from "../../../models/CRMColors";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { BorderBetween } from "../../BuildingBlocks/BorderBetween";
import { HiddenScroll } from "../../BuildingBlocks/HiddenScroll";
import { MinWidth } from "../../BuildingBlocks/MinWidth";
import { Padding } from "../../BuildingBlocks/Padding";
import { SpacingRow } from "../../BuildingBlocks/SpacingRow";
import { CRMInputCheckboxComponent } from "../../CRMInputCheckboxComponent/CRMInputCheckboxComponent";
import { CRMParagraph } from "../CRMParagraph/CRMParagraph";
import { CursorPointer } from "../../CursorPointer/CursorPointer";
import { WeightSemiBold } from "../../WeightSemiBold/WeightSemiBold";
import { CRMBlockFilter } from "../CRMBlockFilter/CRMBlockFilter";
import { TIcon } from "../../CRMIcon/CRMIcon";
import { THexColor } from "../../../models/StringLiterals";
import { SpacingColumn } from "../../BuildingBlocks/SpacingColumn";
import { WeightBold } from "../../WeightBold/WeightBold";
import { Background } from "../../BuildingBlocks/Background";

export const CRMBlockFilterCheckboxes = <A extends string>(props: React.PropsWithChildren<{
    options: Array<A>,
    optionsSelected: Array<A>,
    heading?: string,
    popoutPosition?: "bottom" | "top",
    backgroundColour?: THexColor | "transparent",
    icon?: TIcon,
    iconColour?: THexColor,
    borderLeftColour?: THexColor | "none",
    displayBadgeCount?: boolean,
    onSelectedChange: (selected: Array<A>) => void,
}>): JSX.Element => {
    const displayBadgeCount = props.displayBadgeCount || false;

    const optionsSelected =
        pipe(
            props.optionsSelected,
            array.filter((selected) => props.options.includes(selected)),
        );

    const toggleSection = (section: A) =>
        pipe(
            optionsSelected.includes(section)
                ? pipe(
                optionsSelected,
                    array.filter((s) => s !== section),
                )
                : [
                    ...optionsSelected,
                    section,
                ],
            props.onSelectedChange,
        );

    return <CRMBlockFilter
        backgroundColour={props.backgroundColour}
        popoutPosition={props.popoutPosition}
        icon={props.icon}
        iconColour={props.iconColour}
        borderLeftColour={props.borderLeftColour}
        badgeCount={
            displayBadgeCount
                ? optionsSelected.length
                : undefined
        }
    >
        <Padding
            type="vertical"
            spacing={CRMSpacing.TINY}
        >
            <MinWidth width="100%">
                <HiddenScroll maxHeight="calc(100vh - 260px)">
                    <SpacingColumn spacing={CRMSpacing.MEDIUM}>
                        {/* HEADING */}
                        {props.heading &&
                            <Background padding={`${CRMSpacing.MEDIUM} ${CRMSpacing.MEDIUM} 0px ${CRMSpacing.MEDIUM}`}>
                                <CRMParagraph>
                                    <WeightBold>
                                        {props.heading}
                                    </WeightBold>
                                </CRMParagraph>
                            </Background>
                        }

                        {/* OPTIONS */}
                        <div>
                            {pipe(
                                props.options,
                                array.sort(Ord),
                                array.map<A, [A, boolean]>((section) => [
                                    section,
                                    optionsSelected.includes(section),
                                ]),
                                array.map(([section, isSelected]) =>
                                    <BorderBetween
                                        key={section}
                                        spacing={CRMSpacing.TINY}
                                        borderColour={CRMColors.NEUTRAL_8}
                                    >
                                        <CursorPointer onClick={() => toggleSection(section)}>
                                            <Padding
                                                type="horizontal"
                                                spacing={CRMSpacing.MEDIUM}
                                            >
                                                <SpacingRow
                                                    spacing={CRMSpacing.MEDIUM}
                                                    justifyContent="space-between"
                                                    alignItems="center"
                                                >
                                                    {/* LABEL */}
                                                    <CRMParagraph>
                                                        <WeightSemiBold>
                                                            <span style={{userSelect: "none"}}>{snakeCaseToCopyText(section)}</span>
                                                        </WeightSemiBold>
                                                    </CRMParagraph>

                                                    {/* CHECKBOX */}
                                                    <CRMInputCheckboxComponent
                                                        checked={isSelected}
                                                        onClick={() => undefined}
                                                    />
                                                </SpacingRow>
                                            </Padding>
                                        </CursorPointer>
                                    </BorderBetween>
                                )
                            )}
                        </div>
                    </SpacingColumn>
                </HiddenScroll>
            </MinWidth>
        </Padding>
    </CRMBlockFilter>
};
