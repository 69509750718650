import React from "react";
import { CRMSpacing } from "../../../../models/CRMSpacing";
import { CRMColors } from "../../../../models/CRMColors";
import { Background } from "../../../BuildingBlocks/Background";
import { SpacingColumn } from "../../../BuildingBlocks/SpacingColumn";
import { CRMLink } from "../../../CRMLink/CRMLink";
import { TCaseForm } from "../../../../../../domain/codecs/form/CaseForm";
import { pipe } from "fp-ts/lib/function";
import { array, option } from "fp-ts";
import { defaultSearchProviderFullName, defaultSearchProviderPortalUrl } from "../../../../../../domain/defaultSearchProvider";
import { Text } from "../../../BuildingBlocks/Text";
import { FontQuicksand } from "../../../BuildingBlocks/FontQuicksand";
import { CRMFontSizes } from "../../../../models/CRMFontSizes";
import { Underline } from "../../../Underline/Underline";
import { WeightBold } from "../../../WeightBold/WeightBold";
import { InlineFlex } from "../../../BuildingBlocks/InlineFlex";
import { Shape } from "../../../BuildingBlocks/Shape";
import { Relative } from "../../../BuildingBlocks/Relative";
import { CursorPointer } from "../../../CursorPointer/CursorPointer";
import { CRMIcon } from "../../../CRMIcon/CRMIcon";
import { AnimationPopout } from "../../../BuildingBlocks/AnimationPopout";
import { Absolute } from "../../../BuildingBlocks/Absolute";
import { useOpenClose } from "../../../../hooks/UseOpenClose";
import { SpacingRow } from "../../../BuildingBlocks/SpacingRow";
import { CRMClickCopyWrap } from "../../../Simple/CRMClickCopyWrap/CRMClickCopyWrap";

type TCaseDocumentsHelpProps = {
    caseForm: TCaseForm;
};

export const CRMCaseDocumentsHelp = (props: React.PropsWithChildren<TCaseDocumentsHelpProps>): JSX.Element => {

    const {
        ref,
        isOpen,
        toggleOpen,
    } = useOpenClose();

    const ResourceLinks = [
        ["SDLT", "https://www.gov.uk/sdlt-online"],
        ["LTT", "https://lttservice.wra.gov.wales/"],
        ["Armalytix", "https://portal.armalytix.com/#/reports"],
        ["Stewart Title", "https://www.stewartsolution.com/Transaction/Welcome"],
        ["CLS", "https://orders.clsl.co.uk/ordering/cases"],
        ["HMRC", "https://www.access.service.gov.uk/login/signin/creds"],
        ["Cashroom", "https://www.thecashroomportal.co.uk"],
    ];
    
    const SearchProviderDetails = pipe(
        props.caseForm.children.introducers,
        array.findFirst((introducer) => introducer.id === props.caseForm.children.introducer_id),
        option.map(({search_provider_name, search_provider_url, custom_search_ordering_notes}) => ({
            search_provider_name,
            search_provider_url,
            search_ordering_notes: custom_search_ordering_notes,
        })),
        option.getOrElse(() => ({
            search_provider_name: defaultSearchProviderFullName,
            search_provider_url: defaultSearchProviderPortalUrl,
            search_ordering_notes: "",
        }))
    );

    const DoesSearchProviderHaveInsurance = SearchProviderDetails.search_provider_name === defaultSearchProviderFullName;

    

    return (
        <SpacingRow
            spacing={CRMSpacing.MEDIUM}
            childSize="1fr 1fr 1fr"
            alignItems="flex-start"
        >   
            {/* USEFUL LINKS */}
            <Card>
                <Description>
                    <WeightBold>Useful links: </WeightBold>
                    {ResourceLinks.map(([linkName, link]) => <><Link url={link}>{linkName}</Link>, </> )}
                </Description>
            </Card>
            
            {/* INSURANCE */}
            <Card>
                <SpacingColumn spacing={CRMSpacing.TINY}>
                    {/* SEARCH PACK */}
                    <Description>
                        <WeightBold>Search pack{DoesSearchProviderHaveInsurance && " & Chancel Insurance"}: </WeightBold>
                        <Link url={SearchProviderDetails.search_provider_url}>
                            {SearchProviderDetails.search_provider_name}
                        </Link>
                    </Description>
                    
                    {/* CHANCEL INSURANCE */}
                    {SearchProviderDetails.search_provider_name !== defaultSearchProviderFullName && 
                        <Description>
                            <WeightBold>Chancel Insurance: </WeightBold>
                            <Link url={SearchProviderDetails.search_provider_url}>
                                {defaultSearchProviderFullName}
                            </Link>
                        </Description>
                    }
                </SpacingColumn>
            </Card>

            {/* POST */}
            <Card>
                <SpacingColumn spacing={CRMSpacing.TINY}>
                    {/* POST - TAG */}
                    {props.caseForm.edited.klyant_matter_id &&
                        <CRMClickCopyWrap
                            value={props.caseForm.edited.klyant_matter_id}
                        >
                            <Description>
                                <WeightBold>Post Tag: </WeightBold>
                                {props.caseForm.edited.klyant_matter_id}
                            </Description>
                        </CRMClickCopyWrap>
                    }
                    
                    {/* POST - ACTION */}
                    <Description>
                        <WeightBold>Post Action: </WeightBold>
                        {props.caseForm.children.can_forward_or_shred_post
                            ? "Upload & forward or shred."
                            : "Upload & store."
                        }
                    </Description>
                </SpacingColumn>
            </Card>
        </SpacingRow>
    );
};

const Card = (props: React.PropsWithChildren<{}>) => {
    return (
        <Background
            color={CRMColors.NEUTRAL_PAPER}
            padding={CRMSpacing.MEDIUM}
            borderRadius="8px"
            borderDefinition={`1px solid ${CRMColors.NEUTRAL_8}`}
            width="100%"
        >
            {props.children}
        </Background>
    )
}

type TLinkProps = {
    url: string;
}

const Link = (props: React.PropsWithChildren<TLinkProps>) => {
    return (
        <CRMLink
            target="_blank"
            href={props.url}
            linkStyle="normal"
        >
            <FontQuicksand>
                <Text 
                    size={CRMFontSizes.SMALL}
                    lineHeight={1.4}
                >
                    <Underline>
                        {props.children}
                    </Underline>
                </Text>
            </FontQuicksand>
        </CRMLink>
    );
}


const Description = (props: React.PropsWithChildren<{}>) => {
    return (
        <InlineFlex>
            <FontQuicksand>
                <Text
                    size={CRMFontSizes.SMALL}
                    lineHeight={1.4}
                    weight={500}
                >
                    {props.children}
                </Text>
            </FontQuicksand>
        </InlineFlex>
    )
}