import * as t from "io-ts";
import * as regexes from "../../shared/src/regexes";
import { option } from "fp-ts";
import * as Listing3 from "./Listing3";
import { all } from "../../shared/src/validation/compose/all";
import { inOrder } from "../../shared/src/validation/compose/inOrder";
import { object } from "../../shared/src/validation/create/object";
import { regex } from "../../shared/src/validation/create/regex";
import { string } from "../../shared/src/validation/basic/string";
import { nonEmptyString } from "../../shared/src/validation/basic/nonEmptyString";
import { TValidationError } from "../../shared/src/validation/Error";

const codec =
    t.type({
        building_name: t.string,
        building_number: t.string,
        postcode: t.string,
    });

type T = t.TypeOf<typeof codec>;

export const validator = (userInput: unknown): option.Option<TValidationError> => all(
    object({
        building_name: string,
        building_number: string,
        postcode: inOrder(
            nonEmptyString,
            regex(regexes.constants.POSTCODE_REGEX),
        ),
    }),
)(userInput);

export const fromListing3 = (p: Listing3.T): T => ({
    building_name: p.building_name,
    building_number: p.building_number,
    postcode: p.postcode || "",
});

export const toRequestProperties = (p: T): {
    building_number_or_name: string;
    postcode: string;
} => ({
    building_number_or_name: p.building_number || p.building_name,
    postcode: p.postcode,
});
