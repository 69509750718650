import { array } from "fp-ts";
import { pipe } from "fp-ts/lib/function";
import { Ord } from "fp-ts/lib/number";
import { contramap } from "fp-ts/lib/Ord";
import React from "react";
import { TQuoteRateFeeTransferValue } from "../../../../../domain/codecs/QuoteRateFee";
import { multipleToIncludeVAT } from "../../../../../domain/constants";
import { fromNumber } from "../../../../../domain/models/TCurrency";
import { CRMColors } from "../../../models/CRMColors";
import { CRMFontSizes } from "../../../models/CRMFontSizes";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { BorderBetween } from "../../BuildingBlocks/BorderBetween";
import { FontRoboto } from "../../BuildingBlocks/FontRoboto";
import { FontSize } from "../../BuildingBlocks/FontSize";
import { SpacingColumn } from "../../BuildingBlocks/SpacingColumn";
import { SpacingRow } from "../../BuildingBlocks/SpacingRow";
import { CRMIcon } from "../../CRMIcon/CRMIcon";
import { CRMParagraph } from "../../Simple/CRMParagraph/CRMParagraph";
import { WeightMedium } from "../../WeightMedium/WeightMedium";
import { WeightRegular } from "../../WeightRegular/WeightRegular";
import { WeightSemiBold } from "../../WeightSemiBold/WeightSemiBold";

export const orderFee = contramap((fee: {when_greater_than_or_equal_to_property_price_pence: number | null}) => fee.when_greater_than_or_equal_to_property_price_pence || 0)(Ord);

export const shortPrice = (pricePence: number) => fromNumber(pricePence / 100, 2);

export const displayFeeBand = (min: number, max: number): JSX.Element =>
    <>
        {
            // ANY PRICE BAND
            min === 0 && max === 0 ? <FontRoboto>
                <WeightMedium>
                    <FontSize size={CRMFontSizes.SMALL}>
                        At any price
                    </FontSize>
                </WeightMedium>
            </FontRoboto>

            // UPTO PRICE BAND
            : min === 0 && max > 0 ? <FontRoboto>
                <WeightMedium>
                    <FontSize size={CRMFontSizes.SMALL}>
                        Upto {shortPrice(max)}
                    </FontSize>
                </WeightMedium>
            </FontRoboto>

            // MORE THAN PRICE BAND
            : min > 0 && max === 0 ? <FontRoboto>
                <WeightMedium>
                    <FontSize size={CRMFontSizes.SMALL}>
                        More than {shortPrice(min)}
                    </FontSize>
                </WeightMedium>
            </FontRoboto>

            // BANDS WITH A CUSTOM MIN AND MAX
            : <FontSize size={CRMFontSizes.SMALL}>
                <SpacingRow
                    spacing={CRMSpacing.TINY}
                    justifyContent="start"
                    alignItems="center"
                >
                    {/* MIN */}
                    <FontRoboto>
                        <WeightMedium>
                            {shortPrice(min)}
                        </WeightMedium>
                    </FontRoboto>

                    {/* ICON */}
                    <CRMIcon
                        iconName="arrow-right"
                        size="small"
                        colour="neutral-ink"
                    />

                    {/* MAX */}
                    <FontRoboto>
                        <WeightMedium>
                            {shortPrice(max)}
                        </WeightMedium>
                    </FontRoboto>
                </SpacingRow>
            </FontSize>
        }
    </>;

export const CRMQuoteRatesSingleViewFeesTransferValue = (props: React.PropsWithChildren<{
    transfer_value_fees: Array<TQuoteRateFeeTransferValue>,
    has_vat: boolean,
}>): JSX.Element =>
    <div>
        {pipe(
            props.transfer_value_fees,
            array.sortBy([orderFee]),
            array.mapWithIndex((i, fee) =>
                <BorderBetween
                    key={i}
                    borderColour={CRMColors.NEUTRAL_8}
                    borderWidth="1px"
                    spacing={CRMSpacing.MEDIUM}
                >
                    <SpacingRow
                        spacing={CRMSpacing.MEDIUM}
                        justifyContent="space-between"
                        alignItems="flex-start"
                    >
                        {/* BAND */}
                        <div>
                            {displayFeeBand(fee.when_greater_than_or_equal_to_property_price_pence || 0, fee.when_less_than_property_price_pence || 0)}
                        </div>

                        {/* AMOUNT & AMOUNT + VAT (IF APPLICABLE) */}
                        <SpacingColumn
                            spacing={CRMSpacing.TINY}
                            justifyContent="end"
                        >
                            {fee.fee_pence > 0
                                ? <>
                                    {/* AMOUNT */}
                                    <CRMParagraph justifyContent="end">
                                        <WeightMedium>
                                            {fromNumber(fee.fee_pence / 100, 2)}
                                        </WeightMedium>
                                    </CRMParagraph>

                                    {/* VAT */}
                                    {props.has_vat &&
                                        <CRMParagraph fontSize={CRMFontSizes.X_SMALL}>
                                            <WeightRegular>
                                                {fromNumber((fee.fee_pence / 100) * multipleToIncludeVAT, 2)} (INC VAT)
                                            </WeightRegular>
                                        </CRMParagraph>
                                    }
                                </>
                                : <>
                                    {/* PERCENTAGE */}
                                    <CRMParagraph justifyContent="end">
                                        <WeightMedium>
                                            <FontRoboto>
                                                <WeightSemiBold>
                                                    {fee.fee_progressive_percentage_of_property_price}%
                                                </WeightSemiBold>
                                            </FontRoboto>&nbsp;
                                            of property price
                                        </WeightMedium>
                                    </CRMParagraph>
                                </>}
                        </SpacingColumn>
                    </SpacingRow>
                </BorderBetween>
            ),
        )}
    </div>;
