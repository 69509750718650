import React from "react";
import { TCasesBankAccountVerificationStatus1 } from "../../../../../../domain/codecs/CasesBankAccountVerificationStatus";
import { FrontIcon } from "../FrontIcon/FrontIcon";

type TFrontVerificationIconProps = {
    status: TCasesBankAccountVerificationStatus1;
};

export const FrontVerificationIcon = (props: TFrontVerificationIconProps): JSX.Element => {

    return (
        <div 
            className={`
                front-verification-icon
                front-verification-icon--${props.status}
            `}
        >
            {props.status === "unsubmitted" &&
                <FrontIcon
                    iconName="clock"
                    colour="neutral-ink"
                    size="tiny"
                />
            }
            
            {props.status === "pending" &&
                <FrontIcon
                    iconName="pending-locked"
                    colour="neutral-ink"
                    size="tiny"
                />
            }
            
            {props.status === "rejected" &&
                <FrontIcon
                    iconName="shield-error"
                    colour="neutral-ink"
                    size="tiny"
                />
            }
            
            {props.status === "approved" &&
                <FrontIcon
                    iconName="shield-done"
                    colour="neutral-ink"
                    size="tiny"
                />
            }
        </div>
    );
};
