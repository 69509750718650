import React from "react";
import { TRoadblocksForm, TRoadblocksListItem } from "../../../../../domain/codecs/form/RoadblockForm";
import { SpacingColumn } from "../../BuildingBlocks/SpacingColumn";
import { SpacingRow } from "../../BuildingBlocks/SpacingRow";
import { FontQuicksand } from "../../BuildingBlocks/FontQuicksand";
import { Text } from "../../BuildingBlocks/Text";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { pipe } from "fp-ts/lib/function";
import { CRMRoadblockTableSheetItem } from "../CRMRoadblockTableSheetItem/CRMRoadblockTableSheetItem";
import { CRMEmptyPlaceholder } from "../../Simple/CRMEmptyPlaceholder/CRMEmptyPlaceholder";
import { Background } from "../../BuildingBlocks/Background";
import { CRMColors } from "../../../models/CRMColors";
import { CRMThinColoredButton } from "../../Simple/CRMThinColoredButton/CRMThinColoredButton";
import { WeightSemiBold } from "../../WeightSemiBold/WeightSemiBold";
import { AnimationPopout } from "../../BuildingBlocks/AnimationPopout";
import { ShineShape } from "../../BuildingBlocks/ShineShape";
import { TJobsFormIO } from "../../../../../domain/codecs/formIO/JobsIO";
import { TJob1 } from "../../../../../domain/codecs/Job";
import { TGroupSetting, TJobDefinition, getJobDefinition, getJobsInGroupPartition } from "../../CRMJobs/CRMJobsOverlay/CRMJobsDefinitions";
import { getGoToRoadblockRouteAction, makeJobLinkOutOfRouteAction } from "../../CRMJobs/CRMJobsOverlay/CRMJobsCardLinkGenerators";
import { CRMStatusPill } from "../../Simple/CRMStatusPill/CRMStatusPill";
import { snakeCaseToCopyText } from "../../../../../shared/src/util";
import { TJobsFilter } from "../../../../../domain/codecs/statePartials/JobsPartial";
import { array } from "fp-ts";


type TRoadblockTableSheetProps = {
    roadblocksForm: TRoadblocksForm;
    jobsForm: TJobsFormIO;
    jobsFilter: TJobsFilter;
    jobsGroupingMode: TGroupSetting;
    onLoadMore: () => void;
};

export const CRMRoadblockTableSheet = (props: React.PropsWithChildren<TRoadblockTableSheetProps>): JSX.Element => {

    // Roadblocks
    const RoadblockItems: Array<TRoadblocksListItem> = 
        props.jobsFilter.filter_by_type === null
            ? props.roadblocksForm.children.roadblocks
            : []
    ;
    const IsLoadingMoreRoadblocks = props.roadblocksForm.status === "submitting" || props.roadblocksForm.status === "success";
    const IsThereMoreToRoadblocksLoad = props.roadblocksForm.children.counts.available_count > props.roadblocksForm.children.roadblocks.length;
    const HasRoadblocks = RoadblockItems.length > 0;

    // Jobs
    const JobItems = pipe(
        props.jobsForm.output.jobs,
        array.filter((job) => {
            let isFilteredByType = props.jobsFilter.filter_by_type !== null;
            let isFilteredByHandler = props.jobsFilter.filter_by_handler !== null;

            if (
                props.roadblocksForm.edited.actionableStatus === "blocked" ||
                props.roadblocksForm.edited.roadblockType !== null
            ) {
                return false;
            }
            
            if (isFilteredByType && isFilteredByHandler) {
                return (
                    job.type === props.jobsFilter.filter_by_type
                    && job["case_handler_id"] === props.jobsFilter.filter_by_handler
                );
            }

            if (isFilteredByType) {
                return job.type === props.jobsFilter.filter_by_type;
            }

            if (isFilteredByHandler) {
                return job["case_handler_id"] === props.jobsFilter.filter_by_handler;
            }

            return true;
        })
    );
    const HasJobs = JobItems.length > 0;
    const { ContextualJobs, GeneralJobs } = pipe(
        props.jobsGroupingMode,
        getJobsInGroupPartition(JobItems),
        ({ left, right }) => ({
            ContextualJobs: right,
            GeneralJobs: left.length > 0 ? left : JobItems
        })
    );
    const HasContextualJobs = ContextualJobs.length > 0;
    const HasGeneralJobs = GeneralJobs.length > 0;
    const ContextualJobsGroupName = pipe(
        props.jobsGroupingMode?.groupBy || "",
        snakeCaseToCopyText
    );


    const formatJobName = (jobType: string) => jobType.replace(/^sl_|^sh_/g, '');
    
    const makeRoadblockLink = (roadblock: TRoadblocksListItem) => pipe(
        getGoToRoadblockRouteAction(roadblock),
        makeJobLinkOutOfRouteAction
    )

    const makeJobLink = (job: TJob1, definition: TJobDefinition) => makeJobLinkOutOfRouteAction(definition.routeAction);
    
    const getJobName = (job: TJob1, definition: TJobDefinition) => {
        if (job.type === "sh_listing_ad_hoc" || job.type === "sh_listing_enquiry_ad_hoc") {
            return `Adhoc - ${job.task}`;
        }
        if (job.type === "sl_case_ad_hoc") {
            return `Adhoc - ${job.job}`;
        }
        return definition.title ? definition.title : formatJobName(job.type);
    }

    return (
        <div 
            className="crm-roadblock-table-sheet"
            style={{
                height: "calc(100vh - 210px)"
            }}
        >
            {/* [TABLE SECTION] - HEADER */}
            <div className="crm-roadblock-table-sheet__header">
                <SpacingRow
                    spacing={CRMSpacing.MEDIUM}
                    childSize="2.5fr 2fr 1.5fr 1fr"
                >
                    <HeaderCell>Name</HeaderCell>
                    <HeaderCell>Property</HeaderCell>
                    <HeaderCell>Clients</HeaderCell>
                    <HeaderCell>Status</HeaderCell>
                </SpacingRow>
            </div>

            <div className="crm-roadblock-table-sheet__body">

                {/* [PLACEHOLDER] - NO ROADBLOCKS */}
                {!HasRoadblocks && !HasJobs &&
                    <Background 
                        color={CRMColors.NEUTRAL_PAPER} 
                        padding={`80px 0px 0px 0px`}
                        height="100%"
                    >
                        <CRMEmptyPlaceholder iconColor={CRMColors.NEUTRAL_8}>
                            No roadblocks or jobs to pick up.
                        </CRMEmptyPlaceholder>
                    </Background>
                }

                {/* [TABLE SECTION] - ROWS */}
                <SpacingColumn>
                    
                    {/* [TABLE SECTION] - CONTEXTUAL JOB ROWS */}
                    {HasContextualJobs &&
                        <SpacingColumn>
                            <CategoryTitle title={`Jobs in this ${ContextualJobsGroupName}`} />

                            {/* [ITEMS] - JOBS */}
                            <SpacingColumn>
                                {ContextualJobs.map((job, key) => {
                                    let definition = getJobDefinition(job);
                                    return (
                                        <CRMRoadblockTableSheetItem
                                            href={makeJobLink(job, definition)}
                                            key={key}
                                            name={getJobName(job, definition)}
                                            caseAddress={job["address"] || ""}
                                            clients={job["users"] || ""}
                                            status={"open"} // Always open for jobs
                                        />
                                    );
                                })}
                            </SpacingColumn>
                        </SpacingColumn>
                    }

                    {HasRoadblocks &&
                        <SpacingColumn>
                            <CategoryTitle title="Roadblocks" />

                            {/* [ITEMS] - ROADBLOCKS */}
                            <SpacingColumn>
                                {RoadblockItems.map((item, key) => (
                                    <CRMRoadblockTableSheetItem
                                        href={makeRoadblockLink(item)}
                                        key={key}
                                        name={item.type}
                                        caseAddress={item.case_address}
                                        clients={item.clients}
                                        status={item.status}
                                    />
                                ))}
                            </SpacingColumn>
                            
                            {/* [BUTTON] - LOAD MORE */}
                            {(IsThereMoreToRoadblocksLoad && !IsLoadingMoreRoadblocks) &&
                                <AnimationPopout>
                                    <Background padding={`${CRMSpacing.LARGE} 0px`}>
                                        <SpacingRow justifyContent="center">
                                            <CRMThinColoredButton
                                                backgroundColor={CRMColors.NEUTRAL_2}
                                                fontColor={CRMColors.NEUTRAL_12}
                                                label={<WeightSemiBold>See more</WeightSemiBold>}
                                                onClick={props.onLoadMore}
                                                icon="down-arrow"
                                            />
                                        </SpacingRow>
                                    </Background>
                                </AnimationPopout>
                            }

                            {/* [PLACEHOLDER] - LOADING MORE */}
                            {IsThereMoreToRoadblocksLoad && IsLoadingMoreRoadblocks &&
                                <Background padding={`${CRMSpacing.LARGE} 0px`}>
                                    <SpacingRow justifyContent="center">
                                        <ShineShape
                                            borderRadius="3px"
                                            backgroundColor={CRMColors.NEUTRAL_8} 
                                            shineColor={CRMColors.NEUTRAL_12}
                                            duration={1500}
                                            height={"30px"}
                                            width={"110px"}
                                        />
                                    </SpacingRow>
                                </Background>
                            }
                        </SpacingColumn>
                    }
                    
                    {/* [TABLE SECTION] - GENERAL JOB ROWS */}
                    {HasGeneralJobs &&
                        <SpacingColumn>
                            <CategoryTitle title="Other Jobs" />

                            {/* [ITEMS] - JOBS */}
                            <SpacingColumn>
                                {GeneralJobs.map((job, key) => {
                                    let definition = getJobDefinition(job);
                                    return (
                                        <CRMRoadblockTableSheetItem
                                            href={makeJobLink(job, definition)}
                                            key={key}
                                            name={getJobName(job, definition)}
                                            caseAddress={job["address"] || ""}
                                            clients={job["users"] || ""}
                                            status={"open"} // Always open for jobs
                                        />
                                    );
                                })}
                            </SpacingColumn>
                        </SpacingColumn>
                    }
                </SpacingColumn>
            </div>
        </div>
    );
};

const CategoryTitle = (props: { title: string }) => {
    return (
        <Background
            padding={`${CRMSpacing.MEDIUM} ${CRMSpacing.SMALL}`}
            borderBottomDefinition={`1px solid ${CRMColors.NEUTRAL_6}`}
        >
            <CRMStatusPill
                pillColor={CRMColors.PRIMARY_2}
                borderColor={CRMColors.PRIMARY_2}
                textColor={CRMColors.NEUTRAL_PAPER}
                label={props.title}
            />
        </Background>
    )
}

const HeaderCell = (props: React.PropsWithChildren<{}>) => {
    return (
        <FontQuicksand>
            <Text size={14} weight={700} lineHeight={1.4}>
                {props.children}
            </Text>
        </FontQuicksand>
    );
}
