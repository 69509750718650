import * as t from "io-ts";

export const constants = {
    FORM_DATA: "multipart/form-data",
    JSON: "application/json",
    URL_ENCODED: "application/x-www-form-urlencoded",
    OCTECT_STREAM: "application/octet-stream"
};

const codec = t.union([
    t.literal(constants.FORM_DATA),
    t.literal(constants.JSON),
    t.literal(constants.URL_ENCODED),
    t.literal(constants.OCTECT_STREAM),
]);

export type T = t.TypeOf<typeof codec>;
