import { pipe } from "fp-ts/lib/function";
import { getRouteBlockData } from "../router/getRouteBlockData";
import { array, eq, option, task } from "fp-ts";
import { BlockMap } from "../../models/Blocks";
import { Routes } from "../router/routerRoutes";
import { TDispatch } from "../TDispatch";
import { TGetCodecState } from "../applyActions";
import { TBlockType } from "../router/routerTypes";

/* Thomas Freire Camacho 2023-11-02
James Flight and I have a very long conversation on the best way to implement automatic block refreshing. We thought
maybe it would be better to have this more structured and rather than having function that trigger dispatches they could
actually run the logic from the action files. But this opened up questions about how to best handle the 3 states for
trigger blocks to do there main get requests. These are A) Being directly opened in the app by a user interaction, 
B) Being refreshed by a change in another roadblock, and C) By having a page link copied and opened / page refresh. 
The options discussed were to 1) leave the code trigger actions A) and C) to be controlled by the block itself as it 
currently is (usually using react hooks) or 2) bring all logic into the runAutomaticUpdates (A, B, and C). Pros of 2) 
is that we have one centralised place, cons being that unlike 1) you have to understand the entire roadblock and how it
interacts with multiple copies of itself etc without being able to see the actual visual code. We had many solutions to 
solving both of these problems, but they all felt like guess at how the system will evolve in the future and take time 
to implement and may be wrong. We decided based on this we would stick with the naive solution that had already been 
coded and is currently working. But make sure that it should be easy to refactor to a better solution (likely using 
rxjs) in the future.

NB: This comment is being left on the automaticUpdate function file and the Blocks definition file.
*/
export const runAutomaticUpdates = (
    routes: Routes,
    dispatch: TDispatch,
    getState: TGetCodecState,
    triggeringBlockToIgnore: TBlockType
): task.Task<unknown[]> =>
    {
        // This is a patch for the fact that the below blocks are currently very tightly coupled and share endpoints for
        // edits and gets, therefor an update in one would trigger the get in another but that would affect the original
        // updater which causes issues with state overwriting. Since the idea is in the future all blocks are supposed to
        // be completely seperate instead of implementing a solution to solve this problem generically we just treat each
        // one of them to be the same as each other and filter out the original and the connected blocks
        const blocksThatShareDetailsEndpoints: TBlockType[] = [
            "case_details",
            "case_overview",
            "case_quotes",
        ];
        const blocksToIgnore = blocksThatShareDetailsEndpoints.includes(triggeringBlockToIgnore) ? blocksThatShareDetailsEndpoints : [triggeringBlockToIgnore];
        return pipe(
            getRouteBlockData(routes),
            option.fold(
                () => [],
                (a) => a.blocksOpened.map((b) => b.type)
            ),
            array.uniq(eq.fromEquals<TBlockType>((x, y) => x === y)),
            array.filter((blockType) => !blocksToIgnore.includes(blockType)),
            array.map((type) => BlockMap[type].refreshAction(dispatch, getState, routes)),
            array.sequence(task.ApplicativePar)
        );
    };