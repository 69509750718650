import * as QueryStringFilterMappings from "./QueryStringFilterMappings";
import * as QueryStringFilterGroup1 from "./QueryStringFilterGroup1";
import * as QueryStringFilterMappingFunction from "./QueryStringFilterMappingFunction";
import { TValidationFunction } from "../../shared/src/validation/Function";

export const filterMappings: QueryStringFilterMappings.T = {
    string: {
        listing_id: QueryStringFilterMappingFunction.createForString("listings_enquiries.listing_id"),
        enquiry_id: QueryStringFilterMappingFunction.createForString("listings_enquiries_offers.listings_enquiry_id"),
        status: QueryStringFilterMappingFunction.createForString("listings_enquiries_offers.status"),
    },
};

export const validator: TValidationFunction = QueryStringFilterGroup1.createValidator(filterMappings);

export const createForListingNotWithdrawn = (listingId: string): QueryStringFilterGroup1.T => ({
    type: "GROUP",
    conditional: "AND",
    filters: [
        {
            type: "FILTER",
            property: "listing_id",
            operator: "EQUALS_STRING",
            value: listingId,
        },
        {
            type: "FILTER",
            property: "status",
            operator: "NOT_EQUALS_STRING",
            value: "withdrawn",
        },
    ],
});

export const createForListingPendingOrAccepted = (listingId: string): QueryStringFilterGroup1.T => ({
    type: "GROUP",
    conditional: "AND",
    filters: [
        {
            type: "FILTER",
            property: "listing_id",
            operator: "EQUALS_STRING",
            value: listingId,
        },
        {
            type: "GROUP",
            conditional: "OR",
            filters: [
                {
                    type: "FILTER",
                    property: "status",
                    operator: "EQUALS_STRING",
                    value: "pending",
                },
                {
                    type: "FILTER",
                    property: "status",
                    operator: "EQUALS_STRING",
                    value: "accepted",
                },
            ],
        },
    ],
});

export const createForListingNotPendingOrAccepted = (listingId: string): QueryStringFilterGroup1.T => ({
    type: "GROUP",
    conditional: "AND",
    filters: [
        {
            type: "FILTER",
            property: "listing_id",
            operator: "EQUALS_STRING",
            value: listingId,
        },
        {
            type: "FILTER",
            property: "status",
            operator: "NOT_EQUALS_STRING",
            value: "pending",
        },
        {
            type: "FILTER",
            property: "status",
            operator: "NOT_EQUALS_STRING",
            value: "accepted",
        },
    ],
});
