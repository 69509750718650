import React from "react";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { THexColor } from "../../../models/StringLiterals";
import { BackgroundColour } from "../../BuildingBlocks/BackgroundColour";
import { Border } from "../../BuildingBlocks/Border";
import { Padding } from "../../BuildingBlocks/Padding";
import { CRMParagraph } from "../../Simple/CRMParagraph/CRMParagraph";

export const CRMEmailNotificationBar = (props: React.PropsWithChildren<{
    backgroundColour: THexColor;
    borderColour: THexColor;
}>): JSX.Element =>
    <Border
        type="top"
        definition={`solid 1px ${props.backgroundColour}`}
    >
        <BackgroundColour
            colour={props.backgroundColour}
        >
            <Padding
                type="custom"
                spacing={`${CRMSpacing.TINY} ${CRMSpacing.LARGE}`}
            >
                <CRMParagraph>
                    {props.children}
                </CRMParagraph>
            </Padding>
        </BackgroundColour>
    </Border>;
