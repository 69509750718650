import { option } from "fp-ts";
import { pipe } from "fp-ts/lib/function";
import { fullNameToTuple } from "./fullNameToTuple";
import { isAFullNameOption } from "./isAFullNameOption";

export const getNamePartOrBlank = (text: unknown, part: "first" | "last"): string =>
    pipe(
        isAFullNameOption(text),
        option.fold(
            () => "",
            (fullName) => fullNameToTuple(fullName)[part === "first" ? 0 : 1]
        )
    )
;