import React, { useState, useEffect } from "react";
import { Padding } from "../../../BuildingBlocks/Padding";
import { FrontCondensedQuestionWrap } from "../FrontCondensedQuestionWrap/FrontCondensedQuestionWrap";
import FrontInputLabelAndErrorWrapComponent from "../FrontInputLabelAndErrorWrapComponent/FrontInputLabelAndErrorWrapComponent";
import { IOption } from "../../../../hooks/UseDropdown";
import { FrontFormRow } from "../FrontFormRow/FrontFormRow";
import { FrontMultiDropdown } from "../FrontMultiDropdown/FrontMultiDropdown";
import { pipe } from "fp-ts/lib/function";
import { array } from "fp-ts";
import { FrontSpacing } from "../../../../models/FrontSpacing";

type TInputQuestionsProps<A extends string> = {
    label: string;
    closedLabel?: string;
    isEditable?: boolean;
    value: Array<A>;
    options: Array<IOption<A>>;
    wrapInFormRow?: boolean;
    displayValidationErrorsIfPresent?: boolean;
    onChange: (value: Array<A>) => void; 
};

export const FrontCondensedMultiSelectDropdownQuestion = <A extends string>(props: React.PropsWithChildren<TInputQuestionsProps<A>>): JSX.Element => {
    const [isOpen, setIsOpen] = useState(props.value.length === 0);

    const getSelectedOptionLabel = () => 
        pipe(
            props.options,
            array.filter((option) => props.value.includes(option.value)),
            array.map((option) => option.label),
            (a) => a.join(" & "),
        )

    const renderDropdown = (): JSX.Element => (
        <Padding type="top" spacing="2px">
            <FrontInputLabelAndErrorWrapComponent label={props.label} errorMessage="Please select from the list" displayError={props.value.length === 0 && props.displayValidationErrorsIfPresent ? true : false}>
                <div style={{marginLeft: FrontSpacing.SMALL_3}}>
                    <FrontMultiDropdown
                        value={props.value}
                        options={props.options}
                        onChange={props.onChange}
                    />
                </div>
            </FrontInputLabelAndErrorWrapComponent>
        </Padding>
    );

    return (
        <FrontCondensedQuestionWrap
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            isEditable={props.isEditable}
            label={props.closedLabel || props.label}
            summary={getSelectedOptionLabel()}
        >
            {props.wrapInFormRow &&
                <FrontFormRow>
                    {renderDropdown()}
                </FrontFormRow>
            }
            
            {props.wrapInFormRow !== true &&
                renderDropdown()
            }
        </FrontCondensedQuestionWrap>
    );
};
