import { IsUnion } from "./codecs/codec";

export const alwaysFalse = () => false;
export const alwaysTrue = () => true;


/**
 * This funky typing means that this function will only compile if a union that contains "unknown" is passed to it
 * @example
 * let a = "yes" as "yes" | "no" | "unknown";
 * let b = "yes" as "yes" | "no";
 * let c = "friends" as string;
 * 
 * notUnknown(a); // valid
 * notUnknown(b); // error
 * notUnknown(c); // error
 */
export const notUnknown = <T extends (IsUnion<T> extends true ? string : never)>(val: "unknown" extends T ? T : never) => val !== "unknown";

/**
 * This funky typing means that this function will only accept generic strings which aren't unions
 * @example
 * let a = "yes" as "yes" | "no" | "unknown";
 * let b = "friends" as string;
 * 
 * notUnknown(a); // error
 * notUnknown(b); // valid
 */
export const notEmptyString = <T extends (IsUnion<T> extends true ? never : string)>(val: T) => val.trim() !== "";