import React from "react";
import * as Tenure1 from "../../../../domain/models/Tenure1";
import * as Listing3 from "../../../../domain/models/Listing3";
import * as DisplayAddress from "../../../../domain/models/DisplayAddress";
import * as ListingStatus1 from "../../../../domain/models/ListingStatus1";
import * as ListingReasonForSale1 from "../../../../domain/models/ListingReasonForSale1";
import * as TCurrency from "../../../../domain/models/TCurrency";
import * as ListingPropertyType1 from "../../../../domain/models/ListingPropertyType1";
import { CRMParagraph } from "../Simple/CRMParagraph/CRMParagraph";
import { CRMColumns } from "../CRMColumns/CRMColumns";
import { CRMSpacer } from "../CRMSpacer/CRMSpacer";
import { CRMLink } from "../CRMLink/CRMLink";
import { WeightBold } from "../WeightBold/WeightBold";
import { pipe } from "fp-ts/lib/function";

class CRMListingKeyInfoComponent extends React.Component<{
    listing: Listing3.T;
}> {
    public render (): JSX.Element {
        return (
            <CRMColumns
                padding="medium"
                columns={[
                    {
                        flex: 0,
                        content: <div className="crmListingKeyInfoComponent__thumbnailContainer"
                            style={{
                                backgroundImage: this.props.listing.images.length ? `url(${this.props.listing.images[0].url}) ` : undefined,
                            }}
                        />,
                    },
                    {
                        flex: 1,
                        content: <div>
                            <CRMParagraph>
                                <CRMLink
                                    href={`/crm/listing/${this.props.listing.id}`}
                                    target="_blank"
                                    linkStyle="normal"
                                >
                                    <WeightBold>
                                        {pipe(
                                            this.props.listing,
                                            DisplayAddress.fromListing3,
                                            DisplayAddress.toString,
                                        )}
                                    </WeightBold>
                                </CRMLink>
                            </CRMParagraph>
                            <CRMSpacer size="medium" />
                            <CRMColumns
                                padding="medium"
                                columns={[
                                    {
                                        flex: 1,
                                        content: <div>
                                            <CRMParagraph>{ListingStatus1.fromValueToDisplayName(this.props.listing.status)}</CRMParagraph>
                                            <CRMParagraph>{Tenure1.toDisplayName(this.props.listing.tenure)}</CRMParagraph>
                                            <CRMParagraph>{`Reason for sale: ${ListingReasonForSale1.fromValueToDisplayName(this.props.listing.reason_for_sale)}`}</CRMParagraph>
                                        </div>,
                                    },
                                    {
                                        flex: 1,
                                        content: <div>
                                            <CRMParagraph>{
                                                this.props.listing.price
                                                    ? TCurrency.fromNumber(this.props.listing.price)
                                                    : "Price not set"
                                            }</CRMParagraph>
                                            <CRMParagraph>
                                                {(
                                                    this.props.listing.property_type === "flat"
                                                    || this.props.listing.property_type === "maisonette"
                                                    || this.props.listing.property_type === "studio"
                                                ) && `${Listing3.getEntranceFloorDisplayName(this.props.listing)} `}
                                                {
                                                    this.props.listing.property_type !== "unknown"
                                                        ? ListingPropertyType1.toDisplayName(this.props.listing.property_type)
                                                        : "Property type unknown"
                                                }
                                            </CRMParagraph>
                                            <CRMParagraph>{
                                                this.props.listing.bedroom_count
                                                    ? `${this.props.listing.bedroom_count.toString()} Bed`
                                                    : "Beds unknown"}
                                                &nbsp;/&nbsp;
                                                {this.props.listing.bathroom_count
                                                    ? `${this.props.listing.bathroom_count.toString()} Baths`
                                                    : "Baths unknown"}
                                            </CRMParagraph>
                                            <CRMParagraph>{
                                                pipe(
                                                    [
                                                        ...this.props.listing.above_ground_floors_count !== null
                                                            ? [`${this.props.listing.above_ground_floors_count.toString()} floors`]
                                                            : [],
                                                        ...this.props.listing.bellow_ground_floors_count !== null
                                                            ? [`${this.props.listing.bellow_ground_floors_count.toString()} basements`]
                                                            : [],
                                                        ...this.props.listing.attics_count !== null
                                                            ? [`${this.props.listing.attics_count.toString()} attics`]
                                                            : [],
                                                    ],
                                                    (arr) => arr.join(", "),
                                                )
                                            }</CRMParagraph>
                                        </div>,
                                    },
                                ]}
                            />
                        </div>,
                    },
                ]}
            />
        );
    }
}

export default CRMListingKeyInfoComponent;
