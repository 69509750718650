import React from "react";
import { TCaseEnquiry1Form } from "../../models/TFormModels";
import { CRMButtonQuaternary } from "../CRMButtonQuaternary/CRMButtonQuaternary";
import { CRMSpacingRow } from "../CRMSpacingRow/CRMSpacingRow";
import { CRMButtonSecondary } from "../CRMButtonSecondary/CRMButtonSecondary";
import { CRMCardOutsidePopupBlank } from "../CRMCardOutsidePopupBlank/CRMCardOutsidePopupBlank";
import { IOption } from "./../../hooks/UseDropdown";
import { CRMFormButton } from "../CRMFormButton/CRMFormButton";
import CRMInputLabelAndErrorWrapComponent from "../CRMInputLabelAndErrorWrapComponent/CRMInputLabelAndErrorWrapComponent";
import { CRMMultiSelectDropdownComponent } from "../CRMMultiSelectDropdownComponent/CRMMultiSelectDropdownComponent";
import { CRMPadding } from "../Simple/CRMPadding/CRMPadding";
import { CRMSpacer } from "../CRMSpacer/CRMSpacer";
import CRMTextAreaComponent from "../CRMTextAreaComponent/CRMTextAreaComponent";
import { CRMTitleForCard } from "../CRMTitleForCard/CRMTitleForCard";
import * as JsonError1 from "../../../../domain/models/JsonError1";
import * as CaseEnquiryDocument1 from "../../../../domain/models/CaseEnquiryDocument1";
import { array, option } from "fp-ts";
import CRMInputGeneralComponent from "../CRMInputs/CRMInputGeneralComponent/CRMInputGeneralComponent";
import { pipe } from "fp-ts/lib/function";

type TEditCaseEnquiryPopupProps = {
    isOpen: boolean;
    enquiryForm: TCaseEnquiry1Form;
    documentOptions: Array<IOption<string>>;
    onChangeDocuments: (documents: Array<CaseEnquiryDocument1.T>) => void;
    onChangeMessage: (message: string) => void;
    onClose: () => void;
    onSubmit: () => void;
};

export const CRMEditCaseEnquiryPopup = (props: TEditCaseEnquiryPopupProps): JSX.Element => {

    const getDocumentNameForId = (id: string): string =>
         pipe(
            props.documentOptions,
            array.findFirst((documentOption) => documentOption.value === id),
            option.map((documentOption) => `${documentOption.label}`),
            option.getOrElse(() => ""),
        );

    return (
        <CRMCardOutsidePopupBlank
            isOpen={props.isOpen}
            onClose={props.onClose}
        >
            <CRMPadding size="large">
                {/* TITLE */}
                <CRMTitleForCard>EDIT ENQUIRY</CRMTitleForCard>
                <CRMSpacer size="large" />

                {/* CHOSE DOCUMENTS DROPDOWN */}
                <CRMInputLabelAndErrorWrapComponent label="For document">
                    <CRMMultiSelectDropdownComponent
                        value={ pipe(
                            props.enquiryForm.edit.documents,
                            array.map((document) => document.id),
                        )}
                        options={props.documentOptions}
                        displayError={JsonError1.doTargetKeysHaveInnerErrors(["documents"], props.enquiryForm.validationErrors)}
                        onChange={(v) => props.onChangeDocuments(
                            v.map((id) =>  pipe(
                                props.enquiryForm.edit.documents,
                                array.findFirst((item) => item.id === id),
                                option.fold(
                                    () => ({id, reference: ""}),
                                    (item) => item,
                                )
                            ))
                        )}
                    />
                </CRMInputLabelAndErrorWrapComponent>
                <CRMSpacer size="medium" />

                {/* REFERENCES */}
                { pipe(
                    props.enquiryForm.edit.documents,
                    array.mapWithIndex((i, document) =>
                        <div key={i} className={`crm-edit-case-enquiry-popup__reference-row ${i === props.enquiryForm.edit.documents.length - 1 ? "crm-edit-case-enquiry-popup__reference-row--last" : ""}`}>
                            <CRMInputLabelAndErrorWrapComponent 
                                label={`Which section of ${getDocumentNameForId(document.id)}`}
                            >
                                <CRMInputGeneralComponent
                                    inputType="text"
                                    value={document.reference}
                                    onChange={(value) => props.onChangeDocuments(props.enquiryForm.edit.documents.map((doc) => {
                                        doc = {...doc};
                                        if (doc.id === document.id) {
                                            doc.reference = value;
                                        }
                                        return doc;
                                    }))}
                                    displayError={JsonError1.doTargetKeysHaveInnerErrors([`documents.${i}.reference`], props.enquiryForm.validationErrors)}
                                />
                            </CRMInputLabelAndErrorWrapComponent>
                        </div>
                    ),
                )}

                {/* SET MESSAGE */}
                <CRMInputLabelAndErrorWrapComponent label="Enquiry">
                    <CRMTextAreaComponent
                        value={props.enquiryForm.edit.message}
                        onChange={props.onChangeMessage}
                        displayError={JsonError1.doTargetKeysHaveInnerErrors(["message"], props.enquiryForm.validationErrors)}
                    />
                </CRMInputLabelAndErrorWrapComponent>
                <CRMSpacer size="medium" />

                {/* BUTTONS */}
                <CRMSpacingRow justifyContent="end">
                    <CRMButtonSecondary
                        label="Close"
                        onClick={props.onClose}
                    />
                    <CRMFormButton
                        formStatus={props.enquiryForm.status}
                        ButtonElement={CRMButtonQuaternary}
                        label="Submit"
                        onClick={props.onSubmit}
                    />
                </CRMSpacingRow>
            </CRMPadding>

        </CRMCardOutsidePopupBlank>
    );
};
