import React from "react";
import { TPixelSize } from "../../models/StringLiterals";

type TEdge = "shadow" | "border";
type TJustifyContent = "space-between" | "flex-end";
type TRounding = "rounded" | "flat";

type TCRMBottomBarProps = {
    height?: TPixelSize;
    edge?: TEdge;
    justifyContent?: TJustifyContent;
    rounding?: TRounding;
};

export const CRMBottomControlBar = (props: React.PropsWithChildren<TCRMBottomBarProps>): JSX.Element => {

    const Edge = props.edge || "shadow";
    const Rounding = props.rounding || "flat";
    const ContentJustification = props.justifyContent || "space-between";

    return (
        <div
            className={`
                crm-bottom-control-bar
                crm-bottom-control-bar--${Edge}
                crm-bottom-control-bar--${Rounding}
            `}
            style={{
                height: props.height || "inherit",
                justifyContent: ContentJustification
            }}
        >
            {props.children}
        </div>
    );
};
