import React from "react";
import * as Listing8 from "../../../../domain/models/Listing8";
import * as JsonInnerError1 from "../../../../domain/models/JsonInnerError1";
import * as Listing7 from "../../../../domain/models/Listing7";
import * as JsonError1 from "../../../../domain/models/JsonError1";
import * as Country2 from "../../../../domain/models/Country2";
import * as TFormStatus from "./../../models/TFormStatus";
import Input from "./../../components/OldFront/Simple/Text/InputText";
import { FormRow } from "../../components/FormTemplateBlocksComponents/FormTemplateBlocks";
import FormButton from "../../components/FormButtonComponent/FormButton";
import { Dropdown } from "../../components/DropdownComponent/Dropdown";
import { enumToIDropdownOptions } from "../../functions/enumToIDropdownOptions";
import { LabelWrapForInputs } from "./../../components/OldFront/Simple/Label/LabelWrapForInputs";
import { ThisOrThatInput } from "../../components/ThisOrThatInputComponent/ThisOrThatInputComponent";

type TEditPropertyAddress = {
    getAddressForm: {
        status: TFormStatus.T;
        fields: NonNullable<Required<Listing8.T>>;
        validationInnerErrors: Array<JsonInnerError1.T>;
    };
    propertyForm: {
        status: TFormStatus.T;
        fields: NonNullable<Required<Listing7.T>>;
        validationInnerErrors: Array<JsonInnerError1.T>;
    };
    getAddressInputChanged: <A extends keyof Listing8.T>(key: A, value: Listing8.T[A]) => void;
    submitGetAddressLookup: () => void;
    propertyInputChanged: <A extends keyof Listing7.T>(key: A, value: NonNullable<Required<Listing7.T>>[A]) => void;
};

export class EditPropertyAddress extends React.Component<React.PropsWithChildren<TEditPropertyAddress>> {

    hasValidationError = <A extends keyof Listing7.T>(inputName: A): boolean => JsonError1.doTargetKeysHaveInnerErrors([inputName], this.props.propertyForm.validationInnerErrors);

    public render (): JSX.Element {
        return (
            <div>
                {/* HOUSE NUMBER & NAME */}
                <FormRow>
                    <LabelWrapForInputs
                        required={true}
                        label="House Number/Name"
                    >
                        <ThisOrThatInput
                            thisInputSize="30%"
                            thisInput={
                                <Input
                                    placeholder="Number"
                                    value={this.props.getAddressForm.fields.building_number}
                                    onChange={(value) => this.props.getAddressInputChanged("building_number", value)}
                                    onPressEnterKey={() => null}
                                    displayError={JsonError1.doTargetKeysHaveInnerErrors(["building_number"], this.props.getAddressForm.validationInnerErrors)}
                                />
                            }
                            thatInputSize="70%"
                            thatInput={
                                <Input
                                    placeholder="House"
                                    value={this.props.getAddressForm.fields.building_name}
                                    onChange={(value) => this.props.getAddressInputChanged("building_name", value)}
                                    onPressEnterKey={() => null}
                                    displayError={JsonError1.doTargetKeysHaveInnerErrors(["building_name"], this.props.getAddressForm.validationInnerErrors)}
                                />
                            }
                        />
                    </LabelWrapForInputs>
                </FormRow>
                {/* POST CODE */}
                <FormRow>
                    <LabelWrapForInputs
                        required={true}
                        label="Postcode"
                    >
                        <Input
                            placeholder="CF5 6JD"
                            value={this.props.getAddressForm.fields.postcode}
                            onChange={(value) => this.props.getAddressInputChanged("postcode", value)}
                            onPressEnterKey={() => null}
                            displayError={JsonError1.doTargetKeysHaveInnerErrors(["postcode"], this.props.getAddressForm.validationInnerErrors)}
                        />
                    </LabelWrapForInputs>
                </FormRow>
                {/* BUTTON: AUTOCOMPLETE FROM BUILDING & POSTCODE BUTTON */}
                <FormRow>
                    <FormButton
                        status={this.props.getAddressForm.status}
                        label="Autocomplete from building & postcode"
                        type="frog"
                        fullWidth={true}
                        onClick={this.props.submitGetAddressLookup}
                    />
                </FormRow>
                {/* SUB NUMBER OR SUB BUILDING NAME */}
                <FormRow>
                    <LabelWrapForInputs
                        required={false}
                        label="Sub number/Sub building name"
                    >
                        <ThisOrThatInput
                            thisInputSize="30%"
                            thisInput={
                                <Input
                                    placeholder="Sub number"
                                    value={this.props.propertyForm.fields.sub_building_number}
                                    onChange={(value) => this.props.propertyInputChanged("sub_building_number", value)}
                                    onPressEnterKey={() => null}
                                />
                            }
                            thatInputSize="70%"
                            thatInput={
                                <Input
                                    placeholder="Sub building name"
                                    value={this.props.propertyForm.fields.sub_building_name}
                                    onChange={(value) => this.props.propertyInputChanged("sub_building_name", value)}
                                    onPressEnterKey={() => null}
                                />
                            }
                        />
                    </LabelWrapForInputs>
                </FormRow>
                {/* STREET NAME */}
                <FormRow>
                    <LabelWrapForInputs
                        required={true}
                        label="Street name"
                    >
                        <Input
                            placeholder=""
                            value={this.props.propertyForm.fields.street_name}
                            onChange={(value) => this.props.propertyInputChanged("street_name", value)}
                            onPressEnterKey={() => null}
                            displayError={this.hasValidationError("street_name")}
                        />
                    </LabelWrapForInputs>
                </FormRow>
                {/* CITY / TOWN */}
                <FormRow>
                    <LabelWrapForInputs
                        required={true}
                        label="City / Town"
                    >
                        <Input
                            placeholder=""
                            value={this.props.propertyForm.fields.city_town}
                            onChange={(value) => this.props.propertyInputChanged("city_town", value)}
                            onPressEnterKey={() => null}
                            displayError={this.hasValidationError("city_town")}
                        />
                    </LabelWrapForInputs>
                </FormRow>
                {/* LOCALITY */}
                <FormRow>
                    <LabelWrapForInputs label="Locality">
                        <Input
                            placeholder=""
                            value={this.props.propertyForm.fields.locality}
                            onChange={(value) => this.props.propertyInputChanged("locality", value)}
                            onPressEnterKey={() => null}
                        />
                    </LabelWrapForInputs>
                </FormRow>
                {/* DISTRICT */}
                <FormRow>
                    <LabelWrapForInputs label="District">
                        <Input
                            placeholder=""
                            value={this.props.propertyForm.fields.district}
                            onChange={(value) => this.props.propertyInputChanged("district", value)}
                            onPressEnterKey={() => null}
                        />
                    </LabelWrapForInputs>
                </FormRow>
                {/* COUNTY */}
                <FormRow>
                    <LabelWrapForInputs
                        required={true}
                        label="County"
                    >
                        <Input
                            placeholder=""
                            value={this.props.propertyForm.fields.county}
                            onChange={(value) => this.props.propertyInputChanged("county", value)}
                            onPressEnterKey={() => null}
                            displayError={this.hasValidationError("county")}
                        />
                    </LabelWrapForInputs>
                </FormRow>
                {/* COUNTRY */}
                <FormRow>
                    <LabelWrapForInputs
                        required={false}
                        label="Country"
                    >
                        <Dropdown
                            value={this.props.propertyForm.fields.country}
                            options={enumToIDropdownOptions(
                                Country2.values,
                                Country2.fromValueToDisplayName
                            )}
                            onChange={(value) => this.props.propertyInputChanged("country", value)}
                        />
                    </LabelWrapForInputs>
                </FormRow>
            </div>
        );
    }
}
