import React from "react";
import { WeightBold } from "../WeightBold/WeightBold";
import { WeightMedium } from "../WeightMedium/WeightMedium";
import { CRMIcon, TIcon } from "../CRMIcon/CRMIcon";
import { CRMColors } from "../../models/CRMColors";
import { CRMSpacingRow } from "../CRMSpacingRow/CRMSpacingRow";
import { TextColour } from "../TextColour/TextColour";
import { THexColor } from "../../models/StringLiterals";

type TCRMIconLabelAndMonoText = {
    label: string;
    displayText: string;
    iconName?: TIcon;
    iconAlignment?: "start" | "end";
    colour?: THexColor;
    iconOnClick?: () => void;
    iconTooltipText?: string;
    displayTextOnClick?: () => void;
};

export class CRMIconLabelAndMonoText extends React.Component<React.PropsWithChildren<TCRMIconLabelAndMonoText>> {
    public render (): JSX.Element {
        return (
            <CRMSpacingRow>
                {/* IF LEFT ICON: ICON */}
                {this.props.iconName && this.props.iconAlignment === "start" &&
                    <this.renderIcon />
                }
                {/* LABEL */}
                <div className="crm-icon-label-and-mono-text__label">
                    <WeightMedium>
                        <TextColour colour={this.props.colour || CRMColors.NEUTRAL_INK}>
                            {this.props.label}
                        </TextColour>
                    </WeightMedium>
                </div>
                {/* MONO TEXT */}
                <div
                    className={`
                        crm-icon-label-and-mono-text__display-text
                        crm-icon-label-and-mono-text__display-text--${typeof this.props.displayTextOnClick !== "undefined" ? "clickable" : "not-clickable"}
                    `}
                    onClick={this.props.displayTextOnClick}
                >
                    <WeightBold>
                        <TextColour colour={this.props.colour || CRMColors.NEUTRAL_INK}>
                            {this.props.displayText}
                        </TextColour>
                    </WeightBold>
                </div>
                {/* IF RIGHT ICON: ICON */}
                {this.props.iconName && this.props.iconAlignment === "end" &&
                    <this.renderIcon />
                }
            </CRMSpacingRow>
        );
    }

    private renderIcon = (): JSX.Element => (
        <div
            className={`
                crm-icon-label-and-mono-text__icon-wrapper
                crm-icon-label-and-mono-text__icon-wrapper--${this.props.iconAlignment}
                crm-icon-label-and-mono-text__icon-wrapper--${typeof this.props.iconOnClick !== "undefined" ? "clickable" : "not-clickable"}
            `}
            onClick={this.props.iconOnClick}
            title={this.props.iconTooltipText}
            // Accesibility things
            role={typeof this.props.iconOnClick !== "undefined" ? "button" : undefined}
            tabIndex={typeof this.props.iconOnClick !== "undefined" ? 0 : undefined}
            onKeyDown={typeof this.props.iconOnClick === "undefined" ? undefined :
                (e) => {
                    if (e.keyCode === 13 || e.keyCode === 32) {
                        (this.props.iconOnClick as () => void)();
                        e.preventDefault();
                    }
                }
            }
        >
            <CRMIcon
                size="tiny"
                iconName={this.props.iconName as TIcon}
                colour="hex"
                hexColour={this.props.colour || CRMColors.NEUTRAL_INK}
            />
        </div>
    );
}
