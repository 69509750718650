import React from "react";
import { isAPlainFunction } from "../../../../functions/functions";
import { CRMColors } from "../../../../models/CRMColors";
import { CRMSpacing } from "../../../../models/CRMSpacing";
import { THexColor, TPercentageSize, TPixelSize } from "../../../../models/StringLiterals";
import { Background } from "../../../BuildingBlocks/Background";
import { SpacingRow } from "../../../BuildingBlocks/SpacingRow";
import { TIcon } from "../../../CRMIcon/CRMIcon";
import { CRMEntityBoxIcon } from "../CRMEntityBoxIcon/CRMEntityBoxIcon";
import { SpacingColumn } from "../../../BuildingBlocks/SpacingColumn";

type TBubbleCardProps = {
    icon: TIcon;
    FaceElement: JSX.Element;
    LowerElement?: JSX.Element;
    onClick?: () => void;

    width?: TPercentageSize | TPixelSize;
    backgroundColor?: THexColor;
    borderColor?: THexColor;
    iconCircleColor?: THexColor;
    iconColor?: THexColor;
};

export const CRMEntityBoxBase = (props: React.PropsWithChildren<TBubbleCardProps>): JSX.Element => {
    
    const Cursor = isAPlainFunction(props.onClick) ? "pointer" : "auto";
    const BackgroundColor = props.backgroundColor || CRMColors.PRIMARY_16;
    const BorderColor = props.borderColor || CRMColors.PRIMARY_10;
    const IconCircleColor = props.iconCircleColor || CRMColors.PRIMARY_12;
    const IconColor = props.iconColor || CRMColors.NEUTRAL_INK;
    
    return (
        <Background
            display="inline-flex"
            borderRadius="10px"
            borderDefinition={`1px solid ${BorderColor}`}
            color={BackgroundColor}
            width={props.width}
        >
            <SpacingColumn>

            
                <SpacingRow alignItems="flex-start">
                    {/* FACE */}
                    <Background 
                        padding={`${CRMSpacing.MEDIUM} ${CRMSpacing.SMALL} ${CRMSpacing.SMALL} ${CRMSpacing.MEDIUM}`}
                        cursor={Cursor}
                        onClick={props.onClick}
                    >
                        <CRMEntityBoxIcon
                            icon={props.icon} 
                            circleColor={IconCircleColor}
                            iconColor={IconColor}
                        />
                    </Background>

                    <div>
                        {props.FaceElement}
                    </div>
                </SpacingRow>


                {!!props.LowerElement && 
                    props.LowerElement
                }
            </SpacingColumn>
        </Background>
    );
};
