import React from "react";
import { TIntroducerForm, TIntroducersForm } from "../../../../../domain/codecs/form/IntroducerForm";
import { useResolutionStatus } from "../../../hooks/useResolutionStatus";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { CRMColors } from "../../../models/CRMColors";
import { AnimationPopout } from "../../BuildingBlocks/AnimationPopout";
import { Padding } from "../../BuildingBlocks/Padding";
import { Relative } from "../../BuildingBlocks/Relative";
import { SpacingColumn } from "../../BuildingBlocks/SpacingColumn";
import { SpacingRow } from "../../BuildingBlocks/SpacingRow";
import { CRMEmptyPlaceholder } from "../../Simple/CRMEmptyPlaceholder/CRMEmptyPlaceholder";
import { CRMTableContentPlaceholder } from "../../Simple/CRMTableContentPlaceholder/CRMTableContentPlaceholder";
import { CRMThinColoredButton } from "../../Simple/CRMThinColoredButton/CRMThinColoredButton";
import { WeightSemiBold } from "../../WeightSemiBold/WeightSemiBold";
import { CRMConfirmationCountdown } from "../CRMConfirmationCountdown/CRMConfirmationCountdown";
import { CRMIntroducerRow } from "../CRMIntroducerRow/CRMIntroducerRow";
import { CRMBlockPopover } from "../../CRM/CRMBlock/CRMBlockPopover/CRMBlockPopover";

type TAction = (emailForm: TIntroducerForm, index: number) => void;

type TIntroducerTableProps = {
    form: TIntroducersForm;
    onOpen: TAction;
    onEdit: TAction;
    onArchive:  (emailForm: TIntroducerForm) => void;
    onLoadMore: () => void;
};

type TResolutionStatus = 
    "none"
    | "archiving-introducer"
;

export const CRMIntroducerTable = (props: React.PropsWithChildren<TIntroducerTableProps>): JSX.Element => {

    const {
        resolutionStatus,
        resolutionData,
        setResolution,
        resetResolution
    } = useResolutionStatus<TResolutionStatus, TIntroducerForm>({
        defaultStatus: "none"
    })

    const isShowingLoadMore = () => (
        props.form.children.counts.available_count > props.form.children.introducers.length
        && !isLoading()
    );
    
    const isLoading = () => (
        props.form.status === "loading"
        || props.form.status === "submitting"
    );

    const isEmpty = () => props.form.children.introducers.length === 0;

    // ACTIONS
    const onBeginArchive = (introducer: TIntroducerForm) => 
        () => setResolution("archiving-introducer")(introducer)
    ;

    const onCompleteArchive = () => {
        props.onArchive(resolutionData as TIntroducerForm);
        resetResolution();
    }

    return (
        <Relative>
            <SpacingColumn spacing={CRMSpacing.LARGE}>
                <SpacingColumn>
                    {/* ROWS */}
                    {(!isLoading() && !isEmpty()) && 
                        props.form.children.introducers.map((introducer, index) => (
                            <CRMIntroducerRow
                                key={index}
                                introducer={introducer}
                                onClick={() => props.onOpen(introducer, index)}
                                onEdit={() => props.onEdit(introducer, index)}
                                onDelete={onBeginArchive(introducer)}
                            />       
                        ))
                    }

                    {/* LOADING BAR */}
                    {(isLoading() && !isEmpty()) &&
                        <CRMTableContentPlaceholder
                            rowCount={props.form.children.introducers.length * 2}
                        />
                    }

                    {/* EMPTY */}
                    {(!isLoading() && isEmpty()) &&
                        <Padding spacing={CRMSpacing.LARGE}>
                            <CRMEmptyPlaceholder
                                iconSize="70px"
                                textGap={CRMSpacing.TINY}
                            >
                                No introducers found.
                            </CRMEmptyPlaceholder>
                        </Padding>
                    }
                </SpacingColumn>

                {/* LOAD MORE */}
                {isShowingLoadMore() &&
                    <AnimationPopout>
                        <Padding type="bottom" spacing={CRMSpacing.LARGE}>
                            <SpacingRow justifyContent="center">
                                <CRMThinColoredButton
                                    backgroundColor={CRMColors.NEUTRAL_2}
                                    fontColor={CRMColors.NEUTRAL_12}
                                    label={<WeightSemiBold>See more</WeightSemiBold>}
                                    onClick={props.onLoadMore}
                                    icon="down-arrow"
                                />
                            </SpacingRow>
                        </Padding>
                    </AnimationPopout>
                }
            </SpacingColumn>
            
            {/* CONFIRMATION POPCARD - SINGLE ARCHIVE */}
            {resolutionStatus === "archiving-introducer" &&
                <CRMBlockPopover>
                    <CRMConfirmationCountdown
                        title={`Archiving introducer!`}
                        iconName="archive"
                        primaryButtonMode="done"
                        duration={5000}
                        onUndo={resetResolution}
                        onComplete={onCompleteArchive}
                    /> 
                </CRMBlockPopover>
            }
        </Relative>
    );
};
