import React from "react";
import { TListingFull1Editable } from "../../../../domain/codecs/formEditable/ListingEditable";
import { ListingPropertySpecificsRequired, ListingPropertySpecificsOptional1, ListingPropertySpecificsOptional2 } from "../../../../domain/codecs/Listing";
import { CRMAutosaveIndicatorWrap } from "../CRM/CRMAutosaveIndicatorWrap/CRMAutosaveIndicatorWrap";
import { CRMCodecEditForm } from "../CRMCodecEditForm/CRMCodecEditForm";
import { CRMDropdownComponent } from "../CRMDropdownComponent/CRMDropdownComponent";
import { CRMSpacingColumn } from "../CRMSpacingColumn/CRMSpacingColumn";
import { CRMTitleSection } from "../CRMTitleSection/CRMTitleSection";
import { array } from "fp-ts/lib";
import { pipe } from "fp-ts/function";
import { TListingDataFormIO } from "../../../../domain/codecs/formIO/ListingDataFormIO";
import CRMInputLabelAndErrorWrapComponent from "../CRMInputLabelAndErrorWrapComponent/CRMInputLabelAndErrorWrapComponent";
import { CRMLink } from "../CRMLink/CRMLink";
import { WeightBold } from "../WeightBold/WeightBold";
import { CRMTitleSubSection } from "../CRMTitleSubSection/CRMTitleSubSection";

type TCRMListingSpecificsFormProps = {
    listingPageData: TListingDataFormIO;
    onChange: (listingForm: TListingFull1Editable) => void;
};

export const CRMListingSpecificsForm = (props: TCRMListingSpecificsFormProps): JSX.Element => {

    return (
        <CRMAutosaveIndicatorWrap status={props.listingPageData.output.form.status}>
            <CRMSpacingColumn spacing="large">
                <CRMTitleSection>
                    Specifics
                </CRMTitleSection>
                <CRMTitleSubSection>
                    Required
                </CRMTitleSubSection>
                <CRMCodecEditForm
                    codec={ListingPropertySpecificsRequired}
                    model={props.listingPageData.output.form.edited}
                    validationErrors={props.listingPageData.output.form.validationErrors}
                    onChange={(edited) => props.onChange({
                        ...props.listingPageData.output.form,
                        edited: {
                            ...props.listingPageData.output.form.edited,
                            ...edited,
                        }
                    })}
                />
                <CRMTitleSubSection>
                    Additional
                </CRMTitleSubSection>
                <CRMSpacingColumn spacing="medium">
                    <CRMCodecEditForm
                        codec={ListingPropertySpecificsOptional1}
                        model={props.listingPageData.output.form.edited}
                        validationErrors={props.listingPageData.output.form.validationErrors}
                        onChange={(edited) => props.onChange({
                            ...props.listingPageData.output.form,
                            edited: {
                                ...props.listingPageData.output.form.edited,
                                ...edited,
                            }
                        })}
                    />

                    <CRMCodecEditForm
                        codec={ListingPropertySpecificsOptional2}
                        model={props.listingPageData.output.form.edited}
                        validationErrors={props.listingPageData.output.form.validationErrors}
                        onChange={(edited) => props.onChange({
                            ...props.listingPageData.output.form,
                            edited: {
                                ...props.listingPageData.output.form.edited,
                                ...edited,
                            }
                        })}
                    />
                </CRMSpacingColumn>
                
                {/* SELECT OCCUPIER FROM SELLER PARTY USERS */}
                {props.listingPageData.output.form.edited.property_occupied &&
                    <CRMSpacingColumn spacing="large">
                        <CRMLink
                            href={`/crm/listing/${props.listingPageData.input.listing_id}?visible_tab=seller_details&visible_sections=`}
                            target="_blank"
                            linkStyle="normal"
                        >
                            <WeightBold>Add seller party member</WeightBold>
                        </CRMLink>
                        <CRMInputLabelAndErrorWrapComponent label="Occupier">
                            <CRMDropdownComponent
                                options={pipe(
                                    props.listingPageData.output.read_only.all_seller_party_users_dropdown,
                                    array.map((user) => ({
                                        value: user.id,
                                        label: `${user.first_name} ${user.last_name}${user.is_legal_seller ? " (Legal seller)" : ""}`
                                    })),
                                    (userOptions) => [{value: "unknown", label: "Unknown"}, ...userOptions]
                                )}
                                value={props.listingPageData.output.form.edited.occupier_user_id ?? ""}
                                displayError={false}
                                onChange={(userId) => props.onChange({
                                    ...props.listingPageData.output.form,
                                    edited: {
                                        ...props.listingPageData.output.form.edited,
                                        occupier_user_id: userId === "unknown" ? null : userId
                                    }
                                })}
                            />
                        </CRMInputLabelAndErrorWrapComponent>
                    </CRMSpacingColumn>
                }
            </CRMSpacingColumn>
        </CRMAutosaveIndicatorWrap>
    );
};
