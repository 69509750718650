import React from "react";
import { array as extArray } from "../../../../shared/src/utilByDomainGroupExport";
import { TUser8Form, TUserPhoneNumber5AndC4FormList, TParty2FormList, TEnquiryC2ReadOnlyFormList } from "../../models/TFormModels";
import * as User8 from "../../../../domain/models/User8";
import * as UserPhoneNumber4 from "../../../../domain/models/UserPhoneNumber4";
import * as User4 from "../../../../domain/models/User4";
import { CRMCardOutsidePopupBasic } from "../CRMCardOutsidePopupBasic/CRMCardOutsidePopupBasic";
import * as TFormStatus from "../../models/TFormStatus";
import { CRMParagraph } from "../Simple/CRMParagraph/CRMParagraph";
import { CRMSpacer } from "../CRMSpacer/CRMSpacer";
import { CRMCardInsideUserPartySync } from "../CRMInsideCardUserPartySync/CRMCardInsideUserPartySync";
import { array } from "fp-ts";
import { TInputNames, CRMPartyMemberForm_OLDE } from "../CRMPartyMemberForm_OLDE/CRMPartyMemberForm_OLDE";
import { CRMButtonQuaternary } from "../CRMButtonQuaternary/CRMButtonQuaternary";
import { CRMSpacingRow } from "../CRMSpacingRow/CRMSpacingRow";
import { pipe } from "fp-ts/lib/function";

type TCRMHomeEnquiryPartyMemberForm = {
    personForm: TUser8Form;
    phoneNumbers: TUserPhoneNumber5AndC4FormList["forms"];
    existingEnquiries: TEnquiryC2ReadOnlyFormList["forms"];
    createPhoneNumberStatus: TFormStatus.T;
    matchingParties: TParty2FormList["forms"];
    onChange: (value: User8.T[keyof User8.T], key: keyof User8.T) => void;
    onOpenEnquiryInNewTab: () => void;
    onCloseMatchingEnquryPopup: () => void;
    onDisassociate: () => void;
    onAddNewPhoneNumber: () => void;
    onChangePhoneNumber: (value: UserPhoneNumber4.T[keyof UserPhoneNumber4.T], key: keyof UserPhoneNumber4.T, resourceId: string) => void;
    onRemovePhoneNumber: (id: string) => void;
    onUseParty: (id: string) => void;
    onAddThisUser: (id: string) => void;
};

type TAutoCompletePosition = "hidden" | TInputNames;

type TLocalState = {
    isDisassociatePopupOpen: boolean;
    autoCompletePosition: TAutoCompletePosition;
};

export class CRMHomeEnquiryPartyMemberForm extends React.Component<React.PropsWithChildren<TCRMHomeEnquiryPartyMemberForm>, TLocalState> {

    constructor(props: TCRMHomeEnquiryPartyMemberForm) {
        super(props);
        this.state = {
            isDisassociatePopupOpen: false,
            autoCompletePosition: "email",
        };
    }

    openDisassociatePopupOpen(): void {
        this.setState({
            isDisassociatePopupOpen: true,
        });
    }

    closeDisassociatePopupOpen(): void {
        this.setState({
            isDisassociatePopupOpen: false,
        });
    }

    onDisassociate(): void {
        this.closeDisassociatePopupOpen();
        this.props.onDisassociate();
    }

    onFocusAutoCompleteInput(position: TAutoCompletePosition): void {
        this.setState({
            autoCompletePosition: position,
        });
    }

    onBlurAutoCompleteInput(): void {
        // We dont want the blur logic to execute
        // if we are just switching focus. Because that
        // messes with our animations and as we all know beauty is important ;3
        const cachedCurrentPosition = this.state.autoCompletePosition;
        setTimeout(() => {
            if (cachedCurrentPosition === this.state.autoCompletePosition) {
                this.setState({
                    autoCompletePosition: "hidden",
                });
            }
        }
        , 300);
    }

    getUserName = (user: User8.T): string => `${user.first_name} ${user.last_name}`;

    getMatchingPartyPersonId = (party: TParty2FormList["forms"][number]): string =>
         pipe(
            extArray.highestValueMatch<User8.T>(["first_name", "last_name", "email"], this.props.personForm.edit, party.edit.users),
            (person) => (person as User4.T).id,
        );

    getMatchingPartyPersonName = (party: TParty2FormList["forms"][number]): string =>
         pipe(
            extArray.highestValueMatch<User8.T>(["first_name", "last_name", "email"], this.props.personForm.edit, party.edit.users),
            this.getUserName,
        );

    getMatchingPartyPersonEmail = (party: TParty2FormList["forms"][number]): string =>
         pipe(
            extArray.highestValueMatch<User8.T>(["first_name", "last_name", "email"], this.props.personForm.edit, party.edit.users),
            (user) => user.email || "",
        );

    getMatchingPartyMemberNames = (party: TParty2FormList["forms"][number]): Array<string> =>
         pipe(
            extArray.allButhighestValueMatch<User8.T>(["first_name", "last_name", "email"], this.props.personForm.edit, party.edit.users),
            array.map(this.getUserName),
        );

    getMatchingPartyPhoneNumbers = (party: TParty2FormList["forms"][number]): Array<string> =>
         pipe(
            extArray.allButhighestValueMatch<User8.T>(["first_name", "last_name", "email"], this.props.personForm.edit, party.edit.users),
            array.map((user) => (user as User4.T).phone_numbers),
            array.flatten,
            array.map((number) => number.phone_number),
            (numbers) => numbers.filter((number) => number !== null) as Array<string>
        );

    public render (): JSX.Element {
        return (
            <div className="crm-home-enquiry-party-member-form">
                {/* POPUP - "THIS PARTY ALREADY HAS AN ENQUIRY AGAINST LISTING" PROMPT */}
                <>
                    <CRMCardOutsidePopupBasic
                        isOpen={this.props.existingEnquiries.length > 0}
                        context="important"
                        title={`Enquiry Exists`}
                        ctaText="Open"
                        onCTA={() => this.props.onOpenEnquiryInNewTab()}
                        onClose={() => this.props.onCloseMatchingEnquryPopup()}
                    >
                        <CRMParagraph>
                            This party already has a enquiry open against this listing. Would you like to open it in another tab?
                        </CRMParagraph>
                    </CRMCardOutsidePopupBasic>
                </>
                {/* POPUP - DISASSOCIATE PERSON PROMPT */}
                <>
                    <CRMCardOutsidePopupBasic
                        isOpen={this.state.isDisassociatePopupOpen}
                        context="warning"
                        title={`Disassociate person?`}
                        ctaText="Disassociate"
                        onCTA={() => this.onDisassociate()}
                        onClose={() => this.closeDisassociatePopupOpen()}
                    >
                        <CRMParagraph>
                            You are about to disassociate a person from a party.
                        </CRMParagraph>
                        <CRMSpacer size="tiny"/>
                        <CRMParagraph>
                            This will remove them from the party's people list but not delete them. This person can always be associated with another party in the future.
                        </CRMParagraph>
                    </CRMCardOutsidePopupBasic>
                </>
                {/* AUTO COMPLETE CARD - MATCHING PARTY */}
                {this.props.matchingParties.length > 0 &&
                    <div
                        className={`
                            crm-home-enquiry-party-member-form__autocomplete-suggestion
                            crm-home-enquiry-party-member-form__autocomplete-suggestion--${this.state.autoCompletePosition}
                        `}
                    >
                        {this.props.matchingParties.map((party, index) => (
                            <div key={index}>
                                <CRMCardInsideUserPartySync
                                    userName={this.getMatchingPartyPersonName(party)}
                                    partyMembers={this.getMatchingPartyMemberNames(party)}
                                    phoneNumbers={this.getMatchingPartyPhoneNumbers(party)}
                                    email={this.getMatchingPartyPersonEmail(party)}
                                    onUseParty={() => this.props.onUseParty(party.edit.id)}
                                    onMakeNew={() => this.props.onAddThisUser(this.getMatchingPartyPersonId(party))}
                                />
                                <CRMSpacer size="tiny" />
                            </div>
                        ))}
                    </div>
                }
                {/* PARTY MEMBER FORM */}
                <CRMPartyMemberForm_OLDE
                    personForm={this.props.personForm}
                    showLegalBuyerField={true}
                    onChange={this.props.onChange}
                    onFocus={(inputName) => this.onFocusAutoCompleteInput(inputName)}
                    onBlur={() => this.onBlurAutoCompleteInput()}
                    phoneNumbers={this.props.phoneNumbers}
                    createPhoneNumberStatus={this.props.createPhoneNumberStatus}
                    onAddNewPhoneNumber={this.props.onAddNewPhoneNumber}
                    onRemovePhoneNumber={this.props.onRemovePhoneNumber}
                    onChangePhoneNumber={this.props.onChangePhoneNumber}
                />
                <CRMSpacer size="large" />
                {/* DISSASOCIATE BUTTON */}
                <CRMSpacingRow justifyContent="end">
                    <CRMButtonQuaternary
                        icon="person-off"
                        label="Disassociate"
                        onClick={() => this.openDisassociatePopupOpen()}
                    />
                </CRMSpacingRow>
            </div>
        );
    }
}
