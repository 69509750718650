import React from "react";
import CRMInputLabelAndErrorWrapComponent from "../CRMInputLabelAndErrorWrapComponent/CRMInputLabelAndErrorWrapComponent";
import { CRMRadioListComponent } from "../CRMInputs/CRMRadioListComponent/CRMRadioListComponent";
import { TCRMFormInputWithoutCodec } from "./CRMFormInputTypes";

export const CRMYesNoBooleanRadioFormInput: TCRMFormInputWithoutCodec<boolean> = (props) =>
    <CRMInputLabelAndErrorWrapComponent label={props.label}>
        <CRMRadioListComponent<boolean, boolean>
            value={props.model}
            direction="row"
            options={[
                {
                    value: true,
                    text: "Yes",
                },
                {
                    value: false,
                    text: "No",
                },
            ]}
            onChange={(val) => props.onChange(val)}
            disabled={props.disabled}
            displayError={props.displayError}
        />
    </CRMInputLabelAndErrorWrapComponent>;