import { array, option } from "fp-ts";
import { pipe } from "fp-ts/lib/function";
import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import { TCaseQuoteSentToClientForm, TCreateCaseQuoteUnfilledForm } from "../../../../../domain/codecs/form/CaseForm";
import { CaseQuote, TCaseQuote } from "../../../../../domain/codecs/Quote";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { MinHeight } from "../../BuildingBlocks/MinHeight";
import { Padding } from "../../BuildingBlocks/Padding";
import { Relative } from "../../BuildingBlocks/Relative";
import { SpacingRow } from "../../BuildingBlocks/SpacingRow";
import { CRMBottomControlBar } from "../../CRMBottomControlBar/CRMBottomControlBar";
import { CRMButtonIcon } from "../../CRMButtonIcon/CRMButtonIcon";
import { CRMButtonQuaternary } from "../../CRMButtonQuaternary/CRMButtonQuaternary";
import { CRMBlockDropdown } from "../../Simple/CRMBlockDropdown/CRMBlockDropdown";
import { CRMQuoteCreatePopout } from "../CRMQuoteCreatePopout/CRMQuoteCreatePopout";
import { CRMQuoteInfo } from "../CRMQuoteInfo/CRMQuoteInfo";
import { CRMQuoteSentToClientPopout } from "../CRMQuoteSentToClientPopout/CRMQuoteSentToClientPopout";
import { CRMQuoteSingleView } from "../CRMQuoteSingleView/CRMQuoteSingleView";
import { CRMEmptyPlaceholder } from "../../Simple/CRMEmptyPlaceholder/CRMEmptyPlaceholder";
import { Background } from "../../BuildingBlocks/Background";

type TQuoteBlockViewStatus =
    "quotes"
    | "quote-info"
    | "create"
    | "quote-sent"
;

export const CRMQuoteBlock = (props: React.PropsWithChildren<{
    quoteForms: Array<TCaseQuote>,
    createQuoteForm: TCreateCaseQuoteUnfilledForm,
    caseQuoteSentToClientForm: TCaseQuoteSentToClientForm,
    onChangeQuoteSentForm: (form: TCaseQuoteSentToClientForm) => void
    onSubmitQuoteSentForm: (form: TCaseQuoteSentToClientForm) => void,
    onChangeCreateQuote: (form: TCreateCaseQuoteUnfilledForm) => void,
    onSubmitCreateQuote: (form: TCreateCaseQuoteUnfilledForm) => void,
}>): JSX.Element => {
    const [viewStatus, setViewStatus] = useState<TQuoteBlockViewStatus>("quotes");

    const [viewingQuoteId, setViewingQuoteId] = useState<string | null>(null);

    const hasQuotes = props.quoteForms.length > 0;

    const caseQuoteSentToClient = props.caseQuoteSentToClientForm.edited.quote_sent_to_client === null ? false : true;

    const onViewCreateQuote = () => setViewStatus("create");

    const onCloseCreateQuote = () => setViewStatus("quotes");

    const onViewQuoteInfo = () => setViewStatus("quote-info");

    const onCloseQuoteInfo = () => setViewStatus("quotes");
    
    const onViewQuoteSentForm = () => setViewStatus("quote-sent");

    const onCloseQuoteSentForm = () => setViewStatus("quotes");

    const onSubmitQuoteSentForm = (form: TCaseQuoteSentToClientForm) => {
        props.onSubmitQuoteSentForm(form);
        onCloseQuoteSentForm();
    };

    const onSubmitCreateQuote = (form: TCreateCaseQuoteUnfilledForm) => {
        props.onSubmitCreateQuote(form);
        onCloseCreateQuote();
    };

    const getViewingQuote = (): TCaseQuote =>
        pipe(
            props.quoteForms,
            array.findFirst(({id}) => id === viewingQuoteId),
            option.getOrElse(CaseQuote.newDefault),
        );

    const getLabelForQuoteId = (quoteId: string): string =>
        pipe(
            props.quoteForms,
            array.findIndex(({id}) => id === quoteId),
            option.fold(
                () => "",
                (i) =>
                    i === 0
                        ? "Current Quote"
                        : `Quote #${props.quoteForms.length - i} (${DateTime.fromISO(props.quoteForms[i].created_at).toFormat("d MMM y")})`
            )
        );

    useEffect(() => {
        setViewingQuoteId(
            props.quoteForms.length === 0
                ? null
                : props.quoteForms[0].id,
        )
    }, [props.quoteForms]);

    const onDownload = () =>
        window.open(getViewingQuote().download_url, "_blank");

    const quoteBlockSections = pipe(
        props.quoteForms,
        array.map((quote) => ({
            value: quote.id,
            label: getLabelForQuoteId(quote.id),
            badgeCount: 0,
        })),
    );

    return (
        <Relative>
            {/* VIEW - QUOTES */}
            {viewStatus === "quotes" &&
                <div>
                    <MinHeight height="702px">
                        {!hasQuotes &&
                            <Background padding={`${CRMSpacing.X_LARGE} ${CRMSpacing.LARGE}`}>
                                <CRMEmptyPlaceholder
                                    iconSize="70px"
                                    textGap={CRMSpacing.TINY}
                                >
                                    No quote has been generated.
                                </CRMEmptyPlaceholder>
                            </Background>
                        }

                        {/* SINGLE VIEW */}
                        {hasQuotes &&
                            <CRMQuoteSingleView
                                quoteForm={getViewingQuote()}
                            />
                        }
                    </MinHeight>

                    <CRMBottomControlBar>
                        <SpacingRow
                            spacing={CRMSpacing.MEDIUM}
                            justifyContent="space-between"
                        >
                            {/* QUOTE SELECTOR */}
                            <div>
                                {hasQuotes &&
                                    <CRMBlockDropdown
                                        sections={quoteBlockSections}
                                        value={getViewingQuote().id}
                                        label={getLabelForQuoteId(getViewingQuote().id)}
                                        onValueChange={setViewingQuoteId}
                                    />
                                }
                            </div>

                            {/* CONTROLS */}
                            <Padding
                                type="custom"
                                width="100%" 
                                spacing={`${CRMSpacing.TINY} ${CRMSpacing.MEDIUM}`}
                            >
                                <SpacingRow
                                    justifyContent="start"
                                    spacing={CRMSpacing.TINY}
                                    childSize="auto auto auto auto"
                                >
                                    {hasQuotes &&
                                        <>
                                            {/* DOWNLOAD */}
                                            <CRMButtonIcon
                                                variant="quaternary"
                                                icon="can-download"
                                                title="Download PDF"
                                                onClick={onDownload}
                                            />

                                            {/* INFO */}
                                            <CRMButtonIcon
                                                variant="quaternary"
                                                icon="tasks"
                                                title="View the info given when the quote was created"
                                                onClick={onViewQuoteInfo}
                                            />

                                            {/* NEW QUOTE */}
                                            <CRMButtonQuaternary
                                                icon="add"
                                                label="New Quote"
                                                title="Create a new quote"
                                                onClick={onViewCreateQuote}
                                            />

                                            {/* QUOTE SENT TO CLIENT FORM*/}
                                            <CRMButtonIcon
                                                variant={caseQuoteSentToClient ? "quaternary" : "quaternary-active"}
                                                icon={caseQuoteSentToClient ? "email-ticked" : "email-question"}
                                                title="Confirm a quote has been sent to client"
                                                onClick={onViewQuoteSentForm}
                                            />
                                        </>
                                    }
                                    {!hasQuotes &&
                                        <>
                                            {/* NEW QUOTE */}
                                            <CRMButtonQuaternary
                                                icon="add"
                                                label="New Quote"
                                                title="Create a new quote"
                                                onClick={onViewCreateQuote}
                                            />
                                        </>
                                    }
                                </SpacingRow>
                            </Padding>

                        </SpacingRow>
                    </CRMBottomControlBar>
                </div>
            }

            {/* VIEW - QUOTE INFO */}
            {viewStatus === "quote-info" &&
                <CRMQuoteInfo
                    form={getViewingQuote()}
                    onClose={onCloseQuoteInfo}
                />
            }

            {/* VIEW - CREATE QUOTE */}
            {viewStatus === "create" &&
                <CRMQuoteCreatePopout
                    form={props.createQuoteForm}
                    onClose={onCloseCreateQuote}
                    onChange={props.onChangeCreateQuote}
                    onSave={onSubmitCreateQuote}
                />
            }

            {/* VIEW - QUOTE FORM */}
            {viewStatus === "quote-sent" &&
                <CRMQuoteSentToClientPopout
                    form={props.caseQuoteSentToClientForm}
                    onClose={onCloseQuoteSentForm}
                    onChange={props.onChangeQuoteSentForm}
                    onSave={onSubmitQuoteSentForm}
                />
            }
        </Relative>
    );
};
