import { pipe } from "fp-ts/lib/function";
import { array } from "fp-ts";
import { reactChildrenToArray } from "../../../shared/src/utilsByDomain/react/reactChildrenToArray";
import { isReactComponent } from "../../../shared/src/utilsByDomain/react/isReactComponent";
import { join as arrayJoin } from "../../../shared/src/utilsByDomain/array";
import { usePreviousRef } from "./usePreviousRef";

export const useHasNodeChanged = <T extends React.ReactNode>(originalNode: T) => {
    
    const currentNodeRef = usePreviousRef(originalNode);

    const isNodeDifferent = (node: React.ReactNode) => {
        const newNameID = nodeToNameID(node);
        const currentNameID = !!currentNodeRef ? nodeToNameID(currentNodeRef) : "";
        return newNameID !== currentNameID;
    }

    const nodeToNameID = (node: React.ReactNode): string => pipe(
        reactChildrenToArray(node),
        array.filter(isReactComponent),
        arrayJoin(",")
    );

    return {
        isNodeDifferent
    };
};