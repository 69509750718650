import React, { useState } from "react";
import { CRMCardOutsidePopupBlank } from "../CRMCardOutsidePopupBlank/CRMCardOutsidePopupBlank";
import { CRMPadding } from "../Simple/CRMPadding/CRMPadding";
import { CursorPointer } from "../CursorPointer/CursorPointer";

export const CRMCaseMemberIdChecksImageComponent = (props: React.PropsWithChildren<{imageUrl: string}>): JSX.Element => {
    const [popupIsOpen, setPopupIsOpen] = useState<boolean>(false);

    return (
        <div>
            <div onClick={() => setPopupIsOpen(true)}>
                <CursorPointer>
                    <img
                        src={props.imageUrl}
                        style={{
                            width: "50px",
                        }}
                    />
                </CursorPointer>
            </div>
            <CRMCardOutsidePopupBlank
                isOpen={popupIsOpen}
                onClose={() => setPopupIsOpen(false)}
                context="none"
                size="x-large"
            >
                <CRMPadding size="large">
                    <div style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}>
                        <img
                            src={props.imageUrl}
                            style={{
                                maxWidth: "100%",
                            }}
                        />
                    </div>
                </CRMPadding>
            </CRMCardOutsidePopupBlank>
        </div>
    );
};
