import React, { useState } from "react";
import * as CaseEnquiry5 from "../../../../../../domain/models/CaseEnquiry5";
import * as InternalScope from "../../../../../../domain/models/InternalScope";
import * as CaseEnquiryStatusType from "../../../../../../domain/models/CaseEnquiryStatusType";
import { CRMCardLabel } from "../../../CRMCardLabel/CRMCardLabel";
import { CRMPadding } from "../../../Simple/CRMPadding/CRMPadding";
import { CRMParagraph } from "../../../Simple/CRMParagraph/CRMParagraph";
import { WordBreak } from "../../../BuildingBlocks/WordBreak";
import { CRMIcon, TIcon } from "../../../CRMIcon/CRMIcon";
import { CRMSpacingRow } from "../../../CRMSpacingRow/CRMSpacingRow";
import { CRMSpacer } from "../../../CRMSpacer/CRMSpacer";
import { WeightBold } from "../../../WeightBold/WeightBold";
import { WeightMedium } from "../../../WeightMedium/WeightMedium";
import { CRMButtonTertiary } from "../../../CRMButtonTertiary/CRMButtonTertiary";
import { CRMButtonQuaternary } from "../../../CRMButtonQuaternary/CRMButtonQuaternary";
import { requireExhaustive } from "../../../../../../shared/src/util";
import { CRMEnquiryDocumentAndSectionLink } from "../../../CRMEnquiryDocumentAndSectionLink/CRMEnquiryDocumentAndSectionLink";
import { CRMCaseEnquiryAttachmentNote } from "../CRMCaseEnquiryAttachmentNote/CRMCaseEnquiryAttachmentNote";
import { CRMColors } from "../../../../models/CRMColors";
import { TCRMMainColours } from "../../../../models/TCRMMainColours";
import { CRMSpacing } from "../../../../models/CRMSpacing";
import { TCaseEnquiryAttachmentFilterForm, TCaseEnquiryAttachmentFormList, TCaseEnquiryNote1FormList, TCaseEnquiryTimeline1ReadOnlyFormList } from "../../../../models/TFormModels";
import { CRMCaseEnquiryAttachmentPopup, TEmailDirection } from "../CRMCaseEnquiryAttachmentPopup/CRMCaseEnquiryAttachmentPopup";
import * as User10 from "../../../../../../domain/models/User10";
import { TLegalEmailDecrypted1, TLegalEmailDecryptedWithBody } from "../../../../../../domain/codecs/LegalEmail";
import * as CaseDocument7 from "../../../../../../domain/models/CaseDocument7";
import { pipe } from "fp-ts/lib/function";
import { CRMCaseEnquiryAttachmentDocument } from "../CRMCaseEnquiryAttachmentDocument/CRMCaseEnquiryAttachmentDocument";
import { CRMCaseEnquiryAttachmentEmail } from "../CRMCaseEnquiryAttachmentEmail/CRMCaseEnquiryAttachmentEmail";
import CRMInputLabelAndErrorWrapComponent from "../../../CRMInputLabelAndErrorWrapComponent/CRMInputLabelAndErrorWrapComponent";
import CRMTextAreaComponent from "../../../CRMTextAreaComponent/CRMTextAreaComponent";
import * as JsonError1 from "../../../../../../domain/models/JsonError1";
import { CRMCardOutsidePopupFormSubmit } from "../../../CRMCardOutsidePopupFormSubmit/CRMCardOutsidePopupFormSubmit";
import * as TCaseEnquiryTimeline1ReadOnlyForm from "../../../../models/TCaseEnquiryTimeline1ReadOnlyForm";
import * as Case2 from "../../../../../../domain/models/Case2";
import * as util from "../../../../../../shared/src/util";
import { SpacingRow } from "../../../BuildingBlocks/SpacingRow";
import { TextHightlight } from "../../../BuildingBlocks/TextHightlight";
import { getFormattedDateStringFromString } from "../../../../../../domain/models/ISODateTime";

type TOutsideCardEnquiryProps = {
    enquiry: CaseEnquiry5.T;
    userRole: InternalScope.T;
    case: Case2.T;
    enquiryAttachmentForm: TCaseEnquiryAttachmentFormList["forms"][number];
    enquiryNewNoteForm: TCaseEnquiryNote1FormList["forms"][number];
    enquiryAttachmentFilterForm: TCaseEnquiryAttachmentFilterForm;
    enquiryAttachmentRecipientsList: Array<User10.T>;
    enquiryAttachmentFoundEmails: Array<TLegalEmailDecryptedWithBody>;
    enquiryAttachmentFoundDocuments: Array<CaseDocument7.T>;
    enquiryTimelines: TCaseEnquiryTimeline1ReadOnlyFormList["forms"];
    onReOpen: () => void;
    onEdit: () => void;
    onDelete: () => void;
    onApprove: () => void;
    onMarkAsSent: () => void;
    onAttach: () => void;
    onResolve: () => void;
    onMarkAsNotRelevant: () => void;
    onTimelineDelete: (timelineForm: TCaseEnquiryTimeline1ReadOnlyForm.T) => void;
    onTimelineFormChange: (timelineForm: TCaseEnquiryTimeline1ReadOnlyForm.T) => void;
    onAttachmentFormChange: (value: TCaseEnquiryAttachmentFormList["forms"][number]) => void;
    onAttachmentSelectEmailDirection: (direction: TEmailDirection) => void;
    onAttachmentSelectRecipient: (recipients: Array<string>) => void;
    onAttachmentViewEmail: (email: TLegalEmailDecrypted1) => void;
    onAttachmentModalClose: () => void;
    onAttachmentModalSubmit: () => void;
    onNewNoteModalChange: (value: TCaseEnquiryNote1FormList["forms"][number]) => void;
    onNewNoteModalSubmit: () => void;
};

export const CRMOutsideCardEnquiry = (props: TOutsideCardEnquiryProps): JSX.Element => {

    const [areMoreAttachemntsShowing, setShowMoreAttachements] = useState(false);

    const getNumberOfAttachmentsToShow = (): number => areMoreAttachemntsShowing ? props.enquiryTimelines.length : 2;

    const isAuthorizer = (): boolean => props.userRole === "sail_legal_authoriser_staff";

    const getStatusIcon = (status: CaseEnquiryStatusType.T): TIcon => {
        switch (status) {
            case "unsubmitted":
                return "denied";
            case "pending_approval":
                return "sign";
            case "approved_unsent":
                return "email-notification";
            case "approved_sent":
                return "email-outbound";
            case "resolved":
                return "done-all";
            case "not_relevant":
                return "close";
            default:
                return requireExhaustive(status);
        }
    };

    const getLabelColor = (status: CaseEnquiryStatusType.T): TCRMMainColours => {
        switch (status) {
            case "unsubmitted":
            case "pending_approval":
                return "highlights-instructional-brick-6";
            case "approved_unsent":
            case "approved_sent":
                return "highlights-pertinent-yellow-4";
            case "resolved":
                return "highlights-positive-green-6";
            case "not_relevant":
                return "neutral-4";
            default:
                return requireExhaustive(status);
        }
    };

    const enquiryCanBeChanged = (): boolean =>
        props.case.status === "quote" || props.case.status === "instructed" || props.case.status === "property_report" ? true
        : props.case.status === "ready_for_exchange" || props.case.status === "exchanged" || props.case.status === "completed" || props.case.status === "closed_unsuccessfully" || props.case.status === "closed_successfully" || props.case.status === "closed_created_in_error" || props.case.status === "closed_awaiting_opening_new_case" ? false
        : util.requireExhaustive(props.case.status);

    const EnquiryControlButtons = (): JSX.Element => {
        switch (props.enquiry.status) {
            case "unsubmitted":
                return (
                    enquiryCanBeChanged()
                        ? <CRMSpacingRow spacing="medium">
                            <CRMButtonTertiary
                                icon="pencil"
                                label="Edit"
                                onClick={props.onEdit}
                            />
                            <CRMButtonQuaternary
                                icon="delete"
                                label="Delete"
                                onClick={props.onDelete}
                            />
                        </CRMSpacingRow>
                        : <div></div>
                );
            case "pending_approval":
                return (
                    enquiryCanBeChanged() ?
                        <>
                            <CRMButtonTertiary
                                icon="pencil"
                                label="Edit"
                                onClick={props.onEdit}
                            />
                            {isAuthorizer() && <>
                                <CRMSpacer size="medium" />
                                <CRMSpacingRow spacing="medium">
                                    <CRMButtonTertiary
                                        icon="done"
                                        label="Approve"
                                        onClick={props.onApprove}
                                    />
                                    <CRMButtonQuaternary
                                        icon="close"
                                        label="Not Relevant"
                                        onClick={props.onMarkAsNotRelevant}
                                    />
                                </CRMSpacingRow>
                            </>}
                        </>
                        : <div></div>
                );
            case "approved_unsent":
                return (
                    <CRMSpacingRow spacing="medium">
                        <CRMButtonTertiary
                            icon="email-notification"
                            label="Mark as Sent"
                            onClick={props.onMarkAsSent}
                        />
                    </CRMSpacingRow>
                );
            case "approved_sent":
                return (
                    <CRMSpacingRow spacing="medium">
                        {enquiryCanBeChanged() && <CRMButtonTertiary
                            icon="done-all"
                            label="Resolve"
                            onClick={props.onResolve}
                        />}
                        <CRMButtonTertiary
                            icon="attach"
                            label="Attach"
                            onClick={props.onAttach}
                        />
                    </CRMSpacingRow>
                );
            case "not_relevant":
                return (
                    enquiryCanBeChanged() ?
                        <CRMSpacingRow spacing="medium">
                            <CRMButtonTertiary
                                icon="pencil"
                                label="Edit"
                                onClick={props.onEdit}
                            />
                            <CRMButtonQuaternary
                                icon="repeat"
                                label="Reopen"
                                onClick={props.onReOpen}
                            />
                        </CRMSpacingRow>
                        : <div></div>
                );
            case "resolved":
                return (
                    enquiryCanBeChanged()
                        ? <CRMSpacingRow spacing="medium">
                            <CRMButtonQuaternary
                                icon="repeat"
                                label="Reopen"
                                onClick={props.onReOpen}
                            />
                        </CRMSpacingRow>
                        : <div></div>
                );
            default:
                return requireExhaustive(props.enquiry.status);
        }
    };

    return (
        <>
            {/* Enquiry card */}
            <div className={`crm-outside-card-enquiry`}>
                {/* DETAILS */}
                <div className="crm-outside-card-enquiry__left-half">
                    <CRMPadding size="large">
                        {/* TITLE & STATUS */}
                        <SpacingRow spacing={CRMSpacing.TINY}>
                            {!!props.enquiry.reference_id &&
                                <TextHightlight color={CRMColors.HIGHLIGHTS_CALMING_PINK_8}>
                                    <CRMParagraph>
                                        <WeightBold>
                                            {props.enquiry.reference_id}
                                        </WeightBold>
                                    </CRMParagraph>
                                </TextHightlight>
                            }

                            <CRMIcon
                                iconName={getStatusIcon(props.enquiry.status)}
                                colour="neutral-ink"
                                size="small"
                            />
                            <div className="crm-outside-card-enquiry__title">
                                {CaseEnquiryStatusType.toDisplayString(props.enquiry.status)}
                            </div>
                        </SpacingRow>

                        <CRMSpacer size="tiny" />
                        {/* RAISED BY */}
                        <CRMParagraph>
                            by:&nbsp;
                            <WeightBold>{props.enquiry.created_by_user.first_name} {props.enquiry.created_by_user.last_name}</WeightBold>
                        </CRMParagraph>
                        {/* CREATED AT */}
                        <CRMParagraph>
                            created at:&nbsp;
                            {getFormattedDateStringFromString(props.enquiry.created_at, "dd/MM/yyyy h:mma")}
                        </CRMParagraph>
                        <CRMSpacer size="medium" />
                        {/* RAISED FOR */}
                        {props.enquiry.documents.length > 0 && <CRMParagraph>
                            <WeightMedium>
                                Raised for:
                            </WeightMedium>
                        </CRMParagraph>}
                        {props.enquiry.documents.map((document, index) => (
                            <div key={index} className="crm-outside-card-enquiry__document">
                                <div className="crm-outside-card-enquiry__document-dot">
                                    <CRMIcon
                                        iconName="dot"
                                        colour="neutral-ink"
                                        size="small"
                                    />
                                </div>
                                <CRMEnquiryDocumentAndSectionLink
                                    document={document}
                                />
                            </div>
                        ))}
                        <CRMSpacer size="x-large" />
                        {/* CONTROLS */}
                        <EnquiryControlButtons />
                    </CRMPadding>
                </div>
                {/* ENQUIRY TEXT & ATTACHMENTS */}
                <div className="crm-outside-card-enquiry__right-half">
                    {/* CARD LABEL */}
                    <div className="crm-outside-card-enquiry__card-label">
                        <CRMCardLabel color={getLabelColor(props.enquiry.status)} />
                    </div>
                    {/* BODY */}
                    <CRMPadding size="large">
                        {/* ENQUIRY TEXT */}
                        <WordBreak>
                            <CRMParagraph>
                                {props.enquiry.message}
                            </CRMParagraph>
                        </WordBreak>

                        {/* ATTACHMENTS */}
                        {props.enquiryTimelines.slice(0, getNumberOfAttachmentsToShow()).map((timelineForm, index) => (
                            <div key={index}>
                                <CRMSpacer size="large" />
                                { pipe(
                                    timelineForm,
                                    TCaseEnquiryTimeline1ReadOnlyForm.fold({
                                        onCaseEnquiryNote5: (timelineNoteForm) =>
                                            <CRMCaseEnquiryAttachmentNote
                                                timelineFormNoteVariant={timelineNoteForm}
                                                onFormChange={props.onTimelineFormChange}
                                                onDelete={() => props.onTimelineDelete(timelineNoteForm)}
                                            />,
                                        onCaseEnquiryDocument3: (timelineDocumentForm) =>
                                            <CRMCaseEnquiryAttachmentDocument
                                                timelineFormDocumentVariant={timelineDocumentForm}
                                                onFormChange={props.onTimelineFormChange}
                                                onDelete={() => props.onTimelineDelete(timelineDocumentForm)}
                                            />
                                        ,
                                        onLegalEmail4: (timelineEmailForm) =>
                                            <CRMCaseEnquiryAttachmentEmail
                                                timelineFormEmailVariant={timelineEmailForm}
                                                onFormChange={props.onTimelineFormChange}
                                                onDelete={() => props.onTimelineDelete(timelineEmailForm)}
                                            />,
                                    }),
                                )}
                            </div>
                        ))}

                        {props.enquiryTimelines.length > 2 &&
                            <div
                                className="crm-outside-card-enquiry__see-more"
                                onClick={() => setShowMoreAttachements(!areMoreAttachemntsShowing)}
                            >
                                <CRMSpacer size="large" />
                                <CRMSpacingRow>
                                    <CRMParagraph>
                                        <WeightBold>
                                            {areMoreAttachemntsShowing
                                                ? "Collapse"
                                                : `See more (${props.enquiryTimelines.length - 2})`}
                                        </WeightBold>
                                    </CRMParagraph>
                                    <CRMIcon
                                        iconName={areMoreAttachemntsShowing ? "arrow-up" : "down-arrow"}
                                        colour="neutral-ink"
                                        size="tiny"
                                    />
                                </CRMSpacingRow>
                            </div>
                        }

                        <CRMSpacer size="large" />
                        <CRMButtonQuaternary
                            icon="chat-bubble"
                            label="Add note"
                            onClick={() => props.onNewNoteModalChange({
                                ...props.enquiryNewNoteForm,
                                ui: {
                                    ...props.enquiryNewNoteForm.ui,
                                    isModalOpen: true,
                                },
                            })}
                        />
                    </CRMPadding>
                </div>
            </div>

            {/* ATTACHMENT MODAL */}
            {props.enquiryAttachmentForm.ui.isModalOpen && 
                <CRMCaseEnquiryAttachmentPopup
                    filterForm={props.enquiryAttachmentFilterForm}
                    submitForm={props.enquiryAttachmentForm}
                    recipientsList={props.enquiryAttachmentRecipientsList}
                    foundEmails={props.enquiryAttachmentFoundEmails}
                    foundDocuments={props.enquiryAttachmentFoundDocuments}
                    onSubmitFormChange={props.onAttachmentFormChange}
                    onSelectEmailDirection={props.onAttachmentSelectEmailDirection}
                    onSelectRecipient={props.onAttachmentSelectRecipient}
                    onViewEmail={props.onAttachmentViewEmail}
                    onClose={props.onAttachmentModalClose}
                    onSubmit={props.onAttachmentModalSubmit}
                />
            }

            {/* Add note modal */}
            <CRMCardOutsidePopupFormSubmit
                formStatus={props.enquiryNewNoteForm.status}
                validationErrors={props.enquiryNewNoteForm.validationErrors}
                isOpen={props.enquiryNewNoteForm.ui.isModalOpen}
                title="Add note"
                context="neutral"
                closeText="Cancel"
                ctaText="Save note"
                onClose={() => props.onNewNoteModalChange({
                    ...props.enquiryNewNoteForm,
                    ui: {
                        ...props.enquiryNewNoteForm.ui,
                        isModalOpen: false,
                    },
                })}
                onCTA={props.onNewNoteModalSubmit}
            >
                <CRMInputLabelAndErrorWrapComponent
                    label="Enter a new note for this enquiry"
                    displayError={JsonError1.doTargetKeysHaveInnerErrors(["message"], props.enquiryNewNoteForm.validationErrors)}
                    errorMessage="You must enter a note before saving"
                >
                    <CRMTextAreaComponent
                        displayError={JsonError1.doTargetKeysHaveInnerErrors(["message"], props.enquiryNewNoteForm.validationErrors)}
                        value={props.enquiryNewNoteForm.edit.message}
                        onChange={(message) => props.onNewNoteModalChange({
                            ...props.enquiryNewNoteForm,
                            status: "requiresSubmission",
                            edit: {
                                ...props.enquiryNewNoteForm.edit,
                                message,
                            },
                        })}
                    />
                </CRMInputLabelAndErrorWrapComponent>
            </CRMCardOutsidePopupFormSubmit>
        </>
    );
};
