import React from "react";
import { SpacingRow } from "../../BuildingBlocks/SpacingRow";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { ShineShape } from "../../BuildingBlocks/ShineShape";
import { CRMColors } from "../../../models/CRMColors";
import { SpacingColumn } from "../../BuildingBlocks/SpacingColumn";
import { CRMTextContentPlaceholder } from "../../Simple/CRMTextContentPlaceholder/CRMTextContentPlaceholder";

export const CRMRoadblockMessagePlaceholder = () => {
    return (
        <SpacingRow
            alignItems="flex-start"
            spacing={CRMSpacing.MEDIUM}
            childSize="36px 1fr"
        >
            <ShineShape
                backgroundColor={CRMColors.NEUTRAL_10} 
                shineColor={CRMColors.NEUTRAL_12}
                duration={2000}
                height={"36px"}
                width={"36px"}
                borderRadius="8px"
            />

            <SpacingColumn spacing={CRMSpacing.LARGE}>
                <ShineShape
                    backgroundColor={CRMColors.NEUTRAL_10} 
                    shineColor={CRMColors.NEUTRAL_12}
                    duration={2000}
                    height={"14px"}
                    width={"80px"}
                />

                <SpacingColumn>
                    <CRMTextContentPlaceholder
                        rowCount={4}
                        height="14px"

                        backgroundColor={CRMColors.NEUTRAL_10} 
                        shineColor={CRMColors.NEUTRAL_12}
                    />
                </SpacingColumn>
            </SpacingColumn>
        </SpacingRow>
    )
}
