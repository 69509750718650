import React, {  } from "react";
import { TQuoteRateFeeTransferValue } from "../../../../../domain/codecs/QuoteRateFee";
import { CRMInputCurrency } from "../../CRMInputCurrency/CRMInputCurrency";
import CRMInputLabelAndErrorWrapComponent from "../../CRMInputLabelAndErrorWrapComponent/CRMInputLabelAndErrorWrapComponent";

export const CRMQuoteRatesSingleViewEditFeesFixed = (props: React.PropsWithChildren<{
    fee: TQuoteRateFeeTransferValue,
    displayError: boolean,
    onChange: (fee: TQuoteRateFeeTransferValue) => void,
}>): JSX.Element =>
    <CRMInputLabelAndErrorWrapComponent label="How much?">
        <CRMInputCurrency
            mode="pence"
            value={props.fee.fee_pence}
            placeholder="0.00"
            onChange={(fee_pence) => props.onChange({
                ...props.fee,
                fee_pence: fee_pence || 0,
            })}
            displayError={props.displayError && props.fee.fee_pence === 0}
        />
    </CRMInputLabelAndErrorWrapComponent>;
