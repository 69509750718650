import React, { useState } from "react";

type TCopyState = "untouched" | "success" | "failure";

export const useCopyToClipboard = () => {

    const [copyState, setCopyState] = useState<TCopyState>("untouched");

    const setCopyStateThenRevert = (state: TCopyState) => {
        setCopyState(state);
        setTimeout(() => setCopyState("untouched"), 1000)
    }

    const copyValueToClipboard = (value: string) =>
        navigator.clipboard
            .writeText(value)
            .then(
                () => setCopyStateThenRevert("success"),
                () => setCopyStateThenRevert("failure")
            );

    return {
        copyState,
        copyValueToClipboard
    };
}
