import React from "react";
import { CRMSpacing } from "../../../../models/CRMSpacing";
import { SpacingRow } from "../../../BuildingBlocks/SpacingRow";
import { CRMParagraph } from "../../../Simple/CRMParagraph/CRMParagraph";
import { WeightBold } from "../../../WeightBold/WeightBold";
import { CRMDropdownColourComponent } from "../../../CRMDropdownColourComponent/CRMDropdownColourComponent";
import { TCasesPageView } from "../../../../../../domain/codecs/form/CasesForm";

export const CRMCaseViewFilter = (props: {
    view: TCasesPageView;
    onChangeView: (view: TCasesPageView) => void;
}): JSX.Element =>
    <SpacingRow spacing={CRMSpacing.TINY}>

        <CRMParagraph>
            <WeightBold>Viewing tasks by:</WeightBold>
        </CRMParagraph>
        
        <CRMDropdownColourComponent
            value={props.view}
            options={[
                {
                    value: "case",
                    label: "Case",
                    highlightColour: "primary-13",
                },
                {
                    value: "day",
                    label: "Day",
                    highlightColour: "primary-13",
                },
            ]}
            onChange={props.onChangeView}  
        />
    </SpacingRow>;
