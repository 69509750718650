import React from "react";
import { useEffect } from "react";
import { TCaseSearchResult } from "../../../../../domain/codecs/CaseSearchResult";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { SpacingColumn } from "../../BuildingBlocks/SpacingColumn";
import UnstyledInputGeneralComponent from "../../UnstyledInputGeneralComponent/UnstyledInputGeneralComponent";
import { CRMSearchCaseCard } from "../CRMSearchCaseCard/CRMSearchCaseCard";

type TCaseSearchOverlayProps = {
    isOpen: boolean;
    searchValue: string;
    cases: Array<TCaseSearchResult>;
    onChange: (searchValue: string) => void;
    onClose: () => void; 
};

export const CRMCaseSearchOverlay = (props: React.PropsWithChildren<TCaseSearchOverlayProps>): JSX.Element => {

    const rootRef = React.createRef<HTMLDivElement>();
    const inputRef = React.createRef<HTMLInputElement>();

    const closeIfShadowboxWasClicked = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (rootRef.current?.isSameNode(event.target as Node)) {
            props.onClose();
        }
    }

    useEffect(
        () => {
            if (props.isOpen) {
                inputRef.current?.focus();
            }
        },
        [props.isOpen]
    )

    return (
        <div 
            ref={rootRef}
            className={`
                crm-case-search-overlay
                crm-case-search-overlay--${props.isOpen ? "open" : "closed"}
            `}
            onClick={closeIfShadowboxWasClicked}
        >
            {props.isOpen &&
                <div className="crm-case-search-overlay__body">

                    <div className="crm-case-search-overlay__big-input-wrap">
                        <UnstyledInputGeneralComponent
                            inputRef={inputRef}
                            className="crm-case-search-overlay__big-input"
                            placeholder="Search for cases by address or client name"
                            inputType="text"
                            value={props.searchValue}
                            onChange={props.onChange}
                            onPressEscape={props.onClose}
                            displayError={false}
                        />
                    </div>

                    <div className="crm-case-search-overlay__cards">
                        <SpacingColumn spacing={CRMSpacing.MEDIUM}>
                            {props.cases.map((legalCase, key) => (
                                <CRMSearchCaseCard
                                    key={key}
                                    case={legalCase}
                                    buttons={[
                                        {
                                            label: "Details",
                                            link: `/crm/legal/cases/${legalCase.id}?visible_tab=details`
                                        },
                                        {
                                            label: "Documents",
                                            link: `/crm/legal/cases/${legalCase.id}?visible_tab=document_management`
                                        },
                                        {
                                            label: "Enquiries",
                                            link: `/crm/legal/cases/${legalCase.id}?visible_tab=enquiries`
                                        },
                                        {
                                            label: "Emails",
                                            link: `/crm/legal/cases/${legalCase.id}?visible_tab=emails`
                                        },
                                    ]}
                                />
                            ))}
                        </SpacingColumn>
                    </div>
                </div>
            }
        </div>
    );
};
