import React from "react";
interface ICRMTextAreaComponent {
    displayError?: boolean;
    value?: string;
    placeholder?: string;
    disabled?: boolean;
    pattern?: string;
    autoFocus?: boolean;
    onChange: (value: string) => void;
    onEnter?: () => void;
}

class CRMTextAreaComponent extends React.Component<React.PropsWithChildren<ICRMTextAreaComponent>> {
    public render (): JSX.Element {
        return (
            <textarea
                className={
                    "crm-text-area-component" +
                    (this.props.disabled
                        ? " crm-text-area-component--disabled"
                        : this.props.displayError
                            ? " crm-text-area-component--error "
                            : ""
                    )}
                value={this.props.value}
                placeholder={this.props.placeholder}
                disabled={this.props.disabled}
                autoFocus={this.props.autoFocus}
                onChange={(e) => this.props.onChange(e.currentTarget.value)}
                onKeyDown={(e) => {
                    if (
                        e.key === "Enter"
                        && this.props.onEnter
                        && typeof this.props.value !== "undefined"
                    ) {
                        e.preventDefault();
                        e.stopPropagation();
                        this.props.onEnter();
                    }
                }}
                onFocus={(event) => {
                    // This forces the cursor to the end of the input on autofocus
                    if (this.props.autoFocus) {
                        const originalValue = event.target.value;
                        event.target.value = "";
                        event.target.value = originalValue;
                    }
                }}
            />
        );
    }
}

export default CRMTextAreaComponent;
