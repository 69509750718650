import { array, option } from "fp-ts";
import { pipe } from "fp-ts/lib/function";
import React, { useEffect, useState } from "react";
import { TClientCompanyPartnershipUserType } from "../../../../../domain/codecs/ClientCompanyPartnership";
import { ClientCompanyPartnershipUserForm, TClientCompanyPartnershipForm, TClientCompanyPartnershipUserForm, TClientCompanyPartnershipUserPhoneNumberCreateForm, TClientCompanyPartnershipUserPhoneNumberDeleteForm, TClientCompanyPartnershipUserPhoneNumberForm, TClientCompanyPartnershipUserPhoneNumberMakePrimaryForm } from "../../../../../domain/codecs/form/ClientCompanyPartnershipForm";
import { CRMColors } from "../../../models/CRMColors";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { BorderBetween } from "../../BuildingBlocks/BorderBetween";
import { Flex } from "../../BuildingBlocks/Flex";
import { Padding } from "../../BuildingBlocks/Padding";
import { SpacingColumn } from "../../BuildingBlocks/SpacingColumn";
import { SpacingRow } from "../../BuildingBlocks/SpacingRow";
import { CRMEditAddressForm } from "../../CRMEditAddressForm/CRMEditAddressForm";
import { CRMIcon } from "../../CRMIcon/CRMIcon";
import { CRMParagraph } from "../../Simple/CRMParagraph/CRMParagraph";
import { CRMPopoutHeader } from "../../Simple/CRMPopoutHeader/CRMPopoutHeader";
import { CRMBlockPopover } from "../../CRM/CRMBlock/CRMBlockPopover/CRMBlockPopover";
import { TextColour } from "../../TextColour/TextColour";
import { WeightMedium } from "../../WeightMedium/WeightMedium";
import { CRMCompanyPartnerPeopleForm } from "../CRMCompanyPartnerPeopleForm/CRMCompanyPartnerPeopleForm";
import { CRMCompanyPartnerPersonCard } from "../CRMCompanyPartnerPersonCard/CRMCompanyPartnerPersonCard";

export const CRMCompanyPartnerPeople = (props: React.PropsWithChildren<{
    form: TClientCompanyPartnershipForm,
    onChange: (form: TClientCompanyPartnershipUserForm) => void,
    onSave: (form: TClientCompanyPartnershipUserForm) => void,
    onDelete: (form: TClientCompanyPartnershipUserForm) => void,
    onPhoneNumberChange: (form: TClientCompanyPartnershipUserPhoneNumberForm) => void,
    onNewPhoneNumber: (form: TClientCompanyPartnershipUserPhoneNumberCreateForm) => void,
    onPhoneNumberMakePrimary: (form: TClientCompanyPartnershipUserPhoneNumberMakePrimaryForm) => void,
    onPhoneNumberDelete: (form: TClientCompanyPartnershipUserPhoneNumberDeleteForm) => void,
}>): JSX.Element => {
    const [editingClientCompanyUserId, setEditingClientCompanyUserId] = useState<string | null>(null);

    const editingUserForm =
        pipe(
            props.form.children.user_forms,
            array.findFirst((form) => form.edited.id === editingClientCompanyUserId),
            option.getOrElse(ClientCompanyPartnershipUserForm.newDefault),
        );

    const hasPeople =
        props.form.children.user_forms.length > 0;

    const justUnknown: Array<TClientCompanyPartnershipUserForm> =
        pipe(
            props.form.children.user_forms,
            array.filter((form) => form.original.type === "unknown"),
        );

    const justDirectors: Array<TClientCompanyPartnershipUserForm> =
        pipe(
            props.form.children.user_forms,
            array.filter((form) => form.original.type === "director"),
        );

    const justSignatories: Array<TClientCompanyPartnershipUserForm> =
        pipe(
            props.form.children.user_forms,
            array.filter((form) => form.original.type === "signatory"),
        );

    const justEmployees: Array<TClientCompanyPartnershipUserForm> =
        pipe(
            props.form.children.user_forms,
            array.filter((form) => form.original.type === "employee"),
        );

    const allPeople: Array<{
        type: TClientCompanyPartnershipUserType,
        users: Array<TClientCompanyPartnershipUserForm>
    }> = pipe(
        [
            {
                type: "director" as "director",
                users: justDirectors,
            },
            {
                type: "signatory" as "signatory",
                users: justSignatories,
            },
            {
                type: "employee" as "employee",
                users: justEmployees,
            },
            {
                type: "unknown" as "unknown",
                users: justUnknown,
            },
        ],
        // Remove any groups without any users in them, we don't want to display these
        array.filter(({users}) => users.length > 0),
    );

    // When a user change/delete is submitted to the endpoint and the response returned is a success then we will close the user popup
    useEffect(() =>
        pipe(
            props.form.children.user_forms,
            array.findFirst((form) =>
                form.status === "success"
                && form.edited.id === editingClientCompanyUserId
            ),
            option.fold(
                () => undefined,
                () => setEditingClientCompanyUserId(null)
            )
        )
    , [props.form.children.user_forms]);

    return <>
        {/* NO PEOPLE YET PLACEHOLDER */}
        {!hasPeople &&
            <SpacingColumn
                spacing={CRMSpacing.MEDIUM}
                justifyContent="center"
            >
                {/* ICON */}
                <Flex justifyContent="center">
                    <CRMIcon
                        iconName="air"
                        size="extra-large"
                        colour="neutral-8"
                    />
                </Flex>

                {/* TEXT */}
                <CRMParagraph justifyContent="center">
                    <WeightMedium>
                        <TextColour colour={CRMColors.NEUTRAL_4}>
                            No directors, signatories or employees added yet.
                        </TextColour>
                    </WeightMedium>
                </CRMParagraph>
            </SpacingColumn>
        }

        {/* DISPLAY PEOPLE */}
        {hasPeople &&
            <div>
                {allPeople.map(({users}, i) =>
                    <BorderBetween
                        key={i}
                        borderColour={CRMColors.NEUTRAL_10}
                        spacing={CRMSpacing.MEDIUM}
                    >
                        <SpacingColumn spacing={CRMSpacing.MEDIUM}>
                            {pipe(
                                users,
                                array.chunksOf(2),
                                array.mapWithIndex((uci, usersChunk) =>
                                    <SpacingRow
                                        key={uci}
                                        spacing={CRMSpacing.MEDIUM}
                                        childSize="1fr 1fr"
                                    >
                                        {usersChunk.map((user, ui) =>
                                            <CRMCompanyPartnerPersonCard
                                                key={ui}
                                                form={user}
                                                onEdit={(f) => setEditingClientCompanyUserId(f.edited.id)}
                                            />
                                        )}
                                    </SpacingRow>
                                )
                            )}
                        </SpacingColumn>
                    </BorderBetween>
                )}
            </div>
        }

        {/* EDIT USER POPUP */}
        {editingClientCompanyUserId &&
            <CRMCompanyPartnerPeopleForm
                form={editingUserForm}
                onChange={props.onChange}
                onSave={props.onSave}
                onDelete={props.onDelete}
                onPhoneNumberChange={props.onPhoneNumberChange}
                onNewPhoneNumber={props.onNewPhoneNumber}
                onPhoneNumberMakePrimary={props.onPhoneNumberMakePrimary}
                onPhoneNumberDelete={props.onPhoneNumberDelete}
                onClose={() => setEditingClientCompanyUserId(null)}
            />
        }
    </>;
};
