import React from "react";
import { CRMButtonIcon } from "../CRMButtonIcon/CRMButtonIcon";
import { ICRMInputButtonIconWrapButton } from "../CRMInputButtonIconWrap/CRMInputButtonIconWrap";
import { CRMFormButtonWithParagraph } from "../CRMFormButtonWithParagraph/CRMFormButtonWithParagraph";
import * as TFormStatus from "../../models/TFormStatus";
import CRMInputButtonIconWrap from "../CRMInputButtonIconWrap/CRMInputButtonIconWrap";
import CRMInputGeneralComponent from "../CRMInputs/CRMInputGeneralComponent/CRMInputGeneralComponent";
import { TUserPhoneNumber5AndC4FormList } from "../../models/TFormModels";
import * as JsonError1 from "../../../../domain/models/JsonError1";
import * as UserPhoneNumber4 from "../../../../domain/models/UserPhoneNumber4";

type TCRMInputPhoneNumberComponent = {
    phoneNumbers: TUserPhoneNumber5AndC4FormList["forms"];
    placeholder: string;
    createPhoneNumberStatus: TFormStatus.T;
    onAddNew: () => void;
    onChange: (value: UserPhoneNumber4.T[keyof UserPhoneNumber4.T], key: keyof UserPhoneNumber4.T, resourceId: string) => void;
    onRemove: (resourceId: string) => void;
};

export class CRMInputPhoneNumbersComponent_OLDE extends React.Component<React.PropsWithChildren<TCRMInputPhoneNumberComponent>> {

    constructor(props: TCRMInputPhoneNumberComponent) {
        super(props);
    }

    isFirstPhoneNumberFilled (): boolean {
        return !!(
            this.props.phoneNumbers.length > 0 &&
            this.props.phoneNumbers[0].edit.phone_number &&
            this.props.phoneNumbers[0].edit.phone_number.length > 0
        );
    }

    getButtonIcons(index: number): Array<ICRMInputButtonIconWrapButton> {
        const addButton: ICRMInputButtonIconWrapButton = {
            formStatus: this.props.createPhoneNumberStatus,
            title: "Add phone number",
            variant: "primary",
            icon: "add-phone",
            onClick: () => this.props.onAddNew(),
        };

        const deleteButton: ICRMInputButtonIconWrapButton = {
            variant: "tertiary",
            title: "Delete phone number",
            icon: "delete",
            onClick: () => this.props.onRemove(this.props.phoneNumbers[index].view.id),
        };

        if (this.props.phoneNumbers.length === 1) {
            return [deleteButton, addButton];
        }

        if (index < this.props.phoneNumbers.length-1 ) {
            return [deleteButton];
        }

        if (this.props.phoneNumbers.length-1 === index && index !== 0) {
            return [deleteButton, addButton];
        }

        return [];
    }

    public render (): JSX.Element {
        return (
            <div className="crm-input-phone-number-OLDE">
                {this.props.phoneNumbers.length === 0 &&
                    <CRMFormButtonWithParagraph
                        icon="add-phone"
                        formStatus={this.props.createPhoneNumberStatus}
                        paragraph="Add phone number"
                        onClick={() => this.props.onAddNew()}
                    />
                }
                {/* PHONE NUMBER INPUTS */}
                {this.props.phoneNumbers.length > 0 &&
                    this.props.phoneNumbers.map((model, index) => (
                        <div key={index} className="crm-input-phone-number-OLDE__row">
                            <CRMInputButtonIconWrap buttonIcons={this.getButtonIcons(index)}>
                                <div className="crm-input-phone-number-OLDE__input">
                                    <CRMInputGeneralComponent
                                        inputType="text"
                                        placeholder={this.props.placeholder}
                                        value={model.edit.phone_number || ""}
                                        onChange={(value) => this.props.onChange(value, "phone_number", model.view.id)}
                                        displayError={JsonError1.doTargetKeysHaveInnerErrors(["phone_number"], model.validationErrors)}
                                        onPressEnterKey={() => null}
                                    />
                                     <div
                                        title="Set as primary number"
                                        className="crm-input-phone-number-OLDE__inner-icon"
                                    >
                                        <CRMButtonIcon
                                            variant="tertiary"
                                            icon={model.edit.primary_number ? "bookmarked" : "bookmark"}
                                            onClick={() => {
                                                if (!model.edit.primary_number) {
                                                    this.props.onChange(true, "primary_number", model.view.id);
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                            </CRMInputButtonIconWrap>
                        </div>
                    ))
                }
            </div>
        );
    }
}
