import React from "react";
import { CRMColors } from "../../../../models/CRMColors";
import { CRMSpacing } from "../../../../models/CRMSpacing";
import { Background } from "../../../BuildingBlocks/Background";
import { ShineShape } from "../../../BuildingBlocks/ShineShape";
import { SpacingColumn } from "../../../BuildingBlocks/SpacingColumn";
import { Shape as BuildingBlocksShape } from "../../../BuildingBlocks/Shape";
import { SpacingRow } from "../../../BuildingBlocks/SpacingRow";

export const CRMCaseTablePlaceholderRows = () =>
    <SpacingColumn spacing={CRMSpacing.MEDIUM}>
        <Row />
        <Row />
        <Row />
        <Row />
        <Row />
        <Row />
    </SpacingColumn>

const Cell = () =>
    <BuildingBlocksShape
        height="14px"
        width="100%"
        color={CRMColors.NEUTRAL_8}
    />;

const Milestone = () =>
    <BuildingBlocksShape
        height="14px"
        width="100%"
        color={CRMColors.NEUTRAL_10}
        borderRadius="8px"
    />;

const Row = () =>
    <ShineShape
        backgroundColor={CRMColors.NEUTRAL_12} 
        shineColor={CRMColors.NEUTRAL_PAPER}
        duration={2000}
        width={"100%"}
        height="80px"
    >
        <Background padding={CRMSpacing.MEDIUM}>
            <SpacingColumn spacing={CRMSpacing.LARGE}>
                {/* COLUMNS */}
                <SpacingRow
                    spacing={CRMSpacing.LARGE}
                    childSize="24px 24px 3fr 1.5fr 1fr 1fr 1fr 1fr 90px 90px"
                >
                    <Cell />
                    <Cell />
                    <Cell />
                    <Cell />
                    <Cell />
                    <Cell />
                    <Cell />
                    <Cell />
                    <Cell />
                    <Cell />
                </SpacingRow>

                {/* MILESTONES */}
                <SpacingRow
                    spacing={CRMSpacing.SMALL}
                    childSize="1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr"
                >
                    <Milestone />
                    <Milestone />
                    <Milestone />
                    <Milestone />
                    <Milestone />
                    <Milestone />
                    <Milestone />
                    <Milestone />
                    <Milestone />
                </SpacingRow>
            </SpacingColumn>
        </Background>
    </ShineShape>;
