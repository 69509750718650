import { array } from "fp-ts";
import { pipe } from "fp-ts/lib/pipeable";
import React from "react";
import { TTriageSimpleEmailForm } from "../../../../../domain/codecs/form/TriageForm";
import { requireExhaustive } from "../../../../../shared/src/util";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { THexColor } from "../../../models/StringLiterals";
import { CRMColors } from "../../../models/CRMColors";
import { SpacingColumn } from "../../BuildingBlocks/SpacingColumn";
import { SpacingRow } from "../../BuildingBlocks/SpacingRow";
import { CRMIcon } from "../../CRMIcon/CRMIcon";
import { CRMParagraph } from "../../Simple/CRMParagraph/CRMParagraph";
import { hasVirus, isRelatedToCase, isSmallImage } from "../../Simple/CRMEmailAttachment/CRMEmailAttachment";
import { WeightMedium } from "../../WeightMedium/WeightMedium";
import { WeightSemiBold } from "../../WeightSemiBold/WeightSemiBold";
import { CRMEmailNotificationBar } from "../CRMEmailNotificationBar/CRMEmailNotificationBar";
import { WeightBold } from "../../WeightBold/WeightBold";

type TEmailSingleViewBodyNotificationBarProps = {
    email: TTriageSimpleEmailForm;
};

export const CRMEmailSingleViewBodyNotificationBar = (props: React.PropsWithChildren<TEmailSingleViewBodyNotificationBarProps>): JSX.Element => {
    const hasEmlFileVirusDetected = (): boolean =>
        props.email.children.detailed_email_form.children.email.eml_file_virus_detected;

    const hasSpoofedEmailNotification = (): boolean =>
        props.email.children.detailed_email_form.children.email.possibly_spoofed;

    const hasUnverifiedEmailNotification = (): boolean =>
        props.email.children.detailed_email_form.children.email.from_address_user_verification_status === "unverified";

    const hasAttachmentNotOnCase = (): boolean =>
        pipe(
            props.email.children.detailed_email_form.children.email.attachments,
            array.filter((attachment) => 
                !hasVirus(attachment) 
                && !isRelatedToCase(attachment) 
                && !isSmallImage(attachment)
            ),
        ).length > 0;

    type TNotification =
        "eml_file_virus_detected"
        | "spoofed_email"
        | "unverified"
        | "attachment_may_need_uploading";

    const notifications: Array<TNotification> = [
        ...(hasEmlFileVirusDetected() ? ["eml_file_virus_detected"] : []) as Array<TNotification>,
        ...(hasSpoofedEmailNotification() ? ["spoofed_email"] : []) as Array<TNotification>,
        ...(hasUnverifiedEmailNotification() && !hasSpoofedEmailNotification() ? ["unverified"] : []) as Array<TNotification>,
        ...(hasAttachmentNotOnCase() ? ["attachment_may_need_uploading"] : []) as Array<TNotification>,
    ];

    const getNotificationBorderColour = (notificationToUse: TNotification): THexColor =>
        notificationToUse === "eml_file_virus_detected" ? CRMColors.HIGHLIGHTS_INSTRUCTIONAL_BRICK_6
        : notificationToUse === "spoofed_email" ? CRMColors.HIGHLIGHTS_INSTRUCTIONAL_BRICK_6
        : notificationToUse === "unverified" ? CRMColors.HIGHLIGHTS_PERTINENT_YELLOW_0
        : notificationToUse === "attachment_may_need_uploading" ? CRMColors.PRIMARY_10
        : notificationToUse === null ? CRMColors.NEUTRAL_INK
        : requireExhaustive(notificationToUse);

    const getNotificationBackgroundColour = (notificationToUse: TNotification): THexColor =>
        notificationToUse === "eml_file_virus_detected" ? CRMColors.HIGHLIGHTS_INSTRUCTIONAL_BRICK_10
        : notificationToUse === "spoofed_email" ? CRMColors.HIGHLIGHTS_INSTRUCTIONAL_BRICK_10
        : notificationToUse === "unverified" ? CRMColors.HIGHLIGHTS_PERTINENT_YELLOW_6
        : notificationToUse === "attachment_may_need_uploading" ? CRMColors.PRIMARY_12
        : notificationToUse === null ? CRMColors.NEUTRAL_INK
        : requireExhaustive(notificationToUse);

    const getNotificationText = (notificationToUse: TNotification): JSX.Element => {
        switch(notificationToUse) {
            case "eml_file_virus_detected":
                return <EmlFileVirusDetected />
            case "spoofed_email":
                return <SpoofedEmail />
            case "unverified":
                return <UnverifiedEmail />
            case "attachment_may_need_uploading":
               return <AttachmentMayNeedUploading />
            case null:
                return <></>
            default:
                return requireExhaustive(notificationToUse)
        }
    }

    return <>
        {notifications.map((notification) => 
            <CRMEmailNotificationBar
                key={notification}
                backgroundColour={getNotificationBackgroundColour(notification)}
                borderColour={getNotificationBorderColour(notification)}
            >
                {getNotificationText(notification)}
            </CRMEmailNotificationBar>)
        }
    </>;
};

const SpoofedEmail = () => {
    return (
        <CRMParagraph>
            <SpacingRow
                spacing={CRMSpacing.TINY}
                justifyContent="start"
                alignItems="flex-start"
            >
                <CRMIcon
                    iconName="email-alert"
                    colour="neutral-ink"
                    size="x-small"
                />

                <SpacingColumn
                    spacing={CRMSpacing.TINY}
                    justifyContent="start"
                    alignItems="flex-start"
                >
                    <div>
                        <WeightSemiBold>This email could be fraudulent!</WeightSemiBold>
                    </div>
                    <WeightMedium>The sender may not be who they claim.</WeightMedium>
                </SpacingColumn>
            </SpacingRow>
        </CRMParagraph>
    )
}

const EmlFileVirusDetected = () => {
    return (
        <CRMParagraph>
            <SpacingRow
                spacing={CRMSpacing.TINY}
                justifyContent="start"
                alignItems="flex-start"
            >
                <CRMIcon
                    iconName="email-alert"
                    colour="neutral-ink"
                    size="x-small"
                />

                <SpacingColumn
                    spacing={CRMSpacing.TINY}
                    justifyContent="start"
                    alignItems="flex-start"
                >
                    <div>
                        <WeightSemiBold>This email contains a virus!</WeightSemiBold>
                    </div>
                    <WeightMedium>When scanning the email we've found that a virus exists in one of the attachments or the main body of the email. As a precaution we can't show you the contents of this email.</WeightMedium>
                    <WeightBold>If appropriate please call the sender of this email to explain the situation and ask them to resend the email.</WeightBold>
                </SpacingColumn>
            </SpacingRow>
        </CRMParagraph>
    )
}

const UnverifiedEmail = () => {
    return (
        <CRMParagraph>
            <SpacingRow
                spacing={CRMSpacing.TINY}
                justifyContent="start"
                alignItems="flex-start"
            >
                <CRMIcon
                    iconName="email-question"
                    colour="neutral-ink"
                    size="x-small"
                />

                <WeightSemiBold>This sender should have verified their email address but has not.</WeightSemiBold>
            </SpacingRow>
        </CRMParagraph>
    );
}

const AttachmentMayNeedUploading = () => {
    return (
        <CRMParagraph>
            <SpacingRow
                spacing={CRMSpacing.TINY}
                justifyContent="start"
                alignItems="flex-start"
            >
                <CRMIcon
                    iconName="file-clip-notification"
                    colour="neutral-ink"
                    size="x-small"
                />

                <div>
                    <WeightSemiBold>New documents exist!</WeightSemiBold>&nbsp;
                    <WeightMedium>You may want to upload these to the case.</WeightMedium>
                </div>
            </SpacingRow>
        </CRMParagraph>
    );
}