import React from "react";
import { useHover } from "../../../hooks/useHover";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { TCRMMainColours } from "../../../models/TCRMMainColours";
import { SpacingRow } from "../../BuildingBlocks/SpacingRow";
import { CRMIcon, TIcon } from "../../CRMIcon/CRMIcon";
import { CRMNotificationBadgeGroup, TCRMNotificationBadgeBadges } from "../CRMNotificationBadgeGroup/CRMNotificationBadgeGroup";

type TMenuBarProps = {
    logoHref: string;
    colors: TMenuColors,
    leftButtons: Array<TMenuBarButton>;
    centerButtons?: Array<TMenuBarButton>;
    rightButtons: Array<TMenuBarButton | JSX.Element>;
};

type TMenuColors = {
    logoIconColor: TCRMMainColours,
    logoBackgroundColor: string;
    backgroundColor: string;
    hoverColor: string;
}

export type TMenuBarButton = {
    icon: TIcon;
    label: string;
    isDisabled?: boolean;
    href?: string;
    onClick?: (e: React.MouseEvent<unknown, MouseEvent>) => void;
    badges?: Array<TCRMNotificationBadgeBadges>;
    openInNewTab?: boolean;
}

export const CRMMenuBar = (props: React.PropsWithChildren<TMenuBarProps>): JSX.Element => {

    return (
        <div
            className={`crm-menu-bar`}
            style={{
                backgroundColor: props.colors.backgroundColor
            }}
        >
            {/* LOGO & LEFT BUTTONS */}
            <SpacingRow spacing={CRMSpacing.TINY}>
                <a 
                    className="crm-menu-bar__logo"
                    href={props.logoHref}
                    style={{
                        backgroundColor: props.colors.logoBackgroundColor
                    }}
                >
                    <CRMIcon
                        iconName="sailboat"
                        colour={props.colors.logoIconColor}
                    />
                </a>
                
                {props.leftButtons
                    .filter((button) => button.isDisabled !== true)
                    .map((button, index) => (
                        <Button
                            key={index}
                            button={button}
                            colors={props.colors}
                        />
                    ))
                }
            </SpacingRow>
    
            {/* CENTER BUTTONS */}
            {props.centerButtons && props.centerButtons.length > 0 &&
                <SpacingRow justifyContent="center">
                    {props.centerButtons
                        .filter((button) => button.isDisabled !== true)
                        .map((button, index) => (
                            <Button
                                key={index}
                                colors={props.colors}
                                button={button}
                            />
                        ))
                    }
                </SpacingRow>
            }
            
            {/* FAR RIGHT BUTTONS */}
            <SpacingRow justifyContent="end">
                {props.rightButtons
                    .filter((button) =>
                        "isDisabled" in button === false
                        || (
                            "isDisabled" in button
                            && button.isDisabled !== true
                        )
                    )
                    .map((button, index) => (
                        "label" in button
                            ? <Button
                                key={index}
                                colors={props.colors}
                                button={button}
                            />
                            : button
                    ))
                }
            </SpacingRow>
        </div>
    );
};

const Button = (props: {button: TMenuBarButton, colors: TMenuColors }): JSX.Element => {

    const [ref, isHovered] = useHover<HTMLAnchorElement>({});

    const hasBadges = (): boolean =>
        (props.button.badges || []).length > 0;

    return (
        <div className="crm-menu-bar__button-wrap">
            <a
                ref={ref}
                className={`
                    crm-menu-bar__button
                `}
                title={props.button.label}
                href={props.button.href}
                onClick={props.button.onClick}
                style={{
                    backgroundColor: isHovered ?
                        props.colors.hoverColor :
                        props.colors.backgroundColor
                }}
                target={props.button.openInNewTab ? "_blank" : undefined}
            >
                <CRMIcon
                    iconName={props.button.icon}
                    colour="neutral-paper"
                    size="small"
                />
            </a>

            {/* BADGES */}
            {hasBadges() && <CRMNotificationBadgeGroup
                badges={props.button.badges || []}
                isHovering={isHovered}
            />}
        </div>
    );
}