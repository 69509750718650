import { useState, useEffect } from "react";
import { NonEmptyArray } from "fp-ts/lib/NonEmptyArray";
import { array, option } from "fp-ts";
import { pipe } from "fp-ts/lib/function";

export type TSwitchOption<T> = {
    label: string;
    value: T;
    inputValue: unknown;
    input: JSX.Element;
};


export const useInputSwitch = <T extends string>(options: NonEmptyArray<TSwitchOption<T>>) => {

    const doesMoreThanOneInputOptionHaveValue = (): boolean => pipe(
        options,
        array.filter((inputOption) => !!inputOption.inputValue),
        (optionsWithValue) => optionsWithValue.length > 1,
    );

    const findFilledInOptionValue = (): T => pipe(
        options,
        array.findFirst((inputOption) => !!inputOption.inputValue),
        option.fold(
            () => options[0].value,
            (inputOption) => inputOption.value,
        )
    );

    const [selectedOption, setSelectedOption] = useState<T>(findFilledInOptionValue());

    useEffect(
        () => {
            if (!doesMoreThanOneInputOptionHaveValue()) {
                setSelectedOption(findFilledInOptionValue());
            }
        },
        [options]
    );

    return {
        selectedOption,
        setSelectedOption,
    };
}