import { array } from "fp-ts";
import { pipe } from "fp-ts/lib/function";
import React from "react";
import { TFormStatus } from "../../../../shared/src/codecs/codec";
import { CRMAutosaveIndicatorWrap } from "../CRM/CRMAutosaveIndicatorWrap/CRMAutosaveIndicatorWrap";
import { CRMButtonCard } from "../CRMButtonCard/CRMButtonCard";
import { CRMIcon, TIcon } from "../CRMIcon/CRMIcon";
import { CRMPadding } from "../Simple/CRMPadding/CRMPadding";
import { CRMParagraph } from "../Simple/CRMParagraph/CRMParagraph";
import { CRMSimpleDeferButtonComponent } from "../CRMSimpleDeferButtonComponent/CRMSimpleDeferButtonComponent";
import { CRMSpacingColumn } from "../CRMSpacingColumn/CRMSpacingColumn";
import { CRMSpacingRow } from "../CRMSpacingRow/CRMSpacingRow";
import { WeightMedium } from "../WeightMedium/WeightMedium";



type TDeferPopoutProps<K extends string> = {
    defers: Array<TDeferPopoutOption<K>>,
    onChange: (key: K, value: string | null) => void;
    formStatus: TFormStatus;
}

export type TDeferPopoutOption<K extends string> = {
    iconName: TIcon,
    label: string,
    key: K,
    value: string | null
};

export const CRMMultipleDefersPopoutComponent = <K extends string>(props: TDeferPopoutProps<K>): JSX.Element =>
    <CRMButtonCard
        label={<CRMSpacingRow spacing="tiny" alignItems="center">
            <CRMIcon iconName="snooze" size="medium" colour="neutral-ink" />
            <CRMParagraph>
                Defer Jobs
            </CRMParagraph>
        </CRMSpacingRow>}
    >
        <CRMPadding size="medium">
            <CRMAutosaveIndicatorWrap status={props.formStatus}>
                <CRMSpacingColumn spacing="medium">
                    {pipe(
                        props.defers,
                        array.mapWithIndex((i, defer) =>
                            <CRMSimpleDeferButtonComponent
                                key={i}
                                label={<CRMSpacingRow spacing="tiny" alignItems="center">
                                    <CRMIcon iconName={defer.iconName} size="tiny" colour="neutral-ink" />
                                    <CRMParagraph>
                                        <WeightMedium>
                                            {defer.label}:
                                        </WeightMedium>
                                    </CRMParagraph>
                                </CRMSpacingRow>}
                                value={defer.value}
                                onChange={(value) =>
                                    props.onChange(defer.key, value)
                                }
                                displayError={false}
                            />
                        ),
                    )}
                </CRMSpacingColumn>
            </CRMAutosaveIndicatorWrap>
        </CRMPadding>
    </CRMButtonCard>