import * as t from "io-ts";
import * as ViewingType from "./ViewingType";
import { DateTime } from "luxon";
import { TValidationFunction } from "../../shared/src/validation/Function";
import { object } from "../../shared/src/validation/create/object";
import { isEnum } from "../../shared/src/validation/create/isEnum";
import { array } from "../../shared/src/validation/create/array";
import { arrayUniqueBy } from "../../shared/src/validation/create/arrayUniqueBy";
import { inOrder } from "../../shared/src/validation/compose/inOrder";
import { required } from "../../shared/src/validation/basic/required";
import { stringIsoUtcDateTime } from "../../shared/src/validation/basic/stringIsoUtcDateTime";
import { stringIsoDateTimeIsFuture } from "../../shared/src/validation/basic/stringIsoDateTimeIsFuture";
import { nonEmptyArray } from "../../shared/src/validation/basic/nonEmptyArray";

export const codec = t.type({
    viewing_type: ViewingType.codec,
    requested_times: t.array(t.string),
});

export const validator: TValidationFunction =
    object({
        viewing_type: inOrder(
            required,
            isEnum(ViewingType.values),
        ),
        requested_times: inOrder(
            required,
            array(inOrder(stringIsoUtcDateTime, stringIsoDateTimeIsFuture)),
            nonEmptyArray,
            arrayUniqueBy((x) => typeof x === "string" ? DateTime.fromISO(x).toISO() : ""),
        ),
    });
