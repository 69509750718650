import React from "react";

export class CRMTitleParagraphMono extends React.Component<React.PropsWithChildren<{}>> {
    public render (): JSX.Element {
        return (
            <span className="crm-title-paragraph-mono">
                {this.props.children}
            </span>
        );
    }
}
