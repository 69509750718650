import React from "react";
import { FrontOutsidePopupBasic } from "../FrontOutsidePopupBasic/FrontOutsidePopupBasic";
import * as util from "../../../../../../shared/src/util";
import FrontParagraphRegular from "../../../Front/Simple/FrontParagraphRegular/FrontParagraphRegular";
import { WeightMedium } from "../../../WeightMedium/WeightMedium";
import FrontTitleMinor from "../../Simple/FrontTitleMinor/FrontTitleMinor";
import { TIcon } from "../../Simple/FrontIcon/FrontIcon";
import { FrontLink } from "../../../FrontLink/FrontLink";
import { SpacingColumn } from "../../../BuildingBlocks/SpacingColumn";
import { FrontSpacing } from "../../../../models/FrontSpacing";
import { WeightSemiBold } from "../../../WeightSemiBold/WeightSemiBold";

export class FrontBuyerReadyFactorModal extends React.Component<{
    variant:
        "proof_of_funds"
        | "id_check"
        | "solicitor_details"
        | "mortgage_broker_details";
    isOpen: boolean;
    onClose: () => void;
}> {
    getPopupIconName = (): TIcon => {
        switch(this.props.variant) {
            case "proof_of_funds":
                return "coins";
            case "id_check":
                return "id-card";
            case "solicitor_details":
                return "contract";
            case "mortgage_broker_details":
                return "bank";
            default:
                return util.requireExhaustive(this.props.variant);
        }
    };

    getPopupTitle = (): string => {
        switch(this.props.variant) {
            case "proof_of_funds":
                return "Proof of funds";
            case "id_check":
                return "ID check";
            case "solicitor_details":
                return "Your solicitor's details";
            case "mortgage_broker_details":
                return "Your mortgage broker's details";
            default:
                return util.requireExhaustive(this.props.variant);
        }
    };

    getDescription = (): string => {
        switch(this.props.variant) {
            case "proof_of_funds":
                return "Understanding how you'd pay for the property is really important to us and provides the seller with the assurance they need to be able to accept your offer.";
            case "id_check":
                return "We need to know that there isn't any reason why we couldn't sell you this property. To make ID checks easier we've teamed up with the app Thirdfort.";
            case "solicitor_details":
                return "When your offer is accepted a solicitor will work to help you purchase the property by raising enquiries with the seller and facilitating the payment. It can sometimes take some time to find the right solicitor which is why we ask our buyers to have found a solicitor before their offer is accepted.";
            case "mortgage_broker_details":
                return "A mortgage broker is a financial expert who can help take some of the pressure off you in finding the best mortgage for your needs. If you are using a broker please provide their contact details, we'll then confirm your position with them.";
            default:
                return util.requireExhaustive(this.props.variant);
        }
    };

    getHowDoIDescription = (): JSX.Element => {
        switch(this.props.variant) {
            case "proof_of_funds":
                return <>
                    If you're planning on purchasing the property with a mortgage please email a copy of your Decision In Principle / Agreement In Principle to us. We'll also need to see a screenshot of a bank statement showing the funds required for your mortgage deposit. If you are planning on purchasing the property entirely with cash funds, you'll only need to provide the bank statement screenshot.&nbsp;
                    <FrontLink href={`mailto:hello@sailhomes.co.uk?subject=My proof of funds`}>Click here to email this to us.</FrontLink>
                </>;
            case "id_check":
                return <>
                    When you made your offer with us we asked Thirdfort to get in touch by SMS, they’ll send you a link to their app where you can complete the ID check. The process should only take a few minutes but if you have any problems or if you don't get a message then feel free to get in touch.
                </>;
            case "solicitor_details":
                return <SpacingColumn spacing={FrontSpacing.SMALL_3}>
                    <div>
                        We recommend you use <WeightSemiBold>Sail Legal</WeightSemiBold>. 
                        They are <WeightSemiBold>50% faster</WeightSemiBold> than the national average 
                        of 4 months (offer to completion).&nbsp;
                    </div>
                    <div>
                        <FrontLink
                            href="https://www.saillegal.co.uk/quotes-sail-homes"
                            target="_blank"
                        >
                            Get a free quote here
                        </FrontLink>
                    </div>
                    <div>
                        If you already know which solicitor you will use then please email us their details. 
                        Don't worry we wont get in touch with them until your offer is accepted and you don't 
                        need to make any payments to them yet.
                    </div>
                    <FrontLink href={`mailto:hello@sailhomes.co.uk?subject=My solicitor details`}>
                        Click here to email details of your solicitor to us.
                    </FrontLink>
                </SpacingColumn>;
            case "mortgage_broker_details":
                return <SpacingColumn spacing={FrontSpacing.SMALL_3}>
                    <div>
                        Please email us their details and we'll take it from there.&nbsp;
                        <FrontLink href={`mailto:hello@sailhomes.co.uk?subject=My mortgage broker's details`}>
                            Click here to email details of your mortgage broker to us
                        </FrontLink>
                        .
                    </div>
                    <div>
                        Alternatively if you dont have a Decision in Principle yet, please&nbsp;
                        <FrontLink
                            href="https://lucramortgages.co.uk/thank-you/?calendly=true"
                            target="_blank"
                        >
                            speak to our recommended broker Lucra
                        </FrontLink>
                        , rated&nbsp;
                        <FrontLink
                            href="https://uk.trustpilot.com/review/lucramortgages.co.uk"
                            target="_blank"
                        >
                            5* on Trustpilot
                        </FrontLink>
                        .
                    </div>
                </SpacingColumn>;
            default:
                return util.requireExhaustive(this.props.variant);
        }
    };

    public render (): JSX.Element {
        return (
            <FrontOutsidePopupBasic
                title={this.getPopupTitle()}
                isOpen={this.props.isOpen}
                onClose={this.props.onClose}
                iconName={this.getPopupIconName()}
            >
                {/* Description */}
                <FrontParagraphRegular>
                    <WeightMedium>
                        {this.getDescription()}
                    </WeightMedium>
                </FrontParagraphRegular>

                {/* How do I provide this information */}
                <SpacingColumn spacing={FrontSpacing.MEDIUM_1}>
                    <FrontTitleMinor>
                        How do I provide this information?
                    </FrontTitleMinor>
                    <FrontParagraphRegular>
                        {this.getHowDoIDescription()}
                    </FrontParagraphRegular>
                </SpacingColumn>

                {/* Then what happens */}
                <SpacingColumn spacing={FrontSpacing.MEDIUM_1}>
                    <FrontTitleMinor>
                        Then what happens?
                    </FrontTitleMinor>
                    <FrontParagraphRegular>
                        Providing this information improves our calculation of your "Completion time" and "Fall through risk". As these improve the seller is more likely to accept your offer.
                    </FrontParagraphRegular>
                </SpacingColumn>
            </FrontOutsidePopupBasic>
        );
    }
}
