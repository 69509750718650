import React from "react";
import { TContainerStateProps } from "./../../state/TContainerStateProps";
import { CRMSpacingColumn } from "../../components/CRMSpacingColumn/CRMSpacingColumn";
import { CRMTitleSection } from "../../components/CRMTitleSection/CRMTitleSection";
import { CRMSpacer } from "../../components/CRMSpacer/CRMSpacer";
import { CRMAutosaveIndicatorWrap } from "../../components/CRM/CRMAutosaveIndicatorWrap/CRMAutosaveIndicatorWrap";
import { CRMCodecEditForm } from "../../components/CRMCodecEditForm/CRMCodecEditForm";
import { CaseOtherSideConveyancerDetails } from "../../../../domain/codecs/CasesContractBlock";
import CRMInputLabelAndErrorWrapComponent from "../../components/CRMInputLabelAndErrorWrapComponent/CRMInputLabelAndErrorWrapComponent";
import { CRMDeferButtonComponent } from "../../components/CRMDeferButtonComponent/CRMDeferButtonComponent";
import { CRMLink } from "../../components/CRMLink/CRMLink";
import { CRMSpacingRow } from "../../components/CRMSpacingRow/CRMSpacingRow";

const CRMLegalCaseDetailsOtherSideConveyancerContainer = (props: TContainerStateProps): JSX.Element => (
    <CRMAutosaveIndicatorWrap
        status={props.state.forms.contracts_block.children.details.status}
    >
        <CRMTitleSection>
            Other Side's Conveyancer Details
        </CRMTitleSection>
        <CRMSpacer size="large" />
        <CRMSpacingColumn spacing="medium">
            {/* GET OTHER SIDE CONVEYANCER DETAILS DEFER */}
            <CRMInputLabelAndErrorWrapComponent label="Defer get O/S conveyancer details job">
                <CRMDeferButtonComponent
                    value={props.state.forms.contracts_block.children.details.edited.get_other_side_conveyancer_details_defer}
                    onChange={(get_other_side_conveyancer_details_defer) => props.dispatch({
                        type: "LEGAL_CASE_CONTRACTS_BLOCK_CHANGE",
                        payload: {
                            ...props.state.forms.contracts_block.children.details,
                            edited: {
                                ...props.state.forms.contracts_block.children.details.edited,
                                get_other_side_conveyancer_details_defer,
                            }
                        }
                    })}
                    displayError={false}
                />
            </CRMInputLabelAndErrorWrapComponent>

            {/* CONVEYANCER DETAILS */}
            <CRMCodecEditForm
                codec={CaseOtherSideConveyancerDetails}
                model={props.state.forms.contracts_block.children.details.edited}
                validationErrors={props.state.forms.contracts_block.children.details.validationErrors}
                onChange={(edited) => props.dispatch({
                    type: "LEGAL_CASE_CONTRACTS_BLOCK_CHANGE",
                    payload: {
                        ...props.state.forms.contracts_block.children.details,
                        edited: {
                            ...props.state.forms.contracts_block.children.details.edited,
                            ...edited,
                        }
                    }
                })}
            />
            
            {props.state.forms.contracts_block.children.details.edited.other_side_conveyancer_organisation_name !== "" && <CRMSpacingRow spacing="medium">
                <CRMLink target="_blank" href={`https://solicitors.lawsociety.org.uk/search/results?Type=0&Name=${encodeURIComponent(props.state.forms.contracts_block.children.details.edited.other_side_conveyancer_organisation_name)}&IncludeNlsp=True&Pro=True`} linkStyle="normal">Law Society Search</CRMLink>
                <CRMLink target="_blank" href="https://solicitors.lawsociety.org.uk/?Pro=True" linkStyle="normal">(Plain LS)</CRMLink>
                <CRMLink target="_blank" href="https://www.clc-uk.org/find-a-clc-lawyer/" linkStyle="normal">(Plain CLC)</CRMLink>
            </CRMSpacingRow>}
        </CRMSpacingColumn>
    </CRMAutosaveIndicatorWrap>
);

export default CRMLegalCaseDetailsOtherSideConveyancerContainer;