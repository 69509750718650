import React from "react";
import { FrontSpacing } from "../../../../models/FrontSpacing";
import { SpacingRow } from "../../../BuildingBlocks/SpacingRow";
import { MediaCompSwitch } from "../../../BuildingBlocks/MediaCompSwitch";
import { SpacingColumn } from "../../../BuildingBlocks/SpacingColumn";


export const FrontFormRow = (props: React.PropsWithChildren<{
    alignItems?: "center" | "flex-start" | "flex-end" | "baseline";
    childSize?: string
}>): JSX.Element => {
    return (
        <MediaCompSwitch
            DesktopComponent={SpacingRow}
            desktopProps={{ spacing: FrontSpacing.MEDIUM_1, childSize: props.childSize || "1fr 1fr", alignItems: props.alignItems || "flex-start" }}
            MobileComponent={SpacingColumn}
            mobileProps={{ spacing: FrontSpacing.MEDIUM_1 }}
            breakpoint="600"
        >
            {props.children}
        </MediaCompSwitch>
    )
}