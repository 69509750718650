import React from "react";
import { getToOrFromShortText, TLegalEmail1 } from "../../../../../domain/codecs/LegalEmail";
import { CRMTableRow } from "../../CRMTableRow/CRMTableRow";
import * as util from "../../../../../shared/src/util";
import { CRMIcon, TIcon } from "../../CRMIcon/CRMIcon";
import { SpacingRow } from "../../../components/BuildingBlocks/SpacingRow";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { WeightMedium } from "../../WeightMedium/WeightMedium";
import { DateTime } from "luxon";
import { pipe } from "fp-ts/function";
import { ifTrueInject } from "../../../../../shared/src/utilsByDomain/array";
import { TAction } from "../../CRMTableRow/CRMTableRow";
import { trimWithEllipsis } from "../../../../../shared/src/utilsByDomain/string/trimWithEllipsis";
import { WeightBold } from "../../WeightBold/WeightBold";
import { isAFunctionCurried, onCallIfExists } from "../../../functions/functions";
import { TTriageSimpleEmailForm } from "../../../../../domain/codecs/form/TriageForm";
import { CRMParagraph } from "../../Simple/CRMParagraph/CRMParagraph";
import { CRMColors } from "../../../models/CRMColors";
import { TextColour } from "../../TextColour/TextColour";
import { THexColor } from "../../../models/StringLiterals";

type TEmailRowStatus =
    "resolved-read"
    | "resolved-unread"
    
    | "unresolved-read"
    |"unresolved-unread"
;

export type TEmailRowProps = {
    email: TTriageSimpleEmailForm;
    isSelected?: boolean;
    onClick: () => void;
    onSelect?: () => void;
    onAssign?: () => void;
    onAssignRelated?: () => void;
    onArchive?: () => void;
    onDisassociate?: () => void;
    onReply?: () => void;
    onForward?: () => void;
};

export const CRMEmailRow = (props: React.PropsWithChildren<TEmailRowProps>): JSX.Element => {

    const getStatus = (): TEmailRowStatus => {
        const isRead = props.email.children.is_read || false;
        const isResolved = props.email.edited.resolved_at || false;

        if (isResolved && isRead) {
            return "resolved-read";
        }
        
        if (isResolved && !isRead) {
            return "resolved-unread";
        }

        if (!isResolved && isRead) {
            return "unresolved-read";
        }

        return "unresolved-unread";
    } 

    const isRead = (): boolean => props.email.children.is_read || false;

    const getBackgroundColor = (): THexColor => {
        const status = getStatus();
        switch(status) {
            case "resolved-read":
                return CRMColors.NEUTRAL_10;
            case "resolved-unread":
                return CRMColors.NEUTRAL_PAPER;
            case "unresolved-read":
                return CRMColors.NEUTRAL_10;
            case "unresolved-unread":
                return CRMColors.NEUTRAL_PAPER;
            default:
                util.requireExhaustive(status)
        }
    }
    
    const getFontColourToUse = (): THexColor => {
        const status = getStatus();
        switch(status) {
            case "resolved-read":
                return CRMColors.NEUTRAL_0;
            case "resolved-unread":
                return CRMColors.NEUTRAL_INK;
            case "unresolved-read":
                return CRMColors.NEUTRAL_0;
            case "unresolved-unread":
                return CRMColors.NEUTRAL_INK;
            default:
                util.requireExhaustive(status)
        }
    }

    return <CRMTableRow
        isSelected={props.isSelected}
        backgroundColour={getBackgroundColor()}
        onSelect={props.onSelect}
        onClick={props.onClick}
        actions={pipe(
            [] as Array<TAction>,
            ifTrueInject(
                isAFunctionCurried(props.onAssign),
                {
                    iconName: "file-box" as TIcon,
                    title: "Assign",
                    onClick: onCallIfExists(props.onAssign),
                }
            ),
            ifTrueInject(
                isAFunctionCurried(props.onAssignRelated),
                {
                    iconName: "done" as TIcon,
                    title: "Assign",
                    onClick: onCallIfExists(props.onAssignRelated),
                }
            ),
            ifTrueInject(
                isAFunctionCurried(props.onArchive),
                {
                    iconName: "archive" as TIcon,
                    title: "Archive",
                    onClick: onCallIfExists(props.onArchive),
                }
            ),
            ifTrueInject(
                isAFunctionCurried(props.onDisassociate),
                {
                    iconName: "unfile" as TIcon,
                    title: "Disassociate",
                    onClick: onCallIfExists(props.onDisassociate),
                }
            ),
            ifTrueInject(
                isAFunctionCurried(props.onReply),
                {
                    iconName: "reply" as TIcon,
                    title: "Reply",
                    onClick: onCallIfExists(props.onReply),
                }
            ),
            ifTrueInject(
                isAFunctionCurried(props.onForward),
                {
                    iconName: "forward-arrow" as TIcon,
                    title: "Forward",
                    onClick: onCallIfExists(props.onForward),
                }
            ),
        )}
        onHoverFace={
            <SpacingRow
                spacing={CRMSpacing.MEDIUM}
                justifyContent="start"
            >
                {/* DIRECTION ICON */}
                <DirectionIcon
                    direction={props.email.original.direction}
                    isRead={isRead()}
                />
                {/* SNIPPET */}
                <CRMParagraph lineHeight={1}>
                    {trimWithEllipsis(
                        props.email.original.snippet === "" ? 
                            "No content" : 
                            props.email.original.snippet, 
                        42
                    )}
                </CRMParagraph>
            </SpacingRow>
        }
    >
        <SpacingRow
            spacing={CRMSpacing.MEDIUM}
            childSize={`24px 1fr 1fr 50px`}
            justifyContent="space-between"
        >
            {/* DIRECTION ICON */}
            <DirectionIcon
                direction={props.email.original.direction}
                isRead={isRead()}
            />

            {/* TO/FROM */}
            <CRMParagraph lineHeight={1}>
                <WeightMedium>
                    <TextColour colour={getFontColourToUse()}>
                        {trimWithEllipsis(
                            getToOrFromShortText(props.email.original as unknown as TLegalEmail1), 
                            8
                        )}
                    </TextColour>
                </WeightMedium>
            </CRMParagraph>

            {/* SUBJECT */}
            <CRMParagraph lineHeight={1}>
                <TextColour colour={getFontColourToUse()}>
                    {/* UNREAD SUBJECT IN BOLD */}
                    {!isRead() && <WeightBold>
                        {trimWithEllipsis(
                            props.email.original.subject ? 
                                props.email.original.subject: 
                                "No subject", 
                            24
                        )}
                    </WeightBold>}

                    {/* READ SUBJECT IN MEDIUM */}
                    {isRead() && <WeightMedium>
                        {trimWithEllipsis(
                            props.email.original.subject ? 
                                props.email.original.subject: 
                                "No subject", 
                            24
                        )}
                    </WeightMedium>}
                </TextColour>
            </CRMParagraph>

            {/* DATE */}
            <CRMParagraph lineHeight={1}>
                <WeightMedium>
                    <TextColour colour={getFontColourToUse()}>
                        {DateTime.fromISO(props.email.original.sent_at).setZone("Europe/London").toFormat("d LLL")}
                    </TextColour>
                </WeightMedium>
            </CRMParagraph>
        </SpacingRow>
    </CRMTableRow>;
};

// DIRECTION ICON
type TDirectionIconProps = {
    direction: "inbound" | "outbound";
    isRead: boolean;
};

const DirectionIcon = (props: TDirectionIconProps): JSX.Element => {
    return (
        <CRMIcon
            iconName={
                props.direction === "outbound" ? "forward-arrow"
                : props.direction === "inbound" ? "backward-arrow"
                : util.requireExhaustive(props.direction)
            }
            size="medium"
            colour={props.isRead ? "neutral-4" : "neutral-2"}
            title={props.direction === "outbound" ? "Outbound email" : "Inbound email"}
        />
    )
};