import React from "react";
import { TAdhocTaskForms, TCasesCaseCreateAdhocForm, TCasesCaseForm } from "../../../../../../domain/codecs/form/CasesForm";
import { CRMCaseTableRowMain } from "../CRMCaseTableRowMain/CRMCaseTableRowMain";
import { CRMCaseTableRowMilestones } from "../CRMCaseTableRowMilestones/CRMCaseTableRowMilestones";
import { onChangeForm } from "../../../../../../shared/src/codecs/types/form";
import { SpacingColumn } from "../../../BuildingBlocks/SpacingColumn";
import { CRMSpacing } from "../../../../models/CRMSpacing";
import { Background } from "../../../BuildingBlocks/Background";
import { CRMColors } from "../../../../models/CRMColors";
import { CRMCaseTableRowTasks } from "../CRMCaseTableRowTasks/CRMCaseTableRowTasks";
import { TPlainUser } from "../../../../../../domain/codecs/User";

type TCaseTableRowProps = {
    form: TCasesCaseForm;
    assignableUsers: Array<TPlainUser>;

    isOpen: boolean;
    onChangeFormComment: (form: TCasesCaseForm) => void;
    onSubmitForm: (form: TCasesCaseForm) => void;
    onToggleOpen: () => void;

    onChangeTask: (form: TAdhocTaskForms) => void;
    onArchiveTask: (form: TAdhocTaskForms) => void;
    onSubmitNewTask: (form: TCasesCaseCreateAdhocForm) => void;
};

export const CRMCaseTableRow = (props: React.PropsWithChildren<TCaseTableRowProps>): JSX.Element => {

    const isChased = props.form.children.last_chase_transition.status === "chase";
    const isWorkableTimeout = props.form.children.last_chase_transition.status === "timeout";
    const isChaseChangeAcknowledged = props.form.children.last_chase_transition.acknowledged;

    const onTogglePin = onChangeForm(props.form, props.onSubmitForm)("is_pinned");

    return (
        <div 
            className={`
                crm-case-table-row
                crm-case-table-row--${props.isOpen ? 'open' : 'closed'}
                ${!isWorkableTimeout ? `crm-case-table-row--${isChaseChangeAcknowledged ? 'old' : 'new'}-${isChased ? 'chase' : 'workable'}` : ""}
                ${isWorkableTimeout ? "crm-case-table-row--timeout" : ""}
            `}
        >
            {/* ROW (TOP) */}
            <CRMCaseTableRowMain 
                form={props.form}
                onTogglePin={onTogglePin}
                onChangeFormComment={props.onChangeFormComment}
                onSubmitForm={props.onSubmitForm}
            />

            <Background color={CRMColors.NEUTRAL_12}>
                <SpacingColumn spacing={CRMSpacing.LARGE}>
                    {/* MILESTONES */}
                    <CRMCaseTableRowMilestones 
                        form={props.form}
                        isOpen={props.isOpen}
                        onToggleOpen={props.onToggleOpen}
                    />

                    {/* EXPANDED ROW */}
                    {props.isOpen &&
                        <CRMCaseTableRowTasks
                            form={props.form}
                            assignableUsers={props.assignableUsers}
                            onChangeTask={props.onChangeTask}
                            onArchiveTask={props.onArchiveTask}
                            onSubmitNewTask={props.onSubmitNewTask}
                        />
                    }
                </SpacingColumn>
            </Background>
        </div>
    );
};

