import React from "react";
import { CRMCardOutside } from "../../components/CRMCardOutside/CRMCardOutside";
import { CRMSpacing } from "../../models/CRMSpacing";
import { TContainerStateProps } from "../../state/TContainerStateProps";
import { SpacingColumn } from "../../components/BuildingBlocks/SpacingColumn";
import { Background } from "../../components/BuildingBlocks/Background";
import { CRMButtonPrimary } from "../../components/CRMButtonPrimary/CRMButtonPrimary";
import { CRMCodecEditForm } from "../../components/CRMCodecEditForm/CRMCodecEditForm";
import { CRMParagraph } from "../../components/Simple/CRMParagraph/CRMParagraph";
import { CreateStaffUserForm } from "../../../../domain/codecs/form/CreateStaffUserForm";
import { doesErrorCodeExist } from "../../../../shared/src/codecs/errors";

export const CRMAdminCreateStaffUserContainer = (props: TContainerStateProps): JSX.Element => {

    const form = props.state.forms.create_staff_user_page;

    return (
        <CRMCardOutside borderRounding="right">
            <Background padding={CRMSpacing.LARGE} maxWidth="100%">
                <SpacingColumn spacing={CRMSpacing.LARGE}>
                    <CRMParagraph>
                        Create Staff User With Form Below
                    </CRMParagraph>
                    <CRMParagraph>
                        All fields are required <b>and password must be at least 16 characters long</b>
                    </CRMParagraph>
                    <CRMCodecEditForm 
                        codec={CreateStaffUserForm.payload.codec} 
                        model={form.edited}
                        onChange={(edited) => props.dispatch({
                            type: "CREATE_STAFF_USER_FORM_CHANGE",
                            payload: {
                                ...form,
                                edited: {
                                    ...form.edited,
                                    ...edited,
                                }
                            }
                        })}
                        validationErrors={form.validationErrors}
                    />
                    <CRMButtonPrimary
                        label="Create Staff User"
                        onClick={() => props.dispatch({
                                type: "CREATE_STAFF_USER_FORM_SUBMIT",
                                payload: form
                            })
                        }
                    />
                    {form.status === "success" && <CRMParagraph>
                        CREATED USER
                    </CRMParagraph>}
                    {(form.status === "failure" || form.status === "validationError" || form.status === "unauthorised") && <CRMParagraph>
                        ERROR CREATING USER
                    </CRMParagraph>}
                    {form.status === "validationError" && doesErrorCodeExist("InUse", form.validationErrors) && <CRMParagraph>
                        USER ALREADY EXISTS
                    </CRMParagraph>}
                </SpacingColumn>
            </Background>
        </CRMCardOutside>
    );
};
