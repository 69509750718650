import React from "react";
import { FrontListForm } from "../FrontListForm/FrontListForm";
import { FrontFormSummary, TFormSummaryItem } from "../FrontFormSummary/FrontFormSummary";
import { TFormEditable, TUnpackOriginal, TUnpackEditable } from './../../../../../../shared/src/codecs/types/formEditable'


type TCondensedFormProps<T> = {
    isEditable: boolean;
    canSubmit: boolean;
    canDelete?: boolean;
    form: T;
    deletePopupText: JSX.Element;
    deleteLabelText: string;
    formSummary: Array<TFormSummaryItem>;
    onChange: (form: T) => void;
    onDelete: (form: T) => void;
};

export const FrontCondensedForm = <T extends TFormEditable<TUnpackOriginal<T>, TUnpackEditable<T>>>(props: React.PropsWithChildren<TCondensedFormProps<T>>): JSX.Element => {
    return (
        <div>
            {!props.isEditable &&
                <FrontFormSummary
                    details={props.formSummary}
                />
            }

            {props.isEditable &&
                <FrontListForm
                    canSubmit={props.canSubmit}
                    canDelete={props.canDelete}
                    deletePopupText={props.deletePopupText}
                    onDelete={() => props.onDelete(props.form)}
                    deleteLabelText={props.deleteLabelText}
                >
                    {props.children}
                </FrontListForm>
            }
        </div>
    );
};
