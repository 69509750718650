import React from "react";

type TTextRightProps = {

};

export const TextRight = (props: React.PropsWithChildren<TTextRightProps>): JSX.Element => {

    return (
        <div style={{ textAlign: "right" }}>
            {props.children}
        </div>
    );
};
