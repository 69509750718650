import React from "react";
import { CaseLedgerCategory_displayString, ledgerTypeDictionary } from "../../../../../domain/codecs/CaseLedger";
import { TQuoteRate1, TQuoteRate2 } from "../../../../../domain/codecs/QuoteRate";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { SpacingColumn } from "../../BuildingBlocks/SpacingColumn";
import { CRMTitleSubSection } from "../../CRMTitleSubSection/CRMTitleSubSection";
import { CRMSingleLineLabelAndValue } from "../../Simple/CRMSingleLineLabelAndValue/CRMSingleLineLabelAndValue";

export const CRMQuoteRatesSingleViewConfiguration = (props: React.PropsWithChildren<{
    quoteRate: TQuoteRate1 & TQuoteRate2,
}>): JSX.Element => {
    const category =
        props.quoteRate.rate_type
            ? CaseLedgerCategory_displayString(ledgerTypeDictionary[props.quoteRate.rate_type].category)
            : "";

    return <SpacingColumn spacing={CRMSpacing.MEDIUM}>
        <CRMTitleSubSection>
            Configuration
        </CRMTitleSubSection>

        <SpacingColumn spacing={CRMSpacing.TINY}>
            {/* CATEGORY */}
            <CRMSingleLineLabelAndValue
                label="Category:"
                value={category}
            />

            {/* HAS VAT? */}
            <CRMSingleLineLabelAndValue
                label="Has VAT?"
                value={props.quoteRate.has_vat ? "Yes": "No"}
            />

            {/* FEE MULTIPLIED PER CLIENT? */}
            <CRMSingleLineLabelAndValue
                label="Fee multiplied per client?"
                value={props.quoteRate.is_multiplied_per_client ? "Yes": "No"}
            />

            {/* IS PUBLISHED? */}
            <CRMSingleLineLabelAndValue
                label="Is published?"
                value={props.quoteRate.is_published ? "Yes": "No"}
            />
        </SpacingColumn>
    </SpacingColumn>;
};
