import React from "react";
import { Padding } from "../../components/BuildingBlocks/Padding";
import { CRMCardOutside } from "../../components/CRMCardOutside/CRMCardOutside";
import { CRMSpacing } from "../../models/CRMSpacing";
import { TContainerStateProps } from "../../state/TContainerStateProps";
import { SpacingColumn } from "../../components/BuildingBlocks/SpacingColumn";
import { CRMButtonPrimary } from "../../components/CRMButtonPrimary/CRMButtonPrimary";
import { CRMNoticeBoxComponent } from "../../components/CRMNoticeBoxComponent/CRMNoticeBoxComponent";
import { MaxWidth } from "../../components/BuildingBlocks/MaxWidth";

export const CRMAdminPageKlyantContainer = (props: TContainerStateProps): JSX.Element => {
    const hasError = props.state.forms.homes_admin_page.data.output.klyant.hasError;

    const onAuthControlClick = () =>
        window.location.href = props.state.forms.homes_admin_page.data.output.klyant.authUrl;

    return (
        <CRMCardOutside borderRounding="right">
            <Padding spacing={CRMSpacing.LARGE}>
                <SpacingColumn spacing={CRMSpacing.LARGE}>
                    {/* AUTH ERRORS */}
                    {hasError &&
                        <MaxWidth width="fit-content">
                            <CRMNoticeBoxComponent>
                                The Klyant integration is not setup correctly. Click the button below to authorise access.
                            </CRMNoticeBoxComponent>
                        </MaxWidth>
                    }

                    {/* AUTH CONTROL */}
                    <div>
                        <CRMButtonPrimary
                            label="Authorise access"
                            onClick={onAuthControlClick}
                        />
                    </div>
                </SpacingColumn>
            </Padding>
        </CRMCardOutside>
    );
};
