import React, { useState } from "react";
import { CRMBlockHeader, TBlockHeaderModes, TBlockHeaderOption, TBlockHeaderOptionGroup } from "../CRMBlockHeader/CRMBlockHeader";
import { identity } from "fp-ts";
import { DefaultBlockWidthOptions, TBlockWidthOption, TBlockWidthOptionsDefinition } from "./CRMBlockSizes";

type TBlockProps = {
    mode: TBlockHeaderModes;
    startingWidth?: TBlockWidthOption; 
    selectedOption?: string;
    fixedOption?: TBlockHeaderOption;
    optionGroups: Array<TBlockHeaderOptionGroup>;
    onChange?: (name: string, option: TBlockHeaderOption) => void;
};

export const CRMBlock = (props: React.PropsWithChildren<TBlockProps>): JSX.Element => {
    
    const [blockWidth, setBlockWidth] = useState<TBlockWidthOption>(props.startingWidth || 600);

    return (
        <div 
            className={`crm-block-wrap`}
            style={{
                width: `${blockWidth}px`
            }}
        >
            <div className="crm-block-wrap__header">
                <CRMBlockHeader<TBlockWidthOption>
                    mode={props.mode}

                    fixedOption={props.fixedOption}
                    selectedOption={props.selectedOption}
                    onChange={props.onChange || identity.flatten}
                    optionGroups={props.optionGroups}
                    
                    selectedSize={blockWidth}
                    sizeOptions={DefaultBlockWidthOptions as TBlockWidthOptionsDefinition}
                    onChangeSize={(sizeOption) => setBlockWidth(sizeOption.value)}
                />
            </div>

            <div className="crm-block-wrap__body">
                {props.children}
            </div>
        </div>
    );
};