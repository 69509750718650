import React, { CSSProperties } from "react";

type TAnimationPopoutProps = {
    duration?: number;
    minWidth?: CSSProperties["minWidth"];
};

export const AnimationPopout = (props: React.PropsWithChildren<TAnimationPopoutProps>): JSX.Element => {

    return (
        <div 
            style={{ 
                animation: `bounce ${props.duration || 300}ms linear`,
                minWidth: props.minWidth,
            }}
        >
            {props.children}
        </div>
    );
};
