import React from "react";
import { SpacingColumn } from "../../BuildingBlocks/SpacingColumn";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { WeightSemiBold } from "../../WeightSemiBold/WeightSemiBold";
import { CRMColors } from "../../../models/CRMColors";
import { SpacingRow } from "../../BuildingBlocks/SpacingRow";
import { Padding } from "../../BuildingBlocks/Padding";
import { CRMThinColoredButton } from "../../Simple/CRMThinColoredButton/CRMThinColoredButton";
import { AnimationPopout } from "../../BuildingBlocks/AnimationPopout";
import { Relative } from "../../BuildingBlocks/Relative";
import { CRMTableContentPlaceholder } from "../../Simple/CRMTableContentPlaceholder/CRMTableContentPlaceholder";
import { CRMEmptyPlaceholder } from "../../Simple/CRMEmptyPlaceholder/CRMEmptyPlaceholder";
import { MinHeight } from "../../BuildingBlocks/MinHeight";
import { TProbateHelplineCallbackForm, TProbateHelplineCallbackTypeForm } from "../../../../../domain/codecs/form/ProbateHelplineCallbackForm";
import { CRMProbateHelplineCallbackNowRow } from "../CRMProbateHelplineCallbackNowRow/CRMProbateHelplineCallbackNowRow";

type TInboxProps = {
    form: TProbateHelplineCallbackTypeForm,
    onOpenCallback: (form: TProbateHelplineCallbackForm) => void,
    onLoadMore: () => void,
};

export const CRMProbateHelplineCallbacksNowTable = (props: React.PropsWithChildren<TInboxProps>): JSX.Element => {
    const isShowingLoadMore = props.form.children.counts.available_count > props.form.children.callbacks.length;

    const isEmpty = props.form.children.callbacks.length === 0;

    const isLoading =
        props.form.status === "loading" || props.form.status === "submitting";

    return (
        <Relative>
            <MinHeight height="720px">
                <SpacingColumn spacing={CRMSpacing.MEDIUM}>
                    {/* BODY */}
                    <SpacingColumn spacing={CRMSpacing.LARGE}>
                        {/* CALLBACKS */}
                        <SpacingColumn>
                            {/* ROWS */}
                            {!isLoading && !isEmpty && 
                                props.form.children.callbacks.map((form) => (
                                    <CRMProbateHelplineCallbackNowRow
                                        key={form.edited.id}
                                        form={form}
                                        onClick={() => props.onOpenCallback(form)}
                                    />
                                ))
                            }

                            {/* LOADING BAR */}
                            {isLoading && isEmpty && 
                                <CRMTableContentPlaceholder
                                    rowCount={props.form.children.callbacks.length}
                                />
                            }

                            {/* EMPTY */}
                            {!isLoading && isEmpty &&
                                <Padding spacing={CRMSpacing.LARGE}>
                                    <CRMEmptyPlaceholder
                                        iconSize="70px"
                                        textGap={CRMSpacing.TINY}
                                    >
                                        No callbacks to do.
                                    </CRMEmptyPlaceholder>
                                </Padding>
                            }
                        </SpacingColumn>

                        {/* LOAD MORE */}
                        {isShowingLoadMore &&
                            <AnimationPopout>
                                <Padding type="bottom" spacing={CRMSpacing.LARGE}>
                                    <SpacingRow justifyContent="center">
                                        <CRMThinColoredButton
                                            backgroundColor={CRMColors.NEUTRAL_2}
                                            fontColor={CRMColors.NEUTRAL_12}
                                            label={<WeightSemiBold>See more</WeightSemiBold>}
                                            onClick={props.onLoadMore}
                                            icon="down-arrow"
                                        />
                                    </SpacingRow>
                                </Padding>
                            </AnimationPopout>
                        }
                    </SpacingColumn>
                </SpacingColumn>
            </MinHeight>
        </Relative>
    );
};
