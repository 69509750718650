import React from "react";
import { Background } from "../../../BuildingBlocks/Background";
import { CRMColors } from "../../../../models/CRMColors";
import { FontQuicksand } from "../../../BuildingBlocks/FontQuicksand";
import { FrontColors } from "../../../../models/FrontColors";
import { FrontFontSizes } from "../../../../models/FrontFontSizes";
import { Text } from "../../../BuildingBlocks/Text";

export const FrontPill = (props: {
    label: String
}): JSX.Element =>
    <Background
        width="fit-content"
        color={CRMColors.PRIMARY_2}
        borderRadius="18px"
        padding="4px 15px 5px 15px"
    >
        <FontQuicksand>
            <Text
                color={FrontColors.NEUTRAL_18}
                size={FrontFontSizes.SMALL}
                lineHeight={1.5}
                weight={600}
            >
                {props.label.toUpperCase()}
            </Text>
        </FontQuicksand>
    </Background>;
