import { array } from "fp-ts";
import { pipe } from "fp-ts/lib/function";
import React from "react";
import { QuoteRateAttribute_displayString, QuoteRateClientsOwnership_displayString, QuoteRateClientsPropertyCount_displayString, QuoteRatePropertyResidentialCommercial_displayString, QuoteRateTaxGroup_displayString, QuoteRateTransactionType_displayString, TQuoteRate1, TQuoteRate2 } from "../../../../../domain/codecs/QuoteRate";
import { Tenure1_Tenure2 } from "../../../../../domain/codecs/Tenure";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { SpacingColumn } from "../../BuildingBlocks/SpacingColumn";
import { SpacingRow } from "../../BuildingBlocks/SpacingRow";
import { CRMTitleSubSection } from "../../CRMTitleSubSection/CRMTitleSubSection";
import { CRMPill } from "../../Simple/CRMPill/CRMPill";
import { CRMSingleLineLabelAndValue } from "../../Simple/CRMSingleLineLabelAndValue/CRMSingleLineLabelAndValue";
import { WeightBold } from "../../WeightBold/WeightBold";

export const CRMQuoteRatesSingleViewAppliesWhen = (props: React.PropsWithChildren<{
    quoteRate: TQuoteRate1 & TQuoteRate2,
}>): JSX.Element => {
    const hasAppliesWhenTenure = !!props.quoteRate.applies_when_tenure_is;

    const hasAppliesWhenAttributes = props.quoteRate.applies_when_any_of_attributes.length > 0;

    const hasAppliesWhenTransactionTypes = props.quoteRate.applies_when_any_of_transaction_types.length > 0;

    const hasAppliesWhenPropertyResidentialCommercial = !!props.quoteRate.applies_when_property_residential_commercial_is;

    const hasAppliesWhenClientsOwnership = !!props.quoteRate.applies_when_clients_ownership_is;

    const hasAppliesWhenClientsPropertyCount = !!props.quoteRate.applies_when_clients_property_count_is;

    const hasAppliesWhenTaxGroup = !!props.quoteRate.applies_when_tax_group_is;

    const hasAnyAppliesWhen =
        hasAppliesWhenTenure
        || hasAppliesWhenAttributes
        || hasAppliesWhenTransactionTypes
        || hasAppliesWhenPropertyResidentialCommercial
        || hasAppliesWhenClientsOwnership
        || hasAppliesWhenClientsPropertyCount
        || hasAppliesWhenTaxGroup;

    const appliesWhenTenure =
        props.quoteRate.applies_when_tenure_is
            ? Tenure1_Tenure2(props.quoteRate.applies_when_tenure_is)
            : "";

    const appliesWhenAttributes =
        pipe(
            props.quoteRate.applies_when_any_of_attributes,
            array.map(QuoteRateAttribute_displayString),
            array.map((value) => <CRMPill
                mode="single"
                colour="primary-12"
            >
                <WeightBold>
                    {value}
                </WeightBold>
            </CRMPill>),
            (a) => <SpacingRow spacing={CRMSpacing.TINY}>{a}</SpacingRow>,
        );

    const appliesWhenTransactionTypes =
        pipe(
            props.quoteRate.applies_when_any_of_transaction_types,
            array.map(QuoteRateTransactionType_displayString),
            array.map((value) => <CRMPill
                mode="single"
                colour="highlights-positive-green-10"
            >
                <WeightBold>
                    {value}
                </WeightBold>
            </CRMPill>),
            (a) => <SpacingRow spacing={CRMSpacing.TINY}>{a}</SpacingRow>,
        );

    const appliesWhenPropertyResidentialCommercial =
        props.quoteRate.applies_when_property_residential_commercial_is
            ? QuoteRatePropertyResidentialCommercial_displayString(props.quoteRate.applies_when_property_residential_commercial_is)
            : "";

    const appliesWhenClientsOwnership =
        props.quoteRate.applies_when_clients_ownership_is
            ? QuoteRateClientsOwnership_displayString(props.quoteRate.applies_when_clients_ownership_is)
            : "";

    const appliesWhenClientsPropertyCount =
        props.quoteRate.applies_when_clients_property_count_is
            ? QuoteRateClientsPropertyCount_displayString(props.quoteRate.applies_when_clients_property_count_is)
            : "";

    const appliesWhenTaxGroup =
        props.quoteRate.applies_when_tax_group_is
            ? QuoteRateTaxGroup_displayString(props.quoteRate.applies_when_tax_group_is)
            : "";

    return <SpacingColumn spacing={CRMSpacing.MEDIUM}>
        <CRMTitleSubSection>
            Applies When
        </CRMTitleSubSection>

        {/* WHEN THERE ARE APPLIES WHEN RULES */}
        {hasAnyAppliesWhen &&
            <SpacingColumn spacing={CRMSpacing.TINY}>
                {/* TENURE */}
                {hasAppliesWhenTenure &&
                    <CRMSingleLineLabelAndValue
                        label="Tenure is:"
                        value={appliesWhenTenure}
                    />
                }

                {/* TRANSACTION TYPE */}
                {hasAppliesWhenTransactionTypes &&
                    <CRMSingleLineLabelAndValue
                        label="Transaction type (any of):"
                        value={appliesWhenTransactionTypes}
                    />
                }

                {/* RESIDENTIAL/COMMERCIAL */}
                {hasAppliesWhenPropertyResidentialCommercial &&
                    <CRMSingleLineLabelAndValue
                        label="Residential/Commercial:"
                        value={appliesWhenPropertyResidentialCommercial}
                    />
                }

                {/* OWNERSHIP */}
                {hasAppliesWhenClientsOwnership &&
                    <CRMSingleLineLabelAndValue
                        label="Clients are:"
                        value={appliesWhenClientsOwnership}
                    />
                }

                {/* PROPERTY COUNT */}
                {hasAppliesWhenClientsPropertyCount &&
                    <CRMSingleLineLabelAndValue
                        label="Clients:"
                        value={appliesWhenClientsPropertyCount}
                    />
                }

                {/* ATTRIBUTES */}
                {hasAppliesWhenAttributes &&
                    <CRMSingleLineLabelAndValue
                        label="Attributes (any of):"
                        value={appliesWhenAttributes}
                    />
                }

                {/* TAX GROUP */}
                {hasAppliesWhenTaxGroup &&
                    <CRMSingleLineLabelAndValue
                        label="SDLT/LTT Tax group:"
                        value={appliesWhenTaxGroup}
                    />
                }
            </SpacingColumn>
        }

        {/* WHEN THERE ARE NO RULES */}
        {!hasAnyAppliesWhen &&
            <div>
                <CRMPill colour="neutral-10">
                    <WeightBold>
                        Any quote
                    </WeightBold>
                </CRMPill>
            </div>
        }
    </SpacingColumn>;
};
