import React from "react";

type TVimeoEmbedProps = {
    title: string;
    src: string;
};

export const FrontVimeoEmbed = (props: React.PropsWithChildren<TVimeoEmbedProps>): JSX.Element => {

    return (
        <div className={``}>
             <div style={{
                padding: "56.25% 0 0 0",
                position: "relative",
            }}>
                <iframe 
                    title={props.title}
                    src={props.src} 
                    frameBorder={0}
                    allow="autoplay; fullscreen; picture-in-picture" 
                    allowFullScreen 
                    style={{
                        position: "absolute",
                        top: 0, 
                        left:0, 
                        width: "100%", 
                        height:"100%"
                    }}
                ></iframe>
            </div>
            <script src="https://player.vimeo.com/api/player.js"></script>
        </div>
    );
};
