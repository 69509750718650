import React from "react";
import { TIcon } from "../CRMIcon/CRMIcon";
import { CRMIconPopout } from "../CRMIconPopout/CRMIconPopout";
import { TCRMMainColours } from "../../models/TCRMMainColours";
import { CRMPopoutOptions, ICRMPopoutOption } from "../Simple/CRMPopoutOptions/CRMPopoutOptions";

interface ICRMInlineOptionsComponent {
    options: Array<ICRMPopoutOption>;
    icon?: TIcon;
    iconColour?: TCRMMainColours;
    iconSize?: "small" | "tiny";
    popoutOpenDirection?: "left" | "right";
}

export const CRMInlineOptionsComponent = (props: ICRMInlineOptionsComponent): JSX.Element => (
    <CRMIconPopout
        popoutOpenDirection={ props.popoutOpenDirection || "right"}
        iconProps={{
            iconName: props.icon || "options",
            size: props.iconSize || "tiny",
            colour: props.iconColour || "neutral-ink",
        }}
    >
        <CRMPopoutOptions
            options={props.options}
        />
    </CRMIconPopout>
);
