import { THexColor } from "./StringLiterals";

export const FrontColors = {
    PROBATE_0: "#674D77" as THexColor,
    PROBATE_4: "#735585" as THexColor,
    PRIMARY_2: "#2D3D64" as THexColor,
    PRIMARY_6: "#049DCC" as THexColor,
    PRIMARY_10: "#35B8BE" as THexColor,
    PRIMARY_16: "#B6FBFF" as THexColor,
    PRIMARY_22: "#E4F1FF" as THexColor,  
    NEUTRAL_INK: "#0E1E47" as THexColor,
    NEUTRAL_INK_LIGHT: "#525B73" as THexColor,
    NEUTRAL_0: "#000000" as THexColor,
    NEUTRAL_2: "#191B22" as THexColor, 
    NEUTRAL_6: "#546285" as THexColor,
    NEUTRAL_8: "#758CA5" as THexColor,
    NEUTRAL_10: "#9B9EB8" as THexColor,
    NEUTRAL_10_2: "#E4E5ED" as THexColor,
    NEUTRAL_11: "#D7D7D7" as THexColor,
    NEUTRAL_11_2: "#DDDDE1" as THexColor,
    NEUTRAL_12: "#E0F5F8" as THexColor,
    NEUTRAL_13: "#F2F2F2" as THexColor,
    NEUTRAL_14: "#F5FBFC" as THexColor,
    NEUTRAL_16: "#F8FCFD" as THexColor,
    NEUTRAL_18: "#FFFFFF" as THexColor,
    NEUTRAL_20: "#FAFAFA" as THexColor,
    PERTINENT_CORAL_2: "#DF3B22" as THexColor,
    PERTINENT_CORAL_4: "#FF8946" as THexColor,
    PERTINENT_CORAL_10: "#F29865" as THexColor,
    PERTINENT_CORAL_21: "#FFE9DD" as THexColor,
    PERTINENT_CORAL_22: "#FFEEE5" as THexColor,
    ERROR_FIREBRICK_4: "#AA0000" as THexColor,
    ERROR_FIREBRICK_10: "#F96E6E" as THexColor,
    ERROR_FIREBRICK_22: "#FBD7D7" as THexColor,
    ERROR_FIREBRICK_26: "#FFECEC" as THexColor,
    POSITIVE_APPLE_0: "#126C44" as THexColor,
    POSITIVE_APPLE_2: "#139159" as THexColor,
    POSITIVE_APPLE_4: "#5EB64D" as THexColor,
    POSITIVE_APPLE_10: "#90DB82" as THexColor,
    POSITIVE_APPLE_22: "#DDF2D9" as THexColor,
    POSITIVE_APPLE_24: "#EEF9EC" as THexColor,
};