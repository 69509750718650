import React from "react";
import * as util from "../../../../shared/src/util";
import { CRMTextHighlight } from "../CRMTextHighlight/CRMTextHighlight";
import { CRMIcon } from "../CRMIcon/CRMIcon";

type TCRMEnquiryBuyerReadySectionTitleComponentProps = {
    state: "ok" | "not_complete";
    children: string;
};

export const CRMEnquiryBuyerReadySectionTitleComponent = (props: TCRMEnquiryBuyerReadySectionTitleComponentProps): JSX.Element =>
    props.state === "ok" ? <div className="crm-buyer-ready-section-title-component">
        <div className="crm-buyer-ready-section-title-component__label">
            {props.children}
        </div>
    </div>
    : props.state === "not_complete" ?
        <CRMTextHighlight colour="highlights-pertinent-yellow-8" useInlineFlex={true}>
                <div className="crm-buyer-ready-section-title-component__icon">
                    <CRMIcon
                        iconName="pencil"
                        colour="neutral-ink"
                    />
                </div>
                <div className="crm-buyer-ready-section-title-component__label">
                    {props.children}
                </div>
        </CRMTextHighlight>
    : util.requireExhaustive(props.state);
