import React from "react";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { SpacingRow } from "../../BuildingBlocks/SpacingRow";
import { CRMIcon } from "../../CRMIcon/CRMIcon";
import { WeightMedium } from "../../WeightMedium/WeightMedium";
import { CRMPill } from "../CRMPill/CRMPill";


type TEmailPillProps = {
    email: string;
    onRemove: (email: string) => void;
};

export const CRMEmailPill = (props: React.PropsWithChildren<TEmailPillProps>): JSX.Element => {

    return (
        <CRMPill colour="primary-13">
            <SpacingRow 
                spacing={CRMSpacing.SMALL}
                justifyContent="space-between"
            >
                <WeightMedium>
                    {props.email}
                </WeightMedium>

                <CRMIcon
                    iconName="close"
                    colour="neutral-ink"
                    size="10px"
                    onClick={() => props.onRemove(props.email)}
                />
            </SpacingRow>
        </CRMPill>
    );
};
