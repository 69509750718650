import React from "react";
import { FrontRadio, TRadioValueType } from "./../FrontRadio/FrontRadio";

interface IFrontRadioListProps<A extends TRadioValueType, B extends A> {
    options: Array<IFrontRadioOption<A>>;
    value: B | null;
    onChange: (value: A) => void;
}

interface IFrontRadioOption<A extends TRadioValueType> {
    value: A;
    label: JSX.Element | string;
}

export const FrontRadioList = <A extends TRadioValueType, B extends A>(props: React.PropsWithChildren<IFrontRadioListProps<A, B>>): JSX.Element => {

    return (
        <div className={`front-radio-list`}>
            {props.options.map((option, index) => (
                <div
                    key={index}
                    className="front-radio-list__radio"
                    onClick={() => {
                        if (option.value !== props.value) {
                            props.onChange(option.value);
                        }
                    }}
                >
                    <FrontRadio
                        label={option.label}
                        value={option.value}
                        isSelected={option.value === props.value}
                        onChange={() => {
                            
                        }}
                    />
                </div>
            ))}
        </div>
    );
};
