import * as rxjs from "rxjs";
import * as rxjsOperators from "rxjs/operators";
import * as util from "../../util";
import * as FirstPartyFetchResponse from "../../../../domain/models/FirstPartyFetchResponse";
import * as JsonResponse1 from "../../../../domain/models/JsonResponse1";
import { either, option } from "fp-ts";
import * as TForm from "./../../models/TForm";
import * as request from "./../request";
import { TState, TUnpackArray, TSubmitMethod, TModelFile, TValidator, IExtensionCallback } from "./lensBaseTypes";
import { ofFormValidatedAndSetToSubmitting } from "./ofFormValidatedAndSetToSubmitting";
import { handleFormSubmitErrors } from "./handleFormSubmitErrors";
import { resolveFormResponse } from "./resolveFormResponse";
import { findFirst } from "./findFirst";
import * as TFormStatus from "../../models/TFormStatus";
import { TGetState } from "../../state/TGetState";
import { TSetState } from "../../state/TSetState";
import { pipe } from "fp-ts/lib/function";
import { TActionPayload } from "../../state/actions/TAction";
import { TValidationError } from "../../../../shared/src/validation/Error";

interface ILensSetForm<A extends string, P, V, S> {
    // 5 level lens path guard
    <
        K1 extends keyof S,
        K2 extends keyof S[K1],
        K3 extends keyof S[K1][K2],
        K4 extends keyof S[K1][K2][K3],
        K5 extends keyof S[K1][K2][K3][K4]
    >
    (
        path: [K1, K2, K3, K4, K5],
        urlCallback: (
            value: TUnpackArray<S[K1][K2][K3][K4][K5]>,
            data: P,
        ) => string,
        method: TSubmitMethod,
        validator: TValidator,
        responseModel: TModelFile<V>,
        extensionCallback?: IExtensionCallback<A, P, FirstPartyFetchResponse.T<JsonResponse1.T<V, {}>>>
    ): (
        obs$: rxjs.Observable<TActionPayload<A, P>>,
        getState: TGetState,
        setState: TSetState
    ) => rxjs.Observable<unknown>;
    // 4 level lens path guard
    <
        K1 extends keyof S,
        K2 extends keyof S[K1],
        K3 extends keyof S[K1][K2],
        K4 extends keyof S[K1][K2][K3]
    >
    (
        path: [K1, K2, K3, K4],
        urlCallback: (
            value: TUnpackArray<S[K1][K2][K3][K4]>,
            data: P,
        ) => string,
        method: TSubmitMethod,
        validator: TValidator,
        responseModel: TModelFile<V>,
        extensionCallback?: IExtensionCallback<A, P, FirstPartyFetchResponse.T<JsonResponse1.T<V, {}>>>
    ): (
        obs$: rxjs.Observable<TActionPayload<A, P>>,
        getState: TGetState,
        setState: TSetState,
    ) => rxjs.Observable<unknown>;
    // 3 level lens path guard
    <
        K1 extends keyof S,
        K2 extends keyof S[K1],
        K3 extends keyof S[K1][K2]
    >
    (
        path: [K1, K2, K3],
        urlCallback: (
            value: TUnpackArray<S[K1][K2][K3]>,
            data: P,
        ) => string,
        method: TSubmitMethod,
        validator: TValidator,
        responseModel: TModelFile<V>,
        extensionCallback?: IExtensionCallback<A, P, FirstPartyFetchResponse.T<JsonResponse1.T<V, {}>>>
    ): (
        obs$: rxjs.Observable<TActionPayload<A, P>>,
        getState: TGetState,
        setState: TSetState,
    ) => rxjs.Observable<unknown>;
    // 2 level lens path guard
    <
        K1 extends keyof S,
        K2 extends keyof S[K1]
    >
    (
        path: [K1, K2],
        urlCallback: (
            value: TUnpackArray<S[K1][K2]>,
            data: P,
        ) => string,
        method: TSubmitMethod,
        validator: TValidator,
        responseModel: TModelFile<V>,
        extensionCallback?: IExtensionCallback<A, P, FirstPartyFetchResponse.T<JsonResponse1.T<V, {}>>>
    ): (
        obs$: rxjs.Observable<TActionPayload<A, P>>,
        getState: TGetState,
        setState: TSetState,
    ) => rxjs.Observable<unknown>;
    // 1 level lens path guard
    <K1 extends keyof S>
    (
        path: [K1],
        urlCallback: (
            value: TUnpackArray<S[K1]>,
            data: P,
        ) => string,
        method: TSubmitMethod,
        validator: TValidator,
        responseModel: TModelFile<V>,
        extensionCallback?: IExtensionCallback<A, P, FirstPartyFetchResponse.T<JsonResponse1.T<V, {}>>>
    ): (
        obs$: rxjs.Observable<TActionPayload<A, P>>,
        getState: TGetState,
        setState: TSetState
    ) => rxjs.Observable<unknown>;
}

export const submitFormWhereChanged =
    <A extends string, P, V extends Record<string, unknown>, E, U extends Record<string, unknown>>(): ILensSetForm<A, P, V, TState> =>
        (
            // There is no way to create paramater overloads here so we set to any
            lensPath: any, // eslint-disable-line
            urlCallback: any, // eslint-disable-line
            requestMethod: TSubmitMethod,
            validator: TValidator,
            responseModel: TModelFile<V>,
            extensionCallback?: IExtensionCallback<A, P, FirstPartyFetchResponse.T<JsonResponse1.T<V, {}>>>
        ) =>
            (
                obs$: rxjs.Observable<TActionPayload<A, P>>,
                getState: TGetState,
                setState: TSetState
            ) =>
                obs$.pipe(
                    rxjsOperators.mergeMap((action) =>
                         pipe(
                            findFirst(getState())(lensPath),
                            option.filter((form) => TFormStatus.hasUnsavedChanged((form as TForm.TFormV2<unknown, unknown, {}>).status)),
                            option.fold(
                                rxjs.of,
                                () =>
                                    ofFormValidatedAndSetToSubmitting<TForm.TFormV2<V, E, U>>(getState, setState)(
                                        lensPath,
                                        validator,
                                    ).pipe(
                                        rxjsOperators.mergeMap((validationEither) =>
                                            either.fold<
                                                TValidationError,
                                                TForm.TFormV2<V extends Record<string, unknown> ? V : never, E extends Record<string, unknown> ? E : never, U extends Record<string, unknown> ? U : never>,
                                                rxjs.Observable<FirstPartyFetchResponse.T<JsonResponse1.T<V, {}>>>
                                            >(
                                                handleFormSubmitErrors(setState)(
                                                    lensPath,
                                                ),
                                                (form) => request.payloadFetch<V>(
                                                    urlCallback(form, action.payload),
                                                    requestMethod,
                                                    form.edit,
                                                    responseModel.newDefault(),
                                                    responseModel.codec,
                                                )(),
                                            )(validationEither),
                                        ),
                                        rxjsOperators.tap<FirstPartyFetchResponse.T<JsonResponse1.T<V, {}>>>((response) => util.defaultCRMRequestErrorHandler(response)),
                                        rxjsOperators.tap<FirstPartyFetchResponse.T<JsonResponse1.T<V, {}>>>(resolveFormResponse(setState)(
                                            lensPath,
                                        )),
                                        rxjsOperators.mergeMap((response) => {
                                            if (typeof extensionCallback === "function") {
                                                extensionCallback(action, response, setState, getState);
                                            }
                                            return rxjs.EMPTY;
                                        })
                                    )
                            )
                        )
                    ),
                );
