import React from "react";
import { ICRMDropdownColourOption } from "../../CRMDropdownColourComponent/CRMDropdownColourComponent";
import { CRMIcon } from "../../CRMIcon/CRMIcon";
import { Uppercase } from "../../Uppercase/Uppercase";
import { WeightSemiBold } from "../../WeightSemiBold/WeightSemiBold";

export const CRMDropdownColourFace = <A extends string>(props: {
    ref: React.RefObject<HTMLDivElement>,
    isOpen: boolean,
    selectedOption: ICRMDropdownColourOption<A> | null,
    placeholder?: string,
    setIsOpen: (value: boolean) => void,
    isAnythingSelected: () => boolean,
    onKeyboardNavigation: (e: React.KeyboardEvent<HTMLDivElement>, onChange?: (val: A) => void) => void,
    onChange: (value: A) => void
}): JSX.Element => {

    return (
        <div
            ref={props.ref}
            className="crm-dropdown-colour-face"
        >
            <div 
                className={`
                    crm-dropdown-colour-face__selected-option-container 
                    crm-dropdown-colour-face__selected-option-container--highlights-${props.selectedOption?.highlightColour ?? "neutral-paper"}
                `}
                tabIndex={0}
                onClick={() => props.setIsOpen(!props.isOpen)}
                onKeyDown={(event) => props.onKeyboardNavigation(event, props.onChange)}
            >
                <div className="crm-dropdown-colour-face__option">
                    {props.isAnythingSelected() && props.selectedOption?.iconName &&
                        <div className="crm-dropdown-colour-face__icon-container">
                            <CRMIcon
                                iconName={props.selectedOption.iconName}
                                colour={props.selectedOption.fontColour ?? "neutral-ink"}
                                size="small"
                            />
                        </div>
                    }
                    <div className={`
                        crm-dropdown-colour-face__face-text
                        crm-dropdown-colour-face__face-text--${props.isAnythingSelected() ? 'selected' : 'not-selected'}
                        crm-dropdown-colour-face__face-text--font-${props.selectedOption?.fontColour ?? "neutral-0"} 
                    `}>
                        <WeightSemiBold>
                            <Uppercase>
                                {props.isAnythingSelected() && props.selectedOption?.label}
                                {!props.isAnythingSelected() && (props.placeholder || "Select Value")}
                            </Uppercase>
                        </WeightSemiBold>
                    </div>
                </div>

                <div className="crm-dropdown-colour-face__icon-container">
                    <CRMIcon
                        iconName={props.isOpen ? "up-arrow" : "down-arrow"}
                        colour={props.selectedOption?.fontColour || "neutral-0"}
                        size="tiny"
                    />
                </div>
            </div>
        </div>
    );
};
