import { array } from "fp-ts";
import { pipe } from "fp-ts/lib/function";
import React from "react";
import { ledgerTypeDictionary } from "../../../../../domain/codecs/CaseLedger";
import { QuoteRateAttribute_displayString, QuoteRateClientsOwnership_displayString, QuoteRateClientsPropertyCount_displayString, QuoteRatePropertyResidentialCommercial_displayString, QuoteRateTaxGroup_displayString, QuoteRateTransactionType_displayString, TQuoteRate1 } from "../../../../../domain/codecs/QuoteRate";
import { CRMColors } from "../../../models/CRMColors";
import { CRMFontSizes } from "../../../models/CRMFontSizes";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { Background } from "../../BuildingBlocks/Background";
import { FontSignikaNegative } from "../../BuildingBlocks/FontSignikaNegative";
import { FontSize } from "../../BuildingBlocks/FontSize";
import { SpacingColumn } from "../../BuildingBlocks/SpacingColumn";
import { SpacingRow } from "../../BuildingBlocks/SpacingRow";
import { TextColor } from "../../BuildingBlocks/TextColor";
import { CRMParagraph } from "../../Simple/CRMParagraph/CRMParagraph";
import { Uppercase } from "../../Uppercase/Uppercase";
import { WeightBold } from "../../WeightBold/WeightBold";
import { WeightSemiBold } from "../../WeightSemiBold/WeightSemiBold";

export const CRMQuoteRateCardDetailOverlapping = (props: React.PropsWithChildren<{
    form: TQuoteRate1,
}>): JSX.Element => {
    const feeName =
        props.form.rate_type
            ? ledgerTypeDictionary[props.form.rate_type].displayName
            : "Type not set";

    const allAttributeNames =
        pipe(
            [
                ...(props.form.applies_when_tenure_is ? [props.form.applies_when_tenure_is] : []),
                ...pipe(
                    props.form.applies_when_any_of_attributes,
                    array.map(QuoteRateAttribute_displayString)
                ),
                ...pipe(
                    props.form.applies_when_any_of_transaction_types,
                    array.map(QuoteRateTransactionType_displayString),
                ),
                ...(props.form.applies_when_property_residential_commercial_is ? [QuoteRatePropertyResidentialCommercial_displayString(props.form.applies_when_property_residential_commercial_is)] : []),
                ...(props.form.applies_when_clients_ownership_is ? [QuoteRateClientsOwnership_displayString(props.form.applies_when_clients_ownership_is)] : []),
                ...(props.form.applies_when_clients_property_count_is ? [QuoteRateClientsPropertyCount_displayString(props.form.applies_when_clients_property_count_is)] : []),
                ...(props.form.applies_when_tax_group_is ? [QuoteRateTaxGroup_displayString(props.form.applies_when_tax_group_is)] : []),
            ],
            (a) => a.length === 0
                ? ["Applies to all quotes"]
                : a,
        );

    return (
        <SpacingColumn spacing={CRMSpacing.TINY}>
            {/* FEE NAME */}
            <CRMParagraph>
                <WeightBold>
                    {feeName}
                </WeightBold>
            </CRMParagraph>

            {/* ATTRIBUTES */}
            <SpacingRow
                spacing={CRMSpacing.TINY}
                alignItems="center"
            >
                {pipe(
                    allAttributeNames,
                    array.map((attribute) =>
                        <FontSignikaNegative lineHeight="1px">
                            <WeightSemiBold>
                                <Uppercase>
                                    <TextColor color={CRMColors.NEUTRAL_0}>
                                        <FontSize size={CRMFontSizes.SMALL}>
                                            {attribute}
                                        </FontSize>
                                    </TextColor>
                                </Uppercase>
                            </WeightSemiBold>
                        </FontSignikaNegative>
                    ),
                    // Put a small dot between attribute names
                    array.intersperse(<Background
                        borderRadius="100%"
                        width="4px"
                        height="4px"
                        color={CRMColors.NEUTRAL_0}
                    />)
                )}
            </SpacingRow>
        </SpacingColumn>
    );
};
