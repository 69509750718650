import React from "react";
import { CRMColors } from "../../../models/CRMColors";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { SpacingRow } from "../../BuildingBlocks/SpacingRow";
import { TextColor } from "../../BuildingBlocks/TextColor";
import { CRMIcon } from "../../CRMIcon/CRMIcon";
import { CRMParagraph } from "../../Simple/CRMParagraph/CRMParagraph";
import { CursorPointer } from "../../CursorPointer/CursorPointer";
import { WeightRegular } from "../../WeightRegular/WeightRegular";

export const AddNewNumber = (props: {
    mode: "face" | "list",
    onClick: () => void,
}): JSX.Element =>
    <CursorPointer onClick={props.onClick}>
        <SpacingRow
            spacing={CRMSpacing.TINY}
            justifyContent="start"
            alignItems="center"
        >
            {/* PLUS ICON */}
            <CRMIcon
                iconName="add"
                size="x-small"
                colour="neutral-ink"
            />

            {/* TEXT */}
            <CRMParagraph>
                <WeightRegular>
                    <TextColor color={CRMColors.NEUTRAL_INK}>
                        Add new number
                    </TextColor>
                </WeightRegular>
            </CRMParagraph>
        </SpacingRow>
    </CursorPointer>;
