import React from "react";
import { FrontColors } from "../../../../models/FrontColors";
import { FrontSpacing } from "../../../../models/FrontSpacing";
import { Padding } from "../../../BuildingBlocks/Padding";
import { SpacingRow } from "../../../BuildingBlocks/SpacingRow";
import { TextColor } from "../../../BuildingBlocks/TextColor";
import { WeightMedium } from "../../../WeightMedium/WeightMedium";
import { FrontIcon } from "../FrontIcon/FrontIcon";
import { FrontParagraphRegular } from "../FrontParagraphRegular/FrontParagraphRegular";

type TOnboardingTabButtonProps = {
    label: string;
    onClick: () => void;
};

export const FrontOnboardingTabButton = (props: React.PropsWithChildren<TOnboardingTabButtonProps>): JSX.Element => {

    return (
        <div
            className={`front-onboarding-tab-button`}
            onClick={props.onClick}
        >
            <SpacingRow 
                justifyContent="space-between"
                spacing={FrontSpacing.SMALL_3}
            >
                {/* LABEL TEXT */}
                <FrontParagraphRegular>
                    <WeightMedium>
                        <TextColor color={FrontColors.NEUTRAL_INK}>
                            {props.label}
                        </TextColor>
                    </WeightMedium>
                </FrontParagraphRegular>
                
                {/* ICON */}
                <Padding type="top" spacing="4px">
                    <FrontIcon
                        iconName="chevron-right"
                        colour="neutral-ink-light"
                        size="small"
                    />
                </Padding>
            </SpacingRow>
        </div>
    );
};