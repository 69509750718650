import React from "react";
import * as luxon from "luxon";
import { CRMSpacingRow } from "../../../CRMSpacingRow/CRMSpacingRow";
import { getToCcOrBccText, getToOrFromText, TLegalEmailDecryptedWithBody } from "../../../../../../domain/codecs/LegalEmail";
import { Font } from "../../../Font/Font";
import { CRMFontSizes } from "../../../../models/CRMFontSizes";
import { CRMIcon } from "../../../CRMIcon/CRMIcon";
import { WeightBold } from "../../../WeightBold/WeightBold";
import { CRMParagraph } from "../../../Simple/CRMParagraph/CRMParagraph";
import { WeightMedium } from "../../../WeightMedium/WeightMedium";
import { WeightRegular } from "../../../WeightRegular/WeightRegular";
import * as util from "../../../../../../shared/src/util";
import { CRMColors } from "../../../../models/CRMColors";
import { TextColour } from "../../../TextColour/TextColour";
import { CRMLink } from "../../../CRMLink/CRMLink";
import { SpacingColumn } from "../../../BuildingBlocks/SpacingColumn";
import { CRMSpacing } from "../../../../models/CRMSpacing";
import { CRMTextHighlight } from "../../../CRMTextHighlight/CRMTextHighlight";

type TCRMCaseEmailSummaryProps = {
    email: TLegalEmailDecryptedWithBody;
    onClickViewEmailIcon?: () => void;
    displayAllRecipients?: boolean;
};

export const CRMCaseEnquiryEmailSummary = (props: TCRMCaseEmailSummaryProps): JSX.Element => (
    <>
        {/* DATE */}
        <CRMSpacingRow>
            <Font size={CRMFontSizes.SMALL} family="roboto-mono">
                <WeightBold>
                    <TextColour colour={CRMColors.NEUTRAL_INK}>
                        {luxon.DateTime.fromISO(props.email.sent_at).setZone("Europe/London").toFormat("dd/MM/yyyy")}
                        &nbsp;({luxon.DateTime.fromISO(props.email.sent_at).setZone("Europe/London").toRelative()})
                    </TextColour>
                </WeightBold>
            </Font>
            {props.onClickViewEmailIcon &&
                <CRMIcon
                    iconName="eye"
                    size="small"
                    colour="neutral-ink"
                    onClick={props.onClickViewEmailIcon}
                />
            }
        </CRMSpacingRow>
        {/* SUBJECT */}
        <CRMParagraph>
            <WeightBold>Subject:</WeightBold>&nbsp;
            <WeightMedium>{props.email.subject}</WeightMedium>
        </CRMParagraph>
        {/* FROM AND TO */}
        {props.displayAllRecipients &&
            <>
                <CRMParagraph>
                    <WeightBold>From:</WeightBold>&nbsp;
                    <WeightRegular>{props.email.from_address}</WeightRegular>
                </CRMParagraph>
                <CRMParagraph>
                    <WeightBold>To:</WeightBold>&nbsp;
                    <WeightRegular>{getToCcOrBccText(props.email.to_addresses)}</WeightRegular>
                </CRMParagraph>

                {/* If there are CC'd participants */}
                {props.email.cc_addresses.length > 0 &&
                    <CRMParagraph>
                        <WeightBold>Cc:</WeightBold>&nbsp;
                        <WeightRegular>{getToCcOrBccText(props.email.cc_addresses)}</WeightRegular>
                    </CRMParagraph>
                }
                {/* If there are BCC'd participants */}
                {props.email.bcc_addresses.length > 0 &&
                    <CRMParagraph>
                        <WeightBold>Bcc:</WeightBold>&nbsp;
                        <WeightRegular>{getToCcOrBccText(props.email.bcc_addresses)}</WeightRegular>
                    </CRMParagraph>
                }
                {/* If there are attachments */}
                {(props.email.attachments.length > 0) &&
                    <CRMParagraph>
                        <WeightBold>Attachments:</WeightBold>&nbsp;
                        <SpacingColumn spacing={CRMSpacing.TINY}>
                            {props.email.attachments.map((attachment) =>
                                attachment.virus_detected
                                ? <CRMTextHighlight colour="highlights-instructional-brick-4">{attachment.name}</CRMTextHighlight>
                                : <CRMLink target="_blank" href={`${env.REACT_APP_API_URL}/web/email-attachment-download?fileToken=${encodeURIComponent(attachment.download_token)}`} linkStyle="normal">{attachment.name}</CRMLink>
                            )}
                        </SpacingColumn>
                    </CRMParagraph>
                }
            </>
        }
        {/* FROM OR TO */}
        {!props.displayAllRecipients &&
            <CRMParagraph>
                <WeightBold>
                    {props.email.direction === "outbound" ? "Sent to: "
                        : props.email.direction === "inbound" ? "Received from: "
                        : util.requireExhaustive(props.email.direction)
                    }
                </WeightBold>
                <WeightRegular>{getToOrFromText(props.email)}</WeightRegular>
            </CRMParagraph>
        }
    </>
);
