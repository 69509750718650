import React from "react";
import { TUseEmailInputProps, useEmailInput } from "../../../hooks/useEmailInput";
import { CRMFontSizes } from "../../../models/CRMFontSizes";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { CRMColors } from "../../../models/CRMColors";
import { FontQuicksand } from "../../BuildingBlocks/FontQuicksand";
import { FontSize } from "../../BuildingBlocks/FontSize";
import { InlineFlex } from "../../BuildingBlocks/InlineFlex";
import { SpacingColumn } from "../../BuildingBlocks/SpacingColumn";
import { SpacingRow } from "../../BuildingBlocks/SpacingRow";
import { CRMDropdownGroup, CRMDropdownGroupOptionText, TDropdownGroupOptionProps } from "../../Complex/CRMDropdownGroup/CRMDropdownGroup";
import { CRMFlatInputGeneral } from "../../CRMFlatInputGeneral/CRMFlatInputGeneral";
import { CRMParagraph } from "../../Simple/CRMParagraph/CRMParagraph";
import UnstyledInputGeneralComponent from "../../UnstyledInputGeneralComponent/UnstyledInputGeneralComponent";
import { WeightBold } from "../../WeightBold/WeightBold";
import { WeightMedium } from "../../WeightMedium/WeightMedium";
import { CRMDropdownOptionBox } from "../CRMDropdownOptionBox/CRMDropdownOptionBox";
import { CRMEmailPill } from "../CRMEmailPill/CRMEmailPill";
import { CRMEmptyPlaceholder } from "../CRMEmptyPlaceholder/CRMEmptyPlaceholder";
import { CRMInfoBox } from "../CRMInfoBox/CRMInfoBox";
import { CRMKeyboardButtonIcon } from "../CRMKeyboardButtonIcon/CRMKeyboardButtonIcon";
import { CRMLoadingBar } from "../CRMLoadingBar/CRMLoadingBar";
import { Background } from "../../BuildingBlocks/Background";
import { CRMFuzzySortSearchHighlight } from "../CRMFuzzySortSearchHighlight/CRMFuzzySortSearchHighlight";

type TEmailInputProps = 
    TUseEmailInputProps & 
    {
        prefixValue?: string;
        placeholder?: string;
        disabled?: boolean;
    }
;

export type TEmailInputOption = {
    name: string;
    address: string;
    group: string;
}

export const CRMEmailInput = (props: React.PropsWithChildren<TEmailInputProps>): JSX.Element => {

    const {
        ref,
        inputRef,
        isOpen,
        searchText,
        setSearchText,
        getSearchResultsByGroup,
        open,
        close,
        onPressSearchEnter,
        onToggleEmailSelection,
        onChangeUserForm,
        onSubmitUserForm,
        deriveStatus,
        onEscape,
        onBackspace
    } = useEmailInput(props);

    return (
        <div
            ref={ref} 
            className={`
                crm-email-input
                crm-email-input--${props.disabled ? "disabled" : "enabled"}
            `}
            onClick={() => inputRef.current?.focus()}
        >
            {/* FACE */}
            <div className="crm-email-input__face">

                {(!!props.prefixValue && props.selected.length > 0) &&
                    <InlineFlex>
                        <Background padding={`0px ${CRMSpacing.TINY} 0px 0px`}>
                            <FontQuicksand>
                                <FontSize size={CRMFontSizes.SMALL}>
                                    <WeightBold>
                                        {props.prefixValue}
                                    </WeightBold>
                                </FontSize>
                            </FontQuicksand>
                        </Background>
                    </InlineFlex>
                }

                {props.selected.map((email, key) => (
                    <CRMEmailPill
                        key={key}
                        email={email}
                        onRemove={onToggleEmailSelection}
                    />
                ))}

                <UnstyledInputGeneralComponent
                    inputRef={inputRef}
                    className={`
                        crm-email-input__input
                        crm-email-input__input--${props.disabled ? "disabled" : "enabled"}
                    `}
                    inputType="text"
                    value={searchText}
                    placeholder={props.placeholder || "Enter address or name"}
                    onChange={setSearchText}
                    onFocus={open}
                    onPressEnterKey={onPressSearchEnter}
                    onPressEscape={onEscape}
                    onPressBackspace={onBackspace}
                    onPressTabKey={close}
                    disabled={props.disabled}
                    displayError={false}
                />
            </div>

            {/* BODY */}
            {isOpen &&
                <CRMDropdownOptionBox>
                    
                    {props.newEmailUser?.form.status === "submitting" &&
                        <CRMLoadingBar duration={1500} />
                    }

                    <Background padding={CRMSpacing.MEDIUM}>

                        {deriveStatus() === "no-options" &&
                             <CRMEmptyPlaceholder
                                iconSize="50px" 
                                textGap={"0px"}
                                fontSize={CRMFontSizes.SMALL}
                            >
                                Type to enter a custom email.
                            </CRMEmptyPlaceholder>
                        }

                        {deriveStatus() === "no-match" &&
                            <CRMEmptyPlaceholder
                                iconSize="50px" 
                                textGap={"0px"}
                                fontSize={CRMFontSizes.SMALL}
                            >
                                No match
                            </CRMEmptyPlaceholder>
                        }

                        {deriveStatus() === "new-email-string" &&
                            <CRMParagraph>
                                You are entering a email not in the address book. Press <CRMKeyboardButtonIcon character="enter" /> to add it.
                            </CRMParagraph>
                        }

                        {deriveStatus() === "new-email-form" && !!props.newEmailUser &&
                            <SpacingColumn spacing={CRMSpacing.MEDIUM}>
                                <SpacingRow 
                                    spacing={CRMSpacing.MEDIUM}
                                    childSize="1fr 1fr"
                                >
                                    <CRMFlatInputGeneral
                                        placeholder="First name"
                                        value={props.newEmailUser.form.edited.first_name}
                                        onChange={onChangeUserForm("first_name")}
                                        onPressEnterKey={onSubmitUserForm}
                                        disabled={props.newEmailUser.form.status === "submitting"}
                                    />
                                    <CRMFlatInputGeneral
                                        placeholder="Last name"
                                        value={props.newEmailUser.form?.edited.last_name}
                                        onChange={onChangeUserForm("last_name")}
                                        onPressEnterKey={onSubmitUserForm}
                                        disabled={props.newEmailUser.form.status === "submitting"}
                                    />
                                </SpacingRow>

                                {props.newEmailUser.form.status === "failure" &&
                                    <CRMInfoBox color={CRMColors.HIGHLIGHTS_INSTRUCTIONAL_BRICK_12}>
                                        <SpacingColumn spacing={CRMSpacing.TINY}>
                                            <CRMParagraph>
                                                <WeightBold>
                                                    Sorry, something went wrong!
                                                </WeightBold>
                                            </CRMParagraph>
                                            <CRMParagraph>
                                                <WeightMedium>
                                                    We could not save the contact. Please try again, and if the issue presists contact development.
                                                </WeightMedium>
                                            </CRMParagraph>
                                        </SpacingColumn>
                                    </CRMInfoBox>
                                }

                                <CRMParagraph>
                                    You are adding a <WeightBold>new contact</WeightBold> to the case. 
                                    Please enter the first and last name, and then press <CRMKeyboardButtonIcon character="enter" /> when done. 
                                </CRMParagraph>
                            </SpacingColumn>
                        }

                        {(deriveStatus() === "matching" || deriveStatus() === "untouched") &&
                            <SpacingColumn spacing={CRMSpacing.LARGE}>
                                {getSearchResultsByGroup().map((group, key) => (
                                    <CRMDropdownGroup<TEmailInputOption>
                                        key={key}
                                        searchText={searchText}
                                        group={group}
                                        onToggleOptionSelection={onToggleEmailSelection}
                                        OptionElement={CRMEmailInputDropdownGroupOption}
                                    />
                                ))}
                            </SpacingColumn>
                        }
                    </Background>
                </CRMDropdownOptionBox>
            }
        </div>
    );
};

const CRMEmailInputDropdownGroupOption = (props: TDropdownGroupOptionProps<TEmailInputOption>): JSX.Element => {
    return (
        <div
            className="crm-dropdown-group__option"
            onClick={() => props.onClick(props.option.obj.value)} 
        >
            <SpacingColumn spacing="4px">
                <CRMDropdownGroupOptionText>
                    <CRMFuzzySortSearchHighlight
                        text={props.option.obj.meta ? props.option.obj.meta["name"] as string : ""}
                        searchText={props.searchText}
                    />
                </CRMDropdownGroupOptionText>
                <CRMDropdownGroupOptionText>
                    <CRMFuzzySortSearchHighlight
                        text={`[${props.option.obj.meta?.address}]` || ""}
                        searchText={props.searchText}
                    />
                </CRMDropdownGroupOptionText>
            </SpacingColumn>
        </div>
    )
}
