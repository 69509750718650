import React from "react";
import { SpacingRow } from "../../BuildingBlocks/SpacingRow";
import { TCRMSpacing } from "../../../models/CRMSpacing";
import { CRMCardOutside } from "../../CRMCardOutside/CRMCardOutside";

export interface ITableHeaderRowProps {
    spacingRowChildSize: string;
    spacing: TCRMSpacing;
    headers: Array<string>;
    shadow?: boolean;
    borderRounding?: "left" | "right" | "none";
};

export const CRMTableHeaderRow = (props: React.PropsWithChildren<ITableHeaderRowProps>): JSX.Element => {

    return (
        <CRMCardOutside
            shadow={props.shadow === false ? false : true}
            borderRounding={props.borderRounding || "none"}
        >
            <div className={`crm-table-header-row`}>
                <SpacingRow
                    spacing={props.spacing}
                    childSize={props.spacingRowChildSize}
                >
                    {props.headers.map((header, index) =>
                        <div key={index}>
                            {header}
                        </div>
                    )}
                </SpacingRow>
            </div>
        </CRMCardOutside>
    );
};
