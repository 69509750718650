import React from "react";
import { FrontSpacing } from "../../../models/FrontSpacing";
import { SpacingColumn } from "../../BuildingBlocks/SpacingColumn";
import { TLandRegistryOfficialCopyWithSummaryResponseSuccess } from "../../../../../domain/codecs/LandRegistry";
import { FontQuicksand } from "../../BuildingBlocks/FontQuicksand";
import { FontSize } from "../../BuildingBlocks/FontSize";
import { FrontFontSizes } from "../../../models/FrontFontSizes";
import { FrontPill } from "../../Front/Simple/FrontPill/FrontPill";
import { WeightBold } from "../../WeightBold/WeightBold";
import { FPOrderedList } from "../Simple/FPOrderedList/FPOrderedList";
import { SpacingRow } from "../../BuildingBlocks/SpacingRow";
import { FPPrintReport } from "../Simple/FPPrintReport/FPPrintReport";
import { FPDownloadRegister } from "../Simple/FPDownloadRegister/FPDownloadRegister";
import { propertyReportDisplayData } from "../../../../../domain/propertyReportDisplayData";
import { FrontColors } from "../../../models/FrontColors";
import { Background } from "../../BuildingBlocks/Background";
import { Text } from "../../BuildingBlocks/Text";
import { WeightMedium } from "../../WeightMedium/WeightMedium";
import { Flex } from "../../BuildingBlocks/Flex";
import { TQuotes } from "../../../../../domain/codecs/Quote";
import { TTransactionType1 } from "../../../../../domain/codecs/TransactionType";
import { WeightSemiBold } from "../../WeightSemiBold/WeightSemiBold";
import { pipe } from "fp-ts/lib/function";
import { array, option } from "fp-ts";
import { fromNumber } from "../../../../../domain/models/TCurrency";

const SectionSubheading = (props: React.PropsWithChildren): JSX.Element =>
    <FontQuicksand>
        <FontSize
            size={FrontFontSizes.SMALL}
            lineHeight={1.4}
        >
            <WeightBold>
                {props.children}
            </WeightBold>
        </FontSize>
    </FontQuicksand>;

export const FPPropertyReportResultsSuccess = (props: {
    deceasedName: string,
    data: TLandRegistryOfficialCopyWithSummaryResponseSuccess,
    titleRegisterUrl: string,
    transactionType: TTransactionType1,
    quotes: TQuotes,
}): JSX.Element => {
    const {
        hasProprietorsSection,
        proprietorsList,
        propertyList,
        hasChargesSection,
        chargesCount,
        chargesList,
        hasIssues,
        issuesCount,
        issuesList
    } = propertyReportDisplayData(props.deceasedName, props.data);

    const saleQuoteTotalPence =
        pipe(
            props.quotes.quotes,
            array.findFirst((quote) => quote.transaction_type === "sale"),
            option.map(({total_pence}) => total_pence),
            option.getOrElse(() => 0),
        );

    const transferQuoteTotalPence =
        pipe(
            props.quotes.quotes,
            array.findFirst((quote) => quote.transaction_type === "transfer"),
            option.map(({total_pence}) => total_pence),
            option.getOrElse(() => 0),
        );

    const ctaHeader: JSX.Element =
        props.transactionType === "sale" ? <div>
            <div>
                <WeightMedium>
                    If you are <WeightSemiBold>selling</WeightSemiBold> this home
                </WeightMedium>
            </div>
            <div>
                <WeightBold>
                    we can help.
                </WeightBold>
            </div>
        </div>
        : props.transactionType === "transfer" ? <div>
            <div>
                <WeightMedium>
                    If you are <WeightSemiBold>transferring</WeightSemiBold> this home
                </WeightMedium>
            </div>
            <div>
                <WeightBold>
                    we can help.
                </WeightBold>
            </div>
        </div>
        : <div>
            <div>
                <WeightMedium>
                    Whatever is decided
                </WeightMedium>
            </div>
            <div>
                <WeightBold>
                    we can help.
                </WeightBold>
            </div>
        </div>;

    const ctaBody: JSX.Element =
        props.transactionType === "sale" ? <>
            <div>
                Our preliminary checks have revealed some points about the property's title <WeightBold>that needs our attention.</WeightBold>
            </div>

            <div>
                Opening a conveyancing case early allows us to address these issues promptly, <WeightBold>preparing the property efficiently for sale.</WeightBold>
            </div>

            <div>
                Either let us market the property & provide you with conveyancing for a fee of <WeightBold>only 1.5%</WeightBold> (plus VAT) of the price we sell the property for.
            </div>

            <div>
                Or we can just provide with conveyancing for <WeightSemiBold>{fromNumber(saleQuoteTotalPence / 100, 2)}</WeightSemiBold> (inc VAT).
            </div>

            <div>
                To accept this quote or to discuss other options <WeightSemiBold>call us on</WeightSemiBold> <WeightBold>0808 196 5200</WeightBold> or <WeightSemiBold>email</WeightSemiBold> <WeightBold>hello@sailhomes.co.uk</WeightBold>
            </div>
        </>
        : props.transactionType === "transfer" ? <>
            <div>
                Our preliminary checks have revealed some points about the property's title <WeightBold>that needs our attention.</WeightBold>&nbsp;
                We can address these as well as <WeightBold>transfer ownership</WeightBold> of the property for <WeightSemiBold>{fromNumber(transferQuoteTotalPence / 100, 2)}</WeightSemiBold> (inc VAT).
            </div>

            <div>
                To accept this quote or to discuss other options <WeightSemiBold>call us on</WeightSemiBold> <WeightBold>0808 196 7007</WeightBold> or <WeightSemiBold>email</WeightSemiBold> <WeightBold>hello@saillegal.co.uk</WeightBold>
            </div>
        </>
        : <>
            <div>
                Our preliminary checks have revealed some points about the property's title <WeightBold>that needs our attention.</WeightBold>
            </div>

            <div>
                Opening a conveyancing case early allows us to address these issues promptly, <WeightBold>preparing the property efficiently for sale or transfer of ownership.</WeightBold>
            </div>

            <div>
                If the property is to be <WeightBold>sold</WeightBold> we can market & provide you with conveyancing for a fee of <WeightSemiBold>only 1.5%</WeightSemiBold> (plus VAT) of the price we sell the property or <WeightSemiBold>{fromNumber(saleQuoteTotalPence / 100, 2)}</WeightSemiBold> (inc VAT) if you only want conveyancing.
            </div>

            <div>
                Or if the property is to be <WeightBold>transferred</WeightBold> to new owners we can do this for <WeightSemiBold>{fromNumber(transferQuoteTotalPence / 100, 2)}</WeightSemiBold> (inc VAT).
            </div>

            <div>
                To accept this quote or to discuss other options <WeightSemiBold>call us on</WeightSemiBold> <WeightBold>0808 196 5200</WeightBold> or <WeightSemiBold>email</WeightSemiBold> <WeightBold>hello@sailhomes.co.uk</WeightBold>
            </div>
        </>;

    return <SpacingColumn
        spacing={FrontSpacing.LARGE_2}
        justifyContent="center"
    >
        {/* PROPERTY REPORT RESULTS */}
        <Flex justifyContent="center">
            <Background
                color={FrontColors.NEUTRAL_18}
                borderRadius="10px"
                width="700px"
                padding={FrontSpacing.LARGE_3}
            >
                <SpacingColumn spacing={FrontSpacing.LARGE_3}>
                    {/* PROPRIETORS */}
                    {hasProprietorsSection &&
                        <SpacingColumn spacing={FrontSpacing.MEDIUM_1}>
                            <SpacingColumn spacing="25px">
                                <FrontPill label="Proprietors" />

                                {/* OWNED BY */}
                                <SectionSubheading>
                                    Details of who owns the property and any information relating to their ownership.
                                </SectionSubheading>
                            </SpacingColumn>

                            {/* LIST PROPRIETOR */}
                            <FPOrderedList list={proprietorsList} />
                        </SpacingColumn>
                    }

                    {/* THE PROPERTY */}
                    <SpacingColumn spacing={FrontSpacing.MEDIUM_1}>
                        <SpacingColumn spacing="25px">
                            <FrontPill label="The Property" />

                            {props.data.titleNumber &&
                                <SectionSubheading>
                                    The property is registered under title number {props.data.titleNumber}.
                                </SectionSubheading>
                            }
                        </SpacingColumn>

                        {/* LIST PROPERTY */}
                        <FPOrderedList
                            list={propertyList}                    
                        />
                    </SpacingColumn>

                    {/* CHARGES */}
                    {hasChargesSection &&
                        <SpacingColumn spacing={FrontSpacing.MEDIUM_1}>
                            <SpacingColumn spacing="25px">
                                <FrontPill label={`Mortgages, Loans, ect. (${chargesCount})`} />

                                <SectionSubheading>
                                    Mortgages and other financial interests secured against the property.
                                </SectionSubheading>
                            </SpacingColumn>

                            {/* LIST CHARGES */}
                            <FPOrderedList list={chargesList} />
                        </SpacingColumn>
                    }

                    {/* ISSUES */}
                    {hasIssues &&
                        <SpacingColumn spacing={FrontSpacing.MEDIUM_1}>
                            <SpacingColumn spacing="25px">
                                <FrontPill label={`Issues (${issuesCount})`} />

                                <SectionSubheading>
                                    In addition to anything mentioned above, the following must be dealt with as part of a sale or transfer of ownership.
                                </SectionSubheading>
                            </SpacingColumn>

                            {/* LIST ISSUES */}
                            <FPOrderedList
                                list={issuesList}                    
                            />
                        </SpacingColumn>
                    }

                    {/* PRINT/DOWNLOAD REGISTER */}
                    <SpacingRow
                        justifyContent="start"
                        spacing={FrontSpacing.SMALL_3}
                        childSize="1fr 1fr"
                    >
                        {/* PRINT */}
                        <FPPrintReport />

                        {/* DOWNLOAD REGISTER */}
                        {props.titleRegisterUrl &&
                            <FPDownloadRegister
                                url={props.titleRegisterUrl}
                            />
                        }
                    </SpacingRow>
                </SpacingColumn>
            </Background>
        </Flex>

        {/* CTA */}
            <SpacingColumn
                spacing={FrontSpacing.LARGE_1}
                justifyContent="center"
            >
                <Flex justifyContent="center">
                    <FontQuicksand>
                        <Text
                            width="425px"
                            align="center"
                            size={FrontFontSizes.LARGE}
                            color={FrontColors.NEUTRAL_20}
                            lineHeight={1.2}
                        >
                            {ctaHeader}
                        </Text>
                    </FontQuicksand>
                </Flex>

                <Flex justifyContent="center">
                    <Background
                        color={FrontColors.NEUTRAL_18}
                        borderRadius="10px"
                        width="590px"
                        padding={`${FrontSpacing.MEDIUM_2} ${FrontSpacing.LARGE_3}`}
                    >
                        <Text
                            size={FrontFontSizes.MED}
                            align="center"
                            lineHeight={1.5}
                        >
                            <SpacingColumn spacing={FrontSpacing.MEDIUM_1}>
                                {ctaBody}
                            </SpacingColumn>
                        </Text>
                    </Background>
                </Flex>
        </SpacingColumn>
    </SpacingColumn>;
};
