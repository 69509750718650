import { array, semigroup, record } from "fp-ts";
import { pipe } from "fp-ts/lib/pipeable";
import { TTuple } from "./tuple";

export const getQueryParams = (location: Location): Record<string, string> => 
    pipe(
        locationSearchToQueryParamTuples(location),
        record.fromFoldable(semigroup.getLastSemigroup<string>(), array.array)
    )
;

const locationSearchToQueryParamTuples = (location: Location): Array<TTuple<string, string>> =>
    location.search
        .replace("?", "")
        .split("&")
        .map((string) => string.split("="))
        .filter((paramTuple) => paramTuple.length === 2) as Array<TTuple<string, string>>
;

export const location = {
    getQueryParams,
}
    