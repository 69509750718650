import React, { Fragment } from "react";
import { THexColor } from "../../models/StringLiterals";

type TEmailTemplateBodyProps = {
    backgroundColor?: THexColor;
};

export const EmailTemplateBody = (props: React.PropsWithChildren<TEmailTemplateBodyProps>): JSX.Element => {

    const bodyWidth = 420;

    return (
        <Fragment>
            {/* LAYOUT */}
            {/* @ts-ignore <center> is a HTML4 element ts complains since it only decets html5 elements */}
            <center className="sail-email-template-body"> 
                <table 
                    className="sail-email-template-body__inner" 
                    style={{ 
                        width: bodyWidth, 
                        minWidth: bodyWidth 
                    }}
                >
                    <tbody>
                        <tr>
                            <td>
                                {props.children}
                            </td>
                        </tr>
                    </tbody>
                </table>
            {/* @ts-ignore */}
            </center>
        </Fragment>
    );
};
