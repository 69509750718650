import { pipe } from "fp-ts/lib/pipeable";
import { DateTime } from "luxon";

export const getDateDifferenceInDays = (dateA: string, mode?: "round" | "raw") => 
    (dateB: string): number => {
        let dateTimeA = DateTime.fromISO(dateA);
        let dateTimeB = DateTime.fromISO(dateB);

        return pipe(
            dateTimeB.diff(dateTimeA, ["days"]).toObject(),
            (difference) => difference.days || 0,
            (rawDifference) => mode === "round" ? Math.round(rawDifference) : rawDifference,
        )
    }
;