import { array, identity, option } from "fp-ts";
import React from "react";
import { CRMKeyboardButtonIcon } from "../CRMKeyboardButtonIcon/CRMKeyboardButtonIcon";
import { pipe } from "fp-ts/lib/pipeable";
import { CRMIcon } from "../../CRMIcon/CRMIcon";
import { SpacingRow } from "../../BuildingBlocks/SpacingRow";

export type TKeyboardShortcutDescriptionProps = {
    shortcut: Array<string>;
    mode?: "plus" | "plain"
};

export const CRMKeyboardShortcut = (props: TKeyboardShortcutDescriptionProps) => {

    const Mode = props.mode || "plus";
    const Steps = pipe(
        props.shortcut,
        array.mapWithIndex((index, character) => <CRMKeyboardButtonIcon key={index} character={character} />),
    );
    const StepsWithPlus = pipe(
        Steps,
        array.reduceRightWithIndex(
            [] as Array<JSX.Element>,
            (index, item, sum) => sum
                .concat(item)
                .concat(<CRMIcon key={`+${index}`} iconName="add" colour="neutral-ink" size="12px" />)
        ),
        array.reverse,
        array.tail,
        option.fold(
            () => [],
            identity.flatten
        )
    );
    
    return (
        <SpacingRow spacing={"3px"}>
            {Mode === "plus" ? StepsWithPlus : Steps}
        </SpacingRow>
    );
}
