import React from "react";
import { Background } from "../../BuildingBlocks/Background";
import { SpacingRow } from "../../BuildingBlocks/SpacingRow";
import { CRMIcon, TIcon } from "../../CRMIcon/CRMIcon";
import { THexColor } from "../../../models/StringLiterals";
import { AvatarSize } from "../CRMUserAvatar/CRMUserAvatar";
import { requireExhaustive } from "../../../../../shared/src/util";
import { CRMColors } from "../../../models/CRMColors";

type TRoadblockUserIconProps = {
    icon: TIcon;
    iconColor: THexColor;
    backgroundColor: THexColor;
    size?: keyof typeof AvatarSize;
    hasBorder?: boolean,
};

export const CRMIconAvatar = (props: React.PropsWithChildren<TRoadblockUserIconProps>): JSX.Element => {
    const size = props.size || "regular";

    const backgroundSize = AvatarSize[size];

    const iconSize =
        size === "regular" ? "28px"
        : size === "small" ? "24px"
        : size === "tiny" ? "20px"
        : requireExhaustive(size);

    const hasBorder = props.hasBorder || false;

    const boxShadowDefinition =
        hasBorder
            ? `inset 0px 0px 0px 1px ${CRMColors.NEUTRAL_0}`
            : undefined;

    return (
        <Background
            width={backgroundSize}
            height={backgroundSize}
            borderRadius="8px"
            color={props.backgroundColor}
            boxShadowDefinition={boxShadowDefinition}
        >
            <SpacingRow
                height={backgroundSize}
                alignItems="center"
                justifyContent="center"
            >
                <CRMIcon
                    size={iconSize}
                    iconName={props.icon}
                    colour={"hex"}
                    hexColour={props.iconColor}
                />
            </SpacingRow>
        </Background>
    );
};
