import React, { CSSProperties } from "react";
import { TPercentageSize, TPixelSize } from "../../../models/StringLiterals";

type TTidyPopoutProps = {
    height?: TPixelSize | TPercentageSize | "auto";
    width: TPixelSize | TPercentageSize;
    maxHeight?: TPixelSize | TPercentageSize | string;
    overflowY?: CSSProperties["overflowY"],
};

export const CRMTidyPopout = (props: React.PropsWithChildren<TTidyPopoutProps>): JSX.Element => {
    const overflowY = props.overflowY || "auto";

    return (
        <div 
            className={`crm-tidy-popout`}
            style={{
                height: props.height || "auto",
                width: props.width,
                maxHeight: props.maxHeight || props.height,
                overflowY: overflowY,
            }}
        >
            {props.children}
        </div>
    );
};
