import * as rxjs from "rxjs";
import * as rxjsOperators from "rxjs/operators";
import { TActionObservableWithPayload } from "../../state/applyActions";
import { TState, TUnpackArray } from "./lensBaseTypes";
import { reduceDataToStateUpdate } from "./reduceDataToStateUpdate";
import { setWhere } from "./setWhere";
import {TSetState as TSetState} from "../../state/TSetState";
import {TGetState as TGetState} from "../../state/TGetState";
import { TPayloadActionType } from "../../state/actions/TAction";

interface ISetValueWhere<A extends TPayloadActionType, T, S, R> {
    // 5 level lens path guard
    <
        K1 extends keyof S,
        K2 extends keyof S[K1],
        K3 extends keyof S[K1][K2],
        K4 extends keyof S[K1][K2][K3],
        K5 extends keyof S[K1][K2][K3][K4]
    >
    (
        path: [K1, K2, K3, K4, K5],
        whereCallback: (
            value: TUnpackArray<S[K1][K2][K3][K4][K5]>,
            data: T,
        ) => boolean,
        updateFormCallback: (
            value: TUnpackArray<S[K1][K2][K3][K4][K5]>,
            data: T,
        ) => TUnpackArray<S[K1][K2][K3][K4][K5]>,
    ): (
        obs$: TActionObservableWithPayload<A, T>,
        getState: TGetState,
        setState: TSetState
    ) => R;
    // 4 level lens path guard
    <
        K1 extends keyof S,
        K2 extends keyof S[K1],
        K3 extends keyof S[K1][K2],
        K4 extends keyof S[K1][K2][K3]
    >
    (
        path: [K1, K2, K3, K4],
        whereCallback: (
            value: TUnpackArray<S[K1][K2][K3][K4]>,
            data: T,
        ) => boolean,
        updateFormCallback: (
            value: TUnpackArray<S[K1][K2][K3][K4]>,
            data: T,
        ) => TUnpackArray<S[K1][K2][K3][K4]>,
    ): (
        obs$: TActionObservableWithPayload<A, T>,
        getState: TGetState,
        setState: TSetState
    ) => R;
    // 3 level lens path guard
    <
        K1 extends keyof S,
        K2 extends keyof S[K1],
        K3 extends keyof S[K1][K2]
    >
    (
        path: [K1, K2, K3],
        whereCallback: (
            value: TUnpackArray<S[K1][K2][K3]>,
            data: T,
        ) => boolean,
        updateFormCallback: (
            value: TUnpackArray<S[K1][K2][K3]>,
            data: T,
        ) => TUnpackArray<S[K1][K2][K3]>,
    ): (
        obs$: TActionObservableWithPayload<A, T>,
        getState: TGetState,
        setState: TSetState
    ) => R;
    // 2 level lens path guard
    <
        K1 extends keyof S,
        K2 extends keyof S[K1],
    >
    (
        path: [K1, K2],
        whereCallback: (
            value: TUnpackArray<S[K1][K2]>,
            data: T,
        ) => boolean,
        updateFormCallback: (
            value: TUnpackArray<S[K1][K2]>,
            data: T,
        ) => TUnpackArray<S[K1][K2]>,
    ): (
        obs$: TActionObservableWithPayload<A, T>,
        getState: TGetState,
        setState: TSetState
    ) => R;
    // 1 level lens path guard
    <
        K1 extends keyof S
    >
    (
        path: [K1],
        whereCallback: (
            value: TUnpackArray<S[K1]>,
            data: T,
        ) => boolean,
        updateFormCallback: (
            value: TUnpackArray<S[K1]>,
            data: T,
        ) => TUnpackArray<S[K1]>,
    ): (
        obs$: TActionObservableWithPayload<A, T>,
        getState: TGetState,
        setState: TSetState
    ) => R;
}

export const setValueWhere =
    <A extends TPayloadActionType, T>(): ISetValueWhere<A, T, TState, rxjs.Observable<unknown>> =>
        // There is no way to create paramater overloads here so we set to any
        (
            lens: any, // eslint-disable-line
            whereCallback: ( value: any, data: T) => boolean, // eslint-disable-line
            updateFormCallback: (
                value: any, // eslint-disable-line
                data: T,
            ) => any, // eslint-disable-line
        ) =>
            (
                obs$: TActionObservableWithPayload<A, T>,
                getState: TGetState,
                setState: TSetState
            ) =>
                obs$.pipe(
                    rxjsOperators.tap((action) => {
                        reduceDataToStateUpdate<T>(setState)(
                            setWhere<T>()(
                                lens,
                                whereCallback,
                                (value) => updateFormCallback(value, action.payload as any) as never // eslint-disable-line
                            ),
                        )(action.payload as any); // eslint-disable-line
                    })
                );

export const setValueWhereSubscribed =
    <A extends TPayloadActionType, T>(): ISetValueWhere<A, T, TState, void> =>
        // There is no way to create paramater overloads here so we set to any
        (
            lens: any, // eslint-disable-line
            whereCallback: ( value: any, data: T) => boolean, // eslint-disable-line
            updateFormCallback: (
                value: any, // eslint-disable-line
                data: T,
            ) => any, // eslint-disable-line
        ) =>
            (
                obs$: TActionObservableWithPayload<A, T>,
                getState: TGetState,
                setState: TSetState
            ) =>
                 /* eslint-disable */
                // @ts-ignore
                setValueWhere<A, T>()(lens, whereCallback, updateFormCallback)(obs$, getState, setState)
                    .subscribe();
                /* eslint-enable */
