import React from "react";
import { CRMIcon, TIcon } from "../CRMIcon/CRMIcon";
import { array } from "fp-ts";
import { WeightBold } from "../WeightBold/WeightBold";
import { Uppercase } from "../Uppercase/Uppercase";
import { TCRMMainColours } from "../../models/TCRMMainColours";
import { IOption } from "./../../hooks/UseDropdown";
import { useDropdown } from "../../hooks/UseDropdown";
import { pipe } from "fp-ts/lib/function";
import { CRMDropdownColourFace } from "../Simple/CRMDropdownColourFace/CRMDropdownColourFace";

interface ICRMDropdownColourComponent<A extends string, B extends A> {
    value: B;
    options: Array<ICRMDropdownColourOption<A>>;
    placeholder?: string;
    onChange: (value: A) => void;
}

export interface ICRMDropdownColourOption<A extends string | null> extends IOption<A> {
    iconName?: TIcon;
    highlightColour?: TCRMMainColours;
    fontColour?: TCRMMainColours;
}

export const CRMDropdownColourComponent = <A extends string, B extends A>(props: ICRMDropdownColourComponent<A, B>): JSX.Element => {
    const {
        ref,
        optionsRef,
        isOpen,
        setIsOpen,
        getValue,
        isAnythingSelected,
        onKeyboardNavigation,
    } = useDropdown<A, ICRMDropdownColourOption<A>>(props.value, props.options);

    return (
        <div
            ref={ref}
            className="crm-dropdown-colour"
        >
            {/* FACE */}
            <CRMDropdownColourFace
                ref={ref}
                isOpen={isOpen}
                selectedOption={getValue(props.value)}
                placeholder={props.placeholder}
                setIsOpen={setIsOpen}
                isAnythingSelected={isAnythingSelected}
                onKeyboardNavigation={onKeyboardNavigation}
                onChange={props.onChange}
            />

            {/* OPTIONS */}
            <div className={`
                crm-dropdown-colour__options-expand-container
                crm-dropdown-colour__options-expand-container${isOpen ? "--visible" : "--hidden"}`
            }>
                { pipe(
                    props.options,
                    array.mapWithIndex((i, option) =>
                        <div
                            key={i}
                            ref={optionsRef[i]}
                            tabIndex={isOpen ? 0 : -1}
                            className="crm-dropdown-colour__option crm-dropdown-colour__option--not-selected"
                            onClick={() => {
                                props.onChange(option.value);
                                setIsOpen(false);
                            }}
                            onKeyDown={(event) => onKeyboardNavigation(event, props.onChange)}
                        >
                            {option.iconName &&
                                <div className="crm-dropdown-colour__icon-container">
                                    <CRMIcon
                                        iconName={option.iconName}
                                        colour="neutral-ink"
                                        size="small"
                                    />
                                </div>
                            }
                            <div className="crm-dropdown-colour__option-text">
                                <WeightBold>
                                    <Uppercase>{option.label}</Uppercase>
                                </WeightBold>
                            </div>
                        </div>
                    )
                )}
            </div>
        </div>
    );
};
