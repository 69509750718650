import { array } from "fp-ts";
import { contramap } from "fp-ts/lib/Ord";
import { Ord } from "fp-ts/lib/number";
import { pipe } from "fp-ts/lib/pipeable";
import React, { useState } from "react";
import { TLegalEmailAttachment1 } from "../../../../../domain/codecs/LegalEmail";
import { CRMEmailAttachment, hasVirus, isRelatedToCase, isSmallImage } from "../../Simple/CRMEmailAttachment/CRMEmailAttachment";
import { InlineFlex } from "../../BuildingBlocks/InlineFlex";
import { CursorPointer } from "../../CursorPointer/CursorPointer";
import { SpacingRow } from "../../BuildingBlocks/SpacingRow";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { CRMIcon } from "../../CRMIcon/CRMIcon";
import { CRMParagraph } from "../../Simple/CRMParagraph/CRMParagraph";
import { Inline } from "../../BuildingBlocks/Inline";

type TEmailSingleViewBodyAttachmentsProps = {
    attachments: Array<TLegalEmailAttachment1>
};

export const CRMEmailSingleViewBodyAttachments = (props: React.PropsWithChildren<TEmailSingleViewBodyAttachmentsProps>): JSX.Element => {

    const [isShowingHiddenAttachments, setIsShowingHiddenAttachments] = useState<boolean>(false);

    const attachmentOrder = (attachment: TLegalEmailAttachment1): number =>
        !hasVirus(attachment) && !isRelatedToCase(attachment) && !isSmallImage(attachment) ? 0
        : hasVirus(attachment) ? 1
        : isRelatedToCase(attachment) ? 2
        : 3;

    const [alwaysVisibleAttachments, hiddenAttachments] =
        pipe(
            props.attachments,
            array.sortBy([contramap(attachmentOrder)(Ord)]),
            array.partition((attachment) =>
                !hasVirus(attachment)
                && !isRelatedToCase(attachment)
                && isSmallImage(attachment)
            ),
            ({left, right}) => [left, right],
        );

    const hasHiddenAttachments = (): boolean =>
        hiddenAttachments.length > 0;

    return (
        <Inline>
            {/* ALWAYS VISIBLE ATTACHMENTS */}
            {alwaysVisibleAttachments.map((attachment, index) => (
                <CRMEmailAttachment
                    key={index}
                    attachment={attachment}
                />
            ))}

            {/* HIDDEN ATTACHMENTS */}
            {isShowingHiddenAttachments && hiddenAttachments.map((attachment, index) => (
                <CRMEmailAttachment
                    key={index}
                    attachment={attachment}
                />
            ))}

            {/* TOGGLE HIDDEN ATTACHMENTS BUTTON */}
            {hasHiddenAttachments() && 
                <InlineFlex>
                    <CursorPointer
                        onClick={() => setIsShowingHiddenAttachments(!isShowingHiddenAttachments)}
                    >
                        <SpacingRow
                            spacing={CRMSpacing.TINY}
                            justifyContent="start"
                            alignItems="center"
                            isInline={true}
                        >
                            <CRMIcon
                                iconName={
                                    isShowingHiddenAttachments
                                        ? "checkbox-with-strikethrough"
                                        : "add-box"
                                }
                                colour="neutral-ink"
                                size="tiny"
                            />

                            <CRMParagraph>
                                {!isShowingHiddenAttachments && 
                                    <>Show {hiddenAttachments.length} small images</>
                                }
                                
                                {isShowingHiddenAttachments && 
                                    <>Show less</>
                                }
                            </CRMParagraph>
                        </SpacingRow>
                    </CursorPointer>
                </InlineFlex>
            }
        </Inline>
    );
};
