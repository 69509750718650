import React from "react";
import * as Listing3 from "../../../../domain/models/Listing3";
import * as DisplayAddress from "../../../../domain/models/DisplayAddress";
import * as TCurrency from "../../../../domain/models/TCurrency";
import * as ListingStatus1 from "../../../../domain/models/ListingStatus1";
import * as ListingReasonForSale1 from "../../../../domain/models/ListingReasonForSale1";
 import { pipe } from "fp-ts/lib/function";
import { CRMParagraph } from "../Simple/CRMParagraph/CRMParagraph";
import { CRMColumns } from "../CRMColumns/CRMColumns";
import { CRMSpacer } from "../CRMSpacer/CRMSpacer";

class CRMListingKeyInfoMiniComponent extends React.Component<{
    listing: Listing3.T;
}> {
    public render (): JSX.Element {
        return (
            <CRMColumns
                padding="medium"
                columns={[
                    {
                        flex: 0,
                        content: <div className="crm-mini-listing-key-info-component__thumbnail-container"
                            style={{
                                backgroundImage: this.props.listing.images.length ? `url(${this.props.listing.images[0].url}) ` : undefined,
                            }}
                        />,
                    },
                    {
                        flex: 1,
                        content: <div>
                            <CRMParagraph>
                                <strong>
                                    { pipe(
                                        this.props.listing,
                                        DisplayAddress.fromListing3,
                                        DisplayAddress.toString,
                                    )}
                                </strong>
                            </CRMParagraph>
                            <CRMSpacer size="medium" />
                            <CRMParagraph>
                                {this.props.listing.price
                                    ? TCurrency.fromNumber(this.props.listing.price)
                                    : "Price not set"}
                            </CRMParagraph>
                            <CRMParagraph>
                                {ListingStatus1.fromValueToDisplayName(this.props.listing.status)}
                            </CRMParagraph>
                            <CRMParagraph>
                                {`Reason for sale: ${ListingReasonForSale1.fromValueToDisplayName(this.props.listing.reason_for_sale)}`}
                            </CRMParagraph>
                        </div>,
                    },
                ]}
            />
        );
    }
}

export default CRMListingKeyInfoMiniComponent;
