import React, { useState } from "react";
import { useOpenClose } from "../../hooks/UseOpenClose";
import { CRMColors, TCRMColourValue } from "../../models/CRMColors";
import { AnimationPopout } from "../BuildingBlocks/AnimationPopout";
import { BackgroundColour } from "../BuildingBlocks/BackgroundColour";
import { CRMIcon } from "../CRMIcon/CRMIcon";
import { CRMPadding } from "../Simple/CRMPadding/CRMPadding";
import { CRMSpacingRow } from "../CRMSpacingRow/CRMSpacingRow";
import { CursorPointer } from "../CursorPointer/CursorPointer";

type TButtonCardProps = {
    label: JSX.Element;
    children: JSX.Element;
    backgroundColour?: TCRMColourValue;
};

export const CRMButtonCard = (props: React.PropsWithChildren<TButtonCardProps>): JSX.Element => {
    const {
        ref,
        isOpen,
        setIsOpen,
    } = useOpenClose();

    return (
        <div className="crm-button-card">
            <div className="crm-button-card__button">
                <div className="crm-button-card__button-inner">
                    <BackgroundColour colour={props.backgroundColour || CRMColors.NEUTRAL_PAPER}>
                        <CursorPointer onClick={() => setIsOpen(!isOpen)}>
                            <CRMPadding size="tiny">
                                <CRMSpacingRow spacing="tiny" alignItems="center">
                                    <div>{props.label}</div>
                                    <CRMIcon
                                        iconName={isOpen ? "up-arrow" : "down-arrow"}
                                        size="small"
                                        colour="neutral-ink"
                                    />
                                </CRMSpacingRow>
                            </CRMPadding>
                        </CursorPointer>
                    </BackgroundColour>
                </div>
            </div>
            {isOpen && <div className="crm-button-card__card" ref={ref}>
                <AnimationPopout>
                    <BackgroundColour colour={CRMColors.NEUTRAL_PAPER}>
                        {props.children}
                    </BackgroundColour>
                </AnimationPopout>
            </div>}
        </div>
    );
};
