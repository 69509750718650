import React from "react";
import { array } from "fp-ts";
import { pipe } from "fp-ts/lib/function";
import { array as extArray } from "../../../../shared/src/utilsByDomain/array";
import { SpacingColumn } from "../../components/BuildingBlocks/SpacingColumn";
import { FrontQuestionFormCard } from "../../components/Front/Complex/FrontQuestionFormCard/FrontQuestionFormCard";
import { FrontUserIdentityVerificationSummary } from "../../components/Front/Complex/FrontUserIdentityVerificationSummary/FrontUserIdentityVerificationSummary";
import FrontParagraphSmall from "../../components/Front/Simple/FrontParagraphSmall/FrontParagraphSmall";
import { TContainerStateProps } from "../../state/TContainerStateProps";
import { ClientCaseIdentityVerificationUserForm, TClientCaseIdentityVerificationUserForm } from "../../../../domain/codecs/form/ClientCaseForm";
import { FrontSpacing } from "../../models/FrontSpacing";
import { WeightBold } from "../../components/WeightBold/WeightBold";
import { HorizontalLine } from "../../components/BuildingBlocks/HorizontalLine";
import { FrontColors } from "../../models/FrontColors";
import { MaxWidth } from "../../components/BuildingBlocks/MaxWidth";
import { FrontFormSectionTitle } from "../../components/Front/Simple/FrontFormSectionTitle/FrontFormSectionTitle";
import { TOnboardingTabStatus } from "../../components/Front/Simple/FrontOnboardingTab/FrontOnboardingTab";
import { FrontIdentityVerificationRetryPopup } from "../../components/Front/Complex/FrontIdentityVerificationRetryPopup/FrontIdentityVerificationRetryPopup";
import { requireExhaustive } from "../../../../shared/src/util";


const FrontIdentityVerificationContainer = (props: TContainerStateProps): JSX.Element => {

    const getClients = (): Array<TClientCaseIdentityVerificationUserForm> => 
        props.state.forms.client_case_page.children.identity_verification
            ? props.state.forms.client_case_page.children.identity_verification.users
            : []
    ;

    return (
        <>
            <div>
                <FrontQuestionFormCard
                    title="Verify your identity"
                    paragraph={
                        <MaxWidth width="600px">
                            <SpacingColumn spacing={FrontSpacing.SMALL_3}>
                                <FrontParagraphSmall>
                                    Sail Legal verifies the <WeightBold>authenticity</WeightBold> of all <WeightBold>emails</WeightBold> and <WeightBold>phone numbers</WeightBold> our clients give us.
                                    This helps us be certain that we are actually talking to you, and protects you from fraud. 
                                </FrontParagraphSmall>
                                <FrontParagraphSmall>
                                    Here you can find the <WeightBold>verification status</WeightBold> of your contact 
                                    information, and if something is wrong, what you can do about it.
                                </FrontParagraphSmall>
                            </SpacingColumn>
                        </MaxWidth>
                    }
                >
                    <SpacingColumn spacing={FrontSpacing.MEDIUM_2}>
                        {getClients().map((form, index) => (
                            <MaxWidth
                                key={index} 
                                width="400px"
                            >
                                <SpacingColumn spacing={FrontSpacing.MEDIUM_2}>
                                    <SpacingColumn spacing={FrontSpacing.MEDIUM_1}>
                                        {/* USE NAME */}
                                        <FrontFormSectionTitle>
                                            {form.children.user14.full_name}
                                        </FrontFormSectionTitle>

                                        {/* ID VERIFICATION SUMMARY */}
                                        <FrontUserIdentityVerificationSummary
                                            key={index}
                                            user={form}
                                            onChange={(payload) => props.dispatch({
                                                type: "CLIENT_UPDATE_ID_VERIFICATION_DETAILS",
                                                payload
                                            })}
                                            onAutoCompleteAddress={(payload) => props.dispatch({
                                                type: "CLIENT_AUTOCOMPLETE_ADDRESS",
                                                payload
                                            })}
                                            onSubmit={(payload) => props.dispatch({
                                                type: "CLIENT_SUBMIT_ID_VERIFICATION_DETAILS",
                                                payload
                                            })}
                                            onBeginPhotoVerification={(payload) => props.dispatch({
                                                type: "CLIENT_BEGIN_STRIPE_VERIFICATION",
                                                payload
                                            })}
                                            onProofOfAddressDocumentUpload={(payload) => props.dispatch({
                                                type: "CLIENT_PROOF_OF_ADDRESS_DOCUMENT_UPLOAD",
                                                payload,
                                            })}
                                        />
                                    </SpacingColumn>

                                    {/* SEPERATOR LINE */}
                                    {index < getClients().length-1 &&
                                        <HorizontalLine color={FrontColors.NEUTRAL_11} />
                                    }
                                </SpacingColumn>
                            </MaxWidth>
                        ))}
                    </SpacingColumn>
                </FrontQuestionFormCard>
            </div>

            {/* PHOTO VERIFICATION RETRY */}
            {props.state.routes.queryParams.VIEW_CLIENT_CASE.errorCode && props.state.routes.queryParams.VIEW_CLIENT_CASE.userCaseId && <FrontIdentityVerificationRetryPopup
                form={getClients().find((user) => user.edited.users__cases_id === props.state.routes.queryParams.VIEW_CLIENT_CASE.userCaseId) || ClientCaseIdentityVerificationUserForm.newDefault()}
                errorCode={props.state.routes.queryParams.VIEW_CLIENT_CASE.errorCode}
                onBeginPhotoVerification={(payload) => props.dispatch({
                    type: "CLIENT_BEGIN_STRIPE_VERIFICATION",
                    payload
                })}
            />}
        </>
    );
};

export const doesEveryClientHaveTheirIdentityVerified = (clientUsers: Array<TClientCaseIdentityVerificationUserForm>): boolean =>
    pipe(
        clientUsers,
        array.map((form) => {
            switch (form.children.user14.identity_verification_status) {
                case "not_complete":
                case "failed":
                case "expired":
                case "needs_address_document":
                    return false;
                case "complete":
                case "pending":
                    return true;
                default:
                    return requireExhaustive(form.children.user14.identity_verification_status);
            }
        }),
        extArray.allMatchPredicate((value) => value === true)
    )
;


export const getIdentityVerificationStatus = (props: TContainerStateProps): TOnboardingTabStatus => {
    if (
        typeof props.state.forms.client_case_page.children.identity_verification === "undefined"
        || (
            typeof props.state.forms.client_case_page.children.identity_verification !== "undefined"
            && props.state.forms.client_case_page.children.identity_verification.users.length === 0
        )
    ) {
        return "completed";
    }
    
    return doesEveryClientHaveTheirIdentityVerified(props.state.forms.client_case_page.children.identity_verification.users) ? "completed" : "active";

};

export default FrontIdentityVerificationContainer;
