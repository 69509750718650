import { useState } from "react";
import { array, eq } from "fp-ts";
import { array as arrayExt } from "../../../shared/src/utilByDomainGroupExport";

type TUseMultiSelectProps<T> = {
    options: Array<T>;
    converter: (value: T) => string; 
};

export const useMultiSelect = <T extends unknown>(props: TUseMultiSelectProps<T>) => {
    
    const [values, setValues] = useState<Array<string>>([]);

    const toggleValue = (newValue: string) => {
        if (isSelected(newValue)) {
            setValues(arrayExt.deleteValue<string>(newValue, values));
        } else {
            setValues(values.concat(newValue));
        }
    };

    const toggleSelectAll = () => {
        if (isAllSelected()) {
            setValues([]);
        } else {
            setValues(props.options.map(props.converter));
        }
    }

    const isSelected = (value: string): boolean => array.elem(eq.eqString)(value, values);

    const isAllSelected = () => values.length === props.options.length; 

    const isAnythingSelected = (): boolean => values.length > 0;

    const clearSelection = () => setValues([]);

    return {
        toggleValue,
        toggleSelectAll,
        isSelected,
        isAnythingSelected,
        values,
        isAllSelected,
        clearSelection,
    };
}