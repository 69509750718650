import * as t from "io-ts";
import { nonEmptyString } from "../../shared/src/validation/basic/nonEmptyString";
import { required } from "../../shared/src/validation/basic/required";
import { inOrder } from "../../shared/src/validation/compose/inOrder";
import { rangeConstants } from "../../shared/src/validation/constants/range";
import { isEnum } from "../../shared/src/validation/create/isEnum";
import { object } from "../../shared/src/validation/create/object";
import { range } from "../../shared/src/validation/create/range";
import * as AmenityCategory from "./AmenityCategory";

export const codec = t.type({
    name: t.string,
    type: t.string,
    category: AmenityCategory.codec,
    distance_miles: t.number,
});

export type T = t.TypeOf<typeof codec>;

export const validator = object({
    name: inOrder(required, nonEmptyString),
    type: inOrder(required, nonEmptyString),
    category: inOrder(required, isEnum(AmenityCategory.values)),
    distance_miles: inOrder(required, range(0, rangeConstants.GREATER_THAN)),
});
