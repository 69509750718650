import React from "react";
import { CRMParagraphBulletPoints } from "../CRMBulletList/CRMParagraphBulletPoints";
import { CRMParagraph } from "../Simple/CRMParagraph/CRMParagraph";
import { CRMSpacingColumn } from "../CRMSpacingColumn/CRMSpacingColumn";
import { WeightSemiBold } from "../WeightSemiBold/WeightSemiBold";

export const CRMCaseSignOffAsReadyForExchangePopupChecklistSaleComponent = (): JSX.Element =>
    <CRMSpacingColumn spacing="medium">
        <CRMParagraph>
            <WeightSemiBold>
                Before continuing please review the following high risk flags and confirm you are happy to proceed.
            </WeightSemiBold>
        </CRMParagraph>

        <CRMParagraphBulletPoints
            bullets={[
                "Is the property vacant?",
                "Is a POA in place?",
                "Are net proceeds only being sent to one party without reason?",
                "Is the property being sold quickly from the date of purchase?",
                "Is the property mortgage free?",
                "Is the client elusive, corresponds by email only?",
                "Is the client putting unnecessary pressure on to sell the property quickly?"
            ]}
        />
    </CRMSpacingColumn>;
