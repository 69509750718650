import { pipe } from "fp-ts/lib/function";
import * as QueryStringFilterGroup1 from "../../../domain/models/QueryStringFilterGroup1";

export const makeFilterUrl = (path: string, filter: QueryStringFilterGroup1.T): string =>
     pipe(
        filter,
        JSON.stringify,
        encodeURIComponent,
        (encodedFilter) => `/v1/${path}?filters=${encodedFilter}`,
    );
