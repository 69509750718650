import React from "react";
import { FrontBuyerReadyWaysToImprove } from "../FrontBuyerReadyWaysToImprove/FrontBuyerReadyWaysToImprove";
import { FrontLegend } from "../FrontLegend/FrontLegend";
import { FrontOutsidePopupBasic } from "../FrontOutsidePopupBasic/FrontOutsidePopupBasic";
import FrontParagraphRegular from "../../../Front/Simple/FrontParagraphRegular/FrontParagraphRegular";
import { FrontSpacer } from "../../../FrontSpacer/FrontSpacer";
import FrontTitleMinor from "../../Simple/FrontTitleMinor/FrontTitleMinor";
import { WeightBold } from "../../../WeightBold/WeightBold";
import { WeightMedium } from "../../../WeightMedium/WeightMedium";
import { boolean } from "fp-ts";
import { pipe } from "fp-ts/lib/function";
import { TOffer1NewDefault } from "../../../../../../domain/codecs/Offer";

export class FrontFallThroughRiskModal extends React.Component<{
    isOpen: boolean;
    onClose: () => void;
    thierLatestOffer: TOffer1NewDefault;
}> {
    public getTipsToDisplay = (): FrontBuyerReadyWaysToImprove["props"]["showTipsFor"] =>
        [
            ...pipe(
                this.props.thierLatestOffer.has_provided_proof_of_funds,
                boolean.fold<Array<"proof_of_funds">>(
                    () => ["proof_of_funds"],
                    () => [],
                )
            ),
            ...pipe(
                this.props.thierLatestOffer.id_check_complete,
                boolean.fold<Array<"id_check">>(
                    () => ["id_check"],
                    () => [],
                )
            ),
            ...pipe(
                this.props.thierLatestOffer.has_provided_conveyancer_details,
                boolean.fold<Array<"solicitor_details">>(
                    () => ["solicitor_details"],
                    () => [],
                )
            ),
            ...pipe(
                this.props.thierLatestOffer.has_provided_mortgage_broker_details
                    || this.props.thierLatestOffer.payment_method === "cash",
                boolean.fold<Array<"mortgage_broker_details">>(
                    () => ["mortgage_broker_details"],
                    () => [],
                )
            ),
        ];

    public render (): JSX.Element {
        return (
            <FrontOutsidePopupBasic
                title="What is fall through risk?"
                isOpen={this.props.isOpen}
                onClose={this.props.onClose}
            >
                {/* Description */}
                <FrontParagraphRegular>
                    <WeightMedium>
                    Unfortunately some purchases fall through when the buyer has difficulty getting things in order on their end, these are costly for both sides. Our aim is to minimise this risk to both parties by showing the seller an honest risk calculation for your offer.&nbsp;
                    </WeightMedium>
                    {this.getTipsToDisplay().length > 0 && <WeightBold>
                        Don't worry if your risk is "High" at the moment, everyone starts out that way. Below are some ways to improve your score.
                    </WeightBold>}
                </FrontParagraphRegular>

                {/* Legend */}
                <FrontLegend
                    rows={[
                        {
                            key: "Low",
                            keyColour: "highlights-positive-apple-10",
                            description: "Low means that we've seen your proof of funds, ID check, solicitor details and if you are getting a mortgage your broker has vouched for you.",
                        },
                        {
                            key: "Medium",
                            keyColour: "highlights-pertinent-coral-10",
                            description: "Medium means we've seen your proof of funds but you haven't provided everything else, or you are in a chain.",
                        },
                        {
                            key: "High",
                            keyColour: "highlights-error-firebrick-10",
                            description: "Everyone starts out with a high score until they start providing us with more information. If your score is high right now then below are some ways to improve it.",
                        },
                    ]}
                />

                {this.getTipsToDisplay().length > 0 && <>
                    {/* Why is it important to improve this */}
                    <FrontTitleMinor>
                        Why is it important to improve this?
                    </FrontTitleMinor>
                    <FrontParagraphRegular>
                        The seller takes into account the fall through risk of every offer that is made. Improving your fall through risk will make it more likely for your offer to be accepted.
                    </FrontParagraphRegular>

                    {/* How do I improve this */}
                    <FrontTitleMinor>
                        How do I improve my fall through risk?
                    </FrontTitleMinor>
                    <FrontParagraphRegular>
                        You still need to provide the following, once we’ve received these your score will improve.
                    </FrontParagraphRegular>
                    <FrontBuyerReadyWaysToImprove
                        showTipsFor={this.getTipsToDisplay()}
                    />
                </>}
            </FrontOutsidePopupBasic>
        );
    }
}
