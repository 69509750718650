import { array } from "fp-ts";
import { pipe } from "fp-ts/lib/function";
import React from "react";
import { TCaseQuote } from "../../../../../domain/codecs/Quote";
import { QuoteRateAttribute, QuoteRateAttribute_question, QuoteRateClientsOwnership_displayString, QuoteRateClientsPropertyCount_displayString, QuoteRatePropertyResidentialCommercial_displayString, QuoteRateTransactionType_displayString } from "../../../../../domain/codecs/QuoteRate";
import { Tenure1_Tenure2 } from "../../../../../domain/codecs/Tenure";
import { fromNumber } from "../../../../../domain/models/TCurrency";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { Padding } from "../../BuildingBlocks/Padding";
import { SpacingColumn } from "../../BuildingBlocks/SpacingColumn";
import { CRMTitleSubSection } from "../../CRMTitleSubSection/CRMTitleSubSection";
import { CRMLabelAndMonoValue } from "../../Simple/CRMLabelAndMonoValue/CRMLabelAndMonoValue";
import { CRMPopoutHeader } from "../../Simple/CRMPopoutHeader/CRMPopoutHeader";
import { CRMSingleLineLabelAndValue } from "../../Simple/CRMSingleLineLabelAndValue/CRMSingleLineLabelAndValue";
import { CRMTidyPopout } from "../../Simple/CRMTidyPopout/CRMTidyPopout";

export const CRMQuoteInfo = (props: React.PropsWithChildren<{
    form: TCaseQuote;
    onClose: () => void;
}>): JSX.Element => {
    const hasReferralFee = props.form.referral_fee_pence > 0;

    const residentialCommercial =
        props.form.property_residential_commercial
            ? QuoteRatePropertyResidentialCommercial_displayString(props.form.property_residential_commercial)
            : "N/A";

    const clientsOwnership =
        props.form.clients_ownership
            ? QuoteRateClientsOwnership_displayString(props.form.clients_ownership)
            : "N/A";

    const clientsPropertyCount =
        props.form.clients_property_count
            ? QuoteRateClientsPropertyCount_displayString(props.form.clients_property_count)
            : "N/A";

    return (
        <CRMTidyPopout width="100%" height="730px">
            <CRMPopoutHeader
                icon="arrow-left"
                onClick={props.onClose}
            >
                Quote Info
            </CRMPopoutHeader>

            <Padding spacing={CRMSpacing.LARGE}>
                <SpacingColumn spacing={CRMSpacing.LARGE}>
                    <SpacingColumn spacing={CRMSpacing.TINY}>
                        {/* TRANSACTION TYPE */}
                        <CRMSingleLineLabelAndValue
                            label="Transaction type:"
                            value={QuoteRateTransactionType_displayString(props.form.transaction_type)}
                        />

                        {/* TENURE */}
                        <CRMSingleLineLabelAndValue
                            label="Tenure:"
                            value={Tenure1_Tenure2(props.form.tenure)}
                        />

                        {/* PROPERTY PRICE (TRANSFER MONETARY VALUE) */}
                        <CRMLabelAndMonoValue
                            label="Property price (transfer monetary value):"
                            value={fromNumber(props.form["property_price_pence--transfer-monetary-value"] / 100, 2)}
                        />

                        {/* PROPERTY PRICE (PROPERTY VALUE) */}
                        <CRMLabelAndMonoValue
                            label="Property value:"
                            value={fromNumber(props.form["property_price_pence--property-value"] / 100, 2)}
                        />

                        {/* NUMBER OF CLIENTS */}
                        <CRMLabelAndMonoValue
                            label="Number of clients:"
                            value={props.form.number_of_clients.toString()}
                        />

                        {/* RESIDENTIAL/COMMERCIAL */}
                        <CRMSingleLineLabelAndValue
                            label="Residential/commercial:"
                            value={residentialCommercial}
                        />

                        {/* FIRST TIME BUYERS */}
                        <CRMSingleLineLabelAndValue
                            label="First time buyer status:"
                            value={clientsOwnership}
                        />

                        {/* PROPERTY COUNT */}
                        <CRMSingleLineLabelAndValue
                            label="How many properties will the clients own on completion:"
                            value={clientsPropertyCount}
                        />

                        {/* ATTRIBUTE QUESTIONS */}
                        {pipe(
                            QuoteRateAttribute.values,
                            array.map((attribute) =>
                                <CRMSingleLineLabelAndValue
                                    label={QuoteRateAttribute_question(attribute)}
                                    value={props.form.attributes[attribute] ? "Yes" : "No"}
                                />
                            ),
                        )}
                    </SpacingColumn>

                    {/* REFERRAL */}
                    {hasReferralFee &&
                        <SpacingColumn spacing={CRMSpacing.MEDIUM}>
                            <CRMTitleSubSection>
                                Referral
                            </CRMTitleSubSection>

                            <SpacingColumn spacing={CRMSpacing.TINY}>
                                {/* INTRODUCER NAME */}
                                <CRMSingleLineLabelAndValue
                                    label="Referral fee paid to:"
                                    value={props.form.introducer_name}
                                />

                                {/* REFERRAL FEE */}
                                <CRMLabelAndMonoValue
                                    label="Amount:"
                                    value={fromNumber(props.form.referral_fee_pence / 100, 2)}
                                />
                            </SpacingColumn>
                        </SpacingColumn>
                    }
                </SpacingColumn>
            </Padding>
        </CRMTidyPopout>
    );
};
