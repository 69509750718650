import React, { useEffect, useState } from "react";
import { MarketingCampaignForm, TMarketingCampaignCreateForm, TMarketingCampaignForm, TMarketingCampaignsForm } from "../../../../../domain/codecs/form/MarketingCampaignForm";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { Absolute } from "../../BuildingBlocks/Absolute";
import { MinHeight } from "../../BuildingBlocks/MinHeight";
import { Padding } from "../../BuildingBlocks/Padding";
import { Relative } from "../../BuildingBlocks/Relative";
import { SpacingRow } from "../../BuildingBlocks/SpacingRow";
import { CRMBottomControlBar } from "../../CRMBottomControlBar/CRMBottomControlBar";
import { CRMButtonQuaternary } from "../../CRMButtonQuaternary/CRMButtonQuaternary";
import { CRMMarketingCampaignEditPopout } from "../CRMMarketingCampaignEditPopout/CRMMarketingCampaignEditPopout";
import { CRMMarketingCampaignSingleView } from "../CRMMarketingCampaignSingleView/CRMMarketingCampaignSingleView";
import { CRMMarketingCampaignTable } from "../CRMMarketingCampaignTable/CRMMarketingCampaignTable";
import { CRMBlockScrollCacheView, CRMBlockScrollResetView } from "../../CRM/CRMBlock/CRMBlockViews/CRMBlockViews";
import { CRMBlockPopover } from "../../CRM/CRMBlock/CRMBlockPopover/CRMBlockPopover";

type TMarketingCampaignBlockViewStatus =
    "table"
    | "view-campaign"
;

type TMarketingCampaignBlockPopupViewStatus =
    "none"
    | "edit-campaign"
;

type TMarketingCampaignsBlockProps = {
    form: TMarketingCampaignsForm;
    onAddNewMarketingCampaign: (form: TMarketingCampaignCreateForm) => void;
    onSave: (form: TMarketingCampaignForm) => void; 
    onChange: (form: TMarketingCampaignForm) => void;
    onArchive:  (form: TMarketingCampaignForm) => void;
    onLoadMore: () => void;
};

export const CRMMarketingCampaignsBlock = (props: React.PropsWithChildren<TMarketingCampaignsBlockProps>): JSX.Element => {

    const [viewStatus, setViewStatus] = useState<TMarketingCampaignBlockViewStatus>("table");
    const [popupViewStatus, setPopupViewStatus] = useState<TMarketingCampaignBlockPopupViewStatus>("none");
    const [openMarketingCampaign, setOpenMarketingCampaign] = useState<TMarketingCampaignForm | null>(null);

    useEffect(
        () => {
            const newMarketingCampaign = props.form.children.marketing_campaigns.find((form) => form.original.name === "");
            if (
                props.form.children.create_marketing_campaign_form.status === "success" 
                && !!newMarketingCampaign
            ) {
                setOpenMarketingCampaign(newMarketingCampaign);
                setPopupViewStatus("edit-campaign");
            }
        },
        [props.form.children.marketing_campaigns]
    )

    const getOpenMarketingCampaign = (): TMarketingCampaignForm => {
        const MarketingCampaign = props.form.children.marketing_campaigns.find((form) => form.original.id === openMarketingCampaign?.original.id);
        return MarketingCampaign || MarketingCampaignForm.newDefault();
    }

    const onArchive = (form: TMarketingCampaignForm) => {
        setViewStatus("table");
        props.onArchive(form);
    }

    const onChangeMarketingCampaign = (form: TMarketingCampaignForm) => props.onChange(form);

    const onOpenMarketingCampaign = (MarketingCampaign: TMarketingCampaignForm) => { 
        setOpenMarketingCampaign(MarketingCampaign);
        setViewStatus("view-campaign");
    }

    const onEditMarketingCampaign = (MarketingCampaign: TMarketingCampaignForm) => {
        setOpenMarketingCampaign(MarketingCampaign)
        setPopupViewStatus("edit-campaign");
    }

    const onSubmitNewMarketingCampaign = () => props.onAddNewMarketingCampaign(props.form.children.create_marketing_campaign_form);

    return (
        <>
            {/* POPOVER - EDIT */}
            {popupViewStatus === "edit-campaign" &&
                <CRMBlockPopover
                    padding={CRMSpacing.MEDIUM}
                    height="745px" 
                    maxHeight="745px"
                >
                    <CRMMarketingCampaignEditPopout
                        form={getOpenMarketingCampaign()}
                        onClose={() => setPopupViewStatus("none")}
                        onChange={props.onChange}
                        onSave={props.onSave}
                    />
                </CRMBlockPopover>
            }

            {/* VIEW - TABLE */}
            <CRMBlockScrollCacheView isShowing={viewStatus === "table"}>
                <div>
                    <MinHeight height="702px">
                        <CRMMarketingCampaignTable
                            form={props.form}
                            onOpen={onOpenMarketingCampaign}
                            onEdit={onEditMarketingCampaign}
                            onArchive={onArchive}
                            onLoadMore={props.onLoadMore}
                        />
                    </MinHeight>

                    <CRMBottomControlBar>
                        <Padding 
                            type="custom"
                            width="100%" 
                            spacing={`${CRMSpacing.TINY} ${CRMSpacing.MEDIUM}`}
                        >
                            <SpacingRow justifyContent="end">
                                <CRMButtonQuaternary 
                                    icon="add"
                                    label="Add Marketing Campaign"
                                    onClick={onSubmitNewMarketingCampaign}
                                />
                            </SpacingRow>
                        </Padding>
                    </CRMBottomControlBar>
                </div>
            </CRMBlockScrollCacheView>

            {/* VIEW - SINGLE */}
            <CRMBlockScrollResetView isShowing={viewStatus === "view-campaign"}>
                <CRMMarketingCampaignSingleView
                    form={getOpenMarketingCampaign()}
                    onClose={() => setViewStatus("table")}
                    onChange={onChangeMarketingCampaign}
                    onArchive={onArchive}
                    onSave={props.onSave}
                />
            </CRMBlockScrollResetView>
        </>
    );
};
