import { array, identity, option } from "fp-ts";
import { pipe } from "fp-ts/lib/pipeable"

type TOnFind<A> = (item: A, set: Array<A>, ) => A;
type TOnElse<A> = (set: Array<A>) => Array<A>;

export const updateAtOrElse = 
    <A>(index: number, onUpdate: TOnFind<A>, onElse: TOnElse<A>) =>
        (arrayToUpdate: Array<A>) =>
            pipe(
                arrayToUpdate,
                array.updateAt(
                    index,
                    !!arrayToUpdate[index] ? 
                        onUpdate(arrayToUpdate[index], arrayToUpdate)
                        : {} as A
                ),
                option.fold(
                    () => onElse(arrayToUpdate),
                    identity.flatten
                )
            )
;