import * as rxjs from "rxjs";
import * as rxjsOperators from "rxjs/operators";
import * as TForm from "../../models/TForm";
import { TActionObservable, TFormActionPayload } from "../../state/applyActions";
import { TState } from "./lensBaseTypes";
import { reduceDataToStateUpdate } from "./reduceDataToStateUpdate";
import { setWhere } from "./setWhere";
import { set } from "./set";
import {TSetState as TSetState} from "../../state/TSetState";
import {TGetState as TGetState} from "../../state/TGetState";
import { TPayloadActionType } from "../../state/actions/TAction";

interface ILensSetFormWhereC<A extends TPayloadActionType, T, S> {
    // 5 level lens path guard
    <
        K1 extends keyof S,
        K2 extends keyof S[K1],
        K3 extends keyof S[K1][K2],
        K4 extends keyof S[K1][K2][K3],
        K5 extends keyof S[K1][K2][K3][K4] & "forms"
    >
    (
        path: [K1, K2, K3, K4, K5],
        whereCallback: (
            value: TForm.TFormV2<TForm.TUnpackViewAndEditFormType<T>, TForm.TUnpackEditFormType<T>, TForm.TUnpackUiType<T>>,
            data: TFormActionPayload<Required<TForm.TUnpackEditFormType<T>>, keyof TForm.TUnpackEditFormType<T>>,
        ) => boolean,
    ): (
        obs$: TActionObservable<A, TFormActionPayload<Required<TForm.TUnpackEditFormType<T>>, keyof TForm.TUnpackEditFormType<T>>>,
        getState: TGetState,
        setState: TSetState
    ) => rxjs.Observable<unknown>;
    // 4 level lens path guard
    <
        K1 extends keyof S,
        K2 extends keyof S[K1],
        K3 extends keyof S[K1][K2],
        K4 extends keyof S[K1][K2][K3] & "forms"
    >
    (
        path: [K1, K2, K3, K4],
        whereCallback: (
            value: TForm.TFormV2<TForm.TUnpackViewAndEditFormType<T>, TForm.TUnpackEditFormType<T>, TForm.TUnpackUiType<T>>,
            data: TFormActionPayload<Required<TForm.TUnpackEditFormType<T>>, keyof TForm.TUnpackEditFormType<T>>,
        ) => boolean,
    ): (
        obs$: TActionObservable<A, TFormActionPayload<Required<TForm.TUnpackEditFormType<T>>, keyof TForm.TUnpackEditFormType<T>>>,
        getState: TGetState,
        setState: TSetState
    ) => rxjs.Observable<unknown>;
    // 3 level lens path guard
    <
        K1 extends keyof S,
        K2 extends keyof S[K1],
        K3 extends keyof S[K1][K2] & "forms"
    >
    (
        path: [K1, K2, K3],
        whereCallback: (
            value: TForm.TFormV2<TForm.TUnpackViewAndEditFormType<T>, TForm.TUnpackEditFormType<T>, TForm.TUnpackUiType<T>>,
            data: TFormActionPayload<Required<TForm.TUnpackEditFormType<T>>, keyof TForm.TUnpackEditFormType<T>>,
        ) => boolean,
    ): (
        obs$: TActionObservable<A, TFormActionPayload<Required<TForm.TUnpackEditFormType<T>>, keyof TForm.TUnpackEditFormType<T>>>,
        getState: TGetState,
        setState: TSetState
    ) => rxjs.Observable<unknown>;
    // 2 level lens path guard
    <
        K1 extends keyof S,
        K2 extends keyof S[K1] & "forms",
    >
    (
        path: [K1, K2],
        whereCallback: (
            value: TForm.TFormV2<TForm.TUnpackViewAndEditFormType<T>, TForm.TUnpackEditFormType<T>, TForm.TUnpackUiType<T>>,
            data: TFormActionPayload<Required<TForm.TUnpackEditFormType<T>>, keyof TForm.TUnpackEditFormType<T>>,
        ) => boolean,
    ): (
        obs$: TActionObservable<A, TFormActionPayload<Required<TForm.TUnpackEditFormType<T>>, keyof TForm.TUnpackEditFormType<T>>>,
        getState: TGetState,
        setState: TSetState
    ) => rxjs.Observable<unknown>;
}

export const setFormWhere =
    <A extends TPayloadActionType, T>(): ILensSetFormWhereC<A, T, TState> =>
        // There is no way to create paramater overloads here so we set to any
        (lens: Array<string>, whereCallback: any) => // eslint-disable-line
            (
                obs$: TActionObservable<A, TFormActionPayload<Required<TForm.TUnpackEditFormType<T>>, keyof TForm.TUnpackEditFormType<T>>>,
                getState: TGetState,
                setState: TSetState
            ) =>
                obs$.pipe(
                    rxjsOperators.tap((action) =>
                        reduceDataToStateUpdate<TFormActionPayload<Required<TForm.TUnpackEditFormType<T>>, keyof TForm.TUnpackEditFormType<T>>>(setState)(
                            setWhere<TFormActionPayload<Required<TForm.TUnpackEditFormType<T>>, keyof TForm.TUnpackEditFormType<T>>>()(
                                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                lens as any,
                                whereCallback,
                                /* eslint-disable */
                                // The type is always expected to be never for the form, since we have abstraced the lens scoping,
                                // which is untrue
                                // @ts-ignore
                                (form, payload) => TForm.updateFromAction2<T>(form, payload)
                                /* eslint-enable */
                            ),
                            set<TFormActionPayload<Required<TForm.TUnpackEditFormType<T>>, keyof TForm.TUnpackEditFormType<T>>>()(
                                // Go back up one level in the TFormList to set the root status
                                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                lens.slice(0, lens.length - 1) as any,
                                (formList) => TForm.reduceFormListStatusToHighestPriority(formList as TForm.TFormList<unknown, unknown, {}>) as never
                            )
                        )(action.payload)
                    )
                );
