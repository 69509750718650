import React, { CSSProperties } from "react";

export const TextAlign = (props: React.PropsWithChildren<{
    position: CSSProperties["textAlign"],
}>): JSX.Element => {
    return (
        <div style={{
            textAlign: props.position,
        }}>
            {props.children}
        </div>
    );
};
