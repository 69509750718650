import React from "react";
import { TCasesCaseForm } from "../../../../../../domain/codecs/form/CasesForm";
import { SpacingRow } from "../../../BuildingBlocks/SpacingRow";
import { CRMSpacing } from "../../../../models/CRMSpacing";
import { Background } from "../../../BuildingBlocks/Background";
import { CRMColors } from "../../../../models/CRMColors";
import { CRMIcon } from "../../../CRMIcon/CRMIcon";
import { CRMCaseMilestonePill } from "../CRMCaseMilestonePill/CRMCaseMilestonePill";

type TCaseTableRowMilestonesProps = {
    form: TCasesCaseForm;
    isOpen: boolean,
    onToggleOpen: () => void;
};

export const CRMCaseTableRowMilestones = (props: React.PropsWithChildren<TCaseTableRowMilestonesProps>): JSX.Element => {
    const iconName =
        props.isOpen
            ? "arrow-up"
            : "arrow-down";

    return (
        <Background
            cursor="pointer"
            onClick={props.onToggleOpen}
        >
            <SpacingRow
                childSize="1fr 68px"
            >
                <Background
                    padding={`0px ${CRMSpacing.MEDIUM}`}
                >
                    {/* MILESTONES */}
                    <SpacingRow
                        spacing={CRMSpacing.SMALL}
                        childSize="1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr"
                    >
                        <CRMCaseMilestonePill
                            label="DCP"
                            status={props.form.children.milestones.dcp}
                        />
                        <CRMCaseMilestonePill
                            label="Enq. Raised"
                            status={props.form.children.milestones.enquiries_raised}
                        />
                        <CRMCaseMilestonePill
                            label="Enq. Resolved"
                            status={props.form.children.milestones.enquiries_resolved}
                        />
                        <CRMCaseMilestonePill
                            label="GOP LOA"
                            status={props.form.children.milestones.gop_loa}
                        />
                        <CRMCaseMilestonePill
                            label="LPE1"
                            status={props.form.children.milestones.lpe1}
                        />
                        <CRMCaseMilestonePill
                            label="Red. Stat"
                            status={props.form.children.milestones.redemption_statement}
                        />
                        <CRMCaseMilestonePill
                            label="Signed Docs."
                            status={props.form.children.milestones.signed_docs}
                        />
                        <CRMCaseMilestonePill
                            label="Exchange"
                            status={props.form.children.milestones.exchanged}
                        />
                        <CRMCaseMilestonePill
                            label="Complete"
                            status={props.form.children.milestones.completed}
                        />
                    </SpacingRow>
                </Background>

                {/* BUTTON - OPEN/CLOSE */}
                <Background
                    height="36px"
                    color={CRMColors.NEUTRAL_12}
                    padding={`8px ${CRMSpacing.LARGE} 0px`}
                >
                    <CRMIcon
                        iconName={iconName}
                        size="20px"
                        colour="hex"
                        hexColour={CRMColors.NEUTRAL_4}
                    />
                </Background>
            </SpacingRow>
        </Background>
    );
};
