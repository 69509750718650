import React from "react";
interface IInputTextForInteger{
    placeholder?: string;
    name?: string;
    value?: string;
    displayError?: boolean;
    required?: boolean;
    className?: string;
    disabled?: boolean;
    onChange: (value: string) => void;
    onPressEnterKey: () => void;
}

export class InputTextForInteger extends React.Component<React.PropsWithChildren<IInputTextForInteger>> {
    public render (): JSX.Element {
        return (
            <input
                className={
                    "inputComponent__input inputComponent__input--textForInteger" + // to do --number replace
                    (this.props.displayError ? " inputComponent__input--error " : " ") +
                    this.props.className
                }
                type="text"
                inputMode="numeric"
                pattern="[0-9]*"
                placeholder={this.props.placeholder}
                value={this.props.value}
                required={this.props.required}
                name={this.props.name}
                id={this.props.name}
                onChange={(e) =>
                    new RegExp(/^[0-9]*$/).test(e.currentTarget.value)
                    && (typeof parseInt(e.currentTarget.value) === "number" && parseInt(e.currentTarget.value) >= 0)
                    && e.currentTarget.value.length <= 16 // masimum length before it goes weird
                    || e.currentTarget.value === ""
                        ? this.props.onChange(e.currentTarget.value)
                        : e.preventDefault() // Does not emit when any characters are entered other than [0-9], or below a defined minimum number
                }
                onKeyDown={(e) => e.keyCode === 13 && this.props.onPressEnterKey()}
                disabled={this.props.disabled}
            />
        );
    }
}
