import React from "react";
import { CRMColors } from "../../models/CRMColors";
import { CRMIcon } from "../CRMIcon/CRMIcon";
import { CRMParagraph } from "../Simple/CRMParagraph/CRMParagraph";
import { Text } from "../BuildingBlocks/Text";

export type TRadioValueType = string | number | boolean | null;

interface ICRMRadioComponent<A extends TRadioValueType> {
    text: string;
    value: A;
    onChange: (value: A) => void;
    checked: boolean;
    disabled?: boolean;
    displayError?: boolean;
}

export class CRMRadioComponent<A extends TRadioValueType> extends React.Component<React.PropsWithChildren<ICRMRadioComponent<A>>> {
    public render (): JSX.Element {
        return (
            <span
                className={"crm-radio"}
                onClick={() => {
                    this.props.disabled === true
                        ? undefined
                        : this.props.onChange(this.props.value);
                }}
            >
                <CRMIcon
                    iconName={this.props.checked ? "radio-selected" : "radio-unselected"}
                    colour={
                        this.props.disabled === true ? "neutral-4"
                        : this.props.displayError === true ? "highlights-instructional-brick-2"
                        : "neutral-ink"
                    }
                />
                <div className="crm-radio__label-text-wrap">
                    <CRMParagraph>
                        <Text
                            color={`${this.props.disabled === true ? CRMColors.NEUTRAL_4
                                : CRMColors.NEUTRAL_INK}`}
                            >
                            {this.props.text}
                        </Text>
                    </CRMParagraph>
                </div>
            </span>
        );
    }
}
