import React from "react";

type TButtonType =
    "rooster"
    | "otter"
    | "puffin"
    | "frog"
    | "fox"
    | "charizard"
;

type TButtonSelectedState =
    "on"
    | "off"
;

export interface IButton {
    label: string | React.ReactNode;
    type: TButtonType;
    fullWidth?: boolean;
    selectedState?: TButtonSelectedState;
    disabled?: boolean;
    onClick: () => void;
}

export class Button extends React.Component<React.PropsWithChildren<IButton>> {
    public render (): JSX.Element {
        return (
            <button className={
                `buttonComponent` +
                (this.props.fullWidth ? " buttonComponent--full-width" : "")
            }>
                <div
                    className={
                        `buttonComponent__button` +
                        ` buttonComponent__button--${this.props.type}` +
                        (this.props.selectedState ? `-${this.props.selectedState}` : "-on") +
                        (this.props.disabled ? ` buttonComponent__button--${this.props.type}-disabled` : "")
                    }
                    onClick={() => this.props.disabled ? undefined : this.props.onClick()}
                    onKeyDown={(e) => (e.keyCode === 13 && !this.props.disabled) && this.props.onClick()}
                >
                    {this.props.label}
                </div>
            </button>
        );
    }
}

export default Button;
