import * as rxjs from "rxjs";
import * as rxjsOperators from "rxjs/operators";
import { task, option } from "fp-ts/lib";
import * as fetchWrapper from "../../../wrappers/fetch";
import * as PublicListingSuccessResponse1 from "../../../../../domain/models/PublicListingSuccessResponse1";
import { TGetState } from "../../TGetState";
import { TSetState } from "../../TSetState";
import { pipe } from "fp-ts/lib/function";
import { TActionsDefinitionsList } from "../TAction";
import { TChangeRouteAction } from "../../router/routerTypes";

export const actions: TActionsDefinitionsList = [
    {
        type: "VIEW_LISTING",
        run: (
            obs$: rxjs.Observable<TChangeRouteAction<"VIEW_LISTING">>,
            getState: TGetState,
            setState: TSetState,
        ): void => {
            obs$.pipe(
                rxjsOperators.mergeMap((action) => loadListing(action.params.listingId, getState, setState))
            ).subscribe();
        },
    },
    {
        type: "VIEW_LISTING_BOOK_VIEWING_ADDITIONAL_QUESTIONS",
        run: (
            obs$: rxjs.Observable<TChangeRouteAction<"VIEW_LISTING_BOOK_VIEWING_ADDITIONAL_QUESTIONS">>,
            getState: TGetState,
            setState: TSetState,
        ): void => {
            obs$.pipe(
                rxjsOperators.mergeMap((action) => loadListing(action.params.listingId, getState, setState))
            ).subscribe();
        },
    },
    {
        type: "VIEW_LISTING_BOOK_VIEWING",
        run: (
            obs$: rxjs.Observable<TChangeRouteAction<"VIEW_LISTING_BOOK_VIEWING">>,
            getState: TGetState,
            setState: TSetState,
        ): void => {
            obs$.pipe(
                rxjsOperators.mergeMap((action) => loadListing(action.params.listingId, getState, setState))
            ).subscribe();
        },
    },
    {
        type: "VIEW_LISTING_BOOK_VIEWING_SUCCESS",
        run: (
            obs$: rxjs.Observable<TChangeRouteAction<"VIEW_LISTING_BOOK_VIEWING_SUCCESS">>,
            getState: TGetState,
            setState: TSetState,
        ): void => {
            obs$.pipe(
                rxjsOperators.mergeMap((action) => loadListing(action.params.listingId, getState, setState))
            ).subscribe();
        },
    },
];

const loadListing = (listingId: string, getState: TGetState, setState: TSetState): Promise<void> =>
     pipe(
        task.of(listingId),
        task.chain((id) =>
            fetchWrapper.json<PublicListingSuccessResponse1.T>({
                requestParams: {
                    url: `${env.REACT_APP_API_URL}/v1/public/listings/${id}`,
                    method: "GET",
                    body: option.none,
                },
                expectedTypeCodec: PublicListingSuccessResponse1.codec,
                defaultResponse: getState().activeData.listing.listingResponse.response,
            }),
        ),
        task.map((res) => setState(({...s}) => {
            s.activeData.listing.listingResponse = res;
            return s;
        }))
    )();
