import React from "react";
import { pipe } from "fp-ts/lib/pipeable";
import { TIntroducerForm } from "../../../../../domain/codecs/form/IntroducerForm";
import { formatDate } from "../../../../../shared/src/utilsByDomain/dateTime";
import { capitalizeEveryWord } from "../../../../../shared/src/utilsByDomain/string/capitalizeEveryWord";
import { dashCaseToNormalCase } from "../../../../../shared/src/utilsByDomain/string/dashCaseToNormalCase";
import { requireExhaustive } from "../../../../../shared/src/util";
import { CRMColors } from "../../../models/CRMColors";
import { CRMFontSizes } from "../../../models/CRMFontSizes";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { THexColor } from "../../../models/StringLiterals";
import { Background } from "../../BuildingBlocks/Background";
import { FontQuicksand } from "../../BuildingBlocks/FontQuicksand";
import { SpacingColumn } from "../../BuildingBlocks/SpacingColumn";
import { Text } from "../../BuildingBlocks/Text";
import { getIntroducerStatus } from "../../Complex/CRMIntroducerSingleView/CRMIntroducerSingleView";
import { CRMLabelAndMonoValue } from "../CRMLabelAndMonoValue/CRMLabelAndMonoValue";

type TIntroducerAgreementBubbleProps = {
    form: TIntroducerForm;
};

export const CRMIntroducerAgreementBubble = (props: React.PropsWithChildren<TIntroducerAgreementBubbleProps>): JSX.Element => {

    const getBackgroundColor = (): THexColor => {
        let status = getIntroducerStatus(props.form);
        switch (status) {
            case "agreement-signed":
                return CRMColors.HIGHLIGHTS_POSITIVE_GREEN_12;
            case "no-agreement-signed":
            case "agreement-requires-review":
                return CRMColors.HIGHLIGHTS_INSTRUCTIONAL_BRICK_10;
            case "agreement-not-required":
                return CRMColors.NEUTRAL_10;
            default:
                return requireExhaustive(status);
        }
    }

    const getStatusText = (): string => pipe(
        getIntroducerStatus(props.form),
        dashCaseToNormalCase,
        capitalizeEveryWord,
    );

    return (
        <Background
            display="inline-flex" 
            color={getBackgroundColor()}
            borderRadius="5px"
            padding={CRMSpacing.SMALL}
        >
            <SpacingColumn spacing={CRMSpacing.SMALL}>
                <FontQuicksand>
                    <Text
                        size={CRMFontSizes.MED}
                        lineHeight={1}
                        weight={500}
                    >
                        {getStatusText()}
                    </Text>
                </FontQuicksand>

                {(getIntroducerStatus(props.form) === "agreement-signed"
                    || getIntroducerStatus(props.form) === "agreement-requires-review")
                    && !!props.form.original.referral_agreement_next_review_date
                    && <CRMLabelAndMonoValue
                        label="Review due:"
                        value={formatDate(props.form.original.referral_agreement_next_review_date)}
                    />
                }
            </SpacingColumn>
        </Background>
    );
};
