import { array } from "fp-ts";
import { pipe } from "fp-ts/lib/function";
import React from "react";
import { TCaseDualRepLinkedForm } from "../../../../../domain/codecs/form/CaseForm";
import { CRMColors } from "../../../models/CRMColors";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { SpacingColumn } from "../../BuildingBlocks/SpacingColumn";
import { CRMParagraph } from "../../Simple/CRMParagraph/CRMParagraph";
import { CRMIconCardPopout } from "../../Simple/CRMIconCardPopout/CRMIconCardPopout";
import { WeightBold } from "../../WeightBold/WeightBold";
import { WeightMedium } from "../../WeightMedium/WeightMedium";

export const CRMDualRepIconCardPopout = (props: React.PropsWithChildren<{
    form: TCaseDualRepLinkedForm,
}>): JSX.Element => {
    const concatNames =
        pipe(
            [
                props.form.children.other_case_handler_name,
                props.form.children.other_case_assistant_name,
            ],
            array.filter((name) => name ? true : false),
            (a) => a.join(" & "),
        );

    return <CRMIconCardPopout
        iconName="dual-rep"
        iconBackgroundColour={CRMColors.HIGHLIGHTS_CALMING_PINK_10}
        cardBackgroundColour={CRMColors.HIGHLIGHTS_CALMING_PINK_12}
    >
        <SpacingColumn spacing={CRMSpacing.TINY}>
            {/* TITLE */}
            <CRMParagraph>
                <WeightBold>
                    DUAL REP
                </WeightBold>
            </CRMParagraph>

            {/* NEVER DISCUSS WITH... */}
            <CRMParagraph>
                {concatNames &&
                    <>
                        <WeightMedium>
                            This case should never be discussed with&nbsp;
                        </WeightMedium>

                        <WeightBold>
                            {concatNames}
                        </WeightBold>,&nbsp;

                        <WeightMedium>
                            representing the other side.
                        </WeightMedium>
                    </>
                }

                {!concatNames &&
                    <WeightMedium>
                        This case should never be discussed with the conveyancer & assistant representing the other side.
                    </WeightMedium>
                }
            </CRMParagraph>
        </SpacingColumn>
    </CRMIconCardPopout>;
};
