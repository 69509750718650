import React from "react";
import { TQuoteRateLiveRate } from "../../../../../domain/codecs/form/QuoteRateForm";
import { CRMColors } from "../../../models/CRMColors";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { Background } from "../../BuildingBlocks/Background";
import { Padding } from "../../BuildingBlocks/Padding";
import { CursorPointer } from "../../CursorPointer/CursorPointer";
import { CRMQuoteRateCardDetail } from "../CRMQuoteRateCardDetail/CRMQuoteRateCardDetail";

export const CRMLiveQuoteRateCard = (props: React.PropsWithChildren<{
    form: TQuoteRateLiveRate,
    onOpen: (form: TQuoteRateLiveRate) => void,
}>): JSX.Element =>
    <CursorPointer onClick={() => props.onOpen(props.form)}>
        <Background
            borderRadius="3px"
            color={CRMColors.NEUTRAL_PAPER}
        >
            <Padding spacing={CRMSpacing.MEDIUM}>
                <CRMQuoteRateCardDetail
                    form={props.form}
                />
            </Padding>
        </Background>
    </CursorPointer>;
