import React, { CSSProperties } from "react";
import { TPercentageSize, TPixelSize } from "../../models/StringLiterals";

type TRelativeProps = {
    divRef?: React.RefObject<HTMLDivElement>;
    display?: CSSProperties["display"];
    width?: TPixelSize | TPercentageSize | "fit-content";
    zIndex?: number,
};

export const Relative = (props: React.PropsWithChildren<TRelativeProps>): JSX.Element => {

    return (
        <div
            ref={props.divRef}
            style={{
                position: "relative",
                display: props.display || "inherit",
                width: props.width,
                zIndex: props.zIndex,
            }}
        >
            {props.children}
        </div>
    );
};
