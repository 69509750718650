import React from "react";
import { identity } from "fp-ts";
import { CRMFontSizes } from "../../../../models/CRMFontSizes";
import { CRMSpacing } from "../../../../models/CRMSpacing";
import { CRMColors } from "../../../../models/CRMColors";
import { InlineFlex } from "../../../BuildingBlocks/InlineFlex";
import { SpacingColumn } from "../../../BuildingBlocks/SpacingColumn";
import { SpacingRow } from "../../../BuildingBlocks/SpacingRow";
import { TextHightlight } from "../../../BuildingBlocks/TextHightlight";
import { CRMParagraph } from "../../../Simple/CRMParagraph/CRMParagraph";
import { WeightBold } from "../../../WeightBold/WeightBold";
import { WeightMedium } from "../../../WeightMedium/WeightMedium";
import { WeightSemiBold } from "../../../WeightSemiBold/WeightSemiBold";
import { TCasesDocumentType, toDisplayString as CaseDocumentTypeToCopyText } from "../../../../../../domain/models/CaseDocumentType1";
import { TCasesDocumentFile1 } from "../../../../../../domain/codecs/CasesDocument";
import { Underline } from "../../../Underline/Underline";
import { StyleItalic } from "../../../BuildingBlocks/StyleItalic";

type TEnquiryAttachmentProps = {
    tagPrefix?: string;
    file: TCasesDocumentFile1;
    fileTypeFormatting?: (typeAsCopyText: string) => JSX.Element | string;
    fileNameFormatting?: (message: string) => JSX.Element | string; 
};

type TTextFormatFunction = (value: string, type?: TCasesDocumentType) => JSX.Element | string;

export const CRMFileAttachment = (props: React.PropsWithChildren<TEnquiryAttachmentProps>): JSX.Element => {

    const formatTextWith = (functionName: "fileTypeFormatting" | "fileNameFormatting"): TTextFormatFunction => {
        if (typeof props[functionName] === "function") {
            return props[functionName] as TTextFormatFunction;
        }
        return identity.flatten;
    }

    const formatDocumentType = formatTextWith("fileTypeFormatting");
    const formatFileName = formatTextWith("fileNameFormatting");

    return (
        <SpacingColumn spacing={CRMSpacing.MEDIUM}>
            {/* ATTACHMENT TYPE LABEL */}
            <InlineFlex>
                <TextHightlight color={CRMColors.PRIMARY_12}>
                    <CRMParagraph>
                        <WeightBold>
                            {props.tagPrefix || "" } File
                        </WeightBold>
                    </CRMParagraph>
                </TextHightlight>
            </InlineFlex>
            
            <SpacingColumn spacing={CRMSpacing.TINY}>
                {!!props.file.type &&
                    <CRMParagraph fontSize={CRMFontSizes.SMALL}>
                        <WeightBold>
                            {formatDocumentType(CaseDocumentTypeToCopyText(props.file.type))}
                        </WeightBold>
                    </CRMParagraph>
                }

                {!!props.file.additional_name &&
                    <CRMParagraph fontSize={CRMFontSizes.SMALL}>
                        <WeightMedium>
                            <StyleItalic>
                                {props.file.additional_name}
                            </StyleItalic>
                        </WeightMedium>
                    </CRMParagraph>
                }
                
                {!!props.file.name &&
                    <CRMParagraph fontSize={CRMFontSizes.SMALL}>
                        <SpacingRow spacing={"3px"} alignItems="flex-start">
                            <WeightSemiBold>
                                Name:
                            </WeightSemiBold>
                            <WeightMedium>
                                <Underline>
                                    {formatFileName(`${props.file.name}.${props.file.file_extension}`)}
                                </Underline>
                            </WeightMedium>
                        </SpacingRow>
                    </CRMParagraph>
                }
            </SpacingColumn>
        </SpacingColumn>
    );
};