import React from "react";
import { breakpointOption } from "./MediaPropSwitch";

interface IMediaCompSwitch <M extends Record<string, unknown>, D extends Record<string, unknown>>{
    MobileComponent: React.FunctionComponent<React.PropsWithChildren<M>>;
    mobileProps: M;
    DesktopComponent: React.FunctionComponent<React.PropsWithChildren<D>>,
    desktopProps: D;
    breakpoint: breakpointOption;
};

export const MediaCompSwitch = <M extends Record<string, unknown>, D extends Record<string, unknown>>(props: React.PropsWithChildren<IMediaCompSwitch<M, D>>): JSX.Element => {

    return (
        <div className={`media-prop-switch`}>
            
            <div className={`media-prop-switch__mobile media-prop-switch__mobile--${props.breakpoint}`}>
                <props.MobileComponent {...props.mobileProps}>
                    {props.children}
                </props.MobileComponent>
            </div>

            <div className={`media-prop-switch__desktop media-prop-switch__desktop--${props.breakpoint}`}>
                <props.DesktopComponent {...props.desktopProps}>
                    {props.children}
                </props.DesktopComponent>
            </div>
        </div>
    );
};
