import React, { useState, useEffect } from "react";
import { FrontSpacing } from "../../../../models/FrontSpacing";
import { Padding } from "../../../BuildingBlocks/Padding";
import { SpacingColumn } from "../../../BuildingBlocks/SpacingColumn";
import { FrontInputTextareaComponent } from "../FrontInputTextareaComponent/FrontInputTextareaComponent";
import { FrontCondensedQuestionWrap } from "../FrontCondensedQuestionWrap/FrontCondensedQuestionWrap";
import { FrontFormRow } from "../FrontFormRow/FrontFormRow";
import FrontInputLabelAndErrorWrapComponent from "../FrontInputLabelAndErrorWrapComponent/FrontInputLabelAndErrorWrapComponent";
import { isFilled } from "./../../../../../../shared/src/util";
import { FrontColors } from "../../../../models/FrontColors";
import { FrontInfoBubble } from "../FrontInfoBubble/FrontInfoBubble";
import { MaxWidth } from "../../../BuildingBlocks/MaxWidth";

type TInputQuestionsProps= {
    value: string;
    label: string;
    closedLabel?: string;
    isEditable?: boolean;
    wrapInFormRow?: boolean;
    displayValidationErrorsIfPresent?: boolean;
    onChange: (value: string) => void;
    guidance?: JSX.Element;
};

export const FrontCondensedTextareaQuestion = (props: React.PropsWithChildren<TInputQuestionsProps>): JSX.Element => {

    const inputRef = React.createRef<HTMLTextAreaElement>();
    const [isOpen, setIsOpen] = useState(props.value.length === 0);

    const colapseOnBlurIfFilled = () => {
        if (isFilled(props.value)) {
            setIsOpen(false);
        }
    }

    useEffect(
        () => {
            if (isOpen === true) {
                inputRef.current?.focus();
            }
        },
        [isOpen]
    );

    // Padding is here so that the label texts are perfectly aligned with each otehr
    const renderInput = (): JSX.Element => (
         <Padding type="top" spacing="2px">
            <FrontInputLabelAndErrorWrapComponent label={props.label} errorMessage="Please fill this in" displayError={props.value === "" && props.displayValidationErrorsIfPresent ? true : false}>
                <SpacingColumn spacing={FrontSpacing.MEDIUM_1}>
                    {props.guidance && <MaxWidth width="500px">
                        <FrontInfoBubble color={FrontColors.PRIMARY_22}>
                            {props.guidance}
                        </FrontInfoBubble>
                    </MaxWidth>}

                    <FrontInputTextareaComponent
                        inputRef={inputRef}
                        value={props.value}
                        onChange={props.onChange}
                        onBlur={colapseOnBlurIfFilled}
                    />
                </SpacingColumn>
            </FrontInputLabelAndErrorWrapComponent>
        </Padding>
    )

    return (
        <FrontCondensedQuestionWrap
            isOpen={isOpen}
            isEditable={props.isEditable}
            setIsOpen={setIsOpen}
            label={props.closedLabel || props.label}
            summary={props.value}
        >
           {props.wrapInFormRow &&
                <FrontFormRow>
                    {renderInput()}
                </FrontFormRow>
           }

           {props.wrapInFormRow !== true &&
                renderInput()
           }
        </FrontCondensedQuestionWrap>
    );
};
