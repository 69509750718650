import { from, Observable, timer, forkJoin, of } from "rxjs";
import { array } from "fp-ts";
import { tap, flatMap, debounce, filter, concatMap, mergeMap, map, switchMap } from "rxjs/operators";
import { TActionObservable } from "../applyActions";
import { formOperation } from "../../wrappers/formOperation";
import { pipe } from "fp-ts/lib/pipeable";
import { TListingPhotoFileIO } from "../../../../domain/codecs/fileIO/ListingPhotoFileIO";
import { TListingFull1Editable } from "../../../../domain/codecs/formEditable/ListingEditable";
import { TListingAdHocTask2 } from "../../../../domain/codecs/ListingAdHocTask";
import { TListingAdHocTaskEditable } from "../../../../domain/codecs/formEditable/ListingAdHocTaskEditable";
import { TListingDocumentFileIO } from "../../../../domain/codecs/fileIO/ListingDocumentFileIO";
import { TListingDocumentEditable } from "../../../../domain/codecs/ListingDocument";
import { TListingSellerUserDeleteFormNewDefault } from "../../../../domain/codecs/form/ListingSellerUserDeleteForm";
import { TListingSellerSuggestedSimilarUserForm, TListingSellerUserEditForm } from "../../../../domain/codecs/form/ListingSellerUserEditForm";
import { TPhoneNumberForm, TPhoneNumberFormNewDefault } from "../../../../domain/codecs/form/PhoneNumberForm";
import { TGetState } from "../TGetState";
import { TSetState } from "../TSetState";
import { TListingSignMovementEditForm } from "../../../../domain/codecs/form/ListingSignMovementEditForm";
import { TListingSignMovementCreateForm } from "../../../../domain/codecs/form/ListingSignMovementCreateForm";
import { doFormValuesMatch } from "../../../../shared/src/codecs/types/formEditable";
import { TActionsDefinitionsList } from "./TAction";
import { action, routeAction } from "./actionFunctions";
import { updateUrl } from "../router/updateUrl";

export const actions: TActionsDefinitionsList = [
    routeAction("VIEW_CRM_LISTING", (obs$, lens, setState, getState) => {
        obs$.pipe(
            tap((a) => setState(lens.listing_page.data.input.listing_id.set(a.params.listingId))),
            mergeMap(() => formOperation("GetListingData", lens.listing_page.data.get()(getState()))),
            tap((response) => setState(lens.listing_page.data.set(response))),
        ).subscribe();
    }),
    action("LISTING_PREPARATION_FORM_CHANGE", (obs$: Observable<TListingFull1Editable>, lens, setState, getState) => {
        obs$.pipe(
            tap((payload) => setState(lens.listing_page.data.output.form.set(payload))),
            debounce(() => timer(1000)),
            tap(() => setState(lens.listing_page.data.output.form.status.set("submitting"))),
            switchMap(() => formOperation("UpdateListingFull", lens.listing_page.data.output.form.get()(getState()))),
            tap((response) => setState(lens.listing_page.data.output.form.set(response))),
        ).subscribe();
    }),
    action("LISTING_PAGE_ADD_PHOTOS", (obs$: Observable<TListingPhotoFileIO[]>, lens, setState, getState) => {
        obs$.pipe(
            tap((files) => setState(lens.listing_page.data.output.read_only.images.set(
                [
                    ...getState().listing_page.data.output.read_only.images,
                    ...files.map((file) => {
                        file.status = "submitting";
                        return file;
                    }),
                ]
            ))),
            mergeMap((files) => {
                return forkJoin(...files.map((file) => {
                    return from(formOperation("UploadListingPhoto", file)).pipe(
                        tap((response) => setState(
                            lens.listing_page.data.output.read_only.images.where(
                                (f) => whereDocumentOrPhotoRequestRefMatches(f, response)
                            ).set(response)
                        )),
                    );
                }))
            })
        ).subscribe();
    }),
    action("LISTING_PAGE_REMOVE_PHOTO", (obs$: Observable<TListingPhotoFileIO>, lens, setState, getState) => {
        obs$.pipe(
            tap((payload) => setState(
                lens.listing_page.data.output.read_only.images.where(
                    (image) => image.output.delete_form.input.id === payload.output.delete_form.input.id
                ).output.delete_form.status.set("submitting")
            )),
            mergeMap((payload) => formOperation("DeleteListingPhoto", payload.output.delete_form)),
            tap((response) => {
                if (response.status === "success") {
                    return setState(
                        lens.listing_page.data.output.read_only.images.set(
                            [...pipe(
                                getState().listing_page.data.output.read_only.images,
                                array.filter((image) => image.output.delete_form.input.id !== response.input.id)
                            )]
                        )
                    )
                }
                return setState(
                    lens.listing_page.data.output.read_only.images.where(
                        (image) => image.output.delete_form.input.id === response.input.id
                    ).output.delete_form.set(response)
                );
            }),
        ).subscribe();
    }),
    action("LISTING_PAGE_ADD_DOCUMENTS", (obs$: Observable<TListingDocumentFileIO[]>, lens, setState, getState) => {
        obs$.pipe(
            tap((files) => setState(lens.listing_page.data.output.read_only.documents.set(
                [
                    ...getState().listing_page.data.output.read_only.documents,
                    ...files.map((file) => {
                        file.status = "submitting";
                        return file;
                    }),
                ]
            ))),
            mergeMap((files) => {
                return forkJoin(...files.map((file) => {
                    return from(formOperation("UploadListingDocument", file)).pipe(
                        tap((response) => setState(
                            lens.listing_page.data.output.read_only.documents.where(
                                (f) => whereDocumentOrPhotoRequestRefMatches(f, response)
                            ).set(response)
                        )),
                    );
                }))
            })
        ).subscribe();
    }),
    action("LISTING_PAGE_REMOVE_DOCUMENT", (obs$: Observable<TListingDocumentFileIO>, lens, setState, getState) => {
        obs$.pipe(
            tap((payload) => setState(
                lens.listing_page.data.output.read_only.documents.where(
                    (image) => image.output.delete_form.input.id === payload.output.delete_form.input.id
                ).output.delete_form.status.set("submitting")
            )),
            mergeMap((payload) => formOperation("DeleteListingDocument", payload.output.delete_form)),
            tap((response) => {
                if (response.status === "success") {
                    return setState(
                        lens.listing_page.data.output.read_only.documents.set(
                            [...pipe(
                                getState().listing_page.data.output.read_only.documents,
                                array.filter((image) => image.output.delete_form.input.id !== response.input.id)
                            )]
                        )
                    )
                }
                return setState(
                    lens.listing_page.data.output.read_only.documents.where(
                        (image) => image.output.delete_form.input.id === response.input.id
                    ).output.delete_form.set(response)
                );
            }),
        ).subscribe();
    }),
    action("LISTING_PAGE_UPDATE_DOCUMENT", (obs$: Observable<TListingDocumentEditable>, lens, setState, getState) => {
        obs$.pipe(
            tap((payload) => setState(lens.listing_page.data.output.read_only.documents.where(
                (document) => document.output.editable_form.original.id === payload.original.id
            ).output.editable_form.set(payload))),
            debounce(() => timer(1000)),
            tap((payload) => setState(lens.listing_page.data.output.read_only.documents.where(
                (document) => document.output.editable_form.original.id === payload.original.id
            ).output.editable_form.status.set("submitting"))),
            mergeMap((payload) => formOperation("UpdateListingDocument", lens.listing_page.data.output.read_only.documents.where(
                (document) => document.output.editable_form.original.id === payload.original.id
                ).output.editable_form.get()(getState()))
            ),
            tap((response) => setState(lens.listing_page.data.output.read_only.documents.where(
                (document) => document.output.editable_form.original.id === response.original.id
            ).output.editable_form.set(response))),
        ).subscribe();
    }),
    action("LISTING_PAGE_ADD_AD_HOC_TASK", (obs$: Observable<TListingAdHocTask2>, lens, setState, getState) => {
        obs$.pipe(
            tap((payload) => setState(lens.listing_page.create_ad_hoc_task.input.set(payload))),
            tap(() => setState(lens.listing_page.create_ad_hoc_task.status.set("submitting"))),
            flatMap(() => formOperation("CreateListingAdHocTask", lens.listing_page.create_ad_hoc_task.get()(getState()))),
            tap((response) => setState(lens.listing_page.create_ad_hoc_task.set(response))),
            tap((payload) => {
                const tasks = lens.listing_page.data.output.ad_hoc_tasks.get()(getState());
                setState(lens.listing_page.data.output.ad_hoc_tasks.set(tasks.concat(payload.output)));
            }),
            debounce(() => timer(500)),
            tap(() => setState(lens.listing_page.create_ad_hoc_task.status.set("requiresSubmission"))),
        ).subscribe();
    }),
    action("LISTING_PAGE_UPDATE_AD_HOC_TASKS", (obs$: Observable<Array<TListingAdHocTaskEditable>>, lens, setState) => {
        obs$.pipe(
            tap((tasks) => setState(lens.listing_page.data.output.ad_hoc_tasks.set(tasks))),
            debounce(() => timer(100)),
            concatMap((tasks) => tasks),
            filter((task) => task.status === "requiresSubmission"),
            tap((task) => setState(lens.listing_page.data.output.ad_hoc_tasks.where(doFormValuesMatch(task, "id")).status.set("submitting"))),
            flatMap((task) => formOperation("UpdateListingAdHocTask", task)),
            tap((task) => setState(lens.listing_page.data.output.ad_hoc_tasks.where(doFormValuesMatch(task, "id")).set(task))),
        ).subscribe();
    }),
    action("LISTING_PAGE_ADD_SELLER", (obs$: Observable<undefined>, lens, setState, getState) => {
        obs$.pipe(
            tap(() => setState(lens.listing_page.data.output.listing_seller_user_create.status.set("submitting"))),
            mergeMap(() => formOperation("ListingSellerUserCreate", lens.listing_page.data.output.listing_seller_user_create.get()(getState()))),
            mergeMap(() => formOperation("GetListingData", lens.listing_page.data.get()(getState()))),
            tap((response) => setState(lens.listing_page.data.set(response))),
        ).subscribe();
    }),
    action("LISTING_PAGE_DELETE_SELLER", (obs$: Observable<TListingSellerUserDeleteFormNewDefault>, lens, setState, getState) => {
        obs$.pipe(
            tap((deleteForm) => setState(lens.listing_page.data.output.listing_seller_users.where((user) => user.original.id === deleteForm.original.id).children.delete_form.status.set("submitting"))),
            mergeMap((deleteForm) => formOperation("ListingSellerUserDelete", deleteForm)),
            mergeMap(() => formOperation("GetListingData", lens.listing_page.data.get()(getState()))),
            tap((response) => setState(lens.listing_page.data.set(response))),
        ).subscribe();
    }),
    action("LISTING_PAGE_UPDATE_SELLER_USER", (obs$: Observable<Array<TListingSellerUserEditForm>>, lens, setState) => {
        obs$.pipe(
            tap((users) => setState(lens.listing_page.data.output.listing_seller_users.set(users))),
            debounce(() => timer(1000)),
            concatMap((users) => users),
            filter((user) => user.status === "requiresSubmission"),
            tap((user) => setState(lens.listing_page.data.output.listing_seller_users.where(doFormValuesMatch(user, "id")).status.set("submitting"))),
            flatMap((user) => formOperation("ListingSellerUserEdit", user)),
            tap((user) => setState(lens.listing_page.data.output.listing_seller_users.where(doFormValuesMatch(user, "id")).set(user))),
        ).subscribe();
    }),
    action("LISTING_PAGE_SWAP_SELLER_PARTY_MEMBER", (obs$: Observable<TListingSellerSuggestedSimilarUserForm>, lens, setState, getState, routes, dispatch) => {
        obs$.pipe(
            tap((payload) => setState(
                lens.listing_page.data.output.listing_seller_users
                    .where((listingSellerUsers) => listingSellerUsers.edited.id === payload.edited.swap_from_user_id)
                    .children.suggested_similar_users
                    .where((suggestedSimilarUsers) => suggestedSimilarUsers.edited.swap_to_user_id === payload.edited.swap_to_user_id)
                    .status.set("submitting")
            )),
            switchMap((payload) => formOperation(
                "ListingSwapSellerPartyMember",
                lens.listing_page.data.output.listing_seller_users
                    .where((listingSellerUsers) => listingSellerUsers.edited.id === payload.edited.swap_from_user_id)
                    .children.suggested_similar_users
                    .where((suggestedSimilarUsers) => suggestedSimilarUsers.edited.swap_to_user_id === payload.edited.swap_to_user_id)
                    .get()(getState())
            )),
            tap((response) => setState(
                lens.listing_page.data.output.listing_seller_users
                    .where((listingSellerUsers) => listingSellerUsers.edited.id === response.edited.swap_from_user_id)
                    .children.suggested_similar_users
                    .where((suggestedSimilarUsers) => suggestedSimilarUsers.edited.swap_to_user_id === response.edited.swap_to_user_id)
                    .set(response)
            )),
            switchMap((response) =>
                response.status === "success"
                    ? from(formOperation("GetListingData", lens.listing_page.data.get()(getState())))
                        .pipe(
                            tap((response) => setState(lens.listing_page.data.set(response))),
                        )
                    : of(undefined)
            )
        ).subscribe();
    }),
    action("LISTING_PAGE_UPDATE_SELLER_USER_PHONE_NUMBER", (obs$: Observable<Array<TPhoneNumberFormNewDefault>>, lens, setState) => {
        obs$.pipe(
            tap((phoneNumbers) => phoneNumbers.map((phoneNumber) => setState(lens.listing_page.data.output.listing_seller_users.where((user) => user.edited.id === phoneNumber.edited.user_id).children.phone_numbers.where((pn) => pn.edited.id === phoneNumber.edited.id).set(phoneNumber)))),
            debounce(() => timer(1000)),
            concatMap((phoneNumbers) => phoneNumbers),
            filter((user) => user.status === "requiresSubmission"),
            tap((phoneNumber) => setState(lens.listing_page.data.output.listing_seller_users.where((user) => user.edited.id === phoneNumber.edited.user_id).children.phone_numbers.where((pn) => pn.edited.id === phoneNumber.edited.id).status.set("submitting"))),
            flatMap((phoneNumber) => formOperation("UpdateUserNumber", phoneNumber)),
            tap((phoneNumber) => setState(lens.listing_page.data.output.listing_seller_users.where((user) => user.edited.id === phoneNumber.edited.user_id).children.phone_numbers.where((pn) => pn.edited.id === phoneNumber.edited.id).set(phoneNumber))),
        ).subscribe();
    }),
    action("LISTING_PAGE_ADD_PHONE_NUMBER_TO_SELLER_USER", (obs$: Observable<string>, lens, setState, getState) => {
        obs$.pipe(
            tap((userId) => setState(lens.listing_page.data.output.listing_seller_user_phone_number_create.input.user_id.set(userId))),
            tap(() => setState(lens.listing_page.data.output.listing_seller_user_phone_number_create.status.set("submitting"))),
            map(() => lens.listing_page.data.output.listing_seller_user_phone_number_create.get()(getState())),
            mergeMap((ioForm) => formOperation("CreateUserNumber", ioForm)),
            mergeMap(() => formOperation("GetListingData", lens.listing_page.data.get()(getState()))),
            tap((response) => setState(lens.listing_page.data.set(response))),
        ).subscribe();
    }),
    action("LISTING_PAGE_REMOVE_PHONE_NUMBER_FROM_SELLER_USER", (obs$: Observable<TPhoneNumberForm>, lens, setState, getState) => {
        obs$.pipe(
            tap((numberEditable) => setState(lens.listing_page.data.output.listing_seller_user_phone_number_delete.input.set({
                user_id: numberEditable.original.user_id,
                number_id: numberEditable.original.id
            }))),
            tap(() => setState(lens.listing_page.data.output.listing_seller_user_phone_number_delete.status.set("submitting"))),
            map(() => lens.listing_page.data.output.listing_seller_user_phone_number_delete.get()(getState())),
            mergeMap((ioForm) => formOperation("RemoveUserNumber", ioForm)),
            mergeMap(() => formOperation("GetListingData", lens.listing_page.data.get()(getState()))),
            tap((response) => setState(lens.listing_page.data.set(response))),
        ).subscribe();
    }),
    {
        type: "UPDATE_LISTING_VISIBLE_SECTIONS_FILTER",
        run: (
            obs$: TActionObservable<"UPDATE_LISTING_VISIBLE_SECTIONS_FILTER", string>,
            getState: TGetState,
            setState: TSetState,
        ): void => {
            obs$.pipe(
                tap((a) =>
                    setState(({...s}) => {
                            s.routes.queryParams.VIEW_CRM_LISTING.visible_sections = a.payload;
                            updateUrl(s);
                            return s;
                     }),
                ),
            )
            .subscribe();
        },
    },
    {
        type: "LISTING_UPDATE_VISIBLE_TAB",
        run: (
            obs$: TActionObservable<"LISTING_UPDATE_VISIBLE_TAB", string>,
            getState: TGetState,
            setState: TSetState,
        ): void => {
            obs$.pipe(
                tap((a) =>
                    setState(({...s}) => {
                            s.routes.queryParams.VIEW_CRM_LISTING.visible_tab = a.payload;
                            updateUrl(s);
                            return s;
                     }),
                ),
            )
            .subscribe();
        },
    },
    action("LISTING_PAGE_SIGN_MOVEMENTS_CHANGE", (obs$: Observable<Array<TListingSignMovementEditForm>>, lens, setState) => {
        obs$.pipe(
            tap((movements) => setState(lens.listing_page.data.output.sign_movements.set(movements))),
            debounce(() => timer(100)),
            concatMap((movements) => movements),
            filter((task) => task.status === "requiresSubmission"),
            tap((movement) => setState(lens.listing_page.data.output.sign_movements.where(doFormValuesMatch(movement, "id")).status.set("submitting"))),
            flatMap((movement) => formOperation("UpdateListingSignMovement", movement)),
            tap((movement) => setState(lens.listing_page.data.output.sign_movements.where(doFormValuesMatch(movement, "id")).set(movement))),
        ).subscribe();
    }),
    action("LISTING_PAGE_ADD_SIGN_MOVEMENT_CHANGE", (obs$: Observable<TListingSignMovementCreateForm>, lens, setState) => {
        obs$.pipe(
            tap((payload) => setState(lens.listing_page.create_sign_movement.set(payload))),
        ).subscribe();
    }),
    action("LISTING_PAGE_ADD_SIGN_MOVEMENT", (obs$: Observable<TListingSignMovementCreateForm>, lens, setState, getState) => {
        obs$.pipe(
            tap((payload) => setState(lens.listing_page.create_sign_movement.set(payload))),
            tap(() => setState(lens.listing_page.create_sign_movement.status.set("submitting"))),
            flatMap(() => formOperation("CreateListingSignMovement", lens.listing_page.create_sign_movement.get()(getState()))),
            tap((response) => setState(lens.listing_page.create_sign_movement.status.set(response.status))),
            mergeMap(() => formOperation("GetListingData", lens.listing_page.data.get()(getState()))),
            tap((response) => setState(lens.listing_page.data.set(response))),
        ).subscribe();
    }),
    action("LISTING_PAGE_DELETE_SIGN_MOVEMENT", (obs$: Observable<TListingSignMovementEditForm>, lens, setState, getState) => {
        obs$.pipe(
            tap((movement) => setState(lens.listing_page.data.output.sign_movements.where(doFormValuesMatch(movement, "id")).status.set("submitting"))),
            mergeMap((movement) => formOperation("DeleteListingSignMovement", movement)),
            mergeMap(() => formOperation("GetListingData", lens.listing_page.data.get()(getState()))),
            tap((response) => setState(lens.listing_page.data.set(response))),
        ).subscribe();
    }),
];

const whereDocumentOrPhotoRequestRefMatches = (file: TListingPhotoFileIO | TListingDocumentFileIO, response: TListingPhotoFileIO | TListingDocumentFileIO) => {
    if (file.input.request_ref === undefined || response.input.request_ref === undefined) {
        return false;
    }
    return file.input.request_ref === response.input.request_ref;
};