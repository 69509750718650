import { Observable } from "rxjs";
import { tap, switchMap } from "rxjs/operators";
import { formOperation } from "../../wrappers/formOperation";
import { TActionsDefinitionsList } from "./TAction";
import { action } from "./actionFunctions";
import { TCreateStaffUserForm } from "../../../../domain/codecs/form/CreateStaffUserForm";

export const actions: TActionsDefinitionsList = [
    action("CREATE_STAFF_USER_FORM_CHANGE", (obs$: Observable<TCreateStaffUserForm>, lens, setState, getState, routes, dispatch) => {
        obs$.pipe(
            tap((payload) => setState(lens.create_staff_user_page.set(payload))),
        ).subscribe();
    }),
    action("CREATE_STAFF_USER_FORM_SUBMIT", (obs$: Observable<TCreateStaffUserForm>, lens, setState, getState, routes, dispatch) => {
        obs$.pipe(
            tap(() => setState(lens.create_staff_user_page.status.set("submitting"))),
            switchMap((form) => formOperation("CreateStaffUser", form)),
            tap((form) => setState(lens.create_staff_user_page.set(form))),
        ).subscribe();
    }),
];