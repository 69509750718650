import React from "react";
import * as TFormModels from "../../../models/TFormModels";
import * as CaseDocumentType1 from "../../../../../domain/models/CaseDocumentType1";
import { CRMIcon } from "../../CRMIcon/CRMIcon";
import { CRMInlineDeferPicker } from "../../CRMInlineDeferPicker/CRMInlineDeferPicker";
import { DateTime } from "luxon";
import { CRMIconLabelAndMonoText } from "../../CRMIconLabelAndMonoText/CRMIconLabelAndMonoText";
import * as ISODateTime from "../../../../../domain/models/ISODateTime";
import { CRMInlineOptionsComponent } from "../../CRMInlineOptionsComponent/CRMInlineOptionsComponent";
import { CRMFormErrorComponent } from "../../CRMFormErrorComponent/CRMFormErrorComponent";
import { CRMInlineTextCalendar } from "../../CRMInlineTextCalendar/CRMInlineTextCalendar";
import { SpacingRow } from "../../BuildingBlocks/SpacingRow";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { CRMFontSizes } from "../../../models/CRMFontSizes";
import { SpacingColumn } from "../../BuildingBlocks/SpacingColumn";
import { CursorPointer } from "../../CursorPointer/CursorPointer";
import { FontQuicksand } from "../../BuildingBlocks/FontQuicksand";
import { WeightBold } from "../../WeightBold/WeightBold";
import { FontSize } from "../../BuildingBlocks/FontSize";
import { WeightSemiBold } from "../../WeightSemiBold/WeightSemiBold";
import { BackgroundColour } from "../../BuildingBlocks/BackgroundColour";
import { CRMColors } from "../../../models/CRMColors";
import { Padding } from "../../BuildingBlocks/Padding";

type TCaseDocumentCardFaceProps = {
    documentForm: TFormModels.TCaseDocument6AndCaseDocumentForm1FormList["forms"][number];
    filesForms: TFormModels.TCaseDocumentFile4AndCaseDocumentFileForm1FormList["forms"];
    onDueDateChange: (isoDate: string) => void;
    onValidUntilChange: (isoDate: string | null) => void;
    onDelete: () => void;
    onEdit: () => void;
};

export const CRMCaseDocumentCardFace = (props: React.PropsWithChildren<TCaseDocumentCardFaceProps>): JSX.Element => {

    const allFilesReceived = (): boolean =>
        props.documentForm.edit.received_date !== null
        && props.filesForms.length > 0;

    const isDocumentExpired = (): boolean =>
        props.documentForm.edit.valid_until
        ? ISODateTime.getNumberOfDaysSinceDate(DateTime.fromISO(props.documentForm.edit.valid_until).toJSDate()) > 0
        : false;

    const getDocumentOverdueDays = (): number =>
        ISODateTime.getNumberOfDaysSinceDate(DateTime.fromISO(props.documentForm.edit.due_date).toJSDate());

    const isDocumentOverdue = (): boolean =>
        new Date(props.documentForm.edit.due_date).getTime() < (new Date).getTime();

    const documentCanBeDeleted = (): boolean => props.documentForm.view.enquiries.length === 0;

    const isDocumentOutstanding = () => isDocumentOverdue() && !allFilesReceived() && !isDocumentExpired();

    return (
        <SpacingColumn spacing={CRMSpacing.MEDIUM}>
            {/* TITLE & OPTIONS */}
            <SpacingRow spacing={CRMSpacing.TINY}>
                {/* TITLE */}
                <FontQuicksand>
                    <FontSize size={CRMFontSizes.SMALL}>
                        <WeightBold>
                            {CaseDocumentType1.toDisplayString(props.documentForm.edit.type)}
                        </WeightBold>
                        <WeightSemiBold>
                            {props.documentForm.edit.additional_name ? ` - ${props.documentForm.edit.additional_name}` : ""}
                        </WeightSemiBold>
                    </FontSize>
                </FontQuicksand>
                {/* OPTIONS */}
                <CRMInlineOptionsComponent
                    options={[
                        {
                            label: "Edit",
                            onClick: props.onEdit,
                        },
                        {
                            label: "Delete",
                            onClick: props.onDelete,
                            disabled: !documentCanBeDeleted(),
                            disabledExplanationText: "This document is already being used in an enquiry.",
                        },
                    ]}
                    icon="options"
                    iconSize="tiny"
                />
            </SpacingRow>
            
            <SpacingRow alignItems="flex-start" childSize="5fr auto">
                {/* TEXT LINES */}
                <SpacingColumn spacing={CRMSpacing.TINY}>
                    {/* IF OUTSTANDING FILES ARE LATE: LATE BY */}
                    {(!allFilesReceived() && isDocumentOverdue()) &&
                        <CRMIconLabelAndMonoText
                            label="Late by:"
                            displayText={
                                getDocumentOverdueDays() === 1 ? 
                                    `${getDocumentOverdueDays().toString()} day`
                                    : `${getDocumentOverdueDays().toString()} days`
                            }
                            iconAlignment="start"
                            iconName="clock"
                        />
                    }

                    {/* IF OUTSTANDING FILES: EXPECTED BY */}
                    {!allFilesReceived() && 
                        <SpacingRow spacing={CRMSpacing.TINY}>
                            <CRMIcon
                                iconName="calendar"
                                colour="neutral-ink"
                                size="tiny"
                            />
                            <CRMInlineDeferPicker
                                date={props.documentForm.edit.due_date}
                                label="Expected by:"
                                onChange={(value) => value ?
                                    props.onDueDateChange(value) : 
                                    undefined
                                }
                            />
                        </SpacingRow>
                    }

                    {/* IF ALL FILES RECIEVED: RECIEVED DATE */}
                    {allFilesReceived() &&
                        <SpacingRow spacing={CRMSpacing.TINY}>
                            <CRMIcon
                                iconName="calendar"
                                colour="neutral-ink"
                                size="tiny"
                            />
                            <CRMIconLabelAndMonoText
                                label="Received on:"
                                displayText={
                                    props.documentForm.edit.received_date
                                        ? ISODateTime.getFormattedDateString(DateTime.fromISO(props.documentForm.edit.received_date).toJSDate(), "dd/MM/yyyy")
                                        : ""
                                }
                            />
                        </SpacingRow>
                    }

                    {/* VALID UNIT/EXPIRED ON */}
                    <SpacingRow spacing={CRMSpacing.TINY}>
                        <CRMIcon
                            iconName="calendar-cross"
                            colour="neutral-ink"
                            size="tiny"
                        />
                        <CRMInlineTextCalendar
                            date={props.documentForm.edit.valid_until ? DateTime.fromISO(props.documentForm.edit.valid_until).toJSDate() : null}
                            label={isDocumentExpired() ? "Expired on" : "Valid until"}
                            onChange={(value) => props.onValidUntilChange(value ? DateTime.fromJSDate(value).toISODate() : null)}
                        />
                        {props.documentForm.edit.valid_until &&
                            <CursorPointer>
                                <CRMIcon
                                    size="tiny"
                                    iconName="backspace"
                                    colour="neutral-ink"
                                    onClick={() => props.onValidUntilChange(null)}
                                />
                            </CursorPointer>
                        }
                    </SpacingRow>
                    
                    {/* ERROR MESSAGE */}
                    {props.documentForm.status === "failure" && 
                        <CRMFormErrorComponent
                            errorMessage="This document couldn't be deleted. It may still be in use as part of another enquiry on this case."
                        />
                    }
                </SpacingColumn>
                
                {/* DEMARCATION - INTERNAL/EXTERNAL */}
                <BackgroundColour
                    title={props.documentForm.edit.from_external_source ? "External source" : "Internal source"}
                    colour={isDocumentOutstanding() ? CRMColors.HIGHLIGHTS_INSTRUCTIONAL_BRICK_10 : CRMColors.NEUTRAL_12}
                    borderRadius="10px"
                >
                    <Padding type="custom" spacing={`${CRMSpacing.TINY} ${CRMSpacing.SMALL}`}>
                        <CRMIcon
                            iconName={props.documentForm.edit.from_external_source ? "external" : "internal"}
                            size="medium"
                            colour={isDocumentOutstanding() ? "highlights-instructional-brick-2" : "neutral-4"}
                        />
                    </Padding>
                </BackgroundColour>
            </SpacingRow>


        </SpacingColumn>
    );
};
