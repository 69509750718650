import React, { useState } from "react";
import { Absolute } from "../../BuildingBlocks/Absolute";
import { CRMIcon } from "../../CRMIcon/CRMIcon";
import { CRMColors } from "../../../models/CRMColors";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { SpacingColumn } from "../../BuildingBlocks/SpacingColumn";
import { Background } from "../../BuildingBlocks/Background";
import { SpacingRow } from "../../BuildingBlocks/SpacingRow";
import { FontRoboto } from "../../BuildingBlocks/FontRoboto";
import { Text } from "../../BuildingBlocks/Text";
import { useOpenClose } from "../../../hooks/UseOpenClose";
import { useHover } from "../../../hooks/useHover";

type TListInputValue<T extends unknown> = {
    displayString: string;
    meta: T
}

type TListInputProps<J extends unknown> = {
    value: Array<TListInputValue<J>>;
    mode?: "always-open" | "toggleable";
    disabled?: boolean;
    placeholder?: string;
    onAdd: (value: string) => void;
    onDelete: (value: TListInputValue<J>) => void;
    disableDelete?: boolean;
    disableAdd?: boolean;
};

export const CRMInputList = <T extends unknown>(props: React.PropsWithChildren<TListInputProps<T>>): JSX.Element => {

    const [text, setText] = useState("");
    const [isFocused, setIsFocused] = useState(false);
    const {
        ref, 
        isOpen,
        toggleOpen,
        setIsOpen
    } = useOpenClose();

    const IsDisabled = props.disabled || false;
    const Mode = props.mode || "toggleable";
    const IsToggleable = Mode === "toggleable";
    const HasValues = props.value.length > 0;
    const IsShowingItems = HasValues && (isOpen || !IsToggleable || IsDisabled);
    
    const getTextAreaIcon = () => isFocused ? "enter" : "bullet-list";
    const getTextAreaIconColor = () => text.length > 0 ? CRMColors.NEUTRAL_0 : CRMColors.NEUTRAL_4;

    const onFocusTextArea = () => {
        setIsOpen(true);        
        setIsFocused(true);
    }

    const onTextAreaKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (event.shiftKey === false && event.key === "Enter" && props.disableAdd !== true) {
            event.preventDefault();
            props.onAdd(text);
            setText("");
        }
    }

    const toggleItemVisibility = () => toggleOpen();
    
    return (
        <div 
            ref={ref}
            className={`
                crm-input-list
                crm-input-list--${IsShowingItems ? "rounded" : "flat"}
                crm-input-list--${IsDisabled ? "disabled" : "enabled"}
            `}
        >
            {/* TEXTAREA */}
            {!IsDisabled &&
                <div 
                    className={`
                        crm-input-list__textarea-wrap
                        crm-input-list__textarea-wrap--${IsShowingItems ? "show-border" : "hide-border"}
                    `}
                >
                    <textarea
                        className={`
                            crm-input-list__textarea
                            crm-input-list__textarea--${IsShowingItems ? "rounded" : "flat"}
                        `}
                        disabled={props.disabled}
                        value={text}
                        placeholder={props.placeholder || "Enter list item text"}
                        onFocus={onFocusTextArea}
                        onBlur={() => setIsFocused(false)}
                        onKeyDown={onTextAreaKeyDown}

                        onChange={(event) => setText(event.currentTarget.value)}
                    />

                    <Absolute
                        top="18px"
                        right={CRMSpacing.MEDIUM}
                    >
                        {/* [CONTROLS] ITEM COUNT & TOGGLE VISIBLITY */}
                        {!isOpen && HasValues && IsToggleable &&
                            <SpacingRow 
                                spacing={CRMSpacing.TINY} 
                                alignItems="flex-start"
                            >
                                <Background 
                                    display="flex"
                                    padding="2px 0px 0px"
                                >
                                    <FontRoboto>
                                        <Text size={12} lineHeight={1}>
                                            ({props.value.length})
                                        </Text>
                                    </FontRoboto>
                                </Background>

                                <CRMIcon
                                    iconName="tooltip-open"
                                    colour="hex"
                                    hexColour={CRMColors.NEUTRAL_INK}
                                    size="18px"
                                    onClick={toggleItemVisibility}
                                />
                            </SpacingRow>
                        }

                        {/* [INDICATOR] ENTERING TEXT */}
                        {(isOpen || !HasValues || !IsToggleable) &&
                            <CRMIcon
                                iconName={getTextAreaIcon()}
                                colour="hex"
                                hexColour={getTextAreaIconColor()}
                                size="18px"
                            />
                        }
                    </Absolute>
                </div>
            }

            {/* ITEMS */}
            {IsShowingItems &&
                <SpacingColumn>
                    {props.value.map((option, index) => (
                        <ValueItem
                            key={index}
                            number={index+1}
                            text={option.displayString}
                            disabled={IsDisabled || (props.disableDelete ?? false)}
                            onDelete={() => props.onDelete(option)}
                        />
                    ))}
                </SpacingColumn>
            }
        </div>
    );
};


type TValueItemProps = {
    number: number;
    text: string;
    disabled: boolean;
    onDelete: () => void;
}

const ValueItem = (props: TValueItemProps): JSX.Element => {

    const [ref, isHovering] = useHover<HTMLDivElement>({});

    return (
        <div
            ref={ref}
            className={`
                crm-input-list__option
                crm-input-list__option--${props.disabled ? 'disabled' : 'enabled'}
            `}
        >
        
            <Background 
                padding={`${CRMSpacing.MEDIUM} 42px ${CRMSpacing.MEDIUM} ${CRMSpacing.MEDIUM}`}
            >
                <SpacingRow
                    spacing={CRMSpacing.TINY}
                    alignItems="flex-start"
                >
                    <FontRoboto>
                        <Text size={14} lineHeight={1.4}>
                            {props.number}.
                        </Text>
                    </FontRoboto>

                    <pre className="crm-input-list__option-text">
                        {props.text}
                    </pre>
                </SpacingRow>
            </Background>

            {isHovering && !props.disabled &&
                <Absolute
                    top="18px"
                    right={CRMSpacing.MEDIUM}
                >
                    <CRMIcon
                        iconName="delete"
                        size={`18px`}
                        colour="hex"
                        hexColour={CRMColors.NEUTRAL_INK}
                        onClick={props.onDelete}
                    />
                </Absolute>
            }
        </div>
    );
}