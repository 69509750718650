import React from "react";
import { CRMFormButtonWithParagraph } from "../../components/CRMFormButtonWithParagraph/CRMFormButtonWithParagraph";
import { CRMFormButtonIcon } from "../../components/CRMFormButtonIcon/CRMFormButtonIcon";
import { CRMButtonIcon } from "../../components/CRMButtonIcon/CRMButtonIcon";
import { CRMHomeEnquiryPartyMemberForm } from "../../components/CRMHomeEnquiryPartyMemberForm/CRMHomeEnquiryPartyMemberForm";
import { CRMSpacer } from "../../components/CRMSpacer/CRMSpacer";
import { CRMHorizontalTabs } from "../../components/Complex/CRMHorizontalTabs/CRMHorizontalTabs";
import { CRMTitleSection } from "../../components/CRMTitleSection/CRMTitleSection";
import { TUser15AndC8FormList, TUser8Form, TUserPhoneNumber5AndC4FormList } from "../../models/TFormModels";
import * as TFormStatus from "../../models/TFormStatus";
import { makeFormUpdateAction } from "../../state/applyActions";
import * as TContainerStateProps from "../../state/TContainerStateProps";
import { CRMAutosaveIndicatorWrap } from "../../components/CRM/CRMAutosaveIndicatorWrap/CRMAutosaveIndicatorWrap";
import { pipe } from "fp-ts/lib/function";
import { array } from "fp-ts";

const getTabLabelFromPersonForm = (personForm: TUser8Form): string => {
    if (!personForm.edit.first_name && !personForm.edit.last_name) {
        return "New Person";
    }
    return `${personForm.edit.first_name} ${personForm.edit.last_name}`;
};

class CRMEnquiryPeopleContainer extends React.Component<React.PropsWithChildren<TContainerStateProps.TContainerStateProps>> {

    filterPhoneNumbersByPerson = (personForm: TUser15AndC8FormList["forms"][number]): TUserPhoneNumber5AndC4FormList["forms"] =>
        this.props.state.activeData.crm.enquiry.peoplePhoneNumbersList.forms
            .filter((number) => number.view.user_id === personForm.view.id);

    onOpenEnquiryInNewTab = (): void => {
        const enquiry = this.props.state.activeData.crm.enquiry.enquiriesRelatedToSearchedPartyFormList.forms[0];
        window.open(`/crm/enquiries/${enquiry.view.id}`);
    };

    public render(): JSX.Element {
        return (
            <CRMAutosaveIndicatorWrap status={
                pipe(
                    TFormStatus.reduceToHighestPriority(pipe(
                        [
                            ...this.props.state.activeData.crm.enquiry.peopleFormList.forms,
                            ...this.props.state.activeData.crm.enquiry.peoplePhoneNumbersList.forms,
                        ],
                        array.map((form) => form.status),
                    )),
                    (status) => status === "hidden" ? "untouched"
                        : status,
                )
            }>
                {/* TITLE */}
                <CRMTitleSection>People</CRMTitleSection>
                <CRMSpacer size="large" />
                {/* IF EMPTY: ADD PERSON BUTTON */}
                {!this.props.state.activeData.crm.enquiry.peopleFormList.forms.length &&
                    <CRMFormButtonWithParagraph
                        icon="person-add"
                        formStatus={this.props.state.activeData.crm.enquiry.createPersonForm.status}
                        paragraph="Add Person"
                        onClick={() => this.props.dispatch({
                            type: "CRM_ENQUIRY_NEW_PERSON_SUBMIT",
                        })}
                    />
                }
                {/* IF NOT EMPTY: TABS */}
                {this.props.state.activeData.crm.enquiry.peopleFormList.forms.length > 0 &&
                    <CRMHorizontalTabs
                        tabs={this.props.state.activeData.crm.enquiry.peopleFormList.forms.map((personForm) => ({
                            label: getTabLabelFromPersonForm(personForm),
                            hasErrors: personForm.status === TFormStatus.constants.VALIDATION_ERROR,
                            children: (
                                <>
                                    <CRMSpacer size="medium"/>
                                    <CRMHomeEnquiryPartyMemberForm
                                        existingEnquiries={this.props.state.activeData.crm.enquiry.enquiriesRelatedToSearchedPartyFormList.forms}
                                        createPhoneNumberStatus={this.props.state.activeData.crm.enquiry.createPhoneNumberForm.status}
                                        phoneNumbers={this.filterPhoneNumbersByPerson(personForm)}
                                        matchingParties={this.props.state.activeData.crm.enquiry.matchingPartiesFormList.forms}
                                        personForm={personForm}
                                        onChange={(value, key) =>
                                            this.props.dispatch(
                                                makeFormUpdateAction<"CRM_ENQUIRY_PERSON_INPUT_CHANGED", TUser15AndC8FormList["forms"][number]["edit"], typeof key>(
                                                    "CRM_ENQUIRY_PERSON_INPUT_CHANGED",
                                                    key,
                                                    value as string | null,
                                                    personForm.view.id
                                                )
                                            )
                                        }
                                        onOpenEnquiryInNewTab={() => this.onOpenEnquiryInNewTab()}
                                        onCloseMatchingEnquryPopup={() => this.props.dispatch({
                                            type: "CRM_CLOSE_MATCHING_ENQUIRY_POPUP",
                                            payload: null,
                                        })}
                                        onDisassociate={() => this.props.dispatch({
                                            type: "CRM_ENQUIRY_DISASSOCIATE_PERSON_SUBMIT",
                                            payload: personForm.view.id,
                                        })}
                                        onAddNewPhoneNumber={() => this.props.dispatch({
                                            type: "CRM_NEW_PHONE_NUMBER_SUBMIT",
                                            payload: personForm.view.id,
                                        })}
                                        onRemovePhoneNumber={(numberId) => this.props.dispatch({
                                            type: "CRM_DELETE_PHONE_NUMBER",
                                            payload: numberId,
                                        })}
                                        onChangePhoneNumber={(value, key, resourceId) =>
                                            this.props.dispatch(
                                                makeFormUpdateAction<"CRM_PHONE_NUMBER_INPUT_CHANGED", TUserPhoneNumber5AndC4FormList["forms"][number]["edit"], typeof key>(
                                                    "CRM_PHONE_NUMBER_INPUT_CHANGED",
                                                    key,
                                                    value as string | boolean,
                                                    resourceId
                                                )
                                            )
                                        }
                                        onUseParty={(partyId) => this.props.dispatch({
                                            type: "CRM_ENQUIRY_ASSOCIATE_PARTY",
                                            payload: partyId,
                                        })}
                                        onAddThisUser={(userId) => this.props.dispatch({
                                            type: "CRM_ENQUIRY_ASSOCIATE_USER",
                                            payload: userId,
                                        })}
                                    />
                                    <CRMSpacer size="medium"/>
                                </>
                            ),
                        }))}
                        buttons={[
                            {
                                label: <CRMFormButtonIcon
                                    formStatus={this.props.state.activeData.crm.enquiry.createPersonForm.status}
                                    ButtonElement={(props) => <CRMButtonIcon variant="primary" {...props} />}
                                    icon="person-add"
                                    onClick={() => this.props.dispatch({
                                        type: "CRM_ENQUIRY_NEW_PERSON_SUBMIT",
                                    })}
                                />,
                                side: "right",
                            },
                        ]}
                        goToNewTabs={true}
                    />
                }
            </CRMAutosaveIndicatorWrap>
        );
    }
}

export default CRMEnquiryPeopleContainer;
