import { array, option } from "fp-ts";
import { Ord } from "fp-ts/lib/Date";
import { pipe } from "fp-ts/lib/function";
import { contramap } from "fp-ts/lib/Ord";
import { DateTime } from "luxon";
import React, { useState } from "react";
import { TCaseChargeRedemptionCreateForm, TCaseChargeRedemptionForm, TCaseChargeRedemptionUploadForm } from "../../../../../domain/codecs/form/CaseChargeRedemptionForm";
import { CRMFontSizes } from "../../../models/CRMFontSizes";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { CRMColors } from "../../../models/CRMColors";
import { castNewFormStatusToLegacy } from "../../../util";
import { BorderBetween } from "../../BuildingBlocks/BorderBetween";
import { FontSize } from "../../BuildingBlocks/FontSize";
import { SpacingColumn } from "../../BuildingBlocks/SpacingColumn";
import { SpacingRow } from "../../BuildingBlocks/SpacingRow";
import { CRMButtonIcon } from "../../CRMButtonIcon/CRMButtonIcon";
import { CRMButtonTertiary } from "../../CRMButtonTertiary/CRMButtonTertiary";
import { CRMCardOutsidePopupFormSubmit } from "../../CRMCardOutsidePopupFormSubmit/CRMCardOutsidePopupFormSubmit";
import { CRMDeferButtonComponent } from "../../CRMDeferButtonComponent/CRMDeferButtonComponent";
import { CRMIcon } from "../../CRMIcon/CRMIcon";
import { CRMInputCalendarComponent } from "../../CRMInputCalendarComponent/CRMInputCalendarComponent";
import CRMInputLabelAndErrorWrapComponent from "../../CRMInputLabelAndErrorWrapComponent/CRMInputLabelAndErrorWrapComponent";
import { CRMLink } from "../../CRMLink/CRMLink";
import { CRMMultiUploadBox } from "../../CRMMultiUploadBox/CRMMultiUploadBox";
import { CRMNoticeBoxComponent } from "../../CRMNoticeBoxComponent/CRMNoticeBoxComponent";
import { CRMParagraph } from "../../Simple/CRMParagraph/CRMParagraph";
import { CursorPointer } from "../../CursorPointer/CursorPointer";
import { WeightBold } from "../../WeightBold/WeightBold";
import { WeightSemiBold } from "../../WeightSemiBold/WeightSemiBold";

export const CRMCaseChargeRedemptionUploads = (props: React.PropsWithChildren<{
    beneficiary: string,
    redemptionStatementFileForms: Array<TCaseChargeRedemptionForm>,
    createRedemptionStatementForm: TCaseChargeRedemptionCreateForm,
    deferRedemptionStatementJobValue: string | null,
    expectedCompletionDate: string | null,
    onDeleteFile: (value: TCaseChargeRedemptionForm) => void,
    onUpdateNewRedemption: (value: TCaseChargeRedemptionCreateForm) => void,
    onSubmitNewRedemption: (value: TCaseChargeRedemptionCreateForm) => void,
    onSubmitUpload: (value: TCaseChargeRedemptionUploadForm) => void,
    onDeferRedemptionStatementJobChange: (value: string | null) => void,
}>): JSX.Element => {
    const getExpiredRedemptionForms = () =>
        pipe(
            props.redemptionStatementFileForms,
            array.filter((form) => form.edited.download_token !== ""),
            array.filter((form) => DateTime.fromISO(form.edited.expires_at, {zone: "UTC"}).toSeconds()
                < DateTime.utc().set({hour: 0, minute: 0, second: 0, millisecond: 0}).toSeconds()),
        );

    const getActiveRedemptionForms = () =>
        pipe(
            props.redemptionStatementFileForms,
            array.filter((form) => form.edited.download_token !== ""),
            array.filter((form) => DateTime.fromISO(form.edited.expires_at, {zone: "UTC"}).toSeconds()
                >= DateTime.utc().set({hour: 0, minute: 0, second: 0, millisecond: 0}).toSeconds()),
        );

    const getUploadRedemptionForms = () =>
        pipe(
            props.redemptionStatementFileForms,
            array.filter((form) => form.edited.download_token === ""),
        );

    const setExpiryForNewRedemptionPopupIsOpen = (): boolean =>
        props.createRedemptionStatementForm.status === "untouched" ? false : true;

    const getLastRedemptionStatementToExpireDate = (): string | null =>
        pipe(
            props.redemptionStatementFileForms,
            array.map((form) => form.edited.expires_at),
            array.sortBy([contramap((isoDate: string) => DateTime.fromISO(isoDate).toJSDate())(Ord)]),
            array.last,
            option.fold(
                () => null,
                (iso) => iso,
            )
        );

    const shouldShowOrderRedemptionForCompletionDateNotice = (): boolean =>
        props.expectedCompletionDate
        && (
            getLastRedemptionStatementToExpireDate() === null
            || DateTime.fromISO(props.expectedCompletionDate).toSeconds() > DateTime.fromISO(getLastRedemptionStatementToExpireDate() || "").toSeconds()
        ) ? true : false;

    const hasUploadRedemptionForms = (): boolean =>
        getUploadRedemptionForms().length > 0;

    const hasActiveRedemptionForms = (): boolean =>
        getActiveRedemptionForms().length > 0;

    const hasExpiredRedemptionForms = (): boolean =>
        getExpiredRedemptionForms().length > 0;

    const hasAnyRedemptionForms = (): boolean =>
        hasUploadRedemptionForms()
        || hasActiveRedemptionForms()
        || hasExpiredRedemptionForms();

    return <SpacingColumn spacing={CRMSpacing.MEDIUM}>
        {hasAnyRedemptionForms() && <div>
            {/* UPLOAD REDEMPTION FORMS */}
            {hasUploadRedemptionForms() && <RedemptionSectionWrap>
                {getUploadRedemptionForms().map((form) =>
                    <UploadRedemption
                        key={form.edited.id}
                        form={form}
                        beneficiary={props.beneficiary}
                        onDelete={props.onDeleteFile}
                        onUpload={props.onSubmitUpload}
                    />
                )}
            </RedemptionSectionWrap>}

            {/* ACTIVE REDEMPTION FORMS */}
            {hasActiveRedemptionForms() && <RedemptionSectionWrap>
                {getActiveRedemptionForms().map((form) =>
                    <ActiveRedemption
                        key={form.edited.id}
                        form={form}
                        beneficiary={props.beneficiary}
                        onDelete={props.onDeleteFile}
                    />
                )}
            </RedemptionSectionWrap>}

            {/* EXPIRED REDEMPTION FORMS */}
            {hasExpiredRedemptionForms() && <RedemptionSectionWrap>
                {getExpiredRedemptionForms().map((form) =>
                    <ExpiredRedemption
                        key={form.edited.id}
                        form={form}
                        beneficiary={props.beneficiary}
                        onDelete={props.onDeleteFile}
                    />
                )}
            </RedemptionSectionWrap>}
        </div>}

        {/* REQUEST REDEMPTION STATEMENT FOR COMPLETION DATE NOTICE */}
        {shouldShowOrderRedemptionForCompletionDateNotice() && <CRMNoticeBoxComponent>
            An expected completion date of <WeightBold>{getExpiresDate(props.expectedCompletionDate || "")}</WeightBold> has been set,
            you should request a redemption statement that will be valid for this date.
        </CRMNoticeBoxComponent>}

        <SpacingRow
            spacing={CRMSpacing.MEDIUM}
            alignItems="flex-start"
        >
            {/* NEW REDEMPTION REQUEST */}
            <CRMButtonTertiary
                label="New request"
                onClick={() => props.onUpdateNewRedemption({
                    ...props.createRedemptionStatementForm,
                    status: "requiresSubmission",
                })}
            />

            {/* DEFER JOB CONTROLS */}
            <CRMDeferButtonComponent
                value={props.deferRedemptionStatementJobValue}
                displayError={false}
                onChange={props.onDeferRedemptionStatementJobChange}
                popoutPosition="above-button"
            />
        </SpacingRow>

        {/* SET EXPIRY FOR NEW REDEMPTION POPUP */}
        <CRMCardOutsidePopupFormSubmit
            isOpen={setExpiryForNewRedemptionPopupIsOpen()}
            title="Set expiry date"
            context="neutral"
            closeText="Cancel"
            ctaText="Save"
            formStatus={castNewFormStatusToLegacy(props.createRedemptionStatementForm.status)}
            validationErrors={[]}
            onClose={() => props.onUpdateNewRedemption({
                ...props.createRedemptionStatementForm,
                status: "untouched",
            })}
            onCTA={() => props.onSubmitNewRedemption(props.createRedemptionStatementForm)}
        >
            <CRMInputLabelAndErrorWrapComponent label="What date are you requesting this redemption statement for?">
                <CRMInputCalendarComponent
                    dateType="date"
                    value={props.createRedemptionStatementForm.edited.expires_at}
                    displayError={false}
                    onChange={(expires_at) => props.onUpdateNewRedemption({
                        ...props.createRedemptionStatementForm,
                        edited: {
                            ...props.createRedemptionStatementForm.edited,
                            expires_at,
                        }
                    })}
                    onPressEnterKey={() => undefined}
                />
            </CRMInputLabelAndErrorWrapComponent>
        </CRMCardOutsidePopupFormSubmit>
    </SpacingColumn>;
};

const getExpiresDate = (isoDate: string) =>
    DateTime.fromISO(isoDate).setZone("Europe/London").toFormat("d MMM");

const getRedemptionDownloadFileName = (beneficiary: string, expiresAt: string) =>
    `${beneficiary} redemption statement (expires ${getExpiresDate(expiresAt)})`;

const UploadRedemption = (props: {
    beneficiary: string,
    form: TCaseChargeRedemptionForm,
    onUpload: (value: TCaseChargeRedemptionUploadForm) => void,
    onDelete: (value: TCaseChargeRedemptionForm) => void,
}): JSX.Element => {
    const [deletePopupIsOpen, setDeletePopupIsOpen] = useState<boolean>(false);

    const uploadLabel = (): JSX.Element =>
        <CRMParagraph>
            <SpacingColumn spacing={CRMSpacing.TINY}>
                <WeightBold>
                    Upload statement
                </WeightBold>

                <FontSize size={CRMFontSizes.X_SMALL}>
                    <WeightSemiBold>For {getExpiresDate(props.form.edited.expires_at)},
                    </WeightSemiBold> requested {getExpiresDate(props.form.edited.requested_at)}.
                </FontSize>
            </SpacingColumn>
        </CRMParagraph>;

    const onUpload = (files: Array<File>) => {
        if (files.length !== 1) {
            return;
        }

        const indexOfLastFullStopInFileName = files[0].name.lastIndexOf(".");

        props.onUpload({
            ...props.form.children.upload_form,
            status: "requiresSubmission",
            input: {
                ...props.form.children.upload_form.input,
                file_extension: indexOfLastFullStopInFileName >= 0 ? files[0].name.slice(indexOfLastFullStopInFileName + 1) : "",
                mime_type: files[0].type === "" ? "text/plain" : files[0].type,
            },
            clientSideFileForUpload: files[0],
        });
    };

    return <>
        <SpacingRow
            spacing={CRMSpacing.MEDIUM}
            justifyContent="space-between"
            alignItems="flex-start"
            childSize="1fr auto"
        >
            {/* UPLOAD */}
            <CRMMultiUploadBox
                label={uploadLabel()}
                onFileChange={onUpload}
            />

            {/* DELETE */}
            <CRMButtonIcon
                variant="quaternary"
                icon="delete"
                onClick={() => setDeletePopupIsOpen(true)}
            />
        </SpacingRow>

        {/* DELETE POPUP */}
        <DeleteRedemptionPopup
            isOpen={deletePopupIsOpen}
            form={props.form}
            onDelete={(form) => {
                setDeletePopupIsOpen(false);
                props.onDelete(form);
            }}
            onCancel={() => setDeletePopupIsOpen(false)}
        />
    </>;
};

const ActiveRedemption = (props: {
    beneficiary: string,
    form: TCaseChargeRedemptionForm,
    onDelete: (value: TCaseChargeRedemptionForm) => void,
}): JSX.Element => {
    const [deletePopupIsOpen, setDeletePopupIsOpen] = useState<boolean>(false);

    return <>
        <SpacingRow
            spacing={CRMSpacing.TINY}
            justifyContent="start"
            alignItems="flex-start"
        >
            <SpacingColumn spacing={CRMSpacing.TINY}>
                {/* LINK */}
                <CRMLink
                    href={`${env.REACT_APP_API_URL}/web/legal-file-download?fileToken=${window.encodeURIComponent(props.form.edited.download_token)}&fileName=${window.encodeURIComponent(getRedemptionDownloadFileName(props.beneficiary, props.form.edited.expires_at))}`}
                    target="_blank"
                    linkStyle="normal"
                >
                    <WeightBold>Open {props.beneficiary} redemption statement</WeightBold>
                </CRMLink>

                {/* EXPIRES DATE */}
                <CRMParagraph>
                    Expires {getExpiresDate(props.form.edited.expires_at)}
                </CRMParagraph>
            </SpacingColumn>

            {/* DELETE */}
            <CursorPointer onClick={() => setDeletePopupIsOpen(true)}>
                <CRMIcon
                    iconName="delete"
                    colour="neutral-ink"
                    size="tiny"
                />
            </CursorPointer>
        </SpacingRow>

        {/* DELETE POPUP */}
        <DeleteRedemptionPopup
            isOpen={deletePopupIsOpen}
            form={props.form}
            onDelete={(form) => {
                setDeletePopupIsOpen(false);
                props.onDelete(form);
            }}
            onCancel={() => setDeletePopupIsOpen(false)}
        />
    </>;
};

const ExpiredRedemption = (props: {
    beneficiary: string,
    form: TCaseChargeRedemptionForm,
    onDelete: (value: TCaseChargeRedemptionForm) => void,
}): JSX.Element => {
    const [deletePopupIsOpen, setDeletePopupIsOpen] = useState<boolean>(false);

    return <>
        <SpacingRow
            spacing={CRMSpacing.TINY}
            justifyContent="start"
            alignItems="flex-start"
        >
            {/* LINK */}
            <CRMLink
                href={`${env.REACT_APP_API_URL}/web/legal-file-download?fileToken=${window.encodeURIComponent(props.form.edited.download_token)}&fileName=${window.encodeURIComponent(getRedemptionDownloadFileName(props.beneficiary, props.form.edited.expires_at))}`}
                target="_blank"
                linkStyle="normal"
            >
                Expired statement - {getExpiresDate(props.form.edited.expires_at)}
            </CRMLink>

            {/* DELETE */}
            <CursorPointer onClick={() => setDeletePopupIsOpen(true)}>
                <CRMIcon
                    iconName="delete"
                    colour="neutral-ink"
                    size="tiny"
                />
            </CursorPointer>
        </SpacingRow>

        {/* DELETE POPUP */}
        <DeleteRedemptionPopup
            isOpen={deletePopupIsOpen}
            form={props.form}
            onDelete={(form) => {
                setDeletePopupIsOpen(false);
                props.onDelete(form);
            }}
            onCancel={() => setDeletePopupIsOpen(false)}
        />
    </>;
};

const DeleteRedemptionPopup = (props: {
    isOpen: boolean,
    form: TCaseChargeRedemptionForm,
    onDelete: (value: TCaseChargeRedemptionForm) => void,
    onCancel: () => void;
}): JSX.Element =>
    <CRMCardOutsidePopupFormSubmit
        isOpen={props.isOpen}
        title="Are you sure?"
        context="warning"
        closeText="Cancel"
        ctaText="Yes, delete"
        formStatus="requiresSubmission"
        validationErrors={[]}
        onClose={() => props.onCancel()}
        onCTA={() => props.onDelete(props.form)}
    >
        <CRMParagraph>
            Are you sure you want to delete this redemption statement?
        </CRMParagraph>
    </CRMCardOutsidePopupFormSubmit>;

const RedemptionSectionWrap = (props: React.PropsWithChildren<{}>): JSX.Element =>
    <BorderBetween borderColour={CRMColors.NEUTRAL_8} spacing={CRMSpacing.MEDIUM}>
        <SpacingColumn spacing={CRMSpacing.MEDIUM}>
            {props.children}
        </SpacingColumn>
    </BorderBetween>;
