import React from "react";
import { FrontFontSizes } from "../../../../models/FrontFontSizes";
import { FontQuicksand } from "../../../BuildingBlocks/FontQuicksand";
import { FontSize } from "../../../BuildingBlocks/FontSize";

type TFrontInfoBubbleProps = {
    color: string;
    boxShadow?: boolean;
};

export const FrontInfoBubble = (props: React.PropsWithChildren<TFrontInfoBubbleProps>): JSX.Element => {

    return (
        <div
            className={`front-info-bubble`}
            style={{
                backgroundColor: props.color,
                boxShadow: props.boxShadow ? "1px 3px 2px 0px rgba(0,0,0,0.25)" : "none"
            }}
        >
            <FontQuicksand>
                <FontSize size={FrontFontSizes.MINOR} lineHeight="1.4">
                    {props.children}
                </FontSize>
            </FontQuicksand>
        </div>
    );
};
