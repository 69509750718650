import React from "react";
import { CRMCardOutside } from "../CRMCardOutside/CRMCardOutside";
import { CRMLink } from "../CRMLink/CRMLink";
import { CRMPadding } from "../Simple/CRMPadding/CRMPadding";
import { TCases1 } from "../../../../domain/codecs/Cases";
import { CRMColumns } from "../CRMColumns/CRMColumns";
import { CRMIconTrafficLight } from "../CRMIconTrafficLight/CRMIconTrafficLight";
import * as util from "../../../../shared/src/util";
import { CRMSpacer } from "../CRMSpacer/CRMSpacer";
import { CRMTextHighlight } from "../CRMTextHighlight/CRMTextHighlight";
import { TSessionUser } from "../../../../domain/codecs/User";

export const CRMCaseOverviewCard = (props: {
    case: TCases1;
    user: TSessionUser;
}): JSX.Element => {
    const shouldDisplayDocumentManagementIcon = (): boolean =>
        props.user.jobs.includes("document_management")
        || ! caseHasAnActionableTask()
        || props.user.jobs.length === 0;

    const shouldDisplayRaiseEnquiriesIcon = (): boolean =>
        props.user.jobs.includes("raise_enquiries")
        || ! caseHasAnActionableTask()
        || props.user.jobs.length === 0;

    const shouldDisplayApproveEnquiriesIcon = (): boolean =>
        props.user.jobs.includes("approve_enquiries")
        || ! caseHasAnActionableTask()
        || props.user.jobs.length === 0;

    const shouldDisplaySendEnquiriesIcon = (): boolean =>
        props.user.jobs.includes("send_enquiries")
        || ! caseHasAnActionableTask()
        || props.user.jobs.length === 0;

    const shouldDisplayResolveEnquiriesIcon = (): boolean =>
        props.user.jobs.includes("resolve_enquiries")
        || ! caseHasAnActionableTask()
        || props.user.jobs.length === 0;

    const caseHasAnActionableTask = (): boolean =>
        props.case.documents_status === "requires_action"
        || props.case.raise_enquiries_status === "requires_action"
        || props.case.approve_enquiries_status === "requires_action"
        || props.case.send_enquiries_status === "requires_action"
        || props.case.resolve_enquiries_status === "requires_action"
            ? true
            : false;

    return <div className="crm-case-overview-card">
        <CRMCardOutside
            borderRounding="right"
            shadow={true}
        >
            <CRMPadding size="medium">
                <CRMColumns
                    padding="medium"
                    wrapOnMobile={false}
                    alignItems="center"
                    columns={[
                        {
                            flex: 1,
                            content: <CRMLink
                                href={`/crm/legal/cases/${props.case.id}`}
                                linkStyle="normal"
                            >
                                {props.case.address}
                            </CRMLink>,
                        },
                        {
                            flex: 0,
                            content: <div className="crm-case-overview-card__icon-container">
                                {/* Document management */}
                                {shouldDisplayDocumentManagementIcon() && <div>
                                    <CRMIconTrafficLight
                                        title="Documents"
                                        iconName="document"
                                        status={
                                            props.case.documents_status === "no_action_required" ? "green"
                                            : props.case.documents_status === "requires_action" ? "red"
                                            : util.requireExhaustive(props.case.documents_status)
                                        }
                                        size="medium"
                                    />
                                </div>}

                                {/* Raise enquiries */}
                                {shouldDisplayRaiseEnquiriesIcon() && <div>
                                    <CRMIconTrafficLight
                                        title="Raising enquiries"
                                        iconName="sign"
                                        status={
                                            props.case.raise_enquiries_status === "no_action_required" ? "green"
                                            : props.case.raise_enquiries_status === "requires_action" ? "red"
                                            : util.requireExhaustive(props.case.raise_enquiries_status)
                                        }
                                        size="medium"
                                    />
                                </div>}

                                {/* Approve enquiries */}
                                {shouldDisplayApproveEnquiriesIcon() && <div>
                                    <CRMIconTrafficLight
                                        title="Approve enquiries"
                                        iconName="done"
                                        status={
                                            props.case.approve_enquiries_status === "no_action_required" ? "green"
                                            : props.case.approve_enquiries_status === "requires_action" ? "red"
                                            : util.requireExhaustive(props.case.approve_enquiries_status)
                                        }
                                        size="medium"
                                    />
                                </div>}

                                {/* Send enquiries */}
                                {shouldDisplaySendEnquiriesIcon() && <div>
                                    <CRMIconTrafficLight
                                        title="Send enquiries"
                                        iconName="email-outbound"
                                        status={
                                            props.case.send_enquiries_status === "no_action_required" ? "green"
                                            : props.case.send_enquiries_status === "requires_action" ? "red"
                                            : util.requireExhaustive(props.case.send_enquiries_status)
                                        }
                                        size="medium"
                                    />
                                </div>}

                                {/* Resolve enquiries */}
                                {shouldDisplayResolveEnquiriesIcon() && <div>
                                    <CRMIconTrafficLight
                                        title="Resolving enquiries"
                                        iconName="done-all"
                                        status={
                                            props.case.resolve_enquiries_status === "no_action_required" ? "green"
                                            : props.case.resolve_enquiries_status === "requires_action" ? "red"
                                            : util.requireExhaustive(props.case.resolve_enquiries_status)
                                        }
                                        size="medium"
                                    />
                                </div>}
                            </div>
                        }
                    ]}
                />

                {/* Case without actionable tasks */}
                {!caseHasAnActionableTask() && <div>
                    <CRMSpacer size="tiny" />
                    <div className="crm-case-overview-card__help-title">
                        <CRMTextHighlight colour="highlights-instructional-brick-6">
                            Requires your help
                        </CRMTextHighlight>
                    </div>
                    <CRMSpacer size="tiny" />
                    <div className="crm-case-overview-card__help-explainer">
                        We&apos;re showing you this because the case doesn&apos;t have any actionable tasks.
                    </div>
                </div>}
            </CRMPadding>
        </CRMCardOutside>
    </div>;
};
