import { array } from "fp-ts";
import { pipe } from "fp-ts/lib/function";
import React, {  } from "react";
import { QuoteRateFeeTransferValue, TQuoteRateFeeTransferValue } from "../../../../../domain/codecs/QuoteRateFee";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { SpacingColumn } from "../../BuildingBlocks/SpacingColumn";
import { SpacingRow } from "../../BuildingBlocks/SpacingRow";
import { CRMButtonTertiary } from "../../CRMButtonTertiary/CRMButtonTertiary";
import { CRMNoticeBoxComponent } from "../../CRMNoticeBoxComponent/CRMNoticeBoxComponent";
import { WeightMedium } from "../../WeightMedium/WeightMedium";
import { WeightSemiBold } from "../../WeightSemiBold/WeightSemiBold";
import { CRMQuoteRatesSingleViewEditTransferValueFeesVariableItem } from "./CRMQuoteRatesSingleViewEditTransferValueFeesVariableItem";

export const CRMQuoteRatesSingleViewEditTransferValueFeesVariable = (props: React.PropsWithChildren<{
    transfer_value_fees: Array<TQuoteRateFeeTransferValue>,
    displayError: boolean,
    hasMissingFeeBand: boolean,
    onChange: (transfer_value_fees: Array<TQuoteRateFeeTransferValue>) => void,
}>): JSX.Element => {
    const onFeeChange = (feeIndex: number) => (value: TQuoteRateFeeTransferValue) => {
        const transfer_value_fees = [...props.transfer_value_fees];
        transfer_value_fees[feeIndex] = value;
        props.onChange(transfer_value_fees);
    };

    const onDeleteFee = (feeIndex: number) =>
        pipe(
            props.transfer_value_fees,
            array.filterWithIndex((i) => i !== feeIndex),
            props.onChange
        );

    const onAddFee = () =>
        pipe(
            [
                ...props.transfer_value_fees,
                QuoteRateFeeTransferValue.newDefault(),
            ],
            props.onChange
        );

    return <SpacingColumn spacing={CRMSpacing.MEDIUM}>
        {/* BANDS */}
        {pipe(
            props.transfer_value_fees,
            array.mapWithIndex((i, fee) =>
                <CRMQuoteRatesSingleViewEditTransferValueFeesVariableItem
                    key={i}
                    fee={fee}
                    displayError={props.displayError}
                    canDelete={i > 0}
                    onChange={onFeeChange(i)}
                    onDelete={() => onDeleteFee(i)}
                />
            )
        )}

        {/* MISSING FEE WARNING */}
        {props.hasMissingFeeBand &&
            <CRMNoticeBoxComponent>
                <WeightSemiBold>
                    You have a gap in your fees!&nbsp;
                </WeightSemiBold>

                <WeightMedium>
                    If this isn't intentional it could result in a client not being charged correctly.
                </WeightMedium>
            </CRMNoticeBoxComponent>
        }

        {/* ADD ANOTHER */}
        <SpacingRow justifyContent="center">
            <CRMButtonTertiary
                label="Add another variation"
                onClick={() => onAddFee()}
            />
        </SpacingRow>
    </SpacingColumn>
}
