import { tap, switchMap, debounceTime, filter } from "rxjs/operators";
import { formOperation } from "../../wrappers/formOperation";
import { TActionsDefinitionsList } from "./TAction";
import { action, routeAction } from "./actionFunctions";
import { forkJoin, Observable, of } from "rxjs";
import { TStateCodec } from "../../../../domain/codecs/state";
import { TCodecLens } from "../../../../shared/src/codecs/codecLens";
import { TCodecSetState, TGetCodecState } from "../applyActions";
import { ProbateHelplineCallbackTypeForm, TProbateHelplineCallbackForm, TProbateHelplineCallbackFormCodec, TProbateHelplineCallbackMarkCompleteForm, TProbateHelplineCallbackMarkCompleteFormCodec, TProbateHelplineCallbackTypeFormCodec, TProbateHelplineCallbackTypeVisible } from "../../../../domain/codecs/form/ProbateHelplineCallbackForm";
import { requireExhaustive } from "../../../../shared/src/util";

type TGetRequestName =
    "GetProbateHelplineCallbacksNow"
    | "GetProbateHelplineCallbacksCompleted";

export const reload$ = (
    lens: TCodecLens<TProbateHelplineCallbackTypeFormCodec, TStateCodec>,
    getRequestName: TGetRequestName,
    set: TCodecSetState,
    get: TGetCodecState,
    pagination?: {
        offset: number,
        limit: number,
    },
) =>
    of(
        // If an offset and limit has been provided we will use it, otherwise reload the entire visible list
        // (offset: 0, limit: number of introducers in the array)
        set(lens.edited.set({
            offset: pagination?.offset || 0,
            limit: pagination?.limit || (
                lens.children.callbacks.get()(get()).length < 20
                    ? 20
                    : lens.children.callbacks.get()(get()).length
            ),
        }))
    )
        .pipe(
            switchMap(() => formOperation(getRequestName, lens.get()(get()))),
            tap((response) => set(lens.set({
                ...response,
                children: {
                    ...response.children,
                    callbacks:
                        // If an offset has been provided then a paging action is being taken
                        // We will therefor merge all the new introducers onto the array of introducers we have already requested
                        pagination?.offset
                            ? [
                                ...lens.children.callbacks.get()(get()),
                                ...response.children.callbacks,
                            ]
                            : response.children.callbacks,
                }
            }))),
        );

export const update$ = (
    obs$: Observable<TProbateHelplineCallbackForm>,
    lens: TCodecLens<TProbateHelplineCallbackTypeFormCodec, TStateCodec>,
    set: TCodecSetState,
    get: TGetCodecState,
) =>
    obs$.pipe(
        tap((form) => set(lens.children.callbacks.where(({edited}) => edited.id === form.edited.id).set({
            ...form,
            status: "requiresSubmission",
        }))),
        debounceTime(1000),
        switchMap((form) => formOperation("UpdateProbateHelplineCallback", lens.children.callbacks.where(({edited}) => edited.id === form.edited.id).get()(get()))),
        tap((response) => set(lens.children.callbacks.where(({edited}) => edited.id === response.edited.id).set(response))),
    );

export const markComplete$ = (
    obs$: Observable<TProbateHelplineCallbackMarkCompleteForm>,
    lens: TCodecLens<TProbateHelplineCallbackTypeFormCodec, TStateCodec>,
    getRequestName: TGetRequestName,
    set: TCodecSetState,
    get: TGetCodecState,
) =>
    obs$.pipe(
        tap((form) => set(lens.children.callbacks.where(({edited}) => edited.id === form.edited.id).children.mark_callback_complete_form.set({
            ...form,
            status: "submitting",
        }))),
        switchMap((form) => formOperation("SetProbateHelplineCallbackComplete", lens.children.callbacks.where(({edited}) => edited.id === form.edited.id).children.mark_callback_complete_form.get()(get()))),
        tap((response) => set(lens.children.callbacks.where(({edited}) => edited.id === response.edited.id).children.mark_callback_complete_form.set(response))),
        filter(({status}) => status === "success"),
        switchMap(() => reload$(lens, getRequestName, set, get)),
    );

export const loadMore$ = (
    obs$: Observable<undefined>,
    lens: TCodecLens<TProbateHelplineCallbackTypeFormCodec, TStateCodec>,
    getRequestName: TGetRequestName,
    set: TCodecSetState,
    get: TGetCodecState,
) =>
    obs$.pipe(
        switchMap(() => reload$(lens, getRequestName, set, get, {
            offset: lens.edited.offset.get()(get()) + 20,
            limit: 20,
        }))
    );

export const actions: TActionsDefinitionsList = [
    routeAction("VIEW_CRM_PROBATE_HELPLINE_CALLBACKS", (obs$, lens, set, get) => {
        obs$.pipe(
            tap(() => set(lens.probate_helpline_callbacks_page.set({
                active_type_visible: "NOW",
                now: ProbateHelplineCallbackTypeForm.newDefault(),
                completed: ProbateHelplineCallbackTypeForm.newDefault(),
            }))),
            switchMap(() =>
                forkJoin([
                    reload$(lens.probate_helpline_callbacks_page.now, "GetProbateHelplineCallbacksNow", set, get, {offset: 0, limit: 20}),
                    reload$(lens.probate_helpline_callbacks_page.completed, "GetProbateHelplineCallbacksCompleted", set, get, {offset: 0, limit: 20})
                ])
            ),
        ).subscribe();
    }),
    action("CRM_PROBATE_HELPLINE_CALLBACK_ACTIVE_TYPE_VISIBLE_CHANGE", (obs$: Observable<TProbateHelplineCallbackTypeVisible>, lens, set, get) => {
        obs$.pipe(
            tap((type) => set(lens.probate_helpline_callbacks_page.active_type_visible.set(type))),
            switchMap((type) =>
                type === "NOW" ? reload$(lens.probate_helpline_callbacks_page.now, "GetProbateHelplineCallbacksNow", set, get)
                : type === "COMPLETED" ? reload$(lens.probate_helpline_callbacks_page.completed, "GetProbateHelplineCallbacksCompleted", set, get)
                : requireExhaustive(type)
            ),
        ).subscribe();
    }),

    // Type: NOW
    action("CRM_PROBATE_HELPLINE_CALLBACK_NOW_UPDATE", (obs$: Observable<TProbateHelplineCallbackForm>, lens, set, get) => {
        update$(obs$, lens.probate_helpline_callbacks_page.now, set, get).subscribe();
    }),
    action("CRM_PROBATE_HELPLINE_CALLBACK_NOW_MARK_COMPLETE", (obs$: Observable<TProbateHelplineCallbackMarkCompleteForm>, lens, set, get) => {
        markComplete$(obs$, lens.probate_helpline_callbacks_page.now, "GetProbateHelplineCallbacksNow", set, get).subscribe();
    }),
    action("CRM_PROBATE_HELPLINE_CALLBACK_NOW_SINGLE_VIEW_CLOSED", (obs$: Observable<undefined>, lens, set, get) => {
        obs$.pipe(
            switchMap(() => reload$(lens.probate_helpline_callbacks_page.now, "GetProbateHelplineCallbacksNow", set, get)),
        ).subscribe();
    }),
    action("CRM_PROBATE_HELPLINE_CALLBACK_NOW_LOAD_MORE", (obs$: Observable<undefined>, lens, set, get) => {
        loadMore$(obs$, lens.probate_helpline_callbacks_page.now, "GetProbateHelplineCallbacksNow", set, get).subscribe();
    }),

    // Type: COMPLETED
    action("CRM_PROBATE_HELPLINE_CALLBACK_COMPLETED_UPDATE", (obs$: Observable<TProbateHelplineCallbackForm>, lens, set, get) => {
        update$(obs$, lens.probate_helpline_callbacks_page.completed, set, get).subscribe();
    }),
    action("CRM_PROBATE_HELPLINE_CALLBACK_COMPLETED_MARK_COMPLETE", (obs$: Observable<TProbateHelplineCallbackMarkCompleteForm>, lens, set, get) => {
        markComplete$(obs$, lens.probate_helpline_callbacks_page.completed, "GetProbateHelplineCallbacksCompleted", set, get).subscribe();
    }),
    action("CRM_PROBATE_HELPLINE_CALLBACK_COMPLETED_SINGLE_VIEW_CLOSED", (obs$: Observable<undefined>, lens, set, get) => {
        obs$.pipe(
            switchMap(() => reload$(lens.probate_helpline_callbacks_page.completed, "GetProbateHelplineCallbacksCompleted", set, get)),
        ).subscribe();
    }),
    action("CRM_PROBATE_HELPLINE_CALLBACK_COMPLETED_LOAD_MORE", (obs$: Observable<undefined>, lens, set, get) => {
        loadMore$(obs$, lens.probate_helpline_callbacks_page.completed, "GetProbateHelplineCallbacksCompleted", set, get).subscribe();
    }),
];
