import { pipe } from "fp-ts/lib/function";
import { TStaffUser2 } from "../../../../../domain/codecs/User";
import { TRoadblockAwaitableEntity, TRoadblockForm } from "../../../../../domain/codecs/form/RoadblockForm"
import { array } from "fp-ts";
import { TCommand } from "../CRMRoadblockRichTextArea/CRMRoadblockRichTextArea";

const BaseCommands: Array<TCommand> = [
    {
        action: "add-check-item"
    }
];

export const createRoadblockCommandList = (form: TRoadblockForm): Array<TCommand> => {
    const Participants = form.children.participants;
    const AwaitableEntities = form.children.awaitable_entities || [];
    return array.flatten([
        BaseCommands,
        createCommandPerParticipant("assign", Participants),
        createCommandPerAwaitableEntity(AwaitableEntities),
    ]);
}

const createCommandPerParticipant = (action: "assign", participants: Array<TStaffUser2>): Array<TCommand> =>
    pipe(
        participants,
        array.filter(({ crm_username }) => crm_username.length > 0),    
        array.map(({ crm_username }) => ({
            action,
            target: crm_username
        }))
    )
;

const createCommandPerAwaitableEntity = (entities: Array<TRoadblockAwaitableEntity>): Array<TCommand> =>
    pipe(
        entities,
        array.map((entity) => ({
            action: "await",
            target: entity.name
        }))
    )
;