import React from "react";
import { Background } from "../../../BuildingBlocks/Background";
import { CRMColors } from "../../../../models/CRMColors";
import { FrontColors } from "../../../../models/FrontColors";
import { FrontSpacing } from "../../../../models/FrontSpacing";
import { SpacingRow } from "../../../BuildingBlocks/SpacingRow";
import { FontRoboto } from "../../../BuildingBlocks/FontRoboto";
import { Text } from "../../../BuildingBlocks/Text";
import { FrontFontSizes } from "../../../../models/FrontFontSizes";
import { FrontIcon } from "../../../Front/Simple/FrontIcon/FrontIcon";
import { CursorPointer } from "../../../CursorPointer/CursorPointer";
import { Width } from "../../../BuildingBlocks/Width";

export const FPPrintReport = (props: {}): JSX.Element => {
    const onClick = () => window.print();

    return <Width width="100%">
        <CursorPointer onClick={onClick}>
            <Background
                borderRadius="8px"
                borderDefinition={`solid 2px ${CRMColors.PRIMARY_2}`}
                color={FrontColors.NEUTRAL_18}
                padding={`${FrontSpacing.SMALL_3} 25px`}
            >
                <SpacingRow
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={FrontSpacing.SMALL_3}
                >
                    {/* TEXT */}
                    <FontRoboto>
                        <Text
                            color={CRMColors.PRIMARY_2}
                            lineHeight={1.5}
                            size={FrontFontSizes.X_SMALL}
                            weight={700}
                        >
                            Print Report
                        </Text>
                    </FontRoboto>

                    {/* ICON */}
                    <FrontIcon
                        iconName="print"
                        colour={CRMColors.PRIMARY_2}
                        size="large"
                    />
                </SpacingRow>
            </Background>
        </CursorPointer>
    </Width>;
};
