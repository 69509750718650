import React, { CSSProperties } from "react";
import { TPercentageSize, TPixelSize } from "../../models/StringLiterals";
import { Position } from "./Position";

type TAbsoluteProps = {
    divRef?: React.RefObject<HTMLDivElement>,
    top?: string;
    bottom?: string;
    left?: string;
    right?: string;
    zIndex?: CSSProperties["zIndex"];
    display?: CSSProperties["display"];
    width?: TPixelSize | TPercentageSize;
    height?: TPixelSize | TPercentageSize;
};

export const Absolute = (props: React.PropsWithChildren<TAbsoluteProps>): JSX.Element => {
    return (
        <Position
            position="absolute"
            {...props}
        />
    );
};
