import React from "react";
import { TIntroducerExistingAgreementForm, TIntroducerForm, TIntroducerUploadAgreementForm } from "../../../../../domain/codecs/form/IntroducerForm";
import { onChangeForm } from "../../../../../shared/src/codecs/types/form";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { Padding } from "../../BuildingBlocks/Padding";
import { SpacingColumn } from "../../BuildingBlocks/SpacingColumn";
import { CRMDropdownComponent } from "../../CRMDropdownComponent/CRMDropdownComponent";
import { CRMInputCalendarComponent } from "../../CRMInputCalendarComponent/CRMInputCalendarComponent";
import { CRMInputCurrency } from "../../CRMInputCurrency/CRMInputCurrency";
import CRMInputGeneralComponent from "../../CRMInputs/CRMInputGeneralComponent/CRMInputGeneralComponent";
import CRMInputLabelAndErrorWrapComponent from "../../CRMInputLabelAndErrorWrapComponent/CRMInputLabelAndErrorWrapComponent";
import { CRMMultiUploadBox } from "../../CRMMultiUploadBox/CRMMultiUploadBox";
import { CRMRadioListComponent } from "../../CRMInputs/CRMRadioListComponent/CRMRadioListComponent";
import { CRMTitleSubSection } from "../../CRMTitleSubSection/CRMTitleSubSection";
import { CRMUploadedBox } from "../../Simple/CRMUploadedBox/CRMUploadedBox";
import { getIntroducerStatus } from "../CRMIntroducerSingleView/CRMIntroducerSingleView";
import { defaultSearchProviderFullName } from "../../../../../domain/defaultSearchProvider";
import { CRMInputPercentage } from "../../CRMInputPercentage/CRMInputPercentage";

type TIntroducerFormProps = {
    form: TIntroducerForm;
    onDeleteUploadAgreement: (form: TIntroducerExistingAgreementForm) => void;
    onUploadAgreement: (form: TIntroducerUploadAgreementForm) => void;
    onChange: (form: TIntroducerForm) => void;
};

export const CRMIntroducerForm = (props: React.PropsWithChildren<TIntroducerFormProps>): JSX.Element => {

    const onChange = onChangeForm(props.form, props.onChange);

    const onDeleteUploadAgreement = () =>
        props.onDeleteUploadAgreement(props.form.children.existing_agreement_form as TIntroducerExistingAgreementForm)
    ;

    const onUploadAgreement = (files: Array<File>) => {

        if (files.length !== 1) {
            return;
        }

        const indexOfLastFullStopInFileName = files[0].name.lastIndexOf(".");

        props.onUploadAgreement({
            ...props.form.children.upload_form,
            status: "requiresSubmission",
            input: {
                ...props.form.children.upload_form.input,
                file_extension: indexOfLastFullStopInFileName >= 0 ? files[0].name.slice(indexOfLastFullStopInFileName + 1) : "",
                mime_type: files[0].type === "" ? "text/plain" : files[0].type,
            },
            clientSideFileForUpload: files[0],
        });
    };

    return (
        <Padding 
            width="100%" 
            type="bottom" 
            spacing={"60px"}
        >
            <SpacingColumn spacing={CRMSpacing.X_LARGE}>
                
                {/* SECTION - BASIC INFO */}
                <SpacingColumn spacing={CRMSpacing.MEDIUM}>
                    {/* INPUT - NAME */}
                    <CRMInputLabelAndErrorWrapComponent
                        label="Introducer name"
                    >
                        <CRMInputGeneralComponent
                            value={props.form.edited.name}
                            inputType="text"
                            placeholder="Name"
                            onChange={onChange("name")}
                            displayError={false} 
                        />
                    </CRMInputLabelAndErrorWrapComponent>

                    {/* INPUT - KLYANT CLIENT ID */}
                    <CRMInputLabelAndErrorWrapComponent
                        label="Klyant client ID"
                    >
                        <CRMInputGeneralComponent
                            value={props.form.edited.klyant_client_id}
                            inputType="text"
                            placeholder="ID"
                            onChange={onChange("klyant_client_id")}
                            displayError={false} 
                        />
                    </CRMInputLabelAndErrorWrapComponent>

                    {/* INPUT - REFERRAL FEE & UPLOAD */}
                    <CRMInputLabelAndErrorWrapComponent
                        label="Is this introducer being paid a referral fee?"
                    >
                        <CRMRadioListComponent
                            value={props.form.edited.is_paid_referral_fee}
                            options={[
                                { value: true, text: "Yes" },
                                { value: false, text: "No" },
                            ]}
                            onChange={onChange("is_paid_referral_fee")}
                        />
                    </CRMInputLabelAndErrorWrapComponent>

                    {props.form.edited.is_paid_referral_fee &&
                        <>
                            {getIntroducerStatus(props.form) === "no-agreement-signed" &&
                                <CRMMultiUploadBox
                                    label="Upload Signed Agreement"
                                    onFileChange={onUploadAgreement}
                                />
                            }

                            {(getIntroducerStatus(props.form) === "agreement-signed"
                                || getIntroducerStatus(props.form) === "agreement-requires-review")
                                && <CRMUploadedBox
                                    label="Agreement Document"
                                    onDelete={onDeleteUploadAgreement}
                                    link={props.form.children.existing_agreement_form?.children.download_url || ""} 
                                />
                            }
                        </>
                    }
                    
                    {/* INPUT - NEXT REVIEW DATE */}
                    {!!props.form.children.existing_agreement_form?.edited.id &&
                        <CRMInputLabelAndErrorWrapComponent
                            label="Next review due date"
                        >
                            <CRMInputCalendarComponent
                                placeholder="Select date"
                                dateType="date"
                                value={props.form.edited.referral_agreement_next_review_date || ""}
                                displayError={false}
                                onChange={onChange("referral_agreement_next_review_date")}
                                onPressEnterKey={() => null}
                            />
                        </CRMInputLabelAndErrorWrapComponent>
                    }

                    {/* INPUT - ABORTIVE COSTS */}
                    <CRMInputLabelAndErrorWrapComponent
                        label="Could a case referred to us by this introducer incur abortive costs?"
                    >
                        <CRMRadioListComponent
                            value={props.form.edited.will_charge_abortive_costs}
                            options={[
                                { value: true, text: "Yes" },
                                { value: false, text: "No" },
                            ]}
                            onChange={onChange("will_charge_abortive_costs")}
                        />
                    </CRMInputLabelAndErrorWrapComponent>
                </SpacingColumn>

                {/* SECTION - DEFAULT REFERRAL FEES */}
                {props.form.edited.is_paid_referral_fee &&
                    <SpacingColumn spacing={CRMSpacing.MEDIUM}>
                        <CRMTitleSubSection>
                            Default referral fees
                        </CRMTitleSubSection>

                        {/* INPUT - DEFAULT REFERRAL FEE - PURCHASE */}
                        <CRMInputLabelAndErrorWrapComponent
                            label="What is the default fee we pay for a purchase?"
                        >
                            <CRMInputCurrency
                                value={props.form.edited["default_referral_fee_pence--purchase"] || null}
                                mode="pence"
                                onChange={(value) => onChange("default_referral_fee_pence--purchase")(value || 0)}
                            />
                        </CRMInputLabelAndErrorWrapComponent>

                        {/* INPUT - DEFAULT REFERRAL FEE - SALE */}
                        <CRMInputLabelAndErrorWrapComponent
                            label="What is the default fee we pay for a sale?"
                        >
                            <CRMInputCurrency
                                value={props.form.edited["default_referral_fee_pence--sale"] || null}
                                mode="pence"
                                onChange={(value) => onChange("default_referral_fee_pence--sale")(value || 0)}
                            />
                        </CRMInputLabelAndErrorWrapComponent>

                        {/* INPUT - DEFAULT REFERRAL FEE - TRANSFER */}
                        <CRMInputLabelAndErrorWrapComponent
                            label="What is the default fee we pay for a transfer?"
                        >
                            <CRMInputCurrency
                                value={props.form.edited["default_referral_fee_pence--transfer"] || null}
                                mode="pence"
                                onChange={(value) => onChange("default_referral_fee_pence--transfer")(value || 0)}
                            />
                        </CRMInputLabelAndErrorWrapComponent>

                        {/* INPUT - DEFAULT REFERRAL FEE - REMORTGAGE */}
                        <CRMInputLabelAndErrorWrapComponent
                            label="What is the default fee we pay for a remortgage?"
                        >
                            <CRMInputCurrency
                                value={props.form.edited["default_referral_fee_pence--remortgage"] || null}
                                mode="pence"
                                onChange={(value) => onChange("default_referral_fee_pence--remortgage")(value || 0)}
                            />
                        </CRMInputLabelAndErrorWrapComponent>

                        {/* INPUT - SAIL HOMES SALE REFERRAL FEE PERCENTAGE */}
                        <CRMInputLabelAndErrorWrapComponent
                            label="What is the Sail Homes accepted offer referral percentage?"
                        >
                            <CRMInputPercentage
                                value={props.form.edited.sail_homes_sale_referral_percentage || null}
                                onChange={(value) => onChange("sail_homes_sale_referral_percentage")(value || 0)}
                            />
                        </CRMInputLabelAndErrorWrapComponent>
                    </SpacingColumn>
                }
                
                {/* SECTION - MONEY ON ACCOUNT */}
                <SpacingColumn spacing={CRMSpacing.MEDIUM}>
                    <CRMTitleSubSection>
                        Client Initial payment amounts (money on account)
                    </CRMTitleSubSection>

                    {/* INPUT - PURCHASE */}
                    <CRMInputLabelAndErrorWrapComponent
                        label="Purchase"
                    >
                        <CRMInputCurrency
                            value={props.form.edited.initial_payment_purchase_amount_pence}
                            mode="pence"
                            onChange={onChange("initial_payment_purchase_amount_pence")}
                        />
                    </CRMInputLabelAndErrorWrapComponent>
                    
                    {/* INPUT - TRANSFER */}
                    <CRMInputLabelAndErrorWrapComponent
                        label="Transfer"
                    >
                        <CRMInputCurrency
                            value={props.form.edited.initial_payment_transfer_amount_pence}
                            mode="pence"
                            onChange={onChange("initial_payment_transfer_amount_pence")}
                        />
                    </CRMInputLabelAndErrorWrapComponent>

                    {/* INPUT - REMORTGAGE */}
                    <CRMInputLabelAndErrorWrapComponent
                        label="Remortgage"
                    >
                        <CRMInputCurrency
                            value={props.form.edited.initial_payment_remortgage_amount_pence}
                            mode="pence"
                            onChange={onChange("initial_payment_remortgage_amount_pence")}
                        />
                    </CRMInputLabelAndErrorWrapComponent>
                    
                    {/* INPUT - SALE (WITH LEASEHOLD) */}
                    <CRMInputLabelAndErrorWrapComponent
                        label="Sale (with leasehold)"
                    >
                        <CRMInputCurrency
                            value={props.form.edited.initial_payment_sale_with_leasehold_amount_pence}
                            mode="pence"
                            onChange={onChange("initial_payment_sale_with_leasehold_amount_pence")}
                        />
                    </CRMInputLabelAndErrorWrapComponent>
                    
                    {/* INPUT - SALE (WITHOUT LEASEHOLD) */}
                    <CRMInputLabelAndErrorWrapComponent
                        label="Sale (without leasehold)"
                    >
                        <CRMInputCurrency
                            value={props.form.edited.initial_payment_sale_without_leasehold_amount_pence}
                            mode="pence"
                            onChange={onChange("initial_payment_sale_without_leasehold_amount_pence")}
                        />
                    </CRMInputLabelAndErrorWrapComponent>

                    {/* INPUT - SALE (WITHOUT LEASEHOLD) */}
                    <CRMInputLabelAndErrorWrapComponent
                        label="Additional amount when sale ordering searches"
                    >
                        <CRMInputCurrency
                            value={props.form.edited.initial_payment_additional_seller_searches_amount_pence}
                            mode="pence"
                            onChange={onChange("initial_payment_additional_seller_searches_amount_pence")}
                        />
                    </CRMInputLabelAndErrorWrapComponent>
                </SpacingColumn>


                {/* SECTION - SEARCHES */}
                <SpacingColumn spacing={CRMSpacing.MEDIUM}>
                    <CRMTitleSubSection>
                        Searches
                    </CRMTitleSubSection>

                    {/* INPUT - SEARCH ORIGIN */}
                    <CRMInputLabelAndErrorWrapComponent
                        label="Where are the searches ordered from?"
                    >
                        <CRMDropdownComponent
                            value={props.form.edited.search_provider}
                            options={[
                                { label: `Our default provider (${defaultSearchProviderFullName})`, value: "default" },
                                { label: "Somewhere Else", value: "somewhere_else" },
                            ]}
                            displayError={false}
                            onChange={onChange("search_provider")}
                        />
                    </CRMInputLabelAndErrorWrapComponent>
                    
                    {/* INPUT - SEARCH PROVIDER NAME */}
                    {props.form.edited.search_provider === "somewhere_else" &&<>
                        <CRMInputLabelAndErrorWrapComponent
                            label="Search provider name"
                        >
                            <CRMInputGeneralComponent
                                value={props.form.edited.custom_search_provider_name}
                                inputType="text"
                                placeholder="Name"
                                onChange={onChange("custom_search_provider_name")}
                                displayError={false} 
                            />
                        </CRMInputLabelAndErrorWrapComponent>
                    
                        {/* INPUT - SEARCH PROVIDER URL */}
                        <CRMInputLabelAndErrorWrapComponent
                            label="Search provider URL"
                        >
                            <CRMInputGeneralComponent
                                value={props.form.edited.custom_search_provider_url}
                                inputType="text"
                                placeholder="URL"
                                onChange={onChange("custom_search_provider_url")}
                                displayError={false}
                            />
                        </CRMInputLabelAndErrorWrapComponent>

                        {/* INPUT - SEARCH ORDERING NOTES */}
                        <CRMInputLabelAndErrorWrapComponent
                            label="Search ordering notes"
                        >
                            <CRMInputGeneralComponent
                                value={props.form.edited.custom_search_ordering_notes}
                                inputType="text"
                                placeholder="Notes"
                                onChange={onChange("custom_search_ordering_notes")}
                                displayError={false}
                            />
                        </CRMInputLabelAndErrorWrapComponent>
                    </>}
                </SpacingColumn>

            </SpacingColumn>
        </Padding>
    );
};
