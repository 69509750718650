import React from "react";
import { CRMSpacer } from "../../components/CRMSpacer/CRMSpacer";
import { array } from "fp-ts";
import { TContainerStateProps } from "../../state/TContainerStateProps";
import { pipe } from "fp-ts/lib/function";
import { CRMCaseOverviewCard } from "../../components/CRMCaseOverviewCard/CRMCaseOverviewCard";
import { CRMPageWrap } from "../../components/Simple/CRMPageWrap/CRMPageWrap";
import { CRMPageBody } from "../../components/Simple/CRMPageBody/CRMPageBody";
import { CRMLegalMenuBarContainer } from "../../containers/CRMLegalMenuBarContainer/CRMLegalMenuBarContainer"
class CRMTitleCheckCases extends React.Component<React.PropsWithChildren<TContainerStateProps>, {}> {
    public render(): JSX.Element {
        return (
            <div className="crm-title-check-cases">
                <CRMPageWrap>

                    {/* HEADER */}
                    <CRMLegalMenuBarContainer {...this.props} />

                    {/* BODY */}
                    <CRMPageBody>
                        <CRMSpacer size="large" />
                        <div className="crm-title-check-cases__title">
                            Your title check tasks
                        </div>
                        <CRMSpacer size="large" />
                        {pipe(
                            this.props.state.forms.title_checks_page.output.title_checks,
                            array.mapWithIndex((i, c) => <div key={i}>
                                <CRMCaseOverviewCard
                                    case={c}
                                    user={this.props.state.global.user}
                                />
                                <CRMSpacer size="large" />
                            </div>)
                        )}
                    </CRMPageBody>
                </CRMPageWrap>
            </div>
        );
    }
}

export default CRMTitleCheckCases;
