import React from "react";
import { EmailTemplatePadding } from "./EmailTemplatePadding";

type TEmailTemplateRowProps = {
    className?: string;
    padding?: [
        number, 
        number | undefined, 
        number | undefined, 
        number | undefined
    ];
    isReversed?: boolean;
};

export const EmailTemplateRow = (props: React.PropsWithChildren<TEmailTemplateRowProps>): JSX.Element => {

    if (props.padding) {
        return (
            <table>
                <tbody>
                    <EmailTemplatePadding padding={props.padding}>
                        <table
                            dir={props.isReversed ? "rtl" : ""}
                        >
                            <tbody>
                                <tr>
                                    {props.children}
                                </tr>
                            </tbody>
                        </table>
                    </EmailTemplatePadding>
                </tbody>
            </table>
        );
    }

    return (
        <table
            dir={props.isReversed ? "rtl" : ""}
        >
            <tbody>
                <tr>
                    {props.children}
                </tr>
            </tbody>
        </table>
    );
};
