import React from "react";
import { Background } from "../../BuildingBlocks/Background";
import { CRMColors } from "../../../models/CRMColors";
import { FontQuicksand } from "../../BuildingBlocks/FontQuicksand";
import { CRMIcon } from "../../CRMIcon/CRMIcon";
import { FontSize } from "../../BuildingBlocks/FontSize";
import { TextColor } from "../../BuildingBlocks/TextColor";
import { WeightMedium } from "../../WeightMedium/WeightMedium";
import { CRMFontSizes } from "../../../models/CRMFontSizes";
import { TChatsMessageForm } from "../../../../../domain/codecs/form/ChatsMessageForm";
import { pipe } from "fp-ts/lib/pipeable";
import { array, option } from "fp-ts";
import { validationErrorCodeConstants, T as TValidationErrorCode } from "../../../../../shared/src/validation/ErrorCode";
import { Relative } from "../../BuildingBlocks/Relative";
import { Absolute } from "../../BuildingBlocks/Absolute";
import { AnimationPopout } from "../../BuildingBlocks/AnimationPopout";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { SpacingColumn } from "../../BuildingBlocks/SpacingColumn";
import { Shape } from "../../BuildingBlocks/Shape";
import { CRMKeyboardShortcutWithLabel } from "../../Simple/CRMKeyboardShortcutWithLabel/CRMKeyboardShortcutWithLabel";
import { useHover } from "../../../hooks/useHover";
import { CursorPointer } from "../../CursorPointer/CursorPointer";

type MRoadblockRichTextAreaInfoPillProps = {
    chatForm: TChatsMessageForm;
};

export const CRMRoadblockRichTextAreaInfoPill = (props: React.PropsWithChildren<MRoadblockRichTextAreaInfoPillProps>): JSX.Element => {
    
    const hasValidationError = (codeToFind: TValidationErrorCode) => pipe(
        props.chatForm.validationErrors,
        array.findFirst(([errorCode, string]) => errorCode === codeToFind),
        option.fold(() => false, () => true)
    );

    const getValidationErrorMessage = () => {
        if (hasValidationError(validationErrorCodeConstants.NOTHING_TO_SEND_VALIDATION)) {
            return "Enter text before submitting."
        }

        return "";
    }

    return (
        <>
            {props.chatForm.status !== "validationError" && props.chatForm.status !== "failure" &&
                <InfoPill />
            }

            {props.chatForm.status === "validationError" &&
                <ErrorPill>
                    {getValidationErrorMessage()}
                </ErrorPill>
            }

            {props.chatForm.status === "failure" &&
                <ErrorPill>
                    Sending failed.
                </ErrorPill>
            }
        </>
    );
};

const InfoPill = (props: {}) => {

    const [ref, isHovering] = useHover<HTMLDivElement>({});

    return (
        <Relative divRef={ref}>
            {/* INFO ICON */}
            <CursorPointer>
                <Background
                    color={CRMColors.NEUTRAL_PAPER}
                    borderRadius="100px"
                    width="30px"
                    height="30px"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                    <CRMIcon
                        iconName="question-in-circle"
                        colour="hex"
                        hexColour={CRMColors.NEUTRAL_0}
                    />
                </Background>
            </CursorPointer>

            {isHovering &&
                <Absolute
                    bottom="40px"
                    right="0px"
                >
                    <AnimationPopout>
                        <Background
                            color={CRMColors.NEUTRAL_PAPER}
                            padding={CRMSpacing.MEDIUM}
                            borderRadius="8px"
                            borderDefinition={`1px solid ${CRMColors.NEUTRAL_10}`}
                            width="300px"
                        >
                            <SpacingColumn spacing={CRMSpacing.MEDIUM}>
                                {/* GROUP - TYPE INSTRUCTIONS */}
                                <SpacingColumn spacing={CRMSpacing.TINY}>
                                    <CRMKeyboardShortcutWithLabel shortcut={["Enter"]}>
                                        Send message.
                                    </CRMKeyboardShortcutWithLabel>
                                    <CRMKeyboardShortcutWithLabel shortcut={["Ctrl", "B"]}>
                                        Toggle bold text.
                                    </CRMKeyboardShortcutWithLabel>
                                    <CRMKeyboardShortcutWithLabel shortcut={["Ctrl", "I"]}>
                                        Toggle italic text.
                                    </CRMKeyboardShortcutWithLabel>
                                    <CRMKeyboardShortcutWithLabel shortcut={["Ctrl", "U"]}>
                                        Toggle text underline.
                                    </CRMKeyboardShortcutWithLabel>
                                </SpacingColumn>

                                <Shape width="100%" height="1px" color={CRMColors.NEUTRAL_8} />

                                {/* GROUP - COMMAND INSTRUCTIONS */}
                                <SpacingColumn spacing={CRMSpacing.TINY}>
                                    <CRMKeyboardShortcutWithLabel shortcut={["#"]}>
                                        Start command.
                                    </CRMKeyboardShortcutWithLabel>
                                    <CRMKeyboardShortcutWithLabel 
                                        shortcut={["key-left", "key-right", "key-up", "key-down"]}
                                        mode="plain"
                                    >
                                        Navigate.
                                    </CRMKeyboardShortcutWithLabel>
                                    <CRMKeyboardShortcutWithLabel shortcut={["enter"]}>
                                        Select command or category.
                                    </CRMKeyboardShortcutWithLabel>
                                    <CRMKeyboardShortcutWithLabel shortcut={["Tab"]}>
                                        Select command or category.
                                    </CRMKeyboardShortcutWithLabel>
                                </SpacingColumn>
                            </SpacingColumn>
                        </Background>
                    </AnimationPopout>
                </Absolute>
            }
        </Relative>
    )
}

const ErrorPill = (props: React.PropsWithChildren<{}>) => {
    return (
        <Background
            color={CRMColors.HIGHLIGHTS_INSTRUCTIONAL_BRICK_10}
            borderRadius="8px"
            padding="0px 10px"
            width="fit-content"
            height="30px"
            display="flex"
            alignItems="center"
        >
            <FontQuicksand>
                <FontSize size={CRMFontSizes.SMALL_PLUS} lineHeight={1}>
                    <TextColor color={CRMColors.NEUTRAL_0}>
                        <WeightMedium>
                            {props.children}
                        </WeightMedium>
                    </TextColor>
                </FontSize>
            </FontQuicksand>
        </Background>
    );
}
