import React from "react";
import { SpacingRow } from "../../BuildingBlocks/SpacingRow";
import { CRMKeyboardShortcut, TKeyboardShortcutDescriptionProps } from "../CRMKeyboardShortcut/CRMKeyboardShortcut";
import { CRMParagraph } from "../CRMParagraph/CRMParagraph";
import { WeightBold } from "../../WeightBold/WeightBold";

type TKeyboardShortcutWithLabelProps = TKeyboardShortcutDescriptionProps & {};

export const CRMKeyboardShortcutWithLabel = (props: React.PropsWithChildren<TKeyboardShortcutWithLabelProps>) => {
    return (
        <SpacingRow alignItems="center" spacing={"3px"}>
            <CRMKeyboardShortcut {...props} />
            <CRMParagraph><WeightBold>:</WeightBold></CRMParagraph>
            <CRMParagraph>{props.children}</CRMParagraph>
        </SpacingRow>
    )
}
