import { EditorState } from "draft-js";
import React, { useState } from "react";
import { TDetailedEmailForm } from "../../../../../../domain/codecs/form/TriageForm";
import { CRMColors } from "../../../../models/CRMColors";
import { CRMSpacing } from "../../../../models/CRMSpacing";
import { BackgroundColour } from "../../../BuildingBlocks/BackgroundColour";
import { MinHeight } from "../../../BuildingBlocks/MinHeight";
import { Background } from "../../../BuildingBlocks/Background";
import { SpacingColumn } from "../../../BuildingBlocks/SpacingColumn";
import { CRMBottomControlBar } from "../../../CRMBottomControlBar/CRMBottomControlBar";
import { CRMParagraph } from "../../../Simple/CRMParagraph/CRMParagraph";
import { CRMActionStrip } from "../../../Simple/CRMActionStrip/CRMActionStrip";
import { CRMInfoBox } from "../../../Simple/CRMInfoBox/CRMInfoBox";
import { WeightBold } from "../../../WeightBold/WeightBold";
import { WeightMedium } from "../../../WeightMedium/WeightMedium";
import { CRMComposeEmailAttachments } from "../CRMComposeEmailAttachments/CRMComposeEmailAttachments";
import { CRMComposeEmailBody } from "../CRMComposeEmailBody/CRMComposeEmailBody";
import { CRMComposeEmailControls, TEmailCloseMode } from "../CRMComposeEmailControls/CRMComposeEmailControls";
import { CRMComposeEmailErrorMessages } from "../CRMComposeEmailErrorMessages/CRMComposeEmailErrorMessages";
import { 
    isEmailViewType, 
    TComposeEmailFormAttachmentOptionsProps, 
    TComposeEmailFormCaseSearchProps, 
    TComposeEmailFormWritingProps, 
    TComposeEmailResolutionStatus, 
    TEmailViewStatus 
} from "../CRMComposeEmailForm/CRMComposeEmailFormProps";
import { CRMConfirmationCountdown } from "../../../Complex/CRMConfirmationCountdown/CRMConfirmationCountdown";
import { TAttachmentType } from "../CRMEmailAttachmentFilterSearchSelect/CRMEmailAttachmentFilterSearchSelect";

type TComposeEmailFormViewMainProps = 
    TComposeEmailFormWritingProps &
    TComposeEmailFormCaseSearchProps &
    TComposeEmailFormAttachmentOptionsProps &
    {
        viewStatus: TEmailViewStatus;
        isACaseSelected: boolean;
        caseEmailPageCaseId?: string;
        
        resolutionStatus: TComposeEmailResolutionStatus;
        onResetResolutionSubmission: () => void;
        
        emailContentState: EditorState,
        onChangeEmailContentState: (value: EditorState) => void;
        
        onOpenAttachments: () => void;
        onRemoveAttachment: (type: TAttachmentType, id: string) => void;
        
        attachedEmail: TDetailedEmailForm;
        onOpenAttachedEmail: () => void;
        onOpenEmailTemplates: () => void;
    
        closeMode: TEmailCloseMode;
        isSubmittable: boolean;
        onClose?: () => void;
        onClear: () => void;
        onSend: () => void;
    }
;

export const CRMComposeEmailFormViewMain = (props: React.PropsWithChildren<TComposeEmailFormViewMainProps>): JSX.Element => {

    const [areAttachmentsOpen, setAreAttachmentsOpen] = useState(false);

    const toggleAttachmentsVisiblity = () => setAreAttachmentsOpen(!areAttachmentsOpen);

    const hasAttachedEmail = () => !!props.attachedEmail?.children.email.id;

    const hasAttachments = (): boolean => (
        props.form.edited.attachments.enquiries.length
        + props.form.edited.attachments.files.length
        + props.form.edited.attachments.redemption_statements.length
        + props.form.edited.attachments.quotes.length
    ) > 0;

    return (
        <BackgroundColour colour={CRMColors.NEUTRAL_PAPER}>

            {/* REPLY/FORWARD STRIP */}
            {hasAttachedEmail() &&
                <CRMActionStrip
                    onClick={props.onOpenAttachedEmail}
                    labelIcon={isEmailViewType("reply", props.viewStatus) ? "reply" : "forward-arrow"}
                    actionIcon={"enlarge"}
                >
                    <CRMParagraph>
                        <WeightMedium>{isEmailViewType("reply", props.viewStatus) ? "Replying to" : "Forwarding"}: </WeightMedium> 
                        <WeightBold>{props.attachedEmail?.children.email.subject}</WeightBold>
                    </CRMParagraph>
                </CRMActionStrip>
            }


            {/* COMPOSE EMAIL */}
            <Background padding={CRMSpacing.LARGE}>
                <MinHeight height={areAttachmentsOpen && hasAttachments() ? "auto" : "95vh"}>
                    <SpacingColumn spacing={CRMSpacing.MEDIUM}>
                        
                        {/* CONFIDENTIAL EMAIL BOX */}
                        {isEmailViewType("confidential", props.viewStatus) &&
                            <ConfidentialInfoBox/>
                        }

                        <CRMComposeEmailErrorMessages
                            form={props.form}
                        />

                        <CRMComposeEmailBody
                            viewStatus={props.viewStatus}
                            composingUser={props.composingUser}
                            form={props.form}
                            caseEmailPageCaseId={props.caseEmailPageCaseId}
                            onChange={props.onChange}
                            emailContentState={props.emailContentState}
                            onChangeEmailContentState={props.onChangeEmailContentState}
                            onSelectCase={props.onSelectCase}
                            onChangeSearchCaseValue={props.onChangeSearchCaseValue}
                            hideBody={(areAttachmentsOpen && hasAttachments())}
                            additionalSendToEmailAddresses={props.additionalSendToEmailAddresses}
                        />
                    </SpacingColumn>
                </MinHeight>
            </Background>


            {/* ATTACHMENTS VIEW BAR & SUMMARY */}
            {hasAttachments() &&
                <CRMComposeEmailAttachments
                    isOpen={areAttachmentsOpen}
                    toggleVisibility={toggleAttachmentsVisiblity}
                    form={props.form}
                    onRemoveAttachment={props.onRemoveAttachment}
                />
            }

            {/* CONTROL BAR */}
            <CRMBottomControlBar>
                {props.resolutionStatus === "none" &&
                    <CRMComposeEmailControls
                        viewStatus={props.viewStatus}
                        form={props.form}
                        closeMode={props.closeMode}
                        
                        isACaseSelected={props.isACaseSelected}
                        isSubmittable={props.isSubmittable}
                        
                        onClickAttachment={props.onOpenAttachments}
                        onClickEmailTemplates={props.onOpenEmailTemplates}
                        onSend={props.onSend}
                        onClear={props.onClear}
                        onClose={props.onClose}
                    />
                }

                {props.resolutionStatus === "clear-email" &&
                    <CRMConfirmationCountdown
                        duration={1000}
                        title="Email sent!"
                        iconName="send-email"
                        primaryButtonMode="next"
                        onUndo={props.onResetResolutionSubmission}
                        onComplete={props.onClear}
                    >
                        <Background padding={"0px 0px 3px 0px"}>
                            <SpacingColumn spacing={CRMSpacing.SMALL}>
                                <CRMParagraph>
                                    Clearing text for your new email.
                                </CRMParagraph>
                            </SpacingColumn>
                        </Background>
                    </CRMConfirmationCountdown>
                }
            </CRMBottomControlBar>
        </BackgroundColour>
    );
};

const ConfidentialInfoBox = (): JSX.Element => {
    return (
        <CRMInfoBox color={CRMColors.HIGHLIGHTS_CALMING_PINK_12}>
            <SpacingColumn spacing={CRMSpacing.MEDIUM}>
                
                <SpacingColumn spacing={CRMSpacing.TINY}>
                    <CRMParagraph>
                        You are sending a <WeightBold>confidential email.</WeightBold>
                    </CRMParagraph>
                    <CRMParagraph>
                        This is a private email between you and other staff members.
                        It should not be related to any case.
                    </CRMParagraph>
                </SpacingColumn>
                
            </SpacingColumn>
        </CRMInfoBox>
    );
} 