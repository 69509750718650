import * as QueryStringFilterMappings from "./QueryStringFilterMappings";
import * as QueryStringFilterGroup1 from "./QueryStringFilterGroup1";
import * as QueryStringFilterMappingFunction from "./QueryStringFilterMappingFunction";
import * as CaseEnquiryStatusType from "./CaseEnquiryStatusType";
import { array, nonEmptyArray } from "fp-ts/lib";
import { pipe } from "fp-ts/lib/function";
import { TValidationFunction } from "../../shared/src/validation/Function";

export const filterMappings: QueryStringFilterMappings.T = {
    string: {
        cases_enquiries_status: QueryStringFilterMappingFunction.createForString("cases_enquiries.status"),
    },
};

export const validator: TValidationFunction = QueryStringFilterGroup1.createValidator(filterMappings);

export const createForCasesEnquiriesStatus = (status: CaseEnquiryStatusType.T): QueryStringFilterGroup1.T => ({
    type: "GROUP",
    conditional: "OR",
    filters: [{
        type: "FILTER",
        property: "cases_enquiries_status",
        operator: "EQUALS_STRING",
        value: status,
    }],
});

export const createForCasesEnquiriesStatuses = (statuses: nonEmptyArray.NonEmptyArray<CaseEnquiryStatusType.T>): QueryStringFilterGroup1.T => ({
    type: "GROUP",
    conditional: "OR",
    filters:  pipe(
        statuses,
        array.map((status) => ({
            type: "FILTER",
            property: "cases_enquiries_status",
            operator: "EQUALS_STRING",
            value: status,
        }))
    ),
});
