import { option } from "fp-ts";
import { pipe } from "fp-ts/lib/function";
import React, { useEffect, useState } from "react";
import { MaxHeight } from "../../components/BuildingBlocks/MaxHeight";
import { Padding } from "../../components/BuildingBlocks/Padding";
import { CRMSpacing, CRMSpacingSizeEnumToPxValueNumber, TCRMSpacing } from "../../models/CRMSpacing";
import { CRMEmptyPlaceholder } from "../../components/Simple/CRMEmptyPlaceholder/CRMEmptyPlaceholder";
import { CRMBlock } from "../../components/CRM/CRMBlock/CRMBlock/CRMBlock";
import { SpacingRow } from "../../components/BuildingBlocks/SpacingRow";
import { pick } from "../../../../shared/src/utilsByDomain/record";
import { simpleFold } from "../../../../shared/src/utilsByDomain/option/simpleFold";
import { TBlockType } from "../../state/router/routerTypes";
import { getRouteBlockData } from "../../state/router/getRouteBlockData";
import { updateRouteWithBlockChange } from "../../state/router/updateRouteWithBlockChange";
import { SpacingColumn } from "../../components/BuildingBlocks/SpacingColumn";
import { TBlockWidthName, TBlockWidthOption, getBlockWidthValue } from "../../components/CRM/CRMBlock/CRMBlock/CRMBlockSizes";
import { BlockMap } from "../../models/Blocks";
import { Routes } from "../../state/router/routerRoutes";
import { TDispatch } from "../../state/TDispatch";
import { C as State } from "./../../state/State";

export type TCRMBlocksRouterContainerProps = {
    state: State<Routes>;
    dispatch: TDispatch;
    isFirstLoad: boolean;
    onChangeBlock: () => void;
};

export const CRMBlocksRouterContainer = (props: TCRMBlocksRouterContainerProps): JSX.Element => {

    const blockData = pipe(
        getRouteBlockData(props.state.routes),
        simpleFold
    );
    
    if (blockData === undefined) {
        return (
            <SpacingRow justifyContent="center">
                <CRMEmptyPlaceholder
                    iconName="alert"
                    iconSize="140px"
                >
                    This page is not configured to show blocks.
                </CRMEmptyPlaceholder>
            </SpacingRow>
        )
    }

    const getViewportWidth = () => document.body.getBoundingClientRect().width.valueOf();
    
    const getPaddingSpacingTotal = (paddingSpacing: TCRMSpacing, numberOfColumns: number) => 
        CRMSpacingSizeEnumToPxValueNumber(paddingSpacing) * numberOfColumns;

    const getColsWidth = (colWidth: TBlockWidthName, numberOfColumns: number) =>
        getBlockWidthValue(colWidth) * numberOfColumns;

    const getPaddingSpacingAllowance = (colWidth: TBlockWidthName, numberOfColumns: number) =>
        getViewportWidth() - getColsWidth(colWidth, numberOfColumns) - CRMSpacingSizeEnumToPxValueNumber(CRMSpacing.SMALL);
    
    const getBestSizesForViewportWidth = (numberOfColumns: number) => {

        if (getPaddingSpacingAllowance("Medium", numberOfColumns) - getPaddingSpacingTotal(CRMSpacing.X_LARGE, numberOfColumns) >= 0) {
            return {
                blockWidth: getBlockWidthValue("Medium"),
                paddingSpacing: CRMSpacing.X_LARGE,
            };
        } else if (getPaddingSpacingAllowance("Medium", numberOfColumns) - getPaddingSpacingTotal(CRMSpacing.LARGE, numberOfColumns) >= 0) {
            return {
                blockWidth: getBlockWidthValue("Medium"),
                paddingSpacing: CRMSpacing.LARGE,
            };
        } else if (getPaddingSpacingAllowance("Small", numberOfColumns) - getPaddingSpacingTotal(CRMSpacing.X_LARGE, numberOfColumns) >= 0) {
            return {
                blockWidth: getBlockWidthValue("Small"),
                paddingSpacing: CRMSpacing.X_LARGE,
            }
        };

        return ({
            blockWidth: getBlockWidthValue("Small"),
            paddingSpacing: CRMSpacing.SMALL,
        });
    };

        
    const [defaultBlockSize, setDefaultBlockSize] = useState<TBlockWidthOption>(getBestSizesForViewportWidth(3).blockWidth);
    const [defaultPaddingSpacingSize, setDefaultPaddingSpacingSize] = useState<TCRMSpacing>(getBestSizesForViewportWidth(3).paddingSpacing);

    useEffect(() => {
        setDefaultBlockSize(getBestSizesForViewportWidth(3).blockWidth);
        setDefaultPaddingSpacingSize(getBestSizesForViewportWidth(3).paddingSpacing);
    },[]);

    const onChangeBlock = (newBlock: TBlockType, blockIndex: number) => {
        pipe(
            props.state.routes,
            updateRouteWithBlockChange(newBlock, blockIndex),
            option.fold(
                () => undefined,
                (routeAction) => {
                    props.onChangeBlock()
                    props.dispatch(routeAction)
                }
            )
        )
    };

    const BlockOptions = pick(
        blockData.blocksCodec.payload.codec.values.map((codec) => codec.type),
        BlockMap
    );

    return (
        <MaxHeight height="calc(100vh - 84px)">
            <Padding spacing={`${CRMSpacing.LARGE} 0px 0px ${defaultPaddingSpacingSize}`}>
                <SpacingColumn spacing={CRMSpacing.X_LARGE}>
                    <SpacingRow
                        spacing={defaultPaddingSpacingSize}
                        alignItems="flex-start"
                        justifyContent="start"
                    >   
                        {/* BLOCKS */}
                        {blockData.blocksOpened.map((block, blockIndex) => (
                            <CRMBlock
                                key={blockIndex}
                                mode="select-options"
                                selectedOption={block.type}
                                optionGroups={[
                                    {
                                        name: "Case Blocks",
                                        options: BlockOptions
                                    }
                                ]}
                                startingWidth={defaultBlockSize}
                                onChange={(blockName) => onChangeBlock(blockName as TBlockType, blockIndex)}
                            >
                                {BlockMap[block.type].content({
                                    index: blockIndex,
                                    state: props.state,
                                    dispatch: props.dispatch,
                                    isFirstLoad: props.isFirstLoad
                                })}
                            </CRMBlock>
                        ))}
                    </SpacingRow>
                </SpacingColumn>
            </Padding>
        </MaxHeight>
    )
};
