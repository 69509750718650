import { formSection } from "../modelGenerators/formSection";

const PrepFormSections = [
    "address" as const,
    "price_and_costs" as const,
    "specifics" as const,
    "marketing" as const,
    "signs" as const,
    "documents" as const,
    "selling_process" as const,
    "competing_agent" as const,
    "conveyancing" as const,
    "key_management" as const,
];

const ProgressionFormSections = [
    "progression_seller_solicitor_kick_off" as const,
    "progression_buyer_solicitor_kick_off" as const,
    "progression_buyer_own_survey" as const,
    "progression_buyer_conveyancer_has_everything_required_to_start" as const,
    "progression_buyer_conveyancer_received_contracts" as const,
    "progression_buyer_conveyancer_raised_enquiries" as const,
    "progression_all_enquiries_resolved" as const,
    "progression_exchange_date" as const,
    "progression_completed_date" as const,
    "progression_keys_released_to_buyer" as const,
];

const ProgressionMortgageFormSections = [
    "progression_mortgage_broker_kick_off" as const,
    "progression_mortgage_application_submitted" as const,
    "progression_mortgage_survey" as const,
    "progression_mortgage_offer_received" as const,
];

const AllProgressionFormSections: Array<typeof ProgressionMortgageFormSections[number] | typeof ProgressionFormSections[number]> = [
    "progression_seller_solicitor_kick_off" as const,
    "progression_buyer_solicitor_kick_off" as const,
    "progression_mortgage_broker_kick_off" as const,
    "progression_buyer_own_survey" as const,
    "progression_mortgage_survey" as const,
    "progression_mortgage_application_submitted" as const,
    "progression_mortgage_offer_received" as const,
    "progression_buyer_conveyancer_has_everything_required_to_start" as const,
    "progression_buyer_conveyancer_received_contracts" as const,
    "progression_buyer_conveyancer_raised_enquiries" as const,
    "progression_all_enquiries_resolved" as const,
    "progression_exchange_date" as const,
    "progression_completed_date" as const,
    "progression_keys_released_to_buyer" as const,
];

const SellerDetailsSections = [
    "sellers" as const,
];

export const LegalCaseDetailSections = [
    "case_management" as const,
    "dual_rep" as const,
    "risk_management" as const,
    "case_milestones" as const,
    "exchange_and_complete" as const,
    "post_completion" as const,
    "clients" as const,
    "video_verification_calls" as const,
    "management_company_details" as const,
    "client_bank_accounts" as const,
    "seller_conveyancer_bank_account" as const,
    "client_funds" as const,
    "outgoing_payments" as const,
    "survey" as const,
    "mortgage_offer" as const,
    "related_transaction" as const,
    "stamp_duty_land_tax" as const,
    "audit" as const,
];
export type TLegalCaseDetailSection = typeof LegalCaseDetailSections[number];

export const LegalCaseContractsSections = [
    "milestones" as const,
    "property_details" as const,
    "estate_agent_details" as const,
    "contract_details" as const,
    "other_side_conveyancer_details" as const,
    "other_side_client_details" as const,
    "client_configuration" as const,
    "document_generation" as const,
];
export type TLegalCaseContractsSection = typeof LegalCaseContractsSections[number];

export const CRMListingFormSections = formSection([
    ...SellerDetailsSections,
    ...PrepFormSections,
    ...ProgressionFormSections,
    ...ProgressionMortgageFormSections,
]);
export type TCRMListingFormSections = typeof CRMListingFormSections["list"];

export const CRMListingFormPrepSections = formSection(PrepFormSections);

export const CRMListingSellerDetailsSections = formSection(SellerDetailsSections);

export const CRMListingProgressionFormSections = formSection(ProgressionFormSections);

export const CRMListingAllProgressionFormSections = formSection(AllProgressionFormSections);

export const CRMLegalCaseDetailsSections = formSection(LegalCaseDetailSections);
export type TCRMLegalCaseDetailsSections = typeof CRMLegalCaseDetailsSections["list"];

export const CRMLegalCaseContractsSections = formSection(LegalCaseContractsSections);
export type TCRMLegalCaseContractsSections = typeof CRMLegalCaseContractsSections["list"];

export const CRMLegalFormSections = formSection([
    ...LegalCaseDetailSections,
]);