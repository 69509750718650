import React from "react";
import * as TCurrency from "../../../../domain/models/TCurrency";
import { CRMParagraph } from "../Simple/CRMParagraph/CRMParagraph";
import { TListingEnquiryOfferForm } from "../../../../domain/codecs/formEditable/ListingEnquiryForm";

export class CRMEnquiriesPreviousOffersRowComponent extends React.Component<{
    offer: TListingEnquiryOfferForm;
}> {
    public render (): JSX.Element {
        return (
            <div>
                {/* Amount and status */}
                <CRMParagraph>
                    <strong>
                        {TCurrency.fromNumber(this.props.offer.children.amount)} - {this.props.offer.children.status_display_name}
                    </strong>
                </CRMParagraph>

                {/* Rejected/withdrawn reasons */}
                <CRMParagraph>
                    {this.props.offer.edited.status === "rejected" && `Reason: ${this.props.offer.children.rejected_reason}`}
                    {this.props.offer.edited.status === "withdrawn" && `Reason: ${this.props.offer.edited.withdrawn_reason}`}
                </CRMParagraph>

                {/* Conditions */}
                {this.props.offer.children.conditions && <CRMParagraph>
                    Conditions: {this.props.offer.children.conditions}
                </CRMParagraph>}
            </div>
        );
    }
}
