import React from "react";
import { SpacingColumn } from "../../BuildingBlocks/SpacingColumn";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { ShineShape } from "../../BuildingBlocks/ShineShape";
import { CRMColors } from "../../../models/CRMColors";

type TRoadblockViewChecklistPlaceholderProps = {

};

export const CRMRoadblockViewChecklistPlaceholder = (props: TRoadblockViewChecklistPlaceholderProps): JSX.Element => {

    return (
        <>
            <SpacingColumn spacing={CRMSpacing.LARGE}>
                <ShineShape
                    backgroundColor={CRMColors.NEUTRAL_10} 
                    shineColor={CRMColors.NEUTRAL_12}
                    duration={2000}
                    height={"24px"}
                    width={"60px"}
                    borderRadius="8px"
                />

                <ShineShape
                    backgroundColor={CRMColors.NEUTRAL_10} 
                    shineColor={CRMColors.NEUTRAL_12}
                    duration={2000}
                    height={"18px"}
                    width={"300px"}
                />
                
                <SpacingColumn spacing={CRMSpacing.MEDIUM}>
                    <ShineShape
                        backgroundColor={CRMColors.NEUTRAL_10} 
                        shineColor={CRMColors.NEUTRAL_12}
                        duration={2000}
                        height={"70px"}
                        width={"100%"}
                        borderRadius="2px 5px 5px 2px"
                    />
                    
                    <ShineShape
                        backgroundColor={CRMColors.NEUTRAL_10} 
                        shineColor={CRMColors.NEUTRAL_12}
                        duration={2000}
                        height={"70px"}
                        width={"100%"}
                        borderRadius="2px 5px 5px 2px"
                    />
                </SpacingColumn>
            </SpacingColumn>
        </>
    );
};
