import React from "react";
import { THighlight0, TPrimary0 } from "../../models/TCRMMainColours";

type TCRMCardOutsideSharpCorners = {
    shadow?: boolean;
    border?: THighlight0 | TPrimary0;
};

export class CRMCardOutsideSharpCorners extends React.Component<React.PropsWithChildren<TCRMCardOutsideSharpCorners>> {
    public render (): JSX.Element {
        return (
            <div
                className={`
                    crm-card-outside-sharp
                    crm-card-outside-sharp--${this.props.shadow === true ? "shadow" : "no-shadow"}
                    crm-card-outside-sharp--${this.props.border ? `border-${this.props.border}` : "no-border"}
                `}
            >
                {this.props.children}
            </div>
        );
    }
}
