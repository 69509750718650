import React from "react";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { Padding } from "../../BuildingBlocks/Padding";
import { SpacingColumn } from "../../BuildingBlocks/SpacingColumn";
import { CRMParagraph } from "../../Simple/CRMParagraph/CRMParagraph";
import { WeightBold } from "../../WeightBold/WeightBold";
import { WeightSemiBold } from "../../WeightSemiBold/WeightSemiBold";
import { CRMConfirmationCountdown, TConfirmationCountdownProps } from "../CRMConfirmationCountdown/CRMConfirmationCountdown";

type TConfirmationCountdownWithLabelsProps =
    TConfirmationCountdownProps &
    {
        labels: Array<[string, string | undefined]>
    };

export const CRMConfirmationCountdownWithLabels = (props: TConfirmationCountdownWithLabelsProps): JSX.Element => {

    return (
        <CRMConfirmationCountdown {...props}>
            <Padding type="bottom" spacing={"3px"}>

                <SpacingColumn spacing={CRMSpacing.SMALL}>
                    {props.labels.map(([labelName, labelText], key) => (
                        <CRMParagraph key={key}>
                            <WeightBold>{labelName}:</WeightBold> <WeightSemiBold>{labelText}</WeightSemiBold>
                        </CRMParagraph>
                    ))}
                </SpacingColumn>
                
            </Padding>
        </CRMConfirmationCountdown>
    );
};
