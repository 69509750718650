import React from "react";
import * as Listing3 from "../../../../domain/models/Listing3";
import { CRMParagraph } from "../Simple/CRMParagraph/CRMParagraph";
import { CRMColumns } from "../CRMColumns/CRMColumns";
import { CRMSpacer } from "../CRMSpacer/CRMSpacer";
import { CRMParagraphBulletPoints } from "../CRMBulletList/CRMParagraphBulletPoints";
import * as ListingCondition1 from "../../../../domain/models/ListingCondition1";

class CRMListingAdvantagesDisadvantagesSummaryComponent extends React.Component<{
    listing: Listing3.T;
}> {
    public render (): JSX.Element {
        return (
            <CRMColumns
                padding="medium"
                columns={[
                    {
                        flex: 1,
                        content: <div>
                            <CRMParagraph>
                                {this.props.listing.property_summary
                                    || "No summary available"}
                            </CRMParagraph>
                            <CRMSpacer size="tiny" />
                            <CRMParagraph>
                                {this.props.listing.condition
                                    ? <><strong>Condition:</strong>&nbsp;{ListingCondition1.toDisplayName(this.props.listing.condition)}</>
                                    : "Condition: Unknown"}
                            </CRMParagraph>
                        </div>,
                    },
                    {
                        flex: 1,
                        content: <div>
                            <CRMParagraph>
                                <strong>
                                    Advantages
                                </strong>
                            </CRMParagraph>
                            <CRMSpacer size="tiny" />
                            <CRMParagraph>
                                {this.props.listing.property_advantages.length
                                    ? <CRMParagraphBulletPoints bullets={this.props.listing.property_advantages} />
                                    : "No advantages to show"}
                            </CRMParagraph>
                            <CRMSpacer size="large" />
                            <CRMParagraph>
                                <strong>
                                    Disadvantages
                                </strong>
                            </CRMParagraph>
                            <CRMSpacer size="tiny" />
                            <CRMParagraph>
                                {this.props.listing.property_disadvantages.length
                                    ? <CRMParagraphBulletPoints bullets={this.props.listing.property_disadvantages} />
                                    : "No disadvantages to show"}
                            </CRMParagraph>
                        </div>,
                    },
                ]}
            />
        );
    }
}

export default CRMListingAdvantagesDisadvantagesSummaryComponent;
