import React from "react";
import { CRMDropdownColourComponent } from "../../../CRMDropdownColourComponent/CRMDropdownColourComponent";
import { CRMParagraph } from "../../../Simple/CRMParagraph/CRMParagraph";
import { WeightBold } from "../../../WeightBold/WeightBold";
import * as CaseEnquiryStatusType from "../../../../../../domain/models/CaseEnquiryStatusType";
import { TCRMMainColours } from "../../../../models/TCRMMainColours";
import * as util from "../../../../../../shared/src/util";
import { TIcon } from "../../../CRMIcon/CRMIcon";
import { array, option } from "fp-ts/lib";
import { pipe } from "fp-ts/lib/function";

export type TCaseEnquiryStatusFilterOption = "all" | "approved" | CaseEnquiryStatusType.T;

export interface ICRMCaseEnquiryFilterTitle {
    optionKeyValue: TCaseEnquiryStatusFilterOption;
    onChange: (optionKeyValue: TCaseEnquiryStatusFilterOption) => void;
}

type TCRMCaseEnquiryStatusOption = {
    label: string;
    value: TCaseEnquiryStatusFilterOption;
    iconName?: TIcon;
    highlightColour?: TCRMMainColours;
    fontColour?: TCRMMainColours;
};

export class CRMCaseEnquiryFilterTitle extends React.Component<React.PropsWithChildren<ICRMCaseEnquiryFilterTitle>> {

    public render (): JSX.Element {
        return (
            <div className="case-enquiry-filter-title">
                <div className="case-enquiry-filter-title_text">
                    <CRMParagraph>
                        <WeightBold>Showing</WeightBold>
                    </CRMParagraph>
                </div>
                <div className="case-enquiry-filter-title__dropdown">
                    <CRMDropdownColourComponent<TCaseEnquiryStatusFilterOption, TCaseEnquiryStatusFilterOption>
                        options={this.getCaseEnquiryStatusFilterOptions()}
                        value={this.props.optionKeyValue}
                        onChange={this.props.onChange}
                    />
                </div>
                <div className="case-enquiry-filter-title_text">
                    <CRMParagraph>
                        <WeightBold>enquiries</WeightBold>
                    </CRMParagraph>
                </div>
            </div>
        );
    }

    // The case enquiry status enum doesn't have 'all' or 'approved' so this
    // adds them in to choose from in the filter but maintains enum safety
    private getCaseEnquiryStatusFilterOptions = (): Array<TCRMCaseEnquiryStatusOption> =>
         pipe(
            ["all" as TCaseEnquiryStatusFilterOption].concat(CaseEnquiryStatusType.values),
            (filterOptions) => array.insertAt<TCaseEnquiryStatusFilterOption>(
                 pipe(
                    filterOptions,
                    array.findIndex((filterOption) => filterOption === "approved_unsent"),
                    option.fold<number,number>(
                        () => 3,
                        (index) => index,
                    )
                ),
                "approved")(filterOptions),
            option.fold<Array<TCaseEnquiryStatusFilterOption>, Array<TCaseEnquiryStatusFilterOption>>(
                () => [],
                (options) => options,
            ),
            array.map((filterOption) => this.getCaseEnquiryStatusOption(filterOption))
        );

    private getCaseEnquiryStatusOption = (optionKey: TCaseEnquiryStatusFilterOption): TCRMCaseEnquiryStatusOption => {
        switch (optionKey) {
            case "all":
                return  {
                    label: "all",
                    value: "all",
                };
            case "approved":
                return  {
                    label: "approved",
                    value: "approved",
                    iconName: "done",
                    highlightColour: "highlights-calming-pink-8",
                };
            case "unsubmitted":
                return {
                    label: "unsubmitted",
                    value: "unsubmitted",
                    highlightColour: "neutral-0",
                    fontColour: "neutral-paper",
                };
            case "pending_approval":
                return {
                    label: "pending approval",
                    value: "pending_approval",
                    iconName: "sign",
                    highlightColour: "highlights-pertinent-yellow-8",
                };
            case "resolved":
                return {
                    label: "resolved",
                    value: "resolved",
                    iconName: "done-all",
                    highlightColour: "highlights-positive-green-2",
                    fontColour: "neutral-paper",
                };
            case "approved_unsent":
                return {
                    label: "approved (unsent)",
                    value: "approved_unsent",
                    iconName: "email-notification",
                    highlightColour: "highlights-calming-pink-8",
                };
            case "approved_sent":
                return {
                    label: "approved (sent)",
                    value: "approved_sent",
                    iconName: "email-outbound",
                    highlightColour: "highlights-calming-pink-8",
                };
            case "not_relevant":
                return {
                    label: "not relevant",
                    value: "not_relevant",
                    highlightColour: "neutral-12",
                };
            default:
                return util.requireExhaustive(optionKey);
        }
    };
}
