import React from "react";
import { SpacingColumn } from "../../../BuildingBlocks/SpacingColumn";
import { FrontSpacing } from "../../../../models/FrontSpacing";
import { SpacingRow } from "../../../BuildingBlocks/SpacingRow";
import { FontRoboto } from "../../../BuildingBlocks/FontRoboto";
import { FontSize } from "../../../BuildingBlocks/FontSize";
import { FrontFontSizes } from "../../../../models/FrontFontSizes";
import { TextColor } from "../../../BuildingBlocks/TextColor";
import { FrontColors } from "../../../../models/FrontColors";
import { WeightMedium } from "../../../WeightMedium/WeightMedium";

export const FPOrderedList = (props: {
    list: Array<JSX.Element>
}): JSX.Element =>
    <SpacingColumn spacing={FrontSpacing.MEDIUM_1}>
        {props.list.map((value, i) =>
            <SpacingRow
                key={i}
                justifyContent="space-between"
                alignItems="flex-start"
                spacing={FrontSpacing.SMALL_3}
                childSize="auto 1fr"
            >
                {/* NUMBER */}
                <FontRoboto>
                    <FontSize
                        size={FrontFontSizes.MINOR}
                        lineHeight={1.4}
                    >
                        <TextColor color={FrontColors.NEUTRAL_2}>
                            <WeightMedium>
                                {i + 1}.
                            </WeightMedium>
                        </TextColor>
                    </FontSize>
                </FontRoboto>

                {/* TEXT */}
                <FontRoboto>
                    <FontSize
                        size={FrontFontSizes.MINOR}
                        lineHeight={1.4}
                    >
                        <TextColor color={FrontColors.NEUTRAL_2}>
                            {value}
                        </TextColor>
                    </FontSize>
                </FontRoboto>
            </SpacingRow>
        )}
    </SpacingColumn>;
