import React from "react";


export const FSExclude = (props: React.PropsWithChildren<{}>): JSX.Element => {
    return (
        <span className="fs-exclude">
            {props.children}
        </span>
    );
};
