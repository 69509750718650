import React from "react";
import * as CaseEnquiryDocument2 from "./../../../../domain/models/CaseEnquiryDocument2";
import * as CaseDocumentType1 from "./../../../../domain/models/CaseDocumentType1";
import { CRMLink } from "../CRMLink/CRMLink";
import { CRMParagraph } from "../Simple/CRMParagraph/CRMParagraph";
import { CRMSpacer } from "../CRMSpacer/CRMSpacer";
import { WeightBold } from "../WeightBold/WeightBold";
import { WeightMedium } from "../WeightMedium/WeightMedium";
import { CRMInlineOptionsComponent } from "../CRMInlineOptionsComponent/CRMInlineOptionsComponent";
import { Underline } from "../Underline/Underline";
import { CRMColumnSpacer } from "../CRMColumnSpacer/CRMColumnSpacer";

type TEnquiryDocumentAndSectionLinkProps = {
    document: CaseEnquiryDocument2.T;
};

export const CRMEnquiryDocumentAndSectionLink = (props: TEnquiryDocumentAndSectionLinkProps): JSX.Element => (
        <div className="crm-enquiry-document-section-link">
            {/* DOCUMENT NAME */}
            {props.document.files.length === 0 &&
                <CRMLink href="" linkStyle="no_underline_or_icon">
                    <WeightBold>
                        {CaseDocumentType1.toDisplayString(props.document.type)}{props.document.additional_name ? ` - ${props.document.additional_name}` : ""}
                    </WeightBold>
                </CRMLink>
            }
            {props.document.files.length === 1 &&
                <CRMLink
                    href={`${env.REACT_APP_API_URL}/web/cases/${props.document.case_id}/documents/${props.document.id}/files/${props.document.files[0].id}/download`}
                    target="_blank"
                    linkStyle="normal"
                >
                    <WeightBold>
                        {CaseDocumentType1.toDisplayString(props.document.type)}{props.document.additional_name ? ` - ${props.document.additional_name}` : ""}
                    </WeightBold>
                </CRMLink>
            }
            {props.document.files.length > 1 &&
                <div className="crm-enquiry-document-section-link__wrap">
                    <CRMParagraph>
                        <WeightBold>
                            <Underline>
                                {CaseDocumentType1.toDisplayString(props.document.type)}{props.document.additional_name ? ` - ${props.document.additional_name}` : ""} ({props.document.files.length})
                            </Underline>
                        </WeightBold>
                    </CRMParagraph>
                    <CRMColumnSpacer size="tiny" />
                    <CRMInlineOptionsComponent
                        icon="down-arrow"
                        options={props.document.files.map((file) => ({
                            label: `${file.name}.${file.file_extension}`,
                            onClick: () => window.open(`${env.REACT_APP_API_URL}/web/cases/${props.document.case_id}/documents/${props.document.id}/files/${file.legal_file_id}/download`),
                        }))}
                    />
                </div>
            }
            <CRMSpacer size="tiny" />
            {/* SECTION */}
            <CRMParagraph>
                    { props.document.reference &&
                        (
                        <WeightMedium>
                            &quot;{props.document.reference}&quot;
                        </WeightMedium>
                        )
                    }
            </CRMParagraph>
        </div>
    );
