import React, { useState } from "react";
import { TCaseSignOffAsReadyForExchangeForm } from "../../../../domain/codecs/form/CaseForm";
import { TTransactionType1 } from "../../../../domain/codecs/TransactionType";
import { requireExhaustive } from "../../../../shared/src/util";
import { CRMCardOutsidePopupFormSubmit } from "../CRMCardOutsidePopupFormSubmit/CRMCardOutsidePopupFormSubmit";
import { CRMCaseSignOffAsReadyForExchangePopupChecklistPurchaseComponent } from "./CRMCaseSignOffAsReadyForExchangePopupChecklistPurchaseComponent";
import { CRMCaseSignOffAsReadyForExchangePopupChecklistSaleComponent } from "./CRMCaseSignOffAsReadyForExchangePopupChecklistSaleComponent";
import { CRMCaseSignOffAsReadyForExchangePopupChecklistTransferComponent } from "./CRMCaseSignOffAsReadyForExchangePopupChecklistTransferComponent";
import { CRMCaseSignOffAsReadyForExchangePopupChecklistRemortgageComponent } from "./CRMCaseSignOffAsReadyForExchangePopupChecklistRemortgageComponent";
import { CRMCaseSignOffAsReadyForExchangePopupConfirmationComponent } from "./CRMCaseSignOffAsReadyForExchangePopupConfirmationComponent";

export const CRMCaseSignOffAsReadyForExchangePopupComponent = (props: React.PropsWithChildren<{
    form: TCaseSignOffAsReadyForExchangeForm,
    transactionType: TTransactionType1,
    onSignOff: () => void,
    onClose: () => void,
}>): JSX.Element => {
    const [view, setView] = useState<"checklist" | "confirmation">("checklist");

    const isConfirmationView = view === "confirmation";

    const isChecklistView = view === "checklist";

    const popupCTAText =
        view === "checklist" ? "I have checked the high risk flags"
        : view === "confirmation" ? "Yes, ready for exchange"
        : requireExhaustive(view);

    const popupTitle =
        view === "checklist" ? "High risk flags"
        : view === "confirmation" ? "Are you sure?"
        : requireExhaustive(view);

    const onCTA = () =>
        view === "checklist" ? setView("confirmation")
        : view === "confirmation" ? props.onSignOff()
        : requireExhaustive(view);

    const onClose = () => {
        setView("checklist");
        props.onClose();
    };

    return <CRMCardOutsidePopupFormSubmit
        isOpen={true}
        title={popupTitle}
        context="warning"
        closeText="Cancel"
        ctaText={popupCTAText}
        size="medium"
        onClose={onClose}
        onCTA={onCTA}
        formStatus={
            props.form.status === "unauthorised"
            || props.form.status === "twoFactorRequired"
            || props.form.status === "notFound" ? "failure"
                : props.form.status === "untouched" ? "requiresSubmission"
                : props.form.status}
        validationErrors={[]}
    >
        {/* CHECKLISTS */}
        {isChecklistView &&
            <>
                {
                    props.transactionType === "purchase" ? <CRMCaseSignOffAsReadyForExchangePopupChecklistPurchaseComponent />
                    : props.transactionType === "sale" ? <CRMCaseSignOffAsReadyForExchangePopupChecklistSaleComponent />
                    : props.transactionType === "transfer" ? <CRMCaseSignOffAsReadyForExchangePopupChecklistTransferComponent />
                    : props.transactionType === "remortgage" ? <CRMCaseSignOffAsReadyForExchangePopupChecklistRemortgageComponent />
                    : props.transactionType === "unknown" ? <></>
                    : requireExhaustive(props.transactionType)
                }
            </>
        }

        {/* CONFIRMATION */}
        {isConfirmationView &&
            <CRMCaseSignOffAsReadyForExchangePopupConfirmationComponent
                form={props.form}
            />
        }
    </CRMCardOutsidePopupFormSubmit>;
};
