import React, { useEffect } from "react";
import { CRMTitleSection } from "../../components/CRMTitleSection/CRMTitleSection";
import { CRMSpacer } from "../../components/CRMSpacer/CRMSpacer";
import { CRMAutosaveIndicatorWrap } from "../../components/CRM/CRMAutosaveIndicatorWrap/CRMAutosaveIndicatorWrap";
import { CRMSpacingColumn } from "../../components/CRMSpacingColumn/CRMSpacingColumn";
import { CRMParagraph } from "../../components/Simple/CRMParagraph/CRMParagraph";
import { pipe } from "fp-ts/lib/function";
import { array } from "fp-ts";
import { FormStatus_highestPriority } from "../../../../shared/src/codecs/codec";
import { CRMCaseTitleRestrictions } from "../../components/CRMCaseTitleRestrictions/CRMCaseTitleRestrictions";
import { SpacingRow } from "../../components/BuildingBlocks/SpacingRow";
import { CRMSpacing } from "../../models/CRMSpacing";
import CRMInputLabelAndErrorWrapComponent from "../../components/CRMInputLabelAndErrorWrapComponent/CRMInputLabelAndErrorWrapComponent";
import { CRMRadioListComponent } from "../../components/CRMInputs/CRMRadioListComponent/CRMRadioListComponent";
import { DateTime } from "luxon";
import { CRMDeferButtonComponent } from "../../components/CRMDeferButtonComponent/CRMDeferButtonComponent";
import { CRMBlockScrollCacheView } from "../../components/CRM/CRMBlock/CRMBlockViews/CRMBlockViews";
import { MinHeight } from "../../components/BuildingBlocks/MinHeight";
import { CRMCardOutside } from "../../components/CRMCardOutside/CRMCardOutside";
import { CRMPadding } from "../../components/Simple/CRMPadding/CRMPadding";
import { CRMCaseCharges } from "../../components/CRMCaseCharges/CRMCaseCharges";
import { SpacingColumn } from "../../components/BuildingBlocks/SpacingColumn";
import { TBlockContainerStateProps } from "../../state/TBlockContainerStateProps";

const CRMLegalCaseChargesAndRestrictionsContainer = React.memo((props: TBlockContainerStateProps): JSX.Element => {
    useEffect(
        () => props.dispatch({
            type: "LEGAL_CASE_CHARGES_AND_TITLE_RESTRICTIONS_BLOCK_VIEW",
            payload: null
        }), 
        []
    );

    return (
        <CRMBlockScrollCacheView isShowing={true}>
            <CRMCardOutside borderRounding="right">
                <MinHeight height="100vh">
                    <CRMPadding size="large">
                        <CRMAutosaveIndicatorWrap
                            status={pipe(
                                [
                                    props.state.forms.case_charges_and_restrictions_block.children.case_form.status,
                                    props.state.forms.case_charges_and_restrictions_block.children.create_title_restriction.status,
                                    props.state.forms.case_charges_and_restrictions_block.children.create_charge.status,
                                    ...pipe(
                                        props.state.forms.case_charges_and_restrictions_block.children.restrictions,
                                        array.map((form) => form.status),
                                    ),
                                    ...pipe(
                                        props.state.forms.case_charges_and_restrictions_block.children.charges,
                                        array.map((form) => form.status),
                                    ),
                                ],
                                FormStatus_highestPriority,
                            )}
                        >
                            <SpacingColumn spacing={CRMSpacing.MEDIUM}>
                                <CRMParagraph>
                                    Add any charges & restrictions that appear on the title. For any charges this will allow us to prompt the client to provide the related account numbers.
                                </CRMParagraph>

                                <SpacingRow
                                    spacing={CRMSpacing.MEDIUM}
                                    alignItems="flex-start"
                                    justifyContent="space-between"
                                >
                                    {/* ALL CHARGES & RESTRICTIONS ADDED */}
                                    <CRMInputLabelAndErrorWrapComponent
                                        label="All charges & restrictions added?"
                                    >
                                        <CRMRadioListComponent
                                            value={props.state.forms.case_charges_and_restrictions_block.children.case_form.edited.add_charges_and_title_restrictions_job_done_at === null ? false : true}
                                            direction="row"
                                            options={[
                                                {
                                                    value: true,
                                                    text: "Yes",
                                                },
                                                {
                                                    value: false,
                                                    text: "No",
                                                },
                                            ]}
                                            onChange={(add_title_restrictions_job_done) => props.dispatch({
                                                type: "LEGAL_CASE_CHARGES_AND_TITLE_RESTRICTION_UPDATE_CASE",
                                                payload: {
                                                    ...props.state.forms.case_charges_and_restrictions_block.children.case_form,
                                                    edited: {
                                                        ...props.state.forms.case_charges_and_restrictions_block.children.case_form.edited,
                                                        add_charges_and_title_restrictions_job_done_at: add_title_restrictions_job_done
                                                            ? DateTime.utc().toISO()
                                                            : null,
                                                    }
                                                }
                                            })}
                                            disabled={false}
                                        />
                                    </CRMInputLabelAndErrorWrapComponent>

                                    {/* ALL CHARGES & RESTRICTIONS ADDED - DEFER */}
                                    <CRMDeferButtonComponent
                                        value={props.state.forms.case_charges_and_restrictions_block.children.case_form.edited.add_charges_and_title_restrictions_job_defer}
                                        onChange={(add_charges_and_title_restrictions_job_defer) => props.dispatch({
                                            type: "LEGAL_CASE_CHARGES_AND_TITLE_RESTRICTION_UPDATE_CASE",
                                            payload: {
                                                ...props.state.forms.case_charges_and_restrictions_block.children.case_form,
                                                edited: {
                                                    ...props.state.forms.case_charges_and_restrictions_block.children.case_form.edited,
                                                    add_charges_and_title_restrictions_job_defer,
                                                }
                                            }
                                        })}
                                        displayError={false}
                                        popoutPosition="below-button-left"
                                    />
                                </SpacingRow>

                                <div>
                                    <CRMTitleSection>
                                        Charges
                                    </CRMTitleSection>
                                    <CRMSpacer size="large" />
                                </div>
                            </SpacingColumn>
                            
                            <CRMSpacingColumn spacing="medium">
                                {/* CHARGES */}
                                <CRMCaseCharges
                                    chargeForms={props.state.forms.case_charges_and_restrictions_block.children.charges}
                                    createChargeForm={props.state.forms.case_charges_and_restrictions_block.children.create_charge}
                                    propertiesAvailableForAssociation={props.state.forms.case_charges_and_restrictions_block.children.properties_available_for_association}
                                    expectedCompletionDate={props.state.forms.case_charges_and_restrictions_block.children.expected_completion_date}
                                    onCreateNewCharge={(payload) => props.dispatch({
                                        type: "LEGAL_CASE_CHARGE_CREATE",
                                        payload,
                                    })}
                                    onUpdateCharge={(payload) => props.dispatch({
                                        type: "LEGAL_CASE_CHARGE_UPDATE",
                                        payload,
                                    })}
                                    onDeleteCharge={(payload) => props.dispatch({
                                        type: "LEGAL_CASE_CHARGE_DELETE",
                                        payload,
                                    })}
                                    onUpdateNewChargeRedemption={(payload) => props.dispatch({
                                        type: "LEGAL_CASE_CHARGE_NEW_REDEMPTION_STATEMENT_CHANGE",
                                        payload,
                                    })}
                                    onSubmitNewChargeRedemption={(payload) => props.dispatch({
                                        type: "LEGAL_CASE_CHARGE_NEW_REDEMPTION_STATEMENT_SUBMIT",
                                        payload,
                                    })}
                                    onUploadNewChargeRedemption={(payload) => props.dispatch({
                                        type: "LEGAL_CASE_CHARGE_REDEMPTION_STATEMENT_SUBMIT_UPLOAD",
                                        payload,
                                    })}
                                    onDeleteChargeRedemption={(payload) => props.dispatch({
                                        type: "LEGAL_CASE_CHARGE_NEW_REDEMPTION_STATEMENT_DELETE",
                                        payload,
                                    })}
                                />

                                <div>
                                    <CRMTitleSection>
                                        Restrictions
                                    </CRMTitleSection>
                                    <CRMSpacer size="large" />
                                </div>
                            </CRMSpacingColumn>
                            
                            {/* RESTRICTIONS */}
                            <CRMCaseTitleRestrictions
                                chargeForms={props.state.forms.case_charges_and_restrictions_block.children.charges}
                                restrictionForms={props.state.forms.case_charges_and_restrictions_block.children.restrictions}
                                createRestrictionForm={props.state.forms.case_charges_and_restrictions_block.children.create_title_restriction}
                                propertiesAvailableForAssociation={props.state.forms.case_charges_and_restrictions_block.children.properties_available_for_association}
                                expectedCompletionDate={props.state.forms.case_charges_and_restrictions_block.children.expected_completion_date}
                                onCreateNewTitleRestriction={(payload) => props.dispatch({
                                    type: "LEGAL_CASE_TITLE_RESTRICTION_CREATE",
                                    payload,
                                })}
                                onUpdateTitleRestriction={(payload) => props.dispatch({
                                    type: "LEGAL_CASE_TITLE_RESTRICTION_UPDATE",
                                    payload,
                                })}
                                onDeleteTitleRestriction={(payload) => props.dispatch({
                                    type: "LEGAL_CASE_TITLE_RESTRICTION_DELETE",
                                    payload,
                                })}
                            />
                        </CRMAutosaveIndicatorWrap>
                    </CRMPadding>
                </MinHeight>
            </CRMCardOutside>
        </CRMBlockScrollCacheView>
    );
});

export default CRMLegalCaseChargesAndRestrictionsContainer;
