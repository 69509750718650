import React from "react";
import * as Listing3 from "../../../../domain/models/Listing3";
import * as TCurrency from "../../../../domain/models/TCurrency";
import { CRMTitleForCard } from "../CRMTitleForCard/CRMTitleForCard";
import { CRMSpacer } from "../CRMSpacer/CRMSpacer";
import { CRMParagraph } from "../Simple/CRMParagraph/CRMParagraph";

type TCRMListingRenovationAndSaleValueSummary = {
    listing: Listing3.T;
};

export class CRMListingRenovationAndSaleValueSummary extends React.Component<React.PropsWithChildren<TCRMListingRenovationAndSaleValueSummary>> {
    public render (): JSX.Element {
        return (
            <div>
                <CRMTitleForCard>
                    Renovation & Onward Rent/Sale
                </CRMTitleForCard>
                <CRMSpacer size="tiny" />
                <CRMParagraph>
                    <strong>Renovation estimate:</strong>
                    &nbsp;
                    {
                        this.props.listing.renovation_cost
                        ? TCurrency.fromNumber(this.props.listing.renovation_cost)
                        : "Unknown"
                    }
                </CRMParagraph>
                <CRMSpacer size="tiny" />
                <CRMParagraph>
                    <strong>Sale estimate if renovated:</strong>
                    &nbsp;
                    {
                        this.props.listing.estimated_resale_price
                        ? TCurrency.fromNumber(this.props.listing.estimated_resale_price)
                        : "Unknown"
                    }
                </CRMParagraph>
                <CRMSpacer size="tiny" />
                <CRMParagraph>
                    <strong>Estimated rental price:</strong>
                    &nbsp;
                    {
                        this.props.listing.estimated_rental_price_pcm
                        ? `${TCurrency.fromNumber(this.props.listing.estimated_rental_price_pcm)} p/m`
                        : "Unknown"
                    }
                </CRMParagraph>
                <CRMSpacer size="tiny" />
                <CRMParagraph>
                    <strong>{this.props.listing.renovation_cost || 0 > 0 ? "Estimated rental yield when renovated:" : "Estimated rental yield:"}`</strong>
                    &nbsp;
                    {
                        this.props.listing.estimated_rental_price_pcm && this.props.listing.price
                        ? `${Listing3.getRentalYieldPercentage(this.props.listing).toFixed(2)}%`
                        : "Unknown"
                    }
                </CRMParagraph>
            </div>
        );
    }
}
