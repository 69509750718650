import React from "react";
import { TCRMFormInputWithoutCodec } from "./CRMFormInputTypes";
import CRMInputLabelAndErrorWrapComponent from "../CRMInputLabelAndErrorWrapComponent/CRMInputLabelAndErrorWrapComponent";
import { CRMAdditiveInput } from "../CRM/CRMAdditiveInput/CRMAdditiveInput";

export const CRMAdditiveArrayFormInput: TCRMFormInputWithoutCodec<string[]> = (props) =>
    <CRMInputLabelAndErrorWrapComponent label={props.label}>
        <CRMAdditiveInput
            value={props.model}
            onChange={props.onChange}
            displayError={props.displayError}
        />
    </CRMInputLabelAndErrorWrapComponent>
