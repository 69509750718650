import React from "react";
import { TCasesCaseCreateAdhocForm } from "../../../../../../domain/codecs/form/CasesForm";
import { CRMBlockPopover } from "../../CRMBlock/CRMBlockPopover/CRMBlockPopover";
import { CRMSingleViewHeader } from "../../../Simple/CRMSingleViewHeader/CRMSingleViewHeader";
import { SpacingRow } from "../../../BuildingBlocks/SpacingRow";
import { CRMSpacing } from "../../../../models/CRMSpacing";
import { CRMInlineTextCalendarDefer } from "../../../Complex/CRMInlineTextCalendarDefer/CRMInlineTextCalendarDefer";
import { CRMAssignUserAvatar } from "../../../Complex/CRMAssignUserAvatar/CRMAssignUserAvatar";
import { TPlainUser } from "../../../../../../domain/codecs/User";
import { Background } from "../../../BuildingBlocks/Background";
import { CRMBottomControlBar } from "../../../CRMBottomControlBar/CRMBottomControlBar";
import { CRMButtonPrimary } from "../../../CRMButtonPrimary/CRMButtonPrimary";
import { CRMFormButton } from "../../../CRMFormButton/CRMFormButton";
import CRMTextAreaComponent from "../../../CRMTextAreaComponent/CRMTextAreaComponent";
import { TFormStatus } from "../../../../../../shared/src/codecs/codec";

// [TO DO] Change Name for whole comp
type CRMCaseCreateAdhocPopupProps = {
    status: TFormStatus;
    date: string | null;
    onChangeDate: (date: string | null) => void;
    assignedTo: string;
    onChangeAssignedTo: (assignedTo: string) => void;
    job: string;
    onChangeJob: (job: string) => void;
    assigneeUsers: Array<TPlainUser>;
    onSubmit: () => void;
    onClose: () => void;
};

export const CRMCaseCreateAdhocPopup = (props: CRMCaseCreateAdhocPopupProps): JSX.Element => {

    const IsReadyToSubmit = props.job && props.assignedTo && props.date;
    const IsSubmitting = props.status === "submitting" || props.status === "loading";

    return (
        <CRMBlockPopover
            overflowY="visible"
            display="flex"
            justifyContent="center"
            width="550px"
        >
            <div>
                {/* HEADER */}
                <CRMSingleViewHeader
                    title="Add ad-hoc"
                    edge="border"
                    onClick={props.onClose}
                >
                    <Background padding={`${CRMSpacing.SMALL} ${CRMSpacing.MEDIUM}`}>
                        <SpacingRow
                            spacing={CRMSpacing.MEDIUM}
                            justifyContent="start"
                            alignItems="center"
                        >
                            {/* DATE */}
                            <CRMInlineTextCalendarDefer
                                value={props.date}
                                onChange={props.onChangeDate}
                            />

                            {/* ASSIGNEE */}
                            <CRMAssignUserAvatar
                                size="tiny"
                                value={props.assignedTo}
                                users={props.assigneeUsers}
                                onChange={props.onChangeAssignedTo}
                            />
                        </SpacingRow>
                    </Background>
                </CRMSingleViewHeader>

                {/* JOB */}
                <Background padding={CRMSpacing.LARGE}>
                    <CRMTextAreaComponent
                        value={props.job}
                        autoFocus={true}
                        placeholder="What's the job?"
                        onChange={props.onChangeJob}
                        onEnter={props.onSubmit}
                    />
                </Background>

                {/* BOTTOM BAR */}
                <CRMBottomControlBar
                    justifyContent="flex-end"
                    edge="border"
                    rounding="rounded"
                >
                    <Background padding={`${CRMSpacing.TINY} ${CRMSpacing.MEDIUM}`}>
                        <CRMFormButton
                            formStatus={props.status}
                            ButtonElement={CRMButtonPrimary}
                            label="Submit"
                            disabled={!IsReadyToSubmit || IsSubmitting}
                            onClick={props.onSubmit}
                        />
                    </Background>
                </CRMBottomControlBar>
            </div>
        </CRMBlockPopover>
    );
};
