import * as Country2 from "./Country2";
import * as Listing3 from "./Listing3";
import * as Listing9 from "./Listing9";
import * as Listing6 from "./Listing6";

type T = {
    building_name: string;
    building_number: string;
    sub_building_name: string;
    sub_building_number: string;
    street_name: string;
    city_town: string;
    locality: string;
    county: string;
    country: Country2.TDisplayName;
    postcode: string | null;
};

export const toString = (t: T): string =>
    {
        const formattedAddress: Array<string> = [];
        if (t.sub_building_name) {
            formattedAddress.push(t.sub_building_name);
        } else if (t.sub_building_number) {
            formattedAddress.push(t.sub_building_number);
        }
        if (t.building_number) {
            formattedAddress.push(`${t.building_number} ${t.street_name}`);
        }
        else {
            formattedAddress.push(t.building_name);
            formattedAddress.push(t.street_name);
        }
        if (t.locality) {
            formattedAddress.push(t.locality);
        }
        formattedAddress.push(t.city_town);
        formattedAddress.push(t.county);
        formattedAddress.push(t.country);
        if (t.postcode) {
            formattedAddress.push(t.postcode);
        }
        return formattedAddress.join(", ");
    };

export const toShortString = (t: T): string =>
    {
        const formattedAddress: Array<string> = [];
        if (t.sub_building_name) {
            formattedAddress.push(t.sub_building_name);
        } else if (t.sub_building_number) {
            formattedAddress.push(t.sub_building_number);
        }
        if (t.building_number) {
            formattedAddress.push(`${t.building_number} ${t.street_name}`);
        }
        else {
            formattedAddress.push(t.building_name);
            formattedAddress.push(t.street_name);
        }
        if (t.locality) {
            formattedAddress.push(t.locality);
        }
        formattedAddress.push(t.city_town);
        if (t.postcode) {
            formattedAddress.push(t.postcode);
        }
        return formattedAddress.join(", ");
    };

export const toBuildingAndStreet = (t: T): string =>
    {
        const formattedAddress: Array<string> = [];
        if (t.sub_building_name) {
            formattedAddress.push(t.sub_building_name);
        } else if (t.sub_building_number) {
            formattedAddress.push(t.sub_building_number);
        }
        if (t.building_number) {
            formattedAddress.push(`${t.building_number} ${t.street_name}`);
        }
        else {
            formattedAddress.push(t.building_name);
            formattedAddress.push(t.street_name);
        }
        return formattedAddress.join(", ");
    };

export const isFilled = (t: T): boolean =>
    (t.building_name || t.building_number)
    && t.street_name
    && t.postcode
        ? true
        : false;

export const fromListing3 = (l: Listing3.T): T => ({
    building_name: l.building_name,
    building_number: l.building_number,
    sub_building_name: l.sub_building_name,
    sub_building_number: l.sub_building_number,
    street_name: l.street_name,
    city_town: l.city_town,
    locality: l.locality,
    county: l.county,
    country: Country2.fromValueToDisplayName(l.country),
    postcode: l.postcode,
});

export const fromListing9 = (l: Listing9.T): T => ({
    building_name: l.building_name,
    building_number: l.building_number,
    sub_building_name: l.sub_building_name,
    sub_building_number: l.sub_building_number,
    street_name: l.street_name,
    city_town: l.city_town,
    locality: l.locality,
    county: l.county,
    country: Country2.fromValueToDisplayName(l.country),
    postcode: l.postcode,
});

export const fromListing6 = (l: Listing6.T): T => ({
    building_name: l.building_name,
    building_number: l.building_number,
    sub_building_name: l.sub_building_name,
    sub_building_number: l.sub_building_number,
    street_name: l.street_name,
    city_town: l.city_town,
    locality: l.locality,
    county: l.county,
    country: Country2.fromValueToDisplayName(l.country),
    postcode: l.postcode,
});
