import React from "react";

type TTextHightlightProps = {
    color: string;
    lineHeight?: string;
    width?: "fit-content"
};

export const TextHightlight = (props: React.PropsWithChildren<TTextHightlightProps>): JSX.Element => {

    return (
        <div style={{
            lineHeight: props.lineHeight || "1.5",
            backgroundColor: props.color,
            width: props.width,
        }}>
            {props.children}
        </div>
    );
};
