import React from "react";
import { TContainerStateProps } from "../../state/TContainerStateProps";
import { CRMCaseTable } from "../../components/CRM/CRMCases/CRMCaseTable/CRMCaseTable";
import { SpacingColumn } from "../../components/BuildingBlocks/SpacingColumn";
import { CRMCaseTableFilter } from "../../components/CRM/CRMCases/CRMCaseTableFilter/CRMCaseTableFilter";
import { CRMSpacing } from "../../models/CRMSpacing";
import { CRMDayTable } from "../../components/CRM/CRMCases/CRMDayTable/CRMDayTable";
import { CRMDayTableFilter } from "../../components/CRM/CRMCases/CRMDayTableFilter/CRMDayTableFilter";
import { SpacingRow } from "../../components/BuildingBlocks/SpacingRow";
import { CRMCaseViewFilter } from "../../components/CRM/CRMCases/CRMCaseViewFilter/CRMCaseViewFilter";
import { Background } from "../../components/BuildingBlocks/Background";

const CRMCaseTableContainer = (props: TContainerStateProps): JSX.Element => {
    
    const view = props.state.forms.cases_page.view;
    const isCaseView = view === "case";
    const isDayView = view === "day";

    return (
        <SpacingColumn
            spacing={CRMSpacing.LARGE}
        >
            {/* FILTERS */}
            <Background padding={`0px ${CRMSpacing.X_LARGE}`}>
                <SpacingRow
                    justifyContent="space-between"
                    spacing={CRMSpacing.LARGE}
                >
                    {/* LEFT - CASE VIEW*/}
                    {isCaseView &&
                        <CRMCaseTableFilter
                            form={props.state.forms.cases_page.cases_view_form}
                            onChangeForm={(payload) => props.dispatch({
                                type: "CRM_NEW_CASES_CASE_VIEW_FILTER_CHANGE",
                                payload,
                            })}
                        />
                    }

                    {/* LEFT - DAY VIEW */}
                    {isDayView &&
                        <CRMDayTableFilter
                            form={props.state.forms.cases_page.day_view_form}
                            onChangeForm={(payload) => props.dispatch({
                                type: "CRM_NEW_CASES_DAY_VIEW_FILTER_CHANGE",
                                payload,
                            })}
                        />
                    }

                    {/* RIGHT - VIEW SWITCH */}
                    <CRMCaseViewFilter
                        view={view}
                        onChangeView={(payload) => props.dispatch({
                            type: "CRM_NEW_CASES_VIEW_CHANGE",
                            payload,
                        })}
                    />
                </SpacingRow>
            </Background>
            
            {/* TABLE - CASE VIEW */}
            {isCaseView &&
                <CRMCaseTable
                    form={props.state.forms.cases_page.cases_view_form}
                    assignableUsers={props.state.forms.cases_page.cases_view_form.children.adhoc_assignable_users}
                    openCaseId={props.state.forms.cases_page.cases_view_open_case_id}
                    onSetOpenCaseId={(payload) => props.dispatch({
                        type: "CRM_NEW_CASES_CASE_VIEW_OPEN_CASE_ID_CHANGE",
                        payload,
                    })}
                    onChangeViewForm={(payload) => props.dispatch({
                        type: "CRM_NEW_CASES_CASE_VIEW_SORTING_CHANGE",
                        payload,
                    })}
                    onLoadMore={() => props.dispatch({
                        type: "CRM_NEW_CASES_CASE_VIEW_LOAD_MORE",
                    })}
                    onChangeCaseFormComment={(payload) => props.dispatch({
                        type: "CRM_NEW_CASES_CASE_VIEW_COMMENT_CHANGE",
                        payload,
                    })}
                    onSubmitCaseForm={(payload) => props.dispatch({
                        type: "CRM_NEW_CASES_CASE_VIEW_CASE_SUBMIT",
                        payload,
                    })}
                    onArchiveTask={(payload) => props.dispatch({
                        type: "CRM_NEW_CASES_ARCHIVE_TASK",
                        payload,
                    })}
                    onChangeTask={(payload) => props.dispatch({
                        type: "CRM_NEW_CASES_UPDATE_TASK",
                        payload,
                    })}
                    onSubmitNewTask={(payload) => props.dispatch({
                        type: "CRM_NEW_CASES_SUBMIT_NEW_TASK",
                        payload,
                    })}
                />
            }

            {/* TABLE - DAY VIEW */}
            {isDayView &&
                <CRMDayTable
                    form={props.state.forms.cases_page.day_view_form}
                    assignableUsers={props.state.forms.cases_page.day_view_form.children.assignable_users}
                    onLoadMore={() => props.dispatch({
                        type: "CRM_NEW_CASES_DAY_VIEW_LOAD_MORE",
                    })}
                    onArchiveTask={(payload) => props.dispatch({
                        type: "CRM_NEW_CASES_ARCHIVE_DAY_TASK",
                        payload,
                    })}
                    onChangeTask={(payload) => props.dispatch({
                        type: "CRM_NEW_CASES_UPDATE_DAY_TASK",
                        payload,
                    })}
                />
            }
        </SpacingColumn>
    );
}

export default CRMCaseTableContainer;
