import React from "react";
import { CRMIcon } from "../CRMIcon/CRMIcon";
import { CRMParagraph } from "../Simple/CRMParagraph/CRMParagraph";

export class CRMNoticeBoxComponent extends React.Component<React.PropsWithChildren<{}>> {
    public render (): JSX.Element {
        return (
            <div className="crm-notice-box-component">
                <div className="crm-notice-box-component__text">
                    <CRMParagraph>
                        {this.props.children}
                    </CRMParagraph>
                </div>
                <div className="crm-notice-box-component__icon">
                    <CRMIcon
                        iconName="warning-bubble"
                        colour="neutral-ink"
                        size="medium"
                    />
                </div>
            </div>
        );
    }
}
