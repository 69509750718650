import React from "react";
import { CRMColors } from "../../../models/CRMColors";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { BackgroundColour } from "../../BuildingBlocks/BackgroundColour";
import { MinHeight } from "../../BuildingBlocks/MinHeight";
import { Background } from "../../BuildingBlocks/Background";
import { SpacingColumn } from "../../BuildingBlocks/SpacingColumn";
import { TransactionType1_TransactionType4 } from "../../../../../domain/codecs/TransactionType";
import { CRMTitleSubSection } from "../../CRMTitleSubSection/CRMTitleSubSection";
import CRMInputLabelAndErrorWrapComponent from "../../CRMInputLabelAndErrorWrapComponent/CRMInputLabelAndErrorWrapComponent";
import { CRMDropdownComponent } from "../../CRMDropdownComponent/CRMDropdownComponent";
import { TCaseAutoAssignmentForm } from "../../../../../domain/codecs/form/CaseAutoAssignmentForm";
import { TUserIdAndFullName } from "../../../../../domain/codecs/User";
import { pipe } from "fp-ts/lib/function";
import { array } from "fp-ts";
import { CRMAutosaveIndicatorWrap } from "../../CRM/CRMAutosaveIndicatorWrap/CRMAutosaveIndicatorWrap";
import { FormStatus_highestPriority } from "../../../../../shared/src/codecs/codec";

export const CRMCaseAutoAssignmentBlock = (props: React.PropsWithChildren<{
    assignmentForms: Array<TCaseAutoAssignmentForm>,
    caseHandlers: Array<TUserIdAndFullName>,
    caseAssistants: Array<TUserIdAndFullName>,
    onChange: (form: TCaseAutoAssignmentForm) => void,
}>): JSX.Element => {
    const assignmentForms = props.assignmentForms;

    const caseHandlers = props.caseHandlers;

    const caseAssistants = props.caseAssistants;

    const formStatus =
        pipe(
            assignmentForms,
            array.map(({status}) => status),
            FormStatus_highestPriority,
        );

    return (
        <BackgroundColour colour={CRMColors.NEUTRAL_PAPER}>
            {/* INPUTS */}
            <MinHeight height={"700px"}>
                <Background padding={CRMSpacing.LARGE}>
                    <CRMAutosaveIndicatorWrap
                        width="100%"
                        status={formStatus}
                    >
                        <SpacingColumn spacing={CRMSpacing.LARGE}>
                            {assignmentForms.map((form, i) =>
                                <AssignmentSection
                                    key={i}
                                    form={form}
                                    caseHandlers={caseHandlers}
                                    caseAssistants={caseAssistants}
                                    onChange={props.onChange}
                                />
                            )}
                        </SpacingColumn>
                    </CRMAutosaveIndicatorWrap>
                </Background>
            </MinHeight>
        </BackgroundColour>
    );
};

const AssignmentSection = (props: {
    form: TCaseAutoAssignmentForm,
    caseHandlers: Array<TUserIdAndFullName>,
    caseAssistants: Array<TUserIdAndFullName>,
    onChange: (form: TCaseAutoAssignmentForm) => void,
}): JSX.Element => {
    const caseHandlerOptions =
        pipe(
            props.caseHandlers,
            array.map(({id, full_name}) => ({
                value: id,
                label: full_name,
            })),
        );

    const caseAssistantOptions =
        pipe(
            props.caseAssistants,
            array.map(({id, full_name}) => ({
                value: id,
                label: full_name,
            })),
        );

    const onChangeCaseHandler = (case_handler_id: string) =>
        props.onChange({
            ...props.form,
            edited: {
                ...props.form.edited,
                case_handler_id: case_handler_id || null,
            }
        });

    const onChangeCaseAssistant = (case_assistant_id: string) =>
        props.onChange({
            ...props.form,
            edited: {
                ...props.form.edited,
                case_assistant_id: case_assistant_id || null,
            }
        });

    return <SpacingColumn spacing={CRMSpacing.MEDIUM}>
        {/* TITLE */}
        <CRMTitleSubSection>
            {TransactionType1_TransactionType4(props.form.edited.transaction_type)} Case
        </CRMTitleSubSection>

        {/* CASE HANDLER */}
        <CRMInputLabelAndErrorWrapComponent label="Case Handler">
            <CRMDropdownComponent
                options={caseHandlerOptions}
                value={props.form.edited.case_handler_id || ""}
                onChange={onChangeCaseHandler}
                displayError={false}
            />
        </CRMInputLabelAndErrorWrapComponent>

        {/* CASE ASSISTANT */}
        <CRMInputLabelAndErrorWrapComponent label="Case Assistant">
            <CRMDropdownComponent
                options={caseAssistantOptions}
                value={props.form.edited.case_assistant_id || ""}
                onChange={onChangeCaseAssistant}
                displayError={false}
            />
        </CRMInputLabelAndErrorWrapComponent>
    </SpacingColumn>;
};
