import React from "react";
import { useCountdown } from "../../../hooks/useCountdown";
import { CRMColors } from "../../../models/CRMColors";
import { CRMProgressBar } from "../CRMProgressBar/CRMProgressBar";
import { THexColor } from "../../../models/StringLiterals";

type TCountdownBarProps = {
    duration: number;
    onComplete?: () => void;
    fullBarColor?: THexColor;
    emptyBarColor?: THexColor;
};

export const CRMLoadingBar = (props: React.PropsWithChildren<TCountdownBarProps>): JSX.Element => {
    
    const { progress } = useCountdown({
        startingAmount: 100,
        durationInMs: props.duration,
        framesPerSecond: 8,
        isPaused: false,
        onCountdownComplete: props.onComplete,
    });

    const FullBarColor = props.fullBarColor || CRMColors.HIGHLIGHTS_POSITIVE_GREEN_2;
    const EmptyBarColor = props.emptyBarColor || CRMColors.NEUTRAL_8;
    
    return (
        <CRMProgressBar
            height="1px"
            progress={100 - progress}
            fullBarColor={FullBarColor}
            emptyBarColor={EmptyBarColor}
        />
    );
};
