import React from "react";
import { FrontOutsidePopupBlank } from "../FrontOutsidePopupBlank/FrontOutsidePopupBlank";
import { FontQuicksand } from "../../../BuildingBlocks/FontQuicksand";
import { FontSize } from "../../../BuildingBlocks/FontSize";
import { FrontFontSizes } from "../../../../models/FrontFontSizes";
import { TextColor } from "../../../BuildingBlocks/TextColor";
import { FrontColors } from "../../../../models/FrontColors";
import FrontParagraphSmall from "../../Simple/FrontParagraphSmall/FrontParagraphSmall";
import { SpacingColumn } from "../../../BuildingBlocks/SpacingColumn";
import { FrontSpacing } from "../../../../models/FrontSpacing";
import { SpacingRow } from "../../../BuildingBlocks/SpacingRow";
import { FrontIcon, TIcon } from "../../Simple/FrontIcon/FrontIcon";
import { FrontFormSecondaryButton } from "../../Simple/FrontFormSecondaryButton/FrontFormSecondaryButton";
import { FrontFormPrimaryButton } from "../../Simple/FrontFormPrimaryButton/FrontFormPrimaryButton";
import { Padding } from "../../../BuildingBlocks/Padding";

type TCookieBannerProps = {
    isResolved: boolean;
    title: JSX.Element;
    sections: Array<TCookieBannerSection>;
    onAccept: () => void;
    onReject: () => void;
};

type TCookieBannerSection = {
    title: JSX.Element;
    points: Array<TCookieBannerPoint>;
}

type TCookieBannerPoint = {
    icon: TIcon;
    text: JSX.Element;
};

export const FrontCookieBanner = (props: React.PropsWithChildren<TCookieBannerProps>): JSX.Element => {

    return (
        <div className={`front-cookie-banner`}>
            <FrontOutsidePopupBlank isOpen={!props.isResolved}>
                <SpacingColumn spacing={FrontSpacing.MEDIUM_2}>
                    {/* TITLE */}
                    <FontQuicksand>
                        <FontSize size={FrontFontSizes.LARGE} lineHeight="1.5">
                            <TextColor color={FrontColors.NEUTRAL_2}>
                                {props.title}
                            </TextColor>
                        </FontSize>
                    </FontQuicksand>

                    {/* SECTIONS */}
                    {props.sections.map((section, sectionIndex) => (
                        <SpacingColumn
                            key={sectionIndex}
                            spacing={FrontSpacing.SMALL_3}
                        >
                            {/* SECTION TITLE */}
                            <FrontParagraphSmall>
                                {section.title}
                            </FrontParagraphSmall>
                            {/* POINTS */}
                            <SpacingColumn spacing={FrontSpacing.SMALL_3}>
                                {section.points.map((point, pointIndex) => (
                                    <SpacingRow
                                        key={pointIndex} 
                                        spacing={FrontSpacing.SMALL_3}
                                    >
                                        <FrontIcon
                                            iconName={point.icon}
                                            colour="neutral-0"
                                        />
                                        <FrontParagraphSmall>
                                            {point.text}
                                        </FrontParagraphSmall>
                                    </SpacingRow>
                                ))}
                            </SpacingColumn>
                        </SpacingColumn>    
                    ))}

                    {/* BUTTONS */}
                    <Padding type="top" spacing={FrontSpacing.SMALL_2}>
                        <SpacingRow spacing={FrontSpacing.SMALL_3}>
                            <FrontFormPrimaryButton
                                label="I agree"
                                onClick={props.onAccept}
                            />
                            <FrontFormSecondaryButton
                                label="Do not track"
                                onClick={props.onReject}
                            />
                        </SpacingRow>
                    </Padding>

                </SpacingColumn>
            </FrontOutsidePopupBlank>
        </div>
    );
};
