import React from "react";
import { pipeable } from "fp-ts";
import { TEmailCompositionForm } from "../../../../../../domain/codecs/form/EmailCompositionForm";
import { CRMSpacing } from "../../../../models/CRMSpacing";
import { SpacingColumn } from "../../../BuildingBlocks/SpacingColumn";
import { WeightSemiBold } from "../../../WeightSemiBold/WeightSemiBold";
import { CRMColors } from "../../../../models/CRMColors";
import { Background } from "../../../BuildingBlocks/Background";
import { Text } from "../../../BuildingBlocks/Text";
import { CRMFontSizes } from "../../../../models/CRMFontSizes";
import { WeightBold } from "../../../WeightBold/WeightBold";
import { CRMParagraphBulletPoints } from "../../../CRMBulletList/CRMParagraphBulletPoints";
import { ifTrueInject } from "../../../../../../shared/src/utilsByDomain/array";

type TCRMComposeEmailErrorMessagesProps = {
    form: TEmailCompositionForm;
};

export const CRMComposeEmailErrorMessages = (props: React.PropsWithChildren<TCRMComposeEmailErrorMessagesProps>): JSX.Element => {

    const getSelectedCase = () => 
        props.form.children.cases_search_form.children.find((legalCase) => legalCase.id === props.form.edited.caseID)
    ;

    const hasRecepientOptions = (): boolean => {
        const selectedCase = getSelectedCase();
        if (!!selectedCase) {
            return (
                selectedCase.emails.length > 0 
                || !!props.form.edited.sendTo
            );
        }
        return false;
    }
    
    const hasSenderOptions = (): boolean => {
        const selectedCase = getSelectedCase();
        if (!!selectedCase) {
            return (
                selectedCase.case_handlers.length > 0
                || !!props.form.edited.sentFrom
            );
        }
        return false;
    }

    const isCaseSelected = () => !!getSelectedCase();  

    const hasError = () => (
        isCaseSelected() &&
        (
            !hasRecepientOptions()
            || !hasSenderOptions()
        )
    );
    
    if (!hasError()) {
        return (<></>);
    }

    return (
        <Background
            padding={CRMSpacing.MEDIUM}
            color={CRMColors.HIGHLIGHTS_INSTRUCTIONAL_BRICK_12}
        >
            <SpacingColumn spacing={CRMSpacing.SMALL}>

                {/* ERROR MESSAGE INTRODUCTION */}
                <Text size={CRMFontSizes.SMALL} color={CRMColors.HIGHLIGHTS_INSTRUCTIONAL_BRICK_0}>
                    <WeightBold>
                        This email can not be sent because it has no: 
                    </WeightBold>
                </Text>

                {/* ERROR BULLET POINTS */}
                <Background padding={`0px 0px 0px ${CRMSpacing.TINY}`}>
                    <CRMParagraphBulletPoints
                        bulletColor={CRMColors.HIGHLIGHTS_INSTRUCTIONAL_BRICK_0}
                        bullets={pipeable.pipe(
                            [],
                            ifTrueInject(
                                () => !hasSenderOptions(),
                                <Text size={CRMFontSizes.SMALL}
                                    color={CRMColors.HIGHLIGHTS_INSTRUCTIONAL_BRICK_0}
                                >
                                    <WeightSemiBold>
                                        case handlers assigned to the case
                                    </WeightSemiBold>
                                </Text>
                            ),
                            ifTrueInject(
                                () => !hasRecepientOptions(),
                                <Text size={CRMFontSizes.SMALL}
                                    color={CRMColors.HIGHLIGHTS_INSTRUCTIONAL_BRICK_0}
                                >
                                    <WeightSemiBold>
                                        possible recepients added to the case
                                    </WeightSemiBold>
                                </Text>
                            ),
                        )}
                    />
                </Background>
            </SpacingColumn>
        </Background>
    );
};
