import * as rxjs from "rxjs";
import { array, option, identity } from "fp-ts";
import * as rxjsOperators from "rxjs/operators";
import { TSetState } from "../state/TSetState";
import { TGetState } from "../state/TGetState";
import { pipe } from "fp-ts/lib/function";

export type TActionablePack<P extends Array<unknown>> = {
    setState: TSetState;
    getState: TGetState;
    payloads: P;
};

export const of = <P extends Array<unknown>>(setState: TSetState, getState: TGetState, payloads: P): rxjs.Observable<TActionablePack<P>> =>
    rxjs.of({
        setState,
        getState,
        payloads,
    });

const updateAtPayloads = <TI extends number, P extends Array<unknown>>(toIndex: number, response: P[TI], payloads: P): P =>
    option.fold<P, P>(
        () => payloads,
        identity.flatten,
    )(array.updateAt(toIndex, response)(payloads) as option.Option<P>);

export const fromPayloadIndex = <FI extends number, TI extends number, P extends Array<unknown>>(
    fromIndex: number,
    toIndex: number,
    operator: (payload: P[FI]) => rxjs.Observable<P[TI]>
) =>
    (obs$: rxjs.Observable<TActionablePack<P>>): rxjs.Observable<TActionablePack<P>> =>
        obs$.pipe(
            rxjsOperators.mergeMap((v) =>
                operator(v.payloads[fromIndex])
                    .pipe(
                        rxjsOperators.map((response) => {
                            v.payloads = updateAtPayloads<TI, P>(toIndex, response, v.payloads);
                            return v;
                        })
                )
            )
        );

export const fromPayloadIndexForkJoin = <FI extends number, TI extends number, P extends Array<unknown>>(
    fromIndex: FI,
    toIndexOperators: [
        [TI, (payload: P[FI]) => rxjs.Observable<P[TI]>],
        [TI, (payload: P[FI]) => rxjs.Observable<P[TI]>],
        ...Array<[TI, (payload: P[FI]) => rxjs.Observable<P[TI]>]>
    ],
) =>
    (obs$: rxjs.Observable<TActionablePack<P>>): rxjs.Observable<TActionablePack<P>> =>
        obs$.pipe(
            rxjsOperators.mergeMap((v) =>
                rxjs.forkJoin(
                     pipe(
                        toIndexOperators,
                        array.map(([toIndex, operator]) =>
                            operator(v.payloads[fromIndex])
                                .pipe(
                                    rxjsOperators.map<P[TI], [number, P[TI]]>((response) => [toIndex, response]),
                                ),
                        ),
                    )
                )
                    .pipe(
                        rxjsOperators.map(
                            array.map(([toIndex, response]) =>
                                v.payloads = updateAtPayloads<TI, P>(toIndex, response, v.payloads),
                            ),
                        ),
                        rxjsOperators.mapTo(v),
                    ),
            ),
        );

export const fromPayloads =<TI extends number, P extends Array<unknown>>(
    toIndex: number,
    operator: (payload: P) => rxjs.Observable<P[TI]>
) =>
    (obs$: rxjs.Observable<TActionablePack<P>>): rxjs.Observable<TActionablePack<P>> =>
        obs$.pipe(
            rxjsOperators.mergeMap((v) =>
                operator(v.payloads)
                    .pipe(
                        rxjsOperators.map((response) => {
                            v.payloads = updateAtPayloads<TI, P>(toIndex, response, v.payloads);
                            return v;
                        })
                )
            )
        );

export const tapPayloads = <P extends Array<unknown>>(operator: (payload: P) => void) =>
    (obs$: rxjs.Observable<TActionablePack<P>>): rxjs.Observable<TActionablePack<P>> =>
        obs$.pipe(
            rxjsOperators.tap((v) => operator(v.payloads))
        );
