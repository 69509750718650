import React, { useEffect } from "react";
import { IFuzzySortOption, useFuzzysort } from "../../../hooks/useFuzzysort";
import { SpacingColumn } from "../../BuildingBlocks/SpacingColumn";
import { Border } from "../../BuildingBlocks/Border";
import { CRMColors } from "../../../models/CRMColors";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { Background } from "../../BuildingBlocks/Background";
import { Relative } from "../../BuildingBlocks/Relative";
import { Sticky } from "../../BuildingBlocks/Sticky";
import { BackgroundColour } from "../../BuildingBlocks/BackgroundColour";
import { CRMLoadingBar } from "../../Simple/CRMLoadingBar/CRMLoadingBar";
import { CRMEmptyPlaceholder } from "../../Simple/CRMEmptyPlaceholder/CRMEmptyPlaceholder";
import { TFormStatus } from "../../../../../shared/src/codecs/codec";
import CRMInputGeneralComponent from "../../CRMInputs/CRMInputGeneralComponent/CRMInputGeneralComponent";
import { isAFunction, isNotAFunction } from "../../../functions/functions";
import { TPixelSize } from "../../../models/StringLiterals";
import { contains } from "../../../../../shared/src/utilsByDomain/array";


export type TOnChangeSearchTextFunction = (searchText: string) => void; 

type TRenderCustomSearchInputFunction <M extends Record<string, unknown>> = (
    searchText: string, 
    onChangeSearchText: TOnChangeSearchTextFunction,
    results: Fuzzysort.KeyResults<IFuzzySortOption<string, M>>,
) => JSX.Element;

type TCRMFilterSearchSelectProps <M extends Record<string, unknown>> = {
    searchStickyTopPosition?: TPixelSize;
    searchPlaceholder?: string;
    noMatchesText?: string;
    matchingText?: string;
    // SEARCH
    searchFormStatus?: TFormStatus;
    searchText?: string;
    keepOriginalSearchResultsOrder?: boolean;
    onChangeSearchText?: TOnChangeSearchTextFunction;
    renderCustomSearchInput?: TRenderCustomSearchInputFunction<M>;
    // OPTIONS
    options: Array<IFuzzySortOption<string, M>>;
};

type TFilterSearchSelectStatus =
    "no-matches"
    | "matching"
    | "matched"
;

export const CRMFilterSearchSelect = <M extends Record<string, unknown>>(props: React.PropsWithChildren<TCRMFilterSearchSelectProps<M>>): JSX.Element => {

    const fuzzySortState = useFuzzysort<string, M>(props.options);
    
    useEffect(
        () => {
            fuzzySortState.setSearchOptions(props.options)
        },
        [props.options]
    );

    const getStatus = (): TFilterSearchSelectStatus => {

        if (contains(props.searchFormStatus)(["loading", "requiresSubmission", "submitting"])) {
            return "matching";
        }

        if (contains(fuzzySortState.getStatus())(["matching", "untouched"])) {
            return "matched";
        }
        return "no-matches";
    }

    const onChangeSearchText = (searchText: string) => {
        
        fuzzySortState.setSearchText(searchText);

        if (isAFunction<TOnChangeSearchTextFunction>(props.onChangeSearchText)) {
            props.onChangeSearchText(searchText);
        }
    }

    return (
        <Relative>
            
            {/* [HEADER] - TITLE & SEARCH  */}
            <Sticky top={props.searchStickyTopPosition || "0px"}>
                <SpacingColumn>
                    <Border 
                        type="bottom" 
                        definition={getStatus() === "matching" ? "none" : `1px solid ${CRMColors.NEUTRAL_8}`}
                    >
                        <BackgroundColour colour={CRMColors.NEUTRAL_PAPER}>
                            <SpacingColumn>
                                {/* SEARCH INPUT */}
                                <Background padding={CRMSpacing.MEDIUM}>
                                    {/* DEFAULT SEARCH INPUT */}
                                    {isNotAFunction(props.renderCustomSearchInput) &&
                                        <CRMInputGeneralComponent
                                            inputType="text"
                                            placeholder={props.searchPlaceholder || "Enter search query"}
                                            value={fuzzySortState.searchText}
                                            displayError={false}
                                            onChange={onChangeSearchText}
                                            onPressEnterKey={() => null}
                                        />
                                    }

                                    {/* CUSTOM RENDER SEARCH INPUT */}
                                    {isAFunction<TRenderCustomSearchInputFunction<M>>(props.renderCustomSearchInput) &&
                                        props.renderCustomSearchInput(
                                            fuzzySortState.searchText,
                                            onChangeSearchText,
                                            fuzzySortState.getSearchResults(),
                                        )
                                    }
                                </Background>
                            </SpacingColumn>
                        </BackgroundColour>
                    </Border>

                    {/* SEARCH PROGRESS */}
                    {getStatus() === "matching" && 
                        <CRMLoadingBar duration={1000} />
                    }
                </SpacingColumn>
            </Sticky>


            {/* [PLACEHOLDER] - NO MATCHES */}
            {getStatus() === "no-matches" &&
                <Background padding={CRMSpacing.LARGE}>
                    <CRMEmptyPlaceholder
                        iconSize="70px"
                        textGap={CRMSpacing.TINY}
                    >
                        {props.noMatchesText || "No results found."}
                    </CRMEmptyPlaceholder>
                </Background>
            }
            
            {/* [PLACEHOLDER] - MATCHING */}
            {getStatus() === "matching" &&
                <Background padding={CRMSpacing.LARGE}>
                    <CRMEmptyPlaceholder
                        iconSize="70px"
                        iconName="search-list"
                        textGap={CRMSpacing.TINY}
                    >
                        {props.matchingText || "Matching..."}
                    </CRMEmptyPlaceholder>
                </Background>
            }

            {/* [BODY] - MATCHES */}
            {getStatus() === "matched" &&
                <SpacingColumn>
                    {fuzzySortState
                        .getSearchResults(props.keepOriginalSearchResultsOrder)
                        .map((result, index) => (
                            <div key={`${result.obj.value}`}>
                                {typeof result.obj.richLabelFormatting === "function" &&
                                    result.obj.richLabelFormatting(
                                        result, 
                                        fuzzySortState.searchText
                                    )
                                }
                            </div>
                        ))
                    }
                </SpacingColumn>
            }
        </Relative>
    );
};
