import React, { useState } from "react";
import { CaseLedgerType_displayString, TCaseLedgerCategory } from "../../../../../domain/codecs/CaseLedger";
import { TCaseLedgerEntryForm } from "../../../../../domain/codecs/form/CaseLedgerEntryForm";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { CRMTable } from "../../Simple/CRMTable/CRMTable";
import { WeightBold } from "../../WeightBold/WeightBold";
import { CRMCardOutsidePopupBasic } from "../../CRMCardOutsidePopupBasic/CRMCardOutsidePopupBasic";
import { CRMParagraph } from "../../Simple/CRMParagraph/CRMParagraph";
import { SpacingColumn } from "../../BuildingBlocks/SpacingColumn";
import { penceToCopyText } from "../../../../../shared/src/util";
import { CRMCaseLedgerEntryRow } from "../CRMCaseLedgerEntryRow/CRMCaseLedgerEntryRow";
import { CRMTableHeaderRow } from "../../Simple/CRMTableHeaderRow/CRMTableHeaderRow";
import { pipe } from "fp-ts/lib/function";
import { array, option } from "fp-ts";
import { CRMSpacer } from "../../CRMSpacer/CRMSpacer";
import { CRMTableSubTitleRow } from "../../Simple/CRMTableSubTitleRow/CRMTableSubTitleRow";
import { Sticky } from "../../BuildingBlocks/Sticky";
import { Ord as SOrd } from "fp-ts/lib/string";
import { contramap } from "fp-ts/lib/Ord";

type TCaseLedgerTableProps = {
    entries: Array<TCaseLedgerEntryForm>;
    ledgerIsFinal: boolean;
    onDelete: (form: TCaseLedgerEntryForm) => void;
    onChange: (form: TCaseLedgerEntryForm) => void;
};

type CategoryOrderObj = {
    [K in TCaseLedgerCategory]: {
        displayTitle: string,
    };
};

// As of ES2015 string keys maintain insertion order (note number-like keys do not)
const ledgerCategoryDetailsOrdered: CategoryOrderObj = {
    transfer_in_client: { 
        displayTitle: "Payments from Client",
    },
    transfer_in_third_party: { 
        displayTitle: "Payments from Others",
    },
    fee: { 
        displayTitle: "Fees",
    },
    disbursement: { 
        displayTitle: "Disbursements",
    },
    transfer_out_third_party: {
        displayTitle: "Payments to Others",
    },
    transfer_out: { 
        displayTitle: "Payments to Client",
    },
};

export const CRMCaseLedgerTable = (props: React.PropsWithChildren<TCaseLedgerTableProps>): JSX.Element => {

    const [entryToDelete, setEntryToDelete] = useState<TCaseLedgerEntryForm | null>(null);

    return (
        <div>
            {/* 44px = all the bars */}
            <Sticky zIndex={40} top="42px">
                <CRMTableHeaderRow
                    spacing={CRMSpacing.MEDIUM}
                    spacingRowChildSize={props.ledgerIsFinal ? "1fr 130px 130px 130px" : "1fr 130px 130px 36px 130px"}
                    headers={props.ledgerIsFinal ? ["Item", "", "", "Payment status"] : ["Item", "", "", "Del.", "Payment status"]}
                />
            </Sticky>

            {/* Not using record.keys because we want to preserve order and record.keys sorts alphabetically */}
            {(Object.keys(ledgerCategoryDetailsOrdered) as Array<keyof typeof ledgerCategoryDetailsOrdered>).map((key) => {
                const byCreatedAt = pipe(
                    SOrd,
                    contramap((entry: TCaseLedgerEntryForm) => entry.original.created_at)
                )
                const byType = pipe(
                    SOrd,
                    contramap((entry: TCaseLedgerEntryForm) => entry.original.type)
                )
                const filteredEntries = pipe(
                    props.entries,
                    array.filterMap((item) => 
                        item.edited.category === key ? option.some(item) : option.none
                    ),
                    array.sortBy([byCreatedAt, byType])
                )
                return <React.Fragment key={key}>
                    {filteredEntries.length > 0 && <>
                        <CRMSpacer size="medium"/>
                        <CRMTableSubTitleRow
                            title={ledgerCategoryDetailsOrdered[key].displayTitle}
                        />
                        {/* TABLE */}
                        <CRMTable<TCaseLedgerEntryForm>
                            data={filteredEntries}
                            rowElement={(entry) => (
                                <CRMCaseLedgerEntryRow
                                    entry={entry}
                                    ledgerIsFinal={props.ledgerIsFinal}
                                    onDelete={() => setEntryToDelete(entry)}
                                    onChange={props.onChange}
                                />
                            )}
                        />
                    </>}
                </React.Fragment>;
            })}

            {/* DELETE ENTRY POPUP */}
            <CRMCardOutsidePopupBasic
                isOpen={!!entryToDelete}
                title="Delete entry"
                ctaText="Yes, proceed"
                onCTA={() => {
                    if (entryToDelete) {
                        props.onDelete(entryToDelete);
                        setEntryToDelete(null);
                    }
                }}
                closeText="Cancel"
                onClose={() => setEntryToDelete(null)}
            >
                {!!entryToDelete &&
                    <SpacingColumn spacing={CRMSpacing.MEDIUM}>
                        <CRMParagraph>
                            Are you sure you want to delete ledger entry <WeightBold>{CaseLedgerType_displayString(entryToDelete.original.type)}</WeightBold>? 
                        </CRMParagraph>
                        <CRMParagraph>
                            <WeightBold>
                                Amount: {penceToCopyText(entryToDelete.original.vat_excluded_amount_gbp_pence)} ({entryToDelete.original.credit_or_debit.toUpperCase()})
                            </WeightBold>
                        </CRMParagraph>
                    </SpacingColumn>
                }
            </CRMCardOutsidePopupBasic>
        </div>
    );
};