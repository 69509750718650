import React, { useState } from "react";
import { FrontSpacing } from "../../../../models/FrontSpacing";
import { SpacingColumn } from "../../../BuildingBlocks/SpacingColumn";
import { SpacingRow } from "../../../BuildingBlocks/SpacingRow";
import { FrontFormSecondaryButton } from "../../Simple/FrontFormSecondaryButton/FrontFormSecondaryButton";
import { FrontPopupPrompt } from "../FrontPopupPrompt/FrontPopupPrompt";

type TListFormProps = {
    canSubmit: boolean;
    canDelete?: boolean;
    deletePopupText: JSX.Element;
    deleteLabelText: string;
    onDelete: () => void;
};

export const FrontListForm = (props: React.PropsWithChildren<TListFormProps>): JSX.Element => {

    const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);

    return (
        <div>
            {/* FORM BODY */}
            <SpacingColumn spacing={FrontSpacing.MEDIUM_1}>

                {/* FORM */}
                {props.children}

                {/* DELETE */}
                <SpacingRow spacing={FrontSpacing.MEDIUM_1}>
                    {props.canDelete !== false &&
                        <FrontFormSecondaryButton
                            label={props.deleteLabelText}
                            onClick={() => setIsDeletePopupOpen(true)}
                        />
                    }
                </SpacingRow>
            </SpacingColumn>

            {/* DELETE PROMPT */}
            <FrontPopupPrompt
                isOpen={isDeletePopupOpen}
                title="Just checking"
                closeText="No"
                ctaText="Yes, delete"
                onClose={() => setIsDeletePopupOpen(false)}
                onCTA={() => {
                    props.onDelete();
                    setIsDeletePopupOpen(false);
                }}
            >
                {props.deletePopupText}
            </FrontPopupPrompt>
        </div>
    );
};
