import React from "react";
import { TFrontMainColours } from "../../../../models/TFrontMainColours";
import { array } from "fp-ts";
import { FrontTextColour } from "../../../FrontTextColour/FrontTextColour";
import { pipe } from "fp-ts/lib/function";

export class FrontLegend extends React.Component<{
    rows: Array<{
        key: string;
        keyColour: TFrontMainColours;
        description: string;
    }>;
}> {
    public render (): JSX.Element {
        return (
            <div className="front-legend">
                { pipe(
                    this.props.rows,
                    array.mapWithIndex((i, row) =>
                        <div className={`front-legend__row ${i < this.props.rows.length - 1 ? "front-legend__row--spacer" : ""}`}>
                            <div className="front-legend__cell front-legend__cell--key">
                                <FrontTextColour
                                    colour={row.keyColour}
                                >
                                    {row.key}
                                </FrontTextColour>
                            </div>
                            <div className="front-legend__cell front-legend__cell--description">
                                {row.description}
                            </div>
                        </div>
                    ),
                )}
            </div>
        );
    }
}
