import React from "react";
import { Background } from "../../../BuildingBlocks/Background";
import { CRMIcon } from "../../../CRMIcon/CRMIcon";
import { TCasesCaseForm } from "../../../../../../domain/codecs/form/CasesForm";
import { CRMColors } from "../../../../models/CRMColors";
import { useOpenClose } from "../../../../hooks/UseOpenClose";
import { CRMPopout } from "../../../Simple/CRMPopout/CRMPopout";
import { Absolute } from "../../../BuildingBlocks/Absolute";
import { CRMSpacing } from "../../../../models/CRMSpacing";
import { CRMNumberedList } from "../../Simple/CRMNumberedList/CRMNumberedList";
import { pipe } from "fp-ts/lib/pipeable";
import { array } from "fp-ts";
import { ChaseReasons_displayString } from "../../../Complex/CRMCloseChaseContinueCasePopoutChaserView/CRMCloseChaseContinueCasePopoutChaserView";

type TCaseChaseIndicatorProps = {
    form: TCasesCaseForm;
};

export const CRMCaseChaseIndicator = (props: React.PropsWithChildren<TCaseChaseIndicatorProps>): JSX.Element => {

    const {ref, isOpen, toggleOpen } = useOpenClose();

    const isChased = props.form.children.last_chase_transition.status === "chase";
    const isWorkableTimeout = props.form.children.last_chase_transition.status === "timeout";
    const isChangeAcknowledged = props.form.children.last_chase_transition.acknowledged;
    const chaseReasonsCopy = pipe(
        props.form.children.last_chase_transition.chase_reasons,
        array.map(ChaseReasons_displayString)
    );
    const hasReasons = chaseReasonsCopy.length > 0;

    return (
        <div
            ref={ref}
            className={`crm-case-chase-indicator`}
        >
            <div 
                className={`
                    crm-case-chase-indicator__badge
                    ${isWorkableTimeout ? "crm-case-chase-indicator__badge--timeout" : ""}
                    ${!isWorkableTimeout ? `crm-case-chase-indicator__badge--${isChased ? "chase" : "work"}-${isChangeAcknowledged ? "acknowledged" : "unacknowledged"}` : ""}
                    crm-case-chase-indicator__badge--${hasReasons ? "has-reasons" : "no-reasons"}
                `}
                onClick={toggleOpen}
            >
                <CRMIcon
                    iconName={
                        isChased ? "chase"
                        : isWorkableTimeout ? "chase-timeout"
                        : "chase-stop"
                    }
                    size={"16px"}
                    colour="hex"
                    hexColour={
                        (isChased && isChangeAcknowledged) ? CRMColors.PRIMARY_2
                        : (isChased || isWorkableTimeout) ? CRMColors.HIGHLIGHTS_CALMING_PINK_0 
                        : CRMColors.PRIMARY_0
                    }
                />
            </div>

            {/* CHASE REASONS */}
            {hasReasons && isOpen &&
                <Absolute
                    top={"20px"}
                    left={"0px"}
                    width="300px"
                    zIndex={1}
                >
                    <CRMPopout minWidth={"300px"}>
                        <Background display="flex" padding={CRMSpacing.SMALL}>
                            <CRMNumberedList 
                                list={chaseReasonsCopy}
                            />
                        </Background>
                    </CRMPopout>
                </Absolute>
            }
        </div>
    );
};
