import { array, identity } from "fp-ts";
import { pipe } from "fp-ts/lib/function";
import React from "react";
import { CaseMemberRoleToCopyText } from "../../../../../domain/codecs/CaseMember";
import { TCasesResultsForTriage, resultTypeForTriageToDisplay } from "../../../../../domain/codecs/form/TriageForm";
import { toDisplayString } from "../../../../../domain/models/CaseStatus1";
import { IFuzzySortOption } from "../../../hooks/useFuzzysort";
import { CRMColors } from "../../../models/CRMColors";
import { CRMFontSizes } from "../../../models/CRMFontSizes";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { THexColor } from "../../../models/StringLiterals";
import { AnimationPopout } from "../../BuildingBlocks/AnimationPopout";
import { BackgroundColour } from "../../BuildingBlocks/BackgroundColour";
import { Border } from "../../BuildingBlocks/Border";
import { FontQuicksand } from "../../BuildingBlocks/FontQuicksand";
import { FontSize } from "../../BuildingBlocks/FontSize";
import { InlineFlex } from "../../BuildingBlocks/InlineFlex";
import { Padding } from "../../BuildingBlocks/Padding";
import { SpacingColumn } from "../../BuildingBlocks/SpacingColumn";
import { SpacingRow } from "../../BuildingBlocks/SpacingRow";
import { StyleItalic } from "../../BuildingBlocks/StyleItalic";
import { TextColor } from "../../BuildingBlocks/TextColor";
import { CRMIcon } from "../../CRMIcon/CRMIcon";
import { CRMParagraph } from "../../Simple/CRMParagraph/CRMParagraph";
import { CRMTextHighlight } from "../../CRMTextHighlight/CRMTextHighlight";
import { CRMIconLabel } from "../../Simple/CRMIconLabel/CRMIconLabel";
import { CRMPill } from "../../Simple/CRMPill/CRMPill";
import { CRMThinColoredButton } from "../../Simple/CRMThinColoredButton/CRMThinColoredButton";
import { WeightBold } from "../../WeightBold/WeightBold";
import { WeightMedium } from "../../WeightMedium/WeightMedium";
import { WeightRegular } from "../../WeightRegular/WeightRegular";
import { WeightSemiBold } from "../../WeightSemiBold/WeightSemiBold";

type TFormatFunction = (reference: string) => JSX.Element | string;
type TFormatActionFunction = (eference: string, result: TActionFuzzySortResult) => JSX.Element | string;

// need to pull this out to its on function (dont even call it master format, but rather curry function or something)
const masterFormat = <F extends unknown>(formatFunction?: F) => {
    if (typeof formatFunction === "function") {
        return formatFunction;
    }
    return identity.flatten;
}

type TActionFuzzySortResult = Fuzzysort.KeyResult<IFuzzySortOption<string, TTriageCaseAssignmentCardAction>>;

export type TTriageCaseAssignmentCardAction = {
    backgroundColor: THexColor;
    label: string;
    fuzzySortResult?: Fuzzysort.KeyResult<IFuzzySortOption<string, TTriageCaseAssignmentCardAction>>;
    onClick: () => void;
}

type TTriageCaseAssignmentCardProps = {
    case: TCasesResultsForTriage;
    actions: Array<TTriageCaseAssignmentCardAction>;
    formatAddress?: TFormatFunction;
    formatKlyantMatterId?: TFormatFunction;
    formatClient?: TFormatFunction;
    formatAction?: TFormatActionFunction;
};

export const CRMTriageCaseAssignmentCard = (props: React.PropsWithChildren<TTriageCaseAssignmentCardProps>): JSX.Element => {

    const formatAddress = masterFormat(props.formatAddress);
    const formatKlyantMatterId = masterFormat(props.formatKlyantMatterId);
    const formatClient = masterFormat(props.formatClient);
    const formatAction = masterFormat(props.formatAction);
    const convertedFilteredResultTypes = pipe(
        props.case.result_types,
        array.filter((result) => result !== "manual_search"),
        array.map(resultTypeForTriageToDisplay)
    )

    const hasOnlyOneAction = () => props.actions.length === 1;

    return (
        <Border 
            type="bottom"
            definition={`1px solid ${CRMColors.NEUTRAL_8}`}
        >
            {/* CONTENT */}
            <Padding spacing={CRMSpacing.MEDIUM}>
                <SpacingColumn spacing={CRMSpacing.MEDIUM}>
                    <SpacingRow
                        spacing={CRMSpacing.MEDIUM}
                        justifyContent="space-between"
                        alignItems="flex-start"
                    >
                        {/* LEFT HAND SIDE */}
                        <SpacingColumn spacing={CRMSpacing.TINY}>
                            {/* ADDRESS */}
                            {props.case.addresses.map((address, key) => (
                                <CRMParagraph 
                                    key={key} 
                                    fontSize={CRMFontSizes.SMALL}
                                >
                                    <WeightMedium>
                                        {formatAddress(address)}
                                    </WeightMedium>
                                </CRMParagraph>
                            ))}

                            {/* CASE STATUS */}
                            <CRMParagraph>
                                <StyleItalic>{toDisplayString(props.case.status)}</StyleItalic>
                            </CRMParagraph>

                            {/* AutoMatches Hint */}
                            {convertedFilteredResultTypes.length > 0 &&
                                <CRMParagraph>
                                    <WeightBold>Suggested Because:</WeightBold> <CRMTextHighlight colour="highlights-pertinent-yellow-6" >{convertedFilteredResultTypes.join(", ")}</CRMTextHighlight>
                                </CRMParagraph>
                            }
                        </SpacingColumn>

                        {/* RIGHT HAND SIDE */}
                        <SpacingColumn spacing={CRMSpacing.TINY}>
                            {/* DUAL REP PILL */}
                            {props.case.is_dual_rep &&
                                <CRMPill
                                    mode="single"
                                    colour="highlights-calming-pink-10"
                                >
                                    <WeightSemiBold>
                                        Dual Rep
                                    </WeightSemiBold>
                                </CRMPill>
                            }

                            <SpacingRow spacing={CRMSpacing.TINY}>
                                {/* KLYANT MATTER ID */}
                                <CRMParagraph>
                                    <WeightRegular>
                                        {formatKlyantMatterId(props.case.klyant_matter_id)}
                                    </WeightRegular>
                                </CRMParagraph>

                            
                                {/* CASE TRANSACTION TYPE */}
                                <CRMParagraph>
                                    <WeightBold>
                                        ({props.case.transaction_type[0].toUpperCase()})
                                    </WeightBold>
                                </CRMParagraph>
                            </SpacingRow>
                        </SpacingColumn>
                    </SpacingRow>
                    
                    <SpacingColumn>
                        {/* CLIENTS */}
                        {props.case.contacts.map((contact, key) => (
                            <CRMIconLabel 
                                key={key}
                                iconName="person"
                                label={formatClient(`${contact.name} (${CaseMemberRoleToCopyText(contact.role)})`)}
                            />
                        ))}
                    </SpacingColumn>

                    {/* MULTIPLE ACTIONS */}
                    {props.actions.length > 1 &&
                        <SpacingColumn spacing={CRMSpacing.SMALL}>
                            {props.actions.map((action, index) => (
                                <InlineFlex key={index}>
                                    <CRMThinColoredButton
                                        backgroundColor={action.backgroundColor}
                                        label={formatAction(action.label, action.fuzzySortResult as TActionFuzzySortResult)}
                                        onClick={action.onClick}
                                        icon="arrow-right"
                                    />
                                </InlineFlex>
                            ))}
                        </SpacingColumn>
                    }

                    {/* SINGLE ACTION */}
                    {hasOnlyOneAction() &&
                        <AnimationPopout>
                            <BackgroundColour 
                                colour={props.actions[0].backgroundColor} 
                                borderRadius="5px"
                            >
                                <Padding spacing={CRMSpacing.MEDIUM}>
                                    <SpacingRow justifyContent="space-between">
                                        {/* LABEL */}
                                        <FontQuicksand>
                                            <FontSize 
                                                size={CRMFontSizes.MED} 
                                                lineHeight={1}
                                            >
                                                <TextColor color={CRMColors.NEUTRAL_INK}>
                                                    {formatAction(
                                                        props.actions[0].label, 
                                                        props.actions[0].fuzzySortResult as TActionFuzzySortResult
                                                    )}
                                                </TextColor>
                                            </FontSize>
                                        </FontQuicksand>

                                        {/* ARROW */}
                                        <CRMIcon
                                            iconName="arrow-right"
                                            colour="neutral-ink"
                                        />
                                    </SpacingRow>
                                </Padding>
                            </BackgroundColour>
                        </AnimationPopout>
                    }
                </SpacingColumn>
            </Padding>
        </Border>
    );
};
