import React from "react";
import { SpacingRow } from "../../../BuildingBlocks/SpacingRow";
import { CRMSpacing } from "../../../../models/CRMSpacing";
import { Background } from "../../../BuildingBlocks/Background";
import { FontRoboto } from "../../../BuildingBlocks/FontRoboto";
import { Text } from "../../../BuildingBlocks/Text";
import { FontQuicksand } from "../../../BuildingBlocks/FontQuicksand";
import { SpacingColumn } from "../../../BuildingBlocks/SpacingColumn";

type TNumberedListProps = {
    list: Array<string>;
};

export const CRMNumberedList = (props: React.PropsWithChildren<TNumberedListProps>): JSX.Element => {

    return (
        <SpacingColumn spacing={CRMSpacing.TINY}>
            {props.list.map((text, index) => (
                <SpacingRow
                    alignItems="flex-start"
                    spacing={CRMSpacing.SMALL}
                    childSize="20px 1fr"
                >
                    <ItemNumber>
                        {index+1}.
                    </ItemNumber>

                    <ItemText>
                        {text}
                    </ItemText>
                </SpacingRow>
            ))}
        </SpacingColumn>
    );
};


const ItemNumber = (props: React.PropsWithChildren<{}>) => {
    return (
        <Background display="inline-flex" padding={`3.5px 0px 0px 0px`}>
            <FontRoboto>
                <Text size={12} lineHeight={1} weight={500} align="right">
                    {props.children}
                </Text>
            </FontRoboto>
        </Background>
    );
}

const ItemText = (props: React.PropsWithChildren<{}>) => {
    return (
        <FontQuicksand>
            <Text size={14} lineHeight={1.4}>
                {props.children}
            </Text>
        </FontQuicksand>
    );
}
