import React from "react";
import { FrontSpacing } from "../../../../models/FrontSpacing";
import { SpacingRow } from "../../../BuildingBlocks/SpacingRow";
import { FrontIcon, TIcon } from "../FrontIcon/FrontIcon";

export type TFormAgreeButtonProps = {
    label: string;
    icon?: TIcon;
    hasBoxShadow?: boolean;
    onClick: () => void;
};

export const FrontFormAgreeButton = (props: React.PropsWithChildren<TFormAgreeButtonProps>): JSX.Element => (
    <div
        className={`
            front-form-agree-button
            front-form-agree-button--box-shadow-${props.hasBoxShadow ? "true" : "false" }
        `}
        onClick={() => props.onClick()}
    >
        <SpacingRow
            spacing={FrontSpacing.SMALL_3}
            justifyContent="center"
        >
            {props.icon &&
                <FrontIcon
                    iconName={props.icon}
                    colour={"neutral-18"}
                    size="small"
                />
            }

            <span>{props.label}</span>
        </SpacingRow>
    </div>
);