import React from "react";
import { CRMCardOutsideWarning } from "../../components/CRMCardOutsideWarning/CRMCardOutsideWarning";
import { CRMPadding } from "../../components/Simple/CRMPadding/CRMPadding";
import { CRMParagraph } from "../../components/Simple/CRMParagraph/CRMParagraph";
import CRMListingKeyInfoComponent from "../../components/CRMListingKeyInfoComponent/CRMListingKeyInfoComponent";
import { CRMCardOutsideLabelled } from "../../components/CRMCardOutsideLabelled/CRMCardOutsideLabelled";
import { CRMSpacer } from "../../components/CRMSpacer/CRMSpacer";
import { CRMColumns } from "../../components/CRMColumns/CRMColumns";
import { CRMTitleForCard } from "../../components/CRMTitleForCard/CRMTitleForCard";
import * as TCurrency from "../../../../domain/models/TCurrency";
import { CRMParagraphImportant } from "../../components/Simple/CRMParagraphImportant/CRMParagraphImportant";
import CRMListingAdvantagesDisadvantagesSummaryComponent from "../../components/CRMListingAdvantagesDisadvantagesSummaryComponent/CRMListingAdvantagesDisadvantagesSummaryComponent";
import { CRMListingRenovationAndSaleValueSummary } from "../../components/CRMListingRennovationAndSaleValueSummary/CRMListingRenovationAndSaleValueSummary";
import CRMHighestOffersComponent from "../../components/CRMHighestOffersComponent/CRMHighestOffersComponent";
import { array } from "fp-ts";
import { CRMEnquiryFilesComponent } from "../../components/CRMEnquiryFilesComponent/CRMEnquiryFilesComponent";
import { CRMSellerKeyInfoComponent } from "../../components/CRMSellerKeyInfoComponent/CRMSellerKeyInfoComponent";
import { TContainerStateProps } from "../../state/TContainerStateProps";
import { pipe } from "fp-ts/lib/function";
import { CRMSailHomesAdHocTasksCard } from "../../components/CRMSailHomesAdHocTasksCard/CRMSailHomesAdHocTasksCard";

class CRMListingSummaryForEnquiryContainer extends React.Component<React.PropsWithChildren<TContainerStateProps>> {
    public render(): JSX.Element {
        return (
            <CRMColumns
                padding="large"
                columns={[
                    {
                        flex: 0.35,
                        content: <div>
                            {
                                this.props.state.activeData.crm.enquiry.listingForm.view.status === "sstc" &&
                                this.props.state.activeData.crm.enquiry.listingForm.view.reason_for_sale === "repossession" &&
                            (
                                <>
                                    <CRMCardOutsideWarning
                                        labelColour="highlights-pertinent-yellow-6"
                                        borderRounding="right"
                                        icon="book"
                                    >
                                        <CRMPadding size="medium">
                                            <CRMParagraphImportant>This property is SSTC, however the seller will consider offers</CRMParagraphImportant>
                                        </CRMPadding>
                                    </CRMCardOutsideWarning>
                                    <CRMSpacer size="medium" />
                                </>
                            )}
                            { this.props.state.activeData.crm.enquiry.listingForm.view.video_tour_required_before_viewing &&
                            (
                                <>
                                    <CRMCardOutsideWarning
                                        labelColour="highlights-pertinent-yellow-6"
                                        borderRounding="right"
                                        icon="ondemand-video"
                                    >
                                        <CRMPadding size="medium">
                                            <CRMParagraphImportant>Send a video tour before booking them in for a viewing</CRMParagraphImportant>
                                        </CRMPadding>
                                    </CRMCardOutsideWarning>
                                    <CRMSpacer size="medium" />
                                </>
                            )}
                            <CRMSailHomesAdHocTasksCard
                                addNewTaskFormStatus={this.props.state.forms.listing_enquiry_page.children.new_adhoc_task.status}
                                tasks={this.props.state.forms.listing_enquiry_page.children.adhoc_tasks}
                                onUpdateTasks={(payload) => this.props.dispatch({
                                    type: "CRM_ENQUIRY_UPDATE_AD_HOC_TASKS",
                                    payload,
                                })}
                                onAddNewTask={(task) => this.props.dispatch({
                                    type: "CRM_ENQUIRY_ADD_AD_HOC_TASK",
                                    payload: {
                                        ...this.props.state.forms.listing_enquiry_page.children.new_adhoc_task,
                                        edited: {
                                            ...this.props.state.forms.listing_enquiry_page.children.new_adhoc_task.edited,
                                            task,
                                        },
                                    }
                                })}
                            />
                            <CRMSpacer size="medium" />
                            <CRMCardOutsideLabelled
                                labelColor="highlights-positive-green-6"
                                shadow={true}
                            >
                                <div id="enquiries-files">
                                    <CRMEnquiryFilesComponent
                                        listing={{
                                            ...this.props.state.activeData.crm.enquiry.listingForm.view,
                                            ...this.props.state.activeData.crm.enquiry.listingForm.edit,
                                        }}
                                        form={this.props.state.activeData.crm.enquiry.sendFilesForm}
                                        onChange={(key, value) => this.props.dispatch({
                                            type: "CRM_ENQUIRY_SEND_FILES_INPUT_CHANGED",
                                            payload: {
                                                key,
                                                value,
                                            },
                                        })}
                                        onSubmit={() => this.props.dispatch({
                                            type: "CRM_ENQUIRY_SEND_FILES_SUBMIT",
                                        })}
                                    />
                                </div>
                            </CRMCardOutsideLabelled>
                        </div>,
                    },
                    {
                        flex: 0.65,
                        content: <div>
                            <CRMCardOutsideLabelled
                                labelColor="highlights-instructional-brick-0"
                                shadow={true}
                            >
                                <CRMListingKeyInfoComponent
                                    listing={this.props.state.activeData.crm.enquiry.listingForm.view}
                                />
                            </CRMCardOutsideLabelled>
                            <CRMSpacer size="medium" />
                            <CRMCardOutsideLabelled
                                labelColor="highlights-calming-pink-0"
                                shadow={true}
                            >
                                <CRMListingAdvantagesDisadvantagesSummaryComponent
                                    listing={this.props.state.activeData.crm.enquiry.listingForm.view}
                                />
                            </CRMCardOutsideLabelled>
                            <CRMSpacer size="medium" />
                            <CRMColumns
                                padding="medium"
                                columns={[
                                    {
                                        flex: 1,
                                        content: (
                                            <div>
                                                <CRMCardOutsideLabelled
                                                    labelColor="highlights-calming-pink-4"
                                                    shadow={true}
                                                >
                                                    <CRMTitleForCard>
                                                        The Seller
                                                    </CRMTitleForCard>
                                                    <CRMSpacer size="tiny" />
                                                    <CRMSellerKeyInfoComponent
                                                        listing={this.props.state.activeData.crm.enquiry.listingForm.view}
                                                    />
                                                </CRMCardOutsideLabelled>
                                                {
                                                    this.props.state.activeData.crm.enquiry.listingForm.view.tenure === "leasehold" &&
                                                    <div>
                                                        <CRMSpacer size="medium" />
                                                        <CRMCardOutsideLabelled
                                                            labelColor="highlights-instructional-brick-4"
                                                            shadow={true}
                                                        >
                                                            <CRMTitleForCard>
                                                                Leasehold information
                                                            </CRMTitleForCard>
                                                            <CRMSpacer size="tiny" />
                                                            <CRMParagraph>
                                                                {
                                                                    this.props.state.activeData.crm.enquiry.listingForm.view.lease_length_remaining_years
                                                                    ? `${this.props.state.activeData.crm.enquiry.listingForm.view.lease_length_remaining_years.toString()} years remaining`
                                                                    : "Lease length: Unknown"
                                                                }
                                                            </CRMParagraph>
                                                            <CRMSpacer size="tiny" />
                                                            <CRMParagraph>
                                                                <strong>
                                                                    Service charge:
                                                                </strong>
                                                                &nbsp;
                                                                {
                                                                    this.props.state.activeData.crm.enquiry.listingForm.view.lease_service_charge_pcy
                                                                    ? `${TCurrency.fromNumber(this.props.state.activeData.crm.enquiry.listingForm.view.lease_service_charge_pcy)} p/a`
                                                                    : "Unknown"
                                                                }
                                                            </CRMParagraph>
                                                            <CRMSpacer size="tiny" />
                                                            <CRMParagraph>
                                                                <strong>
                                                                    Ground rent:
                                                                </strong>
                                                                &nbsp;
                                                                {
                                                                    this.props.state.activeData.crm.enquiry.listingForm.view.lease_ground_rent_pcy
                                                                    ? `${TCurrency.fromNumber(this.props.state.activeData.crm.enquiry.listingForm.view.lease_ground_rent_pcy)} p/a`
                                                                    : "Unknown"
                                                                }
                                                            </CRMParagraph>
                                                        </CRMCardOutsideLabelled>
                                                    </div>
                                                }
                                            </div>
                                        ),
                                    },
                                    {
                                        flex: 1,
                                        content: (<div>
                                            <CRMCardOutsideLabelled
                                                labelColor="highlights-positive-green-4"
                                                shadow={true}
                                            >
                                                <CRMHighestOffersComponent
                                                    offers={ pipe(
                                                        this.props.state.activeData.crm.enquiry.highestOffersForms.forms,
                                                        array.map((offerForm) => offerForm.view)
                                                    )}
                                                    enquiriesRelatedToOffers={ pipe(
                                                        this.props.state.activeData.crm.enquiry.enquiriesRelatedToOffersForms.forms,
                                                        array.map((enquiryForm) => enquiryForm.view)
                                                    )}
                                                    partiesRelatedToOffers={ pipe(
                                                        this.props.state.activeData.crm.enquiry.partiesRelatedToOffersForms.forms,
                                                        array.map((partyForm) => partyForm.view)
                                                    )}
                                                />
                                            </CRMCardOutsideLabelled>
                                            <CRMSpacer size="medium" />
                                            <CRMCardOutsideLabelled
                                                labelColor="highlights-pertinent-yellow-0"
                                                shadow={true}
                                            >
                                                <CRMListingRenovationAndSaleValueSummary
                                                    listing={this.props.state.activeData.crm.enquiry.listingForm.view}
                                                />
                                            </CRMCardOutsideLabelled>
                                        </div>),
                                    },
                                ]}
                            />
                        </div>,
                    },
                ]}
            />
        );
    }
}

export default CRMListingSummaryForEnquiryContainer;
