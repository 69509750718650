import { Observable, timer } from "rxjs";
import { tap, mergeMap, debounce, switchMap } from "rxjs/operators";
import { TCodecSetState, TGetCodecState } from "../applyActions";
import { formOperation } from "../../wrappers/formOperation";
import { TActionsDefinitionsList } from "./TAction";
import { action, routeAction } from "./actionFunctions";
import { TStateLens } from "../../../../domain/codecs/state";
import { TCaseTitleCheckForm } from "../../../../domain/codecs/form/CaseTitleCheckForm";
import { runAutomaticUpdates } from "./automaticBlockUpdate";
import { CasePropertyNewTitleIssueForm } from "../../../../domain/codecs/form/CasePropertyNewTitleIssueForm";
import { CasePropertyDeleteTitleIssueForm } from "../../../../domain/codecs/form/CasePropertyDeleteTitleIssueForm";

export const actions: TActionsDefinitionsList = [
    routeAction("VIEW_CRM_LEGAL_CASE", (obs$, lens, setState) => {
        obs$.pipe(
            tap((a) => setState(lens.case_title_checks_block.edited.case_id.set(a.params.caseId))),
        ).subscribe();
    }),
    action("LEGAL_CASE_TITLE_CHECKS_BLOCK_VIEW", (obs$, lens, setState, getState) => {
        obs$.pipe(
            $loadSection(lens, setState, getState),
        ).subscribe();
    }),
    action("LEGAL_CASE_TITLE_CHECKS_BLOCK_FORM_CHANGE", (obs$: Observable<TCaseTitleCheckForm>, lens, setState, getState, routes, dispatch) => {
        obs$.pipe(
            tap((payload) => setState(lens.case_title_checks_block.children.title_checks.where((check) => payload.original.id === check.original.id).set(payload))),
            debounce(() => timer(1000)),
            tap((payload) => setState(lens.case_title_checks_block.children.title_checks.where((check) => payload.original.id === check.original.id).status.set("submitting"))),
            switchMap((payload) => formOperation("UpdateCaseTitleCheck", lens.case_title_checks_block.children.title_checks.where((check) => payload.original.id === check.original.id).get()(getState()))),
            tap((response) => setState(lens.case_title_checks_block.children.title_checks.where((check) => response.original.id === check.original.id).set({
                ...response,
                edited: {
                    ...lens.case_title_checks_block.children.title_checks.where((check) => response.original.id === check.original.id).edited.get()(getState()), // don't want overwrite what someone is typing
                }
            }))),
            tap(() => runAutomaticUpdates(routes, dispatch, getState, "case_title_checks"))
        ).subscribe();
    }),
    action("LEGAL_CASE_TITLE_CHECKS_BLOCK_ADD_OTHER_TITLE_ISSUE", (obs$: Observable<{ titleCheckId: string, text: string }>, lens, setState, getState, routes, dispatch) => {
        obs$.pipe(
            tap((payload) => setState(lens.case_title_checks_block.children.title_checks.where((check) => payload.titleCheckId === check.original.id).children.new_title_check_issue_form.set(
                {
                    ...CasePropertyNewTitleIssueForm.newDefault(),
                    edited: {
                        case_property_id: payload.titleCheckId,
                        description: payload.text,
                    },
                    status: "submitting",
                }
            ))),
            switchMap((payload) => formOperation("CreateCasePropertyNewTitleIssueForm", lens.case_title_checks_block.children.title_checks.where((check) => payload.titleCheckId === check.original.id).children.new_title_check_issue_form.get()(getState()))),
            tap((response) => setState(lens.case_title_checks_block.children.title_checks.where((check) => response.edited.case_property_id === check.original.id).children.new_title_check_issue_form.set(response))),
            $loadSection(lens, setState, getState),
            tap(() => runAutomaticUpdates(routes, dispatch, getState, "case_title_checks")),
        ).subscribe();
    }),
    action("LEGAL_CASE_TITLE_CHECKS_BLOCK_DELETE_OTHER_TITLE_ISSUE", (obs$: Observable<{ titleCheckId: string, issueId: string }>, lens, setState, getState, routes, dispatch) => {
        obs$.pipe(
            tap((payload) => setState(lens.case_title_checks_block.children.title_checks.where((check) => payload.titleCheckId === check.original.id).children.delete_title_check_issue_form.set(
                {
                    ...CasePropertyDeleteTitleIssueForm.newDefault(),
                    edited: {
                        id: payload.issueId,
                    },
                    status: "submitting",
                }
            ))),
            switchMap((payload) => formOperation("DeleteCasePropertyTitleIssue", lens.case_title_checks_block.children.title_checks.where((check) => payload.titleCheckId === check.original.id).children.delete_title_check_issue_form.get()(getState())).
            then((response) => [response, payload] as const)),
            tap(([response, payload]) => setState(lens.case_title_checks_block.children.title_checks.where((check) => payload.titleCheckId === check.original.id).children.delete_title_check_issue_form.set(response))),
            $loadSection(lens, setState, getState),
            tap(() => runAutomaticUpdates(routes, dispatch, getState, "case_title_checks")),
        ).subscribe();
    }),
];

const $loadSection = <T>(lens: TStateLens, setState: TCodecSetState, getState: TGetCodecState) =>
    (obs$: Observable<T>) =>
        obs$.pipe(
            mergeMap(() => formOperation("GetCaseTitleChecks", lens.case_title_checks_block.get()(getState()))),
            tap((response) => setState(lens.case_title_checks_block.set(response))),
        )
    ;