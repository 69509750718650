import React from "react";
import { TContainerStateProps } from "../../state/TContainerStateProps";
import { CRMPageWrap } from "../../components/Simple/CRMPageWrap/CRMPageWrap";
import { CRMLegalMenuBarContainer } from "../../containers/CRMLegalMenuBarContainer/CRMLegalMenuBarContainer"
import { Padding } from "../../components/BuildingBlocks/Padding";
import { SpacingRow } from "../../components/BuildingBlocks/SpacingRow";
import { CRMSpacing } from "../../models/CRMSpacing";
import { CRMCompanyPartnersBlock } from "../../components/CRMCompanyPartner/CRMCompanyPartnersBlock/CRMCompanyPartnersBlock";
import { CRMBlock } from "../../components/CRM/CRMBlock/CRMBlock/CRMBlock";

const CRMLegalClientCompanyPartnersPage = (props: TContainerStateProps): JSX.Element => {

    return (
        <CRMPageWrap>
            {/* HEADER */}
            <CRMLegalMenuBarContainer {...props} />

            {/* BODY */}
            <Padding
                type="custom" 
                spacing={`${CRMSpacing.X_LARGE} ${CRMSpacing.X_LARGE} 0px`}
            >
                <SpacingRow
                    spacing={CRMSpacing.X_LARGE}
                    alignItems="flex-start"
                >
                    {/* BLOCK - COMPANY PARTNERS */}
                    <CRMBlock
                        mode="fixed-option"
                        fixedOption={{
                            icon: "handshake",
                            title: "Company Partners"
                        }}
                        optionGroups={[]}
                    >
                        <CRMCompanyPartnersBlock
                            form={props.state.forms.client_company_partners_page}
                            onChange={(payload) => props.dispatch({
                                type: "CLIENT_COMPANY_PARTNER_CHANGE",
                                payload,
                            })}
                            onSave={(payload) => props.dispatch({
                                type: "CLIENT_COMPANY_PARTNER_SAVE",
                                payload,
                            })}
                            onCreate={() => props.dispatch({
                                type: "CLIENT_COMPANY_PARTNER_CREATE",
                                payload: props.state.forms.client_company_partners_page.children.create_form,
                            })}
                            onDelete={(payload) => props.dispatch({
                                type: "CLIENT_COMPANY_PARTNER_DELETE",
                                payload,
                            })}
                            onAddressAutocomplete={(payload) => props.dispatch({
                                type: "CLIENT_COMPANY_PARTNER_ADDRESS_AUTOCOMPLETE",
                                payload,
                            })}
                            onUploadContract={(payload) => props.dispatch({
                                type: "CLIENT_COMPANY_PARTNER_CONTRACT_UPLOAD",
                                payload,
                            })}
                            onDeleteContract={(payload) => props.dispatch({
                                type: "CLIENT_COMPANY_PARTNER_CONTRACT_DELETE",
                                payload,
                            })}
                            onChangeUser={(payload) => props.dispatch({
                                type: "CLIENT_COMPANY_PARTNER_USER_CHANGE",
                                payload,
                            })}
                            onSaveUser={(payload) => props.dispatch({
                                type: "CLIENT_COMPANY_PARTNER_USER_SAVE",
                                payload,
                            })}
                            onDeleteUser={(payload) => props.dispatch({
                                type: "CLIENT_COMPANY_PARTNER_USER_DELETE",
                                payload,
                            })}
                            onUserPhoneNumberChange={(payload) => props.dispatch({
                                type: "CLIENT_COMPANY_PARTNER_USER_PHONE_NUMBER_CHANGE",
                                payload,
                            })}
                            onUserNewPhoneNumber={(payload) => props.dispatch({
                                type: "CLIENT_COMPANY_PARTNER_USER_PHONE_NUMBER_CREATE",
                                payload,
                            })}
                            onUserPhoneNumberMakePrimary={(payload) => props.dispatch({
                                type: "CLIENT_COMPANY_PARTNER_USER_PHONE_NUMBER_MAKE_PRIMARY",
                                payload,
                            })}
                            onUserPhoneNumberDelete={(payload) => props.dispatch({
                                type: "CLIENT_COMPANY_PARTNER_USER_PHONE_NUMBER_DELETE",
                                payload,
                            })}
                        />
                    </CRMBlock>
                </SpacingRow>
            </Padding>
        </CRMPageWrap>
    );
}

export default CRMLegalClientCompanyPartnersPage;
