import React, { ReactElement, ReactFragment } from "react";
import { array } from "fp-ts";
import { pipe } from "fp-ts/lib/function";

interface ITabButton {
    label: React.ReactNode;
    side: "left" | "right";
}

interface ITabContent {
    label: string | ReactFragment;
    hasErrors?: boolean;
    children: ReactElement | Array<ReactElement> | string;
}

interface ICRMTabsProps {
    tabs: Array<ITabContent>;
    buttons: Array<ITabButton>;
    goToNewTabs: boolean;
    // Will display an error message if no tabs provided but in future this message could be set by props if wanted
}

interface ICRMTabsState {
    activeKey: number;
}

export class CRMHorizontalTabs extends React.Component<React.PropsWithChildren<ICRMTabsProps>, ICRMTabsState> {

    public constructor (props: ICRMTabsProps) {
        super(props);
        this.state = {
            activeKey: 0,
        };
    }

    public componentDidUpdate (previousProps: ICRMTabsProps): void {
        // When a new tab is added to the props check if we should update the state to show that tab
        if (this.props.goToNewTabs && previousProps.tabs.length < this.props.tabs.length) {
            this.setState({
                activeKey: this.props.tabs.length-1,
            });
        }

        // When the active tab is removed from the props, reset the active tab to the first
        if (previousProps.tabs.length > this.props.tabs.length) {
            this.setState({
                activeKey: 0,
            });
        }
    }

    public render (): JSX.Element {
        return (
            <div className="crm-horizontal-tabs">
                {/* TABS/BUTTONS */}
                <div className="crm-horizontal-tabs__tabs">
                    {/* LEFT HAND BUTTONS */}
                    { pipe(
                        this.props.buttons,
                        array.filter((button) => button.side === "left"),
                        array.mapWithIndex((i, button) =>
                            <div key={i} className="crm-horizontal-tabs__button-tab">
                                {button.label}
                            </div>
                        )
                    )}
                    {/* TABS */}
                    { pipe(
                        this.props.tabs,
                        array.mapWithIndex((index, tab) =>
                            <button
                                key={index}
                                className={`
                                    crm-horizontal-tabs__tab
                                    crm-horizontal-tabs__tab--${tab.hasErrors ? "error" : "no-error"}-${this.state.activeKey === index ? "active" : "inactive"}
                                `}
                                onClick={() => {
                                    this.setState({
                                        activeKey: index,
                                    });
                                }}
                            >
                                {tab.label}
                            </button>
                        )
                    )}
                    {/* RIGHT HAND BUTTONS */}
                    { pipe(
                        this.props.buttons,
                        array.filter((button) => button.side === "right"),
                        array.mapWithIndex((i, button) =>
                            <div key={i} className="crm-horizontal-tabs__button-tab">
                                {button.label}
                            </div>
                        )
                    ) }
                </div>

                {/* TAB CONTENT */}
                <div className="crm-horizontal-tabs__content">
                    {this.props.tabs.length > 0 && this.state.activeKey <= this.props.tabs.length-1 &&
                        this.props.tabs[this.state.activeKey].children
                    }
                </div>

                {/* BOTTOM BORDER INDICIATORS */}
                <div className="crm-horizontal-tabs__bottom-indicators">
                    {  pipe(
                        this.props.tabs,
                        array.mapWithIndex((index) =>
                            <div
                                className={`
                                    crm-horizontal-tabs__bottom-indicator
                                    crm-horizontal-tabs__bottom-indicator--${this.state.activeKey === index ? "active" : "inactive"}
                                `}
                                key={index}
                            ></div>
                        )
                    ) }
                </div>
            </div>
        );
    }
}
