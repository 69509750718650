import React from "react";
import { TFontWeightNumeric } from "../../../models/TCRMFontWeight";
import { TPixelSize } from "../../../models/StringLiterals";

export type THoverHighlightTextProps = {
    mode?: "one-line" | "multi-line";
    weight?: TFontWeightNumeric;
    height?: TPixelSize;
    onClick?: () => void;
};
export const CRMHoverHighlightText = (props: React.PropsWithChildren<THoverHighlightTextProps>): JSX.Element => {

    const Mode = props.mode || "one-line";

    return (
        <div
            className={`
                crm-hover-highlight-text
                crm-hover-highlight-text--${Mode}
            `}
            onClick={props.onClick}
            style={{
                height: props.height
            }}
        >
            <div
                className={`
                    crm-hover-highlight-text__pill
                    crm-hover-highlight-text__pill--${Mode}
                `}
                style={{
                    fontWeight: props.weight || 500,
                }}
            >
                {props.children}
            </div>
        </div>
    );
};
