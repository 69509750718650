import React from "react";
import { CRMTextHighlight } from "../CRMTextHighlight/CRMTextHighlight";
export class CRMFormErrorComponent extends React.Component<{
    errorMessage: string;
}> {
    public render (): JSX.Element {
        return (
            <div className="crm-form-error-component">
                <CRMTextHighlight colour="highlights-instructional-brick-10">
                    {this.props.errorMessage}
                </CRMTextHighlight>
            </div>
        );
    }
}
