import React from "react";
import { TSimpleLegalEmailWithPersonsForTriageForm, TTriageSimpleEmailForm } from "../../../../../domain/codecs/form/TriageForm";
import { TEmailPersonTitle, TSimpleLegalEmailPerson } from "../../../../../domain/codecs/LegalEmail";
import { requireExhaustive } from "../../../../../shared/src/util";
import { CRMFontSizes } from "../../../models/CRMFontSizes";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { Padding } from "../../BuildingBlocks/Padding";
import { SpacingColumn } from "../../BuildingBlocks/SpacingColumn";
import { SpacingRow } from "../../BuildingBlocks/SpacingRow";
import { Text } from "../../BuildingBlocks/Text";
import { CRMIcon } from "../../CRMIcon/CRMIcon";
import { CRMEmailOutsiderGlancingLabel } from "../../Simple/CRMEmailOutsiderGlancingLabel/CRMEmailOutsiderGlancingLabel";
import { CRMFuzzySortSearchHighlight } from "../../Simple/CRMFuzzySortSearchHighlight/CRMFuzzySortSearchHighlight";

type TCRMEmailCardToFromStripProps = {
    email: TSimpleLegalEmailWithPersonsForTriageForm;
    searchTerm: string;
};

export const CRMEmailCardToFrom = (props: React.PropsWithChildren<TCRMEmailCardToFromStripProps>) => {
    const isOutbound = () => props.email.original.direction === "outbound";
    const getOutsiders = () => isOutbound() ? props.email.original.to : [props.email.original.from];

    const sortByTitle = (a: TSimpleLegalEmailPerson, b: TSimpleLegalEmailPerson) =>
        getSortWeightNumberBasedOnTitle(b.title) - getSortWeightNumberBasedOnTitle(a.title)
    ;

    const getInsiderNames = () => {
        if (isOutbound() && hasName(props.email.original.from)) {
            return `${props.email.original.from.first_name} ${props.email.original.from.last_name}`;
        }
        if (isOutbound() && !hasName(props.email.original.from)) {
            return `${props.email.original.from.address}`;
        }
        return props.email.original.to
            .map(nameOrEmail)
            .join(", ");
    }

    const hasName = (person: TSimpleLegalEmailPerson) => !!person.first_name && !!person.last_name;

    const nameOrEmail = (person: TSimpleLegalEmailPerson): string  => 
        hasName(person) ? 
            `${person.first_name}. ${person.last_name[0]}`
            : person.address
    ;

    const getSortWeightNumberBasedOnTitle = (title: TEmailPersonTitle): number => {
        switch (title) {
            case "client":
                return 4;
            case "conveyancer":
                return 3;
            case "other":
            case "unknown":
                return 2;
            case "sail_staff":
                return 1;
            default:
                return requireExhaustive(title);
        }
    }

    return (
        <SpacingRow spacing={CRMSpacing.SMALL}>

            {/* COLOR */}
            <SpacingColumn spacing="3px">
                {getOutsiders()
                    .sort(sortByTitle)
                    .map((person, index) => (
                        <CRMEmailOutsiderGlancingLabel
                            key={index}
                            direction={props.email.original.direction}
                            person={person}
                            searchTerm={props.searchTerm}
                        />
                    ))
                }
            </SpacingColumn>

            <CRMIcon
                iconName={isOutbound() ? "line-arrow-left" : "lined-arrow"}
                colour="neutral-ink" 
                size="12px"
            />

            <Padding type="right" spacing={CRMSpacing.MEDIUM}>
                <Text
                    size={CRMFontSizes.SMALL}
                    lineHeight={1}
                    weight={400}
                    margin={"-2px 0px 0px 0px"}
                >
                    <CRMFuzzySortSearchHighlight
                        text={getInsiderNames()}
                        searchText={props.searchTerm}
                    />
                </Text>
            </Padding>
        </SpacingRow>
    );
}
