import React, { useState } from "react";
import { TRichFilterDropdownFaceProps } from "./CRMRichFilterDropdown";
import UnstyledInputGeneralComponent from "../../UnstyledInputGeneralComponent/UnstyledInputGeneralComponent";

export const CRMRichFilterDropdownPillFace = (props: TRichFilterDropdownFaceProps): JSX.Element => {
    
    const onOpen = () => {
        props.onOpen();
        props.inputRef.current?.focus();
    }

    return (
        <div className="crm-rich-filter-dropdown-pill-face">

            {props.selected.length === 0 &&
                <Pill onClick={onOpen}>
                    ALL
                </Pill>
            }

            {props.selected.map((value, key) => (
                <Pill
                    key={key}
                    onClick={onOpen}
                >
                    {props.convertOptionValueToFaceText(value)}
                </Pill>
            ))}
            
            <UnstyledInputGeneralComponent
                inputRef={props.inputRef}
                className={`
                    crm-rich-filter-dropdown-pill-face__input
                    crm-rich-filter-dropdown-pill-face__input--${props.isOpen ? 'open' : 'hidden'}
                    crm-rich-filter-dropdown-pill-face__input--${props.disabled ? "disabled" : "enabled"}
                `}
                inputType="text"
                value={props.searchText}
                placeholder={``}
                onChange={props.onSetSearchText}
                onFocus={() => null}
                onPressEnterKey={props.onPressEnterKey}
                onPressEscape={props.onPressEscape}
                onPressBackspace={props.onPressBackspace}
                onPressTabKey={props.onPressTabKey}
                disabled={props.disabled}
                displayError={false}
            />

        </div>
    );
}

type TPropsPill = {
    onClick: () => void;
}

const Pill = (props: React.PropsWithChildren<TPropsPill>) => {
    return (
        <div 
            className={`crm-rich-filter-dropdown-pill-face__pill`}
            onClick={props.onClick}
        >
            {props.children}
        </div>
    )
}