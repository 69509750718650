import React from "react";
import Page from "../../components/PageComponent/Page";
import { FrontHeader } from "../../components/Front/Simple/FrontHeader/FrontHeader";
import { FrontSpacer } from "../../components/FrontSpacer/FrontSpacer";
import { FrontYourOfferSummary } from "../../components/Front/Complex/FrontYourOfferSummary/FrontYourOfferSummary";
import { FrontOffersTable } from "../../components/Front/Complex/FrontOffersTable/FrontOffersTable";
import FrontTitleMinor from "../../components/Front/Simple/FrontTitleMinor/FrontTitleMinor";
import FrontParagraphSmall from "../../components/Front/Simple/FrontParagraphSmall/FrontParagraphSmall";
import { FrontTextColour } from "../../components/FrontTextColour/FrontTextColour";
import FrontAnchorButtonSecondary from "../../components/FrontAnchorButtonSecondary/FrontAnchorButtonSecondary";
import { sailHomesBrandConstants } from "../../../../domain/constants";
import { TContainerStateProps } from "../../state/TContainerStateProps";

class BuyerEnquiryPage extends React.Component<React.PropsWithChildren<TContainerStateProps>> {
    public render(): JSX.Element {
        return (
            <Page
                showLogout={true}
            >
                {this.props.state.forms.public_offers_page.data.status === "success" && <>
                    <FrontHeader>
                        <div>
                            Your offer for:
                        </div>
                        <div>
                            <FrontTextColour
                                colour="primary-16"
                            >
                                { this.props.state.forms.public_offers_page.data.output.listing_address }
                            </FrontTextColour>
                        </div>
                    </FrontHeader>
                    <FrontSpacer size="large-2" />
                    <div className="buyer-enquiry-page">
                        <div className="buyer-enquiry-page__cell buyer-enquiry-page__cell--your-offer-summary">
                            <div className="buyer-enquiry-page__summary-container">
                                <FrontYourOfferSummary
                                    thierLatestOffer={this.props.state.forms.public_offers_page.data.output.their_latest_offer}
                                    newOfferForm={this.props.state.forms.public_offers_page.new_offer}
                                    onChangeNewOfferForm={(f) => this.props.dispatch({
                                        type: "BUYER_ENQUIRY_NEW_OFFER_FORM_CHANGED",
                                        payload: f,
                                    })}
                                    onSubmitNewOfferForm={(f) => this.props.dispatch({
                                        type: "BUYER_ENQUIRY_NEW_OFFER_SUBMIT",
                                        payload: f,
                                    })}
                                />
                            </div>
                        </div>
                        <div className="buyer-enquiry-page__cell buyer-enquiry-page__cell--all-offers">
                            {/* Other offers */}
                            <FrontTitleMinor>
                                All the offers on this property
                            </FrontTitleMinor>
                            <FrontSpacer size="medium-1" />
                            <FrontOffersTable
                                offers={this.props.state.forms.public_offers_page.data.output.all_offers}
                            />

                            {/* Contact us */}
                            <FrontSpacer size="medium-2" />
                            <FrontParagraphSmall>
                                <FrontTextColour
                                    colour="neutral-ink-light"
                                >
                                    Need to discuss your offer with us? Click the button below to call us.
                                </FrontTextColour>
                            </FrontParagraphSmall>
                            <FrontSpacer size="medium-1" />
                            <FrontAnchorButtonSecondary
                                label="Contact us"
                                href={sailHomesBrandConstants("web").contact.phoneURL}
                            />
                        </div>
                    </div>
                    <FrontSpacer size="large-2" />
                </>}
            </Page>
        );
    }
}

export default BuyerEnquiryPage;
