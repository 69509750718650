import { array, identity } from "fp-ts";
import { pipe } from "fp-ts/lib/pipeable";
import React, { useEffect } from "react";
import { AddEmailUserForm, TAddEmailUserForm } from "../../../domain/codecs/EmailComposition";
import { TFormStatus } from "../../../shared/src/codecs/codec";
import { onChangeForm } from "../../../shared/src/codecs/types/form";
import { updateWhereOr } from "../../../shared/src/utilsByDomain/array";
import { TEmailInputOption } from "../components/Simple/CRMEmailInput/CRMEmailInput";
import { isValidEmail } from "../functions/email/isValidEmail";
import { useDropdown } from "./UseDropdown";
import { IFuzzySortOption, useFuzzysort } from "./useFuzzysort";

type TFuzzySortOptions = Array<Fuzzysort.KeyResult<IFuzzySortOption<string, TEmailInputOption>>>;

export type TResultGroup = { 
    name: string, 
    options: TFuzzySortOptions
};

type TEmailInputStatus = 
    "untouched"
    | "no-options"
    | "no-match"
    | "matching"
    
    | "new-email-string"
    | "new-email-form"
;

export type TUseEmailInputProps = {
    selected: Array<string>;    
    onChange: (emails: Array<string>) => void;
    options: Array<TEmailInputOption>;
    newEmailUser?: TNewEmailUserSupport; 
};

type TNewEmailUserSupport = {
    form: TAddEmailUserForm;
    onChange: (form: TAddEmailUserForm) => void;
    onSubmit: (form: TAddEmailUserForm) => void;    
};


export const useEmailInput = (props: TUseEmailInputProps) => {
    
    const inputRef = React.createRef<HTMLInputElement>();

    const {
        searchText,
        setSearchText,
        getSearchResults,
        setSearchOptions,
    } = useFuzzysort<string, TEmailInputOption>(
        props.options.map(convertEmailOptionToFuzzySortOption)
    );

    const {
        isOpen,
        toggleSelectedValue,
        setIsOpen,
        ref,
    } = useDropdown(
        props.selected,
        props.options.map((option) => ({
            label: option.address,
            value: option.address
        }))
    );

    useEffect(
        () => {
            setSearchOptions(props.options.map(convertEmailOptionToFuzzySortOption));
        },
        [props.options]
    );

    const isShowingOnlyOneOption = (): boolean => getSearchResults().length === 1;
    
    const open = () => setIsOpen(true);

    const close = () => setIsOpen(false);

    const onToggleEmailSelection = (email: string) => {
        props.onChange(toggleSelectedValue(email));
        inputRef.current?.focus();
    };

    const deriveStatus = (): TEmailInputStatus => {
        const hasQuery = searchText.length > 0;
        const noQuery = !hasQuery;
        
        const isEmail = isValidEmail(searchText);
        const notEmail = !isEmail;
        
        const hasResult = getSearchResults().length > 0;
        const noResult = !hasResult;

        const hasSubmission = !!props.newEmailUser;
        const noSubmission = !hasSubmission;

        if (noQuery && props.options.length === 0) {
            return "no-options";
        }

        if (hasQuery && hasResult) {
            return "matching";
        }
        
        if (hasQuery && noResult && notEmail && noSubmission) {
            return "no-match";
        }
        
        if (hasQuery && noResult && notEmail && hasSubmission) {
            return "no-match";
        }
        
        if (hasQuery && noResult && isEmail && noSubmission) {
            return "new-email-string";
        }
        
        if (hasQuery && noResult && isEmail && hasSubmission) {
            return "new-email-form";
        }

        return "untouched";
    }

    const onPressSearchEnter = () => {

        if (isValidEmail(searchText) && !props.newEmailUser) {
            onToggleEmailSelection(searchText);
            setSearchText("");
        }
        
        if (isValidEmail(searchText) && !!props.newEmailUser) {
            onSubmitUserForm();
        }

        if (!isValidEmail(searchText) && isShowingOnlyOneOption()) {
            const option = getSearchResults()[0];
            onToggleEmailSelection(option.obj.value);
            setSearchText("");
        }
    }

    const onEscape = () => {
        inputRef.current?.blur();
        setIsOpen(false);
    }

    const onBackspace = () => {
        if (searchText.length === 0 && props.selected.length > 0) {
            onToggleEmailSelection(props.selected[props.selected.length-1]);
        }
    }

    const getSearchResultsByGroup = (): Array<TResultGroup> => pipe(
        getSearchResults().map(identity.flatten),
        array.reduce(
            [] as Array<TResultGroup>,
            (sum, option) => pipe(
                sum,
                updateWhereOr(
                    (group) => group.name === option.obj.meta?.group,
                    (group) => ({
                        ...group,
                        options: group.options.concat(option)
                    }),
                    (arr) => arr.concat({
                        name: option.obj.meta?.group as string,
                        options: [option]
                    })
                ),
            )
        ),
    );
    
    
    // NEW EMAIL USER FORM
    //

    if (!!props.newEmailUser) {
        useEffect(
            () => {
                if (props.newEmailUser?.form.status === "success") {
                    onToggleEmailSelection(props.newEmailUser.form.edited.email);
                    props.newEmailUser.onChange(AddEmailUserForm.newDefault());
                    setSearchText("");
                }
            }, 
            [props.newEmailUser?.form.status]
        );
    }

    const onSubmitUserForm = () => {
        if (canSubmitEmailUserForm()) {
            props.newEmailUser?.onSubmit({
                ...props.newEmailUser.form,
                edited: {
                    ...props.newEmailUser.form.edited,
                    email: searchText
                }
            })
        }
    };

    const canSubmitEmailUserForm = (): boolean => (
        !!props.newEmailUser
        && props.newEmailUser.form.edited.first_name.length > 1 
        && props.newEmailUser.form.edited.last_name.length > 1
    );

    const onChangeUserForm = (key: keyof TAddEmailUserForm["edited"], status?: TFormStatus) => {
        if (!!props.newEmailUser) {
            return onChangeForm(props.newEmailUser.form, props.newEmailUser?.onChange)(key, status);
        }
        return () => null;
    };

    return {
        searchText,
        setSearchText,
        onToggleEmailSelection,
        getSearchResultsByGroup,
        getSearchResults,
        ref,
        inputRef,
        isOpen,
        open,
        close,
        toggleSelectedValue,
        onPressSearchEnter,
        onChangeUserForm,
        onSubmitUserForm,
        deriveStatus,
        onBackspace,
        onEscape,
    };
}

const convertEmailOptionToFuzzySortOption = (option: TEmailInputOption): IFuzzySortOption<string, TEmailInputOption> => ({
    label: `${option.address} ${option.name}`,
    value: option.address,
    meta: option
});
