import React from "react";
import { useNumberInput } from "../../hooks/useNumberInput";

interface ICRMInputCurrencyComponentProps {
    value: number | null;
    disabled?: boolean;
    displayError?: boolean;
    placeholder?: string;
    onChange: (value: number | null) => void;
}

export const CRMInputPercentage = (props: ICRMInputCurrencyComponentProps): JSX.Element => {

    const {
        setIsFocused,
        faceText,
        onChange
    } = useNumberInput(
        props.value, 
        props.onChange,
        "percentage"
    );

    return (
        <div className="crm-input-percentage">
            <div
                className={`
                    crm-input-percentage__symbol
                    crm-input-percentage__symbol--${props.disabled ? 'disabled' : 'enabled'}
                    crm-input-percentage__symbol--${props.displayError ? 'error' : 'no-error'}
                `}
            >
                %
            </div>
            <input
                className={`
                    crm-input-percentage__input
                    crm-input-percentage__input--${props.disabled ? 'disabled' : 'enabled'}
                    crm-input-percentage__input--${props.displayError ? 'error' : 'no-error'}
                `}
                type="text"
                value={faceText}
                placeholder={props.placeholder}
                disabled={props.disabled}
                onChange={onChange}
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
            />
        </div>
    );
}
