import * as Function from "../Function";
import * as Error from "../Error";
import * as ErrorCode from "../ErrorCode";
import { option } from "fp-ts/lib";
import { DateTime } from "luxon";

export const stringIsoDateTimeIsFuture: Function.TValidationFunction = (value: unknown): option.Option<Error.TValidationError> =>
    typeof value === "undefined" || (
        typeof value === "string"
        && DateTime.fromISO(value).isValid
        && DateTime.fromISO(value) > DateTime.utc()
    )
        ? option.none
        : option.some([[ErrorCode.validationErrorCodeConstants.FUTURE_DATETIME_VALIDATION]]);
