import React from "react";
import { TListingFull1Editable } from "../../../../domain/codecs/formEditable/ListingEditable";
import { ListingProgressionMortgageBrokerKickOff, TListingCRMReadOnlyData1 } from "../../../../domain/codecs/Listing";
import { CRMCodecEditForm } from "../CRMCodecEditForm/CRMCodecEditForm";
import { CRMSpacingColumn } from "../CRMSpacingColumn/CRMSpacingColumn";

type TCRMListingProgressionMortgageBrokerKickOffFormProps = {
    listingForm: TListingFull1Editable;
    listingReadOnly: TListingCRMReadOnlyData1;
    onChange: (listingForm: TListingFull1Editable) => void;
};

export const CRMListingProgressionMortgageBrokerKickOffForm = (props: TCRMListingProgressionMortgageBrokerKickOffFormProps): JSX.Element => {
    return (
        <CRMSpacingColumn spacing="large">
            <CRMCodecEditForm
                codec={ListingProgressionMortgageBrokerKickOff}
                model={props.listingForm.edited}
                validationErrors={props.listingForm.validationErrors}
                onChange={(edited) => props.onChange({
                    ...props.listingForm,
                    edited: {
                        ...props.listingForm.edited,
                        ...edited,
                    }
                })}
            />
        </CRMSpacingColumn>
    );
};
