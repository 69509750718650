import React, { CSSProperties } from "react";

type TStickyProps = {
    top?: string;
    bottom?: string;
    zIndex?: number;
    maxHeight?: string;
    overflowY?: CSSProperties["overflowY"],
    display?: CSSProperties["display"],
    justifyContent?: CSSProperties["justifyContent"],
};

export const Sticky = (props: React.PropsWithChildren<TStickyProps>): JSX.Element => {

    return (
        <div style={{
            position: "sticky",
            display: props.display,
            justifyContent: props.justifyContent,
            top: props.top || "0px",
            bottom: props.bottom || "auto",
            zIndex: props.zIndex,
            maxHeight: props.maxHeight,
            overflowY: props.overflowY,
        }}>
            {props.children}
        </div>
    );
};
