import React, { useEffect } from "react";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { Padding } from "../../BuildingBlocks/Padding";
import { CRMBlockPopover } from "../../CRM/CRMBlock/CRMBlockPopover/CRMBlockPopover";
import { CRMSingleViewHeader } from "../../Simple/CRMSingleViewHeader/CRMSingleViewHeader";
import { CRMButtonTertiaryColorable } from "../../CRMButtonTertiaryColorable/CRMButtonTertiaryColorable";
import { CRMColors } from "../../../models/CRMColors";
import { SpacingRow } from "../../BuildingBlocks/SpacingRow";
import { TFormStatus } from "../../../../../shared/src/codecs/codec";
import { CRMLoadingBar } from "../../Simple/CRMLoadingBar/CRMLoadingBar";

export const CRMSectionPopupPrompt = (props: React.PropsWithChildren<{
    formStatus: TFormStatus;
    title: string;
    onYes: () => void;
    onNo: () => void;
}>): JSX.Element => {

    const isSubmitting = () => props.formStatus === "submitting";

    useEffect(
        () => {
            if (props.formStatus === "success") {
                props.onNo();
            }
        },
        [props.formStatus]
    );

    return (
        <CRMBlockPopover>
            <div>
                {/* HEADER */}
                <CRMSingleViewHeader
                    title={props.title}
                    edge="border"
                />
                
                {props.formStatus === "submitting" && 
                    <CRMLoadingBar duration={3000} />
                }

                {/* CONTENT */}
                <Padding type="custom" spacing={`${CRMSpacing.MEDIUM} ${CRMSpacing.MEDIUM} ${CRMSpacing.LARGE}`}>
                    {props.children}
                </Padding>


                {/* CONTROLS */}
                <SpacingRow spacing={"3px"} childSize="1fr 1fr">
                    <CRMButtonTertiaryColorable
                        label="NO"
                        textAlignment="center"
                        textColor={CRMColors.NEUTRAL_PAPER}
                        backgroundColor={CRMColors.NEUTRAL_2}
                        backgroundColorHover={CRMColors.NEUTRAL_0}
                        onClick={props.onNo}
                        disabled={isSubmitting()}
                    />
                    <CRMButtonTertiaryColorable
                        label="YES"
                        textAlignment="center"
                        textColor={CRMColors.NEUTRAL_PAPER}
                        backgroundColor={CRMColors.PRIMARY_2}
                        backgroundColorHover={CRMColors.PRIMARY_4}
                        onClick={props.onYes}
                        disabled={isSubmitting()}
                    />
                </SpacingRow>
            </div>
        </CRMBlockPopover>
    );
};
