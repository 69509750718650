import React from "react";
import { CRMPageWrap } from "../../components/Simple/CRMPageWrap/CRMPageWrap";
import { CRMLegalMenuBarContainer } from "../../containers/CRMLegalMenuBarContainer/CRMLegalMenuBarContainer"
import { Padding } from "../../components/BuildingBlocks/Padding";
import { SpacingRow } from "../../components/BuildingBlocks/SpacingRow";
import { CRMSpacing } from "../../models/CRMSpacing";
import { CRMBlocksRouterContainer, TCRMBlocksRouterContainerProps } from "../../containers/CRMBlocksRouterContainer/CRMBlocksRouterContainer";

const CRMBlocksTestPage = (props: TCRMBlocksRouterContainerProps): JSX.Element =>
    <CRMPageWrap>
        {/* HEADER */}
        <CRMLegalMenuBarContainer
            state={props.state}
            dispatch={props.dispatch}
        />

        {/* BODY */}
        <Padding
            type="custom" 
            spacing={`${CRMSpacing.X_LARGE} ${CRMSpacing.X_LARGE} 0px`}
        >
            <SpacingRow
                spacing={CRMSpacing.X_LARGE}
                alignItems="flex-start"
            >
                <CRMBlocksRouterContainer 
                    state={props.state}
                    dispatch={props.dispatch}
                    isFirstLoad={props.isFirstLoad}
                    onChangeBlock={() => undefined}
                />
            </SpacingRow>
        </Padding>
    </CRMPageWrap>;

export default CRMBlocksTestPage;
