import React from "react";
import { array } from "fp-ts";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { SpacingColumn } from "../../BuildingBlocks/SpacingColumn";
import { CRMColors } from "../../../models/CRMColors";
import { ShineShape } from "../../BuildingBlocks/ShineShape";
import { SpacingRow } from "../../BuildingBlocks/SpacingRow";
import { Shape } from "../../BuildingBlocks/Shape";
import { Padding } from "../../BuildingBlocks/Padding";

type TTextContentPlaceholderProps = {
    rowCount: number;
};

export const CRMTableContentPlaceholder = (props: React.PropsWithChildren<TTextContentPlaceholderProps>): JSX.Element =>
    <SpacingColumn spacing="1px">
        {array.range(1, props.rowCount).map((index) => (
            <ShineShape
                backgroundColor={CRMColors.NEUTRAL_PAPER} 
                shineColor={CRMColors.NEUTRAL_12}
                duration={2000}
                height={"44px"}
                width={"100%"}
            >
                <Padding type="horizontal" spacing={CRMSpacing.MEDIUM}>
                    <SpacingRow
                        height="44px" 
                        alignItems="center"
                        spacing={CRMSpacing.MEDIUM}
                    >
                        <Shape
                            borderRadius="5px"
                            height="10px"
                            width="10px"
                            color={CRMColors.NEUTRAL_8}
                        />

                        <Shape
                            borderRadius="5px"
                            height="10px"
                            width="200px"
                            color={CRMColors.NEUTRAL_8}
                        />
                    </SpacingRow>
                </Padding>
            </ShineShape>
        ))}
    </SpacingColumn>
