import React from "react";

export default (props: React.SVGAttributes<SVGElement>) => 
<svg width="1440px" height="64px" viewBox="0 0 1440 64" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" {...props}>
    <title>Rectangle</title>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Homepage" transform="translate(0.000000, -1035.000000)" fill="#2D3D64">
            <path d="M0,1059.98033 C225.008447,1026.93209 462.938563,1026.93209 713.790349,1059.98033 C964.642134,1093.02858 1206.71202,1093.02858 1440,1059.98033 L1440,1099 L0,1099 L0,1059.98033 Z" id="Rectangle" transform="translate(720.000000, 1067.097077) scale(1, -1) translate(-720.000000, -1067.097077) "></path>
        </g>
    </g>
</svg>;