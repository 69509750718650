import React, { useState } from "react";
import { TCaseInstructForm } from "../../../../domain/codecs/form/CaseForm";
import { doesErrorCodeExist, doesErrorKeyExist, doesErrorKeySliceExist } from "../../../../shared/src/codecs/errors";
import { CRMButtonPrimary } from "../CRMButtonPrimary/CRMButtonPrimary";
import { CRMCardOutsidePopupFormSubmit } from "../CRMCardOutsidePopupFormSubmit/CRMCardOutsidePopupFormSubmit";
import { CRMFormErrorComponent } from "../CRMFormErrorComponent/CRMFormErrorComponent";
import { CRMParagraph } from "../Simple/CRMParagraph/CRMParagraph";
import { CRMSpacer } from "../CRMSpacer/CRMSpacer";
import { CRMSpacingColumn } from "../CRMSpacingColumn/CRMSpacingColumn";
import { CRMUniversalBulletPoints } from "../CRMUniversalBulletPoints/CRMUniversalBulletPoints";
import { CRMNoticeBoxComponent } from "../CRMNoticeBoxComponent/CRMNoticeBoxComponent";
import { WeightSemiBold } from "../WeightSemiBold/WeightSemiBold";

export const CRMInstructCase = (props: React.PropsWithChildren<{
    hasLedgerEntries: boolean;
    form: TCaseInstructForm;
    onInstruct: () => void;
}>): JSX.Element => {
    const [isPopupOpen, setPopupOpen] = useState<boolean>(false);

    return (
        <>
            {/* Button */}
            <div>
                <CRMButtonPrimary
                    fullWidth={false}
                    icon="done-all"
                    label="Instruct"
                    onClick={() => setPopupOpen(true)}
                />
            </div>

            {/* Popup */}
            <CRMCardOutsidePopupFormSubmit
                isOpen={isPopupOpen}
                title="Are you sure?"
                context="warning"
                closeText="Cancel"
                ctaText="Yes, instruct"
                onClose={() => setPopupOpen(false)}
                onCTA={() => props.onInstruct()}
                formStatus={
                    props.form.status === "unauthorised"
                    || props.form.status === "twoFactorRequired"
                    || props.form.status === "notFound" ? "failure"
                        : props.form.status === "untouched" ? "requiresSubmission"
                        : props.form.status}
                validationErrors={[]}
            >
                <CRMSpacingColumn spacing="medium">
                    {/* WONT COPY QUOTE TO LEDGER NOTICE */}
                    {props.hasLedgerEntries &&
                        <CRMNoticeBoxComponent>
                            The ledger has already been populated so <WeightSemiBold>the quote wont be copied to the ledger this time.</WeightSemiBold>
                        </CRMNoticeBoxComponent>
                    }

                    <CRMParagraph>
                        You are about to instruct this case. Are you sure you want to continue?
                    </CRMParagraph>

                    {props.form.validationErrors.length > 0 && <div>
                        <CRMFormErrorComponent errorMessage="The following must be completed first before you can instruct the case." />
                        <CRMSpacer size="tiny" />
                        <CRMUniversalBulletPoints
                            size="normal"
                            points={[
                                ...(doesErrorKeyExist("transaction_type", props.form.validationErrors) ? [<CRMParagraph>The case's transaction type has must be set.</CRMParagraph>] : []),
                                ...(doesErrorKeyExist("introducer_set", props.form.validationErrors) ? [<CRMParagraph>If there is an introducer it must be set.</CRMParagraph>] : []),
                                ...(doesErrorCodeExist("NoUserAvailableForCaseAssignment", props.form.validationErrors) ? [<CRMParagraph>There isn't a suitable case handler for this case to be automatically assigned to.</CRMParagraph>] : []),
                                ...(doesErrorKeySliceExist("quotes", props.form.validationErrors) ? [<CRMParagraph>A quote must be created with the same transaction type as the case.</CRMParagraph>] : []),
                            ]}
                        />
                    </div>}
                </CRMSpacingColumn>
            </CRMCardOutsidePopupFormSubmit>
        </>
    );
};
