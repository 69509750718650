import { DateTime } from "luxon";
import React from "react";
import { TCRMMainColours } from "../../models/TCRMMainColours";
import { CRMIcon, TIcon } from "../CRMIcon/CRMIcon";
import { CRMPadding } from "../Simple/CRMPadding/CRMPadding";
import { CRMParagraph } from "../Simple/CRMParagraph/CRMParagraph";
import { CRMReactCalendarWrapper } from "../CRMReactCalendarWrapper/CRMReactCalendarWrapper";
import { CursorPointer } from "../CursorPointer/CursorPointer";
import { CRMPopout } from "../Simple/CRMPopout/CRMPopout";
import { SpacingRow } from "../BuildingBlocks/SpacingRow";
import { CRMSpacing } from "../../models/CRMSpacing";
import { isObject } from "../../../../shared/src/util";

export type TDeferPopoutProps =
    {
        options?:
            "all"
            | "only_date",
        value?: string | null;
        onChange: (value: string | null) => void;
    }
    | {
        options: "only_overdue";
        value:
        {
            type: "overdue",
        }
        | {
            type: "iso",
            date: string | null
        };
        onChange: (value: 
            {
                type: "overdue",
            }
            | {
                type: "iso",
                date: string | null
            }
        ) => void;
    };

export const CRMDeferPopout = (props: TDeferPopoutProps): JSX.Element => {

    const Now = DateTime.local();

    const MorningTime = { hour: 7, minute: 0, second: 0, millisecond: 0 };

    const LaterToday = Now.hour < 14 ? 14 : Now.hour + 2;

    const selectedValue =
        typeof props.value === "string" && props.value ? new Date(props.value)
        : isObject(props.value) && "type" in props.value && props.value.type === "overdue" ? undefined
        : isObject(props.value) && "type" in props.value && props.value.type === "iso" && props.value.date ? new Date(props.value.date)
        : new Date();

    const options = props.options || "all";

    const isShowingTimeOptions = options === "all";

    const isShowingOverdue = options === "only_overdue";


    const onChange = (date: DateTime) => () =>
        props.options === "only_overdue" ? props.onChange({type: "iso", date: date.toUTC().toISO()})
        : props.onChange(date.toUTC().toISO());

    const onOverdue = () =>
        props.options === "only_overdue" ? props.onChange({type: "overdue"})
        : undefined;

    const onDeferBySpecificDate = (value: string) => onChange(DateTime.fromISO(value))();

    const onDeferBy30Minutes =  onChange(Now.plus({ minutes: 30 }));

    const onDeferBy1Hour =      onChange(Now.plus({ hours: 1 }));

    const onDeferToLaterToday = onChange(Now.set({ ...MorningTime, hour: LaterToday}));

    const onDeferToTomorrow =   onChange(Now.plus({days: 1}).set({ ...MorningTime }));

    const onDeferBy3Days =      onChange(Now.plus({days: 3}).set({ ...MorningTime }));

    const onDeferBy1Week =      onChange(Now.plus({days: 7}).set({ ...MorningTime }));

    return (
        <CRMPopout>
            {/* OVERDUE ONLY */}
            {isShowingOverdue &&
                <QuickButton
                    text="Overdue"
                    icon="clock"
                    colour="highlights-instructional-brick-2"
                    onChange={onOverdue}
                />
            }

            {!isShowingOverdue &&
                <>
                    {/* IF SHOWING TIME: BUTTONS - QUICK TIME DEFER */}
                    {isShowingTimeOptions && <>
                        <QuickButton
                            text="30 mins"
                            icon="clock"
                            colour="highlights-positive-green-0"
                            onChange={onDeferBy30Minutes}
                        />

                        <QuickButton
                            text="1 hour"
                            icon="clock-full"
                            colour="primary-4"
                            onChange={onDeferBy1Hour}
                        />
                        <QuickButton
                            text="Later Today"
                            icon="alarm"
                            colour="highlights-instructional-brick-2"
                            onChange={onDeferToLaterToday}
                        />
                    </>}

                    {/* BUTTONS - QUICK DATE DEFER */}
                    <QuickButton
                        text="Tomorrow"
                        icon="sun"
                        colour="highlights-calming-pink-0"
                        onChange={onDeferToTomorrow}
                    />
                    <QuickButton
                        text="3 Days"
                        icon= "half-moon"
                        colour="highlights-calming-pink-2"
                        onChange={onDeferBy3Days}
                    />
                    <QuickButton
                        text="1 Week"
                        icon="cresent-moon"
                        colour="highlights-calming-pink-4"
                        onChange={onDeferBy1Week}
                    />
                </>
            }

            {/* CALENDAR */}
            <CRMPadding size="medium">
                <CRMReactCalendarWrapper
                    value={selectedValue} 
                    onChange={onDeferBySpecificDate}
                />
            </CRMPadding>
        </CRMPopout>
    );
};


type TQuickButtonProps = {
    text: string;
    icon: TIcon;
    colour: TCRMMainColours;
    onChange: () => void;
}

const QuickButton = (props: TQuickButtonProps): JSX.Element => (
    <CursorPointer>
        <div className="crm-defer-button-component__button-row" onClick={props.onChange}>
            <CRMPadding size="medium">
                <SpacingRow spacing={CRMSpacing.SMALL}>
                    <CRMIcon iconName={props.icon} colour={props.colour} />
                    <CRMParagraph>
                        {props.text}
                    </CRMParagraph>
                </SpacingRow>
            </CRMPadding>
        </div>
    </CursorPointer>
)