import { from, Observable, of } from "rxjs";
import { tap, filter, mergeMap, switchMap, debounceTime, delay } from "rxjs/operators";
import { TActionObservable, TCodecSetState, TGetCodecState } from "../applyActions";
import { action, routeAction } from "./actionFunctions";
import { formOperation } from "../../wrappers/formOperation";
import { TCaseNewLedgerEntryForm } from "../../../../domain/codecs/form/CaseNewLedgerEntryForm";
import { TCaseLedgerEntryForm } from "../../../../domain/codecs/form/CaseLedgerEntryForm";
import { doFormValuesMatch } from "../../../../shared/src/codecs/types/form";
import { TGetState } from "../TGetState";
import { TSetState } from "../TSetState";
import { TActionsDefinitionsList } from "./TAction";
import { updateUrl } from "../router/updateUrl";
import { TStateLens } from "../../../../domain/codecs/state";

export const actions: TActionsDefinitionsList = [
    routeAction("VIEW_CRM_LEGAL_CASE", (obs$, lens, setState, getState) => {
        obs$.pipe(
            tap((a) => setState(lens.case_ledger_page.case_ledger.edited.case_id.set(a.params.caseId))),
            tap((a) => setState(lens.case_ledger_page.create_case_ledger_item.edited.case_id.set(a.params.caseId))),
            tap((a) => setState(lens.case_ledger_page.mark_case_ledger_as_final.edited.id.set(a.params.caseId))),
            tap((a) => setState(lens.case_ledger_page.rollback_ledger.edited.id.set(a.params.caseId))),
        ).subscribe();
    }),
    action("LEGAL_CASE_GET_LEDGER", (obs$, lens, setState, getState) => {
        obs$.pipe(
            $loadLedger(lens, setState, getState)
        ).subscribe()
    }),
    action("LEGAL_CASE_UPDATE_NEW_LEDGER_ITEM", (obs$: Observable<TCaseNewLedgerEntryForm>, lens, setState) => {
        obs$.pipe(
            tap((form) => setState(lens.case_ledger_page.create_case_ledger_item.set(form))),
        ).subscribe();
    }),
    action("LEGAL_CASE_SUBMIT_NEW_LEDGER_ITEM", (obs$: Observable<TCaseNewLedgerEntryForm>, lens, setState, getState) => {
        obs$.pipe(
            tap(() => setState(lens.case_ledger_page.create_case_ledger_item.status.set("submitting"))),
            switchMap(() => formOperation("CreateCaseLedgerEntry", lens.case_ledger_page.create_case_ledger_item.get()(getState()))),
            tap((response) => setState(lens.case_ledger_page.create_case_ledger_item.set(response))),
            switchMap((response) =>
                response.status === "success"
                    ? from(formOperation("GetCaseLedger", lens.case_ledger_page.case_ledger.get()(getState())))
                        .pipe(
                            tap((r) => setState(lens.case_ledger_page.case_ledger.set(r))),
                            debounceTime(800),
                            tap(() => setState(lens.case_ledger_page.create_case_ledger_item.status.set("requiresSubmission"))),
                            tap(() => setState(lens.case_ledger_page.create_case_ledger_item.edited.vat_excluded_amount_gbp_pence.set(null))),
                        )
                    : of(undefined)
            ),
        ).subscribe();
    }),
    action("LEGAL_CASE_UPDATE_LEDGER_ITEM_CHANGE", (obs$: Observable<TCaseLedgerEntryForm>, lens, setState, getState) => {
        obs$.pipe(
            tap((form) => setState(lens.case_ledger_page.case_ledger.children.ledger_entries.where(doFormValuesMatch(form, "id")).status.set("submitting"))),
            tap((form) => setState(lens.case_ledger_page.case_ledger.children.ledger_entries.where(doFormValuesMatch(form, "id")).set(form))),
            switchMap((form) => formOperation(
                "UpdateCaseLedgerEntry", 
                lens.case_ledger_page.case_ledger.children.ledger_entries
                    .where(doFormValuesMatch(form, "id"))
                    .get()(getState())
            )),
            $loadLedger(lens, setState, getState),
        ).subscribe();
    }),
    action("LEGAL_CASE_DELETE_LEDGER_ITEM", (obs$: Observable<TCaseLedgerEntryForm>, lens, setState, getState) => {
        obs$.pipe(
            tap((form) => setState(lens.case_ledger_page.case_ledger.children.ledger_entries.where(doFormValuesMatch(form, "id")).status.set("submitting"))),
            switchMap((form) => formOperation("DeleteCaseLedgerEntry", lens.case_ledger_page.case_ledger.children.ledger_entries.where(doFormValuesMatch(form, "id")).get()(getState()))),
            $loadLedger(lens, setState, getState),
        ).subscribe();
    }),
    action("LEGAL_CASE_MARK_LEDGER_AS_FINAL", (obs$: Observable<null>, lens, setState, getState) => {
        obs$.pipe(
            tap(() => setState(lens.case_ledger_page.mark_case_ledger_as_final.status.set("submitting"))),
            switchMap(() => formOperation("CaseLedgerMarkLedgerAsFinal", lens.case_ledger_page.mark_case_ledger_as_final.get()(getState()))),
            tap((response) => setState(lens.case_ledger_page.mark_case_ledger_as_final.set(response))),
            $loadLedger(lens, setState, getState),
        ).subscribe();
    }),
    action("LEGAL_CASE_ROLLBACK_LEDGER", (obs$: Observable<null>, lens, setState, getState) => {
        obs$.pipe(
            tap(() => setState(lens.case_ledger_page.rollback_ledger.status.set("submitting"))),
            switchMap(() => formOperation("CaseLedgerRollback", lens.case_ledger_page.rollback_ledger.get()(getState()))),
            tap((response) => setState(lens.case_ledger_page.rollback_ledger.set(response))),
            $loadLedger(lens, setState, getState),
        ).subscribe();
    }),
];

const $loadLedger = <T>(lens: TStateLens, setState: TCodecSetState, getState: TGetCodecState) => 
    (obs$: Observable<T>) =>
        obs$.pipe(
            tap(() => setState(lens.case_ledger_page.case_ledger.status.set("loading"))),
            switchMap(() => formOperation("GetCaseLedger", lens.case_ledger_page.case_ledger.get()(getState()))),
            tap((response) => setState(lens.case_ledger_page.case_ledger.set(response))),
            delay(500),
            tap(() => setState(lens.case_ledger_page.case_ledger.status.set("untouched"))),
        )
;