import { DateTime } from "luxon";

export const isDateAfterAnother = (newDateString: string | null, previousDateString: string | null): boolean => {
    if (newDateString === null) {
        return false;
    }
    const newDate = DateTime.fromISO(newDateString);
    if (!newDate.isValid) {
        return false;
    }
    if (previousDateString === null) {
        return true;
    }
    const previousDate = DateTime.fromISO(previousDateString);
    if (!previousDate.isValid) {
        return false;
    }

    return newDate > previousDate;
}

/*
* format: today | yesterday | d MMM
*/
// [TO-DO] add tomorrow text
export const toDateFormat1 = (date: string) => {
    try {
        const dateTime = DateTime.fromISO(date, { zone: "Europe/London" });
        const diff = DateTime.local().diff(dateTime, "days");
        return diff.days < 0 ? dateTime.toFormat("d MMM")
            : diff.days < 1 ? "Today"
            : diff.days < 2 ? "Yesterday"
            : dateTime.toFormat("d MMM");
    } catch (e) {
        console.error("Could not parse datetime:", date);
        return "";
    }
}