import React from "react";
import CRMInputLabelAndErrorWrapComponent from "../CRMInputLabelAndErrorWrapComponent/CRMInputLabelAndErrorWrapComponent";
import {TCRMFormInputPropsWithoutCodec } from "./CRMFormInputTypes";
import { TUnionOfLiteralsCodec } from "../../../../shared/src/codecs/types/union";
import { snakeCaseToCopyText } from "../../../../shared/src/util";
import { CRMRadioListComponent, ICRMRadioOption } from "../CRMInputs/CRMRadioListComponent/CRMRadioListComponent";

/**
 * This type is being used to allow the comp to either take a codec or options. This is so that by default it
 * can automatically take the codec in the auto edit form. On the other hand if this component is being used on
 * it's own or as an override you should use options to manually set it.
 */
type TCRMRadioButtonsFormInput = <A extends string, B extends A>(props: (TCRMFormInputPropsWithoutCodec<A> & {
    options: Array<ICRMRadioOption<A>>;
    model: B
}) | (TCRMFormInputPropsWithoutCodec<string> & {
    codec: TUnionOfLiteralsCodec
})) => JSX.Element;

/**
 * This comp supports `codec` or `options` (not both) you should probably be using `options`
 */
export const CRMRadioButtonsFormInput: TCRMRadioButtonsFormInput = (props) =>
<CRMInputLabelAndErrorWrapComponent label={props.label}>
    <CRMRadioListComponent<string, string>
            value={props.model}
            direction="row"
            options={
                "options" in props ? props.options :
                props.codec.payload.map((innerCodec) => ({
                value: innerCodec.payload,
                text: snakeCaseToCopyText(innerCodec.payload)
            }))}
            onChange={props.onChange as (v: string) => void}
            disabled={props.disabled}
            displayError={props.displayError}
    />
</CRMInputLabelAndErrorWrapComponent>;
