import React, { useState } from "react";
import { TIntroducerExistingAgreementForm, TIntroducerForm, TIntroducerUploadAgreementForm } from "../../../../../domain/codecs/form/IntroducerForm";
import { penceToFormattedPound } from "../../../../../shared/src/utilsByDomain/money/penceToFormattedPound";
import { numberToPercentString } from "../../../../../shared/src/utilsByDomain/percent/numberToPercentString";
import { CRMColors } from "../../../models/CRMColors";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { BackgroundColour } from "../../BuildingBlocks/BackgroundColour";
import { MinHeight } from "../../BuildingBlocks/MinHeight";
import { Padding } from "../../BuildingBlocks/Padding";
import { SpacingColumn } from "../../BuildingBlocks/SpacingColumn";
import { SpacingRow } from "../../BuildingBlocks/SpacingRow";
import { Sticky } from "../../BuildingBlocks/Sticky";
import { CRMLink } from "../../CRMLink/CRMLink";
import { CRMParagraph } from "../../Simple/CRMParagraph/CRMParagraph";
import { CRMSpacingColumn } from "../../CRMSpacingColumn/CRMSpacingColumn";
import { CRMTitleSubSection } from "../../CRMTitleSubSection/CRMTitleSubSection";
import { CRMSingleViewHeader } from "../../Simple/CRMSingleViewHeader/CRMSingleViewHeader";
import { CRMBottomControlBar } from "../../CRMBottomControlBar/CRMBottomControlBar";
import { CRMButtonIcon } from "../../CRMButtonIcon/CRMButtonIcon";
import { useResolutionStatus } from "../../../hooks/useResolutionStatus";
import { CRMConfirmationCountdown } from "../CRMConfirmationCountdown/CRMConfirmationCountdown";
import { CRMLabelAndMonoValue } from "../../Simple/CRMLabelAndMonoValue/CRMLabelAndMonoValue";
import { CRMIntroducerAgreementBubble } from "../../Simple/CRMIntroducerAgreementBubble/CRMIntroducerAgreementBubble";
import { CRMIntroducerEditPopout } from "../CRMIntroducerEditPopout/CRMIntroducerEditPopout";
import { isDateInThePast } from "../../../../../shared/src/utilsByDomain/dateTime";
import { CRMSingleLineLabelAndValue } from "../../Simple/CRMSingleLineLabelAndValue/CRMSingleLineLabelAndValue";
import { CRMBlockPopover } from "../../CRM/CRMBlock/CRMBlockPopover/CRMBlockPopover";
import { defaultSearchProviderFullName, defaultSearchProviderPortalUrl } from "../../../../../domain/defaultSearchProvider";
import { CRMClickCopyWrap } from "../../Simple/CRMClickCopyWrap/CRMClickCopyWrap";

type TIntroducerSingleViewProps = {
    form: TIntroducerForm;
    onDeleteUploadAgreement: (form: TIntroducerExistingAgreementForm) => void;
    onUploadAgreement: (form: TIntroducerUploadAgreementForm) => void;
    onChange: (form: TIntroducerForm) => void;
    onClose: () => void;
    onArchive: (form: TIntroducerForm) => void;
    onSave: (form: TIntroducerForm) => void;
};

type TIntroducerViewStatus = 
    "view"
    | "edit"
;

export const CRMIntroducerSingleView = (props: React.PropsWithChildren<TIntroducerSingleViewProps>): JSX.Element => {

    const [viewStatus, setViewStatus] = useState<TIntroducerViewStatus>("view");
    const {
        resolutionStatus,
        setResolution,
        resetResolution,
    } = useResolutionStatus<"none" | "archiving", {}>({
        defaultStatus: "none",
    });

    const onArchive = () => setResolution("archiving")();

    const onSubmitArchive = () => {
        props.onArchive(props.form);
        resetResolution();
    }

    const onDownloadReferralAgreement = () =>
        window.open(props.form.children.existing_agreement_form?.children.download_url, "_blank")
    ;

    const onEdit = () => setViewStatus("edit");

    return (
        <>
            {viewStatus === "edit" &&
                <CRMBlockPopover
                    padding={CRMSpacing.MEDIUM}
                    height="745px" 
                    maxHeight="745px"
                >
                    <CRMIntroducerEditPopout
                        form={props.form}
                        onDeleteUploadAgreement={props.onDeleteUploadAgreement}
                        onUploadAgreement={props.onUploadAgreement}
                        onClose={() => setViewStatus("view")}
                        onChange={props.onChange}
                        onSave={props.onSave}
                    />
                </CRMBlockPopover>
            }

            {/* MAIN */}
            <div>
                {/* BODY */}
                <div>
                    <Sticky
                        zIndex={1}
                    >
                        <CRMSingleViewHeader 
                            title={props.form.original.name}
                            onClick={props.onClose} 
                        />
                    </Sticky>
                    
                    <BackgroundColour colour={CRMColors.NEUTRAL_PAPER}>
                        <MinHeight height={"700px"}>
                            <Padding spacing={CRMSpacing.LARGE}>
                                
                                <SpacingColumn spacing={CRMSpacing.LARGE}>

                                    {/* SECTION - AGREEMENT & SEARCH */}
                                    <SpacingColumn spacing={CRMSpacing.SMALL}>
                                        <div>
                                            <CRMIntroducerAgreementBubble form={props.form} />
                                        </div>

                                        {/* ID */}
                                        <CRMClickCopyWrap value={props.form.original.id}>
                                            <CRMSingleLineLabelAndValue
                                                label="ID:"
                                                value={props.form.original.id}
                                            />
                                        </CRMClickCopyWrap>

                                        {/* ABORTIVE COSTS */}
                                        <CRMSingleLineLabelAndValue
                                            label="Abortive costs:"
                                            value={props.form.original.will_charge_abortive_costs ? "Will be charged" : "Not charged"}
                                        />

                                        {/* KLYANT CLIENT ID */}
                                        {props.form.original.klyant_client_id &&
                                            <CRMSingleLineLabelAndValue
                                                label="Klyant client ID:"
                                                value={props.form.original.klyant_client_id}
                                            />
                                        }

                                        {props.form.original.search_provider === "default" &&
                                            <SpacingRow spacing={CRMSpacing.TINY}>
                                                <CRMParagraph>
                                                    Search provider:
                                                </CRMParagraph>
                                                <CRMLink linkStyle="normal" href={defaultSearchProviderPortalUrl}>
                                                    {defaultSearchProviderFullName}
                                                </CRMLink>
                                            </SpacingRow>
                                        }

                                        {props.form.original.search_provider === "somewhere_else" &&
                                            <SpacingRow spacing={CRMSpacing.TINY}>
                                                <CRMParagraph>
                                                    Search provider:
                                                </CRMParagraph>
                                                <CRMLink linkStyle="normal" href={props.form.original.custom_search_provider_url}>
                                                    {props.form.original.custom_search_provider_name || "--"}
                                                </CRMLink>
                                                <CRMParagraph>
                                                    Ordering Notes: {props.form.original.custom_search_ordering_notes}
                                                </CRMParagraph>
                                            </SpacingRow>
                                        }
                                    </SpacingColumn>
                                    
                                    {/* SECTION - REFERRAL FEES */}
                                    <SpacingColumn spacing={CRMSpacing.MEDIUM}>
                                        <CRMTitleSubSection>
                                            Referral fees
                                        </CRMTitleSubSection>
                                                
                                        <CRMSpacingColumn>
                                            <CRMSingleLineLabelAndValue
                                                label="Paid referral fee:"
                                                value={props.form.original.is_paid_referral_fee ? "Yes" : "No"}
                                            />

                                            {/* PURCHASE REFERRAL FEE */}
                                            {props.form.original.is_paid_referral_fee && props.form.original["default_referral_fee_pence--purchase"] > 0 &&
                                                <CRMLabelAndMonoValue
                                                    label="Default fee paid (Purchase):"
                                                    value={penceToFormattedPound(props.form.original["default_referral_fee_pence--purchase"])}
                                                />
                                            }

                                            {/* SALE REFERRAL FEE */}
                                            {props.form.original.is_paid_referral_fee && props.form.original["default_referral_fee_pence--sale"] > 0 &&
                                                <CRMLabelAndMonoValue
                                                    label="Default fee paid (Sale):"
                                                    value={penceToFormattedPound(props.form.original["default_referral_fee_pence--sale"])}
                                                />
                                            }

                                            {/* TRANSFER REFERRAL FEE */}
                                            {props.form.original.is_paid_referral_fee && props.form.original["default_referral_fee_pence--transfer"] > 0 &&
                                                <CRMLabelAndMonoValue
                                                    label="Default fee paid (Transfer):"
                                                    value={penceToFormattedPound(props.form.original["default_referral_fee_pence--transfer"])}
                                                />
                                            }

                                            {/* REMORTGAGE REFERRAL FEE */}
                                            {props.form.original.is_paid_referral_fee && props.form.original["default_referral_fee_pence--remortgage"] > 0 &&
                                                <CRMLabelAndMonoValue
                                                    label="Default fee paid (Remortgage):"
                                                    value={penceToFormattedPound(props.form.original["default_referral_fee_pence--remortgage"])}
                                                />
                                            }

                                            {/* REMORTGAGE REFERRAL FEE */}
                                            {props.form.original.sail_homes_sale_referral_percentage !== 0 &&
                                                <CRMLabelAndMonoValue
                                                    label="Sail Homes Referral Fee Percentage:"
                                                    value={numberToPercentString(props.form.original.sail_homes_sale_referral_percentage)}
                                                />
                                            }
                                        </CRMSpacingColumn>
                                    </SpacingColumn>

                                    {/* SECTION - MONEY ON ACCOUNT */}
                                    <SpacingColumn spacing={CRMSpacing.MEDIUM}>
                                        <CRMTitleSubSection>
                                            Money on Account Amounts
                                        </CRMTitleSubSection>
                                                
                                        <CRMSpacingColumn>
                                            <CRMLabelAndMonoValue
                                                label="Purchase initial payment:"
                                                value={penceToFormattedPound(props.form.original.initial_payment_purchase_amount_pence)}
                                            />

                                            <CRMLabelAndMonoValue
                                                label="Transfer initial payment:"
                                                value={penceToFormattedPound(props.form.original.initial_payment_transfer_amount_pence)}
                                            />

                                            <CRMLabelAndMonoValue
                                                label="Remortgage initial payment:"
                                                value={penceToFormattedPound(props.form.original.initial_payment_remortgage_amount_pence)}
                                            />
                                            
                                            <CRMLabelAndMonoValue
                                                label="Sale initial payment (with leasehold):"
                                                value={penceToFormattedPound(props.form.original.initial_payment_sale_with_leasehold_amount_pence)}
                                            />

                                            <CRMLabelAndMonoValue
                                                label="Sale initial payment (without leasehold):"
                                                value={penceToFormattedPound(props.form.original.initial_payment_sale_without_leasehold_amount_pence)}
                                            />

                                            <CRMLabelAndMonoValue
                                                label="Additional amount when sale ordering searches:"
                                                value={penceToFormattedPound(props.form.original.initial_payment_additional_seller_searches_amount_pence)}
                                            />
                                        </CRMSpacingColumn>
                                    </SpacingColumn>
                                    
                                </SpacingColumn>

                            </Padding>
                        </MinHeight>
                    </BackgroundColour>
                </div>
                
                {/* CONTROL BAR */}
                <CRMBottomControlBar>
                    
                    {resolutionStatus === "archiving" &&
                        <CRMConfirmationCountdown
                            title="Archiving!"
                            iconName="archive"
                            primaryButtonMode="done"
                            onUndo={resetResolution}
                            onComplete={onSubmitArchive}
                        />
                    }

                    {/* CONTROLS */}
                    {resolutionStatus === "none" &&
                        <Padding 
                            type="custom"
                            width="100%" 
                            spacing={`${CRMSpacing.TINY} ${CRMSpacing.MEDIUM}`}
                        >
                            <SpacingRow justifyContent="space-between">
                                <SpacingRow spacing={CRMSpacing.SMALL}>
                                    <CRMButtonIcon
                                        title="Archive introducer."
                                        variant="quaternary"
                                        icon="delete"
                                        onClick={onArchive}
                                    />
                                    
                                    {(getIntroducerStatus(props.form) === "agreement-signed"
                                        || getIntroducerStatus(props.form) === "agreement-requires-review")
                                        && <CRMButtonIcon
                                            title="Download agreement."
                                            variant="quaternary"
                                            icon="can-download"
                                            onClick={onDownloadReferralAgreement}
                                        />
                                    }
                                </SpacingRow>

                                <CRMButtonIcon
                                    title="Edit introducer."
                                    variant="primary"
                                    icon="pencil"
                                    onClick={onEdit}
                                />
                            </SpacingRow>
                        </Padding>
                    }
                </CRMBottomControlBar>
            </div>
        </>
    );
};


type TIntroducerStatus =
    "agreement-not-required"
    | "no-agreement-signed"
    | "agreement-signed"
    | "agreement-requires-review"
;

export const getIntroducerStatus = (introducer: TIntroducerForm): TIntroducerStatus => {
    let reviewDate = introducer.edited.referral_agreement_next_review_date;

    if (!!introducer.edited.is_paid_referral_fee 
        && !!introducer.children.existing_agreement_form?.edited.id
        && (!reviewDate || !isDateInThePast(reviewDate))
    ) {
        return "agreement-signed"
    }
    if (!!introducer.edited.is_paid_referral_fee
        && !!introducer.children.existing_agreement_form?.edited.id
        && (isDateInThePast(reviewDate || ""))) {
        return "agreement-requires-review"
    }
    if (!!introducer.edited.is_paid_referral_fee && !introducer.children.existing_agreement_form?.edited.id) {
        return "no-agreement-signed"
    }

    return "agreement-not-required"
}
