import React, { useEffect } from "react";
import { CRMQuoteBlock } from "../../components/Complex/CRMQuoteBlock/CRMQuoteBlock";
import { CRMBlockScrollCacheView } from "../../components/CRM/CRMBlock/CRMBlockViews/CRMBlockViews";
import { TBlockContainerStateProps } from "../../state/TBlockContainerStateProps";

export const CRMLegalCaseQuoteContainer = (props: TBlockContainerStateProps): JSX.Element => {

    useEffect(
        () => {
            if (!props.isFirstLoad) {
                props.dispatch({
                    type: "CRM_CASE_QUOTE_VIEW",
                    payload: null,
                })
            }
        },
        []
    );

    return (
        <CRMBlockScrollCacheView isShowing={true}>
            <CRMQuoteBlock
                quoteForms={props.state.forms.case_details_page.data.output.details.children.quotes}
                createQuoteForm={props.state.forms.case_details_page.data.output.details.children.create_quote_form}
                caseQuoteSentToClientForm={props.state.forms.case_details_page.data.output.details.children.quote_sent_to_client_form}
                onChangeQuoteSentForm={(payload) => props.dispatch({
                    type: "CRM_CASE_QUOTE_SENT_TO_CLIENT_FORM_CHANGE",
                    payload,
                })}
                onSubmitQuoteSentForm={(payload) => props.dispatch({
                    type: "CRM_CASE_QUOTE_SENT_TO_CLIENT_FORM_SUBMIT",
                    payload,
                })}
                onChangeCreateQuote={(payload) => props.dispatch({
                    type: "CRM_CASE_QUOTE_CREATE_CHANGE",
                    payload,
                })}
                onSubmitCreateQuote={(payload) => props.dispatch({
                    type: "CRM_CASE_QUOTE_CREATE_SUBMIT",
                    payload,
                })}
            />
        </CRMBlockScrollCacheView>
    )
}