import * as QueryStringFilterMappings from "./QueryStringFilterMappings";
import * as QueryStringFilterGroup1 from "./QueryStringFilterGroup1";
import * as QueryStringFilterMappingFunction from "./QueryStringFilterMappingFunction";
import { TValidationFunction } from "../../shared/src/validation/Function";

export const filterMappings: QueryStringFilterMappings.T = {
    date: {
        received_date: QueryStringFilterMappingFunction.createForDate("cases_documents.received_date"),
    },
};

export const validator: TValidationFunction = QueryStringFilterGroup1.createValidator(filterMappings);

export const createForReceivedDateNotNull = (): QueryStringFilterGroup1.T => ({
    type: "GROUP",
    conditional: "OR",
    filters: [{
        type: "FILTER",
        property: "received_date",
        operator: "DATE_IS_NOT_NULL",
        value: "",
    }],
});
