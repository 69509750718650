import React from "react";
import CRMAccordion from "../../components/CRM/CRMAccordionComponent/CRMAccordion";
import { array, option } from "fp-ts/lib";
import { FormRow, FormBody, FormCenteredRow } from "../../components/FormTemplateBlocksComponents/FormTemplateBlocks";
import CRMCard from "../../components/CRMCardComponent/CRMCardComponent";
import LabelWrapForInputs from "../../components/OldFront/Simple/Label/LabelWrapForInputs";
import InputText from "../../components/OldFront/Simple/Text/InputText";
import FormButton from "../../components/FormButtonComponent/FormButton";
import * as TFormStatus from "../../models/TFormStatus";
import { TUserActionPayload } from "../../state/applyActions";
import * as TCRMParty from "../../state/TCRMParty";
import * as User4 from "../../../../domain/models/User4";
import * as JsonError1 from "../../../../domain/models/JsonError1";
import * as JsonInnerError1 from "../../../../domain/models/JsonInnerError1";
import * as FirstPartyFetchResponse from "../../../../domain/models/FirstPartyFetchResponse";
import * as UserPhoneNumber1 from "../../../../domain/models/UserPhoneNumber1";
import InputEmail from "../../components/OldFront/Simple/Email/InputEmail";
import Button from "../../components/OldFront/Simple/ButtonComponent/Button";
import InputPhoneNumber from "../../components/OldFront/Simple/PhoneNumber/InputPhoneNumber";
import Heading5 from "../../components/OldFront/Simple/Heading5Component/Heading5";
import { ThisOrThatInput } from "../../components/ThisOrThatInputComponent/ThisOrThatInputComponent";
import CRMPartyUserExistingPhoneNumbersContainer from "../CRMPartyUserExistingPhoneNumbersContainer/CRMPartyUserExistingPhoneNumbersContainer";
import { TContainerStateProps } from "../../state/TContainerStateProps";
import { pipe } from "fp-ts/lib/function";
import { CRMParagraph } from "../../components/Simple/CRMParagraph/CRMParagraph";
import { CRMCardInside } from "../../components/CRMCardInside/CRMCardInside";
import { CRMPadding } from "../../components/Simple/CRMPadding/CRMPadding";

const getFormStatusToUse = (user: TCRMParty.C["users"][number]): TFormStatus.T =>
     pipe(
        [
            user.userForm.status,
            user.newPhoneNumber.form.status,
            ... pipe(
                user.existingPhoneNumbers,
                array.map((userExistingPhoneNumber) => userExistingPhoneNumber.status)
            ),
        ],
        TFormStatus.reduceToHighestPriority,
    );

const displayUserNameOrDefault = (user: User4.T): string =>
    user.first_name === "" && user.last_name === ""
        ? "Name unknown"
        : `${user.first_name} ${user.last_name}`;

const displayUserPhoneNumbersOrDefault = (phoneNumbers: Array<UserPhoneNumber1.T>): string =>
    phoneNumbers.length === 0
        ? "Phone number unknown"
        :  pipe(
            phoneNumbers,
            array.map((phoneNumber) => phoneNumber.primary_number ? `${phoneNumber.phone_number} (primary)` : phoneNumber.phone_number),
            (p) => p.join(", "),
        );

class CRMPartyUsersContainer extends React.Component<React.PropsWithChildren<TContainerStateProps>> {
    public render(): JSX.Element {
        return (
            <CRMAccordion
                title={`People (${this.props.state.activeData.crm.party.users.length})`}
                formStatus={ pipe(
                    this.props.state.activeData.crm.party.users,
                    array.map(getFormStatusToUse),
                    array.filter((status) => status !== TFormStatus.constants.UNTOUCHED),
                    array.head,
                    option.getOrElse<TFormStatus.T>(() => TFormStatus.constants.UNTOUCHED),
                )}
                defaultsOpen={true}
            >
                {/* ADD NEW/EXISTING USER TO PARTY */}
                <Heading5
                    text="Add new or existing user to the party"
                />
                <FormBody>
                    <FormRow>
                        <LabelWrapForInputs
                            label="Search for an existing user to associate with this party"
                        >
                            <ThisOrThatInput
                                thisInput={
                                    <InputText
                                        placeholder="Search for a user"
                                        value={this.props.state.activeData.crm.party.addPerson.existingUser.searchTerm}
                                        onChange={(value) => this.props.dispatch({
                                            type: "CRM_PARTY_ADD_EXISTING_USER_SEARCH_INPUT_CHANGED",
                                            payload: value,
                                        })}
                                        onPressEnterKey={() => undefined}
                                    />
                                }
                                thatInput={
                                    <FormButton
                                        type="frog"
                                        status={this.props.state.activeData.crm.party.addPerson.newUserForm.status}
                                        label="Add new person"
                                        onClick={() => this.props.dispatch({
                                            type: "CRM_PARTY_ADD_NEW_PERSON_SUBMIT",
                                        })}
                                        fullWidth={true}
                                    />
                                }
                                thisInputSize="70%"
                                thatInputSize="30%"
                            />
                        </LabelWrapForInputs>
                    </FormRow>
                </FormBody>
                {/* SEARCH RESULTS FOR ADD EXISTING USER TO PARTY */}
                { pipe(
                    this.props.state.activeData.crm.party.addPerson.existingUser.searchResults,
                    array.mapWithIndex((i, result) =>
                        <CRMCard
                            key={i}
                            title={displayUserNameOrDefault(result.meta)}
                        >
                            <div className="crmPartyAddPersonContainer__peopleItem">
                                <div>
                                    <FormRow>
                                        {result.meta.email === "" ? "Email unknown" : result.meta.email}
                                    </FormRow>
                                    <FormRow>
                                        {displayUserPhoneNumbersOrDefault(result.meta.phone_numbers)}
                                    </FormRow>
                                </div>
                                <div>
                                    <Button
                                        label="Add user to party"
                                        type="frog"
                                        onClick={() => this.props.dispatch({
                                            type: "CRM_PARTY_ADD_EXISTING_USER_SELECTED",
                                            payload: {
                                                userId: result.meta.id,
                                            },
                                        })}
                                    />
                                </div>
                            </div>
                        </CRMCard>
                    ),
                )}
                {this.props.state.activeData.crm.party.addPerson.existingUser.searchResults.length >= 20
                    ? <FormCenteredRow>
                        Refine your search criteria to see more results
                    </FormCenteredRow>
                    : ""
                }
                {/* EXISTING USERS IN PARTY */}
                <Heading5
                    text="Users in this party"
                />
                <CRMCardInside>
                    <CRMPadding size="medium">
                        <CRMParagraph>
                            NB: Editing the client on all parties.
                        </CRMParagraph>
                    </CRMPadding>
                </CRMCardInside>
                {this.props.state.activeData.crm.party.users.length > 0 ?  pipe(
                    this.props.state.activeData.crm.party.users,
                    array.mapWithIndex((i, user) =>
                        <CRMCard
                            key={i}
                        >
                            <FormBody>
                                <FormRow>
                                    <LabelWrapForInputs
                                        label="First name"
                                    >
                                        <InputText
                                            value={user.userForm.form.first_name}
                                            onChange={(value) => this.props.dispatch({
                                                type: "CRM_PARTY_USER_INPUT_CHANGED",
                                                payload: {
                                                    userId: user.userForm.meta.id,
                                                    key: "first_name",
                                                    value,
                                                } as TUserActionPayload<TCRMParty.C["users"][number]["userForm"]["form"], "first_name">,
                                            })}
                                            onPressEnterKey={() => this.props.dispatch({
                                                type: "CRM_PARTY_USER_SUBMIT",
                                                payload: {
                                                    userId: user.userForm.meta.id,
                                                },
                                            })}
                                        />
                                    </LabelWrapForInputs>
                                </FormRow>
                                <FormRow>
                                    <LabelWrapForInputs
                                        label="Last name"
                                    >
                                        <InputText
                                            value={user.userForm.form.last_name}
                                            onChange={(value) => this.props.dispatch({
                                                type: "CRM_PARTY_USER_INPUT_CHANGED",
                                                payload: {
                                                    userId: user.userForm.meta.id,
                                                    key: "last_name",
                                                    value,
                                                } as TUserActionPayload<TCRMParty.C["users"][number]["userForm"]["form"], "last_name">,
                                            })}
                                            onPressEnterKey={() => this.props.dispatch({
                                                type: "CRM_PARTY_USER_SUBMIT",
                                                payload: {
                                                    userId: user.userForm.meta.id,
                                                },
                                            })}
                                        />
                                    </LabelWrapForInputs>
                                </FormRow>
                                <FormRow>
                                    <LabelWrapForInputs
                                        label="Email"
                                        errorMessage={
                                             pipe(
                                                JsonError1.getInnerErrorsForTargetKeys(["email"], user.userForm.response.validationInnerErrors),
                                                (innerErrors) =>
                                                    array.filter<JsonInnerError1.T>((innerError) => innerError.error_code === "NotUnique")(innerErrors).length > 0 ? "This email address is already in use"
                                                    : array.filter<JsonInnerError1.T>((innerError) => innerError.error_code === "RegexValidation")(innerErrors).length > 0 ? "The email address is invalid"
                                                    : undefined
                                            )
                                        }
                                    >
                                        <InputEmail
                                            value={user.userForm.form.email || ""}
                                            onChange={(value) => this.props.dispatch({
                                                type: "CRM_PARTY_USER_INPUT_CHANGED",
                                                payload: {
                                                    userId: user.userForm.meta.id,
                                                    key: "email",
                                                    value: value === "" ? null : value,
                                                } as TUserActionPayload<TCRMParty.C["users"][number]["userForm"]["form"], "email">,
                                            })}
                                            onPressEnterKey={() => this.props.dispatch({
                                                type: "CRM_PARTY_USER_SUBMIT",
                                                payload: {
                                                    userId: user.userForm.meta.id,
                                                },
                                            })}
                                            displayError={FirstPartyFetchResponse.hasValidationErrorsForTargetKeys(["email"], user.userForm.response)}
                                        />
                                    </LabelWrapForInputs>
                                </FormRow>
                                <CRMPartyUserExistingPhoneNumbersContainer
                                    state={this.props.state}
                                    dispatch={this.props.dispatch}
                                    userId={user.userForm.meta.id}
                                    existingPhoneNumbers={user.existingPhoneNumbers}
                                />
                                {user.newPhoneNumber.isVisible ? (
                                    <FormRow>
                                        <LabelWrapForInputs
                                            errorMessage={
                                                 pipe(
                                                    JsonError1.getInnerErrorsForTargetKeys(["phone_number"], user.newPhoneNumber.form.response.validationInnerErrors),
                                                    (innerErrors) =>
                                                        array.filter<JsonInnerError1.T>((innerError) => innerError.error_code === "NotUnique")(innerErrors).length > 0 ? "This user is already associated with that number"
                                                        : array.filter<JsonInnerError1.T>((innerError) => innerError.error_code === "RegexValidation")(innerErrors).length > 0 ? "The phone number is invalid"
                                                        : undefined
                                                )
                                            }
                                        >
                                            <InputPhoneNumber
                                                value={user.newPhoneNumber.form.form.phone_number || ""}
                                                onChange={(value) => this.props.dispatch({
                                                    type: "CRM_PARTY_USER_NEW_PHONE_NUMBER_INPUT_CHANGED",
                                                    payload: {
                                                        userId: user.newPhoneNumber.form.meta.id,
                                                        key: "phone_number",
                                                        value,
                                                    } as TUserActionPayload<TCRMParty.C["users"][number]["newPhoneNumber"]["form"]["form"], "phone_number">,
                                                })}
                                                onPressEnterKey={() => this.props.dispatch({
                                                    type: "CRM_PARTY_USER_SUBMIT",
                                                    payload: {
                                                        userId: user.newPhoneNumber.form.meta.id,
                                                    },
                                                })}
                                                displayError={FirstPartyFetchResponse.hasValidationErrorsForTargetKeys(["phoneNumber"], user.newPhoneNumber.form.response)}
                                            />
                                        </LabelWrapForInputs>
                                    </FormRow>
                                ) : (
                                    <FormRow>
                                        <Button
                                            type="frog"
                                            label="Add new number"
                                            onClick={() => this.props.dispatch({
                                                type: "CRM_PARTY_USER_ADD_NEW_PHONE_NUMBER_CLICKED",
                                                payload: {
                                                    userId: user.userForm.meta.id,
                                                },
                                            })}
                                        />
                                    </FormRow>
                                )}
                                <FormRow>
                                    <FormButton
                                        status={getFormStatusToUse(user)}
                                        type="frog"
                                        onClick={() => this.props.dispatch({
                                            type: "CRM_PARTY_USER_SUBMIT",
                                            payload: {
                                                userId: user.userForm.meta.id,
                                            },
                                        })}
                                        label="Save"
                                    />
                                </FormRow>
                            </FormBody>
                        </CRMCard>
                    ),
                ) : (
                    <div className="crmParty__peopleEmpty">
                        There are no users in this party
                    </div>
                )}
            </CRMAccordion>
        );
    }
}

export default CRMPartyUsersContainer;
