import React from "react";
import { TContainerStateProps } from "../../state/TContainerStateProps";
import { CRMSpacingColumn } from "../../components/CRMSpacingColumn/CRMSpacingColumn";
import { CRMTitleSection } from "../../components/CRMTitleSection/CRMTitleSection";
import { CRMAutosaveIndicatorWrap } from "../../components/CRM/CRMAutosaveIndicatorWrap/CRMAutosaveIndicatorWrap";
import { TCasesSdlt, TCasesSdltAnswersReviewOutcome } from "../../../../domain/codecs/Cases";
import CRMInputLabelAndErrorWrapComponent from "../../components/CRMInputLabelAndErrorWrapComponent/CRMInputLabelAndErrorWrapComponent";
import { CRMDropdownComponent } from "../../components/CRMDropdownComponent/CRMDropdownComponent";
import { CRMTitleSubSection } from "../../components/CRMTitleSubSection/CRMTitleSubSection";
import { IOption } from "../../hooks/UseDropdown";
import { sdltQuestionLabel_sdlt_all_first_time_buyers, sdltQuestionLabel_sdlt_any_other_properties_on_land, sdltQuestionLabel_sdlt_child_owns_other_property, sdltQuestionLabel_sdlt_everyone_resides_in_uk, sdltQuestionLabel_sdlt_in_partnership_business_owning_other_property, sdltQuestionLabel_sdlt_is_shared_ownership, sdltQuestionLabel_sdlt_joint_buyers_owning_other_property, sdltQuestionLabel_sdlt_other_properties_value_supporting_notes, sdltQuestionLabel_sdlt_spouse_or_partner_owning_other_property, sdltQuestionLabel_sdlt_transaction_replaces_main_residence, sdltQuestionLabel_sdlt_transaction_results_in_owning_two_or_more, sdltQuestionLabel_sdlt_will_be_main_residence } from "../Front/FrontSdltQuestions";
import CRMTextAreaComponent from "../../components/CRMTextAreaComponent/CRMTextAreaComponent";
import { CRMNoticeBoxComponent } from "../../components/CRMNoticeBoxComponent/CRMNoticeBoxComponent";
import { CRMCardInside } from "../../components/CRMCardInside/CRMCardInside";
import { CRMPadding } from "../../components/Simple/CRMPadding/CRMPadding";
import { CRMParagraph } from "../../components/Simple/CRMParagraph/CRMParagraph";
import { WeightBold } from "../../components/WeightBold/WeightBold";
import { CRMDeclarationComponent } from "../../components/CRMDeclarationComponent/CRMDeclarationComponent";
import { CRMFontSizes } from "../../models/CRMFontSizes";
import { CRMTextHighlight } from "../../components/CRMTextHighlight/CRMTextHighlight";
import { pipe } from "fp-ts/lib/function";
import { FormStatus_highestPriority } from "../../../../shared/src/codecs/codec";
import { CRMSpacingRow } from "../../components/CRMSpacingRow/CRMSpacingRow";
import { CRMRadioListComponent } from "../../components/CRMInputs/CRMRadioListComponent/CRMRadioListComponent";
import { CRMDeferButtonComponent } from "../../components/CRMDeferButtonComponent/CRMDeferButtonComponent";

const standardDropdownOptions: Array<IOption<"yes" | "no" | "dont_know" | "unknown">> = [
    {
        label: "Yes",
        value: "yes",
    },
    {
        label: "No",
        value: "no",
    },
    {
        label: <CRMTextHighlight colour="highlights-instructional-brick-6">I don't know</CRMTextHighlight>,
        value: "dont_know",
    },
    {
        label: "Not answered/applicable",
        value: "unknown",
    }
];

const CRMLegalCaseSdltContainer = (props: TContainerStateProps): JSX.Element => {
    const dispatch = <A extends keyof TCasesSdlt>(key: A) => (value: TCasesSdlt[A]) =>
        props.dispatch({
            type: "LEGAL_CASE_SDLT_CHANGE",
            payload: {
                ...props.state.forms.case_details_page.data.output.details.children.sdlt,
                edited: {
                    ...props.state.forms.case_details_page.data.output.details.children.sdlt.edited,
                    [key]: value,
                }
            }
        });

    const sdltSubmittedByClientAndDeclarationAgreedTo = (): boolean => props.state.forms.case_details_page.data.output.details.children.sdlt.children.status === "client_submitted";

    const declarationCtaLabel = () =>
        `Agreed on behalf of ${props.state.forms.case_details_page.data.output.details.children.sdlt.children.client_names_for_declaration}`;

    return <CRMAutosaveIndicatorWrap status={pipe(
        [
            props.state.forms.case_details_page.data.output.details.children.sdlt.status,
            props.state.forms.case_details_page.data.output.details.status,
        ],
        FormStatus_highestPriority,
    )}>
        <CRMSpacingColumn spacing="large">
            <CRMTitleSection>SDLT</CRMTitleSection>
            {/* READY FOR SUBMISSION NOTICE */}
            {sdltSubmittedByClientAndDeclarationAgreedTo() && <CRMCardInside>
                <CRMPadding size="medium">
                    <CRMParagraph>
                        Client(s) have submitted the answers below and declared them to be true.
                    </CRMParagraph>
                </CRMPadding>
            </CRMCardInside>}

            {/* CLIENT NOT SUBMITTED / NOT DECLARED */}
            {!sdltSubmittedByClientAndDeclarationAgreedTo() && <CRMNoticeBoxComponent>
                You <WeightBold>must not</WeightBold> draft an SDLT return until the client(s) have agreed to the declaration below.
            </CRMNoticeBoxComponent>}

            {/* QUESTIONS */}
            <CRMTitleSubSection>Questions</CRMTitleSubSection>
            <CRMSpacingColumn spacing="medium">
                <CRMInputLabelAndErrorWrapComponent label={sdltQuestionLabel_sdlt_transaction_results_in_owning_two_or_more("transaction")}>
                    <CRMDropdownComponent
                        value={props.state.forms.case_details_page.data.output.details.children.sdlt.edited.sdlt_transaction_results_in_owning_two_or_more}
                        options={standardDropdownOptions}
                        onChange={dispatch("sdlt_transaction_results_in_owning_two_or_more")}
                        displayError={false}
                    />
                </CRMInputLabelAndErrorWrapComponent>

                <CRMInputLabelAndErrorWrapComponent label={sdltQuestionLabel_sdlt_spouse_or_partner_owning_other_property()}>
                    <CRMDropdownComponent
                        value={props.state.forms.case_details_page.data.output.details.children.sdlt.edited.sdlt_spouse_or_partner_owning_other_property}
                        options={standardDropdownOptions}
                        onChange={dispatch("sdlt_spouse_or_partner_owning_other_property")}
                        displayError={false}
                    />
                </CRMInputLabelAndErrorWrapComponent>

                <CRMInputLabelAndErrorWrapComponent label={sdltQuestionLabel_sdlt_joint_buyers_owning_other_property()}>
                    <CRMDropdownComponent
                        value={props.state.forms.case_details_page.data.output.details.children.sdlt.edited.sdlt_joint_buyers_owning_other_property}
                        options={standardDropdownOptions}
                        onChange={dispatch("sdlt_joint_buyers_owning_other_property")}
                        displayError={false}
                    />
                </CRMInputLabelAndErrorWrapComponent>

                <CRMInputLabelAndErrorWrapComponent label={sdltQuestionLabel_sdlt_child_owns_other_property()}>
                    <CRMDropdownComponent
                        value={props.state.forms.case_details_page.data.output.details.children.sdlt.edited.sdlt_child_owns_other_property}
                        options={standardDropdownOptions}
                        onChange={dispatch("sdlt_child_owns_other_property")}
                        displayError={false}
                    />
                </CRMInputLabelAndErrorWrapComponent>

                <CRMInputLabelAndErrorWrapComponent label={sdltQuestionLabel_sdlt_other_properties_value_supporting_notes()}>
                    <CRMTextAreaComponent
                        value={props.state.forms.case_details_page.data.output.details.children.sdlt.edited.sdlt_other_properties_value_supporting_notes}
                        onChange={dispatch("sdlt_other_properties_value_supporting_notes")}
                        displayError={false}
                    />
                </CRMInputLabelAndErrorWrapComponent>

                <CRMInputLabelAndErrorWrapComponent label={sdltQuestionLabel_sdlt_will_be_main_residence(false)}>
                    <CRMDropdownComponent
                        value={props.state.forms.case_details_page.data.output.details.children.sdlt.edited.sdlt_will_be_main_residence}
                        options={standardDropdownOptions}
                        onChange={dispatch("sdlt_will_be_main_residence")}
                        displayError={false}
                    />
                </CRMInputLabelAndErrorWrapComponent>

                <CRMInputLabelAndErrorWrapComponent label={sdltQuestionLabel_sdlt_transaction_replaces_main_residence()}>
                    <CRMDropdownComponent
                        value={props.state.forms.case_details_page.data.output.details.children.sdlt.edited.sdlt_transaction_replaces_main_residence}
                        options={standardDropdownOptions}
                        onChange={dispatch("sdlt_transaction_replaces_main_residence")}
                        displayError={false}
                    />
                </CRMInputLabelAndErrorWrapComponent>

                <CRMInputLabelAndErrorWrapComponent label={sdltQuestionLabel_sdlt_in_partnership_business_owning_other_property()}>
                    <CRMDropdownComponent
                        value={props.state.forms.case_details_page.data.output.details.children.sdlt.edited.sdlt_in_partnership_business_owning_other_property}
                        options={standardDropdownOptions}
                        onChange={dispatch("sdlt_in_partnership_business_owning_other_property")}
                        displayError={false}
                    />
                </CRMInputLabelAndErrorWrapComponent>

                <CRMInputLabelAndErrorWrapComponent label={sdltQuestionLabel_sdlt_is_shared_ownership("transaction")}>
                    <CRMDropdownComponent
                        value={props.state.forms.case_details_page.data.output.details.children.sdlt.edited.sdlt_is_shared_ownership}
                        options={standardDropdownOptions}
                        onChange={dispatch("sdlt_is_shared_ownership")}
                        displayError={false}
                    />
                </CRMInputLabelAndErrorWrapComponent>

                <CRMInputLabelAndErrorWrapComponent label={sdltQuestionLabel_sdlt_all_first_time_buyers(false)}>
                    <CRMDropdownComponent
                        value={props.state.forms.case_details_page.data.output.details.children.sdlt.edited.sdlt_all_first_time_buyers}
                        options={standardDropdownOptions}
                        onChange={dispatch("sdlt_all_first_time_buyers")}
                        displayError={false}
                    />
                </CRMInputLabelAndErrorWrapComponent>

                <CRMInputLabelAndErrorWrapComponent label={sdltQuestionLabel_sdlt_any_other_properties_on_land()}>
                    <CRMDropdownComponent
                        value={props.state.forms.case_details_page.data.output.details.children.sdlt.edited.sdlt_any_other_properties_on_land}
                        options={standardDropdownOptions}
                        onChange={dispatch("sdlt_any_other_properties_on_land")}
                        displayError={false}
                    />
                </CRMInputLabelAndErrorWrapComponent>

                <CRMInputLabelAndErrorWrapComponent label={sdltQuestionLabel_sdlt_everyone_resides_in_uk(false)}>
                    <CRMDropdownComponent
                        value={props.state.forms.case_details_page.data.output.details.children.sdlt.edited.sdlt_everyone_resides_in_uk}
                        options={standardDropdownOptions}
                        onChange={dispatch("sdlt_everyone_resides_in_uk")}
                        displayError={false}
                    />
                </CRMInputLabelAndErrorWrapComponent>
            </CRMSpacingColumn>

            {/* DECLARATION */}
            {!sdltSubmittedByClientAndDeclarationAgreedTo() && <CRMDeclarationComponent
                title="Read the following declaration to the client over the phone and press the button if they agree to it."
                subTitle="If this declaration is not agreed to now the client will be asked to agree to it in the portal."
                cta={{
                    visible: true,
                    formStatus: props.state.forms.case_details_page.data.output.details.children.sdlt.children.submit_declaration.status === "untouched"
                        ? "requiresSubmission"
                        : props.state.forms.case_details_page.data.output.details.children.sdlt.children.submit_declaration.status,
                    ctaLabel: declarationCtaLabel(),
                    onCta: () => props.dispatch({
                        type: "LEGAL_CASE_SDLT_SUBMIT_DECLARATION",
                        payload: props.state.forms.case_details_page.data.output.details.children.sdlt.children.submit_declaration,
                    }),
                }}
            >
                <CRMSpacingColumn spacing="large">
                    <CRMParagraph fontSize={CRMFontSizes.MED}>
                        You are aware that your tax affairs are your liability. As to the nature of your Stamp Duty/Land Transaction Tax liability you understand this is also the case.
                    </CRMParagraph>
                    <CRMParagraph fontSize={CRMFontSizes.MED}>
                        You accept that if any circumstances come to light that make you liable to the additional Stamp Duty/Land Transaction Tax surcharge the liability rests with you.
                    </CRMParagraph>
                    <CRMParagraph fontSize={CRMFontSizes.MED}>
                        You further declare that the information given is correct and complete to the best of your knowledge and belief and understand that
                        you may have to pay financial penalties and face prosecution if you have given false information which results in an incorrect Stamp Duty/Land Transaction Tax submission
                        being made by you or on your behalf.
                    </CRMParagraph>
                </CRMSpacingColumn>
            </CRMDeclarationComponent>}

            {/* REVIEW */}
            {sdltSubmittedByClientAndDeclarationAgreedTo() && <>
                <CRMSpacingRow spacing="medium" childSize="1fr 1fr" alignItems="flex-start">
                    <CRMTitleSubSection>
                        Review answers
                    </CRMTitleSubSection>

                    {/* DEFER */}
                    <div style={{display: "flex", justifyContent: "flex-end"}}>
                        <CRMDeferButtonComponent
                            value={props.state.forms.case_details_page.data.output.details.edited.sdlt_answers_review_defer}
                            onChange={(sdlt_answers_review_defer) => props.dispatch({
                            type: "LEGAL_CASE_DETAILS_CHANGE",
                            payload: {
                                ...props.state.forms.case_details_page.data.output.details,
                                edited: {
                                    ...props.state.forms.case_details_page.data.output.details.edited,
                                    sdlt_answers_review_defer
                                }
                            }
                            })}
                            displayError={false}
                        />
                    </div>
                </CRMSpacingRow>
                <CRMInputLabelAndErrorWrapComponent label="Review the answers given above and decide if a referral to SDLT Compass is required">
                    <CRMRadioListComponent<TCasesSdltAnswersReviewOutcome, TCasesSdltAnswersReviewOutcome>
                        value={props.state.forms.case_details_page.data.output.details.edited.sdlt_answers_reviewed_outcome}
                        direction="column"
                        options={[
                            {
                                value: "referred_to_compass",
                                text: "Refer these to SDLT Compass before drafting SDLT return",
                            },
                            {
                                value: "compass_referral_not_required",
                                text: "No referral required, we can draft the SDLT return",
                            },
                            {
                                value: "unknown",
                                text: "Not reviewed yet",
                            },
                        ]}
                        onChange={(sdlt_answers_reviewed_outcome) => props.dispatch({
                            type: "LEGAL_CASE_DETAILS_CHANGE",
                            payload: {
                                ...props.state.forms.case_details_page.data.output.details,
                                edited: {
                                    ...props.state.forms.case_details_page.data.output.details.edited,
                                    sdlt_answers_reviewed_outcome,
                                }
                            }
                        })}
                    />
                </CRMInputLabelAndErrorWrapComponent>
            </>}
        </CRMSpacingColumn>
    </CRMAutosaveIndicatorWrap>;
};

export default CRMLegalCaseSdltContainer;