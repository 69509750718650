import React from "react";
import { from, fromEvent, OperatorFunction } from "rxjs";
import { map, mergeMap, takeUntil, tap } from "rxjs/operators";
import ReactDOM from "react-dom";
import { fromNullable, fold as foldOption } from "fp-ts/lib/Option";
import { fold as foldEither, left, right } from "fp-ts/lib/Either";
import { pipe } from "fp-ts/lib/function";
import Icon from "../OldFront/Simple/IconComponent/Icon";

interface IImageGalleryProps {
    images: Array<IImages>;
    showCarousel: boolean;
}

export interface IImages {
    src: string;
    alt: string;
}

interface IImagesWithIds {
    id: number;
    src: string;
    alt: string;
}

interface IImageGalleryState {
    imagesWithIds: Array<IImagesWithIds>;
    imagesCount: number;
    galleryCurrentImageId: number;
    carouselCurrentImageId: number;
    showWindowControls: boolean;
    showPagination: boolean;
    componentHeight: number;
    carouselMargin: number;
}

export class ImageGallery extends React.Component<React.PropsWithChildren<IImageGalleryProps>, IImageGalleryState> {
    public containerElement: HTMLElement | undefined;

    constructor(props: IImageGalleryProps) {
        super(props);
        const images: Array<IImagesWithIds> = this.generateImageIds();
        const imagesLength: number = images.length;
        this.state = {
            imagesCount: imagesLength,
            imagesWithIds: images,
            galleryCurrentImageId: 0,
            carouselCurrentImageId: 0,
            showWindowControls: true,
            showPagination: true,
            componentHeight: 0,
            carouselMargin: 0,
        };
    }

    public componentDidMount(): void {
        // Handle the case where the component can not find it's own DOM node
        pipe(
            ReactDOM.findDOMNode(this),
            fromNullable,
            foldOption(
                () => left("failed to find the image gallery component's DOM node"),
                (node) => right(fromNullable(node.parentElement)),
            ),
            foldEither(
                (e) => left(e),
                foldOption(
                    () => left("failed to find the image gallery component's parent DOM node"),
                    (parentElement) => right(parentElement),
                )
            ),
            foldEither(
                console.error,
                (parentElement) => { this.containerElement = parentElement; },
            ),
        );
    }

    public render (): JSX.Element {
        return (
            <div className="imageGallery"
                onClick={() => this.setState({ showPagination: true })}
                onMouseLeave={() => this.setState({ showPagination: false })}
            >
                <div
                    className={
                        `imageGallery__gallery_window
                        ${this.state.showWindowControls === true ? " imageGallery__gallery_window--display_overlay" : " imageGallery__gallery_window--hide_overlay"}
                        ${this.state.showPagination === true ? " imageGallery__gallery_window--display_pagination" : " imageGallery__gallery_window--hide_pagination"}`
                    }
                    onMouseEnter={() => this.setState({
                        showWindowControls: true,
                        showPagination: true,
                    })}
                    onMouseLeave={() => this.setState({
                        showWindowControls: false,
                    })}
                    style={{
                        height: `${this.props.showCarousel === true ? "80%" : "100%"}`,
                    }}
                >
                    <div className="imageGallery__overlay_container">
                        <div className="imageGallery__controls_overlay">
                            <div
                                className="imageGallery__gallery_control imageGallery__gallery_control--left"
                                onClick={this.setGalleryPreviousImage}
                                onTouchStart={this.moveGalleryWithDrag$}
                            >
                                <div className="imageGallery__gallery_control_button">
                                    <Icon iconName="leftArrow" height={3.2} width={3.2} type="mouse" />
                                </div>
                            </div>
                            <div
                                className="imageGallery__gallery_control imageGallery__gallery_control--right"
                                onClick={this.setGalleryNextImage}
                                onTouchStart={this.moveGalleryWithDrag$}
                            >
                                <div className="imageGallery__gallery_control_button">
                                    <Icon iconName="rightArrow" height={3.2} width={3.2} type="mouse" />
                                </div>
                            </div>
                        </div>
                        <div className="imageGallery__pagination_overlay">
                            <div className="imageGallery__pagination_icon_container">
                                <Icon iconName="camera" height={2.2} width={2.2} type="mouse" />
                            </div>
                            <div className="imageGallery__pagination_text_container">
                                <span className="imageGallery__pagination_text">
                                    {`${this.state.galleryCurrentImageId + 1} / ${this.state.imagesCount}`}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div
                        className="imageGallery__image_reel"
                        style={{
                            width: `calc(100% * ${this.state.imagesCount})`,
                            marginLeft: `-${this.state.galleryCurrentImageId * 100}%`,
                        }}
                    >
                        {this.state.imagesWithIds.map((image, index) => (
                            <div
                                key={index}
                                className={
                                    `imageGallery__image
                                    ${this.state.galleryCurrentImageId === image.id
                                        ? " imageGallery__image--selected"
                                        : ""
                                    }`
                                }
                                style={{
                                    width: `calc(100% / ${this.state.imagesCount})`,
                                }}
                                onTouchStart={this.moveGalleryWithDrag$}
                            >
                                <img src={image.src} alt={image.alt} data-id={image.id} />
                            </div>
                        ))}
                    </div>
                </div>
                {this.props.showCarousel ? (
                    <div className={
                        `imageGallery__carousel
                        ${this.state.imagesCount > 5
                            ? " imageGallery__carousel--display_overlay"
                            : " imageGallery__carousel--hide_overlay"
                        }`
                    }>
                        <div className="imageGallery__overlay_container">
                            <div className="imageGallery__controls_overlay">
                                <div
                                    className="imageGallery__carousel_control imageGallery__carousel_control--left"
                                    onClick={this.setCarouselPreviousImageGroup}
                                    onTouchStart={this.moveCarouselWithDrag$}
                                >
                                    <div className="imageGallery__carousel_control_button">
                                        <Icon iconName="leftArrow" height={2.2} width={2.2} type="mouse" />
                                    </div>
                                </div>
                                <div
                                    className="imageGallery__carousel_control imageGallery__carousel_control--right"
                                    onClick={this.setCarouselNextImageGroup}
                                    onTouchStart={this.moveCarouselWithDrag$}
                                >
                                    <div className="imageGallery__carousel_control_button">
                                        <Icon iconName="rightArrow" height={2.2} width={2.2} type="mouse" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            className="imageGallery__carousel_image_reel"
                            style={{
                                width: `calc(100% * ${this.state.imagesCount / 5})`,
                                marginLeft: `calc(-20% * ${this.state.carouselMargin})`,
                            }}
                        >
                            {this.state.imagesWithIds.map((image, index) => (
                                <div
                                    key={index}
                                    className={
                                        "imageGallery__carousel_image_reel_column imageGallery__carousel_image_reel_column" +
                                        (this.state.galleryCurrentImageId === image.id ? "--selected"
                                            : image.id === 0 ? "--first-image"
                                            : image.id === this.state.carouselMargin ? "--image-to-left"
                                            : ""
                                        )
                                    }
                                    style={{
                                        width: `calc(20% / (${this.state.imagesCount / 5}))`,
                                    }}
                                    onClick={() => this.moveGalleryToCarouselImage(image.id)}
                                    onTouchStart={this.moveCarouselWithDrag$}
                                >
                                    <img src={image.src} alt={image.alt} data-id={image.id}></img>
                                </div>
                            ))}
                        </div>
                    </div>
                ) : ""}
            </div>
        );
    }

    private generateImageIds = (): Array<IImagesWithIds> => {
        const images: Array<IImagesWithIds> = [];
        this.props.images.forEach((image, index): void => {
            images.push({
                id: index,
                src: image.src,
                alt: image.alt,
            });
        });
        return images;
    };

    private setGalleryNextImage = (): void => {
        const nextImageId: number = this.state.galleryCurrentImageId >= this.state.imagesCount - 1 ? 0 : this.state.galleryCurrentImageId + 1;
        const margin: number = this.getCarouselNextImage(nextImageId);
        this.setState({
            galleryCurrentImageId: nextImageId,
            carouselCurrentImageId: nextImageId,
            carouselMargin: margin,
        });
    };

    private getCarouselNextImage = (galleryImageId: number): number => {
        let margin: number = this.state.carouselMargin;
        if (galleryImageId < margin || galleryImageId >= (margin + 4)) {
            margin = 0;
            if (galleryImageId > 4 && galleryImageId <= (this.state.imagesCount - 1)) {
                margin = galleryImageId - 4;
            }
            return margin;
        }
        return margin;
    };

    private setGalleryPreviousImage = (): void => {
        const previousImageId: number = this.state.galleryCurrentImageId === 0 ? this.state.imagesCount - 1 : this.state.galleryCurrentImageId - 1;
        const margin = this.getCarouselPreviousImage(previousImageId);
        this.setState({
            galleryCurrentImageId: previousImageId,
            carouselCurrentImageId: previousImageId,
            carouselMargin: margin,
        });
    };

    private getCarouselPreviousImage = (galleryImageId: number): number => {
        let margin: number = this.state.carouselMargin;
        if (galleryImageId < margin || galleryImageId >= (margin + 5)) {
            margin = galleryImageId;
            if (galleryImageId < 0) {
                margin = Math.floor(this.state.imagesCount / 5) * 5;
            }
            return margin;
        }
        return margin;
    };

    private setCarouselNextImageGroup = (): void => {
        this.setState({
            carouselMargin: this.getCarouselNextImageGroup(),
        });
    };

    private getCarouselNextImageGroup = (): number => {
        const margin: number = (this.state.carouselMargin + 5) >= this.state.imagesCount ? 0 : this.state.carouselMargin + 5;
        return margin;
    };

    private setCarouselPreviousImageGroup = (): void => {
        this.setState({
            carouselMargin: this.getCarouselPreviousImageGroup(),
        });
    };

    private getCarouselPreviousImageGroup = (): number => {
        const margin: number = this.state.carouselMargin >= 5 ? this.state.carouselMargin - 5
        : this.state.carouselMargin > 0 ? 0
        : Math.floor(this.state.imagesCount / 5) * 5;
        return margin;
    };

    private moveGalleryWithDrag$ = (e: React.TouchEvent): void => {
        const startingCurrentImageId = this.state.galleryCurrentImageId;
        from([e])
            .pipe(
                map((event) => event.persist()),
                mergeMap(() => fromEvent(document, "touchmove")),
                takeUntil(fromEvent(document, "touchend")) as OperatorFunction<Event, TouchEvent>,
                map((touchEvent) => touchEvent.touches[0].clientX),
                tap((currentClientX) =>
                    pipe(
                        // Get the components left & right position on the screen
                        (this.containerElement as HTMLElement).getBoundingClientRect(),
                        // Get the "movement amount" this is a positive or negative integer that represents how many thirds of the component the user has dragged
                        // their finger across. For example, if the user has dragged their finger across 2/3 of the component then this will either be +2 or -2
                        // depending on if the drag was to the right or left. This value is useful because we require at least 1/3 of the component to be "dragged"
                        // before changing the gallery image.
                        (containerBounds) => Math.round((e.touches[0].clientX - currentClientX) / ((containerBounds.right - containerBounds.left) / 3)),
                        // Even though the user may have dragged by more than 1/3 of the screen we will only increment or decrement the gallery image
                        // that is visible by 1
                        (movementAmount) => movementAmount >= 1 ? startingCurrentImageId + 1
                            : movementAmount <= -1 ? startingCurrentImageId - 1
                            : startingCurrentImageId,
                        // Prevent the user from moving to an image that doesn't exist by ignoring the proposed image index
                        (proposedImageIndex) => proposedImageIndex > this.state.imagesCount - 1 ? 0
                            : proposedImageIndex < 0 ? this.state.imagesCount - 1
                            : proposedImageIndex,
                        this.dragGalleryTo, // Move the gallery to the new index
                    ),
                ),
            )
            .subscribe();
    };

    private dragGalleryTo = (proposedId: number): void => {
        const currentImageId: number = this.state.galleryCurrentImageId;
        let margin: number = this.state.carouselMargin;
        if (currentImageId !== proposedId) {
            margin = this.getCarouselPreviousImage(proposedId);
            if (proposedId > currentImageId) {
                margin = proposedId > (currentImageId + 1) ? Math.floor(this.state.imagesCount / 5) * 5 : this.getCarouselNextImage(proposedId);
            } else if (proposedId < (currentImageId - 1)) {
                margin = 0;
            }
        }
        this.setState({
            galleryCurrentImageId: proposedId,
            carouselCurrentImageId: proposedId,
            carouselMargin: margin,
        });
    };

    private moveCarouselWithDrag$ = (e: React.TouchEvent): void => {
        const startingCurrentImageId = this.state.carouselCurrentImageId;
        from([e])
            .pipe(
                map((event) => event.persist()),
                mergeMap(() => fromEvent(document, "touchmove")),
                takeUntil(fromEvent(document, "touchend")) as OperatorFunction<Event, TouchEvent>,
                map((touchEvent) => touchEvent.touches[0].clientX),
                tap((currentClientX) =>
                    pipe(
                        // Get the components left & right position on the screen
                        (this.containerElement as HTMLElement).getBoundingClientRect(),
                        // Get the "movement amount" this is a positive or negative integer that represents how many fifths of the component the user has dragged
                        // their finger across. For example, if the user has dragged their finger across 2/5 of the component then this will either be +2 or -2
                        // depending on if the drag was to the right or left. This value is useful because we require at least 1/5 of the component to be "dragged"
                        // before changing the carousel image.
                        (containerBounds) => Math.round((e.touches[0].clientX - currentClientX) / ((containerBounds.right - containerBounds.left) / 5)),
                        // Even though the user may have dragged by more than 1/5 of the screen we will only increment or decrement the carousel image group
                        // that is visible by 1 set (i.e. 5 carousel images)
                        (movementAmount) => movementAmount >= 1 ? startingCurrentImageId + 1
                            : movementAmount <= -1 ? startingCurrentImageId - 1
                            : startingCurrentImageId,
                        // Prevent the user from moving to an image that doesn't exist by ignoring the proposed image index
                        (proposedDirection) => proposedDirection > this.state.imagesCount - 1 ? this.state.imagesCount - 1
                            : proposedDirection < 0 ? 0
                            : proposedDirection,
                        this.dragCarouselTo, // Move the carousel to the new group
                    ),
                ),
            )
            .subscribe();
    };

    private dragCarouselTo = (id: number): void => {
        const currentImageId: number = this.state.carouselCurrentImageId;
        const margin: number = id > currentImageId ? this.getCarouselNextImageGroup()
        : id < currentImageId ? this.getCarouselPreviousImageGroup()
        : this.state.carouselMargin;
        this.setState({
            carouselCurrentImageId: id,
            carouselMargin: margin,
        });
    };

    private moveGalleryToCarouselImage = (id: number): void => {
        this.setState({
            galleryCurrentImageId: id,
            carouselCurrentImageId: id,
        });
    };
}
