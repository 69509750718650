import React, { useState, useEffect } from "react";
import { Padding } from "../../../BuildingBlocks/Padding";
import { FrontCondensedQuestionWrap } from "../FrontCondensedQuestionWrap/FrontCondensedQuestionWrap";
import FrontInputLabelAndErrorWrapComponent from "../FrontInputLabelAndErrorWrapComponent/FrontInputLabelAndErrorWrapComponent";
import { isFilled } from "../../../../../../shared/src/util";
import { FrontDropdown } from "../FrontDropdown/FrontDropdown";
import { IOption } from "../../../../hooks/UseDropdown";
import { FrontFormRow } from "../FrontFormRow/FrontFormRow";
import { FrontSpacing } from "../../../../models/FrontSpacing";

type TInputQuestionsProps<A extends string, B extends A> = {
    label: string;
    closedLabel?: string;
    isEditable?: boolean;
    value: B;
    options: Array<IOption<A>>;
    wrapInFormRow?: boolean;
    displayValidationErrorsIfPresent?: boolean;
    isOpen?: boolean;
    unknownValueIsError?: boolean;
    onChange: (value: A) => void; 
};

export const FrontCondensedDropdownQuestion = <A extends string, B extends A>(props: React.PropsWithChildren<TInputQuestionsProps<A, B>>): JSX.Element => {

    const inputRef = React.createRef<HTMLInputElement>();
    const [isOpen, setIsOpen] = useState(props.isOpen ?? props.value.length === 0);

    useEffect(
        () => {
            if (isOpen === true) {
                inputRef.current?.focus();
            }
        },
        [isOpen]
    );

    useEffect(() => {
        if (!props.value) {
            setIsOpen(true);
        }
    }, [props.value]);

    const colapseOnBlurIfFilled = () => {
        if (isFilled(props.value)) {
            setIsOpen(false);
        }
    }

    const getSelectedOptionLabel = () => 
        props.options.find((option) => option.value === props.value)?.label || ""

    const renderDropdown = (): JSX.Element => (
        <Padding type="top" spacing="2px">
            <FrontInputLabelAndErrorWrapComponent
                label={props.label} errorMessage="Please select from the list" 
                displayError={
                    (
                        props.value === ""
                        || (props.unknownValueIsError && props.value === "unknown")
                    )
                    && props.displayValidationErrorsIfPresent
                        ? true
                        : false
                }>
                <div style={{marginLeft: FrontSpacing.SMALL_3}}>
                    <FrontDropdown
                        value={props.value}
                        options={props.options}
                        onChange={props.onChange}
                        onBlur={colapseOnBlurIfFilled}
                    />
                </div>
            </FrontInputLabelAndErrorWrapComponent>
        </Padding>
    );

    return (
        <FrontCondensedQuestionWrap
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            isEditable={props.isEditable}
            label={props.closedLabel || props.label}
            summary={getSelectedOptionLabel()}
        >
            {props.wrapInFormRow &&
                <FrontFormRow>
                    {renderDropdown()}
                </FrontFormRow>
            }
            
            {props.wrapInFormRow !== true &&
                renderDropdown()
            }
        </FrontCondensedQuestionWrap>
    );
};
