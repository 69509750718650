import React from "react";
import Heading3 from "../../components/OldFront/Simple/Heading3Component/Heading3";
import CRMAccordion from "../../components/CRM/CRMAccordionComponent/CRMAccordion";
import CRMListingListComponent from "../../components/CRMListingListComponent/CRMListingListComponent";
import * as ListingStatus1 from "../../../../domain/models/ListingStatus1";
import * as TCRMListings from "../../state/TCRMListings";
import { TDispatch } from "../../state/TDispatch";
import { TContainerStateProps } from "../../state/TContainerStateProps";
import { CRMHomesMenuBarContainer } from "../../containers/CRMHomesMenuBarContainer/CRMHomesMenuBarContainer";
import { createChangeRouteAction } from "../../state/router/createChangeRouteAction";
import { getUrlStringForRoute } from "../../state/router/getUrlStringsForRoute";
import { paths } from "../../state/router/routerPaths";

const getListComponentForStatus = (status: ListingStatus1.T, form: TCRMListings.C[keyof TCRMListings.C], dispatch: TDispatch): JSX.Element =>
    <CRMListingListComponent
        listings={form.meta.data}
        showLoadMoreResults={form.meta.meta.available_count > form.meta.meta.returned_count}
        onListingClick={(listing, e) => {
            e.preventDefault();
            dispatch(createChangeRouteAction(
                "VIEW_CRM_LISTING",
                {
                    listingId: listing.id,
                },
                {}
            ));
        }}
        onLoadMoreResultsClick={() => dispatch({
            type: "CRM_LISTINGS_LOAD_MORE",
            payload: {
                status,
            },
        })}
        urlCallback={(listing) =>
            getUrlStringForRoute(
                paths,
                "VIEW_CRM_LISTING",
                {
                    listingId: listing.id,
                },
                {},
            )
        }
    />;

class CRMListingsPage extends React.Component<React.PropsWithChildren<TContainerStateProps>> {
    public render(): JSX.Element {
        return (
            <div className="crmListingsPage__page">
                <CRMHomesMenuBarContainer {...this.props} />
                
                <div className="crmListing__sectionsContainer">
                    <div className="crmParty__heading">
                        <Heading3
                            text="All Listings"
                        />
                    </div>

                    {/* LEAD */}
                    <CRMAccordion
                        title={`Lead (${this.props.state.activeData.crm.listings.lead.meta.meta.available_count.toString()})`}
                    >
                        {getListComponentForStatus("prep_valuation", this.props.state.activeData.crm.listings.lead, this.props.dispatch)}
                    </CRMAccordion>

                    {/* PREP-VALUATION */}
                    <CRMAccordion
                        title={`Prep Valuation (${this.props.state.activeData.crm.listings.prepValuation.meta.meta.available_count.toString()})`}
                    >
                        {getListComponentForStatus("prep_valuation", this.props.state.activeData.crm.listings.prepValuation, this.props.dispatch)}
                    </CRMAccordion>

                    {/* CHASE INSTRUCTION */}
                    <CRMAccordion
                        title={`Chase Instruction (${this.props.state.activeData.crm.listings.chaseInstruction.meta.meta.available_count.toString()})`}
                    >
                        {getListComponentForStatus("chase_instruction", this.props.state.activeData.crm.listings.chaseInstruction, this.props.dispatch)}
                    </CRMAccordion>

                    {/* INSTRUCTED - NEEDS PREP */}
                    <CRMAccordion
                        title={`Instructed - Needs Market Prep (${this.props.state.activeData.crm.listings.instructedNeedsPrep.meta.meta.available_count.toString()})`}
                    >
                        {getListComponentForStatus("instructed_needs_market_prep", this.props.state.activeData.crm.listings.instructedNeedsPrep, this.props.dispatch)}
                    </CRMAccordion>

                    {/* INSTRUCTED - ON MARKET */}
                    <CRMAccordion
                        title={`Instructed - On The Market (${this.props.state.activeData.crm.listings.instructedOnMarket.meta.meta.available_count.toString()})`}
                        defaultsOpen={true}
                    >
                        {getListComponentForStatus("instructed_on_the_market", this.props.state.activeData.crm.listings.instructedOnMarket, this.props.dispatch)}
                    </CRMAccordion>

                    {/* SSTC */}
                    <CRMAccordion
                        title={`SSTC (${this.props.state.activeData.crm.listings.sstc.meta.meta.available_count.toString()})`}
                    >
                        {getListComponentForStatus("sstc", this.props.state.activeData.crm.listings.sstc, this.props.dispatch)}
                    </CRMAccordion>

                    {/* COMPLETED */}
                    <CRMAccordion
                        title={`Completed (${this.props.state.activeData.crm.listings.completed.meta.meta.available_count.toString()})`}
                    >
                        {getListComponentForStatus("completed", this.props.state.activeData.crm.listings.completed, this.props.dispatch)}
                    </CRMAccordion>

                    {/* LOST */}
                    <CRMAccordion
                        title={`Lost (${this.props.state.activeData.crm.listings.lost.meta.meta.available_count.toString()})`}
                    >
                        {getListComponentForStatus("lost", this.props.state.activeData.crm.listings.lost, this.props.dispatch)}
                    </CRMAccordion>
                </div>
            </div>
        );
    }
}

export default CRMListingsPage;
