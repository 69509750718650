import React from "react";

type TRoadblockMessageTextProps = {
    text: string;
};

export const CRMRoadblockMessageText = (props: React.PropsWithChildren<TRoadblockMessageTextProps>): JSX.Element => {

    return (
        <div 
            className={`crm-roadblock-message-text`}
            dangerouslySetInnerHTML={{ __html: props.text }}
        />
    );
};
