import React from "react";
import { FrontSpacing } from "../../../../models/FrontSpacing";
import { Padding } from "../../../BuildingBlocks/Padding";
import { SpacingColumn } from "../../../BuildingBlocks/SpacingColumn";
import { FrontLink } from "../../../FrontLink/FrontLink";
import { WeightBold } from "../../../WeightBold/WeightBold";
import { FrontBulletpoint } from "../FrontBulletpoint/FrontBulletpoint";
import FrontParagraphSmall from "../FrontParagraphSmall/FrontParagraphSmall";

type TFrontOnboardingCaseOverviewQuoteInfoProps = {
    quoteDownloadUrl: string
};

export const FrontOnboardingCaseOverviewQuoteInfo = (props: React.PropsWithChildren<TFrontOnboardingCaseOverviewQuoteInfoProps>): JSX.Element =>

        <SpacingColumn spacing={FrontSpacing.SMALL_2}>        
            {props.quoteDownloadUrl && <>
                
                <FrontParagraphSmall>
                    <WeightBold>Your Conveyancing Quote</WeightBold>
                </FrontParagraphSmall>

                <Padding type="left" spacing={FrontSpacing.SMALL_1}>
                    <div>
                        <FrontBulletpoint>
                            <FrontParagraphSmall>
                                <FrontLink
                                    href={props.quoteDownloadUrl}
                                    target="_blank"
                                >
                                    Click to view/download
                                </FrontLink>
                            </FrontParagraphSmall>
                        </FrontBulletpoint>
                    </div> 
                </Padding>

                <FrontParagraphSmall>
                    You should expect to see a difference between the quote and Completion Statement amounts.
                </FrontParagraphSmall>

                <FrontParagraphSmall>
                    Third party costs are often around £300.
                </FrontParagraphSmall>
            
            </>}
        </SpacingColumn>
    ;
