import React from "react";
import { CRMCardOutside } from "../CRMCardOutside/CRMCardOutside";
import { CRMIcon, TIcon } from "../CRMIcon/CRMIcon";
import { TCRMMainColours } from "../../models/TCRMMainColours";

type TCRMCardOutsideWarning = {
    borderRounding: "left" | "right";
    icon: TIcon;
    labelColour: TCRMMainColours;
    iconColor?: TCRMMainColours;
};

export class CRMCardOutsideWarning extends React.Component<React.PropsWithChildren<TCRMCardOutsideWarning>> {
    public render (): JSX.Element {
        return (
            <CRMCardOutside borderRounding={this.props.borderRounding} shadow={true}>
                <div className="crm-card-outside-warning">
                    <div className="crm-card-outside-warning__content-area">
                        {this.props.children}
                    </div>
                    <div className={
                        `
                            crm-card-outside-warning__icon-box 
                            ${this.props.borderRounding === "right" && "crm-card-outside-warning__icon-box--rounded-right"}
                            crm-card-outside-warning__icon-box--${this.props.labelColour}
                        `
                    }>
                        <CRMIcon
                            iconName={this.props.icon}
                            colour={this.props.iconColor ? this.props.iconColor : "neutral-ink"}
                            size="small"
                        />
                    </div>
                </div>
            </CRMCardOutside>
        );
    }
}
