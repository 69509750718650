import React from "react";
import { THexColor } from "../../../models/StringLiterals";

export const CRMBadge = (props: React.PropsWithChildren<{
    backgroundColour: THexColor,
    fontColour: THexColor,
}>): JSX.Element =>
    <div
        className="crm-badge"
        style={{
            backgroundColor: props.backgroundColour,
            color: props.fontColour,
        }}
    >
        {props.children}
    </div>;
