import React from "react";
import { TCaseEnquiry1Form } from "../../../models/TFormModels";
import { CRMButtonQuaternary } from "../../CRMButtonQuaternary/CRMButtonQuaternary";
import { CRMSpacingRow } from "../../CRMSpacingRow/CRMSpacingRow";
import { CRMButtonSecondary } from "../../CRMButtonSecondary/CRMButtonSecondary";
import { CRMCardOutsidePopupBlank } from "../../CRMCardOutsidePopupBlank/CRMCardOutsidePopupBlank";
import { IOption } from "./../../../hooks/UseDropdown";
import { CRMFormButton } from "../../CRMFormButton/CRMFormButton";
import CRMInputLabelAndErrorWrapComponent from "../../CRMInputLabelAndErrorWrapComponent/CRMInputLabelAndErrorWrapComponent";
import { CRMMultiSelectDropdownComponent } from "../../CRMMultiSelectDropdownComponent/CRMMultiSelectDropdownComponent";
import { CRMPadding } from "../../Simple/CRMPadding/CRMPadding";
import CRMTextAreaComponent from "../../CRMTextAreaComponent/CRMTextAreaComponent";
import { CRMTitleForCard } from "../../CRMTitleForCard/CRMTitleForCard";
import * as JsonError1 from "../../../../../domain/models/JsonError1";
import * as CaseEnquiryDocument1 from "../../../../../domain/models/CaseEnquiryDocument1";
import { array, option } from "fp-ts";
import CRMInputGeneralComponent from "../../CRMInputs/CRMInputGeneralComponent/CRMInputGeneralComponent";
import { pipe } from "fp-ts/lib/function";
import { SpacingColumn } from "../../BuildingBlocks/SpacingColumn";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { Padding } from "../../BuildingBlocks/Padding";
import { Border } from "../../BuildingBlocks/Border";
import { CRMColors } from "../../../models/CRMColors";

type TAddCaseEnquiryPopupProps = {
    isOpen: boolean;
    enquiryForm: TCaseEnquiry1Form;
    documentOptions: Array<IOption<string>>;
    onChangeDocuments: (documents: Array<CaseEnquiryDocument1.T>) => void;
    onChangeMessage: (message: string) => void;
    onChangeReferenceId: (id: string) => void;
    onClose: () => void;
    onSubmit: () => void;
};

export const CRMAddCaseEnquiryPopup = (props: TAddCaseEnquiryPopupProps): JSX.Element => {

    const getDocumentNameForId = (id: string): string =>
         pipe(
            props.documentOptions,
            array.findFirst((documentOption) => documentOption.value === id),
            option.map((documentOption) => `${documentOption.label}`),
            option.getOrElse(() => ""),
        );

    return (
        <CRMCardOutsidePopupBlank
            isOpen={props.isOpen}
            onClose={props.onClose}
        >
            <CRMPadding size="large">
                <SpacingColumn spacing={CRMSpacing.LARGE}>

                    {/* TITLE */}
                    <CRMTitleForCard>
                        Add Enquiry
                    </CRMTitleForCard>

                    <SpacingColumn spacing={CRMSpacing.MEDIUM}>
                        {/* REFERENCE ID */}
                        <CRMInputLabelAndErrorWrapComponent
                            label="Enquiry reference id"
                        >
                            <CRMInputGeneralComponent
                                inputType="numericString"
                                placeholder="eg: E1"
                                value={props.enquiryForm.edit.reference_id}
                                onChange={props.onChangeReferenceId}
                                displayError={JsonError1.doTargetKeysHaveInnerErrors([`reference_id`], props.enquiryForm.validationErrors)}
                            />
                        </CRMInputLabelAndErrorWrapComponent>

                        {/* CHOSE DOCUMENTS DROPDOWN */}
                        <CRMInputLabelAndErrorWrapComponent
                            label="For document"
                        >
                            <CRMMultiSelectDropdownComponent
                                placeholder="No documents selected"
                                value={ pipe(
                                    props.enquiryForm.edit.documents,
                                    array.map((document) => document.id),
                                )}
                                options={props.documentOptions}
                                displayError={JsonError1.doTargetKeysHaveInnerErrors(["documents"], props.enquiryForm.validationErrors)}
                                onChange={(v) => props.onChangeDocuments(
                                    v.map((id) =>  pipe(
                                        props.enquiryForm.edit.documents,
                                        array.findFirst((item) => item.id === id),
                                        option.fold(
                                            () => ({id, reference: ""}),
                                            (item) => item,
                                        )
                                    ))
                                )}
                            />
                        </CRMInputLabelAndErrorWrapComponent>
                    
                        {/* REFERENCES */}
                        {props.enquiryForm.edit.documents.length > 0 &&
                            <Border
                                type="bottom"
                                definition={`1px solid ${CRMColors.NEUTRAL_INK}`}
                            >
                                <Padding
                                    type="custom"
                                    spacing={`0 0 ${CRMSpacing.LARGE} ${CRMSpacing.MEDIUM}`}
                                >
                                    <SpacingColumn spacing={CRMSpacing.MEDIUM}>
                                        {props.enquiryForm.edit.documents.map((document, index) =>
                                            <CRMInputLabelAndErrorWrapComponent 
                                                key={index}
                                                label={`Which section of: ${getDocumentNameForId(document.id)}`}
                                            >
                                                <CRMInputGeneralComponent
                                                    inputType="text"
                                                    value={document.reference}
                                                    onChange={(value) => props.onChangeDocuments(props.enquiryForm.edit.documents.map((doc) => {
                                                        doc = {...doc};
                                                        if (doc.id === document.id) {
                                                            doc.reference = value;
                                                        }
                                                        return doc;
                                                    }))}
                                                    displayError={JsonError1.doTargetKeysHaveInnerErrors([`documents.${index}.reference`], props.enquiryForm.validationErrors)}
                                                />
                                            </CRMInputLabelAndErrorWrapComponent>
                                        )}
                                    </SpacingColumn>
                                </Padding>
                            </Border>
                        }
                    </SpacingColumn>
                                            
                    {/* SET MESSAGE */}
                    <CRMInputLabelAndErrorWrapComponent
                        label="Enquiry"
                    >
                        <CRMTextAreaComponent
                            value={props.enquiryForm.edit.message}
                            onChange={props.onChangeMessage}
                            displayError={JsonError1.doTargetKeysHaveInnerErrors(["message"], props.enquiryForm.validationErrors)}
                        />
                    </CRMInputLabelAndErrorWrapComponent>

                    {/* CONTROLS */}
                    <CRMSpacingRow justifyContent="end">
                        <CRMButtonSecondary
                            label="Close"
                            onClick={props.onClose}
                        />
                        <CRMFormButton
                            formStatus={props.enquiryForm.status}
                            ButtonElement={CRMButtonQuaternary}
                            label="Submit"
                            onClick={props.onSubmit}
                        />
                    </CRMSpacingRow>

                    
                </SpacingColumn>
            </CRMPadding>
        </CRMCardOutsidePopupBlank>
    );
};
