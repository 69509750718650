import React from "react";
import { FontRobotoSlab } from "../../../BuildingBlocks/FontRobotoSlab";
import { Text } from "../../../BuildingBlocks/Text";
import { FrontColors } from "../../../../models/FrontColors";
import { THexColor } from "../../../../models/StringLiterals";

type TLandingTitleProps = {
    color?: THexColor
    size?: number;
    align?: "left" | "center" | "right";
};

export const FPLandingTitle = (props: React.PropsWithChildren<TLandingTitleProps>): JSX.Element => {
    return (
        <FontRobotoSlab>
            <Text
                width="100%"
                size={props.size || 40}
                weight={500}
                letterSpacing="1px"
                lineHeight={1.4}
                align={props.align || "left"}
                color={props.color || FrontColors.PROBATE_0}
            >
                {props.children}
            </Text>
        </FontRobotoSlab>
    );
};
