import * as rxjs from "rxjs";
import * as rxjsOperators from "rxjs/operators";
import { option, nonEmptyArray, array } from "fp-ts/lib";
import { pipe } from "fp-ts/lib/function";
import { array as extArray } from "../../../../../shared/src/utilByDomainGroupExport";
import * as fetchWrapper from "../../../wrappers/fetch";
import * as util from "../../../util";
import * as actionable from "./../../../functions/actionable";
import * as TForm from "../../../models/TForm";
import * as TFormStatus from "../../../models/TFormStatus";
import {
    TUser15AndC8FormList,
    TListing3ReadOnlyForm,
    TListingEnquiriesOffer2ReadOnlyFormList,
    TParty2AndC7Form,
    TEnquiryC2ReadOnlyFormList,
    TParty2ReadOnlyFormList,
    TListingEnquiriesViewing2ReadOnlyFormList,
    TListingEnquiriesViewing2AndListingEnquiriesViewingForm1FormList,
    TListingEnquiriesOffer2AndC1Form,
    TListing3AndListingForm4FormList,
    TEnquirySendFiles1Form,
    TUserPhoneNumber2ReadOnlyForm,
    TUserPhoneNumber5AndC4FormList,
} from "../../../models/TFormModels";
import { TActionObservable, TCodecSetState, TUnpackFormActionPayload} from "../../applyActions";
import * as TCRMEnquiry from "../../TCRMEnquiry";
import { DateTime } from "luxon";
import { makeFilterUrl } from "./../../../functions/makeFilterUrl";
import * as EnquirySuccessResponse1 from "../../../../../domain/models/EnquirySuccessResponse1";
import * as FirstPartyFetchResponse from "../../../../../domain/models/FirstPartyFetchResponse";
import * as ListingEnquiriesViewingSuccessResponse3 from "../../../../../domain/models/ListingEnquiriesViewingSuccessResponse3";
import * as OfferSuccessResponse2 from "../../../../../domain/models/OfferSuccessResponse2";
import * as PartyResponse1 from "../../../../../domain/models/PartyResponse1";
import * as ListingSuccessResponse1 from "../../../../../domain/models/ListingSuccessResponse1";
import * as ListingSuccessResponse2 from "../../../../../domain/models/ListingSuccessResponse2";
import * as EnquirySuccessResponse2 from "../../../../../domain/models/EnquirySuccessResponse2";
import * as PartiesSuccessResponse1 from "../../../../../domain/models/PartiesSuccessResponse1";
import * as PartyPhoneNumbersSuccessResponse1 from "../../../../../domain/models/PartyPhoneNumbersSuccessResponse1";
import * as Party7 from "../../../../../domain/models/Party7";
import * as User4 from "../../../../../domain/models/User4";
import * as UserSuccessResponse2 from "../../../../../domain/models/UserSuccessResponse2";
import * as PartiesFilter1 from "../../../../../domain/models/PartiesFilter1";
import * as EnquiryFilter1 from "../../../../../domain/models/EnquiryFilter1";
import * as Enquiry2 from "../../../../../domain/models/Enquiry2";
import * as DeleteEntitySuccessResponse from "../../../../../domain/models/DeleteEntitySuccessResponse";
import * as ListingEnquiriesViewing2 from "../../../../../domain/models/ListingEnquiriesViewing2";
import * as ListingEnquiriesViewing3 from "../../../../../domain/models/ListingEnquiriesViewing3";
import * as Party2 from "../../../../../domain/models/Party2";
import * as Listing4 from "../../../../../domain/models/Listing4";
import * as Listing3 from "../../../../../domain/models/Listing3";
import * as User8 from "../../../../../domain/models/User8";
import * as UserPhoneNumber4 from "../../../../../domain/models/UserPhoneNumber4";
import * as UserPhoneNumber5 from "../../../../../domain/models/UserPhoneNumber5";
import * as ListingEnquiriesOffer2 from "../../../../../domain/models/ListingEnquiriesOffer2";
import * as ListingEnquiriesOffer1 from "../../../../../domain/models/ListingEnquiriesOffer1";
import * as ListingForm4 from "../../../../../domain/models/ListingForm4";
import * as ListingFormGetAddressIOSubset1 from "../../../../../domain/models/ListingFormGetAddressIOSubset1";
import * as JsonInnerError1 from "../../../../../domain/models/JsonInnerError1";
import * as GetAddressIOResponse from "../../../../../domain/models/GetAddressIOResponse";
import * as Country1 from "../../../../../domain/models/Country1";
import * as EnquirySendFiles1 from "../../../../../domain/models/EnquirySendFiles1";
import * as ViewingFilter1 from "../../../../../domain/models/ViewingFilter1";
import * as ListingFilter1 from "../../../../../domain/models/ListingFilter1";
import * as OfferFilter1 from "../../../../../domain/models/OfferFilter1";
import * as ListingForm5 from "../../../../../domain/models/ListingForm5";
import { TGetState } from "../../TGetState";
import { TSetState } from "../../TSetState";
import { TDispatch } from "../../TDispatch";
import { formOperation } from "../../../wrappers/formOperation";
import { TStateLens } from "../../../../../domain/codecs/state";
import { TActionPayload, TActionsDefinitionsList } from "../TAction";
import { reduceDataToStateUpdate } from "../../../functions/lens/reduceDataToStateUpdate";
import { set } from "../../../functions/lens/set";
import { pipe as lensPipe, pipeSubscribed } from "../../../functions/lens/pipe";
import { submitForm } from "../../../functions/lens/submitForm";
import { setFormWhere } from "../../../functions/lens/setFormWhere";
import { iif } from "../../../functions/lens/iif";
import { submitForFormList } from "../../../functions/lens/submitForFormList";
import { setValue } from "../../../functions/lens/setValue";
import { submitFormsWhereChanged } from "../../../functions/lens/submitFormsWhereChanged";
import { catchEitherSubscribed } from "../../../functions/lens/catchEitherSubscribed";
import { submitForForm } from "../../../functions/lens/submitForForm";
import { toActionable } from "../../../functions/lens/toActionable";
import { submitFormWhere } from "../../../functions/lens/submitFormWhere";
import { submitFormWhereChanged } from "../../../functions/lens/submitFormWhereChanged";
import { setForm } from "../../../functions/lens/setForm";
import { setFormSubscribed } from "../../../functions/lens/setFormSubscribed";
import { setWhere } from "../../../functions/lens/setWhere";
import { findFirstWhere } from "../../../functions/lens/findFirstWhere";
import { TValidationError } from "../../../../../shared/src/validation/Error";
import { TChangeRouteAction } from "../../router/routerTypes";

type TEnquiryRequestPayloads = [
    string,
    FirstPartyFetchResponse.T<EnquirySuccessResponse1.T>,
    FirstPartyFetchResponse.T<ListingEnquiriesViewingSuccessResponse3.T>,
    FirstPartyFetchResponse.T<PartyResponse1.T>,
    FirstPartyFetchResponse.T<ListingSuccessResponse1.T>,
    FirstPartyFetchResponse.T<OfferSuccessResponse2.T>,
    FirstPartyFetchResponse.T<ListingEnquiriesViewingSuccessResponse3.T>,
    FirstPartyFetchResponse.T<ListingSuccessResponse2.T>,
    FirstPartyFetchResponse.T<EnquirySuccessResponse2.T>,
    FirstPartyFetchResponse.T<PartiesSuccessResponse1.T>,
    FirstPartyFetchResponse.T<PartyPhoneNumbersSuccessResponse1.T>,
];

export const actions: TActionsDefinitionsList = [
    {
        type: "VIEW_CRM_ENQUIRY",
        run: (
            obs$: rxjs.Observable<TChangeRouteAction<"VIEW_CRM_ENQUIRY">>,
            getState: TGetState,
            setState: TSetState,
        ): void => {
            obs$.pipe(
                rxjsOperators.mergeMap((action) =>
                     pipe(
                        actionable.of<TEnquiryRequestPayloads>(
                            setState,
                            getState,
                            [
                                action.params.enquiryId,
                                FirstPartyFetchResponse.create2XX(EnquirySuccessResponse1.newDefault()),
                                FirstPartyFetchResponse.create2XX(ListingEnquiriesViewingSuccessResponse3.newDefault()),
                                FirstPartyFetchResponse.create2XX(PartyResponse1.newDefault()),
                                FirstPartyFetchResponse.create2XX(ListingSuccessResponse1.newDefault()),
                                FirstPartyFetchResponse.create2XX(OfferSuccessResponse2.newDefault()),
                                FirstPartyFetchResponse.create2XX(ListingEnquiriesViewingSuccessResponse3.newDefault()),
                                FirstPartyFetchResponse.create2XX(ListingSuccessResponse2.newDefault()),
                                FirstPartyFetchResponse.create2XX(EnquirySuccessResponse2.newDefault()),
                                FirstPartyFetchResponse.create2XX(PartiesSuccessResponse1.newDefault()),
                                FirstPartyFetchResponse.create2XX(PartyPhoneNumbersSuccessResponse1.newDefault()),
                            ]
                        ),
                        actionable.fromPayloadIndexForkJoin<0, 1 | 2, TEnquiryRequestPayloads>(0, [
                            [1, makeGetEnquiryRequest],
                            [2, makeGetViewingsRequest],
                        ]),
                        actionable.fromPayloadIndexForkJoin<1, 3 | 4 | 5 | 6 | 7, TEnquiryRequestPayloads>(1, [
                            [3, (enquiry) => makeGetPartyRequest(enquiry.response.data.party_id)],
                            [4, (enquiry) => makeGetListingRequest(enquiry.response.data.listing_id)],
                            [5, (enquiry) => makeGetHighestOffersRequest(enquiry.response.data.listing_id)],
                            [6, (enquiry) => makeGetOtherViewingsForListingRequest(enquiry.response.data.listing_id)],
                            [7, (enquiry) => makeGetPartiesListingsRequest(enquiry.response.data.party_id)],
                        ]),
                        actionable.fromPayloadIndex<5, 8, TEnquiryRequestPayloads>(5, 8, makeGetEnquiriesRelatedToOffersRequest),
                        actionable.fromPayloadIndex<8, 9, TEnquiryRequestPayloads>(8, 9, makeGetPartiesRelatedToOffersRequest),
                        actionable.fromPayloadIndex<3, 10, TEnquiryRequestPayloads>(3, 10, (req) => makeGetPartyPhoneNumbersRequest(req.response.data.id)),
                        actionable.tapPayloads<TEnquiryRequestPayloads>(
                            reduceDataToStateUpdate<TEnquiryRequestPayloads>(setState)(
                                // Reset the enquiry state so there is nothing held from a previous enquiry
                                set<TEnquiryRequestPayloads>()(
                                    ["activeData", "crm", "enquiry"],
                                    () => new TCRMEnquiry.C,
                                ),
                                set<TEnquiryRequestPayloads>()(
                                    ["activeData", "crm", "enquiry", "enquiryForm"],
                                    (form, data) => TForm.dataToForm<TEnquiryC2ReadOnlyFormList["forms"][number]>({}, {})(data[1].response.data),
                                ),
                                set<TEnquiryRequestPayloads>()(
                                    ["activeData", "crm", "enquiry", "viewingForms"],
                                    (formList, data) => TForm.requestToFormList<TListingEnquiriesViewing2AndListingEnquiriesViewingForm1FormList["forms"][number]>(formList, data[2]),
                                ),
                                set<TEnquiryRequestPayloads>()(
                                    ["activeData", "crm", "enquiry", "partyForm"],
                                    (form, data) => TForm.dataToForm<TParty2AndC7Form>(Party7.newDefault(), {})(data[3].response.data),
                                ),
                                set<TEnquiryRequestPayloads>()(
                                    ["activeData", "crm", "enquiry", "peopleFormList"],
                                    (formList, data) => ({
                                        ...formList,
                                        status: TFormStatus.constants.UNTOUCHED,
                                        forms: data[3].response.data.users.map(TForm.dataToForm<TUser15AndC8FormList["forms"][number]>(formList.defaultEdit, {})),
                                    })
                                ),
                                set<TEnquiryRequestPayloads>()(
                                    ["activeData", "crm", "enquiry", "peoplePhoneNumbersList"],
                                    (formList, data) => TForm.requestToFormList<TUserPhoneNumber5AndC4FormList["forms"][number]>(formList, data[10])
                                ),
                            ),
                        ),
                        actionable.tapPayloads<TEnquiryRequestPayloads>(
                            reduceDataToStateUpdate<TEnquiryRequestPayloads>(setState)(
                                set<TEnquiryRequestPayloads>()(
                                    ["activeData", "crm", "enquiry", "listingForm"],
                                    (form, data) => TForm.dataToForm<TListing3ReadOnlyForm>({}, {})(data[4].response.data),
                                ),
                                set<TEnquiryRequestPayloads>()(
                                    ["activeData", "crm", "enquiry", "highestOffersForms"],
                                    (formList, data) => TForm.requestToFormList<TListingEnquiriesOffer2ReadOnlyFormList["forms"][number]>(formList, data[5]),
                                ),
                                set<TEnquiryRequestPayloads>()(
                                    ["activeData", "crm", "enquiry", "otherViewingsForListing"],
                                    (formList, data) => TForm.requestToFormList<TListingEnquiriesViewing2ReadOnlyFormList["forms"][number]>(formList, data[6]),
                                ),
                                set<TEnquiryRequestPayloads>()(
                                    ["activeData", "crm", "enquiry", "partiesListings"],
                                    (formList, data) =>  pipe(
                                        TForm.requestToFormList<TListing3AndListingForm4FormList["forms"][number]>(formList, data[7]),
                                        openCollapsibleSectionOfLastFormInList,
                                    ),
                                ),
                                set<TEnquiryRequestPayloads>()(
                                    ["activeData", "crm", "enquiry", "enquiriesRelatedToOffersForms"],
                                    (formList, data) => TForm.requestToFormList<TEnquiryC2ReadOnlyFormList["forms"][number]>(formList, data[8]),
                                ),
                                set<TEnquiryRequestPayloads>()(
                                    ["activeData", "crm", "enquiry", "partiesRelatedToOffersForms"],
                                    (formList, data) => TForm.requestToFormList<TParty2ReadOnlyFormList["forms"][number]>(formList, data[9]),
                                ),
                                set<TEnquiryRequestPayloads>()(
                                    ["activeData", "crm", "enquiry", "createListingForm"],
                                    (form, data) => ({
                                        ...form,
                                        edit: {
                                            sellers_party_id: data[1].response.data.party_id,
                                        },
                                    }),
                                ),
                            ),
                        ),
                    )
                ),
            ).subscribe();
        },
    },
    {
        type: "CRM_ENQUIRY_NEW_PERSON_SUBMIT" as const,
        run: (
            obs$: rxjs.Observable<TActionPayload<"CRM_ENQUIRY_NEW_PERSON_SUBMIT", undefined>>,
            getState: TGetState,
            setState: TSetState,
        ) =>
            submitForm<"CRM_ENQUIRY_NEW_PERSON_SUBMIT", undefined, TCRMEnquiry.C["createPersonForm"]["view"], TCRMEnquiry.C["createPersonForm"]["edit"], TCRMEnquiry.C["createPersonForm"]["ui"]>()(
                ["activeData", "crm", "enquiry", "createPersonForm"],
                () => `/v1/parties/${getState().activeData.crm.enquiry.partyForm.view.id}/users`,
                "POST",
                () => option.none,
                User4,
                () => reloadOnlyPartyUsers(
                    getState().activeData.crm.enquiry.partyForm.view.id,
                    getState,
                    setState
                ).subscribe()
            )(obs$, getState, setState).subscribe(),
    },
    {
        type: "CRM_ENQUIRY_DISASSOCIATE_PERSON_SUBMIT" as const,
        run: (
            obs$: rxjs.Observable<TActionPayload<"CRM_ENQUIRY_DISASSOCIATE_PERSON_SUBMIT", string>>,
            getState: TGetState,
            setState: TSetState,
        ) =>
            obs$
                .pipe(
                    rxjsOperators.mergeMap((action) =>
                        rxjs
                            .from(
                                fetchWrapper.json<UserSuccessResponse2.T>({
                                    requestParams: {
                                        url: `${env.REACT_APP_API_URL}/v1/parties/${getState().activeData.crm.enquiry.partyForm.view.id}/users/disassociate`,
                                        method: "PUT",
                                        body: option.some(JSON.stringify({ user_id: action.payload })),
                                    },
                                    expectedTypeCodec: UserSuccessResponse2.codec,
                                    defaultResponse: UserSuccessResponse2.newDefault(),
                                })(),
                            )
                            .pipe(
                                rxjsOperators.mergeMap(() =>
                                    reloadOnlyPartyUsers(
                                        getState().activeData.crm.enquiry.partyForm.view.id,
                                        getState,
                                        setState
                                    )
                                )
                            )
                    ),
                )
                .subscribe(),
    },
    {
        type: "CRM_ENQUIRY_PERSON_INPUT_CHANGED" as const,
        run: (
            obs$: rxjs.Observable<TActionPayload<"CRM_ENQUIRY_PERSON_INPUT_CHANGED", TUnpackFormActionPayload<TUser15AndC8FormList["forms"][number]>>>,
            getState: TGetState,
            setState: TSetState,
        ) =>
            obs$.pipe(
                rxjsOperators.mergeMap((obs) =>
                    setFormWhere<"CRM_ENQUIRY_PERSON_INPUT_CHANGED", TUser15AndC8FormList["forms"][number]>()(
                        ["activeData", "crm", "enquiry", "peopleFormList", "forms"],
                        (value, payload) => value.view.id === payload.resourceId,
                    )(rxjs.of(obs), getState, setState)
                        .pipe(
                            rxjsOperators.mapTo(obs),
                        ),
                ),
                rxjsOperators.debounceTime(350),
                rxjsOperators.switchMap((obs) =>
                    lensPipe(
                        iif<"CRM_ENQUIRY_PERSON_INPUT_CHANGED", TUnpackFormActionPayload<TUser15AndC8FormList["forms"][number]>>(
                            (action) => typeof action.payload.value === "string" && action.payload.value.length > 1,
                            submitForFormList<"CRM_ENQUIRY_PERSON_INPUT_CHANGED", TUnpackFormActionPayload<TUser15AndC8FormList["forms"][number]>, PartiesSuccessResponse1.T>()(
                                ["activeData", "crm", "enquiry", "matchingPartiesFormList"],
                                (payload) => `/v1/parties?filters=${encodeURIComponent(JSON.stringify(PartiesFilter1.createForGenericSearch(typeof payload.value === "string" ? payload.value : "")))}`,
                                () => option.none,
                                "GET",
                                PartiesSuccessResponse1,
                            )
                        ),
                        iif<"CRM_ENQUIRY_PERSON_INPUT_CHANGED", TUnpackFormActionPayload<TUser15AndC8FormList["forms"][number]>>(
                            (action) => typeof action.payload.value !== "string" || action.payload.value.length === 0,
                            setValue<"CRM_ENQUIRY_PERSON_INPUT_CHANGED", TUnpackFormActionPayload<TUser15AndC8FormList["forms"][number]>>()(
                                ["activeData", "crm", "enquiry", "matchingPartiesFormList"],
                                (formList) => {
                                    formList.forms = [];
                                    return formList;
                                }
                            )
                        ),
                    )(rxjs.of(obs), getState, setState),
                ),
                rxjsOperators.debounceTime(1000),
                rxjsOperators.mergeMap((obs) =>
                    submitFormsWhereChanged()(
                        ["activeData", "crm", "enquiry", "peopleFormList", "forms"],
                        (form) => `/v1/users/${form.view.id}`,
                        "PATCH",
                        User8.validator,
                        User4,
                    )(rxjs.of(obs as TActionPayload<"CRM_ENQUIRY_PERSON_INPUT_CHANGED", TUnpackFormActionPayload<TUser15AndC8FormList["forms"][number]>>), getState, setState),
                ),
            ).subscribe(),
    },
    {
        type: "CRM_ENQUIRY_ASSOCIATE_PARTY" as const,
        run: catchEitherSubscribed<"CRM_ENQUIRY_ASSOCIATE_PARTY", string>(
            (action, request) => request.tag !== FirstPartyFetchResponse.constants.STATUS_422,
            submitForForm<"CRM_ENQUIRY_ASSOCIATE_PARTY", string, Enquiry2.T>()(
                ["activeData", "crm", "enquiry", "enquiryForm"],
                (form) => `/v1/enquiries/${form.view.id}`,
                (form, partyId) => ({ party_id: partyId }),
                "PATCH",
                Enquiry2,
            ),
            lensPipe<"CRM_ENQUIRY_ASSOCIATE_PARTY", string>(
                submitForFormList<"CRM_ENQUIRY_ASSOCIATE_PARTY", string, EnquirySuccessResponse2.T>()(
                    ["activeData", "crm", "enquiry", "enquiriesRelatedToSearchedPartyFormList"],
                    (partyId, getState) => makeFilterUrl(
                        'enquiries',
                        EnquiryFilter1.createForListingAndPartyId(
                            getState().activeData.crm.enquiry.listingForm.view.id,
                            partyId
                        )
                    ),
                    () => option.none,
                    "GET",
                    EnquirySuccessResponse2,
                )
            ),
            lensPipe<"CRM_ENQUIRY_ASSOCIATE_PARTY", string>(
                toActionable<"CRM_ENQUIRY_ASSOCIATE_PARTY", string, string>()(
                    (newPartyId) => newPartyId,
                    (partyId, getState, setState) => reloadParty(partyId, getState, setState),
                ),
                toActionable<"CRM_ENQUIRY_ASSOCIATE_PARTY", string, string>()(
                    (newPartyId) => newPartyId,
                    (partyId, getState, setState) => reloadPartyPhoneNumbers(partyId, getState, setState),
                ),
                setValue<"CRM_ENQUIRY_ASSOCIATE_PARTY", string>()(
                    ["activeData", "crm", "enquiry", "matchingPartiesFormList"],
                    (formList) => {
                        formList.forms = [];
                        return formList;
                    }
                )
            )
        ),
    },
    {
        type: "CRM_CLOSE_MATCHING_ENQUIRY_POPUP" as const,
        run: pipeSubscribed<"CRM_CLOSE_MATCHING_ENQUIRY_POPUP", null>(
            setValue<"CRM_CLOSE_MATCHING_ENQUIRY_POPUP", null>()(
                ["activeData", "crm", "enquiry", "enquiriesRelatedToSearchedPartyFormList"],
                (formList) => {
                    formList.forms = [];
                    return formList;
                }
            ),
            setValue<"CRM_CLOSE_MATCHING_ENQUIRY_POPUP", null>()(
                ["activeData", "crm", "enquiry", "matchingPartiesFormList"],
                (formList) => {
                    formList.forms = [];
                    return formList;
                }
            ),
        ),
    },
    {
        type: "CRM_ENQUIRY_ASSOCIATE_USER" as const,
        run: pipeSubscribed<"CRM_ENQUIRY_ASSOCIATE_USER", string>(
            submitForForm<"CRM_ENQUIRY_ASSOCIATE_USER", string, User4.T>()(
                ["activeData", "crm", "enquiry", "associatePersonForm"],
                (form, payload, getState) => `/v1/parties/${getState().activeData.crm.enquiry.partyForm.view.id}/users/associate`,
                (form, userId) => ({ user_id: userId }),
                "PUT",
                User4
            ),
            toActionable<"CRM_ENQUIRY_ASSOCIATE_USER", string, string>()(
                (userId, getState) => getState().activeData.crm.enquiry.partyForm.view.id,
                (partyId, getState, setState) => reloadOnlyPartyUsers(partyId, getState, setState),
            ),
            toActionable<"CRM_ENQUIRY_ASSOCIATE_USER", string, string>()(
                (userId, getState) => getState().activeData.crm.enquiry.partyForm.view.id,
                (partyId, getState, setState) => reloadPartyPhoneNumbers(partyId, getState, setState),
            ),
            setValue<"CRM_ENQUIRY_ASSOCIATE_USER", string>()(
                ["activeData", "crm", "enquiry", "matchingPartiesFormList"],
                (formList) => {
                    formList.forms = [];
                    return formList;
                }
            )
        ),
    },
    {
        type: "CRM_NEW_PHONE_NUMBER_SUBMIT" as const,
        run: pipeSubscribed<"CRM_NEW_PHONE_NUMBER_SUBMIT", string>(
            submitForm<"CRM_NEW_PHONE_NUMBER_SUBMIT", string, TUserPhoneNumber2ReadOnlyForm["view"], TUserPhoneNumber2ReadOnlyForm["edit"], TUserPhoneNumber2ReadOnlyForm["ui"]>()(
                ["activeData", "crm", "enquiry", "createPhoneNumberForm"],
                (form, userId) => `/v1/users/${userId}/phone-numbers`,
                "POST",
                () => option.none,
                User4,
            ),
            toActionable<"CRM_NEW_PHONE_NUMBER_SUBMIT", string, string>()(
                (payload, getState) => getState().activeData.crm.enquiry.partyForm.view.id,
                (partyId, getState, setState) => reloadPartyPhoneNumbers(partyId, getState, setState),
            )
        ),
    },
    {
        type: "CRM_DELETE_PHONE_NUMBER" as const,
        run: (
            obs$: rxjs.Observable<TActionPayload<"CRM_DELETE_PHONE_NUMBER", string>>,
            getState: TGetState,
            setState: TSetState,
        ) =>
            obs$
                .pipe(
                    rxjsOperators.mergeMap((action) =>
                        rxjs
                            .from(
                                fetchWrapper.json<DeleteEntitySuccessResponse.T>({
                                    requestParams: {
                                        url: `${env.REACT_APP_API_URL}/v1/phone-numbers/${action.payload}`,
                                        method: "DELETE",
                                        body: option.none,
                                    },
                                    expectedTypeCodec: DeleteEntitySuccessResponse.codec,
                                    defaultResponse: DeleteEntitySuccessResponse.newDefault(),
                                })(),
                            )
                            .pipe(
                                rxjsOperators.mergeMap(() =>
                                    reloadPartyPhoneNumbers(
                                        getState().activeData.crm.enquiry.partyForm.view.id,
                                        getState,
                                        setState
                                    )
                                )
                            )
                    ),
                )
                .subscribe(),
    },
    {
        type: "CRM_PHONE_NUMBER_INPUT_CHANGED" as const,
        run: (
            obs$: rxjs.Observable<TActionPayload<"CRM_PHONE_NUMBER_INPUT_CHANGED", TUnpackFormActionPayload<TUserPhoneNumber5AndC4FormList["forms"][number]>>>,
            getState: TGetState,
            setState: TSetState,
        ) => {
            obs$.pipe(
                rxjsOperators.mergeMap((obs) =>
                    setFormWhere<"CRM_PHONE_NUMBER_INPUT_CHANGED", TUserPhoneNumber5AndC4FormList["forms"][number]>()(
                        ["activeData", "crm", "enquiry", "peoplePhoneNumbersList", "forms"],
                        (value, payload) => value.view.id === payload.resourceId,
                    )(rxjs.of(obs), getState, setState)
                        .pipe(
                            rxjsOperators.mapTo(obs),
                        ),
                ),
                rxjsOperators.mergeMap((obs) =>
                    obs.payload.key === "primary_number"
                        ? setValue<"CRM_PHONE_NUMBER_INPUT_CHANGED", TUnpackFormActionPayload<TUserPhoneNumber5AndC4FormList["forms"][number]>>()(
                            ["activeData", "crm", "enquiry", "peoplePhoneNumbersList"],
                            (formList, payload) => {
                                formList.forms = formList.forms.map((form) => {
                                    form.edit.primary_number = form.view.id === payload.resourceId;
                                    return form;
                                });
                                return formList;
                            }
                        )(rxjs.of(obs), getState, setState)
                            .pipe(
                                rxjsOperators.mapTo(obs),
                            )
                        : rxjs.of(obs),
                ),
                rxjsOperators.debounceTime(1000),
                rxjsOperators.mergeMap((obs) =>
                    submitFormsWhereChanged()(
                        ["activeData", "crm", "enquiry", "peoplePhoneNumbersList", "forms"],
                        (form) => `/v1/phone-numbers/${form.view.id}`,
                        "PATCH",
                        UserPhoneNumber4.validator,
                        UserPhoneNumber5,
                    )(rxjs.of(obs), getState, setState),
                ),
            ).subscribe();
        }
    },
    {
        type: "CRM_ENQUIRY_NEW_VIEWING_SUBMIT" as const,
        run: (
            obs$: rxjs.Observable<TActionPayload<"CRM_ENQUIRY_NEW_VIEWING_SUBMIT", undefined>>,
            getState: TGetState,
            setState: TSetState,
        ) =>
            submitForm<"CRM_ENQUIRY_NEW_VIEWING_SUBMIT", undefined, TCRMEnquiry.C["createViewingForm"]["view"], TCRMEnquiry.C["createViewingForm"]["edit"], TCRMEnquiry.C["createViewingForm"]["ui"]>()(
                ["activeData", "crm", "enquiry", "createViewingForm"],
                () => `/v1/enquiries/${getState().routes.params.enquiryId}/viewings`,
                "POST",
                () => option.none,
                ListingEnquiriesViewing2,
                () => viewingsActionable(getState().routes.params.enquiryId, setState, getState).subscribe(),
            )(obs$, getState, setState).subscribe(),
    },
    {
        type: "CRM_ENQUIRY_VIEWING_INPUT_CHANGED" as const,
        run: (
            obs$: rxjs.Observable<TActionPayload<"CRM_ENQUIRY_VIEWING_INPUT_CHANGED", TUnpackFormActionPayload<TListingEnquiriesViewing2AndListingEnquiriesViewingForm1FormList["forms"][number]>>>,
            getState: TGetState,
            setState: TSetState,
        ) => {
            obs$.pipe(
                rxjsOperators.mergeMap((obs) =>
                    setFormWhere<"CRM_ENQUIRY_VIEWING_INPUT_CHANGED", TListingEnquiriesViewing2AndListingEnquiriesViewingForm1FormList["forms"][number]>()(
                        ["activeData", "crm", "enquiry", "viewingForms", "forms"],
                        (value, payload) => value.view.id === payload.resourceId
                    )(rxjs.of(obs), getState, setState)
                        .pipe(
                            rxjsOperators.mapTo(obs),
                        ),
                ),
                rxjsOperators.debounceTime(1000),
                rxjsOperators.mergeMap((obs) =>
                    submitFormWhere()(
                        ["activeData", "crm", "enquiry", "viewingForms", "forms"],
                        (form) => form.view.id === obs.payload.resourceId && form.edit.status !== "confirmed",
                        (form) => `/v1/viewings/${form.view.id}`,
                        "PATCH",
                        ListingEnquiriesViewing3.validator,
                        ListingEnquiriesViewing2,
                        () => otherViewingsForListingActionable(getState().activeData.crm.enquiry.enquiryForm.view.listing_id, setState, getState).subscribe(),
                    )(rxjs.of(obs), getState, setState),
                ),
            ).subscribe();
        },
    },
    {
        type: "CRM_ENQUIRY_VIEWING_SAVE_CONFIRMED_VIEWING" as const,
        run: (
            obs$: rxjs.Observable<TActionPayload<"CRM_ENQUIRY_VIEWING_SAVE_CONFIRMED_VIEWING", { resourceId: string }>>,
            getState: TGetState,
            setState: TSetState,
        ) => {
            obs$.pipe(
                rxjsOperators.mergeMap((obs) =>
                    submitFormWhere()(
                        ["activeData", "crm", "enquiry", "viewingForms", "forms"],
                        (form) => form.view.id === obs.payload.resourceId,
                        (form) => `/v1/viewings/${form.view.id}`,
                        "PATCH",
                        ListingEnquiriesViewing3.validator,
                        ListingEnquiriesViewing2,
                        () => otherViewingsForListingActionable(getState().activeData.crm.enquiry.enquiryForm.view.listing_id, setState, getState).subscribe(),
                    )(rxjs.of(obs), getState, setState),
                ),
            ).subscribe();
        },
    },
    {
        type: "CRM_ENQUIRY_SUBMIT" as const,
        run: (
            obs$: rxjs.Observable<TActionPayload<"CRM_ENQUIRY_SUBMIT", undefined>>,
            getState: TGetState,
            setState: TSetState,
        ) =>
            rxjs.forkJoin([
                submitFormsWhereChanged<"CRM_ENQUIRY_SUBMIT", undefined, TCRMEnquiry.C["viewingForms"]["forms"][number]["view"], TCRMEnquiry.C["viewingForms"]["forms"][number]["edit"], TCRMEnquiry.C["viewingForms"]["forms"][number]["ui"]>()(
                    ["activeData", "crm", "enquiry", "viewingForms", "forms"],
                    (form) => `/v1/viewings/${form.view.id}`,
                    "PATCH",
                    ListingEnquiriesViewing3.validator,
                    ListingEnquiriesViewing2,
                    () => otherViewingsForListingActionable(getState().activeData.crm.enquiry.enquiryForm.view.listing_id, setState, getState).subscribe(),
                )(obs$, getState, setState),
                submitFormWhereChanged<"CRM_ENQUIRY_SUBMIT", undefined, TCRMEnquiry.C["enquiryForm"]["view"], TCRMEnquiry.C["enquiryForm"]["edit"], TCRMEnquiry.C["enquiryForm"]["ui"]>()(
                    ["activeData", "crm", "enquiry", "enquiryForm"],
                    () => `/v1/enquiries/${getState().routes.params.enquiryId}`,
                    "PATCH",
                    () => option.none,
                    Enquiry2,
                )(obs$, getState, setState),
                submitFormWhereChanged<"CRM_ENQUIRY_SUBMIT", undefined, TCRMEnquiry.C["partyForm"]["view"], TCRMEnquiry.C["partyForm"]["edit"], TCRMEnquiry.C["partyForm"]["ui"]>()(
                    ["activeData", "crm", "enquiry", "partyForm"],
                    () => `/v1/parties/${getState().activeData.crm.enquiry.partyForm.view.id}`,
                    "PATCH",
                    () => option.none,
                    Party2,
                )(obs$, getState, setState),
                submitFormsWhereChanged<"CRM_ENQUIRY_SUBMIT", undefined, TCRMEnquiry.C["partiesListings"]["forms"][number]["view"], TCRMEnquiry.C["partiesListings"]["forms"][number]["edit"], TCRMEnquiry.C["partiesListings"]["forms"][number]["ui"]>()(
                    ["activeData", "crm", "enquiry", "partiesListings", "forms"],
                    (form) => `/v1/listings/${form.view.id}`,
                    "PATCH",
                    Listing4.validator,
                    Listing3,
                )(obs$, getState, setState),
                submitFormsWhereChanged<"CRM_ENQUIRY_SUBMIT", undefined, TCRMEnquiry.C["peopleFormList"]["forms"][number]["view"], TCRMEnquiry.C["peopleFormList"]["forms"][number]["edit"], TCRMEnquiry.C["peopleFormList"]["forms"][number]["ui"]>()(
                    ["activeData", "crm", "enquiry", "peopleFormList", "forms"],
                    (form) => `/v1/users/${form.view.id}`,
                    "PATCH",
                    User8.validator,
                    User4,
                )(obs$, getState, setState),
                submitFormsWhereChanged<"CRM_ENQUIRY_SUBMIT", undefined, TUserPhoneNumber5AndC4FormList["forms"][number]["view"], TUserPhoneNumber5AndC4FormList["forms"][number]["edit"], TUserPhoneNumber5AndC4FormList["forms"][number]["ui"]>()(
                    ["activeData", "crm", "enquiry", "peoplePhoneNumbersList", "forms"],
                    (form) => `/v1/phone-numbers/${form.view.id}`,
                    "PATCH",
                    UserPhoneNumber4.validator,
                    UserPhoneNumber5,
                )(obs$, getState, setState),
            ]).subscribe(),
    },
    {
        type: "CRM_ENQUIRY_PARTY_INPUT_CHANGED" as const,
        run: (
            obs$: rxjs.Observable<TActionPayload<"CRM_ENQUIRY_PARTY_INPUT_CHANGED", TUnpackFormActionPayload<TParty2AndC7Form>>>,
            getState: TGetState,
            setState: TSetState,
        ) => {
            obs$.pipe(
                rxjsOperators.mergeMap((obs) =>
                    setForm<"CRM_ENQUIRY_PARTY_INPUT_CHANGED", TParty2AndC7Form>()(
                        ["activeData", "crm", "enquiry", "partyForm"],
                    )(rxjs.of(obs), getState, setState)
                        .pipe(
                            rxjsOperators.mapTo(obs),
                        ),
                ),
                rxjsOperators.debounceTime(1000),
                rxjsOperators.mergeMap((obs) =>
                    submitFormWhereChanged()(
                        ["activeData", "crm", "enquiry", "partyForm"],
                        () => `/v1/parties/${getState().activeData.crm.enquiry.partyForm.view.id}`,
                        "PATCH",
                        () => option.none,
                        Party2,
                    )(rxjs.of(obs), getState, setState),
                ),
            ).subscribe();
        }
    },
    {
        type: "CRM_ENQUIRY_NEW_OFFER_INPUT_CHANGED" as const,
        run: setFormSubscribed<"CRM_ENQUIRY_NEW_OFFER_INPUT_CHANGED", TListingEnquiriesOffer2AndC1Form>()(
            ["activeData", "crm", "enquiry", "createOfferForm"],
        ),
    },
    {
        type: "CRM_ENQUIRY_ADD_NEW_OFFER_CLICKED" as const,
        run: (
            obs$: rxjs.Observable<TActionPayload<"CRM_ENQUIRY_ADD_NEW_OFFER_CLICKED", undefined>>,
            getState: TGetState,
            setState: TSetState,
        ) =>
            obs$.pipe(
                rxjsOperators.tap(() =>
                    setState(({...s}) => {
                        s.activeData.crm.enquiry.createOfferFormVisible = true;
                        return s;
                    }),
                ),
            ).subscribe(),
    },
    {
        type: "CRM_ENQUIRY_NEW_OFFER_SUBMIT" as const,
        run: (
            obs$: rxjs.Observable<TActionPayload<"CRM_ENQUIRY_NEW_OFFER_SUBMIT", undefined>>,
            getState: TGetState,
            setState: TSetState,
            dispatch: TDispatch,
            stateLens: TStateLens,
            innerSet: TCodecSetState,
        ) =>
            submitFormWhereChanged<"CRM_ENQUIRY_NEW_OFFER_SUBMIT", undefined, TCRMEnquiry.C["createOfferForm"]["view"], TCRMEnquiry.C["createOfferForm"]["edit"], TCRMEnquiry.C["createOfferForm"]["ui"]>()(
                ["activeData", "crm", "enquiry", "createOfferForm"],
                () => `/v1/enquiries/${getState().routes.params.enquiryId}/offers`,
                "POST",
                ListingEnquiriesOffer1.validator,
                ListingEnquiriesOffer2,
                (_, response) => response.tag === FirstPartyFetchResponse.constants.STATUS_2XX
                    ? rxjs.forkJoin([
                        afterNewOfferActionable(
                            getState().routes.params.enquiryId,
                            getState().activeData.crm.enquiry.enquiryForm.view.listing_id,
                            setState,
                            getState
                        ),
                        rxjs.from(formOperation("GetListingEnquiry", stateLens.listing_enquiry_page.get()(getState().forms)))
                            .pipe(
                                rxjsOperators.tap((res) => innerSet(stateLens.listing_enquiry_page.set(res))),
                            ),
                    ]).subscribe()
                    : undefined,
            )(obs$, getState, setState).subscribe(),
    },
    {
        type: "CRM_ENQUIRY_ADD_PROPERTY_TO_SELL" as const,
        run: (
            obs$: rxjs.Observable<TActionPayload<"CRM_ENQUIRY_ADD_PROPERTY_TO_SELL", undefined>>,
            getState: TGetState,
            setState: TSetState,
        ) =>
            submitForm<"CRM_ENQUIRY_ADD_PROPERTY_TO_SELL", undefined, TCRMEnquiry.C["createListingForm"]["view"], TCRMEnquiry.C["createListingForm"]["edit"], TCRMEnquiry.C["createListingForm"]["ui"]>()(
                ["activeData", "crm", "enquiry", "createListingForm"],
                () => `/v1/listings`,
                "POST",
                () => option.none,
                Listing3,
                () => afterNewPropertyToSellActionable(getState().activeData.crm.enquiry.enquiryForm.view.party_id, setState, getState).subscribe(),
            )(obs$, getState, setState).subscribe(),
    },
    {
        type: "CRM_ENQUIRY_LISTING_INPUT_CHANGED" as const,
        run: (
            obs$: rxjs.Observable<TActionPayload<"CRM_ENQUIRY_LISTING_INPUT_CHANGED", TUnpackFormActionPayload<TListing3AndListingForm4FormList["forms"][number]>>>,
            getState: TGetState,
            setState: TSetState,
        ) => {
            obs$.pipe(
                rxjsOperators.mergeMap((obs) =>
                    setFormWhere<"CRM_ENQUIRY_LISTING_INPUT_CHANGED", TListing3AndListingForm4FormList["forms"][number]>()(
                        ["activeData", "crm", "enquiry", "partiesListings", "forms"],
                        (value, payload) => value.view.id === payload.resourceId
                    )(rxjs.of(obs), getState, setState)
                        .pipe(
                            rxjsOperators.mapTo(obs),
                        ),
                ),
                rxjsOperators.debounceTime(1000),
                rxjsOperators.mergeMap((obs) =>
                    submitFormsWhereChanged()(
                        ["activeData", "crm", "enquiry", "partiesListings", "forms"],
                        (form) => `/v1/listings/${form.view.id}`,
                        "PATCH",
                        Listing4.validator,
                        Listing3,
                    )(rxjs.of(obs), getState, setState),
                ),
            ).subscribe();
        },
    },
    {
        type: "CRM_ENQUIRY_LISTING_ADDRESS_CHANGED" as const,
        run: (
            obs$: TActionObservable<"CRM_ENQUIRY_LISTING_ADDRESS_CHANGED", {resourceId: string; value: ListingForm5.T}>,
            getState: TGetState,
            setState: TSetState,
        ) => {
            obs$.pipe(
                rxjsOperators.mergeMap((obs) =>
                setValue<"CRM_ENQUIRY_LISTING_ADDRESS_CHANGED", {resourceId: string; value: ListingForm5.T}>()(
                    ["activeData", "crm", "enquiry", "partiesListings"],
                    (formList, action) => {
                        formList.forms = extArray.updateWhere<TListing3AndListingForm4FormList["forms"][number]>(
                            (form) => form.view.id === action.resourceId,
                            (form) => {
                                form.status = "requiresSubmission";
                                form.edit.postcode = action.value.postcode;
                                form.edit.county = action.value.county;
                                form.edit.city_town = action.value.city_town;
                                form.edit.country = action.value.country;
                                form.edit.building_name = action.value.building_name;
                                form.edit.building_number = action.value.building_number;
                                form.edit.sub_building_name = action.value.sub_building_name;
                                form.edit.sub_building_number = action.value.sub_building_number;
                                form.edit.street_name = action.value.street_name;
                                form.edit.locality = action.value.locality;
                                form.edit.district = action.value.district;
                                return form;
                            }
                        )(formList.forms);
                        formList.status = "requiresSubmission";
                        return formList;
                    }
                )(rxjs.of(obs), getState, setState)
                        .pipe(
                            rxjsOperators.mapTo(obs),
                        ),
                ),
                rxjsOperators.debounceTime(1000),
                rxjsOperators.mergeMap((obs) =>
                    submitFormsWhereChanged()(
                        ["activeData", "crm", "enquiry", "partiesListings", "forms"],
                        (form) => `/v1/listings/${form.view.id}`,
                        "PATCH",
                        Listing4.validator,
                        Listing3,
                    )(rxjs.of(obs), getState, setState),
                ),
            ).subscribe();
        },
    },
    {
        type: "CRM_ENQUIRY_LISTING_COLLAPSIBLE_CLICKED" as const,
        run: (
            obs$: rxjs.Observable<TActionPayload<"CRM_ENQUIRY_LISTING_COLLAPSIBLE_CLICKED", {resourceId: string}>>,
            getState: TGetState,
            setState: TSetState,
        ) =>
            obs$.subscribe((payload) =>
                reduceDataToStateUpdate(setState)(
                    // Close the open collapsible section so that max 1 will be open
                    setWhere()(
                        ["activeData", "crm", "enquiry", "partiesListings", "forms"],
                        (form) => form.ui.collapsibleSectionIsOpen === true && form.view.id !== payload.payload.resourceId,
                        (form) => {
                            form.ui.collapsibleSectionIsOpen = false;
                            return form;
                        }
                    ),
                    // Open the desired collapsible section
                    setWhere()(
                        ["activeData", "crm", "enquiry", "partiesListings", "forms"],
                        (form) => form.view.id === payload.payload.resourceId,
                        (form) => {
                            form.ui.collapsibleSectionIsOpen = !form.ui.collapsibleSectionIsOpen;
                            return form;
                        }
                    ),
                )(payload)
            ),
    },
    {
        type: "CRM_ENQUIRY_LISTING_ADDRESS_SEARCH" as const,
        run: (
            obs$: rxjs.Observable<TActionPayload<"CRM_ENQUIRY_LISTING_ADDRESS_SEARCH", {resourceId: string}>>,
            getState: TGetState,
            setState: TSetState,
        ) =>
            obs$
                .pipe(
                    rxjsOperators.mergeMap((payload) =>
                        rxjs.of(
                            findFirstWhere(getState())(
                                ["activeData", "crm", "enquiry", "partiesListings", "forms"],
                                (form) => form.view.id === payload.payload.resourceId,
                            ),
                        )
                            .pipe(
                                rxjsOperators.map<option.Option<TListing3AndListingForm4FormList["forms"][number]>, TListing3AndListingForm4FormList["forms"][number]>(
                                    option.getOrElse(() => TForm.defaultTFormV2<
                                        TListing3AndListingForm4FormList["forms"][number]["view"],
                                        TListing3AndListingForm4FormList["forms"][number]["edit"],
                                        TListing3AndListingForm4FormList["forms"][number]["ui"]
                                    >(Listing3.newDefault(), ListingForm4.newDefault(), {
                                        collapsibleSectionIsOpen: false,
                                    }))
                                ),
                                rxjsOperators.map<TListing3AndListingForm4FormList["forms"][number], [TListing3AndListingForm4FormList["forms"][number], option.Option<TValidationError>]>((form) => [
                                    form,
                                    ListingFormGetAddressIOSubset1.validator(form.edit),
                                ]),
                                rxjsOperators.tap(([, validationOption]) =>
                                    reduceDataToStateUpdate(setState)(
                                        setWhere()(
                                            ["activeData", "crm", "enquiry", "partiesListings", "forms"],
                                            (form) => form.view.id === payload.payload.resourceId,
                                            (form) => {
                                                form.validationErrors = option.isSome(validationOption)
                                                    ? JsonInnerError1.arrayFromValidationErrors("Body", validationOption.value)
                                                    : [];
                                                return form;
                                            }
                                        ),
                                    )(payload)
                                ),
                                rxjsOperators.filter(([, validationOption]) => option.isNone(validationOption)),
                                rxjsOperators.mergeMap(([form]) => {
                                    const listingFormGetAddressIOSubset =  pipe(
                                        {
                                            ...form.view,
                                            ...form.edit,
                                        },
                                        ListingFormGetAddressIOSubset1.fromListing3,
                                        ListingFormGetAddressIOSubset1.toRequestProperties,
                                    );
                                    return rxjs.from(
                                        fetchWrapper.thirdPartyJSON<GetAddressIOResponse.T>({
                                            requestParams: {
                                                url: `https://api.getAddress.io/find/${listingFormGetAddressIOSubset.postcode}/${listingFormGetAddressIOSubset.building_number_or_name}?api-key=${env.REACT_APP_GET_ADDRESS_API_KEY}&expand=true`,
                                                method: "GET",
                                                body: option.none,
                                            },
                                            expectedTypeCodec: GetAddressIOResponse.codec,
                                            defaultResponse: GetAddressIOResponse.newDefault(),
                                        })(),
                                    );
                                }),
                                rxjsOperators.tap((response) =>
                                    setState(({...s}) => {
                                        s = setWhere()(
                                            ["activeData", "crm", "enquiry", "partiesListings", "forms"],
                                            (form) => form.view.id === payload.payload.resourceId,
                                            (form) => {
                                                if (response.addresses.length > 0) {
                                                    const firstAddress = response.addresses[0];
                                                    form.edit = {
                                                        ...form.edit,
                                                        country: Country1.fromGetAddressIOApiCountryValueToCountryValue(firstAddress.country),
                                                        county: firstAddress.county,
                                                        district: firstAddress.district,
                                                        locality: firstAddress.locality,
                                                        city_town: firstAddress.town_or_city,
                                                        street_name: firstAddress.thoroughfare,
                                                        sub_building_name: firstAddress.sub_building_name,
                                                        sub_building_number: firstAddress.sub_building_number,
                                                    };
                                                }
                                                return form;
                                            },
                                        )({
                                            data: response,
                                            state: s,
                                            meta: {},
                                        }).state;
                                        return s;
                                    })
                                ),
                                rxjsOperators.mergeMap(() =>
                                    submitFormWhere()(
                                        ["activeData", "crm", "enquiry", "partiesListings", "forms"],
                                        (form) => form.view.id === payload.payload.resourceId,
                                        (form) => `/v1/listings/${form.view.id}`,
                                        "PATCH",
                                        Listing4.validator,
                                        Listing3,
                                    )(rxjs.of(payload), getState, setState),
                                ),
                            )
                    ),
                )
                .subscribe(),
    },
    {
        type: "CRM_ENQUIRY_SEND_FILES_INPUT_CHANGED" as const,
        run: setFormSubscribed<"CRM_ENQUIRY_SEND_FILES_INPUT_CHANGED", TEnquirySendFiles1Form>()(
            ["activeData", "crm", "enquiry", "sendFilesForm"],
        ),
    },
    {
        type: "CRM_ENQUIRY_SEND_FILES_SUBMIT" as const,
        run: (
            obs$: rxjs.Observable<TActionPayload<"CRM_ENQUIRY_SEND_FILES_SUBMIT", undefined>>,
            getState: TGetState,
            setState: TSetState,
        ) =>
            submitForm<"CRM_ENQUIRY_SEND_FILES_SUBMIT", undefined, TCRMEnquiry.C["sendFilesForm"]["view"], TCRMEnquiry.C["sendFilesForm"]["edit"], TCRMEnquiry.C["sendFilesForm"]["ui"]>()(
                ["activeData", "crm", "enquiry", "sendFilesForm"],
                () => `/v1/enquiries/${getState().routes.params.enquiryId}/send-files`,
                "POST",
                EnquirySendFiles1.validator,
            )(obs$, getState, setState).subscribe(),
    },
];

const makeGetEnquiryRequest = (enquiryId: string): rxjs.Observable<FirstPartyFetchResponse.T<EnquirySuccessResponse1.T>> =>
    rxjs.from(
        fetchWrapper.json<EnquirySuccessResponse1.T>({
            requestParams: {
                url: `${env.REACT_APP_API_URL}/v1/enquiries/${enquiryId}`,
                method: "GET",
                body: option.none,
            },
            expectedTypeCodec: EnquirySuccessResponse1.codec,
            defaultResponse: EnquirySuccessResponse1.newDefault(),
        })(),
    )
        .pipe(rxjsOperators.tap(util.defaultCRMRequestErrorHandler));

const makeGetViewingsRequest = (enquiryId: string): rxjs.Observable<FirstPartyFetchResponse.T<ListingEnquiriesViewingSuccessResponse3.T>> =>
    rxjs.from(
        fetchWrapper.json<ListingEnquiriesViewingSuccessResponse3.T>({
            requestParams: {
                url: `${env.REACT_APP_API_URL}/v1/viewings?limit=200&filters=${encodeURIComponent(JSON.stringify(ViewingFilter1.createForEnquiryId(enquiryId)))}&order_by=created_at&order_direction=desc`,
                method: "GET",
                body: option.none,
            },
            expectedTypeCodec: ListingEnquiriesViewingSuccessResponse3.codec,
            defaultResponse: ListingEnquiriesViewingSuccessResponse3.newDefault(),
        })(),
    )
    .pipe(rxjsOperators.tap(util.defaultCRMRequestErrorHandler));

type TEnquiryViewingsOnlyRequestPayloads = [
    string,
    FirstPartyFetchResponse.T<ListingEnquiriesViewingSuccessResponse3.T>,
];

const viewingsActionable = (enquiryId: string, setState: TSetState, getState: TGetState): rxjs.Observable<actionable.TActionablePack<TEnquiryViewingsOnlyRequestPayloads>> =>
     pipe(
        actionable.of<TEnquiryViewingsOnlyRequestPayloads>(
            setState,
            getState,
            [
                enquiryId,
                FirstPartyFetchResponse.create2XX(ListingEnquiriesViewingSuccessResponse3.newDefault()),
            ]
        ),
        actionable.fromPayloadIndex<0, 1, TEnquiryViewingsOnlyRequestPayloads>(0, 1, makeGetViewingsRequest),
        actionable.tapPayloads<TEnquiryViewingsOnlyRequestPayloads>(
            reduceDataToStateUpdate<TEnquiryViewingsOnlyRequestPayloads>(setState)(
                set<TEnquiryViewingsOnlyRequestPayloads>()(
                    ["activeData", "crm", "enquiry", "viewingForms"],
                    (formList, data) => TForm.requestToFormListPreserveOld<TListingEnquiriesViewing2AndListingEnquiriesViewingForm1FormList["forms"][number]>(
                        formList,
                        data[1],
                        (existingForm, newData) => existingForm.view.id === newData.id
                    ),
                ),
            ),
        ),
    );

const makeGetEnquiriesRelatedToOffersRequest = (offerSuccessResponse: FirstPartyFetchResponse.T<OfferSuccessResponse2.T>): rxjs.Observable<FirstPartyFetchResponse.T<EnquirySuccessResponse2.T>> =>
     pipe(
        offerSuccessResponse.response.data,
        nonEmptyArray.fromArray,
        option.fold(
            () => rxjs.of(FirstPartyFetchResponse.create2XX(EnquirySuccessResponse2.newDefault())),
            (offers) =>
                rxjs.from(
                    fetchWrapper.json<EnquirySuccessResponse2.T>({
                        requestParams: {
                            url: `${env.REACT_APP_API_URL}/v1/enquiries?filters=${encodeURIComponent(JSON.stringify(EnquiryFilter1.createWhereIdAnyOf(
                                 pipe(
                                    offers,
                                    nonEmptyArray.map((offer) => offer.enquiry_id),
                                ),
                            )))}`,
                            method: "GET",
                            body: option.none,
                        },
                        expectedTypeCodec: EnquirySuccessResponse2.codec,
                        defaultResponse: EnquirySuccessResponse2.newDefault(),
                    })(),
                ),
        )
    )
        .pipe(rxjsOperators.tap(util.defaultCRMRequestErrorHandler));

const makeGetPartyRequest = (partyId: string): rxjs.Observable<FirstPartyFetchResponse.T<PartyResponse1.T>> =>
    rxjs.from(
        fetchWrapper.json<PartyResponse1.T>({
            requestParams: {
                url: `${env.REACT_APP_API_URL}/v1/parties/${partyId}`,
                method: "GET",
                body: option.none,
            },
            expectedTypeCodec: PartyResponse1.codec,
            defaultResponse: PartyResponse1.newDefault(),
        })(),
    )
        .pipe(rxjsOperators.tap(util.defaultCRMRequestErrorHandler));

const makeGetPartyPhoneNumbersRequest = (partyId: string): rxjs.Observable<FirstPartyFetchResponse.T<PartyPhoneNumbersSuccessResponse1.T>> =>
    rxjs.from(
        fetchWrapper.json<PartyPhoneNumbersSuccessResponse1.T>({
            requestParams: {
                url: `${env.REACT_APP_API_URL}/v1/parties/${partyId}/phone-numbers`,
                method: "GET",
                body: option.none,
            },
            expectedTypeCodec: PartyPhoneNumbersSuccessResponse1.codec,
            defaultResponse: PartyPhoneNumbersSuccessResponse1.newDefault(),
        })(),
    )
        .pipe(rxjsOperators.tap(util.defaultCRMRequestErrorHandler));

const makeGetPartiesRelatedToOffersRequest = (enquirySuccessResponse: FirstPartyFetchResponse.T<EnquirySuccessResponse2.T>): rxjs.Observable<FirstPartyFetchResponse.T<PartiesSuccessResponse1.T>> =>
     pipe(
        enquirySuccessResponse.response.data,
        nonEmptyArray.fromArray,
        option.fold(
            () => rxjs.of(FirstPartyFetchResponse.create2XX(PartiesSuccessResponse1.newDefault())),
            (enquiries) =>
                rxjs.from(
                    fetchWrapper.json<PartiesSuccessResponse1.T>({
                        requestParams: {
                            url: `${env.REACT_APP_API_URL}/v1/parties?filters=${encodeURIComponent(JSON.stringify(PartiesFilter1.createWhereIdAnyOf(
                                 pipe(
                                    enquiries,
                                    nonEmptyArray.map((enquiry) => enquiry.party_id),
                                ),
                            )))}`,
                            method: "GET",
                            body: option.none,
                        },
                        expectedTypeCodec: PartiesSuccessResponse1.codec,
                        defaultResponse: PartiesSuccessResponse1.newDefault(),
                    })(),
                ),
        )
    )
        .pipe(rxjsOperators.tap(util.defaultCRMRequestErrorHandler));

const makeGetListingRequest = (listingId: string): rxjs.Observable<FirstPartyFetchResponse.T<ListingSuccessResponse1.T>> =>
    rxjs.from(
        fetchWrapper.json<ListingSuccessResponse1.T>({
            requestParams: {
                url: `${env.REACT_APP_API_URL}/v1/listings/${listingId}`,
                method: "GET",
                body: option.none,
            },
            expectedTypeCodec: ListingSuccessResponse1.codec,
            defaultResponse: ListingSuccessResponse1.newDefault(),
        })(),
    )
        .pipe(rxjsOperators.tap(util.defaultCRMRequestErrorHandler));

const makeGetPartiesListingsRequest = (partyId: string): rxjs.Observable<FirstPartyFetchResponse.T<ListingSuccessResponse2.T>> =>
    rxjs.from(
        fetchWrapper.json<ListingSuccessResponse2.T>({
            requestParams: {
                url: `${env.REACT_APP_API_URL}/v1/listings?filters=${encodeURIComponent(JSON.stringify(ListingFilter1.createForParty(partyId)))}`,
                method: "GET",
                body: option.none,
            },
            expectedTypeCodec: ListingSuccessResponse2.codec,
            defaultResponse: ListingSuccessResponse2.newDefault(),
        })(),
    )
        .pipe(rxjsOperators.tap(util.defaultCRMRequestErrorHandler));

const makeGetHighestOffersRequest = (listingId: string): rxjs.Observable<FirstPartyFetchResponse.T<OfferSuccessResponse2.T>> =>
    rxjs.from(
        fetchWrapper.json<OfferSuccessResponse2.T>({
            requestParams: {
                url: `${env.REACT_APP_API_URL}/v1/offers?filters=${encodeURIComponent(JSON.stringify(OfferFilter1.createForListingNotWithdrawn(listingId)))}&limit=5&order_by=amount&order_direction=desc`,
                method: "GET",
                body: option.none,
            },
            expectedTypeCodec: OfferSuccessResponse2.codec,
            defaultResponse: OfferSuccessResponse2.newDefault(),
        })(),
    )
        .pipe(rxjsOperators.tap(util.defaultCRMRequestErrorHandler));

const makeGetOtherViewingsForListingRequest = (listingId: string): rxjs.Observable<FirstPartyFetchResponse.T<ListingEnquiriesViewingSuccessResponse3.T>> =>
    rxjs.from(
        fetchWrapper.json<ListingEnquiriesViewingSuccessResponse3.T>({
            requestParams: {
                url: `${env.REACT_APP_API_URL}/v1/viewings?limit=200&filters=${encodeURIComponent(JSON.stringify(ViewingFilter1.createForListingIdOnlyFutureNotCancelled(listingId, DateTime.local().toUTC().toISO())))}`,
                method: "GET",
                body: option.none,
            },
            expectedTypeCodec: ListingEnquiriesViewingSuccessResponse3.codec,
            defaultResponse: ListingEnquiriesViewingSuccessResponse3.newDefault(),
        })(),
    )
    .pipe(rxjsOperators.tap(util.defaultCRMRequestErrorHandler));

type TEnquiryOtherViewingsForListingOnlyRequestPayloads = [
    string,
    FirstPartyFetchResponse.T<ListingEnquiriesViewingSuccessResponse3.T>,
];

const otherViewingsForListingActionable = (listingId: string, setState: TSetState, getState: TGetState): rxjs.Observable<actionable.TActionablePack<TEnquiryOtherViewingsForListingOnlyRequestPayloads>> =>
     pipe(
        actionable.of<TEnquiryViewingsOnlyRequestPayloads>(
            setState,
            getState,
            [
                listingId,
                FirstPartyFetchResponse.create2XX(ListingEnquiriesViewingSuccessResponse3.newDefault()),
            ]
        ),
        actionable.fromPayloadIndex<0, 1, TEnquiryViewingsOnlyRequestPayloads>(0, 1, makeGetOtherViewingsForListingRequest),
        actionable.tapPayloads<TEnquiryViewingsOnlyRequestPayloads>(
            reduceDataToStateUpdate<TEnquiryViewingsOnlyRequestPayloads>(setState)(
                set<TEnquiryViewingsOnlyRequestPayloads>()(
                    ["activeData", "crm", "enquiry", "otherViewingsForListing"],
                    (formList, data) => TForm.requestToFormList<TListingEnquiriesViewing2ReadOnlyFormList["forms"][number]>(formList, data[1]),
                ),
            ),
        ),
    );

type TPartyRequestResponse = [
    string,
    FirstPartyFetchResponse.T<PartyResponse1.T>,
];

const reloadParty = (partyId: string, getState: TGetState, setState: TSetState): rxjs.Observable<actionable.TActionablePack<TPartyRequestResponse>> =>
     pipe(
        actionable.of<TPartyRequestResponse>(
            setState,
            getState,
            [
                partyId,
                FirstPartyFetchResponse.create2XX(PartyResponse1.newDefault()),
            ]
        ),
        actionable.fromPayloadIndex<0, 1, TPartyRequestResponse>(0, 1, makeGetPartyRequest),
        actionable.tapPayloads<TPartyRequestResponse>(
            reduceDataToStateUpdate<TPartyRequestResponse>(setState)(
                set<TPartyRequestResponse>()(
                    ["activeData", "crm", "enquiry", "partyForm"],
                    (form, data) => TForm.dataToForm<TParty2AndC7Form>(Party7.newDefault(), {})(data[1].response.data),
                ),
                set<TPartyRequestResponse>()(
                    ["activeData", "crm", "enquiry", "peopleFormList"],
                    (formList, data) => ({
                        ...formList,
                        status: TFormStatus.constants.UNTOUCHED,
                        forms: TForm.dataToFormsPreserveOld<TUser15AndC8FormList["forms"][number]>(
                            formList,
                            data[1].response.data.users,
                            (existingForm, newData) => existingForm.view.id === newData.id
                        ),
                    })
                ),
            ),
        ),
    );

const reloadOnlyPartyUsers = (partyId: string, getState: TGetState, setState: TSetState): rxjs.Observable<actionable.TActionablePack<TPartyRequestResponse>> =>
     pipe(
        actionable.of<TPartyRequestResponse>(
            setState,
            getState,
            [
                partyId,
                FirstPartyFetchResponse.create2XX(PartyResponse1.newDefault()),
            ]
        ),
        actionable.fromPayloadIndex<0, 1, TPartyRequestResponse>(0, 1, makeGetPartyRequest),
        actionable.tapPayloads<TPartyRequestResponse>(
            reduceDataToStateUpdate<TPartyRequestResponse>(setState)(
                set<TPartyRequestResponse>()(
                    ["activeData", "crm", "enquiry", "peopleFormList"],
                    (formList, data) => ({
                        ...formList,
                        status: TFormStatus.constants.UNTOUCHED,
                        forms: TForm.dataToFormsPreserveOld<TUser15AndC8FormList["forms"][number]>(
                            formList,
                            data[1].response.data.users,
                            (existingForm, newData) => existingForm.view.id === newData.id
                        ),
                    })
                ),
            ),
        ),
    );

type TPartyPhoneNumbersRequestResponse = [
    string,
    FirstPartyFetchResponse.T<PartyPhoneNumbersSuccessResponse1.T>,
];

const reloadPartyPhoneNumbers = (partyId: string, getState: TGetState, setState: TSetState): rxjs.Observable<actionable.TActionablePack<TPartyPhoneNumbersRequestResponse>> =>
     pipe(
        actionable.of<TPartyPhoneNumbersRequestResponse>(
            setState,
            getState,
            [
                partyId,
                FirstPartyFetchResponse.create2XX(PartyPhoneNumbersSuccessResponse1.newDefault()),
            ]
        ),
        actionable.fromPayloadIndex<0, 1, TPartyPhoneNumbersRequestResponse>(0, 1, makeGetPartyPhoneNumbersRequest),
        actionable.tapPayloads<TPartyPhoneNumbersRequestResponse>(
            reduceDataToStateUpdate<TPartyPhoneNumbersRequestResponse>(setState)(
                set<TPartyPhoneNumbersRequestResponse>()(
                    ["activeData", "crm", "enquiry", "peoplePhoneNumbersList"],
                    (formList, data) => TForm.requestToFormListPreserveOld<TUserPhoneNumber5AndC4FormList["forms"][number]>(
                        formList,
                        data[1],
                        (existingForm, newData) => existingForm.view.id === newData.id,
                    )
                ),
            )
        ),
    );

type TEnquiryUpdateOffersRequestPayloads = [
    [string, string],
    FirstPartyFetchResponse.T<OfferSuccessResponse2.T>,
    FirstPartyFetchResponse.T<EnquirySuccessResponse2.T>,
    FirstPartyFetchResponse.T<PartiesSuccessResponse1.T>,
];

// Refresh the "Highest offers" section, previous offers and clear the new offer form
const afterNewOfferActionable = (enquiryId: string, listingId: string, setState: TSetState, getState: TGetState): rxjs.Observable<actionable.TActionablePack<TEnquiryUpdateOffersRequestPayloads>> =>
     pipe(
        actionable.of<TEnquiryUpdateOffersRequestPayloads>(
            setState,
            getState,
            [
                [enquiryId, listingId],
                FirstPartyFetchResponse.create2XX(OfferSuccessResponse2.newDefault()),
                FirstPartyFetchResponse.create2XX(EnquirySuccessResponse2.newDefault()),
                FirstPartyFetchResponse.create2XX(PartiesSuccessResponse1.newDefault()),
            ],
        ),
        actionable.fromPayloadIndex<0, 1, TEnquiryUpdateOffersRequestPayloads>(0, 1, ([, lId]) => makeGetHighestOffersRequest(lId)),
        actionable.fromPayloadIndex<1, 2, TEnquiryUpdateOffersRequestPayloads>(1, 2, makeGetEnquiriesRelatedToOffersRequest),
        actionable.fromPayloadIndex<2, 3, TEnquiryUpdateOffersRequestPayloads>(2, 3, makeGetPartiesRelatedToOffersRequest),
        actionable.tapPayloads<TEnquiryUpdateOffersRequestPayloads>(
            reduceDataToStateUpdate<TEnquiryUpdateOffersRequestPayloads>(setState)(
                set<TEnquiryUpdateOffersRequestPayloads>()(
                    ["activeData", "crm", "enquiry", "highestOffersForms"],
                    (formList, data) => TForm.requestToFormList<TListingEnquiriesOffer2ReadOnlyFormList["forms"][number]>(formList, data[1]),
                ),
                set<TEnquiryUpdateOffersRequestPayloads>()(
                    ["activeData", "crm", "enquiry", "enquiriesRelatedToOffersForms"],
                    (formList, data) => TForm.requestToFormList<TEnquiryC2ReadOnlyFormList["forms"][number]>(formList, data[2]),
                ),
                set<TEnquiryUpdateOffersRequestPayloads>()(
                    ["activeData", "crm", "enquiry", "partiesRelatedToOffersForms"],
                    (formList, data) => TForm.requestToFormList<TParty2ReadOnlyFormList["forms"][number]>(formList, data[3]),
                ),
                set<TEnquiryUpdateOffersRequestPayloads>()(
                    ["activeData", "crm", "enquiry", "createOfferForm"],
                    () => new TCRMEnquiry.C().createOfferForm,
                ),
            ),
        ),
    );

type TEnquiryUpdatePartiesListingsRequestPayloads = [
    string,
    FirstPartyFetchResponse.T<ListingSuccessResponse2.T>,
];

const afterNewPropertyToSellActionable = (partyId: string, setState: TSetState, getState: TGetState): rxjs.Observable<actionable.TActionablePack<TEnquiryUpdatePartiesListingsRequestPayloads>> =>
     pipe(
        actionable.of<TEnquiryUpdatePartiesListingsRequestPayloads>(
            setState,
            getState,
            [
                partyId,
                FirstPartyFetchResponse.create2XX(ListingSuccessResponse2.newDefault()),
            ],
        ),
        actionable.fromPayloadIndex<0, 1, TEnquiryUpdatePartiesListingsRequestPayloads>(0, 1, makeGetPartiesListingsRequest),
        actionable.tapPayloads<TEnquiryUpdatePartiesListingsRequestPayloads>(
            reduceDataToStateUpdate<TEnquiryUpdatePartiesListingsRequestPayloads>(setState)(
                set<TEnquiryUpdatePartiesListingsRequestPayloads>()(
                    ["activeData", "crm", "enquiry", "partiesListings"],
                    (formList, data) =>  pipe(
                        TForm.requestToFormList<TListing3AndListingForm4FormList["forms"][number]>(formList, data[1]),
                        openCollapsibleSectionOfLastFormInList,
                    ),
                ),
            ),
        ),
    );

const openCollapsibleSectionOfLastFormInList = (formList: TListing3AndListingForm4FormList): TListing3AndListingForm4FormList =>
     pipe(
        formList.forms,
        array.last,
        option.map((last) => {
            last.ui.collapsibleSectionIsOpen = true;
            return last;
        }),
        option.fold(
            () => [],
            (last) => [
                ...formList.forms.slice(0, formList.forms.length - 1),
                last,
            ],
        ),
        (forms) => {
            formList.forms = forms;
            return formList;
        },
    );
