import React from "react";
import { TFormStatus } from "../../../../shared/src/codecs/codec";
import { CRMFontSizes } from "../../models/CRMFontSizes";
import { castNewFormStatusToLegacy } from "../../util";
import { CRMButtonTertiary } from "../CRMButtonTertiary/CRMButtonTertiary";
import { CRMFormButton } from "../CRMFormButton/CRMFormButton";
import { CRMIcon } from "../CRMIcon/CRMIcon";
import { CRMPadding } from "../Simple/CRMPadding/CRMPadding";
import { CRMParagraph } from "../Simple/CRMParagraph/CRMParagraph";
import { CRMSpacingColumn } from "../CRMSpacingColumn/CRMSpacingColumn";
import { WeightBold } from "../WeightBold/WeightBold";

export const CRMDeclarationComponent = (props: React.PropsWithChildren<{
    title: string;
    subTitle?: string;
    cta: {
        visible: true;
        ctaLabel: string;
        formStatus: TFormStatus;
        onCta: () => void;
    } | {
        visible: false
    };
}>): JSX.Element =>
    <div className="crm-declaration-component">
        <CRMPadding size="medium">
            <CRMSpacingColumn spacing="large">
                {/* HEADING */}
                <CRMSpacingColumn spacing="tiny">
                    {/* TITLE */}
                    <CRMParagraph fontSize={CRMFontSizes.MED}>
                        <WeightBold>{props.title}</WeightBold>
                    </CRMParagraph>

                    {/* SUBTITLE */}
                    {props.subTitle && <CRMParagraph fontSize={CRMFontSizes.SMALL}>
                        {props.subTitle}
                    </CRMParagraph>}
                </CRMSpacingColumn>

                {/* DECLARATION */}
                <div className="crm-declaration-component__declaration">
                    {/* TOP LEFT ICON */}
                    <div className="crm-declaration-component__icon crm-declaration-component__icon--top">
                        <CRMIcon
                            iconName="quote-left"
                            size="extra-large"
                            colour="highlights-calming-pink-0"
                        />
                    </div>

                    <div className="crm-declaration-component__icon crm-declaration-component__icon--bottom">
                        <CRMIcon
                            iconName="quote-right"
                            size="medium"
                            colour="highlights-calming-pink-0"
                        />
                    </div>

                    {/* DECLARATION BODY */}
                    <CRMPadding size="medium">
                        {props.children}
                    </CRMPadding>
                </div>

                {/* CTA */}
                {props.cta.visible && <div>
                    <CRMFormButton
                        formStatus={castNewFormStatusToLegacy(props.cta.formStatus)}
                        ButtonElement={CRMButtonTertiary}
                        label={props.cta.ctaLabel}
                        onClick={props.cta.onCta}
                    />
                </div>}
            </CRMSpacingColumn>
        </CRMPadding>
    </div>;
