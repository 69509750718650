import React from "react";
import { THexColor, TPercentageSize, TPixelSize } from "../../../models/StringLiterals";

type TCountdownBarProps = {
    progress: number;
    fullBarColor: THexColor;
    emptyBarColor: THexColor;
    height: TPixelSize;
};

export const CRMProgressBar = (props: React.PropsWithChildren<TCountdownBarProps>): JSX.Element => {

    return (
        <div
            style={{
                width: "100%",
                height: props.height,
                backgroundColor: props.emptyBarColor
            }}
        >
            {/* FULL BAR */}
            <div
                style={{
                    height: "100%",
                    width: `${props.progress % 101}%`, 
                    backgroundColor: props.fullBarColor,
                    transition: "all 250ms"
                }}
            />
        </div>
    );
};
