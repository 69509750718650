import React from "react";
import { IOption } from "../../hooks/UseDropdown";
import { WeightBold } from "../WeightBold/WeightBold";
import { CRMDropdownOption } from "../Simple/CRMDropdownOption/CRMDropdownOption";
import { CRMDropdownBase } from "../CRMDropdownBase/CRMDropdownBase";
import { TextColor } from "../BuildingBlocks/TextColor";
import { CRMColors } from "../../models/CRMColors";
import { CRMDropdownOptionBox } from "../Simple/CRMDropdownOptionBox/CRMDropdownOptionBox";
import { CRMDropdownFaceFlat } from "../Simple/CRMDropdownFaceFlat/CRMDropdownFaceFlat";

interface ICRMDropdownComponent<A extends string, B extends A> {
    value: B;
    options: Array<IOption<A>>;
    valuePrefix?: string;
    displayError?: boolean;
    disabled?: boolean;
    placeholder?: string;
    onChange: (value: A) => void;
}

export const CRMDropdownFlat = <A extends string, B extends A>(props: ICRMDropdownComponent<A, B>): JSX.Element => {

    return (
        <CRMDropdownBase
            FaceComponent={(faceProps) => (
                <CRMDropdownFaceFlat {...faceProps}>
                    {/* TEXT - PLACEHOLDER */}
                    {props.value.length === 0 &&
                        <TextColor color={CRMColors.NEUTRAL_4}>
                            {faceProps.placeholder || "Select Value"}
                        </TextColor>
                    }

                    {/* TEXT - VALUE */}
                    {props.value.length > 0 &&
                        <div>
                            <WeightBold>{faceProps.valuePrefix}</WeightBold> {faceProps.faceText}
                        </div>
                    }
                </CRMDropdownFaceFlat>
            )}
            OptionsComponent={(optionProps) => (
                <CRMDropdownOptionBox>
                    {optionProps.options.map((selectOption, key) => (
                        <CRMDropdownOption
                            key={key}
                            isSelected={optionProps.isOptionSelected(selectOption.value)}
                            label={selectOption.label}
                            onClick={() => {
                                props.onChange(selectOption.value);
                                optionProps.onCloseDropdown();
                            }}
                        />
                    ))}
                </CRMDropdownOptionBox>
            )}
            {...props}
        />
    );
}
