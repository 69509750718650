import React from "react";
import { TCaseQuoteSentToClientForm } from "../../../../../domain/codecs/form/CaseForm";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { Padding } from "../../BuildingBlocks/Padding";
import { Relative } from "../../BuildingBlocks/Relative";
import { SpacingColumn } from "../../BuildingBlocks/SpacingColumn";
import { SpacingRow } from "../../BuildingBlocks/SpacingRow";
import { CRMBottomControlBar } from "../../CRMBottomControlBar/CRMBottomControlBar";
import { CRMButtonPrimary } from "../../CRMButtonPrimary/CRMButtonPrimary";
import { CRMFormButton } from "../../CRMFormButton/CRMFormButton";
import { CRMPopoutHeader } from "../../Simple/CRMPopoutHeader/CRMPopoutHeader";
import { CRMTidyPopout } from "../../Simple/CRMTidyPopout/CRMTidyPopout";
import { CRMQuoteSentToClientPopoutForm } from "./CRMQuoteSentToClientPopoutForm";

export const CRMQuoteSentToClientPopout = (props: React.PropsWithChildren<{
    form: TCaseQuoteSentToClientForm;
    onClose: () => void;
    onChange: (form: TCaseQuoteSentToClientForm) => void;
    onSave: (form: TCaseQuoteSentToClientForm) => void;
}>): JSX.Element => 
    <CRMTidyPopout width="100%" maxHeight="750px">
        <Relative>
            <CRMPopoutHeader
                icon="arrow-left"
                onClick={props.onClose}
            >
                Quote Sent to Client
            </CRMPopoutHeader>

            <SpacingColumn
                justifyContent="space-between"
                height={`calc(-268px + 100vh)`}
            >
                <Padding spacing={CRMSpacing.LARGE}>
                    <CRMQuoteSentToClientPopoutForm
                        displayError={props.form.validationErrors.length > 0 ? true : false}
                        form={props.form}
                        onChange={(form) => props.onChange(form)}
                    />
                </Padding>
            </SpacingColumn>

            <CRMBottomControlBar>
                <Padding 
                    type="custom"
                    width="100%" 
                    spacing={`${CRMSpacing.TINY} ${CRMSpacing.MEDIUM}`}
                >
                    <SpacingRow justifyContent="end">
                        <CRMFormButton
                            formStatus={props.form.status}
                            ButtonElement={CRMButtonPrimary}
                            label="Save"
                            onClick={() => props.onSave(props.form)}
                        />
                    </SpacingRow>
                </Padding>
            </CRMBottomControlBar>
        </Relative>
    </CRMTidyPopout>
;
