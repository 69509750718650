import React from "react";
import { array, string } from "fp-ts";
import { TTypeOfCodec, formStatus } from "../../../../shared/src/codecs/codec";
import * as Country2 from "../../../../domain/models/Country2";
import * as Postcode1 from "../../../../domain/models/Postcode1";
import { TAddress3 } from "../../../../domain/codecs/Address";
import CRMInputLabelAndErrorWrapComponent from "../CRMInputLabelAndErrorWrapComponent/CRMInputLabelAndErrorWrapComponent";
import CRMInputGeneralComponent from "../CRMInputs/CRMInputGeneralComponent/CRMInputGeneralComponent";
import { CRMButtonTertiary } from "../CRMButtonTertiary/CRMButtonTertiary";
import { CRMDropdownComponent } from "../CRMDropdownComponent/CRMDropdownComponent";
import { enumToIDropdownOptions } from "../../functions/enumToIDropdownOptions";
import { CRMSpacingRow } from "../CRMSpacingRow/CRMSpacingRow";
import { CRMInputLabelAndErrorWrapSwitch } from "../CRMInputLabelAndErrorWrapSwitch/CRMInputLabelAndErrorWrapSwitch";
import { CRMSpacingColumn } from "../CRMSpacingColumn/CRMSpacingColumn";
import { CRMHorizontalSeperatorLine } from "../CRMHorizontalSeperatorLine/CRMHorizontalSeperatorLine";
import { doesErrorKeyExist, TError } from "../../../../shared/src/codecs/errors";
import { CRMFormButton } from "../CRMFormButton/CRMFormButton";
import * as FormStatus from "../../models/TFormStatus";
import { CRMTextHighlight } from "../CRMTextHighlight/CRMTextHighlight";
import { TextColour } from "../TextColour/TextColour";
import { CRMColors } from "../../models/CRMColors";
import { Font } from "../Font/Font";
import { CRMFontSizeEnumToNumber } from "../../models/CRMFontSizes";

type TCRMEditAddressFormProps = {
    listing: TAddress3;
    validationErrors: TError;
    onChange: (listingAddress: TAddress3) => void;
    autocomplete_address_status?: TTypeOfCodec<typeof formStatus>;
    onAutocompleteClicked: () => void;
};

export const CRMEditAddressForm = (props: TCRMEditAddressFormProps): JSX.Element => {

    const getAutoAddressCompleteStatus = (): FormStatus.T  => {
        if (
            props.autocomplete_address_status &&
            array.elem(string.Eq)(props.autocomplete_address_status, ["success", "failure", "submitting", "validationError"])
        ) {
            return props.autocomplete_address_status as FormStatus.T;
        }

        if (props.listing.postcode) {
            return "requiresSubmission";
        }

        return "untouched";
    };

    return (
        <CRMSpacingColumn spacing="medium">
            {/* POST CODE & BUILDING NAME/NUMBER */}
            <CRMSpacingRow
                spacing="medium"
                alignItems="flex-start"
                childSize="even-fill"
            >
                {/* POSTCODE */}
                <CRMInputLabelAndErrorWrapComponent
                    label="Postcode"
                    errorMessage="This isn't a valid postcode"
                    displayError={doesErrorKeyExist("edited.postcode", props.validationErrors) || doesErrorKeyExist("edited.address_postcode", props.validationErrors)}
                >
                    <CRMInputGeneralComponent
                        inputType="text"
                        value={props.listing.postcode || ""}
                        placeholder="BS1 2LK"
                        onChange={(value) => props.onChange({
                            ...props.listing,
                            postcode: value === "" ? null : Postcode1.standardise(value),
                        })}
                        displayError={doesErrorKeyExist("edited.postcode", props.validationErrors) || doesErrorKeyExist("edited.address_postcode", props.validationErrors)}
                    />
                </CRMInputLabelAndErrorWrapComponent>

                <CRMInputLabelAndErrorWrapSwitch
                    options={[
                        {
                            label: "Number",
                            value: "number",
                            inputValue: props.listing.building_number,
                            input: <CRMInputGeneralComponent
                                inputType="text"
                                value={props.listing.building_number}
                                placeholder="30"
                                onChange={(value) => props.onChange({
                                    ...props.listing,
                                    building_number: value,
                                })}
                                displayError={false}
                            />,
                        },
                        {
                            label: "Name",
                            value: "name",
                            inputValue: props.listing.building_name,
                            input: <CRMInputGeneralComponent
                                inputType="text"
                                value={props.listing.building_name}
                                placeholder="The Clock House"
                                onChange={(value) => props.onChange({
                                    ...props.listing,
                                    building_name: value,
                                })}
                                displayError={false}
                            />,
                        },
                    ]}
                />
            </CRMSpacingRow>

            {/* AUTOCOMPLETE BUTTON */}
            <CRMFormButton
                formStatus={getAutoAddressCompleteStatus()}
                ButtonElement={CRMButtonTertiary}
                icon="search-list"
                label="Autocomplete from building and postcode"
                onClick={props.onAutocompleteClicked}
            />
            {doesErrorKeyExist("autocomplete", props.validationErrors) &&
                <CRMTextHighlight colour="highlights-calming-pink-10">
                    <Font
                        family="quicksand"
                        size={CRMFontSizeEnumToNumber("small")}
                    >
                        <TextColour colour={CRMColors.HIGHLIGHTS_CALMING_PINK_0}>
                            No matching address found!
                        </TextColour>
                    </Font>
                </CRMTextHighlight>
            }

            <CRMHorizontalSeperatorLine />

            {/* SUB NUMBER & SUB NAME */}
            <CRMSpacingRow
                spacing="medium"
                alignItems="flex-start"
                childSize="even-fill"
            >
                <CRMInputLabelAndErrorWrapComponent label="Sub number">
                    <CRMInputGeneralComponent
                        inputType="text"
                        value={props.listing.sub_building_number}
                        onChange={(value) => props.onChange({
                            ...props.listing,
                            sub_building_number: value,
                        })}
                        displayError={false}
                    />
                </CRMInputLabelAndErrorWrapComponent>
                <CRMInputLabelAndErrorWrapComponent label="Sub name">
                    <CRMInputGeneralComponent
                        inputType="text"
                        value={props.listing.sub_building_name}
                        onChange={(value) => props.onChange({
                            ...props.listing,
                            sub_building_name: value,
                        })}
                        displayError={false}
                    />
                </CRMInputLabelAndErrorWrapComponent>
            </CRMSpacingRow>

            {/* STREET NAME & CITY/TOWN */}
            <CRMSpacingRow
                spacing="medium"
                alignItems="flex-start"
                childSize="even-fill"
            >
                <CRMInputLabelAndErrorWrapComponent label="Street name">
                    <CRMInputGeneralComponent
                        inputType="text"
                        value={props.listing.street_name}
                        onChange={(value) => props.onChange({
                            ...props.listing,
                            street_name: value,
                        })}
                        displayError={false}
                    />
                </CRMInputLabelAndErrorWrapComponent>
                <CRMInputLabelAndErrorWrapComponent label="City/Town">
                    <CRMInputGeneralComponent
                        inputType="text"
                        value={props.listing.city_town}
                        onChange={(value) => props.onChange({
                            ...props.listing,
                            city_town: value,
                        })}
                        displayError={false}
                    />
                </CRMInputLabelAndErrorWrapComponent>
            </CRMSpacingRow>

            {/* LOCALITY & DISTRICT */}
            <CRMSpacingRow
                spacing="medium"
                alignItems="flex-start"
                childSize="even-fill"
            >
                <CRMInputLabelAndErrorWrapComponent label="Locality">
                    <CRMInputGeneralComponent
                        inputType="text"
                        value={props.listing.locality}
                        onChange={(value) => props.onChange({
                            ...props.listing,
                            locality: value,
                        })}
                        displayError={false}
                    />
                </CRMInputLabelAndErrorWrapComponent>
                <CRMInputLabelAndErrorWrapComponent label="District">
                    <CRMInputGeneralComponent
                        inputType="text"
                        value={props.listing.district}
                        onChange={(value) => props.onChange({
                            ...props.listing,
                            district: value,
                        })}
                        displayError={false}
                    />
                </CRMInputLabelAndErrorWrapComponent>
            </CRMSpacingRow>

            {/* COUNTY & COUNTRY */}
            <CRMSpacingRow
                spacing="medium"
                alignItems="flex-start"
                childSize="even-fill"
            >
                <CRMInputLabelAndErrorWrapComponent label="County">
                    <CRMInputGeneralComponent
                        inputType="text"
                        value={props.listing.county}
                        onChange={(value) => props.onChange({
                            ...props.listing,
                            county: value,
                        })}
                        displayError={false}
                    />
                </CRMInputLabelAndErrorWrapComponent>
                <CRMInputLabelAndErrorWrapComponent label="Country">
                    <CRMDropdownComponent
                        options={enumToIDropdownOptions(
                            Country2.values,
                            Country2.fromValueToDisplayName
                        )}
                        value={props.listing.country}
                        onChange={(value) => props.onChange({
                            ...props.listing,
                            country: value,
                        })}
                        displayError={false}
                    />
                </CRMInputLabelAndErrorWrapComponent>
            </CRMSpacingRow>
        </CRMSpacingColumn>
    );
};
