import { array, option } from "fp-ts";
import { pipe } from "fp-ts/lib/function";
import React, { useEffect, useRef, useState } from "react";
import { QuoteRateForm, QuoteRateLiveRate, TQuoteRateActiveTypeVisible, TQuoteRateForm, TQuoteRateLiveRate, TQuoteRatePageForm, fold, TQuoteRateCreatedForm, TQuoteRateDeleteOnNextPublishForm, isQuoteRateLiveRate } from "../../../../../domain/codecs/form/QuoteRateForm";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { Padding } from "../../BuildingBlocks/Padding";
import { SpacingRow } from "../../BuildingBlocks/SpacingRow";
import { CRMButtonIcon } from "../../CRMButtonIcon/CRMButtonIcon";
import { CRMButtonPrimary } from "../../CRMButtonPrimary/CRMButtonPrimary";
import { CRMDropdownControlledBlock } from "../CRMDropdownControlledBlock/CRMDropdownControlledBlock";
import { CRMQuoteRatesSingleViewEditPopout } from "../CRMQuoteRatesSingleView/CRMQuoteRatesSingleViewEditPopout";
import { CRMQuoteRatesLiveSingleView } from "../CRMQuoteRatesSingleView/CRMQuoteRatesLiveSingleView";
import { CRMQuoteRatesChangesList } from "./CRMQuoteRatesChangesList";
import { CRMQuoteRatesLiveList } from "./CRMQuoteRatesLiveList";
import { TEmptyForm } from "../../../../../domain/codecs/form/EmptyForm";
import { TUuidForm } from "../../../../../domain/codecs/form/UuidForm";
import { CRMSectionPopupPrompt } from "../CRMSectionPopupPrompt/CRMSectionPopupPrompt";
import { CRMParagraph } from "../../Simple/CRMParagraph/CRMParagraph";
import { WeightBold } from "../../WeightBold/WeightBold";
import { SpacingColumn } from "../../BuildingBlocks/SpacingColumn";
import { WeightMedium } from "../../WeightMedium/WeightMedium";
import { CRMBlockScrollCacheView, CRMBlockScrollResetView } from "../../CRM/CRMBlock/CRMBlockViews/CRMBlockViews";

type TQuoteRatesBlockProps = {
    form: TQuoteRatePageForm,
    activeTypeVisible: TQuoteRateActiveTypeVisible,
    onActiveTypeVisibleChange: (activeTypeVisible: TQuoteRateActiveTypeVisible) => void,
    onCreateNewRate: (form: TEmptyForm) => void,
    onChangeNewRate: (form: TQuoteRateCreatedForm) => void,
    onSaveNewRate: (form: TQuoteRateCreatedForm) => void,
    onToggleDeleteRateOnNextPublish: (form: TUuidForm) => void,
    onReplaceRate: (form: TUuidForm) => void,
    onDeleteRate: (form: TUuidForm) => void,
    onPublishRateChanges: (form: TEmptyForm) => void,
};

export const CRMQuoteRatesBlock = (props: React.PropsWithChildren<TQuoteRatesBlockProps>): JSX.Element => {
    // We need a ref to the previous renders rate changes so that we can track new rate changes as they are added and pre-open the edit popup
    const rateChangesRef = useRef<Array<TQuoteRateForm>>();

    const [rateVisible, setRateVisible] = useState<["LIVE_RATES" | "RATE_CHANGES", string] | null>(null);

    const [isConfirmPublishPromptOpen, setIsConfirmPublishPromptOpen] = useState<boolean>(false);

    const canPublishRateChanges = props.form.children.can_publish_rates;

    const isTableVisible = rateVisible === null;

    const isLiveRatesVisible = rateVisible && rateVisible[0] === "LIVE_RATES";

    const isRateChangesVisible = rateVisible && rateVisible[0] === "RATE_CHANGES";

    const onLiveRateOpen = (form: TQuoteRateLiveRate) =>
            setRateVisible(["LIVE_RATES", form.id]);

    const onChangeRateOpen = (form: TQuoteRateForm | TQuoteRateLiveRate) =>
        isQuoteRateLiveRate(form)
            ? setRateVisible(["LIVE_RATES", form.id])
            : setRateVisible(["RATE_CHANGES", form.edited.id]);

    const onCloseSingleView = () =>
        setRateVisible(null);

    const getLiveRateVisible = (): TQuoteRateLiveRate =>
        pipe(
            props.form.children.live_rates,
            array.findFirst(({id}) => id === (rateVisible && rateVisible[1])),
            option.getOrElse(() => QuoteRateLiveRate.newDefault()),
        );

    const getRateChangeVisible = (): TQuoteRateForm =>
        pipe(
            props.form.children.rate_changes,
            array.findFirst(({edited}) => edited.id === (rateVisible && rateVisible[1])),
            option.getOrElse(() => QuoteRateForm.newDefault()),
        );

    const onSaveNewRate = (form: TQuoteRateCreatedForm) => {
        setRateVisible(null);
        props.onSaveNewRate(form);
    };

    const liveRateOnToggleDeleteRateOnNextPublish = () => {
        setRateVisible(null);
        props.onToggleDeleteRateOnNextPublish(getLiveRateVisible().set_will_be_deleted_on_next_publish_form);
    };

    const rateChangeOnToggleDeleteRateOnNextPublish = (form: TQuoteRateDeleteOnNextPublishForm) => {
        setRateVisible(null);
        props.onToggleDeleteRateOnNextPublish(form.children.undo_delete_on_next_publish_form);
    };

    const onReplaceRate = () => {
        setRateVisible(null);
        props.onReplaceRate(getLiveRateVisible().set_will_be_deleted_on_next_publish_and_replace_form);
    };

    const onDeleteRate = (form: TQuoteRateCreatedForm) => {
        setRateVisible(null);
        props.onDeleteRate(form.children.delete_form);
    };

    const onPublishRateChange = () => {
        setIsConfirmPublishPromptOpen(false);
        props.onPublishRateChanges(props.form.children.publish_rates_form);
    };

    // This effect will open the edit popout as soon as a created rate change is added
    useEffect(
        () => {
            if (
                rateChangesRef.current
                && props.form.children.rate_changes.length > rateChangesRef.current.length
                && props.form.children.rate_changes[0]
                && props.form.children.rate_changes[0].children.tag === "created"
            ) {
                setRateVisible(["RATE_CHANGES", props.form.children.rate_changes[0].edited.id]);
            }

            if (rateChangesRef.current || props.form.status === "success") {
                rateChangesRef.current = props.form.children.rate_changes;
            }
        },
        [props.form.children.rate_changes]
    );

    return (
        <>
            {/* CONFIRM PUBLISH PROMPT */}
            {isConfirmPublishPromptOpen &&
                <CRMSectionPopupPrompt
                    formStatus={props.form.children.publish_rates_form.status}
                    title="Are you sure you want to publish these changes?"
                    onNo={() => setIsConfirmPublishPromptOpen(false)}
                    onYes={onPublishRateChange}
                >
                    <CRMParagraph>
                        <SpacingColumn spacing={CRMSpacing.MEDIUM}>
                            <WeightBold>You are about to put all the changes seen here live!</WeightBold>

                            <WeightMedium>Once published the changes shown here will take immediate affect on new quotes generated.</WeightMedium>
                        </SpacingColumn>
                    </CRMParagraph>
                </CRMSectionPopupPrompt>
            }

            {/* VIEW - TABLE/S */}
            <CRMBlockScrollCacheView isShowing={isTableVisible}>
                <CRMDropdownControlledBlock
                    menuMode="min_width"
                    sections={[
                        {
                            value: "LIVE_RATES",
                            label: "Live Rates",
                            badgeCount: 0,
                            content: (
                                <CRMQuoteRatesLiveList
                                    forms={props.form.children.live_rates}
                                    onOpen={onLiveRateOpen}
                                />
                            )
                        },
                        {
                            value: "RATE_CHANGES",
                            label: "Rate Changes",
                            badgeCount: props.form.children.rate_changes.length,
                            content: (
                                <CRMQuoteRatesChangesList
                                    forms={props.form.children.rate_changes}
                                    onOpen={onChangeRateOpen}
                                    onDelete={props.onDeleteRate}
                                    onUndoDeleteOnNextPublish={props.onToggleDeleteRateOnNextPublish}
                                />
                            ),
                            controlElements: (
                                <Padding
                                    spacing={CRMSpacing.MEDIUM}
                                    type="horizontal"
                                >
                                    <SpacingRow spacing={CRMSpacing.TINY}>
                                        {/* ADD NEW RATE */}
                                        <CRMButtonIcon
                                            variant="quaternary"
                                            icon="add"
                                            onClick={() => props.onCreateNewRate(props.form.children.create_rate_form)}
                                        />

                                        {/* PUBLISH RATE CHANGES */}
                                        <CRMButtonPrimary
                                            icon="done-all"
                                            label="Publish"
                                            disabled={!canPublishRateChanges}
                                            onClick={() => setIsConfirmPublishPromptOpen(true)}
                                        />
                                    </SpacingRow>
                                </Padding>
                            )
                        },
                    ]}
                    value={props.activeTypeVisible}
                    onValueChange={props.onActiveTypeVisibleChange}
                />
            </CRMBlockScrollCacheView>

            {/* VIEW - SINGLE */}
            <CRMBlockScrollResetView isShowing={!isTableVisible}>

                {/* VIEW - LIVE RATES */}
                {isLiveRatesVisible &&
                    <CRMQuoteRatesLiveSingleView
                        quoteRate={getLiveRateVisible()}
                        onClose={onCloseSingleView}
                        onToggleDeleteOnNextPublish={liveRateOnToggleDeleteRateOnNextPublish}
                        onReplace={onReplaceRate}
                    />
                }

                {/* VIEW - RATE CHANGES */}
                {isRateChangesVisible &&
                    pipe(
                        getRateChangeVisible(),
                        fold({
                            onCreatedForm: (form) => (
                                <CRMQuoteRatesSingleViewEditPopout
                                    form={form}
                                    onClose={onCloseSingleView}
                                    onChange={props.onChangeNewRate}
                                    onDelete={onDeleteRate}
                                    onSave={onSaveNewRate}
                                />
                            ),
                            onDeleteOnNextPublishForm: (form) => (
                                <CRMQuoteRatesLiveSingleView
                                    quoteRate={{
                                        ...form.original,
                                        ...form.children,
                                    }}
                                    onClose={onCloseSingleView}
                                    onToggleDeleteOnNextPublish={() => rateChangeOnToggleDeleteRateOnNextPublish(form)}
                                    onReplace={() => undefined}
                                />
                            )
                        })
                    )
                }
            </CRMBlockScrollResetView>
        </>
    );
};
