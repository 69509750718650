import React from "react";
import { TPercentageSize, TPixelSize } from "../../models/StringLiterals";

type TBorderType = 
    "all"
    | "horizontal"
    | "vertical"
    | "left"
    | "right"
    | "top"
    | "bottom"
    | "custom"
;

type TBorderProps = {
    type?: TBorderType;
    definition: string;
    radius?: TPixelSize;
    height?: TPixelSize | TPercentageSize;
};

export const Border = (props: React.PropsWithChildren<TBorderProps>): JSX.Element => {
    const radius = props.radius || "0";
    const borderTypeAndDefinitionToStyle = (type: TBorderType, definition: string): React.CSSProperties => {
        switch (type) {
            case "vertical":
                return {
                    borderTop: definition,
                    borderBottom: definition,
                    borderRadius: radius
                };
            case "horizontal":
                return {
                    borderLeft: definition,
                    borderRight: definition,
                    borderRadius: radius
                };
            case "left":
                return {
                    borderLeft: definition,
                    borderRadius: radius
                };
            case "right":
                return {
                    borderRight: definition,
                    borderRadius: radius
                };
            case "top":
                return {
                    borderTop: definition,
                    borderRadius: radius
                };
            case "bottom":
                return {
                    borderBottom: definition,
                    borderRadius: radius
                };
            case "all":
            case "custom":
            default:
                return {
                    border: definition,
                    borderRadius: radius
                };
        }
    }

    return (
        <div
            style={{
                ...borderTypeAndDefinitionToStyle(props.type || "all", props.definition),
                height: props.height,
            }}
        >
            {props.children}
        </div>
    );
};
