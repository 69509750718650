import React, { useEffect, useState } from "react";
import { array as extArray } from "../../../../shared/src/utilByDomainGroupExport";
import { CRMFontSizes } from "../../models/CRMFontSizes";
import { CRMSpacing } from "../../models/CRMSpacing";
import { CRMColors, TCRMColourValue } from "../../models/CRMColors";
import { FontQuicksand } from "../BuildingBlocks/FontQuicksand";
import { FontSignikaNegative } from "../BuildingBlocks/FontSignikaNegative";
import { FontSize } from "../BuildingBlocks/FontSize";
import { SpacingColumn } from "../BuildingBlocks/SpacingColumn";
import { SpacingRow } from "../BuildingBlocks/SpacingRow";
import { TextColor } from "../BuildingBlocks/TextColor";
import { Padding } from "../BuildingBlocks/Padding";
import { CRMCardOutside } from "../CRMCardOutside/CRMCardOutside";
import { CRMDeferButtonComponent } from "../CRMDeferButtonComponent/CRMDeferButtonComponent";
import { CRMIcon } from "../CRMIcon/CRMIcon";
import { CRMPadding } from "../Simple/CRMPadding/CRMPadding";
import { CRMHorizontalSeperatorLine } from "../CRMHorizontalSeperatorLine/CRMHorizontalSeperatorLine";
import { CRMInputCheckboxComponent } from "../CRMInputCheckboxComponent/CRMInputCheckboxComponent";
import { Uppercase } from "../Uppercase/Uppercase";
import { WeightBold } from "../WeightBold/WeightBold";
import { WeightMedium } from "../WeightMedium/WeightMedium";
import { TListingAdHocTaskEditable } from "../../../../domain/codecs/formEditable/ListingAdHocTaskEditable";
import { Strikethrough } from "../BuildingBlocks/Strikethrough";
import { castNewFormStatusToLegacy } from "../../util";
import { trimWithEllipsis } from "../../../../shared/src/utilsByDomain/string/trimWithEllipsis";
import { CRMButtonIconWithLabel } from "../CRMButtonIconWithLabel/CRMButtonIconWithLabel";
import { CRMCardOutsidePopupBlank } from "../CRMCardOutsidePopupBlank/CRMCardOutsidePopupBlank";
import { CRMTitleForCard } from "../CRMTitleForCard/CRMTitleForCard";
import { CRMButtonSecondary } from "../CRMButtonSecondary/CRMButtonSecondary";
import CRMInputLabelAndErrorWrapComponent from "../CRMInputLabelAndErrorWrapComponent/CRMInputLabelAndErrorWrapComponent";
import CRMTextAreaComponent from "../CRMTextAreaComponent/CRMTextAreaComponent";
import { pipe } from "fp-ts/function";
import { array } from "fp-ts";
import { FormStatus_highestPriority, TFormStatus } from "../../../../shared/src/codecs/codec";
import { CRMFormButton } from "../CRMFormButton/CRMFormButton";
import { CRMButtonQuaternary } from "../CRMButtonQuaternary/CRMButtonQuaternary";
import { DateTime } from "luxon";
import { TListingEnquiryAdHocTasksForm } from "../../../../domain/codecs/formEditable/ListingEnquiryForm";
import { CRMCardOutsidePopupBasic } from "../CRMCardOutsidePopupBasic/CRMCardOutsidePopupBasic";
import { CRMAutosaveIndicatorWrap } from "../CRM/CRMAutosaveIndicatorWrap/CRMAutosaveIndicatorWrap";

type TAdHocTasksCardProps<A extends TListingAdHocTaskEditable | TListingEnquiryAdHocTasksForm> = {
    tasks: Array<A>;
    addNewTaskFormStatus: TFormStatus;
    onAddNewTask: (task: string) => void;
    onUpdateTasks: (tasks: Array<A>) => void;
};


export const CRMSailHomesAdHocTasksCard = <A extends TListingAdHocTaskEditable | TListingEnquiryAdHocTasksForm>(props: TAdHocTasksCardProps<A>): JSX.Element => {

    const [isOpen, setIsOpen] = useState(false);
    const [isAddTaskPopupOpen, setIsAddTaskPopupOpen] = useState(false);
    const [newTask, setNewTask] = useState<string>("");

    useEffect(
        () => {
            if (props.addNewTaskFormStatus === "success") {
                setTimeout(() => closeAddNewTaskPopup(), 500);
            }
        },
        [props.addNewTaskFormStatus]
    );

    const closeAddNewTaskPopup = (): void => {
        setIsAddTaskPopupOpen(false);
        setNewTask("");
    }

    const getIncompleteTaskCount = (): number =>
        props.tasks
            .filter((task) => !task.edited.done)
            .length
    
    const hasIncompleteTasks = (): boolean => getIncompleteTaskCount() > 0;

    const sortTasksIntoOpenDefferedAndDone = (taskA: A, taskB: A): number => {
        // The heirarchy this sorts into is: 
        // 1. Undeffered, 
        // 2. Deffered, 
        // 3. Done
        if (taskA.edited.done && taskB.edited.done) {
            return 0;
        }
        
        if (!taskA.edited.done && taskB.edited.done) {
            return -1;
        }
        
        if (taskA.edited.done && !taskB.edited.done) {
            return 1;
        }

        if (!taskA.edited.deferred_date && taskB.edited.deferred_date) {
            return -1;
        }

        if (taskA.edited.deferred_date && !taskB.edited.deferred_date) {
            return 1;
        }

        return 0;
    }

    const getTextColorBasedTaskState = (task: A): TCRMColourValue  => {
        if (task.edited.done) {
            return CRMColors.NEUTRAL_4;
        }

        return CRMColors.NEUTRAL_INK;
    }

    const hasAddingNewTaskFailed = () => props.addNewTaskFormStatus === "failure" || props.addNewTaskFormStatus === "validationError";

    return (
        <CRMCardOutside borderRounding="right">
            <CRMPadding size="large">
                <SpacingColumn spacing={CRMSpacing.MEDIUM}>
                    {/* FACE */}
                    <SpacingRow
                        spacing={CRMSpacing.TINY}
                        justifyContent="space-between"
                        alignItems="flex-start"
                    >
                        {/* TITLE */}
                        <FontSignikaNegative>
                            <Uppercase>
                                <WeightBold>
                                    <FontSize size={CRMFontSizes.LARGE}>
                                        {getIncompleteTaskCount()} Outstanding Ad Hoc Tasks
                                    </FontSize>
                                </WeightBold>
                            </Uppercase>
                        </FontSignikaNegative>

                        {/* ARROW */}
                        {props.tasks.length > 0 &&
                            <CRMIcon
                                iconName="dynamic"
                                size="small"
                                colour="neutral-ink"
                                onClick={() => setIsOpen(!isOpen)}
                            />
                        }
                    </SpacingRow>

                    {/* TASKS */}
                    {hasIncompleteTasks() &&
                        <SpacingColumn spacing={CRMSpacing.TINY}>
                            {props.tasks
                                .filter((task) => !task.edited.done)
                                .sort(sortTasksIntoOpenDefferedAndDone)
                                .map((task, index) => (
                                    <SpacingRow 
                                        key={index}
                                        spacing={CRMSpacing.TINY}
                                    >
                                        {/* DEFFERED TASK ICON */}
                                        {task.edited.deferred_date && DateTime.fromISO(task.edited.deferred_date).toMillis() > DateTime.local().toMillis() &&
                                            <CRMIcon
                                                iconName="snooze"
                                                colour="neutral-ink"
                                                size="tiny"
                                            />
                                        }
                                        {/* TASK PREVIEW TEXT */}
                                        <FontQuicksand>
                                            <FontSize size={CRMFontSizes.SMALL} lineHeight="1.4">
                                                <WeightMedium>
                                                    <TextColor color={getTextColorBasedTaskState(task)}>
                                                        {trimWithEllipsis(task.edited.task, 45)}
                                                    </TextColor>
                                                </WeightMedium>
                                            </FontSize>
                                        </FontQuicksand>
                                    </SpacingRow>
                                ))}
                        </SpacingColumn>
                    }

                    {/* NO TASKS TEXT */}
                    {!hasIncompleteTasks() &&
                        <FontQuicksand>
                            <FontSize size={CRMFontSizes.SMALL} lineHeight="1.4">
                                <TextColor color={CRMColors.NEUTRAL_INK}>
                                    No incomplete tasks.
                                </TextColor>
                            </FontSize>
                        </FontQuicksand>
                    }

                    {/* ADD TASK BUTTON */}
                    <CRMButtonIconWithLabel
                        variant="primary"
                        label="Add task"
                        icon="add"
                        onClick={() => setIsAddTaskPopupOpen(true)}
                    />
                </SpacingColumn>
            </CRMPadding>

            {/* TASK LIST POPUP */}
            {isOpen && <CRMCardOutsidePopupBasic
                isOpen={true}
                onClose={() => setIsOpen(false)}
                title="Ad hoc tasks"
            >
                <CRMAutosaveIndicatorWrap status={
                    pipe(
                        props.tasks,
                        array.map((form) => form.status),
                        FormStatus_highestPriority,
                    )
                }>
                    <SpacingColumn spacing={CRMSpacing.LARGE}>
                        {props.tasks
                            .sort(sortTasksIntoOpenDefferedAndDone)
                            .map((task, index) => (
                                <SpacingColumn key={index} spacing={CRMSpacing.MEDIUM}>
                                    
                                    <FontQuicksand>
                                        <FontSize size={CRMFontSizes.SMALL} lineHeight="1.4">
                                            <WeightBold>
                                                <TextColor color={getTextColorBasedTaskState(task)}>
                                                    {task.edited.done &&
                                                        <Strikethrough>
                                                            {task.edited.task}
                                                        </Strikethrough>
                                                    }
                                                    {!task.edited.done &&
                                                        task.edited.task
                                                    }
                                                </TextColor>
                                            </WeightBold>
                                        </FontSize>
                                    </FontQuicksand>

                                    <SpacingRow spacing={CRMSpacing.MEDIUM} justifyContent="space-between">
                                        <CRMInputCheckboxComponent
                                            label="Done"
                                            checked={task.edited.done}
                                            onClick={() => props.onUpdateTasks(
                                                pipe(
                                                    props.tasks,
                                                    extArray.updateWhere<A>(
                                                        (compareTask) => compareTask.original.id === task.original.id,
                                                        (taskToUpdate) => ({
                                                            ...taskToUpdate,
                                                            edited: {
                                                                ...taskToUpdate.edited,
                                                                done: !taskToUpdate.edited.done,
                                                            },
                                                            status: "requiresSubmission",
                                                        })
                                                    )
                                                )
                                            )}
                                        />
                                        
                                        {!task.edited.done && 
                                            <CRMDeferButtonComponent
                                                value={task.edited.deferred_date}
                                                onChange={(deferred_date) => props.onUpdateTasks(
                                                    pipe(
                                                        props.tasks,
                                                        extArray.updateWhere<A>(
                                                            (compareTask) => compareTask.original.id === task.original.id,
                                                            (taskToUpdate) => ({
                                                                ...taskToUpdate,
                                                                edited: {
                                                                    ...taskToUpdate.edited,
                                                                    deferred_date,
                                                                },
                                                                status: "requiresSubmission",
                                                            })
                                                        )
                                                    )
                                                )}
                                                displayError={false}
                                            />
                                        }
                                    </SpacingRow>

                                    <CRMHorizontalSeperatorLine />
                                </SpacingColumn>
                            ))}
                        {/* ADD TASK BUTTON */}
                        <CRMButtonIconWithLabel
                            variant="primary"
                            label="Add task"
                            icon="add"
                            onClick={() => {
                                setIsOpen(false);
                                setIsAddTaskPopupOpen(true);
                            }}
                        />
                    </SpacingColumn>
                </CRMAutosaveIndicatorWrap>
            </CRMCardOutsidePopupBasic>}

            {/* ADD TASK POPUP */}
            <CRMCardOutsidePopupBlank
                isOpen={isAddTaskPopupOpen}
                onClose={closeAddNewTaskPopup}
            >
                <Padding spacing={CRMSpacing.LARGE}>
                    <SpacingColumn spacing={CRMSpacing.LARGE}>
                        {/* BODY */}
                        <SpacingColumn spacing={CRMSpacing.MEDIUM}>
                            <CRMTitleForCard>
                                Add ad hoc task
                            </CRMTitleForCard>
                            <CRMInputLabelAndErrorWrapComponent
                                displayError={hasAddingNewTaskFailed()}
                                errorMessage="The task has failed to submit, pelase try again. If this presists contact the developers."
                            >
                                <CRMTextAreaComponent
                                    value={newTask}
                                    displayError={hasAddingNewTaskFailed()}
                                    onChange={setNewTask}
                                />
                            </CRMInputLabelAndErrorWrapComponent>
                        </SpacingColumn>
                        {/* BUTTONS */}
                        <SpacingRow spacing={CRMSpacing.TINY} justifyContent="end">
                            <CRMButtonSecondary
                                label="Cancel"
                                onClick={closeAddNewTaskPopup}
                            />
                            <CRMFormButton
                                ButtonElement={CRMButtonQuaternary}
                                formStatus={props.addNewTaskFormStatus === "untouched" && newTask.length > 0 ? "requiresSubmission" : castNewFormStatusToLegacy(props.addNewTaskFormStatus)}
                                label="Add"
                                onClick={() => props.onAddNewTask(newTask)}
                            />
                        </SpacingRow>
                    </SpacingColumn>
                </Padding>
            </CRMCardOutsidePopupBlank>
        </CRMCardOutside>
    );
};
