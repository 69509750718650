import React from "react";

type TFrontPageProps = {

};

export const FrontPage = (props: React.PropsWithChildren<TFrontPageProps>): JSX.Element => {
    return (
        <div className="front-page">
            <div className="front-page__body">
                {props.children}
            </div>
        </div>
    );
};
