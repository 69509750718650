import React from "react";
import { TTenure1 } from "../../../../../../domain/codecs/Tenure";
import { Shape } from "../../../BuildingBlocks/Shape";
import { CRMColors } from "../../../../models/CRMColors";
import { CRMSpacing } from "../../../../models/CRMSpacing";
import { FontRoboto } from "../../../BuildingBlocks/FontRoboto";
import { SpacingRow } from "../../../BuildingBlocks/SpacingRow";
import { CRMParagraph } from "../../../Simple/CRMParagraph/CRMParagraph";
import { WeightMedium } from "../../../WeightMedium/WeightMedium";
import { Text } from "../../../BuildingBlocks/Text";

export const CRMCaseTitleCheckIdentifyingSummary = (props: {
    tenure: TTenure1,
    isRegistered: boolean | null,
    titleNumber: string,
}): JSX.Element => {
    const registeredDisplayString = 
            props.isRegistered === null ? "Registration Not Set"
            : props.isRegistered ? "Registered"
            : "Unregistered"

    const SpacingDot = (): JSX.Element => <Shape width="3px" height="3px" color={CRMColors.NEUTRAL_INK} borderRadius="50%"/>;

    const canShowTitleNumber = props.titleNumber.trim() !== "";

    return <CRMParagraph>
        <WeightMedium>
            <FontRoboto>
                <Text size={13}>
                    <SpacingRow spacing={CRMSpacing.SMALL}>
                        <div>{props.tenure === "unknown" ? "TENURE NOT SET" : props.tenure.toUpperCase()}</div>
                        <SpacingDot/>
                        <div>{registeredDisplayString.toUpperCase()}</div>
                        {props.isRegistered && <SpacingDot/>}
                        {props.isRegistered && <div>{canShowTitleNumber ? props.titleNumber.toUpperCase() : "TITLE NUMBER NOT SET"}</div>}
                    </SpacingRow>
                </Text>
            </FontRoboto>
        </WeightMedium>
    </CRMParagraph>
}