import React from "react";
import { TTransactionType1 } from "../../../../../../domain/codecs/TransactionType";
import { FrontSpacing } from "../../../../models/FrontSpacing";
import { Padding } from "../../../BuildingBlocks/Padding";
import { SpacingColumn } from "../../../BuildingBlocks/SpacingColumn";
import { WeightBold } from "../../../WeightBold/WeightBold";
import { FrontBulletpoint } from "../../Simple/FrontBulletpoint/FrontBulletpoint";
import FrontParagraphSmall from "../../Simple/FrontParagraphSmall/FrontParagraphSmall";

type TFrontOnboardingCaseOverviewExplanationProps = {
    allStepsAnswered: boolean;
    anyStepsDeferred: boolean;
    transactionType: TTransactionType1;
    sailLegalQuoteFileExists: boolean;
};

export const FrontOnboardingCaseOverviewExplanation = (props: React.PropsWithChildren<TFrontOnboardingCaseOverviewExplanationProps>): JSX.Element => {

    const getTransactionTypeCopyText = () => props.transactionType === "unknown" ? "case" : props.transactionType;

    return (
        <SpacingColumn spacing={FrontSpacing.SMALL_2}>

            <FrontParagraphSmall>
                This is an overview of your <WeightBold>case journey</WeightBold>.
            </FrontParagraphSmall>
            
            {props.allStepsAnswered &&
                <FrontParagraphSmall>
                    Below you will find <WeightBold>links</WeightBold> to all {
                        props.anyStepsDeferred
                            ? "the steps you've gone through so far"
                            : "your completed steps"
                        }.
                </FrontParagraphSmall>
            }

            {!props.allStepsAnswered &&
                <>
                    <FrontParagraphSmall>
                        The <WeightBold>sections below</WeightBold> tell you:
                    </FrontParagraphSmall>
                    
                    {/* BULLET POINTS */}
                    <Padding type="left" spacing={FrontSpacing.SMALL_1}>
                        <SpacingColumn spacing={FrontSpacing.SMALL_1}>
                            <FrontBulletpoint>
                                <FrontParagraphSmall>
                                    What we need you to do to help progress your {getTransactionTypeCopyText()}.
                                </FrontParagraphSmall>
                            </FrontBulletpoint>
                            <FrontBulletpoint>
                                <FrontParagraphSmall>
                                    What you have done so far.
                                </FrontParagraphSmall>
                            </FrontBulletpoint>
                            {props.sailLegalQuoteFileExists &&
                                <FrontBulletpoint>
                                    <FrontParagraphSmall>
                                        Your conveyancing quote.
                                    </FrontParagraphSmall>
                                </FrontBulletpoint>
                            }
                        </SpacingColumn>
                    </Padding> 
                </>
            }
        </SpacingColumn>
    );
};
