import React from "react";
import { TPercentageSize, TPixelSize } from "../../models/StringLiterals";

type THiddenScrollProps = {
    width?: TPixelSize | TPercentageSize | "auto";
    height?: TPixelSize | TPercentageSize | "auto";
    maxHeight?: string;
};

export const HiddenScroll = (props: React.PropsWithChildren<THiddenScrollProps>): JSX.Element => {

    return (
        <div
            className="hidden-scroll"
            style={{
                width: props.width,
                height: props.height,
                maxHeight: props.maxHeight,
            }}
        >
            {props.children}
        </div>
    );
};
