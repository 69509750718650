import React from "react";

export class CRMTitleForCardQuicksandLower extends React.Component<React.PropsWithChildren<{}>> {
    public render (): JSX.Element {
        return (
            <span className="crm-title-for-card-quicksand-lower">
                {this.props.children}
            </span>
        );
    }
}
