import React from "react";

export const UploadButtonWrapper = <P extends Record<string, unknown>, BP extends Record<"onClick", () => unknown> & P, BC extends React.ComponentType<BP>>(props: {
    buttonComponent: BC,
    buttonProps: P,
    onUpload: (file: File) => void;
}): JSX.Element => {
    let uploadInputRef: HTMLInputElement | null = null;

    return (
        <>
            {/* HIDDEN INPUT */}
            <input
                ref={(element) => uploadInputRef = element}
                type="file"
                name="file"
                onChange={(e) => {
                    if (e.currentTarget.files && e.currentTarget.files.length) {
                        props.onUpload(e.currentTarget.files[0]);
                    }
                }}
                style={{display: "none"}}
            />

            {/* UPLOAD BUTTON */}
            {<props.buttonComponent
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                {...props.buttonProps as any}
                onClick={() => uploadInputRef?.click()}
            />}
        </>
    );
};
