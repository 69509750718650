import React, { useEffect, useState } from "react";
import { CRMColors } from "../../../models/CRMColors";
import { Border } from "../../BuildingBlocks/Border";
import { useOpenClose } from "../../../hooks/UseOpenClose";
import { CRMTextBadge, TTextBadgeProps } from "../../CRMTextBadge/CRMTextBadge";
import { CRMCardInside } from "../../CRMCardInside/CRMCardInside";

export const CRMTextBadgePopout = (props: React.PropsWithChildren<TTextBadgeProps>): JSX.Element => {
    const {
        ref,
        isOpen,
        setIsOpen,
    } = useOpenClose();

    // this helps to position the popout relative to the page
    const getRightPosition = (el: HTMLDivElement, pageBodyWidth: number) => {
        var rect = el.getBoundingClientRect();
        var elemWidth = rect.width.valueOf();
        var elemLeft = rect.left;
        var shifted = pageBodyWidth - (elemWidth + elemLeft);
        if (shifted >= 0) {
            return 0
        } else {
            return shifted -5
        }
    }

    const [rightPosition, setRightPosition] = useState<string>("");

    useEffect(() => {
        setRightPosition(`${getRightPosition(ref.current as HTMLDivElement, document.body.getBoundingClientRect().width.valueOf())}px`)
    },[]);

    return (
        <div className="crm-text-badge-popout">
            <div
                className="crm-text-badge-popout__badge-container"
                onClick={() => setIsOpen(!isOpen)}
            >
                <CRMTextBadge
                    {...props}
                />
            </div>

            <div
                className={`
                    crm-text-badge-popout__expand-container
                    crm-text-badge-popout__expand-container${isOpen ? "--visible" : "--hidden"}
                `}
                style={{ left: rightPosition }}
                ref={ref}
            >    
                <CRMCardInside>
                    {props.children}
                </CRMCardInside>
            </div>

        </div>
    );
};