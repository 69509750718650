import React from "react";
import { CRMColors } from "../../../models/CRMColors";
import { THexColor, TPercentageSize, TPixelSize } from "../../../models/StringLiterals";
import { ShineShape } from "../../BuildingBlocks/ShineShape";

type TSingleContentPlaceholderProps = {
    width?: TPixelSize | TPercentageSize;
    height?: TPixelSize | TPercentageSize;
    backgroundColor?: THexColor;
    shineColor?: THexColor;
};

export const CRMSingleContentPlaceholder = (props: TSingleContentPlaceholderProps): JSX.Element => {

    return (
        <ShineShape
            backgroundColor={props.backgroundColor || CRMColors.NEUTRAL_10} 
            shineColor={props.shineColor || CRMColors.NEUTRAL_12}
            duration={2000}
            height={props.height || "20px"}
            width={props.width || "100%"}
        />
    );
};
