import React from "react";
import { TIcon } from "../CRMIcon/CRMIcon";
import { array } from "fp-ts";
import { pipe } from "fp-ts/lib/function";
import { CRMButtonIconWithLabel } from "../CRMButtonIconWithLabel/CRMButtonIconWithLabel";

type TMultiUploadBoxProps = {
    icon?: TIcon;
    label?: string | JSX.Element;
    onFileChange: (files: Array<File>) => void;
};

export const CRMMultiUploadBox = (props: TMultiUploadBoxProps): JSX.Element => {

    const uploadBoxRef = React.createRef<HTMLDivElement>();
    const uploadInputRef = React.createRef<HTMLInputElement>();

    return (
        <div
            className={`crm-multi-upload-box`}
            ref={uploadBoxRef}
            draggable={true}
            onDrop={(event) => {
                event.preventDefault();
                
                if (event.dataTransfer.items && event.dataTransfer.items.length > 0) {
                    props.onFileChange(castDataTransferItemListToFileArray(event.dataTransfer.items));
                } else if (event.dataTransfer.files.length > 0) {
                    props.onFileChange(castFileListToFileArray(event.dataTransfer.files));
                }
            }}
            onDragEnter={(event) => event.preventDefault()}
            onDragLeave={(event) => event.preventDefault()}
            onDragOver={(event) => event.preventDefault()}
            onClick={() => uploadInputRef.current?.click()}
        >
            <CRMButtonIconWithLabel
                variant="quaternary"
                icon={props.icon || "attach"}
                onClick={() => null}
                label={typeof props.label === "undefined" ? "upload" : props.label}
            />
            <input
                ref={uploadInputRef}
                type="file"
                name="file"
                onChange={(event) => {
                    if (event.currentTarget.files && event.currentTarget.files.length > 0) {
                        props.onFileChange(castFileListToFileArray(event.currentTarget.files));
                    }
                    // On Chrome if the file is not different from the previous on change does not trigger, this sets the value back to a default value
                    // so that the file (even if the same) is always recognised as a new event
                    event.currentTarget.value = ""; 
                }}
                style={{display: "none"}}
                multiple
            />
        </div>
    );
};

const castFileListToFileArray = (fileList: FileList): Array<File> =>
    Array.from(fileList);

const castDataTransferItemListToFileArray = (items: DataTransferItemList): Array<File> =>
    pipe(
        Array.from(items),
        array.filter((item) => item.kind === "file"),
        array.map((item) => item.getAsFile() as File)
    )