import React, { useState } from "react";
import { TSimpleLegalEmailWithPersonsForTriageForm, TTriageBulkEmailMarkAsIrrelevantForm, TTriageForm, TTriageSimpleEmailForm } from "../../../../../domain/codecs/form/TriageForm";
import { SpacingColumn } from "../../BuildingBlocks/SpacingColumn";
import { CRMEmailRow } from "../CRMEmailRow/CRMEmailRow";
import { useMultiSelect } from "../../../hooks/useMultiSelect";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { FontQuicksand } from "../../BuildingBlocks/FontQuicksand";
import { FontSize } from "../../BuildingBlocks/FontSize";
import { WeightSemiBold } from "../../WeightSemiBold/WeightSemiBold";
import { CRMColors } from "../../../models/CRMColors";
import { CRMFontSizes } from "../../../models/CRMFontSizes";
import { SpacingRow } from "../../BuildingBlocks/SpacingRow";
import { CRMInputCheckboxComponent } from "../../CRMInputCheckboxComponent/CRMInputCheckboxComponent";
import { Background } from "../../BuildingBlocks/Background";
import { CRMThinColoredButton } from "../../Simple/CRMThinColoredButton/CRMThinColoredButton";
import { isAFunction } from "../../../functions/functions";
import { AnimationPopout } from "../../BuildingBlocks/AnimationPopout";
import { CRMConfirmationCountdown } from "../CRMConfirmationCountdown/CRMConfirmationCountdown";
import { Relative } from "../../BuildingBlocks/Relative";
import { CRMTableContentPlaceholder } from "../../Simple/CRMTableContentPlaceholder/CRMTableContentPlaceholder";
import { CRMEmptyPlaceholder } from "../../Simple/CRMEmptyPlaceholder/CRMEmptyPlaceholder";
import { CRMBlockPopover } from "../../CRM/CRMBlock/CRMBlockPopover/CRMBlockPopover";
import { CRMEmailCard } from "../CRMEmailCard/CRMEmailCard";
import { CRMBottomControlBar } from "../../CRMBottomControlBar/CRMBottomControlBar";
import { CRMIcon } from "../../CRMIcon/CRMIcon";
import { CRMFlatInputGeneral } from "../../CRMFlatInputGeneral/CRMFlatInputGeneral";
import { MinHeight } from "../../BuildingBlocks/MinHeight";


type TFunction = () => void;
type TAction = (emailForm: TTriageSimpleEmailForm) => void;
type TOnBulkArchive = (form: TTriageBulkEmailMarkAsIrrelevantForm) => void; 
type TInboxProps = {
    title?: string;
    mode?: "card" | "row";
    isShowingSelectAll?: boolean;
    form: TTriageForm;
    onOpenEmail: TAction;
    onBulkArchive?: (form: TTriageBulkEmailMarkAsIrrelevantForm) => void;
    onLoadMore?: () => void;
    onSearchTermChange?: (value: string) => void;
};

export const CRMInboxTable = (props: React.PropsWithChildren<TInboxProps>): JSX.Element => {

    const [isBulkArchiveOpen, setIsBulkArchiveOpen] = useState(false);
    const [isSingleArchiveOpen, setIsSingleArchiveOpen] = useState(false);
    const [singleActionEmail, setSingleActionEmail] = useState<TTriageSimpleEmailForm | null>(null);

    const multiSelectState = useMultiSelect<TTriageSimpleEmailForm>({
        options: props.form.children.emails,
        converter: (email) => email.original.id, 
    });

    const onHybirdActionClick = 
        (onBulkAction: TFunction, onSingleAction: TAction) => 
            (emailForm: TTriageSimpleEmailForm) => {
                if (multiSelectState.isSelected(emailForm.original.id) && multiSelectState.values.length > 1) {
                    onBulkAction();
                } else {
                    onSingleAction(emailForm);
                }
            }

    const onBulkArchive = (form: TTriageBulkEmailMarkAsIrrelevantForm) => {
        if (isAFunction<TOnBulkArchive>(props.onBulkArchive)) {
            props.onBulkArchive(form);
        }
    }
    
    // SINGLE ARCHIVE
    const onStartSingleArchive: TAction = (emailForm: TTriageSimpleEmailForm) => {
        setIsSingleArchiveOpen(true);
        setSingleActionEmail(emailForm)
    }
    const onCloseSingleArchive = () => {
        setIsSingleArchiveOpen(false);
        setSingleActionEmail(null);
    }
    const onCompleteSingleArchive = () => {
        onBulkArchive({
            ...props.form.children.bulk_mark_as_irrelevant_form,
            status: "submitting",
            edited: {
                ids: [singleActionEmail?.original.id as string]
            }
        });
        setSingleActionEmail(null);
        onCloseSingleArchive();
    }

    // BULK ARCHIVE
    const onStartBulkArchive = () => setIsBulkArchiveOpen(true);
    const onCloseBulkArchive = () => setIsBulkArchiveOpen(false);
    const onCompleteBulkArchive = () => {
        onBulkArchive({
            ...props.form.children.bulk_mark_as_irrelevant_form,
            status: "submitting",
            edited: {
                ids: multiSelectState.values
            }
        });
        multiSelectState.clearSelection();
        onCloseBulkArchive();
    }

    // CONDITIONALS
    const isShowingHeader = () => !!props.title || props.isShowingSelectAll;
    
    const isShowingLoadMore = () => (
        isAFunction(props.onLoadMore)
        && props.form.children.counts.available_count > props.form.children.emails.length
        && !isLoading()
    );
    
    const isLoading = () => (
        props.form.status === "loading"
        || props.form.status === "submitting"
    );

    const isEmpty = () => props.form.children.emails.length === 0;

    // ACTIONS
    const onArchive = (email: TTriageSimpleEmailForm) =>
        isAFunction<TOnBulkArchive>(props.onBulkArchive) ?
            () => onHybirdActionClick(onStartBulkArchive, onStartSingleArchive)(email)
            : undefined
    ;
    
    const onSelect = (email: TTriageSimpleEmailForm) =>
        isAFunction<TOnBulkArchive>(props.onBulkArchive) ?
            () => multiSelectState.toggleValue(email.original.id)
            : undefined
    ;

    return (
        <Relative>
            <MinHeight height="720px">
                <SpacingColumn spacing={CRMSpacing.MEDIUM}>
                    {/* HEADER */}
                    {isShowingHeader() &&
                        <SpacingRow justifyContent="space-between">
                            {props.title &&
                                <FontQuicksand>
                                    <FontSize size={CRMFontSizes.MED}>
                                        <WeightSemiBold>
                                            {props.title}
                                        </WeightSemiBold>
                                    </FontSize>
                                </FontQuicksand>
                            }

                            {props.isShowingSelectAll &&
                                <Background
                                    padding={`${CRMSpacing.TINY} ${CRMSpacing.MEDIUM}`}
                                    color={CRMColors.NEUTRAL_PAPER}
                                    borderRadius={`0px 5px 0px 0px`}
                                >
                                    <CRMInputCheckboxComponent
                                        checked={multiSelectState.isAllSelected()}
                                        cursor="pointer"
                                        size="medium"
                                        onClick={multiSelectState.toggleSelectAll}
                                    />
                                </Background>
                            }
                        </SpacingRow>
                    }
                    
                    {/* BODY */}
                    <SpacingColumn spacing={CRMSpacing.LARGE}>
                        {/* EMAILS */}
                        <SpacingColumn>
                            {/* MODE - CARDS */}
                            {props.mode === "card" && !isLoading() && !isEmpty() &&
                                <SpacingColumn spacing={CRMSpacing.TINY}>
                                    {props.form.children.emails.map((email, index) => (
                                        <CRMEmailCard
                                            key={index}
                                            email={email as unknown as TSimpleLegalEmailWithPersonsForTriageForm}
                                            searchTerm={props.form.edited.search_term || ""}
                                            onClick={() => props.onOpenEmail(email)}
                                            isSelected={multiSelectState.isSelected(email.original.id)}
                                            onSelect={onSelect(email)}
                                            onArchive={onArchive(email)}
                                        />
                                    ))}
                                </SpacingColumn>
                            }
                            
                            {/* MODE - ROWS */}
                            {props.mode !== "card" && !isLoading() && !isEmpty() && 
                                props.form.children.emails.map((email, index) => (
                                    <CRMEmailRow
                                        key={index}
                                        email={email}
                                        onClick={() => props.onOpenEmail(email)}
                                        isSelected={multiSelectState.isSelected(email.original.id)}
                                        onSelect={onSelect(email)}
                                        onArchive={onArchive(email)}
                                    />
                                ))
                            }

                            {/* LOADING BAR */}
                            {(isLoading() && isEmpty()) && 
                                <CRMTableContentPlaceholder
                                    rowCount={props.form.children.emails.length}
                                />
                            }

                            {/* EMPTY */}
                            {(!isLoading() && isEmpty()) &&
                                <Background padding={CRMSpacing.LARGE}>
                                    <CRMEmptyPlaceholder
                                        iconSize="70px"
                                        textGap={CRMSpacing.TINY}
                                    >
                                        No emails found.
                                    </CRMEmptyPlaceholder>
                                </Background>
                            }
                        </SpacingColumn>

                        {/* LOAD MORE */}
                        {isShowingLoadMore() &&
                            <AnimationPopout>
                                <Background padding={`0px 0px ${CRMSpacing.LARGE} 0px`}>
                                    <SpacingRow justifyContent="center">
                                        <CRMThinColoredButton
                                            backgroundColor={CRMColors.NEUTRAL_2}
                                            fontColor={CRMColors.NEUTRAL_12}
                                            label={<WeightSemiBold>See more</WeightSemiBold>}
                                            onClick={props.onLoadMore as () => void}
                                            icon="down-arrow"
                                        />
                                    </SpacingRow>
                                </Background>
                            </AnimationPopout>
                        }
                    </SpacingColumn>
                </SpacingColumn>
            </MinHeight>
            
            {/* CONFIRMATION POPCARD - SINGLE ARCHIVE */}
            {isSingleArchiveOpen &&
                <CRMBlockPopover>
                    <CRMConfirmationCountdown
                        title={`Archiving email!`}
                        iconName="archive"
                        primaryButtonMode="next"
                        onUndo={onCloseSingleArchive}
                        onComplete={onCompleteSingleArchive}
                    />
                </CRMBlockPopover>
            }
            
            {/* CONFIRMATION POPUP - BULK ARCHIVE */}
            {isBulkArchiveOpen &&
                <CRMBlockPopover>
                    <CRMConfirmationCountdown
                        title={`Archiving ${multiSelectState.values.length} emails!`}
                        iconName="archive"
                        primaryButtonMode="next"
                        onUndo={onCloseBulkArchive}
                        onComplete={onCompleteBulkArchive}
                    />
                </CRMBlockPopover>
            }

            {/* SEARCH INPUT */}
            {props.onSearchTermChange &&
                <CRMBottomControlBar>
                    <Background
                        width="100%" 
                        padding={`${CRMSpacing.TINY} ${CRMSpacing.MEDIUM}`}
                    >
                        <SpacingRow
                            spacing={CRMSpacing.TINY}
                            alignItems="center"
                            childSize="50% auto"
                        >
                            {/* SEARCH */}
                            <CRMFlatInputGeneral
                                value={props.form.edited.search_term || ""}
                                onChange={props.onSearchTermChange}
                                displayError={false}
                                placeholder="Search for an email"
                            />

                            {/* CLEAR SEARCH */}
                            <CRMIcon
                                iconName="backspace"
                                colour={props.form.edited.search_term ? "neutral-ink" : "neutral-4"}
                                size="small"
                                onClick={() =>
                                    props.onSearchTermChange
                                        ? props.onSearchTermChange("")
                                        : undefined
                                }
                            />
                        </SpacingRow>
                    </Background>
                </CRMBottomControlBar>
            }
        </Relative>
    );
};
