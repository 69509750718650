import React from "react";
import { TListingFull1Editable } from "../../../../domain/codecs/formEditable/ListingEditable";
import { TListingDataFormIO } from "../../../../domain/codecs/formIO/ListingDataFormIO";
import { ListingKeyManagement3, ListingKeyManagement1, ListingKeyManagement2, ListingKeynestDetails, ListingKeysafeDetails } from "../../../../domain/codecs/Listing";
import { CRMAutosaveIndicatorWrap } from "../../components/CRM/CRMAutosaveIndicatorWrap/CRMAutosaveIndicatorWrap";
import { CRMCodecEditForm } from "../../components/CRMCodecEditForm/CRMCodecEditForm";
import { CRMDropdownComponent } from "../../components/CRMDropdownComponent/CRMDropdownComponent";
import CRMInputLabelAndErrorWrapComponent from "../../components/CRMInputLabelAndErrorWrapComponent/CRMInputLabelAndErrorWrapComponent";
import { CRMLink } from "../../components/CRMLink/CRMLink";
import { CRMSpacingColumn } from "../../components/CRMSpacingColumn/CRMSpacingColumn";
import { CRMTitleSection } from "../../components/CRMTitleSection/CRMTitleSection";
import { WeightBold } from "../../components/WeightBold/WeightBold";

type TCRMListingKeyManagementFormProps = {
    listingPageData: TListingDataFormIO;
    onChange: (listingForm: TListingFull1Editable) => void;
};

const CRMListingKeyManagementForm = (props: TCRMListingKeyManagementFormProps): JSX.Element => {
    return (
        <CRMAutosaveIndicatorWrap status={props.listingPageData.output.form.status}>
            <CRMSpacingColumn spacing="large">
                <CRMTitleSection>
                    Key Management
                </CRMTitleSection>
                <CRMCodecEditForm
                    codec={ListingKeyManagement1}
                    model={props.listingPageData.output.form.edited}
                    validationErrors={props.listingPageData.output.form.validationErrors}
                    onChange={(edited) => props.onChange({
                        ...props.listingPageData.output.form,
                        edited: {
                            ...props.listingPageData.output.form.edited,
                            ...edited,
                        }
                    })}
                />
                {
                    ! props.listingPageData.output.form.edited.keys_delivered &&
                        <CRMCodecEditForm
                            codec={ListingKeyManagement2}
                            model={props.listingPageData.output.form.edited}
                            validationErrors={props.listingPageData.output.form.validationErrors}
                            onChange={(edited) => props.onChange({
                                ...props.listingPageData.output.form,
                                edited: {
                                    ...props.listingPageData.output.form.edited,
                                    ...edited,
                                }
                            })}
                        />
                }
                <CRMCodecEditForm
                    codec={ListingKeyManagement3}
                    model={props.listingPageData.output.form.edited}
                    validationErrors={props.listingPageData.output.form.validationErrors}
                    onChange={(edited) => props.onChange({
                        ...props.listingPageData.output.form,
                        edited: {
                            ...props.listingPageData.output.form.edited,
                            ...edited,
                        }
                    })}
                />
                { props.listingPageData.output.form.edited.key_holder === "designated_person" &&
                    <CRMSpacingColumn spacing="large">
                        <CRMLink
                            href={`/crm/listing/${props.listingPageData.input.listing_id}?visible_tab=seller_details&visible_sections=`}
                            target="_blank"
                            linkStyle="normal"
                        >
                            <WeightBold>Add seller party member</WeightBold>
                        </CRMLink>
                        <CRMInputLabelAndErrorWrapComponent label="Key holder designated person">
                            <CRMDropdownComponent<string, string>
                                displayError={false}
                                placeholder="Select option"
                                options={
                                    [
                                        ...[{
                                            value: "unknown",
                                            label: "Unknown"
                                        }],
                                        ...props.listingPageData.output.read_only.all_seller_party_users_dropdown.map((user) => ({
                                            value: user.id,
                                            label: `${user.first_name} ${user.last_name} ${user.is_legal_seller ? "(Legal seller)" : ""}`
                                        }))
                                    ]
                                }
                                value={props.listingPageData.output.form.edited.key_holder_user_id || "unknown"}
                                onChange={(val) => props.onChange({
                                    ...props.listingPageData.output.form,
                                    edited: {
                                        ...props.listingPageData.output.form.edited,
                                        ...{
                                            key_holder_user_id: val === "unknown" ? null : val
                                        },
                                    }
                                })}
                            />
                        </CRMInputLabelAndErrorWrapComponent>
                    </CRMSpacingColumn>
                }
                { props.listingPageData.output.form.edited.key_holder === "occupier" &&
                    <CRMSpacingColumn spacing="large">
                        <CRMLink
                            href={`/crm/listing/${props.listingPageData.input.listing_id}?visible_tab=seller_details&visible_sections=`}
                            target="_blank"
                            linkStyle="normal"
                        >
                            <WeightBold>Add seller party member</WeightBold>
                        </CRMLink>
                        <CRMInputLabelAndErrorWrapComponent label="Key holding occupier">
                            <CRMDropdownComponent<string, string>
                                displayError={false}
                                placeholder="Select option"
                                options={
                                    [
                                        ...[{
                                            value: "unknown",
                                            label: "Unknown"
                                        }],
                                        ...props.listingPageData.output.read_only.all_seller_party_users_dropdown.map((user) => ({
                                            value: user.id,
                                            label: `${user.first_name} ${user.last_name} ${user.is_legal_seller ? "(Legal seller)" : ""}`
                                        }))
                                    ]
                                }
                                value={props.listingPageData.output.form.edited.occupier_user_id || "unknown"}
                                onChange={(val) => props.onChange({
                                    ...props.listingPageData.output.form,
                                    edited: {
                                        ...props.listingPageData.output.form.edited,
                                        ...{
                                            occupier_user_id: val === "unknown" ? null : val
                                        },
                                    }
                                })}
                            />
                        </CRMInputLabelAndErrorWrapComponent>
                    </CRMSpacingColumn>
                }
                { props.listingPageData.output.form.edited.key_holder === "keysafe" &&
                    <CRMCodecEditForm
                        codec={ListingKeysafeDetails}
                        model={props.listingPageData.output.form.edited}
                        validationErrors={props.listingPageData.output.form.validationErrors}
                        onChange={(edited) => props.onChange({
                            ...props.listingPageData.output.form,
                            edited: {
                                ...props.listingPageData.output.form.edited,
                                ...edited,
                            }
                        })}
                    />
                }
                { props.listingPageData.output.form.edited.key_holder === "keynest" &&
                    <CRMCodecEditForm
                        codec={ListingKeynestDetails}
                        model={props.listingPageData.output.form.edited}
                        validationErrors={props.listingPageData.output.form.validationErrors}
                        onChange={(edited) => props.onChange({
                            ...props.listingPageData.output.form,
                            edited: {
                                ...props.listingPageData.output.form.edited,
                                ...edited,
                            }
                        })}
                    />
                }
            </CRMSpacingColumn>
        </CRMAutosaveIndicatorWrap>
    );
};


export default CRMListingKeyManagementForm;
