import React from "react";
import { FrontColors } from "../../../../models/FrontColors";
import { FrontSpacing } from "../../../../models/FrontSpacing";
import { Padding } from "../../../BuildingBlocks/Padding";
import { SpacingRow } from "../../../BuildingBlocks/SpacingRow";
import { TextColor } from "../../../BuildingBlocks/TextColor";
import { WeightMedium } from "../../../WeightMedium/WeightMedium";
import { FrontIcon } from "../../../Front/Simple/FrontIcon/FrontIcon";
import { Background } from "../../../BuildingBlocks/Background";
import { CRMColors } from "../../../../models/CRMColors";
import { CursorPointer } from "../../../CursorPointer/CursorPointer";
import FrontParagraphSmall from "../../../Front/Simple/FrontParagraphSmall/FrontParagraphSmall";

type TOnboardingTabButtonProps = {
    label: JSX.Element;
    onClick: () => void;
};

export const FPButton = (props: React.PropsWithChildren<TOnboardingTabButtonProps>): JSX.Element =>
    <CursorPointer onClick={props.onClick}>
        <Background
            color={CRMColors.PRIMARY_0}
            borderRadius="5px"
            padding={`${FrontSpacing.SMALL_2} ${FrontSpacing.SMALL_3} ${FrontSpacing.SMALL_2} ${FrontSpacing.MEDIUM_1}`}
        >
            <SpacingRow 
                justifyContent="space-between"
                spacing={FrontSpacing.SMALL_3}
            >
                {/* LABEL TEXT */}
                <FrontParagraphSmall>
                    <WeightMedium>
                        <TextColor color={FrontColors.NEUTRAL_18}>
                            {props.label}
                        </TextColor>
                    </WeightMedium>
                </FrontParagraphSmall>
                
                {/* ICON */}
                <Padding type="top" spacing="4px">
                    <FrontIcon
                        iconName="chevron-right"
                        colour="neutral-18"
                        size="small"
                    />
                </Padding>
            </SpacingRow>
        </Background>
    </CursorPointer>