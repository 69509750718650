import React, { useEffect, useState } from "react";
import * as TFormModels from "../../models/TFormModels";
import { array, record } from "fp-ts";
import * as CaseDocumentType1 from "../../../../domain/models/CaseDocumentType1";
import { DateTime } from "luxon";
import * as ISODateTime from "../../../../domain/models/ISODateTime";
import { CRMFormStatusBadge } from "../CRMFormStatusBadge/CRMFormStatusBadge";
import * as TFormStatus from "../../models/TFormStatus";
import { CRMCardOutsidePopupBasic } from "../CRMCardOutsidePopupBasic/CRMCardOutsidePopupBasic";
import { CRMParagraph } from "../Simple/CRMParagraph/CRMParagraph";
import { CRMCardOutsideLabelled } from "../CRMCardOutsideLabelled/CRMCardOutsideLabelled";
import CRMInputLabelAndErrorWrapComponent from "../CRMInputLabelAndErrorWrapComponent/CRMInputLabelAndErrorWrapComponent";
import CRMInputGeneralComponent from "../CRMInputs/CRMInputGeneralComponent/CRMInputGeneralComponent";
import { pipe } from "fp-ts/lib/function";
import { SpacingColumn } from "../BuildingBlocks/SpacingColumn";
import { enumToIDropdownOptions } from "../../functions/enumToIDropdownOptions";
import { CRMCaseDocumentCardFace } from "../Complex/CRMCaseDocumentCardFace/CRMCaseDocumentCardFace";
import { TCRMMainColours } from "../../models/TCRMMainColours";
import { CRMCaseDocumentCardBottom } from "../Complex/CRMCaseDocumentCardBottom/CRMCaseDocumentCardBottom";
import { CRMDragUploadOverlayWrap } from "../Complex/CRMDragUploadOverlayWrap/CRMDragUploadOverlayWrap";
import { Absolute } from "../BuildingBlocks/Absolute";
import { CRMInputCheckboxComponent } from "../CRMInputCheckboxComponent/CRMInputCheckboxComponent";
import { CRMFilterDropdown } from "../Simple/CRMFilterDropdown/CRMFilterDropdown";

type TCRMCaseDocumentCardProps = {
    documentForm: TFormModels.TCaseDocument6AndCaseDocumentForm1FormList["forms"][number];
    filesForms: TFormModels.TCaseDocumentFile4AndCaseDocumentFileForm1FormList["forms"];
    activeUploads: Record<string, "in_progress" | "complete" | "error">;
    onDueDateChange: (isoDate: string) => void;
    onValidUntilChange: (isoDate: string | null) => void;
    onReceivedDateChange: (isoDate: string | null) => void;
    onFileNameChange: (fileId: string, value: string) => void;
    onFileDelete: (fileId: string) => void;
    onNewFileUploadChange: (files: File[]) => void;
    onDeleteDocument: () => void;
    onDocumentAdditionalNameChange: (value: string) => void;
    onDocumentTypeChange: (value: CaseDocumentType1.T) => void;
    onDocumentFromExternalSourceChange: (value: boolean) => void;
    onDocumentSharedWithClientChange: (value: boolean) => void;
    onDocumentSharedWithSolicitorChange: (value: boolean) => void;
};

export const CRMCaseDocumentCard = (props: TCRMCaseDocumentCardProps): JSX.Element => {
    
    const [deleteDocumentPopupIsOpenState, setDeleteDocumentPopupIsOpenState] = useState<boolean>(false);
    const [editDocumentPopupIsOpenState, setEditDocumentPopupIsOpenState] = useState<boolean>(false);
    const [documentAdditionalNameState, setDocumentAdditionalNameState] = useState<string>(props.documentForm.edit.additional_name);
    const [documentFromExternalSourceState, setDocumentFromExternalSourceState] = useState<boolean>(props.documentForm.edit.from_external_source);
    const [documentTypeState, setDocumentTypeState] = useState<CaseDocumentType1.T>(props.documentForm.edit.type);

    useEffect(() => {
        setDocumentAdditionalNameState(props.documentForm.edit.additional_name);
    }, [props.documentForm.edit.additional_name]);

    useEffect(() => {
        setDocumentTypeState(props.documentForm.edit.type);
    }, [props.documentForm.edit.type]);

    const saveEditDocument = (): void => {
        props.onDocumentAdditionalNameChange(documentAdditionalNameState);
        props.onDocumentTypeChange(documentTypeState);
        props.onDocumentFromExternalSourceChange(documentFromExternalSourceState)
        setEditDocumentPopupIsOpenState(false);
    };

    const allFilesReceived = (): boolean =>
        props.documentForm.edit.received_date !== null
        && props.filesForms.length > 0;

    const onDeleteDocument = (): void => {
        setDeleteDocumentPopupIsOpenState(false);
        props.onDeleteDocument();
    };

    const isDocumentExpired = (): boolean =>
        props.documentForm.edit.valid_until
        ? ISODateTime.getNumberOfDaysSinceDate(DateTime.fromISO(props.documentForm.edit.valid_until).toJSDate()) > 0
        : false;

    const isDocumentOverdue = (): boolean =>
        new Date(props.documentForm.edit.due_date).getTime() < (new Date).getTime();

    const isUploadAllowed = (): boolean => !allFilesReceived()
        && pipe(
            props.activeUploads,
            record.toArray,
            array.filter(([, status]) => status === "in_progress")
        ).length === 0;

    const getFormStatusToShow = (): TFormStatus.T =>
        TFormStatus.reduceToHighestPriority([
            props.documentForm.status,
            ... pipe(
                props.filesForms,
                array.map((form) => form.status),
            ),
        ]);

    const alertOnlyOneFile = (): void => alert("Only one file can be uploaded at a time.");

    const getLabelColourBasedOnCardState = (): TCRMMainColours => {
        if (isDocumentExpired()) {
            return "neutral-6";
        }

        if (allFilesReceived()) {
            return "highlights-positive-green-2";
        }
        
        if (isDocumentOverdue()) {
            return "highlights-instructional-brick-0";
        }

        return "highlights-pertinent-yellow-2";
    }

    return (
        <div>
            {/* CARD */}
            <CRMDragUploadOverlayWrap
                canUpload={isUploadAllowed()}
                onNewFileUploadChange={props.onNewFileUploadChange}
                onCanOnlyUploadOneFile={() => alertOnlyOneFile()}
            >
                {/* CARD - TOP */}
                <CRMCardOutsideLabelled
                    labelColor={getLabelColourBasedOnCardState()}
                    backgroundColour={isDocumentExpired() ? "neutral-12" : undefined}
                    shadow={false}
                >
                    {/* FORM STATUS BADGE */}
                    {getFormStatusToShow() !== TFormStatus.constants.UNTOUCHED && 
                        <Absolute left="-10px" top="-10px">
                            <CRMFormStatusBadge
                                formStatus={getFormStatusToShow()}
                            />
                        </Absolute>
                    }

                    {/* CARD FACE */}
                    <CRMCaseDocumentCardFace
                        documentForm={props.documentForm}
                        filesForms={props.filesForms}
                        onDueDateChange={props.onDueDateChange}
                        onValidUntilChange={props.onValidUntilChange}
                        onEdit={() => setEditDocumentPopupIsOpenState(true)}
                        onDelete={() => setDeleteDocumentPopupIsOpenState(true)}
                    />
                </CRMCardOutsideLabelled>
                
                {/* CARD - BOTTOM */}
                <CRMCaseDocumentCardBottom
                    activeUploads={props.activeUploads}
                    documentForm={props.documentForm}
                    filesForms={props.filesForms}
                    onReceivedDateChange={props.onReceivedDateChange}
                    onFileNameChange={props.onFileNameChange}
                    onNewFileUploadChange={props.onNewFileUploadChange}
                    onFileDelete={props.onFileDelete}
                    onSharedWithClientChange={props.onDocumentSharedWithClientChange}
                    onSharedWithSolicitorChange={props.onDocumentSharedWithSolicitorChange}
                />
            </CRMDragUploadOverlayWrap>
            
            {/* POPUP - DELETE DOCUMENT */}
            <CRMCardOutsidePopupBasic
                isOpen={deleteDocumentPopupIsOpenState}
                title="Are you sure?"
                context="important"
                onClose={() => setDeleteDocumentPopupIsOpenState(false)}
                closeText="Cancel"
                onCTA={onDeleteDocument}
                ctaText="Yes, delete this document"
            >
                <CRMParagraph>
                    Are you sure you want to delete this document?
                </CRMParagraph>
            </CRMCardOutsidePopupBasic>

            {/* POPUP - EDIT */}
            <CRMCardOutsidePopupBasic
                isOpen={editDocumentPopupIsOpenState}
                title="Edit document"
                context="neutral"
                onClose={() => {
                    setDocumentAdditionalNameState(props.documentForm.edit.additional_name);
                    setEditDocumentPopupIsOpenState(false);
                }}
                closeText="Cancel"
                onCTA={saveEditDocument}
                ctaText="Save"
            >
                <SpacingColumn spacing="medium">
                    <CRMInputLabelAndErrorWrapComponent
                        label="Document type"
                    >
                        <CRMFilterDropdown
                            placeholder="Select document type"
                            value={documentTypeState}
                            options={enumToIDropdownOptions(
                                CaseDocumentType1.values,
                                CaseDocumentType1.toDisplayString
                            )}
                            onChange={setDocumentTypeState}
                        />
                    </CRMInputLabelAndErrorWrapComponent>

                    <CRMInputLabelAndErrorWrapComponent
                        label="Additional name"
                        displayError={false}
                        errorMessage=""
                    >
                        <CRMInputGeneralComponent
                            inputType="text"
                            value={documentAdditionalNameState}
                            onChange={setDocumentAdditionalNameState}
                            displayError={false}
                            onPressEnterKey={saveEditDocument}
                        />
                    </CRMInputLabelAndErrorWrapComponent>

                    <CRMInputCheckboxComponent
                        label="From External Source"
                        checked={documentFromExternalSourceState}
                        onClick={() =>
                            setDocumentFromExternalSourceState(!documentFromExternalSourceState)
                        }
                    />
                </SpacingColumn>
            </CRMCardOutsidePopupBasic>
        </div>
    );
};
