import React from "react";
import { SpacingColumn } from "../../../BuildingBlocks/SpacingColumn";
import { WeightBold } from "../../../WeightBold/WeightBold";
import { FrontParagraphSmall } from "../../Simple/FrontParagraphSmall/FrontParagraphSmall";

type TFormSummaryProps = {
    details: Array<TFormSummaryItem>;
};

export type TFormSummaryItem = { 
    label?: string; 
    text: JSX.Element;
    canShow?: boolean;
};

export const FrontFormSummary = (props: React.PropsWithChildren<TFormSummaryProps>): JSX.Element => {

    return (
        <SpacingColumn>
            {props.details
                .filter((detail) => detail.canShow !== undefined ? detail.canShow : true)
                .map(({label, text}, index) => (
                    <FrontParagraphSmall key={index}>
                        {label && <WeightBold>{label}:</WeightBold>} {text} 
                    </FrontParagraphSmall>
                ))
            }
        </SpacingColumn>
    );
};
