import { pipe } from "fp-ts/lib/function";
import { array, option } from "fp-ts";
import { TBlockParams, TBlockType } from "./routerTypes";
import { Routes } from "./routerRoutes";
import { getRouteBlockData } from "./getRouteBlockData";
import { createChangeRouteAction } from "./createChangeRouteAction";

export const updateRouteWithBlockChange = <T extends TBlockType>(
    blockType: T, 
    blockIndex: number, 
    updateCallback?: (block: TBlockParams<T>) => TBlockParams<T>,
) => 
    (routes: Routes) => pipe(
        routes,
        getRouteBlockData,
        option.chain(({ blocksOpened, blocksCodec }) => pipe(
            blocksCodec.payload.codec.payload,
            array.findFirst((codec) => codec.payload.type.payload === blockType),
            option.chain((codec) => option.some(codec.newDefault())),
            option.chain((newDefaultForNewBlock) => pipe(
                blocksOpened,
                array.updateAt(
                    blockIndex,
                    typeof updateCallback === "function" ?
                        updateCallback(newDefaultForNewBlock as TBlockParams<T>)
                        : newDefaultForNewBlock
                )
            )),
        )),
        option.chain((newBlocks) => 
            option.some(createChangeRouteAction(
                routes.active,
                routes.params,
                routes.queryParams[routes.active],
                newBlocks
            ))
        )
    )
;