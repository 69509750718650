import React, { useEffect, useState } from "react";

type TUseCountdownProgressHook = {
    startingAmount: number;
    durationInMs: number;
    framesPerSecond: number;
    isPaused: boolean;
    onCountdownComplete?: () => void;
};

export const useCountdown = (props: TUseCountdownProgressHook) => {

    const timeBetweenFramesInMs = 1000/props.framesPerSecond;
    const progressAmountSubtractedPerFrame = Math.round(
        props.startingAmount / 
        (props.durationInMs / timeBetweenFramesInMs)
    );
    const [progress, setProgress] = useState(props.startingAmount);
    const [timeoutId, setTimeoutId] = useState<number | null>(null);

    useEffect(
        () => {
            if (props.isPaused === false && progress > 0 ) {
                setTimeoutId(
                    window.setTimeout(
                        () => setProgress(progress - progressAmountSubtractedPerFrame),
                        timeBetweenFramesInMs
                    ),
                );
            } 
            
            else if (props.isPaused === false && progress < 0) {
                setProgress(0);
            }

            else if (
                props.isPaused === false
                && progress === 0
                && typeof props.onCountdownComplete === "function"
            ) {
                props.onCountdownComplete();
            }

            return function cleanup() {
                if (timeoutId !== null) {
                    window.clearTimeout(timeoutId);
                }
            };
        },
        [props.isPaused, progress]
    );

    return {
        progress
    };
}
