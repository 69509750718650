import React from "react";
import * as PartyMovingReason from "../../../../domain/models/PartyMovingReason";
import * as PartyPurchasingSituation from "../../../../domain/models/PartyPurchasingSituation";
import * as PartySellingPropertyState from "../../../../domain/models/PartySellingPropertyState";
import { enumToIDropdownOptions } from "../../functions/enumToIDropdownOptions";
import { LabelWrapForInputs } from "../../components/OldFront/Simple/Label/LabelWrapForInputs";
import { Dropdown } from "../../components/DropdownComponent/Dropdown";
import { FormBody, FormRow } from "../../components/FormTemplateBlocksComponents/FormTemplateBlocks";
import FormButton from "../../components/FormButtonComponent/FormButton";
import { InputTextArea } from "../../components/OldFront/Simple/TextArea/InputTextArea";
import { makeFormUpdateAction } from "../../state/applyActions";
import { TParty2AndC4Form } from "../../models/TFormModels";
import { TContainerStateProps } from "../../state/TContainerStateProps";

class CRMPartySituation extends React.Component<React.PropsWithChildren<TContainerStateProps>> {
    public render(): JSX.Element {
        const formState = this.props.state.activeData.crm.party.situationForm;
        return (
            <FormBody>
                {/* SITUATION NOTES */}
                <FormRow>
                    <LabelWrapForInputs label="Situation Notes">
                        <InputTextArea
                            value={formState.edit.situation_notes}
                            onChange={(value) => this.props.dispatch(
                                makeFormUpdateAction<"CRM_PARTY_SITUATION_INPUT_CHANGED", TParty2AndC4Form["edit"], "situation_notes">(
                                    "CRM_PARTY_SITUATION_INPUT_CHANGED",
                                    "situation_notes",
                                    value
                                )
                            )}
                            onPressEnterKey={() => undefined}
                        />
                    </LabelWrapForInputs>
                </FormRow>
                {/* MOVING REASON */}
                <FormRow>
                    <LabelWrapForInputs label={`Moving Reason:`}>
                        <Dropdown
                            options={enumToIDropdownOptions(
                                PartyMovingReason.values,
                                PartyMovingReason.fromValueToDisplayName
                            )}
                            value={formState.edit.moving_reason}
                            onChange={(value) => this.props.dispatch(
                                makeFormUpdateAction<"CRM_PARTY_SITUATION_INPUT_CHANGED", TParty2AndC4Form["edit"], "moving_reason">(
                                    "CRM_PARTY_SITUATION_INPUT_CHANGED",
                                    "moving_reason",
                                    value
                                )
                            )}
                        />
                    </LabelWrapForInputs>
                </FormRow>
                {/* SELLING PROPERTY */}
                <FormRow>
                    <LabelWrapForInputs label={`Selling Property:`}>
                        <Dropdown
                            options={enumToIDropdownOptions(
                                PartySellingPropertyState.values,
                                PartySellingPropertyState.fromValueToDisplayName
                            )}
                            value={formState.edit.selling_property_state}
                            onChange={(value) => this.props.dispatch(
                                makeFormUpdateAction<"CRM_PARTY_SITUATION_INPUT_CHANGED", TParty2AndC4Form["edit"], "selling_property_state">(
                                    "CRM_PARTY_SITUATION_INPUT_CHANGED",
                                    "selling_property_state",
                                    value
                                )
                            )}
                        />
                    </LabelWrapForInputs>
                </FormRow>
                {/* PURCHASING SITUATION */}
                <FormRow>
                    <LabelWrapForInputs label={`Purchasing Situation:`}>
                        <Dropdown
                            options={enumToIDropdownOptions(
                                PartyPurchasingSituation.values,
                                PartyPurchasingSituation.fromValueToDisplayName
                            )}
                            value={formState.edit.purchasing_situation}
                            onChange={(value) => this.props.dispatch(
                                makeFormUpdateAction<"CRM_PARTY_SITUATION_INPUT_CHANGED", TParty2AndC4Form["edit"], "purchasing_situation">(
                                    "CRM_PARTY_SITUATION_INPUT_CHANGED",
                                    "purchasing_situation",
                                    value
                                )
                            )}
                        />
                    </LabelWrapForInputs>
                </FormRow>
                <FormRow>
                    <FormButton
                        status={formState.status}
                        label="Save general situation"
                        type="frog"
                        onClick={() => this.props.dispatch({
                            type: "CRM_PARTY_SUBMIT_SITUATION",
                        })}
                    />
                </FormRow>
            </FormBody>
        );
    }
}

export default CRMPartySituation;
