import React from "react";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { TPixelSizeShortHand } from "../../../models/StringLiterals";

type TSectionPopupProps = {
    padding?: TPixelSizeShortHand;
    isOpen: boolean;
};

export const PopupBase = (props: React.PropsWithChildren<TSectionPopupProps>): JSX.Element => {

    return (
        <div className={`
            popup-base
            popup-base--${props.isOpen ? "open" : "closed"}
        `}>
            <div 
                className={`popup-base__popout-wrap`}
                style={{
                    padding: props.padding || CRMSpacing.LARGE
                }}
            >
                {props.children}
            </div>
        </div>
    );
};
