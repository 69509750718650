import React from "react";
import { CRMColors } from "../../models/CRMColors";
import { Padding } from "../BuildingBlocks/Padding";
import { CRMIcon, TIcon } from "../CRMIcon/CRMIcon";
import { CRMInlineOptionsComponent } from "../CRMInlineOptionsComponent/CRMInlineOptionsComponent";
import { ICRMPopoutOption } from "../Simple/CRMPopoutOptions/CRMPopoutOptions";
import { CRMParagraph } from "../Simple/CRMParagraph/CRMParagraph";
import { CRMSpacingRow } from "../CRMSpacingRow/CRMSpacingRow";
import { TextColour } from "../TextColour/TextColour";
import { WeightBold } from "../WeightBold/WeightBold";
import { WeightSemiBold } from "../WeightSemiBold/WeightSemiBold";

type TRadioTabProps = {
    isSelected: boolean;
    label: string;
    onClick: () => void;
    icon?: TIcon;
    options?: Array<ICRMPopoutOption>;
};

export const CRMRadioTab = (props: TRadioTabProps): JSX.Element =>
    (
        <div
            className={`
                crm-radio-tab
                crm-radio-tab--${props.isSelected ? 'selected' : 'unselected'}
            `}
            onClick={props.onClick}
        >
            <CRMSpacingRow
                justifyContent="space-between"
                childSize="1fr auto"
                alignItems="flex-start"
            >
                <CRMSpacingRow alignItems="flex-start">
                    {/* ICON - RADIO SELECTED */}
                    <CRMIcon
                        iconName={props.isSelected ? "radio-selected" : "radio-unselected"}
                        colour={props.isSelected ? "primary-2" : "neutral-2"}
                    />
                    {/* ICON - EXTRA */}
                    {props.icon &&
                        <CRMIcon
                            iconName={props.icon}
                            colour={props.isSelected ? "primary-2" : "neutral-2"}
                        />
                    }

                    {/* ICON - PRIMARY CONTACT */}
                    <Padding type="top" spacing="3px">
                        {/* IF STRING: LABEL */}
                        {typeof props.label === "string" &&
                            <CRMParagraph>
                                <TextColour colour={props.isSelected ? CRMColors.PRIMARY_2 : CRMColors.NEUTRAL_2}>
                                    {props.isSelected &&
                                        <WeightBold>
                                            {props.label}
                                        </WeightBold>
                                    }
                                    {!props.isSelected &&
                                        <WeightSemiBold>
                                            {props.label}
                                        </WeightSemiBold>
                                    }
                                </TextColour>
                            </CRMParagraph>
                        }
                    </Padding>
                </CRMSpacingRow>

                {props.options && props.options.length > 0 &&
                    <Padding type="top" spacing="2px">
                        <CRMInlineOptionsComponent
                            iconSize="small"
                            iconColour={props.isSelected ? "primary-2" : "neutral-2"}
                            options={props.options}
                            popoutOpenDirection="left"
                        />
                    </Padding>
                }
            </CRMSpacingRow>

        </div>
    );
