import React, { useEffect } from "react";
import { CRMSpacing } from "./../../models/CRMSpacing";
import { TContainerStateProps } from "../../state/TContainerStateProps";
import { SpacingRow } from "../../components/BuildingBlocks/SpacingRow";
import { CRMComposeEmailForm } from "../../components/CRM/CRMComposeEmail/CRMComposeEmailForm/CRMComposeEmailForm";
import { CRMCaseInbox } from "../../components/Complex/CRMCaseInbox/CRMCaseInbox";
import { TTriageForm } from "../../../../domain/codecs/form/TriageForm";
import { CRMBlockScrollCacheView } from "../../components/CRM/CRMBlock/CRMBlockViews/CRMBlockViews";
import { CRMBlock } from "../../components/CRM/CRMBlock/CRMBlock/CRMBlock";


const CRMCaseInboxContainer = (props: TContainerStateProps): JSX.Element => {

    useEffect(
        () => props.dispatch({
            type: "CASE_INBOX_VIEW",
            payload: null
        }),
        []
    );

    return (
        <SpacingRow
            spacing={CRMSpacing.X_LARGE}
            alignItems="flex-start"
        >
            {/* BLOCK - TRIAGE */}
            <CRMBlock
                mode="fixed-option"
                startingWidth={700}
                fixedOption={{
                    icon: "email-notification",
                    title: "Case Mail"
                }}
                optionGroups={[]}
            >
                <CRMCaseInbox
                    unresolvedForm={props.state.forms.case_inbox_page.unresolved as unknown as TTriageForm}
                    onOpenEmail={(payload) => props.dispatch({
                        type: `CASE_INBOX_UNRESOLVED_OPEN_EMAIL`,
                        payload,
                    })}
                    onLoadMore={() => props.dispatch({
                        type: `CASE_INBOX_UNRESOLVED_LOAD_MORE`,
                    })}
                    onDisassociate={(payload) => props.dispatch({
                        type: "CASE_INBOX_DISASSOCIATE",
                        payload
                    })}
                    onReply={(payload) => props.dispatch({
                        type: "CRM_COMPOSE_EMAIL_REPLY",
                        payload
                    })}
                    onForward={(payload) => props.dispatch({
                        type: "CRM_COMPOSE_EMAIL_FORWARD",
                        payload
                    })}
                    onSearchTermChange={(payload) => props.dispatch({
                        type: "CASE_INBOX_SEARCH_CHANGE",
                        payload,
                    })}
                />
            </CRMBlock>

            {/* BLOCK - COMPOSE EMAIL */}
            <CRMBlock
                mode="fixed-option"
                fixedOption={{
                    icon: "send-email",
                    title: "Compose Email"
                }}
                optionGroups={[]}
            >
                <CRMBlockScrollCacheView isShowing={true}>
                    <CRMComposeEmailForm
                        closeMode="clear-only"
                        apiUrl={env.REACT_APP_API_URL}
                        composingUser={sessionUser}
                        attachedEmail={props.state.forms.global_legal.attached_email}
                        onChangeSearchCaseValue={(payload) => props.dispatch({
                            type: "CRM_COMPOSE_EMAIL_TYPE_CASE_SEARCH_TEXT",
                            payload,
                        })}
                        caseEmailPageCaseId={props.state.forms.case_details_page.data.input.case_id}
                        form={props.state.forms.global_legal.compose_email}
                        onSelectCase={(payload) => props.dispatch({
                            type: "CRM_SET_CASE_FOR_COMPOSE_EMAIL_FORM",
                            payload,
                        })}
                        onChange={(payload) => props.dispatch({
                            type: "CRM_UPDATE_COMPOSE_EMAIL_FORM",
                            payload,
                        })}
                        onSubmit={(payload) => props.dispatch({
                            type: "CRM_SEND_NEW_EMAIL",
                            payload,
                        })}
                        onReply={(payload) => props.dispatch({
                            type: "CRM_SEND_REPLY_EMAIL",
                            payload,
                        })}
                        onForward={(payload) => props.dispatch({
                            type: "CRM_SEND_FORWARD_EMAIL",
                            payload,
                        })}
                        onClear={() => props.dispatch({
                            type: "CRM_RESET_COMPOSE_EMAIL_FORM",
                            payload: null,
                        })}
                        additionalSendToEmailAddresses={[]}
                    />
                </CRMBlockScrollCacheView>
            </CRMBlock>
        </SpacingRow>
    );
}

export default CRMCaseInboxContainer;