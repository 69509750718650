import React from "react";
import { CRMBlockPopover } from "../../CRMBlock/CRMBlockPopover/CRMBlockPopover";
import { CRMSpacing } from "../../../../models/CRMSpacing";
import { CRMPopoutHeader } from "../../../Simple/CRMPopoutHeader/CRMPopoutHeader";
import { CRMEmailAttachmentFilterSearchSelect, TAttachmentType } from "../CRMEmailAttachmentFilterSearchSelect/CRMEmailAttachmentFilterSearchSelect";
import { TEmailCompositionForm } from "../../../../../../domain/codecs/form/EmailCompositionForm";

type TComposeEmailAttachmentSearchPopoverProps = {
    form: TEmailCompositionForm;
    onAddAttachment: (type: TAttachmentType, id: string) => void;
    onClose: () => void;
};

export const CRMComposeEmailAttachmentSearchPopover = (props: React.PropsWithChildren<TComposeEmailAttachmentSearchPopoverProps>): JSX.Element => {

    const TotalAttachmentCount =
        props.form.edited.attachments.enquiries.length
        + props.form.edited.attachments.files.length
        + props.form.edited.attachments.redemption_statements.length
        + props.form.edited.attachments.quotes.length
    ;

    return (
        <CRMBlockPopover
            padding={CRMSpacing.MEDIUM}
            top={CRMSpacing.TINY}
            height="720px" 
            maxHeight="720px"
        >
            <CRMPopoutHeader
                icon="arrow-left"
                onClick={props.onClose}
            >
                Select email attachment ({TotalAttachmentCount})
            </CRMPopoutHeader>

            <CRMEmailAttachmentFilterSearchSelect
                selectedAttachments={props.form.edited.attachments}
                searchAttachmentForm={props.form.children.email_attachments_search_form}
                onClick={props.onAddAttachment}
            />
        </CRMBlockPopover>
    );
};
