import React from "react";
import { CRMStatusPill } from "../../../Simple/CRMStatusPill/CRMStatusPill";
import { CRMColors } from "../../../../models/CRMColors";
import { TCaseTitleCheckFormState } from "../../../../../../domain/codecs/form/CaseTitleCheckForm";

export const CRMCaseTitleCheckStatusPill = (props: {
    formState: TCaseTitleCheckFormState
}): JSX.Element => (
    <CRMStatusPill
        pillColor={props.formState.status === "done" ? CRMColors.HIGHLIGHTS_POSITIVE_GREEN_0 : CRMColors.NEUTRAL_PAPER}
        borderColor={props.formState.status === "done" ? CRMColors.HIGHLIGHTS_POSITIVE_GREEN_0 : CRMColors.NEUTRAL_6}
        textColor={props.formState.status === "done" ? CRMColors.NEUTRAL_PAPER : CRMColors.NEUTRAL_INK}
        label={props.formState.status === "done" ? "Done" : `${props.formState.complete}/${props.formState.total}`}
    />
);