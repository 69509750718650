export const FrontSpacing = {
    MINIMUM: "1px",
    SMALL_1: "5px",
    SMALL_2: "10px",
    SMALL_3: "15px",
    MEDIUM_1: "20px",
    MEDIUM_2: "30px",
    LARGE_1: "40px",
    LARGE_2: "50px",
    LARGE_3: "60px",
} as const;