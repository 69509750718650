import React from "react";
import { TLegalEmailDecrypted1, TLegalEmailDecryptedWithBody } from "../../../../../../domain/codecs/LegalEmail";
import * as CaseDocument7 from "../../../../../../domain/models/CaseDocument7";
import * as User10 from "../../../../../../domain/models/User10";
import * as JsonError1 from "../../../../../../domain/models/JsonError1";
import { TCaseEnquiryAttachmentFilterForm, TCaseEnquiryAttachmentFormList } from "../../../../models/TFormModels";
import { CRMFontSizes } from "../../../../models/CRMFontSizes";
import { CRMButtonPrimary } from "../../../CRMButtonPrimary/CRMButtonPrimary";
import { CRMButtonSecondary } from "../../../CRMButtonSecondary/CRMButtonSecondary";
import { CRMCardOutsidePopupBlank } from "../../../CRMCardOutsidePopupBlank/CRMCardOutsidePopupBlank";
import { CRMCaseEnquiryEmailAttachment } from "../CRMCaseEnquiryEmailAttachment/CRMCaseEnquiryEmailAttachment";
import CRMInputLabelAndErrorWrapComponent from "../../../CRMInputLabelAndErrorWrapComponent/CRMInputLabelAndErrorWrapComponent";
import { CRMMultiSelectDropdownComponent } from "../../../CRMMultiSelectDropdownComponent/CRMMultiSelectDropdownComponent";
import { CRMPadding } from "../../../Simple/CRMPadding/CRMPadding";
import { CRMRadioListComponent } from "../../../CRMInputs/CRMRadioListComponent/CRMRadioListComponent";
import { CRMSpacer } from "../../../CRMSpacer/CRMSpacer";
import { CRMSpacingRow } from "../../../CRMSpacingRow/CRMSpacingRow";
import { CRMTitleForCard } from "../../../CRMTitleForCard/CRMTitleForCard";
import { Font } from "../../../Font/Font";
import { Uppercase } from "../../../Uppercase/Uppercase";
import { WeightSemiBold } from "../../../WeightSemiBold/WeightSemiBold";
import CRMTextAreaComponent from "../../../CRMTextAreaComponent/CRMTextAreaComponent";
import { CRMParagraph } from "../../../Simple/CRMParagraph/CRMParagraph";
import { WeightBold } from "../../../WeightBold/WeightBold";
import { CRMSpacingColumn } from "../../../CRMSpacingColumn/CRMSpacingColumn";
import { CRMCaseEnquiryDocumentAttachment } from "../CRMCaseEnquiryDocumentAttachment/CRMCaseEnquiryDocumentAttachment";
import { CRMFormButton } from "../../../CRMFormButton/CRMFormButton";
import { array } from "fp-ts";
import { CRMBottomControlBar } from "../../../CRMBottomControlBar/CRMBottomControlBar";
import { TextColour } from "../../../TextColour/TextColour";
import { CRMColors } from "../../../../models/CRMColors";
import { pipe } from "fp-ts/lib/function";
import { Padding } from "../../../BuildingBlocks/Padding";
import { CRMSpacing } from "../../../../models/CRMSpacing";

export type TEmailDirection = "inbound" | "outbound" | "both";

type TEnquiryAttachmentPopupProps = {
    filterForm: TCaseEnquiryAttachmentFilterForm;
    submitForm: TCaseEnquiryAttachmentFormList["forms"][number];
    recipientsList: Array<User10.T>;
    foundEmails: Array<TLegalEmailDecryptedWithBody>;
    foundDocuments: Array<CaseDocument7.T>;
    onSubmitFormChange: (value: TCaseEnquiryAttachmentFormList["forms"][number]) => void;
    onSelectEmailDirection: (direction: TEmailDirection) => void;
    onSelectRecipient: (recipients: Array<string>) => void;
    onViewEmail: (email: TLegalEmailDecrypted1) => void;
    onClose: () => void;
    onSubmit: () => void;
};

const CRMColumnTitle = (props: React.PropsWithChildren<{}>): JSX.Element => (
    <Font family="quicksand" size={CRMFontSizes.SMALL}>
        <Uppercase>
            <WeightSemiBold>
                <TextColour colour={CRMColors.NEUTRAL_INK}>
                    {props.children}
                </TextColour>
            </WeightSemiBold>
        </Uppercase>
    </Font>
);

const FilterColumn = (props: TEnquiryAttachmentPopupProps): JSX.Element => (
    <div>
        {/* FILE TYPE */}
        <CRMInputLabelAndErrorWrapComponent
            label="Attachment type"
        >
            <CRMRadioListComponent
                value={props.filterForm.edit.type}
                options={[
                    {
                        value: "documents",
                        text: "Documents",
                    },
                    {
                        value: "emails",
                        text: "Emails",
                    },
                ]}
                direction="row"
                onChange={(value) => props.onSubmitFormChange({
                    ...props.submitForm,
                    status: "untouched",
                    edit: {
                        ...props.submitForm.edit,
                        type: value,
                        ids: [],
                    },
                })}
            />
        </CRMInputLabelAndErrorWrapComponent>
        <CRMSpacer size="medium" />
        {/* IF EMAIL: DIRECTION & RECIPIENTS */}
        {props.filterForm.edit.type === "emails" &&
            <>
                <CRMInputLabelAndErrorWrapComponent
                    label="Direction"
                >
                    <CRMRadioListComponent
                        value={props.filterForm.edit.emailDirection}
                        options={[
                            {
                                value: "inbound",
                                text: "Inbound",
                            },
                            {
                                value: "outbound",
                                text: "Outbound",
                            },
                            {
                                value: "both",
                                text: "Both",
                            },
                        ]}
                        direction="row"
                        onChange={props.onSelectEmailDirection}
                    />
                </CRMInputLabelAndErrorWrapComponent>
                <CRMSpacer size="medium" />
                {/* RECEPIENTS */}
                <CRMInputLabelAndErrorWrapComponent
                    label="Recipients"
                >
                    <CRMMultiSelectDropdownComponent
                        value={props.filterForm.edit.recipients}
                        options={props.recipientsList.map((recipient) => ({
                            value: recipient.id,
                            label: recipient.email || "",
                        }))}
                        onChange={props.onSelectRecipient}
                        placeholder="Showing all recipients"
                    />
                </CRMInputLabelAndErrorWrapComponent>
            </>
        }
    </div>
);

const isAttachmentSelected = (matchId: string, props: TEnquiryAttachmentPopupProps): boolean =>
    !!props.submitForm.edit.ids.find((attachmentId) => attachmentId === matchId);

const getFoundEmailFromId = (props: TEnquiryAttachmentPopupProps, emailId: string): TLegalEmailDecrypted1 | undefined =>
    props.foundEmails.find((email) => email.id === emailId);

const getFoundFileFromId = (props: TEnquiryAttachmentPopupProps, fileId: string): CaseDocument7.T | undefined =>
    props.foundDocuments.find((file) => file.id === fileId);

const inputHasError = (props: TEnquiryAttachmentPopupProps, key: string): boolean =>
    JsonError1.doTargetKeysHaveInnerErrors([key], props.submitForm.validationErrors);

const getDocumentsNotSelected = (props: TEnquiryAttachmentPopupProps): Array<CaseDocument7.T> =>
     pipe(
        props.foundDocuments,
        array.filter((document) => !props.submitForm.edit.ids.includes(document.id)),
    );

const FoundEmailsColumn = (props: TEnquiryAttachmentPopupProps): JSX.Element => (
    <CRMSpacingColumn spacing="large">
        {/* TITLE */}
        <CRMColumnTitle>
            Found Emails ({props.foundEmails.length})
        </CRMColumnTitle>
        {/* IF EMAILS: SHOW */}
        {props.foundEmails.length > 0 &&
            <CRMSpacingColumn spacing="large">
                {props.foundEmails.map((email, index) => (
                    <CRMCaseEnquiryEmailAttachment
                        key={index}
                        mode="selection"
                        email={email}
                        selected={isAttachmentSelected(email.id, props)}
                        onSelect={() =>
                            props.submitForm.edit.ids.includes(email.id) ? undefined
                            : props.onSubmitFormChange({
                                ...props.submitForm,
                                status: "requiresSubmission",
                                edit: {
                                    ...props.submitForm.edit,
                                    ids: [
                                        ...props.submitForm.edit.ids,
                                        email.id,
                                    ],
                                },
                            })
                        }
                        onViewEmail={() => props.onViewEmail(email)}
                    />
                ))}
            </CRMSpacingColumn>
        }
    </CRMSpacingColumn>
);

const FoundDocumentsColumn = (props: TEnquiryAttachmentPopupProps): JSX.Element => (
    <CRMSpacingColumn spacing="large">
        {/* TITLE */}
        <CRMColumnTitle>
            Found Files ({getDocumentsNotSelected(props).length})
        </CRMColumnTitle>
        {/* IF DOCUMENTS: SHOW */}
        {props.foundDocuments.length > 0 &&
            <CRMSpacingColumn spacing="large">
                {getDocumentsNotSelected(props).map((document, index) => (
                    <CRMCaseEnquiryDocumentAttachment
                        key={index}
                        mode="selection"
                        document={document}
                        selected={isAttachmentSelected(document.id, props)}
                        onSelect={() =>
                            props.submitForm.edit.ids.includes(document.id) ? undefined
                            : props.onSubmitFormChange({
                                ...props.submitForm,
                                status: "requiresSubmission",
                                edit: {
                                    ...props.submitForm.edit,
                                    ids: [
                                        ...props.submitForm.edit.ids,
                                        document.id,
                                    ],
                                },
                            })
                        }
                    />
                ))}
            </CRMSpacingColumn>
        }
    </CRMSpacingColumn>
);

const SummaryColumn = (props: TEnquiryAttachmentPopupProps): JSX.Element => (
    <CRMSpacingColumn spacing="large" >
        {/* SUMMARY & NOTE */}
        <CRMColumnTitle>
            Summary
        </CRMColumnTitle>
        <CRMInputLabelAndErrorWrapComponent
            label="Note"
            displayError={inputHasError(props, "summary")}
            errorMessage="You must enter a note."
        >
            <CRMTextAreaComponent
                value={props.submitForm.edit.summary}
                displayError={inputHasError(props, "summary")}
                onChange={(value) => props.onSubmitFormChange({
                    ...props.submitForm,
                    edit: {
                        ...props.submitForm.edit,
                        summary: value,
                    },
                })}
            />
        </CRMInputLabelAndErrorWrapComponent>
        {/* EMAILS TO BE ATTACHED */}
        {props.submitForm.edit.ids.length > 0 &&
            <CRMSpacingColumn spacing="medium">
                <CRMParagraph>
                    <WeightBold>
                        {(props.filterForm.edit.type === "emails" ? "Emails" : "Files")} to be attached ({props.submitForm.edit.ids.length})
                    </WeightBold>
                </CRMParagraph>
                <CRMSpacingColumn spacing="large">
                    {/* IF EMAIL SUMMARY */}
                    {props.filterForm.edit.type === "emails" && props.submitForm.edit.ids.map((attachmentId, index) => (
                        <CRMCaseEnquiryEmailAttachment
                            key={index}
                            mode="summary"
                            email={getFoundEmailFromId(props, attachmentId) as TLegalEmailDecryptedWithBody}
                            onViewEmail={() => props.onViewEmail(getFoundEmailFromId(props, attachmentId) as TLegalEmailDecrypted1)}
                            onDelete={() => props.onSubmitFormChange({
                                ...props.submitForm,
                                status: "requiresSubmission",
                                edit: {
                                    ...props.submitForm.edit,
                                    ids:  pipe(
                                        props.submitForm.edit.ids,
                                        array.filter((id) => id !== attachmentId),
                                    ),
                                },
                            })}
                        />
                    ))}
                    {/* IF FILE SUMMARY */}
                    {props.filterForm.edit.type === "documents" && props.submitForm.edit.ids.map((attachmentId, index) => (
                        <CRMCaseEnquiryDocumentAttachment
                            key={index}
                            mode="summary"
                            document={getFoundFileFromId(props, attachmentId) as CaseDocument7.T}
                            onDelete={() => props.onSubmitFormChange({
                                ...props.submitForm,
                                status: "requiresSubmission",
                                edit: {
                                    ...props.submitForm.edit,
                                    ids:  pipe(
                                        props.submitForm.edit.ids,
                                        array.filter((id) => id !== attachmentId),
                                    ),
                                },
                            })}
                        />
                    ))}
                </CRMSpacingColumn>
            </CRMSpacingColumn>
        }
    </CRMSpacingColumn>
);

export const CRMCaseEnquiryAttachmentPopup = (props: TEnquiryAttachmentPopupProps): JSX.Element => (
        <CRMCardOutsidePopupBlank
            size="x-large"
            context="none"
            isOpen={props.submitForm.ui.isModalOpen}
            onClose={props.onClose}
        >
            <div className="crm-enquiry-attachment-popup">
                {/* BODY */}
                <CRMPadding size="large">
                    <CRMTitleForCard>
                        Attach Emails or Files
                    </CRMTitleForCard>
                    <CRMSpacer size="large" />
                    <CRMSpacingRow
                        spacing="x-large"
                        alignItems="flex-start"
                        childSize="even-fill"
                    >
                        <FilterColumn {...props} />
                        {props.filterForm.edit.type === "emails" &&
                            <FoundEmailsColumn {...props} />
                        }
                        {props.filterForm.edit.type === "documents" &&
                            <FoundDocumentsColumn {...props} />
                        }
                        <SummaryColumn {...props} />
                    </CRMSpacingRow>
                </CRMPadding>
                <CRMSpacer size="medium" />

                {/* BOTTOM BAR */}
                <CRMBottomControlBar>
                    <Padding
                        type="custom"
                        spacing={`${CRMSpacing.TINY} ${CRMSpacing.MEDIUM}`}
                    >
                        <CRMSpacingRow
                            spacing="medium"
                            justifyContent="end"
                        >
                            <CRMButtonSecondary
                                label="Cancel"
                                onClick={props.onClose}
                            />
                            <CRMFormButton
                                formStatus={props.submitForm.status}
                                ButtonElement={CRMButtonPrimary}
                                icon="attach"
                                label="Attach"
                                onClick={props.onSubmit}
                            />
                        </CRMSpacingRow>
                    </Padding>
                </CRMBottomControlBar>
            </div>
        </CRMCardOutsidePopupBlank>
    );
