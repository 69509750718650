import { pipe } from "fp-ts/lib/pipeable";
import { TDateDifference, TDateDifferenceUnit } from "./getDateDifference";

export const roundUpDateDifference = (dateDiff: TDateDifference) => pipe(
    dateDiff,
    roundUpUnit("seconds", 60, "minutes",),
    roundUpUnit("minutes", 60, "hours"),
    roundUpUnit("hours", 24, "days"),
    // rounding beyond hours becomes a whole other problem to solve.
    // since we need to know which month we are in, and year etc.
    // best leave here for now unless we run into future problems
    // ~ Cikito
)

const roundUpUnit = (unit: TDateDifferenceUnit, unitMax: number, unitParent: TDateDifferenceUnit) => 
    (dateDiff: TDateDifference): TDateDifference => {
        if (Math.abs(dateDiff[unit]) > unitMax-1) {
            return {
                ...dateDiff,
                [unit]: 0,
                [unitParent]: dateDiff[unitParent] < 0 ? 
                    dateDiff[unitParent]-1 :
                    dateDiff[unitParent]-1
            }
        }
        return dateDiff;
    }