import React from "react";
import { THexColor, TPixelSize } from "../../models/StringLiterals";

export const BorderBetween = (props: React.PropsWithChildren<{
    borderColour: THexColor;
    borderWidth?: TPixelSize;
    spacing?: TPixelSize;
}>): JSX.Element =>
    <div
        className="border-between"
        style={{
            borderColor: props.borderColour,
            borderBottomWidth: props.borderWidth || "1px",
            marginBottom: props.spacing || "inherit",
            paddingBottom: props.spacing || "inherit",
        }}
    >
        {props.children}
    </div>;
