import React from "react";
import { requireExhaustive } from "../../../../../shared/src/util";
import { CRMInboxTable } from "../CRMInboxTable/CRMInboxTable";
import { 
    TTriageEmailAssignToCaseSearchForm, 
    TTriageForm, 
    TTriageSimpleEmailForm, 
    TTriageEmailAssignToCaseAndUserForm, 
    TTriageEmailAssignToCaseForm, 
    TDetailedEmailForm, 
    TTriageAssigneeActiveTypeVisible, 
    TTriageEmailMarkAsResolvedForm, 
    TTriageEmailAssignToUserOnlyForm
} from "../../../../../domain/codecs/form/TriageForm";
import { CRMDropdownControlledBlock } from "../CRMDropdownControlledBlock/CRMDropdownControlledBlock";
import { useTriage } from "../../../hooks/useTriage";
import { CRMPrivateEmailSingleViewUnresolved } from "../CRMPrivateEmailSingleViewUnresolved/CRMPrivateEmailSingleViewUnresolved";
import { CRMPrivateEmailSingleViewResolved } from "../CRMPrivateEmailSingleViewResolved/CRMPrivateEmailSingleViewResolved";
import { CRMPrivateEmailSingleViewUntriaged } from "../CRMPrivateEmailSingleViewUntriaged/CRMPrivateEmailSingleViewUntriaged";
import { CRMBlockScrollCacheView, CRMBlockScrollResetView } from "../../CRM/CRMBlock/CRMBlockViews/CRMBlockViews";

type TPrivateInboxProps = {
    unresolvedForm: TTriageForm;
    resolvedForm: TTriageForm;
    confidentialForm: TTriageForm;
    untriagedForm: TTriageForm;
    triageTypeVisible: TTriageAssigneeActiveTypeVisible;
    onChangeTriageTypeVisible: (triageTypeVisible: TTriageAssigneeActiveTypeVisible) => void;

    onResolve: (form: TTriageEmailMarkAsResolvedForm) => void;
    onChangeChaseModeForm: (form: TTriageEmailMarkAsResolvedForm) => void;
    onAssignToCaseAndMe: (form: TTriageEmailAssignToCaseAndUserForm, triageType: TTriageAssigneeActiveTypeVisible) => void;
    onAssignToCaseAndStaff: (form: TTriageEmailAssignToCaseAndUserForm, triageType: TTriageAssigneeActiveTypeVisible) => void;
    onAssignToCaseAndHandler: (form: TTriageEmailAssignToCaseAndUserForm, triageType: TTriageAssigneeActiveTypeVisible) => void;
    onAssignToCaseAndResolve: (form: TTriageEmailAssignToCaseForm, triageType: TTriageAssigneeActiveTypeVisible) => void;
    onAssignToPerson: (form: TTriageEmailAssignToUserOnlyForm, triageType: TTriageAssigneeActiveTypeVisible) => void;
    
    onOpenEmail: (email: TTriageSimpleEmailForm, triageType: TTriageAssigneeActiveTypeVisible) => void;
    onReply: (form: TDetailedEmailForm) => void;
    onForward: (form: TDetailedEmailForm) => void;
    onLoadMore: (triageType: TTriageAssigneeActiveTypeVisible) => void;
    onChangeSearchCaseQuery: (form: TTriageEmailAssignToCaseSearchForm, triageType: TTriageAssigneeActiveTypeVisible) => void;
};

export const CRMPrivateInbox = (props: React.PropsWithChildren<TPrivateInboxProps>): JSX.Element => {
    const {
        getTriageFormToUse,
        isPresentEmailSetToOpen,
        getOpenedEmail,
        isShowingNext,
        isShowingPrevious,
        onOpenEmail,
        onLoadMore,
        onChangeSearchText,
        closeSingleView,
        onNext,
        onPrevious,
        onResolve,
        onAssignToCaseAndHandler,
        onAssignToCaseAndMe,
        onAssignToCaseAndResolve,
        onAssignToCaseAndStaff,
        onAssignToPerson,
    } = useTriage<TTriageAssigneeActiveTypeVisible>({
        getTriageFormToUseCallback: (triageTypeVisible) =>
            triageTypeVisible === "UNRESOLVED" ? props.unresolvedForm
            : triageTypeVisible === "RESOLVED" ? props.resolvedForm
            : triageTypeVisible === "CONFIDENTIAL" ? props.confidentialForm
            : triageTypeVisible === "UNTRIAGED" ? props.untriagedForm
            : requireExhaustive(triageTypeVisible),
        ...props,
        onArchive: () => undefined,
        onBulkArchive: () => undefined
    });

    return (
        <>
            {/* TABLE VIEW */}
            <CRMBlockScrollCacheView isShowing={!isPresentEmailSetToOpen()}>
                <CRMDropdownControlledBlock
                    menuMode="full_width"
                    sections={[
                        {
                            value: "UNRESOLVED",
                            label: "Unresolved",
                            badgeCount: props.unresolvedForm.children.counts.available_count,
                            content: <CRMInboxTable
                                form={props.unresolvedForm}
                                onOpenEmail={onOpenEmail}
                                onLoadMore={onLoadMore}
                            />
                        },
                        {
                            value: "RESOLVED",
                            label: "Resolved",
                            badgeCount: props.resolvedForm.children.counts.available_count,
                            content: <CRMInboxTable
                                form={props.resolvedForm}
                                onOpenEmail={onOpenEmail}
                                onLoadMore={onLoadMore}
                            />
                        },
                        {
                            value: "CONFIDENTIAL",
                            label: "Confidential",
                            badgeCount: props.confidentialForm.children.counts.available_count,
                            content: <CRMInboxTable
                                form={props.confidentialForm}
                                onOpenEmail={onOpenEmail}
                                onLoadMore={onLoadMore}
                            />
                        },
                        {
                            value: "UNTRIAGED",
                            label: "To be triaged",
                            badgeCount: props.untriagedForm.children.counts.available_count,
                            content: <CRMInboxTable
                                form={props.untriagedForm}
                                onOpenEmail={onOpenEmail}
                                onLoadMore={onLoadMore}
                            />
                        },
                    ]}
                    value={props.triageTypeVisible}
                    onValueChange={props.onChangeTriageTypeVisible}
                />
            </CRMBlockScrollCacheView>

            {/* SINGLE VIEWS */}
            <CRMBlockScrollResetView isShowing={isPresentEmailSetToOpen()}>
                {props.triageTypeVisible === "UNRESOLVED" &&
                    <CRMPrivateEmailSingleViewUnresolved
                        count={getTriageFormToUse().children.counts.available_count}
                        onChangeSearchText={onChangeSearchText}
                        email={getOpenedEmail()}
                        isShowingNext={isShowingNext()}
                        isShowingPrevious={isShowingPrevious()}
                        
                        onClose={closeSingleView}
                        onNext={onNext}
                        onPrevious={onPrevious}
                        
                        onResolve={onResolve}
                        onChangeChaseModeForm={props.onChangeChaseModeForm}
                        
                        onAssignToCaseAndMe={onAssignToCaseAndMe}
                        onAssignToCaseAndStaff={onAssignToCaseAndStaff}
                        onAssignToCaseAndHandler={onAssignToCaseAndHandler}
                        onAssignToCaseAndResolve={onAssignToCaseAndResolve}
                        onAssignToPerson={onAssignToPerson}

                        onReply={props.onReply}
                        onForward={props.onForward}
                    />
                }

                {props.triageTypeVisible === "UNTRIAGED" &&
                    <CRMPrivateEmailSingleViewUntriaged
                        count={getTriageFormToUse().children.counts.available_count}
                        onChangeSearchText={onChangeSearchText}
                        email={getOpenedEmail()}
                        isShowingNext={isShowingNext()}
                        isShowingPrevious={isShowingPrevious()}
                        
                        onClose={closeSingleView}
                        onNext={onNext}
                        onPrevious={onPrevious}
                        
                        onAssignToCaseAndMe={onAssignToCaseAndMe}
                        onAssignToCaseAndStaff={onAssignToCaseAndStaff}
                        onAssignToCaseAndHandler={onAssignToCaseAndHandler}
                        onAssignToCaseAndResolve={onAssignToCaseAndResolve}
                        onAssignToPerson={onAssignToPerson}

                        onReply={props.onReply}
                        onForward={props.onForward}
                    />
                }
                
                {props.triageTypeVisible === "RESOLVED" &&
                    <CRMPrivateEmailSingleViewResolved
                        count={getTriageFormToUse().children.counts.available_count}
                        email={getOpenedEmail()}
                        isShowingNext={isShowingNext()}
                        isShowingPrevious={isShowingPrevious()}
                        
                        onClose={closeSingleView}
                        onNext={onNext}
                        onPrevious={onPrevious}

                        onReply={props.onReply}
                        onForward={props.onForward}
                    />
                }
                
                {props.triageTypeVisible === "CONFIDENTIAL" &&
                    <CRMPrivateEmailSingleViewResolved
                        count={getTriageFormToUse().children.counts.available_count}
                        email={getOpenedEmail()}
                        isShowingNext={isShowingNext()}
                        isShowingPrevious={isShowingPrevious()}
                        
                        onClose={closeSingleView}
                        onNext={onNext}
                        onPrevious={onPrevious}

                        onReply={props.onReply}
                        onForward={props.onForward}
                    />
                }
            </CRMBlockScrollResetView>
        </>
    );
};
