import { array } from "fp-ts";
import { pipe } from "fp-ts/lib/function";
import React, { useState } from "react";
import { TCasePropertyIdAddressAndTitleNumber } from "../../../../domain/codecs/CaseProperty";
import { TCaseChargeForm, TCaseTitleRestrictionForm, TCreateCaseTitleRestrictionForm } from "../../../../domain/codecs/form/CaseChargesAndRestrictionForm";
import { doesErrorKeyExist } from "../../../../shared/src/codecs/errors";
import { CRMSpacing } from "../../models/CRMSpacing";
import { SpacingColumn } from "../BuildingBlocks/SpacingColumn";
import { CRMVerticalTabs } from "../Complex/CRMVerticalTabs/CRMVerticalTabs";
import { CRMButtonIcon } from "../CRMButtonIcon/CRMButtonIcon";
import { CRMCardOutsidePopupFormSubmit } from "../CRMCardOutsidePopupFormSubmit/CRMCardOutsidePopupFormSubmit";
import { CRMDropdownComponent } from "../CRMDropdownComponent/CRMDropdownComponent";
import { CRMFormButtonIconWithLabel } from "../CRMFormButtonIconWithLabel/CRMFormButtonIconWithLabel";
import CRMInputGeneralComponent from "../CRMInputs/CRMInputGeneralComponent/CRMInputGeneralComponent";
import CRMInputLabelAndErrorWrapComponent from "../CRMInputLabelAndErrorWrapComponent/CRMInputLabelAndErrorWrapComponent";
import { CRMNoticeBoxComponent } from "../CRMNoticeBoxComponent/CRMNoticeBoxComponent";
import { CRMParagraph } from "../Simple/CRMParagraph/CRMParagraph";
import CRMTextAreaComponent from "../CRMTextAreaComponent/CRMTextAreaComponent";
import { enumToIDropdownOptions } from "../../functions/enumToIDropdownOptions";
import { CaseTitleRestrictionType } from "../../../../domain/codecs/CaseTitleRestriction";
import { TitleEntrySection } from "../../../../domain/codecs/TitleEntrySection";
import { snakeCaseToCopyText } from "../../../../shared/src/util";

export const CRMCaseTitleRestrictions = React.memo((props: React.PropsWithChildren<{
    chargeForms: Array<TCaseChargeForm>,
    restrictionForms: Array<TCaseTitleRestrictionForm>,
    createRestrictionForm: TCreateCaseTitleRestrictionForm,
    propertiesAvailableForAssociation: Array<TCasePropertyIdAddressAndTitleNumber>,
    expectedCompletionDate: string | null,
    onCreateNewTitleRestriction: (value: TCreateCaseTitleRestrictionForm) => void;
    onUpdateTitleRestriction: (value: TCaseTitleRestrictionForm) => void;
    onDeleteTitleRestriction: (value: TCaseTitleRestrictionForm) => void;
}>): JSX.Element => {
    const [deletingRestrictionForm, setDeletingRestrictionForm] = useState<TCaseTitleRestrictionForm | null>(null);

    return <>
        <CRMVerticalTabs
            tabs={pipe(
                props.restrictionForms,
                array.map((form) => ({
                    tabLabel: form.edited.restriction_holder || snakeCaseToCopyText(form.edited.type),
                    content: <CRMCaseTitleRestrictionsTabContent
                        key={form.edited.id}
                        chargeForms={props.chargeForms}
                        restrictionForm={form}
                        propertiesAvailableForAssociation={props.propertiesAvailableForAssociation}
                        onUpdateTitleRestriction={props.onUpdateTitleRestriction}
                    />,
                    tabOptions: [{
                        label: "Delete",
                        onClick: () => setDeletingRestrictionForm(form),
                    }],
                }))
            )}
            afterTabsElement={
                <CRMFormButtonIconWithLabel
                    formStatus="requiresSubmission"
                    ButtonElement={(buttonProps) => <CRMButtonIcon variant="primary" {...buttonProps} />}
                    icon="add"
                    label="Add new restriction"
                    onClick={() => props.onCreateNewTitleRestriction(props.createRestrictionForm)}
                />
            }
        />

        {/* DELETE RESTRICTION POPUP */}
        <CRMCardOutsidePopupFormSubmit
            isOpen={deletingRestrictionForm !== null}
            title="Are you sure?"
            context="warning"
            closeText="Cancel"
            ctaText="Yes, delete"
            formStatus="requiresSubmission"
            validationErrors={[]}
            onClose={() => setDeletingRestrictionForm(null)}
            onCTA={() => {
                if (deletingRestrictionForm !== null) {
                    props.onDeleteTitleRestriction(deletingRestrictionForm)
                }
                setDeletingRestrictionForm(null);
            }}
        >
            <CRMParagraph>
                Are you sure you want to delete this title restriction?
            </CRMParagraph>
        </CRMCardOutsidePopupFormSubmit>
    </>;
});

const CRMCaseTitleRestrictionsTabContent = (props: {
    chargeForms: Array<TCaseChargeForm>,
    restrictionForm: TCaseTitleRestrictionForm,
    propertiesAvailableForAssociation: Array<TCasePropertyIdAddressAndTitleNumber>,
    onUpdateTitleRestriction: (value: TCaseTitleRestrictionForm) => void,
}): JSX.Element => {
    const newTitleRestrictionOrderInUse = (): boolean =>
        doesErrorKeyExist("edited.restriction_order", props.restrictionForm.validationErrors);

    const hasPropertiesAvailableForAssociation = (): boolean =>
        props.propertiesAvailableForAssociation.length > 0;
        
    const isFinancialRestriction =
        props.restrictionForm.edited.type === "charge"
        || props.restrictionForm.edited.type === "charge_related";

    const hasNoCharges = props.chargeForms.length === 0;

    const relatedChargeSet = !!props.restrictionForm.edited.cases_charges_id;

    return <SpacingColumn spacing={CRMSpacing.LARGE}>
        <SpacingColumn spacing={CRMSpacing.MEDIUM}>
            {/* TYPE */}
            <CRMInputLabelAndErrorWrapComponent
                label="Type"
            >
                <CRMDropdownComponent
                    value={props.restrictionForm.edited.type}
                    displayError={false}
                    options={enumToIDropdownOptions(
                        CaseTitleRestrictionType.values,
                        snakeCaseToCopyText,
                    )}
                    onChange={(type) => props.onUpdateTitleRestriction({
                        ...props.restrictionForm,
                        edited: {
                            ...props.restrictionForm.edited,
                            type,
                        },
                    })}
                />
            </CRMInputLabelAndErrorWrapComponent>

            {isFinancialRestriction &&
                <>
                    {hasNoCharges &&
                        <CRMNoticeBoxComponent>
                            No charges have been added yet, add these first before creating a restriction.
                        </CRMNoticeBoxComponent>
                    }

                    {!hasNoCharges && !relatedChargeSet &&
                        <CRMNoticeBoxComponent>
                            This restriction should be linked to it's related charge.
                        </CRMNoticeBoxComponent>
                    }

                    <CRMInputLabelAndErrorWrapComponent
                        label="Related charge"
                    >
                        <CRMDropdownComponent
                            value={props.restrictionForm.edited.cases_charges_id || ""}
                            options={pipe(
                                props.chargeForms,
                                array.map((chargeForm) => ({
                                    value: chargeForm.edited.id,
                                    label: pipe(
                                        [
                                            chargeForm.edited.beneficiary || "Unnamed charge",
                                            chargeForm.children.case_property?.title_number || "",
                                            chargeForm.edited.section === "unknown" ? "" : chargeForm.edited.section,
                                            chargeForm.edited.charge_order ? chargeForm.edited.charge_order.toString() : "",
                                        ],
                                        array.filter((value) => value !== ""),
                                        (a) => a.join("/"),
                                    )
                                }))
                            )}
                            displayError={false}
                            onChange={(cases_charges_id) => props.onUpdateTitleRestriction({
                                ...props.restrictionForm,
                                edited: {
                                    ...props.restrictionForm.edited,
                                    cases_charges_id: cases_charges_id || null,
                                },
                            })}
                        />
                    </CRMInputLabelAndErrorWrapComponent>

                    {/* RESTRICTION HOLDER */}
                    <CRMInputLabelAndErrorWrapComponent
                        label="Restriction holder"
                    >
                        <CRMInputGeneralComponent
                            inputType="text"
                            value={props.restrictionForm.edited.restriction_holder}
                            displayError={false}
                            onChange={(restriction_holder) => props.onUpdateTitleRestriction({
                                ...props.restrictionForm,
                                edited: {
                                    ...props.restrictionForm.edited,
                                    restriction_holder,
                                },
                            })}
                        />
                    </CRMInputLabelAndErrorWrapComponent>
                </>
            }

            {/* NO PROPERTIES AVAILABLE FOR ASSOCIATION */}
            {!hasPropertiesAvailableForAssociation() && <CRMNoticeBoxComponent>
                There are no Titles available for selection. Check the "Property details" section contains at least one property with it's "Title number" set.
            </CRMNoticeBoxComponent>}

            {/* PROPERTY SELECT FOR ASSOCIATION */}
            <CRMInputLabelAndErrorWrapComponent
                label="Title"
            >
                <CRMDropdownComponent
                    options={
                        pipe(
                            props.propertiesAvailableForAssociation,
                            array.map((property) => ({
                                value: property.id,
                                label: `${property.title_number} (${property.address})`
                            }))
                        )
                    }
                    value={props.restrictionForm.edited.cases_properties_id || ""}
                    displayError={newTitleRestrictionOrderInUse()}
                    onChange={(cases_properties_id) => props.onUpdateTitleRestriction({
                        ...props.restrictionForm,
                        edited: {
                            ...props.restrictionForm.edited,
                            cases_properties_id: cases_properties_id || null,
                        },
                    })}
                />
            </CRMInputLabelAndErrorWrapComponent>

            {/* SECTION */}
            <CRMInputLabelAndErrorWrapComponent
                label="Which section contains this restriction?"
                displayError={newTitleRestrictionOrderInUse()}
            >
                <CRMDropdownComponent
                    value={props.restrictionForm.edited.section}
                    options={enumToIDropdownOptions(
                        TitleEntrySection.values,
                        snakeCaseToCopyText,
                    )}
                    onChange={(section) => props.onUpdateTitleRestriction({
                        ...props.restrictionForm,
                        edited: {
                            ...props.restrictionForm.edited,
                            section,
                        },
                    })}
                    displayError={newTitleRestrictionOrderInUse()}
                />
            </CRMInputLabelAndErrorWrapComponent>

            {/* RESTRICTION ORDER */}
            <CRMInputLabelAndErrorWrapComponent
                label="What is this restriction's number?"
                guidance="Use the number next to this restriction as it is shown in the Title."
                displayError={newTitleRestrictionOrderInUse()}
                errorMessage="A different restriction in this section is using this number."
            >
                <CRMInputGeneralComponent
                    inputType="numericString"
                    value={
                        props.restrictionForm.edited.restriction_order === null
                            ? undefined
                            : props.restrictionForm.edited.restriction_order.toString()
                    }
                    onChange={(restriction_order) => props.onUpdateTitleRestriction({
                        ...props.restrictionForm,
                        edited: {
                            ...props.restrictionForm.edited,
                            restriction_order: restriction_order === ""
                                ? null
                                : parseInt(restriction_order),
                        },
                    })}
                    displayError={newTitleRestrictionOrderInUse()}
                />
            </CRMInputLabelAndErrorWrapComponent>

            {/* RESTRICTION TEXT */}
            <CRMInputLabelAndErrorWrapComponent
                label="Restriction"
                guidance="Enter the full restriction paragraph as it appears in the Title"
            >
                <CRMTextAreaComponent
                    value={props.restrictionForm.edited.restriction_text}
                    onChange={(restriction_text) => props.onUpdateTitleRestriction({
                        ...props.restrictionForm,
                        edited: {
                            ...props.restrictionForm.edited,
                            restriction_text,
                        },
                    })}
                />
            </CRMInputLabelAndErrorWrapComponent>
        </SpacingColumn>
    </SpacingColumn>;
};
