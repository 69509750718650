import React from "react";
import { CRMIconLabelAndMonoText } from "../CRMIconLabelAndMonoText/CRMIconLabelAndMonoText";
import * as ISODateTime from "../../../../domain/models/ISODateTime";
import { CRMColors } from "../../models/CRMColors";
import { CRMDeferPopout } from "../CrmDeferPopout/CRMDeferPopout";
import { useOpenClose } from "../../hooks/UseOpenClose";

type TCRMInlineDeferPickerProps = {
    date: string | null;
    label: string;
    onChange: (d: string | null) => void;
};

export const CRMInlineDeferPicker = (props: TCRMInlineDeferPickerProps) => {
    const {
        ref,
        isOpen,
        setIsOpen,
    } = useOpenClose();

    return (
        <div style={{position: "relative"}}>
            <CRMIconLabelAndMonoText
                label={props.label}
                displayText={
                    props.date === null ? "select date"
                    : ISODateTime.getFormattedDateString(new Date(props.date), "dd/MM/yyyy h:mma")
                }
                iconAlignment="end"
                iconTooltipText="Select Date"
                colour={props.date === null ? CRMColors.NEUTRAL_2 :  CRMColors.NEUTRAL_INK}
                iconName={isOpen ? "up-arrow" : "down-arrow"}
                // Magic on click functions are scary
                iconOnClick={() => setIsOpen(true)}
                displayTextOnClick={() => setIsOpen(true)}
            />
            {
                isOpen &&
                <div
                    ref={ref}
                    className="crm-inline-text-calendar__popout"
                >
                    <CRMDeferPopout
                        value={props.date}
                        onChange={(val) => {
                            props.onChange(val);
                            setIsOpen(false)
                        }}
                    />
                </div>
            }
        </div>
    );
}
