import React from "react";
import { formatDate } from "../../../../../shared/src/utilsByDomain/dateTime";
import { requireExhaustive } from "../../../../../shared/src/util";
import { CRMColors } from "../../../models/CRMColors";
import { CRMFontSizes } from "../../../models/CRMFontSizes";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { THexColor } from "../../../models/StringLiterals";
import { Background } from "../../BuildingBlocks/Background";
import { FontQuicksand } from "../../BuildingBlocks/FontQuicksand";
import { SpacingColumn } from "../../BuildingBlocks/SpacingColumn";
import { Text } from "../../BuildingBlocks/Text";
import { CRMLabelAndMonoValue } from "../CRMLabelAndMonoValue/CRMLabelAndMonoValue";
import { TClientCompanyPartnershipForm } from "../../../../../domain/codecs/form/ClientCompanyPartnershipForm";

export const CRMCompanyPartnerStatusBubble = (props: React.PropsWithChildren<{
    form: TClientCompanyPartnershipForm,
}>): JSX.Element => {
    const companyPartnerStatus = props.form.children.status;

    const getBackgroundColor = (): THexColor => {
        switch (companyPartnerStatus) {
            case "in_good_standing":
                return CRMColors.HIGHLIGHTS_POSITIVE_GREEN_12;
            case "id_checks_required":
            case "review_due":
            case "setup_not_complete":
                return CRMColors.HIGHLIGHTS_INSTRUCTIONAL_BRICK_10;
            default:
                return requireExhaustive(companyPartnerStatus);
        }
    }

    const getStatusText = (): string =>
        companyPartnerStatus === "id_checks_required" ? "ID checks required"
        : companyPartnerStatus === "in_good_standing" ? "In good standing"
        : companyPartnerStatus === "review_due" ? "Review due"
        : companyPartnerStatus === "setup_not_complete" ? "Setup not complete"
        : requireExhaustive(companyPartnerStatus);


    return (
        <Background
            display="inline-flex" 
            color={getBackgroundColor()}
            borderRadius="5px"
            padding={CRMSpacing.SMALL}
        >
            <SpacingColumn spacing={CRMSpacing.SMALL}>
                <FontQuicksand>
                    <Text
                        size={CRMFontSizes.MED}
                        lineHeight={1}
                        weight={500}
                    >
                        {getStatusText()}
                    </Text>
                </FontQuicksand>

                {/* REVIEW DUE DATE */}
                {(companyPartnerStatus === "in_good_standing" || companyPartnerStatus === "id_checks_required") && <CRMLabelAndMonoValue
                    label="Review due:"
                    value={formatDate(props.form.children.next_review_due_date)}
                />}

                {/* ID CHECKS REQUIRED DATE */}
                {(companyPartnerStatus === "in_good_standing" || companyPartnerStatus === "review_due") && <CRMLabelAndMonoValue
                    label="Next ID check:"
                    value={formatDate(props.form.children.next_id_check_due_date)}
                />}
            </SpacingColumn>
        </Background>
    );
};
