import React from "react";
import { TPercentageSize, TPixelSize } from "../../models/StringLiterals";

type TWidthProps = {
    width?: TPixelSize | TPercentageSize | "fit-content";
    minWidth?: TPixelSize | TPercentageSize | "fit-content";
    maxWidth?: TPixelSize | TPercentageSize | "fit-content";
};

export const Width = (props: React.PropsWithChildren<TWidthProps>): JSX.Element => {

    return (
        <div style={{
            width: props.width,
            minWidth: props.minWidth,
            maxWidth: props.maxWidth,
        }}>
            {props.children}
        </div>
    );
};
