import React from "react";
import { CRMIcon, IIcon } from "../CRMIcon/CRMIcon";
import { useOpenClose } from "../../hooks/UseOpenClose";

export const CRMIconPopout = (props: {
    iconProps: IIcon;
    children: JSX.Element;
    popoutOpenDirection: "left" | "right";
    maxWidth?: string;
}): JSX.Element => {
    const {
        ref,
        isOpen,
        setIsOpen,
    } = useOpenClose();

    return (
        <div
            className="crm-icon-popout"
            ref={ref}
        >
            <div
                className="crm-icon-popout__icon-container"
                onClick={() => setIsOpen(!isOpen)}
            >
                <CRMIcon
                    {...props.iconProps}
                />
            </div>
            {/* OPTIONS */}
            <div
                className={`
                    crm-icon-popout__options-expand-container
                    crm-icon-popout__options-expand-container${isOpen ? "--visible" : "--hidden"}
                    crm-icon-popout__options-expand-container${props.popoutOpenDirection === "right" ? "--open-right" : "--open-left"}
                    crm-icon-popout__options-expand-container--icon-${props.iconProps.size ?? "tiny"}`
                }
                style={{maxWidth: props.maxWidth}}
                onClick={() => setIsOpen(false)}
            >
                {props.children}
            </div>
        </div>
    );
};
