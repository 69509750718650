import React from "react";
import * as TFormStatus from "../../models/TFormStatus";
export class FormBody extends React.Component<React.PropsWithChildren<{
    formStatus?: TFormStatus.T;
}>> {
    public render (): JSX.Element {
        if (this.props.formStatus === "hidden") {
            return (<></>);
        }
        return (
            <div className={`formBody ${this.props.formStatus && TFormStatus.hasUnsavedChanged(this.props.formStatus) ? "formBody--unsaved" : ""}`}>
                {this.props.children}
            </div>
        );
    }
}

export class FormSection extends React.Component<React.PropsWithChildren<{ title: string}>> {
    public render (): JSX.Element {
        return (
            <div className="formSection">
                <div className="formSection__title">
                    {this.props.title}
                </div>
                <div className="formSection__body">
                    {this.props.children}
                </div>
            </div>
        );
    }
}

export class FormRow extends React.Component<React.PropsWithChildren<{}>> {
    public render (): JSX.Element {
        return (
            <div className="formRow">{this.props.children}</div>
        );
    }
}

export class FormCenteredRow extends React.Component<React.PropsWithChildren<{}>> {
    public render (): JSX.Element {
        return (
            <div className="formRow--centered">{this.props.children}</div>
        );
    }
}
