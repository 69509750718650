import { array, option } from "fp-ts";
import { pipe } from "fp-ts/lib/function";
import React, { useEffect, useState } from "react";
import { ClientCompanyPartnershipForm, TClientCompanyPartnershipContractForm, TClientCompanyPartnershipContractUploadForm, TClientCompanyPartnershipDeleteForm, TClientCompanyPartnershipForm, TClientCompanyPartnershipPageForm, TClientCompanyPartnershipUserForm, TClientCompanyPartnershipUserPhoneNumberCreateForm, TClientCompanyPartnershipUserPhoneNumberDeleteForm, TClientCompanyPartnershipUserPhoneNumberForm, TClientCompanyPartnershipUserPhoneNumberMakePrimaryForm } from "../../../../../domain/codecs/form/ClientCompanyPartnershipForm";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { MinHeight } from "../../BuildingBlocks/MinHeight";
import { Padding } from "../../BuildingBlocks/Padding";
import { SpacingRow } from "../../BuildingBlocks/SpacingRow";
import { CRMBottomControlBar } from "../../CRMBottomControlBar/CRMBottomControlBar";
import { CRMButtonQuaternary } from "../../CRMButtonQuaternary/CRMButtonQuaternary";
import { CRMCompanyPartnerEditPopout } from "../CRMCompanyPartnerEditPopout/CRMCompanyPartnerEditPopout";
import { CRMCompanyPartnerSingleView } from "../CRMCompanyPartnerSingleView/CRMCompanyPartnerSingleView";
import { CRMCompanyPartnersTable } from "../CRMCompanyPartnersTable/CRMCompanyPartnersTable";
import { CRMBlockScrollCacheView, CRMBlockScrollResetView } from "../../CRM/CRMBlock/CRMBlockViews/CRMBlockViews";
import { CRMBlockPopover } from "../../CRM/CRMBlock/CRMBlockPopover/CRMBlockPopover";

type TCompanyPartnerBlockViewStatus =
    "table"
    | "view-company"
;

type TCompanyPartnerBlockPopupViewStatus =
    "none"
    | "edit-company"
;

type TCompanyPartnerBlockProps = {
    form: TClientCompanyPartnershipPageForm;
    onChange: (form: TClientCompanyPartnershipForm) => void;
    onSave: (form: TClientCompanyPartnershipForm) => void;
    onCreate: () => void;
    onDelete: (form: TClientCompanyPartnershipDeleteForm) => void;
    onAddressAutocomplete: (form: TClientCompanyPartnershipForm) => void;
    onUploadContract: (form: TClientCompanyPartnershipContractUploadForm) => void;
    onDeleteContract: (form: TClientCompanyPartnershipContractForm) => void;
    onChangeUser: (form: TClientCompanyPartnershipUserForm) => void;
    onSaveUser: (form: TClientCompanyPartnershipUserForm) => void;
    onDeleteUser: (form: TClientCompanyPartnershipUserForm) => void;
    onUserPhoneNumberChange: (form: TClientCompanyPartnershipUserPhoneNumberForm) => void;
    onUserNewPhoneNumber: (form: TClientCompanyPartnershipUserPhoneNumberCreateForm) => void;
    onUserPhoneNumberMakePrimary: (form: TClientCompanyPartnershipUserPhoneNumberMakePrimaryForm) => void;
    onUserPhoneNumberDelete: (form: TClientCompanyPartnershipUserPhoneNumberDeleteForm) => void;
};

export const CRMCompanyPartnersBlock = (props: React.PropsWithChildren<TCompanyPartnerBlockProps>): JSX.Element => {

    const [viewStatus, setViewStatus] = useState<TCompanyPartnerBlockViewStatus>("table");
    const [popupViewStatus, setPopupViewStatus] = useState<TCompanyPartnerBlockPopupViewStatus>("none");
    const [openCompanyId, setOpenCompanyId] = useState<string | null>(null);

    const getOpenCompany = () =>
        pipe(
            props.form.children.client_company_partnership_forms,
            array.findFirst((f) => f.edited.id === openCompanyId),
            option.getOrElse(ClientCompanyPartnershipForm.newDefault),
        );

    const onOpenCompany = (form: TClientCompanyPartnershipForm) => { 
        setOpenCompanyId(form.edited.id);
        setViewStatus("view-company");
    }

    const onEditCompany = (form: TClientCompanyPartnershipForm) => { 
        setOpenCompanyId(form.edited.id);
        setPopupViewStatus("edit-company");
    }

    const onDelete = (form: TClientCompanyPartnershipDeleteForm) => {
        setViewStatus("table");
        props.onDelete(form);
    }

    useEffect(
        () => {
            if (
                props.form.children.client_company_partnership_forms[0]
                && props.form.children.client_company_partnership_forms[0].original.name === ""
                && props.form.children.create_form.status === "success"
            ) {
                setOpenCompanyId(props.form.children.client_company_partnership_forms[0].edited.id);
                setPopupViewStatus("edit-company");
            }
        },
        [props.form.children.client_company_partnership_forms]
    )

    return (
        <>
             {/* VIEW - EDIT */}
             {popupViewStatus === "edit-company" &&
                <CRMBlockPopover
                    padding={CRMSpacing.MEDIUM}
                    height="745px" 
                    maxHeight="745px"
                >
                    <CRMCompanyPartnerEditPopout
                        form={getOpenCompany()}
                        onChange={props.onChange}
                        onSave={props.onSave}
                        onAddressAutocomplete={props.onAddressAutocomplete}
                        onClose={() => setPopupViewStatus("none")}
                        onUploadContract={props.onUploadContract}
                        onDeleteContract={props.onDeleteContract}
                        onChangeUser={props.onChangeUser}
                        onSaveUser={props.onSaveUser}
                        onDeleteUser={props.onDeleteUser}
                        onUserPhoneNumberChange={props.onUserPhoneNumberChange}
                        onUserNewPhoneNumber={props.onUserNewPhoneNumber}
                        onUserPhoneNumberMakePrimary={props.onUserPhoneNumberMakePrimary}
                        onUserPhoneNumberDelete={props.onUserPhoneNumberDelete}
                    />
                </CRMBlockPopover>
            }

            <CRMBlockScrollCacheView isShowing={viewStatus === "table"}>
                <MinHeight height="702px">
                    <CRMCompanyPartnersTable
                        form={props.form}
                        onOpen={onOpenCompany}
                        onEdit={onEditCompany}
                        onDelete={onDelete}
                    />
                </MinHeight>

                <CRMBottomControlBar>
                    <Padding 
                        type="custom"
                        width="100%" 
                        spacing={`${CRMSpacing.TINY} ${CRMSpacing.MEDIUM}`}
                    >
                        <SpacingRow justifyContent="end">
                            <CRMButtonQuaternary 
                                icon="add"
                                label="Add Partner"
                                onClick={props.onCreate}
                            />
                        </SpacingRow>
                    </Padding>
                </CRMBottomControlBar>
            </CRMBlockScrollCacheView>

            {/* VIEW - COMPANY PARTNER */}
            <CRMBlockScrollResetView isShowing={viewStatus === "view-company"}>
                <CRMCompanyPartnerSingleView
                    form={getOpenCompany()}
                    onChange={props.onChange}
                    onSave={props.onSave}
                    onAddressAutocomplete={props.onAddressAutocomplete}
                    onClose={() => setViewStatus("table")}
                    onDelete={onDelete}
                    onUploadContract={props.onUploadContract}
                    onDeleteContract={props.onDeleteContract}
                    onChangeUser={props.onChangeUser}
                    onSaveUser={props.onSaveUser}
                    onDeleteUser={props.onDeleteUser}
                    onUserPhoneNumberChange={props.onUserPhoneNumberChange}
                    onUserNewPhoneNumber={props.onUserNewPhoneNumber}
                    onUserPhoneNumberMakePrimary={props.onUserPhoneNumberMakePrimary}
                    onUserPhoneNumberDelete={props.onUserPhoneNumberDelete}
                />
            </CRMBlockScrollResetView>
        </>
    );
};
