import React, { useEffect } from "react";
import { record, array } from "fp-ts";
import { ledgerItemDetails, ledgerTypeDictionary, TCaseLedgerType } from "../../../../../domain/codecs/CaseLedger";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { Background } from "../../BuildingBlocks/Background";
import { SpacingColumn } from "../../BuildingBlocks/SpacingColumn";
import { SpacingRow } from "../../BuildingBlocks/SpacingRow";
import { CRMButtonQuaternary } from "../../CRMButtonQuaternary/CRMButtonQuaternary";
import { CRMCardOutsideSharpCorners } from "../../CRMCardOutsideSharpCorners/CRMCardOutsideSharpCorners";
import { CRMInputCurrency } from "../../CRMInputCurrency/CRMInputCurrency";
import CRMInputLabelAndErrorWrapComponent from "../../CRMInputLabelAndErrorWrapComponent/CRMInputLabelAndErrorWrapComponent";
import { CRMParagraph } from "../../Simple/CRMParagraph/CRMParagraph";
import { CRMTitleForCard } from "../../CRMTitleForCard/CRMTitleForCard";
import { CRMFilterDropdown } from "../../Simple/CRMFilterDropdown/CRMFilterDropdown";
import { IOption } from "../../../hooks/UseDropdown";
import { CRMRadioListComponent } from "../../CRMInputs/CRMRadioListComponent/CRMRadioListComponent";
import { TCaseNewLedgerEntryForm } from "../../../../../domain/codecs/form/CaseNewLedgerEntryForm";
import { onChangeFormBulk, onChangeForm } from "../../../../../shared/src/codecs/types/form";
import { CRMFormButton } from "../../CRMFormButton/CRMFormButton";
import { castNewFormStatusToLegacy } from "../../../util";
import { doesErrorKeyExist } from "../../../../../shared/src/codecs/errors";
import { CRMFormErrorComponent } from "../../CRMFormErrorComponent/CRMFormErrorComponent";
import { pipe } from "fp-ts/lib/function";
import { Ord as StringOrd } from "fp-ts/lib/string";
import { contramap } from "fp-ts/lib/Ord";

type TCRMAddCaseLedgerItemCardProps = {
    form: TCaseNewLedgerEntryForm;
    ledgerIsFinal: boolean;
    onChange: (form: TCaseNewLedgerEntryForm) => void;
    onSubmit: (form: TCaseNewLedgerEntryForm) => void;
};

const OrdByLedgerDisplayString = pipe(StringOrd, contramap((option: IOption<TCaseLedgerType>) => `${option.label}`))

export const CRMAddCaseLedgerItemCard = (props: React.PropsWithChildren<TCRMAddCaseLedgerItemCardProps>): JSX.Element => {
    
    const onChange = onChangeForm(props.form, props.onChange);

    const onChangeBulk = onChangeFormBulk(props.form, props.onChange);

    const getFilterDropdownOptions = (): Array<IOption<TCaseLedgerType>> =>
        pipe(
            ledgerTypeDictionary,
            record.filter((o: ledgerItemDetails) => !Object.prototype.hasOwnProperty.call(o, "DEPRECATED") || o.DEPRECATED !== true),
            (v) => record.toArray<TCaseLedgerType, ledgerItemDetails>(v),
            array.map(([name, type]): IOption<TCaseLedgerType> => ({
                value: name,
                label: type.displayName,
            })),
            array.sortBy([OrdByLedgerDisplayString]),
        )
    ;

    const hasSelectedItemTypeHaveVat = (): boolean =>
        ledgerTypeDictionary[props.form.edited.type].vatStatus
    ;

    const areAllFieldsFilledIn = (): boolean =>
        props.form.edited.type &&
        props.form.edited.vat_excluded_amount_gbp_pence !== null
    ;

    const getPrefilledItemTypePrice = (type: TCaseLedgerType): number | null =>
        ledgerTypeDictionary[type].prefilledAmountGbpPence
    ;

    useEffect(() => {
        if (props.form.status === "untouched") {
            onChange("vat_excluded_amount_gbp_pence")(getPrefilledItemTypePrice(props.form.edited.type));
        }
    }, []);
        
    return (
        <CRMCardOutsideSharpCorners>
            <Background padding={CRMSpacing.LARGE}>
                <SpacingColumn spacing={CRMSpacing.MEDIUM}>
                    <CRMTitleForCard>
                        Add Item
                    </CRMTitleForCard>
                    
                    <SpacingColumn spacing={CRMSpacing.MEDIUM}>

                        {/* ITEM NAME */}
                        <CRMInputLabelAndErrorWrapComponent label="Item Name">
                            <CRMFilterDropdown
                                value={props.form.edited.type}
                                options={getFilterDropdownOptions()}
                                onChange={(type) => onChangeBulk({
                                    type,
                                    vat_excluded_amount_gbp_pence: getPrefilledItemTypePrice(type),
                                })}
                            />
                        </CRMInputLabelAndErrorWrapComponent>
                        
                        {/* AMOUNT & HAS VAT */}
                        <SpacingRow
                            spacing={CRMSpacing.MEDIUM}
                            childSize="1fr 1fr"
                        >
                            <CRMInputLabelAndErrorWrapComponent label="Amount (excl. VAT)">
                                <CRMInputCurrency
                                    value={props.form.edited.vat_excluded_amount_gbp_pence}
                                    displayError={false}
                                    onChange={onChange("vat_excluded_amount_gbp_pence")}
                                    mode="pence"
                                />
                            </CRMInputLabelAndErrorWrapComponent>

                            <CRMInputLabelAndErrorWrapComponent label="Has VAT?">
                                <CRMParagraph>
                                    <CRMRadioListComponent
                                        value={hasSelectedItemTypeHaveVat()}
                                        disabled={true}
                                        options={[
                                            {
                                                value: true,
                                                text: "Yes",
                                            },
                                            {
                                                value: false,
                                                text: "No",
                                            },
                                        ]}
                                        onChange={() => null}
                                    />
                                </CRMParagraph>
                            </CRMInputLabelAndErrorWrapComponent>

                        </SpacingRow>

                        <SpacingRow justifyContent="end">
                            <CRMFormButton
                                formStatus={castNewFormStatusToLegacy(props.form.status)}
                                ButtonElement={CRMButtonQuaternary}
                                label="Add"
                                title={props.ledgerIsFinal ? "The case ledger has been balanced and marked as final. No entries can be added" : undefined}
                                disabled={props.ledgerIsFinal || !areAllFieldsFilledIn()}
                                onClick={() => props.onSubmit(props.form)}
                            />
                        </SpacingRow>

                        {doesErrorKeyExist("edited.type", props.form.validationErrors) && <CRMFormErrorComponent
                            errorMessage="This item can't be added to the ledger as it already exists. Delete the other one then try again."
                        />}

                    </SpacingColumn>
                </SpacingColumn>
                
            </Background>
        </CRMCardOutsideSharpCorners>
    );
};
