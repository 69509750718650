import React from "react";
import { FrontSpacing } from "../../../../models/FrontSpacing";
import { SpacingColumn } from "../../../BuildingBlocks/SpacingColumn";
import { FrontOnboardingTab } from "../../Simple/FrontOnboardingTab/FrontOnboardingTab";
import { TOnboardingCaseOverviewTab } from "../FrontOnboardingCaseOverview/FrontOnboardingCaseOverview";
import { FrontFormSectionTitle } from "../../Simple/FrontFormSectionTitle/FrontFormSectionTitle";

export type TFrontOnboardingCaseOverviewStepsSummarySectionMobileProps<A extends string> = {
    tabs: Array<TOnboardingCaseOverviewTab<A>>;
    title?: string;
    onSelectTab: (tab: TOnboardingCaseOverviewTab<A>) =>  void;
};

export const FrontOnboardingCaseOverviewStepsSummarySectionMobile = <A extends string>(props: React.PropsWithChildren<TFrontOnboardingCaseOverviewStepsSummarySectionMobileProps<A>>): JSX.Element => (
    <SpacingColumn spacing={FrontSpacing.SMALL_3}>
        
        {/* TITLE */}
        {props.title &&
            <FrontFormSectionTitle>
                {props.title}
            </FrontFormSectionTitle>
        }
        
        {/* TABS */}
        <SpacingColumn spacing={FrontSpacing.SMALL_2}>
            {props.tabs.map((tab, index) => (
                <FrontOnboardingTab
                    key={index}
                    {...tab}
                    mode="filled"
                    isSelected={false}
                    onClick={() => props.onSelectTab(tab)}
                />
            ))}
        </SpacingColumn>

    </SpacingColumn>
);