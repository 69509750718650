import React from "react";
import { isAFunction } from "../../../functions/functions";
import { CRMFontSizes } from "../../../models/CRMFontSizes";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { FontQuicksand } from "../../BuildingBlocks/FontQuicksand";
import { FontSize } from "../../BuildingBlocks/FontSize";
import { Padding } from "../../BuildingBlocks/Padding";
import { SpacingRow } from "../../BuildingBlocks/SpacingRow";
import { CRMIcon } from "../../CRMIcon/CRMIcon";
import { WeightBold } from "../../WeightBold/WeightBold";
import { Background } from "../../BuildingBlocks/Background";

type TSingleViewHeaderProps = {
    title: string;
    edge?: TSingleViewHeaderEdge;
    onClick?: () => void;
};

export type TSingleViewHeaderEdge = "shadow" | "border";

export const CRMSingleViewHeader = (props: React.PropsWithChildren<TSingleViewHeaderProps>): JSX.Element => {
    
    const getEdge = (): TSingleViewHeaderEdge => props.edge || "shadow";

    return (
        <div className={`
            crm-single-view-header 
            crm-single-view-header--${getEdge()}
        `}>
            <SpacingRow
                spacing={CRMSpacing.MEDIUM}
                justifyContent="space-between"
                alignItems="center"
            >
                {/* BACK ARROW & TITLE */}
                <div className={`
                    crm-single-view-header__main
                    
                `}>
                    <SpacingRow 
                        spacing={CRMSpacing.MEDIUM}
                        alignItems="flex-start"
                    >
                        {isAFunction(props.onClick) &&
                            <CRMIcon
                                iconName="arrow-left"
                                colour="neutral-ink"
                                onClick={props.onClick}
                            />
                        }
                        
                        <Background 
                            display="flex"
                            padding="4px 0px 0px 0px"
                            onClick={props.onClick}
                        >
                            <FontQuicksand>
                                <FontSize size={CRMFontSizes.MED}>
                                    <WeightBold>
                                        {props.title}
                                    </WeightBold>
                                </FontSize>
                            </FontQuicksand>
                        </Background>
                    </SpacingRow>
                </div>

                {props.children}
            </SpacingRow>
        </div>
    );
};
