import { tap, switchMap, filter, debounce, debounceTime } from "rxjs/operators";
import { formOperation } from "../../wrappers/formOperation";
import { TActionsDefinitionsList } from "./TAction";
import { action, routeAction } from "./actionFunctions";
import { IntroducerExistingAgreementForm, IntroducersForm, TIntroducerCreateForm, TIntroducerExistingAgreementForm, TIntroducerForm, TIntroducerUploadAgreementForm } from "../../../../domain/codecs/form/IntroducerForm";
import { Observable, of, timer } from "rxjs";
import { TStateCodec } from "../../../../domain/codecs/state";
import { TCodecLens } from "../../../../shared/src/codecs/codecLens";
import { TCodecSetState, TGetCodecState } from "../applyActions";
import { QuoteRatePageForm } from "../../../../domain/codecs/form/QuoteRateForm";
import { reload$ as reloadQuoteRates$ } from "./crmQuoteRatesActions";
import { TCaseAutoAssignmentForm } from "../../../../domain/codecs/form/CaseAutoAssignmentForm";

export const introducersReload$ = (
    lens: TCodecLens<TStateCodec, TStateCodec>,
    set: TCodecSetState,
    get: TGetCodecState,
    pagination?: {
        offset: number,
        limit: number,
    },
) =>
    of(
        // If an offset and limit has been provided we will use it, otherwise reload the entire visible list of introducers
        // (offset: 0, limit: number of introducers in the array)
        set(lens.introducers_block.data.edited.set({
            offset: pagination?.offset || 0,
            limit: pagination?.limit || (
                lens.introducers_block.data.children.introducers.get()(get()).length < 20
                    ? 20
                    : lens.introducers_block.data.children.introducers.get()(get()).length
            ),
        }))
    )
        .pipe(
            switchMap(() => formOperation("GetIntroducers", lens.introducers_block.data.get()(get()))),
            tap((response) => set(lens.introducers_block.data.set({
                ...response,
                children: {
                    ...response.children,
                    introducers:
                        // If an offset has been provided then a paging action is being taken
                        // We will therefor merge all the new introducers onto the array of introducers we have already requested
                        pagination?.offset
                            ? [
                                ...lens.introducers_block.data.children.introducers.get()(get()),
                                ...response.children.introducers,
                            ]
                            : response.children.introducers,
                }
            }))),
        );

export const actions: TActionsDefinitionsList = [
    routeAction("VIEW_CRM_LEGAL_INTRODUCERS", (obs$, lens, set, get) => {
        obs$.pipe(
            tap(() => set(lens.introducers_block.data.set(IntroducersForm.newDefault()))),
            switchMap(() => introducersReload$(lens, set, get, {offset: 0, limit: 20})),
        ).subscribe();
    }),
    action("LEGAL_CASE_INTRODUCER_LOAD_MORE", (obs$, lens, set, get) => {
        obs$.pipe(
            switchMap(() => introducersReload$(lens, set, get, {
                offset: lens.introducers_block.data.edited.offset.get()(get()) + 20,
                limit: 20
            })),
        ).subscribe();
    }),
    action("LEGAL_CASE_INTRODUCER_CREATE", (obs$: Observable<TIntroducerCreateForm>, lens, set, get) => {
        obs$.pipe(
            tap((form) => set(lens.introducers_block.data.children.create_introducer_form.set({
                ...form,
                status: "submitting",
            }))),
            switchMap(() => formOperation("CreateIntroducer", lens.introducers_block.data.children.create_introducer_form.get()(get()))),
            tap((response) => set(lens.introducers_block.data.children.create_introducer_form.set(response))),
            filter(({status}) => status === "success"),
            switchMap(() => introducersReload$(lens, set, get)),
            debounce(() => timer(1000)),
            tap(() => set(lens.introducers_block.data.children.create_introducer_form.status.set("untouched"))),
        ).subscribe();
    }),
    action("LEGAL_CASE_INTRODUCER_DELETE", (obs$: Observable<TIntroducerForm>, lens, set, get) => {
        obs$.pipe(
            tap((form) => set(lens.introducers_block.data.children.introducers.where((introducerForm) => introducerForm.edited.id === form.edited.id).set({
                ...form,
                status: "submitting",
            }))),
            switchMap((form) => formOperation("DeleteIntroducer", lens.introducers_block.data.children.introducers.where((introducerForm) => introducerForm.edited.id === form.edited.id).get()(get()))),
            tap((response) => set(lens.introducers_block.data.children.introducers.where((introducerForm) => introducerForm.edited.id === response.edited.id).set(response))),
            filter(({status}) => status === "success"),
            switchMap(() => introducersReload$(lens, set, get)),
            tap(() => set(lens.introducers_block.introducer_id_open.set(null))),
            tap(() => set(lens.quote_rates_page.data.set(QuoteRatePageForm.newDefault()))),
        ).subscribe();
    }),
    action("LEGAL_CASE_INTRODUCER_CHANGE", (obs$: Observable<TIntroducerForm>, lens, set) => {
        obs$.pipe(
            tap((form) => set(
                lens.introducers_block.data.children.introducers
                    .where((introducerForm) => introducerForm.edited.id === form.edited.id)
                    .set(form))
            ),
        ).subscribe();
    }),
    action("LEGAL_CASE_INTRODUCER_SAVE", (obs$: Observable<TIntroducerForm>, lens, set, get) => {
        obs$.pipe(
            tap((form) => set(
                lens.introducers_block.data.children.introducers
                    .where((introducerForm) => introducerForm.edited.id === form.edited.id)
                    .set({
                        ...form,
                        status: "submitting",
                    }))
            ),
            switchMap((form) => formOperation(
                "UpdateIntroducer", 
                lens.introducers_block.data.children.introducers
                    .where((introducerForm) => introducerForm.edited.id === form.edited.id)
                    .get()(get())
            )),
            tap((response) => set(
                lens.introducers_block.data.children.introducers
                    .where((introducerForm) => introducerForm.edited.id === response.edited.id)
                    .set(response))
            ),
            debounce(() => timer(800)),
            tap((response) => set(
                lens.introducers_block.data.children.introducers
                    .where((introducerForm) => introducerForm.edited.id === response.edited.id)
                    .status.set("requiresSubmission")
            ))
        ).subscribe();
    }),
    action("LEGAL_CASE_INTRODUCER_AGREEMENT_UPLOAD", (obs$: Observable<TIntroducerUploadAgreementForm>, lens, set, get) => {
        obs$.pipe(
            tap((form) => set(lens.introducers_block.data.children.introducers.where((introducerForm) => introducerForm.edited.id === form.input.id).children.upload_form.set({
                ...form,
                status: "submitting",
            }))),
            switchMap((form) => formOperation("UploadIntroducerAgreement", lens.introducers_block.data.children.introducers.where((introducerForm) => introducerForm.edited.id === form.input.id).children.upload_form.get()(get()))),
            tap((response) => set(lens.introducers_block.data.children.introducers.where((introducerForm) => introducerForm.edited.id === response.input.id).children.upload_form.set(response))),
            filter(({status}) => status === "success"),
            switchMap(() => introducersReload$(lens, set, get)),
        ).subscribe();
    }),
    action("LEGAL_CASE_INTRODUCER_AGREEMENT_DELETE", (obs$: Observable<TIntroducerExistingAgreementForm>, lens, set, get) => {
        obs$.pipe(
            tap((form) => set(lens.introducers_block.data.children.introducers.where((introducerForm) => introducerForm.edited.id === form.edited.id).children.existing_agreement_form.set({
                ...form,
                status: "submitting",
            }))),
            switchMap((form) => formOperation("DeleteIntroducerAgreement", lens.introducers_block.data.children.introducers.where((introducerForm) => introducerForm.edited.id === form.edited.id).children.existing_agreement_form.get()(get()) || IntroducerExistingAgreementForm.newDefault())),
            tap((response) => set(lens.introducers_block.data.children.introducers.where((introducerForm) => introducerForm.edited.id === response.edited.id).children.existing_agreement_form.set(response))),
            filter(({status}) => status === "success"),
            switchMap(() => introducersReload$(lens, set, get)),
        ).subscribe();
    }),
    action("LEGAL_CASE_INTRODUCER_OPEN", (obs$: Observable<string>, lens, set, get) => {
        obs$.pipe(
            tap((introducer_id) => set(lens.introducers_block.introducer_id_open.set(introducer_id))),
            tap((introducer_id) => set(lens.quote_rates_page.data.set({
                ...QuoteRatePageForm.newDefault(),
                edited: {
                    introducer_id,
                }
            }))),
            switchMap(() =>
                sessionUser.user_role === "admin"
                    ? reloadQuoteRates$(lens, set, get)
                    : of(undefined),
            ),
        ).subscribe();
    }),
    action("LEGAL_CASE_INTRODUCER_CLOSE", (obs$: Observable<null>, lens, set) => {
        obs$.pipe(
            tap(() => set(lens.introducers_block.introducer_id_open.set(null))),
            tap(() => set(lens.quote_rates_page.data.set(QuoteRatePageForm.newDefault()))),
        ).subscribe();
    }),
    action("CRM_INTRODUCER_CASE_AUTO_ASSIGNMENT_CHANGE", (obs$: Observable<TCaseAutoAssignmentForm>, lens, set, get) => {
        obs$.pipe(
            tap((form) => set(lens.introducers_block.data.children.introducers.where(({edited}) => edited.id === form.edited.introducer_id).children.case_auto_assignment_forms.where(({edited}) =>
                edited.transaction_type === form.edited.transaction_type
                && edited.introducer_id === form.edited.introducer_id
            ).set(form))),
            debounceTime(1000),
            switchMap((form) => formOperation("UpdateCaseAutoAssignmentRules", form)),
            tap((response) => set(lens.introducers_block.data.children.introducers.where(({edited}) => edited.id === response.edited.introducer_id).children.case_auto_assignment_forms.where(({edited}) =>
                edited.transaction_type === response.edited.transaction_type
                && edited.introducer_id === response.edited.introducer_id
            ).set(response))),
        ).subscribe();
    }),
];
