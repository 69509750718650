import React from "react";
import { TPercentageSize, TPixelSize } from "../../models/StringLiterals";

export const Truncate = (props: React.PropsWithChildren<{
    maxWidth?: TPixelSize | TPercentageSize;
}>): JSX.Element => {

    return (
        <div style={{
            whiteSpace: "nowrap",
            maxWidth: props.maxWidth,
            overflow: "hidden",
            textOverflow: "ellipsis",
        }}>
            {props.children}
        </div>
    );
};
