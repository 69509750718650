import { pipe } from "fp-ts/lib/pipeable";
import { array } from "fp-ts";
import { capitaliseFirst, underscoreCaseToNormalCase } from "../../../../../shared/src/utilsByDomain/string";
import { TRichFilterDropdown_PillOption } from "../../Complex/CRMRichFilterDropdown_Pill/CRMRichFilterDropdown_Pill";
import { 
    TJobType,
    ListingEnquiryAdHocJobs,
    ListingPublicNoticeJobs,
    ListingPrepJobs,
    ListingValuationJobs,
    ListingProgressionJobs,
    KeyManagementJobs,
    MarketingJobs,
    BuyerEnquiriesJobs,
    ListingEnquiryOffersShareFeedbackWithBuyerJobs,
    ListingGeneralJobs,
    LegalCaseBasicJobs,
    LegalAdminJobs,
    MiscJobs,
    SailProbateHelplineJobs
} from "../../../../../domain/JobType";


export const jobTypeToPrettyText = (type: TJobType) => pipe(
    type.replace(/^sl_|^sh_/g, ''),
    underscoreCaseToNormalCase,
    capitaliseFirst,
);

const jobToGroup = (group: string) =>
    (type: TJobType): TRichFilterDropdown_PillOption => ({
        group,
        label: jobTypeToPrettyText(type),
        value: type,
    })
;

const sortOptionsAlphabetically = (options: Array<TRichFilterDropdown_PillOption>) => options.sort((optionA, optionB) => {
    if (optionA.value < optionB.value) {
        return -1;
    }
    if (optionA.value > optionB.value) {
        return 1;
    }
    return 0;
});

const jobsToGroupedOptions = (groupName: string, jobs: ReadonlyArray<TJobType>) => pipe(
    jobs.map(jobToGroup(groupName)),
    sortOptionsAlphabetically
)

export const SHJobFilterOptions = pipe(
    [
        jobsToGroupedOptions("Listing Prep", ListingPrepJobs),
        jobsToGroupedOptions("Listing Valuation", ListingValuationJobs),
        jobsToGroupedOptions("Listing Progression", ListingProgressionJobs),
        jobsToGroupedOptions("Buyer Enquiries", BuyerEnquiriesJobs),
        jobsToGroupedOptions("Key Managment", KeyManagementJobs),
        jobsToGroupedOptions("Marketing", MarketingJobs),
        jobsToGroupedOptions("Misc", [
            ...ListingEnquiryAdHocJobs,
            ...ListingGeneralJobs,
            ...ListingEnquiryOffersShareFeedbackWithBuyerJobs,
            ...ListingPublicNoticeJobs,
        ])
    ],
    array.flatten,
);

export const SLJobFilterOptions = pipe(
    [
        jobsToGroupedOptions("Admin", LegalAdminJobs),
        jobsToGroupedOptions("Misc", MiscJobs),
        jobsToGroupedOptions("Basic Jobs", LegalCaseBasicJobs),
    ],
    array.flatten,
);

export const SPJobFilterOptions = pipe(
    [
        jobsToGroupedOptions("Helpline", SailProbateHelplineJobs),
    ],
    array.flatten,
);