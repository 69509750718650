import React, { useEffect, useState } from "react";
import { SpacingColumn } from "../../components/BuildingBlocks/SpacingColumn";
import { FrontQuestionFormCard } from "../../components/Front/Complex/FrontQuestionFormCard/FrontQuestionFormCard";
import { TContainerStateProps } from "../../state/TContainerStateProps";
import { ClientCaseRelatedTransactionAddressForm, ClientCaseRelatedTransactionQuestionForm, TClientCaseRelatedTransaction } from "../../../../domain/codecs/form/ClientCaseForm";
import { FrontSpacing } from "../../models/FrontSpacing";
import { FrontCondensedRadioQuestions } from "../../components/Front/Simple/FrontCondensedRadioQuestions/FrontCondensedRadioQuestions";
import { MediaCompSwitch } from "../../components/BuildingBlocks/MediaCompSwitch";
import { SpacingRow } from "../../components/BuildingBlocks/SpacingRow";
import { FrontFormPrimaryButton } from "../../components/Front/Simple/FrontFormPrimaryButton/FrontFormPrimaryButton";
import { TOnboardingTabStatus } from "../../components/Front/Simple/FrontOnboardingTab/FrontOnboardingTab";
import { FrontRelatedTransactionAddressSearchInput } from "../../components/Front/Simple/FrontAddressSearchInput/FrontRelatedTransactionAddressSearchInput";
import { FrontCondensedQuestionWrap } from "../../components/Front/Simple/FrontCondensedQuestionWrap/FrontCondensedQuestionWrap";
import { FrontFormSectionTitle } from "../../components/Front/Simple/FrontFormSectionTitle/FrontFormSectionTitle";
import FrontParagraphSmall from "../../components/Front/Simple/FrontParagraphSmall/FrontParagraphSmall";
import { TAddress3 } from "../../../../domain/codecs/Address";
import { FrontHelpBubble } from "../../components/Front/Simple/FrontHelpBubble/FrontHelpBubble";
import { WeightBold } from "../../components/WeightBold/WeightBold";
import { FrontLink } from "../../components/FrontLink/FrontLink";
import { FrontRelatedTransactionConveyancerDetails } from "../../components/Front/Complex/FrontRelatedTransactionConveyancerDetails/FrontRelatedTransactionConveyancerDetails";
import { TCasesRelatedTransaction3 } from "../../../../domain/codecs/CasesRelatedTransaction";
import { pipe } from "fp-ts/lib/function";
import { FrontInfoBubble } from "../../components/Front/Simple/FrontInfoBubble/FrontInfoBubble";
import { FrontColors } from "../../models/FrontColors";
import { requireExhaustive } from "../../../../shared/src/util";
import { FrontCondensedDropdownQuestion } from "../../components/Front/Simple/FrontCondensedDropdownQuestion/FrontCondensedDropdownQuestion";
import { array } from "fp-ts";
import { IOption } from "../../hooks/UseDropdown";


const FrontRelatedTransactionContainer = (props: TContainerStateProps): JSX.Element => {
    const [displayValidationErrorsIfPresent, setDisplayValidationErrorsIfPresent] = useState<boolean>(false);
    const [addressFieldsVisible, setAddressFieldsVisible] = useState<boolean>(false);
    const [addressFormOpen, setAddressFormOpen] = useState<boolean>(true);
    const [conveyancerFormOpen, setConveyancerFormOpen] = useState<boolean>(true);

    const getTransactionType = () => props.state.forms.client_case_page.children.case.transaction_type;

    const formCanBeSubmitted = () =>
        getTransactionType() === "sale" && getRelatedTransaction(props).question.edited.related_transaction_needed_before_completion === "no" ? true
        : getTransactionType() === "sale"
            && getRelatedTransaction(props).question.edited.related_transaction_needed_before_completion === "yes"
            && (
                (
                    getRelatedTransaction(props).question.edited.related_purchase_address_known === "not_yet"
                )
                || (
                    getRelatedTransaction(props).question.edited.related_purchase_address_known === "yes"
                    && addressFieldsAreFilled(getRelatedTransaction(props).address.edited)
                    && (
                        (
                            getRelatedTransaction(props).address.edited.wants_quote === "yes"
                            && getRelatedTransaction(props).address.edited.quote_recipient_user_id
                        )
                        || (
                            getRelatedTransaction(props).address.edited.wants_quote === "no"
                            && conveyancerFieldsAreFilled(getRelatedTransaction(props).address.edited)
                        )
                    )
                )
            ) ? true
        : getTransactionType() === "purchase" && getRelatedTransaction(props).question.edited.related_transaction_needed_before_completion === "no" ? true
        : getTransactionType() === "purchase"
            && getRelatedTransaction(props).question.edited.related_transaction_needed_before_completion === "yes"
            && addressFieldsAreFilled(getRelatedTransaction(props).address.edited)
            && getRelatedTransaction(props).address.edited.sale_is_on_market !== "unknown"
            && (
                (
                    getRelatedTransaction(props).address.edited.wants_quote === "yes"
                    && getRelatedTransaction(props).address.edited.quote_recipient_user_id
                )
                || (
                    getRelatedTransaction(props).address.edited.wants_quote === "no"
                    && conveyancerFieldsAreFilled(getRelatedTransaction(props).address.edited)
                )
            ) ? true
        : false;

    const autoCompleteAddressButtonIsVisible = () =>
        addressFieldsVisible === false
        && (
            (
                getTransactionType() === "purchase"
                && getRelatedTransaction(props).question.edited.related_transaction_needed_before_completion === "yes"
                && getRelatedTransaction(props).address.edited.postcode && (getRelatedTransaction(props).address.edited.building_name || getRelatedTransaction(props).address.edited.building_number)
            )
            || (
                getTransactionType() === "sale"
                && getRelatedTransaction(props).question.edited.related_transaction_needed_before_completion === "yes"
                && getRelatedTransaction(props).question.edited.related_purchase_address_known === "yes"
                && getRelatedTransaction(props).address.edited.postcode && (getRelatedTransaction(props).address.edited.building_name || getRelatedTransaction(props).address.edited.building_number)
            )
        )   ? true
            : false;

    const submitButtonIsVisible = () =>
        ! autoCompleteAddressButtonIsVisible()
        && (
            getRelatedTransaction(props).question.original.related_transaction_needed_before_completion === "unknown" // This suggests the user has never answered this yet so the submit should always show
            || getRelatedTransaction(props).question.status !== "untouched" // Or there has been a change to the main question
            || getRelatedTransaction(props).address.status !== "untouched" // Or there has been a change to the address
        )
        && getRelatedTransaction(props).address.children.linked_case === null
            ? true
            : false;

    const getConveyancerSummary = () =>
        `${getRelatedTransaction(props).address.edited.conveyancer_name} (${getRelatedTransaction(props).address.edited.conveyancer_organisation_name}). ${pipe(
            [
                ...(getRelatedTransaction(props).address.edited.conveyancer_email_address ? [`Email: ${getRelatedTransaction(props).address.edited.conveyancer_email_address}`] : []),
                ...(getRelatedTransaction(props).address.edited.conveyancer_phone_number ? [`Tel: ${getRelatedTransaction(props).address.edited.conveyancer_phone_number}`] : []),
            ],
            (a) => a.join(" & "),
        )}`;

    const hasLinkedInstructedCase = () => {
        const status = getRelatedTransaction(props).address.children.linked_case_status;
        switch (status) {
            case "closed_successfully":
            case "completed":
            case "exchanged":
            case "instructed":
            case "ready_for_exchange":
                return true;
            case null:
            case "closed_created_in_error":
            case "closed_unsuccessfully":
            case "closed_awaiting_opening_new_case":
            case "quote":
            case "property_report":
                return false;
            default:
                return requireExhaustive(status)
        }
    }

    const getAddressQuestion = () =>
        <FrontCondensedQuestionWrap
            isOpen={addressFormOpen}
            setIsOpen={setAddressFormOpen}
            label={`Address of the property you are ${getTransactionType() === "purchase" ? "selling" : "buying"}`}
            summary={getRelatedTransaction(props).address.children.address_string}
        >
            <SpacingColumn spacing={FrontSpacing.MEDIUM_1}>
                <FrontFormSectionTitle>
                    Address
                </FrontFormSectionTitle>

                <SpacingColumn spacing={FrontSpacing.MEDIUM_1}>
                    <FrontParagraphSmall>
                        {getTransactionType() === "purchase"
                            ? "Please enter the address of the property you are selling. We need to know this even if your property isn't yet on the market or you are using a different conveyancer to represent you."
                            : "Please enter the address of the property you are buying. We need to know this even if you are using a different conveyancer to represent you."}
                    </FrontParagraphSmall>

                    <FrontRelatedTransactionAddressSearchInput
                        form={getRelatedTransaction(props).address}
                        onChange={(payload) => props.dispatch({
                            type: "CLIENT_UPDATE_RELATED_TRANSACTION_ADDRESS",
                            payload,
                        })}
                        addressFieldsVisible={addressFieldsVisible}
                        displayValidationErrorsIfPresent={displayValidationErrorsIfPresent}
                    />
                </SpacingColumn>
            </SpacingColumn>
        </FrontCondensedQuestionWrap>;

    const getQuoteQuestion = () =>
        <>
            <FrontCondensedRadioQuestions
                value={
                    getRelatedTransaction(props).address.edited.wants_quote === "unknown"
                        ? null
                        : getRelatedTransaction(props).address.edited.wants_quote
                }
                isEditable={true}
                label={`We'd love to represent you for your ${getTransactionType() === "purchase" ? "sale" : "purchase"} as well, would you like us to send you a quote?`}
                options={[
                    {
                        label: "Yes",
                        value: "yes"
                    },
                    {
                        label: "No",
                        value: "no"
                    },
                ]}
                onChange={(wants_quote) => props.dispatch({
                    type: "CLIENT_UPDATE_RELATED_TRANSACTION_ADDRESS",
                    payload: {
                        ...getRelatedTransaction(props).address,
                        edited: {
                            ...getRelatedTransaction(props).address.edited,
                            wants_quote,
                        }
                    }
                })}
                displayValidationErrorsIfPresent={displayValidationErrorsIfPresent}
            />

            {/* DROPDOWN - WHO SHOULD WE CONTACT WITH THE QUOTE */}
            {getRelatedTransaction(props).address.edited.wants_quote === "yes" && <FrontCondensedDropdownQuestion
                label={
                    getTransactionType() === "purchase"
                        ? "Who should we send the quote to? (select one of the owners of this property)"
                        : "Who should we send the quote to? (select one of the buyers of this property)"
                }
                closedLabel="We'll send the quote to"
                value={getRelatedTransaction(props).address.edited.quote_recipient_user_id || ""}
                isEditable={true}
                options={pipe(
                    getRelatedTransaction(props).users_who_can_receive_quote,
                    array.map((user) => ({
                        value: user.id,
                        label: user.full_name,
                    })),
                    array.prepend<IOption<string>>({
                        value: "",
                        label: "Not set",
                    })
                )}
                wrapInFormRow={true}
                onChange={(quote_recipient_user_id) => props.dispatch({
                    type: "CLIENT_UPDATE_RELATED_TRANSACTION_ADDRESS",
                    payload: {
                        ...getRelatedTransaction(props).address,
                        edited: {
                            ...getRelatedTransaction(props).address.edited,
                            quote_recipient_user_id: quote_recipient_user_id || null
                        }
                    },
                })}
                displayValidationErrorsIfPresent={displayValidationErrorsIfPresent}
            />}
        </>;

    const getConveyancerQuestion = () =>
        <FrontCondensedQuestionWrap
            isOpen={conveyancerFormOpen}
            setIsOpen={setConveyancerFormOpen}
            label={`Who's representing you for your ${getTransactionType() === "purchase" ? "sale" : "purchase"}?`}
            summary={getConveyancerSummary()}
        >
            <SpacingColumn spacing={FrontSpacing.MEDIUM_1}>
                <FrontFormSectionTitle>
                    Who will be representing you for your {getTransactionType() === "purchase" ? "sale" : "purchase"}?
                </FrontFormSectionTitle>

                <SpacingColumn spacing={FrontSpacing.MEDIUM_1}>
                    <FrontParagraphSmall>
                        We need to know the details of the conveyancer representing you for the {getTransactionType() === "purchase" ? "sale" : "purchase"} of your property so that we can work with them to ensure both your purchase and sale complete at the same time.
                    </FrontParagraphSmall>

                    <FrontRelatedTransactionConveyancerDetails
                        form={getRelatedTransaction(props).address}
                        onChange={(payload) => props.dispatch({
                            type: "CLIENT_UPDATE_RELATED_TRANSACTION_ADDRESS",
                            payload,
                        })}
                        displayValidationErrorsIfPresent={displayValidationErrorsIfPresent}
                    />

                    {! conveyancerFieldsAreFilled(getRelatedTransaction(props).address.edited) && <FrontHelpBubble
                        type="info"
                        label="Not sure who will be representing you for your sale yet?"
                        paragraph={<>
                            Because it's so important we have these details you wont be able to continue without them. <WeightBold>If you would like us to provide you with a quote for representing your {getTransactionType() === "purchase" ? "sale" : "purchase"} just <FrontLink onClick={() => props.dispatch({
                                type: "CLIENT_UPDATE_RELATED_TRANSACTION_ADDRESS",
                                payload: {
                                    ...getRelatedTransaction(props).address,
                                    edited: {
                                        ...getRelatedTransaction(props).address.edited,
                                        wants_quote: "yes",
                                    }
                                }
                            })}>click here to continue.</FrontLink></WeightBold>
                        </>}
                    />}
                </SpacingColumn>
            </SpacingColumn>
        </FrontCondensedQuestionWrap>;

    useEffect(() => {
        if (
            getRelatedTransaction(props).address.edited.city_town
            || getRelatedTransaction(props).address.edited.county
            || getRelatedTransaction(props).address.edited.district
            || getRelatedTransaction(props).address.edited.locality
            || getRelatedTransaction(props).address.edited.street_name
            || getRelatedTransaction(props).address.edited.sub_building_name
            || getRelatedTransaction(props).address.edited.sub_building_number
        ) {
            setAddressFieldsVisible(true);

            if (props.state.forms.client_case_page.children.related_transaction?.address.status === "untouched") {
                setAddressFormOpen(false);
            }
        }

        if (
            (
                getRelatedTransaction(props).address.edited.conveyancer_organisation_name
                || getRelatedTransaction(props).address.edited.conveyancer_name
                || getRelatedTransaction(props).address.edited.conveyancer_email_address
                || getRelatedTransaction(props).address.edited.conveyancer_phone_number
            ) && props.state.forms.client_case_page.children.related_transaction?.address.status === "untouched"
        ) {
            setConveyancerFormOpen(false);
        }
    }, [props.state.forms.client_case_page.children.related_transaction]);

    return (
        <div>
            <FrontQuestionFormCard
                title={getRelatedTransactionTitle(props)}
            >
                {props.state.forms.client_case_page.children.related_transaction?.address.children.linked_case !== null && <>
                    <FrontParagraphSmall>
                        {hasLinkedInstructedCase()
                            ? <>We're representing you with your {getTransactionType() === "purchase" ? "sale" : "purchase"} of <WeightBold>{getRelatedTransaction(props).address.children.linked_case}.</WeightBold></>
                            : <>We're providing you with a quote for your {getTransactionType() === "purchase" ? "sale" : "purchase"} of <WeightBold>{getRelatedTransaction(props).address.children.linked_case}.</WeightBold> Once you've had a chance to take a look at the quote let us know if you'd like us to represent you.</>}
                    </FrontParagraphSmall>
                </>}

                {props.state.forms.client_case_page.children.related_transaction?.address.children.linked_case === null && <SpacingColumn spacing={FrontSpacing.LARGE_1}>
                    <SpacingColumn spacing={FrontSpacing.MEDIUM_1}>
                        {/* RADIO - DO YOU HAVE A RELATED TRANSACTION */}
                        <FrontCondensedRadioQuestions
                            value={
                                getRelatedTransaction(props).question.edited.related_transaction_needed_before_completion === "unknown"
                                    ? null
                                    : getRelatedTransaction(props).question.edited.related_transaction_needed_before_completion
                            }
                            isEditable={true}
                            label={getRelatedTransactionQuestionLabel(props)}
                            options={[
                                {
                                    label: "Yes",
                                    value: "yes"
                                },
                                {
                                    label: "No",
                                    value: "no"
                                },
                            ]}
                            onChange={(related_transaction_needed_before_completion) => props.dispatch({
                                type: "CLIENT_UPDATE_RELATED_TRANSACTION_QUESTION",
                                payload: {
                                    ...getRelatedTransaction(props).question,
                                    edited: {
                                        ...getRelatedTransaction(props).question.edited,
                                        related_transaction_needed_before_completion,
                                    }
                                }
                            })}
                            displayValidationErrorsIfPresent={displayValidationErrorsIfPresent}
                        />

                        {/* SALE QUESTIONS ABOUT RELATED PURCHASE */}
                        {getTransactionType() === "sale" && getRelatedTransaction(props).question.edited.related_transaction_needed_before_completion === "yes" && <SpacingColumn spacing={FrontSpacing.MEDIUM_1}>
                            {/* RADIO - DO YOU KNOW THE ADDRESS YET */}
                            <FrontCondensedRadioQuestions
                                value={
                                    getRelatedTransaction(props).question.edited.related_purchase_address_known === "unknown"
                                        ? null
                                        : getRelatedTransaction(props).question.edited.related_purchase_address_known
                                }
                                isEditable={true}
                                label="Have you already found the property you are going to buy?"
                                options={[
                                    {
                                        label: "Yes",
                                        value: "yes"
                                    },
                                    {
                                        label: "Not yet",
                                        value: "not_yet"
                                    },
                                ]}
                                onChange={(related_purchase_address_known) => props.dispatch({
                                    type: "CLIENT_UPDATE_RELATED_TRANSACTION_QUESTION",
                                    payload: {
                                        ...getRelatedTransaction(props).question,
                                        edited: {
                                            ...getRelatedTransaction(props).question.edited,
                                            related_purchase_address_known,
                                        }
                                    }
                                })}
                                displayValidationErrorsIfPresent={displayValidationErrorsIfPresent}
                            />

                            {getRelatedTransaction(props).question.edited.related_purchase_address_known === "not_yet" && <FrontHelpBubble
                                type="pending"
                                label="Tell us when this changes"
                                paragraph={<>
                                    <WeightBold>It's important we know about your purchase</WeightBold> (even if you are being represented by a different conveyancer). When you've found the property you are going to buy, just <FrontLink onClick={() => props.dispatch({
                                        type: "CLIENT_UPDATE_RELATED_TRANSACTION_QUESTION",
                                        payload: {
                                            ...getRelatedTransaction(props).question,
                                            edited: {
                                                ...getRelatedTransaction(props).question.edited,
                                                related_purchase_address_known: "yes",
                                            }
                                        }
                                    })}>click here to give us the details.</FrontLink>
                                </>}
                            />}

                            {getRelatedTransaction(props).question.edited.related_purchase_address_known === "yes" && <>
                                {/* ADDRESS FORM */}
                                {getAddressQuestion()}

                                {addressFieldsVisible && <>
                                    {/* RADIO - WOULD YOU LIKE A QUOTE */}
                                    {getQuoteQuestion()}

                                    {/* CONVEYANCER DETAILS */}
                                    {getRelatedTransaction(props).address.edited.wants_quote === "no" && 
                                        getConveyancerQuestion()}
                                </>}
                            </>}
                        </SpacingColumn>}

                        {/* PURCHASE QUESTIONS ABOUT RELATED SALE */}
                        {getTransactionType() === "purchase" && getRelatedTransaction(props).question.edited.related_transaction_needed_before_completion === "yes" && <SpacingColumn spacing={FrontSpacing.MEDIUM_1}>
                            {/* ADDRESS FORM */}
                            {getAddressQuestion()}

                            {addressFieldsVisible && <>
                                {/* RADIO - IS IT ALREADY ON THE MARKET */}
                                <FrontCondensedRadioQuestions
                                    value={
                                        getRelatedTransaction(props).address.edited.sale_is_on_market === "unknown"
                                            ? null
                                            : getRelatedTransaction(props).address.edited.sale_is_on_market
                                    }
                                    isEditable={true}
                                    label="Is this property already on the market?"
                                    options={[
                                        {
                                            label: "Yes",
                                            value: "yes"
                                        },
                                        {
                                            label: "No",
                                            value: "no"
                                        },
                                    ]}
                                    onChange={(sale_is_on_market) => props.dispatch({
                                        type: "CLIENT_UPDATE_RELATED_TRANSACTION_ADDRESS",
                                        payload: {
                                            ...getRelatedTransaction(props).address,
                                            edited: {
                                                ...getRelatedTransaction(props).address.edited,
                                                sale_is_on_market,
                                            }
                                        }
                                    })}
                                    displayValidationErrorsIfPresent={displayValidationErrorsIfPresent}
                                />

                                {/* RADIO - WOULD YOU LIKE A QUOTE */}
                                {getQuoteQuestion()}
                                
                                {/* CONVEYANCER DETAILS */}
                                {getRelatedTransaction(props).address.edited.wants_quote === "no" && 
                                    getConveyancerQuestion()}
                            </>}
                        </SpacingColumn>}
                    </SpacingColumn>
                    
                    {/* CONTROLS */}
                    <SpacingColumn spacing={FrontSpacing.MEDIUM_1}>
                        {submitButtonIsVisible() &&
                            <MediaCompSwitch
                                DesktopComponent={SpacingRow}
                                desktopProps={{ spacing: FrontSpacing.SMALL_3}}
                                MobileComponent={SpacingColumn}
                                mobileProps={{ spacing: FrontSpacing.SMALL_3 }}
                                breakpoint="600"
                            >
                                <FrontFormPrimaryButton
                                    label="Save"
                                    icon="done"
                                    isDisabled={getRelatedTransaction(props).question.status === "submitting"}
                                    onClick={() =>
                                        formCanBeSubmitted()
                                            ? props.dispatch({
                                                type: "CLIENT_SUBMIT_RELATED_TRANSACTION",
                                                payload: getRelatedTransaction(props),
                                            })
                                            : setDisplayValidationErrorsIfPresent(true)
                                    }
                                />
                            </MediaCompSwitch>
                        }

                        {autoCompleteAddressButtonIsVisible() &&
                            <MediaCompSwitch
                                DesktopComponent={SpacingRow}
                                desktopProps={{ spacing: FrontSpacing.SMALL_3}}
                                MobileComponent={SpacingColumn}
                                mobileProps={{ spacing: FrontSpacing.SMALL_3 }}
                                breakpoint="600"
                            >
                                <FrontFormPrimaryButton
                                    label="Find address"
                                    isDisabled={false}
                                    onClick={() => {
                                        props.dispatch({
                                            type: "CLIENT_RELATED_TRANSACTION_AUTOCOMPLETE_ADDRESS",
                                            payload: getRelatedTransaction(props).address,
                                        })
                                        setAddressFieldsVisible(true);
                                    }}
                                />
                            </MediaCompSwitch>
                        }

                        {displayValidationErrorsIfPresent && !formCanBeSubmitted() && <FrontInfoBubble color={FrontColors.ERROR_FIREBRICK_22}>
                            <WeightBold>You've missed a few things above,</WeightBold> please take a look and then try again.
                        </FrontInfoBubble>}
                    </SpacingColumn>
                </SpacingColumn>}
            </FrontQuestionFormCard>
        </div>
    );
};

const getRelatedTransaction = (props: TContainerStateProps): TClientCaseRelatedTransaction =>
    props.state.forms.client_case_page.children.related_transaction || {
            question: ClientCaseRelatedTransactionQuestionForm.newDefault(),
            address: ClientCaseRelatedTransactionAddressForm.newDefault(),
            users_who_can_receive_quote: [],
        };

const addressFieldsAreFilled = (address: TAddress3) =>
    (address.building_name || address.building_number)
    && address.street_name
    && address.city_town
    && address.postcode
    && address.country !== "unknown"
        ? true
        : false;

const conveyancerFieldsAreFilled = (caseRelatedTransaction: TCasesRelatedTransaction3) =>
    caseRelatedTransaction.conveyancer_name
    && caseRelatedTransaction.conveyancer_organisation_name
    && (
        caseRelatedTransaction.conveyancer_phone_number
        || caseRelatedTransaction.conveyancer_email_address
    );

export const getRelatedTransactionTitle = (props: TContainerStateProps) => {
    switch (props.state.forms.client_case_page.children.case.transaction_type) {
        case "sale":
            return "Tell us if you also need to buy";
        case "purchase":
            return "Tell us if you also need to sell";
        default:
            return "Tell us if you also need to buy or sell";
    }
}

export const getRelatedTransactionQuestionLabel = (props: TContainerStateProps) => {
    switch (props.state.forms.client_case_page.children.case.transaction_type) {
        case "sale":
            return "Do you need to purchase a property before you complete on the sale of this one?";
        case "purchase":
            return "Do you need to sell a property in order to purchase this one?";
        default:
            return "Do you need to purchase or sell another property as well?";
    }
}

export const getRelatedTransactionStatus = (props: TContainerStateProps): TOnboardingTabStatus => {
    if (
        (
            (
                props.state.forms.client_case_page.children.related_transaction?.question.original.related_transaction_needed_before_completion === "no"
                || (
                    props.state.forms.client_case_page.children.related_transaction?.question.original.related_transaction_needed_before_completion === "yes"
                    && (
                        (
                            props.state.forms.client_case_page.children.case.transaction_type === "sale"
                            && getRelatedTransaction(props).question.original.related_purchase_address_known === "yes"
                            && addressFieldsAreFilled(getRelatedTransaction(props).address.original)
                            && (
                                props.state.forms.client_case_page.children.related_transaction?.address.original.wants_quote === "yes"
                                || (
                                    props.state.forms.client_case_page.children.related_transaction?.address.original.wants_quote === "no"
                                    && conveyancerFieldsAreFilled(props.state.forms.client_case_page.children.related_transaction?.address.original)
                                )
                            )
                        ) || (
                            props.state.forms.client_case_page.children.case.transaction_type === "purchase"
                            && addressFieldsAreFilled(getRelatedTransaction(props).address.original)
                            && props.state.forms.client_case_page.children.related_transaction?.address.original.sale_is_on_market !== "unknown"
                            && (
                                props.state.forms.client_case_page.children.related_transaction?.address.original.wants_quote === "yes"
                                || (
                                    props.state.forms.client_case_page.children.related_transaction?.address.original.wants_quote === "no"
                                    && conveyancerFieldsAreFilled(props.state.forms.client_case_page.children.related_transaction?.address.original)
                                )
                            )
                        )
                    )
                )
            )
            && props.state.forms.client_case_page.children.related_transaction?.question.status === "untouched"
            && props.state.forms.client_case_page.children.related_transaction?.address.status === "untouched"
        ) || props.state.forms.client_case_page.children.related_transaction?.address.children.linked_case !== null
    ) {
        return "completed";
    }

    if (
        props.state.forms.client_case_page.children.case.transaction_type === "sale"
        && props.state.forms.client_case_page.children.related_transaction?.question.original.related_transaction_needed_before_completion === "yes"
        && props.state.forms.client_case_page.children.related_transaction?.question.original.related_purchase_address_known === "not_yet"
        && props.state.forms.client_case_page.children.related_transaction?.question.status === "untouched"
    ) {
        return "deferred";
    }
    
    return "active";
};

export default FrontRelatedTransactionContainer;
