import React from "react";
import { CRMColors } from "../../../models/CRMColors";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { SpacingColumn } from "../../BuildingBlocks/SpacingColumn";
import { CRMParagraph } from "../../Simple/CRMParagraph/CRMParagraph";
import { CRMIconCardPopout } from "../../Simple/CRMIconCardPopout/CRMIconCardPopout";
import { WeightBold } from "../../WeightBold/WeightBold";

export const CRMLegalCaseHasHomeListingIconCardPopout = (props: React.PropsWithChildren<{
}>): JSX.Element => {

    return <CRMIconCardPopout
        iconName="sailboat"
        iconColour={CRMColors.NEUTRAL_PAPER}
        iconBackgroundColour={CRMColors.PRIMARY_6}
        cardBackgroundColour={CRMColors.PRIMARY_12}
    >
        <SpacingColumn spacing={CRMSpacing.TINY}>
            {/* TITLE */}
            <CRMParagraph>
                <WeightBold>
                    SAIL HOMES CASE
                </WeightBold>
            </CRMParagraph>
                Sail Homes has a listing related to this case
            <CRMParagraph>
                
            </CRMParagraph>
        </SpacingColumn>
    </CRMIconCardPopout>;
};
