import React from "react";
import { CRMIcon, TIcon } from "../CRMIcon/CRMIcon";
import { CRMSpacing } from "../../models/CRMSpacing";
import { SpacingRow } from "../BuildingBlocks/SpacingRow";
import { CRMInputCheckboxComponent } from "../CRMInputCheckboxComponent/CRMInputCheckboxComponent";
import { useHover } from "../../hooks/useHover";
import { CRMColors } from "../../models/CRMColors";
import { THexColor } from "../../models/StringLiterals";

export type TAction = {
    iconName: TIcon;
    title: string;
    onClick: () => void;
}

type TTableRowProps = {
    onHoverFace?: JSX.Element;
    isSelected?: boolean;
    actions: Array<TAction>;
    backgroundColour?: THexColor;
    onSelect?: () => void;
    onClick?: () => void;
};

export const CRMTableRow = (props: React.PropsWithChildren<TTableRowProps>): JSX.Element => {

    const [ref, isHovered] = useHover<HTMLDivElement>({});

    const isSelectable = () => typeof props.onSelect === "function";

    const showOnHoverFace = (): boolean => !!props.onHoverFace && isHovered;

    return (
        <div 
            ref={ref}
            className={`crm-table-row`}
            style={{
                backgroundColor: props.backgroundColour || CRMColors.NEUTRAL_PAPER,
            }}
        >
            <SpacingRow
                justifyContent="space-between"
                height="44px"
                spacing={CRMSpacing.SMALL}
                childSize={`${isSelectable() ? "1fr auto" : "1fr"}`
            }>
                {/* NO HOVER - FACE */}
                <div 
                    className={`
                        crm-table-row__body
                        crm-table-row__body--${showOnHoverFace() ? "hidden" : "shown"}
                    `}
                    onClick={props.onClick}
                >
                    {props.children}
                </div>
                
                {/* HOVER - FACE (ABSOLUTE) */}
                <div 
                    className={`
                        crm-table-row__hover-body
                        crm-table-row__hover-body--${showOnHoverFace() ? "shown" : "hidden" }
                    `}
                    onClick={props.onClick}
                >
                    {props.onHoverFace}
                </div>

                {/* HOVER - ACTIONS */}
                <div className={`
                    crm-table-row__hover-face-actions-container
                    crm-table-row__hover-face-actions-container--${isHovered && props.actions.length > 0 ? "shown" : "hidden"}
                `}>
                    <SpacingRow
                        justifyContent="space-between"
                        spacing={CRMSpacing.TINY}
                        height="44px"
                        alignItems="center"
                    >   
                        {props.actions.map((action, index) => (
                            <div key={index}>
                                <CRMIcon
                                    iconName={action.iconName}
                                    colour="neutral-ink"
                                    size="medium"
                                    title={action.title}
                                    cursor="pointer"
                                    onClick={action.onClick}
                                />
                            </div>
                        ))}

                        {typeof props.onSelect === "function" && 
                            <CRMInputCheckboxComponent
                                checked={props.isSelected || false}
                                cursor="pointer"
                                size="medium"
                                onClick={props.onSelect}
                            />
                        }
                    </SpacingRow>
                </div>

                {/* ON SELECT - SHOW CHECKBOX */}
                {typeof props.onSelect === "function" && 
                    <CRMInputCheckboxComponent
                        checked={props.isSelected || false}
                        cursor="pointer"
                        size="medium"
                        onClick={props.onSelect}
                    />
                }
            </SpacingRow>
        </div>
    );
};

