import React from "react";
import { CRMIcon } from "../../CRMIcon/CRMIcon";
import { CRMColors } from "../../../models/CRMColors";
import { SpacingRow } from "../../BuildingBlocks/SpacingRow";
import { CRMParagraph } from "../../Simple/CRMParagraph/CRMParagraph";
import { WeightSemiBold } from "../../WeightSemiBold/WeightSemiBold";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { formatDate } from "../../../../../shared/src/utilsByDomain/dateTime";
import { WeightBold } from "../../WeightBold/WeightBold";
import { Strikethrough } from "../../BuildingBlocks/Strikethrough";
import { CRMRoadblockChecklistItemBase } from "../CRMRoadblockChecklistItemBase/CRMRoadblockChecklistItemBase";
import { PreserveLineBreaksNotSpaces } from "../../BuildingBlocks/PreserveLineBreaksNotSpaces";
import { Background } from "../../BuildingBlocks/Background";

type TRoadblockChecklistItemManualProps = {
    mode: "first" | "final";
    title: string;

    isDisabledFirstCheck: boolean;
    isDisabledFinalCheck: boolean;

    isCheckedFirst: boolean;
    onChangeFirstCheck: (value: boolean) => void;
    isCheckedFinal: boolean;
    onChangeFinalCheck: (value: boolean) => void;

    resolvedBy: string | null;
    resolvedOn: string | null;
};

export const CRMRoadblockChecklistItemManual = (props: React.PropsWithChildren<TRoadblockChecklistItemManualProps>): JSX.Element => {

    const IsFinalMode = props.mode === "final";
    const ResolutionDateCopy = props.resolvedOn ? formatDate(props.resolvedOn, "d LLL") : "";

    const getCheckboxIcon = (checkValue: boolean) => checkValue ? "checkbox-checked" : "checkbox-unchecked";
    
    const onChangeFirstCheck = () => {
        if (!props.isDisabledFirstCheck) {
            let newValue = !props.isCheckedFirst;
            props.onChangeFirstCheck(newValue);
            if (IsFinalMode && newValue === false) {
                props.onChangeFinalCheck(false);
            }
        }
    }

    const onChangeFinalCheck = () => {
        if (!props.isDisabledFinalCheck) {
            props.onChangeFinalCheck(!props.isCheckedFinal);
        }
    };

    return (
        <CRMRoadblockChecklistItemBase
            ParagraphElement={
                props.isCheckedFirst ?
                    <>Resolved by <WeightBold><PreserveLineBreaksNotSpaces>{props.resolvedBy}</PreserveLineBreaksNotSpaces></WeightBold> on <WeightBold>{ResolutionDateCopy}</WeightBold></>
                    : null
            }
            isDisabledFinalCheck={props.isDisabledFinalCheck}
            isDisabledFirstCheck={props.isDisabledFirstCheck}
            isShowingCheck={IsFinalMode && props.isCheckedFirst}
            isCheckedFinal={props.isCheckedFinal}
            onChangeFinalCheck={onChangeFinalCheck}
        >
            <SpacingRow 
                spacing={CRMSpacing.TINY}
                onClick={onChangeFirstCheck}
                cursorStyle={props.isDisabledFinalCheck && props.isDisabledFirstCheck ? "default" : "pointer"}
                alignItems="flex-start"
            >
                <CRMIcon
                    iconName={getCheckboxIcon(props.isCheckedFirst)}
                    colour="hex"
                    hexColour={CRMColors.NEUTRAL_INK}
                />
                
                <Background padding={`2px 0px 0px 0px`}>
                    <CRMParagraph>
                        <WeightSemiBold>
                            {props.isCheckedFirst && !IsFinalMode &&
                                <Strikethrough>{props.title}</Strikethrough>
                            }
                            {(!props.isCheckedFirst || IsFinalMode) &&
                                <>{props.title}</>
                            }
                        </WeightSemiBold>
                    </CRMParagraph>
                </Background>
            </SpacingRow>
        </CRMRoadblockChecklistItemBase>
    );
};
