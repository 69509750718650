import React from "react";
import { THexColor } from "../../../models/StringLiterals";
import { Background } from "../../BuildingBlocks/Background";
import { SpacingRow } from "../../BuildingBlocks/SpacingRow";
import { FontQuicksand } from "../../BuildingBlocks/FontQuicksand";
import { FontSize } from "../../BuildingBlocks/FontSize";
import { CRMFontSizes } from "../../../models/CRMFontSizes";
import { TextColor } from "../../BuildingBlocks/TextColor";
import { WeightBold } from "../../WeightBold/WeightBold";
import { Shape } from "../../BuildingBlocks/Shape";
import { FontRoboto } from "../../BuildingBlocks/FontRoboto";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { CRMIcon, TIcon } from "../../CRMIcon/CRMIcon";

type TStatusPillProps = {
    pillColor: THexColor;
    borderColor: THexColor;
    seperatorBorderColor?: THexColor;
    textColor: THexColor;
    icon?: TIcon;
    label?: string;
    subLabel?: string;
    subLabelFont?: "roboto" | "quicksand"
    onClick?: () => void;
};

export const CRMStatusPill = (props: React.PropsWithChildren<TStatusPillProps>): JSX.Element => {

    return (
        <Background
            color={props.pillColor}
            borderRadius="8px"
            borderDefinition={`1px solid ${props.borderColor}`}
            padding="0px 10px"
            width="fit-content"
            display="flex"
            height="24px"
            alignItems="center"
            cursor={!!props.onClick ? "pointer" : "auto"}
            onClick={props.onClick}
        >
            <SpacingRow spacing={CRMSpacing.TINY}>

                {!!props.icon &&
                    <CRMIcon
                        iconName={props.icon}
                        size="18px"
                        colour="hex"
                        hexColour={props.textColor}
                    />
                }

                {/* LABEL */}
                {!!props.label &&
                    <FontQuicksand>
                        <FontSize size={CRMFontSizes.SMALL_PLUS} lineHeight={1}>
                            <TextColor color={props.textColor}>
                                <WeightBold>
                                    {props.label}
                                </WeightBold>
                            </TextColor>
                        </FontSize>
                    </FontQuicksand>
                }

                {!!props.subLabel &&
                    <Shape
                        height="100%"
                        width="1px"
                        color={props.seperatorBorderColor || props.borderColor}
                    />
                }

                {!!props.subLabel &&
                    <FontSize size={15}>
                        <TextColor color={props.textColor}>
                            <WeightBold>
                            {(!props.subLabelFont || props.subLabelFont === "roboto") &&
                                <FontRoboto>
                                    {props.subLabel}
                                </FontRoboto>
                            }
                            {props.subLabelFont === "quicksand" &&
                                <FontQuicksand>
                                    {props.subLabel}
                                </FontQuicksand>
                            }
                            </WeightBold>
                        </TextColor>
                    </FontSize>
                }
            </SpacingRow>
        </Background>
    );
};
