import { pipe } from "fp-ts/lib/function";
import React from "react";
import { QuoteRateOverlappingForm_fold, TQuoteRateCreatedForm, TQuoteRateLiveRate } from "../../../../../domain/codecs/form/QuoteRateForm";
import { TUuidForm } from "../../../../../domain/codecs/form/UuidForm";
import { useHover } from "../../../hooks/useHover";
import { CRMColors } from "../../../models/CRMColors";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { Absolute } from "../../BuildingBlocks/Absolute";
import { Background } from "../../BuildingBlocks/Background";
import { Relative } from "../../BuildingBlocks/Relative";
import { SpacingColumn } from "../../BuildingBlocks/SpacingColumn";
import { SpacingRow } from "../../BuildingBlocks/SpacingRow";
import { TextColor } from "../../BuildingBlocks/TextColor";
import { CRMIcon } from "../../CRMIcon/CRMIcon";
import { CRMParagraph } from "../../Simple/CRMParagraph/CRMParagraph";
import { CursorPointer } from "../../CursorPointer/CursorPointer";
import { WeightSemiBold } from "../../WeightSemiBold/WeightSemiBold";
import { CRMLiveQuoteRateCardOverlapping } from "../CRMLiveQuoteRateCard/CRMLiveQuoteRateCardOverlapping";
import { CRMQuoteRateCardDetail } from "../CRMQuoteRateCardDetail/CRMQuoteRateCardDetail";
import { CRMCreatedQuoteRateCardOverlapping } from "./CRMCreatedQuoteRateCardOverlapping";

export const CRMCreatedQuoteRateCard = (props: React.PropsWithChildren<{
    form: TQuoteRateCreatedForm,
    onDelete: (form: TUuidForm) => void,
    onOpen: (form: TQuoteRateCreatedForm | TQuoteRateLiveRate) => void,
}>): JSX.Element => {
    const [ref, isHovering] = useHover<HTMLDivElement>({});

    const hasMissingFees = props.form.children.missing_fees_preventing_publish;

    const hasOverlappingRate = props.form.children.overlapping_rates_preventing_publish.length > 0;

    return <Background
        divRef={ref}
        borderRadius="3px"
        color={CRMColors.NEUTRAL_PAPER}
        padding={CRMSpacing.MEDIUM}
    >
        <Relative width="100%">
            <CursorPointer onClick={() => props.onOpen(props.form)}>
                <SpacingColumn spacing={CRMSpacing.MEDIUM}>
                    <SpacingRow
                        spacing={CRMSpacing.SMALL}
                        justifyContent="start"
                        alignItems="flex-start"
                    >
                        {/* ICON */}
                        <Background
                            borderRadius="5px"
                            color={CRMColors.HIGHLIGHTS_POSITIVE_GREEN_12}
                            padding={CRMSpacing.TINY}
                        >
                            <CRMIcon
                                iconName="add"
                                size="large"
                                colour="highlights-positive-green-0"
                            />
                        </Background>

                        {/* DETAIL */}
                        <CRMQuoteRateCardDetail
                            form={props.form.original}
                        />
                    </SpacingRow>

                    {/* ERRORS - MISSING FEES */}
                    {hasMissingFees &&
                        <Background padding={`0px ${CRMSpacing.SMALL}`}>
                            <CRMParagraph>
                                <WeightSemiBold>
                                    <TextColor color={CRMColors.HIGHLIGHTS_INSTRUCTIONAL_BRICK_0}>
                                        This rate can't be published yet as it hasn't been fully configured.
                                    </TextColor>
                                </WeightSemiBold>
                            </CRMParagraph>
                        </Background>
                    }

                    {/* ERRORS - OVERLAPPING RATE */}
                    {hasOverlappingRate &&
                        <Background padding={`0px ${CRMSpacing.SMALL}`}>
                            <SpacingColumn spacing={CRMSpacing.MEDIUM}>
                                {/* ERROR MESSAGE */}
                                <CRMParagraph>
                                    <WeightSemiBold>
                                        <TextColor color={CRMColors.HIGHLIGHTS_INSTRUCTIONAL_BRICK_0}>
                                            This rate can't be created as it creates an overlap with another rate:
                                        </TextColor>
                                    </WeightSemiBold>
                                </CRMParagraph>

                                {/* OVERLAPPING RATES */}
                                <SpacingColumn spacing={CRMSpacing.SMALL}>
                                    {props.form.children.overlapping_rates_preventing_publish.map((f) =>
                                        pipe(
                                            f,
                                            QuoteRateOverlappingForm_fold({
                                                onQuoteRateLiveRate: (f) =>
                                                    <CRMLiveQuoteRateCardOverlapping
                                                        form={f}
                                                    />,
                                                onQuoteRateCreatedOverlappingForm: (f) =>
                                                    <CRMCreatedQuoteRateCardOverlapping
                                                        form={f}
                                                    />
                                            }),
                                        )
                                    )}
                                </SpacingColumn>
                            </SpacingColumn>
                        </Background>
                    }
                </SpacingColumn>
            </CursorPointer>

            {/* CONTROL */}
            {isHovering &&
                <Absolute
                    top="0px"
                    right="0px"
                >
                    <CRMIcon
                        iconName="delete"
                        size="medium"
                        colour="neutral-ink"
                        onClick={() => props.onDelete(props.form.children.delete_form)}
                    />
                </Absolute>
            }
        </Relative>
    </Background>
}
