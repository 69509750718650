import { TJobCaseAdHoc, TJobLegalCaseBasic, TJobListingAdHoc, TJobListingBasic, TJobListingCarSale, TJobListingEnquiryAdHoc, TJobListingEnquiryBasic, TJobListingEnquiryOffersShareFeedbackWithBuyer, TJobListingPublicNotice, TJobListingService } from "../../../../../domain/codecs/Job";
import { TCRMListingFormSections } from "../../../models/CRMFormSections";
import { TCaseTabs } from "../../../pages/CRMLegalCasePage/CRMLegalCasePage";
import { createChangeRouteAction } from "../../../state/router/createChangeRouteAction"
import { TBlocksParamsObject, TChangeRouteAction, TRouteActionType, TRouteActionTypeWithBlocks } from "../../../state/router/routerTypes";
import { getUrlStringForRoute } from "../../../state/router/getUrlStringsForRoute";
import { paths } from "../../../state/router/routerPaths";
import { TRoadblocksListItem } from "../../../../../domain/codecs/form/RoadblockForm";

export const getGoToListingRouteAction = (props: {
    visibleSections: TCRMListingFormSections[number] | TCRMListingFormSections;
    job: TJobListingBasic | TJobListingPublicNotice | TJobListingService | TJobListingCarSale;
}) => createChangeRouteAction(
    "VIEW_CRM_LISTING",
    {
        listingId: props.job.listing_id
    },
    {
        visible_sections: typeof props.visibleSections === "string" 
            ? props.visibleSections 
            : props.visibleSections.join("|"),
    }
);

export const getAdHocGoToListingRouteAction = (props: {
    job: TJobListingAdHoc
}) => createChangeRouteAction(
    "VIEW_CRM_LISTING", 
    {
        listingId: props.job.listing_id
    },
    {
        visible_tab: props.job.section,
    }
);

type TEnquiryJobs = 
    TJobListingEnquiryBasic 
    | TJobListingEnquiryAdHoc 
    | TJobListingEnquiryOffersShareFeedbackWithBuyer
;

export const getGoToEnquiryRouteAction = (props: {
    job: TEnquiryJobs
}) => createChangeRouteAction(
    "VIEW_CRM_ENQUIRY", 
    {
        enquiryId: props.job.listings_enquiry_id
    },
    {}
);


type BlocksArray = TBlocksParamsObject<TRouteActionTypeWithBlocks>;

export const getGoToCaseRouteAction = (props: {
    job: TJobLegalCaseBasic | TJobCaseAdHoc;
    visibleTab?: TCaseTabs;
    visibleBlocks?: BlocksArray;
}) => {

    // [REFACTOR] this need a proper place to live, preferably inside
    // the actual block router container thing
    const visibleBlocksDefault: BlocksArray = [
        {
            type: "case_details",
            meta: {
                visible_sections: "case_milestones"
            },
        },
        {
            type: "case_overview",
            meta: {},
        },
        {
            type: "case_roadblocks",
            meta: {}
        }
    ];

    const providedVisibleBlocksLength = props.visibleBlocks?.length ?? 0;

    // Overlap the provided roadblocks and the defaults, preferentially picking the provided ones
    const visibleBlocksToSet: BlocksArray = [
        ...(props.visibleBlocks ?? []),
        ...visibleBlocksDefault.slice(providedVisibleBlocksLength)
    ]

    return createChangeRouteAction(
        "VIEW_CRM_LEGAL_CASE", 
        {
            caseId: props.job.case_id,
        },
        {
            visible_tab: props.visibleTab || "",
        },
        visibleBlocksToSet,
    );
}

export const getGoToRoadblockRouteAction = (roadblock: TRoadblocksListItem) => 
    createChangeRouteAction(
        "VIEW_CRM_LEGAL_CASE",
        { caseId: roadblock.case_id },
        { visible_tab: "details" },
        [
            { 
                type: "case_details", 
                meta: {}
            },
            {
                type: "case_overview",
                meta: {}
            },
            { 
                type: "case_roadblocks", 
                meta: { openRoadblockID: roadblock.id }
            },
        ]
    )
;


export const getGoToListingOffersRouteAction = (props: {
    job: TJobListingBasic; 
}) => createChangeRouteAction(
    "VIEW_CRM_LISTING_PERFORMANCE", 
    {
        listingId: props.job.listing_id,
    },
    {}
);


export const getGoToLegalIntroducersRouteAction = () =>
    createChangeRouteAction(
        "VIEW_CRM_LEGAL_INTRODUCERS", 
        {},
        {}
    )
;

export const getGoToProbateCallbacksRouteAction = () =>
    createChangeRouteAction(
        "VIEW_CRM_PROBATE_HELPLINE_CALLBACKS", 
        {},
        {}
    )
;

export const makeJobLinkOutOfRouteAction = (routeAction: TChangeRouteAction<TRouteActionType>) =>
    getUrlStringForRoute(
        paths,
        routeAction.type,
        routeAction.params,
        routeAction.queryStringParams,
        routeAction.blockParams
    )
;