import React from "react";
import { pipe } from "fp-ts/pipeable";
import { array } from "fp-ts";
import { contains } from "../../../../../../shared/src/utilsByDomain/array"
import { CaseEnquiry1Sort, TCaseEnquiry1 } from "../../../../../../domain/codecs/CaseEnquiry";
import { TEmailAttachmentSearchForm } from "../../../../../../domain/codecs/form/EmailAttachmentSearchForm";
import { IFuzzySortOption } from "../../../../hooks/useFuzzysort";
import { toDisplayString as CaseDocumentTypeToCopyText } from "../../../../../../domain/models/CaseDocumentType1";
import { TCasesDocumentFile1 } from "../../../../../../domain/codecs/CasesDocument";
import { CRMEnquiryAttachment } from "../CRMEnquiryAttachment/CRMEnquiryAttachment";
import { CRMFileAttachment } from "../CRMFileAttachment/CRMFileAttachment";
import { TEmailAttachments } from "../../../../../../domain/codecs/EmailComposition";
import { CRMFuzzySortSearchHighlight } from "../../../Simple/CRMFuzzySortSearchHighlight/CRMFuzzySortSearchHighlight";
import { CRMFilterAndSelectTinyArrowOptionWrap } from "../../../Simple/CRMFilterAndSelectTinyArrowOptionWrap/CRMFilterAndSelectTinyArrowOptionWrap";
import { CRMFilterSearchSelect } from "../../../Complex/CRMFilterSearchSelect/CRMFilterSearchSelect";
import { Relative } from "../../../BuildingBlocks/Relative";
import { TCaseChargeLegalFile2 } from "../../../../../../domain/codecs/CaseChargeLegalFile";
import { CRMRedemptionStatementAttachment } from "../../../Complex/CRMRedemptionStatementAttachment/CRMRedemptionStatementAttachment";
import { TQuoteEmailAttachment } from "../../../../../../domain/codecs/Quote";
import { CRMQuoteAttachment } from "../../../Complex/CRMQuoteAttachment/CRMQuoteAttachment";

export type TAttachmentType = "enquiries" | "files" | "redemption_statements" | "quotes";

type TAttachmentFuzzySearchMeta = {
    type: TAttachmentType;
    enquiry?: TCaseEnquiry1;
    file?: TCasesDocumentFile1;
    redemption_statement?: TCaseChargeLegalFile2,
    quote?: TQuoteEmailAttachment,
};

type TCRMEmailAttachmentFilterAndSelectPopoutProps = {
    selectedAttachments: TEmailAttachments;
    searchAttachmentForm: TEmailAttachmentSearchForm;
    onClick: (attachmentType: TAttachmentType, id: string) => void;
};

export const CRMEmailAttachmentFilterSearchSelect = (props: React.PropsWithChildren<TCRMEmailAttachmentFilterAndSelectPopoutProps>): JSX.Element => {

    const enquiryToSearchableLabel = (enquiry: TCaseEnquiry1): string => {
        const documentsAsSearchableString = enquiry.documents
            .map((document) => `${CaseDocumentTypeToCopyText(document.type)} ${document.reference || ""}`)
            .join(" ");

        return `${enquiry.message} ${documentsAsSearchableString}`;
    }

    const fileToSearchLabel = (file: TCasesDocumentFile1): string => {
        return `${CaseDocumentTypeToCopyText(file.type)} ${file.additional_name || ""} ${file.name}.${file.file_extension}}`
    }

    const redemptionStatementToSearchLabel = (redemptionStatement: TCaseChargeLegalFile2): string =>
        `redemption statement ${redemptionStatement.beneficiary}`;

    const quoteToSearchLabel = (quote: TQuoteEmailAttachment): string =>
        `quote ${quote.created_at}`;

    const enquiryToSelectOption = (enquiry: TCaseEnquiry1): IFuzzySortOption<string, TAttachmentFuzzySearchMeta> => ({
        label: enquiryToSearchableLabel(enquiry),
        value: enquiry.id,
        meta: {
            type: "enquiries",
            enquiry,
        },
        richLabelFormatting: (result, searchText) => (
            <EnquiryOption
                result={result}
                searchText={searchText}
                message={enquiry.message}
                onClick={() => props.onClick("enquiries", enquiry.id)}
            />
        )
    });

    const fileToSelectOption = (file: TCasesDocumentFile1): IFuzzySortOption<string, TAttachmentFuzzySearchMeta> => ({
        label: fileToSearchLabel(file),
        value: file.id,
        meta: {
            type: "files",
            file
        },
        richLabelFormatting: (result, searchText) => (
            <FileOption
                result={result}
                searchText={searchText}
                message={`${CaseDocumentTypeToCopyText(file.type)} ${file.additional_name || ""}`}
                onClick={() => props.onClick("files", file.id)}
            />
        )
    });

    const redemptionStatementToSelectOption = (redemptionStatement: TCaseChargeLegalFile2): IFuzzySortOption<string, TAttachmentFuzzySearchMeta> => ({
        label: redemptionStatementToSearchLabel(redemptionStatement),
        value: redemptionStatement.id,
        meta: {
            type: "redemption_statements",
            redemption_statement: redemptionStatement,
        },
        richLabelFormatting: (result, searchText) => (
            <RedemptionStatementOption
                result={result}
                searchText={searchText}
                message={redemptionStatement.beneficiary}
                onClick={() => props.onClick("redemption_statements", redemptionStatement.id)}
            />
        )
    });

    const quoteToSelectOption = (quote: TQuoteEmailAttachment): IFuzzySortOption<string, TAttachmentFuzzySearchMeta> => ({
        label: quoteToSearchLabel(quote),
        value: quote.id,
        meta: {
            type: "quotes",
            quote,
        },
        richLabelFormatting: (result, searchText) => (
            <QuoteOption
                result={result}
                searchText={searchText}
                message={quote.created_at}
                onClick={() => props.onClick("quotes", quote.id)}
            />
        )
    });

    const PopoutOptions = pipe(
        [
            props.searchAttachmentForm.children.enquiries
                .sort(CaseEnquiry1Sort)
                .filter((enquiry) => !contains(enquiry.id)(props.selectedAttachments.enquiries))
                .map(enquiryToSelectOption),
            props.searchAttachmentForm.children.files
                .filter((file) => !contains(file.id)(props.selectedAttachments.files))
                .map(fileToSelectOption),
            props.searchAttachmentForm.children.redemption_statements
                .filter((redemptionStatement) => !contains(redemptionStatement.id)(props.selectedAttachments.redemption_statements))
                .map(redemptionStatementToSelectOption),
            props.searchAttachmentForm.children.quotes
                .filter((quote) => !contains(quote.id)(props.selectedAttachments.quotes))
                .map(quoteToSelectOption),
        ],
        array.flatten
    );

    return (
        <Relative>
            <CRMFilterSearchSelect
                searchStickyTopPosition="51px"
                searchPlaceholder="Search for an enquiry, document, redemption statement or quote"
                noMatchesText="There are no attachments to choose from."
                options={PopoutOptions}
            />
        </Relative>
    );
};


// FILE OPTION
type TFileOptionProps<V extends string> = {
    result: Fuzzysort.KeyResult<IFuzzySortOption<V, TAttachmentFuzzySearchMeta>>;
    message: string;
    searchText: string;
    onClick: () => void;
};

const FileOption = <V extends string>(props: TFileOptionProps<V>): JSX.Element => {
    return (
        <CRMFilterAndSelectTinyArrowOptionWrap onClick={props.onClick}>
            <CRMFileAttachment
                file={props.result.obj.meta?.file as TCasesDocumentFile1}
                fileNameFormatting={(name) => (
                    <CRMFuzzySortSearchHighlight
                        text={name}
                        searchText={props.searchText}
                    />
                )}
                fileTypeFormatting={(type) => (
                    <CRMFuzzySortSearchHighlight
                        text={type}
                        searchText={props.searchText}
                    />
                )}
            />
        </CRMFilterAndSelectTinyArrowOptionWrap>
    );
};


// ENQUIRY OPTION
type TEnquiryOptionProps<V extends string> = {
    result: Fuzzysort.KeyResult<IFuzzySortOption<V, TAttachmentFuzzySearchMeta>>;
    message: string;
    searchText: string;
    onClick: () => void;
};

const EnquiryOption = <V extends string>(props: TEnquiryOptionProps<V>): JSX.Element => {
    return (
        <CRMFilterAndSelectTinyArrowOptionWrap onClick={props.onClick}>
            <CRMEnquiryAttachment
                enquiry={props.result.obj.meta?.enquiry as TCaseEnquiry1}
                documentTypeFormatting={(type) => (
                    <CRMFuzzySortSearchHighlight
                        text={type}
                        searchText={props.searchText}
                    />
                )}
                documentReferenceFormatting={(reference) => (
                    <CRMFuzzySortSearchHighlight
                        text={reference}
                        searchText={props.searchText}
                    />
                )}
                messageFormatting={(message) => (
                    <CRMFuzzySortSearchHighlight
                        text={message}
                        searchText={props.searchText}
                    />
                )}
            />
        </CRMFilterAndSelectTinyArrowOptionWrap>
    );
}

// REDEMPTION STATEMENT OPTION
type TRedemptionStatementOptionProps<V extends string> = {
    result: Fuzzysort.KeyResult<IFuzzySortOption<V, TAttachmentFuzzySearchMeta>>;
    message: string;
    searchText: string;
    onClick: () => void;
};

const RedemptionStatementOption = <V extends string>(props: TRedemptionStatementOptionProps<V>): JSX.Element => {
    return (
        <CRMFilterAndSelectTinyArrowOptionWrap onClick={props.onClick}>
            <CRMRedemptionStatementAttachment
                redemptionStatement={props.result.obj.meta?.redemption_statement as TCaseChargeLegalFile2}
                restrictionHolderFormatting={(restrictionHolder) => (
                    <CRMFuzzySortSearchHighlight
                        text={restrictionHolder}
                        searchText={props.searchText}
                    />
                )}
            />
        </CRMFilterAndSelectTinyArrowOptionWrap>
    );
};

// QUOTE OPTION
type TQuoteOptionProps<V extends string> = {
    result: Fuzzysort.KeyResult<IFuzzySortOption<V, TAttachmentFuzzySearchMeta>>;
    message: string;
    searchText: string;
    onClick: () => void;
};

const QuoteOption = <V extends string>(props: TQuoteOptionProps<V>): JSX.Element => {
    return (
        <CRMFilterAndSelectTinyArrowOptionWrap onClick={props.onClick}>
            <CRMQuoteAttachment
                quote={props.result.obj.meta?.quote as TQuoteEmailAttachment}
                quoteFormatting={(quote) => (
                    <CRMFuzzySortSearchHighlight
                        text={quote}
                        searchText={props.searchText}
                    />
                )}
            />
        </CRMFilterAndSelectTinyArrowOptionWrap>
    );
};
