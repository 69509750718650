import React from "react";
import { TContainerStateProps } from "../../state/TContainerStateProps";
import { SpacingColumn } from "../../components/BuildingBlocks/SpacingColumn";
import { CRMTitleSection } from "../../components/CRMTitleSection/CRMTitleSection";
import { CRMSpacer } from "../../components/CRMSpacer/CRMSpacer";
import { CRMAutosaveIndicatorWrap } from "../../components/CRM/CRMAutosaveIndicatorWrap/CRMAutosaveIndicatorWrap";
import CRMInputLabelAndErrorWrapComponent from "../../components/CRMInputLabelAndErrorWrapComponent/CRMInputLabelAndErrorWrapComponent";
import { SpacingRow } from "../../components/BuildingBlocks/SpacingRow";
import { CRMDropdownClickableForOverlayComponent } from "../../components/CRMDropdownClickableForOverlayComponent/CRMDropdownClickableForOverlayComponent";
import { CRMPlainFilterSearchSelect } from "../../components/Complex/CRMPlainFilterSearchSelect/CRMPlainFilterSearchSelect";
import { CRMTidyPopout } from "../../components/Simple/CRMTidyPopout/CRMTidyPopout";
import { Relative } from "../../components/BuildingBlocks/Relative";
import { CRMPopoutHeader } from "../../components/Simple/CRMPopoutHeader/CRMPopoutHeader";
import { TCaseDualRepSearchResultsForm } from "../../../../domain/codecs/form/CaseForm";
import { CursorPointer } from "../../components/CursorPointer/CursorPointer";
import { CRMIcon } from "../../components/CRMIcon/CRMIcon";
import { doesErrorCodeExist } from "../../../../shared/src/codecs/errors";
import { array } from "fp-ts";
import { pipe } from "fp-ts/lib/function";
import { FormStatus_highestPriority } from "../../../../shared/src/codecs/codec";
import { CRMNoticeBoxComponent } from "../../components/CRMNoticeBoxComponent/CRMNoticeBoxComponent";
import { WeightSemiBold } from "../../components/WeightSemiBold/WeightSemiBold";
import { CRMParagraph } from "../../components/Simple/CRMParagraph/CRMParagraph";
import { Background } from "../../components/BuildingBlocks/Background";
import { CRMSpacing } from "../../models/CRMSpacing";
import { toDisplayString } from "../../../../domain/models/CaseStatus1";

const CRMLegalCaseDetailsDualRepContainer = (props: TContainerStateProps): JSX.Element => {
    const createLinkForm = props.state.forms.case_details_page.data.output.details.children.dual_rep.create_link;

    const linkedCaseForm = props.state.forms.case_details_page.data.output.details.children.dual_rep.linked_case_form;

    const isTransactionTypeSet = props.state.forms.case_details_page.data.output.details.children.transaction_type !== "unknown";

    const getLinkableCaseForm = (formCaseId): TCaseDualRepSearchResultsForm | undefined =>
        createLinkForm.children.find((linkableCase) => linkableCase.original.to_case_id === formCaseId);

    const onClickLinkCase = (formCaseId) => {
        const linkableCase = getLinkableCaseForm(formCaseId);
        if (linkableCase) {
            props.dispatch({
                type: "LEGAL_CASE_LINK_DUAL_REP",
                payload: linkableCase,
            })
        }
    };

    const onClickUnlink = () => {
        if (linkedCaseForm) {
            props.dispatch({
                type: "LEGAL_CASE_UNLINK_DUAL_REP",
                payload: linkedCaseForm,
            });
        }
    }

    const hasError = () =>
        pipe(
            createLinkForm.children,
            array.filter(({status, validationErrors}) =>
                status === "validationError" && doesErrorCodeExist("InUse", validationErrors)
            ),
            ({length}) => length > 0,
        );

    return <>
        <CRMAutosaveIndicatorWrap
            status={pipe(
                [
                    createLinkForm.status,
                    ...createLinkForm.children.map(({status}) => status),
                ],
                FormStatus_highestPriority,
            )}
        >
            <CRMTitleSection>
                Dual Rep
            </CRMTitleSection>
            <CRMSpacer size="large" />

            {/* LINK AND UNLINK CONTROLS */}
            {isTransactionTypeSet &&
                <SpacingColumn spacing="medium">
                    <CRMInputLabelAndErrorWrapComponent
                        label="Link to the other case"
                        displayError={hasError()}
                        errorMessage="The case you are trying to link to is already linked to a different case."
                    >
                        <SpacingRow
                            spacing="tiny"
                            alignItems="center"
                            childSize="1fr auto"
                        >
                            {/* CASE LINK */}
                            <CRMDropdownClickableForOverlayComponent
                                label={
                                    linkedCaseForm
                                        ? `Linked: ${linkedCaseForm.children.klyant_matter_id} - ${linkedCaseForm.children.address} (${toDisplayString(linkedCaseForm.children.other_case_status)})`
                                        : "None linked"
                                }
                            >
                                <CRMTidyPopout
                                    width="450px"
                                    height="400px" 
                                >
                                    <Relative>
                                        <CRMPopoutHeader>
                                            Link with case: 
                                        </CRMPopoutHeader>
                                        <Background
                                            padding={`${CRMSpacing.MEDIUM} ${CRMSpacing.MEDIUM} 0px ${CRMSpacing.MEDIUM}`}
                                        >
                                            <CRMParagraph>
                                                <WeightSemiBold>
                                                    Only cases with their transaction type set will be shown.
                                                </WeightSemiBold>
                                            </CRMParagraph>
                                        </Background>
                                        <CRMPlainFilterSearchSelect
                                            searchStickyTopPosition="51px"
                                            keepOriginalSearchResultsOrder={true}
                                            noMatchText=""
                                            placeholder="Search for a case by address"
                                            searchFormStatus={createLinkForm.status}
                                            onChangeSearchText={(search_term) => 
                                                props.dispatch({
                                                    type: "LEGAL_CASE_UPDATE_DUAL_REP_SEARCH_TERMS",
                                                    payload: {
                                                        ...createLinkForm,
                                                        edited: {
                                                            ...createLinkForm.edited,
                                                            search_term,
                                                        }
                                                    },
                                                })
                                            }
                                            onClick={onClickLinkCase}
                                            options={
                                                createLinkForm.children.map((form) => ({
                                                    label: `${form.children.addresses} - ${form.children.klyant_matter_id} (${toDisplayString(form.children.status)})`,
                                                    value: form.original.to_case_id,
                                                }))
                                            }
                                        />
                                    </Relative>
                                </CRMTidyPopout>
                            </CRMDropdownClickableForOverlayComponent>

                            {/* DELETE ICON */}
                            {linkedCaseForm &&
                                <CursorPointer
                                    onClick={onClickUnlink}
                                >
                                    <CRMIcon
                                        iconName="backspace"
                                        size="medium"
                                        colour="neutral-ink"
                                    />
                                </CursorPointer>
                            }
                        </SpacingRow>
                    </CRMInputLabelAndErrorWrapComponent>
                </SpacingColumn>
            }

            {/* CANT LINK BECAUSE TRANSACTION TYPE ISNT SET */}
            {!isTransactionTypeSet &&
                <CRMNoticeBoxComponent>
                    You can't link this case to another as Dual Rep <WeightSemiBold>until you have set the transaction type.</WeightSemiBold>
                </CRMNoticeBoxComponent>
            }
        </CRMAutosaveIndicatorWrap>
    </>;
};

export default CRMLegalCaseDetailsDualRepContainer;