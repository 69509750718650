import React from "react";
import * as Listing3 from "../../../../domain/models/Listing3";
import * as EnquirySendFiles1 from "../../../../domain/models/EnquirySendFiles1";
import * as JsonError1 from "../../../../domain/models/JsonError1";
import * as ListingDocumentType2 from "../../../../domain/models/ListingDocumentType2";
import * as ListingDocumentUrl from "../../../../domain/models/ListingDocumentUrl";
import { CRMTitleForCard } from "../CRMTitleForCard/CRMTitleForCard";
import { CRMSpacer } from "../CRMSpacer/CRMSpacer";
import { array } from "fp-ts";
import { CRMParagraph } from "../Simple/CRMParagraph/CRMParagraph";
import { CRMEnquiryFilesListItemComponent } from "../CRMEnquiryFilesListItemComponent/CRMEnquiryFilesListItemComponent";
import { CRMButtonTertiary } from "../CRMButtonTertiary/CRMButtonTertiary";
import { CRMSpacingRow } from "../CRMSpacingRow/CRMSpacingRow";
import { CRMFormButton } from "../CRMFormButton/CRMFormButton";
import { TEnquirySendFiles1Form } from "../../models/TFormModels";
import { CRMFormErrorComponent } from "../CRMFormErrorComponent/CRMFormErrorComponent";
import { pipe } from "fp-ts/lib/function";

export class CRMEnquiryFilesComponent extends React.Component<{
    listing: Listing3.T;
    form: TEnquirySendFiles1Form;
    onChange: <K extends keyof EnquirySendFiles1.T>(key: K, value: EnquirySendFiles1.T[K]) => void;
    onSubmit: () => void;
}> {
    public render (): JSX.Element {
        return (
            <div>
                <CRMTitleForCard>
                    Send files to party
                </CRMTitleForCard>
                <CRMSpacer size="medium" />

                {/* No files to display */}
                {!this.props.listing.video_tour_url && !this.props.listing.documents.length && <CRMParagraph>
                    No files available
                </CRMParagraph>}

                {/* Files to display */}
                {this.props.listing.video_tour_url && <CRMEnquiryFilesListItemComponent
                    label="Video tour"
                    url={this.props.listing.video_tour_url}
                    checked={this.props.form.edit.send_video_tour}
                    onChange={() => this.props.onChange("send_video_tour", !this.props.form.edit.send_video_tour)}
                />}
                { pipe(
                    this.props.listing.documents,
                    array.mapWithIndex((i, document) =>
                        <CRMEnquiryFilesListItemComponent
                            key={i}
                            label={ListingDocumentType2.toDisplayName(document.document_type)}
                            url={ListingDocumentUrl.fromListingDocument2AndAccountName(document, env.REACT_APP_ACCOUNT_NAME || "")}
                            checked={this.props.form.edit.document_ids.includes(document.id)}
                            onChange={()=> this.props.onChange(
                                "document_ids",
                                this.props.form.edit.document_ids.includes(document.id)
                                    ? array.filter<string>((documentId) => documentId !== document.id)(this.props.form.edit.document_ids)
                                    : [
                                        ...this.props.form.edit.document_ids,
                                        document.id,
                                    ]
                            )}
                        />
                    ),
                )}

                {/* Errors */}
                {JsonError1.doTargetKeysHaveInnerErrors(["authorization"], this.props.form.validationErrors) && <CRMFormErrorComponent
                    errorMessage="Your account can not send files. You must change your email first to your @sailhomes one"
                />}
                { pipe(
                    JsonError1.getInnerErrorsForTargetKeys(["send_video_tour", "document_ids"], this.props.form.validationErrors),
                    array.filter((innerError) => innerError.error_code === "NothingToSendValidation"),
                    (errors) => errors.length
                        ? <CRMFormErrorComponent
                            errorMessage="You haven't selected any files to send"
                        />
                        : <></>
                )}
                { pipe(
                    JsonError1.getInnerErrorsForTargetKeys(["enquiryId"], this.props.form.validationErrors),
                    array.filter((innerError) => innerError.error_code === "NoUserWithEmailAddressValidation"),
                    (errors) => errors.length
                        ? <CRMFormErrorComponent
                            errorMessage="No users in this party have an email address. If you just added one save it first before sending"
                        />
                        : <></>
                )}

                {/* Submit button */}
                {(this.props.listing.video_tour_url || this.props.listing.documents.length > 0) && <>
                    <CRMSpacer size="large" />
                    <CRMSpacingRow
                        justifyContent="end"
                    >
                        <CRMFormButton
                            formStatus={this.props.form.status}
                            ButtonElement={CRMButtonTertiary}
                            label="Send"
                            onClick={this.props.onSubmit}
                        />
                    </CRMSpacingRow>
                </>}
            </div>
        );
    }
}
