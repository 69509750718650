import React from "react";
import { pipe } from "fp-ts/lib/function";
import { array, identity, option } from "fp-ts";
import { TStaffUser } from "../../../../../domain/codecs/User";
import { SpacingRow } from "../../BuildingBlocks/SpacingRow";
import { CRMUserAvatar } from "../../Complex/CRMUserAvatar/CRMUserAvatar";
import { Padding } from "../../BuildingBlocks/Padding";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { SpacingColumn } from "../../BuildingBlocks/SpacingColumn";
import { CRMIconAvatar } from "../../Complex/CRMIconAvatar/CRMIconAvatar";
import { CRMColors } from "../../../models/CRMColors";
import { FontQuicksand } from "../../BuildingBlocks/FontQuicksand";
import { Text } from "../../BuildingBlocks/Text";
import { CRMFontSizes } from "../../../models/CRMFontSizes";
import { THexColor } from "../../../models/StringLiterals";
import { TRoadblockAction, TRoadblockCheckItemAction, isChatAssignmentAction, isChatAwaitAction, isChatMessageAction, isRoadblockCheckItemAction, isRoadblockOpenAction, isRoadblockResolvedAction, isSignOffInvalidationAction } from "../../../../../domain/codecs/form/RoadblockForm";
import { getDateDistanceSortNumber, findMostDistantDate, formatDateCurried, getTimeUntilCopyText } from "../../../../../shared/src/utilsByDomain/dateTime";
import { CRMRoadblockMessageText } from "../CRMRoadblockMessageText/CRMRoadblockMessageText";
import { CRMRoadblockActionLog } from "../CRMRoadblockActionLog/CRMRoadblockActionLog";
import { camelCaseToCopyText } from "../../../../../shared/src/util";
import { contains } from "../../../../../shared/src/utilsByDomain/array";
import { CRMSystemLastName, CRMSystemName } from "../../../models/CRMSystem";

type TCRMRoadblockMessageProps = {
    participants: Array<TStaffUser>;
    actions: Array<TRoadblockAction>;
};

export const CRMRoadblockMessage = (props: React.PropsWithChildren<TCRMRoadblockMessageProps>): JSX.Element => {

    const getUserName = () => pipe(
        getUser(getMessageUserID()),
        (option) => option.first_name
    );

    const getParticipantsFullName = (id: string | null) => pipe(
        getUser(id),
        (user) => `${user.first_name} ${user.last_name}`
    );

    const getMessageUser = () => getUser(getMessageUserID());

    const getMessageUserID = () => props.actions[0].action.created_by;

    const getUser = (id: string | null): TStaffUser => pipe(
        props.participants,
        array.findFirst((participant) => participant.id === id),
        option.fold(
            () => ({
                id: "",
                first_name: CRMSystemName,
                last_name: CRMSystemLastName,
                email: "",
            }),
            identity.flatten
        )
    );

    const getTimestamp = () => pipe(
        props.actions,
        array.map<TRoadblockAction, string>((action) => action.action_date),
        findMostDistantDate,
        formatDateCurried("h:mm a"),
        (date) => date.toLowerCase()
    );

    const getOrderedTextActions = () => {
        return props.actions
            .filter((action) =>
                action.tag === "message"
                || action.tag === "sign_off_invalidation"
            )
            .sort((actionA, actionB) => getDateDistanceSortNumber(actionA.action_date, actionB.action_date))
        ;
    }

    const getLogActions = () => props.actions.filter((action) => action.tag !== "message");
    
    const getSingleShowLogActions = () => {
        return getLogActions()
            .filter((action) => action.tag !== "check-item")
            .sort((next, current) => {
                const distanceNumber = getDateDistanceSortNumber(next.action_date, current.action_date);
                const orderNumber = distanceNumber === 0 ? 1 : distanceNumber;
                return isMajorAction(next) && !isMajorAction(current) ?
                    -Math.abs(orderNumber)
                    : orderNumber
                ;
            })
    }

    const getCheckListItemLogActions = () => props.actions.filter(isRoadblockCheckItemAction);

    const isMajorAction = (action: TRoadblockAction) => contains(action.tag)(["await", "closed", "open"]);
    
    return (
        <SpacingRow spacing={CRMSpacing.MEDIUM} alignItems="flex-start">

            {/*IF ROBOT: AVATAR */}
            {getMessageUserID() === null &&
                <CRMIconAvatar
                    icon="robot"
                    iconColor={CRMColors.PRIMARY_2}
                    backgroundColor={CRMColors.HIGHLIGHTS_CALMING_PINK_10}
                />
            }

            {/* IF USER: AVATAR */}
            {getMessageUserID() !== null &&
                <CRMUserAvatar
                    user={getMessageUser()}
                />
            }

            {/* BODY */}
            <Padding type="top" spacing="1px">
                <SpacingColumn>
                    {/* NAME & TIMESTAMP */}
                    <SpacingRow spacing={CRMSpacing.TINY}>
                        <NameText color={CRMColors.NEUTRAL_INK}>
                            {getUserName()}
                        </NameText>
                        <NameText > - </NameText>
                        <NameText >
                            {getTimestamp()}
                        </NameText>
                    </SpacingRow>

                    {/* TEXT MESSAGES */}
                    {getOrderedTextActions().length > 0 &&
                        <Padding type="top" spacing={CRMSpacing.SMALL}>
                            <SpacingColumn spacing={CRMSpacing.SMALL}>
                                {getOrderedTextActions().map((action, index) => (
                                    <div key={index}>
                                        {/* CHAT MESSAGE */}
                                        {isChatMessageAction(action) &&
                                            <CRMRoadblockMessageText text={action.action.note_body} />
                                        }

                                        {/* SIGN OFF INVALIDATION */}
                                        {isSignOffInvalidationAction(action) &&
                                            <CRMRoadblockMessageText text={`I've removed the previous Sign Off when ${action.action.user || "we"} ${action.action.sign_off_invalidated_reason || "made changes"}. A new Sign Off will need to be made.`} />
                                        }
                                    </div>
                                ))}
                            </SpacingColumn>
                        </Padding>
                    }

                    {/* ACTION LOGS (SUMMARY) */}
                    {getLogActions().length > 0 &&
                        <Padding type="top" spacing={CRMSpacing.MEDIUM}>
                            <SpacingColumn spacing={CRMSpacing.TINY}>
                                {getSingleShowLogActions().map((action, index) => (
                                    <div key={index}>
                                        {/* ACTION LOG - ASSIGNMENT */}
                                        {isChatAssignmentAction(action) &&
                                            <CRMRoadblockActionLog>
                                                Assigned to {getParticipantsFullName(action.action.assigned_to_user_id)}
                                            </CRMRoadblockActionLog>
                                        }
                                        
                                        {/* ACTION LOG - AWAIT */}
                                        {isChatAwaitAction(action) &&
                                            <CRMRoadblockActionLog type="yellow-pill">
                                                Awaiting the {camelCaseToCopyText(action.action.blocked_by)} for {getTimeUntilCopyText(action.action.blocked_until)}
                                            </CRMRoadblockActionLog>
                                        }
                                        
                                        {/* ACTION LOG - OPEN */}
                                        {isRoadblockOpenAction(action) &&
                                            <CRMRoadblockActionLog type="blue-pill">
                                                Roadblock opened
                                            </CRMRoadblockActionLog>
                                        }
                                        
                                        {/* ACTION LOG - RESOLVED */}
                                        {isRoadblockResolvedAction(action) &&
                                            <CRMRoadblockActionLog type={"green-pill"}>
                                                Roadblock resolved
                                            </CRMRoadblockActionLog>
                                        }
                                    </div>
                                ))}

                                {getCheckListItemLogActions().length > 0 &&
                                    <CRMRoadblockActionLog>
                                        Added check list items ({getCheckListItemLogActions().length})
                                    </CRMRoadblockActionLog>
                                }
                            </SpacingColumn>
                        </Padding>
                    }
                </SpacingColumn>
            </Padding>
        </SpacingRow>
    );
};

type TNameTextProps = { 
    color?: THexColor 
};

export const NameText = (props: React.PropsWithChildren<TNameTextProps>) => (
    <FontQuicksand>
        <Text 
            size={CRMFontSizes.MED} 
            color={props.color || CRMColors.NEUTRAL_2} 
            weight={700}
        >
            {props.children}
        </Text>
    </FontQuicksand>
);
