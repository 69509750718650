import { tap, switchMap, filter, debounce } from "rxjs/operators";
import { formOperation } from "../../wrappers/formOperation";
import { forkJoin, Observable, of, timer, } from "rxjs";
import { TActionsDefinitionsList } from "./TAction";
import { action, routeAction } from "./actionFunctions";
import { TStateLens } from "../../../../domain/codecs/state";
import { TCodecSetState, TGetCodecState } from "../applyActions";

export const actions: TActionsDefinitionsList = [
    routeAction("VIEW_CRM_LEGAL_CASE", (obs$, lens, setState, getState) => {
        obs$.pipe(
            tap((action) => setState(lens.case_satisfaction_page.edited.id.set(action.params.caseId))),
        ).subscribe();
    }),

    action("CASE_SATISFACTION_VIEW", (obs$, lens, setState, getState) => {
        obs$.pipe(
            $loadTimeline(lens, setState, getState),
        ).subscribe()
    }),
    
    action("CASE_SATISFACTION_UPDATE_FORM", (obs$, lens, setState, getState) => {
        obs$.pipe(
            tap((form) => setState(lens.case_satisfaction_page.set(form)))
        ).subscribe()
    }),
    
    action("CASE_SATISFACTION_TOGGLE_NOTE_PIN", (obs$, lens, setState, getState) => {
        obs$.pipe(
            switchMap((form) => formOperation("UpdateCaseCustomerSatisfactionPin", form)),
            filter((response) => response.status === "success"),
            $loadTimeline(lens, setState, getState),
        ).subscribe()
    }),

    action("CASE_SATISFACTION_GET_DETAILED_EMAIL", (obs$, lens, setState, getState) => {
        obs$.pipe(
            switchMap((form) => 
                forkJoin([
                    of(form),
                    formOperation("GetDetailedEmail", form.children.detailed_email_form)
                ])
            ),
            tap(([form, response]) => {
                setState(
                    lens.case_satisfaction_page.children.actions
                        .where((action) => 
                            action.children.tag === "email"
                            &&  action.children.action.id === form.children.action.id 
                        )
                        .set({
                            ...form,
                            children: {
                                ...form.children,
                                detailed_email_form: response
                            }
                        })
                )
            }),
        ).subscribe()
    }),

    
    // CREATE ACTIONS
    action("CASE_SATISFACTION_SUBMIT_NEW_NOTE", (obs$, lens, setState, getState) => {
        obs$.pipe(
            switchMap((form) => formOperation("CreateCaseCustomerSatisfactionNote", form)),
            tap((response) => setState(lens.case_satisfaction_page.children.create_note_action.set(response))),
            
            filter((response) => response.status === "success"),
            $loadTimeline(lens, setState, getState),
            debounce(() => timer(500)),
            tap(() => setState(lens.case_satisfaction_page.children.create_note_action.status.set("untouched")))
        ).subscribe()
    }),
    
    action("CASE_SATISFACTION_CHANGE_SENTIMENT", (obs$, lens, setState, getState) => {
        obs$.pipe(
            switchMap((form) => formOperation("CreateCaseCustomerSatisfactionSentiment", form)),
            tap((response) => setState(lens.case_satisfaction_page.children.create_sentiment_action.set(response))),
            
            filter((response) => response.status === "success"),
            $loadTimeline(lens, setState, getState),
            debounce(() => timer(500)),
            tap(() => setState(lens.case_satisfaction_page.children.create_sentiment_action.status.set("untouched")))
        ).subscribe()
    }),
    
    action("CASE_SATISFACTION_FORMALISED_AS_A_COMPLAINT", (obs$, lens, setState, getState) => {
        obs$.pipe(
            switchMap((form) => formOperation("CreateCaseCustomerSatisfactionFormalisedAsComplaint", form)),
            tap((response) => setState(lens.case_satisfaction_page.children.create_formalised_as_complaint_action.set(response))),
            
            filter((response) => response.status === "success"),
            $loadTimeline(lens, setState, getState),
            debounce(() => timer(500)),
            tap(() => setState(lens.case_satisfaction_page.children.create_formalised_as_complaint_action.status.set("untouched")))
        ).subscribe()
    }),
    
    action("CASE_SATISFACTION_RAISED_WITH_OMBUDSMAN", (obs$, lens, setState, getState) => {
        obs$.pipe(
            switchMap((form) => formOperation("CreateCaseCustomerSatisfactionRaisedWithOmbudsman", form)),
            tap((response) => setState(lens.case_satisfaction_page.children.create_raised_with_ombudsman_action.set(response))),
            
            filter((response) => response.status === "success"),
            $loadTimeline(lens, setState, getState),
            debounce(() => timer(500)),
            tap(() => setState(lens.case_satisfaction_page.children.create_raised_with_ombudsman_action.status.set("untouched")))
        ).subscribe()
    }),

    action("CASE_SATISFACTION_DEFER", (obs$, lens, setState, getState) => {
        obs$.pipe(
            switchMap((form) => formOperation("CreateCaseCustomerSatisfactionDefer", form)),
            tap((response) => setState(lens.case_satisfaction_page.children.create_defer_action.set(response))),
            
            filter((response) => response.status === "success"),
            $loadTimeline(lens, setState, getState),
            debounce(() => timer(500)),
            tap(() => setState(lens.case_satisfaction_page.children.create_defer_action.status.set("untouched")))
        ).subscribe()
    }),
    
    action("CASE_SATISFACTION_REASSIGNED", (obs$, lens, setState, getState) => {
        obs$.pipe(
            switchMap((form) => formOperation("CreateCaseCustomerSatisfactionAssignment", form)),
            filter((response) => response.status === "success"),
            $loadTimeline(lens, setState, getState),
        ).subscribe()
    }),
    
    // DELETE ACTIONS
    action("CASE_SATISFACTION_DELETE_NOTE", (obs$, lens, setState, getState) => {
        obs$.pipe(
            switchMap((form) => formOperation("DeleteCaseCustomerSatisfactionNote", form)),
            filter((response) => response.status === "success"),
            $loadTimeline(lens, setState, getState),
        ).subscribe()
    }),
    
    action("CASE_SATISFACTION_DELETE_SENTIMENT", (obs$, lens, setState, getState) => {
        obs$.pipe(
            switchMap((form) => formOperation("DeleteCustomerSatisfactionSentiment", form)),
            filter((response) => response.status === "success"),
            $loadTimeline(lens, setState, getState),
        ).subscribe()
    }),
    
    action("CASE_SATISFACTION_DELETE_FORMALISED_AS_A_COMPLAINT", (obs$, lens, setState, getState) => {
        obs$.pipe(
            switchMap((form) => formOperation("DeleteCustomerSatisfactionRaisedAsComplaint", form)),
            filter((response) => response.status === "success"),
            $loadTimeline(lens, setState, getState),
        ).subscribe()
    }),
    
    action("CASE_SATISFACTION_DELETE_RAISED_WITH_OMBUDSMAN", (obs$, lens, setState, getState) => {
        obs$.pipe(
            switchMap((form) => formOperation("DeleteCustomerSatisfactionRaisedWithOmbudsman", form)),
            filter((response) => response.status === "success"),
            $loadTimeline(lens, setState, getState),
        ).subscribe()
    }),


];

const $loadTimeline = <T>(lens: TStateLens, setState: TCodecSetState, getState: TGetCodecState) => 
    (obs$: Observable<T>) =>
        obs$.pipe(
            switchMap(() => formOperation("GetCaseCustomerSatisfaction", lens.case_satisfaction_page.get()(getState()))),
            tap((satisfaction) => setState(lens.case_satisfaction_page.set(satisfaction))),
        )
;
