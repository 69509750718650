import * as QueryStringFilterMappings from "./QueryStringFilterMappings";
import * as QueryStringFilterGroup1 from "./QueryStringFilterGroup1";
import * as QueryStringFilterMappingFunction from "./QueryStringFilterMappingFunction";
import { array, nonEmptyArray } from "fp-ts/lib";
import * as ListingStatus1 from "./ListingStatus1";
import { pipe } from "fp-ts/lib/function";
import { TValidationFunction } from "../../shared/src/validation/Function";

export const filterMappings: QueryStringFilterMappings.T = {
    string: {
        id: QueryStringFilterMappingFunction.createForString("listings.id"),
        sellers_party_id: QueryStringFilterMappingFunction.createForString("listings.sellers_party_id"),
        status: QueryStringFilterMappingFunction.createForString("listings.status"),
    },
    full_text: {
        address: QueryStringFilterMappingFunction.createForFullTextSearch("listings.building_name || ' ' || listings.building_number || ' ' || listings.street_name || ' ' || listings.locality || ' ' || listings.city_town || ' ' || listings.county || ' ' || listings.district || ' ' || listings.postcode || ' ' || REPLACE(listings.postcode, ' ', '')"),
    },
};

export const validator: TValidationFunction= QueryStringFilterGroup1.createValidator(filterMappings);

export const createForAddress = (value: string): QueryStringFilterGroup1.T => ({
    type: "GROUP",
    conditional: "AND",
    filters: [{
        type: "FILTER",
        property: "address",
        operator: "WORD_SEARCH_BEGINS_WITH_AND",
        value,
    }],
});

export const createForParty = (value: string): QueryStringFilterGroup1.T => ({
    type: "GROUP",
    conditional: "AND",
    filters: [{
        type: "FILTER",
        property: "sellers_party_id",
        operator: "EQUALS_STRING",
        value,
    }],
});

export const createWhereIdAnyOf = (ids: nonEmptyArray.NonEmptyArray<string>): QueryStringFilterGroup1.T => ({
    type: "GROUP",
    conditional: "OR",
    filters:  pipe(
        ids,
        array.map((id) => ({
            type: "FILTER",
            property: "id",
            operator: "EQUALS_STRING",
            value: id,
        }))
    ),
});

export const createWhereIdNotAnyOf = (ids: nonEmptyArray.NonEmptyArray<string>): QueryStringFilterGroup1.T => ({
    type: "GROUP",
    conditional: "AND",
    filters:  pipe(
        ids,
        array.map((id) => ({
            type: "FILTER",
            property: "id",
            operator: "NOT_EQUALS_STRING",
            value: id,
        }))
    ),
});

export const createForStatus = (value: ListingStatus1.T): QueryStringFilterGroup1.T => ({
    type: "GROUP",
    conditional: "AND",
    filters: [{
        type: "FILTER",
        property: "status",
        operator: "EQUALS_STRING",
        value,
    }],
});
