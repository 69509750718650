import React from "react";
import { SpacingRow } from "../../../BuildingBlocks/SpacingRow";
import { CRMSpacing } from "../../../../models/CRMSpacing";
import { CRMIcon, TIcon } from "../../../CRMIcon/CRMIcon";
import { CRMColors } from "../../../../models/CRMColors";
import { TCasesCaseViewForm, TCasesCaseViewSortableColumns, TTableSortingDirection } from "../../../../../../domain/codecs/form/CasesForm";
import { onChangeForm } from "../../../../../../shared/src/codecs/types/form";

type TCaseTableHeaderProps = {
    form: TCasesCaseViewForm;
    onChange: (form: TCasesCaseViewForm) => void;
};

export const CRMCaseTableHeader = (props: React.PropsWithChildren<TCaseTableHeaderProps>): JSX.Element => {

    const sortingDirection = props.form.edited.order?.direction || "ascending";

    const onChange = onChangeForm(props.form, props.onChange);

    const isSortingColumnActive = (name: TCasesCaseViewSortableColumns): boolean =>
        props.form.edited.order?.column === name
    ;

    const onOrder = (column: TCasesCaseViewSortableColumns) =>
        (direction: TTableSortingDirection | null) => {
            if (direction === null) {
                onChange("order")(null);
            } else {
                onChange("order")({
                    column,
                    direction
                })
            }
        }
    ;

    return (
        <div className="crm-case-table-header">
            <SpacingRow
                spacing={`${CRMSpacing.LARGE}`}
                childSize="68px 1fr"
            >
                <SpacingRow spacing={CRMSpacing.SMALL}>
                    <HeaderIconCell
                        iconName="pinned-side"
                        isActive={isSortingColumnActive("is_pinned")}
                        direction={sortingDirection}
                        onOrder={onOrder("is_pinned")}
                    />
                    
                    <HeaderIconCell
                        iconName="chase"
                        isActive={isSortingColumnActive("chase_mode")}
                        direction={sortingDirection}
                        onOrder={onOrder("chase_mode")}
                    />
                    
                    <HeaderIconCell
                        iconName="house-filled"
                        isActive={isSortingColumnActive("has_sail_homes_listing")}
                        direction={sortingDirection}
                        onOrder={onOrder("has_sail_homes_listing")}
                    />
                </SpacingRow>

                <SpacingRow 
                    spacing={CRMSpacing.LARGE}
                    childSize="3fr 1.5fr 1fr 1fr 1fr 1fr 90px 90px"
                >
                    <HeaderCell>
                        Address
                    </HeaderCell>
                    
                    <HeaderCell>
                        Clients
                    </HeaderCell>
                    
                    <HeaderCell>
                        Type
                    </HeaderCell>
                    
                    <HeaderCell>
                        Comment
                    </HeaderCell>
                    
                    <HeaderCell>
                        Status
                    </HeaderCell>
                    
                    <HeaderCellSortable
                        isActive={isSortingColumnActive("next_date")}
                        direction={sortingDirection}
                        onOrder={onOrder("next_date")}
                    >
                        Next date
                    </HeaderCellSortable>

                    <HeaderCellSortable
                        isActive={isSortingColumnActive("dead_time")}
                        direction={sortingDirection}
                        onOrder={onOrder("dead_time")}
                    >
                        Dead time
                    </HeaderCellSortable>

                    <HeaderCell>
                        Handler
                    </HeaderCell>

                </SpacingRow>
            </SpacingRow>
        </div>
    );
};

export const HeaderCell = (props: React.PropsWithChildren<{}>) => {
    return (
        <div className="crm-case-table-header__cell">
            {props.children}
        </div>
    )
}


type THeaderCellSortableProps = 
    {
        onOrder: (direction: TTableSortingDirection | null) => void
    }
    & TCellSortingProps
;

export const HeaderCellSortable = (props: React.PropsWithChildren<THeaderCellSortableProps>) => {

    const sortIcon = getSortingIcon(props);
    const onOrder = () => props.onOrder(getSortDirection(props));

    return (
        <HeaderCellWrap
            isActive={props.isActive}
            onClick={onOrder}
        >
            <SpacingRow spacing={'3px'}>
                <div>
                    {props.children}
                </div>
                <CRMIcon
                    iconName={sortIcon}
                    colour="hex"
                    hexColour={props.isActive ? CRMColors.PRIMARY_4 : CRMColors.NEUTRAL_2}
                    size="18px"
                />
            </SpacingRow>
        </HeaderCellWrap>
    )
}


type THeaderIconCellProps = 
    {
        iconName: TIcon;
    }
    & THeaderCellSortableProps
;

export const HeaderIconCell = (props: React.PropsWithChildren<THeaderIconCellProps>) => {

    const sortIcon = getSortingIcon(props, props.iconName);
    const onOrder = () => props.onOrder(getSortDirection(props));

    return (
        <HeaderCellWrap
            isActive={props.isActive}
            onClick={onOrder}
        >
            <CRMIcon
                iconName={sortIcon}
                colour="hex"
                hexColour={props.isActive ? CRMColors.PRIMARY_4 : CRMColors.NEUTRAL_4}
                size="16px"
            />
        </HeaderCellWrap>
    );
}


type THeaderCellWrap = {
    isActive?: boolean;
    onClick?: () => void;
};

export const HeaderCellWrap = (props: React.PropsWithChildren<THeaderCellWrap>) => {
    return (
        <div
            className={`
                crm-case-table-header__cell
                crm-case-table-header__cell--sortable
                crm-case-table-header__cell--${props.isActive ? 'active' : 'in-active'}
            `}
            onClick={props.onClick}
        >
            {props.children}
        </div>
    );
}

type TCellSortingProps = {
    isActive: boolean;
    direction: TTableSortingDirection;
}

const getSortingIcon = (props: TCellSortingProps, inactiveIconReplacement?: TIcon): TIcon => {
    const isAscending = props.direction === "ascending";
    const inactiveIcon = inactiveIconReplacement || "inward-arrows";
    return (
        !props.isActive ? inactiveIcon
        : isAscending   ? "arrow-up"
        : "arrow-down"
    );
};

const getSortDirection = (props: TCellSortingProps): TTableSortingDirection | null => {
    const isAscending = props.direction === "ascending";
    return (
        props.isActive && !isAscending  ? null
        : props.isActive && isAscending ? "descending"
        : "ascending"
    );
};