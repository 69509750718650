import React from "react";
import CRMInputLabelAndErrorWrapComponent from "../CRMInputLabelAndErrorWrapComponent/CRMInputLabelAndErrorWrapComponent";
import { CRMInputCalendarComponent } from "../CRMInputCalendarComponent/CRMInputCalendarComponent";
import { TCRMFormInputPropsWithoutCodec } from "./CRMFormInputTypes";
import { SpacingRow } from "../BuildingBlocks/SpacingRow";
import { CRMSpacing } from "../../models/CRMSpacing";
import { CRMButtonIcon } from "../CRMButtonIcon/CRMButtonIcon";

type TProps =
    TCRMFormInputPropsWithoutCodec<string | null>
    & {
        guidance?: string,
        dateType?:
            "datetime-local"
            | "datetime-local_seconds",
    };

export const CRMCalendarDateTimeWithClearFormInput = (props: TProps) => {
    const dateType = props.dateType || "datetime-local";

    return <CRMInputLabelAndErrorWrapComponent
        label={props.label}
        guidance={props.guidance}
    >
        <SpacingRow spacing={CRMSpacing.TINY}>
            <CRMInputCalendarComponent
                dateType={dateType}
                value={props.model ?? ""}
                onChange={(value) => props.onChange(value)}
                onPressEnterKey={() => null}
                displayError={props.displayError}
                disabled={props.disabled}
            />
            <CRMButtonIcon
                variant="tertiary"
                icon="backspace"
                disabledColour="neutral-4"
                disabled={props.disabled}
                onClick={() => !props.disabled ? props.onChange(null) : undefined}
            />
        </SpacingRow>
    </CRMInputLabelAndErrorWrapComponent>
};
