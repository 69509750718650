import React from "react";
import { FrontSpacing } from "../../../../models/FrontSpacing";
import { SpacingRow } from "../../../BuildingBlocks/SpacingRow";
import { FrontIcon, TIcon } from "../FrontIcon/FrontIcon";

export type TFormSecondaryButtonProps = {
    label: string;
    icon?: TIcon;
    onClick: () => void;
};

export const FrontFormSecondaryButton = (props: React.PropsWithChildren<TFormSecondaryButtonProps>): JSX.Element => {

    return (
        <div
            className={`front-form-secondary-button`}
            onClick={props.onClick}
        >
            <SpacingRow 
                spacing={FrontSpacing.SMALL_3}
                justifyContent="center"
            >
                {props.icon &&
                    <FrontIcon
                        iconName={props.icon}
                        colour="neutral-ink"
                        size="small"
                    />
                }

                <span>{props.label}</span>
            </SpacingRow>
        </div>
    );
};
