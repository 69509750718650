import React from "react";
import { CRMIcon, TIcon } from "../CRMIcon/CRMIcon";
import { array } from "fp-ts";
import { WeightBold } from "../WeightBold/WeightBold";
import { Uppercase } from "../Uppercase/Uppercase";
import { useDropdown } from "../../hooks/UseDropdown";
import { IOption } from "./../../hooks/UseDropdown";
import { pipe } from "fp-ts/lib/function";

interface ICRMDropdownTitleComponent<A extends string, B extends A> {
    value: B;
    options: Array<ICRMDropdownTitleOption<A>>;
    onChange: (value: A) => void;
    placeholder?: string;
}

interface ICRMDropdownTitleOption<A extends string> extends IOption<A> {
    iconName?: TIcon;
}

export const CRMDropdownTitleComponent = <A extends string, B extends A>(props: ICRMDropdownTitleComponent<A, B>): JSX.Element => {
    const {
        ref,
        optionsRef,
        isOpen,
        setIsOpen,
        getFaceText,
        getValueOptionProp,
        isAnythingSelected,
        onKeyboardNavigation,
    } = useDropdown<A, ICRMDropdownTitleOption<A>>(props.value, props.options);

    return (
        <div
            ref={ref}
            className="crm-dropdown-title"
        >
            <div
                className="crm-dropdown-title__selected-option-container crm-dropdown-title__selected-option-container"
                tabIndex={0}
                onClick={() => setIsOpen(!isOpen)}
                onKeyDown={(event) => onKeyboardNavigation(event, props.onChange)}
            >
                <div className="crm-dropdown-title__option crm-dropdown-title__option--selected">
                    {isAnythingSelected() && getValueOptionProp(props.value, "iconName") &&
                        <div className="crm-dropdown-title__icon-container crm-dropdown-title__icon-container--option-icon">
                            <CRMIcon
                                iconName={getValueOptionProp(props.value, "iconName") as TIcon}
                                colour="neutral-ink"
                                size="medium"
                            />
                        </div>
                    }
                    <div className={`
                        crm-dropdown-title__face-text
                        crm-dropdown-title__face-text--${isAnythingSelected() ? 'selected' : 'not-selected'}
                    `}>
                        <WeightBold>
                            <Uppercase>
                                {isAnythingSelected() && getFaceText()}
                                {!isAnythingSelected() && (props.placeholder || "Select Value")}
                            </Uppercase>
                        </WeightBold>
                    </div>
                </div>
                <div className="crm-dropdown-title__icon-container crm-dropdown-title__icon-container--open-close-arrow">
                    <CRMIcon
                        iconName={isOpen ? "up-arrow" : "down-arrow"}
                        colour="neutral-ink"
                    />
                </div>
            </div>
            {/* OPTIONS */}
            <div
                className={`
                    crm-dropdown-title__options-expand-container
                    crm-dropdown-title__options-expand-container${isOpen ? "--visible" : "--hidden"}
                `}
            >
                { pipe(
                    props.options,
                    array.mapWithIndex((i, titleOption) =>
                        <div
                            key={i}
                            ref={optionsRef[i]}
                            tabIndex={isOpen ? 0 : -1}
                            className="crm-dropdown-title__option"
                            onClick={() => {
                                props.onChange(titleOption.value);
                                setIsOpen(false);
                            }}
                            onKeyDown={(event) => onKeyboardNavigation(event, props.onChange)}
                        >
                            {titleOption.iconName &&
                                <div className="crm-dropdown-title__icon-container crm-dropdown-title__icon-container--option-icon">
                                    <CRMIcon
                                        iconName={titleOption.iconName}
                                        colour="neutral-ink"
                                        size="medium"
                                    />
                                </div>
                            }
                            <div className="crm-dropdown-title__option-text">
                                <WeightBold>
                                    <Uppercase>{titleOption.label}</Uppercase>
                                </WeightBold>
                            </div>
                        </div>
                    )
                )}
            </div>
        </div>
    );
};
