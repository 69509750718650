import { tap, mergeMap, debounceTime, switchMap, filter, concatMap } from "rxjs/operators";
import { formOperation } from "../../wrappers/formOperation";
import { Observable } from "rxjs";
import { TListingEnquiryAdHocTasksForm, TListingEnquiryInnerForm, TListingEnquiryNewAdHocTaskForm, TListingEnquiryOfferForm } from "../../../../domain/codecs/formEditable/ListingEnquiryForm";
import { doFormValuesMatch } from "../../../../shared/src/codecs/types/formEditable";
import { TActionsDefinitionsList } from "./TAction";
import { action, routeAction } from "./actionFunctions";

export const actions: TActionsDefinitionsList = [
    routeAction("VIEW_CRM_ENQUIRY", (obs$, lens, set, get) => {
        obs$.pipe(
            tap((a) => set(lens.listing_enquiry_page.edited.listing_enquiry_id.set(a.params.enquiryId))),
            mergeMap(() => formOperation("GetListingEnquiry", lens.listing_enquiry_page.get()(get()))),
            tap((res) => set(lens.listing_enquiry_page.set(res))),
        ).subscribe();
    }),
    action("CRM_ENQUIRY_INPUT_CHANGE", (obs$: Observable<TListingEnquiryInnerForm>, lens, set, get) => {
        obs$.pipe(
            tap((payload) => set(lens.listing_enquiry_page.children.enquiry.set(payload))),
            debounceTime(1000),
            tap(() => set(lens.listing_enquiry_page.children.enquiry.status.set("submitting"))),
            switchMap(() => formOperation("UpdateListingEnquiry", lens.listing_enquiry_page.children.enquiry.get()(get()))),
            tap((response) => set(lens.listing_enquiry_page.children.enquiry.set(response))),
        ).subscribe();
    }),
    action("CRM_ENQUIRY_ADD_AD_HOC_TASK", (obs$: Observable<TListingEnquiryNewAdHocTaskForm>, lens, setState, getState) => {
        obs$.pipe(
            tap((payload) => setState(lens.listing_enquiry_page.children.new_adhoc_task.set(payload))),
            tap(() => setState(lens.listing_enquiry_page.children.new_adhoc_task.status.set("submitting"))),
            switchMap(() => formOperation("CreateListingEnquiryAdHocTask", lens.listing_enquiry_page.children.new_adhoc_task.get()(getState()))),
            tap((response) => setState(lens.listing_enquiry_page.children.new_adhoc_task.set(response))),
            switchMap(() => formOperation("GetListingEnquiry", lens.listing_enquiry_page.get()(getState()))),
            tap((res) => setState(lens.listing_enquiry_page.set(res))),
        ).subscribe();
    }),
    action("CRM_ENQUIRY_UPDATE_AD_HOC_TASKS", (obs$: Observable<Array<TListingEnquiryAdHocTasksForm>>, lens, setState) => {
        obs$.pipe(
            tap((tasks) => setState(lens.listing_enquiry_page.children.adhoc_tasks.set(tasks))),
            concatMap((tasks) => tasks),
            filter((task) => task.status === "requiresSubmission"),
            tap((task) => setState(lens.listing_enquiry_page.children.adhoc_tasks.where(doFormValuesMatch(task, "id")).status.set("submitting"))),
            switchMap((task) => formOperation("UpdateListingEnquiryAdHocTask", task)),
            tap((task) => setState(lens.listing_enquiry_page.children.adhoc_tasks.where(doFormValuesMatch(task, "id")).set(task))),
        ).subscribe();
    }),
    action("CRM_ENQUIRY_UPDATE_OFFER", (obs$: Observable<TListingEnquiryOfferForm>, lens, setState, getState) => {
        obs$.pipe(
            tap((payload) => setState(lens.listing_enquiry_page.children.enquiry_offers.where((form) => form.edited.id === payload.edited.id).set({
                ...payload,
                status: "submitting",
            }))),
            switchMap((payload) => formOperation("UpdateListingEnquiryOffer", lens.listing_enquiry_page.children.enquiry_offers.where((form) => form.edited.id === payload.edited.id).get()(getState()))),
            tap((response) => setState(lens.listing_enquiry_page.children.enquiry_offers.where((form) => form.edited.id === response.edited.id).set(response))),
        ).subscribe();
    }),
];
