import { TAddress1 } from "../Address";

// This function needs refinement so its easy to follow
// in the future
export const Address1ToCopyText = (address: TAddress1) => {

    const formattedAddress: Array<string> = [];

    if (address.sub_building_name) {
        formattedAddress.push(address.sub_building_name);
    } else if (address.sub_building_number) {
        formattedAddress.push(address.sub_building_number);
    }

    if (address.building_number) {
        formattedAddress.push(`${address.building_number} ${address.street_name}`);
    }
    else if (address.building_name) {
        formattedAddress.push(address.building_name);
        formattedAddress.push(address.street_name);
    } else {
        formattedAddress.push(address.street_name);
    }

    if (address.locality) {
        formattedAddress.push(address.locality);
    }

    formattedAddress.push(address.city_town);

    if (address.postcode) {
        formattedAddress.push(address.postcode);
    }

    return formattedAddress.join(", ");
};
