import React from "react";
import { CRMCardOutsideLabelled } from "../../components/CRMCardOutsideLabelled/CRMCardOutsideLabelled";
import CRMListingKeyInfoMiniComponent from "../../components/CRMListingKeyInfoMiniComponent/CRMListingKeyInfoMiniComponent";
import { TContainerStateProps } from "../../state/TContainerStateProps";

class CRMListingPerformancePageListingSummaryContainer extends React.Component<React.PropsWithChildren<TContainerStateProps>> {

    public render(): JSX.Element {
        return (
            <CRMCardOutsideLabelled
                labelColor="highlights-instructional-brick-0"
                shadow={false}
            >
                <CRMListingKeyInfoMiniComponent
                    listing={this.props.state.activeData.crm.listingPerformance.listingForm.view}
                />
            </CRMCardOutsideLabelled>
        );
    }
}

export default CRMListingPerformancePageListingSummaryContainer;
