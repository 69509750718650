import React, { useEffect, useState } from "react";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { SpacingColumn } from "../../BuildingBlocks/SpacingColumn";
import { CRMCardOutsidePopupBasic } from "../../CRMCardOutsidePopupBasic/CRMCardOutsidePopupBasic";
import { CRMParagraph } from "../../Simple/CRMParagraph/CRMParagraph";
import { TLandRegistryTitleSuggestion } from "../../../../../domain/codecs/form/CasePropertyForm";
import { CRMCaseLandRegistryTitleSuggestion } from "./CRMCaseLandRegistryTitleSuggestion";
import { WeightBold } from "../../WeightBold/WeightBold";

export const CRMCaseLandRegistryTitleSuggestions = (props: React.PropsWithChildren<{
    suggestions: Array<TLandRegistryTitleSuggestion>
    onSelect: (value: TLandRegistryTitleSuggestion) => void,
}>): JSX.Element => {
    const [hasDismissed, setHasDismissed] = useState<boolean>(false);

    const onClose = () => setHasDismissed(true);

    useEffect(() => setHasDismissed(false), [props.suggestions]);

    return <CRMCardOutsidePopupBasic
        isOpen={!hasDismissed}
        title="Which title does this property relate to?"
        context="neutral"
        closeText="I'll pick later"
        onClose={onClose}
        size="medium"
    >
        <SpacingColumn spacing={CRMSpacing.MEDIUM}>
            {/* EXPLANATION PARAGRAPHS */}
            <SpacingColumn spacing={CRMSpacing.TINY}>
                <CRMParagraph>
                    Below are the Titles that Land Registry hold for this address
                </CRMParagraph>

                <CRMParagraph>
                    <WeightBold>Please select the correct one for this property.</WeightBold>
                </CRMParagraph>
            </SpacingColumn>

            {/* SUGGESTIONS */}
            {props.suggestions.map((suggestion, i) =>
                <CRMCaseLandRegistryTitleSuggestion
                    key={i}
                    suggestion={suggestion}
                    onSelect={props.onSelect}
                />
            )}
        </SpacingColumn>
    </CRMCardOutsidePopupBasic>
};
