import React, { useState } from "react";
import * as FullStory from '@fullstory/browser';
import Cookies from "js-cookie";
import { TContainerStateProps } from "../../state/TContainerStateProps";
import { WeightBold } from "../../components/WeightBold/WeightBold";
import { FrontCookieBanner } from "../../components/Front/Complex/FrontCookieBanner/FrontCookieBanner";
import { cookiePreferenceCookieName, TCookiePreference } from "./../../../../domain/constants";
import { initFullStory } from "../../util";

const FrontCookieBannerContainer = (props: TContainerStateProps): JSX.Element => {

    const hasClienSetCookiePreference = (): boolean =>
        Cookies.get(cookiePreferenceCookieName) !== undefined
    ;

    const [isCookiePreferenceSet, setIsCookiePreferenceSet] = useState(hasClienSetCookiePreference());

    const setClientCookiePreference = (preference: TCookiePreference) => {
        Cookies.set(cookiePreferenceCookieName, preference);
        setIsCookiePreferenceSet(true);
        
        if (preference === "all") {
            initFullStory();
            FullStory.identify(props.state.global.user.id);
        }
    };

    return (
        <div>
            <FrontCookieBanner
                isResolved={isCookiePreferenceSet}
                onAccept={() => setClientCookiePreference("all")}
                onReject={() => setClientCookiePreference("none")}
                title={<>Before you continue to your Sail Legal case</>}
                sections={[
                    {
                        title: <>We want you to know that we use <WeightBold>cookies</WeightBold> to:</>,
                        points: [
                            {
                                icon: "measure",
                                text: <>Measure how smooth our onboarding experience is for you.</>
                            },
                            {
                                icon: "microscope",
                                text: <>Discover new ways in which we could help people sell or buy their home.</>
                            }
                        ]
                    },
                    {
                        title: <>If you <WeightBold>agree</WeightBold> we will use our cookies to:</>,
                        points: [
                            {
                                icon: "roller",
                                text: <>Streamline our service.</>
                            },
                            {
                                icon: "house",
                                text: <>Build tools that will accelerate your home transaction time.</>
                            },
                        ]
                    }
                ]}
            />
        </div>
    );
};

export default FrontCookieBannerContainer;
