import React from "react";
import { TContainerStateProps } from "../../state/TContainerStateProps";
import { CRMSpacingColumn } from "../../components/CRMSpacingColumn/CRMSpacingColumn";
import { CRMTitleSection } from "../../components/CRMTitleSection/CRMTitleSection";
import { CRMSpacer } from "../../components/CRMSpacer/CRMSpacer";
import { CRMAutosaveIndicatorWrap } from "../../components/CRM/CRMAutosaveIndicatorWrap/CRMAutosaveIndicatorWrap";
import { CRMCodecEditForm } from "../../components/CRMCodecEditForm/CRMCodecEditForm";
import { CasesManagementCompanyDetails } from "../../../../domain/codecs/Cases";
import { pipe } from "fp-ts/lib/function";
import { array } from "fp-ts";
import { FormStatus_highestPriority } from "../../../../shared/src/codecs/codec";

const CRMLegalCaseDetailsManagementCompanyDetailsContainer = (props: TContainerStateProps): JSX.Element => (
    <CRMAutosaveIndicatorWrap
        status={pipe(
            [
                props.state.forms.case_details_page.data.output.details.status,
                ...pipe(
                    props.state.forms.contracts_block.children.other_side_members,
                    array.map((form) => form.status),
                ),
            ],
            FormStatus_highestPriority,
        )}
    >
        <CRMTitleSection>
            Management Company Details
        </CRMTitleSection>
        <CRMSpacer size="large" />
        <CRMSpacingColumn spacing="medium">
            {/* COMPANY NAME & JOB DEFER */}
            <CRMCodecEditForm
                codec={CasesManagementCompanyDetails}
                model={props.state.forms.case_details_page.data.output.details.edited}
                validationErrors={props.state.forms.case_details_page.data.output.details.validationErrors}
                onChange={(edited) => props.dispatch({
                    type: "LEGAL_CASE_DETAILS_CHANGE",
                    payload: {
                        ...props.state.forms.case_details_page.data.output.details,
                        edited: {
                            ...props.state.forms.case_details_page.data.output.details.edited,
                            ...edited,
                        }
                    }
                })}
                columns={2}
                showDeferDateLabels={true}
            />
        </CRMSpacingColumn>
    </CRMAutosaveIndicatorWrap>
);

export default CRMLegalCaseDetailsManagementCompanyDetailsContainer;