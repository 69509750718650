import React, { useState } from "react";
import { SpacingColumn } from "../../components/BuildingBlocks/SpacingColumn";
import { FrontQuestionFormCard } from "../../components/Front/Complex/FrontQuestionFormCard/FrontQuestionFormCard";
import { TContainerStateProps } from "../../state/TContainerStateProps";
import { FrontSpacing } from "../../models/FrontSpacing";
import { WeightBold } from "../../components/WeightBold/WeightBold";
import { FrontFormSectionTitle } from "../../components/Front/Simple/FrontFormSectionTitle/FrontFormSectionTitle";
import { MediaCompSwitch } from "../../components/BuildingBlocks/MediaCompSwitch";
import { FrontFormPrimaryButton } from "../../components/Front/Simple/FrontFormPrimaryButton/FrontFormPrimaryButton";
import { TOnboardingTabStatus } from "../../components/Front/Simple/FrontOnboardingTab/FrontOnboardingTab";
import FrontParagraphRegular from "../../components/Front/Simple/FrontParagraphRegular/FrontParagraphRegular";
import { FrontPopupPrompt } from "../../components/Front/Complex/FrontPopupPrompt/FrontPopupPrompt";
import FrontParagraphSmall from "../../components/Front/Simple/FrontParagraphSmall/FrontParagraphSmall";
import { ClientCaseSubmitStepAsCompleteForm } from "../../../../domain/codecs/form/ClientCaseForm";
import { FrontHelpBubble } from "../../components/Front/Simple/FrontHelpBubble/FrontHelpBubble";
import { pipe } from "fp-ts/lib/function";
import { array } from "fp-ts";
import FrontInputLabelAndErrorWrapComponent from "../../components/Front/Simple/FrontInputLabelAndErrorWrapComponent/FrontInputLabelAndErrorWrapComponent";
import FrontInputGeneralComponent from "../../components/Front/Simple/FrontInputGeneralComponent/FrontInputGeneralComponent";
import { MaxWidth } from "../../components/BuildingBlocks/MaxWidth";
import { TPercentageSize, TPixelSize } from "../../models/StringLiterals";
import { WeightMedium } from "../../components/WeightMedium/WeightMedium";

const FrontChargesContainer = (props: TContainerStateProps): JSX.Element => {

    const [isSubmitWithMissingAccountsFormOpen, setSubmitWithMissingAccountsFormOpen] = useState(false);

    const getClientCaseChargeAccountNumbers = () =>
        props.state.forms.client_case_page.children.charge_account_numbers
        || {
            charges: [],
            is_step_complete: false,
            submit_step_as_complete: ClientCaseSubmitStepAsCompleteForm.newDefault(),
        }

    const allAccountNumbersProvided = () =>
        pipe(
            getClientCaseChargeAccountNumbers().charges,
            array.filter((form) => form.edited.account_number === ""),
            (a) => a.length > 0 ? false : true,
        );

    const submitAsComplete = () =>
        props.dispatch({
            type: "CLIENT_SUBMIT_CHARGES",
            payload: null,
        });

    const getTransactionTypeWording = () =>
        props.state.forms.client_case_page.children.case.transaction_type === "sale" ? "sale"
        : props.state.forms.client_case_page.children.case.transaction_type === "remortgage" ? "remortgage"
        : props.state.forms.client_case_page.children.case.transaction_type === "transfer" ? "transfer of ownership"
        : "case";

    const isStepComplete = () =>
        getClientCaseChargeAccountNumbers().is_step_complete;

    const isRemortgage =
        props.state.forms.client_case_page.children.case.transaction_type === "remortgage";

    return (
        <div>
            <FrontQuestionFormCard
                title="Mortgages & other repayments"
            >
                <SpacingColumn spacing={FrontSpacing.LARGE_1}>
                    {/* NOT COMPLETE */}
                    {!isStepComplete() && <>
                        {/* INTRODUCTION */}
                        <SpacingColumn spacing={FrontSpacing.SMALL_3}>
                            {/* INTRO - NON REMORTGAGE CASES */}
                            {!isRemortgage &&
                                <>
                                    <FrontParagraphSmall>
                                        When your {getTransactionTypeWording()} completes we're required to pay off any outstanding mortgages and other financial charges that are held against your property.
                                    </FrontParagraphSmall>

                                    <FrontParagraphSmall>
                                        We'll take care of getting the final value to be repaid and making the payment for you.
                                    </FrontParagraphSmall>
                                </>
                            }

                            {/* INTRO - REMORTGAGE CASES */}
                            {isRemortgage &&
                                <>
                                    <FrontParagraphSmall>
                                        <WeightBold>
                                            When your remortgage completes we'll handle paying your old mortgage off at the same time as your new mortgage starts.
                                        </WeightBold>
                                    </FrontParagraphSmall>

                                    <FrontParagraphSmall>
                                        If you have asked us to (or if we are required to) we can also pay off other charges at the same time (such as Help to Buy and other loans).
                                    </FrontParagraphSmall>
                                </>
                            }

                            <FrontParagraphSmall>
                                Below we've listed the companies who have registered a charge against your property. <WeightBold>We need you to provide us with an account/reference number for each of these.</WeightBold>
                            </FrontParagraphSmall>
                        </SpacingColumn>

                        {pipe(
                            getClientCaseChargeAccountNumbers().charges,
                            array.map((form) =>
                                <SpacingColumn
                                    key={form.edited.id}
                                    spacing={FrontSpacing.MEDIUM_1}
                                >
                                    {/* CHARGE NAME */}
                                    <FrontFormSectionTitle>
                                        {form.children.beneficiary}
                                    </FrontFormSectionTitle>

                                    {/* ACCOUNT NUMBER */}
                                    <FrontInputLabelAndErrorWrapComponent label={`What is the account or reference number for ${form.children.beneficiary}?`}>
                                        <MediaCompSwitch
                                            MobileComponent={MaxWidth}
                                            DesktopComponent={MaxWidth}
                                            mobileProps={{width: "100%" as TPixelSize}}
                                            desktopProps={{width: "300px" as TPercentageSize}}
                                            breakpoint="600"
                                        >
                                            <FrontInputGeneralComponent
                                                inputType="text"
                                                value={form.edited.account_number}
                                                displayError={false}
                                                excludeInFullstory={true}
                                                onChange={(account_number) => props.dispatch({
                                                    type: "CLIENT_UPDATE_CHARGE",
                                                    payload: {
                                                        ...form,
                                                        edited: {
                                                            ...form.edited,
                                                            account_number,
                                                        }
                                                    }
                                                })}
                                            />
                                        </MediaCompSwitch>
                                    </FrontInputLabelAndErrorWrapComponent>
                                </SpacingColumn>
                            ),
                        )}

                        {/* EXPLANATION ON FINDING ACCOUNT NUMBERS */}
                        <FrontHelpBubble
                            type="info"
                            isToggleBubble={allAccountNumbersProvided()}
                            label="Not sure which number to use?"
                            paragraph={<SpacingColumn spacing={FrontSpacing.SMALL_1}>
                                <div>
                                    For mortgage providers you should use your account number with them. For other charges you may have a reference number.
                                </div>
                                <div>
                                    If you're unsure you should try and contact them first to see if they can provide you with one. If they aren't able to you can still move onto the next step without providing it.
                                </div>
                            </SpacingColumn>}
                        />

                        {/* CONTROL */}
                        <div>
                            <FrontFormPrimaryButton
                                label="Next"
                                icon="done"
                                isDisabled={false}
                                onClick={() =>
                                    allAccountNumbersProvided()
                                        ? submitAsComplete()
                                        : setSubmitWithMissingAccountsFormOpen(true)
                                }
                            />
                        </div>
                    </>}

                    {/* WHEN COMPLETE */}
                    {isStepComplete() && <>
                        <FrontParagraphSmall>
                            Below are the account/reference numbers for the charges listed against your property.
                        </FrontParagraphSmall>

                        <SpacingColumn spacing={FrontSpacing.SMALL_1}>
                            {pipe(
                                getClientCaseChargeAccountNumbers().charges,
                                array.map((form) => <FrontParagraphSmall key={form.edited.id}>
                                    <WeightBold>{form.children.beneficiary}</WeightBold>: <WeightMedium>{form.edited.account_number || `(Not provided)`}</WeightMedium>
                                </FrontParagraphSmall>)
                            )}
                        </SpacingColumn>
                    </>}
                </SpacingColumn>
            </FrontQuestionFormCard>

            <FrontPopupPrompt
                title={`Not sure of all the account numbers?`}
                isOpen={isSubmitWithMissingAccountsFormOpen}
                onCTA={() => submitAsComplete()}
                ctaText="Continue anyway"
                closeText="Go back"
                onClose={() => setSubmitWithMissingAccountsFormOpen(false)}
            >
                <SpacingColumn spacing={FrontSpacing.SMALL_3}>
                    <FrontParagraphRegular>
                        You can still continue onto the next step without providing all of the account/reference numbers <WeightBold>but it may cause delays.</WeightBold>
                    </FrontParagraphRegular>
                    <FrontParagraphRegular>
                        We'd first recommend that you try and contact them directly and ask if they can provide you with a reference number so that your conveyancer can redeem the charge on completion.
                    </FrontParagraphRegular>
                </SpacingColumn>
            </FrontPopupPrompt>
        </div>
    );
};

export const getChargesStatus = (props: TContainerStateProps): TOnboardingTabStatus =>
    props.state.forms.client_case_page.children.charge_account_numbers?.is_step_complete ? "completed" : "active";

export default FrontChargesContainer;
