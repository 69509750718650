import React from "react";
import { CRMSpacing } from "../../../models/CRMSpacing";
import CRMInputLabelAndErrorWrapComponent from "../../CRMInputLabelAndErrorWrapComponent/CRMInputLabelAndErrorWrapComponent";
import { TChaseStatusType } from "../../../../../domain/codecs/form/CasesChaseTransitionForm";
import { CRMCheckboxList } from "../../Simple/CRMCheckboxList/CRMCheckboxList";
import { pipe } from "fp-ts/lib/function";
import { array } from "fp-ts";
import { SpacingRow } from "../../BuildingBlocks/SpacingRow";
import { ChaseReasons_displayString } from "../CRMCloseChaseContinueCasePopoutChaserView/CRMCloseChaseContinueCasePopoutChaserView";
import { TChaseReasons, TChaseReasonsWithoutAwaitingOpenNewCase } from "../../../../../domain/codecs/Cases";
import { contramap } from "fp-ts/lib/Ord";
import { Ord as OrdNumber } from "fp-ts/lib/number";
import { Ord as OrdString } from "fp-ts/lib/string";

export type TCloseChaseStatus = "closed" | TChaseStatusType;

type TCRMChaseReasonsMultiSelectProps = {
    multiSelectLabel: string;
    availableChaseReasons: Array<TChaseReasonsWithoutAwaitingOpenNewCase>;
    selectedChaseReasons: Array<TChaseReasons>;
    updateChaseReason: (chaseReason: TChaseReasonsWithoutAwaitingOpenNewCase) => void;
};

export const CRMChaseReasonsMultiSelect = (props: React.PropsWithChildren<TCRMChaseReasonsMultiSelectProps>): JSX.Element => {
    
    const getAvailableChaseReasonsOptions = (i: number) => pipe(
        pipe(
            props.availableChaseReasons,
            array.map(((value) => ({
                value,
                text: ChaseReasons_displayString(value)
            }))),
            array.sortBy([contramap((p: {value: TChaseReasonsWithoutAwaitingOpenNewCase, text: string}) => p.text)(OrdString)]),
            array.sortBy([contramap((p: {value: TChaseReasonsWithoutAwaitingOpenNewCase, text: string}) => p.value !== "other" ? 0 : 1)(OrdNumber)]),
            array.splitAt(
                Math.floor(props.availableChaseReasons.length / 2)
            ),
        )[i],
        array.map((opt) => ({
            value: opt.value,
            label: opt.text,
            isChecked: props.selectedChaseReasons.includes(opt.value) ? true : false
        }))
    );


    return (
        <CRMInputLabelAndErrorWrapComponent
            label={props.multiSelectLabel}
            displayError={false}
        >
            <SpacingRow
                justifyContent="space-between"
                alignItems="flex-start"
                spacing={CRMSpacing.MEDIUM}
            >
                {/* LEFT COLUMN */}
                <CRMCheckboxList
                    columnCount={1}
                    options={getAvailableChaseReasonsOptions(0)}
                    onClick={props.updateChaseReason}
                />

                {/* RIGHT COLUMN */}
                <CRMCheckboxList
                    columnCount={1}
                    options={getAvailableChaseReasonsOptions(1)}
                    onClick={props.updateChaseReason}
                />
            </SpacingRow>
        </CRMInputLabelAndErrorWrapComponent>
    )
};
