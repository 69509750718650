import React from "react";
import { TClientCompanyPartnershipContractForm, TClientCompanyPartnershipContractUploadForm } from "../../../../../domain/codecs/form/ClientCompanyPartnershipForm";
import { CRMMultiUploadBox } from "../../CRMMultiUploadBox/CRMMultiUploadBox";
import { CRMUploadedBox } from "../../Simple/CRMUploadedBox/CRMUploadedBox";

export const CRMCompanyPartnerContractForm = (props: React.PropsWithChildren<{
    existingContractForm: TClientCompanyPartnershipContractForm | null;
    uploadContractForm: TClientCompanyPartnershipContractUploadForm;
    onUploadContract: (form: TClientCompanyPartnershipContractUploadForm) => void;
    onDeleteContract: (form: TClientCompanyPartnershipContractForm) => void;
}>): JSX.Element => {
    const contract = props.existingContractForm;

    const onUploadContract = (files: Array<File>) => {

        if (files.length !== 1) {
            return;
        }

        const indexOfLastFullStopInFileName = files[0].name.lastIndexOf(".");

        props.onUploadContract({
            ...props.uploadContractForm,
            status: "requiresSubmission",
            input: {
                ...props.uploadContractForm.input,
                file_extension: indexOfLastFullStopInFileName >= 0 ? files[0].name.slice(indexOfLastFullStopInFileName + 1) : "",
                mime_type: files[0].type === "" ? "text/plain" : files[0].type,
            },
            clientSideFileForUpload: files[0],
        });
    };

    return <>
        {/* EXISTING PARTNERSHIP CONTRACT */}
        {contract &&
            <CRMUploadedBox
                label="Partnership Contract"
                onDelete={() => props.onDeleteContract(contract)}
                link={contract.children.download_url} 
            />
        }

        {/* UPLOAD PARTNERSHIP CONTRACT */}
        {!contract &&
            <CRMMultiUploadBox
                label="Upload Partnership Contract"
                onFileChange={onUploadContract}
            />
        }
    </>;
};
