import React from "react";
import { SpacingRow } from "../../BuildingBlocks/SpacingRow";
import { CRMIcon, TIcon } from "../../CRMIcon/CRMIcon";
import { CRMColors } from "../../../models/CRMColors";
import { useCopyToClipboard } from "../../../hooks/useCopyToClipboard";

type TClickCopyWrapProps = {
    value: string;
};

export const CRMClickCopyWrap = (props: React.PropsWithChildren<TClickCopyWrapProps>): JSX.Element => {

    const { 
        copyState, 
        copyValueToClipboard
    } = useCopyToClipboard();

    const Icon: TIcon = 
        copyState === "success" 
        ? "done" : 
        
        copyState === "failure"
        ? "error-outline" : 
        
        "copy"
    ;
    
    const IconColor = 
        copyState === "success" 
        ? CRMColors.HIGHLIGHTS_POSITIVE_GREEN_0 : 
        
        copyState === "failure"
        ? CRMColors.HIGHLIGHTS_INSTRUCTIONAL_BRICK_0 : 
        
        CRMColors.NEUTRAL_INK
    ;

    const onCopyValueToClipboard = () => copyValueToClipboard(props.value);

    return (
        <SpacingRow
            spacing={"3px"} 
            cursorStyle="pointer"
            onClick={onCopyValueToClipboard}
        >
            {props.children}

            <CRMIcon
                iconName={Icon}
                size="14px"
                colour="hex"
                hexColour={IconColor}
            />
        </SpacingRow>
    );
};
