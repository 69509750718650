import React from "react";
import { CRMCardOutsideLabelled } from "../../components/CRMCardOutsideLabelled/CRMCardOutsideLabelled";
import { CRMTitleForCard } from "../../components/CRMTitleForCard/CRMTitleForCard";
import { CRMSellerKeyInfoComponent } from "../../components/CRMSellerKeyInfoComponent/CRMSellerKeyInfoComponent";
import { CRMSellerPersonComponent } from "../../components/CRMSellerPersonComponent/CRMSellerPersonComponent";
import { TContainerStateProps } from "../../state/TContainerStateProps";
import { SpacingColumn } from "../../components/BuildingBlocks/SpacingColumn";
import { CRMSpacing } from "../../models/CRMSpacing";

class CRMListingPerformancePageSellerSummaryContainer extends React.Component<React.PropsWithChildren<TContainerStateProps>> {

    public render(): JSX.Element {
        return (
            <SpacingColumn spacing={CRMSpacing.MEDIUM}>
                {/* SELLER SUMMARY */}
                <CRMCardOutsideLabelled
                    labelColor="highlights-calming-pink-4"
                    shadow={false}
                >
                     <SpacingColumn spacing={CRMSpacing.TINY}>
                        <CRMTitleForCard>
                            The Seller
                        </CRMTitleForCard>
                        <CRMSellerKeyInfoComponent
                            listing={this.props.state.activeData.crm.listingPerformance.listingForm.view}
                            visibleSections={[
                                "looking-for",
                                "view-on-offers",
                            ]}
                        />
                     </SpacingColumn>
                </CRMCardOutsideLabelled>

                {/* SELLER CONTACT CARDS */}
                {
                    this.props.state.activeData.crm.listingPerformance.partyForm.view.users.length > 0 &&
                    this.props.state.activeData.crm.listingPerformance.partyForm.view.users.map((user, index) => (
                        <CRMCardOutsideLabelled
                            key={index}
                            labelColor="highlights-positive-green-6"
                            shadow={false}
                        >
                            <CRMSellerPersonComponent user={user} />
                        </CRMCardOutsideLabelled>
                    ))
                }
            </SpacingColumn>
        );
    }
}

export default CRMListingPerformancePageSellerSummaryContainer;
