import * as t from "io-ts";
import * as util from "../../shared/src/util";
import * as QueryStringFilterString from "./QueryStringFilterString";
import * as QueryStringFilterFullText from "./QueryStringFilterFullText";
import * as QueryStringFilterNumber from "./QueryStringFilterNumber";
import * as QueryStringFilterBoolean from "./QueryStringFilterBoolean";
import * as QueryStringFilterDate from "./QueryStringFilterDate";

export type TOperatorValues =
    QueryStringFilterString.T["operator"]
    | QueryStringFilterFullText.T["operator"]
    | QueryStringFilterNumber.T["operator"]
    | QueryStringFilterBoolean.T["operator"]
    | QueryStringFilterDate.T["operator"];

export const codec = t.union([
    QueryStringFilterString.codec,
    QueryStringFilterFullText.codec,
    QueryStringFilterNumber.codec,
    QueryStringFilterBoolean.codec,
    QueryStringFilterDate.codec,
]);

export type T = t.TypeOf<typeof codec>;

export const is = (f: unknown): f is T =>
    util.isObject(f)
    && "type" in f
    && f.type === "FILTER"
    && "property" in f
    && "operator" in f
    && "value" in f;

type TFoldParam<R> = {
    onFilterString: (f: QueryStringFilterString.T) => R;
    onFilterFullText: (f: QueryStringFilterFullText.T) => R;
    onFilterNumber: (f: QueryStringFilterNumber.T) => R;
    onFilterBoolean: (f: QueryStringFilterBoolean.T) => R;
    onFilterDate: (f: QueryStringFilterDate.T) => R;
};

export const fold = <R>(fp: TFoldParam<R>) =>
    (p: T): R =>
        QueryStringFilterString.codec.is(p) ? fp.onFilterString(p)
        : QueryStringFilterFullText.codec.is(p) ? fp.onFilterFullText(p)
        : QueryStringFilterNumber.codec.is(p) ? fp.onFilterNumber(p)
        : QueryStringFilterBoolean.codec.is(p) ? fp.onFilterBoolean(p)
        : fp.onFilterDate(p);
