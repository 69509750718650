import React from "react";
import * as PartySellingPropertyState from "../../../../domain/models/PartySellingPropertyState";
import * as BuyerReadyHasProofOfFundsForCurrentOffer from "../../../../domain/models/BuyerReadyHasProofOfFundsForCurrentOffer";
import * as BuyerReadyHasProvidedSolicitorDetails from "../../../../domain/models/BuyerReadyHasProvidedSolicitorDetails";
import { CRMSpacingRow } from "../CRMSpacingRow/CRMSpacingRow";
import { CRMIconBinary } from "../CRMIconBinary/CRMIconBinary";
import { TListingEnquiriesOffer2AndListingEnquiriesOfferForm2FormList } from "../../models/TFormModels";
import { CRMIconTrafficLight, TTrafficLightStatus } from "../CRMIconTrafficLight/CRMIconTrafficLight";
import * as util from "../../../../shared/src/util";

type TCRMOfferChecksIcons = {
    offer: TListingEnquiriesOffer2AndListingEnquiriesOfferForm2FormList["forms"][number];
};

export class CRMOfferChecksIcons extends React.Component<React.PropsWithChildren<TCRMOfferChecksIcons>> {

    partyChainTrafficLightColour = (status: PartySellingPropertyState.T): TTrafficLightStatus => {
        switch (status) {
            case "no_property_to_sell":
            case "property_to_let":
                return "green";
            case "property_to_sell_under_offer":
            case "property_to_sell_already_exchanged":
                return "amber";
            case "unknown":
            case "property_to_sell_not_on_the_market":
            case "property_to_sell_on_the_market":
                return "red";
            default:
                return util.requireExhaustive(status);
        }
    };

    hasProofOfFunds = (): boolean => BuyerReadyHasProofOfFundsForCurrentOffer.fromBuyerOffer1({
        ...this.props.offer.edit,
        ...this.props.offer.view,
    });

    public render (): JSX.Element {
        return (
            <CRMSpacingRow justifyContent="start">
                {/* PROOF OF FUNS? */}
                <CRMIconBinary
                    title={`Buyer has ${this.hasProofOfFunds() ? 'proof of funds' : 'no proof of funds'}`}
                    completedState={this.hasProofOfFunds()}
                    iconName="money"
                    size="large"
                />
                {/* ID CHECK COMPLETE? */}
                <CRMIconBinary
                    title={`Buyer ID check is ${this.props.offer.view.enquiry.id_check_complete ? " complete" : " not complete"}`}
                    completedState={this.props.offer.view.enquiry.id_check_complete}
                    iconName="identity-badge"
                    size="large"
                />
                {/* SOLICITOR DETAILS */}
                <CRMIconBinary
                    title={`Buyer has ${BuyerReadyHasProvidedSolicitorDetails.fromBuyerEnquiry1(this.props.offer.view.enquiry) ? "shared" : "not shared"} solicitor details`}
                    completedState={BuyerReadyHasProvidedSolicitorDetails.fromBuyerEnquiry1(this.props.offer.view.enquiry)}
                    iconName="legal-gavel"
                    size="large"
                />
                {/* MORTGAGE BROKER VOUCH */}
                {this.props.offer.view.enquiry.payment_method !== "cash" &&
                    <CRMIconBinary
                        title={`Mortgage broker has ${this.props.offer.view.enquiry.mortgage_broker_has_vouched ? 'vouched' : 'not vouched' } for the buyer`}
                        completedState={this.props.offer.view.enquiry.mortgage_broker_has_vouched}
                        iconName="stamp"
                        size="large"
                    />
                }
                {/* IS IN CHAIN & CHAIN STATUS */}
                <CRMIconTrafficLight
                    title={util.snakeCaseToCopyText(this.props.offer.view.party.selling_property_state)}
                    status={this.partyChainTrafficLightColour(this.props.offer.view.party.selling_property_state)}
                    iconName={this.partyChainTrafficLightColour(this.props.offer.view.party.selling_property_state) === "green"
                        ? "chainlink-with-strikethrough"
                        : "chainlink-with-plus-symbol"
                    }
                    size="large"
                />
            </CRMSpacingRow>
        );
    }
}
