import React from "react";
import { CRMStatusPill } from "../../Simple/CRMStatusPill/CRMStatusPill";
import { CRMColors } from "../../../models/CRMColors";

type TRoadblockChatButtonProps = {
    messageCount: number;
    onClick: () => void;
};

export const CRMRoadblockChatButton = (props: React.PropsWithChildren<TRoadblockChatButtonProps>): JSX.Element => {

    return (
        <CRMStatusPill 
            icon="question-anwser"
            pillColor={CRMColors.NEUTRAL_PAPER}
            textColor={CRMColors.NEUTRAL_INK}
            borderColor={CRMColors.NEUTRAL_6}
            subLabel={props.messageCount.toString()}
            onClick={props.onClick}
        />
    );
};
