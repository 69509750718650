import * as rxjs from "rxjs";
import * as rxjsOperators from "rxjs/operators";
import { option } from "fp-ts/lib";
import * as fetchWrapper from "../../../wrappers/fetch";
import * as ListingStatus1 from "../../../../../domain/models/ListingStatus1";
import * as ListingSuccessResponse2 from "../../../../../domain/models/ListingSuccessResponse2";
import * as FirstPartyFetchResponse from "../../../../../domain/models/FirstPartyFetchResponse";
import * as ListingFilter1 from "../../../../../domain/models/ListingFilter1";
import * as util from "../../../util";
import { TActionObservable } from "../../applyActions";
import * as TCRMListings from "../../TCRMListings";
import { TGetState } from "../../TGetState";
import { TSetState } from "../../TSetState";
import { TActionsDefinitionsList } from "../TAction";
import { TChangeRouteAction } from "../../router/routerTypes";

export const actions: TActionsDefinitionsList = [
    {
        type: "VIEW_CRM_LISTINGS",
        run: (
            obs$: rxjs.Observable<TChangeRouteAction<"VIEW_CRM_LISTINGS">>,
            getState: TGetState,
            setState: TSetState,
        ): void => {
            obs$.pipe(
                rxjsOperators.tap(() =>
                    setState(({...s}) => {
                        s.activeData.crm.listings = new TCRMListings.C();
                        return s;
                    }),
                ),
                rxjsOperators.mergeMap(() =>
                    rxjs.forkJoin([
                        getListingsForStatus("lead", 20, setState),
                        getListingsForStatus("prep_valuation", 20, setState),
                        getListingsForStatus("chase_instruction", 20, setState),
                        getListingsForStatus("instructed_needs_market_prep", 20, setState),
                        getListingsForStatus("instructed_on_the_market", 20, setState),
                        getListingsForStatus("sstc", 20, setState),
                        getListingsForStatus("completed", 20, setState),
                        getListingsForStatus("lost", 20, setState),
                    ]),
                ),
            ).subscribe();
        },
    },
    {
        type: "CRM_LISTINGS_LOAD_MORE",
        run: (
            obs$: TActionObservable<"CRM_LISTINGS_LOAD_MORE", { status: ListingStatus1.T }>,
            getState: TGetState,
            setState: TSetState,
        ): void => {
            obs$.pipe(
                rxjsOperators.mergeMap((action) =>
                    getListingsForStatus(
                        action.payload.status,
                        getState().activeData.crm.listings[TCRMListings.keyFromListingStatus1(action.payload.status)].meta.meta.limit + 20,
                        setState,
                    ),
                ),
            ).subscribe();
        },
    },
];

const getListingsForStatus = (status: ListingStatus1.T, limit: number, setState: TSetState): rxjs.Observable<FirstPartyFetchResponse.T<ListingSuccessResponse2.T>> =>
    rxjs.from(
        fetchWrapper.json({
            requestParams: {
                url: `${env.REACT_APP_API_URL}/v1/listings?limit=${limit}&filters=${encodeURIComponent(JSON.stringify(ListingFilter1.createForStatus(status)))}`,
                method: "GET",
                body: option.none,
            },
            expectedTypeCodec: ListingSuccessResponse2.codec,
            defaultResponse: ListingSuccessResponse2.newDefault(),
        })(),
    )
        .pipe(
            rxjsOperators.tap(util.defaultCRMRequestErrorHandler),
            rxjsOperators.filter((response) => response.tag === FirstPartyFetchResponse.constants.STATUS_2XX),
            rxjsOperators.tap((response) =>
                setState(({...s}) => {
                    s.activeData.crm.listings = TCRMListings.updateStatus(s.activeData.crm.listings, status, (form) => {
                        form.meta = response.response;
                        return form;
                    });
                    return s;
                })
            ),
        );
