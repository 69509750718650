import React from "react";
import { CRMTableRow } from "../../CRMTableRow/CRMTableRow";
import { TIcon } from "../../CRMIcon/CRMIcon";
import { SpacingRow } from "../../BuildingBlocks/SpacingRow";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { pipe } from "fp-ts/function";
import { ifTrueInject } from "../../../../../shared/src/utilsByDomain/array";
import { TAction } from "../../CRMTableRow/CRMTableRow";
import { isAFunctionCurried, onCallIfExists } from "../../../functions/functions";
import { TIntroducerForm } from "../../../../../domain/codecs/form/IntroducerForm";
import { CRMParagraph } from "../../Simple/CRMParagraph/CRMParagraph";
import { WeightBold } from "../../WeightBold/WeightBold";
import { CRMColors } from "../../../models/CRMColors";
import { FontQuicksand } from "../../BuildingBlocks/FontQuicksand";
import { CRMFontSizes } from "../../../models/CRMFontSizes";
import { Text } from "../../BuildingBlocks/Text";
import { CRMPill } from "../../Simple/CRMPill/CRMPill";
import { WeightSemiBold } from "../../WeightSemiBold/WeightSemiBold";
import { getIntroducerStatus } from "../CRMIntroducerSingleView/CRMIntroducerSingleView";

export type TIntroducerRowProps = {
    introducer: TIntroducerForm;
    onClick: () => void;
    onDelete?: () => void;
    onEdit?: () => void;
};

export const CRMIntroducerRow = (props: React.PropsWithChildren<TIntroducerRowProps>): JSX.Element => {

    const onDownloadAgreement = (): void => {
        window.open(props.introducer.children.existing_agreement_form?.children.download_url, "_blank")
    };

    return (
        <CRMTableRow
            onClick={props.onClick}
            actions={pipe(
                [] as Array<TAction>, 
                ifTrueInject(
                    isAFunctionCurried(props.onEdit),
                    {
                        iconName: "pencil" as TIcon,
                        title: "Edit introducer",
                        onClick: onCallIfExists(props.onEdit),
                    }
                ),
                ifTrueInject(
                    () => getIntroducerStatus(props.introducer) === "agreement-signed"
                            || getIntroducerStatus(props.introducer) === "agreement-requires-review",
                    {
                        iconName: "can-download" as TIcon,
                        title: "Download legal agreement",
                        onClick: onDownloadAgreement,
                    }
                ),
                ifTrueInject(
                    isAFunctionCurried(props.onDelete),
                    {
                        iconName: "delete" as TIcon,
                        title: "Delete",
                        onClick: onCallIfExists(props.onDelete),
                    }
                ),
            )}
            onHoverFace={
                <SpacingRow
                    spacing={CRMSpacing.MEDIUM}
                    justifyContent="start"
                >
                    <CRMParagraph lineHeight={1}>
                        <WeightBold>
                            {props.introducer.edited.name || "--"}
                        </WeightBold>
                    </CRMParagraph>
                </SpacingRow>
            }
        >
            <SpacingRow
                spacing={CRMSpacing.MEDIUM}
                childSize={`1fr 1fr`}
                justifyContent="space-between"
            >
                <CRMParagraph lineHeight={1}>
                    <WeightBold>
                        {props.introducer.edited.name || "--"}
                    </WeightBold>
                </CRMParagraph>
                
                {getIntroducerStatus(props.introducer) === "agreement-not-required" &&
                    <FontQuicksand>
                        <Text
                            width="100%"
                            size={CRMFontSizes.SMALL}
                            color={CRMColors.NEUTRAL_0}
                            align="right"
                        >
                            No Agreement Required
                        </Text>
                    </FontQuicksand>
                }
                
                {getIntroducerStatus(props.introducer) === "agreement-signed" &&
                    <FontQuicksand>
                        <Text
                            width="100%"
                            size={CRMFontSizes.SMALL}
                            color={CRMColors.NEUTRAL_0}
                            align="right"
                        >
                            Agreement Signed
                        </Text>
                    </FontQuicksand>
                }
                
                {getIntroducerStatus(props.introducer) === "agreement-requires-review" &&
                    <SpacingRow justifyContent="end">
                        <CRMPill 
                            colour="highlights-instructional-brick-10"
                            mode="single"
                        >
                            <WeightSemiBold>
                                Agreement Review Due
                            </WeightSemiBold>
                        </CRMPill>
                    </SpacingRow>
                }

                {getIntroducerStatus(props.introducer) === "no-agreement-signed" &&
                    <SpacingRow justifyContent="end">
                        <CRMPill 
                            colour="highlights-instructional-brick-10"
                            mode="single"
                        >
                            <WeightSemiBold>
                                No Agreement Signed
                            </WeightSemiBold>
                        </CRMPill>
                    </SpacingRow>
                }

            </SpacingRow>
        </CRMTableRow>
    );
};
