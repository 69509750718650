import React from "react";
import { TPercentageSize, TPixelSize } from "../../models/StringLiterals";

type TMaxWidthProps = {
    width: TPixelSize | TPercentageSize | "fit-content";
};

export const MaxWidth = (props: React.PropsWithChildren<TMaxWidthProps>): JSX.Element => {

    return (
        <div style={{ maxWidth: props.width }}>
            {props.children}
        </div>
    );
};
