import * as rxjs from "rxjs";
import * as rxjsOperators from "rxjs/operators";
import * as util from "../../util";
import * as FirstPartyFetchResponse from "../../../../domain/models/FirstPartyFetchResponse";
import * as JsonResponse1 from "../../../../domain/models/JsonResponse1";
import { either, option, array } from "fp-ts";
import { pipe } from "fp-ts/lib/function";
import * as TForm from "../../models/TForm";
import * as request from "../request";
import { TState, TUnpackArray, TSubmitMethod, TModelFile, TValidator, IExtensionCallback } from "./lensBaseTypes";
import { ofFormValidatedAndSetToSubmitting } from "./ofFormValidatedAndSetToSubmitting";
import { resolveFormResponse } from "./resolveFormResponse";
import { set } from "./set";
import * as TFormStatus from "../../models/TFormStatus";
import { handleFormSubmitErrorsInFormList } from "./handleFormSubmitErrorsInFormList";
import { TGetState } from "../../state/TGetState";
import { TSetState } from "../../state/TSetState";
import { TActionPayload } from "../../state/actions/TAction";
import { findFirst } from "./findFirst";
import { TValidationError } from "../../../../shared/src/validation/Error";

interface ILensSetForm<A extends string, P, V, S> {
    // 5 level lens path guard
    <
        K1 extends keyof S,
        K2 extends keyof S[K1],
        K3 extends keyof S[K1][K2],
        K4 extends keyof S[K1][K2][K3],
        K5 extends keyof S[K1][K2][K3][K4] & "forms"
    >
    (
        path: [K1, K2, K3, K4, K5],
        urlCallback: (
            value: TUnpackArray<S[K1][K2][K3][K4][K5]>,
            data: P,
        ) => string,
        method: TSubmitMethod,
        validator: TValidator,
        responseModel: TModelFile<V>,
        extensionCallback?: IExtensionCallback<A, P, Array<V>>
    ): (
        obs$: rxjs.Observable<TActionPayload<A, P>>,
        getState: TGetState,
        setState: TSetState
    ) => rxjs.Observable<unknown>;
    // 4 level lens path guard
    <
        K1 extends keyof S,
        K2 extends keyof S[K1],
        K3 extends keyof S[K1][K2],
        K4 extends keyof S[K1][K2][K3] & "forms"
    >
    (
        path: [K1, K2, K3, K4],
        urlCallback: (
            value: TUnpackArray<S[K1][K2][K3][K4]>,
            data: P,
        ) => string,
        method: TSubmitMethod,
        validator: TValidator,
        responseModel: TModelFile<V>,
        extensionCallback?: IExtensionCallback<A, P, Array<V>>
    ): (
        obs$: rxjs.Observable<TActionPayload<A, P>>,
        getState: TGetState,
        setState: TSetState,
    ) => rxjs.Observable<unknown>;
    // 3 level lens path guard
    <
        K1 extends keyof S,
        K2 extends keyof S[K1],
        K3 extends keyof S[K1][K2] & "forms"
    >
    (
        path: [K1, K2, K3],
        urlCallback: (
            value: TUnpackArray<S[K1][K2][K3]>,
            data: P,
        ) => string,
        method: TSubmitMethod,
        validator: TValidator,
        responseModel: TModelFile<V>,
        extensionCallback?: IExtensionCallback<A, P, Array<V>>
    ): (
        obs$: rxjs.Observable<TActionPayload<A, P>>,
        getState: TGetState,
        setState: TSetState,
    ) => rxjs.Observable<unknown>;
    // 2 level lens path guard
    <
        K1 extends keyof S,
        K2 extends keyof S[K1] & "forms"
    >
    (
        path: [K1, K2],
        urlCallback: (
            value: TUnpackArray<S[K1][K2]>,
            data: P,
        ) => string,
        method: TSubmitMethod,
        validator: TValidator,
        responseModel: TModelFile<V>,
        extensionCallback?: IExtensionCallback<A, P, Array<V>>
    ): (
        obs$: rxjs.Observable<TActionPayload<A, P>>,
        getState: TGetState,
        setState: TSetState,
    ) => rxjs.Observable<unknown>;
}

const reduceFormList = (
    setState: TSetState,
    lensPath: any, // eslint-disable-line
    setCallback: (formList: TForm.TFormList<never, never, Record<string, unknown>>) => never,
): void =>
    setState(({...s}) =>
        set()(
            lensPath.slice(0, lensPath.length - 1),
            (formList) => setCallback(formList as TForm.TFormList<never, never, Record<string, unknown>>)
        )({ data: {}, state: s, meta: {} }).state
    );

const reduceFormListStatusToHighestPriority = (
    setState: TSetState,
    lensPath: any, // eslint-disable-line
): void =>
    reduceFormList(
        setState,
        lensPath,
        (formList) => {
            formList.status =  pipe(
                formList.forms,
                array.map((f) => f.status),
                TFormStatus.reduceToHighestPriority,
            );
            return formList as never;
        }
    );

const reduceFormListFormsToResetStatus = (
    setState: TSetState,
    lensPath: any, // eslint-disable-line
): void =>
    reduceFormList(
        setState,
        lensPath,
        (formList) => {
            formList.forms =  pipe(
                formList.forms,
                array.map(TForm.resetCompleted<TForm.TFormV2<never, never, Record<string, unknown>>>())
            );
            return formList as never;
        },
    );

export const submitFormsWhereChanged =
    <A extends string, P, V extends Record<string, unknown>, E, U extends Record<string, unknown>>(): ILensSetForm<A, P, V, TState> =>
    (
        // There is no way to create paramater overloads here so we set to any
        lensPath: any, // eslint-disable-line
        urlCallback: any, // eslint-disable-line
        requestMethod: TSubmitMethod,
        validator: TValidator,
        responseModel: TModelFile<V>,
        extensionCallback?: IExtensionCallback<A, P, Array<V>>
    ) =>
        (
            obs$: rxjs.Observable<TActionPayload<A, P>>,
            getState: TGetState,
            setState: TSetState
        ) =>
            obs$.pipe(
                rxjsOperators.mergeMap((action) =>
                    rxjs
                        .forkJoin(
                             pipe(
                                findFirst(getState())(lensPath),
                                option.fold(
                                    () => [],
                                    (a) =>  pipe(
                                        array.filterMapWithIndex<TForm.TFormV2<unknown, unknown, {}>, Array<string | number>>((i, form) =>
                                            TFormStatus.hasUnsavedChanged(form.status) ?
                                                option.some([...lensPath, i]) :
                                                option.none,
                                        )(a),
                                        array.map((lensPathToForm) =>
                                            ofFormValidatedAndSetToSubmitting<TForm.TFormV2<V, E, U>>(getState, setState)(
                                                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                                lensPathToForm as any,
                                                validator,
                                            )
                                            .pipe(
                                                rxjsOperators.mergeMap((validationEither) =>
                                                    either.fold<
                                                        TValidationError,
                                                        TForm.TFormV2<V extends Record<string, unknown> ? V : never, E extends Record<string, unknown> ? E : never, U extends Record<string, unknown> ? U : never>,
                                                        rxjs.Observable<FirstPartyFetchResponse.T<JsonResponse1.T<V, {}>>>
                                                    >(
                                                        handleFormSubmitErrorsInFormList(setState)(
                                                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                                            lensPathToForm as any,
                                                        ),
                                                        (form) => request.payloadFetch<V>(
                                                            urlCallback(form, action.payload),
                                                            requestMethod,
                                                            form.edit,
                                                            responseModel.newDefault(),
                                                            responseModel.codec,
                                                        )(),
                                                    )(validationEither),
                                                ),
                                                rxjsOperators.tap<FirstPartyFetchResponse.T<JsonResponse1.T<V, {}>>>((response) => util.defaultCRMRequestErrorHandler(response)),
                                                rxjsOperators.tap<FirstPartyFetchResponse.T<JsonResponse1.T<V, {}>>>(resolveFormResponse(setState)(
                                                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                                    lensPathToForm as any,
                                                )),
                                            )
                                        )
                                    )
                                )
                            )
                        )
                        .pipe(
                            rxjsOperators.tap(() => reduceFormListStatusToHighestPriority(setState, lensPath)),
                            rxjsOperators.delay(400),
                            rxjsOperators.tap(() => reduceFormListFormsToResetStatus(setState, lensPath)),
                            rxjsOperators.tap(() => reduceFormListStatusToHighestPriority(setState, lensPath)),
                            rxjsOperators.mergeMap((responses) => {
                                if (typeof extensionCallback === "function") {
                                    extensionCallback(action,  pipe(
                                        responses,
                                        array.map((response) => response.response.data),
                                    ), setState, getState);
                                }
                                return rxjs.of(responses);
                            })
                        )
                )
            );
