import React from "react";
import { CRMCardOutside } from "../../components/CRMCardOutside/CRMCardOutside";
import { CRMSpacing } from "../../models/CRMSpacing";
import { TContainerStateProps } from "../../state/TContainerStateProps";
import { SpacingColumn } from "../../components/BuildingBlocks/SpacingColumn";
import { Background } from "../../components/BuildingBlocks/Background";
import { CRMButtonPrimary } from "../../components/CRMButtonPrimary/CRMButtonPrimary";
import { CRMCodecEditForm } from "../../components/CRMCodecEditForm/CRMCodecEditForm";
import { CreateServiceBearerTokenForm } from "../../../../domain/codecs/form/CreateServiceBearerTokenForm";
import { CRMParagraph } from "../../components/Simple/CRMParagraph/CRMParagraph";
import { CRMClickCopyWrap } from "../../components/Simple/CRMClickCopyWrap/CRMClickCopyWrap";

export const CRMAdminPageBearerTokenManagementContainer = (props: TContainerStateProps): JSX.Element => {

    const form = props.state.forms.bearer_token_page;

    return (
        <CRMCardOutside borderRounding="right">
            <Background padding={CRMSpacing.LARGE} maxWidth="100%">
                <SpacingColumn spacing={CRMSpacing.LARGE}>
                    <CRMParagraph>
                        Service name can only include lowercase characters, numbers and underscores (a-z, 0-9, _). Service name must not be blank.
                        Service name should be unique to the service provided but multiple tokens per service name can be created.
                    </CRMParagraph>
                    <CRMCodecEditForm 
                        codec={CreateServiceBearerTokenForm.payload.codec} 
                        model={form.edited}
                        onChange={(edited) => props.dispatch({
                            type: "CREATED_BEARER_TOKEN_FORM_CHANGE",
                            payload: {
                                ...form,
                                edited: {
                                    ...form.edited,
                                    ...edited,
                                }
                            }
                        })}
                        validationErrors={form.validationErrors}
                    />
                    <CRMButtonPrimary
                        label="Create Token"
                        onClick={() => props.dispatch({
                                type: "CREATED_BEARER_TOKEN_FORM_SUBMIT",
                                payload: form
                            })
                        }
                    />
                    <CRMParagraph>
                        Make sure to save this token, the token is not retained by the server
                    </CRMParagraph>
                    <CRMParagraph>
                        <div style={{wordBreak: "break-all"}}>Token: 
                            {!form.children.returned_jwt && " No token created"}
                            {form.children.returned_jwt && <CRMClickCopyWrap value={form.children.returned_jwt ?? ""}>
                                <CRMParagraph>{form.children.returned_jwt}</CRMParagraph>
                            </CRMClickCopyWrap>}
                        </div>
                    </CRMParagraph>
                    
                </SpacingColumn>
            </Background>
        </CRMCardOutside>
    );
};
