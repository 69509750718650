import React from "react";
import * as TFormStatus from "./../../models/TFormStatus";
import { CRMButtonQuaternary } from "../CRMButtonQuaternary/CRMButtonQuaternary";
import { CRMSpacingRow } from "../CRMSpacingRow/CRMSpacingRow";
import { CRMButtonSecondary } from "../CRMButtonSecondary/CRMButtonSecondary";
import { CRMCardOutsidePopupBlank, TPopupContext, TPopupSize } from "../CRMCardOutsidePopupBlank/CRMCardOutsidePopupBlank";
import { CRMPadding } from "../Simple/CRMPadding/CRMPadding";
import { CRMSpacer } from "../CRMSpacer/CRMSpacer";
import { CRMTitleForCard } from "../CRMTitleForCard/CRMTitleForCard";
import { CRMFormButton } from "../CRMFormButton/CRMFormButton";
import { CRMFormErrorComponent } from "../CRMFormErrorComponent/CRMFormErrorComponent";
import { validationErrorsToDisplayText } from "../../util";
import * as JsonInnerError1 from "../../../../domain/models/JsonInnerError1";
import { TJsonInnerErrorCodeTranslationRecord } from "../../../../domain/models/JsonInnerError1";

type TCRMCardOutsidePopupBasic = {
    isOpen: boolean;
    title: string;
    context?: TPopupContext;
    closeText?: string;
    ctaText: string;
    formStatus: TFormStatus.T;
    validationErrors: Array<JsonInnerError1.T>;
    hideErrorMessages?: boolean;
    customErrorDisplayTextMap?: TJsonInnerErrorCodeTranslationRecord;
    size?: TPopupSize;
    onClose: () => void;
    onCTA: () => void;
};

export class CRMCardOutsidePopupFormSubmit extends React.Component<React.PropsWithChildren<TCRMCardOutsidePopupBasic>> {

    getResolutionPopupErrorMessage = (): string => {
        const firstError = validationErrorsToDisplayText(
            this.props.validationErrors,
            this.props.customErrorDisplayTextMap
        );
        if (firstError.length === 1) {
            return firstError[0];
        }
        return "";
    };

    public render (): JSX.Element {
        return (
            <CRMCardOutsidePopupBlank
                isOpen={this.props.isOpen}
                onClose={this.props.onClose}
                context={this.props.context}
                size={this.props.size}
            >
                <CRMPadding size="medium">
                    {/* TITLE */}
                    <CRMTitleForCard>{this.props.title}</CRMTitleForCard>
                    <CRMSpacer size="tiny" />

                    {/* ERROR */}
                    {this.props.hideErrorMessages !== true && this.props.formStatus === "failure" && this.props.validationErrors.length > 0 &&
                        <>
                            <CRMFormErrorComponent errorMessage={this.getResolutionPopupErrorMessage()}/>
                            <CRMSpacer size="medium" />
                        </>
                    }

                    {/* BODY */}
                    <>{this.props.children}</>
                    <CRMSpacer size="large" />

                    {/* BUTTONS */}
                    <CRMSpacingRow justifyContent="end">
                        <CRMButtonSecondary
                            label={typeof this.props.closeText === "undefined" ? "Close" : this.props.closeText}
                            onClick={this.props.onClose}
                        />
                        <CRMFormButton
                            formStatus={this.props.formStatus}
                            ButtonElement={CRMButtonQuaternary}
                            label={this.props.ctaText}
                            onClick={this.props.onCTA}
                        />
                    </CRMSpacingRow>
                </CRMPadding>
            </CRMCardOutsidePopupBlank>
        );
    }
}
