import React, { useState } from "react";
import { array } from "fp-ts";
import { pipe } from "fp-ts/lib/pipeable";
import { array as extArray } from "../../../../shared/src/utilsByDomain/array";
import { HorizontalLine } from "../../components/BuildingBlocks/HorizontalLine";
import { SpacingColumn } from "../../components/BuildingBlocks/SpacingColumn";
import { SpacingRow } from "../../components/BuildingBlocks/SpacingRow";
import { FrontBankDetailsForm } from "../../components/Front/Complex/FrontBankDetailsForm/FrontBankDetailsForm";
import { FrontQuestionFormCard } from "../../components/Front/Complex/FrontQuestionFormCard/FrontQuestionFormCard";
import { FrontFormPrimaryButton } from "../../components/Front/Simple/FrontFormPrimaryButton/FrontFormPrimaryButton";
import { FrontFormSecondaryButton } from "../../components/Front/Simple/FrontFormSecondaryButton/FrontFormSecondaryButton";
import FrontParagraphSmall from "../../components/Front/Simple/FrontParagraphSmall/FrontParagraphSmall";
import { FrontColors } from "../../models/FrontColors";
import { FrontSpacing } from "../../models/FrontSpacing";
import { TContainerStateProps } from "../../state/TContainerStateProps";
import { FrontFormSectionTitle } from "../../components/Front/Simple/FrontFormSectionTitle/FrontFormSectionTitle";
import { WeightBold } from "../../components/WeightBold/WeightBold";
import { sailLegalBrandConstants } from "../../../../domain/constants";
import { FrontPopupPrompt } from "../../components/Front/Complex/FrontPopupPrompt/FrontPopupPrompt";
import { useOpenClose } from "../../hooks/UseOpenClose";
import { FrontParagraphRegular } from "../../components/Front/Simple/FrontParagraphRegular/FrontParagraphRegular";
import { MediaCompSwitch } from "../../components/BuildingBlocks/MediaCompSwitch";
import { TOnboardingTabStatus } from "../../components/Front/Simple/FrontOnboardingTab/FrontOnboardingTab";
import { numberOfPropertiesFilledIn, numberOfPropertiesToFillIn } from "../../util";
import { FrontInfoBubble } from "../../components/Front/Simple/FrontInfoBubble/FrontInfoBubble";

const FrontPayoutDetailsQuestionCardContainer = (props: TContainerStateProps): JSX.Element => {

    const submitVerifcationPopup = useOpenClose();

    const isARemortgageCase = (): boolean => props.state.forms.client_case_page.children.case.transaction_type === "remortgage";

    const isSubmissionLocked = (): boolean => props.state.forms.client_case_page.children.case.changes_to_bank_accounts_locked;

    const hasBankAccounts = (): boolean => props.state.forms.client_case_page.children.bank_accounts
        ? props.state.forms.client_case_page.children.bank_accounts.accounts.length > 0
        : false;

    const [displayValidationErrorsIfPresent, setDisplayValidationErrorsIfPresent] = useState(false);

    return (
        props.state.forms.client_case_page.children.bank_accounts
            ? <div>
                <FrontQuestionFormCard
                    title="Tell us which bank account to send money to"
                    paragraph={
                        <SpacingColumn spacing={FrontSpacing.SMALL_3}>
                                {isARemortgageCase()
                                    ? <SpacingColumn spacing={FrontSpacing.SMALL_3}>
                                        <FrontParagraphSmall>
                                            Please enter the bank account details where you would like any extra funds returned to you when your remortgage completes.
                                        </FrontParagraphSmall>
                                        <FrontParagraphSmall>
                                            We will need this if you are remortgaging to unlock equity.
                                        </FrontParagraphSmall>
                                    </SpacingColumn>
                                    : <FrontParagraphSmall>
                                        Please enter the bank account details where you would like the proceeds to be sent post completion.
                                    </FrontParagraphSmall>
                                }
                            <FrontParagraphSmall>
                                Your bank account details are encrypted when stored.
                            </FrontParagraphSmall>

                        </SpacingColumn>
                    }
                >
                    <SpacingColumn spacing={FrontSpacing.LARGE_1}>
                        {/* ACCOUNTS */}
                        {props.state.forms.client_case_page.children.bank_accounts.accounts.map((account) => (   
                            <div key={account.original.id}>
                                <SpacingColumn spacing={FrontSpacing.MEDIUM_1}>
                                    <FrontBankDetailsForm
                                        details={account}
                                        isEditable={props.state.forms.client_case_page.children.case.changes_to_bank_accounts_locked === false}
                                        recepients={props.state.forms.client_case_page.children.bank_accounts?.available_recipients || []}
                                        displayValidationErrorsIfPresent={displayValidationErrorsIfPresent}
                                        onChange={(payload) => props.dispatch({
                                            type: "CLIENT_UPDATE_BANK_DETAILS",
                                            payload
                                        })}
                                        onDelete={(payload) => props.dispatch({
                                            type: "CLIENT_DELETE_BANK_DETAILS",
                                            payload
                                        })}
                                    />

                                    <HorizontalLine color={FrontColors.NEUTRAL_11} />
                                </SpacingColumn>
                            </div>
                        ))}

                        {/* SUBMIT FOR VERIFICATION & ADD BANK DETAILS */}
                        {isSubmissionLocked() === false &&
                            <SpacingColumn spacing={FrontSpacing.MEDIUM_1}>
                                <MediaCompSwitch
                                    DesktopComponent={SpacingRow}
                                    desktopProps={{ spacing: FrontSpacing.SMALL_3}}
                                    MobileComponent={SpacingColumn}
                                    mobileProps={{ spacing: FrontSpacing.SMALL_3 }}
                                    breakpoint="600"
                                >
                                    {hasBankAccounts() &&
                                        <FrontFormPrimaryButton
                                            label="Submit for verification"
                                            icon="lock"
                                            isDisabled={false}
                                            onClick={() =>
                                                areAllQuestionsAnwsered(props)
                                                    ? submitVerifcationPopup.toggleOpen()
                                                    : setDisplayValidationErrorsIfPresent(true)
                                            }
                                        />
                                    }

                                    <FrontFormSecondaryButton
                                        icon="plus-thin"
                                        label="Add bank account"
                                        onClick={() => props.dispatch({
                                            type: "CLIENT_ADD_BANK_ACCOUNT",
                                            payload: null,
                                        })}
                                    />
                                </MediaCompSwitch>

                                {displayValidationErrorsIfPresent && !areAllQuestionsAnwsered(props) && <FrontInfoBubble color={FrontColors.ERROR_FIREBRICK_22}>
                                    <WeightBold>You've missed a few things above,</WeightBold> please take a look and then try again.
                                </FrontInfoBubble>}
                            </SpacingColumn>
                        }

                        {isSubmissionLocked() &&
                            <SpacingColumn spacing={FrontSpacing.MEDIUM_1}>
                                <FrontFormSectionTitle>
                                    Need to change something?
                                </FrontFormSectionTitle>
                                <SpacingColumn spacing={FrontSpacing.SMALL_3}>
                                    <FrontParagraphSmall>
                                        When you submitted your bank account <WeightBold>details</WeightBold> for verification, we <WeightBold>locked them in place</WeightBold>.
                                    </FrontParagraphSmall>
                                    <FrontParagraphSmall>
                                        If you have found that some details were entered incorrectly, or need to add another bank account, 
                                        please call us on <WeightBold>{sailLegalBrandConstants().legalCSNumber}</WeightBold> to get them unlocked.
                                    </FrontParagraphSmall>
                                </SpacingColumn>
                            </SpacingColumn>
                        }
                    </SpacingColumn>

                </FrontQuestionFormCard>

                <FrontPopupPrompt
                    isOpen={submitVerifcationPopup.isOpen}
                    title="Submit bank details?"
                    ctaText="Submit for verification"
                    closeText="Cancel"
                    onCTA={() => {
                        props.dispatch({
                            type: "CLIENT_SUBMIT_BANK_DETAILS_FOR_VERIFICATION",
                            payload: null
                        });
                        submitVerifcationPopup.setIsOpen(false);
                    }}
                    onClose={submitVerifcationPopup.toggleOpen}
                >
                    <SpacingColumn spacing={FrontSpacing.SMALL_3}>
                        <FrontParagraphRegular>
                            You are about to submit your bank details for <WeightBold>verification</WeightBold>, a due diligence process which ensures your accounts validity.
                        </FrontParagraphRegular>
                        <FrontParagraphRegular>
                            Once you have done this, you will <WeightBold>not be able to edit</WeightBold> your bank account details or add new ones. Proceed?
                        </FrontParagraphRegular>
                    </SpacingColumn>
                </FrontPopupPrompt>
            </div>
            : <div></div>
    );
};

export const areAllBankAccountsVerified = (props: TContainerStateProps): boolean =>
    pipe(
        props.state.forms.client_case_page.children.bank_accounts?.accounts || [],
        extArray.allMatchPredicate((account) => account.original.verification_status === "approved")
    )
;

export const anyBankAccountIsRejected = (props: TContainerStateProps): boolean =>
    pipe(
        props.state.forms.client_case_page.children.bank_accounts?.accounts || [],
        extArray.anyMatchPredicate((account) => account.original.verification_status === "rejected")
    )
;

const areAllQuestionsAnwsered = (props: TContainerStateProps): boolean => {
    const totalQuestionCount = getTotalBankDetailQuestionsCount(props);
    return totalQuestionCount > 0 && getAnwseredBankDetailsQuestionsCount(props) === totalQuestionCount;
};

export const getAnwseredBankDetailsQuestionsCount = (props: TContainerStateProps): number =>
    pipe(
        props.state.forms.client_case_page.children.bank_accounts?.accounts || [],
        array.reduce(
            0,
            (sum, account) => sum + numberOfPropertiesFilledIn(["id"], account.edited)
        ),
    )
;

export const getTotalBankDetailQuestionsCount = (props: TContainerStateProps): number =>
    pipe(
        props.state.forms.client_case_page.children.bank_accounts?.accounts || [],
        array.reduce(
            0,
            (sum, account) => sum + numberOfPropertiesToFillIn(["id"], account.edited)
        ),
    )
;


export const getPayoutDetailsStatus = (props: TContainerStateProps): TOnboardingTabStatus => {
    if (typeof props.state.forms.client_case_page.children.bank_accounts === "undefined") {
        return "completed";
    }
    
    if (props.state.forms.client_case_page.children.bank_accounts.accounts.length > 0 && anyBankAccountIsRejected(props)) {
        return "active";
    }
    
    if (props.state.forms.client_case_page.children.bank_accounts.accounts.length > 0 && areAllBankAccountsVerified(props)) {
        return "completed";
    }

    if (props.state.forms.client_case_page.children.case.changes_to_bank_accounts_locked) {
        return "completed";
    }
    
    return "active";
}

export default FrontPayoutDetailsQuestionCardContainer;