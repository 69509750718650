import React from "react";
import { CRMIconLabelAndMonoText } from "../CRMIconLabelAndMonoText/CRMIconLabelAndMonoText";
import DatePicker from "react-datepicker";
import * as ISODateTime from "../../../../domain/models/ISODateTime";
import { CRMColors } from "../../models/CRMColors";
import { THexColor } from "../../models/StringLiterals";

type TCRMInlineTextCalendarProps = {
    date: Date | null;
    label: string;
    popperPlacement?: "auto" | "left-start";
    dateSetColour?: THexColor;
    onChange: (d: Date | null) => void;
};

type TCRMInlineTextCalendarState = {
    isOpen: boolean;
};

export class CRMInlineTextCalendar extends React.Component<React.PropsWithChildren<TCRMInlineTextCalendarProps>, TCRMInlineTextCalendarState> {
    public constructor (props: TCRMInlineTextCalendarProps) {
        super(props);
        this.state = {
            isOpen: false,
        };
    }

    public getDateSetColour () {
        return this.props.dateSetColour || CRMColors.NEUTRAL_INK;
    }

    public render (): JSX.Element {
        // This onclick thing appears to be magic, it's all empty functions but the Datepicker shows up
        const CustomReactDatepickerInput = ({ onClick }: { onClick: () => void }): JSX.Element =>
            <CRMIconLabelAndMonoText
                label={this.props.label}
                displayText={
                    this.props.date === null ? "select date"
                    : ISODateTime.getFormattedDateString(this.props.date, "dd/MM/yyyy")
                }
                iconAlignment="end"
                iconTooltipText="Select Date"
                colour={this.props.date === null ? CRMColors.NEUTRAL_2 :  this.getDateSetColour()}
                iconName={this.state.isOpen ? "up-arrow" : "down-arrow"}
                // Magic on click functions are scary
                iconOnClick={onClick}
                displayTextOnClick={onClick}
            />;
        return (
            <DatePicker
                selected={this.props.date}
                onChange={this.props.onChange}
                onCalendarClose={() => {
                    this.setState({
                        isOpen: false,
                    });
                }}
                onCalendarOpen={() => {
                    this.setState({
                        isOpen: true,
                    });
                }}
                popperPlacement={this.props.popperPlacement}
                customInput={<CustomReactDatepickerInput onClick={() => undefined} />} // Look empty function but it must hook in somehow
            />
        );
    }
}