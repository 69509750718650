import React, { useState } from "react";
import { CRMIcon } from "../../CRMIcon/CRMIcon";
import { pipe } from "fp-ts/lib/function";
import { array, option } from "fp-ts";

type TDragUploadOverlayWrapProps = {
    canUpload: boolean;
    onNewFileUploadChange: (files: File[]) => void;
    onCanOnlyUploadOneFile: () => void;
};

export const CRMDragUploadOverlayWrap = (props: React.PropsWithChildren<TDragUploadOverlayWrapProps>): JSX.Element => {

    const [isDraggingState, setIsDraggingState] = useState<boolean>(false);
    let uploadOverlayRef: HTMLDivElement | null = null;
    let uploadInputRef: HTMLInputElement | null = null;

    return (
        <div
            className="crm-drag-overlay-wrap"
            onDragEnter={(e) => {
                e.preventDefault();
                if (props.canUpload) {
                    setIsDraggingState(true);
                }
            }}
            onDragOver={(e) => e.preventDefault()}
        >
            {/* CARD */}
            <div className="crm-drag-overlay-wrap__container">
                {props.children}
            </div>

            {/* UPLOAD OVERLAY */}
            {isDraggingState && 
                <div
                    ref={(element) => uploadOverlayRef = element}
                    className="crm-drag-overlay-wrap__upload-overlay"
                    onDragLeave={(e) => {
                        e.preventDefault();
                        if (uploadOverlayRef === e.target) {
                            setIsDraggingState(false);
                        }
                    }}
                    onDrop={(e) => {
                        e.preventDefault();
                        setIsDraggingState(false);
                        if (props.canUpload) {
                            if (e.dataTransfer.items) {
                                props.onNewFileUploadChange(
                                    pipe(
                                        Array.from(e.dataTransfer.items),
                                        array.filterMap((item) => {
                                            if (item.kind === "file") {
                                                const asFile = item.getAsFile();
                                                if (asFile) { return option.some(asFile)}
                                            }
                                            return option.none;
                                        })
                                    )
                                )
                            }
                            else {
                                props.onNewFileUploadChange(Array.from(e.dataTransfer.files));
                            }
                        }
                    }}
                >
                    <CRMIcon
                        iconName="attach"
                        size="giant"
                        colour="neutral-paper"
                    />
                </div>
            }

            {/* HIDDEN UPLOAD INPUT */}
            <input
                ref={(element) => uploadInputRef = element}
                type="file"
                name="file"
                multiple={true}
                onChange={(e) => {
                    
                    if (e.currentTarget.files) {
                        props.onNewFileUploadChange(Array.from(e.currentTarget.files));
                    }
                }}
                style={{display: "none"}}
            />
        </div>
    );
};
