import React from "react";
import { InputTextForInteger } from "../TextForInteger/InputTextForInteger";
interface IInputCurrency {
    currencySymbol: string;
    placeholder?: string;
    name?: string;
    value?: string;
    displayError?: boolean;
    required?: boolean;
    className?: string;
    disabled?: boolean;
    onChange: (value: string) => void;
    onPressEnterKey: () => void;
}

class InputCurrency extends React.Component<React.PropsWithChildren<IInputCurrency>> {
    public render (): JSX.Element {
        return (
            <div className="inputComponent__container">
                <div className="inputComponent__currencyContainer">
                    {this.props.currencySymbol}
                </div>
                <div className="inputComponent__inputContainer">
                    <InputTextForInteger
                        placeholder={this.props.placeholder}
                        value={this.props.value}
                        name={this.props.name}
                        displayError={this.props.displayError}
                        required={this.props.required}
                        disabled={this.props.disabled}
                        onChange={this.props.onChange}
                        onPressEnterKey={this.props.onPressEnterKey}
                    />
                </div>
            </div>
        );
    }
}

export default InputCurrency;
