import { array } from "fp-ts";
import { pipe } from "fp-ts/lib/function";
import React from "react";
import { TQuoteRateFeePropertyValue } from "../../../../../domain/codecs/QuoteRateFee";
import { multipleToIncludeVAT } from "../../../../../domain/constants";
import { fromNumber } from "../../../../../domain/models/TCurrency";
import { CRMColors } from "../../../models/CRMColors";
import { CRMFontSizes } from "../../../models/CRMFontSizes";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { BorderBetween } from "../../BuildingBlocks/BorderBetween";
import { SpacingColumn } from "../../BuildingBlocks/SpacingColumn";
import { SpacingRow } from "../../BuildingBlocks/SpacingRow";
import { CRMParagraph } from "../../Simple/CRMParagraph/CRMParagraph";
import { WeightMedium } from "../../WeightMedium/WeightMedium";
import { WeightRegular } from "../../WeightRegular/WeightRegular";
import { displayFeeBand, orderFee } from "./CRMQuoteRatesSingleViewFeesTransferValue";

export const CRMQuoteRatesSingleViewFeesPropertyValue = (props: React.PropsWithChildren<{
    property_value_fees: Array<TQuoteRateFeePropertyValue>,
    has_vat: boolean,
}>): JSX.Element =>
    <div>
        {pipe(
            props.property_value_fees,
            array.sortBy([orderFee]),
            array.mapWithIndex((i, fee) =>
                <BorderBetween
                    key={i}
                    borderColour={CRMColors.NEUTRAL_8}
                    borderWidth="1px"
                    spacing={CRMSpacing.MEDIUM}
                >
                    <SpacingRow
                        spacing={CRMSpacing.MEDIUM}
                        justifyContent="space-between"
                        alignItems="flex-start"
                    >
                        {/* BAND */}
                        <div>
                            {displayFeeBand(fee.when_greater_than_or_equal_to_property_price_pence || 0, fee.when_less_than_property_price_pence || 0)}
                        </div>

                        {/* AMOUNT & AMOUNT + VAT (IF APPLICABLE) */}
                        <SpacingColumn
                            spacing={CRMSpacing.TINY}
                            justifyContent="end"
                        >
                            {/* AMOUNT */}
                            <CRMParagraph justifyContent="end">
                                <WeightMedium>
                                    {fromNumber(fee.fee_pence / 100, 2)}
                                </WeightMedium>
                            </CRMParagraph>

                            {/* VAT */}
                            {props.has_vat &&
                                <CRMParagraph fontSize={CRMFontSizes.X_SMALL}>
                                    <WeightRegular>
                                        {fromNumber((fee.fee_pence / 100) * multipleToIncludeVAT, 2)} (INC VAT)
                                    </WeightRegular>
                                </CRMParagraph>
                            }
                        </SpacingColumn>
                    </SpacingRow>
                </BorderBetween>
            ),
        )}
    </div>;
