export const FrontFontSizes = {
    HUGE: 56,
    BIG: 42,
    XX_LARGE: 38,
    X_LARGE: 30,
    LARGE: 25,
    MED: 18,
    SMALL: 16,
    MINOR: 15,
    X_SMALL: 14,
};
