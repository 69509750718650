import * as JsonResponse1 from "../../../../domain/models/JsonResponse1";
import * as JsonResponseMeta1 from "../../../../domain/models/JsonResponseMeta1";
import * as FirstPartyFetchResponse from "../../../../domain/models/FirstPartyFetchResponse";
import * as TForm from "./../../models/TForm";
import { TState } from "./lensBaseTypes";
import { reduceResponse } from "./reduceResponse";
import { TSetState } from "../../state/TSetState";

interface IReduceResponse<T extends JsonResponse1.T<T["data"], JsonResponseMeta1.T>, S> {
    // 5 level lens path guard
    <
        K1 extends keyof S,
        K2 extends keyof S[K1],
        K3 extends keyof S[K1][K2],
        K4 extends keyof S[K1][K2][K3],
        K5 extends keyof S[K1][K2][K3][K4]
    >
    (path: [K1, K2, K3, K4, K5]): (data: FirstPartyFetchResponse.T<T>) => void;
    // 4 level lens path guard
    <
        K1 extends keyof S,
        K2 extends keyof S[K1],
        K3 extends keyof S[K1][K2],
        K4 extends keyof S[K1][K2][K3]
    >
    (path: [K1, K2, K3, K4]): (data: FirstPartyFetchResponse.T<T>) => void;
    // 3 level lens path guard
    <
        K1 extends keyof S,
        K2 extends keyof S[K1],
        K3 extends keyof S[K1][K2]
    >
    (path: [K1, K2, K3]): (data: FirstPartyFetchResponse.T<T>) => void;
    // 2 level lens path guard
    <
        K1 extends keyof S,
        K2 extends keyof S[K1]
    >
    (path: [K1, K2]): (data: FirstPartyFetchResponse.T<T>) => void;
    // 1 level lens path guard
    <K1 extends keyof S>
    (path: [K1]): (data: FirstPartyFetchResponse.T<T>) => void;
}

export const reduceResponseToForm = <T extends JsonResponse1.T<T["data"], JsonResponseMeta1.T>>(setState: TSetState): IReduceResponse<T, TState> =>
    (lensPath: any) => // eslint-disable-line 
        reduceResponse<T>(setState)(
            /* eslint-disable */
            lensPath,
            // Because lensPath is set to any, and reduceResponse
            // expecets a path it can derive a object type from and compare it in the callback
            // doing responseToForm with types and stuff will never work, so we just disable ts
            // if somone can figure out a way to may it more type safe it would be wonderful
            // @ts-ignore
            TForm.responseToForm
            /* eslint-enable */
        );
