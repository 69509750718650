import { Observable } from "rxjs";
import { tap, switchMap } from "rxjs/operators";
import { formOperation } from "../../wrappers/formOperation";
import { TActionsDefinitionsList } from "./TAction";
import { action } from "./actionFunctions";
import { TCreateServiceBearerTokenForm } from "../../../../domain/codecs/form/CreateServiceBearerTokenForm";

export const actions: TActionsDefinitionsList = [
    action("CREATED_BEARER_TOKEN_FORM_CHANGE", (obs$: Observable<TCreateServiceBearerTokenForm>, lens, setState, getState, routes, dispatch) => {
        obs$.pipe(
            tap((payload) => setState(lens.bearer_token_page.set(payload))),
        ).subscribe();
    }),
    action("CREATED_BEARER_TOKEN_FORM_SUBMIT", (obs$: Observable<TCreateServiceBearerTokenForm>, lens, setState, getState, routes, dispatch) => {
        obs$.pipe(
            tap(() => setState(lens.bearer_token_page.status.set("submitting"))),
            switchMap((form) => formOperation("CreateServiceBearerToken", form)),
            tap((form) => setState(lens.bearer_token_page.set(form))),
        ).subscribe();
    }),
];