import React, { useState } from "react";
import { TContainerStateProps } from "../../state/TContainerStateProps";
import { CRMSpacingColumn } from "../../components/CRMSpacingColumn/CRMSpacingColumn";
import { CRMTitleSection } from "../../components/CRMTitleSection/CRMTitleSection";
import { CRMSpacer } from "../../components/CRMSpacer/CRMSpacer";
import { CRMAutosaveIndicatorWrap } from "../../components/CRM/CRMAutosaveIndicatorWrap/CRMAutosaveIndicatorWrap";
import { CRMCodecEditForm } from "../../components/CRMCodecEditForm/CRMCodecEditForm";
import { pipe } from "fp-ts/lib/function";
import { array, option } from "fp-ts";
import { CRMParagraph } from "../../components/Simple/CRMParagraph/CRMParagraph";
import { CRMPadding } from "../../components/Simple/CRMPadding/CRMPadding";
import { FormStatus_highestPriority } from "../../../../shared/src/codecs/codec";
import { CRMVerticalTabs, TCRMVerticalTab } from "../../components/Complex/CRMVerticalTabs/CRMVerticalTabs";
import { DateTime } from "luxon";
import { WeightBold } from "../../components/WeightBold/WeightBold";
import { TextCenter } from "../../components/BuildingBlocks/TextCenter";
import { CRMFormButtonIconWithLabel } from "../../components/CRMFormButtonIconWithLabel/CRMFormButtonIconWithLabel";
import { CRMButtonIcon } from "../../components/CRMButtonIcon/CRMButtonIcon";
import { VideoVerificationCall3 } from "../../../../domain/codecs/VideoVerificationCall";
import { TCaseVideoVerificationCallListForm, TCaseVideoVerificationCallUserForm } from "../../../../domain/codecs/form/CaseForm";
import { CRMCardOutsidePopupBasic } from "../../components/CRMCardOutsidePopupBasic/CRMCardOutsidePopupBasic";
import { CRMTitleSubSection } from "../../components/CRMTitleSubSection/CRMTitleSubSection";
import { UserVideoVerificationCall3 } from "../../../../domain/codecs/UserVideoVerificationCall";
import CRMInputLabelAndErrorWrapComponent from "../../components/CRMInputLabelAndErrorWrapComponent/CRMInputLabelAndErrorWrapComponent";
import { CRMDropdownComponent } from "../../components/CRMDropdownComponent/CRMDropdownComponent";
import { CRMFormButton } from "../../components/CRMFormButton/CRMFormButton";
import { CRMButtonTertiary } from "../../components/CRMButtonTertiary/CRMButtonTertiary";
import { CRMSpacingRow } from "../../components/CRMSpacingRow/CRMSpacingRow";
import { CursorPointer } from "../../components/CursorPointer/CursorPointer";
import { CRMIcon } from "../../components/CRMIcon/CRMIcon";
import { CRMFormErrorComponent } from "../../components/CRMFormErrorComponent/CRMFormErrorComponent";
import { doesErrorCodeExist } from "../../../../shared/src/codecs/errors";
import { CRMNoticeBoxComponent } from "../../components/CRMNoticeBoxComponent/CRMNoticeBoxComponent";
import { CRMDeferButtonComponent } from "../../components/CRMDeferButtonComponent/CRMDeferButtonComponent";

const CRMLegalCaseDetailsVideoVerificationCallsContainer = (props: TContainerStateProps): JSX.Element => {
    const [videoVerificationCallToDelete, setVideoVerificationCallToDelete] = useState<TCaseVideoVerificationCallListForm | null>(null);
    const [userVideoVerificationCallToDisassociate, setUserVideoVerificationCallToDisassociate] = useState<TCaseVideoVerificationCallUserForm | null>(null);
    const isVideoVerificationCallForThisCase = (videoVerificationCallId: string): boolean =>
        pipe(
            props.state.forms.case_details_page.data.output.details.children.video_verification_call.list,
            array.findFirst((form) => form.edited.id === videoVerificationCallId && form.children.created_for_case_id === props.state.forms.case_details_page.data.output.details.edited.id),
            option.fold(
                () => false,
                () => true,
            )
        );

    return <>
        <CRMAutosaveIndicatorWrap
            status={pipe(
                [
                    ...pipe(
                        props.state.forms.case_details_page.data.output.details.children.video_verification_call.list,
                        array.map((form) => form.status),
                    ),
                    ...pipe(
                        props.state.forms.case_details_page.data.output.details.children.video_verification_call.list,
                        array.map((form) => form.children.users),
                        array.flatten,
                        array.map((form) => form.status),
                    ),
                    props.state.forms.case_details_page.data.output.details.status,
                ],
                FormStatus_highestPriority,
            )}
        >
            <CRMTitleSection>
                Video verification calls
            </CRMTitleSection>
            <CRMSpacer size="large" />
            <CRMSpacingColumn spacing="medium">
                <CRMVerticalTabs
                    tabs={pipe(
                        props.state.forms.case_details_page.data.output.details.children.video_verification_call.list,
                        array.map((form): TCRMVerticalTab => ({
                            tabLabel: form.edited.scheduled_time
                                ? DateTime.fromISO(form.edited.scheduled_time, { zone: "Europe/London" }).toFormat("ff")
                                : "Date not set",
                            tabOptions: isVideoVerificationCallForThisCase(form.edited.id) ? [{
                                label: "Delete",
                                onClick: () => setVideoVerificationCallToDelete(form),
                            }] : [],
                            content: <CRMSpacingColumn spacing="large">
                                {/* CALL CONDUCTED FOR OTHER CASE */}
                                {!isVideoVerificationCallForThisCase(form.edited.id) && <CRMNoticeBoxComponent>
                                    This call was conducted for a different case but has some of the same clients as this one.
                                </CRMNoticeBoxComponent>}

                                <CRMCodecEditForm
                                    codec={VideoVerificationCall3}
                                    model={form.edited}
                                    validationErrors={form.validationErrors}
                                    onChange={(edited) => props.dispatch({
                                        type: "LEGAL_CASE_UPDATE_VIDEO_VERIFICATION_CALL",
                                        payload: {
                                            ...form,
                                            edited: {
                                                ...form.edited,
                                                ...edited
                                            }
                                        }
                                    })}
                                    disabled={!isVideoVerificationCallForThisCase(form.edited.id)}
                                />

                                {isVideoVerificationCallForThisCase(form.edited.id) && <CRMSpacingColumn spacing="medium">
                                    <CRMTitleSubSection>
                                        Add people to this call
                                    </CRMTitleSubSection>

                                    {/* ADD PEOPLE TO CALL DROPDOWN */}
                                    {props.state.forms.case_details_page.data.output.details.children.video_verification_call.users_that_can_be_booked_for_video_call.length > 0 && <>
                                        <CRMInputLabelAndErrorWrapComponent label="Who do you want to add to this scheduled call?">
                                            <CRMDropdownComponent
                                                options={pipe(
                                                    props.state.forms.case_details_page.data.output.details.children.video_verification_call.users_that_can_be_booked_for_video_call,
                                                    array.map((user) => ({
                                                        label: user.full_name,
                                                        value: user.id,
                                                    }))
                                                )}
                                                value={form.children.associate_with_user.edited.user_id}
                                                onChange={(user_id) => props.dispatch({
                                                    type: "LEGAL_CASE_UPDATE_ASSOCIATE_USER_VIDEO_VERIFICATION_CALL_DROPDOWN",
                                                    payload: {
                                                        ...form.children.associate_with_user,
                                                        edited: {
                                                            ...form.children.associate_with_user.edited,
                                                            user_id,
                                                        }
                                                    }
                                                })}
                                                displayError={false}
                                            />
                                        </CRMInputLabelAndErrorWrapComponent>

                                        <CRMFormButton
                                            formStatus={
                                                form.children.associate_with_user.status === "notFound" ? "failure"
                                                : form.children.associate_with_user.status === "twoFactorRequired" ? "failure"
                                                : form.children.associate_with_user.status === "unauthorised" ? "failure"
                                                : form.children.associate_with_user.status
                                            }
                                            label="Add person to call"
                                            icon="done"
                                            onClick={() => props.dispatch({
                                                type: "LEGAL_CASE_ASSOCIATE_USER_VIDEO_VERIFICATION_CALL",
                                                payload: form.children.associate_with_user,
                                            })}
                                            ButtonElement={CRMButtonTertiary}
                                        />
                                        
                                        {form.children.associate_with_user.status === "validationError" && doesErrorCodeExist("NotUnique", form.children.associate_with_user.validationErrors) && <CRMFormErrorComponent
                                            errorMessage="This person has already been added to this call."
                                        />}
                                    </>}

                                    {/* NO USERS TO ADD TO THE CALL */}
                                    {props.state.forms.case_details_page.data.output.details.children.video_verification_call.users_that_can_be_booked_for_video_call.length === 0 && <CRMParagraph>
                                        There aren't any users in this case yet that can be added to the call, first go to the "Clients" section and add them.
                                    </CRMParagraph>}
                                </CRMSpacingColumn>}

                                {form.children.users.length > 0 && <CRMSpacingColumn spacing="medium">
                                    {pipe(
                                        form.children.users,
                                        array.map((userForm) =>
                                            <>
                                                <CRMSpacingRow spacing="tiny" justifyContent="start" alignItems="center">
                                                    <CRMTitleSubSection>
                                                        {userForm.children.full_name}
                                                    </CRMTitleSubSection>
                                                    {isVideoVerificationCallForThisCase(form.edited.id)
                                                        ? <CursorPointer onClick={() => setUserVideoVerificationCallToDisassociate(userForm)}>
                                                            <CRMIcon
                                                                iconName="delete"
                                                                colour="neutral-ink"
                                                                size="tiny"
                                                            />
                                                        </CursorPointer>
                                                        : <div></div>}
                                                </CRMSpacingRow>

                                                {userForm.children.has_expired && userForm.edited.outcome === "pass" && <CRMNoticeBoxComponent>
                                                    This user's video call verification was conducted over 12 months ago and has expired.
                                                </CRMNoticeBoxComponent>}

                                                <CRMCodecEditForm
                                                    codec={UserVideoVerificationCall3}
                                                    model={userForm.edited}
                                                    validationErrors={userForm.validationErrors}
                                                    onChange={(edited) => props.dispatch({
                                                        type: "LEGAL_CASE_UPDATE_USER_VIDEO_VERIFICATION_CALL",
                                                        payload: {
                                                            ...userForm,
                                                            edited: {
                                                                ...userForm.edited,
                                                                ...edited,
                                                            }
                                                        }
                                                    })}
                                                    disabled={!isVideoVerificationCallForThisCase(form.edited.id)}
                                                />
                                            </>
                                        ),
                                    )}
                                </CRMSpacingColumn>}

                            </CRMSpacingColumn>
                        }))
                    )}
                    noTabsContent={<CRMPadding size="tiny">
                        <TextCenter>
                            <CRMParagraph>
                                Add a <WeightBold>new scheduled video verification call</WeightBold> to get started.
                            </CRMParagraph>
                        </TextCenter>
                    </CRMPadding>}
                    afterTabsElement={
                        <CRMSpacingColumn spacing="large">
                            <CRMFormButtonIconWithLabel
                                formStatus="requiresSubmission"
                                ButtonElement={(buttonProps) => <CRMButtonIcon variant="primary" {...buttonProps} />}
                                icon="calendar-add"
                                label="Add new video verification call"
                                onClick={() => props.dispatch({
                                    type: "LEGAL_CASE_CREATE_VIDEO_VERIFICATION_CALL",
                                    payload: props.state.forms.case_details_page.data.output.details.children.video_verification_call.create,
                                })}
                            />

                            <CRMInputLabelAndErrorWrapComponent label="Defer all video verification call jobs">
                                <CRMDeferButtonComponent
                                    value={props.state.forms.case_details_page.data.output.details.edited.video_verification_call_defer}
                                    onChange={(video_verification_call_defer) => props.dispatch({
                                        type: "LEGAL_CASE_DETAILS_CHANGE",
                                        payload: {
                                            ...props.state.forms.case_details_page.data.output.details,
                                            edited: {
                                                ...props.state.forms.case_details_page.data.output.details.edited,
                                                video_verification_call_defer
                                            }
                                        }
                                    })}
                                    displayError={false}
                                    popoutPosition="below-button-left"
                                />
                            </CRMInputLabelAndErrorWrapComponent>
                        </CRMSpacingColumn>
                    }
                />
            </CRMSpacingColumn>
        </CRMAutosaveIndicatorWrap>

        {/* DELETE VIDEO VERIFICATION CALL POPUP */}
        <CRMCardOutsidePopupBasic
            isOpen={videoVerificationCallToDelete ? true : false}
            title="Are you sure?"
            context="important"
            onClose={() => setVideoVerificationCallToDelete(null)}
            closeText="Cancel"
            onCTA={() => {
                if (videoVerificationCallToDelete) {
                    props.dispatch({
                        type: "LEGAL_CASE_DELETE_VIDEO_VERIFICATION_CALL",
                        payload: videoVerificationCallToDelete,
                    });
                }
                setVideoVerificationCallToDelete(null);
            }}
            ctaText="Yes"
        >
            <CRMParagraph>
                Are you sure you want to delete this scheduled video verification call? All data about the outcome of the call will be lost.
            </CRMParagraph>
        </CRMCardOutsidePopupBasic>

        {/* DISASSOCIATE USER VIDEO VERIFICATION CALL POPUP */}
        <CRMCardOutsidePopupBasic
            isOpen={userVideoVerificationCallToDisassociate ? true : false}
            title="Are you sure?"
            context="important"
            onClose={() => setUserVideoVerificationCallToDisassociate(null)}
            closeText="Cancel"
            onCTA={() => {
                if (userVideoVerificationCallToDisassociate) {
                    props.dispatch({
                        type: "LEGAL_CASE_DISASSOCIATE_USER_VIDEO_VERIFICATION_CALL",
                        payload: userVideoVerificationCallToDisassociate,
                    });
                }
                setUserVideoVerificationCallToDisassociate(null);
            }}
            ctaText="Yes"
        >
            <CRMParagraph>
                Are you sure you want to remove this person from the scheduled video verification call? All data about their outcome will be lost.
            </CRMParagraph>
        </CRMCardOutsidePopupBasic>
    </>;
};

export default CRMLegalCaseDetailsVideoVerificationCallsContainer;