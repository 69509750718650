import React from "react";
import DatePicker from "react-datepicker";
import { DateTime } from "luxon";
import { getiOSVersionByUAParsing } from "../../../../util";
import { option } from "fp-ts";
import { pipe } from "fp-ts/lib/function";

interface IInputDate {
    placeholder?: string;
    dateType: "date" | "datetime-local";
    value: string;
    displayError?: boolean;
    onChange: (value: string) => void;
    onPressEnterKey: () => void;
}

class InputDate extends React.Component<React.PropsWithChildren<IInputDate>> {
    public mapValueToFormattedValue (): string {
        return this.props.value === ""
            ? ""
            : this.props.dateType === "date"
            ? DateTime.fromISO(this.props.value, { zone: "Europe/London" }).toFormat("yyyy-MM-dd")
            : DateTime.fromISO(this.props.value, { zone: "Europe/London" }).toFormat("yyyy-MM-dd'T'HH:mm");
    }
    public mapToISOValue (date: string | Date): string {
        return typeof date === "object"
            ? DateTime.fromJSDate(date, { zone: "Europe/London" }).toUTC().toISO()
            : date === ""
            ? ""
            : this.props.dateType === "date"
            ? DateTime.fromFormat(date, "yyyy-MM-dd", { zone: "Europe/London" }).toUTC().toISO()
            : DateTime.fromFormat(date, "yyyy-MM-dd'T'HH:mm", { zone: "Europe/London" }).toUTC().toISO();
    }
    public render (): JSX.Element {
        return this.props.dateType === "date"
        || this.props.dateType === "datetime-local"
        // Check for ios 13 because datetime-local is implemented but broken
        &&  pipe(
            getiOSVersionByUAParsing(),
            option.fold(
                () => true, // If we can't get the iOS version but it supports datetime-local then assume everything is fine
                (version) => version[0] > 13
            )
        ) ?
            <input
                className={
                    "inputComponent__input" +
                    ` inputComponent__input--date` +
                    (this.props.displayError ? " inputComponent__input--error " : " ")
                }
                type={this.props.dateType}
                placeholder={this.props.placeholder}
                value={this.mapValueToFormattedValue()}
                onChange={(e) => {
                    this.props.onChange(this.mapToISOValue(e.currentTarget.value));
                }}
                onKeyDown={(e) => e.keyCode === 13 && this.props.onPressEnterKey()}
            />
        : <DatePicker
            showTimeSelect={this.props.dateType === "datetime-local"}
            selected={this.props.value ? new Date(this.props.value) : undefined}
            className={
                "inputComponent__input" +
                ` inputComponent__input--date` +
                (this.props.displayError ? " inputComponent__input--error " : " ")
            }
            placeholderText={this.props.placeholder}
            onChange={(date) =>
                this.props.onChange(this.mapToISOValue(Array.isArray(date) ? date[0] : date ? date : ""))
            }
            onKeyDown={(e) => e.keyCode === 13 && this.props.onPressEnterKey()}
            dateFormat={`dd-MM-yyyy${this.props.dateType === "datetime-local" ? " HH:mm" : ""}`}
            timeIntervals={15}
        />;
    }
}

export default InputDate;
