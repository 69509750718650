import { pipe } from "fp-ts/lib/function";
import { TCasesCaseForm, TCasesCaseTaskFormForCasesView } from "../../../../../../domain/codecs/form/CasesForm";
import { areISODatesOnTheSameDay } from "../../../../../../shared/src/utilsByDomain/dateTime/areISODatesOnTheSameDay";
import { requireExhaustive } from "../../../../../../shared/src/util";
import { array, identity, option } from "fp-ts";
import { updateAtOrElse } from "../../../../../../shared/src/utilsByDomain/array/updateAtOrElse";
import { getDateDistanceSortNumber } from "../../../../../../shared/src/utilsByDomain/dateTime";

export type TTaskFilterViews = "all" | "task" | "key_date" | "done";
export type TFilterOption = {
    value: TTaskFilterViews;
    label: string;
};

type TTask = TCasesCaseTaskFormForCasesView;
type TTasks = Array<TCasesCaseTaskFormForCasesView>;
type TTaskCategory = TTask["children"]["category"];
type TTaskDayCluster = {
    date: string;
    tasks: TTasks;
};
type TTaskDayClusters = Array<TTaskDayCluster>;

// FILTER
const isTaskDone = (task: TTask): boolean =>
    "is_done" in task.edited   ? !!task.edited.is_done: 
    "is_done" in task.children ? task.children.is_done
    : false
;

const isTaskNotDone = (task: TTask): boolean => !isTaskDone(task);

const isTaskCategory = (category: TTaskCategory ) => 
    (task: TTask): boolean => 
        task.children.category === category
;

export const filterTasksToCategory = (filterView: TTaskFilterViews) => 
    (tasks: TTasks) =>
        filterView === "done"     ? tasks.filter(isTaskDone):
        filterView === "all"      ? tasks.filter(isTaskNotDone):
        filterView === "key_date" ? tasks.filter(isTaskNotDone).filter(isTaskCategory("key_date")):
        filterView === "task"     ? tasks.filter(isTaskNotDone).filter(isTaskCategory("task")):
        requireExhaustive(filterView)
;

// GROUP
const doesTaskFitDayCluster = (task: TTask) => 
    (dayCluster: TTaskDayCluster) => 
        areISODatesOnTheSameDay(task.edited.date, dayCluster.date)
;

const findDayClusterIndexTaskBelongsTo = (dayClusters: TTaskDayClusters, task: TTask) => {
    return pipe(
        dayClusters,
        array.findIndex(doesTaskFitDayCluster(task)),
        option.fold(() => -1, identity.flatten)
    );
}

export const groupTasksByDay = (tasks: TTasks) =>
    pipe(
        tasks,
        array.reduce(
            [] as TTaskDayClusters,
            (dayClusters, task) => pipe(
                dayClusters,
                updateAtOrElse(
                    findDayClusterIndexTaskBelongsTo(dayClusters, task),
                    (dayCluster) => ({
                        ...dayCluster,
                        tasks: dayCluster.tasks.concat(task),
                    }),
                    (set) => set.concat({
                        date: task.edited.date,
                        tasks: [task]
                    })
                )
            )
        )
    )
;

export const sortDayClustersByRecency = (clusters: TTaskDayClusters) => 
    clusters.sort((clusterA, clusterB) => getDateDistanceSortNumber(clusterA.date, clusterB.date))
;