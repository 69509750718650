import React from "react";
export class FrontParagraphSmall extends React.Component<React.PropsWithChildren<{}>> {
    public render (): JSX.Element {
        return (
            <div className="front-paragraph-small">{this.props.children}</div>
        );
    }
}

export default FrontParagraphSmall;
