import * as t from "io-ts";
import * as regexes from "../../shared/src/regexes";
import { nonEmptyString } from "../../shared/src/validation/basic/nonEmptyString";
import { required } from "../../shared/src/validation/basic/required";
import { inOrder } from "../../shared/src/validation/compose/inOrder";
import { object } from "../../shared/src/validation/create/object";
import { regex } from "../../shared/src/validation/create/regex";
import { TValidationFunction } from "../../shared/src/validation/Function";

export const codec = t.type({
    contact_phone_number: t.string,
    contact_email: t.string,
    first_name: t.string,
    last_name: t.string,
});

export const validator: TValidationFunction =
    object({
        contact_phone_number: inOrder(
            required,
            regex(regexes.constants.MOBILE_PHONE_REGEX),
        ),
        contact_email: inOrder(
            required,
            regex(regexes.constants.EMAIL_REGEX),
        ),
        first_name: inOrder(
            required,
            nonEmptyString,
        ),
        last_name: inOrder(
            required,
            nonEmptyString,
        ),
    });
