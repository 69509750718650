import React from "react";
import { TFormStatus } from "../../../../../shared/src/codecs/codec";
import { CRMIcon, TIcon } from "../../CRMIcon/CRMIcon";
import { CRMSpacingRow } from "../../CRMSpacingRow/CRMSpacingRow";
import { TPercentageSize } from "../../../models/StringLiterals";

type TSaveBadgeProps = {
    status: TFormStatus;
    width?: TPercentageSize;
};

export const CRMAutosaveIndicatorWrap = (props: React.PropsWithChildren<TSaveBadgeProps>): JSX.Element => {

    const getIcon = (status: TFormStatus): TIcon => {
        if (status === "success") {
            return "done";
        }

        if (status === "failure") {
            return "error-outline";
        }

        if (status === "validationError") {
            return "warning-bubble";
        }

        return "pending";
    };

    return (
        <div
            className={`crm-autosave-indicator-wrap`}
            style={{
                width: props.width,
            }}
        >
            <div
                className={`
                    crm-autosave-indicator-wrap__badge
                    crm-autosave-indicator-wrap__badge--${props.status}
                `}
            >
                <CRMSpacingRow alignItems="center">
                    <CRMIcon
                        iconName={getIcon(props.status)}
                        colour="neutral-4"
                        size="tiny"
                    />
                </CRMSpacingRow>
            </div>
            <div className="crm-autosave-indicator-wrap__child">
                {props.children}
            </div>
        </div>
    );
};
