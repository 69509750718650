import React from "react";
import { THexColor } from "../../../models/StringLiterals";
import { TCRMMainColours } from "../../../models/TCRMMainColours";


type TPillProps = {
    colour: TCRMMainColours;
    mode?: "single" | "stacked";
    title?: string;
    borderColour?: THexColor;
    onClick?: () => void;
};

export const CRMPill = (props: React.PropsWithChildren<TPillProps>): JSX.Element => {

    return (
        <div
            title={props.title}
            onClick={props.onClick}
            className={`
                crm-pill 
                crm-pill--colour-${props.colour} 
                ${props.onClick ? "crm-pill--link" : ""}
            `}
            style={{
                marginRight: props.mode === "single" ? "0px" : "6px",
                marginBottom: props.mode === "single" ? "0px" : "6px",
                boxShadow: props.borderColour ? 
                    `inset 0px 0px 0px 1px ${props.borderColour}`
                    : "none",
            }}
        >
            {props.children}
        </div>
    );
};
