import React from "react";
import { RoadblockStatus, TRoadblockStatus, TRoadblocksListItem2 } from "../../../../../domain/codecs/form/RoadblockForm";
import { SpacingColumn } from "../../BuildingBlocks/SpacingColumn";
import { CRMRoadblockTableListItem } from "../CRMRoadblockTableListItem/CRMRoadblockTableListItem";
import { CRMRoadblockStatusLabel } from "../CRMRoadblockStatusLabel/CRMRoadblockStatusLabel";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { CRMEmptyPlaceholder } from "../../Simple/CRMEmptyPlaceholder/CRMEmptyPlaceholder";
import { Background } from "../../BuildingBlocks/Background";

type TRoadblocks = Array<TRoadblocksListItem2>;
type TRoadblocksGroup = Array<[TRoadblockStatus, Array<TRoadblocksListItem2>]>;
const RoadblockGroups: Array<TRoadblockStatus> = RoadblockStatus.values;

type TRoadblockTableListProps = {
    roadblocks: TRoadblocks;
    onClick: (roadblock: TRoadblocksListItem2) => void;
};

export const CRMRoadblockTableList = (props: React.PropsWithChildren<TRoadblockTableListProps>): JSX.Element => {
    
    const getRoadblocksByStatus = (matchingStatus: TRoadblockStatus): Array<TRoadblocksListItem2> =>
        props.roadblocks.filter(({ status }) => status === matchingStatus)
    ;

    const roadblocksGrouped: TRoadblocksGroup = RoadblockGroups.reduce(
        (sum, status) => {
            const roadblocks = getRoadblocksByStatus(status);
            return roadblocks.length > 0 ? 
                sum.concat([[status, roadblocks]])
                : sum
            ;
        },
        [] as TRoadblocksGroup
    );

    return (
        <div>
            {props.roadblocks.length > 0 &&
                <Background padding={`0px 0px ${CRMSpacing.X_LARGE}`}>
                    <SpacingColumn spacing={CRMSpacing.X_LARGE}>
                        {roadblocksGrouped.map(([groupStatus, groupRoadblocks], groupIndex) => (
                            <SpacingColumn
                                key={groupIndex}
                                spacing={CRMSpacing.MEDIUM}
                            >
                                <CRMRoadblockStatusLabel
                                    status={groupStatus}
                                />
                                
                                <SpacingColumn>
                                    {groupRoadblocks.map((item) => (
                                        <CRMRoadblockTableListItem 
                                            key={item.id}
                                            item={item}
                                            onClick={() => props.onClick(item)}
                                        />
                                    ))}
                                </SpacingColumn>
                            </SpacingColumn>
                        ))}
                    </SpacingColumn>
                </Background>
            }
            
            {props.roadblocks.length === 0 &&
                <Background padding={`${CRMSpacing.X_LARGE} ${CRMSpacing.LARGE}`}>
                    <CRMEmptyPlaceholder
                        iconSize="70px"
                        textGap={CRMSpacing.TINY}
                    >
                        This case has no roadblocks.
                    </CRMEmptyPlaceholder>
                </Background>
            }
        </div>
    );
};
