import React from "react";
import { CRMInputCalendarComponent } from "../CRMInputCalendarComponent/CRMInputCalendarComponent";
import CRMInputLabelAndErrorWrapComponent from "../CRMInputLabelAndErrorWrapComponent/CRMInputLabelAndErrorWrapComponent";

class CRMViewingPreferredDateListComponent extends React.Component<{
    values: Array<string>;
    displayError: boolean;
    onChange: (values: Array<string>) => void;
    onPressEnterKey: () => void;
}> {
    public onChange (date: string, i: number): void {
        // Copy the values array
        let arr = [...this.props.values];
        // Push the date into the right array location
        arr[i] = date;
        // Flatten the array back to the minimum number of entries if one or more input isn't filled
        arr = arr.filter((v) => v !== undefined);
        this.props.onChange(arr);
    }
    public render (): JSX.Element {
        return <div>
            { /* Display an empty input if no values */ }
            { ! this.props.values.length &&
                <CRMInputLabelAndErrorWrapComponent
                    label="Preferred date & time"
                    displayError={this.props.displayError}
                    errorMessage="You must set a preferred date that is unique"
                >
                    <CRMInputCalendarComponent
                        dateType="datetime-local"
                        value=""
                        displayError={this.props.displayError}
                        onChange={(val) => this.onChange(val, 0)}
                        onPressEnterKey={() => this.props.onPressEnterKey()}
                    />
                </CRMInputLabelAndErrorWrapComponent>
            }
            { this.props.values.map((value, i) =>
                <CRMInputLabelAndErrorWrapComponent
                    key={i}
                    label={i === 0 ? "Preferred date & time" : undefined}
                    displayError={this.props.displayError}
                    errorMessage="You must set a preferred date that is unique"
                >
                    <CRMInputCalendarComponent
                        dateType="datetime-local"
                        value={value}
                        displayError={this.props.displayError}
                        onChange={(val) => this.onChange(val, i)}
                        onPressEnterKey={() => this.props.onPressEnterKey()}
                    />
                </CRMInputLabelAndErrorWrapComponent>
            )}
        </div>;
    }
}

export default CRMViewingPreferredDateListComponent;
