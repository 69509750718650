import React from "react";
import UnstyledInputGeneralComponent, { IInputGeneralComponentProps } from "../UnstyledInputGeneralComponent/UnstyledInputGeneralComponent";

type TFlatInputGeneralProps = {
    placeholder: string;
    value: string;
    onChange: (value: string) => void;
    displayError?: boolean;
    onPressEnterKey?: () => void;
    disabled?: boolean;
    size?: "regular" | "large";
};

export const CRMFlatInputGeneral = (props: React.PropsWithChildren<TFlatInputGeneralProps>): JSX.Element => {

    return (
        <UnstyledInputGeneralComponent
            className={`
                crm-flat-input-general
                crm-flat-input-general--${props.disabled ? "disabled" : "enabled"}
                crm-flat-input-general--size-${props.size || "regular"}
            `}
            inputType="text"
            placeholder={props.placeholder}
            value={props.value}
            displayError={props.displayError || false}
            onChange={props.onChange}
            onPressEnterKey={props.onPressEnterKey}
            disabled={props.disabled}
        />
    );
};

