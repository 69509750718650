import React from "react";
import { array } from "fp-ts/lib";
import Icon from "../OldFront/Simple/IconComponent/Icon";
import * as ListingDocument2 from "../../../../domain/models/ListingDocument2";
import AnchorButton from "../OldFront/Simple/AnchorButton/AnchorButton";
import * as ListingDocumentType1 from "../../../../domain/models/ListingDocumentType1";
import * as ListingDocumentUrl from "../../../../domain/models/ListingDocumentUrl";
import Heading6 from "../OldFront/Simple/Heading6Component/Heading6";
import BodyText from "../OldFront/Simple/BodyTextComponent/BodyText";
import * as util from "../../../../shared/src/util";

interface IDocumentsProps {
    data: Array<ListingDocument2.T>;
    address: string;
}

type TDocumentTitleTranslationKey = string;
type TDocumentDescriptionTranslationKey = string;

const getDocumentDescriptionTuple = (documentType: ListingDocumentType1.T): [TDocumentTitleTranslationKey, TDocumentDescriptionTranslationKey] => {
    switch (documentType) {
        case "energy_certificate":
            return ["Energy Performance Certificate (EPC)", "The Energy Performance Certificate provides details on the energy performance of the property and what can be done to improve it."];
        case "floorplan":
            return ["Floor Plan", "The properties floor plan shows the approximate layout of the property."];
        default:
            return util.requireExhaustive(documentType);
    }
};

class Documents extends React.Component<React.PropsWithChildren<IDocumentsProps>> {
    public render (): JSX.Element {
        return (
            this.props.data.length > 0 ? (
                <div className="documentsComponent">
                    <div className="documentsComponent__count">
                        <BodyText type="normal">
                        {this.props.data.length === 1
                            ? `There is 1 document for ${this.props.address}.`
                            : `There are ${this.props.data.length.toString()} documents for ${this.props.address}.`}
                        </BodyText>
                    </div>
                    <div className="documentsComponent__documents">
                        {array.mapWithIndex<ListingDocument2.T, JSX.Element>((i, document) => (
                            <div className={`documentsComponent__documentRow ${i === this.props.data.length - 1 ? "documentsComponent__documentRow--last" : ""}`} key={i}>
                                <div className="documentsComponent__documentColumn--description">
                                    <div className="documentsComponent__documentTitleContainer">
                                        <Heading6
                                            text={getDocumentDescriptionTuple(document.document_type)[0]}
                                        />
                                    </div>
                                    <div className="documentsComponent__documentDescriptionTextContainer">
                                        <BodyText type="normal">
                                            {getDocumentDescriptionTuple(document.document_type)[1]}
                                        </BodyText>
                                    </div>
                                </div>
                                <div className="documentsComponent__documentColumn--download">
                                    <AnchorButton
                                        label={(
                                            <Icon
                                                iconName="download"
                                                height={2.5}
                                                width={2.5}
                                                type="mouse"
                                            />
                                        )}
                                        type="frog"
                                        url={ListingDocumentUrl.fromListingDocument2AndAccountName(document, env.REACT_APP_ACCOUNT_NAME || "")}
                                    />
                                </div>
                            </div>
                        ))(this.props.data)}
                    </div>
                </div>
            ) : (<div></div>)
        );
    }
}

export default Documents;
