import * as rxjs from "rxjs";
import { tap, flatMap } from "rxjs/operators";
import { formOperation } from "../../wrappers/formOperation";
import { TBuyerSubmitsNewOfferFormEditable } from "../../../../domain/codecs/formEditable/BuyerSubmitsNewOfferFormEditable";
import { TActionsDefinitionsList } from "./TAction";
import { action, routeAction } from "./actionFunctions";

export const actions: TActionsDefinitionsList = [
    routeAction("VIEW_BUYER_ENQUIRY", (obs$, lens, set, get) => {
        obs$.pipe(
            tap((a) => set(lens.public_offers_page.data.input.enquiry_id.set(a.params.enquiryId))),
            tap((a) => set(lens.public_offers_page.new_offer.edited.enquiry_id.set(a.params.enquiryId))),
            flatMap(() => formOperation("GetPublicOffersPageOffers", lens.public_offers_page.data.get()(get()))),
            tap((res) => set(lens.public_offers_page.data.set(res))),
        ).subscribe();
    }),
    action("BUYER_ENQUIRY_NEW_OFFER_FORM_CHANGED", (obs$: rxjs.Observable<TBuyerSubmitsNewOfferFormEditable>, lens, set) => {
        obs$.pipe(
            tap((p) => set(lens.public_offers_page.new_offer.set(p))),
        ).subscribe();
    }),
    action("BUYER_ENQUIRY_NEW_OFFER_SUBMIT", (obs$: rxjs.Observable<TBuyerSubmitsNewOfferFormEditable>, lens, set, get) => {
        obs$.pipe(
            tap(() => set(lens.public_offers_page.new_offer.status.set("submitting"))),
            flatMap((p) => formOperation("BuyerSubmitsNewOffer", p)),
            flatMap(() => formOperation("GetPublicOffersPageOffers", lens.public_offers_page.data.get()(get()))),
            tap((res) => set(lens.public_offers_page.data.set(res))),
            tap(() => set(lens.public_offers_page.new_offer.status.set("success"))),
        ).subscribe();
    }),
];
