import React from "react";
import * as luxon from "luxon";
import * as CaseDocumentType1 from "../../../../../../domain/models/CaseDocumentType1";
import { CRMInlineOptionsComponent } from "../../../CRMInlineOptionsComponent/CRMInlineOptionsComponent";
import { CRMSpacingRow } from "../../../CRMSpacingRow/CRMSpacingRow";
import { CRMIcon } from "../../../CRMIcon/CRMIcon";
import { CRMParagraph } from "../../../Simple/CRMParagraph/CRMParagraph";
import { WeightMedium } from "../../../WeightMedium/WeightMedium";
import { CRMSpacer } from "../../../CRMSpacer/CRMSpacer";
import { array } from "fp-ts";
import { CRMUniversalBulletPoints } from "../../../CRMUniversalBulletPoints/CRMUniversalBulletPoints";
import { CRMLink } from "../../../CRMLink/CRMLink";
import * as TCaseEnquiryTimeline1ReadOnlyForm from "../../../../models/TCaseEnquiryTimeline1ReadOnlyForm";
import * as CaseEnquiryTimeline1 from "../../../../../../domain/models/CaseEnquiryTimeline1";
import { CRMCardOutsidePopupFormSubmit } from "../../../CRMCardOutsidePopupFormSubmit/CRMCardOutsidePopupFormSubmit";
import { pipe } from "fp-ts/lib/function";

type TEnquiryDocumentProps = {
    timelineFormDocumentVariant: TCaseEnquiryTimeline1ReadOnlyForm.T<CaseEnquiryTimeline1.TCaseEnquiryDocument3>;
    onFormChange: (value: TCaseEnquiryTimeline1ReadOnlyForm.T<CaseEnquiryTimeline1.TCaseEnquiryDocument3>) => void;
    onDelete: () => void;
};

export const CRMCaseEnquiryAttachmentDocument = (props: TEnquiryDocumentProps): JSX.Element =>
    (
        <div>
            <div className={`crm-case-enquiry-attachment-document`}>
                {/* TITLE */}
                <CRMSpacingRow>
                    <div className="crm-case-enquiry-attachment-document__title">
                        {luxon.DateTime.fromISO(props.timelineFormDocumentVariant.view.payload.date).setZone("Europe/London").toFormat("dd/MM/yyyy")}
                    </div>
                    <CRMInlineOptionsComponent
                        iconSize="small"
                        options={[
                            {
                                label: "Delete",
                                onClick: () => props.onFormChange({
                                    ...props.timelineFormDocumentVariant,
                                    ui: {
                                        ...props.timelineFormDocumentVariant.ui,
                                        isDeleteModalOpen: true,
                                    },
                                }),
                            },
                        ]}
                    />
                </CRMSpacingRow>
                <CRMSpacer size="tiny" />

                {/* SUBJECT */}
                <CRMSpacingRow>
                    <CRMIcon
                        iconName="document"
                        colour="neutral-ink"
                        size="tiny"
                    />
                    <CRMParagraph>
                        <WeightMedium>
                            { pipe(
                                props.timelineFormDocumentVariant.view.payload.type,
                                CaseDocumentType1.toDisplayString,
                            )}{props.timelineFormDocumentVariant.view.payload.additional_name ? ` - ${props.timelineFormDocumentVariant.view.payload.additional_name}` : ""}
                        </WeightMedium>
                    </CRMParagraph>
                </CRMSpacingRow>

                {/* FILES */}
                {props.timelineFormDocumentVariant.view.payload.files.length > 0 &&
                    <div className="crm-case-enquiry-attachment-document__files">
                        <CRMSpacer size="tiny" />
                        <CRMUniversalBulletPoints
                            points={
                                 pipe(
                                    props.timelineFormDocumentVariant.view.payload.files,
                                    array.mapWithIndex((i, file) =>
                                        <CRMLink
                                            key={i}
                                            href={`${env.REACT_APP_API_URL}/web/cases/${props.timelineFormDocumentVariant.view.payload.case_id}/documents/${props.timelineFormDocumentVariant.view.payload.id}/files/${file.id}/download`}
                                            target="_blank"
                                            linkStyle="normal"
                                        >
                                            {file.name}.{file.file_extension}
                                        </CRMLink>
                                    ),
                                )
                            }
                        />
                    </div>
                }

                {/* DOCUMENT */}
                <CRMSpacer size="tiny" />
                <CRMParagraph>
                    {props.timelineFormDocumentVariant.view.payload.summary}
                </CRMParagraph>
            </div>

            {/* Delete modal */}
            <CRMCardOutsidePopupFormSubmit
                formStatus={props.timelineFormDocumentVariant.status}
                validationErrors={props.timelineFormDocumentVariant.validationErrors}
                isOpen={props.timelineFormDocumentVariant.ui.isDeleteModalOpen}
                title="Delete document attachment"
                context="warning"
                closeText="Cancel"
                ctaText="Yes, delete"
                onClose={() => props.onFormChange({
                    ...props.timelineFormDocumentVariant,
                    ui: {
                        ...props.timelineFormDocumentVariant.ui,
                        isDeleteModalOpen: false,
                    },
                })}
                onCTA={props.onDelete}
            >
                <CRMParagraph>
                    Are you sure you want to remove this document attachment from the enquiry?
                </CRMParagraph>
            </CRMCardOutsidePopupFormSubmit>
        </div>
    );
