import React from "react";
import * as BuyerReadyHasProofOfFundsForCurrentOffer from "../../../../domain/models/BuyerReadyHasProofOfFundsForCurrentOffer";
import * as BuyerReadyTimeToComplete1 from "../../../../domain/models/BuyerReadyTimeToComplete1";
import * as BuyerReadyRiskOfFallThrough1 from "../../../../domain/models/BuyerReadyRiskOfFallThrough1";
import * as TCurrency from "../../../../domain/models/TCurrency";
import { TListingEnquiriesOffer2AndListingEnquiriesOfferForm2FormList } from "../../models/TFormModels";
import { CRMTitleClear } from "../CRMTitleClear/CRMTitleClear";
import { CRMColumnSpacer } from "../CRMColumnSpacer/CRMColumnSpacer";
import { CRMTextHighlight } from "../CRMTextHighlight/CRMTextHighlight";
import { Uppercase } from "../Uppercase/Uppercase";
import { Underline } from "../Underline/Underline";
import { CRMIcon, TIcon } from "../CRMIcon/CRMIcon";
import { requireExhaustive } from "../../../../shared/src/util";
import { SpacingRow } from "../BuildingBlocks/SpacingRow";
import { CRMSpacing } from "../../models/CRMSpacing";
import { Padding } from "../BuildingBlocks/Padding";

type TCRMOfferChecksIcons = {
    offer: TListingEnquiriesOffer2AndListingEnquiriesOfferForm2FormList["forms"][number];
};

const TitleIconAndText = (props: React.PropsWithChildren<{ icon: TIcon; title: string }>): JSX.Element =>
    <span className="crm-card-outside-current-offer__title-icon-label" title={props.title}>
        <CRMIcon iconName={props.icon} colour="neutral-ink" />
        <CRMColumnSpacer size="tiny" />
        <CRMTitleClear>{props.children}</CRMTitleClear>
    </span>
;

export class CRMTitleOfferCard extends React.Component<React.PropsWithChildren<TCRMOfferChecksIcons>> {

    isPartyInChain = (): boolean => this.props.offer.view.party.selling_property_state !== "no_property_to_sell";

    hasProofOfFunds = (): boolean => BuyerReadyHasProofOfFundsForCurrentOffer.fromBuyerOffer1({
        ...this.props.offer.edit,
        ...this.props.offer.view,
    });

    isAccepted = (): boolean => this.props.offer.edit.status === "accepted";

    timeToCompleteToDisplayName = (): string => {
        const timeToComplete = BuyerReadyTimeToComplete1.fromBuyerOffer1({
            ...this.props.offer.edit,
            ...this.props.offer.view,
        });
        switch (timeToComplete) {
            case "less_than_2_months":
                return "<2";
            case "more_than_2_months":
                return ">2";
            default:
                return requireExhaustive(timeToComplete);
        }
    };

    riskOfFallthroughDisplayName = (): string =>
        BuyerReadyRiskOfFallThrough1.fromBuyerOffer1({
            ...this.props.offer.edit,
            ...this.props.offer.view,
        })
    ;

    public render (): JSX.Element {
        return (
            <div className="crm-title-offer-card">
                <SpacingRow spacing={CRMSpacing.TINY}>
                    {/* OFFER AMOUNT */}
                    <CRMTitleClear fontSize="large">
                        {TCurrency.fromNumber(this.props.offer.view.amount)}
                    </CRMTitleClear>

                    {/* IF: ACCEPTED */}
                    {this.isAccepted() &&
                        <CRMTitleClear>
                            <CRMTextHighlight colour="highlights-positive-green-8">
                                <Uppercase>
                                    <Underline>
                                        accepted
                                    </Underline>
                                </Uppercase>
                            </CRMTextHighlight>
                        </CRMTitleClear>
                    }

                    {/* IF: FINAL OFFER */}
                    {this.props.offer.view.enquiry.final_offer_made && !this.isAccepted() &&
                        <CRMTitleClear>
                            <Uppercase>
                                <Underline>
                                    final
                                </Underline>
                            </Uppercase>
                        </CRMTitleClear>
                    }
                    
                    {/* TIME TO COMPLETE */}
                    <Padding type="left" spacing={"10px"}>
                        <TitleIconAndText
                            title={"Time to complete"}
                            icon="clock-full"
                        >
                            {this.timeToCompleteToDisplayName()}
                        </TitleIconAndText>
                    </Padding>

                    {/* RISK OF FALLTHROUGH */}
                    <TitleIconAndText 
                        title={"Risk of fallthrough"}
                        icon="chart"
                    >
                        {this.riskOfFallthroughDisplayName()}
                    </TitleIconAndText>
                </SpacingRow>
            </div>
        );
    }
}
