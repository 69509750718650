import React from "react";
import { CRMColumns } from "../../components/CRMColumns/CRMColumns";
import { CRMPadding } from "../../components/Simple/CRMPadding/CRMPadding";
import { CRMSpacer } from "../../components/CRMSpacer/CRMSpacer";
import CRMListingSummaryForEnquiryContainer from "../../containers/CRMListingSummaryForEnquiryContainer/CRMListingSummaryForEnquiryContainer";
import CRMSavebarComponent from "../../components/CRMSavebarComponent/CRMSavebarComponent";
import CRMEnquiryViewingContainer from "../../containers/CRMEnquiryViewingContainer/CRMEnquiryViewingContainer";
import { CRMCardOutside } from "../../components/CRMCardOutside/CRMCardOutside";
import CRMEnquiryAboutThisPartyContainer from "../../containers/CRMEnquiryAboutThisPartyContainer/CRMEnquiryAboutThisPartyContainer";
import { pipe } from "fp-ts/lib/function";
import { array, option } from "fp-ts";
import * as TFormStatus from "../../models/TFormStatus";
import CRMEnquirySearchCriteriaContainer from "../../containers/CRMEnquirySearchCriteriaContainer/CRMEnquirySearchCriteriaContainer";
import CRMEnquiryAddAnOfferContainer from "../../containers/CRMEnquiryAddAnOfferContainer/CRMEnquiryAddAnOfferContainer";
import CRMEnquiryPeopleContainer from "../../containers/CRMEnquiryPeopleContainer/CRMEnquiryPeopleContainer";
import CRMInputLabelAndErrorWrapComponent from "../../components/CRMInputLabelAndErrorWrapComponent/CRMInputLabelAndErrorWrapComponent";
import { CRMInputCheckboxComponent } from "../../components/CRMInputCheckboxComponent/CRMInputCheckboxComponent";
import { TContainerStateProps } from "../../state/TContainerStateProps";
import { Sticky } from "../../components/BuildingBlocks/Sticky";
import { CRMSpacing } from "../../models/CRMSpacing";
import { CRMPageWrap } from "../../components/Simple/CRMPageWrap/CRMPageWrap";
import { CRMPageBody } from "../../components/Simple/CRMPageBody/CRMPageBody";
import { CRMHomesMenuBarContainer } from "../../containers/CRMHomesMenuBarContainer/CRMHomesMenuBarContainer";

class CRMEnquiryPage extends React.Component<React.PropsWithChildren<TContainerStateProps>> {
    getUnsavedSubforms = (): Array<[string, string]> =>
        [
            ...(TFormStatus.hasUnsavedChanged(this.props.state.activeData.crm.enquiry.createOfferForm.status)
                ? [["enquiries-add-an-offer", "Offers"]]
                : []),
            ...TFormStatus.hasUnsavedChanged(this.props.state.activeData.crm.enquiry.sendFilesForm.status)
                ? [["enquiries-files", "Send files to party"]]
                : [],
            ...pipe(
                this.props.state.activeData.crm.enquiry.viewingForms.forms,
                array.filterMap((form) => form.edit.status === "confirmed" ? option.some(form.status) : option.none),
                TFormStatus.reduceToHighestPriority,
                (status) => TFormStatus.hasUnsavedChanged(status)
                    ? [["viewings", "Viewings"]]
                    : [],
            )
        ] as Array<[string, string]>;

    public render(): JSX.Element {
        return (
            <CRMPageWrap>

                <CRMHomesMenuBarContainer {...this.props} />

                <CRMPageBody>
                    <div className="crm-enquiry-page__body">
                        <CRMColumns
                            padding="large"
                            mobileDirection="reverse"
                            columns={[
                                {
                                    flex: 0.69,
                                    content: (
                                        <Sticky top={CRMSpacing.LARGE} zIndex={5}>
                                            <CRMListingSummaryForEnquiryContainer
                                                state={this.props.state}
                                                dispatch={this.props.dispatch}
                                            />
                                        </Sticky>
                                    ),
                                },
                                {
                                    flex: 0.31,
                                    content: (
                                        <>
                                            <CRMCardOutside borderRounding="left" shadow={true}>
                                                <CRMPadding size="large">
                                                    {/* PEOPLE SECTION */}
                                                    <CRMEnquiryPeopleContainer
                                                        state={this.props.state}
                                                        dispatch={this.props.dispatch}
                                                    />
                                                    <CRMSpacer size="large" />
                                                    {/* VIEWINGS */}
                                                    <CRMEnquiryViewingContainer
                                                        state={this.props.state}
                                                        dispatch={this.props.dispatch}
                                                    />
                                                    <CRMSpacer size="large" />
                                                    {/* ABOUT THIS PARTY */}
                                                    <CRMEnquiryAboutThisPartyContainer
                                                        state={this.props.state}
                                                        dispatch={this.props.dispatch}
                                                    />
                                                    <CRMSpacer size="large" />
                                                    <CRMEnquirySearchCriteriaContainer
                                                        state={this.props.state}
                                                        dispatch={this.props.dispatch}
                                                    />
                                                    <CRMSpacer size="large" />
                                                    <CRMEnquiryAddAnOfferContainer
                                                        state={this.props.state}
                                                        dispatch={this.props.dispatch}
                                                    />
                                                </CRMPadding>
                                            </CRMCardOutside>
                                            <CRMSavebarComponent
                                                unsavedSubFormElementIds={this.getUnsavedSubforms()}
                                            >
                                                <CRMInputLabelAndErrorWrapComponent>
                                                    <CRMInputCheckboxComponent
                                                        label="Party still interested?"
                                                        checked={!this.props.state.forms.listing_enquiry_page.children.enquiry.edited.closed}
                                                        onClick={() => this.props.dispatch({
                                                            type: "CRM_ENQUIRY_INPUT_CHANGE",
                                                            payload: {
                                                                ...this.props.state.forms.listing_enquiry_page.children.enquiry,
                                                                edited: {
                                                                    ...this.props.state.forms.listing_enquiry_page.children.enquiry.edited,
                                                                    closed: !this.props.state.forms.listing_enquiry_page.children.enquiry.edited.closed,
                                                                }
                                                            },
                                                        })}
                                                    />
                                                </CRMInputLabelAndErrorWrapComponent>
                                            </CRMSavebarComponent>
                                        </>
                                    ),
                                },
                            ]}
                        />
                    </div>
                </CRMPageBody>
            </CRMPageWrap>
        );
    }
}

export default CRMEnquiryPage;
