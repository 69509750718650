import React from "react";

export const FrontInputTextareaComponent = (props: React.PropsWithChildren<{
    value: string;
    onChange: (value: string) => void;
    inputRef?: React.RefObject<HTMLTextAreaElement>;
    onBlur?: (e: React.FocusEvent<HTMLTextAreaElement>) => void;
}>): JSX.Element => {

    return (
        <textarea
            ref={props.inputRef}
            value={props.value}
            onChange={(e) => props.onChange(e.target.value)}
            onBlur={props.onBlur}
            className="front-input-textarea"
        />
    );
};
