import React from "react";
import { TRoadblockStatus } from "../../../../../domain/codecs/form/RoadblockForm";
import { capitaliseFirst, underscoreCaseToNormalCase } from "../../../../../shared/src/utilsByDomain/string";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { SpacingRow } from "../../BuildingBlocks/SpacingRow";
import { WeightBold } from "../../WeightBold/WeightBold";
import { trimWithEllipsis } from "../../../../../shared/src/utilsByDomain/string/trimWithEllipsis";
import { FontQuicksand } from "../../BuildingBlocks/FontQuicksand";
import { Text } from "../../BuildingBlocks/Text";
import { CRMRoadblockStatusLabel } from "../CRMRoadblockStatusLabel/CRMRoadblockStatusLabel";
import { pipe } from "fp-ts/lib/function";

type TRoadblockTableSheetItem = {
    href: string;
    name: string;
    caseAddress: string;
    clients: string;
    status: TRoadblockStatus;
    onClick?: () => void;
};

export const CRMRoadblockTableSheetItem = (props: TRoadblockTableSheetItem) => {

    const ListItemName = pipe(
        underscoreCaseToNormalCase(props.name),
        capitaliseFirst
    );

    return (
        <a  
            href={props.href}
            className="crm-roadblock-table-sheet-item"
            onClick={props.onClick}
        >
            <SpacingRow
                spacing={CRMSpacing.MEDIUM}
                childSize="2.5fr 2fr 1.5fr 1fr"
            >
                <RowCell>
                    <WeightBold>
                        {ListItemName}
                    </WeightBold>
                </RowCell>
                
                <RowCell>
                    {trimWithEllipsis(props.caseAddress, 45)}
                </RowCell>
                
                <RowCell>
                    {trimWithEllipsis(props.clients, 45)}
                </RowCell>
                <RowCell>
                    <CRMRoadblockStatusLabel status={props.status} />
                </RowCell>
            </SpacingRow>
        </a>
    );
}

const RowCell = (props: React.PropsWithChildren<{}>) => {
    return (
        <FontQuicksand>
            <Text size={14} weight={500} lineHeight={1.4}>
                {props.children}
            </Text>
        </FontQuicksand>
    );
}