import React from "react";
import { TPercentageSize, TPixelSize } from "../../models/StringLiterals";

export const MaxHeight = (props: React.PropsWithChildren<{
    height: TPixelSize | TPercentageSize | string;
}>): JSX.Element => {

    return (
        <div style={{ maxHeight: props.height }}>
            {props.children}
        </div>
    );
};
