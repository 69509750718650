import React from "react";
import { TFormStatus } from "../../../../../shared/src/codecs/codec";
import { CRMFontSizes } from "../../../models/CRMFontSizes";
import { FontQuicksand } from "../../BuildingBlocks/FontQuicksand";
import { Text } from "../../BuildingBlocks/Text";
import { WeightBold } from "../../WeightBold/WeightBold";
import { CRMSectionPopupPrompt } from "../CRMSectionPopupPrompt/CRMSectionPopupPrompt";

type TVariation = {
    title: string;
    formStatus: TFormStatus;
    body: JSX.Element; 
    onYes: () => void;
};

type TStatusVariableConfirmationCountdownProps<T extends string> = {
    status: T;
    onNo: () => void;
    variations: { 
        [key in T]?: TVariation
    }
};


export const CRMStatusVariableSectionPopupPrompt = <T extends string>(props: TStatusVariableConfirmationCountdownProps<T>) => {
    
    const getVariation = (): TVariation =>
        props.variations[props.status] || 
        {
            title: "",
            formStatus: "untouched",
            body: <></>,
            onYes: () => null,
        }
    ;

    return (
        <CRMSectionPopupPrompt
            formStatus={getVariation().formStatus}
            title={getVariation().title}
            onNo={props.onNo}
            onYes={getVariation().onYes}
        >
            <FontQuicksand>
                <Text size={CRMFontSizes.SMALL} lineHeight={1.2}>
                    {getVariation().body}
                </Text>
            </FontQuicksand>
        </CRMSectionPopupPrompt>
    );
}
