import React from "react";
import { CRMColors } from "../../../../models/CRMColors";
import { CRMFontSizes } from "../../../../models/CRMFontSizes";
import { CRMSpacing } from "../../../../models/CRMSpacing";
import { TPercentageSize, TPixelSize } from "../../../../models/StringLiterals";
import { Background } from "../../../BuildingBlocks/Background";
import { DashedBorder } from "../../../BuildingBlocks/DashedBorder";
import { FontQuicksand } from "../../../BuildingBlocks/FontQuicksand";
import { SpacingRow } from "../../../BuildingBlocks/SpacingRow";
import { Text } from "../../../BuildingBlocks/Text";
import { CRMIcon, TIcon } from "../../../CRMIcon/CRMIcon";
import { CRMEntityBoxIcon } from "../CRMEntityBoxIcon/CRMEntityBoxIcon";
import { useHover } from "../../../../hooks/useHover";

type TBubbleButtonProps = {
    icon: TIcon;
    label: JSX.Element | string;
    maxWidth?: TPixelSize | TPercentageSize;
    onClick: () => void;
};

export const CRMEntityBoxButton = (props: React.PropsWithChildren<TBubbleButtonProps>): JSX.Element => {

    const [hoverRef, isHovering] = useHover<HTMLDivElement>({});

    return (
        <Background 
            divRef={hoverRef}
            display="inline-flex"
        >
            <Background 
                cursor="pointer"
                onClick={props.onClick}
            >
                <DashedBorder
                    color={isHovering ? CRMColors.PRIMARY_6 : CRMColors.NEUTRAL_6}
                    thickness="3px"
                    length={5}
                    gap={5}
                    radius="10px"
                    maxWidth={props.maxWidth}
                >
                    <Background 
                        padding={`${CRMSpacing.MEDIUM}`}
                        color={isHovering ? CRMColors.PRIMARY_16 : "transparent"}
                        borderRadius="5px"
                    >
                        <SpacingRow spacing={CRMSpacing.SMALL}>
                            {/* ICON */}
                            <CRMEntityBoxIcon 
                                icon={props.icon}
                                iconColor={isHovering ? CRMColors.PRIMARY_2  : CRMColors.NEUTRAL_INK}
                                circleColor={isHovering ? CRMColors.PRIMARY_12 : CRMColors.NEUTRAL_10 }
                            />

                            {/* TEXT */}
                            <FontQuicksand>
                                <Text size={CRMFontSizes.SMALL}>
                                    {props.label}
                                </Text>
                            </FontQuicksand>

                            <CRMIcon
                                iconName="arrow-right"
                                size="30px"
                                colour="hex"
                                hexColour={isHovering ? CRMColors.PRIMARY_2 : CRMColors.NEUTRAL_2}
                            />

                        </SpacingRow>
                    </Background>
                </DashedBorder>
            </Background>
        </Background>
    );
};
