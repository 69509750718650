import React from "react";
import { TListingFull1Editable } from "../../../../domain/codecs/formEditable/ListingEditable";
import { ListingCompetingAgent } from "../../../../domain/codecs/Listing";
import { CRMAutosaveIndicatorWrap } from "../CRM/CRMAutosaveIndicatorWrap/CRMAutosaveIndicatorWrap";
import { CRMCodecEditForm } from "../CRMCodecEditForm/CRMCodecEditForm";
import { CRMSpacingColumn } from "../CRMSpacingColumn/CRMSpacingColumn";
import { CRMTitleSection } from "../CRMTitleSection/CRMTitleSection";

type TCCRMListingCompetingAgentFormProps = {
    listingForm: TListingFull1Editable;
    onChange: (listingForm: TListingFull1Editable) => void;
};

export const CRMListingCompetingAgentForm = (props: TCCRMListingCompetingAgentFormProps): JSX.Element => {

    return (
        <CRMAutosaveIndicatorWrap status={props.listingForm.status}>
            <CRMSpacingColumn spacing="large">
                <CRMTitleSection>
                    Competing Agent
                </CRMTitleSection>
                <CRMCodecEditForm
                    codec={ListingCompetingAgent}
                    model={props.listingForm.edited}
                    validationErrors={props.listingForm.validationErrors}
                    onChange={(edited) => props.onChange({
                        ...props.listingForm,
                        edited: {
                            ...props.listingForm.edited,
                            ...edited,
                        }
                    })}
                />
            </CRMSpacingColumn>
        </CRMAutosaveIndicatorWrap>
    );
};
