import React from "react";
import { CRMCardOutside, TCardBackgroundColour } from "../CRMCardOutside/CRMCardOutside";
import { TCRMMainColours, THighlight2 } from "../../models/TCRMMainColours";
import { CRMCardLabel } from "../CRMCardLabel/CRMCardLabel";

type TCRMCardOutsideLabelled = {
    labelColor: TCRMMainColours;
    shadow?: boolean;
    borderColour?: THighlight2;
    backgroundColour?: TCardBackgroundColour;
};

export class CRMCardOutsideLabelled extends React.Component<React.PropsWithChildren<TCRMCardOutsideLabelled>> {
    public render (): JSX.Element {
        return (
            <CRMCardOutside
                borderRounding="right"
                shadow={this.props.shadow}
                borderColor={this.props.borderColour}
                backgroundColor={this.props.backgroundColour}
            >
                <div className="crm-card-outside-labelled">
                    <div
                        className="crm-card-outside-labelled__label"
                    >
                        <CRMCardLabel
                            borderRounding="right"
                            color={this.props.labelColor}
                        />
                    </div>
                    {this.props.children}
                </div>
            </CRMCardOutside>
        );
    }
}
