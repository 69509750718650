import React from "react";
import Button from "../../Simple/ButtonComponent/Button";
import Icon from "../../Simple/IconComponent/Icon";
import ContentContainer from "../../Simple/ContentContainerComponent/ContentContainer";

interface IModalProps {
    fullScreenOnMobile: boolean;
    onCloseClicked: () => void;
}

class Modal extends React.Component<React.PropsWithChildren<IModalProps>> {
    public render (): JSX.Element {
        return (
            <div className={`modalComponent ${this.props.fullScreenOnMobile ? "modalComponent--full-screen" : ""}`}>
                <ContentContainer className={`modalComponent__container ${this.props.fullScreenOnMobile ? " modalComponent__container--full-screen" : ""}`}>
                    <div className={`modalComponent__close-container ${this.props.fullScreenOnMobile ? " modalComponent__close-container--full-screen" : ""}`}>
                        <Button
                            label={
                                <Icon
                                    iconName="cross"
                                    type="sloth"
                                    disabled={false}
                                    height={2.2}
                                    width={2.2}
                                />
                            }
                            type="puffin"
                            onClick={this.props.onCloseClicked}
                        />
                    </div>
                    <div className="modalComponent__container-children">
                        {this.props.children}
                    </div>
                </ContentContainer>
            </div>
        );
    }
}

export default Modal;
