import React from "react";
import {  } from "fp-ts/lib";

type TBodyTextType =
    "large"
    | "normal"
    | "error"
    | "crm"
;

interface IBodyText {
    type: TBodyTextType;
}

class BodyText extends React.Component<React.PropsWithChildren<IBodyText>> {
    public render (): JSX.Element {
        return (
            <p className={
                "bodyText " +
                "bodyText--" + this.props.type}
            >
                {this.props.children}
            </p>
        );
    }
}

export default BodyText;
