import { pipe } from "fp-ts/lib/pipeable";
import { TSizeOption } from "../CRMBlockHeader/CRMBlockHeader";
import { array, option } from "fp-ts";

export const DefaultBlockWidthOptions = [
    {
        icon: "small",
        name: "Small" as const,
        value: 500 as const,
    },
    {
        icon: "medium",
        name: "Medium" as const,
        value: 600 as const,
    },
    {
        icon: "large",
        name: "Large" as const,
        value: 700 as const,
    },
    {
        icon: "huge",
        name: "Huge" as const,
        value: 1000 as const,
    },
];

export type TBlockWidthOption = typeof DefaultBlockWidthOptions[number]["value"];
export type TBlockWidthName = typeof DefaultBlockWidthOptions[number]["name"];
export type TBlockWidthOptionsDefinition = Array<TSizeOption<TBlockWidthOption>>;

export const getBlockWidthValue = (size: TBlockWidthName): TBlockWidthOption =>
    pipe(
        DefaultBlockWidthOptions,
        array.findFirst(({ name }) => name === size),
        option.fold(
            () => 500,
            (block) => block.value
        )
    )
;