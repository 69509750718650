import React from "react";
import { RoadblockStatus_displayString, TRoadblockForm, TRoadblockStatus } from "../../../../../domain/codecs/form/RoadblockForm";
import { CRMColors } from "../../../models/CRMColors";
import { requireExhaustive } from "../../../../../shared/src/util";
import { CRMStatusPill } from "../../Simple/CRMStatusPill/CRMStatusPill";

type TRoadblockStatusLabelProps = {
    status: TRoadblockStatus;
    subLabel?: string;
    onClick?: () => void;
};

export const CRMRoadblockStatusLabel = (props: React.PropsWithChildren<TRoadblockStatusLabelProps>): JSX.Element => {
    
    const Status = props.status;

    const BackgroundColour =    
        Status === "open" ? CRMColors.PRIMARY_2
        : Status === "awaiting_signoff" ? CRMColors.PRIMARY_2
        : Status === "blocked" ? CRMColors.HIGHLIGHTS_PERTINENT_YELLOW_2
        : Status === "resolved" ? CRMColors.HIGHLIGHTS_POSITIVE_GREEN_0
        : Status === "suspended" ? CRMColors.NEUTRAL_10
        : requireExhaustive(Status);

    const TextColour =
        Status === "open" ? CRMColors.NEUTRAL_PAPER
        : Status === "awaiting_signoff" ? CRMColors.NEUTRAL_PAPER
        : Status === "blocked" ? CRMColors.NEUTRAL_INK
        : Status === "resolved" ? CRMColors.NEUTRAL_PAPER
        : Status === "suspended" ? CRMColors.NEUTRAL_INK
        : requireExhaustive(Status);

    const Label = RoadblockStatus_displayString(Status);
    
    const SubLabel = props.subLabel;
    const SeperatorBorderColor = Status === "open" ? CRMColors.NEUTRAL_12 : undefined;

    return (
        <CRMStatusPill
            pillColor={BackgroundColour}
            borderColor={BackgroundColour}
            textColor={TextColour}
            label={Label}
            seperatorBorderColor={SeperatorBorderColor}
            subLabel={SubLabel}
            onClick={props.onClick}
        />
    );
};

type TRoadblockStatusLabelFromFormProps = {
    form: TRoadblockForm;
    onClick?: () => void;
};

export const CRMRoadblockStatusLabelFromForm = (props: React.PropsWithChildren<TRoadblockStatusLabelFromFormProps>): JSX.Element => {

    const AutomaticCheckitems = props.form.children.checklist_items_automatic;
    const ManualCheckItems = props.form.children.checklist_items_manual
    const ChecklistItemCount = ManualCheckItems.length + AutomaticCheckitems.length;
    const DoneManualCount = ManualCheckItems.filter((item) => item.edited.resolved_at !== null).length
    const DoneAutomaticCount = AutomaticCheckitems.filter((item) => item.resolved_at !== null).length;
    const DoneCheckItemsCount = DoneManualCount + DoneAutomaticCount;

    const SubLabel = props.form.children.details.status === "open" && ChecklistItemCount > 0 ? `${DoneCheckItemsCount}/${ChecklistItemCount}` : undefined;

    return (
        <CRMRoadblockStatusLabel
            status={props.form.children.details.status}
            subLabel={SubLabel}
            onClick={props.onClick}
        />
    );
};