import * as rxjs from "rxjs";
import * as rxjsOperators from "rxjs/operators";
import { TActionObservable } from "../../state/applyActions";
import {TSetState as TSetState} from "../../state/TSetState";
import {TGetState as TGetState} from "../../state/TGetState";
import { TLensFunction } from "./lensBaseTypes";
import { TActionPayload, TPayloadActionType } from "../../state/actions/TAction";

export const iif =
    <A extends TPayloadActionType, T> (
        predicate: (action: TActionPayload<A, T>, getState: TGetState) => boolean,
        lensFunction: TLensFunction<A, T, unknown>
    ) =>
        (obs$: TActionObservable<A, T>, getState: TGetState, setState: TSetState) =>
            obs$.pipe(
                rxjsOperators.filter<TActionPayload<A, T>>((action) => predicate(action, getState)),
                rxjsOperators.mergeMap((action) =>
                    lensFunction(
                        rxjs.of(action),
                        getState,
                        setState
                    )
                )
            );
