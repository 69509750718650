import React from "react";
import { TListingFull1Editable } from "../../../../domain/codecs/formEditable/ListingEditable";
import { ListingProgressionMortgageApplicationSubmitted } from "../../../../domain/codecs/Listing";
import { CRMCodecEditForm } from "../CRMCodecEditForm/CRMCodecEditForm";
import { CRMSpacingColumn } from "../CRMSpacingColumn/CRMSpacingColumn";

type TProps = {
    listingForm: TListingFull1Editable;
    onChange: (listingForm: TListingFull1Editable) => void;
};

export const CRMListingProgressionMortgageApplicationSubmitted = (props: TProps): JSX.Element => {
    return (
        <CRMSpacingColumn spacing="large">
            <CRMCodecEditForm
                codec={ListingProgressionMortgageApplicationSubmitted}
                model={props.listingForm.edited}
                validationErrors={props.listingForm.validationErrors}
                onChange={(edited) => props.onChange({
                    ...props.listingForm,
                    edited: {
                        ...props.listingForm.edited,
                        ...edited,
                    }
                })}
            />
        </CRMSpacingColumn>
    );
};
