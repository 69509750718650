import React, { useEffect, useState } from "react";
import { array as extArray } from "../../../../shared/src/utilByDomainGroupExport";
import { CRMFontSizes } from "../../models/CRMFontSizes";
import { CRMSpacing } from "../../models/CRMSpacing";
import { CRMColors, TCRMColourValue } from "../../models/CRMColors";
import { FontQuicksand } from "../BuildingBlocks/FontQuicksand";
import { FontSignikaNegative } from "../BuildingBlocks/FontSignikaNegative";
import { FontSize } from "../BuildingBlocks/FontSize";
import { SpacingColumn } from "../BuildingBlocks/SpacingColumn";
import { SpacingRow } from "../BuildingBlocks/SpacingRow";
import { TextColor } from "../BuildingBlocks/TextColor";
import { Padding } from "../BuildingBlocks/Padding";
import { CRMDeferButtonComponent } from "../CRMDeferButtonComponent/CRMDeferButtonComponent";
import { CRMIcon } from "../CRMIcon/CRMIcon";
import { CRMPadding } from "../Simple/CRMPadding/CRMPadding";
import { CRMHorizontalSeperatorLine } from "../CRMHorizontalSeperatorLine/CRMHorizontalSeperatorLine";
import { CRMInputCheckboxComponent } from "../CRMInputCheckboxComponent/CRMInputCheckboxComponent";
import { Uppercase } from "../Uppercase/Uppercase";
import { WeightBold } from "../WeightBold/WeightBold";
import { WeightMedium } from "../WeightMedium/WeightMedium";
import { Strikethrough } from "../BuildingBlocks/Strikethrough";
import { trimWithEllipsis } from "../../../../shared/src/utilsByDomain/string/trimWithEllipsis";
import { CRMButtonIconWithLabel } from "../CRMButtonIconWithLabel/CRMButtonIconWithLabel";
import { CRMCardOutsidePopupBlank } from "../CRMCardOutsidePopupBlank/CRMCardOutsidePopupBlank";
import { CRMTitleForCard } from "../CRMTitleForCard/CRMTitleForCard";
import CRMInputLabelAndErrorWrapComponent from "../CRMInputLabelAndErrorWrapComponent/CRMInputLabelAndErrorWrapComponent";
import { pipe } from "fp-ts/function";
import { array } from "fp-ts";
import { FormStatus_highestPriority } from "../../../../shared/src/codecs/codec";
import { DateTime } from "luxon";
import { TCaseAdHocJobForm, TCaseAdHocJobNewForm } from "../../../../domain/codecs/form/CaseAdHocJobForms"
import { CRMCardOutsidePopupBasic } from "../CRMCardOutsidePopupBasic/CRMCardOutsidePopupBasic";
import { CRMAutosaveIndicatorWrap } from "../CRM/CRMAutosaveIndicatorWrap/CRMAutosaveIndicatorWrap";
import { CRMParagraph } from "../Simple/CRMParagraph/CRMParagraph";
import { CRMDropdownComponent } from "../CRMDropdownComponent/CRMDropdownComponent";
import { IOption } from "../../hooks/UseDropdown";
import { TUserAssignableToAdhoc } from "../../../../domain/codecs/User";
import { MinWidth } from "../BuildingBlocks/MinWidth";
import { CRMButtonCard } from "../CRMButtonCard/CRMButtonCard";
import { CRMSpacingColumn } from "../CRMSpacingColumn/CRMSpacingColumn";
import { CRMCreateAdHocTaskForm } from "../Complex/CRMCreateAdHocTaskForm/CRMCreateAdHocTaskForm";
import { CRMTitleSection } from "../CRMTitleSection/CRMTitleSection";

type TAdHocJobCardProps<A extends TCaseAdHocJobForm> = {
    jobs: Array<A>;
    createForm: TCaseAdHocJobNewForm;
    onUpdateNewAdhoc: (form: TCaseAdHocJobNewForm) => void;
    onSubmitNewAdhoc: (form: TCaseAdHocJobNewForm) => void;
    onUpdateJobs: (jobs: Array<A>) => void;
};


export const CRMSailLegalAdHocJobsCard = <A extends TCaseAdHocJobForm>(props: TAdHocJobCardProps<A>): JSX.Element => {

    const [isOpen, setIsOpen] = useState(false);
    const [isAddJobPopupOpen, setIsAddJobPopupOpen] = useState(false);

    useEffect(
        () => {
            if (props.createForm.status === "success") {
                setTimeout(() => closeAddNewJobPopup(), 500);
            }
        },
        [props.createForm.status]
    );

    const closeAddNewJobPopup = (): void => {
        setIsAddJobPopupOpen(false);
    }

    const getIncompleteJobCount = (): number =>
        props.jobs
            .filter((job) => job.edited.is_done === null)
            .length
    
    const hasIncompleteJobs = (): boolean => getIncompleteJobCount() > 0;

    const sortJobsIntoOpenDeferredAndDone = (jobA: A, jobB: A): number => {
        // The heirarchy this sorts into is: 
        // 1. Undeferred, 
        // 2. Deferred, 
        // 3. Done
        if (jobA.edited.is_done !== null && jobB.edited.is_done !== null) {
            return 0;
        }
        
        if (jobA.edited.is_done === null && jobB.edited.is_done !== null) {
            return -1;
        }
        
        if (jobA.edited.is_done !== null && jobB.edited.is_done === null) {
            return 1;
        }

        if (!jobA.edited.deferred_date && jobB.edited.deferred_date) {
            return -1;
        }

        if (jobA.edited.deferred_date && !jobB.edited.deferred_date) {
            return 1;
        }

        return 0;
    }

    const getTextColorBasedJobState = (job: A): TCRMColourValue  => {
        if (job.edited.is_done !== null) {
            return CRMColors.NEUTRAL_4;
        }

        return CRMColors.NEUTRAL_INK;
    }

    const getAssignToOptions = (): Array<IOption<string>> =>
        pipe(
            props.createForm.children.users,
            array.filter((user) => user.id !== sessionUser.id),
            array.map<TUserAssignableToAdhoc, IOption<string>>((user) => ({
                value: user.id,
                label: user.full_name,
            })),
            array.prepend<IOption<string>>({
                value: sessionUser.id,
                label: "Me",
            })
        );

    return (
        <>
            <SpacingColumn spacing={CRMSpacing.MEDIUM}>
                {/* TITLE */}
                <CRMTitleSection>
                    Adhoc Jobs
                </CRMTitleSection>

                {/* FACE */}
                <SpacingRow
                    spacing={CRMSpacing.TINY}
                    justifyContent="space-between"
                    alignItems="flex-start"
                >
                    {/* TITLE */}
                    <FontSignikaNegative>
                        <Uppercase>
                            <WeightBold>
                                <FontSize size={CRMFontSizes.LARGE}>
                                    {getIncompleteJobCount()} Outstanding Ad Hoc Jobs
                                </FontSize>
                            </WeightBold>
                        </Uppercase>
                    </FontSignikaNegative>

                    {/* ARROW */}
                    {props.jobs.length > 0 &&
                        <SpacingRow spacing={CRMSpacing.TINY} alignItems="baseline" onClick={() => setIsOpen(!isOpen)} cursorStyle={"pointer"}>
                            <CRMParagraph>
                                Edit / Resolve
                            </CRMParagraph>
                            <CRMIcon
                                iconName="dynamic"
                                size="small"
                                colour="neutral-ink"
                                onClick={() => setIsOpen(!isOpen)}
                                title="Edit / Resolve"
                            />
                        </SpacingRow>
                    }
                </SpacingRow>

                {/* JOBS */}
                {hasIncompleteJobs() &&
                    <SpacingColumn spacing={CRMSpacing.TINY}>
                        {props.jobs
                            .filter((job) => job.edited.is_done === null)
                            .sort(sortJobsIntoOpenDeferredAndDone)
                            .map((job, index) => (
                                <SpacingRow 
                                    key={index}
                                    spacing={CRMSpacing.TINY}
                                >
                                    {/* DEFFERED JOB ICON */}
                                    {job.edited.deferred_date && DateTime.fromISO(job.edited.deferred_date).toMillis() > DateTime.local().toMillis() &&
                                        <CRMIcon
                                            iconName="snooze"
                                            colour="neutral-ink"
                                            size="tiny"
                                        />
                                    }
                                    {/* JOB PREVIEW TEXT */}
                                    <FontQuicksand>
                                        <FontSize size={CRMFontSizes.SMALL} lineHeight="1.4">
                                            <WeightMedium>
                                                <TextColor color={getTextColorBasedJobState(job)}>
                                                    {trimWithEllipsis(job.edited.job, 45)}
                                                </TextColor>
                                            </WeightMedium>
                                        </FontSize>
                                    </FontQuicksand>
                                </SpacingRow>
                            ))}
                    </SpacingColumn>
                }

                {/* NO JOBS TEXT */}
                {!hasIncompleteJobs() &&
                    <FontQuicksand>
                        <FontSize size={CRMFontSizes.SMALL} lineHeight="1.4">
                            <TextColor color={CRMColors.NEUTRAL_INK}>
                                No incomplete jobs..
                            </TextColor>
                        </FontSize>
                    </FontQuicksand>
                }

                {/* ADD JOBS BUTTON */}
                <CRMButtonIconWithLabel
                    variant="primary"
                    label="Add job"
                    icon="add"
                    onClick={() => setIsAddJobPopupOpen(true)}
                />
            </SpacingColumn>

            {/* JOB LIST POPUP */}
            {isOpen && <CRMCardOutsidePopupBasic
                isOpen={true}
                onClose={() => setIsOpen(false)}
                title="Ad hoc jobs"
                size="medium"
            >
                <CRMAutosaveIndicatorWrap status={
                    pipe(
                        props.jobs,
                        array.map((form) => form.status),
                        FormStatus_highestPriority,
                    )
                }>
                    <SpacingColumn spacing={CRMSpacing.LARGE}>
                        {props.jobs
                            .sort(sortJobsIntoOpenDeferredAndDone)
                            .map((job) => (
                                <SpacingColumn key={job.children.id} spacing={CRMSpacing.MEDIUM}>
                                    
                                    <FontQuicksand>
                                        <FontSize size={CRMFontSizes.SMALL} lineHeight="1.4">
                                            <WeightBold>
                                                <TextColor color={getTextColorBasedJobState(job)}>
                                                    {job.edited.is_done !== null &&
                                                        <Strikethrough>
                                                            {job.edited.job}
                                                        </Strikethrough>
                                                    }
                                                    {job.edited.is_done === null &&
                                                        job.edited.job
                                                    }
                                                </TextColor>
                                            </WeightBold>
                                        </FontSize>
                                    </FontQuicksand>

                                    <SpacingRow spacing={CRMSpacing.MEDIUM} justifyContent="space-between">
                                        <CRMInputCheckboxComponent
                                            label="Done"
                                            checked={job.edited.is_done !== null}
                                            onClick={() => props.onUpdateJobs(
                                                pipe(
                                                    props.jobs,
                                                    extArray.updateWhere<A>(
                                                        (compareJob) => compareJob.children.id === job.children.id,
                                                        (jobToUpdate) => ({
                                                            ...jobToUpdate,
                                                            edited: {
                                                                ...jobToUpdate.edited,
                                                                is_done: jobToUpdate.edited.is_done === null ? DateTime.utc().toISODate() : null,
                                                            },
                                                            status: "requiresSubmission",
                                                        })
                                                    )
                                                )
                                            )}
                                        />
                                        
                                        {job.edited.is_done === null &&
                                            <CRMButtonCard label={<CRMParagraph>Defer / Assign</CRMParagraph>} backgroundColour={CRMColors.NEUTRAL_12}>
                                                <MinWidth width="300px">
                                                    <CRMPadding size="medium">
                                                        <CRMSpacingColumn spacing="medium">
                                                            {/* ASSIGN TO */}
                                                            <CRMInputLabelAndErrorWrapComponent label="Assigned to">
                                                                <CRMDropdownComponent
                                                                    options={getAssignToOptions()}
                                                                    value={job.edited.assigned_to}
                                                                    onChange={(assigned_to) => props.onUpdateJobs(
                                                                        pipe(
                                                                            props.jobs,
                                                                            extArray.updateWhere<A>(
                                                                                (compareJob) => compareJob.children.id === job.children.id,
                                                                                (jobToUpdate) => ({
                                                                                    ...jobToUpdate,
                                                                                    edited: {
                                                                                        ...jobToUpdate.edited,
                                                                                        assigned_to,
                                                                                    },
                                                                                    status: "requiresSubmission",
                                                                                })
                                                                            )
                                                                        )
                                                                    )}
                                                                    displayError={false}
                                                                />
                                                            </CRMInputLabelAndErrorWrapComponent>

                                                            {/* DEFER */}
                                                            <CRMDeferButtonComponent
                                                                value={job.edited.deferred_date}
                                                                onChange={(deferred_date) => props.onUpdateJobs(
                                                                    pipe(
                                                                        props.jobs,
                                                                        extArray.updateWhere<A>(
                                                                            (compareJob) => compareJob.children.id === job.children.id,
                                                                            (jobToUpdate) => ({
                                                                                ...jobToUpdate,
                                                                                edited: {
                                                                                    ...jobToUpdate.edited,
                                                                                    deferred_date,
                                                                                },
                                                                                status: "requiresSubmission",
                                                                            })
                                                                        )
                                                                    )
                                                                )}
                                                                displayError={false}
                                                            />
                                                        </CRMSpacingColumn>
                                                    </CRMPadding>
                                                </MinWidth>
                                            </CRMButtonCard>
                                        }
                                    </SpacingRow>

                                    <CRMHorizontalSeperatorLine />
                                </SpacingColumn>
                            ))}
                        {/* ADD JOB BUTTON */}
                        <CRMButtonIconWithLabel
                            variant="primary"
                            label="Add job"
                            icon="add"
                            onClick={() => {
                                setIsOpen(false);
                                setIsAddJobPopupOpen(true);
                            }}
                        />
                    </SpacingColumn>
                </CRMAutosaveIndicatorWrap>
            </CRMCardOutsidePopupBasic>}

            {/* ADD JOB POPUP */}
            <CRMCardOutsidePopupBlank
                isOpen={isAddJobPopupOpen}
                onClose={closeAddNewJobPopup}
            >
                <Padding spacing={CRMSpacing.LARGE}>
                    <SpacingColumn spacing={CRMSpacing.LARGE}>
                        <SpacingColumn spacing={CRMSpacing.MEDIUM}>
                            
                            <CRMTitleForCard>
                                Add ad hoc job
                            </CRMTitleForCard>

                            <CRMCreateAdHocTaskForm
                                form={props.createForm}
                                taskButtonLabel={"Create job"}
                                onChange={(form) => props.onUpdateNewAdhoc(form as TCaseAdHocJobNewForm)}
                                onSubmit={() => props.onSubmitNewAdhoc(props.createForm)}
                                onCancel={closeAddNewJobPopup}
                            />
                        </SpacingColumn>
                    </SpacingColumn>
                </Padding>
            </CRMCardOutsidePopupBlank>
        </>
    );
};
