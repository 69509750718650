import React from "react";

export class CRMTitleParagraph extends React.Component<React.PropsWithChildren<{}>> {
    public render (): JSX.Element {
        return (
            <span className="crm-title-paragraph">
                {this.props.children}
            </span>
        );
    }
}
