import React from "react";
import UnstyledInputGeneralComponent, { IInputGeneralComponentProps } from "../../../UnstyledInputGeneralComponent/UnstyledInputGeneralComponent";

interface IFrontInputGeneralComponentProps extends IInputGeneralComponentProps {
    textAlignment?: "left" | "center" | "right";
    borderRadius?: string;
    excludeInFullstory?: boolean;
    autofocus?: boolean;
};

export class FrontInputGeneralComponent extends React.Component<React.PropsWithChildren<IFrontInputGeneralComponentProps>> {
    public render (): JSX.Element {
        return (
            <UnstyledInputGeneralComponent
                {...this.props}
                className={`
                    front-input-general
                    front-input-general--text-align-${this.props.textAlignment || "left"}
                    front-input-general--${this.props.disabled ? 'disabled' : 'enabled'}
                    front-input-general--${this.props.displayError ? 'error' : 'no-error'}
                    ${this.props.excludeInFullstory ? "fs-exclude" : ""}
                `}
                style={{
                    borderRadius: this.props.borderRadius || "5px"
                }}
            />
        );
    }
}

export default FrontInputGeneralComponent;
