import React from "react";
import { TTriageSimpleEmailForm } from "../../../../../domain/codecs/form/TriageForm";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { TPixelSize } from "../../../models/StringLiterals";
import { Padding } from "../../BuildingBlocks/Padding";
import { Sticky } from "../../BuildingBlocks/Sticky";
import { CRMEmailBody } from "../../Simple/CRMEmailBody/CRMEmailBody";
import { CRMLoadingBar } from "../../Simple/CRMLoadingBar/CRMLoadingBar";
import { CRMRichContentPlaceholder } from "../../Simple/CRMRichContentPlaceholder/CRMRichContentPlaceholder";
import { CRMSingleViewHeader, TSingleViewHeaderEdge } from "../../Simple/CRMSingleViewHeader/CRMSingleViewHeader";
import { CRMEmailMetadata } from "../CRMEmailMetadata/CRMEmailMetadata";
import { CRMEmailSingleViewBodyNotificationBar } from "../CRMEmailSingleViewBodyNotificationBar/CRMEmailSingleViewBodyNotificationBar";

type TEmailSingleViewBodyProps = {
    email: TTriageSimpleEmailForm;
    count?: number;
    headerEdge?: TSingleViewHeaderEdge;
    onClose: () => void;
};

export const CRMEmailSingleViewBody = (props: React.PropsWithChildren<TEmailSingleViewBodyProps>): JSX.Element => {

    const getEmailDetails = () => {
        if (hasDetailedEmail()) {
            return getDetailedEmail();
        }

        return props.email.original;
    }

    const getDetailedEmail = () => props.email.children.detailed_email_form.children.email;

    const hasDetailedEmail = () => !!props.email.children.detailed_email_form.children.email.id;

    const isLoading = () => (
        props.email.status === "loading" ||
        props.email.children.detailed_email_form.status === "loading"
    );


    return (
        <div className="crm-email-single-view-body">
            {/* HEADER */}
            <Sticky 
                zIndex={1}
            >
                <CRMSingleViewHeader 
                    title={getEmailDetails().subject}
                    edge={props.headerEdge}
                    onClick={props.onClose}
                >
                    {!!props.count &&
                        <div className="crm-email-single-view-body__count">
                            {props.count}
                        </div>
                    }
                </CRMSingleViewHeader>

                {isLoading() &&
                    <CRMLoadingBar duration={2000} />
                }
            </Sticky>

            {/* META */}
            <CRMEmailMetadata
                email={props.email.children.detailed_email_form}
                fieldsToShow={[
                    "sent_at",
                    "from",
                    "to",
                    "cc",
                    "bcc",
                    "attachments",
                ]}
            />

            {/* NOTIFICATIONS */}
            {hasDetailedEmail() && 
                <CRMEmailSingleViewBodyNotificationBar email={props.email} />
            }

            {/* BODY */}
            <div className="crm-email-single-view-body__body">
                {/* IS LOADING - PLACEHOLDER */}
                {!hasDetailedEmail() &&
                    <Padding type="horizontal" spacing={CRMSpacing.MEDIUM}>
                        <CRMRichContentPlaceholder
                            shape={[
                                ["text", 3, "100%"],
                                ["image", 2, "200px"],
                                ["text", 5, "100%"],
                                ["image", 4, "100px"],
                                ["text", 6, "100%"],
                            ]}
                        />
                    </Padding>
                }
                
                {/* HAS LOADED - EMAIL BODY */}
                {hasDetailedEmail() &&
                    <CRMEmailBody email={getDetailedEmail()} />
                }
            </div>
        </div>
    );
};
