import React, { useState } from "react";
import { TDetailedEmailForm, TTriageSimpleEmailForm } from "../../../../../domain/codecs/form/TriageForm";
import { useResolutionStatus } from "../../../hooks/useResolutionStatus";
import { TPixelSize } from "../../../models/StringLiterals";
import { CRMBottomControlBar } from "../../CRMBottomControlBar/CRMBottomControlBar";
import { CRMEmailSingleViewBody } from "../CRMEmailSingleViewBody/CRMEmailSingleViewBody";
import { CRMEmailSingleViewControls } from "../CRMEmailSingleViewControls/CRMEmailSingleViewControls";

type TResolutionStatus =
    | "none"
;

type TResolutionData = {}

type TEmailViewStatus = 
    "email-body"
;

type TPrivateEmailSingleViewUnresolvedProps = {
    count: number;
    email: TTriageSimpleEmailForm;

    isShowingNext: boolean;
    isShowingPrevious: boolean;
    
    onClose: () => void;
    onNext: () => void;
    onPrevious: () => void;

    onReply: (form: TDetailedEmailForm) => void;
    onForward: (form: TDetailedEmailForm) => void;
};

export const CRMPrivateEmailSingleViewResolved = (props: React.PropsWithChildren<TPrivateEmailSingleViewUnresolvedProps>): JSX.Element => {

    // The two statuses have been left here for easier expansion
    // if and when the single view starts supporting triage actions.
    const [viewStatus, setViewStatus] = useState<TEmailViewStatus>("email-body");
    const {
        resolutionStatus,
        resolutionData,
        setResolution,
        resetResolution,
    } = useResolutionStatus<TResolutionStatus, TResolutionData>({
        defaultStatus: "none",
        onSet: () => setViewStatus("email-body")
    });

    const hasDetailedEmail = () => !!props.email.children.detailed_email_form.children.email.id;


    return (
        <div className={`crm-email-single-view-resolved`}>
            {/* BODY */}
            {viewStatus === "email-body" &&
                <CRMEmailSingleViewBody
                    {...props}
                />
            }
            
            {/* CONTROLS & COUNTDOWN NOTIFICATIONS */}
            {viewStatus === "email-body" &&
                <CRMBottomControlBar>
                    {resolutionStatus === "none" &&
                        <CRMEmailSingleViewControls
                            hideAllActions={! hasDetailedEmail()}
                            isShowingPrevious={props.isShowingPrevious}
                            isShowingNext={props.isShowingNext}

                            onReply={() => props.onReply(props.email.children.detailed_email_form)}
                            onForward={() => props.onForward(props.email.children.detailed_email_form)}

                            onPrevious={props.onPrevious}
                            onNext={props.onNext}
                        />
                    }
                </CRMBottomControlBar>
            }
        </div>
    );
};
