import { array } from "fp-ts";
import { pipe } from "fp-ts/lib/function";
import React from "react";
import { TCaseCompanyTypeInfo } from "../../../../domain/codecs/Cases";
import { useOpenClose } from "../../hooks/UseOpenClose";
import { CRMFontSizes } from "../../models/CRMFontSizes";
import { CRMSpacing } from "../../models/CRMSpacing";
import { CRMColors } from "../../models/CRMColors";
import { BulletPoint } from "../BuildingBlocks/BulletPoint";
import { Flex } from "../BuildingBlocks/Flex";
import { InlineFlex } from "../BuildingBlocks/InlineFlex";
import { SpacingColumn } from "../BuildingBlocks/SpacingColumn";
import { SpacingRow } from "../BuildingBlocks/SpacingRow";
import { CRMParagraphBulletPoints } from "../CRMBulletList/CRMParagraphBulletPoints";
import { CRMParagraph } from "../Simple/CRMParagraph/CRMParagraph";
import { CRMSeeMore } from "../Simple/CRMSeeMore/CRMSeeMore";
import { WeightBold } from "../WeightBold/WeightBold";
import { WeightMedium } from "../WeightMedium/WeightMedium";
import { amlUndertakings, companyTypeVariations } from "./companyAndUndertakings";

export const CRMCompanyAMLInfoComponent = (props: React.PropsWithChildren<TCaseCompanyTypeInfo>): JSX.Element => {
    const maxUndertakingsVisibleBeforeFold = 5;

    const {
        isOpen,
        toggleOpen,
    } = useOpenClose({
        defaultValue: false,
    });

    const getRelevantCompanyTypeVariations = (): Array<typeof companyTypeVariations[number]> => [
        ...(props.company_type !== "unknown" ? [props.company_type] : []),
        ...(props.company_sub_type_listing_on_any_stock_exchange ? ["listing_on_any_stock_exchange"] as ["listing_on_any_stock_exchange"] : []),
        ...(props.company_sub_type_aim_listed ? ["aim_listed"] as ["aim_listed"] : []),
        ...(props.company_sub_type_majority_owned_or_consolidated_subsidiary ? ["majority_owned_or_consolidated_subsidiary"] as ["majority_owned_or_consolidated_subsidiary"] : []),
        ...(props.company_sub_type_fca_regulated ? ["fca_regulated"] as ["fca_regulated"] : []),
        ...(props.company_sub_type_other_non_uk_credit_or_financial_institutions ? ["other_non_uk_credit_or_financial_institutions"] as ["other_non_uk_credit_or_financial_institutions"] : []),
        ...(props.company_sub_type_uk_or_eea_public_authorities ? ["uk_or_eea_public_authorities"] as ["uk_or_eea_public_authorities"] : []),
        ...(props.company_sub_type_professionals_instructing ? ["professionals_instructing"] as ["professionals_instructing"] : []),
        ...(props.company_sub_type_foundation ? ["foundation"] as ["foundation"] : []),
        ...(props.company_sub_type_uk_charity ? ["uk_charity"] as ["uk_charity"] : []),
        ...(props.company_sub_type_place_of_worship ? ["place_of_worship"] as ["place_of_worship"] : []),
        ...(props.company_sub_type_state_school_or_college ? ["state_school_or_college"] as ["state_school_or_college"] : []),
        ...(props.company_sub_type_club_or_association ? ["club_or_association"] as ["club_or_association"] : []),
        ...(props.company_sub_type_pension_scheme ? ["pension_scheme"] as ["pension_scheme"] : []),
        ...(props.company_sub_type_sovereign_wealth_funds ? ["sovereign_wealth_funds"] as ["sovereign_wealth_funds"] : []),
    ]

    const getRelevantUndertakings = () =>
        pipe(
            amlUndertakings,
            array.filter(({appliesForCompanyTypes}) =>
                pipe(
                    appliesForCompanyTypes,
                    array.filter((companyType) => getRelevantCompanyTypeVariations().includes(companyType)),
                    (a) => a.length > 0,
                )
            ),
        );

    const getRelevantUndertakingsToDisplay = () =>
        isOpen
            ? getRelevantUndertakings()
            : pipe(
                getRelevantUndertakings(),
                array.takeLeft(maxUndertakingsVisibleBeforeFold)
            );

    const shouldDisplaySeeMore = (): boolean =>
        getRelevantUndertakings().length > maxUndertakingsVisibleBeforeFold;

    const getUndertakingsHidden = (): number =>
        getRelevantUndertakings().length - maxUndertakingsVisibleBeforeFold;

    return <SpacingColumn spacing={CRMSpacing.MEDIUM}>
        {/* BULLET POINT AML UNDERTAKINGS */}
        {getRelevantUndertakingsToDisplay().map(({title, description}) =>
            <SpacingRow spacing={CRMSpacing.MEDIUM} alignItems="flex-start">
                {/* BULLET POINT */}
                <div style={{display: "flex", marginTop: "10px"}}>
                    <BulletPoint bulletColor={CRMColors.NEUTRAL_INK} size="normal" />
                </div>

                {/* BULLET CONTENT */}
                <SpacingColumn spacing={CRMSpacing.SMALL}>
                    <CRMParagraph fontSize={CRMFontSizes.MED}>
                        <WeightBold>
                            {title}
                        </WeightBold>
                    </CRMParagraph>
                    <CRMParagraph fontSize={CRMFontSizes.SMALL}>
                        <WeightMedium>
                            {description}
                        </WeightMedium>
                    </CRMParagraph>
                </SpacingColumn>
            </SpacingRow>
        )}

        {/* SEE MORE */}
        {shouldDisplaySeeMore() && <CRMSeeMore
            isExpanded={isOpen}
            seeMoreLabel={`Show ${getUndertakingsHidden()} more checks`}
            onClick={toggleOpen}
        />}
    </SpacingColumn>;
};
