import React from "react";
import { SpacingColumn } from "../../../components/BuildingBlocks/SpacingColumn";
import { FrontQuestionFormCard } from "../../../components/Front/Complex/FrontQuestionFormCard/FrontQuestionFormCard";
import { TContainerStateProps } from "../../../state/TContainerStateProps";
import { FrontSpacing } from "../../../models/FrontSpacing";
import { TOnboardingTabStatus } from "../../../components/Front/Simple/FrontOnboardingTab/FrontOnboardingTab";
import FrontParagraphSmall from "../../../components/Front/Simple/FrontParagraphSmall/FrontParagraphSmall";
import { FrontBulletpoint } from "../../../components/Front/Simple/FrontBulletpoint/FrontBulletpoint";
import { FrontFormSectionTitle } from "../../../components/Front/Simple/FrontFormSectionTitle/FrontFormSectionTitle";
import { requireExhaustive } from "../../../../../shared/src/util";
import { literal } from "../../../../../shared/src/codecs/types/literal";
import { union } from "../../../../../shared/src/codecs/types/union";
import { TTypeOfCodec } from "../../../../../shared/src/codecs/codec";
import { StyleItalic } from "../../../components/BuildingBlocks/StyleItalic";
import { Sticky } from "../../../components/BuildingBlocks/Sticky";
import { SpacingRow } from "../../../components/BuildingBlocks/SpacingRow";
import { FrontFormAgreeButton } from "../../../components/Front/Simple/FrontFormAgreeButton/FrontFormAgreeButton";
import { FrontOnboardingCaseDualRepExplanationMobile } from "../../../components/Front/Complex/FrontOnboardingCaseDualRepExplanationMobile/FrontOnboardingCaseDualRepExplanationMobile";


const FrontDualRepConsentContainer = (props: React.PropsWithChildren<TContainerStateProps>): JSX.Element => (
    <>
        {/* ON MOBILE - DUAL REP CONSENT, AGREE BUTTON AND EXPLANATION */}
        <div className="front-dual-rep-consent-container__mobile">
            <SpacingColumn spacing={FrontSpacing.MEDIUM_1}>
                
                {/* CONSENT BUTTON */}
                {!!props.state.forms.client_case_page.children.dual_rep_consent
                    && props.state.forms.client_case_page.children.dual_rep_consent.is_step_complete === false
                    && <Sticky top="60px">
                        <SpacingRow justifyContent="center">
                            <FrontFormAgreeButton
                                label="Consent to Dual Representation"
                                icon="done"
                                hasBoxShadow={true}
                                onClick={() => props.dispatch({
                                    type: "CLIENT_SUBMIT_DUAL_REP_CONSENT_STEP_FORM_AS_COMPLETE",
                                    payload: null
                                })}
                            />
                        </SpacingRow>
                    </Sticky>
                }
            
                <SpacingColumn spacing={FrontSpacing.MEDIUM_2}>
                    
                    {/* DUAL REP CONSENT - EXPLANATION BUBBLE - MOBILE */}
                    {!!props.state.forms.client_case_page.children.dual_rep_consent
                        && props.state.forms.client_case_page.children.dual_rep_consent.is_step_complete === false
                        && <FrontOnboardingCaseDualRepExplanationMobile
                                caseUserType={props.state.forms.client_case_page.children.dual_rep_consent ? props.state.forms.client_case_page.children.session_user_case_user_type.user_type : "unknown"}
                                transactionType={props.state.forms.client_case_page.children.case.transaction_type}
                                onClick={() => props.dispatch({
                                    type: "CLIENT_SUBMIT_DUAL_REP_CONSENT_STEP_FORM_AS_COMPLETE",
                                    payload: null
                                })}
                            />
                    }
                    
                    {/* DUAL REP CONSENT - FULL INFO - MOBILE */}
                    <FrontQuestionFormCard
                        title="Dual Representation - Consent"
                    >
                        <SpacingColumn spacing={FrontSpacing.LARGE_1}>
                            {DualRepSectionTitle.values.map((title) => getDualRepSection(title, props))}
                        </SpacingColumn>
                    </FrontQuestionFormCard>
                
                </SpacingColumn>
            </SpacingColumn>
        </div>

        {/* DESKTOP - FULL INFO */}
        <div className="front-dual-rep-consent-container__desktop">
            <FrontQuestionFormCard
                title="Dual Representation - Consent"
            >
                <SpacingColumn spacing={FrontSpacing.LARGE_1}>
                    {DualRepSectionTitle.values.map((title) => getDualRepSection(title, props))}
                </SpacingColumn>
            </FrontQuestionFormCard>
        </div>
    </>
);

export const getDualRepConsentStatus = (props: TContainerStateProps): TOnboardingTabStatus => {

    if (
        !!props.state.forms.client_case_page.children.dual_rep_consent
        && props.state.forms.client_case_page.children.dual_rep_consent.is_step_complete === true
    ) {
        return "completed";
    }
    
    return "active";
};

const DualRepSectionTitle = union([
    literal("dualRepresentation"),
    literal("whatYouNeedToKnow"),
    literal("youAndYourLender"),
    literal("whenWeCanActForBuyerAndSeller"),
    literal("whenWeCanNotAct"),
    literal("whatIsAConflictOfInterest"),
    literal("howItWorks"),
    literal("consent"),
    literal("caseHandlers"),
]);
type TDualRepSectionTitle = TTypeOfCodec<typeof DualRepSectionTitle>;

const getDualRepSection = (sectionTitle: TDualRepSectionTitle, props: React.PropsWithChildren<TContainerStateProps>): JSX.Element => {
    switch(sectionTitle) {
        case "dualRepresentation":
            return dualRepresentation();
        case "whatYouNeedToKnow":
            return whatYouNeedToKnow();
        case "youAndYourLender":
            return youAndYourLender();
        case "whenWeCanActForBuyerAndSeller":
            return whenWeCanActForBuyerAndSeller();
        case "whenWeCanNotAct":
            return whenWeCanNotAct();
        case "whatIsAConflictOfInterest":
            return whatIsAConflictOfInterest();
        case "howItWorks":
            return howItWorks();
        case "consent":
            return consent(props);
        case "caseHandlers":
            return caseHandlers();
        default:
            return requireExhaustive(sectionTitle)
    }
};

const dualRepresentation = (): JSX.Element => (
    <SpacingColumn spacing={FrontSpacing.MEDIUM_1}>
        <FrontFormSectionTitle>
            Dual Representation
        </FrontFormSectionTitle>

        <FrontParagraphSmall>
            In certain circumstances, licensed Conveyancers can act for the buyer and seller of the same property in a conveyancing transaction.
        </FrontParagraphSmall>

        <FrontParagraphSmall>
            You and the other party involved in the transaction have proposed that Sail Legal act for both of you in your conveyancing matter.
        </FrontParagraphSmall>

        <FrontParagraphSmall>
            Before we can act, we need to explain the safeguards that will be applied for the benefit of both parties, and all parties must agree to us represent them.  Please provide your consent below if you are happy for us to act for you and the other party.  We will be asking the other party to do the same.
        </FrontParagraphSmall>
    </SpacingColumn>
);

const whatYouNeedToKnow = (): JSX.Element => (
    <SpacingColumn spacing={FrontSpacing.MEDIUM_1}>
        <FrontFormSectionTitle>
            What you need to know
        </FrontFormSectionTitle>

        <FrontParagraphSmall>
            We can only act for both parties if, in our professional opinion, there is not (or is not likely to be) a conflict between the interests of both parties. If a conflict does arise, we will have to stop acting for all parties.
        </FrontParagraphSmall>
    </SpacingColumn>
);

const youAndYourLender = (): JSX.Element => (
    <SpacingColumn spacing={FrontSpacing.MEDIUM_1}>
        <FrontFormSectionTitle>
            You and your lender
        </FrontFormSectionTitle>
    
        <FrontParagraphSmall>
            We can act for you and your lender if the lender is a bank, building society, or any other body that provides mortgage loans. We cannot act for you and your lender where there will be a private mortgage. We need to check with lenders to check they are happy with this.
        </FrontParagraphSmall>
    </SpacingColumn>
);

const whenWeCanActForBuyerAndSeller = (): JSX.Element => (
    <SpacingColumn spacing={FrontSpacing.MEDIUM_1}>
        <FrontFormSectionTitle>
            When we can act for both buyer and the seller
        </FrontFormSectionTitle>  

        <FrontParagraphSmall>
            We can act if you are informed in advance that Sail Legal has been asked to act for both parties; and
        </FrontParagraphSmall>

        <FrontParagraphSmall>
            A different qualified conveyancer represents each party, and you and the other party have freely consented to this arrangement.
        </FrontParagraphSmall>
    </SpacingColumn>
);

const whenWeCanNotAct = (): JSX.Element => (
    <SpacingColumn spacing={FrontSpacing.MEDIUM_1}>
        <FrontFormSectionTitle>
            When we cannot act
        </FrontFormSectionTitle>

        <SpacingColumn spacing={FrontSpacing.MINIMUM}>
            <FrontBulletpoint>
                <FrontParagraphSmall>
                    You are buying a new property from a builder or developer
                </FrontParagraphSmall>
            </FrontBulletpoint>
                
            <FrontBulletpoint>
                <FrontParagraphSmall>
                    You are taking a new lease
                </FrontParagraphSmall>
            </FrontBulletpoint>

            <FrontBulletpoint>
                <FrontParagraphSmall>                         
                    You are taking a mortgage from a private individual
                </FrontParagraphSmall>
            </FrontBulletpoint>
        </SpacingColumn>
    </SpacingColumn>
);

const whatIsAConflictOfInterest = (): JSX.Element => (
    <SpacingColumn spacing={FrontSpacing.MEDIUM_1}>
        <FrontFormSectionTitle>
            What is a conflict of interest
        </FrontFormSectionTitle>
            
        <FrontParagraphSmall>
            This may be where the buyer and seller cannot agree over some aspect of the sale, such as the price or what is included in the sale, or a problem arises between exchange and completion, and one party refuses to complete.  
        </FrontParagraphSmall> 

        <FrontParagraphSmall>
            It could also arise if one party tells us something about the property, but they tell us not to pass this information on to the other party.  
        </FrontParagraphSmall> 

        <FrontParagraphSmall>
            We consider these examples as conflicts and would no longer be able to act for you.
        </FrontParagraphSmall> 
        
        <FrontParagraphSmall>
            Please be aware that if a conflict of interest does arise and we can no longer act, provided that the conflict could not reasonably have been foreseen, we will be entitled to charge a fee for the work and any disbursements incurred to date.
        </FrontParagraphSmall> 
    </SpacingColumn>
);

const howItWorks = (): JSX.Element => (
    <SpacingColumn spacing={FrontSpacing.MEDIUM_1}>
        <FrontFormSectionTitle>
            How it works
        </FrontFormSectionTitle>

        <FrontParagraphSmall>
            A qualified, authorised conveyancer will represent both parties.
        </FrontParagraphSmall>

        <SpacingColumn spacing={FrontSpacing.MINIMUM}>
            <FrontParagraphSmall> 
                Your conveyancer will not be able to view the other party’s file, details, documents etc with only two exceptions:
            </FrontParagraphSmall>

            <FrontBulletpoint>
                <FrontParagraphSmall>
                    One exception to this rule relates to our Anti-Money Laundering obligations in our main Terms of Engagement.  
                </FrontParagraphSmall>
            </FrontBulletpoint>

            <FrontBulletpoint>
                <FrontParagraphSmall>
                    The other exception to this rule relates to potential breaches of the Regulatory Code whereby Edwina Homfray-Davies would conduct an investigation which may, where necessary, trigger reporting obligations to our regulator, the CLC.
                </FrontParagraphSmall>
            </FrontBulletpoint>
        </SpacingColumn>

    </SpacingColumn>
);

const consent = (props: TContainerStateProps): JSX.Element => (
    <SpacingColumn spacing={FrontSpacing.MEDIUM_1}>
        <FrontFormSectionTitle>
            Consent
        </FrontFormSectionTitle>    

        <FrontParagraphSmall>
            We have a central administrative function dealing with post distribution, quote generation, drafting contracts and ordering searches.
        </FrontParagraphSmall>

        <FrontParagraphSmall>
            We also operate a centralised reception function.  As you are consenting to 'acting on both sides' our reception function will not review your file or deal with any enquiries.  All messages will be transferred to your case handler/assistant to deal with.
        </FrontParagraphSmall>

        <SpacingColumn spacing={FrontSpacing.MINIMUM}>
            <FrontParagraphSmall>
                By providing consent you are agreeing to these terms and:
            </FrontParagraphSmall>

            {props.state.forms.client_case_page.children.dual_rep_consent && props.state.forms.client_case_page.children.dual_rep_consent.other_side_case_handler
                && <FrontBulletpoint>
                    <FrontParagraphSmall>
                        Agree to be represented by {
                            props.state.forms.client_case_page.children.dual_rep_consent?.client_case_handler.first_name} {props.state.forms.client_case_page.children.dual_rep_consent?.client_case_handler.last_name
                        } and {
                            props.state.forms.client_case_page.children.dual_rep_consent?.other_side_case_handler?.first_name} {props.state.forms.client_case_page.children.dual_rep_consent?.other_side_case_handler?.last_name
                        } to represent the other side.
                    </FrontParagraphSmall>
                </FrontBulletpoint>
            }

            {props.state.forms.client_case_page.children.dual_rep_consent && !props.state.forms.client_case_page.children.dual_rep_consent.other_side_case_handler
                && <FrontBulletpoint>
                    <FrontParagraphSmall>
                        Agree to be represented by {
                            props.state.forms.client_case_page.children.dual_rep_consent?.client_case_handler.first_name} {props.state.forms.client_case_page.children.dual_rep_consent?.client_case_handler.last_name
                        } and for another Sail Legal Licensed Conveyancer to represent the other side.
                    </FrontParagraphSmall>
                </FrontBulletpoint>
            }

            <FrontBulletpoint>
                <FrontParagraphSmall>
                    Agree Sail Legal will also represent the other party involved in the transaction.
                </FrontParagraphSmall>
            </FrontBulletpoint>

            <FrontBulletpoint>
                <FrontParagraphSmall>
                    Understand if there is a conflict, we will cease to act for all parties.
                </FrontParagraphSmall>
            </FrontBulletpoint>

            <FrontBulletpoint>
                <FrontParagraphSmall>
                    Agree our Anti-Money Laundering and Reporting obligations are exceptions, and you provide consent for our Money Laundering Reporting Officer and Deputy to review the file should the need arise.
                </FrontParagraphSmall>
            </FrontBulletpoint>

            <FrontBulletpoint>
                <FrontParagraphSmall>
                    Agree our Regulatory code obligations are exceptions and you provide consent for Edwina Homfray-Davies to investigate all matters in relation to that potential breach, should the need arise.
                </FrontParagraphSmall>
            </FrontBulletpoint>

            <FrontBulletpoint>
                <FrontParagraphSmall>
                    Agree that you have discussed with any joint buyers/sellers and have authority from them to consent at this stage.
                </FrontParagraphSmall>
            </FrontBulletpoint>
        </SpacingColumn>
    </SpacingColumn>
);

const caseHandlers = (): JSX.Element => (
    <SpacingColumn spacing={FrontSpacing.MEDIUM_1}>
        <StyleItalic>
            <FrontParagraphSmall>
                Edwina Homfray-Davies is a Licensed Conveyancer, the Head of Legal Practice (HOLP) and also the Head of Finance and Administration (HOFA). Jamie Cole is the Money Laundering Reporting Officer (MLRO). Sail Legal is regulated by the Council for Licensed Conveyancers (CLC)
            </FrontParagraphSmall>
        </StyleItalic>
    </SpacingColumn>
);

export default FrontDualRepConsentContainer;