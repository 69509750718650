import * as rxjs from "rxjs";
import * as rxjsOperators from "rxjs/operators";
import { option, nonEmptyArray, array } from "fp-ts";
import { pipe } from "fp-ts/lib/function";
import * as fetchWrapper from "../../../wrappers/fetch";
import * as PartyResponse1 from "../../../../../domain/models/PartyResponse1";
import * as FirstPartyFetchResponse from "../../../../../domain/models/FirstPartyFetchResponse";
import * as UserSuccessResponse2 from "../../../../../domain/models/UserSuccessResponse2";
import * as UserSuccessResponse1 from "../../../../../domain/models/UserSuccessResponse1";
import * as UserFilter1 from "../../../../../domain/models/UserFilter1";
import * as Party4 from "../../../../../domain/models/Party4";
import * as Party5 from "../../../../../domain/models/Party5";
import * as Party6 from "../../../../../domain/models/Party6";
import * as Enquiry3 from "../../../../../domain/models/Enquiry3";
import * as Enquiry2 from "../../../../../domain/models/Enquiry2";
import * as ListingEnquiriesViewing3 from "../../../../../domain/models/ListingEnquiriesViewing3";
import * as ListingEnquiriesViewingSuccessResponse2 from "../../../../../domain/models/ListingEnquiriesViewingSuccessResponse2";
import * as ListingSuccessResponse2 from "../../../../../domain/models/ListingSuccessResponse2";
import * as ListingFilter1 from "../../../../../domain/models/ListingFilter1";
import * as GetAddressIOResponse from "../../../../../domain/models/GetAddressIOResponse";
import * as Listing8 from "../../../../../domain/models/Listing8";
import * as Listing7 from "../../../../../domain/models/Listing7";
import * as Listing2 from "../../../../../domain/models/Listing2";
import * as DeleteEntitySuccessResponse from "../../../../../domain/models/DeleteEntitySuccessResponse";
import * as UserPhoneNumberSuccessResponse1 from "../../../../../domain/models/UserPhoneNumberSuccessResponse1";
import * as Party3 from "../../../../../domain/models/Party3";
import * as EnquirySuccessResponse2 from "../../../../../domain/models/EnquirySuccessResponse2";
import * as ListingEnquiriesViewingSuccessResponse3 from "../../../../../domain/models/ListingEnquiriesViewingSuccessResponse3";
import * as EnquiryFilter1 from "../../../../../domain/models/EnquiryFilter1";
import * as ViewingFilter1 from "../../../../../domain/models/ViewingFilter1";
import * as User8 from "../../../../../domain/models/User8";
import * as UserPhoneNumber4 from "../../../../../domain/models/UserPhoneNumber4";
import * as UserPhoneNumber2 from "../../../../../domain/models/UserPhoneNumber2";
import * as ListingSuccessResponse1 from "../../../../../domain/models/ListingSuccessResponse1";
import * as JsonInnerError1 from "../../../../../domain/models/JsonInnerError1";
import * as actionable from "../../../functions/actionable";
import * as util from "../../../util";
import * as TCRMParty from "../../TCRMParty";
import * as TFormStatus from "../../../models/TFormStatus";
import * as TForm from "../../../models/TForm";
import { DateTime } from "luxon";
import { TActionObservable, TFormUpdateActionPayload, TUserActionPayload, TUserExistingPhoneNumberActionPayload } from "../../applyActions";
import {
    TParty2AndC5Form,
    TParty2AndC4Form,
    TListingEnquiriesViewing2AndC3FormList,
    TEnquiryC2AndC3FormList,
    TListing3FormList,
    TListingEnquiriesViewing2FormList,
} from "../../../models/TFormModels";
import { TGetState } from "../../TGetState";
import { TSetState } from "../../TSetState";
import { TDispatch } from "../../TDispatch";
import { TActionPayload, TActionsDefinitionsList } from "../TAction";
import { setFormSubscribed } from "../../../functions/lens/setFormSubscribed";
import { setFormWhereSubscribed } from "../../../functions/lens/setFormWhereSubscribed";
import { submitFormWhere } from "../../../functions/lens/submitFormWhere";
import { reduceResponseToForm } from "../../../functions/lens/reduceResponseToForm";
import { reduceDataToStateUpdate } from "../../../functions/lens/reduceDataToStateUpdate";
import { set } from "../../../functions/lens/set";
import { TValidationError } from "../../../../../shared/src/validation/Error";
import { TChangeRouteAction } from "../../router/routerTypes";
import { createChangeRouteAction } from "../../router/createChangeRouteAction";

export const actions: TActionsDefinitionsList = [
    {
        type: "VIEW_CRM_PARTY",
        run: (
            obs$: rxjs.Observable<TChangeRouteAction<"VIEW_CRM_PARTY">>,
            getState: TGetState,
            setState: TSetState,
        ): void => {
            obs$.pipe(
                rxjsOperators.mergeMap((action) =>
                    rxjs.of(
                        setState(({...s}) => {
                            s.activeData.crm.party = new TCRMParty.C();
                            return s;
                        }),
                    )
                        .pipe(
                            rxjsOperators.mergeMap(() =>
                                rxjs.forkJoin([
                                    makeGetPartyRequest(action.params.partyId, setState, getState),
                                    makeGetEnquiriesListingsViewingsRequests(
                                        action.params.partyId,
                                        setState,
                                        getState,
                                    ),
                                    makeGetPartyListingsRequest(action.params.partyId, setState, getState),
                                ])
                            ),
                            rxjsOperators.mapTo(undefined)
                        )
                )
            ).subscribe();
        },
    },
    {
        type: "CRM_ADD_NEW_PARTY_BUTTON_CLICKED" as const,
        run: (
            obs$: TActionObservable<"CRM_ADD_NEW_PARTY_BUTTON_CLICKED", undefined>,
            getState: TGetState,
            setState: TSetState,
            dispatch: TDispatch,
        ): void => {
            obs$.pipe(
                rxjsOperators.mergeMap(() =>
                    fetchWrapper.json<PartyResponse1.T>({
                        requestParams: {
                            url: `${env.REACT_APP_API_URL}/v1/parties`,
                            method: "POST",
                            body: option.none,
                        },
                        expectedTypeCodec: PartyResponse1.codec,
                        defaultResponse: PartyResponse1.newDefault(),
                    })(),
                ),
                rxjsOperators.tap(util.defaultCRMRequestErrorHandler),
                rxjsOperators.tap((response) => {
                    if (response.tag === FirstPartyFetchResponse.constants.STATUS_2XX) {
                        dispatch(createChangeRouteAction("VIEW_CRM_PARTY", { partyId: response.response.data.id }, {}));
                    }
                }),
            ).subscribe();
        },
    },
    {
        type: "CRM_PARTY_ADD_NEW_PERSON_SUBMIT" as const,
        run: (
            obs$: TActionObservable<"CRM_PARTY_ADD_NEW_PERSON_SUBMIT", undefined>,
            getState: TGetState,
            setState: TSetState,
        ): void => {
            obs$.pipe(
                rxjsOperators.tap(() =>
                    setState(({...s}) => {
                        s.activeData.crm.party.addPerson.newUserForm.response = FirstPartyFetchResponse.createPending(s.activeData.crm.party.addPerson.newUserForm.response.response);
                        s.activeData.crm.party.addPerson.newUserForm.status = TFormStatus.constants.SUBMITTING;
                        return s;
                    })
                ),
                rxjsOperators.mergeMap(() =>
                    fetchWrapper.json<UserSuccessResponse2.T>({
                        requestParams: {
                            url: `${env.REACT_APP_API_URL}/v1/parties/${getState().activeData.crm.party.addPerson.newUserForm.meta.partyId}/users`,
                            method: "POST",
                            body: option.some(JSON.stringify(getState().activeData.crm.party.addPerson.newUserForm.form)),
                        },
                        expectedTypeCodec: UserSuccessResponse2.codec,
                        defaultResponse: getState().activeData.crm.party.addPerson.newUserForm.response.response,
                    })()
                ),
                rxjsOperators.tap(util.defaultCRMRequestErrorHandler),
                rxjsOperators.tap((response) =>
                setState(({...s}) => {
                        s.activeData.crm.party.addPerson.newUserForm.response = response;
                        s.activeData.crm.party.addPerson.newUserForm.status =
                            response.tag === FirstPartyFetchResponse.constants.STATUS_2XX ? TFormStatus.constants.SUCCESS
                            : TFormStatus.constants.FAILURE;
                        return s;
                    }),
                ),
                rxjsOperators.mergeMap((response) =>
                    response.tag === FirstPartyFetchResponse.constants.STATUS_2XX
                        ? makeGetPartyRequest(getState().activeData.crm.party.partyResponse.response.data.id, setState, getState)
                        : rxjs.of(undefined),
                ),
            ).subscribe();
        },
    },
    {
        type: "CRM_PARTY_ADD_EXISTING_USER_SEARCH_INPUT_CHANGED" as const,
        run: (
            obs$: TActionObservable<"CRM_PARTY_ADD_EXISTING_USER_SEARCH_INPUT_CHANGED", string>,
            getState: TGetState,
            setState: TSetState,
        ): void => {
            obs$
            .pipe(
                rxjsOperators.tap((action) =>
                    setState(({...s}) => {
                        s.activeData.crm.party.addPerson.existingUser.searchTerm = action.payload;
                        return s;
                    }),
                ),
                rxjsOperators.mergeMap((action) =>
                    action.payload === ""
                        ? rxjs.of(
                            setState(({...s}) => {
                                s.activeData.crm.party.addPerson.existingUser.searchResults = [];
                                return s;
                            })
                        )
                            .pipe(
                                rxjsOperators.mergeMapTo(rxjs.EMPTY)
                            )
                        : rxjs.of(action),
                ),
                rxjsOperators.debounceTime(100),
                rxjsOperators.mergeMap(() =>
                    fetchWrapper.json<UserSuccessResponse1.T>({
                        requestParams: {
                            url: `${env.REACT_APP_API_URL}/v1/users?filters=${encodeURIComponent(JSON.stringify({
                                type: "GROUP",
                                conditional: "AND",
                                // If users already exist for this party the search results should include a filter to exclude those users
                                filters:  pipe(
                                    [UserFilter1.create(getState().activeData.crm.party.addPerson.existingUser.searchTerm.trim())],
                                    (a) => getState().activeData.crm.party.partyResponse.response.data.users.length > 0
                                        ? [
                                            ...a,
                                            UserFilter1.createWhereUserIdNotAnyOf(
                                                nonEmptyArray.map<TCRMParty.C["partyResponse"]["response"]["data"]["users"][0], string>((user) => user.id)(getState().activeData.crm.party.partyResponse.response.data.users as nonEmptyArray.NonEmptyArray<TCRMParty.C["partyResponse"]["response"]["data"]["users"][0]>)
                                            ),
                                        ]
                                        : a,
                                    ),
                            }))}`,
                            method: "GET",
                            body: option.none,
                        },
                        expectedTypeCodec: UserSuccessResponse1.codec,
                        defaultResponse: UserSuccessResponse1.newDefault(),
                    })(),
                ),
                rxjsOperators.tap(util.defaultCRMRequestErrorHandler),
                rxjsOperators.tap((response) =>
                    setState(({...s}) => {
                        s.activeData.crm.party = TCRMParty.reduceFromExistingUserSearchResults(s.activeData.crm.party, response);
                        return s;
                    }),
                ),
            ).subscribe();
        },
    },
    {
        type: "CRM_PARTY_ADD_EXISTING_USER_SELECTED" as const,
        run: (
            obs$: TActionObservable<"CRM_PARTY_ADD_EXISTING_USER_SELECTED", {userId: string}>,
            getState: TGetState,
            setState: TSetState,
        ): void => {
            obs$
            .pipe(
                rxjsOperators.mergeMap((action) =>  pipe(
                    getState().activeData.crm.party.addPerson.existingUser.searchResults,
                    array.findIndex((result) => result.meta.id === action.payload.userId),
                    option.fold<number, rxjs.Observable<number>>(
                        () => rxjs.EMPTY,
                        rxjs.of,
                    )
                )),
                rxjsOperators.tap((searchResultsIndex) =>
                    setState(({...s}) => {
                        s.activeData.crm.party.addPerson.existingUser.searchResults[searchResultsIndex].status = TFormStatus.constants.SUBMITTING;
                        return s;
                    }),
                ),
                rxjsOperators.mergeMap((searchResultsIndex) =>
                    rxjs.from(
                        fetchWrapper.json<UserSuccessResponse2.T>({
                            requestParams: {
                                url: `${env.REACT_APP_API_URL}/v1/parties/${getState().activeData.crm.party.partyResponse.response.data.id}/users/associate`,
                                method: "PUT",
                                body: option.some(JSON.stringify(getState().activeData.crm.party.addPerson.existingUser.searchResults[searchResultsIndex].form)),
                            },
                            expectedTypeCodec: UserSuccessResponse2.codec,
                            defaultResponse: getState().activeData.crm.party.addPerson.existingUser.searchResults[searchResultsIndex].response.response,
                        })(),
                    )
                        .pipe(
                            rxjsOperators.map<FirstPartyFetchResponse.T<UserSuccessResponse2.T>, [number, FirstPartyFetchResponse.T<UserSuccessResponse2.T>]>((response) => [searchResultsIndex, response]),
                        ),
                ),
                rxjsOperators.tap(([, response]) => util.defaultCRMRequestErrorHandler(response)),
                rxjsOperators.tap(([searchResultsIndex, response]) =>
                    setState(({...s}) => {
                        if (response.tag === FirstPartyFetchResponse.constants.STATUS_2XX) {
                            // Given the user was successfully added to the party reset the search term & results back to their starting state
                            s.activeData.crm.party.addPerson.existingUser = new TCRMParty.C().addPerson.existingUser;
                        } else {
                            s.activeData.crm.party.addPerson.existingUser.searchResults[searchResultsIndex].response = response;
                            s.activeData.crm.party.addPerson.existingUser.searchResults[searchResultsIndex].status = TFormStatus.constants.FAILURE;
                        }
                        return s;
                    }),
                ),
                rxjsOperators.mergeMap(() =>
                    makeGetPartyRequest(getState().activeData.crm.party.partyResponse.response.data.id, setState, getState),
                ),
            )
            .subscribe();
        },
    },
    {
        type: "CRM_PARTY_SITUATION_INPUT_CHANGED" as const,
        run: setFormSubscribed<"CRM_PARTY_SITUATION_INPUT_CHANGED", TParty2AndC4Form>()(
            ["activeData", "crm", "party", "situationForm"]
        ),
    },
    {
        type: "CRM_PARTY_SUBMIT_SITUATION" as const,
        run: (
            obs$: TActionObservable<"CRM_PARTY_SUBMIT_SITUATION", undefined>,
            getState: TGetState,
            setState: TSetState,
        ): void => {
            obs$.pipe(
                rxjsOperators.map(() => getState().activeData.crm.party.situationForm.edit),
                rxjsOperators.map<Party4.T, [Party4.T, option.Option<TValidationError>]>(
                    (requestBody) => [requestBody, Party4.validator(requestBody)]
                ),
                rxjsOperators.tap(() => setState(({...updateState}) => {
                    updateState.activeData.crm.party.partyResponse = FirstPartyFetchResponse.createPending(
                        updateState.activeData.crm.party.partyResponse.response
                    );
                    updateState.activeData.crm.party.situationForm.status = "submitting";
                    return updateState;
                })),
                rxjsOperators.mergeMap(([requestBody, validationOption]) =>
                    makePartyPatchRequest(requestBody, validationOption, getState, setState),
                ),
                rxjsOperators.tap(util.defaultCRMRequestErrorHandler),
                rxjsOperators.tap((request) => setState(({...updateState}) => {
                    updateState.activeData.crm.party.situationForm.status = (
                        request.tag === FirstPartyFetchResponse.constants.STATUS_2XX ?
                            "success" :
                            "failure"
                    );
                    return updateState;
                })),
            ).subscribe();
        },
    },
    {
       type: "CRM_PARTY_PARTY_NOTES_CHANGED" as const,
       run: setFormSubscribed<"CRM_PARTY_PARTY_NOTES_CHANGED", TParty2AndC5Form>()(
          ["activeData", "crm", "party", "notesForm"]
       ),
    },
    {
        type: "CRM_PARTY_NOTES_SUBMIT" as const,
        run: (
            obs$: TActionObservable<"CRM_PARTY_NOTES_SUBMIT", undefined>,
            getState: TGetState,
            setState: TSetState,
        ): void => {
            obs$.pipe(
                rxjsOperators.map(() => getState().activeData.crm.party.notesForm.edit),
                rxjsOperators.map<Party5.T, [Party5.T, option.Option<TValidationError>]>(
                    (requestBody) => [requestBody, Party5.validator(requestBody)]
                ),
                rxjsOperators.tap(() => setState(({...updateState}) => {
                    updateState.activeData.crm.party.partyResponse = FirstPartyFetchResponse.createPending(
                        updateState.activeData.crm.party.partyResponse.response
                    );
                    updateState.activeData.crm.party.notesForm.status = "submitting";
                    return updateState;
                })),
                rxjsOperators.mergeMap(([requestBody, validationOption]) =>
                    makePartyPatchRequest(requestBody, validationOption, getState, setState),
                ),
                rxjsOperators.tap(util.defaultCRMRequestErrorHandler),
                rxjsOperators.tap((request) => setState(({...updateState}) => {
                    updateState.activeData.crm.party.notesForm.status = (
                        request.tag === FirstPartyFetchResponse.constants.STATUS_2XX ?
                            "success" :
                            "failure"
                    );
                    return updateState;
                })),
            ).subscribe();
        },
    },
    {
        type: "CRM_PARTY_SEARCH_CRITERIA_INPUT_CHANGED" as const,
        run: (
            obs$: TActionObservable<"CRM_PARTY_SEARCH_CRITERIA_INPUT_CHANGED", TFormUpdateActionPayload<Party6.T, keyof Party6.T>>,
            getState: TGetState,
            setState: TSetState,
        ): void => {
            obs$.subscribe((action) => {
                setState(({...updateState}) => {
                    updateState.activeData.crm.party.partySearchCriteriaForm = TForm.updateFromAction(
                        updateState.activeData.crm.party.partySearchCriteriaForm,
                        action.payload,
                    );
                    return updateState;
                });
            });
        },
    },
    {
        type: "CRM_PARTY_SEARCH_CRITERIA_SUBMIT" as const,
        run: (
            obs$: TActionObservable<"CRM_PARTY_SEARCH_CRITERIA_SUBMIT", undefined>,
            getState: TGetState,
            setState: TSetState,
        ): void => {
            obs$.pipe(
                rxjsOperators.map(() => getState().activeData.crm.party.partySearchCriteriaForm.form),
                rxjsOperators.map<Party6.T, [Party6.T, option.Option<TValidationError>]>(
                    (requestBody) => [requestBody, Party6.validator(requestBody)]
                ),
                rxjsOperators.tap(() => setState(({...updateState}) => {
                    updateState.activeData.crm.party.partyResponse = FirstPartyFetchResponse.createPending(
                        updateState.activeData.crm.party.partyResponse.response
                    );
                    updateState.activeData.crm.party.partySearchCriteriaForm.status = "submitting";
                    return updateState;
                })),
                rxjsOperators.mergeMap(([requestBody, validationOption]) =>
                    makePartyPatchRequest(requestBody, validationOption, getState, setState),
                ),
                rxjsOperators.tap(util.defaultCRMRequestErrorHandler),
                rxjsOperators.tap((request) => setState(({...updateState}) => {
                    updateState.activeData.crm.party.partySearchCriteriaForm.status = (
                        request.tag === FirstPartyFetchResponse.constants.STATUS_2XX
                            ? "success"
                            : "failure"
                    );
                    return updateState;
                })),
            ).subscribe();
        },
    },
    {
        type: "CRM_PARTY_ENQUIRY_INPUT_CHANGED" as const,
        run: setFormWhereSubscribed<"CRM_PARTY_ENQUIRY_INPUT_CHANGED", TEnquiryC2AndC3FormList["forms"][number]>()(
            ["activeData", "crm", "party", "enquiryForms", "forms"],
            (value, payload) => value.view.id === payload.resourceId
        ),
    },
    {
        type: "CRM_PARTY_ENQUIRY_SUBMIT" as const,
        run: (
            obs$: rxjs.Observable<TActionPayload<"CRM_PARTY_ENQUIRY_SUBMIT", string>>,
            getState: TGetState,
            setState: TSetState,
        ) =>
            submitFormWhere<"CRM_PARTY_ENQUIRY_SUBMIT", string, Enquiry2.T, Enquiry3.T, {}>()(
                ["activeData", "crm", "party", "enquiryForms", "forms"],
                (form, payload) => form.view.id === payload,
                (form) => `/v1/enquiries/${form.view.id}`,
                "PATCH",
                Enquiry3.validator,
                Enquiry2,
            )(obs$, getState, setState).subscribe(),
    },
    {
        type: "CRM_PARTY_VIEWING_INPUT_CHANGED" as const,
        run: setFormWhereSubscribed<"CRM_PARTY_VIEWING_INPUT_CHANGED", TListingEnquiriesViewing2AndC3FormList["forms"][number]>()(
            ["activeData", "crm", "party", "viewingForms", "forms"],
            (value, payload) => value.view.id === payload.resourceId
        ),
    },
    {
        type: "CRM_PARTY_VIEWING_SUBMIT" as const,
        run: (
            obs$: rxjs.Observable<TActionPayload<"CRM_PARTY_VIEWING_SUBMIT", string>>,
            getState: TGetState,
            setState: TSetState,
        ) =>
            submitFormWhere<"CRM_PARTY_VIEWING_SUBMIT", string, ListingEnquiriesViewing3.T, ListingEnquiriesViewing3.T, {}>()(
                ["activeData", "crm", "party", "viewingForms", "forms"],
                (form, payload) => form.view.id === payload,
                (form) => `/v1/viewings/${form.view.id}`,
                "PATCH",
                ListingEnquiriesViewing3.validator,
                ListingEnquiriesViewing3,
                () => makeGetEnquiriesListingsViewingsRequests(
                    getState().activeData.crm.party.partyResponse.response.data.id,
                    setState,
                    getState
                ),
            )(obs$, getState, setState).subscribe(),
    },
    {
        type: "CRM_PARTY_ADD_VIEWING_TO_ENQUIRY" as const,
        run: (
            obs$: TActionObservable<"CRM_PARTY_ADD_VIEWING_TO_ENQUIRY", {enquiryId: string; partyId: string}>,
            getState: TGetState,
            setState: TSetState,
        ): void => {
            obs$.pipe(
                rxjsOperators.mergeMap((a) =>
                    rxjs.of(setState(({...s}) => {
                        s.activeData.crm.party.partyResponse = FirstPartyFetchResponse.createPending(getState().activeData.crm.party.partyResponse.response);
                        return s;
                    })).pipe(
                        rxjsOperators.mergeMap(() =>
                            fetchWrapper.json<ListingEnquiriesViewingSuccessResponse2.T>({
                                requestParams: {
                                    url: `${env.REACT_APP_API_URL}/v1/enquiries/${a.payload.enquiryId}/viewings`,
                                    method: "POST",
                                    body: option.none,
                                },
                                expectedTypeCodec: ListingEnquiriesViewingSuccessResponse2.codec,
                                defaultResponse: ListingEnquiriesViewingSuccessResponse2.newDefault(),
                            })(),
                        ),
                        rxjsOperators.tap((v) => v),
                        rxjsOperators.tap(util.defaultCRMRequestErrorHandler),
                        rxjsOperators.mergeMap(() => makeGetEnquiriesListingsViewingsRequests(
                            a.payload.partyId,
                            setState,
                            getState
                        )),
                    )
                )
            ).subscribe();
        },
    },
    {
        type: "CRM_PARTY_ENQUIRY_LISTING_SEARCH_INPUT_CHANGED" as const,
        run: (
            obs$: TActionObservable<"CRM_PARTY_ENQUIRY_LISTING_SEARCH_INPUT_CHANGED", string>,
            getState: TGetState,
            setState: TSetState,
        ): void => {
            obs$.pipe(
                rxjsOperators.tap((action) =>
                    setState(({...s}) => {
                        s.activeData.crm.party.newEnquiry.listingSearchTerm = action.payload;
                        return s;
                    })
                ),
                rxjsOperators.debounceTime(100),
                rxjsOperators.tap(() =>
                    setState(({...s}) => {
                        s.activeData.crm.party.newEnquiry.listingResponse = FirstPartyFetchResponse.createPending(s.activeData.crm.party.newEnquiry.listingResponse.response);
                        return s;
                    })
                ),
                rxjsOperators.mergeMap((action) =>
                    fetchWrapper.json<ListingSuccessResponse2.T>({
                        requestParams: {
                            url: `${env.REACT_APP_API_URL}/v1/listings?filters=${encodeURIComponent(JSON.stringify({
                                type: "GROUP",
                                conditional: "AND",
                                // If enquiries already exist for this party the search results should include a filter to remove the enquiries listing from the results
                                filters:  pipe(
                                    [ListingFilter1.createForAddress(action.payload)],
                                    (a) => getState().activeData.crm.party.enquiryForms.forms.length > 0
                                        ? [
                                            ...a,
                                            ListingFilter1.createWhereIdNotAnyOf(
                                                nonEmptyArray.map<TCRMParty.C["enquiryForms"]["forms"][0], string>((enquiry) => enquiry.view.listing_id)(getState().activeData.crm.party.enquiryForms.forms as nonEmptyArray.NonEmptyArray<TCRMParty.C["enquiryForms"]["forms"][0]>)
                                            ),
                                        ]
                                        : a,
                                ),
                            }))}`,
                            method: "GET",
                            body: option.none,
                        },
                        expectedTypeCodec: ListingSuccessResponse2.codec,
                        defaultResponse: getState().activeData.crm.party.newEnquiry.listingResponse.response,
                    })(),
                ),
                rxjsOperators.tap(util.defaultCRMRequestErrorHandler),
                rxjsOperators.tap((response) =>
                    setState(({...s}) => {
                        s.activeData.crm.party.newEnquiry.listingResponse = response;
                        return s;
                    })
                ),
            ).subscribe();
        },
    },
    {
        type: "CRM_PARTY_ADD_LISTING_GET_ADDRESS_INPUT_CHANGED" as const,
        run: (
            obs$: TActionObservable<"CRM_PARTY_ADD_LISTING_GET_ADDRESS_INPUT_CHANGED", TFormUpdateActionPayload<TCRMParty.C["addListingGetAddressForm"]["form"], keyof TCRMParty.C["addListingGetAddressForm"]["form"]>>,
            getState: TGetState,
            setState: TSetState,
        ): void => {
            obs$.subscribe(({payload}) => {
                setState(({...updateState}) => {
                    updateState.activeData.crm.party.addListingGetAddressForm = TForm.updateFromAction<
                        typeof updateState.activeData.crm.party.addListingGetAddressForm,
                        typeof payload["key"],
                        typeof payload
                    >(
                        updateState.activeData.crm.party.addListingGetAddressForm,
                        payload,
                    );
                    updateState.activeData.crm.party.addListingForm.status = TFormStatus.constants.REQUIRES_SUBMISSION;
                    return updateState;
                });
            });
        },
    },
    {
        type: "CRM_PARTY_ADD_LISTING_INPUT_CHANGED" as const,
        run: (
            obs$: TActionObservable<"CRM_PARTY_ADD_LISTING_INPUT_CHANGED", TFormUpdateActionPayload<TCRMParty.C["addListingForm"]["form"], keyof TCRMParty.C["addListingForm"]["form"]>>,
            getState: TGetState,
            setState: TSetState,
        ): void => {
            obs$.subscribe(({payload}) => {
                setState(({...updateState}) => {
                    updateState.activeData.crm.party.addListingForm = TForm.updateFromAction<
                        typeof updateState.activeData.crm.party.addListingForm,
                        typeof payload["key"],
                        typeof payload
                    >(
                        updateState.activeData.crm.party.addListingForm,
                        payload,
                    );
                    return updateState;
                });
            });
        },
    },
    {
        type: "CRM_PARTY_ADD_LISTING_SUBMIT_ADDRESS_LOOKUP" as const,
        run: (
            obs$: TActionObservable<"CRM_PARTY_ADD_LISTING_SUBMIT_ADDRESS_LOOKUP", undefined>,
            getState: TGetState,
            setState: TSetState,
        ): void => {
            obs$
            .pipe(
                rxjsOperators.map(() => getState().activeData.crm.party.addListingGetAddressForm.form),
                rxjsOperators.map<Listing8.T, [Listing8.T, option.Option<TValidationError>]>(
                    (requestBody) => [requestBody, Listing8.validator(requestBody)]
                ),
                rxjsOperators.tap(() => setState(({...updateState}) => {
                    updateState.activeData.crm.party.addListingGetAddressForm.status = "submitting";
                    return updateState;
                })),
                rxjsOperators.mergeMap(([data, validationOption]) =>
                    option.fold<TValidationError, rxjs.Observable<GetAddressIOResponse.T>>(
                        () => {
                            const buildingNumberOrName = data.building_name !== "" ? data.building_name : data.building_number;
                            return rxjs.from(
                                fetchWrapper.thirdPartyJSON<GetAddressIOResponse.T>({
                                    requestParams: {
                                        url: `https://api.getAddress.io/find/${data.postcode}/${buildingNumberOrName}?api-key=${env.REACT_APP_GET_ADDRESS_API_KEY}&expand=true`,
                                        method: "GET",
                                        body: option.none,
                                    },
                                    expectedTypeCodec: GetAddressIOResponse.codec,
                                    defaultResponse: getState().activeData.crm.party.addListingGetAddressForm.response,
                                })()
                            );
                        },
                        (errors) => {
                            setState(({...updateState}) => {
                                updateState.activeData.crm.party.addListingGetAddressForm.status = "failure";
                                updateState.activeData.crm.party.addListingGetAddressForm.meta.validationInnerErrors = JsonInnerError1.arrayFromValidationErrors("Body", errors);
                                return updateState;
                            });
                            return rxjs.empty();
                        },
                    )(validationOption),
                ),
                rxjsOperators.tap((response) => setState(({...updateState}) => {
                    updateState.activeData.crm.party.addListingGetAddressForm.status = "success";
                    updateState.activeData.crm.party.addListingGetAddressForm.response = response;
                    updateState.activeData.crm.party.addListingGetAddressForm.meta.validationInnerErrors = [];
                    return updateState;
                })),
                rxjsOperators.tap((response) => setState(({...updateState}) => {
                    updateState.activeData.crm.party.addListingForm.form = Listing7.fromOwnTypeAndGetAddressIOResponse(
                        updateState.activeData.crm.party.addListingForm.form,
                        response
                    );
                    return updateState;
                })),
            )
            .subscribe();
        },
    },
    {
        type: "CRM_PARTY_ADD_LISTING_SUBMIT" as const,
        run: (
            obs$: TActionObservable<"CRM_PARTY_ADD_LISTING_SUBMIT", undefined>,
            getState: TGetState,
            setState: TSetState,
        ): void => {
            obs$
            .pipe(
                rxjsOperators.map(() => ({
                    sellers_party_id: getState().activeData.crm.party.partyResponse.response.data.id,
                    ...getState().activeData.crm.party.addListingForm.form,
                    ...getState().activeData.crm.party.addListingGetAddressForm.form,
                })),
                rxjsOperators.map((requestBody: Listing2.T) => {
                    if (requestBody.building_name === "") {
                        requestBody.building_name = undefined;
                    }
                    if (requestBody.building_number === "") {
                        requestBody.building_number = undefined;
                    }
                    return requestBody;
                }),
                rxjsOperators.map<Listing2.T, [Listing2.T, option.Option<TValidationError>]>(
                    (requestBody) => [requestBody, Listing2.validator(requestBody)]
                ),
                rxjsOperators.tap(() => setState(({...updateState}) => {
                    updateState.activeData.crm.party.addListingForm.status = "submitting";
                    return updateState;
                })),
                rxjsOperators.mergeMap(([data, validationOption]) =>
                    option.fold<
                        TValidationError,
                        rxjs.Observable<FirstPartyFetchResponse.T<ListingSuccessResponse1.T>>
                    >(
                        () => rxjs.from(
                            fetchWrapper.json<ListingSuccessResponse1.T>({
                                requestParams: {
                                    url: `${env.REACT_APP_API_URL}/v1/listings`,
                                    method: "POST",
                                    body: option.some(JSON.stringify(data)),
                                },
                                expectedTypeCodec: ListingSuccessResponse1.codec,
                                defaultResponse: getState().activeData.crm.party.addListingForm.response.response,
                            })()
                        ),
                        (errors) => {
                            setState(({...updateState}) => {
                                const validationErrors = JsonInnerError1.arrayFromValidationErrors("Body", errors);
                                updateState.activeData.crm.party.addListingForm.status = "failure";
                                updateState.activeData.crm.party.addListingGetAddressForm.meta.validationInnerErrors = validationErrors;
                                updateState.activeData.crm.party.addListingForm.response = FirstPartyFetchResponse.create422(
                                    updateState.activeData.crm.party.addListingForm.response.response,
                                    validationErrors,
                                );
                                return updateState;
                            });
                            return rxjs.empty();
                        },
                    )(validationOption),
                ),
                rxjsOperators.tap((response) => setState(({...updateState}) => {
                    updateState.activeData.crm.party.addListingGetAddressForm.status = "untouched";
                    updateState.activeData.crm.party.addListingGetAddressForm.form = Listing8.newDefault();
                    updateState.activeData.crm.party.addListingGetAddressForm.meta.validationInnerErrors = [];
                    updateState.activeData.crm.party.addListingForm.status = "success";
                    updateState.activeData.crm.party.addListingForm.form = Listing7.newDefault();
                    updateState.activeData.crm.party.addListingForm.response = response;
                    return updateState;
                })),
                rxjsOperators.mergeMap(() =>
                    makeGetPartyListingsRequest(
                        getState().activeData.crm.party.partyResponse.response.data.id,
                        setState,
                        getState,
                    )
                ),
            )
            .subscribe();
        },
    },
    {
        type: "CRM_PARTY_USER_INPUT_CHANGED" as const,
        run: (
            obs$: TActionObservable<"CRM_PARTY_USER_INPUT_CHANGED", TUserActionPayload<TCRMParty.C["users"][number]["userForm"]["form"], keyof TCRMParty.C["users"][number]["userForm"]["form"]>>,
            getState: TGetState,
            setState: TSetState,
        ): void => {
            obs$.subscribe(({payload}) => {
                setState(({...s}) => {
                    s.activeData.crm.party = TCRMParty.updateUserForm(s.activeData.crm.party, payload.userId, (user) => {
                        user = TForm.updateFromAction<
                            typeof user,
                            typeof payload["key"],
                            typeof payload
                        >(
                            user,
                            payload,
                        );
                        return user;
                    });
                    return s;
                });
            });
        },
    },
    {
        type: "CRM_PARTY_USER_NEW_PHONE_NUMBER_INPUT_CHANGED" as const,
        run: (
            obs$: TActionObservable<"CRM_PARTY_USER_NEW_PHONE_NUMBER_INPUT_CHANGED", TUserActionPayload<TCRMParty.C["users"][number]["newPhoneNumber"]["form"]["form"], keyof TCRMParty.C["users"][number]["newPhoneNumber"]["form"]["form"]>>,
            getState: TGetState,
            setState: TSetState,
        ): void => {
            obs$.subscribe(({payload}) => {
                setState(({...s}) => {
                    s.activeData.crm.party = TCRMParty.updateUserNewPhoneNumberForm(s.activeData.crm.party, payload.userId, (user) => {
                        user.form = TForm.updateFromAction<
                            typeof user["form"],
                            typeof payload["key"],
                            typeof payload
                        >(
                            user.form,
                            payload,
                        );
                        return user;
                    });
                    return s;
                });
            });
        },
    },
    {
        type: "CRM_PARTY_USER_ADD_NEW_PHONE_NUMBER_CLICKED" as const,
        run: (
            obs$: TActionObservable<"CRM_PARTY_USER_ADD_NEW_PHONE_NUMBER_CLICKED", {userId: string}>,
            getState: TGetState,
            setState: TSetState,
        ): void => {
            obs$.subscribe(({payload}) => {
                setState(({...s}) => {
                    s.activeData.crm.party = TCRMParty.updateUserNewPhoneNumberForm(s.activeData.crm.party, payload.userId, (user) => {
                        user.isVisible = true;
                        return user;
                    });
                    return s;
                });
            });
        },
    },
    {
        type: "CRM_PARTY_USER_SUBMIT" as const,
        run: (
            obs$: TActionObservable<"CRM_PARTY_USER_SUBMIT", {userId: string}>,
            getState: TGetState,
            setState: TSetState,
        ): void => {
            obs$.pipe(
                rxjsOperators.mergeMap(({payload}) =>  pipe(
                    getState().activeData.crm.party.users,
                    array.findFirst((user) => user.userForm.meta.id === payload.userId),
                    option.fold<TCRMParty.C["users"][number], rxjs.Observable<TCRMParty.C["users"][number]>>(
                        () => rxjs.EMPTY,
                        rxjs.of,
                    ),
                )),
                rxjsOperators.map<
                    TCRMParty.C["users"][number],
                    [TCRMParty.C["users"][number], Array<TCRMParty.TUpdateUsersIndexedValidationResults>]
                >((user) => [user, getValidationResultsForUserForms(user)]),
                rxjsOperators.mergeMap(([user, validationResults]) =>
                     pipe(
                        validationResults,
                        // Get only any failed validators
                        array.filterMap((validationResult) =>
                            option.isSome(validationResult.validationOption)
                                ? option.some({
                                    ...validationResult,
                                    errors: validationResult.validationOption.value,
                                })
                                : option.none,
                        ),
                        array.map((validationResult) => {
                            setState(({...s}) => {
                                s.activeData.crm.party = TCRMParty.updateUserFromValidationResultsWithErrors(s.activeData.crm.party, user.userForm.meta.id, validationResult);
                                return s;
                            });
                            return validationResult;
                        }),
                        (errors) => errors.length === 0
                            ? rxjs.of(user)
                            : rxjs.EMPTY,
                    ),
                ),
                rxjsOperators.tap((user) =>
                    setState(({...s}) => {
                        // Set all forms to submitting
                        s.activeData.crm.party = TCRMParty.updateUser(s.activeData.crm.party, user.userForm.meta.id, (u) => {
                            u.userForm.status = TFormStatus.constants.SUBMITTING;
                            u.newPhoneNumber.form.status = TFormStatus.constants.SUBMITTING;
                            u.existingPhoneNumbers =  pipe(
                                u.existingPhoneNumbers,
                                array.map(({...userPhoneNumber}) => ({
                                    ...userPhoneNumber,
                                    status: TFormStatus.constants.SUBMITTING,
                                })),
                            );
                            return u;
                        });
                        return s;
                    })
                ),
                rxjsOperators.mergeMap((user) => makeUpdateUserRequests(user, setState)),
                rxjsOperators.mergeMap((responses) =>
                     pipe(
                        responses,
                        array.filter((response) => response.tag === FirstPartyFetchResponse.constants.STATUS_2XX),
                        (successfulResponses) => successfulResponses.length === responses.length
                            ? makeGetPartyRequest(getState().activeData.crm.party.partyResponse.response.data.id, setState, getState)
                            : rxjs.EMPTY,
                    )
                ),
            ).subscribe();
        },
    },
    {
        type: "CRM_PARTY_USER_EXISTING_PHONE_NUMBER_INPUT_CHANGED" as const,
        run: (
            obs$: TActionObservable<"CRM_PARTY_USER_EXISTING_PHONE_NUMBER_INPUT_CHANGED", TUserExistingPhoneNumberActionPayload<TCRMParty.C["users"][number]["existingPhoneNumbers"][number]["form"], keyof TCRMParty.C["users"][number]["existingPhoneNumbers"][number]["form"]>>,
            getState: TGetState,
            setState: TSetState,
        ): void => {
            obs$.subscribe((action) => {
                setState(({...s}) => {
                    s.activeData.crm.party = TCRMParty.updateUserPhoneNumberFormWhereId(s.activeData.crm.party, action.payload.userId, action.payload.phoneNumberId, (form) =>
                        TForm.updateFromAction<
                            typeof form,
                            typeof action.payload["key"],
                            typeof action.payload
                        >(
                            form,
                            action.payload,
                        )
                    );

                    // If the primary number boolean has changed set all others to false
                    if (action.payload.key === "primary_number") {
                        s.activeData.crm.party = TCRMParty.updateUserPhoneNumberFormWhereIdNot(s.activeData.crm.party, action.payload.userId, action.payload.phoneNumberId, (form) => {
                            form.form.primary_number = false;
                            return form;
                        });
                    }
                    return s;
                });
            });
        },
    },
    {
        type: "CRM_PARTY_USER_EXISTING_PHONE_NUMBER_DELETE_BUTTON_CLICKED" as const,
        run: (
            obs$: TActionObservable<"CRM_PARTY_USER_EXISTING_PHONE_NUMBER_DELETE_BUTTON_CLICKED", {userId: string; phoneNumberId: string}>,
            getState: TGetState,
            setState: TSetState,
        ): void => {
            obs$.pipe(
                rxjsOperators.mergeMap(({payload}) =>
                     pipe(
                        getState().activeData.crm.party.users,
                        array.findFirst((user) => user.userForm.meta.id === payload.userId),
                        option.fold<TCRMParty.C["users"][number], rxjs.Observable<TCRMParty.C["users"][number]>>(
                            () => rxjs.EMPTY,
                            rxjs.of,
                        ),
                        rxjsOperators.map<
                            TCRMParty.C["users"][number],
                            [TCRMParty.C["users"][number], TCRMParty.C["users"][number]["existingPhoneNumbers"][number]["meta"]["id"], Array<TCRMParty.TUpdateUsersIndexedValidationResults>]
                        >((user) => [user, payload.phoneNumberId, getValidationResultsForUserForms(user)]),
                    ),
                ),
                rxjsOperators.mergeMap(([user, phoneNumberId, validationResults]) =>
                     pipe(
                        validationResults,
                        // Get only any failed validators
                        array.filterMap((validationResult) =>
                            option.isSome(validationResult.validationOption)
                                ? option.some({
                                    ...validationResult,
                                    errors: validationResult.validationOption.value,
                                })
                                : option.none,
                        ),
                        array.map((validationResult) => {
                            setState(({...s}) => {
                                s.activeData.crm.party = TCRMParty.updateUserFromValidationResultsWithErrors(s.activeData.crm.party, user.userForm.meta.id, validationResult);
                                return s;
                            });
                            return validationResult;
                        }),
                        (errors) => errors.length === 0
                            ? rxjs.of(user)
                            : rxjs.EMPTY,
                    )
                    .pipe(
                        rxjsOperators.map<TCRMParty.C["users"][number],
                        [TCRMParty.C["users"][number], TCRMParty.C["users"][number]["existingPhoneNumbers"][number]["meta"]["id"]]>((updatedUser) => [updatedUser, phoneNumberId]),
                        rxjsOperators.tap(([updatedUser]) =>
                            setState(({...s}) => {
                                // Set all forms to submitting
                                s.activeData.crm.party = TCRMParty.updateUser(s.activeData.crm.party, updatedUser.userForm.meta.id, (u) => {
                                    u.userForm.status = TFormStatus.constants.SUBMITTING;
                                    u.newPhoneNumber.form.status = TFormStatus.constants.SUBMITTING;
                                    u.existingPhoneNumbers =  pipe(
                                        u.existingPhoneNumbers,
                                        array.map(({...userPhoneNumber}) => ({
                                            ...userPhoneNumber,
                                            status: TFormStatus.constants.SUBMITTING,
                                        })),
                                    );
                                    return u;
                                });
                                return s;
                            })
                        ),
                        rxjsOperators.mergeMap(([updatedUser]) => makeUpdateUserRequests(updatedUser, setState)),
                        rxjsOperators.map<
                            (Array<FirstPartyFetchResponse.T<UserSuccessResponse2.T> | FirstPartyFetchResponse.T<UserPhoneNumberSuccessResponse1.T>>),
                            [(Array<FirstPartyFetchResponse.T<UserSuccessResponse2.T> | FirstPartyFetchResponse.T<UserPhoneNumberSuccessResponse1.T>>), TCRMParty.C["users"][number]["existingPhoneNumbers"][number]["meta"]["id"]]
                        >((responses) => ([responses, phoneNumberId])),
                        rxjsOperators.mergeMap(([responses, phoneNumber]) =>
                             pipe(
                                responses,
                                array.filter((response) => response.tag === FirstPartyFetchResponse.constants.STATUS_2XX),
                                (successfulResponses) => successfulResponses.length === responses.length
                                    ?  pipe(
                                        makeDeleteUserPhoneNumberRequest(user.userForm.meta.id, phoneNumber, setState),
                                        rxjsOperators.mergeMap((response) =>
                                            response.tag === FirstPartyFetchResponse.constants.STATUS_2XX
                                                ? makeGetPartyRequest(getState().activeData.crm.party.partyResponse.response.data.id, setState, getState)
                                                : rxjs.of(undefined),
                                        ),
                                    )
                                    : rxjs.EMPTY,
                            )
                        ),
                    ),
                ),
            ).subscribe();
        },
    },
];

const makeDeleteUserPhoneNumberRequest = (userId: string, phoneNumberId: string, setState: TSetState): rxjs.Observable<FirstPartyFetchResponse.T<DeleteEntitySuccessResponse.T>> =>
    rxjs.from(
        fetchWrapper.json<DeleteEntitySuccessResponse.T>({
            requestParams: {
                url: `${env.REACT_APP_API_URL}/v1/phone-numbers/${phoneNumberId}`,
                method: "DELETE",
                body: option.none,
            },
            expectedTypeCodec: DeleteEntitySuccessResponse.codec,
            defaultResponse: DeleteEntitySuccessResponse.newDefault(),
        })(),
    )
        .pipe(
            rxjsOperators.tap(util.defaultCRMRequestErrorHandler),
            rxjsOperators.tap((response) =>
                setState(({...s}) => {
                    s.activeData.crm.party = TCRMParty.updateUserPhoneNumberFormWhereId(s.activeData.crm.party, userId, phoneNumberId, (u) => {
                        u.status =
                            response.tag === FirstPartyFetchResponse.constants.STATUS_2XX
                                ? TFormStatus.constants.SUCCESS
                                : TFormStatus.constants.FAILURE;
                        return u;
                    });
                    return s;
                }),
            ),
        );

const makeGetPartyRequest = (partyId: string, setState: TSetState, getState: TGetState): rxjs.Observable<void> =>
    rxjs.of(
        setState(({...s}) => {
            s.activeData.crm.party.partyResponse = FirstPartyFetchResponse.createPending(getState().activeData.crm.party.partyResponse.response);
            return s;
        }),
    )
        .pipe(
            rxjsOperators.mergeMap(() =>
                fetchWrapper.json<PartyResponse1.T>({
                    requestParams: {
                        url: `${env.REACT_APP_API_URL}/v1/parties/${partyId}`,
                        method: "GET",
                        body: option.none,
                    },
                    expectedTypeCodec: PartyResponse1.codec,
                    defaultResponse: getState().activeData.crm.party.partyResponse.response,
                })(),
            ),
            rxjsOperators.tap(util.defaultCRMRequestErrorHandler),
            rxjsOperators.tap(reduceResponseToForm<PartyResponse1.T>(setState)(
                ["activeData", "crm", "party", "situationForm"]
            )),
            rxjsOperators.tap(reduceResponseToForm<PartyResponse1.T>(setState)(
                ["activeData", "crm", "party", "notesForm"]
            )),
            rxjsOperators.tap((partyResponse) =>
                setState(({...s}) => {
                    s.activeData.crm.party = TCRMParty.reduceFromPartyResponse(s.activeData.crm.party, partyResponse);
                    return s;
                }),
            ),
            rxjsOperators.mapTo(undefined),
        );

const makeGetPartyListingsRequest = (partyId: string, setState: TSetState, getState: TGetState): rxjs.Observable<void> =>
    rxjs.of(
        setState(({...s}) => {
            s.activeData.crm.party.listings = FirstPartyFetchResponse.createPending(getState().activeData.crm.party.listings.response);
            return s;
        }),
    )
        .pipe(
            rxjsOperators.mergeMap(() =>
                fetchWrapper.json<ListingSuccessResponse2.T>({
                    requestParams: {
                        url: `${env.REACT_APP_API_URL}/v1/listings?filters=${encodeURIComponent(JSON.stringify(ListingFilter1.createForParty(partyId)))}`,
                        method: "GET",
                        body: option.none,
                    },
                    expectedTypeCodec: ListingSuccessResponse2.codec,
                    defaultResponse: getState().activeData.crm.party.listings.response,
                })(),
            ),
            rxjsOperators.tap(util.defaultCRMRequestErrorHandler),
            rxjsOperators.tap((listingsResponse) => setState(({...updateState}) => {
                updateState.activeData.crm.party.listings = listingsResponse;
                return updateState;
            })),
            rxjsOperators.mapTo(undefined),
        );

const makePartyPatchRequest = (
    requestBody: Party3.T,
    validationOption: option.Option<TValidationError>,
    getState: TGetState,
    setState: TSetState
): rxjs.Observable<FirstPartyFetchResponse.T<PartyResponse1.T>> => option.fold<
        TValidationError,
        rxjs.Observable<FirstPartyFetchResponse.T<PartyResponse1.T>>
    >(
        () => rxjs.from(
            fetchWrapper.json<PartyResponse1.T>({
                requestParams: {
                    url: `${env.REACT_APP_API_URL}/v1/parties/${getState().activeData.crm.party.partyResponse.response.data.id}`,
                    method: "PATCH",
                    body: option.some(JSON.stringify(requestBody)),
                },
                expectedTypeCodec: PartyResponse1.codec,
                defaultResponse: getState().activeData.crm.party.partyResponse.response,
            })()
        ),
        (errors) => {
            setState(({...updateState}) => {
                updateState.activeData.crm.party.partyResponse = FirstPartyFetchResponse.create422(
                    updateState.activeData.crm.party.partyResponse.response,
                    JsonInnerError1.arrayFromValidationErrors("Body", errors),
                );
                return updateState;
            });
            return rxjs.empty();
        },
    )(validationOption);

type TEnquiryRequestPayloads = [
    string,
    FirstPartyFetchResponse.T<EnquirySuccessResponse2.T>,
    FirstPartyFetchResponse.T<ListingSuccessResponse2.T>,
    FirstPartyFetchResponse.T<ListingEnquiriesViewingSuccessResponse3.T>,
    FirstPartyFetchResponse.T<ListingEnquiriesViewingSuccessResponse3.T>,
];

const makeGetEnquiriesRequest = (partyId: string): rxjs.Observable<FirstPartyFetchResponse.T<EnquirySuccessResponse2.T>> =>
    rxjs.from(
        fetchWrapper.json<EnquirySuccessResponse2.T>({
            requestParams: {
                url: `${env.REACT_APP_API_URL}/v1/enquiries?filters=${encodeURIComponent(JSON.stringify(EnquiryFilter1.createForPartyId(partyId)))}`,
                method: "GET",
                body: option.none,
            },
            expectedTypeCodec: EnquirySuccessResponse2.codec,
            defaultResponse: EnquirySuccessResponse2.newDefault(),
        })(),
    )
    .pipe(rxjsOperators.tap(util.defaultCRMRequestErrorHandler));

const makeGetListingsByIdsRequest = (payloads: TEnquiryRequestPayloads): rxjs.Observable<FirstPartyFetchResponse.T<ListingSuccessResponse2.T>> => {

    const enquiryRequest = payloads[1];
    if (enquiryRequest?.response.data.length > 0) {
        const listingIds = nonEmptyArray.map<Enquiry2.T, string>((enquiry) => enquiry.listing_id)(enquiryRequest.response.data as nonEmptyArray.NonEmptyArray<Enquiry2.T>);
        return rxjs
            .from(fetchWrapper.json<ListingSuccessResponse2.T>({
                requestParams: {
                    url: `${env.REACT_APP_API_URL}/v1/listings?filters=${encodeURIComponent(JSON.stringify(ListingFilter1.createWhereIdAnyOf(listingIds)))}`,
                    method: "GET",
                    body: option.none,
                },
                expectedTypeCodec: ListingSuccessResponse2.codec,
                defaultResponse: ListingSuccessResponse2.newDefault(),
            })())
            .pipe(rxjsOperators.tap(util.defaultCRMRequestErrorHandler));
    }
    return rxjs.of(FirstPartyFetchResponse.create2XX(ListingSuccessResponse2.newDefault()));
};

const makeGetViewingsForPartyRequest = (partyId: string): rxjs.Observable<FirstPartyFetchResponse.T<ListingEnquiriesViewingSuccessResponse3.T>> =>
    rxjs
        .from(
            fetchWrapper.json<ListingEnquiriesViewingSuccessResponse3.T>({
                requestParams: {
                    url: `${env.REACT_APP_API_URL}/v1/viewings?filters=${encodeURIComponent(JSON.stringify(ViewingFilter1.createForPartyId(partyId)))}`,
                    method: "GET",
                    body: option.none,
                },
                expectedTypeCodec: ListingEnquiriesViewingSuccessResponse3.codec,
                defaultResponse: ListingEnquiriesViewingSuccessResponse3.newDefault(),
            })(),
        )
        .pipe(rxjsOperators.tap(util.defaultCRMRequestErrorHandler));

const makeGetOtherViewingsForListingIdsRequest = (enquiriesResponse: FirstPartyFetchResponse.T<EnquirySuccessResponse2.T>): rxjs.Observable<FirstPartyFetchResponse.T<ListingEnquiriesViewingSuccessResponse3.T>> => {
    const listingIds =  pipe(
        enquiriesResponse.response.data,
        array.map((enquiry) => enquiry.listing_id)
    );
    if (listingIds.length > 0) {
        return rxjs
            .from(
                fetchWrapper.json<ListingEnquiriesViewingSuccessResponse3.T>({
                    requestParams: {
                        url: `${env.REACT_APP_API_URL}/v1/viewings?limit=200&filters=${encodeURIComponent(JSON.stringify(ViewingFilter1.createForListingIdsOnlyFuture(listingIds as unknown as nonEmptyArray.NonEmptyArray<string>, DateTime.local().toUTC().toISO())))}`,
                        method: "GET",
                        body: option.none,
                    },
                    expectedTypeCodec: ListingEnquiriesViewingSuccessResponse3.codec,
                    defaultResponse: ListingEnquiriesViewingSuccessResponse3.newDefault(),
                })(),
            )
            .pipe(rxjsOperators.tap(util.defaultCRMRequestErrorHandler));
    }
    return rxjs.of(FirstPartyFetchResponse.create2XX(ListingEnquiriesViewingSuccessResponse3.newDefault()));
};

const makeGetEnquiriesListingsViewingsRequests = (partyId: string, setState: TSetState, getState: TGetState): rxjs.Observable<void> =>
     pipe(
        actionable.of<TEnquiryRequestPayloads>(
            setState,
            getState,
            [
                partyId,
                FirstPartyFetchResponse.create2XX(EnquirySuccessResponse2.newDefault()),
                FirstPartyFetchResponse.create2XX(ListingSuccessResponse2.newDefault()),
                FirstPartyFetchResponse.create2XX(ListingEnquiriesViewingSuccessResponse3.newDefault()),
                FirstPartyFetchResponse.create2XX(ListingEnquiriesViewingSuccessResponse3.newDefault()),
            ]
        ),
        actionable.fromPayloadIndex<0, 1, TEnquiryRequestPayloads>(0, 1, makeGetEnquiriesRequest),
        actionable.fromPayloads<2, TEnquiryRequestPayloads>(2, makeGetListingsByIdsRequest),
        actionable.fromPayloadIndex<0, 3, TEnquiryRequestPayloads>(0, 3, makeGetViewingsForPartyRequest),
        actionable.fromPayloadIndex<1, 4, TEnquiryRequestPayloads>(1, 4, makeGetOtherViewingsForListingIdsRequest),
        actionable.tapPayloads<TEnquiryRequestPayloads>(
            reduceDataToStateUpdate<TEnquiryRequestPayloads>(setState)(
                set<TEnquiryRequestPayloads>()(
                    ["activeData", "crm", "party", "enquiryForms"],
                    (formList, data) => TForm.requestToFormList<TEnquiryC2AndC3FormList["forms"][number]>(formList, data[1])
                ),
                set<TEnquiryRequestPayloads>()(
                    ["activeData", "crm", "party", "enquiryListings"],
                    (formList, data) => TForm.requestToFormList<TListing3FormList["forms"][number]>(formList, data[2])
                ),
                set<TEnquiryRequestPayloads>()(
                    ["activeData", "crm", "party", "viewingForms"],
                    (formList, data) => TForm.requestToFormList<TListingEnquiriesViewing2AndC3FormList["forms"][number]>(formList, data[3])
                ),
                set<TEnquiryRequestPayloads>()(
                    ["activeData", "crm", "party", "upcomingViewingForms"],
                    (formList, data) => TForm.requestToFormList<TListingEnquiriesViewing2FormList["forms"][number]>(formList, data[4])
                ),
            )
        ),
        rxjsOperators.mapTo(undefined),
    );

const getValidationResultsForUserForms = (user: TCRMParty.C["users"][number]): Array<TCRMParty.TUpdateUsersIndexedValidationResults> =>
        [
            {
                validationFor: "userForm",
                validationOption: User8.validator(user.userForm.form),
            },
            {
                validationFor: "userNewPhoneNumberForm",
                validationOption: user.newPhoneNumber.form.form.phone_number !== ""
                    ? UserPhoneNumber2.validator(user.newPhoneNumber.form.form)
                    : option.none,
            },
            ... pipe(
                user.existingPhoneNumbers,
                array.map<TCRMParty.C["users"][number]["existingPhoneNumbers"][number], {
                    validationFor: "userExistingPhoneNumberForm";
                    phoneNumberId: string;
                    validationOption: option.Option<TValidationError>;
                }>((userPhoneNumber) => ({
                    validationFor: "userExistingPhoneNumberForm",
                    phoneNumberId: userPhoneNumber.meta.id,
                    validationOption: UserPhoneNumber4.validator(userPhoneNumber.form),
                })),
            ),
        ];

const makeUpdateUserRequests = (user: TCRMParty.C["users"][number], setState: TSetState): rxjs.Observable<Array<
        FirstPartyFetchResponse.T<UserSuccessResponse2.T>
        | FirstPartyFetchResponse.T<UserPhoneNumberSuccessResponse1.T>
    >> =>
    rxjs.forkJoin([
        // Patch user request and response mapping
        rxjs.from(
            fetchWrapper.json<UserSuccessResponse2.T>({
                requestParams: {
                    url: `${env.REACT_APP_API_URL}/v1/users/${user.userForm.meta.id}`,
                    method: "PATCH",
                    body: option.some(JSON.stringify(user.userForm.form)),
                },
                expectedTypeCodec: UserSuccessResponse2.codec,
                defaultResponse: user.userForm.response.response,
            })()
        )
            .pipe(
                rxjsOperators.tap(util.defaultCRMRequestErrorHandler),
                rxjsOperators.tap((response) =>
                    setState(({...s}) => {
                        s.activeData.crm.party = TCRMParty.updateUserForm(s.activeData.crm.party, user.userForm.meta.id, (u) => {
                            u.response = response;
                            u.status =
                                response.tag === FirstPartyFetchResponse.constants.STATUS_2XX
                                    ? TFormStatus.constants.SUCCESS
                                    : TFormStatus.constants.FAILURE;
                            return u;
                        });
                        return s;
                    }),
                ),
            ),
        // New phone number request and response mapping
        (user.newPhoneNumber.form.form.phone_number !== ""
            ? rxjs.from(
                fetchWrapper.json<UserSuccessResponse2.T>({
                    requestParams: {
                        url: `${env.REACT_APP_API_URL}/v1/users/${user.newPhoneNumber.form.meta.id}/phone-numbers`,
                        method: "POST",
                        body: option.some(JSON.stringify(user.newPhoneNumber.form.form)),
                    },
                    expectedTypeCodec: UserSuccessResponse2.codec,
                    defaultResponse: user.newPhoneNumber.form.response.response,
                })())
            : rxjs.of(FirstPartyFetchResponse.create2XX(UserSuccessResponse2.newDefault())))
            .pipe(
                rxjsOperators.tap(util.defaultCRMRequestErrorHandler),
                rxjsOperators.tap((response) =>
                    setState(({...s}) => {
                        s.activeData.crm.party = TCRMParty.updateUserNewPhoneNumberForm(s.activeData.crm.party, user.newPhoneNumber.form.meta.id, (u) => {
                            u.form.response = response;
                            u.form.status =
                                response.tag === FirstPartyFetchResponse.constants.STATUS_2XX
                                    ? TFormStatus.constants.SUCCESS
                                    : TFormStatus.constants.FAILURE;
                            return u;
                        });
                        return s;
                    }),
                ),
            ),
        // Patch existing phone numbers requests and response mappings
        ... pipe(
            user.existingPhoneNumbers,
            array.map((userPhoneNumber) =>
                rxjs.from(
                    fetchWrapper.json<UserPhoneNumberSuccessResponse1.T>({
                        requestParams: {
                            url: `${env.REACT_APP_API_URL}/v1/phone-numbers/${userPhoneNumber.meta.id}`,
                            method: "PATCH",
                            body: option.some(JSON.stringify({
                                ...userPhoneNumber.form,
                                primaryNumber: userPhoneNumber.form.primary_number || undefined,
                            })),
                        },
                        expectedTypeCodec: UserPhoneNumberSuccessResponse1.codec,
                        defaultResponse: userPhoneNumber.response.response,
                    })()
                )
                    .pipe(
                        rxjsOperators.tap(util.defaultCRMRequestErrorHandler),
                        rxjsOperators.tap((response) =>
                            setState(({...s}) => {
                                s.activeData.crm.party = TCRMParty.updateUserPhoneNumberFormWhereId(s.activeData.crm.party, user.userForm.meta.id, userPhoneNumber.meta.id, (u) => {
                                    u.response = response;
                                    u.status =
                                        response.tag === FirstPartyFetchResponse.constants.STATUS_2XX
                                            ? TFormStatus.constants.SUCCESS
                                            : TFormStatus.constants.FAILURE;
                                    return u;
                                });
                                return s;
                            }),
                        ),
                    ),
            ),
        ),
    ]);
