import React from "react";
import CRMInputLabelAndErrorWrapComponent from "../CRMInputLabelAndErrorWrapComponent/CRMInputLabelAndErrorWrapComponent";
import {TCRMFormInputPropsWithoutCodec } from "./CRMFormInputTypes";
import { CRMDropdownComponent } from "../CRMDropdownComponent/CRMDropdownComponent";
import { TUnionOfLiteralsCodec } from "../../../../shared/src/codecs/types/union";
import { snakeCaseToCopyText } from "../../../../shared/src/util";
import { IOption } from "../../hooks/UseDropdown";

/**
 * This type is being used to allow the comp to either take a codec or options. This is so that by default it
 * can automatically take the codec in the auto edit form. On the other hand if this component is being used on
 * it's own or as an override you should use options to manually set it.
 */
type TCRMDropdownFormInput = <A extends string, B extends A>(props: (TCRMFormInputPropsWithoutCodec<A> & {
    options: Array<IOption<A>>;
    model: B
}) | (TCRMFormInputPropsWithoutCodec<string> & {
    codec: TUnionOfLiteralsCodec
})) => JSX.Element;

/**
 * This comp supports `codec` or `options` (not both) you should probably be using `options`
 */
export const CRMDropdownFormInput: TCRMDropdownFormInput = (props) =>
<CRMInputLabelAndErrorWrapComponent label={props.label}>
    <CRMDropdownComponent
        options={
            "options" in props ? props.options :
            props.codec.payload.map((innerCodec) => ({
            value: innerCodec.payload,
            label: snakeCaseToCopyText(innerCodec.payload)
        }))}
        value={props.model}
        onChange={props.onChange as (v: string) => void}
        displayError={props.displayError}
        disabled={props.disabled}
    />
</CRMInputLabelAndErrorWrapComponent>;
