import React, { useEffect } from "react";
import { CRMRoadblocksBlock } from "../../components/CRMRoadblock/CRMRoadblocksBlock/CRMRoadblocksBlock";
import { pipe } from "fp-ts/lib/function";
import { option } from "fp-ts";
import { TBlockContainerStateProps } from "../../state/TBlockContainerStateProps";
import { getRouteBlockData } from "../../state/router/getRouteBlockData";
import { simpleFold } from "../../../../shared/src/utilsByDomain/option/simpleFold";
import { updateRouteWithBlockChange } from "../../state/router/updateRouteWithBlockChange";


const CRMCaseRoadblocksContainer = (props: TBlockContainerStateProps): JSX.Element => {

    const blockData = pipe(
        getRouteBlockData(props.state.routes),
        simpleFold
    );
    const openRoadblockID = blockData?.blocksOpened[props.index].meta["openRoadblockID"];

    useEffect(
        () => props.dispatch({
            type: "LEGAL_CASE_GET_ROADBLOCKS",
            payload: null
        }), 
        []
    );

    const updateRoutesBlockParamsToCacheOpenBlocksID = (openRoadblockID?: string) => {
        pipe(
            props.state.routes,
            updateRouteWithBlockChange(
                "case_roadblocks", 
                props.index,
                (blockParams) => ({
                    ...blockParams,
                    meta: { openRoadblockID }
                })
            ),
            option.fold(
                () => undefined,
                (routeAction) => props.dispatch(routeAction)
            )
        )
    }

    return (
        <CRMRoadblocksBlock
            sessionUser={props.state.global.user}
            openRoadblockID={openRoadblockID}
            roadblocksForm={props.state.forms.case_roadblocks_block}
            onOpen={(item) => {
                updateRoutesBlockParamsToCacheOpenBlocksID(item.id);
                props.dispatch({
                    type: "LEGAL_CASE_GET_ROADBLOCK",
                    payload: item.detailed_roadblock_form
                });
            }}
            onClose={() => {
                updateRoutesBlockParamsToCacheOpenBlocksID();
                props.dispatch({
                    type: "LEGAL_CASE_GET_ROADBLOCKS",
                    payload: null
                });
            }}
            onChange={(payload) => props.dispatch({
                type: "LEGAL_CASE_ROADBLOCK_CHANGE",
                payload
            })}
            onChangeManualItem={(payload) => props.dispatch({
                type: "LEGAL_CASE_UPDATE_ROADBLOCK_MANUAL_CHECK_LIST_ITEM",
                payload
            })}
            onTypeMessage={(payload) => props.dispatch({
                type: "LEGAL_CASE_TYPE_CHAT_MESSAGE",
                payload
            })}
            onSubmitMessage={(payload) => props.dispatch({
                type: "LEGAL_CASE_SUBMIT_CHAT_MESSAGE",
                payload
            })}
            onOpenForm={(payload) => props.dispatch({
                type: "BLOCK_OPEN_BEGIN_CHOICE",
                payload
            })}
            onSignOff={(payload) => props.dispatch({
                type: "LEGAL_CSAE_ROADBLOCK_SIGN_OFF",
                payload
            })}
        />
    );
}
export default CRMCaseRoadblocksContainer;
