import React from "react";
import { CRMRoadblockTableSheet } from "../../CRMRoadblock/CRMRoadblockTableSheet/CRMRoadblockTableSheet";
import { array } from "fp-ts";
import { SpacingColumn } from "../../BuildingBlocks/SpacingColumn";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { Absolute } from "../../BuildingBlocks/Absolute";
import { ShineShape } from "../../BuildingBlocks/ShineShape";
import { CRMColors } from "../../../models/CRMColors";
import { CRMJobsFilterPlaceholder } from "../CRMJobsFilterPlaceholder/CRMJobsFilterPlaceholder";
import { CRMJobsRoadblocksTableFilter, TJobsRoadblocksTableFilterProps } from "../CRMJobsRoadblocksTableFilter/CRMJobsRoadblocksTableFilter";
import { Background } from "../../BuildingBlocks/Background";
import { TGroupSetting } from "../CRMJobsOverlay/CRMJobsDefinitions";


type TJobsRoadblockTableProps = 
    TJobsRoadblocksTableFilterProps 
    & {
        jobsGroupingMode: TGroupSetting;
        onLoadMore: () => void;
    }
;

export const CRMJobsRoadblockTable = (props: React.PropsWithChildren<TJobsRoadblockTableProps>): JSX.Element => {

    const isLoading = props.form.status === "loading";

    return (
        <Absolute top={"0px"} left={"108px"}>
            <Background 
                padding={`24px 0px 0px 0px`}
                minWidth="1000px"
                width="100vw"
                maxWidth="1400px"
            >
                {isLoading &&
                    <Placeholder />
                }

                {!isLoading &&
                    <SpacingColumn spacing={CRMSpacing.LARGE}>    
                        <CRMJobsRoadblocksTableFilter 
                            {...props} 
                        />
                    
                        <CRMRoadblockTableSheet
                            roadblocksForm={props.form}
                            jobsForm={props.jobsForm}
                            jobsFilter={props.jobsFilter}
                            jobsGroupingMode={props.jobsGroupingMode}
                            onLoadMore={props.onLoadMore}
                        />
                    </SpacingColumn>
                }
            </Background>
        </Absolute>
    );
};

const Placeholder = () => {
    return (
        <SpacingColumn spacing={CRMSpacing.LARGE}>
            <CRMJobsFilterPlaceholder />

            <SpacingColumn spacing={CRMSpacing.MEDIUM}>
                <ShineShape
                    backgroundColor={CRMColors.NEUTRAL_10} 
                    shineColor={CRMColors.NEUTRAL_12}
                    duration={2000}
                    height={"40px"}
                    width={"1200px"}
                />
                <SpacingColumn spacing={CRMSpacing.SMALL}>
                    {array.range(0, 9).map((number) => (    
                        <ShineShape
                            key={number}
                            backgroundColor={CRMColors.NEUTRAL_10} 
                            shineColor={CRMColors.NEUTRAL_12}
                            duration={2000}
                            height={"24px"}
                            width={"1200px"}
                        />
                    ))}
                </SpacingColumn>
            </SpacingColumn>
        </SpacingColumn>
    )
}