import React from "react";
import { CRMColors } from "../../../models/CRMColors";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { SpacingRow } from "../../BuildingBlocks/SpacingRow";
import { CRMParagraph } from "../../Simple/CRMParagraph/CRMParagraph";
import { TextColour } from "../../TextColour/TextColour";
import { WeightBold } from "../../WeightBold/WeightBold";
import { WeightMedium } from "../../WeightMedium/WeightMedium";

export const CRMSingleLineLabelAndValue = (props: React.PropsWithChildren<{
    label: string;
    value: string | JSX.Element;
}>): JSX.Element =>
    <TextColour colour={CRMColors.NEUTRAL_0}>
        <SpacingRow spacing={CRMSpacing.TINY}>
            {/* LABEL */}
            <CRMParagraph>
                <WeightMedium>
                    {props.label}
                </WeightMedium>
            </CRMParagraph>
            
            {/* VALUE */}
            {typeof props.value === "string"
                ? <CRMParagraph>
                    <WeightBold>
                        {props.value}
                    </WeightBold>
                </CRMParagraph>
                : props.value
            }
        </SpacingRow>
    </TextColour>;
