import React from "react";

type TCRMSpacer = {
    size: "tiny" | "medium" | "large";
};

export class CRMColumnSpacer extends React.Component<React.PropsWithChildren<TCRMSpacer>> {
    public render (): JSX.Element {
        return (
            <div className={`crm-column-spacer crm-column-spacer--${this.props.size}`}></div>
        );
    }
}
