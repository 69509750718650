import React, { useEffect, useState } from "react";
import ReactDOMServer from "react-dom/server";
import juice from "juice";
import { ContentState, EditorState } from "draft-js";
import { convertToHTML } from "draft-convert";
import { TEmailCompositionForm } from "../../../../../../domain/codecs/form/EmailCompositionForm";
import { onChangeForm } from "../../../../../../shared/src/codecs/types/form";
import { isFilled, requireExhaustive } from "../../../../../../shared/src/util";
import { Relative } from "../../../BuildingBlocks/Relative";
import { TAttachmentType } from "../CRMEmailAttachmentFilterSearchSelect/CRMEmailAttachmentFilterSearchSelect";
import { getAttachedEnquiriesInDetail, getAttachedFileInDetail, getAttachedQuotesInDetail, getAttachedRedemptionStatementsInDetail } from "../CRMComposeEmailAttachments/CRMComposeEmailAttachments";
import { CRMLegalEmail } from "../CRMLegalEmail/CRMLegalEmail";
import { StaffUser } from "../../../../../../domain/codecs/User";
import { anyMatchPredicate, contains } from "../../../../../../shared/src/utilsByDomain/array";
import { CRMComposeEmailFormViewMain } from "../CRMComposeEmailFormViewMain/CRMComposeEmailFormViewMain";
import { CRMComposeEmailFormViewAttachedEmail } from "../CRMComposeEmailFormViewAttachedEmail/CRMComposeEmailFormViewAttachedEmail";
import { isAFunction } from "../../../../functions/functions";
import { doesEmailContain } from "../../../../functions/email/doesEmailContain";
import { isEmailViewType, TComposeEmailFormAttachmentOptionsProps, TComposeEmailFormCaseSearchProps, TComposeEmailFormControlProps, TComposeEmailFormWritingProps, TComposeEmailResolutionStatus, TContextType, TSendType, TEmailViewStatus } from "./CRMComposeEmailFormProps";
import { EmailAttachments, TEmailComposition } from "../../../../../../domain/codecs/EmailComposition";
import * as regexes from "./../../../../../../shared/src/regexes";
import { CRMBlockPopover } from "../../../CRM/CRMBlock/CRMBlockPopover/CRMBlockPopover";
import { CRMSpacing } from "../../../../models/CRMSpacing";
import { textToEditorState } from "../../../CRMRichTextArea/CRMRichTextAreaUtils";
import { CRMComposeEmailAttachmentSearchPopover } from "../CRMComposeEmailAttachmentSearchPopover/CRMComposeEmailAttachmentSearchPopover";
import { CRMComposeEmailTemplatesPopover } from "../CRMComposeEmailTemplatesPopover/CRMComposeEmailTemplatesPopover";
import { TEmailTemplateRecord } from "../CRMComposeEmailTemplateFilterSearchSelect/CRMComposeEmailTemplateFilterSearchSelect";
import { pipe } from "fp-ts/lib/function";

type TComposeEmailFormProps = 
    TComposeEmailFormWritingProps &
    TComposeEmailFormCaseSearchProps &
    TComposeEmailFormAttachmentOptionsProps &
    TComposeEmailFormControlProps &
    {   
        caseEmailPageCaseId?: string;
        viewStatus?: TEmailViewStatus;
        apiUrl: string;
    }
;

export const CRMComposeEmailForm = (props: React.PropsWithChildren<TComposeEmailFormProps>): JSX.Element => {

    const [viewStatus, setViewStatus] = useState<TEmailViewStatus>("compose-case-new");
    const [resolutionStatus, setResolutionStatus] = useState<TComposeEmailResolutionStatus>("none");
    const [editorState, setEditorState] = useState(() => EditorState.createEmpty());


    useEffect(
        () => setEditorState(textToEditorState(props.form.edited.body_draft)),
        []
    );

    useEffect(
        () => {
            if (props.form.edited.body === "" && props.form.edited.body_draft === "") {
                setEditorState(EditorState.createEmpty());
            }
        }, 
        [props.form.edited.body]
    );
    
    useEffect(
        () => {
            if (props.form.status === "success") {
                setResolutionStatus("clear-email");
            }
        },
        [props.form.status],
    );

    useEffect(
        () => setViewStatus(inferComposeViewStatus()),
        [
            props.form.edited.bcc_addresses,
            props.form.edited.cc_addresses,
            props.form.edited.sentFrom,
            props.form.edited.sendTo,
            props.attachedEmail
        ],
    )

    useEffect(
        () => {
            if (isEmailViewType("confidential", viewStatus)) {
                props.onChange(
                    getFormWithUpdatedEdit({
                        caseID: null,
                        attachments: EmailAttachments.newDefault(),
                    })
                );
            }
        },
        [viewStatus]
    )


    const getEmailSendType = (): TSendType => {
        if (isAnEmailBeingSentTo(props.attachedEmail.children.email.from_address)) {
            return "reply";
        }

        if (hasAttachedEmail()) {
            return "forward";
        }
        
        return "new";
    }

    const inferContextType = (): TContextType => {
        if (
            isEmailAddressConfidential(props.form.edited.sentFrom)
            || isAnyEmailAddressConfidential(props.form.edited.sendTo)
            || isAnyEmailAddressConfidential(props.form.edited.cc_addresses)
            || isAnyEmailAddressConfidential(props.form.edited.bcc_addresses)
        ) {
            return "confidential";
        }
        return "case";
    };

    const inferComposeViewStatus = (): TEmailViewStatus => `compose-${inferContextType()}-${getEmailSendType()}`;

    const getSelectedCase = () => props.form.children.cases_search_form.children.find((legalCase) => legalCase.id === props.form.edited.caseID);

    const clearEmailTextEditor = () => {
        setEditorState(
            EditorState.push(
                editorState, 
                ContentState.createFromText(''),
                "remove-range"
            )
        );
    }

    const getFormWithUpdatedEdit = (edited: Partial<TEmailComposition>): TEmailCompositionForm => ({
        ...props.form,
        edited: {
            ...props.form.edited,
            ...edited
        }
    });

    const resetResolutionSubmission = () => setResolutionStatus("none");

    const convertEmailTextAndAttachmentsToLegalEmailTemplateHTML = (): string => {
        const selectedCase = getSelectedCase();
        // this is anoying shuold be refined, its repeated code in email body
        const sentFromDetails = selectedCase?.case_handlers
            .filter((handler) => handler.id !== props.composingUser.id)
            .concat(props.composingUser)
            .find((handler) => handler.email === props.form.edited.sentFrom);

        const emailBodyAsHTML = convertToHTML(editorState.getCurrentContent()).toString();
        const finalEmailHTML = ReactDOMServer.renderToStaticMarkup(
            CRMLegalEmail({
                subject: props.form.edited.subject,
                body: emailBodyAsHTML,
                enquiries: getAttachedEnquiriesInDetail(props.form),
                files: getAttachedFileInDetail(props.form),
                redemptionStatements: getAttachedRedemptionStatementsInDetail(props.form),
                quotes: getAttachedQuotesInDetail(props.form),
                senderDetails: sentFromDetails || StaffUser.newDefault(),
                apiUrl: props.apiUrl,
            })
        );
        const finalEmailHTMLWithComments = finalEmailHTML.replace(/<(template) data-comment\b[^>]*>(.*?)<\/\1>/gm, '$2');

        return juice(
            finalEmailHTMLWithComments, 
            {
                'applyWidthAttributes': false,
                'applyHeightAttributes': false,
                'applyAttributesTableElements': false,
                'preserveImportant': true
            }
        );
    }

    const isCaseSelected = () => !!getSelectedCase();
    const isEmailAddressConfidential = doesEmailContain(regexes.constants.SAIL_LEGAL_CONFIDENTIAL_EMAIL);
    const isAnyEmailAddressConfidential = anyMatchPredicate<string>(isEmailAddressConfidential);
    const isAnEmailBeingSentTo = (email) => contains(email)(props.form.edited.sendTo);
    const hasAttachedEmail = () => !!props.attachedEmail?.children.email.id;
    const isEmailBodyValid = () => getEmailSendType() === "forward" ? true : isEmailBodyPopulated();
    const isEmailBodyPopulated = (): boolean => editorState.getCurrentContent().hasText();
    const isNotAnInvalidCaseEmail = () => isEmailViewType("confidential", viewStatus) ? true : isFilled(props.form.edited.caseID);

    const canSubmit = (): boolean => (
        isNotAnInvalidCaseEmail()
        && isFilled(props.form.edited.sendTo)
        && isFilled(props.form.edited.sentFrom)
        && isFilled(props.form.edited.subject)
        && isEmailBodyValid()
    );

    const onChange = onChangeForm(props.form, props.onChange);

    const onChangeEmailContentState = (newEditorState: EditorState) => {
        setEditorState(newEditorState);
        onChange("body_draft")(convertToHTML(editorState.getCurrentContent()).toString());
    }

    const getTransactionType = () => getSelectedCase()?.transaction_type;
    const getSellerReasonForSale = () =>  getSelectedCase()?.seller_reason_for_sale;
    const getPurchasePaymentMethod = () => getSelectedCase()?.purchase_payment_method || "unknown";
    const getGopDocumentExists = () => getSelectedCase()?.gop_received === "grant_of_probate";
    const unregisteredPropertiesExist = () => pipe(
        getSelectedCase()?.properties
        .filter((property) => property.is_registered !== true).length || 0,
        (numberUnregistered) => numberUnregistered > 0
    );
    
    const onSelectEmailTemplate = (template: TEmailTemplateRecord) => {
        let newEditorState = EditorState.createWithContent(ContentState.createFromText(template.content))
        onChangeEmailContentState(newEditorState);
        setViewStatus(inferComposeViewStatus());
    }

    const onAddAttachment = (type: TAttachmentType, id: string) =>
        onChange("attachments")({
            ...props.form.edited.attachments,
            [type]: props.form.edited.attachments[type].concat(id)
        });
   
    const onRemoveAttachment = (type: TAttachmentType, id: string) =>
        onChange("attachments")({
            ...props.form.edited.attachments,
            [type]: props.form.edited.attachments[type].filter((attachmentId) => attachmentId !== id)
        });

    const onClose = () => {
        const emailBody = convertToHTML(editorState.getCurrentContent()).toString();
        const form = getFormWithUpdatedEdit({ body: emailBody });
        props.onChange(form);
        if (isAFunction<() => void>(props.onClose)) {
            props.onClose();
        }
    }

    const onClear = () => {
        resetResolutionSubmission();
        clearEmailTextEditor();
        props.onClear();
    }

    const onSend = () => {
        const sendType = getEmailSendType();
        const form = getFormWithUpdatedEdit({
            body: convertEmailTextAndAttachmentsToLegalEmailTemplateHTML()
        });
        
        switch (sendType) {
            case "new":
                props.onSubmit(form);
            break;
            case "forward":
                props.onForward(form);
            break;
            case "reply":
                props.onReply(form);
            break;
            default:
                requireExhaustive(sendType);
        }
    }
    
    
    return (
        <Relative>

            {/* [POPOVER] - SELECT EMAIL TEMPLATE */}
            {viewStatus === "view-email-templates" &&
                <CRMComposeEmailTemplatesPopover
                    transactionType={getTransactionType() || "unknown"}
                    sellerReasonForSale={getSellerReasonForSale() || "unknown"}
                    gopDocumentExists={getGopDocumentExists()}
                    purchasePaymentMethod={getPurchasePaymentMethod()}
                    isUnregistered={unregisteredPropertiesExist()}
                    onClose={() => setViewStatus(inferComposeViewStatus())}
                    onSelectTemplate={onSelectEmailTemplate}
                />
            }
            
            {/* [POPOVER] - SELECT ATTACHMENT */}
            {viewStatus === "view-attachment-search" &&
                <CRMComposeEmailAttachmentSearchPopover
                    form={props.form}
                    onClose={() => setViewStatus(inferComposeViewStatus())}
                    onAddAttachment={onAddAttachment}
                />
            }

            {/* [POPOVER] - VIEW ATTACHED EMAIL */}
            {viewStatus === "view-attached-email" &&
                <CRMBlockPopover
                    padding={CRMSpacing.MEDIUM}
                    top={CRMSpacing.TINY}
                    height="720px" 
                    maxHeight="720px"
                >
                    <CRMComposeEmailFormViewAttachedEmail
                        viewStatus={inferComposeViewStatus()}
                        attachedEmail={props.attachedEmail}
                        onClick={() => setViewStatus(inferComposeViewStatus())}
                    />
                </CRMBlockPopover>
            }
            
            {/* [BODY] - EMAIL FORM */}
            <CRMComposeEmailFormViewMain
                {...props}
                
                viewStatus={viewStatus}

                isACaseSelected={isCaseSelected()}
                isSubmittable={canSubmit()}

                resolutionStatus={resolutionStatus}
                onResetResolutionSubmission={resetResolutionSubmission}

                emailContentState={editorState}
                onChangeEmailContentState={onChangeEmailContentState}

                onSend={onSend}
                onClose={onClose}
                onClear={onClear}
                onOpenAttachments={() => setViewStatus("view-attachment-search")}
                onRemoveAttachment={onRemoveAttachment}
                onOpenAttachedEmail={() => setViewStatus("view-attached-email")}
                onOpenEmailTemplates={() => setViewStatus("view-email-templates")}
            />
        </Relative>
    );
};
