import React from "react";
import { UserCaseUserType_UserCaseUserTypeDisplayName } from "../../../../../../domain/codecs/UserCaseUserType";
import { FrontColors } from "../../../../models/FrontColors";
import { FrontSpacing } from "../../../../models/FrontSpacing";
import { Padding } from "../../../BuildingBlocks/Padding";
import { SpacingColumn } from "../../../BuildingBlocks/SpacingColumn";
import { SpacingRow } from "../../../BuildingBlocks/SpacingRow";
import { FrontTextColour } from "../../../FrontTextColour/FrontTextColour";
import { WeightBold } from "../../../WeightBold/WeightBold";
import { FrontIcon } from "../../Simple/FrontIcon/FrontIcon";
import { FrontInfoBubble } from "../../Simple/FrontInfoBubble/FrontInfoBubble";
import FrontParagraphSmall from "../../Simple/FrontParagraphSmall/FrontParagraphSmall";
import { getAnOrA, TFrontOnboardingCaseTOEExplanationProps } from "../FrontOnboardingCaseTOEExplanation/FrontOnboardingCaseTOEExplanation";

export const FrontOnboardingCaseTOEExplanationMobile = (props: React.PropsWithChildren<TFrontOnboardingCaseTOEExplanationProps>): JSX.Element => (
    <FrontInfoBubble
        color={FrontColors.PRIMARY_2}
        boxShadow={true}
    >
        <Padding spacing={FrontSpacing.SMALL_3}>
            <SpacingColumn spacing={FrontSpacing.MEDIUM_1}>
                <SpacingRow alignItems="center" justifyContent="center">
                    <FrontIcon
                        iconName="sail-boat"
                        size="extra-large"
                        colour="neutral-18"
                    />
                </SpacingRow>

                <SpacingColumn spacing={FrontSpacing.SMALL_3}>

                    {(props.caseUserType === "client" || props.caseUserType === "executor") &&
                        <FrontParagraphSmall>
                            <FrontTextColour colour="neutral-18">
                                You are about to begin your journey with Sail Legal.
                            </FrontTextColour>
                        </FrontParagraphSmall>
                    }
                    
                    {(props.caseUserType === "client" || props.caseUserType === "executor") &&
                        <FrontParagraphSmall>
                            <FrontTextColour colour="neutral-18">
                                <WeightBold>
                                    Thank you for choosing us.
                                </WeightBold>
                            </FrontTextColour>
                        </FrontParagraphSmall>
                    }

                    {(props.caseUserType === "client" || props.caseUserType === "executor") &&
                        <FrontParagraphSmall>
                            <FrontTextColour colour="neutral-18">
                                Before we can represent you officially on your {props.transactionType} we need you to agree to our<WeightBold> terms of engagement</WeightBold>.
                            </FrontTextColour>
                        </FrontParagraphSmall>
                        }

                    {props.caseUserType !== "client" && props.caseUserType !== "executor" &&
                        <FrontParagraphSmall>
                            <FrontTextColour colour="neutral-18">
                            As {getAnOrA(props.caseUserType)} <WeightBold>{UserCaseUserType_UserCaseUserTypeDisplayName(props.caseUserType)}</WeightBold> involved with this {props.transactionType}, before completing the next steps we need you to agree to our<WeightBold> terms of engagement</WeightBold>.
                            </FrontTextColour>
                        </FrontParagraphSmall>
                    }

                    <FrontParagraphSmall>
                        <FrontTextColour colour="neutral-18">
                            You can read the terms<WeightBold> below</WeightBold>.
                        </FrontTextColour>
                    </FrontParagraphSmall>
                    
                    <FrontParagraphSmall>
                        <FrontTextColour colour="neutral-18">
                            Please<WeightBold> click the button above </WeightBold>when you are ready to proceed.
                        </FrontTextColour>
                    </FrontParagraphSmall>
                </SpacingColumn>
            </SpacingColumn>
        </Padding>
    </FrontInfoBubble>
);