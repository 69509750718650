import React from "react";
import { CRMPopupShadowBox } from "../Simple/CRMPopupShadowBox/CRMPopupShadowBox";
import { TCalcSize, TPercentageSize, TPixelSize, TPixelSizeShortHand, TViewHeight } from "../../models/StringLiterals";

export type TPopupContext =  "positive" | "important" | "warning" | "neutral" | "none";
export type TPopupSize = "regular" | "large" | "medium" | "medium-max-height" | "x-large" | "full-screen";

type TCRMCardOutsidePopupBlank = {
    isOpen: boolean;
    onClose: () => void;
    context?: TPopupContext;
    size?: TPopupSize;
    noCloseOnShadowboxClick?: boolean;
    borderRadius?: TPixelSize | TPixelSizeShortHand;
    cardMargin?: TPixelSize | TPixelSizeShortHand;
};

const CRMCardOutsidePopupBlankComp = (props: React.PropsWithChildren<TCRMCardOutsidePopupBlank>): JSX.Element => {
    
    const popupBoxRef = React.createRef<HTMLDivElement>();

    return (
        <CRMPopupShadowBox
            isOpen={props.isOpen}
            onClose={props.onClose}
        >
            <div
                className={`
                    crm-card-outside-popup-blank
                    crm-card-outside-popup-blank--${props.size === 'full-screen' ? 'no-padding' : 'padded'}
                `}
            >
                <div
                    ref={popupBoxRef}
                    className={`
                        crm-card-outside-popup-blank__box
                        crm-card-outside-popup-blank__box--${props.context || 'neutral'}
                        crm-card-outside-popup-blank__box--${props.size || 'regular'}
                    `}
                    style={{
                        borderRadius: props.borderRadius ? props.borderRadius : "0px 0px 5px 5px",
                        margin: props.cardMargin ? props.cardMargin : "0px"
                    }}
                >
                    {/* 
                        always un-render children you are not showing, 
                        as they could instantiate hooks, 
                        and run all kinds of other logic in the background that you do not 
                        want running until these popups are actually used 
                    */}
                    {props.isOpen && 
                        props.children
                    }
                </div>
            </div>
        </CRMPopupShadowBox>
    );
};

export const CRMCardOutsidePopupBlank = React.memo(CRMCardOutsidePopupBlankComp) as typeof CRMCardOutsidePopupBlankComp;