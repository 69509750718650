import React from "react";
import { requireExhaustive } from "../../../../../../shared/src/util";
import { useOpenClose } from "../../../../hooks/UseOpenClose";
import { FrontColors } from "../../../../models/FrontColors";
import { FrontFontSizes } from "../../../../models/FrontFontSizes";
import { FrontSpacing } from "../../../../models/FrontSpacing";
import { FontQuicksand } from "../../../BuildingBlocks/FontQuicksand";
import { FontSize } from "../../../BuildingBlocks/FontSize";
import { MaxWidth } from "../../../BuildingBlocks/MaxWidth";
import { SpacingColumn } from "../../../BuildingBlocks/SpacingColumn";
import { SpacingRow } from "../../../BuildingBlocks/SpacingRow";
import { WeightSemiBold } from "../../../WeightSemiBold/WeightSemiBold";
import { FrontIcon, TIcon } from "../FrontIcon/FrontIcon";
import { FrontInfoBubble } from "../FrontInfoBubble/FrontInfoBubble";

type TFrontHelpBubbleProps = {
    type: "blank" | "info" | "pending" | "success" | "failure";
    label: string | JSX.Element;
    labelIcon?: TIcon;
    paragraph: JSX.Element;
    isToggleBubble?: boolean,
};

export const FrontHelpBubble = (props: React.PropsWithChildren<TFrontHelpBubbleProps>): JSX.Element => {

    const { isOpen, toggleOpen } = useOpenClose();

    const getBubbleColorBasedOnStatus = (): string => {
        switch (props.type) {
            case "blank":
                return FrontColors.NEUTRAL_10_2;
            case "info":
                return FrontColors.PRIMARY_22;
            case "pending": 
                return FrontColors.PERTINENT_CORAL_22;
            case "failure": 
                return FrontColors.ERROR_FIREBRICK_22
            case "success": 
                return FrontColors.POSITIVE_APPLE_22
            default:
                return requireExhaustive(props.type);
        }
    }

    const isBubbleShowing = () => props.isToggleBubble ? isOpen : true;

    return (
        <div className={`front-help-bubble`}>
            <SpacingColumn spacing={"3px"}>
                <div>
                    {/* FACE */}
                    <div
                        className={`front-help-bubble__face`}
                        style={{ backgroundColor: getBubbleColorBasedOnStatus() }}
                        onClick={() => {
                            if (props.isToggleBubble) {
                                toggleOpen();
                            }
                        }}
                    >
                        <SpacingRow spacing={FrontSpacing.SMALL_2}>
                            {props.labelIcon &&
                                <FrontIcon
                                    iconName={props.labelIcon}
                                    colour="neutral-ink"
                                    size="small"
                                />
                            }

                            <FontQuicksand>
                                <FontSize size={FrontFontSizes.MINOR}>
                                    <WeightSemiBold>
                                        {props.label}
                                    </WeightSemiBold>
                                </FontSize>
                            </FontQuicksand>
                            
                            {props.isToggleBubble &&
                                <FrontIcon
                                    iconName={isOpen ? "small-cross" : "chevron-down"}
                                    colour="neutral-ink"
                                    size="mini"
                                />
                            }
                        </SpacingRow>
                    </div>
                </div>

                {/* PARAGRAPH BUBBLE */}
                {isBubbleShowing() &&
                    <MaxWidth width="500px">
                        <FrontInfoBubble color={getBubbleColorBasedOnStatus()}>
                            <FontQuicksand>
                                <FontSize size={FrontFontSizes.MINOR} lineHeight="1.4"> 
                                    {props.paragraph}
                                </FontSize>
                            </FontQuicksand>
                        </FrontInfoBubble>
                    </MaxWidth>  
                }
            </SpacingColumn>
        </div>
    );
};
