import React from "react";
import { FrontColors } from "../../../../models/FrontColors";
import { FrontSpacing } from "../../../../models/FrontSpacing";
import { Background } from "../../../BuildingBlocks/Background";
import { Padding } from "../../../BuildingBlocks/Padding";
import { CursorPointer } from "../../../CursorPointer/CursorPointer";
import { FrontRadio, IFrontRadioProps, TRadioValueType } from "../FrontRadio/FrontRadio";

export const FrontRadioButton = <A extends TRadioValueType>(props: React.PropsWithChildren<IFrontRadioProps<A> & {coloursInverted?: boolean}>): JSX.Element => {
    const coloursInverted = props.coloursInverted || false;

    const borderColour =
        coloursInverted
            ? FrontColors.PROBATE_4
            : FrontColors.NEUTRAL_10;

    return <Background
        borderRadius="5px"
        borderDefinition={`solid 1px ${borderColour}`}
        width="fit-content"
        color={FrontColors.NEUTRAL_18}
    >
        <CursorPointer
            onClick={() => {
                if (!props.disabled) {
                    props.onChange(props.value);
                }
            }}
        >
            <Padding spacing={`${FrontSpacing.SMALL_2} ${FrontSpacing.SMALL_3}`}>
                <FrontRadio
                    {...props}
                    onChange={() => undefined}
                />
            </Padding>
        </CursorPointer>
    </Background>;
};
