import React from "react";
import CRMInputLabelAndErrorWrapComponent from "../CRMInputLabelAndErrorWrapComponent/CRMInputLabelAndErrorWrapComponent";
import { CRMInputCalendarComponent } from "../CRMInputCalendarComponent/CRMInputCalendarComponent";
import { TCRMFormInputWithoutCodec } from "./CRMFormInputTypes";
import { SpacingRow } from "../BuildingBlocks/SpacingRow";
import { CRMSpacing } from "../../models/CRMSpacing";
import { CRMButtonIcon } from "../CRMButtonIcon/CRMButtonIcon";

export const CRMCalendarDateWithoutTimeWithClearFormInput: TCRMFormInputWithoutCodec<string | null> = (props) => 
<CRMInputLabelAndErrorWrapComponent label={props.label}>
    <SpacingRow spacing={CRMSpacing.TINY}>
        <CRMInputCalendarComponent
            dateType={"date"}
            value={props.model ?? ""}
            onChange={(value) => props.onChange(value)}
            onPressEnterKey={() => null}
            displayError={props.displayError}
            disabled={props.disabled}
        />
        <CRMButtonIcon
            variant="tertiary"
            icon="backspace"
            disabledColour="neutral-4"
            disabled={props.disabled}
            onClick={() => !props.disabled ? props.onChange(null) : undefined}
        />
    </SpacingRow>
</CRMInputLabelAndErrorWrapComponent>