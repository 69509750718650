import React, { useEffect, useState } from "react";
import { array } from "fp-ts";
import { pipe } from "fp-ts/lib/pipeable";
import { array as extArray } from "../../../shared/src/utilsByDomain/array";

type TUseSplitInputProps = { 
    value: string;
    valuePrepper?: (value: string) => string;
    chunks:  Array<number>;
    onChange: (value: string, splitValue: Array<string>) => void;
};

type TUseSplitInputState = {
    partStates: Array<TPartState>,
};

type TPartState = {
    value: string,
    setValue: React.Dispatch<React.SetStateAction<string>>,
    maxLength: number,
    ref: React.RefObject<HTMLInputElement>,
};

export const useSplitInput = (props: TUseSplitInputProps): TUseSplitInputState => {

    const partStates: Array<TPartState> = pipe(
        props.chunks,
        array.map((maxLength) => {
            let [value, setValue] = useState<string>("")
            return {
                value,
                setValue,
                maxLength,
                ref: React.createRef<HTMLInputElement>()
            }
        })
    );
    const partValues = partStates.map(({ value }) => value);

    useEffect(
        () => {
            if (getPreppedOutsideValue() !== getCombinedInternalValue()) {
                let splitValue = splitValueForLocalState(getPreppedOutsideValue());
                partStates.map(({ setValue }, index) => setValue(splitValue[index]));
            }
        },
        [props.value]
    );

    useEffect(
        () => {
            if (isValueFull()) {
                props.onChange(
                    getCombinedInternalValue(),
                    partStates.map(({value}) => value)
                );
            }

            focusTheNextInput();
        },
        partValues
    );

    useEffect(
        () => {
            document.addEventListener("keyup", (focusedPreviousInputOnEmptyBackspace));
            return () => document.removeEventListener("keyup", focusedPreviousInputOnEmptyBackspace);
        }
    );

    const focusedPreviousInputOnEmptyBackspace = (event: KeyboardEvent) => {
        const focusedInputIndex = getIndexOfFocusedInput();
        if (
            event.key === "Backspace"
            && focusedInputIndex > 0
            && partStates[focusedInputIndex].value.length === 0
        ) {
            partStates[focusedInputIndex-1].ref.current?.focus();
        }
    }

    const getPreppedOutsideValue = (): string => {
        if (typeof props.valuePrepper === "function") {
            return props.valuePrepper(props.value);
        }
        return props.value;
    }

    const focusTheNextInput = () => {
        const focusedInputIndex = getIndexOfFocusedInput();
        if (
            focusedInputIndex > -1
            && focusedInputIndex < partStates.length-1
            && partStates[focusedInputIndex].value.length === partStates[focusedInputIndex].maxLength
        ) {
            partStates[focusedInputIndex+1].ref.current?.focus();
        }
    }

    const getIndexOfFocusedInput = (): number =>
        partStates.reduce(
            (finalIndex,  { ref }, index) => document.activeElement === ref.current ? index : finalIndex,  
            -1
        )
    ;

    const splitValueForLocalState = (value: string) =>
        pipe(
            props.chunks,
            array.reduce(
                {
                    remainingValue: value.split(""),
                    valueInParts: [] as Array<Array<string>>,
                },
                (sum, chunk) => {
                    let splitValue = array.splitAt(chunk)(sum.remainingValue);
                    return {
                        remainingValue: splitValue[1],
                        valueInParts: sum.valueInParts.concat([splitValue[0]])
                    }
                },
            ),
            (sum) => sum.valueInParts, 
            array.map((partValue) => partValue.join("")),
            extArray.mergeOnTop(pipe(
                array.range(0, props.chunks.length-1),
                array.map(() => "")
            ))
        )
    ;

    const isValueFull = (): boolean => 
        getCombinedInternalValue().length === getMaxValueLength()
    ;

    const getMaxValueLength = (): number =>
        props.chunks.reduce((sum, value) => sum + value, 0)
    ;

    const getCombinedInternalValue = () =>
        partStates
            .map(({ value }) => value)
            .join("")
    ;

    return {
        partStates
    }
}