import { pipe } from "fp-ts/lib/function";
import React, { useState } from "react";
import { Address3, Address3_displayString, Address6_displayString, TAddress3 } from "../../../../../domain/codecs/Address";
import { TClientCompanyPartnershipForm } from "../../../../../domain/codecs/form/ClientCompanyPartnershipForm";
import { Listing3 } from "../../../../../domain/codecs/Listing";
import { onChangeForm } from "../../../../../shared/src/codecs/types/form";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { Padding } from "../../BuildingBlocks/Padding";
import { CRMEditAddressForm } from "../../CRMEditAddressForm/CRMEditAddressForm";
import { CRMParagraph } from "../../Simple/CRMParagraph/CRMParagraph";
import { CRMInfoBoxSimpleColourWithEdit } from "../../Simple/CRMInfoBoxSimpleColourWithEdit/CRMInfoBoxSimpleColourWithEdit";
import { CRMPopoutHeader } from "../../Simple/CRMPopoutHeader/CRMPopoutHeader";
import { CRMBlockPopover } from "../../CRM/CRMBlock/CRMBlockPopover/CRMBlockPopover";
import { WeightBold } from "../../WeightBold/WeightBold";
import { WeightMedium } from "../../WeightMedium/WeightMedium";

export const CRMCompanyPartnerAddressForm = (props: React.PropsWithChildren<{
    form: TClientCompanyPartnershipForm;
    onChange: (form: TClientCompanyPartnershipForm) => void;
    onAutocomplete: (form: TClientCompanyPartnershipForm) => void;
}>): JSX.Element => {
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const address =
        pipe(
            {
                building_name: props.form.edited.address_building_name,
                building_number: props.form.edited.address_building_number,
                sub_building_name: props.form.edited.address_sub_building_name,
                sub_building_number: props.form.edited.address_sub_building_number,
                street_name: props.form.edited.address_street_name,
                postcode: props.form.edited.address_postcode,
                country: props.form.edited.address_country,
                county: props.form.edited.address_county,
                locality: props.form.edited.address_locality,
                district: props.form.edited.address_district,
                city_town: props.form.edited.address_city_town,
            },
            Address3_displayString,
        );

    const isAddressFilled = address !== "";

    const onChange = (address: TAddress3) =>
        props.onChange({
            ...props.form,
            edited: {
                ...props.form.edited,
                address_building_name: address.building_name,
                address_building_number: address.building_number,
                address_sub_building_name: address.sub_building_name,
                address_sub_building_number: address.sub_building_number,
                address_street_name: address.street_name,
                address_postcode: address.postcode,
                address_country: address.country,
                address_county: address.county,
                address_locality: address.locality,
                address_district: address.district,
                address_city_town: address.city_town,
            },
        });

    return <>
        <CRMInfoBoxSimpleColourWithEdit
            fullWidth={false}
            displayError={false}
            onEdit={() => setIsOpen(true)}
        >
            <CRMParagraph>
                {/* DISPLAY ADDRESS */}
                {isAddressFilled &&
                    <WeightBold>
                        {address}
                    </WeightBold>
                }

                {/* NO ADDRESS PLACEHOLDER */}
                {!isAddressFilled &&
                    <WeightMedium>
                        Address not set
                    </WeightMedium>
                }
            </CRMParagraph>
        </CRMInfoBoxSimpleColourWithEdit>

        {/* ADDRESS POPOUT */}
        {isOpen &&
            <CRMBlockPopover
                padding={CRMSpacing.MEDIUM}
                height="720px" 
                maxHeight="720px"
            >
                {/* HEADER */}
                <CRMPopoutHeader
                    icon="arrow-left"
                    onClick={() => setIsOpen(false)}
                >
                    Company Address
                </CRMPopoutHeader>

                {/* ADDRESS FIELDS */}
                <Padding spacing={CRMSpacing.MEDIUM}>
                    <CRMEditAddressForm
                        listing={{
                            building_name: props.form.edited.address_building_name,
                            building_number: props.form.edited.address_building_number,
                            sub_building_name: props.form.edited.address_sub_building_name,
                            sub_building_number: props.form.edited.address_sub_building_number,
                            street_name: props.form.edited.address_street_name,
                            postcode: props.form.edited.address_postcode,
                            country: props.form.edited.address_country,
                            county: props.form.edited.address_county,
                            locality: props.form.edited.address_locality,
                            district: props.form.edited.address_district,
                            city_town: props.form.edited.address_city_town,
                        }}
                        validationErrors={props.form.validationErrors}
                        onChange={onChange}
                        onAutocompleteClicked={() => props.onAutocomplete(props.form)}
                    />
                </Padding>
            </CRMBlockPopover>
        }
    </>;
};
