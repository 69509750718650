import React, { useEffect } from "react";
import { FrontIcon } from "../../Simple/FrontIcon/FrontIcon";

type TFrontOutsidePopupBlank = {
    isOpen: boolean;
    onClose?: () => void;
};

export const FrontOutsidePopupBlank = (props: React.PropsWithChildren<TFrontOutsidePopupBlank>): JSX.Element => {
    
    useEffect(
        () => {
            if (props.isOpen) {
                document.body.style.overflow = "hidden";
            }
            
            if (!props.isOpen) {
                document.body.style.overflow = "auto";
            }
            
            return () => {
                document.body.style.overflow = "auto";
            }
        }, 
        [props.isOpen]
    );

    return (
        <div
            className={`
                front-outside-popup-blank 
                front-outside-popup-blank--${props.isOpen ? 'open' : 'closed'}
            `}
        >
            <div className={`front-outside-popup-blank__box`}>
                {typeof props.onClose === "function" &&
                    <div
                        className="front-outside-popup-blank__icon"
                        onClick={props.onClose}
                    >
                        <FrontIcon
                            iconName="cross-filled"
                            colour="primary-2" 
                        />
                    </div>
                }
                {props.children}
            </div>
        </div>
    );
}
