import { tap, mergeMap } from "rxjs/operators";
import { formOperation } from "../../wrappers/formOperation";
import { TActionsDefinitionsList } from "./TAction";
import { action, routeAction } from "./actionFunctions";

export const actions: TActionsDefinitionsList = [
    routeAction("VIEW_CRM_LISTING_PERFORMANCE", (obs$, lens, setState, getState) => {
        obs$.pipe(
            tap((a) => setState(lens.listing_performance_page.data.input.listing_id.set(a.params.listingId))),
            mergeMap(() => formOperation("GetListingData", lens.listing_performance_page.data.get()(getState()))),
            tap((response) => setState(lens.listing_performance_page.data.set(response))),
        ).subscribe();
    }),
    action("CRM_LISTING_PERFORMANCE_SET_OFFERS_SHARE_DEFFER_DATE", (obs$, lens, setState, getState) => {
        obs$.pipe(
            tap((payload) => setState(lens.listing_performance_page.data.output.form.edited.share_offers_deffered.set(payload))),
            tap(() => setState(lens.listing_performance_page.data.output.form.status.set("submitting"))),
            mergeMap(() => formOperation("UpdateListingFull", lens.listing_performance_page.data.output.form.get()(getState()))),
            tap((response) => setState(lens.listing_performance_page.data.output.form.set(response))),
        ).subscribe();
    }),
];
