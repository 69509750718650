import { tap, mergeMap } from "rxjs/operators";
import { formOperation } from "../../wrappers/formOperation";
import { TActionsDefinitionsList } from "./TAction";
import { routeAction } from "./actionFunctions";

export const actions: TActionsDefinitionsList = [
    routeAction("VIEW_CRM_LEGAL_TITLE_CHECKS", (obs$, lens, set, get) => {
        obs$.pipe(
            mergeMap(() => formOperation("GetTitleChecksPage", lens.title_checks_page.get()(get()))),
            tap((res) => set(lens.title_checks_page.set(res))),
        ).subscribe();
    }),
];
