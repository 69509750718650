import React from "react";
import * as ListingEnquiriesViewing2 from "../../../../domain/models/ListingEnquiriesViewing2";
import * as ViewingStatus1 from "../../../../domain/models/ViewingStatus1";
import { CRMGeneralTextBox } from "../CRMGeneralTextBox/CRMGeneralTextBox";
import { CRMSpacer } from "../CRMSpacer/CRMSpacer";
import { CRMTitleForTextBox } from "../CRMTitleForTextBox/CRMTitleForTextBox";
import { array } from "fp-ts/lib";
import { DateTime } from "luxon";
import { CRMParagraphImportant } from "../Simple/CRMParagraphImportant/CRMParagraphImportant";
import { Uppercase } from "../Uppercase/Uppercase";
import { pipe } from "fp-ts/lib/function";

export class CRMEnquiriesUpcomingViewingsComponent extends React.Component<{
    viewings: Array<ListingEnquiriesViewing2.T>;
}> {
    public render (): JSX.Element {
        return (
            <CRMGeneralTextBox>
                <CRMTitleForTextBox>
                    {ListingEnquiriesViewing2.getSimpleViewingArrayGroupedByDate(this.props.viewings, "asc", "upcoming").length > 0 ? "Upcoming viewings" : "No upcoming viewings"}
                </CRMTitleForTextBox>
                <div className="crm-enquiries-upcoming-viewings-component">
                    { pipe(
                        this.props.viewings,
                        (viewings) => ListingEnquiriesViewing2.getSimpleViewingArrayGroupedByDate(viewings, "asc", "upcoming"),
                        array.mapWithIndex((i, group) =>
                            <div key={i} className="crm-enquiries-upcoming-viewings-component__item">
                                <CRMParagraphImportant>
                                    <Uppercase>
                                        {DateTime.fromISO(group.dateFormattedYMD, { zone: "Europe/London" }).toFormat("cccc d LLLL ")}
                                        ({DateTime.fromISO(group.dateFormattedYMD, { zone: "Europe/London" }).diff(DateTime.local(), "days").days <= 0 ? "Today"
                                        : DateTime.fromISO(group.dateFormattedYMD, { zone: "Europe/London" }).diff(DateTime.local(), "days").days <= 1 ? "TOM."
                                        : `${Math.ceil(DateTime.fromISO(group.dateFormattedYMD, { zone: "Europe/London" }).diff(DateTime.local(), "days").days).toString()}`})
                                    </Uppercase>
                                </CRMParagraphImportant>
                                <CRMSpacer size="tiny" />
                                { pipe(
                                    group.viewings,
                                    array.mapWithIndex((ig, viewing) =>
                                        <CRMParagraphImportant key={ig}>{DateTime.fromJSDate(viewing.date, { zone: "Europe/London" }).toFormat("HH:mm")} - {ViewingStatus1.toDisplayName(viewing.status)}</CRMParagraphImportant>
                                    ),
                                )}
                            </div>
                        ),
                    )}
                </div>
                <CRMSpacer size="medium" />
            </CRMGeneralTextBox>
        );
    }
}
