import React from "react";

export class CRMParagraphImportant extends React.Component<React.PropsWithChildren<{}>> {
    public render (): JSX.Element {
        return (
            <div className="crm-paragraph-important">
                {this.props.children}
            </div>
        );
    }
}
