import React, { useEffect } from "react";
import { TContainerStateProps } from "../../state/TContainerStateProps";
import { CRMCustomerSatisfactionSingleView } from "../../components/Complex/CRMCustomerSatisfactionSingleView/CRMCustomerSatisfactionSingleView";
import { CRMBlock } from "../../components/CRM/CRMBlock/CRMBlock/CRMBlock";


const CRMCaseSatisfactionContainer = (props: TContainerStateProps): JSX.Element => {

    useEffect(
        () => props.dispatch({
            type: "CASE_SATISFACTION_VIEW",
            payload: null
        }),
        []
    );

    return (
        <CRMBlock
            mode="fixed-option"
            fixedOption={{
                icon: "chat-bubble",
                title: "Customer Satisfaction"
            }}
            optionGroups={[]}
        >
            <CRMCustomerSatisfactionSingleView
                form={props.state.forms.case_satisfaction_page}
                onChange={(payload) => props.dispatch({
                    type: "CASE_SATISFACTION_UPDATE_FORM",
                    payload
                })}
                onSubmitNewNote={(payload) => props.dispatch({
                    type: "CASE_SATISFACTION_SUBMIT_NEW_NOTE",
                    payload
                })}
                onToggleNotePin={(payload) => props.dispatch({
                    type: "CASE_SATISFACTION_TOGGLE_NOTE_PIN",
                    payload
                })}
                onOpenEmail={(payload) => props.dispatch({
                    type: "CASE_SATISFACTION_GET_DETAILED_EMAIL",
                    payload
                })}
                onChangeSentiment={(payload) => props.dispatch({
                    type: "CASE_SATISFACTION_CHANGE_SENTIMENT",
                    payload
                })}
                onMarkAsRaisedWithTheObudsman={(payload) => props.dispatch({
                    type: "CASE_SATISFACTION_RAISED_WITH_OMBUDSMAN",
                    payload
                })}
                onMarkRaisedAsAFormalComplaint={(payload) => props.dispatch({
                    type: "CASE_SATISFACTION_FORMALISED_AS_A_COMPLAINT",
                    payload
                })}
                onReAssign={(payload) => props.dispatch({
                    type: "CASE_SATISFACTION_REASSIGNED",
                    payload
                })}
                onDeleteNote={(payload) => props.dispatch({
                    type: "CASE_SATISFACTION_DELETE_NOTE",
                    payload
                })}
                onDeleteSentiment={(payload) => props.dispatch({
                    type: "CASE_SATISFACTION_DELETE_SENTIMENT",
                    payload
                })}
                onDeleteRaisedAsAFormalComplaint={(payload) => props.dispatch({
                    type: "CASE_SATISFACTION_DELETE_FORMALISED_AS_A_COMPLAINT",
                    payload
                })}
                onDeleteRaisedWithOmbudsman={(payload) => props.dispatch({
                    type: "CASE_SATISFACTION_DELETE_RAISED_WITH_OMBUDSMAN",
                    payload
                })}
                onDefer={(payload) => props.dispatch({
                    type: "CASE_SATISFACTION_DEFER",
                    payload,
                })}
            />
        </CRMBlock>
    );
}


export default CRMCaseSatisfactionContainer;