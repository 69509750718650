import React from "react";
import { TCaseContractSectionFormUserJointOwnershipHeldAsPreferences } from "../../../../../domain/codecs/form/CaseContractSectionForm";
import { SpacingColumn } from "../../BuildingBlocks/SpacingColumn";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { Background } from "../../BuildingBlocks/Background";
import { CRMColors } from "../../../models/CRMColors";
import { FontQuicksand } from "../../BuildingBlocks/FontQuicksand";
import { Text } from "../../BuildingBlocks/Text";
import { CRMFontSizes } from "../../../models/CRMFontSizes";
import { WeightBold } from "../../WeightBold/WeightBold";
import { TJointOwnershipHeldAsAnsweredType } from "../../../../../domain/codecs/CasesContractBlock";
import { snakeCaseToCopyText } from "../../../../../shared/src/util";
import { pipe } from "fp-ts/lib/function";
import { array, option } from "fp-ts";
import { CRMParagraph } from "../../Simple/CRMParagraph/CRMParagraph";
import { WeightSemiBold } from "../../WeightSemiBold/WeightSemiBold";
import { SimpleGrid } from "../../BuildingBlocks/SimpleGrid";
import { CRMTextBadge } from "../../CRMTextBadge/CRMTextBadge";

export const CRMJointOwnershipHeldAsUserPreferences = (props: React.PropsWithChildren<{
    preferences: TCaseContractSectionFormUserJointOwnershipHeldAsPreferences,
}>): JSX.Element => {
    const status =
        props.preferences.all_clients_agree && props.preferences.all_clients_have_answered && props.preferences.answers.length === 0 ? "agreed_not_applicable"
        : props.preferences.all_clients_agree && props.preferences.all_clients_have_answered ? "agreed"
        : props.preferences.all_clients_agree === false && props.preferences.all_clients_have_answered === false ? "not_agreed_and_not_answered"
        : props.preferences.all_clients_agree === false ? "not_agreed"
        : "not_answered";

    const agreedType: TJointOwnershipHeldAsAnsweredType =
        pipe(
            props.preferences.answers,
            array.head,
            option.fold(
                () => "joint_tenants",
                ({preference}) => preference,
            )
        );

    const hasSomeAnswers =
        props.preferences.answers.length > 0;

    const shouldShowIndividualPreferences =
        status !== "agreed"
        && hasSomeAnswers;

    return <SpacingColumn spacing={CRMSpacing.MEDIUM}>
        {/* STATUS - AGREED */}
        {status === "agreed" &&
            <AgreedStatus
                type={agreedType}
            />
        }

        {/* STATUS - AGREED NOT APPLICABLE */}
        {status === "agreed_not_applicable" &&
            <AgreedNotApplicableStatus />
        }

        {/* STATUS - NOT AGREED AND NOT ANSWERED */}
        {status === "not_agreed_and_not_answered" &&
            <NotAgreedAndNotAnsweredStatus />
        }

        {/* STATUS - NOT AGREED */}
        {status === "not_agreed" &&
            <NotAgreedStatus />
        }

        {/* STATUS - NOT ANSWERED */}
        {status === "not_answered" &&
            <NotAnsweredStatus />
        }

        {/* INDIVIDUAL PREFERENCES */}
        {shouldShowIndividualPreferences &&
            <>
                {/* TITLE */}
                <CRMParagraph fontSize={CRMFontSizes.SMALL}>
                    <WeightSemiBold>
                        Each clients ownership preference:
                    </WeightSemiBold>
                </CRMParagraph>

                {/* PEOPLE */}
                <SimpleGrid
                    templateColumns="1fr 1fr"
                    columnSpacing={CRMSpacing.MEDIUM}
                    rowSpacing={CRMSpacing.MEDIUM}
                >
                    {props.preferences.answers.map((answer) =>
                        <CRMTextBadge
                            key={answer.id}
                            badgeText={answer.name.trim().slice(0, 1).toUpperCase()}
                            badgeColor={CRMColors.NEUTRAL_8}
                            sideLabel={{
                                firstRow: answer.name,
                                secondRow: snakeCaseToCopyText(answer.preference),
                            }}
                        />
                    )}
                </SimpleGrid>
            </>
        }
    </SpacingColumn>
};

const AgreedStatus = (props: {type: TJointOwnershipHeldAsAnsweredType}) => {
    const typeDisplayString = snakeCaseToCopyText(props.type);

    return <Background
        display="inline-flex" 
        color={CRMColors.HIGHLIGHTS_POSITIVE_GREEN_12}
        borderRadius="5px"
        padding={CRMSpacing.SMALL}
        width="300px"
    >
        <FontQuicksand>
            <SpacingColumn spacing={CRMSpacing.TINY}>
                {/* HEADING */}
                <Text
                    size={CRMFontSizes.MED}
                    lineHeight={1.4}
                    weight={600}
                >
                    Ownership agreed
                </Text>

                {/* BODY */}
                <Text
                    size={CRMFontSizes.SMALL}
                    lineHeight={1.4}
                    weight={500}
                >
                    All clients have agreed to hold the property: <WeightBold>{typeDisplayString}.</WeightBold>
                </Text>
            </SpacingColumn>
        </FontQuicksand>
    </Background>;
};

const AgreedNotApplicableStatus = () =>
    <Background
        display="inline-flex" 
        color={CRMColors.HIGHLIGHTS_POSITIVE_GREEN_12}
        borderRadius="5px"
        padding={CRMSpacing.SMALL}
        width="300px"
    >
        <FontQuicksand>
            <SpacingColumn spacing={CRMSpacing.TINY}>
                {/* HEADING */}
                <Text
                    size={CRMFontSizes.MED}
                    lineHeight={1.4}
                    weight={600}
                >
                    Ownership agreed
                </Text>

                {/* BODY */}
                <Text
                    size={CRMFontSizes.SMALL}
                    lineHeight={1.4}
                    weight={500}
                >
                    All clients have stated this question is <WeightBold>not applicable.</WeightBold>
                </Text>
            </SpacingColumn>
        </FontQuicksand>
    </Background>;

const NotAgreedStatus = () =>
    <Background
        display="inline-flex" 
        color={CRMColors.HIGHLIGHTS_INSTRUCTIONAL_BRICK_10}
        borderRadius="5px"
        padding={CRMSpacing.SMALL}
        width="300px"
    >
        <FontQuicksand>
            <SpacingColumn spacing={CRMSpacing.TINY}>
                {/* HEADING */}
                <Text
                    size={CRMFontSizes.MED}
                    lineHeight={1.4}
                    weight={600}
                >
                    Ownership not agreed
                </Text>

                {/* BODY */}
                <Text
                    size={CRMFontSizes.SMALL}
                    lineHeight={1.4}
                    weight={500}
                >
                    Clients have not agreed how the property should be held.
                </Text>
            </SpacingColumn>
        </FontQuicksand>
    </Background>;

const NotAnsweredStatus = () =>
    <Background
        display="inline-flex" 
        color={CRMColors.HIGHLIGHTS_INSTRUCTIONAL_BRICK_10}
        borderRadius="5px"
        padding={CRMSpacing.SMALL}
        width="300px"
    >
        <FontQuicksand>
            <SpacingColumn spacing={CRMSpacing.TINY}>
                {/* HEADING */}
                <Text
                    size={CRMFontSizes.MED}
                    lineHeight={1.4}
                    weight={600}
                >
                    Ownership not agreed
                </Text>

                {/* BODY */}
                <Text
                    size={CRMFontSizes.SMALL}
                    lineHeight={1.4}
                    weight={500}
                >
                    Not all clients have given their ownership preference.
                </Text>
            </SpacingColumn>
        </FontQuicksand>
    </Background>;

const NotAgreedAndNotAnsweredStatus = () =>
    <Background
        display="inline-flex" 
        color={CRMColors.HIGHLIGHTS_INSTRUCTIONAL_BRICK_10}
        borderRadius="5px"
        padding={CRMSpacing.SMALL}
        width="300px"
    >
        <FontQuicksand>
            <SpacingColumn spacing={CRMSpacing.TINY}>
                {/* HEADING */}
                <Text
                    size={CRMFontSizes.MED}
                    lineHeight={1.4}
                    weight={600}
                >
                    Ownership not agreed
                </Text>

                {/* BODY */}
                <Text
                    size={CRMFontSizes.SMALL}
                    lineHeight={1.4}
                    weight={500}
                >
                    Not all clients have answered and the ones that have do not agree how the property should be held.
                </Text>
            </SpacingColumn>
        </FontQuicksand>
    </Background>;
