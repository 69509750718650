import React, { useState } from "react";
import { SpacingColumn } from "../../components/BuildingBlocks/SpacingColumn";
import { FrontQuestionFormCard } from "../../components/Front/Complex/FrontQuestionFormCard/FrontQuestionFormCard";
import FrontParagraphSmall from "../../components/Front/Simple/FrontParagraphSmall/FrontParagraphSmall";
import { TContainerStateProps } from "../../state/TContainerStateProps";
import { FrontSpacing } from "../../models/FrontSpacing";
import { WeightBold } from "../../components/WeightBold/WeightBold";
import { TOnboardingTabStatus } from "../../components/Front/Simple/FrontOnboardingTab/FrontOnboardingTab";
import { ClientCaseDeclarationOfTrustReferralForm } from "../../../../domain/codecs/form/ClientCaseForm";
import { WeightSemiBold } from "../../components/WeightSemiBold/WeightSemiBold";
import { WeightMedium } from "../../components/WeightMedium/WeightMedium";
import { FrontCondensedQuestionWrap } from "../../components/Front/Simple/FrontCondensedQuestionWrap/FrontCondensedQuestionWrap";
import { FrontInfoBubble } from "../../components/Front/Simple/FrontInfoBubble/FrontInfoBubble";
import { FrontColors } from "../../models/FrontColors";
import { FrontBulletpoint } from "../../components/Front/Simple/FrontBulletpoint/FrontBulletpoint";
import { FrontHelpBubble } from "../../components/Front/Simple/FrontHelpBubble/FrontHelpBubble";
import FrontInputLabelAndErrorWrapComponent from "../../components/Front/Simple/FrontInputLabelAndErrorWrapComponent/FrontInputLabelAndErrorWrapComponent";
import { FrontRadioList } from "../../components/Front/Simple/FrontRadioList/FrontRadioList";
import { MediaCompSwitch } from "../../components/BuildingBlocks/MediaCompSwitch";
import { SpacingRow } from "../../components/BuildingBlocks/SpacingRow";
import { FrontFormPrimaryButton } from "../../components/Front/Simple/FrontFormPrimaryButton/FrontFormPrimaryButton";
import { FrontPopupPrompt } from "../../components/Front/Complex/FrontPopupPrompt/FrontPopupPrompt";
import FrontParagraphRegular from "../../components/Front/Simple/FrontParagraphRegular/FrontParagraphRegular";

const FrontDeclarationOfTrustReferralContainer = (props: TContainerStateProps): JSX.Element => {
    const form = props.state.forms.client_case_page.children.declaration_of_trust_referral?.form || ClientCaseDeclarationOfTrustReferralForm.newDefault();

    const isStepComplete =
        typeof props.state.forms.client_case_page.children.declaration_of_trust_referral !== "undefined"
            ? props.state.forms.client_case_page.children.declaration_of_trust_referral.is_step_complete
            : true;

    const sessionUserCaseUserType = props.state.forms.client_case_page.children.session_user_case_user_type.user_type;

    const isGiftorView =
        !isStepComplete
        && sessionUserCaseUserType === "giftor";

    const isEqualSharesView =
        !isStepComplete
        && !isGiftorView
        && form.children.joint_ownership_held_as_preference === "tenants_in_common_in_equal_shares";

    const isDifferentSharesView =
        !isStepComplete
        && !isGiftorView
        && form.children.joint_ownership_held_as_preference === "on_trust";

    const [displayValidationErrorsIfPresent, setDisplayValidationErrorsIfPresent] = useState<boolean>(false);

    const [questionIsOpen, setQuestionIsOpen] = useState<boolean>(true);

    const isFormReadyToSubmit =
        form.edited.refer_to_sail_wills_for_declaration_of_trust !== null;

    const onChange = (value: boolean) => {
        setQuestionIsOpen(false);
        props.dispatch({
            type: "CLIENT_DECLARATION_OF_TRUST_REFERRAL_CHANGE",
            payload: {
                ...form,
                edited: {
                    ...form.edited,
                    refer_to_sail_wills_for_declaration_of_trust: value,
                },
            },
        });
    };

    const onSubmit = () =>
        props.dispatch({
            type: "CLIENT_DECLARATION_OF_TRUST_REFERRAL_SUBMIT",
        });

    return <>
        {/* GIFTOR */}
        {isGiftorView &&
            <GiftorView
                isOpen={questionIsOpen}
                value={form.edited.refer_to_sail_wills_for_declaration_of_trust}
                displayValidationErrorsIfPresent={displayValidationErrorsIfPresent}
                isFormReadyToSubmit={isFormReadyToSubmit}
                onChange={onChange}
                onSubmit={onSubmit}
                setIsOpen={setQuestionIsOpen}
                setDisplayValidationErrorsIfPresent={setDisplayValidationErrorsIfPresent}
            />
        }

        {/* EQUAL SHARES VIEW */}
        {isEqualSharesView &&
            <EqualSharesView
                isOpen={questionIsOpen}
                value={form.edited.refer_to_sail_wills_for_declaration_of_trust}
                displayValidationErrorsIfPresent={displayValidationErrorsIfPresent}
                isFormReadyToSubmit={isFormReadyToSubmit}
                onChange={onChange}
                onSubmit={onSubmit}
                setIsOpen={setQuestionIsOpen}
                setDisplayValidationErrorsIfPresent={setDisplayValidationErrorsIfPresent}
            />
        }

        {/* DIFFERENT SHARES */}
        {isDifferentSharesView &&
            <DifferentSharesView
                isOpen={questionIsOpen}
                value={form.edited.refer_to_sail_wills_for_declaration_of_trust}
                displayValidationErrorsIfPresent={displayValidationErrorsIfPresent}
                isFormReadyToSubmit={isFormReadyToSubmit}
                onChange={onChange}
                onSubmit={onSubmit}
                setIsOpen={setQuestionIsOpen}
                setDisplayValidationErrorsIfPresent={setDisplayValidationErrorsIfPresent}
            />
        }

        {/* STEP COMPLETE */}
        {isStepComplete &&
            <FrontQuestionFormCard
                title="Declaration of Trust Protection"
            >
                <FrontParagraphSmall>
                    Thank you, if you've asked us to get in touch we will do so. <WeightSemiBold>If you need to talk to Sail Wills now you can call on 0808 196 7498.</WeightSemiBold>
                </FrontParagraphSmall>
            </FrontQuestionFormCard>
        }
    </>
};

const GiftorView = (props: {
    isOpen: boolean
    value: boolean | null,
    displayValidationErrorsIfPresent: boolean,
    isFormReadyToSubmit: boolean,
    onChange: (value: boolean) => void,
    onSubmit: () => void,
    setIsOpen: (value: boolean) => void,
    setDisplayValidationErrorsIfPresent: (value: boolean) => void
}) =>
    <FrontQuestionFormCard
        title="Protect the money you are gifting"
    >
        <SpacingColumn spacing={FrontSpacing.MEDIUM_2}>
            <SpacingColumn spacing={FrontSpacing.SMALL_2}>
                <FrontParagraphSmall>
                    It's a wonderful gift being able to help family, friends and loved ones own property. Still,&nbsp;
                    <WeightSemiBold>you need to make sure you're protecting the money you've gifted.</WeightSemiBold>
                </FrontParagraphSmall>

                <SpacingColumn spacing={FrontSpacing.SMALL_1}>
                    <FrontParagraphSmall>
                        <WeightSemiBold>To do this we can create a Declaration of Trust.</WeightSemiBold>&nbsp;
                        This is a legal document which shows <WeightSemiBold>how you've gifted money, what they can do with the money and how to pay you back.</WeightSemiBold>&nbsp;
                        This is a lifeline when:
                    </FrontParagraphSmall>

                    {/* BULLETS */}
                    <FrontParagraphSmall>
                        <SpacingColumn spacing={FrontSpacing.MINIMUM}>
                            <FrontBulletpoint pointAdjust="11px"><WeightMedium>They sell the property. You might want to state that they should pay you back?</WeightMedium></FrontBulletpoint>
                            <FrontBulletpoint pointAdjust="11px"><WeightMedium>If the relationship between the owners breaks down. You may not want everyone to continue benefiting from your gift.</WeightMedium></FrontBulletpoint>
                            <FrontBulletpoint pointAdjust="11px"><WeightMedium>You die and you want to reflect this gift in your Will.</WeightMedium></FrontBulletpoint>
                        </SpacingColumn>
                    </FrontParagraphSmall>
                </SpacingColumn>

                <FrontParagraphSmall>
                    Don't leave the above to chance, they often end in disputes. A Declaration of Trust keeps everyone on the same page.
                </FrontParagraphSmall>
            </SpacingColumn>

            <SpacingColumn spacing={FrontSpacing.MEDIUM_1}>
                {/* QUESTION */}
                <FrontCondensedQuestionWrap
                    isOpen={props.isOpen}
                    label="Would you like to speak with someone from Sail Wills who can discuss this in more detail"
                    summary={props.value ? "Yes" : "No"}
                    setIsOpen={props.setIsOpen}
                >
                    <FrontInputLabelAndErrorWrapComponent label={<WeightMedium>Would you like to speak with someone from Sail Wills who can discuss this in more detail? (this consultation is <WeightBold>free</WeightBold>)</WeightMedium>}>
                        <FrontRadioList
                            value={props.value}
                            options={[
                                {
                                    value: true,
                                    label: "Yes",
                                },
                                {
                                    value: false,
                                    label: "No",
                                },
                            ]}
                            onChange={props.onChange}
                        />
                    </FrontInputLabelAndErrorWrapComponent>
                </FrontCondensedQuestionWrap>

                {/* WARNING */}
                {props.value === false &&
                    <FrontHelpBubble
                        type="info"
                        label="Are you sure?"
                        paragraph={<SpacingColumn spacing={FrontSpacing.SMALL_2}>
                            <WeightSemiBold>
                                In this situation it's advised to have a Declaration of Trust.
                            </WeightSemiBold>
                            
                            <WeightMedium>
                                Sail Wills can provide this service for a competitive fee. <WeightBold>All consultation is free</WeightBold> and you only pay for the final document.
                            </WeightMedium>
                        </SpacingColumn>}
                    />
                }
            </SpacingColumn>

            {/* FOOTER */}
            <Footer {...props}/>
        </SpacingColumn>
    </FrontQuestionFormCard>;

const EqualSharesView = (props: {
    isOpen: boolean
    value: boolean | null,
    displayValidationErrorsIfPresent: boolean,
    isFormReadyToSubmit: boolean,
    onChange: (value: boolean) => void,
    onSubmit: () => void,
    setIsOpen: (value: boolean) => void,
    setDisplayValidationErrorsIfPresent: (value: boolean) => void
}) =>
    <FrontQuestionFormCard
        title="Declaration of Trust Protection"
    >
        <SpacingColumn spacing={FrontSpacing.MEDIUM_2}>
            <SpacingColumn spacing={FrontSpacing.SMALL_2}>
                <FrontParagraphSmall>
                    You've told us that you want to hold the property as tenants in common.&nbsp;
                    <WeightSemiBold>This is a good choice if you don't want to pass your share to the other owners when you die.</WeightSemiBold>
                </FrontParagraphSmall>

                <SpacingColumn spacing={FrontSpacing.SMALL_1}>
                    <FrontParagraphSmall>
                        <WeightSemiBold>It may also be a good idea to have a Declaration of Trust written.</WeightSemiBold>&nbsp;
                        This is a legal document that <WeightSemiBold>protects the money you have put into the property.</WeightSemiBold> This document will show:
                    </FrontParagraphSmall>

                    {/* BULLETS */}
                    <FrontParagraphSmall>
                        <SpacingColumn spacing={FrontSpacing.MINIMUM}>
                            <FrontBulletpoint pointAdjust="11px"><WeightMedium>Who has added more to the deposit for the property and how to pay them back when selling.</WeightMedium></FrontBulletpoint>
                            <FrontBulletpoint pointAdjust="11px"><WeightMedium>Who is helping with the monthly mortgage payments and how to pay them back.</WeightMedium></FrontBulletpoint>
                            <FrontBulletpoint pointAdjust="11px"><WeightMedium>Who has gifted money.</WeightMedium></FrontBulletpoint>
                        </SpacingColumn>
                    </FrontParagraphSmall>
                </SpacingColumn>
            </SpacingColumn>

            <SpacingColumn spacing={FrontSpacing.MEDIUM_1}>
                {/* QUESTION */}
                <FrontCondensedQuestionWrap
                    isOpen={props.isOpen}
                    label="Would you like to speak with someone from Sail Wills who can discuss this in more detail"
                    summary={props.value ? "Yes" : "No"}
                    setIsOpen={props.setIsOpen}
                >
                    <FrontInputLabelAndErrorWrapComponent label={<WeightMedium>Would you like to speak with someone from Sail Wills who can discuss this in more detail? (this consultation is <WeightBold>free</WeightBold>)</WeightMedium>}>
                        <FrontRadioList
                            value={props.value}
                            options={[
                                {
                                    value: true,
                                    label: "Yes",
                                },
                                {
                                    value: false,
                                    label: "No",
                                },
                            ]}
                            onChange={props.onChange}
                        />
                    </FrontInputLabelAndErrorWrapComponent>
                </FrontCondensedQuestionWrap>
            </SpacingColumn>

            {/* FOOTER */}
            <Footer {...props}/>
        </SpacingColumn>
    </FrontQuestionFormCard>;

const DifferentSharesView = (props: {
    isOpen: boolean
    value: boolean | null,
    displayValidationErrorsIfPresent: boolean,
    isFormReadyToSubmit: boolean,
    onChange: (value: boolean) => void,
    onSubmit: () => void,
    setIsOpen: (value: boolean) => void,
    setDisplayValidationErrorsIfPresent: (value: boolean) => void
}) =>
    <FrontQuestionFormCard
        title="Declaration of Trust Protection"
    >
        <SpacingColumn spacing={FrontSpacing.MEDIUM_2}>
            <SpacingColumn spacing={FrontSpacing.SMALL_2}>
                <FrontParagraphSmall>
                    You've told us that you want to hold the property as tenants in common.&nbsp;
                    <WeightSemiBold>This is a good choice if you don't want to pass your share to the other owners when you die.</WeightSemiBold>
                </FrontParagraphSmall>

                <SpacingColumn spacing={FrontSpacing.SMALL_1}>
                    <FrontParagraphSmall>
                        <WeightSemiBold>We now need to write a Declaration of Trust for you.</WeightSemiBold>&nbsp;
                        This is a legal document that <WeightSemiBold>protects the money you have put into the property.</WeightSemiBold> This document will show:
                    </FrontParagraphSmall>

                    {/* BULLETS */}
                    <FrontParagraphSmall>
                        <SpacingColumn spacing={FrontSpacing.MINIMUM}>
                            <FrontBulletpoint pointAdjust="11px"><WeightMedium>The percentage share each of you have in the property.</WeightMedium></FrontBulletpoint>
                            <FrontBulletpoint pointAdjust="11px"><WeightMedium>Who has added more to the deposit for the property and how to pay them back when selling.</WeightMedium></FrontBulletpoint>
                            <FrontBulletpoint pointAdjust="11px"><WeightMedium>Who is helping with the monthly mortgage payments and how to pay them back.</WeightMedium></FrontBulletpoint>
                            <FrontBulletpoint pointAdjust="11px"><WeightMedium>Who has gifted money.</WeightMedium></FrontBulletpoint>
                        </SpacingColumn>
                    </FrontParagraphSmall>
                </SpacingColumn>
            </SpacingColumn>

            <SpacingColumn spacing={FrontSpacing.MEDIUM_1}>
                {/* QUESTION */}
                <FrontCondensedQuestionWrap
                    isOpen={props.isOpen}
                    label="Would you like to speak with someone from Sail Wills who can discuss this in more detail"
                    summary={props.value ? "Yes" : "No"}
                    setIsOpen={props.setIsOpen}
                >
                    <FrontInputLabelAndErrorWrapComponent label={<WeightMedium>Are you happy for Sail Wills to get in touch to prepare your Declaration of Trust? (this consultation is <WeightBold>free</WeightBold>)</WeightMedium>}>
                        <FrontRadioList
                            value={props.value}
                            options={[
                                {
                                    value: true,
                                    label: "Yes",
                                },
                                {
                                    value: false,
                                    label: "No",
                                },
                            ]}
                            onChange={props.onChange}
                        />
                    </FrontInputLabelAndErrorWrapComponent>
                </FrontCondensedQuestionWrap>

                {/* WARNING */}
                {props.value === false &&
                    <FrontHelpBubble
                        type="info"
                        label="Are you sure?"
                        paragraph={<SpacingColumn spacing={FrontSpacing.SMALL_2}>
                            <WeightMedium>
                                <WeightSemiBold>In these circumstances it's required to have a Declaration of Trust.</WeightSemiBold>&nbsp;
                                Without one it could be disastrous for you when you sell the property or for your loved ones who would inherit.
                            </WeightMedium>

                            <WeightMedium>
                                Sail Wills can provide this service for a competitive fee. <WeightBold>All consultation is free</WeightBold> and you only pay for the final document.
                            </WeightMedium>
                            
                            <WeightMedium>
                                If you choose to continue without Sail Wills we will assume you are making other arrangements.
                            </WeightMedium>
                        </SpacingColumn>}
                    />
                }
            </SpacingColumn>

            {/* FOOTER */}
            <Footer {...props}/>
        </SpacingColumn>
    </FrontQuestionFormCard>;

const Footer = (props: {
    value: boolean | null,
    displayValidationErrorsIfPresent: boolean,
    isFormReadyToSubmit: boolean,
    onSubmit: () => void,
    setDisplayValidationErrorsIfPresent: (value: boolean) => void
}) => {
    const [popupIsOpen, setPopupIsOpen] = useState<boolean>(false);

    return <>
        <SpacingColumn spacing={FrontSpacing.MEDIUM_1}>
            {/* CONTROL */}
            <MediaCompSwitch
                DesktopComponent={SpacingRow}
                desktopProps={{ spacing: FrontSpacing.SMALL_3}}
                MobileComponent={SpacingColumn}
                mobileProps={{ spacing: FrontSpacing.SMALL_3 }}
                breakpoint="600"
            >
                <FrontFormPrimaryButton
                    label="Continue"
                    icon="done"
                    isDisabled={false}
                    onClick={() =>
                        props.isFormReadyToSubmit && props.value ? setPopupIsOpen(true)
                        : props.isFormReadyToSubmit && !props.value ? props.onSubmit()
                        : props.setDisplayValidationErrorsIfPresent(true)
                    }
                />
            </MediaCompSwitch>

            {/* ERRORS */}
            {props.displayValidationErrorsIfPresent && !props.isFormReadyToSubmit && <FrontInfoBubble color={FrontColors.ERROR_FIREBRICK_22}>
                <WeightBold>You've missed a few things above,</WeightBold> please take a look and then try again.
            </FrontInfoBubble>}
        </SpacingColumn>
        
        {/* POPUP */}
        <FrontPopupPrompt
            isOpen={popupIsOpen}
            title="We'll call you"
            ctaText="Ok"
            onCTA={props.onSubmit}
        >
            <SpacingColumn spacing={FrontSpacing.SMALL_3}>
                <FrontParagraphRegular>
                    <WeightBold>Sail Wills will give you a call over the next few days to discuss this with you.</WeightBold>
                </FrontParagraphRegular>
                <FrontParagraphRegular>
                    If you need to call them now you can do so on 0808 196 7498.
                </FrontParagraphRegular>
            </SpacingColumn>
        </FrontPopupPrompt>
    </>;
}

export const getDeclarationOfTrustReferralStatus = (props: TContainerStateProps): TOnboardingTabStatus => {
    if (props.state.forms.client_case_page.children.declaration_of_trust_referral?.is_step_complete) {
        return "completed";
    }
    
    return "active";
};

export default FrontDeclarationOfTrustReferralContainer;

