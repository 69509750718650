import React from "react";
import { Button } from "../../components/OldFront/Simple/ButtonComponent/Button";
import Heading2 from "../../components/OldFront/Simple/Heading2Component/Heading2";
import Page from "../../components/PageComponent/Page";
import Heading4 from "../../components/OldFront/Simple/Heading4Component/Heading4";
import Heading5 from "../../components/OldFront/Simple/Heading5Component/Heading5";
import Heading6 from "../../components/OldFront/Simple/Heading6Component/Heading6";
import ContentContainer from "../../components/OldFront/Simple/ContentContainerComponent/ContentContainer";
import Map from "../../components/OldFront/Complex/MapComponent/Map";
import Amenities from "../../components/OldFront/Complex/AmenitiesComponent/Amenities";
import SafeHtmlRender from "../../components/SafeHtmlRenderComponent/SafeHtmlRender";
import Documents from "../../components/DocumentsComponent/Documents";
import Card from "../../components/OldFront/Simple/CardComponent/Card";
import * as DisplayAddress from "../../../../domain/models/DisplayAddress";
import * as MapAddress from "../../../../domain/models/MapAddress";
import * as FirstPartyFetchResponse from "../../../../domain/models/FirstPartyFetchResponse";
import { pipe } from "fp-ts/lib/function";
import { array } from "fp-ts/lib";
import { IImages, ImageGallery } from "../../components/ImageGalleryComponent/ImageGallery";
import AnchorButton from "../../components/OldFront/Simple/AnchorButton/AnchorButton";
import { sailHomesBrandConstants } from "../../../../domain/constants";
import * as ListingImage1 from "../../../../domain/models/ListingImage1";
import * as TCurrency from "../../../../domain/models/TCurrency";
import * as BookViewingModal from "../../containers/ListingContainer/BookViewingModal";
import * as BookViewingModalAdditionalQuestions from "../../containers/ListingContainer/BookViewingModalAdditionalQuestions";
import * as BookViewingModalSuccess from "../../containers/ListingContainer/BookViewingModalSuccess";
import { FrontHeader } from "../../components/Front/Simple/FrontHeader/FrontHeader";
import * as TContainerStateProps from "../../state/TContainerStateProps";
import { createChangeRouteAction } from "../../state/router/createChangeRouteAction";

interface IListingPageState {
    bookingWidgetVisible: boolean;
}

export interface IListingPageProps extends TContainerStateProps.TContainerStateProps {
    bookViewingModalOpen: boolean;
}

class ListingPage extends React.Component<React.PropsWithChildren<IListingPageProps>, IListingPageState> {

    constructor(props: IListingPageProps) {
        super(props);
        this.state = {
            bookingWidgetVisible: false,
        };
    }

    public componentDidUpdate(): void {
        document.title = this.props.state.activeData.listing.listingResponse.tag === "2XX"
            ? `For Sale - ${pipe(DisplayAddress.fromListing6(this.props.state.activeData.listing.listingResponse.response.data), DisplayAddress.toString)}`
            : "For Sale - Properties in your area";
    }

    public render (): JSX.Element {
        return this.props.state.activeData.listing.listingResponse.tag === FirstPartyFetchResponse.constants.STATUS_2XX ? (
            <Page
                showLogout={false}
            >
                {/* MODAL: BOOK VIEWING  */}
                {this.props.bookViewingModalOpen &&
                    <div className="listing__book-viewing-modal-container">
                        {this.props.state.routes.active === "VIEW_LISTING_BOOK_VIEWING" &&
                            BookViewingModal.render(this.props, this.props.state.activeData.listing.listingResponse.response.data)
                        }
                        {this.props.state.routes.active === "VIEW_LISTING_BOOK_VIEWING_ADDITIONAL_QUESTIONS" &&
                            BookViewingModalAdditionalQuestions.render(this.props, this.props.state.activeData.listing.listingResponse.response.data)
                        }
                        {this.props.state.routes.active === "VIEW_LISTING_BOOK_VIEWING_SUCCESS" &&
                            BookViewingModalSuccess.render(this.props, this.props.state.activeData.listing.listingResponse.response.data)
                        }
                    </div>
                }

                <div className={
                        "listing__page-section" +
                        (this.props.bookViewingModalOpen ? " listing__page-section--hidden" : "")
                    }
                    style={this.props.bookViewingModalOpen
                        ? {
                            top: `-${window.scrollY}"px"`,
                        } : {
                            top: "",
                        }
                    }
                >
                    <FrontHeader>
                        {pipe(
                            DisplayAddress.fromListing6(this.props.state.activeData.listing.listingResponse.response.data),
                            DisplayAddress.toShortString,
                        )}
                    </FrontHeader>
                    <div className="listing__top-grid-section">
                        <ContentContainer className="listing__top-grid-container">
                            <ContentContainer className="listing__price-container">
                                <Heading2 text={TCurrency.fromNumber(
                                    this.props.state.activeData.listing.listingResponse.response.data.price
                                    ? this.props.state.activeData.listing.listingResponse.response.data.price
                                    : 0 )}/>
                                <Heading4 text={
                                    this.props.state.activeData.listing.listingResponse.response.data.tenure === "freehold" ? "Freehold Property"
                                    : this.props.state.activeData.listing.listingResponse.response.data.tenure === "leasehold" ? "Leasehold Property"
                                    : ""
                                }/>
                            </ContentContainer>
                            {this.props.state.activeData.listing.listingResponse.response.data.images.length > 0 ? (
                                <ContentContainer className="listing__images-container">
                                    <div className="listing__image-gallery-container-aspect-ratio-setter">
                                        <div className="listing__image-gallery-container-position-setter">
                                            <ImageGallery
                                                images={array.map<ListingImage1.T, IImages>((image) => ({
                                                    src: image.url,
                                                    alt: image.name,
                                                }))(this.props.state.activeData.listing.listingResponse.response.data.images)}
                                                showCarousel={true}
                                            />
                                        </div>
                                    </div>
                                </ContentContainer>
                            ) : ""}
                            <ContentContainer className="listing__sidebar-container">
                                <div className="listing__cta">
                                    <Button
                                        label="Book a viewing"
                                        type="rooster"
                                        disabled={false}
                                        onClick={() => this.props.dispatch(createChangeRouteAction("VIEW_LISTING_BOOK_VIEWING", { listingId: this.props.state.routes.params.listingId }, {}))}
                                        fullWidth={true}
                                    />
                                    <AnchorButton
                                        label="Make an offer"
                                        type="otter"
                                        disabled={false}
                                        url={sailHomesBrandConstants("web").contact.phoneURL}
                                        fullWidth={true}
                                    />
                                </div>
                            </ContentContainer>
                        </ContentContainer>
                    </div>
                    <div className="listing__body-section">
                        <ContentContainer className="listing__body-container">
                            <Card>
                                <div>
                                    <div className="listing__detail-container">
                                        {/* Description */}
                                        <Heading4 text="Description"/>
                                        <div className="listing__detail-text-container">
                                            <SafeHtmlRender html={this.props.state.activeData.listing.listingResponse.response.data.description}/>
                                        </div>
                                    </div>
                                    {this.props.state.activeData.listing.listingResponse.response.data.documents.length > 0 ? (
                                        <div className="listing__detail-container">
                                            {/* Documents */}
                                            <Heading4 text="Documents"/>
                                            <Documents
                                                data={this.props.state.activeData.listing.listingResponse.response.data.documents}
                                                address={DisplayAddress.toBuildingAndStreet(DisplayAddress.fromListing6(this.props.state.activeData.listing.listingResponse.response.data))}
                                            />
                                        </div>
                                    ) : ""}
                                    {this.props.state.activeData.listing.listingResponse.response.data.amenities.length > 0 ? (
                                        <div className="listing__detail-container">
                                            {/* Amenities */}
                                            <Heading4 text="Nearby Amenities" />
                                            <Amenities
                                                data={this.props.state.activeData.listing.listingResponse.response.data.amenities}
                                                address={DisplayAddress.toBuildingAndStreet(DisplayAddress.fromListing6(this.props.state.activeData.listing.listingResponse.response.data))}
                                            />
                                        </div>
                                    ) : ""}
                                </div>
                            </Card>
                        </ContentContainer>
                    </div>
                    <div className="listing__map-section">
                        <ContentContainer className="listing__map-container">
                            <div className="listing__map-heading">
                                <Heading4 text="Property Location" />
                            </div>
                            <div className="listing__map-frame">
                                <Map
                                    address={MapAddress.fromListing6(this.props.state.activeData.listing.listingResponse.response.data)}
                                    latLng={
                                        this.props.state.activeData.listing.listingResponse.response.data.latitude
                                        && this.props.state.activeData.listing.listingResponse.response.data.longitude
                                        ? [
                                            this.props.state.activeData.listing.listingResponse.response.data.latitude,
                                            this.props.state.activeData.listing.listingResponse.response.data.longitude,
                                        ] :
                                        undefined
                                    }
                                />
                            </div>
                        </ContentContainer>
                    </div>
                    <div className="listing__cta2-section">
                        <ContentContainer className="listing__cta2-container">
                            {/* CTA 2 */}
                            <div className="listing__cta2-words">
                                <div className="listing__cta2-main-heading-container">
                                    <div className="listing__cta2-heading-container">
                                        <Heading5 text="Love this property?" />
                                    </div>
                                    <div className="listing__cta2-heading-container">
                                        <Heading5 text="Book a viewing or make an offer" />
                                    </div>
                                </div>
                                <div className="listing__cta2-heading-container">
                                    <Heading6 text="We recommend viewing before making an offer. Get in there!"/>
                                </div>
                            </div>
                            <div className="listing__cta2-buttons">
                                <div className="listing__cta2-buttons-single-container">
                                    <Button
                                        label="Book a viewing"
                                        type="rooster"
                                        disabled={false}
                                        onClick={() => this.props.dispatch(createChangeRouteAction("VIEW_LISTING_BOOK_VIEWING", { listingId: this.props.state.routes.params.listingId }, {}))}
                                        fullWidth={true}
                                    />
                                </div>
                                <div className="listing__cta2-buttons-single-container">
                                    <AnchorButton
                                        label="Make an offer"
                                        type="otter"
                                        disabled={false}
                                        url={sailHomesBrandConstants("web").contact.phoneURL}
                                        fullWidth={true}
                                    />
                                </div>
                            </div>
                        </ContentContainer>
                    </div>
                </div>
            </Page>
        ) : <div></div>;
    }
}

export default ListingPage;
