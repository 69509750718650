import React from "react";
import { CRMIcon } from "../CRMIcon/CRMIcon";
import { TCRMMainColours } from "../../models/TCRMMainColours";
import { CRMColumnSpacer } from "../CRMColumnSpacer/CRMColumnSpacer";
import { TButton } from "../../models/TButton";

const TButtonToIconColour = (props: TButton): TCRMMainColours => {
    if (props.disabled) {
        return "neutral-4";
    }
    if (props.highlight === "instructional-brick") {
        return "highlights-instructional-brick-2";
    }
    if (props.highlight === "positive-green") {
        return "highlights-positive-green-4";
    }
    if (props.highlight === "calming-pink") {
        return "highlights-calming-pink-2";
    }
    return "neutral-ink";
};

export class CRMButtonQuaternary extends React.Component<React.PropsWithChildren<TButton>> {
    public render (): JSX.Element {
        return (
            <a
                role="button"
                className={`
                    crm-button-quaternary
                    crm-button-quaternary--highlight-${this.props.highlight ? this.props.highlight : "none"}
                    crm-button-quaternary--${this.props.disabled ? "disabled" : "enabled"}
                    crm-button-quaternary--${this.props.fullWidth ? "full-width" : "auto"}
                `}
                title={this.props.title}
                onClick={this.props.disabled ? undefined : this.props.onClick}
                href={this.props.disabled ? undefined : this.props.href}
                target={this.props.target}
            >
                {/* ICON */}
                {this.props.icon &&
                    <div className={`crm-button-primary__icon`}>
                        <CRMIcon
                            iconName={this.props.icon}
                            colour={TButtonToIconColour(this.props)}
                            size="small"
                        />
                        <CRMColumnSpacer size="tiny"/>
                    </div>
                }
                {this.props.label}
            </a>
        );
    }
}
