import React from "react";
import { TContainerStateProps } from "../../state/TContainerStateProps";
import { CRMPageWrap } from "../../components/Simple/CRMPageWrap/CRMPageWrap";
import { CRMLegalMenuBarContainer } from "../../containers/CRMLegalMenuBarContainer/CRMLegalMenuBarContainer"
import { Padding } from "../../components/BuildingBlocks/Padding";
import { SpacingRow } from "../../components/BuildingBlocks/SpacingRow";
import { CRMSpacing } from "../../models/CRMSpacing";
import { CRMQuoteRatesBlock } from "../../components/Complex/CRMQuoteRatesBlock/CRMQuoteRatesBlock";
import { CRMBlock } from "../../components/CRM/CRMBlock/CRMBlock/CRMBlock";

const CRMLegalQuoteRatesPage = (props: TContainerStateProps): JSX.Element =>
    <CRMPageWrap>
        {/* HEADER */}
        <CRMLegalMenuBarContainer {...props} />

        {/* BODY */}
        <Padding
            type="custom" 
            spacing={`${CRMSpacing.X_LARGE} ${CRMSpacing.X_LARGE} 0px`}
        >
            <SpacingRow
                spacing={CRMSpacing.X_LARGE}
                alignItems="flex-start"
            >
                {/* BLOCK - RATES */}
                <CRMBlock
                    mode="fixed-option"
                    fixedOption={{
                        icon: "quote",
                        title: "Rates"
                    }}
                    optionGroups={[]}
                >
                    <CRMQuoteRatesBlock
                        form={props.state.forms.quote_rates_page.data}
                        activeTypeVisible={props.state.forms.quote_rates_page.active_type_visible}
                        onActiveTypeVisibleChange={(payload) => props.dispatch({
                            type: "CRM_QUOTE_RATES_ACTIVE_TYPE_CHANGE",
                            payload,
                        })}
                        onCreateNewRate={(payload) => props.dispatch({
                            type: "CRM_QUOTE_RATES_CREATE",
                            payload,
                        })}
                        onChangeNewRate={(payload) => props.dispatch({
                            type: "CRM_QUOTE_RATES_CHANGE",
                            payload,
                        })}
                        onSaveNewRate={(payload) => props.dispatch({
                            type: "CRM_QUOTE_RATES_SAVE",
                            payload,
                        })}
                        onToggleDeleteRateOnNextPublish={(payload) => props.dispatch({
                            type: "CRM_QUOTE_RATES_TOGGLE_DELETE_ON_NEXT_PUBLISH",
                            payload,
                        })}
                        onReplaceRate={(payload) => props.dispatch({
                            type: "CRM_QUOTE_RATES_REPLACE",
                            payload,
                        })}
                        onDeleteRate={(payload) => props.dispatch({
                            type: "CRM_QUOTE_RATES_DELETE",
                            payload,
                        })}
                        onPublishRateChanges={(payload) => props.dispatch({
                            type: "CRM_QUOTE_RATES_PUBLISH",
                            payload,
                        })}
                    />
                </CRMBlock>
            </SpacingRow>
        </Padding>
    </CRMPageWrap>;

export default CRMLegalQuoteRatesPage;
