import React from "react";
import * as ListingEnquiriesViewing2 from "../../../../domain/models/ListingEnquiriesViewing2";
import * as ISODateTime from "../../../../domain/models/ISODateTime";
import * as User10 from "../../../../domain/models/User10";
import { CRMSpacer } from "../CRMSpacer/CRMSpacer";
import { CRMCardOutsideMinor } from "../CRMCardOutsideMinor/CRMCardOutsideMinor";
import { CRMParagraph } from "../Simple/CRMParagraph/CRMParagraph";
import { WeightBold } from "../WeightBold/WeightBold";
import { Uppercase } from "../Uppercase/Uppercase";
import { CRMIcon } from "../CRMIcon/CRMIcon";
import { CRMTitleSubSection } from "../CRMTitleSubSection/CRMTitleSubSection";
import { WeightRegular } from "../WeightRegular/WeightRegular";
import { CRMTextHighlight } from "../CRMTextHighlight/CRMTextHighlight";
import { CRMPadding } from "../Simple/CRMPadding/CRMPadding";

class CRMPastViewingMiniComponent extends React.Component<{
    viewing: ListingEnquiriesViewing2.TSimpleViewing;
}> {

    timeSinceViewingString = (days: number, formattedDateString: string): string => {
        switch (true) {
            case (days >= 14):
                return `${formattedDateString} (${Math.floor(days/7).toString()} weeks ago)`;
            case (days > 1):
                return `${formattedDateString} (${days.toString()} days ago)`;
            case (days > 0):
                return `${formattedDateString} (Yesterday)`;
            case (days === 0):
                return `${formattedDateString} (Earlier today)`;
            default:
                return formattedDateString;
        }
    };

    public render (): JSX.Element {
        return (
            <div className="crm-past-viewing-mini-component">
                <CRMCardOutsideMinor thickLeftBorder={true}>
                    <CRMPadding size="medium">
                        <CRMParagraph>
                            <div className="crm-past-viewing-mini-component__days-since-viewing">
                                <WeightBold>
                                    {this.props.viewing.status === "confirmed" ?
                                        this.timeSinceViewingString(
                                            ISODateTime.getNumberOfDaysSinceDate(this.props.viewing.date),
                                            ISODateTime.getFormattedDateString(this.props.viewing.date, "ccc d LLLL - h:mma")
                                        )
                                    : "" }
                                </WeightBold>
                            </div>
                        </CRMParagraph>
                        <CRMSpacer size="tiny"/>
                        <CRMTitleSubSection>
                            <Uppercase>{this.props.viewing.viewing_type === "in_person" ? "In person" : "Online"}</Uppercase>
                        </CRMTitleSubSection>
                        <CRMSpacer size="tiny"/>
                        <CRMParagraph>
                            <WeightRegular>by</WeightRegular>
                            <WeightBold>{User10.usersToNamesString(this.props.viewing.party.users, () => "Names Not Given")}</WeightBold>
                            {this.props.viewing.agent !== "" && this.props.viewing.agent !== null ?
                                <span>
                                    <WeightRegular>with agent</WeightRegular>
                                    <WeightBold>{this.props.viewing.agent}</WeightBold>
                                </span>
                                : ""
                            }
                        </CRMParagraph>
                        <CRMSpacer size="tiny" />
                        {this.props.viewing.feedback === "" ? "" :
                            <CRMParagraph>
                                <WeightBold>Feedback</WeightBold>
                                <WeightRegular>
                                    <CRMTextHighlight colour={this.props.viewing.shared_with_seller_at === null ? "highlights-instructional-brick-10" : "highlights-positive-green-12"}>
                                        {this.props.viewing.feedback}
                                    </CRMTextHighlight>
                                </WeightRegular>
                            </CRMParagraph>
                        }
                        {this.props.viewing.shared_with_seller_at !== null ?
                            <div className="crm-past-viewing-mini-component__feedback-provided-to-seller-status-icon">
                                <CRMIcon
                                    iconName="done-all"
                                    colour="neutral-ink"
                                />
                            </div>
                            : <div className="crm-past-viewing-mini-component__feedback-provided-to-seller-status-icon crm-past-viewing-mini-component__feedback-provided-to-seller-status-icon--label">
                                <div className="crm-past-viewing-mini-component__feedback-provided-to-seller-status-icon--new-feedback-icon">
                                    <CRMIcon
                                        iconName="new"
                                        colour="neutral-ink"
                                        size="extra-large"
                                    />
                                </div>
                                <CRMIcon
                                    iconName="not-interested"
                                    colour="neutral-ink"
                                    size="large"
                                />
                            </div>
                        }
                    </CRMPadding>
                </CRMCardOutsideMinor>
            </div>
        );
    }
}

export default CRMPastViewingMiniComponent;
