import { TEmailCompositionForm } from "../../../../../../domain/codecs/form/EmailCompositionForm";
import { TDetailedEmailForm } from "../../../../../../domain/codecs/form/TriageForm";
import { TSessionUser } from "../../../../../../domain/codecs/User";
import { TEmailCloseMode } from "../CRMComposeEmailControls/CRMComposeEmailControls";

export type TEmailViewStatus = 
    "compose-confidential-reply"
    | "compose-confidential-new"
    | "compose-confidential-forward"
    | "compose-case-reply"
    | "compose-case-new"
    | "compose-case-forward"
    | "view-attachment-search"
    | "view-attached-email"
    | "view-email-templates"
;

export type TComposeEmailResolutionStatus = 
    "clear-email"
    | "none"
;

export type TSendType =
    "new"
    | "reply"
    | "forward"
;

export type TActivityType =
    "compose"
    | "view"
;

export type TContextType =
    "case"
    | "confidential"
;

export type TComposeEmailFormWritingProps = {
    form: TEmailCompositionForm;
    onChange: (form: TEmailCompositionForm) => void;
    composingUser: TSessionUser;
    additionalSendToEmailAddresses: Array<string>;
}

export type TComposeEmailFormCaseSearchProps = {
    onChangeSearchCaseValue: (value: string) => void;
    onSelectCase: (form: TEmailCompositionForm) => void; 
}

export type TComposeEmailFormAttachmentOptionsProps = {
    attachedEmail: TDetailedEmailForm;
}

export type TComposeEmailFormControlProps = {
    closeMode: TEmailCloseMode;
    onClose?: () => void;
    onClear: () => void;
    onSubmit: (form: TEmailCompositionForm) => void;
    onReply: (form: TEmailCompositionForm) => void;
    onForward: (form: TEmailCompositionForm) => void;
}

export const isEmailViewType = (type: TActivityType | TContextType | TSendType, viewStatus: TEmailViewStatus) => !!viewStatus.match(type);