import React from "react";

type TCRMCardOutsidePopupBlank = {
    isOpen: boolean;
    onClose: () => void;
    opacity?: number;
    noCloseOnShadowboxClick?: boolean;
};

const CRMPopupShadowBoxComp = (props: React.PropsWithChildren<TCRMCardOutsidePopupBlank>): JSX.Element => {
    
    const popupBoxRef = React.createRef<HTMLDivElement>();

    const Opacity = props.opacity !== undefined ? props.opacity : 0.54;

    return (
        <div
            className={`
                crm-popup-shadow-box 
                crm-popup-shadow-box--${props.isOpen ? 'open' : 'closed'}
            `}
            style={{
                backgroundColor: `rgba(0, 1, 27, ${Opacity})`
            }}
            onClick={(event) => {
                if (
                    props.noCloseOnShadowboxClick !== true && 
                    popupBoxRef.current && 
                    !popupBoxRef.current.contains(event.target as Node | null)
                ) {
                    props.onClose();
                }
            }}
        >
            <div
                ref={popupBoxRef}
                className={`crm-popup-shadow-box__container`}
            >
                {/* 
                    always un-render children you are not showing, 
                    as they could instantiate hooks, 
                    and run all kinds of other logic in the background that you do not 
                    want running until these popups are actually used 
                */}
                {props.isOpen && 
                    props.children
                }
            </div>
        </div>
    );
};

export const CRMPopupShadowBox = React.memo(CRMPopupShadowBoxComp) as typeof CRMPopupShadowBoxComp;