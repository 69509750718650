import React from "react";

type TRelativeProps = {
    divRef?: React.RefObject<HTMLDivElement>;
};

export const PreserveLineBreaksNotSpaces = (props: React.PropsWithChildren<TRelativeProps>): JSX.Element => {

    return (
        <div
            ref={props.divRef}
            style={{
                whiteSpace: "pre-line",
                display: "inline"
            }}
        >
            {props.children}
        </div>
    );
};
