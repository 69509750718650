import React from "react";
import { CRMCardOutside } from "../../../CRMCardOutside/CRMCardOutside";
import { CRMPadding } from "../../../Simple/CRMPadding/CRMPadding";
import { TTenure1 } from "../../../../../../domain/codecs/Tenure";
import { Flex } from "../../../BuildingBlocks/Flex";
import { CRMParagraph } from "../../../Simple/CRMParagraph/CRMParagraph";
import { WeightBold } from "../../../WeightBold/WeightBold";
import { CursorPointer } from "../../../CursorPointer/CursorPointer";
import { CRMSpacing } from "../../../../models/CRMSpacing";
import { SpacingColumn } from "../../../BuildingBlocks/SpacingColumn";
import { Text } from "../../../BuildingBlocks/Text";
import { FontQuicksand } from "../../../BuildingBlocks/FontQuicksand";
import { CRMCaseTitleCheckIdentifyingSummary } from "../CRMCaseTitleCheckIdentifyingSummary/CRMCaseTitleCheckIdentifyingSummary";
import { CRMCaseTitleCheckStatusPill } from "../CRMCaseTitleCheckStatusPill/CRMCaseTitleCheckStatusPill";
import { TCaseTitleCheckFormState } from "../../../../../../domain/codecs/form/CaseTitleCheckForm";

export const CRMCaseTitleCheckCard = (props: {
    shortAddress: string,
    tenure: TTenure1,
    isRegistered: boolean | null,
    titleNumber: string,
    formState: TCaseTitleCheckFormState;
    onClick: () => void,
}): JSX.Element =>
    {
        return <CursorPointer onClick={props.onClick}>
            <CRMCardOutside
                borderRounding="none"
                shadow={false}
                backgroundColor="neutral-paper"
            >
                <CRMPadding size="medium">
                    <Flex direction="row">
                        <SpacingColumn spacing={CRMSpacing.MEDIUM}>
                            <CRMParagraph>
                                <WeightBold>
                                    <FontQuicksand>
                                        <Text size={15}>
                                            {props.shortAddress ? props.shortAddress : <>&nbsp;</>}
                                        </Text>
                                    </FontQuicksand>
                                </WeightBold>
                            </CRMParagraph>
                            <CRMCaseTitleCheckIdentifyingSummary
                                tenure={props.tenure}
                                isRegistered={props.isRegistered}
                                titleNumber={props.titleNumber}
                            />
                        </SpacingColumn>
                        <CRMCaseTitleCheckStatusPill
                            formState={props.formState}
                        />
                    </Flex>
                </CRMPadding>
            </CRMCardOutside>
        </CursorPointer>;
    };