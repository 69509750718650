import * as t from "io-ts";
import * as util from "../../shared/src/util";

export const codec = t.type({
    type: t.literal("FILTER"),
    property: t.string,
    operator: t.union([
        t.literal("EQUALS_DATE"),
        t.literal("GREATER_THAN_DATE"),
        t.literal("GREATER_THAN_OR_EQUALS_DATE"),
        t.literal("LESS_THAN_DATE"),
        t.literal("LESS_THAN_OR_EQUALS_DATE"),
        t.literal("DATE_IS_NULL"),
        t.literal("DATE_IS_NOT_NULL"),
    ]),
    value: t.string,
});

export type T = t.TypeOf<typeof codec>;

export const operatorValues = util.typeValuesFromCodec(codec.props.operator.types as Array<t.LiteralC<T["operator"]>>);
