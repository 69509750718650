import React from "react";
import CRMInputLabelAndErrorWrapComponent from "../../CRMInputLabelAndErrorWrapComponent/CRMInputLabelAndErrorWrapComponent";
import { CRMRadioListComponent } from "../../CRMInputs/CRMRadioListComponent/CRMRadioListComponent";

export const CRMCompanyPartnerAMLAndKYCQuestion = (props: React.PropsWithChildren<{
    label: string,
    value: boolean | null,
    onChange: (value: boolean | null) => void,
}>): JSX.Element =>
    <CRMInputLabelAndErrorWrapComponent label={props.label}>
        <CRMRadioListComponent
            options={[
                {
                    value: "yes",
                    text: "Yes"
                },
                {
                    value: "no",
                    text: "No"
                },
                {
                    value: "not_answered",
                    text: "Not answered"
                },
            ]}
            value={
                props.value === true ? "yes"
                : props.value === false ? "no"
                : "not_answered"
            }
            onChange={(value) => props.onChange(
                value === "yes" ? true
                : value === "no" ? false
                : null
            )}
        />
    </CRMInputLabelAndErrorWrapComponent>;
