import { capitalizeFirstLetter } from "../string/capitalizeFirstLetter";

export const cleanName = (firstName: string, middleName: string, lastName: string): string => {
    const correctCapitalisationAndTrim = (namePart: string): string => {
        // If the name is all one case change to capitalize the first letter, if the name has upper and lower assume it has been set for a reason
        if (namePart === namePart.toUpperCase() || namePart === namePart.toLowerCase()) {
            return  capitalizeFirstLetter(namePart.trim());
        }
        return namePart.trim();
    }
    return [firstName, middleName, lastName].map(correctCapitalisationAndTrim).filter((part: string) => part !== "").join(" ");
}