import React from "react";
import { array } from "fp-ts";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { SpacingColumn } from "../../BuildingBlocks/SpacingColumn";
import { CRMSingleContentPlaceholder } from "../CRMSingleContentPlaceholder/CRMSingleContentPlaceholder";
import { pickRandom } from "../../../functions/mock/GeneralMocks_BuildingBlocks";
import { THexColor, TPercentageSize, TPixelSize } from "../../../models/StringLiterals";

type TTextContentPlaceholderProps = {
    rowCount: number;
    mode?: TPixelSize | TPercentageSize | "left-align" | "justify";
    height?: TPixelSize | TPercentageSize;
    backgroundColor?: THexColor;
    shineColor?: THexColor;
};

export const CRMTextContentPlaceholder = (props: React.PropsWithChildren<TTextContentPlaceholderProps>): JSX.Element => {

    const getSinglePlaceholderWidth = (): TPercentageSize | TPixelSize => {
        if (props.mode === "justify") {
            return "100%";
        }
        if (props.mode === "left-align") {
            return pickRandom<TPercentageSize>(["70%", "75%", "80%", "85%", "90%", "95%", "100%"]);
        }
        return props.mode || "100%";
    }

    return (
        <SpacingColumn spacing={CRMSpacing.SMALL}>
            {array.range(1, props.rowCount).map((index) => (
                <CRMSingleContentPlaceholder
                    key={index}
                    width={getSinglePlaceholderWidth()}
                    height={props.height}
                    backgroundColor={props.backgroundColor}
                    shineColor={props.shineColor}
                />
            ))}
        </SpacingColumn>
    );
};
