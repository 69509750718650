import * as t from "io-ts";
import * as JsonResponse1 from "./JsonResponse1";
import * as CaseEnquiry5 from "./CaseEnquiry5";
import * as PaginationMeta1 from "./PaginationMeta1";

export const codec = JsonResponse1.createCodec(
    t.array(CaseEnquiry5.codec),
    t.type({}),
);

export type T = t.TypeOf<typeof codec>;

export const newDefault = (): T => ({
    data: [],
    meta: PaginationMeta1.newDefault(),
});
