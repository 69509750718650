import React from "react";
import { CRMFormErrorComponent } from "../CRMFormErrorComponent/CRMFormErrorComponent";
import { WeightSemiBold } from "../WeightSemiBold/WeightSemiBold";
import * as util from "../../../../shared/src/util";
import { WeightBold } from "../WeightBold/WeightBold";
import { WeightRegular } from "../WeightRegular/WeightRegular";
import { WeightMedium } from "../WeightMedium/WeightMedium";
import { TFontWeight } from "../../models/TCRMFontWeight";

interface ICRMInputLabelAndErrorWrapComponent {
    displayError?: boolean;
    errorMessage?: string;
    label?: string | JSX.Element;
    labelFontWeight?: TFontWeight;
    guidance?: string;
    title?: string;
}

export class CRMInputLabelAndErrorWrapComponent extends React.Component<React.PropsWithChildren<ICRMInputLabelAndErrorWrapComponent>> {

    getFontWeightComponent = (fontWeight: TFontWeight): JSX.Element => {
        switch (fontWeight) {
            case "bold":
                return <WeightBold>{this.props.label}</WeightBold>;
            case "semi-bold":
                return <WeightSemiBold>{this.props.label}</WeightSemiBold>;
            case "medium":
                return <WeightMedium>{this.props.label}</WeightMedium>;
            case "regular":
                return <WeightRegular>{this.props.label}</WeightRegular>;
            default:
                return util.requireExhaustive(fontWeight);
        }
    };

    public render (): JSX.Element {
        return (
            <div className="crm-input-label-and-error-wrap" title={this.props.title}>
                {typeof this.props.label === "string" &&
                    <div className="crm-input-label-and-error-wrap__label">
                        <span className="crm-input-label-and-error-wrap__labelText">
                            {this.getFontWeightComponent(this.props.labelFontWeight ?? "semi-bold")}
                        </span>
                        {this.props.guidance &&
                            <span className="crm-input-label-and-error-wrap__guidanceText">
                                {this.props.guidance}
                            </span>
                        }
                    </div>
                }

                {typeof this.props.label === "object" &&
                    <div className="crm-input-label-and-error-wrap__label">
                        <span className="crm-input-label-and-error-wrap__labelText">
                            {this.props.label}
                        </span>
                    </div>
                }

                <div className="crm-input-label-and-error-wrap__formElement">
                    {this.props.children}
                </div>

                {/* ERROR MESSAGE */}
                {this.props.displayError &&
                    <CRMFormErrorComponent
                        errorMessage={this.props.errorMessage || ""}
                    />
                }
            </div>
        );
    }
}

export default CRMInputLabelAndErrorWrapComponent;
