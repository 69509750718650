import React, { useEffect, useState } from "react";
import { FrontSpacing } from "../../../../models/FrontSpacing";
import FrontInputGeneralComponent from "../FrontInputGeneralComponent/FrontInputGeneralComponent";
import FrontInputLabelAndErrorWrapComponent from "../FrontInputLabelAndErrorWrapComponent/FrontInputLabelAndErrorWrapComponent";
import { FrontFormPrimaryButton, TFormPrimaryButtonProps } from "../FrontFormPrimaryButton/FrontFormPrimaryButton";
import { SpacingColumn } from "../../../BuildingBlocks/SpacingColumn";
import { TUserLegalCorrespondenceAddressForm } from "../../../../../../domain/codecs/form/UserLegalCorrespondenceAddressForm";
import { onChangeForm } from "../../../../../../shared/src/codecs/types/form";
import { FrontDropdown } from "../FrontDropdown/FrontDropdown";
import { Country4, Country4ToDisplayName } from "../../../../../../domain/codecs/Country";
import { isValidPostcode } from "../../../../../../domain/codecs/overloads/Address";
import { isFilled } from "../../../../../../shared/src/util";
import { FrontPostCodeNameNumberInput } from "../FrontPostCodeNameNumberInput/FrontPostCodeNameNumberInput";
import { FrontFormRow } from "../FrontFormRow/FrontFormRow";
import { InlineFlex } from "../../../BuildingBlocks/InlineFlex";
import { SpacingRow } from "../../../BuildingBlocks/SpacingRow";
import { WeightRegular } from "../../../WeightRegular/WeightRegular";

type TAddressSearchInputProps = {
    form: TUserLegalCorrespondenceAddressForm;
    onChange: (form: TUserLegalCorrespondenceAddressForm) => void;
    onAutoComplete: (form: TUserLegalCorrespondenceAddressForm) => void;
    submit?: TFormPrimaryButtonProps & { onClick: (form: TUserLegalCorrespondenceAddressForm) => void }
};

export const FrontAddressSearchInput = (props: React.PropsWithChildren<TAddressSearchInputProps>): JSX.Element => {
    const [addressFieldsVisible, setAddressFieldsVisible] = useState<boolean>(false);

    const canAutoComplete = (): boolean =>
        isValidPostcode(props.form.edited.legal_correspondence_postcode)
        && (isFilled(props.form.edited.legal_correspondence_building_name) || isFilled(props.form.edited.legal_correspondence_building_number))
    ;

    const onChange = onChangeForm(props.form, props.onChange);
    const onChangeBuildingNumberOrName = (prop: "legal_correspondence_building_number" | "legal_correspondence_building_name") => (value: string) => props.onChange({
        ...props.form,
        edited: {
            ...props.form.edited,
            legal_correspondence_building_number: prop === "legal_correspondence_building_number" ? value : "",
            legal_correspondence_building_name: prop === "legal_correspondence_building_name" ? value : "",
        }
    });

    const isCountrySelected = () => props.form.edited.legal_correspondence_country !== "unknown";

    const isUKSelectedAsCountry = () => props.form.edited.legal_correspondence_country === "GB";

    const RenderPostCodeNameNumberInput = (): JSX.Element => (
        <FrontPostCodeNameNumberInput
            postcode={props.form.edited.legal_correspondence_postcode}
            buildingNumber={props.form.edited.legal_correspondence_building_number}
            buildingName={props.form.edited.legal_correspondence_building_name}
            onChangePostcode={onChange("legal_correspondence_postcode")}
            onChangeNumber={onChangeBuildingNumberOrName("legal_correspondence_building_number")}
            onChangeName={onChangeBuildingNumberOrName("legal_correspondence_building_name")}
        />
    );

    const onAutoComplete = (form: TUserLegalCorrespondenceAddressForm) => {
        props.onAutoComplete(form);
        setAddressFieldsVisible(true);
    }

    useEffect(() => {
        if (
            props.form.edited.legal_correspondence_city_town
            || props.form.edited.legal_correspondence_county
            || props.form.edited.legal_correspondence_district
            || props.form.edited.legal_correspondence_locality
            || props.form.edited.legal_correspondence_street_name
            || props.form.edited.legal_correspondence_sub_building_name
            || props.form.edited.legal_correspondence_sub_building_number
        ) {
            setAddressFieldsVisible(true);
        }
    }, [props.form.edited]);

    return (
        <div className={`front-address-search-input`}>
            <SpacingColumn spacing={FrontSpacing.MEDIUM_1}>
                
                <FrontFormRow>
                    <FrontInputLabelAndErrorWrapComponent label="Country">
                        <FrontDropdown
                            value={props.form.edited.legal_correspondence_country}
                            options={Country4.values.map((value) => ({
                                value,
                                label: Country4ToDisplayName(value)
                            }))}
                            onChange={onChange("legal_correspondence_country")}
                        />
                    </FrontInputLabelAndErrorWrapComponent>
                </FrontFormRow>

                {isCountrySelected() && <div>
                    {RenderPostCodeNameNumberInput()}
                </div>}

                {isUKSelectedAsCountry() && !addressFieldsVisible && canAutoComplete() && <FrontFormRow>
                    <div></div>
                    <FrontFormPrimaryButton
                        label="Find address"
                        isDisabled={false}
                        onClick={() => onAutoComplete(props.form)} 
                    />
                </FrontFormRow>}
                
                {/* MANUAL ENTRY FORM */}
                {(addressFieldsVisible || (isCountrySelected() && !isUKSelectedAsCountry())) &&
                    <SpacingColumn spacing={FrontSpacing.MEDIUM_1}>
                        {/* SUB NO & SUB NAME */}
                        <SpacingRow
                            spacing={FrontSpacing.MEDIUM_1}
                            childSize="1fr 1fr"
                        >
                            <FrontInputLabelAndErrorWrapComponent label={<WeightRegular>Flat No.</WeightRegular>}>
                                <FrontInputGeneralComponent
                                    inputType="text"
                                    value={props.form.edited.legal_correspondence_sub_building_number}
                                    placeholder=""
                                    onChange={onChange("legal_correspondence_sub_building_number")}
                                    displayError={false}
                                />
                            </FrontInputLabelAndErrorWrapComponent>
                            
                            <FrontInputLabelAndErrorWrapComponent label={<WeightRegular>Flat Name</WeightRegular>}>
                                <FrontInputGeneralComponent
                                    inputType="text"
                                    value={props.form.edited.legal_correspondence_sub_building_name}
                                    placeholder=""
                                    onChange={onChange("legal_correspondence_sub_building_name")}
                                    displayError={false}
                                />
                            </FrontInputLabelAndErrorWrapComponent>
                        </SpacingRow>
                        
                        {/* STREET & CITY/TOWN */}
                        <FrontFormRow>
                            <FrontInputLabelAndErrorWrapComponent label="Street name">
                                <FrontInputGeneralComponent
                                    inputType="text"
                                    value={props.form.edited.legal_correspondence_street_name}
                                    placeholder=""
                                    onChange={onChange("legal_correspondence_street_name")}
                                    displayError={false}
                                />
                            </FrontInputLabelAndErrorWrapComponent>
                            
                            <FrontInputLabelAndErrorWrapComponent label="City/Town">
                                <FrontInputGeneralComponent
                                    inputType="text"
                                    value={props.form.edited.legal_correspondence_city_town}
                                    placeholder=""
                                    onChange={onChange("legal_correspondence_city_town")}
                                    displayError={false}
                                />
                            </FrontInputLabelAndErrorWrapComponent>
                        </FrontFormRow>
                        
                        {/* COUNTY & DISTRICT */}
                        <FrontFormRow>
                            <FrontInputLabelAndErrorWrapComponent label="County">
                                <FrontInputGeneralComponent
                                    inputType="text"
                                    value={props.form.edited.legal_correspondence_county}
                                    placeholder=""
                                    onChange={onChange("legal_correspondence_county")}
                                    displayError={false}
                                />
                            </FrontInputLabelAndErrorWrapComponent>

                            <FrontInputLabelAndErrorWrapComponent label="District">
                                <FrontInputGeneralComponent
                                    inputType="text"
                                    value={props.form.edited.legal_correspondence_district}
                                    placeholder=""
                                    onChange={onChange("legal_correspondence_district")}
                                    displayError={false}
                                />
                            </FrontInputLabelAndErrorWrapComponent>
                        </FrontFormRow>
                        
                        {/* LOCALITY & IF: SUBMIT */}
                        <SpacingRow
                            spacing={FrontSpacing.MEDIUM_1}
                            childSize="1fr 1fr"
                            alignItems="flex-end"
                        >
                            <FrontInputLabelAndErrorWrapComponent label="Locality">
                                <FrontInputGeneralComponent
                                    inputType="text"
                                    value={props.form.edited.legal_correspondence_locality}
                                    placeholder=""
                                    onChange={onChange("legal_correspondence_locality")}
                                    displayError={false}
                                />
                            </FrontInputLabelAndErrorWrapComponent>                            

                            {typeof props.submit === "object" &&
                                <InlineFlex>
                                    <FrontFormPrimaryButton
                                        label={props.submit.label}
                                        icon={props.submit.icon}
                                        isDisabled={props.submit.isDisabled}
                                        onClick={() => props.submit?.onClick(props.form)} 
                                    />
                                </InlineFlex>
                            }
                            
                            
                        </SpacingRow>


                    </SpacingColumn>
                }
            </SpacingColumn>
        </div>
    );
};