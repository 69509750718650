import React from "react";
import { SpacingRow } from "../../BuildingBlocks/SpacingRow";
import { CRMIcon, TIcon } from "../../CRMIcon/CRMIcon";
import { contains } from "../../../../../shared/src/utilsByDomain/array";
import { pipe } from "fp-ts/lib/pipeable";

type TCRMKeyboardButtonIconProps = {
    character: string;
};

export const CRMKeyboardButtonIcon = (props: React.PropsWithChildren<TCRMKeyboardButtonIconProps>): JSX.Element => {

    const lowerCaseCharacter = props.character.toLowerCase();
    const isShowingASymbol = pipe(
        ["enter", "key-left", "key-right", "key-up", "key-down"],
        contains(lowerCaseCharacter)
    );

    return (
        <div className="crm-keyboard-button-icon">
            <div className="crm-keyboard-button-icon__body">
                <SpacingRow spacing="3px">
                    {!isShowingASymbol &&
                        <span>{props.character}</span>
                    }

                    {isShowingASymbol &&
                        <CRMIcon
                            iconName={lowerCaseCharacter as TIcon}
                            colour="neutral-ink"
                            size="12px"
                        />
                    }
                </SpacingRow>
            </div>
        </div>
    );
};
