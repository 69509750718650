import React from "react";
import { CRMBlockChoicePopout, TBlockOpenInstruction } from "../../components/CRM/CRMBlock/CRMBlockChoicePopout/CRMBlockChoicePopout";
import { TContainerStateProps } from "../../state/TContainerStateProps";
import { CRMPopupShadowBox } from "../../components/Simple/CRMPopupShadowBox/CRMPopupShadowBox";
import { Background } from "../../components/BuildingBlocks/Background";
import { pipe } from "fp-ts/lib/function";
import { getRouteBlockData } from "../../state/router/getRouteBlockData";
import { updateRouteWithBlockChange } from "../../state/router/updateRouteWithBlockChange";
import { option } from "fp-ts";
import { TBlockType, TBlocksParamsObject, TRouteActionType } from "../../state/router/routerTypes";
import { AnimationPopout } from "../../components/BuildingBlocks/AnimationPopout";
import { underscoreCaseToNormalCase } from "../../../../shared/src/utilsByDomain/string";
import { capitalizeEveryWord } from "../../../../shared/src/utilsByDomain/string/capitalizeEveryWord";


const CRMBlockChoiceContainer = (props: TContainerStateProps): JSX.Element => {

    const BoxRef = React.createRef<HTMLDivElement>();
    const BlockToOpen = props.state.forms.block_column_picker.opens;
    const OpeningBlockNameCopy = pipe(
        underscoreCaseToNormalCase(BlockToOpen || ""),
        capitalizeEveryWord
    );
    const BlockData: TBlocksParamsObject<TRouteActionType> = pipe(
        getRouteBlockData(props.state.routes),
        option.fold(
            () => [],
            (data) => data.blocksOpened
        )
    );

    const onClose = () => props.dispatch({
        type: "BLOCK_OPEN_END_CHOICE",
        payload: null
    });

    const onChange = (payload: TBlockOpenInstruction) => pipe(
        props.state.routes,
        updateRouteWithBlockChange(BlockToOpen as TBlockType, payload.location-1),
        option.fold(
            () => undefined,
            (routeAction) => {
                props.dispatch(routeAction);
                onClose();
            }
        )
    );

    const onClickBox = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (BoxRef.current && BoxRef.current === event.target) {
            onClose();
        }
    }

    return (
        <CRMPopupShadowBox
            isOpen={!!BlockToOpen}
            onClose={onClose}
        >
            {!!BlockToOpen &&
                <Background
                    divRef={BoxRef}
                    display="flex"
                    alignItems="center" 
                    justifyContent="center"
                    height="100vh"
                    onClick={onClickBox}
                >
                    <AnimationPopout>
                        <CRMBlockChoicePopout
                            openingBlock={OpeningBlockNameCopy}
                            mode="replace-only"
                            blocksOpened={BlockData}
                            onChange={onChange}
                            onClose={onClose}
                        />
                    </AnimationPopout>
                </Background>
            }
        </CRMPopupShadowBox>
    );
}

export default CRMBlockChoiceContainer;