import React, { ReactNode } from "react";
import { TBlockContainerStateProps } from "../state/TBlockContainerStateProps";
import { TBlockType } from "../state/router/routerTypes";
import { TIcon } from "../components/CRMIcon/CRMIcon";
import CRMCaseDetailsOverviewContainer from "../containers/CRM/CRMCaseDetailsOverviewContainer";
import CRMCaseRoadblocksContainer from "../containers/CRMCaseRoadblocksContainer/CRMCaseRoadblocksContainer";
import CRMLegalCaseDetailsContainer from "../containers/CRMLegalCaseDetailsContainer/CRMLegalCaseDetailsContainer";
import { CRMLegalCaseContractsContainer } from "../containers/CRMLegalCaseContractsContainer/CRMLegalCaseContractsContainer";
import { CRMLegalCaseQuoteContainer } from "../containers/CRMLegalCaseQuoteContainer/CRMLegalCaseQuoteContainer";
import { task } from "fp-ts";
import { TDispatch } from "../state/TDispatch";
import { TGetCodecState } from "../state/applyActions";
import { Routes } from "../state/router/routerRoutes";
import { CRMLegalCaseTitleChecksContainer } from "../containers/CRMLegalCaseTitleChecksContainer/CRMLegalCaseTitleChecksContainer";
import CRMLegalCaseChargesAndRestrictionsContainer from "../containers/CRMLegalCaseChargesAndRestrictionsContainer/CRMLegalCaseChargesAndRestrictionsContainer";

export type TBlocksMap = {
    [K in TBlockType]: {
        icon: TIcon,
        title: string,
        content: (props: TBlockContainerStateProps) => ReactNode,
        refreshAction: (dispatch: TDispatch, getState: TGetCodecState, routes: Routes) => task.Task<unknown>
    }
};


/* Thomas Freire Camacho 2023-11-02
James Flight and I have a very long conversation on the best way to implement automatic block refreshing. We thought
maybe it would be better to have this more structured and rather than having function that trigger dispatches they could
actually run the logic from the action files. But this opened up questions about how to best handle the 3 states for
trigger blocks to do there main get requests. These are A) Being directly opened in the app by a user interaction, 
B) Being refreshed by a change in another roadblock, and C) By having a page link copied and opened / page refresh. 
The options discussed were to 1) leave the code trigger actions A) and C) to be controlled by the block itself as it 
currently is (usually using react hooks) or 2) bring all logic into the runAutomaticUpdates (A, B, and C). Pros of 2) 
is that we have one centralised place, cons being that unlike 1) you have to understand the entire roadblock and how it
interacts with multiple copies of itself etc without being able to see the actual visual code. We had many solutions to 
solving both of these problems, but they all felt like guess at how the system will evolve in the future and take time 
to implement and may be wrong. We decided based on this we would stick with the naive solution that had already been 
coded and is currently working. But make sure that it should be easy to refactor to a better solution (likely using 
rxjs) in the future.

NB: This comment is being left on the automaticUpdate function file and the Blocks definition file.
*/
export const BlockMap: TBlocksMap = {
    case_details: {
        icon: "file-box",
        title: "Details",
        content: (props) => <CRMLegalCaseDetailsContainer {...props} />,
        refreshAction: (dispatch) => {
            dispatch({
                type: "LEGAL_CASE_DETAILS_VIEW",
                payload: null,
            })
            return task.of({});
        }
    },
    case_quotes: {
        icon: "quote",
        title: "Quote",
        content: (props) => <CRMLegalCaseQuoteContainer {...props} />,
        refreshAction: (dispatch) => {
            dispatch({
                type: "CRM_CASE_QUOTE_VIEW",
                payload: null,
            })
            return task.of({});
        }
    },
    case_overview: {
        icon: "document",
        title: "Overview",
        content: (props) => <CRMCaseDetailsOverviewContainer {...props} />,
        refreshAction: (dispatch) => {
            dispatch({
                type: "CRM_CASE_OVERVIEW_VIEW",
                payload: null,
            })
            return task.of({});
        }
    },
    case_roadblocks: {
        icon: "roadblocks",
        title: "Roadblocks",
        content: (props) => <CRMCaseRoadblocksContainer {...props} />,
        refreshAction: (dispatch) => {
            dispatch({
                type: "LEGAL_CASE_GET_ROADBLOCKS",
                payload: null,
            })
            return task.of({});
        }
    },
    case_contracts: {
        icon: "sign",
        title: "Contract",
        content: (props) => <CRMLegalCaseContractsContainer {...props} />,
        refreshAction: (dispatch) => {
            dispatch({
                type: "LEGAL_CASE_CONTRACTS_BLOCK_VIEW",
                payload: null,
            })
            return task.of({});
        }
    },
    case_title_checks: {
        icon: "report",
        title: "Title Checks",
        content: (props) => <CRMLegalCaseTitleChecksContainer {...props} />,
        refreshAction: (dispatch) => {
            dispatch({
                type: "LEGAL_CASE_TITLE_CHECKS_BLOCK_VIEW",
                payload: null,
            })
            return task.of({});
        }
    },
    charges_and_restrictions: {
        icon: "denied",
        title: "Charges & Restrictions",
        content: (props) => <CRMLegalCaseChargesAndRestrictionsContainer {...props} />,
        refreshAction: (dispatch) => {
            dispatch({
                type: "LEGAL_CASE_CHARGES_AND_TITLE_RESTRICTIONS_BLOCK_VIEW",
                payload: null,
            })
            return task.of({});
        }
    },
};