import React from "react";
class FrontParagraphTiny extends React.Component<React.PropsWithChildren<{}>> {
    public render (): JSX.Element {
        return (
            <div className="front-paragraph-tiny">{this.props.children}</div>
        );
    }
}

export default FrontParagraphTiny;
