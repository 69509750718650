import React from "react";
import { useOpenClose } from "../../../../hooks/UseOpenClose";
import { TPixelSize } from "../../../../models/StringLiterals";
import { Absolute } from "../../../BuildingBlocks/Absolute";
import { CRMButtonIcon } from "../../../CRMButtonIcon/CRMButtonIcon";
import { TIcon } from "../../../CRMIcon/CRMIcon";
import { CRMPopout } from "../../../Simple/CRMPopout/CRMPopout";

type TBlockActionPopoutProps = {
    icon: TIcon;
    title?: string;
    options: Array<{
        label: JSX.Element | string;
        onClick: () => void;
    }>;
    popoutWidth?: TPixelSize;
};

export const CRMBlockActionPopout = (props: React.PropsWithChildren<TBlockActionPopoutProps>): JSX.Element => {

    const { 
        isOpen, 
        ref,
        toggleOpen 
    } = useOpenClose();

    return (
        <div 
            className="crm-block-action-popout" 
            ref={ref}
        >
            {isOpen &&
                <Absolute 
                    bottom="100%" 
                    left="0px"
                    width={props.popoutWidth || "300px"}
                >
                    <CRMPopout>
                        {props.options.map((option, key) => (
                            <div
                                key={key}
                                className="crm-block-action-popout__option"
                                onClick={() => {
                                    option.onClick()
                                    toggleOpen();
                                }}
                            >
                                {option.label}
                            </div>
                        ))}
                    </CRMPopout>
                </Absolute>
            }
            
            <CRMButtonIcon
                title={props.title || ""}
                variant={isOpen ? "quaternary-active" : "quaternary"}
                icon={props.icon}
                onClick={toggleOpen}
            />
        </div>
    );
};
