import React from "react";
import { WeightSemiBold } from "../../../WeightSemiBold/WeightSemiBold";
import FrontHeaderSwirve from "../../../../../src/assets/FrontHeaderSwirve";
import FrontTitleHeader from "../FrontTitleHeader/FrontTitleHeader";
import { FrontTextColour } from "../../../FrontTextColour/FrontTextColour";

type TFrontHeader = {};

export class FrontHeader extends React.Component<React.PropsWithChildren<TFrontHeader>> {
    public render (): JSX.Element {
        return (
            <div className="front-header">
                {/* TITLE */}
                <div className="front-header__body">
                    <div className="front-header__title">
                        <FrontTitleHeader>
                            <WeightSemiBold>
                                <FrontTextColour colour="neutral-18">
                                    {this.props.children}
                                </FrontTextColour>
                            </WeightSemiBold>
                        </FrontTitleHeader>
                    </div>
                </div>
                {/* SWIRVE */}
                <FrontHeaderSwirve className="front-header__swirve" />
            </div>
        );
    }
}
