import React from "react";
import { FrontSpacing } from "../../../../models/FrontSpacing";
import { SpacingRow } from "../../../BuildingBlocks/SpacingRow";
import { FrontIcon, TIcon } from "../FrontIcon/FrontIcon";

export type TFormPrimaryButtonProps = {
    label: string | JSX.Element;
    icon?: TIcon;
    isDisabled?: boolean;
    iconPosition?: "left" | "right";
    colourVariant?: "primary" | "positive-apple";
    onClick: () => void;
};

export const FrontFormPrimaryButton = (props: React.PropsWithChildren<TFormPrimaryButtonProps>): JSX.Element => {
    const iconPosition = props.iconPosition || "left";

    const colourVariant = props.colourVariant || "primary";

    return (
        <div
            className={`
                front-form-primary-button
                front-form-primary-button--${props.isDisabled ? "disabled" : `enabled-${colourVariant}`}
            `}
            onClick={() => {
                if (props.isDisabled !== true) {
                    props.onClick();
                }
            }}
        >
            <SpacingRow
                spacing={FrontSpacing.SMALL_3}
                justifyContent="center"
            >
                {/* ICON - LEFT */}
                {props.icon && iconPosition === "left" &&
                    <FrontIcon
                        iconName={props.icon}
                        colour={"neutral-18"}
                        size="small"
                    />
                }

                {/* LABEL */}
                <span>
                    {props.label}
                </span>

                {/* ICON - RIGHT */}
                {props.icon && iconPosition === "right" &&
                    <FrontIcon
                        iconName={props.icon}
                        colour={"neutral-18"}
                        size="small"
                    />
                }
            </SpacingRow>
        </div>
    );
};
