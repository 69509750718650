import React from "react";
import { TFormStatus } from "../../../../../../shared/src/codecs/codec";

type TFrontInputHuge = {
    formStatus: TFormStatus;
    currencySymbol?: string;
    placeholder?: string;
    value: number | null;
    onChange: (value: number | null) => void;
    onPressEnterKey: () => void;
};

const isInputChangeEventAValidNumber = (event: React.ChangeEvent<HTMLInputElement>): boolean =>
    new RegExp(/^[0-9]*$/).test(event.currentTarget.value)
    && (typeof parseInt(event.currentTarget.value) === "number"
    && parseInt(event.currentTarget.value) >= 0)
    && (event.currentTarget.value.length <= 16)
;

const getNumericInputValue = (event: React.ChangeEvent<HTMLInputElement>, currentValue: number | null): number | null => {
    if (event.currentTarget.value === null || event.currentTarget.value === "") {
        return null;
    }
    if (isInputChangeEventAValidNumber(event)) {
        return Number(event.currentTarget.value);
    }

    return currentValue;
};

export class FrontInputCurrencyHuge extends React.Component<React.PropsWithChildren<TFrontInputHuge>> {
    public render (): JSX.Element {
        return (
            <div
                className={`
                    front-input-currency-huge
                    front-input-currency-huge--${this.props.formStatus}
                `
            }>
                <div
                    className={`
                        front-input-currency-huge__symbol
                        front-input-currency-huge__symbol--${this.props.formStatus}
                    `}
                >
                    {this.props.currencySymbol || "£"}
                </div>
                <input
                    className={`
                        front-input-currency-huge__input
                        front-input-currency-huge__input--${this.props.formStatus}
                    `}
                    type="text"
                    inputMode="numeric"
                    value={this.props.value || ""}
                    placeholder={this.props.placeholder || "0"}
                    onChange={(event) => this.props.onChange(getNumericInputValue(event, this.props.value))}
                    onKeyDown={(e) => e.keyCode === 13 && this.props.onPressEnterKey()}
                />
            </div>
        );
    }
}
