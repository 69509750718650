import { array } from "fp-ts";
import { pipe } from "fp-ts/lib/function";
import React from "react";
import { TMarketingCampaignForm } from "../../../../../domain/codecs/form/MarketingCampaignForm";
import { FormStatus_highestPriority } from "../../../../../shared/src/codecs/codec";
import { CRMColors } from "../../../models/CRMColors";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { Background } from "../../BuildingBlocks/Background";
import { MinHeight } from "../../BuildingBlocks/MinHeight";
import { Padding } from "../../BuildingBlocks/Padding";
import { Relative } from "../../BuildingBlocks/Relative";
import { SpacingRow } from "../../BuildingBlocks/SpacingRow";
import { CRMBottomControlBar } from "../../CRMBottomControlBar/CRMBottomControlBar";
import { CRMButtonPrimary } from "../../CRMButtonPrimary/CRMButtonPrimary";
import { CRMFormButton } from "../../CRMFormButton/CRMFormButton";
import { CRMPopoutHeader } from "../../Simple/CRMPopoutHeader/CRMPopoutHeader";
import { CRMTidyPopout } from "../../Simple/CRMTidyPopout/CRMTidyPopout";
import { CRMMarketingCampaignForm } from "../CRMMarketingCampaignForm/CRMMarketingCampaignForm";

type TMarketingCampaignEditPopoutProps = {
    form: TMarketingCampaignForm;
    onClose: () => void;
    onChange: (form: TMarketingCampaignForm) => void;
    onSave: (form: TMarketingCampaignForm) => void;
};

export const CRMMarketingCampaignEditPopout = (props: React.PropsWithChildren<TMarketingCampaignEditPopoutProps>): JSX.Element => {

    const formStatusForSaveButton = props.form.children.spend_forms_to_be_deleted.length > 0 ?
        "requiresSubmission"
        : pipe(
            [
                props.form.status,
                ...pipe(
                    props.form.children.spend_forms,
                    array.map((form) => form.status),
                ),
            ],
            FormStatus_highestPriority,
        );

    return (
        <Background color={CRMColors.NEUTRAL_PAPER}>
            <Relative>
                <CRMPopoutHeader
                    icon="arrow-left"
                    onClick={props.onClose}
                >
                    Editing {props.form.edited.name || "Introducer"}
                </CRMPopoutHeader>

                <MinHeight height="720px">
                    <Padding spacing={CRMSpacing.LARGE}>
                        <CRMMarketingCampaignForm
                            form={props.form}
                            onChange={props.onChange}
                        />
                    </Padding>
                </MinHeight>

                <CRMBottomControlBar edge="border">
                    <Padding 
                        type="custom"
                        width="100%" 
                        spacing={`${CRMSpacing.TINY} ${CRMSpacing.MEDIUM}`}
                    >
                        <SpacingRow justifyContent="end">
                            <CRMFormButton
                                formStatus={formStatusForSaveButton}
                                ButtonElement={CRMButtonPrimary}
                                label="Save"
                                onClick={() => props.onSave(props.form)}
                            />
                        </SpacingRow>
                    </Padding>
                </CRMBottomControlBar>
            </Relative>
        </Background>
    );
};
