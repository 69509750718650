import React from "react";
import { FrontColors } from "../../../../models/FrontColors";
import { FrontFontSizes } from "../../../../models/FrontFontSizes";
import { FrontSpacing } from "../../../../models/FrontSpacing";
import { Background } from "../../../BuildingBlocks/Background";
import { FontQuicksand } from "../../../BuildingBlocks/FontQuicksand";
import { FontSize } from "../../../BuildingBlocks/FontSize";
import { Padding } from "../../../BuildingBlocks/Padding";
import { SpacingRow } from "../../../BuildingBlocks/SpacingRow";
import { TextColor } from "../../../BuildingBlocks/TextColor";
import { CursorPointer } from "../../../CursorPointer/CursorPointer";
import { WeightSemiBold } from "../../../WeightSemiBold/WeightSemiBold";
import { FrontIcon, TIcon } from "../FrontIcon/FrontIcon";

type TFrontButtonIconProps = {
    label: string,
    iconName?: TIcon,
    iconPosition?: "left" | "right",
    onClick: () => void,
};

export const FrontButtonSecondarySmall = (props: React.PropsWithChildren<TFrontButtonIconProps>): JSX.Element => {
    const iconPosition =
        props.iconPosition || "right";

    return <Background
        borderRadius="5px"
        borderDefinition={`solid 1px ${FrontColors.NEUTRAL_INK}`}
        borderBottomDefinition={`solid 2px ${FrontColors.NEUTRAL_INK}`}
        width="fit-content"
    >
        <CursorPointer onClick={props.onClick}>
            <Padding spacing={`${FrontSpacing.SMALL_2} ${FrontSpacing.SMALL_3}`}>
                <SpacingRow
                    justifyContent="start"
                    alignItems="center"
                    spacing={FrontSpacing.SMALL_2}
                >
                    {/* ICON - LEFT */}
                    {props.iconName && iconPosition === "left" &&
                        <FrontIcon
                            iconName={props.iconName}
                            colour="neutral-ink"
                            size="tiny"
                        />
                    }

                    {/* LABEL */}
                    <FontQuicksand>
                        <WeightSemiBold>
                            <FontSize size={FrontFontSizes.SMALL}>
                                <TextColor color={FrontColors.NEUTRAL_INK}>
                                    {props.label}
                                </TextColor>
                            </FontSize>
                        </WeightSemiBold>
                    </FontQuicksand>

                    {/* ICON - RIGHT */}
                    {props.iconName && iconPosition === "right" &&
                        <FrontIcon
                            iconName={props.iconName}
                            colour="neutral-ink"
                            size="tiny"
                        />
                    }
                </SpacingRow>
            </Padding>
        </CursorPointer>
    </Background>;
};
