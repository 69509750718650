import React, { useEffect, useState } from 'react';

import {
    EditorState,
    CompositeDecorator,
    getDefaultKeyBinding,
} from 'draft-js';
import { useActiveSuggestionProfile } from './useActiveSuggestionProfile';
import { TDraftJsDecoratorTypeUpdated, TSuggestionProfile, TSyntheticKeyboardEvent } from './Types';
import { useFocusedSuggestion } from './useFocusedSuggestion';
import { isAFunction } from '../../../../functions/functions';

export type TKeyBindCallbackPlain = (event: TSyntheticKeyboardEvent) => void;
export type TKeyBindCallback<T extends Object> = (props: TKeyboardCallbackProps<T>) => "add-entity" | null;
export type TKeyboardCallbackProps<T extends Object> = {
    event: TSyntheticKeyboardEvent, 
    suggestion: T | undefined;
} 

export type TAutocompleteProps<T extends Object> = 
    Draft.EditorProps & 
    { 
        suggestionProfiles: Array<TSuggestionProfile<T>>,
        onKeyBindTab?: TKeyBindCallback<T>;
        onKeyBindRightArrow?: TKeyBindCallback<T>;
        onKeyBindEnterDuringAutocomplete: TKeyBindCallback<T>;
        onKeyBindEnter?: TKeyBindCallbackPlain;
    }
;

export const DraftJsAutocompleteWrapper = <T extends Object>(props: React.PropsWithChildren<TAutocompleteProps<T>>): JSX.Element => {

    useEffect(() => {
        const decorator = makeCompositeDecorator();
        const newEditorState = EditorState.set(props.editorState, { decorator });
        props.onChange(newEditorState);
    }, []);

    const {
        activeProfile,
        clearActiveProfile,
        hasActiveProfile,
    } = useActiveSuggestionProfile<T>({
        editorState: props.editorState,
        suggestionProfiles: props.suggestionProfiles,
        onChange: () => recalibrateFocusedSuggestion()
    });

    const {
        focusedSuggestionIndex,
        focusOnNextSuggestion,
        focusOnPreviousSuggestion,
        recalibrateFocusedSuggestion,
        resetFocusedSuggestion,
        getFocusedSuggestion,
        insertFocusedSuggestion,
        onInsertSuggestion,
    } = useFocusedSuggestion<T>({ 
        activeProfile,
        editorState: props.editorState,
        onChange: (newEditorState: EditorState) => {
            props.onChange(newEditorState);
            clearActiveProfile();
        }
    })

    const makeCompositeDecorator = (): Draft.CompositeDecorator =>
        new CompositeDecorator(
            props.suggestionProfiles.reduce(
                (decorators, profile) => decorators.concat([
                    getInsertedSuggestionDecorator(profile),
                ]), 
                getAllEditorDecorators()
            )
        )
    ;

    const getInsertedSuggestionDecorator = (profile: TSuggestionProfile<T>): Draft.DraftDecorator => ({
        component: profile.insertedComponent,
        strategy: (contentBlock, callback, contentState) => {
            contentBlock.findEntityRanges(
                (character) => {
                    const entityKey = character.getEntity();
                    if (entityKey === null) {
                        return false;
                    }
                    return contentState.getEntity(entityKey).getType() === profile.name;
                },
                callback
            );
        }
    })

    const getAllEditorDecorators = (): Array<Draft.DraftDecorator> => {
        const decorators = props.editorState.getDecorator() as TDraftJsDecoratorTypeUpdated;
        return !!decorators ? decorators._decorators || [] : [];
    }

    const clearProfileAndSuggestions = () => {
        clearActiveProfile();
        resetFocusedSuggestion();
    }

    const getCurrentTimestamp = () => Math.floor(new Date().getTime()/1000);

    const onKeyCommand = (callback: () => void, propCallbackName: string) =>
        (event: TSyntheticKeyboardEvent) => {
            if (hasActiveProfile()) {
                event.preventDefault();
                callback();
            }

            if (!!props[propCallbackName]) {
                props[propCallbackName]({
                    event,
                    suggestion: getFocusedSuggestion()
                });
            }
        } 

    const onDownArrow = onKeyCommand(focusOnNextSuggestion, "onDownArrow");
    const onUpArrow = onKeyCommand(focusOnPreviousSuggestion, "onUpArrow");
    const onEscape = onKeyCommand(clearProfileAndSuggestions, "onEscape",);

    const keyBindingFn = (event: TSyntheticKeyboardEvent) => {
        
        if (event.key === "ArrowRight" && hasActiveProfile() && isAFunction<TKeyBindCallback<T>>(props.onKeyBindRightArrow)) {
            return props.onKeyBindRightArrow({
                event,
                suggestion: getFocusedSuggestion(),
            })
        }

        if (event.key === "Tab" && hasActiveProfile() && isAFunction<TKeyBindCallback<T>>(props.onKeyBindTab)) {
            return props.onKeyBindTab({
                event,
                suggestion: getFocusedSuggestion(),
            });
        }

        if (event.key === "Enter" && hasActiveProfile() && isAFunction<TKeyBindCallback<T>>(props.onKeyBindEnterDuringAutocomplete)) {
            return props.onKeyBindEnterDuringAutocomplete({
                event,
                suggestion: getFocusedSuggestion(),
            });
        }

        if (!event.shiftKey && event.key === "Enter" && !hasActiveProfile() && isAFunction<TKeyBindCallbackPlain>(props.onKeyBindEnter)) {
            props.onKeyBindEnter(event);
            return null;
        }

        if (event.shiftKey && event.key === "Enter") {
            // We make a newline on shift+enter, draftJs calls it split-block.
            // I guess because a newline will split any block the cursor is in.
            return "split-block";
        }

        return getDefaultKeyBinding(event);
    }

    const handleKeyCommand = (command: string) => {
        if (command === 'add-entity') {
            insertFocusedSuggestion();
            return 'handled';
        }

        return props.handleKeyCommand ? 
            props.handleKeyCommand(command, props.editorState, getCurrentTimestamp()) 
            : 'not-handled'
        ;
    }

    return (
        <React.Fragment>

            {/* EDITOR & OTHER CHILDREN */}
            {React.Children.map(
                props.children,
                (child) => React.cloneElement(
                    // @ts-ignore (not sure about the type error on this one, neeed to check and fix)
                    child,
                    {
                        ...props,
                        onDownArrow,
                        onUpArrow,
                        onEscape,
                        keyBindingFn,
                        handleKeyCommand      
                    }
                )
            )}

            {/* SUGGESTIONS */}
            {hasActiveProfile() &&
                <activeProfile.listComponent>
                    {activeProfile.suggestions.length === 0 &&
                        <activeProfile.emptyListComponent/>
                    }

                    {activeProfile.suggestions.length > 0 && activeProfile.suggestions.map((suggestion, index) => (
                        <activeProfile.itemComponent
                            key={index}
                            item={suggestion} 
                            current={focusedSuggestionIndex === index}
                            onClick={() => onInsertSuggestion(suggestion, activeProfile)}
                        />
                    ))}
                </activeProfile.listComponent>
            }
        </React.Fragment>
    )
}
