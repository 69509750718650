import React, { useEffect, useState } from "react";
import { IntroducerForm, TIntroducerCreateForm, TIntroducerExistingAgreementForm, TIntroducerForm, TIntroducersForm, TIntroducerUploadAgreementForm } from "../../../../../domain/codecs/form/IntroducerForm";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { MinHeight } from "../../BuildingBlocks/MinHeight";
import { Padding } from "../../BuildingBlocks/Padding";
import { SpacingRow } from "../../BuildingBlocks/SpacingRow";
import { CRMBottomControlBar } from "../../CRMBottomControlBar/CRMBottomControlBar";
import { CRMButtonQuaternary } from "../../CRMButtonQuaternary/CRMButtonQuaternary";
import { CRMIntroducerEditPopout } from "../CRMIntroducerEditPopout/CRMIntroducerEditPopout";
import { CRMIntroducerSingleView } from "../CRMIntroducerSingleView/CRMIntroducerSingleView";
import { CRMIntroducerTable } from "../CRMIntroducerTable/CRMIntroducerTable";
import { CRMBlockScrollCacheView, CRMBlockScrollResetView } from "../../CRM/CRMBlock/CRMBlockViews/CRMBlockViews";
import { CRMBlockPopover } from "../../CRM/CRMBlock/CRMBlockPopover/CRMBlockPopover";

type TIntroducerBlockViewStatus =
    "table"
    | "view-introducer"
;

type TIntroducerBlockPopupStatus = 
    "none"
    | "edit-introducer"
;

type TIntroducersBlockProps = {
    introducerIdOpen: string | null;
    form: TIntroducersForm;
    onUploadAgreement: (form: TIntroducerUploadAgreementForm) => void;
    onDeleteUploadAgreement: (form: TIntroducerExistingAgreementForm) => void;
    onAddNewIntroducer: (form: TIntroducerCreateForm) => void;
    onSave: (form: TIntroducerForm) => void; 
    onChange: (form: TIntroducerForm) => void;
    onArchive:  (form: TIntroducerForm) => void;
    onLoadMore: () => void;
    onOpen: (id: string) => void;
    onClose: () => void;
};

export const CRMIntroducersBlock = (props: React.PropsWithChildren<TIntroducersBlockProps>): JSX.Element => {

    const [viewStatus, setViewStatus] = useState<TIntroducerBlockViewStatus>("table");
    const [popupViewStatus, setPopupViewStatus] = useState<TIntroducerBlockPopupStatus>("none")

    useEffect(
        () => {
            const newIntroducer = props.form.children.introducers.find((form) => form.original.name === "");
            if (
                props.form.children.create_introducer_form.status === "success" 
                && !!newIntroducer
            ) {
                props.onOpen(newIntroducer.edited.id);
                setPopupViewStatus("edit-introducer");
            }
        },
        [props.form.children.introducers]
    );

    const getOpenIntroducer = (): TIntroducerForm => {
        const introducer = props.form.children.introducers.find((form) => form.original.id === props.introducerIdOpen);
        return introducer || IntroducerForm.newDefault();
    }

    const onArchive = (form: TIntroducerForm) => {
        setViewStatus("table");
        props.onArchive(form);
    }

    const onChangeIntroducer = (form: TIntroducerForm) => props.onChange(form);

    const onOpenIntroducer = (introducer: TIntroducerForm) => { 
        props.onOpen(introducer.edited.id);
        setViewStatus("view-introducer");
    }

    const onEditIntroducer = (introducer: TIntroducerForm) => {
        props.onOpen(introducer.edited.id);
        setPopupViewStatus("edit-introducer");
    }

    const onCloseSingleView = () => {
        props.onClose();
        setViewStatus("table");
    }
    
    const onClosePopup = () => {
        props.onClose();
        setPopupViewStatus("none");
    }

    const onSubmitNewIntroducer = () => props.onAddNewIntroducer(props.form.children.create_introducer_form);

    return (
        <>
            {/* POPOUT - EDIT INTRODUCER */}
            {popupViewStatus === "edit-introducer" && 
                <CRMBlockPopover
                    padding={CRMSpacing.MEDIUM}
                    height="745px" 
                    maxHeight="745px"
                >
                    <CRMIntroducerEditPopout
                        form={getOpenIntroducer()}
                        onDeleteUploadAgreement={props.onDeleteUploadAgreement}
                        onUploadAgreement={props.onUploadAgreement}
                        onClose={onClosePopup}
                        onChange={props.onChange}
                        onSave={props.onSave}
                    />
                </CRMBlockPopover>
            }

            {/* VIEW - TABLE */}
            <CRMBlockScrollCacheView isShowing={viewStatus === "table"}>
                <MinHeight height="702px">
                    <CRMIntroducerTable
                        form={props.form}
                        onOpen={onOpenIntroducer}
                        onEdit={onEditIntroducer}
                        onArchive={onArchive}
                        onLoadMore={props.onLoadMore}
                    />
                </MinHeight>

                <CRMBottomControlBar>
                    <Padding 
                        type="custom"
                        width="100%" 
                        spacing={`${CRMSpacing.TINY} ${CRMSpacing.MEDIUM}`}
                    >
                        <SpacingRow justifyContent="end">
                            <CRMButtonQuaternary 
                                icon="add"
                                label="Add Introducer"
                                onClick={onSubmitNewIntroducer}
                            />
                        </SpacingRow>
                    </Padding>
                </CRMBottomControlBar>
            </CRMBlockScrollCacheView>

            {/* VIEW - INTRODUCER */}
            <CRMBlockScrollResetView isShowing={viewStatus === "view-introducer"}>
                <CRMIntroducerSingleView
                    form={getOpenIntroducer()}
                    onDeleteUploadAgreement={props.onDeleteUploadAgreement}
                    onUploadAgreement={props.onUploadAgreement}
                    onClose={onCloseSingleView}
                    onChange={onChangeIntroducer}
                    onArchive={onArchive}
                    onSave={props.onSave}
                />
            </CRMBlockScrollResetView>
        </>
    );
};
