import React from "react";
import { array } from "fp-ts";
import { pipe } from "fp-ts/lib/pipeable";
import { CRMCardOutsidePastOffer } from "../../components/CRMCardOutsidePastOffer/CRMCardOutsidePastOffer";
import { CRMCardOutsideCurrentOffer } from "../../components/CRMCardOutsideCurrentOffer/CRMCardOutsideCurrentOffer";
import { CRMTitleClear } from "../../components/CRMTitleClear/CRMTitleClear";
import { CRMDeferButtonComponent } from "../../components/CRMDeferButtonComponent/CRMDeferButtonComponent";
import { Uppercase } from "../../components/Uppercase/Uppercase";
import { TListingEnquiriesOffer2AndListingEnquiriesOfferForm2FormList } from "../../models/TFormModels";
import { makeFormUpdateAction } from "../../state/applyActions";
import { TContainerStateProps } from "../../state/TContainerStateProps";
import { SpacingColumn } from "../../components/BuildingBlocks/SpacingColumn";
import { CRMSpacing } from "../../models/CRMSpacing";
import { SpacingRow } from "../../components/BuildingBlocks/SpacingRow";
import { CRMFormButton } from "../../components/CRMFormButton/CRMFormButton";
import { CRMButtonQuaternary } from "../../components/CRMButtonQuaternary/CRMButtonQuaternary";
import { FontQuicksand } from "../../components/BuildingBlocks/FontQuicksand";
import { FontSize } from "../../components/BuildingBlocks/FontSize";
import { CRMFontSizes } from "../../models/CRMFontSizes";
import { WeightBold } from "../../components/WeightBold/WeightBold";
import { isInvalidDate } from "../../../../shared/src/util";

const BoldParagraph = (props: React.PropsWithChildren<{}>): JSX.Element => (
    <FontQuicksand>
        <FontSize size={CRMFontSizes.SMALL}>
            <WeightBold>
                {props.children}
            </WeightBold>
        </FontSize>
    </FontQuicksand>
);

const CRMListingPerformancePageOffersContainer = (props: TContainerStateProps): JSX.Element => {

    const areThereUnsharedOffers = (): boolean => pipe(
        props.state.activeData.crm.listingPerformance.pendingOrAcceptedOffers.forms.concat(
            props.state.activeData.crm.listingPerformance.withdrawnOrRejectedOffers.forms
        ),
        array.filter((form) => !form.edit.shared_with_seller_at),
        array.isNonEmpty
    );

    return (
        <div className="crm-listing-performance-page-offers">
            <SpacingColumn spacing={CRMSpacing.LARGE}>
                {/* SHARE OFFERS BAR */}
                {areThereUnsharedOffers() &&
                    <SpacingRow spacing={CRMSpacing.MEDIUM}>
                        <BoldParagraph>
                            Share offers:
                        </BoldParagraph>
                        
                        {isInvalidDate(props.state.forms.listing_performance_page.data.output.form.edited.share_offers_deffered) &&
                            <SpacingRow spacing={CRMSpacing.MEDIUM}>
                                <CRMFormButton
                                    ButtonElement={CRMButtonQuaternary}
                                    formStatus={props.state.activeData.crm.listingPerformance.sendPerformanceUpdateForm.status}
                                    label="Mark as done"
                                    onClick={() => props.dispatch({
                                        type: "CRM_LISTING_PERFORMANCE_SEND_PERFORMANCE_UPDATE_CLICKED",
                                        payload: {},
                                    })}
                                />
                                <BoldParagraph>
                                    or
                                </BoldParagraph>
                            </SpacingRow>
                        }
                        
                        <CRMDeferButtonComponent
                            value={props.state.forms.listing_performance_page.data.output.form.edited.share_offers_deffered}
                            onChange={(value) => props.dispatch({
                                type: "CRM_LISTING_PERFORMANCE_SET_OFFERS_SHARE_DEFFER_DATE",
                                payload: value,
                            })}
                            displayError={false}
                        />
                    </SpacingRow>
                }

                {props.state.activeData.crm.listingPerformance.pendingOrAcceptedOffers.forms.length === 0 &&
                    <CRMTitleClear>
                        <Uppercase>
                            No Pending Offers
                        </Uppercase>
                    </CRMTitleClear>
                }

                {/* CURRENT OFFERS */}
                {props.state.activeData.crm.listingPerformance.pendingOrAcceptedOffers.forms.map((offer, index) => (
                    <CRMCardOutsideCurrentOffer
                        key={index}
                        offer={offer}
                        customErrorDisplayTextMap={{
                            "NotUnique": "The offer can not be returned to a pending state because the party already has another pending offer. Only one pending offer per party is allowed.",
                        }}
                        onOpenResolution={() => props.dispatch({
                            type: "CRM_LISTING_PERFORMANCE_BEGIN_SUBMISSION",
                            payload: {
                                resourceId: offer.view.id,
                            },
                        })}
                        onCloseResolution={() => props.dispatch({
                            type: "CRM_LISTING_PERFORMANCE_RESET_CURRENT_OFFER_FORM",
                            payload: {
                                resourceId: offer.view.id,
                            },
                        })}
                        onChangeRejectionReason={(value) => props.dispatch(makeFormUpdateAction<
                            "CRM_LISTING_PERFORMANCE_UPDATE_CURRENT_OFFER_FORM",
                            TListingEnquiriesOffer2AndListingEnquiriesOfferForm2FormList["forms"][number]["edit"],
                            "rejected_reason"
                        >(
                            "CRM_LISTING_PERFORMANCE_UPDATE_CURRENT_OFFER_FORM",
                            "rejected_reason",
                            value,
                            offer.view.id
                        ))}
                        onAccept={() => props.dispatch({
                            type: "CRM_LISTING_PERFORMANCE_CHANGE_CURRENT_OFFER_STATUS",
                            payload: {
                                resourceId: offer.view.id,
                                key: "status",
                                value: "accepted",
                            },
                        })}
                        onReject={() => props.dispatch({
                            type: "CRM_LISTING_PERFORMANCE_CHANGE_CURRENT_OFFER_STATUS",
                            payload: {
                                resourceId: offer.view.id,
                                key: "status",
                                value: "rejected",
                            },
                        })}
                        onRevert={() => props.dispatch({
                            type: "CRM_LISTING_PERFORMANCE_CHANGE_CURRENT_OFFER_STATUS",
                            payload: {
                                resourceId: offer.view.id,
                                key: "status",
                                value: "pending",
                            },
                        })}
                        onShare={() => props.dispatch({
                            type: "CRM_LISTING_PERFORMANCE_MARK_CURRENT_OFFER_AS_SHARED",
                            payload: {
                                resourceId: offer.view.id,
                                key: "shared_with_seller_at",
                                value: new Date().toISOString(),
                            },
                        })}
                    />
                ))}

                {/* IF PAST OFFERS: DIVIDING LINE - PAST AND PRESENT */}
                {props.state.activeData.crm.listingPerformance.withdrawnOrRejectedOffers.forms.length > 0 &&
                    <div className="crm-listing-performance-page-offers__past-present-line"></div>
                }
                {/* PAST OFFERS */}
                {props.state.activeData.crm.listingPerformance.withdrawnOrRejectedOffers.forms.map((offerForm, index) => (
                    <CRMCardOutsidePastOffer
                        key={index}
                        offer={offerForm}
                        customErrorDisplayTextMap={{
                            "NotUnique": "crm_offer_can_not_be_returned_to_pending_because_pending",
                        }}
                        onOpenResolution={() => props.dispatch({
                            type: "CRM_LISTING_PERFORMANCE_BEGIN_PAST_OFFER_SUBMISSION",
                            payload: {
                                resourceId: offerForm.view.id,
                            },
                        })}
                        onCloseResolution={() => props.dispatch({
                            type: "CRM_LISTING_PERFORMANCE_RESET_PAST_OFFER_FORM",
                            payload: {
                                resourceId: offerForm.view.id,
                            },
                        })}
                        onReturnToPending={() => props.dispatch({
                            type: "CRM_LISTING_PERFORMANCE_CHANGE_PAST_OFFER_STATUS",
                            payload: {
                                resourceId: offerForm.view.id,
                                key: "status",
                                value: "pending",
                            },
                        })}
                    />
                ))}
            </SpacingColumn>
        </div>
    );
}

export default CRMListingPerformancePageOffersContainer;
