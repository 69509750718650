import React from "react";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { Background } from "../../BuildingBlocks/Background";
import { SpacingColumn } from "../../BuildingBlocks/SpacingColumn";
import { SpacingRow } from "../../BuildingBlocks/SpacingRow";
import { CRMButtonPrimary } from "../../CRMButtonPrimary/CRMButtonPrimary";
import { CRMCardInside } from "../../CRMCardInside/CRMCardInside";
import { CRMParagraph } from "../../Simple/CRMParagraph/CRMParagraph";
import { WeightBold } from "../../WeightBold/WeightBold";
import { WeightMedium } from "../../WeightMedium/WeightMedium";
import { TLandRegistryTitleSuggestion } from "../../../../../domain/codecs/form/CasePropertyForm";
import { Tenure1_Tenure2 } from "../../../../../domain/codecs/Tenure";
import { WeightSemiBold } from "../../WeightSemiBold/WeightSemiBold";

export const CRMCaseLandRegistryTitleSuggestion = (props: React.PropsWithChildren<{
    suggestion: TLandRegistryTitleSuggestion,
    onSelect: (value: TLandRegistryTitleSuggestion) => void,
}>): JSX.Element =>
    <CRMCardInside>
        <Background padding={CRMSpacing.MEDIUM}>
            <SpacingRow
                justifyContent="space-between"
                spacing={CRMSpacing.MEDIUM}
            >
                {/* TITLE NUMBER & TENURE */}
                <SpacingColumn spacing={CRMSpacing.TINY}>
                    <SpacingRow
                        justifyContent="start"
                        spacing={CRMSpacing.MEDIUM}
                    >
                        <CRMParagraph>
                            <WeightSemiBold>
                                {Tenure1_Tenure2(props.suggestion.tenure).toUpperCase()}
                            </WeightSemiBold>
                        </CRMParagraph>
                        
                        <CRMParagraph>
                            <WeightMedium>
                                {props.suggestion.title_number}
                            </WeightMedium>
                        </CRMParagraph>
                    </SpacingRow>

                    <CRMParagraph>
                        <WeightBold>
                            {props.suggestion.address}
                        </WeightBold>
                    </CRMParagraph>
                </SpacingColumn>

                {/* CONTROL */}
                <CRMButtonPrimary
                    label="Select"
                    onClick={() => props.onSelect(props.suggestion)}
                />
            </SpacingRow>
        </Background>
    </CRMCardInside>
