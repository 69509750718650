import * as rxjs from "rxjs";
import * as rxjsOperators from "rxjs/operators";
import * as util from "../../util";
import * as TForm from "../../models/TForm";
import { payloadFetch, TFormRequest } from "../request";
import { TState, TSubmitMethod, TModelFile } from "./lensBaseTypes";
import { reduceDataToStateUpdate } from "./reduceDataToStateUpdate";
import { set } from "./set";
import { get } from "./get";
import { TGetState } from "../../state/TGetState";
import { TSetState } from "../../state/TSetState";
import { TActionPayload } from "../../state/actions/TAction";

interface ILensSubmitForForm<A extends string, P, V extends Record<string, unknown>, S> {
    // 5 level lens path guard
    <
        K1 extends keyof S,
        K2 extends keyof S[K1],
        K3 extends keyof S[K1][K2],
        K4 extends keyof S[K1][K2][K3],
        K5 extends keyof S[K1][K2][K3][K4]
    >
    (
        path: [K1, K2, K3, K4, K5],
        urlCallback: (
            form: S[K1][K2][K3][K4][K5],
            data: P,
            getState: TGetState
        ) => string,
        payloadCallback: (
            form: S[K1][K2][K3][K4][K5],
            data: P,
            getState: TGetState
        ) => unknown,
        method: TSubmitMethod,
        responseModel: TModelFile<V>,
    ): (
        obs$: rxjs.Observable<TActionPayload<A, P>>,
        getState: TGetState,
        setState: TSetState
    ) => rxjs.Observable<unknown>;
    // 4 level lens path guard
    <
        K1 extends keyof S,
        K2 extends keyof S[K1],
        K3 extends keyof S[K1][K2],
        K4 extends keyof S[K1][K2][K3]
    >
    (
        path: [K1, K2, K3, K4],
        urlCallback: (
            form: S[K1][K2][K3][K4],
            data: P,
            getState: TGetState
        ) => string,
        payloadCallback: (
            form: S[K1][K2][K3][K4],
            data: P,
            getState: TGetState
        ) => unknown,
        method: TSubmitMethod,
        responseModel: TModelFile<V>,
    ): (
        obs$: rxjs.Observable<TActionPayload<A, P>>,
        getState: TGetState,
        setState: TSetState,
    ) => rxjs.Observable<unknown>;
    // 3 level lens path guard
    <
        K1 extends keyof S,
        K2 extends keyof S[K1],
        K3 extends keyof S[K1][K2]
    >
    (
        path: [K1, K2, K3],
        urlCallback: (
            form: S[K1][K2][K3],
            data: P,
            getState: TGetState
        ) => string,
        payloadCallback: (
            form: S[K1][K2][K3],
            data: P,
            getState: TGetState
        ) => unknown,
        method: TSubmitMethod,
        responseModel: TModelFile<V>,
    ): (
        obs$: rxjs.Observable<TActionPayload<A, P>>,
        getState: TGetState,
        setState: TSetState,
    ) => rxjs.Observable<unknown>;
    // 2 level lens path guard
    <
        K1 extends keyof S,
        K2 extends keyof S[K1]
    >
    (
        path: [K1, K2],
        urlCallback: (
            form: S[K1][K2],
            data: P,
            getState: TGetState
        ) => string,
        payloadCallback: (
            form: S[K1][K2],
            data: P,
            getState: TGetState
        ) => unknown,
        method: TSubmitMethod,
        responseModel: TModelFile<V>,
    ): (
        obs$: rxjs.Observable<TActionPayload<A, P>>,
        getState: TGetState,
        setState: TSetState,
    ) => rxjs.Observable<unknown>;
    // 1 level lens path guard
    <K1 extends keyof S>
    (
        path: [K1],
        urlCallback: (
            form: S[K1],
            data: P,
            getState: TGetState
        ) => string,
        payloadCallback: (
            form: S[K1],
            data: P,
            getState: TGetState
        ) => unknown,
        method: TSubmitMethod,
        responseModel: TModelFile<V>,
    ): (
        obs$: rxjs.Observable<TActionPayload<A, P>>,
        getState: TGetState,
        setState: TSetState
    ) => rxjs.Observable<unknown>;
}

export const submitForForm =
    <A extends string, P, V extends Record<string, unknown>>(): ILensSubmitForForm<A, P, V, TState> =>
        (
            // There is no way to create paramater overloads here so we set to any
            lensPath: any, // eslint-disable-line
            urlCallback: any, // eslint-disable-line
            payloadCallback: any, // eslint-disable-line
            requestMethod: TSubmitMethod,
            responseModel: TModelFile<V>,
        ) =>
            (
                obs$: rxjs.Observable<TActionPayload<A, P>>,
                getState: TGetState,
                setState: TSetState
            ) =>
                obs$.pipe(
                    rxjsOperators.mergeMap((action) =>
                        payloadFetch<V>(
                            urlCallback(get()(lensPath, getState), action.payload, getState),
                            requestMethod,
                            payloadCallback(get()(lensPath, getState), action.payload, getState),
                            responseModel.newDefault(),
                            responseModel.codec,
                        )()
                    ),
                    rxjsOperators.tap<TFormRequest<V>>((req) => util.defaultCRMRequestErrorHandler(req)),
                    rxjsOperators.tap<TFormRequest<V>>(
                        reduceDataToStateUpdate<TFormRequest<V>>(setState)(
                            set<TFormRequest<V>>()(
                                lensPath,
                                /* eslint-disable */
                                // The type is always expected to be never for the form, since we have abstraced the lens scoping,
                                // which is untrue
                                // @ts-ignore
                                (form, req) => TForm.dataToForm(form.defaultEdit, {})(req.response.data),
                                /* eslint-enable */
                            )
                        )
                    ),
                );
