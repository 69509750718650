import React, {  } from "react";
import { FrontSpacing } from "../../../../models/FrontSpacing";
import FrontInputGeneralComponent from "../../Simple/FrontInputGeneralComponent/FrontInputGeneralComponent";
import FrontInputLabelAndErrorWrapComponent from "../../Simple/FrontInputLabelAndErrorWrapComponent/FrontInputLabelAndErrorWrapComponent";
import { SpacingColumn } from "../../../BuildingBlocks/SpacingColumn";
import { onChangeForm } from "../../../../../../shared/src/codecs/types/form";
import { FrontFormRow } from "../../Simple/FrontFormRow/FrontFormRow";
import { TClientCaseRelatedTransactionAddressForm } from "../../../../../../domain/codecs/form/ClientCaseForm";
import { doesErrorKeyExist } from "../../../../../../shared/src/codecs/errors";

type TProps = {
    form: TClientCaseRelatedTransactionAddressForm;
    onChange: (form: TClientCaseRelatedTransactionAddressForm) => void;
    displayValidationErrorsIfPresent: boolean;
};

export const FrontRelatedTransactionConveyancerDetails = (props: React.PropsWithChildren<TProps>): JSX.Element => {
    const onChange = onChangeForm(props.form, props.onChange);

    const conveyancerOrganisationNameHasError = () => props.displayValidationErrorsIfPresent && props.form.edited.conveyancer_organisation_name === "";
    const conveyancerNameHasError = () => props.displayValidationErrorsIfPresent && props.form.edited.conveyancer_name === "";
    const conveyancerPhoneNumberHasError = () => (props.displayValidationErrorsIfPresent && props.form.edited.conveyancer_phone_number === null) || doesErrorKeyExist("edited.conveyancer_phone_number", props.form.validationErrors);
    const conveyancerEmailHasError = () => (props.displayValidationErrorsIfPresent && props.form.edited.conveyancer_email_address === null) || doesErrorKeyExist("edited.conveyancer_email_address", props.form.validationErrors);

    return (
        <div>
            <SpacingColumn spacing={FrontSpacing.MEDIUM_1}>
                <FrontFormRow>
                    <FrontInputLabelAndErrorWrapComponent label="Practice / Organisation name" errorMessage="Tell us who your conveyancer works for" displayError={conveyancerOrganisationNameHasError()}>
                        <FrontInputGeneralComponent
                            inputType="text"
                            value={props.form.edited.conveyancer_organisation_name}
                            placeholder=""
                            onChange={onChange("conveyancer_organisation_name")}
                            displayError={conveyancerOrganisationNameHasError()}
                        />
                    </FrontInputLabelAndErrorWrapComponent>
                    
                    <FrontInputLabelAndErrorWrapComponent label="Your conveyancer's name" errorMessage="Who is handling your sale?" displayError={conveyancerNameHasError()}>
                        <FrontInputGeneralComponent
                            inputType="text"
                            value={props.form.edited.conveyancer_name}
                            placeholder=""
                            onChange={onChange("conveyancer_name")}
                            displayError={conveyancerNameHasError()}
                        />
                    </FrontInputLabelAndErrorWrapComponent>
                </FrontFormRow>

                <FrontFormRow>
                    <FrontInputLabelAndErrorWrapComponent label="Conveyancer's phone number" errorMessage="Please enter a valid phone number" displayError={conveyancerPhoneNumberHasError()}>
                        <FrontInputGeneralComponent
                            inputType="tel"
                            value={props.form.edited.conveyancer_phone_number || ""}
                            placeholder=""
                            onChange={(value) => onChange("conveyancer_phone_number")(value || null)}
                            displayError={conveyancerPhoneNumberHasError()}
                        />
                    </FrontInputLabelAndErrorWrapComponent>
                    
                    <FrontInputLabelAndErrorWrapComponent label="Conveyancer's Email" errorMessage="Please enter a valid email address" displayError={conveyancerEmailHasError()}>
                        <FrontInputGeneralComponent
                            inputType="email"
                            value={props.form.edited.conveyancer_email_address || ""}
                            placeholder=""
                            onChange={(value) => onChange("conveyancer_email_address")(value || null)}
                            displayError={conveyancerEmailHasError()}
                        />
                    </FrontInputLabelAndErrorWrapComponent>
                </FrontFormRow>
            </SpacingColumn>
        </div>
    );
};