import { array, identity, option } from "fp-ts";
import { pipe } from "fp-ts/lib/function";
import { useEffect, useState } from "react";
import { 
    TriageSimpleEmailForm, 
    TTriageBulkEmailMarkAsIrrelevantForm, 
    TDetailedEmailForm, 
    TTriageEmailAssignToCaseAndUserForm, 
    TTriageEmailAssignToCaseForm, 
    TTriageEmailAssignToCaseSearchForm, 
    TTriageEmailAssignToUserOnlyForm, 
    TTriageEmailMarkAsIrrelevantForm, 
    TTriageEmailMarkAsResolvedForm, 
    TTriageForm,  
    TTriageSimpleEmailForm, 
    TTriageEmailDisassociateFromCaseForm
} from "../../../domain/codecs/form/TriageForm";
import { requireExhaustive } from "../../../shared/src/util";
import { isAFunction } from "../functions/functions";

type TLoadStatus = "resolve-next" | "load-next" | "resolve-load-next" | "none";

export const useTriage = <A extends string>(props: {
    triageTypeVisible: A,
    getTriageFormToUseCallback: (triageTypeVisible: A) => TTriageForm,
    
    onDisassociate?: (form: TTriageEmailDisassociateFromCaseForm, triageType: A) => void;
    onArchive: (form: TTriageEmailMarkAsIrrelevantForm, triageType: A) => void,
    onResolve: (form: TTriageEmailMarkAsResolvedForm, triageType: A) => void;
    onBulkArchive: (form: TTriageBulkEmailMarkAsIrrelevantForm, triageType: A) => void,
    onAssignToPerson: (form: TTriageEmailAssignToUserOnlyForm, triageType: A) => void,
    onAssignToCaseAndMe: (form: TTriageEmailAssignToCaseAndUserForm, triageType: A) => void,
    onAssignToCaseAndStaff: (form: TTriageEmailAssignToCaseAndUserForm, triageType: A) => void,
    onAssignToCaseAndHandler: (form: TTriageEmailAssignToCaseAndUserForm, triageType: A) => void,
    onAssignToCaseAndResolve: (form: TTriageEmailAssignToCaseForm, triageType: A) => void,
    
    onOpenEmail: (email: TTriageSimpleEmailForm, triageType: A) => void,
    onReply: (form: TDetailedEmailForm) => void,
    onForward: (form: TDetailedEmailForm) => void,
    onLoadMore: (triageType: A) => void,
    onChangeSearchCaseQuery: (form: TTriageEmailAssignToCaseSearchForm, triageType: A) => void,
}) => {
    
    const [openEmailIndex, setOpenEmailIndex] = useState(-1);
    const [loadStatus, setLoadStatus] = useState<TLoadStatus>("none");

    const getTriageFormToUse = () => props.getTriageFormToUseCallback(props.triageTypeVisible);

    const getEmailByIndexOption = (index: number) => 
        !!getTriageFormToUse().children.emails[index] ? 
            option.some(getTriageFormToUse().children.emails[index]) :
            option.none
    ;
    
    const getEmailIndexByID = (emailId: string | null): number => pipe(
        getTriageFormToUse().children.emails,
        array.findIndex((email) => email.original.id === emailId),
        option.fold(
            () => -1,
            identity.flatten
        )
    );

    const doesNextEmailNeedFetching = (currentIndex) => currentIndex === getTriageFormToUse().children.emails.length-1;

    const getEmailByIndexChange = (indexAdjustor: (index: number) => number) => 
        (index) => pipe(
            index,
            indexAdjustor,
            getEmailByIndexOption,
            option.fold(
                () => TriageSimpleEmailForm.newDefault(),
                identity.flatten 
            )
        )
    ;

    const navigateToNextEmail = (currentIndex: number, fetchStatus: "load-next" | "resolve-next") => {
    
        if (doesNextEmailNeedFetching(currentIndex)) {
            setLoadStatus(fetchStatus);
            onLoadMore();
        } else {
            setLoadStatus("none");
            onOpenEmail(pipe(
                currentIndex,
                getEmailByIndexChange((index) => index + 1)
            ));
        }
    }

    const navigateToPreviousEmail = (currentIndex: number) => 
        onOpenEmail(pipe(
            currentIndex,
            getEmailByIndexChange((index) => index === 0 ? 0 : index -1)
        ))
    ;

    const setLoadStatusForNextEmail = () => setLoadStatus(
        doesNextEmailNeedFetching(openEmailIndex) ? "resolve-load-next" : "resolve-next"
    );
    
    
    // CHECKS
    const isShowingNext = () => openEmailIndex < getTriageFormToUse().children.counts.available_count-1;
    
    const isShowingPrevious = () => openEmailIndex > 0;
    
    const isPresentEmailSetToOpen = (): boolean => pipe(
        getEmailByIndexOption(openEmailIndex),
        option.fold(
            () => false,
            () => true
        )
    );


    // ACTIONS
    const closeSingleView = () => setOpenEmailIndex(-1);

    const onPrevious = () => navigateToPreviousEmail(openEmailIndex);

    const onNext = () => navigateToNextEmail(openEmailIndex, "load-next");

    const onArchive = (form: TTriageEmailMarkAsIrrelevantForm) => {
        setLoadStatusForNextEmail();
        props.onArchive(form, props.triageTypeVisible);
    }
    
    const onDisassociate = (form: TTriageEmailMarkAsIrrelevantForm) => {
        setLoadStatusForNextEmail();
        if (isAFunction<(form: TTriageEmailDisassociateFromCaseForm, triageType: A) => void>(props.onDisassociate)) {
            props.onDisassociate(form, props.triageTypeVisible);
        }
    }
    
    const onResolve = (form: TTriageEmailMarkAsResolvedForm) => {
        setLoadStatusForNextEmail();
        props.onResolve(form, props.triageTypeVisible);
    }

    
    const onAssignToPerson = (form: TTriageEmailAssignToUserOnlyForm) => {
        setLoadStatusForNextEmail();
        props.onAssignToPerson(form, props.triageTypeVisible);
    }
    
    const onAssignToCaseAndMe = (form: TTriageEmailAssignToCaseAndUserForm) => {
        setLoadStatusForNextEmail();
        props.onAssignToCaseAndMe(form, props.triageTypeVisible);
    }
    
    const onAssignToCaseAndStaff = (form: TTriageEmailAssignToCaseAndUserForm) => {
        setLoadStatusForNextEmail();
        props.onAssignToCaseAndStaff(form, props.triageTypeVisible);
    }
    
    const onAssignToCaseAndHandler = (form: TTriageEmailAssignToCaseAndUserForm) => {
        setLoadStatusForNextEmail();
        props.onAssignToCaseAndHandler(form, props.triageTypeVisible);
    }
    
    const onAssignToCaseAndResolve = (form: TTriageEmailAssignToCaseForm) => {
        setLoadStatusForNextEmail();
        props.onAssignToCaseAndResolve(form, props.triageTypeVisible);
    }

    const onBulkArchive = (form: TTriageBulkEmailMarkAsIrrelevantForm) => {
        props.onBulkArchive(form, props.triageTypeVisible);
    }

    const onLoadMore = () => {
        props.onLoadMore(props.triageTypeVisible);
    }

    const onOpenEmail = (email: TTriageSimpleEmailForm) => {
        setOpenEmailIndex(getEmailIndexByID(email.original.id));
        if (email.children.detailed_email_form.status !== "success" || props.triageTypeVisible === "UNRESOLVED") {
            props.onOpenEmail(email, props.triageTypeVisible);
        }
    }
    
    const onChangeSearchText = (search_term: string) => {
        props.onChangeSearchCaseQuery({
            ...getOpenedEmailSearchCaseForm(),
            edited: {
                ...getOpenedEmailSearchCaseForm().edited,
                search_term,
            }
        }, props.triageTypeVisible);
    }

    const getOpenedEmail = (): TTriageSimpleEmailForm => 
        pipe(
            getEmailByIndexOption(openEmailIndex),
            option.fold(
                () => TriageSimpleEmailForm.newDefault(),
                identity.flatten
            )
        );

    const getOpenedEmailSearchCaseForm = () => 
        getOpenedEmail().children.detailed_email_form.children.assign_to_case_search_form
    ;

    useEffect(
        () => {
            switch (loadStatus) {
                case "load-next":
                    navigateToNextEmail(openEmailIndex, "load-next");
                    break;
                case "resolve-next":
                    navigateToNextEmail(openEmailIndex-1, "load-next"); 
                    break;
                case "resolve-load-next":
                    navigateToNextEmail(openEmailIndex-1, "resolve-next"); 
                    break;
                case "none":
                    break;
                default:
                    requireExhaustive(loadStatus);
            }
        },
        [getTriageFormToUse().children.emails]
    );

    return {
        getTriageFormToUse,
        isPresentEmailSetToOpen,
        getOpenedEmail,
        isShowingNext,
        isShowingPrevious,
        onOpenEmail,
        onDisassociate,
        onArchive,
        onResolve,
        onBulkArchive,
        onLoadMore,
        onChangeSearchText,
        closeSingleView,
        onNext,
        onPrevious,
        onAssignToCaseAndHandler,
        onAssignToCaseAndMe,
        onAssignToCaseAndResolve,
        onAssignToCaseAndStaff,
        onAssignToPerson,
    };
};
