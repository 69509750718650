import React from "react";

export const WordBreak = (props: React.PropsWithChildren<{}>): JSX.Element =>
    (
        <div
            className="word-break"
            style={{
                overflowWrap: "break-word",
                wordWrap: "break-word",
                wordBreak: "break-word",
                hyphens: "auto"
            }}
        >
            {props.children}
        </div>
    );