import * as Enviroment from "./models/Enviroment";

// IMPORTANT - Remeber that when bringing in external strings as part of regexes you need to escape the special characters

const escapeRegExp = (string: string) => string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string

const validURLPartsRegexOrString = (env: Enviroment.T): string => {
    const parts: Array<string> = [];
    const domainNameEscaped = escapeRegExp(Enviroment.getDomainNameFromEnv(env));
    if (domainNameEscaped !== "") {
        parts.push(`https://[a-zA-Z0-9-]+\\.${domainNameEscaped}|https://${domainNameEscaped}`);
    }
    const sailProbateDomainNameEscaped = escapeRegExp(Enviroment.getSailProbateDomainNameFromEnv(env));
    if (sailProbateDomainNameEscaped !== "") {
        parts.push(`https://[a-zA-Z0-9-]+\\.${sailProbateDomainNameEscaped}|https://${sailProbateDomainNameEscaped}`);
    }
    const sailLegalDomainNameEscaped = escapeRegExp(Enviroment.getSailLegalDomainNameFromEnv(env));
    if (sailLegalDomainNameEscaped !== "") {
        parts.push(`https://[a-zA-Z0-9-]+\\.${sailLegalDomainNameEscaped}|https://${sailLegalDomainNameEscaped}`);
    }
    const joinedParts = parts.join("|");
    if (joinedParts !== "") {
        return joinedParts;
    }
    // Handle missing vars being empty and allowing possibly weird strings
    return "https://sailhomes.co.uk";
};

export const constants = {
    VALID_SAILHOMES_CORS_URL: (env: Enviroment.T): string =>
        `^(${validURLPartsRegexOrString(env)})$`,
    VALID_SAILHOMES_REDIRECT_URL: (env: Enviroment.T): string =>
        `^(${validURLPartsRegexOrString(env)})[A-Za-z0-9/?_&%=+\-|*!()~]*$`,
    VALID_SAILHOMES_EMAIL: (env: Enviroment.T): string =>
        `^.*@${escapeRegExp(Enviroment.getDomainNameFromEnv(env))}$`,
    VALID_SAILLEGAL_EMAIL: (env: Enviroment.T): string =>
    `^.*@((${escapeRegExp(Enviroment.getSailLegalDomainNameFromEnv(env))})|(saillegal\\.co\\.uk))$`,
};
