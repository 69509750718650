import { pipe } from "fp-ts/lib/pipeable";
import { TRoadblockAction } from "../../../../../domain/codecs/form/RoadblockForm";
import { areISODatesNoMoreThanNUnitsApart } from "../../../../../shared/src/utilsByDomain/dateTime/areISODatesNoMoreThanNUnitsApart";
import { areISODatesOnTheSameDay } from "../../../../../shared/src/utilsByDomain/dateTime/areISODatesOnTheSameDay";
import { updateAtOrElse } from "../../../../../shared/src/utilsByDomain/array/updateAtOrElse";
import { array, identity, option } from "fp-ts";
import { getDateDistanceSortNumber } from "../../../../../shared/src/utilsByDomain/dateTime";

type TMinuteCluster = {
    date: string;
    userID: string | null;
    actions: Array<TRoadblockAction>
};

type TMinuteClusters = Array<TMinuteCluster>;

type TDayCluster = {
    date: string;
    minuteClusters: TMinuteClusters;
};

type TDayClusters = Array<TDayCluster>;

const doesActionFitMinuteCluster = (action: TRoadblockAction) => 
    (minuteCluster: TMinuteCluster) => {
        const isSameUser = minuteCluster.userID === action.action.created_by;
        const isWithinTimeCluster = areISODatesNoMoreThanNUnitsApart({
            unit: "minutes",
            amount: 5,
            dateA: minuteCluster.date,
            dateB: action.action_date
        });

        return isSameUser && isWithinTimeCluster;
    }
;

const doesMinuteClusterFitDayCluster = (minuteCluster: TMinuteCluster) => 
    (dayCluster: TDayCluster) => 
        areISODatesOnTheSameDay(minuteCluster.date, dayCluster.date)
;

const findMinuteClusterIndexActionBelongsTo = (minuteClusters: TMinuteClusters, action: TRoadblockAction) => {
    return pipe(
        minuteClusters,
        array.findIndex(doesActionFitMinuteCluster(action)),
        option.fold(() => -1, identity.flatten)
    );
}

const findDayClusterIndexMinuteClusterBelongsTo = (dayClusters: TDayClusters, minuteCluster: TMinuteCluster) => {
    return pipe(
        dayClusters,
        array.findIndex(doesMinuteClusterFitDayCluster(minuteCluster)),
        option.fold(() => -1, identity.flatten)
    );
}

export const groupActionsIntoMinuteClusters = (actions: Array<TRoadblockAction>) => pipe(
    actions,
    array.reduce(
        [] as TMinuteClusters,
        (minuteClusters, action) => pipe(
            minuteClusters,
            updateAtOrElse(
                findMinuteClusterIndexActionBelongsTo(minuteClusters, action),
                (cluster) => ({
                    ...cluster,
                    actions: cluster.actions.concat(action) 
                }),
                (set) => set.concat({
                    date: action.action_date,
                    userID: action.action.created_by,
                    actions: [action]
                })
            )
        ),
    )
);

export const groupMinuteClustersIntoDayClusters = (minuteClusters: TMinuteClusters) => pipe(
    minuteClusters,
    array.reduce(
        [] as TDayClusters,
        (dayClusters, minuteCluster) => pipe(
            dayClusters,
            updateAtOrElse(
                findDayClusterIndexMinuteClusterBelongsTo(dayClusters, minuteCluster),
                (dayCluster) => ({
                    ...dayCluster,
                    minuteClusters: dayCluster.minuteClusters.concat(minuteCluster)
                }),
                (set) => set.concat({
                    date: minuteCluster.date,
                    minuteClusters: [minuteCluster]
                })
            )
        )
    )
);

export const sortDayClustersByRecency = (clusters: TDayClusters) => 
    clusters.sort((clusterA, clusterB) => getDateDistanceSortNumber(clusterA.date, clusterB.date))
;