import React from "react";
import { DateTime } from "luxon";
import * as EnquiryPaymentMethodDisplayName from "../../../../domain/models/EnquiryPaymentMethodDisplayName";
import * as BuyerReadyHasProofOfFundsForCurrentOffer from "../../../../domain/models/BuyerReadyHasProofOfFundsForCurrentOffer";
import * as PartyPurchasingSituation from "../../../../domain/models/PartyPurchasingSituation";
import * as User10 from "../../../../domain/models/User10";
import { CRMCardOutside, TCardBackgroundColour } from "../CRMCardOutside/CRMCardOutside";
import { CRMSpacer } from "../CRMSpacer/CRMSpacer";
import { CRMParagraph } from "../Simple/CRMParagraph/CRMParagraph";
import { array } from "fp-ts";
import { WeightBold } from "../WeightBold/WeightBold";
import { WeightMedium } from "../WeightMedium/WeightMedium";
import { CRMTitleParagraphMono } from "../CRMTitleParagraphMono/CRMTitleParagraphMono";
import { Uppercase } from "../Uppercase/Uppercase";
import { CRMButtonFlatColourIcon } from "../CRMButtonFlatColourIcon/CRMButtonFlatColourIcon";
import { TListingEnquiriesOffer2AndListingEnquiriesOfferForm2FormList } from "../../models/TFormModels";
import { CRMOfferChecksIcons } from "../CRMOfferChecksIcons/CRMOfferChecksIcons";
import { CRMTitleOfferCard } from "../CRMTitleOfferCard/CRMTitleOfferCard";
import { CRMCardOutsidePopupFormSubmit } from "../CRMCardOutsidePopupFormSubmit/CRMCardOutsidePopupFormSubmit";
import CRMInputLabelAndErrorWrapComponent from "../CRMInputLabelAndErrorWrapComponent/CRMInputLabelAndErrorWrapComponent";
import CRMTextAreaComponent from "../CRMTextAreaComponent/CRMTextAreaComponent";
import { TJsonInnerErrorCodeTranslationRecord } from "../../../../domain/models/JsonInnerError1";
import { CRMLink } from "../CRMLink/CRMLink";
import { CRMFormErrorComponent } from "../CRMFormErrorComponent/CRMFormErrorComponent";
import { CRMUniversalBulletPoints } from "../CRMUniversalBulletPoints/CRMUniversalBulletPoints";
import * as JsonError1 from "../../../../domain/models/JsonError1";
import { pipe } from "fp-ts/lib/function";
import { SpacingColumn } from "../BuildingBlocks/SpacingColumn";
import { CRMSpacing } from "../../models/CRMSpacing";
import { SpacingRow } from "../BuildingBlocks/SpacingRow";
import { CRMFontSizes } from "../../models/CRMFontSizes";
import { Absolute } from "../BuildingBlocks/Absolute";
import { Lowercase } from "../BuildingBlocks/Lowercase";
import { CRMButtonIconSwipeWrap } from "../CRMButtonIconSwipeWrap/CRMIconSwipeButton";
import { CRMIconBinary } from "../CRMIconBinary/CRMIconBinary";
import { CRMColors } from "../../models/CRMColors";

type TProps = {
    offer: TListingEnquiriesOffer2AndListingEnquiriesOfferForm2FormList["forms"][number];
    customErrorDisplayTextMap?: TJsonInnerErrorCodeTranslationRecord;
    onAccept: () => void;
    onReject: () => void;
    onRevert: () => void;
    onShare: () => void;
    onOpenResolution: () => void;
    onCloseResolution: () => void;
    onChangeRejectionReason: (value: string) => void;
};

type TPopupMode = "accept" | "revert" | "reject" | null;

type TState = {
    areResolutionButtonsShowing: boolean;
    popupMode: "accept" | "revert" | "reject" | null;
};

export class CRMCardOutsideCurrentOffer extends React.Component<React.PropsWithChildren<TProps>, TState> {

    constructor(props: TProps) {
        super(props);
        this.state = {
            areResolutionButtonsShowing: false,
            popupMode: null,
        };
    }

    getPaymentMethodDisplayName = (): EnquiryPaymentMethodDisplayName.T =>
        EnquiryPaymentMethodDisplayName.fromEnquiryPaymentMethod1(this.props.offer.view.enquiry.payment_method)
    ;

    hasProofOfFunds = (): boolean => BuyerReadyHasProofOfFundsForCurrentOffer.fromBuyerOffer1({
        ...this.props.offer.edit,
        ...this.props.offer.view,
    });

    hasIdCheck = (): boolean => this.props.offer.view.enquiry.id_check_complete;

    isNewOffer = (): boolean => this.props.offer.edit.shared_with_seller_at === null;

    isAccepted = (): boolean => this.props.offer.edit.status === "accepted";

    getCardBackgroundColour = (): TCardBackgroundColour => {
        if (this.isAccepted()) {
            return "highlights-positive-green-12";
        }

        if (!this.hasProofOfFunds()) {
            return "neutral-12";
        }

        return "neutral-paper";
    };

    toggleResolutionButtons = (): void => this.setState({
        areResolutionButtonsShowing: !this.state.areResolutionButtonsShowing,
    });

    onPopupSubmit = (): void => {
        if (this.state.popupMode === "accept") {
            this.props.onAccept();
        }
        if (this.state.popupMode === "reject") {
            this.props.onReject();
        }
        if (this.state.popupMode === "revert") {
            this.props.onRevert();
        }
    };

    onOpenPopup = (popupMode: TPopupMode): void => {
        this.setState({ popupMode });
        this.props.onOpenResolution();
    };

    doBuyerUserErrorsExist = (): boolean =>
        pipe(
            JsonError1.getAllTargetKeys(this.props.offer.validationErrors),
            array.filter((targetKey) => targetKey.substr(0, 10) === "buyerUsers"),
            (a) => a.length > 0,
        );

    doSellerUserErrorsExist = (): boolean =>
        pipe(
            JsonError1.getAllTargetKeys(this.props.offer.validationErrors),
            array.filter((targetKey) => targetKey.substr(0, 11) === "sellerUsers"),
            (a) => a.length > 0,
        );

    noSellerInfoErrorsExist = (): boolean =>
        pipe(
            JsonError1.getAllTargetKeys(this.props.offer.validationErrors),
            array.filter((targetKey) => targetKey.substr(0, 16) === "legal_seller_set"),
            (a) => a.length > 0,
        );
    
    getBuyerNamesAsCopyText = () => pipe(
        this.props.offer.view.party.users,
        array.map(User10.toDisplayName),
        (names) => names.join(" & ")
    );

    getOfferDateCopyText = () =>
        DateTime.fromISO(this.props.offer.view.created_at).setZone("Europe/London").toFormat('dd/L/yy');

    getPurchasingSituationCopyText = () =>
        PartyPurchasingSituation.fromValueToDisplayName(this.props.offer.view.party.purchasing_situation)
    ;
        
    public render (): JSX.Element {
        return (
            <div className="crm-card-outside-current-offer">
                 {/* POPUP: ACCEPT OFFER */}
                 <CRMCardOutsidePopupFormSubmit
                    formStatus={this.props.offer.status}
                    validationErrors={this.props.offer.validationErrors}
                    context="positive"
                    hideErrorMessages={true}
                    isOpen={this.props.offer.status !== "untouched" && this.state.popupMode === "accept"}
                    title="Accept Offer"
                    ctaText="Accept"
                    onCTA={this.props.onAccept}
                    onClose={this.props.onCloseResolution}
                >
                    <CRMParagraph>
                        Are you sure you want to do this?
                    </CRMParagraph>
                    {this.props.offer.validationErrors.length === 1 && this.props.offer.validationErrors[0].error_code === "ListingAlreadyHasAnOfferAcceptedValidation" ? <>
                        <CRMSpacer size="medium" />
                        <CRMFormErrorComponent
                            errorMessage="This listing already has an accepted offer. That offer must be returned to pending before you can accept another one."
                        />
                    </> : this.props.offer.validationErrors.length > 0 ? <>
                        <CRMSpacer size="medium" />
                        <CRMFormErrorComponent
                            errorMessage="The following must be completed first before you can accept the offer."
                        />
                        <CRMSpacer size="tiny" />
                        <CRMUniversalBulletPoints
                            size="normal"
                            points={[
                                ...JsonError1.doTargetKeysHaveInnerErrors(["listing.seller_conveyancer_organisation_name"], this.props.offer.validationErrors)
                                    || JsonError1.doTargetKeysHaveInnerErrors(["listing.seller_conveyancer_name"], this.props.offer.validationErrors)
                                    || JsonError1.doTargetKeysHaveInnerErrors(["listing.seller_conveyancer_address"], this.props.offer.validationErrors)
                                    || JsonError1.doTargetKeysHaveInnerErrors(["listing.seller_conveyancer_phone_number"], this.props.offer.validationErrors)
                                    || JsonError1.doTargetKeysHaveInnerErrors(["listing.seller_conveyancer_email_address"], this.props.offer.validationErrors)
                                        ? [<CRMParagraph>The seller's conveyancer details</CRMParagraph>]
                                        : [],
                                ...JsonError1.doTargetKeysHaveInnerErrors(["listingEnquiry.conveyancer_organisation_name"], this.props.offer.validationErrors)
                                    || JsonError1.doTargetKeysHaveInnerErrors(["listingEnquiry.conveyancer_name"], this.props.offer.validationErrors)
                                    || JsonError1.doTargetKeysHaveInnerErrors(["listingEnquiry.conveyancer_address"], this.props.offer.validationErrors)
                                    || JsonError1.doTargetKeysHaveInnerErrors(["listingEnquiry.conveyancer_phone_number"], this.props.offer.validationErrors)
                                    || JsonError1.doTargetKeysHaveInnerErrors(["listingEnquiry.onveyancer_email_address"], this.props.offer.validationErrors)
                                        ? [<CRMParagraph>The purchaser's conveyancer details</CRMParagraph>]
                                        : [],
                                ...JsonError1.doTargetKeysHaveInnerErrors(["listingEnquiry.purchasers_address"], this.props.offer.validationErrors)
                                    ? [<CRMParagraph>The purchaser's address</CRMParagraph>]
                                    : [],
                                ...JsonError1.doTargetKeysHaveInnerErrors(["listing.tenure"], this.props.offer.validationErrors)
                                    ? [<CRMParagraph>The listing's tenure</CRMParagraph>]
                                    : [],
                                ...JsonError1.doTargetKeysHaveInnerErrors(["listing"], this.props.offer.validationErrors)
                                    ? [<CRMParagraph>The Aventria reference</CRMParagraph>]
                                    : [],
                                ...this.doBuyerUserErrorsExist()
                                    ? [<CRMParagraph>The purchaser's legal names</CRMParagraph>]
                                    : [],
                                ...this.doSellerUserErrorsExist()
                                    ? [<CRMParagraph>The seller's legal names and Id Check (or GoP)</CRMParagraph>]
                                    : [],
                                ...this.noSellerInfoErrorsExist()
                                    ? [<CRMParagraph>Who the legal sellers are / the seller's company name</CRMParagraph>]
                                    : [],
                            ]}
                        />
                    </>
                    : <></>}
                </CRMCardOutsidePopupFormSubmit>

                {/* POPUP: REJECT OFFER */}
                 <CRMCardOutsidePopupFormSubmit
                    formStatus={this.props.offer.status}
                    validationErrors={this.props.offer.validationErrors}
                    context="important"
                    isOpen={this.props.offer.status !== "untouched" && this.state.popupMode === "reject"}
                    title="Reject Offer"
                    ctaText="Reject"
                    onCTA={this.props.onReject}
                    onClose={this.props.onCloseResolution}
                >
                    <CRMParagraph>
                        <CRMInputLabelAndErrorWrapComponent
                            label="Reason for rejecting offer"
                        >
                            <CRMTextAreaComponent
                                value={this.props.offer.edit.rejected_reason}
                                onChange={this.props.onChangeRejectionReason}
                                displayError={false}
                            />
                        </CRMInputLabelAndErrorWrapComponent>
                    </CRMParagraph>
                </CRMCardOutsidePopupFormSubmit>

                {/* POPUP: REVERT TO PENDING */}
                <CRMCardOutsidePopupFormSubmit
                    formStatus={this.props.offer.status === "untouched" ? "requiresSubmission" : this.props.offer.status}
                    validationErrors={this.props.offer.validationErrors}
                    customErrorDisplayTextMap={this.props.customErrorDisplayTextMap}
                    context="warning"
                    isOpen={this.props.offer.status !== "untouched" && this.state.popupMode === "revert"}
                    title="Return offer to pending?"
                    onCTA={this.props.onRevert}
                    ctaText="Return to pending"
                    onClose={this.props.onCloseResolution}
                    closeText="Cancel"
                >
                    <CRMParagraph>
                        Are you sure you want to return this offer back to pending?
                    </CRMParagraph>
                </CRMCardOutsidePopupFormSubmit>

                {/* CARD */}
                <CRMCardOutside
                    borderRounding="right"
                    backgroundColor={this.getCardBackgroundColour()}
                >
                    <div className="crm-card-outside-current-offer__card">

                        {this.isNewOffer() &&
                            <Absolute top="0px" right="0px">
                                <CRMButtonIconSwipeWrap
                                    title="Mark as shared"
                                    iconName="done-all"
                                    backgroundColour="highlights-positive-green-2"
                                    iconColour="neutral-paper"
                                    onClick={this.props.onShare}
                                >
                                    <CRMIconBinary
                                        completedState={true}
                                        iconName="new"
                                        size="extra-large"
                                    />
                                </CRMButtonIconSwipeWrap>
                            </Absolute>
                        }

                        {/* CARD TITLE */}
                        <CRMTitleOfferCard offer={this.props.offer} />
                        <CRMSpacer size="tiny" />

                        <SpacingColumn spacing={CRMSpacing.MEDIUM}>
                            {/* PAYMENT METHOD (AS SUB TITLE) */}
                            <CRMTitleParagraphMono>
                                <WeightMedium>
                                    <SpacingRow spacing={CRMSpacing.TINY}>
                                        {this.getPaymentMethodDisplayName()}
                                        {this.getPaymentMethodDisplayName() === "Mortgage" && this.props.offer.view.enquiry.funds_ltv_percentage &&
                                            <span>
                                                ({this.props.offer.view.enquiry.funds_ltv_percentage}% LTV)
                                            </span>
                                        }
                                    </SpacingRow>
                                </WeightMedium>
                            </CRMTitleParagraphMono>

                            {/* OFFER CONDITION (AS PARAGRAPH) */}
                            {this.props.offer.view.conditions &&
                                <div className="crm-card-outside-current-offer__condition">
                                    <CRMParagraph>
                                        <WeightBold>Conditions:</WeightBold> {this.props.offer.view.conditions}
                                    </CRMParagraph>
                                </div>
                            }

                            {/* OFFER CHECKS (AS ICONS) */}
                            <CRMOfferChecksIcons
                                offer={this.props.offer} 
                            />

                            {/* OFFER MADE ON DATE */}
                            <SpacingColumn spacing={CRMSpacing.TINY}>
                                {/* PURCHASING SITUATION, OFFER DATE, BUYER NAMES & PARTY LINK */}
                                <SpacingColumn spacing={"3px"}>
                                    <SpacingRow spacing={CRMSpacing.TINY}>
                                        <CRMParagraph fontSize={CRMFontSizes.SMALL}>
                                            Made on <WeightBold>{this.getOfferDateCopyText()}</WeightBold> by
                                        </CRMParagraph>

                                        {this.props.offer.view.party.purchasing_situation !== "unknown" &&
                                            <CRMParagraph>
                                                <Lowercase>
                                                    a <WeightBold>{this.getPurchasingSituationCopyText()}</WeightBold>
                                                </Lowercase>
                                            </CRMParagraph>
                                        }
                                    </SpacingRow>

                                    <CRMParagraph>
                                        <CRMLink
                                            href={`/crm/enquiries/${this.props.offer.view.enquiry.id}`}
                                            target="_blank"
                                            linkStyle="normal"
                                        >
                                            <Uppercase>
                                                <WeightBold>
                                                    {this.getBuyerNamesAsCopyText() || "Names not given" }
                                                </WeightBold>
                                            </Uppercase>
                                        </CRMLink>
                                    </CRMParagraph>
                                </SpacingColumn>
                            </SpacingColumn>
                        </SpacingColumn>


                        {/* ACCEPTED OFFER, RESOLUTION BUTTONS */}
                        <div className="crm-card-outside-current-offer__buttons">
                            {/* REVERT */}
                            <CRMButtonFlatColourIcon
                                title="Revert offer acceptance"
                                size="small"
                                backgroundColor={CRMColors.HIGHLIGHTS_POSITIVE_GREEN_8}
                                icon="repeat"
                                onClick={() => this.onOpenPopup("revert")}
                            />
                        </div>

                        {/* PENDING OFFER, RESOLUTION BUTTONS */}
                        {!this.isAccepted() &&
                            <div className="crm-card-outside-current-offer__buttons">
                                {/* ACCEPT */}
                                <div
                                    className={`
                                        crm-card-outside-current-offer__resolve-button
                                        crm-card-outside-current-offer__resolve-button--${this.state.areResolutionButtonsShowing ? 'showing' : 'hiding'}
                                    `}
                                >
                                    <CRMButtonFlatColourIcon
                                        title="Accept"
                                        size="large"
                                        backgroundColor={CRMColors.HIGHLIGHTS_POSITIVE_GREEN_8}
                                        icon="done-outline"
                                        onClick={() => this.onOpenPopup("accept")}
                                    />
                                </div>
                                {/* REJECT */}
                                <div
                                    className={`
                                        crm-card-outside-current-offer__resolve-button
                                        crm-card-outside-current-offer__resolve-button--${this.state.areResolutionButtonsShowing ? 'showing' : 'hiding'}
                                    `}
                                >
                                    <CRMButtonFlatColourIcon
                                        title="Reject"
                                        size="large"
                                        backgroundColor={CRMColors.HIGHLIGHTS_INSTRUCTIONAL_BRICK_6}
                                        icon="not-interested"
                                        onClick={() => this.onOpenPopup("reject")}
                                    />
                                </div>
                                {/* TOGGLE RESOLUTION BUTTONS BUTTON */}
                                <CRMButtonFlatColourIcon
                                    title={this.state.areResolutionButtonsShowing ?
                                        "Hide offer resolution buttons" :
                                        "Show offer resolution buttons"
                                    }
                                    size="small"
                                    backgroundColor={CRMColors.HIGHLIGHTS_CALMING_PINK_10}
                                    icon={this.state.areResolutionButtonsShowing ? "minus" : "dynamic"}
                                    onClick={this.toggleResolutionButtons.bind(this)}
                                />
                            </div>
                        }
                    </div>
                </CRMCardOutside>
            </div>
        );
    }
}
