import React, { useEffect } from "react";

type TFrontOutsidePopupBlank = {
    isOpen: boolean;
    onClose?: () => void;
};

export const FrontOutsidePopupBlock = (props: React.PropsWithChildren<TFrontOutsidePopupBlank>): JSX.Element => {
    
    const shadowBoxRef = React.createRef<HTMLDivElement>();

    useEffect(
        () => {
            if (props.isOpen) {
                document.body.style.overflow = "hidden";
            }
            
            if (!props.isOpen) {
                document.body.style.overflow = "auto";
            }
            
            return () => {
                document.body.style.overflow = "auto";
            }
        }, 
        [props.isOpen]
    );

    return (
        <div
            ref={shadowBoxRef}
            className={`
                front-outside-popup-block 
                front-outside-popup-block--${props.isOpen ? 'open' : 'closed'}
            `}
            onClick={(event) => {
                if (event.target === shadowBoxRef.current && typeof props.onClose === "function") {
                    props.onClose();
                }
            }}
        >
            <div className={`front-outside-popup-block__box`}>
                {props.children}
            </div>
        </div>
    );
}
