import React from "react";
import { TContainerStateProps } from "../../state/TContainerStateProps";
import { Background } from "../../components/BuildingBlocks/Background";
import { FrontColors } from "../../models/FrontColors";
import { Flex } from "../../components/BuildingBlocks/Flex";
import { Padding } from "../../components/BuildingBlocks/Padding";
import { FrontSpacing } from "../../models/FrontSpacing";
import { FPPropertyReportForm } from "../../components/FrontProbate/PropertyReport/FPPropertyReportForm";
import { FPPropertyReportTitleSelect } from "../../components/FrontProbate/PropertyReport/FPPropertyReportTitleSelect";
import { FPPropertyReportResults } from "../../components/FrontProbate/PropertyReport/FPPropertyReportResults";
import { pipe } from "fp-ts/lib/function";
import { array, option } from "fp-ts";
import { PublicPropertyReportCreateForm, PublicPropertyReportTitleSearchForm, TPublicPropertyReportAddressSearchForm, TPublicPropertyReportCreateForm, TPublicPropertyReportTitleSearchForm } from "../../../../domain/codecs/form/PublicPropertyReportForm";
import { FPPropertyReportTransactionTypeQuestion } from "../../components/FrontProbate/PropertyReport/FPPropertyReportTransactionTypeQuestion";
import { FPPropertyReportValuationQuestion } from "../../components/FrontProbate/PropertyReport/FPPropertyReportValuationQuestion";
import { FPPropertyReportAddressSelect } from "../../components/FrontProbate/PropertyReport/FPPropertyReportAddressSelect";
import { FPPropertyReportExecutorOrBeneficiary } from "../../components/FrontProbate/PropertyReport/FPPropertyReportExecutorOrBeneficiary";

export const FrontProbatePropertyReportContainer = (props: TContainerStateProps): JSX.Element => {
    const view = props.state.forms.public_property_report_page.view;

    const selectedAddressId = props.state.forms.public_property_report_page.selected_address_id;

    const addressForm = props.state.forms.public_property_report_page.form;

    const selectedTitleSearchForm: TPublicPropertyReportTitleSearchForm =
        pipe(
            addressForm.children.results,
            array.findFirst(({edited}) => edited.id === selectedAddressId),
            option.getOrElse(() => PublicPropertyReportTitleSearchForm.newDefault()),
        );

    const visibleTitleNumber = props.state.forms.public_property_report_page.visible_title_number;

    const visiblePropertyReportCreateForm: TPublicPropertyReportCreateForm =
        pipe(
            visibleTitleNumber !== null
            && selectedAddressId
            && selectedTitleSearchForm.children.response?._tag === "success"
                ? pipe(
                    selectedTitleSearchForm.children.response.results,
                    array.findFirst((result) => result.edited.title_number === visibleTitleNumber)
                )
                : option.none,
            option.fold(
                PublicPropertyReportCreateForm.newDefault,
                (f) => f,
            ),
        );

    const onSubmitInitialForm = () =>
        props.dispatch({
            type: "PUBLIC_PROPERTY_REPORT_INITIAL_FORM_CONTINUE_PRESSED",
        });

    const onChangeAddressSearchForm =
        (payload: TPublicPropertyReportAddressSearchForm) =>
            props.dispatch({
                type: "PUBLIC_PROPERTY_REPORT_ADDRESS_SEARCH_CHANGE",
                payload,
            });

    const onSelectTransactionType =
        (payload: TPublicPropertyReportAddressSearchForm) =>
            props.dispatch({
                type: "PUBLIC_PROPERTY_REPORT_TRANSACTION_TYPE_CHANGE",
                payload,
            });

    const onSelectWantValuation =
        (payload: TPublicPropertyReportAddressSearchForm) =>
            props.dispatch({
                type: "PUBLIC_PROPERTY_REPORT_WANTS_VALUATION_CHANGE",
                payload,
            });

    const onSubmitAddressSearchForm = () =>
        props.dispatch({
            type: "PUBLIC_PROPERTY_REPORT_ADDRESS_SEARCH_SUBMIT",
        });

    const onChangeVisibleTitleNumber =
        (payload) =>
            props.dispatch({
                type: "PUBLIC_PROPERTY_REPORT_VISIBLE_TITLE_NUMBER_CHANGE",
                payload,
            });

    const onSelectAddressId =
        (payload: number) =>
            props.dispatch({
                type: "PUBLIC_PROPERTY_REPORT_SELECTED_ADDRESS_ID_CHANGE",
                payload,
            });

    return (
        <Background
            width="100%"
            minHeight="100vh"
            color={FrontColors.PROBATE_0}
        >
            <Flex justifyContent="center">
                <Padding spacing={FrontSpacing.LARGE_2}>
                    {/* FORM INITIAL */}
                    {view === "form_initial" &&
                        <FPPropertyReportForm
                            selectedAddressId={selectedAddressId}
                            form={addressForm}
                            onChange={onChangeAddressSearchForm}
                            onSubmit={onSubmitInitialForm}
                        />
                    }

                    {/* FORM TRANSACTION TYPE */}
                    {view === "form_transaction_type" &&
                        <FPPropertyReportTransactionTypeQuestion
                            form={addressForm}
                            onSelect={onSelectTransactionType}
                        />
                    }

                    {/* FORM WANTS VALUATION */}
                    {view === "form_wants_valuation" &&
                        <FPPropertyReportValuationQuestion
                            form={addressForm}
                            onSelect={onSelectWantValuation}
                        />
                    }

                    {/* FORM EXECUTOR OR BENEFICIARY */}
                    {view === "form_executor_or_beneficiary" &&
                        <FPPropertyReportExecutorOrBeneficiary
                            form={addressForm}
                            onChange={onChangeAddressSearchForm}
                            onSubmit={onSubmitAddressSearchForm}
                        />
                    }

                    {/* ADDRESS SELECT */}
                    {view === "address_select" &&
                        <FPPropertyReportAddressSelect
                            form={addressForm}
                            onSelect={onSelectAddressId}
                        />
                    }

                    {/* TITLE SELECT */}
                    {view === "title_select" &&
                        <FPPropertyReportTitleSelect
                            form={selectedTitleSearchForm}
                            onSelect={onChangeVisibleTitleNumber}
                        />
                    }

                    {/* RESULTS */}
                    {view === "results" &&
                        <FPPropertyReportResults
                            form={visiblePropertyReportCreateForm}
                        />
                    }
                </Padding>
            </Flex>
        </Background>
    );
};
