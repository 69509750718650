import React from "react";
import { HorizontalLine } from "../../BuildingBlocks/HorizontalLine";
import { CRMColors } from "../../../models/CRMColors";
import { Sticky } from "../../BuildingBlocks/Sticky";
import { Background } from "../../BuildingBlocks/Background";
import { Padding } from "../../BuildingBlocks/Padding";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { CRMParagraph } from "../../Simple/CRMParagraph/CRMParagraph";
import { CRMFontSizes } from "../../../models/CRMFontSizes";
import { WeightMedium } from "../../WeightMedium/WeightMedium";
import { Absolute } from "../../BuildingBlocks/Absolute";
import { DateTime } from "luxon";
import { SpacingRow } from "../../BuildingBlocks/SpacingRow";
import { TPixelSize } from "../../../models/StringLiterals";

export const CRMRoadblockActionDateSeparator = (props: React.PropsWithChildren<{
    top?: TPixelSize;
    date: string;
}>): JSX.Element => {
    const dateFormatted =
        DateTime.fromISO(props.date).setZone("Europe/London").toISODate() === DateTime.utc().setZone("Europe/London").toISODate()
            ? "Today"
            : DateTime.fromISO(props.date).setZone("Europe/London").toFormat("d MMM");

    return <>
        {/* LINE */}
        <HorizontalLine color={CRMColors.NEUTRAL_8} />

        {/* PILL */}
        <Sticky top={`${props.top || '23px'}`}>
            <Absolute
                width="100%"
                top="-17px"
            >
                <SpacingRow
                    alignItems="center"
                    justifyContent="center"
                >
                    <Background
                        color={CRMColors.NEUTRAL_PAPER}
                        borderRadius="25px"
                        borderDefinition={`solid 1px ${CRMColors.NEUTRAL_4}`}
                        height="34px"
                        minWidth="80px"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Padding spacing={`0px ${CRMSpacing.MEDIUM}`}>
                            <CRMParagraph fontSize={CRMFontSizes.SMALL}>
                                <WeightMedium>
                                    {dateFormatted}
                                </WeightMedium>
                            </CRMParagraph>
                        </Padding>
                    </Background>
                </SpacingRow>
            </Absolute>
        </Sticky>
    </>
};
