import React from "react";
import { TQuoteRateDeleteOnNextPublishForm } from "../../../../../domain/codecs/form/QuoteRateForm";
import { TUuidForm } from "../../../../../domain/codecs/form/UuidForm";
import { useHover } from "../../../hooks/useHover";
import { CRMColors } from "../../../models/CRMColors";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { Absolute } from "../../BuildingBlocks/Absolute";
import { Background } from "../../BuildingBlocks/Background";
import { Relative } from "../../BuildingBlocks/Relative";
import { SpacingRow } from "../../BuildingBlocks/SpacingRow";
import { CRMIcon } from "../../CRMIcon/CRMIcon";
import { CursorPointer } from "../../CursorPointer/CursorPointer";
import { CRMQuoteRateCardDetail } from "../CRMQuoteRateCardDetail/CRMQuoteRateCardDetail";

export const CRMDeleteOnNextPublishQuoteRateCard = (props: React.PropsWithChildren<{
    form: TQuoteRateDeleteOnNextPublishForm,
    onUndoDeleteOnNextPublish: (form: TUuidForm) => void,
    onOpen: (form: TQuoteRateDeleteOnNextPublishForm) => void,
}>): JSX.Element => {
    const [ref, isHovering] = useHover<HTMLDivElement>({});

    return <Background
        divRef={ref}
        borderRadius="3px"
        color={CRMColors.NEUTRAL_PAPER}
        padding={CRMSpacing.MEDIUM}
    >
        <Relative width="100%">
            <CursorPointer onClick={() => props.onOpen(props.form)}>
                <SpacingRow
                    spacing={CRMSpacing.SMALL}
                    justifyContent="start"
                    alignItems="flex-start"
                >
                    {/* ICON */}
                    <Background
                        borderRadius="5px"
                        color={CRMColors.HIGHLIGHTS_INSTRUCTIONAL_BRICK_12}
                        padding={CRMSpacing.TINY}
                    >
                        <CRMIcon
                            iconName="minus"
                            size="large"
                            colour="highlights-instructional-brick-0"
                        />
                    </Background>

                    {/* DETAIL */}
                    <CRMQuoteRateCardDetail
                        form={props.form.original}
                    />
                </SpacingRow>
            </CursorPointer>

            {/* CONTROL */}
            {isHovering &&
                <Absolute
                    top="0px"
                    right="0px"
                >
                    <CRMIcon
                        iconName="undo"
                        size="medium"
                        colour="neutral-ink"
                        onClick={() => props.onUndoDeleteOnNextPublish(props.form.children.undo_delete_on_next_publish_form)}
                    />
                </Absolute>
            }
        </Relative>
    </Background>
}
