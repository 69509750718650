import React from "react";
import { CRMSpacingRow } from "../../../CRMSpacingRow/CRMSpacingRow";
import { TLegalEmailDecryptedWithBody } from "../../../../../../domain/codecs/LegalEmail";
import { CRMInputCheckboxComponent } from "../../../CRMInputCheckboxComponent/CRMInputCheckboxComponent";
import { CRMIcon } from "../../../CRMIcon/CRMIcon";
import { onCallIfExists } from "../../../../functions/functions";
import { CRMSpacingColumn } from "../../../CRMSpacingColumn/CRMSpacingColumn";
import { CRMCaseEnquiryEmailSummary } from "../CRMCaseEnquiryEmailSummary/CRMCaseEnquiryEmailSummary";

type TCRMEmailAttachmentProps = {
    mode: "selection" | "summary";
    email: TLegalEmailDecryptedWithBody;
    selected?: boolean;
    onSelect?: () => void;
    onDelete?: () => void;
    onViewEmail: () => void;
};

export const CRMCaseEnquiryEmailAttachment = (props: TCRMEmailAttachmentProps): JSX.Element => (
    <div className={`crm-email-attachment`}>
        <CRMSpacingRow spacing="medium" alignItems="flex-start">
            {/* IF MODE IS SELECTION: CHECKBOX */}
            {props.mode === "selection" &&
                <CRMInputCheckboxComponent
                    checked={props.selected || false}
                    onClick={onCallIfExists(props.onSelect)}
                />
            }
            {/* IF MODE IS SUMMARY: DELETE ICON */}
            {props.mode === "summary" &&
                <CRMIcon
                    iconName="delete"
                    colour="neutral-ink"
                    onClick={props.onDelete}
                />
            }
            {/* DATE, SUBJECT AND FROM/TO SUMMARY */}
            <CRMSpacingColumn spacing="tiny">
                <CRMCaseEnquiryEmailSummary
                    email={props.email}
                    onClickViewEmailIcon={props.onViewEmail}
                />
            </CRMSpacingColumn>
        </CRMSpacingRow>
    </div>
);
