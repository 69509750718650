import { DateTime, DurationUnit } from "luxon"

// areISODatesWithinNUnitsOfEachOther
export const areISODatesNoMoreThanNUnitsApart = (props: {
    dateA: string;
    dateB: string; 
    unit: DurationUnit; 
    amount: number;
}) => {
    const unitDifference = DateTime
        .fromISO(props.dateA)
        .diff(DateTime.fromISO(props.dateB), [props.unit])
        .toObject()
        [props.unit]

    return typeof unitDifference === "number" ? 
        Math.abs(unitDifference) <= props.amount
        : false
    ;
}