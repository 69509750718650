import React from "react";
import { CRMTableRow } from "../../CRMTableRow/CRMTableRow";
import { TIcon } from "../../CRMIcon/CRMIcon";
import { SpacingRow } from "../../BuildingBlocks/SpacingRow";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { pipe } from "fp-ts/function";
import { ifTrueInject } from "../../../../../shared/src/utilsByDomain/array";
import { TAction } from "../../CRMTableRow/CRMTableRow";
import { isAFunctionCurried, onCallIfExists } from "../../../functions/functions";
import { CRMParagraph } from "../../Simple/CRMParagraph/CRMParagraph";
import { WeightBold } from "../../WeightBold/WeightBold";
import { TMarketingCampaignForm } from "../../../../../domain/codecs/form/MarketingCampaignForm";

export type TMarketingRowProps = {
    marketingCampaign: TMarketingCampaignForm;
    onClick: () => void;
    onDelete?: () => void;
    onEdit?: () => void;
};

export const CRMMarketingCampaignRow = (props: React.PropsWithChildren<TMarketingRowProps>): JSX.Element => {

    return (
        <CRMTableRow
            onClick={props.onClick}
            actions={pipe(
                [] as Array<TAction>, 
                ifTrueInject(
                    isAFunctionCurried(props.onEdit),
                    {
                        iconName: "pencil" as TIcon,
                        title: "Edit introducer",
                        onClick: onCallIfExists(props.onEdit),
                    }
                ),
                ifTrueInject(
                    isAFunctionCurried(props.onDelete),
                    {
                        iconName: "delete" as TIcon,
                        title: "Delete",
                        onClick: onCallIfExists(props.onDelete),
                    }
                ),
            )}
            onHoverFace={
                <SpacingRow
                    spacing={CRMSpacing.MEDIUM}
                    justifyContent="start"
                >
                    <CRMParagraph lineHeight={1}>
                        <WeightBold>
                            {props.marketingCampaign.edited.name || "--"}
                        </WeightBold>
                    </CRMParagraph>
                </SpacingRow>
            }
        >
            <SpacingRow
                spacing={CRMSpacing.MEDIUM}
                childSize={`1fr 1fr`}
                justifyContent="space-between"
            >
                <CRMParagraph lineHeight={1}>
                    <WeightBold>
                        {props.marketingCampaign.edited.name || "--"}
                    </WeightBold>
                </CRMParagraph>

            </SpacingRow>
        </CRMTableRow>
    );
};
