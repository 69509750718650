import React from "react";
interface IHeading5 {
    text: string;
    modifierName?: "crab"; // Useful for when text is on a different background colour than normal
}

class Heading5 extends React.Component<React.PropsWithChildren<IHeading5>> {
    public render (): JSX.Element {
        return (
            <h5 className={`heading5${this.props.modifierName ? " heading5--" + this.props.modifierName : ""}`}>{this.props.text}</h5>
        );
    }
}

export default Heading5;
