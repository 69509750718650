import React, { useEffect, useState } from "react";
import { CasesCaseFormNextDateType_displayString, TCasesCaseForm } from "../../../../../../domain/codecs/form/CasesForm";
import { SpacingRow } from "../../../BuildingBlocks/SpacingRow";
import { snakeCaseToCopyText } from "../../../../../../shared/src/util";
import { toDateFormat1 } from "../../../../../../shared/src/dateTime";
import { CRMSpacing } from "../../../../models/CRMSpacing";
import { Background } from "../../../BuildingBlocks/Background";
import { CRMColors } from "../../../../models/CRMColors";
import { CRMIcon } from "../../../CRMIcon/CRMIcon";
import { Absolute } from "../../../BuildingBlocks/Absolute";
import { useHover } from "../../../../hooks/useHover";
import { CRMHoverHighlightText } from "../../../Simple/CRMHoverHighlightText/CRMHoverHighlightText";
import { CRMCaseEditCommentPopover } from "../CRMCaseEditCommentPopover/CRMCaseEditCommentPopover";
import { createChangeRouteAction } from "../../../../state/router/createChangeRouteAction";
import { Routes } from "../../../../state/router/routerRoutes";
import { makeJobLinkOutOfRouteAction } from "../../../CRMJobs/CRMJobsOverlay/CRMJobsCardLinkGenerators";
import { CRMCaseChaseIndicator } from "../CRMCaseChaseIndicator/CRMCaseChaseIndicator";

type TCaseTableRowMainProps = {
    form: TCasesCaseForm;
    onTogglePin: (value: boolean) => void;
    onChangeFormComment: (form: TCasesCaseForm) => void;
    onSubmitForm: (form: TCasesCaseForm) => void;
};

export const CRMCaseTableRowMain = (props: React.PropsWithChildren<TCaseTableRowMainProps>): JSX.Element => {

    const [ref, isHovering] = useHover<HTMLDivElement>({});
    const [isCommentPopupOpen, setIsCommentPopupOpen] = useState<boolean>(false);

    useEffect(
        () => props.form.status === "success"
            ? setIsCommentPopupOpen(false)
            : undefined,
        [props.form.status]
    );
    
    const isASailHomesCase = props.form.children.has_sail_homes_listing;
    
    const isChased = props.form.children.last_chase_transition.status === "chase";
    const isChaseChangeAcknowledged = props.form.children.last_chase_transition.acknowledged;
    const isWorkableTimeout = props.form.children.last_chase_transition.status === "timeout";

    const isPinned = props.form.edited.is_pinned;
    const nextDateType = CasesCaseFormNextDateType_displayString(props.form.children.next_date.type);

    const nextDateDate =
        props.form.children.next_date.date
            ? toDateFormat1(props.form.children.next_date.date)
            : "";
            
    const comment = props.form.edited.comment.trim() || "-- --";
        
    const onTogglePin = () => props.onTogglePin(!isPinned);

    const onCommentClick = () => setIsCommentPopupOpen(true);

    const onCommentClose = () => setIsCommentPopupOpen(false);

    const onClickAddress = () => {
        let link = makeJobLinkOutOfRouteAction(
            createChangeRouteAction(
                "VIEW_CRM_LEGAL_CASE", 
                { caseId: props.form.original.id },
                { visible_tab: "" },
                new Routes().blockParams.VIEW_CRM_LEGAL_CASE,
            )
        );
        window.open(link, "_blank");
    }

    return (
        <div 
            ref={ref}
            className={`crm-case-table-row-main`}
        >
            <Background
                color={CRMColors.NEUTRAL_PAPER}
                padding={`0px ${CRMSpacing.MEDIUM}`}
                borderBottomDefinition={`1px solid ${CRMColors.NEUTRAL_8}`}
                height="46px"
            >
                <SpacingRow
                    spacing={`${CRMSpacing.LARGE}`}
                    childSize="68px 1fr"
                >
                    {/* CELLS - INDICATORS */}
                    <SpacingRow spacing={CRMSpacing.SMALL}>
                        {/* INDICATOR - IS PINNED */}
                        <IconCell>
                            {isPinned &&
                                <CRMIcon
                                    iconName="pinned-side"
                                    size={"16px"}
                                    colour="hex"
                                    hexColour={CRMColors.HIGHLIGHTS_POSITIVE_GREEN_0}
                                />
                            }
                        </IconCell>
                        
                        {/* INDICATOR - CHASE MODE */}
                        <IconCell>
                            {(isChased || !isChaseChangeAcknowledged || isWorkableTimeout) &&
                                <CRMCaseChaseIndicator form={props.form} />
                            }
                        </IconCell>
                        
                        {/* INDICATOR - IS SAILS HOMES CASE */}
                        <IconCell>
                            {isASailHomesCase &&
                                <CRMIcon
                                    iconName="house-filled"
                                    size={"16px"}
                                    colour="hex"
                                    hexColour={CRMColors.PRIMARY_2}
                                />
                            }
                        </IconCell>
                    </SpacingRow>
                    
                    {/* CELLS - TEXT */}
                    <SpacingRow 
                        spacing={CRMSpacing.LARGE}
                        childSize="3fr 1.5fr 1fr 1fr 1fr 1fr 90px 90px"
                    >
                        <Cell 
                            weight={700}
                            onClick={onClickAddress}
                        >
                            {props.form.children.address}
                        </Cell>

                        <Cell>
                            {props.form.children.clients}
                        </Cell>

                        <Cell>
                            {snakeCaseToCopyText(props.form.children.type)}
                        </Cell>

                        <Cell>
                            <CRMHoverHighlightText onClick={onCommentClick}>
                                {comment}
                            </CRMHoverHighlightText>
                        </Cell>

                        <Cell>
                            {snakeCaseToCopyText(props.form.children.status)}
                        </Cell>

                        <Cell weight={600}>
                            {nextDateType} {nextDateDate}
                        </Cell>

                        <Cell>
                            {props.form.children.dead_time_days}d
                        </Cell>

                        <Cell>
                            {props.form.children.case_handler_initials}
                        </Cell>
                    </SpacingRow>
                </SpacingRow>
            </Background>

            {/* BUTTON - PIN/UNPIN */}
            {isHovering && 
                <Absolute
                    top={`0px`}
                    right={`0px`}
                >
                    <Background 
                        padding={`${CRMSpacing.MEDIUM} ${CRMSpacing.LARGE} ${CRMSpacing.SMALL}`}
                        cursor={"pointer"}
                        onClick={onTogglePin}
                    >
                        <CRMIcon
                            iconName={isPinned ? "pinned" : "pin"}
                            size={`20px`}
                            colour="hex"
                            hexColour={isPinned ? CRMColors.NEUTRAL_0 : CRMColors.NEUTRAL_2}
                        />
                    </Background>
                </Absolute>
            }

            {/* COMMENT POPUP */}
            {isCommentPopupOpen &&
                <CRMCaseEditCommentPopover
                    form={props.form}
                    onChange={props.onChangeFormComment}
                    onSubmit={props.onSubmitForm}
                    onClose={onCommentClose}
                />
            }
        </div>
    );
};

type TCellProps = {
    weight?: 600 | 700;
    onClick?: () => void,
}

export const Cell = (props: React.PropsWithChildren<TCellProps>) => {
    return (
        <div
            className="crm-case-table-row-main__cell"
            style={{
                fontWeight: props.weight || 500,
                cursor:
                    props.onClick
                        ? "pointer"
                        : "auto"
            }}
            onClick={props.onClick}
        >
            {props.children}
        </div>
    )
}

export const IconCell = (props: React.PropsWithChildren<{}>) => {
    return (
        <Background 
            padding={`7px 0px 0px`}
            width="16px"
        >
            <SpacingRow>
                {props.children}
            </SpacingRow>
        </Background>
    )
}
