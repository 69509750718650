import { array } from "fp-ts";
import { IOption } from "./../hooks/UseDropdown";
import { pipe } from "fp-ts/lib/function";
import { Ord as StringOrd } from "fp-ts/lib/string";
import { contramap } from "fp-ts/lib/Ord";

export const enumToIDropdownOptions = <E extends string, N extends string>(
    enumerable: Array<E>,
    fromValueToDisplayNameCallback: (v: E) => N
): Array<IOption<E>> =>
     pipe(
        enumerable,
        array.map((ref: E) => ({
            value: ref,
            label: fromValueToDisplayNameCallback(ref),
        })),
    );

const OrdByDisplayString = pipe(StringOrd, contramap<string, IOption<string>>((option) => `${option.label}`));

export const enumToIDropdownOptionsSortedByDisplayName = <E extends string, N extends string>(
        enumerable: Array<E>,
        fromValueToDisplayNameCallback: (v: E) => N
): Array<IOption<E>> =>
    pipe(
        enumToIDropdownOptions(enumerable, fromValueToDisplayNameCallback),
        array.sortBy([OrdByDisplayString]),
    );