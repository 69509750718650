import React, { useState } from "react";
import { TFormStatus } from "../../../../shared/src/codecs/codec";
import { CRMButtonPrimary } from "../CRMButtonPrimary/CRMButtonPrimary";
import { CRMCardOutsidePopupFormSubmit } from "../CRMCardOutsidePopupFormSubmit/CRMCardOutsidePopupFormSubmit";
import { CRMParagraph } from "../Simple/CRMParagraph/CRMParagraph";

export const CRMCaseRollbackComponent = (props: React.PropsWithChildren<{
    formStatus: TFormStatus;
    rollbackTo: "pre_instruction" | "pre_ready_for_exchange";
    onYes: () => void;
}>): JSX.Element => {
    const [isPopupOpen, setPopupOpen] = useState<boolean>(false);

    const openPopup = () => setPopupOpen(true);

    const closePopup = () => {
        setPopupOpen(false);
    };

    return (
        <>
            {/* Button */}
            <div>
                <CRMButtonPrimary
                    fullWidth={false}
                    icon="undo"
                    label={`Rollback ${props.rollbackTo === "pre_ready_for_exchange" ? "ready for exchange" : "instruction"}`}
                    onClick={openPopup}
                />
            </div>

            {/* Popup */}
            {isPopupOpen &&
                <CRMCardOutsidePopupFormSubmit
                    isOpen={true}
                    title={"Are you sure?"}
                    context="warning"
                    closeText="Cancel"
                    ctaText={"Yes, rollback"}
                    size="medium"
                    onClose={closePopup}
                    onCTA={props.onYes}
                    formStatus={
                        props.formStatus === "unauthorised"
                        || props.formStatus === "twoFactorRequired"
                        || props.formStatus === "notFound" ? "failure"
                            : props.formStatus === "untouched" ? "requiresSubmission"
                            : props.formStatus}
                    validationErrors={[]}
                >
                    <CRMParagraph>
                        You are about to roll back this case {props.rollbackTo === "pre_ready_for_exchange" ? "to before sign off for ready for exchange" : "to before instruction"}. Are you sure you want to continue?
                    </CRMParagraph>
                </CRMCardOutsidePopupFormSubmit>
            }
        </>
    );
};
