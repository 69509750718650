import * as t from "io-ts";
import * as JsonResponse1 from "./JsonResponse1";
import * as Enquiry2 from "./Enquiry2";

export const codec = JsonResponse1.createCodec(
    Enquiry2.codec,
    t.type({}),
);

export type T = t.TypeOf<typeof codec>;

export const newDefault = (): T => ({
    data: Enquiry2.newDefault(),
    meta: {},
});
