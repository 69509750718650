import React, { useEffect, useState } from "react";
import { CasesCaseCreateAdhocForm, TAdhocTaskForms, TKeyDateTaskForms, TCasesCaseCreateAdhocForm, TCasesCaseForm, TCasesCaseTaskFormAdhoc, TCasesCaseTaskFormAdhocForDayView, TCasesCaseTaskFormForCasesView } from "../../../../../../domain/codecs/form/CasesForm";
import { SpacingRow } from "../../../BuildingBlocks/SpacingRow";
import { CRMSpacing } from "../../../../models/CRMSpacing";
import { Background } from "../../../BuildingBlocks/Background";
import { CRMStepSwitch } from "../../../Complex/CRMStepSwitch/CRMStepSwitch";
import { CRMCasesTaskRowChangeable } from "../CRMCasesTaskRowChangeable/CRMCasesTaskRowChangeable";
import { CRMCasesTaskRow } from "../CRMCasesTaskRow/CRMCasesTaskRow";
import { TPlainUser } from "../../../../../../domain/codecs/User";
import { pipe } from "fp-ts/lib/pipeable";
import { SpacingColumn } from "../../../BuildingBlocks/SpacingColumn";
import { toDateFormat1 } from "../../../../../../shared/src/dateTime";
import { TFilterOption, TTaskFilterViews, filterTasksToCategory, groupTasksByDay, sortDayClustersByRecency } from "./CRMCaseTaskGroupingLogic";
import { FontQuicksand } from "../../../BuildingBlocks/FontQuicksand";
import { Text } from "../../../BuildingBlocks/Text";
import { CRMFontSizes } from "../../../../models/CRMFontSizes";
import { Uppercase } from "../../../Uppercase/Uppercase";
import { CRMEmptyPlaceholder } from "../../../Simple/CRMEmptyPlaceholder/CRMEmptyPlaceholder";
import { CRMButtonPrimary } from "../../../CRMButtonPrimary/CRMButtonPrimary";
import { useOpenClose } from "../../../../hooks/UseOpenClose";
import { CRMCaseCreateAdhocPopup } from "../CRMCaseCreateAdhocPopup/CRMCaseCreateAdhocPopup";
import { onChangeForm } from "../../../../../../shared/src/codecs/types/form";
import { CRMColors } from "../../../../models/CRMColors";
import { DateTime } from "luxon";

type TCRMCaseTableRowTasksProps = {
    form: TCasesCaseForm;
    assignableUsers: Array<TPlainUser>;
    onChangeTask: (form: TAdhocTaskForms) => void;
    onArchiveTask: (form: TAdhocTaskForms) => void;
    onSubmitNewTask: (form: TCasesCaseCreateAdhocForm) => void;
};

export const CRMCaseTableRowTasks = (props: TCRMCaseTableRowTasksProps): JSX.Element => {

    const [taskView, setTaskView] = useState<TTaskFilterViews>("all");

    const newTaskPopup = useOpenClose();
    const [newAdHoc, setNewAdHoc] = useState(props.form.children.create_adhoc_form);
    
    useEffect(
        () => {
            if (props.form.children.create_adhoc_form.status === "success") {
                setTimeout(() => {
                    newTaskPopup.setIsOpen(false);
                    setNewAdHoc({
                        ...props.form.children.create_adhoc_form,
                        edited: {
                            ...props.form.children.create_adhoc_form.edited,
                            date: DateTime.utc().toISODate(),
                            job: ""
                        }
                    })
                }, 500)
            }
        },
        [props.form.children.create_adhoc_form.status]
    );

    const SwitchOptions: Array<TFilterOption> = [
        {
            value: "all",
            label: "All",
        },
        {
            value: "task",
            label: "Task",
        },
        {
            value: "key_date",
            label: "Key Date",
        },
        {
            value: "done",
            label: "Done",
        },
    ];

    const TaskForms = pipe(
        props.form.children.task_forms,
        filterTasksToCategory(taskView),
        groupTasksByDay,
        sortDayClustersByRecency
    );
    const IsEmpty = TaskForms.length === 0;

    const onChangeNewAdHoc = onChangeForm(newAdHoc, setNewAdHoc);

    const onSubmitNewAdHoc = () => {
        props.onSubmitNewTask(newAdHoc);
    }

    return (
        <div
            className="crm-case-table-row-tasks"
        >
            <SpacingRow
                justifyContent="space-between"
                alignItems="flex-start"
                spacing={CRMSpacing.LARGE}
                childSize="1fr 180px"
            >
                {/* IF NOT EMPTY: TASKS */}
                {IsEmpty && 
                    <Background 
                        padding={`${CRMSpacing.X_LARGE} ${CRMSpacing.LARGE} 50px`}
                        color={CRMColors.NEUTRAL_PAPER}
                        borderDefinition={`1px solid ${CRMColors.NEUTRAL_8}`}
                        borderRadius="5px"
                    >
                        <CRMEmptyPlaceholder
                            iconSize="140px"
                            textGap={CRMSpacing.TINY}
                        >
                            No tasks found.
                        </CRMEmptyPlaceholder>
                    </Background>
                }

                {/* IF NOT EMPTY: TASKS */}
                {!IsEmpty && 
                    <div className="crm-case-table-row-tasks__tasks">
                        {TaskForms.map((dayCluster, clusterIndex) => (
                            <SpacingColumn key={clusterIndex}>
                                
                                {clusterIndex > 0 &&
                                    <GroupTitle date={dayCluster.date} />
                                }

                                <SpacingColumn>
                                    {dayCluster.tasks.map((task) => (
                                        task.children.category === "task" ?
                                            <CRMCasesTaskRowChangeable
                                                key={`${task.original.id}_${task.original.tag}`}
                                                form={task as TAdhocTaskForms}
                                                assignableUsers={props.assignableUsers}
                                                onChange={props.onChangeTask}
                                                onArchive={props.onArchiveTask}
                                            /> :
                                            <CRMCasesTaskRow
                                                key={`${task.original.id}_${task.original.tag}`}
                                                form={task as TKeyDateTaskForms}
                                            />
                                    ))}
                                </SpacingColumn>
                            </SpacingColumn>
                        ))}
                    </div>
                }

                {/* RIGH - CONTROLS */}
                <SpacingColumn spacing={CRMSpacing.LARGE}>
                    {/* VIEW SWITCH */}
                    <CRMStepSwitch
                        value={taskView}
                        steps={SwitchOptions}
                        onChange={setTaskView}
                    />

                    <CRMButtonPrimary
                        icon="add"
                        label={"Add ad-hoc"}
                        onClick={newTaskPopup.toggleOpen}
                    />
                </SpacingColumn>
            </SpacingRow>

            {/* POPUP - ADD NEW  TASK */}
            {newTaskPopup.isOpen &&
                <CRMCaseCreateAdhocPopup
                    status={props.form.children.create_adhoc_form.status}
                    assigneeUsers={props.assignableUsers}
                    assignedTo={newAdHoc.edited.assigned_to}
                    onChangeAssignedTo={onChangeNewAdHoc("assigned_to")}
                    job={newAdHoc.edited.job}
                    onChangeJob={onChangeNewAdHoc("job")}
                    date={newAdHoc.edited.date}
                    onChangeDate={onChangeNewAdHoc("date")}
                    onSubmit={onSubmitNewAdHoc}
                    onClose={newTaskPopup.toggleOpen}
                />
            }
        </div>
    );
};


type TGroupTitleProps = {
    date: string;
}

const GroupTitle = (props: TGroupTitleProps): JSX.Element => {
    return (
        <Background
            height={"86px"}
            display="flex"
            alignItems="center"
            padding={`0px 0px 0px 22px`}
        >
            <FontQuicksand>
                <Text
                    size={CRMFontSizes.SMALL}
                    weight={700}
                >
                    <Uppercase>
                        {toDateFormat1(props.date)}
                    </Uppercase>
                </Text>
            </FontQuicksand>
        </Background>
    )
}
