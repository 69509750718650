import React, { useEffect, useState } from "react";
import { TTriageSimpleEmailForm } from "../../../../../domain/codecs/form/TriageForm";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { Sticky } from "../../BuildingBlocks/Sticky";
import { CRMPopoutHeader } from "../../Simple/CRMPopoutHeader/CRMPopoutHeader";
import { CRMBlockPopover } from "../../CRM/CRMBlock/CRMBlockPopover/CRMBlockPopover";
import CRMInputLabelAndErrorWrapComponent from "../../CRMInputLabelAndErrorWrapComponent/CRMInputLabelAndErrorWrapComponent";
import { CRMRadioListComponent } from "../../CRMInputs/CRMRadioListComponent/CRMRadioListComponent";
import { TCaseUpdateChaseModeForm, TChaseStatusType } from "../../../../../domain/codecs/form/CasesChaseTransitionForm";
import { pipe } from "fp-ts/lib/function";
import { array } from "fp-ts";
import { SpacingColumn } from "../../BuildingBlocks/SpacingColumn";
import { CRMBottomControlBar } from "../../CRMBottomControlBar/CRMBottomControlBar";
import { Background } from "../../BuildingBlocks/Background";
import { SpacingRow } from "../../BuildingBlocks/SpacingRow";
import { CRMFormButton } from "../../CRMFormButton/CRMFormButton";
import { CRMButtonPrimary } from "../../CRMButtonPrimary/CRMButtonPrimary";
import { TChaseReasons, TChaseReasonsWithoutAwaitingOpenNewCase } from "../../../../../domain/codecs/Cases";
import { MinHeight } from "../../BuildingBlocks/MinHeight";
import { CRMChaseReasonsMultiSelect } from "../CRMChaseReasonsMultiSelect/CRMChaseReasonsMultiSelect";

type TChaseOrWorkableStatus = "chase" | "workable";

type TCRMUpdateChaseModeSectionPopoutProps = {
    email: TTriageSimpleEmailForm;
    chaseForm: TCaseUpdateChaseModeForm;
    onChangeChaseModeForm: (form: TCaseUpdateChaseModeForm) => void;
    onSubmit: () => void;
    onClose: () => void;
};

export const CRMUpdateChaseModeSectionPopout = (props: React.PropsWithChildren<TCRMUpdateChaseModeSectionPopoutProps>): JSX.Element => {
    const isSubmitting = props.chaseForm.status === "submitting"

    const getChaseOrWorkableStatus = (status: TChaseStatusType): TChaseOrWorkableStatus => status === "chase" ? "chase" : "workable";

    useEffect(
        () => {
            if (props.chaseForm.status === "success") {
                props.onClose();
            }
        },
        [props.chaseForm.status],
    );

    const onChangeChaseModeReasons = (selectedChaseReasons: Array<TChaseReasons>) => props.onChangeChaseModeForm({
            ...props.chaseForm,
            edited: {
                ...props.chaseForm.edited,
                selected_chase_reasons: selectedChaseReasons,
            }
        });

    const updateChaseStatus = (value: TChaseStatusType) =>
        props.onChangeChaseModeForm({
            ...props.chaseForm,
            edited: {
                ...props.chaseForm.edited,
                status: value,
            }
        });

    const updateChaseReasons = (value: TChaseReasonsWithoutAwaitingOpenNewCase) => 
        onChangeChaseModeReasons(
            props.chaseForm.edited.selected_chase_reasons.includes(value)
                ? pipe(
                    props.chaseForm.edited.selected_chase_reasons,
                    array.filter((v) => v !== value)
                )
                : [
                    ...props.chaseForm.edited.selected_chase_reasons,
                    value,
                ]
        );

    return (
        <CRMBlockPopover
            padding={CRMSpacing.MEDIUM}
            height="540px"
        >
            <CRMPopoutHeader
                icon="arrow-left"
                onClick={props.onClose}
            >
                Update or Turn Off Chase Mode
            </CRMPopoutHeader>
            
            <MinHeight height="500px">
            <Background padding={CRMSpacing.LARGE}>
                <SpacingColumn spacing={CRMSpacing.LARGE}>
                    <CRMInputLabelAndErrorWrapComponent
                        label="What would you like to do?"
                    >
                        <CRMRadioListComponent<TChaseOrWorkableStatus, TChaseOrWorkableStatus>
                            value={getChaseOrWorkableStatus(props.chaseForm.edited.status)}
                            direction="column"
                            options={[
                                {
                                    value: "chase",
                                    text: "Leave in Chase Mode/Update Chase Reasons",
                                },
                                {
                                    value: "workable",
                                    text: "Switch off Chase Mode",
                                }
                            ]}
                            onChange={(val) => updateChaseStatus(val)}
                        />
                    </CRMInputLabelAndErrorWrapComponent>

                    {getChaseOrWorkableStatus(props.chaseForm.edited.status) === "chase"
                        && <CRMChaseReasonsMultiSelect
                            multiSelectLabel="Update chase reasons:"
                            availableChaseReasons={props.chaseForm.children.available_chase_reasons}
                            selectedChaseReasons={props.chaseForm.edited.selected_chase_reasons}
                            updateChaseReason={updateChaseReasons}
                        />
                    }
                    
                </SpacingColumn>
            </Background>
            </MinHeight>

            <Sticky bottom="0px" zIndex={1}>
                {/* SUBMIT BUTTONS */}
                <CRMBottomControlBar>
                    <Background
                        width="100%"
                        padding={`${CRMSpacing.TINY} ${CRMSpacing.MEDIUM}`}
                    >
                        <SpacingRow justifyContent="end">
                            <CRMFormButton
                                ButtonElement={CRMButtonPrimary}
                                formStatus={props.chaseForm.status}
                                label="Submit"
                                disabled={isSubmitting}
                                onClick={props.onSubmit}
                            />
                        </SpacingRow>
                    </Background>
                </CRMBottomControlBar>
            </Sticky>
        </CRMBlockPopover>
    );
};
