import React, { createRef, useEffect } from "react";
import { TBlockContainerStateProps } from "../../state/TBlockContainerStateProps";
import { CRMBlockScrollCacheView, CRMBlockScrollResetView } from "../../components/CRM/CRMBlock/CRMBlockViews/CRMBlockViews";
import { CRMEmptyPlaceholder } from "../../components/Simple/CRMEmptyPlaceholder/CRMEmptyPlaceholder";
import { Padding } from "../../components/BuildingBlocks/Padding";
import { CRMSpacing } from "../../models/CRMSpacing";
import { CRMCaseTitleCheckCard } from "../../components/Contextual/CRMCaseTitleCheck/CRMCaseTitleCheckCard/CRMCaseTitleCheckCard";
import { SpacingColumn } from "../../components/BuildingBlocks/SpacingColumn";
import { TCaseTitleCheckForm, getCompletedStateTupleFromData } from "../../../../domain/codecs/form/CaseTitleCheckForm";
import { pipe } from "fp-ts/lib/function";
import { updateRouteWithBlockChange } from "../../state/router/updateRouteWithBlockChange";
import { array, option } from "fp-ts";
import { CRMCaseTitleCheckSingleView } from "../../components/Contextual/CRMCaseTitleCheck/CRMCaseTitleCheckSingleView/CRMCaseTitleCheckSingleView";
import { getRouteBlockData } from "../../state/router/getRouteBlockData";
import { simpleFold } from "../../../../shared/src/utilsByDomain/option/simpleFold";


export const CRMLegalCaseTitleChecksContainer = (props: React.PropsWithChildren<TBlockContainerStateProps>): JSX.Element => {

    useEffect(
        () => props.dispatch({
            type: "LEGAL_CASE_TITLE_CHECKS_BLOCK_VIEW",
            payload: null,
        }),
        []
    );

    const setOrDelete = <J, T>(obj: T, key: keyof T, value: J): Omit<T, keyof T> | T => {
        if (typeof value !== 'undefined') {
            return {
                ...obj,
                [key]: value,
            };
        } else {
            // eslint-disable-next-line
            const { [key]: deletedProp, ...rest } = obj;
            return rest;
        }
    }
    

    const updateRoutesBlockParamsToCacheOpenBlocksID = (openRoadblockID?: string) => {
        pipe(
            props.state.routes,
            updateRouteWithBlockChange(
                "case_title_checks", 
                props.index,
                (blockParams) => {
                    return ({
                        ...blockParams,
                        meta: setOrDelete(blockParams.meta, "openTitleCheckPropertyId", openRoadblockID)
                    });
                }
            ),
            option.fold(
                () => undefined,
                (routeAction) => props.dispatch(routeAction)
            )
        )
    }

    const SingleViewRef = createRef<HTMLDivElement>();

    const titleChecks = props.state.forms.case_title_checks_block.children.title_checks;

    const blockData = pipe(
        getRouteBlockData(props.state.routes),
        simpleFold
    );
    const currentBlock = blockData?.blocksOpened[props.index]
    const openTitleCheckPropertyId = currentBlock && currentBlock.type === "case_title_checks" ? currentBlock.meta.openTitleCheckPropertyId : undefined;

    const currentlyOpenTitleCheck = pipe(
        titleChecks,
        array.findFirst((titleCheck) => titleCheck.original.id === openTitleCheckPropertyId),
        simpleFold
    );

    const changeOpen = (propertyId: string | undefined) => {
        updateRoutesBlockParamsToCacheOpenBlocksID(propertyId);
    }

    const trimAndFilterEmptyPredicate = (s: string) => s.trim() !== "";

    const getShortAddress = (
        titleCheck: TCaseTitleCheckForm
    ) => [
        [
            // Get the sub_building number and name joined by a space
            [
                titleCheck.children.read_only.sub_building_number,
                titleCheck.children.read_only.sub_building_name
            ].filter(trimAndFilterEmptyPredicate).join(" "),
            // Get the building number and name joing by a space
            [
                titleCheck.children.read_only.building_number,
                titleCheck.children.read_only.building_name
            ].filter(trimAndFilterEmptyPredicate).join(" "),
            // Join these together by a comma if both exist
        ].filter(trimAndFilterEmptyPredicate).join(", "),
        // Add the street name on the end if it exists by a space
        titleCheck.children.read_only.street_name
    ].filter(trimAndFilterEmptyPredicate).join(" ")

    return <>
        <CRMBlockScrollCacheView isShowing={currentlyOpenTitleCheck === undefined}>
            {titleChecks.length === 0 && 
                <Padding spacing={`${CRMSpacing.X_LARGE} ${CRMSpacing.LARGE}`}>
                    <CRMEmptyPlaceholder
                        iconSize="70px"
                        textGap={CRMSpacing.TINY}
                    >
                        This case has no titles to check yet.
                    </CRMEmptyPlaceholder>
                </Padding>
            }
            {titleChecks.length > 0 && 
                <Padding spacing={`0px 0px ${CRMSpacing.X_LARGE}`}>
                    <SpacingColumn spacing={CRMSpacing.SMALL}>
                        {titleChecks.map((titleCheck) => 
                            {
                                const titleCheckFormState= getCompletedStateTupleFromData({
                                    ...titleCheck.edited,
                                    ...titleCheck.children.read_only,
                                });
                                return <CRMCaseTitleCheckCard
                                    formState={titleCheckFormState}
                                    shortAddress={getShortAddress(titleCheck)}
                                    isRegistered={titleCheck.children.read_only.is_registered}
                                    tenure={titleCheck.children.read_only.tenure}
                                    titleNumber={titleCheck.children.read_only.title_number}
                                    key={titleCheck.original.id}
                                    onClick={() => changeOpen(titleCheck.original.id)} />;
                            }
                        )}
                    </SpacingColumn>
                </Padding>
            }
        </CRMBlockScrollCacheView>

        <CRMBlockScrollResetView 
            elementRef={SingleViewRef}
            isShowing={openTitleCheckPropertyId !== undefined}
        >
            {currentlyOpenTitleCheck &&
            <CRMCaseTitleCheckSingleView
                onClose={() => changeOpen(undefined)}
                form={currentlyOpenTitleCheck}
                shortAddress={getShortAddress(currentlyOpenTitleCheck)}
                onChange={(payload) => props.dispatch({
                    type: "LEGAL_CASE_TITLE_CHECKS_BLOCK_FORM_CHANGE",
                    payload,
                })}
                onAddTitleCheckIssue={(form, description) => props.dispatch({
                    type: "LEGAL_CASE_TITLE_CHECKS_BLOCK_ADD_OTHER_TITLE_ISSUE",
                    payload: {
                        titleCheckId: form.original.id,
                        text: description,
                    },
                })}
                onDeleteTitleCheckIssue={(form, id) => props.dispatch({
                    type: "LEGAL_CASE_TITLE_CHECKS_BLOCK_DELETE_OTHER_TITLE_ISSUE",
                    payload: {
                        titleCheckId: form.original.id,
                        issueId: id,
                    },
                })}
            />}
        </CRMBlockScrollResetView>
    </>
};
