import { array, either, option } from "fp-ts";
import { pipe } from "fp-ts/lib/function";
import React, { useEffect, useState } from "react";
import { fromEvent, Subscription, timer } from "rxjs";
import { filter, tap, throttle } from "rxjs/operators";
import { ListingPhotoFileIO, TListingPhotoFileIO } from "../../../../domain/codecs/fileIO/ListingPhotoFileIO";
import { ListingImage3 } from "../../../../domain/codecs/ListingImage";
import { TFormStatus } from "../../../../shared/src/codecs/codec";
import { moveIndexFromToBeforeInArray } from "../../../../shared/src/util";
import { CRMCardOutsidePopupBasic } from "../CRMCardOutsidePopupBasic/CRMCardOutsidePopupBasic";
import { CRMIcon } from "../CRMIcon/CRMIcon";
import { CRMMultiUploadBox } from "../CRMMultiUploadBox/CRMMultiUploadBox";
import { CRMParagraph } from "../Simple/CRMParagraph/CRMParagraph";
import { CRMSpacer } from "../CRMSpacer/CRMSpacer";
import { v4 as uuidv4 } from "uuid";

type TImageUploadGalleryProps = {
    images: Array<TListingPhotoFileIO>;
    onUpdateOrder: (images: Array<TListingPhotoFileIO>) => void;
    onRemove: (image: TListingPhotoFileIO) => void;
    onUpload: (files: Array<TListingPhotoFileIO>) => void;
    listingId: string;
};

export const CRMImageUploadGallery = (props: TImageUploadGalleryProps): JSX.Element => {

    var mouseMoveSub: Subscription;
    var mouseDownSub: Subscription;
    var mouseUpSub: Subscription;

    const [imageToBeDeletedIndex, setImageToBeDeletedIndex] = useState(-1);

    const [isDragHappening, setIsDragging] = useState(false);
    const [draggedImageOrderNumber, setDraggedImageOrderNumber] = useState(-1);
    const [draggedOverImageOrderNumber, setDraggedOverImageOrderNumber] = useState(-1);
    const [dragCordinates, setDragCordinates] = useState({ top: 0, left: 0 })

    const mouseMoveObservable = fromEvent<MouseEvent>(document, "mousemove")
        .pipe(
            throttle(() => timer(50)),
            filter(() => isDragHappening),
            tap((event) => setDragCordinatesFromMouseEvent(event)),
            tap((event) => setDraggedOverImageOrderNumber(getValidImageOrderOfMousedOverElement(event, draggedImageOrderNumber)))
        );

    const mouseDownObservable = fromEvent<MouseEvent>(document, "mousedown")
        .pipe(
            filter(isEventOfAMouseDownOnADraggableUI),
            tap((event) => {
                setIsDragging(true);
                setDraggedImageOrderNumber(getImageOrderNumberIfGivenAttributeExistsInElementStack("gallerydrag")(event.composedPath()) as number);
                setDragCordinatesFromMouseEvent(event);
            }),
        );

    const mouseUpObservable = fromEvent<MouseEvent>(document, "mouseup")
        .pipe(
            filter(() => isDragHappening),
            tap(() => props.onUpdateOrder(getUpdatetImagesOrder(props.images, draggedImageOrderNumber, draggedOverImageOrderNumber))),
            tap(() => {
                setIsDragging(false);
                setDraggedImageOrderNumber(-1);
                setDraggedOverImageOrderNumber(-1);
                setDragCordinates({ top: 0, left: 0 });
            })
        );

    const setDragCordinatesFromMouseEvent = (event: MouseEvent) =>
        setDragCordinates({ 
            top: event.pageY - 25,
            left: event.pageX -25 
        });

    useEffect(() => {
        mouseMoveSub = mouseMoveObservable.subscribe();
        mouseDownSub = mouseDownObservable.subscribe();
        mouseUpSub = mouseUpObservable.subscribe();

        return () => {
            mouseMoveSub.unsubscribe();
            mouseDownSub.unsubscribe();
            mouseUpSub.unsubscribe();
        }
    });

    return (
        <div 
            className={`crm-image-upload-gallery`}  
        >
            <div className="crm-image-upload-gallery__grid">
                {props.images
                    .map((image, index) => (
                        <GalleryImage
                            key={index}
                            url={image.output.url}
                            order={index}
                            status={image.status}
                            isBeingDraggedOver={index === draggedOverImageOrderNumber}
                            isBeingDragged={index === draggedImageOrderNumber}
                            isDragHappening={isDragHappening}
                            left={dragCordinates.left}
                            top={dragCordinates.top}
                            onDelete={() => setImageToBeDeletedIndex(index)}
                        />
                    ))
                }
            </div>

            {props.images.length > 0 && <CRMSpacer size="large" />}
            <CRMMultiUploadBox
                icon="camera"
                label="Upload photo/s"
                onFileChange={
                    (files: Array<File>) =>
                        props.onUpload(
                            pipe(
                                files,
                                array.filterMap<File, TListingPhotoFileIO>((file) => 
                                    {
                                        if (file.size > 15 * 1024 * 1024) {
                                            return option.none;
                                        }
                                        const indexOfLastFullStopInFileName = file.name.lastIndexOf(".")
                                        const obj = {
                                            listing_id: props.listingId,
                                            name: indexOfLastFullStopInFileName >= 0 ? file.name.slice(0, indexOfLastFullStopInFileName) : file.name,
                                            file_extension: indexOfLastFullStopInFileName >= 0 ? file.name.slice(indexOfLastFullStopInFileName + 1) : "",
                                            mime_type: file.type === "" ? "text/plain" : file.type, 
                                        };
                                        return pipe(
                                            obj,
                                            ListingImage3.decode,
                                            either.fold(
                                                () => option.none,
                                                (imageObj) => option.some<TListingPhotoFileIO>({
                                                    ...ListingPhotoFileIO.newDefault(),
                                                    status: "requiresSubmission",
                                                    input: {
                                                        request_ref: uuidv4(),
                                                        ...imageObj,
                                                    },  
                                                    clientSideFileForUpload: file,
                                                })
                                            ),
                                        );
                                    }
                                ),
                                (filteredFiles) => {
                                    if (filteredFiles.length < files.length) {
                                        if (filteredFiles.length === 0) {
                                            alert("The file type you attempted to upload is not supported or too large.")
                                        }
                                        else {
                                            alert("Some of the files you attempted to upload are not supported or too large. The supported file types will be uploaded")
                                        }
                                    }
                                    return filteredFiles
                                },
                            )
                        )
                }
            />

            {/* DELETE IMAGE POPUP */}
            <CRMCardOutsidePopupBasic
                isOpen={imageToBeDeletedIndex > -1}
                title="Delete image?"
                ctaText="Yes, delete"
                closeText="Cancel"
                onCTA={() => {
                    props.onRemove(props.images[imageToBeDeletedIndex]);
                    setImageToBeDeletedIndex(-1);
                }}
                onClose={() => setImageToBeDeletedIndex(-1)}
            >
                <CRMParagraph>
                    Are you sure you want to delete this image?
                </CRMParagraph>
            </CRMCardOutsidePopupBasic>
        </div>
    );
};

const isEventOfAMouseDownOnADraggableUI = (event: MouseEvent): boolean => {
    return pipe(
        event.composedPath(),
        getImageOrderNumberIfGivenAttributeExistsInElementStack("gallerydrag"),
        (value) => value > -1
    );
};

const getValidImageOrderOfMousedOverElement = (event: MouseEvent, draggedImageOrderNumber: number) =>
    getImageOrderNumberIfGivenAttributeExistsInElementStack("gallerydrop")(
        document.elementsFromPoint(event.pageX, event.pageY),
        draggedImageOrderNumber,
    )
;

const getImageOrderNumberIfGivenAttributeExistsInElementStack = function (attributeName: "gallerydrag" | "gallerydrop") {
    return (path: Array<EventTarget>, orderNumberToIgnore?: number): number => pipe(
        path,
        array.map((node) => node instanceof Element && node.attributes ? node.attributes.getNamedItem(attributeName) : null),
        array.filter<Attr | null, Attr>((attribute): attribute is Attr => attribute !== null),
        array.map((attribute) => parseInt(attribute.value)),
        array.filter((orderNumber) => orderNumber !== orderNumberToIgnore),
        array.head,
        option.fold(
            () => -1,
            (orderNumber) => orderNumber
        )
    );
};

const getUpdatetImagesOrder = (
    images: Array<TListingPhotoFileIO>,
    draggedImageOrderNumber: number,
    draggedOverImageOrderNumber: number
) => {
    if (draggedImageOrderNumber < 0 || draggedOverImageOrderNumber < 0) {
        return images;
    }

    return moveIndexFromToBeforeInArray(draggedImageOrderNumber, draggedOverImageOrderNumber, images);
}


type TGalleryImageProps = {
    order: number;
    url: string;
    status: TFormStatus;
    isBeingDraggedOver: boolean;
    isBeingDragged: boolean;
    isDragHappening: boolean;
    top: number;
    left: number;
    onDelete: () => void;
};

const GalleryImage = (props: TGalleryImageProps ) => {
    const [isHoveredOver, setIsOverlayShowing] = useState(false);

    const getFaceBEMModifier = (): "dragged" | "static" | "faded" => {
        if (props.isBeingDragged) {
            return "dragged";
        }

        if (props.isDragHappening) {
            return "faded";
        }

        return "static";
    }

    const showImageOverlay = ():boolean => {
        if (props.isBeingDragged) {
            return true;
        }
        
        if (!props.isBeingDragged && props.isDragHappening) {
            return false;
        }

        return isHoveredOver;
    };

    return (
        <div 
            className="crm-gallery-image"
            onMouseEnter={() => setIsOverlayShowing(true)}
            onMouseLeave={() => setIsOverlayShowing(false)}
        >
            {/* IMAGE FACE (THE PART THAT IS VISIBLY DRAGGED) */}
            <div 
                className={`
                    crm-gallery-image__face
                    crm-gallery-image__face--${getFaceBEMModifier()}
                `}
                style={{
                    left: props.isBeingDragged ? `${props.left}px` : "0px",
                    top: props.isBeingDragged ? `${props.top}px` : "0px",
                }}
                // eslint-disable-next-line
                // @ts-ignore
                gallerydrop={props.status === "success" || props.status === "untouched" ? props.order : undefined}
            >
                {/* OVERLAY - CONTROLS */}
                <div className={`
                    crm-gallery-image__overlay
                    crm-gallery-image__overlay--${showImageOverlay() ? 'show' : 'hide'}
                    crm-gallery-image__overlay--${props.status === "success" || props.status === "untouched" ? "enabled" : "disabled"}
                `}>
                    {/* DRAG */}
                    <div
                        className="crm-gallery-image__top-left-icon"
                        
                        // eslint-disable-next-line
                        // @ts-ignore
                        gallerydrag={props.status === "success" || props.status === "untouched" ? props.order : undefined}
                    >
                        <CRMIcon iconName="hand" colour="neutral-paper" />
                    </div>
                    {/* DELETE */}
                    <div 
                        className="crm-gallery-image__bottom-right-icon"
                        onClick={() => props.onDelete()}
                    >
                        <CRMIcon iconName="delete" colour="neutral-paper" />
                    </div>
                </div>
                
                {/* PLACEMENT INDICATOR */}
                <div className={`
                    crm-gallery-image__placement-indicator
                    crm-gallery-image__placement-indicator--${props.isBeingDraggedOver ? 'showing' : 'hiding'}
                `}></div>

                {/* IMAGE */}
                {props.status !== "submitting" && props.status !== "failure" &&
                    <img className="crm-gallery-image__image" src={props.url} />
                }
                {/* UPLOADING */}
                {props.status === "submitting" &&
                    <div className="crm-gallery-image__pending">
                        <CRMIcon
                            iconName="pending"
                            colour="neutral-0"
                            size="large"
                        />
                    </div>
                }
                
                {/* ERROR */}
                {props.status === "failure" &&
                    <div className="crm-gallery-image__error">
                        <CRMIcon
                            iconName="error-outline"
                            colour="highlights-instructional-brick-0"
                            size="large"
                        />
                    </div>
                }

            </div>
            {/* IMAGE PLACEHOLDER (THE PART THAT REMAINS WHEN DRAGGING HAPPENS) */}

            <div className="crm-gallery-image__image-placeholder"></div>
        </div>
    );
};