import React, { useEffect, useState } from "react";
import { ContentState, EditorState } from "draft-js";
import { convertFromHTML, convertToHTML } from "draft-convert";
import { CRMFontSizes } from "../../models/CRMFontSizes";
import { CRMSpacing } from "../../models/CRMSpacing";
import { TContainerStateProps } from "../../state/TContainerStateProps";
import { CRMCardOutsideLabelled } from "../../components/CRMCardOutsideLabelled/CRMCardOutsideLabelled";
import { CRMAutosaveIndicatorWrap } from "../../components/CRM/CRMAutosaveIndicatorWrap/CRMAutosaveIndicatorWrap";
import { FontQuicksand } from "../../components/BuildingBlocks/FontQuicksand";
import { FontSize } from "../../components/BuildingBlocks/FontSize";
import { SpacingColumn } from "../../components/BuildingBlocks/SpacingColumn";
import { SpacingRow } from "../../components/BuildingBlocks/SpacingRow";
import { CRMIcon } from "../../components/CRMIcon/CRMIcon";
import { CRMRichTextArea } from "../../components/CRMRichTextArea/CRMRichTextArea";
import { Uppercase } from "../../components/Uppercase/Uppercase";
import { WeightBold } from "../../components/WeightBold/WeightBold";

export const CRMRichCaseNotesCard = (props: TContainerStateProps): JSX.Element => {
    const externalContentState = props.state.forms.case_details_page.data.output.details.edited.staff_notes === "" ? ContentState.createFromText("") : 
        convertFromHTML(
            // Check if we have a non html string as notes if so convert the new lines to br tags
            props.state.forms.case_details_page.data.output.details.edited.staff_notes[0] === "<" ? 
                props.state.forms.case_details_page.data.output.details.edited.staff_notes 
                : props.state.forms.case_details_page.data.output.details.edited.staff_notes.replaceAll("\n", "<br />")
        );
    

    const [editorState, setEditorState] = useState(() =>
        {
            return EditorState.createWithContent(externalContentState);
        },
    );

    const [lastData, setLastData] = useState(props.state.forms.case_details_page.data.output.details.edited.staff_notes);
    useEffect(() => {
        if (lastData !== props.state.forms.case_details_page.data.output.details.edited.staff_notes) {
            setLastData(props.state.forms.case_details_page.data.output.details.edited.staff_notes)
            if (convertToHTML(editorState.getCurrentContent()).toString() !== props.state.forms.case_details_page.data.output.details.edited.staff_notes) {
                setEditorState(
                    EditorState.createWithContent(externalContentState)
                ); 
            }
        }
    })

    return <CRMCardOutsideLabelled
        labelColor={"highlights-calming-pink-4"}
        shadow={true}
    >
        <CRMAutosaveIndicatorWrap
            status={props.state.forms.case_details_page.data.output.details.status}
        >
            <SpacingColumn spacing={CRMSpacing.MEDIUM}>
                {/* TITLE */}
                <SpacingRow spacing={CRMSpacing.TINY}>
                    <CRMIcon 
                        iconName="chat-bubble"
                        size="small"
                        colour="neutral-ink"
                    />

                    <FontQuicksand>
                        <FontSize size={CRMFontSizes.SMALL} lineHeight="1.2">
                            <Uppercase>
                                <WeightBold>
                                    Staff Notes
                                </WeightBold>
                            </Uppercase>
                        </FontSize>
                    </FontQuicksand>
                </SpacingRow>
                <CRMRichTextArea
                    contentState={editorState}
                    onChange={(returnedState) => {
                        const hasBodyChanged = editorState.getCurrentContent() != returnedState.getCurrentContent();
                        setEditorState(returnedState);
                        if(hasBodyChanged) {
                            props.dispatch({
                                type: "LEGAL_CASE_DETAILS_CHANGE",
                                payload: {
                                    ...props.state.forms.case_details_page.data.output.details,
                                    edited: {
                                        ...props.state.forms.case_details_page.data.output.details.edited,
                                        staff_notes: convertToHTML(returnedState.getCurrentContent()).toString(),
                                    }
                                }
                            });
                        }
                    }
                    }
                ></CRMRichTextArea>
            </SpacingColumn>
        </CRMAutosaveIndicatorWrap>
    </CRMCardOutsideLabelled>
} 
    