import React from "react";

type TFrontOutsideCardProps = {
    id?: string;
};

export const FrontOutsideCard = (props: React.PropsWithChildren<TFrontOutsideCardProps>): JSX.Element => {
    return (
        <div
            id={props.id || ""}
            className={`front-outside-card`}
        >
            {props.children}
        </div>
    );
};
