import React from "react";
import { TContainerStateProps } from "./../../state/TContainerStateProps";
import { CRMSpacingColumn } from "../../components/CRMSpacingColumn/CRMSpacingColumn";
import { CRMTitleSection } from "../../components/CRMTitleSection/CRMTitleSection";
import { CRMSpacer } from "../../components/CRMSpacer/CRMSpacer";
import { CRMAutosaveIndicatorWrap } from "../../components/CRM/CRMAutosaveIndicatorWrap/CRMAutosaveIndicatorWrap";
import { CRMCodecEditForm } from "../../components/CRMCodecEditForm/CRMCodecEditForm";
import { CasesSellerConveyancerBankAccount } from "../../../../domain/codecs/Cases";

const CRMLegalCaseDetailsSellerConveyancerBankAccountContainer = (props: TContainerStateProps): JSX.Element => (
    <CRMAutosaveIndicatorWrap
        status={props.state.forms.case_details_page.data.output.details.status}
    >
        <CRMTitleSection>
            Seller's conveyancer bank account
        </CRMTitleSection>
        <CRMSpacer size="large" />
        <CRMSpacingColumn spacing="medium">
            <CRMCodecEditForm
                codec={CasesSellerConveyancerBankAccount}
                model={props.state.forms.case_details_page.data.output.details.edited}
                validationErrors={props.state.forms.case_details_page.data.output.details.validationErrors}
                onChange={(edited) => props.dispatch({
                    type: "LEGAL_CASE_DETAILS_CHANGE",
                    payload: {
                        ...props.state.forms.case_details_page.data.output.details,
                        edited: {
                            ...props.state.forms.case_details_page.data.output.details.edited,
                            ...edited,
                        }
                    }
                })}
                columns={2}
                showDeferDateLabels={true}
            />
        </CRMSpacingColumn>
    </CRMAutosaveIndicatorWrap>
);

export default CRMLegalCaseDetailsSellerConveyancerBankAccountContainer;