import React from "react";
import { TUserLegalNamesForm } from "../../../../../../domain/codecs/form/UserLegalNamesForm";
import { onChangeForm } from "../../../../../../shared/src/codecs/types/form";
import { FrontSpacing } from "../../../../models/FrontSpacing";
import { MediaCompSwitch } from "../../../BuildingBlocks/MediaCompSwitch";
import { SpacingColumn } from "../../../BuildingBlocks/SpacingColumn";
import { SpacingRow } from "../../../BuildingBlocks/SpacingRow";
import FrontInputGeneralComponent from "../../Simple/FrontInputGeneralComponent/FrontInputGeneralComponent";
import FrontInputLabelAndErrorWrapComponent from "../../Simple/FrontInputLabelAndErrorWrapComponent/FrontInputLabelAndErrorWrapComponent";

type TLegalNameFormProps = {
    form: TUserLegalNamesForm;
    onChange: (form: TUserLegalNamesForm) => void;
};

export const FrontLegalNameForm = (props: React.PropsWithChildren<TLegalNameFormProps>): JSX.Element => {

    const onChange = onChangeForm(props.form, props.onChange);

    return (
        <MediaCompSwitch
            DesktopComponent={SpacingRow}
            MobileComponent={SpacingColumn}
            desktopProps={{ spacing: FrontSpacing.MEDIUM_1 }}
            mobileProps={{ spacing: FrontSpacing.MEDIUM_1 }}
            breakpoint="600"
        > 
            {/* FIRST NAME */}
            <FrontInputLabelAndErrorWrapComponent label="First name">
                <FrontInputGeneralComponent
                    inputType="text"
                    value={props.form.edited.legal_first_legal_name}
                    onChange={onChange("legal_first_legal_name")}
                    displayError={false}
                />
            </FrontInputLabelAndErrorWrapComponent>
            
            {/* MIDDLE NAME */}
            <FrontInputLabelAndErrorWrapComponent label="Middle name(s)">
                <FrontInputGeneralComponent
                    inputType="text"
                    value={props.form.edited.legal_middle_legal_name}
                    onChange={onChange("legal_middle_legal_name")}
                    displayError={false}
                />
            </FrontInputLabelAndErrorWrapComponent>
            
            {/* LAST NAME */}
            <FrontInputLabelAndErrorWrapComponent label="Last name">
                <FrontInputGeneralComponent
                    inputType="text"
                    value={props.form.edited.legal_last_legal_name}
                    onChange={onChange("legal_last_legal_name")}
                    displayError={false}
                />
            </FrontInputLabelAndErrorWrapComponent>
        </MediaCompSwitch>
    );
};
