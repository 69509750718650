import React from "react";
interface IInputPhoneNumber {
    placeholder?: string;
    pattern?: string;
    name?: string;
    value?: string;
    displayError?: boolean;
    required?: boolean;
    className?: string;
    disabled?: boolean;
    onChange: (value: string) => void;
    onPressEnterKey: () => void;
}

class InputPhoneNumber extends React.Component<React.PropsWithChildren<IInputPhoneNumber>> {
    public render (): JSX.Element {
        return (
            <input
                className={
                    "inputComponent__input inputComponent__input--tel" +
                    (this.props.displayError ? " inputComponent__input--error " : " ") +
                    this.props.className
                }
                pattern={this.props.pattern}
                type="tel"
                placeholder={this.props.placeholder}
                value={this.props.value}
                required={this.props.required}
                name={this.props.name}
                id={this.props.name}
                disabled={this.props.disabled}
                onChange={(e) => this.props.onChange(e.currentTarget.value)}
                onKeyDown={(e) => e.keyCode === 13 && this.props.onPressEnterKey()}
            />
        );
    }
}

export default InputPhoneNumber;
