import React from "react";
import { CRMFontSizes } from "../../../models/CRMFontSizes";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { CRMColors } from "../../../models/CRMColors";
import { InlineFlex } from "../../BuildingBlocks/InlineFlex";
import { SpacingColumn } from "../../BuildingBlocks/SpacingColumn";
import { TextHightlight } from "../../BuildingBlocks/TextHightlight";
import { CRMParagraph } from "../../Simple/CRMParagraph/CRMParagraph";
import { WeightBold } from "../../WeightBold/WeightBold";
import { TCaseChargeLegalFile2 } from "../../../../../domain/codecs/CaseChargeLegalFile";
import { identity } from "fp-ts";
import { WeightSemiBold } from "../../WeightSemiBold/WeightSemiBold";
import { WeightMedium } from "../../WeightMedium/WeightMedium";
import { DateTime } from "luxon";

type TTextFormatFunction = (value: string) => JSX.Element | string;

export const CRMRedemptionStatementAttachment = (props: React.PropsWithChildren<{
    tagPrefix?: string;
    redemptionStatement: TCaseChargeLegalFile2;
    restrictionHolderFormatting?: (message: string) => JSX.Element | string; 
}>): JSX.Element => {
    const formatTextWith = (functionName: "restrictionHolderFormatting"): TTextFormatFunction => {
        if (typeof props[functionName] === "function") {
            return props[functionName] as TTextFormatFunction;
        }
        return identity.flatten;
    }

    const formatRestrictionHolder = formatTextWith("restrictionHolderFormatting");

    return (
        <SpacingColumn spacing={CRMSpacing.MEDIUM}>
            {/* ATTACHMENT TYPE LABEL */}
            <InlineFlex>
                <TextHightlight color={CRMColors.HIGHLIGHTS_PERTINENT_YELLOW_6}>
                    <CRMParagraph>
                        <WeightBold>
                            {props.tagPrefix || "" } Redemption statement
                        </WeightBold>
                    </CRMParagraph>
                </TextHightlight>
            </InlineFlex>
            
            <SpacingColumn spacing={CRMSpacing.TINY}>
                <CRMParagraph fontSize={CRMFontSizes.SMALL}>
                    <WeightBold>
                        {formatRestrictionHolder(props.redemptionStatement.beneficiary)}
                    </WeightBold>
                </CRMParagraph>
                
                <CRMParagraph fontSize={CRMFontSizes.SMALL}>
                    <WeightSemiBold>
                        Expires: 
                    </WeightSemiBold>&nbsp;
                    <WeightMedium>{DateTime.fromISO(props.redemptionStatement.expires_at).setZone("Europe/London").toFormat("d MMM")}</WeightMedium>
                </CRMParagraph>
            </SpacingColumn>
        </SpacingColumn>
    );
};