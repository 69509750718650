import React from "react";
import * as ListingEnquiriesViewing2 from "../../../../domain/models/ListingEnquiriesViewing2";
import * as ISODateTime from "../../../../domain/models/ISODateTime";
import * as User10 from "../../../../domain/models/User10";
import { CRMSpacer } from "../CRMSpacer/CRMSpacer";
import { CRMCardOutsideMinor } from "../CRMCardOutsideMinor/CRMCardOutsideMinor";
import { CRMParagraph } from "../Simple/CRMParagraph/CRMParagraph";
import { WeightBold } from "../WeightBold/WeightBold";
import { Uppercase } from "../Uppercase/Uppercase";
import { CRMIcon } from "../CRMIcon/CRMIcon";
import { CRMTitleSubSection } from "../CRMTitleSubSection/CRMTitleSubSection";
import { CRMPadding } from "../Simple/CRMPadding/CRMPadding";

class CRMUpcomingViewingMiniComponent extends React.Component<{
    viewing: ListingEnquiriesViewing2.TSimpleViewing;
}> {
    timeUntilViewingString = (days: number, formattedDateString: string): string => {
        switch (true) {
            case (days >= 14):
                return `${formattedDateString} (In ${Math.floor(days/7).toString()} weeks)`;
            case (days > 1):
                return `${formattedDateString} (In ${days.toString()} days)`;
            case (days > 0):
                return `${formattedDateString} (Tomorrow)`;
            case (days === 0):
                return `${formattedDateString} (Later today)`;
            default:
                return formattedDateString;
        }
    };

    public render (): JSX.Element {
        return (
            <div className="crm-upcoming-viewing-mini-component">
                <CRMCardOutsideMinor thickLeftBorder={true}>
                    <CRMPadding size="medium">
                        <CRMParagraph>
                            <div className="crm-upcoming-viewing-mini-component__days-until-viewing">
                                <WeightBold>
                                    {this.timeUntilViewingString(
                                        ISODateTime.getNumberOfDaysUntilDate(this.props.viewing.date),
                                        ISODateTime.getFormattedDateString(this.props.viewing.date, "ccc d LLLL - h:mma"),
                                    )}
                                </WeightBold>
                            </div>
                        </CRMParagraph>
                        <CRMSpacer size="tiny"/>
                        <CRMTitleSubSection>
                            <Uppercase>{this.props.viewing.viewing_type === "in_person" ? "In person" : "Online"}</Uppercase>
                        </CRMTitleSubSection>
                        <CRMSpacer size="tiny"/>
                        <CRMParagraph>
                            <WeightBold>{User10.usersToNamesString(this.props.viewing.party.users, () => "Names Not Given")}</WeightBold>
                        </CRMParagraph>
                        <div className="crm-upcoming-viewing-mini-component__confirmation-status-icon">
                            <CRMIcon
                                iconName={this.props.viewing.status === "confirmed" ? "done-all" : "question-in-circle" }
                                colour="neutral-ink"
                            />
                        </div>
                    </CRMPadding>
                </CRMCardOutsideMinor>
            </div>
        );
    }
}

export default CRMUpcomingViewingMiniComponent;
