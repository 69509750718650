import React from "react";
import { NonEmptyArray } from "fp-ts/lib/NonEmptyArray";
import { CRMSpacingRow } from "../CRMSpacingRow/CRMSpacingRow";
import { useInputSwitch, TSwitchOption } from "../../hooks/useInputSwitch";
import CRMInputLabelAndErrorWrapComponent from "../CRMInputLabelAndErrorWrapComponent/CRMInputLabelAndErrorWrapComponent";

type TInputLabelAndErrorWrapSwitchProps<T> = {
    options: NonEmptyArray<TSwitchOption<T>>;
};

type TMultiOptionLabel<T> = {
    options: Array<TSwitchOption<T>>;
    selectedOption: T;
    onSelectOption: (value: T) => void;
};

export const CRMInputLabelAndErrorWrapSwitch = <T extends string>(props: TInputLabelAndErrorWrapSwitchProps<T>): JSX.Element => {

    const {
        selectedOption,
        setSelectedOption,
    } = useInputSwitch(props.options);

    return (
        <CRMInputLabelAndErrorWrapComponent
            label={
                <MultiOptionLabel
                    options={props.options}
                    selectedOption={selectedOption}
                    onSelectOption={(optionKey) => setSelectedOption(optionKey)}
                />
            }
        >
            {props.options.map((optionInput, index) => (
                <div
                    key={index}
                    className={`
                        crm-input-label-and-error-wrap-switch__option
                        crm-input-label-and-error-wrap-switch__option--${selectedOption === optionInput.value ? 'selected' : 'un-selected'}
                    `}
                >
                    {optionInput.input}
                </div>
            ))}
        </CRMInputLabelAndErrorWrapComponent>
    );
};

const MultiOptionLabel = <T extends string>(props: TMultiOptionLabel<T>): JSX.Element => (
    <CRMSpacingRow spacing="tiny">
        {props.options.map((inputOption, index) => (
            <div
                key={index}
                className={`
                    crm-input-label-and-error-wrap-switch__label
                    crm-input-label-and-error-wrap-switch__label--${props.selectedOption === inputOption.value ? 'selected' : 'un-selected'}
                `}
                onClick={() => props.onSelectOption(inputOption.value)}
            >
                {inputOption.label}
            </div>
        ))}
    </CRMSpacingRow>
);