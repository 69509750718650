import React from "react";
import { LabelWrapForInputs } from "../../components/OldFront/Simple/Label/LabelWrapForInputs";
import { FormBody, FormRow } from "../../components/FormTemplateBlocksComponents/FormTemplateBlocks";
import FormButton from "../../components/FormButtonComponent/FormButton";
import { option } from "fp-ts";
import { InputTextForInteger } from "../../components/OldFront/Simple/TextForInteger/InputTextForInteger";
import InputCurrency from "../../components/OldFront/Simple/Currency/InputCurrency";
import InputTextArea from "../../components/OldFront/Simple/TextArea/InputTextArea";
import { TFormUpdateActionPayload } from "../../state/applyActions";
import * as Party6 from "../../../../domain/models/Party6";
import { TContainerStateProps } from "../../state/TContainerStateProps";
import { pipe } from "fp-ts/lib/function";

class CRMPartySearchCriteria extends React.Component<React.PropsWithChildren<TContainerStateProps>> {
    public render(): JSX.Element {
        const formState = this.props.state.activeData.crm.party.partySearchCriteriaForm;
        return (
            <FormBody>
                {/* SEARCH CRITERIA - AREA */}
                <FormRow>
                    <LabelWrapForInputs label="Area" >
                        <InputTextArea
                            value={formState.form.search_criteria_area}
                            onChange={(value) => this.props.dispatch({
                                type: "CRM_PARTY_SEARCH_CRITERIA_INPUT_CHANGED",
                                payload: {
                                    key: "search_criteria_area",
                                    value,
                                } as TFormUpdateActionPayload<Party6.T, "search_criteria_area">,
                            })}
                            onPressEnterKey={() => undefined}
                        />
                    </LabelWrapForInputs>
                </FormRow>
                {/* SEARCH CRITERIA - MIN BEDS */}
                <FormRow>
                    <LabelWrapForInputs
                        label="Min. Beds"
                        guidance="Number only (or leave blank)"
                    >
                        <InputTextForInteger
                            value={
                                 pipe(
                                    formState.form.search_criteria_min_beds,
                                    option.fromNullable,
                                    option.fold(
                                        () => (""),
                                        (minBedsNumber: number) => minBedsNumber.toString()
                                    )
                                )
                            }
                            onChange={(value) => this.props.dispatch({
                                    type: "CRM_PARTY_SEARCH_CRITERIA_INPUT_CHANGED",
                                    payload: {
                                        key: "search_criteria_min_beds",
                                        value: value === "" ? null : Number(value),
                                    } as TFormUpdateActionPayload<Party6.T, "search_criteria_min_beds">,
                            })}
                            onPressEnterKey={() => undefined}
                        />
                    </LabelWrapForInputs>
                </FormRow>
                {/* SEARCH CRITERIA - MAX PRICE */}
                <FormRow>
                    <LabelWrapForInputs
                        label="Max. Price"
                        guidance="Enter an amount in whole GBP without commas, spaces or decimal place (or leave blank)"
                    >
                        <InputCurrency
                            currencySymbol="£"
                            value={
                                 pipe(
                                    formState.form.search_criteria_max_price,
                                    option.fromNullable,
                                    option.fold(
                                        () => (""),
                                        (maxPrice) => maxPrice.toString()
                                    )
                                )
                            }
                            onChange={(value) => this.props.dispatch({
                                type: "CRM_PARTY_SEARCH_CRITERIA_INPUT_CHANGED",
                                payload: {
                                    key: "search_criteria_max_price",
                                    value: value === "" ? null : Number(value),
                                } as TFormUpdateActionPayload<Party6.T, "search_criteria_max_price">,
                        })}
                            onPressEnterKey={() => undefined}
                        />
                    </LabelWrapForInputs>
                </FormRow>
                {/* SEARCH CRITERIA - PREFERENCES */}
                <FormRow>
                    <LabelWrapForInputs label="Preferences">
                        <InputTextArea
                            value={formState.form.search_criteria_preferences}
                            onChange={(value) => this.props.dispatch({
                                type: "CRM_PARTY_SEARCH_CRITERIA_INPUT_CHANGED",
                                payload: {
                                    key: "search_criteria_preferences",
                                    value,
                                } as TFormUpdateActionPayload<Party6.T, "search_criteria_preferences">,
                            })}
                            onPressEnterKey={() => undefined}
                        />
                    </LabelWrapForInputs>
                </FormRow>
                <FormRow>
                    <FormButton
                        status={formState.status}
                        label="Save search criteria"
                        type="frog"
                        onClick={() => this.props.dispatch({type: "CRM_PARTY_SEARCH_CRITERIA_SUBMIT"})}
                    />
                </FormRow>
            </FormBody>
        );
    }
}

export default CRMPartySearchCriteria;
