import React from "react";
import { hexToRgb } from "../../functions/colour";
import { CRMSpacing } from "../../models/CRMSpacing";
import { THexColor, TPixelSize } from "../../models/StringLiterals";
import { Absolute } from "../BuildingBlocks/Absolute";
import { BackgroundLinearGradient } from "../BuildingBlocks/BackgroundLinearGradient";
import { MinWidth } from "../BuildingBlocks/MinWidth";
import { Padding } from "../BuildingBlocks/Padding";
import { SpacingColumn } from "../BuildingBlocks/SpacingColumn";
import { SpacingRow } from "../BuildingBlocks/SpacingRow";

export const CRMTimelineControlsWrapper = (props: React.PropsWithChildren<{
    backgroundColour: THexColor;
    top?: TPixelSize;
    right?: TPixelSize;
    bottom?: TPixelSize;
}>): JSX.Element =>
    <Absolute 
        top={props.top} 
        right={props.right}
        bottom={props.bottom}
    >
        <SpacingColumn 
            alignItems="center" 
            height="100%"
        >
            <Padding
                type="right"
                spacing={CRMSpacing.SMALL}
            >
                <BackgroundLinearGradient
                    leftColour={`rgba(${hexToRgb(props.backgroundColour)}, 0.6)`}
                    leftColourStopsAt="10%"
                    rightColour={`rgba(${hexToRgb(props.backgroundColour)}, 1)`}
                >
                    <MinWidth width="45px">
                        <SpacingRow justifyContent="end" spacing={CRMSpacing.TINY}>
                            {props.children}
                        </SpacingRow>
                    </MinWidth>
                </BackgroundLinearGradient>
            </Padding>
        </SpacingColumn>
    </Absolute>;
