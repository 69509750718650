import { array, option } from "fp-ts";
import { pipe } from "fp-ts/lib/function";
import React from "react";
import { ClientCompanyPartnershipUserType, ClientCompanyPartnershipUserType_displayString } from "../../../../../domain/codecs/ClientCompanyPartnership";
import { TClientCompanyPartnershipUserForm, TClientCompanyPartnershipUserPhoneNumberCreateForm, TClientCompanyPartnershipUserPhoneNumberDeleteForm, TClientCompanyPartnershipUserPhoneNumberForm, TClientCompanyPartnershipUserPhoneNumberMakePrimaryForm } from "../../../../../domain/codecs/form/ClientCompanyPartnershipForm";
import { FormStatus_highestPriority } from "../../../../../shared/src/codecs/codec";
import { doesErrorCodeExist, doesErrorKeyExist } from "../../../../../shared/src/codecs/errors";
import { onChangeForm } from "../../../../../shared/src/codecs/types/form";
import { useResolutionStatus } from "../../../hooks/useResolutionStatus";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { MinWidth } from "../../BuildingBlocks/MinWidth";
import { Padding } from "../../BuildingBlocks/Padding";
import { Relative } from "../../BuildingBlocks/Relative";
import { SpacingColumn } from "../../BuildingBlocks/SpacingColumn";
import { SpacingRow } from "../../BuildingBlocks/SpacingRow";
import { CRMBottomControlBar } from "../../CRMBottomControlBar/CRMBottomControlBar";
import { CRMButtonIcon } from "../../CRMButtonIcon/CRMButtonIcon";
import { CRMButtonPrimary } from "../../CRMButtonPrimary/CRMButtonPrimary";
import { CRMDropdownComponent } from "../../CRMDropdownComponent/CRMDropdownComponent";
import { CRMFormButton } from "../../CRMFormButton/CRMFormButton";
import CRMInputGeneralComponent from "../../CRMInputs/CRMInputGeneralComponent/CRMInputGeneralComponent";
import CRMInputLabelAndErrorWrapComponent from "../../CRMInputLabelAndErrorWrapComponent/CRMInputLabelAndErrorWrapComponent";
import { CRMPopoutHeader } from "../../Simple/CRMPopoutHeader/CRMPopoutHeader";
import { CRMBlockPopover } from "../../CRM/CRMBlock/CRMBlockPopover/CRMBlockPopover";
import { CRMConfirmationCountdown } from "../../Complex/CRMConfirmationCountdown/CRMConfirmationCountdown";
import { CRMInputPhoneNumberCardStack, TPhoneNumber } from "../../Complex/CRMInputPhoneNumberCardStack/CRMInputPhoneNumberCardStack";

export const CRMCompanyPartnerPeopleForm = (props: React.PropsWithChildren<{
    form: TClientCompanyPartnershipUserForm;
    onChange: (form: TClientCompanyPartnershipUserForm) => void;
    onSave: (form: TClientCompanyPartnershipUserForm) => void;
    onDelete: (form: TClientCompanyPartnershipUserForm) => void;
    onPhoneNumberChange: (form: TClientCompanyPartnershipUserPhoneNumberForm) => void;
    onNewPhoneNumber: (form: TClientCompanyPartnershipUserPhoneNumberCreateForm) => void;
    onPhoneNumberMakePrimary: (form: TClientCompanyPartnershipUserPhoneNumberMakePrimaryForm) => void;
    onPhoneNumberDelete: (form: TClientCompanyPartnershipUserPhoneNumberDeleteForm) => void;
    onClose: () => void;
}>): JSX.Element => {
    const {
        resolutionStatus,
        resolutionData,
        setResolution,
        resetResolution,
    } = useResolutionStatus<"none" | "deleting" | "deleting_phone_number", {id: string}>({
        defaultStatus: "none",
    });

    const fullName =
        pipe(
            [
                props.form.edited.first_name,
                props.form.edited.last_name
            ],
            array.filter((name) => name !== ""),
            (a) => a.join(" "),
        )

    const phoneNumbers =
        pipe(
            props.form.children.phone_number_forms,
            array.map((form): TPhoneNumber => ({
                id: form.edited.id,
                phone_number: form.edited.phone_number,
                primary_number: form.children.primary_number,
                is_verified: form.children.is_verified,
                has_error: form.status === "validationError",
            })),
        );

    const formStatus =
        pipe(
            [
                props.form.status,
                ...props.form.children.phone_number_forms.map(({status}) => status),
            ],
            FormStatus_highestPriority,
        );

    const hasPhoneNumberInvalidError = doesErrorCodeExist("PhoneNumberInvalid", props.form.validationErrors);

    const hasPhoneNumberInUseError = doesErrorCodeExist("InUse", props.form.validationErrors);

    const onChange = onChangeForm(props.form, props.onChange);

    const onPhoneNumberChange = (id: string, phone_number: string | null) =>
        pipe(
            props.form.children.phone_number_forms,
            array.findFirst(({edited}) => edited.id === id),
            option.fold(
                () => undefined,
                (form) => onChangeForm(form, props.onPhoneNumberChange)("phone_number")(phone_number),
            )
        );

    const onNewPhoneNumber = () => props.onNewPhoneNumber(props.form.children.create_phone_number_form);

    const onPhoneNumberMakePrimary = (id: string) =>
        pipe(
            props.form.children.phone_number_forms,
            array.findFirst(({edited}) => edited.id === id),
            option.fold(
                () => undefined,
                (form) => props.onPhoneNumberMakePrimary(form.children.make_primary_form),
            )
        );

    const onPhoneNumberDelete = (id: string) =>
        pipe(
            props.form.children.phone_number_forms,
            array.findFirst(({edited}) => edited.id === id),
            option.fold(
                () => undefined,
                (form) => {
                    props.onPhoneNumberDelete(form.children.delete_form);
                    resetResolution();
                },
            )
        );

    return <CRMBlockPopover
        padding={CRMSpacing.MEDIUM}
        maxHeight="720px"
    >
        <Relative>
            {/* HEADER */}
            <CRMPopoutHeader
                icon="arrow-left"
                onClick={props.onClose}
            >
                Edit {fullName || "user"}
            </CRMPopoutHeader>

            {/* USER FIELDS */}
            <Padding
                spacing={CRMSpacing.MEDIUM}
                height="720px"
            >
                <MinWidth width="100%">
                    <SpacingColumn spacing={CRMSpacing.MEDIUM}>
                        {/* FIRST NAME */}
                        <CRMInputLabelAndErrorWrapComponent label="First name">
                            <CRMInputGeneralComponent
                                inputType="text"
                                value={props.form.edited.first_name}
                                onChange={onChange("first_name")}
                                displayError={false}
                            />
                        </CRMInputLabelAndErrorWrapComponent>

                        {/* LAST NAME */}
                        <CRMInputLabelAndErrorWrapComponent label="Last name">
                            <CRMInputGeneralComponent
                                inputType="text"
                                value={props.form.edited.last_name}
                                onChange={onChange("last_name")}
                                displayError={false}
                            />
                        </CRMInputLabelAndErrorWrapComponent>

                        {/* TYPE */}
                        <CRMInputLabelAndErrorWrapComponent label="Type">
                            <CRMDropdownComponent
                                value={props.form.edited.type}
                                options={pipe(
                                    ClientCompanyPartnershipUserType.values,
                                    array.map((value) => ({
                                        value,
                                        label: ClientCompanyPartnershipUserType_displayString(value),
                                    }))
                                )}
                                onChange={onChange("type")}
                                displayError={false}
                            />
                        </CRMInputLabelAndErrorWrapComponent>

                        {/* EMAIL */}
                        <CRMInputLabelAndErrorWrapComponent
                            label="Email"
                            displayError={doesErrorKeyExist("edited.email", props.form.validationErrors)}
                            errorMessage="This email address is not valid or already in use."
                        >
                            <CRMInputGeneralComponent
                                inputType="email"
                                value={props.form.edited.email || ""}
                                onChange={(email) => onChange("email")(email || null)}
                                displayError={doesErrorKeyExist("edited.email", props.form.validationErrors)}
                            />
                        </CRMInputLabelAndErrorWrapComponent>

                        {/* PHONE NUMBERS */}
                        <CRMInputLabelAndErrorWrapComponent
                            label="Phone numbers"
                            displayError={hasPhoneNumberInvalidError || hasPhoneNumberInUseError}
                            errorMessage={
                                hasPhoneNumberInvalidError ? "One of the phone numbers provided isn't valid."
                                : hasPhoneNumberInUseError ? "You can't add the same phone number twice."
                                : ""
                            }
                        >
                            <CRMInputPhoneNumberCardStack
                                phoneNumbers={phoneNumbers}
                                onAddNewNumber={onNewPhoneNumber}
                                onMakePrimary={onPhoneNumberMakePrimary}
                                onDelete={(id) => setResolution("deleting_phone_number")({id})}
                                onPhoneNumberChange={onPhoneNumberChange}
                            />
                        </CRMInputLabelAndErrorWrapComponent>
                    </SpacingColumn>
                </MinWidth>
            </Padding>

            {/* CONTROLS */}
            <CRMBottomControlBar>
                {resolutionStatus === "deleting" &&
                    <CRMConfirmationCountdown
                        title="Deleting!"
                        iconName="archive"
                        primaryButtonMode="done"
                        onUndo={resetResolution}
                        onComplete={() => props.onDelete(props.form)}
                    />
                }

                {resolutionStatus === "deleting_phone_number" &&
                    <CRMConfirmationCountdown
                        title="Deleting phone number!"
                        iconName="archive"
                        primaryButtonMode="done"
                        onUndo={resetResolution}
                        onComplete={() => onPhoneNumberDelete(resolutionData?.id || "")}
                    />
                }

                {resolutionStatus === "none" &&
                    <Padding 
                        type="custom"
                        width="100%" 
                        spacing={`${CRMSpacing.TINY} ${CRMSpacing.MEDIUM}`}
                    >
                        <SpacingRow justifyContent="space-between">
                            {/* DELETE */}
                            <CRMButtonIcon
                                variant="quaternary"
                                icon="delete"
                                onClick={setResolution("deleting")}
                            />

                            {/* SAVE */}
                            <CRMFormButton
                                formStatus={formStatus}
                                ButtonElement={CRMButtonPrimary}
                                label="Save"
                                onClick={() => props.onSave(props.form)}
                            />
                        </SpacingRow>
                    </Padding>
                }
            </CRMBottomControlBar>
        </Relative>
    </CRMBlockPopover>;
};
