import React from "react";
import { pipe } from "fp-ts/lib/function";
import { CRMPadding } from "../../components/Simple/CRMPadding/CRMPadding";
import { CRMCardOutsideLabelled } from "./../../components/CRMCardOutsideLabelled/CRMCardOutsideLabelled";
import { CRMCardOutside } from "./../../components/CRMCardOutside/CRMCardOutside";
import { CRMListingProgressionSellerSolicitorKickOffForm } from "./../../components/CRMListingProgressionSellerSolicitorKickOffForm/CRMListingProgressionSellerSolicitorKickOffForm";
import { CRMListingProgressionBuyerSolicitorKickOffForm } from "./../../components/CRMListingProgressionBuyerSolicitorKickOffForm/CRMListingProgressionBuyerSolicitorKickOffForm";
import { CRMListingProgressionMortgageBrokerKickOffForm } from "./../../components/CRMListingProgressionMortgageBrokerKickOffForm/CRMListingProgressionMortgageBrokerKickOffForm";
import { CRMListingProgressionBuyerOwnSurveyForm } from "./../../components/CRMListingProgressionBuyerOwnSurveyForm/CRMListingProgressionBuyerOwnSurveyForm";
import { CRMListingProgressionMortgageSurveyForm } from "./../../components/CRMListingProgressionMortgageSurveyForm/CRMListingProgressionMortgageSurveyForm";
import { CRMListingProgressionBuyerConveyancerHasEverythingRequiredToStartForm } from "./../../components/CRMListingProgressionBuyerConveyancerHasEverythingRequiredToStartForm/CRMListingProgressionBuyerConveyancerHasEverythingRequiredToStartForm";
import { CRMListingProgressionMortgageOfferReceived } from "./../../components/CRMListingProgressionMortgageOfferReceived/CRMListingProgressionMortgageOfferReceived";
import { CRMListingProgressionBuyerConveyancerReceivedContractsForm } from "./../../components/CRMListingProgressionBuyerConveyancerReceivedContractsForm/CRMListingProgressionBuyerConveyancerReceivedContractsForm";
import { CRMListingProgressionBuyerConveyancerRaisedEnquiriesForm } from "./../../components/CRMListingProgressionBuyerConveyancerRaisedEnquiriesForm/CRMListingProgressionBuyerConveyancerRaisedEnquiriesForm";
import { CRMListingProgressionAllEnquiriesResolvedForm } from "./../../components/CRMListingProgressionAllEnquiriesResolvedForm/CRMListingProgressionAllEnquiriesResolvedForm";
import { CRMListingProgressionKeysReleasedToBuyerForm } from "./../../components/CRMListingProgressionKeysReleasedToBuyerForm/CRMListingProgressionKeysReleasedToBuyerForm";
import { CRMListingProgressionExchangeDateSetForm } from "./../../components/CRMListingProgressionExchangeDateSetForm/CRMListingProgressionExchangeDateSetForm";
import { CRMListingProgressionCompletedDateSetForm } from "./../../components/CRMListingProgressionCompletedDateSetForm/CRMListingProgressionCompletedDateSetForm";
import { CRMTitleSection } from "./../../components/CRMTitleSection/CRMTitleSection";
import { CRMListingProgressionKickOffLinks } from "./../../components/CRMListingProgressionKickOffLinks/CRMListingProgressionKickOffLinks";
import { CRMAutosaveIndicatorWrap } from "./../../components/CRM/CRMAutosaveIndicatorWrap/CRMAutosaveIndicatorWrap";
import { CRMHorizontalSeperatorLine } from "./../../components/CRMHorizontalSeperatorLine/CRMHorizontalSeperatorLine";
import { array } from "fp-ts";
import { CRMListingProgressionMortgageApplicationSubmitted } from "./../../components/CRMListingProgressionMortgageApplicationSubmitted/CRMListingProgressionMortgageApplicationSubmitted";
import { CRMListingCaseOverview } from "./../../components/CRMListingCaseOverview/CRMListingCaseOverview";
import { CRMFontSizes } from "../../models/CRMFontSizes";
import { WeightBold } from "./../../components/WeightBold/WeightBold";
import { Uppercase } from "./../../components/Uppercase/Uppercase";
import { WeightMedium } from "./../../components/WeightMedium/WeightMedium";
import { WeightRegular } from "./../../components/WeightRegular/WeightRegular";
import { CRMListingPageCasePeople } from "./../../components/CRMListingPageCasePeople/CRMListingPageCasePeople";
import { CRMListingFormSections, TCRMListingFormSections } from "../../models/CRMFormSections";
import { C as State } from "./../../state/State";
import { TDispatch } from "./../../state/TDispatch";
import { Sticky } from "../../components/BuildingBlocks/Sticky";
import { ListingProgressionNotes } from "../../../../domain/codecs/Listing";
import { CRMCodecEditForm } from "../../components/CRMCodecEditForm/CRMCodecEditForm";
import { CRMSpacing } from "../../models/CRMSpacing";
import { SpacingColumn } from "../../components/BuildingBlocks/SpacingColumn";
import { CRMSailHomesAdHocTasksCard } from "../../components/CRMSailHomesAdHocTasksCard/CRMSailHomesAdHocTasksCard";
import { Routes } from "../../state/router/routerRoutes";
import { SpacingRow } from "../../components/BuildingBlocks/SpacingRow";
import { FontSignikaNegative } from "../../components/BuildingBlocks/FontSignikaNegative";
import { Text } from "../../components/BuildingBlocks/Text";
import { FontQuicksand } from "../../components/BuildingBlocks/FontQuicksand";
import { CRMListingIntroducerRefNotesCard } from "../../components/CRMListingIntroducerRefNotesCard/CRMListingIntroducerRefNotesCard";

type TListingPagePrepContainerProps = {
    state: State<Routes>;
    dispatch: TDispatch;
};

export const CRMListingPageProgressionContainer = (props: TListingPagePrepContainerProps): JSX.Element => {

    const hasOfferAccepted = (): boolean =>
        props.state.forms.listing_page.data.output.read_only.listing_has_offer_accepted;

    const acceptedOfferIsMortgage = (): boolean =>
        props.state.forms.listing_page.data.output.read_only.buyer_payment_method === "mortgage"
    ;

    const isDetailsSectionSelected = (section: TCRMListingFormSections[number]): boolean => 
        CRMListingFormSections.isDetailsSectionSelected(
            props.state.routes.queryParams.VIEW_CRM_LISTING.visible_sections,
            section,
        );

    const introducerRef = () => props.state.forms.listing_page.data.output.read_only.introducer_external_reference_id;
    const introducerNotes = () => props.state.forms.listing_page.data.output.read_only.introducer_notes;

    return (
        <SpacingRow
            childSize="1fr 2fr"
            spacing={CRMSpacing.LARGE}
            alignItems="flex-start"
        >
            <SpacingColumn spacing={CRMSpacing.LARGE} >
                {/* AD HOC CARD */}
                <CRMSailHomesAdHocTasksCard
                    addNewTaskFormStatus={props.state.forms.listing_page.create_ad_hoc_task.status}
                    tasks={props.state.forms.listing_page.data.output.ad_hoc_tasks.filter((task) => task.original.section === "progression")}
                    onUpdateTasks={(payload) => props.dispatch({
                        type: "LISTING_PAGE_UPDATE_AD_HOC_TASKS",
                        payload,
                    })}
                    onAddNewTask={(task) => props.dispatch({
                        type: "LISTING_PAGE_ADD_AD_HOC_TASK",
                        payload: {
                            task,
                            listing_id: props.state.forms.listing_page.data.output.form.original.id,
                            section: "progression"
                        }
                    })}
                />
            

                {/* FORMS */}
                <CRMCardOutside
                    borderRounding="right"
                    shadow={true}
                >
                    <CRMPadding size="large">
                        {/* Kickoffs */}
                        <SpacingColumn spacing={CRMSpacing.LARGE}>
                            <CRMCodecEditForm
                                codec={ListingProgressionNotes}
                                model={props.state.forms.listing_page.data.output.form.edited}
                                validationErrors={props.state.forms.listing_page.data.output.form.validationErrors}
                                onChange={(edited) => props.dispatch({
                                    type: "LISTING_PREPARATION_FORM_CHANGE",
                                    payload: {
                                        ...props.state.forms.listing_page.data.output.form,
                                        edited: {
                                            ...props.state.forms.listing_page.data.output.form.edited,
                                            ...edited,
                                        }
                                    }
                                })}
                            />
                            {hasOfferAccepted()
                                && (
                                    isDetailsSectionSelected("progression_buyer_solicitor_kick_off")
                                    || isDetailsSectionSelected("progression_seller_solicitor_kick_off")
                                    || (isDetailsSectionSelected("progression_mortgage_broker_kick_off") && acceptedOfferIsMortgage())
                                )
                                && <CRMAutosaveIndicatorWrap status={props.state.forms.listing_page.data.output.form.status}>
                                    <SpacingColumn spacing={CRMSpacing.LARGE}>
                                        <CRMTitleSection>
                                            Kick-offs
                                        </CRMTitleSection>
                                        <CRMListingProgressionKickOffLinks
                                            listingForm={props.state.forms.listing_page.data.output.form}
                                            listingReadOnly={props.state.forms.listing_page.data.output.read_only}
                                        />
                                        {...pipe(
                                            [
                                                ...isDetailsSectionSelected("progression_seller_solicitor_kick_off")
                                                    ? [<CRMListingProgressionSellerSolicitorKickOffForm
                                                        listingForm={props.state.forms.listing_page.data.output.form}
                                                        listingReadOnly={props.state.forms.listing_page.data.output.read_only}
                                                        onChange={(form) => props.dispatch({
                                                            type: "LISTING_PREPARATION_FORM_CHANGE",
                                                            payload: form
                                                        })}
                                                    />]
                                                    : [],
                                                ...isDetailsSectionSelected("progression_buyer_solicitor_kick_off")
                                                    ? [<CRMListingProgressionBuyerSolicitorKickOffForm
                                                        listingForm={props.state.forms.listing_page.data.output.form}
                                                        listingReadOnly={props.state.forms.listing_page.data.output.read_only}
                                                        onChange={(form) => props.dispatch({
                                                            type: "LISTING_PREPARATION_FORM_CHANGE",
                                                            payload: form
                                                        })}
                                                    />]
                                                    : [],
                                                ...isDetailsSectionSelected("progression_mortgage_broker_kick_off") && acceptedOfferIsMortgage()
                                                    ? [<CRMListingProgressionMortgageBrokerKickOffForm
                                                        listingForm={props.state.forms.listing_page.data.output.form}
                                                        listingReadOnly={props.state.forms.listing_page.data.output.read_only}
                                                        onChange={(form) => props.dispatch({
                                                            type: "LISTING_PREPARATION_FORM_CHANGE",
                                                            payload: form
                                                        })}
                                                    />]
                                                    : [],
                                            ],
                                            array.intersperse(<CRMHorizontalSeperatorLine />),
                                        )}
                                    </SpacingColumn>
                            </CRMAutosaveIndicatorWrap>}

                            {/* Surveys */}
                            {hasOfferAccepted()
                                && (
                                    isDetailsSectionSelected("progression_buyer_own_survey")
                                    || (isDetailsSectionSelected("progression_mortgage_survey") && acceptedOfferIsMortgage())
                                )
                                && <CRMAutosaveIndicatorWrap status={props.state.forms.listing_page.data.output.form.status}>
                                    <SpacingColumn spacing={CRMSpacing.LARGE}>
                                        <CRMTitleSection>
                                            Surveys
                                        </CRMTitleSection>
                                        {...pipe(
                                            [
                                                ...isDetailsSectionSelected("progression_buyer_own_survey")
                                                    ? [<CRMListingProgressionBuyerOwnSurveyForm
                                                        listingForm={props.state.forms.listing_page.data.output.form}
                                                        onChange={(form) => props.dispatch({
                                                            type: "LISTING_PREPARATION_FORM_CHANGE",
                                                            payload: form
                                                        })}
                                                    />]
                                                    : [],
                                                ...isDetailsSectionSelected("progression_mortgage_survey") && acceptedOfferIsMortgage()
                                                    ? [<CRMListingProgressionMortgageSurveyForm
                                                        listingForm={props.state.forms.listing_page.data.output.form}
                                                        onChange={(form) => props.dispatch({
                                                            type: "LISTING_PREPARATION_FORM_CHANGE",
                                                            payload: form
                                                        })}
                                                    />]
                                                    : [],
                                            ],
                                            array.intersperse(<CRMHorizontalSeperatorLine />),
                                        )}
                                    </SpacingColumn>
                                </CRMAutosaveIndicatorWrap>}

                            {/* Mortgage timeline */}
                            {hasOfferAccepted()
                                && acceptedOfferIsMortgage()
                                && (
                                    isDetailsSectionSelected("progression_mortgage_application_submitted")
                                    || isDetailsSectionSelected("progression_mortgage_offer_received")
                                )
                                && <CRMAutosaveIndicatorWrap status={props.state.forms.listing_page.data.output.form.status}>
                                    <SpacingColumn spacing={CRMSpacing.LARGE}>
                                        <CRMTitleSection>
                                            Mortgage timeline
                                        </CRMTitleSection>
                                        {...pipe(
                                            [
                                                ...isDetailsSectionSelected("progression_mortgage_application_submitted")
                                                    ? [<CRMListingProgressionMortgageApplicationSubmitted
                                                        listingForm={props.state.forms.listing_page.data.output.form}
                                                            onChange={(form) => props.dispatch({
                                                            type: "LISTING_PREPARATION_FORM_CHANGE",
                                                            payload: form
                                                        })}
                                                    />]
                                                    : [],
                                                ...isDetailsSectionSelected("progression_mortgage_offer_received")
                                                    ? [<CRMListingProgressionMortgageOfferReceived
                                                        listingForm={props.state.forms.listing_page.data.output.form}
                                                            onChange={(form) => props.dispatch({
                                                            type: "LISTING_PREPARATION_FORM_CHANGE",
                                                            payload: form
                                                        })}
                                                    />]
                                                    : [],
                                            ],
                                            array.intersperse(<CRMHorizontalSeperatorLine />),
                                        )}
                                    </SpacingColumn>
                                </CRMAutosaveIndicatorWrap>}

                            {/* Conveyancing timeline */}
                            {hasOfferAccepted()
                                && (
                                    isDetailsSectionSelected("progression_buyer_conveyancer_has_everything_required_to_start")
                                    || isDetailsSectionSelected("progression_buyer_conveyancer_received_contracts")
                                    || isDetailsSectionSelected("progression_buyer_conveyancer_raised_enquiries")
                                    || isDetailsSectionSelected("progression_all_enquiries_resolved")
                                    || isDetailsSectionSelected("progression_exchange_date")
                                    || isDetailsSectionSelected("progression_completed_date")
                                )
                                && <CRMAutosaveIndicatorWrap status={props.state.forms.listing_page.data.output.form.status}>
                                    <SpacingColumn spacing={CRMSpacing.LARGE}>
                                        <CRMTitleSection>
                                            Conveyancing timeline
                                        </CRMTitleSection>
                                        {...pipe(
                                            [
                                                ...isDetailsSectionSelected("progression_buyer_conveyancer_has_everything_required_to_start")
                                                    ? [<CRMListingProgressionBuyerConveyancerHasEverythingRequiredToStartForm
                                                        listingForm={props.state.forms.listing_page.data.output.form}
                                                        onChange={(form) => props.dispatch({
                                                            type: "LISTING_PREPARATION_FORM_CHANGE",
                                                            payload: form
                                                        })}
                                                    />]
                                                    : [],
                                                ...isDetailsSectionSelected("progression_buyer_conveyancer_received_contracts")
                                                    ? [<CRMListingProgressionBuyerConveyancerReceivedContractsForm
                                                        listingForm={props.state.forms.listing_page.data.output.form}
                                                        onChange={(form) => props.dispatch({
                                                            type: "LISTING_PREPARATION_FORM_CHANGE",
                                                            payload: form
                                                        })}
                                                    />]
                                                    : [],
                                                ...isDetailsSectionSelected("progression_buyer_conveyancer_raised_enquiries")
                                                    ? [<CRMListingProgressionBuyerConveyancerRaisedEnquiriesForm
                                                        listingForm={props.state.forms.listing_page.data.output.form}
                                                        onChange={(form) => props.dispatch({
                                                            type: "LISTING_PREPARATION_FORM_CHANGE",
                                                            payload: form
                                                        })}
                                                    />]
                                                    : [],
                                                ...isDetailsSectionSelected("progression_all_enquiries_resolved")
                                                    ? [<CRMListingProgressionAllEnquiriesResolvedForm
                                                        listingForm={props.state.forms.listing_page.data.output.form}
                                                        onChange={(form) => props.dispatch({
                                                            type: "LISTING_PREPARATION_FORM_CHANGE",
                                                            payload: form
                                                        })}
                                                    />]
                                                    : [],
                                                ...isDetailsSectionSelected("progression_exchange_date")
                                                    ? [<CRMListingProgressionExchangeDateSetForm
                                                        listingForm={props.state.forms.listing_page.data.output.form}
                                                        onChange={(form) => props.dispatch({
                                                            type: "LISTING_PREPARATION_FORM_CHANGE",
                                                            payload: form
                                                        })}
                                                    />]
                                                    : [],
                                                ...isDetailsSectionSelected("progression_completed_date")
                                                    ? [<CRMListingProgressionCompletedDateSetForm
                                                        listingForm={props.state.forms.listing_page.data.output.form}
                                                        onChange={(form) => props.dispatch({
                                                            type: "LISTING_PREPARATION_FORM_CHANGE",
                                                            payload: form
                                                        })}
                                                    />]
                                                    : [],
                                            ],
                                            array.intersperse(<CRMHorizontalSeperatorLine />),
                                        )}
                                    </SpacingColumn>
                                </CRMAutosaveIndicatorWrap>}

                            { isDetailsSectionSelected("progression_keys_released_to_buyer") && hasOfferAccepted()
                                && <CRMListingProgressionKeysReleasedToBuyerForm
                                    listingForm={props.state.forms.listing_page.data.output.form}
                                    onChange={(form) => props.dispatch({
                                        type: "LISTING_PREPARATION_FORM_CHANGE",
                                        payload: form
                                    })}
                                />
                            }
                        </SpacingColumn>
                    </CRMPadding>
                </CRMCardOutside>
            </SpacingColumn>
            
            {/* INFORMATION COLUMN */}
            <Sticky top="65px">
                <SpacingColumn spacing={CRMSpacing.LARGE}>
                    {/* Case overview */}
                    <CRMCardOutsideLabelled
                        shadow={false}
                        labelColor="highlights-instructional-brick-0"
                    >
                        <CRMListingCaseOverview
                            listingFull1={props.state.forms.listing_page.data.output.form.edited}
                            listing4={props.state.forms.listing_page.data.output.read_only}
                        />
                    </CRMCardOutsideLabelled>

                    {/* Introducer details */}
                    {(introducerRef() !== "" || introducerNotes() !== "") &&
                        <SpacingRow 
                            spacing={CRMSpacing.MEDIUM} 
                            childSize="1fr 1fr"
                            alignItems="flex-start"
                        >
                            <SpacingColumn spacing={CRMSpacing.MEDIUM}>
                                {/* Title */}
                                <FontSignikaNegative>
                                    <Text size={CRMFontSizes.LARGE}>
                                        <WeightBold>
                                            <Uppercase>
                                                Introducer Details
                                            </Uppercase>
                                        </WeightBold>
                                    </Text>
                                </FontSignikaNegative>

                                <CRMListingIntroducerRefNotesCard
                                    introducerRef={introducerRef()}
                                    introducerNotes={introducerNotes()}
                                    dateOfDeath={props.state.forms.listing_page.data.output.form.edited.date_of_death || undefined}
                                />
                            </SpacingColumn>
                        </SpacingRow>
                    }

                    <SpacingRow 
                        spacing={CRMSpacing.MEDIUM} 
                        childSize="1fr 1fr"
                        alignItems="flex-start"
                    >
                        {/* Buyers */}
                        <SpacingColumn spacing={CRMSpacing.MEDIUM}>
                            {/* Title */}
                            <FontSignikaNegative>
                                <Text size={CRMFontSizes.LARGE}>
                                    <WeightBold>
                                        <Uppercase>
                                            Buyers
                                        </Uppercase>
                                    </WeightBold>
                                </Text>
                            </FontSignikaNegative>

                            {/* Buyers solicitor */}
                            <CRMCardOutsideLabelled
                                labelColor="highlights-calming-pink-6"
                                shadow={false}
                            >
                                <SpacingColumn spacing={CRMSpacing.MEDIUM}>
                                    <SpacingColumn spacing={CRMSpacing.TINY}>
                                        <FontSignikaNegative>
                                            <Text size={CRMFontSizes.LARGE}>
                                                <WeightBold>
                                                    <Uppercase>
                                                        Buyer's solicitor
                                                    </Uppercase>
                                                </WeightBold>
                                            </Text>
                                        </FontSignikaNegative>
                                        <FontQuicksand>
                                            <Text size={CRMFontSizes.SMALL}>
                                                <WeightMedium>
                                                    {props.state.forms.listing_page.data.output.read_only.buyer_conveyancer_organisation_name}
                                                </WeightMedium>
                                            </Text>
                                        </FontQuicksand>
                                    </SpacingColumn>
                                    <SpacingColumn spacing={CRMSpacing.TINY}>
                                        <FontQuicksand>
                                            <Text size={CRMFontSizes.SMALL}>
                                                <WeightBold>
                                                    {props.state.forms.listing_page.data.output.read_only.buyer_conveyancer_name}
                                                </WeightBold>
                                            </Text>
                                        </FontQuicksand>
                                        <FontQuicksand>
                                            <Text size={CRMFontSizes.SMALL}>
                                                <WeightRegular>
                                                    {props.state.forms.listing_page.data.output.read_only.buyer_conveyancer_phone_number}
                                                </WeightRegular>
                                            </Text>
                                        </FontQuicksand>
                                        <FontQuicksand>
                                            <Text size={CRMFontSizes.SMALL}>
                                                <WeightRegular>
                                                    {props.state.forms.listing_page.data.output.read_only.buyer_conveyancer_email_address}
                                                </WeightRegular>
                                            </Text>
                                        </FontQuicksand>
                                    </SpacingColumn>
                                </SpacingColumn>
                            </CRMCardOutsideLabelled>

                            {/* Buyer's Mortgage broker */}
                            {props.state.forms.listing_page.data.output.read_only.buyer_payment_method === "mortgage"
                                && (
                                    props.state.forms.listing_page.data.output.read_only.mortgage_broker_name
                                    || props.state.forms.listing_page.data.output.read_only.mortgage_broker_email_address
                                    || props.state.forms.listing_page.data.output.read_only.mortgage_broker_phone_number
                                )
                                && <CRMCardOutsideLabelled
                                    labelColor="highlights-pertinent-yellow-2"
                                    shadow={false}
                                >
                                    <SpacingColumn spacing={CRMSpacing.TINY}>
                                        <FontSignikaNegative>
                                            <Text size={CRMFontSizes.LARGE}>
                                                <WeightBold>
                                                    <Uppercase>
                                                        Mortgage broker
                                                    </Uppercase>
                                                </WeightBold>
                                            </Text>
                                        </FontSignikaNegative>
                                        <FontQuicksand>
                                            <Text size={CRMFontSizes.SMALL}>
                                                <WeightBold>
                                                    {props.state.forms.listing_page.data.output.read_only.mortgage_broker_name || "Name not set"}
                                                </WeightBold>
                                            </Text>
                                        </FontQuicksand>
                                        <FontQuicksand>
                                            <Text size={CRMFontSizes.SMALL}>
                                                <WeightRegular>
                                                    {props.state.forms.listing_page.data.output.read_only.mortgage_broker_phone_number || "Phone number not set"}
                                                </WeightRegular>
                                            </Text>
                                        </FontQuicksand>
                                        <FontQuicksand>
                                            <Text size={CRMFontSizes.SMALL}>
                                                <WeightRegular>
                                                    {props.state.forms.listing_page.data.output.read_only.mortgage_broker_email_address || "Email not set"}
                                                </WeightRegular>
                                            </Text>
                                        </FontQuicksand>
                                    </SpacingColumn>
                                </CRMCardOutsideLabelled>
                            }

                            {/* Buyer Users */}
                            <SpacingColumn spacing={CRMSpacing.MEDIUM}>
                                {pipe(
                                    props.state.forms.listing_page.data.output.read_only.buyer_users,
                                    array.mapWithIndex((i, user) =>
                                        <CRMListingPageCasePeople
                                            key={i}
                                            user={user}
                                        />
                                    ),
                                )}
                            </SpacingColumn>

                        </SpacingColumn>

                        {/* Sellers */}
                        <SpacingColumn spacing={CRMSpacing.MEDIUM}>
                            {/* Title */}
                            <FontSignikaNegative>
                                <Text size={CRMFontSizes.LARGE}>
                                    <WeightBold>
                                        <Uppercase>
                                            Sellers
                                        </Uppercase>
                                    </WeightBold>
                                </Text>
                            </FontSignikaNegative>

                            {/* Sellers solicitor */}
                            <CRMCardOutsideLabelled
                                labelColor="highlights-calming-pink-6"
                                shadow={false}
                            >
                                <SpacingColumn spacing={CRMSpacing.MEDIUM}>
                                    <SpacingColumn spacing={CRMSpacing.TINY}>
                                        <FontSignikaNegative>
                                            <Text size={CRMFontSizes.LARGE}>
                                                <WeightBold>
                                                    <Uppercase>
                                                        Seller's solicitor
                                                    </Uppercase>
                                                </WeightBold>
                                            </Text>
                                        </FontSignikaNegative>
                                        <FontQuicksand>
                                            <Text size={CRMFontSizes.SMALL}>
                                                <WeightMedium>
                                                    {props.state.forms.listing_page.data.output.form.edited.seller_conveyancer_organisation_name}
                                                </WeightMedium>
                                            </Text>
                                        </FontQuicksand>
                                    </SpacingColumn>
                                    <SpacingColumn spacing={CRMSpacing.TINY}>
                                        <FontQuicksand>
                                            <Text size={CRMFontSizes.SMALL}>
                                                <WeightBold>
                                                    {props.state.forms.listing_page.data.output.form.edited.seller_conveyancer_name}
                                                </WeightBold>
                                            </Text>
                                        </FontQuicksand>
                                        <FontQuicksand>
                                            <Text size={CRMFontSizes.SMALL}>
                                                <WeightRegular>
                                                    {props.state.forms.listing_page.data.output.form.edited.seller_conveyancer_phone_number}
                                                </WeightRegular>
                                            </Text>
                                        </FontQuicksand>
                                        <FontQuicksand>
                                            <Text size={CRMFontSizes.SMALL}>
                                                <WeightRegular>
                                                    {props.state.forms.listing_page.data.output.form.edited.seller_conveyancer_email_address}
                                                </WeightRegular>
                                            </Text>
                                        </FontQuicksand>
                                    </SpacingColumn>
                                </SpacingColumn>
                            </CRMCardOutsideLabelled>

                            {/* Seller users*/}
                            <SpacingColumn spacing={CRMSpacing.MEDIUM}>
                                {props.state.forms.listing_page.data.output.form.edited.seller_company_name && 
                                    <CRMCardOutsideLabelled
                                        labelColor="highlights-calming-pink-6"
                                        shadow={false}
                                    >
                                        <SpacingColumn spacing={CRMSpacing.TINY}>
                                            <FontSignikaNegative>
                                                <Text size={CRMFontSizes.LARGE}>
                                                    <WeightBold>
                                                        <Uppercase>
                                                            Corporate Seller Name
                                                        </Uppercase>
                                                    </WeightBold>
                                                </Text>
                                            </FontSignikaNegative>
                                            <FontQuicksand>
                                                <Text size={CRMFontSizes.SMALL}>
                                                    <WeightMedium>
                                                        {props.state.forms.listing_page.data.output.form.edited.seller_company_name}
                                                    </WeightMedium>
                                                </Text>
                                            </FontQuicksand>
                                        </SpacingColumn>
                                    </CRMCardOutsideLabelled>
                                }
                                {pipe(
                                    props.state.forms.listing_page.data.output.read_only.seller_users,
                                    array.mapWithIndex((i, user) =>
                                        <CRMListingPageCasePeople
                                            key={i}
                                            user={user}
                                        />
                                    ),
                                )}
                            </SpacingColumn>
                        </SpacingColumn>

                    </SpacingRow>
                </SpacingColumn>
            </Sticky>
            
        </SpacingRow>
    );
};
