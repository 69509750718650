import React from "react";
import { TCalcSize, TPercentageSize, TPixelSize, TViewHeight } from "../../models/StringLiterals";

type TMaxWidthProps = {
    height: TPixelSize | TPercentageSize | TViewHeight | TCalcSize | "auto";
};

export const MinHeight = (props: React.PropsWithChildren<TMaxWidthProps>): JSX.Element => {

    return (
        <div style={{ minHeight: props.height, width: "100%" }}>
            {props.children}
        </div>
    );
};
