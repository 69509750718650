import { Observable, from, of } from "rxjs";
import { tap, switchMap, debounceTime, map } from "rxjs/operators";
import { formOperation } from "../../wrappers/formOperation";
import { TActionsDefinitionsList } from "./TAction";
import { action, routeAction } from "./actionFunctions";
import { runAutomaticUpdates } from "./automaticBlockUpdate";
import { TCreateCaseTitleRestrictionForm, TCaseTitleRestrictionForm, TCaseChargesAndTitleRestrictionsCaseForm, TCreateCaseChargeForm, TCaseChargeForm } from "../../../../domain/codecs/form/CaseChargesAndRestrictionForm";
import { TCaseChargeRedemptionCreateForm, TCaseChargeRedemptionForm, TCaseChargeRedemptionUploadForm } from "../../../../domain/codecs/form/CaseChargeRedemptionForm";
import { TStateLens } from "../../../../domain/codecs/state";
import { TCodecSetState, TGetCodecState } from "../applyActions";

const reloadChargesAndRestrictions$ = (lens: TStateLens, set: TCodecSetState, get: TGetCodecState) =>
    from(formOperation("GetCaseChargesAndRestrictions", lens.case_charges_and_restrictions_block.get()(get())))
        .pipe(
            tap((response) => set(lens.case_charges_and_restrictions_block.set(response))),
        );

export const actions: TActionsDefinitionsList = [
    routeAction("VIEW_CRM_LEGAL_CASE", (obs$, lens, set) => {
        obs$.pipe(
            tap((a) => set(lens.case_charges_and_restrictions_block.edited.id.set(a.params.caseId))),
        ).subscribe();
    }),
    action("LEGAL_CASE_CHARGES_AND_TITLE_RESTRICTIONS_BLOCK_VIEW", (obs$, lens, set, get) => {
        obs$.pipe(
            switchMap(() => reloadChargesAndRestrictions$(lens, set, get))
        ).subscribe();
    }),
    action("LEGAL_CASE_TITLE_RESTRICTION_CREATE", (obs$: Observable<TCreateCaseTitleRestrictionForm>, lens, set, get, routes, dispatch) => {
        obs$.pipe(
            tap((form) => set(lens.case_charges_and_restrictions_block.children.create_title_restriction.set({
                ...form,
                status: "submitting",
            }))),
            switchMap(() => formOperation("CreateCaseTitleRestriction", lens.case_charges_and_restrictions_block.children.create_title_restriction.get()(get()))),
            tap((response) => set(lens.case_charges_and_restrictions_block.children.create_title_restriction.set(response))),
            switchMap((response) =>
                response.status === "success"
                    ? reloadChargesAndRestrictions$(lens, set, get)
                    : of(undefined)
            ),
            tap(() => runAutomaticUpdates(routes, dispatch, get, "charges_and_restrictions"))
        ).subscribe();
    }),
    action("LEGAL_CASE_TITLE_RESTRICTION_UPDATE", (obs$: Observable<TCaseTitleRestrictionForm>, lens, set, get, routes, dispatch) => {
        obs$.pipe(
            tap((form) => set(lens.case_charges_and_restrictions_block.children.restrictions.where((f) => f.edited.id === form.edited.id).set({
                ...form,
                status: "requiresSubmission",
            }))),
            debounceTime(1000),
            tap((form) => set(lens.case_charges_and_restrictions_block.children.restrictions.where((f) => f.edited.id === form.edited.id).status.set("submitting"))),
            switchMap((form) => formOperation("UpdateCaseTitleRestriction", lens.case_charges_and_restrictions_block.children.restrictions.where((f) => f.edited.id === form.edited.id).get()(get()))),
            tap((response) => set(lens.case_charges_and_restrictions_block.children.restrictions.where((f) => f.edited.id === response.edited.id).set(response))),
            tap(() => runAutomaticUpdates(routes, dispatch, get, "charges_and_restrictions"))
        ).subscribe();
    }),
    action("LEGAL_CASE_TITLE_RESTRICTION_DELETE", (obs$: Observable<TCaseTitleRestrictionForm>, lens, set, get, routes, dispatch) => {
        obs$.pipe(
            tap((form) => set(lens.case_charges_and_restrictions_block.children.restrictions.where((f) => f.edited.id === form.edited.id).set({
                ...form,
                status: "submitting",
            }))),
            switchMap((form) => formOperation("DeleteCaseTitleRestriction", lens.case_charges_and_restrictions_block.children.restrictions.where((f) => f.edited.id === form.edited.id).get()(get()))),
            tap((response) => set(lens.case_charges_and_restrictions_block.children.restrictions.where((f) => f.edited.id === response.edited.id).set(response))),
            switchMap((response) =>
                response.status === "success"
                    ? reloadChargesAndRestrictions$(lens, set, get)
                    : of(undefined)
            ),
            tap(() => runAutomaticUpdates(routes, dispatch, get, "charges_and_restrictions"))
        ).subscribe();
    }),
    action("LEGAL_CASE_CHARGE_CREATE", (obs$: Observable<TCreateCaseChargeForm>, lens, set, get, routes, dispatch) => {
        obs$.pipe(
            tap((form) => set(lens.case_charges_and_restrictions_block.children.create_charge.set({
                ...form,
                status: "submitting",
            }))),
            switchMap(() => formOperation("CreateCaseCharge", lens.case_charges_and_restrictions_block.children.create_charge.get()(get()))),
            tap((response) => set(lens.case_charges_and_restrictions_block.children.create_charge.set(response))),
            switchMap((response) =>
                response.status === "success"
                    ? reloadChargesAndRestrictions$(lens, set, get)
                    : of(undefined)
            ),
            tap(() => runAutomaticUpdates(routes, dispatch, get, "charges_and_restrictions"))
        ).subscribe();
    }),
    action("LEGAL_CASE_CHARGE_UPDATE", (obs$: Observable<TCaseChargeForm>, lens, set, get, routes, dispatch) => {
        obs$.pipe(
            tap((form) => set(lens.case_charges_and_restrictions_block.children.charges.where((f) => f.edited.id === form.edited.id).set({
                ...form,
                status: "requiresSubmission",
            }))),
            debounceTime(1000),
            tap((form) => set(lens.case_charges_and_restrictions_block.children.charges.where((f) => f.edited.id === form.edited.id).status.set("submitting"))),
            switchMap((form) => formOperation("UpdateCaseCharge", lens.case_charges_and_restrictions_block.children.charges.where((f) => f.edited.id === form.edited.id).get()(get()))),
            tap((response) => set(lens.case_charges_and_restrictions_block.children.charges.where((f) => f.edited.id === response.edited.id).set(response))),
            tap(() => runAutomaticUpdates(routes, dispatch, get, "charges_and_restrictions"))
        ).subscribe();
    }),
    action("LEGAL_CASE_CHARGE_DELETE", (obs$: Observable<TCaseChargeForm>, lens, set, get, routes, dispatch) => {
        obs$.pipe(
            tap((form) => set(lens.case_charges_and_restrictions_block.children.charges.where((f) => f.edited.id === form.edited.id).set({
                ...form,
                status: "submitting",
            }))),
            switchMap((form) => formOperation("DeleteCaseCharge", lens.case_charges_and_restrictions_block.children.charges.where((f) => f.edited.id === form.edited.id).get()(get()))),
            tap((response) => set(lens.case_charges_and_restrictions_block.children.charges.where((f) => f.edited.id === response.edited.id).set(response))),
            switchMap((response) =>
                response.status === "success"
                    ? reloadChargesAndRestrictions$(lens, set, get)
                    : of(undefined)
            ),
            tap(() => runAutomaticUpdates(routes, dispatch, get, "charges_and_restrictions"))
        ).subscribe();
    }),
    action("LEGAL_CASE_CHARGE_NEW_REDEMPTION_STATEMENT_CHANGE", (obs$: Observable<TCaseChargeRedemptionCreateForm>, lens, set) => {
        obs$.pipe(
            tap((form) => set(lens.case_charges_and_restrictions_block.children.charges.where((f) => f.edited.id === form.edited.cases_charges_id).children.redemption_statement_create_form.set(form))),
        ).subscribe();
    }),
    action("LEGAL_CASE_CHARGE_NEW_REDEMPTION_STATEMENT_SUBMIT", (obs$: Observable<TCaseChargeRedemptionCreateForm>, lens, set, get, routes, dispatch) => {
        obs$.pipe(
            tap((form) => set(lens.case_charges_and_restrictions_block.children.charges.where((f) => f.edited.id === form.edited.cases_charges_id).children.redemption_statement_create_form.set({
                ...form,
                status: "submitting",
            }))),
            switchMap((form) => formOperation("CreateCaseChargeRedemption", lens.case_charges_and_restrictions_block.children.charges.where((f) => f.edited.id === form.edited.cases_charges_id).children.redemption_statement_create_form.get()(get()))),
            tap((response) => set(lens.case_charges_and_restrictions_block.children.charges.where((f) => f.edited.id === response.edited.cases_charges_id).children.redemption_statement_create_form.set(response))),
            switchMap((response) =>
                response.status === "success"
                    ? reloadChargesAndRestrictions$(lens, set, get)
                    : of(undefined)
            ),
            tap(() => runAutomaticUpdates(routes, dispatch, get, "charges_and_restrictions"))
        ).subscribe();
    }),
    action("LEGAL_CASE_CHARGE_REDEMPTION_STATEMENT_SUBMIT_UPLOAD", (obs$: Observable<TCaseChargeRedemptionUploadForm>, lens, set, get, routes, dispatch) => {
        obs$.pipe(
            tap((form) => set(lens.case_charges_and_restrictions_block.children.charges.where((f) => f.edited.id === form.input.cases_charges_id).children.redemption_statement_forms.where((f) => f.edited.id === form.input.id).children.upload_form.set({
                ...form,
                status: "submitting",
            }))),
            switchMap((form) => formOperation("UploadCaseChargeRedemption", lens.case_charges_and_restrictions_block.children.charges.where((f) => f.edited.id === form.input.cases_charges_id).children.redemption_statement_forms.where((f) => f.edited.id === form.input.id).children.upload_form.get()(get()))),
            tap((response) => set(lens.case_charges_and_restrictions_block.children.charges.where((f) => f.edited.id === response.input.cases_charges_id).children.redemption_statement_forms.where((f) => f.edited.id === response.input.id).children.upload_form.set(response))),
            switchMap((response) =>
                response.status === "success"
                    ? reloadChargesAndRestrictions$(lens, set, get)
                    : of(undefined)
            ),
            tap(() => runAutomaticUpdates(routes, dispatch, get, "charges_and_restrictions"))
        ).subscribe();
    }),
    action("LEGAL_CASE_CHARGE_NEW_REDEMPTION_STATEMENT_DELETE", (obs$: Observable<TCaseChargeRedemptionForm>, lens, set, get, routes, dispatch) => {
        obs$.pipe(
            tap((form) => set(lens.case_charges_and_restrictions_block.children.charges.where((f) => f.edited.id === form.edited.cases_charges_id).children.redemption_statement_forms.where((f) => f.edited.id === form.edited.id).status.set("submitting"))),
            switchMap((form) => formOperation("DeleteCaseChargeRedemption", lens.case_charges_and_restrictions_block.children.charges.where((f) => f.edited.id === form.edited.cases_charges_id).children.redemption_statement_forms.where((f) => f.edited.id === form.edited.id).get()(get()))),
            tap((response) => set(lens.case_charges_and_restrictions_block.children.charges.where((f) => f.edited.id === response.edited.cases_charges_id).children.redemption_statement_forms.where((f) => f.edited.id === response.edited.id).set(response))),
            switchMap((response) =>
                response.status === "success"
                    ? reloadChargesAndRestrictions$(lens, set, get)
                    : of(undefined)
            ),
            tap(() => runAutomaticUpdates(routes, dispatch, get, "charges_and_restrictions"))
        ).subscribe();
    }),
    action("LEGAL_CASE_CHARGES_AND_TITLE_RESTRICTION_UPDATE_CASE", (obs$: Observable<TCaseChargesAndTitleRestrictionsCaseForm>, lens, set, get, routes, dispatch) => {
        obs$.pipe(
            map((form): TCaseChargesAndTitleRestrictionsCaseForm => ({
                ...form,
                status: "submitting",
            })),
            tap((form) => set(lens.case_charges_and_restrictions_block.children.case_form.set(form))),
            switchMap((form) => formOperation("UpdateCaseChargesAndTitleRestrictionsCase", form)),
            switchMap((response) =>
                response.status === "success"
                    ? reloadChargesAndRestrictions$(lens, set, get)
                    : of(undefined)
            ),
            tap(() => runAutomaticUpdates(routes, dispatch, get, "charges_and_restrictions"))
        ).subscribe();
    }),
];
