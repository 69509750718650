import React, { useState } from "react";
import { TContainerStateProps } from "../../state/TContainerStateProps";
import { CRMPageWrap } from "./../../components/Simple/CRMPageWrap/CRMPageWrap";
import { CRMPageBody } from "./../../components/Simple/CRMPageBody/CRMPageBody";
import { CRMLegalMenuBarContainer } from "./../../containers/CRMLegalMenuBarContainer/CRMLegalMenuBarContainer";
import { CRMPadding } from "../../components/Simple/CRMPadding/CRMPadding";
import { CRMSpacingRow } from "../../components/CRMSpacingRow/CRMSpacingRow";
import { SimpleGrid } from "../../components/BuildingBlocks/SimpleGrid";
import { Sticky } from "../../components/BuildingBlocks/Sticky";
import { CRMSpacing } from "../../models/CRMSpacing";
import { CRMCardOutside } from "../../components/CRMCardOutside/CRMCardOutside";
import { CRMSpacingColumn } from "../../components/CRMSpacingColumn/CRMSpacingColumn";
import { CRMParagraph } from "../../components/Simple/CRMParagraph/CRMParagraph";
import { CRMParagraphBulletPoints } from "../../components/CRMBulletList/CRMParagraphBulletPoints";
import { WeightBold } from "../../components/WeightBold/WeightBold";
import { CRMIcon } from "../../components/CRMIcon/CRMIcon";
import CRMInputLabelAndErrorWrapComponent from "../../components/CRMInputLabelAndErrorWrapComponent/CRMInputLabelAndErrorWrapComponent";
import { CRMDropdownComponent } from "../../components/CRMDropdownComponent/CRMDropdownComponent";
import { FontQuicksand } from "../../components/BuildingBlocks/FontQuicksand";
import { FontSize } from "../../components/BuildingBlocks/FontSize";
import { CRMFontSizes } from "../../models/CRMFontSizes";
import { pipe } from "fp-ts/lib/function";
import { array, record } from "fp-ts";
import { DateTime } from "luxon";
import { LegalMIReportType, TLegalMIReportType, getReportTitleAndDescription } from "../../../../domain/codecs/LegalMIReportType";
import { CRMReportDetailsCard } from "../../components/CRMReportDetailsCard/CRMReportDetailsCard";

export const CRMLegalReportsPage = (props: TContainerStateProps): JSX.Element => {
    const now = new Date();
    const startYear = 2020;
    const currentYear = now.getUTCFullYear();
    const currentMonth = now.getUTCMonth() + 1;
    const yearsSinceStart = currentYear - startYear;
    // There might be a better way to do this, feel free to replace if found - TFC
    const months = [
        ...yearsSinceStart > 0 ? pipe(
            array.range(1, yearsSinceStart),
            array.reduce([], (a: string[], i: number) => [
                ...a,
                ...pipe(
                    array.range(1, 12),
                    array.map((r) => `${startYear + i-1}-${r < 10 ? "0" : ""}${r}`)
                )    
            ])
        ) : [],
        ...pipe(
            array.range(1, currentMonth),
            array.map((r) => `${currentYear}-${r < 10 ? "0" : ""}${r}`)
        ),
    ].reverse();

    const [currentSelectedMonth, setCurrentSelectedMonth] = useState<string>(months[0]);

    LegalMIReportType.values
    type ReportDetails = {
        type: TLegalMIReportType;
        title: string;
        description: string;
        helpText: string | null;
    };

    const reportsByLetter = pipe(
        LegalMIReportType.values,
        array.map((reportType): ReportDetails => {
            const [title, description, helpText] = getReportTitleAndDescription(reportType);
            return {type: reportType, title, description, helpText};
        }),
        array.reduce({}, (obj: Record<string, Array<ReportDetails>>, reportDetails: ReportDetails) => {
            const firstLetterOfTitle = reportDetails.title.toLowerCase().charAt(0);
            obj[firstLetterOfTitle] = [ ...(Object.prototype.hasOwnProperty.call(obj, firstLetterOfTitle) ? obj[firstLetterOfTitle] : []) , reportDetails].sort((a, b) => a.title.localeCompare(b.title));
            return obj;
        }),
    );

    return (
        <div className="crm-legal-cases">
            <CRMPageWrap>
                {/* HEADER */}
                <CRMLegalMenuBarContainer {...props} />

                {/* BODY */}
                <CRMPageBody>
                    <CRMPadding size="large">
                        <CRMSpacingColumn spacing="large">
                            {/* PAGE TITLE  */}
                            <FontQuicksand>
                                <FontSize size={CRMFontSizes.XX_LARGE}>
                                    <WeightBold>
                                        Download reports for: {DateTime.fromFormat(currentSelectedMonth, "yyyy-MM").toFormat("LLLL yyyy")}
                                    </WeightBold>
                                </FontSize>
                            </FontQuicksand>
                            {/*  */}
                            <CRMSpacingRow
                                spacing="x-large"
                                alignItems="flex-start"
                                childSize="2fr 1fr"
                            >
                                <CRMSpacingColumn spacing="large">
                                    {
                                        pipe(
                                            reportsByLetter,
                                            record.keys,
                                            (keys) => keys.sort(),
                                            array.map((key) => {
                                                return (
                                                    <React.Fragment key={key}>
                                                    <FontQuicksand>
                                                        <FontSize size={CRMFontSizes.X_LARGE}>
                                                            <WeightBold>
                                                                {key.toUpperCase()}
                                                            </WeightBold>
                                                        </FontSize>
                                                    </FontQuicksand>
                                                    <SimpleGrid templateColumns="1fr 1fr" columnSpacing={CRMSpacing.MEDIUM} rowSpacing={CRMSpacing.MEDIUM}>
                                                        {
                                                            pipe(
                                                                reportsByLetter[key],
                                                                array.mapWithIndex((i, reportDetails) => (
                                                                <CRMReportDetailsCard
                                                                    key={i}
                                                                    title={reportDetails.title}
                                                                    description={reportDetails.description}
                                                                    helpText={reportDetails.helpText}
                                                                    link={`${env.REACT_APP_API_URL}/web/legal/reports/${reportDetails.type}?month=${currentSelectedMonth}` }
                                                                />))
                                                            )
                                                        }
                                                        
                                                    </SimpleGrid>
                                                    </React.Fragment>
                                                )
                                            })
                                        )
                                    }
                                </CRMSpacingColumn>
                                <Sticky top={CRMSpacing.X_LARGE}>
                                    <CRMCardOutside borderRounding="right" shadow={true}>
                                        <CRMPadding size="large">
                                            <CRMSpacingColumn spacing="medium">
                                                <CRMParagraph>
                                                    <CRMParagraphBulletPoints
                                                        bullets={[
                                                            <span>Select the <WeightBold>month</WeightBold> you wish to see reports for below.</span>,
                                                            <span>Browse all reports to the left. They are <WeightBold>alphabetically ordered</WeightBold>.</span>,
                                                            <span>Click the <CRMIcon iconName="can-download" colour="neutral-ink" size="tiny" inline={true}/> button on the ones you want.</span>,
                                                        ]}
                                                    />
                                                
                                                </CRMParagraph>
                                                <CRMInputLabelAndErrorWrapComponent label="Report Month">
                                                    <CRMDropdownComponent
                                                        value={currentSelectedMonth}
                                                        options={months.map((month) => ({
                                                            value: month,
                                                            label: DateTime.fromFormat(month, "yyyy-MM").toFormat("LLLL yyyy"),
                                                        }))}
                                                        onChange={setCurrentSelectedMonth}
                                                        displayError={false}
                                                    />
                                                </CRMInputLabelAndErrorWrapComponent>
                                            </CRMSpacingColumn>
                                        </CRMPadding>
                                    </CRMCardOutside>
                                </Sticky>
                            </CRMSpacingRow>
                        </CRMSpacingColumn>
                    </CRMPadding>
                </CRMPageBody>
            </CRMPageWrap>
        </div>
    );
}
