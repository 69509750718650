import { option } from "fp-ts";
import React, { useState } from "react";
import { TCasesBankAccountVerificationStatus1 } from "../../../../../../domain/codecs/CasesBankAccountVerificationStatus";
import { TClientCaseIdentityVerificationUserForm, TClientCaseIdentityVerificationUserProofOfAddressForm } from "../../../../../../domain/codecs/form/ClientCaseForm";
import { sailLegalBrandConstants } from "../../../../../../domain/constants";
import { FrontColors } from "../../../../models/FrontColors";
import { FrontSpacing } from "../../../../models/FrontSpacing";
import { MaxWidth } from "../../../BuildingBlocks/MaxWidth";
import { MediaCompSwitch } from "../../../BuildingBlocks/MediaCompSwitch";
import { SpacingColumn } from "../../../BuildingBlocks/SpacingColumn";
import { SpacingRow } from "../../../BuildingBlocks/SpacingRow";
import { UploadButtonWrapper } from "../../../BuildingBlocks/UploadButtonWrapper";
import { WeightBold } from "../../../WeightBold/WeightBold";
import { FrontBulletpoint } from "../../Simple/FrontBulletpoint/FrontBulletpoint";
import { FrontButtonErrorSwitch } from "../../Simple/FrontButtonErrorSwitch/FrontButtonErrorSwitch";
import { FrontFormPrimaryButton } from "../../Simple/FrontFormPrimaryButton/FrontFormPrimaryButton";
import { TIcon } from "../../Simple/FrontIcon/FrontIcon";
import { FrontInfoBubble } from "../../Simple/FrontInfoBubble/FrontInfoBubble";
import FrontParagraphSmall from "../../Simple/FrontParagraphSmall/FrontParagraphSmall";
import { FrontTextLink } from "../../Simple/FrontTextLink/FrontTextLink";
import { FrontVerificationIcon } from "../../Simple/FrontVerificationIcon/FrontVerificationIcon";
import { FrontVerifiedArticle } from "../FrontVerifiedArticle/FrontVerifiedArticle";
import { FrontIdentityVerificationPopup } from "./../FrontIdentityVerificationPopup/FrontIdentityVerificationPopup"

type TFrontUserIdentityVerificationSummaryProps = {
    user: TClientCaseIdentityVerificationUserForm;
    onChange: (user: TClientCaseIdentityVerificationUserForm) => void;
    onAutoCompleteAddress: (user: TClientCaseIdentityVerificationUserForm) => void;
    onSubmit: (user: TClientCaseIdentityVerificationUserForm) => void;
    onBeginPhotoVerification: (user: TClientCaseIdentityVerificationUserForm) => void;
    onProofOfAddressDocumentUpload: (form: TClientCaseIdentityVerificationUserProofOfAddressForm) => void;
};

export const FrontUserIdentityVerificationSummary = (props: React.PropsWithChildren<TFrontUserIdentityVerificationSummaryProps>): JSX.Element => {
    const [isIDVerificationOpen, setIsIDVerificationOpen] = useState(false);

    const toggleIDVerificationPopup = () => setIsIDVerificationOpen(!isIDVerificationOpen);

    const convertVerificationStatusToVerificationBadgeStatus = (): TCasesBankAccountVerificationStatus1 => {
        switch (props.user.children.user14.identity_verification_status) {
            case "not_complete":
            case "expired":
            case "failed":
            case "needs_address_document":
                return "rejected";
            case "complete":
                return "approved";
            case "pending":
                return "pending";
        }
    }

    const convertEmailToASeperatedString = () => {
        if (props.user.children.user14.email) {
            const splitEmail = props.user.children.user14.email.split("@");
            return `${splitEmail[0]} @${splitEmail[1] || ""}`;
        }
        return "";
    }

    return (
        <div>
            <SpacingColumn spacing={FrontSpacing.MEDIUM_2}>
                {props.user.children.user14.phone_numbers.map((phoneNumber, key) => (
                    <FrontVerifiedArticle
                        key={key}
                        label="Phone number"
                        value={phoneNumber.phone_number || ""}
                        status={phoneNumber.is_verified ? "approved" : "rejected"}
                        explanation={
                            <span>
                                This number has <WeightBold>not been verified.</WeightBold> Please call us on {sailLegalBrandConstants().legalCSNumber} to verify it.
                            </span>
                        }
                    />
                ))}

                {props.user.children.user14.email &&
                    <FrontVerifiedArticle
                        label="Email"
                        value={convertEmailToASeperatedString()}
                        status={props.user.children.user14.email_is_verified ? "approved" : "rejected"}
                        explanation={
                            <span>
                                This email has <WeightBold>not been verified.</WeightBold> The recipient should check their inbox for a verification email,
                                and open the link within to <WeightBold>complete verification.</WeightBold>
                            </span>
                        }
                    />
                }
                
                {/* ID & ADDRESS VERIFICATION */}
                <SpacingColumn spacing={FrontSpacing.SMALL_1}>
                    <SpacingRow spacing={FrontSpacing.SMALL_2}>
                        <FrontVerificationIcon 
                            status={convertVerificationStatusToVerificationBadgeStatus()}
                        />
                        <FrontParagraphSmall>
                            <WeightBold>Your ID & proof of address</WeightBold>
                        </FrontParagraphSmall>
                    </SpacingRow>

                    {props.user.children.user14.identity_verification_status === "not_complete" &&
                        <SpacingColumn spacing={FrontSpacing.SMALL_2}>
                            <MaxWidth width="500px">
                                <FrontInfoBubble color={FrontColors.ERROR_FIREBRICK_22}>
                                    We need to <WeightBold>verify your identity</WeightBold>. This is a <WeightBold>quick</WeightBold> and essential process that allows us to <WeightBold>protect you</WeightBold> from impersonation and without which we can not represent you.
                                </FrontInfoBubble>
                            </MaxWidth>
                            
                            <MaxWidth width="500px">
                                <FrontInfoBubble color={FrontColors.PRIMARY_22}>
                                    <SpacingColumn spacing={FrontSpacing.SMALL_1}>
                                        <span>Before you begin make sure you have at hand:</span>
                                        <FrontBulletpoint>
                                            A <WeightBold>proof of identity (driver's license, passport).</WeightBold> 
                                        </FrontBulletpoint>
                                        <FrontBulletpoint>
                                            Your <WeightBold>current address.</WeightBold>
                                        </FrontBulletpoint>
                                    </SpacingColumn>
                                </FrontInfoBubble>
                            </MaxWidth>
                            
                            <MaxWidth width="500px">
                                <FrontInfoBubble color={FrontColors.PRIMARY_22}>
                                    Please, <FrontTextLink onClick={toggleIDVerificationPopup}>click here</FrontTextLink> to begin your verification process.
                                </FrontInfoBubble>
                            </MaxWidth>
                        </SpacingColumn>
                    }

                    {props.user.children.user14.identity_verification_status === "failed" &&
                        <SpacingColumn spacing={FrontSpacing.SMALL_2}>
                            <MaxWidth width="500px">
                                <FrontInfoBubble color={FrontColors.ERROR_FIREBRICK_22}>
                                    <SpacingColumn spacing={FrontSpacing.SMALL_1}>
                                        <div>
                                            <WeightBold>Sorry, we need you to try that again!</WeightBold>
                                        </div>
                                        <div>
                                            We weren't able to verify your identity on your last attempt (we've emailed you the reason). Please try again below.
                                        </div>
                                    </SpacingColumn>
                                </FrontInfoBubble>
                            </MaxWidth>
                            
                            <MaxWidth width="500px">
                                <FrontInfoBubble color={FrontColors.PRIMARY_22}>
                                    <SpacingColumn spacing={FrontSpacing.SMALL_1}>
                                        <span>Before you begin make sure you have at hand:</span>
                                        <FrontBulletpoint>
                                            A <WeightBold>proof of identity (driver's license, passport).</WeightBold> 
                                        </FrontBulletpoint>
                                        <FrontBulletpoint>
                                            Your <WeightBold>current address.</WeightBold>
                                        </FrontBulletpoint>
                                    </SpacingColumn>
                                </FrontInfoBubble>
                            </MaxWidth>
                            
                            <MaxWidth width="500px">
                                <FrontInfoBubble color={FrontColors.PRIMARY_22}>
                                    Please, <FrontTextLink onClick={toggleIDVerificationPopup}>click here</FrontTextLink> to begin your verification process.
                                </FrontInfoBubble>
                            </MaxWidth>
                        </SpacingColumn>
                    }

                    {props.user.children.user14.identity_verification_status === "expired" &&
                        <SpacingColumn spacing={FrontSpacing.SMALL_2}>
                            <MaxWidth width="500px">
                                <FrontInfoBubble color={FrontColors.ERROR_FIREBRICK_22}>
                                    <SpacingColumn spacing={FrontSpacing.SMALL_1}>
                                        <div>
                                            <WeightBold>Your last ID check has expired, we need you to do another one.</WeightBold>
                                        </div>
                                        <div>
                                            Each ID check you do with us has an expiry date, after then we need to ask you to take another one so that we can check none of your circumstances have changed.
                                        </div>
                                    </SpacingColumn>
                                </FrontInfoBubble>
                            </MaxWidth>
                            
                            <MaxWidth width="500px">
                                <FrontInfoBubble color={FrontColors.PRIMARY_22}>
                                    <SpacingColumn spacing={FrontSpacing.SMALL_1}>
                                        <span>Before you begin make sure you have at hand:</span>
                                        <FrontBulletpoint>
                                            A <WeightBold>proof of identity (driver's license, passport).</WeightBold> 
                                        </FrontBulletpoint>
                                        <FrontBulletpoint>
                                            Your <WeightBold>current address.</WeightBold>
                                        </FrontBulletpoint>
                                    </SpacingColumn>
                                </FrontInfoBubble>
                            </MaxWidth>
                            
                            <MaxWidth width="500px">
                                <FrontInfoBubble color={FrontColors.PRIMARY_22}>
                                    Please, <FrontTextLink onClick={toggleIDVerificationPopup}>click here</FrontTextLink> to begin your verification process.
                                </FrontInfoBubble>
                            </MaxWidth>
                        </SpacingColumn>
                    }

                    {/* UPLOAD PROOF OF ADDRESS DOCUMENT */}
                    {props.user.children.user14.identity_verification_status === "needs_address_document" &&
                        <MaxWidth width="500px">
                            <SpacingColumn spacing={FrontSpacing.MEDIUM_1}>
                                <FrontInfoBubble color={FrontColors.ERROR_FIREBRICK_22}>
                                    <SpacingColumn spacing={FrontSpacing.SMALL_1}>
                                        <div>
                                            <WeightBold>We need you to send us a document with your address on it.</WeightBold>
                                        </div>
                                        <div>
                                            Please upload one of the following:
                                            <ul>
                                                <li>A photo of a utility bill / phone bill or bank statement you've received within the last 3 months.</li>
                                                <li>Your most recent council tax bill.</li>
                                            </ul>
                                        </div>
                                    </SpacingColumn>
                                </FrontInfoBubble>

                                {/* UPLOAD BUTTON */}
                                <FrontButtonErrorSwitch
                                    formStatus={props.user.children.upload_proof_of_address_form.status}
                                    validationErrors={props.user.children.upload_proof_of_address_form.validationErrors}
                                    buttonComponent={<MediaCompSwitch
                                        DesktopComponent={SpacingRow}
                                        desktopProps={{ spacing: FrontSpacing.SMALL_3}}
                                        MobileComponent={SpacingColumn}
                                        mobileProps={{ spacing: FrontSpacing.SMALL_3 }}
                                        breakpoint="600"
                                    >
                                        <UploadButtonWrapper
                                            buttonComponent={FrontFormPrimaryButton}
                                            buttonProps={{
                                                label: props.user.children.upload_proof_of_address_form.status === "submitting" ? "Just a moment..."
                                                    : props.user.children.upload_proof_of_address_form.status === "validationError" ? "Try again"
                                                    : "Upload document",
                                                icon: "paperclip" as TIcon,
                                                isDisabled: props.user.children.upload_proof_of_address_form.status === "submitting",
                                            }}
                                            onUpload={(file) => {
                                                const indexOfLastFullStopInFileName = file.name.lastIndexOf(".");
                                                props.onProofOfAddressDocumentUpload(
                                                    file.size <= 1024 * 1024 * 15
                                                        ? {
                                                            ...props.user.children.upload_proof_of_address_form,
                                                            status: "submitting",
                                                            input: {
                                                                ...props.user.children.upload_proof_of_address_form.input,
                                                                name: indexOfLastFullStopInFileName >= 0 ? file.name.slice(0, indexOfLastFullStopInFileName) : file.name,
                                                                file_extension: indexOfLastFullStopInFileName >= 0 ? file.name.slice(indexOfLastFullStopInFileName + 1) : "",
                                                                mime_type: file.type === "" ? "text/plain" : file.type, 
                                                            },
                                                            clientSideFileForUpload: file,
                                                        }
                                                        : {
                                                            ...props.user.children.upload_proof_of_address_form,
                                                            status: "validationError",
                                                            validationErrors: [["FileTooLarge", ""]],
                                                        }
                                                );
                                            }}
                                        />
                                    </MediaCompSwitch>}
                                    errorHeaderText="Upload document"
                                    errorMap={(errorCodes) =>
                                        errorCodes.includes("InvalidFileFormat") ? option.some(
                                            <SpacingColumn spacing={FrontSpacing.SMALL_3}>
                                                <FrontParagraphSmall>
                                                    <WeightBold>
                                                        We can't accept that kind of file.
                                                    </WeightBold>
    
                                                    <div>
                                                        We can only accept JPG/PNG image files or PDF documents. Try saving the file in one of these formats or get in touch if you need help.
                                                    </div>
                                                </FrontParagraphSmall>
                                            </SpacingColumn>
                                        )
                                        : errorCodes.includes("FileTooLarge") ? option.some(
                                            <SpacingColumn spacing={FrontSpacing.SMALL_3}>
                                                <FrontParagraphSmall>
                                                    <WeightBold>
                                                        That file is too big.
                                                    </WeightBold>
    
                                                    <div>
                                                        We can only accept files under 15Mb, you could try compressing the file or reducing the quality slightly. If you need help just get in touch.
                                                    </div>
                                                </FrontParagraphSmall>
                                            </SpacingColumn>
                                        )
                                        : option.none
                                    }
                                />
                            </SpacingColumn>
                        </MaxWidth>
                    }

                    {props.user.children.user14.identity_verification_status === "pending" &&
                        <MaxWidth width="500px">
                            <FrontInfoBubble color={FrontColors.PERTINENT_CORAL_22}>
                                Your identity verification is <WeightBold>in progress</WeightBold>, please check back here <WeightBold>soon</WeightBold> to see its outcome.
                            </FrontInfoBubble>
                        </MaxWidth>
                    }
                    
                    {props.user.children.user14.identity_verification_status === "complete" &&
                        <MaxWidth width="500px">
                            <FrontInfoBubble color={FrontColors.POSITIVE_APPLE_22}>
                                We've <WeightBold>completed</WeightBold> your identity verification, if we require anything else we will let you know.
                            </FrontInfoBubble>
                        </MaxWidth>
                    }
                </SpacingColumn>
            </SpacingColumn>

            <FrontIdentityVerificationPopup
                isOpen={isIDVerificationOpen}
                onClose={toggleIDVerificationPopup}
                form={props.user}
                onChange={props.onChange}
                onAutoCompleteAddress={props.onAutoCompleteAddress}
                onSubmit={props.onSubmit}
                onBeginPhotoVerification={props.onBeginPhotoVerification}
            />
        </div>
    );
};
