import { forkJoin, Observable, timer, of, from } from "rxjs";
import { tap, mergeMap, flatMap, switchMap, map, debounce, concatMap, debounceTime, filter } from "rxjs/operators";
import { formOperation } from "../../wrappers/formOperation";
import { doFormValuesMatch } from "../../../../shared/src/codecs/types/form";
import { TBankDetailsForm } from "../../../../domain/codecs/form/BankDetailsForm";
import { TClientCaseCompanyForm, TClientCaseIdentityVerificationUserForm, TClientCaseIdentityVerificationUserProofOfAddressForm, TClientCasePurchasePaymentDetailsForm, TClientCaseRelatedTransaction, TClientCaseRelatedTransactionAddressForm, TClientCaseRelatedTransactionQuestionForm, TClientCaseRemortgageMortgageDetailsForm, TClientCaseSdltForm, TClientCaseChargeForm, TClientCaseUserForm, TClientCaseVideoVerificationCallCreateForm, TClientCaseJointOwnershipHeldAsForm, TClientCaseDeclarationOfTrustReferralForm, TClientCaseWillReferralForm } from "../../../../domain/codecs/form/ClientCaseForm";
import { TActionsDefinitionsList } from "./TAction";
import { action, routeAction } from "./actionFunctions";
import { pipe } from "fp-ts/lib/function";
import { array, record } from "fp-ts";

export const actions: TActionsDefinitionsList = [
    routeAction("VIEW_CLIENT_CASE", (obs$, lens, set, get) => {
        obs$.pipe(
            tap((a) => set(lens.client_case_page.edited.case_id.set(a.params.caseId))),
            mergeMap(() => formOperation("GetClientCase", lens.client_case_page.get()(get()))),
            tap((response) => set(lens.client_case_page.set(response)))
        ).subscribe();
    }),
    action("CLIENT_ADD_BANK_ACCOUNT", (obs$: Observable<null>, lens, setState, getState) => {
        obs$.pipe(
            tap(() => setState(lens.client_case_page.children.bank_accounts.create_account.status.set("submitting"))),
            flatMap(() => formOperation("CreateClientCaseBankAccount", lens.client_case_page.children.bank_accounts.create_account.get()(getState()))),
            tap((response) => setState(lens.client_case_page.children.bank_accounts.create_account.set(response))),
            flatMap(() => formOperation("GetClientCase", lens.client_case_page.get()(getState()))),
            tap((response) => setState(lens.client_case_page.set(response))),
        ).subscribe();
    }),
    action("CLIENT_UPDATE_BANK_DETAILS", (obs$: Observable<TBankDetailsForm>, lens, setState, getState) => {
        obs$.pipe(
            tap((form) => setState(lens.client_case_page.children.bank_accounts.accounts.where(doFormValuesMatch(form, "id")).set(form))),
            debounceTime(1000),
            tap((form) => setState(lens.client_case_page.children.bank_accounts.accounts.where(doFormValuesMatch(form, "id")).status.set("submitting"))),
            switchMap((form) => formOperation("UpdateClientCaseBankAccount", lens.client_case_page.children.bank_accounts.accounts.where(doFormValuesMatch(form, "id")).get()(getState()))),
            tap((form) => setState(lens.client_case_page.children.bank_accounts.accounts.where(doFormValuesMatch(form, "id")).set({
                ...form,
                edited: lens.client_case_page.children.bank_accounts.accounts.where(doFormValuesMatch(form, "id")).edited.get()(getState()),
            }))),
        ).subscribe();
    }),
    action("CLIENT_DELETE_BANK_DETAILS", (obs$: Observable<TBankDetailsForm>, lens, setState, getState) => {
        obs$.pipe(
            tap((form) => setState(lens.client_case_page.children.bank_accounts.accounts.where(doFormValuesMatch(form, "id")).status.set("submitting"))),
            flatMap((form) => formOperation("DeleteClientCaseBankAccount", lens.client_case_page.children.bank_accounts.accounts.where(doFormValuesMatch(form, "id")).get()(getState()))),
            flatMap(() => formOperation("GetClientCase", lens.client_case_page.get()(getState()))),
            tap((response) => setState(lens.client_case_page.set(response))),
        ).subscribe();
    }),
    action("CLIENT_SUBMIT_BANK_DETAILS_FOR_VERIFICATION", (obs$: Observable<null>, lens, setState, getState) => {
        obs$.pipe(
            tap(() => setState(lens.client_case_page.status.set("submitting"))),
            flatMap(() => formOperation("SubmitClientCaseBankAccountsForVerification", lens.client_case_page.children.bank_accounts.submit_for_verification.get()(getState()))),
            flatMap(() => formOperation("GetClientCase", lens.client_case_page.get()(getState()))),
            tap((response) => setState(lens.client_case_page.set(response))),
        ).subscribe();
    }),
    action("CLIENT_UPDATE_ID_VERIFICATION_DETAILS", (obs$: Observable<TClientCaseIdentityVerificationUserForm>, lens, setState) => {
        obs$.pipe(
            tap((form) => setState(lens.client_case_page.children.identity_verification.users.where(doFormValuesMatch(form, "users__cases_id")).set(form))),
        ).subscribe();
    }),
    action("CLIENT_AUTOCOMPLETE_ADDRESS", (obs$: Observable<TClientCaseIdentityVerificationUserForm>, lens, setState) => {
        obs$.pipe(
            tap((form) => setState(lens.client_case_page.children.identity_verification.users.where(doFormValuesMatch(form, "users__cases_id")).status.set("submitting"))),
            switchMap((form) => formOperation("AutocompleteClientVerificationAddress", form)),
            tap((updatedForm) => setState(lens.client_case_page.children.identity_verification.users.where(doFormValuesMatch(updatedForm, "users__cases_id")).set(updatedForm))),
        ).subscribe();
    }),
    action("CLIENT_BEGIN_STRIPE_VERIFICATION", (obs$: Observable<TClientCaseIdentityVerificationUserForm>, lens, setState) => {
        obs$.pipe(
            tap((form) => setState(lens.client_case_page.children.identity_verification.users.where(doFormValuesMatch(form, "users__cases_id")).children.create_id_verification_link.status.set("submitting"))),
            switchMap((form) => formOperation("CreateStripeIdVerificationLink", form.children.create_id_verification_link)),
            tap((response) => {
                if (response.status === "success") {
                    window.location.href = response.children.redirect_url;
                }
                setState(lens.client_case_page.children.identity_verification.users
                    .where((form) => form.original.users__cases_id === response.original.users__cases_id)
                    .children.create_id_verification_link.status.set(response.status))
                ;
            })
        ).subscribe();
    }),
    action("CLIENT_SUBMIT_ID_VERIFICATION_DETAILS", (obs$: Observable<TClientCaseIdentityVerificationUserForm>, lens, setState) => {
        obs$.pipe(
            tap((form) => setState(lens.client_case_page.children.identity_verification.users.where(doFormValuesMatch(form, "users__cases_id")).status.set("submitting"))),
            flatMap((form) => formOperation("UpdateClientCaseUserIdCheck", form)),
            tap((response) => setState(lens.client_case_page.children.identity_verification.users.where(doFormValuesMatch(response, "users__cases_id")).set(response))),
        ).subscribe();
    }),
    action("CLIENT_CONFIRM_DEPOSIT_TRANSFER", (obs$: Observable<null>, lens, setState, getState) => {
        obs$.pipe(
            tap(() => setState(lens.client_case_page.children.payment.user_says_paid.status.set("submitting"))),
            map(() => lens.client_case_page.children.payment.user_says_paid.get()(getState())),            
            flatMap((form) => formOperation("UpdateClientInitialPaymentUserSaysPaid", form)),
            tap((response) => setState(lens.client_case_page.children.payment.user_says_paid.set(response))),
            mergeMap(() => formOperation("GetClientCase", lens.client_case_page.get()(getState()))),
            tap((response) => setState(lens.client_case_page.set(response))),
        ).subscribe();
    }),
    action("CLIENT_UPDATE_COMPANY", (obs$: Observable<TClientCaseCompanyForm>, lens, setState) => {
        obs$.pipe(
            tap((form) => setState(lens.client_case_page.children.confirm_case_users?.company.set(form))),
            debounce(() => timer(1000)),
            tap(() => setState(lens.client_case_page.children.confirm_case_users?.company.status.set("submitting"))),
            mergeMap((form) => formOperation("UpdateClientCaseCompanyName", form)),
            tap((response) => setState(lens.client_case_page.children.confirm_case_users?.company.set(response))),
        ).subscribe();
    }),
    action("CLIENT_UPDATE_RELATED_TRANSACTION_QUESTION", (obs$: Observable<TClientCaseRelatedTransactionQuestionForm>, lens, setState) => {
        obs$.pipe(
            tap((form) => setState(lens.client_case_page.children.related_transaction?.question.set({
                ...form,
                status: "requiresSubmission",
            })))
        ).subscribe();
    }),
    action("CLIENT_SUBMIT_RELATED_TRANSACTION", (obs$: Observable<TClientCaseRelatedTransaction>, lens, setState, getState) => {
        obs$.pipe(
            switchMap((payload) =>
                forkJoin([
                    of(setState(lens.client_case_page.children.related_transaction?.question.set({
                        ...payload.question,
                        status: "submitting",
                    })))
                        .pipe(
                            switchMap(() => formOperation("UpdateClientCaseRelatedTransactionQuestion", payload.question)),
                            tap((form) => setState(lens.client_case_page.children.related_transaction?.question.set(form))),
                        ),
                    pipe(
                        lens.client_case_page.children.related_transaction?.address.edited.get()(getState()),
                        record.toArray,
                        array.filter(([key, val]) => val !== lens.client_case_page.children.related_transaction?.address.original.get()(getState())[key]),
                        (a) =>
                            a.length > 0
                                ? of(setState(lens.client_case_page.children.related_transaction?.address.set({
                                    ...payload.address,
                                    status: "submitting",
                                })))
                                    .pipe(
                                        switchMap(() => formOperation("UpdateClientCaseRelatedTransactionAddress", payload.address)),
                                        tap((form) => setState(lens.client_case_page.children.related_transaction?.address.set(form))),
                                    )
                                : of(undefined)
                    )
                ])
            ),
            switchMap(([, addressRequest]) =>
                lens.client_case_page.children.related_transaction?.question.status.get()(getState()) === "success"
                && (lens.client_case_page.children.related_transaction?.address.status.get()(getState()) === "success" || typeof addressRequest === "undefined")
                    ? from(formOperation("GetClientCase", lens.client_case_page.get()(getState())))
                        .pipe(tap((form) => setState(lens.client_case_page.set(form))))
                    : of(undefined)
            ),
        ).subscribe();
    }),
    action("CLIENT_UPDATE_RELATED_TRANSACTION_ADDRESS", (obs$: Observable<TClientCaseRelatedTransactionAddressForm>, lens, setState) => {
        obs$.pipe(
            tap((form) => setState(lens.client_case_page.children.related_transaction?.address.set({
                ...form,
                status: "requiresSubmission",
            }))),
        ).subscribe();
    }),
    action("CLIENT_RELATED_TRANSACTION_AUTOCOMPLETE_ADDRESS", (obs$: Observable<TClientCaseRelatedTransactionAddressForm>, lens, setState) => {
        obs$.pipe(
            tap(() => setState(lens.client_case_page.children.related_transaction?.address.status.set("submitting"))),
            switchMap((form) => formOperation("AutocompleteRelatedTransactionAddress", form)),
            tap((form) => setState(lens.client_case_page.children.related_transaction?.address.set(form))),
        ).subscribe();
    }),
    action("CLIENT_ADD_CLIENT_USER", (obs$: Observable<null>, lens, setState, getState) => {
        obs$.pipe(
            tap(() => setState(lens.client_case_page.children.confirm_case_users?.create_case_user.status.set("submitting"))),
            map(() => lens.client_case_page.children.confirm_case_users?.create_case_user.get()(getState())),
            switchMap((form) => formOperation("CreateClientCaseUser", form)),
            switchMap(() => formOperation("GetClientCase", lens.client_case_page.get()(getState()))),
            tap((response) => setState(lens.client_case_page.set(response))),
        ).subscribe()
    }),
    action("CLIENT_DELETE_CLIENT_USER", (obs$: Observable<TClientCaseUserForm>, lens, setState, getState) => {
        obs$.pipe(
            tap((form) => setState(lens.client_case_page.children.confirm_case_users.users.where(doFormValuesMatch(form, "users__cases_id")).status.set("submitting"))),
            switchMap((form) => formOperation("DeleteClientCaseUser", form)),
            switchMap(() => formOperation("GetClientCase", lens.client_case_page.get()(getState()))),
            tap((response) => setState(lens.client_case_page.set(response))),
        ).subscribe()
    }),
    action("CLIENT_UPDATE_CLIENT_USER", (obs$: Observable<TClientCaseUserForm>, lens, setState, getState) => {
        obs$.pipe(
            tap((form) => setState(lens.client_case_page.children.confirm_case_users.users.where(doFormValuesMatch(form, "users__cases_id")).set(form))),
            debounceTime(1000),
            tap((form) => setState(lens.client_case_page.children.confirm_case_users.users.where(doFormValuesMatch(form, "users__cases_id")).status.set("submitting"))),
            switchMap((form) => formOperation("UpdateClientCaseUser", form)),
            tap((form) => setState(lens.client_case_page.children.confirm_case_users.users.where(doFormValuesMatch(form, "users__cases_id")).set({
                ...form,
                edited: lens.client_case_page.children.confirm_case_users.users.where(doFormValuesMatch(form, "users__cases_id")).edited.get()(getState()),
            }))),
        ).subscribe()
    }),
    action("CLIENT_SET_PRIMARY_CONTACT", (obs$: Observable<string>, lens, setState, getState) => {
        obs$.pipe(
            map((primartyContactUserId) =>
                lens.client_case_page.children.confirm_case_users.users
                    .get()(getState())
                    .map((user) => ({
                        ...user,
                        edited: {
                            ...user.edited,
                            is_primary_contact: user.children.user_id === primartyContactUserId  
                        }
                    }))
            ),
            tap((users) => setState(lens.client_case_page.children.confirm_case_users.users.set(users))),
            debounce(() => timer(500)),
            concatMap((users) => users),
            tap((form) => setState(lens.client_case_page.children.confirm_case_users.users.where(doFormValuesMatch(form, "users__cases_id")).status.set("submitting"))),
            switchMap((user) => formOperation("UpdateClientCaseUser", user)),
        ).subscribe()
    }),
    action("CLIENT_SUBMIT_CLIENTS_FORM_AS_COMPLETE", (obs$: Observable<null>, lens, setState, getState) => {
        obs$.pipe(
            map(() => lens.client_case_page.children.confirm_case_users.submit_step_as_complete.get()(getState())),
            switchMap((form) => formOperation("SubmitClientCaseUsersStepComplete", form)),
            switchMap(() => formOperation("GetClientCase", lens.client_case_page.get()(getState()))),
            tap((response) => setState(lens.client_case_page.set(response))),
        ).subscribe()
    }),
    action("CLIENT_VIDEO_VERIFICATION_CALL_UPDATE", (obs$: Observable<TClientCaseVideoVerificationCallCreateForm>, lens, setState, getState) => {
        obs$.pipe(
            tap((form) => setState(lens.client_case_page.children.video_verification_call.create_booking.set(form))),
            filter(() => lens.client_case_page.children.video_verification_call.create_booking.children.status.get()(getState()) === "untouched"),
            switchMap(() => formOperation("GetClientCaseVideoVerificationCallAvailableTimes", lens.client_case_page.children.video_verification_call.create_booking.children.get()(getState()))),
            tap((response) => setState(lens.client_case_page.children.video_verification_call.create_booking.children.set(response))),
        ).subscribe()
    }),
    action("CLIENT_VIDEO_VERIFICATION_CALL_SUBMIT", (obs$: Observable<TClientCaseVideoVerificationCallCreateForm>, lens, setState, getState) => {
        obs$.pipe(
            tap((form) => setState(lens.client_case_page.children.video_verification_call.create_booking.set({
                ...form,
                status: "submitting",
            }))),
            switchMap(() => formOperation("CreateClientCaseVideoVerificationCall", lens.client_case_page.children.video_verification_call.create_booking.get()(getState()))),
            tap((response) => setState(lens.client_case_page.children.video_verification_call.create_booking.set(response))),
            switchMap(() => formOperation("GetClientCase", lens.client_case_page.get()(getState()))),
            tap((response) => setState(lens.client_case_page.set(response))),
        ).subscribe()
    }),
    action("CLIENT_PROOF_OF_ADDRESS_DOCUMENT_UPLOAD", (obs$: Observable<TClientCaseIdentityVerificationUserProofOfAddressForm>, lens, setState, getState) => {
        obs$.pipe(
            tap((form) => setState(lens.client_case_page.children.identity_verification.users.where((user) => user.children.upload_proof_of_address_form.input.users_id_checks_id === form.input.users_id_checks_id).children.upload_proof_of_address_form.set(form))),
            filter((form) => form.status === "submitting"),
            switchMap((form) => formOperation("UploadClientCaseProofOfAddressDocument", lens.client_case_page.children.identity_verification.users.where((user) => user.children.upload_proof_of_address_form.input.users_id_checks_id === form.input.users_id_checks_id).children.upload_proof_of_address_form.get()(getState()))),
            tap((response) => setState(lens.client_case_page.children.identity_verification.users.where((user) => user.children.upload_proof_of_address_form.input.users_id_checks_id === response.input.users_id_checks_id).children.upload_proof_of_address_form.set(response))),
            switchMap((response) =>
                response.status === "success"
                    ? from(formOperation("GetClientCase", lens.client_case_page.get()(getState())))
                        .pipe(tap((r) => setState(lens.client_case_page.set(r))))
                    : of(undefined)
            ),
        ).subscribe()
    }),
    action("CLIENT_UPDATE_SDLT_QUESTIONS", (obs$: Observable<TClientCaseSdltForm>, lens, setState, getState) => {
        obs$.pipe(
            tap((form) => setState(lens.client_case_page.children.sdlt_questions.set(form))),
            debounceTime(1000),
            switchMap((form) => formOperation("UpdateClientCaseSdltQuestion", form)),
            tap((response) => setState(lens.client_case_page.children.sdlt_questions.set({
                ...response,
                edited: lens.client_case_page.children.sdlt_questions.edited.get()(getState()),
            }))),
        ).subscribe()
    }),
    action("CLIENT_SUBMIT_SDLT_QUESTIONS", (obs$: Observable<TClientCaseSdltForm>, lens, setState, getState) => {
        obs$.pipe(
            tap((form) => setState(lens.client_case_page.children.sdlt_questions.set({
                ...form,
                status: "submitting",
            }))),
            switchMap((form) => formOperation("SubmitClientCaseSdltQuestion", form)),
            tap((response) => setState(lens.client_case_page.children.sdlt_questions.set(response))),
            switchMap((response) =>
                response.status === "success"
                    ? from(formOperation("GetClientCase", lens.client_case_page.get()(getState())))
                        .pipe(tap((r) => setState(lens.client_case_page.set(r))))
                    : of(undefined)
            ),
        ).subscribe()
    }),
    action("CLIENT_UPDATE_PURCHASE_PAYMENT_DETAILS", (obs$: Observable<TClientCasePurchasePaymentDetailsForm>, lens, setState) => {
        obs$.pipe(
            tap((form) => setState(lens.client_case_page.children.purchase_payment_details.set({
                ...form,
                status: "requiresSubmission"
            }))),
        ).subscribe()
    }),
    action("CLIENT_SAVE_PURCHASE_PAYMENT_DETAILS", (obs$: Observable<null>, lens, setState, getState) => {
        obs$.pipe(
            map(() => lens.client_case_page.children.purchase_payment_details.get()(getState())),
            switchMap((form) => formOperation("SaveClientCasePurchasePaymentDetails", form)),
            switchMap(() => formOperation("GetClientCase", lens.client_case_page.get()(getState()))),
            tap((response) => setState(lens.client_case_page.set(response))),
        ).subscribe()
    }),
    action("CLIENT_SUBMIT_PURCHASE_PAYMENT_DETAILS_FORM_AS_COMPLETE", (obs$: Observable<null>, lens, setState, getState) => {
        obs$.pipe(
            map(() => lens.client_case_page.children.purchase_payment_details.get()(getState())),
            switchMap((form) => formOperation("SubmitClientCasePurchasePaymentDetailsAsFinal", form)),
            switchMap(() => formOperation("GetClientCase", lens.client_case_page.get()(getState()))),
            tap((response) => setState(lens.client_case_page.set(response))),
        ).subscribe()
    }),
    action("CLIENT_UPDATE_REMORTGAGE_MORTGAGE_DETAILS", (obs$: Observable<TClientCaseRemortgageMortgageDetailsForm>, lens, setState) => {
        obs$.pipe(
            tap((form) => setState(lens.client_case_page.children.remortgage_details.set({
                ...form,
                status: "requiresSubmission"
            }))),
        ).subscribe()
    }),
    action("CLIENT_SUBMIT_REMORTGAGE_MORTGAGE_DETAILS", (obs$: Observable<null>, lens, setState, getState) => {
        obs$.pipe(
            map(() => lens.client_case_page.children.remortgage_details.get()(getState())),
            switchMap((form) => formOperation("SubmitClientCaseRemortgageMortgageDetails", form)),
            switchMap(() => formOperation("GetClientCase", lens.client_case_page.get()(getState()))),
            tap((response) => setState(lens.client_case_page.set(response))),
        ).subscribe()
    }),
    action("CLIENT_UPDATE_CHARGE", (obs$: Observable<TClientCaseChargeForm>, lens, setState, getState) => {
        obs$.pipe(
            tap((form) => setState(lens.client_case_page.children.charge_account_numbers.charges.where((f) => f.edited.id === form.edited.id).set({
                ...form,
                status: "requiresSubmission",
            }))),
            debounceTime(1000),
            switchMap((form) => formOperation("UpdateClientCaseCharge", form)),
            tap((response) => setState(lens.client_case_page.children.charge_account_numbers.charges.where((f) => f.edited.id === response.edited.id).set({
                ...response,
                edited: lens.client_case_page.children.charge_account_numbers.charges.where((f) => f.edited.id === response.edited.id).edited.get()(getState()),
            }))),
        ).subscribe()
    }),
    action("CLIENT_SUBMIT_CHARGES", (obs$: Observable<null>, lens, setState, getState) => {
        obs$.pipe(
            tap(() => setState(lens.client_case_page.children.charge_account_numbers.submit_step_as_complete.status.set("submitting"))),
            switchMap(() => formOperation("SubmitClientCaseCharge", lens.client_case_page.children.charge_account_numbers.submit_step_as_complete.get()(getState()))),
            tap((response) => setState(lens.client_case_page.children.charge_account_numbers.submit_step_as_complete.set(response))),
            switchMap((response) =>
                response.status === "success"
                    ? from(formOperation("GetClientCase", lens.client_case_page.get()(getState())))
                        .pipe(tap((r) => setState(lens.client_case_page.set(r))))
                    : of(undefined)
            ),
        ).subscribe()
    }),
    action("CLIENT_SUBMIT_AGREE_TO_TOE_STEP_FORM_AS_COMPLETE", (obs$: Observable<null>, lens, setState, getState) => {
        obs$.pipe(
            map(() => lens.client_case_page.children.agree_to_toe.submit_step_as_complete.get()(getState())),
            switchMap((form) => formOperation("SubmitClientCaseUserAgreeToTOEStepAsComplete", form)),
            switchMap(() => formOperation("GetClientCase", lens.client_case_page.get()(getState()))),
            tap((response) => setState(lens.client_case_page.set(response))),
        ).subscribe()
    }),
    action("CLIENT_SUBMIT_DUAL_REP_CONSENT_STEP_FORM_AS_COMPLETE", (obs$: Observable<null>, lens, setState, getState) => {
        obs$.pipe(
            map(() => lens.client_case_page.children.dual_rep_consent.submit_step_as_complete.get()(getState())),
            switchMap((form) => formOperation("SubmitClientCaseUserDualRepConsentStepAsComplete", form)),
            switchMap(() => formOperation("GetClientCase", lens.client_case_page.get()(getState()))),
            tap((response) => setState(lens.client_case_page.set(response))),
        ).subscribe()
    }),
    action("CLIENT_SUBMIT_PROPERTY_INFORMATION_FORMS_AS_COMPLETE", (obs$: Observable<null>, lens, setState, getState) => {
        obs$.pipe(
            map(() => lens.client_case_page.children.property_information_forms.submit_step_as_complete_form.get()(getState())),
            switchMap((form) => formOperation("SubmitClientCasePropertyInformationFormsStepComplete", form)),
            switchMap(() => formOperation("GetClientCase", lens.client_case_page.get()(getState()))),
            tap((response) => setState(lens.client_case_page.set(response))),
        ).subscribe()
    }),
    action("CLIENT_JOINT_OWNERSHIP_HELD_AS_PREFERENCE_CHANGE", (obs$: Observable<TClientCaseJointOwnershipHeldAsForm>, lens, setState) => {
        obs$.pipe(
            tap((form) => setState(lens.client_case_page.children.joint_ownership_held_as_preference.form.set(form))),
        ).subscribe()
    }),
    action("CLIENT_JOINT_OWNERSHIP_HELD_AS_PREFERENCE_SUBMIT", (obs$: Observable<undefined>, lens, setState, getState) => {
        obs$.pipe(
            map(() => ({
                ...lens.client_case_page.children.joint_ownership_held_as_preference.form.get()(getState()),
                status: "submitting" as "submitting",
            })),
            switchMap((form) => formOperation("UpdateClientCaseJointOwnershipHeldAsPreference", form)),
            tap((response) => setState(lens.client_case_page.children.joint_ownership_held_as_preference.form.set(response))),
            switchMap((response) =>
                response.status === "success"
                    ? from(formOperation("GetClientCase", lens.client_case_page.get()(getState())))
                        .pipe(tap((r) => setState(lens.client_case_page.set(r))))
                    : of(undefined)
            ),
        ).subscribe()
    }),
    action("CLIENT_DECLARATION_OF_TRUST_REFERRAL_CHANGE", (obs$: Observable<TClientCaseDeclarationOfTrustReferralForm>, lens, setState) => {
        obs$.pipe(
            tap((form) => setState(lens.client_case_page.children.declaration_of_trust_referral.form.set(form))),
        ).subscribe()
    }),
    action("CLIENT_DECLARATION_OF_TRUST_REFERRAL_SUBMIT", (obs$: Observable<undefined>, lens, setState, getState) => {
        obs$.pipe(
            map(() => ({
                ...lens.client_case_page.children.declaration_of_trust_referral.form.get()(getState()),
                status: "submitting" as "submitting",
            })),
            switchMap((form) => formOperation("UpdateClientCaseDeclarationOfTrustReferral", form)),
            tap((response) => setState(lens.client_case_page.children.declaration_of_trust_referral.form.set(response))),
            switchMap((response) =>
                response.status === "success"
                    ? from(formOperation("GetClientCase", lens.client_case_page.get()(getState())))
                        .pipe(tap((r) => setState(lens.client_case_page.set(r))))
                    : of(undefined)
            ),
        ).subscribe()
    }),
    action("CLIENT_WILL_REFERRAL_CHANGE", (obs$: Observable<TClientCaseWillReferralForm>, lens, setState) => {
        obs$.pipe(
            tap((form) => setState(lens.client_case_page.children.will_referral.form.set(form))),
        ).subscribe()
    }),
    action("CLIENT_WILL_REFERRAL_SUBMIT", (obs$: Observable<TClientCaseWillReferralForm>, lens, setState, getState) => {
        obs$.pipe(
            filter(({status}) => status !== "submitting"),
            map((form) => ({
                ...form,
                status: "submitting" as "submitting",
            })),
            tap((form) => setState(lens.client_case_page.children.will_referral.form.set(form))),
            switchMap((form) => formOperation("UpdateClientCaseWillReferral", form)),
            tap((response) => setState(lens.client_case_page.children.will_referral.form.set(response))),
            switchMap((response) =>
                response.status === "success"
                    ? from(formOperation("GetClientCase", lens.client_case_page.get()(getState())))
                        .pipe(tap((r) => setState(lens.client_case_page.set(r))))
                    : of(undefined)
            ),
        ).subscribe()
    }),
];
