import React, { useState } from "react";
import { CRMColors } from "../../../models/CRMColors";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { BackgroundColour } from "../../BuildingBlocks/BackgroundColour";
import { MinHeight } from "../../BuildingBlocks/MinHeight";
import { Padding } from "../../BuildingBlocks/Padding";
import { SpacingColumn } from "../../BuildingBlocks/SpacingColumn";
import { SpacingRow } from "../../BuildingBlocks/SpacingRow";
import { Sticky } from "../../BuildingBlocks/Sticky";
import { CRMParagraph } from "../../Simple/CRMParagraph/CRMParagraph";
import { CRMSingleViewHeader } from "../../Simple/CRMSingleViewHeader/CRMSingleViewHeader";
import { Relative } from "../../BuildingBlocks/Relative";
import { CRMBottomControlBar } from "../../CRMBottomControlBar/CRMBottomControlBar";
import { CRMButtonIcon } from "../../CRMButtonIcon/CRMButtonIcon";
import { Absolute } from "../../BuildingBlocks/Absolute";
import { useResolutionStatus } from "../../../hooks/useResolutionStatus";
import { CRMConfirmationCountdown } from "../CRMConfirmationCountdown/CRMConfirmationCountdown";
import { TPixelSize } from "../../../models/StringLiterals";
import { TMarketingCampaignForm, TMarketingCampaignSpendForm } from "../../../../../domain/codecs/form/MarketingCampaignForm";
import { CRMMarketingCampaignEditPopout } from "../CRMMarketingCampaignEditPopout/CRMMarketingCampaignEditPopout";
import { CRMTitleSubSection } from "../../CRMTitleSubSection/CRMTitleSubSection";
import { pipe } from "fp-ts/lib/function";
import { array, date, ord } from "fp-ts";
import { CRMBlockPopover } from "../../CRM/CRMBlock/CRMBlockPopover/CRMBlockPopover";

type TIntroducerSingleViewProps = {
    form: TMarketingCampaignForm;
    onChange: (form: TMarketingCampaignForm) => void;
    onClose: () => void;
    onArchive: (form: TMarketingCampaignForm) => void;
    onSave: (form: TMarketingCampaignForm) => void;
};

type TPopupViewStatus = 
    "none"
    | "edit"
;

export const CRMMarketingCampaignSingleView = (props: React.PropsWithChildren<TIntroducerSingleViewProps>): JSX.Element => {

    const [popupViewStatus, setPopupViewStatus] = useState<TPopupViewStatus>("none");
    const {
        resolutionStatus,
        setResolution,
        resetResolution,
    } = useResolutionStatus<"none" | "archiving", {}>({
        defaultStatus: "none",
    });

    const onArchive = () => setResolution("archiving")();

    const onSubmitArchive = () => {
        props.onArchive(props.form);
        resetResolution();
    }

    const onEdit = () => setPopupViewStatus("edit");

    const spendFormsMergedFilteredOrdered =
        pipe(
            [
                ...props.form.children.spend_forms,
                ...props.form.children.spend_forms_to_be_deleted,
            ],
            array.filter((spend_form) => spend_form.original.id !== ""),
            array.sortBy([
                pipe(
                    date.Ord,
                    ord.contramap((form: TMarketingCampaignSpendForm) => new Date(form.edited.cost_date))
                )
            ])
        );

    return (
        <>

            {/* POPOVER - EDIT */}
            {popupViewStatus === "edit" &&
                <CRMBlockPopover
                    padding={CRMSpacing.MEDIUM}
                    height="745px" 
                    maxHeight="745px"
                >
                    <CRMMarketingCampaignEditPopout
                        form={props.form}
                        onClose={() => setPopupViewStatus("none")}
                        onChange={props.onChange}
                        onSave={props.onSave}
                    />
                </CRMBlockPopover>
            }
            
            {/* MAIN */}
            <div>
                {/* BODY */}
                <div>
                    <Sticky
                        zIndex={1}
                    >
                        <CRMSingleViewHeader 
                            title={props.form.original.name}
                            onClick={props.onClose} 
                        />
                    </Sticky>
                    
                    <BackgroundColour colour={CRMColors.NEUTRAL_PAPER}>
                        <MinHeight height={"700px"}>
                            <Padding spacing={CRMSpacing.LARGE}>
                                
                                <SpacingColumn spacing={CRMSpacing.LARGE}>

                                    {/* SECTION - SHORT CODE */}
                                    <SpacingColumn spacing={CRMSpacing.SMALL}>

                                        <SpacingRow spacing={CRMSpacing.TINY}>
                                            <CRMParagraph>
                                                Short Code:
                                            </CRMParagraph>
                                            <CRMParagraph>
                                                {props.form.original.short_code}
                                            </CRMParagraph>
                                        </SpacingRow>

                                    </SpacingColumn>

                                    {/* SECTION - SPEND */}
                                    <SpacingColumn spacing={CRMSpacing.SMALL}>
                                
                                        <CRMTitleSubSection>
                                            Spend
                                        </CRMTitleSubSection>

                                        {
                                            pipe(
                                                spendFormsMergedFilteredOrdered,
                                                array.map((spend_form) => <SpacingRow spacing={CRMSpacing.MEDIUM} childSize="1fr 1fr">
                                                    <CRMParagraph>{spend_form.original.cost_date}:</CRMParagraph>
                                                    <CRMParagraph>£{spend_form.original.cost_pence/100}</CRMParagraph>
                                                </SpacingRow>)
                                            )
                                        }

                                    </SpacingColumn>
                                    
                                </SpacingColumn>

                            </Padding>
                        </MinHeight>
                    </BackgroundColour>
                </div>
                
                {/* CONTROL BAR */}
                <CRMBottomControlBar>
                    
                    {resolutionStatus === "archiving" &&
                        <CRMConfirmationCountdown
                            title="Archiving!"
                            iconName="archive"
                            primaryButtonMode="done"
                            onUndo={resetResolution}
                            onComplete={onSubmitArchive}
                        />
                    }

                    {/* CONTROLS */}
                    {resolutionStatus === "none" &&
                        <Padding 
                            type="custom"
                            width="100%" 
                            spacing={`${CRMSpacing.TINY} ${CRMSpacing.MEDIUM}`}
                        >
                            <SpacingRow justifyContent="space-between">
                                <SpacingRow spacing={CRMSpacing.SMALL}>
                                    <CRMButtonIcon
                                        title="Archive campaign."
                                        variant="quaternary"
                                        icon="delete"
                                        onClick={onArchive}
                                    />
                                </SpacingRow>

                                <CRMButtonIcon
                                    title="Edit campaign."
                                    variant="primary"
                                    icon="pencil"
                                    onClick={onEdit}
                                />
                            </SpacingRow>
                        </Padding>
                    }
                </CRMBottomControlBar>
            </div>
        </>
    );
};

