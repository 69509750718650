import React from "react";
import { CRMTitleSection } from "../../components/CRMTitleSection/CRMTitleSection";
import { CRMSpacer } from "../../components/CRMSpacer/CRMSpacer";
import { CRMDropdownComponent } from "../../components/CRMDropdownComponent/CRMDropdownComponent";
import { enumToIDropdownOptions } from "../../functions/enumToIDropdownOptions";
import CRMInputLabelAndErrorWrapComponent from "../../components/CRMInputLabelAndErrorWrapComponent/CRMInputLabelAndErrorWrapComponent";
import { CRMInputCurrency } from "../../components/CRMInputCurrency/CRMInputCurrency";
import CRMTextAreaComponent from "../../components/CRMTextAreaComponent/CRMTextAreaComponent";
import * as util from "../../../../shared/src/util";
import { CRMFormButtonIconWithLabel } from "../../components/CRMFormButtonIconWithLabel/CRMFormButtonIconWithLabel";
import { CRMButtonIcon } from "../../components/CRMButtonIcon/CRMButtonIcon";
import { array } from "fp-ts";
import { CRMCollapsibleSectionComponent } from "../../components/CRMCollapsibleSectionComponent/CRMCollapsibleSectionComponent";
import { CRMTitleSubSection } from "../../components/CRMTitleSubSection/CRMTitleSubSection";
import CRMInputGeneralComponent from "../../components/CRMInputs/CRMInputGeneralComponent/CRMInputGeneralComponent";
import CRMEnquiryEditListingAddressComponent from "../../components/CRMEnquiryEditListingAddressComponent/CRMEnquiryEditListingAddressComponent";
import { CRMInputCalendarComponent } from "../../components/CRMInputCalendarComponent/CRMInputCalendarComponent";
import { CRMColumns } from "../../components/CRMColumns/CRMColumns";
import CRMEnquiryBuyerReadyComponent from "../../components/CRMEnquiryBuyerReadyComponent/CRMEnquiryBuyerReadyComponent";
import * as PartySellingPropertyState from "../../../../domain/models/PartySellingPropertyState";
import * as JsonError1 from "../../../../domain/models/JsonError1";
import * as PartyMovingReason from "../../../../domain/models/PartyMovingReason";
import * as PartyPurchasingSituation from "../../../../domain/models/PartyPurchasingSituation";
import * as DisplayAddress from "../../../../domain/models/DisplayAddress";
import * as ListingValuationType from "../../../../domain/models/ListingValuationType";
import * as ListingCondition1 from "../../../../domain/models/ListingCondition1";
import * as ListingContractType2 from "../../../../domain/models/ListingContractType2";
import * as ListingReasonForSale1 from "../../../../domain/models/ListingReasonForSale1";
import * as TContainerStateProps from "../../state/TContainerStateProps";
import { pipe } from "fp-ts/lib/function";
import { CRMAutosaveIndicatorWrap } from "../../components/CRM/CRMAutosaveIndicatorWrap/CRMAutosaveIndicatorWrap";
import * as TFormStatus from "../../models/TFormStatus";
import { ListingEnquiryPurchasingIntent1, ListingEnquiryPurchasingIntent1_displayName } from "../../../../domain/codecs/ListingEnquiryPurchasingIntent";
import { PaymentMethod, PaymentMethod_displayName } from "../../../../domain/codecs/PaymentMethod";
import { ListingEnquiryRenovationWorkBy1, ListingEnquiryRenovationWorkBy1_displayName } from "../../../../domain/codecs/ListingEnquiryRenovationWorkBy";
import { doesErrorKeyExist } from "../../../../shared/src/codecs/errors";

const areListingFormsVisible = (p: PartySellingPropertyState.T): boolean => {
    switch (p) {
        case "property_to_sell_already_exchanged":
        case "property_to_sell_not_on_the_market":
        case "property_to_sell_on_the_market":
        case "property_to_sell_under_offer":
            return true;
        case "no_property_to_sell":
        case "property_to_let":
        case "unknown":
            return false;
        default:
            return util.requireExhaustive(p);
    }
};

export class CRMEnquiryAboutThisPartyContainer extends React.Component<React.PropsWithChildren<TContainerStateProps.TContainerStateProps>> {
    public render(): JSX.Element {
        return (
            <div>
                <CRMAutosaveIndicatorWrap status={
                    pipe(
                        TFormStatus.reduceToHighestPriority([
                            this.props.state.forms.listing_enquiry_page.children.enquiry.status === "unauthorised" || this.props.state.forms.listing_enquiry_page.children.enquiry.status === "twoFactorRequired" || this.props.state.forms.listing_enquiry_page.children.enquiry.status === "notFound" ? "failure"
                                : this.props.state.forms.listing_enquiry_page.children.enquiry.status,
                            this.props.state.activeData.crm.enquiry.partyForm.status,
                        ]),
                        (status) => status === "hidden" ? "untouched"
                            : status,
                    )
                }>
                    <CRMTitleSection>About this party</CRMTitleSection>
                    <CRMSpacer size="large" />
                    <CRMEnquiryBuyerReadyComponent
                        enquiry={this.props.state.forms.listing_enquiry_page.children.enquiry}
                        usersFormList={this.props.state.activeData.crm.enquiry.peopleFormList}
                        onChange={(payload) => this.props.dispatch({
                            type: "CRM_ENQUIRY_INPUT_CHANGE",
                            payload,
                        })}
                        onUserChange={(p) => this.props.dispatch({
                            type: "CRM_ENQUIRY_PERSON_INPUT_CHANGED",
                            payload: {
                                key: p.key,
                                value: p.value,
                                resourceId: p.userId,
                            },
                        })}
                    />
                    <CRMSpacer size="medium" />
                    <CRMInputLabelAndErrorWrapComponent
                        label="Purchasing intent"
                        displayError={JsonError1.doTargetKeysHaveInnerErrors(["purchasing_intent"], this.props.state.activeData.crm.enquiry.enquiryForm.validationErrors)}
                        errorMessage={JsonError1.getInnerErrorsForTargetKeysErrorMessageString(["purchasing_intent"], this.props.state.activeData.crm.enquiry.enquiryForm.validationErrors)}
                    >
                        <CRMDropdownComponent
                            options={enumToIDropdownOptions(
                                ListingEnquiryPurchasingIntent1.values,
                                ListingEnquiryPurchasingIntent1_displayName,
                            )}
                            value={this.props.state.forms.listing_enquiry_page.children.enquiry.edited.purchasing_intent}
                            onChange={(purchasing_intent) => this.props.dispatch({
                                type: "CRM_ENQUIRY_INPUT_CHANGE",
                                payload: {
                                    ...this.props.state.forms.listing_enquiry_page.children.enquiry,
                                    edited: {
                                        ...this.props.state.forms.listing_enquiry_page.children.enquiry.edited,
                                        purchasing_intent,
                                    }
                                },
                            })}
                            displayError={doesErrorKeyExist("edited.purchasing_intent", this.props.state.forms.listing_enquiry_page.children.enquiry.validationErrors)}
                        />
                    </CRMInputLabelAndErrorWrapComponent>
                    <CRMSpacer size="medium" />
                    <CRMInputLabelAndErrorWrapComponent
                        label="Payment method"
                        displayError={JsonError1.doTargetKeysHaveInnerErrors(["paymentMethod"], this.props.state.activeData.crm.enquiry.enquiryForm.validationErrors)}
                        errorMessage={JsonError1.getInnerErrorsForTargetKeysErrorMessageString(["paymentMethod"], this.props.state.activeData.crm.enquiry.enquiryForm.validationErrors)}
                    >
                        <CRMDropdownComponent
                            options={enumToIDropdownOptions(
                                PaymentMethod.values,
                                PaymentMethod_displayName,
                            )}
                            value={this.props.state.forms.listing_enquiry_page.children.enquiry.edited.payment_method}
                            onChange={(payment_method) => this.props.dispatch({
                                type: "CRM_ENQUIRY_INPUT_CHANGE",
                                payload: {
                                    ...this.props.state.forms.listing_enquiry_page.children.enquiry,
                                    edited: {
                                        ...this.props.state.forms.listing_enquiry_page.children.enquiry.edited,
                                        payment_method,
                                    }
                                },
                            })}
                            displayError={doesErrorKeyExist("edited.payment_method", this.props.state.forms.listing_enquiry_page.children.enquiry.validationErrors)}
                        />
                    </CRMInputLabelAndErrorWrapComponent>
                    <CRMSpacer size="medium" />
                    <CRMInputLabelAndErrorWrapComponent
                        label="How would they carry out renovation"
                        displayError={JsonError1.doTargetKeysHaveInnerErrors(["renovation_work_by"], this.props.state.activeData.crm.enquiry.enquiryForm.validationErrors)}
                        errorMessage={JsonError1.getInnerErrorsForTargetKeysErrorMessageString(["renovation_work_by"], this.props.state.activeData.crm.enquiry.enquiryForm.validationErrors)}
                    >
                        <CRMDropdownComponent
                            options={enumToIDropdownOptions(
                                ListingEnquiryRenovationWorkBy1.values,
                                ListingEnquiryRenovationWorkBy1_displayName,
                            )}
                            value={this.props.state.forms.listing_enquiry_page.children.enquiry.edited.renovation_work_by}
                            onChange={(renovation_work_by) => this.props.dispatch({
                                type: "CRM_ENQUIRY_INPUT_CHANGE",
                                payload: {
                                    ...this.props.state.forms.listing_enquiry_page.children.enquiry,
                                    edited: {
                                        ...this.props.state.forms.listing_enquiry_page.children.enquiry.edited,
                                        renovation_work_by,
                                    }
                                },
                            })}
                            displayError={doesErrorKeyExist("edited.renovation_work_by", this.props.state.forms.listing_enquiry_page.children.enquiry.validationErrors)}
                        />
                    </CRMInputLabelAndErrorWrapComponent>
                    <CRMSpacer size="medium" />
                    <CRMInputLabelAndErrorWrapComponent
                        label="Amount needed to make on resale"
                        displayError={JsonError1.doTargetKeysHaveInnerErrors(["amount_required_when_reselling"], this.props.state.activeData.crm.enquiry.enquiryForm.validationErrors)}
                        errorMessage={JsonError1.getInnerErrorsForTargetKeysErrorMessageString(["amount_required_when_reselling"], this.props.state.activeData.crm.enquiry.enquiryForm.validationErrors)}
                    >
                        <CRMInputCurrency
                            value={this.props.state.forms.listing_enquiry_page.children.enquiry.edited.amount_required_when_reselling}
                            currencySymbol="£"
                            displayError={doesErrorKeyExist("edited.amount_required_when_reselling", this.props.state.forms.listing_enquiry_page.children.enquiry.validationErrors)}
                            onChange={(amount_required_when_reselling) => this.props.dispatch({
                                type: "CRM_ENQUIRY_INPUT_CHANGE",
                                payload: {
                                    ...this.props.state.forms.listing_enquiry_page.children.enquiry,
                                    edited: {
                                        ...this.props.state.forms.listing_enquiry_page.children.enquiry.edited,
                                        amount_required_when_reselling,
                                    }
                                },
                            })}
                            onPressEnterKey={() => undefined}
                        />
                    </CRMInputLabelAndErrorWrapComponent>
                    <CRMSpacer size="medium" />
                    <CRMInputLabelAndErrorWrapComponent
                        label="Enquiry details"
                        displayError={JsonError1.doTargetKeysHaveInnerErrors(["notes"], this.props.state.activeData.crm.enquiry.enquiryForm.validationErrors)}
                        errorMessage={JsonError1.getInnerErrorsForTargetKeysErrorMessageString(["notes"], this.props.state.activeData.crm.enquiry.enquiryForm.validationErrors)}
                    >
                        <CRMTextAreaComponent
                            value={this.props.state.forms.listing_enquiry_page.children.enquiry.edited.notes}
                            displayError={doesErrorKeyExist("edited.notes", this.props.state.forms.listing_enquiry_page.children.enquiry.validationErrors)}
                            onChange={(notes) => this.props.dispatch({
                                type: "CRM_ENQUIRY_INPUT_CHANGE",
                                payload: {
                                    ...this.props.state.forms.listing_enquiry_page.children.enquiry,
                                    edited: {
                                        ...this.props.state.forms.listing_enquiry_page.children.enquiry.edited,
                                        notes,
                                    }
                                },
                            })}
                        />
                    </CRMInputLabelAndErrorWrapComponent>
                    <CRMSpacer size="medium" />
                    <CRMInputLabelAndErrorWrapComponent
                        label="Moving reason"
                        displayError={JsonError1.doTargetKeysHaveInnerErrors(["moving_reason"], this.props.state.activeData.crm.enquiry.partyForm.validationErrors)}
                        errorMessage={JsonError1.getInnerErrorsForTargetKeysErrorMessageString(["moving_reason"], this.props.state.activeData.crm.enquiry.partyForm.validationErrors)}
                    >
                        <CRMDropdownComponent
                            options={enumToIDropdownOptions(
                                PartyMovingReason.values,
                                PartyMovingReason.fromValueToDisplayName,
                            )}
                            value={this.props.state.activeData.crm.enquiry.partyForm.edit.moving_reason}
                            onChange={(value) => this.props.dispatch({
                                type: "CRM_ENQUIRY_PARTY_INPUT_CHANGED",
                                payload: {
                                    key: "moving_reason",
                                    value,
                                },
                            })}
                            displayError={JsonError1.doTargetKeysHaveInnerErrors(["moving_reason"], this.props.state.activeData.crm.enquiry.partyForm.validationErrors)}
                        />
                    </CRMInputLabelAndErrorWrapComponent>
                    <CRMSpacer size="medium" />
                    <CRMInputLabelAndErrorWrapComponent
                        label="Purchasing situation"
                        displayError={JsonError1.doTargetKeysHaveInnerErrors(["purchasing_situation"], this.props.state.activeData.crm.enquiry.partyForm.validationErrors)}
                        errorMessage={JsonError1.getInnerErrorsForTargetKeysErrorMessageString(["purchasing_situation"], this.props.state.activeData.crm.enquiry.partyForm.validationErrors)}
                    >
                        <CRMDropdownComponent
                            options={enumToIDropdownOptions(
                                PartyPurchasingSituation.values,
                                PartyPurchasingSituation.fromValueToDisplayName,
                            )}
                            value={this.props.state.activeData.crm.enquiry.partyForm.edit.purchasing_situation}
                            onChange={(value) => this.props.dispatch({
                                type: "CRM_ENQUIRY_PARTY_INPUT_CHANGED",
                                payload: {
                                    key: "purchasing_situation",
                                    value,
                                },
                            })}
                            displayError={JsonError1.doTargetKeysHaveInnerErrors(["purchasing_situation"], this.props.state.activeData.crm.enquiry.partyForm.validationErrors)}
                        />
                    </CRMInputLabelAndErrorWrapComponent>
                    <CRMSpacer size="medium" />
                    <CRMInputLabelAndErrorWrapComponent
                        label="Situation notes"
                        displayError={JsonError1.doTargetKeysHaveInnerErrors(["situation_notes"], this.props.state.activeData.crm.enquiry.partyForm.validationErrors)}
                        errorMessage={JsonError1.getInnerErrorsForTargetKeysErrorMessageString(["situation_notes"], this.props.state.activeData.crm.enquiry.partyForm.validationErrors)}
                    >
                        <CRMTextAreaComponent
                            value={this.props.state.activeData.crm.enquiry.partyForm.edit.situation_notes}
                            displayError={JsonError1.doTargetKeysHaveInnerErrors(["situation_notes"], this.props.state.activeData.crm.enquiry.partyForm.validationErrors)}
                            onChange={(value) => this.props.dispatch({
                                type: "CRM_ENQUIRY_PARTY_INPUT_CHANGED",
                                payload: {
                                    key: "situation_notes",
                                    value,
                                },
                            })}
                        />
                    </CRMInputLabelAndErrorWrapComponent>
                    <CRMSpacer size="medium" />
                    <CRMInputLabelAndErrorWrapComponent
                        label="Selling property"
                        displayError={JsonError1.doTargetKeysHaveInnerErrors(["selling_property_state"], this.props.state.activeData.crm.enquiry.partyForm.validationErrors)}
                        errorMessage={JsonError1.getInnerErrorsForTargetKeysErrorMessageString(["selling_property_state"], this.props.state.activeData.crm.enquiry.partyForm.validationErrors)}
                    >
                        <CRMDropdownComponent
                            options={enumToIDropdownOptions(
                                PartySellingPropertyState.values,
                                PartySellingPropertyState.fromValueToDisplayName,
                            )}
                            value={this.props.state.activeData.crm.enquiry.partyForm.edit.selling_property_state}
                            onChange={(value) => this.props.dispatch({
                                type: "CRM_ENQUIRY_PARTY_INPUT_CHANGED",
                                payload: {
                                    key: "selling_property_state",
                                    value,
                                },
                            })}
                            displayError={JsonError1.doTargetKeysHaveInnerErrors(["selling_property_state"], this.props.state.activeData.crm.enquiry.partyForm.validationErrors)}
                            optionOpensForm={true}
                            formOptionIsSelected={areListingFormsVisible(this.props.state.activeData.crm.enquiry.partyForm.edit.selling_property_state)}
                        />
                    </CRMInputLabelAndErrorWrapComponent>
                </CRMAutosaveIndicatorWrap>

                <CRMSpacer size="medium" />
                {/* PARTY LISTING - PARTY HAS PROPERTY TO SELL */}
                {areListingFormsVisible(this.props.state.activeData.crm.enquiry.partyForm.edit.selling_property_state) && <CRMFormButtonIconWithLabel
                    label="Add property to sell"
                    icon="house"
                    ButtonElement={(props) => <CRMButtonIcon variant="primary" {...props} />}
                    onClick={() => this.props.dispatch({
                        type: "CRM_ENQUIRY_ADD_PROPERTY_TO_SELL",
                    })}
                    formStatus={this.props.state.activeData.crm.enquiry.createListingForm.status}
                />}
                <CRMAutosaveIndicatorWrap status={
                    pipe(
                        TFormStatus.reduceToHighestPriority(pipe(
                            this.props.state.activeData.crm.enquiry.partiesListings.forms,
                            array.map((form) => form.status),
                        )),
                        (status) => status === "hidden" ? "untouched"
                            : status,
                    )
                }>
                    { pipe(
                        this.props.state.activeData.crm.enquiry.partiesListings.forms,
                        array.mapWithIndex((i, form) =>
                            <div key={i}>
                                <CRMSpacer size="large" />
                                <CRMCollapsibleSectionComponent
                                    title={ pipe(
                                        {
                                            ...form.view,
                                            ...form.edit,
                                        },
                                        DisplayAddress.fromListing3,
                                        (da) =>
                                            DisplayAddress.isFilled(da)
                                                ? DisplayAddress.toShortString(da)
                                                : "Address not complete"
                                    )}
                                    isOpen={form.ui.collapsibleSectionIsOpen}
                                    onOpenChange={() => this.props.dispatch({
                                        type: "CRM_ENQUIRY_LISTING_COLLAPSIBLE_CLICKED",
                                        payload: {
                                            resourceId: form.view.id,
                                        },
                                    })}
                                >
                                    <CRMSpacer size="large" />
                                    <CRMEnquiryEditListingAddressComponent
                                        listing={{
                                            ...form.view,
                                            ...form.edit,
                                        }}
                                        validationErrors={form.validationErrors}
                                        onChange={(payload) => this.props.dispatch({
                                            type: "CRM_ENQUIRY_LISTING_ADDRESS_CHANGED",
                                            payload: {
                                                resourceId: form.view.id,
                                                value: payload,
                                            },
                                        })}
                                        onAutocompleteClicked={() => this.props.dispatch({
                                            type: "CRM_ENQUIRY_LISTING_ADDRESS_SEARCH",
                                            payload: {
                                                resourceId: form.view.id,
                                            },
                                        })}
                                    />
                                    <CRMSpacer size="large" />
                                    {areListingFormsVisible(this.props.state.activeData.crm.enquiry.partyForm.edit.selling_property_state) &&
                                    /* PARTY'S PROPERTY IS LISTED WITH COMPETING AGENT */
                                    <div>
                                        <CRMTitleSubSection>
                                            Property to sell - Competing agent
                                        </CRMTitleSubSection>
                                        <CRMSpacer size="medium" />
                                        <CRMInputLabelAndErrorWrapComponent
                                            label="Which agent has this property listed"
                                            displayError={JsonError1.doTargetKeysHaveInnerErrors(["competitor_agent_name"], form.validationErrors)}
                                            errorMessage={JsonError1.getInnerErrorsForTargetKeysErrorMessageString(["competitor_agent_name"], form.validationErrors)}
                                        >
                                            <CRMInputGeneralComponent
                                                inputType="text"
                                                value={form.edit.competitor_agent_name}
                                                displayError={JsonError1.doTargetKeysHaveInnerErrors(["competitor_agent_name"], form.validationErrors)}
                                                onChange={(value) => this.props.dispatch({
                                                    type: "CRM_ENQUIRY_LISTING_INPUT_CHANGED",
                                                    payload: {
                                                        key: "competitor_agent_name",
                                                        resourceId: form.view.id,
                                                        value,
                                                    },
                                                })}
                                                onPressEnterKey={() => undefined}
                                            />
                                        </CRMInputLabelAndErrorWrapComponent>
                                        <CRMSpacer size="medium" />
                                        <CRMInputLabelAndErrorWrapComponent
                                            label="Approximate date listed/instructed with other agent"
                                            displayError={JsonError1.doTargetKeysHaveInnerErrors(["competitor_agent_instructed_at"], form.validationErrors)}
                                            errorMessage={JsonError1.getInnerErrorsForTargetKeysErrorMessageString(["competitor_agent_instructed_at"], form.validationErrors)}
                                        >
                                            <CRMInputCalendarComponent
                                                dateType="date"
                                                value={form.edit.competitor_agent_instructed_at === null ? "" : form.edit.competitor_agent_instructed_at}
                                                displayError={JsonError1.doTargetKeysHaveInnerErrors(["competitor_agent_instructed_at"], form.validationErrors)}
                                                onChange={(value) => this.props.dispatch({
                                                    type: "CRM_ENQUIRY_LISTING_INPUT_CHANGED",
                                                    payload: {
                                                        key: "competitor_agent_instructed_at",
                                                        resourceId: form.view.id,
                                                        value: value === "" ? null : value,
                                                    },
                                                })}
                                                onPressEnterKey={() => undefined}
                                            />
                                        </CRMInputLabelAndErrorWrapComponent>
                                        <CRMSpacer size="medium" />
                                        <CRMInputLabelAndErrorWrapComponent
                                            label="What is it on the market for?"
                                            displayError={JsonError1.doTargetKeysHaveInnerErrors(["competitor_agent_market_price"], form.validationErrors)}
                                            errorMessage={JsonError1.getInnerErrorsForTargetKeysErrorMessageString(["competitor_agent_market_price"], form.validationErrors)}
                                        >
                                            <CRMInputCurrency
                                                currencySymbol="£"
                                                value={form.edit.competitor_agent_market_price}
                                                displayError={JsonError1.doTargetKeysHaveInnerErrors(["competitor_agent_market_price"], form.validationErrors)}
                                                onChange={(value) => this.props.dispatch({
                                                    type: "CRM_ENQUIRY_LISTING_INPUT_CHANGED",
                                                    payload: {
                                                        key: "competitor_agent_market_price",
                                                        resourceId: form.view.id,
                                                        value,
                                                    },
                                                })}
                                                onPressEnterKey={() => undefined}
                                            />
                                        </CRMInputLabelAndErrorWrapComponent>
                                        <CRMSpacer size="medium" />
                                        <CRMInputLabelAndErrorWrapComponent
                                            label="How is the other agent doing?"
                                            displayError={JsonError1.doTargetKeysHaveInnerErrors(["competitor_agent_listing_status"], form.validationErrors)}
                                            errorMessage={JsonError1.getInnerErrorsForTargetKeysErrorMessageString(["competitor_agent_listing_status"], form.validationErrors)}
                                        >
                                            <CRMTextAreaComponent
                                                value={form.edit.competitor_agent_listing_status}
                                                displayError={JsonError1.doTargetKeysHaveInnerErrors(["competitor_agent_listing_status"], form.validationErrors)}
                                                onChange={(value) => this.props.dispatch({
                                                    type: "CRM_ENQUIRY_LISTING_INPUT_CHANGED",
                                                    payload: {
                                                        key: "competitor_agent_listing_status",
                                                        resourceId: form.view.id,
                                                        value,
                                                    },
                                                })}
                                            />
                                        </CRMInputLabelAndErrorWrapComponent>
                                        {/* PARTY CUSTOMER NEEDS */}
                                        <CRMSpacer size="medium" />
                                        <CRMTitleSubSection>
                                            Property to sell - Customer needs
                                        </CRMTitleSubSection>
                                        <CRMSpacer size="medium" />
                                        <CRMInputLabelAndErrorWrapComponent
                                            label="Valuation type"
                                            displayError={JsonError1.doTargetKeysHaveInnerErrors(["valuation_type"], form.validationErrors)}
                                            errorMessage={JsonError1.getInnerErrorsForTargetKeysErrorMessageString(["valuation_type"], form.validationErrors)}
                                        >
                                            <CRMDropdownComponent
                                                options={enumToIDropdownOptions(
                                                    ListingValuationType.values,
                                                    ListingValuationType.fromValueToDisplayName,
                                                )}
                                                value={form.edit.valuation_type}
                                                displayError={JsonError1.doTargetKeysHaveInnerErrors(["valuation_type"], form.validationErrors)}
                                                onChange={(value) => this.props.dispatch({
                                                    type: "CRM_ENQUIRY_LISTING_INPUT_CHANGED",
                                                    payload: {
                                                        key: "valuation_type",
                                                        resourceId: form.view.id,
                                                        value,
                                                    },
                                                })}
                                            />
                                        </CRMInputLabelAndErrorWrapComponent>
                                        <CRMSpacer size="medium" />
                                        <CRMInputLabelAndErrorWrapComponent
                                            label="Valuation date and time"
                                            displayError={JsonError1.doTargetKeysHaveInnerErrors(["valuation_date"], form.validationErrors)}
                                            errorMessage={JsonError1.getInnerErrorsForTargetKeysErrorMessageString(["valuation_date"], form.validationErrors)}
                                        >
                                            <CRMInputCalendarComponent
                                                dateType="datetime-local"
                                                value={form.edit.valuation_date === null ? "" : form.edit.valuation_date}
                                                displayError={JsonError1.doTargetKeysHaveInnerErrors(["valuation_date"], form.validationErrors)}
                                                onChange={(value) => this.props.dispatch({
                                                    type: "CRM_ENQUIRY_LISTING_INPUT_CHANGED",
                                                    payload: {
                                                        key: "valuation_date",
                                                        resourceId: form.view.id,
                                                        value: value === "" ? null : value,
                                                    },
                                                })}
                                                onPressEnterKey={() => undefined}
                                            />
                                        </CRMInputLabelAndErrorWrapComponent>
                                        <CRMSpacer size="medium" />
                                        <CRMInputLabelAndErrorWrapComponent
                                            label="When do you need to sell the property by?"
                                            displayError={JsonError1.doTargetKeysHaveInnerErrors(["seller_latest_desired_sell_date"], form.validationErrors)}
                                            errorMessage={JsonError1.getInnerErrorsForTargetKeysErrorMessageString(["seller_latest_desired_sell_date"], form.validationErrors)}
                                        >
                                            <CRMInputCalendarComponent
                                                dateType="date"
                                                value={form.edit.seller_latest_desired_sell_date === null ? "" : form.edit.seller_latest_desired_sell_date}
                                                displayError={JsonError1.doTargetKeysHaveInnerErrors(["seller_latest_desired_sell_date"], form.validationErrors)}
                                                onChange={(value) => this.props.dispatch({
                                                    type: "CRM_ENQUIRY_LISTING_INPUT_CHANGED",
                                                    payload: {
                                                        key: "seller_latest_desired_sell_date",
                                                        resourceId: form.view.id,
                                                        value: value === "" ? null : value,
                                                    },
                                                })}
                                                onPressEnterKey={() => undefined}
                                            />
                                        </CRMInputLabelAndErrorWrapComponent>
                                        <CRMSpacer size="medium" />
                                        <CRMInputLabelAndErrorWrapComponent
                                            label="What's the minimum amount to sell the property for?"
                                            displayError={JsonError1.doTargetKeysHaveInnerErrors(["seller_minimum_desired_sell_value"], form.validationErrors)}
                                            errorMessage={JsonError1.getInnerErrorsForTargetKeysErrorMessageString(["seller_minimum_desired_sell_value"], form.validationErrors)}
                                        >
                                            <CRMInputCurrency
                                                value={form.edit.seller_minimum_desired_sell_value === null ? null : form.edit.seller_minimum_desired_sell_value}
                                                currencySymbol="£"
                                                displayError={JsonError1.doTargetKeysHaveInnerErrors(["seller_minimum_desired_sell_value"], form.validationErrors)}
                                                onChange={(value) => this.props.dispatch({
                                                    type: "CRM_ENQUIRY_LISTING_INPUT_CHANGED",
                                                    payload: {
                                                        key: "seller_minimum_desired_sell_value",
                                                        resourceId: form.view.id,
                                                        value,
                                                    },
                                                })}
                                                onPressEnterKey={() => undefined}
                                            />
                                        </CRMInputLabelAndErrorWrapComponent>
                                        <CRMSpacer size="medium" />
                                        <CRMInputLabelAndErrorWrapComponent
                                            label="Specific needs wanted from an agent"
                                            displayError={JsonError1.doTargetKeysHaveInnerErrors(["seller_specfic_agent_needs"], form.validationErrors)}
                                            errorMessage={JsonError1.getInnerErrorsForTargetKeysErrorMessageString(["seller_specfic_agent_needs"], form.validationErrors)}
                                        >
                                            <CRMTextAreaComponent
                                                value={form.edit.seller_specfic_agent_needs}
                                                displayError={JsonError1.doTargetKeysHaveInnerErrors(["seller_specfic_agent_needs"], form.validationErrors)}
                                                onChange={(value) => this.props.dispatch({
                                                    type: "CRM_ENQUIRY_LISTING_INPUT_CHANGED",
                                                    payload: {
                                                        key: "seller_specfic_agent_needs",
                                                        resourceId: form.view.id,
                                                        value,
                                                    },
                                                })}
                                            />
                                        </CRMInputLabelAndErrorWrapComponent>
                                        <CRMSpacer size="medium" />
                                        {/* PARTY - PROPERTY TO SELL INTERIOR & CONDITION */}
                                        <CRMTitleSubSection>
                                            Property to sell - Interior and Condition
                                        </CRMTitleSubSection>
                                        <CRMSpacer size="medium" />
                                        <CRMColumns
                                            padding="medium"
                                            alignItems="center"
                                            wrapOnMobile={false}
                                            columns={[
                                                {
                                                    flex: 1,
                                                    content: <CRMInputLabelAndErrorWrapComponent
                                                        label="Bedrooms"
                                                        displayError={JsonError1.doTargetKeysHaveInnerErrors(["bedroom_count"], form.validationErrors)}
                                                        errorMessage={JsonError1.getInnerErrorsForTargetKeysErrorMessageString(["bedroom_count"], form.validationErrors)}
                                                    >
                                                        <CRMInputGeneralComponent
                                                            inputType="numericString"
                                                            value={form.edit.bedroom_count?.toString() ?? ""}
                                                            displayError={JsonError1.doTargetKeysHaveInnerErrors(["bedroom_count"], form.validationErrors)}
                                                            onChange={(value) => this.props.dispatch({
                                                                type: "CRM_ENQUIRY_LISTING_INPUT_CHANGED",
                                                                payload: {
                                                                    key: "bedroom_count",
                                                                    resourceId: form.view.id,
                                                                    value: value === "" ? null : Number(value),
                                                                },
                                                            })}
                                                            onPressEnterKey={() => undefined}
                                                        />
                                                    </CRMInputLabelAndErrorWrapComponent>,
                                                },
                                                {
                                                    flex: 1,
                                                    content: <CRMInputLabelAndErrorWrapComponent
                                                        label="Bathrooms"
                                                        displayError={JsonError1.doTargetKeysHaveInnerErrors(["bathroom_count"], form.validationErrors)}
                                                        errorMessage={JsonError1.getInnerErrorsForTargetKeysErrorMessageString(["bathroom_count"], form.validationErrors)}
                                                    >
                                                        <CRMInputGeneralComponent
                                                            inputType="numericString"
                                                            value={form.edit.bathroom_count?.toString() ?? ""}
                                                            displayError={JsonError1.doTargetKeysHaveInnerErrors(["bathroom_count"], form.validationErrors)}
                                                            onChange={(value) => this.props.dispatch({
                                                                type: "CRM_ENQUIRY_LISTING_INPUT_CHANGED",
                                                                payload: {
                                                                    key: "bathroom_count",
                                                                    resourceId: form.view.id,
                                                                    value: value === "" ? null : Number(value),
                                                                },
                                                            })}
                                                            onPressEnterKey={() => undefined}
                                                        />
                                                    </CRMInputLabelAndErrorWrapComponent>,
                                                },
                                            ]}
                                        />
                                        <CRMSpacer size="medium" />
                                        <CRMInputLabelAndErrorWrapComponent
                                            label="Condition"
                                            displayError={JsonError1.doTargetKeysHaveInnerErrors(["condition"], form.validationErrors)}
                                            errorMessage={JsonError1.getInnerErrorsForTargetKeysErrorMessageString(["condition"], form.validationErrors)}
                                        >
                                            <CRMDropdownComponent
                                                options={enumToIDropdownOptions(
                                                    ListingCondition1.values,
                                                    ListingCondition1.toDisplayName,
                                                )}
                                                value={form.edit.condition === null ? "unknown" : form.edit.condition}
                                                displayError={JsonError1.doTargetKeysHaveInnerErrors(["condition"], form.validationErrors)}
                                                onChange={(value) => this.props.dispatch({
                                                    type: "CRM_ENQUIRY_LISTING_INPUT_CHANGED",
                                                    payload: {
                                                        key: "condition",
                                                        resourceId: form.view.id,
                                                        value,
                                                    },
                                                })}
                                            />
                                        </CRMInputLabelAndErrorWrapComponent>
                                        <CRMSpacer size="medium" />
                                        <CRMInputLabelAndErrorWrapComponent
                                            label="Changes made to the property since moving in (electrics, heating, windows, roof etc)"
                                            displayError={JsonError1.doTargetKeysHaveInnerErrors(["significant_renovations_made"], form.validationErrors)}
                                            errorMessage={JsonError1.getInnerErrorsForTargetKeysErrorMessageString(["significant_renovations_made"], form.validationErrors)}
                                        >
                                            <CRMTextAreaComponent
                                                value={form.edit.significant_renovations_made}
                                                displayError={JsonError1.doTargetKeysHaveInnerErrors(["significant_renovations_made"], form.validationErrors)}
                                                onChange={(value) => this.props.dispatch({
                                                    type: "CRM_ENQUIRY_LISTING_INPUT_CHANGED",
                                                    payload: {
                                                        key: "significant_renovations_made",
                                                        resourceId: form.view.id,
                                                        value,
                                                    },
                                                })}
                                            />
                                        </CRMInputLabelAndErrorWrapComponent>
                                        <CRMSpacer size="medium" />
                                        {/* PARTY - PROPERTY TO SELL CIRCUMSTANCES AND NOTES */}
                                        <CRMTitleSubSection>
                                            Property to sell - Circumstances and notes
                                        </CRMTitleSubSection>
                                        <CRMSpacer size="medium" />
                                        <CRMInputLabelAndErrorWrapComponent
                                            label="Property notes"
                                            displayError={JsonError1.doTargetKeysHaveInnerErrors(["admin_notes"], form.validationErrors)}
                                            errorMessage={JsonError1.getInnerErrorsForTargetKeysErrorMessageString(["admin_notes"], form.validationErrors)}
                                        >
                                            <CRMTextAreaComponent
                                                value={form.edit.admin_notes}
                                                displayError={JsonError1.doTargetKeysHaveInnerErrors(["admin_notes"], form.validationErrors)}
                                                onChange={(value) => this.props.dispatch({
                                                    type: "CRM_ENQUIRY_LISTING_INPUT_CHANGED",
                                                    payload: {
                                                        key: "admin_notes",
                                                        resourceId: form.view.id,
                                                        value,
                                                    },
                                                })}
                                            />
                                        </CRMInputLabelAndErrorWrapComponent>
                                        <CRMSpacer size="medium" />
                                        <CRMInputLabelAndErrorWrapComponent
                                            label="Reason for sale"
                                            displayError={JsonError1.doTargetKeysHaveInnerErrors(["reason_for_sale"], form.validationErrors)}
                                            errorMessage={JsonError1.getInnerErrorsForTargetKeysErrorMessageString(["reason_for_sale"], form.validationErrors)}
                                        >
                                            <CRMDropdownComponent
                                                options={enumToIDropdownOptions(
                                                    ListingReasonForSale1.values,
                                                    ListingReasonForSale1.fromValueToDisplayName,
                                                )}
                                                value={form.edit.reason_for_sale}
                                                displayError={JsonError1.doTargetKeysHaveInnerErrors(["reason_for_sale"], form.validationErrors)}
                                                onChange={(value) => this.props.dispatch({
                                                    type: "CRM_ENQUIRY_LISTING_INPUT_CHANGED",
                                                    payload: {
                                                        key: "reason_for_sale",
                                                        resourceId: form.view.id,
                                                        value,
                                                    },
                                                })}
                                            />
                                        </CRMInputLabelAndErrorWrapComponent>
                                        <CRMSpacer size="medium" />
                                    </div>
                                }
                                </CRMCollapsibleSectionComponent>
                            </div>
                        ),
                    )}
                </CRMAutosaveIndicatorWrap>
            </div>
        );
    }
}

export default CRMEnquiryAboutThisPartyContainer;
