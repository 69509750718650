import React from "react";
import { CRMDeferPopout } from "../CrmDeferPopout/CRMDeferPopout";
import { useOpenClose } from "../../hooks/UseOpenClose";
import { DateTime } from "luxon";
import { CRMParagraph } from "../Simple/CRMParagraph/CRMParagraph";
import { WeightBold } from "../WeightBold/WeightBold";
import { CRMIcon } from "../CRMIcon/CRMIcon";
import { CRMSpacingRow } from "../CRMSpacingRow/CRMSpacingRow";
import { CursorPointer } from "../CursorPointer/CursorPointer";
import { CRMColors } from "../../models/CRMColors";
import { TextColour } from "../TextColour/TextColour";
import { isInvalidDate } from "../../../../shared/src/util";
import { getAmountOfTimeInFutureFromNow } from "../CRMDeferButtonComponent/CRMDeferButtonComponent";

type TCRMDeferButtonComponentProps = {
    label: JSX.Element;
    value: string | null;
    onChange: (date: string | null) => void;
    displayError: boolean;
};

export const CRMSimpleDeferButtonComponent = (props: TCRMDeferButtonComponentProps): JSX.Element => {
    const {
        ref,
        isOpen,
        setIsOpen,
    } = useOpenClose();

    const dateTime = DateTime.fromISO(props.value || "");

    return (
        <div className={`crm-simple-defer-button-component`}>
            <div 
                className="crm-simple-defer-button-component__container"
                onClick={() => setIsOpen(!isOpen)}
            >
                <CursorPointer>
                    <TextColour colour={CRMColors.NEUTRAL_INK}>
                        {isInvalidDate(props.value) &&
                            <CRMSpacingRow>
                                {props.label}
                                <CRMParagraph>
                                    <WeightBold>Set defer date</WeightBold>
                                </CRMParagraph>
                                <CRMIcon
                                    size="tiny" 
                                    iconName={isOpen ? "arrow-up" : "arrow-down"}
                                    colour="neutral-ink"
                                />
                            </CRMSpacingRow>
                        }

                        {!isInvalidDate(props.value) &&
                            <CRMSpacingRow>
                                {props.label}
                                <CRMParagraph>
                                    <WeightBold>{getAmountOfTimeInFutureFromNow(dateTime)}</WeightBold>
                                </CRMParagraph>
                                <div>
                                    <CRMIcon 
                                        size="tiny" 
                                        iconName={isOpen ? "arrow-up" : "arrow-down"}
                                        colour="neutral-ink"
                                    />
                                </div>
                                <div onClick={(e) => {
                                    e.stopPropagation();
                                    props.onChange(null)
                                }}>
                                    <CRMIcon size="tiny" iconName="delete" colour="neutral-ink" />
                                </div>
                            </CRMSpacingRow>
                        }
                    </TextColour>
                </CursorPointer>
            </div>

            { isOpen &&
                <div 
                    ref={ref}
                    className="crm-simple-defer-button-component__popout"
                >
                    <CRMDeferPopout onChange={(date) => {
                        props.onChange(date);
                        setIsOpen(false);
                    }} />
                </div>
            }
        </div>
    );
};
