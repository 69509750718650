import React from "react";
import { CRMCardOutsideLabelled } from "./../../components/CRMCardOutsideLabelled/CRMCardOutsideLabelled";
import { CRMFontSizes } from "../../models/CRMFontSizes";
import { WeightBold } from "./../../components/WeightBold/WeightBold";
import { WeightRegular } from "./../../components/WeightRegular/WeightRegular";
import { CRMSpacing } from "../../models/CRMSpacing";
import { SpacingColumn } from "../../components/BuildingBlocks/SpacingColumn";
import { Text } from "../../components/BuildingBlocks/Text";
import { FontQuicksand } from "../../components/BuildingBlocks/FontQuicksand";

type TCRMListingIntroducerRefNotesCardProps = {
    introducerRef: string;
    introducerNotes: string;
    dateOfDeath?: string;
};

export const CRMListingIntroducerRefNotesCard = (props: TCRMListingIntroducerRefNotesCardProps): JSX.Element => 
    <CRMCardOutsideLabelled
        labelColor="highlights-calming-pink-6"
        shadow={false}
    >
        <SpacingColumn spacing={CRMSpacing.MEDIUM}>
            {/* INTRODUCER REF */}
            <FontQuicksand>
                <Text size={CRMFontSizes.SMALL}>
                    <WeightBold>Their case ref: </WeightBold>
                    <WeightRegular>{props.introducerRef === "" ? "Not set" : props.introducerRef}</WeightRegular> 
                </Text>
            </FontQuicksand>

            {/* DECEASED DATE OF DEATH */}
            <FontQuicksand>
                <Text size={CRMFontSizes.SMALL}>
                    <WeightBold>Deceased date of death: </WeightBold>
                    <WeightRegular>{props.dateOfDeath ?? "Not set"}</WeightRegular> 
                </Text>
            </FontQuicksand>

            {/* INTRODUCER NOTES */}
            {props.introducerNotes !== "" &&
                <SpacingColumn spacing={CRMSpacing.TINY}>
                    <FontQuicksand>
                        <Text size={CRMFontSizes.SMALL}>
                            <WeightBold>Their notes on this case:</WeightBold>
                        </Text>
                    </FontQuicksand>
                    <FontQuicksand>
                        <Text size={CRMFontSizes.SMALL}>
                            <WeightRegular>{props.introducerNotes}</WeightRegular>
                        </Text>
                    </FontQuicksand>
                </SpacingColumn>
            }
        </SpacingColumn>
    </CRMCardOutsideLabelled>
