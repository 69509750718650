import React from "react";
import { FrontSpacing } from "../../../../models/FrontSpacing";
import { Background } from "../../../BuildingBlocks/Background";
import { Padding } from "../../../BuildingBlocks/Padding";
import { CursorPointer } from "../../../CursorPointer/CursorPointer";
import { FrontIcon, TIcon } from "../../../Front/Simple/FrontIcon/FrontIcon";
import { FrontColors } from "../../../../models/FrontColors";

export const FPInputButton = (props: {
    iconName: TIcon,
    coloursInverted?: boolean,
    onClick: () => void,
}): JSX.Element => {
    const coloursInverted = props.coloursInverted || false;

    const backgroundColour =
        coloursInverted
            ? FrontColors.PROBATE_0
            : FrontColors.NEUTRAL_18;

    const borderColour =
        coloursInverted
            ? FrontColors.NEUTRAL_18
            : FrontColors.PROBATE_0;

    const iconColour =
        coloursInverted
            ? "neutral-18"
            : "neutral-2";
            
    return <Background
        borderRadius="0px 5px 5px 0px"
        borderDefinition={`solid 2px ${borderColour}`}
        color={backgroundColour}
    >
        <CursorPointer onClick={props.onClick}>
            <Padding spacing={FrontSpacing.SMALL_2}>
                {/* ICON */}
                <FrontIcon
                    iconName={props.iconName}
                    size="medium"
                    colour={iconColour}
                />
            </Padding>
        </CursorPointer>
    </Background>;
};
