import React from "react";
import { TContainerStateProps } from "./../../state/TContainerStateProps";
import { CRMSpacingColumn } from "../../components/CRMSpacingColumn/CRMSpacingColumn";
import { CRMTitleSection } from "../../components/CRMTitleSection/CRMTitleSection";
import { CRMSpacer } from "../../components/CRMSpacer/CRMSpacer";
import { CRMAutosaveIndicatorWrap } from "../../components/CRM/CRMAutosaveIndicatorWrap/CRMAutosaveIndicatorWrap";
import { CRMCodecEditForm } from "../../components/CRMCodecEditForm/CRMCodecEditForm";
import { CRMCaseOtherSideMembersForm } from "../../components/CRMCaseOtherSideMembersForm/CRMCaseOtherSideMembersForm";
import { pipe } from "fp-ts/lib/function";
import { array } from "fp-ts";
import { FormStatus_highestPriority } from "../../../../shared/src/codecs/codec";
import { CRMPadding } from "../../components/Simple/CRMPadding/CRMPadding";
import { CRMTitleForCard } from "../../components/CRMTitleForCard/CRMTitleForCard";
import { CRMSpacingRow } from "../../components/CRMSpacingRow/CRMSpacingRow";
import { CRMButtonSecondary } from "../../components/CRMButtonSecondary/CRMButtonSecondary";
import { snakeCaseToCopyText } from "../../../../shared/src/util";
import { CRMGenericEditModalWithText } from "../../components/CRMGenericEditModalWithText/CRMGenericEditModalWithText";
import { CRMInputLabelAndErrorWrapComponent } from "../../components/CRMInputLabelAndErrorWrapComponent/CRMInputLabelAndErrorWrapComponent";
import { CRMLink } from "../../components/CRMLink/CRMLink";
import { CaseOtherSideClientDetails, CaseOtherSideCompany, CaseOtherSideCompanyIsOverseas, CaseOtherSideCompanyOverseasDetails, CasesOtherSideCompanyAddess } from "../../../../domain/codecs/CasesContractBlock";

const CRMLegalCaseDetailsOtherSideClientContainer = (props: TContainerStateProps): JSX.Element => (
    <CRMAutosaveIndicatorWrap
        status={pipe(
            [
                props.state.forms.contracts_block.children.details.status,
                ...pipe(
                    props.state.forms.contracts_block.children.other_side_members,
                    array.map((form) => form.status),
                ),
            ],
            FormStatus_highestPriority,
        )}
    >
        <CRMTitleSection>
            Other Side's Client Details
        </CRMTitleSection>
        <CRMSpacer size="large" />
        <CRMSpacingColumn spacing="medium">
            {/* COMPANY NAME & JOB DEFER */}
            <CRMCodecEditForm
                codec={CaseOtherSideClientDetails}
                model={props.state.forms.contracts_block.children.details.edited}
                validationErrors={props.state.forms.contracts_block.children.details.validationErrors}
                onChange={(edited) => props.dispatch({
                    type: "LEGAL_CASE_CONTRACTS_BLOCK_CHANGE",
                    payload: {
                        ...props.state.forms.contracts_block.children.details,
                        edited: {
                            ...props.state.forms.contracts_block.children.details.edited,
                            ...edited,
                        }
                    }
                })}
                columns={2}
                showDeferDateLabels={true}
            />

            <CRMCodecEditForm
                codec={CaseOtherSideCompany}
                model={props.state.forms.contracts_block.children.details.edited}
                validationErrors={props.state.forms.contracts_block.children.details.validationErrors}
                onChange={(edited) => props.dispatch({
                    type: "LEGAL_CASE_CONTRACTS_BLOCK_CHANGE",
                    payload: {
                        ...props.state.forms.contracts_block.children.details,
                        edited: {
                            ...props.state.forms.contracts_block.children.details.edited,
                            ...edited,
                        }
                    }
                })}
                columns={2}
                showDeferDateLabels={true}
            />

            {props.state.forms.contracts_block.children.details.edited.other_side_company_name !== "" && <CRMSpacingRow spacing="medium">
                <CRMLink target="_blank" href={`https://find-and-update.company-information.service.gov.uk/search?q=${encodeURIComponent(props.state.forms.contracts_block.children.details.edited.other_side_company_name)}`} linkStyle="normal">Companies House Search</CRMLink>
                <CRMLink target="_blank" href="https://find-and-update.company-information.service.gov.uk/search" linkStyle="normal">(Plain Link)</CRMLink>
            </CRMSpacingRow>}

            <CRMCodecEditForm
                codec={CaseOtherSideCompanyIsOverseas}
                model={props.state.forms.contracts_block.children.details.edited}
                validationErrors={props.state.forms.contracts_block.children.details.validationErrors}
                onChange={(edited) => props.dispatch({
                    type: "LEGAL_CASE_CONTRACTS_BLOCK_CHANGE",
                    payload: {
                        ...props.state.forms.contracts_block.children.details,
                        edited: {
                            ...props.state.forms.contracts_block.children.details.edited,
                            ...edited,
                        }
                    }
                })}
                columns={2}
                showDeferDateLabels={true}
            />

            {props.state.forms.contracts_block.children.details.edited.other_side_company_is_overseas_for_transfer && <CRMCodecEditForm
                codec={CaseOtherSideCompanyOverseasDetails}
                model={props.state.forms.contracts_block.children.details.edited}
                validationErrors={props.state.forms.contracts_block.children.details.validationErrors}
                onChange={(edited) => props.dispatch({
                    type: "LEGAL_CASE_CONTRACTS_BLOCK_CHANGE",
                    payload: {
                        ...props.state.forms.contracts_block.children.details,
                        edited: {
                            ...props.state.forms.contracts_block.children.details.edited,
                            ...edited,
                        }
                    }
                })}
                columns={1}
                showDeferDateLabels={true}
            />}
        
            <CRMInputLabelAndErrorWrapComponent label="Other Side Company Address">
                <CRMGenericEditModalWithText
                    modalSize="large"
                    text={(() => {
                        const address = [
                            [
                                props.state.forms.contracts_block.children.details.edited.other_side_company_sub_building_name,
                                props.state.forms.contracts_block.children.details.edited.other_side_company_sub_building_number,
                                props.state.forms.contracts_block.children.details.edited.other_side_company_building_name,
                                props.state.forms.contracts_block.children.details.edited.other_side_company_building_number,
                                props.state.forms.contracts_block.children.details.edited.other_side_company_street_name
                            ].filter((s) => s !== "").join(" "),
                            props.state.forms.contracts_block.children.details.edited.other_side_company_locality,
                            props.state.forms.contracts_block.children.details.edited.other_side_company_district,
                            props.state.forms.contracts_block.children.details.edited.other_side_company_city_town,
                            props.state.forms.contracts_block.children.details.edited.other_side_company_postcode,
                            props.state.forms.contracts_block.children.details.edited.other_side_company_county,
                            props.state.forms.contracts_block.children.details.edited.other_side_company_country === "unknown" ?
                            "" : snakeCaseToCopyText(props.state.forms.contracts_block.children.details.edited.other_side_company_country)
                        ].filter((s) => s !== "" && s !== null).join(", ")

                        return address === "" ? "No Address" : address;
                    })()}
                    body={
                        (setIsOpen) => (
                            <CRMPadding size="large">
                                <CRMAutosaveIndicatorWrap
                                    status={props.state.forms.contracts_block.children.details.status}
                                >
                                    <CRMTitleForCard>OTHER SIDE COMPANY ADDRESS</CRMTitleForCard>

                                    <CRMCodecEditForm
                                        codec={CasesOtherSideCompanyAddess}
                                        model={props.state.forms.contracts_block.children.details.edited}
                                        validationErrors={props.state.forms.contracts_block.children.details.validationErrors}
                                        onChange={(edited) => props.dispatch({
                                            type: "LEGAL_CASE_CONTRACTS_BLOCK_CHANGE",
                                            payload: {
                                                ...props.state.forms.contracts_block.children.details,
                                                edited: {
                                                    ...props.state.forms.contracts_block.children.details.edited,
                                                    ...edited,
                                                }
                                            }
                                        })}
                                        columns={2}
                                    />
                                    {/* BUTTONS */}
                                    <CRMSpacingRow justifyContent="end">
                                        <CRMButtonSecondary
                                            label="Close"
                                            onClick={() => setIsOpen(false)}
                                        />
                                    </CRMSpacingRow>
                                </CRMAutosaveIndicatorWrap>
                            </CRMPadding>
                        )
                    }
                />
            </CRMInputLabelAndErrorWrapComponent>
            {/* USERS */}
            <CRMCaseOtherSideMembersForm
                members={props.state.forms.contracts_block.children.other_side_members}
                onAddMember={() => props.dispatch({
                    type: "LEGAL_CASE_CREATE_OTHER_SIDE_MEMBER",
                })}
                addMemberFormStatus={props.state.forms.contracts_block.children.new_other_side_member.status}
                onUpdateMember={(payload) => props.dispatch({
                    type: "LEGAL_CASE_UPDATE_OTHER_SIDE_MEMBER",
                    payload,
                })}
                onDeleteMember={(payload) => props.dispatch({
                    type: "LEGAL_CASE_DELETE_OTHER_SIDE_MEMBER",
                    payload,
                })}
            />
        </CRMSpacingColumn>
    </CRMAutosaveIndicatorWrap>
);

export default CRMLegalCaseDetailsOtherSideClientContainer;