import React from "react";
import { useHover } from "../../../hooks/useHover";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { THexColor } from "../../../models/StringLiterals";
import { Absolute } from "../../BuildingBlocks/Absolute";
import { Background } from "../../BuildingBlocks/Background";
import { Padding } from "../../BuildingBlocks/Padding";
import { Relative } from "../../BuildingBlocks/Relative";
import { CRMIcon, TIcon } from "../../CRMIcon/CRMIcon";
import { CursorPointer } from "../../CursorPointer/CursorPointer";
import { CRMColors } from "../../../models/CRMColors";

export const CRMIconCardPopout = (props: React.PropsWithChildren<{
    iconName: TIcon,
    iconColour?: THexColor,
    iconBackgroundColour: THexColor,
    cardBackgroundColour: THexColor,
}>): JSX.Element => {
    const [ref, isHovering] = useHover<HTMLDivElement>({});

    return (
        <Relative
            width="fit-content"
            divRef={ref}
        >
            {/* ICON */}
            <CursorPointer>
                <Background
                    color={props.iconBackgroundColour}
                    borderRadius="5px"
                >
                    <Padding spacing={CRMSpacing.TINY}>
                        <CRMIcon
                            iconName={props.iconName}
                            size="medium"
                            colour="hex"
                            hexColour={props.iconColour ?? CRMColors.NEUTRAL_INK}
                        />
                    </Padding>
                </Background>
            </CursorPointer>

            {/* CARD */}
            {isHovering &&
                <Absolute
                    right="0"
                    top="46px"
                >
                    <Background
                        borderRadius="5px"
                        color={props.cardBackgroundColour}
                        boxShadowDefinition="2px 2px 3px rgba(0, 0, 0, 0.25)"
                        width="360px"
                    >
                        <Padding spacing={CRMSpacing.MEDIUM}>
                            {props.children}
                        </Padding>
                    </Background>
                </Absolute>
            }
        </Relative>
    );
};
