import { convertToRaw, EditorState,} from "draft-js";
import React, { useEffect, useState } from "react";
import { TSuggestionProfile, TInsertionCoordinate, TActivatedSuggestionProfile, TInsertion, getEmptyActivatedProfile, getEmptyInsertion, getEmptyProfile } from "./Types";

type TUseMatchProps<T> = {
    editorState: EditorState;
    suggestionProfiles: Array<TSuggestionProfile<T>>;
    onChange: (oldMatch: TActivatedSuggestionProfile<T>, newMatch: TActivatedSuggestionProfile<T>) => void;
};

export const useActiveSuggestionProfile = <T extends Object>(props: TUseMatchProps<T>) => {

    const [activeProfile, setActiveProfile] = useState<TActivatedSuggestionProfile<T>>(getEmptyActivatedProfile());

    useEffect(
        () => {
            let newActiveProfile = getNewActiveProfile();
            setActiveProfile(newActiveProfile);
            if (newActiveProfile.name !== "EMPTY") {
                props.onChange(activeProfile, newActiveProfile)
            }
        },
        [props.editorState]
    );

    const hasActiveProfile = (): boolean => activeProfile.name !== "EMPTY";

    const clearActiveProfile = () => setActiveProfile(getEmptyActivatedProfile());

    const findProfilesPrefixInsertionCoordinates = (profile: TSuggestionProfile<T>, contentBlock: Draft.ContentBlock): Array<TInsertionCoordinate> => {
    
        const contentText = contentBlock.getText();
        let regex = new RegExp(String.raw({ raw: `(${profile.prefix})(\\S*)(\\s|$)` }), 'g');
        let insertionCoordinates: Array<TInsertionCoordinate> = [];
        let matchArray: RegExpExecArray | null;
        
        while ((matchArray = regex.exec(contentText)) !== null) {
            insertionCoordinates.push({
                text: matchArray[2],
                start: matchArray.index,
                end: matchArray.index + matchArray[0].trim().length
            });
        }
        return insertionCoordinates;
    }
    
    const getNewActiveProfile = (): TActivatedSuggestionProfile<T> => {
        
        if (isCurrentTextEmpty() || isCurrentSelectionAnEntity()) {
            return getEmptyActivatedProfile();
        }

        const editedInsertion = getInsertionBeingEdited();
        const profile = findInsertionsProfile(editedInsertion);

        if (!doesProfileAllowMoreInsertions(profile)) {
            return getEmptyActivatedProfile();
        }
        
        return {
            ...editedInsertion,
            ...profile,
            suggestions: findProfilesSuggestionsWhichMatchInsertion(profile, editedInsertion)
        };
    }

    const doesProfileAllowMoreInsertions = (profile: TSuggestionProfile<T>): boolean => {
        const insertionCount = getAllEditorEntitiesMadeByThisComponent()
            .filter((entity) => entity.type === profile.name)
            .length;
        return insertionCount < profile.maximumInsertions;
    }

    const getAllEditorEntitiesMadeByThisComponent = () => {
        const rawMapAsArrayObject = convertToRaw(props.editorState.getCurrentContent()).entityMap;
        const rawMap = Object.keys(rawMapAsArrayObject).map((index) => rawMapAsArrayObject[index]);
        return rawMap.filter((entity) => doesEntityTypeMatchProfile(entity.type))
    }

    const doesEntityTypeMatchProfile = (entityType) => getAllProfileNames().findIndex((profileName) => profileName === entityType) > -1;

    const getAllProfileNames = () => props.suggestionProfiles.map((profile) => profile.name); 
    
    const getInsertionBeingEdited = (): TInsertion => {
        const selectionOffsets = getEditorsSelectionOffsets();
        const result = getAllInsertions()
            .find((insertion) => selectionOffsets.start >= insertion.start && selectionOffsets.start <= insertion.end);

        return result || getEmptyInsertion();
    }

    const getAllInsertions = (): Array<TInsertion> => {
        const currentContentBlock = props.editorState.getCurrentContent().getBlockForKey(getEditorsAnchorKey());
        return props.suggestionProfiles
            .reduce(
                (sum, profile) => 
                    findProfilesPrefixInsertionCoordinates(profile, currentContentBlock)
                        .map((insertionCoordinate) => ({ type: profile.name, ...insertionCoordinate }))
                        .concat(sum),
                [] as Array<TInsertion>
            );
    }

    const findInsertionsProfile = (insertion: TInsertion): TSuggestionProfile<T> => {
        if (insertion.start === -1) {
            return getEmptyProfile();
        }
        return props.suggestionProfiles.find(({ name }) => name === insertion.type) || getEmptyProfile();
    }

    const findProfilesSuggestionsWhichMatchInsertion = (profile: TSuggestionProfile<T>, insertion: TInsertion): Array<T> => {
        try {
            return profile
                .onMatch(insertion.text)
                .filter((suggestion) => 
                    profile.hasUniqueInsertions ? 
                        !hasSuggestionBeenInserted(profile, suggestion)
                        : true 
                    )

        } catch(e) {
            return [];
        }
    }

    const hasSuggestionBeenInserted = (profile: TSuggestionProfile<T>, suggestion: T): boolean =>
        getAllInsertionsForProfile(profile.name)
            .filter(({ text }) => text !== "")
            .map(({ text }) => `${profile.prefix}${text}`)
            .indexOf(profile.format(suggestion))
        > -1
    ;

    const getAllInsertionsForProfile = (profileName: string) => getAllInsertions().filter((insertion) => insertion.type === profileName);

    const getEditorsAnchorKey = () => props.editorState.getSelection().getAnchorKey();

    const getEditorsCurrentContentBlock = () => props.editorState.getCurrentContent().getBlockForKey(getEditorsAnchorKey());

    const isCurrentTextEmpty = (): boolean => getEditorsCurrentContentBlock().getText().length === 0;

    const getEditorsSelectionOffsets = () => ({
        start: props.editorState.getSelection().getStartOffset(),
        end: props.editorState.getSelection().getEndOffset(),
    })
      
    const isCurrentSelectionAnEntity = (): boolean => {
        let selectionOffsets = getEditorsSelectionOffsets();
        let currentContentBlock = getEditorsCurrentContentBlock();
        let entityBefore = currentContentBlock.getEntityAt(selectionOffsets.start - 1);
        let entityAfter = currentContentBlock.getEntityAt(selectionOffsets.end);
    
        return entityBefore !== null || entityAfter !== null;
    }

    return {
        activeProfile,
        clearActiveProfile,
        hasActiveProfile
    };
}
