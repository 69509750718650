import React, { CSSProperties } from "react";
import { TCalcSize, THSLColor, THexColor, TPercentageSize, TPixelSize, TPixelSizeShortHand, TViewHeight, TViewWidth } from "../../models/StringLiterals";

type TBackgroundProps = {
    divRef?: React.RefObject<HTMLDivElement>;
    display?: "flex" | "inline-flex";
    position?: CSSProperties["position"],
    color?: THexColor | THSLColor | "transparent";
    borderDefinition?: string;
    borderBottomDefinition?: string;
    borderTopDefinition?: string;
    borderRightDefinition?: string;
    borderLeftDefinition?: string;
    borderRadius?: TPixelSizeShortHand | TPercentageSize;
    boxShadowDefinition?: string;
    padding?: TPixelSizeShortHand;
    minWidth?: TPixelSize | TPercentageSize | "fit-content" | "min-content" | TViewWidth;
    width?: TPixelSize | TPercentageSize | "fit-content" | "min-content" | TViewWidth | TCalcSize;
    height?: TPixelSize | TPercentageSize | TViewHeight | TCalcSize;
    maxHeight?: TPixelSize | TPercentageSize | TViewHeight;
    maxWidth?: TPixelSize | TPercentageSize | TViewWidth;
    minHeight?: TPixelSize | TPercentageSize | TViewHeight;
    backgroundImage?: CSSProperties["backgroundImage"],
    backgroundRepeat?: CSSProperties["backgroundRepeat"],
    backgroundPosition?: CSSProperties["backgroundPosition"],
    backgroundSize?: CSSProperties["backgroundSize"],
    alignItems?: CSSProperties["alignItems"];
    justifyContent?: CSSProperties["justifyContent"];
    cursor?: CSSProperties["cursor"];
    transition?: CSSProperties["transition"];
    top?: TPixelSize,
    left?: TPixelSize,
    marginBottom?: TPixelSize,
    margin?: TPixelSize,
    onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
};

export const Background = (props: React.PropsWithChildren<TBackgroundProps>): JSX.Element => {
    return (
        <div
            ref={props.divRef}
            style={{ 
                display: props.display,
                position: props.position,
                backgroundColor: props.color,
                border: props.borderDefinition,
                borderBottom: props.borderBottomDefinition || props.borderDefinition,
                borderTop: props.borderTopDefinition || props.borderDefinition,
                borderRight: props.borderRightDefinition || props.borderDefinition,
                borderLeft: props.borderLeftDefinition || props.borderDefinition,
                borderRadius: props.borderRadius,
                padding: props.padding,
                boxShadow: props.boxShadowDefinition,
                minWidth: props.minWidth,
                width: props.width,
                height: props.height,
                minHeight: props.minHeight,
                maxHeight: props.maxHeight,
                maxWidth: props.maxWidth,
                backgroundImage: props.backgroundImage,
                backgroundPosition: props.backgroundPosition,
                backgroundRepeat: props.backgroundRepeat,
                backgroundSize: props.backgroundSize,
                alignItems: props.alignItems,
                justifyContent: props.justifyContent,
                cursor: props.cursor,
                transition: props.transition,
                top: props.top,
                left: props.left,
                marginBottom: props.marginBottom,
                margin: props.margin,
            }}
            onClick={props.onClick}
        >
            {props.children}
        </div>
    );
};
