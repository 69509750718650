import React, { useEffect, useState } from "react";
import { FrontSpacing } from "../../../../models/FrontSpacing";
import FrontInputGeneralComponent from "../FrontInputGeneralComponent/FrontInputGeneralComponent";
import FrontInputLabelAndErrorWrapComponent from "../FrontInputLabelAndErrorWrapComponent/FrontInputLabelAndErrorWrapComponent";
import { FrontFormPrimaryButton } from "../FrontFormPrimaryButton/FrontFormPrimaryButton";
import { SpacingColumn } from "../../../BuildingBlocks/SpacingColumn";
import { onChangeForm } from "../../../../../../shared/src/codecs/types/form";
import { FrontDropdown } from "../FrontDropdown/FrontDropdown";
import { Country1 } from "../../../../../../domain/codecs/Country";
import { Country1ToCopyText, isValidPostcode } from "../../../../../../domain/codecs/overloads/Address";
import { isFilled } from "../../../../../../shared/src/util";
import { FrontPostCodeNameNumberInput } from "../FrontPostCodeNameNumberInput/FrontPostCodeNameNumberInput";
import { FrontFormRow } from "../FrontFormRow/FrontFormRow";
import { SpacingRow } from "../../../BuildingBlocks/SpacingRow";
import { WeightRegular } from "../../../WeightRegular/WeightRegular";
import { TClientCaseRelatedTransactionAddressForm } from "../../../../../../domain/codecs/form/ClientCaseForm";
import { doesErrorKeyExist } from "../../../../../../shared/src/codecs/errors";

type TAddressSearchInputProps = {
    form: TClientCaseRelatedTransactionAddressForm;
    onChange: (form: TClientCaseRelatedTransactionAddressForm) => void;
    addressFieldsVisible: boolean;
    displayValidationErrorsIfPresent: boolean;
};

export const FrontRelatedTransactionAddressSearchInput = (props: React.PropsWithChildren<TAddressSearchInputProps>): JSX.Element => {
    const onChange = onChangeForm(props.form, props.onChange);
    const onChangeBuildingNumberOrName = (prop: "building_number" | "building_name") => (value: string) => props.onChange({
        ...props.form,
        edited: {
            ...props.form.edited,
            building_number: prop === "building_number" ? value : "",
            building_name: prop === "building_name" ? value : "",
        }
    });

    const RenderPostCodeNameNumberInput = (): JSX.Element => (
        <FrontPostCodeNameNumberInput
            postcode={props.form.edited.postcode || ""}
            buildingNumber={props.form.edited.building_number}
            buildingName={props.form.edited.building_name}
            onChangePostcode={(value) => onChange("postcode")(value || null)}
            onChangeNumber={onChangeBuildingNumberOrName("building_number")}
            onChangeName={onChangeBuildingNumberOrName("building_name")}
            postcodeValidationError={doesErrorKeyExist("edited.postcode", props.form.validationErrors)}
            displayValidationErrorsIfPresent={props.displayValidationErrorsIfPresent}
        />
    );

    return (
        <div className={`front-address-search-input`}>
            <SpacingColumn spacing={FrontSpacing.MEDIUM_1}>
                <div>
                    {RenderPostCodeNameNumberInput()}
                </div>
                
                {/* OTHER ADDRESS FIELDS */}
                {props.addressFieldsVisible &&
                    <SpacingColumn spacing={FrontSpacing.MEDIUM_1}>
                        {/* SUB NO & SUB NAME */}
                        <SpacingRow
                            spacing={FrontSpacing.MEDIUM_1}
                            childSize="1fr 1fr"
                        >
                            <FrontInputLabelAndErrorWrapComponent label={<WeightRegular>Flat No.</WeightRegular>}>
                                <FrontInputGeneralComponent
                                    inputType="text"
                                    value={props.form.edited.sub_building_number}
                                    placeholder=""
                                    onChange={onChange("sub_building_number")}
                                    displayError={false}
                                />
                            </FrontInputLabelAndErrorWrapComponent>
                            
                            <FrontInputLabelAndErrorWrapComponent label={<WeightRegular>Flat Name</WeightRegular>}>
                                <FrontInputGeneralComponent
                                    inputType="text"
                                    value={props.form.edited.sub_building_name}
                                    placeholder=""
                                    onChange={onChange("sub_building_name")}
                                    displayError={false}
                                />
                            </FrontInputLabelAndErrorWrapComponent>
                        </SpacingRow>
                        
                        {/* STREET & CITY/TOWN */}
                        <FrontFormRow>
                            <FrontInputLabelAndErrorWrapComponent label="Street name" errorMessage="Please enter a street name" displayError={props.displayValidationErrorsIfPresent && props.form.edited.street_name === ""}>
                                <FrontInputGeneralComponent
                                    inputType="text"
                                    value={props.form.edited.street_name}
                                    placeholder=""
                                    onChange={onChange("street_name")}
                                    displayError={props.displayValidationErrorsIfPresent && props.form.edited.street_name === ""}
                                />
                            </FrontInputLabelAndErrorWrapComponent>
                            
                            <FrontInputLabelAndErrorWrapComponent label="City/Town" errorMessage="Please enter a city or town" displayError={props.displayValidationErrorsIfPresent && props.form.edited.city_town === ""}>
                                <FrontInputGeneralComponent
                                    inputType="text"
                                    value={props.form.edited.city_town}
                                    placeholder=""
                                    onChange={onChange("city_town")}
                                    displayError={props.displayValidationErrorsIfPresent && props.form.edited.city_town === ""}
                                />
                            </FrontInputLabelAndErrorWrapComponent>
                        </FrontFormRow>
                        
                        {/* COUNTY & DISTRICT */}
                        <FrontFormRow>
                            <FrontInputLabelAndErrorWrapComponent label="County">
                                <FrontInputGeneralComponent
                                    inputType="text"
                                    value={props.form.edited.county}
                                    placeholder=""
                                    onChange={onChange("county")}
                                    displayError={false}
                                />
                            </FrontInputLabelAndErrorWrapComponent>

                            <FrontInputLabelAndErrorWrapComponent label="District">
                                <FrontInputGeneralComponent
                                    inputType="text"
                                    value={props.form.edited.district}
                                    placeholder=""
                                    onChange={onChange("district")}
                                    displayError={false}
                                />
                            </FrontInputLabelAndErrorWrapComponent>
                        </FrontFormRow>
                        
                        {/* LOCALITY & IF: SUBMIT */}
                        <SpacingRow
                            spacing={FrontSpacing.MEDIUM_1}
                            childSize="1fr 1fr"
                            alignItems="flex-start"
                        >
                            <FrontInputLabelAndErrorWrapComponent label="Locality">
                                <FrontInputGeneralComponent
                                    inputType="text"
                                    value={props.form.edited.locality}
                                    placeholder=""
                                    onChange={onChange("locality")}
                                    displayError={false}
                                />
                            </FrontInputLabelAndErrorWrapComponent>                            

                            <FrontInputLabelAndErrorWrapComponent label="Country" errorMessage="Please select a country" displayError={props.displayValidationErrorsIfPresent && props.form.edited.country === "unknown"}>
                                <FrontDropdown
                                    value={props.form.edited.country}
                                    options={Country1.values.map((value) => ({
                                        value,
                                        label: Country1ToCopyText(value)
                                    }))}
                                    onChange={onChange("country")}
                                />
                            </FrontInputLabelAndErrorWrapComponent>
                        </SpacingRow>

                    </SpacingColumn>
                }
            </SpacingColumn>
        </div>
    );
};