import React from "react";

type TUnderlineProps = {

};

export const Underline = (props: React.PropsWithChildren<TUnderlineProps>): JSX.Element => {

    return (
        <span
            style={{
                textDecoration: "underline",
                display: "inline-block"
            }}
        >
                {props.children}
        </span>
    );
};
