import React from "react";
import { FrontOutsidePopupBlank } from "../FrontOutsidePopupBlank/FrontOutsidePopupBlank";
import { Uppercase } from "../../../Uppercase/Uppercase";
import { WeightBold } from "../../../WeightBold/WeightBold";
import { FrontIcon, TIcon } from "../../Simple/FrontIcon/FrontIcon";
import { SpacingRow } from "../../../BuildingBlocks/SpacingRow";
import { FrontSpacing } from "../../../../models/FrontSpacing";
import { SpacingColumn } from "../../../BuildingBlocks/SpacingColumn";
import { FontQuicksand } from "../../../BuildingBlocks/FontQuicksand";
import { FontSize } from "../../../BuildingBlocks/FontSize";
import { FrontFontSizes } from "../../../../models/FrontFontSizes";
import { TextColor } from "../../../BuildingBlocks/TextColor";
import { FrontColors } from "../../../../models/FrontColors";

export type TFrontOutsidePopupBasic = {
    isOpen: boolean;
    onClose?: () => void;
    title: string;
    iconName?: TIcon;
};

export const FrontOutsidePopupBasic = (props: React.PropsWithChildren<TFrontOutsidePopupBasic>): JSX.Element => {
    return (
        <FrontOutsidePopupBlank
            isOpen={props.isOpen}
            onClose={props.onClose}
        >
            <SpacingColumn spacing={FrontSpacing.LARGE_2}>
                {/* TITLE */}
                <SpacingRow spacing={FrontSpacing.SMALL_2}>
                    {props.iconName && 
                        <FrontIcon
                            iconName={props.iconName}
                            colour="primary-2"
                            size="large"
                        />
                    }
                    <FontQuicksand>
                        <FontSize size={FrontFontSizes.X_LARGE}>
                            <TextColor color={FrontColors.PRIMARY_2}>
                                <Uppercase>
                                    <WeightBold>
                                        {props.title}
                                    </WeightBold>
                                </Uppercase>
                            </TextColor>
                        </FontSize>
                    </FontQuicksand>
                </SpacingRow>
                
                {/* BODY */}
                {props.children}
            </SpacingColumn>
        </FrontOutsidePopupBlank>
    );
}
