import React from "react";
import { onKeyPress } from "../../functions/keypress/onKeyPress";

export interface ITextAreaComponentProps {
    required?: boolean;
    displayError: boolean;
    name?: string;
    value?: string;
    placeholder?: string;
    disabled?: boolean;
    pattern?: string;
    maxLength?: number;
    onChange: (value: string) => void;
    onPressTabKey?: (event: React.KeyboardEvent<HTMLTextAreaElement>) => void;
    onPressEscape?: (event: React.KeyboardEvent<HTMLTextAreaElement>) => void;
    onPressBackspace?: (event: React.KeyboardEvent<HTMLTextAreaElement>) => void;
    onFocus?: () => void;
    onBlur?: (e: React.FocusEvent<HTMLTextAreaElement>) => void;
    autoFocus?: boolean;
    textAreaRef?: React.RefObject<HTMLTextAreaElement>;
}

type TUnstyledTextAreaComponentProps = {
    className?: string;
    style?: React.CSSProperties;
} & ITextAreaComponentProps;

class UnstyledTextAreaComponent extends React.Component<React.PropsWithChildren<TUnstyledTextAreaComponentProps>> {

    onChange (event: React.ChangeEvent<HTMLTextAreaElement>): void {
        this.props.onChange(event.currentTarget.value);
    }

    public render (): JSX.Element {
        return (
            <textarea
                ref={this.props.textAreaRef}
                className={this.props.className}
                required={this.props.required || false}
                name={this.props.name}
                id={this.props.name}
                value={this.props.value}
                placeholder={this.props.placeholder}
                disabled={this.props.disabled}
                onChange={(event) => this.onChange(event)}
                onKeyDown={onKeyPress({
                    onTab: this.props.onPressTabKey,
                    onBackspace: this.props.onPressBackspace,
                    onEscape: this.props.onPressEscape
                })}
                maxLength={this.props.maxLength}
                onFocus={this.props.onFocus}
                onBlur={this.props.onBlur}
                autoFocus={this.props.autoFocus}
                style={this.props.style}
                autoComplete="off"
            />
        );
    }
}

export default UnstyledTextAreaComponent;
