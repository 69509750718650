import React from "react";
import CRMInputLabelAndErrorWrapComponent from "../CRMInputLabelAndErrorWrapComponent/CRMInputLabelAndErrorWrapComponent";
import { TCRMFormInputWithoutCodec } from "./CRMFormInputTypes";
import CRMInputDecimalComponent from "../CRMInputDecimal/CRMInputDecimalComponent";

export const CRMDecimalFormInput: TCRMFormInputWithoutCodec<number | null> = (props) => 
<CRMInputLabelAndErrorWrapComponent label={props.label}>
    <CRMInputDecimalComponent
        value={props.model}
        onChange={(value) => props.onChange(value)}
        displayError={props.displayError}
        disabled={props.disabled}
        allowNegative={true}
    />
</CRMInputLabelAndErrorWrapComponent>