import React, { useEffect, useState } from "react";
import * as TFormModels from "../../models/TFormModels";
import { CRMInlineOptionsComponent } from "../CRMInlineOptionsComponent/CRMInlineOptionsComponent";
import { CRMLink } from "../CRMLink/CRMLink";
import { CRMCardOutsidePopupBasic } from "../CRMCardOutsidePopupBasic/CRMCardOutsidePopupBasic";
import { CRMParagraph } from "../Simple/CRMParagraph/CRMParagraph";
import CRMInputLabelAndErrorWrapComponent from "../CRMInputLabelAndErrorWrapComponent/CRMInputLabelAndErrorWrapComponent";
import CRMInputGeneralComponent from "../CRMInputs/CRMInputGeneralComponent/CRMInputGeneralComponent";
import { CRMSpacing } from "../../models/CRMSpacing";
import { SpacingRow } from "../BuildingBlocks/SpacingRow";


// If you want to see what this looks like in Storybook go to CRMCaseDocumentManagement.stories.tsx

type TCaseDocumentManagementFileRowProps = {
    documentForm: TFormModels.TCaseDocument6AndCaseDocumentForm1FormList["forms"][number];
    fileForm: TFormModels.TCaseDocumentFile4AndCaseDocumentFileForm1FormList["forms"][number];
    onFileNameChange: (fileId: string, value: string) => void;
    onFileDelete: (fileId: string) => void;
};

export const CRMCaseDocumentManagementFileRow = (props: TCaseDocumentManagementFileRowProps): JSX.Element => {
    const [renameFilePopupIsOpenState, setRenameFilePopupIsOpenState] = useState<boolean>(false);
    const [deleteFilePopupIsOpenState, setDeleteFilePopupIsOpenState] = useState<boolean>(false);
    const [fileNameState, setFileNameState] = useState<string>(props.fileForm.edit.name);

    const saveRename = (): void => {
        props.onFileNameChange(props.fileForm.view.id, fileNameState);
        setRenameFilePopupIsOpenState(false);
    };

    const fileCanBeDeleted = (): boolean =>
        props.documentForm.view.enquiries.length === 0
        && props.documentForm.edit.received_date === null;

    useEffect(() => {
        setFileNameState(props.fileForm.edit.name);
    }, [props.fileForm.edit.name]);

    return (
        <div>
            {/* BODY - DOCUMENT FILE LINK & OPTIONS */}
            <SpacingRow 
                spacing={CRMSpacing.TINY}
                alignItems="flex-start"
            >
                <CRMLink
                    href={`${env.REACT_APP_API_URL}/web/cases/${props.documentForm.view.case_id}/documents/${props.documentForm.view.id}/files/${props.fileForm.view.id}/download`}
                    target="_blank"
                    linkStyle="normal"
                >
                    {props.fileForm.edit.name}.{props.fileForm.view.file_extension}
                </CRMLink>
                
                <CRMInlineOptionsComponent
                    icon="options"
                    iconSize="tiny"
                    options={[
                        {
                            label: "Rename",
                            onClick: () => setRenameFilePopupIsOpenState(true),
                        },
                        {
                            label: "Delete",
                            onClick: () =>
                                fileCanBeDeleted() ? setDeleteFilePopupIsOpenState(true)
                                : undefined,
                            disabled: !fileCanBeDeleted(),
                            disabledExplanationText: "This file is either already being used in an enquiry or 'all files correct' is checked.",
                        },
                    ]}
                />
            </SpacingRow>

            {/* POPUP - RENAME FILE */}
            <CRMCardOutsidePopupBasic
                isOpen={renameFilePopupIsOpenState}
                title="Rename file"
                context="neutral"
                onClose={() => {
                    setFileNameState(props.fileForm.edit.name);
                    setRenameFilePopupIsOpenState(false);
                }}
                closeText="Cancel"
                onCTA={saveRename}
                ctaText="Save"
                ctaDisabled={fileNameState === ""}
            >
                <CRMParagraph>
                    <CRMInputLabelAndErrorWrapComponent
                        label="File name"
                        displayError={false}
                        errorMessage="An error occurred when saving the file name"
                    >
                        <CRMInputGeneralComponent
                            inputType="text"
                            value={fileNameState}
                            onChange={setFileNameState}
                            displayError={false}
                            onPressEnterKey={() => fileNameState ? saveRename() : undefined}
                        />
                    </CRMInputLabelAndErrorWrapComponent>
                </CRMParagraph>
            </CRMCardOutsidePopupBasic>

            {/* POPUP - CONFIRM DELETE */}
            <CRMCardOutsidePopupBasic
                isOpen={deleteFilePopupIsOpenState}
                title="Are you sure?"
                context="important"
                onClose={() => setDeleteFilePopupIsOpenState(false)}
                closeText="Cancel"
                onCTA={() => {
                    props.onFileDelete(props.fileForm.view.id);
                    setDeleteFilePopupIsOpenState(false);
                }}
                ctaText="Yes, delete this file"
            >
                <CRMParagraph>
                    Are you sure you want to delete this file?
                </CRMParagraph>
            </CRMCardOutsidePopupBasic>
        </div>
    );
};
