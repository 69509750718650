import React, { useEffect, useState } from "react";
import { TContainerStateProps } from "../../state/TContainerStateProps";
import { CRMSpacer } from "../../components/CRMSpacer/CRMSpacer";
import CRMInputLabelAndErrorWrapComponent from "../../components/CRMInputLabelAndErrorWrapComponent/CRMInputLabelAndErrorWrapComponent";
import { CRMDeferButtonComponent } from "../../components/CRMDeferButtonComponent/CRMDeferButtonComponent";
import { CRMSpacingColumn } from "../../components/CRMSpacingColumn/CRMSpacingColumn";
import { CRMSpacingRow } from "../../components/CRMSpacingRow/CRMSpacingRow";
import { CRMButtonIcon } from "../../components/CRMButtonIcon/CRMButtonIcon";
import { WeightBold } from "../../components/WeightBold/WeightBold";
import { CRMEditAddressForm } from "../../components/CRMEditAddressForm/CRMEditAddressForm";
import { Tenure1 } from "../../../../domain/codecs/Tenure";
import { CasePropertyTitleGuarantee2 } from "../../../../domain/codecs/CasePropertyTitleGuarantee";
import { CasePropertyTitleClass } from "../../../../domain/codecs/CasePropertyTitleClass";
import { CRMDropdownComponent } from "../../components/CRMDropdownComponent/CRMDropdownComponent";
import { TCasePropertyForm } from "../../../../domain/codecs/form/CasePropertyForm";
import { requireExhaustive, snakeCaseToCopyText } from "../../../../shared/src/util";
import { SpacingRow } from "../../components/BuildingBlocks/SpacingRow";
import { CRMSpacing } from "../../models/CRMSpacing";
import CRMInputGeneralComponent from "../../components/CRMInputs/CRMInputGeneralComponent/CRMInputGeneralComponent";
import { doesErrorCodeExist, doesErrorKeyExist } from "../../../../shared/src/codecs/errors";
import { CRMRadioListComponent } from "../../components/CRMInputs/CRMRadioListComponent/CRMRadioListComponent";
import { CRMInputCalendarComponent } from "../../components/CRMInputCalendarComponent/CRMInputCalendarComponent";
import { CasePropertyTransferOf, dateOnTitleRegisterToLandRegFormat } from "../../../../domain/codecs/CaseProperty";
import { DateTime } from "luxon";
import { CRMNoticeBoxComponent } from "../../components/CRMNoticeBoxComponent/CRMNoticeBoxComponent";
import { SpacingColumn } from "../../components/BuildingBlocks/SpacingColumn";
import { CRMCalendarDateTimeWithClearFormInput } from "../../components/CRMFormInputs/CRMCalendarDateTimeWithClearFormInput";
import { CRMButtonQuaternary } from "../../components/CRMButtonQuaternary/CRMButtonQuaternary";
import { CRMCardOutsidePopupFormSubmit } from "../../components/CRMCardOutsidePopupFormSubmit/CRMCardOutsidePopupFormSubmit";
import { CRMParagraph } from "../../components/Simple/CRMParagraph/CRMParagraph";
import { WeightSemiBold } from "../../components/WeightSemiBold/WeightSemiBold";
import { WeightMedium } from "../../components/WeightMedium/WeightMedium";
import CRMTextAreaComponent from "../../components/CRMTextAreaComponent/CRMTextAreaComponent";

export const CRMLegalCaseDetailsPropertyDetailsPropertyContainer = (props: TContainerStateProps & {form: TCasePropertyForm}): JSX.Element => {
    const [orderRegisterPopupIsShowing, setOrderRegisterPopupIsShowing] = useState(false);

    const [createValuationPopupIsShowing, setCreateValuationPopupIsShowing] = useState(false);

    const transactionType = props.state.forms.contracts_block.children.details.edited.transaction_type;

    const shouldShowOrderRegisterControl =
        props.form.edited.is_registered
        && props.form.edited.title_number;

    const shouldShowCreateValuationControl =
        (
            (
                transactionType === "sale"
                || transactionType === "transfer"
                || transactionType === "remortgage"
            ) ? true
            : (
                transactionType === "purchase"
                || transactionType === "unknown"
            ) ? false
            : requireExhaustive(transactionType)
        )
        && (
            (
                props.form.edited.is_registered
                && props.form.edited.title_number
            )
            || props.form.edited.is_registered === false
        );

    const hasValuationAlreadyBeenRequested =
        props.form.children.valuation.is_listing_created;

    const isTitleNumberError =
        doesErrorKeyExist(`edited.title_number`, props.form.validationErrors)
        || doesErrorCodeExist("TitleNumberInvalid", props.form.children.valuation.create_listing_form.validationErrors)
        || doesErrorCodeExist("TitleNumberInvalid", props.form.children.order_register_form.validationErrors);
    
    const dispatchLegalCaseUpdate = <T extends unknown>(edited: T, formKey: keyof typeof props.form.edited) => props.dispatch({
        type: "LEGAL_CASE_UPDATE_PROPERTY",
        payload: {
            ...props.form,
            edited: {
                ...props.form.edited,
                [formKey]: edited,
            },
        }
    });

    const dispatchOrderRegister = () =>
        props.dispatch({
            type: "LEGAL_CASE_PROPERTY_ORDER_REGISTER",
            payload: props.form.children.order_register_form,
        });

    const dispatchCreateListing = () =>
        props.dispatch({
            type: "LEGAL_CASE_PROPERTY_CREATE_LISTING",
            payload: props.form.children.valuation.create_listing_form,
        });

    useEffect(() => {
        if (
            props.form.children.order_register_form.status === "success"
            || props.form.children.order_register_form.status === "validationError"
        ) {
            setTimeout(() => setOrderRegisterPopupIsShowing(false), 500);
        }

        if (
            props.form.children.valuation.create_listing_form.status === "success"
            || props.form.children.valuation.create_listing_form.status === "validationError"
        ) {
            setTimeout(() => setCreateValuationPopupIsShowing(false), 500);
        }
    }, [
        props.form.children.order_register_form.status,
        props.form.children.valuation.create_listing_form.status,
    ]);

    return <>
        <div>
            <CRMSpacer size="large" />
            <CRMSpacingColumn spacing="medium">
                <SpacingRow
                    spacing={CRMSpacing.MEDIUM}
                    childSize="1fr 1fr"
                    alignItems="flex-start"
                >
                    <CRMInputLabelAndErrorWrapComponent
                        label={"Tenure"}
                    >
                        <CRMDropdownComponent
                            options={Tenure1.payload.map((innerCodec) => ({
                                value: innerCodec.payload,
                                label: snakeCaseToCopyText(innerCodec.payload)
                            }))}
                            value={props.form.edited.tenure}
                            onChange={(v) => dispatchLegalCaseUpdate(v, "tenure")}
                            displayError={doesErrorKeyExist(`edited.tenure`, props.form.validationErrors)}
                        />
                    </CRMInputLabelAndErrorWrapComponent>

                    <CRMInputLabelAndErrorWrapComponent
                        label={"Title being transferred"}
                    >
                        <CRMDropdownComponent
                            options={CasePropertyTransferOf.payload.map((innerCodec) => ({
                                value: innerCodec.payload,
                                label: snakeCaseToCopyText(innerCodec.payload)
                            }))}
                            value={props.form.edited.transfer_of}
                            onChange={(v) => dispatchLegalCaseUpdate(v, "transfer_of")}
                            displayError={doesErrorKeyExist(`edited.transfer_of`, props.form.validationErrors)}
                        />
                    </CRMInputLabelAndErrorWrapComponent>
                </SpacingRow>

                <SpacingRow
                    spacing={CRMSpacing.MEDIUM}
                    childSize="1fr 1fr"
                    alignItems="flex-start"
                >
                    <CRMInputLabelAndErrorWrapComponent
                        label={"Title Guarantee"}
                    >
                        <CRMDropdownComponent
                            options={CasePropertyTitleGuarantee2.payload.map((innerCodec) => ({
                                value: innerCodec.payload,
                                label: snakeCaseToCopyText(innerCodec.payload)
                            }))}
                            value={props.form.edited.title_guarantee}
                            onChange={(v) => dispatchLegalCaseUpdate(v, "title_guarantee")}
                            displayError={doesErrorKeyExist(`edited.tenure`, props.form.validationErrors)}
                        />
                    </CRMInputLabelAndErrorWrapComponent>

                    <CRMInputLabelAndErrorWrapComponent
                        label={"Title Class"}
                    >
                        <CRMDropdownComponent
                            options={CasePropertyTitleClass.payload.map((innerCodec) => ({
                                value: innerCodec.payload,
                                label: snakeCaseToCopyText(innerCodec.payload)
                            }))}
                            value={props.form.edited.title_class}
                            onChange={(v) => dispatchLegalCaseUpdate(v, "title_class")}
                            displayError={doesErrorKeyExist(`edited.title_class`, props.form.validationErrors)}
                        />
                    </CRMInputLabelAndErrorWrapComponent>
                </SpacingRow>

                <SpacingRow
                    spacing={CRMSpacing.MEDIUM}
                    childSize="1fr 1fr"
                    alignItems="flex-start"
                >
                    {/* IS REGISTERED */}
                    <CRMInputLabelAndErrorWrapComponent
                        label={"Is registered?"}
                    >
                        <CRMSpacingRow spacing="tiny" childSize="auto auto">
                            <CRMRadioListComponent<boolean, boolean>
                                value={props.form.edited.is_registered}
                                direction="row"
                                options={[
                                    {
                                        value: true,
                                        text: "Yes",
                                    },
                                    {
                                        value: false,
                                        text: "No",
                                    },
                                ]}
                                onChange={(val) => dispatchLegalCaseUpdate(val, "is_registered")}
                            />
                            <CRMButtonIcon
                                variant="tertiary"
                                icon="backspace"
                                disabledColour="neutral-4"
                                onClick={() => dispatchLegalCaseUpdate(null, "is_registered")}
                            />
                        </CRMSpacingRow>
                    </CRMInputLabelAndErrorWrapComponent>
                </SpacingRow>

                {props.form.edited.tenure === "leasehold" &&
                    <SpacingRow
                        spacing={CRMSpacing.MEDIUM}
                        childSize="1fr 1fr"
                        alignItems="flex-start"
                    >
                        <CRMInputLabelAndErrorWrapComponent
                            label={"Affected by building safety act?"}
                        >
                            <CRMSpacingRow spacing="tiny" childSize="auto auto">
                                <CRMRadioListComponent
                                    value={props.form.edited.affected_by_building_safety_act}
                                    direction="row"
                                    options={[
                                        {
                                            value: true,
                                            text: "Yes",
                                        },
                                        {
                                            value: false,
                                            text: "No",
                                        },
                                    ]}
                                    onChange={(val) => dispatchLegalCaseUpdate(val, "affected_by_building_safety_act")}
                                />
                                <CRMButtonIcon
                                    variant="tertiary"
                                    icon="backspace"
                                    disabledColour="neutral-4"
                                    onClick={() => dispatchLegalCaseUpdate(null, "affected_by_building_safety_act")}
                                />
                            </CRMSpacingRow>
                        </CRMInputLabelAndErrorWrapComponent>
                        
                        {/* DEFER CHECK IF AFFECTED BY BSA JOB */}
                        <CRMInputLabelAndErrorWrapComponent label="Defer check if affected by BSA job">
                            <CRMDeferButtonComponent
                                value={props.state.forms.contracts_block.children.details.edited.get_property_details_job_defer}
                                onChange={(check_if_affected_by_bsa_job_defer) => props.dispatch({
                                    type: "LEGAL_CASE_CONTRACTS_BLOCK_CHANGE",
                                    payload: {
                                        ...props.state.forms.contracts_block.children.details,
                                        edited: {
                                            ...props.state.forms.contracts_block.children.details.edited,
                                            check_if_affected_by_bsa_job_defer,
                                        }
                                    }
                                })}
                                displayError={false}
                                popoutPosition="below-button-left"
                            />
                        </CRMInputLabelAndErrorWrapComponent>
                    </SpacingRow>
                }

                {props.form.edited.is_registered === false && <>
                    <CRMInputLabelAndErrorWrapComponent
                            label={"Root Of Title Document Name"}
                        >
                        <CRMTextAreaComponent
                            onChange={(edited) => dispatchLegalCaseUpdate(edited, "root_of_title_document_name") }
                            value={props.form.edited.root_of_title_document_name}
                        />
                    </CRMInputLabelAndErrorWrapComponent>
                    <CRMInputLabelAndErrorWrapComponent
                            label={"Root Of Title Parties"}
                        >
                        <CRMTextAreaComponent
                            value={props.form.edited.root_of_title_parties}
                            onChange={(edited) => dispatchLegalCaseUpdate(edited, "root_of_title_parties") }
                        />
                    </CRMInputLabelAndErrorWrapComponent>
                    <CRMInputLabelAndErrorWrapComponent
                            label={"Root Of Title Document Date"}
                        >
                        <CRMSpacingRow spacing="tiny" childSize="1fr auto">
                            <CRMInputCalendarComponent
                                dateType={"date"}
                                value={props.form.edited.root_of_title_document_date || ""}
                                onChange={(edited) => dispatchLegalCaseUpdate(edited, "root_of_title_document_date") }
                                onPressEnterKey={() => null}
                                displayError={doesErrorKeyExist(`edited.root_of_title_document_date`, props.form.validationErrors)}
                            />
                            <CRMButtonIcon
                                variant="tertiary"
                                icon="backspace"
                                disabledColour="neutral-4"
                                onClick={() => dispatchLegalCaseUpdate(null, "root_of_title_document_date")}
                            />
                        </CRMSpacingRow>
                    </CRMInputLabelAndErrorWrapComponent>
                </>}

                {props.form.edited.is_registered && <>
                    <SpacingRow
                        spacing={CRMSpacing.MEDIUM}
                        childSize="1fr 1fr"
                        alignItems="flex-end"
                    >
                        {/* TITLE NUMBER */}
                        <CRMInputLabelAndErrorWrapComponent
                            label={"Title Number"}
                        >
                            <CRMInputGeneralComponent
                                inputType={"text"}
                                value={props.form.edited.title_number}
                                onChange={(edited) => dispatchLegalCaseUpdate(edited, "title_number") }
                                displayError={isTitleNumberError}
                            />
                        </CRMInputLabelAndErrorWrapComponent>
                        
                        {/* ORDER REGISTER */}
                        {shouldShowOrderRegisterControl &&
                            <CRMButtonQuaternary
                                icon="file-clip"
                                label="Order Register"
                                onClick={() => setOrderRegisterPopupIsShowing(true)}
                            />
                        }
                    </SpacingRow>

                    {/* LAST TRANSFER DATE */}
                    <CRMInputLabelAndErrorWrapComponent
                        label={"Last Transfer Date"}
                    >
                        <CRMSpacingRow spacing="tiny" childSize="1fr auto">
                            <CRMInputCalendarComponent
                                dateType={"date"}
                                value={props.form.edited.last_transfer_date || ""}
                                onChange={(edited) => dispatchLegalCaseUpdate(edited, "last_transfer_date") }
                                onPressEnterKey={() => null}
                                displayError={doesErrorKeyExist(`edited.last_transfer_date`, props.form.validationErrors)}
                            />
                            <CRMButtonIcon
                                variant="tertiary"
                                icon="backspace"
                                disabledColour="neutral-4"
                                onClick={() => dispatchLegalCaseUpdate(null, "last_transfer_date")}
                            />
                        </CRMSpacingRow>
                    </CRMInputLabelAndErrorWrapComponent>

                {/* RECENT LAST TRANSFER WARNING */}
                {(props.form.edited.last_transfer_date
                    && DateTime.fromISO(props.form.edited.last_transfer_date).toSeconds() >= DateTime.utc().minus({months: 6}).toSeconds()
                ) &&
                    <CRMNoticeBoxComponent>
                        <SpacingColumn spacing={CRMSpacing.MEDIUM}>
                            <WeightBold>
                                This property was last transferred less than 6 months ago. You should consider any AML risks associated.
                            </WeightBold>

                            <CRMRadioListComponent<boolean, boolean>
                                value={props.form.edited.recent_last_transfer_date_risk_acknowledged}
                                direction="column"
                                options={[
                                    {
                                        value: true,
                                        text: "Risks considered and proceeding",
                                    },
                                    {
                                        value: false,
                                        text: "Not checked yet",
                                    },
                                ]}
                                onChange={(val) => dispatchLegalCaseUpdate(val, "recent_last_transfer_date_risk_acknowledged")}
                            />
                        </SpacingColumn>
                    </CRMNoticeBoxComponent>
                }

                    {/* DATE ON TITLE REGISTER */}
                    <CRMCalendarDateTimeWithClearFormInput 
                        dateType="datetime-local_seconds"
                        guidance={dateOnTitleRegisterToLandRegFormat(props.form.edited.date_on_title_register)}
                        label="Date on Title Register"
                        model={props.form.edited.date_on_title_register}
                        onChange={(value) => 
                            dispatchLegalCaseUpdate(value, "date_on_title_register")
                        }
                        displayError={doesErrorKeyExist(`edited.date_on_title_register`, props.form.validationErrors)}
                    />
                </>}

                {/* ADDRESS FORM */}
                <CRMEditAddressForm
                    listing={props.form.edited}
                    validationErrors={props.form.validationErrors}
                    onChange={(address) => {
                        props.dispatch({
                            type: "LEGAL_CASE_UPDATE_PROPERTY",
                            payload: {
                                ...props.form,
                                edited: {
                                    ...props.form.edited,
                                    ...address,
                                },
                                status: "requiresSubmission",
                            }
                        });
                    } }
                    autocomplete_address_status={props.state.forms.case_details_page.autocomplete_address_status}
                    onAutocompleteClicked={() => props.dispatch({
                        type: "LEGAL_CASE_ADDRESS_AUTOCOMPLETE",
                        payload: props.form
                    })}
                />

                {/* REFER TO SAIL HOMES */}
                {shouldShowCreateValuationControl &&
                    <CRMButtonQuaternary
                        icon={
                            hasValuationAlreadyBeenRequested
                                ? "done-all"
                                : "house-and-building"
                        }
                        label={
                            hasValuationAlreadyBeenRequested
                                ? "Sail Homes Valuation Requested"
                                : "Create Sail Homes Valuation Request"
                        }
                        disabled={hasValuationAlreadyBeenRequested}
                        onClick={() => setCreateValuationPopupIsShowing(true)}
                    />
                }
            </CRMSpacingColumn>
            <CRMSpacer size="medium" />
        </div>

        {/* ORDER REGISTER POPUP */}
        <CRMCardOutsidePopupFormSubmit
            isOpen={orderRegisterPopupIsShowing}
            title="Are you sure?"
            context="warning"
            closeText="Cancel"
            ctaText="Yes, purchase Register"
            onClose={() => setOrderRegisterPopupIsShowing(false)}
            onCTA={dispatchOrderRegister}
            formStatus={
                props.form.children.order_register_form.status === "untouched" ? "requiresSubmission"
                : (
                    props.form.children.order_register_form.status === "unauthorised"
                    || props.form.children.order_register_form.status === "notFound"
                    || props.form.children.order_register_form.status === "twoFactorRequired"
                ) ? "failure"
                : props.form.children.order_register_form.status
            }
            validationErrors={[]}
            size="medium"
        >
            <SpacingColumn spacing={CRMSpacing.MEDIUM}>
                <CRMParagraph>
                    <WeightSemiBold>
                        You are about to purchase this Register from Land Registry.
                    </WeightSemiBold>
                </CRMParagraph>

                <CRMParagraph>
                    <WeightMedium>
                        Doing so will upload the Register to this case's documents screen, it will also fill in some of the details in this property section as well as charges, restrictions, clients ect.
                    </WeightMedium>
                </CRMParagraph>

                <CRMParagraph>
                    <WeightBold>
                        If this is the first time we're getting this Register then a cost will be incurred. Are you sure you want to order this Register?
                    </WeightBold>
                </CRMParagraph>
            </SpacingColumn>
        </CRMCardOutsidePopupFormSubmit>

        {/* CREATE VALUATION POPUP */}
        <CRMCardOutsidePopupFormSubmit
            isOpen={createValuationPopupIsShowing}
            title="Are you sure?"
            context="warning"
            closeText="Cancel"
            ctaText="Yes, create valuation request"
            onClose={() => setCreateValuationPopupIsShowing(false)}
            onCTA={dispatchCreateListing}
            formStatus={
                props.form.children.valuation.create_listing_form.status === "untouched" ? "requiresSubmission"
                : (
                    props.form.children.valuation.create_listing_form.status === "unauthorised"
                    || props.form.children.valuation.create_listing_form.status === "notFound"
                    || props.form.children.valuation.create_listing_form.status === "twoFactorRequired"
                ) ? "failure"
                : props.form.children.valuation.create_listing_form.status
            }
            validationErrors={[]}
            size="medium"
        >
            <SpacingColumn spacing={CRMSpacing.MEDIUM}>
                <CRMParagraph>
                    <WeightSemiBold>
                        You are about to ask Sail Homes to value this property.
                    </WeightSemiBold>
                </CRMParagraph>

                <CRMParagraph>
                    <WeightMedium>
                        Doing so make a copy of the publicly accessible data on this property (the address and details from the Register) and add this into Sail Homes's system so that
                        they can value the property and if appropriate convert the client to one of their customers.
                    </WeightMedium>
                </CRMParagraph>

                <CRMNoticeBoxComponent>
                    The client should be made aware that Sail Homes will prepare a valuation for them and will approach them separately from the Sail Legal team.
                </CRMNoticeBoxComponent>
            </SpacingColumn>
        </CRMCardOutsidePopupFormSubmit>
    </>;
};
