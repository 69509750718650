import React from "react";
import HomesLogo from "../../../../../src/assets/SailHomesSquare";
import LegalLogo from "../../../../../src/assets/SailLegalSquare";
import ProbateLogo from "../../../../../src/assets/SailProbateSquare";
import { SpacingRow } from "../../../BuildingBlocks/SpacingRow";
import { VerticalLine } from "../../../BuildingBlocks/VerticalLine";
import { FrontColors } from "../../../../models/FrontColors";
import { FrontSpacing } from "../../../../models/FrontSpacing";
import { FontQuicksand } from "../../../BuildingBlocks/FontQuicksand";
import { FontSize } from "../../../BuildingBlocks/FontSize";
import { FrontFontSizes } from "../../../../models/FrontFontSizes";
import { TextColor } from "../../../BuildingBlocks/TextColor";
import { MediaPropSwitch } from "../../../BuildingBlocks/MediaPropSwitch";

type TFrontFormHeaderProps = {
    context: "homes" | "legal" | "probate";
    title: JSX.Element;
};

export const FrontFormHeader = (props: React.PropsWithChildren<TFrontFormHeaderProps>): JSX.Element => {
    return (
        <div style={{ width: "100%"}}>
            
            <SpacingRow justifyContent="space-between">
                <SpacingRow spacing={FrontSpacing.MEDIUM_1}>
                    {/* LOGO */}
                    {props.context === "homes" &&
                        <HomesLogo height="60px" width="49px" />
                    }
                    
                    {props.context === "legal" &&
                        <LegalLogo height="60px" width="49px" />
                    }
                    
                    {props.context === "probate" &&
                        <ProbateLogo height="60px" width="49px" />
                    }

                    {/* LOGO & TITLE SEPERATOR LINE */}
                    <VerticalLine height="32px" color={FrontColors.NEUTRAL_10} />
                    
                    {/* TITLE */}
                    <FontQuicksand>
                        <MediaPropSwitch
                            Component={FontSize}
                            desktop={{ size: FrontFontSizes.X_LARGE}}
                            mobile={{ size: FrontFontSizes.LARGE }}
                            breakpoint="600"
                        >
                            <TextColor color={FrontColors.NEUTRAL_6}>
                                {props.title}
                            </TextColor>
                        </MediaPropSwitch>
                    </FontQuicksand>
                </SpacingRow>
            </SpacingRow>

        </div>
    );
};
