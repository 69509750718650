import React from "react";
import { either } from "fp-ts/lib";
import sanitize from "sanitize-html";

interface ISafeHtmlRender {
    html: string;
}

class SafeHtmlRender extends React.Component<React.PropsWithChildren<ISafeHtmlRender>> {
    public render (): JSX.Element {
        const html = either.getOrElse( () => "" )(either.tryCatch(() => sanitize(this.props.html, {
            allowedTags: ["h1", "h2", "h3", "h4", "h5", "h6", "blockquote", "p", "ul", "ol",
            "nl", "li", "b", "i", "u", "strong", "em", "strike", "abbr", "code", "hr", "br", "div",
            "table", "thead", "caption", "tbody", "tr", "th", "td", "pre" ],
            allowedAttributes: {},
            selfClosing: ["br", "hr"],
        }), (e) => e));
        return (
            <div className={"SafeHtmlRenderComponent"} dangerouslySetInnerHTML={{__html: html}} />
        );
    }
}

export default SafeHtmlRender;
