import React from "react";
import { TFrontMainColours } from "../../../../models/TFrontMainColours";
import * as TCurrency from "../../../../../../domain/models/TCurrency";
import { requireExhaustive } from "../../../../../../shared/src/util";
import FrontButtonMicro from "./../../Simple/FrontButtonMicro/FrontButtonMicro";
import { FrontRow } from "../../../FrontRow/FrontRow";
import { FrontIcon } from "../../Simple/FrontIcon/FrontIcon";
import { FrontInputCurrencyHuge } from "../../Simple/FrontInputHuge/FrontInputCurrencyHuge";
import { FrontOfferSummaryGauge } from "../FrontOfferSummaryGauge/FrontOfferSummaryGauge";
import { FrontOfferSummaryStatusIcon } from "../../Simple/FrontOfferSummaryStatusIcon/FrontOfferSummaryStatusIcon";
import { FrontTitlePageSection } from "../../../Front/Simple/FrontPageSectionTitle/FrontTitlePageSection";
import FrontParagraphSmall from "../../../Front/Simple/FrontParagraphSmall/FrontParagraphSmall";
import FrontParagraphTiny from "../../../Front/Simple/FrontParagraphTiny/FrontParagraphTiny";
import { FrontSpacer } from "../../../FrontSpacer/FrontSpacer";
import { FrontTextColour } from "../../../FrontTextColour/FrontTextColour";
import FrontTitleMinor from "../../Simple/FrontTitleMinor/FrontTitleMinor";
import { Underline } from "../../../Underline/Underline";
import { WeightBold } from "../../../WeightBold/WeightBold";
import { WeightMedium } from "../../../WeightMedium/WeightMedium";
import { FrontCompletionTimeModal } from "../FrontCompletionTimeModal/FrontCompletionTimeModal";
import { FrontFallThroughRiskModal } from "../FrontFallThroughRiskModal/FrontFallThroughRiskModal";
import { FrontBuyerReadyFactorModal } from "../FrontBuyerReadyFactorModal/FrontBuyerReadyFactorModal";
import { TOffer1NewDefault } from "../../../../../../domain/codecs/Offer";
import { TBuyerSubmitsNewOfferFormEditableNewDefault } from "../../../../../../domain/codecs/formEditable/BuyerSubmitsNewOfferFormEditable";
import { TEstimatedTimeToCompletion1 } from "../../../../../../domain/codecs/EstimatedTimeToCompletion";
import { TRiskOfFallThrough1NewDfault } from "../../../../../../domain/codecs/RiskOfFallThrough";

type TFrontYourOfferSummary = {
    thierLatestOffer: TOffer1NewDefault;
    newOfferForm: TBuyerSubmitsNewOfferFormEditableNewDefault;
    onChangeNewOfferForm: (form: TBuyerSubmitsNewOfferFormEditableNewDefault) => void;
    onSubmitNewOfferForm: (form: TBuyerSubmitsNewOfferFormEditableNewDefault) => void;
};

type TState = {
    isUpdatingOffer: boolean;
    isCompletionTimePopupOpen: boolean;
    isFallThroughRiskPopupOpen: boolean;
    isIDCheckPopupOpen: boolean;
    isProofOfFundsPopupOpen: boolean;
    isSolicitorDetailsPopupOpen: boolean;
    isMortgageBrokerDetailsPopupOpen: boolean;
};

export class FrontYourOfferSummary extends React.Component<React.PropsWithChildren<TFrontYourOfferSummary>, TState> {

    constructor(props: TFrontYourOfferSummary) {
        super(props);
        this.state = {
            isUpdatingOffer: false,
            isCompletionTimePopupOpen: false,
            isFallThroughRiskPopupOpen: false,
            isIDCheckPopupOpen: false,
            isProofOfFundsPopupOpen: false,
            isSolicitorDetailsPopupOpen: false,
            isMortgageBrokerDetailsPopupOpen: false,
        };
    }

    toggleOfferUpdate = (): void => this.setState({ isUpdatingOffer: !this.state.isUpdatingOffer });

    toggleCompletionTimePopup = (): void => this.setState({ isCompletionTimePopupOpen: !this.state.isCompletionTimePopupOpen });

    toggleFallThroughRiskPopup = (): void => this.setState({ isFallThroughRiskPopupOpen: !this.state.isFallThroughRiskPopupOpen });

    toggleIDCheckPopup = (): void => this.setState({ isIDCheckPopupOpen: !this.state.isIDCheckPopupOpen });

    toggleProofOfFundsPopup = (): void => this.setState({ isProofOfFundsPopupOpen: !this.state.isProofOfFundsPopupOpen });

    toggleSolicitorDetailsPopup = (): void => this.setState({ isSolicitorDetailsPopupOpen: !this.state.isSolicitorDetailsPopupOpen });

    toggleMortgageBrokerDetailsPopup = (): void => this.setState({ isMortgageBrokerDetailsPopupOpen: !this.state.isMortgageBrokerDetailsPopupOpen });

    timeToCompleteColour = (timeToComplete: TEstimatedTimeToCompletion1): TFrontMainColours => {
        switch (timeToComplete) {
            case "fast":
                return "highlights-positive-apple-10";
            case "slow":
                return "highlights-error-firebrick-10";
            default:
                return requireExhaustive(timeToComplete);
        }
    };

    riskOfFallThroughDisplayColour = (risk: TRiskOfFallThrough1NewDfault): TFrontMainColours => {
        switch (risk) {
            case "high":
                return "highlights-error-firebrick-10";
            case "medium":
                return "highlights-pertinent-coral-10";
            case "low":
                return "highlights-positive-apple-10";
            default:
                return requireExhaustive(risk);
        }
    };

    public render (): JSX.Element {
        return (
            <>
                <div className="front-your-offer-summary">
                    {/* HEADER */}
                    <div className="front-your-offer-summary__header">
                        <WeightMedium>
                            <FrontTitleMinor>
                                <FrontTextColour colour="neutral-18">
                                    Your Offer
                                </FrontTextColour>
                            </FrontTitleMinor>
                        </WeightMedium>

                        <FrontButtonMicro
                            label={this.state.isUpdatingOffer ? "Close" : "Change Offer"}
                            onClick={this.toggleOfferUpdate}
                        />
                    </div>
                    <FrontSpacer size="medium-2" />

                    {/* AMOUNT */}
                    <div className="front-your-offer-summary__amount">
                        <WeightBold>
                            <FrontTitlePageSection>
                                <FrontTextColour colour="neutral-18">
                                    {TCurrency.fromNumber(this.props.thierLatestOffer.amount)}
                                </FrontTextColour>
                            </FrontTitlePageSection>
                        </WeightBold>
                    </div>
                    <FrontSpacer size="medium-1" />

                    {/* BODY */}
                    <div className="front-your-offer-summary__body">
                        {/* BODY: OFFER SUMMARY */}
                        <div className={`
                            front-your-offer-summary__summary-body
                            
                        `}>
                            <div className="front-your-offer-summary__gauges">
                                <FrontOfferSummaryGauge
                                    label="Your current completion time"
                                    colour={this.timeToCompleteColour(this.props.thierLatestOffer.estimated_time_to_complete)}
                                    title={this.props.thierLatestOffer.estimated_time_to_complete}
                                    onClickInfo={this.toggleCompletionTimePopup}
                                />
                                <div className="front-your-offer-summary__seperator"></div>
                                <FrontOfferSummaryGauge
                                    label="Your current risk of fall through"
                                    colour={this.riskOfFallThroughDisplayColour(this.props.thierLatestOffer.risk_of_fall_through)}
                                    title={this.props.thierLatestOffer.risk_of_fall_through}
                                    onClickInfo={this.toggleFallThroughRiskPopup}
                                />
                            </div>
                            <FrontSpacer size="medium-2" />

                            {/* PARAGRAPH */}
                            <FrontParagraphTiny>
                                <FrontTextColour colour="neutral-11">
                                    <WeightMedium>
                                        The seller assesses your offer based on its
                                        &nbsp;
                                        <Underline>
                                            completion time
                                        </Underline>
                                        &nbsp;
                                        and
                                        &nbsp;
                                        <Underline>
                                            fall through risk.
                                        </Underline>
                                    </WeightMedium>
                                    <WeightBold>
                                        &nbsp;You can improve these by providing the information below.
                                    </WeightBold>
                                </FrontTextColour>
                            </FrontParagraphTiny>
                            <FrontSpacer size="large-1" />

                            {/* STEPS COMPLETED */}
                            <div className="front-your-offer-summary__completion-icons">
                                {/* YOUR OFFER */}
                                <FrontOfferSummaryStatusIcon
                                    isComplete={true}
                                    icon="money"
                                    title="offer"
                                    isClickable={false}
                                    onClick={() => undefined}
                                />
                                <FrontSpacer size="medium-2" />
                                {/* ID CHECK */}
                                <FrontOfferSummaryStatusIcon
                                    isComplete={this.props.thierLatestOffer.id_check_complete}
                                    icon="id-card"
                                    title="id"
                                    isClickable={true}
                                    onClick={this.toggleIDCheckPopup}
                                />
                                <FrontSpacer size="medium-2" />
                                {/* PROOF OF FUNDS */}
                                <FrontOfferSummaryStatusIcon
                                    isComplete={this.props.thierLatestOffer.has_provided_proof_of_funds}
                                    icon="coins"
                                    title="pof"
                                    isClickable={true}
                                    onClick={this.toggleProofOfFundsPopup}
                                />
                                <FrontSpacer size="medium-2" />
                                {/* SOLICITOR DETAILS */}
                                <FrontOfferSummaryStatusIcon
                                    isComplete={this.props.thierLatestOffer.has_provided_conveyancer_details}
                                    icon="contract"
                                    title="solicitor"
                                    isClickable={true}
                                    onClick={this.toggleSolicitorDetailsPopup}
                                />
                                <FrontSpacer size="medium-2" />
                                {/* MORTGAGE BROKER DETAILS */}
                                {this.props.thierLatestOffer.payment_method !== "cash" && <FrontOfferSummaryStatusIcon
                                    isComplete={this.props.thierLatestOffer.has_provided_mortgage_broker_details}
                                    icon="bank"
                                    title="broker"
                                    isClickable={true}
                                    onClick={this.toggleMortgageBrokerDetailsPopup}
                                />}
                            </div>
                        </div>

                        {/* BODY: CHANGE OFFER */}
                        <div
                            className={`
                                front-your-offer-summary__change-offer-body
                                front-your-offer-summary__change-offer-body--${this.state.isUpdatingOffer ? "visible" : "hidden"}
                            `}
                        >
                            <div className="front-your-offer-summary__change-offer-body-paragraph">
                                {this.props.newOfferForm.status !== "success" &&
                                    <>
                                        <FrontParagraphSmall>
                                            <WeightMedium>
                                                <FrontTextColour colour="neutral-18">
                                                    You can make as many changes to your offer as you like by entering a new offer in here. We’ll then pass the update straight onto the seller.
                                                </FrontTextColour>
                                            </WeightMedium>
                                        </FrontParagraphSmall>
                                        <FrontSpacer size="medium-2" />
                                    </>
                                }
                                <FrontInputCurrencyHuge
                                    formStatus={this.props.newOfferForm.status}
                                    value={this.props.newOfferForm.edited.amount}
                                    onChange={(val) => {
                                        this.props.newOfferForm.edited.amount = val || 0;
                                        this.props.newOfferForm.status = "untouched";
                                        this.props.onChangeNewOfferForm(this.props.newOfferForm);
                                    }}
                                    onPressEnterKey={() => null}
                                />
                                <FrontSpacer size="medium-2" />

                                {/* IF NOT SUCCESS: SUBMIT BUTTON */}
                                {this.props.newOfferForm.status !== "success" &&
                                    <>
                                        <FrontRow justifyContent="end">
                                            <FrontButtonMicro
                                                colour="primary-6"
                                                label="Submit"
                                                onClick={() => this.props.onSubmitNewOfferForm(this.props.newOfferForm)}
                                            />
                                        </FrontRow>
                                        <FrontSpacer size="medium-1" />
                                    </>
                                }

                                {this.props.newOfferForm.status === "success" &&
                                    <>
                                        <FrontRow justifyContent="start">
                                            <FrontIcon iconName="tick" size="small" colour="highlights-positive-apple-10" />
                                            <WeightMedium>
                                                <FrontParagraphSmall>
                                                    <FrontTextColour colour="neutral-18">
                                                        Offer Updated
                                                    </FrontTextColour>
                                                </FrontParagraphSmall>
                                            </WeightMedium>
                                        </FrontRow>
                                        <FrontSpacer size="small-2" />

                                        <WeightMedium>
                                            <FrontParagraphSmall>
                                                <FrontTextColour colour="neutral-18">
                                                    We’ve got your new offer and we’ll share that with the seller.
                                                </FrontTextColour>
                                            </FrontParagraphSmall>
                                        </WeightMedium>
                                    </>
                                }

                                {this.props.newOfferForm.status === "validationError" &&
                                    <WeightMedium>
                                        <FrontParagraphSmall>
                                            <FrontTextColour colour="neutral-18">
                                                You need to enter an amount before submitting your new offer.
                                            </FrontTextColour>
                                        </FrontParagraphSmall>
                                    </WeightMedium>
                                }

                                {this.props.newOfferForm.status === "failure" &&
                                    <>
                                        <WeightMedium>
                                            <FrontParagraphSmall>
                                                <FrontTextColour colour="neutral-18">
                                                    Your offer could not be updated
                                                </FrontTextColour>
                                            </FrontParagraphSmall>
                                        </WeightMedium>
                                        <FrontSpacer size="small-2" />

                                        <WeightMedium>
                                            <FrontParagraphSmall>
                                                <FrontTextColour colour="neutral-18">
                                                    You can try again, if the issue presists do not hesitate to contact us.
                                                </FrontTextColour>
                                            </FrontParagraphSmall>
                                        </WeightMedium>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>

                {/* Popups */}
                <FrontCompletionTimeModal
                    isOpen={this.state.isCompletionTimePopupOpen}
                    onClose={this.toggleCompletionTimePopup}
                    thierLatestOffer={this.props.thierLatestOffer}
                />

                <FrontFallThroughRiskModal
                    isOpen={this.state.isFallThroughRiskPopupOpen}
                    onClose={this.toggleFallThroughRiskPopup}
                    thierLatestOffer={this.props.thierLatestOffer}
                />

                <FrontBuyerReadyFactorModal
                    isOpen={this.state.isProofOfFundsPopupOpen}
                    onClose={this.toggleProofOfFundsPopup}
                    variant="proof_of_funds"
                />

                <FrontBuyerReadyFactorModal
                    isOpen={this.state.isIDCheckPopupOpen}
                    onClose={this.toggleIDCheckPopup}
                    variant="id_check"
                />

                <FrontBuyerReadyFactorModal
                    isOpen={this.state.isSolicitorDetailsPopupOpen}
                    onClose={this.toggleSolicitorDetailsPopup}
                    variant="solicitor_details"
                />

                <FrontBuyerReadyFactorModal
                    isOpen={this.state.isMortgageBrokerDetailsPopupOpen}
                    onClose={this.toggleMortgageBrokerDetailsPopup}
                    variant="mortgage_broker_details"
                />
            </>
        );
    }
}
