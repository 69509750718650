import { TrueRoadblockType, TRoadblockType } from "../../../../../domain/codecs/form/RoadblockForm";
import { pipe } from "fp-ts/lib/pipeable";
import { array } from "fp-ts";
import { capitaliseFirst, underscoreCaseToNormalCase } from "../../../../../shared/src/utilsByDomain/string";
import { TRichFilterDropdown_PillOption } from "../../Complex/CRMRichFilterDropdown_Pill/CRMRichFilterDropdown_Pill";

export const RoadblockType = pipe(
    TrueRoadblockType.payload,
    array.map((item) => item.payload)
);

export const RoadblockFilterOptions: Array<TRichFilterDropdown_PillOption> = pipe(
    RoadblockType.sort(),
    array.map<TRoadblockType, TRichFilterDropdown_PillOption>((item) => ({
        label: pipe(
            underscoreCaseToNormalCase(item),
            capitaliseFirst
        ),
        value: item,
        group: "Roadblocks"
    })),
);