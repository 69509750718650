import { array } from "fp-ts";
import { pipe } from "fp-ts/lib/function";
import React from "react";
import { TQuoteRateLiveRate } from "../../../../../domain/codecs/form/QuoteRateForm";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { SpacingColumn } from "../../BuildingBlocks/SpacingColumn";
import { CRMLiveQuoteRateCard } from "../CRMLiveQuoteRateCard/CRMLiveQuoteRateCard";

export const CRMQuoteRatesLiveList = (props: React.PropsWithChildren<{
    forms: Array<TQuoteRateLiveRate>,
    onOpen: (form: TQuoteRateLiveRate) => void,
}>): JSX.Element =>
    <SpacingColumn spacing={CRMSpacing.SMALL}>
        {pipe(
            props.forms,
            array.map((form) =>
                <CRMLiveQuoteRateCard
                    key={form.id}
                    form={form}
                    onOpen={() => props.onOpen(form)}
                />
            )
        )}
    </SpacingColumn>;
