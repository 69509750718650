import * as QueryStringFilterMappings from "./QueryStringFilterMappings";
import * as QueryStringFilterGroup1 from "./QueryStringFilterGroup1";
import * as QueryStringFilterMappingFunction from "./QueryStringFilterMappingFunction";
import { nonEmptyArray, array } from "fp-ts";
import { pipe } from "fp-ts/lib/function";
import { TValidationFunction } from "../../shared/src/validation/Function";

export const filterMappings: QueryStringFilterMappings.T = {
    string: {
        party_id: QueryStringFilterMappingFunction.createForString("listings_enquiries.party_id"),
        listing_id: QueryStringFilterMappingFunction.createForString("listings_enquiries.listing_id"),
        id: QueryStringFilterMappingFunction.createForString("listings_enquiries.id"),
    },
};

export const validator: TValidationFunction = QueryStringFilterGroup1.createValidator(filterMappings);

export const createForPartyId = (partyId: string): QueryStringFilterGroup1.T => ({
    type: "GROUP",
    conditional: "AND",
    filters: [
        {
            type: "FILTER",
            property: "party_id",
            operator: "EQUALS_STRING",
            value: partyId,
        },
    ],
});

export const createForListingAndPartyId = (listingId: string, partyId: string): QueryStringFilterGroup1.T => ({
    type: "GROUP",
    conditional: "AND",
    filters: [
        {
            type: "FILTER",
            property: "listing_id",
            operator: "EQUALS_STRING",
            value: listingId,
        },
        {
            type: "FILTER",
            property: "party_id",
            operator: "EQUALS_STRING",
            value: partyId,
        },
    ],
});

export const createWhereIdAnyOf = (ids: nonEmptyArray.NonEmptyArray<string>): QueryStringFilterGroup1.T => ({
    type: "GROUP",
    conditional: "OR",
    filters:  pipe(
        ids,
        array.map((id) => ({
            type: "FILTER",
            property: "id",
            operator: "EQUALS_STRING",
            value: id,
        }))
    ),
});
