import { array, record } from "fp-ts";
import { pipe } from "fp-ts/lib/function";
import { Ord } from "fp-ts/lib/number";
import { contramap } from "fp-ts/lib/Ord";
import React from "react";
import { CaseLedgerCategory_displayString, TCaseLedgerCategory } from "../../../../../domain/codecs/CaseLedger";
import { TCaseQuote, TQuoteItem } from "../../../../../domain/codecs/Quote";
import { fromNumber } from "../../../../../domain/models/TCurrency";
import { CRMColors } from "../../../models/CRMColors";
import { CRMFontSizes } from "../../../models/CRMFontSizes";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { Flex } from "../../BuildingBlocks/Flex";
import { FontRoboto } from "../../BuildingBlocks/FontRoboto";
import { FontSize } from "../../BuildingBlocks/FontSize";
import { HorizontalLine } from "../../BuildingBlocks/HorizontalLine";
import { SpacingColumn } from "../../BuildingBlocks/SpacingColumn";
import { SpacingRow } from "../../BuildingBlocks/SpacingRow";
import { CRMParagraph } from "../../Simple/CRMParagraph/CRMParagraph";
import { CRMTableRow } from "../../CRMTableRow/CRMTableRow";
import { WeightBold } from "../../WeightBold/WeightBold";
import { WeightMedium } from "../../WeightMedium/WeightMedium";
import { WeightRegular } from "../../WeightRegular/WeightRegular";
import { WeightSemiBold } from "../../WeightSemiBold/WeightSemiBold";

export const CRMQuoteSingleView = (props: React.PropsWithChildren<{
    quoteForm: TCaseQuote,
}>): JSX.Element => {
    const sortQuoteItemsPriceDescending = (items: Array<TQuoteItem>) => pipe(
        items,
        array.sortBy([contramap((item: TQuoteItem) => item.fee_pence_total)(Ord)]),
        array.reverse,
    );

    const sortQuoteCategories = (categoriesWithItems: Array<[TCaseLedgerCategory, Array<TQuoteItem>]>) =>
        pipe(
            categoriesWithItems,
            array.sortBy([contramap(([key]: [TCaseLedgerCategory, Array<TQuoteItem>]) => key === "fee" ? 0 : 1)(Ord)]),
        );

    const itemsInCategory = pipe(
        props.quoteForm.items,
        sortQuoteItemsPriceDescending,
        array.reduce<TQuoteItem, Record<TCaseLedgerCategory, Array<TQuoteItem>>>({
            fee: [],
            disbursement: [],
            transfer_in_client: [],
            transfer_in_third_party: [],
            transfer_out: [],
            transfer_out_third_party: [],
        }, (acum, item) => {
            acum[item.category] = [
                ...acum[item.category],
                item,
            ];

            return acum;
        }),
        record.toArray,
        array.filter(([_, items]) => items.length > 0),
        sortQuoteCategories,
    );

    return <SpacingColumn spacing={CRMSpacing.LARGE}>
        {/* QUOTE ITEMS */}
        {pipe(
            itemsInCategory,
            array.map(([key, items]) =>
                <SpacingColumn
                    key={key}
                    spacing={CRMSpacing.MEDIUM}
                >
                    {/* CATEGORY TITLE */}
                    <CRMParagraph>
                        <WeightSemiBold>
                            <FontSize size={CRMFontSizes.MED}>
                                {CaseLedgerCategory_displayString(key)}
                            </FontSize>
                        </WeightSemiBold>
                    </CRMParagraph>

                    {/* ITEMS */}
                    <SpacingColumn>
                        {items.map((item, key) =>
                            <CRMTableRow key={key} actions={[]}>
                                <SpacingRow justifyContent="space-between">
                                    <SpacingRow
                                        alignItems="center"
                                        spacing={CRMSpacing.TINY}
                                    >
                                        {/* ITEM NAME */}
                                        <CRMParagraph>
                                            <WeightBold>
                                                {item.item_display_name}
                                            </WeightBold>
                                        </CRMParagraph>

                                        {/* QUANTITY */}
                                        {item.quantity > 1 &&
                                            <CRMParagraph>
                                                <FontRoboto>
                                                    <WeightRegular>
                                                        x{item.quantity}
                                                    </WeightRegular>
                                                </FontRoboto>
                                            </CRMParagraph>
                                        }
                                    </SpacingRow>

                                    {/* PRICE */}
                                    <CRMParagraph>
                                        <FontRoboto>
                                            <WeightRegular>
                                                {fromNumber(item.fee_pence_total / 100, 2)}
                                            </WeightRegular>
                                        </FontRoboto>
                                    </CRMParagraph>
                                </SpacingRow>
                            </CRMTableRow>
                        )}
                    </SpacingColumn>
                </SpacingColumn>
            )
        )}

        <HorizontalLine color={CRMColors.NEUTRAL_6} />

        {/* TOTALS */}
        <SpacingRow justifyContent="end">
            <SpacingColumn
                spacing={CRMSpacing.TINY}
                alignItems="flex-end"
            >
                {/* TOTAL */}
                <CRMParagraph>
                    <WeightSemiBold>
                        <FontSize size={CRMFontSizes.MED}>
                            Total (INC VAT):&nbsp;
                        </FontSize>
                    </WeightSemiBold>

                    <WeightBold>
                        <FontSize size={CRMFontSizes.MED}>
                            <FontRoboto>
                                {fromNumber(props.quoteForm.total_pence / 100, 2)}
                            </FontRoboto>
                        </FontSize>
                    </WeightBold>
                </CRMParagraph>

                {/* REFERRAL */}
                {props.quoteForm.referral_fee_pence > 0 &&
                    <Flex justifyContent="end">
                        <CRMParagraph>
                            <WeightMedium>
                                Referral fee:&nbsp;
                            </WeightMedium>
                            
                            <WeightSemiBold>
                                <FontRoboto>
                                    {fromNumber(props.quoteForm.referral_fee_pence / 100, 2)}
                                </FontRoboto>
                            </WeightSemiBold>
                        </CRMParagraph>
                    </Flex>
                }
            </SpacingColumn>
        </SpacingRow>
    </SpacingColumn>
};
