import React from "react";
import { useState, useEffect} from "react";
import { pipe } from "fp-ts/lib/pipeable";
import { array, option, record } from "fp-ts";
import { record as extRecord } from "../../../../../../shared/src/utilsByDomain/record";
import { isEmpty } from "../../../../../../shared/src/util";
import { TBankDetailsForm } from "../../../../../../domain/codecs/form/BankDetailsForm";
import { TUser16 } from "../../../../../../domain/codecs/User";
import { FrontSpacing } from "../../../../models/FrontSpacing";
import { SpacingColumn } from "../../../BuildingBlocks/SpacingColumn";
import { FrontDropdown } from "../../Simple/FrontDropdown/FrontDropdown";
import FrontInputGeneralComponent from "../../Simple/FrontInputGeneralComponent/FrontInputGeneralComponent";
import FrontInputLabelAndErrorWrapComponent from "../../Simple/FrontInputLabelAndErrorWrapComponent/FrontInputLabelAndErrorWrapComponent";
import { FrontSortCodeInput } from "../../Simple/FrontSortCodeInput/FrontSortCodeInput";
import { WeightMedium } from "../../../WeightMedium/WeightMedium";
import { FrontHelpBubble } from "../../Simple/FrontHelpBubble/FrontHelpBubble";
import { onChangeForm } from "../../../../../../shared/src/codecs/types/form";
import { FrontRadioList } from "../../Simple/FrontRadioList/FrontRadioList";
import { FrontMultiDropdown } from "../../Simple/FrontMultiDropdown/FrontMultiDropdown";
import FrontParagraphRegular from "../../Simple/FrontParagraphRegular/FrontParagraphRegular";
import { FSExclude } from "../../../BuildingBlocks/FSExclude";
import { FrontFormRow } from "../../Simple/FrontFormRow/FrontFormRow";
import { FrontCondensedForm } from "../FrontCondensedForm/FrontCondensedForm";
import { isEveryRecordPropertyFilled } from "../../../../util";


type TBankDetailsFormProps = {
    details: TBankDetailsForm;
    recepients: Array<TUser16>;
    isEditable: boolean;
    displayValidationErrorsIfPresent: boolean;
    onChange: (form: TBankDetailsForm) => void;
    onDelete: (form: TBankDetailsForm) => void;
};

export const FrontBankDetailsForm = (props: React.PropsWithChildren<TBankDetailsFormProps>): JSX.Element => {

    const areAllDetailsFilledIn = (): boolean => isEveryRecordPropertyFilled(["id"], props.details.original);
    
    const areAllEditedDetailsFilledIn = (): boolean =>
        props.details.edited.recipient_users__cases_ids.length > 0 &&
        props.details.edited.bank_name !== "" &&
        props.details.edited.account_number !== "" &&
        props.details.edited.sort_code !== ""
    ;

    const [isJointAccount, setIsJointAccount] = useState<boolean | null>(null);
    const [isDeets, setIsDeets] = useState<boolean | null>(null);

    useEffect(
        () => {
            if (isJointAccount === null) {
                setIsJointAccount(props.details.edited.recipient_users__cases_ids.length > 1);
            }
            if (isDeets === null) {
                setIsDeets(areAllDetailsFilledIn());
            }
        },
        [props.details]
    )

    const getAccountHolderNames = () => pipe(
        props.details.edited.recipient_users__cases_ids,
        array.map(getAccountHolderNameById),
        (names) => names.join(", ") 
    );

    const getAccountHolderNameById = (id: string) =>
        pipe(
            props.recepients,
            array.findFirst((recepient) => recepient.users__cases_id === id),
            option.fold(
                () => "",
                (recepient) => recepient.full_name
            )
        )
    ;


    const formatSortcode = (value: string) =>
        pipe(
            value.split(""),
            array.chunksOf(2),
            array.map((chunk) => chunk.join("")),
            (chunks) => chunks.join("-") 
        )
    ;

    const onChange = onChangeForm(props.details, props.onChange);

    const onSetIsAccountJoint = (value: boolean) => {
        setIsJointAccount(value);

        if (value === false) {
            onChange("recipient_users__cases_ids")([]);
        }
    }

    return (
        <div className={`front-bank-details-form`}>
            <SpacingColumn spacing={FrontSpacing.SMALL_3}>
                
                {/* TEXT BUBBLE FOR: PENDING VERIFICATION */}
                {(
                    props.details.edited.verification_status === "pending"
                    || props.details.edited.verification_status === "not_verified_as_will_not_be_used"
                ) &&
                    <FrontHelpBubble
                        type="pending"
                        labelIcon="shield-error"
                        label="This account is being verified"
                        paragraph={
                            <span>
                                Sail Legal is running a check to verify these details are correct. Please check back with this page soon.
                            </span>
                        }
                    />
                }
                
                {/* TEXT BUBBLE FOR: VERIFIED */}
                {props.details.edited.verification_status === "approved" &&
                    <FrontHelpBubble
                        type="success"
                        labelIcon="shield-done"
                        label="Account verified"
                        paragraph={
                            <span>
                                Account details provided have been confirmed to be correct.
                            </span>
                        }
                    />
                }
                
                {/* TEXT BUBBLE FOR: REJECTED VERIFICATION */}
                {props.details.edited.verification_status === "rejected" &&
                    <FrontHelpBubble
                        type="failure"
                        labelIcon="shield-cross"
                        label="Account verification failed"
                        paragraph={
                            <span>
                                The account details provided have not passed our checks. Please confirm that the details are correct, or call us.
                            </span>
                        }
                    />
                }

                {/* FORM */}
                <FrontCondensedForm
                    isEditable={props.isEditable}
                    canSubmit={areAllEditedDetailsFilledIn()}
                    form={props.details}
                    deletePopupText={
                        <FrontParagraphRegular>
                            Are you sure you want to delete this bank account?
                        </FrontParagraphRegular>
                    }
                    formSummary={[
                        { 
                            label: isJointAccount ? "Account holders" : "Account holder", 
                            text: <WeightMedium>{getAccountHolderNames()}</WeightMedium>
                        },
                        { 
                            label: "Bank",
                            text: <FSExclude><WeightMedium>{props.details.edited.bank_name}</WeightMedium></FSExclude>
                        },
                        { 
                            label: "Account Number",
                            text: <FSExclude><WeightMedium>{props.details.edited.account_number}</WeightMedium></FSExclude>
                        },
                        { 
                            label: "Sortcode",
                            text: <FSExclude><WeightMedium>{formatSortcode(props.details.edited.sort_code)}</WeightMedium></FSExclude>
                        },
                    ]}
                    onChange={props.onChange}
                    onDelete={props.onDelete}
                    deleteLabelText="Delete this account"
                >
                    {/* ### FORM BODY ROWS ### */}
                    {/* JOINT ACCOUNT & ACCOUNT HODLER/S */}
                    <FrontFormRow>
                        <FrontInputLabelAndErrorWrapComponent label="Is this a joint account?">
                            <FrontRadioList
                                value={isJointAccount}
                                options={[
                                    {
                                        label: "Yes",
                                        value: true,
                                    },
                                    {
                                        label: "No",
                                        value: false,
                                    },
                                ]}
                                onChange={onSetIsAccountJoint}
                            />
                        </FrontInputLabelAndErrorWrapComponent>
                        
                        {isJointAccount === true &&
                            <FrontInputLabelAndErrorWrapComponent label="Joint account holders" errorMessage="Please select the account holders from the list" displayError={props.details.edited.recipient_users__cases_ids.length === 0 && props.displayValidationErrorsIfPresent ? true : false}>
                                <FrontMultiDropdown
                                    value={props.details.edited.recipient_users__cases_ids}
                                    placeholder=""
                                    limitedFaceText={true}
                                    options={props.recepients.map((user) => ({
                                        value: user.users__cases_id,
                                        label: user.full_name
                                    }))}
                                    onChange={onChange("recipient_users__cases_ids")}
                                />
                            </FrontInputLabelAndErrorWrapComponent>
                        }
                        
                        {isJointAccount === false &&
                            <FrontInputLabelAndErrorWrapComponent label="Account holder" errorMessage="Please select the account holder from the list" displayError={props.details.edited.recipient_users__cases_ids.length === 0 && props.displayValidationErrorsIfPresent ? true : false}>
                                <FrontDropdown
                                    value={props.details.edited.recipient_users__cases_ids[0] || ""}
                                    options={props.recepients
                                        .map((user) => ({
                                            value: user.users__cases_id,
                                            label: user.full_name
                                        }))
                                        .concat({
                                            value: "",
                                            label: "Not set",
                                        })
                                        .reverse()
                                    }
                                    onChange={(value) => onChange("recipient_users__cases_ids")(value ? [value] : [])}
                                />
                            </FrontInputLabelAndErrorWrapComponent>
                        }
                    </FrontFormRow>
                    
                    {/* BANK NAME & ACCOUNT NUMBER */}
                    <FrontFormRow>
                        <FrontInputLabelAndErrorWrapComponent label="Bank Name" errorMessage="Please enter the bank's name" displayError={props.details.edited.bank_name === "" && props.displayValidationErrorsIfPresent ? true : false}>
                            <FrontInputGeneralComponent
                                inputType="text"
                                excludeInFullstory={true}
                                value={props.details.edited.bank_name}
                                displayError={props.details.edited.bank_name === "" && props.displayValidationErrorsIfPresent ? true : false}
                                onChange={onChange("bank_name")}
                            />
                        </FrontInputLabelAndErrorWrapComponent>

                        <FrontInputLabelAndErrorWrapComponent label="Account Number" errorMessage="Please enter the account number" displayError={props.details.edited.account_number === "" && props.displayValidationErrorsIfPresent ? true : false}>
                            <FrontInputGeneralComponent
                                inputType="numericString"
                                excludeInFullstory={true}
                                value={props.details.edited.account_number}
                                displayError={props.details.edited.account_number === "" && props.displayValidationErrorsIfPresent ? true : false}
                                onChange={onChange("account_number")}
                            />
                        </FrontInputLabelAndErrorWrapComponent>
                    </FrontFormRow>
                    
                    {/* ACCOUNT NUMBER & SORT CODE */}
                    <FrontFormRow>
                        <FrontInputLabelAndErrorWrapComponent label="Sort code" errorMessage="Please enter the sort code" displayError={props.details.edited.sort_code === "" && props.displayValidationErrorsIfPresent ? true : false}>
                            <FrontSortCodeInput
                                value={props.details.edited.sort_code}
                                onChange={onChange("sort_code")}
                                excludeInFullstory={true}
                            />
                        </FrontInputLabelAndErrorWrapComponent>
                    </FrontFormRow>
                </FrontCondensedForm>


            </SpacingColumn>
        </div>
    );
};


