import React from "react";
import { CRMFontSizes } from "../../../models/CRMFontSizes";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { FontRoboto } from "../../BuildingBlocks/FontRoboto";
import { SpacingRow } from "../../BuildingBlocks/SpacingRow";
import { Text } from "../../BuildingBlocks/Text";
import { CRMParagraph } from "../../Simple/CRMParagraph/CRMParagraph";

type TLabelAndMonoValueProps = {
    label: string;
    value: string;
};

export const CRMLabelAndMonoValue = (props: React.PropsWithChildren<TLabelAndMonoValueProps>): JSX.Element => {

    return (
        <SpacingRow spacing={CRMSpacing.TINY}>
            <CRMParagraph>
                {props.label}
            </CRMParagraph>
            <FontRoboto>
                <Text size={CRMFontSizes.SMALL} weight={500}>
                    {props.value}
                </Text>
            </FontRoboto>
        </SpacingRow>
    );
};
