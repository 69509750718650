import { Observable } from "rxjs";
import { tap, mergeMap, flatMap, filter, concatMap } from "rxjs/operators";
import { formOperation } from "../../wrappers/formOperation";
import { TAdminUserEditable } from "../../../../domain/codecs/formEditable/AdminUserEditable";
import { doFormValuesMatch } from "../../../../shared/src/codecs/types/formEditable";
import { TActionsDefinitionsList } from "./TAction";
import { action, routeAction } from "./actionFunctions";

export const actions: TActionsDefinitionsList = [
    routeAction("VIEW_ADMIN", (obs$, lens, set, get) => {
        obs$.pipe(
            mergeMap(() => formOperation("GetAdminPage", lens.homes_admin_page.data.get()(get()))),
            tap((response) => set(lens.homes_admin_page.data.set(response))),
        ).subscribe();
    }),
    action("HOMES_ADMIN_USERS_CHANGE", (obs$: Observable<Array<TAdminUserEditable>>, lens, setState) => {
        obs$.pipe(
            tap((adminUsers) => setState(lens.homes_admin_page.data.output.adminUsers.set(adminUsers))),
            concatMap((adminUsers) => adminUsers),
            filter((member) => member.status === "requiresSubmission"),
            tap((member) => setState(lens.homes_admin_page.data.output.adminUsers.where(doFormValuesMatch(member, "id")).status.set("submitting"))),
            flatMap((adminUser) => formOperation("UpdateAdminUser", adminUser)),
            tap((member) => setState(lens.homes_admin_page.data.output.adminUsers.where(doFormValuesMatch(member, "id")).status.set(member.status))),
        ).subscribe();
    }),
];
