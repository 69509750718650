import React from "react";
import { CRMColors } from "../../../models/CRMColors";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { BackgroundColour } from "../../BuildingBlocks/BackgroundColour";
import { MinHeight } from "../../BuildingBlocks/MinHeight";
import { Padding } from "../../BuildingBlocks/Padding";
import { Sticky } from "../../BuildingBlocks/Sticky";
import { CRMSingleViewHeader } from "../../Simple/CRMSingleViewHeader/CRMSingleViewHeader";
import { Relative } from "../../BuildingBlocks/Relative";
import { CRMBottomControlBar } from "../../CRMBottomControlBar/CRMBottomControlBar";
import { useResolutionStatus } from "../../../hooks/useResolutionStatus";
import { TPixelSize } from "../../../models/StringLiterals";
import { TProbateHelplineCallbackForm, TProbateHelplineCallbackMarkCompleteForm } from "../../../../../domain/codecs/form/ProbateHelplineCallbackForm";
import { pipe } from "fp-ts/lib/function";
import { array } from "fp-ts";
import { SpacingColumn } from "../../BuildingBlocks/SpacingColumn";
import { CRMProbateHelplineCallbackWaitBubble } from "../../Simple/CRMProbateHelplineCallbackWaitBubble/CRMProbateHelplineCallbackWaitBubble";
import { WeightBold } from "../../WeightBold/WeightBold";
import { DateTime } from "luxon";
import { SpacingRow } from "../../BuildingBlocks/SpacingRow";
import { requireExhaustive } from "../../../../../shared/src/util";
import { CRMButtonIcon } from "../../CRMButtonIcon/CRMButtonIcon";
import { CRMConfirmationCountdown } from "../CRMConfirmationCountdown/CRMConfirmationCountdown";
import { CRMProbateHelplineSingleViewPersonalInformation } from "../CRMProbateHelplineSingleViewPersonalInformation/CRMProbateHelplineSingleViewPersonalInformation";
import { CRMProbateHelplineSingleViewNotesAndDefer } from "../CRMProbateHelplineSingleViewNotesAndDefer/CRMProbateHelplineSingleViewNotesAndDefer";
import { CRMBlockActionPopout } from "../../CRM/CRMBlock/CRMBlockActionPopout/CRMBlockActionPopout";

export const CRMProbateHelplineNowSingleView = (props: React.PropsWithChildren<{
    form: TProbateHelplineCallbackForm,
    onChange: (form: TProbateHelplineCallbackForm) => void,
    onMarkAsComplete: (form: TProbateHelplineCallbackMarkCompleteForm) => void,
    onClose: () => void;
}>): JSX.Element => {
    const {
        resolutionStatus,
        setResolution,
        resetResolution,
    } = useResolutionStatus<"none" | "completing", {}>({
        defaultStatus: "none",
    });

    const fullName =
        pipe(
            [
                props.form.children.first_name,
                props.form.children.last_name,
            ],
            array.filter((namePart) => namePart !== ""),
            (a) => a.join(" "),
        );

    const onDeferChange = (value: "1 hour" | "3 hours" | "tomorrow" | "1 week" | "clear") =>
        props.onChange({
            ...props.form,
            edited: {
                ...props.form.edited,
                defer_timestamp:
                    value === "1 hour" ? DateTime.utc().plus({hours: 1}).toISO()
                    : value === "3 hours" ? DateTime.utc().plus({hours: 3}).toISO()
                    : value === "tomorrow" ? DateTime.utc().plus({days: 1}).set({hour: 0, minute: 0, second: 0, millisecond: 0}).toISO()
                    : value === "1 week" ? DateTime.utc().plus({days: 7}).set({hour: 0, minute: 0, second: 0, millisecond: 0}).toISO()
                    : value === "clear" ? null
                    : requireExhaustive(value),
            }
        });

    const onComplete = () => props.onMarkAsComplete(props.form.children.mark_callback_complete_form);

    return (
        <Relative>
            <div>
                {/* BODY */}
                <div>
                    <Sticky
                        zIndex={1}
                    >
                        <CRMSingleViewHeader 
                            title={fullName}
                            onClick={props.onClose} 
                        />
                    </Sticky>
                    
                    <BackgroundColour colour={CRMColors.NEUTRAL_PAPER}>
                        <MinHeight height={"700px"}>
                            <Padding spacing={CRMSpacing.LARGE}>
                                <SpacingColumn spacing={CRMSpacing.LARGE}>
                                    {/* WAIT BADGE */}
                                    <CRMProbateHelplineCallbackWaitBubble
                                        form={props.form}
                                    />

                                    {/* PERSONAL INFORMATION */}
                                    <CRMProbateHelplineSingleViewPersonalInformation
                                        form={props.form}
                                    />

                                    {/* NOTES & DEFER */}
                                    <CRMProbateHelplineSingleViewNotesAndDefer
                                        form={props.form}
                                        onChange={props.onChange}
                                    />
                                </SpacingColumn>
                            </Padding>
                        </MinHeight>
                    </BackgroundColour>
                </div>
                
                {/* CONTROL BAR */}
                <CRMBottomControlBar>
                    {resolutionStatus === "completing" &&
                        <CRMConfirmationCountdown
                            title="Marking as complete."
                            iconName="done-all"
                            primaryButtonMode="done"
                            onUndo={resetResolution}
                            onComplete={onComplete}
                        />
                    }

                    {/* CONTROLS */}
                    {resolutionStatus === "none" &&
                        <Padding 
                            type="custom"
                            width="100%" 
                            spacing={`${CRMSpacing.TINY} ${CRMSpacing.MEDIUM}`}
                        >
                            <SpacingRow spacing={CRMSpacing.TINY}>
                                {/* COMPLETE BUTTON */}
                                <CRMButtonIcon
                                    title="Mark callback as complete."
                                    variant="quaternary"
                                    icon="done-all"
                                    onClick={() => setResolution("completing")()}
                                />

                                {/* DEFER */}
                                <CRMBlockActionPopout
                                    icon="snooze"
                                    title="Defer"
                                    options={[
                                        {
                                            label: <span>Defer <WeightBold>1 hour</WeightBold></span>,
                                            onClick: () => onDeferChange("1 hour"),
                                        },
                                        {
                                            label: <span>Defer <WeightBold>3 hours</WeightBold></span>,
                                            onClick: () => onDeferChange("3 hours"),
                                        },
                                        {
                                            label: <span>Defer <WeightBold>till tomorrow</WeightBold></span>,
                                            onClick: () => onDeferChange("tomorrow"),
                                        },
                                        {
                                            label: <span>Defer <WeightBold>1 week</WeightBold></span>,
                                            onClick: () => onDeferChange("1 week"),
                                        },
                                        {
                                            label: <span><WeightBold>Clear defer</WeightBold></span>,
                                            onClick: () => onDeferChange("clear"),
                                        },
                                    ]}
                                />
                            </SpacingRow>
                        </Padding>
                    }
                </CRMBottomControlBar>
            </div>
        </Relative>
    );
};
