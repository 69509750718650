import React, { useEffect, useState } from "react";
import { TQuoteRateCreatedForm } from "../../../../../domain/codecs/form/QuoteRateForm";
import { QuoteRateFeePropertyValue, QuoteRateFeeTransferValue, TQuoteRateFeeTransferValue } from "../../../../../domain/codecs/QuoteRateFee";
import { onChangeForm } from "../../../../../shared/src/codecs/types/form";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { SpacingColumn } from "../../BuildingBlocks/SpacingColumn";
import CRMInputLabelAndErrorWrapComponent from "../../CRMInputLabelAndErrorWrapComponent/CRMInputLabelAndErrorWrapComponent";
import { CRMRadioListComponent } from "../../CRMInputs/CRMRadioListComponent/CRMRadioListComponent";
import { CRMTitleSubSection } from "../../CRMTitleSubSection/CRMTitleSubSection";
import { CRMQuoteRatesSingleViewEditFeesFixed } from "./CRMQuoteRatesSingleViewEditFeesFixed";
import { CRMQuoteRatesSingleViewEditPropertyValueFeesVariable } from "./CRMQuoteRatesSingleViewEditPropertyValueFeesVariable";
import { CRMQuoteRatesSingleViewEditTransferValueFeesVariable } from "./CRMQuoteRatesSingleViewEditTransferValueFeesVariable";

export const CRMQuoteRatesSingleViewEditFees = (props: React.PropsWithChildren<{
    form: TQuoteRateCreatedForm,
    displayError: boolean,
    hasMissingFeeBand: boolean,
    onChange: (form: TQuoteRateCreatedForm) => void,
}>): JSX.Element => {
    const [feeStructure, setFeeStructure] = useState<"fixed" | "variable_transfer_value" | "variable_property_value">(
        props.form.edited.transfer_value_fees.length > 1
        || (
            props.form.edited.transfer_value_fees.length === 1
            && (
                (props.form.edited.transfer_value_fees[0].when_greater_than_or_equal_to_property_price_pence || 0) > 0
                || (props.form.edited.transfer_value_fees[0].when_less_than_property_price_pence || 0) > 0
            )
        ) ? "variable_transfer_value"
        : props.form.edited.property_value_fees.length > 0 ? "variable_property_value"
        : "fixed"
    );

    const isFixedFee = feeStructure === "fixed";

    const isVariableTransferValueFee = feeStructure === "variable_transfer_value";

    const isVariablePropertyValueFee = feeStructure === "variable_property_value";

    const getFixedFee = (): TQuoteRateFeeTransferValue =>
        props.form.edited.transfer_value_fees.length === 1
            ? props.form.edited.transfer_value_fees[0]
            : QuoteRateFeeTransferValue.newDefault();

    const onChangeFixedFee = (fee: TQuoteRateFeeTransferValue) =>
        onChangeForm(props.form, props.onChange)("transfer_value_fees")([fee]);

    const onChangeVariableTransferValueFees = onChangeForm(props.form, props.onChange)("transfer_value_fees");

    const onChangeVariablePropertyValueFees = onChangeForm(props.form, props.onChange)("property_value_fees");

    const onChangeFeeStructure = (value: "fixed" | "variable_transfer_value" | "variable_property_value") => {
        if (value === "fixed" || value === "variable_transfer_value") {
            props.onChange({
                ...props.form,
                edited: {
                    ...props.form.edited,
                    transfer_value_fees: [QuoteRateFeeTransferValue.newDefault()],
                    property_value_fees: [],
                },
            });
        }

        if (value === "variable_property_value") {
            props.onChange({
                ...props.form,
                edited: {
                    ...props.form.edited,
                    transfer_value_fees: [],
                    property_value_fees: [QuoteRateFeePropertyValue.newDefault()],
                },
            });
        }
        
        setFeeStructure(value);
    };

    useEffect(() => {
        // Create the first fee if there aren't any
        if (
            props.form.edited.transfer_value_fees.length === 0
            && props.form.edited.property_value_fees.length === 0
        ) {
            onChangeForm(props.form, props.onChange)("transfer_value_fees")([QuoteRateFeeTransferValue.newDefault()]);
        }
    }, [
        props.form.edited.transfer_value_fees,
        props.form.edited.property_value_fees,
    ]);

    return <SpacingColumn spacing={CRMSpacing.MEDIUM}>
        <CRMTitleSubSection>
            Fees
        </CRMTitleSubSection>

        <CRMInputLabelAndErrorWrapComponent label="What type of fee structure does this rate use?">
            <CRMRadioListComponent
                direction="column"
                value={feeStructure}
                options={[
                    {
                        value: "fixed",
                        text: "A fixed fee",
                    },
                    {
                        value: "variable_transfer_value",
                        text: "Variable fee depending on transfer value",
                    },
                    {
                        value: "variable_property_value",
                        text: "Variable fee depending on property value",
                    },
                ]}
                onChange={onChangeFeeStructure}
            />
        </CRMInputLabelAndErrorWrapComponent>

        {/* FIXED FEE */}
        {isFixedFee &&
            <CRMQuoteRatesSingleViewEditFeesFixed
                fee={getFixedFee()}
                displayError={props.displayError}
                onChange={onChangeFixedFee}
            />
        }

        {/* VARIABLE TRANSFER VALUE FEES */}
        {isVariableTransferValueFee &&
            <CRMQuoteRatesSingleViewEditTransferValueFeesVariable
                transfer_value_fees={props.form.edited.transfer_value_fees}
                displayError={props.displayError}
                hasMissingFeeBand={props.hasMissingFeeBand}
                onChange={onChangeVariableTransferValueFees}
            />
        }

        {/* VARIABLE PROPERTY VALUE FEES */}
        {isVariablePropertyValueFee &&
            <CRMQuoteRatesSingleViewEditPropertyValueFeesVariable
                property_value_fees={props.form.edited.property_value_fees}
                displayError={props.displayError}
                hasMissingFeeBand={props.hasMissingFeeBand}
                onChange={onChangeVariablePropertyValueFees}
            />
        }
    </SpacingColumn>
};
