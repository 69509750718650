import React from "react";

type TTextColourProps = {
    colour: string;
};

/**
 * @deprecated Use @see Text
 */
export const TextColour = (props: React.PropsWithChildren<TTextColourProps>): JSX.Element =>
    (
        <span style={{ color: props.colour }}>
            {props.children}
        </span>
    );
