import React, { ReactNode } from "react";
import { CRMIcon, TIcon } from "../../../CRMIcon/CRMIcon";
import { SpacingRow } from "../../../BuildingBlocks/SpacingRow";
import { CRMSpacing } from "../../../../models/CRMSpacing";
import { CRMParagraph } from "../../../Simple/CRMParagraph/CRMParagraph";
import { WeightBold } from "../../../WeightBold/WeightBold";
import { CRMColors } from "../../../../models/CRMColors";
import { SpacingColumn } from "../../../BuildingBlocks/SpacingColumn";
import { CRMBlockHeaderSection } from "../CRMBlockHeaderSection/CRMBlockHeaderSection";
import { toTuples } from "../../../../../../shared/src/utilsByDomain/record";
import { array, option as FPTSOption } from "fp-ts";
import { pipe } from "fp-ts/lib/function";
import { NonEmptyArray } from "fp-ts/lib/NonEmptyArray";
import { CRMBlockHeaderOption } from "../CRMBlockHeaderOption/CRMBlockHeaderOption";
import { CRMEmptyPlaceholder } from "../../../Simple/CRMEmptyPlaceholder/CRMEmptyPlaceholder";
import { useOpenClose } from "../../../../hooks/UseOpenClose";
import { TBlockContainerStateProps } from "../../../../state/TBlockContainerStateProps";

type TBlockHeaderProps<S extends number> = {
    mode: TBlockHeaderModes;

    fixedOption?: TBlockHeaderOption;
    selectedOption?: string;
    optionGroups: Array<TBlockHeaderOptionGroup>;
    onChange: (name: string, option: TBlockHeaderOption) => void;

    selectedSize: S;
    sizeOptions: Array<TSizeOption<S>>;
    onChangeSize: (option: TSizeOption<S>) => void; 
};

export type TBlockHeaderModes = "select-options" | "fixed-option";

export type TBlockHeaderOptionGroup = {
    name: string;
    options: TBlockHeaderOptions;
}

type TBlockHeaderOptions = Record<string, TBlockHeaderOption>;
type TBlockHeaderOptionsTuple = [string, TBlockHeaderOption];
export type TBlockHeaderOption = {
    icon: TIcon;
    title: string;
    content?: (props: TBlockContainerStateProps) => ReactNode;
};

export type TSizeOption<S extends number> = {
    icon: TIcon;
    name: string;
    value: S;
}


export const CRMBlockHeader = <S extends number>(props: React.PropsWithChildren<TBlockHeaderProps<S>>): JSX.Element => {

    const { isOpen, toggleOpen } = useOpenClose({ defaultValue: false })

    const optionsToChunkedTuples = (options: TBlockHeaderOptions): Array<NonEmptyArray<TBlockHeaderOptionsTuple>> =>
        pipe(
            toTuples(options),
            array.chunksOf(2),
        )
    ;

    const getFace = () => props.mode === "select-options" ? 
        getSelectedOption()
        : props.fixedOption || getEmptyOption()
    ;

    const getSelectedOption = () =>
        pipe(
            props.optionGroups,
            array.map(({ options }) => options),
            array.map(toTuples),
            array.flatten,
            array.findFirst(([name]) => props.selectedOption === name),
            FPTSOption.fold(
                getEmptyOption,
                ([, option]) => option
            ),
        )
    ;
    
    const getEmptyOption = (): TBlockHeaderOption => ({
        icon: "air",
        title: "-- --",
        content: () => (
            <CRMEmptyPlaceholder>
                This block does not exist.
            </CRMEmptyPlaceholder>
        )
    });

    const onSelectOption = (name: string, option: TBlockHeaderOption) => {
        props.onChange(name, option);
        toggleOpen();
    }

    return (
        <div className={`
            crm-block-header
            crm-block-header--${isOpen ? "open" : "closed"}
        `}>
            {/* FACE */}
            <div 
                className="crm-block-header__face"
                onClick={toggleOpen}
            >
                <SpacingRow childSize="1fr 0fr">
                    {/* TITLE */}
                    <SpacingRow spacing={CRMSpacing.TINY}>
                        <CRMIcon
                            iconName={getFace().icon}
                            colour="neutral-ink"
                            size="20px"
                        />
                        <CRMParagraph lineHeight={1}>
                            <WeightBold>
                                {getFace().title}
                            </WeightBold>
                        </CRMParagraph>
                    </SpacingRow>

                    {/* ARROW */}
                    <SpacingRow
                        spacing={CRMSpacing.TINY}
                        minHeight="24px"
                    >
                        <CRMIcon
                            iconName={isOpen ? "arrow-up" : "arrow-down"}
                            colour={"hex"}
                            hexColour={CRMColors.NEUTRAL_INK}
                        />
                    </SpacingRow>
                </SpacingRow>
            </div>

            {/* BODY */}
            <div className="crm-block-header__body">
                <SpacingRow 
                    spacing={CRMSpacing.LARGE}
                    childSize="1fr 2fr"
                >
                    {/* SIZE GROUP */}
                    {props.sizeOptions &&
                        <CRMBlockHeaderSection title="Size Options" >
                            <SpacingColumn spacing={CRMSpacing.MEDIUM}>
                                {props.sizeOptions.map((sizeOption, key) => (
                                    <CRMBlockHeaderOption
                                        key={key}
                                        mode="secondary"
                                        isSelected={props.selectedSize === sizeOption.value}
                                        icon={sizeOption.icon}
                                        label={sizeOption.name}
                                        onClick={() => props.onChangeSize(sizeOption)}
                                    />
                                ))}
                            </SpacingColumn>
                        </CRMBlockHeaderSection>
                    }

                    {/* BLOCK GROUPS */}
                    {props.mode === "select-options" && props.optionGroups.map((optionGroup, groupKey) => (
                        <CRMBlockHeaderSection 
                            key={groupKey}
                            title={optionGroup.name} 
                        >
                            <SpacingColumn spacing={CRMSpacing.TINY}>
                                {optionsToChunkedTuples(optionGroup.options).map((chunk, optionChunkKey) => (
                                    <SpacingRow
                                        key={optionChunkKey}
                                        spacing={CRMSpacing.MEDIUM}
                                        childSize="1fr 1fr"
                                        alignItems="flex-start"
                                    >
                                        {chunk.map(([name, blockOption]) =>
                                            <CRMBlockHeaderOption
                                                key={name}
                                                mode={"primary"}
                                                isSelected={props.selectedOption === name}
                                                icon={blockOption.icon}
                                                label={blockOption.title}
                                                onClick={() => onSelectOption(name, blockOption)}
                                            />
                                        )}
                                    </SpacingRow>
                                ))}
                            </SpacingColumn>
                        </CRMBlockHeaderSection>
                    ))}
                </SpacingRow>
            </div>

        </div>
    );
};
