import React, { CSSProperties } from "react";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { THexColor, TPercentageSize, TPixelSize } from "../../../models/StringLiterals";

type TInfoBoxProps = {
    width?: TPixelSize | TPercentageSize;
    border?: CSSProperties["border"];
    color: THexColor;
};

export const CRMInfoBox = (props: React.PropsWithChildren<TInfoBoxProps>): JSX.Element => {

    return (
        <div
            style={{
                width: props.width || "100%",
                backgroundColor: props.color,
                border: props.border,
                padding: CRMSpacing.MEDIUM,
                borderRadius: "5px"
            }}
        >
            {props.children}
        </div>
    );
};
