import { TTypeOfCodec } from "../../../shared/src/codecs/codec";
import { form } from "../../../shared/src/codecs/types/form";
import { required } from "../../../shared/src/codecs/types/required";
import { string } from "../../../shared/src/codecs/types/string";

export const ProbateLeadForm = form(
    required({
        phone: string(),
        campaign: string(),
        cta: string(),
    }),
    required({}),
);
export type TProbateLeadFormCodec = typeof ProbateLeadForm;
export type TProbateLeadForm = TTypeOfCodec<TProbateLeadFormCodec>;