import { array } from "fp-ts";
import { pipe } from "fp-ts/lib/function";
import { Ord } from "fp-ts/lib/number";
import { contramap } from "fp-ts/lib/Ord";
import React, { useState } from "react";
import { ledgerTypeDictionary } from "../../../../../domain/codecs/CaseLedger";
import { TQuoteRateCreatedForm } from "../../../../../domain/codecs/form/QuoteRateForm";
import { TQuoteRateFeePropertyValue, TQuoteRateFeeTransferValue } from "../../../../../domain/codecs/QuoteRateFee";
import { useResolutionStatus } from "../../../hooks/useResolutionStatus";
import { CRMColors } from "../../../models/CRMColors";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { CRMZIndex } from "../../../models/CRMZIndex";
import { BackgroundColour } from "../../BuildingBlocks/BackgroundColour";
import { MinHeight } from "../../BuildingBlocks/MinHeight";
import { Padding } from "../../BuildingBlocks/Padding";
import { Relative } from "../../BuildingBlocks/Relative";
import { SpacingColumn } from "../../BuildingBlocks/SpacingColumn";
import { SpacingRow } from "../../BuildingBlocks/SpacingRow";
import { Sticky } from "../../BuildingBlocks/Sticky";
import { CRMBottomControlBar } from "../../CRMBottomControlBar/CRMBottomControlBar";
import { CRMButtonPrimary } from "../../CRMButtonPrimary/CRMButtonPrimary";
import { CRMButtonQuaternary } from "../../CRMButtonQuaternary/CRMButtonQuaternary";
import { CRMParagraph } from "../../Simple/CRMParagraph/CRMParagraph";
import { CRMTitleSubSection } from "../../CRMTitleSubSection/CRMTitleSubSection";
import { CRMSavebarAlertDrawer } from "../../Simple/CRMSavebarAlertDrawer/CRMSavebarAlertDrawer";
import { CRMSingleViewHeader } from "../../Simple/CRMSingleViewHeader/CRMSingleViewHeader";
import { WeightMedium } from "../../WeightMedium/WeightMedium";
import { WeightSemiBold } from "../../WeightSemiBold/WeightSemiBold";
import { CRMConfirmationCountdown } from "../CRMConfirmationCountdown/CRMConfirmationCountdown";
import { CRMQuoteRatesSingleViewEditAppliesWhen } from "./CRMQuoteRatesSingleViewEditAppliesWhen";
import { CRMQuoteRatesSingleViewEditBasicQuestions } from "./CRMQuoteRatesSingleViewEditBasicQuestions";
import { CRMQuoteRatesSingleViewEditFees } from "./CRMQuoteRatesSingleViewEditFees";
import { CRMQuoteRatesSingleViewEditModifiers } from "./CRMQuoteRatesSingleViewEditModifiers";

export const CRMQuoteRatesSingleViewEditPopout = (props: React.PropsWithChildren<{
    form: TQuoteRateCreatedForm
    onClose: () => void,
    onChange: (form: TQuoteRateCreatedForm) => void,
    onSave: (form: TQuoteRateCreatedForm) => void,
    onDelete: (form: TQuoteRateCreatedForm) => void,
}>): JSX.Element => {
    const {
        resolutionStatus,
        setResolution,
        resetResolution,
    } = useResolutionStatus<"none" | "deleting", {}>({
        defaultStatus: "none",
    });

    const [displayError, setDisplayError] = useState<boolean>(false);

    const isPropertyValueFee = props.form.edited.property_value_fees.length > 0;

    const isTransferValueFee = !isPropertyValueFee;

    const getHasMissingFeeBand = (checkProperty: "transfer_value_fees" | "property_value_fees") =>
        pipe(
            props.form.edited[checkProperty],
            // Sort the fees into ascending order from their lowest band
            array.sortBy([contramap((fee: TQuoteRateFeePropertyValue) => fee.when_greater_than_or_equal_to_property_price_pence || 0)(Ord)]),
            // Get the lowest and highest band of all bands combined, also find any gaps between bands
            array.reduceWithIndex<TQuoteRateFeePropertyValue, [number, number, boolean]>([0, 0, false], (i, [lowestBand, highestBand, gapInFeesFound], fee) =>
                i === 0 ? [fee.when_greater_than_or_equal_to_property_price_pence || 0, fee.when_less_than_property_price_pence || 0, false]
                : [
                    lowestBand,
                    fee.when_less_than_property_price_pence || 0,
                    gapInFeesFound || (
                        (fee.when_greater_than_or_equal_to_property_price_pence || 0) !== highestBand
                    ),
                ]
            ),
            // If a gap was found or if the lowest isn't 0 and the highest isn't also 0 (representing infinite)
            ([lowestBand, highestBand, gapInFeesFound]) =>
                gapInFeesFound
                || lowestBand !== 0
                || highestBand !== 0
        );

    const hasMissingFeeBand =
        isPropertyValueFee ? getHasMissingFeeBand("property_value_fees")
        : getHasMissingFeeBand("transfer_value_fees");

    const rateType =
        props.form.edited.rate_type
            ? ledgerTypeDictionary[props.form.edited.rate_type].displayName
            : "";

    const onSave = () => {
        if (
            props.form.edited.rate_type === null
            || (
                (
                    props.form.edited.rate_type === "stamp_duty_land_tax"
                    || props.form.edited.rate_type === "land_transaction_tax"
                )
                && props.form.edited.applies_when_tax_group_is === null
            )
            || (
                isTransferValueFee
                && pipe(
                    props.form.edited.transfer_value_fees,
                    array.filter(({fee_pence, fee_progressive_percentage_of_property_price}) => fee_pence === 0 && fee_progressive_percentage_of_property_price === 0),
                ).length > 0
            )
            || (
                isPropertyValueFee
                && pipe(
                    props.form.edited.property_value_fees,
                    array.filter(({fee_pence}) => fee_pence === 0),
                ).length > 0
            )
        ) {
            setDisplayError(true);
        } else {
            setDisplayError(false);
            props.onSave(props.form);
        }
    };

    const onDelete = () => setResolution("deleting")();

    const onSubmitDelete = () => props.onDelete(props.form);

    return (
        <div>
            {/* BODY */}
            <div>
                <Sticky zIndex={4}>
                    <CRMSingleViewHeader 
                        title={`New ${rateType} rate`}
                        onClick={props.onClose} 
                    />
                </Sticky>
                
                <BackgroundColour colour={CRMColors.NEUTRAL_PAPER}>
                    <MinHeight height={"700px"}>
                        <Padding spacing={CRMSpacing.LARGE}>
                            <SpacingColumn spacing={CRMSpacing.LARGE}>
                                {/* BASIC RATE QUESTIONS */}
                                <CRMQuoteRatesSingleViewEditBasicQuestions
                                    form={props.form}
                                    displayError={displayError}
                                    onChange={props.onChange}
                                />

                                {/* APPLIES WHEN QUESTIONS */}
                                <CRMQuoteRatesSingleViewEditAppliesWhen
                                    form={props.form}
                                    displayError={displayError}
                                    onChange={props.onChange}
                                />

                                {/* FEES */}
                                <CRMQuoteRatesSingleViewEditFees
                                    form={props.form}
                                    displayError={displayError}
                                    hasMissingFeeBand={hasMissingFeeBand}
                                    onChange={props.onChange}
                                />

                                {/* MODIFIERS */}
                                <CRMQuoteRatesSingleViewEditModifiers
                                    form={props.form}
                                    onChange={props.onChange}
                                />
                            </SpacingColumn>
                        </Padding>
                    </MinHeight>
                </BackgroundColour>
            </div>

            {/* CONTROL BAR */}
            <CRMBottomControlBar>
                {resolutionStatus === "deleting" &&
                    <CRMConfirmationCountdown
                        title="Deleting!"
                        iconName="delete"
                        primaryButtonMode="done"
                        onUndo={resetResolution}
                        onComplete={onSubmitDelete}
                    />
                }

                <Relative width="100%">
                    <Padding 
                        type="custom"
                        width="100%" 
                        spacing={`${CRMSpacing.TINY} ${CRMSpacing.MEDIUM}`}
                    >
                        <SpacingRow
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            {/* DELETE */}
                            <CRMButtonQuaternary
                                icon="delete"
                                label="Delete"
                                onClick={onDelete}
                            />

                            <SpacingRow
                                spacing={CRMSpacing.TINY}
                                justifyContent="end"
                                alignItems="center"
                            >
                                {/* MISSING FEE BAND ALERT BADGE */}
                                {hasMissingFeeBand &&
                                    <CRMSavebarAlertDrawer>
                                        <Padding
                                            spacing={CRMSpacing.TINY}
                                        >
                                            <SpacingColumn spacing={CRMSpacing.MEDIUM}>
                                                <CRMTitleSubSection>
                                                    You have a gap in your fees
                                                </CRMTitleSubSection>

                                                <CRMParagraph>
                                                    <WeightMedium>
                                                        You can still save this rate but&nbsp;
                                                    </WeightMedium>

                                                    <WeightSemiBold>
                                                        if this isn't intentional it could result in a client not being charged correctly.
                                                    </WeightSemiBold>
                                                </CRMParagraph>
                                            </SpacingColumn>
                                        </Padding>
                                    </CRMSavebarAlertDrawer>
                                }

                                {/* SAVE */}
                                <CRMButtonPrimary
                                    label="Save"
                                    onClick={onSave}
                                />
                            </SpacingRow>
                        </SpacingRow>
                    </Padding>
                </Relative>
            </CRMBottomControlBar>
        </div>
    );
};
