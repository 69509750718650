import React from "react";
import { TCRMFormInputWithoutCodec } from "./CRMFormInputTypes";
import { CRMDeferButtonComponent } from "../CRMDeferButtonComponent/CRMDeferButtonComponent";

export const CRMDeferInputWithoutLabelFormInput: TCRMFormInputWithoutCodec<string | null> = (props) =>
    <CRMDeferButtonComponent
        value={props.model}
        displayError={props.displayError}
        onChange={props.onChange}
        alignSelf="flex-end" 
    />;
