import { array, option, record } from "fp-ts";
import { TRoadblockType } from "../../../../../domain/codecs/form/RoadblockForm";
import { TBlockType } from "../../../state/router/routerTypes";
import { pipe } from "fp-ts/lib/pipeable";

export const RoadblockToFormMap: Record<TRoadblockType, TBlockType | null> = {
    "ad-hoc": null,
    "quote": "case_quotes",
    "chase_instruction": "case_contracts",
    "title_checks": "case_title_checks",
    "contracts": null,
    "client_verification": null,
    "test_roadblock_1": null,
    "test_roadblock_2": null,
    "test_roadblock_3": null,
    "test_roadblock_4": null,
    "test_roadblock_5": null,
    "test_roadblock_6": null,
    "ongoing_risk_assessment": null
};

export const getRoadblockForm = (typeToFind: TRoadblockType) => pipe(
    record.toArray(RoadblockToFormMap),
    array.findFirst(([roadblockType]) => roadblockType === typeToFind),
    option.fold(
        () => null,
        ([roadblockType, blockType]) => blockType
    )
);

export const doesRoadblockLinkToForm = (type: TRoadblockType) => getRoadblockForm(type) !== null;