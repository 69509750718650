import { useEffect, useState } from "react";

type TUseSelectedTab<T extends unknown> = {
    isSelectedTabIndex: (index: number) => boolean,
    setSelectedTabIndex: React.Dispatch<React.SetStateAction<number>>,
    selectedTabIndex: number,
    getSelectedTab: () => T | null;
}

export const useSelectedTab = <T extends unknown>(tabs: Array<T>): TUseSelectedTab<T> => {
    const [selectedTabIndex, setSelectedTabIndex] = useState(0);
    
    useEffect(
        () => {
            if (selectedTabIndex > tabs.length-1) {
                // this needs looking into an rework
                setSelectedTabIndex(selectedTabIndex-1 < 0 ? 0 : selectedTabIndex-1);
            }
        }, 
        [tabs.length]
    );

    const isSelectedTabIndex = (index: number): boolean => index === selectedTabIndex;

    const getSelectedTab = (): T | null =>
        tabs.length > 0 ?
            tabs[selectedTabIndex] :
            null
    ;

    return {
        isSelectedTabIndex,
        setSelectedTabIndex,
        selectedTabIndex,
        getSelectedTab,
    };
}