import React from "react";
interface IHeading4 {
    text: string;
    modifierName?: "camel"; // Useful for when text is on a different background colour than normal
}

class Heading4 extends React.Component<React.PropsWithChildren<IHeading4>> {
    public render (): JSX.Element {
        return (
            <h4 className={`heading4${this.props.modifierName ? " heading4--" + this.props.modifierName : ""}`}>{this.props.text}</h4>
        );
    }
}

export default Heading4;
