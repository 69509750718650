import React, { useState } from "react";
import { TFormStatus } from "../../../../shared/src/codecs/codec";
import { CRMSpacingColumn } from "../CRMSpacingColumn/CRMSpacingColumn";
import { CRMMultiUploadBox } from "../CRMMultiUploadBox/CRMMultiUploadBox";
import { CRMSpacingRow } from "../CRMSpacingRow/CRMSpacingRow";
import { CRMIcon, TIcon } from "../CRMIcon/CRMIcon";
import { CRMParagraphInline } from "../Simple/CRMParagraphInline/CRMParagraphInline";
import { CRMInlineOptionsComponent } from "../CRMInlineOptionsComponent/CRMInlineOptionsComponent";
import { CRMColors, TCRMColourValue } from "../../models/CRMColors";
import { TCRMMainColours } from "../../models/TCRMMainColours";
import { WeightBold } from "../WeightBold/WeightBold";
import { TextColor } from "../BuildingBlocks/TextColor";
import { CRMCardOutsidePopupBasic } from "../CRMCardOutsidePopupBasic/CRMCardOutsidePopupBasic";
import { CRMParagraph } from "../Simple/CRMParagraph/CRMParagraph";
import CRMInputLabelAndErrorWrapComponent from "../CRMInputLabelAndErrorWrapComponent/CRMInputLabelAndErrorWrapComponent";
import CRMInputGeneralComponent from "../CRMInputs/CRMInputGeneralComponent/CRMInputGeneralComponent";
import { ListingDocumentFileIO, TListingDocumentFileIO } from "../../../../domain/codecs/fileIO/ListingDocumentFileIO";
import { array, option } from "fp-ts";
import { pipe } from "fp-ts/function";
import { ListingDocumentType, TListingDocument3, TListingDocumentEditable, TListingDocumentType } from "../../../../domain/codecs/ListingDocument";
import { v4 as uuidv4 } from "uuid";
import { CRMDropdownComponent } from "../CRMDropdownComponent/CRMDropdownComponent";
import { CRMLink } from "../CRMLink/CRMLink";
import { CRMSpacer } from "../CRMSpacer/CRMSpacer";
import { CRMRadioListComponent } from "../CRMInputs/CRMRadioListComponent/CRMRadioListComponent";
import { DateTime } from "luxon";
import { TListingStatusDisplayString } from "../../../../domain/codecs/ListingStatus";

type TFileUploadProps = {
    files: Array<TListingDocumentFileIO>;
    onUpload: (files: Array<TListingDocumentFileIO>) => void;
    onUpdate: (editable: TListingDocumentEditable) => void;
    onRemove: (file: TListingDocumentFileIO) => void;
    listingId: string;
    listingStatus: TListingStatusDisplayString;
};

export const CRMListingFileUpload = (props: TFileUploadProps): JSX.Element => {

    const [editingFileIndex, setEditingFileIndex] = useState(-1);
    const [renamingFileName, setRenamingFileName] = useState("");
    const [changingDocumentType, setChangingDocumentType] = useState<TListingDocumentType>("other");
    const [sentToSolictor, setSentToSolictor] = useState<string | null>(null);

    const getIconBasedOnStatus = (status: TFormStatus): TIcon => {
        switch (status) {
            case "submitting":
                return "pending";
            case "failure":
                return "error-outline"
            default:
                return "file-clip";
        }
    }
    
    const getTextColourBasedOnStatus = (status: TFormStatus): TCRMColourValue => {
        switch (status) {
            case "submitting":
                return CRMColors.NEUTRAL_2;
            case "failure":
                return CRMColors.HIGHLIGHTS_INSTRUCTIONAL_BRICK_0
            default:
                return CRMColors.NEUTRAL_INK;
        }
    }
    
    const getIconColourBasedOnStatus = (status: TFormStatus): TCRMMainColours => {
        switch (status) {
            case "submitting":
                return "neutral-6";
            case "failure":
                return "highlights-instructional-brick-0";
            default:
                return "neutral-ink";
        }
    }

    const closeFileEditPopup = () => {
        setChangingDocumentType("other");
        setRenamingFileName("");
        setEditingFileIndex(-1);
        setSentToSolictor(null);
    }

    const callOnUpdateFile = () => {
        props.onUpdate({
            ...props.files[editingFileIndex].output.editable_form,
            edited: {
                ...props.files[editingFileIndex].output.editable_form.edited,
                name: renamingFileName,
                document_type: changingDocumentType,
                sent_to_solicitor: sentToSolictor,
            }
        });
        closeFileEditPopup();
    };

    return (
        <div>
            <CRMSpacingColumn spacing="medium">
                {props.files.length > 0 && 
                    <CRMSpacingColumn spacing="tiny">
                        {props.files.map((file, index) => (
                            <CRMSpacingRow 
                                key={index} 
                                spacing="medium"
                                alignItems="flex-start"
                            >
                                {/* ICON & TEXT */}
                                <CRMSpacingRow>
                                    <CRMIcon
                                        iconName={getIconBasedOnStatus(file.status)}
                                        colour={getIconColourBasedOnStatus(file.status)}
                                        size="medium"
                                    />
                                    <CRMParagraphInline>
                                        {file.status === "submitting" &&
                                            <TextColor color={getTextColourBasedOnStatus(file.status)}>
                                                <WeightBold>
                                                    {file.clientSideFileForUpload?.name ?? `${file.output.editable_form.edited.name}.${file.output.file_extension}`}
                                                </WeightBold>
                                            </TextColor>
                                        }
                                        {file.status !== "submitting" && 
                                            <CRMLink
                                                href={file.output.url}
                                                linkStyle="normal"
                                                target="_blank"
                                            >
                                                <TextColor color={getTextColourBasedOnStatus(file.status)}>
                                                    <WeightBold>
                                                        {file.output.editable_form.edited.name}.{file.output.file_extension}
                                                    </WeightBold>
                                                    {' '}({file.output.editable_form.edited.document_type.replace(/\_/g, " ")})
                                                </TextColor>
                                            </CRMLink>
                                        }
                                    </CRMParagraphInline>
                                    {props.listingStatus === "SSTC" && !file.output.editable_form.edited.sent_to_solicitor && <div
                                        title="Has not been sent to solictors"
                                    >
                                        <CRMIcon
                                            iconName={"email-notification"}
                                            colour={"highlights-pertinent-yellow-0"}
                                            size="medium"
                                        />
                                    </div>}
                                </CRMSpacingRow>

                                {/* INLINE OPTIONS */}
                                {file.status !== "submitting" && <CRMInlineOptionsComponent
                                    options={[
                                        {
                                            label: "Edit",
                                            onClick: () => {
                                                setEditingFileIndex(index);
                                                setRenamingFileName(file.output.editable_form.edited.name);
                                                setChangingDocumentType(file.output.editable_form.edited.document_type);
                                                setSentToSolictor(file.output.editable_form.edited.sent_to_solicitor);
                                            },
                                        },
                                        {
                                            label: "Delete",
                                            onClick: () => props.onRemove(file),
                                        }
                                    ]}
                                />}
                            </CRMSpacingRow>
                        ))}
                    </CRMSpacingColumn>
                }

                <CRMMultiUploadBox
                    icon="files"
                    label="Upload files"
                    onFileChange={
                        (files: Array<File>) =>
                        props.onUpload(
                            pipe(
                                files,
                                array.filterMap<File, TListingDocumentFileIO>((file) => 
                                    {
                                        if (file.size > 15 * 1024 * 1024) {
                                            return option.none;
                                        }
                                        const indexOfLastFullStopInFileName = file.name.lastIndexOf(".")
                                        const obj: TListingDocument3 = {
                                            listing_id: props.listingId,
                                            name: indexOfLastFullStopInFileName >= 0 ? file.name.slice(0, indexOfLastFullStopInFileName) : file.name,
                                            file_extension: indexOfLastFullStopInFileName >= 0 ? file.name.slice(indexOfLastFullStopInFileName + 1) : "",
                                            mime_type: file.type === "" ? "text/plain" : file.type,
                                            document_type: "other" // This is set to other by default
                                        };
                                        return option.some<TListingDocumentFileIO>({
                                            ...ListingDocumentFileIO.newDefault(),
                                            status: "requiresSubmission",
                                            input: {
                                                request_ref: uuidv4(),
                                                ...obj,
                                            },  
                                            clientSideFileForUpload: file,
                                        });
                                    }
                                ),
                                (filteredFiles) => {
                                    if (filteredFiles.length < files.length) {
                                        if (filteredFiles.length === 0) {
                                            alert("The file type you attempted to upload is too large.")
                                        }
                                        else {
                                            alert("Some of the files you attempted to upload are too large. The files that weren't too large are being uploaded.")
                                        }
                                    }
                                    return filteredFiles
                                },
                            )
                        )
                    }
                />
            </CRMSpacingColumn>
            
            {/* EDIT POPUP */}
            {editingFileIndex > -1 &&
                <CRMCardOutsidePopupBasic
                    isOpen={editingFileIndex > -1}
                    title="Edit document"
                    context="neutral"
                    closeText="Cancel"
                    ctaText="Save"
                    onClose={closeFileEditPopup}
                    onCTA={callOnUpdateFile}
                >
                    <CRMParagraph>
                        <CRMInputLabelAndErrorWrapComponent label="File Name">
                            <CRMInputGeneralComponent
                                inputType="text"
                                value={renamingFileName}
                                onChange={setRenamingFileName}
                                displayError={false}
                            />
                        </CRMInputLabelAndErrorWrapComponent>
                        <CRMSpacer size="medium"/>
                        <CRMInputLabelAndErrorWrapComponent label="Document Type">
                            <CRMDropdownComponent
                                options={ListingDocumentType.values.map((value) => ({
                                    value: value, 
                                    label: value.replace(/\_/g, " ")
                                }))}
                                value={changingDocumentType}
                                displayError={false}
                                onChange={setChangingDocumentType}
                            />
                        </CRMInputLabelAndErrorWrapComponent>
                        <CRMSpacer size="medium"/>
                        <CRMInputLabelAndErrorWrapComponent label="Sent to solictor?">
                            <CRMRadioListComponent<"true" | "false", "true" | "false">
                                value={sentToSolictor === null ? "false" : "true"}
                                direction="row"
                                options={[
                                    {
                                        value: "true",
                                        text: "Yes",
                                    },
                                    {
                                        value: "false",
                                        text: "No",
                                    },
                                ]}
                                onChange={(val) => {
                                    setSentToSolictor(val === "true" ? DateTime.utc().toISO() : null);
                                }}
                            />
                        </CRMInputLabelAndErrorWrapComponent>
                    </CRMParagraph>
                </CRMCardOutsidePopupBasic>
            }
        </div>

    );
};
