import React from "react";
import { Sticky } from "../../../BuildingBlocks/Sticky";
import { CRMSingleViewHeader } from "../../../Simple/CRMSingleViewHeader/CRMSingleViewHeader";
import { TCaseTitleCheckForm, getCaseTitleCheckFieldsThatShouldBeHidden, getCompletedStateTupleFromData } from "../../../../../../domain/codecs/form/CaseTitleCheckForm";
import { CRMCaseTitleCheckIdentifyingSummary } from "../CRMCaseTitleCheckIdentifyingSummary/CRMCaseTitleCheckIdentifyingSummary";
import { CRMCaseTitleCheckStatusPill } from "../CRMCaseTitleCheckStatusPill/CRMCaseTitleCheckStatusPill";
import { Padding } from "../../../BuildingBlocks/Padding";
import { CRMSpacing } from "../../../../models/CRMSpacing";
import { SpacingColumn } from "../../../BuildingBlocks/SpacingColumn";
import { BackgroundColour } from "../../../BuildingBlocks/BackgroundColour";
import { CRMColors } from "../../../../models/CRMColors";
import { MinHeight } from "../../../BuildingBlocks/MinHeight";
import { CRMCodecEditForm } from "../../../CRMCodecEditForm/CRMCodecEditForm";
import { TitleCheckEditable } from "../../../../../../domain/codecs/TitleCheck";
import { YesNoUnknown } from "../../../../../../domain/codecs/YesNoUnknown";
import { CRMAutosaveIndicatorWrap } from "../../../CRM/CRMAutosaveIndicatorWrap/CRMAutosaveIndicatorWrap";
import { CRMColumnSpacer } from "../../../CRMColumnSpacer/CRMColumnSpacer";
import { CRMRadioButtonsFormInput } from "../../../CRMFormInputs/CRMRadioButtonsFormInput";
import { CRMInputList } from "../../../Complex/CRMInputList/CRMInputList";
import CRMInputLabelAndErrorWrapComponent from "../../../CRMInputLabelAndErrorWrapComponent/CRMInputLabelAndErrorWrapComponent";

export const CRMCaseTitleCheckSingleView = (props: {
    shortAddress: string,
    form: TCaseTitleCheckForm,
    onClose: () => void,
    onChange: (form: TCaseTitleCheckForm) => void;
    onAddTitleCheckIssue: (form: TCaseTitleCheckForm, description: string) => void;
    onDeleteTitleCheckIssue: (form: TCaseTitleCheckForm, id: string) => void;
}): JSX.Element =>
    {
        const titleCheckFormState = getCompletedStateTupleFromData({
            ...props.form.edited,
            ...props.form.children.read_only,
        });

        return <>
            {/* HEADER */}
            <Sticky zIndex={4}>
                <CRMSingleViewHeader
                    title={props.shortAddress}
                    onClick={props.onClose}
                >
                    <BackgroundColour colour={CRMColors.NEUTRAL_PAPER}>
                        <Padding spacing={`0px ${CRMSpacing.MEDIUM}`}>
                            <CRMCaseTitleCheckStatusPill
                                formState={titleCheckFormState}
                            />
                        </Padding>
                    </BackgroundColour>
                </CRMSingleViewHeader>
            </Sticky>

            <BackgroundColour colour={CRMColors.NEUTRAL_PAPER}>
                <MinHeight height="calc(100vh - 216px)">
                        <Padding spacing={CRMSpacing.MEDIUM}>
                            <SpacingColumn spacing={CRMSpacing.LARGE}>
                                <CRMCaseTitleCheckIdentifyingSummary
                                    tenure={props.form.children.read_only.tenure}
                                    isRegistered={props.form.children.read_only.is_registered}
                                    titleNumber={props.form.children.read_only.title_number}
                                />

                                <CRMAutosaveIndicatorWrap status={props.form.status}>
                                    <CRMColumnSpacer size="large"/>
                                    <CRMCodecEditForm
                                        codec={TitleCheckEditable}
                                        model={props.form.edited}
                                        validationErrors={props.form.validationErrors}
                                        onChange={(p) =>
                                            props.onChange({
                                                ...props.form,
                                                edited: {
                                                    ...props.form.edited,
                                                    ...p,
                                                }
                                            })
                                        }
                                        hideFields={getCaseTitleCheckFieldsThatShouldBeHidden({
                                            ...props.form.edited,
                                            ...props.form.children.read_only,
                                        })}
                                        overrideComps={{
                                            has_other_title_issues: (overrideProps) => <SpacingColumn spacing={CRMSpacing.MEDIUM}>
                                                <CRMRadioButtonsFormInput
                                                    {...overrideProps}
                                                    onChange={overrideProps.onChange as (string) => void}
                                                    codec={YesNoUnknown}
                                                />
                                                {overrideProps.model === "yes" &&
                                                    <CRMInputLabelAndErrorWrapComponent label={"Other Title Issues"} guidance="Please add any other issues that are not already part of the checklist (note: charges & restrictions are stored in the Charges & Restrictions block)">
                                                        <CRMInputList
                                                            value={props.form.children.title_check_issues.map((tci) => ({
                                                                meta: tci,
                                                                displayString: tci.description,
                                                            }))}
                                                            onAdd={(description) => props.onAddTitleCheckIssue(props.form, description)}
                                                            onDelete={(i) => props.onDeleteTitleCheckIssue(props.form, i.meta.id)}
                                                            disabled={props.form.edited.all_other_title_issues_added !== null}
                                                            mode="always-open"
                                                            disableAdd={props.form.children.new_title_check_issue_form.status === "submitting"}
                                                            disableDelete={props.form.children.delete_title_check_issue_form.status === "submitting"}
                                                        />
                                                    </CRMInputLabelAndErrorWrapComponent>
                                                }
                                            </SpacingColumn>
                                        }}
                                    />
                                </CRMAutosaveIndicatorWrap>
                            </SpacingColumn>
                        </Padding>
                </MinHeight>
            </BackgroundColour>
            

        </>;
    };