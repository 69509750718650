import * as t from "io-ts";

const codec = t.number;

type T = t.TypeOf<typeof codec>;

export const totalFundsAvailable = (obj: {
    funds_cash_verified_upto: number | null;
    funds_dip_verified_upto: number | null;
}): T =>
    (obj.funds_cash_verified_upto || 0) + (obj.funds_dip_verified_upto || 0);
