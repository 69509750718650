import React, { useState } from "react";
import { CRMEntityBoxBase } from "../../Simple/CRMEntityBoxBase/CRMEntityBoxBase";
import { Background } from "../../../BuildingBlocks/Background";
import { CRMHoverHighlightTextInput } from "../../../Simple/CRMHoverHighlightTextInput/CRMHoverHighlightTextInput";
import { TTransferSetupRecordForm } from "../../../../../../domain/codecs/form/TransferSetupRecordForm";
import { onChangeForm } from "../../../../../../shared/src/codecs/types/form";
import { TTransferCompanyType } from "../../../../../../domain/codecs/CaseTransferSetup";
import { CRMEntityBoxDropdown, TEntityBoxDropdownOption } from "../../Simple/CRMEntityBoxDropdown/CRMEntityBoxDropdown";
import { Relative } from "../../../BuildingBlocks/Relative";
import { CRMEntityButton } from "../../Simple/CRMEntityButton/CRMEntityButton";
import { Absolute } from "../../../BuildingBlocks/Absolute";
import { useOpenClose } from "../../../../hooks/UseOpenClose";
import { CRMSectionPopupPrompt } from "../../../Complex/CRMSectionPopupPrompt/CRMSectionPopupPrompt";
import { snakeCaseToCopyText } from "../../../../../../shared/src/util";
import { FontQuicksand } from "../../../BuildingBlocks/FontQuicksand";
import { CRMFontSizes } from "../../../../models/CRMFontSizes";
import { Text } from "../../../BuildingBlocks/Text";
import { WeightBold } from "../../../WeightBold/WeightBold";


export type TRecordEntityType = "our-side-client" | "other-side-client" | "company";;

type TRecordEntityMeta = {
    type: TRecordEntityType
};

type TRecordEntityValue = string | TTransferCompanyType;

export type TRecordEntityOption = TEntityBoxDropdownOption<TRecordEntityValue, TRecordEntityMeta>;

type TCaseContractEntityBoxProps = {
    form: TTransferSetupRecordForm;
    onChange: (form: TTransferSetupRecordForm) => void;
    onDelete: (form: TTransferSetupRecordForm) => void;
    dropdownPlaceholder?: string;
    entityOptions: Array<TRecordEntityOption>;
};

export const CRMCaseContractTransferRecord = (props: React.PropsWithChildren<TCaseContractEntityBoxProps>): JSX.Element => {

    const { ref, isOpen, toggleOpen , setIsOpen} = useOpenClose();

    const SelectedEntityID = 
        props.form.edited.company_type              ? props.form.edited.company_type
        : props.form.edited.other_side_user_case_id ? props.form.edited.other_side_user_case_id
        : props.form.edited.user_case_id
    ;
    const EntityDescription =
        props.form.edited.record_type === "last_deceased_for_as1" ? "Deceased person"
        : snakeCaseToCopyText(props.form.edited.record_type)
    ;

    const onChange = onChangeForm(props.form, props.onChange);

    const updateFormEditableWithSelectedEntity = (option: TRecordEntityOption): TTransferSetupRecordForm["edited"] => {
        if (option.meta.type === "our-side-client") {
            return {
                ...props.form.edited,
                user_case_id: option.value,
                other_side_user_case_id: null,
                company_type: null
            }
        }
        if (option.meta.type === "other-side-client") {
            return {
                ...props.form.edited,
                user_case_id: null,
                other_side_user_case_id: option.value,
                company_type: null
            }
        }
        if (option.meta.type === "company") {
            return {
                ...props.form.edited,
                user_case_id: null,
                other_side_user_case_id: null,
                company_type: option.value as TTransferCompanyType,
            }
        }
        return props.form["edited"];
    };

    const closeDeletePopup = () => setIsOpen(false);

    const onSelectEntity = (option: TRecordEntityOption) => {
        let edited = updateFormEditableWithSelectedEntity(option);
        props.onChange({ ...props.form, edited });
    }

    const onDelete = () => {
        closeDeletePopup();
        props.onDelete(props.form);
    }

    const IsDropDownSelected =
        props.form.edited.user_case_id !== null
        || props.form.edited.other_side_user_case_id !== null
        || props.form.edited.company_type !== null
    

    return (
        <Relative
            display="block"
            divRef={ref}
        >
            <Relative display="inline-flex">
                <CRMEntityBoxBase
                    icon="person"
                    width="100%"
                    FaceElement={(
                        <CRMEntityBoxDropdown<TRecordEntityValue, TRecordEntityMeta>
                            placeholder={props.dropdownPlaceholder}
                            value={SelectedEntityID}
                            options={props.entityOptions}
                            onSelect={onSelectEntity}
                        />
                    )}
                    LowerElement={(
                        <RelationshipHighlightInput
                            value={props.form.edited.text_to_append}
                            onChange={onChange("text_to_append")}
                            disabled={!IsDropDownSelected}
                        />
                    )}
                />

                <Absolute
                    top="18px"
                    right="-12px"
                >
                    <CRMEntityButton
                        icon="delete"
                        onClick={toggleOpen}
                    />
                </Absolute>
            </Relative>

            {isOpen &&
                <CRMSectionPopupPrompt
                    formStatus={"untouched"}
                    title={`Delete Record`}
                    onNo={closeDeletePopup}
                    onYes={onDelete}
                >
                    <FontQuicksand>
                        <Text size={CRMFontSizes.SMALL} lineHeight={1.2}>
                            Are you sure you want to delete this <WeightBold>{EntityDescription}</WeightBold>?
                        </Text>
                    </FontQuicksand>
                </CRMSectionPopupPrompt>
            }
        </Relative>
    );
};


type TRelationshipHighlightInput = {
    value: string;
    onChange: (value: string) => void;
    disabled: boolean;
};

const RelationshipHighlightInput = (props: TRelationshipHighlightInput) => {
    return (
        <Background 
            display="flex" 
            padding={`0px 16px 10px`}
        >
            <CRMHoverHighlightTextInput 
                placeholder={"as what for who?"}
                value={props.value}
                onChange={props.onChange}
                disabled={props.disabled}
            />
        </Background>
    );
}