import React from "react";

export interface ITableHeaderRowProps {
    title: string;
};

export const CRMTableSubTitleRow = (props: React.PropsWithChildren<ITableHeaderRowProps>): JSX.Element => {

    return (
        <div className={`crm-table-sub-title-row`}>
            {props.title}
        </div>
    );
};
