import React from "react";
import { CRMColors } from "../../../models/CRMColors";
import { CRMIcon } from "../../CRMIcon/CRMIcon";
import { WeightBold } from "../../WeightBold/WeightBold";
import { toDateFormat1 } from "../../../../../shared/src/dateTime";
import { Text } from "../../BuildingBlocks/Text";
import { CRMFontSizes } from "../../../models/CRMFontSizes";
import { Padding } from "../../BuildingBlocks/Padding";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { SpacingRow } from "../../BuildingBlocks/SpacingRow";
import { WeightRegular } from "../../WeightRegular/WeightRegular";
import { Relative } from "../../BuildingBlocks/Relative";
import { useHover } from "../../../hooks/useHover";
import { CRMTimelineControlsWrapper } from "../../CRMTimelineControlsWrapper/CRMTimelineControlsWrapper";
import { Background } from "../../BuildingBlocks/Background";
import { CursorPointer } from "../../CursorPointer/CursorPointer";
import { isAPlainFunction } from "../../../functions/functions";

export const CRMTimelinePhoneCall = (props: {
    date: string,
    callFromName: string,
    callToName: string,
    onClickDelete?: () => void;
}): JSX.Element => {
    const [ref, isHovering] = useHover<HTMLDivElement>({});
    
    return (
        <Relative divRef={ref}>
            {/* PHONE CALL CARD */}
            <Background
                color={CRMColors.NEUTRAL_PAPER}
                borderDefinition={`1px solid ${CRMColors.NEUTRAL_0}`}
                borderRadius="5px"
            >
                <Padding spacing={CRMSpacing.SMALL}>
                    <Text size={CRMFontSizes.SMALL_PLUS} color={CRMColors.NEUTRAL_0}>
                        <SpacingRow justifyContent="start" spacing={CRMSpacing.SMALL}>
                            {/* ICON */}
                            <CRMIcon
                                iconName="phone"
                                colour="neutral-0"
                                size="tiny"
                            />

                            {/* DATE */}
                            <WeightRegular>{ toDateFormat1(props.date) }</WeightRegular>

                            {/* CALL FROM  NAME */}
                            <WeightBold>{props.callFromName}</WeightBold>

                            {/* CALL ARROW */}
                            <CRMIcon
                                iconName="lined-arrow"
                                colour="neutral-0"
                                size="tiny"
                            />

                            {/* CALL TO  NAME */}
                            <WeightBold>{props.callToName}</WeightBold>
                        </SpacingRow>
                    </Text>
                </Padding>
            </Background>

            {/* DELETE */}
            {isHovering && isAPlainFunction(props.onClickDelete) && 
                <CRMTimelineControlsWrapper
                    top="0px"
                    right="0px"
                    bottom="0px"
                    backgroundColour={CRMColors.NEUTRAL_PAPER}
                >
                    <CursorPointer onClick={props.onClickDelete}>
                        <CRMIcon
                            iconName="delete"
                            colour="neutral-ink"
                            size="small"
                        />
                    </CursorPointer>
                </CRMTimelineControlsWrapper>
            }
        </Relative>
    );
};
