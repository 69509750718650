import React, { useEffect, useState } from "react";
import { TRoadblockForm } from "../../../../../domain/codecs/form/RoadblockForm";
import { pipe } from "fp-ts/lib/pipeable";
import { Background } from "../../BuildingBlocks/Background";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { Sticky } from "../../BuildingBlocks/Sticky";
import { CRMSingleViewHeader } from "../../Simple/CRMSingleViewHeader/CRMSingleViewHeader";
import { capitaliseFirst, underscoreCaseToNormalCase } from "../../../../../shared/src/utilsByDomain/string";
import { CRMRoadblockStatusLabelPopover } from "../CRMRoadblockStatusLabelPopover/CRMRoadblockStatusLabelPopover";
import { CRMLoadingBar } from "../../Simple/CRMLoadingBar/CRMLoadingBar";
import { CRMRoadblockRichTextArea, HTML__TextAreaWithCommandsEditorContent } from "../CRMRoadblockRichTextArea/CRMRoadblockRichTextArea";
import { EditorState } from "draft-js";
import { CRMBottomControlBar } from "../../CRMBottomControlBar/CRMBottomControlBar";
import { CRMRoadblockMessages } from "../CRMRoadblockMessages/CRMRoadblockMessages";
import { TChatsMessageForm } from "../../../../../domain/codecs/form/ChatsMessageForm";
import { SpacingColumn } from "../../BuildingBlocks/SpacingColumn";
import { useCallbackOnUniquePropChange } from "../../../hooks/useCallbackOnUniquePropChange";
import { getResetEditorState } from "../CRMRoadblockRichTextArea/DraftJsUtils/getResetEditorState";
import { Shape } from "../../BuildingBlocks/Shape";
import { CRMColors } from "../../../models/CRMColors";
import { Relative } from "../../BuildingBlocks/Relative";
import { Absolute } from "../../BuildingBlocks/Absolute";
import { CRMRoadblockRichTextAreaInfoPill } from "../CRMRoadblockRichTextAreaInfoPill/CRMRoadblockRichTextAreaInfoPill";
import { SpacingRow } from "../../BuildingBlocks/SpacingRow";
import { CRMBlockHeaderButton } from "../../CRM/CRMBlock/CRMBlockHeaderButton/CRMBlockHeaderButton";
import { getRoadblockForm } from "../CRMRoadblocksBlock/CRMRoadblockToFormMap";
import { Padding } from "../../BuildingBlocks/Padding";
import { TBlockType } from "../../../state/router/routerTypes";
import { createRoadblockCommandList } from "./CRMRoadblockViewChatCommands";

type TRoadblockViewChatProps = {
    sessionUserName: string;
    parentRef: React.RefObject<HTMLDivElement>;
    form: TRoadblockForm;
    onTypeMessage: (form: TChatsMessageForm) => void;
    onSubmitMessage: (form: TChatsMessageForm) => void;
    onClose: () => void;
    onOpenForm: (target: TBlockType) => void;
    onOpenCheckList: () => void;
};

export const CRMRoadblockViewChat = (props: React.PropsWithChildren<TRoadblockViewChatProps>): JSX.Element => {

    const [editorState, setEditorState] = useState(() => EditorState.createWithContent(
        HTML__TextAreaWithCommandsEditorContent(props.form.children.chat_message_form.edited.note_body)
    ));

    useEffect(
        () => {
            scrollToBottom();
        }, 
        []
    );

    useCallbackOnUniquePropChange(
        (newStatus) => {
            if (newStatus === "success") {
                scrollToBottom();
            }
        },
        props.form.status
    );

    useCallbackOnUniquePropChange(
        (newStatus) => {
            if (newStatus === "success") {
                setEditorState(getResetEditorState(editorState));
            }
        },
        props.form.children.chat_message_form.status
    );

    const IsLoading = props.form.status === "loading";
    const HasErrored = props.form.status === "failure" || props.form.status === "validationError";
    const CommandList = createRoadblockCommandList(props.form);
    const IsMessageSubmitting = props.form.children.chat_message_form.status === "submitting";
    const RoadblockLinkedForm = getRoadblockForm(props.form.children.details.roadblock_type);
    const RoadblockName = pipe(
        underscoreCaseToNormalCase(props.form.children.details.roadblock_type),
        capitaliseFirst
    );
    

    const scrollToBottom = () => {
        let blockWrap = props.parentRef.current;
        
        if (!!blockWrap) {
            blockWrap.scrollTop = blockWrap.scrollHeight;
        }
    }

    const getUpdatedChatMessageForm = (html: string) => ({
        ...props.form.children.chat_message_form,
        edited: {
            ...props.form.children.chat_message_form.edited,
            note_body: html
        }
    })

    const onTypeMessage = (editorState: EditorState, html: string) => {
        setEditorState(editorState);
        props.onTypeMessage(getUpdatedChatMessageForm(html));
    }

    const onSubmitMessage = (editorState: EditorState, html: string) => {
        props.onSubmitMessage(getUpdatedChatMessageForm(html));
    }

    return (
        <div>
            {/* HEADER */}
            <Sticky zIndex={1}>
                <CRMSingleViewHeader
                    title={RoadblockName}
                    onClick={props.onClose}
                >
                    <SpacingRow>
                        <Padding type="right" spacing={CRMSpacing.SMALL}>
                            <CRMRoadblockStatusLabelPopover
                                form={props.form}
                                onClick={props.onOpenCheckList}
                            />
                        </Padding>

                        {RoadblockLinkedForm !== null &&
                            <CRMBlockHeaderButton
                                icon="form-icon"
                                isActive={false}
                                onClick={() => props.onOpenForm(RoadblockLinkedForm)}
                            />
                        }
                    </SpacingRow>
                </CRMSingleViewHeader>
                
                {IsLoading &&
                    <CRMLoadingBar duration={2000} />
                } 
            </Sticky>
            
            {/* MESSAGES */}
            <CRMRoadblockMessages 
                form={props.form}
                onClose={props.onClose}
            />

            {/* IF LOADED: TEXTAREA */}
            {!IsLoading && !HasErrored &&
                <CRMBottomControlBar>
                    <SpacingColumn>
                        {IsMessageSubmitting ?
                            <CRMLoadingBar duration={2500} />
                            : <Shape height="1px" width="100%" color={CRMColors.NEUTRAL_PAPER} />
                        }
                        
                        <Background
                            width="100%"
                            padding={`30px ${CRMSpacing.LARGE} ${CRMSpacing.LARGE}`}
                        >
                            <Relative>
                                <CRMRoadblockRichTextArea
                                    placeholder="Begin your text here"
                                    editorState={editorState}
                                    commandList={CommandList}
                                    onChange={onTypeMessage}
                                    onSubmit={onSubmitMessage}
                                />

                                <Absolute right="0px" bottom="3px">
                                    <CRMRoadblockRichTextAreaInfoPill
                                        chatForm={props.form.children.chat_message_form}
                                    />
                                </Absolute>
                            </Relative>
                        </Background>
                    </SpacingColumn>
                </CRMBottomControlBar>
            }
        </div>
    );
};
