import { EditorState, RichUtils } from "draft-js";

export const removeSelectedBlocksStyle = (editorState: EditorState): EditorState  => {
    
    const newContentState = RichUtils.tryToRemoveBlockStyle(editorState);
    
    if (newContentState) {
        return EditorState.push(
            editorState, 
            newContentState, 
            'change-block-type'
        );
    }

    return editorState;
}