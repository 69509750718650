import * as t from "io-ts";
import * as CaseEnquiry7 from "./CaseEnquiry7";
import * as CaseDocumentType1 from "./CaseDocumentType1";
import * as CaseDocumentFile4 from "./CaseDocumentFile4";

export const codec = t.type({
    id: t.string,
    case_id: t.string,
    type: CaseDocumentType1.codec,
    due_date: t.string,
    received_date: t.union([t.string, t.null]),
    additional_name: t.string,
    from_external_source: t.boolean,
    files: t.array(CaseDocumentFile4.codec),
    all_files_checked_for_enquiries: t.boolean,
    valid_until: t.union([t.string, t.null]),
    enquiries: t.array(CaseEnquiry7.codec),
    shared_with_client: t.boolean,
    shared_with_solicitor: t.boolean,
});

export type T = t.TypeOf<typeof codec>;

export const newDefault = (): T => ({
    id: "",
    case_id: "",
    type: "ta6",
    due_date: "2021-01-01T01:02:03",
    received_date: null,
    additional_name: "",
    from_external_source: false,
    files: [],
    all_files_checked_for_enquiries: false,
    enquiries: [],
    valid_until: null,
    shared_with_client: false,
    shared_with_solicitor: false,
});
