import { array } from "fp-ts";
import { pipe } from "fp-ts/lib/function";
import React, { useState } from "react";
import { TQuoteRateForm, fold, TQuoteRateLiveRate } from "../../../../../domain/codecs/form/QuoteRateForm";
import { TUuidForm } from "../../../../../domain/codecs/form/UuidForm";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { Relative } from "../../BuildingBlocks/Relative";
import { SpacingColumn } from "../../BuildingBlocks/SpacingColumn";
import { CRMBlockPopover } from "../../CRM/CRMBlock/CRMBlockPopover/CRMBlockPopover";
import { CRMConfirmationCountdown } from "../CRMConfirmationCountdown/CRMConfirmationCountdown";
import { CRMCreatedQuoteRateCard } from "../CRMCreatedQuoteRateCard/CRMCreatedQuoteRateCard";
import { CRMDeleteOnNextPublishQuoteRateCard } from "../CRMDeleteOnNextPublishQuoteRateCard/CRMDeleteOnNextPublishQuoteRateCard";

export const CRMQuoteRatesChangesList = (props: React.PropsWithChildren<{
    forms: Array<TQuoteRateForm>,
    onOpen: (form: TQuoteRateForm | TQuoteRateLiveRate) => void,
    onDelete: (form: TUuidForm) => void,
    onUndoDeleteOnNextPublish: (form: TUuidForm) => void,
}>): JSX.Element => {
    const [isDeletingForm, setIsDeletingForm] = useState<TUuidForm | null>(null);
    const [isUndoDeleteOnNextPublishForm, setIsUndoDeleteOnNextPublishForm] = useState<TUuidForm | null>(null);

    const onDelete = (form: TUuidForm) => setIsDeletingForm(form);

    const onClearDelete = () => setIsDeletingForm(null);

    const onSubmitDelete = () => {
        if (isDeletingForm) {
            props.onDelete(isDeletingForm);
        }
        setIsDeletingForm(null);
    }

    const onUndoDeleteOnNextPublish = (form: TUuidForm) => setIsUndoDeleteOnNextPublishForm(form);

    const onClearUndoDeleteOnNextPublish = () => setIsUndoDeleteOnNextPublishForm(null);

    const onSubmitUndoDeleteOnNextPublish = () => {
        if (isUndoDeleteOnNextPublishForm) {
            props.onUndoDeleteOnNextPublish(isUndoDeleteOnNextPublishForm);
        }
        setIsUndoDeleteOnNextPublishForm(null);
    }

    return <Relative>
        {/* LIST */}
        <SpacingColumn spacing={CRMSpacing.SMALL}>
            {pipe(
                props.forms,
                array.map(fold({
                    onCreatedForm: (form) =>
                        <CRMCreatedQuoteRateCard
                            key={form.edited.id}
                            form={form}
                            onOpen={props.onOpen}
                            onDelete={onDelete}
                        />,
                    onDeleteOnNextPublishForm: (form) =>
                        <CRMDeleteOnNextPublishQuoteRateCard
                            key={form.edited.id}
                            form={form}
                            onOpen={props.onOpen}
                            onUndoDeleteOnNextPublish={onUndoDeleteOnNextPublish}
                        />,
                })),
            )}
        </SpacingColumn>

        {/* DELETE COUNTDOWN */}
        {isDeletingForm &&
            <CRMBlockPopover>
                <CRMConfirmationCountdown
                    title={`Deleting!`}
                    iconName="delete"
                    primaryButtonMode="done"
                    onUndo={onClearDelete}
                    onComplete={onSubmitDelete}
                />
            </CRMBlockPopover>
        }

        {/* UNDO DELETE ON NEXT PUBLISH COUNTDOWN */}
        {isUndoDeleteOnNextPublishForm &&
            <CRMBlockPopover>
                <CRMConfirmationCountdown
                    title={`Undoing delete on next publish!`}
                    iconName="undo"
                    primaryButtonMode="done"
                    onUndo={onClearUndoDeleteOnNextPublish}
                    onComplete={onSubmitUndoDeleteOnNextPublish}
                />
            </CRMBlockPopover>
        }
    </Relative>;
};
