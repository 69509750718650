import { filter, map, switchMap, tap } from "rxjs/operators";
import { TActionsDefinitionsList } from "./TAction";
import { action, routeAction } from "./actionFunctions";
import { PublicCreateQuoteUnfilledForm_PublicCreateQuoteFilledForm, PublicCreateQuoteUnfilledQuote, PublicQuotePage, PublicQuoteViewsMobile, PublicQuoteViewsMobileQuoteStep, TPublicCreateQuoteAddressForm, TPublicCreateQuoteFilledForm, TPublicCreateQuoteUnfilledForm, TPublicCreateQuoteUnfilledQuote, TPublicQuotePage, TPublicQuoteTransactionType, TPublicQuoteViewsMobile } from "../../../../domain/codecs/form/PublicQuoteForm";
import { pipe } from "fp-ts/lib/function";
import { array, option } from "fp-ts";
import { Observable, of } from "rxjs";
import { TQuoteRateTransactionType } from "../../../../domain/codecs/QuoteRate";
import { formOperation } from "../../wrappers/formOperation";

const setupViewSteps = (page: TPublicQuotePage): TPublicQuotePage => ({
    ...page,
    views_mobile: pipe(
        PublicQuoteViewsMobile.values,
        array.map((view: TPublicQuoteViewsMobile): Array<TPublicQuoteViewsMobile> => [{
            ...view,
            active: view.tag === "start",
        }]),
        (views) =>
            // If we are doing two quotes (e.g. a sale and purchase) add in a second set of quote questions
            page.form.edited.quotes.length === 2
                ? pipe(
                    views,
                    array.insertAt(
                        views.length - 2, // Add the second questions in before the client questions & result at the end
                        pipe(
                            PublicQuoteViewsMobileQuoteStep.values,
                            array.map((view): TPublicQuoteViewsMobile => ({
                                ...view,
                                active: false,
                                quoteIndex: 1,
                            })),
                        )
                    ),
                    option.getOrElse(() => views),
                )
                : views,
        array.flatten,
    )
});

const mapViewActive = (indexToMakeActive: number, mobileViews: Array<TPublicQuoteViewsMobile>): Array<TPublicQuoteViewsMobile> =>
    pipe(
        mobileViews,
        array.mapWithIndex((i, view) => ({
            ...view,
            active: i === indexToMakeActive,
        }))
    );

const getActiveIndex = (mobileViews: Array<TPublicQuoteViewsMobile>): number =>
    pipe(
        mobileViews,
        array.findIndex(({active}) => active),
        option.getOrElse(() => 0),
    );

const getNextIndexToBeActive = (mobileViews: Array<TPublicQuoteViewsMobile>): number =>
    pipe(
        mobileViews,
        getActiveIndex,
        (i) =>
            mobileViews[i + 1]
                ? i + 1
                : i,
    );

const mapPageToNextView = (page: TPublicQuotePage): TPublicQuotePage => ({
    ...page,
    views_mobile: pipe(
        page.views_mobile,
        getNextIndexToBeActive,
        (indexToMakeActive) => mapViewActive(indexToMakeActive, page.views_mobile),
    )
});

const removeIrrelevantMobileViews = (page: TPublicQuotePage, isClientView: boolean): TPublicQuotePage => {
    const getQuoteAtIndex = (index: number) => page.form.edited.quotes[index];

    const filterExcludeViewForTransactionTypes = (tag: TPublicQuoteViewsMobile["tag"], oneOfTransactionTypes: Array<TQuoteRateTransactionType | null>) => (view: TPublicQuoteViewsMobile) =>
        "quoteIndex" in view
        && view.tag === tag
        && oneOfTransactionTypes.includes(getQuoteAtIndex(view.quoteIndex).transaction_type)
            ? false
            : true;

    return {
        ...page,
        views_mobile: pipe(
            page.views_mobile,
            array.filter(filterExcludeViewForTransactionTypes("clients_ownership", ["sale", "remortgage"])),
            array.filter(filterExcludeViewForTransactionTypes("clients_property_count", ["sale", "remortgage"])),
            array.filter(filterExcludeViewForTransactionTypes("unregistered_property", ["purchase", "remortgage", "transfer_and_remortgage"])),
            array.filter(filterExcludeViewForTransactionTypes("new_build_property", ["remortgage", "sale", "transfer", "transfer_and_remortgage"])),
            array.filter(filterExcludeViewForTransactionTypes("mortgage_involved", ["remortgage", "transfer_and_remortgage"])),
            array.filter(filterExcludeViewForTransactionTypes("requires_search_pack", ["remortgage", "sale", "transfer", "transfer_and_remortgage"])),
            array.filter(filterExcludeViewForTransactionTypes("has_gifted_deposit", ["remortgage", "sale", "transfer", "transfer_and_remortgage"])),
            array.filter(filterExcludeViewForTransactionTypes("client_only_using_cash", ["sale", "remortgage"])),
            array.filter((view: TPublicQuoteViewsMobile) => 
                view.tag !== "introducer_user_details" 
                || (
                    view.tag === "introducer_user_details" 
                    && page.form.edited.introducer_id !== null 
                    && !isClientView
                )
            )
        )
    };
};

const removeSearchPackQuestion = (page: TPublicQuotePage, index: number): TPublicQuotePage => ({
    ...page,
    views_mobile: pipe(
        page.views_mobile,
        array.filter((view) =>
            (
                view.tag === "requires_search_pack"
                && view.quoteIndex === index
            ) === false
        )
    )
});

export const actions: TActionsDefinitionsList = [
    routeAction("VIEW_PUBLIC_LEGAL_QUOTE", (obs$, lens, set, get) => {
        obs$.pipe(
            map((payload) => ({
                ...PublicQuotePage.newDefault(),
                form: {
                    ...PublicQuotePage.newDefault().form,
                    edited: {
                        ...PublicQuotePage.newDefault().form.edited,
                        introducer_id: payload.queryStringParams.introducer_id || null,
                        quotes: [PublicCreateQuoteUnfilledQuote.newDefault()],
                    }
                },
            })),
            map(setupViewSteps),
            tap((form) => set(lens.public_quote_page.set(form))),
        ).subscribe();
    }),
    action("PUBLIC_LEGAL_QUOTE_TRANSACTION_TYPE_CHANGE", (obs$: Observable<TPublicQuoteTransactionType>, lens, set, get, route) => {
        obs$
            .pipe(
                map((transaction_type) => ({
                    ...lens.public_quote_page.get()(get()),
                    form: {
                        ...lens.public_quote_page.form.get()(get()),
                        edited: {
                            ...lens.public_quote_page.form.edited.get()(get()),
                            quotes:
                                transaction_type === "sale_and_purchase" ? [
                                    {
                                        ...PublicCreateQuoteUnfilledQuote.newDefault(),
                                        transaction_type: "sale" as TQuoteRateTransactionType,
                                    },
                                    {
                                        ...PublicCreateQuoteUnfilledQuote.newDefault(),
                                        transaction_type: "purchase" as TQuoteRateTransactionType,
                                    },
                                ]
                                : [
                                    {
                                        ...PublicCreateQuoteUnfilledQuote.newDefault(),
                                        transaction_type: transaction_type as TQuoteRateTransactionType,
                                    }
                                ]
                        }
                    }
                })),
                map(setupViewSteps),
                map((viewSteps) => removeIrrelevantMobileViews(viewSteps, route.queryParams.VIEW_PUBLIC_LEGAL_QUOTE.view === "client")),
                map(mapPageToNextView),
                tap((page) => set(lens.public_quote_page.set(page))),
            )
            .subscribe();
    }),
    action("PUBLIC_LEGAL_QUOTE_NEXT_CLICK", (obs$: Observable<undefined>, lens, set, get) => {
        obs$
            .pipe(
                map(() => lens.public_quote_page.get()(get())),
                map(mapPageToNextView),
                tap((page) => set(lens.public_quote_page.set(page))),
            )
            .subscribe();
    }),
    action("PUBLIC_LEGAL_QUOTE_CHANGE", (obs$: Observable<{quoteIndex: number, quote: TPublicCreateQuoteUnfilledQuote}>, lens, set, get) => {    
        obs$
            .pipe(
                switchMap((payload) =>
                    of(lens.public_quote_page.form.edited.quotes.get()(get()))
                        .pipe(
                            map((quotes) => {
                                quotes[payload.quoteIndex] = payload.quote;
                                return quotes;
                            }),
                            tap((quotes) => set(lens.public_quote_page.form.edited.quotes.set(quotes))),
                        )
                )
            )
            .subscribe();
    }),
    action("PUBLIC_LEGAL_QUOTE_TRANSFER_VALUE_PRICE_CHANGE", (obs$: Observable<{quoteIndex: number, value: number | null}>, lens, set, get) => {    
        obs$
            .pipe(
                switchMap((payload) =>
                    of(lens.public_quote_page.form.edited.quotes.get()(get()))
                        .pipe(
                            map((quotes) => {
                                quotes[payload.quoteIndex] = {
                                    ...quotes[payload.quoteIndex],
                                    "property_price_pence--transfer-monetary-value": payload.value,
                                    // For convenience copy the transfer amount over into the property value when it is changed
                                    "property_price_pence--property-value":
                                        payload.value
                                        && payload.value > 0
                                            ? payload.value
                                            : quotes[payload.quoteIndex]["property_price_pence--property-value"],
                                };
                                return quotes;
                            }),
                            tap((quotes) => set(lens.public_quote_page.form.edited.quotes.set(quotes))),
                        )
                )
            )
            .subscribe();
    }),
    action("PUBLIC_LEGAL_QUOTE_PROPERTY_VALUE_PRICE_CHANGE", (obs$: Observable<{quoteIndex: number, value: number | null}>, lens, set, get) => {    
        obs$
            .pipe(
                switchMap((payload) =>
                    of(lens.public_quote_page.form.edited.quotes.get()(get()))
                        .pipe(
                            map((quotes) => {
                                quotes[payload.quoteIndex] = {
                                    ...quotes[payload.quoteIndex],
                                    "property_price_pence--property-value": payload.value,
                                };
                                return quotes;
                            }),
                            tap((quotes) => set(lens.public_quote_page.form.edited.quotes.set(quotes))),
                        )
                )
            )
            .subscribe();
    }),
    action("PUBLIC_LEGAL_QUOTE_MORTGAGE_INVOLVED_CHANGE", (obs$: Observable<{quoteIndex: number, value: boolean}>, lens, set, get) => {    
        obs$
            .pipe(
                switchMap((payload) =>
                    of(lens.public_quote_page.get()(get()))
                        .pipe(
                            map((page) => {
                                page.form.edited.quotes[payload.quoteIndex] = {
                                    ...page.form.edited.quotes[payload.quoteIndex],
                                    attributes: {
                                        ...page.form.edited.quotes[payload.quoteIndex].attributes,
                                        mortgage_involved: payload.value,
                                        requires_search_pack:
                                            payload.value
                                            && page.form.edited.quotes[payload.quoteIndex].transaction_type === "purchase"
                                                ? true
                                                : page.form.edited.quotes[payload.quoteIndex].attributes.requires_search_pack,
                                    },
                                };
                                return page;
                            }),
                            map((page) =>
                                payload.value
                                    ? removeSearchPackQuestion(page, payload.quoteIndex)
                                    : page,
                            ),
                            map(mapPageToNextView),
                            tap((page) => set(lens.public_quote_page.set(page))),
                        )
                )
            )
            .subscribe();
    }),
    action("PUBLIC_LEGAL_QUOTE_RADIO_SELECT", (obs$: Observable<{quoteIndex: number, quote: TPublicCreateQuoteUnfilledQuote}>, lens, set, get) => {    
        obs$
            .pipe(
                switchMap((payload) =>
                    of(lens.public_quote_page.get()(get()))
                        .pipe(
                            map((page) => {
                                page.form.edited.quotes[payload.quoteIndex] = payload.quote;
                                return page;
                            }),
                            map(mapPageToNextView),
                            tap((page) => set(lens.public_quote_page.set(page))),
                        )
                )
            )
            .subscribe();
    }),
    action("PUBLIC_LEGAL_QUOTE_FORM_CHANGE", (obs$: Observable<TPublicCreateQuoteUnfilledForm>, lens, set, get) => {    
        obs$
            .pipe(
                tap((form) => set(lens.public_quote_page.form.set(form))),
            )
            .subscribe();
    }),
    action("PUBLIC_LEGAL_QUOTE_GO_BACK", (obs$: Observable<undefined>, lens, set, get) => {    
        obs$
            .pipe(
                map(() => lens.public_quote_page.views_mobile.get()(get())),
                map(getActiveIndex),
                map((activeIndex) => mapViewActive(activeIndex - 1, lens.public_quote_page.views_mobile.get()(get()))),
                tap((mobileViews) => set(lens.public_quote_page.views_mobile.set(mobileViews))),
            )
            .subscribe();
    }),
    action("PUBLIC_LEGAL_QUOTE_ADDRESS_FORM_CHANGE", (obs$: Observable<{quoteIndex: number, form: TPublicCreateQuoteAddressForm}>, lens, set, get) => {    
        obs$
            .pipe(
                switchMap((payload) =>
                    of(lens.public_quote_page.get()(get()))
                        .pipe(
                            map((page) => {
                                page.form.edited.quotes[payload.quoteIndex].address_form = payload.form;
                                return page;
                            }),
                            tap((page) => set(lens.public_quote_page.set(page))),
                        )
                )
            )
            .subscribe();
    }),
    action("PUBLIC_LEGAL_QUOTE_ADDRESS_FORM_AUTOCOMPLETE", (obs$: Observable<number>, lens, set, get) => {
        obs$
            .pipe(
                switchMap((payload) =>
                    of(lens.public_quote_page.get()(get()))
                        .pipe(
                            map((page) => page.form.edited.quotes[payload].address_form),
                            switchMap((form) => formOperation("AutocompletePublicQuoteAddress", form)),
                            map((response) => {
                                const edited = lens.public_quote_page.form.edited.get()(get());
                                edited.quotes[payload].address_form = response;
                                return edited;
                            }),
                            tap((edited) => set(lens.public_quote_page.form.edited.set(edited))),
                        )
                )
            )
            .subscribe();
    }),
    action("PUBLIC_LEGAL_QUOTE_SUBMIT", (obs$: Observable<undefined>, lens, set, get) => {
        obs$
            .pipe(
                map(() => lens.public_quote_page.form.get()(get())),
                filter(({status}) => status !== "submitting"),
                map((form): TPublicCreateQuoteUnfilledForm => ({
                    ...form,
                    status: "submitting",
                })),
                tap((form) => set(lens.public_quote_page.form.set(form))),
                switchMap((form) => formOperation("CreatePublicQuote", PublicCreateQuoteUnfilledForm_PublicCreateQuoteFilledForm(form))),
                tap((response) => set(lens.public_quote_page.form.set(
                    (response as unknown as TPublicCreateQuoteUnfilledForm).status === "success"
                        ? response as unknown as TPublicCreateQuoteUnfilledForm
                        : {
                            ...lens.public_quote_page.form.get()(get()),
                            status: (response as unknown as TPublicCreateQuoteUnfilledForm).status,
                            validationErrors: (response as unknown as TPublicCreateQuoteUnfilledForm).validationErrors
                        }
                ))),
                filter(({status}) => status === "success"),
                map((form) => ({
                    ...lens.public_quote_page.get()(get()),
                    form,
                })),
                map(mapPageToNextView),
                tap((page) => set(lens.public_quote_page.set(page))),
            )
            .subscribe();
    }),
];
