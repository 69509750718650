import React from "react";
import * as ListingEnquiriesViewing2 from "../../../../domain/models/ListingEnquiriesViewing2";
import { CRMSpacer } from "../../components/CRMSpacer/CRMSpacer";
import { array } from "fp-ts";
import { pipe } from "fp-ts/lib/function";
import { CRMParagraph } from "../../components/Simple/CRMParagraph/CRMParagraph";
import { CRMPadding } from "../../components/Simple/CRMPadding/CRMPadding";
import CRMPastViewingMiniComponent from "../../components/CRMPastViewingMiniComponent/CRMPastViewingMiniComponent";

class CRMListingPerformancePagePastViewingsContainer extends React.Component<{
    viewings: Array<ListingEnquiriesViewing2.T>;
}> {

    public render (): JSX.Element {
        return (
            <div>
                {/* CARD OF EACH PAST VIEWING */}
                {this.props.viewings.length ?
                     pipe(
                        this.props.viewings,
                        (viewings) => ListingEnquiriesViewing2.sortToSimpleViewingArray(viewings, "asc", "past"),
                        array.mapWithIndex((i, viewing) =>
                            viewing.status === "confirmed" ?
                                <div key={i}>
                                    {viewing.shared_with_seller_at !== null ? <CRMSpacer size="medium" /> : <CRMSpacer size="large" />}
                                    <CRMPastViewingMiniComponent
                                        viewing={viewing}
                                    />
                                </div>
                            : ""
                        )
                    )
                    // IF THERE ARE NO VIEWINGS
                    : <CRMPadding size="tiny" direction="left">
                        <CRMParagraph>
                            No past viewings
                        </CRMParagraph>
                    </CRMPadding>
                }
            </div>
        );
    }
}

export default CRMListingPerformancePagePastViewingsContainer;
