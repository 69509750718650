import React from "react";
import { FrontColors } from "../../../../models/FrontColors";
import { FrontSpacing } from "../../../../models/FrontSpacing";
import { Padding } from "../../../BuildingBlocks/Padding";
import { SpacingRow } from "../../../BuildingBlocks/SpacingRow";
import { TextColor } from "../../../BuildingBlocks/TextColor";
import { WeightMedium } from "../../../WeightMedium/WeightMedium";
import { FrontIcon, TIcon } from "../FrontIcon/FrontIcon";
import { FrontParagraphRegular } from "../FrontParagraphRegular/FrontParagraphRegular";

type TOnboardingTabProps = {
    label: string;
    status: TOnboardingTabStatus;
    isSelected: boolean;
    mode?: "filled" | "border";
    onClick: () => void;
};

export type TOnboardingTabStatus = "active" | "deferred" | "completed";

export const FrontOnboardingTab = (props: React.PropsWithChildren<TOnboardingTabProps>): JSX.Element => {

    return (
        <div
            className={`
                front-onboarding-tab
                front-onboarding-tab--${props.isSelected ? "selected" : "unselected"}
                front-onboarding-tab--${props.mode || "border"}-${props.status}
            `}
            onClick={props.onClick}
        >
            <SpacingRow 
                justifyContent="space-between"
                spacing={FrontSpacing.SMALL_2}
            >
                
                {/* LABEL */}
                <QuestionTabLabel {...props}>
                    {props.label}
                </QuestionTabLabel>

                {props.status !== "active" &&
                    <Padding type="top" spacing="4px">
                        <FrontIcon
                            iconName={getIconBasedOnStatus(props.status)}
                            colour={"neutral-6"}
                            size="small"
                        />
                    </Padding>
                }
            </SpacingRow>
        </div>
    );
};

const QuestionTabLabel = (props: React.PropsWithChildren<TOnboardingTabProps>): JSX.Element => {
    return (
        <FrontParagraphRegular>
            <WeightMedium>
                <TextColor color={props.status === "active" ? FrontColors.NEUTRAL_INK : FrontColors.NEUTRAL_6}>
                    {props.children}
                </TextColor>
            </WeightMedium>
        </FrontParagraphRegular>
    );
}

export const getIconBasedOnStatus = (status: TOnboardingTabStatus): TIcon => {
    switch (status) {
        case "completed":
            return "done";
        case "deferred":
            return "clock";
        default:
            return "chevron-right"
    }
}