import React from "react";
import { array, option, identity } from "fp-ts";
import { pipe } from "fp-ts/lib/function";
import { array as extArray } from "../../../../../shared/src/utilsByDomain/array";
import { CRMButtonIconWithLabel } from "../../CRMButtonIconWithLabel/CRMButtonIconWithLabel";
import CRMInputGeneralComponent from "../../CRMInputs/CRMInputGeneralComponent/CRMInputGeneralComponent";
import { CRMInputButtonIconWrap, ICRMInputButtonIconWrapButton } from "../../CRMInputButtonIconWrap/CRMInputButtonIconWrap";
import { CRMSpacingColumn } from "../../CRMSpacingColumn/CRMSpacingColumn";

type TAdditiveInputProps = {
    value: Array<string>;
    onChange: (value: Array<string>) => void;
    displayError?: boolean;
};

/**
 * @deprecated Use CRMInputList instead.
 */
export const CRMAdditiveInput = (props: TAdditiveInputProps): JSX.Element => {

    const onAddInput = () => props.onChange(props.value.concat(""));

    const getButtonIcons = (index: number): Array<ICRMInputButtonIconWrapButton> => {

        const addButton: ICRMInputButtonIconWrapButton = {
            title: "Add",
            variant: "primary",
            icon: "add",
            onClick: () => onAddInput(),
        };

        const deleteButton: ICRMInputButtonIconWrapButton = {
            variant: "tertiary",
            title: "Delete",
            icon: "delete",
            onClick: () => props.onChange(
               pipe(
                    props.value,
                    array.deleteAt(index),
                    option.fold(
                        () => props.value,
                        identity.flatten,
                    )
               )
            )
        };

        if (props.value.length === 1) {
            return [deleteButton, addButton];
        }

        if (index < props.value.length-1 ) {
            return [deleteButton];
        }

        if (props.value.length-1 === index && index !== 0) {
            return [deleteButton, addButton];
        }

        return [];
    }

    return (
        <CRMSpacingColumn>
            {props.value.map((text, index) => 
                <CRMInputButtonIconWrap
                    key={index}
                    buttonIcons={getButtonIcons(index)}
                >
                    <CRMInputGeneralComponent
                        inputType="text"
                        value={text}
                        displayError={props.displayError || false}
                        onChange={(updatedValue) => 
                            props.onChange(
                                extArray.updateWhereWithIndex(
                                    (memberIndex) => memberIndex === index,
                                    () => updatedValue
                                )(props.value)    
                            )
                        }
                    />
                </CRMInputButtonIconWrap>
            )}
            {/* EMPTY ARRAY - ADD BUTTON */}
            {props.value.length === 0 &&
                <CRMButtonIconWithLabel
                    label="Add"
                    icon="add"
                    variant="primary"
                    onClick={() => onAddInput()}
                />
            }
        </CRMSpacingColumn>
    );
};
