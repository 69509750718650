import React from "react";

type TFontProps = {
    size?: number;
    family?: "quicksand" | "signika-negative" | "roboto-mono";
};

/**
 * @deprecated Use individual font comps (eg FontRoboto)
 */
export const Font = (props: React.PropsWithChildren<TFontProps>): JSX.Element => (
        <div
            className={`
                font
                font--family-${props.family || "quicksand"}
            `}
            style={{
                fontSize: props.size || "14",
            }}
        >
            {props.children}
        </div>
    );
