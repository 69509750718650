import { THSLColor, THexColor } from "../models/StringLiterals";
import { TCRMColourValue } from "../models/CRMColors";

export const hexToRgb = (colour: THexColor): `${number},${number},${number}` => {
    const hex = colour.replace(/#/g, '');
    var bigint = parseInt(hex, 16);
    var r = (bigint >> 16) & 255;
    var g = (bigint >> 8) & 255;
    var b = bigint & 255;

    return r + "," + g + "," + b as `${number},${number},${number}`;
}

export const textToHSL = (text: string, saturation = 70, lightness = 88): THSLColor => {
    let hash = 0;
    for (let i = 0; i < text.length; i++) {
        hash = text.charCodeAt(i) + ((hash << 5) - hash);
        hash = hash & hash;
    }
    return `hsl(${(hash % 360)}, ${saturation}%, ${lightness}%)`;
}