import React from "react";
import { CRMParagraphBulletPoints } from "../CRMBulletList/CRMParagraphBulletPoints";
import { CRMParagraph } from "../Simple/CRMParagraph/CRMParagraph";
import { CRMSpacingColumn } from "../CRMSpacingColumn/CRMSpacingColumn";
import { WeightSemiBold } from "../WeightSemiBold/WeightSemiBold";

export const CRMCaseSignOffAsReadyForExchangePopupChecklistRemortgageComponent = (): JSX.Element =>
    <CRMSpacingColumn spacing="medium">
        <CRMParagraph>
            <WeightSemiBold>
                Before continuing please review the following high risk flags and confirm you are happy to proceed.
            </WeightSemiBold>
        </CRMParagraph>

        <CRMParagraphBulletPoints
            bullets={[
                "Is the property vacant?",
                "Is a POA in place?",
                "Are net funds only being sent to one party without reason?",
                "Is the property mortgage free?",
                "Is the client elusive, corresponds by email only?",
                "Is the client putting unnecessary pressure on to remortgage the property quickly?",
                "How did the client instruct - if not local why instruct Sail Legal?",
                "Confirm you have reviewed our Red Flags Checklist. Do you have any other concerns?",
                "Confirm clients have not changed any bank details from the beginning of the file.",
                "Confirm you have not received any alerts on change of email or phone numbers."
            ]}
        />
    </CRMSpacingColumn>;
