import * as t from "io-ts";
import * as util from "../../shared/src/util";

export const codec = t.type({
    type: t.literal("FILTER"),
    property: t.string,
    operator: t.literal("EQUALS_BOOLEAN"),
    value: t.boolean,
});

export type T = t.TypeOf<typeof codec>;

export const operatorValues = util.typeValuesFromCodec([codec.props.operator]);
