import React from "react";
import { CaseLedgerType_displayString } from "../../../../../domain/codecs/CaseLedger";
import { TCaseLedgerEntryForm } from "../../../../../domain/codecs/form/CaseLedgerEntryForm";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { SpacingRow } from "../../BuildingBlocks/SpacingRow";
import { CRMIcon } from "../../CRMIcon/CRMIcon";
import { WeightBold } from "../../WeightBold/WeightBold";
import { penceToCopyText } from "../../../../../shared/src/util";
import { CRMMultiSelectDropdownTextComponent } from "../../CRMMultiSelectDropdownTextComponent/CRMMultiSelectDropdownTextComponent";
import { DateTime } from "luxon";
import { requireExhaustive } from "../../../../../shared/src/util";

type CRMCaseLedgerRowProps = {
    entry: TCaseLedgerEntryForm;
    ledgerIsFinal: boolean;
    onDelete: (entry: TCaseLedgerEntryForm) => void;
    onChange: (entry: TCaseLedgerEntryForm) => void;
}

export const CRMCaseLedgerEntryRow = (props: CRMCaseLedgerRowProps): JSX.Element => {

    const getTotalAmountCopyText = (): string =>
        penceToCopyText(props.entry.original.vat_excluded_amount_gbp_pence + props.entry.original.vat_amount_gbp_pence)
    ;

    const getPaymentStatusLabel = (): string =>
        props.entry.edited.credit_or_debit === "credit" ? 
            props.entry.edited.slip_added_to_cashroom && props.entry.edited.paid_at ? "Paid"
            : props.entry.edited.slip_added_to_cashroom && props.entry.edited.paid_at === null ? "Not received"
            : (props.entry.edited.paid_at || props.entry.edited.user_says_paid) && props.entry.edited.slip_added_to_cashroom === null ? "Needs slip"
            : "Not paid"
        : props.entry.edited.credit_or_debit === "debit" ?
            props.entry.edited.paid_at ? "Paid"
            : props.entry.edited.slip_added_to_cashroom ? "Slip added"
            : "Needs slip"
        : requireExhaustive(props.entry.edited.credit_or_debit);
    
    return (
        <SpacingRow
            spacing={CRMSpacing.MEDIUM}
            childSize={props.ledgerIsFinal ? "1fr 130px 130px 130px" : "1fr 130px 130px 36px 130px"}
        >

            {/* TYPE */}
            <span>
                {CaseLedgerType_displayString(props.entry.original.type)}
            </span>
            
            {/* CREDIT */}
            <div>
                {props.entry.original.credit_or_debit === "credit" &&
                    <SpacingRow spacing={CRMSpacing.TINY}>
                        <CRMIcon
                            iconName="forward-arrow"
                            colour="highlights-positive-green-4"
                        />
                        <WeightBold>
                            {getTotalAmountCopyText()}
                        </WeightBold>
                    </SpacingRow>
                }
            </div>
            
            {/* DEBIT */}
            <div>
                {props.entry.original.credit_or_debit === "debit" &&
                    <SpacingRow spacing={CRMSpacing.TINY}>
                        <CRMIcon
                            iconName="backward-arrow"
                            colour="highlights-instructional-brick-2"
                        />
                        <WeightBold>
                            {getTotalAmountCopyText()}
                        </WeightBold>
                    </SpacingRow>
                }
            </div>

            {/* DELETE */}
            {!props.ledgerIsFinal &&
                <CRMIcon
                    iconName="delete"
                    colour="neutral-ink"
                    onClick={() => props.onDelete(props.entry)}
                />
            }

            {/* PAYMENT STATUS */}
            {props.entry.original.credit_or_debit === "credit" &&
                <CRMMultiSelectDropdownTextComponent
                    value={[
                        ...(props.entry.edited.paid_at ? ["paid_at"] : []),
                        ...(props.entry.edited.slip_added_to_cashroom ? ["slip_added_to_cashroom"] : []),
                        ...(props.entry.edited.user_says_paid ? ["user_says_paid"] : []),
                    ] as Array<"paid_at" | "slip_added_to_cashroom" | "user_says_paid">}
                    onChange={(values) => props.onChange({
                        ...props.entry,
                        edited: {
                            ...props.entry.edited,
                            paid_at: values.includes("paid_at")
                                ? props.entry.edited.paid_at || DateTime.utc().toISO()
                                : null,
                            slip_added_to_cashroom: values.includes("slip_added_to_cashroom")
                                ? props.entry.edited.slip_added_to_cashroom || DateTime.utc().toISO()
                                : null,
                            user_says_paid: values.includes("user_says_paid")
                                ? props.entry.edited.user_says_paid || DateTime.utc().toISO()
                                : null,
                        }
                    })}
                    options={[
                        {
                            value: "user_says_paid",
                            label: "Client says they have paid"
                        },
                        {
                            value: "slip_added_to_cashroom",
                            label: "Slip added to cashroom"
                        },
                        {
                            value: "paid_at",
                            label: "Payment received"
                        },
                    ]}
                    label={getPaymentStatusLabel()}
                />
            }
            {props.entry.original.credit_or_debit === "debit" &&
                <CRMMultiSelectDropdownTextComponent
                    value={[
                        ...(props.entry.edited.paid_at ? ["paid_at"] : []),
                        ...(props.entry.edited.slip_added_to_cashroom ? ["slip_added_to_cashroom"] : []),
                    ] as Array<"paid_at" | "slip_added_to_cashroom" >}
                    onChange={(values) => props.onChange({
                        ...props.entry,
                        edited: {
                            ...props.entry.edited,
                            paid_at: values.includes("paid_at")
                                ? props.entry.edited.paid_at || DateTime.utc().toISO()
                                : null,
                            slip_added_to_cashroom: values.includes("slip_added_to_cashroom")
                                ? props.entry.edited.slip_added_to_cashroom || DateTime.utc().toISO()
                                : null,
                        }
                    })}
                    options={[
                        {
                            value: "slip_added_to_cashroom",
                            label: "Slip added to cashroom"
                        },
                        {
                            value: "paid_at",
                            label: "Payment sent"
                        },
                    ]}
                    label={getPaymentStatusLabel()}
                />
            }
        </SpacingRow>
    );
}