import React from "react";
import { CRMCardOutsideSharpCorners } from "../../CRMCardOutsideSharpCorners/CRMCardOutsideSharpCorners";
import { SpacingColumn } from "../../BuildingBlocks/SpacingColumn";
import { SpacingRow } from "../../BuildingBlocks/SpacingRow";
import { Background } from "../../BuildingBlocks/Background";
import { WeightBold } from "../../WeightBold/WeightBold";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { TextRight } from "../../BuildingBlocks/TextRight";
import { FontSize } from "../../BuildingBlocks/FontSize";
import { CRMFontSizes } from "../../../models/CRMFontSizes";
import { CRMColors } from "../../../models/CRMColors";
import { TextHightlight } from "../../BuildingBlocks/TextHightlight";
import { penceToCopyText } from "../../../../../shared/src/util";

type TCardLedgerTotalsProps = {
    creditInPence: number;
    debitInPence: number;
};

type TBalanceState = "balanced" | "in-credit" | "in-debt"; 

export const CRMCardLedgerTotals = (props: React.PropsWithChildren<TCardLedgerTotalsProps>): JSX.Element => {

    const getBalanceState = (): TBalanceState => {
        let balance = getBalance();
        if (balance === 0) {
            return "balanced";
        }

        if (balance > 0) {
            return "in-credit";
        }

        return "in-debt";
    }

    const getBalance = (): number =>
        props.creditInPence - props.debitInPence
    ;

    return (
        <CRMCardOutsideSharpCorners>
            <SpacingColumn>
                {/* TOTALS */}
                <Background padding={`${CRMSpacing.MEDIUM} ${CRMSpacing.LARGE}`}>
                    <SpacingColumn spacing={CRMSpacing.MEDIUM}>

                        {/* TOTAL CREDIT */}
                        <SpacingRow
                            spacing={CRMSpacing.MEDIUM}
                            childSize="1fr 100px"
                        >
                            <Text>Total Credit:</Text>
                            
                            <Text>{penceToCopyText(props.creditInPence)}</Text>
                        </SpacingRow>
                        
                        {/* TOTAL DEBIT */}
                        <SpacingRow
                            spacing={CRMSpacing.MEDIUM}
                            childSize="1fr 100px"
                        >
                            <Text>Total Debit:</Text>
                            
                            <Text>{penceToCopyText(props.debitInPence)}</Text>
                        </SpacingRow>
                    </SpacingColumn>
                </Background>

                {/* TOTALS SUMMED UP */}
                <Background
                    padding={`${CRMSpacing.MEDIUM} ${CRMSpacing.LARGE}`}
                    color={CRMColors.NEUTRAL_12}
                >
                    <SpacingRow
                        spacing={CRMSpacing.MEDIUM}
                        childSize="1fr 100px"
                    >
                        <Text>
                            {getBalanceState() === "in-debt" &&
                                <span>Balance Required:</span>
                            }
                            {getBalanceState() === "in-credit" &&
                                <span>Balance to Send:</span>
                            }
                        </Text>
                        
                        {/* AMOUNT */}
                        <Text>
                            
                            {getBalanceState() !== "balanced" &&
                                <TextHightlight 
                                    color={getBalanceState() === "in-credit" ? 
                                        CRMColors.HIGHLIGHTS_POSITIVE_GREEN_10 : 
                                        CRMColors.HIGHLIGHTS_INSTRUCTIONAL_BRICK_8
                                    }
                                >
                                    {penceToCopyText(Math.abs(getBalance()))}
                                </TextHightlight>
                            }

                            {getBalanceState() === "balanced" &&
                                <span>
                                    <i>Balanced</i>
                                </span>
                            }
                        </Text>
                    </SpacingRow>
                </Background>
            </SpacingColumn>
        </CRMCardOutsideSharpCorners>
    );
};

const Text = (props: React.PropsWithChildren<{}>): JSX.Element => {
    return (
        <FontSize size={CRMFontSizes.SMALL}>
            <TextRight>
                <WeightBold>
                    {props.children}
                </WeightBold>
            </TextRight>
        </FontSize>
    );
}