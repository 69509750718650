import React from "react";
import { TCaseEnquiry1 } from "../../../../../../domain/codecs/CaseEnquiry";
import { TCasesDocumentFile1 } from "../../../../../../domain/codecs/CasesDocument";
import { TCaseChargeLegalFile2 } from "../../../../../../domain/codecs/CaseChargeLegalFile";
import { TEmailCompositionForm } from "../../../../../../domain/codecs/form/EmailCompositionForm";
import { pluralSingleText } from "../../../../../../shared/src/util";
import { CRMSpacing } from "../../../../models/CRMSpacing";
import { CRMColors } from "../../../../models/CRMColors";
import { SpacingColumn } from "../../../BuildingBlocks/SpacingColumn";
import { SpacingRow } from "../../../BuildingBlocks/SpacingRow";
import { TextHightlight } from "../../../BuildingBlocks/TextHightlight";
import { CRMIcon } from "../../../CRMIcon/CRMIcon";
import { CRMParagraph } from "../../../Simple/CRMParagraph/CRMParagraph";
import { CRMTitleParagraph } from "../../../CRMTitleParagraph/CRMTitleParagraph";
import { CRMTitleSubSection } from "../../../CRMTitleSubSection/CRMTitleSubSection";
import { CursorPointer } from "../../../CursorPointer/CursorPointer";
import { WeightSemiBold } from "../../../WeightSemiBold/WeightSemiBold";
import { TAttachmentType } from "../CRMEmailAttachmentFilterSearchSelect/CRMEmailAttachmentFilterSearchSelect";
import { CRMEnquiryAttachmentInserted } from "../CRMEnquiryAttachmentInserted/CRMEnquiryAttachmentInserted";
import { CRMFileAttachmentInserted } from "../CRMFileAttachmentInserted/CRMFileAttachmentInserted";
import { CRMRedemptionStatementAttachmentInserted } from "../CRMRedemptionStatementAttachmentInserted/CRMRedemptionStatementAttachmentInserted";
import { CRMQuoteAttachmentInserted } from "../CRMQuoteAttachmentInserted/CRMQuoteAttachmentInserted";
import { TQuoteEmailAttachment } from "../../../../../../domain/codecs/Quote";

type TComposeEmailAttachmentsProps = {
    isOpen: boolean;
    toggleVisibility: () => void;
    form: TEmailCompositionForm;
    onRemoveAttachment: (type: TAttachmentType, id: string) => void;
};

export const CRMComposeEmailAttachments = (props: React.PropsWithChildren<TComposeEmailAttachmentsProps>): JSX.Element => {

    const getAttachedEnquiriesCount = (): number => props.form.edited.attachments.enquiries.length;
    
    const getAttachedFilesCount = (): number => props.form.edited.attachments.files.length;

    const getAttachedRedemptionStatementsCount = (): number => props.form.edited.attachments.redemption_statements.length;

    const getAttachedQuotesCount = (): number => props.form.edited.attachments.quotes.length;

    const hasAttachedFiles = (): boolean => getAttachedFilesCount() > 0;
    
    const hasAttachedEnquiries = (): boolean => getAttachedEnquiriesCount() > 0;

    const hasAttachedRedemptionStatements = (): boolean => getAttachedRedemptionStatementsCount() > 0;

    const hasAttachedQuotes = (): boolean => getAttachedQuotesCount() > 0;

    const getAttachmentCount = (): number => getAttachedEnquiriesCount() + getAttachedFilesCount() + getAttachedRedemptionStatementsCount() + getAttachedQuotesCount(); 

    const hasAttachments = (): boolean => getAttachmentCount() > 0;

    return (
        <div 
            className={`
                crm-compose-email-attachments
                crm-compose-email-attachments--${props.isOpen && hasAttachments() ? "open" : "closed"}
            `}
        >
            {!props.isOpen &&
                <CursorPointer onClick={props.toggleVisibility}>
                    <SpacingColumn spacing={CRMSpacing.MEDIUM}>
                        <SpacingRow justifyContent="space-between">

                            {/* TEXT & ATTACHMENTS ADDED */}
                            <SpacingRow spacing={CRMSpacing.TINY}>
                                <CRMTitleParagraph>
                                    <WeightSemiBold>
                                        Attached:
                                    </WeightSemiBold>
                                </CRMTitleParagraph>
                                
                                <SpacingRow spacing={CRMSpacing.TINY}>
                                    {hasAttachedEnquiries() &&
                                        <TextHightlight color={CRMColors.HIGHLIGHTS_CALMING_PINK_8}>
                                            <CRMParagraph>
                                                <WeightSemiBold>
                                                    {getAttachedEnquiriesCount()} {pluralSingleText("Enquiry", "Enquiries")(getAttachedEnquiriesCount())}
                                                    {hasAttachedFiles() && ","}
                                                </WeightSemiBold>
                                            </CRMParagraph>
                                        </TextHightlight>
                                    }

                                    {hasAttachedFiles() &&
                                        <TextHightlight color={CRMColors.PRIMARY_12 }>
                                            <CRMParagraph>
                                                <WeightSemiBold>
                                                    {getAttachedFilesCount()} {pluralSingleText("File", "Files")(getAttachedFilesCount())}
                                                </WeightSemiBold>
                                            </CRMParagraph>
                                        </TextHightlight>
                                    }

                                    {hasAttachedRedemptionStatements() &&
                                        <TextHightlight color={CRMColors.HIGHLIGHTS_PERTINENT_YELLOW_6 }>
                                            <CRMParagraph>
                                                <WeightSemiBold>
                                                    {getAttachedRedemptionStatementsCount()} {pluralSingleText("Redemption statement", "Redemption statements")(getAttachedRedemptionStatementsCount())}
                                                </WeightSemiBold>
                                            </CRMParagraph>
                                        </TextHightlight>
                                    }

                                    {hasAttachedQuotes() &&
                                        <TextHightlight color={CRMColors.HIGHLIGHTS_EASY_ORANGE_10 }>
                                            <CRMParagraph>
                                                <WeightSemiBold>
                                                    {getAttachedQuotesCount()} {pluralSingleText("Quote", "Quotes")(getAttachedQuotesCount())}
                                                </WeightSemiBold>
                                            </CRMParagraph>
                                        </TextHightlight>
                                    }
                                </SpacingRow>
                            </SpacingRow>
                            
                            {/* OPEN/CLOSE ARROW */}
                            <CRMIcon
                                iconName="arrow-up"
                                colour="neutral-ink"
                                size="large"
                            />
                        </SpacingRow>
                    </SpacingColumn>
                </CursorPointer>
            }

            {/* ATTACHMENTS IN DETAIL */}
            {props.isOpen &&
                <SpacingColumn spacing={CRMSpacing.LARGE}>    
                    <CursorPointer onClick={props.toggleVisibility}>
                        <SpacingRow justifyContent="space-between">
                            <CRMTitleSubSection>
                                Attachments ({getAttachmentCount()})
                            </CRMTitleSubSection>
                            
                            <CRMIcon
                                iconName="down-arrow"
                                colour="neutral-ink"
                                size="large"
                            />
                        </SpacingRow>
                    </CursorPointer>

                    <SpacingColumn spacing={CRMSpacing.LARGE}>
                        {getAttachedEnquiriesInDetail(props.form).map((enquiry, index) => (
                            <CRMEnquiryAttachmentInserted
                                key={enquiry.id}
                                index={index+1}
                                enquiry={enquiry} 
                                onRemove={() => props.onRemoveAttachment("enquiries", enquiry.id)}
                            />
                        ))}
                        {getAttachedFileInDetail(props.form).map((file, index) => (
                            <CRMFileAttachmentInserted
                                key={file.id}
                                index={index + 1 + getAttachedEnquiriesCount()}
                                file={file} 
                                onRemove={() => props.onRemoveAttachment("files", file.id)}
                            />
                        ))}
                        {getAttachedRedemptionStatementsInDetail(props.form).map((redemptionStatement, index) => (
                            <CRMRedemptionStatementAttachmentInserted
                                key={redemptionStatement.id}
                                index={index + 1 + getAttachedEnquiriesCount() + getAttachedFilesCount()}
                                redemptionStatement={redemptionStatement} 
                                onRemove={() => props.onRemoveAttachment("redemption_statements", redemptionStatement.id)}
                            />
                        ))}
                        {getAttachedQuotesInDetail(props.form).map((quote, index) => (
                            <CRMQuoteAttachmentInserted
                                key={quote.id}
                                index={index + 1 + getAttachedEnquiriesCount() + getAttachedFilesCount() + getAttachedRedemptionStatementsCount()}
                                quote={quote} 
                                onRemove={() => props.onRemoveAttachment("quotes", quote.id)}
                            />
                        ))}
                    </SpacingColumn>
                </SpacingColumn>
            }
        </div>
    );
};

export const getAttachedEnquiriesInDetail = (emailForm: TEmailCompositionForm): Array<TCaseEnquiry1> => 
    emailForm.edited.attachments.enquiries
        .map((enquiryID) => emailForm.children.email_attachments_search_form.children.enquiries.find((enquiry) => enquiry.id === enquiryID))
        .filter((result) => result !== undefined) as Array<TCaseEnquiry1>
    ;
    
export const getAttachedFileInDetail = (emailForm: TEmailCompositionForm): Array<TCasesDocumentFile1> => 
    emailForm.edited.attachments.files
        .map((fileID) => emailForm.children.email_attachments_search_form.children.files.find((file) => file.id === fileID))
        .filter((result) => result !== undefined) as Array<TCasesDocumentFile1>
;

export const getAttachedRedemptionStatementsInDetail = (emailForm: TEmailCompositionForm): Array<TCaseChargeLegalFile2> => 
    emailForm.edited.attachments.redemption_statements
        .map((redemptionStatementId) => emailForm.children.email_attachments_search_form.children.redemption_statements.find((redemptionStatements) => redemptionStatements.id === redemptionStatementId))
        .filter((result) => result !== undefined) as Array<TCaseChargeLegalFile2>
;

export const getAttachedQuotesInDetail = (emailForm: TEmailCompositionForm): Array<TQuoteEmailAttachment> => 
    emailForm.edited.attachments.quotes
        .map((quoteId) => emailForm.children.email_attachments_search_form.children.quotes.find((quotes) => quotes.id === quoteId))
        .filter((result) => result !== undefined) as Array<TQuoteEmailAttachment>
;
