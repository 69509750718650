import { TActionObservable, TUnpackFormActionPayload } from "../../applyActions";
import * as rxjs from "rxjs";
import * as rxjsOperators from "rxjs/operators";
import * as fetchWrapper from "../../../wrappers/fetch";
import * as CaseDocumentsResponse1 from "../../../../../domain/models/CaseDocumentsResponse1";
import * as CaseResponse1 from "../../../../../domain/models/CaseResponse1";
import { option, array } from "fp-ts";
import { pipe } from "fp-ts/lib/function";
import * as util from "../../../util";
import * as FirstPartyFetchResponse from "../../../../../domain/models/FirstPartyFetchResponse";
import * as TForm from "../../../models/TForm";
import { TCaseDocument6AndCaseDocumentForm1FormList, TCaseDocument7AndCaseDocumentForm2Form, TCaseDocumentFile4AndCaseDocumentFileForm1FormList, TCase2ReadOnlyForm } from "../../../models/TFormModels";
import * as CaseDocument1 from "../../../../../domain/models/CaseDocument1";
import * as CaseDocument6 from "../../../../../domain/models/CaseDocument6";
import * as CaseDocumentFileForm1 from "../../../../../domain/models/CaseDocumentFileForm1";
import * as CaseDocumentFile2 from "../../../../../domain/models/CaseDocumentFile2";
import * as CaseDocumentFile4 from "../../../../../domain/models/CaseDocumentFile4";
import { TGetState } from "../../TGetState";
import { TSetState } from "../../TSetState";
import { CRM } from "../../State";
import { TActionPayload, TActionsDefinitionsList } from "../TAction";
import { reduceDataToStateUpdate } from "../../../functions/lens/reduceDataToStateUpdate";
import { set } from "../../../functions/lens/set";
import { setFormSubscribed } from "../../../functions/lens/setFormSubscribed";
import { submitForm } from "../../../functions/lens/submitForm";
import { pipeSubscribed, pipe as lensPipe } from "../../../functions/lens/pipe";
import { setFormWhere } from "../../../functions/lens/setFormWhere";
import { submitFormWhere } from "../../../functions/lens/submitFormWhere";
import { v4 as uuidv4 } from "uuid";

export const actions: TActionsDefinitionsList = [
    {
        type: "CRM_LEGAL_CASE_DOCUMENT_VIEW",
        run: (
            obs$: TActionObservable<"CRM_LEGAL_CASE_DOCUMENT_VIEW", null>,
            getState: TGetState,
            setState: TSetState,
        ): void => {
            obs$.pipe(
                rxjsOperators.mergeMap(() =>
                    rxjs.forkJoin([
                        makeGetDocumentsRequest(setState, getState),
                        makeGetCaseRequest(setState, getState),
                    ]),
                ),
            )
            .subscribe();
        },
    },
    {
        type: "CRM_LEGAL_CASE_DOCUMENT_VIEW_RELOAD_DOCS",
        run: (
            obs$: TActionObservable<"CRM_LEGAL_CASE_DOCUMENT_VIEW_RELOAD_DOCS", null>,
            getState: TGetState,
            setState: TSetState,
        ): void => {
            obs$.pipe(
                rxjsOperators.mergeMap(() =>
                    makeGetDocumentsRequest(setState, getState),
                ),
            )
            .subscribe();
        },
    },
    {
        type: "CRM_LEGAL_CASE_DOCUMENT_TOGGLE_FORM" as const,
        run: (
            obs$: rxjs.Observable<TActionPayload<"CRM_LEGAL_CASE_DOCUMENT_TOGGLE_FORM", {}>>,
            getState: TGetState,
            setState: TSetState,
        ) =>
            obs$.subscribe((payload) =>
                reduceDataToStateUpdate(setState)(
                    set()(
                        ["activeData", "crm", "legalCaseDocuments", "newDocumentForm"],
                        (form) => {
                            form.ui.isShowingForm = !form.ui.isShowingForm;
                            return form;
                        }
                    ),
                )(payload)
            ),
    },
    {
        type: "CRM_LEGAL_CASE_DOCUMENT_NEW_DOCUMENT_INPUT_CHANGED",
        run: setFormSubscribed<"CRM_LEGAL_CASE_DOCUMENT_NEW_DOCUMENT_INPUT_CHANGED", TCaseDocument7AndCaseDocumentForm2Form>()(
            ["activeData", "crm", "legalCaseDocuments", "newDocumentForm"],
        ),
    },
    {
        type: "CRM_LEGAL_CASE_DOCUMENT_NEW_DOCUMENT_SUBMIT" as const,
        run: (
            obs$: rxjs.Observable<TActionPayload<"CRM_LEGAL_CASE_DOCUMENT_NEW_DOCUMENT_SUBMIT", undefined>>,
            getState: TGetState,
            setState: TSetState,
        ) =>
            submitForm<"CRM_LEGAL_CASE_DOCUMENT_NEW_DOCUMENT_SUBMIT", undefined, TCaseDocument7AndCaseDocumentForm2Form["view"], TCaseDocument7AndCaseDocumentForm2Form["edit"], TCaseDocument7AndCaseDocumentForm2Form["ui"]>()(
                ["activeData", "crm", "legalCaseDocuments", "newDocumentForm"],
                () => `/v1/cases/${getState().routes.params.caseId}/documents`,
                "POST",
                CaseDocument1.validator,
                CaseDocument6,
                (_, response) => {
                    rxjs.forkJoin([
                        makeGetDocumentsRequest(setState, getState),
                        rxjs.of(
                            setState(({ ...s }) => {
                                s.activeData.crm.legalCaseDocuments.newDocumentForm.edit = new CRM().legalCaseDocuments.newDocumentForm.edit;
                                if (response) {
                                    s.activeData.crm.legalCaseDocuments.justFlaggedDocumentIds = [
                                        ...s.activeData.crm.legalCaseDocuments.justFlaggedDocumentIds,
                                        response.id,
                                    ];
                                }
                                return s;
                            }),
                        ),
                    ])
                        .subscribe();
                }
            )(obs$, getState, setState).subscribe(),
    },
    {
        type: "CRM_LEGAL_CASE_DOCUMENT_FILES_TO_UPLOAD" as const,
        run: (
            obs$: rxjs.Observable<TActionPayload<"CRM_LEGAL_CASE_DOCUMENT_FILES_TO_UPLOAD", {
                documentId: string,
                files: File[],
            }>>,
            getState,
            setState,
            dispatch
        ) => obs$.pipe(
            rxjsOperators.tap((ob) => {
                return setState(({ ...s }) => {
                    s.activeData.crm.legalCaseDocuments.activeUploads[ob.payload.documentId] = {}
                    return s;
                });
            }),
            rxjsOperators.mergeMap((ob) => ob.payload.files.map((file) => ({file: file, documentId: ob.payload.documentId, temp_id: uuidv4()}))),
            rxjsOperators.tap((ob) => {
                return setState(({ ...s }) => {
                    s.activeData.crm.legalCaseDocuments.activeUploads[ob.documentId] = {
                        ...(s.activeData.crm.legalCaseDocuments.activeUploads[ob.documentId] ?? []),
                        [ob.temp_id]: "in_progress",
                    }
                    return s;
                });
            }),
            rxjsOperators.concatMap((ob) =>
                rxjs.of(ob.temp_id).pipe(
                    rxjsOperators.mergeMap( () => 
                        rxjs.from(
                            fetchWrapper.file({
                                requestParams: {
                                    url: `${env.REACT_APP_API_URL}/v1/cases/${getState().routes.params.caseId}/documents/${ob.documentId}/files`,
                                    method: "POST",
                                    body: ob.file,
                                    headers: {
                                        "Content-Type": "application/octet-stream",
                                        "x-sail-name": ob.file.name,
                                        "x-sail-mime-type": ob.file.type,
                                        "x-sail-file-extension": getFileExtension(ob.file.name)
                                    }
                                },
                            })(),
                        )
                    ),
                    rxjsOperators.tap((response) => {
                        return setState(({ ...s }) => {
                            s.activeData.crm.legalCaseDocuments.activeUploads[ob.documentId][ob.temp_id] = response.tag === "204" ? "complete" : "error"
                            return s;
                        });
                    }),
                )
            ),
            rxjsOperators.debounceTime(300),
            rxjsOperators.tap(() =>
                {
                    dispatch({
                        type: "CRM_LEGAL_CASE_DOCUMENT_VIEW_RELOAD_DOCS",
                        payload: null,
                    });
                }
            ),
        ).subscribe()
    },
    {
        type: "CRM_LEGAL_CASE_DOCUMENT_DELETE_DOCUMENT" as const,
        run: pipeSubscribed<"CRM_LEGAL_CASE_DOCUMENT_DELETE_DOCUMENT", { resourceId: string }>(
            submitFormWhere<"CRM_LEGAL_CASE_DOCUMENT_DELETE_DOCUMENT", { resourceId: string }, TCaseDocument6AndCaseDocumentForm1FormList["forms"][number]["view"], TCaseDocument6AndCaseDocumentForm1FormList["forms"][number]["edit"], TCaseDocument6AndCaseDocumentForm1FormList["forms"][number]["ui"]>()(
                ["activeData", "crm", "legalCaseDocuments", "documentFormList", "forms"],
                (form, action) => form.view.id === action.resourceId,
                (form) => `/v1/cases/${form.view.case_id}/documents/${form.view.id}`,
                "DELETE",
                () => option.none,
                undefined,
                (_, response, setState, getState) => {
                    (response?.tag === "204" ? makeGetDocumentsRequest(setState, getState)
                    : rxjs.EMPTY)
                        .subscribe();
                }
            ),
        ),
    },
    {
        type: "CRM_LEGAL_CASE_DOCUMENT_CHANGE_DOCUMENT" as const,
        run: (
            obs$: rxjs.Observable<TActionPayload<"CRM_LEGAL_CASE_DOCUMENT_CHANGE_DOCUMENT", TUnpackFormActionPayload<TCaseDocument6AndCaseDocumentForm1FormList["forms"][number]>>>,
            getState: TGetState,
            setState: TSetState,
        ) =>
            obs$.pipe(
                rxjsOperators.mergeMap((obs) =>
                    setFormWhere<"CRM_LEGAL_CASE_DOCUMENT_CHANGE_DOCUMENT", TCaseDocument6AndCaseDocumentForm1FormList["forms"][number]>()(
                        ["activeData", "crm", "legalCaseDocuments", "documentFormList", "forms"],
                        (form, action) => form.view.id === action.resourceId,
                    )(rxjs.of(obs), getState, setState)
                        .pipe(
                            rxjsOperators.mapTo(obs),
                        ),
                ),
                rxjsOperators.mergeMap((obs) =>
                    // if the valid_until field gets set to null, uncheck all files received
                    // this is so that resetting the expiry is enforced
                    obs.payload.key === "valid_until"
                    && obs.payload.value === null
                    ? setFormWhere<"CRM_LEGAL_CASE_DOCUMENT_CHANGE_DOCUMENT", TCaseDocument6AndCaseDocumentForm1FormList["forms"][number]>()(
                        ["activeData", "crm", "legalCaseDocuments", "documentFormList", "forms"],
                        (form, action) => form.view.id === action.resourceId,
                    )(rxjs.of({
                        type: "CRM_LEGAL_CASE_DOCUMENT_CHANGE_DOCUMENT",
                        payload: { key: "received_date", value: null, resourceId: obs.payload.resourceId }
                    }), getState, setState)
                        .pipe(
                            rxjsOperators.mapTo(obs),
                        )
                    : rxjs.of(obs)
                ),
                rxjsOperators.debounceTime(250),
                rxjsOperators.mergeMap((obs) =>
                    submitFormWhere<"CRM_LEGAL_CASE_DOCUMENT_CHANGE_DOCUMENT", TUnpackFormActionPayload<TCaseDocument6AndCaseDocumentForm1FormList["forms"][number]>, TCaseDocument6AndCaseDocumentForm1FormList["forms"][number]["view"], TCaseDocument6AndCaseDocumentForm1FormList["forms"][number]["edit"], TCaseDocument6AndCaseDocumentForm1FormList["forms"][number]["ui"]>()(
                        ["activeData", "crm", "legalCaseDocuments", "documentFormList", "forms"],
                        (form, action) => form.view.id === action.resourceId,
                        (form) => `/v1/cases/${form.view.case_id}/documents/${form.view.id}`,
                        "PATCH",
                        () => option.none,
                        undefined,
                        (_, response) => {
                            (response?.tag === "204" ? makeGetDocumentsRequest(setState, getState)
                            : rxjs.EMPTY)
                                .subscribe();
                        }
                    )(rxjs.of(obs), getState, setState),
                ),
            ).subscribe()
    },
    {
        type: "CRM_LEGAL_CASE_DOCUMENT_CHANGE_DOCUMENT_FILE" as const,
        run: (
            obs$: rxjs.Observable<TActionPayload<"CRM_LEGAL_CASE_DOCUMENT_CHANGE_DOCUMENT_FILE", TUnpackFormActionPayload<TCaseDocumentFile4AndCaseDocumentFileForm1FormList["forms"][number]>>>,
            getState: TGetState,
            setState: TSetState,
        ) =>
            lensPipe<"CRM_LEGAL_CASE_DOCUMENT_CHANGE_DOCUMENT_FILE", TUnpackFormActionPayload<TCaseDocumentFile4AndCaseDocumentFileForm1FormList["forms"][number]>>(
                setFormWhere<"CRM_LEGAL_CASE_DOCUMENT_CHANGE_DOCUMENT_FILE", TCaseDocumentFile4AndCaseDocumentFileForm1FormList["forms"][number]>()(
                    ["activeData", "crm", "legalCaseDocuments", "documentFilesFormList", "forms"],
                    (form, action) => form.view.id === action.resourceId,
                ),
                submitFormWhere<"CRM_LEGAL_CASE_DOCUMENT_CHANGE_DOCUMENT_FILE", TUnpackFormActionPayload<TCaseDocumentFile4AndCaseDocumentFileForm1FormList["forms"][number]>, TCaseDocumentFile4AndCaseDocumentFileForm1FormList["forms"][number]["view"], TCaseDocumentFile4AndCaseDocumentFileForm1FormList["forms"][number]["edit"], TCaseDocumentFile4AndCaseDocumentFileForm1FormList["forms"][number]["ui"]>()(
                    ["activeData", "crm", "legalCaseDocuments", "documentFilesFormList", "forms"],
                    (form, action) => form.view.id === action.resourceId,
                    (form) => `/v1/cases/${getState().routes.params.caseId}/documents/${form.view.cases_document_id}/files/${form.view.id}`,
                    "PATCH",
                    CaseDocumentFile2.validator,
                    undefined,
                    (_, response) => {
                        (response?.tag === "204" ? makeGetDocumentsRequest(setState, getState)
                        : rxjs.EMPTY)
                            .subscribe();
                    }
                ),
            )(obs$, getState, setState).subscribe(),
    },
    {
        type: "CRM_LEGAL_CASE_DOCUMENT_DELETE_DOCUMENT_FILE" as const,
        run: (
            obs$: rxjs.Observable<TActionPayload<"CRM_LEGAL_CASE_DOCUMENT_DELETE_DOCUMENT_FILE", { resourceId: string }>>,
            getState: TGetState,
            setState: TSetState,
        ) =>
            submitFormWhere<"CRM_LEGAL_CASE_DOCUMENT_DELETE_DOCUMENT_FILE", { resourceId: string }, TCaseDocumentFile4AndCaseDocumentFileForm1FormList["forms"][number]["view"], TCaseDocumentFile4AndCaseDocumentFileForm1FormList["forms"][number]["edit"], TCaseDocumentFile4AndCaseDocumentFileForm1FormList["forms"][number]["ui"]>()(
                ["activeData", "crm", "legalCaseDocuments", "documentFilesFormList", "forms"],
                (form, action) => form.view.id === action.resourceId,
                (form) => `/v1/cases/${getState().routes.params.caseId}/documents/${form.view.cases_document_id}/files/${form.view.id}`,
                "DELETE",
                () => option.none,
                undefined,
                (_, response) => {
                    (response?.tag === "204" ? makeGetDocumentsRequest(setState, getState)
                    : rxjs.EMPTY)
                        .subscribe();
                }
            )(obs$, getState, setState).subscribe(),
    },
];

const makeGetCaseRequest = (setState: TSetState, getState: TGetState): rxjs.Observable<void> =>
    rxjs.from(
        fetchWrapper.json<CaseResponse1.T>({
            requestParams: {
                url: `${env.REACT_APP_API_URL}/v1/cases/${getState().routes.params.caseId}`,
                method: "GET",
                body: option.none,
            },
            expectedTypeCodec: CaseResponse1.codec,
            defaultResponse: CaseResponse1.newDefault(),
        })(),
    ).pipe(
        rxjsOperators.tap(util.defaultCRMRequestErrorHandler),
        rxjsOperators.tap(
            reduceDataToStateUpdate<FirstPartyFetchResponse.T<CaseResponse1.T>>(setState)(
                set<FirstPartyFetchResponse.T<CaseResponse1.T>>()(
                    ["activeData", "crm", "legalCaseDocuments", "caseForm"],
                    (form, res) => TForm.dataToForm<TCase2ReadOnlyForm>({}, {})(res.response.data),
                ),
            )
        ),
        rxjsOperators.mapTo(undefined),
    );

const makeGetDocumentsRequest = (setState: TSetState, getState: TGetState): rxjs.Observable<void> =>
    rxjs.from(
        fetchWrapper.json<CaseDocumentsResponse1.T>({
            requestParams: {
                url: `${env.REACT_APP_API_URL}/v1/cases/${getState().routes.params.caseId}/documents`,
                method: "GET",
                body: option.none,
            },
            expectedTypeCodec: CaseDocumentsResponse1.codec,
            defaultResponse: CaseDocumentsResponse1.newDefault(),
        })(),
    ).pipe(
        rxjsOperators.tap(util.defaultCRMRequestErrorHandler),
        rxjsOperators.tap(
            reduceDataToStateUpdate<FirstPartyFetchResponse.T<CaseDocumentsResponse1.T>>(setState)(
                set<FirstPartyFetchResponse.T<CaseDocumentsResponse1.T>>()(
                    ["activeData", "crm", "legalCaseDocuments", "documentFormList"],
                    (formList, res) => TForm.requestToFormList<TCaseDocument6AndCaseDocumentForm1FormList["forms"][number]>(formList, res),
                ),
                set<FirstPartyFetchResponse.T<CaseDocumentsResponse1.T>>()(
                    ["activeData", "crm", "legalCaseDocuments", "documentFilesFormList"],
                    (_, res) =>
                         pipe(
                            res.response.data,
                            array.map((document): TCaseDocumentFile4AndCaseDocumentFileForm1FormList["forms"] =>
                                 pipe(
                                    document.files,
                                    array.map((file) => ({
                                        ...TForm.defaultTFormV2(CaseDocumentFile4.newDefault(), CaseDocumentFileForm1.newDefault(), {}),
                                        view: file,
                                        edit: file,
                                        status: "untouched",
                                    })),
                                )
                            ),
                            array.flatten,
                            (forms): TCaseDocumentFile4AndCaseDocumentFileForm1FormList => ({
                                ...TForm.defaultTListForm(CaseDocumentFileForm1.newDefault(), {}),
                                forms,
                            })
                        )
                ),
            )
        ),
        rxjsOperators.mapTo(undefined),
    );

const getFileExtension = (fname: string) => fname.slice((fname.lastIndexOf(".") - 1 >>> 0) + 2);