import React from "react";
import { FrontColors } from "../../../models/FrontColors";
import { FrontSpacing } from "../../../models/FrontSpacing";
import { Flex } from "../../BuildingBlocks/Flex";
import { MaxWidth } from "../../BuildingBlocks/MaxWidth";
import { SpacingColumn } from "../../BuildingBlocks/SpacingColumn";
import { FPLandingTitle } from "../Simple/FPLandingTitle/FPLandingTitle";
import { FPSimpleLogo } from "../Simple/FPSimpleLogo/FPSimpleLogo";
import { TPublicPropertyReportCreateForm } from "../../../../../domain/codecs/form/PublicPropertyReportForm";
import { LandRegistryOfficialCopyWithSummaryResponseSuccess } from "../../../../../domain/codecs/LandRegistry";
import { FPPropertyReportResultsSuccess } from "./FPPropertyReportResultsSuccess";
import { doesErrorCodeExist } from "../../../../../shared/src/codecs/errors";
import { FPError } from "../Complex/FPError/FPError";
import { FontQuicksand } from "../../BuildingBlocks/FontQuicksand";
import { FontSize } from "../../BuildingBlocks/FontSize";
import { TextAlign } from "../../BuildingBlocks/TextAlign";
import { TextColor } from "../../BuildingBlocks/TextColor";
import { WeightSemiBold } from "../../WeightSemiBold/WeightSemiBold";
import { FPPropertyReportSubmitting } from "./FPPropertyReportSubmitting";

export const FPPropertyReportResults = (props: {
    form: TPublicPropertyReportCreateForm,
}): JSX.Element => {
    const isSuccess = props.form.children.response?._tag === "success";

    const isSubmitting = props.form.status === "submitting";

    const isRecaptchaError = doesErrorCodeExist("RecaptchaFailed", props.form.validationErrors);

    const isLandRegistryTitleNotAvailable =
        props.form.children.response?._tag === "error"
        && (
            props.form.children.response.code === "not_available_electronically"
            ||  props.form.children.response.code === "title_number_invalid"
        );

    const isLandRegistryFaultError =
        props.form.children.response?._tag === "error"
        && props.form.children.response.code === "fault";

    const isServiceUnavailableError =
        props.form.children.response?._tag === "out_of_hours"
        || doesErrorCodeExist("ServiceUnavailable", props.form.validationErrors);

    const isError =
        isRecaptchaError
        || isLandRegistryTitleNotAvailable
        || isLandRegistryFaultError
        || isServiceUnavailableError;

    const successData =
        props.form.children.response?._tag === "success"
            ? props.form.children.response
            : LandRegistryOfficialCopyWithSummaryResponseSuccess.newDefault();

    const errorMessages = [
        ...(isRecaptchaError ? ["Something went wrong when submitting your request, please refresh the page and try again"] : []),
        ...(isLandRegistryTitleNotAvailable ? ["Land Registry do not hold an electronic copy of the data for this property. Unfortunately we wont be able to generate a Property Report for this address"] : []),
        ...(isLandRegistryFaultError ? ["Land Registry are having difficulties at the moment, please try again later"] : []),
        ...(isServiceUnavailableError ? ["Land Registry's service is currently offline, please try again later"] : []),
    ];

    const address = (
        props.form.children.response?._tag === "success"
            ? props.form.children.response.address || ""
            : ""
    ).toUpperCase();

    return <SpacingColumn
        spacing={FrontSpacing.LARGE_1}
        justifyContent="center"
    >
        {/* LOGO */}
        <Flex justifyContent="center">
            <FPSimpleLogo width="95px" />
        </Flex>

        {/* HEADER */}
        <Flex justifyContent="center">
            <MaxWidth width="530px">
                <FPLandingTitle
                    size={60}
                    color={FrontColors.NEUTRAL_18}
                    align="center"
                >
                    Your Report.
                </FPLandingTitle>
            </MaxWidth>
        </Flex>

        {/* ADDRESS */}
        <Flex justifyContent="center">
            <MaxWidth width="530px">
                <Flex justifyContent="center">
                    <MaxWidth width="425px">
                        <FontQuicksand>
                            <FontSize
                                size={20}
                                lineHeight={1.2}
                            >
                                <WeightSemiBold>
                                    <TextColor color={FrontColors.NEUTRAL_18}>
                                        <TextAlign position="center">
                                            {address}
                                        </TextAlign>
                                    </TextColor>
                                </WeightSemiBold>
                            </FontSize>
                        </FontQuicksand>
                    </MaxWidth>
                </Flex>
            </MaxWidth>
        </Flex>

        <Flex justifyContent="center">
            {/* SUBMITTING - PLACEHOLDER */}
            {isSubmitting &&
                <FPPropertyReportSubmitting />
            }

            {/* SUCCESS - SHOW RESULTS */}
            {isSuccess &&
                <FPPropertyReportResultsSuccess
                    deceasedName={props.form.edited.deceased_name}
                    data={successData}
                    titleRegisterUrl={props.form.children.title_register_url}
                    transactionType={props.form.edited.transaction_type || "unknown"}
                    quotes={props.form.children.quotes}
                />
            }

            {/* ERROR */}
            {isError &&
                <FPError
                    errorMessages={errorMessages}
                />
            }
        </Flex>
    </SpacingColumn>;
};
