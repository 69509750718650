import React from "react";
import { Background } from "../../../BuildingBlocks/Background";
import { FrontSpacing } from "../../../../models/FrontSpacing";
import { SpacingRow } from "../../../BuildingBlocks/SpacingRow";
import { Padding } from "../../../BuildingBlocks/Padding";
import { FrontColors } from "../../../../models/FrontColors";
import { FrontIcon } from "../../../Front/Simple/FrontIcon/FrontIcon";
import { SpacingColumn } from "../../../BuildingBlocks/SpacingColumn";
import { FontQuicksand } from "../../../BuildingBlocks/FontQuicksand";
import { FontSize } from "../../../BuildingBlocks/FontSize";
import { FrontFontSizes } from "../../../../models/FrontFontSizes";
import { TextColor } from "../../../BuildingBlocks/TextColor";
import { WeightSemiBold } from "../../../WeightSemiBold/WeightSemiBold";
import { FrontBulletpoint } from "../../../Front/Simple/FrontBulletpoint/FrontBulletpoint";
import { WeightMedium } from "../../../WeightMedium/WeightMedium";
import { AnimationPopout } from "../../../BuildingBlocks/AnimationPopout";

export const FPError = (props: {
    errorMessages: Array<string>,
}): JSX.Element =>
    <AnimationPopout>
        <Background
            color="#F7E2EF"
            borderRadius="5px"
        >
            <SpacingRow
                justifyContent="start"
                alignItems="flex-start"
                childSize="1fr auto"
            >
                {/* ERROR MESSAGES */}
                <Padding spacing={FrontSpacing.MEDIUM_1}>
                    <SpacingColumn spacing={FrontSpacing.MEDIUM_1}>
                        <FontQuicksand>
                            <FontSize size={FrontFontSizes.MED}>
                                <TextColor color={FrontColors.NEUTRAL_INK}>
                                    <WeightSemiBold>
                                        There seems to be an issue.
                                    </WeightSemiBold>
                                </TextColor>
                            </FontSize>
                        </FontQuicksand>

                        <Padding spacing={`0px 0px 0px ${FrontSpacing.SMALL_2}`}>
                            <SpacingColumn spacing={FrontSpacing.SMALL_2}>
                                {props.errorMessages.map((errorMessage, i) =>
                                    <SpacingRow
                                        key={i}
                                        justifyContent="start"
                                        alignItems="flex-start"
                                        spacing={FrontSpacing.SMALL_2}
                                    >
                                        <FrontBulletpoint />

                                        <FontQuicksand>
                                            <FontSize
                                                size={FrontFontSizes.SMALL}
                                                lineHeight={1.4}
                                            >
                                                <TextColor color={FrontColors.NEUTRAL_INK}>
                                                    <WeightMedium>
                                                        {errorMessage}
                                                    </WeightMedium>
                                                </TextColor>
                                            </FontSize>
                                        </FontQuicksand>
                                    </SpacingRow>
                                )}
                            </SpacingColumn>
                        </Padding>
                    </SpacingColumn>
                </Padding>

                {/* ICON */}
                <Background
                    borderRadius="5px"
                    borderDefinition={`solid 2px ${FrontColors.PROBATE_0}`}
                    padding="8px"
                >
                    <FrontIcon
                        iconName="bulb"
                        colour={FrontColors.PROBATE_0}
                        size="26px"
                    />
                </Background>
            </SpacingRow>
        </Background>
    </AnimationPopout>;
