import React from "react";
import { FrontSpacing } from "../../../../models/FrontSpacing";
import { SpacingRow } from "../../../BuildingBlocks/SpacingRow";
import { FrontInputGeneralComponent } from "../FrontInputGeneralComponent/FrontInputGeneralComponent";
import { useSplitInput } from "../../../../hooks/useSplitInput";

type TFrontSortCodeInputProps = {
    value: string;
    excludeInFullstory?: boolean;
    onChange: (value: string) => void;
};

export const FrontSortCodeInput = (props: React.PropsWithChildren<TFrontSortCodeInputProps>): JSX.Element => {

    const { partStates } = useSplitInput({
        value: props.value,
        chunks: [2, 2, 2],
        onChange: props.onChange,
    });

    return (
        <div className={`front-sort-code-input`}>
            <SpacingRow spacing={FrontSpacing.SMALL_3}>
                {partStates.map(({ value, setValue, maxLength, ref }, key) => (
                    <div key={key} className="front-sort-code-input__input">
                        <FrontInputGeneralComponent
                            value={value}
                            excludeInFullstory={props.excludeInFullstory}
                            inputRef={ref}
                            inputType="numericString"
                            maxLength={maxLength}
                            displayError={false}
                            onChange={setValue}
                            onPressEnterKey={() => null}
                        />
                    </div>
                ))}
            </SpacingRow>
        </div>
    );
};
