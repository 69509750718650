import { array } from "fp-ts";
import { pipe } from "fp-ts/lib/function";
import React from "react";
import { TQuoteRateCreatedForm } from "../../../../../domain/codecs/form/QuoteRateForm";
import { QuoteRateAttribute, QuoteRateAttribute_displayString, QuoteRateClientsOwnership, QuoteRateClientsOwnership_displayString, QuoteRateClientsPropertyCount, QuoteRateClientsPropertyCount_displayString, QuoteRatePropertyResidentialCommercial, QuoteRatePropertyResidentialCommercial_displayString, QuoteRateTaxGroup, QuoteRateTaxGroupLTT, QuoteRateTaxGroupSDLT, QuoteRateTaxGroup_displayString, QuoteRateTransactionType, QuoteRateTransactionType_displayString, TQuoteRateAttribute, TQuoteRateClientsOwnership, TQuoteRateClientsPropertyCount, TQuoteRatePropertyResidentialCommercial, TQuoteRateTaxGroup, TQuoteRateTaxGroupLTT, TQuoteRateTaxGroupSDLT, TQuoteRateTransactionType } from "../../../../../domain/codecs/QuoteRate";
import { Tenure1_Tenure2, Tenure3, TTenure3 } from "../../../../../domain/codecs/Tenure";
import { onChangeForm } from "../../../../../shared/src/codecs/types/form";
import { IOption } from "../../../hooks/UseDropdown";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { SpacingColumn } from "../../BuildingBlocks/SpacingColumn";
import { CRMDropdownComponent } from "../../CRMDropdownComponent/CRMDropdownComponent";
import CRMInputLabelAndErrorWrapComponent from "../../CRMInputLabelAndErrorWrapComponent/CRMInputLabelAndErrorWrapComponent";
import { CRMTitleSubSection } from "../../CRMTitleSubSection/CRMTitleSubSection";
import { CRMCheckboxList } from "../../Simple/CRMCheckboxList/CRMCheckboxList";

export const CRMQuoteRatesSingleViewEditAppliesWhen = (props: React.PropsWithChildren<{
    form: TQuoteRateCreatedForm,
    displayError: boolean,
    onChange: (form: TQuoteRateCreatedForm) => void,
}>): JSX.Element => {
    const isSDLTRateSelected = props.form.edited.rate_type === "stamp_duty_land_tax";

    const isLTTRateSelected = props.form.edited.rate_type === "land_transaction_tax";

    const isAnyTaxRateSelected =
        isSDLTRateSelected
        || isLTTRateSelected;

    const taxGroupOptions: Array<TQuoteRateTaxGroupSDLT | TQuoteRateTaxGroupLTT> =
        isSDLTRateSelected ? QuoteRateTaxGroupSDLT.values
        : isLTTRateSelected ? QuoteRateTaxGroupLTT.values
        : [];

    const onChangeTransactionType = (transactionType: TQuoteRateTransactionType) =>
        pipe(
            props.form.edited.applies_when_any_of_transaction_types.includes(transactionType)
                ? pipe(
                    props.form.edited.applies_when_any_of_transaction_types,
                    array.filter((type) => type !== transactionType),
                )
                : [
                    ...props.form.edited.applies_when_any_of_transaction_types,
                    transactionType,
                ],
            onChangeForm(props.form, props.onChange)("applies_when_any_of_transaction_types"),
        );

    const onChangeAttributes = (attribute: TQuoteRateAttribute) =>
        pipe(
            props.form.edited.applies_when_any_of_attributes.includes(attribute)
                ? pipe(
                    props.form.edited.applies_when_any_of_attributes,
                    array.filter((type) => type !== attribute),
                )
                : [
                    ...props.form.edited.applies_when_any_of_attributes,
                    attribute,
                ],
            onChangeForm(props.form, props.onChange)("applies_when_any_of_attributes"),
        );

    const onChangeTenure = (value: TTenure3 | "any") =>
        onChangeForm(props.form, props.onChange)("applies_when_tenure_is")(value === "any" ? null : value);

    const onChangePropertyResidentialCommercial = (value: TQuoteRatePropertyResidentialCommercial | "any") =>
        onChangeForm(props.form, props.onChange)("applies_when_property_residential_commercial_is")(value === "any" ? null : value);

    const onChangeClientsOwnership = (value: TQuoteRateClientsOwnership | "any") =>
        onChangeForm(props.form, props.onChange)("applies_when_clients_ownership_is")(value === "any" ? null : value);

    const onChangeClientsPropertyCount = (value: TQuoteRateClientsPropertyCount | "any") =>
        onChangeForm(props.form, props.onChange)("applies_when_clients_property_count_is")(value === "any" ? null : value);

    const onChangeTaxGroup = (value: TQuoteRateTaxGroup | "") =>
        onChangeForm(props.form, props.onChange)("applies_when_tax_group_is")(value === "" ? null : value);

    return <SpacingColumn spacing={CRMSpacing.MEDIUM}>
        <CRMTitleSubSection>
            When does this rate apply?
        </CRMTitleSubSection>

        {/* APPLIES WHEN QUESTIONS - NORMAL RATES (EXCLUDES TAX RATES) */}
        {!isAnyTaxRateSelected &&
            <>
                {/* TRANSACTION TYPE */}
                <CRMInputLabelAndErrorWrapComponent label="Only when any of these types of transaction:">
                    <CRMCheckboxList
                        columnCount={2}
                        options={pipe(
                            QuoteRateTransactionType.values,
                            array.map((value) => ({
                                value,
                                label: QuoteRateTransactionType_displayString(value),
                                isChecked: props.form.edited.applies_when_any_of_transaction_types.includes(value)
                            }))
                        )}
                        onClick={onChangeTransactionType}
                    />
                </CRMInputLabelAndErrorWrapComponent>

                {/* ATTRIBUTES */}
                <CRMInputLabelAndErrorWrapComponent label="And only when any of these:">
                    <CRMCheckboxList
                        columnCount={2}
                        options={pipe(
                            QuoteRateAttribute.values,
                            array.map((value) => ({
                                value,
                                label: QuoteRateAttribute_displayString(value),
                                isChecked: props.form.edited.applies_when_any_of_attributes.includes(value)
                            }))
                        )}
                        onClick={onChangeAttributes}
                    />
                </CRMInputLabelAndErrorWrapComponent>

                {/* TENURE */}
                <CRMInputLabelAndErrorWrapComponent label="And when the tenure is:">
                    <CRMDropdownComponent
                        value={props.form.edited.applies_when_tenure_is || "any"}
                        options={pipe(
                            Tenure3.values,
                            array.map((value) => ({
                                label: Tenure1_Tenure2(value),
                                value,
                            })),
                            array.prepend<IOption<TTenure3 | "any">>({
                                label: "Any",
                                value: "any",
                            }),
                        )}
                        onChange={onChangeTenure}
                        displayError={false}
                    />
                </CRMInputLabelAndErrorWrapComponent>

                {/* RESIDENTIAL/COMMERCIAL */}
                <CRMInputLabelAndErrorWrapComponent label="And when property is residential/commercial:">
                    <CRMDropdownComponent
                        value={props.form.edited.applies_when_property_residential_commercial_is || "any"}
                        options={pipe(
                            QuoteRatePropertyResidentialCommercial.values,
                            array.map((value) => ({
                                label: QuoteRatePropertyResidentialCommercial_displayString(value),
                                value,
                            })),
                            array.prepend<IOption<TQuoteRatePropertyResidentialCommercial | "any">>({
                                label: "Any",
                                value: "any",
                            }),
                        )}
                        onChange={onChangePropertyResidentialCommercial}
                        displayError={false}
                    />
                </CRMInputLabelAndErrorWrapComponent>

                {/* CLIENTS OWNERSHIP */}
                <CRMInputLabelAndErrorWrapComponent label="And when clients are/not first time buyers:">
                    <CRMDropdownComponent
                        value={props.form.edited.applies_when_clients_ownership_is || "any"}
                        options={pipe(
                            QuoteRateClientsOwnership.values,
                            array.map((value) => ({
                                label: QuoteRateClientsOwnership_displayString(value),
                                value,
                            })),
                            array.prepend<IOption<TQuoteRateClientsOwnership | "any">>({
                                label: "Any",
                                value: "any",
                            }),
                        )}
                        onChange={onChangeClientsOwnership}
                        displayError={false}
                    />
                </CRMInputLabelAndErrorWrapComponent>

                {/* CLIENTS PROPERTY COUNT */}
                <CRMInputLabelAndErrorWrapComponent label="And when clients will own this many properties on completion:">
                    <CRMDropdownComponent
                        value={props.form.edited.applies_when_clients_property_count_is || "any"}
                        options={pipe(
                            QuoteRateClientsPropertyCount.values,
                            array.map((value) => ({
                                label: QuoteRateClientsPropertyCount_displayString(value),
                                value,
                            })),
                            array.prepend<IOption<TQuoteRateClientsPropertyCount | "any">>({
                                label: "Any",
                                value: "any",
                            }),
                        )}
                        onChange={onChangeClientsPropertyCount}
                        displayError={false}
                    />
                </CRMInputLabelAndErrorWrapComponent>
            </>
        }

        {/* APPLIES WHEN QUESTIONS - TAX QUESTION ONLY */}
        {isAnyTaxRateSelected &&
            <>
                {/* TAX GROUP */}
                <CRMInputLabelAndErrorWrapComponent label="When the client is a part of this tax group:">
                    <CRMDropdownComponent
                        value={props.form.edited.applies_when_tax_group_is || ""}
                        options={pipe(
                            taxGroupOptions,
                            array.map((value) => ({
                                label: QuoteRateTaxGroup_displayString(value),
                                value,
                            })),
                            array.prepend<IOption<TQuoteRateTaxGroup | "">>({
                                label: "Please select",
                                value: "",
                            }),
                        )}
                        onChange={onChangeTaxGroup}
                        displayError={props.displayError && props.form.edited.applies_when_tax_group_is === null}
                    />
                </CRMInputLabelAndErrorWrapComponent>
            </>
        }
    </SpacingColumn>
};
