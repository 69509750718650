import React from "react";
import { CRMSpacingRow } from "../../../CRMSpacingRow/CRMSpacingRow";
import * as CaseDocument7 from "../../../../../../domain/models/CaseDocument7";
import { CRMInputCheckboxComponent } from "../../../CRMInputCheckboxComponent/CRMInputCheckboxComponent";
import { Font } from "../../../Font/Font";
import { CRMFontSizes } from "../../../../models/CRMFontSizes";
import { CRMIcon } from "../../../CRMIcon/CRMIcon";
import { WeightBold } from "../../../WeightBold/WeightBold";
import { onCallIfExists } from "../../../../functions/functions";
import { CRMLink } from "../../../CRMLink/CRMLink";
import { CRMSpacingColumn } from "../../../CRMSpacingColumn/CRMSpacingColumn";
import { WeightMedium } from "../../../WeightMedium/WeightMedium";
import { pipe } from "fp-ts/lib/function";
import * as CaseDocumentType1 from "../../../../../../domain/models/CaseDocumentType1";
import { CRMColors } from "../../../../models/CRMColors";
import { TextColour } from "../../../TextColour/TextColour";

type TEmailAttachmentProps = {
    mode: "selection" | "summary";
    document: CaseDocument7.T;
    selected?: boolean;
    onSelect?: () => void;
    onDelete?: () => void;
};

export const CRMCaseEnquiryDocumentAttachment = (props: TEmailAttachmentProps): JSX.Element => (
        <div className={`crm-email-attachment`}>
            <CRMSpacingRow spacing="medium" alignItems="flex-start">
                {/* IF MODE IS SELECTION: CHECKBOX */}
                {props.mode === "selection" &&
                    <CRMInputCheckboxComponent
                        checked={props.selected || false}
                        onClick={onCallIfExists(props.onSelect)}
                    />
                }
                {/* IF MODE IS SUMMARY: DELETE ICON */}
                {props.mode === "summary" &&
                    <CRMIcon
                        iconName="delete"
                        colour="neutral-ink"
                        onClick={props.onDelete}
                    />
                }
                <CRMSpacingColumn spacing="tiny">
                    {/* TITLE */}
                    <CRMSpacingRow>
                        <Font size={CRMFontSizes.SMALL} family="roboto-mono">
                            <WeightBold>
                                <TextColour colour={CRMColors.NEUTRAL_INK}>
                                    { pipe(
                                        props.document.type,
                                        CaseDocumentType1.toDisplayString,
                                    )}{props.document.additional_name ? ` - ${props.document.additional_name}` : ""}
                                </TextColour>
                            </WeightBold>
                        </Font>
                    </CRMSpacingRow>
                    {props.document.files.map((file, index) => (
                        <CRMLink
                            key={index}
                            href={`${env.REACT_APP_API_URL}/web/cases/${props.document.case_id}/documents/${props.document.id}/files/${file.id}/download`}
                            target="_blank"
                            linkStyle="normal"
                        >
                            <WeightMedium>
                                {file.name}.{file.file_extension}
                            </WeightMedium>
                        </CRMLink>
                    ))}
                </CRMSpacingColumn>
            </CRMSpacingRow>
        </div>
    );
