import React, { PropsWithChildren, useEffect } from "react";
import { useHasNodeChanged } from "../../../../hooks/useHasNodeChanged"; 
import { Background } from "../../../BuildingBlocks/Background";

type TViewProps = {
    isShowing: boolean;
}

export const CRMBlockScrollCacheView = (props: PropsWithChildren<TViewProps>) => {
    
    const bodyRef = React.createRef<HTMLDivElement>();
    const { isNodeDifferent } = useHasNodeChanged(props.children);

    useEffect(
        () => {
            try {
                if (isNodeDifferent(props.children)) {
                    bodyRef.current?.scrollTo(0, 0);
                }
            } catch (error) { 
                console.log(error); 
            }
        },
        [props.children]
    )

    return (
        <CRMBlockView
            elementRef={bodyRef} 
            display={props.isShowing ? undefined : "none"}
        >
            {props.children}
        </CRMBlockView>
    );
}


type TBlockScrollResetViewProps = 
    TViewProps & 
    {
        elementRef?: React.RefObject<HTMLDivElement>;
    };

export const CRMBlockScrollResetView = (props: PropsWithChildren<TBlockScrollResetViewProps>) => {
    return (
        <>
            {props.isShowing &&
                <CRMBlockView elementRef={props.elementRef}>
                    {props.children}
                </CRMBlockView>
            }
        </>
    )
}

type TCRMBlockView = {
    elementRef?: React.RefObject<HTMLDivElement>;
    display?: "none";
}

const CRMBlockView = (props: React.PropsWithChildren<TCRMBlockView>) => {
    return (
        <div 
            ref={props.elementRef} 
            className="crm-block-view" 
            style={{
                display: props.display
            }}
        >
            <Background padding={`16px 0px 0px 0px`} width="100%">
                {props.children}
            </Background>
        </div>
    )
}