import React from "react";
import { CRMLegalCaseDocumentGrid } from "../../components/CRMLegalCaseDocumentGrid/CRMLegalCaseDocumentGrid";
import { array } from "fp-ts";
import { pipe } from "fp-ts/lib/function";
import { CRMCaseEnquiryFlagDocumentForm } from "../../components/Contextual/CRMCaseEnquiry/CRMCaseEnquiryFlagDocumentForm/CRMCaseEnquiryFlagDocumentForm";
import { CRMButtonTertiary } from "../../components/CRMButtonTertiary/CRMButtonTertiary";
import { TCaseDocument6AndCaseDocumentForm1FormList } from "../../models/TFormModels";
import { TContainerStateProps } from "../../state/TContainerStateProps";
import { CRMSpacing } from "../../models/CRMSpacing";
import { SpacingRow } from "../../components/BuildingBlocks/SpacingRow";
import { SpacingColumn } from "../../components/BuildingBlocks/SpacingColumn";
import { Padding } from "../../components/BuildingBlocks/Padding";
import { DateTime } from "luxon";
import { Ord as StringOrd } from "fp-ts/lib/string";
import { contramap } from "fp-ts/lib/Ord";
import { toDisplayString as CaseDocumentType1ToDisplayString } from "../../../../domain/models/CaseDocumentType1";
import { getNumberOfDaysSinceDate } from "../../../../domain/models/ISODateTime";
import { CRMCaseDocumentsHelp } from "../../components/CRM/CRMCaseDocuments/CRMCaseDocumentsHelp/CRMCaseDocumentsHelp";

const OrdByTypeDisplayString = pipe(StringOrd, contramap((form: TCaseDocument6AndCaseDocumentForm1FormList["forms"][number]) => CaseDocumentType1ToDisplayString(form.edit.type)))
const OrdByAdditionalName = pipe(StringOrd, contramap((form: TCaseDocument6AndCaseDocumentForm1FormList["forms"][number]) => form.edit.additional_name))

export default class CRMLegalCaseDocumentsContainer extends React.Component<React.PropsWithChildren<TContainerStateProps>, {}> {

    onNewFileUploadChange = (documentId: string, files: File[]): void =>
        this.props.dispatch({
            type: "CRM_LEGAL_CASE_DOCUMENT_FILES_TO_UPLOAD",
            payload: {
                documentId: documentId,
                files: files
            },
        }
    );

    onDeleteDocument = (documentId: string): void => this.props.dispatch({
        type: "CRM_LEGAL_CASE_DOCUMENT_DELETE_DOCUMENT",
        payload: {
            resourceId: documentId,
        },
    });

    onDueDateChange = (documentId: string, value: string): void => this.props.dispatch({
        type: "CRM_LEGAL_CASE_DOCUMENT_CHANGE_DOCUMENT",
        payload: {
            resourceId: documentId,
            key: "due_date",
            value,
        },
    });

    onValidUntilChange = (documentId: string, value: string | null): void => this.props.dispatch({
        type: "CRM_LEGAL_CASE_DOCUMENT_CHANGE_DOCUMENT",
        payload: {
            resourceId: documentId,
            key: "valid_until",
            value,
        },
    });

    onReceivedDateChange = (documentId: string, value: string | null): void => this.props.dispatch({
        type: "CRM_LEGAL_CASE_DOCUMENT_CHANGE_DOCUMENT",
        payload: {
            resourceId: documentId,
            key: "received_date",
            value,
        },
    });

    onDocumentAdditionalNameChange = (documentId: string, value: string): void => this.props.dispatch({
        type: "CRM_LEGAL_CASE_DOCUMENT_CHANGE_DOCUMENT",
        payload: {
            resourceId: documentId,
            key: "additional_name",
            value,
        },
    });

    onDocumentFromExternalSourceChange = (documentId: string, value: boolean): void => this.props.dispatch({
        type: "CRM_LEGAL_CASE_DOCUMENT_CHANGE_DOCUMENT",
        payload: {
            resourceId: documentId,
            key: "from_external_source",
            value,
        },
    });

    onDocumentTypeChange = (documentId: string, value: string): void => this.props.dispatch({
        type: "CRM_LEGAL_CASE_DOCUMENT_CHANGE_DOCUMENT",
        payload: {
            resourceId: documentId,
            key: "type",
            value,
        },
    });
    
    onDocumentSharedWithClientChange = (documentId: string, value: boolean): void => this.props.dispatch({
        type: "CRM_LEGAL_CASE_DOCUMENT_CHANGE_DOCUMENT",
        payload: {
            resourceId: documentId,
            key: "shared_with_client",
            value,
        },
    });
    
    onDocumentSharedWithSolicitorChange = (documentId: string, value: boolean): void => this.props.dispatch({
        type: "CRM_LEGAL_CASE_DOCUMENT_CHANGE_DOCUMENT",
        payload: {
            resourceId: documentId,
            key: "shared_with_solicitor",
            value,
        },
    });

    onFileNameChange = (fileId: string, value: string): void => this.props.dispatch({
        type: "CRM_LEGAL_CASE_DOCUMENT_CHANGE_DOCUMENT_FILE",
        payload: {
            resourceId: fileId,
            key: "name",
            value,
        },
    });

    onFileDelete = (fileId: string): void => this.props.dispatch({
        type: "CRM_LEGAL_CASE_DOCUMENT_DELETE_DOCUMENT_FILE",
        payload: {
            resourceId: fileId,
        },
    });

    constructor(props) {
        super(props);
        this.props.dispatch({
            type: "CRM_LEGAL_CASE_DOCUMENT_VIEW",
            payload: null
        });
    }

    public render(): JSX.Element {

        return (
            <div className="crm-legal-case-documents">
                <Padding type="vertical" spacing={CRMSpacing.X_LARGE}>
                    <SpacingColumn spacing={CRMSpacing.LARGE}>
                        <SpacingRow
                            spacing={CRMSpacing.LARGE}
                            justifyContent="end"
                            alignItems="flex-start"
                        >
                            <CRMCaseDocumentsHelp
                                caseForm={this.props.state.forms.case_details_page.data.output.details}
                            />

                            
                            {/* [BUTTON] - FLAG DOCUMENTS FOR UPLOAD */}
                            <CRMButtonTertiary
                                icon={
                                    this.props.state.activeData.crm.legalCaseDocuments.newDocumentForm.ui.isShowingForm
                                        ? "close"
                                        : "search-list"
                                }
                                label={
                                    this.props.state.activeData.crm.legalCaseDocuments.newDocumentForm.ui.isShowingForm
                                        ? "Close"
                                        : "Flag Documents For Upload"
                                }
                                highlight={
                                    this.props.state.activeData.crm.legalCaseDocuments.newDocumentForm.ui.isShowingForm
                                        ? "neutral-paper"
                                        : "none"
                                }
                                onClick={() => this.props.dispatch({
                                    type: "CRM_LEGAL_CASE_DOCUMENT_TOGGLE_FORM",
                                    payload: {},
                                })}
                            />
                        </SpacingRow>

                        <SpacingRow
                            spacing={CRMSpacing.LARGE}
                            childSize={
                                this.props.state.activeData.crm.legalCaseDocuments.newDocumentForm.ui.isShowingForm ?
                                    "2fr 1fr" :
                                    "1fr"
                            }
                        >
                            <SpacingColumn spacing={CRMSpacing.LARGE}>

                                {/* IF FLAGGING: [CARD GROUP] - JUST FLAGGED  */}
                                {this.props.state.activeData.crm.legalCaseDocuments.newDocumentForm.ui.isShowingForm &&
                                    <CRMLegalCaseDocumentGrid
                                        mode={this.props.state.activeData.crm.legalCaseDocuments.newDocumentForm.ui.isShowingForm ? "add-new" : "view"}
                                        title="Just Flagged"
                                        documentFormList={({
                                            ...this.props.state.activeData.crm.legalCaseDocuments.documentFormList,
                                            forms: pipe(
                                                this.props.state.activeData.crm.legalCaseDocuments.documentFormList.forms,
                                                array.filter((f) => this.props.state.activeData.crm.legalCaseDocuments.justFlaggedDocumentIds.includes(f.view.id) ),
                                                array.sortBy([OrdByTypeDisplayString, OrdByAdditionalName]),
                                            ),
                                        })}
                                        documentFilesFormList={this.props.state.activeData.crm.legalCaseDocuments.documentFilesFormList}
                                        activeUploads={this.props.state.activeData.crm.legalCaseDocuments.activeUploads}
                                        onNewFileUploadChange={this.onNewFileUploadChange}
                                        onDeleteDocument={this.onDeleteDocument}
                                        onDueDateChange={this.onDueDateChange}
                                        onValidUntilChange={this.onValidUntilChange}
                                        onReceivedDateChange={this.onReceivedDateChange}
                                        onDocumentAdditionalNameChange={this.onDocumentAdditionalNameChange}
                                        onFileNameChange={this.onFileNameChange}
                                        onFileDelete={this.onFileDelete}
                                        onDocumentTypeChange={this.onDocumentTypeChange}
                                        onDocumentFromExternalSourceChange={this.onDocumentFromExternalSourceChange}
                                        onDocumentSharedWithClientChange={this.onDocumentSharedWithClientChange}
                                        onDocumentSharedWithSolicitorChange={this.onDocumentSharedWithSolicitorChange}
                                    />
                                }

                                {/* [CARD GROUP] - PAST DUE */}
                                <CRMLegalCaseDocumentGrid
                                    mode={this.props.state.activeData.crm.legalCaseDocuments.newDocumentForm.ui.isShowingForm ? "add-new" : "view"}
                                    title="Past Due"
                                    documentFormList={({
                                        ...this.props.state.activeData.crm.legalCaseDocuments.documentFormList,
                                        forms: pipe(
                                            this.props.state.activeData.crm.legalCaseDocuments.documentFormList.forms,
                                            array.filter((f) => 
                                                f.edit.received_date === null && DateTime.fromISO(f.edit.due_date).toUTC().diffNow("days").days < -1
                                                && (!this.props.state.activeData.crm.legalCaseDocuments.newDocumentForm.ui.isShowingForm || !this.props.state.activeData.crm.legalCaseDocuments.justFlaggedDocumentIds.includes(f.view.id))
                                            ),
                                            array.sortBy([OrdByTypeDisplayString, OrdByAdditionalName]),
                                        ),
                                    })}
                                    activeUploads={this.props.state.activeData.crm.legalCaseDocuments.activeUploads}
                                    documentFilesFormList={this.props.state.activeData.crm.legalCaseDocuments.documentFilesFormList}
                                    onNewFileUploadChange={this.onNewFileUploadChange}
                                    onDeleteDocument={this.onDeleteDocument}
                                    onDueDateChange={this.onDueDateChange}
                                    onValidUntilChange={this.onValidUntilChange}
                                    onReceivedDateChange={this.onReceivedDateChange}
                                    onDocumentAdditionalNameChange={this.onDocumentAdditionalNameChange}
                                    onFileNameChange={this.onFileNameChange}
                                    onFileDelete={this.onFileDelete}
                                    onDocumentTypeChange={this.onDocumentTypeChange}
                                    onDocumentFromExternalSourceChange={this.onDocumentFromExternalSourceChange}
                                    onDocumentSharedWithClientChange={this.onDocumentSharedWithClientChange}
                                    onDocumentSharedWithSolicitorChange={this.onDocumentSharedWithSolicitorChange}
                                />

                                {/* [CARD GROUP] - AWAITING */}
                                <CRMLegalCaseDocumentGrid
                                    mode={this.props.state.activeData.crm.legalCaseDocuments.newDocumentForm.ui.isShowingForm ? "add-new" : "view"}
                                    title="Awaiting"
                                    documentFormList={({
                                        ...this.props.state.activeData.crm.legalCaseDocuments.documentFormList,
                                        forms: pipe(
                                            this.props.state.activeData.crm.legalCaseDocuments.documentFormList.forms,
                                            array.filter((f) => 
                                                f.edit.received_date === null && DateTime.fromISO(f.edit.due_date).toUTC().diffNow("days").days >= -1
                                                && (!this.props.state.activeData.crm.legalCaseDocuments.newDocumentForm.ui.isShowingForm || !this.props.state.activeData.crm.legalCaseDocuments.justFlaggedDocumentIds.includes(f.view.id))
                                            ),
                                            array.sortBy([OrdByTypeDisplayString, OrdByAdditionalName]),
                                        ),
                                    })}
                                    activeUploads={this.props.state.activeData.crm.legalCaseDocuments.activeUploads}
                                    documentFilesFormList={this.props.state.activeData.crm.legalCaseDocuments.documentFilesFormList}
                                    onNewFileUploadChange={this.onNewFileUploadChange}
                                    onDeleteDocument={this.onDeleteDocument}
                                    onDueDateChange={this.onDueDateChange}
                                    onValidUntilChange={this.onValidUntilChange}
                                    onReceivedDateChange={this.onReceivedDateChange}
                                    onDocumentAdditionalNameChange={this.onDocumentAdditionalNameChange}
                                    onFileNameChange={this.onFileNameChange}
                                    onFileDelete={this.onFileDelete}
                                    onDocumentTypeChange={this.onDocumentTypeChange}
                                    onDocumentFromExternalSourceChange={this.onDocumentFromExternalSourceChange}
                                    onDocumentSharedWithClientChange={this.onDocumentSharedWithClientChange}
                                    onDocumentSharedWithSolicitorChange={this.onDocumentSharedWithSolicitorChange}
                                />

                                {/* [CARD GROUP] - ALL FILES CORRECT */}
                                <CRMLegalCaseDocumentGrid
                                    mode={this.props.state.activeData.crm.legalCaseDocuments.newDocumentForm.ui.isShowingForm ? "add-new" : "view"}
                                    title="All Files Correct"
                                    documentFormList={({
                                        ...this.props.state.activeData.crm.legalCaseDocuments.documentFormList,
                                        forms: pipe(
                                            this.props.state.activeData.crm.legalCaseDocuments.documentFormList.forms,
                                            array.filter((f) => 
                                                f.edit.received_date !== null
                                                && (!this.props.state.activeData.crm.legalCaseDocuments.newDocumentForm.ui.isShowingForm || !this.props.state.activeData.crm.legalCaseDocuments.justFlaggedDocumentIds.includes(f.view.id))
                                                && (f.edit.valid_until === null || getNumberOfDaysSinceDate(DateTime.fromISO(f.edit.valid_until).toJSDate()) <= 0)
                                            ),
                                            array.sortBy([OrdByTypeDisplayString, OrdByAdditionalName]),
                                        ),
                                    })}
                                    activeUploads={this.props.state.activeData.crm.legalCaseDocuments.activeUploads}
                                    documentFilesFormList={this.props.state.activeData.crm.legalCaseDocuments.documentFilesFormList}
                                    onNewFileUploadChange={this.onNewFileUploadChange}
                                    onDeleteDocument={this.onDeleteDocument}
                                    onDueDateChange={this.onDueDateChange}
                                    onValidUntilChange={this.onValidUntilChange}
                                    onReceivedDateChange={this.onReceivedDateChange}
                                    onDocumentAdditionalNameChange={this.onDocumentAdditionalNameChange}
                                    onFileNameChange={this.onFileNameChange}
                                    onFileDelete={this.onFileDelete}
                                    onDocumentTypeChange={this.onDocumentTypeChange}
                                    onDocumentFromExternalSourceChange={this.onDocumentFromExternalSourceChange}
                                    onDocumentSharedWithClientChange={this.onDocumentSharedWithClientChange}
                                    onDocumentSharedWithSolicitorChange={this.onDocumentSharedWithSolicitorChange}
                                />

                                {/* [CARD GROUP] - EXPIRED */}
                                <CRMLegalCaseDocumentGrid
                                    mode={this.props.state.activeData.crm.legalCaseDocuments.newDocumentForm.ui.isShowingForm ? "add-new" : "view"}
                                    title="Expired"
                                    documentFormList={({
                                        ...this.props.state.activeData.crm.legalCaseDocuments.documentFormList,
                                        forms: pipe(
                                            this.props.state.activeData.crm.legalCaseDocuments.documentFormList.forms,
                                            array.filter((f) => 
                                                f.edit.received_date !== null
                                                && (!this.props.state.activeData.crm.legalCaseDocuments.newDocumentForm.ui.isShowingForm || !this.props.state.activeData.crm.legalCaseDocuments.justFlaggedDocumentIds.includes(f.view.id))
                                                && (f.edit.valid_until !== null && getNumberOfDaysSinceDate(DateTime.fromISO(f.edit.valid_until).toJSDate()) > 0)
                                            ),
                                            array.sortBy([OrdByTypeDisplayString, OrdByAdditionalName]),
                                        ),
                                    })}
                                    activeUploads={this.props.state.activeData.crm.legalCaseDocuments.activeUploads}
                                    documentFilesFormList={this.props.state.activeData.crm.legalCaseDocuments.documentFilesFormList}
                                    onNewFileUploadChange={this.onNewFileUploadChange}
                                    onDeleteDocument={this.onDeleteDocument}
                                    onDueDateChange={this.onDueDateChange}
                                    onValidUntilChange={this.onValidUntilChange}
                                    onReceivedDateChange={this.onReceivedDateChange}
                                    onDocumentAdditionalNameChange={this.onDocumentAdditionalNameChange}
                                    onFileNameChange={this.onFileNameChange}
                                    onFileDelete={this.onFileDelete}
                                    onDocumentTypeChange={this.onDocumentTypeChange}
                                    onDocumentFromExternalSourceChange={this.onDocumentFromExternalSourceChange}
                                    onDocumentSharedWithClientChange={this.onDocumentSharedWithClientChange}
                                    onDocumentSharedWithSolicitorChange={this.onDocumentSharedWithSolicitorChange}
                                />
                            </SpacingColumn>
                            
                            {/* SIDEBAR - FLAG DOCUMENTS */}
                            {this.props.state.activeData.crm.legalCaseDocuments.newDocumentForm.ui.isShowingForm &&
                                <div className="crm-legal-case-documents__form-section">
                                    <div className="crm-legal-case-documents__form-fields-container">
                                        <CRMCaseEnquiryFlagDocumentForm
                                            documentTypeValue={this.props.state.activeData.crm.legalCaseDocuments.newDocumentForm.edit.type}
                                            documentAdditionalNameValue={this.props.state.activeData.crm.legalCaseDocuments.newDocumentForm.edit.additional_name}
                                            documentFromExternalSourceValue={this.props.state.activeData.crm.legalCaseDocuments.newDocumentForm.edit.from_external_source}
                                            expectedByDate={this.props.state.activeData.crm.legalCaseDocuments.newDocumentForm.edit.due_date}
                                            formStatus={this.props.state.activeData.crm.legalCaseDocuments.newDocumentForm.status}
                                            onChangeDate={(value) => this.props.dispatch({
                                                type: "CRM_LEGAL_CASE_DOCUMENT_NEW_DOCUMENT_INPUT_CHANGED",
                                                payload: {
                                                    key: "due_date",
                                                    value,
                                                },
                                            })}
                                            onAdd={() => this.props.dispatch({
                                                type: "CRM_LEGAL_CASE_DOCUMENT_NEW_DOCUMENT_SUBMIT",
                                            })}
                                            onChangeDropdown={(value) => this.props.dispatch({
                                                type: "CRM_LEGAL_CASE_DOCUMENT_NEW_DOCUMENT_INPUT_CHANGED",
                                                payload: {
                                                    key: "type",
                                                    value,
                                                },
                                            })}
                                            onChangeText={(value) => this.props.dispatch({
                                                type: "CRM_LEGAL_CASE_DOCUMENT_NEW_DOCUMENT_INPUT_CHANGED",
                                                payload: {
                                                    key: "additional_name",
                                                    value,
                                                },
                                            })}
                                            onChangeFromExternalSource={(value) => this.props.dispatch({
                                                type: "CRM_LEGAL_CASE_DOCUMENT_NEW_DOCUMENT_INPUT_CHANGED",
                                                payload: {
                                                    key: "from_external_source",
                                                    value,
                                                },
                                            })}
                                        />
                                    </div>
                                </div>
                            }
                        </SpacingRow>
                    </SpacingColumn>
                </Padding>
            </div>
        );
    }
}
