import React from "react";
import { TRoadblockForm } from "../../../../../domain/codecs/form/RoadblockForm";
import { formatDateToDayOfWeekAndFull } from "../../../../../shared/src/utilsByDomain/dateTime";
import { CRMColors } from "../../../models/CRMColors";
import { CRMFontSizes } from "../../../models/CRMFontSizes";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { Background } from "../../BuildingBlocks/Background";
import { Padding } from "../../BuildingBlocks/Padding";
import { SpacingColumn } from "../../BuildingBlocks/SpacingColumn";
import { TextColor } from "../../BuildingBlocks/TextColor";
import { CRMParagraph } from "../../Simple/CRMParagraph/CRMParagraph";
import { WeightBold } from "../../WeightBold/WeightBold";
import { WeightMedium } from "../../WeightMedium/WeightMedium";
import { CRMSystemName } from "../../../models/CRMSystem";

export const CRMRoadblockStatusLabelPopoverBlocked = (props: {
    form: TRoadblockForm,
    noBoxShadow?: boolean;
}): JSX.Element => {

    const blockedTill = formatDateToDayOfWeekAndFull(props.form.children.details.blocked_till || "");
    const blockedByUser = props.form.children.details.blocked_by_user || CRMSystemName;

    return <Background
        color={CRMColors.HIGHLIGHTS_PERTINENT_YELLOW_2}
        borderRadius="8px"  
        boxShadowDefinition={
            props.noBoxShadow ? '' : `0px 2px 5px 0px rgba(0, 0, 0, 0.14)`
        }
    >
        {/* TOP SECTION */}
        <Padding spacing={CRMSpacing.MEDIUM}>
            <SpacingColumn spacing={CRMSpacing.TINY}>
                {/* TITLE */}
                <CRMParagraph fontSize={CRMFontSizes.SMALL_PLUS}>
                    <TextColor color={CRMColors.NEUTRAL_INK}>
                        <WeightBold>
                            This roadblock is deferred.
                        </WeightBold>
                    </TextColor>
                </CRMParagraph>

                <Padding spacing={`0px 0px 0px ${CRMSpacing.TINY}`}>
                    <SpacingColumn spacing={CRMSpacing.TINY}>
                        {/* UNTIL */}
                        <CRMParagraph>
                            <WeightBold>until:</WeightBold> <WeightMedium>{blockedTill}</WeightMedium>
                        </CRMParagraph>

                        {/* BY */}
                        <CRMParagraph>
                            <WeightBold>by:</WeightBold> <WeightMedium>{blockedByUser}</WeightMedium>
                        </CRMParagraph>

                        {/* WAITING ON */}
                        <CRMParagraph>
                            <WeightBold>waiting on:</WeightBold> <WeightMedium>{props.form.children.details.blocked_waiting_on}</WeightMedium>
                        </CRMParagraph>
                    </SpacingColumn>
                </Padding>
            </SpacingColumn>
        </Padding>
    </Background>;
};
