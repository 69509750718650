import React, { useState } from "react";
import { pipe } from "fp-ts/lib/function";
import { array as extArray } from "../../../../shared/src/utilByDomainGroupExport";
import { TFormStatus } from "./../../../../shared/src/codecs/codec";
import { CRMPartyMemberForm } from "../CRMPartyMemberForm/CRMPartyMemberForm";
import { CRMButtonIcon } from "../CRMButtonIcon/CRMButtonIcon";
import { CRMFormButtonIconWithLabel } from "../CRMFormButtonIconWithLabel/CRMFormButtonIconWithLabel";
import { CRMParagraph } from "../Simple/CRMParagraph/CRMParagraph";
import { CRMPadding } from "../Simple/CRMPadding/CRMPadding";
import { TextCenter } from "../TextCenter/TextCenter";
import { WeightBold } from "../WeightBold/WeightBold";
import { TCaseMemberChildrenForm, TCaseMemberCreateIdCheckForm, TCaseMemberForm, TCaseMemberIdCheckForm, TCaseMemberSuggestedSimilarUserForm, TCaseSendInstructedEmailForm } from "../../../../domain/codecs/form/CaseMemberForm";
import { TPhoneNumberForm } from "../../../../domain/codecs/form/PhoneNumberForm";
import { editFormEditableValue } from "../../../../shared/src/codecs/types/formEditable";
import { CRMVerticalTabs } from "../Complex/CRMVerticalTabs/CRMVerticalTabs";
import { array } from "fp-ts";
import { CRMCardOutsidePopupBasic } from "../CRMCardOutsidePopupBasic/CRMCardOutsidePopupBasic";
import { TCaseEditableFull } from "../../../../domain/codecs/Cases";
import { CRMCardInside } from "../CRMCardInside/CRMCardInside";

type TPartyMembersFormProps = {
    case: TCaseEditableFull;
    caseIsInstructed: boolean;
    members: Array<TCaseMemberForm>;
    isNotAllowedToReceiveInstructEmailDueToDualRepConsent: boolean;
    onAddMember: () => void;
    onRemoveMember: (member: TCaseMemberForm) => void;
    onUpdateMembers: (members: Array<TCaseMemberForm>) => void;
    onUpdateMemberChild: (memberChild: TCaseMemberChildrenForm) => void;
    onMemberSendInstructedEmailClick: (value: TCaseSendInstructedEmailForm) => void;
    createPhoneNumberStatus: TFormStatus;
    onAddNewPhoneNumber: (memberId: string) => void;
    onRemovePhoneNumber: (form: TPhoneNumberForm) => void;
    onChildIdChecksChange: (value: TCaseMemberIdCheckForm) => void;
    onNewIdCheck: (value: TCaseMemberCreateIdCheckForm) => void;
    onSwapMember: (form: TCaseMemberSuggestedSimilarUserForm) => void;
};

export const CRMPartyMembersForm = (props: TPartyMembersFormProps): JSX.Element => {
    const [memberToBeDeleted, setMemberToBeDeleted] = useState<TCaseMemberForm | null>(null);

    return <>
        <CRMCardInside>
            <CRMPadding size="medium">
                <CRMParagraph>
                    NB: Editing the client on all cases.
                </CRMParagraph>
            </CRMPadding>
        </CRMCardInside>
        <CRMVerticalTabs
            tabs={pipe(
                props.members,
                array.map((member) => ({
                    tabLabel: `${member.edited.first_name} ${member.edited.last_name}`,
                    labelIcon: member.edited.is_primary_contact ? "person-tick" : undefined,
                    tabOptions: [
                        {
                            label: "Set as primary contact",
                            onClick: () => props.onUpdateMembers(
                                    pipe(
                                    props.members,
                                    extArray.updateWhere(
                                        (memberToUpdate) => memberToUpdate.original.id === member.original.id,
                                        (memberToUpdate) => editFormEditableValue(
                                            memberToUpdate,
                                            "is_primary_contact",
                                            true
                                        )
                                    ),
                                )
                            )
                        },
                        {
                            label: "Remove from case",
                            onClick: () => setMemberToBeDeleted(member),
                        },
                    ],
                    content: <CRMPartyMemberForm
                        member={member}
                        onChange={(updatedMember) => 
                            {
                                return props.onUpdateMembers(
                                    pipe(
                                        props.members,
                                        extArray.updateWhere(
                                            (m) => m.original.id === updatedMember.original.id,
                                            () => updatedMember
                                        )
                                    )
                                );
                            }
                        }
                        isNotAllowedToReceiveInstructEmailDueToDualRepConsent={props.isNotAllowedToReceiveInstructEmailDueToDualRepConsent}
                        onChildChange={props.onUpdateMemberChild}
                        onSendInstructedEmailClick={props.onMemberSendInstructedEmailClick}
                        onAddNewPhoneNumber={() => props.onAddNewPhoneNumber(member.original.id)}
                        onRemovePhoneNumber={props.onRemovePhoneNumber}
                        createPhoneNumberStatus={props.createPhoneNumberStatus}
                        case={props.case}
                        caseIsInstructed={props.caseIsInstructed}
                        onChildIdChecksChange={props.onChildIdChecksChange}
                        onNewIdCheck={props.onNewIdCheck}
                        onSwapMember={props.onSwapMember}
                    />
                }))
            )}
            noTabsContent={
                <CRMPadding size="tiny">
                    <TextCenter>
                        <CRMParagraph>
                            Add <WeightBold>clients</WeightBold> to get started.
                        </CRMParagraph>
                    </TextCenter>
                </CRMPadding>
            }
            afterTabsElement={
                <CRMFormButtonIconWithLabel
                    formStatus="requiresSubmission"
                    ButtonElement={(buttonProps) => <CRMButtonIcon variant="primary" {...buttonProps} />}
                    icon="person-add"
                    label="Add new client"
                    onClick={props.onAddMember}
                />
            }
        />

        {/* DELETE POPUP */}
        <CRMCardOutsidePopupBasic
            isOpen={memberToBeDeleted ? true : false}
            title="Are you sure?"
            context="important"
            onClose={() => setMemberToBeDeleted(null)}
            closeText="Cancel"
            onCTA={() => {
                if (memberToBeDeleted) {
                    props.onRemoveMember(memberToBeDeleted);
                }
                setMemberToBeDeleted(null);
            }}
            ctaText="Yes"
        >
            <CRMParagraph>
                Are you sure you want to delete this person from the party?
            </CRMParagraph>
        </CRMCardOutsidePopupBasic>
    </>;
}
