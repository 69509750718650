import React from "react";
import { THexColor, TPercentageSize, TPixelSize, TPixelSizeShortHand } from "../../models/StringLiterals";

type TShineShapeProps = {
    backgroundColor: THexColor;
    shineColor: THexColor;
    duration: number;
    width: TPixelSize | TPercentageSize;
    height?: TPixelSize | TPercentageSize | "auto";
    borderRadius?: TPixelSize | TPercentageSize | TPixelSizeShortHand;
};

export const ShineShape = (props: React.PropsWithChildren<TShineShapeProps>): JSX.Element => {

    return (
        <div 
            className="shine-shape"
            style={{
                background: props.backgroundColor,
                backgroundImage: `linear-gradient(
                    to right, 
                    ${props.backgroundColor} 0%, 
                    ${props.shineColor} 20%, 
                    ${props.backgroundColor} 40%, 
                    ${props.backgroundColor} 100%
                )`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "100% 100%",
                display: "inline-block",
                position: "relative",
                animationDuration: `${props.duration}ms`,
                WebkitAnimationDuration: `${props.duration}ms`,
                height: props.height || "auto",
                width: props.width,
                borderRadius: props.borderRadius,
            }}
        >
            {props.children}
        </div>
    );
};
