import * as t from "io-ts";
import * as JsonResponse1 from "./JsonResponse1";
import * as PaginationMeta1 from "./PaginationMeta1";
import * as User4 from "./User4";

export const codec = JsonResponse1.createCodec(
    t.array(User4.codec),
    PaginationMeta1.codec,
);

export type T = t.TypeOf<typeof codec>;

export const newDefault = (): T => ({
    data: [],
    meta: PaginationMeta1.newDefault(),
});
