import React from "react";
import * as ListingEnquiriesOffer2 from "../../../../domain/models/ListingEnquiriesOffer2";
import * as Enquiry2 from "../../../../domain/models/Enquiry2";
import * as Party2 from "../../../../domain/models/Party2";
import * as TCurrency from "../../../../domain/models/TCurrency";
import * as OfferStatus from "../../../../domain/models/OfferStatus";
import * as EnquiryPaymentMethodDisplayName from "../../../../domain/models/EnquiryPaymentMethodDisplayName";
import * as User4 from "../../../../domain/models/User4";
import { nonEmptyArray, array, option } from "fp-ts/lib";
import { CRMParagraph } from "../Simple/CRMParagraph/CRMParagraph";
import { CRMSpacer } from "../CRMSpacer/CRMSpacer";
import { CRMTitleForCard } from "../CRMTitleForCard/CRMTitleForCard";
import { pipe } from "fp-ts/lib/function";
import { CRMFontSizes } from "../../models/CRMFontSizes";

class CRMHighestOffersComponent extends React.Component<{
    offers: Array<ListingEnquiriesOffer2.T>;
    enquiriesRelatedToOffers: Array<Enquiry2.T>;
    partiesRelatedToOffers: Array<Party2.T>;
}> {
    public render (): JSX.Element {
        return (
            <div>
                <CRMTitleForCard>
                    Highest offers
                </CRMTitleForCard>
                <CRMSpacer size="tiny" />
                { pipe(
                    this.props.offers,
                    nonEmptyArray.fromArray,
                    option.fold<nonEmptyArray.NonEmptyArray<ListingEnquiriesOffer2.T>, Array<JSX.Element> | JSX.Element>(
                        () =>
                            <CRMParagraph>
                                There are no offers yet
                            </CRMParagraph>,
                         pipe(
                            nonEmptyArray.mapWithIndex((i, offer) =>
                                <div key={i}>
                                    <CRMParagraph>
                                        <strong>
                                            {TCurrency.fromNumber(offer.amount)} - {getPaymentMethod(offer, this.props.enquiriesRelatedToOffers)}
                                        </strong>
                                    </CRMParagraph>
                                    <CRMParagraph>
                                        <strong>{OfferStatus.toDisplayName(offer.status)}</strong>
                                    </CRMParagraph>
                                    {offer.status === "rejected" && offer.rejected_reason && <CRMParagraph fontSize={CRMFontSizes.X_SMALL}>
                                        <strong>Reason: {offer.rejected_reason}</strong>
                                    </CRMParagraph>}
                                    <CRMParagraph fontSize={CRMFontSizes.X_SMALL}>
                                        {getUserNames(offer, this.props.enquiriesRelatedToOffers, this.props.partiesRelatedToOffers)}
                                    </CRMParagraph>
                                    {i < this.props.offers.length - 1
                                        ? <CRMSpacer size="medium" />
                                        : <></>}
                                </div>
                            ),
                        )
                    )
                )}
            </div>
        );
    }
}

const getPaymentMethod = (offer: ListingEnquiriesOffer2.T, enquiries: Array<Enquiry2.T>): string =>
     pipe(
        enquiries,
        array.findFirst((enquiry) => enquiry.id === offer.enquiry_id),
        option.getOrElse(Enquiry2.newDefault),
        (enquiry) => enquiry.payment_method,
        EnquiryPaymentMethodDisplayName.fromEnquiryPaymentMethod1,
    );

const getUserNames = (offer: ListingEnquiriesOffer2.T, enquiries: Array<Enquiry2.T>, parties: Array<Party2.T>): string =>
     pipe(
        enquiries,
        array.findFirst((enquiry) => enquiry.id === offer.enquiry_id),
        option.getOrElse(Enquiry2.newDefault),
        (enquiry) =>  pipe(
            parties,
            array.findFirst((party) => party.id === enquiry.party_id),
            option.getOrElse(Party2.newDefault),
            (party) => party.users,
            array.map(User4.toDisplayName),
            (users) => users.join(" & "),
        ),
    );

export default CRMHighestOffersComponent;
