import React, { useState } from "react";
import { SpacingColumn } from "../../components/BuildingBlocks/SpacingColumn";
import { FrontQuestionFormCard } from "../../components/Front/Complex/FrontQuestionFormCard/FrontQuestionFormCard";
import { TContainerStateProps } from "../../state/TContainerStateProps";
import { FrontSpacing } from "../../models/FrontSpacing";
import { WeightBold } from "../../components/WeightBold/WeightBold";
import { FrontColors } from "../../models/FrontColors";
import { FrontFormSectionTitle } from "../../components/Front/Simple/FrontFormSectionTitle/FrontFormSectionTitle";
import { onChangeForm } from "../../../../shared/src/codecs/types/form";
import { MediaCompSwitch } from "../../components/BuildingBlocks/MediaCompSwitch";
import { SpacingRow } from "../../components/BuildingBlocks/SpacingRow";
import { FrontFormPrimaryButton } from "../../components/Front/Simple/FrontFormPrimaryButton/FrontFormPrimaryButton";
import { FrontCondensedDropdownQuestion } from "../../components/Front/Simple/FrontCondensedDropdownQuestion/FrontCondensedDropdownQuestion";
import { TOnboardingTabStatus } from "../../components/Front/Simple/FrontOnboardingTab/FrontOnboardingTab";
import FrontParagraphRegular from "../../components/Front/Simple/FrontParagraphRegular/FrontParagraphRegular";
import { FrontPopupPrompt } from "../../components/Front/Complex/FrontPopupPrompt/FrontPopupPrompt";
import FrontParagraphSmall from "../../components/Front/Simple/FrontParagraphSmall/FrontParagraphSmall";
import { sailLegalBrandConstants } from "../../../../domain/constants";
import { FrontInfoBubble } from "../../components/Front/Simple/FrontInfoBubble/FrontInfoBubble";
import { ClientCaseRemortgageMortgageDetailsForm } from "../../../../domain/codecs/form/ClientCaseForm";
import { CasesLenderNameOnPanel1, CasesLenderNameOnPanel1_displayString } from "../../../../domain/codecs/CasesLenderNameOnPanel";
import { enumToIDropdownOptionsSortedByDisplayName } from "../../functions/enumToIDropdownOptions";
import { penceToCopyText } from "../../../../shared/src/util";
import { pipe } from "fp-ts/lib/function";
import { FrontFormRow } from "../../components/Front/Simple/FrontFormRow/FrontFormRow";
import FrontInputCurrencyComponent from "../../components/Front/Simple/FrontInputCurrencyComponent/FrontInputCurrencyComponent";
import FrontInputLabelAndErrorWrapComponent from "../../components/Front/Simple/FrontInputLabelAndErrorWrapComponent/FrontInputLabelAndErrorWrapComponent";
import { FrontCondensedQuestionWrap } from "../../components/Front/Simple/FrontCondensedQuestionWrap/FrontCondensedQuestionWrap";

const FrontRemortgageMortgageDetailsContainer = (props: TContainerStateProps): JSX.Element => {

    const [isSubmitFormOpen, setIsSubmitFormOpen] = useState(false);
    const [displayValidationErrorsIfPresent, setDisplayValidationErrorsIfPresent] = useState(false);

    const getClientCaseRemortgageMortgageDetailsForm = () =>
        props.state.forms.client_case_page.children.remortgage_details
        || ClientCaseRemortgageMortgageDetailsForm.newDefault();
    
    const getLenderName = () => getClientCaseRemortgageMortgageDetailsForm().edited.mortgage_lender_name;

    const getRemortgageAmount = () => getClientCaseRemortgageMortgageDetailsForm().edited.expected_mortgage_amount_pence;

    const formCanBeSubmittedAsFinal = (): boolean =>
        getLenderName() !== "unknown"
        && getRemortgageAmount() !== null
    ;

    const hasBeenSubmitted = () => props.state.forms.client_case_page.children.remortgage_details?.children.is_step_complete ? true : false;

    return (
        <div>
            <FrontQuestionFormCard
                title={"Remortgage details"}
            >
                <SpacingColumn spacing={FrontSpacing.LARGE_1}>

                    {/* PAYMENT DETAILS */}
                    <SpacingColumn spacing={FrontSpacing.LARGE_1}>

                        {/* INPUT - LENDER NAME */}
                        <SpacingColumn spacing={FrontSpacing.SMALL_3}>

                            <FrontCondensedDropdownQuestion
                                label="Which lender are you remortgaging with?"
                                closedLabel="Mortgage lender"
                                value={getLenderName()}
                                isEditable={!hasBeenSubmitted()}
                                isOpen={getLenderName() === "unknown"}
                                options={enumToIDropdownOptionsSortedByDisplayName(
                                    CasesLenderNameOnPanel1.values,
                                    CasesLenderNameOnPanel1_displayString)  
                                }
                                wrapInFormRow={true}
                                onChange={onChangeForm(
                                    getClientCaseRemortgageMortgageDetailsForm(),
                                    (payload) => props.dispatch({
                                        type: "CLIENT_UPDATE_REMORTGAGE_MORTGAGE_DETAILS",
                                        payload,
                                    })
                                )("mortgage_lender_name")}
                                displayValidationErrorsIfPresent={displayValidationErrorsIfPresent}
                                unknownValueIsError={true}
                            />

                            {getLenderName() === "unknown" && 
                                <FrontParagraphSmall>
                                    If your lender is not on the list <WeightBold>please call us</WeightBold> on <WeightBold>{sailLegalBrandConstants().legalCSNumber}</WeightBold>.
                                </FrontParagraphSmall>
                            }
                        </SpacingColumn>

                        {/* REMORTGAGE AMOUNT */}
                        <FrontCondensedQuestionWrap
                            isOpen={!hasBeenSubmitted()}
                            setIsOpen={() => !hasBeenSubmitted()}
                            label={`How much is your remortgage?`}
                            isEditable={!hasBeenSubmitted()}
                            summary={pipe(
                                getRemortgageAmount(),
                                (amount) => amount ? penceToCopyText(amount) : ""
                            )}
                        >
                            <FrontFormRow childSize="1fr">
                                <FrontInputLabelAndErrorWrapComponent
                                    label="How much is your remortgage?"
                                    errorMessage="We need to know this"
                                    displayError={
                                        displayValidationErrorsIfPresent
                                        && getLenderName() !== "unknown"
                                        && getRemortgageAmount() === null
                                    }
                                >
                                    <FrontInputCurrencyComponent
                                        value={getRemortgageAmount()}
                                        onChange={(value) => {
                                            value !== null && setDisplayValidationErrorsIfPresent(false)
                                            onChangeForm(
                                                getClientCaseRemortgageMortgageDetailsForm(),
                                                (payload) => props.dispatch({
                                                    type: "CLIENT_UPDATE_REMORTGAGE_MORTGAGE_DETAILS",
                                                    payload,
                                                })
                                            )("expected_mortgage_amount_pence")(value)
                                        }}
                                    />
                                </FrontInputLabelAndErrorWrapComponent>
                            </FrontFormRow>
                        </FrontCondensedQuestionWrap>
                        
                    </SpacingColumn>

                    {/* CONTROLS - SUBMIT/POST-SUBMIT INFO */}
                    <SpacingColumn spacing={FrontSpacing.MEDIUM_1}>
                        
                        {/* CONTROLS - SUBMIT AS FINAL BUTTON */}
                        {!hasBeenSubmitted() &&
                            <MediaCompSwitch
                                DesktopComponent={SpacingRow}
                                desktopProps={{ spacing: FrontSpacing.SMALL_3}}
                                MobileComponent={SpacingColumn}
                                mobileProps={{ spacing: FrontSpacing.SMALL_3 }}
                                breakpoint="600"
                            >
                                <FrontFormPrimaryButton
                                    label={`Submit remortgage details`}
                                    icon="lock"
                                    onClick={() => {
                                        if (formCanBeSubmittedAsFinal()) {
                                            setIsSubmitFormOpen(true)
                                        } else {
                                            setDisplayValidationErrorsIfPresent(true)
                                        }
                                    }}
                                />
                            </MediaCompSwitch>
                        }

                        {/* VALIDATION ERROR MESSAGE */}
                        {
                            !hasBeenSubmitted()
                            && displayValidationErrorsIfPresent
                            && (getLenderName() === "unknown" || getRemortgageAmount() === null)
                            && <FrontInfoBubble color={FrontColors.ERROR_FIREBRICK_22}>
                                <WeightBold>You've missed something above,</WeightBold> please take a look and then try again.
                            </FrontInfoBubble>
                        }

                        {/* AFTER SUBMISSION - HOW TO CHANGE INFO */}
                        {hasBeenSubmitted() &&
                            <>
                                <FrontFormSectionTitle>
                                    Need to change something?
                                </FrontFormSectionTitle>
                                <SpacingColumn spacing={FrontSpacing.SMALL_3}>
                                    <FrontParagraphSmall>
                                        If you have noticed that any of the details you have entered are wrong <WeightBold>please call us</WeightBold> on <WeightBold>{sailLegalBrandConstants().legalCSNumber}</WeightBold> and we will fix them.
                                    </FrontParagraphSmall>
                                </SpacingColumn>
                            </>
                        }
                    </SpacingColumn>

                </SpacingColumn>
            </FrontQuestionFormCard>

            <FrontPopupPrompt
                title={`Are these details correct?`}
                isOpen={isSubmitFormOpen}
                onCTA={() => props.dispatch({
                    type: "CLIENT_SUBMIT_REMORTGAGE_MORTGAGE_DETAILS",
                    payload: null,
                })}
                ctaText="Yes, that's everything"
                closeText="No"
                onClose={() => setIsSubmitFormOpen(false)}
            >
                <SpacingColumn spacing={FrontSpacing.SMALL_3}>
                    <FrontParagraphRegular>
                        After you submit, you will not be able to edit your mortgage details unless you call us.
                    </FrontParagraphRegular>
                    <FrontParagraphRegular>
                        If you are happy with everything we can go onto the next step.
                    </FrontParagraphRegular>
                </SpacingColumn>
            </FrontPopupPrompt>
        </div>
    );
};

export const getRemortgageMortgageDetailsStatus = (props: TContainerStateProps): TOnboardingTabStatus => {
    if (
        props.state.forms.client_case_page.children.remortgage_details?.children.is_step_complete !== null
        && props.state.forms.client_case_page.children.remortgage_details?.edited.mortgage_lender_name !== 'unknown'
        && props.state.forms.client_case_page.children.remortgage_details?.edited.expected_mortgage_amount_pence !== null
    ) {
        return "completed";
    };

    return "active";
};

export default FrontRemortgageMortgageDetailsContainer;
