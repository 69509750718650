import { Observable } from "rxjs";
import * as rxjsOperators from "rxjs/operators";
import * as SendMagicLink1 from "../../../../../domain/models/SendMagicLink1";
import { pipeSubscribed } from "../../../functions/lens/pipe";
import { setFormSubscribed } from "../../../functions/lens/setFormSubscribed";
import { submitForm } from "../../../functions/lens/submitForm";
import {
    TSendMagicLink1Form,
} from "../../../models/TFormModels";
import * as TAuthResendLink from "../../TAuthResendLink";
import { TGetState } from "../../TGetState";
import { TSetState } from "../../TSetState";
import { TActionsDefinitionsList } from "../TAction";
import { TChangeRouteAction } from "../../router/routerTypes";

export const actions: TActionsDefinitionsList = [
    {
        type: "VIEW_MAGIC_LINK_RESEND" as const,
        run: (obs$: Observable<TChangeRouteAction<"VIEW_MAGIC_LINK_RESEND">>, getState: TGetState, setState: TSetState) => {
            obs$.subscribe(() => {
                setState(({...s}) => {
                    s.activeData.authResendLink.resendLinkForm = {
                        ...new TAuthResendLink.C().resendLinkForm,
                        edit: {
                            email: "",
                            redirect_url: getState().routes.queryParams.VIEW_MAGIC_LINK_RESEND.redirect_url,
                        },
                    }
                    return s;
                })
            });
        }
    },
    {
        type: "MAGIC_LINK_RESEND_INPUT_CHANGED" as const,
        run: setFormSubscribed<"MAGIC_LINK_RESEND_INPUT_CHANGED", TSendMagicLink1Form>()(
            ["activeData", "authResendLink", "resendLinkForm"],
        ),
    },
    {
        type: "MAGIC_LINK_RESEND_SUBMIT" as const,
        run: pipeSubscribed<"MAGIC_LINK_RESEND_SUBMIT", {}>(
            (obs$, getState, setState) =>
                submitForm()(
                    ["activeData", "authResendLink", "resendLinkForm"],
                    () => `/v1/public/send-magic-link`,
                    "POST",
                    SendMagicLink1.validator("web"),
                )(obs$, getState, setState),
            (obs$, getState) =>
                obs$.pipe(
                    rxjsOperators.tap(() =>
                        getState().activeData.authResendLink.resendLinkForm.status === "success"
                            ? window.location.href = `/auth/resent-link?redirect_url=${encodeURIComponent(getState().activeData.authResendLink.resendLinkForm.edit.redirect_url)}`
                            : undefined,
                    ),
                ),
        ),
    },
];
