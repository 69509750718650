import { TFormV2 } from "./TForm";
import * as CaseEnquiryTimeline1 from "../../../domain/models/CaseEnquiryTimeline1";
import * as util from "../../../shared/src/util";

export type T<V extends CaseEnquiryTimeline1.T = CaseEnquiryTimeline1.T> = TFormV2<V, {}, {
    enquiryId: string;
    isDeleteModalOpen: boolean;
}>;

type TFoldParams<R> = {
    onCaseEnquiryDocument3: (p: T<CaseEnquiryTimeline1.TCaseEnquiryDocument3>) => R;
    onLegalEmail4: (p: T<CaseEnquiryTimeline1.TLegalEmail4>) => R;
    onCaseEnquiryNote5: (p: T<CaseEnquiryTimeline1.TCaseEnquiryNote5>) => R;
};

export const fold = <R>(fp: TFoldParams<R>) => (p: T): R =>
    p.view._tag === "document" ? fp.onCaseEnquiryDocument3(p as T<CaseEnquiryTimeline1.TCaseEnquiryDocument3>)
    : p.view._tag === "email" ? fp.onLegalEmail4(p as T<CaseEnquiryTimeline1.TLegalEmail4>)
    : p.view._tag === "note" ? fp.onCaseEnquiryNote5(p as T<CaseEnquiryTimeline1.TCaseEnquiryNote5>)
    : util.requireExhaustive(p.view._tag);
