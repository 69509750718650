import React from "react";
import { TContainerStateProps } from "../../state/TContainerStateProps";
import { CRMMenuBar } from "../../components/Complex/CRMMenuBar/CRMMenuBar";
import { CRMColors } from "../../models/CRMColors";

export const CRMProbateMenuBarContainer = (props: TContainerStateProps): JSX.Element => {
    return (
        <div>
            <CRMMenuBar
                logoHref="/crm/probate/probate-helpline/callbacks"
                colors={{
                    backgroundColor: CRMColors.HIGHLIGHTS_CALMING_PINK_0,
                    logoBackgroundColor: CRMColors.HIGHLIGHTS_CALMING_PINK_2,
                    logoIconColor: "highlights-calming-pink-12",
                    hoverColor: CRMColors.HIGHLIGHTS_CALMING_PINK_4,
                }}
                leftButtons={[
                    {
                        icon: "callback" as const,
                        label: "Probate helpline callbacks",
                        href: "/crm/probate/probate-helpline/callbacks",
                    },
                ]}
                centerButtons={[]}
                rightButtons={[
                    {
                        icon: "logout",
                        label: "Logout",
                        href: `${env.REACT_APP_AUTH_URL}/web/logout`,
                        onClick: (e) => {
                            if (confirm("Confirm logout") !== true) {
                                e.preventDefault();
                            }
                        }
                    },
                ]}
            />
        </div>
    );
};
