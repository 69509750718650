import React from "react";
import { TIntroducerForm } from "../../../../../domain/codecs/form/IntroducerForm";
import { formatDate } from "../../../../../shared/src/utilsByDomain/dateTime";
import { capitalizeEveryWord } from "../../../../../shared/src/utilsByDomain/string/capitalizeEveryWord";
import { dashCaseToNormalCase } from "../../../../../shared/src/utilsByDomain/string/dashCaseToNormalCase";
import { requireExhaustive } from "../../../../../shared/src/util";
import { CRMColors } from "../../../models/CRMColors";
import { CRMFontSizes } from "../../../models/CRMFontSizes";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { Background } from "../../BuildingBlocks/Background";
import { FontQuicksand } from "../../BuildingBlocks/FontQuicksand";
import { Text } from "../../BuildingBlocks/Text";
import { getIntroducerStatus } from "../../Complex/CRMIntroducerSingleView/CRMIntroducerSingleView";
import { CRMLabelAndMonoValue } from "../CRMLabelAndMonoValue/CRMLabelAndMonoValue";
import { TProbateHelplineCallbackForm } from "../../../../../domain/codecs/form/ProbateHelplineCallbackForm";
import { isLongWait } from "../../Complex/CRMProbateHelplineCallbackNowRow/CRMProbateHelplineCallbackNowRow";
import { DateTime } from "luxon";
import { pipe } from "fp-ts/lib/function";

export const CRMProbateHelplineCallbackWaitBubble = (props: React.PropsWithChildren<{
    form: TProbateHelplineCallbackForm,
}>): JSX.Element => {

    const backgroundColour =
        isLongWait(props.form.children.created_at)
            ? CRMColors.HIGHLIGHTS_INSTRUCTIONAL_BRICK_10
            : CRMColors.NEUTRAL_10;

    const waitAbbreviation =
        pipe(
            DateTime.utc().diff(DateTime.fromISO(props.form.children.created_at)).shiftTo("days", "hours"),
            ({days, hours}) => ({
                days: Math.floor(days),
                hours: Math.floor(hours),
            }),
            ({days, hours}) =>
                days === 0 && hours === 0 ? "a few moments"
                : days === 0 ? `${hours} hours`
                : `${days} days, ${hours} hours`
        );

    return <div>
        <Background
            display="inline-flex" 
            color={backgroundColour}
            borderRadius="5px"
            padding={CRMSpacing.SMALL}
        >
            <FontQuicksand>
                <Text
                    size={CRMFontSizes.MED}
                    lineHeight={1}
                    weight={500}
                >
                    Waiting {waitAbbreviation}
                </Text>
            </FontQuicksand>
        </Background>
    </div>;
};
