import React, { useState } from "react";
import { SpacingColumn } from "../../components/BuildingBlocks/SpacingColumn";
import { FrontQuestionFormCard } from "../../components/Front/Complex/FrontQuestionFormCard/FrontQuestionFormCard";
import FrontParagraphSmall from "../../components/Front/Simple/FrontParagraphSmall/FrontParagraphSmall";
import { TContainerStateProps } from "../../state/TContainerStateProps";
import { FrontSpacing } from "../../models/FrontSpacing";
import { WeightBold } from "../../components/WeightBold/WeightBold";
import { TOnboardingTabStatus } from "../../components/Front/Simple/FrontOnboardingTab/FrontOnboardingTab";
import { ClientCaseJointOwnershipHeldAsForm, ClientCaseJointOwnershipHeldAsFormTenantsInCommonPreference_displayString, ClientCaseJointOwnershipHeldAsFormType_displayString } from "../../../../domain/codecs/form/ClientCaseForm";
import { WeightSemiBold } from "../../components/WeightSemiBold/WeightSemiBold";
import { WeightMedium } from "../../components/WeightMedium/WeightMedium";
import { FrontCondensedQuestionWrap } from "../../components/Front/Simple/FrontCondensedQuestionWrap/FrontCondensedQuestionWrap";
import { FrontRadioCardList } from "../../components/Front/Simple/FrontRadioCardList/FrontRadioCardList";
import { FrontInfoBubble } from "../../components/Front/Simple/FrontInfoBubble/FrontInfoBubble";
import { FrontColors } from "../../models/FrontColors";
import { FrontBulletpoint } from "../../components/Front/Simple/FrontBulletpoint/FrontBulletpoint";
import { FrontHelpBubble } from "../../components/Front/Simple/FrontHelpBubble/FrontHelpBubble";
import FrontInputLabelAndErrorWrapComponent from "../../components/Front/Simple/FrontInputLabelAndErrorWrapComponent/FrontInputLabelAndErrorWrapComponent";
import { FrontRadioList } from "../../components/Front/Simple/FrontRadioList/FrontRadioList";
import { MediaCompSwitch } from "../../components/BuildingBlocks/MediaCompSwitch";
import { SpacingRow } from "../../components/BuildingBlocks/SpacingRow";
import { FrontFormPrimaryButton } from "../../components/Front/Simple/FrontFormPrimaryButton/FrontFormPrimaryButton";

const FrontJointOwnershipHeldAsPreferenceContainer = (props: TContainerStateProps): JSX.Element => {
    const form = props.state.forms.client_case_page.children.joint_ownership_held_as_preference?.form || ClientCaseJointOwnershipHeldAsForm.newDefault();

    const isStepComplete =
        typeof props.state.forms.client_case_page.children.joint_ownership_held_as_preference !== "undefined"
            ? props.state.forms.client_case_page.children.joint_ownership_held_as_preference.is_step_complete
            : true;

    const typeQuestionSummary = ClientCaseJointOwnershipHeldAsFormType_displayString(form.edited.type);

    const tenantsInCommonPreferenceQuestionSummary = ClientCaseJointOwnershipHeldAsFormTenantsInCommonPreference_displayString(form.edited.tenants_in_common_preference);

    const [isTypeQuestionOpen, setIsTypeQuestionOpen] = useState<boolean>(true);

    const [isTenantsInCommonPreferenceOpen, setIsTenantsInCommonPreferenceOpen] = useState<boolean>(false);

    const [displayValidationErrorsIfPresent, setDisplayValidationErrorsIfPresent] = useState<boolean>(false);

    const isFormReadyToSubmit =
        form.edited.type === "joint_tenants"
        || form.edited.type === "not_applicable"
        || (
            form.edited.type === "tenants_in_common"
            && form.edited.tenants_in_common_preference !== null
        );

    const onChange = <A extends keyof typeof form.edited>(key: A) => (value: typeof form.edited[A]) => {
        if (key === "type") {
            setIsTypeQuestionOpen(false);
            setIsTenantsInCommonPreferenceOpen(true);
        }
        if (key === "tenants_in_common_preference") {
            setIsTenantsInCommonPreferenceOpen(false);
        }

        props.dispatch({
            type: "CLIENT_JOINT_OWNERSHIP_HELD_AS_PREFERENCE_CHANGE",
            payload: {
                ...form,
                edited: {
                    ...form.edited,
                    [key]: value,
                }
            }
        });
    };

    const onSubmit = () =>
        props.dispatch({
            type: "CLIENT_JOINT_OWNERSHIP_HELD_AS_PREFERENCE_SUBMIT",
        });

    return (
        <FrontQuestionFormCard
            title="How the property will be jointly owned"
        >
            {/* ACTIVE */}
            {!isStepComplete &&
                <SpacingColumn spacing={FrontSpacing.MEDIUM_2}>
                    {/* EXPLANATION PARAGRAPHS */}
                    <SpacingColumn spacing={FrontSpacing.SMALL_2}>
                        <FrontParagraphSmall>
                            If there will be multiple owners of the property you need to make a decision about how you'd
                            like to handle ownership of the property <WeightBold>if one of you were to die or your relationship breaks down.</WeightBold>
                        </FrontParagraphSmall>

                        <FrontParagraphSmall>
                            We do this by telling Land Registry of your wishes when we register the property in your names.
                        </FrontParagraphSmall>

                        <FrontParagraphSmall>
                            Land Registry provide a few different options for you to choose from, please select the one you <WeightMedium>wish to proceed with.</WeightMedium>
                        </FrontParagraphSmall>
                    </SpacingColumn>

                    {/* OPTIONS */}
                    <FrontCondensedQuestionWrap
                        isOpen={isTypeQuestionOpen}
                        setIsOpen={() => setIsTypeQuestionOpen(true)}
                        label="You have chosen to share ownership as"
                        isEditable={true}
                        summary={typeQuestionSummary}
                    >
                        <FrontRadioCardList
                            value={form.edited.type}
                            options={[
                                {
                                    value: "joint_tenants",
                                    label: "Joint tenants",
                                    children: <FrontInfoBubble color={FrontColors.PRIMARY_22}>
                                        <FrontParagraphSmall>
                                            <SpacingColumn spacing={FrontSpacing.SMALL_1}>
                                                <WeightBold>
                                                    As joint tenants:
                                                </WeightBold>

                                                <SpacingColumn spacing={FrontSpacing.MINIMUM}>
                                                    <FrontBulletpoint pointAdjust="11px"><WeightMedium>You have <WeightBold>equal rights</WeightBold> to the whole property.</WeightMedium></FrontBulletpoint>
                                                    <FrontBulletpoint pointAdjust="11px"><WeightMedium>The property automatically goes to the other owners if you die.</WeightMedium></FrontBulletpoint>
                                                    <FrontBulletpoint pointAdjust="11px"><WeightMedium>You <WeightBold>cannot</WeightBold> pass on your ownership of the property in your will.</WeightMedium></FrontBulletpoint>
                                                </SpacingColumn>
                                            </SpacingColumn>
                                        </FrontParagraphSmall>
                                    </FrontInfoBubble>
                                },
                                {
                                    value: "tenants_in_common",
                                    label: "Tenants in common",
                                    children: <SpacingColumn spacing={FrontSpacing.MEDIUM_1}>
                                        <FrontInfoBubble color={FrontColors.PRIMARY_22}>
                                            <FrontParagraphSmall>
                                                <SpacingColumn spacing={FrontSpacing.SMALL_1}>
                                                    <WeightBold>
                                                        As tenants in common:
                                                    </WeightBold>

                                                    <SpacingColumn spacing={FrontSpacing.MINIMUM}>
                                                        <FrontBulletpoint pointAdjust="11px"><WeightMedium>you each own a <WeightBold>percentage share</WeightBold> of the property (can be equal but doesn't have to be).</WeightMedium></FrontBulletpoint>
                                                        <FrontBulletpoint pointAdjust="11px"><WeightMedium>the property does not automatically go to the other owners if you die.</WeightMedium></FrontBulletpoint>
                                                        <FrontBulletpoint pointAdjust="11px"><WeightMedium>you <WeightBold>can</WeightBold> pass on your share of the property in your will.</WeightMedium></FrontBulletpoint>
                                                    </SpacingColumn>
                                                </SpacingColumn>
                                            </FrontParagraphSmall>
                                        </FrontInfoBubble>

                                        {/* DECLARATION OF TRUST PROTECTION */}
                                        <FrontHelpBubble
                                            type="success"
                                            label={<WeightMedium>Declaration of Trust <WeightSemiBold>Protection</WeightSemiBold></WeightMedium>}
                                            labelIcon="shield-done"
                                            paragraph={<FrontParagraphSmall>
                                                <SpacingColumn spacing={FrontSpacing.SMALL_1}>
                                                    <div>
                                                        This type of ownership <WeightSemiBold>can</WeightSemiBold> benefit from a Declaration of Trust which can be used to:
                                                    </div>

                                                    <SpacingColumn spacing={FrontSpacing.MINIMUM}>
                                                        <FrontBulletpoint pointAdjust="11px"><WeightMedium>protect one persons money <WeightBold>if they have contributed more towards the property.</WeightBold></WeightMedium></FrontBulletpoint>
                                                        <FrontBulletpoint pointAdjust="11px"><WeightMedium>protect cohabiting <WeightBold>unmarried couples/friends.</WeightBold></WeightMedium></FrontBulletpoint>
                                                        <FrontBulletpoint pointAdjust="11px"><WeightMedium>protect <WeightBold>business partners</WeightBold> who own property together.</WeightMedium></FrontBulletpoint>
                                                    </SpacingColumn>
                                                </SpacingColumn>
                                            </FrontParagraphSmall>}
                                        />
                                    </SpacingColumn>
                                },
                                {
                                    value: "not_applicable",
                                    label: "There's only one owner or this isn't applicable to me.",
                                    children: <></>,
                                },
                            ]}
                            onChange={onChange("type")}
                        />
                    </FrontCondensedQuestionWrap>

                    {/* TENANTS IN COMMON PREFERENCE */}
                    {form.edited.type === "tenants_in_common" &&
                        <FrontCondensedQuestionWrap
                            isOpen={isTenantsInCommonPreferenceOpen}
                            setIsOpen={() => setIsTenantsInCommonPreferenceOpen(true)}
                            label="Each person will have"
                            isEditable={true}
                            summary={tenantsInCommonPreferenceQuestionSummary}
                        >
                            <FrontInputLabelAndErrorWrapComponent label="How many shares in the property will each person have?">
                                <FrontRadioList
                                    value={form.edited.tenants_in_common_preference}
                                    options={[
                                        {
                                            value: "equal_shares",
                                            label: ClientCaseJointOwnershipHeldAsFormTenantsInCommonPreference_displayString("equal_shares"),
                                        },
                                        {
                                            value: "different_shares",
                                            label: ClientCaseJointOwnershipHeldAsFormTenantsInCommonPreference_displayString("different_shares"),
                                        },
                                    ]}
                                    onChange={onChange("tenants_in_common_preference")}
                                />
                            </FrontInputLabelAndErrorWrapComponent>
                        </FrontCondensedQuestionWrap>
                    }

                    <SpacingColumn spacing={FrontSpacing.MEDIUM_1}>
                        <MediaCompSwitch
                            DesktopComponent={SpacingRow}
                            desktopProps={{ spacing: FrontSpacing.SMALL_3}}
                            MobileComponent={SpacingColumn}
                            mobileProps={{ spacing: FrontSpacing.SMALL_3 }}
                            breakpoint="600"
                        >
                            <FrontFormPrimaryButton
                                label="Continue"
                                icon="done"
                                isDisabled={false}
                                onClick={() =>
                                    isFormReadyToSubmit
                                        ? onSubmit()
                                        : setDisplayValidationErrorsIfPresent(true)
                                }
                            />
                        </MediaCompSwitch>

                        {displayValidationErrorsIfPresent && !isFormReadyToSubmit && <FrontInfoBubble color={FrontColors.ERROR_FIREBRICK_22}>
                            <WeightBold>You've missed a few things above,</WeightBold> please take a look and then try again.
                        </FrontInfoBubble>}
                    </SpacingColumn>
                </SpacingColumn>
            }

            {/* COMPLETE */}
            {isStepComplete &&
                <FrontParagraphSmall>
                    Thank you, your preferences have been saved.
                </FrontParagraphSmall>
            }
        </FrontQuestionFormCard>
    );
};

export const getJointOwnershipHeldAsPreferenceStatus = (props: TContainerStateProps): TOnboardingTabStatus => {
    if (props.state.forms.client_case_page.children.joint_ownership_held_as_preference?.is_step_complete) {
        return "completed";
    }
    
    return "active";
};

export default FrontJointOwnershipHeldAsPreferenceContainer;

