import React from "react";
import { TContainerStateProps } from "./../../state/TContainerStateProps";
import { CRMTitleSection } from "../../components/CRMTitleSection/CRMTitleSection";
import { CRMSpacer } from "../../components/CRMSpacer/CRMSpacer";
import { CRMAutosaveIndicatorWrap } from "../../components/CRM/CRMAutosaveIndicatorWrap/CRMAutosaveIndicatorWrap";
import { CRMRiskManagement } from "../../components/CRMRiskManagement/CRMRiskManagement";

const CRMLegalCaseDetailsRiskManagementContainer = (props: TContainerStateProps): JSX.Element =>
    <CRMAutosaveIndicatorWrap
        status={props.state.forms.case_details_page.data.output.details.status}
    >
        <CRMTitleSection>
            Risk Management
        </CRMTitleSection>
        <CRMSpacer size="large" />

        {/* RISK ASSESSMENTS */}
        <CRMRiskManagement
            newForm={props.state.forms.case_details_page.data.output.details.children.risk_assessments.new_form}
            assessments={props.state.forms.case_details_page.data.output.details.children.risk_assessments.assessments}
            newAssessmentRequired={props.state.forms.case_details_page.data.output.details.children.risk_assessments.new_assessment_required}
            onNewFormChange={(payload) => props.dispatch({
                type: "LEGAL_CASE_UPDATE_NEW_RISK_ASSESSMENT",
                payload,
            })}
            onNewSubmit={() => props.dispatch({
                type: "LEGAL_CASE_SUBMIT_NEW_RISK_ASSESSMENT",
                payload: undefined,
            })}
        />
    </CRMAutosaveIndicatorWrap>

export default CRMLegalCaseDetailsRiskManagementContainer;