import React from "react";
import { TClientCompanyPartnershipForm } from "../../../../../domain/codecs/form/ClientCompanyPartnershipForm";
import { onChangeForm } from "../../../../../shared/src/codecs/types/form";
import { CRMCompanyPartnerAMLAndKYCQuestion } from "../../Simple/CRMCompanyPartnerAMLAndKYCQuestion/CRMCompanyPartnerAMLAndKYCQuestion";

export const CRMCompanyPartnerAMLAndKYCForm = (props: React.PropsWithChildren<{
    form: TClientCompanyPartnershipForm;
    onChange: (form: TClientCompanyPartnershipForm) => void;
}>): JSX.Element => {
    const onChange = onChangeForm(props.form, props.onChange);

    return <>
        <CRMCompanyPartnerAMLAndKYCQuestion
            label="Is this company listed on ANY stock exchange?"
            value={props.form.edited.sub_type_listing_on_any_stock_exchange}
            onChange={onChange("sub_type_listing_on_any_stock_exchange")}
        />

        <CRMCompanyPartnerAMLAndKYCQuestion
            label="Is this company listed as an Alternative Investment Market (AIM) company?"
            value={props.form.edited.sub_type_aim_listed}
            onChange={onChange("sub_type_aim_listed")}
        />

        <CRMCompanyPartnerAMLAndKYCQuestion
            label="Is this company majority owned or a consolidated subsidiary?"
            value={props.form.edited.sub_type_majority_owned_or_consolidated_subsidiary}
            onChange={onChange("sub_type_majority_owned_or_consolidated_subsidiary")}
        />

        <CRMCompanyPartnerAMLAndKYCQuestion
            label="Is this company regulated by the Financial Conduct Authority?"
            value={props.form.edited.sub_type_fca_regulated}
            onChange={onChange("sub_type_fca_regulated")}
        />

        <CRMCompanyPartnerAMLAndKYCQuestion
            label="Is this company a non-UK credit or financial institutions (including nominee or trustee subsidiaries)?"
            value={props.form.edited.sub_type_other_non_uk_credit_or_financial_institutions}
            onChange={onChange("sub_type_other_non_uk_credit_or_financial_institutions")}
        />

        <CRMCompanyPartnerAMLAndKYCQuestion
            label="Is this company a UK or EEA public authorities or EU institution?"
            value={props.form.edited.sub_type_uk_or_eea_public_authorities}
            onChange={onChange("sub_type_uk_or_eea_public_authorities")}
        />

        <CRMCompanyPartnerAMLAndKYCQuestion
            label="Has this been instructed to us by a 'Professional' (firm or individual)?"
            value={props.form.edited.sub_type_professionals_instructing}
            onChange={onChange("sub_type_professionals_instructing")}
        />

        <CRMCompanyPartnerAMLAndKYCQuestion
            label="Is this company a Foundation?"
            value={props.form.edited.sub_type_foundation}
            onChange={onChange("sub_type_foundation")}
        />

        <CRMCompanyPartnerAMLAndKYCQuestion
            label="Is this company a UK registered charity?"
            value={props.form.edited.sub_type_uk_charity}
            onChange={onChange("sub_type_uk_charity")}
        />

        <CRMCompanyPartnerAMLAndKYCQuestion
            label="Is this a church or place of worship (excludes Charities)?"
            value={props.form.edited.sub_type_place_of_worship}
            onChange={onChange("sub_type_place_of_worship")}
        />

        <CRMCompanyPartnerAMLAndKYCQuestion
            label="Is this a state school or college?"
            value={props.form.edited.sub_type_state_school_or_college}
            onChange={onChange("sub_type_state_school_or_college")}
        />

        <CRMCompanyPartnerAMLAndKYCQuestion
            label="Is this a club or association?"
            value={props.form.edited.sub_type_club_or_association}
            onChange={onChange("sub_type_club_or_association")}
        />

        <CRMCompanyPartnerAMLAndKYCQuestion
            label="Is the main product of this company a pension scheme?"
            value={props.form.edited.sub_type_pension_scheme}
            onChange={onChange("sub_type_pension_scheme")}
        />

        <CRMCompanyPartnerAMLAndKYCQuestion
            label="Is this company related to a sovereign wealth fund?"
            value={props.form.edited.sub_type_sovereign_wealth_funds}
            onChange={onChange("sub_type_sovereign_wealth_funds")}
        />
    </>;
}
