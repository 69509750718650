import { pipe } from "fp-ts/lib/function";
import { array, identity, option } from "fp-ts";
import React, { useState } from "react";
import { TCasesResultsForTriage, TDetailedEmailForm, TTriageEmailAssignToCaseAndUserForm, TTriageEmailAssignToCaseForm, TTriageEmailAssignToUserOnlyForm, TTriageEmailMarkAsIrrelevantForm, TTriageSimpleEmailForm } from "../../../../../domain/codecs/form/TriageForm";
import { CRMBottomControlBar } from "../../CRMBottomControlBar/CRMBottomControlBar";
import { CRMConfirmationCountdown } from "../CRMConfirmationCountdown/CRMConfirmationCountdown";
import { CRMEmailSingleViewBody } from "../CRMEmailSingleViewBody/CRMEmailSingleViewBody";
import { CRMEmailSingleViewControls } from "../CRMEmailSingleViewControls/CRMEmailSingleViewControls";
import { useResolutionStatus } from "../../../hooks/useResolutionStatus";
import { CRMConfirmationCountdownWithLabels } from "../CRMConfirmationCountdownWithLabels/CRMConfirmationCountdownWithLabels";
import { CRMAssignToCaseSectionPopout } from "../CRMAssignToCaseSectionPopout/CRMAssignToCaseSectionPopout";
import { CRMAssignToPersonSectionPopout } from "../CRMAssignToPersonSectionPopout/CRMAssignToPersonSectionPopout";

type TResolutionStatus = 
    "assign-to-case-and-me"
    | "assign-to-case-and-staff"
    | "assign-to-case-and-handler"
    | "assign-to-case-and-resolve"
    | "assign-to-person"
    | "archive"
    | "none"
;

type TResolutionData = {
    form?: TResolutionForms;
    caseResult?: TCasesResultsForTriage;
}

type TResolutionForms = 
    TTriageEmailAssignToCaseForm
    | TTriageEmailAssignToCaseAndUserForm
    | TTriageEmailAssignToUserOnlyForm
;

type TEmailViewStatus = 
    "email-body"
    | "assign-to-case"
    | "assign-to-person"
;

type TTriageEmailSingleViewProps = {
    count: number;
    email: TTriageSimpleEmailForm;
    onChangeSearchText: (searchText: string) => void;

    isShowingNext: boolean;
    isShowingPrevious: boolean;
    
    onClose: () => void;
    onNext: () => void;
    onPrevious: () => void;
    onArchive: (form: TTriageEmailMarkAsIrrelevantForm) => void;
    onAssignToPerson: (form: TTriageEmailAssignToUserOnlyForm) => void;
    onAssignToCaseAndMe: (form: TTriageEmailAssignToCaseAndUserForm) => void;
    onAssignToCaseAndStaff: (form: TTriageEmailAssignToCaseAndUserForm) => void;
    onAssignToCaseAndHandler: (form: TTriageEmailAssignToCaseAndUserForm) => void;
    onAssignToCaseAndResolve: (form: TTriageEmailAssignToCaseForm) => void;
    onReply: (form: TDetailedEmailForm) => void;
    onForward: (form: TDetailedEmailForm) => void;
};

export const    CRMTriageEmailSingleViewUnassigned = (props: React.PropsWithChildren<TTriageEmailSingleViewProps>): JSX.Element => {

    const [viewStatus, setViewStatus] = useState<TEmailViewStatus>("email-body");
    const {
        resolutionStatus,
        resolutionData,
        setResolution,
        resetResolution,
    } = useResolutionStatus<TResolutionStatus, TResolutionData>({
        defaultStatus: "none",
        onSet: () => setViewStatus("email-body")
    });


    const onSelectPersonToAssignTo = (personId: string) => pipe(
        props.email.children.detailed_email_form.children.assign_to_user_only_forms,
        array.findFirst((form) => form.original.user_id === personId),
        option.fold(() => undefined, identity.flatten),
        (form) => ({ form }),
        setResolution("assign-to-person"),
    );

    const onArchive = () => {
        props.onArchive(props.email.children.detailed_email_form.children.mark_as_irrelevant_form);
        resetResolution();
    };
    
    const onAssignToPerson = () => {
        props.onAssignToPerson(resolutionData?.form as TTriageEmailAssignToUserOnlyForm);
        resetResolution();
    };
    
    const onAssignToCaseAndMe = () => {
        props.onAssignToCaseAndMe(resolutionData?.form as TTriageEmailAssignToCaseAndUserForm);
        resetResolution();
    };
    
    const onAssignToCaseAndStaff = () => {
        props.onAssignToCaseAndStaff(resolutionData?.form as TTriageEmailAssignToCaseAndUserForm);
        resetResolution();
    };
    
    const onAssignToCaseAndHandler = () => {
        props.onAssignToCaseAndHandler(resolutionData?.form as TTriageEmailAssignToCaseAndUserForm);
        resetResolution();
    };
    
    const onAssignToCaseAndResolve = () => {
        props.onAssignToCaseAndResolve(resolutionData?.form as TTriageEmailAssignToCaseForm);
        resetResolution();
    };

    const hasDetailedEmail = () => !!props.email.children.detailed_email_form.children.email.id;


    return (
        <div className={`crm-email-single-view`}>

            {/* POPUP - ASSIGN TO CASE */}
            {viewStatus === "assign-to-case" &&
                <CRMAssignToCaseSectionPopout
                    email={props.email}
                    onChangeSearchText={props.onChangeSearchText}
                    onAssignAndResolveWithNoAction={setResolution("assign-to-case-and-resolve")}
                    onAssignToMe={setResolution("assign-to-case-and-me")}
                    onAssignToStaff={setResolution("assign-to-case-and-staff")}
                    onAssignToHandler={setResolution("assign-to-case-and-handler")}
                    onClose={() => setViewStatus("email-body")}
                />
            }
            
            {/* POPUP - ASSIGN TO PERSON */}
            {viewStatus === "assign-to-person" &&
                <CRMAssignToPersonSectionPopout
                    email={props.email}
                    onSelectPersonToAssignTo={onSelectPersonToAssignTo}
                    onClose={() => setViewStatus("email-body")}
                />
            }

            {/* BODY */}
            <CRMEmailSingleViewBody
                {...props}
            />
            
            {/* CONTROLS & COUNTDOWN NOTIFICATIONS */}
            <CRMBottomControlBar>
                {resolutionStatus === "none" &&
                    <CRMEmailSingleViewControls
                        hideAllActions={! hasDetailedEmail()}
                        isShowingPrevious={props.isShowingPrevious}
                        isShowingNext={props.isShowingNext}
                        onPrevious={props.onPrevious}
                        onNext={props.onNext}
                        
                        onArchive={setResolution("archive")}
                        onAssignToCase={() => setViewStatus("assign-to-case")}
                        onAssignToPerson={() => setViewStatus("assign-to-person")}
                        onReply={() => props.onReply(props.email.children.detailed_email_form)}
                        onForward={() => props.onForward(props.email.children.detailed_email_form)}
                    />
                }

                {resolutionStatus === "archive" &&
                    <CRMConfirmationCountdown
                        title="Archiving!"
                        iconName="archive"
                        primaryButtonMode="next"
                        onUndo={resetResolution}
                        onComplete={onArchive}
                    />
                }
                
                {resolutionStatus === "assign-to-person" &&
                    <CRMConfirmationCountdownWithLabels
                        title="Assigning to person"
                        iconName="person"
                        primaryButtonMode="next"
                        onUndo={resetResolution}
                        onComplete={onAssignToPerson}
                        labels={[
                            ["Name", resolutionData?.form?.children["name"]]
                        ]}
                    />
                }
                
                {resolutionStatus === "assign-to-case-and-me" &&
                    <CRMConfirmationCountdownWithLabels
                        title="Assigning email"
                        iconName="email-ticked"
                        primaryButtonMode="next"
                        onUndo={resetResolution}
                        onComplete={onAssignToCaseAndMe}
                        labels={[
                            ["To case", resolutionData?.caseResult?.addresses[0]],
                            ["To person", "you"],
                        ]}
                    />
                }
                
                {resolutionStatus === "assign-to-case-and-staff" &&
                    <CRMConfirmationCountdownWithLabels
                        title="Assigning to person!"
                        iconName="email-ticked"
                        primaryButtonMode="next"
                        onUndo={resetResolution}
                        onComplete={onAssignToCaseAndStaff}
                        labels={[
                            ["To case", resolutionData?.caseResult?.addresses[0]],
                            ["To person", resolutionData?.form?.children["name"]],
                        ]}
                    />
                }
                
                {resolutionStatus === "assign-to-case-and-handler" &&
                    <CRMConfirmationCountdownWithLabels
                        title="Assigning to handler!"
                        iconName="email-ticked"
                        primaryButtonMode="next"
                        onUndo={resetResolution}
                        onComplete={onAssignToCaseAndHandler}
                        labels={[
                            ["To case", resolutionData?.caseResult?.addresses[0]],
                            ["Handler", resolutionData?.form?.children["name"]],
                        ]}
                    />
                }
                
                {resolutionStatus === "assign-to-case-and-resolve" &&
                    <CRMConfirmationCountdownWithLabels
                        title="Assigning and resolving"
                        iconName="done-all"
                        primaryButtonMode="next"
                        onUndo={resetResolution}
                        onComplete={onAssignToCaseAndResolve}
                        labels={[
                            ["To case", resolutionData?.caseResult?.addresses[0]]
                        ]}
                    />
                }
            </CRMBottomControlBar>
        </div>
    );
};
