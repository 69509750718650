import React from "react";
import { Background } from "../../../BuildingBlocks/Background";
import { CRMColors } from "../../../../models/CRMColors";
import { FrontColors } from "../../../../models/FrontColors";
import { FrontSpacing } from "../../../../models/FrontSpacing";
import { SpacingRow } from "../../../BuildingBlocks/SpacingRow";
import { FontRoboto } from "../../../BuildingBlocks/FontRoboto";
import { Text } from "../../../BuildingBlocks/Text";
import { FrontFontSizes } from "../../../../models/FrontFontSizes";
import { FrontIcon } from "../../../Front/Simple/FrontIcon/FrontIcon";
import { Width } from "../../../BuildingBlocks/Width";

export const FPDownloadRegister = (props: {
    url: string
}): JSX.Element =>
    <Width width="100%">
        <a
            href={props.url}
            target="_blank"
            style={{
                textDecoration: "none",
            }}
        >
            <Background
                borderRadius="8px"
                borderDefinition={`solid 2px ${CRMColors.PRIMARY_2}`}
                color={FrontColors.PRIMARY_2}
                padding={`${FrontSpacing.SMALL_3} 25px`}
            >
                <SpacingRow
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={FrontSpacing.MEDIUM_2}
                >
                    {/* TEXT */}
                    <FontRoboto>
                        <Text
                            color={CRMColors.PRIMARY_16}
                            lineHeight={1.5}
                            size={FrontFontSizes.X_SMALL}
                            weight={700}
                        >
                            Download Register
                        </Text>
                    </FontRoboto>

                    {/* ICON */}
                    <FrontIcon
                        iconName="document"
                        colour={CRMColors.PRIMARY_16}
                        size="large"
                    />
                </SpacingRow>
            </Background>
        </a>
    </Width>;
