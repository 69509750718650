import React from "react";
import { WeightMedium } from "../WeightMedium/WeightMedium";

export class CRMTitleClear extends React.Component<React.PropsWithChildren<{
    fontSize?: "med" | "large";
}>> {
    public render (): JSX.Element {
        return (
            <div className={`crm-title-clear crm-title-clear--${this.props.fontSize || "large"}`}>
                <WeightMedium>
                    {this.props.children}
                </WeightMedium>
            </div>
        );
    }
}
