import React, { CSSProperties } from "react";

export const InlineFlex = (props: React.PropsWithChildren<{
    width?: CSSProperties["width"],
}>): JSX.Element => {
    return (
        <div style={{
            display: "inline-flex",
            width: props.width,
        }}>
            {props.children}
        </div>
    );
};
