import React from "react";
import { TContainerStateProps } from "../../state/TContainerStateProps";
import { CRMPageWrap } from "../../components/Simple/CRMPageWrap/CRMPageWrap";
import { CRMLegalMenuBarContainer } from "../../containers/CRMLegalMenuBarContainer/CRMLegalMenuBarContainer"
import { Padding } from "../../components/BuildingBlocks/Padding";
import { SpacingRow } from "../../components/BuildingBlocks/SpacingRow";
import { CRMSpacing } from "../../models/CRMSpacing";
import { CRMMarketingCampaignsBlock } from "../../components/Complex/CRMMarketingCampaignsBlock/CRMMarketingCampaignsBlock";
import { CRMBlock } from "../../components/CRM/CRMBlock/CRMBlock/CRMBlock";

const CRMMarketingCampaignsPage = (props: TContainerStateProps): JSX.Element =>
    <CRMPageWrap>
        {/* HEADER */}
        <CRMLegalMenuBarContainer {...props} />

        {/* BODY */}
        <Padding
            type="custom" 
            spacing={`${CRMSpacing.X_LARGE} ${CRMSpacing.X_LARGE} 0px`}
        >
            <SpacingRow
                spacing={CRMSpacing.X_LARGE}
                alignItems="flex-start"
            >
                {/* BLOCK - MARKETING CAMPAIGNS */}
                <CRMBlock
                    mode="fixed-option"
                    fixedOption={{
                        icon: "campaign",
                        title: "Marketing Campaigns"
                    }}
                    optionGroups={[]}
                >
                    <CRMMarketingCampaignsBlock
                        form={props.state.forms.marketing_campaigns_block}
                        onAddNewMarketingCampaign={(payload) => props.dispatch({
                            type: "LEGAL_CASE_MARKETING_CAMPAIGN_CREATE",
                            payload
                        })}
                        onArchive={(payload) => props.dispatch({
                            type: "LEGAL_CASE_MARKETING_CAMPAIGN_DELETE",
                            payload
                        })}
                        onChange={(payload) => props.dispatch({
                            type: "LEGAL_CASE_MARKETING_CAMPAIGN_CHANGE",
                            payload
                        })}
                        onSave={(payload) => props.dispatch({
                            type: "LEGAL_CASE_MARKETING_CAMPAIGN_SAVE",
                            payload
                        })}
                        onLoadMore={() => props.dispatch({
                            type: "LEGAL_CASE_MARKETING_CAMPAIGN_LOAD_MORE",
                            payload: null,
                        })}
                    />
                </CRMBlock>
            </SpacingRow>
        </Padding>
    </CRMPageWrap>;

export default CRMMarketingCampaignsPage;
