import React from "react";
import { ListingPropertyMarketing } from "../../../../domain/codecs/Listing";
import { CRMAutosaveIndicatorWrap } from "../../components/CRM/CRMAutosaveIndicatorWrap/CRMAutosaveIndicatorWrap";
import { CRMCodecEditForm } from "../../components/CRMCodecEditForm/CRMCodecEditForm";
import { CRMTitleSection } from "../../components/CRMTitleSection/CRMTitleSection";
import { CRMImageUploadGallery } from "../../components/CRMImageUploadGallery/CRMImageUploadGallery";
import { CRMTitleSubSection } from "../../components/CRMTitleSubSection/CRMTitleSubSection";
import { SpacingColumn } from "../../components/BuildingBlocks/SpacingColumn";
import { CRMSpacing } from "../../models/CRMSpacing";
import { TContainerStateProps } from "../../state/TContainerStateProps";

export const CRMListingMarketingFormContainer = (props: TContainerStateProps): JSX.Element => {

    return (
        <CRMAutosaveIndicatorWrap status={props.state.forms.listing_page.data.output.form.status}>
            <SpacingColumn spacing={CRMSpacing.LARGE}>
                <CRMTitleSection>
                    Marketing
                </CRMTitleSection>

                {/* GENERAL MARKETING FORMS */}
                <CRMCodecEditForm
                    codec={ListingPropertyMarketing}
                    model={props.state.forms.listing_page.data.output.form.edited}
                    validationErrors={props.state.forms.listing_page.data.output.form.validationErrors}
                    onChange={(edited) => props.dispatch({
                        type: "LISTING_PREPARATION_FORM_CHANGE",
                        payload: {
                            ...props.state.forms.listing_page.data.output.form,
                            edited: {
                                ...props.state.forms.listing_page.data.output.form.edited,
                                ...edited,
                            }
                        }
                    })}
                />
                {/* IMAGES */}
                <SpacingColumn spacing={CRMSpacing.MEDIUM}>
                    <CRMTitleSubSection>
                        Images
                    </CRMTitleSubSection>
                    <CRMImageUploadGallery
                        listingId={props.state.forms.listing_page.data.output.form.original.id}
                        images={props.state.forms.listing_page.data.output.read_only.images}
                        onUpdateOrder={() => alert("Image redording is not yet implemented.")}
                        onRemove={(file) => props.dispatch({
                            type: "LISTING_PAGE_REMOVE_PHOTO",
                            payload: file
                        })}
                        onUpload={(files) =>
                            props.dispatch({
                                type: "LISTING_PAGE_ADD_PHOTOS",
                                payload: files
                            })
                        }
                    />
                </SpacingColumn>
            </SpacingColumn>
        </CRMAutosaveIndicatorWrap>
    );
};
