import React, { useEffect } from "react";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { Background } from "../../BuildingBlocks/Background";
import { CRMBottomControlBar } from "../../CRMBottomControlBar/CRMBottomControlBar";
import { CRMButtonPrimary } from "../../CRMButtonPrimary/CRMButtonPrimary";
import { CRMFormButton } from "../../CRMFormButton/CRMFormButton";
import CRMTextAreaComponent from "../../CRMTextAreaComponent/CRMTextAreaComponent";
import { CRMBlockPopover } from "../../CRM/CRMBlock/CRMBlockPopover/CRMBlockPopover";
import { CRMSingleViewHeader } from "../../Simple/CRMSingleViewHeader/CRMSingleViewHeader";
import { TCaseCustomerSatisfactionNewNoteForm } from "../../../../../domain/codecs/form/CaseCustomerSatisfactionForm";
import { CRMInlineTextCalendar } from "../../CRMInlineTextCalendar/CRMInlineTextCalendar";
import { DateTime } from "luxon";
import { CRMColors } from "../../../models/CRMColors";
import { onChangeForm } from "../../../../../shared/src/codecs/types/form";

export const CRMGrievanceAddNote = (props: React.PropsWithChildren<{
    form: TCaseCustomerSatisfactionNewNoteForm;
    onChange: (form: TCaseCustomerSatisfactionNewNoteForm) => void;
    onSubmit: () => void;
    onClose: () => void;
}>): JSX.Element => {

    useEffect(
        () => {
            if (props.form.status === "success") {
                props.onClose();
            }
        },
        [props.form.status]
    );

    const onChange = onChangeForm(props.form, props.onChange);

    const getOccuredAtDate = (): Date => 
        props.form.edited.occurred_at ?
            DateTime.fromISO(props.form.edited.occurred_at).toJSDate()
            : DateTime.utc().toJSDate()
    ;

    return (
        <CRMBlockPopover>
            <div>
                {/* HEADER */}
                <CRMSingleViewHeader
                    title="Add Note"
                    edge="border"
                    onClick={props.onClose}
                >
                    <Background padding={`${CRMSpacing.SMALL} ${CRMSpacing.MEDIUM}`}>
                        <CRMInlineTextCalendar
                            popperPlacement="left-start"
                            date={getOccuredAtDate()}
                            label=""
                            dateSetColour={CRMColors.NEUTRAL_0}
                            onChange={(date) => onChange("occurred_at")((date as Date).toISOString())}
                        />
                    </Background>
                </CRMSingleViewHeader>

                {/* CONTENT */}
                <Background padding={CRMSpacing.LARGE}>
                    <CRMTextAreaComponent
                        value={props.form.edited.note}
                        autoFocus={true}
                        onChange={onChange("note")}
                    />
                </Background>

                {/* BOTTOM BAR */}
                <CRMBottomControlBar
                    justifyContent="flex-end"
                    edge="border"
                >
                    <Background padding={`${CRMSpacing.TINY} ${CRMSpacing.MEDIUM}`}>
                        <CRMFormButton
                            formStatus={props.form.status}
                            ButtonElement={CRMButtonPrimary}
                            label="Submit"
                            onClick={props.onSubmit}
                        />
                    </Background>
                </CRMBottomControlBar>
            </div>
        </CRMBlockPopover>
    );
};
