import React from "react";
interface IHeading2 {
    text: string;
}

class Heading2 extends React.Component<React.PropsWithChildren<IHeading2>> {
    public render (): JSX.Element {
        return (
            <h2 className="heading2">{this.props.text}</h2>
        );
    }
}

export default Heading2;
