import React from "react";
import * as TFormStatus from "./../../models/TFormStatus";
import { CRMButtonIcon } from "../CRMButtonIcon/CRMButtonIcon";
import { CRMFormButtonIcon } from "../CRMFormButtonIcon/CRMFormButtonIcon";
import { CRMColumnSpacer } from "../CRMColumnSpacer/CRMColumnSpacer";
import { CRMParagraph } from "../Simple/CRMParagraph/CRMParagraph";
import { TIcon } from "../CRMIcon/CRMIcon";

type TCRMFormButtonWithParagraph = {
    icon: TIcon;
    formStatus: TFormStatus.T;
    onClick: () => void;
    paragraph: string;
    transparent?: boolean;
    paragraphClickable?: boolean;
};

export class CRMFormButtonWithParagraph extends React.Component<React.PropsWithChildren<TCRMFormButtonWithParagraph>> {
    public render (): JSX.Element {
        return (
            <div className="crm-form-button-with-paragraph">
                <CRMFormButtonIcon
                    icon={this.props.icon}
                    ButtonElement={(props) => <CRMButtonIcon variant={this.props.transparent ? "quaternary" : "primary"} {...props} />}
                    onClick={this.props.onClick}
                    formStatus={this.props.formStatus}
                />
                <CRMColumnSpacer size="medium" />
                <div onClick={() => this.props.paragraphClickable && !TFormStatus.UsualFormStatusToDisabledState(this.props.formStatus) ? this.props.onClick() : undefined} className={`
                    crm-form-button-with-paragraph__paragraph-wrapper
                    crm-form-button-with-paragraph__paragraph-wrapper--${this.props.paragraphClickable ? "clickable" : "not-clickable"}
                    crm-form-button-with-paragraph__paragraph-wrapper--${this.props.paragraphClickable && TFormStatus.UsualFormStatusToDisabledState(this.props.formStatus) ? "clickable-disabled" : "not-clickable-disabled"}
                `}>
                    <CRMParagraph>{this.props.paragraph}</CRMParagraph>
                </div>
            </div>
        );
    }
}
