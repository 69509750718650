
import React from "react";
import Heading5 from "../components/OldFront/Simple/Heading5Component/Heading5";
import * as Party2 from "../../../domain/models/Party2";
import * as FirstPartyFetchResponse from "../../../domain/models/FirstPartyFetchResponse";
import { array } from "fp-ts/lib";
import Button from "../components/OldFront/Simple/ButtonComponent/Button";
import { AnchorLinkDispatch } from "../containers/AnchorLinkDispatch";
import { InputText } from "../components/OldFront/Simple/Text/InputText";
import CRMListingListComponent from "../components/CRMListingListComponent/CRMListingListComponent";
import { TContainerStateProps } from "../state/TContainerStateProps";
import { pipe } from "fp-ts/lib/function";
import { CRMHomesMenuBarContainer } from "../containers/CRMHomesMenuBarContainer/CRMHomesMenuBarContainer";
import { createChangeRouteAction } from "../state/router/createChangeRouteAction";
import { getUrlStringForRoute } from "../state/router/getUrlStringsForRoute";
import { paths } from "../state/router/routerPaths";
import { CRMKPIs } from "../components/Simple/CRMKPIs/CRMKPIs";

class CRMSearchPage extends React.Component<React.PropsWithChildren<TContainerStateProps>> {
    public render(): JSX.Element {
        return (
            <>
                <div className="crmSearch__page">
                    <CRMHomesMenuBarContainer {...this.props} />

                    <div className="crmSearch__sectionsContainer">
                        <div className="crmSearch__search">
                            <InputText
                                placeholder="Search for a buyer, seller or property..."
                                value={this.props.state.activeData.crm.search.searchInput}
                                onChange={(searchInput) => this.props.dispatch({
                                    type: "CRM_SEARCH_INPUT_CHANGED",
                                    payload: searchInput,
                                })}
                                onPressEnterKey={() => undefined}
                                autoFocus={true}
                            />
                        </div>

                        {/* Listings */}
                        <div className="crmSearch__section">
                            <div className="crmSearch__heading">
                                <Heading5
                                    text={`Listings (${this.props.state.activeData.crm.search.listingsSearchResponse.response.meta.available_count
                                        > this.props.state.activeData.crm.search.listingsSearchResponse.response.meta.returned_count
                                        ? `${this.props.state.activeData.crm.search.listingsSearchResponse.response.meta.returned_count} / ${this.props.state.activeData.crm.search.listingsSearchResponse.response.meta.available_count}`
                                        : this.props.state.activeData.crm.search.listingsSearchResponse.response.meta.available_count.toString()} results)`}
                                />
                            </div>
                            <CRMListingListComponent
                                listings={this.props.state.activeData.crm.search.listingsSearchResponse.response.data}
                                showLoadMoreResults={this.props.state.activeData.crm.search.listingsSearchResponse.response.meta.available_count > this.props.state.activeData.crm.search.listingsSearchResponse.response.meta.returned_count}
                                onListingClick={(listing, e) => {
                                    e.preventDefault();
                                    this.props.dispatch(
                                        createChangeRouteAction(
                                            "VIEW_CRM_LISTING",
                                            {
                                                listingId: listing.id,
                                            },
                                            {}
                                        )
                                    );
                                }}
                                onLoadMoreResultsClick={() => this.props.dispatch({type: "CRM_SEARCH_LOAD_MORE_LISTINGS"})}
                                urlCallback={(listing) =>
                                    getUrlStringForRoute(
                                        paths,
                                        "VIEW_CRM_LISTING",
                                        {
                                            listingId: listing.id,
                                        },
                                        {},
                                    )
                                }
                            />
                        </div>

                        {/* Parties */}
                        <div className="crmSearch__section">
                            <div className="crmSearch__heading">
                                <Heading5
                                    text={`Parties (${this.props.state.activeData.crm.search.partiesSearchResponse.response.meta.available_count
                                        > this.props.state.activeData.crm.search.partiesSearchResponse.response.meta.returned_count
                                        ? `${this.props.state.activeData.crm.search.partiesSearchResponse.response.meta.returned_count} / ${this.props.state.activeData.crm.search.partiesSearchResponse.response.meta.available_count}`
                                        : this.props.state.activeData.crm.search.partiesSearchResponse.response.meta.available_count.toString()}) results`
                                    }
                                />
                            </div>
                            <div className="crmSearch__results">
                                {array.mapWithIndex<Party2.T, JSX.Element>((i, party) => (
                                    <div key={i}>
                                        <AnchorLinkDispatch
                                            dispatch={this.props.dispatch}
                                            action={createChangeRouteAction(
                                                "VIEW_CRM_PARTY",
                                                {
                                                    partyId: party.id,
                                                },
                                                {}
                                            )}
                                            text={`${
                                                pipe(
                                                    party.users,
                                                    array.map((u) => `${u.first_name} ${u.last_name}`)
                                                ).join(", ")
                                            }`}
                                        />
                                    </div>
                                ))(this.props.state.activeData.crm.search.partiesSearchResponse.response.data)}
                            </div>
                            {this.props.state.activeData.crm.search.partiesSearchResponse.response.meta.available_count > this.props.state.activeData.crm.search.partiesSearchResponse.response.meta.returned_count ? (
                                <div className="crmSearch__moreResults">
                                    <Button
                                        label="Load more results..."
                                        type="frog"
                                        fullWidth={true}
                                        disabled={this.props.state.activeData.crm.search.partiesSearchResponse.tag === FirstPartyFetchResponse.constants.PENDING}
                                        onClick={() => this.props.dispatch({type: "CRM_SEARCH_LOAD_MORE_PARTIES"})}
                                    />
                                </div>
                            ) : ""}
                        </div>
                    </div>
                </div>

                {/* WEEKLY KPIS */}
                <CRMKPIs
                    isOpen={this.props.state.forms.weekly_kpi_is_showing}
                    onClose={() => this.props.dispatch({
                        type: "CRM_CLOSE_WEEKLY_KPIS",
                    })}
                />
            </>
        );
    }
}

export default CRMSearchPage;
