import { array } from "fp-ts";
import { pipe } from "fp-ts/lib/function";
import React from "react";
import { CompanyType, CompanyType_displayString } from "../../../../../domain/codecs/Cases";
import { TClientCompanyPartnershipForm } from "../../../../../domain/codecs/form/ClientCompanyPartnershipForm";
import { onChangeForm } from "../../../../../shared/src/codecs/types/form";
import { CRMDropdownComponent } from "../../CRMDropdownComponent/CRMDropdownComponent";
import CRMInputGeneralComponent from "../../CRMInputs/CRMInputGeneralComponent/CRMInputGeneralComponent";
import CRMInputLabelAndErrorWrapComponent from "../../CRMInputLabelAndErrorWrapComponent/CRMInputLabelAndErrorWrapComponent";

export const CRMCompanyPartnerCompanyInformationForm = (props: React.PropsWithChildren<{
    form: TClientCompanyPartnershipForm;
    onChange: (form: TClientCompanyPartnershipForm) => void;
}>): JSX.Element => {
    const onChange = onChangeForm(props.form, props.onChange);

    return <>
        {/* NAME */}
        <CRMInputLabelAndErrorWrapComponent label="Name">
            <CRMInputGeneralComponent
                inputType="text"
                value={props.form.edited.name}
                onChange={onChange("name")}
                displayError={false}
            />
        </CRMInputLabelAndErrorWrapComponent>

        {/* NUMBER */}
        <CRMInputLabelAndErrorWrapComponent label="Number">
            <CRMInputGeneralComponent
                inputType="text"
                value={props.form.edited.number}
                onChange={onChange("number")}
                displayError={false}
            />
        </CRMInputLabelAndErrorWrapComponent>

        {/* TYPE */}
        <CRMInputLabelAndErrorWrapComponent label="Type">
            <CRMDropdownComponent
                options={pipe(
                    CompanyType.values,
                    array.map((value) => ({
                        value,
                        label: CompanyType_displayString(value),
                    }))
                )}
                value={props.form.edited.type}
                onChange={onChange("type")}
                displayError={false}
            />
        </CRMInputLabelAndErrorWrapComponent>
    </>;
}
