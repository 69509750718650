import React from "react";
import { TAdhocTaskForms, TKeyDateTaskForms, TCasesDayViewForm } from "../../../../../../domain/codecs/form/CasesForm";
import { CRMSpacing } from "../../../../models/CRMSpacing";
import { SpacingColumn } from "../../../BuildingBlocks/SpacingColumn";
import { CRMColors } from "../../../../models/CRMColors";
import { AnimationPopout } from "../../../BuildingBlocks/AnimationPopout";
import { Background } from "../../../BuildingBlocks/Background";
import { SpacingRow } from "../../../BuildingBlocks/SpacingRow";
import { CRMThinColoredButton } from "../../../Simple/CRMThinColoredButton/CRMThinColoredButton";
import { WeightSemiBold } from "../../../WeightSemiBold/WeightSemiBold";
import { CRMEmptyPlaceholder } from "../../../Simple/CRMEmptyPlaceholder/CRMEmptyPlaceholder";
import { CRMFontSizes } from "../../../../models/CRMFontSizes";
import { CRMDayTablePlaceholderRows } from "../CRMDayTablePlaceholderRows/CRMDayTablePlaceholderRows";
import { CRMCasesTaskRowChangeable } from "../CRMCasesTaskRowChangeable/CRMCasesTaskRowChangeable";
import { TPlainUser } from "../../../../../../domain/codecs/User";
import { CRMCasesTaskRow } from "../CRMCasesTaskRow/CRMCasesTaskRow";
import { WeightBold } from "../../../WeightBold/WeightBold";
import { CRMInfoBox } from "../../../Simple/CRMInfoBox/CRMInfoBox";
import { CRMParagraph } from "../../../Simple/CRMParagraph/CRMParagraph";
import { InlineFlex } from "../../../BuildingBlocks/InlineFlex";
import { CRMCasesKeyDatesRail } from "../CRMCasesKeyDatesRail/CRMCasesKeyDatesRail";
import { mockTCasesKeyDates__ExampleRange } from "../../../../functions/mock/CodecMocks_Cases";

export const CRMDayTable = (props: {
    form: TCasesDayViewForm,
    assignableUsers: Array<TPlainUser>,
    onLoadMore: () => void,
    onChangeTask: (form: TAdhocTaskForms) => void,
    onArchiveTask: (form: TAdhocTaskForms) => void,
}): JSX.Element => {

    const HasKeyDates = props.form.children.key_dates.length > 0;

    const isShowingLoadMore =
        props.form.children.available_count > props.form.children.task_forms.length;

    const isLoading =
        props.form.status === "submitting";

    const isNoRows =
        props.form.children.task_forms.length === 0
        && !isLoading;

    const overdueCount =
        props.form.children.overdue_count;

    const isShowingOverdueNotice =
        props.form.edited.filters.date.type !== "overdue"
        && !!overdueCount;
    
    return <>
        {!isLoading &&
            <SpacingColumn spacing={CRMSpacing.LARGE}>
                {/* KEY DATES */}
                {HasKeyDates &&
                    <CRMCasesKeyDatesRail
                        paddingHorizontal={40}
                        dates={props.form.children.key_dates} 
                    />
                }

                {/* TABLE */}
                <Background padding={`0px ${CRMSpacing.X_LARGE}`}>
                    <SpacingColumn spacing={CRMSpacing.MEDIUM}>
                        {/* OVERDUE BANNER */}
                        {isShowingOverdueNotice &&
                            <InlineFlex width="max-content">
                                <CRMInfoBox 
                                    color={CRMColors.HIGHLIGHTS_INSTRUCTIONAL_BRICK_12}
                                    border={`1px solid ${CRMColors.HIGHLIGHTS_INSTRUCTIONAL_BRICK_8}`}
                                >
                                    <CRMParagraph>
                                        {overdueCount === 1
                                            ? <>There is <WeightBold>{overdueCount} overdue task</WeightBold> that should have it's date changed.</>
                                            : <>There are <WeightBold>{overdueCount} overdue tasks</WeightBold> that should have their dates changed.</>
                                        }
                                    </CRMParagraph>
                                </CRMInfoBox>
                            </InlineFlex>
                        }

                        {/* TASK ROWS */}
                        <SpacingColumn>
                            {props.form.children.task_forms.map((form) =>
                                form.children.category === "task" ? 
                                    <CRMCasesTaskRowChangeable
                                        key={`${form.original.id}_${form.original.tag}`}
                                        form={form as TAdhocTaskForms}
                                        assignableUsers={props.assignableUsers}
                                        onChange={props.onChangeTask}
                                        onArchive={props.onArchiveTask}
                                    />
                                    : <CRMCasesTaskRow
                                        key={`${form.original.id}_${form.original.tag}`}
                                        form={form as TKeyDateTaskForms}
                                    />
                            )}
                        </SpacingColumn>

                        {/* LOAD MORE */}
                        {isShowingLoadMore &&
                            <AnimationPopout>
                                <Background padding={`0px 0px ${CRMSpacing.LARGE} 0px`}>
                                    <SpacingRow justifyContent="center">
                                        <CRMThinColoredButton
                                            backgroundColor={CRMColors.NEUTRAL_2}
                                            fontColor={CRMColors.NEUTRAL_12}
                                            label={<WeightSemiBold>See more</WeightSemiBold>}
                                            onClick={props.onLoadMore}
                                            icon="down-arrow"
                                        />
                                    </SpacingRow>
                                </Background>
                            </AnimationPopout>
                        }
                    </SpacingColumn>
                </Background>
            </SpacingColumn>
        }

        {/* PLACEHOLDER */}
        {isLoading &&
            <CRMDayTablePlaceholderRows />
        }

        {/* NO ROWS */}
        {isNoRows &&
            <Background padding="50px 0px 0px 0px">
                <CRMEmptyPlaceholder
                    iconSize="100px"
                    fontSize={CRMFontSizes.X_LARGE}
                >
                    Nothing to see here...
                </CRMEmptyPlaceholder>
            </Background>
        }
    </>;
};