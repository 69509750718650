import * as t from "io-ts";
import { all } from "../../shared/src/validation/compose/all";
import { TValidationFunction } from "../../shared/src/validation/Function";
import * as ListingViewing1 from "./ListingViewing1";
import * as ListingViewing2 from "./ListingViewing2";

export const codec = t.intersection([
    ListingViewing1.codec,
    ListingViewing2.codec,
]);

export type T = t.TypeOf<typeof codec>;

export const validator: TValidationFunction =
    all(
        ListingViewing1.validator,
        ListingViewing2.validator,
    );
