import React from "react";
import { TQuoteRate1 } from "../../../../../domain/codecs/QuoteRate";
import { TQuoteRateFeePropertyValue, TQuoteRateFeeTransferValue } from "../../../../../domain/codecs/QuoteRateFee";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { SpacingColumn } from "../../BuildingBlocks/SpacingColumn";
import { CRMTitleSubSection } from "../../CRMTitleSubSection/CRMTitleSubSection";
import { CRMSingleLineLabelAndValue } from "../../Simple/CRMSingleLineLabelAndValue/CRMSingleLineLabelAndValue";
import { CRMQuoteRatesSingleViewFeesPropertyValue } from "./CRMQuoteRatesSingleViewFeesPropertyValue";
import { CRMQuoteRatesSingleViewFeesTransferValue } from "./CRMQuoteRatesSingleViewFeesTransferValue";

export const CRMQuoteRatesSingleViewFees = (props: React.PropsWithChildren<{
    quoteRate:
        TQuoteRate1
        & {
            transfer_value_fees: Array<TQuoteRateFeeTransferValue>,
            property_value_fees: Array<TQuoteRateFeePropertyValue>,
        },
}>): JSX.Element => {
    const isTransferValueFees = props.quoteRate.transfer_value_fees.length > 0;

    const isPropertyValueFees = !isTransferValueFees;

    return <SpacingColumn spacing={CRMSpacing.MEDIUM}>
        <CRMTitleSubSection>
            Fees based on the {isTransferValueFees ? "amount being transferred" : "value of the property"}
        </CRMTitleSubSection>

        {/* TRANSFER VALUE FEES */}
        {isTransferValueFees &&
            <CRMQuoteRatesSingleViewFeesTransferValue
                transfer_value_fees={props.quoteRate.transfer_value_fees}
                has_vat={props.quoteRate.has_vat}
            />
        }

        {/* PROPERTY VALUE FEES */}
        {isPropertyValueFees &&
            <CRMQuoteRatesSingleViewFeesPropertyValue
                property_value_fees={props.quoteRate.property_value_fees}
                has_vat={props.quoteRate.has_vat}
            />
        }
    </SpacingColumn>;
}
