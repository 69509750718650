import React, { useState } from "react";
import { TRoadblockForm } from "../../../../../domain/codecs/form/RoadblockForm";
import { CRMRoadblockMessage } from "../CRMRoadblockMessage/CRMRoadblockMessage";
import { pipe } from "fp-ts/lib/pipeable";
import { SpacingColumn } from "../../BuildingBlocks/SpacingColumn";
import { CRMRoadblockActionDateSeparator } from "../CRMRoadblockActionDateSeparator/CRMRoadblockActionDateSeparator";
import { Background } from "../../BuildingBlocks/Background";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { CRMRoadblockMessagePlaceholder } from "../CRMRoadblockMessagePlaceholder/CRMRoadblockMessagePlaceholder";
import { CRMColors } from "../../../models/CRMColors";
import { groupActionsIntoMinuteClusters, groupMinuteClustersIntoDayClusters, sortDayClustersByRecency } from "./CRMRoadblockMessagesGroupingLogic";
import { CRMErrorPlaceholder } from "../../Simple/CRMErrorPlaceholder/CRMErrorPlaceholder";

type TRoadblockMessagesProps = {
    form: TRoadblockForm;
    onClose: () => void;
};

export const CRMRoadblockMessages = (props: React.PropsWithChildren<TRoadblockMessagesProps>): JSX.Element => {

    const IsLoading = props.form.status === "loading";
    const HasErrored = props.form.status === "failure" || props.form.status === "validationError";
    const Participants = props.form.children.participants;
    const Messages = pipe(
        props.form.children.actions,
        groupActionsIntoMinuteClusters,
        groupMinuteClustersIntoDayClusters,
        sortDayClustersByRecency
    );

    return (
        <>
            {/* IF LOADING: PLACEHOLDER */}
            {IsLoading &&
                <div className={`crm-roadblock-messages crm-roadblock-messages--placeholder`}>
                    <SpacingColumn spacing={CRMSpacing.LARGE}>
                        <CRMRoadblockMessagePlaceholder />
                        <CRMRoadblockMessagePlaceholder />
                        <CRMRoadblockMessagePlaceholder />
                        <CRMRoadblockMessagePlaceholder />
                    </SpacingColumn>
                </div>
            }

            {/* IF ERRORED: PLACEHOLDER */}
            {HasErrored &&
                <div className="crm-roadblock-view-checklist__body">
                    <CRMErrorPlaceholder
                        title="Something is not right."
                        buttonText="Back to case roadblocks"
                        onClick={props.onClose}
                    >
                        The system was not able to get the roadblock data. <br/>
                        If this happens again let the development team know.
                    </CRMErrorPlaceholder>
                </div>
            }

            {/* IF LOADED: MESSAGES */}
            {!IsLoading && !HasErrored &&
                <div className={`crm-roadblock-messages`}>
                    <SpacingColumn>
                        {Messages.map((dayCluster, dayIndex) => (
                            <Background
                                key={dayIndex}
                                padding={`${CRMSpacing.X_LARGE} 0px ${CRMSpacing.LARGE}`}
                                color={CRMColors.NEUTRAL_PAPER}
                            >
                                
                                <CRMRoadblockActionDateSeparator 
                                    top="70px"
                                    date={dayCluster.date} 
                                />
                                
                                <Background padding={`${CRMSpacing.X_LARGE} ${CRMSpacing.LARGE} 0px`}>
                                    <SpacingColumn spacing={CRMSpacing.LARGE}>
                                        {dayCluster.minuteClusters.map((minuteCluster, messageIndex) => (
                                            <CRMRoadblockMessage
                                                key={messageIndex}
                                                participants={Participants}
                                                actions={minuteCluster.actions}
                                            />
                                        ))}
                                    </SpacingColumn>
                                </Background>
                            </Background>
                        ))}
                    </SpacingColumn>
                </div>
            }
        </>
    );
};

