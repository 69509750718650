import React from "react";
import { TRoadblockForm } from "../../../../../domain/codecs/form/RoadblockForm";
import { formatDateToDayOfWeekAndFull } from "../../../../../shared/src/utilsByDomain/dateTime";
import { CRMColors } from "../../../models/CRMColors";
import { CRMFontSizes } from "../../../models/CRMFontSizes";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { Background } from "../../BuildingBlocks/Background";
import { Padding } from "../../BuildingBlocks/Padding";
import { SpacingColumn } from "../../BuildingBlocks/SpacingColumn";
import { TextColor } from "../../BuildingBlocks/TextColor";
import { CRMParagraph } from "../../Simple/CRMParagraph/CRMParagraph";
import { WeightBold } from "../../WeightBold/WeightBold";
import { WeightMedium } from "../../WeightMedium/WeightMedium";
import { CRMSystemName } from "../../../models/CRMSystem";

export const CRMRoadblockStatusLabelPopoverResolved = (props: {
    form: TRoadblockForm;
    noBoxShadow?: boolean;
}): JSX.Element => {
    const resolvedOn = formatDateToDayOfWeekAndFull(props.form.children.details.resolved_on || "");

    const resolvedByUser = props.form.children.details.resolved_by_user || CRMSystemName;

    return <Background
        color={CRMColors.HIGHLIGHTS_POSITIVE_GREEN_0}
        borderRadius="8px"  
        boxShadowDefinition={
            props.noBoxShadow ? '' : `0px 2px 5px 0px rgba(0, 0, 0, 0.14)`
        }
    >
        {/* TOP SECTION */}
        <Padding spacing={CRMSpacing.MEDIUM}>
            <SpacingColumn spacing={CRMSpacing.TINY}>
                {/* TITLE */}
                <CRMParagraph fontSize={CRMFontSizes.SMALL_PLUS}>
                    <TextColor color={CRMColors.NEUTRAL_PAPER}>
                        <WeightBold>
                            Roadblock resolved.
                        </WeightBold>
                    </TextColor>
                </CRMParagraph>

                <Padding spacing={`0px 0px 0px ${CRMSpacing.TINY}`}>
                    <SpacingColumn spacing={CRMSpacing.TINY}>
                        {/* BY */}
                        <CRMParagraph>
                            <TextColor color={CRMColors.NEUTRAL_PAPER}>
                            <WeightBold>by:</WeightBold> <WeightMedium>{resolvedByUser}</WeightMedium>
                            </TextColor>
                        </CRMParagraph>

                        {/* ON */}
                        <CRMParagraph>
                            <TextColor color={CRMColors.NEUTRAL_PAPER}>
                                <WeightBold>on:</WeightBold> <WeightMedium>{resolvedOn}</WeightMedium>
                            </TextColor>
                        </CRMParagraph>
                    </SpacingColumn>
                </Padding>
            </SpacingColumn>
        </Padding>
    </Background>;
};