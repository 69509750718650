import React from "react";
import { CRMFormButtonIcon, TCRMFormButtonIcon } from "../CRMFormButtonIcon/CRMFormButtonIcon";
import { CRMColumns } from "../CRMColumns/CRMColumns";
import { CRMParagraph } from "../Simple/CRMParagraph/CRMParagraph";

export class CRMFormButtonIconWithLabel extends React.Component<{
    label: string;
} & TCRMFormButtonIcon> {
    public render (): JSX.Element {
        return (
            <CRMColumns
                padding="medium"
                alignItems="center"
                wrapOnMobile={false}
                columns={[
                    {
                        flex: 0,
                        content: <CRMFormButtonIcon
                            icon={this.props.icon}
                            ButtonElement={this.props.ButtonElement}
                            onClick={this.props.onClick}
                            formStatus={this.props.formStatus}
                        />,
                    },
                    {
                        flex: 1,
                        content: <CRMParagraph>{this.props.label}</CRMParagraph>,
                    },
                ]}
            />
        );
    }
}
