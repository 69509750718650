import React, { useState } from "react";
import { IOption } from "../../../../hooks/UseDropdown";
import { FrontColors } from "../../../../models/FrontColors";
import { FrontSpacing } from "../../../../models/FrontSpacing";
import { MaxWidth } from "../../../BuildingBlocks/MaxWidth";
import { Padding } from "../../../BuildingBlocks/Padding";
import { SpacingColumn } from "../../../BuildingBlocks/SpacingColumn";
import { FrontCondensedQuestionWrap } from "../FrontCondensedQuestionWrap/FrontCondensedQuestionWrap";
import { FrontInfoBubble } from "../FrontInfoBubble/FrontInfoBubble";
import FrontInputLabelAndErrorWrapComponent from "../FrontInputLabelAndErrorWrapComponent/FrontInputLabelAndErrorWrapComponent";
import { FrontRadioList } from "../FrontRadioList/FrontRadioList";

type TRadioQuestionsProps<A> = {
    value: null | A;
    label: string;
    closedLabel?: string;
    isEditable?: boolean;
    displayValidationErrorsIfPresent?: boolean;
    options: Array<IOption<A>>;
    onChange: (value: A) => void;
    guidance?: JSX.Element;
};

export const FrontCondensedRadioQuestions = <A extends string | number | boolean>(props: React.PropsWithChildren<TRadioQuestionsProps<A>>): JSX.Element => {

    const [isOpen, setIsOpen] = useState(props.value === null);

    const onChange = (value: A) => {
        setIsOpen(false);
        props.onChange(value);
    }

    const getCondensedSummary = (): JSX.Element | string => {
        const selectedOption = getSelectedOption();
        if (selectedOption) {
            return selectedOption.label;
        }
        return "";
    }

    const getSelectedOption = () => props.options.find((option) => option.value === props.value);

    return (
        <FrontCondensedQuestionWrap
            isOpen={isOpen}
            isEditable={props.isEditable}
            setIsOpen={setIsOpen}
            label={props.closedLabel || props.label}
            summary={getCondensedSummary()}
        >
            {/* Padding is here so that the label texts are perfectly aligned with each otehr */}
            <Padding type="top" spacing="2px">
                <FrontInputLabelAndErrorWrapComponent
                    label={props.label}
                    errorMessage="We need to know this"
                    displayError={props.value === null && props.displayValidationErrorsIfPresent ? true : false}
                >
                    <div style={{marginLeft: FrontSpacing.SMALL_3}}>
                        <SpacingColumn spacing={FrontSpacing.MEDIUM_1}>
                            {props.guidance && <MaxWidth width="500px">
                                <FrontInfoBubble color={FrontColors.PRIMARY_22}>
                                    {props.guidance}
                                </FrontInfoBubble>
                            </MaxWidth>}

                            <FrontRadioList
                                value={props.value}
                                options={props.options}
                                onChange={onChange}
                            />
                        </SpacingColumn>
                    </div>
                </FrontInputLabelAndErrorWrapComponent>
            </Padding>
        </FrontCondensedQuestionWrap>
    );
};
