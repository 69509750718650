import React from "react";
import { CRMTitleSection } from "../../components/CRMTitleSection/CRMTitleSection";
import { CRMSpacer } from "../../components/CRMSpacer/CRMSpacer";
import { CRMButtonIcon } from "../../components/CRMButtonIcon/CRMButtonIcon";
import { CRMEnquiriesUpcomingViewingsComponent } from "../../components/CRMEnquiriesUpcomingViewingsComponent/CRMEnquiriesUpcomingViewingsComponent";
import { array } from "fp-ts";
import { CRMTitleSubSection } from "../../components/CRMTitleSubSection/CRMTitleSubSection";
import { CRMDropdownComponent } from "../../components/CRMDropdownComponent/CRMDropdownComponent";
import { enumToIDropdownOptions } from "../../functions/enumToIDropdownOptions";
import CRMInputLabelAndErrorWrapComponent from "../../components/CRMInputLabelAndErrorWrapComponent/CRMInputLabelAndErrorWrapComponent";
import CRMViewingPreferredDateListComponent from "../../components/CRMViewingPreferredDateListComponent/CRMViewingPreferredDateListComponent";
import { CRMFormButtonIconWithLabel } from "../../components/CRMFormButtonIconWithLabel/CRMFormButtonIconWithLabel";
import { CRMInputCalendarComponent } from "../../components/CRMInputCalendarComponent/CRMInputCalendarComponent";
import { CRMSpacingRow } from "../../components/CRMSpacingRow/CRMSpacingRow";
import { CRMButtonTertiary } from "../../components/CRMButtonTertiary/CRMButtonTertiary";
import CRMInputGeneralComponent from "../../components/CRMInputs/CRMInputGeneralComponent/CRMInputGeneralComponent";
import CRMTextAreaComponent from "../../components/CRMTextAreaComponent/CRMTextAreaComponent";
import * as ViewingType from "../../../../domain/models/ViewingType";
import * as ViewingTypeDisplayName from "../../../../domain/models/ViewingTypeDisplayName";
import * as ViewingStatus1 from "../../../../domain/models/ViewingStatus1";
import * as JsonError1 from "../../../../domain/models/JsonError1";
import { CRMNoticeBoxViewingArrangements } from "../../components/CRMNoticeBoxViewingArrangements/CRMNoticeBoxViewingArrangements";
import { TContainerStateProps } from "../../state/TContainerStateProps";
import { pipe } from "fp-ts/lib/function";
import { CRMDeferButtonComponent } from "../../components/CRMDeferButtonComponent/CRMDeferButtonComponent";
import { CRMAutosaveIndicatorWrap } from "../../components/CRM/CRMAutosaveIndicatorWrap/CRMAutosaveIndicatorWrap";
import * as TFormStatus from "../../models/TFormStatus";
import { CRMFormButton } from "../../components/CRMFormButton/CRMFormButton";
import { CRMButtonQuaternary } from "../../components/CRMButtonQuaternary/CRMButtonQuaternary";
import { CRMInputCheckboxComponent } from "../../components/CRMInputCheckboxComponent/CRMInputCheckboxComponent";
class CRMEnquiryViewingContainer extends React.Component<React.PropsWithChildren<TContainerStateProps>> {
    public render(): JSX.Element {
        return (
            <CRMAutosaveIndicatorWrap status={
                pipe(
                    TFormStatus.reduceToHighestPriority([
                        ...pipe(
                            this.props.state.activeData.crm.enquiry.viewingForms.forms,
                            array.map((form) => form.status),
                        ),
                        pipe(
                            this.props.state.forms.listing_enquiry_page.children.enquiry.status,
                            (status) => status === "unauthorised" || status === "twoFactorRequired" || status === "notFound" ? "failure" : status,
                        ),
                    ]),
                    (status) => status === "hidden" ? "untouched"
                        : status,
                )
            }>
                <CRMTitleSection>Viewing</CRMTitleSection>
                <CRMSpacer size="large" />

                {/* Defer book a viewing job component */}
                <CRMInputLabelAndErrorWrapComponent label="Defer all viewing jobs">
                    <CRMDeferButtonComponent
                        value={this.props.state.forms.listing_enquiry_page.children.enquiry.edited.viewings_deferred}
                        onChange={(value) => this.props.dispatch({
                            type: "CRM_ENQUIRY_INPUT_CHANGE",
                            payload: {
                                ...this.props.state.forms.listing_enquiry_page.children.enquiry,
                                edited: {
                                    ...this.props.state.forms.listing_enquiry_page.children.enquiry.edited,
                                    viewings_deferred: value,
                                }
                            }
                        })}
                        displayError={false}
                    />
                    <CRMSpacer size="medium" />
                </CRMInputLabelAndErrorWrapComponent>

                <CRMEnquiriesUpcomingViewingsComponent
                    viewings={ pipe(
                        this.props.state.activeData.crm.enquiry.otherViewingsForListing.forms,
                        array.map((form) => form.view),
                    )}
                />

                <CRMSpacer size="medium" />
                <CRMSpacingRow justifyContent="space-between" alignItems="center">
                    <CRMFormButtonIconWithLabel
                        label="Add viewing"
                        icon="calendar-add"
                        ButtonElement={(props) => <CRMButtonIcon variant="primary" {...props} />}
                        onClick={() => this.props.dispatch({
                            type: "CRM_ENQUIRY_NEW_VIEWING_SUBMIT",
                        })}
                        formStatus={this.props.state.activeData.crm.enquiry.createViewingForm.status}
                    />
                    <CRMInputCheckboxComponent
                        label="Wants to view?"
                        checked={this.props.state.forms.listing_enquiry_page.children.enquiry.edited.wants_to_view}
                        onClick={() => this.props.dispatch({
                            type: "CRM_ENQUIRY_INPUT_CHANGE",
                            payload: {
                                ...this.props.state.forms.listing_enquiry_page.children.enquiry,
                                edited: {
                                    ...this.props.state.forms.listing_enquiry_page.children.enquiry.edited,
                                    wants_to_view: !this.props.state.forms.listing_enquiry_page.children.enquiry.edited.wants_to_view,
                                }
                            }
                        })}
                    />
                </CRMSpacingRow>
                { pipe(
                    this.props.state.activeData.crm.enquiry.viewingForms.forms,
                    array.mapWithIndex((i, viewing) =>
                        <div key={i}>
                            <CRMSpacer size="medium" />
                            <CRMTitleSubSection>{`Viewing #${(this.props.state.activeData.crm.enquiry.viewingForms.forms.length - i).toString()}`}</CRMTitleSubSection>
                            <CRMSpacer size="medium" />
                            <CRMInputLabelAndErrorWrapComponent
                                displayError={false}
                                errorMessage=""
                                label="Status"
                            >
                                <CRMDropdownComponent
                                    options={enumToIDropdownOptions(
                                        ViewingStatus1.values,
                                        ViewingStatus1.toDisplayName,
                                    )}
                                    value={viewing.edit.status}
                                    onChange={(value) => this.props.dispatch({
                                        type: "CRM_ENQUIRY_VIEWING_INPUT_CHANGED",
                                        payload: {
                                            key: "status",
                                            value,
                                            resourceId: viewing.view.id,
                                        },
                                    })}
                                    displayError={false}
                                />
                            </CRMInputLabelAndErrorWrapComponent>

                            {/* Display type & preferred times */}
                            {(viewing.edit.status === "pending" || viewing.edit.status === "confirmed") && <>
                                <CRMSpacer size="medium" />
                                <CRMInputLabelAndErrorWrapComponent
                                    displayError={false}
                                    errorMessage=""
                                    label="Type"
                                >
                                    <CRMDropdownComponent
                                        options={enumToIDropdownOptions(
                                            ViewingType.values,
                                            ViewingTypeDisplayName.fromViewingType,
                                        )}
                                        value={viewing.edit.viewing_type}
                                        onChange={(value) => this.props.dispatch({
                                            type: "CRM_ENQUIRY_VIEWING_INPUT_CHANGED",
                                            payload: {
                                                key: "viewing_type",
                                                value,
                                                resourceId: viewing.view.id,
                                            },
                                        })}
                                        displayError={false}
                                    />
                                </CRMInputLabelAndErrorWrapComponent>
                                <CRMSpacer size="medium" />
                                {viewing.edit.status === "pending" && <CRMNoticeBoxViewingArrangements
                                    listing={this.props.state.activeData.crm.enquiry.listingForm.view}
                                />}
                                <CRMSpacer size="medium" />
                                <CRMViewingPreferredDateListComponent
                                    values={viewing.edit.requested_times}
                                    displayError={JsonError1.doTargetKeysHaveInnerErrors(["requested_times"], viewing.validationErrors)}
                                    onChange={(value) => this.props.dispatch({
                                        type: "CRM_ENQUIRY_VIEWING_INPUT_CHANGED",
                                        payload: {
                                            key: "requested_times",
                                            value,
                                            resourceId: viewing.view.id,
                                        },
                                    })}
                                    onPressEnterKey={() => undefined}
                                />
                            </>}

                            {/* Display confirmed time & agent name */}
                            {viewing.edit.status === "confirmed" && <>
                                <CRMSpacer size="medium" />
                                <CRMInputLabelAndErrorWrapComponent
                                    label="Confirmed date & time"
                                    displayError={JsonError1.doTargetKeysHaveInnerErrors(["confirmed_time"], viewing.validationErrors)}
                                    errorMessage="You must set a confirmed time"
                                >
                                    <CRMInputCalendarComponent
                                        dateType="datetime-local"
                                        value={viewing.edit.confirmed_time || ""}
                                        displayError={JsonError1.doTargetKeysHaveInnerErrors(["confirmed_time"], viewing.validationErrors)}
                                        onChange={(value) => this.props.dispatch({
                                            type: "CRM_ENQUIRY_VIEWING_INPUT_CHANGED",
                                            payload: {
                                                key: "confirmed_time",
                                                value: value || null,
                                                resourceId: viewing.view.id,
                                            },
                                        })}
                                        onPressEnterKey={() => undefined}
                                    />
                                </CRMInputLabelAndErrorWrapComponent>
                                <CRMSpacer size="medium" />
                                <CRMSpacingRow
                                    justifyContent="end"
                                >
                                    <CRMButtonQuaternary
                                        label="Copy from preferred"
                                        onClick={() => this.props.dispatch({
                                            type: "CRM_ENQUIRY_VIEWING_INPUT_CHANGED",
                                            payload: {
                                                key: "confirmed_time",
                                                value: viewing.edit.requested_times.length
                                                    ? viewing.edit.requested_times[0]
                                                    : viewing.edit.confirmed_time,
                                                resourceId: viewing.view.id,
                                            },
                                        })}
                                    />
                                </CRMSpacingRow>
                                <CRMSpacer size="medium" />
                                <CRMInputLabelAndErrorWrapComponent
                                    label="Agent name"
                                    displayError={false}
                                    errorMessage=""
                                >
                                    <CRMInputGeneralComponent
                                        inputType="text"
                                        value={viewing.edit.agent}
                                        displayError={false}
                                        onChange={(value) => this.props.dispatch({
                                            type: "CRM_ENQUIRY_VIEWING_INPUT_CHANGED",
                                            payload: {
                                                key: "agent",
                                                value,
                                                resourceId: viewing.view.id,
                                            },
                                        })}
                                    />
                                </CRMInputLabelAndErrorWrapComponent>
                            </>}

                            {/* Display feedback */}
                            {(viewing.edit.status === "confirmed" || viewing.edit.status === "cancelled") && <>
                                <CRMSpacer size="medium" />
                                    <CRMInputLabelAndErrorWrapComponent
                                        label="Feedback"
                                        displayError={false}
                                        errorMessage=""
                                    >
                                        <CRMTextAreaComponent
                                            value={viewing.edit.feedback}
                                            displayError={false}
                                            onChange={(value) => this.props.dispatch({
                                                type: "CRM_ENQUIRY_VIEWING_INPUT_CHANGED",
                                                payload: {
                                                    key: "feedback",
                                                    value,
                                                    resourceId: viewing.view.id,
                                                },
                                            })}
                                        />
                                    </CRMInputLabelAndErrorWrapComponent>
                            </>}

                            {/* Manual save button for confirmed viewings */}
                            {viewing.edit.status === "confirmed" && <>
                                <CRMSpacer size="medium" />
                                <CRMSpacingRow
                                    justifyContent="end"
                                >
                                    <CRMFormButton
                                        formStatus={viewing.status}
                                        ButtonElement={CRMButtonTertiary}
                                        label="Save"
                                        onClick={() => this.props.dispatch({
                                            type: "CRM_ENQUIRY_VIEWING_SAVE_CONFIRMED_VIEWING",
                                            payload: {
                                                resourceId: viewing.view.id,
                                            },
                                        })}
                                    />
                                </CRMSpacingRow>
                            </>}
                        </div>
                    ),
                )}
            </CRMAutosaveIndicatorWrap>
        );
    }
}

export default CRMEnquiryViewingContainer;
