import { TCasesKeyDate } from "../../../../../../domain/codecs/form/CasesForm";
import { array, identity, option } from "fp-ts";
import { pipe } from "fp-ts/lib/pipeable";
import { updateAtOrElse } from "../../../../../../shared/src/utilsByDomain/array/updateAtOrElse";
import { areISODatesOnTheSameDay } from "../../../../../../shared/src/utilsByDomain/dateTime/areISODatesOnTheSameDay";
import { getDateDistanceSortNumber } from "../../../../../../shared/src/utilsByDomain/dateTime";

type TKeyDateDayCluster = {
    date: string;
    keyDates: Array<TCasesKeyDate>;
};
type TKeyDateDayClusters = Array<TKeyDateDayCluster>;


const doesKeyDateFitDayCluster = (keyDate: TCasesKeyDate) => 
    (dayCluster: TKeyDateDayCluster) => 
        areISODatesOnTheSameDay(keyDate.date, dayCluster.date)
;

const findWhichDayClusterKeyDateBelongsTo = (dayClusters: TKeyDateDayClusters, keyDate: TCasesKeyDate) => {
    return pipe(
        dayClusters,
        array.findIndex(doesKeyDateFitDayCluster(keyDate)),
        option.fold(() => -1, identity.flatten)
    );
}

export const groupKeyDatesByDay = (keyDates: Array<TCasesKeyDate>) =>
    pipe(
        keyDates,
        array.reduce(
            [] as TKeyDateDayClusters,
            (dayClusters, keyDate) => pipe(
                dayClusters,
                updateAtOrElse(
                    findWhichDayClusterKeyDateBelongsTo(dayClusters, keyDate),
                    (dayCluster) => ({
                        ...dayCluster,
                        keyDates: dayCluster.keyDates.concat(keyDate),
                    }),
                    (set) => set.concat({
                        date: keyDate.date,
                        keyDates: [keyDate]
                    })
                )
            )
        )
    )

export const sortKeyDatesByRecency = (keyDates: Array<TCasesKeyDate>) => 
    keyDates.sort((dateA, dateB) => getDateDistanceSortNumber(dateA.date, dateB.date))
;

export const sortKeyDatesDayClustersByRecency = (clusters: TKeyDateDayClusters) => 
    clusters.sort((clusterA, clusterB) => getDateDistanceSortNumber(clusterA.date, clusterB.date))
;