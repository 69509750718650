import React from "react";

type TBoxShadowProps = {
    definition: string;
    transition?: string;
};

/**
 * @deprecated Use @see Background's boxShadowDefinition
 */
export const BoxShadow: React.FunctionComponent<React.PropsWithChildren<TBoxShadowProps>> = (props): JSX.Element => {
    return (
        <div
            style={{
                boxShadow: props.definition,
                transition: props.transition,
            }}
        >
            {props.children}
        </div>
    );
};
