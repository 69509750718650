import { option } from "fp-ts";
import { paths } from "../../state/router/routerPaths";
import { TBlocksParamsObject, TRouteActionType } from "../../state/router/routerTypes";
import { Routes } from "../../state/router/routerRoutes";

export const getRouteBlockData = (routes: Routes) => {
    const blocksOpened: TBlocksParamsObject<TRouteActionType> = routes.blockParams[routes.active];
    const activePathDefinition = paths[routes.active];
    const blocksCodec = "blocksCodec" in activePathDefinition ? activePathDefinition.blocksCodec : undefined;

    if (blocksOpened === undefined || blocksCodec === undefined) {
        return option.none;
    }
    return option.some({
        blocksOpened,
        blocksCodec,
    })
}