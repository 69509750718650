import React from "react";
import { FrontColors } from "../../../../models/FrontColors";
import { FrontSpacing } from "../../../../models/FrontSpacing";
import { SpacingColumn } from "../../../BuildingBlocks/SpacingColumn";
import { WeightBold } from "../../../WeightBold/WeightBold";
import { FrontInfoBubble } from "../../Simple/FrontInfoBubble/FrontInfoBubble";
import { Padding } from "../../../BuildingBlocks/Padding";
import { SpacingRow } from "../../../BuildingBlocks/SpacingRow";
import { FrontIcon } from "../../Simple/FrontIcon/FrontIcon";
import FrontParagraphSmall from "../../Simple/FrontParagraphSmall/FrontParagraphSmall";
import { FrontTextColour } from "../../../FrontTextColour/FrontTextColour";
import { FrontFormAgreeButton } from "../../Simple/FrontFormAgreeButton/FrontFormAgreeButton";
import { TUserCaseUserType, UserCaseUserType_UserCaseUserTypeDisplayName } from "../../../../../../domain/codecs/UserCaseUserType";
import { TTransactionType1 } from "../../../../../../domain/codecs/TransactionType";

export type TFrontOnboardingCaseTOEExplanationProps = {
    caseUserType: TUserCaseUserType;
    transactionType: TTransactionType1;
    onClick: () => void;
};

export const FrontOnboardingCaseTOEExplanation = (props: React.PropsWithChildren<TFrontOnboardingCaseTOEExplanationProps>): JSX.Element => (
    
    <SpacingColumn spacing={FrontSpacing.MEDIUM_1}>
        
        {/* EXPLANATION */}
        <FrontInfoBubble color={FrontColors.PRIMARY_2}>
            <Padding spacing={FrontSpacing.SMALL_3}>
                <SpacingColumn spacing={FrontSpacing.MEDIUM_1}>
                    <SpacingRow alignItems="center" justifyContent="center">
                        <FrontIcon
                            iconName="sail-boat"
                            size="extra-large"
                            colour="neutral-18"
                        />
                    </SpacingRow>
                
                    <SpacingColumn spacing={FrontSpacing.SMALL_3}>
                        {(props.caseUserType === "client" || props.caseUserType === "executor") &&
                            <FrontParagraphSmall>
                                <FrontTextColour colour="neutral-18">
                                    You are about to begin your journey with Sail Legal.
                                </FrontTextColour>
                            </FrontParagraphSmall>
                        }
                        
                        {(props.caseUserType === "client" || props.caseUserType === "executor") &&
                            <FrontParagraphSmall>
                                <FrontTextColour colour="neutral-18">
                                    <WeightBold>
                                        Thank you for choosing us.
                                    </WeightBold>
                                </FrontTextColour>
                            </FrontParagraphSmall>
                        }
                        
                        {(props.caseUserType === "client" || props.caseUserType === "executor") &&
                            <FrontParagraphSmall>
                                <FrontTextColour colour="neutral-18">
                                    Before we can represent you officially on your {props.transactionType} we need you to agree to our<WeightBold> terms of engagement</WeightBold>.
                                </FrontTextColour>
                            </FrontParagraphSmall>
                        }

                        {props.caseUserType !== "client" && props.caseUserType !== "executor" &&
                            <FrontParagraphSmall>
                                <FrontTextColour colour="neutral-18">
                                    As {getAnOrA(props.caseUserType)} <WeightBold>{UserCaseUserType_UserCaseUserTypeDisplayName(props.caseUserType)}</WeightBold> involved with this {props.transactionType}, before completing the next steps we need you to agree to our<WeightBold> terms of engagement</WeightBold>.
                                </FrontTextColour>
                            </FrontParagraphSmall>
                        }
                        
                        <FrontParagraphSmall>
                            <FrontTextColour colour="neutral-18">
                                Please read through the terms<WeightBold> on this page</WeightBold>.
                            </FrontTextColour>
                        </FrontParagraphSmall>
                        
                        <FrontParagraphSmall>
                            <FrontTextColour colour="neutral-18">
                                <WeightBold>Click the button below </WeightBold>when you are ready to proceed.
                            </FrontTextColour>
                        </FrontParagraphSmall>
                    </SpacingColumn>
                </SpacingColumn>
            </Padding>
        </FrontInfoBubble>

        {/* AGREE BUTTON */}
        <SpacingRow justifyContent="end">
            <FrontFormAgreeButton
                label="I agree to the terms"
                icon="done"
                onClick={() => props.onClick()}
            />
        </SpacingRow>
    </SpacingColumn>
);

export const getAnOrA = (userType: TUserCaseUserType): string => {
    if (userType === "lpa" || userType === "authorised_third_party" || userType === "executor") {
        return "an"
    } else {
        return "a"
    }
};