import React from "react";
import { TContainerStateProps } from "../../state/TContainerStateProps";
import { CRMPageWrap } from "../../components/Simple/CRMPageWrap/CRMPageWrap";
import { CRMLegalMenuBarContainer } from "../../containers/CRMLegalMenuBarContainer/CRMLegalMenuBarContainer"
import CRMCaseTableContainer from "../../containers/CRMLegal_Misc/CRMCaseTableContainer";
import { Background } from "../../components/BuildingBlocks/Background";
import { CRMSpacing } from "../../models/CRMSpacing";
import { CRMKPIs } from "../../components/Simple/CRMKPIs/CRMKPIs";

const CRMLegalCasesPage = (props: TContainerStateProps): JSX.Element =>
    <>
        <CRMPageWrap>
            {/* HEADER */}
            <CRMLegalMenuBarContainer {...props} />

            <Background
                padding={`${CRMSpacing.X_LARGE} 0px`}
            >
                <CRMCaseTableContainer {...props} />
            </Background>
        </CRMPageWrap>
        
        {/* WEEKLY KPIS */}
        <CRMKPIs
            isOpen={props.state.forms.weekly_kpi_is_showing}
            onClose={() => props.dispatch({
                type: "CRM_CLOSE_WEEKLY_KPIS",
            })}
        />
    </>;

export default CRMLegalCasesPage;
