import { either } from "fp-ts";
import { pipe } from "fp-ts/lib/pipeable";
import React from "react";
import { TDetailedEmailForm } from "../../../../../../domain/codecs/form/TriageForm";
import { sanitizeEmail } from "../../../../functions/email/sanitizeEmail";
import { Sticky } from "../../../BuildingBlocks/Sticky";
import { CRMParagraph } from "../../../Simple/CRMParagraph/CRMParagraph";
import { CRMActionStrip } from "../../../Simple/CRMActionStrip/CRMActionStrip";
import { WeightBold } from "../../../WeightBold/WeightBold";
import { WeightMedium } from "../../../WeightMedium/WeightMedium";
import { isEmailViewType, TEmailViewStatus } from "../CRMComposeEmailForm/CRMComposeEmailFormProps";

type TComposeEmailFormViewAttachedEmailProps = {
    viewStatus: TEmailViewStatus;
    attachedEmail: TDetailedEmailForm;
    onClick: () => void;
};

export const CRMComposeEmailFormViewAttachedEmail = (props: React.PropsWithChildren<TComposeEmailFormViewAttachedEmailProps>): JSX.Element => {

    const sanatizeEmailBody = (emailBody: string, emailId: string): string => pipe(
        either.tryCatch(() => sanitizeEmail(emailBody, emailId), (e) => e),
        either.getOrElse(() => "")
    );

    return (
        <div>
            <Sticky>
                <CRMActionStrip
                    onClick={props.onClick}
                    labelIcon={isEmailViewType("reply", props.viewStatus) ? "reply" : "forward-arrow"}
                    actionIcon={"shrink"}
                >
                    <CRMParagraph>
                        <WeightMedium>{isEmailViewType("reply", props.viewStatus) ? "Replying to" : "Forwarding"}: </WeightMedium> 
                        <WeightBold>{props.attachedEmail?.children.email.subject}</WeightBold>
                    </CRMParagraph>
                </CRMActionStrip>
            </Sticky>

            {React.createElement(
                props.attachedEmail?.children.email.html_or_text === "text" ? "pre" : "div",
                {
                    dangerouslySetInnerHTML: {__html: sanatizeEmailBody(
                        props.attachedEmail?.children.email.body_html_or_text || "",
                        props.attachedEmail?.children.email.id || "",
                    )},
                    style: props.attachedEmail?.children.email.html_or_text === "text" ? {whiteSpace: "pre-wrap", wordWrap: "break-word", marginLeft: "16px", marginRight: "16px"} : {} 
                }
            )}
        </div>
    );
};
