import { array } from "fp-ts";
import { pipe } from "fp-ts/lib/function";
import { Eq } from "fp-ts/lib/string";
import React from "react";
import { TCaseSignOffAsReadyForExchangeForm } from "../../../../domain/codecs/form/CaseForm";
import { CRMFormErrorComponent } from "../CRMFormErrorComponent/CRMFormErrorComponent";
import { CRMParagraph } from "../Simple/CRMParagraph/CRMParagraph";
import { CRMSpacer } from "../CRMSpacer/CRMSpacer";
import { CRMSpacingColumn } from "../CRMSpacingColumn/CRMSpacingColumn";
import { CRMUniversalBulletPoints } from "../CRMUniversalBulletPoints/CRMUniversalBulletPoints";

export const CRMCaseSignOffAsReadyForExchangePopupConfirmationComponent = (props: React.PropsWithChildren<{
    form: TCaseSignOffAsReadyForExchangeForm,
}>): JSX.Element => {
    const errors = (): Array<string> =>
        pipe(
            props.form.validationErrors,
            array.map(([,error]) => error.split(".")[0]),
            array.uniq(Eq),
        );
        
    return <CRMSpacingColumn spacing="medium">
        <CRMParagraph>
            You are about to mark this case as ready for exchange. Are you sure you want to continue?
        </CRMParagraph>

        {props.form.validationErrors.length > 0 && <div>
            <CRMFormErrorComponent errorMessage="The following must be completed first before you can move the case to ready for exchange." />
            <CRMSpacer size="tiny" />
            <CRMUniversalBulletPoints
                size="normal"
                points={[
                    ...(errors().includes("enquiries") ? [<CRMParagraph>Not all enquiries have been resolved or marked as not relevant.</CRMParagraph>] : []),
                    ...(errors().includes("id_checks") ? [<CRMParagraph>Some users on this case have not passed their ID check.</CRMParagraph>] : []),
                    ...(errors().includes("conflicts_of_interest") ? [<CRMParagraph>Conflicts of interest have been flagged and require resolving.</CRMParagraph>] : []),
                    ...(errors().includes("undertakings") ? [<CRMParagraph>Non standard undertakings have been flagged and require approving (or has not been set to no).</CRMParagraph>] : []),
                    ...(errors().includes("last_risk_assessment") ? [<CRMParagraph>A new risk assessment needs to be made with a "Green" grade.</CRMParagraph>] : []),
                    ...(errors().includes("charges") ? [<CRMParagraph>Some of the charges do not have an account number set, this could indicate that a redemption statement has not been requested.</CRMParagraph>] : []),
                    ...(errors().includes("leasehold_fee") ? [<CRMParagraph>The leasehold fee must be added to the ledger.</CRMParagraph>] : []),
                    ...(errors().includes("land_reg_fee") ? [<CRMParagraph>The land registry fee must be added to the ledger.</CRMParagraph>] : []),
                    ...(errors().includes("electronic_transfer") ? [<CRMParagraph>The electronic transfer fee(s) must be added to the ledger.</CRMParagraph>] : []),
                    ...(errors().includes("dual_rep_lender_conflict") ? [<CRMParagraph>The lender does not allow dual rep cases by default and has not signed off on allowing this case.</CRMParagraph>] : []),
                    ...(errors().includes("dual_rep_missing_consent") ? [<CRMParagraph>Some users on this case have not consented to the terms of Dual Rep.</CRMParagraph>] : []),
                    ...(errors().includes("video_calls") ? [<CRMParagraph>Some users on this case have not passed their video call.</CRMParagraph>] : []),
                    ...(errors().includes("client_notified_of_erc") ? [<CRMParagraph>Either an Early Repayment Charge exists and the client has not been notified or we haven't checked for ERCs.</CRMParagraph>] : []),
                    ...(errors().includes("other_side_solicitor_details_checked") ? [<CRMParagraph>The other side's SRA/CLC number hasn't been added.</CRMParagraph>] : []),
                    ...(errors().includes("all_clients_added_and_right_to_buy_or_sell_checked") ? [<CRMParagraph>Our clients haven't been checked as correct (including right to buy or sell).</CRMParagraph>] : []),
                    ...(errors().includes("all_other_side_clients_added_and_right_to_buy_or_sell_checked") ? [<CRMParagraph>The other side's clients haven't been checked as correct (including right to buy or sell).</CRMParagraph>] : []),
                    ...(errors().includes("all_charges_and_title_restrictions_added") ? [<CRMParagraph>All charges & title restrictions haven't been marked as added.</CRMParagraph>] : []),
                    ...(errors().includes("apportionment_question_answered") ? [<CRMParagraph>Either you haven't answered if there is a management company or there is a management company but "Requires apportionment statement" hasn't been answered.</CRMParagraph>] : []),
                    ...(errors().includes("estate_agent_fee_added_to_ledger") ? [<CRMParagraph>An estate agent fee must be added to the ledger.</CRMParagraph>] : []),
                    ...(errors().includes("bank_accounts") ? [<CRMParagraph>Not all bank accounts have been verified or not all clients have been asked if they agree with the bank accounts to be used.</CRMParagraph>] : []),
                    ...(errors().includes("draft_completion_statement_uploaded") ? [<CRMParagraph>A draft completion statement needs to be uploaded.</CRMParagraph>] : []),
                    ...(errors().includes("deposit_added_to_ledger") ? [<CRMParagraph>A deposit must be added to the ledger.</CRMParagraph>] : []),
                    ...(errors().includes("joint_ownership_held_as") ? [<CRMParagraph>Joint ownership held as must be answered.</CRMParagraph>] : []),
                    ...(errors().includes("leasehold_report") ? [<CRMParagraph>A leasehold report must be uploaded.</CRMParagraph>] : []),
                    ...(errors().includes("search_report") ? [<CRMParagraph>A search report must be uploaded.</CRMParagraph>] : []),
                    ...(errors().includes("protocol_forms_required") ? [<CRMParagraph>A TA6, TA10 (and where applicable a TA7) must be uploaded.</CRMParagraph>] : []),
                    ...(errors().includes("clients_authorise_exchange_and_complete") ? [<CRMParagraph>Client(s) have not authorised exchange & complete yet.</CRMParagraph>] : []),
                    ...(errors().includes("mortgage_offer_expired") ? [<CRMParagraph>A mortgage offer which hasn't expired needs to be uploaded.</CRMParagraph>] : []),
                    ...(errors().includes("mortgage_offer_questions_answered") ? [<CRMParagraph>Some of the mortgage offer questions aren't answered satisfactorily.</CRMParagraph>] : []),
                    ...(errors().includes("sdlt_declaration_given") ? [<CRMParagraph>Not all clients have agreed to the SDLT/LTT declaration.</CRMParagraph>] : []),
                    ...(errors().includes("sdlt_return_uploaded_or_referred_to_compass") ? [<CRMParagraph>Either you have not uploaded an SDLT return or set that the client has been referred to Compass.</CRMParagraph>] : []),
                    ...(errors().includes("recent_last_transfer_risk_not_assessed") ? [<CRMParagraph>Either you haven't answered if all properties are registered (and provided a last registration date where applicable) or some of the properties were last registered within 6 months and the AML risks have not yet been considered.</CRMParagraph>] : []),
                    ...(errors().includes("has_unresolved_mandatory_roadblocks") ? [<CRMParagraph>The are still unresolved mandatory roadblocks on the case.</CRMParagraph>] : []),
                ]}
            />
        </div>}
    </CRMSpacingColumn>;
};
