import React from "react";

export default (props: React.SVGAttributes<SVGElement>) => <svg width="89" height="108" viewBox="0 0 89 108" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<path d="M9.76109 95.0994C10.0784 95.0994 10.3334 95.1958 10.5261 95.3884C10.7188 95.5811 10.8151 95.8304 10.8151 96.1364V105.962C10.8151 106.257 10.7131 106.506 10.5091 106.71C10.3051 106.903 10.0444 106.999 9.72709 106.999C9.44376 106.999 9.20009 106.903 8.99609 106.71C8.79209 106.506 8.69009 106.257 8.69009 105.962V102.001H3.26709V105.962C3.26709 106.257 3.15942 106.506 2.94409 106.71C2.72876 106.903 2.47942 106.999 2.19609 106.999C1.89009 106.999 1.63509 106.903 1.43109 106.71C1.23842 106.506 1.14209 106.257 1.14209 105.962V96.1364C1.14209 95.8418 1.24409 95.5981 1.44809 95.4054C1.65209 95.2014 1.91276 95.0994 2.23009 95.0994C2.51342 95.0994 2.75709 95.2014 2.96109 95.4054C3.16509 95.5981 3.26709 95.8418 3.26709 96.1364V100.046H8.69009V96.1364C8.69009 95.8418 8.79776 95.5981 9.01309 95.4054C9.22842 95.2014 9.47776 95.0994 9.76109 95.0994Z" fill="#2D3D64"/>
<path d="M31.3638 101.049C31.3638 102.171 31.1145 103.203 30.6158 104.143C30.1172 105.073 29.4258 105.809 28.5418 106.353C27.6692 106.897 26.6832 107.169 25.5838 107.169C24.4845 107.169 23.4928 106.897 22.6088 106.353C21.7362 105.809 21.0505 105.073 20.5518 104.143C20.0645 103.203 19.8208 102.171 19.8208 101.049C19.8208 99.9274 20.0645 98.9018 20.5518 97.9724C21.0505 97.0318 21.7362 96.2894 22.6088 95.7454C23.4928 95.2014 24.4845 94.9294 25.5838 94.9294C26.6832 94.9294 27.6692 95.2014 28.5418 95.7454C29.4258 96.2894 30.1172 97.0318 30.6158 97.9724C31.1145 98.9018 31.3638 99.9274 31.3638 101.049ZM29.1538 101.049C29.1538 100.29 29.0008 99.6044 28.6948 98.9924C28.3888 98.3691 27.9638 97.8761 27.4198 97.5134C26.8758 97.1508 26.2638 96.9694 25.5838 96.9694C24.8925 96.9694 24.2748 97.1508 23.7308 97.5134C23.1982 97.8648 22.7788 98.3521 22.4728 98.9754C22.1782 99.5988 22.0308 100.29 22.0308 101.049C22.0308 101.809 22.1782 102.5 22.4728 103.123C22.7788 103.747 23.1982 104.24 23.7308 104.602C24.2748 104.954 24.8925 105.129 25.5838 105.129C26.2638 105.129 26.8758 104.948 27.4198 104.585C27.9638 104.223 28.3888 103.735 28.6948 103.123C29.0008 102.5 29.1538 101.809 29.1538 101.049Z" fill="#2D3D64"/>
<path d="M50.6893 95.0824C50.9726 95.0824 51.2163 95.1844 51.4203 95.3884C51.6356 95.5811 51.7433 95.8248 51.7433 96.1194V105.962C51.7433 106.257 51.6469 106.506 51.4543 106.71C51.2616 106.903 51.0066 106.999 50.6893 106.999C50.3719 106.999 50.1113 106.903 49.9073 106.71C49.7146 106.518 49.6183 106.268 49.6183 105.962V99.1114L46.8133 103.395C46.6999 103.531 46.5696 103.645 46.4223 103.735C46.2749 103.815 46.1219 103.849 45.9633 103.837C45.8159 103.849 45.6686 103.815 45.5213 103.735C45.3739 103.645 45.2436 103.531 45.1303 103.395L42.3253 98.9584V105.962C42.3253 106.268 42.2346 106.518 42.0533 106.71C41.8719 106.903 41.6339 106.999 41.3393 106.999C41.0559 106.999 40.8236 106.903 40.6423 106.71C40.4609 106.506 40.3703 106.257 40.3703 105.962V96.1194C40.3703 95.8361 40.4723 95.5924 40.6763 95.3884C40.8916 95.1844 41.1466 95.0824 41.4413 95.0824C41.6113 95.0824 41.7813 95.1278 41.9513 95.2184C42.1213 95.2978 42.2516 95.4054 42.3423 95.5414L46.0483 101.236L49.8563 95.5414C49.9469 95.4054 50.0659 95.2978 50.2133 95.2184C50.3719 95.1278 50.5306 95.0824 50.6893 95.0824Z" fill="#2D3D64"/>
<path d="M68.086 105.044C68.3806 105.044 68.6243 105.146 68.817 105.35C69.021 105.543 69.123 105.77 69.123 106.03C69.123 106.314 69.021 106.546 68.817 106.727C68.6243 106.909 68.3806 106.999 68.086 106.999H62.391C62.0963 106.999 61.847 106.903 61.643 106.71C61.4503 106.506 61.354 106.257 61.354 105.962V96.1364C61.354 95.8418 61.4503 95.5981 61.643 95.4054C61.847 95.2014 62.0963 95.0994 62.391 95.0994H68.086C68.3806 95.0994 68.6243 95.1958 68.817 95.3884C69.021 95.5698 69.123 95.8078 69.123 96.1024C69.123 96.3858 69.0266 96.6181 68.834 96.7994C68.6413 96.9694 68.392 97.0544 68.086 97.0544H63.479V99.9444H67.321C67.6156 99.9444 67.8593 100.041 68.052 100.233C68.256 100.415 68.358 100.653 68.358 100.947C68.358 101.231 68.2616 101.463 68.069 101.644C67.8763 101.814 67.627 101.899 67.321 101.899H63.479V105.044H68.086Z" fill="#2D3D64"/>
<path d="M81.9625 107.169C81.1465 107.169 80.4212 107.067 79.7865 106.863C79.1518 106.659 78.5568 106.314 78.0015 105.826C77.6955 105.554 77.5425 105.254 77.5425 104.925C77.5425 104.665 77.6388 104.438 77.8315 104.245C78.0241 104.041 78.2565 103.939 78.5285 103.939C78.7438 103.939 78.9365 104.007 79.1065 104.143C79.5598 104.517 80.0018 104.789 80.4325 104.959C80.8631 105.129 81.3731 105.214 81.9625 105.214C82.5971 105.214 83.1412 105.073 83.5945 104.789C84.0592 104.506 84.2915 104.155 84.2915 103.735C84.2915 103.225 84.0648 102.829 83.6115 102.545C83.1581 102.251 82.4385 102.03 81.4525 101.882C78.9705 101.52 77.7295 100.364 77.7295 98.4144C77.7295 97.7004 77.9165 97.0828 78.2905 96.5614C78.6645 96.0288 79.1745 95.6264 79.8205 95.3544C80.4665 95.0711 81.1861 94.9294 81.9795 94.9294C82.6935 94.9294 83.3621 95.0371 83.9855 95.2524C84.6201 95.4678 85.1471 95.7511 85.5665 96.1024C85.8951 96.3631 86.0595 96.6634 86.0595 97.0034C86.0595 97.2641 85.9631 97.4964 85.7705 97.7004C85.5778 97.8931 85.3511 97.9894 85.0905 97.9894C84.9205 97.9894 84.7675 97.9384 84.6315 97.8364C84.3368 97.5984 83.9231 97.3831 83.3905 97.1904C82.8691 96.9864 82.3988 96.8844 81.9795 96.8844C81.2655 96.8844 80.7101 97.0204 80.3135 97.2924C79.9281 97.5531 79.7355 97.8988 79.7355 98.3294C79.7355 98.8168 79.9338 99.1851 80.3305 99.4344C80.7385 99.6838 81.3731 99.8878 82.2345 100.046C83.2092 100.216 83.9855 100.437 84.5635 100.709C85.1528 100.97 85.5948 101.333 85.8895 101.797C86.1842 102.251 86.3315 102.857 86.3315 103.616C86.3315 104.33 86.1275 104.959 85.7195 105.503C85.3228 106.036 84.7901 106.45 84.1215 106.744C83.4528 107.028 82.7331 107.169 81.9625 107.169Z" fill="#2D3D64"/>
<path fillRule="evenodd" clipRule="evenodd" d="M88.0266 63.1494C82.2494 54.9246 77.8788 47.0819 74.915 39.6211C70.0506 27.3751 66.6781 14.168 64.7986 -0.000244141H64.5609L62.688 85.1686C67.422 84.192 71.6121 83.3977 75.2583 82.7855L75.7753 82.6993C80.1402 81.9768 83.7034 81.5231 86.4646 81.3378C86.9838 81.303 87.5047 81.2705 88.0266 81.2408V87.1591H0.867188V-0.000244141H88.0266V63.1494ZM30.8676 87.0498C38.2309 80.6796 44.3631 78.8463 49.2646 81.5504C54.166 84.2542 56.7557 85.7737 57.0342 86.1086C54.6217 82.405 53.184 77.7849 52.721 72.2482C52.0267 63.9433 51.7668 55.1717 53.4108 45.7882C55.055 36.4047 57.9258 19.7855 63.6771 8.94065C45.3226 36.7223 40.9088 54.8166 36.3025 69.2648C33.2317 78.8969 31.42 84.8251 30.8676 87.0498Z" fill="#2D3D64"/>
</svg>
