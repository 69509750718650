import React from "react";
import { CRMColors } from "../../../models/CRMColors";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { BackgroundColour } from "../../BuildingBlocks/BackgroundColour";
import { MinHeight } from "../../BuildingBlocks/MinHeight";
import { Padding } from "../../BuildingBlocks/Padding";
import { SpacingColumn } from "../../BuildingBlocks/SpacingColumn";
import { SpacingRow } from "../../BuildingBlocks/SpacingRow";
import { Sticky } from "../../BuildingBlocks/Sticky";
import { CRMSingleViewHeader } from "../../Simple/CRMSingleViewHeader/CRMSingleViewHeader";
import { CRMBottomControlBar } from "../../CRMBottomControlBar/CRMBottomControlBar";
import { useResolutionStatus } from "../../../hooks/useResolutionStatus";
import { CRMConfirmationCountdown } from "../CRMConfirmationCountdown/CRMConfirmationCountdown";
import { CRMButtonQuaternary } from "../../CRMButtonQuaternary/CRMButtonQuaternary";
import { TQuoteRate1, TQuoteRate2 } from "../../../../../domain/codecs/QuoteRate";
import { ledgerTypeDictionary } from "../../../../../domain/codecs/CaseLedger";
import { CRMQuoteRatesSingleViewAppliesWhen } from "./CRMQuoteRatesSingleViewAppliesWhen";
import { CRMQuoteRatesSingleViewConfiguration } from "./CRMQuoteRatesSingleViewConfiguration";
import { TQuoteRateFeePropertyValue, TQuoteRateFeeTransferValue } from "../../../../../domain/codecs/QuoteRateFee";
import { CRMQuoteRatesSingleViewFees } from "./CRMQuoteRatesSingleViewFees";

export const CRMQuoteRatesLiveSingleView = (props: React.PropsWithChildren<{
    quoteRate:
        TQuoteRate1
        & TQuoteRate2
        & {
            transfer_value_fees: Array<TQuoteRateFeeTransferValue>,
            property_value_fees: Array<TQuoteRateFeePropertyValue>,
        },
    onClose: () => void,
    onToggleDeleteOnNextPublish: () => void,
    onReplace: () => void,
}>): JSX.Element => {
    const {
        resolutionStatus,
        setResolution,
        resetResolution,
    } = useResolutionStatus<"none" | "toggle_will_be_deleted_on_next_publish" | "replace", {}>({
        defaultStatus: "none",
    });

    const rateName =
        props.quoteRate.rate_type
            ? ledgerTypeDictionary[props.quoteRate.rate_type].displayName
            : "New rate";

    const onToggleDeleteOnNextPublish = () => setResolution("toggle_will_be_deleted_on_next_publish")();

    const onReplace = () => setResolution("replace")();

    const onSubmitToggleDeleteOnNextPublish = () => {
        resetResolution();
        props.onToggleDeleteOnNextPublish();
    };

    const onSubmitReplace = () => {
        resetResolution();
        props.onReplace();
    }

    return (
        <div>
            {/* BODY */}
            <div>
                <Sticky zIndex={1}>
                    <CRMSingleViewHeader 
                        title={rateName}
                        onClick={props.onClose} 
                    />
                </Sticky>
                
                <BackgroundColour colour={CRMColors.NEUTRAL_PAPER}>
                    <MinHeight height={"700px"}>
                        <Padding spacing={CRMSpacing.LARGE}>
                            <SpacingColumn spacing={CRMSpacing.LARGE}>
                                {/* APPLIES WHEN */}
                                <CRMQuoteRatesSingleViewAppliesWhen
                                    quoteRate={props.quoteRate}
                                />

                                {/* CONFIGURATION */}
                                <CRMQuoteRatesSingleViewConfiguration
                                    quoteRate={props.quoteRate}
                                />

                                {/* FEES */}
                                <CRMQuoteRatesSingleViewFees
                                    quoteRate={props.quoteRate}
                                />
                            </SpacingColumn>

                        </Padding>
                    </MinHeight>
                </BackgroundColour>
            </div>
            
            {/* CONTROL BAR */}
            <CRMBottomControlBar>
                {resolutionStatus === "toggle_will_be_deleted_on_next_publish" &&
                    <CRMConfirmationCountdown
                        title="Toggling delete on next publish!"
                        iconName="delete"
                        primaryButtonMode="done"
                        onUndo={resetResolution}
                        onComplete={onSubmitToggleDeleteOnNextPublish}
                    />
                }

                {resolutionStatus === "replace" &&
                    <CRMConfirmationCountdown
                        title="Replacing this with a new rate!"
                        iconName="copy"
                        primaryButtonMode="done"
                        onUndo={resetResolution}
                        onComplete={onSubmitReplace}
                    />
                }

                {/* CONTROLS */}
                {resolutionStatus === "none" &&
                    <Padding 
                        type="custom"
                        width="100%" 
                        spacing={`${CRMSpacing.TINY} ${CRMSpacing.MEDIUM}`}
                    >
                        {/* CONTROLS - WHEN THIS RATE WILL DELETE ON NEXT PUBLISH */}
                        {props.quoteRate.will_be_deleted_on_next_publish &&
                            <CRMButtonQuaternary
                                icon="undo"
                                label="Cancel delete on next publish"
                                onClick={onToggleDeleteOnNextPublish}
                            />
                        }

                        {/* CONTROLS - WHEN THIS HASN'T BEEN SET TO DELETE ON NEXT PUBLISH */}
                        {!props.quoteRate.will_be_deleted_on_next_publish &&
                            <SpacingRow
                                spacing={CRMSpacing.TINY}
                                justifyContent="start"
                                alignItems="center"
                            >
                                {/* DELETE ON NEXT PUBLISH */}
                                <CRMButtonQuaternary
                                    icon="delete"
                                    label="Delete on next publish"
                                    onClick={onToggleDeleteOnNextPublish}
                                />

                                {/* REPLACE */}
                                <CRMButtonQuaternary
                                    icon="copy"
                                    label="Replace"
                                    onClick={onReplace}
                                />
                            </SpacingRow>
                        }
                    </Padding>
                }
            </CRMBottomControlBar>
        </div>
    );
};
