import React, { useState } from "react";
import { TCaseDualRepSearchResultsForm } from "../../../../../domain/codecs/form/CaseForm";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { SpacingColumn } from "../../BuildingBlocks/SpacingColumn";
import { CRMCardOutsidePopupBasic } from "../../CRMCardOutsidePopupBasic/CRMCardOutsidePopupBasic";
import { CRMParagraph } from "../../Simple/CRMParagraph/CRMParagraph";
import { WeightMedium } from "../../WeightMedium/WeightMedium";
import { CRMCaseDualRepSuggestion } from "../CRMCaseDualRepSuggestion/CRMCaseDualRepSuggestion";

export const CRMCaseDualRepSuggestions = (props: React.PropsWithChildren<{
    suggestionForms: Array<TCaseDualRepSearchResultsForm>,
    onLinkToSuggestion: (form: TCaseDualRepSearchResultsForm) => void,
}>): JSX.Element => {
    const [hasDismissed, setHasDismissed] = useState<boolean>(false);

    const moreThan1Suggestion = props.suggestionForms.length > 1;

    const onClose = () => setHasDismissed(true);

    return <CRMCardOutsidePopupBasic
        isOpen={!hasDismissed}
        title="Is this a Dual Rep case?"
        context="neutral"
        closeText="No! This is not a Dual Rep case"
        onClose={onClose}
        size="medium"
    >
        <SpacingColumn spacing={CRMSpacing.MEDIUM}>
            {/* EXPLANATION PARAGRAPHS */}
            <SpacingColumn spacing={CRMSpacing.TINY}>
                <CRMParagraph>
                    {moreThan1Suggestion &&
                        <>We've found other cases that have the same property address.</>}

                    {!moreThan1Suggestion &&
                        <>We've found a case that has the same property address.</>}
                </CRMParagraph>

                <CRMParagraph>
                    <WeightMedium>If this case is Dual Rep and should be linked to another then you should do this now.</WeightMedium>
                </CRMParagraph>
            </SpacingColumn>

            {/* SUGGESTIONS */}
            {props.suggestionForms.map((form, i) =>
                <CRMCaseDualRepSuggestion
                    key={i}
                    suggestionForm={form}
                    onLinkToSuggestion={props.onLinkToSuggestion}
                />
            )}
        </SpacingColumn>
    </CRMCardOutsidePopupBasic>
};
