import React from "react";
import { array, tuple, ord, option } from "fp-ts/lib";
import { pipe } from "fp-ts/lib/function";
import Heading5 from "./../../Simple/Heading5Component/Heading5";
import * as util from "./../../../../../../shared/src/util";
import Icon from "./../../Simple/IconComponent/Icon";
import BodyText from "./../../Simple/BodyTextComponent/BodyText";

type TAmenitiesCategory =
    "shop"
    | "education"
    | "healthcare"
    | "leisure"
;

interface IAmenitiesData {
    category: TAmenitiesCategory;
    type: string;
    name: string;
    distance_miles: number;
}

interface IAmenitiesProps {
    data: Array<IAmenitiesData>;
    address: string;
}

// The indexes of this array are used to determine the order categories should be displayed, to render a new category first add it to the beginning of the array
const amenityCategoryDisplayOrder: Array<TAmenitiesCategory> = [
    "leisure",
    "shop",
    "education",
    "healthcare",
];

const getCategoryFriendlyName = (category: TAmenitiesCategory): string => {
    switch (category) {
        case "education":
            return "Education";
        case "healthcare":
            return "Healthcare";
        case "leisure":
            return "Leisure";
        case "shop":
            return "Local Shops";
        default:
            return util.requireExhaustive(category);
    }
};

const getDistanceRounded1DP = (distance: number): number =>
    Math.round((distance + Number.EPSILON) * 10) / 10;

class Amenities extends React.Component<React.PropsWithChildren<IAmenitiesProps>> {
    public render (): JSX.Element {
        return (
            <div className="amenitiesComponent">
                <div className="amenitiesComponent__description-container">
                    <BodyText type="normal">
                    House prices are based on a variety of factors, including local amenities. Here are some of the best places around {this.props.address}.
                    </BodyText>
                </div>
                {pipe(
                    // Group the amenities into their categories, the resulting array will be returned with the following structure
                    // [[category, [...amenities]], ...]
                    array.reduce<IAmenitiesData, Array<[TAmenitiesCategory, Array<IAmenitiesData>]>>([], (groupedAmenitiesArray, amenity) =>
                        pipe(
                            array.findIndex<[TAmenitiesCategory, Array<IAmenitiesData>]>(([category]) => category === amenity.category)(groupedAmenitiesArray),
                            option.chain((index) =>
                                array.modifyAt<[TAmenitiesCategory, Array<IAmenitiesData>]>(index, ([category, amenities]) => [
                                    category,
                                    amenities.concat(amenity),
                                ])(groupedAmenitiesArray)
                            ),
                            option.fold(
                                () => groupedAmenitiesArray.concat([[amenity.category, [amenity]]]),
                                (a) => a,
                            ),
                        ),
                    )(this.props.data),
                    // Order the amenities in each category by their lowest distance first
                    array.map(tuple.mapLeft(array.sortBy([
                        ord.ord.contramap<number, IAmenitiesData>(ord.ordNumber, (amenity) => amenity.distance_miles),
                    ]))),
                    // Order the categories by the preferred display order
                    array.sortBy([
                        ord.ord.contramap<number, [TAmenitiesCategory, Array<IAmenitiesData>]>(ord.ordNumber, ([category]) =>
                            pipe(
                                array.findIndex((v) => v === category)(amenityCategoryDisplayOrder),
                                option.fold(
                                    () => amenityCategoryDisplayOrder.length,
                                    (index) => index,
                                )
                            )
                        ),
                    ]),
                    array.map(([category, amenities]) => (
                        <div key={category} className="amenitiesComponent__group">
                            <div className="amenitiesComponent__groupRow--category">
                                <div className="amenitiesComponent__groupRowTitleContainer">
                                    <Heading5
                                        text={getCategoryFriendlyName(category)}
                                        modifierName="crab"
                                    />
                                </div>
                                <div className="amenitiesComponent__iconContainer">
                                    <div className="amenitiesComponent__icon">
                                        <Icon
                                            iconName={category}
                                            height={4}
                                            width={4}
                                            type="possum"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="amenitiesComponent__groupRow--amenities">
                                {array.mapWithIndex<IAmenitiesData, JSX.Element>((i, amenity) => (
                                    <div className="amenitiesComponent__amenity" key={`${i}-amenity`}>
                                        <div className="amenitiesComponent__amenityRow amenitiesComponent__amenityRow--name" key={`${i}-name`}>
                                            <BodyText type="normal">
                                                {amenity.name}
                                            </BodyText>
                                        </div>
                                        <div className="amenitiesComponent__amenityRow" key={`${i}-type`}>
                                            <BodyText type="normal">
                                                {amenity.type}
                                            </BodyText>
                                        </div>
                                        <div className="amenitiesComponent__amenityRow amenitiesComponent__amenityRow--last" key={`${i}-distance`}>
                                            <BodyText type="normal">
                                                {getDistanceRounded1DP(amenity.distance_miles)
                                                    ? `${getDistanceRounded1DP(amenity.distance_miles).toString()} miles`
                                                    : "Nearby"}
                                            </BodyText>
                                        </div>
                                    </div>
                                ))(amenities)}
                            </div>
                        </div>
                    )),
                )}
            </div>
        );
    }
}

export default Amenities;
