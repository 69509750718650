import React from "react";
import { CRMInfoBoxSimpleColourComponent } from "../CRMInfoBoxSimpleColourComponent/CRMInfoBoxSimpleColourComponent";
import { CRMPadding } from "../Simple/CRMPadding/CRMPadding";
import { CRMColumns } from "../CRMColumns/CRMColumns";
import { CRMParagraph } from "../Simple/CRMParagraph/CRMParagraph";
import { CRMCardOutsidePopupBasic } from "../CRMCardOutsidePopupBasic/CRMCardOutsidePopupBasic";
import { CRMButtonIcon } from "../CRMButtonIcon/CRMButtonIcon";
import CRMInputLabelAndErrorWrapComponent from "../CRMInputLabelAndErrorWrapComponent/CRMInputLabelAndErrorWrapComponent";
import CRMInputGeneralComponent from "../CRMInputs/CRMInputGeneralComponent/CRMInputGeneralComponent";
import { CRMSpacer } from "../CRMSpacer/CRMSpacer";
import { CRMInputCurrency } from "../CRMInputCurrency/CRMInputCurrency";
import { CRMInputCheckboxComponent } from "../CRMInputCheckboxComponent/CRMInputCheckboxComponent";
import CRMTextAreaComponent from "../CRMTextAreaComponent/CRMTextAreaComponent";
import { CRMNoticeBoxComponent } from "../CRMNoticeBoxComponent/CRMNoticeBoxComponent";
import { array } from "fp-ts";
import { pipe } from "fp-ts/lib/function";
import { CRMEnquiryBuyerReadySectionTitleComponent } from "../CRMEnquiryBuyerReadySectionTitleComponent/CRMEnquiryBuyerReadySectionTitleComponent";
import { CRMHorizontalTabs } from "../Complex/CRMHorizontalTabs/CRMHorizontalTabs";
import { TUser15AndC8FormList } from "../../models/TFormModels";
import * as User8 from "../../../../domain/models/User8";
import * as User15 from "../../../../domain/models/User15";
import { CRMAutosaveIndicatorWrap } from "../CRM/CRMAutosaveIndicatorWrap/CRMAutosaveIndicatorWrap";
import * as TFormStatus from "../../models/TFormStatus";
import { CRMDeferButtonComponent } from "../CRMDeferButtonComponent/CRMDeferButtonComponent";
import { TListingEnquiryInnerForm } from "../../../../domain/codecs/formEditable/ListingEnquiryForm";
import { doesErrorKeyExist } from "../../../../shared/src/codecs/errors";

class CRMEnquiryBuyerReadyComponent extends React.Component<{
    enquiry: TListingEnquiryInnerForm;
    usersFormList: TUser15AndC8FormList;
    onChange: (value: TListingEnquiryInnerForm) => void;
    onUserChange: <T extends keyof User8.T>(p: {
        userId: string;
        key: T;
        value: Required<User8.T>[T];
    }) => void;
}, {
    modalOpen: boolean;
}> {
    constructor(props: CRMEnquiryBuyerReadyComponent["props"]) {
        super(props);
        this.state = {
            modalOpen: false,
        };
    }

    openModal = (): void =>
        this.setState({modalOpen: true});

    closeModal = (): void =>
        this.setState({modalOpen: false});

    paymentMethodIsCash = (): boolean =>
        this.props.enquiry.edited.payment_method === "cash";

    hasValidationErrorsForAny = (): boolean =>
        doesErrorKeyExist("edited.conveyancer_phone_number", this.props.enquiry.validationErrors)
        || doesErrorKeyExist("edited.conveyancer_email_address", this.props.enquiry.validationErrors)
        || doesErrorKeyExist("edited.mortgage_broker_email_address", this.props.enquiry.validationErrors)
        || doesErrorKeyExist("edited.mortgage_broker_phone_number", this.props.enquiry.validationErrors)
        || doesErrorKeyExist("edited.funds_ltv_percentage", this.props.enquiry.validationErrors)

    purchaseLegalNamesAndIdCheckSectionComplete = (): boolean =>
        this.props.enquiry.edited.id_check_complete
        && this.props.enquiry.edited.purchasers_address
        && pipe(
            this.props.usersFormList.forms,
            array.filter((userForm) =>
                userForm.edit.is_legal_buyer
                && User15.isSailHomesLegalNameFilled({
                    ...userForm.view,
                    ...userForm.edit,
                }) === false
            ),
        ).length === 0 ? true : false;

    public render(): JSX.Element {
        return (
            <div className="crm-enquiry-buyer-ready-component">
                <div
                    className="crm-enquiry-buyer-ready-component__info-box-content"
                    onClick={() => this.openModal()}
                >
                    <CRMInfoBoxSimpleColourComponent
                        fullWidth={false}
                        displayError={this.hasValidationErrorsForAny()}
                    >
                        <CRMPadding size="medium">
                            <CRMColumns
                                padding="medium"
                                alignItems="center"
                                wrapOnMobile={false}
                                columns={[
                                    {
                                        flex: 1,
                                        content: <CRMParagraph>
                                            Buyer Ready Info (funds, solicitor & ID)
                                        </CRMParagraph>,
                                    },
                                    {
                                        flex: 0,
                                        content: <CRMButtonIcon
                                            variant="secondary"
                                            icon="pencil"
                                            onClick={this.openModal}
                                        />,
                                    },
                                ]}
                            />
                        </CRMPadding>
                    </CRMInfoBoxSimpleColourComponent>
                </div>

                {/* Popup */}
                <CRMCardOutsidePopupBasic
                    isOpen={this.state.modalOpen}
                    size="large"
                    title="Add funds, solicitor & Purchase info"
                    onClose={this.closeModal}
                >
                    <CRMAutosaveIndicatorWrap status={this.props.enquiry.status}>
                        <div className="crm-enquiry-buyer-ready-component__popup-container">
                            <div className="crm-enquiry-buyer-ready-component__popup-container-column crm-enquiry-buyer-ready-component__popup-container-column--left">
                                <CRMEnquiryBuyerReadySectionTitleComponent state={this.props.enquiry.children.has_provided_proof_of_funds ? "ok" : "not_complete"}>
                                    Funds
                                </CRMEnquiryBuyerReadySectionTitleComponent>
                                <CRMSpacer size="medium" />
                                <CRMColumns
                                    padding="medium"
                                    wrapOnMobile={false}
                                    alignItems="flex-start"
                                    columns={[
                                        {
                                            flex: 1,
                                            content: <CRMInputLabelAndErrorWrapComponent
                                                label="Cash funds available"
                                            >
                                                <CRMInputCurrency
                                                    value={this.props.enquiry.edited.funds_cash_verified_upto}
                                                    onChange={(funds_cash_verified_upto) => this.props.onChange({
                                                        ...this.props.enquiry,
                                                        edited: {
                                                            ...this.props.enquiry.edited,
                                                            funds_cash_verified_upto,
                                                        }
                                                    })}
                                                    onPressEnterKey={() => undefined}
                                                    displayError={false}
                                                    currencySymbol="£"
                                                />
                                            </CRMInputLabelAndErrorWrapComponent>,
                                        },
                                        {
                                            flex: 1,
                                            content: !this.paymentMethodIsCash() ? <>
                                                <CRMInputLabelAndErrorWrapComponent
                                                    label="DIP max amount"
                                                >
                                                    <CRMInputCurrency
                                                        value={this.props.enquiry.edited.funds_dip_verified_upto}
                                                        onChange={(funds_dip_verified_upto) => this.props.onChange({
                                                            ...this.props.enquiry,
                                                            edited: {
                                                                ...this.props.enquiry.edited,
                                                                funds_dip_verified_upto,
                                                            }
                                                        })}
                                                        onPressEnterKey={() => undefined}
                                                        displayError={false}
                                                        currencySymbol="£"
                                                    />
                                                </CRMInputLabelAndErrorWrapComponent>
                                                <CRMSpacer size="medium" />
                                                <CRMInputLabelAndErrorWrapComponent
                                                    label="Loan to value percentage"
                                                    displayError={doesErrorKeyExist("edited.fundsLtvPercentage", this.props.enquiry.validationErrors)}
                                                    errorMessage="LTV can not exceed 100%"
                                                >
                                                    <CRMInputGeneralComponent
                                                        inputType="numericString"
                                                        value={this.props.enquiry.edited.funds_ltv_percentage === null ? "" : this.props.enquiry.edited.funds_ltv_percentage.toString()}
                                                        onChange={(funds_ltv_percentage) => this.props.onChange({
                                                            ...this.props.enquiry,
                                                            edited: {
                                                                ...this.props.enquiry.edited,
                                                                funds_ltv_percentage: funds_ltv_percentage === "" ? null : Number(funds_ltv_percentage),
                                                            }
                                                        })}
                                                        displayError={doesErrorKeyExist("edited.funds_ltv_percentage", this.props.enquiry.validationErrors)}
                                                    />
                                                </CRMInputLabelAndErrorWrapComponent>
                                            </> : <></>,
                                        },
                                    ]}
                                />

                                {/* If latest offer exceeds the proof of funds we have display a notice */}
                                {this.props.enquiry.children.has_made_offer && !this.props.enquiry.children.has_provided_proof_of_funds_for_offer && <>
                                    <CRMSpacer size="medium" />
                                    <CRMNoticeBoxComponent>
                                        The funds entered here do not cover the current offer. You may need to request further proof.
                                    </CRMNoticeBoxComponent>
                                </>}

                                {/* Mortgage broker details */}
                                {!this.paymentMethodIsCash() &&
                                    <>
                                        <CRMSpacer size="large" />
                                        <CRMEnquiryBuyerReadySectionTitleComponent state={this.props.enquiry.children.has_provided_mortgage_broker_details ? "ok" : "not_complete"}>
                                            Mortgage broker
                                        </CRMEnquiryBuyerReadySectionTitleComponent>
                                        <CRMSpacer size="medium" />
                                        <CRMInputLabelAndErrorWrapComponent>
                                            <CRMInputCheckboxComponent
                                                label="Broker has vouched that buyer is likely to be approved for finance"
                                                checked={this.props.enquiry.edited.mortgage_broker_has_vouched}
                                                onClick={() => this.props.onChange({
                                                    ...this.props.enquiry,
                                                    edited: {
                                                        ...this.props.enquiry.edited,
                                                        mortgage_broker_has_vouched: !this.props.enquiry.edited.mortgage_broker_has_vouched,
                                                    }
                                                })}
                                            />
                                        </CRMInputLabelAndErrorWrapComponent>
                                        <CRMSpacer size="medium" />
                                        <CRMColumns
                                            padding="medium"
                                            wrapOnMobile={false}
                                            alignItems="flex-start"
                                            columns={[
                                                {
                                                    flex: 1,
                                                    content: <>
                                                        <CRMInputLabelAndErrorWrapComponent
                                                            label="Broker organisation"
                                                        >
                                                            <CRMInputGeneralComponent
                                                                inputType="text"
                                                                value={this.props.enquiry.edited.mortgage_broker_organisation_name}
                                                                onChange={(mortgage_broker_organisation_name) => this.props.onChange({
                                                                    ...this.props.enquiry,
                                                                    edited: {
                                                                        ...this.props.enquiry.edited,
                                                                        mortgage_broker_organisation_name,
                                                                    }
                                                                })}
                                                                displayError={false}
                                                            />
                                                        </CRMInputLabelAndErrorWrapComponent>
                                                        <CRMSpacer size="medium" />
                                                        <CRMInputLabelAndErrorWrapComponent
                                                            label="Broker phone number"
                                                            displayError={doesErrorKeyExist("edited.mortgageBrokerPhoneNumber", this.props.enquiry.validationErrors)}
                                                            errorMessage="This phone number is invalid"
                                                        >
                                                            <CRMInputGeneralComponent
                                                                inputType="tel"
                                                                value={this.props.enquiry.edited.mortgage_broker_phone_number || ""}
                                                                onChange={(mortgage_broker_phone_number) => this.props.onChange({
                                                                    ...this.props.enquiry,
                                                                    edited: {
                                                                        ...this.props.enquiry.edited,
                                                                        mortgage_broker_phone_number: mortgage_broker_phone_number || null,
                                                                    }
                                                                })}
                                                                displayError={doesErrorKeyExist("edited.mortgage_broker_phone_number", this.props.enquiry.validationErrors)}
                                                            />
                                                        </CRMInputLabelAndErrorWrapComponent>
                                                    </>,
                                                },
                                                {
                                                    flex: 1,
                                                    content: <>
                                                        <CRMInputLabelAndErrorWrapComponent
                                                            label="Broker name"
                                                        >
                                                            <CRMInputGeneralComponent
                                                                inputType="text"
                                                                value={this.props.enquiry.edited.mortgage_broker_name}
                                                                onChange={(mortgage_broker_name) => this.props.onChange({
                                                                    ...this.props.enquiry,
                                                                    edited: {
                                                                        ...this.props.enquiry.edited,
                                                                        mortgage_broker_name,
                                                                    }
                                                                })}
                                                                displayError={false}
                                                            />
                                                        </CRMInputLabelAndErrorWrapComponent>
                                                        <CRMSpacer size="medium" />
                                                        <CRMInputLabelAndErrorWrapComponent
                                                            label="Broker email"
                                                            displayError={doesErrorKeyExist("edited.mortgageBrokerEmailAddress", this.props.enquiry.validationErrors)}
                                                            errorMessage="This email address is invalid"
                                                        >
                                                            <CRMInputGeneralComponent
                                                                inputType="tel"
                                                                value={this.props.enquiry.edited.mortgage_broker_email_address || ""}
                                                                onChange={(mortgage_broker_email_address) => this.props.onChange({
                                                                    ...this.props.enquiry,
                                                                    edited: {
                                                                        ...this.props.enquiry.edited,
                                                                        mortgage_broker_email_address: mortgage_broker_email_address || null,
                                                                    }
                                                                })}
                                                                displayError={doesErrorKeyExist("edited.mortgage_broker_email_address", this.props.enquiry.validationErrors)}
                                                            />
                                                        </CRMInputLabelAndErrorWrapComponent>
                                                    </>,
                                                },
                                            ]}
                                        />
                                    </>}

                                {/* Solicitor details */}
                                <CRMSpacer size="large" />
                                <CRMEnquiryBuyerReadySectionTitleComponent state={this.props.enquiry.children.has_provided_solicitor_details ? "ok" : "not_complete"}>
                                    Solicitor details
                                </CRMEnquiryBuyerReadySectionTitleComponent>
                                <CRMSpacer size="medium" />
                                <CRMColumns
                                    padding="medium"
                                    wrapOnMobile={false}
                                    alignItems="flex-start"
                                    columns={[
                                        {
                                            flex: 1,
                                            content: <>
                                                <CRMInputLabelAndErrorWrapComponent
                                                    label="Solicitor organisation"
                                                >
                                                    <CRMInputGeneralComponent
                                                        inputType="text"
                                                        value={this.props.enquiry.edited.conveyancer_organisation_name}
                                                        onChange={(conveyancer_organisation_name) => this.props.onChange({
                                                            ...this.props.enquiry,
                                                            edited: {
                                                                ...this.props.enquiry.edited,
                                                                conveyancer_organisation_name,
                                                            }
                                                        })}
                                                        displayError={false}
                                                    />
                                                </CRMInputLabelAndErrorWrapComponent>
                                                <CRMSpacer size="medium" />
                                                <CRMInputLabelAndErrorWrapComponent
                                                    label="Solicitor phone number"
                                                    displayError={doesErrorKeyExist("edited.conveyancerPhoneNumber", this.props.enquiry.validationErrors)}
                                                    errorMessage="This phone number is invalid"
                                                >
                                                    <CRMInputGeneralComponent
                                                        inputType="tel"
                                                        value={this.props.enquiry.edited.conveyancer_phone_number || ""}
                                                        onChange={(conveyancer_phone_number) => this.props.onChange({
                                                            ...this.props.enquiry,
                                                            edited: {
                                                                ...this.props.enquiry.edited,
                                                                conveyancer_phone_number: conveyancer_phone_number || null,
                                                            }
                                                        })}
                                                        displayError={doesErrorKeyExist("edited.conveyancer_phone_number", this.props.enquiry.validationErrors)}
                                                    />
                                                </CRMInputLabelAndErrorWrapComponent>
                                            </>,
                                        },
                                        {
                                            flex: 1,
                                            content: <>
                                                <CRMInputLabelAndErrorWrapComponent
                                                    label="Solicitor name"
                                                >
                                                    <CRMInputGeneralComponent
                                                        inputType="text"
                                                        value={this.props.enquiry.edited.conveyancer_name}
                                                        onChange={(conveyancer_name) => this.props.onChange({
                                                            ...this.props.enquiry,
                                                            edited: {
                                                                ...this.props.enquiry.edited,
                                                                conveyancer_name,
                                                            }
                                                        })}
                                                        displayError={false}
                                                    />
                                                </CRMInputLabelAndErrorWrapComponent>
                                                <CRMSpacer size="medium" />
                                                <CRMInputLabelAndErrorWrapComponent
                                                    label="Solicitor email"
                                                    displayError={doesErrorKeyExist("edited.conveyancerEmailAddress", this.props.enquiry.validationErrors)}
                                                    errorMessage="This email address is invalid"
                                                >
                                                    <CRMInputGeneralComponent
                                                        inputType="tel"
                                                        value={this.props.enquiry.edited.conveyancer_email_address || ""}
                                                        onChange={(conveyancer_email_address) => this.props.onChange({
                                                            ...this.props.enquiry,
                                                            edited: {
                                                                ...this.props.enquiry.edited,
                                                                conveyancer_email_address: conveyancer_email_address || null,
                                                            }
                                                        })}
                                                        displayError={doesErrorKeyExist("edited.conveyancer_email_address", this.props.enquiry.validationErrors)}
                                                    />
                                                </CRMInputLabelAndErrorWrapComponent>
                                            </>,
                                        },
                                    ]}
                                />
                                <CRMSpacer size="medium" />
                                <CRMInputLabelAndErrorWrapComponent
                                    label="Solicitor address"
                                >
                                    <CRMTextAreaComponent
                                        value={this.props.enquiry.edited.conveyancer_address}
                                        onChange={(conveyancer_address) => this.props.onChange({
                                            ...this.props.enquiry,
                                            edited: {
                                                ...this.props.enquiry.edited,
                                                conveyancer_address,
                                            }
                                        })}
                                        displayError={false}
                                    />
                                </CRMInputLabelAndErrorWrapComponent>
                            </div>
                            <div className="crm-enquiry-buyer-ready-component__popup-container-column crm-enquiry-buyer-ready-component__popup-container-column--right">
                                {/* Purchaser names & ID Check */}
                                <CRMEnquiryBuyerReadySectionTitleComponent state={this.purchaseLegalNamesAndIdCheckSectionComplete() ? "ok" : "not_complete"}>
                                    Purchaser names & ID checks
                                </CRMEnquiryBuyerReadySectionTitleComponent>

                                <CRMSpacer size="medium" />
                                <CRMInputLabelAndErrorWrapComponent label="Defer get legal buyers job">
                                    <CRMDeferButtonComponent
                                        value={this.props.enquiry.edited.get_legal_buyers_defer}
                                        onChange={(get_legal_buyers_defer) => this.props.onChange({
                                            ...this.props.enquiry,
                                            edited: {
                                                ...this.props.enquiry.edited,
                                                get_legal_buyers_defer,
                                            }
                                        })}
                                        displayError={false}
                                    />
                                </CRMInputLabelAndErrorWrapComponent>

                                <CRMSpacer size="medium" />
                                <CRMInputLabelAndErrorWrapComponent>
                                    <CRMInputCheckboxComponent
                                        label="All ID checks complete"
                                        checked={this.props.enquiry.edited.id_check_complete}
                                        onClick={() => this.props.onChange({
                                            ...this.props.enquiry,
                                            edited: {
                                                ...this.props.enquiry.edited,
                                                id_check_complete: !this.props.enquiry.edited.id_check_complete,
                                            }
                                        })}
                                    />
                                </CRMInputLabelAndErrorWrapComponent>

                                <CRMSpacer size="medium" />
                                <CRMInputLabelAndErrorWrapComponent
                                    label="Purchaser's company name (if applicable)"
                                    displayError={doesErrorKeyExist("edited.purchasers_company_name", this.props.enquiry.validationErrors)}
                                    errorMessage=""
                                >
                                    <CRMInputGeneralComponent
                                        inputType="text"
                                        value={this.props.enquiry.edited.purchasers_company_name}
                                        onChange={(purchasers_company_name) => this.props.onChange({
                                            ...this.props.enquiry,
                                            edited: {
                                                ...this.props.enquiry.edited,
                                                purchasers_company_name,
                                            }
                                        })}
                                        displayError={doesErrorKeyExist("edited.purchasers_company_name", this.props.enquiry.validationErrors)}
                                    />
                                </CRMInputLabelAndErrorWrapComponent>

                                <CRMSpacer size="medium" />
                                <CRMInputLabelAndErrorWrapComponent
                                    label="Purchaser's address"
                                    displayError={doesErrorKeyExist("edited.purchasers_address", this.props.enquiry.validationErrors)}
                                    errorMessage=""
                                >
                                    <CRMTextAreaComponent
                                        value={this.props.enquiry.edited.purchasers_address}
                                        onChange={(purchasers_address) => this.props.onChange({
                                            ...this.props.enquiry,
                                            edited: {
                                                ...this.props.enquiry.edited,
                                                purchasers_address,
                                            }
                                        })}
                                        displayError={doesErrorKeyExist("edited.purchasers_address", this.props.enquiry.validationErrors)}
                                    />
                                </CRMInputLabelAndErrorWrapComponent>

                                <CRMSpacer size="medium" />
                                <CRMAutosaveIndicatorWrap status={
                                    pipe(
                                        TFormStatus.reduceToHighestPriority(pipe(
                                            this.props.usersFormList.forms,
                                            array.map((form) => form.status),
                                        )),
                                        (status) => status === "hidden" ? "untouched"
                                            : status,
                                    )
                                }>
                                    <CRMHorizontalTabs
                                        tabs={pipe(
                                            this.props.usersFormList.forms,
                                            array.map((userForm) => ({
                                                label: `${userForm.edit.first_name} ${userForm.edit.last_name}`,
                                                children: <div>
                                                    <CRMSpacer size="medium" />
                                                    <CRMInputCheckboxComponent
                                                        label="They are a legal buyer"
                                                        checked={userForm.edit.is_legal_buyer}
                                                        onClick={() => this.props.onUserChange({
                                                            userId: userForm.view.id,
                                                            key: "is_legal_buyer",
                                                            value: !userForm.edit.is_legal_buyer,
                                                        })}
                                                    />
                                                    {userForm.edit.is_legal_buyer && <>
                                                        <CRMSpacer size="medium" />
                                                        <CRMInputLabelAndErrorWrapComponent
                                                            label="Legal first name"
                                                            displayError={false}
                                                            errorMessage=""
                                                        >
                                                            <CRMInputGeneralComponent
                                                                inputType="text"
                                                                value={userForm.edit.homes_first_legal_name}
                                                                onChange={(value) => this.props.onUserChange({
                                                                    userId: userForm.view.id,
                                                                    key: "homes_first_legal_name",
                                                                    value,
                                                                })}
                                                                displayError={false}
                                                            />
                                                        </CRMInputLabelAndErrorWrapComponent>
                                                        <CRMSpacer size="medium" />
                                                        <CRMInputLabelAndErrorWrapComponent
                                                            label="Legal middle name"
                                                            displayError={false}
                                                            errorMessage=""
                                                        >
                                                            <CRMInputGeneralComponent
                                                                inputType="text"
                                                                value={userForm.edit.homes_middle_legal_name}
                                                                onChange={(value) => this.props.onUserChange({
                                                                    userId: userForm.view.id,
                                                                    key: "homes_middle_legal_name",
                                                                    value,
                                                                })}
                                                                displayError={false}
                                                            />
                                                        </CRMInputLabelAndErrorWrapComponent>
                                                        <CRMSpacer size="medium" />
                                                        <CRMInputLabelAndErrorWrapComponent
                                                            label="Legal last name"
                                                            displayError={false}
                                                            errorMessage=""
                                                        >
                                                            <CRMInputGeneralComponent
                                                                inputType="text"
                                                                value={userForm.edit.homes_last_legal_name}
                                                                onChange={(value) => this.props.onUserChange({
                                                                    userId: userForm.view.id,
                                                                    key: "homes_last_legal_name",
                                                                    value,
                                                                })}
                                                                displayError={false}
                                                            />
                                                        </CRMInputLabelAndErrorWrapComponent>
                                                        <CRMSpacer size="medium" />
                                                        <CRMInputLabelAndErrorWrapComponent
                                                            label="Thirdfort ID check URL"
                                                            displayError={false}
                                                            errorMessage=""
                                                            labelFontWeight="medium"
                                                        >
                                                            <CRMInputGeneralComponent
                                                                inputType="text"
                                                                value={userForm.edit.homes_thirdfort_id_check_url}
                                                                onChange={(value) => this.props.onUserChange({
                                                                    userId: userForm.view.id,
                                                                    key: "homes_thirdfort_id_check_url",
                                                                    value,
                                                                })}
                                                                displayError={false}
                                                            />
                                                        </CRMInputLabelAndErrorWrapComponent>
                                                    </>}
                                                    <CRMSpacer size="medium" />
                                                </div>,
                                            }))
                                        )}
                                        buttons={[]}
                                        goToNewTabs={false}
                                    />
                                </CRMAutosaveIndicatorWrap>
                            </div>
                        </div>
                    </CRMAutosaveIndicatorWrap>
                </CRMCardOutsidePopupBasic>
            </div>
        );
    }
}

export default CRMEnquiryBuyerReadyComponent;
