import { DateTime } from "luxon";
import React from "react";
import { TCaseQuoteSentToClientForm } from "../../../../../domain/codecs/form/CaseForm";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { SpacingColumn } from "../../BuildingBlocks/SpacingColumn";
import CRMInputLabelAndErrorWrapComponent from "../../CRMInputLabelAndErrorWrapComponent/CRMInputLabelAndErrorWrapComponent";
import { CRMRadioListComponent } from "../../CRMInputs/CRMRadioListComponent/CRMRadioListComponent";

export const CRMQuoteSentToClientPopoutForm = (props: React.PropsWithChildren<{
    form: TCaseQuoteSentToClientForm,
    displayError: boolean,
    onChange: (form: TCaseQuoteSentToClientForm) => void,
}>): JSX.Element => {

    return (
        <SpacingColumn spacing={CRMSpacing.LARGE}>
            <SpacingColumn spacing={CRMSpacing.MEDIUM}>

                <CRMInputLabelAndErrorWrapComponent
                    label="Has a quote been sent to client(s)?"
                    displayError={props.displayError}
                >
                    <CRMRadioListComponent
                        value={!!props.form.edited.quote_sent_to_client ? true : false}
                        options={[
                            {
                                value: false,
                                text: "No",
                            },
                            {
                                value: true,
                                text: "Yes"
                            },
                        ]}
                        displayError={props.displayError}
                        onChange={(value) => props.onChange({
                            ...props.form,
                            edited: {
                                ...props.form.edited,
                                quote_sent_to_client: value === true ? DateTime.utc().toISO() : null
                            }
                        })}
                    />
                </CRMInputLabelAndErrorWrapComponent>

            </SpacingColumn>
        </SpacingColumn>
    )
};
