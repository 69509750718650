import React from "react";
import { TTriageSimpleEmailForm } from "../../../../../domain/codecs/form/TriageForm";
import { IFuzzySortOption } from "../../../hooks/useFuzzysort";
import { CRMColors } from "../../../models/CRMColors";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { Border } from "../../BuildingBlocks/Border";
import { MinHeight } from "../../BuildingBlocks/MinHeight";
import { Sticky } from "../../BuildingBlocks/Sticky";
import { CRMPopoutHeader } from "../../Simple/CRMPopoutHeader/CRMPopoutHeader";
import { CRMBlockPopover } from "../../CRM/CRMBlock/CRMBlockPopover/CRMBlockPopover";
import { CRMEmailMetadata } from "../CRMEmailMetadata/CRMEmailMetadata";
import { CRMPlainFilterSearchSelect } from "../CRMPlainFilterSearchSelect/CRMPlainFilterSearchSelect";

type TAssignToPersonSectionPopoutProps = {
    email: TTriageSimpleEmailForm;
    onSelectPersonToAssignTo: (value: string, option: IFuzzySortOption<string, {}>) => void;
    onClose: () => void;
};

export const CRMAssignToPersonSectionPopout = (props: React.PropsWithChildren<TAssignToPersonSectionPopoutProps>): JSX.Element => {

    return (
        <CRMBlockPopover
            padding={CRMSpacing.MEDIUM}
            height="720px" 
            maxHeight="720px"
        >
            <CRMPopoutHeader
                icon="arrow-left"
                onClick={props.onClose}
            >
                Assign to person
            </CRMPopoutHeader>

            <MinHeight height="570px">
                <CRMPlainFilterSearchSelect
                    searchStickyTopPosition="51px"
                    options={props.email.children.detailed_email_form.children.assign_to_user_only_forms.map((userForm) => ({
                        value: userForm.original.user_id,
                        label: userForm.children.name,
                        meta: userForm
                    }))}
                    placeholder="Search for staff member by name"
                    onClick={props.onSelectPersonToAssignTo}
                />
            </MinHeight>

            <Sticky bottom="0px" zIndex={1}>
                <Border 
                    type="top" 
                    definition={`1px solid ${CRMColors.NEUTRAL_8}`}
                >
                    <CRMEmailMetadata
                        padding={CRMSpacing.MEDIUM}
                        email={props.email.children.detailed_email_form}
                        fieldsToShow={["subject", "from", "to", "cc"]}
                    />
                </Border>
            </Sticky>
        </CRMBlockPopover>
    );
};
