import { identity, option } from "fp-ts";
import { pipe } from "fp-ts/lib/pipeable";


export const extractEmail = (value) => pipe(
    extractEmailOption(value),
    option.fold(
        () => "",
        identity.flatten
    )
);

export const extractEmailOrSelf = (value) => pipe(
    extractEmailOption(value),
    option.fold(
        () => value,
        identity.flatten
    )
);

export const extractEmailOption = (value: string) => {
    let match = value.match(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi);
    if (match === null) {
        return option.none;
    }
    return option.some(match[0]);
}