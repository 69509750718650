import * as t from "io-ts";
import * as JsonResponse1 from "./JsonResponse1";
import * as Case4 from "./Case4";

export const codec = JsonResponse1.createCodec(
    Case4.codec,
    t.type({}),
);

export type T = t.TypeOf<typeof codec>;

export const newDefault = (): T => ({
    data: Case4.newDefault(),
    meta: {},
});
