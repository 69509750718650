import React, { CSSProperties } from "react";
import { TPercentageSize, TPixelSize, TPixelSizeShortHand } from "../../../../models/StringLiterals";
import { Sticky } from "../../../BuildingBlocks/Sticky";
import { CRMTidyPopout } from "../../../Simple/CRMTidyPopout/CRMTidyPopout";
import { PopupBase } from "../../../Simple/PopupBase/PopupBase";

type TCRMBlockPopoverProps = {
    top?: TPixelSize;
    padding?: TPixelSizeShortHand;
    width?: TPixelSize;
    height?: TPixelSize | TPercentageSize;
    maxHeight?: TPixelSize | TPercentageSize;
    overflowY?: CSSProperties["overflowY"];
    display?: CSSProperties["display"],
    justifyContent?: CSSProperties["justifyContent"],
};

export const CRMBlockPopover = (props: React.PropsWithChildren<TCRMBlockPopoverProps>): JSX.Element => {
    const width = props.width || "100%";

    return <PopupBase
        padding={props.padding} 
        isOpen={true}
    >
        <Sticky
            top={props.top || "60px"}
            display={props.display}
            justifyContent={props.justifyContent}
        >
            <CRMTidyPopout 
                width={width}
                height={props.height}
                maxHeight={props.maxHeight}
                overflowY={props.overflowY}
            >
                {props.children}
            </CRMTidyPopout>
        </Sticky>
    </PopupBase>
};
