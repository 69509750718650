import React from "react";
import { CRMButtonQuaternary } from "../../../CRMButtonQuaternary/CRMButtonQuaternary";
import { CRMInputCalendarComponent } from "../../../CRMInputCalendarComponent/CRMInputCalendarComponent";
import CRMInputLabelAndErrorWrapComponent from "../../../CRMInputLabelAndErrorWrapComponent/CRMInputLabelAndErrorWrapComponent";
import { CRMParagraph } from "../../../Simple/CRMParagraph/CRMParagraph";
import { CRMSpacer } from "../../../CRMSpacer/CRMSpacer";
import { SpacingRow } from "../../../BuildingBlocks/SpacingRow";
import * as CaseDocumentType1 from "../../../../../../domain/models/CaseDocumentType1";
import * as TFormStatus from "../../../../models/TFormStatus";
import { enumToIDropdownOptionsSortedByDisplayName } from "../../../../functions/enumToIDropdownOptions";
import CRMInputGeneralComponent from "../../../CRMInputs/CRMInputGeneralComponent/CRMInputGeneralComponent";
import { CRMTitleForCard } from "../../../CRMTitleForCard/CRMTitleForCard";
import { CRMInputCheckboxComponent } from "../../../CRMInputCheckboxComponent/CRMInputCheckboxComponent";
import { CRMFilterDropdown } from "../../../Simple/CRMFilterDropdown/CRMFilterDropdown";
import { CRMNoticeBoxComponent } from "../../../CRMNoticeBoxComponent/CRMNoticeBoxComponent";

export type TEmptyStringOrCaseDocumentType1Tuple = "" | CaseDocumentType1.T | CaseDocumentType1.TDepreciatedDocumentTypes;

type TCRMCaseEnquiryFlagDocumentForm = {
    documentTypeValue: TEmptyStringOrCaseDocumentType1Tuple;
    documentAdditionalNameValue: string;
    documentFromExternalSourceValue: boolean;
    expectedByDate: string;
    formStatus: TFormStatus.T;
    onChangeDropdown: (value: TEmptyStringOrCaseDocumentType1Tuple) => void;
    onChangeText: (value: string) => void;
    onChangeFromExternalSource: (value: boolean) => void;
    onChangeDate: (value: string) => void;
    onAdd: () => void;
};

export class CRMCaseEnquiryFlagDocumentForm extends React.Component<React.PropsWithChildren<TCRMCaseEnquiryFlagDocumentForm>> {
    typeSelectedIsDepreciated = (): boolean =>
        CaseDocumentType1.DepreciatedDocumentTypes.includes(this.props.documentTypeValue as CaseDocumentType1.TDepreciatedDocumentTypes);

    public render (): JSX.Element {
        return (
            <div className="crm-case-enquiry-flag-document-form">
                {/* TITLE */}
                <CRMTitleForCard>
                    Flag documents for manual upload
                </CRMTitleForCard>
                <CRMSpacer size="large" />
                {/* GUIDANCE TEXT */}
                <CRMParagraph>
                    Flag a document for a future manual upload.
                </CRMParagraph>
                <CRMSpacer size="tiny" />
                <CRMParagraph>
                    This will help you chase documents and stay on top of the ones which have not been received.
                </CRMParagraph>
                <CRMSpacer size="large" />
                {/* DOCUMENT TYPE */}
                <CRMInputLabelAndErrorWrapComponent
                    label="Document Type"
                >
                    <CRMFilterDropdown
                        placeholder="Select document type"
                        value={this.props.documentTypeValue}
                        options={[
                            ...
                            enumToIDropdownOptionsSortedByDisplayName(
                                CaseDocumentType1.valuesWithDepreciated,
                                CaseDocumentType1.toDisplayString
                            ),
                            {label: "", value: ""}
                        ]}
                        onChange={this.props.onChangeDropdown}
                    />
                </CRMInputLabelAndErrorWrapComponent>
                <CRMSpacer size="large" />

                {/* SHOW WHEN NOT DEPRECIATED */}
                {!this.typeSelectedIsDepreciated() && <>
                    {/* ADDITIONAL NAME FOR DOCUMENT */}
                    <CRMInputLabelAndErrorWrapComponent
                        label="Additional Name (optional)"
                        labelFontWeight="regular"
                    >
                        <CRMInputGeneralComponent
                            inputType="text"
                            displayError={false}
                            placeholder="Text to append to document name"
                            onChange={this.props.onChangeText}
                            value={this.props.documentAdditionalNameValue}
                        />
                    </CRMInputLabelAndErrorWrapComponent>
                    <CRMSpacer size="large" />
                    <CRMInputCheckboxComponent
                        label="From External Source"
                        checked={this.props.documentFromExternalSourceValue}
                        onClick={() =>
                            this.props.onChangeFromExternalSource(!this.props.documentFromExternalSourceValue)
                        }
                    />
                    <CRMSpacer size="large" />
                    {/* EXPECTED BY DATE */}
                    <CRMInputLabelAndErrorWrapComponent
                        label="Expected by date"
                        displayError={false}
                        errorMessage=""
                    >
                        <CRMInputCalendarComponent
                            placeholder="Select date"
                            dateType="date"
                            value={this.props.expectedByDate}
                            displayError={false}
                            onChange={this.props.onChangeDate}
                            onPressEnterKey={() => null}
                        ></CRMInputCalendarComponent>
                    </CRMInputLabelAndErrorWrapComponent>
                    <CRMSpacer size="large" />
                    {/* ADD DOCUMENT BUTTON */}
                    <SpacingRow justifyContent="end">
                        <CRMButtonQuaternary
                            label="Add"
                            icon={this.props.formStatus === "submitting" ? "pending"
                                : this.props.formStatus === "failure" ? "warning-bubble"
                                : "done"}
                            disabled={this.props.documentTypeValue === "" || this.props.expectedByDate === "" ? true : false}
                            onClick={this.props.onAdd}
                            highlight={this.props.formStatus === "failure" ? "instructional-brick"
                                : this.props.formStatus === "success" ? "positive-green"
                                : "none"
                            }
                        />
                    </SpacingRow>
                </>}

                {/* SHOW WHEN IS DEPRECIATED */}
                {this.typeSelectedIsDepreciated() && <>
                    <CRMNoticeBoxComponent>
                        This document type can no longer be uploaded from here. Please upload this document from the correct section in the "Details" tab.
                    </CRMNoticeBoxComponent>
                </>}
            </div>
        );
    }
}
