import React from "react";
import { CRMColors } from "../../../models/CRMColors";
import { Shape } from "../../BuildingBlocks/Shape";
import { SpacingRow } from "../../BuildingBlocks/SpacingRow";
import { CRMIcon } from "../../CRMIcon/CRMIcon";
import { TTimelineSentiment } from "../CRMTimelineSentiment/CRMTimelineSentiment";

type TSentimentIconProps = {
    sentiment: TTimelineSentiment;
};

export const CRMSentimentIcon = (props: React.PropsWithChildren<TSentimentIconProps>): JSX.Element => {

    return (
        <Shape
            width="26px"
            height="26px"
            borderRadius="100px"
            color={
                props.sentiment === "happy" ?
                    CRMColors.HIGHLIGHTS_POSITIVE_GREEN_12
                    : CRMColors.HIGHLIGHTS_INSTRUCTIONAL_BRICK_12
            }
        >
            <SpacingRow
                alignItems="center"
                justifyContent="center"
            >
                <CRMIcon 
                    iconName={props.sentiment}
                    colour={props.sentiment === "happy" ? 
                        "highlights-positive-green-0"
                        : "highlights-instructional-brick-0"
                    }
                />
            </SpacingRow>
        </Shape>
    );
};
