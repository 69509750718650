import React from "react";
import { TButton } from "./../../models/TButton";
import { CRMIcon } from "../CRMIcon/CRMIcon";
import { CRMColumnSpacer } from "../CRMColumnSpacer/CRMColumnSpacer";
import { TCRMMainColours } from "../../models/TCRMMainColours";

const TButtonToIconColour = (props: TButton): TCRMMainColours => {
    if (props.disabled) {
        return "neutral-2";
    }
    if (props.highlight === "instructional-brick") {
        return "highlights-instructional-brick-0";
    }
    if (props.highlight === "positive-green") {
        return "highlights-positive-green-4";
    }
    if (props.highlight === "calming-pink") {
        return "highlights-calming-pink-0";
    }
    return "neutral-ink";
};

export class CRMButtonSecondary extends React.Component<React.PropsWithChildren<TButton>> {
    public render (): JSX.Element {
        return (
            <a
                className={`
                    crm-button-secondary
                    crm-button-secondary--highlight-${this.props.highlight ? this.props.highlight : "none"}
                    crm-button-secondary--${this.props.fullWidth ? "full-width" : "auto"}
                    crm-button-secondary--${this.props.disabled ? "disabled" : "enabled"}
                `}
                role="button"
                title={this.props.title}
                onClick={this.props.disabled ? undefined : this.props.onClick}
                href={this.props.disabled ? undefined : this.props.href}
                target={this.props.target}
            >
                {/* ICON */}
                {this.props.icon &&
                    <div className={`crm-button-secondary__icon`}>
                        <CRMIcon
                            iconName={this.props.icon}
                            colour={TButtonToIconColour(this.props)}
                            size="small"
                        />
                        <CRMColumnSpacer size="tiny"/>
                    </div>
                }
                {/* LABEL */}
                {this.props.label}
            </a>
        );
    }
}
