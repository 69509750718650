import React from "react";
import { foldGrievanceFormAction, TCaseCustomerSatisfactionDeleteSentimentForm, TCaseCustomerSatisfactionEmailActionForm, TCaseCustomerSatisfactionFormAction, TCaseCustomerSatisfactionFormalisedAsComplaintActionForm, TCaseCustomerSatisfactionNoteActionForm, TCaseCustomerSatisfactionNoteTogglePinForm, TCaseCustomerSatisfactionRaisedWithOmbudsmanActionForm, TCaseCustomerSatisfactionSentimentActionForm } from "../../../../../domain/codecs/form/CaseCustomerSatisfactionForm";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { Background } from "../../BuildingBlocks/Background";
import { CRMTimelineDefer } from "../../Simple/CRMTimelineDefer/CRMTimelineDefer";
import { CRMTimelineEmail } from "../../Simple/CRMTimelineEmail/CRMTimelineEmail";
import { CRMTimelineEvent } from "../../Simple/CRMTimelineEvent/CRMTimelineEvent";
import { CRMTimelinePhoneCall } from "../../Simple/CRMTimelinePhoneCall/CRMTimelinePhoneCall";
import { CRMTimelineSentiment } from "../../Simple/CRMTimelineSentiment/CRMTimelineSentiment";
import { CRMTimelineTextItem } from "../../Simple/CRMTimelineTextItem/CRMTimelineTextItem";
import { CRMTimelineWrapper } from "../CRMTimelineWrapper/CRMTimelineWrapper";

type TCustomerSatisfactionTimelineProps = {
    entries: Array<TCaseCustomerSatisfactionFormAction>;
    onOpenEmail: (action: TCaseCustomerSatisfactionEmailActionForm) => void;
    onDeleteRaisedAsAFormalComplaint: (action: TCaseCustomerSatisfactionFormalisedAsComplaintActionForm) => void;
    onDeleteRaisedWithOmbudsman: (action: TCaseCustomerSatisfactionRaisedWithOmbudsmanActionForm) => void;
    onDeleteNote: (action: TCaseCustomerSatisfactionNoteActionForm) => void;
    onToggleNotePin: (form: TCaseCustomerSatisfactionNoteTogglePinForm) => void; 
    onDeleteSentiment: (form: TCaseCustomerSatisfactionSentimentActionForm) => void;
};

export const CRMCustomerSatisfactionTimeline = (props: React.PropsWithChildren<TCustomerSatisfactionTimelineProps>): JSX.Element => {

    return (
        <Background 
            padding={CRMSpacing.MEDIUM}
            minHeight="800px"
        >
            <CRMTimelineWrapper
                entries={props.entries.map(
                    foldGrievanceFormAction<JSX.Element>({
                        onFormalisedAsComplaintAction: (action) =>
                            <CRMTimelineEvent
                                date={action.children.action.occurred_at}
                                eventDescription={`Raised as a formal complaint`}
                                onClickDelete={() => props.onDeleteRaisedAsAFormalComplaint(action)}
                            />,
                        onRaisedWithOmbudsmanAction: (action) =>
                            <CRMTimelineEvent
                                date={action.children.action.occurred_at}
                                eventDescription={`Raised with the ombudsman`}
                                onClickDelete={() => props.onDeleteRaisedWithOmbudsman(action)}
                            />,
                        onAssignmentAction: (action) =>
                            <CRMTimelineEvent
                                date={action.children.action.occurred_at}
                                eventDescription={`Re-assigned to ${action.children.action.assigned_to}`}
                            />,
                        onNoteAction: (action) =>
                            <CRMTimelineTextItem
                                isPinned={action.children.action.is_pinned}
                                date={action.children.action.occurred_at}
                                createdByUserName={action.children.action.created_by}
                                text={action.children.action.note}
                                onClickDelete={() => props.onDeleteNote(action)}
                                onClickPin={() => props.onToggleNotePin(action.children.toggle_pin_form)}
                            />,
                        onEmailAction: (action) => 
                            <CRMTimelineEmail
                                date={action.children.action.occurred_at}
                                senderName={action.children.action.sent_from}
                                snippet={action.children.action.subject}
                                onEmailOpen={() => props.onOpenEmail(action)}
                            />,
                        onPhoneCallAction: (action) =>
                            <CRMTimelinePhoneCall
                                date={action.children.action.occurred_at}
                                callToName={action.children.action.call_to}
                                callFromName={action.children.action.call_from}
                            />,
                        onSentimentAction: (action) =>
                            <CRMTimelineSentiment
                                date={action.children.action.occurred_at}
                                sentiment={action.children.action.sentiment}
                                changedBy={action.children.action.changed_by}
                                onClickDelete={() => props.onDeleteSentiment(action)}
                            />,
                        onDeferAction: (action) =>
                            <CRMTimelineDefer
                                jobName="Review unhappy satisfaction job"
                                createdAt={action.children.action.occurred_at}
                                deferDate={action.children.action.defer_till}
                            />,
                        onDefault: () => <div></div>
                    }))
                }
            />
        </Background>
    );
};
