import * as t from "io-ts";
import * as JsonResponse1 from "./JsonResponse1";
import * as Enquiry5 from "./Enquiry5";

export const codec = JsonResponse1.createCodec(
    t.array(Enquiry5.codec),
    t.type({}),
);

export type T = t.TypeOf<typeof codec>;

export const newDefault = (): T => ({
    data: [],
    meta: {},
});
