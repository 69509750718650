import React from "react";
import { array, option, eq } from "fp-ts";
import * as Listing3 from "./../../../../domain/models/Listing3";
import * as ListingChainStatus1 from "./../../../../domain/models/ListingChainStatus1";
import * as PriceQualifier1 from "./../../../../domain/models/PriceQualifier1";
import { CRMParagraph } from "../Simple/CRMParagraph/CRMParagraph";
import { CRMSpacer } from "../CRMSpacer/CRMSpacer";
import { pipe } from "fp-ts/lib/function";

type TSectionName = "looking-for"
    | "view-on-offers"
    | "chain-status"
;

type TCRMSellerKeyInfoComponent = {
    listing: Listing3.T;
    visibleSections?: Array<TSectionName>;
};

export class CRMSellerKeyInfoComponent extends React.Component<React.PropsWithChildren<TCRMSellerKeyInfoComponent>> {

    getPriceQualifierText = (): string =>  this.props.listing.price_qualifier ?
        PriceQualifier1.toString(this.props.listing.price_qualifier) :
        "Unknown"
    ;

    getViewOnOffersText = (): string =>
        this.props.listing.sellers_view_on_offers ||
        "Unknown"
    ;

    isSectionVisible = (sectionName: TSectionName, visibleSections?: Array<TSectionName>): boolean =>  pipe(
        visibleSections,
        option.fromPredicate((sections) => !!sections && sections.length > 0),
        option.fold(
            () => true,
            (sections) => array.elem(eq.eqString)(sectionName, sections as Array<TSectionName>)
        )
    );

    public render (): JSX.Element {
        return (
            <div>
                {/* LOOKING FOR */}
                {this.isSectionVisible("looking-for", this.props.visibleSections) &&
                    <>
                        <CRMParagraph>
                            <strong>Looking for:</strong>
                            &nbsp;
                            {this.getPriceQualifierText()}
                        </CRMParagraph>
                        <CRMSpacer size="medium" />
                    </>
                }

                {/* VIEW ON OFFERS */}
                {this.isSectionVisible("view-on-offers", this.props.visibleSections) &&
                    <>
                        <CRMParagraph>
                            <strong>Views on offers:</strong>
                        </CRMParagraph>
                        <CRMParagraph>
                            {this.getViewOnOffersText()}
                        </CRMParagraph>
                        <CRMSpacer size="medium" />
                    </>
                }

                {/* CHAIN STATUS */}
                {this.isSectionVisible("chain-status", this.props.visibleSections) &&
                    <>
                        <CRMParagraph>
                            <strong>Chain status:</strong>
                            &nbsp;
                            {ListingChainStatus1.toString(this.props.listing.chain_status)}
                        </CRMParagraph>
                    </>
                }
            </div>
        );
    }
}
