import { array } from "fp-ts";
import { pipe } from "fp-ts/lib/function";
import React, { useState } from "react";
import { TCasePropertyIdAddressAndTitleNumber } from "../../../../domain/codecs/CaseProperty";
import { TCaseChargeForm, TCreateCaseChargeForm } from "../../../../domain/codecs/form/CaseChargesAndRestrictionForm";
import { TCaseChargeRedemptionCreateForm, TCaseChargeRedemptionForm, TCaseChargeRedemptionUploadForm } from "../../../../domain/codecs/form/CaseChargeRedemptionForm";
import { doesErrorCodeExist, doesErrorKeyExist } from "../../../../shared/src/codecs/errors";
import { CRMSpacing } from "../../models/CRMSpacing";
import { SpacingColumn } from "../BuildingBlocks/SpacingColumn";
import { CRMVerticalTabs } from "../Complex/CRMVerticalTabs/CRMVerticalTabs";
import { CRMButtonIcon } from "../CRMButtonIcon/CRMButtonIcon";
import { CRMCardOutsidePopupFormSubmit } from "../CRMCardOutsidePopupFormSubmit/CRMCardOutsidePopupFormSubmit";
import { CRMDropdownComponent } from "../CRMDropdownComponent/CRMDropdownComponent";
import { CRMFormButtonIconWithLabel } from "../CRMFormButtonIconWithLabel/CRMFormButtonIconWithLabel";
import CRMInputGeneralComponent from "../CRMInputs/CRMInputGeneralComponent/CRMInputGeneralComponent";
import CRMInputLabelAndErrorWrapComponent from "../CRMInputLabelAndErrorWrapComponent/CRMInputLabelAndErrorWrapComponent";
import { CRMNoticeBoxComponent } from "../CRMNoticeBoxComponent/CRMNoticeBoxComponent";
import { CRMParagraph } from "../Simple/CRMParagraph/CRMParagraph";
import { CRMRadioListComponent } from "../CRMInputs/CRMRadioListComponent/CRMRadioListComponent";
import { CRMSpacingRow } from "../CRMSpacingRow/CRMSpacingRow";
import CRMTextAreaComponent from "../CRMTextAreaComponent/CRMTextAreaComponent";
import { CRMTitleSubSection } from "../CRMTitleSubSection/CRMTitleSubSection";
import { enumToIDropdownOptions } from "../../functions/enumToIDropdownOptions";
import { TitleEntrySection } from "../../../../domain/codecs/TitleEntrySection";
import { snakeCaseToCopyText } from "../../../../shared/src/util";
import { CRMCaseChargeRedemptionUploads } from "../Complex/CRMCaseChargeRedemptionUploads/CRMCaseChargeRedemptionUploads";
import { CRMFormErrorComponent } from "../CRMFormErrorComponent/CRMFormErrorComponent";
import { CaseChargeType } from "../../../../domain/codecs/CaseCharge";

export const CRMCaseCharges = React.memo((props: React.PropsWithChildren<{
    chargeForms: Array<TCaseChargeForm>,
    createChargeForm: TCreateCaseChargeForm,
    propertiesAvailableForAssociation: Array<TCasePropertyIdAddressAndTitleNumber>,
    expectedCompletionDate: string | null,
    onCreateNewCharge: (value: TCreateCaseChargeForm) => void;
    onUpdateCharge: (value: TCaseChargeForm) => void;
    onDeleteCharge: (value: TCaseChargeForm) => void;
    onUpdateNewChargeRedemption: (value: TCaseChargeRedemptionCreateForm) => void;
    onSubmitNewChargeRedemption: (value: TCaseChargeRedemptionCreateForm) => void;
    onUploadNewChargeRedemption: (value: TCaseChargeRedemptionUploadForm) => void;
    onDeleteChargeRedemption: (value: TCaseChargeRedemptionForm) => void;
}>): JSX.Element => {
    const [deletingChargeForm, setDeletingChargeForm] = useState<TCaseChargeForm | null>(null);

    return <>
        <CRMVerticalTabs
            tabs={pipe(
                props.chargeForms,
                array.map((form) => ({
                    tabLabel: form.edited.beneficiary || "Unnamed Charge",
                    content: <CRMCaseChargesTabContent
                        key={form.edited.id}
                        chargeForm={form}
                        createRedemptionStatementForm={form.children.redemption_statement_create_form}
                        propertiesAvailableForAssociation={props.propertiesAvailableForAssociation}
                        expectedCompletionDate={props.expectedCompletionDate}
                        onUpdateCharge={props.onUpdateCharge}
                        onUpdateNewChargeRedemption={props.onUpdateNewChargeRedemption}
                        onSubmitNewChargeRedemption={props.onSubmitNewChargeRedemption}
                        onUploadNewChargeRedemption={props.onUploadNewChargeRedemption}
                        onDeleteChargeRedemption={props.onDeleteChargeRedemption}
                    />,
                    tabOptions: [{
                        label: "Delete",
                        onClick: () => setDeletingChargeForm(form),
                    }],
                }))
            )}
            afterTabsElement={
                <CRMFormButtonIconWithLabel
                    formStatus="requiresSubmission"
                    ButtonElement={(buttonProps) => <CRMButtonIcon variant="primary" {...buttonProps} />}
                    icon="add"
                    label="Add new charge"
                    onClick={() => props.onCreateNewCharge(props.createChargeForm)}
                />
            }
        />

        {/* DELETE CHARGE POPUP */}
        <CRMCardOutsidePopupFormSubmit
            isOpen={deletingChargeForm !== null}
            title="Are you sure?"
            context="warning"
            closeText="Cancel"
            ctaText="Yes, delete"
            formStatus="requiresSubmission"
            validationErrors={[]}
            onClose={() => setDeletingChargeForm(null)}
            onCTA={() => {
                if (deletingChargeForm !== null) {
                    props.onDeleteCharge(deletingChargeForm)
                }
                setDeletingChargeForm(null);
            }}
        >
            <CRMParagraph>
                Are you sure you want to delete this title charge along with any associated redemption statements?
            </CRMParagraph>
        </CRMCardOutsidePopupFormSubmit>
    </>;
});

const CRMCaseChargesTabContent = (props: {
    chargeForm: TCaseChargeForm,
    createRedemptionStatementForm: TCaseChargeRedemptionCreateForm,
    propertiesAvailableForAssociation: Array<TCasePropertyIdAddressAndTitleNumber>,
    expectedCompletionDate: string | null,
    onUpdateCharge: (value: TCaseChargeForm) => void,
    onUpdateNewChargeRedemption: (value: TCaseChargeRedemptionCreateForm) => void;
    onSubmitNewChargeRedemption: (value: TCaseChargeRedemptionCreateForm) => void;
    onUploadNewChargeRedemption: (value: TCaseChargeRedemptionUploadForm) => void;
    onDeleteChargeRedemption: (value: TCaseChargeRedemptionForm) => void,
}): JSX.Element => {
    const accountNumberNotSet = (): boolean =>
        props.chargeForm.edited.account_number === "";

    const newChargeOrderInUse = (): boolean =>
        doesErrorKeyExist("edited.charge_order", props.chargeForm.validationErrors);

    const hasPropertiesAvailableForAssociation = (): boolean =>
        props.propertiesAvailableForAssociation.length > 0;

    const clientHasSubmittedAnswers = (): boolean =>
        props.chargeForm.children.client_submitted_account_numbers_at !== null;

    const shouldShowAccountNumberMayChangeWarning = (): boolean =>
        !clientHasSubmittedAnswers() && !accountNumberNotSet();

    const hasAtLeastOneUploadedRedemptionStatement =
        pipe(
            props.chargeForm.children.redemption_statement_forms,
            array.filter(({edited}) => edited.download_token !== ""),
            (a) => a.length > 0,
        );

    const hasInUseError =
        props.chargeForm.status === "validationError"
        && doesErrorCodeExist("InUse", props.chargeForm.validationErrors);

    return <SpacingColumn spacing={CRMSpacing.LARGE}>
        <SpacingColumn spacing={CRMSpacing.MEDIUM}>
            {/* IN USE ERROR */}
            {hasInUseError &&
                <CRMFormErrorComponent
                    errorMessage="This charge can not be deleted yet as there are restrictions related to it. First go to the restriction and remove it's link to this charge, then try again."
                />
            }

            {/* TYPE */}
            <CRMInputLabelAndErrorWrapComponent label="Type">
                <CRMDropdownComponent
                    value={props.chargeForm.edited.type}
                    options={enumToIDropdownOptions(
                        CaseChargeType.values,
                        snakeCaseToCopyText,
                    )}
                    displayError={false}
                    onChange={(type) => props.onUpdateCharge({
                        ...props.chargeForm,
                        edited: {
                            ...props.chargeForm.edited,
                            type,
                        },
                    })}
                />
            </CRMInputLabelAndErrorWrapComponent>

            {/* BENEFICIARY */}
            <CRMInputLabelAndErrorWrapComponent
                label="Beneficiary"
                guidance={`Use the simplest name for this charge so the client will understand, e.g.: "Santander".`}
            >
                <CRMInputGeneralComponent
                    inputType="text"
                    value={props.chargeForm.edited.beneficiary}
                    displayError={false}
                    onChange={(beneficiary) => props.onUpdateCharge({
                        ...props.chargeForm,
                        edited: {
                            ...props.chargeForm.edited,
                            beneficiary,
                        },
                    })}
                />
            </CRMInputLabelAndErrorWrapComponent>

            {shouldShowAccountNumberMayChangeWarning() && <CRMNoticeBoxComponent>
                This account number may still change as the client has not yet confirmed it is correct.
            </CRMNoticeBoxComponent>}

            {/* ACCOUNT NUMBER */}
            <CRMInputLabelAndErrorWrapComponent
                label="Account number"
                guidance={accountNumberNotSet()
                    ? `We'll contact the client to provide this but you can enter it if you already know it.`
                    : ""
                }
                labelFontWeight="medium"
            >
                <CRMInputGeneralComponent
                    inputType="text"
                    value={props.chargeForm.edited.account_number}
                    displayError={false}
                    onChange={(account_number) => props.onUpdateCharge({
                        ...props.chargeForm,
                        edited: {
                            ...props.chargeForm.edited,
                            account_number,
                        },
                    })}
                />
            </CRMInputLabelAndErrorWrapComponent>

            {hasAtLeastOneUploadedRedemptionStatement &&
                <>
                    {/* IS THERE AN EARLY REPAYMENT CHARGE? */}
                    <CRMInputLabelAndErrorWrapComponent label="Is there any Early Repayment Charge (ERC)?">
                        <CRMSpacingRow spacing="tiny" childSize="auto auto">
                            <CRMRadioListComponent<boolean, boolean>
                                value={props.chargeForm.edited.has_early_repayment_charge}
                                direction="row"
                                options={[
                                    {
                                        value: true,
                                        text: "Yes",
                                    },
                                    {
                                        value: false,
                                        text: "No",
                                    },
                                ]}
                                onChange={(has_early_repayment_charge) => props.onUpdateCharge({
                                    ...props.chargeForm,
                                    edited: {
                                        ...props.chargeForm.edited,
                                        has_early_repayment_charge,
                                    },
                                })}
                            />
                            <CRMButtonIcon
                                variant="tertiary"
                                icon="backspace"
                                disabledColour="neutral-4"
                                disabled={props.chargeForm.edited.has_early_repayment_charge === null}
                                onClick={() => props.onUpdateCharge({
                                    ...props.chargeForm,
                                    edited: {
                                        ...props.chargeForm.edited,
                                        has_early_repayment_charge: null,
                                    },
                                })}
                            />
                        </CRMSpacingRow>
                    </CRMInputLabelAndErrorWrapComponent>

                    {/* CLIENT NOTIFIED OF ERC? */}
                    {props.chargeForm.edited.has_early_repayment_charge &&
                        <CRMInputLabelAndErrorWrapComponent
                            label="Has the client been notified of the ERC?"
                            guidance="It may not be necessary to inform the client of the exact figure as this may change depending on time of redemption."
                        >
                            <CRMRadioListComponent<boolean, boolean>
                                value={props.chargeForm.edited.client_notified_of_early_repayment_charge}
                                direction="row"
                                options={[
                                    {
                                        value: true,
                                        text: "Yes",
                                    },
                                    {
                                        value: false,
                                        text: "No",
                                    },
                                ]}
                                onChange={(client_notified_of_early_repayment_charge) => props.onUpdateCharge({
                                    ...props.chargeForm,
                                    edited: {
                                        ...props.chargeForm.edited,
                                        client_notified_of_early_repayment_charge,
                                    },
                                })}
                            />
                        </CRMInputLabelAndErrorWrapComponent>
                    }
                </>
            }

            {/* NO PROPERTIES AVAILABLE FOR ASSOCIATION */}
            {!hasPropertiesAvailableForAssociation() && <CRMNoticeBoxComponent>
                There are no Titles available for selection. Check the "Property details" section contains at least one property with it's "Title number" set.
            </CRMNoticeBoxComponent>}

            {/* PROPERTY SELECT FOR ASSOCIATION */}
            <CRMInputLabelAndErrorWrapComponent
                label="Title"
            >
                <CRMDropdownComponent
                    options={
                        pipe(
                            props.propertiesAvailableForAssociation,
                            array.map((property) => ({
                                value: property.id,
                                label: `${property.title_number} (${property.address})`
                            }))
                        )
                    }
                    value={props.chargeForm.edited.cases_properties_id || ""}
                    displayError={newChargeOrderInUse()}
                    onChange={(cases_properties_id) => props.onUpdateCharge({
                        ...props.chargeForm,
                        edited: {
                            ...props.chargeForm.edited,
                            cases_properties_id: cases_properties_id || null,
                        },
                    })}
                />
            </CRMInputLabelAndErrorWrapComponent>

            {/* SECTION */}
            <CRMInputLabelAndErrorWrapComponent
                label="Which section contains this charge?"
                displayError={newChargeOrderInUse()}
            >
                <CRMDropdownComponent
                    value={props.chargeForm.edited.section}
                    options={enumToIDropdownOptions(
                        TitleEntrySection.values,
                        snakeCaseToCopyText,
                    )}
                    onChange={(section) => props.onUpdateCharge({
                        ...props.chargeForm,
                        edited: {
                            ...props.chargeForm.edited,
                            section,
                        },
                    })}
                    displayError={newChargeOrderInUse()}
                />
            </CRMInputLabelAndErrorWrapComponent>

            {/* CHARGE ORDER */}
            <CRMInputLabelAndErrorWrapComponent
                label="What is this charge's number?"
                guidance="Use the number next to this charge as it is shown in the Title."
                displayError={newChargeOrderInUse()}
                errorMessage="A different charge in this section is using this number."
            >
                <CRMInputGeneralComponent
                    inputType="numericString"
                    value={
                        props.chargeForm.edited.charge_order === null
                            ? undefined
                            : props.chargeForm.edited.charge_order.toString()
                    }
                    onChange={(charge_order) => props.onUpdateCharge({
                        ...props.chargeForm,
                        edited: {
                            ...props.chargeForm.edited,
                            charge_order: charge_order === ""
                                ? null
                                : parseInt(charge_order),
                        },
                    })}
                    displayError={newChargeOrderInUse()}
                />
            </CRMInputLabelAndErrorWrapComponent>

            {/* CHARGE TEXT */}
            <CRMInputLabelAndErrorWrapComponent
                label="Charge"
                guidance="Enter the full charge paragraph as it appears in the Title"
            >
                <CRMTextAreaComponent
                    value={props.chargeForm.edited.charge_text}
                    onChange={(charge_text) => props.onUpdateCharge({
                        ...props.chargeForm,
                        edited: {
                            ...props.chargeForm.edited,
                            charge_text,
                        },
                    })}
                />
            </CRMInputLabelAndErrorWrapComponent>

            {/* REDEMPTION STATEMENTS */}
            <CRMTitleSubSection>
                Redemption statements
            </CRMTitleSubSection>

            <CRMCaseChargeRedemptionUploads
                beneficiary={props.chargeForm.edited.beneficiary}
                createRedemptionStatementForm={props.createRedemptionStatementForm}
                redemptionStatementFileForms={props.chargeForm.children.redemption_statement_forms}
                deferRedemptionStatementJobValue={props.chargeForm.edited.get_redemption_statement_defer}
                expectedCompletionDate={props.expectedCompletionDate}
                onDeleteFile={props.onDeleteChargeRedemption}
                onUpdateNewRedemption={props.onUpdateNewChargeRedemption}
                onSubmitNewRedemption={() => props.onSubmitNewChargeRedemption(props.chargeForm.children.redemption_statement_create_form)}
                onSubmitUpload={props.onUploadNewChargeRedemption}
                onDeferRedemptionStatementJobChange={(get_redemption_statement_defer) => props.onUpdateCharge({
                    ...props.chargeForm,
                    edited: {
                        ...props.chargeForm.edited,
                        get_redemption_statement_defer,
                    },
                })}
            />
        </SpacingColumn>
    </SpacingColumn>;
};
