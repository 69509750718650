import React from "react";
import { TPixelSize } from "../../../../models/StringLiterals";

export const FPSimpleLogo = (props: {
    width: TPixelSize,
}): JSX.Element =>
    <svg
        width={props.width}
        viewBox="0 0 62 33"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M39.5801 29.8821C39.2486 28.259 39.3047 27.1866 39.3241 25.521C39.3663 21.8774 40.202 18.1674 41.8078 14.4938C44.5863 8.13873 48.917 4.67625 51.0415 3.14083C51.4498 2.84557 53.8108 8.58395 49.3602 12.7383C49.3036 12.7878 49.2481 12.8379 49.1944 12.8894C49.149 12.9296 49.2302 12.8399 49.1944 12.8894C49.251 12.8393 49.3062 12.7887 49.3602 12.7383C50.6679 11.6003 52.6335 11.0503 52.6335 11.0503C53.1785 12.6815 52.6835 16.533 51.5807 18.8639C51.0519 19.9812 50.2474 20.8918 49.0484 21.7304C48.3497 22.2193 47.5923 22.6319 46.8594 23.0314C46.1532 23.4163 45.4226 23.8144 44.7742 24.268C43.6018 25.088 42.8808 25.9589 42.4369 27.0919C42.4231 27.1278 42.4066 27.1624 42.3884 27.1962C42.1188 27.6932 41.963 28.6282 42.0092 29.8821H39.5801ZM50.2916 5.6657C48.5268 7.35224 45.6608 10.2874 43.4946 15.2425C42.2956 17.9846 41.2504 21.4855 41.1871 25.4316C41.7705 24.4023 42.5947 23.5316 43.7235 22.7425C44.4557 22.23 45.2319 21.8072 45.9827 21.3978C46.7028 21.0056 47.3831 20.6346 47.9977 20.2048C48.9224 19.5581 49.9833 18.223 50.3962 17.424C50.8358 16.5726 51.5449 14.5649 51.4363 12.8804C50.2 13.1285 46.048 16.432 46.048 16.432C46.048 16.432 45.8399 15.0736 47.2264 13.1573C48.3274 11.6353 51.1669 8.56502 50.2916 5.6657Z"
            fill="white"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M61.737 33V0H0V33H61.737ZM30.8223 1.9393V31.0578H59.8096V1.9393H30.8223ZM17.6767 1.9393C18.8471 12.6462 23.462 22.2291 30.2963 29.3019C29.7299 29.267 29.1578 29.2437 28.5798 29.232C24.5486 29.1593 20.6358 29.7183 16.9283 30.8248L17.5236 1.9393H17.6767ZM16.7896 3.1972C16.7896 3.1972 12.6023 14.3816 12.6023 20.8547C12.6023 24.1014 12.9953 27.2433 13.7293 30.2163C12.504 29.5116 11.1747 29.1272 9.78765 29.1272C8.21272 29.1272 6.71291 29.6222 5.3605 30.5133C5.93268 26.623 6.89209 22.6833 8.29941 18.8105C10.4032 13.013 13.371 7.75425 16.7896 3.1972Z"
            fill="white
        "/>
    </svg>;
