import React from "react";
import { FrontOutsidePopupBasic, TFrontOutsidePopupBasic } from "../FrontOutsidePopupBasic/FrontOutsidePopupBasic";
import { SpacingColumn } from "../../../BuildingBlocks/SpacingColumn";
import { SpacingRow } from "../../../BuildingBlocks/SpacingRow";
import { FrontFormPrimaryButton } from "../../Simple/FrontFormPrimaryButton/FrontFormPrimaryButton";
import { FrontFormSecondaryButton } from "../../Simple/FrontFormSecondaryButton/FrontFormSecondaryButton";
import { FrontSpacing } from "../../../../models/FrontSpacing";

interface TFrontPopupPrompt extends TFrontOutsidePopupBasic {
    ctaDisabled?: boolean;
    closeText?: string;
    ctaText?: string;
    onClose?: () => void;
    onCTA?: () => void;
};

export const FrontPopupPrompt = (props: React.PropsWithChildren<TFrontPopupPrompt>): JSX.Element => {
    return (
        <FrontOutsidePopupBasic
            title={props.title}
            iconName={props.iconName}
            isOpen={props.isOpen}
            onClose={props.onClose}
        >
            <SpacingColumn spacing={FrontSpacing.LARGE_1}>
                {props.children}

                <SpacingRow spacing={FrontSpacing.SMALL_3}>
                    {/* CTA */}
                    {typeof props.onCTA === "function" && props.ctaText &&
                        <FrontFormPrimaryButton
                            label={props.ctaText}
                            onClick={props.onCTA}
                        />
                    }

                    {/* CLOSE */}
                    {typeof props.onClose === "function" &&
                        <FrontFormSecondaryButton
                            label={props.closeText || "Close"}
                            onClick={props.onClose}
                        />
                    }
                </SpacingRow>
            </SpacingColumn>
        </FrontOutsidePopupBasic>
    );
}
