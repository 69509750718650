import React from "react";
import { TCasesKeyDate } from "../../../../../../domain/codecs/form/CasesForm";
import { SpacingRow } from "../../../BuildingBlocks/SpacingRow";
import { SpacingColumn } from "../../../BuildingBlocks/SpacingColumn";
import { toDateFormat1 } from "../../../../../../shared/src/dateTime";
import { snakeCaseToCopyText } from "../../../../../../shared/src/util";
import { pipe } from "fp-ts/lib/pipeable";
import { CRMSpacing } from "../../../../models/CRMSpacing";
import { CRMColors } from "../../../../models/CRMColors";
import { THexColor } from "../../../../models/StringLiterals";
import { getDetailsOfDateDifference, isToday } from "../../../../../../shared/src/utilsByDomain/dateTime";
import { DateTime } from "luxon";
import { dateToSimpleFormat } from "../../../../../../shared/src/utilsByDomain/dateTime/dateToSimpleFormat";
import { getDateDifferenceInDays } from "../../../../../../shared/src/utilsByDomain/dateTime/getDateDifferenceInDays";
import { makeJobLinkOutOfRouteAction } from "../../../CRMJobs/CRMJobsOverlay/CRMJobsCardLinkGenerators";
import { createChangeRouteAction } from "../../../../state/router/createChangeRouteAction";
import { Routes } from "../../../../state/router/routerRoutes";

type TCasesKeyDateProps = {
    dates: Array<TCasesKeyDate>;
};

export const CRMCasesKeyDate = (props: React.PropsWithChildren<TCasesKeyDateProps>): JSX.Element => {
    return (
        <div className="crm-cases-key-date">
            {props.dates.map((keyDate, index) => (
                <Card
                    key={index}
                    isFirst={index === 0}
                    keyDate={keyDate}
                />
            ))}
        </div>
    );
};


type TCardProps = {
    isFirst: boolean;
    keyDate: TCasesKeyDate;
}

const Card = (props: TCardProps) => {

    let CaseLink = makeJobLinkOutOfRouteAction(
        createChangeRouteAction(
            "VIEW_CRM_LEGAL_CASE", 
            { caseId: props.keyDate.id },
            { visible_tab: "" },
            new Routes().blockParams.VIEW_CRM_LEGAL_CASE,
        )
    );

    const TypeCopy = pipe(
        props.keyDate.type,
        (text) => text.replace('_date', ''),
        snakeCaseToCopyText,
    );

    const onClick = () => window.open(CaseLink, "_blank");

    return (
        <div 
            className="crm-cases-key-date__card"
            onClick={onClick}
        >
            <SpacingColumn spacing={CRMSpacing.SMALL}>
                {props.isFirst &&
                    <div className="crm-cases-key-date__card-header">
                        <SpacingRow justifyContent="space-between">
                            <CardText weight={700}>
                                {toDateFormat1(props.keyDate.date)}
                            </CardText>
                            
                            {!isToday(props.keyDate.date) &&
                                <DateHelpPill date={props.keyDate.date} />
                            }
                        </SpacingRow>
                    </div>
                }

                <SpacingColumn spacing={CRMSpacing.TINY}>
                    <CardText>
                        {TypeCopy}
                    </CardText>
                    <CardText weight={500}>
                        {props.keyDate.address}
                    </CardText>
                </SpacingColumn>
            </SpacingColumn>
        </div>
    )
}


type TCardTextProps = {
    weight?: 400 | 500 | 700;
    color?: THexColor;
}

const CardText = (props: React.PropsWithChildren<TCardTextProps>): JSX.Element => {
    return (
        <div 
            className="crm-cases-key-date__text"
            style={{
                fontWeight: props.weight,
                color: props.color
            }}
        >
            {props.children}
        </div>
    )
}


type TTypeDateHelpPillProps = {
    date: string;
}

const DateHelpPill = (props: TTypeDateHelpPillProps): JSX.Element => {

    const DaysApart = pipe(
        DateTime.local().toISO(),
        getDateDifferenceInDays(props.date)
    );
    const DateDifferenceCopy = dateToSimpleFormat(props.date);
    const IsLate = DaysApart > 0;
    const Mode = IsLate ? "late" : "upcoming";

    return (
        <div 
            className={`
                crm-cases-key-date__tiny-pill
                crm-cases-key-date__tiny-pill--${Mode}
            `}
        >
            <CardText 
                weight={700} 
                color={CRMColors.NEUTRAL_0}
            >
                {DateDifferenceCopy} {IsLate && "late"}
            </CardText>
        </div>
    );
}