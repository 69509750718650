import React, { ReactFragment } from "react";
// import "./FrontAnchorButtonSecondary.scss";

interface IFrontAnchorButtonSecondary {
    label: string | ReactFragment;
    fullWidth?: boolean;
    href: string;
}

class FrontAnchorButtonSecondary extends React.Component<React.PropsWithChildren<IFrontAnchorButtonSecondary>> {
    public render (): JSX.Element {
        return (
            <a
                className={
                    `front-anchor-button-secondary` +
                    (this.props.fullWidth ? " front-anchor-button-secondary--full-width" : "")
                }
                href={this.props.href}
            >
                {this.props.label}
            </a>
        );
    }
}

export default FrontAnchorButtonSecondary;
