import { pipe } from "fp-ts/lib/function";
import React, { useState } from "react";
import { TCaseMemberCreateIdCheckForm, TCaseMemberIdCheckForm } from "../../../../domain/codecs/form/CaseMemberForm";
import { CRMDropdownComponent } from "../CRMDropdownComponent/CRMDropdownComponent";
import { CRMSpacingColumn } from "../CRMSpacingColumn/CRMSpacingColumn";
import { CRMSpacingRow } from "../CRMSpacingRow/CRMSpacingRow";
import { array, ord } from "fp-ts";
import { Ord } from "fp-ts/lib/Date";
import { DateTime } from "luxon";
import { CRMCodecEditForm } from "../CRMCodecEditForm/CRMCodecEditForm";
import { CRMSpacer } from "../CRMSpacer/CRMSpacer";
import { CRMLink } from "../CRMLink/CRMLink";
import { WeightBold } from "../WeightBold/WeightBold";
import { CRMPadding } from "../Simple/CRMPadding/CRMPadding";
import { TextCenter } from "../TextCenter/TextCenter";
import { CRMParagraph } from "../Simple/CRMParagraph/CRMParagraph";
import { CRMFormButtonIconWithLabel } from "../CRMFormButtonIconWithLabel/CRMFormButtonIconWithLabel";
import { CRMButtonIcon } from "../CRMButtonIcon/CRMButtonIcon";
import { UsersIdCheck1, UsersIdCheck2, UsersIdCheck5, UsersIdCheckAddress } from "../../../../domain/codecs/UsersIdCheck";
import { CRMAutosaveIndicatorWrap } from "../CRM/CRMAutosaveIndicatorWrap/CRMAutosaveIndicatorWrap";
import { FormStatus_highestPriority } from "../../../../shared/src/codecs/codec";
import { CRMFormButtonIcon } from "../CRMFormButtonIcon/CRMFormButtonIcon";
import { CRMCaseMemberIdChecksImageComponent } from "../CRMCaseMemberIdChecksImageComponent/CRMCaseMemberIdChecksImageComponent";
import { CRMTitleSubSection } from "../CRMTitleSubSection/CRMTitleSubSection";
import { CRMNoticeBoxComponent } from "../CRMNoticeBoxComponent/CRMNoticeBoxComponent";

type TProps = {
    idChecks: Array<TCaseMemberIdCheckForm>;
    newIdCheckForm: TCaseMemberCreateIdCheckForm;
    onNew: (p: TCaseMemberCreateIdCheckForm) => void;
    onChange: (idCheck: TCaseMemberIdCheckForm) => void;
};

export const CRMCaseMemberIdChecksComponent = (props: React.PropsWithChildren<TProps>): JSX.Element => {
    const [formIdSelected, setFormIdSelected] = useState<string>("");

    const getIdChecksSorted = () =>
        pipe(
            props.idChecks,
            array.sortBy([ord.contramap((idCheckForm: TCaseMemberIdCheckForm) => DateTime.fromISO(idCheckForm.children.created_at).toJSDate())(Ord)]),
            array.reverse,
        );

    const getSelectedIdCheckId = () =>
        (
            formIdSelected
            && pipe(
                getIdChecksSorted(),
                array.map((form) => form.edited.id)
            ).includes(formIdSelected)
        )
            ? formIdSelected
            : getIdChecksSorted()[0].edited.id;

    return (
        props.idChecks.length > 0
            ? <CRMAutosaveIndicatorWrap status={
                pipe(
                    props.idChecks,
                    array.map((form) => form.status),
                    FormStatus_highestPriority,
                )
            }>
                <CRMSpacingColumn spacing="medium">
                    <CRMSpacingRow childSize="2fr 1fr">
                        <CRMDropdownComponent
                            options={pipe(
                                getIdChecksSorted(),
                                array.mapWithIndex((i, idCheckForm) => ({
                                    value: idCheckForm.edited.id,
                                    label: `ID check #${props.idChecks.length - i} - ${DateTime.fromISO(idCheckForm.children.created_at).setZone("Europe/London").toFormat("DD")}${idCheckForm.children.potential_fraud && idCheckForm.edited.potential_fraud_outcome === "unknown" ? " (Fraud risk)" : ""}`,
                                }))
                            )}
                            value={getSelectedIdCheckId()}
                            displayError={false}
                            onChange={setFormIdSelected}
                        />

                        <div>
                            <CRMFormButtonIcon
                                formStatus={
                                    (props.newIdCheckForm.status === "twoFactorRequired" || props.newIdCheckForm.status === "notFound" || props.newIdCheckForm.status === "unauthorised") ? "failure"
                                    : props.newIdCheckForm.status === "untouched" ? "requiresSubmission"
                                    : props.newIdCheckForm.status
                                }
                                ButtonElement={(p) => <CRMButtonIcon variant="primary" {...p} />}
                                icon="add"
                                onClick={() => {
                                    setFormIdSelected("");
                                    props.onNew(props.newIdCheckForm);
                                }}
                            />
                        </div>
                    </CRMSpacingRow>

                    <div>
                        {pipe(
                            props.idChecks,
                            array.mapWithIndex((i, form) =>
                                <div key={i}>
                                    {form.edited.id === getSelectedIdCheckId() && <>
                                        <CRMSpacingColumn spacing="medium">
                                            {form.children.potential_fraud && form.edited.potential_fraud_outcome !== "not_fraud" && <CRMNoticeBoxComponent>
                                                Stripe has reported this ID check may have used a fraudulent document or the person using the document either didn't
                                                look the same as the person shown in the document's picture or they used a recording of the person.
                                            </CRMNoticeBoxComponent>}

                                            {form.children.has_expired
                                                && form.edited.id_check_result_identity_document_status === "pass"
                                                && form.edited.id_check_result_address_status === "pass"
                                                && form.edited.id_check_result_peps_sanctions_status === "pass"
                                                && <CRMNoticeBoxComponent>
                                                    This ID check has expired.
                                                </CRMNoticeBoxComponent>}

                                            <CRMCodecEditForm
                                                codec={UsersIdCheck1}
                                                model={form.edited}
                                                validationErrors={form.validationErrors}
                                                onChange={(p) =>
                                                    props.onChange({
                                                        ...form,
                                                        edited: {
                                                            ...form.edited,
                                                            ...p,
                                                        }
                                                    })
                                                }
                                            />

                                            {/* ID Images */}
                                            {form.children.id_check_image_urls.length > 0 && <CRMSpacingRow spacing="medium" justifyContent="start">
                                                {pipe(
                                                    form.children.id_check_image_urls,
                                                    array.mapWithIndex((ii, imageUrl) =>
                                                        <CRMCaseMemberIdChecksImageComponent key={ii} imageUrl={imageUrl} />
                                                    ),
                                                )}
                                            </CRMSpacingRow>}

                                            <div>
                                                <CRMCodecEditForm
                                                    codec={UsersIdCheckAddress}
                                                    model={form.edited}
                                                    validationErrors={form.validationErrors}
                                                    onChange={(p) =>
                                                        props.onChange({
                                                            ...form,
                                                            edited: {
                                                                ...form.edited,
                                                                ...p,
                                                            }
                                                        })
                                                    }
                                                />
                                                {form.children.uploaded_proof_of_address_url && <>
                                                    <CRMSpacer size="tiny" />
                                                    <CRMLink
                                                        href={form.children.uploaded_proof_of_address_url}
                                                        linkStyle="normal"
                                                        target="_blank"
                                                    >
                                                        <WeightBold>Open uploaded proof of address</WeightBold>
                                                    </CRMLink>
                                                </>}
                                            </div>

                                            <CRMCodecEditForm
                                                codec={UsersIdCheck2}
                                                model={form.edited}
                                                validationErrors={form.validationErrors}
                                                onChange={(p) =>
                                                    props.onChange({
                                                        ...form,
                                                        edited: {
                                                            ...form.edited,
                                                            ...p,
                                                        }
                                                    })
                                                }
                                            />

                                            {form.children.potential_fraud && <>
                                                <CRMTitleSubSection>
                                                    Fraud review
                                                </CRMTitleSubSection>
                                                <CRMCodecEditForm
                                                    codec={UsersIdCheck5}
                                                    model={form.edited}
                                                    validationErrors={form.validationErrors}
                                                    onChange={(p) =>
                                                        props.onChange({
                                                            ...form,
                                                            edited: {
                                                                ...form.edited,
                                                                ...p,
                                                            }
                                                        })
                                                    }
                                                />
                                            </>}
                                        </CRMSpacingColumn>
                                        {form.children.id_check_result_peps_sanctions_report && <>
                                            <CRMSpacer size="tiny" />
                                            <CRMLink
                                                href={form.children.id_check_result_peps_sanctions_report}
                                                linkStyle="normal"
                                                target="_blank"
                                            >
                                                <WeightBold>Open report to investigate</WeightBold>
                                            </CRMLink>
                                        </>}
                                    </>}
                                </div>
                            ),
                        )}
                    </div>
                </CRMSpacingColumn>
            </CRMAutosaveIndicatorWrap>
            : <CRMSpacingRow
                spacing="large"
                alignItems="flex-start"
                childSize="even-fill"
            >
                <CRMPadding size="tiny">
                    <TextCenter>
                        <CRMParagraph>
                            Add a new <WeightBold>ID check</WeightBold> to get started.
                        </CRMParagraph>
                    </TextCenter>
                </CRMPadding>

                <div>
                    <CRMFormButtonIconWithLabel
                        formStatus={
                            (props.newIdCheckForm.status === "twoFactorRequired" || props.newIdCheckForm.status === "notFound" || props.newIdCheckForm.status === "unauthorised") ? "failure"
                            : props.newIdCheckForm.status === "untouched" ? "requiresSubmission"
                            : props.newIdCheckForm.status
                        }
                        ButtonElement={(p) => <CRMButtonIcon variant="primary" {...p} />}
                        icon="add"
                        label="New ID Check"
                        onClick={() => {
                            setFormIdSelected("");
                            props.onNew(props.newIdCheckForm);
                        }}
                    />
                </div>
            </CRMSpacingRow>
    );
};
