import React, { useState } from "react";
import { CRMColors } from "../../../models/CRMColors";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { BackgroundColour } from "../../BuildingBlocks/BackgroundColour";
import { MinHeight } from "../../BuildingBlocks/MinHeight";
import { Padding } from "../../BuildingBlocks/Padding";
import { SpacingColumn } from "../../BuildingBlocks/SpacingColumn";
import { SpacingRow } from "../../BuildingBlocks/SpacingRow";
import { Sticky } from "../../BuildingBlocks/Sticky";
import { CRMSingleViewHeader } from "../../Simple/CRMSingleViewHeader/CRMSingleViewHeader";
import { CRMBottomControlBar } from "../../CRMBottomControlBar/CRMBottomControlBar";
import { CRMButtonIcon } from "../../CRMButtonIcon/CRMButtonIcon";
import { useResolutionStatus } from "../../../hooks/useResolutionStatus";
import { TClientCompanyPartnershipContractForm, TClientCompanyPartnershipContractUploadForm, TClientCompanyPartnershipDeleteForm, TClientCompanyPartnershipForm, TClientCompanyPartnershipUserForm, TClientCompanyPartnershipUserPhoneNumberCreateForm, TClientCompanyPartnershipUserPhoneNumberDeleteForm, TClientCompanyPartnershipUserPhoneNumberForm, TClientCompanyPartnershipUserPhoneNumberMakePrimaryForm } from "../../../../../domain/codecs/form/ClientCompanyPartnershipForm";
import { CRMCompanyPartnerStatusBubble } from "../../Simple/CRMCompanyPartnerStatusBubble/CRMCompanyPartnerStatusBubble";
import { CRMTitleSubSection } from "../../CRMTitleSubSection/CRMTitleSubSection";
import { CRMCompanyPartnerCompanyInformation } from "../../Simple/CRMCompanyPartnerCompanyInformation/CRMCompanyPartnerCompanyInformation";
import { CRMCompanyPartnerAMLAndKYC } from "../CRMCompanyPartnerAMLAndKYC/CRMCompanyPartnerAMLAndKYC";
import { CRMCompanyPartnerPeople } from "../CRMCompanyPartnerPeople/CRMCompanyPartnerPeople";
import { CRMCompanyPartnerEditPopout } from "../CRMCompanyPartnerEditPopout/CRMCompanyPartnerEditPopout";
import { CRMConfirmationCountdown } from "../../Complex/CRMConfirmationCountdown/CRMConfirmationCountdown";
import { CRMBlockPopover } from "../../CRM/CRMBlock/CRMBlockPopover/CRMBlockPopover";

type TCompanyPartnerSinglePopupViewStatus = 
    "none"
    | "edit"
;

export const CRMCompanyPartnerSingleView = (props: React.PropsWithChildren<{
    form: TClientCompanyPartnershipForm;
    onChange: (form: TClientCompanyPartnershipForm) => void;
    onSave: (form: TClientCompanyPartnershipForm) => void;
    onClose: () => void;
    onDelete: (form: TClientCompanyPartnershipDeleteForm) => void;
    onAddressAutocomplete: (form: TClientCompanyPartnershipForm) => void;
    onUploadContract: (form: TClientCompanyPartnershipContractUploadForm) => void;
    onDeleteContract: (form: TClientCompanyPartnershipContractForm) => void;
    onChangeUser: (form: TClientCompanyPartnershipUserForm) => void;
    onSaveUser: (form: TClientCompanyPartnershipUserForm) => void;
    onDeleteUser: (form: TClientCompanyPartnershipUserForm) => void;
    onUserPhoneNumberChange: (form: TClientCompanyPartnershipUserPhoneNumberForm) => void;
    onUserNewPhoneNumber: (form: TClientCompanyPartnershipUserPhoneNumberCreateForm) => void;
    onUserPhoneNumberMakePrimary: (form: TClientCompanyPartnershipUserPhoneNumberMakePrimaryForm) => void;
    onUserPhoneNumberDelete: (form: TClientCompanyPartnershipUserPhoneNumberDeleteForm) => void;
}>): JSX.Element => {

    const [popupViewStatus, setPopupViewStatus] = useState<TCompanyPartnerSinglePopupViewStatus>("none");
    const {
        resolutionStatus,
        setResolution,
        resetResolution,
    } = useResolutionStatus<"none" | "deleting", {}>({
        defaultStatus: "none",
    });

    const onDownloadPartnershipContract = () =>
        window.open(props.form.children.partnership_contract_form?.children.download_url, "_blank")
    ;

    const onEdit = () => setPopupViewStatus("edit");

    const onDelete = () => setResolution("deleting")();

    const onSubmitDelete = () => {
        props.onDelete(props.form.children.delete_form);
        resetResolution();
    }

    return (
        <>
            {/* POPUP */}
            {popupViewStatus === "edit" &&
                <CRMBlockPopover
                    padding={CRMSpacing.MEDIUM}
                    height="745px" 
                    maxHeight="745px"
                >
                    <CRMCompanyPartnerEditPopout
                        form={props.form}
                        onChange={props.onChange}
                        onSave={props.onSave}
                        onAddressAutocomplete={props.onAddressAutocomplete}
                        onClose={() => setPopupViewStatus("none")}
                        onUploadContract={props.onUploadContract}
                        onDeleteContract={props.onDeleteContract}
                        onChangeUser={props.onChangeUser}
                        onSaveUser={props.onSaveUser}
                        onDeleteUser={props.onDeleteUser}
                        onUserPhoneNumberChange={props.onUserPhoneNumberChange}
                        onUserNewPhoneNumber={props.onUserNewPhoneNumber}
                        onUserPhoneNumberMakePrimary={props.onUserPhoneNumberMakePrimary}
                        onUserPhoneNumberDelete={props.onUserPhoneNumberDelete}
                    />
                </CRMBlockPopover>
            }

            {/* MAIN */}
            <div>
                {/* BODY */}
                <div>
                    <Sticky
                        zIndex={1}
                    >
                        <CRMSingleViewHeader 
                            title={props.form.original.name || "New company"}
                            onClick={props.onClose} 
                        />
                    </Sticky>
                    
                    <BackgroundColour colour={CRMColors.NEUTRAL_PAPER}>
                        <MinHeight height={"700px"}>
                            <Padding spacing={CRMSpacing.LARGE}>
                                <SpacingColumn spacing={CRMSpacing.LARGE}>
                                    
                                    {/* STATUS BUBBLE */}
                                    <div>
                                        <CRMCompanyPartnerStatusBubble form={props.form} />
                                    </div>
                                    
                                    {/* COMPANY INFORMATION */}
                                    <SpacingColumn spacing={CRMSpacing.MEDIUM}>
                                        <CRMTitleSubSection>
                                            Company Information
                                        </CRMTitleSubSection>

                                        <CRMCompanyPartnerCompanyInformation form={props.form} />
                                    </SpacingColumn>

                                    {/* AML & KYC */}
                                    <SpacingColumn spacing={CRMSpacing.MEDIUM}>
                                        <CRMTitleSubSection>
                                            AML & KYC
                                        </CRMTitleSubSection>

                                        <CRMCompanyPartnerAMLAndKYC form={props.form} />
                                    </SpacingColumn>

                                    {/* PEOPLE */}
                                    <SpacingColumn spacing={CRMSpacing.MEDIUM}>
                                        <CRMTitleSubSection>
                                            People
                                        </CRMTitleSubSection>

                                        <CRMCompanyPartnerPeople
                                            form={props.form}
                                            onChange={props.onChangeUser}
                                            onSave={props.onSaveUser}
                                            onDelete={props.onDeleteUser}
                                            onPhoneNumberChange={props.onUserPhoneNumberChange}
                                            onNewPhoneNumber={props.onUserNewPhoneNumber}
                                            onPhoneNumberMakePrimary={props.onUserPhoneNumberMakePrimary}
                                            onPhoneNumberDelete={props.onUserPhoneNumberDelete}
                                        />
                                    </SpacingColumn>
                                </SpacingColumn>
                            </Padding>
                        </MinHeight>
                    </BackgroundColour>
                </div>
                    
                {/* CONTROL BAR */}
                <CRMBottomControlBar>
                    {resolutionStatus === "deleting" &&
                        <CRMConfirmationCountdown
                            title="Deleting!"
                            iconName="archive"
                            primaryButtonMode="done"
                            onUndo={resetResolution}
                            onComplete={onSubmitDelete}
                        />
                    }

                    {/* CONTROLS */}
                    {resolutionStatus === "none" &&
                        <Padding 
                            type="custom"
                            width="100%" 
                            spacing={`${CRMSpacing.TINY} ${CRMSpacing.MEDIUM}`}
                        >
                            <SpacingRow spacing={CRMSpacing.SMALL}>
                                <CRMButtonIcon
                                    title="Archive company."
                                    variant="quaternary"
                                    icon="delete"
                                    onClick={onDelete}
                                />
                                
                                {props.form.children.partnership_contract_form && <CRMButtonIcon
                                        title="Download partnership contract."
                                        variant="quaternary"
                                        icon="can-download"
                                        onClick={onDownloadPartnershipContract}
                                    />
                                }

                                <CRMButtonIcon
                                    title="Edit company."
                                    variant="primary"
                                    icon="pencil"
                                    onClick={onEdit}
                                />
                            </SpacingRow>
                        </Padding>
                    }
                </CRMBottomControlBar>
            </div>
        </>
    );
};
