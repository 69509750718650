import React, { ReactNode, ReactNodeArray } from "react";
import Heading5 from "./../OldFront/Simple/Heading5Component/Heading5";

interface ICRMCardProps {
    title?: string;
    children?: ReactNode | ReactNodeArray;
}

class CRMCard extends React.Component<React.PropsWithChildren<ICRMCardProps>> {
    public render (): JSX.Element {
        return (
            <div className="crmCard">
                {this.props.title ? (
                    <div className="crmCard__title">
                        <Heading5 text={this.props.title}/>
                    </div>
                ): <></>}
                <div className="crmCard__content">
                    {this.props.children}
                </div>
            </div>
        );
    }
}

export default CRMCard;
