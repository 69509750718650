import React from "react";
import { CRMColors } from "../../../models/CRMColors";
import { CRMFontSizes } from "../../../models/CRMFontSizes";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { Background } from "../../BuildingBlocks/Background";
import { Padding } from "../../BuildingBlocks/Padding";
import { TextColor } from "../../BuildingBlocks/TextColor";
import { CRMParagraph } from "../../Simple/CRMParagraph/CRMParagraph";
import { WeightBold } from "../../WeightBold/WeightBold";

type TRoadblockStatusLabelPopoverCancelledProps = {
    noBoxShadow?: boolean;
};

export const CRMRoadblockStatusLabelPopoverCancelled = (props: TRoadblockStatusLabelPopoverCancelledProps): JSX.Element => (
    <Background
        color={CRMColors.NEUTRAL_10}
        borderRadius="8px"  
        boxShadowDefinition={
            props.noBoxShadow ? '' : `0px 2px 5px 0px rgba(0, 0, 0, 0.14)`
        }
    >
        {/* TOP SECTION */}
        <Padding spacing={CRMSpacing.MEDIUM}>
            {/* TITLE */}
            <CRMParagraph fontSize={CRMFontSizes.SMALL_PLUS}>
                <TextColor color={CRMColors.NEUTRAL_INK}>
                    <WeightBold>
                        This roadblock is temporarily suspended until another roadblock, whose completion it depends on, is resolved.
                    </WeightBold>
                </TextColor>
            </CRMParagraph>
        </Padding>
    </Background>
);
