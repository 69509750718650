import * as JsonResponse1 from "../../../../domain/models/JsonResponse1";
import * as JsonResponseMeta1 from "../../../../domain/models/JsonResponseMeta1";
import * as FirstPartyFetchResponse from "../../../../domain/models/FirstPartyFetchResponse";
import { TState } from "./lensBaseTypes";
import { reduceDataToStateUpdate } from "./reduceDataToStateUpdate";
import { set } from "./set";
import * as TSetState from "../../state/TSetState";

interface IReduceResponse<T extends JsonResponse1.T<T["data"], JsonResponseMeta1.T>, S> {
    // 5 level lens path guard
    <
        K1 extends keyof S,
        K2 extends keyof S[K1],
        K3 extends keyof S[K1][K2],
        K4 extends keyof S[K1][K2][K3],
        K5 extends keyof S[K1][K2][K3][K4]
    >
    (
        path: [K1, K2, K3, K4, K5],
        callback: (value: S[K1][K2][K3][K4][K5], data: FirstPartyFetchResponse.T<T>, state: S) => S[K1][K2][K3][K4][K5]
    ): (data: FirstPartyFetchResponse.T<T>) => void;
    // 4 level lens path guard
    <
        K1 extends keyof S,
        K2 extends keyof S[K1],
        K3 extends keyof S[K1][K2],
        K4 extends keyof S[K1][K2][K3]
    >
    (
        path: [K1, K2, K3, K4],
        callback: (value: S[K1][K2][K3][K4], data: FirstPartyFetchResponse.T<T>, state: S) => S[K1][K2][K3][K4]
    ): (data: FirstPartyFetchResponse.T<T>) => void;
    // 3 level lens path guard
    <
        K1 extends keyof S,
        K2 extends keyof S[K1],
        K3 extends keyof S[K1][K2]
    >
    (
        path: [K1, K2, K3],
        callback: (value: S[K1][K2][K3], data: FirstPartyFetchResponse.T<T>, state: S) => S[K1][K2][K3]
    ): (data: FirstPartyFetchResponse.T<T>) => void;
    // 2 level lens path guard
    <
        K1 extends keyof S,
        K2 extends keyof S[K1]
    >
    (
        path: [K1, K2],
        callback: (value: S[K1][K2], data: FirstPartyFetchResponse.T<T>, state: S) => S[K1][K2]
    ): (data: FirstPartyFetchResponse.T<T>) => void;
    // 1 level lens path guard
    <K1 extends keyof S>
    (
        path: [K1],
        callback: (value: S[K1], data: FirstPartyFetchResponse.T<T>, state: S) => S[K1]
    ): (data: FirstPartyFetchResponse.T<T>) => void;
}

export const reduceResponse = <T extends JsonResponse1.T<T["data"], JsonResponseMeta1.T>>(setState: TSetState.TSetState): IReduceResponse<T, TState> =>
    (lensPath: any, callback: any) => // eslint-disable-line
        reduceDataToStateUpdate<FirstPartyFetchResponse.T<T>>(setState)(
            /* eslint-disable */
            // The type is always expected to be never for the form, since we have abstraced the lens scoping,
            // which is untrue
            // @ts-ignore
            set()(lensPath, callback),
            /* eslint-enable */
        );
