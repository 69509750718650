import React from "react";
import { CRMTitleForCardQuicksandLower } from "../CRMTitleForCardQuicksandLower/CRMTitleForCardQuicksandLower";
import * as CaseDocumentType1 from "../../../../domain/models/CaseDocumentType1";
import * as CaseEnquiryStatusType from "../../../../domain/models/CaseEnquiryStatusType";
import { array } from "fp-ts";
import { CRMIcon, TIcon } from "../CRMIcon/CRMIcon";
import { CRMLink } from "../CRMLink/CRMLink";
import { CRMInputCheckboxComponent } from "../CRMInputCheckboxComponent/CRMInputCheckboxComponent";
import * as TFormModels from "../../models/TFormModels";
import { CRMFormErrorComponent } from "../CRMFormErrorComponent/CRMFormErrorComponent";
import { pipe } from "fp-ts/lib/function";
import { SpacingColumn } from "../BuildingBlocks/SpacingColumn";
import { CRMSpacing } from "../../models/CRMSpacing";
import { SpacingRow } from "../BuildingBlocks/SpacingRow";
import { FontQuicksand } from "../BuildingBlocks/FontQuicksand";
import { FontSize } from "../BuildingBlocks/FontSize";
import { CRMFontSizes } from "../../models/CRMFontSizes";
import { WeightMedium } from "../WeightMedium/WeightMedium";
import { WeightBold } from "../WeightBold/WeightBold";

type TCRMCardOutsideEnquiryDocumentProps = {
    caseDocumentForm: TFormModels.TCaseDocument6AndCaseDocumentForm3FormList["forms"][number];
    onAllFilesCheckedChange: (value: boolean) => void;
};

export const CRMCardOutsideEnquiryDocument = (props: TCRMCardOutsideEnquiryDocumentProps): JSX.Element => {

    const getCountOfEnquiriesByType = (type: CaseEnquiryStatusType.T): number =>
        pipe(
            props.caseDocumentForm.view.enquiries,
            array.filter((enquiry) => enquiry.status === type),
            (a) => a.length,
        )
    ;
    const getUnsubmittedEnquiryCount = () => getCountOfEnquiriesByType("unsubmitted");
    const getPendingApprovalEnquiryCount = () => getCountOfEnquiriesByType("pending_approval");
    const getApprovedUnsentEnquiryCount = () => getCountOfEnquiriesByType("approved_unsent");
    const getApprovedSentEnquiryCount = () => getCountOfEnquiriesByType("approved_sent");
    const getResolvedEnquiryCount = () => getCountOfEnquiriesByType("resolved");

    const areAllEnquiriesResolved = (): boolean =>
        getUnsubmittedEnquiryCount() === 0
        && getPendingApprovalEnquiryCount() === 0
        && getApprovedUnsentEnquiryCount() === 0
        && getApprovedSentEnquiryCount() === 0
        && getResolvedEnquiryCount() > 0;

    const areZeroEnquiriesRaised = (): boolean =>
        getUnsubmittedEnquiryCount() === 0
        && getPendingApprovalEnquiryCount() === 0
        && getApprovedUnsentEnquiryCount() === 0
        && getApprovedSentEnquiryCount() === 0
        && getResolvedEnquiryCount() === 0;

    return (
        <div className="crm-card-outside-enquiry-document">
            <div className="crm-card-outside-enquiry-document__card">
                <SpacingColumn spacing={CRMSpacing.MEDIUM}>
                    {/* TITLE */}
                    <CRMTitleForCardQuicksandLower>
                        {CaseDocumentType1.toDisplayString(props.caseDocumentForm.view.type)}
                        {props.caseDocumentForm.view.additional_name ? ` - ${props.caseDocumentForm.view.additional_name}` : ""}
                    </CRMTitleForCardQuicksandLower>

                    {/* BADGES & RESOLUTION TEXT */}
                    <div>
                        {/* BADGES */}
                        {(areAllEnquiriesResolved() === false && !areZeroEnquiriesRaised())  && 
                            <SpacingRow spacing={CRMSpacing.MEDIUM}>
                                <DocumentBadge
                                title="Unsubmitted enquiries"
                                    icon="denied"
                                    count={getUnsubmittedEnquiryCount()}
                                />
                                <DocumentBadge
                                    title="Enquiries pending approval"
                                    icon="sign"
                                    count={getPendingApprovalEnquiryCount()}
                                />
                                <DocumentBadge
                                    title="Enquiries approved but not yet sent"
                                    icon="email-notification"
                                    count={getApprovedUnsentEnquiryCount()}
                                />
                                <DocumentBadge
                                    title="Enquiries approved and sent"
                                    icon="email-outbound"
                                    count={getApprovedSentEnquiryCount()}
                                />
                                <DocumentBadge
                                    title="Resolved enquiries"
                                    icon="done-all"
                                    count={getResolvedEnquiryCount()}
                                />
                            </SpacingRow>
                        }

                        {/* RESOLUTION TEXT - ALL RESOLVED */}
                        {areAllEnquiriesResolved() && 
                            <IconAndLabel icon="done-all">
                                <WeightMedium>
                                    All enquiries resolved
                                </WeightMedium>
                            </IconAndLabel>
                        }

                        {/* RESOLUTION TEXT - NO ENQUIRIES */}
                        {areZeroEnquiriesRaised() && 
                            <IconAndLabel icon="done">
                                <WeightMedium>
                                    No enquiries raised
                                </WeightMedium>
                            </IconAndLabel>
                        }
                    </div>
                

                    {/* FILES WITH LINKS */}
                    <SpacingColumn spacing={CRMSpacing.TINY}>
                        {props.caseDocumentForm.view.files.map((file, index) => 
                            <CRMLink
                                key={index}
                                href={`${env.REACT_APP_API_URL}/web/cases/${props.caseDocumentForm.view.case_id}/documents/${props.caseDocumentForm.view.id}/files/${file.id}/download`}
                                target="_blank"
                                linkStyle="normal"
                            >
                                {file.name}.{file.file_extension}
                            </CRMLink>
                        )}
                    </SpacingColumn>
                </SpacingColumn>
            </div>

            {/* BOTTOM BAR - ALL FILES CHECKED */}
            <div className="crm-card-outside-enquiry-document__all-files-checked">
                <SpacingColumn spacing={CRMSpacing.MEDIUM}>
                    <CRMInputCheckboxComponent
                        label="All files checked for enquiries"
                        checked={props.caseDocumentForm.edit.all_files_checked_for_enquiries}
                        onClick={() => props.onAllFilesCheckedChange(!props.caseDocumentForm.edit.all_files_checked_for_enquiries)}
                    />
                    {props.caseDocumentForm.status === "failure" &&
                        <CRMFormErrorComponent
                            errorMessage="Something went wrong when saving that change, please try again."
                        />
                    }
                </SpacingColumn>
            </div>
        </div>
    );
};


type TEnquiryTypeBadgeProps = {
    title: string;
    icon: TIcon;
    count: number;
};

const DocumentBadge = (props: React.PropsWithChildren<TEnquiryTypeBadgeProps>): JSX.Element => (
    <>
        {props.count > 0 &&
            <div title={props.title}>
                <IconAndLabel icon={props.icon}>
                    <WeightBold>
                        {props.count}
                    </WeightBold>
                </IconAndLabel>
            </div>
        }
    </>
);

type TIconAndLabel = {
    icon: TIcon;
};

const IconAndLabel = (props: React.PropsWithChildren<TIconAndLabel>): JSX.Element => (
    <SpacingRow spacing={CRMSpacing.TINY}>
        <CRMIcon
            size="tiny"
            iconName={props.icon}
            colour="neutral-ink"
        />
        <FontQuicksand>
            <FontSize size={CRMFontSizes.SMALL}>
                {props.children}
            </FontSize>
        </FontQuicksand>
    </SpacingRow>
);