import React, { ReactNode } from "react";

type TInputSize = "20%"
    | "30%"
    | "40%"
    | "50%"
    | "60%"
    | "70%"
    | "80%"
;

type TThisOrThatInput = {
    thisInput: ReactNode;
    thatInput: ReactNode;
    thisInputSize?: TInputSize;
    thatInputSize?: TInputSize;
};

export class ThisOrThatInput extends React.Component<React.PropsWithChildren<TThisOrThatInput>> {
    public render (): JSX.Element {
        return (
            <div className="thisOrThatInput">
                <div className="thisOrThatInput__input" style={{ width: this.props.thisInputSize || "50%"}}>
                    {this.props.thisInput}
                </div>
                <div className="thisOrThatInput__or">OR</div>
                <div className="thisOrThatInput__input" style={{ width: this.props.thatInputSize || "50%"}}>
                    {this.props.thatInput}
                </div>
            </div>
        );
    }
}
