import React from "react";
import * as TFormStatus from "../../../models/TFormStatus";

interface ICRMAccordionProps {
    title: string;
    formStatus?: TFormStatus.T;
    defaultsOpen?: boolean;
    onOpen?: () => void;
    isSubAccordion?: boolean;
}

interface ICRMAccordionState {
    isOpen: boolean;
}

class CRMAccordion extends React.Component<React.PropsWithChildren<ICRMAccordionProps>, ICRMAccordionState> {
    public constructor (props: ICRMAccordionProps) {
        super(props);
        if (props.defaultsOpen && typeof props.onOpen !== "undefined") {
            props.onOpen();
        }
        this.state = {
            isOpen: props.defaultsOpen || false,
        };
    }

    public componentDidUpdate (previousProps: ICRMAccordionProps): void {
        if (previousProps.defaultsOpen !== this.props.defaultsOpen) {
            if (this.props.defaultsOpen && typeof this.props.onOpen !== "undefined") {
                this.props.onOpen();
            }
            this.setState({
                isOpen: this.props.defaultsOpen || false,
            });
        }
    }

    public isFormUnsaved = (): boolean =>
        typeof this.props.formStatus !== "undefined" && TFormStatus.hasUnsavedChanged(this.props.formStatus);

    public render (): JSX.Element {
        return (
            <div className={`
                crmAccordion 
                crmAccordion--${this.isFormUnsaved() ? "unsaved" : "saved"}
                
            `}>
                <div
                    className={`crmAccordion__header ${this.state.isOpen ? "crmAccordion__header--open" : ""}`}
                    onClick={() => {
                        if (!this.state.isOpen && typeof this.props.onOpen !== "undefined") {
                            this.props.onOpen();
                        }
                        this.setState({
                            isOpen: !this.state.isOpen,
                        });
                    }}
                >
                    <div>
                        {this.props.title} {this.isFormUnsaved() ? "(Unsaved)" : ""}
                    </div>
                    <div>
                        {this.state.isOpen ? "-" : "+"}
                    </div>
                </div>
                {this.state.isOpen ? (
                    <div>
                        {this.props.children}
                    </div>
                ) : ""}
            </div>
        );
    }
}

export default CRMAccordion;
