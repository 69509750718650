import React, { useEffect, useState } from "react";
import { TContainerStateProps } from "../../state/TContainerStateProps";
import { CRMPageWrap } from "../../components/Simple/CRMPageWrap/CRMPageWrap";
import { CRMPageBody } from "../../components/Simple/CRMPageBody/CRMPageBody";
import { CRMLegalMenuBarContainer } from "../../containers/CRMLegalMenuBarContainer/CRMLegalMenuBarContainer"
import { CRMFilterNav, TSubNavButton } from "../../components/CRMFilterNav/CRMFilterNav";
import { snakeCaseToCopyText } from "../../../../shared/src/util";
import CRMLegalCaseDocumentsContainer from "../../containers/CRMLegalCaseDocumentsContainer/CRMLegalCaseDocumentsContainer";
import CRMLegalCaseEnquiriesContainer from "../../containers/CRMLegalCaseEnquiriesContainer/CRMLegalCaseEnquiriesContainer";
import { CRMCaseLedgerContainer } from "../../containers/CRM/CRMCaseLedgerContainer";
import CRMLegalCaseConflictsOfInterestContainer from "../../containers/CRMLegalCaseConflictsOfInterestContainer/CRMLegalCaseConflictsOfInterestContainer";
import CRMCaseInboxContainer from "../../containers/CRMCaseInboxContainer/CRMCaseInboxContainer";
import { Background } from "../../components/BuildingBlocks/Background";
import CRMCaseSatisfactionContainer from "../../containers/CRM/CRMCaseSatisfactionContainer";
import { CRMDualRepIconCardPopout } from "../../components/Complex/CRMDualRepIconCardPopout/CRMDualRepIconCardPopout";
import { SpacingRow } from "../../components/BuildingBlocks/SpacingRow";
import { CRMSpacing } from "../../models/CRMSpacing";
import { doesErrorCodeExist } from "../../../../shared/src/codecs/errors";
import { CRMCaseNotAuthorizedDualRep } from "../../components/Simple/CRMCaseNotAuthorizedDualRep/CRMCaseNotAuthorizedDualRep";
import { CRMCaseDualRepBlock } from "../../components/CRMCaseDualRepBlock/CRMCaseDualRepBlock";
import { CRMBlocksRouterContainer } from "../../containers/CRMBlocksRouterContainer/CRMBlocksRouterContainer";
import CRMBlockChoiceContainer from "../../containers/CRM/CRMBlockChoiceContainer";
import { CRMClickCopyWrap } from "../../components/Simple/CRMClickCopyWrap/CRMClickCopyWrap";
import { CRMLegalCaseHasHomeListingIconCardPopout } from "../../components/Complex/CRMLegalCaseHasHomeListingIconCardPopout/CRMLegalCaseHasHomeListingIconCardPopout";
import { CRMColors } from "../../models/CRMColors";
import { CRMStatusPill } from "../../components/Simple/CRMStatusPill/CRMStatusPill";
import { CRMCloseChaseContinueCaseIconCardPopout } from "../../components/Complex/CRMCloseChaseContinueCaseIconCardPopout/CRMCloseChaseContinueCaseIconCardPopout";
import { CRMCloseChaseContinueCasePopoutChaserView } from "../../components/Complex/CRMCloseChaseContinueCasePopoutChaserView/CRMCloseChaseContinueCasePopoutChaserView";
import { CRMCloseChaseContinueCasePopoutRequestView } from "../../components/Complex/CRMCloseChaseContinueCasePopoutRequestView/CRMCloseChaseContinueCasePopoutRequestView";
import { CRMCloseChaseContinueCasePopoutAcknowledgeView } from "../../components/Complex/CRMCloseChaseContinueCasePopoutAcknowledgeView/CRMCloseChaseContinueCasePopoutAcknowledgeView";

export type TCaseTabs = 
    "unset"
    | "details"
    | "document_management"
    | "enquiries"
    | "ledger"
    | "emails"
    | "satisfaction"
    | "coi"
    | "satisfaction"
;

const CRMLegalCasePage = (props: TContainerStateProps): JSX.Element => {
    const [closeChaseContinueIsOpen, setCloseChaseContinueIsOpen] = useState<boolean>(false);
    const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true);
    const [originalTab, setOriginalTab] = useState<TCaseTabs>("unset");
    const [currentTab, setCurrentTab] = useState<TCaseTabs>("unset");

    useEffect(
        () => props.dispatch({
            type: "LEGAL_CASE_PAGE_VIEW",
            payload: null
        }),
        []
    );

    useEffect(
        () => {
            if (originalTab === "unset" || (originalTab === currentTab)) {
                setIsFirstLoad(true);
                setCurrentTab(getSelectedTab());
            } else {
                if (originalTab !== currentTab) {
                    setIsFirstLoad(false);
                }
            };
        },
        []
    );

    const getCaseStatus = () => props.state.forms.case_details_page.data.output.details.children.status;

    const isChaserStaff = () => props.state.global.user.is_chaser_staff;

    const inChaseMode = props.state.forms.chase_mode_form.children.latest_chase.status === "chase";

    const getCaseLabel = (): JSX.Element =>
        <SpacingRow spacing={CRMSpacing.SMALL}>
            {/* TRANSACTION TYPE & PROPERTY INFO */}
            <SpacingRow spacing={CRMSpacing.TINY}>
                {getCaseAddressesCopyText() &&
                    <CRMClickCopyWrap
                        value={getCaseAddressesCopyText()}
                    >
                        <div 
                            title={getCaseAddressesCopyText()}
                            style={{
                                display: "inline-block",
                                maxWidth: "350px",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                            }} 
                        >
                            {getCaseAddressesCopyText()}
                        </div>
                    </CRMClickCopyWrap>
                }
                
                <div style={{whiteSpace: "nowrap"}}>
                    <CRMStatusPill
                        pillColor={inChaseMode ? CRMColors.HIGHLIGHTS_CALMING_PINK_10 : CRMColors.NEUTRAL_PAPER}
                        borderColor={inChaseMode ? CRMColors.HIGHLIGHTS_CALMING_PINK_2 : CRMColors.NEUTRAL_6}
                        textColor={CRMColors.NEUTRAL_INK}
                        label={`${getCaseStatusAsCopyText()}`}
                        subLabelFont="quicksand"
                        subLabel={`${getCaseTransactionType()}${getSellerReasonForSale() === "unknown" ? "" : `/${getSellerReasonForSale()}`}`}
                    /> 
                </div>
            </SpacingRow>

            <SpacingRow spacing={CRMSpacing.TINY}>
                {/* SAIL HOMES CASE ICON */}
                {props.state.forms.case_details_page.data.output.sail_homes_case_id && 
                    <CRMLegalCaseHasHomeListingIconCardPopout />
                }
                
                {/* DUAL REP ICON */}
                {props.state.forms.case_details_page.data.output.details.children.dual_rep.linked_case_form &&
                    <CRMDualRepIconCardPopout form={props.state.forms.case_details_page.data.output.details.children.dual_rep.linked_case_form} />
                }

                {/* SAIL LEGAL CLOSE/CHASE/CONTINUE CASE ICON */}
                {getCaseStatus() !== "closed_successfully"
                    ? <CRMCloseChaseContinueCaseIconCardPopout
                        isInChaseMode={inChaseMode}
                        notification={showNotification}
                        onClick={() => setCloseChaseContinueIsOpen(!closeChaseContinueIsOpen)}
                    />
                    : <></>
                }
            </SpacingRow>
        </SpacingRow>

    const getCaseStatusAsCopyText = () => `${snakeCaseToCopyText(getCaseStatus())}${inChaseMode ? " (in chase)" : ""}`;

    const getCaseTransactionType = () => props.state.forms.case_details_page.data.output.details.children.transaction_type;

    const getSellerReasonForSale = () => props.state.forms.case_details_page.data.output.details.children.seller_reason_for_sale;

    const getCaseAddressesCopyText = (): string => {
        return props.state.forms.case_details_page.data.output.properties.map((p) => p.address_short_string).join(" & ")
    }

    const getSelectedTab = (): TCaseTabs => {
        if (props.state.routes.queryParams.VIEW_CRM_LEGAL_CASE.visible_tab) {
            return props.state.routes.queryParams.VIEW_CRM_LEGAL_CASE.visible_tab as unknown as TCaseTabs;
        }

        return "details";
    };

    const onSelectTab = (tab: TCaseTabs) => {
        props.dispatch({
            type: "LEGAL_CASE_UPDATE_VISIBLE_TAB",
            payload: tab
        });
        setOriginalTab(currentTab);
        setCurrentTab(tab);
        setIsFirstLoad(false);
    };

    const isNotAuthorizedDualRep = () => doesErrorCodeExist("NotAuthorizedForDualRepCase", props.state.forms.case_details_page.data.validationErrors);

    const isInstructed = props.state.forms.case_details_page.data.output.details.edited.is_instructed !== null;

    const subNavButtons: Array<TSubNavButton<TCaseTabs>> =
        [
            {
                label: "Details",
                value: "details",
                filters: [],
                isDisabled: false,
            },
            {
                label: "Documents",
                value: "document_management",
                filters: [],
                isDisabled: false,
            },
            {
                label: "Enquiries",
                value: "enquiries",
                filters: [],
                isDisabled: false,
            },
            {
                label: "Emails",
                value: "emails",
                filters: [],
                isDisabled: false,
            },
            {
                label: "Satisfaction",
                value: "satisfaction",
                filters: [],
                isDisabled: false,
            },
            {
                label: "Ledger",
                value: "ledger",
                filters: [],
                isDisabled: !isInstructed,
            },
            {
                label: "Conflicts",
                value: "coi",
                filters: [],
                isDisabled: false,
            },
        ];

    const dualRepLinkedCaseForm = props.state.forms.case_details_page.data.output.details.children.dual_rep.linked_case_form;

    const showAcceptRejectForm = () => {
        if (
            inChaseMode 
            && props.state.forms.chase_mode_form.children.latest_chase.acknowledged_at === null
        ) {
            return true;
        } else {
            return false;
        }
    };

    const showAcknowledgeForm = () => {
        if (props.state.forms.chase_mode_form.children.latest_chase.acknowledged_at === null
            && (
                props.state.forms.chase_mode_form.children.latest_chase.status === "workable"
                || props.state.forms.chase_mode_form.children.latest_chase.status === "workable_rejected_chase_request"
            )
        ) {
            return true;
        } else {
            return false;
        }
    };

    const showNotification =
        (
            showAcceptRejectForm()
            && isChaserStaff()
        )
        || (
            showAcknowledgeForm()
            && !isChaserStaff()
        );

    return (
        <CRMPageWrap>

            {/* HEADER */}
            <CRMLegalMenuBarContainer {...props} />
            
            {props.state.forms.case_details_page.data.status === "success" && <>
                {/* SUB NAV */}
                <CRMFilterNav<TCaseTabs>
                    selected={currentTab}
                    selectedFilters={[]}
                    popoutText={getCaseLabel()}
                    buttons={subNavButtons}
                    onChange={onSelectTab}
                    onChangeFilters={() => undefined}
                />

                {/* BODY */}
                {currentTab !== "emails" && currentTab !== "satisfaction" &&
                    <CRMPageBody
                        fullViewportWidth={currentTab==="details"}
                        overflowAuto={currentTab==="details"}
                    >
                        {/* CLOSE/CHASE/CONTINUE FORM POPOUT */}
                        {/* CHASER VIEW - ACCEPT/REJECT VIEW*/}
                        {closeChaseContinueIsOpen
                            && isChaserStaff()
                            && showAcceptRejectForm()
                            && !showAcknowledgeForm()
                            && <CRMCloseChaseContinueCasePopoutChaserView
                                {...props}    
                                isOpen={closeChaseContinueIsOpen}
                                onClose={() => setCloseChaseContinueIsOpen(false)}
                            />
                        }

                        {/* ACKNOWLEDGE REJECTION/ACKNOWLEDGE RETURN TO WORKABLE MODE VIEW */}
                        {closeChaseContinueIsOpen
                            && !isChaserStaff()
                            && showAcknowledgeForm()
                            && !showAcceptRejectForm()
                            && <CRMCloseChaseContinueCasePopoutAcknowledgeView
                                {...props}    
                                isOpen={closeChaseContinueIsOpen}
                                onClose={() => setCloseChaseContinueIsOpen(false)}
                            />
                        }

                        {/* DEFAULT VIEW */}
                        {closeChaseContinueIsOpen
                            && (
                                (isChaserStaff() && !showAcceptRejectForm())
                                || (!isChaserStaff() && !showAcknowledgeForm())
                            )
                            && <CRMCloseChaseContinueCasePopoutRequestView
                                {...props}    
                                isOpen={closeChaseContinueIsOpen}
                                onClose={() => setCloseChaseContinueIsOpen(false)}
                            />
                        }

                        {currentTab === "details" &&
                            <CRMBlocksRouterContainer
                                state={props.state}
                                dispatch={props.dispatch}
                                isFirstLoad={isFirstLoad}
                                onChangeBlock={() => setIsFirstLoad(false)}
                            />
                        }

                        {currentTab === "document_management" && 
                            <CRMLegalCaseDocumentsContainer {...props} />
                        }

                        {currentTab === "enquiries" && 
                            <CRMLegalCaseEnquiriesContainer {...props} />
                        }

                        {currentTab === "ledger" && 
                            <CRMCaseLedgerContainer {...props} />
                        }

                        {currentTab === "coi" && 
                            <CRMLegalCaseConflictsOfInterestContainer {...props} />
                        }
                    </CRMPageBody>
                }

                {(currentTab === "emails" || currentTab === "satisfaction") &&
                    <Background padding={`${CRMSpacing.SMALL} ${CRMSpacing.X_LARGE} 0px`}>
                        {/* CLOSE/CHASE/CONTINUE FORM POPOUT */}
                        {/* CHASER VIEW - ACCEPT/REJECT VIEW*/}
                        {closeChaseContinueIsOpen
                            && isChaserStaff()
                            && showAcceptRejectForm()
                            && !showAcknowledgeForm()
                            && <CRMCloseChaseContinueCasePopoutChaserView
                                {...props}    
                                isOpen={closeChaseContinueIsOpen}
                                onClose={() => setCloseChaseContinueIsOpen(false)}
                            />
                        }

                        {/* ACKNOWLEDGE REJECTION/ACKNOWLEDGE RETURN TO WORKABLE MODE VIEW */}
                        {closeChaseContinueIsOpen
                            && !isChaserStaff()
                            && showAcknowledgeForm()
                            && !showAcceptRejectForm()
                            && <CRMCloseChaseContinueCasePopoutAcknowledgeView
                                {...props}    
                                isOpen={closeChaseContinueIsOpen}
                                onClose={() => setCloseChaseContinueIsOpen(false)}
                            />
                        }

                        {/* DEFAULT VIEW */}
                        {closeChaseContinueIsOpen
                            && (
                                (isChaserStaff() && !showAcceptRejectForm())
                                || (!isChaserStaff() && !showAcknowledgeForm())
                            )
                            && <CRMCloseChaseContinueCasePopoutRequestView
                                {...props}    
                                isOpen={closeChaseContinueIsOpen}
                                onClose={() => setCloseChaseContinueIsOpen(false)}
                            />
                        }

                        {currentTab === "emails" &&
                            <CRMCaseInboxContainer {...props} />
                        }

                        {currentTab === "satisfaction" &&
                            <CRMCaseSatisfactionContainer {...props} />
                        }
                    </Background> 
                }

                {/* DUAL REP - WORKING ON CASE BLOCKED UNTIL ALL PARTIES CONSENT */}
                {dualRepLinkedCaseForm && !dualRepLinkedCaseForm.children.our_case_can_be_worked_on &&
                    <CRMCaseDualRepBlock {...dualRepLinkedCaseForm.children} />
                }
            </>}

            {isNotAuthorizedDualRep() &&
                <Background padding="100px">
                    <CRMCaseNotAuthorizedDualRep />
                </Background>
            }

            <CRMBlockChoiceContainer {...props} />

        </CRMPageWrap>
    );
};

export default CRMLegalCasePage;
