import React from "react";
import * as TFormModels from "../../../models/TFormModels";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { Background } from "../../BuildingBlocks/Background";
import { SpacingColumn } from "../../BuildingBlocks/SpacingColumn";
import { SpacingRow } from "../../BuildingBlocks/SpacingRow";
import { BackgroundColour } from "../../BuildingBlocks/BackgroundColour";
import { CRMButtonIcon } from "../../CRMButtonIcon/CRMButtonIcon";
import { CRMCaseDocumentManagementFileRow } from "../../CRMCaseDocumentManagementFileRow/CRMCaseDocumentManagementFileRow";
import { CRMFormButtonIcon } from "../../CRMFormButtonIcon/CRMFormButtonIcon";
import { CRMIcon, TIcon } from "../../CRMIcon/CRMIcon";
import * as TFormStatus from "../../../models/TFormStatus";
import { CRMColors } from "../../../models/CRMColors";
import { DocumentTypesThatRequiredAnExpiry } from "../../../../../domain/models/CaseDocumentType1";
import { CursorPointer } from "../../CursorPointer/CursorPointer";
import { CRMHorizontalSeperatorLine } from "../../CRMHorizontalSeperatorLine/CRMHorizontalSeperatorLine";
import { pipe } from "fp-ts/lib/function";
import { array, record } from "fp-ts";
import { CRMParagraph } from "../../Simple/CRMParagraph/CRMParagraph";
import { Text } from "../../BuildingBlocks/Text";

type TCaseDocumentCardBottomProps = {
    documentForm: TFormModels.TCaseDocument6AndCaseDocumentForm1FormList["forms"][number];
    filesForms: TFormModels.TCaseDocumentFile4AndCaseDocumentFileForm1FormList["forms"];
    activeUploads: Record<string, "in_progress" | "complete" | "error">;
    onReceivedDateChange: (isoDate: string | null) => void;
    onFileNameChange: (fileId: string, value: string) => void;
    onNewFileUploadChange: (files: File[]) => void;
    onFileDelete: (fileId: string) => void;
    onSharedWithClientChange: (value: boolean) => void;
    onSharedWithSolicitorChange: (value: boolean) => void;
};

export const CRMCaseDocumentCardBottom = (props: React.PropsWithChildren<TCaseDocumentCardBottomProps>): JSX.Element => {

    let uploadInputRef: HTMLInputElement | null = null;

    const hasFiles = (): boolean => props.filesForms.length > 0;

    const allFilesReceived = (): boolean =>
        props.documentForm.edit.received_date !== null
        && hasFiles() 
    ;

    const activeUploadsAsArray = pipe(
        props.activeUploads,
        record.toArray
    );

    const isUploadAllowed = (): boolean =>
        !allFilesReceived()
        && pipe(
            activeUploadsAsArray,
            array.filter(([, status]) => status === "in_progress")
        ).length === 0;
    ;


    const totalUploadsStarted = activeUploadsAsArray.length;

    const totalErrors = pipe(
        activeUploadsAsArray,
        array.filter(([, status]) => status === "error")
    ).length;

    const totalComplete = pipe(
        activeUploadsAsArray,
        array.filter(([, status]) => status === "complete")
    ).length;

    return (
        <BackgroundColour colour={CRMColors.NEUTRAL_12}>
            {/* CHECKBOXES & FILE UPLOAD */}
            <SpacingRow justifyContent={hasFiles() ? "space-between" : "end"}>
                {hasFiles() &&
                    <SpacingRow>
                        <CheckboxBlock
                            title="All files correct?"
                            icon="file-clip"
                            isChecked={allFilesReceived()}
                            isDisabled={
                                DocumentTypesThatRequiredAnExpiry.includes(props.documentForm.edit.type)
                                && ! props.documentForm.edit.valid_until
                            }
                            onClick={() =>
                                props.onReceivedDateChange(
                                    allFilesReceived() ? null : new Date().toISOString()
                                )
                            }
                        />
                        <CheckboxBlock
                            title="Shared with client?"
                            icon="person"
                            isChecked={props.documentForm.edit.shared_with_client}
                            onClick={() => props.onSharedWithClientChange(!props.documentForm.edit.shared_with_client)}
                        />
                        <CheckboxBlock
                            title="Shared with solicitor?"
                            icon="legal-gavel"
                            isChecked={props.documentForm.edit.shared_with_solicitor}
                            onClick={() => props.onSharedWithSolicitorChange(!props.documentForm.edit.shared_with_solicitor)}
                        />
                    </SpacingRow>
                }

                {!allFilesReceived() && 
                    <SpacingRow>
                    {totalUploadsStarted > 0 && <CRMParagraph>
                        {totalComplete}
                        {totalErrors > 0 ?<>,&nbsp;<Text display="inline-flex" color={CRMColors.HIGHLIGHTS_INSTRUCTIONAL_BRICK_0}>errors: {totalErrors}</Text></> : ""}
                        &nbsp;/&nbsp;{totalUploadsStarted}
                    </CRMParagraph>}
                    <Background padding={CRMSpacing.TINY}>
                        <CRMFormButtonIcon
                            icon="attach"
                            ButtonElement={(p) => <CRMButtonIcon variant="quaternary" {...p} />}
                            onClick={() => {
                                if (isUploadAllowed()) {
                                    return uploadInputRef?.click();
                                }
                            }}
                            formStatus={isUploadAllowed() ? "requiresSubmission" : "submitting"}
                        />
                    </Background>
                    </SpacingRow>
                }
            </SpacingRow>

            {/* LIST - UPLOADED FILES */}
            {hasFiles() &&
                <Background
                    padding={`${CRMSpacing.TINY} ${CRMSpacing.MEDIUM} ${CRMSpacing.MEDIUM}`}
                    color={CRMColors.NEUTRAL_12}
                >
                    <SpacingColumn spacing={CRMSpacing.MEDIUM}>
                        
                        <CRMHorizontalSeperatorLine  />
                        
                        <SpacingColumn spacing={CRMSpacing.TINY}>
                            {props.filesForms.map((form, index) =>
                                <CRMCaseDocumentManagementFileRow
                                    key={index}
                                    documentForm={props.documentForm}
                                    fileForm={form}
                                    onFileNameChange={props.onFileNameChange}
                                    onFileDelete={props.onFileDelete}
                                />
                            )}
                        </SpacingColumn>
                    </SpacingColumn>
                </Background>
            }

            {/* HIDDEN - UPLOAD INPUT */}
            <input
                ref={(element) => uploadInputRef = element}
                type="file"
                name="file"
                multiple={true}
                onChange={(e) => {
                    if (e.currentTarget.files) {
                        props.onNewFileUploadChange(Array.from(e.currentTarget.files));
                    }
                }}
                style={{display: "none"}}
            />
        </BackgroundColour>
    );
};


type TCheckboxBlock = {
    icon: TIcon;
    isChecked: boolean;
    title: string;
    isDisabled?: boolean;
    onClick: () => void;
};

const CheckboxBlock = (props: TCheckboxBlock) => {
    return (
        ! props.isDisabled
            ? <CursorPointer
                onClick={props.onClick}
                title={props.title}
            >
                {CheckboxBlockInner(props)}
            </CursorPointer>
            : CheckboxBlockInner(props)
    )
}

const CheckboxBlockInner = (props: TCheckboxBlock) => {
    return (
        <Background
            padding={`${CRMSpacing.SMALL} ${CRMSpacing.MEDIUM}`}
            color={props.isChecked ? CRMColors.NEUTRAL_12 : CRMColors.NEUTRAL_12}
        >
            <SpacingRow spacing={CRMSpacing.SMALL}>
                <CRMIcon
                    iconName={props.icon}
                    colour={
                        props.isDisabled
                        ? "neutral-6"
                        : props.isChecked
                        ? "highlights-positive-green-0"
                        : "neutral-ink"
                    }
                    size="medium"
                />
                <CRMIcon
                    iconName={ props.isChecked ? "checkbox-checked" : "checkbox-unchecked"}
                    colour={
                        props.isDisabled
                        ? "neutral-6"
                        : props.isChecked
                        ? "highlights-positive-green-0"
                        : "neutral-ink"
                    }
                    size="x-small"
                />
            </SpacingRow>
        </Background>
    )
}