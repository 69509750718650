import React from "react";
import { CRMSpacing } from "../../../../models/CRMSpacing";
import { CRMColors } from "../../../../models/CRMColors";
import { SpacingColumn } from "../../../BuildingBlocks/SpacingColumn";
import { SpacingRow } from "../../../BuildingBlocks/SpacingRow";
import { Border } from "../../../BuildingBlocks/Border";
import { Padding } from "../../../BuildingBlocks/Padding";
import { MaxWidth } from "../../../BuildingBlocks/MaxWidth";
import { CRMButtonQuaternary } from "../../../CRMButtonQuaternary/CRMButtonQuaternary";
import { TCaseEnquiry1 } from "../../../../../../domain/codecs/CaseEnquiry";
import { CRMEnquiryAttachment } from "../CRMEnquiryAttachment/CRMEnquiryAttachment";

type TEnquryAttachmentInsertedProps = {
    index: number;
    enquiry: TCaseEnquiry1;
    onRemove: () => void;
}

export const CRMEnquiryAttachmentInserted = (props: TEnquryAttachmentInsertedProps): JSX.Element => (
    <Border type="bottom" definition={`1px solid ${CRMColors.NEUTRAL_8}`}>
        <Padding type="bottom" spacing={CRMSpacing.LARGE}>
            <SpacingColumn spacing={CRMSpacing.MEDIUM}>
                <SpacingColumn spacing={CRMSpacing.TINY}>
                    {/* We use max width here to keep the enquiry paragraph text short enough per line for readability  */}
                    <MaxWidth width="450px">
                        <CRMEnquiryAttachment
                            tagPrefix={`${props.index}. `}
                            enquiry={props.enquiry}
                        />
                    </MaxWidth>
                </SpacingColumn>

                <SpacingRow>
                    <CRMButtonQuaternary
                        label="Remove"
                        icon="delete"
                        onClick={props.onRemove}
                    />
                </SpacingRow>
            </SpacingColumn>
        </Padding>
    </Border>
);
