import React from "react";
import { Background } from "../../BuildingBlocks/Background";
import { CRMColors } from "../../../models/CRMColors";
import { BorderBetween } from "../../BuildingBlocks/BorderBetween";
import { Flex } from "../../BuildingBlocks/Flex";
import { CRMFontSizes } from "../../../models/CRMFontSizes";
import { Text } from "../../BuildingBlocks/Text";
import { CursorPointer } from "../../CursorPointer/CursorPointer";
import { FontQuicksand } from "../../BuildingBlocks/FontQuicksand";

type TStep<A extends string> = {
    value: A,
    label: string,
};

export const CRMStepSwitch = <A extends string>(props: {
    value: A,
    steps: Array<TStep<A>>,
    onChange: (value: A) => void,
}): JSX.Element => {

    return <Background
        width="100%"
        borderDefinition={`solid 1px ${CRMColors.NEUTRAL_6}`}
        borderRadius="8px"
        color={CRMColors.NEUTRAL_PAPER}
    >
        {/* STEPS */}
        {props.steps.map((step, index) => {
            const isStepActive = props.value === step.value;

            const indexBeforeActiveStep = props.steps.findIndex((s) => s.value === props.value) - 1;

            const isIndexBeforeActiveStep = index === indexBeforeActiveStep;

            const showBorderBetween =
                !isStepActive
                && !isIndexBeforeActiveStep;

            return <>
                {/* WITHOUT BORDER */}
                {!showBorderBetween &&
                    <>
                        {/* ACTIVE STEP */}
                        {isStepActive &&
                            <ActiveStep step={step} />
                        }

                        {/* INACTIVE STEP */}
                        {!isStepActive &&
                            <InactiveStep
                                step={step}
                                onChange={props.onChange}
                            />
                        }
                    </>
                }

                {/* WITH BORDER */}
                {showBorderBetween &&
                    <BorderBetween
                        key={step.value}
                        borderColour={CRMColors.NEUTRAL_6}
                        spacing="0px"
                    >
                        {/* ACTIVE STEP */}
                        {isStepActive &&
                            <ActiveStep step={step} />
                        }

                        {/* INACTIVE STEP */}
                        {!isStepActive &&
                            <InactiveStep
                                step={step}
                                onChange={props.onChange}
                            />
                        }
                    </BorderBetween>
                }
            </>
        })}
    </Background>
};

const ActiveStep = <A extends string>(props: { step: TStep<A> }) =>
    <Background
        position="relative"
        width={`calc(100% + 2px)`}
        height={`calc(100% + 2px)`}
        top="-1px"
        left="-1px"
        marginBottom="-2px"
        borderDefinition={`solid 1px ${CRMColors.PRIMARY_10}`}
        borderRadius="8px"
        color={CRMColors.PRIMARY_16}
    >
        <Flex justifyContent="center">
            <FontQuicksand>
                <Text
                    size={CRMFontSizes.SMALL}
                    weight={600}
                    color={CRMColors.PRIMARY_0}
                    lineHeight={1.4}
                >
                    {props.step.label}
                </Text>
            </FontQuicksand>
        </Flex>
    </Background>;

const InactiveStep = <A extends string>(props: {
    step: TStep<A>,
    onChange: (value: A) => void,
}) =>
    <CursorPointer onClick={() => props.onChange(props.step.value)}>
        <Flex justifyContent="center">
            <FontQuicksand>
                <Text
                    size={CRMFontSizes.SMALL}
                    weight={600}
                    color={CRMColors.NEUTRAL_2}
                    lineHeight={1.4}
                >
                    {props.step.label}
                </Text>
            </FontQuicksand>
        </Flex>
    </CursorPointer>;
