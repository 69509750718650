import React from "react";
import * as luxon from "luxon";
import { CRMInlineOptionsComponent } from "../../../CRMInlineOptionsComponent/CRMInlineOptionsComponent";
import { CRMSpacingRow } from "../../../CRMSpacingRow/CRMSpacingRow";
import { CRMIcon } from "../../../CRMIcon/CRMIcon";
import { CRMParagraph } from "../../../Simple/CRMParagraph/CRMParagraph";
import { WeightMedium } from "../../../WeightMedium/WeightMedium";
import { CRMSpacer } from "../../../CRMSpacer/CRMSpacer";
import * as util from "../../../../../../shared/src/util";
import * as TCaseEnquiryTimeline1ReadOnlyForm from "../../../../models/TCaseEnquiryTimeline1ReadOnlyForm";
import * as CaseEnquiryTimeline1 from "../../../../../../domain/models/CaseEnquiryTimeline1";
import { CRMCardOutsidePopupFormSubmit } from "../../../CRMCardOutsidePopupFormSubmit/CRMCardOutsidePopupFormSubmit";

type TEnquiryEmailProps = {
    timelineFormEmailVariant: TCaseEnquiryTimeline1ReadOnlyForm.T<CaseEnquiryTimeline1.TLegalEmail4>;
    onFormChange: (value: TCaseEnquiryTimeline1ReadOnlyForm.T<CaseEnquiryTimeline1.TLegalEmail4>) => void;
    onDelete: () => void;
};

export const CRMCaseEnquiryAttachmentEmail = (props: TEnquiryEmailProps): JSX.Element =>
    (
        <div>
            <div className={`crm-case-enquiry-attachment-email`}>
                {/* TITLE */}
                <CRMSpacingRow>
                    <div className="crm-case-enquiry-attachment-email__title">
                        {luxon.DateTime.fromISO(props.timelineFormEmailVariant.view.payload.date).setZone("Europe/London").toFormat("dd/MM/yyyy")}
                    </div>
                    <CRMInlineOptionsComponent
                        iconSize="small"
                        options={[
                            {
                                label: "Delete",
                                onClick: () => props.onFormChange({
                                    ...props.timelineFormEmailVariant,
                                    ui: {
                                        ...props.timelineFormEmailVariant.ui,
                                        isDeleteModalOpen: true,
                                    },
                                }),
                            },
                        ]}
                    />
                </CRMSpacingRow>
                <CRMSpacer size="tiny" />

                {/* SUBJECT */}
                <CRMSpacingRow>
                    <CRMIcon
                        iconName={
                            props.timelineFormEmailVariant.view.payload.direction === "outbound" ? "email-outbound"
                            : props.timelineFormEmailVariant.view.payload.direction === "inbound" ? "email-inbound"
                            : util.requireExhaustive(props.timelineFormEmailVariant.view.payload.direction)
                        }
                        colour="neutral-ink"
                        size="tiny"
                    />
                    <CRMParagraph>
                        <WeightMedium>
                            &quot;{props.timelineFormEmailVariant.view.payload.subject}&quot;
                        </WeightMedium>
                    </CRMParagraph>
                </CRMSpacingRow>

                {/* EMAIL */}
                <CRMSpacer size="tiny" />
                <CRMParagraph>
                    {props.timelineFormEmailVariant.view.payload.summary}
                </CRMParagraph>
            </div>

            {/* Delete modal */}
            <CRMCardOutsidePopupFormSubmit
                formStatus={props.timelineFormEmailVariant.status}
                validationErrors={props.timelineFormEmailVariant.validationErrors}
                isOpen={props.timelineFormEmailVariant.ui.isDeleteModalOpen}
                title="Delete email attachment"
                context="warning"
                closeText="Cancel"
                ctaText="Yes, delete"
                onClose={() => props.onFormChange({
                    ...props.timelineFormEmailVariant,
                    ui: {
                        ...props.timelineFormEmailVariant.ui,
                        isDeleteModalOpen: false,
                    },
                })}
                onCTA={props.onDelete}
            >
                <CRMParagraph>
                Are you sure you want to remove this email attachment from the enquiry?
                </CRMParagraph>
            </CRMCardOutsidePopupFormSubmit>
        </div>
    );
