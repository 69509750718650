import React from "react";
import { sailLegalBrandConstants } from "../../../../domain/constants";
import { FontQuicksand } from "../../components/BuildingBlocks/FontQuicksand";
import { FontSize } from "../../components/BuildingBlocks/FontSize";
import { MediaPropSwitch } from "../../components/BuildingBlocks/MediaPropSwitch";
import { Padding, TPaddingProps } from "../../components/BuildingBlocks/Padding";
import { SpacingColumn } from "../../components/BuildingBlocks/SpacingColumn";
import { SpacingRow } from "../../components/BuildingBlocks/SpacingRow";
import { TextCenter } from "../../components/BuildingBlocks/TextCenter";
import { TextColor } from "../../components/BuildingBlocks/TextColor";
import { CursorPointer } from "../../components/CursorPointer/CursorPointer";
import { FrontAuthCodeInput } from "../../components/Front/Simple/FrontAuthCodeInput/FrontAuthCodeInput";
import { FrontCardTitle } from "../../components/Front/Simple/FrontCardTitle/FrontCardTitle";
import { FrontOutsideCard } from "../../components/Front/Simple/FrontOutsideCard/FrontOutsideCard";
import { FrontParagraphRegular } from "../../components/Front/Simple/FrontParagraphRegular/FrontParagraphRegular";
import FrontParagraphSmall from "../../components/Front/Simple/FrontParagraphSmall/FrontParagraphSmall";
import { Underline } from "../../components/Underline/Underline";
import { WeightBold } from "../../components/WeightBold/WeightBold";
import { WeightMedium } from "../../components/WeightMedium/WeightMedium";
import { FrontColors } from "../../models/FrontColors";
import { FrontFontSizes } from "../../models/FrontFontSizes";
import { FrontSpacing } from "../../models/FrontSpacing";
import { TContainerStateProps } from "../../state/TContainerStateProps";

const FrontTwoFactorAuthCardContainer = (props: TContainerStateProps): JSX.Element => {

    const getPhoneNumber = () => props.state.forms.buyer2FAPage.edited.primary_phone_number_obscured;

    const hasValidPhoneNumber = () =>
        getPhoneNumber() !== null &&
        props.state.forms.buyer2FAPage.status !== "validationError"
    ;

    const isEnteredCodeInvalid = () => props.state.forms.buyer2FAPage.children.submit_auth_code.status === "validationError";

    return (
        <FrontOutsideCard>
            <MediaPropSwitch<TPaddingProps>
                Component={Padding}
                mobile={{ type: "horizontal", spacing: "0px" }}
                desktop={{ type: "horizontal", spacing: FrontSpacing.MEDIUM_2 }}
                breakpoint="600"
            >
                <SpacingColumn spacing={FrontSpacing.LARGE_1}>
                    <SpacingColumn spacing={FrontSpacing.LARGE_2}>
                        {/* TITLE & PARAGRAPH */}
                        <SpacingColumn spacing={FrontSpacing.MEDIUM_2}>
                            <TextCenter>
                                <FrontCardTitle>
                                    Two Factor Authentication
                                </FrontCardTitle>
                            </TextCenter>
                            
                            {!hasValidPhoneNumber() &&
                                <>
                                    <TextCenter>
                                        <FrontParagraphRegular>
                                            You appear to have no record of a valid phone number with us.
                                        </FrontParagraphRegular>
                                    </TextCenter>
                                    <TextCenter>
                                        <FrontParagraphRegular>
                                            Please call us at <WeightBold>{sailLegalBrandConstants().legalCSNumber}</WeightBold> to set one up, and enable your <WeightBold>Two-Factor Authentication.</WeightBold>
                                        </FrontParagraphRegular>
                                    </TextCenter>
                                </>
                            }
                            
                            {hasValidPhoneNumber() &&
                                <TextCenter>
                                    <FrontParagraphRegular>
                                        A SMS has been sent to <WeightBold>{getPhoneNumber()}</WeightBold> containing your <WeightBold>authentication code</WeightBold>. Please enter it below.
                                    </FrontParagraphRegular>
                                </TextCenter>
                            }
                        </SpacingColumn>

                        {/* CODE INPUT */}
                        {hasValidPhoneNumber() &&
                            <SpacingColumn spacing={FrontSpacing.MEDIUM_1}>
                                
                                {/* IF CODE IS INCORRECT */}
                                {isEnteredCodeInvalid() && 
                                    <TextCenter>
                                        <FrontParagraphSmall>
                                            <TextColor color={FrontColors.ERROR_FIREBRICK_10}>
                                                <WeightMedium>
                                                    Code is incorrect
                                                </WeightMedium>
                                            </TextColor>
                                        </FrontParagraphSmall>
                                    </TextCenter>
                                }

                                <SpacingRow justifyContent="center">
                                    <FrontAuthCodeInput
                                        value={props.state.forms.buyer2FAPage.children.submit_auth_code.edited.auth_code}
                                        onChange={(payload) => props.dispatch({
                                            type: "2FA_SUBMIT_CODE",
                                            payload
                                        })}
                                        displayError={isEnteredCodeInvalid()}
                                    />
                                </SpacingRow>
                            </SpacingColumn>
                        }
                    </SpacingColumn>

                    {/* RESEND & CALL US */}
                    {hasValidPhoneNumber() &&
                        <SpacingColumn spacing={FrontSpacing.MEDIUM_1}>

                            {props.state.forms.buyer2FAPage.children.resend_auth_code.status === "success" &&
                                <TextCenter>
                                    <FontQuicksand>
                                        <FontSize size={FrontFontSizes.X_SMALL}>
                                            <TextColor color={FrontColors.POSITIVE_APPLE_2}>
                                                <WeightBold>
                                                    Code sent!
                                                </WeightBold>
                                            </TextColor>
                                        </FontSize>
                                    </FontQuicksand>
                                </TextCenter>
                            }
                            
                            {props.state.forms.buyer2FAPage.children.resend_auth_code.status === "failure" &&
                                <TextCenter>
                                    <FontQuicksand>
                                        <FontSize size={FrontFontSizes.X_SMALL}>
                                            <TextColor color={FrontColors.ERROR_FIREBRICK_4}>
                                                <WeightBold>
                                                    Something went wrong, please try again in a few seconds.
                                                </WeightBold>
                                            </TextColor>
                                        </FontSize>
                                    </FontQuicksand>
                                </TextCenter>
                            }
                            
                            {props.state.forms.buyer2FAPage.children.resend_auth_code.status === "untouched" &&
                                <CursorPointer
                                    onClick={() => props.dispatch({
                                        type: "2FA_RESEND_CODE",
                                        payload: null
                                    })}
                                >
                                    <TextCenter>
                                        <FontQuicksand>
                                            <FontSize size={FrontFontSizes.X_SMALL}>
                                                <Underline>
                                                    <WeightMedium>
                                                        Resend code?
                                                    </WeightMedium>
                                                </Underline>
                                            </FontSize>
                                        </FontQuicksand>
                                    </TextCenter>
                                </CursorPointer>
                            }

                            <TextCenter>
                                <FontQuicksand>
                                    <FontSize size={FrontFontSizes.X_SMALL}>
                                        If you can not sign in call us on {sailLegalBrandConstants().legalCSNumber}.
                                    </FontSize>
                                </FontQuicksand>
                            </TextCenter>
                        </SpacingColumn>
                    }

                </SpacingColumn>
            </MediaPropSwitch>
        </FrontOutsideCard>
    );
};

export default FrontTwoFactorAuthCardContainer;