import React from "react";
import { EditPropertyAddress } from "../../components/EditPropertyAddressComponent/EditPropertyAddress";
import { FormBody, FormRow, FormSection } from "../../components/FormTemplateBlocksComponents/FormTemplateBlocks";
import FormButton from "../../components/FormButtonComponent/FormButton";
import { enumToIDropdownOptions } from "../../functions/enumToIDropdownOptions";
import LabelWrapForInputs from "../../components/OldFront/Simple/Label/LabelWrapForInputs";
import { Dropdown } from "../../components/DropdownComponent/Dropdown";
import InputTextArea from "../../components/OldFront/Simple/TextArea/InputTextArea";
import * as ListingReasonForSale1 from "../../../../domain/models/ListingReasonForSale1";
import { TContainerStateProps } from "../../state/TContainerStateProps";

class CRMPartyAddListingContainer extends React.Component<React.PropsWithChildren<TContainerStateProps>> {
    public render(): JSX.Element {
        const getAddressFormState = this.props.state.activeData.crm.party.addListingGetAddressForm;
        const formState = this.props.state.activeData.crm.party.addListingForm;
        return (
            <FormBody>
                {/* SECTION: ADDRESS */}
                <FormSection title="Property Address">
                    <EditPropertyAddress
                        getAddressForm={{
                            status: getAddressFormState.status,
                            fields: getAddressFormState.form,
                            validationInnerErrors: getAddressFormState.meta.validationInnerErrors,
                        }}
                        propertyForm={{
                            status: formState.status,
                            fields: formState.form,
                            validationInnerErrors: formState.response.validationInnerErrors,
                        }}
                        getAddressInputChanged={
                            (key, value) => this.props.dispatch({
                                type: "CRM_PARTY_ADD_LISTING_GET_ADDRESS_INPUT_CHANGED",
                                payload: {
                                    key,
                                    value,
                                },
                            })
                        }
                        submitGetAddressLookup={
                            () => this.props.dispatch({type: "CRM_PARTY_ADD_LISTING_SUBMIT_ADDRESS_LOOKUP"})
                        }
                        propertyInputChanged={
                            (key, value) => this.props.dispatch({
                                type: "CRM_PARTY_ADD_LISTING_INPUT_CHANGED",
                                payload: {
                                    key,
                                    value,
                                },
                            })
                        }
                    />
                </FormSection>
                {/* SECTION: CIRCUMSTANCES AND NOTES */}
                <FormSection title="Circumstances & Notes" >
                    {/* PROPERTY NOTES */}
                    <FormRow>
                        <LabelWrapForInputs label="Property notes">
                            <InputTextArea
                                value={formState.form.admin_notes}
                                onChange={(value) => this.props.dispatch({
                                    type: "CRM_PARTY_ADD_LISTING_INPUT_CHANGED",
                                    payload: {
                                        key: "admin_notes",
                                        value,
                                    },
                                })}
                                onPressEnterKey={() => null}
                            />
                        </LabelWrapForInputs>
                    </FormRow>
                    {/* REASON FOR SALE */}
                    <FormRow>
                        <LabelWrapForInputs
                            required={false}
                            label="Reason for sale"
                        >
                            <Dropdown
                                value={formState.form.reason_for_sale}
                                options={enumToIDropdownOptions(
                                    ListingReasonForSale1.values,
                                    ListingReasonForSale1.fromValueToDisplayName
                                )}
                                onChange={(value) => this.props.dispatch({
                                    type: "CRM_PARTY_ADD_LISTING_INPUT_CHANGED",
                                    payload: {
                                        key: "reason_for_sale",
                                        value,
                                    },
                                })}
                            />
                        </LabelWrapForInputs>
                    </FormRow>
                </FormSection>
                <FormRow>
                    <FormButton
                        status={formState.status}
                        label="Save property"
                        type="frog"
                        fullWidth={true}
                        onClick={() => this.props.dispatch({type: "CRM_PARTY_ADD_LISTING_SUBMIT"})}
                    />
                </FormRow>
            </FormBody>
        );
    }
}

export default CRMPartyAddListingContainer;
