import React, { useEffect, useState } from "react";
import { CRMColors } from "../../../models/CRMColors";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { Background } from "../../BuildingBlocks/Background";
import { SpacingRow } from "../../BuildingBlocks/SpacingRow";
import { CRMIcon } from "../../CRMIcon/CRMIcon";
import { CRMSingleViewHeader } from "../../Simple/CRMSingleViewHeader/CRMSingleViewHeader";
import { TCaseCustomerSatisfactionForm, TCaseCustomerSatisfactionSentimentActionForm, isCaseCustomerSatisfactionSentimentActionForm, TCaseCustomerSatisfactionNoteTogglePinForm, TCaseCustomerSatisfactionNewNoteForm, TCaseCustomerSatisfactionNewSentimentForm, TCaseCustomerSatisfactionNewRaisedWithOmbudsmanForm, TCaseCustomerSatisfactionNewFormalisedAsComplaintForm, TCaseCustomerSatisfactionDeleteNoteForm, TCaseCustomerSatisfactionNoteActionForm, TCaseCustomerSatisfactionFormAction, TCaseCustomerSatisfactionRaisedWithOmbudsmanActionForm, TCaseCustomerSatisfactionDeleteSentimentForm, TCaseCustomerSatisfactionDeleteRaisedWithOmbudsmanForm, TCaseCustomerSatisfactionDeleteFormalisedAsComplaintForm, TCaseCustomerSatisfactionFormalisedAsComplaintActionForm, TCaseCustomerSatisfactionNewAssignmentForm, TCaseCustomerSatisfactionEmailActionForm, TCaseCustomerSatisfactionNewDeferForm } from "./../../../../../domain/codecs/form/CaseCustomerSatisfactionForm";
import { TTimelineSentiment } from "../../Simple/CRMTimelineSentiment/CRMTimelineSentiment";
import { CRMBottomControlBar } from "../../CRMBottomControlBar/CRMBottomControlBar";
import { Sticky } from "../../BuildingBlocks/Sticky";
import { WeightBold } from "../../WeightBold/WeightBold";
import { requireExhaustive } from "../../../../../shared/src/util";
import { Relative } from "../../BuildingBlocks/Relative";
import { CRMGrievanceAddNote } from "../CRMGrievanceAddNote/CRMGrievanceAddNote";
import { onChangeFormChildren } from "../../../../../shared/src/codecs/types/form";
import { TCaseCustomerSatisfactionSentiment } from "../../../../../domain/codecs/CaseCustomerSatisfaction";
import { useResolutionStatus } from "../../../hooks/useResolutionStatus";
import { CRMCustomerSatisfactionSingleViewControls } from "../CRMCustomerSatisfactionSingleViewControls/CRMCustomerSatisfactionSingleViewControls";
import { CRMSentimentIcon } from "../../Simple/CRMSentimentIcon/CRMSentimentIcon";
import { CRMStatusVariableConfirmationCountdown } from "../CRMStatusVariableConfirmationCountdown/CRMStatusVariableConfirmationCountdown";
import { CRMStatusVariableSectionPopupPrompt } from "../CRMStatusVariableSectionPopupPrompt/CRMStatusVariableSectionPopupPrompt";
import { CRMCustomerSatisfactionTimeline } from "../CRMCustomerSatisfactionTimeline/CRMCustomerSatisfactionTimeline";
import { CRMBlockPopover } from "../../CRM/CRMBlock/CRMBlockPopover/CRMBlockPopover";
import { CRMPopoutHeader } from "../../Simple/CRMPopoutHeader/CRMPopoutHeader";
import { CRMPlainFilterSearchSelect } from "../CRMPlainFilterSearchSelect/CRMPlainFilterSearchSelect";
import { CRMEmailSingleViewBody } from "../CRMEmailSingleViewBody/CRMEmailSingleViewBody";
import { TDetailedEmailForm, TriageSimpleEmailForm, TTriageSimpleEmailForm } from "../../../../../domain/codecs/form/TriageForm";
import { CRMBlockScrollCacheView } from "../../CRM/CRMBlock/CRMBlockViews/CRMBlockViews";

type TViewStatus =
    "whole-timeline"
    | "pinned-entries-timeline"
;

type TResolutionStatus = 
    "none"
    | "delete-raised-with-ombudsman"
    | "delete-raised-as-a-formal-complaint"
    | "delete-note"
    | "delete-sentiment"
;

type TPopupStatus =
    "none"
    | "add-note"
    | "reassign"
    | "mark-sad-sentiment"
    | "mark-happy-sentiment"
    | "raised-with-obudsman"
    | "raised-as-a-formal-complaint"
    | "delete-a-note"
    | "view-email"
    | "defer"
;

type TCustomerSatisfactionSingleViewProps = {
    form: TCaseCustomerSatisfactionForm;
    onChange: (form: TCaseCustomerSatisfactionForm) => void;
    onSubmitNewNote: (form: TCaseCustomerSatisfactionNewNoteForm) => void;
    onToggleNotePin: (form: TCaseCustomerSatisfactionNoteTogglePinForm) => void;
    onChangeSentiment: (form: TCaseCustomerSatisfactionNewSentimentForm) => void;
    onMarkAsRaisedWithTheObudsman: (form: TCaseCustomerSatisfactionNewRaisedWithOmbudsmanForm) => void;
    onMarkRaisedAsAFormalComplaint: (form: TCaseCustomerSatisfactionNewFormalisedAsComplaintForm) => void;
    onDefer: (form: TCaseCustomerSatisfactionNewDeferForm) => void;
    onReAssign: (form: TCaseCustomerSatisfactionNewAssignmentForm) => void;
    onOpenEmail: (action: TCaseCustomerSatisfactionEmailActionForm) => void;
    
    onDeleteNote: (form: TCaseCustomerSatisfactionDeleteNoteForm) => void;
    onDeleteSentiment: (form: TCaseCustomerSatisfactionDeleteSentimentForm) => void;
    onDeleteRaisedWithOmbudsman: (form: TCaseCustomerSatisfactionDeleteRaisedWithOmbudsmanForm) => void;
    onDeleteRaisedAsAFormalComplaint: (form: TCaseCustomerSatisfactionDeleteFormalisedAsComplaintForm) => void; 
};

export const CRMCustomerSatisfactionSingleView = (props: React.PropsWithChildren<TCustomerSatisfactionSingleViewProps>): JSX.Element => {

    const [viewStatus, setViewStatus] = useState<TViewStatus>("whole-timeline");
    const [popupViewStatus, setPopupViewStatus] = useState<TPopupStatus>("none");
    const [openedEmail, setOpenedEmail] = useState<TTriageSimpleEmailForm | null>(null);
    const {
        resolutionStatus,
        resolutionData,
        setResolution,
        resetResolution,
    } = useResolutionStatus<TResolutionStatus, TCaseCustomerSatisfactionFormAction>({
        defaultStatus: "none",
    });

    useEffect(
        () => {  
            setOpenedEmail(getOpenedEmail())
        },
        [props.form.children.actions]
    )

    const onChangeChildren = onChangeFormChildren(props.form, props.onChange);

    const isShowingAConfirmationPopup = () => (
        popupViewStatus !== "none" 
        && popupViewStatus !== "add-note"
        && popupViewStatus !== "reassign"
        && popupViewStatus !== "view-email"
    )

    const toggleView = () => setViewStatus(
        viewStatus === "whole-timeline" ?
            "pinned-entries-timeline"
            : "whole-timeline"
    );

    const resetPopupView = () => {
        setPopupViewStatus("none");
        setOpenedEmail(null);
    };

    const getSentiment = (): TTimelineSentiment => {
        const sentimentActions: Array<TCaseCustomerSatisfactionSentimentActionForm> =  props.form.children.actions.filter(
            isCaseCustomerSatisfactionSentimentActionForm
        );
        const lastAction: TCaseCustomerSatisfactionSentimentActionForm | null = sentimentActions.length > 0 ? 
            sentimentActions[sentimentActions.length-1] 
            : null
        ;
        return lastAction?.children.action.sentiment || "happy";
    }

    const onOpenEmail = (form: TCaseCustomerSatisfactionEmailActionForm) => {
        props.onOpenEmail(form);
        setPopupViewStatus("view-email");
        setOpenedEmail(wrapDetailedEmailIntoASimpleOne(form.children.detailed_email_form));
    }

    const getOpenedEmail = (): TTriageSimpleEmailForm | null => {
        
        let openedId = openedEmail?.children.detailed_email_form.original.id;
        let entries = getEntriesToShow();
        let emailEntries = entries
            .filter((entry) => entry.children.tag === "email") as Array<TCaseCustomerSatisfactionEmailActionForm>;
        
        let newOpenedEmail = emailEntries
            .find((entry) => entry.children.detailed_email_form.original.id === openedId);

        if (!!newOpenedEmail && newOpenedEmail.children.detailed_email_form.status === "success") {
            return wrapDetailedEmailIntoASimpleOne(newOpenedEmail.children.detailed_email_form);
        }

        return openedEmail;
    }

    const wrapDetailedEmailIntoASimpleOne = (detailedEmail: TDetailedEmailForm): TTriageSimpleEmailForm => 
        ({
            ...TriageSimpleEmailForm.newDefault(),
            children: {
                detailed_email_form: detailedEmail
            }
        })
    ;

    const getEntriesToShow = (): Array<TCaseCustomerSatisfactionFormAction> => {
        switch (viewStatus) {
            case "whole-timeline":
                return props.form.children.actions;
            case "pinned-entries-timeline":
                return props.form.children.actions.filter((action) => 
                    action.children.tag === "note"
                    && action.children.action.is_pinned
                );
            default:
                return requireExhaustive(viewStatus);
        }
    }

    const getChangedSentimentForm = (sentiment: TCaseCustomerSatisfactionSentiment ): TCaseCustomerSatisfactionNewSentimentForm => ({
        ...props.form.children.create_sentiment_action,
        edited: {
            ...props.form.children.create_sentiment_action.edited,
            sentiment
        }
    }) 

    const onChangeSentimentAsHappy = () =>
        props.onChangeSentiment(
            getChangedSentimentForm("happy")
        )
    ;
    
    const onChangeSentimentAsSad = () =>
        props.onChangeSentiment(
            getChangedSentimentForm("sad")
        )
    ;

    const onRaisedWithOmbudsman = () => {
        props.onMarkAsRaisedWithTheObudsman(props.form.children.create_raised_with_ombudsman_action);
    }
    
    const onMarkRaisedAsAFormalComplaint = () => {
        props.onMarkRaisedAsAFormalComplaint(props.form.children.create_formalised_as_complaint_action);
    }

    const onDefer = () => {
        props.onDefer(props.form.children.create_defer_action);
    }
    
    const onReAssign = (id: string) => {
        let action = props.form.children.assign_to_users_actions.find((actn) => actn.edited.assigned_to === id);
        if (action) {
            props.onReAssign(action);
            setPopupViewStatus("none");
        }
    };

    // DELETIONS
    const onCompleteDeletingNote = () => {
        props.onDeleteNote((resolutionData as TCaseCustomerSatisfactionNoteActionForm).children.delete_form);
        resetResolution();
    }

    const onCompleteDeletingSentiment = () => {
        props.onDeleteSentiment((resolutionData as TCaseCustomerSatisfactionSentimentActionForm).children.delete_form);
        resetResolution();
    }

    const onCompleteDeletingRaisedWithOmbudsman = () => {
        props.onDeleteRaisedWithOmbudsman((resolutionData as TCaseCustomerSatisfactionRaisedWithOmbudsmanActionForm).children.delete_form);
        resetResolution();
    }

    const onCompleteDeletingRaisedAsAFormalComplaint = () => {
        props.onDeleteRaisedAsAFormalComplaint((resolutionData as TCaseCustomerSatisfactionFormalisedAsComplaintActionForm).children.delete_form);
        resetResolution();
    }


    return (
        <CRMBlockScrollCacheView isShowing={true}>
            <Relative>
                {/* POPUP - VIEW EMAIL */}
                {popupViewStatus === "view-email" && !!openedEmail &&
                    <CRMBlockPopover maxHeight="700px">
                        <CRMEmailSingleViewBody
                            headerEdge="border"
                            email={openedEmail}
                            onClose={resetPopupView}
                        />
                    </CRMBlockPopover>
                }

                {/* POPUP - ADD NOTE */}
                {popupViewStatus === "add-note" &&
                    <CRMGrievanceAddNote
                        form={props.form.children.create_note_action}
                        onChange={onChangeChildren("create_note_action")}
                        onSubmit={() => props.onSubmitNewNote(props.form.children.create_note_action)}
                        onClose={resetPopupView}
                    />
                }

                {popupViewStatus === "reassign" &&
                    <CRMBlockPopover maxHeight="700px">
                        <CRMPopoutHeader
                            icon="arrow-left"
                            onClick={() => setPopupViewStatus("none")}
                        >
                            Assign to person
                        </CRMPopoutHeader>

                        <CRMPlainFilterSearchSelect
                            searchStickyTopPosition="51px"
                            options={props.form.children.assign_to_users_actions.map((actionForm) => ({
                                value: actionForm.edited.assigned_to,
                                label: actionForm.edited.assignee_name,
                            }))}
                            placeholder="Search for staff member by name"
                            onClick={onReAssign}
                        />
                    </CRMBlockPopover>
                }

                {isShowingAConfirmationPopup() &&
                    <CRMStatusVariableSectionPopupPrompt
                        status={popupViewStatus}
                        onNo={resetPopupView}
                        variations={{
                            "mark-sad-sentiment": {
                                title: "Change sentiment",
                                formStatus: props.form.children.create_sentiment_action.status,
                                body: <> You are about to change the satisfaction <WeightBold>sentiment</WeightBold> to <WeightBold>unhappy.</WeightBold> Proceed?</>,
                                onYes: onChangeSentimentAsSad
                            },
                            "mark-happy-sentiment": {
                                title: "Change sentiment",
                                formStatus: props.form.children.create_sentiment_action.status,
                                body: <> You are about to change the satisfaction <WeightBold>sentiment</WeightBold> to <WeightBold>happy.</WeightBold> Proceed?</>,
                                onYes: onChangeSentimentAsHappy
                            },
                            "raised-with-obudsman": {
                                title: "Raise with the ombudsman",
                                formStatus: props.form.children.create_raised_with_ombudsman_action.status,
                                body: <>You are about to note that the client has <WeightBold>raised a complaint with the obudsman.</WeightBold> Proceed?</>,
                                onYes: onRaisedWithOmbudsman
                            },
                            "raised-as-a-formal-complaint": {
                                title: "Raised as a formal complaint",
                                formStatus: props.form.children.create_formalised_as_complaint_action.status,
                                body: <>You are about to note that the client has <WeightBold>raised a formal complaint.</WeightBold> Proceed?</>,
                                onYes: onMarkRaisedAsAFormalComplaint
                            },
                            "defer": {
                                title: "Defer job",
                                formStatus: props.form.children.create_defer_action.status,
                                body: <>You are about to defer the "Review unhappy satisfaction job" for <WeightBold>7 days.</WeightBold> Proceed?</>,
                                onYes: onDefer
                            },
                        }}
                    />
                }

                {/* HEADER */}
                <Sticky zIndex={4}>
                    <CRMSingleViewHeader
                        title="Timeline"
                    >   
                        <Background padding={`0px ${CRMSpacing.MEDIUM} 0px 0px`}>
                            <SpacingRow spacing={CRMSpacing.SMALL}>
                                <CRMIcon
                                    title={viewStatus === "whole-timeline" ? "See pinned notes" : "See whole timeline"}
                                    iconName={viewStatus === "whole-timeline" ? "pin-list" : "timeline"}
                                    colour="neutral-ink"
                                    onClick={toggleView}
                                />

                                <CRMSentimentIcon sentiment={getSentiment()} />
                            </SpacingRow>
                        </Background>
                    </CRMSingleViewHeader>
                </Sticky>

                {/* BODY */}

                <Background color={CRMColors.NEUTRAL_PAPER}>
                    <CRMCustomerSatisfactionTimeline
                        entries={getEntriesToShow()}
                        onDeleteRaisedAsAFormalComplaint={setResolution("delete-raised-as-a-formal-complaint")}
                        onDeleteRaisedWithOmbudsman={setResolution("delete-raised-with-ombudsman")}
                        onDeleteNote={setResolution("delete-note")}
                        onDeleteSentiment={setResolution("delete-sentiment")}
                        onToggleNotePin={props.onToggleNotePin}
                        onOpenEmail={onOpenEmail}
                    />
                </Background>
                
                {/* CONTROLS */}
                <CRMBottomControlBar>
                    {resolutionStatus !== "none" &&
                        <CRMStatusVariableConfirmationCountdown
                            status={resolutionStatus}
                            onUndo={resetResolution}
                            variations={{
                                "delete-note": ["Deleting Note!", onCompleteDeletingNote],
                                "delete-sentiment": ["Deleting Sentiment!", onCompleteDeletingSentiment],
                                "delete-raised-as-a-formal-complaint": ["Deleting Event!", onCompleteDeletingRaisedAsAFormalComplaint],
                                "delete-raised-with-ombudsman": ["Deleting Event!", onCompleteDeletingRaisedWithOmbudsman]
                            }}
                        />
                    }

                    {/* CONTROLS */}
                    {resolutionStatus === "none" &&
                        <CRMCustomerSatisfactionSingleViewControls
                            onAddNote={() => setPopupViewStatus("add-note")}
                            onReAssign={() => setPopupViewStatus("reassign")}
                            onSetSentimentAsHappy={() => setPopupViewStatus("mark-happy-sentiment")}
                            onSetSentimentAsSad={() => setPopupViewStatus("mark-sad-sentiment")}
                            onMarkAsRaisedAsAFormalComplaint={() => setPopupViewStatus("raised-as-a-formal-complaint")}
                            onMarkAsRaisedWithTheObudsman={() => setPopupViewStatus("raised-with-obudsman")}
                            onDefer={() => setPopupViewStatus("defer")}
                        />
                    }
                </CRMBottomControlBar>
            </Relative>
        </CRMBlockScrollCacheView>
    );
};