import React from "react";

type TCRMSoloColumn = {
    size: "third" | "half";
};

export class CRMSoloColumn extends React.Component<React.PropsWithChildren<TCRMSoloColumn>> {
    public render (): JSX.Element {
        return (
            <div className={`crm-solo-column`}>
                <div className={`crm-solo-column__body crm-solo-column__body--${this.props.size}`}>
                    {this.props.children}
                </div>
            </div>
        );
    }
}
