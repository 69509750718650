import { array } from "fp-ts";
import { pipe } from "fp-ts/lib/function";
import { DateTime } from "luxon";
import React from "react";
import { requireExhaustive } from "../shared/src/util";
import { CasePropertyTitleClass_displayString } from "./codecs/CasePropertyTitleClass";
import { TLandRegistryOfficialCopyWithSummaryResponseSuccess, TNormalisedDeathOfProprietorNotice, TNormalisedRestriction, TNormalisedAgreedNotice, TNormalisedEntry, TNormalisedHomeRightsEntry, TNormalisedRegisteredCharge, TNormalisedRentChargeEntry, TNormalisedEntryCharge, TNormalisedCharge, NormalisedRegisteredCharge, NormalisedDiscountCharge, TNormalisedDiscountCharge, NormalisedNotedCharge, TNormalisedNotedCharge, NormalisedEquitableCharge, TNormalisedEquitableCharge } from "./codecs/LandRegistry";
import { Tenure1_Tenure2 } from "./codecs/Tenure";
import { fromNumber } from "./models/TCurrency";
import { is } from "../shared/src/codecs/codec";

const PositiveStatement = (props: React.PropsWithChildren): JSX.Element =>
    <div style={{
        display: "inline",
        fontWeight: 700,
        color: "#139159",
    }}>
        {props.children}
    </div>;

const NegativeStatement = (props: React.PropsWithChildren): JSX.Element =>
    <div style={{
        display: "inline",
        fontWeight: 700,
        color: "#DF3B22",
    }}>
        {props.children}
    </div>;

const NeutralStatement = (props: React.PropsWithChildren): JSX.Element =>
    <div style={{
        display: "inline",
        fontWeight: 700,
    }}>
        {props.children}
    </div>;

const WeightMedium = (props: React.PropsWithChildren): JSX.Element =>
    <div style={{
        display: "inline",
        fontWeight: 500
    }}>
        {props.children}
    </div>;

export const propertyReportDisplayData = (
    deceasedName: string,
    data: TLandRegistryOfficialCopyWithSummaryResponseSuccess,
) => {
    const proprietors = data.proprietors.map(({name}) => name).join(" & ");

    const proprietorsCount = data.proprietors.length;

    const hasProprietors = proprietorsCount > 0;

    const restrictions = data.restrictions.filter((restriction) => restriction.type !== "joint_proprietor");

    const isRegistered = data.isRegistered;

    const hasJointProprietorRestriction =
        pipe(
            data.restrictions,
            array.filter((restriction) => restriction.type === "joint_proprietor"),
            (a) => a.length > 0,
        );

    const isJointTenants =
        proprietorsCount >= 2
        && !hasJointProprietorRestriction;

    const tenure = Tenure1_Tenure2(data.tenure);

    const ownershipYears =
        data.lastRegistrationDate
            ? Math.floor(DateTime.utc().diff(DateTime.fromISO(data.lastRegistrationDate)).as("years"))
            : null;

    const leaseYearsRemaining =
        data.earliestLeaseApproximateExpiry
            ? Math.floor(DateTime.fromISO(data.earliestLeaseApproximateExpiry).diff(DateTime.utc()).as("years"))
            : null;

    const deceasedNameMatches =
        pipe(
            data.proprietors,
            array.filter(({name}) => name.toLowerCase().trim() === deceasedName.toLowerCase().trim())
        ).length > 0;

    const getDeathOfProprietorNoticeDisplayValue = (notice: TNormalisedDeathOfProprietorNotice): JSX.Element =>
        notice.deceased
            ? <>Land Registry have been notified of the death of <NeutralStatement>{notice.deceased}.</NeutralStatement></>
            : <>Land registry have received the following <NeutralStatement>death notice: </NeutralStatement> "{notice.entryText}".</>;

    const proprietorsList = [
        ...(hasProprietors ? [<>This property is owned by <NeutralStatement>{proprietors}.</NeutralStatement></>] : []),
        ...(hasJointProprietorRestriction ? [<><NeutralStatement>There is a sole proprietor restriction</NeutralStatement>, meaning the property is held as tenants in common. <NegativeStatement>You may require a conveyancer's help to transfer ownership of the property.</NegativeStatement></>] : []),
        ...(isJointTenants ? [<>The owners hold the property as <PositiveStatement>joint tenants.</PositiveStatement></>] : []),
        ...(isRegistered && deceasedNameMatches === false ? [<><NegativeStatement>The deceased's name doesn't exactly match</NegativeStatement> the data Land Registry have. Check you have the correct name of the deceased.</>] : []),
        ...(data.firstRegistrationCaution.status === "found" && data.firstRegistrationCaution.beneficiary ? [<><NeutralStatement>{data.firstRegistrationCaution.beneficiary}</NeutralStatement> has registered a <NegativeStatement>caution against first registration</NegativeStatement> on the property. This means that {data.firstRegistrationCaution.beneficiary} have told Land Registry that before the property can be registered for the first time permission must be sought first. It could be that {data.firstRegistrationCaution.beneficiary} have a financial or other interest in the property. Sail Legal will need to investigate this further.</>] : []),
        ...(data.firstRegistrationCaution.status === "found" && (data.firstRegistrationCaution.beneficiary === null || data.firstRegistrationCaution.beneficiary === "") ? [<><NegativeStatement>A caution against first registration</NegativeStatement> has been registered on the property. This means that someone has told Land Registry that before the property can be registered for the first time permission must be sought first. It could be that they have a financial or other interest in the property. Sail Legal will need to investigate this further.</>] : []),
        ...pipe(
            data.deathOfProprietorNotices,
            array.map(getDeathOfProprietorNoticeDisplayValue),
        )
    ];

    const hasProprietorsSection = proprietorsList.length > 0;

    const getRestrictionDisplayValue = (restriction: TNormalisedRestriction): JSX.Element =>
        (
            restriction.type === "charge"
            || restriction.type === "charge_related"
        ) ? <><NeutralStatement>{restriction.relatedChargeBeneficiary || "A beneficiary of a charge"}</NeutralStatement> require their charge be <NegativeStatement>removed on sale or transfer of ownership.</NegativeStatement></>
        : (
            restriction.type === "non_charge"
            && restriction.registrationDate !== null
        ) ? <>On <WeightMedium>{DateTime.fromISO(restriction.registrationDate).toFormat("d LLLL yyyy")}</WeightMedium> the following restriction was registered against the property: <NeutralStatement>"{restriction.entryText}"</NeutralStatement>. <NegativeStatement>Sail Legal will be able to assist with the removal of this restriction.</NegativeStatement></>
        : restriction.type === "non_charge" ? <>The following restriction is registered against the property: <NeutralStatement>"{restriction.entryText}"</NeutralStatement>. <NegativeStatement>A conveyancer will be able to assist with the removal of this restriction.</NegativeStatement></>
        : restriction.type === "joint_proprietor" ? <></> // These are being excluded from restrictions anyway
        : requireExhaustive(restriction.type);

    const getAgreedNoticeDisplayValue = (agreedNotice: TNormalisedAgreedNotice): JSX.Element =>
        agreedNotice.type === "option_to_purchase" && agreedNotice.deedParty ? <><NeutralStatement>An option to purchase notice</NeutralStatement> is on the title, made by parties: "{agreedNotice.deedParty}". <NegativeStatement>This notice gives the party listed the opportunity to purchase the property at any time.</NegativeStatement> It is important to investigate this party's involvement.</>
        : agreedNotice.type === "option_to_purchase" && agreedNotice.deedParty === "" ? <><NeutralStatement>An option to purchase notice</NeutralStatement> is on the title. <NegativeStatement>This notice gives another party the opportunity to purchase the property at any time.</NegativeStatement> It is important to investigate this party's involvement.</>
        : agreedNotice.type === "right_of_preemption" && agreedNotice.deedParty ? <><NeutralStatement>A right of pre-emption notice (also known as a first refusal notice)</NeutralStatement> is on the title, made by parties: "{agreedNotice.deedParty}". <NegativeStatement>This notice gives the party listed the opportunity to purchase the property first before the property is next sold or transferred.</NegativeStatement> If you aren't using Sail Homes & Sail Legal you must make this party aware of the change in circumstances.</>
        : agreedNotice.type === "right_of_preemption" && agreedNotice.deedParty === "" ? <><NeutralStatement>A right of pre-emption notice (also known as a first refusal notice)</NeutralStatement> is on the title. <NegativeStatement>This notice gives another party the opportunity to purchase the property first before the property is next sold or transferred.</NegativeStatement> If you aren't using Sail Homes & Sail Legal you must make this party aware of the change in circumstances.</>
        : <>The following agreed notice is present: <NeutralStatement>"{agreedNotice.entryText}".</NeutralStatement> <NegativeStatement>You may need to petition Land Registry for the removal of this if the interest has come to an end.</NegativeStatement> <PositiveStatement>Sail Legal can take care of this for you.</PositiveStatement></>

    const getCautionEntryDisplayValue = (entry: TNormalisedEntry): JSX.Element =>
        <><NegativeStatement>The following caution is registered against the property:</NegativeStatement> "{entry.entryText}". <PositiveStatement>Sail Legal will be able to assist with this.</PositiveStatement></>

    const getHomeRightEntryDisplayValue = (entry: TNormalisedHomeRightsEntry): JSX.Element =>
        entry.parties
        ? <>A home rights entry involving <NeutralStatement>{entry.parties}'s</NeutralStatement> right to live at the property exists. <NegativeStatement>Unless this is removed the property will not be able to be sold.</NegativeStatement> <PositiveStatement>Sail Legal can assist with this.</PositiveStatement></>
        : <>The following home rights entry exists: "{entry.entryText}". <NegativeStatement>Unless this is removed the property will not be able to be sold.</NegativeStatement> <PositiveStatement>Sail Legal can assist with this.</PositiveStatement></>

    const getChargeDisplayValue = (charge: TNormalisedRegisteredCharge): JSX.Element =>
        (
            charge.proprietor !== null
            && charge.registrationDate !== null
        ) ? <>A financial interest benefitting <NeutralStatement>{charge.proprietor}</NeutralStatement> was registered against the property on <WeightMedium>{DateTime.fromISO(charge.registrationDate).toFormat("d LLLL yyyy")}</WeightMedium>. <NegativeStatement>This may need to be removed as part of a sale or transfer of ownership, Sail Legal will be able to advise in more detail.</NegativeStatement></>
        : charge.proprietor !== null ? <>A financial interest benefitting <NeutralStatement>{charge.proprietor}</NeutralStatement> was registered against the property. <NegativeStatement>A conveyancer may need to remove this as part of a sale or transfer of ownership.</NegativeStatement></>
        : <>The following financial interest has been registered against the property: <NeutralStatement>"{charge.entryText}"</NeutralStatement>. <NegativeStatement>A conveyancer may need to remove this as part of a sale or transfer of ownership.</NegativeStatement></>

    const getRentChargeDisplayValue = (rentCharge: TNormalisedRentChargeEntry): JSX.Element =>
        rentCharge.rentChargeAmountString
            ? <>The land is <NegativeStatement>subject to an annual rentcharge of {rentCharge.rentChargeAmountString}.</NegativeStatement> You will need a receipt of payment, or pay any outstanding debt. Or you can get an indemnity policy to cover.</>
            : <>The land is <NegativeStatement>subject to the following rentcharge:</NegativeStatement> "{rentCharge.entryText}". You will need a receipt of payment, or pay any outstanding debt. Or you can get an indemnity policy to cover.</>;

    const getDiscountChargeDisplayValue = (charge: TNormalisedEntryCharge): JSX.Element =>
        <>The following discount charge is registered: "{charge.entryText}".</>;

    const getNotedChargeDisplayValue = (charge: TNormalisedEntryCharge): JSX.Element =>
        charge.proprietor
            ? <>There is a <NegativeStatement>noted charge</NegativeStatement> registered against the property benefitting <NeutralStatement>{charge.proprietor}.</NeutralStatement></>
            : <>The following <NegativeStatement>noted charge</NegativeStatement> is registered against the property: "{charge.entryText}".</>;

    const getEquitableChargeDisplayValue = (charge: TNormalisedEntryCharge): JSX.Element =>
        charge.proprietor
            ? <>There is a <NegativeStatement>equitable charge</NegativeStatement> registered against the property benefitting <NeutralStatement>{charge.proprietor}.</NeutralStatement></>
            : <>The following <NegativeStatement>equitable charge</NegativeStatement> is registered against the property: "{charge.entryText}".</>;

    const getCreditorsNoticeDisplayValue = (entry: TNormalisedEntry): JSX.Element =>
        <>The following <NegativeStatement>creditors notice</NegativeStatement> is registered: "{entry.entryText}".</>;

    const hasVendorsLienEntries = data.vendorsLienEntries.length > 0;
    
    const registeredCharges =
        pipe(
            data.charges,
            array.filter<TNormalisedCharge, TNormalisedRegisteredCharge>((p): p is TNormalisedRegisteredCharge => is(NormalisedRegisteredCharge, p)),
        );

    const discountCharges =
        pipe(
            data.charges,
            array.filter<TNormalisedCharge, TNormalisedDiscountCharge>((p): p is TNormalisedDiscountCharge => is(NormalisedDiscountCharge, p)),
        );

    const notedCharges =
        pipe(
            data.charges,
            array.filter<TNormalisedCharge, TNormalisedNotedCharge>((p): p is TNormalisedNotedCharge => is(NormalisedNotedCharge, p)),
        );

    const equitableCharges =
        pipe(
            data.charges,
            array.filter<TNormalisedCharge, TNormalisedEquitableCharge>((p): p is TNormalisedEquitableCharge => is(NormalisedEquitableCharge, p)),
        );

    const chargesList = [
        ...pipe(
            registeredCharges,
            array.map(getChargeDisplayValue),
        ),
        ...pipe(
            data.rentChargeEntries,
            array.map(getRentChargeDisplayValue),
        ),
        ...pipe(
            discountCharges,
            array.map(getDiscountChargeDisplayValue),
        ),
        ...pipe(
            notedCharges,
            array.map(getNotedChargeDisplayValue),
        ),
        ...pipe(
            equitableCharges,
            array.map(getEquitableChargeDisplayValue),
        ),
        ...pipe(
            data.creditorsNotices,
            array.map(getCreditorsNoticeDisplayValue),
        ),
    ];

    const chargesCount = chargesList.length;

    const hasChargesSection = chargesList.length > 0;

    const hasBankruptcyNotices = data.bankruptcyEntries.length > 0;

    const hasDeedOfPostponementEntries = data.deedOfPostponementEntries.length > 0;

    const hasGreenOutEntries = data.greenOutEntries.length > 0;

    const issuesList = [
        // Restrictions
        ...pipe(
            restrictions,
            array.map(getRestrictionDisplayValue),
        ),

        // Agreed notices
        ...pipe(
            data.agreedNotices,
            array.map(getAgreedNoticeDisplayValue),
        ),

        ...(hasBankruptcyNotices ? [<><NegativeStatement>The owners have declared bankruptcy.</NegativeStatement> This can cause significant issues when selling the property.</>] : []),

        // Caution entries
        ...pipe(
            data.cautionEntries,
            array.map(getCautionEntryDisplayValue),
        ),

        ...(hasDeedOfPostponementEntries ? [<><NegativeStatement>A deed of postponement exists.</NegativeStatement> <PositiveStatement>Sail Legal will be able to discuss the significance of this.</PositiveStatement></>] : []),
        ...(hasGreenOutEntries ? [<><NegativeStatement>Green out entries have been made to the plan.</NegativeStatement> These highlight a change in ownership to areas of the plan edged in green. <PositiveStatement>Sail Legal will be able to review this and check for any issues.</PositiveStatement></>] : []),

        // Home rights entries
        ...pipe(
            data.homeRightsEntries,
            array.map(getHomeRightEntryDisplayValue),
        ),

        ...(hasVendorsLienEntries ? [<><NegativeStatement>A vendors lien entry exists.</NegativeStatement> This indicates that in certain circumstances the property can be repossessed by the original seller.</>] : []),
    ];

    const issuesCount = issuesList.length;

    const hasIssues = issuesCount > 0;

    const propertyList = [
        ...(isRegistered === false ? [
            <NegativeStatement>The property is unregistered.</NegativeStatement>,
            <>Unregistered properties <NeutralStatement>must</NeutralStatement> be registered with Land Registry for the first time on sale or transfer of ownership, to do this you will need to locate the original deeds. <PositiveStatement>Talk to Sail Legal if you are worried these have been lost or damaged.</PositiveStatement></>,
            <>Some Estate Agents and Conveyancers struggle to sell unregistered properties and complete the complex legal work. <PositiveStatement>Sail Homes & Sail Legal are experienced and able to help.</PositiveStatement></>
        ] : []),
        ...(data.titleClass === "absolute" ? [<>The property title class is <PositiveStatement>{CasePropertyTitleClass_displayString(data.titleClass).toLowerCase()}.</PositiveStatement></>] : []),
        ...((data.titleClass !== "unknown" && data.titleClass !== "absolute") ? [<>The property title class is <NegativeStatement>{CasePropertyTitleClass_displayString(data.titleClass).toLowerCase()}.</NegativeStatement> This is less than ideal, it may be time to look to upgrade the class with Land Registry or to purchase an indemnity policy for future owners.</>] : []),
        ...(data.tenure !== "unknown" ? [<>The property tenure is <NeutralStatement>{tenure}.</NeutralStatement></>] : []),
        ...(data.tenure === "leasehold" || data.tenure === "commonhold" ? [<>{tenure} properties may be subject to recent changes in legislation, like the Building Safety Act, <NegativeStatement>that make them difficult to sell.</NegativeStatement> Sail Legal will be able to provide more guidance here.</>] : []),
        ...(data.lastPricePaid && data.lastPricePaid.date !== null ? [<>On {DateTime.fromISO(data.lastPricePaid.date).toFormat("d LLLL yyyy")} the price paid for this property was <NeutralStatement>{fromNumber(data.lastPricePaid.amountPence / 100)}</NeutralStatement>.</>] : []),
        ...(data.lastPricePaid && data.lastPricePaid.date === null ? [<>The last price paid for this property was <NeutralStatement>{fromNumber(data.lastPricePaid.amountPence / 100)}</NeutralStatement>.</>] : []),
        ...(ownershipYears !== null && ownershipYears > 1 ? [<>The property has been owned by the current owners for <PositiveStatement>{ownershipYears} years.</PositiveStatement></>] : []),
        ...(ownershipYears !== null && ownershipYears <= 1 ? [<>The property has been owned by the current owners for <NegativeStatement>less than 1 year.</NegativeStatement> There is an increased risk of property fraud that must be considered.</>] : []),
        ...((data.groundRentsTotalPence || 0) > 25000 ? [<>The ground rent is <NegativeStatement>over £250 per annum.</NegativeStatement> This may make getting a mortgage difficult.</>] : []),
        ...(leaseYearsRemaining !== null && leaseYearsRemaining < 1 ? [<>The property lease has <NegativeStatement>less than one year remaining.</NegativeStatement> This will severely impact the value.</>] : []),
        ...(leaseYearsRemaining !== null && leaseYearsRemaining <= 80 && leaseYearsRemaining >= 1 ? [<>The property lease has only <NegativeStatement>{leaseYearsRemaining} years remaining.</NegativeStatement> This will severely impact the value.</>] : []),
        ...(leaseYearsRemaining !== null && leaseYearsRemaining > 80 ? [<>The property lease has <PositiveStatement>{leaseYearsRemaining} years remaining.</PositiveStatement></>] : []),
    ];

    return {
        hasProprietorsSection,
        proprietorsList,
        propertyList,
        hasChargesSection,
        chargesCount,
        chargesList,
        hasIssues,
        issuesCount,
        issuesList
    };
};