import React from "react";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { TCRMMainColours } from "../../../models/TCRMMainColours";
import { CRMPageBody } from "../../Simple/CRMPageBody/CRMPageBody";
import { Padding } from "../../BuildingBlocks/Padding";
import { SpacingColumn } from "../../BuildingBlocks/SpacingColumn";
import { SpacingRow } from "../../BuildingBlocks/SpacingRow";
import { TIcon } from "../../CRMIcon/CRMIcon";
import { useSelectedTab } from "../../../hooks/useSelectedTab";
import { CRMPageTab } from "../../Simple/CRMPageTab/CRMPageTab";
import { Sticky } from "../../BuildingBlocks/Sticky";

type TCRMTabPageProps = {
    tabs: Array<TTabPageTab>;
};

type TTabPageTab = {
    label: string;
    icon?: TIcon;
    iconColor?: TCRMMainColours;
    content: JSX.Element;
};

export const CRMTabPage = (props: TCRMTabPageProps): JSX.Element => {

    const {
        isSelectedTabIndex,
        setSelectedTabIndex,
        getSelectedTab
    } = useSelectedTab<TTabPageTab>(props.tabs);

    return (
        <CRMPageBody>
            <Padding type="top" spacing={CRMSpacing.LARGE}>
                <SpacingRow
                    childSize="2fr 1fr"
                    spacing={CRMSpacing.X_LARGE}
                    alignItems="flex-start"
                >
                    {getSelectedTab() !== null &&
                        getSelectedTab()?.content
                    }

                    <Sticky top={CRMSpacing.LARGE}>
                        <SpacingColumn spacing={CRMSpacing.TINY}>
                            {props.tabs.map((tab, index) => (
                                <CRMPageTab
                                    key={index}
                                    isSelected={isSelectedTabIndex(index)}
                                    onClick={() => setSelectedTabIndex(index)}
                                    {...tab}
                                />
                            ))}
                        </SpacingColumn>
                    </Sticky>
                </SpacingRow>
            </Padding>
        </CRMPageBody>
    );
};
