import React from "react";
import { TContainerStateProps } from "./../../state/TContainerStateProps";
import { CRMCardOutsideLabelled } from "../../components/CRMCardOutsideLabelled/CRMCardOutsideLabelled";
import { CRMParagraph } from "../../components/Simple/CRMParagraph/CRMParagraph";
import { CRMSpacingColumn } from "../../components/CRMSpacingColumn/CRMSpacingColumn";
import { WeightBold } from "../../components/WeightBold/WeightBold";
import { FontSize } from "../../components/BuildingBlocks/FontSize";
import { Uppercase } from "../../components/Uppercase/Uppercase";
import { CRMFontSizes } from "../../models/CRMFontSizes";
import { CRMHorizontalSeperatorLine } from "../../components/CRMHorizontalSeperatorLine/CRMHorizontalSeperatorLine";
import { WeightMedium } from "../../components/WeightMedium/WeightMedium";
import { WeightSemiBold } from "../../components/WeightSemiBold/WeightSemiBold";
import { SpacingColumn } from "../../components/BuildingBlocks/SpacingColumn";
import { CRMSpacing } from "../../models/CRMSpacing";
import { SpacingRow } from "../../components/BuildingBlocks/SpacingRow";
import { requireExhaustive } from "../../../../shared/src/util";
import { CaseDualRepLinkedForm } from "../../../../domain/codecs/form/CaseForm";
import { CRMIcon } from "../../components/CRMIcon/CRMIcon";

const CRMCaseDualRepCardContainer = (props: TContainerStateProps): JSX.Element => {
    const linkedCaseForm =
        props.state.forms.case_details_page.data.output.details.children.dual_rep.linked_case_form
        || CaseDualRepLinkedForm.newDefault();

    const awaitingConsentOnOtherSide = () =>
        !linkedCaseForm.children.at_least_one_other_side_clients_consent;

    const awaitingConsentOnOurSide = () =>
        !linkedCaseForm.children.at_least_one_our_side_clients_consent;

    const noUsersAddedOnOurSideToConsent = () =>
        allOurSideConsents().length === 0;

    const allOurSideConsents = () =>
        linkedCaseForm.children.our_side_client_consents || [];

    const thisCaseCanBeWorkedOn = () =>
        linkedCaseForm.children.our_case_can_be_worked_on;

    const dualRepTypeDisplayString = () =>
        linkedCaseForm.children.type === "original" ? "dual rep priority"
        : linkedCaseForm.children.type === "dual_rep" ? "dual rep non-priority"
        : requireExhaustive(linkedCaseForm.children.type);

    return (
        <CRMCardOutsideLabelled
            labelColor="highlights-calming-pink-4"
            shadow={true}
        >
            <CRMParagraph>
                <CRMSpacingColumn spacing="medium">
                    <FontSize size={CRMFontSizes.SMALL} lineHeight="1.2">
                        <Uppercase>
                            <WeightBold>
                                Dual Rep Consent
                            </WeightBold>
                        </Uppercase>
                    </FontSize>
                    
                    {/* CONSENT DUE ON OUR SIDE */}
                    {awaitingConsentOnOurSide() &&
                        <CRMParagraph>
                            Some of this case's clients <WeightSemiBold>haven't</WeightSemiBold> agreed to the terms of Dual Rep yet.
                        </CRMParagraph>
                    }

                    {/* CONSENT NOT DUE ON OUR SIDE */}
                    {!awaitingConsentOnOurSide() && !noUsersAddedOnOurSideToConsent() &&
                        <CRMParagraph>
                            At least one of this case's clients have agreed to the terms of Dual Rep.
                        </CRMParagraph>
                    }

                    {/* NO USERS ADDED ON OUR SIDE YET */}
                    {noUsersAddedOnOurSideToConsent() &&
                        <CRMParagraph>
                            No users have been added to this case who need to consent to Dual Rep.
                        </CRMParagraph>
                    }

                    {/* OUR SIDE USERS CONSENT STATUS */}
                    {!noUsersAddedOnOurSideToConsent() &&
                        <SpacingColumn spacing={CRMSpacing.TINY}>
                            {allOurSideConsents().map(({name, has_consented}, i) =>
                                <SpacingRow
                                    key={i}
                                    spacing={CRMSpacing.TINY}
                                    justifyContent="space-between"
                                >
                                    <div>{name}</div>

                                    {/* HAS CONSENTED */}
                                    {has_consented &&
                                        <WeightMedium>Consented</WeightMedium>
                                    }

                                    {/* HASN'T CONSENTED */}
                                    {!has_consented &&
                                        <WeightSemiBold>Hasn't consented</WeightSemiBold>
                                    }
                                </SpacingRow>
                            )}
                        </SpacingColumn>
                    }

                    {/* CONSENT DUE ON OTHER SIDE */}
                    {awaitingConsentOnOtherSide() &&
                        <>
                            <CRMHorizontalSeperatorLine />
                            <CRMParagraph>
                                The other side's clients <WeightSemiBold>haven't</WeightSemiBold> agreed to the terms of Dual Rep yet.
                            </CRMParagraph>
                        </>
                    }

                    {/* CAN THIS CASE BE WORKED ON */}
                    <CRMHorizontalSeperatorLine />
                    <SpacingRow
                        spacing={CRMSpacing.MEDIUM}
                        justifyContent="start"
                        alignItems="flex-start"
                    >
                        <CRMIcon
                            iconName={thisCaseCanBeWorkedOn() ? "done" : "hand"}
                            size="tiny"
                            colour="neutral-0"
                        />
                        <CRMParagraph>
                            This is the <WeightSemiBold>{dualRepTypeDisplayString()}</WeightSemiBold> case and <WeightBold>{thisCaseCanBeWorkedOn() ? "can be worked on." : "can not be worked on yet."}</WeightBold>
                        </CRMParagraph>
                    </SpacingRow>
                </CRMSpacingColumn>
            </CRMParagraph>
        </CRMCardOutsideLabelled>
    );
}

export default CRMCaseDualRepCardContainer;