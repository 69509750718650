import * as rxjs from "rxjs";
import * as JsonInnerError1 from "../../../../domain/models/JsonInnerError1";
import * as TFormStatus from "../../models/TFormStatus";
import * as TForm from "../../models/TForm";
import { TState, TUnpackArray } from "./lensBaseTypes";
import { reduceDataToStateUpdate } from "./reduceDataToStateUpdate";
import { setWhere } from "./setWhere";
import { TSetState } from "../../state/TSetState";
import { TValidationError } from "../../../../shared/src/validation/Error";

interface ILensHandleFormSubmitErrorsWhere<S> {
    // 5 level lens path guard
    <
        K1 extends keyof S,
        K2 extends keyof S[K1],
        K3 extends keyof S[K1][K2],
        K4 extends keyof S[K1][K2][K3],
        K5 extends keyof S[K1][K2][K3][K4]
    >
    (
        path: [K1, K2, K3, K4, K5],
        whereCallback: (value: TUnpackArray<S[K1][K2][K3][K4][K5]>) => boolean
    ): (error: TValidationError) => rxjs.Observable<never>;
    // 4 level lens path guard
    <
        K1 extends keyof S,
        K2 extends keyof S[K1],
        K3 extends keyof S[K1][K2],
        K4 extends keyof S[K1][K2][K3]
    >
    (
        path: [K1, K2, K3, K4],
        whereCallback: (value: TUnpackArray<S[K1][K2][K3][K4]>) => boolean
    ): (error: TValidationError) => rxjs.Observable<never>;
    // 3 level lens path guard
    <
        K1 extends keyof S,
        K2 extends keyof S[K1],
        K3 extends keyof S[K1][K2]
    >
    (
        path: [K1, K2, K3],
        whereCallback: (value: TUnpackArray<S[K1][K2][K3]>) => boolean
    ): (error: TValidationError) => rxjs.Observable<never>;
    // 2 level lens path guard
    <
        K1 extends keyof S,
        K2 extends keyof S[K1]
    >
    (
        path: [K1, K2],
        whereCallback: (value: TUnpackArray<S[K1][K2]>) => boolean
    ): (error: TValidationError) => rxjs.Observable<never>;
    // 1 level lens path guard
    <K1 extends keyof S>
    (
        path: [K1],
        whereCallback: (value: TUnpackArray<S[K1]>) => boolean
    ): (error: TValidationError) => rxjs.Observable<never>;
}

export const handleFormSubmitErrorsInFormListWhere = (setState: TSetState): ILensHandleFormSubmitErrorsWhere<TState> =>
    // There is no way to create paramater overloads here so we set to any
    (lensPath: any, whereCallback: any) => // eslint-disable-line
        (errors: TValidationError) => {
            reduceDataToStateUpdate<TValidationError>(setState)(
                setWhere<TValidationError>()(
                    lensPath,
                    whereCallback,
                    /* eslint-disable */
                    // The type is always expected to be never for the form, since we have abstraced the lens scoping,
                    // which is untrue
                    // @ts-ignore
                    (updateForm: TForm.TFormV2<{}, {}>) => {
                        updateForm.status = TFormStatus.constants.VALIDATION_ERROR;
                        updateForm.validationErrors = JsonInnerError1.arrayFromValidationErrors("Body", errors);
                        return updateForm;
                    }
                    /* eslint-enable */
                )
            )(errors);
            return rxjs.EMPTY;
        };
