import React, { CSSProperties } from "react";

type TSimpleGridProps = {
    templateColumns: CSSProperties["gridTemplateColumns"];
    templateRows?: CSSProperties["gridTemplateRows"];
    columnSpacing?: string;
    rowSpacing?: string;
    alignItems?: CSSProperties["alignItems"];
};

export const SimpleGrid = (props: React.PropsWithChildren<TSimpleGridProps>): JSX.Element => {
    return (
        <div
            style={{
                width: "100%",
                display: "grid",
                gridColumnGap: props.columnSpacing || "0px",
                gridRowGap: props.rowSpacing || "0px",
                gridTemplateColumns: props.templateColumns,
                gridTemplateRows: props.templateRows,
                alignItems: props.alignItems,
            }}
        >
            {props.children}
        </div>
    );
};
