import React from "react";
import { CRMColors } from "../../../models/CRMColors";
import { CRMIcon } from "../../CRMIcon/CRMIcon";
import { WeightBold } from "../../WeightBold/WeightBold";
import { toDateFormat1 } from "../../../../../shared/src/dateTime";
import { Text } from "../../BuildingBlocks/Text";
import { CRMFontSizes } from "../../../models/CRMFontSizes";
import { Padding } from "../../BuildingBlocks/Padding";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { SpacingRow } from "../../BuildingBlocks/SpacingRow";
import { Relative } from "../../BuildingBlocks/Relative";
import { useHover } from "../../../hooks/useHover";
import { CRMTimelineControlsWrapper } from "../../CRMTimelineControlsWrapper/CRMTimelineControlsWrapper";
import { Background } from "../../BuildingBlocks/Background";
import { CursorPointer } from "../../CursorPointer/CursorPointer";
import { requireExhaustive } from "../../../../../shared/src/util";
import { THexColor } from "../../../models/StringLiterals";
import { WeightMedium } from "../../WeightMedium/WeightMedium";

export type TTimelineSentiment = "sad" | "happy";

type TCRMTimelineSentimentProps = {
    date: string,
    sentiment: TTimelineSentiment;
    changedBy: string | null;
    onClickDelete: () => void;
};

export const CRMTimelineSentiment = (props: TCRMTimelineSentimentProps): JSX.Element => {
    const [ref, isHovering] = useHover<HTMLDivElement>({});
    
    const getSentimentAsCopy = (): string => {
        switch (props.sentiment) {
            case "happy":
                return "Happy";
            case "sad":
                return "Unhappy"
            default:
                return requireExhaustive(props.sentiment);
        }
    }
    
    const getBackgroundColourBySentiment = (): THexColor => {
        switch (props.sentiment) {
            case "happy":
                return CRMColors.HIGHLIGHTS_POSITIVE_GREEN_12;
            case "sad":
                return CRMColors.HIGHLIGHTS_INSTRUCTIONAL_BRICK_12;
            default:
                return requireExhaustive(props.sentiment);
        }
    }
    
    const getInkColourBySentiment = (): THexColor => {
        switch (props.sentiment) {
            case "happy":
                return CRMColors.HIGHLIGHTS_POSITIVE_GREEN_0;
            case "sad":
                return CRMColors.HIGHLIGHTS_INSTRUCTIONAL_BRICK_0;
            default:
                return requireExhaustive(props.sentiment);
        }
    }

    const getChangedBy = (): string =>
        props.changedBy || "Automatically";

    return (
        <Relative divRef={ref}>
            <Background
                color={getBackgroundColourBySentiment()}
                borderRadius="5px"
            >
                <Padding spacing={CRMSpacing.MEDIUM}>
                    <SpacingRow
                        justifyContent="start"
                        spacing={CRMSpacing.SMALL}
                    >
                        {/* ICON */}
                        <CRMIcon
                            iconName={props.sentiment}
                            colour={"hex"}
                            hexColour={getInkColourBySentiment()}
                            size={"26px"}
                        />

                        {/* TEXT */}
                        <Text
                            size={CRMFontSizes.MED}
                            color={getInkColourBySentiment()}
                            weight={400}
                        >
                            {toDateFormat1(props.date)} - <WeightMedium>{getChangedBy()} set sentiment to:</WeightMedium> <WeightBold>{getSentimentAsCopy()}</WeightBold>
                        </Text>
                    </SpacingRow>
                </Padding>
            </Background>

            {/* DELETE */}
            {isHovering && 
                <CRMTimelineControlsWrapper
                    top="0px"
                    right="0px"
                    bottom="0px"
                    backgroundColour={getBackgroundColourBySentiment()}
                >
                    <CursorPointer onClick={props.onClickDelete}>
                        <CRMIcon
                            iconName="delete"
                            colour="hex"
                            hexColour={getInkColourBySentiment()}
                            size="small"
                        />
                    </CursorPointer>
                </CRMTimelineControlsWrapper>
            }
        </Relative>
    );
};
