import React from "react";
import { TButton } from "../../models/TButton";
import { CRMIcon } from "../CRMIcon/CRMIcon";
import { SpacingRow } from "../BuildingBlocks/SpacingRow";
import { THexColor } from "../../models/StringLiterals";
import { CRMSpacing } from "../../models/CRMSpacing";
import { useHover } from "../../hooks/useHover";

type TButtonTertiaryColorableProps =  
    TButton &
    {
        onHoverIn?: () => void;
        onHoverOut?: () => void;
        textColor: THexColor;
        backgroundColor: THexColor;
        textColorHover?: THexColor;
        backgroundColorHover?: THexColor;
        textAlignment?: "start" | "end" | "center"
    }
;

export const CRMButtonTertiaryColorable = (props: React.PropsWithChildren<TButtonTertiaryColorableProps>): JSX.Element => {

    const [ref, isHovered] = useHover<HTMLAnchorElement>({
        onHoverIn: props.onHoverIn,
        onHoverOut: props.onHoverOut
    });

    return (
        <a
            ref={ref}
            className={`
                crm-button-tertiary-colorable
                crm-button-tertiary-colorable--${props.fullWidth ? "full-width" : "auto"}
            `}
            role="button"
            title={props.title}
            onClick={props.disabled ? undefined : props.onClick}
            href={props.disabled ? undefined : props.href}
            target={props.target}
            style={{
                backgroundColor: isHovered && props.backgroundColorHover ? 
                    props.backgroundColorHover :
                    props.backgroundColor
                ,
                color: isHovered && props.textColorHover ? 
                    props.textColorHover :
                    props.textColor
            }}
        >
            <SpacingRow 
                spacing={CRMSpacing.TINY}
                justifyContent={props.textAlignment}
            >
                {/* ICON */}
                {props.icon &&
                    <CRMIcon
                        iconName={props.icon}
                        colour="neutral-ink"
                        hexColour={isHovered && props.textColorHover ? 
                            props.textColorHover : 
                            props.textColor
                        }
                        size="small"
                    />
                }
                {/* LABEL */}
                {props.label}
            </SpacingRow>
        </a>
    );
};
