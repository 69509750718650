import React from "react";
import { array } from "fp-ts/lib";
import * as Listing3 from "../../../../domain/models/Listing3";
import * as DisplayAddress from "../../../../domain/models/DisplayAddress";
import AnchorLink from "../OldFront/Simple/AnchorLinkComponent/AnchorLink";
import Button from "../OldFront/Simple/ButtonComponent/Button";
import { pipe } from "fp-ts/lib/function";

class CRMListingListComponent extends React.Component<{
    listings: Array<Listing3.T>;
    showLoadMoreResults: boolean;
    onListingClick: (listing: Listing3.T, e: React.MouseEvent) => void;
    onLoadMoreResultsClick: () => void;
    urlCallback: (listing: Listing3.T) => string;
}> {
    public render (): JSX.Element {
        return (
            <div>
                <div className="crmListingListComponent__results">
                    { pipe(
                        this.props.listings,
                        array.mapWithIndex<Listing3.T, JSX.Element>((i, listing) => (
                            <div key={i}>
                                <AnchorLink
                                    onClick={(e) => this.props.onListingClick(listing, e)}
                                    text={ pipe(
                                        DisplayAddress.fromListing3(listing),
                                        DisplayAddress.toString,
                                    )}
                                    url={this.props.urlCallback(listing)}
                                />
                            </div>
                        )),
                    )}
                </div>
                {this.props.showLoadMoreResults ? (
                    <div className="crmListingListComponent__moreResults">
                        <Button
                            label="Load more listings"
                            type="frog"
                            fullWidth={true}
                            onClick={this.props.onLoadMoreResultsClick}
                        />
                    </div>
                ): <></>}
            </div>
        );
    }
}

export default CRMListingListComponent;
