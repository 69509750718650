import React from "react";
import { useOpenClose } from "../../../../hooks/UseOpenClose";
import { pipe } from "fp-ts/lib/function";
import { array, option } from "fp-ts";
import { Relative } from "../../../BuildingBlocks/Relative";
import { Background } from "../../../BuildingBlocks/Background";
import { CRMSpacing } from "../../../../models/CRMSpacing";
import { SpacingRow } from "../../../BuildingBlocks/SpacingRow";
import { SpacingColumn } from "../../../BuildingBlocks/SpacingColumn";
import { FontQuicksand } from "../../../BuildingBlocks/FontQuicksand";
import { Text } from "../../../BuildingBlocks/Text";
import { THexColor } from "../../../../models/StringLiterals";
import { CRMFontSizes } from "../../../../models/CRMFontSizes";
import { CRMColors } from "../../../../models/CRMColors";
import { Absolute } from "../../../BuildingBlocks/Absolute";
import { CRMPopout } from "../../../Simple/CRMPopout/CRMPopout";
import { CRMFilterAndSelectTinyArrowOptionWrap } from "../../../Simple/CRMFilterAndSelectTinyArrowOptionWrap/CRMFilterAndSelectTinyArrowOptionWrap";
import { CRMIcon } from "../../../CRMIcon/CRMIcon";
import { CRMEmptyPlaceholder } from "../../../Simple/CRMEmptyPlaceholder/CRMEmptyPlaceholder";


export type TEntityBoxDropdownOption<T extends string, M extends Record<string, unknown>> = {
    value: T;
    label: string;
    meta: M
}

type CRMEntityBoxDropdownProps<T extends string, M extends Record<string, unknown>> = {
    value: null | T;
    placeholder?: string;
    onSelect: (option: TEntityBoxDropdownOption<T, M>) => void;
    options: Array<TEntityBoxDropdownOption<T, M>>;
}

export const CRMEntityBoxDropdown = <T extends string, M extends Record<string, unknown>>(props: CRMEntityBoxDropdownProps<T, M>) => {
    
    const { ref, toggleOpen, isOpen, setIsOpen} = useOpenClose();

    const Placeholder = props.placeholder || "Select option";
    const IsEmpty = props.value === null;
    const HasOptions = props.options.length > 0;
    const Label = pipe(
        props.options,
        array.findFirst((entity) => entity.value === props.value),
        option.fold(
            () => Placeholder,
            (entity) => entity.label
        ),
    );
    const LabelArray = pipe(
        Label,
        (label) => label.split(/\s/g),
        (splitLabel) => pipe(
            splitLabel,
            array.chunksOf(Math.ceil(splitLabel.length/2))
        ),
        array.map((labelChunk) => labelChunk.join(" "))
    );

    
    const onSelect = (option: TEntityBoxDropdownOption<T, M>) => {
        props.onSelect(option);
        setIsOpen(false);
    }

    return (
        <Relative divRef={ref}>
            {/* FACE */}
            <Background
                display="inline-flex"
                cursor="pointer"
                padding={`${CRMSpacing.MEDIUM} ${CRMSpacing.MEDIUM} ${CRMSpacing.SMALL} 0px`}
                onClick={toggleOpen}
            >
                <SpacingRow
                    spacing={CRMSpacing.SMALL} 
                    // alignItems="center"
                    alignItems="flex-start"
                >

                    {/* VALUE */}
                    <BoxText color={IsEmpty ? CRMColors.NEUTRAL_2 : CRMColors.NEUTRAL_INK}>
                        {/* {Label} */}
                        <SpacingColumn>
                            {LabelArray.map((labelPart, index) => (
                                <span key={index}>
                                    {labelPart}
                                </span>
                            ))}
                        </SpacingColumn>
                    </BoxText>
                    
                    {/* TOGGLE ICON */}
                    <CRMIcon
                        iconName={isOpen ? "up-arrow" : "down-arrow"}
                        colour="hex"
                        size="30px"
                        hexColour={CRMColors.NEUTRAL_2}
                    />
                </SpacingRow>
            </Background>

            {/* POPOUT - OPTIONS */}
            {isOpen && 
                <Absolute
                    top={"52px"}
                    left={"0px"}
                    width="300px"
                    zIndex={2}
                >
                    <CRMPopout minWidth={"300px"}>
                        {/* HAS OPTIONS: OPTIONS */}
                        {HasOptions &&
                            <SpacingColumn>
                                {props.options.map((entity, index) => (
                                    <CRMFilterAndSelectTinyArrowOptionWrap 
                                        key={index}
                                        size="small"
                                        mode="no-icon"
                                        onClick={() => onSelect(entity)}
                                    >
                                        <FontQuicksand>
                                            <Text size={CRMFontSizes.SMALL}>
                                                {entity.label}
                                            </Text>
                                        </FontQuicksand>
                                    </CRMFilterAndSelectTinyArrowOptionWrap>
                                ))}
                            </SpacingColumn>
                        }

                        {/* HAS NO OPTOINS: PLACEHOLDER */}
                        {!HasOptions &&
                            <Background padding={CRMSpacing.MEDIUM}>
                                <CRMEmptyPlaceholder
                                    iconSize="50px"
                                    textGap={CRMSpacing.TINY}
                                >
                                    No options to choose from.
                                </CRMEmptyPlaceholder>
                            </Background>
                        }
                    </CRMPopout>
                </Absolute>
            }
        </Relative>
    );
}

type TBoxTextProps = {
    color?: THexColor;
}

const BoxText = (props: React.PropsWithChildren<TBoxTextProps>): JSX.Element => {
    return (
        <FontQuicksand>
            <Text
                lineHeight={1.2}
                size={CRMFontSizes.SMALL}
                color={props.color || CRMColors.NEUTRAL_INK}
                weight={500}
            >
                {props.children}
            </Text>
        </FontQuicksand>
    );
}

