import React from "react";
import { CRMIcon } from "../../CRMIcon/CRMIcon";
import { CRMColors } from "../../../models/CRMColors";
import { SpacingRow } from "../../BuildingBlocks/SpacingRow";
import { CRMParagraph } from "../../Simple/CRMParagraph/CRMParagraph";
import { WeightSemiBold } from "../../WeightSemiBold/WeightSemiBold";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { Strikethrough } from "../../BuildingBlocks/Strikethrough";
import { CRMRoadblockChecklistItemBase } from "../CRMRoadblockChecklistItemBase/CRMRoadblockChecklistItemBase";
import { Padding } from "../../BuildingBlocks/Padding";
import { PreserveLineBreaksNotSpaces } from "../../BuildingBlocks/PreserveLineBreaksNotSpaces";

type TRoadblockChecklistItemManualProps = {
    mode: "first" | "final";
    title: string;
    isDisabled: boolean;
    resolvedBy: string;
    isCheckedFirst: boolean;
    isCheckedFinal: boolean;
    onChangeFinalCheck: (value: boolean) => void;
};

export const CRMRoadblockChecklistItemAutomatic = (props: React.PropsWithChildren<TRoadblockChecklistItemManualProps>): JSX.Element => {

    const IsFinalMode = props.mode === "final";

    const onChangeFinalCheck = () => {
        if (!props.isDisabled) {
            props.onChangeFinalCheck(!props.isCheckedFinal);
        }
    }

    return (
        <CRMRoadblockChecklistItemBase
            ParagraphElement={
                (!props.isCheckedFirst || IsFinalMode) ?
                    <><PreserveLineBreaksNotSpaces><WeightSemiBold>Resolved by:</WeightSemiBold> {props.resolvedBy}</PreserveLineBreaksNotSpaces></>
                    : null
            }
            isDisabledFinalCheck={props.isDisabled}
            isDisabledFirstCheck={props.isDisabled}
            isShowingCheck={IsFinalMode && props.isCheckedFirst}
            isCheckedFinal={props.isCheckedFinal}
            onChangeFinalCheck={onChangeFinalCheck}
        >
            <SpacingRow 
                spacing={CRMSpacing.TINY}
                alignItems="flex-start"
            >
                <CRMIcon
                    iconName={props.isCheckedFirst ? "done" : "hourglass"}
                    colour="hex"
                    hexColour={CRMColors.NEUTRAL_0}
                />
                
                <Padding type="top" spacing={`2px`}>
                    <CRMParagraph>
                        <WeightSemiBold>
                            {props.isCheckedFirst && !IsFinalMode &&
                                <Strikethrough>{props.title}</Strikethrough>
                            }
                            {(!props.isCheckedFirst || IsFinalMode) &&
                                <>{props.title}</>
                            }
                        </WeightSemiBold>
                    </CRMParagraph>
                </Padding>
            </SpacingRow>
        </CRMRoadblockChecklistItemBase>
    );
};
