import React from "react";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { TProbateHelplineCallbackForm } from "../../../../../domain/codecs/form/ProbateHelplineCallbackForm";
import { SpacingColumn } from "../../BuildingBlocks/SpacingColumn";
import { CRMTitleSubSection } from "../../CRMTitleSubSection/CRMTitleSubSection";
import { CRMParagraph } from "../../Simple/CRMParagraph/CRMParagraph";
import { WeightMedium } from "../../WeightMedium/WeightMedium";
import { WeightBold } from "../../WeightBold/WeightBold";

export const CRMProbateHelplineSingleViewPersonalInformation = (props: React.PropsWithChildren<{
    form: TProbateHelplineCallbackForm,
}>): JSX.Element => {
    const canAccessInLifeLedger = 
        props.form.children.user_has_consented_to_shared_data
            ? "Yes"
            : "No";

    return <SpacingColumn spacing={CRMSpacing.MEDIUM}>
        <CRMTitleSubSection>
            Personal Information
        </CRMTitleSubSection>

        <SpacingColumn spacing={CRMSpacing.TINY}>
            {/* FIRST NAME */}
            <CRMParagraph>
                <WeightMedium>
                    First name:&nbsp;
                </WeightMedium>
                <WeightBold>
                    {props.form.children.first_name}
                </WeightBold>
            </CRMParagraph>

            {/* LAST NAME */}
            <CRMParagraph>
                <WeightMedium>
                    Last name:&nbsp;
                </WeightMedium>
                <WeightBold>
                    {props.form.children.last_name}
                </WeightBold>
            </CRMParagraph>

            {/* EMAIL */}
            <CRMParagraph>
                <WeightMedium>
                    Email:&nbsp;
                </WeightMedium>
                <WeightBold>
                    {props.form.children.email}
                </WeightBold>
            </CRMParagraph>

            {/* PHONE NUMBER */}
            <CRMParagraph>
                <WeightMedium>
                    Phone number:&nbsp;
                </WeightMedium>
                <WeightBold>
                    {props.form.children.phone_number}
                </WeightBold>
            </CRMParagraph>

            {/* CAN ACCESS IN LIFE LEDGER */}
            <CRMParagraph>
                <WeightMedium>
                    Can access in Life Ledger:&nbsp;
                </WeightMedium>
                <WeightBold>
                    {canAccessInLifeLedger}
                </WeightBold>
            </CRMParagraph>
        </SpacingColumn>
    </SpacingColumn>;
};
