import React from "react";
import { CRMColors } from "../../../models/CRMColors";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { BackgroundColour } from "../../BuildingBlocks/BackgroundColour";
import { Padding } from "../../BuildingBlocks/Padding";
import { SpacingRow } from "../../BuildingBlocks/SpacingRow";
import { CRMIcon, TIcon } from "../../CRMIcon/CRMIcon";
import { CursorPointer } from "../../CursorPointer/CursorPointer";

type TActionStripProps = {
    actionIcon: TIcon;
    labelIcon?: TIcon;
    onClick: () => void;
};

export const CRMActionStrip = (props: React.PropsWithChildren<TActionStripProps>): JSX.Element => {

    return (
        <CursorPointer onClick={props.onClick}>
            <BackgroundColour colour={CRMColors.NEUTRAL_12}>
                <Padding 
                    type="custom" 
                    spacing={`${CRMSpacing.SMALL} ${CRMSpacing.MEDIUM}`}
                >
                    <SpacingRow justifyContent="space-between">

                        <SpacingRow spacing={CRMSpacing.TINY}>
                            {props.labelIcon &&
                                <CRMIcon
                                    iconName={props.labelIcon}
                                    colour="neutral-ink"
                                />
                            }

                            {props.children}
                        </SpacingRow>
                        
                        <CRMIcon
                            iconName={props.actionIcon}
                            colour="neutral-ink"
                        />
                    </SpacingRow>
                </Padding>
            </BackgroundColour>
        </CursorPointer>
    );
};
