import React from "react";

export type TSuggestionProfile<T> = {
    prefix: string;
    name: string;
    mutability: "MUTABLE" | "SEGMENTED" | "IMMUTABLE";
    maximumInsertions: number | typeof Infinity;
    hasUniqueInsertions: boolean;
    insertedComponent:  (props: TAutocompleteInsertProps) => JSX.Element;
    listComponent:  (props: TAutocompleteListProps) => JSX.Element
    emptyListComponent:  () => JSX.Element;
    itemComponent: (props: TAutocompleteListItemProps<T>) => JSX.Element;
    onMatch: (queryText: string) => Array<T>;
    format: (data: T) => string
}

export type TAutocompleteInsertProps = 
    React.PropsWithChildren<{
        contentState: Draft.ContentState;
        dir: null | string;
        start: number;
        end: number;
        decoratedText: string;
        entityKey: string;
        offsetKey: string;
        blockKey: string;
    }>
;

export type TAutocompleteListProps = React.PropsWithChildren<{}>;

export type TAutocompleteListItemProps<T> = {
    current: boolean;
    item: T;
    onClick: () => void;
};

export type TInsertion = { type: string } & TInsertionCoordinate;

export type TInsertionCoordinate = {
    text: string;
    start: number;
    end: number;
};

export type TInsertionCoordinateWithType = 
    TInsertionCoordinate
    & {
        type: string;
    }
;

export type TActivatedSuggestionProfile<T> = 
    {
        suggestions: Array<T>;
    }
    & TInsertionCoordinate
    & TSuggestionProfile<T>
;

export type TSyntheticKeyboardEvent = React.KeyboardEvent<{}> & { code: string };

export type TDraftJsDecoratorTypeUpdated =
    { _decorators: Array<Draft.DraftDecorator> }
    & Draft.DraftDecoratorType
;

export const getEmptyActivatedProfile = <T extends Object>(): TActivatedSuggestionProfile<T> => ({
    ...getEmptyProfile(),
    text: "",
    start: -1,
    end: -1,
    suggestions: [],
});

export const getEmptyProfile = <T extends Object>(): TSuggestionProfile<T> => ({
    prefix: "",
    name: "EMPTY",
    mutability: "IMMUTABLE",
    maximumInsertions: -1,
    hasUniqueInsertions: false,
    onMatch: () => [],
    insertedComponent:  () => <></>,
    listComponent:  () => <></>,
    emptyListComponent:  () => <></>,
    itemComponent: () => <></>,
    format: () => "",
});

export const getEmptyInsertion = (): TInsertion => ({
    type: "EMPTY",
    ...getEmptyInsertionCoordinate()
});

export const getEmptyInsertionCoordinate = (): TInsertionCoordinate => ({
    text: "",
    start: -1,
    end: -1,
});
