import React from "react";
import { FrontSpacing } from "../../../../models/FrontSpacing";
import { SpacingColumn } from "../../../BuildingBlocks/SpacingColumn";
import { FrontParagraphSmall } from "../../Simple/FrontParagraphSmall/FrontParagraphSmall";
import { FrontOutsideCard } from "../../Simple/FrontOutsideCard/FrontOutsideCard";
import { FrontCardTitle } from "../../Simple/FrontCardTitle/FrontCardTitle";

type TFrontQuestionFormCardProps = {
    title: string;
    id?: string;
    paragraph?: JSX.Element;
};

export const FrontQuestionFormCard = (props: React.PropsWithChildren<TFrontQuestionFormCardProps>): JSX.Element => {

    return (
        <FrontOutsideCard id={props.id}>
            <SpacingColumn spacing={FrontSpacing.MEDIUM_2}>
                {/* TITLE */}
                <FrontCardTitle>
                    {props.title}
                </FrontCardTitle>

                {/* PARAGRAPH */}
                {props.paragraph &&
                    <FrontParagraphSmall>
                        {props.paragraph}
                    </FrontParagraphSmall>
                }

                {/* BODY */}
                <div>
                    {props.children}
                </div>
            </SpacingColumn>
        </FrontOutsideCard>
    );
};
