import React from "react";
import { FrontFormRow } from "../FrontFormRow/FrontFormRow";
import FrontInputGeneralComponent from "../FrontInputGeneralComponent/FrontInputGeneralComponent";
import FrontInputLabelAndErrorWrapComponent from "../FrontInputLabelAndErrorWrapComponent/FrontInputLabelAndErrorWrapComponent";

type TPostCodeNameNumberInputProps = {
    postcode: string;
    buildingNumber: string;
    buildingName: string;
    onChangePostcode: (value: string) => void;
    onChangeNumber: (value: string) => void;
    onChangeName: (value: string) => void;
    postcodeValidationError?: boolean;
    displayValidationErrorsIfPresent?: boolean;
};

export const FrontPostCodeNameNumberInput = (props: React.PropsWithChildren<TPostCodeNameNumberInputProps>): JSX.Element => {

    return (
        <FrontFormRow>
            <FrontInputLabelAndErrorWrapComponent label="Postcode" errorMessage="Please enter a valid postcode" displayError={
                props.postcodeValidationError
                || (
                    props.displayValidationErrorsIfPresent
                    && props.postcode === ""
                )
            }>
                <FrontInputGeneralComponent
                    inputType="text"
                    value={props.postcode}
                    placeholder=""
                    onChange={(postcode) => props.onChangePostcode(postcode.toUpperCase())}
                    displayError={props.postcodeValidationError || (
                        props.displayValidationErrorsIfPresent
                        && props.postcode === ""
                    ) || false}
                />
            </FrontInputLabelAndErrorWrapComponent>

            <FrontInputLabelAndErrorWrapComponent label="Building Number/Name" errorMessage="Please enter a building name or number" displayError={
                props.displayValidationErrorsIfPresent
                && props.buildingName === ""
                && props.buildingNumber === ""
            }>
                <FrontInputGeneralComponent
                    inputType="text"
                    value={props.buildingNumber || props.buildingName}
                    placeholder=""
                    onChange={(value) => {
                        if (new RegExp(/[0-9]/).test(value)) {
                            props.onChangeNumber(value);
                        } else {
                            props.onChangeName(value);
                        }
                    }}
                    displayError={
                        props.displayValidationErrorsIfPresent
                        && props.buildingName === ""
                        && props.buildingNumber === ""
                            ? true
                            : false
                    }
                />
            </FrontInputLabelAndErrorWrapComponent>
        </FrontFormRow>
    );
};
