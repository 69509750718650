import React from "react";
import { TCRMMainColours } from "../../models/TCRMMainColours";
import { CRMIcon, TIcon, TIconSizes } from "../CRMIcon/CRMIcon";

type TCRMIconSwipeButton = {
    iconName: TIcon;
    backgroundColour: TCRMMainColours;
    iconColour: TCRMMainColours;
    onClick: () => void;
    size?: TIconSizes;
    title?: string;
};

type TState = {
    isSwipeButtonShowing: boolean;
};

export class CRMButtonIconSwipeWrap extends React.Component<React.PropsWithChildren<TCRMIconSwipeButton>, TState> {

    constructor(props: TCRMIconSwipeButton) {
        super(props);
        this.state = {
            isSwipeButtonShowing: false,
        };
    }

    toggleSwipeButton = (): void => this.setState({ isSwipeButtonShowing: !this.state.isSwipeButtonShowing });

    public render (): JSX.Element {
        return (
            <div
                className="crm-button-icon-swipe-wrap"
                title={this.props.title}
                onMouseOver={this.toggleSwipeButton}
                onMouseOut={this.toggleSwipeButton}
            >
                {/* ICON BUTTON */}
                <div
                    className={`
                        crm-button-icon-swipe-wrap__icon-button
                        crm-button-icon-swipe-wrap__icon-button--colour-${this.props.backgroundColour}
                        crm-button-icon-swipe-wrap__icon-button--${this.state.isSwipeButtonShowing ? "showing" : "hidden"}
                    `}
                    onClick={this.props.onClick}
                >
                    <CRMIcon
                        iconName={this.props.iconName}
                        colour={this.props.iconColour}
                        size={this.props.size || "medium"}
                    />
                </div>
                {/* HINT */}
                <div
                    className={`
                        crm-button-icon-swipe-wrap__hint
                        crm-button-icon-swipe-wrap__hint--colour-${this.props.backgroundColour}
                    `}
                ></div>

                {/* CHILDREN */}
                <div className="crm-button-icon-swipe-wrap__children">
                    {this.props.children}
                </div>
            </div>
        );
    }
}
