import React from "react";
interface IHeading3 {
    text: string;
}

class Heading3 extends React.Component<React.PropsWithChildren<IHeading3>> {
    public render (): JSX.Element {
        return (
            <h3 className="heading3">{this.props.text}</h3>
        );
    }
}

export default Heading3;
