import React, { useEffect } from "react";
import { CRMSpacingRow } from "../../components/CRMSpacingRow/CRMSpacingRow";
import { TContainerStateProps } from "./../../state/TContainerStateProps";
import { CRMCardOutside } from "../../components/CRMCardOutside/CRMCardOutside";
import { CRMSpacingColumn } from "../../components/CRMSpacingColumn/CRMSpacingColumn";
import { CRMPadding } from "../../components/Simple/CRMPadding/CRMPadding";
import { CRMSpacing } from "../../models/CRMSpacing";
import { Padding } from "../../components/BuildingBlocks/Padding";
import { CRMParagraph } from "../../components/Simple/CRMParagraph/CRMParagraph";
import { pipe } from "fp-ts/lib/function";
import { array } from "fp-ts";
import { CRMLink } from "../../components/CRMLink/CRMLink";
import { CRMCodecEditForm } from "../../components/CRMCodecEditForm/CRMCodecEditForm";
import { CaseConflictOfInterestFormEditablePart, TCaseConflictOfInterestForm, TCaseConflictOfInterestFormEditablePart } from "../../../../domain/codecs/form/CaseConflictsOfInterestForms";
import { WeightBold } from "../../components/WeightBold/WeightBold";
import { snakeCaseToCopyText } from "../../../../shared/src/util";
import { CRMAutosaveIndicatorWrap } from "../../components/CRM/CRMAutosaveIndicatorWrap/CRMAutosaveIndicatorWrap";
import { FormStatus_highestPriority } from "../../../../shared/src/codecs/codec";
import { CRMTitleSection } from "../../components/CRMTitleSection/CRMTitleSection";
import { CRMSpacer } from "../../components/CRMSpacer/CRMSpacer";
import { useSelectedTab } from "../../hooks/useSelectedTab";
import { CRMRadioTab } from "../../components/CRMRadioTab/CRMRadioTab";
import { Sticky } from "../../components/BuildingBlocks/Sticky";
import { CRMEmptyPlaceholder } from "../../components/Simple/CRMEmptyPlaceholder/CRMEmptyPlaceholder";
import { getUrlStringForRoute } from "../../state/router/getUrlStringsForRoute";
import { paths } from "../../state/router/routerPaths";

const CRMLegalCaseConflictsOfInterestContainer = (props: TContainerStateProps): JSX.Element => {

    const {
        isSelectedTabIndex,
        setSelectedTabIndex,
        selectedTabIndex
    } = useSelectedTab<TCaseConflictOfInterestForm>(props.state.forms.case_details_page.data.output.conflicts_of_interest);
    
    useEffect(
        () => props.dispatch({
            type: "LEGAL_CASE_COI_VIEW",
            payload: null,
        }),
        []
    );

    return (
        <Padding type="vertical" spacing={CRMSpacing.X_LARGE}>
            <CRMSpacingColumn spacing="x-large">
                {props.state.forms.case_details_page.data.output.conflicts_of_interest.length <= 0 &&
                <CRMEmptyPlaceholder>
                    No conflicts of interest.
                </CRMEmptyPlaceholder>
                }
                {props.state.forms.case_details_page.data.output.conflicts_of_interest.length > 0 &&
                    <CRMSpacingRow
                        spacing="x-large"
                        alignItems="flex-start"
                        childSize="2fr 1fr"
                    >
                        <Sticky top={CRMSpacing.X_LARGE}>
                            <CRMCardOutside borderRounding="right" shadow={true}>
                                <CRMPadding size="large">
                                    <CRMSpacingColumn spacing="large">
                                        <CRMAutosaveIndicatorWrap
                                            status={pipe(
                                                props.state.forms.case_details_page.data.output.conflicts_of_interest,
                                                array.map((form) => form.status),
                                                FormStatus_highestPriority,
                                            )}
                                        >
                                            <CRMTitleSection>
                                                Conflict Of Interest
                                            </CRMTitleSection>
                                            <CRMSpacer size="large"/>
                                            <CRMSpacingColumn spacing="medium">
                                            <CRMParagraph>
                                                <WeightBold>Flagged Against Case: </WeightBold>
                                                {props.state.forms.case_details_page.data.output.details.original.id === props.state.forms.case_details_page.data.output.conflicts_of_interest[selectedTabIndex].children.flagged_against_case_id && <>Current Case</>}
                                                {props.state.forms.case_details_page.data.output.details.original.id !== props.state.forms.case_details_page.data.output.conflicts_of_interest[selectedTabIndex].children.flagged_against_case_id &&
                                                    <CRMLink target="_blank" href={
                                                        getUrlStringForRoute(
                                                            paths,
                                                            "VIEW_CRM_LEGAL_CASE",
                                                            { caseId: props.state.forms.case_details_page.data.output.conflicts_of_interest[selectedTabIndex].children.flagged_against_case_id },
                                                            {},
                                                            props.state.routes.blockParams.VIEW_CRM_LEGAL_CASE
                                                        )
                                                    } linkStyle="normal">Other Case</CRMLink>}
                                            </CRMParagraph>
                                            <CRMParagraph><WeightBold>Source Case Detail: </WeightBold>{snakeCaseToCopyText(props.state.forms.case_details_page.data.output.conflicts_of_interest[selectedTabIndex].children.source)}</CRMParagraph>
                                            <CRMParagraph><WeightBold>Flagged Case Detail: </WeightBold>{snakeCaseToCopyText(props.state.forms.case_details_page.data.output.conflicts_of_interest[selectedTabIndex].children.target)}</CRMParagraph>
                                            <CRMParagraph><WeightBold>Data at Time of Check: </WeightBold>{props.state.forms.case_details_page.data.output.conflicts_of_interest[selectedTabIndex].children.data_at_time_of_check}</CRMParagraph>
                                            <CRMParagraph><WeightBold>Date of Check: </WeightBold>{props.state.forms.case_details_page.data.output.conflicts_of_interest[selectedTabIndex].children.created_at}</CRMParagraph>
                                            {/* This used to be authoriser only but has been updated to allow non authorisers to edit as well */}
                                            <CRMCodecEditForm
                                                codec={CaseConflictOfInterestFormEditablePart}
                                                model={props.state.forms.case_details_page.data.output.conflicts_of_interest[selectedTabIndex].edited}
                                                validationErrors={props.state.forms.case_details_page.data.output.conflicts_of_interest[selectedTabIndex].validationErrors}
                                                columns={1}
                                                onChange={(edited: TCaseConflictOfInterestFormEditablePart) => {
                                                    props.dispatch({
                                                        type: "LEGAL_CASE_COI_CHANGE",
                                                        payload: {
                                                            ...props.state.forms.case_details_page.data.output.conflicts_of_interest[selectedTabIndex],
                                                            edited: {
                                                                ...props.state.forms.case_details_page.data.output.conflicts_of_interest[selectedTabIndex].edited,
                                                                ...edited,
                                                            },
                                                            status: "requiresSubmission"
                                                        }
                                                    })
                                                }}
                                            />
                                        </CRMSpacingColumn>
                                        </CRMAutosaveIndicatorWrap>
                                    </CRMSpacingColumn>
                                </CRMPadding>
                            </CRMCardOutside>
                        </Sticky>

                        <CRMCardOutside borderRounding="right" shadow={false}>
                            <CRMPadding size="large">
                                <CRMSpacingColumn spacing="medium">
                                    {props.state.forms.case_details_page.data.output.conflicts_of_interest.map((coi, index) => (
                                        <CRMRadioTab
                                            key={index}
                                            isSelected={isSelectedTabIndex(index)}
                                            label={`${snakeCaseToCopyText(coi.children.source)} -> ${snakeCaseToCopyText(coi.children.target)}${coi.edited.status === "flagged" ? " (unresolved)" : ""}`}
                                            onClick={() => setSelectedTabIndex(index)}
                                            icon={coi.edited.status === "flagged" ? "alert" : undefined}
                                        />
                                    ))}
                                </CRMSpacingColumn>
                            </CRMPadding>
                        </CRMCardOutside>
                    </CRMSpacingRow>
                }
            </CRMSpacingColumn>
        </Padding>
    );
};

export default CRMLegalCaseConflictsOfInterestContainer;