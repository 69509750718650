import React from "react";
import DatePicker from "react-datepicker";
import { DateTime } from "luxon";
import { getiOSVersionByUAParsing } from "../../util";
import { option } from "fp-ts";
import { pipe } from "fp-ts/lib/function";
import { CRMIcon } from "../CRMIcon/CRMIcon";
import * as PartialISODatetime from "../../../../domain/models/PartialISODatetime";

export const CRMInputCalendarComponent = (props: {
    placeholder?: string;
    dateType: "date"
        | "datetime-local"
        | "datetime-local_seconds";
    value: string;
    displayError: boolean;
    onChange: (value: string) => void;
    onPressEnterKey: () => void;
    disabled?: boolean;
}): JSX.Element => {
    const formattedValue =
        props.value === "" ? ""
        : props.dateType === "date" ? PartialISODatetime.getDate(props.value)
        : props.dateType === "datetime-local" ? DateTime.fromISO(props.value, { zone: "Europe/London" }).toFormat("yyyy-MM-dd'T'HH:mm")
        : DateTime.fromISO(props.value, { zone: "Europe/London" }).toFormat("yyyy-MM-dd'T'HH:mm:ss");

    const mapToISOValue = (date: string | Date): string =>
        typeof date === "object" ? DateTime.fromJSDate(date, { zone: "Europe/London" }).toUTC().toISO()
        : date === "" ? ""
        : props.dateType === "date" ? PartialISODatetime.getDate(date)
        : props.dateType === "datetime-local" ? DateTime.fromFormat(date, "yyyy-MM-dd'T'HH:mm", { zone: "Europe/London" }).toUTC().toISO()
        : DateTime.fromFormat(date, "yyyy-MM-dd'T'HH:mm:ss", { zone: "Europe/London" }).toUTC().toISO();
    
    const requiresIOSPolyfill =
        pipe(
            getiOSVersionByUAParsing(),
            option.fold(
                () => false, // If we can't get the iOS version but it supports datetime-local then assume everything is fine
                (version) => version[0] <= 13
            ),
        );

    const dateType =
        props.dateType === "datetime-local_seconds"
            ? "datetime-local"
            : props.dateType;

    const shouldIncludeSeconds = props.dateType === "datetime-local_seconds";

    const dateFormat =
        props.dateType === "date" ? "dd-MM-yyyy"
        : props.dateType === "datetime-local" ? "dd-MM-yyyy HH:mm"
        : "dd-MM-yyyy HH:mm:ss";

    return (
        <div className="CRMInputCalendarComponent">
            {!requiresIOSPolyfill
                ? <div className="CRMInputCalendarComponent__inputContainer">
                    <input
                        className={
                            "CRMInputCalendarComponent__input" +
                            (props.displayError ? " CRMInputCalendarComponent__input--error " : "")
                        }
                        type={dateType}
                        placeholder={props.placeholder}
                        value={formattedValue}
                        required={true}
                        onChange={(e) => {
                            props.onChange(mapToISOValue(e.currentTarget.value));
                        }}
                        onKeyDown={(e) => e.keyCode === 13 && props.onPressEnterKey()}
                        disabled={props.disabled}
                        step={
                            shouldIncludeSeconds
                                ? "1"
                                : undefined
                        }
                    />
                </div>
                : <div className="CRMInputCalendarComponent__inputContainer"><DatePicker
                    showTimeSelect={dateType === "datetime-local"}
                    selected={props.value ? new Date(props.value) : undefined}
                    className={
                        "CRMInputCalendarComponent__input" +
                        ` CRMInputCalendarComponent__input--date` +
                        (props.displayError ? " CRMInputCalendarComponent__input--error " : " ")
                    }
                    placeholderText={props.placeholder}
                    onChange={(date) =>
                        props.onChange(mapToISOValue(Array.isArray(date) ? date[0] : date ? date : ""))
                    }
                    onKeyDown={(e) => e.keyCode === 13 && props.onPressEnterKey()}
                    dateFormat={dateFormat}
                    timeIntervals={15}
                    required={true}
                    disabled={props.disabled}
                />
                </div>
            }
            <div className="CRMInputCalendarComponent__icon">
                <CRMIcon iconName="calendar" colour="neutral-ink"/>
            </div>
        </div>
    );
};
