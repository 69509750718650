import React from "react";
import { TCasesKeyDate } from "../../../../../../domain/codecs/form/CasesForm";
import { pipe } from "fp-ts/lib/pipeable";
import { CRMCasesKeyDate } from "../CRMCasesKeyDate/CRMCasesKeyDate";
import { SpacingRow } from "../../../BuildingBlocks/SpacingRow";
import { CRMSpacing } from "../../../../models/CRMSpacing";
import { groupKeyDatesByDay, sortKeyDatesByRecency, sortKeyDatesDayClustersByRecency } from "./CRMCasesKeyDatesRailGroupingLogic";
import { useMouseWheelHorizontalScroll } from "../../../../hooks/useMouseWheelHorizontalScroll";
import { Background } from "../../../BuildingBlocks/Background";

type TCasesKeyDatesRailProps = {
    dates: Array<TCasesKeyDate>;
    paddingHorizontal?: number;
};

export const CRMCasesKeyDatesRail = (props: React.PropsWithChildren<TCasesKeyDatesRailProps>): JSX.Element => {
    
    const SHorizontalScroll = useMouseWheelHorizontalScroll<HTMLDivElement>({ scrollAmount: 150 });
    const PaddingHorizontal = props.paddingHorizontal || 0;
    const KeyDatesByDay = pipe(
        props.dates,
        sortKeyDatesByRecency,
        groupKeyDatesByDay,
        sortKeyDatesDayClustersByRecency,
    );

    return (
        <div
            ref={SHorizontalScroll.ElementRef}
            className={`crm-cases-key-dates-rail`}
            onWheel={SHorizontalScroll.onWheelMove}
            style={{
                paddingLeft: PaddingHorizontal,
            }}
        >
            <SpacingRow spacing={CRMSpacing.LARGE}>
                {KeyDatesByDay.map((dayCluster, clusterIndex) => (
                    <CRMCasesKeyDate
                        key={clusterIndex}
                        dates={dayCluster.keyDates}
                    />
                ))}

                {/* [Note] This is here because CSS is insane.
                    If you add 40px to it's left padding the scroll adjust correctly.
                    But if you add 40px to it's right padding, it predents that it's not there.
                    Therefore it never tucks in by 40px when the scroll is complete.
                    So we literarly add an element that creates the desired width.
                */}
                {PaddingHorizontal > 0 &&
                    <Background
                        height="1px" 
                        width={`${PaddingHorizontal-24}px`}
                    />
                }
            </SpacingRow>
        </div>
    );
};