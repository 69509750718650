import React from "react";
import { AppContainer } from "./containers/AppContainer";
import { Datacenter, datadogLogs } from "@datadog/browser-logs";
import { createStateObservable } from "./state/createStateObservable";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import { addHooksToDomPurify } from "./functions/email/sanitizeEmail";
import { actions } from "./state/actions";
// import { createRoot } from 'react-dom/client';
import ReactDOM from "react-dom";

const setupThenRenderReact = (): void => {
    datadogLogs.init({
        clientToken: (env.REACT_APP_DATADOG_CLIENT_TOKEN || ""),
        forwardErrorsToLogs: true,
        datacenter: Datacenter.EU,
        sampleRate: 100,
    });

    // This needs to be here because DomPurifier uses a singelton model for hooks :(
    addHooksToDomPurify();
    
    const [state$, dispatch] = createStateObservable(actions);

    // LATER this is the new way of rendering react apps but for some reason it breaks
    // the useOpenClose function, until that is fixed we are rolling back and the app
    // will continue working as if an older version of React - Tom Freire Camacho 2023-11-03

    // const container = document.getElementById('app');
    // const root = createRoot(container!);

    // state$.subscribe((s) => {
    //     root.render(<AppContainer state={s} dispatch={dispatch} />);
    // });

    state$.subscribe((s) => {
        ReactDOM.render(
            <AppContainer state={s} dispatch={dispatch} />, 
            document.getElementById("app")
        );
    });
};

setupThenRenderReact();
