import React from "react"

export const useOnClickFocus = () => {
    const focusThisRef = React.createRef<HTMLInputElement>();

    const onClickMoveFocus = () => {
        if (focusThisRef.current) {
            focusThisRef.current.focus();
        }
    };

    return {
        focusThisRef,
        onClickMoveFocus,
    };
};
