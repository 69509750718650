import React from "react";
import { TFrontMainColours } from "../../../../models/TFrontMainColours";
import { FrontIcon, TIcon } from "../FrontIcon/FrontIcon";
import FrontParagraphRegular from "../../Simple/FrontParagraphRegular/FrontParagraphRegular";
import FrontParagraphTiny from "../../Simple/FrontParagraphTiny/FrontParagraphTiny";
import { FrontTextColour } from "../../../FrontTextColour/FrontTextColour";
import { WeightBold } from "../../../WeightBold/WeightBold";
import { requireExhaustive } from "../../../../../../shared/src/util";
import { FrontLink } from "../../../FrontLink/FrontLink";

type TFrontOfferSummaryTitle = 
    "offer"
    | "id"
    | "pof"
    | "solicitor"
    | "broker"
;

type TFrontOfferSummaryStatusIcon = {
    isComplete: boolean;
    icon: TIcon;
    title: TFrontOfferSummaryTitle;
    onClick: () => void;
    isClickable: boolean;
};

export class FrontOfferSummaryStatusIcon extends React.Component<React.PropsWithChildren<TFrontOfferSummaryStatusIcon>> {

    getColour = (): TFrontMainColours => this.props.isComplete ? "highlights-positive-apple-10" : "neutral-18";

    getTitleString = (frontOfferSummaryTitle: TFrontOfferSummaryTitle): string => {
        switch (frontOfferSummaryTitle) {
            case "offer":
                return "Your Offer";
            case "id":
                return "ID Check";
            case "pof":
                return "Proof of Funds";
            case "solicitor":
                return "Solicitor's Details";
            case "broker":
            return "Mortgage Broker's Details";
            default:
                return requireExhaustive(frontOfferSummaryTitle);
        }
    }
    
    public render (): JSX.Element {
        return (
            <div
                className="front-offer-summary-status-icon"
            >
                <div 
                    className={`front-offer-summary-status-icon__cell ${this.props.isClickable ? "front-offer-summary-status-icon__cell--clickable" : ""}`}
                    onClick={() => this.props.onClick()}
                >
                    <FrontIcon
                        iconName={this.props.icon}
                        colour={this.getColour()}
                        size="extra-large"
                    />
                </div>
                <div
                    className={`
                        front-offer-summary-status-icon__cell 
                        ${this.props.isClickable ? "front-offer-summary-status-icon__cell--clickable" : ""}
                    `}
                >
                    <div>
                        <FrontParagraphRegular>
                            <WeightBold>
                                <FrontTextColour colour={this.getColour()}>
                                    {(this.props.title === "offer"
                                        || this.props.title === "id"
                                        || this.props.title === "pof")
                                        && <span onClick={() => this.props.onClick()}>
                                            {this.getTitleString(this.props.title)}
                                        </span>
                                    }

                                    {this.props.title === "solicitor"
                                        && <>
                                            <span onClick={() => this.props.onClick()} >
                                                {this.getTitleString(this.props.title)}&nbsp;
                                            </span>
                                            <FrontLink 
                                                href="https://www.saillegal.co.uk/quotes-sail-homes"
                                                target="_blank"
                                            >
                                                (get a quote)
                                            </FrontLink>
                                        </>
                                    }

                                    {this.props.title === "broker"
                                        && <>
                                            <span onClick={() => this.props.onClick()} >
                                                {this.getTitleString(this.props.title)}&nbsp;
                                            </span>
                                            <FrontLink
                                                href="https://lucramortgages.co.uk/thank-you/?calendly=true"
                                                target="_blank"
                                            >
                                                (book a call with Lucra)
                                            </FrontLink>
                                        </>
                                    }
                                </FrontTextColour>
                            </WeightBold>
                        </FrontParagraphRegular>
                    </div>
                    <div onClick={() => this.props.onClick()}>
                        <FrontParagraphTiny>
                            <WeightBold>
                                <FrontTextColour colour={this.getColour()}>
                                    {!this.props.isComplete
                                        ? <u>
                                            How do I provide this?
                                        </u>
                                        : "Done"}
                                </FrontTextColour>
                            </WeightBold>
                        </FrontParagraphTiny>
                    </div>
                </div>
                {this.props.isComplete
                    && <div 
                        className={`
                            front-offer-summary-status-icon__cell 
                            front-offer-summary-status-icon__cell--check 
                            ${this.props.isClickable ? "front-offer-summary-status-icon__cell--clickable" : ""}
                        `}
                    >
                    <FrontIcon
                        iconName="tick"
                        colour={this.getColour()}
                      size="small"
                    />
                </div>}
            </div>
        );
    }
}
