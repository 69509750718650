import React from "react";
import CRMInputLabelAndErrorWrapComponent from "../CRMInputLabelAndErrorWrapComponent/CRMInputLabelAndErrorWrapComponent";
import CRMInputGeneralComponent from "../CRMInputs/CRMInputGeneralComponent/CRMInputGeneralComponent";
import { TCRMFormInputWithoutCodec } from "./CRMFormInputTypes";

export const CRMTelFormInput: TCRMFormInputWithoutCodec<string> = (props) => 
    <CRMInputLabelAndErrorWrapComponent label={props.label}>
        <CRMInputGeneralComponent
            inputType={"tel"}
            value={props.model}
            onChange={(value) => props.onChange(value)}
            displayError={props.displayError}
            disabled={props.disabled}
        />
    </CRMInputLabelAndErrorWrapComponent>
