import React from "react";
import { CRMPopoutHeader } from "../../../Simple/CRMPopoutHeader/CRMPopoutHeader";
import { CRMBlockPopover } from "../../CRMBlock/CRMBlockPopover/CRMBlockPopover";
import { CRMSpacing } from "../../../../models/CRMSpacing";
import { CRMComposeEmailTemplateFilterSearchSelect, TEmailTemplateRecord } from "../CRMComposeEmailTemplateFilterSearchSelect/CRMComposeEmailTemplateFilterSearchSelect";
import { TCasesPurchasePaymentMethod1 } from "../../../../../../domain/codecs/CasesPurchasePaymentMethod";
import { TTransactionType1 } from "../../../../../../domain/codecs/TransactionType";
import { TCaseSellerReasonForSale1 } from "../../../../../../domain/codecs/CaseSellerReasonForSale";

type TComposeEmailTemplatesPopoverProps = {
    onClose: () => void;
    transactionType: TTransactionType1;
    sellerReasonForSale: TCaseSellerReasonForSale1;
    gopDocumentExists: boolean;
    purchasePaymentMethod: TCasesPurchasePaymentMethod1;
    isUnregistered: boolean;
    onSelectTemplate: (template: TEmailTemplateRecord) => void
};

export const CRMComposeEmailTemplatesPopover = (props: React.PropsWithChildren<TComposeEmailTemplatesPopoverProps>): JSX.Element => {

    return (
        <CRMBlockPopover
            padding={CRMSpacing.MEDIUM}
            top={CRMSpacing.TINY}
            height="720px" 
            maxHeight="720px"
        >
            <CRMPopoutHeader
                icon="arrow-left"
                onClick={props.onClose}
            >
                Select email template
            </CRMPopoutHeader>
            
            <CRMComposeEmailTemplateFilterSearchSelect
                transactionType={props.transactionType}
                sellerReasonForSale={props.sellerReasonForSale}
                gopDocumentExists={props.gopDocumentExists}
                purchasePaymentMethod={props.purchasePaymentMethod}
                isUnregistered={props.isUnregistered}
                onSelectTemplate={props.onSelectTemplate}
            />
        </CRMBlockPopover>
    );
};
