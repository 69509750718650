import React from "react";
import { TAdhocTaskForms, TCasesCaseCreateAdhocForm, TCasesCaseForm, TCasesCaseViewForm } from "../../../../../../domain/codecs/form/CasesForm";
import { CRMCaseTableRow } from "../CRMCaseTableRow/CRMCaseTableRow";
import { CRMSpacing } from "../../../../models/CRMSpacing";
import { SpacingColumn } from "../../../BuildingBlocks/SpacingColumn";
import { CRMCaseTableHeader } from "../CRMCaseTableHeader/CRMCaseTableHeader";
import { CRMColors } from "../../../../models/CRMColors";
import { AnimationPopout } from "../../../BuildingBlocks/AnimationPopout";
import { Background } from "../../../BuildingBlocks/Background";
import { SpacingRow } from "../../../BuildingBlocks/SpacingRow";
import { CRMThinColoredButton } from "../../../Simple/CRMThinColoredButton/CRMThinColoredButton";
import { WeightSemiBold } from "../../../WeightSemiBold/WeightSemiBold";
import { CRMCaseTablePlaceholderRows } from "../CRMCaseTablePlaceholderRows/CRMCaseTablePlaceholderRows";
import { CRMEmptyPlaceholder } from "../../../Simple/CRMEmptyPlaceholder/CRMEmptyPlaceholder";
import { TPlainUser } from "../../../../../../domain/codecs/User";
import { CRMCasesKeyDatesRail } from "../CRMCasesKeyDatesRail/CRMCasesKeyDatesRail";

type TCaseTableProps = {
    form: TCasesCaseViewForm;
    assignableUsers: Array<TPlainUser>;

    openCaseId: string | null;
    onSetOpenCaseId: (value: string | null) => void;
    
    onChangeViewForm: (form: TCasesCaseViewForm) => void;
    onLoadMore: () => void;

    onChangeCaseFormComment: (form: TCasesCaseForm) => void;
    onSubmitCaseForm: (form: TCasesCaseForm) => void;

    onChangeTask: (form: [string, TAdhocTaskForms]) => void;
    onArchiveTask: (form: [string, TAdhocTaskForms]) => void;
    onSubmitNewTask: (tuple: [string, TCasesCaseCreateAdhocForm]) => void;
};

export const CRMCaseTable = (props: React.PropsWithChildren<TCaseTableProps>): JSX.Element => {

    const HasKeyDates = props.form.children.key_dates.length > 0;

    const IsShowingLoadMore =
        props.form.children.available_count > props.form.children.case_forms.length;

    const IsLoading =
        props.form.status === "submitting";

    const HasNoRows =
        props.form.children.case_forms.length === 0
        && !IsLoading;
        
    return (
        <div className={`crm-case-table`}>
            <SpacingColumn spacing={CRMSpacing.LARGE}>

                {/* KEY DATES */}
                {HasKeyDates &&
                    <CRMCasesKeyDatesRail
                        paddingHorizontal={40}
                        dates={props.form.children.key_dates} 
                    />
                }

                {/* TABLE */}
                <Background padding={`0px ${CRMSpacing.X_LARGE}`}>
                    <SpacingColumn>
                        {/* TABLE - HEADER */}
                        <CRMCaseTableHeader
                            form={props.form}
                            onChange={props.onChangeViewForm}
                        />
                        {/* TABLE - ROWS */}
                        <div className="crm-case-table__body">
                            {!IsLoading &&
                                <SpacingColumn spacing={CRMSpacing.MEDIUM}>
                                    
                                    {props.form.children.case_forms.map((caseForm) => (
                                        <CRMCaseTableRow
                                            key={caseForm.original.id}
                                            form={caseForm}
                                            assignableUsers={props.assignableUsers}

                                            onChangeFormComment={props.onChangeCaseFormComment}
                                            onSubmitForm={props.onSubmitCaseForm}
                                            
                                            isOpen={props.openCaseId === caseForm.edited.id}
                                            onToggleOpen={() => props.onSetOpenCaseId(
                                                props.openCaseId === caseForm.edited.id
                                                    ? null
                                                    : caseForm.edited.id
                                            )}

                                            onArchiveTask={(form) => props.onArchiveTask([caseForm.original.id, form])}
                                            onChangeTask={(form) => props.onChangeTask([caseForm.original.id, form])}
                                            onSubmitNewTask={(form) => props.onSubmitNewTask([caseForm.original.id, form])}
                                        />
                                    ))}

                                    {/* LOAD MORE */}
                                    {IsShowingLoadMore &&
                                        <AnimationPopout>
                                            <Background padding={`0px 0px ${CRMSpacing.LARGE} 0px`}>
                                                <SpacingRow justifyContent="center">
                                                    <CRMThinColoredButton
                                                        backgroundColor={CRMColors.NEUTRAL_2}
                                                        fontColor={CRMColors.NEUTRAL_12}
                                                        label={<WeightSemiBold>See more</WeightSemiBold>}
                                                        onClick={props.onLoadMore}
                                                        icon="down-arrow"
                                                    />
                                                </SpacingRow>
                                            </Background>
                                        </AnimationPopout>
                                    }
                                </SpacingColumn>
                            }

                            {/* PLACEHOLDER */}
                            {IsLoading &&
                                <CRMCaseTablePlaceholderRows />
                            }

                            {/* NO ROWS */}
                            {HasNoRows &&
                                <Background 
                                    padding="50px 0px 70px"
                                    color={CRMColors.NEUTRAL_PAPER}
                                    borderRadius={`0px 0px 5px 5px`}
                                >
                                    <CRMEmptyPlaceholder iconSize="140px">
                                        Nothing to see here.
                                    </CRMEmptyPlaceholder>
                                </Background>
                            }
                        </div>
                    </SpacingColumn>
                </Background>
            </SpacingColumn>
        </div>
    );
};