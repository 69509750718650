import React from "react";
import * as util from "../../../../../../shared/src/util";
import { CRMCaseEnquiryFilterTitle, ICRMCaseEnquiryFilterTitle } from "../CRMCaseEnquiryFilterTitleComponent/CRMCaseEnquiryFilterTitleComponent";
import * as InternalScope from "../../../../../../domain/models/InternalScope";
import { CRMButtonTertiary } from "../../../CRMButtonTertiary/CRMButtonTertiary";
import { CRMColumns } from "../../../CRMColumns/CRMColumns";
import { SpacingRow } from "../../../BuildingBlocks/SpacingRow";
import * as Case2 from "../../../../../../domain/models/Case2";
import * as CaseEnquiry4 from "../../../../../../domain/models/CaseEnquiry4";

interface ICRMCaseEnquiryHeader extends ICRMCaseEnquiryFilterTitle {
    case: Case2.T;
    enquiries: Array<CaseEnquiry4.T>;
    userScope: InternalScope.T;
    enquiriesToSubmitCount: number;
    onClickAddEnquiry: () => void;
    onClickSubmitEnquiries: () => void;
}

export class CRMCaseEnquiryHeader extends React.Component<ICRMCaseEnquiryHeader, {
    readyForExchangePopupIsOpen: boolean;
}> {
    constructor(props: ICRMCaseEnquiryHeader) {
        super(props);
        this.state = {
            readyForExchangePopupIsOpen: false,
        };
    }

    getSubmitEnquiriesButton = (): React.ReactNode => {
        switch (this.props.userScope) {
            case "sail_legal_authoriser_staff":
                return <div></div>
            case "sail_legal_non_authoriser_staff":
            case "admin":
                return <CRMButtonTertiary
                    fullWidth={false}
                    icon="sign"
                    disabled={this.props.enquiriesToSubmitCount ? false : true}
                    label={`Submit enquiries for approval (${this.props.enquiriesToSubmitCount.toString()})`}
                    onClick={this.props.onClickSubmitEnquiries}
                />;
            case "user":
            case "sail_homes_staff":
                return <></>;
            default:
                return util.requireExhaustive(this.props.userScope);
        }
    };

    public render (): JSX.Element {
        return (
            this.props.userScope === "user" || this.props.userScope === "sail_homes_staff" ? <></>
            : <div>
                {/* Header */}
                <div className="case-enquiry-header">
                    <CRMColumns
                        padding="tiny"
                        alignItems="center"
                        columns={[
                            {
                                flex: 3,
                                content:
                                    <SpacingRow justifyContent="start">
                                        <CRMCaseEnquiryFilterTitle
                                            onChange={this.props.onChange}
                                            optionKeyValue={this.props.optionKeyValue}
                                        />
                                    </SpacingRow>,
                            },
                            {
                                flex: 5,
                                content:
                                    <SpacingRow justifyContent="space-between">
                                        {this.props.case.status === "quote"
                                            || this.props.case.status === "instructed"
                                            || this.props.case.status === "property_report"
                                                ? <CRMButtonTertiary
                                                    fullWidth={false}
                                                    icon="add"
                                                    label="Add Enquiry"
                                                    onClick={this.props.onClickAddEnquiry}
                                                />
                                                : this.props.case.status === "ready_for_exchange"
                                                    || this.props.case.status === "exchanged" 
                                                    || this.props.case.status === "completed"
                                                    || this.props.case.status === "closed_created_in_error"
                                                    || this.props.case.status === "closed_unsuccessfully"
                                                    || this.props.case.status === "closed_successfully"
                                                    || this.props.case.status === "closed_awaiting_opening_new_case"
                                                        ? <div></div>
                                                        : util.requireExhaustive(this.props.case.status)}
                                        {this.getSubmitEnquiriesButton()}
                                    </SpacingRow>,
                            },
                        ]}
                    />
                </div>
            </div>
        );
    }
}
