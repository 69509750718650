import React from "react";
import { array } from "fp-ts";
import { array as extArray } from "../../../../shared/src/utilByDomainGroupExport";
import { CRMButtonIcon } from "../CRMButtonIcon/CRMButtonIcon";
import { ICRMInputButtonIconWrapButton } from "../CRMInputButtonIconWrap/CRMInputButtonIconWrap";
import { CRMFormButtonWithParagraph } from "../CRMFormButtonWithParagraph/CRMFormButtonWithParagraph";
import { TFormStatus } from "./../../../../shared/src/codecs/codec";
import CRMInputButtonIconWrap from "../CRMInputButtonIconWrap/CRMInputButtonIconWrap";
import CRMInputGeneralComponent from "../CRMInputs/CRMInputGeneralComponent/CRMInputGeneralComponent";
import { TPhoneNumberForm } from "../../../../domain/codecs/form/PhoneNumberForm";
import { doesErrorKeyExist } from "../../../../shared/src/codecs/errors";
import { editFormEditableValue } from "../../../../shared/src/codecs/types/formEditable";
import { castNewFormStatusToLegacy } from "../../util";
import { pipe } from "fp-ts/lib/function";
import { SpacingRow } from "../BuildingBlocks/SpacingRow";
import { CRMSpacing } from "../../models/CRMSpacing";
import { CRMIconBadge } from "../CRMIconBadge/CRMIconBadge";

type TCRMInputPhoneNumberComponent = {
    phoneNumbers: Array<TPhoneNumberForm>;
    placeholder: string;
    createPhoneNumberStatus: TFormStatus;
    onAddNew: () => void;
    onChange: (value: Array<TPhoneNumberForm>) => void;
    onRemove: (form: TPhoneNumberForm) => void;
    showVerificationStatus: boolean;
};

export class CRMInputPhoneNumbers extends React.Component<React.PropsWithChildren<TCRMInputPhoneNumberComponent>> {

    constructor(props: TCRMInputPhoneNumberComponent) {
        super(props);
    }

    isFirstPhoneNumberFilled (): boolean {
        return !!(
            this.props.phoneNumbers.length > 0 &&
            this.props.phoneNumbers[0].edited.phone_number &&
            this.props.phoneNumbers[0].edited.phone_number.length > 0
        );
    }

    getButtonIcons(index: number): Array<ICRMInputButtonIconWrapButton> {
        const addButton: ICRMInputButtonIconWrapButton = {
            formStatus: castNewFormStatusToLegacy(this.props.createPhoneNumberStatus),
            title: "Add number",
            variant: "primary",
            icon: "add-phone",
            onClick: () => this.props.onAddNew(),
        };

        const deleteButton: ICRMInputButtonIconWrapButton = {
            variant: "tertiary",
            title: "Delete number",
            icon: "delete",
            onClick: () => this.props.onRemove(this.props.phoneNumbers[index]),
        };

        if (this.props.phoneNumbers.length === 1) {
            return [deleteButton, addButton];
        }

        if (index < this.props.phoneNumbers.length-1 ) {
            return [deleteButton];
        }

        if (this.props.phoneNumbers.length-1 === index && index !== 0) {
            return [deleteButton, addButton];
        }

        return [];
    }

    public render (): JSX.Element {
        return (
            <div className="crm-input-phone-number">
                {this.props.phoneNumbers.length === 0 &&
                    <CRMFormButtonWithParagraph
                        icon="add-phone"
                        formStatus={castNewFormStatusToLegacy(this.props.createPhoneNumberStatus)}
                        paragraph="Add number"
                        onClick={() => this.props.onAddNew()}
                    />
                }
                {/* PHONE NUMBER INPUTS */}
                {this.props.phoneNumbers.length > 0 && this.props.phoneNumbers.map((currentNumber, index) => (
                    <div key={index} className="crm-input-phone-number__row">
                        <SpacingRow
                            spacing={CRMSpacing.MEDIUM}
                            childSize={this.props.showVerificationStatus ? "1fr 32px" : "1fr"}
                        >
                            {/* INPUT */}
                            <CRMInputButtonIconWrap buttonIcons={this.getButtonIcons(index)}>
                                <div className="crm-input-phone-number__input">
                                    <CRMInputGeneralComponent
                                        inputType="text"
                                        placeholder={this.props.placeholder}
                                        value={currentNumber.edited.phone_number || ""}
                                        onChange={(value) => this.props.onChange(
                                            pipe(
                                                this.props.phoneNumbers,
                                                extArray.updateWhere(
                                                    (number) => number.original.id === currentNumber.original.id,
                                                    () => editFormEditableValue(currentNumber, "phone_number", value),
                                                ),
                                            )
                                        )}
                                        displayError={doesErrorKeyExist("edited.phone_number", currentNumber.validationErrors)}
                                        onPressEnterKey={() => null}
                                    />
                                    <div
                                        title="Set as primary number"
                                        className="crm-input-phone-number__inner-icon"
                                    >
                                        <CRMButtonIcon
                                            variant="tertiary"
                                            icon={currentNumber.edited.primary_number ? "bookmarked" : "bookmark"}
                                            onClick={() => this.props.onChange(
                                                pipe(
                                                    this.props.phoneNumbers,
                                                    array.map((number) => editFormEditableValue(number, "primary_number", false)),
                                                    extArray.updateWhere(
                                                        (number) => number.original.id === currentNumber.original.id,
                                                        () => editFormEditableValue(currentNumber, "primary_number", !currentNumber.edited.primary_number),
                                                    ),
                                                )
                                            )}
                                        />
                                    </div>
                                </div>
                            </CRMInputButtonIconWrap>

                            {/* VERIFICATION STATUS */}
                            {this.props.showVerificationStatus && currentNumber.edited.is_verified &&
                                <CRMIconBadge
                                    icon="phone"
                                    iconColour="neutral-ink"
                                    iconTitle="This number has been verified by the user"
                                    badgeColour="highlights-positive-green-10"
                                    size="small"
                                />
                            }

                            {this.props.showVerificationStatus && !currentNumber.edited.is_verified &&
                                <CRMIconBadge
                                    icon="phone-disabled"
                                    iconColour="neutral-ink"
                                    iconTitle="This number has not been verified by the user yet."
                                    badgeColour="highlights-pertinent-yellow-6"
                                    size="small"
                                />
                            }
                        </SpacingRow>
                    </div>))
                }
            </div>
        );
    }
}
