import { Lens } from "monocle-ts";
import * as fptsExtensions from "../../../../shared/src/utilByDomainGroupExport";
import { TUnpackArray, TState, TLensPack } from "./lensBaseTypes";

interface ILensSetWhere<D, S> {
    // 5 level lens path guard
    <
        K1 extends keyof S,
        K2 extends keyof S[K1],
        K3 extends keyof S[K1][K2],
        K4 extends keyof S[K1][K2][K3],
        K5 extends keyof S[K1][K2][K3][K4]
    >
    (
        path: [K1, K2, K3, K4, K5],
        whereCallback: (value: TUnpackArray<S[K1][K2][K3][K4][K5]>, data: D) => boolean,
        callback: (value: TUnpackArray<S[K1][K2][K3][K4][K5]>, data: D, state: S) => TUnpackArray<S[K1][K2][K3][K4][K5]>
    ): (pack: TLensPack<D>) => TLensPack<D>;
    // 4 level lens path guard
    <
        K1 extends keyof S,
        K2 extends keyof S[K1],
        K3 extends keyof S[K1][K2],
        K4 extends keyof S[K1][K2][K3]
    >
    (
        path: [K1, K2, K3, K4],
        whereCallback: (value: TUnpackArray<S[K1][K2][K3][K4]>, data: D) => boolean,
        callback: (value: TUnpackArray<S[K1][K2][K3][K4]>, data: D, state: S) => TUnpackArray<S[K1][K2][K3][K4]>
    ): (pack: TLensPack<D>) => TLensPack<D>;
    // 3 level lens path guard
    <
        K1 extends keyof S,
        K2 extends keyof S[K1],
        K3 extends keyof S[K1][K2]
    >
    (
        path: [K1, K2, K3],
        whereCallback: (value: TUnpackArray<S[K1][K2][K3]>, data: D) => boolean,
        callback: (value: TUnpackArray<S[K1][K2][K3]>, data: D, state: S) => TUnpackArray<S[K1][K2][K3]>
    ): (pack: TLensPack<D>) => TLensPack<D>;
    // 2 level lens path guard
    <
        K1 extends keyof S,
        K2 extends keyof S[K1]
    >
    (
        path: [K1, K2],
        whereCallback: (value: TUnpackArray<S[K1][K2]>, data: D) => boolean,
        callback: (value: TUnpackArray<S[K1][K2]>, data: D, state: S) => TUnpackArray<S[K1][K2]>
    ): (pack: TLensPack<D>) => TLensPack<D>;
    // 1 level lens path guard
    <K1 extends keyof S>
    (
        path: [K1],
        whereCallback: (value: TUnpackArray<S[K1]>, data: D) => boolean,
        callback: (value: TUnpackArray<S[K1]>, data: D, state: S) => TUnpackArray<S[K1]>
    ): (pack: TLensPack<D>) => TLensPack<D>;
}

export const setWhere = <T>(): ILensSetWhere<T, TState> =>
    // There is no way to create paramater overloads here so we set to any
    (lens: any, whereCallback: any, updateCallback: any) => // eslint-disable-line
        (pack: TLensPack<T> ) => {
            const focusedLens = Lens.fromPath<TState>()(lens);
            const stateModificationFunc = focusedLens.modify(
                 /* eslint-disable */
                // The type is always expected to be never for the form, since we have abstraced the lens scoping,
                // which is untrue
                // @ts-ignore
                fptsExtensions.array.updateWhere(
                    (value) => whereCallback(value, pack.data),
                    (value) => updateCallback(value, pack.data, pack.state),
                )
                /* eslint-enable */
            );
            pack.state = stateModificationFunc(pack.state);
            return pack;
        };
