import { taskEither, task, record, array } from "fp-ts";
import { pipe } from "fp-ts/lib/function";
import { TTypeOfNewDefault } from "../../../shared/src/codecs/codec";
import { FileIOSailKeyPrefix, TAnyFileIOCodec } from "../../../shared/src/codecs/types/fileIO";
import { TAnyFormIOCodec } from "../../../shared/src/codecs/types/formIO";
import { TFormOperationCodecs, TFormOperationName, formOperationCodecs } from "../../../domain/codecs/formOperationCodecs/formOperationCodecs";

const getAdditionalHeaders = <N extends TFormOperationName>(name: N, form: TTypeOfNewDefault<TFormOperationCodecs[N]>): Record<string, string> => {
    if (formOperationCodecs[name].type === "FileIOCodec") {
        const headers = pipe(
            (form as TTypeOfNewDefault<TAnyFileIOCodec>).input,
            record.toArray,
            array.reduce(
                {} as Record<string, string>,
                (obj, [key, value]) => record.upsertAt(`${FileIOSailKeyPrefix}${key.replace(/\_/g, "-")}`, value)(obj)
            )
        );
        return headers;
    }
    return {};
}

export const formOperation = <N extends TFormOperationName>(name: N, form: TTypeOfNewDefault<TFormOperationCodecs[N]>): Promise<TTypeOfNewDefault<TFormOperationCodecs[N]>> =>
    {
        if (formOperationCodecs[name].type === "FileIOCodec" && (form as TTypeOfNewDefault<TAnyFileIOCodec>).clientSideFileForUpload === undefined ) {
            return task.of(({ ...formOperationCodecs[name].newDefault(), ...{ status: "failure" } }) as TTypeOfNewDefault<typeof formOperationCodecs[N]>)();
        }
        return pipe(
            taskEither.tryCatch<TTypeOfNewDefault<typeof formOperationCodecs[N]>, TTypeOfNewDefault<typeof formOperationCodecs[N]>>(
                () => fetch(`${env.REACT_APP_API_URL}/formOperation/${name}`, {
                    credentials: "include",
                    method: "POST",
                    headers: {
                        "content-type": formOperationCodecs[name].type === "FileIOCodec" ? "application/octet-stream" : "application/json",
                        "accept": "application/json",
                        // If file io add the input properties as headers
                        ...getAdditionalHeaders(name, form),
                    },
                    body: formOperationCodecs[name].type === "FormIOCodec"
                            ? JSON.stringify((pipe(
                                { ...form } as TTypeOfNewDefault<TAnyFormIOCodec>,
                                (f) => { delete f.output; return f; }))
                            )
                        : formOperationCodecs[name].type === "FileIOCodec" // If FileIO upload file
                            ? (form as TTypeOfNewDefault<TAnyFileIOCodec>).clientSideFileForUpload
                        : JSON.stringify(form),
                })
                    .then((res) => res.json()),
                () => ({ ...formOperationCodecs[name].newDefault(), ...{ status: "failure" } }) as TTypeOfNewDefault<typeof formOperationCodecs[N]>
            ),
            taskEither.fold(
                (f) => task.of(f),
                (f) => task.of(f)
            ),
            task.map((f) => {
                if (f.status === "unauthorised") {
                    if (window.location.href.includes(`${env.REACT_APP_CRM_URL}/crm`)) {
                        window.location.href = `${env.REACT_APP_AUTH_URL}/web/login?redirect_url=${encodeURIComponent(window.location.href)}`;
                    } else {
                        window.location.href = `${env.REACT_APP_CRM_URL}/auth/resend-link?redirect_url=${encodeURIComponent(window.location.href)}`;
                    }
                }
                if (f.status === "twoFactorRequired") {
                    window.location.href = `${env.REACT_APP_CRM_URL}/auth/two-factor?redirect_url=${encodeURIComponent(window.location.href)}`;
                }
                return f;
            })
        )();
    };
