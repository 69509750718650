import React from "react";
import { useOpenClose } from "../../../hooks/UseOpenClose";
import { CRMColors } from "../../../models/CRMColors";
import { Absolute } from "../../BuildingBlocks/Absolute";
import { Background } from "../../BuildingBlocks/Background";
import { MinWidth } from "../../BuildingBlocks/MinWidth";
import { Relative } from "../../BuildingBlocks/Relative";
import { CRMBlockHeaderButton } from "../../CRM/CRMBlock/CRMBlockHeaderButton/CRMBlockHeaderButton";
import { TIcon } from "../../CRMIcon/CRMIcon";
import { THexColor } from "../../../models/StringLiterals";

export const CRMBlockFilter = (props: React.PropsWithChildren<{
    backgroundColour?: THexColor | "transparent";
    iconColour?: THexColor;
    popoutPosition?: "bottom" | "top";
    icon?: TIcon,
    borderLeftColour?: THexColor | "none",
    badgeCount?: number,
}>): JSX.Element => {
    const { ref, isOpen, toggleOpen } = useOpenClose();

    const popoutPosition = props.popoutPosition || "top";

    const icon = props.icon || "filter";

    const bottom =
        popoutPosition === "top"
            ? "44px"
            : undefined;

    const top =
        popoutPosition === "bottom"
            ? "44px"
            : undefined;

    return (
        <Relative
            width="44px"
            divRef={ref}
        >
            {/* BUTTON */}
            <CRMBlockHeaderButton
                icon={icon}
                iconColour={props.iconColour}
                isActive={isOpen}
                backgroundColour={props.backgroundColour}
                borderLeftColour={props.borderLeftColour}
                badgeCount={props.badgeCount}
                onClick={toggleOpen}
            />

            {/* MENU */}
            {isOpen &&
                <Absolute
                    bottom={bottom}
                    top={top}
                    right="0"
                >
                    <MinWidth width="300px">
                        <Background
                            color={CRMColors.NEUTRAL_PAPER}
                            borderRadius="0px 5px 0px 0px"
                            boxShadowDefinition="0px 2px 5px rgba(0, 0, 0, 0.4)"
                        >
                            {props.children}
                        </Background>
                    </MinWidth>
                </Absolute>
            }
        </Relative>
    );
};

