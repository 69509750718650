import React from "react";
import { CRMColumns } from "../CRMColumns/CRMColumns";
import { CRMIcon } from "../CRMIcon/CRMIcon";
import { CRMInputCheckboxComponent } from "../CRMInputCheckboxComponent/CRMInputCheckboxComponent";
import { CRMSpacer } from "../CRMSpacer/CRMSpacer";

export class CRMEnquiryFilesListItemComponent extends React.Component<{
    label: string;
    url: string;
    checked: boolean;
    onChange: () => void;
}> {
    public render (): JSX.Element {
        return (
            <div className="crm-enquiry-files-list-item-component">
                <CRMColumns
                    padding="tiny"
                    alignItems="center"
                    wrapOnMobile={false}
                    columns={[
                        {
                            flex: 0,
                            content: <div className="crm-enquiry-files-list-item-component__checkbox-wrap">
                                <CRMInputCheckboxComponent
                                    checked={this.props.checked}
                                    label={this.props.label}
                                    onClick={this.props.onChange}
                                />
                            </div>,
                        },
                        {
                            flex: 1,
                            content: <a
                                className="crm-enquiry-files-list-item-component__link"
                                href={this.props.url}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <CRMIcon
                                    iconName="link"
                                    colour="neutral-ink"
                                    size="tiny"
                                />
                            </a>,
                        },
                    ]}
                />
                <CRMSpacer size="tiny" />
            </div>
        );
    }
}
