import React from "react";
import { CRMIconBadge } from "../CRMIconBadge/CRMIconBadge";

export class CRMInfoBoxSimpleColourComponent extends React.Component<React.PropsWithChildren<{
    fullWidth: boolean;
    displayError: boolean;
}>> {
    public render (): JSX.Element {
        return (
            <div className={
                `crm-info-box-simple-colour-component
                ${this.props.fullWidth ? "crm-info-box-simple-colour-component--full-width" : ""}
                ${this.props.displayError ? "crm-info-box-simple-colour-component--error" : ""}`
            }>
                <div>
                    {this.props.displayError && <div className="crm-info-box-simple-colour-component__error-badge">
                        <CRMIconBadge
                            icon="warning-bubble"
                            iconColour="highlights-instructional-brick-0"
                        />
                    </div>}
                    {this.props.children}
                </div>
            </div>
        );
    }
}
