import React from "react";
import Page from "../../components/PageComponent/Page";
import { FrontSpacer } from "../../components/FrontSpacer/FrontSpacer";
import FrontTitleMinor from "../../components/Front/Simple/FrontTitleMinor/FrontTitleMinor";
import { FrontTitlePageSection } from "../../components/Front/Simple/FrontPageSectionTitle/FrontTitlePageSection";
import { FrontRow } from "../../components/FrontRow/FrontRow";
import FrontButtonPrimary from "../../components/Front/Simple/FrontButtonPrimary/FrontButtonPrimary";
import { FrontTextColour } from "../../components/FrontTextColour/FrontTextColour";
import { WeightBold } from "../../components/WeightBold/WeightBold";
import * as JsonError1 from "../../../../domain/models/JsonError1";
import FrontInputLabelAndErrorWrapComponent from "../../components/Front/Simple/FrontInputLabelAndErrorWrapComponent/FrontInputLabelAndErrorWrapComponent";
import FrontInputGeneralComponent from "../../components/Front/Simple/FrontInputGeneralComponent/FrontInputGeneralComponent";
import { WeightMedium } from "../../components/WeightMedium/WeightMedium";
import FrontParagraphSmall from "../../components/Front/Simple/FrontParagraphSmall/FrontParagraphSmall";
import { TContainerStateProps } from "../../state/TContainerStateProps";

class ResendMagicLinkPage extends React.Component<React.PropsWithChildren<TContainerStateProps>> {
    emailInputHasErrors = (): boolean =>
        JsonError1.doesInnerErrorExist("NoUserWithEmailAddressValidation", this.props.state.activeData.authResendLink.resendLinkForm.validationErrors)
        || JsonError1.doTargetKeysHaveInnerErrors(["email"], this.props.state.activeData.authResendLink.resendLinkForm.validationErrors);

    redirectUrlHasError = (): boolean =>
        JsonError1.doTargetKeysHaveInnerErrors(["redirect_url"], this.props.state.activeData.authResendLink.resendLinkForm.validationErrors);

    public render(): JSX.Element {
        return (
            <Page
                showLogout={false}
                hiddenLink={`${env.REACT_APP_AUTH_URL}/web/login${
                    this.props.state.routes.queryParams.VIEW_MAGIC_LINK_RESEND.redirect_url
                    ? `?redirect_url=${this.props.state.routes.queryParams.VIEW_MAGIC_LINK_RESEND.redirect_url}` : ""}`}
            >
                <div className="resend-magic-link-page">
                        <FrontTitlePageSection>
                            <FrontTextColour colour={"neutral-2"}>
                                <WeightBold>
                                    We need you to login again...
                                </WeightBold>
                            </FrontTextColour>
                        </FrontTitlePageSection>
                        <FrontSpacer size="medium-1" />
                        <div className="resend-magic-link-page__paragraph-width-container">
                            <FrontTitleMinor>
                                <FrontTextColour colour={"neutral-2"}>
                                    Either the link you’ve used has expired or you’ve been logged out. We need to send you a new magic link in order to continue.
                                </FrontTextColour>
                            </FrontTitleMinor>
                            <FrontSpacer size="large-2" />
                            {this.redirectUrlHasError() && <>
                                <FrontParagraphSmall>
                                    <FrontTextColour colour="highlights-error-firebrick-4">
                                        <WeightMedium>
                                            It looks like there’s something wrong with the link you used to get here. Press the back button to go back to the page you were on and try again.
                                        </WeightMedium>
                                    </FrontTextColour>
                                </FrontParagraphSmall>
                                <FrontSpacer size="medium-2" />
                            </>}
                            <div className="resend-magic-link-page__form">
                                <FrontInputLabelAndErrorWrapComponent
                                    label="Enter your email address"
                                    displayError={this.emailInputHasErrors()}
                                    errorMessage="This email address doesn't appear to exist in our system. Is it the same email you've given us before?"
                                >
                                    <FrontInputGeneralComponent
                                        inputType="email"
                                        displayError={this.emailInputHasErrors()}
                                        value={this.props.state.activeData.authResendLink.resendLinkForm.edit.email}
                                        onChange={(value) => this.props.dispatch({
                                            type: "MAGIC_LINK_RESEND_INPUT_CHANGED",
                                            payload: {
                                                key: "email",
                                                value,
                                            },
                                        })}
                                        onPressEnterKey={() => this.props.dispatch({
                                            type: "MAGIC_LINK_RESEND_SUBMIT",
                                            payload: {},
                                        })}
                                        autoFocus={true}
                                    />
                                </FrontInputLabelAndErrorWrapComponent>
                            </div>
                        </div>
                        <FrontSpacer size="medium-2" />
                        <FrontRow justifyContent="start">
                            <FrontButtonPrimary
                                label="Send Magic Link"
                                onClick={() => this.props.dispatch({
                                    type: "MAGIC_LINK_RESEND_SUBMIT",
                                    payload: {},
                                })}
                            />
                        </FrontRow>
                </div>
            </Page>
        );
    }
}

export default ResendMagicLinkPage;
