import React from "react";
import { CRMFontSizes } from "../../../models/CRMFontSizes";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { CRMColors } from "../../../models/CRMColors";
import { Background } from "../../BuildingBlocks/Background";
import { FontQuicksand } from "../../BuildingBlocks/FontQuicksand";
import { SpacingRow } from "../../BuildingBlocks/SpacingRow";
import { Text } from "../../BuildingBlocks/Text";
import { CRMButtonIcon } from "../../CRMButtonIcon/CRMButtonIcon";
import { CRMLink } from "../../CRMLink/CRMLink";

type TUploadedBoxProps = {
    link: string;
    label: string;
    onDelete: () => void;
};

export const CRMUploadedBox = (props: React.PropsWithChildren<TUploadedBoxProps>): JSX.Element => {

    return (
        <Background
            color={CRMColors.PRIMARY_14}
            borderRadius={"5px"}
            padding={CRMSpacing.LARGE}   
        >
            <SpacingRow spacing={CRMSpacing.MEDIUM}>

                <CRMButtonIcon
                    icon="delete"
                    variant="quaternary"
                    onClick={props.onDelete}
                />

                <FontQuicksand>
                    <Text
                        size={CRMFontSizes.MED}
                        weight={500}
                    >
                        <CRMLink
                            linkStyle="normal"
                            target="_blank"
                            href={props.link}
                        >
                            {props.label}
                        </CRMLink>
                    </Text>
                </FontQuicksand>
            </SpacingRow>
        </Background>
    );
};
