
import React, { useState } from "react";
import DatePicker from "react-datepicker";
import { DateTime } from "luxon";
import { getiOSVersionByUAParsing } from "../../util";
import { option } from "fp-ts";
import { pipe } from "fp-ts/lib/function";
import * as PartialISODatetime from "../../../../domain/models/PartialISODatetime";
import { onKeyPress } from "../../functions/keypress/onKeyPress";

type TDateType = "date"
    | "datetime-local"
    | "datetime-local_seconds"
;

export const UnstyledInputDateComponent = (props: {
    placeholder?: string;
    dateType: TDateType;
    value: string;
    displayError: boolean;
    autoFocus?: boolean;
    className?: string;
    style?: React.CSSProperties;
    onChange: (value: string) => void;
    onPressEnterKey?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
    onPressTabKey?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
    onPressEscape?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
    onPressBackspace?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
    disabled?: boolean;
}): JSX.Element => {

    const [inputType, setInputType] = useState<"text" | TDateType>("text");

    const formattedValue =
        props.value === "" ? ""
        : props.dateType === "date" ? PartialISODatetime.getDate(props.value)
        : props.dateType === "datetime-local" ? DateTime.fromISO(props.value, { zone: "Europe/London" }).toFormat("yyyy-MM-dd'T'HH:mm")
        : DateTime.fromISO(props.value, { zone: "Europe/London" }).toFormat("yyyy-MM-dd'T'HH:mm:ss");

    const mapToISOValue = (date: string | Date): string =>
        typeof date === "object" ? DateTime.fromJSDate(date, { zone: "Europe/London" }).toUTC().toISO()
        : date === "" ? ""
        : props.dateType === "date" ? PartialISODatetime.getDate(date)
        : props.dateType === "datetime-local" ? DateTime.fromFormat(date, "yyyy-MM-dd'T'HH:mm", { zone: "Europe/London" }).toUTC().toISO()
        : DateTime.fromFormat(date, "yyyy-MM-dd'T'HH:mm:ss", { zone: "Europe/London" }).toUTC().toISO();
    
    const requiresIOSPolyfill =
        pipe(
            getiOSVersionByUAParsing(),
            option.fold(
                () => false, // If we can't get the iOS version but it supports datetime-local then assume everything is fine
                (version) => version[0] <= 13
            ),
        );

    const shouldIncludeSeconds = props.dateType === "datetime-local_seconds";

    const dateFormat =
        props.dateType === "date" ? "dd-MM-yyyy"
        : props.dateType === "datetime-local" ? "dd-MM-yyyy HH:mm"
        : "dd-MM-yyyy HH:mm:ss";
    return (
        !requiresIOSPolyfill
            ? <input
                className={props.className}
                style={props.style}
                type={inputType}
                onFocus={() => setInputType(props.dateType)}
                onBlur={() => setInputType("text")}
                placeholder={props.placeholder}
                value={formattedValue}
                required={true}
                onChange={(e) => {
                    props.onChange(mapToISOValue(e.currentTarget.value));
                }}
                onKeyDown={onKeyPress({
                    onEnter: props.onPressEnterKey,
                    onTab: props.onPressTabKey,
                    onBackspace: props.onPressBackspace,
                    onEscape: props.onPressEscape
                })}
                disabled={props.disabled}
                step={
                    shouldIncludeSeconds
                        ? "1"
                        : undefined
                }
            />
            : <DatePicker
                showTimeSelect={false}
                selected={props.value ? new Date(props.value) : undefined}
                className={props.className}
                placeholderText={props.placeholder}
                onFocus={() => setInputType(props.dateType)}
                onBlur={() => setInputType("text")}
                onChange={(date) =>
                    props.onChange(mapToISOValue(Array.isArray(date) ? date[0] : date ? date : ""))
                }
                onKeyDown={onKeyPress({
                    onEnter: props.onPressEnterKey,
                    onTab: props.onPressTabKey,
                    onBackspace: props.onPressBackspace,
                    onEscape: props.onPressEscape
                })}
                dateFormat={dateFormat}
                timeIntervals={15}
                required={true}
                disabled={props.disabled}
            />
    );
};