import { TForm } from "../../../../shared/src/codecs/types/form";
import { foldForm } from "./foldForm";

export const foldFormOnSuccess = <T extends TForm<unknown, unknown, unknown>, F>(
    onLeft: (form: T) => F,
    onRight: (form: T) => F,  
) => foldForm<T, F>(
    (form) => form.status === "success",
    onLeft,
    onRight
);