import React from "react";
import { TContainerStateProps } from "./../../state/TContainerStateProps";
import { CRMTitleSection } from "../../components/CRMTitleSection/CRMTitleSection";
import { CRMSpacer } from "../../components/CRMSpacer/CRMSpacer";
import { CRMAutosaveIndicatorWrap } from "../../components/CRM/CRMAutosaveIndicatorWrap/CRMAutosaveIndicatorWrap";
import { CRMCodecEditForm } from "../../components/CRMCodecEditForm/CRMCodecEditForm";
import { CaseContractAs1, CaseOurSideCompanyTransferExecutionType, CaseOtherSideCompanyTransferExecutionType } from "../../../../domain/codecs/CasesContractBlock";
import { SpacingColumn } from "../../components/BuildingBlocks/SpacingColumn";
import { CRMSpacing } from "../../models/CRMSpacing";
import { CRMCaseContractTransferSetupRecords } from "../../components/CRM/CRMCaseContract/CRMCaseContractTransferSetupRecords/CRMCaseContractTransferSetupRecords";

const CRMLegalCaseDetailsContractClientConfigurationContainer = (props: TContainerStateProps): JSX.Element => {


    return <CRMAutosaveIndicatorWrap
        status={props.state.forms.contracts_block.children.details.status}
    >
        <CRMTitleSection>
            Client Configuration
        </CRMTitleSection>
        <CRMSpacer size="large" />

        <SpacingColumn spacing={CRMSpacing.MEDIUM}>

            {/* Only a transfer can use AS1s */}
            {props.state.forms.contracts_block.children.transaction_type === "transfer" && <CRMCodecEditForm
                codec={CaseContractAs1}
                model={props.state.forms.contracts_block.children.details.edited}
                validationErrors={props.state.forms.contracts_block.children.details.validationErrors}
                columns={1}
                onChange={(edited) => props.dispatch({
                    type: "LEGAL_CASE_CONTRACTS_BLOCK_CHANGE",
                    payload: {
                        ...props.state.forms.contracts_block.children.details,
                        edited: {
                            ...props.state.forms.contracts_block.children.details.edited,
                            ...edited,
                        }
                    }
                })}
                showDeferDateLabels={true}
            />}

            {/* Our side company transfer type */}
            <CRMCodecEditForm
                codec={CaseOurSideCompanyTransferExecutionType}
                model={props.state.forms.contracts_block.children.details.edited}
                validationErrors={props.state.forms.contracts_block.children.details.validationErrors}
                columns={1}
                onChange={(edited) => props.dispatch({
                    type: "LEGAL_CASE_CONTRACTS_BLOCK_CHANGE",
                    payload: {
                        ...props.state.forms.contracts_block.children.details,
                        edited: {
                            ...props.state.forms.contracts_block.children.details.edited,
                            ...edited,
                        }
                    }
                })}
                showDeferDateLabels={true}
            />

            {/* CLIENT CONFIGURATION */}
            <CRMCodecEditForm
                codec={CaseOtherSideCompanyTransferExecutionType}
                model={props.state.forms.contracts_block.children.details.edited}
                validationErrors={props.state.forms.contracts_block.children.details.validationErrors}
                columns={1}
                onChange={(edited) => props.dispatch({
                    type: "LEGAL_CASE_CONTRACTS_BLOCK_CHANGE",
                    payload: {
                        ...props.state.forms.contracts_block.children.details,
                        edited: {
                            ...props.state.forms.contracts_block.children.details.edited,
                            ...edited,
                        }
                    }
                })}
                showDeferDateLabels={true}
            />


            <CRMCaseContractTransferSetupRecords
                form={props.state.forms.contracts_block}
                onAddRecord={(payload) => props.dispatch({
                    type: "LEGAL_CONTRACT_CREATE_TRANSFER_RECORD",
                    payload
                })}
                onChangeRecord={(payload) => props.dispatch({
                    type: "LEGAL_CONTRACT_UPDATE_TRANSFER_RECORD",
                    payload
                })}
                onDeleteRecord={(payload) => props.dispatch({
                    type: "LEGAL_CONTRACT_DELETE_TRANSFER_RECORD",
                    payload
                })}
            />

        </SpacingColumn>
    </CRMAutosaveIndicatorWrap>;
}

export default CRMLegalCaseDetailsContractClientConfigurationContainer;