import { NonEmptyArray } from "fp-ts/lib/NonEmptyArray";
import { record, nonEmptyArray } from "fp-ts/lib";
import { literal } from "../../../shared/src/codecs/types/literal";
import { TAnyUnionOfFormsCodec, union } from "../../../shared/src/codecs/types/union";
import * as ContentType from "../../../domain/models/ContentType";
import { pipe } from "fp-ts/lib/function";
import { TAnyFormIOCodec } from "../../../shared/src/codecs/types/formIO";
import { CaseMemberChildrenForm, CaseMemberForm, TCaseMemberChildrenFormCodec, TCaseMemberFormCodec, TCaseSendInstructedEmailFormCodec, CaseSendInstructedEmailForm, TCaseMemberCreateIdCheckFormCodec, TCaseMemberIdCheckFormCodec, CaseMemberCreateIdCheckForm, CaseMemberIdCheckForm, CaseMemberSuggestedSimilarUserForm, TCaseMemberSuggestedSimilarUserFormCodec } from "../form/CaseMemberForm";
import { TAnyFormEditableCodec } from "../../../shared/src/codecs/types/formEditable";
import { BuyerSubmitsNewOfferFormEditable, TBuyerSubmitsNewOfferFormEditableCodec } from "../formEditable/BuyerSubmitsNewOfferFormEditable";
import { PublicOffersPageFormIO, TPublicOffersPageFormIOCodec } from "../formIO/PublicOffersPageFormIO";
import { TitleChecksPageFormIO, TTitleChecksPageFormIOCodec } from "../formIO/TitleChecksPageFormIO";
import { LegalCaseFormIO, TLegalCaseFormIOCodec } from "../formIO/LegalCaseFormIO";
import { CaseForm, TCaseFormCodec, CaseBankAccountForm, TCaseBankAccountFormCodec, TCaseSignOffAsReadyForExchangeFormCodec, CaseSignOffAsReadyForExchangeForm, TCaseInstructFormCodec, CaseInstructForm, TCaseRelatedTransactionCreateFormCodec, CaseRelatedTransactionCreateForm, TCaseRelatedTransactionCreateSearchResultsFormCodec, CaseRelatedTransactionCreateSearchResultsForm, TCaseRelatedTransactionForm1Codec, CaseRelatedTransactionForm1, TCaseRelatedTransactionDataFormCodec, CaseRelatedTransactionDataForm, CaseVideoVerificationCallCreateForm, TCaseVideoVerificationCallCreateFormCodec, CaseVideoVerificationCallListForm, TCaseVideoVerificationCallListFormCodec, CaseVideoVerificationCallAssociateWithUserForm, TCaseVideoVerificationCallAssociateWithUserFormCodec, CaseVideoVerificationCallUserForm, TCaseVideoVerificationCallUserFormCodec, TCaseSdltFormCodec, CaseSdltForm, CaseSdltDeclarationForm, TCaseSdltDeclarationFormCodec, TCaseNewRiskAssessmentFormCodec, CaseNewRiskAssessmentForm, CaseAuditForm, TCaseAuditFormCodec, TSubmitCaseAuditAsCompleteFormCodec, SubmitCaseAuditAsCompleteForm, CaseDualRepCreateForm, TCaseDualRepCreateFormCodec, CaseDualRepSearchResultsForm, TCaseDualRepSearchResultsFormCodec, CaseDualRepLinkedForm, TCaseDualRepLinkedFormCodec, TCreateCaseQuoteFilledFormCodec, CreateCaseQuoteFilledForm, TCaseQuoteSentToClientFormCodec, CaseQuoteSentToClientForm } from "../form/CaseForm";
import { NewCaseMemberFormIO, TNewCaseMemberFormIOCodec } from "../formIO/NewCaseMemberFormIO";
import { RemoveUserNumberFormIO, TRemoveUserNumberFormIOCodec } from "../formIO/RemoveUserNumberFormIO";
import { RemoveCaseMemberFormIO, TRemoveCaseMemberFormIOCodec } from "../formIO/RemoveCaseMemberFormIO";
import { ListingDataFormIO, TListingDataFormIOCodec } from "../formIO/ListingDataFormIO";
import { ListingFull1Editable, TListingFull1EditableCodec } from "../formEditable/ListingEditable";
import { JobsFormIO, TJobsFormIOCodec } from "../formIO/JobsIO"; 
import { NewListingAdHocTaskFormIO, TNewListingAdHocTaskFormIOCodec } from "../formIO/NewListingAdHocTaskFormIO";
import { ListingAdHocTaskEditable, TListingAdHocTaskEditableCodec } from "../formEditable/ListingAdHocTaskEditable";
import { ListingPhotoFileIO, TListingPhotoFileIOCodec } from "../fileIO/ListingPhotoFileIO";
import { TAnyFileIOCodec } from "../../../shared/src/codecs/types/fileIO";
import { ListingDocumentFileIO, TListingDocumentFileIOCodec } from "../fileIO/ListingDocumentFileIO";
import { DeleteListingDocumentFormIO, TDeleteListingDocumentFormIOCodec } from "../formIO/DeleteListingDocumentFormIO";
import { DeleteListingPhotoFormIO, TDeleteListingPhotoFormIOCodec } from "../formIO/DeleteListingPhotoFormIO";
import { ListingDocumentEditable, TListingDocumentEditableCodec } from "../ListingDocument";
import { ListingEnquiryAdHocTasksForm, ListingEnquiryForm, ListingEnquiryInnerForm, ListingEnquiryNewAdHocTaskForm, ListingEnquiryOfferForm, TListingEnquiryAdHocTasksFormCodec, TListingEnquiryFormCodec, TListingEnquiryInnerFormCodec, TListingEnquiryNewAdHocTaskFormCodec, TListingEnquiryOfferFormCodec } from "../formEditable/ListingEnquiryForm";
import { TAnyFormCodec } from "../../../shared/src/codecs/types/form";
import { ListingSignMovementEditForm, TListingSignMovementEditFormCodec } from "../form/ListingSignMovementEditForm";
import { ListingSignMovementCreateForm, TListingSignMovementCreateFormCodec } from "../form/ListingSignMovementCreateForm";
import { AdminPageFormIO, TAdminPageFormIOCodec } from "../formIO/AdminPageFormIO";
import { AdminUserEditable, TAdminUserEditableCodec } from "../formEditable/AdminUserEditable";
import { PhoneNumberForm, TPhoneNumberFormCodec } from "../form/PhoneNumberForm";
import { ListingSellerUserCreateForm, TListingSellerUserCreateFormCodec } from "../form/ListingSellerUserCreateForm";
import { ListingSellerSuggestedSimilarUserForm, ListingSellerUserEditForm, TListingSellerSuggestedSimilarUserFormCodec, TListingSellerUserEditFormCodec } from "../form/ListingSellerUserEditForm";
import { ListingSellerUserDeleteForm, TListingSellerUserDeleteFormCodec } from "../form/ListingSellerUserDeleteForm";
import { NewUserNumberFormIO, TNewUserNumberFormIOCodec } from "../formIO/NewUserNumberFormIO";
import { CaseNewPropertyForm, TCaseNewPropertyFormCodec } from "../form/CaseNewPropertyForm";
import { CasePropertyForm, TCasePropertyFormCodec } from "../form/CasePropertyForm";
import { CasesSearchForm, TCasesSearchFormCodec } from "../form/CasesSearchForm";
import { CaseNewOtherSideMemberForm, TCaseNewOtherSideMemberFormCodec } from "../form/CaseNewOtherSideMemberForm";
import { CaseOtherSideMemberForm, TCaseOtherSideMemberFormCodec } from "../form/CaseOtherSideMemberForm";
import { TTwoFactorAuthFormCodec, TwoFactorAuthForm, TTwoFactorAuthCodeFormCodec, TwoFactorAuthCodeForm, TResendTwoFactorAuthCodeFormCodec, ResendTwoFactorAuthCodeForm } from "../form/TwoFactorAuthForm";
import { ClientCaseBankAccountForm, ClientCaseCreateBankAccountForm, ClientCaseForm, TClientCaseBankAccountFormCodec, TClientCaseCreateBankAccountFormCodec, TClientCaseFormCodec, TClientCaseSubmitBankAccountsForVerificationFormCodec, ClientCaseSubmitBankAccountsForVerificationForm, TClientCaseCreateIdVerificationLinkFormCodec, ClientCaseCreateIdVerificationLinkForm, TClientCaseIdentityVerificationUserFormCodec, ClientCaseIdentityVerificationUserForm, ClientCaseUserSaysPaymentPaidForm, TClientCaseUserSaysPaymentPaidFormCodec, ClientCaseCompanyForm, TClientCaseCompanyFormCodec, TClientCaseCreateUserFormCodec, ClientCaseCreateUserForm, TClientCaseUserFormCodec, ClientCaseUserForm, TClientCaseRelatedTransactionQuestionFormCodec, ClientCaseRelatedTransactionQuestionForm, ClientCaseRelatedTransactionAddressForm, TClientCaseRelatedTransactionAddressFormCodec, ClientCaseVideoVerificationCallAvailableTimesForm, TClientCaseVideoVerificationCallAvailableTimesFormCodec, ClientCaseVideoVerificationCallCreateForm, TClientCaseVideoVerificationCallCreateFormCodec, ClientCaseIdentityVerificationUserProofOfAddressForm, TClientCaseIdentityVerificationUserProofOfAddressFormCodec, TClientCaseSdltFormCodec, ClientCaseSdltForm, TClientCasePurchasePaymentDetailsFormCodec, ClientCasePurchasePaymentDetailsForm, ClientCaseChargeForm, TClientCaseChargeFormCodec, TClientCaseSubmitStepAsCompleteFormCodec, ClientCaseSubmitStepAsCompleteForm, ClientCaseRemortgageMortgageDetailsForm, TClientCaseRemortgageMortgageDetailsFormCodec, TClientCaseJointOwnershipHeldAsFormCodec, ClientCaseJointOwnershipHeldAsForm, ClientCaseDeclarationOfTrustReferralForm, TClientCaseDeclarationOfTrustReferralFormCodec, TClientCaseWillReferralFormCodec, ClientCaseWillReferralForm } from "../form/ClientCaseForm";
import { LegalCasesKeyDatesFormIO, TLegalCasesKeyDatesFormIOCodec } from "../formIO/LegalCasesKeyDatesFormIO";
import { CaseNewLedgerEntryForm, TCaseNewLedgerEntryFormCodec } from "../form/CaseNewLedgerEntryForm";
import { CaseLedgerForm, TCaseLedgerFormCodec } from "../form/CaseLedgerForm";
import { CaseConflictOfInterestForm, TCaseConflictOfInterestFormCodec } from "../form/CaseConflictsOfInterestForms";
import { TCaseLedgerEntryFormCodec, CaseLedgerEntryForm } from "../form/CaseLedgerEntryForm";
import { TCaseLedgerMarkLedgerAsFinalFormCodec, CaseLedgerMarkLedgerAsFinalForm, TCaseLedgerRollbackFormCodec, CaseLedgerRollbackForm } from "../form/CaseLedgerMarkLedgerAsFinalForm";
import { CaseAdHocJobForm, CaseAdHocJobNewForm, TCaseAdHocJobFormCodec, TCaseAdHocJobNewFormCodec } from "../form/CaseAdHocJobForms";
import { TLegalMailboxAccessEditFormCodec, LegalMailboxAccessEditForm } from "../form/LegalMailboxAccessEditForm";
import { EmailAttachmentSearchForm, TEmailAttachmentSearchFormCodec } from "../form/EmailAttachmentSearchForm";
import { TriageBulkEmailMarkAsIrrelevantForm, DetailedEmailForm, TriageEmailAssignToCaseAndUserForm, TriageEmailAssignToCaseForm, TriageEmailAssignToCaseSearchForm, TriageEmailAssignToCaseWithAdhocForm, TriageEmailAssignToUserOnlyForm, TriageEmailMarkAsIrrelevantForm, TriageEmailMarkAsResolvedForm, TriageForm, TTriageBulkEmailMarkAsIrrelevantFormCodec, TDetailedEmailFormCodec, TTriageEmailAssignToCaseAndUserFormCodec, TTriageEmailAssignToCaseFormCodec, TTriageEmailAssignToCaseSearchFormCodec, TTriageEmailAssignToCaseWithAdhocFormCodec, TTriageEmailAssignToUserOnlyFormCodec, TTriageEmailMarkAsIrrelevantFormCodec, TTriageEmailMarkAsResolvedFormCodec, TTriageFormCodec, TriageEmailDisassociateFromCaseForm, TTriageEmailDisassociateFromCaseFormCodec } from "../form/TriageForm";
import { EmailCompositionForm, TEmailCompositionFormCodec } from "../form/EmailCompositionForm";
import { TInboxCountFormCodec, InboxCountForm } from "../form/InboxCountForm";
import { CaseTitleRestrictionForm, CaseChargesAndTitleRestrictionsCaseForm, CaseChargesAndRestrictionsForm, CreateCaseTitleRestrictionForm, TCaseTitleRestrictionFormCodec, TCaseChargesAndTitleRestrictionsCaseFormCodec, TCaseChargesAndRestrictionsFormCodec, TCreateCaseTitleRestrictionFormCodec, CreateCaseChargeForm, CaseChargeForm, TCaseChargeFormCodec, TCreateCaseChargeFormCodec } from "../form/CaseChargesAndRestrictionForm";
import { CaseChargeRedemptionCreateForm, CaseChargeRedemptionForm, CaseChargeRedemptionUploadForm, TCaseChargeRedemptionCreateFormCodec, TCaseChargeRedemptionFormCodec, TCaseChargeRedemptionUploadFormCodec } from "../form/CaseChargeRedemptionForm";
import { IntroducerCreateForm, IntroducerExistingAgreementForm, IntroducerForm, IntroducersForm, IntroducerUploadAgreementForm, TIntroducerCreateFormCodec, TIntroducerExistingAgreementFormCodec, TIntroducerFormCodec, TIntroducersFormCodec, TIntroducerUploadAgreementFormCodec } from "../form/IntroducerForm";
import { CaseCustomerSatisfactionDeleteFormalisedAsComplaintForm, CaseCustomerSatisfactionDeleteNoteForm, CaseCustomerSatisfactionDeleteRaisedWithOmbudsmanForm, CaseCustomerSatisfactionDeleteSentimentForm, CaseCustomerSatisfactionForm, CaseCustomerSatisfactionNewAssignmentForm, CaseCustomerSatisfactionNewDeferForm, CaseCustomerSatisfactionNewFormalisedAsComplaintForm, CaseCustomerSatisfactionNewNoteForm, CaseCustomerSatisfactionNewRaisedWithOmbudsmanForm, CaseCustomerSatisfactionNewSentimentForm, CaseCustomerSatisfactionNoteTogglePinForm, TCaseCustomerSatisfactionDeleteFormalisedAsComplaintFormCodec, TCaseCustomerSatisfactionDeleteNoteFormCodec, TCaseCustomerSatisfactionDeleteRaisedWithOmbudsmanFormCodec, TCaseCustomerSatisfactionDeleteSentimentFormCodec, TCaseCustomerSatisfactionFormCodec, TCaseCustomerSatisfactionNewAssignmentFormCodec, TCaseCustomerSatisfactionNewDeferFormCodec, TCaseCustomerSatisfactionNewFormalisedAsComplaintFormCodec, TCaseCustomerSatisfactionNewNoteFormCodec, TCaseCustomerSatisfactionNewRaisedWithOmbudsmanFormCodec, TCaseCustomerSatisfactionNewSentimentFormCodec, TCaseCustomerSatisfactionNoteTogglePinFormCodec } from "../form/CaseCustomerSatisfactionForm";
import { TClientCompanyPartnershipPageFormCodec, ClientCompanyPartnershipPageForm, ClientCompanyPartnershipForm, TClientCompanyPartnershipFormCodec, ClientCompanyPartnershipCreateForm, TClientCompanyPartnershipCreateFormCodec, ClientCompanyPartnershipDeleteForm, TClientCompanyPartnershipDeleteFormCodec, ClientCompanyPartnershipReviewForm, TClientCompanyPartnershipReviewFormCodec, ClientCompanyPartnershipContractUploadForm, TClientCompanyPartnershipContractUploadFormCodec, TClientCompanyPartnershipContractFormCodec, ClientCompanyPartnershipContractForm, TClientCompanyPartnershipUserFormCodec, ClientCompanyPartnershipUserForm, TClientCompanyPartnershipUserPhoneNumberCreateFormCodec, ClientCompanyPartnershipUserPhoneNumberCreateForm, ClientCompanyPartnershipUserPhoneNumberMakePrimaryForm, TClientCompanyPartnershipUserPhoneNumberMakePrimaryFormCodec, ClientCompanyPartnershipUserPhoneNumberDeleteForm, TClientCompanyPartnershipUserPhoneNumberDeleteFormCodec } from "../form/ClientCompanyPartnershipForm";
import { ProbateHelplineCallbackForm, ProbateHelplineCallbackMarkCompleteForm, ProbateHelplineCallbackTypeForm, TProbateHelplineCallbackFormCodec, TProbateHelplineCallbackMarkCompleteFormCodec, TProbateHelplineCallbackTypeFormCodec } from "../form/ProbateHelplineCallbackForm";
import { MarketingCampaignCreateForm, MarketingCampaignForm, MarketingCampaignsForm, TMarketingCampaignCreateFormCodec, TMarketingCampaignFormCodec, TMarketingCampaignsFormCodec } from "../form/MarketingCampaignForm";
import { QuoteRateCreatedForm, QuoteRatePageForm, QuoteRateIntroducerIdForm, TQuoteRateCreatedFormCodec, TQuoteRatePageFormCodec, TQuoteRateIntroducerIdFormCodec } from "../form/QuoteRateForm";
import { TUuidFormCodec, UuidForm } from "../form/UuidForm";
import { PublicCreateQuoteAddressForm, PublicCreateQuoteFilledForm, TPublicCreateQuoteAddressFormCodec, TPublicCreateQuoteFilledFormCodec } from "../form/PublicQuoteForm";
import { CaseAutoAssignmentForm, CaseAutoAssignmentPageForm, TCaseAutoAssignmentFormCodec, TCaseAutoAssignmentPageFormCodec } from "../form/CaseAutoAssignmentForm";
import { RoadblockCheckListItemManualForm, RoadblockForm, RoadblockSignOffForm, RoadblocksForm, RoadblocksForm2, TRoadblockCheckListItemManualFormCodec, TRoadblockFormCodec, TRoadblockSignOffFormCodec, TRoadblocksForm2Codec, TRoadblocksFormCodec } from "../form/RoadblockForm";
import { ProbateLeadForm } from "../form/ProbateLeadForm";
import { TProbateLeadFormCodec } from "../form/ProbateLeadForm";
import { ChatsMessageForm, TChatsMessageFormCodec } from "../form/ChatsMessageForm";
import { TCaseContractSectionFormCodec, CaseContractSectionForm } from "../form/CaseContractSectionForm";
import { CaseContractDetailsForm, TCaseContractDetailsFormCodec } from "../form/CaseContractDetailsForm";
import { CaseTitleChecksForm, TCaseTitleChecksFormCodec } from "../form/CaseTitleChecksForm";
import { CaseTitleCheckForm, TCaseTitleCheckFormCodec } from "../form/CaseTitleCheckForm";
import { PublicPropertyReportTitleSearchForm, TPublicPropertyReportTitleSearchFormCodec, PublicPropertyReportCreateForm, TPublicPropertyReportCreateFormCodec, TPublicPropertyReportAddressSearchFormCodec, PublicPropertyReportAddressSearchForm } from "../form/PublicPropertyReportForm";
import { CasePropertyNewTitleIssueForm, TCasePropertyNewTitleIssueFormCodec } from "../form/CasePropertyNewTitleIssueForm";
import { CasePropertyDeleteTitleIssueForm, TCasePropertyDeleteTitleIssueFormCodec } from "../form/CasePropertyDeleteTitleIssueForm";
import { SessionFeatureToggleForm, TSessionFeatureToggleFormCodec } from "../form/SessionFeatureToggleForm";
import { CasesCaseCreateAdhocForm, CasesCaseForm, CasesCaseTaskFormAdhocForCasesView, CasesCaseTaskFormUpdatableForCasesView, CasesCaseViewForm, CasesDayViewForm, TCasesCaseCreateAdhocFormCodec, TCasesCaseFormCodec, TCasesCaseTaskFormAdhocCodec, TCasesCaseTaskFormUpdatableCodec, TCasesCaseViewFormCodec, TCasesDayViewFormCodec } from "../form/CasesForm";
import { CaseChaseModeForm, TCaseChaseModeFormCodec, ChaseModeUpdateFormWithChaseReasons, TChaseModeUpdateFormWithChaseReasonsCodec, ChaseModeUpdateForm, TChaseModeUpdateFormCodec } from "../form/CasesChaseTransitionForm";
import { CreateServiceBearerTokenForm, TCreateServiceBearerTokenFormCodec } from "../form/CreateServiceBearerTokenForm";
import { NewTransferSetupRecordForCaseForm, TNewTransferSetupRecordForCaseFormCodec } from "../form/NewTransferSetupRecordForCaseForm";
import { TTransferSetupRecordFormCodec, TransferSetupRecordForm } from "../form/TransferSetupRecordForm";
import { CreateStaffUserForm, TCreateStaffUserFormCodec } from "../form/CreateStaffUserForm";

export type TAnyFormOperationCodec = TAnyFormEditableCodec | TAnyFormIOCodec | TAnyFileIOCodec | TAnyFormCodec | TAnyUnionOfFormsCodec;

export type TFormOperationCodecs = {
    GetPublicOffersPageOffers: TPublicOffersPageFormIOCodec,
    GetCase: TLegalCaseFormIOCodec,
    GetCaseContractSection: TCaseContractSectionFormCodec,
    GetCaseTitleChecks: TCaseTitleChecksFormCodec,
    UpdateCaseTitleCheck: TCaseTitleCheckFormCodec,
    CreateCasePropertyNewTitleIssueForm: TCasePropertyNewTitleIssueFormCodec,
    DeleteCasePropertyTitleIssue: TCasePropertyDeleteTitleIssueFormCodec,
    UpdateCaseContractSection: TCaseContractDetailsFormCodec,
    CreateCaseProperty: TCaseNewPropertyFormCodec,
    UpdateCaseProperty: TCasePropertyFormCodec,
    DeleteCaseProperty: TCasePropertyFormCodec,
    OrderCasePropertyTitleRegister: TUuidFormCodec,
    CreateListingFromCaseProperty: TUuidFormCodec,
    UpdateLegalCase:TCaseFormCodec,
    BuyerSubmitsNewOffer: TBuyerSubmitsNewOfferFormEditableCodec,
    GetTitleChecksPage: TTitleChecksPageFormIOCodec,
    AutocompleteCaseDetailsAddress: TCasePropertyFormCodec,
    CreateCaseMember: TNewCaseMemberFormIOCodec,
    RemoveCaseMember: TRemoveCaseMemberFormIOCodec,
    UpdateCaseMember: TCaseMemberFormCodec,
    UpdateCaseMemberCaseUser: TCaseMemberChildrenFormCodec,
    CreateUserNumber: TNewUserNumberFormIOCodec,
    UpdateUserNumber: TPhoneNumberFormCodec,
    RemoveUserNumber: TRemoveUserNumberFormIOCodec,
    GetListingData: TListingDataFormIOCodec,
    UpdateListingFull: TListingFull1EditableCodec,
    GetJobs: TJobsFormIOCodec,
    CreateListingAdHocTask: TNewListingAdHocTaskFormIOCodec,
    UpdateListingAdHocTask: TListingAdHocTaskEditableCodec,
    UploadListingPhoto: TListingPhotoFileIOCodec,
    UploadListingDocument: TListingDocumentFileIOCodec,
    DeleteListingDocument: TDeleteListingDocumentFormIOCodec,
    DeleteListingPhoto: TDeleteListingPhotoFormIOCodec,
    UpdateUserLegalMailboxAccess: TLegalMailboxAccessEditFormCodec,
    UpdateListingDocument: TListingDocumentEditableCodec,
    GetListingEnquiry: TListingEnquiryFormCodec,
    UpdateListingEnquiry: TListingEnquiryInnerFormCodec,
    CreateListingSignMovement: TListingSignMovementCreateFormCodec,
    UpdateListingSignMovement: TListingSignMovementEditFormCodec,
    DeleteListingSignMovement: TListingSignMovementEditFormCodec,
    CreateListingEnquiryAdHocTask: TListingEnquiryNewAdHocTaskFormCodec,
    UpdateListingEnquiryAdHocTask: TListingEnquiryAdHocTasksFormCodec,
    GetAdminPage: TAdminPageFormIOCodec,
    UpdateAdminUser: TAdminUserEditableCodec,
    UpdateListingEnquiryOffer: TListingEnquiryOfferFormCodec,
    ListingSellerUserCreate: TListingSellerUserCreateFormCodec,
    ListingSellerUserEdit: TListingSellerUserEditFormCodec,
    ListingSellerUserDelete: TListingSellerUserDeleteFormCodec,
    ListingSwapSellerPartyMember: TListingSellerSuggestedSimilarUserFormCodec,
    CasesSearch: TCasesSearchFormCodec,
    CreateCaseOtherSideMember: TCaseNewOtherSideMemberFormCodec,
    UpdateCaseOtherSideMember: TCaseOtherSideMemberFormCodec,
    DeleteCaseOtherSideMember: TCaseOtherSideMemberFormCodec,
    GetTwoFactorAuth: TTwoFactorAuthFormCodec,
    SubmitTwoFactorAuthCode: TTwoFactorAuthCodeFormCodec,
    ResendTwoFactorAuthCode: TResendTwoFactorAuthCodeFormCodec,
    GetClientCase: TClientCaseFormCodec,
    SubmitClientCaseUserAgreeToTOEStepAsComplete: TClientCaseSubmitStepAsCompleteFormCodec,
    SubmitClientCaseUserDualRepConsentStepAsComplete: TClientCaseSubmitStepAsCompleteFormCodec,
    SendCaseInstructedEmail: TCaseSendInstructedEmailFormCodec,
    CreateClientCaseBankAccount: TClientCaseCreateBankAccountFormCodec,
    UpdateClientCaseBankAccount: TClientCaseBankAccountFormCodec,
    DeleteClientCaseBankAccount: TClientCaseBankAccountFormCodec,
    SubmitClientCaseBankAccountsForVerification: TClientCaseSubmitBankAccountsForVerificationFormCodec,
    GetLegalCasesKeyDates: TLegalCasesKeyDatesFormIOCodec,
    UpdateCaseBankAccount: TCaseBankAccountFormCodec,
    CreateCaseLedgerEntry: TCaseNewLedgerEntryFormCodec,
    GetCaseLedger: TCaseLedgerFormCodec,
    DeleteCaseLedgerEntry: TCaseLedgerEntryFormCodec,
    UpdateCaseLedgerEntry: TCaseLedgerEntryFormCodec,
    CreateStripeIdVerificationLink: TClientCaseCreateIdVerificationLinkFormCodec,
    UpdateClientCaseUserIdCheck: TClientCaseIdentityVerificationUserFormCodec,
    CreateCaseMemberIdCheck: TCaseMemberCreateIdCheckFormCodec,
    UpdateCaseMemberIdCheck: TCaseMemberIdCheckFormCodec,
    UpdateCaseConflictOfInterest: TCaseConflictOfInterestFormCodec,
    CaseLedgerMarkLedgerAsFinal: TCaseLedgerMarkLedgerAsFinalFormCodec,
    AutocompleteClientVerificationAddress: TClientCaseIdentityVerificationUserFormCodec,
    SignOffAsReadyForExchange: TCaseSignOffAsReadyForExchangeFormCodec,
    SwapCaseMember: TCaseMemberSuggestedSimilarUserFormCodec,
    InstructCase: TCaseInstructFormCodec,
    UpdateClientInitialPaymentUserSaysPaid: TClientCaseUserSaysPaymentPaidFormCodec,
    UpdateClientCaseCompanyName: TClientCaseCompanyFormCodec,
    CreateClientCaseUser: TClientCaseCreateUserFormCodec,
    DeleteClientCaseUser: TClientCaseUserFormCodec,
    UpdateClientCaseUser: TClientCaseUserFormCodec,
    SubmitClientCaseUsersStepComplete: TClientCaseSubmitStepAsCompleteFormCodec,
    CreateCaseAdHocJob: TCaseAdHocJobNewFormCodec,
    UpdateCaseAdHocJob: TCaseAdHocJobFormCodec,
    GetCaseRelatedSearch: TCaseRelatedTransactionCreateFormCodec,
    CreateCaseRelatedTransaction: TCaseRelatedTransactionCreateSearchResultsFormCodec,
    DeleteCaseRelatedTransaction: TCaseRelatedTransactionForm1Codec,
    UpdateClientCaseRelatedTransactionQuestion: TClientCaseRelatedTransactionQuestionFormCodec,
    AutocompleteRelatedTransactionAddress: TClientCaseRelatedTransactionAddressFormCodec,
    UpdateClientCaseRelatedTransactionAddress: TClientCaseRelatedTransactionAddressFormCodec,
    UpdateCaseRelatedTransaction: TCaseRelatedTransactionDataFormCodec,
    SendNewLegalEmail: TEmailCompositionFormCodec,
    ReplyLegalEmail: TEmailCompositionFormCodec,
    ForwardLegalEmail: TEmailCompositionFormCodec,
    CreateCaseVideoVerificationCall: TCaseVideoVerificationCallCreateFormCodec,
    UpdateCaseVideoVerificationCall: TCaseVideoVerificationCallListFormCodec,
    DeleteCaseVideoVerificationCall: TCaseVideoVerificationCallListFormCodec,
    AssociateCaseUserVideoVerificationCall: TCaseVideoVerificationCallAssociateWithUserFormCodec,
    UpdateCaseUserVideoVerificationCall: TCaseVideoVerificationCallUserFormCodec,
    DisassociateCaseUserVideoVerificationCall: TCaseVideoVerificationCallUserFormCodec,
    GetClientCaseVideoVerificationCallAvailableTimes: TClientCaseVideoVerificationCallAvailableTimesFormCodec,
    CreateClientCaseVideoVerificationCall: TClientCaseVideoVerificationCallCreateFormCodec,
    GetCaseEmailAttachmentOptions: TEmailAttachmentSearchFormCodec,
    UploadClientCaseProofOfAddressDocument: TClientCaseIdentityVerificationUserProofOfAddressFormCodec,
    UpdateClientCaseSdltQuestion: TClientCaseSdltFormCodec,
    SubmitClientCaseSdltQuestion: TClientCaseSdltFormCodec,
    SaveClientCasePurchasePaymentDetails: TClientCasePurchasePaymentDetailsFormCodec,
    SubmitClientCasePurchasePaymentDetailsAsFinal: TClientCasePurchasePaymentDetailsFormCodec,
    SubmitClientCaseRemortgageMortgageDetails: TClientCaseRemortgageMortgageDetailsFormCodec,
    SubmitClientCasePropertyInformationFormsStepComplete: TUuidFormCodec,
    UpdateCaseSdlt: TCaseSdltFormCodec,
    SubmitCaseSdltDeclaration: TCaseSdltDeclarationFormCodec,
    GetTriageUnassignedSimpleEmails: TTriageFormCodec,
    GetTriageExpiredSimpleEmails: TTriageFormCodec,
    GetDetailedEmail: TDetailedEmailFormCodec,
    CaseLedgerRollback: TCaseLedgerRollbackFormCodec,
    SetTriageEmailAsIrrelevant: TTriageEmailMarkAsIrrelevantFormCodec,
    SetTriageEmailAsResolved: TTriageEmailMarkAsResolvedFormCodec,
    SetTriageEmailAsDisassociated: TTriageEmailDisassociateFromCaseFormCodec,
    AssignResolveAndCreateAdhocTriageEmail: TTriageEmailAssignToCaseWithAdhocFormCodec,
    AssignTriageEmailToUserOnly: TTriageEmailAssignToUserOnlyFormCodec,
    AssignTriageEmail: TTriageEmailAssignToCaseAndUserFormCodec,
    AssignAndResolveTriageEmail: TTriageEmailAssignToCaseFormCodec,
    
    GetAssigneeResolvedEmails: TTriageFormCodec,
    GetAssigneeUnresolvedEmails: TTriageFormCodec,
    GetUserUntriagedEmails: TTriageFormCodec,
    GetConfidentialEmails: TTriageFormCodec,
    GetCaseInboxEmails: TTriageFormCodec;

    SetTriageBulkEmailsAsIrrelevant: TTriageBulkEmailMarkAsIrrelevantFormCodec,
    GetUnresolvedInboxCount: TInboxCountFormCodec,
    SubmitCaseRiskAssessment: TCaseNewRiskAssessmentFormCodec,
    UpdateCaseAuditComments: TCaseAuditFormCodec,
    SubmitCaseAuditAsComplete: TSubmitCaseAuditAsCompleteFormCodec,
    GetSearchForTriageEmails: TTriageEmailAssignToCaseSearchFormCodec,
    GetCaseChargesAndRestrictions: TCaseChargesAndRestrictionsFormCodec,
    UpdateCaseChargesAndTitleRestrictionsCase: TCaseChargesAndTitleRestrictionsCaseFormCodec,
    CreateCaseTitleRestriction: TCreateCaseTitleRestrictionFormCodec,
    UpdateCaseTitleRestriction: TCaseTitleRestrictionFormCodec,
    DeleteCaseTitleRestriction: TCaseTitleRestrictionFormCodec,
    UpdateClientCaseCharge: TClientCaseChargeFormCodec,
    SubmitClientCaseCharge: TClientCaseSubmitStepAsCompleteFormCodec,
    CreateCaseChargeRedemption: TCaseChargeRedemptionCreateFormCodec,
    UploadCaseChargeRedemption: TCaseChargeRedemptionUploadFormCodec,
    DeleteCaseChargeRedemption: TCaseChargeRedemptionFormCodec,
    CreateCaseCharge: TCreateCaseChargeFormCodec,
    UpdateCaseCharge: TCaseChargeFormCodec,
    DeleteCaseCharge: TCaseChargeFormCodec,

    GetIntroducers: TIntroducersFormCodec,
    CreateIntroducer: TIntroducerCreateFormCodec,
    UpdateIntroducer: TIntroducerFormCodec,
    DeleteIntroducer: TIntroducerFormCodec,
    UploadIntroducerAgreement: TIntroducerUploadAgreementFormCodec,
    DeleteIntroducerAgreement: TIntroducerExistingAgreementFormCodec,

    GetMarketingCampaigns: TMarketingCampaignsFormCodec,
    CreateMarketingCampaign: TMarketingCampaignCreateFormCodec,
    UpdateMarketingCampaign: TMarketingCampaignFormCodec,
    DeleteMarketingCampaign: TMarketingCampaignFormCodec,

    GetCaseCustomerSatisfaction: TCaseCustomerSatisfactionFormCodec,
    UpdateCaseCustomerSatisfactionPin: TCaseCustomerSatisfactionNoteTogglePinFormCodec,
    CreateCaseCustomerSatisfactionNote: TCaseCustomerSatisfactionNewNoteFormCodec,
    CreateCaseCustomerSatisfactionFormalisedAsComplaint: TCaseCustomerSatisfactionNewFormalisedAsComplaintFormCodec,
    CreateCaseCustomerSatisfactionRaisedWithOmbudsman: TCaseCustomerSatisfactionNewRaisedWithOmbudsmanFormCodec,
    CreateCaseCustomerSatisfactionSentiment: TCaseCustomerSatisfactionNewSentimentFormCodec,
    CreateCaseCustomerSatisfactionAssignment: TCaseCustomerSatisfactionNewAssignmentFormCodec,
    CreateCaseCustomerSatisfactionDefer: TCaseCustomerSatisfactionNewDeferFormCodec,
    
    DeleteCaseCustomerSatisfactionNote: TCaseCustomerSatisfactionDeleteNoteFormCodec,
    DeleteCustomerSatisfactionSentiment: TCaseCustomerSatisfactionDeleteSentimentFormCodec,
    DeleteCustomerSatisfactionRaisedWithOmbudsman: TCaseCustomerSatisfactionDeleteRaisedWithOmbudsmanFormCodec,
    DeleteCustomerSatisfactionRaisedAsComplaint: TCaseCustomerSatisfactionDeleteFormalisedAsComplaintFormCodec,

    GetClientCompanyPartnerships: TClientCompanyPartnershipPageFormCodec,
    UpdateClientCompanyPartnership: TClientCompanyPartnershipFormCodec,
    CreateClientCompanyPartnership: TClientCompanyPartnershipCreateFormCodec,
    DeleteClientCompanyPartnership: TClientCompanyPartnershipDeleteFormCodec,
    ReviewClientCompanyPartnership: TClientCompanyPartnershipReviewFormCodec,
    UploadClientCompanyPartnershipContract: TClientCompanyPartnershipContractUploadFormCodec,
    DeleteClientCompanyPartnershipContract: TClientCompanyPartnershipContractFormCodec,
    AutocompleteClientCompanyPartnershipAddress: TClientCompanyPartnershipFormCodec,
    UpdateClientCompanyPartnershipUser: TClientCompanyPartnershipUserFormCodec,
    DisassociateClientCompanyPartnershipUser: TClientCompanyPartnershipUserFormCodec,
    CreateClientCompanyPartnershipUserPhoneNumber: TClientCompanyPartnershipUserPhoneNumberCreateFormCodec,
    SetClientCompanyPartnershipUserPhoneNumberPrimary: TClientCompanyPartnershipUserPhoneNumberMakePrimaryFormCodec,
    DeleteClientCompanyPartnershipUserPhoneNumber: TClientCompanyPartnershipUserPhoneNumberDeleteFormCodec,

    GetCaseDualRepSearch: TCaseDualRepCreateFormCodec,
    LinkCaseDualRep: TCaseDualRepSearchResultsFormCodec,
    UnlinkCaseDualRep: TCaseDualRepLinkedFormCodec,

    GetProbateHelplineCallbacksNow: TProbateHelplineCallbackTypeFormCodec,
    GetProbateHelplineCallbacksCompleted: TProbateHelplineCallbackTypeFormCodec,
    UpdateProbateHelplineCallback: TProbateHelplineCallbackFormCodec,
    SetProbateHelplineCallbackComplete: TProbateHelplineCallbackMarkCompleteFormCodec,
    
    GetQuoteRates: TQuoteRatePageFormCodec,
    UpdateQuoteRate: TQuoteRateCreatedFormCodec,
    CreateQuoteRate: TQuoteRateIntroducerIdFormCodec,
    DeleteQuoteRate: TUuidFormCodec,
    ToggleDeleteOnNextPublishQuoteRate: TUuidFormCodec,
    SetDeleteOnNextPublishAndReplaceQuoteRate: TUuidFormCodec,
    PublishQuoteRates: TQuoteRateIntroducerIdFormCodec,
    
    CreateCaseQuote: TCreateCaseQuoteFilledFormCodec,
    UpdateCaseQuoteSentToClientStatus: TCaseQuoteSentToClientFormCodec,
    CreatePublicQuote: TPublicCreateQuoteFilledFormCodec,
    AutocompletePublicQuoteAddress: TPublicCreateQuoteAddressFormCodec,

    GetRoadblocks: TRoadblocksFormCodec,
    GetCaseRoadblocks: TRoadblocksForm2Codec,
    CreateChatMessage: TChatsMessageFormCodec,
    GetRoadblock: TRoadblockFormCodec,
    UpdateRoadblockChecklistItem: TRoadblockCheckListItemManualFormCodec,
    SubmitRoadblockSignOff: TRoadblockSignOffFormCodec,

    GetCaseAutoAssignmentRules: TCaseAutoAssignmentPageFormCodec,
    UpdateCaseAutoAssignmentRules: TCaseAutoAssignmentFormCodec,
    CreateProbateLead: TProbateLeadFormCodec,

    GetPublicPropertyReportTitleNumbers: TPublicPropertyReportTitleSearchFormCodec,
    CreatePublicPropertyReport: TPublicPropertyReportCreateFormCodec,
    GetPublicPropertyReportAddressSearch: TPublicPropertyReportAddressSearchFormCodec,
    
    UpdateSessionFeatureToggles: TSessionFeatureToggleFormCodec,

    UpdateClientCaseJointOwnershipHeldAsPreference: TClientCaseJointOwnershipHeldAsFormCodec,

    UpdateClientCaseDeclarationOfTrustReferral: TClientCaseDeclarationOfTrustReferralFormCodec,

    UpdateClientCaseWillReferral: TClientCaseWillReferralFormCodec,

    GetCasesCaseView: TCasesCaseViewFormCodec,
    GetCasesCaseViewCase: TCasesCaseFormCodec,
    CreateCasesCaseAdhoc: TCasesCaseCreateAdhocFormCodec,
    UpdateCasesCaseTask: TCasesCaseTaskFormUpdatableCodec,
    DeleteCasesCaseAdhoc: TCasesCaseTaskFormAdhocCodec,
    UpdateCasesCase: TCasesCaseFormCodec,
    GetCasesDayView: TCasesDayViewFormCodec,
 
    GetCaseChaseMode: TCaseChaseModeFormCodec,
    ChaseModeAccept: TChaseModeUpdateFormCodec,
    ChaseModeWorkableModeAcknowledge: TChaseModeUpdateFormCodec,
    ChaseModeCloseAwaitingNewCase: TChaseModeUpdateFormCodec,
    ChaseModeCloseCaseUnsuccessfully: TChaseModeUpdateFormCodec,
    ChaseModeCloseCaseOpenedInError: TChaseModeUpdateFormCodec,
    ChaseModeReopenCase: TChaseModeUpdateFormCodec,
    ChaseModeReject: TChaseModeUpdateFormCodec,
    ChaseModeSubmit: TChaseModeUpdateFormWithChaseReasonsCodec,
    ChaseModeSubmitToWorkable: TChaseModeUpdateFormCodec,

    CreateServiceBearerToken: TCreateServiceBearerTokenFormCodec,
    CreateStaffUser: TCreateStaffUserFormCodec,

    CreateLegalDraftContractPack: TUuidFormCodec,
    CreateCaseTransferSetupRecord: TNewTransferSetupRecordForCaseFormCodec,
    UpdateCaseTransferSetupRecord: TTransferSetupRecordFormCodec,
    DeleteCaseTransferSetupRecord: TTransferSetupRecordFormCodec,
    CreateTA13: TUuidFormCodec,
    CreateLegalESignCert: TUuidFormCodec,
    CreateTransferDisclaimer: TUuidFormCodec
};

export const formOperationCodecs: TFormOperationCodecs = {
    GetPublicOffersPageOffers: PublicOffersPageFormIO,
    GetCase: LegalCaseFormIO,
    GetCaseContractSection: CaseContractSectionForm,
    GetCaseTitleChecks: CaseTitleChecksForm,
    UpdateCaseTitleCheck: CaseTitleCheckForm,
    CreateCasePropertyNewTitleIssueForm: CasePropertyNewTitleIssueForm,
    DeleteCasePropertyTitleIssue: CasePropertyDeleteTitleIssueForm,
    UpdateCaseContractSection: CaseContractDetailsForm,
    CreateCaseProperty: CaseNewPropertyForm,
    UpdateCaseProperty: CasePropertyForm,
    DeleteCaseProperty: CasePropertyForm,
    OrderCasePropertyTitleRegister: UuidForm,
    CreateListingFromCaseProperty: UuidForm,
    UpdateLegalCase: CaseForm,
    BuyerSubmitsNewOffer: BuyerSubmitsNewOfferFormEditable,
    GetTitleChecksPage: TitleChecksPageFormIO,
    AutocompleteCaseDetailsAddress: CasePropertyForm,
    CreateCaseMember: NewCaseMemberFormIO,
    RemoveCaseMember: RemoveCaseMemberFormIO,
    UpdateCaseMember: CaseMemberForm,
    UpdateCaseMemberCaseUser: CaseMemberChildrenForm,
    CreateUserNumber: NewUserNumberFormIO,
    UpdateUserNumber: PhoneNumberForm,
    RemoveUserNumber: RemoveUserNumberFormIO,
    GetListingData: ListingDataFormIO,
    UpdateListingFull: ListingFull1Editable,
    GetJobs: JobsFormIO,
    CreateListingAdHocTask: NewListingAdHocTaskFormIO,
    UpdateListingAdHocTask: ListingAdHocTaskEditable,
    UploadListingPhoto: ListingPhotoFileIO,
    UploadListingDocument: ListingDocumentFileIO,
    DeleteListingDocument: DeleteListingDocumentFormIO,
    DeleteListingPhoto: DeleteListingPhotoFormIO,
    UpdateUserLegalMailboxAccess: LegalMailboxAccessEditForm,
    UpdateListingDocument: ListingDocumentEditable,
    GetListingEnquiry: ListingEnquiryForm,
    UpdateListingEnquiry: ListingEnquiryInnerForm,
    CreateListingSignMovement: ListingSignMovementCreateForm,
    UpdateListingSignMovement: ListingSignMovementEditForm,
    DeleteListingSignMovement: ListingSignMovementEditForm,
    CreateListingEnquiryAdHocTask: ListingEnquiryNewAdHocTaskForm,
    UpdateListingEnquiryAdHocTask: ListingEnquiryAdHocTasksForm,
    GetAdminPage: AdminPageFormIO,
    UpdateAdminUser: AdminUserEditable,
    UpdateListingEnquiryOffer: ListingEnquiryOfferForm,
    ListingSellerUserCreate: ListingSellerUserCreateForm,
    ListingSellerUserEdit: ListingSellerUserEditForm,
    ListingSellerUserDelete: ListingSellerUserDeleteForm,
    ListingSwapSellerPartyMember: ListingSellerSuggestedSimilarUserForm,
    CasesSearch: CasesSearchForm,
    CreateCaseOtherSideMember: CaseNewOtherSideMemberForm,
    UpdateCaseOtherSideMember: CaseOtherSideMemberForm,
    DeleteCaseOtherSideMember: CaseOtherSideMemberForm,
    GetTwoFactorAuth: TwoFactorAuthForm,
    SubmitTwoFactorAuthCode: TwoFactorAuthCodeForm,
    ResendTwoFactorAuthCode: ResendTwoFactorAuthCodeForm,
    GetClientCase: ClientCaseForm,
    SubmitClientCaseUserAgreeToTOEStepAsComplete: ClientCaseSubmitStepAsCompleteForm,
    SubmitClientCaseUserDualRepConsentStepAsComplete: ClientCaseSubmitStepAsCompleteForm,
    SendCaseInstructedEmail: CaseSendInstructedEmailForm,
    CreateClientCaseBankAccount: ClientCaseCreateBankAccountForm,
    UpdateClientCaseBankAccount: ClientCaseBankAccountForm,
    DeleteClientCaseBankAccount: ClientCaseBankAccountForm,
    SubmitClientCaseBankAccountsForVerification: ClientCaseSubmitBankAccountsForVerificationForm,
    GetLegalCasesKeyDates: LegalCasesKeyDatesFormIO,
    UpdateCaseBankAccount: CaseBankAccountForm,
    CreateCaseLedgerEntry: CaseNewLedgerEntryForm,
    GetCaseLedger: CaseLedgerForm,
    DeleteCaseLedgerEntry: CaseLedgerEntryForm,
    UpdateCaseLedgerEntry: CaseLedgerEntryForm,
    CreateStripeIdVerificationLink: ClientCaseCreateIdVerificationLinkForm,
    UpdateClientCaseUserIdCheck: ClientCaseIdentityVerificationUserForm,
    CreateCaseMemberIdCheck: CaseMemberCreateIdCheckForm,
    UpdateCaseMemberIdCheck: CaseMemberIdCheckForm,
    UpdateCaseConflictOfInterest: CaseConflictOfInterestForm,
    CaseLedgerMarkLedgerAsFinal: CaseLedgerMarkLedgerAsFinalForm,
    AutocompleteClientVerificationAddress: ClientCaseIdentityVerificationUserForm,
    SignOffAsReadyForExchange: CaseSignOffAsReadyForExchangeForm,
    SwapCaseMember: CaseMemberSuggestedSimilarUserForm,
    InstructCase: CaseInstructForm,
    UpdateClientInitialPaymentUserSaysPaid: ClientCaseUserSaysPaymentPaidForm,
    UpdateClientCaseCompanyName: ClientCaseCompanyForm,
    CreateClientCaseUser: ClientCaseCreateUserForm,
    DeleteClientCaseUser: ClientCaseUserForm,
    UpdateClientCaseUser: ClientCaseUserForm,
    SubmitClientCaseUsersStepComplete: ClientCaseSubmitStepAsCompleteForm,
    CreateCaseAdHocJob: CaseAdHocJobNewForm,
    UpdateCaseAdHocJob: CaseAdHocJobForm,
    GetCaseRelatedSearch: CaseRelatedTransactionCreateForm,
    CreateCaseRelatedTransaction: CaseRelatedTransactionCreateSearchResultsForm,
    DeleteCaseRelatedTransaction: CaseRelatedTransactionForm1,
    UpdateClientCaseRelatedTransactionQuestion: ClientCaseRelatedTransactionQuestionForm,
    AutocompleteRelatedTransactionAddress: ClientCaseRelatedTransactionAddressForm,
    UpdateClientCaseRelatedTransactionAddress: ClientCaseRelatedTransactionAddressForm,
    UpdateCaseRelatedTransaction: CaseRelatedTransactionDataForm,
    SendNewLegalEmail: EmailCompositionForm,
    ReplyLegalEmail: EmailCompositionForm,
    ForwardLegalEmail: EmailCompositionForm,
    CreateCaseVideoVerificationCall: CaseVideoVerificationCallCreateForm,
    UpdateCaseVideoVerificationCall: CaseVideoVerificationCallListForm,
    DeleteCaseVideoVerificationCall: CaseVideoVerificationCallListForm,
    AssociateCaseUserVideoVerificationCall: CaseVideoVerificationCallAssociateWithUserForm,
    UpdateCaseUserVideoVerificationCall: CaseVideoVerificationCallUserForm,
    DisassociateCaseUserVideoVerificationCall: CaseVideoVerificationCallUserForm,
    GetClientCaseVideoVerificationCallAvailableTimes: ClientCaseVideoVerificationCallAvailableTimesForm,
    CreateClientCaseVideoVerificationCall: ClientCaseVideoVerificationCallCreateForm,
    GetCaseEmailAttachmentOptions: EmailAttachmentSearchForm,
    UploadClientCaseProofOfAddressDocument: ClientCaseIdentityVerificationUserProofOfAddressForm,
    UpdateClientCaseSdltQuestion: ClientCaseSdltForm,
    SubmitClientCaseSdltQuestion: ClientCaseSdltForm,
    SaveClientCasePurchasePaymentDetails: ClientCasePurchasePaymentDetailsForm,
    SubmitClientCasePurchasePaymentDetailsAsFinal: ClientCasePurchasePaymentDetailsForm,
    SubmitClientCaseRemortgageMortgageDetails: ClientCaseRemortgageMortgageDetailsForm,
    SubmitClientCasePropertyInformationFormsStepComplete: UuidForm,
    UpdateCaseSdlt: CaseSdltForm,
    SubmitCaseSdltDeclaration: CaseSdltDeclarationForm,
    GetTriageUnassignedSimpleEmails: TriageForm,
    GetTriageExpiredSimpleEmails: TriageForm,
    GetDetailedEmail: DetailedEmailForm,
    CaseLedgerRollback: CaseLedgerRollbackForm,
    SetTriageEmailAsIrrelevant: TriageEmailMarkAsIrrelevantForm,
    SetTriageEmailAsResolved: TriageEmailMarkAsResolvedForm,
    SetTriageEmailAsDisassociated: TriageEmailDisassociateFromCaseForm,
    AssignResolveAndCreateAdhocTriageEmail: TriageEmailAssignToCaseWithAdhocForm,
    AssignTriageEmailToUserOnly: TriageEmailAssignToUserOnlyForm,
    AssignTriageEmail: TriageEmailAssignToCaseAndUserForm,
    AssignAndResolveTriageEmail: TriageEmailAssignToCaseForm,
    
    GetAssigneeResolvedEmails: TriageForm,
    GetAssigneeUnresolvedEmails: TriageForm,
    GetUserUntriagedEmails: TriageForm,
    GetConfidentialEmails: TriageForm,
    GetCaseInboxEmails: TriageForm,

    SetTriageBulkEmailsAsIrrelevant: TriageBulkEmailMarkAsIrrelevantForm,
    GetUnresolvedInboxCount: InboxCountForm,
    SubmitCaseRiskAssessment: CaseNewRiskAssessmentForm,
    UpdateCaseAuditComments: CaseAuditForm,
    SubmitCaseAuditAsComplete: SubmitCaseAuditAsCompleteForm,
    GetSearchForTriageEmails: TriageEmailAssignToCaseSearchForm,
    GetCaseChargesAndRestrictions: CaseChargesAndRestrictionsForm,
    UpdateCaseChargesAndTitleRestrictionsCase: CaseChargesAndTitleRestrictionsCaseForm,
    CreateCaseTitleRestriction: CreateCaseTitleRestrictionForm,
    UpdateCaseTitleRestriction: CaseTitleRestrictionForm,
    DeleteCaseTitleRestriction: CaseTitleRestrictionForm,
    UpdateClientCaseCharge: ClientCaseChargeForm,
    SubmitClientCaseCharge: ClientCaseSubmitStepAsCompleteForm,
    CreateCaseChargeRedemption: CaseChargeRedemptionCreateForm,
    UploadCaseChargeRedemption: CaseChargeRedemptionUploadForm,
    DeleteCaseChargeRedemption: CaseChargeRedemptionForm,
    CreateCaseCharge: CreateCaseChargeForm,
    UpdateCaseCharge: CaseChargeForm,
    DeleteCaseCharge: CaseChargeForm,

    GetIntroducers: IntroducersForm,
    CreateIntroducer: IntroducerCreateForm,
    UpdateIntroducer: IntroducerForm,
    DeleteIntroducer: IntroducerForm,
    UploadIntroducerAgreement: IntroducerUploadAgreementForm,
    DeleteIntroducerAgreement: IntroducerExistingAgreementForm,

    GetMarketingCampaigns: MarketingCampaignsForm,
    CreateMarketingCampaign: MarketingCampaignCreateForm,
    UpdateMarketingCampaign: MarketingCampaignForm,
    DeleteMarketingCampaign: MarketingCampaignForm,

    GetCaseCustomerSatisfaction: CaseCustomerSatisfactionForm,
    UpdateCaseCustomerSatisfactionPin: CaseCustomerSatisfactionNoteTogglePinForm,
    CreateCaseCustomerSatisfactionNote: CaseCustomerSatisfactionNewNoteForm,
    CreateCaseCustomerSatisfactionFormalisedAsComplaint: CaseCustomerSatisfactionNewFormalisedAsComplaintForm,
    CreateCaseCustomerSatisfactionRaisedWithOmbudsman: CaseCustomerSatisfactionNewRaisedWithOmbudsmanForm,
    CreateCaseCustomerSatisfactionSentiment: CaseCustomerSatisfactionNewSentimentForm,
    CreateCaseCustomerSatisfactionAssignment: CaseCustomerSatisfactionNewAssignmentForm,
    CreateCaseCustomerSatisfactionDefer: CaseCustomerSatisfactionNewDeferForm,

    DeleteCaseCustomerSatisfactionNote: CaseCustomerSatisfactionDeleteNoteForm,
    DeleteCustomerSatisfactionSentiment: CaseCustomerSatisfactionDeleteSentimentForm,
    DeleteCustomerSatisfactionRaisedWithOmbudsman: CaseCustomerSatisfactionDeleteRaisedWithOmbudsmanForm,
    DeleteCustomerSatisfactionRaisedAsComplaint: CaseCustomerSatisfactionDeleteFormalisedAsComplaintForm,

    GetClientCompanyPartnerships: ClientCompanyPartnershipPageForm,
    UpdateClientCompanyPartnership: ClientCompanyPartnershipForm,
    CreateClientCompanyPartnership: ClientCompanyPartnershipCreateForm,
    DeleteClientCompanyPartnership: ClientCompanyPartnershipDeleteForm,
    ReviewClientCompanyPartnership: ClientCompanyPartnershipReviewForm,
    UploadClientCompanyPartnershipContract: ClientCompanyPartnershipContractUploadForm,
    DeleteClientCompanyPartnershipContract: ClientCompanyPartnershipContractForm,
    AutocompleteClientCompanyPartnershipAddress: ClientCompanyPartnershipForm,
    UpdateClientCompanyPartnershipUser: ClientCompanyPartnershipUserForm,
    DisassociateClientCompanyPartnershipUser: ClientCompanyPartnershipUserForm,
    CreateClientCompanyPartnershipUserPhoneNumber: ClientCompanyPartnershipUserPhoneNumberCreateForm,
    SetClientCompanyPartnershipUserPhoneNumberPrimary: ClientCompanyPartnershipUserPhoneNumberMakePrimaryForm,
    DeleteClientCompanyPartnershipUserPhoneNumber: ClientCompanyPartnershipUserPhoneNumberDeleteForm,

    GetCaseDualRepSearch: CaseDualRepCreateForm,
    LinkCaseDualRep: CaseDualRepSearchResultsForm,
    UnlinkCaseDualRep: CaseDualRepLinkedForm,

    GetProbateHelplineCallbacksNow: ProbateHelplineCallbackTypeForm,
    GetProbateHelplineCallbacksCompleted: ProbateHelplineCallbackTypeForm,
    UpdateProbateHelplineCallback: ProbateHelplineCallbackForm,
    SetProbateHelplineCallbackComplete: ProbateHelplineCallbackMarkCompleteForm,

    GetQuoteRates: QuoteRatePageForm,
    UpdateQuoteRate: QuoteRateCreatedForm,
    CreateQuoteRate: QuoteRateIntroducerIdForm,
    DeleteQuoteRate: UuidForm,
    ToggleDeleteOnNextPublishQuoteRate: UuidForm,
    SetDeleteOnNextPublishAndReplaceQuoteRate: UuidForm,
    PublishQuoteRates: QuoteRateIntroducerIdForm,
    
    CreateCaseQuote: CreateCaseQuoteFilledForm,
    UpdateCaseQuoteSentToClientStatus: CaseQuoteSentToClientForm,
    CreatePublicQuote: PublicCreateQuoteFilledForm,
    AutocompletePublicQuoteAddress: PublicCreateQuoteAddressForm,

    GetRoadblocks: RoadblocksForm,
    GetCaseRoadblocks: RoadblocksForm2,
    CreateChatMessage: ChatsMessageForm,
    GetRoadblock: RoadblockForm,
    UpdateRoadblockChecklistItem: RoadblockCheckListItemManualForm,
    SubmitRoadblockSignOff: RoadblockSignOffForm,

    GetCaseAutoAssignmentRules: CaseAutoAssignmentPageForm,
    UpdateCaseAutoAssignmentRules: CaseAutoAssignmentForm,

    CreateProbateLead: ProbateLeadForm,

    GetPublicPropertyReportTitleNumbers: PublicPropertyReportTitleSearchForm,
    CreatePublicPropertyReport: PublicPropertyReportCreateForm,
    GetPublicPropertyReportAddressSearch: PublicPropertyReportAddressSearchForm,

    UpdateSessionFeatureToggles: SessionFeatureToggleForm,

    UpdateClientCaseJointOwnershipHeldAsPreference: ClientCaseJointOwnershipHeldAsForm,

    UpdateClientCaseDeclarationOfTrustReferral: ClientCaseDeclarationOfTrustReferralForm,

    UpdateClientCaseWillReferral: ClientCaseWillReferralForm,

    GetCasesCaseView: CasesCaseViewForm,
    GetCasesCaseViewCase: CasesCaseForm,
    CreateCasesCaseAdhoc: CasesCaseCreateAdhocForm,
    UpdateCasesCaseTask: CasesCaseTaskFormUpdatableForCasesView,
    DeleteCasesCaseAdhoc: CasesCaseTaskFormAdhocForCasesView,
    UpdateCasesCase: CasesCaseForm,
    GetCasesDayView: CasesDayViewForm,

    GetCaseChaseMode: CaseChaseModeForm,
    ChaseModeAccept: ChaseModeUpdateForm,
    ChaseModeWorkableModeAcknowledge: ChaseModeUpdateForm,
    ChaseModeCloseAwaitingNewCase: ChaseModeUpdateForm,
    ChaseModeCloseCaseUnsuccessfully: ChaseModeUpdateForm,
    ChaseModeCloseCaseOpenedInError: ChaseModeUpdateForm,
    ChaseModeReopenCase: ChaseModeUpdateForm,
    ChaseModeReject: ChaseModeUpdateForm,
    ChaseModeSubmit: ChaseModeUpdateFormWithChaseReasons,
    ChaseModeSubmitToWorkable: ChaseModeUpdateForm,

    CreateServiceBearerToken: CreateServiceBearerTokenForm,
    CreateStaffUser: CreateStaffUserForm,

    CreateLegalDraftContractPack: UuidForm,
    CreateCaseTransferSetupRecord: NewTransferSetupRecordForCaseForm,
    UpdateCaseTransferSetupRecord: TransferSetupRecordForm,
    DeleteCaseTransferSetupRecord: TransferSetupRecordForm,
    CreateTA13: UuidForm,
    CreateLegalESignCert: UuidForm,
    CreateTransferDisclaimer: UuidForm
};

export type TFormOperationName = keyof TFormOperationCodecs;

export const formOperationNameCodec = union(pipe(
    (record.keys(formOperationCodecs) as NonEmptyArray<TFormOperationName>),
    nonEmptyArray.map((v) => literal(v)))
);

export const getContentType = (operation: TFormOperationName): ContentType.T => {
    if (formOperationCodecs[operation].type === "FileIOCodec") {
        return ContentType.constants.OCTECT_STREAM;
    }
    return ContentType.constants.JSON;
};