import React from "react";
import { CRMIcon } from "../CRMIcon/CRMIcon";
export class CRMCollapsibleSectionComponent extends React.Component<React.PropsWithChildren<{
    title: string;
    isOpen: boolean;
    onOpenChange: () => void;
}>> {
    public render (): JSX.Element {
        return (
            <div className="crm-collapsible-section-component">
                <div
                    className="crm-collapsible-section-component__header"
                    onClick={this.props.onOpenChange}
                >
                    <div className="crm-collapsible-section-component__header-column-title">
                        {this.props.title}
                    </div>
                    <div className={
                        `crm-collapsible-section-component__header-column-arrow
                        ${this.props.isOpen
                            ? "crm-collapsible-section-component__header-column-arrow--open"
                            : "crm-collapsible-section-component__header-column-arrow--closed"}`
                    }>
                        <CRMIcon
                            iconName={this.props.isOpen ? "up-arrow" : "down-arrow"}
                            colour="neutral-ink"
                            size="tiny"
                        />
                    </div>
                </div>
                {this.props.isOpen && <div>
                    {this.props.children}
                </div>}
            </div>
        );
    }
}
