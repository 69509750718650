import React, { useState } from "react";
import { CRMCardOutsidePopupBlank, TPopupSize } from "../CRMCardOutsidePopupBlank/CRMCardOutsidePopupBlank";
import { CRMParagraph } from "../Simple/CRMParagraph/CRMParagraph";
import { CRMInfoBoxSimpleColourWithEdit } from "../Simple/CRMInfoBoxSimpleColourWithEdit/CRMInfoBoxSimpleColourWithEdit";

type TGenericEditModalWithTextProps = {
    text: string,
    body: (setIsOpen: React.Dispatch<React.SetStateAction<boolean>>) => React.ReactNode,
    modalSize?: TPopupSize; 
};

export const CRMGenericEditModalWithText = React.memo((props: React.PropsWithChildren<TGenericEditModalWithTextProps>): JSX.Element => {

    const [isOpen, setIsOpen] = useState(false);

    return (
        <>
            <CRMInfoBoxSimpleColourWithEdit
                fullWidth={false}
                displayError={false}
                onEdit={() => setIsOpen(true)}
            >
                <CRMParagraph>
                        {props.text}
                </CRMParagraph>
            </CRMInfoBoxSimpleColourWithEdit>

            {/* MODAL */}
            <CRMCardOutsidePopupBlank
                isOpen={isOpen}
                onClose={() => setIsOpen(false)}
                size={props.modalSize ?? 'regular'}
            >
                {props.body(setIsOpen)}
            </CRMCardOutsidePopupBlank>
        </>
    );
});
