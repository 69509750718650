import React from "react";

type TPageWrapProps = {
};

export const CRMPageWrap = (props: React.PropsWithChildren<TPageWrapProps>): JSX.Element => {
    return (
        <div className="crm-page-wrap">
            {props.children}
        </div>
    );
};
