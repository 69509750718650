import React from "react";

type TFrontTitleHeader = {};

class FrontTitleHeader extends React.Component<React.PropsWithChildren<TFrontTitleHeader>> {
    public render (): JSX.Element {
        return (
            <div className="front-title-header">{this.props.children}</div>
        );
    }
}

export default FrontTitleHeader;
