import React from "react";
import { FrontColors } from "../../../models/FrontColors";
import { FrontSpacing } from "../../../models/FrontSpacing";
import { Flex } from "../../BuildingBlocks/Flex";
import { MaxWidth } from "../../BuildingBlocks/MaxWidth";
import { SpacingColumn } from "../../BuildingBlocks/SpacingColumn";
import { FPLandingTitle } from "../Simple/FPLandingTitle/FPLandingTitle";
import { FPSimpleLogo } from "../Simple/FPSimpleLogo/FPSimpleLogo";
import { TPublicPropertyReportAddressSearchForm } from "../../../../../domain/codecs/form/PublicPropertyReportForm";
import { FontQuicksand } from "../../BuildingBlocks/FontQuicksand";
import { FontSize } from "../../BuildingBlocks/FontSize";
import { TextAlign } from "../../BuildingBlocks/TextAlign";
import { TextColor } from "../../BuildingBlocks/TextColor";
import { WeightSemiBold } from "../../WeightSemiBold/WeightSemiBold";
import { FrontRadioButton } from "../../Front/Simple/FrontRadioButton/FrontRadioButton";
import FrontParagraphRegular from "../../Front/Simple/FrontParagraphRegular/FrontParagraphRegular";
import { WeightMedium } from "../../WeightMedium/WeightMedium";
import { FrontFontSizes } from "../../../models/FrontFontSizes";

export const FPPropertyReportValuationQuestion = (props: {
    form: TPublicPropertyReportAddressSearchForm,
    onSelect: (form: TPublicPropertyReportAddressSearchForm) => void,
}): JSX.Element => {
    const wantsValuation =
        props.form.edited.wants_valuation;

    const onChange = (wants_valuation: boolean) =>
        props.onSelect({
            ...props.form,
            edited: {
                ...props.form.edited,
                wants_valuation,
            },
        });

    const Button = (p: {
        value: boolean,
        label: JSX.Element,
    }): JSX.Element =>
        <FrontRadioButton
            coloursInverted={true}
            value={p.value}
            label={
                <FrontParagraphRegular>
                    <WeightMedium>
                        {p.label}
                    </WeightMedium>
                </FrontParagraphRegular>
            }
            isSelected={wantsValuation === p.value}
            onChange={onChange}
        />;

    return <SpacingColumn
        spacing={FrontSpacing.LARGE_1}
        justifyContent="center"
    >
        {/* LOGO */}
        <Flex justifyContent="center">
            <FPSimpleLogo width="95px" />
        </Flex>

        {/* HEADER */}
        <Flex justifyContent="center">
            <MaxWidth width="530px">
                <FPLandingTitle
                    size={60}
                    color={FrontColors.NEUTRAL_18}
                    align="center"
                >
                    Just a Few More Questions.
                </FPLandingTitle>
            </MaxWidth>
        </Flex>

        {/* QUESTION */}
        <Flex justifyContent="center">
            <MaxWidth width="530px">
                <Flex justifyContent="center">
                    <MaxWidth width="425px">
                        <FontQuicksand>
                            <FontSize
                                size={FrontFontSizes.MED}
                                lineHeight={1.2}
                            >
                                <WeightSemiBold>
                                    <TextColor color={FrontColors.NEUTRAL_18}>
                                        <TextAlign position="center">
                                            Would you like a free HMRC compliant valuation to assist with your inheritance tax forms?
                                        </TextAlign>
                                    </TextColor>
                                </WeightSemiBold>
                            </FontSize>
                        </FontQuicksand>
                    </MaxWidth>
                </Flex>
            </MaxWidth>
        </Flex>

        {/* OPTIONS */}
        <SpacingColumn spacing={FrontSpacing.MEDIUM_1}>
            {/* YES */}
            <Button
                value={true}
                label={<WeightSemiBold>Yes, arrange free valuation</WeightSemiBold>}
            />

            {/* NO */}
            <Button
                value={false}
                label={<>No, I understand that I need one but will make other arrangements</>}
            />
        </SpacingColumn>
    </SpacingColumn>;
};
