import { array, date, ord } from "fp-ts";
import { pipe } from "fp-ts/lib/function";
import { DateTime } from "luxon";
import React from "react";
import { MarketingCampaignSpendForm, TMarketingCampaignForm, TMarketingCampaignSpendForm } from "../../../../../domain/codecs/form/MarketingCampaignForm";
import { TMarketingCampaignSpend1 } from "../../../../../domain/codecs/MarketingCampaign";
import { doesErrorKeyExist } from "../../../../../shared/src/codecs/errors";
import { onChangeForm } from "../../../../../shared/src/codecs/types/form";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { Padding } from "../../BuildingBlocks/Padding";
import { SpacingColumn } from "../../BuildingBlocks/SpacingColumn";
import { SpacingRow } from "../../BuildingBlocks/SpacingRow";
import { CRMButtonQuaternary } from "../../CRMButtonQuaternary/CRMButtonQuaternary";
import { CRMIcon } from "../../CRMIcon/CRMIcon";
import { CRMInputCalendarComponent } from "../../CRMInputCalendarComponent/CRMInputCalendarComponent";
import { CRMInputCurrency } from "../../CRMInputCurrency/CRMInputCurrency";
import CRMInputGeneralComponent from "../../CRMInputs/CRMInputGeneralComponent/CRMInputGeneralComponent";
import CRMInputLabelAndErrorWrapComponent from "../../CRMInputLabelAndErrorWrapComponent/CRMInputLabelAndErrorWrapComponent";
import { CRMParagraph } from "../../Simple/CRMParagraph/CRMParagraph";
import { CRMTitleSubSection } from "../../CRMTitleSubSection/CRMTitleSubSection";

type TMarketingCampaignFormProps = {
    form: TMarketingCampaignForm;
    onChange: (form: TMarketingCampaignForm) => void;
};



export const CRMMarketingCampaignForm = (props: React.PropsWithChildren<TMarketingCampaignFormProps>): JSX.Element => {

    const onChange = onChangeForm(props.form, props.onChange);

    const onSpendChange = <A extends keyof TMarketingCampaignSpend1>(key: A, index: number) => (value: TMarketingCampaignSpend1[A]) =>
        pipe(
            {
                ...props.form,
                children: {
                    ...props.form.children,
                    spend_forms: pipe(
                        props.form.children.spend_forms,
                        array.mapWithIndex((currentIndex, spend_form): TMarketingCampaignSpendForm => index === currentIndex ? ({
                            ...spend_form,
                            status: "requiresSubmission",
                            edited: {
                                ...spend_form.edited,
                                [key]: value,
                            }
                        }) : spend_form)
                    )
                }
            },
            props.onChange
        );

    const onAddSpend = () =>
        pipe(
            {
                ...props.form,
                children: {
                    ...props.form.children,
                    spend_forms: [
                        ...props.form.children.spend_forms,
                        {
                            ...MarketingCampaignSpendForm.newDefault(),
                            status: "requiresSubmission",
                            edited: {
                                ...MarketingCampaignSpendForm.newDefault().edited,
                                cost_date: DateTime.utc().toFormat("yyyy-MM-dd"),
                            }
                        }
                    ]
                }
            },
            props.onChange
        );

    const onDeleteSpend = (index: number) =>
        pipe(
            {
                ...props.form,
                children: {
                    ...props.form.children,
                    spend_forms: pipe(
                        props.form.children.spend_forms,
                        array.filterWithIndex((i) => i !== index)
                    ),
                    spend_forms_to_be_deleted: [
                        ...props.form.children.spend_forms_to_be_deleted,
                        {...props.form.children.spend_forms[index]},
                    ]
                }
            },
            props.onChange
        );

    const spendFormsOrderedAndIndexed = pipe(
        props.form.children.spend_forms,
        // inject index before ordering
        array.mapWithIndex((index, spend_form) => ({index: index, spend_form: spend_form})),
        array.sortBy([
            pipe(
                date.Ord,
                ord.contramap((d: {index: number, spend_form: TMarketingCampaignSpendForm}) => new Date(d.spend_form.edited.cost_date))
            )
        ]),
    )

    return (
        <Padding 
            width="100%" 
            type="bottom" 
            spacing={"60px"}
        >
            <SpacingColumn spacing={CRMSpacing.X_LARGE}>
                
                {/* SECTION - AGREEMENT */}
                <SpacingColumn spacing={CRMSpacing.MEDIUM}>
                    <CRMTitleSubSection>
                        General
                    </CRMTitleSubSection>

                    <SpacingRow spacing={CRMSpacing.TINY}>
                        <CRMParagraph>
                            Short Code:
                        </CRMParagraph>
                        <CRMParagraph>
                            {props.form.original.short_code}
                        </CRMParagraph>
                    </SpacingRow>

                    {/* INPUT - NAME */}
                    <CRMInputLabelAndErrorWrapComponent
                        label="Campaign name"
                    >
                        <CRMInputGeneralComponent
                            value={props.form.edited.name}
                            inputType="text"
                            placeholder="Name"
                            onChange={onChange("name")}
                            displayError={false} 
                        />
                    </CRMInputLabelAndErrorWrapComponent>

                </SpacingColumn>

                {/* SECTION - SPEND */}
                <SpacingColumn spacing={CRMSpacing.MEDIUM}>

                    <CRMTitleSubSection>
                        Spend
                    </CRMTitleSubSection>

                    {
                        pipe(
                            spendFormsOrderedAndIndexed,
                            array.map(({index, spend_form}) => <SpacingRow key={index} spacing={CRMSpacing.MEDIUM} childSize="1fr 1fr auto"> 
                                <CRMInputCalendarComponent
                                    dateType={"date"}
                                    value={spend_form.edited.cost_date || ""}
                                    onChange={onSpendChange("cost_date", index)}
                                    onPressEnterKey={() => null}
                                    displayError={doesErrorKeyExist(`edited.cost_date`, spend_form.validationErrors)}
                                />
                                <CRMInputCurrency
                                    mode="pence"
                                    value={spend_form.edited.cost_pence}
                                    displayError={doesErrorKeyExist(`edited.cost_pence`, spend_form.validationErrors)}
                                    onChange={(value) => onSpendChange("cost_pence", index)(value || 0)}
                                />
                                <CRMIcon
                                    iconName="delete"
                                    size="medium"
                                    colour="neutral-ink"
                                    onClick={() => onDeleteSpend(index)}
                                />
                            </SpacingRow>)
                        )
                    }

                    {/* ADD ANOTHER */}
                    <SpacingRow justifyContent="start">
                        <CRMButtonQuaternary
                            label="Add another"
                            onClick={() => onAddSpend()}
                        />
                    </SpacingRow>

                </SpacingColumn>
                

            </SpacingColumn>
        </Padding>
    );
};
