import React, { CSSProperties } from "react";
import { TPercentageSize, TPixelSize, TViewHeight, TViewWidth } from "../../models/StringLiterals";

type TPositionProps = {
    divRef?: React.RefObject<HTMLDivElement>,
    position: CSSProperties["position"];
    top?: string;
    bottom?: string;
    left?: string;
    right?: string;
    zIndex?: CSSProperties["zIndex"];
    maxHeight?: string;
    display?: CSSProperties["display"];
    width?: TPixelSize | TPercentageSize | TViewWidth;
    height?: TPixelSize | TPercentageSize | TViewHeight;
};

export const Position = (props: React.PropsWithChildren<TPositionProps>): JSX.Element => {
    return (
        <div
            ref={props.divRef}
            style={{
                position: props.position,
                top: props.top || "auto",
                bottom: props.bottom || "auto",
                left: props.left || "auto",
                right: props.right || "auto",
                zIndex: props.zIndex || "auto",
                display: props.display || "inherit",
                width: props.width,
                height: props.height,
            }}
        >
            {props.children}
        </div>
    );
};
