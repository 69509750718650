import React from "react";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { SpacingColumn } from "../../BuildingBlocks/SpacingColumn";
import { SpacingRow } from "../../BuildingBlocks/SpacingRow";
import { CRMIcon, TIcon, TIconSizes } from "../../CRMIcon/CRMIcon";
import { CRMFontSizes } from "../../../models/CRMFontSizes";
import { CRMColors } from "../../../models/CRMColors";
import { FontQuicksand } from "../../BuildingBlocks/FontQuicksand";
import { Background } from "../../BuildingBlocks/Background";
import { Text } from "../../BuildingBlocks/Text";
import { CRMButtonQuaternary } from "../../CRMButtonQuaternary/CRMButtonQuaternary";
import { Flex } from "../../BuildingBlocks/Flex";
import { TextAlign } from "../../BuildingBlocks/TextAlign";
import { MaxWidth } from "../../BuildingBlocks/MaxWidth";

type TCRMEmptyPlaceholder = {
    title: string;
    buttonText: string;
    onClick: () => void;
};

export const CRMErrorPlaceholder = (props: React.PropsWithChildren<TCRMEmptyPlaceholder>): JSX.Element => {

    return (
        <Background padding={`100px 50px 50px`}>
            <SpacingColumn spacing={CRMSpacing.X_LARGE}>

                <SpacingColumn spacing={CRMSpacing.LARGE}>
                    {/* ICON */}
                    <SpacingRow justifyContent="center">
                        <CRMIcon
                            iconName={"broken"}
                            size={"70px"}
                            colour="hex"
                            hexColour={CRMColors.HIGHLIGHTS_INSTRUCTIONAL_BRICK_0}
                        />
                    </SpacingRow>

                    <SpacingColumn spacing={CRMSpacing.MEDIUM}>
                        {/* TEXT - TITLE */}
                        <TextAlign position="center">
                            <FontQuicksand>
                                <Text 
                                    size={CRMFontSizes.MED}
                                    lineHeight={1.5}
                                    color={CRMColors.PRIMARY_0}
                                    weight={700}
                                >
                                    {props.title}
                                </Text>
                            </FontQuicksand>
                        </TextAlign>

                        {/* TEXT - PARAGRAPH */}
                        <SpacingRow justifyContent="center">
                            <MaxWidth width="400px">
                                <TextAlign position="center">
                                    <FontQuicksand>
                                            <Text 
                                                size={CRMFontSizes.SMALL}
                                                lineHeight={1.5}
                                                color={CRMColors.PRIMARY_0}
                                                weight={500}
                                            >
                                                {props.children}
                                            </Text>
                                    </FontQuicksand>
                                </TextAlign>
                            </MaxWidth>
                        </SpacingRow>
                    </SpacingColumn>
                </SpacingColumn>

                {/* BUTTON - NEXT STEP */}
                <SpacingRow justifyContent="center">
                    <Flex>
                        <CRMButtonQuaternary
                            icon="arrow-left"
                            label={props.buttonText}
                            onClick={props.onClick}
                        />
                    </Flex>
                </SpacingRow>

            </SpacingColumn>
        </Background>
    );
};
