import React from "react";
import { CRMSpacing } from "../../../../models/CRMSpacing";
import { Background } from "../../../BuildingBlocks/Background";
import { CRMBottomControlBar } from "../../../CRMBottomControlBar/CRMBottomControlBar";
import { CRMButtonPrimary } from "../../../CRMButtonPrimary/CRMButtonPrimary";
import { CRMFormButton } from "../../../CRMFormButton/CRMFormButton";
import CRMTextAreaComponent from "../../../CRMTextAreaComponent/CRMTextAreaComponent";
import { CRMSingleViewHeader } from "../../../Simple/CRMSingleViewHeader/CRMSingleViewHeader";
import { CRMBlockPopover } from "../../CRMBlock/CRMBlockPopover/CRMBlockPopover";
import { TCasesCaseForm } from "../../../../../../domain/codecs/form/CasesForm";
import { onChangeForm } from "../../../../../../shared/src/codecs/types/form";

type CRMCaseEditCommentPopoverProps = {
    form: TCasesCaseForm;
    onChange: (form: TCasesCaseForm) => void;
    onSubmit: (form: TCasesCaseForm) => void;
    onClose: () => void;
}

export const CRMCaseEditCommentPopover = (props: CRMCaseEditCommentPopoverProps): JSX.Element => {
    const onChange = onChangeForm(props.form, props.onChange);

    return (
        <CRMBlockPopover
            display="flex"
            justifyContent="center"
            width="550px"
        >
            <div>
                {/* HEADER */}
                <CRMSingleViewHeader
                    title="Edit comment"
                    edge="border"
                    onClick={props.onClose}
                />

                {/* COMMENT */}
                <Background padding={CRMSpacing.LARGE}>
                    <CRMTextAreaComponent
                        value={props.form.edited.comment}
                        autoFocus={true}
                        placeholder="Add a brief comment"
                        onChange={onChange("comment")}
                        onEnter={() => props.onSubmit(props.form)}
                    />
                </Background>

                {/* BOTTOM BAR */}
                <CRMBottomControlBar
                    justifyContent="flex-end"
                    edge="border"
                >
                    <Background padding={`${CRMSpacing.TINY} ${CRMSpacing.MEDIUM}`}>
                        <CRMFormButton
                            formStatus={props.form.status}
                            ButtonElement={CRMButtonPrimary}
                            label="Submit"
                            onClick={() => props.onSubmit(props.form)}
                        />
                    </Background>
                </CRMBottomControlBar>
            </div>
        </CRMBlockPopover>
    );
};
