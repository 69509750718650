import React from "react";
import { TransactionType1_TransactionType4 } from "../../../../../../domain/codecs/TransactionType";
import SailSquareLogo from "../../../../../src/assets/SailUniversalSquareLogo";
import { useOpenClose } from "../../../../hooks/UseOpenClose"
import { FrontFontSizes } from "../../../../models/FrontFontSizes";
import { FrontSpacing } from "../../../../models/FrontSpacing";
import { elipseText } from "../../../../util";
import { FontQuicksand } from "../../../BuildingBlocks/FontQuicksand";
import { FontSize } from "../../../BuildingBlocks/FontSize";
import { Padding } from "../../../BuildingBlocks/Padding";
import { SpacingColumn } from "../../../BuildingBlocks/SpacingColumn";
import { SpacingRow } from "../../../BuildingBlocks/SpacingRow";
import { WeightBold } from "../../../WeightBold/WeightBold";
import { WeightSemiBold } from "../../../WeightSemiBold/WeightSemiBold";
import { FrontFormPrimaryButton } from "../../Simple/FrontFormPrimaryButton/FrontFormPrimaryButton";
import { FrontIcon } from "../../Simple/FrontIcon/FrontIcon";
import { FrontOnboardingCaseOverviewExplanation } from "../../Simple/FrontOnboardingCaseOverviewExplanation/FrontOnboardingCaseOverviewExplanation";
import { FrontOnboardingCaseOverviewQuoteInfo } from "../../Simple/FrontOnboardingCaseOverviewQuoteInfo/FrontOnboardingCaseOverviewQuoteInfo";
import { TOnboardingCaseOverviewProps, TOnboardingCaseOverviewTab } from "../FrontOnboardingCaseOverview/FrontOnboardingCaseOverview";
import { FrontOnboardingCaseOverviewCurrentStep } from "../FrontOnboardingCaseOverviewCurrentStep/FrontOnboardingCaseOverviewCurrentStep";
import { FrontOnboardingCaseOverviewStepsSummarySectionMobile } from "../FrontOnboardingCaseOverviewStepsSummarySectionMobile/FrontOnboardingCaseOverviewStepsSummarySectionMobile";

export const FrontOnboardingCaseOverviewMobile = <A extends string>(props: React.PropsWithChildren<TOnboardingCaseOverviewProps<A>>): JSX.Element => {
    const { isOpen, toggleOpen } = useOpenClose({
        turnOffGlobalScrollOnOpen: true,
    });

    const canSwitchToRelatedTransaction = !!props.switchToRelatedTransaction;

    const getHeader = (): string =>
        elipseText(props.address, 25);

    const hasMoreStepsToAnswer = () => props.currentTabs.length > 0;

    const onSelectTab = (tab: TOnboardingCaseOverviewTab<A>) => {
        toggleOpen();
        props.onChange(tab);
    }

    const onRelatedTransactionSwitch = () =>
        window.location.href = props.switchToRelatedTransaction?.url || "";

    return (
        <div 
            className={`
                front-onboarding-case-overview-mobile
                front-onboarding-case-overview-mobile--${isOpen ? "open" : "closed"}
            `}
        >
            <SpacingColumn>
                {/* FACE */}
                <SpacingColumn 
                    alignItems="center"
                    onClick={toggleOpen}
                >
                    <Padding
                        type="custom"
                        spacing={`11px ${FrontSpacing.SMALL_3}`}
                    >
                        <SpacingRow justifyContent="space-between">
                            <SpacingRow spacing={FrontSpacing.MEDIUM_1}>

                                <SailSquareLogo height="24px" width="24px" />

                                <FontQuicksand>
                                    <FontSize size={FrontFontSizes.SMALL}>
                                        {getHeader()}
                                    </FontSize>
                                </FontQuicksand>
                            </SpacingRow>

                            <FrontIcon
                                iconName={isOpen ? "chevron-up" : "menu"}
                                colour="neutral-6"
                                size="medium"
                            />
                        </SpacingRow>
                    </Padding>
                </SpacingColumn>

                {/* WHEN OPEN */}
                {isOpen &&
                    <div className="front-onboarding-case-overview-mobile__body">
                        <Padding type="custom" spacing={`${FrontSpacing.SMALL_3} ${FrontSpacing.MEDIUM_1} 80px ${FrontSpacing.MEDIUM_1}`}>
                            <SpacingColumn spacing={hasMoreStepsToAnswer() ? FrontSpacing.LARGE_1 : FrontSpacing.MEDIUM_1}>
                            {/* RELATED TRANSACTION SWITCH BUTTON */}
                            {canSwitchToRelatedTransaction &&
                                <div>
                                    <FrontFormPrimaryButton
                                        label={<WeightSemiBold>Switch to your <WeightBold>{TransactionType1_TransactionType4(props.switchToRelatedTransaction?.related_transaction_type || "unknown")}</WeightBold></WeightSemiBold>}
                                        icon="chevron-right"
                                        iconPosition="right"
                                        onClick={onRelatedTransactionSwitch}
                                    />
                                </div>
                            }

                                {/* EXPLANATION */}
                                <FrontOnboardingCaseOverviewExplanation
                                    allStepsAnswered={!hasMoreStepsToAnswer()}
                                    anyStepsDeferred={props.deferredTabs.length > 0}
                                    transactionType={props.transactionType}
                                    sailLegalQuoteFileExists={!!props.quoteDownloadUrl}
                                />

                                {/* TAB SECTION: CURRENT STEP */}
                                {hasMoreStepsToAnswer() ?
                                    props.currentTabs.map((currentTab) =>
                                        <FrontOnboardingCaseOverviewCurrentStep
                                            tab={currentTab}
                                            isMobileView={true}
                                            isSelected={false}
                                            onClick={() => onSelectTab(currentTab)}
                                        />
                                    )
                                    : undefined
                                }

                                {/* TAB SECTION: DEFERRED STEPS */}
                                {props.deferredTabs.length > 0 &&
                                    <FrontOnboardingCaseOverviewStepsSummarySectionMobile
                                        title={props.currentTabs.length > 0 ? "When you know the answers" : "We still need you to"}
                                        tabs={props.deferredTabs}
                                        onSelectTab={onSelectTab}
                                    />
                                }
                                
                                {/* TAB SECTION: COMPLETED STEPS */}
                                {props.completedTabs.length > 0 &&
                                    <FrontOnboardingCaseOverviewStepsSummarySectionMobile
                                        title={hasMoreStepsToAnswer() || props.deferredTabs.length > 0 ? "Completed" : undefined}
                                        tabs={props.completedTabs}
                                        onSelectTab={onSelectTab}
                                    />
                                }

                                {/* SAIL LEGAL QUOTE DOC INFO */}
                                {props.quoteDownloadUrl &&
                                    <FrontOnboardingCaseOverviewQuoteInfo
                                        quoteDownloadUrl={props.quoteDownloadUrl}
                                    />
                                }
                                
                            </SpacingColumn>
                        </Padding>
                    </div>
                }
            </SpacingColumn>
        </div>
    );
};
