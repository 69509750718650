import React from "react";
import { TContainerStateProps } from "../state/TContainerStateProps";
import { FrontPage } from "../components/Front/Simple/FrontPage/FrontPage";
import { SpacingRow } from "../components/BuildingBlocks/SpacingRow";
import FrontTwoFactorAuthCardContainer from "../containers/Front/FrontTwoFactorAuthCardContainer";
import { MaxWidth } from "../components/BuildingBlocks/MaxWidth";

const Front2FAPage = (props: TContainerStateProps): JSX.Element =>  {

    return (
        <FrontPage>
            <SpacingRow
                justifyContent="center"
                alignItems="flex-start"
            >
                <MaxWidth width="670px">
                    {props.state.forms.buyer2FAPage.status !== "untouched" && <FrontTwoFactorAuthCardContainer {...props}/>}
                </MaxWidth>
            </SpacingRow>
        </FrontPage>
    );
};


export default Front2FAPage;