import { array, option, record } from "fp-ts";
import { pipe } from "fp-ts/function";
import React from "react";
import { TListingFull1Editable } from "../../../../domain/codecs/formEditable/ListingEditable";
import { ListingDocumentManagementGenericControls, ListingDocumentManagementGenericDates, ListingDocumentManagementLeaseholdDates, TListingCRMReadOnlyData1 } from "../../../../domain/codecs/Listing";
import { TListingDocumentType } from "../../../../domain/codecs/ListingDocument";
import { doesErrorKeyExist } from "../../../../shared/src/codecs/errors";
import { requireExhaustive, snakeCaseToCopyText } from "../../../../shared/src/util";
import { CRMAutosaveIndicatorWrap } from "../CRM/CRMAutosaveIndicatorWrap/CRMAutosaveIndicatorWrap";
import { CRMButtonIcon } from "../CRMButtonIcon/CRMButtonIcon";
import { CRMCodecEditForm } from "../CRMCodecEditForm/CRMCodecEditForm";
import { CRMInputCalendarComponent } from "../CRMInputCalendarComponent/CRMInputCalendarComponent";
import CRMInputLabelAndErrorWrapComponent from "../CRMInputLabelAndErrorWrapComponent/CRMInputLabelAndErrorWrapComponent";
import { CRMSpacingColumn } from "../CRMSpacingColumn/CRMSpacingColumn";
import { CRMSpacingRow } from "../CRMSpacingRow/CRMSpacingRow";
import { CRMTitleSection } from "../CRMTitleSection/CRMTitleSection";

type TCRMListingDocumentManagementsFormProps = {
    listingForm: TListingFull1Editable;
    listingReadOnly: TListingCRMReadOnlyData1,
    onChange: (listingForm: TListingFull1Editable) => void;
};

type TKeysOfDates = keyof typeof ListingDocumentManagementGenericDates.payload | keyof typeof ListingDocumentManagementLeaseholdDates.payload;

const fromKeyGetDocType = (key: TKeysOfDates): TListingDocumentType => {
    switch (key) {
        case "epc_expected_date":
            return "energy_certificate";
        case "chancel_search_expected_date":
            return "chancel_search";
        case "drainage_and_water_search_expected_date":
            return "drainage_water_search";
        case "environment_search_expected_date":
            return "environmental_search";
        case "floorplan_expected_date":
            return "floorplan";
        case "freehold_title_plan_expected_date":
            return "title_plan";
        case "freehold_title_register_expected_date":
            return "title_register";
        case "local_authority_search_expected_date":
            return "local_authority_search";
        case "leasehold_lease_expected_date":
            return "leasehold_lease";
        case "leasehold_title_plan_expected_date":
            return "leasehold_title_plan";
        case "leasehold_title_register_expected_date":
            return "leasehold_title_register";
        default:
            return requireExhaustive(key);
    }
}

const fromDateKeyCreateOptionOfComponent = (key: TKeysOfDates, props: TCRMListingDocumentManagementsFormProps, currentDocumentTypesArray: TListingDocumentType[], excludedDocumentTypesArray: TListingDocumentType[]): option.Option<JSX.Element> => {
    const docType = fromKeyGetDocType(key);
    if (!currentDocumentTypesArray.includes(docType) && !excludedDocumentTypesArray.includes(docType)) {
        return option.some(
            <CRMInputLabelAndErrorWrapComponent label={snakeCaseToCopyText(key)} key={key}>
                <CRMSpacingRow spacing="tiny" childSize="1fr auto">
                    <CRMInputCalendarComponent
                        dateType={"date"}
                        value={props.listingForm.edited[key] || ""}
                        onChange={(value) => props.onChange({
                            ...props.listingForm,
                            edited: {
                                ...props.listingForm.edited,
                                [key]: value,
                            }
                        })}
                        onPressEnterKey={() => null}
                        displayError={doesErrorKeyExist(`edited.${key}`, props.listingForm.validationErrors)}
                    />
                    <CRMButtonIcon
                        variant="tertiary"
                        icon="backspace"
                        disabledColour="neutral-4"
                        disabled={props.listingForm.edited[key] ? false : true}
                        onClick={() => props.onChange({
                            ...props.listingForm,
                            edited: {
                                ...props.listingForm.edited,
                                [key]: null,
                            }
                        })}
                    />
                </CRMSpacingRow>
            </CRMInputLabelAndErrorWrapComponent>
        )
    }
    return option.none;
}

export const CRMListingDocumentManagementsForm = (props: TCRMListingDocumentManagementsFormProps): JSX.Element => {
    const currentDocumentTypesArray = props.listingReadOnly.documents.map((document) => document.output.editable_form.edited.document_type);
    return (
        <CRMAutosaveIndicatorWrap status={props.listingForm.status}>
            <CRMSpacingColumn spacing="large">
                <CRMTitleSection>
                    Documents
                </CRMTitleSection>
                {/* Generic controls */}
                <CRMCodecEditForm
                    codec={ListingDocumentManagementGenericControls}
                    model={props.listingForm.edited}
                    validationErrors={props.listingForm.validationErrors}
                    onChange={(edited) => props.onChange({
                        ...props.listingForm,
                        edited: {
                            ...props.listingForm.edited,
                            ...edited,
                        }
                    })}
                    showDeferDateLabels={true}
                />
                {/* Generic dates */}
                {
                    pipe(
                        ListingDocumentManagementGenericDates.payload,
                        record.keys,
                        array.filterMap((key) => fromDateKeyCreateOptionOfComponent(key, props, currentDocumentTypesArray, props.listingForm.edited.excluded_documents))
                    )
                }
                {/* leasehold dates */}
                {
                    props.listingForm.edited.tenure === "leasehold" && pipe(
                        ListingDocumentManagementLeaseholdDates.payload,
                        record.keys,
                        array.filterMap((key) => fromDateKeyCreateOptionOfComponent(key, props, currentDocumentTypesArray, props.listingForm.edited.excluded_documents))
                    )
                }
            </CRMSpacingColumn>
        </CRMAutosaveIndicatorWrap>
    );
};
