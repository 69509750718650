import * as rxjs from "rxjs";
import * as TGetState from "../../TGetState";
import * as TSetState from "../../TSetState";
import { TDispatch } from "../../TDispatch";
import * as ViewingType from "../../../../../domain/models/ViewingType";
import * as FirstPartyFetchResponse from "../../../../../domain/models/FirstPartyFetchResponse";
import * as ListingViewing1 from "../../../../../domain/models/ListingViewing1";
import * as JsonInnerError1 from "../../../../../domain/models/JsonInnerError1";
import * as ListingViewing3 from "../../../../../domain/models/ListingViewing3";
import * as JsonResponse1 from "../../../../../domain/models/JsonResponse1";
import * as ListingEnquiriesViewing1 from "../../../../../domain/models/ListingEnquiriesViewing1";
import * as rxjsOperators from "rxjs/operators";
import { option, tuple } from "fp-ts/lib";
import { pipe } from "fp-ts/lib/function";
import { DateTime } from "luxon";
import * as fetchWrapper from "../../../wrappers/fetch";
import * as t from "io-ts";
import { TActionObservable } from "../../applyActions";
import { BookViewingState } from "../../State";
import { C as State } from "../../State";
import { TActionsDefinitionsList } from "../TAction";
import { TValidationError } from "../../../../../shared/src/validation/Error";
import { createChangeRouteAction } from "../../router/createChangeRouteAction";
import { Routes } from "../../router/routerRoutes";

export const actions: TActionsDefinitionsList = [
    {
        type: "BOOK_VIEWING_VIEWING_MODE_INPUT_CHANGED" as const,
        run: (
            obs$: TActionObservable<"BOOK_VIEWING_VIEWING_MODE_INPUT_CHANGED", ViewingType.T>,
            getState: TGetState.TGetState,
            setState: TSetState.TSetState,
        ): void => {
            obs$.subscribe(({payload}) => {
                setState(({...s}) => {
                    s.activeData.bookViewing.viewingType = payload;

                    s.activeData.bookViewing.bookViewingResponse = FirstPartyFetchResponse.removeValidationErrors(
                        ["viewingType"],
                        s.activeData.bookViewing.bookViewingResponse
                    );

                    return s;
                });
            });
        },
    },
    {
        type: "BOOK_VIEWING_SLOT1_DATE_INPUT_CHANGED" as const,
        run: (
            obs$: TActionObservable<"BOOK_VIEWING_SLOT1_DATE_INPUT_CHANGED", string>,
            getState: TGetState.TGetState,
            setState: TSetState.TSetState,
        ): void => {
            obs$.subscribe(({payload}) => {
                slotInputChanged$(payload, setState, 0, "date");
            });
        },
    },
    {
        type: "BOOK_VIEWING_SLOT1_TIME_INPUT_CHANGED" as const,
        run: (
            obs$: TActionObservable<"BOOK_VIEWING_SLOT1_TIME_INPUT_CHANGED", string>,
            getState: TGetState.TGetState,
            setState: TSetState.TSetState,
        ): void => {
            obs$.subscribe(({payload}) => {
                slotInputChanged$(payload, setState, 0, "time");
            });
        },
    },
    {
        type: "BOOK_VIEWING_SLOT2_DATE_INPUT_CHANGED" as const,
        run: (
            obs$: TActionObservable<"BOOK_VIEWING_SLOT2_DATE_INPUT_CHANGED", string>,
            getState: TGetState.TGetState,
            setState: TSetState.TSetState,
        ): void => {
            obs$.subscribe(({payload}) => {
                slotInputChanged$(payload, setState, 1, "date");
            });
        },
    },
    {
        type: "BOOK_VIEWING_SLOT2_TIME_INPUT_CHANGED" as const,
        run: (
            obs$: TActionObservable<"BOOK_VIEWING_SLOT2_TIME_INPUT_CHANGED", string>,
            getState: TGetState.TGetState,
            setState: TSetState.TSetState,
        ): void => {
            obs$.subscribe(({payload}) => {
                slotInputChanged$(payload, setState, 1, "time");
            });
        },
    },
    {
        type: "BOOK_VIEWING_SLOT3_DATE_INPUT_CHANGED" as const,
        run: (
            obs$: TActionObservable<"BOOK_VIEWING_SLOT3_DATE_INPUT_CHANGED", string>,
            getState: TGetState.TGetState,
            setState: TSetState.TSetState,
        ): void => {
            obs$.subscribe(({payload}) => {
                slotInputChanged$(payload, setState, 2, "date");
            });
        },
    },
    {
        type: "BOOK_VIEWING_SLOT3_TIME_INPUT_CHANGED" as const,
        run: (
            obs$: TActionObservable<"BOOK_VIEWING_SLOT3_TIME_INPUT_CHANGED", string>,
            getState: TGetState.TGetState,
            setState: TSetState.TSetState,
        ): void => {
            obs$.subscribe(({payload}) => {
                slotInputChanged$(payload, setState, 2, "time");
            });
        },
    },
    {
        type: "BOOK_VIEWING_PROCEED_TO_ADDITIONAL_QUESTIONS_PAGE_BUTTON_CLICKED" as const,
        run: (
            obs$: TActionObservable<"BOOK_VIEWING_PROCEED_TO_ADDITIONAL_QUESTIONS_PAGE_BUTTON_CLICKED", undefined>,
            getState: TGetState.TGetState,
            setState: TSetState.TSetState,
            dispatch: TDispatch,
        ): void => {
            obs$
            .pipe(
                rxjsOperators.map(() => getState()),
                rxjsOperators.map((s) =>
                        ListingViewing1.validator({
                            viewing_type: s.activeData.bookViewing.viewingType,
                            requested_times: s.activeData.bookViewing.requestedTimes,
                        }),
                    ),
                    rxjsOperators.tap(
                        option.fold(
                            () => dispatch(createChangeRouteAction("VIEW_LISTING_BOOK_VIEWING_ADDITIONAL_QUESTIONS", { listingId: getState().routes.params.listingId }, {})),
                            (errors) => setState(({...s}) => {
                                s.activeData.bookViewing.bookViewingResponse = FirstPartyFetchResponse.create422(
                                    getState().activeData.bookViewing.bookViewingResponse.response,
                                    JsonInnerError1.arrayFromValidationErrors("Body", errors),
                                );
                                return s;
                            }),
                        ),
                    ),
            )
            .subscribe();
        },
    },
    {
        type: "BOOK_VIEWING_EDIT_VIEWING_SLOT_CHOICES_LINK_CLICKED" as const,
        run: (
            obs$: TActionObservable<"BOOK_VIEWING_EDIT_VIEWING_SLOT_CHOICES_LINK_CLICKED", undefined>,
            getState: TGetState.TGetState,
            setState: TSetState.TSetState,
            dispatch: TDispatch,
        ): void => {
            obs$.subscribe(() => {
                dispatch(createChangeRouteAction("VIEW_LISTING_BOOK_VIEWING", { listingId: getState().routes.params.listingId }, {}));
            });
        },
    },
    {
        type: "BOOK_VIEWING_FIRST_NAME_INPUT_CHANGED" as const,
        run: (
            obs$: TActionObservable<"BOOK_VIEWING_FIRST_NAME_INPUT_CHANGED", string>,
            getState: TGetState.TGetState,
            setState: TSetState.TSetState,
        ): void => {
            obs$
            .pipe(
                rxjsOperators.mergeMap(({payload}) => personalDetailsInputChanged$(payload, setState, "firstName"))
            )
            .subscribe();
        },
    },
    {
        type: "BOOK_VIEWING_LAST_NAME_NAME_INPUT_CHANGED" as const,
        run: (
            obs$: TActionObservable<"BOOK_VIEWING_LAST_NAME_NAME_INPUT_CHANGED", string>,
            getState: TGetState.TGetState,
            setState: TSetState.TSetState,
        ): void => {
            obs$
            .pipe(
                rxjsOperators.mergeMap(({payload}) => personalDetailsInputChanged$(payload, setState, "lastName"))
            )
            .subscribe();
        },
    },
    {
        type: "BOOK_VIEWING_CONTACT_EMAIL_INPUT_CHANGED" as const,
        run: (
            obs$: TActionObservable<"BOOK_VIEWING_CONTACT_EMAIL_INPUT_CHANGED", string>,
            getState: TGetState.TGetState,
            setState: TSetState.TSetState,
        ): void => {
            obs$
            .pipe(
                rxjsOperators.mergeMap(({payload}) => personalDetailsInputChanged$(payload, setState, "contactEmail"))
            )
            .subscribe();
        },
    },
    {
        type: "BOOK_VIEWING_CONTACT_PHONE_NUMBER_INPUT_CHANGED" as const,
        run: (
            obs$: TActionObservable<"BOOK_VIEWING_CONTACT_PHONE_NUMBER_INPUT_CHANGED", string>,
            getState: TGetState.TGetState,
            setState: TSetState.TSetState,
        ): void => {
            obs$
            .pipe(
                rxjsOperators.mergeMap(({payload}) => personalDetailsInputChanged$(payload, setState, "contactPhoneNumber"))
            )
            .subscribe();
        },
    },
    {
        type: "BOOK_VIEWING_CONFIRM_DETAILS_AND_VIEWING_SLOT_CHOICES_BUTTON_CLICKED" as const,
        run: (
            obs$: TActionObservable<"BOOK_VIEWING_CONFIRM_DETAILS_AND_VIEWING_SLOT_CHOICES_BUTTON_CLICKED", undefined>,
            getState: TGetState.TGetState,
            setState: TSetState.TSetState,
            dispatch: TDispatch,
        ): void => {
            obs$
            .pipe(
                rxjsOperators.map(() => getState()),
                rxjsOperators.map((s) =>
                    [
                        s,
                        ListingViewing3.validator({
                            viewing_type: s.activeData.bookViewing.viewingType,
                            requested_times: s.activeData.bookViewing.requestedTimes,
                            first_name: s.activeData.bookViewing.firstName,
                            last_name: s.activeData.bookViewing.lastName,
                            contact_email: s.activeData.bookViewing.contactEmail,
                            contact_phone_number: s.activeData.bookViewing.contactPhoneNumber,
                        }),
                    ] as [State<Routes>, option.Option<TValidationError>],
                ),
                rxjsOperators.mergeMap(([s, validationResult]) =>
                     pipe(
                        validationResult,
                        option.fold(
                            () => rxjs.from(
                                fetchWrapper.json<JsonResponse1.T<ListingEnquiriesViewing1.T, {}>>({
                                    requestParams: {
                                        url: `${env.REACT_APP_API_URL}/v1/public/listings/${s.routes.params.listingId}/viewings`,
                                        method: "POST",
                                        body: option.some(JSON.stringify({
                                            viewing_type: s.activeData.bookViewing.viewingType,
                                            requested_times: s.activeData.bookViewing.requestedTimes,
                                            first_name: s.activeData.bookViewing.firstName,
                                            last_name: s.activeData.bookViewing.lastName,
                                            contact_email: s.activeData.bookViewing.contactEmail,
                                            contact_phone_number: s.activeData.bookViewing.contactPhoneNumber,
                                        } as ListingViewing3.T)),
                                    },
                                    expectedTypeCodec: JsonResponse1.createCodec(ListingEnquiriesViewing1.codec, t.type({})),
                                    defaultResponse: getState().activeData.bookViewing.bookViewingResponse.response,
                                })(),
                            ).pipe(
                                rxjsOperators.map((r) => {
                                    setState(({...st}) => {
                                        st.activeData.bookViewing.bookViewingResponse = r;
                                        return st;
                                    });
                                    if (r.tag === FirstPartyFetchResponse.constants.STATUS_2XX) {
                                        dispatch(createChangeRouteAction("VIEW_LISTING_BOOK_VIEWING_SUCCESS", { listingId: getState().routes.params.listingId }, {}));
                                    }
                                }),
                            ),
                            (errors) => {
                                setState(({...st}) => {
                                    st.activeData.bookViewing.bookViewingResponse = FirstPartyFetchResponse.create422(
                                        getState().activeData.bookViewing.bookViewingResponse.response,
                                        JsonInnerError1.arrayFromValidationErrors("Body", errors),
                                    );
                                    return s;
                                });
                                return rxjs.of(undefined);
                            },
                        )
                    )
                ),
            )
            .subscribe();
        },
    },
];

const slotInputChanged$ = (value: string, setState: TSetState.TSetState, requestedViewingIndex: 0 | 1 | 2, inputType: "date" | "time"): rxjs.Observable<void> =>
    rxjs.of(
        setState(({...s}) => {
            // Set the requested viewing slot to an ISO timestamp string by concatenating the date and time values
            s.activeData.bookViewing.requestedTimes[requestedViewingIndex] =  pipe(
                // Where a timestamp already exists break apart the date from the time so they can be operated on independently
                s.activeData.bookViewing.requestedTimes[requestedViewingIndex].split("T"),
                // In cases where date or time hasn't been provided yet provide a default value
                (dta) => [
                    dta[0] || "",
                    dta[1] || "",
                ] as [string, string],
                tuple.map((date) =>
                    inputType === "date" ? (DateTime.fromISO(value).toFormat("yyyy-MM-dd") || date) : date
                ),
                tuple.mapLeft((time) =>
                    inputType === "time" ? value : time,
                ),
                (dta) => dta[0] === "" && dta[1] === "" ? ""
                    : dta[0] !== "" && dta[1] !== "" ? (DateTime.fromISO(dta.join("T"), { zone: "Europe/London" }).toUTC().toISO() || "")
                    : dta.join("T")
            );

            s.activeData.bookViewing.bookViewingResponse = FirstPartyFetchResponse.removeValidationErrors(
                [
                    "requestedTimes",
                    `requestedTimes.${requestedViewingIndex}`,
                ],
                s.activeData.bookViewing.bookViewingResponse
            );

            return s;
        }),
    );

const personalDetailsInputChanged$ = (value: string, setState: TSetState.TSetState, propertyToChange: Extract<keyof BookViewingState, "firstName" | "lastName" | "contactEmail" | "contactPhoneNumber">): rxjs.Observable<void> =>
    rxjs.of(
        setState(({...s}) => {
            s.activeData.bookViewing[propertyToChange] = value;

            s.activeData.bookViewing.bookViewingResponse = FirstPartyFetchResponse.removeValidationErrors(
                [propertyToChange],
                s.activeData.bookViewing.bookViewingResponse
            );

            return s;
        }),
    );
