import React from "react";
import * as TCurrency from "../../../../domain/models/TCurrency";
import * as OfferStatus from "../../../../domain/models/OfferStatus";
import * as EnquiryPaymentMethodDisplayName from "../../../../domain/models/EnquiryPaymentMethodDisplayName";
import * as PartyPurchasingSituation from "../../../../domain/models/PartyPurchasingSituation";
import * as User10 from "../../../../domain/models/User10";
import { CRMCardOutsideOutlined } from "../CRMCardOutsideOutlined/CRMCardOutsideOutlined";
import { CRMColumns } from "../CRMColumns/CRMColumns";
import { CRMTextHighlight } from "../CRMTextHighlight/CRMTextHighlight";
import { CRMSpacer } from "../CRMSpacer/CRMSpacer";
import { CRMTitleClear } from "../CRMTitleClear/CRMTitleClear";
import { CRMParagraph } from "../Simple/CRMParagraph/CRMParagraph";
import { array } from "fp-ts";
import { CRMIcon } from "../CRMIcon/CRMIcon";
import { WeightBold } from "../WeightBold/WeightBold";
import { WeightMedium } from "../WeightMedium/WeightMedium";
import { WeightRegular } from "../WeightRegular/WeightRegular";
import { Uppercase } from "../Uppercase/Uppercase";
import { TListingEnquiriesOffer2AndListingEnquiriesOfferForm2FormList } from "../../models/TFormModels";
import { CRMCardOutsidePopupFormSubmit } from "../CRMCardOutsidePopupFormSubmit/CRMCardOutsidePopupFormSubmit";
import { TJsonInnerErrorCodeTranslationRecord } from "../../../../domain/models/JsonInnerError1";
import { CRMLink } from "../CRMLink/CRMLink";
import { pipe } from "fp-ts/lib/function";

type TProps = {
    offer: TListingEnquiriesOffer2AndListingEnquiriesOfferForm2FormList["forms"][number];
    customErrorDisplayTextMap?: TJsonInnerErrorCodeTranslationRecord;
    onReturnToPending: () => void;
    onOpenResolution: () => void;
    onCloseResolution: () => void;
};

export class CRMCardOutsidePastOffer extends React.Component<React.PropsWithChildren<TProps>> {
    public render (): JSX.Element {
        return (
            <>
                {/* MODAL: RETURN TO PENDING */}
                <CRMCardOutsidePopupFormSubmit
                    formStatus={this.props.offer.status}
                    validationErrors={this.props.offer.validationErrors}
                    customErrorDisplayTextMap={this.props.customErrorDisplayTextMap}
                    isOpen={this.props.offer.status !== "untouched"}
                    title="Return offer to pending?"
                    ctaText="Return to pending"
                    onClose={this.props.onCloseResolution}
                    closeText="Cancel"
                    onCTA={this.props.onReturnToPending}
                    context="warning"
                >
                    <CRMParagraph>
                        Are you sure you want to return this offer back to pending?
                    </CRMParagraph>
                </CRMCardOutsidePopupFormSubmit>

                {/* CARD */}
                <CRMCardOutsideOutlined
                    borderRounding="right"
                >
                    <div className="crm-card-outside-past-offer">
                        {/* Offer amount and status */}
                        <CRMColumns
                            padding="tiny"
                            wrapOnMobile={false}
                            alignItems="center"
                            columns={[
                                {
                                    flex: 0,
                                    content: <CRMTitleClear fontSize="large">
                                        <div className="crm-card-outside-past-offer__title crm-card-outside-past-offer__title--strike">
                                            {TCurrency.fromNumber(this.props.offer.view.amount)}
                                        </div>
                                    </CRMTitleClear>,
                                },
                                {
                                    flex: 1,
                                    content: <div className="crm-card-outside-past-offer__title">
                                        <CRMTitleClear fontSize="large">
                                            <CRMTextHighlight
                                                colour={this.props.offer.edit.status === "withdrawn" ? "highlights-calming-pink-6" : "highlights-instructional-brick-4"}
                                            >
                                                {OfferStatus.toDisplayName(this.props.offer.edit.status)}
                                            </CRMTextHighlight>
                                        </CRMTitleClear>
                                    </div>,
                                },
                            ]}
                        />
                        <CRMSpacer size="tiny" />
                        {/* Payment method */}
                        <div className="crm-card-outside-past-offer__payment-method">
                            <WeightMedium>
                                {EnquiryPaymentMethodDisplayName.fromEnquiryPaymentMethod1(this.props.offer.view.enquiry.payment_method)}
                            </WeightMedium>
                        </div>
                        <CRMSpacer size="medium" />

                        {/* Withdrawn reason */}
                        {this.props.offer.edit.status === "withdrawn" && this.props.offer.view.withdrawn_reason && <>
                            <CRMParagraph>
                                <CRMTextHighlight
                                    colour="highlights-calming-pink-10"
                                >
                                    <WeightBold>Reason:</WeightBold> <WeightMedium>{this.props.offer.view.withdrawn_reason}</WeightMedium>
                                </CRMTextHighlight>
                            </CRMParagraph>
                            <CRMSpacer size="medium" />
                        </>}

                        {/* Rejected reason */}
                        {this.props.offer.edit.status === "rejected" && this.props.offer.edit.rejected_reason && <>
                            <CRMParagraph>
                                <CRMTextHighlight
                                    colour="highlights-instructional-brick-8"
                                >
                                    <WeightBold>Reason:</WeightBold> <WeightMedium>{this.props.offer.edit.rejected_reason}</WeightMedium>
                                </CRMTextHighlight>
                            </CRMParagraph>
                            <CRMSpacer size="medium" />
                        </>}

                        {/* Conditions */}
                        {this.props.offer.view.conditions && <>
                            <CRMParagraph>
                                <WeightBold>Conditions:</WeightBold> <WeightMedium>{this.props.offer.view.conditions}</WeightMedium>
                            </CRMParagraph>
                            <CRMSpacer size="tiny" />
                        </>}

                        {/* Purchasing situation */}
                        {this.props.offer.view.party.purchasing_situation !== "unknown" && <CRMParagraph>
                            <WeightRegular>
                                {PartyPurchasingSituation.fromValueToDisplayName(this.props.offer.view.party.purchasing_situation)}:
                            </WeightRegular>
                        </CRMParagraph>}

                        {/* USERS */}
                        <CRMParagraph>
                            <CRMLink
                                href={`/crm/enquiries/${this.props.offer.view.enquiry.id}`}
                                target="_blank"
                                linkStyle="normal"
                            >
                                <Uppercase>
                                    <WeightBold>
                                        { pipe(
                                            this.props.offer.view.party.users,
                                            array.map(User10.toDisplayName),
                                            (names) => names.join(" & ")
                                        )}
                                    </WeightBold>
                                </Uppercase>
                            </CRMLink>
                        </CRMParagraph>

                        {/* RETURN TO PENDING MODAL OPENING BUTTON */}
                        <div
                            className="crm-card-outside-past-offer__pending-button"
                            onClick={this.props.onOpenResolution}
                        >
                            <CRMIcon
                                iconName="repeat"
                                colour="neutral-ink"
                                size="small"
                            />
                        </div>
                    </div>
                </CRMCardOutsideOutlined>
            </>
        );
    }
}
