import * as QueryStringFilter1 from "./QueryStringFilter1";
import * as QueryStringFilterMappingResult from "./QueryStringFilterMappingResult";
import * as QueryStringFilterString from "./QueryStringFilterString";
import * as QueryStringFilterFullText from "./QueryStringFilterFullText";
import * as QueryStringFilterNumber from "./QueryStringFilterNumber";
import * as QueryStringFilterBoolean from "./QueryStringFilterBoolean";
import * as QueryStringFilterDate from "./QueryStringFilterDate";
import * as E164PhoneNumber from "../../shared/src/util/E164PhoneNumber";
import * as util from "../../shared/src/util";
import { option, array } from "fp-ts/lib";
import { pipe } from "fp-ts/lib/function";

export type T<O extends QueryStringFilter1.TOperatorValues> = ((operator: O, nthParam: number, realValue: string | number | boolean) => QueryStringFilterMappingResult.T);

export const createForString = (column: string): T<QueryStringFilterString.T["operator"]> =>
    ((operator, nthParam, value): QueryStringFilterMappingResult.T => ({
        condition: ((): string => {
            switch(operator) {
                case "BEGINS_WITH":
                    return `LOWER(${column}::varchar) LIKE LOWER($${nthParam}) || '%'`;
                case "EQUALS_STRING":
                    return `LOWER(${column}::varchar) = LOWER($${nthParam})`;
                case "NOT_EQUALS_STRING":
                    return `LOWER(${column}::varchar) <> LOWER($${nthParam})`;
                default:
                    return util.requireExhaustive(operator);
            }
        })(),
        value: option.some(value),
    }));

export const createForFullTextSearch = (column: string): T<QueryStringFilterFullText.T["operator"]> =>
    ((operator, nthParam, value): QueryStringFilterMappingResult.T => {
        switch(operator) {
            case "WORD_SEARCH_BEGINS_WITH_AND":
                return {
                    condition: `to_tsvector('simple', ${column}) @@ to_tsquery('simple', $${nthParam})`,
                    value:  pipe(
                        (typeof value === "string" ? value : "").split(" "),
                        array.map((s) => s.replace(/[^a-zA-Z0-9+]/g, "")),
                        array.map((s) => s.trim()),
                        array.filterMap((s) => s !== "" ? option.some(`${s}:*`) : option.none),
                        (a) => a.join(" & "),
                        option.some,
                    ),
                };
            case "WORD_SEARCH_BEGINS_WITH_OR":
                return {
                    condition: `to_tsvector('simple', ${column}) @@ to_tsquery('simple', $${nthParam})`,
                    value:  pipe(
                        (typeof value === "string" ? value : "").split(" "),
                        array.map((s) => s.replace(/[^a-zA-Z0-9+]/g, "")),
                        array.map((s) => s.trim()),
                        array.filterMap((s) => s !== "" ? option.some(`${s}:*`) : option.none),
                        (a) => a.join(" | "),
                        option.some,
                    ),
                };
            default:
                return util.requireExhaustive(operator);
        }
    });

export const createForNumber = (column: string): T<QueryStringFilterNumber.T["operator"]> =>
    ((operator, nthParam, value): QueryStringFilterMappingResult.T => ({
        condition: ((): string => {
            switch(operator) {
                case "EQUALS_NUMBER":
                    return `${column} = $${nthParam}`;
                case "GREATER_THAN_NUMBER":
                    return `${column} > $${nthParam}`;
                case "GREATER_THAN_OR_EQUALS_NUMBER":
                    return `${column} >= $${nthParam}`;
                case "LESS_THAN_NUMBER":
                    return `${column} < $${nthParam}`;
                case "LESS_THAN_OR_EQUALS_NUMBER":
                    return `${column} <= $${nthParam}`;
                default:
                    return util.requireExhaustive(operator);
            }
        })(),
        value: option.some(value),
    }));

export const createForBoolean = (column: string): T<QueryStringFilterBoolean.T["operator"]> =>
    ((operator, nthParam, value): QueryStringFilterMappingResult.T => ({
        condition: ((): string => {
            switch(operator) {
                case "EQUALS_BOOLEAN":
                    return `${column} = $${nthParam}`;
                default:
                    return util.requireExhaustive(operator);
            }
        })(),
        value: option.some(value),
    }));

export const createForDate = (column: string): T<QueryStringFilterDate.T["operator"]> =>
    ((operator, nthParam, value): QueryStringFilterMappingResult.T => {
        switch(operator) {
            case "EQUALS_DATE":
                return {
                    condition: `${column} = $${nthParam}`,
                    value: option.some(value),
                };
            case "GREATER_THAN_DATE":
                return {
                    condition: `${column} > $${nthParam}`,
                    value: option.some(value),
                };
            case "GREATER_THAN_OR_EQUALS_DATE":
                return {
                    condition: `${column} >= $${nthParam}`,
                    value: option.some(value),
                };
            case "LESS_THAN_DATE":
                return {
                    condition: `${column} < $${nthParam}`,
                    value: option.some(value),
                };
            case "LESS_THAN_OR_EQUALS_DATE":
                return {
                    condition: `${column} <= $${nthParam}`,
                    value: option.some(value),
                };
            case "DATE_IS_NULL":
                return {
                    condition: `${column} IS NULL`,
                    value: option.none,
                };
            case "DATE_IS_NOT_NULL":
                return {
                    condition: `${column} IS NOT NULL`,
                    value: option.none,
                };
            default:
                return util.requireExhaustive(operator);
        }
    });

export const createForDateInArray = (column: string): T<QueryStringFilterDate.T["operator"]> =>
    ((operator, nthParam, value): QueryStringFilterMappingResult.T => {
        switch(operator) {
            case "EQUALS_DATE":
                return {
                    condition: `$${nthParam} = ANY(${column})`,
                    value: option.some(value),
                };
            case "GREATER_THAN_DATE":
                return {
                    condition: `$${nthParam} < ANY(${column})`,
                    value: option.some(value),
                };
            case "GREATER_THAN_OR_EQUALS_DATE":
                return {
                    condition: `$${nthParam} <= ANY(${column})`,
                    value: option.some(value),
                };
            case "LESS_THAN_DATE":
                return {
                    condition: `$${nthParam} > ANY(${column})`,
                    value: option.some(value),
                };
            case "LESS_THAN_OR_EQUALS_DATE":
                return {
                    condition: `$${nthParam} >= ANY(${column})`,
                    value: option.some(value),
                };
            case "DATE_IS_NULL":
                return {
                    condition: `true = false`,
                    value: option.none,
                };
            case "DATE_IS_NOT_NULL":
                return {
                    condition: `true = false`,
                    value: option.none,
                };
            default:
                return util.requireExhaustive(operator);
        }
    });

export const createForPhoneNumber = (column: string): T<QueryStringFilterString.T["operator"]> =>
    (operator, nthParam, value): QueryStringFilterMappingResult.T =>
        createForString(column)(
            operator,
            nthParam,
            E164PhoneNumber.fromLocalNumber(typeof value === "string" ? value : "")
        );
