import React, { useEffect } from "react";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { CRMPopoutHeader } from "../../Simple/CRMPopoutHeader/CRMPopoutHeader";
import { SpacingColumn } from "../../BuildingBlocks/SpacingColumn";
import { CRMBottomControlBar } from "../../CRMBottomControlBar/CRMBottomControlBar";
import { Background } from "../../BuildingBlocks/Background";
import { SpacingRow } from "../../BuildingBlocks/SpacingRow";
import { CRMFormButton } from "../../CRMFormButton/CRMFormButton";
import { CRMButtonPrimary } from "../../CRMButtonPrimary/CRMButtonPrimary";
import { TContainerStateProps } from "../../../state/TContainerStateProps";
import { CRMCardOutsidePopupBlank } from "../../CRMCardOutsidePopupBlank/CRMCardOutsidePopupBlank";
import { CRMParagraph } from "../../Simple/CRMParagraph/CRMParagraph";

export const CRMCloseChaseContinueCasePopoutAcknowledgeView = (
    props: TContainerStateProps & {
        isOpen: boolean,
        onClose: () => void,
    }): JSX.Element => {
    
    const getAcknowledgeCaseIsWorkableAgainForm = () => props.state.forms.chase_mode_form.children.acknowledge_workable_transition_form;
    const isSubmitting = getAcknowledgeCaseIsWorkableAgainForm().status === "submitting" 
    const caseChaseStatus = props.state.forms.chase_mode_form.children.latest_chase.status;
    
    useEffect(
        () => {
            if (getAcknowledgeCaseIsWorkableAgainForm().status === "success") {
                props.onClose();
            }
        },
        [getAcknowledgeCaseIsWorkableAgainForm().status],
    );

    return (
        <CRMCardOutsidePopupBlank
            isOpen={props.isOpen}
            context="none"
            size="regular"
            borderRadius="5px"
            cardMargin="78px 0px"
            onClose={props.onClose}
        >
            {/* HEADER */}
            <CRMPopoutHeader
                icon="arrow-left"
                borderRadius="5px"
                onClick={props.onClose}
            >
                {caseChaseStatus === "workable" && "Chase Mode Switched Off"}{caseChaseStatus === "workable_rejected_chase_request" && "Chase Mode Request - Not Accepted"}
            </CRMPopoutHeader>
            
            <Background padding={CRMSpacing.LARGE} minHeight="100px">
                <SpacingColumn spacing={CRMSpacing.SMALL}>
                    <CRMParagraph>
                        {caseChaseStatus === "workable" && "Chase mode has been switched off - you can now work on the case again."}
                        {caseChaseStatus === "workable_rejected_chase_request" && "The request to put this case into Chase Mode was not accepted as there is work on the case which can be actioned at this time."}
                    </CRMParagraph>
                    <CRMParagraph>
                        Please click below to acknowledge this message.
                    </CRMParagraph>
                </SpacingColumn>
            </Background>


            {/* ACKNOwLEDGE BUTTON */}
            <div style={{overflow: "clip", borderRadius: "5px"}}>
                <CRMBottomControlBar
                    justifyContent="flex-end"
                    edge="border"
                >
                    <SpacingColumn>
                        <Background padding={CRMSpacing.MEDIUM}>
                            <SpacingRow spacing={CRMSpacing.MEDIUM} justifyContent="end">
                                <CRMFormButton
                                    ButtonElement={CRMButtonPrimary}
                                    formStatus={getAcknowledgeCaseIsWorkableAgainForm().status}
                                    label="Acknowledge"
                                    disabled={isSubmitting}
                                    onClick={() => props.dispatch({
                                        type: "LEGAL_CASE_WORKABLE_MODE_ACKNOWLEDGE_FORM_SUBMIT",
                                        payload: undefined,
                                    })}
                                />
                            </SpacingRow>
                        </Background>
                    </SpacingColumn>
                </CRMBottomControlBar>
            </div>
        </CRMCardOutsidePopupBlank>
    );
};
