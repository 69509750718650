import React from "react";
import { Button } from "../../components/OldFront/Simple/ButtonComponent/Button";
import * as DisplayAddress from "../../../../domain/models/DisplayAddress";
import Modal from "../../components/OldFront/Complex/ModalComponent/Modal";
import Heading3 from "../../components/OldFront/Simple/Heading3Component/Heading3";
import BodyText from "../../components/OldFront/Simple/BodyTextComponent/BodyText";
import { Dropdown } from "../../components/DropdownComponent/Dropdown";
import { IOption } from "./../../hooks/UseDropdown";
import * as Listing6 from "../../../../domain/models/Listing6";
import * as FirstPartyFetchResponse from "../../../../domain/models/FirstPartyFetchResponse";
import * as PartialISODatetime from "../../../../domain/models/PartialISODatetime";
import * as JsonInnerError1 from "../../../../domain/models/JsonInnerError1";
import { IListingPageProps } from "../../pages/ListingPage/ListingPage";
import * as util from "../../../../shared/src/util";
import * as constants from "../../../../domain/constants";
import AnchorButton from "../../components/OldFront/Simple/AnchorButton/AnchorButton";
import { array, option } from "fp-ts/lib";
import { pipe } from "fp-ts/lib/function";
import InputDate from "../../components/OldFront/Simple/Date/InputDate";
import { createChangeRouteAction } from "../../state/router/createChangeRouteAction";

const viewingTimeSlotsArray: Array<IOption<string>> = [
    { value: "", label: "--:--" },
    { value: "08:00", label: "08:00"}, { value: "08:30", label: "08:30" },
    { value: "09:00", label: "09:00" }, { value: "09:30", label: "09:30" },
    { value: "10:00", label: "10:00" }, { value: "10:30", label: "10:30" },
    { value: "11:00", label: "11:00" }, { value: "11:30", label: "11:30" },
    { value: "12:00", label: "12:00" }, { value: "12:30", label: "12:30" },
    { value: "13:00", label: "13:00" }, { value: "13:30", label: "13:30" },
    { value: "14:00", label: "14:00" }, { value: "14:30", label: "14:30" },
    { value: "15:00", label: "15:00" }, { value: "15:30", label: "15:30" },
    { value: "16:00", label: "16:00" }, { value: "16:30", label: "16:30" },
    { value: "17:00", label: "17:00" }, { value: "17:30", label: "17:30" },
    { value: "18:00", label: "18:00" }, { value: "18:30", label: "18:30" },
    { value: "19:00", label: "19:00" }, { value: "19:30", label: "19:30" },
];

export const render = (props: IListingPageProps, listingData: Listing6.T): React.ReactNode =>
    <Modal
        fullScreenOnMobile={true}
        onCloseClicked={() => props.dispatch(createChangeRouteAction("VIEW_LISTING", { listingId: listingData.id }, {}))}
    >
        <div className="listing__book-viewing-modal-heading-container">
            <Heading3 text="Book a viewing" />
        </div>
        <BodyText type="normal">
            <span className="listing__book-viewing-modal-address">
                {`At ${ pipe(
                    DisplayAddress.fromListing6(listingData),
                    DisplayAddress.toShortString,
                )}`}
            </span>
        </BodyText>
        <BodyText type="normal">
            How would you like to view this property?
        </BodyText>
        <div className={`listing__book-viewing-modal-buttons-container ${
            FirstPartyFetchResponse.hasValidationErrorsForTargetKeys(["viewingType"], props.state.activeData.bookViewing.bookViewingResponse)
                ? "listing__book-viewing-modal-buttons-container--error"
                : ""
        }`}>
            <div className="listing__book-viewing-modal-button-container">
                <Button
                    label="Online"
                    type="otter"
                    selectedState={props.state.activeData.bookViewing.viewingType === "online" ? "on" : "off" }
                    onClick={() => props.dispatch({
                        type: "BOOK_VIEWING_VIEWING_MODE_INPUT_CHANGED",
                        payload: "online",
                    })}
                    fullWidth={true}
                />
            </div>
            <div className="listing__book-viewing-modal-button-container"
            >
                <Button
                    label="In person"
                    type="otter"
                    selectedState={props.state.activeData.bookViewing.viewingType === "in_person" ? "on" : "off" }
                    onClick={() => props.dispatch({
                        type: "BOOK_VIEWING_VIEWING_MODE_INPUT_CHANGED",
                        payload: "in_person",
                    })}
                    fullWidth={true}
                />
            </div>
        </div>
        <BodyText type="normal">
            Please provide three dates and times that suit you. The seller will then select one of those three that they can do as well.
        </BodyText>
        <div className="listing__book-viewing-form">
            <div className="listing__book-viewing-form-slots-container">
                <div className="listing__book-viewing-slot-container">
                    <div className="listing__book-viewing-slot-date">
                        <label className="listing__book-viewing-input-label"
                            htmlFor="slot1Date"
                        >
                            {`Date 1`}
                        </label>
                        <InputDate
                            dateType="date"
                            placeholder={util.getNextDateMonToSat("DMY")}
                            value={PartialISODatetime.getDate(props.state.activeData.bookViewing.requestedTimes[0])}
                            displayError={
                                FirstPartyFetchResponse.hasValidationErrorsForTargetKeys(["requestedTimes", "requestedTimes.0"], props.state.activeData.bookViewing.bookViewingResponse)
                                    ? true
                                    : false
                            }
                            onChange={(payload) => props.dispatch({
                                type: "BOOK_VIEWING_SLOT1_DATE_INPUT_CHANGED",
                                payload,
                            })}
                            onPressEnterKey={() => undefined}
                        />
                    </div>
                    <div className="listing__book-viewing-slot-time">
                        <label className="listing__book-viewing-input-label"
                            htmlFor="slot1Time"
                        >
                            Time
                        </label>
                        <Dropdown
                            name="slot1Time"
                            onChange={(payload) => props.dispatch({
                                type: "BOOK_VIEWING_SLOT1_TIME_INPUT_CHANGED",
                                payload,
                            })}
                            options={viewingTimeSlotsArray}
                            value={PartialISODatetime.getHoursMinsInLocal(props.state.activeData.bookViewing.requestedTimes[0])}
                            displayError={FirstPartyFetchResponse.hasValidationErrorsForTargetKeys(["requestedTimes", "requestedTimes.0"], props.state.activeData.bookViewing.bookViewingResponse)}
                        />
                    </div>
                </div>
                <div className="listing__book-viewing-slot-container">
                    <div className="listing__book-viewing-slot-date">
                        <label className="listing__book-viewing-input-label"
                            htmlFor="slot2Date"
                        >
                            {`Date 2`}
                        </label>
                        <InputDate
                            dateType="date"
                            placeholder={util.getNextDateMonToSat("DMY")}
                            value={PartialISODatetime.getDate(props.state.activeData.bookViewing.requestedTimes[1])}
                            displayError={
                                FirstPartyFetchResponse.hasValidationErrorsForTargetKeys(["requestedTimes", "requestedTimes.1"], props.state.activeData.bookViewing.bookViewingResponse)
                                    ? true
                                    : false
                            }
                            onChange={(payload) => props.dispatch({
                                type: "BOOK_VIEWING_SLOT2_DATE_INPUT_CHANGED",
                                payload,
                            })}
                            onPressEnterKey={() => undefined}
                        />
                    </div>
                    <div className="listing__book-viewing-slot-time">
                        <label className="listing__book-viewing-input-label"
                            htmlFor="slot2Time"
                        >
                            Time
                        </label>
                        <Dropdown
                            name="slot2Time"
                            onChange={(payload) => props.dispatch({
                                type: "BOOK_VIEWING_SLOT2_TIME_INPUT_CHANGED",
                                payload,
                            })}
                            options={viewingTimeSlotsArray}
                            value={PartialISODatetime.getHoursMinsInLocal(props.state.activeData.bookViewing.requestedTimes[1])}
                            displayError={FirstPartyFetchResponse.hasValidationErrorsForTargetKeys(["requestedTimes", "requestedTimes.1"], props.state.activeData.bookViewing.bookViewingResponse)}
                        />
                    </div>
                </div>
                <div className="listing__book-viewing-slot-container">
                    <div className="listing__book-viewing-slot-date">
                        <label className="listing__book-viewing-input-label"
                            htmlFor="slot3Date"
                        >
                            {`Date 3`}
                        </label>
                        <InputDate
                            dateType="date"
                            placeholder={util.getNextDateMonToSat("DMY")}
                            value={PartialISODatetime.getDate(props.state.activeData.bookViewing.requestedTimes[2])}
                            displayError={
                                FirstPartyFetchResponse.hasValidationErrorsForTargetKeys(["requestedTimes", "requestedTimes.2"], props.state.activeData.bookViewing.bookViewingResponse)
                                    ? true
                                    : false
                            }
                            onChange={(payload) => props.dispatch({
                                type: "BOOK_VIEWING_SLOT3_DATE_INPUT_CHANGED",
                                payload,
                            })}
                            onPressEnterKey={() => undefined}
                        />
                    </div>
                    <div className="listing__book-viewing-slot-time">
                        <label className="listing__book-viewing-input-label"
                            htmlFor="slot3Time"
                        >
                            Time
                        </label>
                        <Dropdown
                            name="slot3Time"
                            onChange={(payload) => props.dispatch({
                                type: "BOOK_VIEWING_SLOT3_TIME_INPUT_CHANGED",
                                payload,
                            })}
                            options={viewingTimeSlotsArray}
                            value={PartialISODatetime.getHoursMinsInLocal(props.state.activeData.bookViewing.requestedTimes[2])}
                            displayError={FirstPartyFetchResponse.hasValidationErrorsForTargetKeys(["requestedTimes", "requestedTimes.2"], props.state.activeData.bookViewing.bookViewingResponse)}
                        />
                    </div>
                </div>
            </div>
            <div className="listing__book-viewing-submit-form-container">
                { pipe(
                    props.state.activeData.bookViewing.bookViewingResponse.validationInnerErrors,
                    array.findFirst((innerError) =>
                        innerError.error_code === "UtcDatetimeValidation"
                        || innerError.error_code === "FutureDatetimeValidation"
                        || innerError.error_code === "ArrayNotUniqueValidation"
                    ),
                    option.fold<JsonInnerError1.T, JSX.Element | string>(
                        () => "",
                        () => (
                            <div className="listing__book-viewing-error-messages-container">
                                <BodyText type="error">
                                    <div className="listing__book-viewing-error-messages-text">
                                        You must provide three different dates & times to view the property and they must be in the future
                                    </div>
                                    <AnchorButton
                                        label="Book a viewing"
                                        type="puffin"
                                        fullWidth={true}
                                        url={constants.sailHomesBrandConstants("web").contact.phoneURL}
                                    />
                                </BodyText>
                            </div>
                        ),
                    ),
                )}
                <div className="listing__book-viewing-submit-button-container"
                    onKeyDown={(e) => e.keyCode === 13 ? props.dispatch({type: "BOOK_VIEWING_PROCEED_TO_ADDITIONAL_QUESTIONS_PAGE_BUTTON_CLICKED"}): undefined}
                >
                    <Button
                        label="Request these viewing slots"
                        type="rooster"
                        fullWidth={true}
                        onClick={() => props.dispatch({type: "BOOK_VIEWING_PROCEED_TO_ADDITIONAL_QUESTIONS_PAGE_BUTTON_CLICKED"})}
                    />
                </div>
            </div>
        </div>
    </Modal>;
