import React from "react";
import { CRMSpacingRow } from "../CRMSpacingRow/CRMSpacingRow";
import { CRMParagraph } from "../Simple/CRMParagraph/CRMParagraph";
import { CRMTextHighlight } from "../CRMTextHighlight/CRMTextHighlight";
import { CRMSpacer } from "../CRMSpacer/CRMSpacer";
import { array } from "fp-ts";
import { CRMSectionLink } from "../CRMSectionLink/CRMSectionLink";
import { pipe } from "fp-ts/lib/function";
import { CRMFontSizes } from "../../models/CRMFontSizes";
import { CRMSavebarAlertDrawer } from "../Simple/CRMSavebarAlertDrawer/CRMSavebarAlertDrawer";

interface ICRMSavebarProps {
    unsavedSubFormElementIds?: Array<[string, string]>;
}

class CRMSavebarComponent extends React.Component<React.PropsWithChildren<ICRMSavebarProps>> {
    public render (): JSX.Element {
        return (
            <div className="crm-savebar-component">
                <div className="crm-savebar-component__main">
                    <div>
                        {this.props.children}
                    </div>
                    <div>
                        <CRMSpacingRow
                            justifyContent="end"
                        >
                            {this.props.unsavedSubFormElementIds && this.props.unsavedSubFormElementIds.length > 0 &&
                                <CRMSavebarAlertDrawer>
                                    <CRMParagraph fontSize={CRMFontSizes.X_SMALL}>
                                        The sub-forms below have been modified without saving or submitting. To save these forms click on the links and complete the form.
                                    </CRMParagraph>
                                    <CRMSpacer size="tiny"/>
                                    <CRMParagraph fontSize={CRMFontSizes.X_SMALL}>
                                        <CRMTextHighlight colour="highlights-pertinent-yellow-8">
                                            You can still leave the page or save the main form without saving these sub-forms. However the changes in the sub-forms will not be saved automatically.
                                        </CRMTextHighlight>
                                    </CRMParagraph>
                                    <div className="crm-savebar-component__section-links-container">
                                        {this.props.unsavedSubFormElementIds &&  pipe(
                                            this.props.unsavedSubFormElementIds,
                                            array.map(([id, name]) =>
                                                (<CRMSectionLink key={id} href={`#${id}`}>{name}</CRMSectionLink>)
                                            )
                                        )}
                                    </div>
                                </CRMSavebarAlertDrawer> 
                            }
                        </CRMSpacingRow>
                    </div>
                </div>
            </div>
        );
    }
}

export default CRMSavebarComponent;
