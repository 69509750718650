import React, { useState } from "react";
import { SpacingColumn } from "../../components/BuildingBlocks/SpacingColumn";
import { FrontQuestionFormCard } from "../../components/Front/Complex/FrontQuestionFormCard/FrontQuestionFormCard";
import { TContainerStateProps } from "../../state/TContainerStateProps";
import { FrontSpacing } from "../../models/FrontSpacing";
import { WeightBold } from "../../components/WeightBold/WeightBold";
import { FrontColors } from "../../models/FrontColors";
import { FrontFormSectionTitle } from "../../components/Front/Simple/FrontFormSectionTitle/FrontFormSectionTitle";
import { FrontCondensedRadioQuestions } from "../../components/Front/Simple/FrontCondensedRadioQuestions/FrontCondensedRadioQuestions";
import { onChangeForm, onChangeFormBulk } from "../../../../shared/src/codecs/types/form";
import { MediaCompSwitch } from "../../components/BuildingBlocks/MediaCompSwitch";
import { SpacingRow } from "../../components/BuildingBlocks/SpacingRow";
import { FrontFormPrimaryButton } from "../../components/Front/Simple/FrontFormPrimaryButton/FrontFormPrimaryButton";
import { FrontCondensedDropdownQuestion } from "../../components/Front/Simple/FrontCondensedDropdownQuestion/FrontCondensedDropdownQuestion";
import { TOnboardingTabStatus } from "../../components/Front/Simple/FrontOnboardingTab/FrontOnboardingTab";
import FrontParagraphRegular from "../../components/Front/Simple/FrontParagraphRegular/FrontParagraphRegular";
import { FrontPopupPrompt } from "../../components/Front/Complex/FrontPopupPrompt/FrontPopupPrompt";
import FrontParagraphSmall from "../../components/Front/Simple/FrontParagraphSmall/FrontParagraphSmall";
import { sailLegalBrandConstants } from "../../../../domain/constants";
import { FrontInfoBubble } from "../../components/Front/Simple/FrontInfoBubble/FrontInfoBubble";
import { ClientCasePurchasePaymentDetailsForm, TClientCasePurchasePaymentDetailsForm } from "../../../../domain/codecs/form/ClientCaseForm";
import { CasesLenderNameOnPanel1, CasesLenderNameOnPanel1_displayString } from "../../../../domain/codecs/CasesLenderNameOnPanel";
import { TCasesPurchaseMortgageLenderNameKnown } from "../../../../domain/codecs/Cases";
import { enumToIDropdownOptionsSortedByDisplayName } from "../../functions/enumToIDropdownOptions";
import { FrontHelpBubble } from "../../components/Front/Simple/FrontHelpBubble/FrontHelpBubble";
import { FrontLink } from "../../components/FrontLink/FrontLink";
import { penceToCopyText } from "../../../../shared/src/util";
import { pipe } from "fp-ts/lib/function";
import { FrontFormRow } from "../../components/Front/Simple/FrontFormRow/FrontFormRow";
import FrontInputCurrencyComponent from "../../components/Front/Simple/FrontInputCurrencyComponent/FrontInputCurrencyComponent";
import FrontInputLabelAndErrorWrapComponent from "../../components/Front/Simple/FrontInputLabelAndErrorWrapComponent/FrontInputLabelAndErrorWrapComponent";
import { FrontCondensedQuestionWrap } from "../../components/Front/Simple/FrontCondensedQuestionWrap/FrontCondensedQuestionWrap";

const FrontPurchasePaymentDetailsContainer = (props: TContainerStateProps): JSX.Element => {

    const [isSubmitFormOpen, setIsSubmitFormOpen] = useState(false);
    const [displayValidationErrorsIfPresent, setDisplayValidationErrorsIfPresent] = useState(false);

    const getClientCasePurchasePaymentDetailsForm = () =>
        props.state.forms.client_case_page.children.purchase_payment_details
        || ClientCasePurchasePaymentDetailsForm.newDefault();

    const isMortgagePurchase = (): boolean => getClientCasePurchasePaymentDetailsForm().edited.purchase_payment_method === "mortgage";
    
    const isCashPurchase = (): boolean => getClientCasePurchasePaymentDetailsForm().edited.purchase_payment_method === "cash";
    
    const getLenderNameKnownAnswer = () => getClientCasePurchasePaymentDetailsForm().edited.mortgage_lender_name_known;

    const getLenderName = () => getClientCasePurchasePaymentDetailsForm().edited.mortgage_lender_name;

    const getExpectedMortgagePaymentAmount = () => getClientCasePurchasePaymentDetailsForm().edited.expected_mortgage_amount_pence;

    const formCanBeSubmittedAsFinal = (): boolean =>
        isCashPurchase()
        || (
            isMortgagePurchase()
            && getLenderNameKnownAnswer() === "yes"
            && getLenderName() !== "unknown"
            && getExpectedMortgagePaymentAmount() !== null
        );

    const getWhichControlButtonOrInfoToDisplay = () => {
        if (hasBeenSubmitted()) {
            return "already_submitted_as_final_text";
        }

        if (!hasBeenSubmitted() && isCashPurchase()) {
            return "submit_as_final_button";
        }

        if (
            !hasBeenSubmitted()
            && isMortgagePurchase()
            && getLenderNameKnownAnswer() === "yes"
            && getExpectedMortgagePaymentAmount() !== null
        ) {
            return "submit_as_final_button";
        }

        return "save_progress_button";
    }

    const hasBeenSubmitted = () => props.state.forms.client_case_page.children.purchase_payment_details?.children.is_step_complete ? true : false;

    const onChangePurchasePaymentLenderNameKnown = (lenderNameKnown: TCasesPurchaseMortgageLenderNameKnown) =>
        onChangeFormBulk(
            getClientCasePurchasePaymentDetailsForm(),
            (payload) => props.dispatch({
                type: "CLIENT_UPDATE_PURCHASE_PAYMENT_DETAILS",
                payload,
            })
        )({
            mortgage_lender_name_known: lenderNameKnown,
            mortgage_lender_name: lenderNameKnown === "not_yet"
                ? "unknown"
                : props.state.forms.client_case_page.children.purchase_payment_details?.edited.mortgage_lender_name,
        });

    return (
        <div>
            <FrontQuestionFormCard
                title={"Payment method"}
            >
                <SpacingColumn spacing={FrontSpacing.LARGE_1}>

                    {/* PAYMENT DETAILS */}
                    <SpacingColumn spacing={FrontSpacing.LARGE_1}>
                        
                        {/* RADIO - ARE YOU FUNDING PURCHASE USING A MORTGAGE? */}
                        <FrontCondensedRadioQuestions
                            value={isMortgagePurchase() ? "mortgage"
                                : isCashPurchase() ? "cash"
                                : null
                            }
                            isEditable={!hasBeenSubmitted()}
                            label={`Will you be using a mortgage to fund the transaction?`}
                            options={[
                                {
                                    label: "Yes",
                                    value: "mortgage"
                                },
                                {
                                    label: "No (cash only)",
                                    value: "cash"
                                },
                            ]}
                            onChange={(value) => {
                                value === "cash" && setDisplayValidationErrorsIfPresent(false)
                                onChangeFormBulk(
                                    getClientCasePurchasePaymentDetailsForm(),
                                    (payload) => props.dispatch({
                                        type: "CLIENT_UPDATE_PURCHASE_PAYMENT_DETAILS",
                                        payload,
                                    })
                                )({
                                    purchase_payment_method: value,
                                    mortgage_lender_name_known: "unknown",
                                    mortgage_lender_name: "unknown",
                                    expected_mortgage_amount_pence: null,
                                })}
                            }
                            displayValidationErrorsIfPresent={displayValidationErrorsIfPresent}
                        />

                        {/* RADIO - DO YOU KNOW THE LENDER NAME YET */}
                        {isMortgagePurchase()
                            && <SpacingColumn spacing={FrontSpacing.MEDIUM_1}>
                                <FrontCondensedRadioQuestions
                                    value={getLenderNameKnownAnswer() === "unknown"
                                        ? null
                                        : getLenderNameKnownAnswer()
                                    }
                                    isEditable={!hasBeenSubmitted()}
                                    label="Do you know the name of your lender?"
                                    options={[
                                        {
                                            label: "Yes",
                                            value: "yes"
                                        },
                                        {
                                            label: "Not yet",
                                            value: "not_yet"
                                        },
                                    ]}
                                    onChange={onChangePurchasePaymentLenderNameKnown}
                                    displayValidationErrorsIfPresent={displayValidationErrorsIfPresent}
                                />

                                {getLenderNameKnownAnswer() === "not_yet"
                                    && <FrontHelpBubble
                                        type="pending"
                                        label="Tell us when this changes"
                                        paragraph={<>
                                            <WeightBold>It's important we know who your lender will be.</WeightBold> When you know just <FrontLink
                                                onClick={() => onChangePurchasePaymentLenderNameKnown("yes")}
                                            >click here to give us the details.</FrontLink>
                                        </>}
                                    />
                                }
                            </SpacingColumn>
                        }

                        {/* (IF MORTGAGE AND LENDER NAME KNOWN) INPUT - LENDER NAME */}
                        {isMortgagePurchase() && getLenderNameKnownAnswer() === "yes"
                            && <SpacingColumn spacing={FrontSpacing.SMALL_3}>

                                <FrontCondensedDropdownQuestion
                                    label="Which lender will you be using?"
                                    closedLabel="Mortgage lender"
                                    value={getLenderName()}
                                    isEditable={!hasBeenSubmitted()}
                                    isOpen={getLenderNameKnownAnswer() === "yes" && getLenderName() === "unknown"}
                                    options={enumToIDropdownOptionsSortedByDisplayName(
                                        CasesLenderNameOnPanel1.values,
                                        CasesLenderNameOnPanel1_displayString)  
                                    }
                                    wrapInFormRow={true}
                                    onChange={onChangeForm(
                                        getClientCasePurchasePaymentDetailsForm(),
                                        (payload) => props.dispatch({
                                            type: "CLIENT_UPDATE_PURCHASE_PAYMENT_DETAILS",
                                            payload,
                                        })
                                    )("mortgage_lender_name")}
                                    displayValidationErrorsIfPresent={displayValidationErrorsIfPresent}
                                    unknownValueIsError={true}
                                />

                                {getLenderName() === "unknown" && 
                                    <FrontParagraphSmall>
                                        If your lender is not on the list <WeightBold>please call us</WeightBold> on <WeightBold>{sailLegalBrandConstants().legalCSNumber}</WeightBold>.
                                    </FrontParagraphSmall>
                                }
                            </SpacingColumn>
                        } 

                        {/* EXPECTED MORTGAGE AMOUNT */}
                        {isMortgagePurchase() &&
                            <FrontCondensedQuestionWrap
                                isOpen={!hasBeenSubmitted()}
                                setIsOpen={() => !hasBeenSubmitted()}
                                label={`What amount of the mortgage will be used to pay for the transaction?`}
                                isEditable={!hasBeenSubmitted()}
                                summary={pipe(
                                    getExpectedMortgagePaymentAmount(),
                                    (amount) => amount ? penceToCopyText(amount) : ""
                                )}
                            >
                                <FrontFormRow childSize="1fr">
                                    <FrontInputLabelAndErrorWrapComponent
                                        label="What amount of the mortgage will be used to pay for the transaction?"
                                        errorMessage="We need to know this"
                                        displayError={
                                            displayValidationErrorsIfPresent
                                            && getLenderNameKnownAnswer() === "yes"
                                            && getLenderName() !== "unknown"
                                            && getExpectedMortgagePaymentAmount() === null
                                        }
                                        guidance={getLenderNameKnownAnswer() === "not_yet"
                                            ? <SpacingColumn spacing={FrontSpacing.SMALL_1}>
                                                <WeightBold>Don't worry if you don't know the exact amount yet.</WeightBold>
                                                <div>Enter the approximate amount for now. When you let us know who your lender will be you can update it then.</div>
                                            </SpacingColumn>
                                            : undefined
                                        }
                                    >
                                        <FrontInputCurrencyComponent
                                            value={getExpectedMortgagePaymentAmount()}
                                            onChange={(value) => {
                                                value !== null && setDisplayValidationErrorsIfPresent(false)
                                                onChangeForm(
                                                    getClientCasePurchasePaymentDetailsForm(),
                                                    (payload) => props.dispatch({
                                                        type: "CLIENT_UPDATE_PURCHASE_PAYMENT_DETAILS",
                                                        payload,
                                                    })
                                                )("expected_mortgage_amount_pence")(value)
                                            }}
                                        />
                                    </FrontInputLabelAndErrorWrapComponent>
                                </FrontFormRow>
                            </FrontCondensedQuestionWrap>
                        }   
           
                    </SpacingColumn>

                    {/* CONTROLS - SAVE/SUBMIT/POST-SUBMIT INFO */}
                    <SpacingColumn spacing={FrontSpacing.MEDIUM_1}>
                    
                        {/* CONTROLS - SAVE PROGRESS BUTTON */}
                        {
                            getWhichControlButtonOrInfoToDisplay() === "save_progress_button"
                            && <MediaCompSwitch
                                DesktopComponent={SpacingRow}
                                desktopProps={{ spacing: FrontSpacing.SMALL_3}}
                                MobileComponent={SpacingColumn}
                                mobileProps={{ spacing: FrontSpacing.SMALL_3 }}
                                breakpoint="600"
                            >
                                <FrontFormPrimaryButton
                                    label={`Save`}
                                    icon="done"
                                    isDisabled={false}
                                    onClick={() =>
                                        allNonDeferableQuestionsAreAnswered(getClientCasePurchasePaymentDetailsForm())
                                            ? props.dispatch({
                                                type: "CLIENT_SAVE_PURCHASE_PAYMENT_DETAILS",
                                                payload: null,
                                            })
                                            : setDisplayValidationErrorsIfPresent(true)
                                    }
                                />
                            </MediaCompSwitch>
                        }
                        
                        {/* CONTROLS - SUBMIT AS FINAL BUTTON */}
                        {
                            getWhichControlButtonOrInfoToDisplay() === "submit_as_final_button"
                            && <MediaCompSwitch
                                    DesktopComponent={SpacingRow}
                                    desktopProps={{ spacing: FrontSpacing.SMALL_3}}
                                    MobileComponent={SpacingColumn}
                                    mobileProps={{ spacing: FrontSpacing.SMALL_3 }}
                                    breakpoint="600"
                                >
                                    <FrontFormPrimaryButton
                                        label={`Finished? Submit payment details`}
                                        icon="lock"
                                        onClick={() => {
                                            if (formCanBeSubmittedAsFinal()) {
                                                props.dispatch({
                                                    type: "CLIENT_SAVE_PURCHASE_PAYMENT_DETAILS",
                                                    payload: null,
                                                })
                                                setIsSubmitFormOpen(true)
                                            } else {
                                                setDisplayValidationErrorsIfPresent(true)
                                            }
                                        }}
                                    />
                                </MediaCompSwitch>
                        }

                        {/* VALIDATION ERROR MESSAGE */}
                        {
                            !hasBeenSubmitted()
                            && displayValidationErrorsIfPresent
                            && (
                                (
                                    getLenderNameKnownAnswer() === "yes"
                                    && (
                                        getLenderName() === "unknown"
                                        || (getLenderName() !== "unknown" && getExpectedMortgagePaymentAmount() === null)
                                    )
                                )
                            )
                            && <FrontInfoBubble color={FrontColors.ERROR_FIREBRICK_22}>
                                <WeightBold>You've missed something above,</WeightBold> please take a look and then try again.
                            </FrontInfoBubble>
                        }

                        {/* AFTER SUBMISSION - HOW TO CHANGE INFO */}
                        {getWhichControlButtonOrInfoToDisplay() === "already_submitted_as_final_text"
                            && <>
                                <FrontFormSectionTitle>
                                    Need to change something?
                                </FrontFormSectionTitle>
                                <SpacingColumn spacing={FrontSpacing.SMALL_3}>
                                    <FrontParagraphSmall>
                                        If you have noticed that any of the details you have entered are wrong <WeightBold>please call us</WeightBold> on <WeightBold>{sailLegalBrandConstants().legalCSNumber}</WeightBold> and we will fix them.
                                    </FrontParagraphSmall>
                                </SpacingColumn>
                            </>
                        }
                    </SpacingColumn>

                </SpacingColumn>
            </FrontQuestionFormCard>

            <FrontPopupPrompt
                title={`Are these details correct?`}
                isOpen={isSubmitFormOpen}
                onCTA={() => props.dispatch({
                    type: "CLIENT_SUBMIT_PURCHASE_PAYMENT_DETAILS_FORM_AS_COMPLETE",
                    payload: null,
                })}
                ctaText="Yes, that's everything"
                closeText="No"
                onClose={() => setIsSubmitFormOpen(false)}
            >
                <SpacingColumn spacing={FrontSpacing.SMALL_3}>
                    <FrontParagraphRegular>
                        After you submit, you will not be able to edit your payment details unless you call us.
                    </FrontParagraphRegular>
                    <FrontParagraphRegular>
                        If you are happy with everything we can go onto the next step.
                    </FrontParagraphRegular>
                </SpacingColumn>
            </FrontPopupPrompt>
        </div>
    );
};

export const getPurchasePaymentDetailsStatus = (props: TContainerStateProps): TOnboardingTabStatus => {
    if (
        props.state.forms.client_case_page.children.purchase_payment_details?.children.is_step_complete !== null
        && props.state.forms.client_case_page.children.purchase_payment_details?.edited.purchase_payment_method === "cash"
    ) {
        return "completed";
    };

    if (
        props.state.forms.client_case_page.children.purchase_payment_details?.children.is_step_complete !== null
        && (
            props.state.forms.client_case_page.children.purchase_payment_details?.edited.purchase_payment_method === "mortgage"
            && props.state.forms.client_case_page.children.purchase_payment_details?.edited.mortgage_lender_name !== "unknown"
            && props.state.forms.client_case_page.children.purchase_payment_details?.edited.expected_mortgage_amount_pence !== null
        )
    ) {
        return "completed";
    }

    if (
        props.state.forms.client_case_page.children.purchase_payment_details?.original.purchase_payment_method === "mortgage"
        && (
            props.state.forms.client_case_page.children.purchase_payment_details?.original.mortgage_lender_name_known === "not_yet"
            || props.state.forms.client_case_page.children.purchase_payment_details?.original.expected_mortgage_amount_pence === null
        )
        && props.state.forms.client_case_page.children.purchase_payment_details?.status === "untouched"
    ) {
        return "deferred";
    }

    return "active";
};

export default FrontPurchasePaymentDetailsContainer;

const allNonDeferableQuestionsAreAnswered = (form: TClientCasePurchasePaymentDetailsForm): boolean => {
    const isPurchasePaymentMethodQuestionAnswered = form.edited.purchase_payment_method !== "unknown"
    const canProceedWithLenderNameAnswers = form.edited.mortgage_lender_name_known === "not_yet" || (form.edited.mortgage_lender_name_known === "yes" && form.edited.mortgage_lender_name !== "unknown")
    return isPurchasePaymentMethodQuestionAnswered && canProceedWithLenderNameAnswers 
}
