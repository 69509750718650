import React from "react";
import { SpacingColumn } from "../../BuildingBlocks/SpacingColumn";
import { ShineShape } from "../../BuildingBlocks/ShineShape";
import { FrontColors } from "../../../models/FrontColors";
import { SpacingRow } from "../../BuildingBlocks/SpacingRow";
import { Background } from "../../BuildingBlocks/Background";
import { FrontSpacing } from "../../../models/FrontSpacing";

export const FPPropertyReportSubmitting = (): JSX.Element =>
    <Background
        color={FrontColors.NEUTRAL_18}
        borderRadius="10px"
        width="700px"
        padding={FrontSpacing.LARGE_3}
    >
        <SpacingColumn spacing="25px">
            <ShinePill />

            <SpacingColumn spacing="20px">
                <ShineSubHeading />

                <ShineListItem />

                <ShineListItem />

                <ShineListItem />
            </SpacingColumn>
        </SpacingColumn>
    </Background>;

const ShinePill = (): JSX.Element =>
    <ShineShape
        backgroundColor={FrontColors.PRIMARY_2}
        shineColor={FrontColors.NEUTRAL_6}
        duration={4000}
        width="180px"
        height="34px"
        borderRadius="18px"
    />;

const ShineSubHeading = (): JSX.Element =>
    <SpacingColumn spacing="5px">
        <ShineShape
            backgroundColor={FrontColors.NEUTRAL_13}
            shineColor={FrontColors.NEUTRAL_20}
            duration={2500}
            width="100%"
            height="15px"
        />
        <ShineShape
            backgroundColor={FrontColors.NEUTRAL_13}
            shineColor={FrontColors.NEUTRAL_20}
            duration={2500}
            width="20%"
            height="15px"
        />
    </SpacingColumn>;

const ShineListItem = (): JSX.Element =>
    <SpacingRow
        justifyContent="start"
        alignItems="flex-start"
        childSize="max-content 1fr"
        spacing="15px"
    >
        <ShineShape
            backgroundColor={FrontColors.NEUTRAL_13}
            shineColor={FrontColors.NEUTRAL_20}
            duration={2500}
            width="20px"
            height="15px"
        />

        <SpacingColumn spacing="5px">
            <ShineShape
                backgroundColor={FrontColors.NEUTRAL_13}
                shineColor={FrontColors.NEUTRAL_20}
                duration={2500}
                width="100%"
                height="15px"
            />
            <ShineShape
                backgroundColor={FrontColors.NEUTRAL_13}
                shineColor={FrontColors.NEUTRAL_20}
                duration={2500}
                width="100%"
                height="15px"
            />
            <ShineShape
                backgroundColor={FrontColors.NEUTRAL_13}
                shineColor={FrontColors.NEUTRAL_20}
                duration={2500}
                width="20%"
                height="15px"
            />
        </SpacingColumn>
    </SpacingRow>;
