import React from "react";
import { array } from "fp-ts";
import { FrontIcon, TIcon } from "../../Simple/FrontIcon/FrontIcon";
import * as util from "../../../../../../shared/src/util";
import FrontParagraphRegular from "../../Simple/FrontParagraphRegular/FrontParagraphRegular";
import { WeightMedium } from "../../../WeightMedium/WeightMedium";
import { FrontSpacer } from "../../../FrontSpacer/FrontSpacer";
import { FrontLink } from "../../../FrontLink/FrontLink";
import { pipe } from "fp-ts/lib/function";

export class FrontBuyerReadyWaysToImprove extends React.Component<{
    showTipsFor: Array<
        "proof_of_funds"
        | "id_check"
        | "solicitor_details"
        | "mortgage_broker_details">;
}> {
    getTipIconToUse = (tip: FrontBuyerReadyWaysToImprove["props"]["showTipsFor"][number]): TIcon => {
        switch(tip) {
            case "proof_of_funds":
                return "coins";
            case "id_check":
                return "id-card";
            case "solicitor_details":
                return "contract";
            case "mortgage_broker_details":
                return "bank";
            default:
                return util.requireExhaustive(tip);
        }
    };

    getTipTitleToUse = (tip: FrontBuyerReadyWaysToImprove["props"]["showTipsFor"][number]): string => {
        switch(tip) {
            case "proof_of_funds":
                return "Proof of funds";
            case "id_check":
                return "ID check";
            case "solicitor_details":
                return "Solicitor details";
            case "mortgage_broker_details":
                return "Mortgage broker details";
            default:
                return util.requireExhaustive(tip);
        }
    };

    getTipDescriptionToUse = (tip: FrontBuyerReadyWaysToImprove["props"]["showTipsFor"][number]): JSX.Element => {
        switch(tip) {
            case "proof_of_funds":
                return <>
                    If you're planning on purchasing the property with a mortgage please email a copy of your Decision In Principle / Agreement In Principle to us. We'll also need to see a screenshot of a bank statement showing the funds required for your mortgage deposit. If you are planning on purchasing the property entirely with cash funds, you'll only need to provide the bank statement screenshot.&nbsp;
                    <FrontLink href={`mailto:hello@sailhomes.co.uk?subject=My proof of funds`}>Click here to email this to us.</FrontLink>
                </>;
            case "id_check":
                return <>
                    You should have received an SMS from Thirdfort to complete an ID check. Please let us know if you have any trouble with this or haven't received the message.
                </>;
            case "solicitor_details":
                return <>
                    Please give us the contact details of the solicitor you will be using if your offer is accepted. If you need a recommendation let us know, we'd be happy to help.&nbsp;
                    <FrontLink href={`mailto:hello@sailhomes.co.uk?subject=My solicitor details`}>Click here to email this to us.</FrontLink>
                </>;
            case "mortgage_broker_details":
                return <>
                    If you are using a mortgage broker please give us their contact details. We'll then get in touch to confirm your position.&nbsp;
                    <FrontLink href={`mailto:hello@sailhomes.co.uk?subject=My mortgage broker's details`}>Click here to email this to us.</FrontLink>
                </>;
            default:
                return util.requireExhaustive(tip);
        }
    };

    public render (): JSX.Element {
        return (
            <div className="front-buyer-ready-ways-to-improve">
                { pipe(
                    this.props.showTipsFor,
                    array.mapWithIndex((i, tip) =>
                        <div key={i} className="front-buyer-ready-ways-to-improve__row">
                            <div className="front-buyer-ready-ways-to-improve__cell front-buyer-ready-ways-to-improve__cell--icon">
                                <FrontIcon
                                    iconName={this.getTipIconToUse(tip)}
                                    size="extra-large"
                                    colour="neutral-2"
                                />
                            </div>
                            <div className="front-buyer-ready-ways-to-improve__cell">
                                <FrontParagraphRegular>
                                    <WeightMedium>
                                        {this.getTipTitleToUse(tip)}
                                    </WeightMedium>
                                </FrontParagraphRegular>
                                <FrontSpacer size="small-2" />
                                <FrontParagraphRegular>
                                    {this.getTipDescriptionToUse(tip)}
                                </FrontParagraphRegular>
                            </div>
                        </div>
                    ),
                )}
            </div>
        );
    }
}
