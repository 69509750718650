import React from "react";
import { SpacingColumn } from "../../../components/BuildingBlocks/SpacingColumn";
import { FrontQuestionFormCard } from "../../../components/Front/Complex/FrontQuestionFormCard/FrontQuestionFormCard";
import { TContainerStateProps } from "../../../state/TContainerStateProps";
import { FrontSpacing } from "../../../models/FrontSpacing";
import { TOnboardingTabStatus } from "../../../components/Front/Simple/FrontOnboardingTab/FrontOnboardingTab";
import FrontParagraphSmall from "../../../components/Front/Simple/FrontParagraphSmall/FrontParagraphSmall";
import { FrontBulletpoint } from "../../../components/Front/Simple/FrontBulletpoint/FrontBulletpoint";
import { FrontTextColour } from "../../../components/FrontTextColour/FrontTextColour";
import { WeightBold } from "../../../components/WeightBold/WeightBold";
import { FrontLink } from "../../../components/FrontLink/FrontLink";
import { FrontFormSectionTitle } from "../../../components/Front/Simple/FrontFormSectionTitle/FrontFormSectionTitle";
import { requireExhaustive } from "../../../../../shared/src/util";
import { literal } from "../../../../../shared/src/codecs/types/literal";
import { union } from "../../../../../shared/src/codecs/types/union";
import { TTypeOfCodec } from "../../../../../shared/src/codecs/codec";
import { FrontOnboardingCaseTOEExplanationMobile } from "../../../components/Front/Complex/FrontOnboardingCaseTOEExplanationMobile/FrontOnboardingCaseTOEExplanationMobile";
import { Sticky } from "../../../components/BuildingBlocks/Sticky";
import { FrontFormAgreeButton } from "../../../components/Front/Simple/FrontFormAgreeButton/FrontFormAgreeButton";
import { SpacingRow } from "../../../components/BuildingBlocks/SpacingRow";

const FrontAgreeToTOEContainer = (props: React.PropsWithChildren<TContainerStateProps>): JSX.Element => (
    <>
        {/* ON MOBILE - TERMS, AGREE BUTTON AND EXPLANATION */}
        <div className="front-agree-to-toe-container__mobile">
            <SpacingColumn spacing={FrontSpacing.MEDIUM_1}>
                
                {/* AGREE BUTTON */}
                {!props.state.forms.client_case_page.children.agree_to_toe.is_step_complete &&
                    <Sticky top="60px">
                        <SpacingRow justifyContent="center">
                            <FrontFormAgreeButton
                                label="I agree to the terms"
                                icon="done"
                                hasBoxShadow={true}
                                onClick={() => props.dispatch({
                                    type: "CLIENT_SUBMIT_AGREE_TO_TOE_STEP_FORM_AS_COMPLETE",
                                    payload: null
                                })}
                            />
                        </SpacingRow>
                    </Sticky>
                }
            
                <SpacingColumn spacing={FrontSpacing.MEDIUM_2}>
                    
                    {/* TOE EXPLANATION BUBBLE - MOBILE */}
                    {!props.state.forms.client_case_page.children.agree_to_toe.is_step_complete &&
                        <FrontOnboardingCaseTOEExplanationMobile 
                            caseUserType={props.state.forms.client_case_page.children.session_user_case_user_type.user_type}
                            transactionType={props.state.forms.client_case_page.children.case.transaction_type}
                            onClick={() => undefined}
                        />
                    }
                    
                    {/* TERMS - MOBILE */}
                    <FrontQuestionFormCard
                        title="Your Agreement With Us"
                        >
                        <SpacingColumn spacing={FrontSpacing.LARGE_1}>
                            {ToeSectionTitle.values.map((title) => getToeSection(title))}
                        </SpacingColumn>
                    </FrontQuestionFormCard>
                
                </SpacingColumn>
            </SpacingColumn>
        </div>

        {/* DESKTOP - TERMS */}
        <div className="front-agree-to-toe-container__desktop">
            <FrontQuestionFormCard
                title="Your Agreement With Us"
            >
                <SpacingColumn spacing={FrontSpacing.LARGE_1}>
                    {ToeSectionTitle.values.map((title) => getToeSection(title))}
                </SpacingColumn>
            </FrontQuestionFormCard>
        </div>
    </>
);

export const getAgreeToTOEStatus = (props: TContainerStateProps): TOnboardingTabStatus => {

    if (props.state.forms.client_case_page.children.agree_to_toe.is_step_complete === true) {
        return "completed";
    }
    
    return "active";
};

const ToeSectionTitle = union([
    literal("standardTermsOfEngagement"),
    literal("yourInstructions"),
    literal("ourService"),
    literal("whatOurServiceDoesNotInvolve"),
    literal("whatWeNeedFromYou"),
    literal("communication"),
    literal("identification"),
    literal("ourFees"),
    literal("whenToSendMoney"),
    literal("sofAndSow"),
    literal("yourMoneyDeposit"),
    literal("abortiveTransactions"),
    literal("paymentOfFees"),
    literal("interestOnFees"),
    literal("cybercrimeAndFraud"),
    literal("sdltAndLtt"),
    literal("howWeWorkWithLender"),
    literal("fca"),
    literal("commissions"),
    literal("ifThingsGoWrong"),
    literal("professionalIndemnityInsurance"),
    literal("dataProtection"),
    literal("defaultByBanks"),
    literal("storageAndDataRetention"),
    literal("amendmentToOurTermsAndEngagement"),
    literal("consumerContractsRegs2013"),
    literal("agreementThroughConduct")
]);
type TToeSectionTitle = TTypeOfCodec<typeof ToeSectionTitle>;

const getToeSection = (sectionTitle: TToeSectionTitle): JSX.Element => {
    switch(sectionTitle) {
        case "standardTermsOfEngagement":
            return standardTermsOfEngagement();
        case "yourInstructions":
            return yourInstructions();
        case "ourService":
            return ourService();
        case "whatOurServiceDoesNotInvolve":
            return whatOurServiceDoesNotInvolve();
        case "whatWeNeedFromYou":
            return whatWeNeedFromYou();
        case "communication":
            return communication();
        case "identification":
            return identification();
        case "ourFees":
            return ourFees();
        case "whenToSendMoney":
            return whenToSendMoney();
        case "sofAndSow":
            return sofAndSow();
        case "yourMoneyDeposit":
            return yourMoneyDeposit();
        case "abortiveTransactions":
            return abortiveTransactions();
        case "paymentOfFees":
            return paymentOfFees();
        case "interestOnFees":
            return interestOnFees();
        case "cybercrimeAndFraud":
            return cybercrimeAndFraud();
        case "sdltAndLtt":
            return sdltAndLtt();
        case "howWeWorkWithLender":
            return howWeWorkWithLender();
        case "fca":
            return fca();
        case "commissions":
            return commissions();
        case "ifThingsGoWrong":
            return ifThingsGoWrong();
        case "professionalIndemnityInsurance":
            return professionalIndemnityInsurance();
        case "dataProtection":
            return dataProtection();
        case "defaultByBanks":
            return defaultByBanks();
        case "storageAndDataRetention":
            return storageAndDataRetention();
        case "amendmentToOurTermsAndEngagement":
            return amendmentToOurTermsAndEngagement();
        case "consumerContractsRegs2013":
            return consumerContractsRegs2013();
        case "agreementThroughConduct":
            return agreementThroughConduct();
        default:
            return requireExhaustive(sectionTitle)
    }
};

const standardTermsOfEngagement = (): JSX.Element => (
    <SpacingColumn spacing={FrontSpacing.MEDIUM_1}>
        <FrontFormSectionTitle>
            Sail Legal Ltd:  Standard Terms of Engagement
        </FrontFormSectionTitle>

        <FrontParagraphSmall>
            In these conditions, the words 'we' and 'us' means Sail Legal Ltd, and 'you' means you as our client/s who have read and understood these Terms of Engagement and have signed our documentation to confirm you wish us to act on your behalf.
        </FrontParagraphSmall>

        <FrontParagraphSmall>
            Sail Legal Ltd is a Licensed Conveyancing Practice.  We are authorised and regulated by the Council of Licensed Conveyancers (CLC); our CLC ID number is 14065.  Our VAT number is 388314763.  We are required to comply with several professional rules, outcomes and conduct set by our regulator.  We are also required to provide you with our Terms of Engagement, setting out how we will act for you, what we can advise on, and any limitations to our retainer. In addition, we must agree at the outset of the case on how we can effectively work together and understand the expectations surrounding your transaction.
        </FrontParagraphSmall>

        <FrontParagraphSmall>
            These Terms of Engagement should be read in conjunction with our Letter of Engagement.  If you are happy to proceed, you will be asked to sign and return a copy of our 'Instructions to Proceed' document.
        </FrontParagraphSmall>
    </SpacingColumn>
);

const yourInstructions = (): JSX.Element => (
    <SpacingColumn spacing={FrontSpacing.MEDIUM_1}>
        <FrontFormSectionTitle>
            Your Instructions
        </FrontFormSectionTitle>

        <FrontParagraphSmall>
            We appreciate it can be a daunting experience buying, selling or remortgaging a property. However, to ensure we have a smooth process and manage expectations, we must agree on how to work effectively together to achieve the same goal.  We also need to ensure you know how we will progress your case and what we need from you.
        </FrontParagraphSmall>
    </SpacingColumn>
);

const ourService = (): JSX.Element => (
    <SpacingColumn spacing={FrontSpacing.MEDIUM_1}>
        <FrontFormSectionTitle>
            Our Service
        </FrontFormSectionTitle>
        
        <SpacingColumn spacing={FrontSpacing.MINIMUM}>
            <FrontBulletpoint>
                <FrontParagraphSmall>
                    We will always act honestly and professionally.
                </FrontParagraphSmall>
            </FrontBulletpoint>
            
            <FrontBulletpoint>
                <FrontParagraphSmall>
                    We will be open and transparent about our service and fees.
                </FrontParagraphSmall>
            </FrontBulletpoint>
            
            <FrontBulletpoint>
                <FrontParagraphSmall>
                    We will ensure the advice you receive is relevant to your transaction.
                </FrontParagraphSmall>
            </FrontBulletpoint>
            
            <FrontBulletpoint>
                <FrontParagraphSmall>
                    We will acknowledge your communication within 24 hours and provide you with a detailed response within three working days.
                </FrontParagraphSmall>
            </FrontBulletpoint>
            
            <FrontBulletpoint>
                <FrontParagraphSmall>
                We will provide you with a quotation at the beginning of your transaction based on the information available to us at the time. However, suppose your case becomes more complex or further legal work is required (including additional disbursements). In that case, we will advise you before any extra cost is incurred and seek your approval to proceed.
            </FrontParagraphSmall>
            </FrontBulletpoint>
            
            <FrontBulletpoint>
                <FrontParagraphSmall>
                We will always treat your matter with the highest level of client confidentiality.
                </FrontParagraphSmall>
            </FrontBulletpoint>
            
            <FrontBulletpoint>
                <FrontParagraphSmall>
                We will do our best to work to any agreed deadlines; you must advise us of this as soon as possible.  If we cannot meet your expectations, we will let you know to enable you to instruct elsewhere.
                </FrontParagraphSmall>
            </FrontBulletpoint>
        </SpacingColumn>
    </SpacingColumn>
);

const whatOurServiceDoesNotInvolve = (): JSX.Element => (
    <SpacingColumn spacing={FrontSpacing.MEDIUM_1}>
        <FrontFormSectionTitle>
            What our Service does not Involve
        </FrontFormSectionTitle>  

        <SpacingColumn spacing={FrontSpacing.MINIMUM}>
            <FrontBulletpoint>
                <FrontParagraphSmall>
                    We are not tax experts and will not advise you on any tax relief or specific calculations relating to SDLT/LTT.   
                </FrontParagraphSmall>
            </FrontBulletpoint>
                
            <FrontBulletpoint>
                <FrontParagraphSmall>
                    We are not experts in tax liabilities such as Capital Gains Tax and will not advise you concerning this.  We urge you to consult an accountant for all tax advice.
                </FrontParagraphSmall>
            </FrontBulletpoint>

            <FrontBulletpoint>
                <FrontParagraphSmall>
                    We cannot provide financial advice or advise you on whether your mortgage offer is suited to your needs.
                </FrontParagraphSmall>
            </FrontBulletpoint>

            <FrontBulletpoint>
                <FrontParagraphSmall>
                    We cannot provide structural advice on the property or your valuation report.
                </FrontParagraphSmall>
            </FrontBulletpoint>

            <FrontBulletpoint>
                <FrontParagraphSmall>
                    We will not make any payments to any third party who is not our client or not part of the transaction, even if these are your instructions.
                </FrontParagraphSmall>
            </FrontBulletpoint>

            <FrontBulletpoint>
                <FrontParagraphSmall>
                    If we act for joint clients, we will not transfer funds to a single account or accept instructions to change banking details.
                </FrontParagraphSmall>
            </FrontBulletpoint>

            <FrontBulletpoint>
                <FrontParagraphSmall>
                    We will not advise you on the merits and results of specific reports such as timber, damp, cladding, structural defects etc. Instead, you will be required to seek expert advice and provide us with instructions to proceed.
                </FrontParagraphSmall>
            </FrontBulletpoint>
        </SpacingColumn>
    </SpacingColumn>
);

const whatWeNeedFromYou = (): JSX.Element => (
    <SpacingColumn spacing={FrontSpacing.MEDIUM_1}>
        <FrontFormSectionTitle>
            What we need from you
        </FrontFormSectionTitle>
        <SpacingColumn spacing={FrontSpacing.MINIMUM}>
            <FrontBulletpoint>
                <FrontParagraphSmall>
                    Please complete our documentation setting out your personal details and details of the transaction as soon as possible.         
                </FrontParagraphSmall>
            </FrontBulletpoint>
                
            <FrontBulletpoint>
                <FrontParagraphSmall>
                    If your circumstances change or the transaction significantly changes, please advise us immediately, in writing.
                </FrontParagraphSmall>
            </FrontBulletpoint>

            <FrontBulletpoint>
                <FrontParagraphSmall>                         
                    We will require you to respond to requests for information as soon as possible; failure may result in your case being delayed.
                </FrontParagraphSmall>
            </FrontBulletpoint>

            <FrontBulletpoint>
                <FrontParagraphSmall>  
                    We will need you to comply with our electronic identification and source of funds/wealth requirements as soon as possible.
                </FrontParagraphSmall>
            </FrontBulletpoint>
        </SpacingColumn>
    </SpacingColumn>
);

const communication = (): JSX.Element => (
    <SpacingColumn spacing={FrontSpacing.MEDIUM_1}>
        <FrontFormSectionTitle>
            Communication
        </FrontFormSectionTitle>
            
        <FrontParagraphSmall>
            We will communicate via letter, email, SMS text or video call.  If you require a specific method of communication, please let us know.  We always insist on a face-to-face video call at the beginning of every transaction with you.  If this cannot be achieved, we will require a face-to-face meeting at our offices to verify your identity. Alternatively, you will be required to attend a local law firm with your identification.  Please note the local law firm may charge you a fee to undertake part of the identity check, which you will be responsible for; this does not form part of our fees.  For further information on this process, please see the below heading Identification.
        </FrontParagraphSmall> 
    </SpacingColumn>
);

const identification = (): JSX.Element => (
    <SpacingColumn spacing={FrontSpacing.MEDIUM_1}>
        <FrontFormSectionTitle>
            Identification
        </FrontFormSectionTitle>

        <FrontParagraphSmall>
            As a regulated law firm, we are under an obligation to prove your identity before we can progress with any matter.  The Money Laundering and Terrorist Financing (Amendment) Regulations 2019 sets out the requirements.  In summary, we are required to:
        </FrontParagraphSmall>

        <SpacingColumn spacing={FrontSpacing.MINIMUM}>
            <FrontBulletpoint>
                <FrontParagraphSmall> 
                    Verify your identity based on documents, data or information from a reliable and independent source
                </FrontParagraphSmall>
            </FrontBulletpoint>

            <FrontBulletpoint>
                <FrontParagraphSmall>
                    Identify any person classified by the regulations as a beneficial owner taking reasonable measures to understand such individuals' ownership and control structure.
                </FrontParagraphSmall>
            </FrontBulletpoint>

            <FrontBulletpoint>
                <FrontParagraphSmall>
                    Continue to monitor the transaction and keep the information up to date
                </FrontParagraphSmall>
            </FrontBulletpoint>

            <FrontBulletpoint>
                <FrontParagraphSmall> 
                    Report to the relevant authority if we know or suspect that an offence has or may be committed.  By instructing us to act, you give us irrevocable authority to disclose to the National Crime Agency if we consider it appropriate.  We will not be able to advise you if we have made a report.
                </FrontParagraphSmall>
            </FrontBulletpoint>
        </SpacingColumn>

        <FrontParagraphSmall>
            To comply with the statute and supporting regulations, we commission the services of Stripe to undertake electronic identification checks.  Stripe uses facial recognition biometrics and links to photographic evidence such as your passport or driving license.  If we are not satisfied with the results of the identification checks, we reserve the right to request further information. Our complete requirements and process is set out in our Letter of Engagement.
        </FrontParagraphSmall>
    </SpacingColumn>
);

const ourFees = (): JSX.Element => (
    <SpacingColumn spacing={FrontSpacing.MEDIUM_1}>
        <FrontFormSectionTitle>
            Our Fees
        </FrontFormSectionTitle>    

        <FrontParagraphSmall>
            Our Letter of Engagement sets out your transaction and provides a quote.  Our quote breaks down our fees, VAT and disbursements due to be incurred.  This quote is based on what we know at the time of instruction.  If further work is required, we will provide you with an updated quote setting out the additional fees/disbursements and why they are necessary.
        </FrontParagraphSmall>

        <FrontParagraphSmall>
            Disbursements are fees out of our control, such as search or land registration fees.  There may be unexpected costs which will unveil as the matter proceeds.  If this is the case, we will inform you and seek your consent to proceed.
        </FrontParagraphSmall>
    </SpacingColumn>
);

const whenToSendMoney = (): JSX.Element => (
    <SpacingColumn spacing={FrontSpacing.MEDIUM_1}>
        <FrontFormSectionTitle>
            When to send money
        </FrontFormSectionTitle>

        <FrontParagraphSmall>
            We may ask you to send money on account of disbursements at the beginning of your case.  This is to enable us to apply for searches on your behalf.
        </FrontParagraphSmall>

        <FrontParagraphSmall>
            We will also ask you to send any shortfall or deposit to us when the case has progressed, and we are near to exchange of contracts.  Please refer to the paragraph Cybercrime, which provides further information about payments.
        </FrontParagraphSmall>
    </SpacingColumn>
);

const sofAndSow = (): JSX.Element => (
    <SpacingColumn spacing={FrontSpacing.MEDIUM_1}>
        <FrontFormSectionTitle>
            Source of Funds & Source of Wealth
        </FrontFormSectionTitle>

        <FrontParagraphSmall>
            We must undertake additional checks on how funds have accumulated and where they have come from.  This involves obtaining evidence of your Source of Funds, i.e. showing funds in your bank account and also Source of Wealth, i.e. what is the background of the funds, such as an inheritance.
        </FrontParagraphSmall>

        <FrontParagraphSmall>
            We appreciate this process may feel a little invasive, but we cannot proceed without this evidence. We work with Armalytix, a Company that enables us to obtain information electronically. This is set out in our Letter of Engagement. We require this information as soon as possible.
        </FrontParagraphSmall>

        <FrontParagraphSmall>
            Please do not be alarmed by us asking you for this information; it is standard procedure in any law firm.  The sooner we can satisfy this requirement, the sooner we will be able to proceed.
        </FrontParagraphSmall>
    </SpacingColumn>
);

const yourMoneyDeposit = (): JSX.Element => (
    <SpacingColumn spacing={FrontSpacing.MEDIUM_1}>
        <FrontFormSectionTitle>
            Your Money, Deposit
        </FrontFormSectionTitle>

        <FrontParagraphSmall>
            We are not required to open any special deposit account or account to you for any interest that may accrue or ought to accrue on money held in our client account.
        </FrontParagraphSmall>
    </SpacingColumn>
);

const abortiveTransactions = (): JSX.Element => (
    <SpacingColumn spacing={FrontSpacing.MEDIUM_1}>
        <FrontFormSectionTitle>
            Abortive Transactions
        </FrontFormSectionTitle>

        <FrontParagraphSmall>
            If your matter does not proceed for any reason, we will send you an invoice for the work completed to date.
        </FrontParagraphSmall>
    </SpacingColumn>
);

const paymentOfFees = (): JSX.Element => (
    <SpacingColumn spacing={FrontSpacing.MEDIUM_1}>
        <FrontFormSectionTitle>
            Payment of our Fees
        </FrontFormSectionTitle>

        <FrontParagraphSmall>
            We will send you a Draft Completion Statement providing a complete financial breakdown and a Final Completion Statement before the completion of your case.  We will include our fees in both statements and require payment (cleared funds) before completion.    Please note we do not accept cash payments.  We will only accept a clear BACS or telegraphic transfer from your bank account.
        </FrontParagraphSmall>
    </SpacingColumn>
);

const interestOnFees = (): JSX.Element => (
    <SpacingColumn spacing={FrontSpacing.MEDIUM_1}>
        <FrontFormSectionTitle>
            Interest on Fees
        </FrontFormSectionTitle>

        <FrontParagraphSmall>
            If our invoice remains outstanding for 30 days or more, we will charge interest at 2% per month or part month from the date the statement was due to the date we receive outstanding/cleared funds.
        </FrontParagraphSmall>
    </SpacingColumn>
);

const cybercrimeAndFraud = (): JSX.Element => (
    <SpacingColumn spacing={FrontSpacing.MEDIUM_1}>
        <FrontFormSectionTitle>
            Cybercrime & Fraud
        </FrontFormSectionTitle>

        <FrontParagraphSmall>
            Please be aware we will never send our bank details to you via email.  We do not have any intention of changing our banking details.  We will provide our bank details to you once we are in a position to proceed with your transaction.  There will be specific points in the transaction where we will require funds:
        </FrontParagraphSmall>

        <SpacingColumn spacing={FrontSpacing.MINIMUM}>
            <FrontBulletpoint>
                <FrontParagraphSmall>
                    We may require money on account of searches at the beginning of the transaction.  Once you are happy with these Terms and our Letter of Engagement, we will provide details of how you can pay us this money.
                </FrontParagraphSmall>
            </FrontBulletpoint>

            <FrontBulletpoint>
                <FrontParagraphSmall>
                    If you are buying a property, we may require a deposit, or your deposit may be coming from your sale.  Once we are in a position to proceed, we will advise how this money is to be sent to us.  If the exchange and completion are planned within a short period, we will ask you to send us the entire balance of money due for completion before the exchange of contracts takes place.  Again, the balance must be cleared of funds.
                </FrontParagraphSmall>
            </FrontBulletpoint>

            <FrontBulletpoint>
                <FrontParagraphSmall>
                    We can only accept funds from the bank account of you, the client, and not from any third party (e.g. a giftor). The funds must come from the same bank account that was used to complete the Source of wealth checks. If you send funds from a different account, this may cause delays.
                </FrontParagraphSmall>
            </FrontBulletpoint>

            <FrontBulletpoint>
                <FrontParagraphSmall>
                    On a sale and remortgage, if there is a shortfall to redeem (pay off) your mortgage or other debts, we will send you a Completion Statement notifying you of the shortfall, calculated to the completion date.  At that time, we will advise how funds are to be received. 
                </FrontParagraphSmall>
            </FrontBulletpoint>
        </SpacingColumn>

        <FrontParagraphSmall>
            <WeightBold>
                <FrontTextColour colour="highlights-pertinent-coral-4">
                    If you receive any emails purporting to be from Sail Legal Ltd advising you to send money, please contact Jamie Cole, immediately on 0808 1967 007. Do not send any funds until we have had a video conference call with you to understand the circumstances.
                </FrontTextColour>
            </WeightBold>
        </FrontParagraphSmall>
    </SpacingColumn>
);

const sdltAndLtt = (): JSX.Element => (
    <SpacingColumn spacing={FrontSpacing.MEDIUM_1}>
        <FrontFormSectionTitle>
            Stamp Duty Land Tax (SDLT) and LTT
        </FrontFormSectionTitle>

        <FrontParagraphSmall>
            We will submit a tax return for SDLT/LTT as your 'tax agent' within 14 days of the completion of your purchase transaction.  As mentioned above, we are not tax experts; therefore, you will be required to obtain any specialist advice you may feel is necessary.
        </FrontParagraphSmall>

        <FrontParagraphSmall>
            We will make a charge in respect of the submission as set out in our quotation.  This fee does not include any cost for dealing with subsequent investigations or enquiries HMRC may have regarding the tax return.
        </FrontParagraphSmall>
    </SpacingColumn>
);

const howWeWorkWithLender = (): JSX.Element => (
    <SpacingColumn spacing={FrontSpacing.MEDIUM_1}>
        <FrontFormSectionTitle>
            How we work with a lender
        </FrontFormSectionTitle>

        <FrontParagraphSmall>
            If you are buying a property with a mortgage, we will also be instructed by your lender to act on their behalf in your transaction.  We will send you a Mortgage Report once we receive your Mortgage Offer.  We will also explain how to execute the Mortgage Deed and other supporting documentation.  It is your responsibility to ensure you meet the terms and obligations of your Mortgage Offer.  We will not provide any financial advice or be involved with affordability measures.
        </FrontParagraphSmall>

        <FrontParagraphSmall>
            We will be obliged to report to your lender any matters with the transaction that are onerous to them. Agreeing to these Terms of Engagement provides your irrevocable authority to proceed.
        </FrontParagraphSmall>
    </SpacingColumn>
);

const fca = (): JSX.Element => (
    <SpacingColumn spacing={FrontSpacing.MEDIUM_1}>
        <FrontFormSectionTitle>
            Financial Conduct Authority
        </FrontFormSectionTitle>

        <FrontParagraphSmall>
            Sail Legal is not authorised by the Financial Conduct Authority.  However, we are included in the register maintained by the Financial Conduct  Authority so that we can carry on Insurance Distribution Activities, which is broadly advising on the selling and administration of insurance contracts. This part of our business is regulated by the Council for Licensed Conveyancers, and arrangements for complaints or redress if something goes wrong are subject to the jurisdiction of the Legal Ombudsman (<FrontLink>www.legal ombudsman.org.uk</FrontLink>). The register can be accessed via the Financial Conduct Authority website at <FrontLink>register.fca.org.uk.</FrontLink>
        </FrontParagraphSmall>
    </SpacingColumn>
);

const commissions = (): JSX.Element => (
    <SpacingColumn spacing={FrontSpacing.MEDIUM_1}>
        <FrontFormSectionTitle>
            Commissions
        </FrontFormSectionTitle>

        <FrontParagraphSmall>
            If we pay or receive a commission or other benefit from any third party in respect of an introduction or other service, we will inform you of the commission amount.  By signing these Terms, you give us the authority to pay or retain the benefit/commission.
        </FrontParagraphSmall>
    </SpacingColumn>
);

const ifThingsGoWrong = (): JSX.Element => (
    <SpacingColumn spacing={FrontSpacing.MEDIUM_1}>
        <FrontFormSectionTitle>
            If things go wrong
        </FrontFormSectionTitle>

        <FrontParagraphSmall>
            We pride ourselves in our service, but you may feel we did not get it right. Nevertheless, we aim to listen to and resolve any difficulties or complaints as quickly as possible.  We have a Complaints Procedure setting out how we will manage any issues and report to you.  If you would like a copy of our Procedure, please do not hesitate to let us know.
        </FrontParagraphSmall>

        <FrontParagraphSmall>
            The Complaints Procedure describes how we will listen to you, investigate and report to you on the matter.  If you are unhappy with our findings after the report, you have a right to report the case to The Legal Ombudsman.
        </FrontParagraphSmall>

        <FrontParagraphSmall>
            Please be aware you must report the matter to The Legal Ombudsman within six years from the date of the act you are unsatisfied with or from three years you should reasonably know there were grounds to complain.  You may also report to the Legal Ombudsman within eight weeks of raising the matter with us if we have failed to respond to you.
        </FrontParagraphSmall>

        <SpacingColumn spacing={FrontSpacing.MINIMUM}>
            <FrontParagraphSmall>
                The address of The Legal Ombudsman is:
            </FrontParagraphSmall>

            <FrontParagraphSmall>
                <FrontLink>www.legalombudsman.org.uk</FrontLink>
            </FrontParagraphSmall>
            
            <FrontParagraphSmall>
                Tel:  0300 555 0333 
            </FrontParagraphSmall>

            <FrontParagraphSmall>
                PO Box 6167
            </FrontParagraphSmall>

            <FrontParagraphSmall>
                Slough
            </FrontParagraphSmall>
            
            <FrontParagraphSmall>
                SL1 0EH
            </FrontParagraphSmall>
        </SpacingColumn>
    </SpacingColumn>
);

const professionalIndemnityInsurance = (): JSX.Element => (
    <SpacingColumn spacing={FrontSpacing.MEDIUM_1}>
        <FrontFormSectionTitle>
            Professional Indemnity Insurance
        </FrontFormSectionTitle>

        <FrontParagraphSmall>
            We have Professional Indemnity Insurance which extends to £ 3 million for each claim.  Our insurance has been obtained under a facility granted to Howden UK Group Limited and provided by Arch Insurance (UK) Limited.  
        </FrontParagraphSmall>

        <FrontParagraphSmall>
            If you make a valid claim and we cannot meet the liability in full, you may be entitled to compensation from the Council of Licensed Conveyancers at www.conveyancer.org.uk.
        </FrontParagraphSmall>
    </SpacingColumn>
);

const dataProtection = (): JSX.Element => (
    <SpacingColumn spacing={FrontSpacing.MEDIUM_1}>
        <FrontFormSectionTitle>
            Data Protection Issues
        </FrontFormSectionTitle>

        <FrontParagraphSmall>
            We hold and process your personal data while providing conveyancing services to you.  To perform our contractual obligations to you, we will disclose your information and instructions to any mortgage lender for whom we also act in connection with your transaction and to other parties to whom it is necessary to disclose your information and instructions.  
        </FrontParagraphSmall>

        <FrontParagraphSmall>
            The lawful basis for disclosure is Contract, Legal Obligation and Legitimate Interest and is contained in our Privacy Notice.
        </FrontParagraphSmall>

        <FrontParagraphSmall>
            The data we will disclose will be relevant and proportionate to the task.
        </FrontParagraphSmall>

        <FrontParagraphSmall>
            As lawyers, we are under a professional and legal obligation to keep your affairs private and confidential. However, we are required, by current legislation, to make a report to the National Crime Agency (NCA) when we know or suspect that a transaction involves money laundering or terrorist financing. By completing the onboarding, you are providing us with your irrevocable authority to disclose to the above bodies if we consider it appropriate. You accept this duty overrides any duty of confidentiality that we have to you. Please note that we cannot inform you if we have to make such a report.
        </FrontParagraphSmall>
    </SpacingColumn>
);

const defaultByBanks = (): JSX.Element => (
    <SpacingColumn spacing={FrontSpacing.MEDIUM_1}>
        <FrontFormSectionTitle>
            Default by Banks
        </FrontFormSectionTitle>

        <FrontParagraphSmall>
            Sail Legal disclaims any liability for the loss of client money due to default by a bank.
        </FrontParagraphSmall>
    </SpacingColumn>
);

const storageAndDataRetention = (): JSX.Element => (
    <SpacingColumn spacing={FrontSpacing.MEDIUM_1}>
        <FrontFormSectionTitle>
            Storage of Papers & Retention of Data
        </FrontFormSectionTitle>                        

        <FrontParagraphSmall>
            We shall retain a copy of your file for a minimum of six years on a sale transaction and fifteen years on any other type of transaction from the date on which our file is archived.  At the end of this period, we will destroy the papers.  
        </FrontParagraphSmall>
    </SpacingColumn>
);

const amendmentToOurTermsAndEngagement = (): JSX.Element => (
    <SpacingColumn spacing={FrontSpacing.MEDIUM_1}>
        <FrontFormSectionTitle>
            Amendment to our Terms and Engagement
        </FrontFormSectionTitle>
            
        <FrontParagraphSmall>
            Please note these terms may not be altered unless agreed in writing by a Director of Sail Legal.  If you do not understand any part of this document, please do not hesitate to contact us; we will be happy to discuss any queries you may have. However, you are entering into a contract with us by instructing us to proceed.
        </FrontParagraphSmall>
    </SpacingColumn>
);

const consumerContractsRegs2013 = (): JSX.Element => (
    <SpacingColumn spacing={FrontSpacing.MEDIUM_1}>
        <FrontFormSectionTitle>
            Consumer Contracts (Information, Cancellation and Additional Charges) Regulations 2013
        </FrontFormSectionTitle>

        <FrontParagraphSmall>
            You have a right to cancel your instructions within 14 days without providing us with any reason.  If you wish to cancel, you must provide us with clear instructions in writing.  If you cancel, we will return any money paid to the account.
        </FrontParagraphSmall>

        <FrontParagraphSmall>
            Please agree to the terms by providing clear instructions if you would like us to work on your case before the 14-day cancellation period. Then, we will commence work on your behalf.  Should you subsequently cancel, we will return any funds we hold minus costs/disbursements incurred.
        </FrontParagraphSmall>
    </SpacingColumn>
);

const agreementThroughConduct = (): JSX.Element => (
    <FrontParagraphSmall>
        <WeightBold>
            By completing onboarding and paying money on account, you authorise Sail Legal to work on our case and waive the 14-day cancellation period. 
        </WeightBold>
    </FrontParagraphSmall>
);

export default FrontAgreeToTOEContainer;
