import * as t from "io-ts";
import { boolean } from "../../shared/src/validation/basic/boolean";
import { required } from "../../shared/src/validation/basic/required";
import { string } from "../../shared/src/validation/basic/string";
import { stringIsoUtcDate } from "../../shared/src/validation/basic/stringIsoUtcDate";
import { inOrder } from "../../shared/src/validation/compose/inOrder";
import { isEnum } from "../../shared/src/validation/create/isEnum";
import { object } from "../../shared/src/validation/create/object";
import { TValidationFunction } from "../../shared/src/validation/Function";
import * as CaseDocumentType1 from "./CaseDocumentType1";

export const codec = t.intersection([
    t.type({
        type: CaseDocumentType1.codec,
        due_date: t.string,
        from_external_source: t.boolean,
    }),
    t.partial({
        additional_name: t.string,
    }),
]);

export type T = t.TypeOf<typeof codec>;
export type TCaseDocument1 = T;

export const validator: TValidationFunction =
    object({
        type: inOrder(
            required,
            isEnum(CaseDocumentType1.values),
        ),
        due_date: inOrder(
            required,
            stringIsoUtcDate,
        ),
        from_external_source: inOrder(
            required,
            boolean,
        ),
        additional_name: string,
    });
