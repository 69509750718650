import React, { ReactFragment } from "react";
import { TFormStatus } from "../../../../shared/src/codecs/codec";
import { TButton, TButtonHighlight } from "../../models/TButton";
import * as TFormStatusLegacy from "../../models/TFormStatus";
import { TIcon } from "./../CRMIcon/CRMIcon";

type TStatus = TFormStatusLegacy.T | TFormStatus;

type TCRMFormButton = {
    formStatus: TStatus;
    ButtonElement: React.ComponentType<TButton>;
    label: string | ReactFragment;
    icon?: TIcon;
    fullWidth?: boolean;
    disabled?: boolean;
    title?: string;
    onClick: () => void;
    enabledOnUntouched?: boolean
};

const FormStatusToIconName = (status: TStatus, icon?: TIcon): TIcon | undefined => {
    switch (status) {
        case "submitting":
            return "pending";
        case "success":
            return "done-outline";
        case "failure":
            return "error-outline";
        case "validationError":
            return "warning-bubble";
        default:
            return icon;
    }
};

const FormStatusToDisabledState = (status: TStatus, enabledOnUntouched?: boolean): boolean =>
    (enabledOnUntouched !== true && status === "untouched") || status === "submitting" || status === "loading";

const FormStatusToButtonHighlight = (status: TStatus): TButtonHighlight => {
    switch (status) {
        case "success":
            return "positive-green";
        case "failure":
            return "instructional-brick";
        case "validationError":
            return "instructional-brick";
        default:
            return "none";
    }
};

export class CRMFormButton extends React.Component<React.PropsWithChildren<TCRMFormButton>> {
    public render (): JSX.Element {
        const ButtonElement = this.props.ButtonElement;

        return (
            <ButtonElement
                label={this.props.label}
                fullWidth={this.props.fullWidth}
                highlight={FormStatusToButtonHighlight(this.props.formStatus)}
                icon={FormStatusToIconName(this.props.formStatus, this.props.icon)}
                disabled={this.props.disabled !== undefined ? this.props.disabled : FormStatusToDisabledState(this.props.formStatus, this.props.enabledOnUntouched)}
                title={this.props.title}
                onClick={this.props.onClick}
            />
        );
    }
}
