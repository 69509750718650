import React from "react";
import { CRMTableRow } from "../../CRMTableRow/CRMTableRow";
import { TIcon } from "../../CRMIcon/CRMIcon";
import { SpacingRow } from "../../BuildingBlocks/SpacingRow";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { TAction } from "../../CRMTableRow/CRMTableRow";
import { TClientCompanyPartnershipForm } from "../../../../../domain/codecs/form/ClientCompanyPartnershipForm";
import { CRMParagraph } from "../../Simple/CRMParagraph/CRMParagraph";
import { WeightBold } from "../../WeightBold/WeightBold";
import { CRMColors } from "../../../models/CRMColors";
import { FontQuicksand } from "../../BuildingBlocks/FontQuicksand";
import { CRMFontSizes } from "../../../models/CRMFontSizes";
import { Text } from "../../BuildingBlocks/Text";
import { CRMPill } from "../../Simple/CRMPill/CRMPill";
import { WeightSemiBold } from "../../WeightSemiBold/WeightSemiBold";

export type TCompanyPartnerRowProps = {
    companyPartner: TClientCompanyPartnershipForm;
    onClick: () => void;
    onEdit: () => void;
    onDelete: () => void;
};

export const CRMCompanyPartnerRow = (props: React.PropsWithChildren<TCompanyPartnerRowProps>): JSX.Element => {
    const getCompanyPartnershipStatus = () => props.companyPartner.children.status;

    const onDownloadContract = (): void => {
        window.open(props.companyPartner.children.partnership_contract_form?.children.download_url, "_blank");
    };

    return (
        <CRMTableRow
            onClick={props.onClick}
            actions={[
                {
                    iconName: "pencil",
                    title: "Edit company",
                    onClick: props.onEdit,
                },
                ...(props.companyPartner.children.partnership_contract_form ? [{
                    iconName: "can-download",
                    title: "Download partnership contract",
                    onClick: onDownloadContract,
                }] as Array<TAction> : []),
                {
                    iconName: "delete" as TIcon,
                    title: "Delete",
                    onClick: props.onDelete,
                }
            ]}
            onHoverFace={
                <SpacingRow
                    spacing={CRMSpacing.MEDIUM}
                    justifyContent="start"
                >
                    <CRMParagraph lineHeight={1}>
                        <WeightBold>
                            {props.companyPartner.original.name || "--"}
                        </WeightBold>
                    </CRMParagraph>
                </SpacingRow>
            }
        >
            <SpacingRow
                spacing={CRMSpacing.MEDIUM}
                childSize={`1fr 1fr`}
                justifyContent="space-between"
            >
                <CRMParagraph lineHeight={1}>
                    <WeightBold>
                        {props.companyPartner.original.name || "--"}
                    </WeightBold>
                </CRMParagraph>
                
                {getCompanyPartnershipStatus() === "in_good_standing" &&
                    <FontQuicksand>
                        <Text
                            width="100%"
                            size={CRMFontSizes.SMALL}
                            color={CRMColors.NEUTRAL_0}
                            align="right"
                        >
                            In good standing
                        </Text>
                    </FontQuicksand>
                }
                
                {getCompanyPartnershipStatus() === "review_due" &&
                    <SpacingRow justifyContent="end">
                        <CRMPill 
                            colour="highlights-instructional-brick-10"
                            mode="single"
                        >
                            <WeightSemiBold>
                                Review due
                            </WeightSemiBold>
                        </CRMPill>
                    </SpacingRow>
                }

                {getCompanyPartnershipStatus() === "setup_not_complete" &&
                    <SpacingRow justifyContent="end">
                        <CRMPill 
                            colour="highlights-instructional-brick-10"
                            mode="single"
                        >
                            <WeightSemiBold>
                                Setup not complete
                            </WeightSemiBold>
                        </CRMPill>
                    </SpacingRow>
                }

                {getCompanyPartnershipStatus() === "id_checks_required" &&
                    <SpacingRow justifyContent="end">
                        <CRMPill 
                            colour="highlights-instructional-brick-10"
                            mode="single"
                        >
                            <WeightSemiBold>
                                ID checks required
                            </WeightSemiBold>
                        </CRMPill>
                    </SpacingRow>
                }

            </SpacingRow>
        </CRMTableRow>
    );
};
