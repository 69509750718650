import React from "react";
import { array } from "fp-ts";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { SpacingRow } from "../../BuildingBlocks/SpacingRow";
import { CRMSingleContentPlaceholder } from "../CRMSingleContentPlaceholder/CRMSingleContentPlaceholder";
import { TPercentageSize, TPixelSize } from "../../../models/StringLiterals";

type TSpacedContentPlaceholderProps = {
    count: number;
    height?: TPixelSize | TPercentageSize;
};

export const CRMSpacedContentPlaceholder = (props: TSpacedContentPlaceholderProps): JSX.Element => {

    return (
        <SpacingRow 
            spacing={CRMSpacing.MEDIUM}
            childSize="even-fill"
        >
            {array.range(1, props.count).map((index) => (
                <CRMSingleContentPlaceholder
                    key={index}
                    height={props.height}
                    width="100%"
                />
            ))}
        </SpacingRow>
    );
};
