import React from "react";
import { dateTime } from "../../../../shared/src/utilByDomainGroupExport";
import { DateTime } from "luxon";
import { TListingCRMReadOnlyData1, TListingFull1 } from "../../../../domain/codecs/Listing";
import { Font } from "../Font/Font";
import { WeightBold } from "../WeightBold/WeightBold";
import { CRMFontSizes } from "../../models/CRMFontSizes";
import { Uppercase } from "../Uppercase/Uppercase";
import { WeightRegular } from "../WeightRegular/WeightRegular";
import { CRMTimeline } from "../CRMTimeline/CRMTimeline";
import { SpacingColumn } from "../BuildingBlocks/SpacingColumn";
import { CRMSpacing } from "../../models/CRMSpacing";
import { FontSize } from "../BuildingBlocks/FontSize";
import { FontSignikaNegative } from "../BuildingBlocks/FontSignikaNegative";
import { pipe } from "fp-ts/lib/pipeable";
import { TTimeUnitName } from "../../../../shared/src/utilsByDomain/dateTime";

type TCRMListingCaseOverviewProps = {
    listingFull1: TListingFull1;
    listing4: TListingCRMReadOnlyData1;
};

export const CRMListingCaseOverview = (props: TCRMListingCaseOverviewProps): JSX.Element => {

    const timeFormatter = (text: TTimeUnitName) => {
        if (text === "moments") {
            return "...";
        }

        if (text === "minutes" || text === "minute") {
            return " min "
        }

        return text.charAt(0);
    }

    const conveyancingKickOffsComplete = (): boolean =>
        props.listingFull1.buyer_solicitor_kick_off_email_sent !== null
        && props.listingFull1.seller_solicitor_kick_off_email_sent !== null;

    const timeElapsedSinceKickOffs = (): string => pipe(
        dateTime.findMostRecentDate([
            props.listingFull1.buyer_solicitor_kick_off_email_sent,
            props.listingFull1.seller_solicitor_kick_off_email_sent
        ]),
        dateTime.getTimeElapsedInFormatedText(timeFormatter),
    );

    const conveyancingContractReceivedAndEverythingFromBuyer = (): boolean =>
        props.listingFull1.buyer_conveyancer_has_everything_required_to_start !== null
        && props.listingFull1.buyer_conveyancer_received_contracts !== null;

    const timeElapsedSinceConveyancingContractReceived = (): string => pipe(
        dateTime.findMostRecentDate([
            props.listingFull1.buyer_conveyancer_has_everything_required_to_start,
            props.listingFull1.buyer_conveyancer_received_contracts
        ]),
        dateTime.getTimeElapsedInFormatedText(timeFormatter),
    );

    const conveyancingEnquiriesRaised = (): boolean =>
        props.listingFull1.buyer_conveyancer_raised_enquiries !== null;

    const timeElapsedSinceConveyancingEnquiriesRaised = (): string =>
        props.listingFull1.buyer_conveyancer_raised_enquiries ?
            dateTime.getTimeElapsedInFormatedText(timeFormatter)(props.listingFull1.buyer_conveyancer_raised_enquiries)
            : ""
    ;

    const conveyancingExchangeDateSet = (): boolean =>
        props.listingFull1.estimated_exchange_date !== null;

    const timeElapsedSinceConveyancingExchangeDateSet = (): string =>
        props.listingFull1.estimated_exchange_date ?
            dateTime.getTimeElapsedInFormatedText(timeFormatter)(props.listingFull1.estimated_exchange_date)
            : ""
    ;

    const conveyancingCompleted = (): boolean =>
        props.listingFull1.has_completed !== null;

    const mortgageKickOffComplete = (): boolean =>
        props.listingFull1.mortgage_broker_kick_off_email_sent !== null;

    const timeElapsedSinceMortgageKickOffComplete = (): string =>
        props.listingFull1.mortgage_broker_kick_off_email_sent ?
            dateTime.getTimeElapsedInFormatedText(timeFormatter)(props.listingFull1.mortgage_broker_kick_off_email_sent)
            : ""
    ;

    const mortgageApplicationSubmitted = (): boolean =>
        props.listingFull1.mortgage_application_submitted !== null;

    const timeElapsedSinceMortgageApplicationSubmitted = (): string =>
        props.listingFull1.mortgage_application_submitted ?
            dateTime.getTimeElapsedInFormatedText(timeFormatter)(props.listingFull1.mortgage_application_submitted)
            : ""
    ;

    const mortgageValuationBooked = (): boolean =>
        props.listingFull1.mortgage_survey_datetime !== null ||
        mortgageOfferReceived()
    ;

    const timeElapsedSinceMortgageValuationBooked = (): string =>
        props.listingFull1.mortgage_survey_datetime ?
            dateTime.getTimeElapsedInFormatedText(timeFormatter)(props.listingFull1.mortgage_survey_datetime)
            : ""
    ;

    const mortgageOfferReceived = (): boolean =>
        props.listingFull1.mortgage_offer_received !== null;
    
    const getOfferAcceptedDateShort = (): string => dateTime.getTimeElapsedInFormatedText((unit) => ` ${unit} ago`)(props.listing4.accepted_offer_accepted_at as string);

    return (
        <div className="crm-listing-case-overview">
            <div className="crm-listing-case-overview__thumbnail-container"
                style={{
                    backgroundImage: `url(${props.listing4.image_url})` || undefined,
                }}
            />
            <SpacingColumn spacing={CRMSpacing.MEDIUM}>
                <FontSignikaNegative>
                    <FontSize size={CRMFontSizes.LARGE}>
                        <WeightBold>
                            <Uppercase>
                                Offer accepted {getOfferAcceptedDateShort()} ({DateTime.fromISO(props.listing4.accepted_offer_accepted_at as string).setZone("Europe/London").toFormat('d/L/yy')})
                            </Uppercase>
                        </WeightBold>
                    </FontSize>
                </FontSignikaNegative>

                {/* TIMELINE: CONVEYANCING */}
                <SpacingColumn spacing={CRMSpacing.MEDIUM}>
                    <Font family="signika-negative" size={CRMFontSizes.X_SMALL}>
                        <WeightRegular>
                            <Uppercase>
                                Conveyancing timeline
                            </Uppercase>
                        </WeightRegular>
                    </Font>
                    
                    <CRMTimeline
                        steps={[
                            {
                                iconName: "wave",
                                altText: "Kick off",
                                completed: conveyancingKickOffsComplete(),
                                tunnelText: timeElapsedSinceKickOffs(),
                            },
                            {
                                iconName: "document",
                                altText: "Contracts received, buyer solicitor can start",
                                completed: conveyancingContractReceivedAndEverythingFromBuyer(),
                                tunnelText: timeElapsedSinceConveyancingContractReceived()
                            },
                            {
                                iconName: "pencil",
                                altText: "Enquiries raised",
                                completed: conveyancingEnquiriesRaised(),
                                tunnelText: timeElapsedSinceConveyancingEnquiriesRaised(),
                            },
                            {
                                iconName: "calendar",
                                altText: "Exchange date set",
                                completed: conveyancingExchangeDateSet(),
                                tunnelText: timeElapsedSinceConveyancingExchangeDateSet(),
                            },
                            {
                                iconName: "done-all",
                                altText: "Completed",
                                completed: conveyancingCompleted(),
                            },
                        ]}
                    />
                </SpacingColumn>

                {/* TIMELINE: MORTGAGE */}
                {props.listing4.buyer_payment_method === "mortgage" &&
                    <SpacingColumn spacing={CRMSpacing.MEDIUM}>
                        <Font family="signika-negative" size={CRMFontSizes.X_SMALL}>
                            <WeightRegular>
                                <Uppercase>
                                    Mortgage timeline
                                </Uppercase>
                            </WeightRegular>
                        </Font>
                        <CRMTimeline
                            steps={[
                                {
                                    iconName: "wave",
                                    altText: "Kick off",
                                    completed: mortgageKickOffComplete(),
                                    tunnelText: timeElapsedSinceMortgageKickOffComplete(),
                                },
                                {
                                    iconName: "email-outbound",
                                    altText: "Application submitted",
                                    completed: mortgageApplicationSubmitted(),
                                    tunnelText: timeElapsedSinceMortgageApplicationSubmitted(),
                                },
                                {
                                    iconName: "calendar-tick",
                                    altText: "Valuation booked",
                                    completed: mortgageValuationBooked(),
                                    tunnelText: timeElapsedSinceMortgageValuationBooked(),
                                },
                                {
                                    iconName: "done-all",
                                    altText: "Mortgage offer received",
                                    completed: mortgageOfferReceived(),
                                },
                            ]}
                        />
                    </SpacingColumn>
                }
            </SpacingColumn>
        </div>
    );
};
