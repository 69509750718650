import * as rxjs from "rxjs";
import * as rxjsOperators from "rxjs/operators";
import { option } from "fp-ts/lib";
import * as FirstPartyFetchResponse from "../../../../../domain/models/FirstPartyFetchResponse";
import * as ListingSuccessResponse1 from "../../../../../domain/models/ListingSuccessResponse1";
import * as OfferSuccessResponse2 from "../../../../../domain/models/OfferSuccessResponse2";
import * as EnquirySuccessResponse3 from "../../../../../domain/models/EnquirySuccessResponse3";
import * as PartyResponse1 from "../../../../../domain/models/PartyResponse1";
import * as ListingEnquiriesViewingSuccessResponse3 from "../../../../../domain/models/ListingEnquiriesViewingSuccessResponse3";
import * as Party7 from "../../../../../domain/models/Party7";
import * as ListingEnquiriesOffer4 from "../../../../../domain/models/ListingEnquiriesOffer4";
import * as ListingEnquiriesOffer2 from "../../../../../domain/models/ListingEnquiriesOffer2";
import * as actionable from "../../../functions/actionable";
import * as TForm from "../../../models/TForm";
import {
    TParty2AndC7Form,
    TListing3ReadOnlyForm,
    TEnquiry5ReadOnlyFormList,
    TListingEnquiriesOffer2AndListingEnquiriesOfferForm2FormList,
    TListingEnquiriesViewing2ReadOnlyFormList,
} from "../../../models/TFormModels";
import { TUnpackFormActionPayload, TUpdateWherePayload } from "../../applyActions";
import {
    reloadOffersActionable,
    reloadOffersAndViewingsActionable,
    makeGetListingRequest,
    makeGetInterestedEnquiriesRequest,
    makeGetPartyRequest,
    makeGetPendingOrAcceptedOffers,
    makeGetNotPendingOrAcceptedOffers,
    makeGetUpcomingViewingsRequest,
    makeGetPastViewingsRequest,
} from "./crmListingPerformanceRequests";
import { TGetState } from "../../TGetState";
import { TSetState } from "../../TSetState";
import { pipe } from "fp-ts/lib/function";
import { TActionsDefinitionsList } from "../TAction";
import { reduceDataToStateUpdate } from "../../../functions/lens/reduceDataToStateUpdate";
import { set } from "../../../functions/lens/set";
import { setFormWhereSubscribed } from "../../../functions/lens/setFormWhereSubscribed";
import { setValueWhere, setValueWhereSubscribed } from "../../../functions/lens/setValueWhere";
import { pipeSubscribed } from "../../../functions/lens/pipe";
import { setFormWhere } from "../../../functions/lens/setFormWhere";
import { catchEither } from "../../../functions/lens/catchEither";
import { submitFormsWhereChanged } from "../../../functions/lens/submitFormsWhereChanged";
import { toActionable } from "../../../functions/lens/toActionable";
import { submitForm } from "../../../functions/lens/submitForm";
import { TChangeRouteAction } from "../../router/routerTypes";

type TListingPerformanceRequestPayloads = [
    string,
    FirstPartyFetchResponse.T<ListingSuccessResponse1.T>,
    FirstPartyFetchResponse.T<OfferSuccessResponse2.T>,
    FirstPartyFetchResponse.T<OfferSuccessResponse2.T>,
    FirstPartyFetchResponse.T<EnquirySuccessResponse3.T>,
    FirstPartyFetchResponse.T<PartyResponse1.T>,
    FirstPartyFetchResponse.T<ListingEnquiriesViewingSuccessResponse3.T>,
    FirstPartyFetchResponse.T<ListingEnquiriesViewingSuccessResponse3.T>
];

export const actions: TActionsDefinitionsList = [
    {
        type: "VIEW_CRM_LISTING_PERFORMANCE",
        run: (
            obs$: rxjs.Observable<TChangeRouteAction<"VIEW_CRM_LISTING_PERFORMANCE">>,
            getState: TGetState,
            setState: TSetState,
        ): void => {
            obs$.pipe(
                rxjsOperators.mergeMap((action) =>
                     pipe(
                        actionable.of<TListingPerformanceRequestPayloads>(
                            setState,
                            getState,
                            [
                                action.params.listingId,
                                FirstPartyFetchResponse.create2XX(ListingSuccessResponse1.newDefault()),
                                FirstPartyFetchResponse.create2XX(OfferSuccessResponse2.newDefault()),
                                FirstPartyFetchResponse.create2XX(OfferSuccessResponse2.newDefault()),
                                FirstPartyFetchResponse.create2XX(EnquirySuccessResponse3.newDefault()),
                                FirstPartyFetchResponse.create2XX(PartyResponse1.newDefault()),
                                FirstPartyFetchResponse.create2XX(ListingEnquiriesViewingSuccessResponse3.newDefault()),
                                FirstPartyFetchResponse.create2XX(ListingEnquiriesViewingSuccessResponse3.newDefault()),
                            ]
                        ),
                        actionable.fromPayloadIndexForkJoin<0, 1 | 2 | 3 | 4, TListingPerformanceRequestPayloads>(0, [
                            [1, makeGetListingRequest],
                            [2, makeGetPendingOrAcceptedOffers],
                            [3, makeGetNotPendingOrAcceptedOffers],
                            [4, makeGetInterestedEnquiriesRequest],
                        ]),
                        actionable.fromPayloadIndex<1, 5, TListingPerformanceRequestPayloads>(1, 5,(listingPerformance) =>
                            makeGetPartyRequest(listingPerformance.response.data.sellers_party_id)
                        ),
                        actionable.fromPayloadIndexForkJoin<0, 6 | 7, TListingPerformanceRequestPayloads>(0, [
                            [6, makeGetUpcomingViewingsRequest],
                            [7, makeGetPastViewingsRequest],
                        ]),
                        actionable.tapPayloads<TListingPerformanceRequestPayloads>(
                            reduceDataToStateUpdate<TListingPerformanceRequestPayloads>(setState)(
                                set<TListingPerformanceRequestPayloads>()(
                                    ["activeData", "crm", "listingPerformance", "listingForm"],
                                    (form, data) => TForm.dataToForm<TListing3ReadOnlyForm>({}, {})(data[1].response.data),
                                ),
                                set<TListingPerformanceRequestPayloads>()(
                                    ["activeData", "crm", "listingPerformance", "pendingOrAcceptedOffers"],
                                    (formList, data) => TForm.requestToFormList<TListingEnquiriesOffer2AndListingEnquiriesOfferForm2FormList["forms"][number]>(formList, data[2]),
                                ),
                                set<TListingPerformanceRequestPayloads>()(
                                    ["activeData", "crm", "listingPerformance", "withdrawnOrRejectedOffers"],
                                    (formList, data) => TForm.requestToFormList<TListingEnquiriesOffer2AndListingEnquiriesOfferForm2FormList["forms"][number]>(formList, data[3]),
                                ),
                                set<TListingPerformanceRequestPayloads>()(
                                    ["activeData", "crm", "listingPerformance", "interestedEnquiriesWithoutOffer"],
                                    (formList, data) => TForm.requestToFormList<TEnquiry5ReadOnlyFormList["forms"][number]>(formList, data[4]),
                                ),
                                set<TListingPerformanceRequestPayloads>()(
                                    ["activeData", "crm", "listingPerformance", "partyForm"],
                                    (form, data) => TForm.dataToForm<TParty2AndC7Form>(Party7.newDefault(), {})(data[5].response.data),
                                ),
                                set<TListingPerformanceRequestPayloads>()(
                                    ["activeData", "crm", "listingPerformance", "upcomingViewings"],
                                    (formList, data) => TForm.requestToFormList<TListingEnquiriesViewing2ReadOnlyFormList["forms"][number]>(formList, data[6]),
                                ),
                                set<TListingPerformanceRequestPayloads>()(
                                    ["activeData", "crm", "listingPerformance", "pastViewings"],
                                    (formList, data) => TForm.requestToFormList<TListingEnquiriesViewing2ReadOnlyFormList["forms"][number]>(formList, data[7]),
                                ),
                            ),
                        ),
                    )
                ),
            ).subscribe();
        },
    },
    // CURRENT OFFERS
    {
        type: "CRM_LISTING_PERFORMANCE_UPDATE_CURRENT_OFFER_FORM" as const,
        run: setFormWhereSubscribed<"CRM_LISTING_PERFORMANCE_UPDATE_CURRENT_OFFER_FORM", TListingEnquiriesOffer2AndListingEnquiriesOfferForm2FormList["forms"][number]>()(
            ["activeData", "crm", "listingPerformance", "pendingOrAcceptedOffers", "forms"],
            (form, payload) => form.view.id === payload.resourceId,
        ),
    },
    {
        type: "CRM_LISTING_PERFORMANCE_BEGIN_SUBMISSION" as const,
        run: setValueWhereSubscribed<"CRM_LISTING_PERFORMANCE_BEGIN_SUBMISSION", TUpdateWherePayload>()(
            ["activeData", "crm", "listingPerformance", "pendingOrAcceptedOffers", "forms"],
            (form, payload) => form.view.id === payload.resourceId,
            (form) => {
                form.status = "requiresSubmission";
                return form;
            }
        ),
    },
    {
        type: "CRM_LISTING_PERFORMANCE_RESET_CURRENT_OFFER_FORM" as const,
        run: setValueWhereSubscribed<"CRM_LISTING_PERFORMANCE_RESET_CURRENT_OFFER_FORM", TUpdateWherePayload>()(
            ["activeData", "crm", "listingPerformance", "pendingOrAcceptedOffers", "forms"],
            (form, payload) => form.view.id === payload.resourceId,
            (form) => {
                form.validationErrors = [];
                form.status = form.defaultStatus;
                form.edit.rejected_reason = "";
                return form;
            }
        ),
    },
    {
        type: "CRM_LISTING_PERFORMANCE_CHANGE_CURRENT_OFFER_STATUS" as const,
        run: pipeSubscribed<"CRM_LISTING_PERFORMANCE_CHANGE_CURRENT_OFFER_STATUS", TUnpackFormActionPayload<TListingEnquiriesOffer2AndListingEnquiriesOfferForm2FormList["forms"][number]>>(
            setFormWhere<"CRM_LISTING_PERFORMANCE_CHANGE_CURRENT_OFFER_STATUS", TListingEnquiriesOffer2AndListingEnquiriesOfferForm2FormList["forms"][number]>()(
                ["activeData", "crm", "listingPerformance", "pendingOrAcceptedOffers", "forms"],
                (value, payload) => value.view.id === payload.resourceId,
            ),
            catchEither<"CRM_LISTING_PERFORMANCE_CHANGE_CURRENT_OFFER_STATUS", TUpdateWherePayload>(
                (payload, request) => request.tag === "2XX",
                submitFormsWhereChanged<
                    "CRM_LISTING_PERFORMANCE_CHANGE_CURRENT_OFFER_STATUS",
                    unknown,
                    TListingEnquiriesOffer2AndListingEnquiriesOfferForm2FormList["forms"][number]["view"],
                    TListingEnquiriesOffer2AndListingEnquiriesOfferForm2FormList["forms"][number]["edit"],
                    TListingEnquiriesOffer2AndListingEnquiriesOfferForm2FormList["forms"][number]["ui"]
                >()(
                    ["activeData", "crm", "listingPerformance", "pendingOrAcceptedOffers", "forms"],
                    (form) => `/v1/offers/${form.view.id}`,
                    "PATCH",
                    ListingEnquiriesOffer4.validator,
                    ListingEnquiriesOffer2,
                ),
                setValueWhere<"CRM_LISTING_PERFORMANCE_CHANGE_CURRENT_OFFER_STATUS", TUpdateWherePayload>()(
                    ["activeData", "crm", "listingPerformance", "pendingOrAcceptedOffers", "forms"],
                    (value, payload) => value.view.id === payload.resourceId,
                    (form) => {
                        form.edit.status = "pending";
                        return form;
                    }
                ),
                toActionable<"CRM_LISTING_PERFORMANCE_CHANGE_CURRENT_OFFER_STATUS", unknown, string>()(
                    (p, getState) => getState().routes.params.listingId,
                    reloadOffersActionable
                )
            )
        ),
    },
    {
        type: "CRM_LISTING_PERFORMANCE_MARK_CURRENT_OFFER_AS_SHARED" as const,
        run: pipeSubscribed<"CRM_LISTING_PERFORMANCE_MARK_CURRENT_OFFER_AS_SHARED", TUnpackFormActionPayload<TListingEnquiriesOffer2AndListingEnquiriesOfferForm2FormList["forms"][number]>>(
            setFormWhere<"CRM_LISTING_PERFORMANCE_MARK_CURRENT_OFFER_AS_SHARED", TListingEnquiriesOffer2AndListingEnquiriesOfferForm2FormList["forms"][number]>()(
                ["activeData", "crm", "listingPerformance", "pendingOrAcceptedOffers", "forms"],
                (value, payload) => value.view.id === payload.resourceId,
            ),
            submitFormsWhereChanged<"CRM_LISTING_PERFORMANCE_MARK_CURRENT_OFFER_AS_SHARED", unknown, TListingEnquiriesOffer2AndListingEnquiriesOfferForm2FormList["forms"][number]["view"], TListingEnquiriesOffer2AndListingEnquiriesOfferForm2FormList["forms"][number]["edit"], TListingEnquiriesOffer2AndListingEnquiriesOfferForm2FormList["forms"][number]["ui"]>()(
                ["activeData", "crm", "listingPerformance", "pendingOrAcceptedOffers", "forms"],
                (form) => `/v1/offers/${form.view.id}`,
                "PATCH",
                ListingEnquiriesOffer4.validator,
                ListingEnquiriesOffer2,
            ),
            toActionable<"CRM_LISTING_PERFORMANCE_MARK_CURRENT_OFFER_AS_SHARED", unknown, string>()(
                (p, getState) => getState().routes.params.listingId,
                reloadOffersActionable
            )
        ),
    },
    // PAST OFFERS
    {
        type: "CRM_LISTING_PERFORMANCE_BEGIN_PAST_OFFER_SUBMISSION" as const,
        run: setValueWhereSubscribed<"CRM_LISTING_PERFORMANCE_BEGIN_PAST_OFFER_SUBMISSION", TUpdateWherePayload>()(
            ["activeData", "crm", "listingPerformance", "withdrawnOrRejectedOffers", "forms"],
            (form, payload) => form.view.id === payload.resourceId,
            (form) => {
                form.status = "requiresSubmission";
                return form;
            }
        ),
    },
    {
        type: "CRM_LISTING_PERFORMANCE_RESET_PAST_OFFER_FORM" as const,
        run: setValueWhereSubscribed<"CRM_LISTING_PERFORMANCE_RESET_PAST_OFFER_FORM", TUpdateWherePayload>()(
            ["activeData", "crm", "listingPerformance", "withdrawnOrRejectedOffers", "forms"],
            (form, payload) => form.view.id === payload.resourceId,
            (form) => {
                form.validationErrors = [];
                form.status = form.defaultStatus;
                form.edit.rejected_reason = "";
                return form;
            }
        ),
    },
    {
        type: "CRM_LISTING_PERFORMANCE_CHANGE_PAST_OFFER_STATUS" as const,
        run: pipeSubscribed<"CRM_LISTING_PERFORMANCE_CHANGE_PAST_OFFER_STATUS", TUnpackFormActionPayload<TListingEnquiriesOffer2AndListingEnquiriesOfferForm2FormList["forms"][number]>>(
            setFormWhere<"CRM_LISTING_PERFORMANCE_CHANGE_PAST_OFFER_STATUS", TListingEnquiriesOffer2AndListingEnquiriesOfferForm2FormList["forms"][number]>()(
                ["activeData", "crm", "listingPerformance", "withdrawnOrRejectedOffers", "forms"],
                (value, payload) => value.view.id === payload.resourceId,
            ),
            submitFormsWhereChanged<"CRM_LISTING_PERFORMANCE_CHANGE_PAST_OFFER_STATUS", unknown, TListingEnquiriesOffer2AndListingEnquiriesOfferForm2FormList["forms"][number]["view"], TListingEnquiriesOffer2AndListingEnquiriesOfferForm2FormList["forms"][number]["edit"], TListingEnquiriesOffer2AndListingEnquiriesOfferForm2FormList["forms"][number]["ui"]>()(
                ["activeData", "crm", "listingPerformance", "withdrawnOrRejectedOffers", "forms"],
                (form) => `/v1/offers/${form.view.id}`,
                "PATCH",
                ListingEnquiriesOffer4.validator,
                ListingEnquiriesOffer2,
            ),
            toActionable<"CRM_LISTING_PERFORMANCE_CHANGE_PAST_OFFER_STATUS", unknown, string>()(
                (p, getState) => getState().routes.params.listingId,
                reloadOffersActionable
            )
        ),
    },
    {
        type: "CRM_LISTING_PERFORMANCE_SEND_PERFORMANCE_UPDATE_CLICKED" as const,
        run: pipeSubscribed<"CRM_LISTING_PERFORMANCE_SEND_PERFORMANCE_UPDATE_CLICKED", {}>(
            (obs, getState, setState) => submitForm()(
                ["activeData", "crm", "listingPerformance", "sendPerformanceUpdateForm"],
                () => `/v1/listings/${getState().routes.params.listingId}/send-performance-update`,
                "POST",
                () => option.none,
            )(obs, getState, setState),
            toActionable<"CRM_LISTING_PERFORMANCE_SEND_PERFORMANCE_UPDATE_CLICKED", unknown, string>()(
                (p, getState) => getState().routes.params.listingId,
                reloadOffersAndViewingsActionable,
            )
        ),
    },
];
