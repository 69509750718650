import React from "react";
import { CRMIcon, TIconSizes } from "../CRMIcon/CRMIcon";
import { TButtonIcon } from "../../models/TButton";
import { CRMColors } from "../../models/CRMColors";
import { THexColor } from "../../models/StringLiterals";

type TCRMButtonFlatColorIconProps =  
    TButtonIcon &
    {
        backgroundColor: THexColor;
        iconColor?: THexColor;
        size?: TIconSizes;
        isRounded?: boolean;
        disabled?: boolean;
    }
;

export const CRMButtonFlatColourIcon = (props: TCRMButtonFlatColorIconProps): JSX.Element => {

    const getIconColor = (): THexColor =>
        props.disabled ? 
            CRMColors.NEUTRAL_2
            : props.iconColor || CRMColors.NEUTRAL_INK
    ;

    return (
        <div
            title={props.title}
            style={{
                backgroundColor: props.disabled ? CRMColors.NEUTRAL_12 : props.backgroundColor,
            }}
            className={`
                crm-button-flat-colour-icon
                crm-button-flat-colour-icon--size-${props.size || "medium"}
                crm-button-flat-colour-icon--${props.disabled ?  "disabled" : "enabled" }
                crm-button-flat-colour-icon--${props.isRounded ?  "rounded" : "angled" }
            `}
            onClick={!props.disabled ? props.onClick : undefined}
        >
            <CRMIcon
                iconName={props.icon}
                size={props.size || "medium"}
                colour="hex"
                hexColour={getIconColor()}
            />
        </div>
    );
}
