import { array } from "fp-ts";
import { pipe } from "fp-ts/lib/function";
import React from "react";
import { TCreateCaseQuoteUnfilledForm } from "../../../../../domain/codecs/form/CaseForm";
import { QuoteRateAttribute, QuoteRateAttribute_question, QuoteRateTransactionType, QuoteRateTransactionType_displayString, TQuoteRateAttribute, TQuoteRateTransactionType } from "../../../../../domain/codecs/QuoteRate";
import { Tenure1, Tenure1_Tenure2 } from "../../../../../domain/codecs/Tenure";
import { onChangeForm } from "../../../../../shared/src/codecs/types/form";
import { IOption } from "../../../hooks/UseDropdown";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { SpacingColumn } from "../../BuildingBlocks/SpacingColumn";
import { CRMDropdownComponent } from "../../CRMDropdownComponent/CRMDropdownComponent";
import { CRMInputCurrency } from "../../CRMInputCurrency/CRMInputCurrency";
import CRMInputGeneralComponent from "../../CRMInputs/CRMInputGeneralComponent/CRMInputGeneralComponent";
import CRMInputLabelAndErrorWrapComponent from "../../CRMInputLabelAndErrorWrapComponent/CRMInputLabelAndErrorWrapComponent";
import { CRMRadioListComponent } from "../../CRMInputs/CRMRadioListComponent/CRMRadioListComponent";
import { CRMTitleSubSection } from "../../CRMTitleSubSection/CRMTitleSubSection";

export const CRMQuoteCreatePopoutForm = (props: React.PropsWithChildren<{
    form: TCreateCaseQuoteUnfilledForm,
    displayError: boolean,
    onChange: (form: TCreateCaseQuoteUnfilledForm) => void,
}>): JSX.Element => {
    const isDisplayingTransactionTypeError = props.displayError && props.form.edited.transaction_type === null;

    const isDisplayingTransferValueError = props.displayError && props.form.edited["property_price_pence--transfer-monetary-value"] === null;

    const isDisplayingPropertyValueError = props.displayError && (props.form.edited["property_price_pence--property-value"] === null || props.form.edited["property_price_pence--property-value"] === 0);

    const isDisplayingNumberOfClientsError = props.displayError && props.form.edited.number_of_clients === null;

    const isDisplayingResidentialCommercialError = props.displayError && props.form.edited.property_residential_commercial === null;

    const isDisplayingClientsOwnershipError = props.displayError && props.form.edited.clients_ownership === null;

    const isDisplayingClientsPropertyCountError = props.displayError && props.form.edited.clients_property_count === null;

    const numberOfClientsAsString = props.form.edited.number_of_clients ? props.form.edited.number_of_clients.toString() : "";

    const referralFeePence: number =
        props.form.edited.transaction_type
            ? props.form.children.referral_fees[props.form.edited.transaction_type]
            : 0;

    const getIsDisplayingAttributeError = (attribute: TQuoteRateAttribute): boolean =>
        props.displayError && props.form.edited.attributes[attribute] === null;

    const onChange = onChangeForm(props.form, props.onChange);

    const onChangeNumberOfClients = (value: string) =>
        onChange("number_of_clients")(value ? Number(value) : null);

    const onChangeAttribute = (key: TQuoteRateAttribute) => (value: boolean) =>
        pipe(
            {
                ...props.form.edited.attributes,
                [key]: value,
            },
            (attributes) => ({
                ...props.form,
                edited: {
                    ...props.form.edited,
                    attributes,
                }
            }),
            props.onChange,
        );

    return <SpacingColumn spacing={CRMSpacing.LARGE}>
        <SpacingColumn spacing={CRMSpacing.MEDIUM}>
            {/* TRANSACTION TYPE */}
            <CRMInputLabelAndErrorWrapComponent
                label="Transaction type"
                displayError={isDisplayingTransactionTypeError}
            >
                <CRMDropdownComponent
                    value={props.form.edited.transaction_type || ""}
                    options={pipe(
                        QuoteRateTransactionType.values,
                        array.map((value) => ({
                            value,
                            label: QuoteRateTransactionType_displayString(value)
                        })),
                        array.prepend<IOption<TQuoteRateTransactionType | "">>({
                            value: "",
                            label: "Please select",
                        })
                    )}
                    onChange={(value) => onChange("transaction_type")(value || null)}
                    displayError={isDisplayingTransactionTypeError}
                />
            </CRMInputLabelAndErrorWrapComponent>

            {/* TENURE */}
            <CRMInputLabelAndErrorWrapComponent label="Tenure">
                <CRMDropdownComponent
                    value={props.form.edited.tenure || "unknown"}
                    options={pipe(
                        Tenure1.values,
                        array.map((value) => ({
                            value,
                            label: Tenure1_Tenure2(value)
                        }))
                    )}
                    onChange={onChange("tenure")}
                    displayError={false}
                />
            </CRMInputLabelAndErrorWrapComponent>

            {/* PROPERTY PRICE (TRANSFER MONETARY VALUE) */}
            <CRMInputLabelAndErrorWrapComponent
                label="How much is this property being transferred for?"
                displayError={isDisplayingTransferValueError}
            >
                <CRMInputCurrency
                    mode="pence"
                    value={props.form.edited["property_price_pence--transfer-monetary-value"]}
                    onChange={onChange("property_price_pence--transfer-monetary-value")}
                    displayError={isDisplayingTransferValueError}
                />
            </CRMInputLabelAndErrorWrapComponent>

            {/* PROPERTY PRICE (PROPERTY VALUE) */}
            <CRMInputLabelAndErrorWrapComponent
                label="What is the value of the property?"
                displayError={isDisplayingPropertyValueError}
            >
                <CRMInputCurrency
                    mode="pence"
                    value={props.form.edited["property_price_pence--property-value"]}
                    onChange={onChange("property_price_pence--property-value")}
                    displayError={isDisplayingPropertyValueError}
                />
            </CRMInputLabelAndErrorWrapComponent>

            {/* NUMBER OF CLIENTS */}
            <CRMInputLabelAndErrorWrapComponent
                label="Number of clients"
                guidance="This includes executors & all others for the purposes of ID checks & bankruptcy searches"
            >
                <CRMInputGeneralComponent
                    inputType="numericString"
                    value={numberOfClientsAsString}
                    onChange={onChangeNumberOfClients}
                    displayError={isDisplayingNumberOfClientsError}
                />
            </CRMInputLabelAndErrorWrapComponent>

            {/* RESIDENTIAL/COMMERCIAL */}
            <CRMInputLabelAndErrorWrapComponent
                label="Is the property residential/commercial?"
                displayError={isDisplayingResidentialCommercialError}
            >
                <CRMRadioListComponent
                    value={props.form.edited.property_residential_commercial}
                    options={[
                        {
                            value: "residential",
                            text: "Residential"
                        },
                        {
                            value: "commercial",
                            text: "Commercial/Mixed Use/Land",
                        }
                    ]}
                    displayError={isDisplayingResidentialCommercialError}
                    onChange={onChange("property_residential_commercial")}
                />
            </CRMInputLabelAndErrorWrapComponent>

            {/* CLIENT OWNERSHIP */}
            <CRMInputLabelAndErrorWrapComponent
                label="Have the clients ever owned a property before?"
                displayError={isDisplayingClientsOwnershipError}
            >
                <CRMRadioListComponent
                    value={props.form.edited.clients_ownership}
                    options={[
                        {
                            value: "first_time_buyers",
                            text: "No, they would be first time buyers"
                        },
                        {
                            value: "not_first_time_buyers",
                            text: "Yes",
                        }
                    ]}
                    displayError={isDisplayingClientsOwnershipError}
                    onChange={onChange("clients_ownership")}
                />
            </CRMInputLabelAndErrorWrapComponent>

            {/* CLIENT NUMBER OF PROPERTIES */}
            <CRMInputLabelAndErrorWrapComponent
                label="On completion of this transaction how many properties will the clients own?"
                displayError={isDisplayingClientsPropertyCountError}
            >
                <CRMRadioListComponent
                    value={props.form.edited.clients_property_count}
                    options={[
                        {
                            value: "one_property",
                            text: "One or less"
                        },
                        {
                            value: "more_than_one_property",
                            text: "More than 1 property",
                        }
                    ]}
                    displayError={isDisplayingClientsPropertyCountError}
                    onChange={onChange("clients_property_count")}
                />
            </CRMInputLabelAndErrorWrapComponent>

            {/* ATTRIBUTE QUESTIONS */}
            {pipe(
                QuoteRateAttribute.values,
                array.map((attribute) =>
                    <CRMInputLabelAndErrorWrapComponent
                        label={QuoteRateAttribute_question(attribute)}
                        displayError={getIsDisplayingAttributeError(attribute)}
                    >
                        <CRMRadioListComponent
                            value={props.form.edited.attributes[attribute]}
                            options={[
                                {
                                    value: false,
                                    text: "No",
                                },
                                {
                                    value: true,
                                    text: "Yes"
                                },
                            ]}
                            displayError={getIsDisplayingAttributeError(attribute)}
                            onChange={onChangeAttribute(attribute)}
                        />
                    </CRMInputLabelAndErrorWrapComponent>
                ),
            )}
        </SpacingColumn>

        {/* REFERRAL */}
        <SpacingColumn spacing={CRMSpacing.MEDIUM}>
            <CRMTitleSubSection>
                Referral
            </CRMTitleSubSection>

            {/* INTRODUCER NAME */}
            <CRMInputLabelAndErrorWrapComponent label="Referral fee paid to">
                <CRMInputGeneralComponent
                    inputType="text"
                    value={props.form.children.introducer_name || "No one"}
                    disabled={true}
                    displayError={false}
                    onChange={() => undefined}
                />
            </CRMInputLabelAndErrorWrapComponent>

            {/* REFERRAL AMOUNT */}
            <CRMInputLabelAndErrorWrapComponent label="Amount">
                <CRMInputCurrency
                    mode="pence"
                    value={referralFeePence}
                    disabled={true}
                    onChange={() => undefined}
                />
            </CRMInputLabelAndErrorWrapComponent>
        </SpacingColumn>
    </SpacingColumn>
};
