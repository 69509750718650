import React from "react";
import { CRMRichFilterDropdown } from "../../Simple/CRMRichFilterDropdown/CRMRichFilterDropdown";
import { TUseRichFilterDropdownBaseProps } from "../../../hooks/useRichFilterDropdown";
import { CRMRichFilterDropdownPillFace } from "../../Simple/CRMRichFilterDropdown/CRMRichFilterDropdownPillFace";
import { CRMDropdownGroupOption, CRMDropdownGroupOptionText, TDropdownGroupOptionProps } from "../CRMDropdownGroup/CRMDropdownGroup";
import { CRMFuzzySortSearchHighlight } from "../../Simple/CRMFuzzySortSearchHighlight/CRMFuzzySortSearchHighlight";
import { IFuzzySortOption } from "../../../hooks/useFuzzysort";
import { IOption } from "../../../hooks/UseDropdown";
import { isAFunction } from "../../../functions/functions";
import { identity } from "fp-ts";

export type TRichFilterDropdown_PillOption = {
    value: string;
    label: string;
    group: string;
}

type TPillRichFilterDropdownProps = 
    TUseRichFilterDropdownBaseProps<TRichFilterDropdown_PillOption> & 
    {
        placeholder?: string;
        disabled?: boolean;
        convertOptionValueToFaceText?: (value: string) => string;
    }
;

export const CRMRichFilterDropdown_Pill = (props: React.PropsWithChildren<TPillRichFilterDropdownProps>): JSX.Element => {

    const convertOptionValueToFaceText = 
        isAFunction<(value: string) => string>(props.convertOptionValueToFaceText)
            ? props.convertOptionValueToFaceText
            : identity.flatten
    ;

    const convertOptionToFuzzySortOption = (option: TRichFilterDropdown_PillOption): IFuzzySortOption<string, TRichFilterDropdown_PillOption> => ({
        label: option.label,
        value: option.value,
        meta: option
    });
    
    const convertOptionToDropdownOption = (option: TRichFilterDropdown_PillOption): IOption<string> => ({
        label: option.label,
        value: option.value
    });

    return (
        <CRMRichFilterDropdown
            selectionCap={1}
            selected={props.selected}
            onChange={props.onChange}
            options={props.options}
            convertOptionValueToFaceText={convertOptionValueToFaceText}
            convertOptionToFuzzySortOption={convertOptionToFuzzySortOption}
            convertOptionToDropdownOption={convertOptionToDropdownOption}
            FaceElement={CRMRichFilterDropdownPillFace}
            OptionElement={Option}
        />
    );
};

const Option = (props: TDropdownGroupOptionProps<TRichFilterDropdown_PillOption>) => {
    return (
        <CRMDropdownGroupOption
            onClick={() => props.onClick(props.option.obj.value)}
        >
            <CRMDropdownGroupOptionText>
                <CRMFuzzySortSearchHighlight
                    text={props.option.obj.meta?.label || ""}
                    searchText={props.searchText}
                />
            </CRMDropdownGroupOptionText>
        </CRMDropdownGroupOption>
    )
}
