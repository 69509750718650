import React, { useState } from "react";
import { CRMColors } from "../../../models/CRMColors";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { BackgroundColour } from "../../BuildingBlocks/BackgroundColour";
import { MinWidth } from "../../BuildingBlocks/MinWidth";
import { Padding } from "../../BuildingBlocks/Padding";
import { SpacingColumn } from "../../BuildingBlocks/SpacingColumn";
import { SpacingRow } from "../../BuildingBlocks/SpacingRow";
import { CRMButtonTertiaryColorable } from "../../CRMButtonTertiaryColorable/CRMButtonTertiaryColorable";
import { CRMIcon, TIcon } from "../../CRMIcon/CRMIcon";
import { CRMParagraph } from "../../Simple/CRMParagraph/CRMParagraph";
import { CRMCountdownBar } from "../../Simple/CRMCountdownBar/CRMCountdownBar";
import { WeightBold } from "../../WeightBold/WeightBold";

export type TConfirmationCountdownProps = {
    title: string;
    // the reason this is not just a "string" is because the button text must be 4 characters long
    // this prevents styleistic blunders
    primaryButtonMode: "next" | "done" | "none";
    iconName: TIcon;
    duration?: number;
    onUndo: () => void;
    onComplete: () => void;
};

export const CRMConfirmationCountdown = (props: React.PropsWithChildren<TConfirmationCountdownProps>): JSX.Element => {

    const [isCountdownPaused, setIsCountdownPaused] = useState(false);

    const onPauseCountdown = () => setIsCountdownPaused(true);
    
    const onUnPauseCountdown = () => setIsCountdownPaused(false);
    
    const getPrimaryButtonTextBasedOnMode = () => {
        if (props.primaryButtonMode === "next") {
            return "NEXT";
        }

        return "DONE";
    }

    return (
        <MinWidth width="100%">
            <BackgroundColour colour={CRMColors.NEUTRAL_PAPER}>
                <SpacingRow 
                    alignItems="flex-end"
                    childSize="1fr 85px"
                >
                    {/* MAIN */}
                    <SpacingColumn
                        height="100%"
                        alignContent="space-between"
                    >
                        {/* BODY */}
                        <Padding
                            type="custom" 
                            spacing={`${!!props.children ? "10px" : "20px"} ${CRMSpacing.MEDIUM} ${CRMSpacing.SMALL} ${CRMSpacing.MEDIUM}`}
                        >
                            <SpacingColumn spacing={CRMSpacing.SMALL}>

                                {/* TITLE */}
                                <SpacingRow spacing={CRMSpacing.SMALL}>
                                    <CRMIcon
                                        iconName={props.iconName}
                                        colour="neutral-ink"
                                    />
                                    <CRMParagraph lineHeight={1}>
                                        <WeightBold>
                                            {props.title}
                                        </WeightBold>
                                    </CRMParagraph>
                                </SpacingRow>

                                {/* BODY */}
                                {!!props.children &&
                                    props.children
                                }
                            </SpacingColumn>
                        </Padding>

                        {/* COUNTDOWN BAR */}
                        <CRMCountdownBar
                            duration={props.duration || 1500}
                            isPaused={isCountdownPaused}
                            onCountdownComplete={props.onComplete}
                        />
                    </SpacingColumn>

                    {/* BUTTONS */}
                    <SpacingColumn spacing="2px">
                        {/* UNDO BUTTON */}
                        <CRMButtonTertiaryColorable
                            label="UNDO"
                            fullWidth={true}
                            onHoverIn={onPauseCountdown}
                            onHoverOut={onUnPauseCountdown}
                            textColor={CRMColors.NEUTRAL_PAPER}
                            backgroundColor={CRMColors.NEUTRAL_2}
                            backgroundColorHover={CRMColors.NEUTRAL_0}
                            onClick={props.onUndo}
                        />
                        {/* IF ENABLED - PRIMARY BUTTON */}
                        {props.primaryButtonMode !== "none" &&
                            <CRMButtonTertiaryColorable
                                label={getPrimaryButtonTextBasedOnMode()}
                                fullWidth={true}
                                textColor={CRMColors.NEUTRAL_PAPER}
                                backgroundColor={CRMColors.PRIMARY_2}
                                backgroundColorHover={CRMColors.PRIMARY_4}
                                onClick={props.onComplete}
                            />
                        }
                    </SpacingColumn>
                </SpacingRow>


            </BackgroundColour>
        </MinWidth>
    );
};
