import React from "react";
import { FrontColors } from "../../../models/FrontColors";
import { FrontSpacing } from "../../../models/FrontSpacing";
import { Flex } from "../../BuildingBlocks/Flex";
import { MaxWidth } from "../../BuildingBlocks/MaxWidth";
import { SpacingColumn } from "../../BuildingBlocks/SpacingColumn";
import { FPLandingTitle } from "../Simple/FPLandingTitle/FPLandingTitle";
import { FPSimpleLogo } from "../Simple/FPSimpleLogo/FPSimpleLogo";
import { TPublicPropertyReportCreateForm, TPublicPropertyReportTitleSearchForm } from "../../../../../domain/codecs/form/PublicPropertyReportForm";
import { Background } from "../../BuildingBlocks/Background";
import { FontQuicksand } from "../../BuildingBlocks/FontQuicksand";
import { FontSize } from "../../BuildingBlocks/FontSize";
import { TextColor } from "../../BuildingBlocks/TextColor";
import { WeightSemiBold } from "../../WeightSemiBold/WeightSemiBold";
import { WeightBold } from "../../WeightBold/WeightBold";
import { FPButton } from "../Complex/FPButton/FPButton";
import { Width } from "../../BuildingBlocks/Width";
import { Tenure1_Tenure2 } from "../../../../../domain/codecs/Tenure";
import { Text } from "../../BuildingBlocks/Text";
import { FrontFontSizes } from "../../../models/FrontFontSizes";
import { SpacingRow } from "../../BuildingBlocks/SpacingRow";
import { FrontButtonSecondarySmall } from "../../Front/Simple/FrontButtonSecondarySmall/FrontButtonSecondarySmall";
import { pipe } from "fp-ts/lib/function";
import { array } from "fp-ts";

export const FPPropertyReportTitleSelect = (props: {
    form: TPublicPropertyReportTitleSearchForm,
    onSelect: (titleNumber: string) => void,
}): JSX.Element => {
    const options =
        props.form.children.response?._tag === "success"
            ? pipe(
                props.form.children.response.results,
                array.filter(({edited}) => edited.title_number !== "")
            )
            : [];

    const getLabel = (form: TPublicPropertyReportCreateForm): JSX.Element =>
        <SpacingColumn spacing={FrontSpacing.SMALL_1}>
            <WeightBold>{form.children.read_only.full_address}</WeightBold>
            <Text size={FrontFontSizes.MINOR}>
                <SpacingRow
                    justifyContent="start"
                    alignItems="center"
                    spacing={FrontSpacing.SMALL_2}
                >
                    {/* TENURE */}
                    {form.children.read_only.tenure !== "unknown" &&
                        <WeightSemiBold>
                            {Tenure1_Tenure2(form.children.read_only.tenure)}
                        </WeightSemiBold>
                    }

                    {/* TITLE NUMBER */}
                    <div>#{form.edited.title_number}</div>
                </SpacingRow>
            </Text>
        </SpacingColumn>;

    const onSelectNoneOfTheAbove = () =>
        props.onSelect("");

    return <SpacingColumn
        spacing={FrontSpacing.LARGE_1}
        justifyContent="center"
    >
        {/* LOGO */}
        <Flex justifyContent="center">
            <FPSimpleLogo width="95px" />
        </Flex>

        {/* HEADER */}
        <Flex justifyContent="center">
            <MaxWidth width="530px">
                <FPLandingTitle
                    size={60}
                    color={FrontColors.NEUTRAL_18}
                    align="center"
                >
                    Nearly There...
                </FPLandingTitle>
            </MaxWidth>
        </Flex>

        {/* WHITE AREA */}
        <Flex justifyContent="center">
            <Background
                color={FrontColors.NEUTRAL_18}
                borderRadius="10px"
                width="700px"
                padding={FrontSpacing.LARGE_1}
            >
                <SpacingColumn spacing={FrontSpacing.MEDIUM_2}>
                    {/* PLEASE SELECT THE CLOSEST MATCHING PROPERTY */}
                    <FontQuicksand>
                        <FontSize size="20px">
                            <TextColor color={FrontColors.NEUTRAL_2}>
                                <WeightSemiBold>
                                    Land Registry have these records for this property.&nbsp;
                                </WeightSemiBold>
                                <WeightBold>
                                    Please select the correct one.
                                </WeightBold>
                            </TextColor>
                        </FontSize>
                    </FontQuicksand>

                    {/* OPTIONS */}
                    <SpacingColumn spacing={FrontSpacing.SMALL_2}>
                        {options.map((option, i) =>
                            <Width
                                key={i}
                                width="fit-content"
                            >
                                <FPButton
                                    label={getLabel(option)}
                                    onClick={() => props.onSelect(option.edited.title_number)}
                                />
                            </Width>
                        )}

                        {/* NONE OF THE ABOVE */}
                        <FrontButtonSecondarySmall
                            label="None of the above"
                            iconName="chevron-right"
                            iconPosition="right"
                            onClick={onSelectNoneOfTheAbove}
                        />
                    </SpacingColumn>
                </SpacingColumn>
            </Background>
        </Flex>
    </SpacingColumn>;
};
