import React, { useEffect } from "react";
import { SpacingColumn } from "../../components/BuildingBlocks/SpacingColumn";
import { CRMSpacing } from "../../models/CRMSpacing";
import { SpacingRow } from "../../components/BuildingBlocks/SpacingRow";
import { CRMCardOutsideWarning } from "../../components/CRMCardOutsideWarning/CRMCardOutsideWarning";
import { CRMParagraphImportant } from "../../components/Simple/CRMParagraphImportant/CRMParagraphImportant";
import { Padding } from "../../components/BuildingBlocks/Padding";
import CRMCaseMainDetailsCardContainer from "./CRMCaseMainDetailsCardContainer";
import { CRMRichCaseNotesCard } from "./CRMRichCaseNotesCard";
import { CRMLink } from "../../components/CRMLink/CRMLink";
import CRMCaseDualRepCardContainer from "./CRMCaseDualRepCardContainer";
import { CRMBlockScrollCacheView } from "../../components/CRM/CRMBlock/CRMBlockViews/CRMBlockViews";
import { TBlockContainerStateProps } from "../../state/TBlockContainerStateProps";
import { CRMCardOutsideLabelled } from "../../components/CRMCardOutsideLabelled/CRMCardOutsideLabelled";
import { CRMParagraph } from "../../components/Simple/CRMParagraph/CRMParagraph";
import { WeightBold } from "../../components/WeightBold/WeightBold";
import { FontQuicksand } from "../../components/BuildingBlocks/FontQuicksand";
import { CRMFontSizes } from "../../models/CRMFontSizes";
import { Uppercase } from "../../components/Uppercase/Uppercase";
import { Text } from "../../components/BuildingBlocks/Text";

const CRMCaseDetailsOverviewContainer = (props: TBlockContainerStateProps): JSX.Element => {

    useEffect(
        () => {
            if (!props.isFirstLoad) {
                props.dispatch({
                    type: "CRM_CASE_OVERVIEW_VIEW",
                    payload: null,
                })
            }
        },
        []
    );

    const hasConflictsOfInterest = () =>
        props.state.forms.case_details_page.data.output.conflicts_of_interest
            .filter((coi) => coi.edited.status === "flagged")
            .length > 0
    ;

    const isDualRep = () => !! props.state.forms.case_details_page.data.output.details.children.dual_rep.linked_case_form;

    const introducerNotes = () => props.state.forms.case_details_page.data.output.details.children.introducer_notes;
    const introducerCaseRef = () => props.state.forms.case_details_page.data.output.details.children.introducer_external_reference_id;

    return (
        <CRMBlockScrollCacheView isShowing={true}>
            <SpacingColumn spacing={CRMSpacing.MEDIUM}>
                {props.state.forms.case_details_page.data.output.sail_homes_case_id && 
                    <CRMCardOutsideWarning
                        labelColour="primary-6"
                        iconColor="neutral-paper"
                        borderRounding="right"
                        icon="sailboat">
                            <Padding spacing={CRMSpacing.MEDIUM}>
                                <CRMParagraphImportant>
                                    Sail Homes Listing!
                                </CRMParagraphImportant>
                            </Padding>
                    </CRMCardOutsideWarning>
                }
                {props.state.forms.case_details_page.data.output.details.edited.using_esigning && 
                    <CRMCardOutsideWarning
                        labelColour="highlights-calming-pink-4"
                        borderRounding="right"
                        icon="sign">
                            <Padding spacing={CRMSpacing.MEDIUM}>
                                <CRMParagraphImportant>
                                    This case is using esigning.&nbsp;&nbsp;&nbsp;
                                    <CRMLink
                                        target="_blank"
                                        href={"https://www.yotisign.com/app/home"}
                                        linkStyle="normal"
                                    ><CRMParagraphImportant>
                                                Open Yoti
                                        </CRMParagraphImportant>
                                    </CRMLink>
                                </CRMParagraphImportant>
                            </Padding>
                    </CRMCardOutsideWarning>
                }

                <CRMRichCaseNotesCard {...props} />

                <SpacingRow 
                    spacing={CRMSpacing.MEDIUM} 
                    childSize="1fr 1fr"
                    alignItems="flex-start"
                >
                    <SpacingColumn spacing={CRMSpacing.MEDIUM}>

                        {/* CARD - MAIN DETAILS */}
                        <CRMCaseMainDetailsCardContainer {...props} />

                        {/* CARD - DUAL REP */}
                        {isDualRep() &&
                            <CRMCaseDualRepCardContainer {...props} />
                        }
                    </SpacingColumn>

                    <SpacingColumn spacing={CRMSpacing.MEDIUM}>

                        {/* CARD - CONFLICT OF INTEREST */}
                        {hasConflictsOfInterest() && 
                            <CRMCardOutsideWarning
                                labelColour="highlights-pertinent-yellow-4"
                                borderRounding="right"
                                icon="alert"
                            >
                                <Padding spacing={CRMSpacing.MEDIUM}>
                                    <CRMParagraphImportant>This case has unresolved conflicts of interests</CRMParagraphImportant>
                                </Padding>
                            </CRMCardOutsideWarning>
                        }
                    </SpacingColumn>
                </SpacingRow>

                {/* CARD - INTRODUCER DETAILS */}
                {(introducerCaseRef()  !== "" || introducerNotes()) &&
                    <SpacingRow 
                        spacing={CRMSpacing.MEDIUM} 
                        childSize="1fr"
                    >
                        <CRMCardOutsideLabelled
                            labelColor="highlights-calming-pink-4"
                            shadow={true}
                        >

                        <SpacingColumn spacing={CRMSpacing.MEDIUM}>
                            <FontQuicksand>
                                <Text size={CRMFontSizes.SMALL} lineHeight={1.2}>
                                    <Uppercase>
                                        <WeightBold>
                                            Introducer Details
                                        </WeightBold>
                                    </Uppercase>
                                </Text>
                            </FontQuicksand>
                            
                            <SpacingColumn spacing={CRMSpacing.TINY}>
                                
                                {/* INTRODUCER CASE REF */}
                                {introducerCaseRef() !== "" &&
                                    <CRMParagraph>
                                        <WeightBold>Their case ref: </WeightBold>
                                        {introducerCaseRef()}
                                    </CRMParagraph>
                                }

                                {/* INTRODUCER NOTES */}
                                {introducerNotes() !== "" &&
                                    <CRMParagraph>
                                        <WeightBold>
                                            Their notes on this case:
                                        </WeightBold>
                                        <CRMParagraph>
                                            {introducerNotes()}
                                        </CRMParagraph>
                                    </CRMParagraph>
                                }
                                </SpacingColumn>
                            </SpacingColumn>
                        </CRMCardOutsideLabelled>

                    </SpacingRow>
                }
            </SpacingColumn>
        </CRMBlockScrollCacheView>
    );
}

export default CRMCaseDetailsOverviewContainer;
