import React from "react";

export type breakpointOption = "600" | "1200";

interface IMediaPropSwitch <P extends Record<string, unknown>>{
    Component: React.FunctionComponent<React.PropsWithChildren<P>>,
    mobile: P,
    desktop: P,
    breakpoint: breakpointOption,
};

export const MediaPropSwitch = <P extends Record<string, unknown>>(props: React.PropsWithChildren<IMediaPropSwitch<P>>): JSX.Element => {

    return (
        <div className={`media-prop-switch`}>
            
            <div className={`media-prop-switch__mobile media-prop-switch__mobile--${props.breakpoint}`}>
                <props.Component {...props.mobile}>
                    {props.children}
                </props.Component>
            </div>

            <div className={`media-prop-switch__desktop media-prop-switch__desktop--${props.breakpoint}`}>
                <props.Component {...props.desktop}>
                    {props.children}
                </props.Component>
            </div>
        </div>
    );
};
