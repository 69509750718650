import React from "react";
import { TContainerStateProps } from "../../state/TContainerStateProps";
import { SpacingColumn } from "../../components/BuildingBlocks/SpacingColumn";
import { CRMTitleSection } from "../../components/CRMTitleSection/CRMTitleSection";
import { CRMSpacer } from "../../components/CRMSpacer/CRMSpacer";
import { CRMAutosaveIndicatorWrap } from "../../components/CRM/CRMAutosaveIndicatorWrap/CRMAutosaveIndicatorWrap";
import { CRMCodecEditForm } from "../../components/CRMCodecEditForm/CRMCodecEditForm";
import { CasesRelatedTransactionNeededBeforeCompletionType } from "../../../../domain/codecs/Cases";
import CRMInputLabelAndErrorWrapComponent from "../../components/CRMInputLabelAndErrorWrapComponent/CRMInputLabelAndErrorWrapComponent";
import { SpacingRow } from "../../components/BuildingBlocks/SpacingRow";
import { CRMDropdownComponent } from "../../components/CRMDropdownComponent/CRMDropdownComponent";
import { enumToIDropdownOptions } from "../../functions/enumToIDropdownOptions";
import { snakeCaseToCopyText } from "../../../../shared/src/util";
import { CaseRelatedTransactionForm1, TCaseRelatedTransactionCreateSearchResultsForm } from "../../../../domain/codecs/form/CaseForm";
import { pipe } from "fp-ts/lib/function";
import { CRMIcon } from "../../components/CRMIcon/CRMIcon";
import { CursorPointer } from "../../components/CursorPointer/CursorPointer";
import { CRMDropdownClickableForOverlayComponent } from "../../components/CRMDropdownClickableForOverlayComponent/CRMDropdownClickableForOverlayComponent";
import { CRMGenericEditModalWithText } from "../../components/CRMGenericEditModalWithText/CRMGenericEditModalWithText";
import { Country1ToCopyText } from "../../../../domain/codecs/overloads/Address";
import { CRMPadding } from "../../components/Simple/CRMPadding/CRMPadding";
import { CRMTitleForCard } from "../../components/CRMTitleForCard/CRMTitleForCard";
import { CRMButtonSecondary } from "../../components/CRMButtonSecondary/CRMButtonSecondary";
import { Address3 } from "../../../../domain/codecs/Address";
import { CasesRelatedTransactionConveyancerDetails, CasesRelatedTransactionSaleIsOnMarket } from "../../../../domain/codecs/CasesRelatedTransaction";
import { CRMRadioListComponent } from "../../components/CRMInputs/CRMRadioListComponent/CRMRadioListComponent";
import { FormStatus_highestPriority } from "../../../../shared/src/codecs/codec";
import { CRMNoticeBoxComponent } from "../../components/CRMNoticeBoxComponent/CRMNoticeBoxComponent";
import { CRMPlainFilterSearchSelect } from "../../components/Complex/CRMPlainFilterSearchSelect/CRMPlainFilterSearchSelect";
import { CRMTidyPopout } from "../../components/Simple/CRMTidyPopout/CRMTidyPopout";
import { Relative } from "../../components/BuildingBlocks/Relative";
import { CRMPopoutHeader } from "../../components/Simple/CRMPopoutHeader/CRMPopoutHeader";

const CRMLegalCaseDetailsRelatedTransactionContainer = (props: TContainerStateProps): JSX.Element => {
    
    const getCaseTransactionType = () => props.state.forms.case_details_page.data.output.details.children.transaction_type;

    const getLinkableCases = () => props.state.forms.case_details_page.data.output.details.children.related_transaction.create_link.children;

    const getLinkableCaseForm = (formCaseId: string): TCaseRelatedTransactionCreateSearchResultsForm | undefined =>
        getLinkableCases().find((linkableCase) => linkableCase.original.to_case_id === formCaseId);
    ;

    const onClickLinkCase = (formCaseId) => {
        const linkableCase = getLinkableCaseForm(formCaseId);
        if (linkableCase) {
            props.dispatch({
                type: "LEGAL_CASE_CREATE_RELATED_TRANSACTION",
                payload: linkableCase,
            })
        }
    }

    return <CRMAutosaveIndicatorWrap
        status={pipe(
            [
                props.state.forms.case_details_page.data.output.details.status,
                props.state.forms.case_details_page.data.output.details.children.related_transaction.related_data.status,
            ],
            FormStatus_highestPriority,
        )}
    >
        <CRMTitleSection>
            Related {
                getCaseTransactionType() === "purchase" ? "sale"
                : getCaseTransactionType() === "sale" ? "purchase"
                : "transaction"
            }
        </CRMTitleSection>
        <CRMSpacer size="large" />
        <SpacingColumn spacing="medium">
            {props.state.forms.case_details_page.data.output.details.edited.related_transaction_needed_before_completion === "yes" && props.state.forms.case_details_page.data.output.details.children.related_transaction.related_data.children.linked_to?.children.internal_or_external !== "internal" && <CRMNoticeBoxComponent>
                The related {
                    getCaseTransactionType() === "purchase" ? "sale"
                    : getCaseTransactionType() === "sale" ? "purchase"
                    : "transaction"
                } is being represented by an external conveyancer (not us). Details are below.
            </CRMNoticeBoxComponent>}

            <SpacingRow spacing="large" childSize="even-fill">
                {/* CASE LINK */}
                <CRMInputLabelAndErrorWrapComponent
                    label={`Link to an existing ${
                        getCaseTransactionType() === "purchase" ? "sale"
                        : getCaseTransactionType() === "sale" ? "purchase"
                        : "case"
                    }`}
                >
                    <SpacingRow
                        spacing="tiny"
                        alignItems="center"
                        childSize="1fr auto"
                    >
                        <CRMDropdownClickableForOverlayComponent
                            label={(
                                props.state.forms.case_details_page.data.output.details.children.related_transaction.related_data.children.linked_to?.children.internal_or_external !== "internal" ? 
                                "None"
                                    : (props.state.forms.case_details_page.data.output.details.children.related_transaction.related_data.children.linked_to?.children.address || "").length > 30 ? 
                                        `${props.state.forms.case_details_page.data.output.details.children.related_transaction.related_data.children.linked_to?.children.address.slice(0, 30)}...`
                                            : props.state.forms.case_details_page.data.output.details.children.related_transaction.related_data.children.linked_to?.children.address
                            ) || "None"}
                        >
                            <CRMTidyPopout
                                width="450px"
                                height="400px" 
                            >
                                <Relative>
                                    <CRMPopoutHeader>
                                        Link with case: 
                                    </CRMPopoutHeader>
                                    <CRMPlainFilterSearchSelect
                                        searchStickyTopPosition="51px"
                                        keepOriginalSearchResultsOrder={true}
                                        noMatchText=""
                                        placeholder="Search for a case by address"
                                        searchFormStatus={props.state.forms.case_details_page.data.output.details.children.related_transaction.create_link.status}
                                        onChangeSearchText={(search_term) => 
                                            props.dispatch({
                                                type: "LEGAL_CASE_UPDATE_CREATE_RELATED_TRANSACTION_SEARCH_TERMS",
                                                payload: {
                                                    ...props.state.forms.case_details_page.data.output.details.children.related_transaction.create_link,
                                                    edited: {
                                                        ...props.state.forms.case_details_page.data.output.details.children.related_transaction.create_link.edited,
                                                        search_term,
                                                    }
                                                },
                                            })
                                        }
                                        onClick={onClickLinkCase}
                                        options={
                                            props.state.forms.case_details_page.data.output.details.children.related_transaction.create_link.children.map((form) => ({
                                                label: `${form.children.addresses} ${form.children.client_names} (${snakeCaseToCopyText(form.children.status)})`,
                                                value: form.original.to_case_id,
                                            }))
                                        }
                                    />
                                </Relative>
                            </CRMTidyPopout>
                        </CRMDropdownClickableForOverlayComponent>

                        {
                            props.state.forms.case_details_page.data.output.details.children.related_transaction.related_data.children.linked_to && 
                            props.state.forms.case_details_page.data.output.details.children.related_transaction.related_data.children.linked_to.children.internal_or_external === "internal" && 
                                <CursorPointer onClick={() => props.dispatch({
                                    type: "LEGAL_CASE_DELETE_RELATED_TRANSACTION",
                                    payload: props.state.forms.case_details_page.data.output.details.children.related_transaction.related_data.children.linked_to || CaseRelatedTransactionForm1.newDefault(),
                                })}>
                                    <CRMIcon
                                        iconName="backspace"
                                        size="medium"
                                        colour="neutral-ink"
                                    />
                                </CursorPointer>
                        }

                    </SpacingRow>
                </CRMInputLabelAndErrorWrapComponent>

                {/* RELATED TRANSACTION NEEDED DROPDOWN */}
                <CRMInputLabelAndErrorWrapComponent label={`Client has/will have related ${
                    getCaseTransactionType() === "purchase" ? "sale"
                    : getCaseTransactionType() === "sale" ? "purchase"
                    : "transaction"
                }?`}>
                    <CRMDropdownComponent
                        options={enumToIDropdownOptions(
                            CasesRelatedTransactionNeededBeforeCompletionType.values,
                            snakeCaseToCopyText,
                        )}
                        value={props.state.forms.case_details_page.data.output.details.edited.related_transaction_needed_before_completion}
                        displayError={false}
                        onChange={(related_transaction_needed_before_completion) => props.dispatch({
                            type: "LEGAL_CASE_DETAILS_CHANGE",
                            payload: {
                                ...props.state.forms.case_details_page.data.output.details,
                                edited: {
                                    ...props.state.forms.case_details_page.data.output.details.edited,
                                    related_transaction_needed_before_completion,
                                }
                            }
                        })}
                    />
                </CRMInputLabelAndErrorWrapComponent>
            </SpacingRow>

            {props.state.forms.case_details_page.data.output.details.edited.related_transaction_needed_before_completion === "yes" && <>
                {/* SALE REFERRAL TO SAIL HOMES */}
                {props.state.forms.case_details_page.data.output.details.children.transaction_type === "purchase" &&
                <SpacingRow spacing="medium" alignItems="flex-start" childSize="1fr 1fr">
                    <CRMInputLabelAndErrorWrapComponent label="Property is on the market?">
                        <CRMDropdownComponent
                            value={props.state.forms.case_details_page.data.output.details.children.related_transaction.related_data.edited.sale_is_on_market}
                            options={enumToIDropdownOptions(
                                CasesRelatedTransactionSaleIsOnMarket.values,
                                snakeCaseToCopyText,
                            )}
                            onChange={(sale_is_on_market) => props.dispatch({
                                type: "LEGAL_CASE_UPDATE_RELATED_TRANSACTION_DATA",
                                payload: {
                                    ...props.state.forms.case_details_page.data.output.details.children.related_transaction.related_data,
                                    edited: {
                                        ...props.state.forms.case_details_page.data.output.details.children.related_transaction.related_data.edited,
                                        sale_is_on_market,
                                    }
                                }
                            })}
                            displayError={false}
                        />
                    </CRMInputLabelAndErrorWrapComponent>

                    {props.state.forms.case_details_page.data.output.details.children.related_transaction.related_data.edited.sale_is_on_market === "no" ?
                        <CRMInputLabelAndErrorWrapComponent label="Referred to Sail Homes?">
                            <CRMRadioListComponent<boolean, boolean>
                                value={props.state.forms.case_details_page.data.output.details.children.related_transaction.related_data.edited.sale_referred_to_sail_homes}
                                direction="row"
                                options={[
                                    {
                                        value: true,
                                        text: "Yes",
                                    },
                                    {
                                        value: false,
                                        text: "No",
                                    },
                                ]}
                                onChange={(sale_referred_to_sail_homes) => props.dispatch({
                                    type: "LEGAL_CASE_UPDATE_RELATED_TRANSACTION_DATA",
                                    payload: {
                                        ...props.state.forms.case_details_page.data.output.details.children.related_transaction.related_data,
                                        edited: {
                                            ...props.state.forms.case_details_page.data.output.details.children.related_transaction.related_data.edited,
                                            sale_referred_to_sail_homes,
                                        }
                                    }
                                })}
                            />
                        </CRMInputLabelAndErrorWrapComponent>
                        : <div></div>}
                </SpacingRow>}

                {props.state.forms.case_details_page.data.output.details.children.related_transaction.related_data.children.linked_to?.children.internal_or_external !== "internal" && <>
                    {/* PROPERTY ADDRESS */}
                    <CRMInputLabelAndErrorWrapComponent label="Property address">
                        <CRMGenericEditModalWithText
                            modalSize="large"
                            text={(() => {
                                const address = [
                                    [
                                        props.state.forms.case_details_page.data.output.details.children.related_transaction.related_data.edited.sub_building_name,
                                        props.state.forms.case_details_page.data.output.details.children.related_transaction.related_data.edited.sub_building_number,
                                        props.state.forms.case_details_page.data.output.details.children.related_transaction.related_data.edited.building_name,
                                        props.state.forms.case_details_page.data.output.details.children.related_transaction.related_data.edited.building_number,
                                        props.state.forms.case_details_page.data.output.details.children.related_transaction.related_data.edited.street_name
                                    ].filter((s) => s !== "").join(" "),
                                    props.state.forms.case_details_page.data.output.details.children.related_transaction.related_data.edited.locality,
                                    props.state.forms.case_details_page.data.output.details.children.related_transaction.related_data.edited.district,
                                    props.state.forms.case_details_page.data.output.details.children.related_transaction.related_data.edited.city_town,
                                    props.state.forms.case_details_page.data.output.details.children.related_transaction.related_data.edited.postcode,
                                    props.state.forms.case_details_page.data.output.details.children.related_transaction.related_data.edited.county,
                                    props.state.forms.case_details_page.data.output.details.children.related_transaction.related_data.edited.country === "unknown" ?
                                    "" : Country1ToCopyText(props.state.forms.case_details_page.data.output.details.children.related_transaction.related_data.edited.country)
                                ].filter((s) => s !== "" && s !== null).join(", ")

                                return address === "" ? "No Address" : address;
                            })()}
                            body={
                                (setIsOpen) => (
                                    <CRMPadding size="large">
                                        <CRMAutosaveIndicatorWrap
                                            status={props.state.forms.case_details_page.data.output.details.children.related_transaction.related_data.status}
                                        >
                                            <CRMTitleForCard>Property address</CRMTitleForCard>
                                            <CRMSpacer size="large" />

                                            <CRMCodecEditForm
                                                codec={Address3}
                                                model={props.state.forms.case_details_page.data.output.details.children.related_transaction.related_data.edited}
                                                validationErrors={props.state.forms.case_details_page.data.output.details.children.related_transaction.related_data.validationErrors}
                                                onChange={(edited) => props.dispatch({
                                                    type: "LEGAL_CASE_UPDATE_RELATED_TRANSACTION_DATA",
                                                    payload: {
                                                        ...props.state.forms.case_details_page.data.output.details.children.related_transaction.related_data,
                                                        edited: {
                                                            ...props.state.forms.case_details_page.data.output.details.children.related_transaction.related_data.edited,
                                                            ...edited,
                                                        }
                                                    }
                                                })}
                                                columns={2}
                                            />
                                            <CRMSpacer size="large" />

                                            {/* BUTTONS */}
                                            <SpacingRow justifyContent="end">
                                                <CRMButtonSecondary
                                                    label="Close"
                                                    onClick={() => setIsOpen(false)}
                                                />
                                            </SpacingRow>
                                        </CRMAutosaveIndicatorWrap>
                                    </CRMPadding>
                                )
                            }
                        />
                    </CRMInputLabelAndErrorWrapComponent>

                    <CRMCodecEditForm
                        codec={CasesRelatedTransactionConveyancerDetails}
                        model={props.state.forms.case_details_page.data.output.details.children.related_transaction.related_data.edited}
                        validationErrors={props.state.forms.case_details_page.data.output.details.children.related_transaction.related_data.validationErrors}
                        onChange={(edited) => props.dispatch({
                            type: "LEGAL_CASE_UPDATE_RELATED_TRANSACTION_DATA",
                            payload: {
                                ...props.state.forms.case_details_page.data.output.details.children.related_transaction.related_data,
                                edited: {
                                    ...props.state.forms.case_details_page.data.output.details.children.related_transaction.related_data.edited,
                                    ...edited,
                                }
                            }
                        })}
                        columns={2}
                    />
                </>}
            </>}
        </SpacingColumn>
    </CRMAutosaveIndicatorWrap>;
};

export default CRMLegalCaseDetailsRelatedTransactionContainer;