import { array } from "fp-ts";
import { pipe } from "fp-ts/lib/function";
import React, { useState } from "react";
import { TCaseDualRepOurSideConsent } from "../../../../domain/codecs/form/CaseForm";
import { CRMSpacing } from "../../models/CRMSpacing";
import { SpacingColumn } from "../BuildingBlocks/SpacingColumn";
import { CRMParagraphBulletPoints } from "../CRMBulletList/CRMParagraphBulletPoints";
import { CRMCardOutsidePopupBasic } from "../CRMCardOutsidePopupBasic/CRMCardOutsidePopupBasic";
import { CRMHorizontalSeperatorLine } from "../CRMHorizontalSeperatorLine/CRMHorizontalSeperatorLine";
import { CRMNoticeBoxComponent } from "../CRMNoticeBoxComponent/CRMNoticeBoxComponent";
import { CRMParagraph } from "../Simple/CRMParagraph/CRMParagraph";
import { CRMTitleSubSection } from "../CRMTitleSubSection/CRMTitleSubSection";
import { WeightBold } from "../WeightBold/WeightBold";
import { WeightMedium } from "../WeightMedium/WeightMedium";

export const CRMCaseDualRepBlock = (props: React.PropsWithChildren<{
    at_least_one_our_side_clients_consent: boolean,
    at_least_one_other_side_clients_consent: boolean,
    our_side_client_consents: Array<TCaseDualRepOurSideConsent>,
}>): JSX.Element => {
    const [isOpen, setIsOpen] = useState<boolean>(true);

    const ourClientNamesWhoHaventConsented =
        pipe(
            props.our_side_client_consents,
            array.filter(({has_consented}) => !has_consented),
            array.map(({name}) => name),
        );

    return (
        <CRMCardOutsidePopupBasic
            isOpen={isOpen}
            context="warning"
            size="medium"
            title="Don't progress this case yet!"
            closeText="I understand, continue."
            onClose={() => setIsOpen(false)}
        >
            <SpacingColumn spacing={CRMSpacing.LARGE}>
                <CRMNoticeBoxComponent>
                    This is a Dual Rep case and needs someone from both sides to consent to the Dual Rep terms before it can be progressed.
                </CRMNoticeBoxComponent>

                {/* GUIDANCE - CONSENT FROM OUR CLIENTS */}
                {!props.at_least_one_our_side_clients_consent &&
                    <SpacingColumn spacing={CRMSpacing.MEDIUM}>
                        <CRMTitleSubSection>
                            This case's users need to consent.
                        </CRMTitleSubSection>
                        <CRMParagraph>
                            Some of the users on this case haven't consented yet. One of these users need to consent to the terms in Onboarding before the case can be progressed:
                        </CRMParagraph>

                        {/* USERS ON OUR SIDE WHO HAVEN'T CONSENTED */}
                        <CRMParagraphBulletPoints
                            bullets={ourClientNamesWhoHaventConsented}
                        />
                    </SpacingColumn>
                }

                {/* GUIDANCE - CONSENT FROM OTHER SIDE CLIENTS */}
                {!props.at_least_one_other_side_clients_consent &&
                    <SpacingColumn spacing={CRMSpacing.MEDIUM}>
                        <CRMTitleSubSection>
                            The other side hasn't consented yet.
                        </CRMTitleSubSection>
                        <CRMParagraph>
                            The other side will need to have provided at least one consent before this case can be progressed, once they have this notice will go away.
                        </CRMParagraph>
                    </SpacingColumn>
                }

                <CRMHorizontalSeperatorLine />

                {/* OVERRIDE EXPLANATION */}
                <CRMParagraph>
                    <WeightMedium>
                        You can close this notice if you wish to view the case or perform administrative tasks like the initial setup&nbsp;
                    </WeightMedium>
                    <WeightBold>
                        but you must not progress the case.
                    </WeightBold>
                </CRMParagraph>
            </SpacingColumn>
        </CRMCardOutsidePopupBasic>
    );
};
