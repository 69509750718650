import React from "react";
import { TPercentageSize, TPixelSize } from "../../models/StringLiterals";

type TMinWidthProps = {
    width: TPixelSize | TPercentageSize | "min-content";
};

export const MinWidth = (props: React.PropsWithChildren<TMinWidthProps>): JSX.Element => {

    return (
        <div style={{ minWidth: props.width }}>
            {props.children}
        </div>
    );
};
