import React from "react";
import { AllLegalCaseJobs, AllListingEnquiryJobs, AllListingJobs, TJob1, TJobListingCarSale, TJobListingService } from "../../../../../domain/codecs/Job";
import { requireExhaustive } from "../../../../../shared/src/util";
import { TJobCaseAdHoc, TJobLegalCaseBasic, TJobListingAdHoc, TJobListingBasic, TJobListingEnquiryAdHoc, TJobListingEnquiryBasic, TJobListingEnquiryOffersShareFeedbackWithBuyer, TJobListingPublicNotice, TJobLegalAdminJob } from "../../../../../domain/codecs/Job";
import { getAdHocGoToListingRouteAction, getGoToCaseRouteAction, getGoToEnquiryRouteAction, getGoToLegalIntroducersRouteAction, getGoToListingOffersRouteAction, getGoToListingRouteAction, getGoToProbateCallbacksRouteAction } from "./CRMJobsCardLinkGenerators";
import { Separated } from "fp-ts/lib/Separated";
import { array } from "fp-ts";
import { pipe } from "fp-ts/lib/function";
import { TIcon } from "../../CRMIcon/CRMIcon";
import { TChangeRouteAction, TRouteActionType } from "../../../state/router/routerTypes";

export type TGrouping1 = { 
    groupBy: "listing", 
    listingId: string
};

export type TGrouping2 = {
    groupBy: "listing_enquiry", 
    listingEnquiryId: string
};

export type TGrouping3 = {
    groupBy: "case", 
    caseId: string
};

export type TGroupSetting =
    TGrouping1
    | TGrouping2
    | TGrouping3
    | null
;

export const getJobsInGroupPartition = (jobs: Array<TJob1>) =>
    (grouping: TGroupSetting): Separated<Array<TJob1>, Array<TJob1>> => {
        if (grouping === null) {
            return { left: [], right: [] };
        }
        return pipe(
            jobs,
            array.partition((job) =>
                grouping.groupBy === "listing" && "listing_id" in job && job.listing_id === grouping.listingId && AllListingJobs.decode(job)._tag === "Right" ? true
                : grouping.groupBy === "listing_enquiry" && "listings_enquiry_id" in job && job.listings_enquiry_id === grouping.listingEnquiryId && AllListingEnquiryJobs.decode(job)._tag === "Right" ? true
                : grouping.groupBy === "case" && "case_id" in job && job.case_id === grouping.caseId && AllLegalCaseJobs.decode(job)._tag === "Right" ? true
                : false,
            )
        )
    }
;


export type TJobDefinition = {
    title?: string;
    job?: TJobListingBasic 
        | TJobListingPublicNotice
        | TJobListingAdHoc
        | TJobListingEnquiryAdHoc
        | TJobListingEnquiryBasic
        | TJobLegalCaseBasic
        | TJobCaseAdHoc
        | TJobLegalAdminJob
        | TJobListingEnquiryOffersShareFeedbackWithBuyer
        | TJobListingBasic
        | TJobListingService
        | TJobListingCarSale
    routeAction: TChangeRouteAction<TRouteActionType>;
}

// [REFACTOR] Needs to just return definitions
export const getJobDefinition = (job: TJob1): TJobDefinition => {

    switch (job.type) {
        // START CRMJobCardViewListing
        case "sh_progression_buyer_solicitor_kick_off":
            return {
                    title: "Buyer solicitor kick off",
                    job: job,
                    routeAction: getGoToListingRouteAction({ 
                        visibleSections: "progression_buyer_solicitor_kick_off",
                        job, 
                    }),
                }
        case "sh_progression_seller_solicitor_kick_off":
            return {
                    title: "Seller solicitor kick off",
                    job: job,
                    routeAction: getGoToListingRouteAction({
                        visibleSections: "progression_seller_solicitor_kick_off",
                        job
                    })
                }
        case "sh_progression_mortgage_broker_kick_off":
            return {
                    title: "Mortgage broker kick off",
                    job: job,
                    routeAction: getGoToListingRouteAction({
                        visibleSections: "progression_mortgage_broker_kick_off",
                        job
                    })
                }
        case "sh_progression_collect_buyer_own_survey_details":
            return {
                    title: "Collect info on buyer's own survey",
                    job: job,
                    routeAction: getGoToListingRouteAction({
                        visibleSections: "progression_buyer_own_survey",
                        job
                    })
                }
        case "sh_progression_check_buyer_sol_has_everything_needed_to_start":
            return {
                    title: "Find out if buyer solicitor has everything they need to start the case",
                    job: job,
                    routeAction: getGoToListingRouteAction({
                        visibleSections: "progression_buyer_conveyancer_has_everything_required_to_start",
                        job
                    })
                }
        case "sh_progression_check_buyer_sol_has_received_contracts":
            return {
                    title: "Find out if buyer solicitor has received the contracts from the seller solicitor",
                    job: job,
                    routeAction: getGoToListingRouteAction({
                        visibleSections: "progression_buyer_conveyancer_received_contracts",
                        job
                    })
                }
        case "sh_progression_check_buyer_sol_has_raised_enquiries":
            return {
                    title: "Find out if buyer solicitor has raised enquiries",
                    job: job,
                    routeAction: getGoToListingRouteAction({
                        visibleSections: "progression_buyer_conveyancer_raised_enquiries",
                        job
                    })
                }
        case "sh_progression_check_if_all_enquires_are_resolved":
            return {
                    title: "Check all enquiries have been resolved",
                    job: job,
                    routeAction: getGoToListingRouteAction({
                        visibleSections: "progression_all_enquiries_resolved",
                        job
                    })
                }
        case "sh_progression_establish_an_exchange_date":
            return {
                    title: "Establish an exchange date",
                    job: job,
                    routeAction: getGoToListingRouteAction({
                        visibleSections: "progression_exchange_date",
                        job
                    })
                }
        case "sh_progression_establish_a_completion_date":
            return {
                    title: "Establish a completion date",
                    job: job,
                    routeAction: getGoToListingRouteAction({
                        visibleSections: "progression_completed_date",
                        job
                    })
                }
        case "sh_progression_has_completed":
            return {
                    title: "Establish if case has completed",
                    job: job,
                    routeAction: getGoToListingRouteAction({
                        visibleSections: "progression_completed_date",
                        job
                    })
                }
        case "sh_progression_release_keys_to_buyer":
            return {
                    title: "Release keys to buyer",
                    job: job,
                    routeAction: getGoToListingRouteAction({
                        visibleSections: "progression_keys_released_to_buyer",
                        job
                    })
                }
        case "sh_progression_mortgage_application_submitted":
            return {
                    title: "Find out if the mortgage broker has submitted the application",
                    job: job,
                    routeAction: getGoToListingRouteAction({
                        visibleSections: "progression_mortgage_application_submitted",
                        job
                    })
                }
        case "sh_progression_mortgage_offer_received":
            return {
                    title: "Find out if the mortgage offer is received yet",
                    job: job,
                    routeAction: getGoToListingRouteAction({
                        visibleSections: "progression_mortgage_offer_received",
                        job
                    })
                }
        case "sh_listing_prep_order_freehold_title_register":
            return {
                    title: "Order/Get expected date for freehold title register",
                    job: job,
                    routeAction: getGoToListingRouteAction({
                        visibleSections: "documents",
                        job
                    })
                }
        case "sh_listing_prep_order_freehold_title_plan":
            return {
                    title: "Order/Get expected date for freehold title plan",
                    job: job,
                    routeAction: getGoToListingRouteAction({
                        visibleSections: "documents",
                        job
                    })
                }
        case "sh_listing_prep_order_leasehold_title_plan":
            return {
                    title: "Order/Get expected date for leasehold title plan",
                    job: job,
                    routeAction: getGoToListingRouteAction({
                        visibleSections: "documents",
                        job
                    })
                }
        case "sh_listing_prep_order_leasehold_title_register":
            return {
                    title: "Order/Get expected date for leasehold title register",
                    job: job,
                    routeAction: getGoToListingRouteAction({
                        visibleSections: "documents",
                        job
                    })
                }
        case "sh_listing_prep_order_leasehold_lease":
            return {
                    title: "Order/Get expected date for leasehold lease",
                    job: job,
                    routeAction: getGoToListingRouteAction({
                        visibleSections: "documents",
                        job
                    })
                }
        case "sh_listing_prep_order_local_authority_search":
            return {
                    title: "Order/Get expected date for local authority search",
                    job: job,
                    routeAction: getGoToListingRouteAction({
                        visibleSections: "documents",
                        job
                    })
                }
        case "sh_listing_prep_order_drainage_and_water_search":
            return {
                    title: "Order/Get expected date for drainage and water search",
                    job: job,
                    routeAction: getGoToListingRouteAction({
                        visibleSections: "documents",
                        job
                    })
                }
        case "sh_listing_prep_order_chancel_search":
            return {
                    title: "Order/Get expected date for chancel search",
                    job: job,
                    routeAction: getGoToListingRouteAction({
                        visibleSections: "documents",
                        job
                    })
                }
        case "sh_listing_prep_order_enviromental_search":
            return {
                    title: "Order/Get expected date for enviroment search",
                    job: job,
                    routeAction: getGoToListingRouteAction({
                        visibleSections: "documents",
                        job
                    })
                }
        case "sh_listing_prep_order_epc":
            return {
                    title: "Order/Get expected date for epc",
                    job: job,
                    routeAction: getGoToListingRouteAction({
                        visibleSections: "documents",
                        job
                    })
                }
        case "sh_listing_prep_order_floorplan":
            return {
                    title: "Order/Get expected date for floorplan",
                    job: job,
                    routeAction: getGoToListingRouteAction({
                        visibleSections: "documents",
                        job
                    })
                }
        case "sh_listing_prep_upload_freehold_title_register":
            return {
                    title: "Upload/Update expected date for freehold title register",
                    job: job,
                    routeAction: getGoToListingRouteAction({
                        visibleSections: "documents",
                        job
                    })
                }
        case "sh_listing_prep_upload_freehold_title_plan":
            return {
                    title: "Upload/Update expected date for freehold title plan",
                    job: job,
                    routeAction: getGoToListingRouteAction({
                        visibleSections: "documents",
                        job
                    })
                }
        case "sh_listing_prep_upload_leasehold_title_register":
            return {
                    title: "Upload/Update expected date for leasehold title plan",
                    job: job,
                    routeAction: getGoToListingRouteAction({
                        visibleSections: "documents",
                        job
                    })
                }
        case "sh_listing_prep_upload_leasehold_title_plan":
            return {
                    title: "Upload/Update expected date for leasehold title plan",
                    job: job,
                    routeAction: getGoToListingRouteAction({
                        visibleSections: "documents",
                        job
                    })
                }
        case "sh_listing_prep_upload_leasehold_lease":
            return {
                    title: "Upload/Update expected date for leasehold lease",
                    job: job,
                    routeAction: getGoToListingRouteAction({
                        visibleSections: "documents",
                        job
                    })
                }
        case "sh_listing_prep_upload_local_authority_search":
            return {
                    title: "Upload/Update expected date for local authority search",
                    job: job,
                    routeAction: getGoToListingRouteAction({
                        visibleSections: "documents",
                        job
                    })
                }
        case "sh_listing_prep_upload_drainage_and_water_search":
            return {
                    title: "Upload/Update expected date for drainage and water search",
                    job: job,
                    routeAction: getGoToListingRouteAction({
                        visibleSections: "documents",
                        job
                    })
                }
        case "sh_listing_prep_upload_chancel_search":
            return {
                    title: "Upload/Update expected date for chancel search",
                    job: job,
                    routeAction: getGoToListingRouteAction({
                        visibleSections: "documents",
                        job
                    })
                }
        case "sh_listing_prep_upload_enviromental_search":
            return {
                    title: "Upload/Update expected date for enviromental search",
                    job: job,
                    routeAction: getGoToListingRouteAction({
                        visibleSections: "documents",
                        job
                    })
                }
        case "sh_listing_prep_upload_epc":
            return {
                    title: "Upload/Update expected date for epc",
                    job: job,
                    routeAction: getGoToListingRouteAction({
                        visibleSections: "documents",
                        job
                    })
                }
        case "sh_listing_prep_upload_floorplan":
            return {
                    title: "Upload/Update expected date for floorplan",
                    job: job,
                    routeAction: getGoToListingRouteAction({
                        visibleSections: "documents",
                        job
                    })
                }
        case "sh_listing_prep_send_docs_to_solicitor":
            return {
                    title: "Docs need sending to solictor",
                    job: job,
                    routeAction: getGoToListingRouteAction({
                        visibleSections: "documents",
                        job
                    })
                }
        case "sh_choose_key_strategy":
            return {
                    title: "Choose key holder strategy",
                    job: job,
                    routeAction: getGoToListingRouteAction({
                        visibleSections: "key_management",
                        job
                    })
                }
        case "sh_setup_keynest":
            return {
                    title: "Setup keynest",
                    job: job,
                    routeAction: getGoToListingRouteAction({
                        visibleSections: "key_management",
                        job
                    })
                }
        case "sh_assign_keyholding_occupier":
            return {
                    title: "Assign keyholding occupier",
                    job: job,
                    routeAction: getGoToListingRouteAction({
                        visibleSections: "key_management",
                        job
                    })
                }
        case "sh_assign_keyholding_designated_person":
            return {
                    title: "Assign keyholding designated person",
                    job: job,
                    routeAction: getGoToListingRouteAction({
                        visibleSections: "key_management",
                        job
                    })
                }
        case "sh_book_in_keysafe_to_be_fitted":
            return {
                    title: "Book keysafe fitting",
                    job: job,
                    routeAction: getGoToListingRouteAction({
                        visibleSections: "key_management",
                        job
                    })
                }
        case "sh_confirm_key_safe_fitted":
            return {
                    title: "Confirm keysafe fitted and code set",
                    job: job,
                    routeAction: getGoToListingRouteAction({
                        visibleSections: "key_management",
                        job
                    })
                }
        case "sh_arrange_key_delivery":
            return {
                    title: "Arrange key delivery",
                    job: job,
                    routeAction: getGoToListingRouteAction({
                        visibleSections: "key_management",
                        job
                    })
                }
        case "sh_confirm_keys_delivered":
            return {
                    title: "Confirm keys delivered",
                    job: job,
                    routeAction: getGoToListingRouteAction({
                        visibleSections: "key_management",
                        job
                    })
                }
        case "sh_book_photography":
            return {
                    title: "Book photography",
                    job: job,
                    routeAction: getGoToListingRouteAction({
                        visibleSections: "marketing",
                        job
                    })
                }
        case "sh_book_video_tour":
            return {
                    title: "Book video tour recording",
                    job: job,
                    routeAction: getGoToListingRouteAction({
                        visibleSections: "marketing",
                        job
                    })
                }
        case "sh_upload_photos":
            return {
                    title: "Upload photos",
                    job: job,
                    routeAction: getGoToListingRouteAction({
                        visibleSections: "marketing",
                        job
                    })
                }
        case "sh_link_video_tour":
            return {
                    title: "Link video tour",
                    job: job,
                    routeAction: getGoToListingRouteAction({
                        visibleSections: "marketing",
                        job
                    })
                }
        case "sh_order_descripition":
            return {
                    title: "Order Description",
                    job: job,
                    routeAction: getGoToListingRouteAction({
                        visibleSections: "marketing",
                        job
                    })
                }
        case "sh_enter_and_proofread_description":
            return {
                    title: "Chase/Upload Description",
                    job: job,
                    routeAction: getGoToListingRouteAction({
                        visibleSections: "marketing",
                        job
                    })
                }
        case "sh_set_minimum_listing_specifics":
            return {
                    title: "Set Minimum Listing Specifics and Price",
                    job: job,
                    routeAction: getGoToListingRouteAction({
                        visibleSections: ["specifics", "price_and_costs"],
                        job
                    })
                }
        case "sh_approve_listing_optional_specifics":
            return {
                    title: "Approve listing specifics for marketing",
                    job: job,
                    routeAction: getGoToListingRouteAction({
                        visibleSections: "specifics",
                        job
                    })
                }
        case "sh_enter_portal_admin_links":
            return {
                    title: "Add portal admin links",
                    job: job,
                    routeAction: getGoToListingRouteAction({
                        visibleSections: "marketing",
                        job
                    })
                }
        case "sh_listing_id_check":
            return {
                    title: "Do ID check or get GOP",
                    job: job,
                    routeAction: getGoToListingRouteAction({
                        visibleSections: "sellers",
                        job
                    })
                }
        case "sh_go_live_on_portals":
            return {
                    title: "Set listing live on all portals",
                    job: job,
                    routeAction: getGoToListingRouteAction({
                        visibleSections: "selling_process",
                        job
                    })
                }
        case "sh_chase_listing_instruction":
            return {
                    title: "Chase instruction or mark as lost",
                    job: job,
                    routeAction: getGoToListingRouteAction({
                        visibleSections: "selling_process",
                        job
                    })
                }
        case "sh_listing_prep_choose_viewings_strategy":
            return {
                    title: "Set viewings strategy",
                    job: job,
                    routeAction: getGoToListingRouteAction({
                        visibleSections: "selling_process",
                        job
                    })
                }
        case "sh_listing_prep_assign_viewings_conducted_by_occupier":
            return {
                    title: "Set the seller party occupier who will be conducting viewings",
                    job: job,
                    routeAction: getGoToListingRouteAction({
                        visibleSections: "selling_process",
                        job
                    })
                }
        case "sh_listing_prep_assign_viewings_conducted_by_designated_person":
            return {
                    title: "Set the seller party member who will be conducting viewings",
                    job: job,
                    routeAction: getGoToListingRouteAction({
                        visibleSections: "selling_process",
                        job
                    })
                }
        case "sh_listing_prep_viewings_update_viewber_details":
            return {
                    title: "Update/put property details into Viewber ready for viewings",
                    job: job,
                    routeAction: getGoToListingRouteAction({
                        visibleSections: "selling_process",
                        job
                    })
                }
        case "sh_arrange_valuation_date":
            return {
                    title: "Arrange valuation date",
                    job: job,
                    routeAction: getGoToListingRouteAction({
                        visibleSections: "selling_process",
                        job
                    })
                }
        case "sh_send_valuation":
            return {
                    title: "Send valuation to client(s)",
                    job: job,
                    routeAction: getGoToListingRouteAction({
                        visibleSections: "selling_process",
                        job
                    })
                }
        case "sh_send_seller_pifs":
            return {
                    title: "Send seller property information forms to complete",
                    job: job,
                    routeAction: getGoToListingRouteAction({
                        visibleSections: "conveyancing",
                        job
                    })
                }
        case "sh_rightmove_public_notice":
            return {
                    title: job.public_notice_task === "add_or_update" ? "Add/Update public notice on Rightmove" : job.public_notice_task === "remove" ? "Remove public notice from Rightmove" : requireExhaustive(job.public_notice_task),
                    job: job,
                    routeAction: getGoToListingRouteAction({
                        visibleSections: "marketing",
                        job
                    })
                }
        case "sh_set_legal_seller_or_company_name":
            return {
                    title: "Set legal seller / seller's company name",
                    job: job,
                    routeAction: getGoToListingRouteAction({
                        visibleSections: "sellers",
                        job
                    })
                }
        case "sh_pay_referral_fee":
            return {
                    title: "Pay referral fee",
                    job: job,
                    routeAction: getGoToListingRouteAction({
                        visibleSections: "selling_process",
                        job
                    })
                }
        // END CRMJobCardViewListing
        
        case "sh_offers_shared":
            return {
                    title: "Share offers",
                    job: job,
                    routeAction: getGoToListingOffersRouteAction({ job })
                }
        case "sh_get_legal_buyers":
            return {
                    title: "Get legal buyers / purchaser's company name",
                    job: job,
                    routeAction: getGoToEnquiryRouteAction({ job })
                }
        case "sh_thirdfort_id_check":
            return {
                    title: "Start Thirdfort ID check",
                    job: job,
                    routeAction: getGoToEnquiryRouteAction({ job })
                }
        case "sh_viewings_book":
            return {
                    title: "Get viewing time",
                    job: job,
                    routeAction: getGoToEnquiryRouteAction({ job })
                }
            case "sh_viewings_confirm":
                return {
                    title: "Confirm viewing",
                    job: job,
                    routeAction: getGoToEnquiryRouteAction({ job })
                }
            case "sh_viewings_feedback":
                return {
                    title: "Get viewing feedback",
                    job: job,
                    routeAction: getGoToEnquiryRouteAction({ job })
                }
        case "sh_listing_ad_hoc":
            return {
                    job: job,
                    routeAction: getAdHocGoToListingRouteAction({ job })
                }
        case "sh_listing_enquiry_ad_hoc":
            return {
                    job: job,
                    routeAction: getGoToEnquiryRouteAction({ job })
                }
        case "sh_offers_share_feedback_with_buyer":
            return {
                    job: job,
                    routeAction: getGoToEnquiryRouteAction({ job })
                }
        case "sl_all_clients_added_and_checked":
            return {
                    title: "Check all clients added to the case and have right to sell/buy",
                    job: job,
                    routeAction: getGoToCaseRouteAction({
                        job,
                        visibleBlocks:  [
                            {
                                type: "case_details",
                                meta: {
                                    visible_sections: "clients"
                                },
                            },
                        ]
                    })
                }
        case "sl_book_video_call_verification":
            return {
                    title: "Chase client(s) to book video call",
                    job: job,
                    routeAction: getGoToCaseRouteAction({
                        job,
                        visibleBlocks: [
                            {
                                type: "case_details",
                                meta: {
                                    visible_sections: "video_verification_calls"
                                },
                            },
                        ]
                    }),
                }
        case "sl_review_peps_and_sanctions":
            return {
                    title: "Review PEPs and Sanctions",
                    job: job,
                    routeAction: getGoToCaseRouteAction({
                        job,
                        visibleBlocks: [
                            {
                                type: "case_details",
                                meta: {
                                    visible_sections: "clients"
                                },
                            },
                        ]
                    }),
                }
        case "sl_review_uploaded_proof_of_address":
            return {
                    title: "Review uploaded proof of address",
                    job: job,
                    routeAction: getGoToCaseRouteAction({
                        job,
                        visibleBlocks: [
                            {
                                type: "case_details",
                                meta: {
                                    visible_sections: "clients"
                                },
                            },
                        ]
                    }),
                }
        case "sl_do_video_call_verification":
            return {
                    title: "Do video call verification and record the outcome",
                    job: job,
                    routeAction: getGoToCaseRouteAction({
                        job,
                        visibleBlocks: [
                            {
                                type: "case_details",
                                meta: {
                                    visible_sections: "video_verification_calls"
                                },
                            },
                        ]
                    }),
                }
        case "sl_authoriser_check_video_call_verification_fails":
            return {
                    title: "Authoriser check video call verification fails",
                    job: job,
                    routeAction: getGoToCaseRouteAction({
                        job,
                        visibleBlocks: [
                            {
                                type: "case_details",
                                meta: {
                                    visible_sections: "video_verification_calls"
                                },
                            },
                        ]
                    }),
                }
        case "sl_get_other_side_conveyancer_details":
            return {
                    title: "Enter other side's conveyancer details",
                    job: job,
                    routeAction: getGoToCaseRouteAction({ job })
                }
        case "sl_verify_other_sides_conveyancer":
            return {
                    title: "Verify other side’s conveyancer with SRA/CLC",
                    job: job,
                    routeAction: getGoToCaseRouteAction({ job })
                }
        case "sl_upload_expected_documents":
            return {
                    title: "Create/Upload document(s)",
                    job: job,
                    routeAction: getGoToCaseRouteAction({ 
                        job,
                        visibleTab: "document_management",
                    })
                }
        case "sl_chase_external_documents":
            return {
                    title: "Chase/Upload External Docs",
                    job: job,
                    routeAction: getGoToCaseRouteAction({ 
                        job,
                        visibleTab: "document_management",
                    })
                }
        case "sl_external_documents_overdue":
            return {
                    title: "Some External Docs Are 2+ Weeks Overdue",
                    job: job,
                    routeAction: getGoToCaseRouteAction({ 
                        job,
                        visibleTab: "document_management",
                    })
                }
        case "sl_review_document":
            return {
                    title: "Review Document",
                    job: job,
                    routeAction: getGoToCaseRouteAction({ 
                        job,
                        visibleTab: "enquiries",
                    })
                }
        case "sl_approve_enquiries":
            return {
                    title: "Approve enquiries",
                    job: job,
                    routeAction: getGoToCaseRouteAction({ 
                        job,
                        visibleTab: "enquiries",
                    })
                }
        case "sl_send_enquiries":
            return {
                    title: "Send enquiries",
                    job: job,
                    routeAction: getGoToCaseRouteAction({ 
                        job,
                        visibleTab: "enquiries",
                    })
                }
        case "sl_resolve_enquiries":
            return {
                    title: "Resolve enquiries",
                    job: job,
                    routeAction: getGoToCaseRouteAction({ 
                        job,
                        visibleTab: "enquiries",
                    })
                }
        case "sl_get_property_details":
            return {
                    title: "Get all property details",
                    job: job,
                    routeAction: getGoToCaseRouteAction({ job })
                }
        case "sl_get_contract_details":
            return {
                    title: "Get contract details",
                    job: job,
                    routeAction: getGoToCaseRouteAction({ job })
                }
        case "sl_add_completion_deadline_for_auction_case":
            return {
                    title: "Add completion deadline for auction case",
                    job: job,
                    routeAction: getGoToCaseRouteAction({ job })
                }
        case "sl_get_mortgage_lender":
            return {
                    title: "Get mortgage lender",
                    job: job,
                    routeAction: getGoToCaseRouteAction({ job })
                }
        case "sl_set_up_case_in_klyant":
            return {
                    title: "Set up case in Klyant, add matter and client id to CRM",
                    job: job,
                    routeAction: getGoToCaseRouteAction({
                        job,
                        visibleBlocks: [
                            {
                                type: "case_details",
                                meta: {
                                    visible_sections: "clients"
                                },
                            },
                        ]
                    })
                }
        case "sl_get_other_side_client_details_and_check":
            return {
                    title: "All other side clients added, details correct and right to buy/sell checked?",
                    job: job,
                    routeAction: getGoToCaseRouteAction({ job })
                }
        case "sl_get_management_company_details":
            return {
                    title: "Fill out management company details",
                    job: job,
                    routeAction: getGoToCaseRouteAction({
                        job,
                        visibleBlocks: [
                            {
                                type: "case_details",
                                meta: {
                                    visible_sections: "management_company_details"
                                },
                            },
                        ]
                    })
                }
        case "sl_send_instructed_email":
            return {
                    title: "Send instructed email to some clients / authorised third parties",
                    job: job,
                    routeAction: getGoToCaseRouteAction({
                        job,
                        visibleBlocks: [
                            {
                                type: "case_details",
                                meta: {
                                    visible_sections: "clients"
                                },
                            },
                        ]
                    })
                }
        case "sl_add_estate_agent_details_to_case":
            return {
                    title: "Add estate agent details",
                    job: job,
                    routeAction: getGoToCaseRouteAction({ job })
                }
        case "sl_chase_offer_accepted":
            return {
                    title: "Chase offer accept confirmation",
                    job: job,
                    routeAction: getGoToCaseRouteAction({
                        job,
                        visibleBlocks: [
                            {
                                type: "case_details",
                                meta: {
                                    visible_sections: "case_milestones"
                                },
                            },
                        ]
                    })
                }
        case "sl_assign_case_handler":
            return {
                    title: "Assign case handler",
                    job: job,
                    routeAction: getGoToCaseRouteAction({
                        job,
                        visibleBlocks: [
                            {
                                type: "case_details",
                                meta: {
                                    visible_sections: "case_management"
                                },
                            },
                        ]
                    })
                }
        case "sl_chase_case_instruction":
            return {
                    title: "Chase case instruction",
                    job: job,
                    routeAction: getGoToCaseRouteAction({
                        job,
                        visibleBlocks: [
                            {
                                type: "case_details",
                                meta: {
                                    visible_sections: "case_milestones"
                                },
                            },
                        ]
                    })
                }
        case "sl_verify_bank_accounts":
            return {
                    title: "Verify client bank accounts",
                    job: job,
                    routeAction: getGoToCaseRouteAction({
                        job,
                        visibleBlocks: [
                            {
                                type: "case_details",
                                meta: {
                                    visible_sections: "client_bank_accounts"
                                },
                            },
                        ]
                    })
                }
        case "sl_create_draft_contract_document_and_tr1":
            return {
                    title: "Create draft contract and TR1",
                    job: job,
                    routeAction: getGoToCaseRouteAction({ 
                        job,
                        visibleTab: "document_management",
                    })
                }
        case "sl_send_draft_contract":
            return {
                    title: "Send draft contract",
                    job: job,
                    routeAction: getGoToCaseRouteAction({
                        job,
                        visibleBlocks: [
                            {
                                type: "case_details",
                                meta: {
                                    visible_sections: "case_milestones"
                                },
                            },
                        ]
                    })
                }
        case "sl_bankruptcy_search":
            return {
                    title: "Order bankruptcy search & get results",
                    job: job,
                    routeAction: getGoToCaseRouteAction({
                        job,
                        visibleBlocks: [
                            {
                                type: "case_details",
                                meta: {
                                    visible_sections: "clients"
                                },
                            },
                        ]
                    })
                }
        case "sl_get_survey_details":
            return {
                    title: "Get survey details",
                    job: job,
                    routeAction: getGoToCaseRouteAction({
                        job,
                        visibleBlocks: [
                            {
                                type: "case_details",
                                meta: {
                                    visible_sections: "survey"
                                },
                            },
                        ]
                    })
                }
        case "sl_upload_title_report":
            return {
                    title: "Create and upload title report",
                    job: job,
                    routeAction: getGoToCaseRouteAction({
                        job,
                        visibleTab: "document_management",
                    })
                }
        case "sl_update_title_report":
            return {
                    title: "Update title report with new information",
                    job: job,
                    routeAction: getGoToCaseRouteAction({
                        job,
                        visibleTab: "document_management",
                    })
                }
        case "sl_set_risk_grade": 
            return {
                    title: "Make risk assessment",
                    job: job,
                    routeAction: getGoToCaseRouteAction({
                        job,
                        visibleBlocks: [
                            {
                                type: "case_details",
                                meta: {
                                    visible_sections: "risk_management"
                                },
                            },
                        ]
                    })
                }
        case "sl_add_details_of_mortgage_offer":
            return {
                    title: "Add details of mortgage offer / check mortgage document expiry",
                    job: job,
                    routeAction: getGoToCaseRouteAction({
                        job,
                        visibleBlocks: [
                            {
                                type: "case_details",
                                meta: {
                                    visible_sections: "mortgage_offer"
                                },
                            },
                        ]
                    })
                }
        case "sl_share_mortgage_retention_with_client":
            return {
                    title: "Share mortgage retention with client and confirm they are proceeding",
                    job: job,
                    routeAction: getGoToCaseRouteAction({
                        job,
                        visibleBlocks: [
                            {
                                type: "case_details",
                                meta: {
                                    visible_sections: "mortgage_offer"
                                },
                            },
                        ]
                    })
                }
        case "sl_request_funds_from_lender":
            return {
                    title: "Request mortgage funds from lender",
                    job: job,
                    routeAction: getGoToCaseRouteAction({
                        job,
                        visibleBlocks: [
                            {
                                type: "case_details",
                                meta: {
                                    visible_sections: "mortgage_offer"
                                },
                            },
                        ]
                    })
                }
        case "sl_confirm_funds_received_from_lender":
            return {
                    title: "Confirm mortgage funds received from lender",
                    job: job,
                    routeAction: getGoToCaseRouteAction({
                        job,
                        visibleBlocks: [
                            {
                                type: "case_details",
                                meta: {
                                    visible_sections: "mortgage_offer"
                                },
                            },
                        ]
                    })
                }
        case "sl_request_funds_from_client":
            return {
                    title: "Request funds from client",
                    job: job,
                    routeAction: getGoToCaseRouteAction({
                        job,
                        visibleBlocks: [
                            {
                                type: "case_details",
                                meta: {
                                    visible_sections: "client_funds"
                                },
                            },
                        ]
                    })
                }
        case "sl_confirm_funds_received_from_client":
            return {
                    title: "Confirm funds received from client",
                    job: job,
                    routeAction: getGoToCaseRouteAction({
                        job,
                        visibleBlocks: [
                            {
                                type: "case_details",
                                meta: {
                                    visible_sections: "client_funds"
                                },
                            },
                        ]
                    })
                }
        case "sl_pay_seller_solicitor":
            return {
                    title: "Pay seller solicitor",
                    job: job,
                    routeAction: getGoToCaseRouteAction({
                        job,
                        visibleBlocks: [
                            {
                                type: "case_details",
                                meta: {
                                    visible_sections: "outgoing_payments"
                                },
                            },
                        ]
                    })
                }
        case "sl_confirm_payment_received_by_seller_solicitor":
            return {
                    title: "Confirm payment received by seller solicitor",
                    job: job,
                    routeAction: getGoToCaseRouteAction({
                        job,
                        visibleBlocks: [
                            {
                                type: "case_details",
                                meta: {
                                    visible_sections: "outgoing_payments"
                                },
                            },
                        ]
                    })
                }
        case "sl_pay_sail_legal_invoice_from_client_account_funds":
            return {
                    title: "Transfer client funds to office account to pay our invoice",
                    job: job,
                    routeAction: getGoToCaseRouteAction({
                        job,
                        visibleBlocks: [
                            {
                                type: "case_details",
                                meta: {
                                    visible_sections: "outgoing_payments"
                                },
                            },
                        ]
                    })
                }
        case "sl_proceeds_of_sale_received_from_buyer_solicitor":
            return {
                    title: "Proceeds of sale received from buyer's solicitor?",
                    job: job,
                    routeAction: getGoToCaseRouteAction({
                        job,
                        visibleBlocks: [
                            {
                                type: "case_details",
                                meta: {
                                    visible_sections: "client_funds"
                                },
                            },
                        ]
                    })
                }
        case "sl_upload_search_report":
            return {
                    title: "Create and upload search report",
                    job: job,
                    routeAction: getGoToCaseRouteAction({
                        job,
                        visibleTab: "document_management",
                    })
                }
        case "sl_update_search_report":
            return {
                    title: "Update search report with new information",
                    job: job,
                    routeAction: getGoToCaseRouteAction({
                        job,
                        visibleTab: "document_management",
                    })
                }
        case "sl_upload_enquiries_report":
            return {
                    title: "Create and upload enquiries report",
                    job: job,
                    routeAction: getGoToCaseRouteAction({
                        job,
                        visibleTab: "document_management",
                    })
                }
        case "sl_update_enquiries_report":
            return {
                    title: "Update enquiries report with new information",
                    job: job,
                    routeAction: getGoToCaseRouteAction({
                        job,
                        visibleTab: "document_management",
                    })
                }
        case "sl_upload_mortgage_report":
            return {
                    title: "Create and upload mortgage offer report",
                    job: job,
                    routeAction: getGoToCaseRouteAction({
                        job,
                        visibleTab: "document_management",
                    })
                }
        case "sl_update_mortgage_report":
            return {
                    title: "Update mortgage offer report with new information",
                    job: job,
                    routeAction: getGoToCaseRouteAction({
                        job,
                        visibleTab: "document_management",
                    })
                }
        case "sl_upload_leasehold_report":
            return {
                    title: "Create and upload leasehold pack report",
                    job: job,
                    routeAction: getGoToCaseRouteAction({
                        job,
                        visibleTab: "document_management",
                    })
                }
        case "sl_update_leasehold_report":
            return {
                    title: "Update leasehold pack report with new information",
                    job: job,
                    routeAction: getGoToCaseRouteAction({
                        job,
                        visibleTab: "document_management",
                    })
                }
        case "sl_verify_seller_conveyancer_bank_account":
            return {
                    title: "Verify seller's conveyancer bank account",
                    job: job,
                    routeAction: getGoToCaseRouteAction({
                        job,
                        visibleBlocks: [
                            {
                                type: "case_details",
                                meta: {
                                    visible_sections: "seller_conveyancer_bank_account"
                                },
                            },
                        ]
                    })
                }
        case "sl_review_fraudulent_id_check":
            return {
                    title: "Review fraudulent ID check",
                    job: job,
                    routeAction: getGoToCaseRouteAction({
                        job,
                        visibleBlocks: [
                            {
                                type: "case_details",
                                meta: {
                                    visible_sections: "clients"
                                },
                            },
                        ]
                    })
                }
        case "sl_review_case_without_id_check":
            return {
                    title: "ID checks are missing for user(s), 14+ days after instruction",
                    job: job,
                    routeAction: getGoToCaseRouteAction({
                        job,
                        visibleBlocks: [
                            {
                                type: "case_details",
                                meta: {
                                    visible_sections: "clients"
                                },
                            },
                        ]
                    })
                }
        case "sl_review_case_cois":
            return {
                    title: "Review cases unresolved conflicts",
                    job: job,
                    routeAction: getGoToCaseRouteAction({
                        job,
                        visibleTab: "coi",
                    })
                }
        case "sl_finalise_case_ledger":
            return {
                    title: "Balance ledger then mark as final/completed",
                    job: job,
                    routeAction: getGoToCaseRouteAction({
                        job,
                        visibleTab: "ledger",
                    })
                }
        case "sl_chase_payments_due":
            return {
                    title: "Chase payments due before completion",
                    job: job,
                    routeAction: getGoToCaseRouteAction({
                        job,
                        visibleTab: "ledger",
                    })
                }
        case "sl_add_slip_to_cashroom":
            return {
                    title: "Add slips to Cashroom for items the client says they've paid",
                    job: job,
                    routeAction: getGoToCaseRouteAction({
                        job,
                        visibleTab: "ledger",
                    })
                }
        case "sl_payout_ledger_items":
            return {
                    title: "Payout and add slips for cases debits",
                    job: job,
                    routeAction: getGoToCaseRouteAction({
                        job,
                        visibleTab: "ledger",
                    })
                }
        case "sl_submit_registration":
            return {
                    title: "Submit registration",
                    job: job,
                    routeAction: getGoToCaseRouteAction({
                        job,
                        visibleTab: "details",
                        visibleBlocks: [
                            {
                                type: "case_details",
                                meta: {
                                    visible_sections: "post_completion"
                                },
                            },
                            {
                                type: "case_overview",
                                meta: {},
                            },
                        ]
                    })
                }
        case "sl_resolve_requisition":
            return {
                    title: "Resolve requisition",
                    job: job,
                    routeAction: getGoToCaseRouteAction({
                        job,
                        visibleTab: "details",
                        visibleBlocks: [
                            {
                                type: "case_details",
                                meta: {
                                    visible_sections: "post_completion"
                                },
                            },
                            {
                                type: "case_overview",
                                meta: {},
                            },
                        ]
                    })
                }
        case "sl_resolve_cancellation":
            return {
                    title: "Resolve cancellation",
                    job: job,
                    routeAction: getGoToCaseRouteAction({
                        job,
                        visibleTab: "details",
                        visibleBlocks: [
                            {
                                type: "case_details",
                                meta: {
                                    visible_sections: "post_completion"
                                },
                            },
                            {
                                type: "case_overview",
                                meta: {},
                            },
                        ]
                    })
                }
        case "sl_complete_registration":
            return {
                    title: "Check for registration completion",
                    job: job,
                    routeAction: getGoToCaseRouteAction({
                        job,
                        visibleTab: "details",
                        visibleBlocks: [
                            {
                                type: "case_details",
                                meta: {
                                    visible_sections: "post_completion"
                                },
                            },
                            {
                                type: "case_overview",
                                meta: {},
                            },
                        ]
                    })
                }
        case "sl_check_if_searches_required":
            return {
                    title: "Check if client (cash buyer) wants searches ordered",
                    job: job,
                    routeAction: getGoToCaseRouteAction({
                        job,
                        visibleTab: "details",
                        visibleBlocks: [
                            {
                                type: "case_contracts",
                                meta: {
                                    visible_sections: "contract_details",
                                },
                            },
                            {
                                type: "case_overview",
                                meta: {},
                            },
                        ]
                    })
                }
        case "sl_order_searches":
            return {
                    title: "Order searches",
                    job: job,
                    routeAction: getGoToCaseRouteAction({
                        job,
                        visibleTab: "details",
                        visibleBlocks: [
                            {
                                type: "case_details",
                                meta: {
                                    visible_sections: "case_milestones"
                                },
                            },
                        ]
                    })
                }
        case "sl_order_lpe1":
            return {
                    title: "Order LPE1",
                    job: job,
                    routeAction: getGoToCaseRouteAction({
                        job,
                        visibleTab: "details",
                        visibleBlocks: [
                            {
                                type: "case_details",
                                meta: {
                                    visible_sections: "management_company_details"
                                },
                            },
                            {
                                type: "case_overview",
                                meta: {},
                            },
                        ]
                    })
                }
        case "sl_case_ad_hoc":
            return {
                    job: job,
                    routeAction: getGoToCaseRouteAction({ job })
                }
        case "sl_refer_sale_to_sail_homes":
            return {
                    title: "Refer the related sale to Sail Homes",
                    job: job,
                    routeAction: getGoToCaseRouteAction({
                        job,
                        visibleTab: "details",
                        visibleBlocks: [
                            {
                                type: "case_details",
                                meta: {
                                    visible_sections: "related_transaction"
                                },
                            },
                            {
                                type: "case_overview",
                                meta: {},
                            },
                        ]
                    })
                }
        case "sl_upload_quote":
            return {
                    title: "Create quote",
                    job: job,
                    routeAction: getGoToCaseRouteAction({
                        job,
                        visibleTab: "details",
                        visibleBlocks: [
                            {
                                type: "case_details",
                                meta: {
                                    visible_sections: "clients"
                                },
                            },
                            {
                                type: "case_quotes",
                                meta: {},
                            },
                        ]
                    })
                }
        case "sl_suspicious_file_uploaded_to_case":
            return {
                    title: "A suspicious file has been uploaded by one of the case's users!",
                    job: job,
                    routeAction: getGoToCaseRouteAction({
                        job,
                        visibleTab: "details",
                        visibleBlocks: [
                            {
                                type: "case_details",
                                meta: {
                                    visible_sections: "clients"
                                },
                            },
                            {
                                type: "case_overview",
                                meta: {},
                            },
                        ]
                    })
                }
        case "sl_review_sdlt_answers":
            return {
                    title: "Review SDLT answers",
                    job: job,
                    routeAction: getGoToCaseRouteAction({
                        job,
                        visibleTab: "details",
                        visibleBlocks: [
                            {
                                type: "case_details",
                                meta: {
                                    visible_sections: "stamp_duty_land_tax"
                                },
                            },
                            {
                                type: "case_overview",
                                meta: {},
                            },
                        ]
                    })
                }
        case "sl_add_sdlt_to_ledger":
            return {
                    title: "Add SDLT to ledger",
                    job: job,
                    routeAction: getGoToCaseRouteAction({
                        job,
                        visibleTab: "ledger",
                    })
                }
        case "sl_send_report":
            return {
                    title: "Send report(s) to client",
                    job: job,
                    routeAction: getGoToCaseRouteAction({
                        job,
                        visibleTab: "document_management",
                    })
                }
        case "sl_audit_case":
            return {
                    title: "Audit case",
                    job: job,
                    routeAction: getGoToCaseRouteAction({
                        job,
                        visibleTab: "details",
                    })
                }
        case "sl_add_charges_and_title_restrictions":
            return {
                    title: "Add charges & title restrictions",
                    job: job,
                    routeAction: getGoToCaseRouteAction({
                        job,
                        visibleTab: "details",
                        visibleBlocks: [
                            {
                                type: "charges_and_restrictions",
                                meta: {},
                            },
                        ]
                    })
                }
        case "sl_carry_out_ready_for_exchange_review":
            return {
                    title: "Carry out ready for exchange review",
                    job: job,
                    routeAction: getGoToCaseRouteAction({
                        job,
                        visibleTab: "details",
                        visibleBlocks: [
                            {
                                type: "case_details",
                                meta: {
                                    visible_sections: "exchange_and_complete"
                                },
                            },
                            {
                                type: "case_overview",
                                meta: {},
                            },
                        ]
                    })
                }
        case "sl_carry_out_ready_for_exchange_review_actions":
            return {
                    title: "Carry out ready for exchange review actions",
                    job: job,
                    routeAction: getGoToCaseRouteAction({
                        job,
                        visibleTab: "details",
                        visibleBlocks: [
                            {
                                type: "case_details",
                                meta: {
                                    visible_sections: "exchange_and_complete"
                                },
                            },
                            {
                                type: "case_overview",
                                meta: {},
                            },
                        ]
                    })
                }
        case "sl_get_first_redemption_statement":
            return {
                    title: "Get a restriction's first redemption statement",
                    job: job,
                    routeAction: getGoToCaseRouteAction({
                        job,
                        visibleTab: "details",
                        visibleBlocks: [
                            {
                                type: "charges_and_restrictions",
                                meta: {},
                            },
                        ]
                    })
                }
        case "sl_get_redemption_statement_for_completion":
            return {
                    title: "Get a restriction's final redemption statement",
                    job: job,
                    routeAction: getGoToCaseRouteAction({
                        job,
                        visibleTab: "details",
                        visibleBlocks: [
                            {
                                type: "charges_and_restrictions",
                                meta: {},
                            },
                        ]
                    })
                }
        case "sl_chase_redemption_statement":
            return {
                    title: "Chase an overdue redemption statement",
                    job: job,
                    routeAction: getGoToCaseRouteAction({
                        job,
                        visibleTab: "details",
                        visibleBlocks: [
                            {
                                type: "charges_and_restrictions",
                                meta: {},
                            },
                        ]
                    })
                }
        case "sl_admin_upload_signed_introducer_referral_agreement":
            return {
                    title: job.introducers.length === 1
                        ? "Upload a signed referral agreement for an introducer"
                        : "Upload signed referral agreements for introducers"
                    ,
                    job: job,
                    routeAction: getGoToLegalIntroducersRouteAction()
                }
        case "sl_admin_review_introducer_referral_agreement":
            return {
                    title: job.introducers.length === 1
                        ? "Review referral agreement for an introducer"
                        : "Review referral agreements for introducers"
                    ,
                    job: job,
                    routeAction: getGoToLegalIntroducersRouteAction()
                }
        case "sl_review_unhappy_satisfaction":
            return {
                    title: "Review unhappy customer satisfaction",
                    job: job,
                    routeAction: getGoToCaseRouteAction({
                        job,
                        visibleTab: "satisfaction",
                    })
                }
        case "sl_dual_rep_conflict_job":
            return {
                    title: "A dual rep case has a lender that doesn't accept dual rep",
                    job: job,
                    routeAction: getGoToCaseRouteAction({
                        job,
                        visibleTab: "details",
                        visibleBlocks: [
                            {
                                type: "case_contracts",
                                meta: {
                                    visible_sections: "contract_details",
                                },
                            },
                            {
                                type: "case_overview",
                                meta: {},
                            },
                        ]
                    })
                }
        case "sl_post_forwarded_or_shredded":
            return {
                    title: "Forward or shred post",
                    job: job,
                    routeAction: getGoToCaseRouteAction({
                        job,
                        visibleTab: "details",
                        visibleBlocks: [
                            {
                                type: "case_details",
                                meta: {
                                    visible_sections: "post_completion"
                                },
                            },
                            {
                                type: "case_overview",
                                meta: {},
                            },
                        ]
                    })
                }
        case "sl_order_oes":
            return {
                    title: "Order OEs",
                    job: job,
                    routeAction: getGoToCaseRouteAction({
                        job,
                        visibleBlocks: [
                            {
                                type: "case_details",
                                meta: {
                                    visible_sections: "case_milestones"
                                },
                            },
                        ]
                    })
                }
        case "sp_probate_helpline_callback":
            return {
                    title: "Call customer(s)",
                    routeAction: getGoToProbateCallbacksRouteAction()
                }
        case "sl_get_early_repayment_charge":
            return {
                    title: "Is there an Early Repayment Charge?",
                    job: job,
                    routeAction: getGoToCaseRouteAction({
                        job,
                        visibleTab: "details",
                        visibleBlocks: [
                            {
                                type: "charges_and_restrictions",
                                meta: {},
                            },
                        ]
                    })
                }
        case "sl_notify_client_of_early_repayment_charge":
            return {
                    title: "Notify the client of an Early Repayment Charge",
                    job: job,
                    routeAction: getGoToCaseRouteAction({
                        job,
                        visibleTab: "details",
                        visibleBlocks: [
                            {
                                type: "case_details",
                                meta: {
                                    visible_sections: "clients"
                                },
                            },
                            {
                                type: "charges_and_restrictions",
                                meta: {},
                            },
                        ]
                    })
                }
        case "sl_check_if_affected_by_bsa":
            return {
                    title: "Check if case is affected by BSA",
                    job: job,
                    routeAction: getGoToCaseRouteAction({
                        job,
                        visibleTab: "details",
                        visibleBlocks: [
                            {
                                type: "case_contracts",
                                meta: {
                                    visible_sections: "property_details"
                                },
                            },
                            {
                                type: "case_overview",
                                meta: {},
                            },
                        ]
                    })
                }
        case "sl_charge_aborted_costs":
            return {
                    title: "Invoice client aborted costs",
                    job: job,
                    routeAction: getGoToCaseRouteAction({
                        job,
                        visibleTab: "ledger",
                    })
                }
        case "sl_pay_referral_fee":
            return {
                    title: "Pay referral fee",
                    job: job,
                    routeAction: getGoToCaseRouteAction({
                        job,
                        visibleTab: "details",
                        visibleBlocks: [
                            {
                                type: "case_contracts",
                                meta: {
                                    visible_sections: "contract_details"
                                },
                            },
                            {
                                type: "case_overview",
                                meta: {},
                            },
                        ]
                    })
                }
            case "sh_progress_additional_service":
                return {
                        title: getJobTitleForListingServiceJob(job),
                        job: job,
                        routeAction: getGoToListingRouteAction({ 
                            visibleSections: "address",
                            job, 
                        })
                    }
            case "sh_progress_car_sale":
                return {
                        title: getJobTitleForCarSaleJob(job),
                        job: job,
                        routeAction: getGoToListingRouteAction({ 
                            visibleSections: "address",
                            job, 
                        })
                    }
        default:
            return requireExhaustive(job);
    }
}

const getJobTitleForListingServiceJob = (job: TJobListingService) => `${job.service_type === "Unknown" ? "Additional service" : job.service_type}: Progress '${job.status}' section`;

const getJobTitleForCarSaleJob = (job: TJobListingCarSale) => `Car sale: Progress '${job.status}' section`;