import { array, option } from "fp-ts";
import { pipe } from "fp-ts/lib/function";
import React from "react";
import { TFormStatus } from "../../../../../../shared/src/codecs/codec";
import { TError, TErrorCode } from "../../../../../../shared/src/codecs/errors";
import { FrontSpacing } from "../../../../models/FrontSpacing";
import { Padding } from "../../../BuildingBlocks/Padding";
import { SpacingColumn } from "../../../BuildingBlocks/SpacingColumn";
import { SpacingRow } from "../../../BuildingBlocks/SpacingRow";
import { FrontIcon } from "../FrontIcon/FrontIcon";
import FrontParagraphSmall from "../FrontParagraphSmall/FrontParagraphSmall";

export const FrontButtonErrorSwitch = (props: {
    formStatus: TFormStatus,
    validationErrors: TError,
    buttonComponent: JSX.Element,
    errorHeaderText: string,
    // Return Some with the JSX element if you want to return a custom error, or None to let the component render a default error
    errorMap: (errorCodes: Array<TErrorCode>) => option.Option<JSX.Element>,
}): JSX.Element => {
    const formStatusIsError = (): boolean =>
        props.formStatus === "failure" || props.formStatus === "notFound" || props.formStatus === "validationError" ? true : false;

    const getErrorToDisplay = (): JSX.Element =>
        pipe(
            props.errorMap(
                pipe(
                    props.validationErrors,
                    array.map(([errorCode]) => errorCode)
                ),
            ),
            option.fold(
                () => <FrontParagraphSmall>
                    Sorry, something went wrong. Please try again and if the issue persists get in touch with us to help you.
                </FrontParagraphSmall>,
                (errorJsx) => errorJsx,
            )
        );

    return <>
        {/* NO ERRORS - DISPLAY BUTTON */}
        {!formStatusIsError() && props.buttonComponent}

        {/* ERRORS */}
        {formStatusIsError() && <div className="front-button-error-switch">
            <div className="front-button-error-switch__header">
                <SpacingRow
                    spacing={FrontSpacing.SMALL_3}
                    justifyContent="center"
                >
                    <FrontIcon
                        iconName="shield-error"
                        colour="highlights-error-firebrick-4"
                        size="small"
                    />

                    <span>{props.errorHeaderText}</span>
                </SpacingRow>
            </div>

            <div className="front-button-error-switch__body">
                <Padding spacing={FrontSpacing.MEDIUM_1}>
                    <SpacingColumn spacing={FrontSpacing.MEDIUM_1}>
                        {getErrorToDisplay()}
                        
                        <div>{props.buttonComponent}</div>
                    </SpacingColumn>
                </Padding>
            </div>
        </div>}
    </>;
};
