import React from "react";
import CRMInputLabelAndErrorWrapComponent from "../CRMInputLabelAndErrorWrapComponent/CRMInputLabelAndErrorWrapComponent";
import { TCRMFormInputWithoutCodec } from "./CRMFormInputTypes";
import { CRMInputCurrency } from "../CRMInputCurrency/CRMInputCurrency";

export const CRMCurrencyFormInput: TCRMFormInputWithoutCodec<number | null> = (props) => 
<CRMInputLabelAndErrorWrapComponent label={props.label}>
    <CRMInputCurrency
        currencySymbol="£"
        value={props.model}
        onChange={props.onChange}
        onPressEnterKey={() => undefined}
        displayError={props.displayError}
        disabled={props.disabled}
    />
</CRMInputLabelAndErrorWrapComponent>


