import React from "react";
import { useOpenClose } from "../../../hooks/UseOpenClose";
import { Relative } from "../../BuildingBlocks/Relative";
import { CRMDropdownColourFace } from "../../Simple/CRMDropdownColourFace/CRMDropdownColourFace";
import { CRMDeferPopout, TDeferPopoutProps } from "../../CrmDeferPopout/CRMDeferPopout";
import { toDateFormat1 } from "../../../../../shared/src/dateTime";
import { IOption } from "../../../hooks/UseDropdown";
import { Absolute } from "../../BuildingBlocks/Absolute";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { isObject } from "../../../../../shared/src/util";
import { CRMZIndex } from "../../../models/CRMZIndex";

export const CRMDropdownCalendar = <A extends TDeferPopoutProps>(props: A): JSX.Element => {
    const {
        ref,
        isOpen,
        setIsOpen
    } = useOpenClose({});

    const selectedOption: IOption<string> | null =
        typeof props.value === "string" ? {value: props.value, label: toDateFormat1(props.value)}
        : props.value && "type" in props.value && props.value.type === "overdue" ? {value: "overdue", label: "Overdue"}
        : props.value && "type" in props.value && props.value.type === "iso" && props.value.date ? {value: props.value.date, label: toDateFormat1(props.value.date)}
        : null;

    const isAnythingSelected = () =>
        !!(typeof props.value === "string" && props.value
        || props.value && isObject(props.value) && "type" in props.value && props.value.type === "overdue"
        || props.value && isObject(props.value) && "type" in props.value && props.value.type === "iso" && props.value.date);

    const onChange = (value) => {
        setIsOpen(false);
        props.onChange(value);
    };

    return <Relative divRef={ref}>
        {/* DROPDOWN FACE */}
        <CRMDropdownColourFace
            ref={ref}
            isOpen={isOpen}
            selectedOption={selectedOption}
            setIsOpen={setIsOpen}
            isAnythingSelected={isAnythingSelected}
            onKeyboardNavigation={() => undefined}
            onChange={() => undefined}
        />

        {/* POPOUT */}
        {isOpen &&
            <Absolute
                top={CRMSpacing.LARGE}
                zIndex={CRMZIndex.SECOND_Z}
            >
                <CRMDeferPopout
                    {...props}
                    onChange={onChange}
                />
            </Absolute>
        }
    </Relative>;
}