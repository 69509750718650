import React from "react";
import * as Listing3 from "../../../../domain/models/Listing3";
import * as ListingStatus1 from "../../../../domain/models/ListingStatus1";
import CRMCard from "../../components/CRMCardComponent/CRMCardComponent";
import AnchorLink from "../../components/OldFront/Simple/AnchorLinkComponent/AnchorLink";
import { TContainerStateProps } from "../../state/TContainerStateProps";

class CRMPartyViewListingsContainer extends React.Component<React.PropsWithChildren<TContainerStateProps>> {

    areThereAnyListings = (): boolean => this.props.state.activeData.crm.party.listings.response.data.length > 0;

    public render(): JSX.Element {
        return (
            <div className="partyViewListing">
                {this.areThereAnyListings() && this.props.state.activeData.crm.party.listings.response.data.map((listing, key) => (
                        <div key={key} className="partyViewListing__card">
                            <CRMCard
                                title={`${Listing3.getBuildingNameOrNumber(listing)} ${listing.street_name}`}
                            >
                                <div className="partyViewListing__card-body">
                                    <div className="partyViewListing__card-text">
                                        <div className="partyViewListing__status">
                                            {ListingStatus1.fromValueToDisplayName(listing.status)}
                                        </div>
                                        <div className="partyViewListing__link">
                                            <AnchorLink
                                                text={"Go To Listing"}
                                                url={`/crm/listing/${listing.id}`}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </CRMCard>
                        </div>
                    ))
                }
            </div>
        );
    }
}

export default CRMPartyViewListingsContainer;
