import React, { useState, useEffect } from "react";
import { CRMIcon } from "../../CRMIcon/CRMIcon";
import { CRMSpacingRow } from "../../CRMSpacingRow/CRMSpacingRow";
import { pipe } from "fp-ts/lib/function";
import { TRoadblocksForm } from "../../../../../domain/codecs/form/RoadblockForm";
import { CRMJobsRoadblockTable } from "../CRMJobsRoadblockTable/CRMJobsRoadblockTable";
import { TJobsFilter } from "../../../../../domain/codecs/statePartials/JobsPartial";
import { TJobsFormIO } from "../../../../../domain/codecs/formIO/JobsIO";
import { TGroupSetting, getJobsInGroupPartition } from "./CRMJobsDefinitions";


type TJobsOverlayProps = {
    onOpen: () => void;
    
    roadblocksForm: TRoadblocksForm;
    onChangeRoadblocks: (form: TRoadblocksForm) => void;
    onLoadMoreRoadblocks: () => void;

    jobsFilter: TJobsFilter;
    onChangeJobs: (filter: TJobsFilter) => void;
    jobsForm: TJobsFormIO;
    groupingSetting: TGroupSetting;
};

const CRMJobsOverlayComponent = (props: TJobsOverlayProps): JSX.Element => {

    const [isOpen, setIsOpen] = useState(false);

    const jobGroupingJobCount = pipe(
        props.groupingSetting,
        getJobsInGroupPartition(props.jobsForm.output.jobs),
        (grouping) => grouping.right.length
    );
    
    useEffect(
        () => {
            if (isOpen) {
                document.body.style.overflow = "hidden";
            }
            
            if (!isOpen) {
                document.body.style.overflow = "auto";
            }
            
            return () => {
                document.body.style.overflow = "auto";
            }
        },
        [isOpen]
    );

    return (
        <div className={`
            crm-jobs-overlay
            crm-jobs-overlay--${isOpen ? 'open' : 'closed'}
        `}>
            <div className={`crm-jobs-overlay__body`}>                
                {/* [VIEW] - ROADBLOCKS */}
                {isOpen && 
                    <CRMJobsRoadblockTable
                        form={props.roadblocksForm}
                        jobsForm={props.jobsForm}
                        jobsGroupingMode={props.groupingSetting}
                        staffUsers={props.jobsForm.output.all_staff_users}
                        onChangeRoadblocks={props.onChangeRoadblocks}
                        jobsFilter={props.jobsFilter}
                        onChangeJobs={props.onChangeJobs}
                        onLoadMore={props.onLoadMoreRoadblocks}
                    />
                }
            </div>

            {/* [BUTTON] OPEN CLOSE */}
            <div
                className={`
                    crm-jobs-overlay__button
                    crm-jobs-overlay__button--${isOpen ? 'open' : 'closed'}
                    ${props.groupingSetting && !isOpen ? "crm-jobs-overlay__button--grouping" : ""}
                    ${props.groupingSetting && !isOpen && jobGroupingJobCount ? "crm-jobs-overlay__button--grouping-has-jobs" : ""}
                `}
                onClick={() => {
                    setIsOpen(!isOpen);
                    if (!isOpen) {
                        props.onOpen();
                    }
                }}
            >
                <CRMSpacingRow
                    spacing="tiny"
                    justifyContent="center"
                    alignItems="center"
                >
                    <CRMIcon
                        iconName={isOpen ? 'close' : 'jobs'}
                        colour="neutral-0"
                    />

                    {props.groupingSetting && !isOpen && 
                        jobGroupingJobCount
                    }
                </CRMSpacingRow>
            </div>
        </div>
    );
};

export const CRMJobsOverlay = React.memo(CRMJobsOverlayComponent);