import React from "react";
import { CRMEndOfBoxIndicator } from "../CRMEndOfBoxIndicator/CRMEndOfBoxIndicator";
export class CRMGeneralTextBox extends React.Component<React.PropsWithChildren<{}>> {
    public render (): JSX.Element {
        return (
            <div className="crm-general-text-box">
                {this.props.children}
                <CRMEndOfBoxIndicator/>
            </div>
        );
    }
}
