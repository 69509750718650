import React from "react";
import { Icon } from "../OldFront/Simple/IconComponent/Icon";
import { IOption } from "./../../hooks/UseDropdown";

interface IDropdown<A extends string, B extends A> {
    options: Array<IOption<A>>;
    value: B;
    displayError?: boolean;
    name?: string;
    onChange: (value: A) => void;
}

export class Dropdown<A extends string, B extends A> extends React.Component<React.PropsWithChildren<IDropdown<A, B>>> {
    public render (): JSX.Element {
        return (
            <div className="dropdownComponent">
                <select
                    className={"dropdownComponent__select" +
                        (this.props.displayError === true ? " dropdownComponent__select--error" : "")}
                    value={this.props.value}
                    id={this.props.name ? this.props.name : undefined}
                    name={this.props.name ? this.props.name : undefined}
                    onChange={(e) => this.props.onChange(e.currentTarget.value as A)}
                >
                    {this.props.options.map((option) => (
                        <option className="dropdownComponent__option"
                            key={option.value}
                            value={option.value}
                        >
                            {option.label}
                        </option>
                    ))}
                </select>
                <div className="dropdownIcon">
                    <Icon iconName="downArrow" height={2} width={2} type="sloth" />
                </div>
            </div>
        );
    }
}
