import React from "react";
import { FrontSpacing } from "../../../../models/FrontSpacing";
import { SpacingColumn } from "../../../BuildingBlocks/SpacingColumn";
import { FrontOnboardingTab } from "../../Simple/FrontOnboardingTab/FrontOnboardingTab";
import { TOnboardingCaseOverviewTab } from "../FrontOnboardingCaseOverview/FrontOnboardingCaseOverview";
import { FrontFormSectionTitle } from "../../Simple/FrontFormSectionTitle/FrontFormSectionTitle";

export type TFrontOnboardingCaseOverviewCurrentStepProps<A extends string> = {
    tab: TOnboardingCaseOverviewTab<A>;
    isSelected: boolean;
    isMobileView?: boolean;
    onClick: (tab: TOnboardingCaseOverviewTab<A>) =>  void;
};

export const FrontOnboardingCaseOverviewCurrentStep = <A extends string>(props: TFrontOnboardingCaseOverviewCurrentStepProps<A>): JSX.Element => (
    
    <SpacingColumn spacing={FrontSpacing.SMALL_3}>
        
        {/* TITLE */}
        <FrontFormSectionTitle>
            We need you to:
        </FrontFormSectionTitle>
        
        {/* TABS */}
        <SpacingColumn spacing={FrontSpacing.SMALL_2}>
            <FrontOnboardingTab
                {...props.tab}
                isSelected={props.isMobileView ? false : props.isSelected}
                mode={props.isMobileView ? "filled" : "border"}
                onClick={() => props.onClick(props.tab)}
            />
        </SpacingColumn>

    </SpacingColumn>
);