import React, { useState } from "react";
import { FrontColors } from "../../../models/FrontColors";
import { FrontSpacing } from "../../../models/FrontSpacing";
import { Flex } from "../../BuildingBlocks/Flex";
import { FontQuicksand } from "../../BuildingBlocks/FontQuicksand";
import { FontSize } from "../../BuildingBlocks/FontSize";
import { MaxWidth } from "../../BuildingBlocks/MaxWidth";
import { SpacingColumn } from "../../BuildingBlocks/SpacingColumn";
import { TextAlign } from "../../BuildingBlocks/TextAlign";
import { TextColor } from "../../BuildingBlocks/TextColor";
import { Width } from "../../BuildingBlocks/Width";
import { WeightSemiBold } from "../../WeightSemiBold/WeightSemiBold";
import { FPInput } from "../Complex/FPInput/FPInput";
import { FPLandingTitle } from "../Simple/FPLandingTitle/FPLandingTitle";
import { FPSimpleLogo } from "../Simple/FPSimpleLogo/FPSimpleLogo";
import { TPublicPropertyReportAddressSearchForm } from "../../../../../domain/codecs/form/PublicPropertyReportForm";
import { onChangeForm } from "../../../../../shared/src/codecs/types/form";
import { SpacingRow } from "../../BuildingBlocks/SpacingRow";
import { FPInputButton } from "../Complex/FPInputButton/FPInputButton";
import { doesErrorKeyExist } from "../../../../../shared/src/codecs/errors";
import { FPError } from "../Complex/FPError/FPError";
import { FPCheckbox } from "../Complex/FPCheckbox/FPCheckbox";

export const FPPropertyReportExecutorOrBeneficiary = (props: {
    form: TPublicPropertyReportAddressSearchForm,
    onChange: (form: TPublicPropertyReportAddressSearchForm) => void,
    onSubmit: () => void,
}): JSX.Element => {
    const [hasErrors, setHasErrors] = useState<boolean>(false);

    const firstName = props.form.edited.executor_or_beneficiary.first_name;

    const lastName = props.form.edited.executor_or_beneficiary.last_name;

    const isExecutor = props.form.edited.executor_or_beneficiary.is_executor;

    const email = props.form.edited.executor_or_beneficiary.email;

    const phoneNumber = props.form.edited.executor_or_beneficiary.phone_number;

    const firstNameHasError =
        hasErrors && firstName.trim() === ""
        || doesErrorKeyExist("edited.executor_or_beneficiary.first_name", props.form.validationErrors);

    const lastNameHasError =
        hasErrors && lastName.trim() === ""
        || doesErrorKeyExist("edited.executor_or_beneficiary.last_name", props.form.validationErrors);

    const emailHasError =
        (hasErrors && (email === null || email.trim() === ""))
        || doesErrorKeyExist("edited.executor_or_beneficiary.email", props.form.validationErrors);

    const phoneNumberHasError =
        (hasErrors && (phoneNumber === null || phoneNumber.trim() === ""))
        || doesErrorKeyExist("edited.executor_or_beneficiary.phone_number", props.form.validationErrors);

    const errorMessages =
        [
            ...(firstNameHasError ? ["Please enter their first name"] : []),
            ...(lastNameHasError ? ["Please enter their last name"] : []),
            ...(emailHasError ? ["Their email address does not look valid"] : []),
            ...(phoneNumberHasError ? ["Their phone number does not look valid"] : []),
        ];

    const isExecutorLabel =
        firstName
            ? `${firstName} is an Executor`
            : "They are an Executor"

    const onChange = (key: keyof typeof props.form.edited.executor_or_beneficiary) =>
        (value: string | null | boolean) =>
            onChangeForm(props.form, props.onChange)("executor_or_beneficiary")({
                ...props.form.edited.executor_or_beneficiary,
                [key]: value,
            });

    const onSubmit = () => {
        if (
            firstName.trim() === ""
            || lastName.trim() === ""
            || email === null
            || email.trim() === ""
            || phoneNumber === null
            || phoneNumber.trim() === ""
        ) {
            setHasErrors(true);
        } else if (props.form.status !== "submitting") {
            setHasErrors(false);
            props.onSubmit();
        }
    };

    return <SpacingColumn
        spacing={FrontSpacing.LARGE_1}
        justifyContent="center"
    >
        {/* LOGO */}
        <Flex justifyContent="center">
            <FPSimpleLogo width="95px" />
        </Flex>

        {/* HEADER */}
        <Flex justifyContent="center">
            <MaxWidth width="700px">
                <FPLandingTitle
                    size={60}
                    color={FrontColors.NEUTRAL_18}
                    align="center"
                >
                    Please provide your client's contact details.
                </FPLandingTitle>
            </MaxWidth>
        </Flex>

        {/* SUBTITLE */}
        <Flex justifyContent="center">
            <MaxWidth width="700px">
                <Flex justifyContent="center">
                    <MaxWidth width="700px">
                        <FontQuicksand>
                            <FontSize
                                size={20}
                                lineHeight={1.2}
                            >
                                <WeightSemiBold>
                                    <TextColor color={FrontColors.NEUTRAL_18}>
                                        You will receive a copy of the valuation report when it is ready. We also want to provide this to the Executor/Beneficiary directly for their records.
                                    </TextColor>
                                </WeightSemiBold>
                            </FontSize>
                        </FontQuicksand>
                    </MaxWidth>
                </Flex>
            </MaxWidth>
        </Flex>

        {/* INPUTS */}
        <Flex justifyContent="center">
            <SpacingRow
                spacing={FrontSpacing.MEDIUM_1}
                alignItems="flex-start"
            >
                {/* INPUTS LEFT */}
                <Width width="335px">
                    <SpacingColumn spacing={FrontSpacing.SMALL_3}>
                        {/* FIRST NAME */}
                        <FPInput
                            placeholder="Their first name"
                            value={firstName}
                            coloursInverted={true}
                            displayError={firstNameHasError}
                            onChange={onChange("first_name")}
                            onEnter={onSubmit}
                        />

                        {/* LAST NAME */}
                        <FPInput
                            placeholder="Their last name"
                            value={lastName}
                            coloursInverted={true}
                            displayError={lastNameHasError}
                            onChange={onChange("last_name")}
                            onEnter={onSubmit}
                        />

                        {/* ERRORS */}
                        {errorMessages.length > 0 &&
                            <FPError
                                errorMessages={errorMessages}
                            />
                        }
                    </SpacingColumn>
                </Width>

                {/* INPUTS RIGHT */}
                <Width width="335px">
                    <SpacingColumn spacing={FrontSpacing.SMALL_3}>
                        {/* IS EXECUTOR */}
                        <FPCheckbox
                            label={isExecutorLabel}
                            value={isExecutor}
                            coloursInverted={true}
                            onChange={onChange("is_executor")}
                        />

                        {/* EMAIL */}
                        <FPInput
                            placeholder="Their email"
                            value={email || ""}
                            coloursInverted={true}
                            displayError={emailHasError}
                            onChange={(value) =>
                                onChange("email")(value || null)
                            }
                            onEnter={onSubmit}
                        />

                        <SpacingRow
                            justifyContent="start"
                            alignItems="center"
                            childSize="1fr min-content"
                        >
                            {/* PHONE */}
                            <FPInput
                                flatEdge={true}
                                placeholder="Their phone number"
                                value={phoneNumber || ""}
                                coloursInverted={true}
                                displayError={phoneNumberHasError}
                                onChange={(value) =>
                                    onChange("phone_number")(value || null)
                                }
                                onEnter={onSubmit}
                            />

                            {/* SUBMIT */}
                            <FPInputButton
                                coloursInverted={true}
                                iconName="chevron-right"
                                onClick={onSubmit}
                            />
                        </SpacingRow>
                    </SpacingColumn>
                </Width>
            </SpacingRow>
        </Flex>
    </SpacingColumn>;
};
