import React from "react";
import { FrontFormErrorComponent } from "./../../Simple/FrontFormErrorComponent/FrontFormErrorComponent";
import { WeightMedium } from "./../../../WeightMedium/WeightMedium";
import { FrontSpacing } from "../../../../models/FrontSpacing";
import { SpacingColumn } from "../../../BuildingBlocks/SpacingColumn";
import { MaxWidth } from "../../../BuildingBlocks/MaxWidth";
import { FrontInfoBubble } from "../FrontInfoBubble/FrontInfoBubble";
import { FrontColors } from "../../../../models/FrontColors";
interface IFrontInputLabelAndErrorWrapComponent {
    displayError?: boolean;
    errorMessage?: string;
    label?: string | JSX.Element;
    guidance?: string | JSX.Element;
}

export class FrontInputLabelAndErrorWrapComponent extends React.Component<React.PropsWithChildren<IFrontInputLabelAndErrorWrapComponent>> {
    public render (): JSX.Element {
        return (
            <div className="front-input-label-and-error-wrap">
                {this.props.label ?
                    <div className="front-input-label-and-error-wrap__label">
                        <span className="front-input-label-and-error-wrap__labelText">
                            <WeightMedium>{this.props.label}</WeightMedium>
                        </span>
                    </div>
                    : ""
                }

                <SpacingColumn spacing={FrontSpacing.MEDIUM_1}>
                    {this.props.guidance &&
                        <div style={{marginLeft: FrontSpacing.SMALL_3}}>
                            <MaxWidth width="500px">
                                <FrontInfoBubble color={FrontColors.PRIMARY_22}>
                                    {this.props.guidance}
                                </FrontInfoBubble>
                            </MaxWidth>
                        </div>
                    }

                    <div className="front-input-label-and-error-wrap__formElement">
                        {this.props.children}
                    </div>

                </SpacingColumn>

                {this.props.displayError && 
                    <FrontFormErrorComponent
                        errorMessage={this.props.errorMessage || ""}
                    />
                }
            </div>
        );
    }
}

export default FrontInputLabelAndErrorWrapComponent;
