import React from "react";
import { TCRMMainColours } from "../../models/TCRMMainColours";

type TCardLabelProps = {
    borderRounding?: "left" | "right";
    color: TCRMMainColours;
};

export const CRMCardLabel = (props: TCardLabelProps): JSX.Element => (
        <div
            className={`
                crm-card-label
                crm-card-label--corner-${props.borderRounding || "right"}
                crm-card-label--${props.color}
            `}
        >
        </div>
    );
