import * as t from "io-ts";
import * as PriceQualifier1 from "./PriceQualifier1";
import * as Tenure1 from "./Tenure1";
import * as ListingAmenity1 from "./ListingAmenity1";
import * as Country2 from "./Country2";
import * as regexes from "../../shared/src/regexes";
import * as ListingCondition1 from "./ListingCondition1";
import * as ListingReasonForSale1 from "./ListingReasonForSale1";
import * as ListingPropertyType1 from "./ListingPropertyType1";
import * as ListingChainStatus1 from "./ListingChainStatus1";
import { option } from "fp-ts";
import * as ListingValuationType from "./ListingValuationType";
import * as ListingKeyHolder1 from "./ListingKeyHolder1";
import * as ListingViewingsConductedBy1 from "./ListingViewingsConductedBy1";
import { TValidationError } from "../../shared/src/validation/Error";
import { all } from "../../shared/src/validation/compose/all";
import { inOrder } from "../../shared/src/validation/compose/inOrder";
import { or } from "../../shared/src/validation/compose/or";
import { object } from "../../shared/src/validation/create/object";
import { isEnum } from "../../shared/src/validation/create/isEnum";
import { regex } from "../../shared/src/validation/create/regex";
import { required } from "../../shared/src/validation/basic/required";
import { string } from "../../shared/src/validation/basic/string";
import { uuid } from "../../shared/src/validation/basic/uuid";
import { nonEmptyString } from "../../shared/src/validation/basic/nonEmptyString";
import { integerPositive } from "../../shared/src/validation/basic/integerPositive";
import { nullable } from "../../shared/src/validation/basic/nullable";
import { decimal } from "../../shared/src/validation/basic/decimal";
import { array } from "../../shared/src/validation/create/array";
import { boolean } from "../../shared/src/validation/basic/boolean";
import { positiveDecimal } from "../../shared/src/validation/basic/postitiveDecimal";
import { stringIsoUtcDate } from "../../shared/src/validation/basic/stringIsoUtcDate";
import { stringIsoUtcDateTime } from "../../shared/src/validation/basic/stringIsoUtcDateTime";
import { integer } from "../../shared/src/validation/basic/integer";
import { objectCallback } from "../../shared/src/validation/create/objectCallback";
import { validationErrorCodeConstants } from "../../shared/src/validation/ErrorCode";

export const codec = t.intersection([
    t.type({
        sellers_party_id: t.string,
    }),
    t.partial({
        street_name: t.string,
        postcode: t.union([t.string, t.null]),
        county: t.string,
        city_town: t.string,
        country: Country2.codec,
        has_introducer: t.boolean,
        introducer_id: t.union([t.string, t.null]),
        building_name: t.string,
        building_number: t.string,
        sub_building_name: t.string,
        sub_building_number: t.string,
        locality: t.string,
        district: t.string,
        title_number: t.string,
        uprn: t.string,
        latitude: t.number,
        longitude: t.number,
        amenities: t.array(ListingAmenity1.codec),
        tenure: Tenure1.codec,
        price_qualifier: PriceQualifier1.codec,
        price: t.number,
        description: t.string,
        condition: ListingCondition1.codec,
        reason_for_sale: ListingReasonForSale1.codec,
        admin_notes: t.string,
        property_type: ListingPropertyType1.codec,
        bedroom_count: t.union([t.null, t.number]),
        bathroom_count: t.union([t.null, t.number]),
        property_summary: t.string,
        property_advantages: t.array(t.string),
        property_disadvantages: t.array(t.string),
        sellers_view_on_offers: t.string,
        chain_status: ListingChainStatus1.codec,
        renovation_cost: t.number,
        estimated_resale_price: t.number,
        estimated_rental_price_pcm: t.number,
        video_tour_url: t.string,
        video_tour_required_before_viewing: t.boolean,
        lease_length_remaining_years: t.number,
        lease_service_charge_pcy: t.number,
        lease_ground_rent_pcy: t.number,
        competitor_agent_instructed_at: t.string,
        valuation_type: ListingValuationType.codec,
        valuation_date: t.union([t.null, t.string]),
        seller_latest_desired_sell_date: t.string,
        seller_minimum_desired_sell_value: t.union([t.null, t.number]),
        seller_specfic_agent_needs: t.string,
        significant_renovations_made: t.string,
        competitor_agent_name: t.string,
        competitor_agent_market_price: t.number,
        competitor_agent_listing_status: t.string,
        main_entrance_floor_level: t.union([t.null, t.number]),
        above_ground_floors_count: t.union([t.null, t.number]),
        bellow_ground_floors_count: t.union([t.null, t.number]),
        attics_count: t.union([t.null, t.number]),
        property_occupied: t.union([t.boolean, t.null]),
        viewings_can_be_booked_from: t.union([t.null, t.string]),
        viewings_conducted_by: ListingViewingsConductedBy1.codec,
        key_holder: ListingKeyHolder1.codec,
        preferred_viewing_times: t.string,
        key_holder_user_id: t.union([t.null, t.string]),
        occupier_user_id: t.union([t.null, t.string]),
        viewings_conducted_by_user_id: t.union([t.null, t.string]),
        date_of_death: t.union([t.null, t.string]),
        referrer_name: t.string,
        referrer_firm: t.string,
        referrer_email: t.union([t.null, t.string]),
        referrer_phone_number: t.union([t.null, t.string]),
        seller_conveyancer_organisation_name: t.string,
        seller_conveyancer_name: t.string,
        seller_conveyancer_phone_number: t.union([t.null, t.string]),
        seller_conveyancer_email_address: t.union([t.null, t.string]),
        seller_conveyancer_address: t.string,
        seller_company_name: t.string,
        aventria_reference: t.string,
        introducer_external_reference_id: t.string,
        introducer_notes: t.string,
    }),
]);

export type T = t.TypeOf<typeof codec>;

export const validator = (userInput: unknown): option.Option<TValidationError> => all(
    object({
        sellers_party_id: inOrder(
            required,
            string,
            uuid,
        ),
        description: nonEmptyString,
        price: integerPositive,
        price_qualifier: inOrder(
            nonEmptyString,
            isEnum(PriceQualifier1.values),
        ),
        building_name: nonEmptyString,
        building_number: nonEmptyString,
        sub_building_name: string,
        sub_building_number: string,
        street_name: nonEmptyString,
        postcode: or(
            inOrder(
                nonEmptyString,
                regex(regexes.constants.POSTCODE_REGEX)),
            nullable,
        ),
        county: nonEmptyString,
        locality: string,
        district: string,
        city_town: nonEmptyString,
        country: inOrder(
            nonEmptyString,
            isEnum(Country2.values)),
        tenure: inOrder(
            nonEmptyString,
            isEnum(Tenure1.values)),
        title_number: string,
        uprn: string,
        latitude: decimal,
        longitude: decimal,
        amenities: array(ListingAmenity1.validator),
        has_introducer: boolean,
        introducer_id: or(
            uuid,
            nullable,
        ),
        condition: inOrder(
            nonEmptyString,
            isEnum(ListingCondition1.values),
        ),
        reason_for_sale: inOrder(
            nonEmptyString,
            isEnum(ListingReasonForSale1.values),
        ),
        admin_notes: string,
        property_type: isEnum(ListingPropertyType1.values),
        bedroom_count: or(
            integerPositive,
            nullable,
        ),
        bathroom_count: or(
            integerPositive,
            nullable,
        ),
        property_summary: string,
        property_advantages: array(nonEmptyString),
        property_disadvantages: array(nonEmptyString),
        sellers_view_on_offers: string,
        chain_status: isEnum(ListingChainStatus1.values),
        renovation_cost: integerPositive,
        estimated_resale_price: integerPositive,
        estimated_rental_price_pcm: integerPositive,
        video_tour_url: string,
        video_tour_required_before_viewing: boolean,
        lease_length_remaining_years: integerPositive,
        lease_service_charge_pcy: positiveDecimal,
        lease_ground_rent_pcy: positiveDecimal,
        competitor_agent_instructed_at: stringIsoUtcDate,
        valuation_type: isEnum(ListingValuationType.values),
        valuation_date: or(
            stringIsoUtcDateTime,
            nullable,
        ),
        seller_latest_desired_sell_date: stringIsoUtcDate,
        seller_minimum_desired_sell_value: or(
            integerPositive,
            nullable,
        ),
        seller_specfic_agent_needs: string,
        significant_renovations_made: string,
        competitor_agent_name: string,
        competitor_agent_market_price: integerPositive,
        competitor_agent_listing_status: string,
        main_entrance_floor_level: or(
            integer,
            nullable
        ),
        above_ground_floors_count: or(
            integerPositive,
            nullable
        ),
        bellow_ground_floors_count: or(
            integerPositive,
            nullable
        ),
        attics_count: or(
            integerPositive,
            nullable
        ),
        property_occupied: or(
            boolean,
            nullable
        ),
        viewings_can_be_booked_from: or(
            stringIsoUtcDateTime,
            nullable,
        ),
        viewings_conducted_by: ListingViewingsConductedBy1.validator,
        key_holder: ListingKeyHolder1.validator,
        preferred_viewing_times: string,
        key_holder_user_id: or(
            string,
            uuid,
            nullable,
        ),
        occupier_user_id: or(
            string,
            uuid,
            nullable,
        ),
        viewings_conducted_by_user_id: or(
            string,
            uuid,
            nullable,
        ),
        date_of_death: or(
            inOrder(
                string,
                stringIsoUtcDate
            ),
            nullable,
        ),
        referrer_name: string,
        referrer_firm: string,
        referrer_email: or(
            inOrder(
                string,
                regex(regexes.constants.EMAIL_REGEX),
            ),
            nullable,
        ),
        referrer_phone_number: or(
            inOrder(
                string,
                regex(regexes.constants.MOBILE_PHONE_REGEX),
            ),
            nullable,
        ),
        seller_conveyancer_organisation_name: string,
        seller_conveyancer_name: string,
        seller_conveyancer_phone_number: or(
            inOrder(
                string,
                regex(regexes.constants.MOBILE_PHONE_REGEX),
            ),
            nullable,
        ),
        seller_conveyancer_email_address: or(
            inOrder(
                string,
                regex(regexes.constants.EMAIL_REGEX),
            ),
            nullable,
        ),
        seller_conveyancer_address: string,
        seller_company_name: string,
        aventria_reference: string,
        introducer_external_reference_id: string,
        introducer_notes: string,
    }),
    // Check that both price and priceQualifier must be added together or not at all
    objectCallback((val: Record<string, unknown>): option.Option<TValidationError> =>
        (val.price === undefined && val.price_qualifier !== undefined) || (val.price !== undefined && val.price_qualifier === undefined)
            ? option.some([
                [validationErrorCodeConstants.REQUIRED_CONDITIONALLY_VALIDATION, "price"],
                [validationErrorCodeConstants.REQUIRED_CONDITIONALLY_VALIDATION, "price_qualifier"],
            ])
            : option.none
    ),
)(userInput);
