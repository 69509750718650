import * as t from "io-ts";
import * as Enquiry2 from "./Enquiry2";
import * as PartyC8 from "./Party8";

export const codec = t.type({
    ...Enquiry2.codec.props,
    party: PartyC8.codec,
});

export type T = t.TypeOf<typeof codec>;
