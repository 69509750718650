import React from "react";
import { TQuoteRateLiveRate } from "../../../../../domain/codecs/form/QuoteRateForm";
import { useHover } from "../../../hooks/useHover";
import { CRMColors } from "../../../models/CRMColors";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { Background } from "../../BuildingBlocks/Background";
import { Padding } from "../../BuildingBlocks/Padding";
import { SpacingColumn } from "../../BuildingBlocks/SpacingColumn";
import { SpacingRow } from "../../BuildingBlocks/SpacingRow";
import { CRMIcon } from "../../CRMIcon/CRMIcon";
import { CRMQuoteRateCardDetailOverlapping } from "../CRMQuoteRateCardDetail/CRMQuoteRateCardDetailOverlapping";

export const CRMLiveQuoteRateCardOverlapping = (props: React.PropsWithChildren<{
    form: TQuoteRateLiveRate,
}>): JSX.Element => {
    const [ref, isHovering] = useHover<HTMLDivElement>({});

    return <Background
        divRef={ref}
        borderRadius="3px"
        color={CRMColors.NEUTRAL_PAPER}
    >
        <SpacingColumn spacing={CRMSpacing.MEDIUM}>
            <SpacingRow
                spacing={CRMSpacing.SMALL}
                justifyContent="start"
                alignItems="center"
            >
                {/* BULLET ICON */}
                <CRMIcon
                    iconName="reply-reverse"
                    size="small"
                    colour="neutral-ink"
                />

                {/* TYPE ICON */}
                <Background
                    borderRadius="5px"
                    color={CRMColors.NEUTRAL_10}
                >
                    <Padding spacing={CRMSpacing.TINY}>
                        <CRMIcon
                            iconName="done-all"
                            size="extra-medium"
                            colour="neutral-4"
                        />
                    </Padding>
                </Background>

                {/* DETAIL */}
                <CRMQuoteRateCardDetailOverlapping
                    form={props.form}
                />
            </SpacingRow>
        </SpacingColumn>
    </Background>;
}
