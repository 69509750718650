import React from "react";
import CRMInputGeneralComponent from "../CRMInputs/CRMInputGeneralComponent/CRMInputGeneralComponent";

interface ICRMInputDecimalComponent {
    displayError: boolean;
    placeholder?: string;
    value: number | null;
    disabled?: boolean;
    allowNegative?: boolean;
    onChange: (value: number | null) => void;
}

type TCRMInputDecimalState = {
    currentVal: string;
}

const isStringDecimalEqualToDecimal = (stringDecimal: string, decimal: number | null) => 
    (
        stringDecimal === ""
        && decimal === null
    )
    || stringDecimal === "-" // User is starting to input a negative number
    || parseFloat(stringDecimal) === decimal;

class CRMInputDecimalComponent extends React.Component<React.PropsWithChildren<ICRMInputDecimalComponent>, TCRMInputDecimalState> {
    constructor(props: ICRMInputDecimalComponent) {
        super(props);
        this.state = {
            currentVal: this.props.value !== null ? this.props.value.toString() : ""
        };
    }

    public regex = () =>
        this.props.allowNegative
            ? /^-?[0-9\.]*$/
            : /^[0-9\.]*$/;

    public componentDidUpdate() {
        if (! isStringDecimalEqualToDecimal(this.state.currentVal, this.props.value)) {
            this.setState({currentVal: this.props.value !== null ? this.props.value.toString() : ""});
        }
    }

    public render (): JSX.Element {
        return (
            <CRMInputGeneralComponent
                inputType="text"
                displayError={this.props.displayError}
                placeholder={this.props.placeholder}
                value={this.state.currentVal}
                disabled={this.props.disabled}
                onChange={(changedVal) => {
                    if (this.regex().test(changedVal)) {
                        this.setState({currentVal: changedVal});

                        if(! isStringDecimalEqualToDecimal(changedVal, this.props.value)) {
                            this.props.onChange(changedVal === "" ? null : parseFloat(changedVal));
                        }
                    }  
                }}
            />
        );
    }
}

export default CRMInputDecimalComponent;
