import { array, option } from "fp-ts";
import { pipe } from "fp-ts/lib/function";
import React from "react";
import { CRMColors } from "../../../models/CRMColors";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { BorderBetween } from "../../BuildingBlocks/BorderBetween";
import { MinWidth } from "../../BuildingBlocks/MinWidth";
import { Padding } from "../../BuildingBlocks/Padding";
import { CRMCardStackingInput } from "../../Simple/CRMCardStackingInput/CRMCardStackingInput";
import { AddNewNumber } from "./AddNewNumber";
import { Number } from "./Number";

export type TPhoneNumber = {
    id: string,
    phone_number: string | null,
    primary_number: boolean,
    is_verified: boolean,
    has_error: boolean,
};

export const CRMInputPhoneNumberCardStack = (props: React.PropsWithChildren<{
    phoneNumbers: Array<TPhoneNumber>,
    onAddNewNumber: () => void,
    onPhoneNumberChange: (id: string, phoneNumber: string | null) => void,
    onMakePrimary: (id: string) => void,
    onDelete: (id: string) => void,
}>): JSX.Element => {
    const cardCount = props.phoneNumbers.length;

    const facePhoneNumber =
        pipe(
            props.phoneNumbers,
            array.findFirst(({primary_number}) => primary_number),
        );

    const face =
        pipe(
            facePhoneNumber,
            option.fold(
                () => <AddNewNumber
                    mode="face"
                    onClick={props.onAddNewNumber}
                />,
                (number) => <Number
                    mode="face"
                    number={number}
                    onPhoneNumberChange={props.onPhoneNumberChange}
                    onMakePrimary={props.onMakePrimary}
                    onDelete={props.onDelete}
                />
            )
        );

    const hasErrors =
        pipe(
            props.phoneNumbers,
            array.filter(({has_error}) => has_error),
            (a) => a.length > 0,
        );

    return <CRMCardStackingInput
        cardCount={cardCount}
        face={face}
        displayError={hasErrors}
    >
        <Padding
            type="vertical"
            spacing={CRMSpacing.TINY}
        >
            <MinWidth width="100%">
                {/* LIST OF PHONE NUMBERS */}
                {props.phoneNumbers.map((number, i) =>
                    <BorderBetween
                        key={i}
                        borderColour={CRMColors.NEUTRAL_4}
                        spacing={CRMSpacing.TINY}
                    >
                        <Padding
                            type="horizontal"
                            spacing={CRMSpacing.SMALL}
                        >
                            <Number
                                mode="list"
                                number={number}
                                onPhoneNumberChange={props.onPhoneNumberChange}
                                onMakePrimary={props.onMakePrimary}
                                onDelete={props.onDelete}
                            />
                        </Padding>
                    </BorderBetween>
                )}

                {/* ADD NEW NUMBER */}
                <Padding
                    type="horizontal"
                    spacing={CRMSpacing.SMALL}
                >
                    <AddNewNumber
                        mode="list"
                        onClick={props.onAddNewNumber}
                    />
                </Padding>
            </MinWidth>
        </Padding>
    </CRMCardStackingInput>
};
