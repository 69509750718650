import React from "react";
import * as TCurrency from "../../../../../../domain/models/TCurrency";
import { array } from "fp-ts";
import { WeightMedium } from "../../../WeightMedium/WeightMedium";
import { WeightBold } from "../../../WeightBold/WeightBold";
import FrontParagraphTiny from "./../../Simple/FrontParagraphTiny/FrontParagraphTiny";
import { FrontTextColour } from "../../../FrontTextColour/FrontTextColour";
import { pipe } from "fp-ts/lib/function";
import { TOffer3NewDefault } from "../../../../../../domain/codecs/Offer";

type TFrontOffersTable = {
    offers: Array<TOffer3NewDefault>;
};

export class FrontOffersTable extends React.Component<React.PropsWithChildren<TFrontOffersTable>> {
    public render (): JSX.Element {
        return (
            <div className={"front-offers-table"} role="table">
                {/* HEADER */}
                <div role="row" className={"front-offers-table__row front-offers-table__row--header"}>
                    <div role="columnheader" className={"front-offers-table__cell"}><WeightBold>Amount</WeightBold></div>
                    <div role="columnheader" className={"front-offers-table__cell"}><WeightBold>Completion time</WeightBold></div>
                    <div role="columnheader" className={"front-offers-table__cell"}><WeightBold>Fall through risk</WeightBold></div>
                </div>
                <div className={"front-offers-table__horizontal-rule front-offers-table__horizontal-rule--header"}></div>
                {pipe(
                    this.props.offers,
                    array.mapWithIndex((index, offer) => (
                        <React.Fragment key={index}>
                            {index !== 0 && <div className={"front-offers-table__horizontal-rule"}></div>}
                            {/* ROW */}
                            <div role="row" className={`front-offers-table__row ${offer.is_own_offer ? "front-offers-table__row--highlighted" : ""}`}>
                                {/* CELL */}
                                <div role="cell" className={"front-offers-table__cell"}>
                                    <WeightBold>{TCurrency.fromNumber(offer.amount)}</WeightBold>
                                    {offer.is_own_offer && <FrontParagraphTiny>
                                        <FrontTextColour colour="neutral-18">
                                            &nbsp;(yours)
                                        </FrontTextColour>
                                    </FrontParagraphTiny>}
                                </div>
                                {/* CELL */}
                                <div role="cell" className={"front-offers-table__cell"}>
                                    <WeightMedium>{offer.estimated_time_to_complete}</WeightMedium>
                                </div>
                                {/* CELL */}
                                <div role="cell" className={"front-offers-table__cell"}>
                                    <WeightMedium>{offer.risk_of_fall_through}</WeightMedium>
                                </div>
                            </div>
                        </React.Fragment>
                    ))
                )}
            </div>
        );
    }
}
