import React from "react";
import { FrontColors } from "../../../../models/FrontColors";
import { FrontSpacing } from "../../../../models/FrontSpacing";
import { Background } from "../../../BuildingBlocks/Background";
import { SpacingRow } from "../../../BuildingBlocks/SpacingRow";
import { FrontIcon, TIcon } from "../../../Front/Simple/FrontIcon/FrontIcon";
import { Text } from "../../../BuildingBlocks/Text";
import { FontRobotoSlab } from "../../../BuildingBlocks/FontRobotoSlab";
import { CursorPointer } from "../../../CursorPointer/CursorPointer";
import { FrontFontSizes } from "../../../../models/FrontFontSizes";

export const FPCheckbox = (props: React.PropsWithChildren<{
    coloursInverted?: boolean;
    flatEdge?: boolean;
    value: boolean;
    label: string;
    displayError?: boolean;
    onChange: (value: boolean) => void;
}>): JSX.Element => {
    const coloursInverted = props.coloursInverted || false;

    const flatEdge = props.flatEdge || false;

    const displayError = props.displayError || false;

    const inputBackgroundColour =
        coloursInverted
            ? FrontColors.NEUTRAL_18
            : FrontColors.PROBATE_0;

    const borderColour =
        coloursInverted
            ? FrontColors.NEUTRAL_18
            : FrontColors.PROBATE_0;

    const inputColour =
        displayError ? FrontColors.ERROR_FIREBRICK_10
        : coloursInverted ? FrontColors.NEUTRAL_0
        : FrontColors.NEUTRAL_13;

    const borderRadius =
        flatEdge
            ? "5px 0px 0px 5px"
            : "5px";

    const iconName: TIcon =
        props.value
            ? "checkbox-checked"
            : "checkbox-unchecked";

    return <CursorPointer onClick={() => props.onChange(!props.value)}>
        <Background
            display="flex"
            borderRadius={borderRadius}
            borderDefinition={`solid 2px ${borderColour}`}
            color={inputBackgroundColour}
            height="100%"
            minHeight="48px"
            padding={`0px ${FrontSpacing.SMALL_3}`}
            alignItems="center"
        >
            <SpacingRow
                justifyContent="start"
                alignItems="center"
                spacing={FrontSpacing.SMALL_3}
            >
                {/* CHECKBOX */}
                <FrontIcon
                    iconName={iconName}
                    size="22px"
                    colour={inputColour}
                />

                {/* LABEL */}
                <FontRobotoSlab>
                    <Text
                        size={FrontFontSizes.SMALL}
                        lineHeight={1.5}
                    >
                        {props.label}
                    </Text>
                </FontRobotoSlab>
            </SpacingRow>
        </Background>
    </CursorPointer>;
};
