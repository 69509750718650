import React from "react";
import Page from "../../components/PageComponent/Page";
import { FrontSpacer } from "../../components/FrontSpacer/FrontSpacer";
import FrontTitleMinor from "../../components/Front/Simple/FrontTitleMinor/FrontTitleMinor";
import { FrontTitlePageSection } from "../../components/Front/Simple/FrontPageSectionTitle/FrontTitlePageSection";
import { FrontRow } from "../../components/FrontRow/FrontRow";
import FrontButtonPrimary from "../../components/Front/Simple/FrontButtonPrimary/FrontButtonPrimary";
import { FrontTextColour } from "../../components/FrontTextColour/FrontTextColour";
import { WeightBold } from "../../components/WeightBold/WeightBold";
import { TContainerStateProps } from "../../state/TContainerStateProps";
import { createChangeRouteAction } from "../../state/router/createChangeRouteAction";

class ResentMagicLinkPage extends React.Component<React.PropsWithChildren<TContainerStateProps>> {
    public render(): JSX.Element {
        return (
            <Page
                showLogout={false}
                hiddenLink={`${env.REACT_APP_AUTH_URL}/web/login${
                    this.props.state.routes.queryParams.VIEW_MAGIC_LINK_RESENT.redirect_url
                    ? `?redirect_url=${this.props.state.routes.queryParams.VIEW_MAGIC_LINK_RESENT.redirect_url}` : ""}`}
            >
                <div className="resent-magic-link-page">
                        <FrontTitlePageSection>
                            <FrontTextColour colour={"neutral-2"}>
                                <WeightBold>
                                    Check your email
                                </WeightBold>
                            </FrontTextColour>
                        </FrontTitlePageSection>
                        <FrontSpacer size="medium-1" />
                        <div className="resent-magic-link-page__paragraph-width-container">
                            <FrontTitleMinor>
                                <FrontTextColour colour={"neutral-2"}>
                                    We’ve sent you an email with a magic link to access your account.
                                </FrontTextColour>
                            </FrontTitleMinor>
                        </div>
                        <FrontSpacer size="medium-2" />
                        <FrontRow justifyContent="start">
                            <FrontButtonPrimary
                                label="Enter email again"
                                onClick={() => this.props.dispatch(createChangeRouteAction("VIEW_MAGIC_LINK_RESEND", {}, {redirect_url: this.props.state.routes.queryParams.VIEW_MAGIC_LINK_RESENT.redirect_url}))}
                            />
                        </FrontRow>
                </div>
            </Page>
        );
    }
}

export default ResentMagicLinkPage;
