import React from "react";
import { array, eq } from "fp-ts";
import { array as arrayExt } from "../../../../shared/src/utilByDomainGroupExport";
import { normaliseValueToArray } from "../../../../shared/src/util";
import { useDropdown } from "../../hooks/UseDropdown";
import { useOpenClose } from "../../hooks/UseOpenClose";
import { CRMFontSizes } from "../../models/CRMFontSizes";
import { CRMSpacing } from "../../models/CRMSpacing";
import { CRMColors } from "../../models/CRMColors";
import { FontQuicksand } from "../BuildingBlocks/FontQuicksand";
import { Text } from "../BuildingBlocks/Text";
import { Relative } from "../BuildingBlocks/Relative";
import { SpacingRow } from "../BuildingBlocks/SpacingRow";
import { CRMIcon } from "../CRMIcon/CRMIcon";
import { CRMPageBody } from "../Simple/CRMPageBody/CRMPageBody";
import { CursorPointer } from "../CursorPointer/CursorPointer";
import { CRMDropdownOption } from "../Simple/CRMDropdownOption/CRMDropdownOption";
import { CRMClickCopyWrap } from "../Simple/CRMClickCopyWrap/CRMClickCopyWrap";

type TSubNavProps<T> = {
    selected: T;
    selectedFilters: Array<string>;
    buttons: Array<TSubNavButton<T>>;
    popoutText: JSX.Element | string;
    onChange: (link: T) => void;
    onChangeFilters: (filters: Array<string>) => void;
};

export type TSubNavButton<T> = {
    label: string;
    value: T;
    filters: Array<[string, string]>; // Tuple [value, displayString]
    isDisabled?: boolean;
};



export const CRMFilterNav = <T,>(props: React.PropsWithChildren<TSubNavProps<T>>): JSX.Element => {
    const popoutState = useOpenClose();
    const filterStates = props.buttons.map((button) => 
        useDropdown(
            props.selectedFilters, 
            button.filters.map(([value, label]) => ({ value, label }))
        )
    );

    const isFilterSelected = (value: string): boolean =>
        array.elem(eq.eqString)(value, normaliseValueToArray(props.selectedFilters));

    const toggleSelectedValue = (newValue: string): Array<string> => {
        if (isFilterSelected(newValue)) {
            return arrayExt.deleteValue<string>(newValue, normaliseValueToArray(props.selectedFilters));
        }
        return normaliseValueToArray(props.selectedFilters).concat(newValue);
    };

    const popoutTextLabel = <FontQuicksand>
        <Text 
            size={CRMFontSizes.SMALL}
            color={CRMColors.NEUTRAL_INK}
        >
            {props.popoutText}
        </Text>
    </FontQuicksand>;

    return (
        <div className={`crm-filter-nav`}>
            <CRMPageBody fullViewportWidth={true}>
                <SpacingRow justifyContent="space-between">
                    <SpacingRow spacing={"0px"}>
                        {/* BUTTONS */}
                        {props.buttons.map((button, index) => (
                            <div
                                key={index}
                                ref={filterStates[index].ref}
                            >
                                <Relative>
                                    <SpacingRow>
                                        {/* BUTTON - FACE */}
                                        <div
                                            className={`
                                                crm-filter-nav__button
                                                crm-filter-nav__button--${button.isDisabled ? 'disabled' :  'enabled'}
                                                crm-filter-nav__button--${props.selected === button.value ? 'selected' :  'unselected'}
                                            `}
                                            onClick={() => {
                                                if (!button.isDisabled && button.value !== props.selected) {
                                                    props.onChange(button.value)
                                                }

                                                if (button.value === props.selected) {
                                                    filterStates[index].setIsOpen(!filterStates[index].isOpen);
                                                }
                                            }}
                                        >
                                            {button.label}
                                        </div>
                                        {/* BUTTON - FILTER FACE */}
                                        {props.selected === button.value && button.filters.length > 0 &&
                                            <div
                                                className={`
                                                    crm-filter-nav__filter-button
                                                    crm-filter-nav__filter-button--${filterStates[index].isOpen ? 'open' : 'closed'}
                                                `}
                                                onClick={() => filterStates[index].setIsOpen(!filterStates[index].isOpen)}
                                            >
                                                <CRMIcon
                                                    iconName="filter"
                                                    colour={filterStates[index].isOpen ? "neutral-paper" : "primary-0"}
                                                    size="small"
                                                />
                                                {props.selectedFilters.length > 0 &&
                                                    <div className="crm-filter-nav__filter-count">
                                                        {props.selectedFilters.length}
                                                    </div>
                                                }
                                            </div>
                                        }
                                        {/* BUTTON - FILTER POPOUT */}
                                        {button.filters.length > 0 && filterStates[index].isOpen &&
                                            <div className="crm-filter-nav__filter-popout"> 
                                                {button.filters.map(([filterKey, filterDisplay], key) => (
                                                    <CRMDropdownOption
                                                        key={key}
                                                        hasCheckbox={true}
                                                        isSelected={isFilterSelected(filterKey)}
                                                        label={filterDisplay}
                                                        onClick={() => props.onChangeFilters(toggleSelectedValue(filterKey))}
                                                    />
                                                ))}
                                            </div>
                                        }
                                    </SpacingRow>
                                </Relative>
                            </div>
                        ))}
                    </SpacingRow>

                    {/* SIDE POPOUT BUTTON & POPOUT */}
                    {props.popoutText && 
                        <div ref={popoutState.ref}>
                            {props.children && 
                                <SpacingRow alignItems="center" spacing={CRMSpacing.TINY}>
                                    <SpacingRow alignItems="center" spacing={CRMSpacing.TINY}>
                                        <CRMClickCopyWrap
                                            value={props.popoutText as string}
                                        >
                                            {popoutTextLabel}
                                        </CRMClickCopyWrap>
                                        <CursorPointer onClick={() => popoutState.setIsOpen(!popoutState.isOpen)}>
                                            <CRMIcon
                                                iconName={popoutState.isOpen ? "up-arrow" : "down-arrow"}
                                                colour="neutral-ink"
                                            />
                                        </CursorPointer>
                                    </SpacingRow>
                                    <Relative>
                                        {/* POPOUT */}
                                        {popoutState.isOpen &&
                                            <div className="crm-filter-nav__popout">
                                                {props.children}
                                            </div>
                                        }
                                    </Relative>
                                </SpacingRow>
                            }
                            
                            {!props.children && 
                                <SpacingRow alignItems="center" spacing={CRMSpacing.TINY}>
                                    {popoutTextLabel}
                                </SpacingRow>
                            }
                        </div>
                    }
                </SpacingRow>
            </CRMPageBody>
        </div>
    );
};
