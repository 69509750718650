import React from "react";
import { TContainerStateProps } from "./../../state/TContainerStateProps";
import { CRMTitleSection } from "../../components/CRMTitleSection/CRMTitleSection";
import { CRMSpacer } from "../../components/CRMSpacer/CRMSpacer";
import { CRMAutosaveIndicatorWrap } from "../../components/CRM/CRMAutosaveIndicatorWrap/CRMAutosaveIndicatorWrap";
import { CRMSpacingColumn } from "../../components/CRMSpacingColumn/CRMSpacingColumn";
import { pipe } from "fp-ts/lib/function";
import { array } from "fp-ts";
import { FormStatus_highestPriority } from "../../../../shared/src/codecs/codec";
import { CRMCodecEditForm } from "../../components/CRMCodecEditForm/CRMCodecEditForm";
import { CaseEstateAgentDetails } from "../../../../domain/codecs/CasesContractBlock";

const CRMLegalCaseDetailsEstateAgentDetailsContainer = (props: TContainerStateProps): JSX.Element => (
    <CRMAutosaveIndicatorWrap
        status={pipe(
            [
                props.state.forms.contracts_block.children.details.status,
                ...pipe(
                    props.state.forms.contracts_block.children.properties,
                    array.map((form) => form.status),
                ),
            ],
            FormStatus_highestPriority,
        )}
    >
        <CRMTitleSection>
            Estate Agent Details
        </CRMTitleSection>
        <CRMSpacer size="large" />
        
        <CRMSpacingColumn spacing="medium">

            <CRMCodecEditForm
                codec={CaseEstateAgentDetails}
                model={props.state.forms.contracts_block.children.details.edited}
                validationErrors={props.state.forms.contracts_block.children.details.validationErrors}
                columns={1}
                onChange={(edited) => {
                    props.dispatch({
                        type: "LEGAL_CASE_CONTRACTS_BLOCK_CHANGE",
                        payload: {
                            ...props.state.forms.contracts_block.children.details,
                            edited: {
                                ...props.state.forms.contracts_block.children.details.edited,
                                ...edited,
                            }
                        }
                    })
                }}
            />

        </CRMSpacingColumn>
    </CRMAutosaveIndicatorWrap>
);

export default CRMLegalCaseDetailsEstateAgentDetailsContainer;
