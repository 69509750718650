import { EditorState, Modifier } from "draft-js";
import React, { useEffect, useState } from "react";
import { cycleNumberThroughRange, TCycleRange } from "../../../../hooks/useNumberRangeCycling";
import { TActivatedSuggestionProfile } from "./Types";

type TUseFocusedSuggestionProps<T> = {
    activeProfile: TActivatedSuggestionProfile<T>;
    editorState: Draft.EditorState;
    onChange: (editorState: Draft.EditorState) => void;
}

export const useFocusedSuggestion = <T extends Object>(props: TUseFocusedSuggestionProps<T>) => {
    
    const [focusedSuggestionIndex, setFocusedSuggestionIndex] = useState(0);

    const getRange = (): TCycleRange => [0, props.activeProfile.suggestions.length-1];
    const moveSuggestionFocusBy = (cycleAmount: number) => setFocusedSuggestionIndex(
        cycleNumberThroughRange(getRange(), focusedSuggestionIndex, cycleAmount)
    );

    const focusOnNextSuggestion = () => moveSuggestionFocusBy(+1)
    const focusOnPreviousSuggestion = () => moveSuggestionFocusBy(-1);
    const recalibrateFocusedSuggestion = () => moveSuggestionFocusBy(+0);
    const resetFocusedSuggestion = () => setFocusedSuggestionIndex(0);

    const getFocusedSuggestion = (): T | undefined => props.activeProfile.suggestions[focusedSuggestionIndex];
    const isFocusedOnValidSuggestion = () => getFocusedSuggestion() !== undefined;

    const insertFocusedSuggestion = () => {
        if (isFocusedOnValidSuggestion()) {
            onInsertSuggestion(getFocusedSuggestion() as T, props.activeProfile);
        }
    }

    const onInsertSuggestion = (suggestion: T, activeProfile: TActivatedSuggestionProfile<T>) =>
        props.onChange(getEditorStateWithInsertedSuggestion(suggestion, activeProfile));
    ;

    const getEditorStateWithInsertedSuggestion = <T extends Object>(suggestion: T, profile: TActivatedSuggestionProfile<T>): EditorState => {

        const contentStateWithEntity = props.editorState
            .getCurrentContent()
            .createEntity(profile.name, profile.mutability, suggestion)
        ;
        const selection = props.editorState.getSelection()
            .merge({ 
                anchorOffset: profile.start,
                focusOffset: profile.end
            });
    
        let newContentState = Modifier.replaceText(
            contentStateWithEntity,
            selection,
            profile.format(suggestion),
            undefined,
            contentStateWithEntity.getLastCreatedEntityKey(),
        );
    
        return EditorState.forceSelection(
            EditorState.push(
                props.editorState,
                newContentState,
                'apply-entity'
            ),
            newContentState.getSelectionAfter()
        );
    }

    return {
        focusedSuggestionIndex,
        focusOnNextSuggestion,
        focusOnPreviousSuggestion,
        recalibrateFocusedSuggestion,
        resetFocusedSuggestion,
        getFocusedSuggestion,
        insertFocusedSuggestion,
        onInsertSuggestion,
    }
}