import React, { useState } from "react";
import { CRMPadding } from "../../components/Simple/CRMPadding/CRMPadding";
import { array } from "fp-ts/lib";
import { pipe } from "fp-ts/lib/function";
import CRMListingPerformancePageOffersContainer from "../../containers/CRMListingPerformancePageOffersContainer/CRMListingPerformancePageOffersContainer";
import CRMListingPerformancePageInterestedPartiesWithoutOfferContainer from "../../containers/CRMListingPerformancePageInterestedPartiesWithoutOfferContainer/CRMListingPerformancePageInterestedPartiesWithoutOfferContainer";
import CRMListingPerformancePageSellerSummaryContainer from "../../containers/CRMListingPerformancePageSellerSummaryContainer/CRMListingPerformancePageSellerSummaryContainer";
import CRMListingPerformancePageListingSummaryContainer from "../../containers/CRMListingPerformancePageListingSummaryContainer/CRMListingPerformancePageListingSummaryContainer";
import CRMListingPerformancePageUpcomingViewingsContainer from "../../containers/CRMListingPerformancePageUpcomingViewingsContainer/CRMListingPerformancePageUpcomingViewingsContainer";
import CRMListingPerformancePagePastViewingsContainer from "../../containers/CRMListingPerformancePagePastViewingsContainer/CRMListingPerformancePagePastViewingsContainer";
import { TContainerStateProps } from "../../state/TContainerStateProps";
import { SpacingRow } from "../../components/BuildingBlocks/SpacingRow";
import { CRMSpacing } from "../../models/CRMSpacing";
import { Padding } from "../../components/BuildingBlocks/Padding";
import { Sticky } from "../../components/BuildingBlocks/Sticky";
import { SpacingColumn } from "../../components/BuildingBlocks/SpacingColumn";
import { CRMDropdownTitleComponent } from "../../components/CRMDropdownTitleComponent/CRMDropdownTitleComponent";
import { CRMPageWrap } from "../../components/Simple/CRMPageWrap/CRMPageWrap";
import { CRMPageBody } from "../../components/Simple/CRMPageBody/CRMPageBody";
import { CRMHomesMenuBarContainer } from "../../containers/CRMHomesMenuBarContainer/CRMHomesMenuBarContainer";

type TLastColumnViews = "feedback" | "parties" | "viewings";

const CRMListingPerformancePage = (props: TContainerStateProps): JSX.Element => {

    const [lastColumnView, setLastColumnView] = useState<TLastColumnViews>("feedback");

    return (
        <CRMPageWrap>

                <CRMHomesMenuBarContainer {...props} />

                <CRMPageBody>
                <Padding type="vertical" spacing={CRMSpacing.LARGE}>
                    <SpacingRow
                        alignItems="flex-start"
                        spacing={CRMSpacing.X_LARGE}
                        childSize="3fr 5fr 4fr"
                    >
                        {/* LISTING & SELLER COLUMN */}
                        <Sticky top={CRMSpacing.LARGE}>
                            <SpacingColumn
                                spacing={CRMSpacing.MEDIUM}
                                alignItems="flex-start"
                            >
                                <CRMListingPerformancePageListingSummaryContainer
                                    dispatch={props.dispatch}
                                    state={props.state}
                                />
                                <CRMListingPerformancePageSellerSummaryContainer
                                    dispatch={props.dispatch}
                                    state={props.state}
                                />
                            </SpacingColumn>
                        </Sticky>

                        {/* OFFERS COLUMN */}
                        <CRMListingPerformancePageOffersContainer
                            dispatch={props.dispatch}
                            state={props.state}
                        />

                        {/* VIEWINGS & INTERESTED PARTIES COLUMN */}
                        <SpacingColumn spacing={CRMSpacing.LARGE}>
                            <CRMDropdownTitleComponent<TLastColumnViews, TLastColumnViews>
                                value={lastColumnView}
                                options={[
                                    {
                                        label: "Viewings feedback",
                                        value: "feedback",
                                    },
                                    {
                                        label: "Parties without offer",
                                        value: "parties",
                                    },
                                    {
                                        label: "Upcoming Viewings",
                                        value: "viewings",
                                    },
                                ]}
                                onChange={setLastColumnView}
                            />
                            {lastColumnView === "feedback" &&
                                <CRMListingPerformancePagePastViewingsContainer
                                    viewings={ pipe(
                                        props.state.activeData.crm.listingPerformance.pastViewings.forms,
                                        array.map((pastViewingForm) => pastViewingForm.view)
                                    )}
                                />
                            }
                            {lastColumnView === "parties" &&
                                <CRMPadding size="tiny" direction="left">
                                    <CRMListingPerformancePageInterestedPartiesWithoutOfferContainer
                                        dispatch={props.dispatch}
                                        state={props.state}
                                    />
                                </CRMPadding>
                            }
                            {lastColumnView === "viewings" &&
                                <CRMListingPerformancePageUpcomingViewingsContainer
                                    viewings={ pipe(
                                        props.state.activeData.crm.listingPerformance.upcomingViewings.forms,
                                        array.map((upcomingViewingForm) => upcomingViewingForm.view)
                                    )}
                                />
                            }
                        </SpacingColumn>
                    </SpacingRow>
                </Padding>
            </CRMPageBody>
        </CRMPageWrap>
    );
};

export default CRMListingPerformancePage;
