import React, { useEffect } from "react";
import { TFormStatus } from "../../../shared/src/codecs/codec";
import { TOnChangeSearchTextFunction } from "../components/Complex/CRMFilterSearchSelect/CRMFilterSearchSelect";
import { isAFunction } from "../functions/functions";
import { IOption, useDropdown } from "./UseDropdown";
import { useFuzzysort } from "./useFuzzysort";

type TUseFilterDropdownProps<A extends string> = {
    value: A;
    options: Array<IOption<A>>;
    onChange: (value: A) => void;
    
    preserveFilterText?: boolean,
    filterStatus?: TFormStatus;
    filterText?: string;
    setFilterText?: (value: string) => void; 
}

export type TOptionBoxStatus = 
    "no-search-made"
    | "no-match"
    | "match"
    | "searching-on-empty"
    | "none"
;

export type TFaceStatus = 
    "closed-empty"
    | "closed-selected"
    | "open"
;

export const useFilterDropdown = <A extends string, M extends Record<string, unknown>>(props: TUseFilterDropdownProps<A>) => {

    const filterInputRef = React.createRef<HTMLInputElement>();
    const fuzzySortState = useFuzzysort<string, M>(props.options);
    const {
        ref,
        isOpen,
        setIsOpen,
        getFaceText,
        isOptionSelected,
    } = useDropdown<A, IOption<A>>(props.value, props.options);

    useEffect(
        () => {
            if (isOpen === true) {
                filterInputRef.current?.focus();
            }
        },
        [isOpen]
    );

    useEffect(
        () => fuzzySortState.setSearchOptions(props.options),
        [props.options]
    );

    const getFaceStatus = (): TFaceStatus => {
        if (!isOpen && props.value.length > 0) {
            return "closed-selected";
        }
        
        if (!isOpen && props.value.length === 0) {
            return "closed-empty";
        }

        return "open"
    }

    const getOptionBoxStatus = (): TOptionBoxStatus => {
        
        if (
            props.options.length === 0 
            && fuzzySortState.searchText.length === 0
        ) {
            return "no-search-made";
        }

        if (
            props.options.length === 0 
            && fuzzySortState.searchText.length > 0
        ) {
            return "searching-on-empty";
        }

        if (
            props.filterStatus !== "submitting" 
            && props.filterStatus !== "loading"
            && props.filterStatus !== "requiresSubmission" 
            && props.options.length === 0 
            && fuzzySortState.searchText.length > 0
        ) {
            return "no-match";
        }

        if (props.options.length > 0) {
            return "match";
        }

        return "none";
    }

    const onChangeFilterText = (filterText: string) => {
        fuzzySortState.setSearchText(filterText);

        if (isAFunction<TOnChangeSearchTextFunction>(props.setFilterText)) {
            props.setFilterText(filterText);
        }
    }

    const onSelectOption = (option: IOption<A>) => {
        
        props.onChange(option.value);
        setIsOpen(false);
        
        if (!props.preserveFilterText) {
            onChangeFilterText("");
        }
    }

    const isLoadingResults = () => (
        props.filterStatus === "loading"
        || props.filterStatus === "requiresSubmission"
        || props.filterStatus === "submitting"
    );


    return {
        dropdownRef: ref,
        filterInputRef,
        isOpen,
        openDropdown: () => setIsOpen(true), 
        closeDropdown: () => setIsOpen(false), 
        toggleDropdown: () => setIsOpen(!isOpen),
        getFaceText,
        isOptionSelected,
        getFaceStatus,
        getOptionBoxStatus,
        onSelectOption,
        onChangeFilterText,
        getOptions: (keepOriginalSortOrder?: boolean) => fuzzySortState.getSearchResults(keepOriginalSortOrder),
        isLoadingResults,
    };
}
