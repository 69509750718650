import { Lens } from "monocle-ts";
import { TGetState } from "../../state/TGetState";
import { TState } from "./lensBaseTypes";

interface ISet<S> {
    // 5 level lens path guard
    <
        K1 extends keyof S,
        K2 extends keyof S[K1],
        K3 extends keyof S[K1][K2],
        K4 extends keyof S[K1][K2][K3],
        K5 extends keyof S[K1][K2][K3][K4]
    >
    (
        path: [K1, K2, K3, K4, K5],
        getState: TGetState,
    ): S[K1][K2][K3][K4][K5];
    // 4 level lens path guard
    <
        K1 extends keyof S,
        K2 extends keyof S[K1],
        K3 extends keyof S[K1][K2],
        K4 extends keyof S[K1][K2][K3]
    >
    (
        path: [K1, K2, K3, K4],
        getState: TGetState,
    ): S[K1][K2][K3][K4];
    // 3 level lens path guard
    <
        K1 extends keyof S,
        K2 extends keyof S[K1],
        K3 extends keyof S[K1][K2]
    >
    (
        path: [K1, K2, K3],
        getState: TGetState,
    ): S[K1][K2][K3];
    // 2 level lens path guard
    <
        K1 extends keyof S,
        K2 extends keyof S[K1]
    >
    (
        path: [K1, K2],
        getState: TGetState,
    ): S[K1][K2];
    // 1 level lens path guard
    <K1 extends keyof S>
    (
        path: [K1],
        getState: TGetState,
    ): S[K1];
}

export const get = (): ISet<TState> =>
    // There is no way to create paramater overloads here so we set to any
    (lens: any, getState: TGetState) => { // eslint-disable-line
        const focusedLens = Lens.fromPath<TState>()(lens);
        return focusedLens.get(getState());
    };
