import { forkJoin, from, Observable, of, timer } from "rxjs";
import { array } from "fp-ts";
import { tap, flatMap, map, debounce, filter, concatMap, mergeMap, switchMap, debounceTime } from "rxjs/operators";
import { array as extArray } from "../../../../shared/src/utilsByDomain/array";
import { TActionObservable, TCodecSetState, TGetCodecState } from "../applyActions";
import { formOperation } from "../../wrappers/formOperation";
import { TCaseForm,
    TCaseBankAccountForm,
    TCaseSignOffAsReadyForExchangeForm,
    TCaseRelatedTransactionCreateForm,
    TCaseRelatedTransactionCreateSearchResultsForm,
    TCaseRelatedTransactionForm1,
    TCaseRelatedTransactionDataForm,
    TCaseVideoVerificationCallCreateForm,
    TCaseVideoVerificationCallListForm,
    TCaseVideoVerificationCallAssociateWithUserForm,
    TCaseVideoVerificationCallUserForm,
    TCaseSdltForm,
    TCaseSdltDeclarationForm,
    TCaseNewRiskAssessmentForm,
    TCaseAuditForm,
    TSubmitCaseAuditAsCompleteForm,
    TCaseDualRepCreateForm,
    TCaseDualRepSearchResultsForm,
    TCaseDualRepLinkedForm,
    CaseSignOffAsReadyForExchangeForm,
    TCreateCaseQuoteUnfilledForm,
    TCreateCaseQuoteFilledForm,
    TCaseQuoteSentToClientForm } from "../../../../domain/codecs/form/CaseForm";
import { TPhoneNumberForm } from "../../../../domain/codecs/form/PhoneNumberForm";
import { TCaseMemberChildrenForm, TCaseMemberCreateIdCheckForm, TCaseMemberForm, TCaseMemberIdCheckForm, TCaseMemberSuggestedSimilarUserForm, TCaseSendInstructedEmailForm } from "../../../../domain/codecs/form/CaseMemberForm";
import { doFormValuesMatch as doFormEditableValuesMatch } from "../../../../shared/src/codecs/types/formEditable";
import { pipe } from "fp-ts/lib/function";
import { TGetState } from "../TGetState";
import { TSetState } from "../TSetState";
import { TActionsDefinitionsList } from "./TAction";
import { action, routeAction } from "./actionFunctions";
import { TCaseConflictOfInterestForm } from "../../../../domain/codecs/form/CaseConflictsOfInterestForms";
import { TCaseAdHocJobForm, TCaseAdHocJobNewForm } from "../../../../domain/codecs/form/CaseAdHocJobForms";
import { TriageForm } from "../../../../domain/codecs/form/TriageForm";
import { getSimpleLegalEmailPeopleFromCaseDetails, patchSimpleEmailFormsWithEmailPeople } from "./crmLegalCaseInboxActions";
import { updateUrl } from "../router/updateUrl";
import { TStateLens } from "../../../../domain/codecs/state";
import { requireExhaustive } from "../../../../shared/src/util";
import { runAutomaticUpdates } from "./automaticBlockUpdate";

export const actions: TActionsDefinitionsList = [
    routeAction("VIEW_CRM_LEGAL_CASE", (obs$, lens, setState, getState) => {
        obs$.pipe(
            tap((a) => setState(lens.case_details_page.data.input.case_id.set(a.params.caseId))),
            tap((a) => setState(lens.case_details_page.create_member.input.case_id.set(a.params.caseId))),
            tap((a) => setState(lens.contracts_block.edited.case_id.set(a.params.caseId))),
            tap((act) => setState(lens.case_inbox_page.set({
                unresolved: {
                    ...TriageForm.newDefault(),
                    edited: {
                        case_id: act.params.caseId,
                        offset: 0,
                        limit: 100,
                    },
                }
            }))),
        ).subscribe();
    }),


    action("LEGAL_CASE_PAGE_VIEW", (obs$, lens, setState, getState) => {
        obs$.pipe(
            $loadCaseAndEmails(lens, setState, getState),
        ).subscribe();
    }),
    action("CASE_INBOX_VIEW", (obs$, lens, setState, getState) => {
        obs$.pipe(
            $loadCaseAndEmails(lens, setState, getState),
        ).subscribe();
    }),

    action("LEGAL_CASE_DETAILS_VIEW", (obs$, lens, setState, getState) => {
        obs$.pipe(
            $loadCase(lens, setState, getState),
        ).subscribe();
    }),
    action("CRM_CASE_OVERVIEW_VIEW", (obs$, lens, setState, getState) => {
        obs$.pipe(
            $loadCase(lens, setState, getState),
        ).subscribe();
    }),
    action("CRM_CASE_QUOTE_VIEW", (obs$, lens, setState, getState) => {
        obs$.pipe(
            $loadCase(lens, setState, getState),
        ).subscribe();
    }),
    action("LEGAL_CASE_COI_VIEW", (obs$, lens, setState, getState) => {
        obs$.pipe(
            $loadCase(lens, setState, getState)
        ).subscribe();
    }),

    action("LEGAL_CASE_DETAILS_CHANGE", (obs$: Observable<TCaseForm>, lens, setState, getState, routes, dispatch) => {
        obs$.pipe(
            tap((payload) => setState(lens.case_details_page.data.output.details.set(payload))),
            debounce(() => timer(1000)),
            tap(() => setState(lens.case_details_page.data.output.details.status.set("submitting"))),
            switchMap(() => formOperation("UpdateLegalCase", lens.case_details_page.data.output.details.get()(getState()))),
            tap((response) => setState(lens.case_details_page.data.output.details.set({
                ...response,
                edited: {
                    ...lens.case_details_page.data.output.details.edited.get()(getState()), // don't want overwrite what someone is typing
                }
            }))),
            tap(() => runAutomaticUpdates(routes, dispatch, getState, "case_details"))
        ).subscribe();
    }),
    action("LEGAL_CASE_SDLT_CHANGE", (obs$: Observable<TCaseSdltForm>, lens, setState, getState, routes, dispatch) => {
        obs$.pipe(
            tap((payload) => setState(lens.case_details_page.data.output.details.children.sdlt.set(payload))),
            debounce(() => timer(1000)),
            tap(() => setState(lens.case_details_page.data.output.details.children.sdlt.status.set("submitting"))),
            switchMap(() => formOperation("UpdateCaseSdlt", lens.case_details_page.data.output.details.children.sdlt.get()(getState()))),
            tap((response) => setState(lens.case_details_page.data.output.details.children.sdlt.set({
                ...response,
                edited: lens.case_details_page.data.output.details.children.sdlt.edited.get()(getState())
            }))),
            tap(() => runAutomaticUpdates(routes, dispatch, getState, "case_details"))
        ).subscribe();
    }),
    action("LEGAL_CASE_SDLT_SUBMIT_DECLARATION", (obs$: Observable<TCaseSdltDeclarationForm>, lens, setState, getState, routes, dispatch) => {
        obs$.pipe(
            tap((form) => setState(lens.case_details_page.data.output.details.children.sdlt.children.submit_declaration.set({
                ...form,
                status: "submitting",
            }))),
            switchMap(() => formOperation("SubmitCaseSdltDeclaration", lens.case_details_page.data.output.details.children.sdlt.children.submit_declaration.get()(getState()))),
            tap((response) => setState(lens.case_details_page.data.output.details.children.sdlt.children.submit_declaration.set(response))),
            switchMap((response) =>
                response.status === "success"
                    ? from(formOperation("GetCase", lens.case_details_page.data.get()(getState())))
                        .pipe(
                            tap((r) => setState(lens.case_details_page.data.set(r)))
                        )
                    : of(undefined)
            ),
            tap(() => runAutomaticUpdates(routes, dispatch, getState, "case_details"))
        ).subscribe();
    }),
    action("LEGAL_CASE_MEMBERS_CHANGE", (obs$: Observable<Array<TCaseMemberForm>>, lens, setState, getState, routes, dispatch) => {
        obs$.pipe(
            tap((members) => setState(lens.case_details_page.data.output.details.children.members.set(members))),
            debounce(() => timer(1000)),
            concatMap((members) => members),
            filter((member) => member.status === "requiresSubmission"),
            tap((member) => setState(lens.case_details_page.data.output.details.children.members.where(doFormEditableValuesMatch(member, "id")).status.set("submitting"))),
            switchMap((member) => formOperation("UpdateCaseMember", member)),
            tap((response) => setState(lens.case_details_page.data.output.details.children.members.where(doFormEditableValuesMatch(response, "id")).set({
                ...response,
                edited: lens.case_details_page.data.output.details.children.members.where(doFormEditableValuesMatch(response, "id")).edited.get()(getState()),
            }))),
            tap(() => runAutomaticUpdates(routes, dispatch, getState, "case_details"))
        ).subscribe();
    }),
    action("LEGAL_CASE_MEMBERS_CHANGE", (obs$: Observable<Array<TCaseMemberForm>>, lens, setState, getState, routes, dispatch) => {
        obs$.pipe(
            debounce(() => timer(1000)),
            map((members) => members.map((member) => member.edited.phone_numbers)),
            map((members) => pipe(
                members,
                array.flatten,
                array.filter((number) => number.status === "requiresSubmission"),
                extArray.splitWhere((number) => number.edited.primary_number === false),
                array.flatten
            )),
            concatMap((members) => members),
            tap((number) => {
                setState(
                    lens.case_details_page.data.output.details.children.members
                        .where((member) => member.original.id === number.original.user_id)
                        .edited.phone_numbers
                        .where(doFormEditableValuesMatch(number, "id"))
                        .status.set("submitting")
                )
            }),
            switchMap((number) => formOperation("UpdateUserNumber", number)),
            tap((number) => {
                setState(
                    lens.case_details_page.data.output.details.children.members
                        .where((member) => member.original.id === number.original.user_id)
                        .edited.phone_numbers
                        .where(doFormEditableValuesMatch(number, "id"))
                        .set({
                            ...number,
                            edited: lens.case_details_page.data.output.details.children.members
                                .where((member) => member.original.id === number.original.user_id)
                                .edited.phone_numbers
                                .where(doFormEditableValuesMatch(number, "id"))
                                .edited
                                .get()(getState())
                        })
                )
            }),
            tap(() => runAutomaticUpdates(routes, dispatch, getState, "case_details"))
        ).subscribe();
    }),
    action("LEGAL_CASE_MEMBER_USER_CASE_DATA_CHANGE", (obs$: Observable<TCaseMemberChildrenForm>, lens, setState, getState, routes, dispatch) => {
        obs$.pipe(
            tap((memberChildForm) => setState(lens.case_details_page.data.output.details.children.members.where((form) => form.edited.id === memberChildForm.edited.user_id).children.set(memberChildForm))),
            debounce(() => timer(1000)),
            tap((memberChildForm) => setState(lens.case_details_page.data.output.details.children.members.where((form) => form.edited.id === memberChildForm.edited.user_id).children.status.set("submitting"))),
            switchMap((memberChildForm) => formOperation("UpdateCaseMemberCaseUser", memberChildForm)),
            tap((response) => setState(lens.case_details_page.data.output.details.children.members.where((form) => form.edited.id === response.edited.user_id).children.set({
                ...response
            }))),
            tap(() => runAutomaticUpdates(routes, dispatch, getState, "case_details"))
        ).subscribe();
    }),
    action("LEGAL_CASE_DETAILS_ADD_MEMBER", (obs$, lens, setState, getState, routes, dispatch) => {
        obs$.pipe(
            flatMap(() => formOperation("CreateCaseMember", lens.case_details_page.create_member.get()(getState()))),
            tap((payload) => {
                const members = lens.case_details_page.data.output.details.children.members.get()(getState());
                setState(lens.case_details_page.data.output.details.children.members.set(members.concat(payload.output.member)));
            }),
            tap(() => runAutomaticUpdates(routes, dispatch, getState, "case_details"))
        ).subscribe();
    }),
    action("LEGAL_CASE_DETAILS_REMOVE_MEMBER", (obs$: Observable<TCaseMemberForm>, lens, setState, getState, routes, dispatch) => {
        obs$.pipe(
            tap((member) => setState(lens.case_details_page.remove_member.input.set({ user_id: member.original.id, case_id: lens.case_details_page.data.input.case_id.get()(getState()) }))),
            switchMap(() => formOperation("RemoveCaseMember", lens.case_details_page.remove_member.get()(getState()))),
            tap((response) => setState(lens.case_details_page.remove_member.set(response))),
            tap((payload) => {
                const members = lens.case_details_page.data.output.details.children.members.get()(getState());
                const updatedMembers = pipe(
                    members,
                    extArray.deleteWhere((member) => member.original.id === payload.output.user_id)
                );
                setState(lens.case_details_page.data.output.details.children.members.set(updatedMembers));
            }),
            tap(() => runAutomaticUpdates(routes, dispatch, getState, "case_details"))
        ).subscribe();
    }),
    action("LEGAL_CASE_DETAILS_ADD_MEMBER_NUMBER", (obs$: Observable<string>, lens, setState, getState, routes, dispatch) => {
        obs$.pipe(
            tap((userId) => setState(lens.case_details_page.create_member_number.input.user_id.set(userId))),
            tap(() => setState(lens.case_details_page.create_member_number.status.set("submitting"))),
            map(() => lens.case_details_page.create_member_number.get()(getState())),
            flatMap((ioForm) => formOperation("CreateUserNumber", ioForm)),
            tap((payload) => setState(lens.case_details_page.create_member_number.set(payload))),
            tap((payload) => {
                const numberOwner = lens.case_details_page.data.output.details.children.members.where((member) => member.original.id === payload.output.original.user_id)
                const allNumbers = numberOwner.edited.phone_numbers.get()(getState());
                setState(numberOwner.edited.phone_numbers.set(allNumbers.concat(payload.output)));
            }),
            tap(() => runAutomaticUpdates(routes, dispatch, getState, "case_details"))
        ).subscribe();
    }),
    action("LEGAL_CASE_DETAILS_REMOVE_MEMBER_NUMBER", (obs$: Observable<TPhoneNumberForm>, lens, setState, getState, routes, dispatch) => {
        obs$.pipe(
            tap((numberEditable) => setState(lens.case_details_page.remove_member_number.input.set({
                user_id: numberEditable.original.user_id,
                number_id: numberEditable.original.id
            }))),
            tap(() => setState(lens.case_details_page.remove_member_number.status.set("submitting"))),
            map(() => lens.case_details_page.remove_member_number.get()(getState())),
            flatMap((ioForm) => formOperation("RemoveUserNumber", ioForm)),
            tap((payload) => setState(lens.case_details_page.remove_member_number.set(payload))),
            tap((payload) => {
                const numberOwner = lens.case_details_page.data.output.details.children.members
                    .where((member) => member.original.id === payload.output.user_id)

                const updatedNumbers = pipe(
                    numberOwner.edited.phone_numbers.get()(getState()),
                    extArray.deleteWhere((number) => number.original.id === payload.output.number_id)
                );
                setState(numberOwner.edited.phone_numbers.set(updatedNumbers));
                setState(numberOwner.original.phone_numbers.set(updatedNumbers));
            }),
            tap(() => runAutomaticUpdates(routes, dispatch, getState, "case_details"))
        ).subscribe();
    }),
    action("LEGAL_CASE_MEMBER_USER_CASE_NEW_ID_CHECK", (obs$: Observable<TCaseMemberCreateIdCheckForm>, lens, setState, getState, routes, dispatch) => {
        obs$.pipe(
            switchMap((payload) => formOperation("CreateCaseMemberIdCheck", payload)),
            tap((response) => setState(lens.case_details_page.data.output.details.children.members.where((form) => form.children.children.create_id_check.edited.users__cases_id === response.edited.users__cases_id).children.children.create_id_check.set(response))),
            switchMap((response) =>
                response.status === "success"
                    ? from(formOperation("GetCase", lens.case_details_page.data.get()(getState())))
                        .pipe(
                            tap((r) => setState(lens.case_details_page.data.set(r))),
                        )
                    : of(undefined)
            ),
            tap(() => runAutomaticUpdates(routes, dispatch, getState, "case_details"))
        ).subscribe();
    }),
    action("LEGAL_CASE_MEMBER_USER_CASE_UPDATE_ID_CHECK", (obs$: Observable<TCaseMemberIdCheckForm>, lens, setState, getState, routes, dispatch) => {
        obs$.pipe(
            tap((payload) => setState(lens.case_details_page.data.output.details.children.members.where((form) => form.children.children.users__cases_id === payload.children.users__cases_id).children.children.id_checks.where((form) => form.edited.id === payload.edited.id).set(payload))),
            debounceTime(1000),
            tap((payload) => setState(lens.case_details_page.data.output.details.children.members.where((form) => form.children.children.users__cases_id === payload.children.users__cases_id).children.children.id_checks.where((form) => form.edited.id === payload.edited.id).status.set("submitting"))),
            switchMap((payload) => formOperation("UpdateCaseMemberIdCheck", payload)),
            tap((response) => setState(lens.case_details_page.data.output.details.children.members.where((form) => form.children.children.users__cases_id === response.children.users__cases_id).children.children.id_checks.where((form) => form.edited.id === response.edited.id).set({
                ...response,
                edited: lens.case_details_page.data.output.details.children.members.where((form) => form.children.children.users__cases_id === response.children.users__cases_id).children.children.id_checks.where((form) => form.edited.id === response.edited.id).edited.get()(getState()),
            }))),
            tap(() => runAutomaticUpdates(routes, dispatch, getState, "case_details"))
        ).subscribe();
    }),
    {
        type: "UPDATE_LEGAL_CASE_VISIBLE_SECTIONS_FILTER",
        run: (
            obs$: TActionObservable<"UPDATE_LEGAL_CASE_VISIBLE_SECTIONS_FILTER", {index: number, sections: string}>,
            getState: TGetState,
            setState: TSetState,
        ): void => {
            obs$.pipe(
                tap((a) =>
                    setState(({...s}) => {
                            let block = s.routes.blockParams.VIEW_CRM_LEGAL_CASE[a.payload.index];

                            switch (block.type) {
                                case "case_contracts":
                                case "case_details":
                                    block.meta.visible_sections = a.payload.sections;
                                    s.routes.blockParams.VIEW_CRM_LEGAL_CASE[a.payload.index] = block;
                                    break;
                                case "case_overview":
                                case "case_quotes":
                                case "case_roadblocks":
                                    break;
                                default:
                                    requireExhaustive;
                            };
                            updateUrl(s);
                            return s;
                     }),
                ),
            )
            .subscribe();
        },
    },
    {
        type: "LEGAL_CASE_UPDATE_VISIBLE_TAB",
        run: (
            obs$: TActionObservable<"LEGAL_CASE_UPDATE_VISIBLE_TAB", string>,
            getState: TGetState,
            setState: TSetState,
        ): void => {
            obs$.pipe(
                tap((a) =>
                    setState(({...s}) => {
                            s.routes.queryParams.VIEW_CRM_LEGAL_CASE.visible_tab = a.payload;
                            updateUrl(s);
                            return s;
                     }),
                ),
            )
            .subscribe();
        },
    },
    action("LEGAL_CASE_SEND_INSTRUCTED_EMAIL", (obs$: Observable<TCaseSendInstructedEmailForm>, lens, setState, getState) => {
        obs$.pipe(
            tap((payload) => setState(lens.case_details_page.data.output.details.children.members.where((form) => form.edited.id === payload.edited.user_id).children.children.send_instructed_email.status.set("submitting"))),
            switchMap((payload) => formOperation("SendCaseInstructedEmail", lens.case_details_page.data.output.details.children.members.where((form) => form.edited.id === payload.edited.user_id).children.children.send_instructed_email.get()(getState()))),
            tap((response) => setState(lens.case_details_page.data.output.details.children.members.where((form) => form.edited.id === response.edited.user_id).children.children.send_instructed_email.set(response))),
            switchMap(() => formOperation("GetCase", lens.case_details_page.data.get()(getState()))),
            tap((response) => setState(lens.case_details_page.data.set(response))),
        ).subscribe();
    }),
    action("LEGAL_CASE_UPDATE_CLIENT_BANK_ACCOUNT", (obs$: Observable<TCaseBankAccountForm>, lens, setState, getState, routes, dispatch) => {
        obs$.pipe(
            tap((payload) => setState(lens.case_details_page.data.output.details.children.bank_accounts.where((form) => form.edited.id === payload.edited.id).set(payload))),
            debounceTime(1000),
            tap((payload) => setState(lens.case_details_page.data.output.details.children.bank_accounts.where((form) => form.edited.id === payload.edited.id).status.set("submitting"))),
            switchMap((payload) => formOperation("UpdateCaseBankAccount", lens.case_details_page.data.output.details.children.bank_accounts.where((form) => form.edited.id === payload.edited.id).get()(getState()))),
            tap((response) => setState(lens.case_details_page.data.output.details.children.bank_accounts.where((form) => form.edited.id === response.edited.id).set({
                ...response,
                edited: lens.case_details_page.data.output.details.children.bank_accounts.where((form) => form.edited.id === response.edited.id).edited.get()(getState()),
            }))),
            tap(() => runAutomaticUpdates(routes, dispatch, getState, "case_details"))
        ).subscribe();
    }),
    action("LEGAL_CASE_COI_CHANGE", (obs$: Observable<TCaseConflictOfInterestForm>, lens, setState, getState) => {
        obs$.pipe(
            tap((coi) => setState(lens.case_details_page.data.output.conflicts_of_interest.where((coiForm) => coiForm.children.id === coi.children.id).set(coi))),
            debounce(() => timer(1000)),
            tap((coi) => setState(lens.case_details_page.data.output.conflicts_of_interest.where((coiForm) => coiForm.children.id === coi.children.id).status.set("submitting"))),
            switchMap((coi) => formOperation("UpdateCaseConflictOfInterest", lens.case_details_page.data.output.conflicts_of_interest.where((coiForm) => coiForm.children.id === coi.children.id).get()(getState()))),
            tap((response) => setState(lens.case_details_page.data.output.conflicts_of_interest.where((coiForm) => coiForm.children.id === response.children.id).set({
                ...response,
                edited: lens.case_details_page.data.output.conflicts_of_interest.where((coiForm) => coiForm.children.id === response.children.id).edited.get()(getState()),
            })))
        ).subscribe();
    }),
    action("LEGAL_CASE_SIGN_OFF_AS_READY_FOR_EXCHANGE", (obs$: Observable<TCaseSignOffAsReadyForExchangeForm>, lens, setState, getState, routes, dispatch) => {
        obs$.pipe(
            tap(() => setState(lens.case_details_page.data.output.details.children.sign_off_as_ready_for_exchange.status.set("submitting"))),
            switchMap(() => formOperation("SignOffAsReadyForExchange", lens.case_details_page.data.output.details.children.sign_off_as_ready_for_exchange.get()(getState()))),
            tap((res) => setState(lens.case_details_page.data.output.details.children.sign_off_as_ready_for_exchange.set(res))),
            switchMap((res) =>
                res.status === "success"
                    ? from(formOperation("GetCase", lens.case_details_page.data.get()(getState())))
                        .pipe(
                            tap((response) => setState(lens.case_details_page.data.set(response))),
                        )
                    : of(undefined)
            ),
            tap(() => runAutomaticUpdates(routes, dispatch, getState, "case_details"))
        ).subscribe();
    }),
    action("LEGAL_CASE_CANCEL_SIGN_OFF_AS_READY_FOR_EXCHANGE", (obs$: Observable<undefined>, lens, setState, getState, routes, dispatch) => {
        obs$.pipe(
            tap(() => setState(lens.case_details_page.data.output.details.children.sign_off_as_ready_for_exchange.set({
                ...CaseSignOffAsReadyForExchangeForm.newDefault(),
                edited: {
                    id: lens.case_details_page.data.output.details.children.sign_off_as_ready_for_exchange.edited.id.get()(getState()),
                },
                original: {
                    id: lens.case_details_page.data.output.details.children.sign_off_as_ready_for_exchange.original.id.get()(getState()),
                },
            }))),
            tap(() => runAutomaticUpdates(routes, dispatch, getState, "case_details"))
        ).subscribe();
    }),
    action("LEGAL_CASE_INSTRUCT", (obs$: Observable<TCaseSignOffAsReadyForExchangeForm>, lens, setState, getState, routes, dispatch) => {
        obs$.pipe(
            filter(() => lens.case_details_page.data.output.details.children.instruct.status.get()(getState()) !== "submitting"),
            tap(() => setState(lens.case_details_page.data.output.details.children.instruct.status.set("submitting"))),
            switchMap(() => formOperation("InstructCase", lens.case_details_page.data.output.details.children.instruct.get()(getState()))),
            tap((res) => setState(lens.case_details_page.data.output.details.children.instruct.set(res))),
            switchMap((res) =>
                res.status === "success"
                    ? from(formOperation("GetCase", lens.case_details_page.data.get()(getState())))
                        .pipe(
                            tap((response) => setState(lens.case_details_page.data.set(response))),
                        )
                    : of(undefined)
            ),
            tap(() => runAutomaticUpdates(routes, dispatch, getState, "case_details"))
        ).subscribe();
    }),
    action("LEGAL_CASE_SWAP_MEMBER", (obs$: Observable<TCaseMemberSuggestedSimilarUserForm>, lens, setState, getState, routes, dispatch) => {
        obs$.pipe(
            tap((payload) => setState(
                lens.case_details_page.data.output.details.children.members
                    .where((form) => form.edited.id === payload.edited.swap_from_user_id)
                    .children.children.suggested_similar_users
                    .where((form) => form.edited.swap_to_user_id === payload.edited.swap_to_user_id)
                    .status.set("submitting")
            )),
            switchMap((payload) => formOperation(
                "SwapCaseMember",
                lens.case_details_page.data.output.details.children.members
                    .where((form) => form.edited.id === payload.edited.swap_from_user_id)
                    .children.children.suggested_similar_users
                    .where((form) => form.edited.swap_to_user_id === payload.edited.swap_to_user_id)
                    .get()(getState())
            )),
            tap((response) => setState(
                lens.case_details_page.data.output.details.children.members
                    .where((form) => form.edited.id === response.edited.swap_from_user_id)
                    .children.children.suggested_similar_users
                    .where((form) => form.edited.swap_to_user_id === response.edited.swap_to_user_id)
                    .set(response)
            )),
            switchMap((response) =>
                response.status === "success"
                    ? from(formOperation("GetCase", lens.case_details_page.data.get()(getState())))
                        .pipe(
                            tap((r) => setState(lens.case_details_page.data.set(r))),
                        )
                    : of(undefined)
            ),
            tap(() => runAutomaticUpdates(routes, dispatch, getState, "case_details"))
        ).subscribe();
    }),
    action("LEGAL_CASE_UPDATE_ADD_AD_HOC_JOB", (obs$: Observable<TCaseAdHocJobNewForm>, lens, setState) => {
        obs$.pipe(
            tap((payload) => setState(lens.case_details_page.data.output.create_new_ad_hoc_job.set(payload)))
        ).subscribe();
    }),
    action("LEGAL_CASE_ADD_AD_HOC_JOB", (obs$: Observable<TCaseAdHocJobNewForm>, lens, setState, getState, routes, dispatch) => {
        obs$.pipe(
            tap((payload) => setState(lens.case_details_page.data.output.create_new_ad_hoc_job.set(payload))),
            tap(() => setState(lens.case_details_page.data.output.create_new_ad_hoc_job.status.set("submitting"))),
            switchMap(() => formOperation("CreateCaseAdHocJob", lens.case_details_page.data.output.create_new_ad_hoc_job.get()(getState()))),
            tap((response) => setState(lens.case_details_page.data.output.create_new_ad_hoc_job.set(response))),
            switchMap((response) =>
                response.status === "success"
                    ? from(formOperation("GetCase", lens.case_details_page.data.get()(getState())))
                        .pipe(tap((r) => setState(lens.case_details_page.data.set(r))))
                    : of (undefined)
            ),
            tap(() => runAutomaticUpdates(routes, dispatch, getState, "case_details"))
        ).subscribe();
    }),
    action("LEGAL_CASE_UPDATE_AD_HOC_JOBS", (obs$: Observable<Array<TCaseAdHocJobForm>>, lens, setState, getState, routes, dispatch) => {
        obs$.pipe(
            tap((jobs) => setState(lens.case_details_page.data.output.ad_hoc_jobs.set(jobs))),
            concatMap((jobs) => jobs),
            filter((job) => job.status === "requiresSubmission"),
            tap((job) => setState(lens.case_details_page.data.output.ad_hoc_jobs.where((j) => j.children.id === job.children.id).status.set("submitting"))),
            switchMap((job) => formOperation("UpdateCaseAdHocJob", job)),
            tap((job) => setState(lens.case_details_page.data.output.ad_hoc_jobs.where((j) => j.children.id === job.children.id).set(job))),
            tap(() => runAutomaticUpdates(routes, dispatch, getState, "case_details"))
        ).subscribe();
    }),
    action("LEGAL_CASE_UPDATE_DUAL_REP_SEARCH_TERMS", (obs$: Observable<TCaseDualRepCreateForm>, lens, setState, getState, routes, dispatch) => {
        obs$.pipe(
            tap((form) => setState(lens.case_details_page.data.output.details.children.dual_rep.create_link.set({
                ...form,
                status: "requiresSubmission",
            }))),
            debounceTime(1000),
            tap(() => setState(lens.case_details_page.data.output.details.children.dual_rep.create_link.status.set("submitting"))),
            switchMap((form) => formOperation("GetCaseDualRepSearch", form)),
            tap((form) => setState(lens.case_details_page.data.output.details.children.dual_rep.create_link.set({
                ...form,
                edited: lens.case_details_page.data.output.details.children.dual_rep.create_link.edited.get()(getState()),
            }))),
            tap(() => runAutomaticUpdates(routes, dispatch, getState, "case_details"))
        ).subscribe();
    }),
    action("LEGAL_CASE_LINK_DUAL_REP", (obs$: Observable<TCaseDualRepSearchResultsForm>, lens, setState, getState, routes, dispatch) => {
        obs$.pipe(
            switchMap((form) => formOperation("LinkCaseDualRep", form)),
            tap((response) => setState(lens.case_details_page.data.output.details.children.dual_rep.create_link.children.where((f) => f.edited.from_case_id === response.edited.from_case_id && f.edited.to_case_id === response.edited.to_case_id).set(response))),
            filter(({status}) => status === "success"),
            switchMap(() => formOperation("GetCase", lens.case_details_page.data.get()(getState()))),
            tap((response) => setState(lens.case_details_page.data.set(response))),
            tap(() => runAutomaticUpdates(routes, dispatch, getState, "case_details"))
        ).subscribe();
    }),
    action("LEGAL_CASE_UNLINK_DUAL_REP", (obs$: Observable<TCaseDualRepLinkedForm>, lens, setState, getState, routes, dispatch) => {
        obs$.pipe(
            switchMap((form) => formOperation("UnlinkCaseDualRep", form)),
            switchMap(() => formOperation("GetCase", lens.case_details_page.data.get()(getState()))),
            tap((response) => setState(lens.case_details_page.data.set(response))),
            tap(() => runAutomaticUpdates(routes, dispatch, getState, "case_details"))
        ).subscribe();
    }),
    action("LEGAL_CASE_UPDATE_CREATE_RELATED_TRANSACTION_SEARCH_TERMS", (obs$: Observable<TCaseRelatedTransactionCreateForm>, lens, setState, getState, routes, dispatch) => {
        obs$.pipe(
            tap((form) => setState(lens.case_details_page.data.output.details.children.related_transaction.create_link.set({
                ...form,
                status: "requiresSubmission",
            }))),
            debounceTime(1000),
            tap(() => setState(lens.case_details_page.data.output.details.children.related_transaction.create_link.status.set("submitting"))),
            switchMap((form) => formOperation("GetCaseRelatedSearch", form)),
            tap((form) => setState(lens.case_details_page.data.output.details.children.related_transaction.create_link.set({
                ...form,
                edited: lens.case_details_page.data.output.details.children.related_transaction.create_link.edited.get()(getState()),
            }))),
            tap(() => runAutomaticUpdates(routes, dispatch, getState, "case_details"))
        ).subscribe();
    }),
    action("LEGAL_CASE_CREATE_RELATED_TRANSACTION", (obs$: Observable<TCaseRelatedTransactionCreateSearchResultsForm>, lens, setState, getState, routes, dispatch) => {
        obs$.pipe(
            switchMap((form) => formOperation("CreateCaseRelatedTransaction", form)),
            switchMap(() => formOperation("GetCase", lens.case_details_page.data.get()(getState()))),
            tap((response) => setState(lens.case_details_page.data.set(response))),
            tap(() => runAutomaticUpdates(routes, dispatch, getState, "case_details"))
        ).subscribe();
    }),
    action("LEGAL_CASE_DELETE_RELATED_TRANSACTION", (obs$: Observable<TCaseRelatedTransactionForm1>, lens, setState, getState, routes, dispatch) => {
        obs$.pipe(
            switchMap((form) => formOperation("DeleteCaseRelatedTransaction", form)),
            switchMap(() => formOperation("GetCase", lens.case_details_page.data.get()(getState()))),
            tap((response) => setState(lens.case_details_page.data.set(response))),
            tap(() => runAutomaticUpdates(routes, dispatch, getState, "case_details"))
        ).subscribe();
    }),
    action("LEGAL_CASE_UPDATE_RELATED_TRANSACTION_DATA", (obs$: Observable<TCaseRelatedTransactionDataForm>, lens, setState, getState, routes, dispatch) => {
        obs$.pipe(
            tap((form) => setState(lens.case_details_page.data.output.details.children.related_transaction.related_data.set({
                ...form,
                status: "requiresSubmission",
            }))),
            debounceTime(1000),
            tap(() => setState(lens.case_details_page.data.output.details.children.related_transaction.related_data.status.set("submitting"))),
            switchMap(() => formOperation("UpdateCaseRelatedTransaction", lens.case_details_page.data.output.details.children.related_transaction.related_data.get()(getState()))),
            tap((response) => setState(lens.case_details_page.data.output.details.children.related_transaction.related_data.set({
                ...response,
                edited: lens.case_details_page.data.output.details.children.related_transaction.related_data.edited.get()(getState()),
            }))),
            tap(() => runAutomaticUpdates(routes, dispatch, getState, "case_details"))
        ).subscribe();
    }),
    action("LEGAL_CASE_UPDATE_ASSOCIATE_USER_VIDEO_VERIFICATION_CALL_DROPDOWN", (obs$: Observable<TCaseVideoVerificationCallAssociateWithUserForm>, lens, setState) => {
        obs$.pipe(
            tap((form) => setState(lens.case_details_page.data.output.details.children.video_verification_call.list.where((f) => f.edited.id === form.edited.video_verification_call_id).children.associate_with_user.set({
                ...form,
                status: form.edited.user_id ? "requiresSubmission" : "untouched",
            }))),
        ).subscribe();
    }),
    action("LEGAL_CASE_CREATE_VIDEO_VERIFICATION_CALL", (obs$: Observable<TCaseVideoVerificationCallCreateForm>, lens, setState, getState, routes, dispatch) => {
        obs$.pipe(
            tap((form) => setState(lens.case_details_page.data.output.details.children.video_verification_call.create.set({
                ...form,
                status: "submitting",
            }))),
            switchMap(() => formOperation("CreateCaseVideoVerificationCall", lens.case_details_page.data.output.details.children.video_verification_call.create.get()(getState()))),
            tap((response) => setState(lens.case_details_page.data.output.details.children.video_verification_call.create.set(response))),
            switchMap((response) =>
                response.status === "success"
                    ? from(formOperation("GetCase", lens.case_details_page.data.get()(getState())))
                        .pipe(
                            tap((r) => setState(lens.case_details_page.data.set(r)))
                        )
                    : of(undefined)
            ),
            tap(() => runAutomaticUpdates(routes, dispatch, getState, "case_details"))
        ).subscribe();
    }),
    action("LEGAL_CASE_UPDATE_VIDEO_VERIFICATION_CALL", (obs$: Observable<TCaseVideoVerificationCallListForm>, lens, setState, getState, routes, dispatch) => {
        obs$.pipe(
            tap((form) => setState(lens.case_details_page.data.output.details.children.video_verification_call.list.where((f) => f.edited.id === form.edited.id).set({
                ...form,
                status: "requiresSubmission",
            }))),
            debounceTime(1000),
            tap((form) => setState(lens.case_details_page.data.output.details.children.video_verification_call.list.where((f) => f.edited.id === form.edited.id).status.set("submitting"))),
            switchMap((form) => formOperation("UpdateCaseVideoVerificationCall", lens.case_details_page.data.output.details.children.video_verification_call.list.where((f) => f.edited.id === form.edited.id).get()(getState()))),
            tap((response) => setState(lens.case_details_page.data.output.details.children.video_verification_call.list.where((f) => f.edited.id === response.edited.id).set({
                ...response,
                edited: lens.case_details_page.data.output.details.children.video_verification_call.list.where((f) => f.edited.id === response.edited.id).edited.get()(getState()),
            }))),
            tap(() => runAutomaticUpdates(routes, dispatch, getState, "case_details"))
        ).subscribe();
    }),
    action("LEGAL_CASE_DELETE_VIDEO_VERIFICATION_CALL", (obs$: Observable<TCaseVideoVerificationCallListForm>, lens, setState, getState, routes, dispatch) => {
        obs$.pipe(
            tap((form) => setState(lens.case_details_page.data.output.details.children.video_verification_call.list.where((f) => f.edited.id === form.edited.id).set({
                ...form,
                status: "submitting",
            }))),
            switchMap((form) => formOperation("DeleteCaseVideoVerificationCall", lens.case_details_page.data.output.details.children.video_verification_call.list.where((f) => f.edited.id === form.edited.id).get()(getState()))),
            tap((response) => setState(lens.case_details_page.data.output.details.children.video_verification_call.list.where((f) => f.edited.id === response.edited.id).set(response))),
            switchMap((response) =>
                response.status === "success"
                    ? from(formOperation("GetCase", lens.case_details_page.data.get()(getState())))
                        .pipe(
                            tap((r) => setState(lens.case_details_page.data.set(r)))
                        )
                    : of(undefined)
            ),
            tap(() => runAutomaticUpdates(routes, dispatch, getState, "case_details"))
        ).subscribe();
    }),
    action("LEGAL_CASE_ASSOCIATE_USER_VIDEO_VERIFICATION_CALL", (obs$: Observable<TCaseVideoVerificationCallAssociateWithUserForm>, lens, setState, getState, routes, dispatch) => {
        obs$.pipe(
            tap((form) => setState(lens.case_details_page.data.output.details.children.video_verification_call.list.where((f) => f.edited.id === form.edited.video_verification_call_id).children.associate_with_user.set({
                ...form,
                status: "submitting",
            }))),
            switchMap((form) => formOperation("AssociateCaseUserVideoVerificationCall", lens.case_details_page.data.output.details.children.video_verification_call.list.where((f) => f.edited.id === form.edited.video_verification_call_id).children.associate_with_user.get()(getState()))),
            tap((response) => setState(lens.case_details_page.data.output.details.children.video_verification_call.list.where((f) => f.edited.id === response.edited.video_verification_call_id).children.associate_with_user.set(response))),
            switchMap((response) =>
                response.status === "success"
                    ? from(formOperation("GetCase", lens.case_details_page.data.get()(getState())))
                        .pipe(
                            tap((r) => setState(lens.case_details_page.data.set(r)))
                        )
                    : of(undefined)
            ),
            tap(() => runAutomaticUpdates(routes, dispatch, getState, "case_details"))
        ).subscribe();
    }),
    action("LEGAL_CASE_UPDATE_USER_VIDEO_VERIFICATION_CALL", (obs$: Observable<TCaseVideoVerificationCallUserForm>, lens, setState, getState, routes, dispatch) => {
        obs$.pipe(
            tap((form) => setState(lens.case_details_page.data.output.details.children.video_verification_call.list.where((f) => f.edited.id === form.children.video_verification_call_id).children.users.where((f) => f.edited.id === form.edited.id).set({
                ...form,
                status: "requiresSubmission",
            }))),
            debounceTime(1000),
            tap((form) => setState(lens.case_details_page.data.output.details.children.video_verification_call.list.where((f) => f.edited.id === form.children.video_verification_call_id).children.users.where((f) => f.edited.id === form.edited.id).status.set("submitting"))),
            switchMap((form) => formOperation("UpdateCaseUserVideoVerificationCall", lens.case_details_page.data.output.details.children.video_verification_call.list.where((f) => f.edited.id === form.children.video_verification_call_id).children.users.where((f) => f.edited.id === form.edited.id).get()(getState()))),
            tap((response) => setState(lens.case_details_page.data.output.details.children.video_verification_call.list.where((f) => f.edited.id === response.children.video_verification_call_id).children.users.where((f) => f.edited.id === response.edited.id).set(response))),
            switchMap((response) =>
                response.status === "success"
                    ? from(formOperation("GetCase", lens.case_details_page.data.get()(getState())))
                        .pipe(
                            tap((r) => setState(lens.case_details_page.data.set(r)))
                        )
                    : of(undefined)
            ),
            tap(() => runAutomaticUpdates(routes, dispatch, getState, "case_details"))
        ).subscribe();
    }),
    action("LEGAL_CASE_DISASSOCIATE_USER_VIDEO_VERIFICATION_CALL", (obs$: Observable<TCaseVideoVerificationCallUserForm>, lens, setState, getState, routes, dispatch) => {
        obs$.pipe(
            tap((form) => setState(lens.case_details_page.data.output.details.children.video_verification_call.list.where((f) => f.edited.id === form.children.video_verification_call_id).children.users.where((f) => f.edited.id === form.edited.id).set({
                ...form,
                status: "submitting",
            }))),
            switchMap((form) => formOperation("DisassociateCaseUserVideoVerificationCall", lens.case_details_page.data.output.details.children.video_verification_call.list.where((f) => f.edited.id === form.children.video_verification_call_id).children.users.where((f) => f.edited.id === form.edited.id).get()(getState()))),
            tap((response) => setState(lens.case_details_page.data.output.details.children.video_verification_call.list.where((f) => f.edited.id === response.children.video_verification_call_id).children.users.where((f) => f.edited.id === response.edited.id).set(response))),
            switchMap((response) =>
                response.status === "success"
                    ? from(formOperation("GetCase", lens.case_details_page.data.get()(getState())))
                        .pipe(
                            tap((r) => setState(lens.case_details_page.data.set(r)))
                        )
                    : of(undefined)
            ),
            tap(() => runAutomaticUpdates(routes, dispatch, getState, "case_details"))
        ).subscribe();
    }),
    action("LEGAL_CASE_UPDATE_NEW_RISK_ASSESSMENT", (obs$: Observable<TCaseNewRiskAssessmentForm>, lens, setState) => {
        obs$.pipe(
            tap((form) => setState(lens.case_details_page.data.output.details.children.risk_assessments.new_form.set(form))),
        ).subscribe();
    }),
    action("LEGAL_CASE_SUBMIT_NEW_RISK_ASSESSMENT", (obs$: Observable<undefined>, lens, setState, getState) => {
        obs$.pipe(
            tap(() => setState(lens.case_details_page.data.output.details.children.risk_assessments.new_form.status.set("submitting"))),
            map(() => lens.case_details_page.data.output.details.children.risk_assessments.new_form.get()(getState())),
            switchMap((form) => formOperation("SubmitCaseRiskAssessment", form)),
            tap((response) => setState(lens.case_details_page.data.output.details.children.risk_assessments.new_form.set(response))),
            switchMap((response) =>
                response.status === "success"
                    ? from(formOperation("GetCase", lens.case_details_page.data.get()(getState())))
                        .pipe(
                            tap((r) => setState(lens.case_details_page.data.set(r)))
                        )
                    : of(undefined)
            ),
        ).subscribe();
    }),
    action("LEGAL_CASE_AUDIT_COMMENTS_CHANGE", (obs$: Observable<TCaseAuditForm>, lens, setState, getState, routes, dispatch) => {
        obs$.pipe(
            tap((payload) => setState(lens.case_details_page.data.output.details.children.audit.set(payload))),
            debounce(() => timer(1000)),
            tap(() => setState(lens.case_details_page.data.output.details.children.audit.status.set("submitting"))),
            switchMap(() => formOperation("UpdateCaseAuditComments", lens.case_details_page.data.output.details.children.audit.get()(getState()))),
            tap((response) => setState(lens.case_details_page.data.output.details.children.audit.set({
                ...response,
                edited: lens.case_details_page.data.output.details.children.audit.edited.get()(getState()),
            }))),
            tap(() => runAutomaticUpdates(routes, dispatch, getState, "case_details"))
        ).subscribe();
    }),
    action("LEGAL_CASE_AUDIT_SUBMIT_AS_COMPLETE_BUTTON_CLICKED", (obs$: Observable<TCaseAuditForm>, lens, setState) => {
        obs$.pipe(
            tap((form) => setState(lens.case_details_page.data.output.details.children.audit.set(form))),
        ).subscribe();
    }),
    action("LEGAL_CASE_AUDIT_SUBMIT_AS_COMPLETE", (obs$: Observable<TSubmitCaseAuditAsCompleteForm>, lens, setState, getState, routes, dispatch) => {
        obs$.pipe(
            tap(() => setState(lens.case_details_page.data.output.details.children.audit.children.submit_audit_as_complete.status.set("submitting"))),
            switchMap(() => formOperation("SubmitCaseAuditAsComplete", lens.case_details_page.data.output.details.children.audit.children.submit_audit_as_complete.get()(getState()))),
            tap((response) => setState(lens.case_details_page.data.output.details.children.audit.children.submit_audit_as_complete.set(response))),
            switchMap((response) =>
                response.status === "success"
                    ? from(formOperation("GetCase", lens.case_details_page.data.get()(getState())))
                        .pipe(
                            tap((r) => setState(lens.case_details_page.data.set(r)))
                        )
                    : of(undefined)
            ),
            tap(() => runAutomaticUpdates(routes, dispatch, getState, "case_details"))
        ).subscribe();
    }),
    action("CRM_CASE_QUOTE_CREATE_CHANGE", (obs$: Observable<TCreateCaseQuoteUnfilledForm>, lens, set, get) => {
        obs$.pipe(
            tap((payload) => set(lens.case_details_page.data.output.details.children.create_quote_form.set(payload))),
        ).subscribe();
    }),
    action("CRM_CASE_QUOTE_CREATE_SUBMIT", (obs$: Observable<TCreateCaseQuoteUnfilledForm>, lens, set, get, routes, dispatch) => {
        obs$.pipe(
            map((payload): TCreateCaseQuoteUnfilledForm => ({
                ...payload,
                status: "submitting",
                original: payload.edited,
            })),
            tap((payload) => set(lens.case_details_page.data.output.details.children.create_quote_form.set(payload))),
            switchMap((payload) => formOperation("CreateCaseQuote", payload as unknown as TCreateCaseQuoteFilledForm)),
            tap((response) => set(lens.case_details_page.data.output.details.children.create_quote_form.set(response as unknown as TCreateCaseQuoteUnfilledForm))),
            filter(({status}) => status === "success"),
            switchMap(() => formOperation("GetCase", lens.case_details_page.data.get()(get()))),
            tap((response) => set(lens.case_details_page.data.set(response))),
            tap(() => runAutomaticUpdates(routes, dispatch, get, "case_quotes"))
        ).subscribe();
    }),
    action("CRM_CASE_QUOTE_SENT_TO_CLIENT_FORM_CHANGE", (obs$: Observable<TCaseQuoteSentToClientForm>, lens, set, get) => {
        obs$.pipe(
            tap((payload) => set(lens.case_details_page.data.output.details.children.quote_sent_to_client_form.set(payload))),
        ).subscribe();
    }),
    action("CRM_CASE_QUOTE_SENT_TO_CLIENT_FORM_SUBMIT", (obs$: Observable<TCaseQuoteSentToClientForm>, lens, set, get, routes, dispatch) => {
        obs$.pipe(
            map((payload): TCaseQuoteSentToClientForm => ({
                ...payload,
                status: "submitting",
                original: payload.edited,
            })),
            tap((payload) => set(lens.case_details_page.data.output.details.children.quote_sent_to_client_form.set(payload))),
            switchMap((payload) => formOperation("UpdateCaseQuoteSentToClientStatus", payload as unknown as TCaseQuoteSentToClientForm)),
            tap((response) => set(lens.case_details_page.data.output.details.children.quote_sent_to_client_form.set(response as unknown as TCaseQuoteSentToClientForm))),
            filter(({status}) => status === "success"),
            switchMap(() => formOperation("GetCase", lens.case_details_page.data.get()(get()))),
            tap((response) => set(lens.case_details_page.data.set(response))),
            tap(() => runAutomaticUpdates(routes, dispatch, get, "case_quotes"))
        ).subscribe();
    }),
];

const $loadCase = <T>(lens: TStateLens, setState: TCodecSetState, getState: TGetCodecState) => 
    (obs$: Observable<T>) =>
        obs$.pipe(
            mergeMap(() => formOperation("GetCase", lens.case_details_page.data.get()(getState()))),
            tap((response) => setState(lens.case_details_page.data.set(response))),
        )
;

const $loadCaseAndEmails = <T>(lens: TStateLens, setState: TCodecSetState, getState: TGetCodecState) => 
    (obs$: Observable<T>) =>
        obs$.pipe(
            switchMap(() =>
                forkJoin([
                    formOperation("GetCase", lens.case_details_page.data.get()(getState())),
                    formOperation("GetCaseInboxEmails", {
                        ...lens.case_inbox_page.unresolved.get()(getState()),
                        children: {
                            ...lens.case_inbox_page.unresolved.get()(getState()).children,
                            emails: [],
                        }
                    }),
                ])
            ),
            tap(([details, unresolved]) => { 
                
                let emailPeople = getSimpleLegalEmailPeopleFromCaseDetails(details);
                let emailsWithPeople = patchSimpleEmailFormsWithEmailPeople(
                    unresolved.children.emails,
                    emailPeople
                );

                setState(lens.case_details_page.data.set(details))
                setState(lens.case_inbox_page.set({ 
                    unresolved: {
                        ...unresolved,
                        children: {
                            ...unresolved.children,
                            emails: emailsWithPeople
                        }
                    } 
                }));
                
            }),
        )
;