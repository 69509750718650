import { tap, switchMap, filter, debounce } from "rxjs/operators";
import { formOperation } from "../../wrappers/formOperation";
import { TActionsDefinitionsList } from "./TAction";
import { action, routeAction } from "./actionFunctions";
import { Observable, of, timer } from "rxjs";
import { TStateCodec } from "../../../../domain/codecs/state";
import { TCodecLens } from "../../../../shared/src/codecs/codecLens";
import { TCodecSetState, TGetCodecState } from "../applyActions";
import { MarketingCampaignsForm, TMarketingCampaignCreateForm, TMarketingCampaignForm } from "../../../../domain/codecs/form/MarketingCampaignForm";

export const campaingsReload$ = (
    lens: TCodecLens<TStateCodec, TStateCodec>,
    set: TCodecSetState,
    get: TGetCodecState,
    pagination?: {
        offset: number,
        limit: number,
    },
) =>
    of(
        // If an offset and limit has been provided we will use it, otherwise reload the entire visible list of campaigns
        // (offset: 0, limit: number of campaigns in the array)
        set(lens.marketing_campaigns_block.edited.set({
            offset: pagination?.offset || 0,
            limit: pagination?.limit || (
                lens.marketing_campaigns_block.children.marketing_campaigns.get()(get()).length < 20
                    ? 20
                    : lens.marketing_campaigns_block.children.marketing_campaigns.get()(get()).length
            ),
        }))
    )
        .pipe(
            switchMap(() => formOperation("GetMarketingCampaigns", lens.marketing_campaigns_block.get()(get()))),
            tap((response) => set(lens.marketing_campaigns_block.set({
                ...response,
                children: {
                    ...response.children,
                    marketing_campaigns:
                        // If an offset has been provided then a paging action is being taken
                        // We will therefor merge all the new marketing campaigns onto the array of marketing campaigns we have already requested
                        pagination?.offset
                            ? [
                                ...lens.marketing_campaigns_block.children.marketing_campaigns.get()(get()),
                                ...response.children.marketing_campaigns,
                            ]
                            : response.children.marketing_campaigns,
                }
            }))),
        );

export const actions: TActionsDefinitionsList = [
    routeAction("VIEW_CRM_LEGAL_MARKETING_CAMPAIGNS", (obs$, lens, set, get) => {
        obs$.pipe(
            tap(() => set(lens.marketing_campaigns_block.set(MarketingCampaignsForm.newDefault()))),
            switchMap(() => campaingsReload$(lens, set, get, {offset: 0, limit: 20})),
        ).subscribe();
    }),
    action("LEGAL_CASE_MARKETING_CAMPAIGN_LOAD_MORE", (obs$, lens, set, get) => {
        obs$.pipe(
            switchMap(() => campaingsReload$(lens, set, get, {
                offset: lens.marketing_campaigns_block.edited.offset.get()(get()) + 20,
                limit: 20
            })),
        ).subscribe();
    }),
    action("LEGAL_CASE_MARKETING_CAMPAIGN_CREATE", (obs$: Observable<TMarketingCampaignCreateForm>, lens, set, get) => {
        obs$.pipe(
            tap((form) => set(lens.marketing_campaigns_block.children.create_marketing_campaign_form.set({
                ...form,
                status: "submitting",
            }))),
            switchMap(() => formOperation("CreateMarketingCampaign", lens.marketing_campaigns_block.children.create_marketing_campaign_form.get()(get()))),
            tap((response) => set(lens.marketing_campaigns_block.children.create_marketing_campaign_form.set(response))),
            filter(({status}) => status === "success"),
            switchMap(() => campaingsReload$(lens, set, get)),
            debounce(() => timer(1000)),
            tap(() => set(lens.marketing_campaigns_block.children.create_marketing_campaign_form.status.set("untouched"))),
        ).subscribe();
    }),
    action("LEGAL_CASE_MARKETING_CAMPAIGN_DELETE", (obs$: Observable<TMarketingCampaignForm>, lens, set, get) => {
        obs$.pipe(
            tap((form) => set(lens.marketing_campaigns_block.children.marketing_campaigns.where((campaignForm) => campaignForm.edited.id === form.edited.id).set({
                ...form,
                status: "submitting",
            }))),
            switchMap((form) => formOperation("DeleteMarketingCampaign", lens.marketing_campaigns_block.children.marketing_campaigns.where((campaignForm) => campaignForm.edited.id === form.edited.id).get()(get()))),
            tap((response) => set(lens.marketing_campaigns_block.children.marketing_campaigns.where((campaignForm) => campaignForm.edited.id === response.edited.id).set(response))),
            filter(({status}) => status === "success"),
            switchMap(() => campaingsReload$(lens, set, get)),
        ).subscribe();
    }),
    action("LEGAL_CASE_MARKETING_CAMPAIGN_CHANGE", (obs$: Observable<TMarketingCampaignForm>, lens, set) => {
        obs$.pipe(
            tap((form) => set(
                lens.marketing_campaigns_block.children.marketing_campaigns
                    .where((campaignForm) => campaignForm.edited.id === form.edited.id)
                    .set(form))
            ),
        ).subscribe();
    }),
    action("LEGAL_CASE_MARKETING_CAMPAIGN_SAVE", (obs$: Observable<TMarketingCampaignForm>, lens, set, get) => {
        obs$.pipe(
            tap((form) => set(
                lens.marketing_campaigns_block.children.marketing_campaigns
                    .where((campaignForm) => campaignForm.edited.id === form.edited.id)
                    .set({
                        ...form,
                        status: "submitting",
                    }))
            ),
            switchMap((form) => formOperation(
                "UpdateMarketingCampaign", 
                lens.marketing_campaigns_block.children.marketing_campaigns
                    .where((campaignForm) => campaignForm.edited.id === form.edited.id)
                    .get()(get())
            )),
            tap((response) => set(
                lens.marketing_campaigns_block.children.marketing_campaigns
                    .where((campaignForm) => campaignForm.edited.id === response.edited.id)
                    .set(response))
            ),
            debounce(() => timer(800)),
            tap((response) => set(
                lens.marketing_campaigns_block.children.marketing_campaigns
                    .where((campaignForm) => campaignForm.edited.id === response.edited.id)
                    .status.set("requiresSubmission")
            ))
        ).subscribe();
    }),
];
