import React, { useState } from "react";
import { TContainerStateProps } from "../../state/TContainerStateProps";
import { CRMTitleSection } from "../../components/CRMTitleSection/CRMTitleSection";
import { CRMSpacer } from "../../components/CRMSpacer/CRMSpacer";
import { CRMAutosaveIndicatorWrap } from "../../components/CRM/CRMAutosaveIndicatorWrap/CRMAutosaveIndicatorWrap";
import { CRMSpacingColumn } from "../../components/CRMSpacingColumn/CRMSpacingColumn";
import { CRMCodecEditForm } from "../../components/CRMCodecEditForm/CRMCodecEditForm";
import { CaseAuditComments } from "../../../../domain/codecs/form/CaseForm";
import { CRMParagraph } from "../../components/Simple/CRMParagraph/CRMParagraph";
import { CRMCardOutsidePopupFormSubmit } from "../../components/CRMCardOutsidePopupFormSubmit/CRMCardOutsidePopupFormSubmit";
import { castNewFormStatusToLegacy } from "../../util";
import { CRMFormButton } from "../../components/CRMFormButton/CRMFormButton";
import { CRMButtonTertiary } from "../../components/CRMButtonTertiary/CRMButtonTertiary";

const CRMLegalCaseDetailsAuditContainer = (props: TContainerStateProps): JSX.Element => {
    
    const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);

    const auditHasBeenSubmittedAsComplete = (): boolean => props.state.forms.case_details_page.data.output.details.children.audit.children.audit_completed_at !== null;
    
    const getCommentFormStatus = () => props.state.forms.case_details_page.data.output.details.children.audit.status;

    const auditFormCanBeSubmitted = (): boolean =>
        props.state.forms.case_details_page.data.output.details.children.audit.edited.audit_comments !== ""
        && !auditHasBeenSubmittedAsComplete()
        && props.state.global.user.user_role === "sail_legal_authoriser_staff"
        && (
            props.state.forms.case_details_page.data.output.details.children.status === "completed"
            || props.state.forms.case_details_page.data.output.details.children.status === "closed_successfully"
        );


    return (
        <CRMAutosaveIndicatorWrap
            status={getCommentFormStatus()}
        >
            <CRMTitleSection>
                Case Audit
            </CRMTitleSection>
            <CRMSpacer size="large" />
            
            <CRMSpacingColumn spacing="medium">
                {auditHasBeenSubmittedAsComplete() &&
                    <CRMParagraph>
                        {props.state.forms.case_details_page.data.output.details.children.audit.original.audit_comments}
                    </CRMParagraph>
                }

                {!auditHasBeenSubmittedAsComplete() &&
                    <CRMSpacingColumn spacing="medium">
                        <CRMCodecEditForm
                            codec={CaseAuditComments}
                            model={props.state.forms.case_details_page.data.output.details.children.audit.edited}
                            validationErrors={props.state.forms.case_details_page.data.output.details.children.audit.validationErrors}
                            columns={1}
                            onChange={(edited) => {
                                props.dispatch({
                                    type: "LEGAL_CASE_AUDIT_COMMENTS_CHANGE",
                                    payload: {
                                        ...props.state.forms.case_details_page.data.output.details.children.audit,
                                        edited: {
                                            ...props.state.forms.case_details_page.data.output.details.children.audit.edited,
                                            ...edited,
                                        }
                                    }
                                })
                            }}
                        />

                        {/* BUTTON: SUBMIT AUDIT AS COMPLETE */}
                        <div>
                            <CRMFormButton
                                formStatus={auditFormCanBeSubmitted() ? "requiresSubmission" : "untouched"}
                                ButtonElement={CRMButtonTertiary}
                                label="Submit Audit as Complete"
                                disabled={!auditFormCanBeSubmitted()}
                                fullWidth={false}
                                onClick={() => {
                                    props.dispatch({
                                        type: "LEGAL_CASE_AUDIT_SUBMIT_AS_COMPLETE_BUTTON_CLICKED",
                                        payload: {
                                            ...props.state.forms.case_details_page.data.output.details.children.audit,
                                            status: "requiresSubmission",
                                        }
                                    });
                                    setIsPopupOpen(true);
                                }}
                            />
                        </div>

                        {/* POPUP */}
                        <CRMCardOutsidePopupFormSubmit
                            isOpen={isPopupOpen}
                            title="Are you sure?"
                            context="warning"
                            closeText="Cancel"
                            ctaText="Sign Off as Complete"
                            onClose={() => setIsPopupOpen(false)}
                            onCTA={() => props.dispatch({
                                type: "LEGAL_CASE_AUDIT_SUBMIT_AS_COMPLETE",
                                payload: {
                                    ...props.state.forms.case_details_page.data.output.details.children.audit.children.submit_audit_as_complete,
                                    edited: {
                                        case_id: props.state.forms.case_details_page.data.input.case_id
                                    },
                                    status: "requiresSubmission"
                                },
                            })}
                            formStatus={castNewFormStatusToLegacy(getCommentFormStatus())}
                            validationErrors={[]}
                        >
                            <CRMSpacingColumn spacing="medium">
                                <CRMParagraph>
                                    You are about to mark this case audit as complete.
                                </CRMParagraph>
                                <CRMParagraph>
                                    You won't be able to edit the audit after it's been submitted. Are you sure you want to continue?
                                </CRMParagraph>
                            </CRMSpacingColumn>
                        </CRMCardOutsidePopupFormSubmit>
                    </CRMSpacingColumn>
                }

            </CRMSpacingColumn>
        </CRMAutosaveIndicatorWrap>
    );
};

export default CRMLegalCaseDetailsAuditContainer;
