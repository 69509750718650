import React from "react";
import { EditorState } from "draft-js";
import { pipe } from "fp-ts/lib/pipeable";
import { onChangeForm } from "../../../../../../shared/src/codecs/types/form";
import { underscoreCaseToNormalCase, capitaliseFirst } from "../../../../../../shared/src/utilsByDomain/string";
import { CRMSpacing } from "../../../../models/CRMSpacing";
import { SpacingColumn } from "../../../BuildingBlocks/SpacingColumn";
import { CRMFlatInputGeneral } from "../../../CRMFlatInputGeneral/CRMFlatInputGeneral";
import { CRMRichTextArea } from "../../../CRMRichTextArea/CRMRichTextArea";
import { CRMFilterDropdownFlat } from "../../../Simple/CRMFilterDropdownFlat/CRMFilterDropdownFlat";
import { IOption } from "../../../../hooks/UseDropdown";
import { CRMDropdownFlat } from "../../../CRMDropdownFlat/CRMDropdownFlat";
import { CRMEmailInput, TEmailInputOption } from "../../../Simple/CRMEmailInput/CRMEmailInput";
import { array } from "fp-ts";
import { isEmailViewType, TComposeEmailFormCaseSearchProps, TComposeEmailFormWritingProps, TEmailViewStatus } from "../CRMComposeEmailForm/CRMComposeEmailFormProps";
import { snakeCaseToCopyText } from "../../../../../../shared/src/util";
import { TCaseSearchResult } from "../../../../../../domain/codecs/CaseSearchResult";
import { Address1ToCopyText } from "../../../../../../domain/codecs/util/Address1ToCopyText";

type TCRMComposeEmailBodyProps = 
    TComposeEmailFormWritingProps &
    TComposeEmailFormCaseSearchProps &
    {
        viewStatus: TEmailViewStatus;
        hideBody: boolean;
        emailContentState: EditorState,
        caseEmailPageCaseId?: string,
        onChangeEmailContentState: (value: EditorState) => void;
    };

export const CRMComposeEmailBody = (props: React.PropsWithChildren<TCRMComposeEmailBodyProps>): JSX.Element => {

    const onSelectCase = onChangeForm(props.form, props.onSelectCase)("caseID");
    const onChange = onChangeForm(props.form, props.onChange);

    const getSelectedCase = () => 
        props.form.children.cases_search_form.children.find((legalCase) => legalCase.id === props.form.edited.caseID)
    ;

    const caseSearchResultsToCaseDropdownOptions = (): Array<IOption<string>> => 
        props.form.children.cases_search_form.children.map((legalCase) => ({
            label: getCaseDropdownLabel(legalCase, props.caseEmailPageCaseId),
            value: legalCase.id,
        }))
    ;

    const getCaseDropdownLabel = (caseResult: TCaseSearchResult, caseEmailPageCaseId?: string): string => {
        let label = ""; 
        const status = snakeCaseToCopyText(caseResult.status);
    
        if(caseEmailPageCaseId === caseResult.id) {
            label = "This case: ";
        }
        
        if (!!caseResult.klyant_matter_id && caseResult.properties.length > 0) {
            return `${label}${caseResult.klyant_matter_id} - ${Address1ToCopyText(caseResult.properties[0].address)} (${status})`;
        }

        if (!!caseResult.klyant_matter_id && caseResult.properties.length === 0) {
            return `${label}${caseResult.klyant_matter_id} - No address given (${status})`;
        }

        if (!caseResult.klyant_matter_id && caseResult.properties.length > 0) {
            return `${label}No klyant id - ${Address1ToCopyText(caseResult.properties[0].address)} (${status})`;
        }

        return `${label}No klyant id or address entered`;
    };

    const getSendInputOptions = (): Array<TEmailInputOption> => {
        const selectedCase = getSelectedCase();

        if (!selectedCase) {
            return [];
        }
        
        return [
            ...selectedCase.emails.map((user) => ({
                name: user.name || "-- --",
                address: user.email,
                group: pipe(
                    user.role,
                    underscoreCaseToNormalCase,
                    capitaliseFirst,
                )
            })),
            ...props.additionalSendToEmailAddresses.map((email) => ({
                name: "-- --",
                address: email,
                group: "External"
            }))
        ];
    }
    
    const selectedCaseToSentFromDropdownOptions = (): Array<IOption<string>> => pipe(
        [
            getSelectedCaseHandlersAsDropdownOptions(),
            getComposingUsersEmailsAsDropdownOptions()
        ],
        array.flatten
    );

    const getSelectedCaseHandlersAsDropdownOptions = (): Array<IOption<string>> => {
        const selectedCase = getSelectedCase();
        
        if (!!selectedCase) {
            return selectedCase.case_handlers
                .filter((handler) => handler.id !== props.composingUser.id)
                .map((handler) => ({
                    value: handler.email,
                    label: (
                        <span>
                            {handler.first_name} {handler.last_name} ({handler.email})
                        </span>
                    )
                }))
        }

        return [];
    }

    const getComposingUsersEmailsAsDropdownOptions = (): Array<IOption<string>> => ([
        {
            value: props.composingUser.email,
            label: (
                <span>
                    {props.composingUser.first_name} {props.composingUser.last_name} ({props.composingUser.email})
                </span>
            )
        },
        {
            value: props.composingUser.confidential_email,
            label: (
                <span>
                    {props.composingUser.first_name} {props.composingUser.last_name} ({props.composingUser.confidential_email})
                </span>
            )
        }
    ]);

    return (
        <SpacingColumn spacing={CRMSpacing.X_LARGE}>
            {/* SENDING INSTRUCTIONS */}
            <SpacingColumn spacing={CRMSpacing.MEDIUM}>
                
                {/* SEARCH CASE - INPUT */}
                {isEmailViewType("case", props.viewStatus) &&
                    <CRMFilterDropdownFlat
                        valuePrefix="Case:"
                        value={props.form.edited.caseID ?? ""}
                        placeholder="Select case"
                        keepOriginalSortOrder={true}
                        searchText={props.form.children.cases_search_form.edited.searchString}
                        onSetSearchText={props.onChangeSearchCaseValue}
                        searchStatus={props.form.children.cases_search_form.status}
                        onChange={onSelectCase}
                        options={caseSearchResultsToCaseDropdownOptions()}
                        displayError={false}
                        preserveSearch={true}
                    />
                }

                {/* SEND TO - INPUT */}
                <CRMEmailInput
                    prefixValue="Send to:"
                    placeholder="Enter recipient"
                    selected={props.form.edited.sendTo}
                    onChange={onChange("sendTo")}
                    options={getSendInputOptions()}
                />

                {/* CC - INPUT */}
                <CRMEmailInput
                    prefixValue="CC:"
                    placeholder="Enter CC address"
                    selected={props.form.edited.cc_addresses}
                    onChange={onChange("cc_addresses")}
                    options={getSendInputOptions()}
                />

                {/* BCC - INPUT */}
                <CRMEmailInput
                    prefixValue="BCC:"
                    placeholder="Enter BCC address"
                    selected={props.form.edited.bcc_addresses}
                    onChange={onChange("bcc_addresses")}
                    options={getSendInputOptions()}
                />
                
                {/* SENT FROM - INPUT */}
                <CRMDropdownFlat
                    placeholder="Sent from"
                    valuePrefix="From:"
                    value={props.form.edited.sentFrom}
                    options={selectedCaseToSentFromDropdownOptions()}
                    onChange={onChange("sentFrom")}
                />
            </SpacingColumn>
            
            {/* CONTENT OF THE EMAIL */}
            {!props.hideBody && 
                <SpacingColumn spacing={CRMSpacing.LARGE}>
                    <CRMFlatInputGeneral
                        placeholder="Subject"
                        value={props.form.edited.subject}
                        onChange={onChange("subject")}
                    />
                    <CRMRichTextArea
                        placeholder="Begin your email here"
                        contentState={props.emailContentState}
                        onChange={props.onChangeEmailContentState}
                    />
                </SpacingColumn>
            }
        </SpacingColumn>
    );
};
