import React, { useEffect } from "react";
import { CRMAddCaseEnquiryPopup } from "../../components/CRM/CRMAddCaseEnquiryPopup/CRMAddCaseEnquiryPopup";
import { CRMCardOutsideEnquiryDocument } from "../../components/CRMCardOutsideEnquiryDocument/CRMCardOutsideEnquiryDocument";
import { CRMCaseEnquiryHeader } from "../../components/Contextual/CRMCaseEnquiry/CRMCaseEnquiryHeaderComponent/CRMCaseEnquiryHeaderComponent";
import { CRMOutsideCardEnquiry } from "../../components/Contextual/CRMCaseEnquiry/CRMOutsideCardEnquiry/CRMOutsideCardEnquiry";
import * as TCRMLegalCaseEnquiries from "../../state/TCRMLegalCaseEnquiries";
import * as CaseDocumentType1 from "../../../../domain/models/CaseDocumentType1";
import { CRMCardOutsidePopupBasic } from "../../components/CRMCardOutsidePopupBasic/CRMCardOutsidePopupBasic";
import { CRMParagraph } from "../../components/Simple/CRMParagraph/CRMParagraph";
import { CRMEditCaseEnquiryPopup } from "../../components/CRMEditCaseEnquiryPopup/CRMEditCaseEnquiryPopup";
import { array, option, apply } from "fp-ts";
import { TContainerStateProps } from "../../state/TContainerStateProps";
import { pipe } from "fp-ts/lib/function";
import { Padding } from "../../components/BuildingBlocks/Padding";
import { CRMSpacing } from "../../models/CRMSpacing";
import { SpacingColumn } from "../../components/BuildingBlocks/SpacingColumn";
import { SpacingRow } from "../../components/BuildingBlocks/SpacingRow";
import { TCaseEnquiry5Form, TCaseEnquiryAttachmentFormList, TCaseEnquiryNote1FormList } from "../../models/TFormModels";
import { CRMMultipleDefersPopoutComponent } from "../../components/CRMMultipleDefersPopoutComponent/CRMMultipleDefersPopoutComponent";

type TEnquiryCard = {
    enquiry: TCaseEnquiry5Form;
    attachment: TCaseEnquiryAttachmentFormList["forms"][number];
    note: TCaseEnquiryNote1FormList["forms"][number];
};

const CRMLegalCaseEnquiriesContainer = (props: TContainerStateProps): JSX.Element => {

    useEffect(
        () => props.dispatch({
            type: "CRM_CASE_ENQUIRIES_VIEW",
            payload: null
        }), 
        []
    );

    const unsubmittedEnquiresCount = getUnsubmittedEnquiriesCount(props.state.activeData.crm.legalCaseEnquiries);

    const getEnquiriesForCards = (): Array<TEnquiryCard> =>
        pipe(
            props.state.activeData.crm.legalCaseEnquiries.enquiries.forms,
            array.map((form) => pipe(
                apply.sequenceT(option.option)(
                    pipe(
                        props.state.activeData.crm.legalCaseEnquiries.enquiryAttachments.forms,
                        array.findFirst((enquiryAttachmentForm) => enquiryAttachmentForm.ui.enquiryId === form.view.id),
                    ),
                    pipe(
                        props.state.activeData.crm.legalCaseEnquiries.enquiryNewNotes.forms,
                        array.findFirst((enquiryNewNoteForm) => enquiryNewNoteForm.ui.enquiryId === form.view.id),
                    ),
                ),
                option.fold(
                    () => null,
                    ([enquiryAttachmentForm, enquiryNewNoteForm]) => ({
                        enquiry: form,
                        attachment: enquiryAttachmentForm,
                        note: enquiryNewNoteForm
                    })
                ),
            )),
            array.filter<TEnquiryCard | null, TEnquiryCard>((card): card is TEnquiryCard => card !== null),
        );

    const getDocumentsForCards = () => 
        props.state.activeData.crm.legalCaseEnquiries.documents.forms
            .filter((form) => CaseDocumentType1.CasesDocumentTypesCanHaveEnquiries.includes(form.view.type))
            .sort((formA, formB) => {
                if (formA.edit.all_files_checked_for_enquiries && formB.edit.all_files_checked_for_enquiries) {
                    return 0;
                }
                if (formA.edit.all_files_checked_for_enquiries && !formB.edit.all_files_checked_for_enquiries) {
                    return 1;
                }
                return -1;
            })
    ;

    return (
        <div>
            <Padding type="vertical" spacing={CRMSpacing.X_LARGE}>
                <SpacingColumn spacing={CRMSpacing.LARGE}>

                    {/* HEADER: APPROVE, FILTER */}
                    <CRMCaseEnquiryHeader
                        case={props.state.activeData.crm.legalCaseEnquiries.caseForm.view}
                        enquiries={pipe(
                            props.state.activeData.crm.legalCaseEnquiries.enquiries.forms,
                            array.map((form) => ({
                                ...form.view,
                                ...form.edit,
                            })),
                        )}
                        optionKeyValue={props.state.activeData.crm.legalCaseEnquiries.enquiryFilter.edit.filter}
                        onChange={(value) =>
                            props.dispatch({
                                type: "CRM_CASE_ENQUIRIES_FILTER_INPUT_CHANGE",
                                payload: {
                                    key: "filter",
                                    value,
                                },
                            })
                        }
                        userScope={props.state.global.user.user_role}
                        enquiriesToSubmitCount={unsubmittedEnquiresCount}
                        onClickAddEnquiry={()=>props.dispatch({ type: "CRM_CASE_ENQUIRY_CLICK_ADD_ENQUIRY"})}
                        onClickSubmitEnquiries={()=>props.dispatch({ type: "CRM_CASE_ENQUIRY_SUBMIT_ENQUIRIES_FOR_APPROVAL_BUTTON"})}
                    />

                    <SpacingRow
                        spacing={CRMSpacing.X_LARGE}
                        alignItems="flex-start"
                        childSize="2fr 1fr" 
                    >
                        {/* COLUMN - ENQUIRY CARDS */}
                        <SpacingColumn spacing={CRMSpacing.LARGE}>
                            {getEnquiriesForCards().map((card, index) => (
                                <CRMOutsideCardEnquiry
                                    key={index}
                                    enquiry={card.enquiry.view}
                                    case={props.state.activeData.crm.legalCaseEnquiries.caseForm.view}
                                    userRole={props.state.global.user.user_role}
                                    enquiryAttachmentForm={card.attachment}
                                    enquiryNewNoteForm={card.note}
                                    enquiryAttachmentFilterForm={props.state.activeData.crm.legalCaseEnquiries.enquiryAttachmentFilter}
                                    enquiryAttachmentRecipientsList={[]}
                                    enquiryAttachmentFoundEmails={[]}
                                    enquiryAttachmentFoundDocuments={ pipe(
                                        props.state.activeData.crm.legalCaseEnquiries.documents.forms,
                                        array.map((documentForm) => ({
                                            ...documentForm.view,
                                            ...documentForm.edit,
                                        })),
                                    )}
                                    enquiryTimelines={ pipe(
                                        props.state.activeData.crm.legalCaseEnquiries.enquiryTimelines.forms,
                                        array.filter((timelineForm) => timelineForm.ui.enquiryId === card.enquiry.view.id),
                                    )}
                                    onReOpen={()=>props.dispatch({ type: "CRM_CASE_ENQUIRY_REOPEN", payload: card.enquiry.view.id})}
                                    onEdit={()=>props.dispatch({ type: "CRM_CASE_ENQUIRY_CLICK_EDIT_ENQUIRY", payload: card.enquiry.view.id })}
                                    onDelete={()=>props.dispatch({ type: "CRM_CASE_ENQUIRY_DELETE", payload: card.enquiry.view.id})}
                                    onApprove={()=>props.dispatch({ type: "CRM_CASE_ENQUIRY_APPROVE", payload: card.enquiry.view.id})}
                                    onMarkAsSent={()=>props.dispatch({ type: "CRM_CASE_ENQUIRY_MARK_AS_SENT", payload: card.enquiry.view.id})}
                                    onAttach={()=> props.dispatch({ type: "CRM_CASE_ENQUIRIES_ENQUIRY_ATTACHMENT_OPEN_MODAL", payload: {resourceId: card.enquiry.view.id}})}
                                    onResolve={()=>props.dispatch({ type: "CRM_CASE_ENQUIRY_MARK_AS_RESOLVED", payload: card.enquiry.view.id})}
                                    onMarkAsNotRelevant={()=>props.dispatch({ type: "CRM_CASE_ENQUIRY_MARK_AS_NOT_RELEVANT", payload: card.enquiry.view.id})}
                                    onTimelineDelete={(payload) => props.dispatch({ type: "CRM_CASE_ENQUIRIES_ENQUIRY_TIMELINE_DELETE", payload})}
                                    onTimelineFormChange={(value) =>
                                        props.dispatch({ type: "CRM_CASE_ENQUIRIES_ENQUIRY_TIMELINE_CHANGE", payload: {
                                            value,
                                        }})
                                    }
                                    onAttachmentFormChange={(value) =>
                                        props.dispatch({ type: "CRM_CASE_ENQUIRIES_ENQUIRY_ATTACHMENT_CHANGE", payload: {
                                            resourceId: card.enquiry.view.id,
                                            value,
                                        }})
                                    }
                                    onAttachmentSelectEmailDirection={() => undefined}
                                    onAttachmentSelectRecipient={() => undefined}
                                    onAttachmentViewEmail={() => undefined}
                                    onAttachmentModalClose={() => props.dispatch({ type: "CRM_CASE_ENQUIRIES_ENQUIRY_ATTACHMENT_CLOSE_MODAL", payload: {resourceId: card.enquiry.view.id}})}
                                    onAttachmentModalSubmit={() => props.dispatch({ type: "CRM_CASE_ENQUIRIES_ENQUIRY_ATTACHMENT_SUBMIT", payload: card.enquiry.view.id })}
                                    onNewNoteModalChange={(value) =>
                                        props.dispatch({ type: "CRM_CASE_ENQUIRIES_NEW_NOTE_CHANGE", payload: {
                                            resourceId: card.enquiry.view.id,
                                            value,
                                        }})
                                    }
                                    onNewNoteModalSubmit={() => props.dispatch({ type: "CRM_CASE_ENQUIRIES_NEW_NOTE_SUBMIT", payload: card.enquiry.view.id})}
                                />
                            ))}
                        </SpacingColumn>
                        
                        {/* COLUMN - DEFER & DOCUMENT CARDS */}
                        <SpacingColumn spacing={CRMSpacing.LARGE}>
                            {/* ALL DEFERS */}
                            <CRMMultipleDefersPopoutComponent
                                defers={[
                                    {
                                        iconName: "pencil",
                                        label: "Raising Enquiries",
                                        key: "raise_enquiries_job_defer",
                                        value: props.state.forms.case_details_page.data.output.details.edited.raise_enquiries_job_defer,
                                    },
                                    {
                                        iconName: "done",
                                        label: "Approve Enquiries",
                                        key: "approve_enquiries_job_defer",
                                        value: props.state.forms.case_details_page.data.output.details.edited.approve_enquiries_job_defer,
                                    },
                                    {
                                        iconName: "email-outbound",
                                        label: "Send Enquiries",
                                        key: "send_enquiries_job_defer",
                                        value: props.state.forms.case_details_page.data.output.details.edited.send_enquiries_job_defer,
                                    },
                                    {
                                        iconName: "done-all",
                                        label: "Resolve Enquiries",
                                        key: "resolve_enquiries_job_defer",
                                        value: props.state.forms.case_details_page.data.output.details.edited.resolve_enquiries_job_defer,
                                    },
                                ]}
                                onChange={(key, value) => {
                                    props.dispatch({
                                        type: "LEGAL_CASE_DETAILS_CHANGE",
                                        payload: {
                                            ...props.state.forms.case_details_page.data.output.details,
                                            edited: {
                                                ...props.state.forms.case_details_page.data.output.details.edited,
                                                [key]: value,
                                            }
                                        }
                                    })
                                }}
                                formStatus={props.state.forms.case_details_page.data.output.details.status}
                            />

                            {/* DOCUMENT CARDS */}
                            {getDocumentsForCards().map((form, index) =>
                                <CRMCardOutsideEnquiryDocument
                                    key={index}
                                    caseDocumentForm={form}
                                    onAllFilesCheckedChange={(value) => props.dispatch({ 
                                        type: "CRM_CASE_ENQUIRIES_DOCUMENT_CHANGE",
                                        payload: {
                                            key: "all_files_checked_for_enquiries",
                                            value,
                                            resourceId: form.view.id,
                                        }
                                    })}
                                />
                            )}
                        </SpacingColumn>
                    </SpacingRow>
                </SpacingColumn>
            </Padding>
            

            {/* ## */}
            {/* ### MODALS ### */}
            {/* ## */}

            {/* MODAL: ADD CASE ENQUIRY */}
            <CRMAddCaseEnquiryPopup
                isOpen={props.state.activeData.crm.legalCaseEnquiries.addEnquiryModal === "open"}
                enquiryForm={props.state.activeData.crm.legalCaseEnquiries.addEnquiry}
                documentOptions={props.state.activeData.crm.legalCaseEnquiries.documents.forms.map((d) => ({
                    value: d.view.id,
                    label: `${CaseDocumentType1.toDisplayString(d.view.type)}${d.view.additional_name ? ` - ${d.view.additional_name}` : ""}`,
                }))}
                onChangeDocuments={(d)=> props.dispatch({
                    type: "CRM_CASE_ENQUIRY_ADD_ENQUIRY_CHANGE_DOCUMENT",
                    payload: { key: "documents", value: d },
                })}
                onChangeMessage={(m)=> props.dispatch({
                    type: "CRM_CASE_ENQUIRY_ADD_ENQUIRY_CHANGE_DOCUMENT",
                    payload: { key: "message", value: m },
                })}
                onChangeReferenceId={(value)=> props.dispatch({
                    type: "CRM_CASE_ENQUIRY_ADD_ENQUIRY_CHANGE_DOCUMENT",
                    payload: { key: "reference_id", value },
                })}
                onClose={()=> props.dispatch({ type: "CRM_CASE_ENQUIRY_CLICK_CLOSE_ADD_ENQUIRY_MODAL"})}
                onSubmit={()=> props.dispatch({ type: "CRM_CASE_ENQUIRY_ADD_ENQUIRY_SUBMIT"})}
            />

            {/* MODAL: EDIT ENQUIRY MODAL */}
            <CRMEditCaseEnquiryPopup
                isOpen={props.state.activeData.crm.legalCaseEnquiries.editEnquiryModal === "open"}
                enquiryForm={props.state.activeData.crm.legalCaseEnquiries.editEnquiry}
                documentOptions={props.state.activeData.crm.legalCaseEnquiries.documents.forms.map((d) => ({
                    value: d.view.id,
                    label: CaseDocumentType1.toDisplayString(d.view.type),
                }))}
                onChangeDocuments={(d)=> props.dispatch({
                    type: "CRM_CASE_ENQUIRY_EDIT_ENQUIRY_CHANGE_DOCUMENT",
                    payload: { key: "documents", value: d },
                })}
                onChangeMessage={(m)=> props.dispatch({
                    type: "CRM_CASE_ENQUIRY_EDIT_ENQUIRY_CHANGE_DOCUMENT",
                    payload: { key: "message", value: m },
                })}
                onClose={()=> props.dispatch({ type: "CRM_CASE_ENQUIRY_CLICK_CLOSE_EDIT_ENQUIRY_MODAL"})}
                onSubmit={()=> props.dispatch({ type: "CRM_CASE_ENQUIRY_EDIT_ENQUIRY_SUBMIT"})}
            />

            {/* MODAL: SUBMIT ENQUIRIES CONFIRMATION */}
            <CRMCardOutsidePopupBasic
                isOpen={props.state.activeData.crm.legalCaseEnquiries.submitEnquiriesForApprovalModal === "open" ? true : false}
                title={"Submit Enquiries"}
                onClose={() => props.dispatch({ type: "CRM_CASE_ENQUIRY_CLOSE_SUBMIT_ENQUIRIES_MODAL"})}
                onCTA={() => props.dispatch({ type: "CRM_CASE_SUBMIT_ENQUIRIES_FOR_APPROVAL"})}
                context="warning"
                ctaText={"Submit"}
                ctaDisabled={false}
            >
                <CRMParagraph>
                    {`You are about to submit ${unsubmittedEnquiresCount} ${unsubmittedEnquiresCount > 1 ? "enquiries" : "enquiry"} for approval. Proceed?`}
                </CRMParagraph>
            </CRMCardOutsidePopupBasic>
        </div>
    );
};

const getUnsubmittedEnquiriesCount = (s: TCRMLegalCaseEnquiries.C): number =>
    s.enquiries.forms
    .filter((e) => e.view.status === "unsubmitted")
    .length;

export default CRMLegalCaseEnquiriesContainer;