import React from "react";
import { CRMGeneralTextBox } from "../CRMGeneralTextBox/CRMGeneralTextBox";
import { CRMTitleForTextBox } from "../CRMTitleForTextBox/CRMTitleForTextBox";
import { CRMSpacer } from "../CRMSpacer/CRMSpacer";
import { array, option, nonEmptyArray } from "fp-ts";
import { CRMSpacingRow } from "../CRMSpacingRow/CRMSpacingRow";
import { CRMButtonQuaternary } from "../CRMButtonQuaternary/CRMButtonQuaternary";
import CRMInputLabelAndErrorWrapComponent from "../CRMInputLabelAndErrorWrapComponent/CRMInputLabelAndErrorWrapComponent";
import CRMTextAreaComponent from "../CRMTextAreaComponent/CRMTextAreaComponent";
import { CRMCardOutsidePopupBasic } from "../CRMCardOutsidePopupBasic/CRMCardOutsidePopupBasic";
import { CRMEnquiriesPreviousOffersRowComponent } from "../CRMEnquiriesPreviousOffersRowComponent/CRMEnquiriesPreviousOffersRowComponent";
import { pipe } from "fp-ts/lib/function";
import { CRMInputCheckboxComponent } from "../CRMInputCheckboxComponent/CRMInputCheckboxComponent";
import { CRMDeferButtonComponent } from "../CRMDeferButtonComponent/CRMDeferButtonComponent";
import { TListingEnquiryOfferForm } from "../../../../domain/codecs/formEditable/ListingEnquiryForm";
import { DateTime } from "luxon";

export class CRMEnquiriesPreviousOffersComponent extends React.Component<{
    offers: Array<TListingEnquiryOfferForm>;
    onChange: (value: TListingEnquiryOfferForm) => void;
}, {
    withdrawPopupOpen: boolean;
    withdrawReason: string;
}> {
    constructor(props: CRMEnquiriesPreviousOffersComponent["props"]) {
        super(props);
        this.state = {
            withdrawPopupOpen: false,
            withdrawReason: "",
        };
    }

    openPopup = (): void =>
        this.setState({
            withdrawPopupOpen: true,
        });

    closeAndResetPopup = (): void =>
        this.setState({
            withdrawPopupOpen: false,
            withdrawReason: "",
        });

    getCurrentOffer = (): option.Option<TListingEnquiryOfferForm> =>
        array.head(this.props.offers);

    getPreviousOffers = (): option.Option<nonEmptyArray.NonEmptyArray<TListingEnquiryOfferForm>> =>
         pipe(
            this.props.offers,
            array.tail,
            option.chain(nonEmptyArray.fromArray),
        );

    public render (): JSX.Element {
        return (
            <>
                {/* Current offer */}
                <CRMGeneralTextBox>
                    { pipe(
                        this.getCurrentOffer(),
                        option.fold(
                            () => <>
                                <CRMTitleForTextBox>
                                    No offers yet
                                </CRMTitleForTextBox>
                                <CRMSpacer size="medium" />
                            </>,
                            (currentOffer) => <>
                                <CRMTitleForTextBox>
                                    Current offer
                                </CRMTitleForTextBox>
                                <CRMSpacer size="medium" />

                                {/* Show the current offer and conditionally the withdraw button */}
                                {currentOffer.edited.status === "pending"
                                    ? <CRMSpacingRow justifyContent="space-between" alignItems="flex-start" spacing="medium">
                                        <CRMEnquiriesPreviousOffersRowComponent
                                            offer={currentOffer}
                                        />
                                        <CRMButtonQuaternary
                                            label="Withdraw"
                                            onClick={this.openPopup}
                                        />
                                    </CRMSpacingRow>
                                    : <CRMEnquiriesPreviousOffersRowComponent
                                        offer={currentOffer}
                                    />}

                                {currentOffer.edited.status !== "withdrawn" && <>
                                    <CRMSpacer size="medium" />
                                    <CRMSpacingRow justifyContent="space-between" alignItems="flex-start" spacing="medium">
                                        <CRMInputCheckboxComponent
                                            label="Offer feedback shared with buyer"
                                            checked={currentOffer.edited.feedback_shared_with_buyer_at !== null}
                                            onClick={() => this.props.onChange({
                                                ...currentOffer,
                                                edited: {
                                                    ...currentOffer.edited,
                                                    feedback_shared_with_buyer_at: currentOffer.edited.feedback_shared_with_buyer_at ? null : DateTime.utc().toISO(),
                                                }
                                            })}
                                        />
                                        <CRMDeferButtonComponent
                                            value={currentOffer.edited.feedback_shared_with_buyer_deferred}
                                            onChange={(feedback_shared_with_buyer_deferred) => this.props.onChange({
                                                ...currentOffer,
                                                edited: {
                                                    ...currentOffer.edited,
                                                    feedback_shared_with_buyer_deferred,
                                                }
                                            })}
                                            displayError={false}
                                            popoutPosition="above-button"
                                        />
                                    </CRMSpacingRow>
                                </>}

                                {/* If open show the withdraw popup */}
                                {this.state.withdrawPopupOpen && currentOffer.edited.status === "pending" && <>
                                    <CRMCardOutsidePopupBasic
                                        isOpen={this.state.withdrawPopupOpen}
                                        title="Withdraw"
                                        ctaText="Continue"
                                        onClose={this.closeAndResetPopup}
                                        closeText="Cancel"
                                        onCTA={() => {
                                            this.props.onChange({
                                                ...currentOffer,
                                                edited: {
                                                    ...currentOffer.edited,
                                                    status: "withdrawn",
                                                    withdrawn_reason: this.state.withdrawReason,
                                                    shared_with_seller_at: null,
                                                }
                                            });
                                            this.closeAndResetPopup();
                                        }}
                                        ctaDisabled={!this.state.withdrawReason}
                                    >
                                        <CRMInputLabelAndErrorWrapComponent
                                            label="Reason for withdrawing offer"
                                        >
                                            <CRMTextAreaComponent
                                                displayError={false}
                                                value={this.state.withdrawReason}
                                                onChange={(withdrawReason) => this.setState({
                                                    withdrawReason,
                                                })}
                                            />
                                        </CRMInputLabelAndErrorWrapComponent>
                                    </CRMCardOutsidePopupBasic>
                                </>}
                                <CRMSpacer size="medium" />
                            </>
                        )
                    )}
                </CRMGeneralTextBox>

                {/* Previous offers */}
                { pipe(
                    this.getPreviousOffers(),
                    option.fold(
                        () => <></>,
                        (previousOffers) => <>
                            <CRMSpacer size="medium" />
                            <CRMGeneralTextBox>
                                <CRMTitleForTextBox>{this.props.offers.length
                                    ? "Previous offers"
                                    : "No offers yet"
                                }</CRMTitleForTextBox>
                                <CRMSpacer size="medium" />
                                { pipe(
                                    previousOffers,
                                    array.mapWithIndex((i, offer) => <div key={i}>
                                        <CRMEnquiriesPreviousOffersRowComponent
                                            key={i}
                                            offer={offer}
                                        />
                                        {i < this.props.offers.length - 1 && <CRMSpacer size="tiny" />}
                                    </div>)
                                )}
                                {this.props.offers.length > 0 && <CRMSpacer size="medium" />}
                            </CRMGeneralTextBox>
                        </>
                    )
                )}
            </>
        );
    }
}
