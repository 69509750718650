import React, { useState } from "react";
import { CRMIcon } from "../../../CRMIcon/CRMIcon";
import { CRMColors } from "../../../../models/CRMColors";
import { SpacingRow } from "../../../BuildingBlocks/SpacingRow";
import { CRMSpacing } from "../../../../models/CRMSpacing";
import { TKeyDateTaskForms } from "../../../../../../domain/codecs/form/CasesForm";
import { toDateFormat1 } from "../../../../../../shared/src/dateTime";
import { TIcon } from "../../../CRMIcon/CRMIcon";
import { underscoreCaseToNormalCase } from "../../../../../../shared/src/utilsByDomain/string";
import { capitalizeEveryWord } from "../../../../../../shared/src/utilsByDomain/string/capitalizeEveryWord";
import { pipe } from "fp-ts/lib/function";
import { TFontWeightNumeric } from "../../../../models/TCRMFontWeight";
import { createChangeRouteAction } from "../../../../state/router/createChangeRouteAction";
import { makeJobLinkOutOfRouteAction } from "../../../CRMJobs/CRMJobsOverlay/CRMJobsCardLinkGenerators";
import { Routes } from "../../../../state/router/routerRoutes";
import { Background } from "../../../BuildingBlocks/Background";
import { CRMHoverHighlightText, THoverHighlightTextProps } from "../../../Simple/CRMHoverHighlightText/CRMHoverHighlightText";
import { IIcon } from "../../../CRMIcon/CRMIcon";

type TCasesTaskRowChangeableProps = {
    form: TKeyDateTaskForms;
};

export const CRMCasesTaskRow = (props: TCasesTaskRowChangeableProps): JSX.Element => {

    const IsForDayView = !!props.form.children["address"];
    const IsDone = props.form.children.is_done;
    const TaskTagName = pipe(
        props.form.original.tag,
        underscoreCaseToNormalCase,
        capitalizeEveryWord
    );

    const onClickAddress = () => {
        let link = makeJobLinkOutOfRouteAction(
            createChangeRouteAction(
                "VIEW_CRM_LEGAL_CASE", 
                { caseId: props.form.children.case_id },
                { visible_tab: "" },
                new Routes().blockParams.VIEW_CRM_LEGAL_CASE,
            )
        );
        window.open(link, "_blank");
    }

    return (
        <CRMCasesTaskRowBody>
            <SpacingRow
                spacing={CRMSpacing.SMALL}
                childSize="24px 1fr"
                alignItems="flex-start"
            >
                {/* CELL - CHECKBOX */}
                {!IsDone && 
                    <CRMCasesTaskRowIconCell
                        iconName="clock"
                        colour="hex"
                        hexColour={CRMColors.NEUTRAL_4}
                    /> 
                }
                {IsDone &&
                    <CRMCasesTaskRowIconCell
                        iconName="done"
                        colour="hex"
                        hexColour={CRMColors.NEUTRAL_INK}
                    />
                }
                {/* CELLS - TEXT*/}
                <SpacingRow
                    spacing={CRMSpacing.MEDIUM}
                    // There is no assign user here, yet we set 30px as if it is.
                    // That's because 1fr without 30px here becomes larger than 
                    // the changable tasks ones and you get a misalignment of task note.
                    childSize={IsForDayView ? "80px 1fr 3fr 30px" : "80px 1fr 30px"}
                    alignItems="flex-start"
                >
                    {/* CELL - DUE DATE */}
                    <CRMCasesTaskRowCell>
                        {toDateFormat1(props.form.original.date)}
                    </CRMCasesTaskRowCell>

                    {/* IF DAY VIEW: CELL - CASE ADDRESS  */}
                    {IsForDayView  &&
                        <CRMCasesTaskRowCell onClick={onClickAddress}>
                            {props.form.children["address"]}
                        </CRMCasesTaskRowCell>
                    }
                    
                    {/* CELL - TAG NAME */}
                    <CRMCasesTaskRowCell weight={600}>
                        {TaskTagName}
                    </CRMCasesTaskRowCell>
                </SpacingRow>
            </SpacingRow>
        </CRMCasesTaskRowBody>
    );
};

type TCRMCasesTaskRowBody = {
    divRef?: React.RefObject<HTMLDivElement>;
    isActive?: boolean;
    isClickable?: boolean;
};

export const CRMCasesTaskRowBody = (props: React.PropsWithChildren<TCRMCasesTaskRowBody> ): JSX.Element => {
    return (
        <div
            ref={props.divRef}
            className={`
                crm-cases-task-row
                crm-cases-task-row--${props.isActive ? 'active' : 'in-active'}
                crm-cases-task-row--${props.isClickable ? 'clickable' : 'not-clickable'}
            `}
        >
            {props.children}
        </div>
    )
}

type TCRMCasesTaskRowCellProps = {
    weight?: TFontWeightNumeric;
    onClick?: () => void;
}

export const CRMCasesTaskRowIconCell = (props: IIcon) => {
    return (
        <Background display="flex" padding={`10px 0px`}>
            <CRMIcon {...props} />
        </Background>
    )
}

export const CRMCasesTaskRowCell = (props: React.PropsWithChildren<TCRMCasesTaskRowCellProps> ): JSX.Element => {
    return (
        <div 
            className="crm-cases-task-row__cell"
            style={{
                fontWeight: props.weight || 700,
                cursor:
                    props.onClick
                        ? "pointer"
                        : "auto"
            }}
            onClick={props.onClick}
        >
            {props.children}
        </div>
    )
}

export const CRMCasesTaskRowEditableCell = (props: React.PropsWithChildren<THoverHighlightTextProps>): JSX.Element => {
    return (
        <Background display="flex" padding={`12px 0px`}>
            <CRMHoverHighlightText {...props}>
                {props.children}
            </CRMHoverHighlightText>
        </Background>
    )
}