import React from "react";
import { Padding } from "../../../BuildingBlocks/Padding";
import { useNumberInput } from "../../../../hooks/useNumberInput";
import { SpacingRow } from "../../../BuildingBlocks/SpacingRow";
import { FrontSpacing } from "../../../../models/FrontSpacing";
import { Background } from "../../../BuildingBlocks/Background";
import { FontQuicksand } from "../../../BuildingBlocks/FontQuicksand";
import { FontSize } from "../../../BuildingBlocks/FontSize";
import { FrontFontSizes } from "../../../../models/FrontFontSizes";
import { TextColor } from "../../../BuildingBlocks/TextColor";
import { FrontColors } from "../../../../models/FrontColors";
import { useOnClickFocus } from "../../../../hooks/useOnClickFocus";
import { CursorText } from "../../../CursorText/CursorText";

interface IFrontInputCurrencyComponentProps {
    value: number | null;
    disabled?: boolean;
    currencySymbol?: string;
    displayError?: boolean;
    placeholder?: string;
    textAlignment?: "left" | "center" | "right";
    excludeInFullstory?: boolean;
    fullWidth?: boolean,
    onChange: (value: number | null) => void;
    onPressEnterKey?: () => void;   
}

export const FrontInputCurrencyComponent = (props: IFrontInputCurrencyComponentProps): JSX.Element => {
    const {
        faceText,
        onChange
    } = useNumberInput(
        props.value, 
        props.onChange, 
        "pence"
    );

    const {
        onClickMoveFocus,
        focusThisRef,
    } = useOnClickFocus();

    const borderColour =
        props.displayError
            ? FrontColors.ERROR_FIREBRICK_10
            : FrontColors.NEUTRAL_10;

    return (
        <Background
            borderRadius="5px"
            borderDefinition={`solid 1px ${borderColour}`}
            width={props.fullWidth ? "100%": "250px"}
            onClick={onClickMoveFocus}
        >
            <CursorText onClick={onClickMoveFocus}>
                <Padding spacing={`12px ${FrontSpacing.SMALL_3}`}>
                    <SpacingRow spacing={FrontSpacing.SMALL_1}>
                        {/* CURRENCY SYMBOL */}
                        <FontQuicksand>
                            <FontSize size={FrontFontSizes.MED}>
                                <TextColor color={FrontColors.NEUTRAL_INK}>
                                    {props.currencySymbol || "£"}
                                </TextColor>
                            </FontSize>
                        </FontQuicksand>

                        {/* INPUT */}
                        <input
                            ref={focusThisRef}
                            type="text"
                            value={faceText}
                            placeholder={props.placeholder}
                            disabled={props.disabled}
                            onChange={onChange}
                            style={{
                                outline: "none",
                                appearance: "none",
                                WebkitAppearance: "none",
                                border: "none",
                                width: "100%",
                                fontSize: FrontFontSizes.SMALL,
                            }}
                        />
                    </SpacingRow>
                </Padding>
            </CursorText>
        </Background>
    );
}

export default FrontInputCurrencyComponent;