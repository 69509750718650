import React from "react";
import { CRMColors } from "../../../models/CRMColors";
import { CRMIcon } from "../../CRMIcon/CRMIcon";
import { WeightBold } from "../../WeightBold/WeightBold";
import { toDateFormat1 } from "../../../../../shared/src/dateTime";
import { Text } from "../../BuildingBlocks/Text";
import { CRMFontSizes } from "../../../models/CRMFontSizes";
import { Padding } from "../../BuildingBlocks/Padding";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { SpacingRow } from "../../BuildingBlocks/SpacingRow";
import { WeightRegular } from "../../WeightRegular/WeightRegular";
import { Truncate } from "../../BuildingBlocks/Truncate";
import { CursorPointer } from "../../CursorPointer/CursorPointer";
import { Relative } from "../../BuildingBlocks/Relative";
import { useHover } from "../../../hooks/useHover";
import { CRMTimelineControlsWrapper } from "../../CRMTimelineControlsWrapper/CRMTimelineControlsWrapper";
import { Background } from "../../BuildingBlocks/Background";
import { isAPlainFunction } from "../../../functions/functions";

export const CRMTimelineEmail = (props: {
    date: string,
    senderName: string,
    snippet: string,
    onEmailOpen: () => void;
    onClickDelete?: () => void;
}): JSX.Element => {
    const [ref, isHovering] = useHover<HTMLDivElement>({});
    
    return (
        <Relative divRef={ref}>
            {/* EMAIL CARD */}
            <Background
                color={CRMColors.NEUTRAL_PAPER}
                borderDefinition={`1px solid ${CRMColors.NEUTRAL_0}`}
                borderRadius="5px"
            >
                <CursorPointer onClick={props.onEmailOpen}>
                    <Padding spacing={CRMSpacing.SMALL}>
                        <Text size={CRMFontSizes.SMALL_PLUS} color={CRMColors.NEUTRAL_0}>
                            <SpacingRow justifyContent="start" spacing={CRMSpacing.SMALL}>
                                {/* ICON */}
                                <CRMIcon
                                    iconName="email"
                                    colour="neutral-0"
                                    size="tiny"
                                />

                                {/* DATE */}
                                <WeightRegular>{ toDateFormat1(props.date) }</WeightRegular>

                                {/* SENDER  NAME */}
                                <WeightBold>{props.senderName}</WeightBold>

                                {/* SNIPPET */}
                                <Truncate>
                                    <WeightRegular>
                                        {props.snippet}
                                    </WeightRegular>
                                </Truncate>
                            </SpacingRow>
                        </Text>
                    </Padding>
                </CursorPointer>
            </Background>

            {/* DELETE */}
            {isHovering && isAPlainFunction(props.onClickDelete) && 
                <CRMTimelineControlsWrapper
                    top="0px"
                    right="0px"
                    bottom="0px"
                    backgroundColour={CRMColors.NEUTRAL_PAPER}
                >
                    <CursorPointer onClick={props.onClickDelete}>
                        <CRMIcon
                            iconName="delete"
                            colour="neutral-ink"
                            size="small"
                        />
                    </CursorPointer>
                </CRMTimelineControlsWrapper>
            }
        </Relative>
    );
};
