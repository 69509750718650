import * as rxjs from "rxjs";
import * as rxjsOperators from "rxjs/operators";
import { option } from "fp-ts/lib";
import * as fetchWrapper from "../../../wrappers/fetch";
import * as FirstPartyFetchResponse from "../../../../../domain/models/FirstPartyFetchResponse";
import * as ListingSuccessResponse2 from "../../../../../domain/models/ListingSuccessResponse2";
import * as PartiesSuccessResponse1 from "../../../../../domain/models/PartiesSuccessResponse1";
import * as ListingFilter1 from "../../../../../domain/models/ListingFilter1";
import * as PartiesFilter1 from "../../../../../domain/models/PartiesFilter1";
import * as util from "../../../util";
import { TActionObservable } from "../../applyActions";
import { TGetState } from "../../TGetState";
import { TSetState } from "../../TSetState";
import { TActionsDefinitionsList } from "../TAction";
import { TChangeRouteAction } from "../../router/routerTypes";

export const actions: TActionsDefinitionsList = [
    {
        type: "VIEW_CRM_SEARCH",
        run: (
            obs$: rxjs.Observable<TChangeRouteAction<"VIEW_CRM_SEARCH">>,
            getState: TGetState,
            setState: TSetState,
        ): void => {
            obs$.subscribe(() => {
                setState(({...s}) => {
                    s.activeData.crm.search.searchInput = "";
                    s.activeData.crm.search.listingsSearchResponse = FirstPartyFetchResponse.create2XX(ListingSuccessResponse2.newDefault());
                    s.activeData.crm.search.partiesSearchResponse = FirstPartyFetchResponse.create2XX(PartiesSuccessResponse1.newDefault());
                    return s;
                });
            });
        },
    },
    {
        type: "CRM_SEARCH_INPUT_CHANGED" as const,
        run: (
            obs$: TActionObservable<"CRM_SEARCH_INPUT_CHANGED", string>,
            getState: TGetState,
            setState: TSetState,
        ): void => {
            obs$.pipe(
                rxjsOperators.tap(({payload}) =>
                    setState(({...s}) => {
                        s.activeData.crm.search.searchInput = payload;
                        s.activeData.crm.search.listingsSearchResponse = FirstPartyFetchResponse.createPending(s.activeData.crm.search.listingsSearchResponse.response);
                        s.activeData.crm.search.partiesSearchResponse = FirstPartyFetchResponse.createPending(s.activeData.crm.search.partiesSearchResponse.response);
                        return s;
                    }),
                ),
                rxjsOperators.debounceTime(250),
                rxjsOperators.switchMap(({payload}) =>
                    payload.trim() === ""
                        ? rxjs.of(setState(({...s}) => {
                            s.activeData.crm.search.listingsSearchResponse = FirstPartyFetchResponse.create2XX(ListingSuccessResponse2.newDefault());
                            s.activeData.crm.search.partiesSearchResponse = FirstPartyFetchResponse.create2XX(PartiesSuccessResponse1.newDefault());
                            return s;
                        }))
                        : rxjs.forkJoin(
                            makeListingsRequest(setState, getState, 20),
                            makePartiesRequest(setState, getState, 20),
                        ),
                ),
                rxjsOperators.mapTo(undefined),
            ).subscribe();
        },
    },
    {
        type: "CRM_SEARCH_LOAD_MORE_LISTINGS" as const,
        run: (
            obs$: TActionObservable<"CRM_SEARCH_LOAD_MORE_LISTINGS", undefined>,
            getState: TGetState,
            setState: TSetState,
        ): void => {
            obs$.pipe(
                rxjsOperators.mergeMap(() =>
                    makeListingsRequest(setState, getState, getState().activeData.crm.search.listingsSearchResponse.response.meta.limit + 20)
                ),
            ).subscribe();
        },
    },
    {
        type: "CRM_SEARCH_LOAD_MORE_PARTIES" as const,
        run: (
            obs$: TActionObservable<"CRM_SEARCH_LOAD_MORE_PARTIES", undefined>,
            getState: TGetState,
            setState: TSetState,
        ): void => {
            obs$.pipe(
                rxjsOperators.mergeMap(() =>
                    makePartiesRequest(setState, getState, getState().activeData.crm.search.partiesSearchResponse.response.meta.limit + 20)
                ),
            ).subscribe();
        },
    },
];

const makeListingsRequest = (setState: TSetState, getState: TGetState, limit: number): rxjs.Observable<void> =>
    rxjs.from(
        fetchWrapper.json<ListingSuccessResponse2.T>({
            requestParams: {
                url: `${env.REACT_APP_API_URL}/v1/listings?filters=${encodeURIComponent(JSON.stringify(ListingFilter1.createForAddress(getState().activeData.crm.search.searchInput)))}&offset=0&limit=${limit}`,
                method: "GET",
                body: option.none,
            },
            expectedTypeCodec: ListingSuccessResponse2.codec,
            defaultResponse: getState().activeData.crm.search.listingsSearchResponse.response,
        })(),
    )
        .pipe(
            rxjsOperators.tap(util.defaultCRMRequestErrorHandler),
            rxjsOperators.tap((res) => {
                setState(({...s}) => {
                    s.activeData.crm.search.listingsSearchResponse = res;
                    return s;
                });
            }),
            rxjsOperators.mapTo(undefined),
        );

const makePartiesRequest = (setState: TSetState, getState: TGetState, limit: number): rxjs.Observable<void> =>
    rxjs.from(
        fetchWrapper.json<PartiesSuccessResponse1.T>({
            requestParams: {
                url: `${env.REACT_APP_API_URL}/v1/parties?filters=${encodeURIComponent(JSON.stringify(PartiesFilter1.createForGenericSearch(getState().activeData.crm.search.searchInput) ))}&offset=0&limit=${limit}`,
                method: "GET",
                body: option.none,
            },
            expectedTypeCodec: PartiesSuccessResponse1.codec,
            defaultResponse: getState().activeData.crm.search.partiesSearchResponse.response,
        })(),
    )
        .pipe(
            rxjsOperators.tap(util.defaultCRMRequestErrorHandler),
            rxjsOperators.tap((res) => {
                setState(({...s}) => {
                    s.activeData.crm.search.partiesSearchResponse = res;
                    return s;
                });
            }),
            rxjsOperators.mapTo(undefined),
        );
