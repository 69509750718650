import * as t from "io-ts";
import * as CaseEnquiryStatusType from "./CaseEnquiryStatusType";

export const codec = t.type({
    id: t.string,
    message: t.string,
    status: CaseEnquiryStatusType.codec,
});

export type T = t.TypeOf<typeof codec>;

export const newDefault = (): T => ({
    id: "",
    message: "",
    status: "unsubmitted",
});
