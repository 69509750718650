import { useEffect, useState } from "react";

export const useCallbackOnUniquePropChange = <T extends unknown>(
    callback: (current: T, old: T) => void,
    value: T
) => {
    
    const [cachedValue, setCachedValue] = useState(value);
    
    useEffect(
        () => {
            if (cachedValue !== value) {
                setCachedValue(value);
                callback(value, cachedValue);
            }
        },
        [value]
    );
}