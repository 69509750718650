import { array, option } from "fp-ts";
import { pipe } from "fp-ts/lib/function";
import { NonEmptyArray } from "fp-ts/lib/NonEmptyArray";
import React from "react";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { MinHeight } from "../../BuildingBlocks/MinHeight";
import { SpacingRow } from "../../BuildingBlocks/SpacingRow";
import { CRMBottomControlBar } from "../../CRMBottomControlBar/CRMBottomControlBar";
import { CRMBlockDropdown, TBlockDropdownSection } from "../../Simple/CRMBlockDropdown/CRMBlockDropdown";

export const CRMDropdownControlledBlock = <A extends string, B extends A>(props: React.PropsWithChildren<{
    menuMode: "full_width" | "min_width",
    sections: NonEmptyArray<
        TBlockDropdownSection<A>
        & {
            content: JSX.Element,
            controlElements?: JSX.Element,
        }
    >,
    value: B,
    onValueChange: (value: B) => void,
}>): JSX.Element => {
    const getSelectedSection = () =>
        pipe(
            props.sections,
            array.findFirst((section) => section.value === props.value),
            option.getOrElse(() => props.sections[0]),
        );

    const getSelectedSectionContent = (): JSX.Element =>
        getSelectedSection().content;

    const getSelectedSectionLabel = (): string =>
        getSelectedSection().label;

    const getSelectedSectionControlElement = (): JSX.Element =>
        getSelectedSection().controlElements || <></>;

    const hasAnyControls =
        pipe(
            props.sections,
            array.filter(({controlElements}) => !!controlElements),
            (a) => a.length > 0,
        );

    return (
        <>
            {/* SELECTED CONTENT */}
            <MinHeight height="800px">
                {getSelectedSectionContent()}
            </MinHeight>

            <CRMBottomControlBar height="46px">
                <SpacingRow
                    spacing={CRMSpacing.MEDIUM}
                    justifyContent="space-between"
                    alignItems="center"
                    childSize={hasAnyControls || props.menuMode === "min_width" ? "auto" : "100%"}
                >
                    {/* DROPDOWN COMP */}
                    <CRMBlockDropdown
                        sections={props.sections}
                        value={props.value}
                        label={getSelectedSectionLabel()}
                        onValueChange={props.onValueChange}
                    />

                    {/* CONTROLS ELEMENTS */}
                    {getSelectedSectionControlElement()}
                </SpacingRow>
            </CRMBottomControlBar>
        </>
    );
};
