import * as ViewingType from "./ViewingType";
import * as util from "../../shared/src/util";

type T =
    "Online"
    | "In person";

export const fromViewingType = (p: ViewingType.T): T => {
    switch (p) {
        case "online":
            return "Online";
        case "in_person":
            return "In person";
        default:
            return util.requireExhaustive(p);
    }
};
