import React from "react";
import { TContainerStateProps } from "./../../state/TContainerStateProps";
import { CRMSpacingColumn } from "../../components/CRMSpacingColumn/CRMSpacingColumn";
import { CRMSpacingRow } from "../../components/CRMSpacingRow/CRMSpacingRow";
import { CRMTitleSection } from "../../components/CRMTitleSection/CRMTitleSection";
import { CRMSpacer } from "../../components/CRMSpacer/CRMSpacer";
import { CRMAutosaveIndicatorWrap } from "../../components/CRM/CRMAutosaveIndicatorWrap/CRMAutosaveIndicatorWrap";
import { CRMCodecEditForm } from "../../components/CRMCodecEditForm/CRMCodecEditForm";
import { pipe } from "fp-ts/lib/function";
import { array } from "fp-ts";
import { FormStatus_highestPriority } from "../../../../shared/src/codecs/codec";
import { CRMVerticalTabs } from "../../components/Complex/CRMVerticalTabs/CRMVerticalTabs";
import { CRMInfoBoxSimpleColourComponent } from "../../components/CRMInfoBoxSimpleColourComponent/CRMInfoBoxSimpleColourComponent";
import { CRMPadding } from "../../components/Simple/CRMPadding/CRMPadding";
import { FontSize } from "../../components/BuildingBlocks/FontSize";
import { FontSignikaNegative } from "../../components/BuildingBlocks/FontSignikaNegative";
import { WeightBold } from "../../components/WeightBold/WeightBold";
import { CRMFontSizes } from "../../models/CRMFontSizes";
import { CRMNoticeBoxComponent } from "../../components/CRMNoticeBoxComponent/CRMNoticeBoxComponent";
import { CasesBankAccountVerificationSteps } from "../../../../domain/codecs/CasesBankAccount";
import { CRMTitleSubSection } from "../../components/CRMTitleSubSection/CRMTitleSubSection";
import { CRMParagraph } from "../../components/Simple/CRMParagraph/CRMParagraph";
import { DateTime } from "luxon";
import { CRMRadioListComponent } from "../../components/CRMInputs/CRMRadioListComponent/CRMRadioListComponent";
import { CRMInputLabelAndErrorWrapComponent } from "../../components/CRMInputLabelAndErrorWrapComponent/CRMInputLabelAndErrorWrapComponent";
import { CRMDeferButtonComponent } from "../../components/CRMDeferButtonComponent/CRMDeferButtonComponent";
import { CRMLink } from "../../components/CRMLink/CRMLink";

const CRMLegalCaseDetailsClientBankAccountsContainer = (props: TContainerStateProps): JSX.Element => (
    <CRMAutosaveIndicatorWrap
        status={pipe(
            [
                props.state.forms.case_details_page.data.output.details.status,
                ...pipe(
                    props.state.forms.case_details_page.data.output.details.children.bank_accounts,
                    array.map((form) => form.status),
                ),
            ],
            FormStatus_highestPriority,
        )}
    >
        <CRMTitleSection>
            Client bank accounts
        </CRMTitleSection>
        <CRMSpacer size="large" />
        <CRMSpacingColumn spacing="medium">
            <CRMSpacingRow
                spacing="medium"
                childSize="even-fill"
                alignItems="flex-start"
            >
                <CRMLink target="_blank" href={`https://portal.armalytix.com/#/reports`} linkStyle="normal">Armalytix Portal</CRMLink>
                {/* BANK ACCOUNT CHANGE LOCK */}
                <CRMInputLabelAndErrorWrapComponent
                    label="Client changes to bank accounts locked"
                    guidance={
                        props.state.global.user.user_role !== "sail_legal_authoriser_staff" && props.state.global.user.user_role !== "admin"
                            ? "Only authorisers can lock/unlock client changes to a bank account"
                            : undefined
                    }
                >
                    <CRMRadioListComponent
                        options={[
                            {
                                value: true,
                                text: "Yes",
                            },
                            {
                                value: false,
                                text: "No",
                            },
                        ]}
                        value={props.state.forms.case_details_page.data.output.details.edited.client_changes_to_bank_accounts_locked ? true : false}
                        direction="row"
                        onChange={(value) => props.dispatch({
                            type: "LEGAL_CASE_DETAILS_CHANGE",
                            payload: {
                                ...props.state.forms.case_details_page.data.output.details,
                                edited: {
                                    ...props.state.forms.case_details_page.data.output.details.edited,
                                    client_changes_to_bank_accounts_locked: value ? DateTime.utc().toISO() : null,
                                }
                            }
                        })}
                        disabled={props.state.global.user.user_role !== "sail_legal_authoriser_staff" && props.state.global.user.user_role !== "admin"}
                    />
                </CRMInputLabelAndErrorWrapComponent>

                {/* BANK ACCOUNT VERIFICATION JOBS DEFER */}
                <CRMInputLabelAndErrorWrapComponent label="Bank account verification defer">
                    <CRMDeferButtonComponent
                        value={props.state.forms.case_details_page.data.output.details.edited.bank_account_verification_defer}
                        onChange={(bank_account_verification_defer) => props.dispatch({
                            type: "LEGAL_CASE_DETAILS_CHANGE",
                            payload: {
                                ...props.state.forms.case_details_page.data.output.details,
                                edited: {
                                    ...props.state.forms.case_details_page.data.output.details.edited,
                                    bank_account_verification_defer,
                                }
                            }
                        })}
                        displayError={false}
                    />
                </CRMInputLabelAndErrorWrapComponent>
            </CRMSpacingRow>

            {/* BANK ACCOUNT TABS */}
            {props.state.forms.case_details_page.data.output.details.children.bank_accounts.length > 0 && <CRMVerticalTabs
                tabs={pipe(
                    props.state.forms.case_details_page.data.output.details.children.bank_accounts,
                    array.map((bankAccountForm) => ({
                        tabLabel: bankAccountForm.children.recipient_full_legal_names,
                        tabOptions: [],
                        content: <CRMSpacingColumn spacing="medium">
                            <CRMTitleSubSection>
                                Account details
                            </CRMTitleSubSection>
                            {/* WARNING WHEN UNVERIFIED */}
                            {(bankAccountForm.edited.verification_status !== "approved" || bankAccountForm.edited.all_clients_agree_with_this_bank_account === null) && <CRMNoticeBoxComponent>
                                <div>
                                    <WeightBold>
                                        Do not make payments to this account!
                                    </WeightBold>
                                </div>
                                <div>
                                    Not all verification steps have been completed successfully yet.
                                </div>
                            </CRMNoticeBoxComponent>}

                            {/* NON-EDITABLE BANK DETAILS */}
                            <CRMInfoBoxSimpleColourComponent
                                fullWidth={true}
                                displayError={false}
                            >
                                <CRMPadding size="medium">
                                    <FontSize size={CRMFontSizes.MED}>
                                        <CRMSpacingColumn spacing="medium">
                                            <CRMSpacingRow
                                                spacing="medium"
                                                childSize="even-fill"
                                                justifyContent="start"
                                                alignItems="flex-start"
                                            >
                                                <CRMSpacingColumn spacing="tiny">
                                                    <div><FontSize size={CRMFontSizes.X_SMALL}>Names on account:</FontSize></div>
                                                    <div><FontSignikaNegative>{bankAccountForm.children.recipient_full_legal_names}</FontSignikaNegative></div>
                                                </CRMSpacingColumn>
                                                <CRMSpacingColumn spacing="tiny">
                                                    <div><FontSize size={CRMFontSizes.X_SMALL}>Bank name:</FontSize></div>
                                                    <div><FontSignikaNegative>{bankAccountForm.children.bank_name}</FontSignikaNegative></div>
                                                </CRMSpacingColumn>
                                            </CRMSpacingRow>
                                            <CRMSpacingRow
                                                spacing="medium"
                                                childSize="even-fill"
                                                justifyContent="start"
                                                alignItems="flex-start"
                                            >
                                                <CRMSpacingColumn spacing="tiny">
                                                    <div><FontSize size={CRMFontSizes.X_SMALL}>Sort code:</FontSize></div>
                                                    <div><FontSignikaNegative>{bankAccountForm.children.sort_code_hyphenated}</FontSignikaNegative></div>
                                                </CRMSpacingColumn>
                                                <CRMSpacingColumn spacing="tiny">
                                                    <div><FontSize size={CRMFontSizes.X_SMALL}>Account number:</FontSize></div>
                                                    <div><FontSignikaNegative>{bankAccountForm.children.account_number}</FontSignikaNegative></div>
                                                </CRMSpacingColumn>
                                            </CRMSpacingRow>
                                        </CRMSpacingColumn>
                                    </FontSize>
                                </CRMPadding>
                            </CRMInfoBoxSimpleColourComponent>

                            {/* VERIFICATION FORM */}
                            <CRMTitleSubSection>
                                Verification
                            </CRMTitleSubSection>
                            <CRMCodecEditForm
                                codec={CasesBankAccountVerificationSteps}
                                model={bankAccountForm.edited}
                                validationErrors={bankAccountForm.validationErrors}
                                onChange={(edited) => {
                                    props.dispatch({
                                        type: "LEGAL_CASE_UPDATE_CLIENT_BANK_ACCOUNT",
                                        payload: {
                                            ...bankAccountForm,
                                            edited: {
                                                ...bankAccountForm.edited,
                                                ...edited,
                                            }
                                        }
                                    })
                                }}
                            />
                        </CRMSpacingColumn>
                    }))
                )}
            />}

            {/* NO BANK ACCOUNTS ADDED YET */}
            {props.state.forms.case_details_page.data.output.details.children.bank_accounts.length === 0 && <CRMParagraph>
                No client bank accounts have been added yet.
            </CRMParagraph>}
        </CRMSpacingColumn>
    </CRMAutosaveIndicatorWrap>
);

export default CRMLegalCaseDetailsClientBankAccountsContainer;