import React from "react";
import { CRMButtonQuaternary } from "../CRMButtonQuaternary/CRMButtonQuaternary";
import { CRMSpacingRow } from "../CRMSpacingRow/CRMSpacingRow";
import { CRMButtonSecondary } from "../CRMButtonSecondary/CRMButtonSecondary";
import { CRMCardOutsidePopupBlank, TPopupContext, TPopupSize } from "../CRMCardOutsidePopupBlank/CRMCardOutsidePopupBlank";
import { CRMPadding } from "../Simple/CRMPadding/CRMPadding";
import { CRMSpacer } from "../CRMSpacer/CRMSpacer";
import { CRMTitleForCard } from "../CRMTitleForCard/CRMTitleForCard";

type TCRMCardOutsidePopupBasic = {
    isOpen: boolean;
    title: string;
    context?: TPopupContext;
    size?: TPopupSize;
    closeText?: string;
    ctaText?: string;
    isInfo?: boolean;
    ctaDisabled?: boolean;
    onClose: () => void;
    onCTA?: () => void;
};

export class CRMCardOutsidePopupBasic extends React.Component<React.PropsWithChildren<TCRMCardOutsidePopupBasic>> {
    public render (): JSX.Element {
        return (
            <CRMCardOutsidePopupBlank
                isOpen={this.props.isOpen}
                onClose={this.props.onClose}
                context={this.props.context}
                size={this.props.size}
            >
                <CRMPadding size="large">
                    {/* TITLE */}
                    <CRMTitleForCard>{this.props.title}</CRMTitleForCard>
                    <CRMSpacer size="medium" />

                    {/* BODY */}
                    <>{this.props.children}</>
                    <CRMSpacer size="large" />

                    {/* BUTTONS */}
                    <CRMSpacingRow justifyContent="end">
                        <CRMButtonSecondary
                            label={typeof this.props.closeText === "undefined" ? "Close" : this.props.closeText}
                            onClick={this.props.onClose}
                        />
                        {!this.props.isInfo && typeof this.props.onCTA === "function" && this.props.ctaText &&
                            <CRMButtonQuaternary
                                label={this.props.ctaText}
                                onClick={this.props.onCTA}
                                disabled={this.props.ctaDisabled}
                            />
                        }
                    </CRMSpacingRow>
                </CRMPadding>
            </CRMCardOutsidePopupBlank>
        );
    }
}
