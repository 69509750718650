import React from "react";
import { CursorPointer } from "../../../CursorPointer/CursorPointer";
import { Background } from "../../../BuildingBlocks/Background";
import { FrontSpacing } from "../../../../models/FrontSpacing";
import { SpacingColumn } from "../../../BuildingBlocks/SpacingColumn";
import { SpacingRow } from "../../../BuildingBlocks/SpacingRow";
import { FrontIcon } from "../FrontIcon/FrontIcon";
import { FontQuicksand } from "../../../BuildingBlocks/FontQuicksand";
import { Text } from "../../../BuildingBlocks/Text";
import { FrontFontSizes } from "../../../../models/FrontFontSizes";
import { FrontColors } from "../../../../models/FrontColors";
import { WeightMedium } from "../../../WeightMedium/WeightMedium";
import { Uppercase } from "../../../Uppercase/Uppercase";
import { MediaCompChildrenSwitch } from "../../../BuildingBlocks/MediaCompChildrenSwitch";

export const FrontRadioCard = (props: React.PropsWithChildren<{
    value: boolean,
    label: string,
    onChange: (value: boolean) => void,
}>): JSX.Element => {
    const onChange = () =>
        props.onChange(true);

    return <MediaCompChildrenSwitch
        DesktopChild={
            <CursorPointer onClick={onChange}>
                <Background
                    borderRadius="5px"
                    borderDefinition="solid 1px #CACBD8"
                    boxShadowDefinition="1px 2px 5px 0px rgba(0, 0, 0, 0.25)"
                    padding={FrontSpacing.MEDIUM_2}
                >
                    <Content {...props} />
                </Background>
            </CursorPointer>
        }
        MobileChild={
            <CursorPointer onClick={onChange}>
                <Content {...props} />
            </CursorPointer>
        }
        breakpoint="600"
    />;
};

const Content = (props: React.PropsWithChildren<{
    value: boolean,
    label: string,
}>) =>
    <SpacingColumn spacing={FrontSpacing.MEDIUM_1}>
        <SpacingRow
            spacing={FrontSpacing.SMALL_2}
            justifyContent="start"
            alignItems="center"
        >
            {/* RADIO */}
            <FrontIcon
                iconName={props.value ? "radio-selected" : "radio-unselected"}
                colour="neutral-0"
            />

            {/* LABEL */}
            <FontQuicksand>
                <Text
                    size={FrontFontSizes.MED}
                    color={FrontColors.NEUTRAL_2}
                >
                    <WeightMedium>
                        <Uppercase>
                            {props.label}
                        </Uppercase>
                    </WeightMedium>
                </Text>
            </FontQuicksand>
        </SpacingRow>

        {/* BODY */}
        {props.children}
    </SpacingColumn>;
