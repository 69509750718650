import React from "react";
import { CRMColumns } from "../CRMColumns/CRMColumns";
import { CRMParagraph } from "../Simple/CRMParagraph/CRMParagraph";
import { CRMButtonIcon, ICRMButtonIcon } from "../CRMButtonIcon/CRMButtonIcon";
import { WeightMedium } from "../WeightMedium/WeightMedium";

export class CRMButtonIconWithLabel extends React.Component<{
    label: string | JSX.Element;
} & ICRMButtonIcon> {
    public render (): JSX.Element {
        return (
            <CRMColumns
                padding="medium"
                alignItems="center"
                wrapOnMobile={false}
                columns={[
                    {
                        flex: 0,
                        content: <CRMButtonIcon
                            icon={this.props.icon}
                            variant={this.props.variant}
                            onClick={this.props.onClick}
                        />,
                    },
                    {
                        flex: 1,
                        content: (
                            typeof this.props.label === "string"
                                ? <CRMParagraph>
                                    <WeightMedium>
                                        {this.props.label}
                                    </WeightMedium>
                                </CRMParagraph>
                                : this.props.label
                        ),
                    },
                ]}
            />
        );
    }
}
