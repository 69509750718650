import React from "react";
import { TContainerStateProps } from "./../../state/TContainerStateProps";
import { CRMSpacingColumn } from "../../components/CRMSpacingColumn/CRMSpacingColumn";
import { CRMTitleSection } from "../../components/CRMTitleSection/CRMTitleSection";
import { CRMSpacer } from "../../components/CRMSpacer/CRMSpacer";
import { CRMAutosaveIndicatorWrap } from "../../components/CRM/CRMAutosaveIndicatorWrap/CRMAutosaveIndicatorWrap";
import { CRMCodecEditForm } from "../../components/CRMCodecEditForm/CRMCodecEditForm";
import { CasesClientFunds, CasesProceedsOfSaleReceived } from "../../../../domain/codecs/Cases";
import { CRMTitleSubSection } from "../../components/CRMTitleSubSection/CRMTitleSubSection";
import { CRMHorizontalSeperatorLine } from "../../components/CRMHorizontalSeperatorLine/CRMHorizontalSeperatorLine";

const CRMLegalCaseDetailsClientFundsContainer = (props: TContainerStateProps): JSX.Element => (
    <CRMAutosaveIndicatorWrap
        status={props.state.forms.case_details_page.data.output.details.status}
    >
        <CRMTitleSection>
            Client funds
        </CRMTitleSection>
        <CRMSpacer size="large" />
        <CRMSpacingColumn spacing="medium">
            {/* REQUEST/RECEIVE CLIENT FUNDS */}
            {(
                props.state.forms.case_details_page.data.output.details.children.transaction_type === "purchase"
                || props.state.forms.case_details_page.data.output.details.children.transaction_type === "transfer"
            ) && (
                props.state.forms.case_details_page.data.output.details.children.purchase_payment_method === "cash"
                || props.state.forms.case_details_page.data.output.details.children.purchase_payment_method === "mortgage"
            ) && <>
                <CRMHorizontalSeperatorLine />
                <CRMTitleSubSection>
                    Request/Receive client funds
                </CRMTitleSubSection>
                <CRMCodecEditForm
                    codec={CasesClientFunds}
                    model={props.state.forms.case_details_page.data.output.details.edited}
                    validationErrors={props.state.forms.case_details_page.data.output.details.validationErrors}
                    onChange={(edited) => props.dispatch({
                        type: "LEGAL_CASE_DETAILS_CHANGE",
                        payload: {
                            ...props.state.forms.case_details_page.data.output.details,
                            edited: {
                                ...props.state.forms.case_details_page.data.output.details.edited,
                                ...edited,
                            }
                        }
                    })}
                    columns={2}
                    showDeferDateLabels={true}
                />
            </>}

            {/* PROCEEDS OF SALE RECEIVED FROM BUYER SOLICITOR */}
            {props.state.forms.case_details_page.data.output.details.children.transaction_type === "sale" && <>
                <CRMHorizontalSeperatorLine />
                <CRMTitleSubSection>
                    Proceeds of sale received from buyer's solicitor
                </CRMTitleSubSection>
                <CRMCodecEditForm
                    codec={CasesProceedsOfSaleReceived}
                    model={props.state.forms.case_details_page.data.output.details.edited}
                    validationErrors={props.state.forms.case_details_page.data.output.details.validationErrors}
                    onChange={(edited) => props.dispatch({
                        type: "LEGAL_CASE_DETAILS_CHANGE",
                        payload: {
                            ...props.state.forms.case_details_page.data.output.details,
                            edited: {
                                ...props.state.forms.case_details_page.data.output.details.edited,
                                ...edited,
                            }
                        }
                    })}
                    columns={2}
                    showDeferDateLabels={true}
                />
            </>}
        </CRMSpacingColumn>
    </CRMAutosaveIndicatorWrap>
);

export default CRMLegalCaseDetailsClientFundsContainer;