import React from "react";
import { CRMColors } from "../../../../models/CRMColors";
import { CRMSpacing } from "../../../../models/CRMSpacing";
import { Background } from "../../../BuildingBlocks/Background";
import { ShineShape } from "../../../BuildingBlocks/ShineShape";
import { SpacingColumn } from "../../../BuildingBlocks/SpacingColumn";
import { Shape as BuildingBlocksShape } from "../../../BuildingBlocks/Shape";
import { SpacingRow } from "../../../BuildingBlocks/SpacingRow";

export const CRMDayTablePlaceholderRows = () =>
    <SpacingColumn spacing="1px">
        <Row />
        <Row />
        <Row />
        <Row />
        <Row />
        <Row />
        <Row />
        <Row />
        <Row />
    </SpacingColumn>

const Cell = () =>
    <BuildingBlocksShape
        height="14px"
        width="100%"
        color={CRMColors.NEUTRAL_8}
    />;

const Row = () =>
    <ShineShape
        backgroundColor={CRMColors.NEUTRAL_12} 
        shineColor={CRMColors.NEUTRAL_PAPER}
        duration={2000}
        width={"100%"}
        height="44px"
    >
        <Background padding={CRMSpacing.MEDIUM}>
            <SpacingRow
                justifyContent="space-between"
                childSize="1fr 40px"
            >
                <SpacingRow
                    spacing="45px"
                    childSize="100px 180px 300px"
                >
                    <SpacingRow
                        spacing="10px"
                        childSize="25px 1fr"
                    >
                        <Cell />
                        <Cell />
                    </SpacingRow>
                    <Cell />
                    <Cell />
                </SpacingRow>

                <Cell />
            </SpacingRow>
        </Background>
    </ShineShape>;
