import React from "react";
export class FrontLink extends React.Component<React.PropsWithChildren<{
    href?: string;
    onClick?: () => void;
    target?: React.AnchorHTMLAttributes<HTMLAnchorElement>["target"];
}>> {
    public render (): JSX.Element {
        return (
            <span className="front-link">
                <a href={this.props.href}
                    onClick={() => this.props.onClick ? this.props.onClick() : undefined}
                    target={this.props.target}
                >
                    {this.props.children}
                </a>
            </span>
        );
    }
}
