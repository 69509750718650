import { overload } from "../../../shared/src/codecs/types/overload";
import { string } from "../../../shared/src/codecs/types/string";
import { TUserLegalNames, UserLegalNames } from "../User";

export const UserLegalNamesToFullLegalName = (user: TUserLegalNames) =>
    `${user.legal_first_legal_name} ${user.legal_middle_legal_name} ${user.legal_last_legal_name}`
        .replace(/\s\s/, " ")

export const UserLegalNamesToFullLegalNameOverload = overload(
    string(),
    UserLegalNames,
    UserLegalNamesToFullLegalName 
);

