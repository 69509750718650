import * as t from "io-ts";
import * as BuyerReadyTotalFundsAvailable from "./BuyerReadyTotalFundsAvailable";
import * as BuyerReadyHasProvidedProofOfFunds from "./BuyerReadyHasProvidedProofOfFunds";
import * as ListingEnquiriesOffer6 from "../models/ListingEnquiriesOffer6";
import * as BuyerOffer1 from "./BuyerOffer1";

export const codec = t.boolean;

export type T = t.TypeOf<typeof codec>;

const hasProofOfFundsForCurrentOffer = (obj: {
    funds_cash_verified_upto: number | null;
    funds_dip_verified_upto: number | null;
    amount: number;
}): T =>
BuyerReadyHasProvidedProofOfFunds.hasProvidedProofOfFunds({funds_cash_verified_upto: obj.funds_cash_verified_upto, funds_dip_verified_upto: obj.funds_dip_verified_upto})
    && BuyerReadyTotalFundsAvailable.totalFundsAvailable({funds_cash_verified_upto: obj.funds_cash_verified_upto, funds_dip_verified_upto: obj.funds_dip_verified_upto}) >= obj.amount;

export const fromListingEnquiriesOffer6 = (m: ListingEnquiriesOffer6.T): T => hasProofOfFundsForCurrentOffer({
    amount: m.amount,
    funds_cash_verified_upto: m.funds_cash_verified_upto,
    funds_dip_verified_upto: m.funds_dip_verified_upto,
});

export const fromBuyerOffer1 = (m: BuyerOffer1.T): T => hasProofOfFundsForCurrentOffer({
    amount: m.amount,
    funds_cash_verified_upto: m.enquiry.funds_cash_verified_upto,
    funds_dip_verified_upto: m.enquiry.funds_dip_verified_upto,
});
