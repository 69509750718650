import React from "react";
import { useOpenClose } from "../../../hooks/UseOpenClose";
import { CRMColors } from "../../../models/CRMColors";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { AnimationPopout } from "../../BuildingBlocks/AnimationPopout";
import { BackgroundColour } from "../../BuildingBlocks/BackgroundColour";
import { BorderBetween } from "../../BuildingBlocks/BorderBetween";
import { BoxShadow } from "../../BuildingBlocks/BoxShadow";
import { HorizontalLine } from "../../BuildingBlocks/HorizontalLine";
import { MinWidth } from "../../BuildingBlocks/MinWidth";
import { Padding } from "../../BuildingBlocks/Padding";
import { Position } from "../../BuildingBlocks/Position";
import { Relative } from "../../BuildingBlocks/Relative";
import { SpacingRow } from "../../BuildingBlocks/SpacingRow";
import { CRMBadge } from "../../Complex/CRMBadge/CRMBadge";
import { CRMIcon } from "../../CRMIcon/CRMIcon";
import { CRMParagraph } from "../../Simple/CRMParagraph/CRMParagraph";
import { CursorPointer } from "../../CursorPointer/CursorPointer";
import { WeightBold } from "../../WeightBold/WeightBold";
import { WeightMedium } from "../../WeightMedium/WeightMedium";

export type TBlockDropdownSection<A extends string> = {
    value: A,
    label: string,
    badgeCount: number,
};

export const CRMBlockDropdown = <A extends string, B extends A>(props: React.PropsWithChildren<{
    sections: Array<TBlockDropdownSection<A>>,
    value: B,
    label: string,
    onValueChange: (value: B) => void,
}>): JSX.Element => {
    const {
        ref,
        isOpen,
        setIsOpen,
        toggleOpen,
    } = useOpenClose();

    const onOptionSelect = (value: B) => {
        props.onValueChange(value);
        setIsOpen(false);
    };

    return <Relative width="100%">
        {/* DROPDOWN OPTIONS */}
        <DropdownOptions
            sections={props.sections}
            isOpen={isOpen}
            onOptionSelect={onOptionSelect}
        />

        {/* DROPDOWN FACE */}
        <DropdownFace
            label={props.label}
            dropdownRef={ref}
            isOpen={isOpen}
            onOpenCloseToggle={toggleOpen}
        />
    </Relative>
};

const DropdownFace = (props: React.PropsWithChildren<{
    label: string,
    dropdownRef: React.RefObject<HTMLDivElement>,
    isOpen: boolean,
    onOpenCloseToggle: () => void,
}>) => {
    const getIconName = () => props.isOpen ? "down-arrow" : "up-arrow";

    return <MinWidth width="100%">
        <div ref={props.dropdownRef}>
            <CursorPointer onClick={props.onOpenCloseToggle}>
                <BackgroundColour colour={CRMColors.NEUTRAL_PAPER}>
                    <Padding type="custom" spacing={`${CRMSpacing.SMALL} ${CRMSpacing.LARGE}`}>
                        <SpacingRow
                            spacing={CRMSpacing.MEDIUM}
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            {/* FACE */}
                            <CRMParagraph>
                                <WeightMedium>
                                    Viewing:&nbsp;
                                </WeightMedium>

                                <WeightBold>
                                    {props.label}
                                </WeightBold>
                            </CRMParagraph>

                            {/* ICON */}
                            <CRMIcon
                                colour="neutral-ink"
                                size="medium"
                                iconName={getIconName()}
                            />
                        </SpacingRow>
                    </Padding>
                </BackgroundColour>
            </CursorPointer>
        </div>
    </MinWidth>;
};

const DropdownOptions = <A extends string, B extends A>(props: React.PropsWithChildren<{
    sections: Array<TBlockDropdownSection<A>>,
    isOpen: boolean,
    onOptionSelect: (value: B) => void,
}>): JSX.Element => {
    const getOptionsDisplayProperty = () => props.isOpen ? "block" : "none";

    return <Position
        position="absolute"
        left="0"
        bottom="45px"
        width="100%"
        display={getOptionsDisplayProperty()}
    >
        <AnimationPopout>
            <BackgroundColour colour={CRMColors.NEUTRAL_PAPER}>
                <BoxShadow definition="0px -2px 5px rgba(0, 0, 0, 0.19)">
                    {props.sections.map((section, i) => <DropdownOption
                        key={section.value}
                        label={section.label}
                        badgeCount={section.badgeCount}
                        onClick={() => props.onOptionSelect(section.value as B)}
                    />)}
                </BoxShadow>
            </BackgroundColour>

            <HorizontalLine color={CRMColors.NEUTRAL_0} />
        </AnimationPopout>
    </Position>;
};

const DropdownOption = <A extends string>(props: React.PropsWithChildren<{
    label: A,
    badgeCount: number,
    onClick: () => void,
}>): JSX.Element => {
    const isShowingBadgeCount = (): boolean =>
        props.badgeCount > 0;

    return <BorderBetween borderColour={CRMColors.NEUTRAL_8}>
        <MinWidth width="100%">
            <CursorPointer onClick={props.onClick}>
                <div className="crm-block-dropdown__option">
                    <Padding type="custom" spacing={`${CRMSpacing.SMALL} ${CRMSpacing.LARGE}`}>
                        <SpacingRow
                            spacing={CRMSpacing.MEDIUM}
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            {/* LABEL */}
                            <CRMParagraph>
                                <WeightMedium>
                                    {props.label}
                                </WeightMedium>
                            </CRMParagraph>

                            {/* BADGE */}
                            {isShowingBadgeCount() && <CRMBadge
                                backgroundColour={CRMColors.NEUTRAL_6}
                                fontColour={CRMColors.NEUTRAL_PAPER}
                            >
                                {props.badgeCount}
                            </CRMBadge>}
                        </SpacingRow>
                    </Padding>
                </div>
            </CursorPointer>
        </MinWidth>
    </BorderBetween>;
};
