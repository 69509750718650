import React, { useState, useEffect } from "react";
import { Padding } from "../../../BuildingBlocks/Padding";
import { FrontCondensedQuestionWrap } from "../FrontCondensedQuestionWrap/FrontCondensedQuestionWrap";
import { FrontFormRow } from "../FrontFormRow/FrontFormRow";
import FrontInputGeneralComponent from "../FrontInputGeneralComponent/FrontInputGeneralComponent";
import FrontInputLabelAndErrorWrapComponent from "../FrontInputLabelAndErrorWrapComponent/FrontInputLabelAndErrorWrapComponent";
import { isFilled } from "./../../../../../../shared/src/util";

type TInputQuestionsProps= {
    value: string;
    label: string;
    closedLabel?: string;
    isEditable?: boolean;
    wrapInFormRow?: boolean;
    displayValidationErrorsIfPresent?: boolean;
    onChange: (value: string) => void;
};

export const FrontCondensedInputQuestion = (props: React.PropsWithChildren<TInputQuestionsProps>): JSX.Element => {

    const inputRef = React.createRef<HTMLInputElement>();
    const [isOpen, setIsOpen] = useState(props.value.length === 0);

    const colapseOnBlurIfFilled = () => {
        if (isFilled(props.value)) {
            setIsOpen(false);
        }
    }

    useEffect(
        () => {
            if (isOpen === true) {
                inputRef.current?.focus();
            }
        },
        [isOpen]
    );

    // Padding is here so that the label texts are perfectly aligned with each otehr
    const renderInput = (): JSX.Element => (
         <Padding type="top" spacing="2px">
            <FrontInputLabelAndErrorWrapComponent label={props.label} errorMessage="Please fill this in" displayError={props.value === "" && props.displayValidationErrorsIfPresent ? true : false}>
                <FrontInputGeneralComponent
                    inputRef={inputRef}
                    inputType="text"
                    value={props.value}
                    displayError={false}
                    onChange={props.onChange}
                    onBlur={colapseOnBlurIfFilled}
                />
            </FrontInputLabelAndErrorWrapComponent>
        </Padding>
    )

    return (
        <FrontCondensedQuestionWrap
            isOpen={isOpen}
            isEditable={props.isEditable}
            setIsOpen={setIsOpen}
            label={props.closedLabel || props.label}
            summary={props.value}
        >
           {props.wrapInFormRow &&
                <FrontFormRow>
                    {renderInput()}
                </FrontFormRow>
           }

           {props.wrapInFormRow !== true &&
                renderInput()
           }
        </FrontCondensedQuestionWrap>
    );
};
