import React from "react";
import { CRMFontSizes } from "../../../models/CRMFontSizes";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { CRMColors } from "../../../models/CRMColors";
import { InlineFlex } from "../../BuildingBlocks/InlineFlex";
import { SpacingColumn } from "../../BuildingBlocks/SpacingColumn";
import { TextHightlight } from "../../BuildingBlocks/TextHightlight";
import { CRMParagraph } from "../../Simple/CRMParagraph/CRMParagraph";
import { WeightBold } from "../../WeightBold/WeightBold";
import { identity } from "fp-ts";
import { TQuoteEmailAttachment } from "../../../../../domain/codecs/Quote";

type TTextFormatFunction = (value: string) => JSX.Element | string;

export const CRMQuoteAttachment = (props: React.PropsWithChildren<{
    tagPrefix?: string;
    quote: TQuoteEmailAttachment;
    quoteFormatting?: (message: string) => JSX.Element | string; 
}>): JSX.Element => {
    const formatTextWith = (functionName: "quoteFormatting"): TTextFormatFunction => {
        if (typeof props[functionName] === "function") {
            return props[functionName] as TTextFormatFunction;
        }
        return identity.flatten;
    }

    const formatQuote = formatTextWith("quoteFormatting");

    return (
        <SpacingColumn spacing={CRMSpacing.MEDIUM}>
            {/* ATTACHMENT TYPE LABEL */}
            <InlineFlex>
                <TextHightlight color={CRMColors.HIGHLIGHTS_EASY_ORANGE_10}>
                    <CRMParagraph>
                        <WeightBold>
                            {props.tagPrefix || "" } Quote
                        </WeightBold>
                    </CRMParagraph>
                </TextHightlight>
            </InlineFlex>
            
            <CRMParagraph fontSize={CRMFontSizes.SMALL}>
                <WeightBold>
                    {formatQuote(props.quote.created_at)}
                </WeightBold>
            </CRMParagraph>
        </SpacingColumn>
    );
};