import { TTypeOfCodec } from "../../../shared/src/codecs/codec";
import { boolean } from "../../../shared/src/codecs/types/boolean";
import { form, TFormCodec } from "../../../shared/src/codecs/types/form";
import { required } from "../../../shared/src/codecs/types/required";
import { string } from "../../../shared/src/codecs/types/string";
import { uuid } from "../../../shared/src/codecs/types/uuid";
import { InternalScope } from "../InternalScope";

export const LegalMailboxAccessEditFormEditablePart = required({
        access_granted: boolean(),
    });
export type TLegalMailboxAccessEditFormEditablePartCodec = typeof LegalMailboxAccessEditFormEditablePart;
export type TLegalMailboxAccessEditFormEditablePart = TTypeOfCodec<TLegalMailboxAccessEditFormEditablePartCodec>;


export const LegalMailboxAccessEditFormReadOnlyPart = required({
    id: uuid(),
    user_role: InternalScope,
    first_name: string(),
    last_name: string(),
});
export type TLegalMailboxAccessEditFormReadOnlyPartCodec = typeof LegalMailboxAccessEditFormReadOnlyPart;


export const LegalMailboxAccessEditForm: TFormCodec<TLegalMailboxAccessEditFormEditablePartCodec, TLegalMailboxAccessEditFormReadOnlyPartCodec> = form(
    LegalMailboxAccessEditFormEditablePart,
    LegalMailboxAccessEditFormReadOnlyPart,
);
export type TLegalMailboxAccessEditFormCodec = typeof LegalMailboxAccessEditForm;
export type TLegalMailboxAccessEditForm = TTypeOfCodec<TLegalMailboxAccessEditFormCodec>;