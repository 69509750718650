import React from "react";

export const companyTypeVariations = [
    "uk_ltd",
    "uk_unlisted",
    "uk_llp_or_scottish_partnership",
    "companies_on_other_regulated_markets_with_similar_controls",
    "companies_on_other_markets_without_similar_controls",
    "public_sector_or_government",
    "trusts",
    "listing_on_any_stock_exchange",
    "aim_listed",
    "majority_owned_or_consolidated_subsidiary",
    "fca_regulated",
    "other_non_uk_credit_or_financial_institutions",
    "uk_or_eea_public_authorities",
    "professionals_instructing",
    "foundation",
    "uk_charity",
    "place_of_worship",
    "state_school_or_college",
    "club_or_association",
    "pension_scheme",
    "sovereign_wealth_funds",
] as const;

export const amlUndertakings: Array<{
        title: string,
        description: JSX.Element,
        appliesForCompanyTypes: Array<typeof companyTypeVariations[number]>,
}> = [
    {
        title: "Get the company/entities legal name",
        description: <>Using the Companies House service for UK company's (or a similar Government service if the company is registered overseas) and check the full legal name of the company is listed in the input above.</>,
        appliesForCompanyTypes: [
            "uk_ltd",
            "uk_unlisted",
            "uk_llp_or_scottish_partnership",
            "companies_on_other_regulated_markets_with_similar_controls",
            "companies_on_other_markets_without_similar_controls",
            "public_sector_or_government",
            "trusts",
            "listing_on_any_stock_exchange",
            "aim_listed",
            "majority_owned_or_consolidated_subsidiary",
            "uk_or_eea_public_authorities",
            "foundation",
            "uk_charity",
            "club_or_association",
        ],
    },
    {
        title: "Get the company/entity's business nature",
        description: <>Make sure you have a full understanding of the company's business nature and if it affects the risk assessment.</>,
        appliesForCompanyTypes: [
            "uk_llp_or_scottish_partnership",
            "public_sector_or_government",
            "trusts",
            "uk_or_eea_public_authorities",
            "foundation",
            "uk_charity",
            "club_or_association",
        ],
    },
    {
        title: "Get the company's number",
        description: <>Using the Companies House service for UK companies (or a similar Government service if the company is registered overseas) get the company's number and check it is correct in the input above.</>,
        appliesForCompanyTypes: [
            "uk_ltd",
            "uk_unlisted",
            "uk_llp_or_scottish_partnership",
            "companies_on_other_regulated_markets_with_similar_controls",
            "companies_on_other_markets_without_similar_controls",
            "public_sector_or_government",
            "trusts",
            "listing_on_any_stock_exchange",
            "aim_listed",
            "majority_owned_or_consolidated_subsidiary",
            "foundation",
            "uk_charity",
        ],
    },
    {
        title: "Get & ID check all company/entity directors/officers/partners and any people with significant control (PSC, usually those who hold 25% of shares or over)",
        description: <>The Companies House people with significant control (PSC) register may be a starting point but is insufficient in itself. Obtain further verification, e.g. confirmation from the client that the PSC register is up to date, or a copy of the share register. For some companies you may also wish to use the client's own website to obtain a fuller list of potential PSCs. Any persons identified should be added to the case so that they receive a full ID check & AML check.</>,
        appliesForCompanyTypes: [
            "uk_ltd",
            "uk_unlisted",
            "uk_llp_or_scottish_partnership",
            "companies_on_other_regulated_markets_with_similar_controls",
            "companies_on_other_markets_without_similar_controls",
            "trusts",
            "uk_or_eea_public_authorities",
            "foundation",
            "club_or_association",
        ],
    },
    {
        title: "Get the registered office address",
        description: <>Using the Companies House service for UK companies (or a similar Government service if the company is registered overseas) get the company's registered office address.</>,
        appliesForCompanyTypes: [
            "uk_ltd",
            "uk_unlisted",
            "uk_llp_or_scottish_partnership",
            "companies_on_other_regulated_markets_with_similar_controls",
            "companies_on_other_markets_without_similar_controls",
            "public_sector_or_government",
            "trusts",
            "listing_on_any_stock_exchange",
            "aim_listed",
            "majority_owned_or_consolidated_subsidiary",
            "uk_or_eea_public_authorities",
            "foundation",
            "uk_charity",
            "club_or_association",
        ],
    },
    {
        title: "Get the principal business address",
        description: <>Using the Companies House service for UK companies (or a similar Government service if the company is registered overseas) and/or the client's own website get the company's principal address (if different from the registered office address).</>,
        appliesForCompanyTypes: [
            "uk_ltd",
            "uk_unlisted",
            "uk_llp_or_scottish_partnership",
            "companies_on_other_regulated_markets_with_similar_controls",
            "companies_on_other_markets_without_similar_controls",
            "public_sector_or_government",
            "trusts",
            "listing_on_any_stock_exchange",
            "aim_listed",
            "majority_owned_or_consolidated_subsidiary",
            "foundation",
            "uk_charity",
        ],
    },
    {
        title: "Obtain evidence of listing on the stock exchange",
        description: <>To do this a print-out of the webpage of the relevant stock exchange showing the listing (e.g. London Stock Exchange page) would suffice, or other independent evidence of listing.</>,
        appliesForCompanyTypes: [
            "listing_on_any_stock_exchange",
            "aim_listed",
        ],
    },
    {
        title: "Get the latest company annual report",
        description: <>Using the Companies House service for UK companies (or a similar Government service if the company is registered overseas) get the latest annual report for the company.</>,
        appliesForCompanyTypes: [
            "uk_ltd",
            "uk_unlisted",
            "uk_llp_or_scottish_partnership",
            "companies_on_other_regulated_markets_with_similar_controls",
            "companies_on_other_markets_without_similar_controls",
            "trusts",
            "majority_owned_or_consolidated_subsidiary",
            "foundation",
        ],
    },
    {
        title: "Get evidence of authorisation by the Financial Conduct Authority (or similar overseas regulator)",
        description: <>For FCA: Print-out of https://register.fca.org.uk/s/ or a print-out of FCA-equivalent register or evidence of license or authorisation to conduct financial and/or banking business. A list of regulatory authorities in EU and Financial Action Task Force (FATF) member states is available from the Joint Money Laundering Steering Group (JMLSG) at: https://jmlsg.org.uk</>,
        appliesForCompanyTypes: [
            "fca_regulated",
            "other_non_uk_credit_or_financial_institutions",
        ],
    },
    {
        title: "Get the name and position of the person in the company/entity who is instructing us and check they are authorised to do so",
        description: <>As this is a UK/EEA public authority or EU institution we must check the person instructing us has authority from them to do so.</>,
        appliesForCompanyTypes: [
            "uk_or_eea_public_authorities",
        ],
    },
    {
        title: "Get the company/entities proof of registration",
        description: <>Using the Companies House service for UK companies (or a similar Government service if the company is registered overseas) get the company's registration document.</>,
        appliesForCompanyTypes: [
            "uk_ltd",
            "uk_unlisted",
            "uk_llp_or_scottish_partnership",
            "companies_on_other_regulated_markets_with_similar_controls",
            "companies_on_other_markets_without_similar_controls",
            "trusts",
            "foundation",
        ],
    },
    {
        title: "Understand the law to which the company/entity is subject",
        description: <>Using the Companies House service for UK companies (or a similar Government service if the company is registered overseas) look at the articles / memorandum of association.</>,
        appliesForCompanyTypes: [
            "uk_ltd",
            "uk_unlisted",
            "uk_llp_or_scottish_partnership",
            "companies_on_other_regulated_markets_with_similar_controls",
            "companies_on_other_markets_without_similar_controls",
            "trusts",
            "foundation",
        ],
    },
    {
        title: "Assess level of transparency in the jurisdiction to determine the extent of the due diligence required",
        description: <>Because we are dealing with a non-UK company we need to assess the risk and the due diligence required.</>,
        appliesForCompanyTypes: [
            "companies_on_other_regulated_markets_with_similar_controls",
            "companies_on_other_markets_without_similar_controls",
        ],
    },
    {
        title: "Make a note of the professionals details who is instructing us",
        description: <>Because we are being instructed by a professional (firm or individual) we should also record their name, registered number and registered office address and place of business. This includes professionals like other solicitors, medical, ect.</>,
        appliesForCompanyTypes: [
            "professionals_instructing",
        ],
    },
    {
        title: "Get the names of individuals instructing at the practice and details of their authority to do so",
        description: <>You may need to check a government website/directory to determine who can confirm the person instructing us has authority to do so.</>,
        appliesForCompanyTypes: [
            "public_sector_or_government",
        ],
    },
    {
        title: "Get the legal name of beneficiaries of the trust",
        description: <>This applies to trusts & foundations.</>,
        appliesForCompanyTypes: [
            "trusts",
            "foundation",
        ],
    },
    {
        title: "Determine if the trust is revocable or irrevocable and how that affects this case",
        description: <>This applies to trusts & foundations.</>,
        appliesForCompanyTypes: [
            "trusts",
            "foundation",
        ],
    },
    {
        title: "Get the legal name of settlors of the trust",
        description: <>This applies to trusts & foundations.</>,
        appliesForCompanyTypes: [
            "trusts",
            "foundation",
        ],
    },
    {
        title: "Get the legal name of trustees of the trust",
        description: <>This applies to trusts & foundations.</>,
        appliesForCompanyTypes: [
            "trusts",
            "foundation",
        ],
    },
    {
        title: "Get the legal name of protectors & controllers of the trust",
        description: <>This applies to trusts & foundations.</>,
        appliesForCompanyTypes: [
            "trusts",
            "foundation",
        ],
    },
    {
        title: "Get a certified copy of the trust deed",
        description: <>This applies to trusts & foundations.</>,
        appliesForCompanyTypes: [
            "trusts",
            "foundation",
        ],
    },
    {
        title: "Understand why the trust is being used and why that jurisdiction is being used",
        description: <>This applies to trusts & foundations.</>,
        appliesForCompanyTypes: [
            "trusts",
            "foundation",
        ],
    },
    {
        title: "Understand the nature of the charities activities",
        description: <>Determine if there are any changes to the risk factor based on these activities.</>,
        appliesForCompanyTypes: [
            "uk_charity",
        ],
    },
    {
        title: "Get a GRO certificate",
        description: <>Obtain General Register Office (GRO) certificate or contact HMRC. See section 6.14.17 of LSAG guidance.</>,
        appliesForCompanyTypes: [
            "place_of_worship",
        ],
    },
    {
        title: "Check the list of approved educational establishments",
        description: <>Check that this establishment appears on either www.gov.uk/check-university-award-degree or https://get-information-schools.service.gov.uk</>,
        appliesForCompanyTypes: [
            "state_school_or_college",
        ],
    },
    {
        title: "Get evidence of registration with HMRC as pension provider.",
        description: <>You may also be able to obtain evidence through the Pensions Regulator, or the front page of the scheme document may suffice.</>,
        appliesForCompanyTypes: [
            "pension_scheme",
        ],
    },
    {
        title: "Stop! Seek advice from the MLRO.",
        description: <>Because there is an aspect of Sovereign wealth funds you must seek advice from the MLRO before continuing.</>,
        appliesForCompanyTypes: [
            "sovereign_wealth_funds",
        ],
    },
];
