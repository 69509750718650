import React from "react";
import { CRMColors, TCRMColourValue } from "../../models/CRMColors";
import { CRMFontSizes } from "../../models/CRMFontSizes";
import { CRMSpacing } from "../../models/CRMSpacing";
import { FontQuicksand } from "../BuildingBlocks/FontQuicksand";
import { FontSize } from "../BuildingBlocks/FontSize";
import { SpacingColumn } from "../BuildingBlocks/SpacingColumn";
import { SpacingRow } from "../BuildingBlocks/SpacingRow";
import { TextColor } from "../BuildingBlocks/TextColor";
import { CRMIconBadge, TIconBadge } from "../CRMIconBadge/CRMIconBadge";
import { WeightBold } from "../WeightBold/WeightBold";
import { WeightSemiBold } from "../WeightSemiBold/WeightSemiBold";

export type TTextBadgeProps = {
    badgeText: string;
    badgeColor: TCRMColourValue;
    iconBadge?: TIconBadge;
    sideLabel?: TSideLabel;
}

type TSideLabel = {
    firstRow?: string;
    secondRow?: string;
}

export const CRMTextBadge = (props: React.PropsWithChildren<TTextBadgeProps>): JSX.Element => (
    <SpacingRow spacing={CRMSpacing.SMALL}>

        {/* BADGE */}
        <div
            className="crm-text-badge"
            style={{
                backgroundColor: props.badgeColor
            }}
        >
            <div className="crm-text-badge__text">
                {props.badgeText}
            </div>

            {/* ICON BADGE */}
            {props.iconBadge &&
                <div className="crm-text-badge__icon-badge">
                    <CRMIconBadge
                        icon={props.iconBadge.icon}
                        iconColour={props.iconBadge.iconColour}
                        iconTitle={props.iconBadge.iconTitle}
                        badgeColour={props.iconBadge.badgeColour}
                        onClick={props.iconBadge.onClick}
                        size={props.iconBadge.size}
                        flat={true}
                    />
                </div>
            }
        </div>

        {/* LABEL - TO RIGHT */}
        {props.sideLabel &&
            <FontQuicksand>
                <FontSize size={CRMFontSizes.SMALL}>
                    <TextColor color={CRMColors.NEUTRAL_0}>
                        <SpacingColumn
                            spacing={CRMSpacing.TINY}
                        >
            
                            {props.sideLabel.firstRow &&
                                <WeightSemiBold>
                                    {props.sideLabel.firstRow}
                                </WeightSemiBold>
                            }

                            {props.sideLabel.secondRow &&
                                <WeightBold>
                                    {props.sideLabel.secondRow}
                                </WeightBold>
                            }
                        </SpacingColumn>
                    </TextColor>
                </FontSize>
            </FontQuicksand>
        }
    </SpacingRow>
);
