import React from "react";

export class CRMTitleForCard extends React.Component<React.PropsWithChildren<{}>> {
    public render (): JSX.Element {
        return (
            <span className="crm-title-for-card">
                {this.props.children}
            </span>
        );
    }
}
