import React from "react";
import { useOpenClose } from "../../../hooks/UseOpenClose";
import { CRMColors } from "../../../models/CRMColors";
import { CRMFontSizes } from "../../../models/CRMFontSizes";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { TPercentageSize, TPixelSize } from "../../../models/StringLiterals";
import { Absolute } from "../../BuildingBlocks/Absolute";
import { AnimationPopout } from "../../BuildingBlocks/AnimationPopout";
import { Background } from "../../BuildingBlocks/Background";
import { Border } from "../../BuildingBlocks/Border";
import { FontRoboto } from "../../BuildingBlocks/FontRoboto";
import { FontSize } from "../../BuildingBlocks/FontSize";
import { Padding } from "../../BuildingBlocks/Padding";
import { Relative } from "../../BuildingBlocks/Relative";
import { SpacingRow } from "../../BuildingBlocks/SpacingRow";
import { TextColor } from "../../BuildingBlocks/TextColor";
import { CRMIcon } from "../../CRMIcon/CRMIcon";
import { CRMParagraph } from "../../Simple/CRMParagraph/CRMParagraph";
import { CursorPointer } from "../../CursorPointer/CursorPointer";
import { WeightRegular } from "../../WeightRegular/WeightRegular";

export const CRMCardStackingInput = (props: React.PropsWithChildren<{
    face: JSX.Element,
    cardCount: number,
    displayError: boolean,
    widthWhenExpanded?: TPixelSize | TPercentageSize,
}>): JSX.Element => {
    const {
        ref,
        isOpen,
        setIsOpen,
    } = useOpenClose({});

    return <Relative divRef={ref}>
        <CursorPointer onClick={() => setIsOpen(true)}>
            <Border
                definition={
                    isOpen
                        ? ""
                        : `solid 1px ${props.displayError ? CRMColors.HIGHLIGHTS_INSTRUCTIONAL_BRICK_2 : CRMColors.NEUTRAL_4}`
                }
                height="34px"
            >
                <Padding
                    type="horizontal"
                    spacing={CRMSpacing.SMALL}
                    height="100%"
                >
                    <SpacingRow
                        spacing={CRMSpacing.TINY}
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        {/* INPUT FACE */}
                        <div>
                            {props.face}
                        </div>

                        <SpacingRow spacing={CRMSpacing.TINY}>
                            {/* CARD COUNT */}
                            <CRMParagraph>
                                <FontRoboto>
                                    <FontSize size={CRMFontSizes.X_SMALL}>
                                        <WeightRegular>
                                            <TextColor color={CRMColors.NEUTRAL_INK}>
                                                ({props.cardCount})
                                            </TextColor>
                                        </WeightRegular>
                                    </FontSize>
                                </FontRoboto>
                            </CRMParagraph>

                            {/* CARD ICON */}
                            <div style={{height: "18px"}}>
                                <CRMIcon
                                    iconName="tooltip-open"
                                    size="x-small"
                                    colour="neutral-ink"
                                />
                            </div>
                        </SpacingRow>
                    </SpacingRow>
                </Padding>
            </Border>
        </CursorPointer>

        {/* EXPANDED POPOUT */}
        {isOpen &&
            <Absolute
                top="0"
                right="0"
                width={props.widthWhenExpanded || "100%"}
            >
                <AnimationPopout>
                    <Background
                        color={CRMColors.NEUTRAL_PAPER}
                        borderDefinition={`solid 1px ${props.displayError ? CRMColors.HIGHLIGHTS_INSTRUCTIONAL_BRICK_2 : CRMColors.NEUTRAL_0}`}
                        borderRadius="4px"
                    >
                        {props.children}
                    </Background>
                </AnimationPopout>
            </Absolute>
        }
    </Relative>
};
