import React, { useEffect, useState } from "react";
import { SpacingColumn } from "../../components/BuildingBlocks/SpacingColumn";
import { FrontQuestionFormCard } from "../../components/Front/Complex/FrontQuestionFormCard/FrontQuestionFormCard";
import FrontParagraphSmall from "../../components/Front/Simple/FrontParagraphSmall/FrontParagraphSmall";
import { TContainerStateProps } from "../../state/TContainerStateProps";
import { FrontSpacing } from "../../models/FrontSpacing";
import { WeightBold } from "../../components/WeightBold/WeightBold";
import { MaxWidth } from "../../components/BuildingBlocks/MaxWidth";
import { TOnboardingTabStatus } from "../../components/Front/Simple/FrontOnboardingTab/FrontOnboardingTab";
import { FrontInfoBubble } from "../../components/Front/Simple/FrontInfoBubble/FrontInfoBubble";
import { FrontColors } from "../../models/FrontColors";
import { FrontCondensedMultiSelectDropdownQuestion } from "../../components/Front/Simple/FrontCondensedMultiSelectDropdownQuestion/FrontCondensedMultiSelectDropdownQuestion";
import { MediaCompSwitch } from "../../components/BuildingBlocks/MediaCompSwitch";
import { SpacingRow } from "../../components/BuildingBlocks/SpacingRow";
import { FrontFormPrimaryButton } from "../../components/Front/Simple/FrontFormPrimaryButton/FrontFormPrimaryButton";
import { pipe } from "fp-ts/function";
import { array } from "fp-ts";
import { ClientCaseVideoVerificationCallCreateForm } from "../../../../domain/codecs/form/ClientCaseForm";
import { FrontBookVideoCallDateAndTimeSelection } from "../../components/FrontBookVideoCallDateAndTimeSelection/FrontBookVideoCallDateAndTimeSelection";
import { sailLegalBrandConstants } from "../../../../domain/constants";
import { FrontFormSectionTitle } from "../../components/Front/Simple/FrontFormSectionTitle/FrontFormSectionTitle";

const FrontBookVideoCallContainer = (props: TContainerStateProps): JSX.Element => {
    const [dateAndTimeFieldsVisible, setDateAndTimeFieldsVisible] = useState<boolean>(false);
    const [displayValidationErrorsIfPresent, setDisplayValidationErrorsIfPresent] = useState(false);

    const getNamesToBookFor = (): string =>
        pipe(
            getVideoVerificationCall(props).users_who_can_be_booked,
            array.map(({full_name}) => full_name),
            (a) => a.join(" & "),
        );

    const formCanBeSubmitted = (): boolean =>
        getVideoVerificationCall(props).create_booking.edited.user_ids.length > 0
        && getVideoVerificationCall(props).create_booking.edited.scheduled_time
            ? true
            : false;

    const errorGettingAvailableTimes = (): boolean =>
        getVideoVerificationCall(props).create_booking.children.status === "failure"
        || (
            getVideoVerificationCall(props).create_booking.children.status === "success"
            && getVideoVerificationCall(props).create_booking.children.edited.available_times.length === 0
        );

    useEffect(() => {
        if (
            getVideoVerificationCall(props).create_booking.edited.user_ids.length > 0
            && getVideoVerificationCall(props).create_booking.children.status === "success"
        ) {
            setDateAndTimeFieldsVisible(true);
        }
    }, [
        props.state.forms.client_case_page.children.video_verification_call?.create_booking.edited,
        props.state.forms.client_case_page.children.video_verification_call?.create_booking.children,
    ]);

    return (
        <div>
            <FrontQuestionFormCard
                title="When are you free for a video call with us?"
            >
                <MaxWidth width="600px">
                    {hasBookedAppointment(props)
                        ? <SpacingColumn spacing={FrontSpacing.MEDIUM_1}>
                            <FrontFormSectionTitle>
                                Your video call has been booked
                            </FrontFormSectionTitle>
                            <SpacingColumn spacing={FrontSpacing.SMALL_3}>
                                <FrontParagraphSmall>
                                    <WeightBold>We've emailed you an invitation to join the video call.</WeightBold>
                                </FrontParagraphSmall>
                                <FrontParagraphSmall>
                                    If you need to make any changes to your appointment just call us on {sailLegalBrandConstants().legalCSNumber}.
                                </FrontParagraphSmall>
                            </SpacingColumn>
                        </SpacingColumn>
                        : <SpacingColumn spacing={FrontSpacing.LARGE_1}>
                            {/* INTRODUCTION */}
                            <SpacingColumn spacing={FrontSpacing.SMALL_3}>
                                <FrontParagraphSmall>
                                    <WeightBold>We want to make sure we are doing the best possible job when representing you,</WeightBold> to do this we need to have a quick video call together, usually lasting no more than 20 - 30 minutes.
                                </FrontParagraphSmall>

                                <FrontParagraphSmall>
                                    During the call we can discuss your specific needs and you can ask any questions that you might have. This video call is also something our insurer requires us to carry out.
                                </FrontParagraphSmall>

                                <FrontParagraphSmall>
                                    <WeightBold>We'll need to do a video call with {getNamesToBookFor()}.</WeightBold> {getVideoVerificationCall(props).users_who_can_be_booked.length > 1 ? "You can either book now for just yourself or everyone can attend on one video call." : ""}
                                </FrontParagraphSmall>
                            </SpacingColumn>

                            {hasMissedAppointment(props) && <FrontInfoBubble color={FrontColors.PRIMARY_22}>
                                We're sorry we missed you last time, please schedule a new appointment below.
                            </FrontInfoBubble>}

                            <SpacingColumn spacing={FrontSpacing.MEDIUM_1}>
                                {/* WHO ARE YOU BOOKING FOR? */}
                                <FrontCondensedMultiSelectDropdownQuestion
                                    label="Who are you booking for?"
                                    closedLabel="Booking for"
                                    value={getVideoVerificationCall(props).create_booking.edited.user_ids}
                                    options={
                                        pipe(
                                            getVideoVerificationCall(props).users_who_can_be_booked,
                                            array.map((user) => ({
                                                value: user.id,
                                                label: user.full_name
                                            }))
                                        )
                                    }
                                    wrapInFormRow={true}
                                    displayValidationErrorsIfPresent={displayValidationErrorsIfPresent}
                                    onChange={(user_ids) => props.dispatch({
                                        type: "CLIENT_VIDEO_VERIFICATION_CALL_UPDATE",
                                        payload: {
                                            ...getVideoVerificationCall(props).create_booking,
                                            edited: {
                                                ...getVideoVerificationCall(props).create_booking.edited,
                                                user_ids,
                                            }
                                        }
                                    })}
                                />

                                {/* ON WHAT DAY/TIME? */}
                                {dateAndTimeFieldsVisible && <FrontBookVideoCallDateAndTimeSelection
                                    form={getVideoVerificationCall(props).create_booking.children}
                                    displayValidationErrorsIfPresent={displayValidationErrorsIfPresent}
                                    onChange={(scheduled_time) => props.dispatch({
                                        type: "CLIENT_VIDEO_VERIFICATION_CALL_UPDATE",
                                        payload: {
                                            ...getVideoVerificationCall(props).create_booking,
                                            edited: {
                                                ...getVideoVerificationCall(props).create_booking.edited,
                                                scheduled_time,
                                            }
                                        }
                                    })}
                                    value={getVideoVerificationCall(props).create_booking.edited.scheduled_time}
                                />}

                                {/* ERRORS & NO APPOINTMENTS AVAILABLE TO BE BOOKED */}
                                {errorGettingAvailableTimes() && <FrontInfoBubble color={FrontColors.ERROR_FIREBRICK_22}>
                                    <WeightBold>We can't find any available slots for you to book right now.</WeightBold> It might be that your case handler's calendar is full
                                    or there's an issue that is preventing the booking. <WeightBold>In any case give us a call on {sailLegalBrandConstants().legalCSNumber} and we will book you in over the phone.</WeightBold>
                                </FrontInfoBubble>}
                            </SpacingColumn>

                            {!errorGettingAvailableTimes() && <SpacingColumn spacing={FrontSpacing.MEDIUM_1}>
                                {/* CONTROL */}
                                <SpacingColumn spacing={FrontSpacing.SMALL_3}>
                                    {dateAndTimeFieldsVisible && <FrontParagraphSmall>
                                        Once you've clicked the button below we'll email you details of your video call appointment as well as instructions on how to join the call.
                                    </FrontParagraphSmall>}

                                    <MediaCompSwitch
                                        DesktopComponent={SpacingRow}
                                        desktopProps={{ spacing: FrontSpacing.SMALL_3}}
                                        MobileComponent={SpacingColumn}
                                        mobileProps={{ spacing: FrontSpacing.SMALL_3 }}
                                        breakpoint="600"
                                    >
                                        <FrontFormPrimaryButton
                                            label={dateAndTimeFieldsVisible ? "Book video call" : "Next"}
                                            icon="done"
                                            isDisabled={getVideoVerificationCall(props).create_booking.status === "submitting"}
                                            onClick={() =>
                                                formCanBeSubmitted()
                                                    ? props.dispatch({
                                                        type: "CLIENT_VIDEO_VERIFICATION_CALL_SUBMIT",
                                                        payload: getVideoVerificationCall(props).create_booking,
                                                    })
                                                    : setDisplayValidationErrorsIfPresent(true)
                                            }
                                        />
                                    </MediaCompSwitch>
                                </SpacingColumn>

                                {displayValidationErrorsIfPresent && !formCanBeSubmitted() && <FrontInfoBubble color={FrontColors.ERROR_FIREBRICK_22}>
                                    <WeightBold>You've missed a few things above,</WeightBold> please take a look and then try again.
                                </FrontInfoBubble>}
                            </SpacingColumn>}
                        </SpacingColumn>}
                </MaxWidth>
            </FrontQuestionFormCard>
        </div>
    );
};

const getVideoVerificationCall = (props: TContainerStateProps) =>
    props.state.forms.client_case_page.children.video_verification_call || {
        users_who_can_be_booked: [],
        create_booking: ClientCaseVideoVerificationCallCreateForm.newDefault(),
        logged_in_user_status: "not_booked",
    };

const hasMissedAppointment = (props: TContainerStateProps): boolean =>
    getVideoVerificationCall(props).logged_in_user_status === "missed_appointment";

const hasBookedAppointment = (props: TContainerStateProps): boolean =>
    getVideoVerificationCall(props).logged_in_user_status === "booked"
    || getVideoVerificationCall(props).users_who_can_be_booked.length === 0;

export const getBookVideoCallStatus = (props: TContainerStateProps): TOnboardingTabStatus => {
    if (hasBookedAppointment(props)) {
        return "completed";
    }

    return "active";
};

export default FrontBookVideoCallContainer;
