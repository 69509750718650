import React from "react";
import { CRMButtonSecondary } from "../CRMButtonSecondary/CRMButtonSecondary";
import { CRMButtonTertiary } from "../CRMButtonTertiary/CRMButtonTertiary";
import { CRMCardInside } from "../CRMCardInside/CRMCardInside";
import { CRMPadding } from "../Simple/CRMPadding/CRMPadding";
import { CRMParagraph } from "../Simple/CRMParagraph/CRMParagraph";
import { CRMSpacingRow } from "../CRMSpacingRow/CRMSpacingRow";
import { CRMSpacer } from "../CRMSpacer/CRMSpacer";
import { CRMTitleParagraph } from "../CRMTitleParagraph/CRMTitleParagraph";

type TCRMCardInsideUserPartySync = {
    userName: string;
    partyMembers: Array<string>;
    phoneNumbers: Array<string>;
    email: string;
    onUseParty: () => void;
    onMakeNew: () => void;
};

export class CRMCardInsideUserPartySync extends React.Component<React.PropsWithChildren<TCRMCardInsideUserPartySync>> {
    public render (): JSX.Element {
        return (
            <CRMCardInside>
                <CRMPadding size="medium">
                    {/* TITLE */}
                    <CRMTitleParagraph>
                        Matches:&nbsp;<u>{this.props.userName}</u>
                    </CRMTitleParagraph>
                    <CRMSpacer size="tiny"/>

                    {/* IN PARTY WITH */}
                    {this.props.partyMembers.length > 0 &&
                        <div>
                            <CRMParagraph>
                                in party with {this.props.partyMembers.join(", ")}
                            </CRMParagraph>
                            <CRMSpacer size="medium"/>
                        </div>
                    }

                    {/* PHONE NUMBERS */}
                    {this.props.phoneNumbers.length > 0 &&
                        <CRMParagraph>
                            <b>{this.props.phoneNumbers.join(", ")}</b>
                        </CRMParagraph>
                    }

                    {/* EMAIL */}
                    <CRMParagraph>
                        <b>{this.props.email}</b>
                    </CRMParagraph>
                    <CRMSpacer size="medium"/>

                    {/* BUTTONS */}
                    <CRMSpacingRow justifyContent="end">
                        <CRMButtonSecondary
                            label="Add this user"
                            onClick={this.props.onMakeNew}
                        />
                        <CRMButtonTertiary
                            label="Use Party"
                            onClick={this.props.onUseParty}
                        />
                    </CRMSpacingRow>
                </CRMPadding>
            </CRMCardInside>
        );
    }
}
