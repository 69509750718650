import React, { useState } from "react";
import { CRMIcon } from "../../../CRMIcon/CRMIcon";
import { CRMColors } from "../../../../models/CRMColors";
import { CRMHoverHighlightText } from "../../../Simple/CRMHoverHighlightText/CRMHoverHighlightText";
import { CRMAssignUserAvatar } from "../../../Complex/CRMAssignUserAvatar/CRMAssignUserAvatar";
import { SpacingRow } from "../../../BuildingBlocks/SpacingRow";
import { CRMSpacing } from "../../../../models/CRMSpacing";
import { useHover } from "../../../../hooks/useHover";
import { TAdhocTaskForms } from "../../../../../../domain/codecs/form/CasesForm";
import { toDateFormat1 } from "../../../../../../shared/src/dateTime";
import { TPlainUser } from "../../../../../../domain/codecs/User";
import { onChangeForm } from "../../../../../../shared/src/codecs/types/form";
import { useOpenClose } from "../../../../hooks/UseOpenClose";
import { Relative } from "../../../BuildingBlocks/Relative";
import { CRMDeferPopout } from "../../../CrmDeferPopout/CRMDeferPopout";
import { Absolute } from "../../../BuildingBlocks/Absolute";
import { CRMCaseCreateAdhocPopup } from "../CRMCaseCreateAdhocPopup/CRMCaseCreateAdhocPopup";
import { CRMLoadingBar } from "../../../Simple/CRMLoadingBar/CRMLoadingBar";
import { DateTime } from "luxon";
import { TIcon } from "../../../CRMIcon/CRMIcon";
import { CRMCasesTaskRowBody, CRMCasesTaskRowCell, CRMCasesTaskRowEditableCell, CRMCasesTaskRowIconCell } from "../CRMCasesTaskRow/CRMCasesTaskRow";
import { makeJobLinkOutOfRouteAction } from "../../../CRMJobs/CRMJobsOverlay/CRMJobsCardLinkGenerators";
import { createChangeRouteAction } from "../../../../state/router/createChangeRouteAction";
import { Routes } from "../../../../state/router/routerRoutes";
import { Background } from "../../../BuildingBlocks/Background";

type TCasesTaskRowChangeableProps = {
    form: TAdhocTaskForms;
    assignableUsers: Array<TPlainUser>;
    onChange: (form: TAdhocTaskForms) => void;
    onArchive: (form: TAdhocTaskForms) => void;
};

export const CRMCasesTaskRowChangeable = (props: TCasesTaskRowChangeableProps): JSX.Element => {
    
    const [hoverRef, isHovering] = useHover<HTMLDivElement>({});
    const [isAssignUserOpen, setIsAssignUserOpen] = useState(false);
    const [cachedEdit, setCachedEdit] = useState({ ... props.form });
    const [isCompleting, setIsCompleting] = useState(false);
    const [isArchiving, setArchiving] = useState(false);
    const openCloseCalendar = useOpenClose();
    const openCloseEditPopup = useOpenClose({ inputRef: hoverRef });


    const IsDone = props.form.original.is_done;
    const IsForDayView = !!props.form.children["address"];
    const IsActive = openCloseCalendar.isOpen || isAssignUserOpen || openCloseEditPopup.isOpen;
    const CheckboxIcon: TIcon =
        isCompleting ? "checkbox-checked":
        isArchiving ? "delete": 
        "checkbox-unchecked"
    ;

    const onChange = onChangeForm(props.form, props.onChange);
    const onSetAsDone = () => setIsCompleting(true);
    const onSubmitAsDone = () => {
        setIsCompleting(false);
        onChange("is_done")(DateTime.local().toUTC().toISO());
    }
    
    const onSetAsArchiving = () => setArchiving(true);
    const onSubmitAsArchived = () => {
        props.onArchive(props.form);
        setArchiving(false);
    }

    const onOpen = () => openCloseEditPopup.toggleOpen();
    const onChangeCachedEdit = onChangeForm(cachedEdit, setCachedEdit);
    const onSubmitEdit = () => {
        props.onChange(cachedEdit);
        openCloseEditPopup.setIsOpen(false);
    }

    const onChangeDate = (date: string | null) => {
        onChange("date")(date);
        openCloseCalendar.setIsOpen(false);
    }

    const onClickAddress = () => {
        let link = makeJobLinkOutOfRouteAction(
            createChangeRouteAction(
                "VIEW_CRM_LEGAL_CASE", 
                { caseId: props.form.children.case_id },
                { visible_tab: "" },
                new Routes().blockParams.VIEW_CRM_LEGAL_CASE,
            )
        );
        window.open(link, "_blank");
    }

    return (
        <CRMCasesTaskRowBody
            divRef={hoverRef}
            isActive={IsActive}
            isClickable={true}
        >
            {/* IF SET TO DONE: COMPLETION LOADING BAR */}
            {isCompleting &&
                <Absolute
                    top="0px"
                    left="0px"
                    width="100%"
                    height="44px" // we want it to be as high as the row so it blocks clicking of other options. 
                                  // may not be the cleanest way, but i prefer it to if statements in each funciton.
                >
                    <CRMLoadingBar
                        duration={2000}
                        onComplete={onSubmitAsDone}
                    />
                </Absolute>
            }
            
            {/* IF SET TO ARCHIVE: COMPLETION LOADING BAR */}
            {isArchiving &&
                <Absolute
                    top="0px"
                    left="0px"
                    width="100%"
                    height="44px"
                >
                    <CRMLoadingBar
                        duration={2000}
                        onComplete={onSubmitAsArchived}
                        fullBarColor={CRMColors.NEUTRAL_4}
                        emptyBarColor={CRMColors.NEUTRAL_8}
                    />
                </Absolute>
            }

            <SpacingRow
                spacing={CRMSpacing.SMALL}
                childSize="24px 1fr"
                alignItems="flex-start"
            >
                {/* CELL - CHECKBOX */}
                {!IsDone &&
                    <CRMCasesTaskRowIconCell
                        iconName={CheckboxIcon}
                        colour="hex"
                        hexColour={isCompleting ? CRMColors.HIGHLIGHTS_POSITIVE_GREEN_0 : CRMColors.NEUTRAL_INK}
                        cursor="pointer"
                        onClick={onSetAsDone}
                    />
                }
                {IsDone && 
                    <CRMCasesTaskRowIconCell
                        iconName={"done"}
                        colour="hex"
                        hexColour={CRMColors.NEUTRAL_INK}
                    />
                }

                {/* CELLS - TEXT*/}
                <SpacingRow
                    spacing={CRMSpacing.MEDIUM}
                    childSize={IsForDayView ? "80px 1fr 3fr 30px" : "80px 1fr 30px"}
                    alignItems="flex-start"
                >
                    {/* CELL - DUE DATE */}
                    <Relative divRef={openCloseCalendar.ref}>
                        <CRMCasesTaskRowEditableCell
                            weight={700}
                            onClick={openCloseCalendar.toggleOpen}
                        >
                            {toDateFormat1(props.form.original.date)}
                        </CRMCasesTaskRowEditableCell>

                        {openCloseCalendar.isOpen &&
                            <Absolute
                                top="25px"
                                left="5px"
                            >
                                <CRMDeferPopout
                                    options="only_date"
                                    onChange={onChangeDate}
                                />
                            </Absolute>
                        }
                    </Relative>
                    
                    {/* IF DAY VIEW: ADDRESS & JOB */}
                    {IsForDayView  &&
                        <>
                            {/* IF DAY VIEW: CELL - CASE ADDRESS  */}
                            <CRMCasesTaskRowCell onClick={onClickAddress}>
                                {props.form.children["address"]}
                            </CRMCasesTaskRowCell>

                            {/* CELL - JOB TEXT */}
                            <CRMCasesTaskRowEditableCell
                                mode="multi-line"
                                onClick={onOpen}
                            >
                                {props.form.original.job}
                            </CRMCasesTaskRowEditableCell>
                        </>
                    }

                    {/* IF CASE VIEW: JUST JOB TEXT */}                    
                    {!IsForDayView  &&
                        <CRMCasesTaskRowEditableCell
                            mode="multi-line"
                            onClick={onOpen}
                        >
                            {props.form.original.job}
                        </CRMCasesTaskRowEditableCell>
                    }
                    
                    {/* CELL - ASSIGNED USER */}
                    <Background display="flex" padding={`6px 0px`}>
                        <CRMAssignUserAvatar
                            size="small"
                            value={props.form.original.assigned_to_user_id}
                            onChange={onChange("assigned_to_user_id")}
                            users={props.assignableUsers}
                            onToggleOpen={setIsAssignUserOpen}
                        />
                    </Background>

                    {/* ON HOVER: ACTIONS */}
                    <div 
                        className={`
                            crm-cases-task-row-changeable__hover-actions-wrap
                            crm-cases-task-row-changeable__hover-actions-wrap--${isHovering ? "shown" : "hidden"}
                        `}
                    >
                        <CRMIcon
                            iconName={"archive"}
                            colour="hex"
                            hexColour={CRMColors.NEUTRAL_INK}
                            size="medium"
                            title={"Delete task."}
                            cursor="pointer"
                            onClick={onSetAsArchiving}
                        />
                    </div>
                </SpacingRow>
            </SpacingRow>
            
            {/* POPUP - EDIT WHOLE TASK */}
            {openCloseEditPopup.isOpen &&
                <CRMCaseCreateAdhocPopup
                    status={props.form.status}
                    assigneeUsers={props.assignableUsers}

                    assignedTo={cachedEdit.edited.assigned_to_user_id}
                    onChangeAssignedTo={onChangeCachedEdit("assigned_to_user_id")}
                    job={cachedEdit.edited.job}
                    onChangeJob={onChangeCachedEdit("job")}
                    date={cachedEdit.edited.date}
                    onChangeDate={onChangeCachedEdit("date")}

                    onSubmit={onSubmitEdit}
                    onClose={openCloseEditPopup.toggleOpen}
                />
            }
        </CRMCasesTaskRowBody>
    );
};
