import { array } from "fp-ts";
import { pipe } from "fp-ts/lib/function";
import React from "react";
import { CaseLedgerType, ledgerTypeDictionary, TCaseLedgerType } from "../../../../../domain/codecs/CaseLedger";
import { TQuoteRateCreatedForm } from "../../../../../domain/codecs/form/QuoteRateForm";
import { onChangeForm } from "../../../../../shared/src/codecs/types/form";
import { IOption } from "../../../hooks/UseDropdown";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { SpacingColumn } from "../../BuildingBlocks/SpacingColumn";
import CRMInputLabelAndErrorWrapComponent from "../../CRMInputLabelAndErrorWrapComponent/CRMInputLabelAndErrorWrapComponent";
import { CRMNoticeBoxComponent } from "../../CRMNoticeBoxComponent/CRMNoticeBoxComponent";
import { CRMParagraph } from "../../Simple/CRMParagraph/CRMParagraph";
import { CRMFilterDropdown } from "../../Simple/CRMFilterDropdown/CRMFilterDropdown";
import { WeightBold } from "../../WeightBold/WeightBold";

export const CRMQuoteRatesSingleViewEditBasicQuestions = (props: React.PropsWithChildren<{
    form: TQuoteRateCreatedForm,
    displayError: boolean,
    onChange: (form: TQuoteRateCreatedForm) => void,
}>): JSX.Element => {
    const onChange = onChangeForm({
        ...props.form,
        edited: {
            ...props.form.edited,
            applies_when_tax_group_is: null, // The tax group must be reset on change here or could lead to odd behavior where a previous selection has been made even though the option isn't presented to the user in the UI
        }
    }, props.onChange);

    const shouldShowReferralFeeBehaviorWarning =
        props.form.edited.rate_type === "legal_fee"
        && props.form.children.introducer_id;

    return <SpacingColumn spacing={CRMSpacing.MEDIUM}>
        {/* REFERRAL FEE BEHAVIOR WARNING */}
        {shouldShowReferralFeeBehaviorWarning &&
            <CRMNoticeBoxComponent>
                <SpacingColumn spacing={CRMSpacing.MEDIUM}>
                    <CRMParagraph>
                        Before creating a custom legal fee for this introducer please be aware that the total legal fee quoted to the client will include the referral fee.
                    </CRMParagraph>

                    <CRMParagraph>
                        If you only want to create a custom Legal Fee rate in order to add on the referral <WeightBold>then you do not need to create one as this will be handled automatically.</WeightBold>
                    </CRMParagraph>
                </SpacingColumn>
            </CRMNoticeBoxComponent>
        }

        {/* WHAT TYPE OF RATE IS THIS? */}
        <CRMInputLabelAndErrorWrapComponent label="What type of rate is this?">
            <CRMFilterDropdown
                value={props.form.edited.rate_type || ""}
                options={pipe(
                    CaseLedgerType.values,
                    array.map((value) => ({
                        label: ledgerTypeDictionary[value].displayName,
                        value,
                    })),
                    array.prepend<IOption<TCaseLedgerType | "">>({
                        label: "Please select",
                        value: "",
                    }),
                )}
                onChange={(value) => onChange("rate_type")(value || null)}
                displayError={props.displayError && props.form.edited.rate_type === null}
            />
        </CRMInputLabelAndErrorWrapComponent>
    </SpacingColumn>
};
