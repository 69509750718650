import React from "react";
import { CRMColors } from "../../../models/CRMColors";
import { CRMIcon } from "../../CRMIcon/CRMIcon";
import { WeightBold } from "../../WeightBold/WeightBold";
import { toDateFormat1 } from "../../../../../shared/src/dateTime";
import { Text } from "../../BuildingBlocks/Text";
import { CRMFontSizes } from "../../../models/CRMFontSizes";
import { Padding } from "../../BuildingBlocks/Padding";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { SpacingRow } from "../../BuildingBlocks/SpacingRow";
import { Relative } from "../../BuildingBlocks/Relative";
import { useHover } from "../../../hooks/useHover";
import { CRMTimelineControlsWrapper } from "../../CRMTimelineControlsWrapper/CRMTimelineControlsWrapper";
import { WeightMedium } from "../../WeightMedium/WeightMedium";
import { Background } from "../../BuildingBlocks/Background";
import { CursorPointer } from "../../CursorPointer/CursorPointer";
import { isAPlainFunction } from "../../../functions/functions";

export const CRMTimelineEvent = (props: {
    date: string,
    eventDescription: string,
    onClickDelete?: () => void;
}): JSX.Element => {
    const [ref, isHovering] = useHover<HTMLDivElement>({});
    
    return (
        <Relative divRef={ref}>
            {/* EVENT CARD */}
            <Background
                color={CRMColors.HIGHLIGHTS_CALMING_PINK_12}
                borderDefinition={`1px solid ${CRMColors.HIGHLIGHTS_CALMING_PINK_12}`}
                borderRadius="5px"
            >
                <Padding spacing={CRMSpacing.SMALL}>
                    <SpacingRow
                        justifyContent="start"
                        spacing={CRMSpacing.SMALL}
                    >
                        {/* ICON */}
                        <CRMIcon
                            iconName="lightbulb"
                            colour="neutral-0"
                            size="medium"
                        />

                        {/* EVENT DESCRIPTION */}
                        <Text
                            size={CRMFontSizes.SMALL_PLUS}
                            color={CRMColors.NEUTRAL_0}
                            weight={400}
                        >
                            {toDateFormat1(props.date)} - <WeightMedium>Grievance has been</WeightMedium> <WeightBold>{props.eventDescription}</WeightBold>
                        </Text>
                    </SpacingRow>
                </Padding>
            </Background>

            {/* DELETE */}
            {isHovering && isAPlainFunction(props.onClickDelete) && 
                <CRMTimelineControlsWrapper
                    top="0px"
                    right="0px"
                    bottom="0px"
                    backgroundColour={CRMColors.HIGHLIGHTS_CALMING_PINK_12}
                >
                    <CursorPointer onClick={props.onClickDelete}>
                        <CRMIcon
                            iconName="delete"
                            colour="neutral-ink"
                            size="small"
                        />
                    </CursorPointer>
                </CRMTimelineControlsWrapper>
            }
        </Relative>
    );
};
