import { either, identity } from "fp-ts";
import { pipe } from "fp-ts/lib/pipeable";
import React from "react";
import { TDetailedLegalEmailForTriage } from "../../../../../domain/codecs/LegalEmail";
import { sanitizeEmail } from "../../../functions/email/sanitizeEmail";

type TEmailBody = {
    email: TDetailedLegalEmailForTriage;
}

export const CRMEmailBody = (props: TEmailBody) => {
    
    const plainTextEmailInlineStyles = {
        whiteSpace: "pre-wrap", 
        wordWrap: "break-word", 
        marginLeft: "16px", 
        marginRight: "16px"
    }

    const sanatizeEmailBody = (emailBody: string, emailId: string): string => 
        pipe(
            either.tryCatch(
                () => sanitizeEmail(emailBody, emailId), 
                identity.flatten
            ),
            either.getOrElse(() => "")
        )
    ;
    
    const isTextOnlyEmail = () => props.email.html_or_text === "text";
    
    const getEmailHTML = () => sanatizeEmailBody(
        props.email.body_html_or_text, 
        props.email.id
    );

    const getInlineStyle = () => isTextOnlyEmail() ? plainTextEmailInlineStyles : {};

    const getBodyElement = () => isTextOnlyEmail() ? "pre" : "div"; 

    return React.createElement(
        getBodyElement(),
        {
            dangerouslySetInnerHTML: { __html: getEmailHTML() },
            style: getInlineStyle() 
        }
    )
}
