import { from, merge, Observable, of } from "rxjs";
import { tap, switchMap } from "rxjs/operators";
import { formOperation } from "../../wrappers/formOperation";
import { TActionsDefinitionsList } from "./TAction";
import { action, routeAction } from "./actionFunctions";
import { runAutomaticUpdates } from "./automaticBlockUpdate";
import { TChaseModeUpdateForm, TChaseModeUpdateFormWithChaseReasons } from "../../../../domain/codecs/form/CasesChaseTransitionForm";

export const actions: TActionsDefinitionsList = [
    routeAction("VIEW_CRM_LEGAL_CASE", (obs$, lens, setState, getState) => {
        obs$.pipe(
            tap((a) => setState(lens.chase_mode_form.edited.id.set(a.params.caseId))),
            switchMap(() =>
                from(formOperation("GetCaseChaseMode", lens.chase_mode_form.get()(getState())))
                    .pipe(
                        tap((response) => setState(lens.chase_mode_form.set(response))),
                    )
            ),
        ).subscribe();
    }),
    // Chaser actions
    action("LEGAL_CASE_ACCEPT_TO_CHASE_MODE_FORM_SUBMIT", (obs$: Observable<undefined>, lens, setState, getState, routes, dispatch) => {
        obs$.pipe(
            tap(() => setState(lens.chase_mode_form.children.accept_chase_transition_form.status.set("submitting"))),
            switchMap(() => formOperation("ChaseModeAccept", lens.chase_mode_form.children.accept_chase_transition_form.get()(getState()))),
            tap((res) => setState(lens.chase_mode_form.children.accept_chase_transition_form.set(res))),
            switchMap((res) =>
                res.status === "success"
                    ? from(formOperation("GetCaseChaseMode", lens.chase_mode_form.get()(getState())))
                        .pipe(
                            tap((response) => setState(lens.chase_mode_form.set(response))),
                        )
                    : of(undefined)
            ),
            tap(() => runAutomaticUpdates(routes, dispatch, getState, "case_details"))
        ).subscribe();
    }),
    action("LEGAL_CASE_REJECT_FROM_CHASE_MODE_FORM_SUBMIT", (obs$: Observable<undefined>, lens, setState, getState, routes, dispatch) => {
        obs$.pipe(
            tap(() => setState(lens.chase_mode_form.children.reject_from_chase_mode_form.status.set("submitting"))),
            switchMap(() => formOperation("ChaseModeReject", lens.chase_mode_form.children.reject_from_chase_mode_form.get()(getState()))),
            tap((res) => setState(lens.chase_mode_form.children.reject_from_chase_mode_form.set(res))),
            switchMap((res) =>
                res.status === "success"
                    ? from(formOperation("GetCaseChaseMode", lens.chase_mode_form.get()(getState())))
                    .pipe(
                        tap((response) => setState(lens.chase_mode_form.set(response))),
                    )
                    : of(undefined)
            ),
            tap(() => runAutomaticUpdates(routes, dispatch, getState, "case_details"))
        ).subscribe();
    }),
    // Requester actions
    action("LEGAL_CASE_WORKABLE_MODE_ACKNOWLEDGE_FORM_SUBMIT", (obs$: Observable<undefined>, lens, setState, getState, routes, dispatch) => {
        obs$.pipe(
            tap(() => setState(lens.chase_mode_form.children.acknowledge_workable_transition_form.status.set("submitting"))),
            switchMap(() => formOperation("ChaseModeWorkableModeAcknowledge", lens.chase_mode_form.children.acknowledge_workable_transition_form.get()(getState()))),
            tap((res) => setState(lens.chase_mode_form.children.acknowledge_workable_transition_form.set(res))),
            switchMap((res) =>
                res.status === "success"
                    ? from(formOperation("GetCaseChaseMode", lens.chase_mode_form.get()(getState())))
                    .pipe(
                        tap((response) => setState(lens.chase_mode_form.set(response))),
                    )
                    : of(undefined)
            ),
            tap(() => runAutomaticUpdates(routes, dispatch, getState, "case_details"))
        ).subscribe();
    }),
    action("LEGAL_CASE_TO_CHASE_MODE_FORM_CHANGE", (obs$: Observable<TChaseModeUpdateFormWithChaseReasons>, lens, setState) => {
        obs$.pipe(
            tap((payload) => setState(lens.chase_mode_form.children.submit_to_chase_mode_form.set({
                ...payload,
                status: "requiresSubmission",
            }))),
        ).subscribe();
    }),
    action("LEGAL_CASE_TO_CHASE_MODE_FORM_SUBMIT", (obs$: Observable<void>, lens, setState, getState, routes, dispatch) => {
        obs$.pipe(
            tap(() => setState(lens.chase_mode_form.children.submit_to_chase_mode_form.status.set("submitting"))),
            switchMap(() => formOperation("ChaseModeSubmit", lens.chase_mode_form.children.submit_to_chase_mode_form.get()(getState()))),
            tap((res) => setState(lens.chase_mode_form.children.submit_to_chase_mode_form.set(res))),
            switchMap((res) =>
                res.status === "success"
                    ? from(formOperation("GetCaseChaseMode", lens.chase_mode_form.get()(getState())))
                    .pipe(
                        tap((response) => setState(lens.chase_mode_form.set(response))),
                    )
                    : of(undefined)
            ),
            tap(() => runAutomaticUpdates(routes, dispatch, getState, "case_details"))
        ).subscribe();
    }),
    action("LEGAL_CASE_TO_WORKABLE_MODE_FORM_SUBMIT", (obs$: Observable<undefined>, lens, setState, getState, routes, dispatch) => {
        obs$.pipe(
            tap(() => setState(lens.chase_mode_form.children.submit_to_workable_mode_form.status.set("submitting"))),
            switchMap(() => formOperation("ChaseModeSubmitToWorkable", lens.chase_mode_form.children.submit_to_workable_mode_form.get()(getState()))),
            tap((res) => setState(lens.chase_mode_form.children.submit_to_workable_mode_form.set(res))),
            switchMap((res) =>
                res.status === "success"
                    ? from(formOperation("GetCaseChaseMode", lens.chase_mode_form.get()(getState())))
                        .pipe(
                            tap((response) => setState(lens.chase_mode_form.set(response))),
                        )
                    : of(undefined)
            ),
            tap(() => runAutomaticUpdates(routes, dispatch, getState, "case_details"))
        ).subscribe();
    }),
    // Close/Reopen actions
    action("LEGAL_CASE_CHASE_MODE_CLOSE_AWAITING_NEW_CASE_FORM_SUBMIT", (obs$: Observable<TChaseModeUpdateForm>, lens, setState, getState, routes, dispatch) => {
        obs$.pipe(
            tap((payload) => setState(lens.chase_mode_form.children.submit_to_closed_awaiting_new_case_form.set({
                ...payload,
                status: "submitting",
            }))),
            switchMap(() => formOperation("ChaseModeCloseAwaitingNewCase", lens.chase_mode_form.children.submit_to_closed_awaiting_new_case_form.get()(getState()))),
            tap((res) => setState(lens.chase_mode_form.children.submit_to_closed_awaiting_new_case_form.set(res))),
            switchMap((res) =>
                res.status === "success"
                    ? merge(
                        from(formOperation("GetCaseChaseMode", lens.chase_mode_form.get()(getState())))
                            .pipe(
                                tap((response) => setState(lens.chase_mode_form.set(response))),
                            ),
                        from(formOperation("GetCase", lens.case_details_page.data.get()(getState())))
                            .pipe(
                                tap((response) => setState(lens.case_details_page.data.set(response))),
                            )
                    )
                    : of(undefined)
            ),
            tap(() => runAutomaticUpdates(routes, dispatch, getState, "case_details"))
        ).subscribe();
    }),
    action("LEGAL_CASE_CHASE_MODE_CLOSE_CASE_UNCCESSFULLY_FORM_SUBMIT", (obs$: Observable<TChaseModeUpdateForm>, lens, setState, getState, routes, dispatch) => {
        obs$.pipe(
            tap((payload) => setState(lens.chase_mode_form.children.submit_to_closed_unsuccessfully_form.set({
                ...payload,
                status: "submitting"
            }))),
            switchMap(() => formOperation("ChaseModeCloseCaseUnsuccessfully", lens.chase_mode_form.children.submit_to_closed_unsuccessfully_form.get()(getState()))),
            tap((res) => setState(lens.chase_mode_form.children.submit_to_closed_unsuccessfully_form.set(res))),
            switchMap((res) =>
                res.status === "success"
                    ? merge(
                        from(formOperation("GetCaseChaseMode", lens.chase_mode_form.get()(getState())))
                            .pipe(
                                tap((response) => setState(lens.chase_mode_form.set(response))),
                            ),
                        from(formOperation("GetCase", lens.case_details_page.data.get()(getState())))
                            .pipe(
                                tap((response) => setState(lens.case_details_page.data.set(response))),
                            )
                    )
                    : of(undefined)
            ),
            tap(() => runAutomaticUpdates(routes, dispatch, getState, "case_details"))
        ).subscribe();
    }),
    action("LEGAL_CASE_CHASE_MODE_CLOSE_CASE_OPENED_IN_ERROR_FORM_SUBMIT", (obs$: Observable<TChaseModeUpdateForm>, lens, setState, getState, routes, dispatch) => {
        obs$.pipe(
            tap((payload) => setState(lens.chase_mode_form.children.submit_to_closed_unsuccessfully_form.set({
                ...payload,
                status: "submitting"
            }))),
            switchMap(() => formOperation("ChaseModeCloseCaseOpenedInError", lens.chase_mode_form.children.submit_to_closed_unsuccessfully_form.get()(getState()))),
            tap((res) => setState(lens.chase_mode_form.children.submit_to_closed_unsuccessfully_form.set(res))),
            switchMap((res) =>
                res.status === "success"
                    ? merge(
                        from(formOperation("GetCaseChaseMode", lens.chase_mode_form.get()(getState())))
                            .pipe(
                                tap((response) => setState(lens.chase_mode_form.set(response))),
                            ),
                        from(formOperation("GetCase", lens.case_details_page.data.get()(getState())))
                            .pipe(
                                tap((response) => setState(lens.case_details_page.data.set(response))),
                            )
                    )
                    : of(undefined)
            ),
            tap(() => runAutomaticUpdates(routes, dispatch, getState, "case_details"))
        ).subscribe();
    }),
    action("LEGAL_CASE_REOPEN_CASE_FORM_SUBMIT", (obs$: Observable<undefined>, lens, setState, getState, routes, dispatch) => {
        obs$.pipe(
            tap(() => setState(lens.chase_mode_form.children.submit_to_reopen_form.status.set("submitting"))),
            switchMap(() => formOperation("ChaseModeReopenCase", lens.chase_mode_form.children.submit_to_reopen_form.get()(getState()))),
            tap((res) => setState(lens.chase_mode_form.children.submit_to_reopen_form.set(res))),
            switchMap((res) =>
                res.status === "success"
                    ? merge(
                        from(formOperation("GetCaseChaseMode", lens.chase_mode_form.get()(getState())))
                            .pipe(
                                tap((response) => setState(lens.chase_mode_form.set(response))),
                            ),
                        from(formOperation("GetCase", lens.case_details_page.data.get()(getState())))
                            .pipe(
                                tap((response) => setState(lens.case_details_page.data.set(response))),
                            )
                    )
                    : of(undefined)
            ),
            tap(() => runAutomaticUpdates(routes, dispatch, getState, "case_details"))
        ).subscribe();
    }),
];