import React from "react";
import { FrontColors } from "../../../models/FrontColors";
import { FrontSpacing } from "../../../models/FrontSpacing";
import { Flex } from "../../BuildingBlocks/Flex";
import { MaxWidth } from "../../BuildingBlocks/MaxWidth";
import { SpacingColumn } from "../../BuildingBlocks/SpacingColumn";
import { FPLandingTitle } from "../Simple/FPLandingTitle/FPLandingTitle";
import { FPSimpleLogo } from "../Simple/FPSimpleLogo/FPSimpleLogo";
import { TPublicPropertyReportAddressSearchForm } from "../../../../../domain/codecs/form/PublicPropertyReportForm";
import { Background } from "../../BuildingBlocks/Background";
import { FontQuicksand } from "../../BuildingBlocks/FontQuicksand";
import { FontSize } from "../../BuildingBlocks/FontSize";
import { TextColor } from "../../BuildingBlocks/TextColor";
import { WeightSemiBold } from "../../WeightSemiBold/WeightSemiBold";
import { WeightBold } from "../../WeightBold/WeightBold";
import { FPButton } from "../Complex/FPButton/FPButton";
import { Width } from "../../BuildingBlocks/Width";

export const FPPropertyReportAddressSelect = (props: {
    form: TPublicPropertyReportAddressSearchForm,
    onSelect: (id: number) => void,
}): JSX.Element =>
    <SpacingColumn
        spacing={FrontSpacing.LARGE_1}
        justifyContent="center"
    >
        {/* LOGO */}
        <Flex justifyContent="center">
            <FPSimpleLogo width="95px" />
        </Flex>

        {/* HEADER */}
        <Flex justifyContent="center">
            <MaxWidth width="530px">
                <FPLandingTitle
                    size={60}
                    color={FrontColors.NEUTRAL_18}
                    align="center"
                >
                    Nearly There...
                </FPLandingTitle>
            </MaxWidth>
        </Flex>

        {/* WHITE AREA */}
        <Flex justifyContent="center">
            <Background
                color={FrontColors.NEUTRAL_18}
                borderRadius="10px"
                width="700px"
                padding={FrontSpacing.LARGE_1}
            >
                <SpacingColumn spacing={FrontSpacing.MEDIUM_2}>
                    {/* PLEASE SELECT THE CLOSEST MATCHING PROPERTY */}
                    <FontQuicksand>
                        <FontSize size="20px">
                            <TextColor color={FrontColors.NEUTRAL_2}>
                                <WeightSemiBold>
                                    We've found a few properties by that address.&nbsp;
                                </WeightSemiBold>
                                <WeightBold>
                                    Please select the correct one.
                                </WeightBold>
                            </TextColor>
                        </FontSize>
                    </FontQuicksand>

                    {/* OPTIONS */}
                    <SpacingColumn spacing={FrontSpacing.SMALL_2}>
                        {props.form.children.results.map((option) =>
                            <Width
                                key={option.edited.id}
                                width="fit-content"
                            >
                                <FPButton
                                    label={<WeightBold>{option.children.address_string}</WeightBold>}
                                    onClick={() => props.onSelect(option.edited.id)}
                                />
                            </Width>
                        )}
                    </SpacingColumn>
                </SpacingColumn>
            </Background>
        </Flex>
    </SpacingColumn>;
