import React from "react";
import { CRMFontSizes } from "../../../models/CRMFontSizes";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { SpacingRow } from "../../BuildingBlocks/SpacingRow";
import { CRMIcon, TIcon } from "../../CRMIcon/CRMIcon";
import { CRMParagraph } from "../../Simple/CRMParagraph/CRMParagraph";

type TIconLabelProps = {
    label: JSX.Element | string;
    iconName: TIcon;
};

export const CRMIconLabel = (props: React.PropsWithChildren<TIconLabelProps>): JSX.Element => {

    return (
        <SpacingRow spacing={CRMSpacing.TINY}>
            <CRMIcon
                iconName={props.iconName}
                colour="neutral-ink"
                size={"20px"}
            />
            <CRMParagraph fontSize={CRMFontSizes.SMALL}>
                {props.label}
            </CRMParagraph>
        </SpacingRow>
    );
};
