import React from "react";
import { CaseLedgerType_displayString, TCaseLedgerAmountAndPaidStatus } from "../../../../../../domain/codecs/CaseLedger";
import { fromNumber } from "../../../../../../domain/models/TCurrency";
import { Background } from "../../../BuildingBlocks/Background";
import { FrontSpacing } from "../../../../models/FrontSpacing";
import { FrontColors } from "../../../../models/FrontColors";
import { SpacingRow } from "../../../BuildingBlocks/SpacingRow";
import { FrontIcon } from "../FrontIcon/FrontIcon";
import FrontParagraphSmall from "../FrontParagraphSmall/FrontParagraphSmall";
import { requireExhaustive } from "../../../../../../shared/src/util";
import { SpacingColumn } from "../../../BuildingBlocks/SpacingColumn";
import { WeightBold } from "../../../WeightBold/WeightBold";
import { WeightSemiBold } from "../../../WeightSemiBold/WeightSemiBold";

export const FrontPaymentTransactionRow = React.memo((props: {
    item: TCaseLedgerAmountAndPaidStatus
}): JSX.Element => {
    const amountString = fromNumber(props.item.amount / 100, 2);

    const explanation = CaseLedgerType_displayString(props.item.type);

    const paidStatus =
        props.item.status === "paid" ? "Payment received"
        : props.item.status === "user_says_paid" ? "You've told us you've paid"
        : props.item.status === "not_paid" ? ""
        : requireExhaustive(props.item.status);

    return <SpacingColumn spacing={FrontSpacing.SMALL_2}>
        {/* HEADING */}
        <Background
            padding={`${FrontSpacing.SMALL_2} ${FrontSpacing.SMALL_3}`}
            color={FrontColors.POSITIVE_APPLE_22}
            borderRadius="3px"
        >
            <SpacingRow
                justifyContent="start"
                alignItems="center"
                spacing={FrontSpacing.SMALL_2}
            >
                {/* ICON */}
                <FrontIcon
                    iconName="done"
                    size="small"
                    colour="neutral-0"
                />

                {/* TEXT */}
                <FrontParagraphSmall>
                    <WeightSemiBold>
                        {paidStatus}
                    </WeightSemiBold>
                </FrontParagraphSmall>
            </SpacingRow>
        </Background>

        {/* DETAILS */}
        <SpacingColumn spacing={FrontSpacing.MINIMUM}>
            {/* AMOUNT */}
            <FrontParagraphSmall>
                <WeightBold>Amount:</WeightBold> {amountString}
            </FrontParagraphSmall>

            {/* EXPLANATION */}
            <FrontParagraphSmall>
                <WeightBold>For:</WeightBold> {explanation}
            </FrontParagraphSmall>
        </SpacingColumn>
    </SpacingColumn>
});
