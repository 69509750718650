import DOMPurify from "dompurify";

// These hooks are adapted from javascript DOMPurify demo example: https://github.com/cure53/DOMPurify/blob/main/demos/hooks-proxy-demo.html
// They aim to cover all known http leaks (https://github.com/cure53/HTTPLeaks/blob/main/leak.html) and vulnerabilities to xss attacks

// Some naaasty global state needed here in order to get around the fact that DOMPurify gives you no way of passing in vars from outside
let emailID = "";

const proxy = `${env.REACT_APP_API_URL}/v1/external-link-safe-redirect?redirect_url=`;

const config = {
    FORBID_TAGS: ['svg','title'],
    WHOLE_DOCUMENT: true,
};

const urlAttributes = ['action', 'background', 'href', 'poster', 'src', 'srcset'];

const urlRegex = /(url\("?)(?!data:)/gim;

/**
 *  Take CSS property-value pairs and proxy URLs in values,
 *  then add the styles to an array of property-value pairs
 */
const addStyles = (output: Array<string>, styles: CSSStyleDeclaration) => {
    if (styles.length) {
        Object.keys(styles).map((propIndex) => {
            if (urlRegex.test(styles[styles[propIndex]])) {
                styles[styles[propIndex]] = styles[styles[propIndex]].replace(urlRegex, '$1' + proxy)
            }
            output.push(`${styles[propIndex]}:${styles[styles[propIndex]]};`);
        });
    };
};

/**
 * Take CSS rules and analyze them, proxy URLs via addStyles(),
 * then create matching CSS text for later application to the DOM
 */

const addCSSRules = (output: Array<string>, cssRules: CSSRuleList | undefined) => {
    if (cssRules) {
        Object.keys(cssRules).map((rule) => {
            var rulePropName = cssRules[rule];
            var bodyOrAllRegex = /body|\*/gim;
            
            if (bodyOrAllRegex.test(rulePropName.selectorText)) {
                var currentRule = rulePropName;
                currentRule.selectorText = currentRule.selectorText.replace(bodyOrAllRegex,".crm-legal-email-detail-view__email-content")
                output.push(`${rulePropName.selectorText}{`);
                addStyles(output, currentRule.style);
                output.push("}");
            } else if (
                rulePropName === "@media"
                || rulePropName.constructor.name === "@media"
                || rulePropName.type === rulePropName.MEDIA_RULE
            ) {
                output.push(`@media ${rulePropName.media.mediaText}{`);
                addCSSRules(output, rulePropName.cssRules);
                output.push("}");
            } else if (
                rulePropName === "@font-face"
                || rulePropName.constructor.name === "@font-face"
                || rulePropName.type === rulePropName.FONT_FACE_RULE
            ) {
                output.push(`@font-face {`);
                if (rulePropName.style) {
                    addStyles(output, rulePropName.style);
                }
                output.push("}")
            } else if (
                rulePropName === "@import"
                || rulePropName.constructor.name === "@import"
                || rulePropName.type === rulePropName.IMPORT_RULE
            ) {
                output.push(`@import {}`);
            } else if (
                rulePropName === "@keyframes"
                || rulePropName.constructor.name === "@keyframes"
                || rulePropName.type === rulePropName.KEYFRAMES_RULE
            ) {
                output.push(`@keyframes ${rulePropName.name}{`);
                if (rulePropName.cssRules.length) {
                    var keyframeRules: Array<CSSKeyframeRule> = rulePropName.cssRules;
                    Object.keys(keyframeRules).map((frameRule) => {
                        if (keyframeRules[frameRule].keyText && keyframeRules[frameRule].type === 8) {
                            if (keyframeRules[frameRule].style) {
                                output.push(`${keyframeRules[frameRule].keyText}{`);
                                addStyles(output, keyframeRules[frameRule].style);
                            };
                            output.push("}");
                        }
                    });
                }
                output.push("}");
            } else {
                var selectorText = rulePropName.selectorText;
                if (selectorText.indexOf(",") !== -1) {
                    var selectorTextArray = selectorText.split(",")
                    selectorText = selectorTextArray.join(", .crm-legal-email-detail-view__email-content");
                };
                output.push(`.crm-legal-email-detail-view__email-content ${selectorText} {`);
                addStyles(output, rulePropName.style)
                output.push('}');
            }

        })
    }
};

/**
 * Proxy a URL in case it's not a Data URI
*/
const proxyAttribute = (attribute: string, url: string) => {
    if ((/data:image\//.test(url) || url.startsWith(proxy))
        // the below handles leaking svg via data:image e.g. 
        // <img src="data:image/svg+xml,<svg%20xmlns='%68ttp:%2f/www.w3.org/2000/svg'%20xmlns:xlink='%68ttp:%2f/www.w3.org/1999/xlink'><image%20xlink:hr%65f='%68ttps:%2f/leaking.via/svg-via-data'></image></svg>">
        // becomes <img src="https://www.proxyredirect.com?redirect_url=_encoded_url_bit">
        && (/<svg/.test(url) === false)
    ) { 
        return url;
    } else if (attribute === "src" && /^cid:/.test(url)) {
        // Replace inline cid src attributes with a link to our hosted version of the file
        return url.replace("cid:", proxy + window.encodeURIComponent(`${env.REACT_APP_API_URL}/web/email-cid-download?legal_emails_id=${emailID}&cid=`));
    } else {
        return proxy + window.encodeURIComponent(url);
    }
};

// Add a hook to enforce proxy for leaky CSS rules
const addSanitizeStyleElementHook = () => DOMPurify.addHook('uponSanitizeElement', function (node, data) {
    if (node.tagName === "STYLE" || data.tagName === "style") {
        var output = [];
        var styleElement = node as HTMLStyleElement;
        var styleSheetRuleList = styleElement.sheet?.cssRules;
        addCSSRules(output, styleSheetRuleList);
        node.textContent = output.join('\n');
    }
});

// Add a hook to enforce proxy for all HTTP leaks incl. inline CSS
const addSanitizeAttributesHook = () => DOMPurify.addHook('afterSanitizeAttributes', function (node) {
    urlAttributes.map((urlAttr) => {
        if (node.hasAttribute(urlAttr)) {
            node.setAttribute(
                urlAttr,
                proxyAttribute(urlAttr, node.attributes[urlAttr].value)
            );
        };
    });

    // Add a hook to make all links open a new window
    // set all elements owning target to target=_blank
    if ('target' in node) {
        node.setAttribute('target','_blank');
        // prevent https://www.owasp.org/index.php/Reverse_Tabnabbing
        node.setAttribute('rel', 'noopener noreferrer');
    }
    
    // set non-HTML/MathML links to xlink:show=new
    if (!node.hasAttribute('target')
        && (node.hasAttribute('xlink:href')
            || node.hasAttribute('href'))) {
        node.setAttribute('xlink:show', 'new');
    }

    if (node.hasAttribute("style")) {
        var styles: CSSStyleDeclaration = (node as HTMLElement).style;
        var output: Array<string> = [];
        for (var propIndex = 0; propIndex <= styles.length -1; propIndex++) {
            // we re-write each property-value pair to remove invalid CSS
            if ((node as HTMLElement).style[styles[propIndex]] && urlRegex.test((node as HTMLElement).style[styles[propIndex]])) {
                var url: string = (node as HTMLElement).style[styles[propIndex]].replace(urlRegex, '$1' + proxy);
                (node as HTMLElement).style[styles[propIndex]] = url;
            };
            output.push(styles[propIndex] + ':' + (node as HTMLElement).style[styles[propIndex]] + ';');
        }
        // re-add styles in case any are left
        if (output.length) {
            node.setAttribute('style', output.join(''));
        } else {
            node.removeAttribute('style');
        }
    };
});

export const addHooksToDomPurify = () => {
    addSanitizeStyleElementHook();
    addSanitizeAttributesHook();
};

export const sanitizeEmail = (emailBody: string | Node, eId: string) => {
    emailID = eId;
    return DOMPurify.sanitize(emailBody, config);
};