import * as rxjs from "rxjs";
import * as rxjsOperators from "rxjs/operators";
import { option } from "fp-ts/lib";
import { pipe } from "fp-ts/lib/function";
import * as fetchWrapper from "../../../wrappers/fetch";
import * as FirstPartyFetchResponse from "../../../../../domain/models/FirstPartyFetchResponse";
import * as OfferSuccessResponse2 from "../../../../../domain/models/OfferSuccessResponse2";
import * as ListingEnquiriesViewingSuccessResponse3 from "../../../../../domain/models/ListingEnquiriesViewingSuccessResponse3";
import * as ListingSuccessResponse1 from "../../../../../domain/models/ListingSuccessResponse1";
import * as EnquirySuccessResponse3 from "../../../../../domain/models/EnquirySuccessResponse3";
import * as PartyResponse1 from "../../../../../domain/models/PartyResponse1";
import * as ViewingFilter1 from "../../../../../domain/models/ViewingFilter1";
import * as OfferFilter1 from "../../../../../domain/models/OfferFilter1";
import * as util from "../../../util";
import * as actionable from "../../../functions/actionable";
import * as TForm from "../../../models/TForm";
import { DateTime } from "luxon";
import {
    TListingEnquiriesOffer2AndListingEnquiriesOfferForm2FormList,
    TListingEnquiriesViewing2ReadOnlyFormList,
} from "../../../models/TFormModels";
import { TGetState } from "../../TGetState";
import { TSetState } from "../../TSetState";
import { reduceDataToStateUpdate } from "../../../functions/lens/reduceDataToStateUpdate";
import { set } from "../../../functions/lens/set";

type TListingPerformanceUpdateOffersRequestPayloads = [
    string,
    FirstPartyFetchResponse.T<OfferSuccessResponse2.T>,
    FirstPartyFetchResponse.T<OfferSuccessResponse2.T>,
];

type TListingPerformanceUpdateOffersAndViewingsRequestPayloads= [
    string,
    FirstPartyFetchResponse.T<OfferSuccessResponse2.T>,
    FirstPartyFetchResponse.T<OfferSuccessResponse2.T>,
    FirstPartyFetchResponse.T<ListingEnquiriesViewingSuccessResponse3.T>,
    FirstPartyFetchResponse.T<ListingEnquiriesViewingSuccessResponse3.T>,
];

export const reloadOffersActionable = (listingId: string, getState: TGetState, setState: TSetState): rxjs.Observable<actionable.TActionablePack<TListingPerformanceUpdateOffersRequestPayloads>> =>
         pipe(
            actionable.of<TListingPerformanceUpdateOffersRequestPayloads>(
                setState,
                getState,
                [
                    listingId,
                    FirstPartyFetchResponse.create2XX(OfferSuccessResponse2.newDefault()),
                    FirstPartyFetchResponse.create2XX(OfferSuccessResponse2.newDefault()),
                ],
            ),
            actionable.fromPayloadIndexForkJoin<0, 1 | 2, TListingPerformanceUpdateOffersRequestPayloads>(0, [
                [1, makeGetPendingOrAcceptedOffers],
                [2, makeGetNotPendingOrAcceptedOffers],
            ]),
            actionable.tapPayloads<TListingPerformanceUpdateOffersRequestPayloads>(
                reduceDataToStateUpdate<TListingPerformanceUpdateOffersRequestPayloads>(setState)(
                    set<TListingPerformanceUpdateOffersRequestPayloads>()(
                        ["activeData", "crm", "listingPerformance", "pendingOrAcceptedOffers"],
                        (formList, data) => TForm.requestToFormListPreserveSpecific<TListingEnquiriesOffer2AndListingEnquiriesOfferForm2FormList["forms"][number]>(
                            formList,
                            data[1],
                            (current, incoming) => current.view.id === incoming.id,
                            (current, incoming) => ({
                                ...incoming,
                                status: current.status,
                            })
                        ),
                    ),
                    set<TListingPerformanceUpdateOffersRequestPayloads>()(
                        ["activeData", "crm", "listingPerformance", "withdrawnOrRejectedOffers"],
                        (formList, data) => TForm.requestToFormListPreserveSpecific<TListingEnquiriesOffer2AndListingEnquiriesOfferForm2FormList["forms"][number]>(
                            formList,
                            data[2],
                            (current, incoming) => current.view.id === incoming.id,
                            (current, incoming) => ({
                                ...incoming,
                                status: current.status,
                                validationErrors: current.validationErrors,
                            })
                        ),
                    ),
                ),
            ),
        );

export const reloadOffersAndViewingsActionable = (listingId: string, getState: TGetState, setState: TSetState): rxjs.Observable<actionable.TActionablePack<TListingPerformanceUpdateOffersAndViewingsRequestPayloads>> =>
         pipe(
            actionable.of<TListingPerformanceUpdateOffersAndViewingsRequestPayloads>(
                setState,
                getState,
                [
                    listingId,
                    FirstPartyFetchResponse.create2XX(OfferSuccessResponse2.newDefault()),
                    FirstPartyFetchResponse.create2XX(OfferSuccessResponse2.newDefault()),
                    FirstPartyFetchResponse.create2XX(ListingEnquiriesViewingSuccessResponse3.newDefault()),
                    FirstPartyFetchResponse.create2XX(ListingEnquiriesViewingSuccessResponse3.newDefault()),
                ],
            ),
            actionable.fromPayloadIndexForkJoin<0, 1 | 2 | 3 | 4, TListingPerformanceUpdateOffersAndViewingsRequestPayloads>(0, [
                [1, makeGetPendingOrAcceptedOffers],
                [2, makeGetNotPendingOrAcceptedOffers],
                [3, makeGetUpcomingViewingsRequest],
                [4, makeGetPastViewingsRequest],
            ]),
            actionable.tapPayloads<TListingPerformanceUpdateOffersAndViewingsRequestPayloads>(
                reduceDataToStateUpdate<TListingPerformanceUpdateOffersAndViewingsRequestPayloads>(setState)(
                    set<TListingPerformanceUpdateOffersAndViewingsRequestPayloads>()(
                        ["activeData", "crm", "listingPerformance", "pendingOrAcceptedOffers"],
                        (formList, data) => TForm.requestToFormListPreserveSpecific<TListingEnquiriesOffer2AndListingEnquiriesOfferForm2FormList["forms"][number]>(
                            formList,
                            data[1],
                            (current, incoming) => current.view.id === incoming.id,
                            (current, incoming) => ({
                                ...incoming,
                                status: current.status,
                            })
                        ),
                    ),
                    set<TListingPerformanceUpdateOffersAndViewingsRequestPayloads>()(
                        ["activeData", "crm", "listingPerformance", "withdrawnOrRejectedOffers"],
                        (formList, data) => TForm.requestToFormListPreserveSpecific<TListingEnquiriesOffer2AndListingEnquiriesOfferForm2FormList["forms"][number]>(
                            formList,
                            data[2],
                            (current, incoming) => current.view.id === incoming.id,
                            (current, incoming) => ({
                                ...incoming,
                                status: current.status,
                                validationErrors: current.validationErrors,
                            })
                        ),
                    ),
                    set<TListingPerformanceUpdateOffersAndViewingsRequestPayloads>()(
                        ["activeData", "crm", "listingPerformance", "upcomingViewings"],
                        (formList, data) => TForm.requestToFormList<TListingEnquiriesViewing2ReadOnlyFormList["forms"][number]>(
                            formList,
                            data[3],
                        ),
                    ),
                    set<TListingPerformanceUpdateOffersAndViewingsRequestPayloads>()(
                        ["activeData", "crm", "listingPerformance", "pastViewings"],
                        (formList, data) => TForm.requestToFormList<TListingEnquiriesViewing2ReadOnlyFormList["forms"][number]>(
                            formList,
                            data[4],
                        ),
                    ),
                ),
            ),
        );

export const makeGetListingRequest = (listingId: string): rxjs.Observable<FirstPartyFetchResponse.T<ListingSuccessResponse1.T>> =>
    rxjs.from(
        fetchWrapper.json<ListingSuccessResponse1.T>({
            requestParams: {
                url: `${env.REACT_APP_API_URL}/v1/listings/${listingId}`,
                method: "GET",
                body: option.none,
            },
            expectedTypeCodec: ListingSuccessResponse1.codec,
            defaultResponse: ListingSuccessResponse1.newDefault(),
        })(),
    )
        .pipe(rxjsOperators.tap(util.defaultCRMRequestErrorHandler));

export const makeGetInterestedEnquiriesRequest = (listingId: string): rxjs.Observable<FirstPartyFetchResponse.T<EnquirySuccessResponse3.T>> =>
    rxjs.from(
        fetchWrapper.json<EnquirySuccessResponse3.T>({
            requestParams: {
                url: `${env.REACT_APP_API_URL}/v1/listings/${listingId}/interested-enquiries`,
                method: "GET",
                body: option.none,
            },
            expectedTypeCodec: EnquirySuccessResponse3.codec,
            defaultResponse: EnquirySuccessResponse3.newDefault(),
        })(),
    )
        .pipe(rxjsOperators.tap(util.defaultCRMRequestErrorHandler));

export const makeGetPartyRequest = (partyId: string): rxjs.Observable<FirstPartyFetchResponse.T<PartyResponse1.T>> =>
    rxjs.from(
        fetchWrapper.json<PartyResponse1.T>({
            requestParams: {
                url: `${env.REACT_APP_API_URL}/v1/parties/${partyId}`,
                method: "GET",
                body: option.none,
            },
            expectedTypeCodec: PartyResponse1.codec,
            defaultResponse: PartyResponse1.newDefault(),
        })(),
    )
        .pipe(rxjsOperators.tap(util.defaultCRMRequestErrorHandler));

export const makeGetPendingOrAcceptedOffers = (listingId: string): rxjs.Observable<FirstPartyFetchResponse.T<OfferSuccessResponse2.T>> =>
    rxjs.from(
        fetchWrapper.json<OfferSuccessResponse2.T>({
            requestParams: {
                url: `${env.REACT_APP_API_URL}/v1/offers?filters=${encodeURIComponent(JSON.stringify(OfferFilter1.createForListingPendingOrAccepted(listingId)))}&limit=200&order_by=amount&order_direction=desc`,
                method: "GET",
                body: option.none,
            },
            expectedTypeCodec: OfferSuccessResponse2.codec,
            defaultResponse: OfferSuccessResponse2.newDefault(),
        })(),
    )
        .pipe(rxjsOperators.tap(util.defaultCRMRequestErrorHandler));

export const makeGetNotPendingOrAcceptedOffers = (listingId: string): rxjs.Observable<FirstPartyFetchResponse.T<OfferSuccessResponse2.T>> =>
    rxjs.from(
        fetchWrapper.json<OfferSuccessResponse2.T>({
            requestParams: {
                url: `${env.REACT_APP_API_URL}/v1/offers?filters=${encodeURIComponent(JSON.stringify(OfferFilter1.createForListingNotPendingOrAccepted(listingId)))}&limit=200`,
                method: "GET",
                body: option.none,
            },
            expectedTypeCodec: OfferSuccessResponse2.codec,
            defaultResponse: OfferSuccessResponse2.newDefault(),
        })(),
    )
        .pipe(rxjsOperators.tap(util.defaultCRMRequestErrorHandler));

export const makeGetUpcomingViewingsRequest = (listingId: string): rxjs.Observable<FirstPartyFetchResponse.T<ListingEnquiriesViewingSuccessResponse3.T>> =>
    rxjs.from(
        fetchWrapper.json<ListingEnquiriesViewingSuccessResponse3.T>({
            requestParams: {
                url: `${env.REACT_APP_API_URL}/v1/viewings?limit=200&filters=${encodeURIComponent(JSON.stringify(ViewingFilter1.createForListingIdOnlyFutureNotCancelled(listingId, DateTime.local().toUTC().toISO())))}`,
                method: "GET",
                body: option.none,
            },
            expectedTypeCodec: ListingEnquiriesViewingSuccessResponse3.codec,
            defaultResponse: ListingEnquiriesViewingSuccessResponse3.newDefault(),
        })(),
    )
        .pipe(rxjsOperators.tap(util.defaultCRMRequestErrorHandler));

export const makeGetPastViewingsRequest = (listingId: string): rxjs.Observable<FirstPartyFetchResponse.T<ListingEnquiriesViewingSuccessResponse3.T>> =>
    rxjs.from(
        fetchWrapper.json<ListingEnquiriesViewingSuccessResponse3.T>({
            requestParams: {
                url: `${env.REACT_APP_API_URL}/v1/viewings?limit=200&filters=${encodeURIComponent(JSON.stringify(ViewingFilter1.createForListingIdOnlyPastAndConfirmed(listingId, DateTime.local().toUTC().toISO())))}`,
                method: "GET",
                body: option.none,
            },
            expectedTypeCodec: ListingEnquiriesViewingSuccessResponse3.codec,
            defaultResponse: ListingEnquiriesViewingSuccessResponse3.newDefault(),
        })(),
    )
        .pipe(rxjsOperators.tap(util.defaultCRMRequestErrorHandler));
