import React from "react";
import { TUser6 } from "../../../../domain/codecs/User";
import { CRMCardOutsideLabelled } from "../CRMCardOutsideLabelled/CRMCardOutsideLabelled";
import { CRMSpacingColumn } from "../CRMSpacingColumn/CRMSpacingColumn";
import { Font } from "../Font/Font";
import { WeightBold } from "../WeightBold/WeightBold";
import { WeightRegular } from "../WeightRegular/WeightRegular";
import { array } from "fp-ts";
import { pipe } from "fp-ts/lib/function";
import { CRMFontSizes } from "../../models/CRMFontSizes";
import { CRMSpacer } from "../CRMSpacer/CRMSpacer";

export const CRMListingPageCasePeople = (props: {
    user: TUser6;
}): JSX.Element => {
    return (
        <CRMCardOutsideLabelled
            labelColor="highlights-positive-green-6"
            shadow={false}
        >
            
            <Font family="quicksand" size={CRMFontSizes.SMALL}>
                <WeightBold>
                    {props.user.homes_first_legal_name !== "" ? props.user.homes_first_legal_name : "[Legal First Name Missing]"} {props.user.homes_last_legal_name !== "" ? props.user.homes_last_legal_name : "[Legal Last Name Missing]"}
                </WeightBold>
            </Font>
            <CRMSpacer size="medium" />
            <CRMSpacingColumn spacing="tiny">
                {pipe(
                    props.user.phone_numbers,
                    array.mapWithIndex((ip, userPhoneNumber) =>
                        <Font key={ip} family="quicksand" size={CRMFontSizes.SMALL}>
                            <WeightRegular>
                                {userPhoneNumber.phone_number}
                            </WeightRegular>
                        </Font>
                    ),
                )}
                {props.user.email && <Font family="quicksand" size={CRMFontSizes.SMALL}>
                    <WeightRegular>
                        {props.user.email}
                    </WeightRegular>
                </Font>}
            </CRMSpacingColumn>
        </CRMCardOutsideLabelled>
    );
};
