import React from "react";
import { THexColor, TPercentageSize, TPixelSize, TPixelSizeShortHand } from "../../models/StringLiterals";
import { isAPlainFunction } from "../../functions/functions";

type TShapeProps = {
    width: TPixelSize | TPercentageSize;
    height: TPixelSize | TPercentageSize | "auto";
    borderRadius?: TPixelSize | TPercentageSize;
    color?: THexColor;
    margin?: TPixelSizeShortHand;
    maxWidth?: TPixelSize | TPercentageSize;
    onClick?: () => void;
};

export const Shape = (props: React.PropsWithChildren<TShapeProps>): JSX.Element => {

    return (
        <div 
            style={{ 
                height: props.height,
                width: props.width,
                maxWidth: props.maxWidth,
                borderRadius: props.borderRadius,
                backgroundColor: props.color || "transparent",
                margin: props.margin,
                cursor: isAPlainFunction(props.onClick) ? "pointer" : "inherit",
            }}
            onClick={props.onClick}
        >
            {props.children}
        </div>
    );
};
