
import * as rxjsOperators from "rxjs/operators";
import { option } from "fp-ts";
import * as fetchWrapper from "../../../wrappers/fetch";
import * as CaseResponse3 from "../../../../../domain/models/CaseResponse3";
import * as FirstPartyFetchResponse from "../../../../../domain/models/FirstPartyFetchResponse";
import * as util from "../../../util";
import { TActionObservable } from "../../applyActions";
import { TGetState } from "../../TGetState";
import { TSetState } from "../../TSetState";
import { TDispatch } from "../../TDispatch";
import { TActionsDefinitionsList } from "../TAction";

export const actions: TActionsDefinitionsList = [
    {
        type: "CRM_ADD_NEW_CASE_BUTTON_CLICKED" as const,
        run: (
            obs$: TActionObservable<"CRM_ADD_NEW_CASE_BUTTON_CLICKED", undefined>,
            getState: TGetState,
            setState: TSetState,
            dispatch: TDispatch,
        ): void => {
            obs$.pipe(
                rxjsOperators.mergeMap(() =>
                    fetchWrapper.json<CaseResponse3.T>({
                        requestParams: {
                            url: `${env.REACT_APP_API_URL}/v1/cases`,
                            method: "POST",
                            body: option.none,
                        },
                        expectedTypeCodec: CaseResponse3.codec,
                        defaultResponse: CaseResponse3.newDefault(),
                    })(),
                ),
                rxjsOperators.tap(util.defaultCRMRequestErrorHandler),
                rxjsOperators.tap((response) => {
                    if (response.tag === FirstPartyFetchResponse.constants.STATUS_2XX) {
                        /* Georgina W - ideally we would run this as a dispatch action as below, and that works fine when 
                            the CRM_ADD_NEW_CASE_BUTTON_CLICKED is run from the /cases page. However, with our current
                            state update setup, if a user clicks on it when they're already on a case view, we're in 
                            danger of not having all the blocks update to the newly created case and mixing
                            up the new case data with the previous case the user was on.

                            TL:DR this is hacky but necessary until a bigger solution for state updates of blocks is implemented
                           
                                dispatch(createChangeRouteAction(
                                    "VIEW_CRM_LEGAL_CASE",
                                    { caseId: response.response.data.id },
                                    {},
                                    [
                                        {
                                            type: "case_details",
                                            meta: {
                                                visible_sections: "clients|case_milestones"
                                            },
                                        },
                                        {
                                            type: "case_contracts",
                                            meta: {},
                                        },
                                        {
                                            type: "case_overview",
                                            meta: {},
                                        },
                                    ]
                                 ));
                        */
                        // NB opening a new case preset blocks used to include 'contracts' but it's too fiddley with state stuff to get the data to refresh to a new case instead of the old case
                        window.location.href = `/crm/legal/cases/${response.response.data.id}?visible_tab=&blocks=[-details*overview*roadblocks.]*.%2C--('type!'case_.'~meta!())%01.-*_`
                    }
                }),
                
            ).subscribe();
        },
    },
];
