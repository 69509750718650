import { array } from "fp-ts";
import { pipe } from "fp-ts/lib/function";
import React from "react";
import { ClientCompanyPartnershipUserType_displayString } from "../../../../../domain/codecs/ClientCompanyPartnership";
import { TClientCompanyPartnershipUserForm } from "../../../../../domain/codecs/form/ClientCompanyPartnershipForm";
import { requireExhaustive } from "../../../../../shared/src/util";
import { CRMColors } from "../../../models/CRMColors";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { MinWidth } from "../../BuildingBlocks/MinWidth";
import { Padding } from "../../BuildingBlocks/Padding";
import { SpacingColumn } from "../../BuildingBlocks/SpacingColumn";
import { SpacingRow } from "../../BuildingBlocks/SpacingRow";
import { TextColor } from "../../BuildingBlocks/TextColor";
import { CRMIcon } from "../../CRMIcon/CRMIcon";
import { CRMParagraph } from "../../Simple/CRMParagraph/CRMParagraph";
import { WeightBold } from "../../WeightBold/WeightBold";
import { WeightRegular } from "../../WeightRegular/WeightRegular";
import { WeightSemiBold } from "../../WeightSemiBold/WeightSemiBold";
import { CRMTextBadgePopout } from "../../Complex/CRMTextBadgePopout/CRMTextBadgePopout";

export const CRMCompanyPartnerPersonCard = (props: React.PropsWithChildren<{
    form: TClientCompanyPartnershipUserForm,
    onEdit: (form: TClientCompanyPartnershipUserForm) => void,
}>): JSX.Element => {
    const abbreviation =
        props.form.original.type === "director" ? "Di"
        : props.form.original.type === "signatory" ? "S"
        : props.form.original.type === "employee" ? "E"
        : props.form.original.type === "unknown" ? "?"
        : requireExhaustive(props.form.original.type);

    const badgeColour =
        props.form.original.type === "director" ? CRMColors.NEUTRAL_8
        : props.form.original.type === "signatory" ? CRMColors.PRIMARY_12
        : props.form.original.type === "employee" ? CRMColors.HIGHLIGHTS_CALMING_PINK_10
        : props.form.original.type === "unknown" ? CRMColors.NEUTRAL_10
        : requireExhaustive(props.form.original.type);

    const fullName =
        pipe(
            [
                props.form.original.first_name,
                props.form.original.last_name
            ],
            array.filter((name) => name !== ""),
            (a) => a.join(" "),
        );

    const userType =
        ClientCompanyPartnershipUserType_displayString(props.form.original.type);

    const email =
        props.form.original.email || "";

    const phoneNumbers =
        pipe(
            props.form.children.phone_number_forms,
            array.map(({edited}) => edited.phone_number),
            array.filter((phoneNumber) => phoneNumber !== null && phoneNumber !== ""),
        );

    const hasPhoneNumbers =
        phoneNumbers.length > 0;

    return <CRMTextBadgePopout
        badgeText={abbreviation}
        badgeColor={badgeColour}
        sideLabel={{
            firstRow: props.form.original.first_name || undefined,
            secondRow: props.form.original.last_name || undefined,
        }}
    >
        <Padding spacing={CRMSpacing.MEDIUM}>
            <MinWidth width="200px">
                <SpacingColumn spacing={CRMSpacing.MEDIUM}>
                    <SpacingRow
                        spacing={CRMSpacing.MEDIUM}
                        justifyContent="space-between"
                        alignItems="flex-start"
                    >
                        <SpacingColumn spacing={CRMSpacing.TINY}>
                            {/* NAME */}
                            {fullName &&
                                <CRMParagraph>
                                    <WeightBold>
                                        <TextColor color={CRMColors.NEUTRAL_0}>
                                            {fullName}
                                        </TextColor>
                                    </WeightBold>
                                </CRMParagraph>
                            }

                            {/* USER TYPE */}
                            <CRMParagraph>
                                <WeightRegular>
                                    <TextColor color={CRMColors.NEUTRAL_0}>
                                        {userType}
                                    </TextColor>
                                </WeightRegular>
                            </CRMParagraph>
                        </SpacingColumn>

                        {/* CONTROLS */}
                        <CRMIcon
                            iconName="pencil"
                            colour="neutral-ink"
                            size="tiny"
                            onClick={() => props.onEdit(props.form)}
                        />
                    </SpacingRow>

                    {(email || hasPhoneNumbers) &&
                        <SpacingColumn spacing={CRMSpacing.TINY}>
                            {/* EMAIL */}
                            {email &&
                                <CRMParagraph>
                                    <WeightSemiBold>
                                        <TextColor color={CRMColors.NEUTRAL_0}>
                                            {email}
                                        </TextColor>
                                    </WeightSemiBold>
                                </CRMParagraph>
                            }

                            {/* PHONE NUMBERS */}
                            {hasPhoneNumbers &&
                                phoneNumbers.map((phoneNumber, i) =>
                                    <CRMParagraph key={i}>
                                        <WeightSemiBold>
                                            <TextColor color={CRMColors.NEUTRAL_0}>
                                                {phoneNumber}
                                            </TextColor>
                                        </WeightSemiBold>
                                    </CRMParagraph>
                                )
                            }
                        </SpacingColumn>
                    }
                </SpacingColumn>
            </MinWidth>
        </Padding>
    </CRMTextBadgePopout>;
}
