import React from "react";
import { identity } from "fp-ts";
import { TCaseEnquiry1 } from "../../../../../../domain/codecs/CaseEnquiry";
import { CRMFontSizes } from "../../../../models/CRMFontSizes";
import { CRMSpacing } from "../../../../models/CRMSpacing";
import { CRMColors } from "../../../../models/CRMColors";
import { InlineFlex } from "../../../BuildingBlocks/InlineFlex";
import { SpacingColumn } from "../../../BuildingBlocks/SpacingColumn";
import { SpacingRow } from "../../../BuildingBlocks/SpacingRow";
import { TextHightlight } from "../../../BuildingBlocks/TextHightlight";
import { CRMIcon } from "../../../CRMIcon/CRMIcon";
import { CRMParagraph } from "../../../Simple/CRMParagraph/CRMParagraph";
import { WeightBold } from "../../../WeightBold/WeightBold";
import { WeightMedium } from "../../../WeightMedium/WeightMedium";
import { WeightSemiBold } from "../../../WeightSemiBold/WeightSemiBold";
import { TCasesDocumentType, toDisplayString as CaseDocumentTypeToCopyText } from "../../../../../../domain/models/CaseDocumentType1";
import { toDisplayString as CaseEnquiryStatusTypeToDisplayString } from "../../../../../../domain/models/CaseEnquiryStatusType";

type TEnquiryAttachmentProps = {
    tagPrefix?: string;
    enquiry: TCaseEnquiry1;
    documentReferenceFormatting?: (reference: string) => JSX.Element | string;
    documentTypeFormatting?: (typeAsCopyText: string, type: TCasesDocumentType) => JSX.Element | string;
    messageFormatting?: (message: string) => JSX.Element | string; 
};

type TTextFormatFunction = (value: string, type?: TCasesDocumentType) => JSX.Element | string;

export const CRMEnquiryAttachment = (props: React.PropsWithChildren<TEnquiryAttachmentProps>): JSX.Element => {

    const formatTextWith = (functionName: "documentReferenceFormatting" | "documentTypeFormatting" | "messageFormatting"): TTextFormatFunction => {
        if (typeof props[functionName] === "function") {
            return props[functionName] as TTextFormatFunction;
        }
        return identity.flatten;
    }

    const formatDocumentReference = formatTextWith("documentReferenceFormatting");
    const formatDocumentType = formatTextWith("documentTypeFormatting");
    const formatMessage = formatTextWith("documentTypeFormatting");

    return (
        <SpacingColumn spacing={CRMSpacing.MEDIUM}>

            {/* TAG */}
            <InlineFlex>
                <TextHightlight color={CRMColors.HIGHLIGHTS_CALMING_PINK_8}>
                    <CRMParagraph>
                        <WeightBold>
                            {props.tagPrefix || ""} Enquiry {!!props.enquiry.reference_id ? `(${props.enquiry.reference_id})` : ""} - {CaseEnquiryStatusTypeToDisplayString(props.enquiry.status)}
                        </WeightBold>
                    </CRMParagraph>
                </TextHightlight>
            </InlineFlex>

            {/* RAISED FOR DOCUMENTS LIST */}
            <SpacingColumn spacing={CRMSpacing.SMALL}>
                {props.enquiry.documents?.map((document) => (
                    <SpacingRow
                        key={document.id} 
                        alignItems="flex-start"
                    >
                        <CRMIcon
                            iconName="dot"
                            size="small"
                            colour="neutral-ink"
                        />
                        <SpacingColumn spacing={CRMSpacing.TINY}>
                            {/* RAISED AGAINST DOCUMENT */}
                            <CRMParagraph>
                                <WeightSemiBold>
                                    {formatDocumentType(CaseDocumentTypeToCopyText(document.type), document.type)}
                                </WeightSemiBold>
                            </CRMParagraph>
                            {/* RAISED FOR SECTION */}
                            {!!document.reference &&
                                <CRMParagraph>
                                    <WeightMedium>
                                        {formatDocumentReference(document.reference)}
                                    </WeightMedium>
                                </CRMParagraph>
                            }
                        </SpacingColumn> 
                    </SpacingRow>
                ))}
            </SpacingColumn>
            
            {/* ENQUIRY TEXT */}
            <CRMParagraph fontSize={CRMFontSizes.SMALL}>
                <WeightMedium>
                    {formatMessage(props.enquiry.message)}
                </WeightMedium>
            </CRMParagraph>
        </SpacingColumn>
    );
};
