import React from "react";
import Calendar from "react-calendar";
import { DateTime } from "luxon";

type TCRMReactCalendarWrapperProps = {
    value?: Date;
    onChange: (date: string) => void;
};

export const CRMReactCalendarWrapper = (props: TCRMReactCalendarWrapperProps): JSX.Element => {

    return (
        <div className={`crm-react-calendar-wrapper`}>
            <Calendar
                value={props.value ? props.value : new Date()}
                onChange={(value: Date) => { 
                    props.onChange(
                        DateTime.fromJSDate(value)
                            // do not add .setZone("Europe/London") since it literarly
                            // rewinds it a day back by subtracting hours from 00:00
                            // if we are setting the hour the timezone does not matter anyways.
                            //
                            // Set to 7am so that its set to the start of a working day
                            .set({hour: 7, minute: 0, second: 0, millisecond: 0})
                            .toString())
                }}
            />
        </div>
    );
};
