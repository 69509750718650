import React, { useState } from "react";
import { TFormStatus } from "./../../../../shared/src/codecs/codec";
import CRMInputGeneralComponent from "../CRMInputs/CRMInputGeneralComponent/CRMInputGeneralComponent";
import CRMInputLabelAndErrorWrapComponent from "../CRMInputLabelAndErrorWrapComponent/CRMInputLabelAndErrorWrapComponent";
import { CRMInputPhoneNumbers } from "../CRMInputPhoneNumbers/CRMInputPhoneNumbers";
import { CRMInputCheckboxComponent } from "../CRMInputCheckboxComponent/CRMInputCheckboxComponent";
import { SpacingColumn } from "../BuildingBlocks/SpacingColumn";
import { TCaseMemberChildrenForm, TCaseMemberCreateIdCheckForm, TCaseMemberForm, TCaseMemberIdCheckForm, TCaseMemberSuggestedSimilarUserForm, TCaseSendInstructedEmailForm } from "../../../../domain/codecs/form/CaseMemberForm";
import { doesErrorKeyExist } from "../../../../shared/src/codecs/errors";
import { editFormEditableValue } from "../../../../shared/src/codecs/types/formEditable";
import { TPhoneNumberForm } from "../../../../domain/codecs/form/PhoneNumberForm";
import { CRMDropdownComponent } from "../CRMDropdownComponent/CRMDropdownComponent";
import { enumToIDropdownOptions } from "../../functions/enumToIDropdownOptions";
import { Country4, Country4ToDisplayName } from "../../../../domain/codecs/Country";
import { UserCaseBankruptcySearch, UserCaseIsCompanyDirector} from "../../../../domain/codecs/UserCase";
import { CanAccessPortal, RequiresIdCheck, UserCaseUserType, UserCaseUserType_UserCaseUserTypeDisplayName } from "../../../../domain/codecs/UserCaseUserType";
import { TUser20, UserLegalCorrespondenceAddress1 } from "../../../../domain/codecs/User";
import { CRMHorizontalSeperatorLine } from "../CRMHorizontalSeperatorLine/CRMHorizontalSeperatorLine";
import { CRMTitleSubSection } from "../CRMTitleSubSection/CRMTitleSubSection";
import { CRMCodecEditForm } from "../CRMCodecEditForm/CRMCodecEditForm";
import { SpacingRow } from "../BuildingBlocks/SpacingRow";
import { CRMSpacing } from "../../models/CRMSpacing";
import { CRMIconBadge } from "../CRMIconBadge/CRMIconBadge";
import { CRMFormButton } from "../CRMFormButton/CRMFormButton";
import { CRMButtonTertiary } from "../CRMButtonTertiary/CRMButtonTertiary";
import { CRMButtonQuaternary } from "../CRMButtonQuaternary/CRMButtonQuaternary";
import { DateTime } from "luxon";
import { TCaseEditableFull } from "../../../../domain/codecs/Cases";
import { CRMNoticeBoxComponent } from "../CRMNoticeBoxComponent/CRMNoticeBoxComponent";
import { CRMPadding } from "../Simple/CRMPadding/CRMPadding";
import { CRMTitleForCard } from "../CRMTitleForCard/CRMTitleForCard";
import { CRMButtonSecondary } from "../CRMButtonSecondary/CRMButtonSecondary";
import { CRMAutosaveIndicatorWrap } from "../CRM/CRMAutosaveIndicatorWrap/CRMAutosaveIndicatorWrap";
import { CRMGenericEditModalWithText } from "../CRMGenericEditModalWithText/CRMGenericEditModalWithText";
import { CRMInputCalendarComponent } from "../CRMInputCalendarComponent/CRMInputCalendarComponent";
import { CRMButtonIcon } from "../CRMButtonIcon/CRMButtonIcon";
import { CRMCaseMemberIdChecksComponent } from "../CRMCaseMemberIdChecksComponent/CRMCaseMemberIdChecksComponent";
import { CRMDeferButtonComponent } from "../CRMDeferButtonComponent/CRMDeferButtonComponent";
import { CRMSpacer } from "../CRMSpacer/CRMSpacer";
import { pipe } from "fp-ts/lib/function";
import { array, ord } from "fp-ts";
import { CRMClientSuggestion } from "../CRMClientSuggestion/CRMClientSuggestion";
import { CRMZIndex } from "../../models/CRMZIndex";
import { Ord } from "fp-ts/lib/boolean";
import { Position } from "../BuildingBlocks/Position";

type TCRMHomeEnquiryPartyMemberForm = {
    case: TCaseEditableFull;
    caseIsInstructed: boolean;
    member: TCaseMemberForm;
    showLegalBuyerField?: boolean;
    isNotAllowedToReceiveInstructEmailDueToDualRepConsent: boolean;
    onChange: (value: TCaseMemberForm) => void;
    onChildChange: (value: TCaseMemberChildrenForm) => void;
    onChildIdChecksChange: (value: TCaseMemberIdCheckForm) => void;
    onNewIdCheck: (value: TCaseMemberCreateIdCheckForm) => void;
    onSendInstructedEmailClick: (value: TCaseSendInstructedEmailForm) => void;
    createPhoneNumberStatus: TFormStatus;
    onAddNewPhoneNumber: () => void;
    onRemovePhoneNumber: (form: TPhoneNumberForm) => void;
    onSwapMember: (form: TCaseMemberSuggestedSimilarUserForm) => void;
};

export const CRMPartyMemberForm = (props: TCRMHomeEnquiryPartyMemberForm): JSX.Element => {
    const [inputShowingSuggestions, setInputShowingSuggestions] = useState<"first_name" | "last_name" | "email" | "none">("none");
    const [suggestionInputInFocus, setSuggestionInputInFocus] = useState<"first_name" | "last_name" | "email" | "none">("none");

    const getLastSentDisplayString = (lastSent: string): string => {
        const diff = DateTime.utc().diff(DateTime.fromISO(lastSent));

        if (diff.shiftTo("minute").minutes < 59) {
            return "moments";
        }

        if (Math.round(diff.shiftTo("hours").hours) < 24) {
            return `${Math.round(diff.shiftTo("hours").hours)} hours`;
        }

        if (Math.round(diff.shiftTo("days").days) < 7) {
            return `${Math.round(diff.shiftTo("days").days)} days`;
        }

        return `${Math.round(diff.shiftTo("week").weeks)} weeks`;
    }

    return (
        <SpacingColumn spacing={CRMSpacing.MEDIUM}>
            {/* CASE USER TYPE */}
           
            <CRMInputLabelAndErrorWrapComponent label="Type">
                <CRMDropdownComponent
                    options={enumToIDropdownOptions(
                        UserCaseUserType.values,
                        UserCaseUserType_UserCaseUserTypeDisplayName,
                    )}
                    displayError={false}
                    value={props.member.children.edited.user_type}
                    onChange={(user_type) => props.onChildChange({
                        ...props.member.children,
                        edited: {
                            ...props.member.children.edited,
                            user_type,
                        },
                    })}
                />
            </CRMInputLabelAndErrorWrapComponent>

            <div
                onMouseLeave={() => setTimeout(() => 
                    {setInputShowingSuggestions("none")}
                    ,1000
                )}
                onMouseEnter={() => {
                    if (inputShowingSuggestions === "none" && suggestionInputInFocus !== "none") {
                        setInputShowingSuggestions(suggestionInputInFocus);
                    }
                }}
            >
                <SpacingColumn spacing={CRMSpacing.MEDIUM}>
                    {/* FIRST NAME */}
                    <Position position="relative">
                        <CRMInputLabelAndErrorWrapComponent label="First name">
                            <CRMInputGeneralComponent
                                inputType="text"
                                displayError={doesErrorKeyExist("edited.first_name", props.member.validationErrors)}
                                placeholder="John"
                                value={props.member.edited.first_name}
                                onChange={(value) => props.onChange(
                                    editFormEditableValue(props.member, "first_name", value)
                                )}
                                onFocus={() => {
                                    setInputShowingSuggestions("first_name");
                                    setSuggestionInputInFocus("first_name");
                                }}
                                onBlur={() => {
                                    setSuggestionInputInFocus("none");
                                }}
                            />
                        </CRMInputLabelAndErrorWrapComponent>
                        {inputShowingSuggestions === "first_name" && <CRMPartyMemberFormSuggestions
                            member={props.member}
                            onSwapMember={props.onSwapMember}
                        />}
                    </Position>

                    {/* LAST NAME */}
                    <Position position="relative">
                        <CRMInputLabelAndErrorWrapComponent label="Last name">
                            <CRMInputGeneralComponent
                                inputType="text"
                                displayError={doesErrorKeyExist("edited.last_name", props.member.validationErrors)}
                                placeholder="Doe"
                                value={props.member.edited.last_name}
                                onChange={(value) => props.onChange(
                                    editFormEditableValue(props.member, "last_name", value)
                                )}
                                onFocus={() => {
                                    setInputShowingSuggestions("last_name");
                                    setSuggestionInputInFocus("last_name");
                                }}
                                onBlur={() => {
                                    setSuggestionInputInFocus("none");
                                }}
                            />
                        </CRMInputLabelAndErrorWrapComponent>
                        {inputShowingSuggestions === "last_name" && <CRMPartyMemberFormSuggestions
                            member={props.member}
                            onSwapMember={props.onSwapMember}
                        />}
                    </Position>

                    {/* EMAIL */}
                    <Position position="relative">
                        <CRMInputLabelAndErrorWrapComponent label="Email">
                            <SpacingRow
                                spacing={CRMSpacing.MEDIUM}
                                childSize="1fr 32px"
                            >
                                <CRMInputGeneralComponent
                                    inputType="email"
                                    displayError={doesErrorKeyExist("edited.email", props.member.validationErrors)}
                                    placeholder="john@doe.com"
                                    value={props.member.edited.email || ""}
                                    onChange={(value) => props.onChange({
                                        ...props.member,
                                        status: "requiresSubmission",
                                        edited: {
                                            ...props.member.edited,
                                            email: value || null,
                                            email_is_verified: false,
                                        }
                                    })}
                                    onFocus={() => {
                                        setInputShowingSuggestions("email");
                                        setSuggestionInputInFocus("email");
                                    }}
                                    onBlur={() => {
                                        setSuggestionInputInFocus("none");
                                    }}
                                />
                                
                                {/* IF EMAIL VERIFIED */}
                                {props.member.edited.email_is_verified &&
                                    <CRMIconBadge
                                        icon="email-secure"
                                        iconColour="neutral-ink"
                                        iconTitle="This email has been verified by the user"
                                        badgeColour="highlights-positive-green-10"
                                        size="small"
                                    />
                                }

                                {/* IF PENDING VERIFICATION */}
                                {!props.member.edited.email_is_verified &&
                                    <CRMIconBadge
                                        icon="email-pending"
                                        iconColour="neutral-ink"
                                        iconTitle="This email has not been verified by the user yet."
                                        badgeColour="highlights-pertinent-yellow-6"
                                        size="small"
                                    />
                                }
                            </SpacingRow>
                        </CRMInputLabelAndErrorWrapComponent>
                        {inputShowingSuggestions === "email" && <CRMPartyMemberFormSuggestions
                            member={props.member}
                            onSwapMember={props.onSwapMember}
                        />}
                    </Position>

                    {props.showLegalBuyerField &&
                        <CRMInputCheckboxComponent
                            label="They are the legal buyer"
                            onClick={() => props.onChange(
                                editFormEditableValue(props.member, "is_legal_buyer", !props.member.edited.is_legal_buyer)
                            )}
                            checked={props.member.edited.is_legal_buyer || false}
                        />
                    }

                    {/* PHONE NUMBERS */}
                    <CRMInputLabelAndErrorWrapComponent label="Phone number">
                        <CRMInputPhoneNumbers
                            placeholder="Add phone number"
                            phoneNumbers={props.member.edited.phone_numbers}
                            createPhoneNumberStatus={props.createPhoneNumberStatus}
                            showVerificationStatus={true}
                            onAddNew={props.onAddNewPhoneNumber}
                            onRemove={props.onRemovePhoneNumber}
                            onChange={(phoneNumbers) =>
                                props.onChange(
                                    editFormEditableValue(
                                        props.member,
                                        "phone_numbers",
                                        phoneNumbers,
                                        "untouched"
                                    )
                                )
                            }
                        />
                    </CRMInputLabelAndErrorWrapComponent>
                </SpacingColumn>
            </div>

            {/* SEND/RESEND INSTRUCTED EMAIL */}
            {
                props.caseIsInstructed
                && props.member.edited.email
                && CanAccessPortal.includes(props.member.children.edited.user_type)
                && <>
                    {props.isNotAllowedToReceiveInstructEmailDueToDualRepConsent &&
                        <CRMNoticeBoxComponent>
                            You can't send the instruct email to this person yet as the other side must all consent to Dual Rep first before our customers complete onboarding.
                        </CRMNoticeBoxComponent>
                    }

                    <CRMFormButton
                        formStatus={
                            props.member.children.children.send_instructed_email.status === "unauthorised"
                            || props.member.children.children.send_instructed_email.status === "notFound"
                            || props.member.children.children.send_instructed_email.status === "twoFactorRequired" ? "failure"
                            : props.member.children.children.send_instructed_email.status === "untouched" ? "requiresSubmission"
                            : props.member.children.children.send_instructed_email.status}
                        ButtonElement={
                            props.member.children.children.instructed_email_last_sent
                                ? CRMButtonQuaternary
                                : CRMButtonTertiary
                        }
                        label={
                            props.member.children.children.instructed_email_last_sent
                                ? `Resend instructed email (sent ${getLastSentDisplayString(props.member.children.children.instructed_email_last_sent)} ago)`
                                : "Send instructed email"
                        }
                        icon="email-outbound"
                        onClick={() => props.onSendInstructedEmailClick(props.member.children.children.send_instructed_email)}
                        disabled={props.isNotAllowedToReceiveInstructEmailDueToDualRepConsent}
                    />
                </>}

            {RequiresIdCheck.includes(props.member.children.edited.user_type) && <>
                <CRMHorizontalSeperatorLine />
                <CRMTitleSubSection>
                    ID Checks
                </CRMTitleSubSection>

                <CRMCaseMemberIdChecksComponent
                    idChecks={props.member.children.children.id_checks}
                    newIdCheckForm={props.member.children.children.create_id_check}
                    onChange={props.onChildIdChecksChange}
                    onNew={props.onNewIdCheck}
                />

                <CRMInputLabelAndErrorWrapComponent label="Defer ID check jobs">
                    <CRMDeferButtonComponent
                        value={props.member.children.edited.id_check_deferred}
                        onChange={(id_check_deferred) => props.onChildChange({
                            ...props.member.children,
                            edited: {
                                ...props.member.children.edited,
                                id_check_deferred,
                            }
                        })}
                        displayError={false}
                    />
                </CRMInputLabelAndErrorWrapComponent>
            </>}

            {props.member.children.edited.user_type === "client" && props.case.is_transacting_as_company === "yes" && <>
                <CRMHorizontalSeperatorLine />
                <CRMTitleSubSection>
                    Company position
                </CRMTitleSubSection>

                <CRMCodecEditForm
                    codec={UserCaseIsCompanyDirector}
                    model={props.member.children.edited}
                    validationErrors={[]}
                    onChange={(p) => props.onChildChange({
                        ...props.member.children,
                        edited: {
                            ...props.member.children.edited,
                            ...p,
                        },
                    })}
                />
            </>}

            {(props.member.children.edited.user_type !== "unknown"
                && props.member.children.edited.user_type !== "authorised_third_party"
                && props.member.children.edited.user_type !== "introducer") && <>
                <CRMHorizontalSeperatorLine />
                <CRMTitleSubSection>
                    Legal name, address & date of birth
                </CRMTitleSubSection>

                {/* LEGAL FIRST NAME */}
                <CRMInputLabelAndErrorWrapComponent label="Legal first name">
                    <CRMInputGeneralComponent
                        inputType="text"
                        displayError={doesErrorKeyExist("edited.legal_first_legal_name", props.member.validationErrors)}
                        placeholder=""
                        value={props.member.edited.legal_first_legal_name}
                        onChange={(value) => props.onChange(
                            editFormEditableValue(props.member, "legal_first_legal_name", value)
                        )}
                    />
                </CRMInputLabelAndErrorWrapComponent>

                {/* LEGAL MIDDLE NAME */}
                <CRMInputLabelAndErrorWrapComponent label="Legal middle name">
                    <CRMInputGeneralComponent
                        inputType="text"
                        displayError={doesErrorKeyExist("edited.legal_middle_legal_name", props.member.validationErrors)}
                        placeholder=""
                        value={props.member.edited.legal_middle_legal_name}
                        onChange={(value) => props.onChange(
                            editFormEditableValue(props.member, "legal_middle_legal_name", value)
                        )}
                    />
                </CRMInputLabelAndErrorWrapComponent>

                {/* LEGAL LAST NAME */}
                <CRMInputLabelAndErrorWrapComponent label="Legal last name">
                    <CRMInputGeneralComponent
                        inputType="text"
                        displayError={doesErrorKeyExist("edited.legal_last_legal_name", props.member.validationErrors)}
                        placeholder=""
                        value={props.member.edited.legal_last_legal_name}
                        onChange={(value) => props.onChange(
                            editFormEditableValue(props.member, "legal_last_legal_name", value)
                        )}
                    />
                </CRMInputLabelAndErrorWrapComponent>

                {/* LEGAL DATE OF BIRTH */}
                <CRMInputLabelAndErrorWrapComponent label="Legal date of birth">
                    <SpacingRow spacing={CRMSpacing.TINY} childSize="1fr auto">
                        <CRMInputCalendarComponent
                            dateType="date"
                            value={props.member.edited.legal_date_of_birth || ""}
                            onChange={(value) => props.onChange(
                                editFormEditableValue(props.member, "legal_date_of_birth", value || null)
                            )}
                            onPressEnterKey={() => null}
                            displayError={false}
                        />
                        <CRMButtonIcon
                            variant="tertiary"
                            icon="backspace"
                            disabledColour="neutral-4"
                            disabled={props.member.edited.legal_date_of_birth ? false : true}
                            onClick={() => props.onChange(
                                editFormEditableValue(props.member, "legal_date_of_birth", null)
                            )}
                        />
                    </SpacingRow>
                </CRMInputLabelAndErrorWrapComponent>

                {/* NATIONAL INSURANCE NUMBER */}
                <CRMInputLabelAndErrorWrapComponent label="National insurance number">
                    <CRMInputGeneralComponent
                        inputType="text"
                        displayError={doesErrorKeyExist("edited.national_insurance_number", props.member.validationErrors)}
                        placeholder=""
                        value={props.member.edited.national_insurance_number}
                        onChange={(value) => props.onChange(
                            editFormEditableValue(props.member, "national_insurance_number", value)
                        )}
                    />
                </CRMInputLabelAndErrorWrapComponent>

                {/* LEGAL CORRESPONDENCE ADDRESS */}
                <CRMInputLabelAndErrorWrapComponent label="Legal Correspondence Address">
                    <CRMGenericEditModalWithText
                        modalSize="large"
                        text={(() => {
                            const address = [
                                [
                                    props.member.edited.legal_correspondence_sub_building_name,
                                    props.member.edited.legal_correspondence_sub_building_number,
                                    props.member.edited.legal_correspondence_building_name,
                                    props.member.edited.legal_correspondence_building_number,
                                    props.member.edited.legal_correspondence_street_name
                                ].filter((s) => s !== "").join(" "),
                                props.member.edited.legal_correspondence_locality,
                                props.member.edited.legal_correspondence_district,
                                props.member.edited.legal_correspondence_city_town,
                                props.member.edited.legal_correspondence_postcode,
                                props.member.edited.legal_correspondence_county,
                                props.member.edited.legal_correspondence_country === "unknown" ?
                                "" : Country4ToDisplayName(props.member.edited.legal_correspondence_country)
                            ].filter((s) => s !== "" && s !== null).join(", ")

                            return address === "" ? "No Address" : address;
                        })()}
                        body={
                            (setIsOpen) => (
                                <CRMPadding size="large">
                                    <CRMAutosaveIndicatorWrap
                                        status={props.member.status}
                                    >
                                        <CRMTitleForCard>LEGAL CORRESPONDENCE ADDRESS</CRMTitleForCard>
                                        <CRMSpacer size="large" />

                                        <SpacingColumn spacing={CRMSpacing.MEDIUM}>
                                            <CRMCodecEditForm
                                                codec={UserLegalCorrespondenceAddress1}
                                                model={props.member.edited}
                                                validationErrors={props.member.validationErrors}
                                                onChange={(p) => props.onChange({
                                                    ...props.member,
                                                    status: "requiresSubmission",
                                                    edited: {
                                                        ...props.member.edited,
                                                        ...p,
                                                    },
                                                })}
                                                columns={2}
                                            />

                                            <SpacingRow
                                                spacing={CRMSpacing.MEDIUM}
                                                alignItems="flex-start"
                                                childSize="1fr 1fr"
                                            >
                                                <CRMInputLabelAndErrorWrapComponent label="Legal correspondence country">
                                                    <CRMDropdownComponent
                                                        value={props.member.edited.legal_correspondence_country}
                                                        options={enumToIDropdownOptions(
                                                            Country4.values,
                                                            Country4ToDisplayName,
                                                        )}
                                                        displayError={false}
                                                        onChange={(legal_correspondence_country) => props.onChange({
                                                            ...props.member,
                                                            status: "requiresSubmission",
                                                            edited: {
                                                                ...props.member.edited,
                                                                legal_correspondence_country,
                                                            },
                                                        })}
                                                    />
                                                </CRMInputLabelAndErrorWrapComponent>
                                                <div></div>
                                            </SpacingRow>
                                        </SpacingColumn>
                                        <CRMSpacer size="large" />

                                        {/* BUTTONS */}
                                        <SpacingRow justifyContent="end">
                                            <CRMButtonSecondary
                                                label="Close"
                                                onClick={() => setIsOpen(false)}
                                            />
                                        </SpacingRow>
                                    </CRMAutosaveIndicatorWrap>
                                </CRMPadding>
                            )
                        }
                    />
                </CRMInputLabelAndErrorWrapComponent>
            </>}

            {props.member.children.edited.bankruptcy_search_required && props.caseIsInstructed && <>
                <CRMHorizontalSeperatorLine />
                <CRMTitleSubSection>
                    Bankruptcy search
                </CRMTitleSubSection>

                {props.member.children.edited.bankruptcy_search_has_expired_and_requires_renewal &&
                    <CRMNoticeBoxComponent>
                        The bankruptcy search has expired and needs to be renewed.
                    </CRMNoticeBoxComponent>
                }

                <CRMCodecEditForm
                    codec={UserCaseBankruptcySearch}
                    model={props.member.children.edited}
                    validationErrors={props.member.children.validationErrors}
                    onChange={(p) => props.onChildChange({
                        ...props.member.children,
                        edited: {
                            ...props.member.children.edited,
                            ...p,
                        },
                    })}
                />
            </>
            }
        </SpacingColumn>
    );
};

const CRMPartyMemberFormSuggestions = (props: {
    member: TCaseMemberForm,
    onSwapMember: (form: TCaseMemberSuggestedSimilarUserForm) => void
}): JSX.Element =>
    props.member.children.children.suggested_similar_users.length > 0
        ? <div
            style={{
                position: "absolute",
                top: "60px",
                zIndex: CRMZIndex.THIRD_Z,
                width: "300px",
            }}
        >
            <SpacingColumn spacing={CRMSpacing.TINY}>
                {pipe(
                    props.member.children.children.suggested_similar_users,
                    // sorts so that users with null email address are suggested last
                    array.sortBy([ord.contramap((suggestedSimilarUser: TCaseMemberSuggestedSimilarUserForm) => typeof suggestedSimilarUser.children.email === "string")(Ord)]),
                    array.reverse,
                    array.mapWithIndex((i, form) =>
                        <CRMClientSuggestion
                            context="case"
                            key={i}
                            form={form}
                            onUse={(f) => props.onSwapMember(f as TCaseMemberSuggestedSimilarUserForm)}
                        />
                    ),
                )}
            </SpacingColumn>
        </div>
        : <></>;