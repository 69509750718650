import React from "react";

type VerticalLineProps = {
    color: string;
    height: string;
    width?: string;
};

export const VerticalLine = (props: VerticalLineProps): JSX.Element => {

    return (
        <div 
            style={{
                height: props.height,
                width: props.width || "1px",
                backgroundColor: props.color
            }}
        />
    );
};
