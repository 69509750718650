import React from "react";
import { useSplitInput } from "../../../../hooks/useSplitInput";
import { FrontSpacing } from "../../../../models/FrontSpacing";
import { SpacingRow } from "../../../BuildingBlocks/SpacingRow";
import FrontInputGeneralComponent from "../FrontInputGeneralComponent/FrontInputGeneralComponent";

type TFrontAuthCodeInputProps = {
    value: string;
    onChange: (value: string) => void;
    displayError?: boolean;
};

export const FrontAuthCodeInput = (props: React.PropsWithChildren<TFrontAuthCodeInputProps>): JSX.Element => {

    const { partStates } = useSplitInput({
        value: props.value,
        chunks: [1, 1, 1, 1, 1, 1],
        onChange: props.onChange
    });

    return (
        <SpacingRow
            spacing={FrontSpacing.SMALL_2}
            childSize="40px 40px 40px 40px 40px 40px"
        >
            {partStates.map(({ value, setValue, ref, maxLength }, index) => (
                <FrontInputGeneralComponent
                    key={index}
                    inputRef={ref}
                    placeholder=""
                    inputType="text"
                    maxLength={maxLength}
                    value={value}
                    textAlignment="center"
                    onChange={setValue}
                    displayError={!!props.displayError}
                    autoFocus={index === 0}
                />
            ))}
        </SpacingRow>
    );
};
