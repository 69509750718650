import React from "react";
import { useOpenClose } from "../../hooks/UseOpenClose";
import { CRMDropdownFace } from "../Simple/CRMDropdownFace/CRMDropdownFace";

export const CRMDropdownClickableForOverlayComponent = (props: {
    label: string;
    children: JSX.Element;
}): JSX.Element => {

    const {
        ref,
        isOpen,
        setIsOpen,
     } = useOpenClose();

    return (
        <div ref={ref}>
            {/* FACE */}
            <CRMDropdownFace
                isOpen={isOpen}
                toggleOpen={() => setIsOpen(!isOpen)}
            >
                {props.label}
            </CRMDropdownFace>

            {/* OVERLAY */}
            {isOpen &&
                <div className="crm-dropdown-clickable-for-overlay__children">
                    {props.children}
                </div>
            }
        </div>
    );
};
