import React from "react";
import { TIntroducerExistingAgreementForm, TIntroducerForm, TIntroducerUploadAgreementForm } from "../../../../../domain/codecs/form/IntroducerForm";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { Background } from "../../BuildingBlocks/Background";
import { Relative } from "../../BuildingBlocks/Relative";
import { SpacingRow } from "../../BuildingBlocks/SpacingRow";
import { CRMBottomControlBar } from "../../CRMBottomControlBar/CRMBottomControlBar";
import { CRMButtonPrimary } from "../../CRMButtonPrimary/CRMButtonPrimary";
import { CRMFormButton } from "../../CRMFormButton/CRMFormButton";
import { CRMPopoutHeader } from "../../Simple/CRMPopoutHeader/CRMPopoutHeader";
import { CRMTidyPopout } from "../../Simple/CRMTidyPopout/CRMTidyPopout";
import { CRMIntroducerForm } from "../CRMIntroducerForm/CRMIntroducerForm";

type TIntroducerEditPopoutProps = {
    form: TIntroducerForm;
    onDeleteUploadAgreement: (form: TIntroducerExistingAgreementForm) => void;
    onUploadAgreement: (form: TIntroducerUploadAgreementForm) => void;
    onClose: () => void;
    onChange: (form: TIntroducerForm) => void;
    onSave: (form: TIntroducerForm) => void;
};

export const CRMIntroducerEditPopout = (props: React.PropsWithChildren<TIntroducerEditPopoutProps>): JSX.Element => {

    return (
        <CRMTidyPopout width="100%" height="745px">
            <Relative>
                <CRMPopoutHeader
                    icon="arrow-left"
                    onClick={props.onClose}
                >
                    Editing {props.form.edited.name || "Introducer"}
                </CRMPopoutHeader>

                <Background padding={CRMSpacing.LARGE}>
                    <CRMIntroducerForm
                        form={props.form}
                        onDeleteUploadAgreement={props.onDeleteUploadAgreement}
                        onUploadAgreement={props.onUploadAgreement}
                        onChange={props.onChange}
                    />
                </Background>

                <CRMBottomControlBar edge="border">
                    <Background
                        width="100%" 
                        padding={`${CRMSpacing.TINY} ${CRMSpacing.MEDIUM}`}
                    >
                        <SpacingRow justifyContent="end">
                            <CRMFormButton
                                formStatus={props.form.status}
                                ButtonElement={CRMButtonPrimary}
                                label="Save"
                                onClick={() => props.onSave(props.form)}
                            />
                        </SpacingRow>
                    </Background>
                </CRMBottomControlBar>
            </Relative>
        </CRMTidyPopout>
    );
};
