import React from "react";
import * as ListingForm5 from "../../../../domain/models/ListingForm5";
import * as JsonInnerError1 from "../../../../domain/models/JsonInnerError1";
import * as JsonError1 from "../../../../domain/models/JsonError1";
import * as Listing3 from "../../../../domain/models/Listing3";
import * as DisplayAddress from "../../../../domain/models/DisplayAddress";
import { CRMInfoBoxSimpleColourComponent } from "../CRMInfoBoxSimpleColourComponent/CRMInfoBoxSimpleColourComponent";
import { CRMPadding } from "../Simple/CRMPadding/CRMPadding";
import { CRMColumns } from "../CRMColumns/CRMColumns";
import { CRMParagraph } from "../Simple/CRMParagraph/CRMParagraph";
import { pipe } from "fp-ts/lib/function";
import { CRMCardOutsidePopupBasic } from "../CRMCardOutsidePopupBasic/CRMCardOutsidePopupBasic";
import { CRMButtonIcon } from "../CRMButtonIcon/CRMButtonIcon";
import { CRMEditAddressForm } from "../CRMEditAddressForm/CRMEditAddressForm";

class CRMEnquiryEditListingAddressComponent extends React.Component<{
    listing: ListingForm5.T;
    validationErrors: Array<JsonInnerError1.T>;
    onChange: (listingAddress: ListingForm5.T) => void;
    onAutocompleteClicked: () => void;
}, {
    modalOpen: boolean;
}> {
    constructor(props: CRMEnquiryEditListingAddressComponent["props"]) {
        super(props);
        this.state = {
            modalOpen: false,
        };
    }

    openModal = (): void =>
        this.setState({modalOpen: true});

    closeModal = (): void =>
        this.setState({modalOpen: false});

    hasValidationErrorsForAddress = (): boolean =>
        JsonError1.doTargetKeysHaveInnerErrors(["postcode"], this.props.validationErrors);

    public render(): JSX.Element {
        return (
            <div className="crm-enquiry-edit-listing-address-component">
                { pipe(
                    {
                        ...Listing3.newDefault(),
                        ...this.props.listing,
                    },
                    DisplayAddress.fromListing3,
                    DisplayAddress.isFilled,
                )
                    // Has existing address
                    ? <div className="crm-enquiry-edit-listing-address-component__info-box-content">
                            <CRMInfoBoxSimpleColourComponent
                                fullWidth={true}
                                displayError={this.hasValidationErrorsForAddress()}
                            >
                                <CRMPadding size="medium">
                                    <CRMColumns
                                        padding="medium"
                                        alignItems="center"
                                        wrapOnMobile={false}
                                        columns={[
                                            {
                                                flex: 1,
                                                content: <CRMParagraph>
                                                    { pipe(
                                                        {
                                                            ...Listing3.newDefault(),
                                                            ...this.props.listing,
                                                        },
                                                        DisplayAddress.fromListing3,
                                                        DisplayAddress.toString,
                                                    )}
                                                </CRMParagraph>,
                                            },
                                            {
                                                flex: 0,
                                                content: <CRMButtonIcon
                                                    variant="secondary"
                                                    icon="pencil"
                                                    onClick={this.openModal}
                                                />,
                                            },
                                        ]}
                                    />
                                </CRMPadding>
                            </CRMInfoBoxSimpleColourComponent>
                        </div>

                    // No address yet
                    : <div
                        className="crm-enquiry-edit-listing-address-component__info-box-content crm-enquiry-edit-listing-address-component__info-box-content--add-address"
                        onClick={this.openModal}
                    >
                        <CRMInfoBoxSimpleColourComponent
                            fullWidth={false}
                            displayError={this.hasValidationErrorsForAddress()}
                        >
                            <CRMPadding size="medium">
                                <CRMColumns
                                    padding="medium"
                                    alignItems="center"
                                    wrapOnMobile={false}
                                    columns={[
                                        {
                                            flex: 1,
                                            content: <div className="crm-enquiry-edit-listing-address-component__add_address_text_container">
                                                <CRMParagraph>
                                                    Add address
                                                </CRMParagraph>
                                            </div>,
                                        },
                                        {
                                            flex: 1,
                                            content: <CRMButtonIcon
                                                variant="secondary"
                                                icon="pencil"
                                                onClick={this.openModal}
                                            />,
                                        },
                                    ]}
                                />
                            </CRMPadding>
                        </CRMInfoBoxSimpleColourComponent>
                    </div>}

                {/* Popup */}
                <CRMCardOutsidePopupBasic
                    isOpen={this.state.modalOpen}
                    size="medium"
                    title="Add address"
                    closeText="Continue"
                    onClose={this.closeModal}
                >
                   <CRMEditAddressForm
                        listing={this.props.listing}
                        validationErrors={this.props.validationErrors.map(JsonInnerError1.toTErrorTuple)}
                        onChange={this.props.onChange}
                        onAutocompleteClicked={this.props.onAutocompleteClicked}
                   />
                </CRMCardOutsidePopupBasic>
            </div>
        );
    }
}

export default CRMEnquiryEditListingAddressComponent;
