import React from "react";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { SpacingRow } from "../../BuildingBlocks/SpacingRow";
import { CRMIcon, TIcon } from "../../CRMIcon/CRMIcon";

type TCRMFilterAndSelectTinyArrowOptionWrapProps = {
    onClick?: () => void;
    icon?: TIcon;
    mode?: "icon" | "no-icon";
    size?: "small" | "regular";
};

export const CRMFilterAndSelectTinyArrowOptionWrap = (props: React.PropsWithChildren<TCRMFilterAndSelectTinyArrowOptionWrapProps>): JSX.Element => {

    const Size = props.size || "regular";
    const Mode = props.mode || "icon";
    const Icon = props.icon || "arrow-right";

    return (
        <div
            className={`
                crm-filter-and-select-tiny-arrow-option-wrap
                crm-filter-and-select-tiny-arrow-option-wrap--${Size}
            `}
            onClick={props.onClick}
        >
            <SpacingRow
                spacing={CRMSpacing.MEDIUM} 
                alignItems="flex-start"
                childSize="1fr 16px"
            >
                {props.children}

                {Mode === "icon" &&
                    <CRMIcon
                        iconName={Icon}
                        colour="neutral-2"
                        size="small"
                    />
                }
            </SpacingRow>
        </div>
    );
}
