import React from "react";
import { TCasesRiskAssessment2, getCasesRiskAssessmentGradeLabel } from "../../../../domain/codecs/CasesRiskAssessment";
import { CRMColors } from "../../models/CRMColors";
import { CRMSpacing } from "../../models/CRMSpacing";
import { SpacingColumn } from "../BuildingBlocks/SpacingColumn";
import { FontSize } from "../BuildingBlocks/FontSize";
import { TextColour } from "../TextColour/TextColour";
import { WeightBold } from "../WeightBold/WeightBold";
import { CRMFontSizes } from "../../models/CRMFontSizes";
import { CRMParagraph } from "../Simple/CRMParagraph/CRMParagraph";
import { WeightMedium } from "../WeightMedium/WeightMedium";
import { CRMStatusPill } from "../Simple/CRMStatusPill/CRMStatusPill";
import { CRMSpacingRow } from "../CRMSpacingRow/CRMSpacingRow";
import { DateTime } from "luxon";
import { Underline } from "../Underline/Underline";
import { WeightSemiBold } from "../WeightSemiBold/WeightSemiBold";
export const CRMRiskManagementAssessment = (props: { assessment: TCasesRiskAssessment2 }): JSX.Element => {
    const getGradeColour = () =>
        props.assessment.grade === "red" ? CRMColors.HIGHLIGHTS_INSTRUCTIONAL_BRICK_2
        : props.assessment.grade === "amber" ? CRMColors.HIGHLIGHTS_PERTINENT_YELLOW_4
        : props.assessment.grade === "green" ? CRMColors.HIGHLIGHTS_POSITIVE_GREEN_2
        : CRMColors.NEUTRAL_INK;

    const getPillFontColour = () =>
        props.assessment.grade === "red" ? CRMColors.NEUTRAL_PAPER
        : props.assessment.grade === "green" ? CRMColors.NEUTRAL_PAPER
        : CRMColors.NEUTRAL_INK;

    return <SpacingColumn spacing={CRMSpacing.TINY}>
        <CRMSpacingRow>
            <CRMStatusPill
                borderColor={getGradeColour()}
                pillColor={getGradeColour()}
                textColor={getPillFontColour()}
                label={getCasesRiskAssessmentGradeLabel(props.assessment.grade)}
            />
            <FontSize size={CRMFontSizes.MED}>
                <WeightSemiBold>
                    <TextColour colour={CRMColors.NEUTRAL_0}>
                        {DateTime.fromISO(props.assessment.created_at).toFormat("dd LLL yyyy") }
                    </TextColour>
                </WeightSemiBold>
            </FontSize>
        </CRMSpacingRow>
        <CRMParagraph fontSize={CRMFontSizes.SMALL}>
            <WeightMedium>
                {props.assessment.explanation}
            </WeightMedium>
        </CRMParagraph>
        
        {
            props.assessment.are_any_of_the_clients_vulnerable === "yes"
            ? <CRMParagraph fontSize={CRMFontSizes.SMALL}>
                    <WeightBold>
                        Clients <Underline>are vulnerable</Underline>:&nbsp;
                    </WeightBold>
                    <WeightMedium>
                        {props.assessment.explanation}
                    </WeightMedium>
            </CRMParagraph>
            : <CRMParagraph fontSize={CRMFontSizes.SMALL}>
                    <WeightBold>
                        Clients <Underline>are not vulnerable</Underline>
                    </WeightBold>
            </CRMParagraph>
        }
        
    </SpacingColumn>;
};
