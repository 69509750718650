import React from "react";
import { CRMSpacing } from "../../../../models/CRMSpacing";
import { SpacingRow } from "../../../BuildingBlocks/SpacingRow";
import { CRMParagraph } from "../../../Simple/CRMParagraph/CRMParagraph";
import { WeightBold } from "../../../WeightBold/WeightBold";
import { TCasesDayViewForm } from "../../../../../../domain/codecs/form/CasesForm";
import { CRMDropdownCalendar } from "../../../Complex/CRMDropdownCalendar/CRMDropdownCalendar";
import { pipe } from "fp-ts/lib/function";
import { array } from "fp-ts";
import { CRMDropdownColourComponent, ICRMDropdownColourOption } from "../../../CRMDropdownColourComponent/CRMDropdownColourComponent";
import { onChangeForm } from "../../../../../../shared/src/codecs/types/form";

export const CRMDayTableFilter = (props: React.PropsWithChildren<{
    form: TCasesDayViewForm,
    onChangeForm: (form: TCasesDayViewForm) => void,
}>): JSX.Element => {
    const assignableUsers: Array<ICRMDropdownColourOption<string>> =
        pipe(
            props.form.children.assignable_users,
            array.map(({ id, first_name, last_name}) => ({
                label: `${first_name} ${last_name}`,
                value: id
            })),
            array.prepend({ label: "Any", value: "any" })
        );

    const onChange = onChangeForm(props.form, props.onChangeForm);

    const onChangeFilter = (field: keyof TCasesDayViewForm["edited"]["filters"]) =>
        (value) =>
            onChange("filters")({
                ...props.form.edited.filters,
                [field]: value === "any" ? null : value
            })
    ;

    return <SpacingRow spacing={CRMSpacing.TINY}>
        <CRMParagraph>
            <WeightBold>Showing tasks for</WeightBold>
        </CRMParagraph>

        <CRMDropdownCalendar
            options="only_overdue"
            value={props.form.edited.filters.date}
            onChange={onChangeFilter("date")}
        />

        <CRMParagraph>
            <WeightBold>, assigned to</WeightBold>
        </CRMParagraph>
        <CRMDropdownColourComponent
            value={props.form.edited.filters.assigned_to || "any"}
            options={assignableUsers}
            onChange={onChangeFilter("assigned_to")}
        />
    </SpacingRow>
};
