import React from "react";
import { DateTime } from "luxon";
import { useSplitInput } from "../../../../hooks/useSplitInput";
import { SpacingRow } from "../../../BuildingBlocks/SpacingRow";
import UnstyledInputGeneralComponent from "../../../UnstyledInputGeneralComponent/UnstyledInputGeneralComponent";
import { isEmpty } from "../../../../../../shared/src/util";

type TDateOfBirthInputProps = {
    value: string | null;
    onChange: (value: string | null) => void;
};

export const FrontDateOfBirthInput = (props: React.PropsWithChildren<TDateOfBirthInputProps>): JSX.Element => {
    const { partStates } = useSplitInput({
        value: props.value || "",
        valuePrepper: (value) => isEmpty(value) ? "" : DateTime.fromISO(value).toFormat('ddMMyyyy'), 
        chunks: [2, 2, 4],
        onChange: (value) => {
            const date = DateTime.fromFormat(value, "ddMMyyyy");
            if (
                date.isValid
                && Number(date.toFormat("yyyy")) >= 1900 && Number(date.toFormat("yyyy")) <= Number(DateTime.utc().toFormat("yyyy"))
            ) {
                // We have to manually set our own timezone
                // and time here because luxon has a timezone bug
                // where it will rewind days forward or backward 
                // on timezone change when certain month days combo match
                // so we do it manually
                props.onChange(`${date.toFormat("yyyy-MM-dd")}T00:00:00.000Z`);
            } else {
                props.onChange(null);
            }
        }
    });

    return (
        <div className={`front-date-of-birth-input`}>
            <SpacingRow childSize="25px 15px 35px 15px 50px">
                <UnstyledInputGeneralComponent
                    inputRef={partStates[0].ref}
                    className={`front-date-of-birth-input__micro-input`}
                    placeholder="dd"
                    value={partStates[0].value}
                    inputType="numericString"
                    maxLength={partStates[0].maxLength}
                    displayError={false}
                    onChange={(value) =>
                        partStates[0].setValue(value)
                    }
                />
                <div className="front-date-of-birth-input__seperator">/</div>
                <UnstyledInputGeneralComponent
                    inputRef={partStates[1].ref}
                    className={`front-date-of-birth-input__micro-input`}
                    placeholder="mm"
                    value={partStates[1].value}
                    inputType="numericString"
                    maxLength={partStates[1].maxLength}
                    displayError={false}
                    onChange={partStates[1].setValue}
                />
                <div className="front-date-of-birth-input__seperator">/</div>
                <UnstyledInputGeneralComponent
                    inputRef={partStates[2].ref}
                    className={`front-date-of-birth-input__micro-input`}
                    value={partStates[2].value}
                    placeholder="yyyy"
                    inputType="numericString"
                    maxLength={partStates[2].maxLength}
                    displayError={false}
                    onChange={partStates[2].setValue}
                />
            </SpacingRow>
        </div>
    );
};
