import { pipe } from "fp-ts/lib/function";
import React, { useEffect, useState } from "react";
import { TQuoteRateFeeTransferValue } from "../../../../../domain/codecs/QuoteRateFee";
import { CRMColors } from "../../../models/CRMColors";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { HorizontalLine } from "../../BuildingBlocks/HorizontalLine";
import { SpacingColumn } from "../../BuildingBlocks/SpacingColumn";
import { SpacingRow } from "../../BuildingBlocks/SpacingRow";
import { CRMIcon } from "../../CRMIcon/CRMIcon";
import { CRMInputCurrency } from "../../CRMInputCurrency/CRMInputCurrency";
import CRMInputLabelAndErrorWrapComponent from "../../CRMInputLabelAndErrorWrapComponent/CRMInputLabelAndErrorWrapComponent";
import { CRMInputPercentage } from "../../CRMInputPercentage/CRMInputPercentage";
import { CRMParagraph } from "../../Simple/CRMParagraph/CRMParagraph";
import { CRMRadioComponent } from "../../CRMRadioComponent/CRMRadioComponent";
import { CursorPointer } from "../../CursorPointer/CursorPointer";
import { WeightMedium } from "../../WeightMedium/WeightMedium";

export const CRMQuoteRatesSingleViewEditTransferValueFeesVariableItem = (props: React.PropsWithChildren<{
    fee: TQuoteRateFeeTransferValue,
    displayError: boolean,
    canDelete: boolean,
    onChange: (transfer_value_fees: TQuoteRateFeeTransferValue) => void,
    onDelete: () => void,
}>): JSX.Element => {
    const [feeType, setFeeType] = useState<"fixed" | "progressive">("fixed");

    const isFixedType = feeType === "fixed";

    const isProgressiveType = feeType === "progressive";

    const onFeeTypeChange = (value: "fixed" | "progressive") => {
        if (value === "fixed") {
            onFeeChange("fee_progressive_percentage_of_property_price")(0);
        }

        if (value === "progressive") {
            onFeeChange("fee_pence")(0);
        }

        setFeeType(value);
    };

    const onFeeChange = <A extends keyof TQuoteRateFeeTransferValue>(key: A) => (value: TQuoteRateFeeTransferValue[A]) =>
        pipe(
            {
                ...props.fee,
                [key]: value,
            },
            props.onChange,
        );

    useEffect(() => {
        if (props.fee.fee_progressive_percentage_of_property_price > 0) {
            setFeeType("progressive");
        }

        if (props.fee.fee_pence > 0) {
            setFeeType("fixed");
        }
    }, [
        props.fee.fee_progressive_percentage_of_property_price,
        props.fee.fee_pence,
    ]);

    return <SpacingColumn
        spacing={CRMSpacing.MEDIUM}
    >
        {/* GREATER THAN OR EQUAL TO */}
        <CRMInputLabelAndErrorWrapComponent label="When greater than or equal to:">
            <CRMInputCurrency
                mode="pence"
                value={props.fee.when_greater_than_or_equal_to_property_price_pence}
                onChange={onFeeChange("when_greater_than_or_equal_to_property_price_pence")}
            />
        </CRMInputLabelAndErrorWrapComponent>

        {/* LESS THAN */}
        <CRMInputLabelAndErrorWrapComponent
            label="And less than:"
            guidance="Leave empty for infinity."
        >
            <CRMInputCurrency
                mode="pence"
                value={props.fee.when_less_than_property_price_pence}
                onChange={onFeeChange("when_less_than_property_price_pence")}
            />
        </CRMInputLabelAndErrorWrapComponent>

        {/* FEE */}
        <CRMInputLabelAndErrorWrapComponent label="Then the fee is:">
            <SpacingRow
                spacing={CRMSpacing.MEDIUM}
                justifyContent="space-between"
                childSize="1fr 1fr"
                alignItems="flex-start"
            >
                {/* FIXED AMOUNT */}
                <SpacingColumn
                    spacing={CRMSpacing.TINY}
                >
                    <CRMRadioComponent
                        text="A fixed amount"
                        value="fixed"
                        onChange={() => onFeeTypeChange("fixed")}
                        checked={isFixedType}
                    />

                    <CRMInputCurrency
                        mode="pence"
                        value={feeType === "fixed" ? props.fee.fee_pence : null}
                        disabled={!isFixedType}
                        displayError={props.displayError && feeType === "fixed" && props.fee.fee_pence === 0}
                        onChange={(value) => onFeeChange("fee_pence")(value || 0)}
                    />
                </SpacingColumn>

                {/* PROGRESSIVE */}
                <SpacingColumn
                    spacing={CRMSpacing.TINY}
                >
                    <CRMRadioComponent
                        text="A progressive % of transfer value"
                        value="progressive"
                        onChange={() => onFeeTypeChange("progressive")}
                        checked={isProgressiveType}
                    />

                    <CRMInputPercentage
                        value={feeType === "progressive" ? props.fee.fee_progressive_percentage_of_property_price : null}
                        disabled={!isProgressiveType}
                        displayError={props.displayError && feeType === "progressive" && props.fee.fee_progressive_percentage_of_property_price === 0}
                        onChange={(value) => onFeeChange("fee_progressive_percentage_of_property_price")(value || 0)}
                    />
                </SpacingColumn>
            </SpacingRow>
        </CRMInputLabelAndErrorWrapComponent>

        {/* DELETE */}
        {props.canDelete &&
            <CursorPointer onClick={() => props.onDelete()}>
                <SpacingRow
                    spacing={CRMSpacing.TINY}
                    justifyContent="start"
                    alignItems="center"
                >
                    {/* ICON */}
                    <CRMIcon
                        iconName="delete"
                        colour="neutral-ink"
                        size="small"
                    />

                    {/* TEXT */}
                    <CRMParagraph>
                        <WeightMedium>
                            Delete this variation
                        </WeightMedium>
                    </CRMParagraph>
                </SpacingRow>
            </CursorPointer>
        }

        {/* LINE */}
        <HorizontalLine color={CRMColors.NEUTRAL_8} />
    </SpacingColumn>;
};
