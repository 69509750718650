import React, { useState } from "react";
import { isAFunction } from "../functions/functions";

type TUseResolutionStatusProps<S extends string, D> = {
    defaultStatus: S;
    onSet?: TOnSet<S, D>;
}

type TOnSet<S extends string, D> = (status: S, data?: D) => void;

export const useResolutionStatus = <S extends string, D>(props: TUseResolutionStatusProps<S, D>) => {
    
    const [resolutionStatus, setResolutionStatus] = useState<S>(props.defaultStatus);
    const [resolutionData, setResolutionData] = useState<D | null>(null);

    const setResolution = (status: S) => 
        (data?: D) => {
            setResolutionStatus(status);
            setResolutionData(data || null);

            if (isAFunction<TOnSet<S,D>>(props.onSet)) {
                props.onSet(status, data);
            }
        }
    
    const resetResolution = () => {
        setResolutionStatus(props.defaultStatus);
        setResolutionData(null);
    };

    return {
        resolutionStatus,
        resolutionData,
        setResolution,
        resetResolution,
    };
}