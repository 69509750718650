import React from "react";
import { THexColor } from "../../../models/StringLiterals";
import { CRMIcon, TIcon } from "../../CRMIcon/CRMIcon";
import { CRMColors } from "../../../models/CRMColors";
import { CRMFontSizes } from "../../../models/CRMFontSizes";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { FontQuicksand } from "../../BuildingBlocks/FontQuicksand";
import { FontSize } from "../../BuildingBlocks/FontSize";
import { SpacingRow } from "../../BuildingBlocks/SpacingRow";
import { TextColor } from "../../BuildingBlocks/TextColor";

type TThinColoredButtonProps = {
    label: JSX.Element | string;
    backgroundColor: THexColor;
    fontColor?: THexColor;
    onClick: () => void;
    icon?: TIcon;
};

export const CRMThinColoredButton = (props: React.PropsWithChildren<TThinColoredButtonProps>): JSX.Element => {

    return (
        <div 
            style={{
                backgroundColor: props.backgroundColor,
                borderRadius: "3px",
                display: "inline-flex",
                height: "30px",
                alignItems: "center",
                padding: `0px ${CRMSpacing.SMALL}`,
                cursor: "pointer"
            }}
            onClick={props.onClick}
        >
            <SpacingRow spacing="3px">
                {/* LABEL */}
                <FontQuicksand>
                    <FontSize size={CRMFontSizes.SMALL} lineHeight={1}>
                        <TextColor color={props.fontColor || CRMColors.NEUTRAL_INK}>
                            {props.label}
                        </TextColor>
                    </FontSize>
                </FontQuicksand>
                
                {/* ICON */}
                {props.icon &&
                    <CRMIcon
                        iconName={props.icon}
                        colour="hex"
                        hexColour={props.fontColor}
                    />
                } 
            </SpacingRow>
        </div>
    );
};
