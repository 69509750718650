import { Observable, of, timer, from } from "rxjs";
import { tap, debounce, mergeMap, switchMap, filter, debounceTime } from "rxjs/operators";
import { TCodecSetState, TGetCodecState } from "../applyActions";
import { formOperation } from "../../wrappers/formOperation";
import { TActionsDefinitionsList } from "./TAction";
import { action } from "./actionFunctions";
import { TStateCodec, TStateLens } from "../../../../domain/codecs/state";
import { TCaseContractDetailsForm } from "../../../../domain/codecs/form/CaseContractDetailsForm";
import { TCasePropertyForm, TLandRegistryTitleSuggestion } from "../../../../domain/codecs/form/CasePropertyForm";
import { TCaseOtherSideMemberForm } from "../../../../domain/codecs/form/CaseOtherSideMemberForm";
import { TCaseDualRepSearchResultsForm } from "../../../../domain/codecs/form/CaseForm";
import { pipe } from "fp-ts/lib/function";
import { array } from "fp-ts";
import { runAutomaticUpdates } from "./automaticBlockUpdate";
import { TCodecLens } from "../../../../shared/src/codecs/codecLens";
import { TUuidForm } from "../../../../domain/codecs/form/UuidForm";

const setLandRegistryTitleSuggestion$ = (
    response: TCasePropertyForm,
    lens: TCodecLens<TStateCodec, TStateCodec>,
    set: TCodecSetState,
    get: TGetCodecState,
) =>
    response.edited.title_number === ""
    && response.children.land_registry_title_suggestions.length === 1
        ? of(
            set(lens.contracts_block.children.properties.where((form) => form.edited.id === response.edited.id).edited.set({
                ...response.edited,
                is_registered: true,
                title_number: response.children.land_registry_title_suggestions[0].title_number,
                tenure: response.children.land_registry_title_suggestions[0].tenure,
            }))
        )
            .pipe(
                switchMap(() => formOperation("UpdateCaseProperty", lens.contracts_block.children.properties.where((form) => form.edited.id === response.edited.id).get()(get()))),
                tap((r) => set(lens.contracts_block.children.properties.where((form) => form.edited.id === r.edited.id).set({
                    ...r,
                    edited: lens.contracts_block.children.properties.where((form) => form.edited.id === r.edited.id).edited.get()(get()),
                })))
            )
        : of(undefined)

const updateCaseProperty$ = (
    casePropertyId: string,
    lens: TCodecLens<TStateCodec, TStateCodec>,
    set: TCodecSetState,
    get: TGetCodecState,
) =>
    from(formOperation("UpdateCaseProperty", lens.contracts_block.children.properties.where((form) => form.edited.id === casePropertyId).get()(get())))
        .pipe(
            tap((response) => set(lens.contracts_block.children.properties.where((form) => form.edited.id === response.edited.id).set({
                ...response,
                edited: lens.contracts_block.children.properties.where((form) => form.edited.id === response.edited.id).edited.get()(get()),
            }))),
            switchMap((response) => setLandRegistryTitleSuggestion$(response, lens, set, get)),
        );

export const actions: TActionsDefinitionsList = [
    action("LEGAL_CASE_CONTRACTS_BLOCK_VIEW", (obs$, lens, setState, getState) => {
        obs$.pipe(
            $loadSection(lens, setState, getState),
        ).subscribe();
    }),
    action("LEGAL_CASE_CONTRACTS_BLOCK_CHANGE", (obs$: Observable<TCaseContractDetailsForm>, lens, setState, getState, routes, dispatch) => {
        obs$.pipe(
            tap((payload) => setState(lens.contracts_block.children.details.set(payload))),
            debounce(() => timer(1000)),
            tap(() => setState(lens.contracts_block.children.details.status.set("submitting"))),
            switchMap(() => formOperation("UpdateCaseContractSection", lens.contracts_block.children.details.get()(getState()))),
            tap((response) => setState(lens.contracts_block.children.details.set({
                ...response,
                edited: {
                    ...lens.contracts_block.children.details.edited.get()(getState()),
                    searches_required: response.edited.searches_required,
                    introducer_referral_fee_gbp_pence: response.edited.introducer_referral_fee_gbp_pence,
                }
            }))),
            tap(() => runAutomaticUpdates(routes, dispatch, getState, "case_contracts"))
        ).subscribe();
    }),
    action("LEGAL_CASE_ADDRESS_AUTOCOMPLETE", (obs$: Observable<TCasePropertyForm>, lens, setState, getState) => {
        obs$.pipe(
            tap(() => setState(lens.case_details_page.autocomplete_address_status.set("submitting"))),
            switchMap((payload) => formOperation("AutocompleteCaseDetailsAddress", payload)),
            tap((response) => setState(lens.contracts_block.children.properties.where((form) => form.edited.id === response.edited.id).set(response))),
            tap((response) => setState(lens.case_details_page.autocomplete_address_status.set(response.status))),
            tap((response) => setState(lens.contracts_block.children.properties.where((form) => form.edited.id === response.edited.id).status.set("submitting"))),
            switchMap((response) => formOperation("UpdateCaseProperty", lens.contracts_block.children.properties.where((form) => form.edited.id === response.edited.id).get()(getState()))),
            tap((response) => setState(lens.contracts_block.children.properties.where((form) => form.edited.id === response.edited.id).set(response))),
            switchMap((response) => setLandRegistryTitleSuggestion$(response, lens, setState, getState)),
        ).subscribe();
    }),
    action("LEGAL_CASE_ADD_PROPERTY", (obs$, lens, setState, getState, routes, dispatch) => {
        obs$.pipe(
            tap(() => setState(lens.contracts_block.children.new_property.status.set("submitting"))),
            switchMap(() => formOperation("CreateCaseProperty", lens.contracts_block.children.new_property.get()(getState()))),
            tap((response) => setState(lens.contracts_block.children.new_property.set(response))),
            $loadSection(lens, setState, getState),
            tap(() => runAutomaticUpdates(routes, dispatch, getState, "case_contracts"))
        ).subscribe();
    }),
    action("LEGAL_CASE_UPDATE_PROPERTY", (obs$: Observable<TCasePropertyForm>, lens, setState, getState, routes, dispatch) => {
        obs$.pipe(
            tap((payload) => setState(lens.contracts_block.children.properties.where((form) => form.edited.id === payload.edited.id).set({
                ...payload,
                status: "requiresSubmission",
            }))),
            debounceTime(1000),
            switchMap((payload) => updateCaseProperty$(payload.edited.id, lens, setState, getState)),
            tap(() => runAutomaticUpdates(routes, dispatch, getState, "case_contracts"))
        ).subscribe();
    }),
    action("LEGAL_CASE_DELETE_PROPERTY", (obs$: Observable<TCasePropertyForm>, lens, setState, getState, routes, dispatch) => {
        obs$.pipe(
            switchMap((payload) => formOperation("DeleteCaseProperty", lens.contracts_block.children.properties.where((form) => form.edited.id === payload.edited.id).get()(getState()))),
            $loadSection(lens, setState, getState),
            tap(() => runAutomaticUpdates(routes, dispatch, getState, "case_contracts"))
        ).subscribe();
    }),
    action("LEGAL_CASE_PROPERTY_ORDER_REGISTER", (obs$: Observable<TUuidForm>, lens, setState, getState, routes, dispatch) => {
        obs$.pipe(
            tap((payload) => setState(lens.contracts_block.children.properties.where((form) => form.edited.id === payload.edited.id).children.order_register_form.status.set("submitting"))),
            switchMap((payload) => formOperation("OrderCasePropertyTitleRegister", lens.contracts_block.children.properties.where((form) => form.edited.id === payload.edited.id).children.order_register_form.get()(getState()))),
            tap((response) => setState(lens.contracts_block.children.properties.where((form) => form.edited.id === response.edited.id).children.order_register_form.set(response))),
            filter(({status}) => status === "success"),
            $loadSection(lens, setState, getState),
            tap(() => runAutomaticUpdates(routes, dispatch, getState, "case_contracts"))
        ).subscribe();
    }),
    action("LEGAL_CASE_PROPERTY_CREATE_LISTING", (obs$: Observable<TUuidForm>, lens, setState, getState, routes, dispatch) => {
        obs$.pipe(
            tap((payload) => setState(lens.contracts_block.children.properties.where((form) => form.edited.id === payload.edited.id).children.valuation.create_listing_form.status.set("submitting"))),
            switchMap((payload) => formOperation("CreateListingFromCaseProperty", lens.contracts_block.children.properties.where((form) => form.edited.id === payload.edited.id).children.valuation.create_listing_form.get()(getState()))),
            tap((response) => setState(lens.contracts_block.children.properties.where((form) => form.edited.id === response.edited.id).children.valuation.create_listing_form.set(response))),
            filter(({status}) => status === "success"),
            $loadSection(lens, setState, getState),
            tap(() => runAutomaticUpdates(routes, dispatch, getState, "case_contracts"))
        ).subscribe();
    }),
    action("LEGAL_CASE_SELECT_LAND_REGISTRY_TITLE_SUGGESTION", (obs$: Observable<TLandRegistryTitleSuggestion>, lens, setState, getState, routes, dispatch) => {
        obs$.pipe(
            tap((payload) => setState(lens.contracts_block.children.properties.where((form) => form.edited.id === payload.id).set({
                ...lens.contracts_block.children.properties.where((form) => form.edited.id === payload.id).get()(getState()),
                status: "requiresSubmission",
                edited: {
                    ...lens.contracts_block.children.properties.where((form) => form.edited.id === payload.id).get()(getState()).edited,
                    is_registered: true,
                    title_number: payload.title_number,
                    tenure: payload.tenure
                }
            }))),
            switchMap((payload) => updateCaseProperty$(payload.id, lens, setState, getState)),
            tap(() => runAutomaticUpdates(routes, dispatch, getState, "case_contracts"))
        ).subscribe();
    }),
    action("LEGAL_CASE_CREATE_OTHER_SIDE_MEMBER", (obs$, lens, setState, getState, routes, dispatch) => {
        obs$.pipe(
            tap(() => setState(lens.contracts_block.children.new_other_side_member.status.set("submitting"))),
            switchMap(() => formOperation("CreateCaseOtherSideMember", lens.contracts_block.children.new_other_side_member.get()(getState()))),
            tap((response) => setState(lens.contracts_block.children.new_other_side_member.set(response))),
            filter((response) => response.status === "success"),
            $loadSection(lens, setState, getState),
            tap(() => runAutomaticUpdates(routes, dispatch, getState, "case_contracts"))
        ).subscribe();
    }),
    action("LEGAL_CASE_UPDATE_OTHER_SIDE_MEMBER", (obs$: Observable<TCaseOtherSideMemberForm>, lens, setState, getState, routes, dispatch) => {
        obs$.pipe(
            tap((payload) => setState(lens.contracts_block.children.other_side_members.where((form) => form.edited.id === payload.edited.id).set(payload))),
            debounceTime(1000),
            tap((payload) => setState(lens.contracts_block.children.other_side_members.where((form) => form.edited.id === payload.edited.id).status.set("submitting"))),
            switchMap((payload) => formOperation("UpdateCaseOtherSideMember", lens.contracts_block.children.other_side_members.where((form) => form.edited.id === payload.edited.id).get()(getState()))),
            tap((response) => setState(lens.contracts_block.children.other_side_members.where((form) => form.edited.id === response.edited.id).set({
                ...response,
                edited: lens.contracts_block.children.other_side_members.where((form) => form.edited.id === response.edited.id).edited.get()(getState()),
            }))),
            tap(() => runAutomaticUpdates(routes, dispatch, getState, "case_contracts"))
        ).subscribe();
    }),
    action("LEGAL_CASE_DELETE_OTHER_SIDE_MEMBER", (obs$: Observable<TCaseOtherSideMemberForm>, lens, setState, getState, routes, dispatch) => {
        obs$.pipe(
            tap((payload) => setState(lens.contracts_block.children.other_side_members.where((form) => form.edited.id === payload.edited.id).status.set("submitting"))),
            switchMap((payload) => formOperation("DeleteCaseOtherSideMember", lens.contracts_block.children.other_side_members.where((form) => form.edited.id === payload.edited.id).get()(getState()))),
            $loadSection(lens, setState, getState),
            tap(() => runAutomaticUpdates(routes, dispatch, getState, "case_contracts"))
        ).subscribe();
    }),
    action("LEGAL_CASE_SUGGESTION_LINK_DUAL_REP", (obs$: Observable<TCaseDualRepSearchResultsForm>, lens, setState, getState, routes, dispatch) => {
        obs$.pipe(
            switchMap((form) => formOperation("LinkCaseDualRep", form)),
            tap((response) => setState(lens.contracts_block.children.properties.where((f) =>
                pipe(
                    f.children.dual_rep_suggestion_forms,
                    array.filter((dualRepForm) => dualRepForm.edited.from_case_id === response.edited.from_case_id && dualRepForm.edited.to_case_id === response.edited.to_case_id),
                    ({length}) => length > 0,
                )
            ).children.dual_rep_suggestion_forms.where((f) => f.edited.from_case_id === response.edited.from_case_id && f.edited.to_case_id === response.edited.to_case_id).set(response))),
            filter(({status}) => status === "success"),
            $loadSection(lens, setState, getState),
            tap(() => runAutomaticUpdates(routes, dispatch, getState, "case_contracts"))
        ).subscribe();
    }),
    action("LEGAL_CONTRACT_CREATE_DCP", (obs$, lens, setState, getState, routes, dispatch) => {
        obs$.pipe(
            tap(() => setState(lens.contracts_block.children.create_dcp_form.status.set("submitting"))),
            switchMap(() => formOperation("CreateLegalDraftContractPack", lens.contracts_block.children.create_dcp_form.get()(getState()))),
            tap((response) => setState(lens.contracts_block.children.create_dcp_form.set(response))),
            tap(() => runAutomaticUpdates(routes, dispatch, getState, "case_contracts"))
        ).subscribe();
    }),
    action("LEGAL_CONTRACT_CREATE_TA13", (obs$, lens, setState, getState, routes, dispatch) => {
        obs$.pipe(
            tap(() => setState(lens.contracts_block.children.create_TA13_form.status.set("submitting"))),
            switchMap(() => formOperation("CreateTA13", lens.contracts_block.children.create_TA13_form.get()(getState()))),
            tap((response) => setState(lens.contracts_block.children.create_TA13_form.set(response))),
            tap(() => runAutomaticUpdates(routes, dispatch, getState, "case_contracts"))
        ).subscribe();
    }),
    action("LEGAL_CONTRACT_CREATE_TRANSFER_DISCLAIMER", (obs$, lens, setState, getState, routes, dispatch) => {
        obs$.pipe(
            tap(() => setState(lens.contracts_block.children.create_transfer_disclaimer_form.status.set("submitting"))),
            switchMap(() => formOperation("CreateTransferDisclaimer", lens.contracts_block.children.create_transfer_disclaimer_form.get()(getState()))),
            tap((response) => setState(lens.contracts_block.children.create_transfer_disclaimer_form.set(response))),
            tap(() => runAutomaticUpdates(routes, dispatch, getState, "case_contracts"))
        ).subscribe();
    }),
    action("LEGAL_CONTRACT_CREATE_ESIGN_CERT", (obs$, lens, setState, getState, routes, dispatch) => {
        obs$.pipe(
            tap(() => setState(lens.contracts_block.children.create_esign_cert_form.status.set("submitting"))),
            switchMap(() => formOperation("CreateLegalESignCert", lens.contracts_block.children.create_esign_cert_form.get()(getState()))),
            tap((response) => setState(lens.contracts_block.children.create_esign_cert_form.set(response))),
            tap(() => runAutomaticUpdates(routes, dispatch, getState, "case_contracts"))
        ).subscribe();
    }),

    // 
    // TRANSFER RECORDS
    // 
    action("LEGAL_CONTRACT_CREATE_TRANSFER_RECORD", (obs$, lens, setState, getState) => {
        obs$.pipe(
            switchMap((form) => formOperation("CreateCaseTransferSetupRecord", form)),
            filter(({status}) => status === "success"),
            $loadSection(lens, setState, getState)
        ).subscribe()
    }),
    action("LEGAL_CONTRACT_UPDATE_TRANSFER_RECORD", (obs$, lens, setState, getState) => {
        obs$.pipe(
            tap((form) => setState(
                lens.contracts_block.children.transfer_setup_records
                    .where((record) => record.original.id === form.original.id)
                    .set(form)
            )),
            debounceTime(1000),
            switchMap((form) => formOperation("UpdateCaseTransferSetupRecord", form)),
        ).subscribe()
    }),
    action("LEGAL_CONTRACT_DELETE_TRANSFER_RECORD", (obs$, lens, setState, getState) => {
        obs$.pipe(
            switchMap((form) => formOperation("DeleteCaseTransferSetupRecord", form)),
            filter(({status}) => status === "success"),
            $loadSection(lens, setState, getState)
        ).subscribe()
    })
];

const $loadSection = <T>(lens: TStateLens, setState: TCodecSetState, getState: TGetCodecState) => 
    (obs$: Observable<T>) =>
        obs$.pipe(
            mergeMap(() => formOperation("GetCaseContractSection", lens.contracts_block.get()(getState()))),
            tap((response) => setState(lens.contracts_block.set(response))),
        )
;