import React from "react";

type TTextLinkProps = {
    onClick: () => void;
};

export const FrontTextLink = (props: React.PropsWithChildren<TTextLinkProps>): JSX.Element => {

    return (
        <div
            className={`front-text-link`}
            onClick={props.onClick}
        >
            {props.children}
        </div>
    );
};
