import React from "react";
import { TClientCompanyPartnershipForm } from "../../../../../domain/codecs/form/ClientCompanyPartnershipForm";
import { CRMColors } from "../../../models/CRMColors";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { BorderBetween } from "../../BuildingBlocks/BorderBetween";
import { SpacingColumn } from "../../BuildingBlocks/SpacingColumn";
import { CRMParagraph } from "../../Simple/CRMParagraph/CRMParagraph";
import { TextColour } from "../../TextColour/TextColour";
import { WeightBold } from "../../WeightBold/WeightBold";
import { WeightMedium } from "../../WeightMedium/WeightMedium";

export const CRMCompanyPartnerAMLAndKYC = (props: React.PropsWithChildren<{
    form: TClientCompanyPartnershipForm
}>): JSX.Element => {
    const questions: Array<{question: string, answer: "Yes" | "No" | "Not answered"}> = [
        {
            question: "Is this company listed on ANY stock exchange?",
            answer:
                props.form.original.sub_type_listing_on_any_stock_exchange === true ? "Yes"
                : props.form.original.sub_type_listing_on_any_stock_exchange === false ? "No"
                : "Not answered",
        },
        {
            question: "Is this company listed as an Alternative Investment Market (AIM) company?",
            answer:
                props.form.original.sub_type_aim_listed === true ? "Yes"
                : props.form.original.sub_type_aim_listed === false ? "No"
                : "Not answered",
        },
        {
            question: "Is this company majority owned or a consolidated subsidiary?",
            answer:
                props.form.original.sub_type_majority_owned_or_consolidated_subsidiary === true ? "Yes"
                : props.form.original.sub_type_majority_owned_or_consolidated_subsidiary === false ? "No"
                : "Not answered",
        },
        {
            question: "Is this company regulated by the Financial Conduct Authority?",
            answer:
                props.form.original.sub_type_fca_regulated === true ? "Yes"
                : props.form.original.sub_type_fca_regulated === false ? "No"
                : "Not answered",
        },
        {
            question: "Is this company a non-UK credit or financial institutions (including nominee or trustee subsidiaries)?",
            answer:
                props.form.original.sub_type_other_non_uk_credit_or_financial_institutions === true ? "Yes"
                : props.form.original.sub_type_other_non_uk_credit_or_financial_institutions === false ? "No"
                : "Not answered",
        },
        {
            question: "Is this company a UK or EEA public authorities or EU institution?",
            answer:
                props.form.original.sub_type_uk_or_eea_public_authorities === true ? "Yes"
                : props.form.original.sub_type_uk_or_eea_public_authorities === false ? "No"
                : "Not answered",
        },
        {
            question: "Has this been instructed to us by a 'Professional' (firm or individual)?",
            answer:
                props.form.original.sub_type_professionals_instructing === true ? "Yes"
                : props.form.original.sub_type_professionals_instructing === false ? "No"
                : "Not answered",
        },
        {
            question: "Is this company a Foundation?",
            answer:
                props.form.original.sub_type_foundation === true ? "Yes"
                : props.form.original.sub_type_foundation === false ? "No"
                : "Not answered",
        },
        {
            question: "Is this company a UK registered charity?",
            answer:
                props.form.original.sub_type_uk_charity === true ? "Yes"
                : props.form.original.sub_type_uk_charity === false ? "No"
                : "Not answered",
        },
        {
            question: "Is this a church or place of worship (excludes Charities)?",
            answer:
                props.form.original.sub_type_place_of_worship === true ? "Yes"
                : props.form.original.sub_type_place_of_worship === false ? "No"
                : "Not answered",
        },
        {
            question: "Is this a state school or college?",
            answer:
                props.form.original.sub_type_state_school_or_college === true ? "Yes"
                : props.form.original.sub_type_state_school_or_college === false ? "No"
                : "Not answered",
        },
        {
            question: "Is this a club or association?",
            answer:
                props.form.original.sub_type_club_or_association === true ? "Yes"
                : props.form.original.sub_type_club_or_association === false ? "No"
                : "Not answered",
        },
        {
            question: "Is the main product of this company a pension scheme?",
            answer:
                props.form.original.sub_type_pension_scheme === true ? "Yes"
                : props.form.original.sub_type_pension_scheme === false ? "No"
                : "Not answered",
        },
        {
            question: "Is this company related to a sovereign wealth fund?",
            answer:
                props.form.original.sub_type_sovereign_wealth_funds === true ? "Yes"
                : props.form.original.sub_type_sovereign_wealth_funds === false ? "No"
                : "Not answered",
        },
    ];

    return <div>
        {questions.map(({question, answer}, i) =>
            <BorderBetween
                key={i}
                borderColour={CRMColors.NEUTRAL_10}
                spacing={CRMSpacing.MEDIUM}
            >
                <SpacingColumn spacing={CRMSpacing.TINY}>
                    {/* QUESTION */}
                    <CRMParagraph>
                        <WeightMedium>
                            <TextColour colour={CRMColors.NEUTRAL_0}>
                                {question}
                            </TextColour>
                        </WeightMedium>
                    </CRMParagraph>

                    {/* ANSWER */}
                    <CRMParagraph>
                        {(answer === "Yes" || answer === "No") &&
                            <WeightBold>
                                <TextColour colour={CRMColors.NEUTRAL_0}>
                                    {answer}
                                </TextColour>
                            </WeightBold>
                        }

                        {answer === "Not answered" &&
                            <WeightBold>
                                <TextColour colour={CRMColors.NEUTRAL_0}>
                                    {answer}
                                </TextColour>
                            </WeightBold>
                        }
                    </CRMParagraph>
                </SpacingColumn>
            </BorderBetween>
        )}
    </div>
};
