import React, { useState } from "react";
import { TListingFull1Editable } from "../../../../domain/codecs/formEditable/ListingEditable";
import { TListingCRMReadOnlyData1 } from "../../../../domain/codecs/Listing";
import { TUserWithValidLegalNames } from "../../../../domain/codecs/User";
import { CRMCardOutsidePopupBasic } from "../CRMCardOutsidePopupBasic/CRMCardOutsidePopupBasic";
import { CRMLink } from "../CRMLink/CRMLink";
import { CRMParagraph } from "../Simple/CRMParagraph/CRMParagraph";
import { CRMSpacingColumn } from "../CRMSpacingColumn/CRMSpacingColumn";
import { CRMTextHighlight } from "../CRMTextHighlight/CRMTextHighlight";
import { WeightBold } from "../WeightBold/WeightBold";
import { pipe } from "fp-ts/lib/function";
import { array } from "fp-ts";
import * as TCurrency from "../../../../domain/models/TCurrency";

type TCRMListingProgressionKickOffLinksProps = {
    listingForm: TListingFull1Editable;
    listingReadOnly: TListingCRMReadOnlyData1;
};

export const CRMListingProgressionKickOffLinks = (props: TCRMListingProgressionKickOffLinksProps): JSX.Element => {
    const [buyerSolicitorPopupIsOpen, setBuyerSolicitorPopupIsOpen] = useState<boolean>(false);
    const [sellerSolicitorPopupIsOpen, setSellerSolicitorPopupIsOpen] = useState<boolean>(false);
    const [mortgageBrokerPopupIsOpen, setMortgageBrokerPopupIsOpen] = useState<boolean>(false);

    const isProbate = props.listingForm.edited.reason_for_sale === "probate";
    const gopDocumentExists = props.listingReadOnly.documents.find((docs) => docs.input.document_type === "grant_of_probate") !== undefined ? true : false;
    const buyerPaymentMethod = props.listingReadOnly.buyer_payment_method;
    const numberOfBuyerUsers = props.listingReadOnly.buyer_users.length;

    const titleRegisterExists = props.listingReadOnly.documents.find((docs) => docs.input.document_type === "title_register") !== undefined ? true : false;
    const leaseholdTitleRegisterExists = props.listingReadOnly.documents.find((docs) => docs.input.document_type === "leasehold_title_register") !== undefined ? true : false;
    const isUnregistered = props.listingForm.edited.title_deed_number === ""
        && !titleRegisterExists
        && !leaseholdTitleRegisterExists
    
    const getBulletPointString = () => "%E2%80%A2";
        
    const getSpacesString = (numberOfSpaces: number) => {
        const space = "%20";
        let spaces: Array<string> = [space];
        for (let i = 1; i < numberOfSpaces; i++) {
            spaces.push(space);
        };
        return spaces.join("");
    }

    const getTabString = (numberOfTabs: number) => {
        const tab = getSpacesString(8);
        let tabs: Array<string> = [tab];
        for (let i = 1; i < numberOfTabs; i++) {
            tabs.push(tab);
        };
        return tabs.join("");
    };

    const getTabThenSpacesString = () =>
        `${getTabString(1)}${getSpacesString(2)} `

    const getTabThenBulletPointString = (numberOfTabs: number) =>
        `${getTabString(numberOfTabs)}${getBulletPointString()} `

    const getLineBreaksString = (numberOfBreaks: number) => {
        let lineBreaks: Array<string> = ["%0D%0A"];
        for (let i = 1; i < numberOfBreaks; i++ ) {
            lineBreaks.push("%0D%0A");
        };
        return lineBreaks.join("");
    };

    const getProbateDeadlineBulletString = () => {
        const stringStart = `${getTabThenBulletPointString(1)}The exchange deadline is `;
        let deadlineString = ""
        const stringEnd = `. Anything outside this timeline will need approval from the executor.${getLineBreaksString(1)}`;
        if (gopDocumentExists && buyerPaymentMethod === "mortgage") {
            deadlineString = "28 days from the acceptance of the offer"
        }
        if (gopDocumentExists && buyerPaymentMethod === "cash") {
            deadlineString = "14 days from the acceptance of the offer"
        }
        if (!gopDocumentExists) {
            deadlineString = "14 days from the grant being received"
        }
        return `${stringStart}${deadlineString}${stringEnd}`;
    };

    const getUseOurSearchesBulletString = () => 
        getTabThenBulletPointString(1)
        + "You use the searches provided by us. Your client will be covered under the personal search insurance. "
        + getLineBreaksString(1) + getTabThenSpacesString()
        + "If they expire before completion, we will provide a search indemnity at our expense. "
        + getLineBreaksString(1)

    const getRaiseEnquiriesBeforeGrantBulletString = () => 
        getTabThenBulletPointString(1)
        + "You will raise enquiries before the grant arrives. "
        + getLineBreaksString(1)

    const getRegisterPropertyBulletString = () =>
        getTabThenBulletPointString(1)
        + "You will register the property as part of your standard completion process. "
        + "Our client has not instructed us to register before the sale. "
        + getLineBreaksString(1) + getTabThenSpacesString()
        + "We’ll provide you with the epitome of the title and assist you with any related requisitions. "

    const getEmailBullets = () => {
        let bullets: Array<string> = [];
        
        if (isProbate) {
            bullets.push(getProbateDeadlineBulletString())
        };
        
        bullets.push(getUseOurSearchesBulletString())
        
        if (!gopDocumentExists) {
            bullets.push(getRaiseEnquiriesBeforeGrantBulletString())
        };
        
        if (isUnregistered) {
            bullets.push(getRegisterPropertyBulletString())
        };
        return bullets.join("");
    }

    const getEmailTemplateString = (mailToAddress: string | null, subject: string, name: string, sections: Array<string>) => {
        const mailTo = `mailto:${mailToAddress}`;
        const subj = `?subject=${subject}`;
        const bodyStart = `&body=For the attention of: ${name},${getLineBreaksString(2)}`;
        return mailTo + subj + bodyStart + sections.join("");
    };

    const getBuyerSolicitorEmailHref = () => getEmailTemplateString(
        props.listingReadOnly.buyer_conveyancer_email_address,
        props.listingReadOnly.address_short_string,
        props.listingReadOnly.buyer_conveyancer_name,
        [
            `Please find attached the Memorandum of Sale for the purchase of:${getLineBreaksString(1)}${props.listingReadOnly.address_short_string}${getLineBreaksString(2)}`,
            `Your client${numberOfBuyerUsers > 1 ? "s’" : "’s"} offer has been accepted, conditional to the following:${getLineBreaksString(1)}`,
            `${getEmailBullets()}${getLineBreaksString(2)}`,
            `${props.listingForm.edited.seller_conveyancer_organisation_name} will send you draft contracts and official copies, as well as these documents too, but I wanted to share them with you directly so you can get the ball rolling.${getLineBreaksString(2)}`,
            `Included for you:${getLineBreaksString(2)}`,
            `Can you please send me a quick response to confirm that you have received these?${getLineBreaksString(2)}`,
            `Any questions, let me know.`
        ]
    );

    const getSellerSolicitorMailLink = (): string =>
        `mailto:${props.listingForm.edited.seller_conveyancer_email_address}?subject=${props.listingReadOnly.address_short_string}&body=Hi ${props.listingForm.edited.seller_conveyancer_name},${getLineBreaksString(2)}Whilst marketing the property, we have completed some preparation work that I hope will help make the sale of ${props.listingReadOnly.address_short_string} far smoother.${getLineBreaksString(2)}Included for you:${getLineBreaksString(2)}If there is anything else I can do to help, please let me know.`

    const joinUserNames = (users: Array<TUserWithValidLegalNames>): string =>
        pipe(
            users,
            array.map((user) => [
                ...user.homes_first_legal_name ? [user.homes_first_legal_name] : [],
                ...user.homes_middle_legal_name ? [user.homes_middle_legal_name] : [],
                ...user.homes_last_legal_name ? [user.homes_last_legal_name] : [],
            ].join(" ")),
            (a) => a.join(", "),
        );

    const getBuyerName = (): string =>
        props.listingReadOnly.purchasers_company_name.trim()
            ? `${props.listingReadOnly.purchasers_company_name} (${joinUserNames(props.listingReadOnly.buyer_users)})`
            : joinUserNames(props.listingReadOnly.buyer_users);

    return (
        <>
            {props.listingReadOnly.listing_has_offer_accepted === true && <CRMSpacingColumn spacing="tiny">
                <CRMLink
                    onClick={() => setBuyerSolicitorPopupIsOpen(true)}
                    href=""
                    linkStyle="normal"
                >
                    <WeightBold>Open buyer solicitor email template</WeightBold>
                </CRMLink>
                <CRMLink
                    onClick={() => setSellerSolicitorPopupIsOpen(true)}
                    href=""
                    linkStyle="normal"
                >
                    <WeightBold>Open seller solicitor email template</WeightBold>
                </CRMLink>
                {buyerPaymentMethod === "mortgage" && props.listingReadOnly.mortgage_broker_email_address && <CRMLink
                    onClick={() => setMortgageBrokerPopupIsOpen(true)}
                    href=""
                    linkStyle="normal"
                >
                    <WeightBold>Open mortgage broker email template</WeightBold>
                </CRMLink>}
                <CRMLink href={`${env.REACT_APP_API_URL}/web/listings/${props.listingForm.edited.id}/mos`} target="_blank" linkStyle="normal">
                    Memorandum of Sale
                </CRMLink>
                <CRMLink href={`${env.REACT_APP_API_URL}/web/listings/${props.listingForm.edited.id}/best-price-certificate`} target="_blank" linkStyle="normal">
                    Aventria best price certificate
                </CRMLink>
            </CRMSpacingColumn>}

            {/* Buyer solicitor popup */}
            <CRMCardOutsidePopupBasic
                isOpen={buyerSolicitorPopupIsOpen}
                title="Take care before sending"
                context="warning"
                isInfo={true}
                onClose={() => setBuyerSolicitorPopupIsOpen(false)}
                closeText="Cancel"
            >
                <CRMSpacingColumn spacing="medium">
                    <CRMParagraph>
                        <CRMTextHighlight colour="highlights-instructional-brick-8">
                            Before sending the email check you are sending it from your Sail Homes address.
                        </CRMTextHighlight>
                    </CRMParagraph>
                    <CRMLink
                        target="_blank"
                        linkStyle="normal"
                        href={getBuyerSolicitorEmailHref()}
                    >
                        <WeightBold>Open email template</WeightBold>
                    </CRMLink>
                </CRMSpacingColumn>
            </CRMCardOutsidePopupBasic>

            {/* Seller solicitor popup */}
            <CRMCardOutsidePopupBasic
                isOpen={sellerSolicitorPopupIsOpen}
                title="Take care before sending"
                context="warning"
                isInfo={true}
                onClose={() => setSellerSolicitorPopupIsOpen(false)}
                closeText="Cancel"
            >
                <CRMSpacingColumn spacing="medium">
                    <CRMParagraph>
                        <CRMTextHighlight colour="highlights-instructional-brick-8">
                            Before sending the email check you are sending it from your Sail Homes address.
                        </CRMTextHighlight>
                    </CRMParagraph>
                    <CRMLink
                        target="_blank"
                        href={getSellerSolicitorMailLink()}
                        linkStyle="normal"
                    >
                        <WeightBold>Open email template</WeightBold>
                    </CRMLink>
                </CRMSpacingColumn>
            </CRMCardOutsidePopupBasic>

            {/* Mortgage broker popup */}
            <CRMCardOutsidePopupBasic
                isOpen={mortgageBrokerPopupIsOpen}
                title="Take care before sending"
                context="warning"
                isInfo={true}
                onClose={() => setMortgageBrokerPopupIsOpen(false)}
                closeText="Cancel"
            >
                <CRMSpacingColumn spacing="medium">
                    <CRMParagraph>
                        <CRMTextHighlight colour="highlights-instructional-brick-8">
                            Before sending the email check you are sending it from your Sail Homes address.
                        </CRMTextHighlight>
                    </CRMParagraph>
                    <CRMLink
                        target="_blank"
                        linkStyle="normal"
                        href={`mailto:${props.listingReadOnly.mortgage_broker_email_address}
                        ?subject=Mortgage application can now be submitted - ${getBuyerName()}
                        &body=Hi ${props.listingReadOnly.mortgage_broker_name},${getLineBreaksString(2)}This is just a quick email to let you know that your client${numberOfBuyerUsers > 1 ? "s’" : "’s"} offer of ${TCurrency.fromNumber(props.listingReadOnly.accepted_offer_amount)} has been accepted:${getLineBreaksString(2)}Client:${getLineBreaksString(1)}${getBuyerName()}${getLineBreaksString(2)}Property:${getLineBreaksString(1)}${props.listingReadOnly.address_short_string}${getLineBreaksString(2)}A mortgage application can now be submitted if your client is happy to proceed.${getLineBreaksString(2)}Please let me know if you have any questions.`}
                    >
                        <WeightBold>Open email template</WeightBold>
                    </CRMLink>
                </CRMSpacingColumn>
            </CRMCardOutsidePopupBasic>
        </>
    );
};
