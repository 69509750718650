import React, { useEffect, useState } from "react";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { CRMPopoutHeader } from "../../Simple/CRMPopoutHeader/CRMPopoutHeader";
import CRMInputLabelAndErrorWrapComponent from "../../CRMInputLabelAndErrorWrapComponent/CRMInputLabelAndErrorWrapComponent";
import { CRMRadioListComponent } from "../../CRMInputs/CRMRadioListComponent/CRMRadioListComponent";
import { TChaseStatusType } from "../../../../../domain/codecs/form/CasesChaseTransitionForm";
import { pipe } from "fp-ts/lib/function";
import { array } from "fp-ts";
import { SpacingColumn } from "../../BuildingBlocks/SpacingColumn";
import { CRMBottomControlBar } from "../../CRMBottomControlBar/CRMBottomControlBar";
import { Background } from "../../BuildingBlocks/Background";
import { SpacingRow } from "../../BuildingBlocks/SpacingRow";
import { CRMFormButton } from "../../CRMFormButton/CRMFormButton";
import { CRMButtonPrimary } from "../../CRMButtonPrimary/CRMButtonPrimary";
import { TContainerStateProps } from "../../../state/TContainerStateProps";
import { CRMCardOutsidePopupBlank } from "../../CRMCardOutsidePopupBlank/CRMCardOutsidePopupBlank";
import { getTransactingString } from "../../../../../domain/codecs/TransactionType";
import { TChaseReasons, TChaseReasonsWithoutAwaitingOpenNewCase } from "../../../../../domain/codecs/Cases";
import { CRMChaseReasonsMultiSelect } from "../CRMChaseReasonsMultiSelect/CRMChaseReasonsMultiSelect";

export type TCloseChaseStatus = "closed" | TChaseStatusType;

type TClosedReason =
    "no_conveyancing_required"
    | "abortive_transaction"
    | "do_not_contact"
    | "unable_to_represent_client"
    | "created_in_error"
;

export const CRMCloseChaseContinueCasePopoutRequestView = (
    props: TContainerStateProps & {
        isOpen: boolean,
        onClose: () => void,
    }): JSX.Element => {
    
    const caseId = props.state.forms.case_details_page.data.output.details.children.id;
    const getSubmitToChaseModeForm = () => props.state.forms.chase_mode_form.children.submit_to_chase_mode_form;
    const getSubmitCloseAwaitingNewCaseForm = () => props.state.forms.chase_mode_form.children.submit_to_closed_awaiting_new_case_form;
    const getSubmitToClosedUnsuccessfullyForm = () => props.state.forms.chase_mode_form.children.submit_to_closed_unsuccessfully_form;
    const getSubmitToWorkableModeForm = () => props.state.forms.chase_mode_form.children.submit_to_workable_mode_form;
    const getSubmitToReopenCaseForm = () => props.state.forms.chase_mode_form.children.submit_to_reopen_form;
    const isSubmitting = getSubmitToChaseModeForm().status === "submitting"
        || getSubmitCloseAwaitingNewCaseForm().status === "submitting"
        || getSubmitToClosedUnsuccessfullyForm().status === "submitting"
        || getSubmitToWorkableModeForm().status === "submitting"
        || getSubmitToReopenCaseForm().status === "submitting";

    const initialChaseMode = props.state.forms.chase_mode_form.children.latest_chase.status;
    const caseStatus = props.state.forms.case_details_page.data.output.details.children.status;
    const transactionType = props.state.forms.case_details_page.data.output.details.children.transaction_type;
    const caseIsPreInstruction = caseStatus === "property_report" || caseStatus === "quote";
    const caseIsClosedBeforeCompletion = caseStatus === "closed_created_in_error" || caseStatus === "closed_unsuccessfully" || caseStatus === "closed_awaiting_opening_new_case" ;
    const caseIsClosed = caseIsClosedBeforeCompletion || caseStatus === "closed_successfully";

    const [newCloseChaseContinueStatus, setNewCloseChaseContinueStatus] = useState<TCloseChaseStatus | undefined>(undefined);
    const [closedReason, setClosedReason] = useState<TClosedReason | null>(null);

    useEffect(
        () => {
            if (getSubmitToChaseModeForm().status === "success") {
                props.onClose();
            }
        },
        [getSubmitToChaseModeForm().status],
    );

    useEffect(
        () => {
            if (getSubmitToChaseModeForm().status === "success") {
                props.onClose();
            }
        },
        [getSubmitToChaseModeForm().status],
    );

    useEffect(
        () => {
            if (getSubmitCloseAwaitingNewCaseForm().status === "success") {
                props.onClose();
            }
        },
        [getSubmitCloseAwaitingNewCaseForm().status],
    );

    useEffect(
        () => {
            if (getSubmitToClosedUnsuccessfullyForm().status === "success") {
                props.onClose();
            }
        },
        [getSubmitToClosedUnsuccessfullyForm().status],
    );

    useEffect(
        () => {
            if (getSubmitToWorkableModeForm().status === "success") {
                props.onClose();
            }
        },
        [getSubmitToWorkableModeForm().status],
    );

    useEffect(
        () => {
            if (getSubmitToReopenCaseForm().status === "success") {
                props.onClose();
            }
        },
        [getSubmitToReopenCaseForm().status],
    );

    useEffect(
        () => setNewCloseChaseContinueStatus(
            getCloseChaseContinueStatus(initialChaseMode, caseIsClosed)
        ),
        [initialChaseMode, caseIsClosed],
    );

    const updateChaseReasons = (value: TChaseReasonsWithoutAwaitingOpenNewCase) =>
        onChangeChaseReasons(
            getSubmitToChaseModeForm().edited.selected_chase_reasons.includes(value)
                ? pipe(
                    getSubmitToChaseModeForm().edited.selected_chase_reasons,
                    array.filter((v) => v !== value)
                )
                : [
                    ...getSubmitToChaseModeForm().edited.selected_chase_reasons,
                    value,
                ]
        );

    const anyReasonsChecked = (obj: "chase" | "close") =>
        obj === "chase"
            ? !! getSubmitToChaseModeForm().edited.selected_chase_reasons.length
            : !! closedReason;
   
    const formIsReadyToSign = () => {
        let ready = false;

        // putting into chase mode and any chase_reasons exist
        if (newCloseChaseContinueStatus === "chase" && anyReasonsChecked("chase")) {
            ready = true;
        };
        
        // selected option to close the case and any close reasons exist
        if (newCloseChaseContinueStatus === "closed" && anyReasonsChecked("close")) {
            ready = true;
        };

        // going from chase/closed to workable
        if (newCloseChaseContinueStatus === "workable") {
            ready = true;
        };

        if (isSubmitting) {
            ready = true;
        }
        
        return ready;
    };

    const resetAllCheckboxesToFalse = () => {
        onChangeChaseReasons([]);
        setClosedReason(null);
    };

    const onChangeCloseChaseContinue = (val: TCloseChaseStatus) => {
        setNewCloseChaseContinueStatus(val);
        resetAllCheckboxesToFalse()
    };

    const onClose = () => {
        props.onClose()
        setNewCloseChaseContinueStatus(undefined);
    };

    const getCloseReasonsOptions = (): Array<{value: TClosedReason, text: string}> => [
        {
            value: "abortive_transaction",
            text: `This transaction fell through`,
        },
        {
            value: "no_conveyancing_required",
            text: `Not ${getTransactingString(transactionType)} at all anymore`,
        },
        {
            value: "unable_to_represent_client",
            text: "We're unable to act for them",
        },
        {
            value: "do_not_contact",
            text: `They ${caseIsPreInstruction ? "don't" : "no longer"} want to use Sail Legal`,
        },
        {
            value: "created_in_error",
            text: "Created/opened case by mistake",
        }
    ];

    const onChangeChaseReasons = (selected_chase_reasons: Array<TChaseReasons>) =>
        props.dispatch({
            type: "LEGAL_CASE_TO_CHASE_MODE_FORM_CHANGE",
            payload: {
                ...getSubmitToChaseModeForm(),
                edited: {
                    id: caseId,
                    selected_chase_reasons,
                }
            }
        });

    const onSubmit = (toStatus: TCloseChaseStatus) => {
        if (toStatus === "chase") {
            props.dispatch({
                type: "LEGAL_CASE_TO_CHASE_MODE_FORM_SUBMIT",
            });
        };

        if (toStatus === "workable" && !caseIsClosedBeforeCompletion) {
            props.dispatch({
                type: "LEGAL_CASE_TO_WORKABLE_MODE_FORM_SUBMIT",
                payload: undefined,
            });
        };

        // reopen case
        if (toStatus === "workable" && caseIsClosedBeforeCompletion) {
            props.dispatch({
                type: "LEGAL_CASE_REOPEN_CASE_FORM_SUBMIT",
                payload: undefined,
            });
        };

        // closed - created in error
        if (toStatus === "closed" && closedReason === "created_in_error") {
            props.dispatch({
                type: "LEGAL_CASE_CHASE_MODE_CLOSE_CASE_OPENED_IN_ERROR_FORM_SUBMIT",
                payload: {
                    ...getSubmitToClosedUnsuccessfullyForm(),
                    edited: {
                        id: caseId
                    }
                }
            });
        };

        // closed - aborted but no chase
        if (
            toStatus === "closed"
            && (
                closedReason === "do_not_contact"
                || closedReason === "no_conveyancing_required"
                || closedReason === "unable_to_represent_client"
            )
        ) {
            props.dispatch({
                type: "LEGAL_CASE_CHASE_MODE_CLOSE_CASE_UNCCESSFULLY_FORM_SUBMIT",
                payload: {
                    ...getSubmitToClosedUnsuccessfullyForm(),
                    edited: {
                        id: caseId,
                    }
                }
            });
        };

        // closed - abortive transaction so await opening new case
        if (
            toStatus === "closed"
            && closedReason === "abortive_transaction"
        ) {
            props.dispatch({
                type: "LEGAL_CASE_CHASE_MODE_CLOSE_AWAITING_NEW_CASE_FORM_SUBMIT",
                payload: {
                    ...getSubmitCloseAwaitingNewCaseForm(),
                    edited: {
                        id: caseId,
                    }
                }
            });
        };
    };

    const getHeaderText = () => {
        let text = "";

        if (initialChaseMode === "workable" || initialChaseMode === "workable_rejected_chase_request") {
            text = "Activate Chase Mode or Close Case"
        };

        if (initialChaseMode === "chase") {
            text = "Deactivate Chase Mode"
        }

        if (caseIsClosedBeforeCompletion) {
            text = "Reopen this Case"
        };
        
        return text;
    };

    const getCloseChaseContinueStatusOption = (value: TCloseChaseStatus, text: string): {value: TCloseChaseStatus, text: string} => ({
        value,
        text
    });

    const getCloseChaseContinueOptions = (): Array<{value: TCloseChaseStatus, text: string}> => {
        const chaseOnOption = getCloseChaseContinueStatusOption("chase", "Turn on Chase Mode");
        const chaseOffOption = getCloseChaseContinueStatusOption("workable", "Continue with Case");
        const closeOption = getCloseChaseContinueStatusOption("closed", "Close the Case");

        const cccOptions = [
            chaseOnOption,
            chaseOffOption,
            closeOption
        ]
        if (initialChaseMode === "chase") {
            return [
                chaseOnOption,
                getCloseChaseContinueStatusOption("workable", "Turn off Chase Mode"),
            ]
        };

        if (caseIsClosedBeforeCompletion) {
            return [
                getCloseChaseContinueStatusOption("workable", "Reopen the case"),
                closeOption
            ]
        } else {
            return cccOptions
        };
    };

    const getCloseChaseContinueStatus = (chase: TChaseStatusType, isClosed: boolean) => {
        if (chase === "chase") {
            return "chase"
        } else if (isClosed) {
            return "closed"
        } else {
            return "workable"
        }
    };
    
    return (
        <CRMCardOutsidePopupBlank
            isOpen={props.isOpen}
            context="none"
            size="medium"
            borderRadius="5px"
            cardMargin="78px 0px"
            onClose={onClose}
        >
            <CRMPopoutHeader
                icon="arrow-left"
                borderRadius="5px"
                onClick={onClose}
            >
                {getHeaderText()}
            </CRMPopoutHeader>

            <SpacingColumn spacing={CRMSpacing.TINY}>
                <Background padding={CRMSpacing.LARGE} minHeight="100px">
                    <SpacingColumn spacing={CRMSpacing.MEDIUM}>
                        <CRMInputLabelAndErrorWrapComponent
                            label="What would you like to do?"
                        >
                            <CRMRadioListComponent<TCloseChaseStatus, TCloseChaseStatus>
                                value={newCloseChaseContinueStatus || getCloseChaseContinueStatus(initialChaseMode, caseIsClosed)}
                                direction="column"
                                options={getCloseChaseContinueOptions()}
                                onChange={(val) => onChangeCloseChaseContinue(val)}
                                disabled={caseStatus === "closed_successfully"}
                            />
                        </CRMInputLabelAndErrorWrapComponent>

                        {/* CHASE MODE */}
                        {newCloseChaseContinueStatus === "chase"
                            && <CRMChaseReasonsMultiSelect
                                multiSelectLabel="Because:"
                                availableChaseReasons={props.state.forms.chase_mode_form.children.available_chase_reasons}
                                selectedChaseReasons={props.state.forms.chase_mode_form.children.submit_to_chase_mode_form.edited.selected_chase_reasons}
                                updateChaseReason={updateChaseReasons}
                            />
                        }

                        {/* CLOSE CASE */}
                        {newCloseChaseContinueStatus === "closed"
                            && <CRMInputLabelAndErrorWrapComponent
                                label={`Because:`}
                                displayError={false}
                            >
                                <CRMRadioListComponent
                                    value={closedReason}
                                    direction="column"
                                    options={getCloseReasonsOptions()}
                                    onChange={setClosedReason}
                                />
                            </CRMInputLabelAndErrorWrapComponent>
                        }
                    </SpacingColumn>
                </Background>
                
                {/* SUBMIT / UPDATE */}
                <CRMBottomControlBar>
                    <SpacingColumn>
                        <Background
                            width="100%"
                            padding={`${CRMSpacing.TINY} ${CRMSpacing.MEDIUM}`}
                        >
                            {newCloseChaseContinueStatus === "closed" &&
                                // CLOSING - CREATED IN ERROR
                                <SpacingRow justifyContent="end">
                                    <CRMFormButton
                                        ButtonElement={CRMButtonPrimary}
                                        formStatus={getSubmitToClosedUnsuccessfullyForm().status}
                                        label="Submit"
                                        disabled={
                                            isSubmitting
                                            || !formIsReadyToSign()
                                        }
                                        onClick={() => onSubmit("closed")}
                                    />
                                </SpacingRow>
                            }

                            {newCloseChaseContinueStatus === "chase" &&
                                // TRANSITIONING INTO CHASE MODE
                                <SpacingRow justifyContent="end">
                                    <CRMFormButton
                                        ButtonElement={CRMButtonPrimary}
                                        formStatus={getSubmitToChaseModeForm().status}
                                        label="Submit"
                                        disabled={
                                            isSubmitting
                                            || !formIsReadyToSign()
                                        }
                                        onClick={() => onSubmit("chase")}
                                    />
                                </SpacingRow>
                            }

                            {newCloseChaseContinueStatus === "workable" && caseIsClosedBeforeCompletion &&
                                // TRANSITIONING INTO CHASE MODE
                                <SpacingRow justifyContent="end">
                                    <CRMFormButton
                                        ButtonElement={CRMButtonPrimary}
                                        formStatus={getSubmitToReopenCaseForm().status}
                                        label="Submit"
                                        disabled={
                                            isSubmitting
                                            || !formIsReadyToSign()
                                        }
                                        onClick={() => onSubmit("workable")}
                                    />
                                </SpacingRow>
                            }

                            {newCloseChaseContinueStatus === "workable" && !caseIsClosedBeforeCompletion &&
                                // TRANSITIONING INTO CHASE MODE
                                <SpacingRow justifyContent="end">
                                    <CRMFormButton
                                        ButtonElement={CRMButtonPrimary}
                                        formStatus={getSubmitToChaseModeForm().status}
                                        label="Submit"
                                        disabled={
                                            isSubmitting
                                            || !formIsReadyToSign()
                                        }
                                        onClick={() => onSubmit("workable")}
                                    />
                                </SpacingRow>
                            }
                        </Background>
                    </SpacingColumn>
                </CRMBottomControlBar>
            </SpacingColumn>
        </CRMCardOutsidePopupBlank>
    );
};
