import React from "react";
import { CRMSpacer } from "../CRMSpacer/CRMSpacer";
import { CRMCardOutside } from "../CRMCardOutside/CRMCardOutside";
import { CRMButtonIcon } from "../CRMButtonIcon/CRMButtonIcon";
import { CRMParagraph } from "../Simple/CRMParagraph/CRMParagraph";
import { CRMPadding } from "../Simple/CRMPadding/CRMPadding";
import { CRMFontSizes } from "../../models/CRMFontSizes";
import { FontQuicksand } from "../BuildingBlocks/FontQuicksand";
import { FontSize } from "../BuildingBlocks/FontSize";
import { WeightBold } from "../WeightBold/WeightBold";
import { CRMIconPopout } from "../CRMIconPopout/CRMIconPopout";

type TCRMReportDetailsCardProps = {
    title: string,
    description: string,
    link: string,
    helpText: string | null,
};

export const CRMReportDetailsCard = (props: TCRMReportDetailsCardProps): JSX.Element => {

    return (
        <CRMCardOutside
            shadow={false}
            borderRounding="none"
        >
            <CRMPadding size="medium">
                {/* Report Title */}
                <FontQuicksand>
                    <FontSize size={CRMFontSizes.MED}>
                        <WeightBold>
                            {props.title}
                        </WeightBold>
                    </FontSize>
                </FontQuicksand>
                <CRMSpacer size="medium" />

                {/* Description Here */}
                <CRMParagraph>
                    {props.description}
                </CRMParagraph>
                {props.helpText !== null && 
                    <CRMIconPopout
                        iconProps={{
                            iconName: "question-in-circle",
                            size: "medium",
                            colour: "neutral-ink",
                        }}
                        popoutOpenDirection="right"
                        maxWidth="600px"
                    >
                        <CRMParagraph><CRMPadding size="tiny">{props.helpText}</CRMPadding></CRMParagraph>
                    </CRMIconPopout>
                }
            </CRMPadding>
            <div className="crm-report-details-card__lower-box">
                <CRMPadding size="tiny">
                    <CRMButtonIcon icon="can-download" href={props.link} download={true} variant="quaternary"/>
                </CRMPadding>
            </div>
            
        </CRMCardOutside>
    );
};
