import React, { useState } from "react";
import { CRMPopupShadowBox } from "../CRMPopupShadowBox/CRMPopupShadowBox";
import { Background } from "../../BuildingBlocks/Background";
import { CRMColors } from "../../../models/CRMColors";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { SpacingColumn } from "../../BuildingBlocks/SpacingColumn";
import { CRMParagraph } from "../CRMParagraph/CRMParagraph";
import { CRMFontSizes } from "../../../models/CRMFontSizes";
import { Flex } from "../../BuildingBlocks/Flex";
import { WeightSemiBold } from "../../WeightSemiBold/WeightSemiBold";
import { WeightMedium } from "../../WeightMedium/WeightMedium";
import { CRMButtonPrimary } from "../../CRMButtonPrimary/CRMButtonPrimary";
import { MaxWidth } from "../../BuildingBlocks/MaxWidth";
import { FontSize } from "../../BuildingBlocks/FontSize";
import { TextAlign } from "../../BuildingBlocks/TextAlign";
import { SpacingRow } from "../../BuildingBlocks/SpacingRow";
import { CRMButtonQuaternary } from "../../CRMButtonQuaternary/CRMButtonQuaternary";

export const CRMKPIs = (props: {
    isOpen: boolean,
    onClose: () => void,
}): JSX.Element => {
    const [continueToKPISClicked, setContinueToKPISClicked] = useState<boolean>(false);

    const view =
        continueToKPISClicked ? "kpis"
            : "initial";

    const motivationQuotes = [
        "Remember, success is just a nap away. Take more breaks; we're counting on you!",
        "Don't worry about deadlines; they're just suggestions from people who don't understand your creative process.",
        "Failure is just success in disguise. Embrace the disguise - it's very fashionable this season.",
        "If you're not confused, you're not working here. Keep up the chaos, my friend!",
        "Procrastination is the key to innovation. Take your time, we're in no rush... mostly.",
        "They say time is money, but who needs money when you've got a really good time?",
        "Our company motto: 'Why be productive today when you can be exceptional tomorrow?'",
        "Remember, the early bird may get the worm, but the second mouse gets the cheese. Pace yourself.",
        "Success is a journey, not a destination. So, take a scenic route and enjoy the detours!",
        "If at first, you don't succeed, try, try again. Or just give up and have some ice cream - it's a win-win situation.",
        "The only thing standing between you and success is the fact that you're sitting down.",
        "Mondays are just nature's way of punishing you for having fun on the weekend.",
        "Confidence is the feeling you have before you understand the situation. So, stay confidently clueless!",
        "The early worm gets eaten by the bird, so sleep in and be a late worm. Safety first!",
        "Success is 10% inspiration and 90% procrastination. You're doing great!",
        "The more you fail, the higher your success rate of impressing others with your resilience.",
        "Why do today what you can put off until someone else does it for you tomorrow?",
        "Always remember: you're not lazy; you're just on energy-saving mode.",
        "Efficiency is doing better what shouldn't be done at all. Keep up the excellent inefficiency!",
        "When life gives you lemons, make sure to find someone to squeeze them for you. Delegation is key!",
        "Aim for the stars, but remember, even astronauts have to come back to Earth to file their reports.",
        "The only exercise some of us get is jumping to conclusions. Keep that mental agility!",
        "Success is like a fart; it's only truly satisfying when it's your own.",
        "If you're going to rise, you might as well shine. Or just take a nap; it's your call.",
        "Dream big, work small - that way, you'll never run out of dreams!",
        "Remember, the early bird might get the worm, but the second mouse gets the cheese. Choose your role model wisely.",
        "Pro tip: coffee might not solve all your problems, but it's worth a shot. And a second shot.",
        "If life gives you lemons, make lemonade. If it gives you tomatoes, make Bloody Marys. Adaptability is key.",
        "Success is 1% inspiration, 99% coffee. Keep brewing greatness!",
        "The best way to appreciate your job is to imagine yourself without one. Then imagine yourself with a better one.",
        "You're not lazy; you're just in energy-saving mode. Think of it as eco-friendly productivity.",
        "Success is like a fart; it's only worthwhile if it's your own. Own your success, my friend!",
        "Remember, it's not about the destination; it's about the journey. Especially if the journey involves snacks.",
        "Procrastination is the art of keeping up with yesterday. You're a true artist!",
        "Failure is the foundation of success. So, you're basically building a skyscraper of success right now.",
        "If at first, you don't succeed, redefine success. It's all about perspective!",
        "The early bird gets the worm, but the second mouse gets the cheese. Choose your battles wisely.",
        "Success is not the key to happiness. Happiness is the key to avoiding Monday blues.",
        "The road to success is always under construction. So, take the detours, they're more interesting.",
        "If you're not making mistakes, you're not trying hard enough. Keep up the excellent effort!",
        "Success is like a squirrel; it's more elusive if you chase it. Just be a tree and let it come to you.",
        "Don't count the days; make the days count. Especially the ones where you do absolutely nothing.",
        "They say hard work pays off in the future. So, procrastinate now and let your future self handle it.",
        "The early bird catches the worm, but the second mouse gets the cheese. Be the second mouse - cheese is delicious.",
        "Success is like a cat; it'll come to you when it's ready. Until then, pretend not to care and check your emails.",
        "The best things in life are free. Like the coffee in the break room. Take advantage of the perks!",
        "If at first, you don't succeed, redefine success. It's all about perspective. And a touch of denial.",
        "The only thing standing between you and success is the fact that you're sitting down. Stand up - for coffee.",
        "Success is not the key to happiness. Happiness is the key to avoiding Monday blues. So, stay happy, my friend!",
        "Aim to be the conveyancer who knows their leaseholds from their freeholds like they know their coffee orders. Precision and caffeine: the keys to success.",
        "In conveyancing, success is 1% inspiration, 99% not getting lost in the Land Registry. Navigate with confidence, and always bring a compass.",
        "They say Rome wasn't built in a day, but they clearly didn't have a conveyancer on the team. We'd have sorted the paperwork in hours.",
        "Be like a good property survey: thorough, accurate, and slightly judgmental about damp issues.",
        "To succeed in conveyancing, remember the three Ps: Patience, Precision, and a hefty supply of Post-its. Never underestimate the power of a good Post-it.",
        "Our job is like a game of Monopoly, but with real money and slightly less arguing over who gets to be the top hat.",
        "In the world of conveyancing, always strive to be the kind of person your dog thinks you are: reliable, hardworking, and always ready for a walk around the block for a break.",
        "The golden rule of conveyancing: Treat every client like they're your grandmother. Explain everything patiently, and always offer biscuits.",
        "In conveyancing, being able to read fine print isn't just a skill; it's a superpower. Flex those reading glasses like the hero you are.",
        "Remember, in the grand story of a property, you're the pen that writes the happy ending. Just make sure you don't run out of ink.",
        "Our profession is all about moving forward. If you're not advancing, check if you've accidentally stapled yourself to a file again.",
        "To be a great conveyancer, you must be part detective, part psychic, and part caffeine-addict. It's about balance.",
        "Aim to be the conveyancer that even the most confused first-time buyer trusts. Be their guide, their guru, and their go-to for all things property.",
        "Keep calm and conveyance on. And when that fails, remember: chocolate and deep breaths can fix almost anything, except maybe a broken chain.",
        "In this game, the early bird catches the worm, but the smart conveyancer catches the early mistakes. Be both, and you're golden.",
        "Success in conveyancing is like a good sandwich: It's all about the fillings. Fill your transactions with efficiency, accuracy, and a dash of humor.",
        "Let's be the conveyancers who are known for turning mountains into molehills, complex transactions into walks in the park, and, most importantly, coffee into contracts.",
        "In conveyancing, we don't just transfer deeds; we transfer dreams. And sometimes, nightmares, but mostly dreams.",
        "Remember, the difference between a smooth conveyancing process and a rocky one is often just how you handle the boulders. And a hard hat. Always wear a hard hat.",
        "To be successful in conveyancing, you need the tenacity of a terrier, the wisdom of an owl, and the ability to survive on snack foods during long closings.",
        "Be the kind of conveyancer that when your feet hit the floor each morning, the Land Registry trembles.",
        "In the world of property, be less like a haunted house—full of surprises and screams—and more like a welcoming cottage, warm and inviting.",
        "Aim to make your client meetings like a good cup of tea: comforting, warm, and capable of solving most problems.",
        "Remember, in conveyancing, every cloud has a silver lining. Except for lost title deeds. That's just rain.",
        "In conveyancing, always be as enthusiastic about your hundredth property transaction as you were about your first. Just with less surprise at how complicated it is.",
        "Let's make our conveyancing firm like a good sitcom: full of quirky characters, unexpected plot twists, and happy endings.",
        "Our goal: To make the conveyancing process smoother than a freshly resurfaced road. Minus the traffic cones and detours.",
        "In conveyancing, being proactive is key. It's like chess; always be three moves ahead, and if all else fails, knock over the board.",
        "To excel in conveyancing, one must master the art of appearing calm on the surface while paddling like mad underneath.",
        "In the realm of conveyancing, let's not just pass the baton. Let's make sure it's gold-plated, engraved, and delivered with a bow.",
        "Conveyancing: where 'completion day' is more exciting than Christmas, but with more paperwork and less tinsel.",
        "Aim to be so good at conveyancing that even the properties want to thank you for bringing them together with their new owners.",
        "In the world of conveyancing, always strive to be the beacon of hope in the fog of legal jargon and paperwork.",
        "Let's handle every conveyancing case like we're defusing a bomb: with precision, calm, and a steady hand. And maybe a bomb disposal suit, just in case.",
        "To be a star in conveyancing, you don't need to change the world. Just change the address of one happy family at a time.",
        "In conveyancing, some days you're the pigeon, and some days you're the statue. Aim to always be the one flying.",
        "A good conveyancer is like a good gardener; we plant the seeds (of deals), nurture them (through the process), and occasionally deal with pests (unexpected complications).",
        "To thrive in conveyancing, one must possess the patience of a saint, the focus of a monk, and the caffeine tolerance of a college student during finals week.",
        "In the symphony of conveyancing, be the conductor, not just a player. And if the orchestra gets too chaotic, remember: there's always the gavel.",
        "They say patience is a virtue. In conveyancing, it's not just a virtue; it's a survival skill. And possibly a superpower.",
        "Be the conveyancer who's smoother than a freshly printed deed. Just make sure you're also more legible.",
        "In conveyancing, every day is a new episode in a soap opera. Be ready for drama, but always aim for a happy ending.",
        "Remember, a conveyancer's job is to turn 'I wish' into 'I own.' It's like being a fairy godparent, but with more paperwork and fewer wands.",
        "Navigating a conveyancing deal is like doing a dance. Sometimes it's a waltz, sometimes it's the tango, and occasionally, it's just trying not to step on anyone's toes.",
        "In the grand kitchen of conveyancing, we're the chefs. The recipe is complex, the heat is on, but the end dish is always worth it. Just mind the boiling points.",
        "A conveyancer's to-do list: 1. Solve problems. 2. Juggle deadlines. 3. Find missing documents. 4. Save the day. 5. Repeat.",
        "The golden rule of conveyancing: Treat each case as if it were your own. Except when it comes to decorating choices. Those, you can silently judge.",
        "Conveyancing: Where you're part lawyer, part therapist, and part magician.",
        "To be a top conveyancer, you must be as sturdy as the houses you transfer. And as welcoming as their new front doors.",
        "A conveyancer's motto: 'Keep calm and carry on... until completion.' Then, you can breathe. And maybe sleep.",
        "In conveyancing, the thrill of the chase is real. Especially when you're chasing signatures, documents, and the occasional reluctant lender.",
        "Being a great conveyancer is like being a great chef: It's not just about following the recipe, it's about adding your own flair. And making sure nothing burns.",
        "Remember, in conveyancing, every block in the road is just a stepping stone to success. Or at least, a good story for the office.",
        "Conveyancing: Where 'completion' is the goal, 'exchange' is the climax, and 'enquiries' are the plot twists. Who said legal work isn't exciting?",
        "The life of a conveyancer: Part superhero, part wizard, all human. With a little bit of coffee thrown in for good measure.",
        "In conveyancing, be like a good wine: Get better with age, improve with each transaction, and always be ready for celebration.",
        "A conveyancer's job: To find the calm in the chaos, the order in the disorder, and the keys in the sea of paperwork.",
        "Remember, every conveyancing file is a story. Your job is to give it a happy ending, preferably with less drama than a Shakespearean play.",
    ];

    const randomMotivationQuote =
        motivationQuotes.sort(() => Math.random() - 0.5)[0];

    const ctaLabels = [
        "I was born ready",
        "Show me the KPIs",
        "Let's go",
        "Is the sky blue?",
        "Go go go...",
    ];

    const randomCTALabel =
        ctaLabels.sort(() => Math.random() - 0.5)[0];

    return <CRMPopupShadowBox
        isOpen={props.isOpen}
        onClose={props.onClose}
    >
        <Background padding={`${CRMSpacing.LARGE} 0px 0px 0px`}>
            <Background
                color={CRMColors.NEUTRAL_PAPER}
                borderRadius="8px"
                padding={CRMSpacing.LARGE}
                width="70vw"
                height="90vh"
            >
                {/* VIEW - INITIAL */}
                {view === "initial" &&
                    <SpacingColumn
                        height="100%"
                        childSize="1fr min-content"
                    >
                        <Flex
                            justifyContent="center"
                            alignItems="center"
                            minHeight="100%"
                        >
                            <SpacingColumn
                                justifyContent="center"
                                alignItems="center"
                                spacing={CRMSpacing.X_LARGE}
                            >
                                {/* HEADER */}
                                <Flex justifyContent="center">
                                    <CRMParagraph fontSize={40}>
                                        <WeightSemiBold>
                                            Ready to see how we're doing?
                                        </WeightSemiBold>
                                    </CRMParagraph>
                                </Flex>

                                {/* QUOTE */}
                                <Flex justifyContent="center">
                                    <MaxWidth width="500px">
                                        <CRMParagraph fontSize={CRMFontSizes.LARGE}>
                                            <TextAlign position="center">
                                                <WeightMedium>
                                                    "{randomMotivationQuote}"&nbsp;
                                                </WeightMedium>
                                                <FontSize size={CRMFontSizes.MED}>
                                                    - Jamie Cole
                                                </FontSize>
                                            </TextAlign>
                                        </CRMParagraph>
                                    </MaxWidth>
                                </Flex>

                                <Flex justifyContent="center">
                                    <SpacingRow
                                        justifyContent="center"
                                        alignItems="center"
                                        spacing={CRMSpacing.SMALL}
                                    >
                                        {/* CTA */}
                                        <CRMButtonPrimary
                                            label={randomCTALabel}
                                            fullWidth={false}
                                            onClick={() => setContinueToKPISClicked(true)}
                                        />

                                        {/* CLOSE */}
                                        <CRMButtonQuaternary
                                            label="No thanks"
                                            onClick={props.onClose}
                                        />
                                    </SpacingRow>
                                </Flex>
                            </SpacingColumn>
                        </Flex>
                    </SpacingColumn>
                }

                {/* VIEW - KPIs */}
                {view === "kpis" &&
                    <SpacingColumn
                        childSize="1fr min-content"
                        spacing={CRMSpacing.MEDIUM}
                        height="100%"
                    >
                        {/* IFRAME */}
                        <iframe
                            src="https://data.saillegal.co.uk/public/dashboard/4047f375-e620-448d-aa84-ab81526304b4"
                            frameBorder="0"
                            width="100%"
                            height="100%"
                            allowTransparency={true}
                        />

                        {/* CLOSE */}
                        <Flex justifyContent="start">
                            <CRMButtonQuaternary
                                label="Close"
                                icon="arrow-left"
                                onClick={props.onClose}
                            />
                        </Flex>
                    </SpacingColumn>
                }
            </Background>
        </Background>
    </CRMPopupShadowBox>
};
