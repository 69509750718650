import React from "react";
import { CRMIcon } from "../../CRMIcon/CRMIcon";

type TDropdownFaceFlatProps = {
    isOpen: boolean;
    toggleOpen: () => void;
    displayError?: boolean;
    disabled?: boolean;
};

export const CRMDropdownFaceFlat = (props: React.PropsWithChildren<TDropdownFaceFlatProps>): JSX.Element => {

    return (
        <div
            className={`
                crm-dropdown-face-flat
                crm-dropdown-face-flat--${props.isOpen ? 'open' : 'closed'}-${props.displayError ? "error" : "no-error"}
                crm-dropdown-face-flat--${props.disabled ? "disabled" : "enabled"}
            `}
            onClick={() => {
                if (!props.disabled) {
                    props.toggleOpen();
                }
            }}
        >
            
            {props.children}

            {/* ARROW ICON */}
            <div
                className={`
                    crm-dropdown-face-flat__face-icon
                    crm-dropdown-face-flat__face-icon--${props.isOpen ? 'open' : 'closed'}
                `}
            >
                <CRMIcon
                    iconName="down-arrow" 
                    colour={props.disabled ? "neutral-4" : "neutral-ink"}
                />
            </div>
        </div>
    );
};
