import React from "react";
import { TContainerStateProps } from "./../../state/TContainerStateProps";
import { CRMTitleSection } from "../../components/CRMTitleSection/CRMTitleSection";
import { CRMSpacer } from "../../components/CRMSpacer/CRMSpacer";
import { CRMAutosaveIndicatorWrap } from "../../components/CRM/CRMAutosaveIndicatorWrap/CRMAutosaveIndicatorWrap";
import { Cases9, CasesMilestonesSearches, CasesMilestonesReportsPartial, CasesMilestonesSaleOnlyPartial, CasesIsClosedBeforeCompletion, CasesIsCreatedInError } from "../../../../domain/codecs/Cases";
import { CRMCodecEditForm } from "../../components/CRMCodecEditForm/CRMCodecEditForm";
import { CRMSpacingColumn } from "../../components/CRMSpacingColumn/CRMSpacingColumn";
import { CRMInstructCase } from "../../components/CRMInstructCase/CRMInstructCase";
import { CRMDeferButtonComponent } from "../../components/CRMDeferButtonComponent/CRMDeferButtonComponent";
import { CRMSpacingRow } from "../../components/CRMSpacingRow/CRMSpacingRow";

const CRMLegalCaseDetailsCaseMilestonesContainer = (props: TContainerStateProps): JSX.Element => {
    const hasLedgerEntries = props.state.forms.case_ledger_page.case_ledger.children.ledger_entries.length > 0;

    return <CRMAutosaveIndicatorWrap
        status={props.state.forms.case_details_page.data.output.details.status}
    >
        <CRMTitleSection>
            Case Milestones
        </CRMTitleSection>
        
        <CRMSpacer size="large" />

        <CRMSpacingColumn spacing="medium">
            {props.state.forms.case_details_page.data.output.details.children.status === "quote" &&
                <CRMSpacingRow spacing="medium" justifyContent="space-between">
                    <CRMInstructCase
                        hasLedgerEntries={hasLedgerEntries}
                        form={props.state.forms.case_details_page.data.output.details.children.instruct}
                        onInstruct={() => props.dispatch({
                            type: "LEGAL_CASE_INSTRUCT",
                            payload: props.state.forms.case_details_page.data.output.details.children.instruct,
                        })}
                    />

                    <CRMDeferButtonComponent
                        value={props.state.forms.case_details_page.data.output.details.edited.chase_instruction_defer}
                        onChange={(chase_instruction_defer) =>
                            props.dispatch({
                                type: "LEGAL_CASE_DETAILS_CHANGE",
                                payload: {
                                    ...props.state.forms.case_details_page.data.output.details,
                                    edited: {
                                        ...props.state.forms.case_details_page.data.output.details.edited,
                                        chase_instruction_defer,
                                    }
                                }
                            })
                        }
                        displayError={false}
                        popoutPosition="below-button-left"
                    />
                </CRMSpacingRow>
            }
            
            {props.state.forms.case_details_page.data.output.details.children.status !== "quote" && <>
                <CRMCodecEditForm
                    codec={Cases9}
                    model={props.state.forms.case_details_page.data.output.details.edited}
                    validationErrors={props.state.forms.case_details_page.data.output.details.validationErrors}
                    columns={2}
                    onChange={(edited) => {
                        props.dispatch({
                            type: "LEGAL_CASE_DETAILS_CHANGE",
                            payload: {
                                ...props.state.forms.case_details_page.data.output.details,
                                edited: {
                                    ...props.state.forms.case_details_page.data.output.details.edited,
                                    ...edited,
                                }
                            }
                        })
                    }}
                />

                <CRMCodecEditForm
                    codec={CasesMilestonesReportsPartial}
                    model={props.state.forms.case_details_page.data.output.details.edited}
                    validationErrors={props.state.forms.case_details_page.data.output.details.validationErrors}
                    columns={1}
                    onChange={(edited) => {
                        props.dispatch({
                            type: "LEGAL_CASE_DETAILS_CHANGE",
                            payload: {
                                ...props.state.forms.case_details_page.data.output.details,
                                edited: {
                                    ...props.state.forms.case_details_page.data.output.details.edited,
                                    ...edited,
                                }
                            }
                        })
                    }}
                />

                {(
                    (
                        props.state.forms.case_details_page.data.output.details.children.transaction_type === "purchase"
                        && props.state.forms.case_details_page.data.output.details.children.searches_required
                        && props.state.forms.case_details_page.data.output.details.children.dual_rep.linked_case_form?.children.other_case_purchasing_searches !== true
                    ) || (
                        props.state.forms.case_details_page.data.output.details.children.transaction_type === "sale"
                        && props.state.forms.case_details_page.data.output.details.children.seller_to_purchase_searches
                    )
                )  &&
                    <CRMCodecEditForm
                        codec={CasesMilestonesSearches}
                        model={props.state.forms.case_details_page.data.output.details.edited}
                        validationErrors={props.state.forms.case_details_page.data.output.details.validationErrors}
                        columns={2}
                        onChange={(edited) => {
                            props.dispatch({
                                type: "LEGAL_CASE_DETAILS_CHANGE",
                                payload: {
                                    ...props.state.forms.case_details_page.data.output.details,
                                    edited: {
                                        ...props.state.forms.case_details_page.data.output.details.edited,
                                        ...edited,
                                    }
                                }
                            })
                        }}
                    />
                }
                
                {props.state.forms.case_details_page.data.output.details.children.transaction_type === "sale" &&
                    <>
                        <CRMCodecEditForm
                            codec={CasesMilestonesSaleOnlyPartial}
                            model={props.state.forms.case_details_page.data.output.details.edited}
                            validationErrors={props.state.forms.case_details_page.data.output.details.validationErrors}
                            columns={1}
                            onChange={(edited) => {
                                props.dispatch({
                                    type: "LEGAL_CASE_DETAILS_CHANGE",
                                    payload: {
                                        ...props.state.forms.case_details_page.data.output.details,
                                        edited: {
                                            ...props.state.forms.case_details_page.data.output.details.edited,
                                            ...edited,
                                        }
                                    }
                                })
                            }}
                        />
                    </>
                }
            </>}
        </CRMSpacingColumn>
    </CRMAutosaveIndicatorWrap>;
};

export default CRMLegalCaseDetailsCaseMilestonesContainer;