import React from "react";
import { breakpointOption } from "./MediaPropSwitch";

interface IMediaCompSwitch {
    MobileChild: JSX.Element,
    DesktopChild: JSX.Element,
    breakpoint: breakpointOption;
};

export const MediaCompChildrenSwitch = (props: React.PropsWithChildren<IMediaCompSwitch>): JSX.Element =>
    <div className={`media-prop-switch`}>
        <div className={`media-prop-switch__mobile media-prop-switch__mobile--${props.breakpoint}`}>
            {props.MobileChild}
        </div>

        <div className={`media-prop-switch__desktop media-prop-switch__desktop--${props.breakpoint}`}>
            {props.DesktopChild}
        </div>
    </div>