import { pipe } from "fp-ts/lib/function";
import { DateTime } from "luxon";

export const getDate = (iso: string): string =>
    iso.split("T")[0] || "";

// Take a partial or full ISO string and get the hours and minutes converted to local time,
// When only time exists on the partial BST will work off todays date, when a full ISO is provided
// BST will work off the date provided
export const getHoursMinsInLocal = (iso: string): string =>
     pipe(
        /^[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}.*$/.test(iso)
            ? iso
            : iso.split("T")[1] || "",
        (time) => time === "" ? "" : DateTime.fromISO(time).toFormat("HH:mm"),
    );

// Returns formatted as "Monday, 4 July 2020"
export const getDateString = (iso: string): string =>
     pipe(
        iso.split("T")[0] || "",
        (date) => date === "" ? "" : DateTime.fromISO(date).toFormat("cccc, d LLL y"),
    );

// Returns formatted as "12:35 PM"
export const getTimeString = (iso: string): string =>
     pipe(
        /^[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}.*$/.test(iso)
            ? iso
            : iso.split("T")[1] || "",
        (time) => time === "" ? "" : DateTime.fromISO(time).toFormat("t"),
    );
