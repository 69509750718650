import React from "react";

export class CRMCardOutsideOutlined extends React.Component<React.PropsWithChildren<{
    borderRounding: "left" | "right";
}>> {
    public render (): JSX.Element {
        return (
            <div className={`crm-card-outside-outlined crm-card-outside-outlined--rounded-${this.props.borderRounding}`}>
                {this.props.children}
            </div>
        );
    }
}
