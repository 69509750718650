import * as QueryStringFilterMappings from "./QueryStringFilterMappings";
import * as QueryStringFilterGroup1 from "./QueryStringFilterGroup1";
import * as QueryStringFilterMappingFunction from "./QueryStringFilterMappingFunction";
import { array, nonEmptyArray } from "fp-ts/lib";
import { pipe } from "fp-ts/lib/function";
import { TValidationFunction } from "../../shared/src/validation/Function";

export const filterMappings: QueryStringFilterMappings.T = {
    string: {
        id: QueryStringFilterMappingFunction.createForString("parties.id"),
        user_id: QueryStringFilterMappingFunction.createForString("search_users.id"),
        user_first_name: QueryStringFilterMappingFunction.createForString("search_users.first_name"),
        user_last_name: QueryStringFilterMappingFunction.createForString("search_users.last_name"),
        user_email: QueryStringFilterMappingFunction.createForString("search_users.email"),
        user_phone_number: QueryStringFilterMappingFunction.createForPhoneNumber("search_users_phone_numbers.phone_number"),
        enquiry_listing_id: QueryStringFilterMappingFunction.createForString("listings_enquiries.listing_id"),
    },
};

export const validator: TValidationFunction = QueryStringFilterGroup1.createValidator(filterMappings);

export const createForGenericSearch = (value: string): QueryStringFilterGroup1.T => ({
    type: "GROUP",
    conditional: "OR",
    filters: [
        {
            type: "FILTER",
            property: "user_first_name",
            operator: "BEGINS_WITH",
            value,
        },
        {
            type: "FILTER",
            property: "user_last_name",
            operator: "BEGINS_WITH",
            value,
        },
        {
            type: "FILTER",
            property: "user_email",
            operator: "BEGINS_WITH",
            value,
        },
        {
            type: "FILTER",
            property: "user_phone_number",
            operator: "BEGINS_WITH",
            value,
        },
    ],
});

export const createWhereIdAnyOf = (ids: nonEmptyArray.NonEmptyArray<string>): QueryStringFilterGroup1.T => ({
    type: "GROUP",
    conditional: "OR",
    filters:  pipe(
        ids,
        array.map((id) => ({
            type: "FILTER",
            property: "id",
            operator: "EQUALS_STRING",
            value: id,
        }))
    ),
});
