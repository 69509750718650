import React from "react";
import { CRMParagraphBulletPoints } from "../CRMBulletList/CRMParagraphBulletPoints";
import { CRMParagraph } from "../Simple/CRMParagraph/CRMParagraph";
import { CRMSpacingColumn } from "../CRMSpacingColumn/CRMSpacingColumn";
import { WeightSemiBold } from "../WeightSemiBold/WeightSemiBold";

export const CRMCaseSignOffAsReadyForExchangePopupChecklistTransferComponent = (): JSX.Element =>
    <CRMSpacingColumn spacing="medium">
        <CRMParagraph>
            <WeightSemiBold>
                Before continuing please review the following high risk flags and confirm you are happy to proceed.
            </WeightSemiBold>
        </CRMParagraph>

        <CRMParagraphBulletPoints
            bullets={[
                "Is the property vacant?",
                "Is a POA in place?",
                "Is the property mortgage free?",
                "Is the client elusive, corresponds by email only?",
                "Is the client putting unnecessary pressure on to transfer the property quickly?",
                "Is there a large cash deposit?",
                "How did the client instruct - if not local why instruct Sail Legal?",
                "Confirm you have reviewed our Red Flags Checklist. Do you have any other concerns?",
                "Confirm you have not received any alerts on change of email or phone numbers.",
                "Have we checked if SDLT/LTT is payable?",
                "Are all parties represented and have we checked any Separation Agreement/Consent Order?",
                "If any parties have been added/removed, have all appropriate checks been completed to mitigate risk?"
            ]}
        />
    </CRMSpacingColumn>;
