import React from "react";
import { CRMSpacer } from "../../components/CRMSpacer/CRMSpacer";
import { CRMTitleSection } from "../../components/CRMTitleSection/CRMTitleSection";
import CRMInputLabelAndErrorWrapComponent from "../../components/CRMInputLabelAndErrorWrapComponent/CRMInputLabelAndErrorWrapComponent";
import CRMInputGeneralComponent from "../../components/CRMInputs/CRMInputGeneralComponent/CRMInputGeneralComponent";
import CRMTextAreaComponent from "../../components/CRMTextAreaComponent/CRMTextAreaComponent";
import { CRMInputCurrency } from "../../components/CRMInputCurrency/CRMInputCurrency";
import { TContainerStateProps } from "../../state/TContainerStateProps";
import { CRMAutosaveIndicatorWrap } from "../../components/CRM/CRMAutosaveIndicatorWrap/CRMAutosaveIndicatorWrap";

class CRMEnquirySearchCriteriaContainer extends React.Component<React.PropsWithChildren<TContainerStateProps>> {
    public render(): JSX.Element {
        return (
            <CRMAutosaveIndicatorWrap status={this.props.state.activeData.crm.enquiry.partyForm.status === "hidden" ? "untouched" : this.props.state.activeData.crm.enquiry.partyForm.status}>
                <CRMTitleSection>Search Criteria</CRMTitleSection>
                <CRMSpacer size="large" />
                <CRMInputLabelAndErrorWrapComponent
                    label="Area they are searching in"
                    displayError={false}
                    errorMessage=""
                >
                    <CRMTextAreaComponent
                        displayError={false}
                        value={this.props.state.activeData.crm.enquiry.partyForm.edit.search_criteria_area}
                        onChange={(value) => this.props.dispatch({
                            type: "CRM_ENQUIRY_PARTY_INPUT_CHANGED",
                            payload: {
                                key: "search_criteria_area",
                                value,
                            },
                        })}
                    />
                </CRMInputLabelAndErrorWrapComponent>
                <CRMSpacer size="medium" />
                <CRMInputLabelAndErrorWrapComponent
                    label="Min number of beds wanted"
                    displayError={false}
                    errorMessage=""
                >
                    <CRMInputGeneralComponent
                        inputType="numericString"
                        displayError={false}
                        value={this.props.state.activeData.crm.enquiry.partyForm.edit.search_criteria_min_beds?.toString() ?? ""}
                        onChange={(value) => this.props.dispatch({
                            type: "CRM_ENQUIRY_PARTY_INPUT_CHANGED",
                            payload: {
                                key: "search_criteria_min_beds",
                                value: value === "" ? null : Number(value),
                            },
                        })}
                        onPressEnterKey={() => undefined}
                    />
                </CRMInputLabelAndErrorWrapComponent>
                <CRMSpacer size="medium" />
                <CRMInputLabelAndErrorWrapComponent
                    label="Maximum price they are willing to pay"
                    displayError={false}
                    errorMessage=""
                >
                    <CRMInputCurrency
                        currencySymbol="£"
                        displayError={false}
                        value={this.props.state.activeData.crm.enquiry.partyForm.edit.search_criteria_max_price}
                        onChange={(value) => this.props.dispatch({
                            type: "CRM_ENQUIRY_PARTY_INPUT_CHANGED",
                            payload: {
                                key: "search_criteria_max_price",
                                value,
                            },
                        })}
                        onPressEnterKey={() => undefined}
                    />
                </CRMInputLabelAndErrorWrapComponent>
                <CRMSpacer size="medium" />
                <CRMInputLabelAndErrorWrapComponent
                    label="Property preferences"
                    displayError={false}
                    errorMessage=""
                >
                    <CRMTextAreaComponent
                        displayError={false}
                        value={this.props.state.activeData.crm.enquiry.partyForm.edit.search_criteria_preferences}
                        onChange={(value) => this.props.dispatch({
                            type: "CRM_ENQUIRY_PARTY_INPUT_CHANGED",
                            payload: {
                                key: "search_criteria_preferences",
                                value,
                            },
                        })}
                    />
                </CRMInputLabelAndErrorWrapComponent>
            </CRMAutosaveIndicatorWrap>
        );
    }
}

export default CRMEnquirySearchCriteriaContainer;
