import React from "react";
import { CRMColors } from "../../../models/CRMColors";
import { CRMFontSizes } from "../../../models/CRMFontSizes";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { FontRoboto } from "../../BuildingBlocks/FontRoboto";
import { SpacingRow } from "../../BuildingBlocks/SpacingRow";
import { TextColor } from "../../BuildingBlocks/TextColor";
import { CRMIcon } from "../../CRMIcon/CRMIcon";
import { CRMParagraph } from "../../Simple/CRMParagraph/CRMParagraph";
import { CursorPointer } from "../../CursorPointer/CursorPointer";
import { CRMInputVerificationIndicator } from "../../Simple/CRMInputVerificationIndicator/CRMInputVerificationIndicator";
import UnstyledInputGeneralComponent from "../../UnstyledInputGeneralComponent/UnstyledInputGeneralComponent";
import { WeightRegular } from "../../WeightRegular/WeightRegular";
import { TPhoneNumber } from "./CRMInputPhoneNumberCardStack";

export const Number = (props: {
    mode: "face" | "list",
    number: TPhoneNumber,
    onPhoneNumberChange: (id: string, phoneNumber: string | null) => void,
    onMakePrimary: (id: string) => void,
    onDelete: (id: string) => void,
}): JSX.Element => {
    const shouldShowIsPrimary =
        props.mode === "list"
        && props.number.primary_number;

    const shouldShowIsNotPrimary =
        props.mode === "list"
        && !props.number.primary_number;

    const shouldShowDelete = props.mode === "list";

    const isPhoneNumberEditable = props.mode === "list";

    const placeholder = "Enter number";

    return <SpacingRow
        spacing={CRMSpacing.MEDIUM}
        justifyContent="space-between"
        alignItems="center"
    >
        {/* LEFT HAND SIDE */}
        <SpacingRow
            spacing={CRMSpacing.TINY}
            justifyContent="start"
            alignItems="center"
        >
            {/* VERIFICATION INDICATOR */}
            <CRMInputVerificationIndicator status={props.number.is_verified ? "verified" : "unverified"} />

            {/* PHONE NUMBER - UNEDITABLE */}
            {!isPhoneNumberEditable &&
                <CRMParagraph fontSize={CRMFontSizes.SMALL}>
                    <FontRoboto>
                        <WeightRegular>
                            <TextColor color={CRMColors.NEUTRAL_INK}>
                                {props.number.phone_number || ""}
                            </TextColor>
                        </WeightRegular>
                    </FontRoboto>
                </CRMParagraph>
            }

            {/* PHONE NUMBER - EDITABLE */}
            {isPhoneNumberEditable &&
                <UnstyledInputGeneralComponent
                    inputType="tel"
                    value={props.number.phone_number || ""}
                    onChange={(phoneNumber) => props.onPhoneNumberChange(props.number.id, phoneNumber || null)}
                    displayError={false}
                    placeholder={placeholder}
                    style={{
                        background: "none",
                        border: "0",
                        fontSize: CRMFontSizes.SMALL,
                        width: `${((props.number.phone_number || "").length || placeholder.length) + 1}ch`,
                        outline: "none",
                        fontFamily: `"Roboto Mono", monospace`,
                        color: props.number.has_error ? CRMColors.HIGHLIGHTS_INSTRUCTIONAL_BRICK_2 : CRMColors.NEUTRAL_INK
                    }}
                />
            }

            {/* SET PRIMARY */}
            {shouldShowIsNotPrimary &&
                <CursorPointer onClick={() => props.onMakePrimary(props.number.id)}>
                    <CRMIcon
                        iconName="bookmark"
                        size="x-small"
                        colour="neutral-0"
                    />
                </CursorPointer>
            }

            {/* IS PRIMARY */}
            {shouldShowIsPrimary &&
                <CRMIcon
                    iconName="bookmarked"
                    size="x-small"
                    colour="neutral-2"
                />
            }
        </SpacingRow>

        {/* RIGHT HAND DELETE */}
        {shouldShowDelete &&
            <CursorPointer onClick={() => props.onDelete(props.number.id)}>
                <CRMIcon
                    iconName="delete"
                    size="x-small"
                    colour="neutral-ink"
                />
            </CursorPointer>
        }
    </SpacingRow>;
};
