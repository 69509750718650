import React from "react";
import { CRMColors } from "../../../models/CRMColors";
import { CRMIcon } from "../../CRMIcon/CRMIcon";
import { WeightBold } from "../../WeightBold/WeightBold";
import { toDateFormat1 } from "../../../../../shared/src/dateTime";
import { Text } from "../../BuildingBlocks/Text";
import { CRMFontSizes } from "../../../models/CRMFontSizes";
import { Padding } from "../../BuildingBlocks/Padding";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { SpacingRow } from "../../BuildingBlocks/SpacingRow";
import { WeightMedium } from "../../WeightMedium/WeightMedium";
import { Background } from "../../BuildingBlocks/Background";
import { getAmountOfTimeInFutureFromDate } from "../../CRMDeferButtonComponent/CRMDeferButtonComponent";
import { DateTime } from "luxon";

export const CRMTimelineDefer = (props: {
    jobName: string,
    deferDate: string;
    createdAt: string;
}): JSX.Element =>
    <Background
        color={CRMColors.HIGHLIGHTS_PERTINENT_YELLOW_8}
        borderDefinition={`1px solid ${CRMColors.HIGHLIGHTS_PERTINENT_YELLOW_8}`}
        borderRadius="5px"
    >
        <Padding spacing={CRMSpacing.SMALL}>
            <SpacingRow
                justifyContent="start"
                spacing={CRMSpacing.SMALL}
            >
                {/* ICON */}
                <CRMIcon
                    iconName="snooze"
                    colour="neutral-0"
                    size="medium"
                />

                {/* DEFER TEXT */}
                <Text
                    size={CRMFontSizes.SMALL_PLUS}
                    color={CRMColors.NEUTRAL_0}
                    weight={400}
                >
                    {toDateFormat1(props.createdAt)} - <WeightMedium>{props.jobName}</WeightMedium> <WeightBold>deferred for {getAmountOfTimeInFutureFromDate(DateTime.fromISO(props.createdAt), DateTime.fromISO(props.deferDate))}</WeightBold>
                </Text>
            </SpacingRow>
        </Padding>
    </Background>;
