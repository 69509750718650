import React from "react";
import { FrontColors } from "../../../../models/FrontColors";
import { FrontSpacing } from "../../../../models/FrontSpacing";
import { SpacingColumn } from "../../../BuildingBlocks/SpacingColumn";
import { WeightBold } from "../../../WeightBold/WeightBold";
import { FrontInfoBubble } from "../../Simple/FrontInfoBubble/FrontInfoBubble";
import { Padding } from "../../../BuildingBlocks/Padding";
import { SpacingRow } from "../../../BuildingBlocks/SpacingRow";
import { FrontIcon } from "../../Simple/FrontIcon/FrontIcon";
import FrontParagraphSmall from "../../Simple/FrontParagraphSmall/FrontParagraphSmall";
import { FrontTextColour } from "../../../FrontTextColour/FrontTextColour";
import { TFrontOnboardingCaseDualRepExplanationProps } from "../FrontOnboardingCaseDualRepExplanation/FrontOnboardingCaseDualRepExplanation";

export const FrontOnboardingCaseDualRepExplanationMobile = (props: React.PropsWithChildren<TFrontOnboardingCaseDualRepExplanationProps>): JSX.Element => (
    
    <SpacingColumn spacing={FrontSpacing.MEDIUM_1}>
        
        {/* EXPLANATION */}
        <FrontInfoBubble color={FrontColors.PRIMARY_2}>
            <Padding spacing={FrontSpacing.SMALL_3}>
                <SpacingColumn spacing={FrontSpacing.MEDIUM_1}>
                    <SpacingRow alignItems="center" justifyContent="center">
                        <FrontIcon
                            iconName="sail-boat"
                            size="extra-large"
                            colour="neutral-18"
                        />
                    </SpacingRow>
                
                    <SpacingColumn spacing={FrontSpacing.SMALL_3}>
                        <FrontParagraphSmall>
                            <FrontTextColour colour="neutral-18">
                                Your {props.transactionType} is one side of a <WeightBold>Dual Representation</WeightBold> case.
                            </FrontTextColour>
                        </FrontParagraphSmall>

                        <FrontParagraphSmall>
                            <FrontTextColour colour="neutral-18">
                                We need you to read through how this works and then <WeightBold>acknowledge your consent</WeightBold>.
                            </FrontTextColour>
                        </FrontParagraphSmall>
                        
                        <FrontParagraphSmall>
                            <FrontTextColour colour="neutral-18">
                                Once <WeightBold>all concerned parties have consented</WeightBold> we can progress to the next steps.
                            </FrontTextColour>
                        </FrontParagraphSmall>
                        
                        <FrontParagraphSmall>
                            <FrontTextColour colour="neutral-18">
                                <WeightBold>Click the button above </WeightBold>to consent to Dual Representation.
                            </FrontTextColour>
                        </FrontParagraphSmall>
                    </SpacingColumn>
                </SpacingColumn>
            </Padding>
        </FrontInfoBubble>
    </SpacingColumn>
);