import React from "react";
import { record, array, string, readonlyArray } from "fp-ts";
import { pipe } from "fp-ts/lib/function";
import { Padding } from "../../components/BuildingBlocks/Padding";
import { CRMCardOutside } from "../../components/CRMCardOutside/CRMCardOutside";
import { CRMTitleSection } from "../../components/CRMTitleSection/CRMTitleSection";
import { CRMSpacing } from "../../models/CRMSpacing";
import { TContainerStateProps } from "../../state/TContainerStateProps";
import { SpacingColumn } from "../../components/BuildingBlocks/SpacingColumn";
import { camelCaseToCopyText, pluralSingleText, removeText, snakeCaseToCopyText } from "../../../../shared/src/util";
import { CRMJobAssignmentInput } from "../../components/CRMJobAssignmentInput/CRMJobAssignmentInput";
import { SimpleGrid } from "../../components/BuildingBlocks/SimpleGrid";
import { TAdminUserEditable } from "../../../../domain/codecs/formEditable/AdminUserEditable";
import { FontQuicksand } from "../../components/BuildingBlocks/FontQuicksand";
import { FontSize } from "../../components/BuildingBlocks/FontSize";
import { CRMFontSizes } from "../../models/CRMFontSizes";
import { TextHightlight } from "../../components/BuildingBlocks/TextHightlight";
import { CRMColors } from "../../models/CRMColors";
import { TextColor } from "../../components/BuildingBlocks/TextColor";
import { WeightBold } from "../../components/WeightBold/WeightBold";
import { JobTypeCategories, JobTypes, TJobType } from "../../../../domain/JobType";

export const CRMAdminPageAssignJobsContainer = (props: TContainerStateProps): JSX.Element => {

    const getAdminUsers = (): Array<TAdminUserEditable> => 
        props.state.forms.homes_admin_page.data.output.adminUsers
    ;

    const jobTypeToReadableText = (type: TJobType): string =>
        pipe(
            type,
            removeText("sh_"),
            removeText("sl_"),
            removeText("sp_"),
            snakeCaseToCopyText,
        )
    ;
 
    return (
        <CRMCardOutside borderRounding="right">
            <Padding spacing={CRMSpacing.LARGE}>
                <SpacingColumn spacing={CRMSpacing.LARGE}>

                    {/* SOME JOBS HAVE NOT BEEN ASIGNED: WARNING BUBBLE */}
                    {getUnsassignedJobsCount(getAdminUsers()) > 0 &&
                        <FontQuicksand>
                            <FontSize size={CRMFontSizes.SMALL}>
                                <TextHightlight color={CRMColors.HIGHLIGHTS_INSTRUCTIONAL_BRICK_12}>
                                    <TextColor color={CRMColors.HIGHLIGHTS_INSTRUCTIONAL_BRICK_0}>
                                        {getUnsassignedJobsCount(getAdminUsers())} {pluralSingleText("job has", "jobs have")(getUnsassignedJobsCount(getAdminUsers()))} <WeightBold>not been assigned</WeightBold> to anyone!
                                    </TextColor>
                                </TextHightlight>
                            </FontSize>
                        </FontQuicksand>
                    }

                    {/* JOBS BY CATEGORY */}
                    <SpacingColumn spacing={CRMSpacing.LARGE}>
                        {pipe(
                            JobTypeCategories,
                            record.toArray,
                            array.map(([key, jobs]) => (
                                <SpacingColumn key={key} spacing={CRMSpacing.MEDIUM}>
                                    {/* JOB CATEGORY TITLE */}
                                    <CRMTitleSection>
                                        {camelCaseToCopyText(key)}
                                    </CRMTitleSection>

                                    {/* JOBS UNDER A CATEGORY */}
                                    <SimpleGrid
                                        columnSpacing={CRMSpacing.MEDIUM}
                                        rowSpacing={CRMSpacing.MEDIUM}
                                        templateColumns="1fr 1fr"
                                    >
                                        {pipe(
                                            jobs,
                                            readonlyArray.map((type: TJobType) =>
                                                <CRMJobAssignmentInput
                                                    key={type}
                                                    description={jobTypeToReadableText(type)}
                                                    type={type}
                                                    users={props.state.forms.homes_admin_page.data.output.adminUsers}
                                                    onChange={(adminUsers) => props.dispatch({
                                                        type: "HOMES_ADMIN_USERS_CHANGE",
                                                        payload: adminUsers
                                                    })}
                                                />
                                            )
                                        )}
                                    </SimpleGrid>
                                </SpacingColumn>
                            ))
                        )}
                    </SpacingColumn>
                </SpacingColumn>
            </Padding>
        </CRMCardOutside>
    );
};

export const getUnsassignedJobsCount = (users: Array<TAdminUserEditable>): number => 
    pipe(
        users,
        array.map((user) => user.edited.jobs),
        array.flatten,
        array.uniq(string.Eq),
        (assignedJobs) => JobTypes.length - assignedJobs.length
    )
;
