import { tap, mergeMap, debounceTime, switchMap } from "rxjs/operators";
import { formOperation } from "../../wrappers/formOperation";
import { TActionsDefinitionsList } from "./TAction";
import { action } from "./actionFunctions";
import { TTriageSimpleEmailForm } from "../../../../domain/codecs/form/TriageForm";
import { createTriageLoadMore$, createTriageOpenEmail$, deleteEmailFromList } from "./crmTriageActions";
import { TSimpleLegalEmailPerson } from "../../../../domain/codecs/LegalEmail";
import { array, option } from "fp-ts";
import { pipe } from "fp-ts/lib/function";
import { array as extArray } from "../../../../shared/src/utilsByDomain/array";
import { TLegalCaseFormIO } from "../../../../domain/codecs/formIO/LegalCaseFormIO";
import { TCaseMemberForm } from "../../../../domain/codecs/form/CaseMemberForm";
import { getFirstNameOrBlank } from "../../../../shared/src/utilsByDomain/name/getFirstNameOrBlank";
import { getLastNameOrBlank } from "../../../../shared/src/utilsByDomain/name/getLastNameOrBlank";
import { doTwoEmailDomainsMatch } from "../../functions/email/doTwoEmailDomainsMatch";
import { extractEmailOrSelf } from "../../functions/email/extractEmail";
import { foldFormOnSuccess } from "../../functions/form/foldFormOnSuccess";
import { Observable } from "rxjs";

export const actions: TActionsDefinitionsList = [
    // DISASSOCIATE
    action("CASE_INBOX_DISASSOCIATE", (obs$, lens, setState, getState) => {
        obs$.pipe(
            mergeMap((payload) => formOperation("SetTriageEmailAsDisassociated", payload)),
            tap(foldFormOnSuccess(
                (response) => setState(
                    lens.case_inbox_page.unresolved.children.emails
                        .where((email) => email.original.id === response.original.email_id)
                        .children.detailed_email_form.children.disassociate_from_case_form
                        .set(response)
                ),
                deleteEmailFromList(lens.case_inbox_page.unresolved.children, setState, getState)
            ))
        ).subscribe();
    }),
    // OPEN EMAIL
    action("CASE_INBOX_UNRESOLVED_OPEN_EMAIL", (obs$, lens, setState, getState) => {
        createTriageOpenEmail$(obs$, lens.case_inbox_page.unresolved, setState, getState).subscribe();
    }),
    // LOAD MORE
    action("CASE_INBOX_UNRESOLVED_LOAD_MORE", (obs$, lens, setState, getState) => {
        createTriageLoadMore$(
            obs$, 
            lens.case_inbox_page.unresolved, 
            "GetCaseInboxEmails", 
            setState, 
            getState,
            100,
            (emails) => patchSimpleEmailFormsWithEmailPeople(
                emails,
                getSimpleLegalEmailPeopleFromCaseDetails(lens.case_details_page.data.get()(getState()))
            )
        ).subscribe();
    }),
    // SEARCH
    action("CASE_INBOX_SEARCH_CHANGE", (obs$: Observable<string>, lens, setState, getState) => {
        obs$.pipe(
            tap((searchTerm) => setState(lens.case_inbox_page.unresolved.edited.search_term.set(searchTerm))),
            debounceTime(1000),
            switchMap(() => formOperation("GetCaseInboxEmails", {
                ...lens.case_inbox_page.unresolved.get()(getState()),
                children: {
                    ...lens.case_inbox_page.unresolved.get()(getState()).children,
                    emails: [],
                }
            })),
            tap((response) => setState(lens.case_inbox_page.unresolved.set({
                ...response,
                children: {
                    ...response.children,
                    emails: patchSimpleEmailFormsWithEmailPeople(
                        response.children.emails,
                        getSimpleLegalEmailPeopleFromCaseDetails(lens.case_details_page.data.get()(getState())),
                    )
                }
            }))),
        ).subscribe();
    }),
];

export const patchSimpleEmailFormsWithEmailPeople = (
    emails: Array<TTriageSimpleEmailForm>, 
    people: Array<TSimpleLegalEmailPerson>
): Array<TTriageSimpleEmailForm> =>
    emails.map((email) => {
                            
        let fromPerson: TSimpleLegalEmailPerson = getMatchingEmailPerson(email.original.from_address, people);
        let toPeople: Array<TSimpleLegalEmailPerson> = email.original.to_addresses.map((toEmail) =>
            getMatchingEmailPerson(toEmail, people)
        );
        let body = {
            ...email.original,
            to: toPeople,
            from: fromPerson,
        }

        return {
            ...email,
            original: body,
            edited: body,
        } as TTriageSimpleEmailForm;
    })
;

export const getSimpleLegalEmailPeopleFromCaseDetails = (details: TLegalCaseFormIO): Array<TSimpleLegalEmailPerson> => pipe(
    details.output.details.children.members,
    array.map<TCaseMemberForm, TSimpleLegalEmailPerson>((member) => ({
        title: "client",
        first_name: member.original.first_name,
        last_name: member.original.last_name,
        address: member.original.email || ''
    })),
    extArray.inject<TSimpleLegalEmailPerson>({
        title: "conveyancer",
        first_name: getFirstNameOrBlank(details.output.details.children.other_side_conveyancer_name),
        last_name: getLastNameOrBlank(details.output.details.children.other_side_conveyancer_name),
        address: details.output.details.children.other_side_conveyancer_email_address || ""
    }),
    extArray.concat<TSimpleLegalEmailPerson>(
        details.output.sail_legal_case_handlers
            .concat(details.output.sail_legal_non_authorisers)
            .map((staff) => ({
                title: "sail_staff",
                first_name: staff.first_name,
                last_name: staff.last_name,
                address: staff.email
            }))
    ),
    array.map((person) => ({
        ...person,
        address: extractEmailOrSelf(person.address)
    }))
);

export const getMatchingEmailPerson = (email: string, people: Array<TSimpleLegalEmailPerson>): TSimpleLegalEmailPerson => {
    
    let address = extractEmailOrSelf(email);
    
    return pipe(
        people,
        array.findFirst((person) => person.address === address),
        option.fold(
            () => ({
                address,
                title: "other",
                first_name: "",
                last_name: ""
            }),
            (person) => 
                person.title === "conveyancer" ?
                    { ...person, address }
                    : person
        )
    );
}