import { tap, mergeMap, debounceTime, switchMap } from "rxjs/operators";
import { formOperation } from "../../wrappers/formOperation";
import { TActionsDefinitionsList } from "./TAction";
import { action, routeAction } from "./actionFunctions";
import { Observable } from "rxjs";
import { TCaseAutoAssignmentForm } from "../../../../domain/codecs/form/CaseAutoAssignmentForm";

export const actions: TActionsDefinitionsList = [
    routeAction("VIEW_CRM_LEGAL_CASE_AUTO_ASSIGNMENT", (obs$, lens, set, get) => {
        obs$.pipe(
            mergeMap(() => formOperation("GetCaseAutoAssignmentRules", lens.case_assignment_page_form.get()(get()))),
            tap((response) => set(lens.case_assignment_page_form.set(response)))
        ).subscribe();
    }),
    action("CRM_CASE_AUTO_ASSIGNMENT_CHANGE", (obs$: Observable<TCaseAutoAssignmentForm>, lens, set, get) => {
        obs$.pipe(
            tap((form) => set(lens.case_assignment_page_form.children.case_auto_assignment_forms.where(({edited}) =>
                edited.transaction_type === form.edited.transaction_type
                && edited.introducer_id === form.edited.introducer_id
            ).set(form))),
            debounceTime(1000),
            switchMap((form) => formOperation("UpdateCaseAutoAssignmentRules", form)),
            tap((response) => set(lens.case_assignment_page_form.children.case_auto_assignment_forms.where(({edited}) =>
                edited.transaction_type === response.edited.transaction_type
                && edited.introducer_id === response.edited.introducer_id
            ).set(response))),
        ).subscribe();
    }),
];
