import React from "react";
import { CRMSpacing } from "../../../../models/CRMSpacing";
import { SpacingRow } from "../../../BuildingBlocks/SpacingRow";
import { CRMParagraph } from "../../../Simple/CRMParagraph/CRMParagraph";
import { WeightBold } from "../../../WeightBold/WeightBold";
import { CRMDropdownColourComponent } from "../../../CRMDropdownColourComponent/CRMDropdownColourComponent";
import { ICRMDropdownColourOption } from "../../../CRMDropdownColourComponent/CRMDropdownColourComponent";
import { TCasesCaseViewForm, TCasesCaseViewFilterStatus, TCasesCaseViewFilterChaseStatus } from "../../../../../../domain/codecs/form/CasesForm";
import { pipe } from "fp-ts/lib/pipeable";
import { array } from "fp-ts";
import { onChangeForm } from "../../../../../../shared/src/codecs/types/form";

type TCaseTableFilterProps = {
    form: TCasesCaseViewForm;
    onChangeForm: (form: TCasesCaseViewForm) => void;
};

const StatusOptions: Array<ICRMDropdownColourOption<TCasesCaseViewFilterStatus>> = [
    { 
        label: "Instructed",
        value: "any_instructed"
    },
    { 
        label: "Instructed (Excl. completed)",
        value: "any_instructed_excluding_completed"
    },
    { 
        label: "Instructed (Excl. abeyance & completed)",
        value: "any_instructed_excluding_abeyance_and_completed"
    },
    { 
        label: "Property report",
        value: "property_report"
    },
    { 
        label: "Quote only",
        value: "quote"
    },
    { 
        label: "Completed",
        value: "only_completed"
    },
];

const ChaseOptions: Array<ICRMDropdownColourOption<TCasesCaseViewFilterChaseStatus>> = [
    {
        value: "any",
        label: "Any mode",
    },
    {
        value: "chase",
        label: "Chase mode",
    },
    {
        value: "workable",
        label: "Workable mode",
    },
    {
        value: "timeout",
        label: "Workable timed-out mode",
    },
];

export const CRMCaseTableFilter = (props: React.PropsWithChildren<TCaseTableFilterProps>): JSX.Element => {

    const CaseHandlerOptions: Array<ICRMDropdownColourOption<string>> = pipe(
        props.form.children.case_handler_users,
        array.map(({ id, first_name, last_name}) => ({
            label: `${first_name} ${last_name}`,
            value: id
        })),
        array.prepend({ label: "Anyone", value: "any" })
    );
    
    const IntroducerOptions: Array<ICRMDropdownColourOption<string>> = pipe(
        props.form.children.introducers,
        array.map(({ id, name}) => ({
            label: name,
            value: id
        })),
        array.prepend({ label: "Anyone", value: "any" })
    );

    const isAEntityField = (field: string) => field === "assigned_to" || field === "introduced_by";

    const onChange = onChangeForm(props.form, props.onChangeForm);

    const onChangeFilter = (field: keyof TCasesCaseViewForm["edited"]["filters"]) =>
        (value: TCasesCaseViewFilterStatus | string) =>
            onChange("filters")({
                ...props.form.edited.filters,
                [field]: isAEntityField(field) && value === "any" ? null : value
            })
    ;
    
    return (
        <SpacingRow spacing={CRMSpacing.TINY}>
            {/* STATUS */}
            <CRMParagraph>
                <WeightBold>Showing</WeightBold>
            </CRMParagraph>
            <CRMDropdownColourComponent
                value={props.form.edited.filters.status}
                options={StatusOptions}
                onChange={onChangeFilter("status")}
            />

            {/* CHASE MODE */}
            <CRMParagraph>
                <WeightBold>cases in</WeightBold>
            </CRMParagraph>
            <CRMDropdownColourComponent
                value={props.form.edited.filters.chase || "any"}
                options={ChaseOptions}
                onChange={onChangeFilter("chase")}
            />

            {/* ASSIGNED TO */}
            <CRMParagraph>
                <WeightBold>.  Assigned to</WeightBold>
            </CRMParagraph>
            <CRMDropdownColourComponent
                value={props.form.edited.filters.assigned_to || "any"}
                options={CaseHandlerOptions}
                onChange={onChangeFilter("assigned_to")}
            />
            
            {/* INTRODUCER */}
            <CRMParagraph>, </CRMParagraph>
            <CRMParagraph>
                <WeightBold>introduced by</WeightBold>
            </CRMParagraph>
            <CRMDropdownColourComponent
                value={props.form.edited.filters.introduced_by || "any"}
                options={IntroducerOptions}
                onChange={onChangeFilter("introduced_by")}
            />
        </SpacingRow>
    );
};
