import * as rxjs from "rxjs";
import * as rxjsOperators from "rxjs/operators";
import { TActionObservable } from "../../state/applyActions";
import {TSetState as TSetState} from "../../state/TSetState";
import {TGetState as TGetState} from "../../state/TGetState";
import { TPayloadActionType } from "../../state/actions/TAction";

type TGetActionableFirstPayloadValue<T,F> = (
    payload: T,
    getState: TGetState,
    setState: TSetState
) => F;

type TActionableCallback<F> = (
    payload: F,
    getState: TGetState,
    setState: TSetState
) => rxjs.Observable<unknown>;

export const toActionable =
    <A extends TPayloadActionType, T, F>() =>
        (
            getValueCallback: TGetActionableFirstPayloadValue<T, F>,
            actionableCallback: TActionableCallback<F>
        ) =>
            (obs$: TActionObservable<A, T>, getState: TGetState, setState: TSetState) =>
                obs$
                    .pipe(
                        rxjsOperators.mergeMap((action) =>
                            actionableCallback(
                                /* eslint-disable */
                                // @ts-ignore
                                getValueCallback(action.payload, getState, setState),
                                /* eslint-enable */
                                getState,
                                setState
                            ),
                        )
                    );
