import React from "react";
import { array } from "fp-ts";
import { pipe } from "fp-ts/lib/function";
import { requireExhaustive } from "../../../../shared/src/util";

type TPaddingSizes = "tiny" | "small" | "medium" | "large" | "x-large";

type TCRMSpacingRowProps = React.PropsWithChildren<{
    justifyContent?: "start" | "end" | "center" | "space-around" | "space-between" | "space-evenly" | "stretch";
    alignItems?: "center" | "flex-start" | "flex-end";
    spacing?: TPaddingSizes;
    childSize?: "auto" | "even-fill" | string;
    onClick?: () => void;
}>;

const getSpacingGapMeasurment = (gap: TPaddingSizes): string => {
    switch (gap) {
        case "tiny":
            return "6px";
        case "small":
            return "10px";
        case "medium":
            return "16px";
        case "large":
            return "24px";
        case "x-large":
            return "40px";
        default:
            return requireExhaustive(gap);
    }
};

const getGridTemplateColumns = (props: TCRMSpacingRowProps): string => {
    if (!props.childSize || props.childSize === "auto") {
        return getGridTemplateColumnsBasedOnVisibleChildCount(props.children, "auto");
    }

    if (props.childSize === "even-fill") {
        return getGridTemplateColumnsBasedOnVisibleChildCount(props.children, "1fr");
    }

    return props.childSize;
};

const getGridTemplateColumnsBasedOnVisibleChildCount = (children: React.ReactNode, columnSize: string): string =>
     pipe(
        React.Children.toArray(children),
        array.map(() => columnSize),
        (arr) => arr.join(" "),
    );

/**
 * @deprecated Use SpacingRow
 */
export const CRMSpacingRow = (props: TCRMSpacingRowProps): JSX.Element => (
        <div
            className="crm-spacing-row"
            style={{
                // stylelint-disable
                justifyContent: props.justifyContent || "start",
                gridColumnGap: getSpacingGapMeasurment(props.spacing || "tiny"),
                gridTemplateColumns: getGridTemplateColumns(props),
                alignItems: props.alignItems || "center",
                // stylelint-enable
            }}
            onClick={props.onClick}
        >
            {props.children}
        </div>
    );
