import React from "react";
import { Absolute } from "../BuildingBlocks/Absolute";
import { Relative } from "../BuildingBlocks/Relative";
import { CRMIcon } from "../CRMIcon/CRMIcon";
import { Underline } from "../Underline/Underline";

interface ICRMSectionLinkProps {
    href: string;
    onClick?: (e: React.MouseEvent) => void;
    target?: React.AnchorHTMLAttributes<HTMLAnchorElement>["target"];
    linkStyle: "normal" | "no_underline_or_icon" | "icon_only";
}

export class CRMLink extends React.Component<React.PropsWithChildren<ICRMSectionLinkProps>> {
    public render (): JSX.Element {
        return (
            <a
                className={`crm-link`}
                href={this.props.href}
                onClick={(event) => {
                    if (!this.props.href || typeof this.props.onClick === "function") {
                        event.preventDefault();
                    }
                    if (typeof this.props.onClick === "function") {
                        this.props.onClick(event);
                    }
                }}
                target={this.props.target}
            >
                <span
                    className={`
                        crm-link__text
                        crm-link__text--${this.props.linkStyle === "icon_only" ? "icon-only" : "icon-and-text"}
                    `}
                >

                    {this.props.linkStyle === "normal" &&
                        <Underline>
                            {this.props.children}
                        </Underline> 
                    }
                    
                    {this.props.linkStyle === "no_underline_or_icon" &&
                        <span>
                            {this.props.children}
                        </span>
                    }

                    {this.props.linkStyle !== "no_underline_or_icon" &&
                        <Relative display="inline">
                            <Absolute
                                display="inline-flex"
                                top="3px"
                                left={this.props.linkStyle === "icon_only" ? "0px" : "3px"}
                            >
                                <CRMIcon
                                    iconName="open"
                                    colour="neutral-ink"
                                    size="14px"
                                />
                            </Absolute>
                        </Relative>
                    }
                </span>
            </a>
        );
    }
}
