import { DateTime } from "luxon";
import { pipe } from "fp-ts/lib/function";

type T = string;

// ISO DateTime for 5PM today (if the time is 4PM or later this will move to the end of the next business day)
export const createEndOfBusiness = (): T =>
     pipe(
        DateTime.utc() > DateTime.fromString("16:00", "HH:mm", {zone: "Europe/London"}).toUTC()
            ? DateTime.fromString("17:00", "HH:mm", {zone: "Europe/London"}).toUTC().plus({ days: 1 })
            : DateTime.fromString("17:00", "HH:mm", {zone: "Europe/London"}).toUTC(),
        (date) => date.weekday >= 6 ? date.plus({ days: date.weekday === 6 ? 2 : 1 }) : date,
        (date) => date.toISO(),
    );


// ISO DateTime for 5PM in n days (or the next business day)
export const createEndOfBusinessDaysFromNow = (days: number): T =>
     pipe(
        DateTime.fromString("17:00", "HH:mm", {zone: "Europe/London"}).toUTC().plus({ days }),
        (date) => date.weekday >= 6 ? date.plus({ days: date.weekday === 6 ? 2 : 1 }) : date,
        (date) => date.toISO(),
    );

// ISO DateTime for 7am in n days (or the next business day)
export const createStartOfBusinessDaysFromNow = (days: number): T =>
     pipe(
        DateTime.fromString("07:00", "HH:mm", {zone: "Europe/London"}).toUTC().plus({ days }),
        (date) => date.weekday >= 6 ? date.plus({ days: date.weekday === 6 ? 2 : 1 }) : date,
        (date) => date.toISO(),
    );

// Returns ISO DateTime provided as an argument or if earlier than 9AM returns 5PM the business day before
export const createForDateTimeOrEarlier = (iso: T): T =>
     pipe(
        DateTime.fromISO(iso, {zone: "Europe/London"}).hour < 9
            ? DateTime.fromISO(iso, {zone: "Europe/London"}).minus({days: 1}).set({hour: 17, minute: 0})
            : DateTime.fromISO(iso, {zone: "Europe/London"}),
        (date) => date.weekday >= 6 ? (date.minus({ days: date.weekday === 6 ? 1 : 2 }).set({hour: 17, minute: 0})) : date,
        (date) => date.toUTC().toISO(),
    );

export const getNumberOfDaysUntilDate = (date: Date): number =>
    DateTime.fromISO(date.toISOString(), {zone: "Europe/London"}).set({hour: 0, minute: 0, second: 0, millisecond: 0}).diff(DateTime.local().set({hour: 0, minute: 0, second: 0, millisecond: 0}), "days").days;

export const getNumberOfDaysSinceDate = (date: Date): number =>
    Math.floor(DateTime.local().set({hour: 0, minute: 0, second: 0, millisecond: 0}).diff(DateTime.fromISO(date.toISOString(), { zone: "Europe/London" }).set({hour: 0, minute: 0, second: 0, millisecond: 0}), "days").days);

export const getFormattedDateString = (date: Date, format: T): string =>
    DateTime.fromISO(date.toISOString(), { zone: "Europe/London" }).toFormat(format);

export const getFormattedDateStringFromString = (date: string, format: T): string =>
    DateTime.fromISO(date, { zone: "Europe/London" }).toFormat(format);

// postgres returns the timestamp type like so: 2021-01-02 01:02:03 or 2021-01-02 01:02:03.4 or 2021-01-02 01:02:03.7592923
// this function will return a consistent output as an ISO datetime
export const fromPgTimestamp = (pgTimestamp: string): T =>
     pipe(
        [...new RegExp(/([0-9]{4}-[0-9]{2}-[0-9]{2}) ([0-9]{2}:[0-9]{2}:[0-9]{2})\.?([0-9]{0,3}).*/).exec(pgTimestamp) || []],
        ([, date, time, micro]) => `${date || ""}T${time || ""}.${(micro || "").padEnd(3, "0")}Z`,
    );
