import React from "react";
import { CRMPartyMembersForm } from "../../components/CRMPartyMembersForm/CRMPartyMembersForm";
import { TContainerStateProps } from "./../../state/TContainerStateProps";
import { CRMSpacingColumn } from "../../components/CRMSpacingColumn/CRMSpacingColumn";
import { CRMTitleSection } from "../../components/CRMTitleSection/CRMTitleSection";
import { CRMSpacer } from "../../components/CRMSpacer/CRMSpacer";
import { CRMAutosaveIndicatorWrap } from "../../components/CRM/CRMAutosaveIndicatorWrap/CRMAutosaveIndicatorWrap";
import { CRMCodecEditForm } from "../../components/CRMCodecEditForm/CRMCodecEditForm";
import { Cases4, CasesEmailPhoneVerification, Cases10, CasesCompanyAddress, CasesCompany, CasesIsTransactingAsCompany, CompanyType, CompanyType_displayString, CaseCompanyIsOverseas, CaseCompanyOverseasDetails } from "../../../../domain/codecs/Cases";
import { array } from "fp-ts";
import { pipe } from "fp-ts/lib/function";
import { FormStatus_highestPriority } from "../../../../shared/src/codecs/codec";
import { WeightBold } from "../../components/WeightBold/WeightBold";
import { CRMLink } from "../../components/CRMLink/CRMLink";
import { doesErrorKeyExist } from "../../../../shared/src/codecs/errors";
import { CRMFormErrorComponent } from "../../components/CRMFormErrorComponent/CRMFormErrorComponent";
import { CRMTitleSubSection } from "../../components/CRMTitleSubSection/CRMTitleSubSection";
import { CRMPadding } from "../../components/Simple/CRMPadding/CRMPadding";
import { CRMTitleForCard } from "../../components/CRMTitleForCard/CRMTitleForCard";
import { CRMSpacingRow } from "../../components/CRMSpacingRow/CRMSpacingRow";
import { CRMButtonSecondary } from "../../components/CRMButtonSecondary/CRMButtonSecondary";
import { requireExhaustive, snakeCaseToCopyText } from "../../../../shared/src/util";
import { CRMGenericEditModalWithText } from "../../components/CRMGenericEditModalWithText/CRMGenericEditModalWithText";
import { CRMInputLabelAndErrorWrapComponent } from "../../components/CRMInputLabelAndErrorWrapComponent/CRMInputLabelAndErrorWrapComponent";
import { CRMDropdownComponent } from "../../components/CRMDropdownComponent/CRMDropdownComponent";
import { CRMRadioListComponent } from "../../components/CRMInputs/CRMRadioListComponent/CRMRadioListComponent";
import { SpacingRow } from "../../components/BuildingBlocks/SpacingRow";
import { CRMSpacing } from "../../models/CRMSpacing";
import { CRMButtonIcon } from "../../components/CRMButtonIcon/CRMButtonIcon";
import { CRMCompanyAMLInfoComponent } from "../../components/CRMCompanyAMLInfoComponent/CRMCompanyAMLInfoComponent";
import { CRMCardInside } from "../../components/CRMCardInside/CRMCardInside";
import { CRMParagraph } from "../../components/Simple/CRMParagraph/CRMParagraph";

const CRMLegalCaseDetailsPartyContainer = (props: TContainerStateProps): JSX.Element => {
    const CompanySubTypeQuestion = (p: {
        formKey:
            "company_sub_type_listing_on_any_stock_exchange"
            | "company_sub_type_aim_listed"
            | "company_sub_type_majority_owned_or_consolidated_subsidiary"
            | "company_sub_type_fca_regulated"
            | "company_sub_type_other_non_uk_credit_or_financial_institutions"
            | "company_sub_type_uk_or_eea_public_authorities"
            | "company_sub_type_professionals_instructing"
            | "company_sub_type_foundation"
            | "company_sub_type_uk_charity"
            | "company_sub_type_place_of_worship"
            | "company_sub_type_state_school_or_college"
            | "company_sub_type_club_or_association"
            | "company_sub_type_pension_scheme"
            | "company_sub_type_sovereign_wealth_funds",
        label: string,
        guidance?: string,
    }): JSX.Element =>
        <CRMInputLabelAndErrorWrapComponent
            label={p.label}
            guidance={p.guidance}
        >
            <CRMSpacingRow spacing="tiny">
                <CRMRadioListComponent
                    value={props.state.forms.case_details_page.data.output.details.edited[p.formKey]}
                    options={[
                        {
                            value: true,
                            text: "Yes",
                        },
                        {
                            value: false,
                            text: "No",
                        },
                    ]}
                    onChange={(value) => props.dispatch({
                        type: "LEGAL_CASE_DETAILS_CHANGE",
                        payload: {
                            ...props.state.forms.case_details_page.data.output.details,
                            edited: {
                                ...props.state.forms.case_details_page.data.output.details.edited,
                                [p.formKey]: value,
                            }
                        }
                    })}
                />

                <CRMButtonIcon
                    variant="tertiary"
                    icon="backspace"
                    disabledColour="neutral-4"
                    disabled={false}
                    onClick={() => props.dispatch({
                        type: "LEGAL_CASE_DETAILS_CHANGE",
                        payload: {
                            ...props.state.forms.case_details_page.data.output.details,
                            edited: {
                                ...props.state.forms.case_details_page.data.output.details.edited,
                                [p.formKey]: null,
                            }
                        }
                    })}
                />
            </CRMSpacingRow>
        </CRMInputLabelAndErrorWrapComponent>;

    return <CRMAutosaveIndicatorWrap
        status={pipe(
            [
                ...pipe(
                    props.state.forms.case_details_page.data.output.details.children.members,
                    array.map((form) => form.status),
                ),
                ...pipe(
                    props.state.forms.case_details_page.data.output.details.children.members,
                    array.map((form) => form.children.status)
                ),
                props.state.forms.case_details_page.data.output.details.status,
            ],
            FormStatus_highestPriority,
        )}
    >
        {/* BASIC INFO */}
        <CRMTitleSection>
            Client basic information
        </CRMTitleSection>
        <CRMSpacer size="large" />
        <CRMSpacingColumn spacing="medium">
            {/* KLYANT */}
            <CRMTitleSubSection>
                Klyant
            </CRMTitleSubSection>
            <CRMLink
                href="https://app.klyant.com/login"
                linkStyle="normal"
                target="_blank"
            >
                <WeightBold>Open Klyant (in new tab)</WeightBold>
            </CRMLink>
            <CRMCodecEditForm
                codec={Cases10}
                model={props.state.forms.case_details_page.data.output.details.edited}
                columns={2}
                validationErrors={props.state.forms.case_details_page.data.output.details.validationErrors}
                onChange={(edited) => props.dispatch({
                    type: "LEGAL_CASE_DETAILS_CHANGE",
                    payload: {
                        ...props.state.forms.case_details_page.data.output.details,
                        edited: {
                            ...props.state.forms.case_details_page.data.output.details.edited,
                            ...edited,
                        }
                    }
                })}
            />

            {/* IS TRANSACTING AS A COMPANY */}
            <CRMCodecEditForm
                columns={2}
                showDeferDateLabels={true}
                codec={CasesIsTransactingAsCompany}
                model={props.state.forms.case_details_page.data.output.details.edited}
                validationErrors={props.state.forms.case_details_page.data.output.details.validationErrors}
                onChange={(edited) => props.dispatch({
                    type: "LEGAL_CASE_DETAILS_CHANGE",
                    payload: {
                        ...props.state.forms.case_details_page.data.output.details,
                        edited: {
                            ...props.state.forms.case_details_page.data.output.details.edited,
                            ...edited,
                        }
                    }
                })}
            />
        </CRMSpacingColumn>

        {/* COMPANY INFORMATION */}
        {props.state.forms.case_details_page.data.output.details.edited.is_transacting_as_company === "yes" && <>
            <CRMSpacer size="large" />
            <CRMTitleSection>
                Company Information
            </CRMTitleSection>
            <CRMSpacer size="large" />
            <CRMSpacingColumn spacing="medium">
                {/* COMPANY NAME & NUMBER */}
                <CRMCodecEditForm
                    columns={2}
                    showDeferDateLabels={true}
                    codec={CasesCompany}
                    model={props.state.forms.case_details_page.data.output.details.edited}
                    validationErrors={props.state.forms.case_details_page.data.output.details.validationErrors}
                    onChange={(edited) => props.dispatch({
                        type: "LEGAL_CASE_DETAILS_CHANGE",
                        payload: {
                            ...props.state.forms.case_details_page.data.output.details,
                            edited: {
                                ...props.state.forms.case_details_page.data.output.details.edited,
                                ...edited,
                            }
                        }
                    })}
                />

                <CRMCodecEditForm
                    columns={2}
                    showDeferDateLabels={true}
                    codec={CaseCompanyIsOverseas}
                    model={props.state.forms.case_details_page.data.output.details.edited}
                    validationErrors={props.state.forms.case_details_page.data.output.details.validationErrors}
                    onChange={(edited) => props.dispatch({
                        type: "LEGAL_CASE_DETAILS_CHANGE",
                        payload: {
                            ...props.state.forms.case_details_page.data.output.details,
                            edited: {
                                ...props.state.forms.case_details_page.data.output.details.edited,
                                ...edited,
                            }
                        }
                    })}
                />

                {props.state.forms.case_details_page.data.output.details.edited.company_is_overseas_for_transfer && 
                    <CRMCodecEditForm
                        columns={1}
                        showDeferDateLabels={true}
                        codec={CaseCompanyOverseasDetails}
                        model={props.state.forms.case_details_page.data.output.details.edited}
                        validationErrors={props.state.forms.case_details_page.data.output.details.validationErrors}
                        onChange={(edited) => props.dispatch({
                            type: "LEGAL_CASE_DETAILS_CHANGE",
                            payload: {
                                ...props.state.forms.case_details_page.data.output.details,
                                edited: {
                                    ...props.state.forms.case_details_page.data.output.details.edited,
                                    ...edited,
                                }
                            }
                        })}
                    />
                }

                {/* COMPANY ADDRESS */}
                <CRMInputLabelAndErrorWrapComponent label="Company Address">
                    <CRMGenericEditModalWithText
                        modalSize="large"
                        text={(() => {
                            const address = [
                                [
                                    props.state.forms.case_details_page.data.output.details.edited.company_sub_building_name,
                                    props.state.forms.case_details_page.data.output.details.edited.company_sub_building_number,
                                    props.state.forms.case_details_page.data.output.details.edited.company_building_name,
                                    props.state.forms.case_details_page.data.output.details.edited.company_building_number,
                                    props.state.forms.case_details_page.data.output.details.edited.company_street_name
                                ].filter((s) => s !== "").join(" "),
                                props.state.forms.case_details_page.data.output.details.edited.company_locality,
                                props.state.forms.case_details_page.data.output.details.edited.company_district,
                                props.state.forms.case_details_page.data.output.details.edited.company_city_town,
                                props.state.forms.case_details_page.data.output.details.edited.company_postcode,
                                props.state.forms.case_details_page.data.output.details.edited.company_county,
                                props.state.forms.case_details_page.data.output.details.edited.company_country === "unknown" ?
                                "" : snakeCaseToCopyText(props.state.forms.case_details_page.data.output.details.edited.company_country)
                            ].filter((s) => s !== "" && s !== null).join(", ")

                            return address === "" ? "No Address" : address;
                        })()}
                        body={
                            (setIsOpen) => (
                                <CRMPadding size="large">
                                    <CRMAutosaveIndicatorWrap
                                        status={props.state.forms.case_details_page.data.output.details.status}
                                    >
                                        <CRMTitleForCard>COMPANY ADDRESS</CRMTitleForCard>

                                        <CRMCodecEditForm
                                            codec={CasesCompanyAddress}
                                            model={props.state.forms.case_details_page.data.output.details.edited}
                                            validationErrors={props.state.forms.case_details_page.data.output.details.validationErrors}
                                            onChange={(edited) => props.dispatch({
                                                type: "LEGAL_CASE_DETAILS_CHANGE",
                                                payload: {
                                                    ...props.state.forms.case_details_page.data.output.details,
                                                    edited: {
                                                        ...props.state.forms.case_details_page.data.output.details.edited,
                                                        ...edited,
                                                    }
                                                }
                                            })}
                                            columns={2}
                                        />
                                        {/* BUTTONS */}
                                        <CRMSpacingRow justifyContent="end">
                                            <CRMButtonSecondary
                                                label="Close"
                                                onClick={() => setIsOpen(false)}
                                            />
                                        </CRMSpacingRow>
                                    </CRMAutosaveIndicatorWrap>
                                </CRMPadding>
                            )
                        }
                    />
                </CRMInputLabelAndErrorWrapComponent>

                {/* COMPANY TYPE */}
                <CRMInputLabelAndErrorWrapComponent label="What type of company is this?">
                    <CRMDropdownComponent
                        value={props.state.forms.case_details_page.data.output.details.edited.company_type}
                        options={
                            pipe(
                                CompanyType.values,
                                array.map((value) => ({
                                    value,
                                    label: CompanyType_displayString(value),
                                }))
                            )
                        }
                        onChange={(company_type) => props.dispatch({
                            type: "LEGAL_CASE_DETAILS_CHANGE",
                            payload: {
                                ...props.state.forms.case_details_page.data.output.details,
                                edited: {
                                    ...props.state.forms.case_details_page.data.output.details.edited,
                                    company_type,
                                }
                            }
                        })}
                        displayError={false}
                    />
                </CRMInputLabelAndErrorWrapComponent>

                <SpacingRow
                    spacing={CRMSpacing.MEDIUM}
                    childSize="1fr 1fr"
                    alignItems="flex-start"
                >
                    {/* COMPANY SUB TYPE: LISTED STOCK EXCHANGE */}
                    <CompanySubTypeQuestion
                        formKey="company_sub_type_listing_on_any_stock_exchange"
                        label="Is this company listed on ANY stock exchange?"
                        guidance="You should check the London Stock Exchange and others as required."
                    />

                    {/* COMPANY SUB TYPE: LISTED AIM */}
                    <CompanySubTypeQuestion
                        formKey="company_sub_type_aim_listed"
                        label="Is this company listed as an Alternative Investment Market (AIM) company?"
                    />
                </SpacingRow>
                <SpacingRow
                    spacing={CRMSpacing.MEDIUM}
                    childSize="1fr 1fr"
                    alignItems="flex-start"
                >
                    {/* COMPANY SUB TYPE: MAJORITY OWNED */}
                    <CompanySubTypeQuestion
                        formKey="company_sub_type_majority_owned_or_consolidated_subsidiary"
                        label="Is this company majority owned or a consolidated subsidiary?"
                    />
                    
                    {/* COMPANY SUB TYPE: FCA REGS */}
                    <CompanySubTypeQuestion
                        formKey="company_sub_type_fca_regulated"
                        label="Is this company regulated by the Financial Conduct Authority?"
                        guidance="Check the FCA Register."
                    />
                </SpacingRow>
                <SpacingRow
                    spacing={CRMSpacing.MEDIUM}
                    childSize="1fr 1fr"
                    alignItems="flex-start"
                >
                    {/* COMPANY SUB TYPE: NON UK CREDIT OR FINANCIAL INSTITUTION */}
                    <CompanySubTypeQuestion
                        formKey="company_sub_type_other_non_uk_credit_or_financial_institutions"
                        label="Is this company a non-UK credit or financial institutions (including nominee or trustee subsidiaries)"
                    />

                    {/* COMPANY SUB TYPE: UK OR EEA PUBLIC AUTHORITY */}
                    <CompanySubTypeQuestion
                        formKey="company_sub_type_uk_or_eea_public_authorities"
                        label="Is this company a UK or EEA public authorities or EU institution?"
                    />
                </SpacingRow>
                <SpacingRow
                    spacing={CRMSpacing.MEDIUM}
                    childSize="1fr 1fr"
                    alignItems="flex-start"
                >
                    {/* COMPANY SUB TYPE: INSTRUCTED BY PROFESSIONAL */}
                    <CompanySubTypeQuestion
                        formKey="company_sub_type_professionals_instructing"
                        label="Has this case been instructed to us by a 'Professional' (firm or individual)?"
                    />

                    {/* COMPANY SUB TYPE: FOUNDATION */}
                    <CompanySubTypeQuestion
                        formKey="company_sub_type_foundation"
                        label="Is this company a Foundation?"
                    />
                </SpacingRow>
                <SpacingRow
                    spacing={CRMSpacing.MEDIUM}
                    childSize="1fr 1fr"
                    alignItems="flex-start"
                >
                    {/* COMPANY SUB TYPE: UK CHARITY */}
                    <CompanySubTypeQuestion
                        formKey="company_sub_type_uk_charity"
                        label="Is this company a UK registered charity?"
                    />

                    {/* COMPANY SUB TYPE: CHURCHES */}
                    <CompanySubTypeQuestion
                        formKey="company_sub_type_place_of_worship"
                        label="Is this a church or place of worship (excludes Charities)?"
                    />
                </SpacingRow>
                <SpacingRow
                    spacing={CRMSpacing.MEDIUM}
                    childSize="1fr 1fr"
                    alignItems="flex-start"
                >
                    {/* COMPANY SUB TYPE: STATE SCHOOL */}
                    <CompanySubTypeQuestion
                        formKey="company_sub_type_state_school_or_college"
                        label="Is this a state school or college?"
                    />

                    {/* COMPANY SUB TYPE: CLUBS & ASSOCIATIONS */}
                    <CompanySubTypeQuestion
                        formKey="company_sub_type_club_or_association"
                        label="Is this a club or association?"
                    />
                </SpacingRow>
                <SpacingRow
                    spacing={CRMSpacing.MEDIUM}
                    childSize="1fr 1fr"
                    alignItems="flex-start"
                >
                    {/* COMPANY SUB TYPE: PENSION SCHEME */}
                    <CompanySubTypeQuestion
                        formKey="company_sub_type_pension_scheme"
                        label="Is the main product of this company a pension scheme?"
                    />

                    {/* COMPANY SUB TYPE: SOVEREIGN WEALTH FUNDS */}
                    <CompanySubTypeQuestion
                        formKey="company_sub_type_sovereign_wealth_funds"
                        label="Is this company related to a sovereign wealth fund?"
                    />
                </SpacingRow>

                {props.state.forms.case_details_page.data.output.details.edited.company_type !== "unknown" && <>
                    <CRMTitleSubSection>
                        Company AML checks to undertake
                    </CRMTitleSubSection>

                    <CRMCompanyAMLInfoComponent
                        {...props.state.forms.case_details_page.data.output.details.edited}
                    />
                </>}
            </CRMSpacingColumn>
        </>}

        {/* CLIENT USERS */}
        <CRMSpacer size="large" />
        <CRMTitleSection>
            Members
        </CRMTitleSection>
        <CRMSpacer size="large" />
        <CRMSpacingColumn spacing="medium">
            {/* 'All clients are added to case' inputs */}
            <CRMCodecEditForm
                columns={2} 
                showDeferDateLabels={true}
                codec={Cases4}
                model={props.state.forms.case_details_page.data.output.details.edited}
                validationErrors={props.state.forms.case_details_page.data.output.details.validationErrors}
                onChange={(edited) => props.dispatch({
                    type: "LEGAL_CASE_DETAILS_CHANGE",
                    payload: {
                        ...props.state.forms.case_details_page.data.output.details,
                        edited: {
                            ...props.state.forms.case_details_page.data.output.details.edited,
                            ...edited,
                        }
                    }
                })}
            />

            <CRMCardInside>
                <CRMPadding size="medium">
                    <CRMParagraph>
                        If any active client email address or phone numbers are not marked as verified by the user, think carefully before setting the following field.
                    </CRMParagraph>
                </CRMPadding>
            </CRMCardInside>

            <CRMCodecEditForm
                columns={2} 
                showDeferDateLabels={true}
                codec={CasesEmailPhoneVerification}
                model={props.state.forms.case_details_page.data.output.details.edited}
                validationErrors={props.state.forms.case_details_page.data.output.details.validationErrors}
                onChange={(edited) => props.dispatch({
                    type: "LEGAL_CASE_DETAILS_CHANGE",
                    payload: {
                        ...props.state.forms.case_details_page.data.output.details,
                        edited: {
                            ...props.state.forms.case_details_page.data.output.details.edited,
                            ...edited,
                        }
                    }
                })}
            />
            
            {doesErrorKeyExist("cases_bank_accounts", props.state.forms.case_details_page.remove_member.validationErrors) && <CRMFormErrorComponent
                errorMessage="This user is used as a recipient on a bank account and can not be deleted unless the bank account is removed."
            />}

            {/* Members form */}
            <CRMPartyMembersForm
                members={props.state.forms.case_details_page.data.output.details.children.members}
                isNotAllowedToReceiveInstructEmailDueToDualRepConsent={
                    !(
                        props.state.forms.case_details_page.data.output.details.children.dual_rep.linked_case_form
                            ? props.state.forms.case_details_page.data.output.details.children.dual_rep.linked_case_form.children.can_send_instruct_emails
                            : true
                    )
                }
                onUpdateMembers={(payload) => props.dispatch({
                    type: "LEGAL_CASE_MEMBERS_CHANGE",
                    payload,
                })}
                onUpdateMemberChild={(payload) => props.dispatch({
                    type: "LEGAL_CASE_MEMBER_USER_CASE_DATA_CHANGE",
                    payload,
                })}
                onAddMember={() => props.dispatch({
                    type: "LEGAL_CASE_DETAILS_ADD_MEMBER",
                })}
                onRemoveMember={(payload) => props.dispatch({
                    type: "LEGAL_CASE_DETAILS_REMOVE_MEMBER",
                    payload,
                })}
                createPhoneNumberStatus={
                    props.state.forms.case_details_page.create_member_number.status === "untouched" ?
                        "requiresSubmission" :
                        props.state.forms.case_details_page.create_member_number.status
                }
                onAddNewPhoneNumber={(userId) => props.dispatch({
                    type: "LEGAL_CASE_DETAILS_ADD_MEMBER_NUMBER",
                    payload: userId,
                })}
                onRemovePhoneNumber={(payload) => props.dispatch({
                    type: "LEGAL_CASE_DETAILS_REMOVE_MEMBER_NUMBER",
                    payload
                })}
                case={props.state.forms.case_details_page.data.output.details.edited}
                caseIsInstructed={
                    props.state.forms.case_details_page.data.output.details.children.status === "instructed"
                    || props.state.forms.case_details_page.data.output.details.children.status === "ready_for_exchange"
                    || props.state.forms.case_details_page.data.output.details.children.status === "exchanged"
                    || props.state.forms.case_details_page.data.output.details.children.status === "completed"
                    || props.state.forms.case_details_page.data.output.details.children.status === "closed_successfully"
                        ? true
                    : props.state.forms.case_details_page.data.output.details.children.status === "closed_created_in_error"
                    || props.state.forms.case_details_page.data.output.details.children.status === "closed_unsuccessfully"
                    || props.state.forms.case_details_page.data.output.details.children.status === "property_report"
                    || props.state.forms.case_details_page.data.output.details.children.status === "quote"
                    || props.state.forms.case_details_page.data.output.details.children.status === "closed_awaiting_opening_new_case"
                        ? false
                        : requireExhaustive(props.state.forms.case_details_page.data.output.details.children.status)
                }
                onMemberSendInstructedEmailClick={(payload) => props.dispatch({
                    type: "LEGAL_CASE_SEND_INSTRUCTED_EMAIL",
                    payload,
                })}
                onChildIdChecksChange={(payload) => props.dispatch({
                    type: "LEGAL_CASE_MEMBER_USER_CASE_UPDATE_ID_CHECK",
                    payload,
                })}
                onNewIdCheck={(payload) => props.dispatch({
                    type: "LEGAL_CASE_MEMBER_USER_CASE_NEW_ID_CHECK",
                    payload,
                })}
                onSwapMember={(payload) => props.dispatch({
                    type: "LEGAL_CASE_SWAP_MEMBER",
                    payload,
                })}
            />
        </CRMSpacingColumn>
    </CRMAutosaveIndicatorWrap>
};

export default CRMLegalCaseDetailsPartyContainer;