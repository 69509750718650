import React from "react";
import { Relative } from "../../BuildingBlocks/Relative";
import { useOpenClose } from "../../../hooks/UseOpenClose";
import { CRMPlainFilterSearchSelect } from "../CRMPlainFilterSearchSelect/CRMPlainFilterSearchSelect";
import { TPlainUser } from "../../../../../domain/codecs/User";
import { CRMColors } from "../../../models/CRMColors";
import { AvatarSize, CRMUserAvatar } from "../CRMUserAvatar/CRMUserAvatar";
import { CursorPointer } from "../../CursorPointer/CursorPointer";
import { CRMIconAvatar } from "../CRMIconAvatar/CRMIconAvatar";
import { pipe } from "fp-ts/lib/function";
import { array, option } from "fp-ts";
import { CRMPopout } from "../../Simple/CRMPopout/CRMPopout";
import { Absolute } from "../../BuildingBlocks/Absolute";
import { useHover } from "../../../hooks/useHover";
import { CRMZIndex } from "../../../models/CRMZIndex";

export const CRMAssignUserAvatar = (props: {
    size: keyof typeof AvatarSize;
    users: Array<TPlainUser>;
    value: string | null;
    onChange: (value: string) => void;
    onToggleOpen?: (isOpen: boolean) => void
}): JSX.Element => {
    
    const {
        ref,
        isOpen,
        toggleOpen,
        setIsOpen,
    } = useOpenClose({
        onToggle: props.onToggleOpen
    });

    const [hoverRef, isHovering] = useHover<HTMLDivElement>({});

    const hasBorder = isHovering || isOpen;
    const selectedUser = pipe(
        props.users,
        array.findFirst(({id}) => id === props.value),
        option.getOrElse<TPlainUser | null>(() => null),
    );
    const popoutTop = `calc(${AvatarSize[props.size]} + 3px)`;
    const popoutLeft = `calc(-300px + ${AvatarSize[props.size]})`;

    const onChange = (value: string) => {
        props.onChange(value);
        setIsOpen(false);
    };

    return (
        <Relative
            divRef={ref}
            width="fit-content"
            zIndex={CRMZIndex.SECOND_Z}
        >
            {/* AVATAR */}
            <CursorPointer
                divRef={hoverRef}
                onClick={toggleOpen}
            >
                {/* AVATAR - NOT SET */}
                {selectedUser === null &&
                    <CRMIconAvatar
                        size={props.size}
                        icon="person"
                        iconColor={CRMColors.NEUTRAL_2}
                        backgroundColor={CRMColors.NEUTRAL_10}
                        hasBorder={hasBorder}
                    />
                }

                {/* AVATAR - SET */}
                {selectedUser &&
                    <CRMUserAvatar
                        size={props.size}
                        user={selectedUser}
                        hasBorder={hasBorder}
                    />
                }
            </CursorPointer>

            {/* POPOUT */}
            {isOpen &&
                <Absolute
                    top={popoutTop}
                    left={popoutLeft}
                    width="300px"
                >
                    <CRMPopout minWidth={"300px"}>
                        <CRMPlainFilterSearchSelect
                            options={props.users.map((user) => ({
                                value: user.id,
                                label: `${user.first_name} ${user.last_name}`,
                            }))}
                            placeholder="Type staff name"
                            onClick={onChange}
                        />
                    </CRMPopout>
                </Absolute>
            }
        </Relative>
    );
}
