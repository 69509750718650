import * as ListingImage6 from "./ListingImage6";
import * as MimeType1 from "./MimeType1";
import * as S3BucketUrl from "./S3BucketUrl";

type T = {
    id: string;
    name: string;
    file_extension: string;
    url: string;
    mime_type: MimeType1.T;
};

export const fromListingImage6 = (m: ListingImage6.T, s3BaseUrl: S3BucketUrl.T): T => (
    {
        id: m.id,
        name: m.name,
        file_extension: m.file_extension,
        url: `${s3BaseUrl}/${m.id}/${m.id}.${m.file_extension}`,
        mime_type: m.mime_type,
    }
);
