import React, { ReactElement } from "react";

interface ICard {
    children: ReactElement | Array<ReactElement>;
}

class Card extends React.Component<React.PropsWithChildren<ICard>> {
    public render (): JSX.Element {
        return (
            <div className={"cardComponent"}>{this.props.children}</div>
        );
    }
}

export default Card;
