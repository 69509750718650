import React from "react";
import { TPlainUser } from "../../../../../domain/codecs/User";
import { Background } from "../../BuildingBlocks/Background";
import { SpacingRow } from "../../BuildingBlocks/SpacingRow";
import { FontQuicksand } from "../../BuildingBlocks/FontQuicksand";
import { Text } from "../../BuildingBlocks/Text";
import { CRMFontSizes } from "../../../models/CRMFontSizes";
import { CRMColors } from "../../../models/CRMColors";
import { textToHSL } from "../../../functions/colour";

export const AvatarSize = {
    tiny: "26px",
    small: "30px",
    regular: "36px"
} as const;

type TRoadblockUserAvatarProps = {
    user: TPlainUser;
    size?: keyof typeof AvatarSize,
    hasBorder?: boolean,
};

export const CRMUserAvatar = (props: React.PropsWithChildren<TRoadblockUserAvatarProps>): JSX.Element => {
    const nameToInitial = (name: string) => name.charAt(0).toUpperCase();

    const size = props.size
        ? AvatarSize[props.size]
        : AvatarSize.regular;

    const hasBorder = props.hasBorder || false;

    const boxShadowDefinition =
        hasBorder
            ? `inset 0px 0px 0px 1px ${CRMColors.NEUTRAL_0}`
            : undefined;

    return (
        <Background
            width={size}
            height={size}
            borderRadius="8px"
            color={textToHSL(`${props.user.first_name}.${props.user.last_name}`)}
            boxShadowDefinition={boxShadowDefinition}
        >
            <SpacingRow
                height={size}
                alignItems="center"
                justifyContent="center"
            >
                <FontQuicksand>
                    <Text
                        size={CRMFontSizes.SMALL}
                        color={CRMColors.NEUTRAL_INK}
                        weight={500}
                    >
                        {nameToInitial(props.user.first_name)}.{nameToInitial(props.user.last_name)}
                    </Text>
                </FontQuicksand>
            </SpacingRow>
        </Background>
    );
};
