import { DateTime } from "luxon";
import { TTimeUnitName, getDetailsOfDateDifference } from "../dateTime";
import { pipe } from "fp-ts/lib/pipeable";
import { pluralSingleText } from "../../util";
import { roundUpDateDifference } from "./roundUpDateDifference";

export const getDateDifferenceAndFormat = (
    dateToCompareAgainst: string, 
    textFormater: (count: number, unit: TTimeUnitName) => string
) =>
    (date: string): string =>
        pipe(
            date,
            getDetailsOfDateDifference(DateTime.fromISO(dateToCompareAgainst)),
            roundUpDateDifference,
            (details) => {
                if (Math.abs(details.years) > 0) {
                    return textFormater(
                        details.years,
                        pluralSingleText("year", "years")(details.years)
                    );
                }
                if (Math.abs(details.months) > 0) {
                    return textFormater(
                        details.months,
                        pluralSingleText("month", "months")(details.months)
                    );
                }
                if (Math.abs(details.weeks) > 0) {
                    return textFormater(
                        details.weeks, 
                        pluralSingleText("week", "weeks")(details.weeks)
                    );
                }
                if (Math.abs(details.days) > 0) {
                    return textFormater(
                        details.days,
                        pluralSingleText("day", "days")(details.days)
                    );
                }
                if (Math.abs(details.hours) > 0) {
                    return textFormater(
                        details.hours,
                        pluralSingleText("hour", "hours")(details.hours)
                    )
                }
                if (Math.abs(details.minutes) > 0) {
                    return textFormater(
                        details.minutes,
                        pluralSingleText("minute", "minutes")(details.minutes)
                    );
                }

                return textFormater(0, "moments");
            }
        )
    ;