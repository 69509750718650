import React from "react";
import { array } from "fp-ts/lib";
import { FormRow } from "../../components/FormTemplateBlocksComponents/FormTemplateBlocks";
import LabelWrapForInputs from "../../components/OldFront/Simple/Label/LabelWrapForInputs";
import { TUserExistingPhoneNumberActionPayload } from "../../state/applyActions";
import * as TCRMParty from "../../state/TCRMParty";
import * as JsonError1 from "../../../../domain/models/JsonError1";
import * as JsonInnerError1 from "../../../../domain/models/JsonInnerError1";
import * as FirstPartyFetchResponse from "../../../../domain/models/FirstPartyFetchResponse";
import Button from "../../components/OldFront/Simple/ButtonComponent/Button";
import InputPhoneNumber from "../../components/OldFront/Simple/PhoneNumber/InputPhoneNumber";
import { TDispatch } from "../../state/TDispatch";
import { C as State } from "../../state/State";
import { pipe } from "fp-ts/lib/function";
import { Routes } from "../../state/router/routerRoutes";

interface ICRMUserExistingPhoneNumbersContainerProps {
    dispatch: TDispatch;
    state: State<Routes>;
    userId: TCRMParty.C["users"][number]["userForm"]["meta"]["id"];
    existingPhoneNumbers: TCRMParty.C["users"][number]["existingPhoneNumbers"];
}

class CRMPartyUserExistingPhoneNumbersContainer extends React.Component<React.PropsWithChildren<ICRMUserExistingPhoneNumbersContainerProps>> {
    public render(): JSX.Element {
        return (
            <div>
                <FormRow>
                    <LabelWrapForInputs>
                        Phone numbers
                    </LabelWrapForInputs>
                </FormRow>
                {
                     pipe(
                        this.props.existingPhoneNumbers,
                        array.mapWithIndex((ip, userPhoneNumber) => (
                            <FormRow key={ip}>
                                <div className="crmPartyUsersContainer__phoneNumberContainer">
                                    <div className="crmPartyUsersContainer__cell--input">
                                        <LabelWrapForInputs
                                            errorMessage={
                                                 pipe(
                                                    JsonError1.getInnerErrorsForTargetKeys(["phone_number"], userPhoneNumber.response.validationInnerErrors),
                                                    (innerErrors) =>
                                                        array.filter<JsonInnerError1.T>((innerError) => innerError.error_code === "NotUnique")(innerErrors).length > 0 ? "This user is already associated with that number"
                                                        : array.filter<JsonInnerError1.T>((innerError) => innerError.error_code === "NonEmptyStringValidation" || innerError.error_code === "RegexValidation")(innerErrors).length > 0 ? "The phone number is invalid"
                                                        : undefined
                                                )
                                            }
                                        >
                                            <InputPhoneNumber
                                                value={userPhoneNumber.form.phone_number}
                                                onChange={(value) => this.props.dispatch({
                                                    type: "CRM_PARTY_USER_EXISTING_PHONE_NUMBER_INPUT_CHANGED",
                                                    payload: {
                                                        userId: this.props.userId,
                                                        phoneNumberId: userPhoneNumber.meta.id,
                                                        key: "phone_number",
                                                        value,
                                                    } as TUserExistingPhoneNumberActionPayload<TCRMParty.C["users"][number]["existingPhoneNumbers"][number]["form"], "phone_number">,
                                                })}
                                                onPressEnterKey={() => this.props.dispatch({
                                                    type: "CRM_PARTY_USER_SUBMIT",
                                                    payload: {
                                                        userId: this.props.userId,
                                                    },
                                                })}
                                                displayError={FirstPartyFetchResponse.hasValidationErrorsForTargetKeys(["phoneNumber"], userPhoneNumber.response)}
                                            />
                                        </LabelWrapForInputs>
                                    </div>
                                    <div className="crmPartyUsersContainer__cell">
                                        <Button
                                            type="otter"
                                            label={
                                                userPhoneNumber.form.primary_number
                                                    ? "Primary"
                                                    : "Make this primary"
                                            }
                                            onClick={() => this.props.dispatch({
                                                type: "CRM_PARTY_USER_EXISTING_PHONE_NUMBER_INPUT_CHANGED",
                                                payload: {
                                                    userId: this.props.userId,
                                                    phoneNumberId: userPhoneNumber.meta.id,
                                                    key: "primary_number",
                                                    value: true,
                                                } as TUserExistingPhoneNumberActionPayload<TCRMParty.C["users"][number]["existingPhoneNumbers"][number]["form"], "primary_number">,
                                            })}
                                            disabled={userPhoneNumber.form.primary_number}
                                        />
                                    </div>
                                    <div className="crmPartyUsersContainer__cell">
                                        <Button
                                            type="otter"
                                            label="Delete"
                                            onClick={() => this.props.dispatch({
                                                type: "CRM_PARTY_USER_EXISTING_PHONE_NUMBER_DELETE_BUTTON_CLICKED",
                                                payload: {
                                                    userId: this.props.userId,
                                                    phoneNumberId: userPhoneNumber.meta.id,
                                                },
                                            })}
                                        />
                                    </div>
                                </div>
                            </FormRow>
                        ))
                    )
                }
            </div>
        );
    }
}

export default CRMPartyUserExistingPhoneNumbersContainer;
