import React from "react";
import { CRMCodecEditForm } from "../CRMCodecEditForm/CRMCodecEditForm";
import { CRMSpacingColumn } from "../CRMSpacingColumn/CRMSpacingColumn";
import { CRMTitleSection } from "../CRMTitleSection/CRMTitleSection";
import { ListingAddress } from "../../../../domain/codecs/Listing";
import { TListingFull1Editable } from "../../../../domain/codecs/formEditable/ListingEditable";
import { CRMAutosaveIndicatorWrap } from "../CRM/CRMAutosaveIndicatorWrap/CRMAutosaveIndicatorWrap";

type TCRMListingPrepDetailsProps = {
    listingForm: TListingFull1Editable;
    onChange: (listingForm: TListingFull1Editable) => void;
};

export const CRMListingAddressForm = (props: TCRMListingPrepDetailsProps): JSX.Element => {
    return (
        <CRMSpacingColumn spacing="large">
            {/* ADDRESS */}
            <CRMAutosaveIndicatorWrap status={props.listingForm.status}>
                <CRMSpacingColumn spacing="large">
                    <CRMTitleSection>
                        Address
                    </CRMTitleSection>
                    <CRMCodecEditForm
                        codec={ListingAddress}
                        model={props.listingForm.edited}
                        validationErrors={props.listingForm.validationErrors}
                        columns={2}
                        onChange={(edited) => props.onChange({
                            ...props.listingForm,
                            edited: {
                                ...props.listingForm.edited,
                                ...edited,
                            }
                        })}
                    />
                </CRMSpacingColumn>
            </CRMAutosaveIndicatorWrap>
        </CRMSpacingColumn>
    );
};