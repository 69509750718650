import React from "react";

export const Lowercase = (props: React.PropsWithChildren<{}>): JSX.Element => {
    return (
        <span style={{
            textTransform: "lowercase"
        }}>
            {props.children}
        </span>
    );
}
