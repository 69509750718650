import React from "react";
import { CRMSpacing } from "../../../../models/CRMSpacing";
import { SpacingRow } from "../../../BuildingBlocks/SpacingRow";
import { CRMIcon, TIcon } from "../../../CRMIcon/CRMIcon";
import { WeightBold } from "../../../WeightBold/WeightBold";
import { CRMParagraph } from "../../../Simple/CRMParagraph/CRMParagraph";
 
type TBlockSelectorOptionProps = {
    isSelected: boolean;
    mode: "primary" | "secondary";
    icon: TIcon;
    label: string;
    onClick: () => void;
};

export const CRMBlockHeaderOption = (props: React.PropsWithChildren<TBlockSelectorOptionProps>): JSX.Element => {
    return (
        <div 
            className={`
                crm-block-header-option
                crm-block-header-option--${props.isSelected ? 'selected' : 'unselected'}-${props.mode}
            `}
            onClick={props.onClick}
        >
            <SpacingRow
                spacing={CRMSpacing.TINY}
                justifyContent="start"
                alignItems="center"
            >
                {/* ICON */}
                <CRMIcon
                    iconName={props.icon}
                    size="small"
                    colour="neutral-ink"
                />

                {/* LABEL */}
                <CRMParagraph>
                    <WeightBold>
                        {props.label}
                    </WeightBold>
                </CRMParagraph>
            </SpacingRow>
        </div>
    );
}