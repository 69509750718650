import React from "react";
import { requireExhaustive } from "../../../../../shared/src/util";
import { CRMTriageEmailSingleViewUnassigned } from "../CRMTriageEmailSingleViewUnassigned/CRMTriageEmailSingleViewUnassigned";
import { CRMInboxTable } from "../CRMInboxTable/CRMInboxTable";
import { TTriageBulkEmailMarkAsIrrelevantForm, TTriageEmailAssignToCaseSearchForm, TTriageEmailMarkAsIrrelevantForm, TTriageForm, TTriageSimpleEmailForm, TTriageEmailAssignToCaseAndUserForm, TTriageEmailAssignToCaseForm, TTriageEmailAssignToUserOnlyForm, TDetailedEmailForm, TTriageActiveTypeVisible, TTriageEmailMarkAsResolvedForm } from "../../../../../domain/codecs/form/TriageForm";
import { CRMDropdownControlledBlock } from "../CRMDropdownControlledBlock/CRMDropdownControlledBlock";
import { useTriage } from "../../../hooks/useTriage";
import { CRMTriageEmailSingleViewExpired } from "../CRMTriageEmailSingleViewExpired/CRMTriageEmailSingleViewExpired";
import { CRMBlockScrollCacheView, CRMBlockScrollResetView } from "../../CRM/CRMBlock/CRMBlockViews/CRMBlockViews";

type TTriageInboxProps = {
    unassignedForm: TTriageForm;
    expiredForm: TTriageForm;
    triageTypeVisible: TTriageActiveTypeVisible;
    onChangeTriageTypeVisible: (triageTypeVisible: TTriageActiveTypeVisible) => void;
    onArchive: (form: TTriageEmailMarkAsIrrelevantForm, triageType: TTriageActiveTypeVisible) => void;
    onResolve: (form: TTriageEmailMarkAsResolvedForm, triageType: TTriageActiveTypeVisible) => void;
    onChangeChaseModeForm: (form: TTriageEmailMarkAsResolvedForm) => void;
    onBulkArchive: (form: TTriageBulkEmailMarkAsIrrelevantForm, triageType: TTriageActiveTypeVisible) => void;
    onAssignToPerson: (form: TTriageEmailAssignToUserOnlyForm, triageType: TTriageActiveTypeVisible) => void;
    onAssignToCaseAndMe: (form: TTriageEmailAssignToCaseAndUserForm, triageType: TTriageActiveTypeVisible) => void;
    onAssignToCaseAndStaff: (form: TTriageEmailAssignToCaseAndUserForm, triageType: TTriageActiveTypeVisible) => void;
    onAssignToCaseAndHandler: (form: TTriageEmailAssignToCaseAndUserForm, triageType: TTriageActiveTypeVisible) => void;
    onAssignToCaseAndResolve: (form: TTriageEmailAssignToCaseForm, triageType: TTriageActiveTypeVisible) => void;
    onOpenEmail: (email: TTriageSimpleEmailForm, triageType: TTriageActiveTypeVisible) => void;
    onReply: (form: TDetailedEmailForm) => void;
    onForward: (form: TDetailedEmailForm) => void;
    onLoadMore: (triageType: TTriageActiveTypeVisible) => void;
    onChangeSearchCaseQuery: (form: TTriageEmailAssignToCaseSearchForm, triageType: TTriageActiveTypeVisible) => void;
};

export const CRMTriageInbox = (props: React.PropsWithChildren<TTriageInboxProps>): JSX.Element => {
    const {
        getTriageFormToUse,
        isPresentEmailSetToOpen,
        getOpenedEmail,
        isShowingNext,
        isShowingPrevious,
        onOpenEmail,
        onArchive,
        onResolve,
        onBulkArchive,
        onLoadMore,
        onChangeSearchText,
        closeSingleView,
        onNext,
        onPrevious,
        onAssignToCaseAndHandler,
        onAssignToCaseAndMe,
        onAssignToCaseAndResolve,
        onAssignToCaseAndStaff,
        onAssignToPerson,
    } = useTriage<TTriageActiveTypeVisible>({
        getTriageFormToUseCallback: (triageTypeVisible) =>
            triageTypeVisible === "UNASSIGNED" ? props.unassignedForm
            : triageTypeVisible === "EXPIRED" ? props.expiredForm
            : requireExhaustive(triageTypeVisible),
        ...props,
    });

    const isUnassignedEmailOpen = (): boolean =>
        isPresentEmailSetToOpen() && props.triageTypeVisible === "UNASSIGNED";

    const isExpiredEmailOpen = (): boolean =>
        isPresentEmailSetToOpen() && props.triageTypeVisible === "EXPIRED";

    return (
        <>
            {/* TABLE */}
            <CRMBlockScrollCacheView isShowing={!isPresentEmailSetToOpen()}>
                <CRMDropdownControlledBlock
                    menuMode="full_width"
                    sections={[
                        {
                            value: "UNASSIGNED",
                            label: "Unassigned",
                            badgeCount: props.unassignedForm.children.counts.available_count,
                            content: 
                                <CRMInboxTable
                                    form={props.unassignedForm}
                                    onOpenEmail={onOpenEmail}
                                    onBulkArchive={onBulkArchive}
                                    onLoadMore={onLoadMore}
                                />
                        },
                        {
                            value: "EXPIRED",
                            label: "Expired",
                            badgeCount: props.expiredForm.children.counts.available_count,
                            content: 
                                <CRMInboxTable
                                    form={props.expiredForm}
                                    onOpenEmail={onOpenEmail}
                                    onBulkArchive={onBulkArchive}
                                    onLoadMore={onLoadMore}
                                />
                        },
                    ]}
                    value={props.triageTypeVisible}
                    onValueChange={props.onChangeTriageTypeVisible}
                />
            </CRMBlockScrollCacheView>
            
            {/* SINGLE VIEWS */}
            <CRMBlockScrollResetView isShowing={isPresentEmailSetToOpen()}>
                {/* SINGLE VIEW - UNASSIGNED */}
                {isUnassignedEmailOpen() &&
                    <CRMTriageEmailSingleViewUnassigned
                        count={getTriageFormToUse().children.counts.available_count}
                        onChangeSearchText={onChangeSearchText}
                        email={getOpenedEmail()}
                        isShowingNext={isShowingNext()}
                        isShowingPrevious={isShowingPrevious()}
                        onClose={closeSingleView}
                        onNext={onNext}
                        onPrevious={onPrevious}
                        onArchive={onArchive}
                        onAssignToCaseAndMe={onAssignToCaseAndMe}
                        onAssignToCaseAndStaff={onAssignToCaseAndStaff}
                        onAssignToCaseAndHandler={onAssignToCaseAndHandler}
                        onAssignToCaseAndResolve={onAssignToCaseAndResolve}
                        onAssignToPerson={onAssignToPerson}
                        onReply={props.onReply}
                        onForward={props.onForward}
                    />
                }

                {/* SINGLE VIEW - EXPIRED */}
                {isExpiredEmailOpen() &&
                    <CRMTriageEmailSingleViewExpired
                        count={getTriageFormToUse().children.counts.available_count}
                        onChangeSearchText={onChangeSearchText}
                        email={getOpenedEmail()}
                        isShowingNext={isShowingNext()}
                        isShowingPrevious={isShowingPrevious()}
                        onClose={closeSingleView}
                        onNext={onNext}
                        onPrevious={onPrevious}
                        onResolve={onResolve}
                        onChangeChaseModeForm={props.onChangeChaseModeForm}
                        onAssignToCaseAndMe={onAssignToCaseAndMe}
                        onAssignToCaseAndStaff={onAssignToCaseAndStaff}
                        onAssignToCaseAndHandler={onAssignToCaseAndHandler}
                        onAssignToCaseAndResolve={onAssignToCaseAndResolve}
                        onAssignToPerson={onAssignToPerson}
                        onReply={props.onReply}
                        onForward={props.onForward}
                    />
                }
            </CRMBlockScrollResetView>
        </>
    );
};
