import React from "react";
import { isAPlainFunction } from "../../../functions/functions";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { CRMColors } from "../../../models/CRMColors";
import { MinHeight } from "../../BuildingBlocks/MinHeight";
import { Padding } from "../../BuildingBlocks/Padding";
import { Shape } from "../../BuildingBlocks/Shape";
import { SpacingRow } from "../../BuildingBlocks/SpacingRow";
import { CRMButtonIcon } from "../../CRMButtonIcon/CRMButtonIcon";

type TEmailSingleVIewControlsProps = {
    hideAllActions?: boolean;
    isShowingPrevious: boolean;
    isShowingNext: boolean;

    onArchive?: () => void;
    onResolve?: () => void;
    onAssignToCase?: () => void;
    onAssignToPerson?: () => void;
    onDisassociate?: () => void;
    
    onReply: () => void;
    onForward: () => void;

    onPrevious: () => void;
    onNext: () => void;
};

export const CRMEmailSingleViewControls = (props: React.PropsWithChildren<TEmailSingleVIewControlsProps>): JSX.Element => {

    const hasAnyTriageActions = () => (
        isAPlainFunction(props.onArchive) 
        || isAPlainFunction(props.onResolve)
        || isAPlainFunction(props.onAssignToCase)
        || isAPlainFunction(props.onAssignToPerson)
        || isAPlainFunction(props.onDisassociate)
    );

    return (
        <MinHeight height="42px">
            <Padding 
                type="custom"
                spacing={`${CRMSpacing.TINY} ${CRMSpacing.MEDIUM}`}
                width="100%"
            >
                <SpacingRow justifyContent="space-between">
                    <SpacingRow spacing={CRMSpacing.TINY}>
                        {/* BUTTON GROUP - TRIAGE ACTIONS */}
                        {hasAnyTriageActions() && ! props.hideAllActions &&
                            <SpacingRow spacing={CRMSpacing.TINY}>

                                {isAPlainFunction(props.onArchive) &&
                                    <CRMButtonIcon
                                        title="Archive email."
                                        variant="quaternary"
                                        icon="archive"
                                        onClick={props.onArchive}
                                    />
                                }
                                
                                {isAPlainFunction(props.onResolve) &&
                                    <CRMButtonIcon
                                        title="Mark email as resolved!"
                                        variant="quaternary"
                                        icon="done-all"
                                        onClick={props.onResolve}
                                    />
                                }
                                
                                {isAPlainFunction(props.onAssignToCase) &&
                                    <CRMButtonIcon
                                        title="Assign email to a case and person."
                                        variant="quaternary"
                                        icon="file-box"
                                        onClick={props.onAssignToCase}
                                    />
                                }

                                {isAPlainFunction(props.onAssignToPerson) &&
                                    <CRMButtonIcon
                                        title="Assign email to a person only."
                                        variant="quaternary"
                                        icon="person"
                                        onClick={props.onAssignToPerson}
                                    />
                                }
                                
                                {isAPlainFunction(props.onDisassociate) &&
                                    <CRMButtonIcon
                                        title="Disassociate from case, and return to triage."
                                        variant="quaternary"
                                        icon="unfile"
                                        onClick={props.onDisassociate}
                                    />
                                }
                            </SpacingRow>
                        }
                        
                        {/* BUTTON GROUP - EMAIL */}
                        <SpacingRow spacing={CRMSpacing.TINY}>
                            {/* BUTTON SEPERATOR */}
                            {hasAnyTriageActions() && ! props.hideAllActions &&
                                <Shape
                                    height="100%" 
                                    width="2px"
                                    color={CRMColors.NEUTRAL_INK} 
                                />
                            }

                            {/* BUTTON - REPLY */}
                            {
                                ! props.hideAllActions &&
                                    <CRMButtonIcon
                                    title="Reply"
                                    variant="primary"
                                    icon="reply"
                                    onClick={props.onReply}
                                />
                            }
                            {/* BUTTON - FORWARD */}
                            {
                                ! props.hideAllActions &&
                                <CRMButtonIcon
                                    title="Forward"
                                    variant="primary"
                                    icon="forward-arrow"
                                    onClick={props.onForward}
                                />
                            }


                            
                        </SpacingRow>
                    </SpacingRow>
                    
                    {/* BUTTON GROUP - NAVIGATION */}
                    <SpacingRow spacing={CRMSpacing.TINY}>
                        {props.isShowingPrevious &&
                            <CRMButtonIcon
                                variant="tertiary"
                                icon="arrow-left"
                                onClick={props.onPrevious}
                            />
                        }

                        {props.isShowingNext &&
                            <CRMButtonIcon
                                variant="tertiary"
                                icon="arrow-right"
                                onClick={props.onNext}
                            />
                        }
                    </SpacingRow>
                </SpacingRow>
            </Padding>
        </MinHeight>
    );
};
