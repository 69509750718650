import * as rxjs from "rxjs";
import * as JsonInnerError1 from "./../../../../domain/models/JsonInnerError1";
import * as TFormStatus from "../../models/TFormStatus";
import * as TForm from "../../models/TForm";
import { TState } from "./lensBaseTypes";
import { reduceDataToStateUpdate } from "./reduceDataToStateUpdate";
import { set } from "./set";
import { pipe } from "fp-ts/lib/function";
import { TSetState } from "../../state/TSetState";
import { TValidationError } from "../../../../shared/src/validation/Error";

interface ILensHandleFormSubmitErrors<S> {
    // 5 level lens path guard
    <
        K1 extends keyof S,
        K2 extends keyof S[K1],
        K3 extends keyof S[K1][K2],
        K4 extends keyof S[K1][K2][K3] & "forms",
        K5 extends keyof S[K1][K2][K3][K4] & number
    >
    (
        path: [K1, K2, K3, K4, K5],
    ): (error: TValidationError) => rxjs.Observable<never>;
    // 4 level lens path guard
    <
        K1 extends keyof S,
        K2 extends keyof S[K1],
        K3 extends keyof S[K1][K2] & "forms",
        K4 extends keyof S[K1][K2][K3] & number
    >
    (
        path: [K1, K2, K3, K4],
    ): (error: TValidationError) => rxjs.Observable<never>;
    // 3 level lens path guard
    <
        K1 extends keyof S,
        K2 extends keyof S[K1] & "forms",
        K3 extends keyof S[K1][K2] & number
    >
    (
        path: [K1, K2, K3],
    ): (error: TValidationError) => rxjs.Observable<never>;
}

export const handleFormSubmitErrorsInFormList = (setState: TSetState): ILensHandleFormSubmitErrors<TState> =>
    // There is no way to create paramater overloads here so we set to any
    (lensPath: any) => // eslint-disable-line
        (errors: TValidationError) => {
            reduceDataToStateUpdate<TValidationError>(setState)((p) =>
                 pipe(
                    p,
                    set<TValidationError>()(
                        lensPath,
                        /* eslint-disable */
                        // The type is always expected to be never for the form, since we have abstraced the lens scoping,
                        // which is untrue
                        // @ts-ignore
                        (updateForm: TForm.TFormV2<{}, {}>) => {
                            updateForm.status = TFormStatus.constants.VALIDATION_ERROR;
                            updateForm.validationErrors = JsonInnerError1.arrayFromValidationErrors("Body", errors);
                            return updateForm;
                        }
                        /* eslint-enable */
                    ),
                    set<TValidationError>()(
                        lensPath.slice(0, lensPath.length - 2),
                        /* eslint-disable */
                        // @ts-ignore
                        (updateForm: TForm.TFormV2<{}, {}>) => {
                            updateForm.status = TFormStatus.constants.VALIDATION_ERROR;
                            return updateForm;
                        }
                        /* eslint-enable */
                    ),
                )
            )(errors);
            return rxjs.EMPTY;
        };
