import { array } from "fp-ts";
import { pipe } from "fp-ts/lib/function";
import { doesKeyExist } from "../record";
import { allMatchPredicate } from "../array";

export const doKeysExist = (keys: Array<string>) =>
    (record: Record<string, unknown>): boolean => pipe(
        keys,
        array.map(doesKeyExist(record)),
        allMatchPredicate<boolean>((value) => value === true)
    )