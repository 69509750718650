import * as QueryStringFilterMappings from "./QueryStringFilterMappings";
import * as QueryStringFilterGroup1 from "./QueryStringFilterGroup1";
import * as QueryStringFilterMappingFunction from "./QueryStringFilterMappingFunction";
import { nonEmptyArray, array } from "fp-ts/lib";
import { pipe } from "fp-ts/lib/function";
import { TValidationFunction } from "../../shared/src/validation/Function";

export const filterMappings: QueryStringFilterMappings.T = {
    string: {
        party_id: QueryStringFilterMappingFunction.createForString("listings_enquiries.party_id"),
        listing_id: QueryStringFilterMappingFunction.createForString("listings_enquiries.listing_id"),
        enquiry_id: QueryStringFilterMappingFunction.createForString("listings_enquiries.id"),
        status: QueryStringFilterMappingFunction.createForString("listings_enquiries_viewings.status"),
    },
    date: {
        requested_time: QueryStringFilterMappingFunction.createForDateInArray("listings_enquiries_viewings.requested_times"),
        confirmed_time: QueryStringFilterMappingFunction.createForDate("listings_enquiries_viewings.confirmed_time"),
    },
};

export const validator: TValidationFunction = QueryStringFilterGroup1.createValidator(filterMappings);

export const createForPartyId = (partyId: string): QueryStringFilterGroup1.T => ({
    type: "GROUP",
    conditional: "AND",
    filters: [
        {
            type: "FILTER",
            property: "party_id",
            operator: "EQUALS_STRING",
            value: partyId,
        },
    ],
});

export const createForListingIdOnlyFutureNotCancelled = (listingId: string, currentIsoDateTime: string): QueryStringFilterGroup1.T => ({
    type: "GROUP",
    conditional: "AND",
    filters: [
        {
            type: "GROUP",
            conditional: "OR",
            filters: [{
                type: "FILTER",
                property: "listing_id",
                operator: "EQUALS_STRING",
                value: listingId,
            }],
        },
        {
            type: "GROUP",
            conditional: "OR",
            filters: [{
                type: "FILTER",
                property: "status",
                operator: "NOT_EQUALS_STRING",
                value: "cancelled",
            }],
        },
        {
            type: "GROUP",
            conditional: "OR",
            filters: [
                {
                    type: "FILTER",
                    property: "requested_time",
                    operator: "GREATER_THAN_DATE",
                    value: currentIsoDateTime,
                },
                {
                    type: "FILTER",
                    property: "confirmed_time",
                    operator: "GREATER_THAN_DATE",
                    value: currentIsoDateTime,
                },
            ],
        },
    ],
});

export const createForListingIdsOnlyFuture = (listingIds: nonEmptyArray.NonEmptyArray<string>, currentIsoDateTime: string): QueryStringFilterGroup1.T => ({
    type: "GROUP",
    conditional: "AND",
    filters: [
        {
            type: "GROUP",
            conditional: "OR",
            filters:  pipe(
                listingIds,
                array.map((listingId) => ({
                    type: "FILTER",
                    property: "listing_id",
                    operator: "EQUALS_STRING",
                    value: listingId,
                })),
            ),
        },
        {
            type: "GROUP",
            conditional: "OR",
            filters: [
                {
                    type: "FILTER",
                    property: "requested_time",
                    operator: "GREATER_THAN_DATE",
                    value: currentIsoDateTime,
                },
                {
                    type: "FILTER",
                    property: "confirmed_time",
                    operator: "GREATER_THAN_DATE",
                    value: currentIsoDateTime,
                },
            ],
        },
    ],
});

export const createForListingIdOnlyPastAndConfirmed = (listingId: string, currentIsoDateTime: string): QueryStringFilterGroup1.T => ({
    type: "GROUP",
    conditional: "AND",
    filters: [
        {
            type: "GROUP",
            conditional: "OR",
            filters: [{
                type: "FILTER",
                property: "listing_id",
                operator: "EQUALS_STRING",
                value: listingId,
            }],
        },
        {
            type: "GROUP",
            conditional: "OR",
            filters: [{
                type: "FILTER",
                property: "confirmed_time",
                operator: "LESS_THAN_DATE",
                value: currentIsoDateTime,
            }],
        },
    ],
});

export const createForEnquiryId = (enquiryId: string): QueryStringFilterGroup1.T => ({
    type: "GROUP",
    conditional: "AND",
    filters: [
        {
            type: "FILTER",
            property: "enquiry_id",
            operator: "EQUALS_STRING",
            value: enquiryId,
        },
    ],
});
