import React from "react";
import { TContainerStateProps } from "./../../state/TContainerStateProps";
import { snakeCaseToCopyText } from "../../../../shared/src/util";
import { CRMCardOutsideLabelled } from "../../components/CRMCardOutsideLabelled/CRMCardOutsideLabelled";
import { CRMParagraph } from "../../components/Simple/CRMParagraph/CRMParagraph";
import { WeightBold } from "../../components/WeightBold/WeightBold";
import { CRMLink } from "../../components/CRMLink/CRMLink";
import { SpacingColumn } from "../../components/BuildingBlocks/SpacingColumn";

const CRMCaseMainDetailsCardContainer = (props: TContainerStateProps): JSX.Element => {
    const getCaseStatusAsCopyTest = () => snakeCaseToCopyText(props.state.forms.case_details_page.data.output.details.children.status);

    const getCaseTransactionType = () => props.state.forms.case_details_page.data.output.details.children.transaction_type;

    const getSellerReasonForSale = () => props.state.forms.case_details_page.data.output.details.children.seller_reason_for_sale;

    const getCaseAddressesCopyText = (): string => {
        return props.state.forms.case_details_page.data.output.properties.map((p) => p.address_short_string).join(" & ")
    };
    
    return (
        <CRMCardOutsideLabelled
            labelColor="highlights-calming-pink-4"
            shadow={true}
        >
            <CRMParagraph>
                <SpacingColumn spacing="medium">
                    <SpacingColumn spacing="tiny">
                        <div><WeightBold>{getCaseStatusAsCopyTest()} ({getCaseTransactionType()}{getSellerReasonForSale() === "unknown" ? "" : `/${getSellerReasonForSale()}`}):</WeightBold></div>
                        <div>{getCaseAddressesCopyText()}</div>
                    </SpacingColumn>
                    {(
                        props.state.forms.case_details_page.data.output.details.children.transaction_type === "sale"
                        || props.state.forms.case_details_page.data.output.details.children.transaction_type === "purchase"
                    ) && props.state.forms.case_details_page.data.output.details.children.related_transaction.related_data.children.linked_to?.children.address
                    && props.state.forms.case_details_page.data.output.details.edited.related_transaction_needed_before_completion === "yes"
                    && <SpacingColumn spacing="tiny">
                        <div>
                            <WeightBold>
                                Related {
                                    getCaseTransactionType() === "purchase" ? "sale"
                                    : getCaseTransactionType() === "sale" ? "purchase"
                                    : "transaction"
                                }:&nbsp;
                            </WeightBold>
                        </div>
                        <div>
                            {props.state.forms.case_details_page.data.output.details.children.related_transaction.related_data.children.linked_to?.children.related_case_id
                                ? <CRMLink
                                    linkStyle="normal"
                                    href={`/crm/legal/cases/${props.state.forms.case_details_page.data.output.details.children.related_transaction.related_data.children.linked_to?.children.related_case_id}`}
                                    target="_blank"
                                >{props.state.forms.case_details_page.data.output.details.children.related_transaction.related_data.children.linked_to?.children.address}</CRMLink>
                                : `${props.state.forms.case_details_page.data.output.details.children.related_transaction.related_data.children.linked_to?.children.address} (external case)`}
                        </div>
                    </SpacingColumn>}
                    <div><WeightBold>Klyant Matter ID:</WeightBold> {props.state.forms.case_details_page.data.output.details.edited.klyant_matter_id.trim() !== "" ? props.state.forms.case_details_page.data.output.details.edited.klyant_matter_id: "Not Set"}</div>
                </SpacingColumn>
            </CRMParagraph>
        </CRMCardOutsideLabelled>
    );
}

export default CRMCaseMainDetailsCardContainer;