import React from "react";
import { TListingFull1Editable } from "../../../../domain/codecs/formEditable/ListingEditable";
import { ListingSellingProcess1, ListingSellingProcess2, ListingSellingProcess3, ListingSellingProcess5 }from "../../../../domain/codecs/Listing";
import { CRMAutosaveIndicatorWrap } from "../CRM/CRMAutosaveIndicatorWrap/CRMAutosaveIndicatorWrap";
import { CRMCodecEditForm } from "../CRMCodecEditForm/CRMCodecEditForm";
import { CRMDropdownComponent } from "../CRMDropdownComponent/CRMDropdownComponent";
import { CRMSpacingColumn } from "../CRMSpacingColumn/CRMSpacingColumn";
import { CRMTitleSection } from "../CRMTitleSection/CRMTitleSection";
import { array } from "fp-ts/lib";
import { pipe } from "fp-ts/function";
import CRMInputLabelAndErrorWrapComponent from "../CRMInputLabelAndErrorWrapComponent/CRMInputLabelAndErrorWrapComponent";
import { CRMLink } from "../CRMLink/CRMLink";
import { WeightBold } from "../WeightBold/WeightBold";
import { TListingDataFormIO } from "../../../../domain/codecs/formIO/ListingDataFormIO";
import { DateTime } from "luxon";
import { CRMInputCurrency } from "../CRMInputCurrency/CRMInputCurrency";
import { CRMYesNoBooleanRadioFormInput } from "../CRMFormInputs/CRMYesNoBooleanRadioFormInput";
import { CRMCardInside } from "../CRMCardInside/CRMCardInside";
import { CRMPadding } from "../Simple/CRMPadding/CRMPadding";
import { CRMParagraph } from "../Simple/CRMParagraph/CRMParagraph";

type TCRMListingSellingProcessFormProps = {
    listingPageData: TListingDataFormIO;
    visibleSections?: string;
    onChange: (listingForm: TListingFull1Editable) => void;
};

export const CRMListingSellingProcessForm = (props: TCRMListingSellingProcessFormProps): JSX.Element => {

    return (
        <CRMAutosaveIndicatorWrap status={props.listingPageData.output.form.status}>
            <CRMSpacingColumn spacing="large">
                <CRMTitleSection>
                    Selling Process
                </CRMTitleSection>
                <CRMCodecEditForm
                    codec={ListingSellingProcess1}
                    model={props.listingPageData.output.form.edited}
                    validationErrors={props.listingPageData.output.form.validationErrors}
                    onChange={(edited) => props.onChange(
                        {
                            ...props.listingPageData.output.form,
                            edited: {
                                ...props.listingPageData.output.form.edited,
                                ...edited
                            }
                        }
                    )}
                    overrideComps={{
                        introducer_id: (p) =>
                            <CRMInputLabelAndErrorWrapComponent label="Who is the introducer?">
                                <CRMDropdownComponent
                                    value={p.model || ""}
                                    options={pipe(
                                        props.listingPageData.output.read_only.introducers,
                                        array.map(({id, name}) => ({
                                            value: id,
                                            label: name,
                                        }))
                                    )}
                                    onChange={p.onChange}
                                    displayError={false}
                                    disabled={props.listingPageData.output.form.edited.has_introducer === false}
                                />
                            </CRMInputLabelAndErrorWrapComponent>
                    }}
                />
                <CRMInputLabelAndErrorWrapComponent label="Referral fee">
                    <CRMInputCurrency
                        mode="pence"
                        disabled={true}
                        value={props.listingPageData.output.form.edited.referral_fee_pence}
                        onChange={(edited) => props.onChange(
                            {
                                ...props.listingPageData.output.form,
                                edited: {
                                    ...props.listingPageData.output.form.edited,
                                    referral_fee_pence: edited
                                }
                            }
                        )}
                    />
                </CRMInputLabelAndErrorWrapComponent>

                
                <CRMInputLabelAndErrorWrapComponent label="Skip sail legal referral fee subtraction">
                    <CRMCardInside>
                        <CRMPadding size="medium">
                            <CRMParagraph>
                                The following field is to be set to true only if Sail Homes are doing the estate agency, but Sail Legal are not doing the conveyancing.
                            </CRMParagraph>
                        </CRMPadding>
                    </CRMCardInside>
                    <CRMYesNoBooleanRadioFormInput
                        label={""}
                        model={props.listingPageData.output.form.edited.skip_sail_legal_referral_fee_subtraction}
                        onChange={(edited) => props.onChange(
                            {
                                ...props.listingPageData.output.form,
                                edited: {
                                    ...props.listingPageData.output.form.edited,
                                    skip_sail_legal_referral_fee_subtraction: edited
                                }
                            }
                        )}
                        displayError={false}
                    />
                </CRMInputLabelAndErrorWrapComponent>
                <CRMCodecEditForm
                    codec={ListingSellingProcess5}
                    model={props.listingPageData.output.form.edited}
                    validationErrors={props.listingPageData.output.form.validationErrors}
                    onChange={(edited) => props.onChange(
                        {
                            ...props.listingPageData.output.form,
                            edited: {
                                ...props.listingPageData.output.form.edited,
                                ...edited,
                                viewings_can_be_booked_from: edited.viewings_can_be_booked_from === null && edited.instructed_date !== null
                                    ? DateTime.fromISO(edited.instructed_date ?? "").setZone("Europe/London").toUTC().toString()
                                    : edited.viewings_can_be_booked_from
                            }
                        }
                    )}
                />

                {/* VIEWINGS CONDUCTED BY DESIGNATED USER (IN SELLER PARTY) */}
                {props.listingPageData.output.form.edited.viewings_conducted_by === "designated_person" &&
                    <CRMSpacingColumn spacing="large">
                        <CRMLink
                            href={`/crm/listing/${props.listingPageData.input.listing_id}?visible_tab=seller_details&visible_sections=`}
                            target="_blank"
                            linkStyle="normal"
                        >
                            <WeightBold>Add seller party member</WeightBold>
                        </CRMLink>
                        <CRMInputLabelAndErrorWrapComponent label="Viewings designated person">
                            <CRMDropdownComponent
                                placeholder="Select user"
                                options={pipe(
                                    props.listingPageData.output.read_only.all_seller_party_users_dropdown,
                                    array.map((user) => ({
                                        value: user.id,
                                        label: `${user.first_name} ${user.last_name}${user.is_legal_seller ? " (legal seller)" : ""}`
                                    })),
                                    (userOptions) => [{value: "unknown", label: "Unknown"}, ...userOptions]
                                )}
                                value={props.listingPageData.output.form.edited.viewings_conducted_by_user_id || "unknown"}
                                displayError={false}
                                onChange={(userId) => props.onChange({
                                    ...props.listingPageData.output.form,
                                    edited: {
                                        ...props.listingPageData.output.form.edited,
                                        ...{
                                            viewings_conducted_by_user_id: props.listingPageData.output.form.edited.viewings_conducted_by === "designated_person" &&
                                                userId !== "unknown" ? userId : null
                                        },
                                    }
                                })}
                            />
                        </CRMInputLabelAndErrorWrapComponent>
                    </CRMSpacingColumn>
                }

                {/* VIEWINGS CONDUCTED BY OCCUPIER */}
                {props.listingPageData.output.form.edited.viewings_conducted_by === "occupier" &&
                    <CRMSpacingColumn spacing="large">
                        <CRMLink
                            href={`/crm/listing/${props.listingPageData.input.listing_id}?visible_tab=seller_details&visible_sections=`}
                            target="_blank"
                            linkStyle="normal"
                        >
                            <WeightBold>Add seller party member</WeightBold>
                        </CRMLink>
                        <CRMInputLabelAndErrorWrapComponent label="Viewings occupier">
                            <CRMDropdownComponent
                                placeholder="Select user"
                                options={pipe(
                                    props.listingPageData.output.read_only.all_seller_party_users_dropdown,
                                    array.map((user) => ({
                                        value: user.id,
                                        label: `${user.first_name} ${user.last_name}${user.is_legal_seller ? " (Legal seller)" : ""}`
                                    })),
                                    (userOptions) => [{value: "unknown", label: "Unknown"}, ...userOptions]
                                )}
                                value={props.listingPageData.output.form.edited.occupier_user_id || "unknown"}
                                displayError={false}
                                onChange={(userId) => props.onChange({
                                    ...props.listingPageData.output.form,
                                    edited: {
                                        ...props.listingPageData.output.form.edited,
                                        ...{
                                            occupier_user_id: userId === "unknown" ? null : userId,
                                        },
                                    }
                                })}
                            />
                        </CRMInputLabelAndErrorWrapComponent>
                    </CRMSpacingColumn>
                }

                {/* VIEWINGS BY VIEWBER - VIEWBER DETAILS UPDATED */}
                {props.listingPageData.output.form.edited.viewings_conducted_by === "viewber" &&
                    <CRMCodecEditForm
                        codec={ListingSellingProcess2}
                        model={props.listingPageData.output.form.edited}
                        validationErrors={props.listingPageData.output.form.validationErrors}
                        onChange={(edited) => props.onChange({
                            ...props.listingPageData.output.form,
                            edited: {
                                ...props.listingPageData.output.form.edited,
                                ...edited,
                            }
                        })}
                    />
                }

                {/* DEFER ALL VIEWINGS MANAGEMENT JOBS */}
                <CRMCodecEditForm
                    codec={ListingSellingProcess3}
                    model={props.listingPageData.output.form.edited}
                    validationErrors={props.listingPageData.output.form.validationErrors}
                    onChange={(edited) => props.onChange({
                        ...props.listingPageData.output.form,
                        edited: {
                            ...props.listingPageData.output.form.edited,
                            ...edited,
                        }
                    })}
                />
            </CRMSpacingColumn>
        </CRMAutosaveIndicatorWrap>
    );
};
