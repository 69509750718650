import React, { Fragment } from "react";

type TEmailTemplatePaddingProps = {
    padding: [
        number, 
        number | undefined, 
        number | undefined, 
        number | undefined
    ],
    align?: "left" | "center" | "right",
    valign?: "top" | "middle" | "bottom",
};


export const EmailTemplatePadding = (props: React.PropsWithChildren<TEmailTemplatePaddingProps>): JSX.Element => {

    const paddingTop = props.padding[0]
    const paddingRight = (typeof props.padding[1] !== 'undefined') ? props.padding[1] : paddingTop
    const paddingBottom = (typeof props.padding[2] !== 'undefined') ? props.padding[2] : paddingTop
    const paddingLeft = (typeof props.padding[3] !== 'undefined') ? props.padding[3] : paddingRight

    return (
        <Fragment>
            
            {/* TOP PADDING SPACER */}
            {(paddingTop !== 0) &&
                <tr>
                    <td
                        style={{
                            fontSize: "1px",
                            lineHeight: "1px"
                        }}
                        height={paddingTop}
                    >
                        &nbsp;
                    </td>
                </tr>
            }

            {/* CONTENT */}
            <tr>
                <td
                    valign="middle"
                    style={{
                        verticalAlign: props.valign,
                    }}
                >

                    {(props.align || (paddingLeft !== 0) || (paddingRight !== 0)) &&
                        <table
                            style={{
                                textAlign: props.align,
                                margin: props.align === "center" ? "0 auto" : ""
                            }}
                        >
                            <tbody>
                                <tr>
                                    {(paddingLeft !== 0) &&
                                        <td width={paddingLeft} />
                                    }

                                <td style={{
                                    textAlign: props.align
                                }}>
                                    {props.children}
                                </td>

                                {(paddingRight !== 0) &&
                                    <td width={paddingRight} />
                                }
                                </tr>
                            </tbody>
                        </table>
                    }
                    
                    {!(props.align || paddingLeft !== 0 || paddingRight !== 0) &&
                        <Fragment>
                            {props.children}
                        </Fragment>
                    }
                </td>
            </tr>
            
            {/* BOTTOM PADDING SPACER */}
            {(paddingBottom !== 0) &&
                <tr>
                    <td
                        style={{
                            fontSize: "1px",
                            lineHeight: "1px"
                        }}
                        height={paddingBottom}
                    >
                        &nbsp;
                    </td>
                </tr>
            }
        </Fragment>
    );
};
