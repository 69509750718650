import * as Function from "../Function";
import * as Error from "../Error";
import * as ErrorCode from "../ErrorCode";
import { option } from "fp-ts/lib";
import { DateTime } from "luxon";

export const stringIsoUtcDate: Function.TValidationFunction = (value: unknown): option.Option<Error.TValidationError> =>
    typeof value === "undefined" || (
        typeof value === "string"
        && /^\d{4}-\d{2}-\d{2}$/.test(value)
        && DateTime.fromISO(value).isValid
    )
        ? option.none
        : option.some([[ErrorCode.validationErrorCodeConstants.UTC_DATE_VALIDATION]]);
