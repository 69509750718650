import React from "react";
import { CRMParagraphInline } from "../Simple/CRMParagraphInline/CRMParagraphInline";
import { CRMUniversalBulletPoints } from "../CRMUniversalBulletPoints/CRMUniversalBulletPoints";
import { TextColor } from "../BuildingBlocks/TextColor";

type TCRMBulletList = {
    bullets: Array<JSX.Element | string>;
    bulletColor?: string;
    textColour?: string;
};

export class CRMParagraphBulletPoints extends React.Component<React.PropsWithChildren<TCRMBulletList>> {
    public render (): JSX.Element {
        return (
            <CRMUniversalBulletPoints
                bulletColor={this.props.bulletColor}
                points={this.props.bullets.map((bullet, index) =>
                    <CRMParagraphInline key={index}>
                        {!this.props.textColour && <>
                            {bullet}
                        </>}

                        {this.props.textColour && <TextColor color={this.props.textColour}>
                            {bullet}
                        </TextColor>}
                    </CRMParagraphInline>
                )}
            />
        );
    }
}
