import React, { useState } from "react";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { TProbateHelplineCallbackForm } from "../../../../../domain/codecs/form/ProbateHelplineCallbackForm";
import { SpacingColumn } from "../../BuildingBlocks/SpacingColumn";
import { CRMTitleSubSection } from "../../CRMTitleSubSection/CRMTitleSubSection";
import { CRMParagraph } from "../../Simple/CRMParagraph/CRMParagraph";
import { WeightBold } from "../../WeightBold/WeightBold";
import { CRMRichTextArea } from "../../CRMRichTextArea/CRMRichTextArea";
import { ContentState, EditorState } from "draft-js";
import { convertFromHTML, convertToHTML } from "draft-convert";
import { DateTime } from "luxon";
import { SpacingRow } from "../../BuildingBlocks/SpacingRow";
import { CRMIcon } from "../../CRMIcon/CRMIcon";
import { getAmountOfTimeInFutureFromNow } from "../../CRMDeferButtonComponent/CRMDeferButtonComponent";

export const CRMProbateHelplineSingleViewNotesAndDefer = (props: React.PropsWithChildren<{
    form: TProbateHelplineCallbackForm,
    onChange: (form: TProbateHelplineCallbackForm) => void,
}>): JSX.Element => {
    const isDeferred =
        props.form.edited.defer_timestamp !== null
        && DateTime.fromISO(props.form.edited.defer_timestamp).toSeconds() > DateTime.utc().toSeconds();

    const externalContentState = props.form.edited.notes === ""
        ? ContentState.createFromText("")
        : convertFromHTML(props.form.edited.notes);

    const [editorState, setEditorState] = useState(() => EditorState.createWithContent(externalContentState));

    const onNotesChange = (value: EditorState) => {
        setEditorState(value);
        props.onChange({
            ...props.form,
            edited: {
                ...props.form.edited,
                notes: convertToHTML(value.getCurrentContent()).toString(),
            },
        });
    };

    return <SpacingColumn spacing={CRMSpacing.MEDIUM}>
        <CRMTitleSubSection>
            Notes
        </CRMTitleSubSection>

        {/* NOTES */}
        <CRMRichTextArea
            placeholder="Write something here..."
            contentState={editorState}
            onChange={onNotesChange}
        />

        {/* DEFER */}
        {isDeferred &&
            <SpacingRow
                spacing={CRMSpacing.TINY}
                justifyContent="start"
                alignItems="flex-start"
            >
                {/* ICON */}
                <CRMIcon
                    iconName="snooze"
                    size="small"
                    colour="neutral-ink"
                />

                {/* LABEL */}
                <CRMParagraph>
                    Callback deferred:&nbsp;
                    <WeightBold>
                        {getAmountOfTimeInFutureFromNow(DateTime.fromISO(props.form.edited.defer_timestamp || ""))}
                    </WeightBold>
                </CRMParagraph>
            </SpacingRow>
        }
    </SpacingColumn>;
};
