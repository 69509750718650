import React, { useEffect, useState } from "react";
import { onCallIfExists } from "../functions/functions";

export type TCycleRange = [number, number];

type TUseNumberRangeCyclingProps = {
    range: TCycleRange;
    defaultNumber?: number;
    onChange?: () => void;
};

export const cycleNumberThroughRange = (range: TCycleRange, currentNumber: number, cycleAmount: number): number => {
    const [rangeFloor, rangeCeiling] = range;
    const resultAfterCycle = currentNumber + cycleAmount;
    if (resultAfterCycle > rangeCeiling) {
        return rangeFloor;
    }
    if (resultAfterCycle < rangeFloor) {
        return rangeCeiling;
    }
    return resultAfterCycle;
}

export const useNumberRangeCycling = (props: TUseNumberRangeCyclingProps) => {

    const [number, setNumber] = useState<number>(props.defaultNumber || props.range[0]);

    const cycleUp = () => setNumber(
        cycleNumberThroughRange(props.range, number, +1)
    );
    const cycleDown = () => setNumber(
        cycleNumberThroughRange(props.range, number, -1)
    );

    return {
        number,
        cycleUp,
        cycleDown,
    };
}