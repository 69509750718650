import React from "react";
import { TRoadblocksListItem2 } from "../../../../../domain/codecs/form/RoadblockForm";
import { SpacingRow } from "../../BuildingBlocks/SpacingRow";
import { Background } from "../../BuildingBlocks/Background";
import { CRMUserAvatar } from "../../Complex/CRMUserAvatar/CRMUserAvatar";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { CRMParagraph } from "../../Simple/CRMParagraph/CRMParagraph";
import { WeightBold } from "../../WeightBold/WeightBold";
import { capitaliseFirst, underscoreCaseToNormalCase } from "../../../../../shared/src/utilsByDomain/string";
import { pipe } from "fp-ts/lib/pipeable";
import { getDateDifferenceAndFormat } from "../../../../../shared/src/utilsByDomain/dateTime/getDateDifferenceAndFormat";
import { getDaysLeftUntilEndDate } from "../../../../../shared/src/utilsByDomain/dateTime/getDaysLeftUntilEndDate";
import { CRMColors } from "../../../models/CRMColors";
import { WeightMedium } from "../../WeightMedium/WeightMedium";

type TRoadblockTableListItemProps = {
    item: TRoadblocksListItem2;
    onClick: (roadblock: TRoadblocksListItem2) => void;
};

export const CRMRoadblockTableListItem = (props: React.PropsWithChildren<TRoadblockTableListItemProps>): JSX.Element => {

    const IsOpenStatus = props.item.status === "open";
    const ListItemName = pipe(
        props.item.type,
        underscoreCaseToNormalCase,
        capitaliseFirst
    );
    const DaysTilSla = getDaysLeftUntilEndDate(props.item.opened_on, props.item.sla);
    const HighlightColor =
        IsOpenStatus && DaysTilSla < 0 ? CRMColors.HIGHLIGHTS_INSTRUCTIONAL_BRICK_10
        : IsOpenStatus && DaysTilSla < 3 ? CRMColors.HIGHLIGHTS_PERTINENT_YELLOW_8
        : CRMColors.NEUTRAL_PAPER
    ;
    const DaysCompletedIn = props.item.last_closed_on ? 
        pipe(
            props.item.opened_on,
            getDateDifferenceAndFormat(
                props.item.last_closed_on,
                (count, unit) => `Done in ${count}${unit[0]}`
            ),
        )
        : null
    ;

    return (
        <div 
            className={`crm-roadblock-table-list-item`}
            onClick={() => props.onClick(props.item)}
        >
            <SpacingRow 
                justifyContent="space-between"
                spacing={CRMSpacing.LARGE}
                childSize="1fr 36px"
            >
                {/* TEXT */}
                <Background 
                    width="100%"
                    color={HighlightColor}
                >
                    <SpacingRow 
                        justifyContent="space-between"
                        spacing={CRMSpacing.MEDIUM}
                    >
                        <CRMParagraph>
                            <WeightBold>
                                {ListItemName}
                            </WeightBold>
                        </CRMParagraph>

                        {DaysCompletedIn !== null &&
                            <CRMParagraph>
                                <WeightMedium>{DaysCompletedIn}</WeightMedium>
                            </CRMParagraph>
                        }
                        
                        {DaysCompletedIn === null &&
                            <CRMParagraph>
                                {DaysTilSla === 0 && 
                                    <WeightMedium>Today</WeightMedium>
                                }
                                {DaysTilSla > 0 && 
                                    <WeightMedium>{DaysTilSla}d left</WeightMedium>
                                }

                                {DaysTilSla < 0 && 
                                    <WeightMedium>{Math.abs(DaysTilSla)}d late</WeightMedium>
                                }
                            </CRMParagraph>
                        }
                    </SpacingRow>
                </Background>

                {/* AVATAR */}
                <CRMUserAvatar
                    user={props.item.assigned_users[0]}
                />
            </SpacingRow>
        </div>
    );
};
