import { array } from "fp-ts";
import { pipe } from "fp-ts/lib/function";
import React, {  } from "react";
import { QuoteRateFeePropertyValue, TQuoteRateFeePropertyValue } from "../../../../../domain/codecs/QuoteRateFee";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { SpacingColumn } from "../../BuildingBlocks/SpacingColumn";
import { SpacingRow } from "../../BuildingBlocks/SpacingRow";
import { CRMButtonTertiary } from "../../CRMButtonTertiary/CRMButtonTertiary";
import { CRMNoticeBoxComponent } from "../../CRMNoticeBoxComponent/CRMNoticeBoxComponent";
import { WeightMedium } from "../../WeightMedium/WeightMedium";
import { WeightSemiBold } from "../../WeightSemiBold/WeightSemiBold";
import { CRMQuoteRatesSingleViewEditPropertyValueFeesVariableItem } from "./CRMQuoteRatesSingleViewEditPropertyValueFeesVariableItem";

export const CRMQuoteRatesSingleViewEditPropertyValueFeesVariable = (props: React.PropsWithChildren<{
    property_value_fees: Array<TQuoteRateFeePropertyValue>,
    displayError: boolean,
    hasMissingFeeBand: boolean,
    onChange: (property_value_fees: Array<TQuoteRateFeePropertyValue>) => void,
}>): JSX.Element => {
    const onFeeChange = (feeIndex: number) => (value: TQuoteRateFeePropertyValue) => {
        const property_value_fees = [...props.property_value_fees];
        property_value_fees[feeIndex] = value;
        props.onChange(property_value_fees);
    };

    const onDeleteFee = (feeIndex: number) =>
        pipe(
            props.property_value_fees,
            array.filterWithIndex((i) => i !== feeIndex),
            props.onChange
        );

    const onAddFee = () =>
        pipe(
            [
                ...props.property_value_fees,
                QuoteRateFeePropertyValue.newDefault(),
            ],
            props.onChange
        );

    return <SpacingColumn spacing={CRMSpacing.MEDIUM}>
        {/* BANDS */}
        {pipe(
            props.property_value_fees,
            array.mapWithIndex((i, fee) =>
                <CRMQuoteRatesSingleViewEditPropertyValueFeesVariableItem
                    key={i}
                    fee={fee}
                    displayError={props.displayError}
                    canDelete={i > 0}
                    onChange={onFeeChange(i)}
                    onDelete={() => onDeleteFee(i)}
                />
            )
        )}

        {/* MISSING FEE WARNING */}
        {props.hasMissingFeeBand &&
            <CRMNoticeBoxComponent>
                <WeightSemiBold>
                    You have a gap in your fees!&nbsp;
                </WeightSemiBold>

                <WeightMedium>
                    If this isn't intentional it could result in a client not being charged correctly.
                </WeightMedium>
            </CRMNoticeBoxComponent>
        }

        {/* ADD ANOTHER */}
        <SpacingRow justifyContent="center">
            <CRMButtonTertiary
                label="Add another variation"
                onClick={() => onAddFee()}
            />
        </SpacingRow>
    </SpacingColumn>
}
