import React, { Fragment } from "react";
import { EmailTemplatePadding } from "./EmailTemplatePadding";

type TEmailTemplateRowProps = {
    className?: string;
    padding?: [
        number, 
        number | undefined, 
        number | undefined, 
        number | undefined
    ];
    fullDesktop?: boolean;
    align?: "left" | "center" | "right",
    valign?: "top" | "middle" | "bottom",
};

export const EmailTemplateColumn = (props: React.PropsWithChildren<TEmailTemplateRowProps>): JSX.Element => {

    return (
        <Fragment>
            <th
                style={{
                    textAlign: props.align,
                    verticalAlign: props.valign,
                    margin: props.align === "center" ? "0 auto" : ""
                }}
                dir="ltr"
            >
                {!!props.padding &&
                    <table>
                        <tbody>
                            <EmailTemplatePadding
                                padding={props.padding}
                                align={props.align}
                            >
                                {props.children}
                            </EmailTemplatePadding>
                        </tbody>
                    </table>
                }

                {(!props.padding && props.align) &&
                    <table
                        style={{
                            textAlign: props.align,
                            margin: props.align === "center" ? "0 auto" : ""
                        }}
                    >
                        <tbody>
                            <tr>
                                <td
                                    style={{
                                        textAlign: props.align
                                    }}
                                >
                                    {props.children}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                }

                {(!props.padding && !props.align) &&
                    <Fragment>
                        {props.children}
                    </Fragment>
                }
            </th>

            {props.fullDesktop &&
                <Fragment>
                    <template data-comment dangerouslySetInnerHTML={{ 
                        __html: '<!--[if lt mso 9]></tr></table><table style="width: 100%; border-collapse: collapse; mso-table-lspace: 0; mso-table-rspace: 0;"><tr><![endif]-->' 
                    }} />
                    <template data-comment dangerouslySetInnerHTML={{ 
                        __html: '<!--[if gte mso 9]></tr></table><table style="width: 100%; border-collapse: collapse; mso-table-lspace: 0; mso-table-rspace: 0;"><tr><![endif]-->' 
                    }} />
                </Fragment>
            }
        </Fragment>
    )
};
