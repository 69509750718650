import React from "react";
import { TContainerStateProps } from "../../state/TContainerStateProps";
import { CRMPageWrap } from "../../components/Simple/CRMPageWrap/CRMPageWrap";
import { Padding } from "../../components/BuildingBlocks/Padding";
import { CRMSpacing } from "../../models/CRMSpacing";
import { CRMProbateMenuBarContainer } from "../../containers/CRMProbateMenuBarContainer/CRMProbateMenuBarContainer";
import { CRMProbateHelplineCallbacks } from "../../components/Complex/CRMProbateHelplineCallbacks/CRMProbateHelplineCallbacks";
import { CRMBlock } from "../../components/CRM/CRMBlock/CRMBlock/CRMBlock";

const CRMProbateHelplineCallbackPage = (props: TContainerStateProps): JSX.Element =>
    <CRMPageWrap>
        {/* HEADER */}
        <CRMProbateMenuBarContainer {...props} />

        {/* BODY */}
        <Padding
            type="custom" 
            spacing={`${CRMSpacing.X_LARGE} ${CRMSpacing.X_LARGE} 0px`}
        >
            {/* BLOCK - PROBATE HELPLINE CALLBACKS */}
            <CRMBlock
                mode="fixed-option"
                fixedOption={{
                    icon: "callback",
                    title: "Probate Helpline Callbacks"
                }}
                optionGroups={[]}
            >
                <CRMProbateHelplineCallbacks
                    nowForm={props.state.forms.probate_helpline_callbacks_page.now}
                    completedForm={props.state.forms.probate_helpline_callbacks_page.completed}
                    typeVisible={props.state.forms.probate_helpline_callbacks_page.active_type_visible}
                    onChangeTypeVisible={(payload) => props.dispatch({
                        type: "CRM_PROBATE_HELPLINE_CALLBACK_ACTIVE_TYPE_VISIBLE_CHANGE",
                        payload,
                    })}
                    onCallbackChange={(payload) => props.dispatch({
                        type: `CRM_PROBATE_HELPLINE_CALLBACK_${props.state.forms.probate_helpline_callbacks_page.active_type_visible}_UPDATE`,
                        payload,
                    })}
                    onMarkAsComplete={(payload) => props.dispatch({
                        type: `CRM_PROBATE_HELPLINE_CALLBACK_${props.state.forms.probate_helpline_callbacks_page.active_type_visible}_MARK_COMPLETE`,
                        payload,
                    })}
                    onLoadMore={() => props.dispatch({
                        type: `CRM_PROBATE_HELPLINE_CALLBACK_${props.state.forms.probate_helpline_callbacks_page.active_type_visible}_LOAD_MORE`,
                    })}
                    onSingleViewClose={() => props.dispatch({
                        type: `CRM_PROBATE_HELPLINE_CALLBACK_${props.state.forms.probate_helpline_callbacks_page.active_type_visible}_SINGLE_VIEW_CLOSED`,
                    })}
                />
            </CRMBlock>
        </Padding>
    </CRMPageWrap>
;

export default CRMProbateHelplineCallbackPage;
