import React from "react";
import { FrontPage } from "../components/Front/Simple/FrontPage/FrontPage";
import { SpacingRow } from "../components/BuildingBlocks/SpacingRow";
import { Padding, TPaddingProps } from "../components/BuildingBlocks/Padding";
import { FrontSpacing } from "../models/FrontSpacing";
import { MaxWidth } from "../components/BuildingBlocks/MaxWidth";
import { SpacingColumn } from "../components/BuildingBlocks/SpacingColumn";
import { TextCenter } from "../components/BuildingBlocks/TextCenter";
import { MediaPropSwitch } from "../components/BuildingBlocks/MediaPropSwitch";
import { FrontCardTitle } from "../components/Front/Simple/FrontCardTitle/FrontCardTitle";
import { FrontOutsideCard } from "../components/Front/Simple/FrontOutsideCard/FrontOutsideCard";
import { FrontParagraphRegular } from "../components/Front/Simple/FrontParagraphRegular/FrontParagraphRegular";
import { WeightBold } from "../components/WeightBold/WeightBold";
import { FrontIcon } from "../components/Front/Simple/FrontIcon/FrontIcon";

const FrontEmailVerificationConfirmationPage = (): JSX.Element =>  {

    return (
        <FrontPage>
            <SpacingRow
                justifyContent="center"
                alignItems="flex-start"
            >
                <Padding type="top" spacing={FrontSpacing.MEDIUM_1}>

                    <MaxWidth width="670px">

                        <SpacingColumn spacing={FrontSpacing.LARGE_1}>

                            {/* ICON */}
                            <SpacingRow spacing={FrontSpacing.MEDIUM_1} justifyContent="center">
                                <FrontIcon
                                    iconName="done"
                                    colour="highlights-positive-apple-2"
                                    size="huge"
                                />
                            </SpacingRow>

                            <FrontOutsideCard>
                                <MediaPropSwitch<TPaddingProps>
                                    Component={Padding}
                                    mobile={{ type: "horizontal", spacing: "0px"}}
                                    desktop={{ type: "horizontal", spacing: FrontSpacing.MEDIUM_2 }}
                                    breakpoint="600"
                                >
                                    <SpacingColumn spacing={FrontSpacing.MEDIUM_2}>
                                        {/* TITLE */}
                                        <TextCenter>
                                            <FrontCardTitle>
                                                Your email has been verified!
                                            </FrontCardTitle>
                                        </TextCenter>
                                        
                                        {/* PARAGRAPH */}
                                        <SpacingColumn spacing={FrontSpacing.SMALL_3}>
                                            <TextCenter>
                                                <FrontParagraphRegular>
                                                    Thank you for taking the time to verify your email with <WeightBold>Sail Legal</WeightBold>. Your action helps us maintain a high
                                                    standard of security and protect you from fraud.
                                                </FrontParagraphRegular>
                                            </TextCenter>
                                            
                                            <TextCenter>
                                                <FrontParagraphRegular>
                                                    All future correspondence from this address will be regarded as <WeightBold>trustworthy.</WeightBold>
                                                </FrontParagraphRegular>
                                            </TextCenter>
                                            
                                            <TextCenter>
                                                <FrontParagraphRegular>
                                                    Have a wonderful day.
                                                </FrontParagraphRegular>
                                            </TextCenter>
                                        </SpacingColumn>
                                    </SpacingColumn>
                                </MediaPropSwitch>
                            </FrontOutsideCard>
                        </SpacingColumn>
                        
                    </MaxWidth>
                </Padding>
            </SpacingRow>
        </FrontPage>
    );
};


export default FrontEmailVerificationConfirmationPage;