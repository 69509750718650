import React from "react";
import { CRMColors } from "../../../../models/CRMColors";
import { CRMFontSizes } from "../../../../models/CRMFontSizes";
import { CRMSpacing } from "../../../../models/CRMSpacing";
import { Background } from "../../../BuildingBlocks/Background";
import { HorizontalLine } from "../../../BuildingBlocks/HorizontalLine";
import { SpacingColumn } from "../../../BuildingBlocks/SpacingColumn";
import { FontQuicksand } from "../../../BuildingBlocks/FontQuicksand";
import { Text } from "../../../BuildingBlocks/Text";

export const CRMBlockHeaderSection = (props: React.PropsWithChildren<{ title: string }>): JSX.Element => {

    return (
        <Background minHeight="100%">
            <SpacingColumn spacing={CRMSpacing.MEDIUM}>    
                {/* HEADER */}
                <SpacingColumn spacing={CRMSpacing.TINY}>
                    <FontQuicksand>
                        <Text 
                            size={CRMFontSizes.MED} 
                            weight={600}
                            color={CRMColors.NEUTRAL_INK}
                        >
                            {props.title}
                        </Text>
                    </FontQuicksand>

                    <HorizontalLine color={CRMColors.NEUTRAL_6} />
                </SpacingColumn>

                {/* BODY */}
                {props.children}

            </SpacingColumn>
        </Background>
    );
}