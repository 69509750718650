import React, { useState, useEffect }  from "react";
import { array, option } from "fp-ts";
import * as FullStory from '@fullstory/browser';
import { TContainerStateProps } from "../state/TContainerStateProps";
import { FrontPage } from "../components/Front/Simple/FrontPage/FrontPage";
import { SpacingColumn } from "../components/BuildingBlocks/SpacingColumn";
import { FrontFormHeader } from "../components/Front/Complex/FrontFormHeader/FrontFormHeader";
import { FrontSpacing } from "../models/FrontSpacing";
import FrontPayoutDetailsQuestionCardContainer, { getPayoutDetailsStatus } from "../containers/Front/FrontPayoutDetailsQuestionCardContainer";
import FrontIdentityVerificationContainer, { getIdentityVerificationStatus } from "../containers/Front/FrontIdentityVerificationContainer";
import FrontPaymentContainer, { getPaymentTabStatus } from "../containers/Front/FrontPaymentContainer";
import FrontWhoAreTheClientsContainer, { getWhoAreTheClientsFormTitle, getWhoAreTheClientsStatus } from "../containers/Front/FrontWhoAreTheClientsContainer";
import FrontCookieBannerContainer from "../containers/Front/FrontCookieBannerContainer";
import { getCookiePreference, initFullStory, isFullyScrolledIntoView, isPartialtyScrolledIntoView, scrollElementIntoViewById } from "../util";
import { pipe } from "fp-ts/lib/function";
import { ifTrueInject, inject } from "../../../shared/src/utilsByDomain/array";
import { FrontOnboardingCaseOverview, TOnboardingCaseOverviewTab } from "../components/Front/Complex/FrontOnboardingCaseOverview/FrontOnboardingCaseOverview";
import { FrontOnboardingCaseOverviewMobile } from "../components/Front/Complex/FrontOnboardingCaseOverviewMobile/FrontOnboardingCaseOverviewMobile";
import { FrontOnboardingInfoCard } from "../components/Front/Complex/FrontOnboardingInfoCard/FrontOnboardingInfoCard";
import { FrontColors } from "../models/FrontColors";
import { DateTime } from "luxon";
import FrontRelatedTransactionContainer, {getRelatedTransactionStatus, getRelatedTransactionTitle } from "../containers/Front/FrontRelatedTransactionContainer";
import FrontBookVideoCallContainer, { getBookVideoCallStatus } from "../containers/Front/FrontBookVideoCallContainer";
import FrontSdltQuestions, { getSdltTabStatus } from "../containers/Front/FrontSdltQuestions";
import { WeightMedium } from "../components/WeightMedium/WeightMedium";
import FrontPurchasePaymentDetailsContainer, { getPurchasePaymentDetailsStatus } from "../containers/Front/FrontPurchasePaymentDetailsContainer";
import { FrontOnboardingTabButton } from "../components/Front/Simple/FrontOnboardingTabButton/FrontOnboardingTabButton";
import FrontChargesContainer, { getChargesStatus } from "../containers/Front/FrontChargesContainer";
import FrontAgreeToTOEContainer, { getAgreeToTOEStatus } from "../containers/Front/FrontAgreeToTOEContainer/FrontAgreeToTOEContainer";
import FrontDualRepConsentContainer, { getDualRepConsentStatus } from "../containers/Front/FrontDualRepConsentContainer/FrontDualRepConsentContainer";
import { RequiresDualRepConsent, TUserCaseUserType } from "../../../domain/codecs/UserCaseUserType";
import { ClientCasePurchasePaymentDetailsForm, ClientCaseRelatedTransaction } from "../../../domain/codecs/form/ClientCaseForm";
import FrontRemortgageMortgageDetailsContainer, { getRemortgageMortgageDetailsStatus } from "../containers/Front/FrontRemortgageMortgageDetailsContainer";
import FrontPropertyInformationFormsContainer, { getPropertyInformationFormsStatus } from "../containers/Front/FrontPropertyInformationFormsContainer";
import FrontJointOwnershipHeldAsPreferenceContainer, { getJointOwnershipHeldAsPreferenceStatus } from "../containers/Front/FrontJointOwnershipHeldAsPreferenceContainer";
import FrontDeclarationOfTrustReferralContainer, { getDeclarationOfTrustReferralStatus } from "../containers/Front/FrontDeclarationOfTrustReferralContainer";
import FrontWillReferralContainer, { getWillReferralStatus } from "../containers/Front/FrontWillReferralContainer";

export type TStepNames =
    "identity_verification"
    | "who_are_the_clients"
    | "related_transaction"
    | "payment"
    | "purchase_payment_details"
    | "remortgage_mortgage_details"
    | "sdlt_questions"
    | "payout_details"
    | "book_video_call"
    | "charges"
    | "agree_to_toe"
    | "dual_rep_consent"
    | "property_information_forms"
    | "joint_ownership_held_as_preferences"
    | "declaration_of_trust_referral"
    | "will_referral";

type TStep = 
    TOnboardingCaseOverviewTab<TStepNames> & 
    {
        ref?: React.RefObject<HTMLDivElement>;
        ContainerElement: (props: TContainerStateProps) => JSX.Element; 
    }
;

const FrontOnboardingPage = (props: TContainerStateProps): JSX.Element =>  {

    const [selectedStep, setSelectedStep] = useState<TStepNames | undefined>(undefined);
    const [steps, setSteps] = useState<Array<TStep>>([]);

    useEffect(
        () => {
            const allSteps = getChronologicalStepList();
            const latestStep = getLatestStep(allSteps);
            setSteps(allSteps);
            setSelectedStep(latestStep[0] ? latestStep[0].value : undefined);
        },
        [props.state.forms.client_case_page.children]
    );

    useEffect(
        () => {
            window.addEventListener("scroll", onMouseScroll);
            return () => window.removeEventListener("scroll", onMouseScroll);
        },
    );

    useEffect(() => {
        if (getCookiePreference() === "all") {
            initFullStory();
            FullStory.identify(props.state.global.user.id);
        }
        
        return () => FullStory.shutdown();
    }, []);

    const getChronologicalStepList = (): Array<TStep> =>  pipe(
        [],
        inject<TStep>(
            {
                value: "agree_to_toe",
                label: "Agree to the Terms of Engagement",
                status: getAgreeToTOEStatus(props),
                ContainerElement: FrontAgreeToTOEContainer
            }
        ),
        ifTrueInject<TStep>(
            hasToConsentToDualRep,
            {
                value: "dual_rep_consent",
                label: "Consent to Dual Representation",
                status: getDualRepConsentStatus(props),
                ContainerElement: FrontDualRepConsentContainer
            }
        ),
        ifTrueInject<TStep>(
            hasCaseClients,
            {
                value: "who_are_the_clients",
                label: getWhoAreTheClientsFormTitle(props),
                status: getWhoAreTheClientsStatus(props),
                ContainerElement: FrontWhoAreTheClientsContainer
            }
        ),
        ifTrueInject<TStep>(
            hasRelatedTransaction,
            {
                value: "related_transaction",
                label: getRelatedTransactionTitle(props),
                status: getRelatedTransactionStatus(props),
                ContainerElement: FrontRelatedTransactionContainer
            }
        ),
        ifTrueInject<TStep>(
            hasIdentityVerification,
            {
                value: "identity_verification",
                label: "Verify your identity",
                status: getIdentityVerificationStatus(props),
                ContainerElement: FrontIdentityVerificationContainer
            }
        ),
        ifTrueInject<TStep>(
            hasVideoVerificationCall,
            {
                value: "book_video_call",
                label: "Book your video call with us",
                status: getBookVideoCallStatus(props),
                ContainerElement: FrontBookVideoCallContainer
            }
        ),
        ifTrueInject<TStep>(
            hasToMakePayments,
            {
                value: "payment",
                label: "Make a payment",
                status: getPaymentTabStatus(props),
                ContainerElement: FrontPaymentContainer
            }
        ),
        ifTrueInject<TStep>(
            hasToAnswerPurchasePaymentDetailsQuestions,
            {
                value: "purchase_payment_details",
                label: "Tell us how you'll be paying",
                status: getPurchasePaymentDetailsStatus(props),
                ContainerElement: FrontPurchasePaymentDetailsContainer
            }
        ),
        ifTrueInject<TStep>(
            hasToAnswerRemortgageMortgageDetailsQuestions,
            {
                value: "remortgage_mortgage_details",
                label: "Tell us about your remortgage",
                status: getRemortgageMortgageDetailsStatus(props),
                ContainerElement: FrontRemortgageMortgageDetailsContainer
            }
        ),
        ifTrueInject<TStep>(
            hasToAnswerChargeQuestions,
            {
                value: "charges",
                label: "Tell us your mortgage account number",
                status: getChargesStatus(props),
                ContainerElement: FrontChargesContainer
            }
        ),
        ifTrueInject<TStep>(
            hasToAnswerSdltQuestions,
            {
                value: "sdlt_questions",
                label: "Answer tax questions",
                status: getSdltTabStatus(props),
                ContainerElement: FrontSdltQuestions
            }
        ),
        ifTrueInject<TStep>(
            hasToAnswerJointOwnershipHeldAsPreferences,
            {
                value: "joint_ownership_held_as_preferences",
                label: "Tell us your joint ownership wishes",
                status: getJointOwnershipHeldAsPreferenceStatus(props),
                ContainerElement: FrontJointOwnershipHeldAsPreferenceContainer
            }
        ),
        ifTrueInject<TStep>(
            hasToAnswerDeclarationOfTrustReferral,
            {
                value: "declaration_of_trust_referral",
                label: "Create a Declaration of Trust",
                status: getDeclarationOfTrustReferralStatus(props),
                ContainerElement: FrontDeclarationOfTrustReferralContainer
            }
        ),
        ifTrueInject<TStep>(
            hasToAnswerWillReferral,
            {
                value: "will_referral",
                label: "Protect your family's future",
                status: getWillReferralStatus(props),
                ContainerElement: FrontWillReferralContainer,
            }
        ),
        ifTrueInject<TStep>(
            hasPayoutDetails,
            {
                value: "payout_details",
                label: "Tell us which bank account to send money to",
                status: getPayoutDetailsStatus(props),
                ContainerElement: FrontPayoutDetailsQuestionCardContainer
            }
        ),
        ifTrueInject<TStep>(
            hasPropertyInformationForms,
            {
                value: "property_information_forms",
                label: "Complete the Property Information Forms",
                status: getPropertyInformationFormsStatus(props),
                ContainerElement: FrontPropertyInformationFormsContainer,
            }
        ),
        array.map((step) => ({
            ref: React.createRef<HTMLDivElement>(),
            ...step
        }))
    );

    const onMouseScroll = () => {
        const fullyScrolledIntoViewCard = pipe(
            steps,
            array.findFirst<TStep>((card) => !!card.ref?.current && isFullyScrolledIntoView(card.ref.current)),
            option.fold(
                () => null,
                (step) => step.value
            )
        );
        const partiallyScrolledIntoViewCard = pipe(
            steps,
            array.findFirst<TStep>((card) => !!card.ref?.current && isPartialtyScrolledIntoView(card.ref.current)),
            option.fold(
                () => null,
                (step) => step .value
            )
        );

        // Changing steps based on where someone is in the page should only need to happen when all steps are complete and the user is scrolling through completed ones
        if (areAllStepsComplete()) {
            setSelectedStep(
                fullyScrolledIntoViewCard 
                || partiallyScrolledIntoViewCard 
                || selectedStep
            );
        }
    };

    const areAllStepsComplete = () => getLatestStep(steps).length === 0 && getDeferredSteps().length === 0;
    
    const getLatestStep = (stepList: Array<TStep>): Array<TStep> => pipe(
        stepList,
        array.findFirst((step) => step.status === "active"),
        option.fold(
            () => [],
            (step) => [step],
        )
    );

    const getCompletedSteps = (): Array<TStep> => 
        steps.filter((step) => step.status === "completed")
    ;

    const getDeferredSteps = (): Array<TStep> =>
        steps.filter((step) => step.status === "deferred")
    ;

    const hasToConsentToDualRep = () => !!props.state.forms.client_case_page.children.dual_rep_consent
        && RequiresDualRepConsent.includes(getUserCaseUserType())

    const hasIdentityVerification = () => !!props.state.forms.client_case_page.children.identity_verification;
    
    const hasCaseClients = () => !!props.state.forms.client_case_page.children.confirm_case_users;

    const hasRelatedTransaction = () => !!props.state.forms.client_case_page.children.related_transaction && (
        getTransactionType() === "purchase"
        || getTransactionType() === "sale"
    );
    
    const isARemortgageCase = () => getTransactionType() === "remortgage";

    const hasPayoutDetails = () => isARemortgageCase() || (isASaleCase() && !!props.state.forms.client_case_page.children.bank_accounts);

    const hasPropertyInformationForms = () => !!props.state.forms.client_case_page.children.property_information_forms;

    const hasVideoVerificationCall = () => !!props.state.forms.client_case_page.children.video_verification_call;
    
    const isASaleCase = () => getTransactionType() === "sale";

    const hasToMakePayments = (): boolean =>
        props.state.forms.client_case_page.children.payment !== undefined
    ;

    const hasToAnswerPurchasePaymentDetailsQuestions = (): boolean =>
        props.state.forms.client_case_page.children.purchase_payment_details !== undefined
    ;

    const hasToAnswerRemortgageMortgageDetailsQuestions = (): boolean => isARemortgageCase();

    const hasToAnswerSdltQuestions = (): boolean =>
        props.state.forms.client_case_page.children.sdlt_questions !== undefined;

    const hasToAnswerChargeQuestions = (): boolean =>
        props.state.forms.client_case_page.children.charge_account_numbers !== undefined
        && props.state.forms.client_case_page.children.charge_account_numbers.charges.length > 0;

    const hasToAnswerJointOwnershipHeldAsPreferences = (): boolean =>
        props.state.forms.client_case_page.children.joint_ownership_held_as_preference !== undefined;

    const hasToAnswerDeclarationOfTrustReferral = (): boolean =>
        props.state.forms.client_case_page.children.declaration_of_trust_referral !== undefined;

    const hasToAnswerWillReferral = (): boolean =>
        props.state.forms.client_case_page.children.will_referral !== undefined;

    const getTransactionType = () => props.state.forms.client_case_page.children.case.transaction_type;

    const renderSelectedStepsContainerElement = (): JSX.Element => {
        const currentStep = steps.find((step) => step.value === selectedStep);
        if (currentStep) {
            return (
                <currentStep.ContainerElement {...props} />
            );
        }
        return (<div></div>);
    }

    const onSelectStep = (step: TOnboardingCaseOverviewTab<TStepNames>): void => {
        step.status === "deferred"
            ? onSelectDeferredStep(step.value)
            : setSelectedStep(step.value)
        scrollElementIntoViewById(step.value);
    }

    const onSelectDeferredStep = (stepName: TStepNames): void => {
        if (stepName === "purchase_payment_details") {
            props.dispatch({
                type: "CLIENT_UPDATE_PURCHASE_PAYMENT_DETAILS",
                payload: {
                    ...props.state.forms.client_case_page.children.purchase_payment_details || ClientCasePurchasePaymentDetailsForm.newDefault(),
                    status: "requiresSubmission",
                }
            });
        };
        
        if (stepName === "related_transaction") {
            props.dispatch({
                type: "CLIENT_UPDATE_RELATED_TRANSACTION_QUESTION",
                payload: {
                    ...props.state.forms.client_case_page.children.related_transaction?.question || ClientCaseRelatedTransaction.newDefault().question,
                    status: "requiresSubmission",
                }
            });

            props.dispatch({
                type: "CLIENT_UPDATE_RELATED_TRANSACTION_ADDRESS",
                payload: {
                    ...props.state.forms.client_case_page.children.related_transaction?.address || ClientCaseRelatedTransaction.newDefault().address,
                    status: "requiresSubmission",
                }
            });
        }
    };

    const getTitle = (): JSX.Element =>
    props.state.forms.client_case_page.children.case.address
        ? <>Your {getTransactionType()} of: <WeightMedium>{props.state.forms.client_case_page.children.case.address}</WeightMedium></>
        : <>Your {getTransactionType()}</>;

    const getUserCaseUserType = (): TUserCaseUserType => props.state.forms.client_case_page.children.session_user_case_user_type.user_type;

    return (
        <FrontPage>
            <title>Sail Legal - Your case</title>

            {(props.state.forms.client_case_page.status !== "untouched" && props.state.forms.client_case_page.status !== "loading") &&
                <>
                    <div className="front-onboarding-page">
                        <SpacingColumn spacing={FrontSpacing.LARGE_2}>
                            
                            {/* HEADER */}
                            <div className="front-onboarding-page__header">
                                <FrontFormHeader
                                    context="legal"
                                    title={getTitle()}
                                />
                            </div>
                            
                            <div className="front-onboarding-page__body">

                                {/* IF INCOMPLETE - SINGLE SELECTED FORM */}
                                {getLatestStep(steps).length > 0 && 
                                    renderSelectedStepsContainerElement()
                                }

                                {/* IF COMPLETED - CONGRATS + ALL FORMS */}
                                {getLatestStep(steps).length === 0 &&
                                    getDeferredSteps().length === 0 &&
                                        <SpacingColumn
                                            spacing={FrontSpacing.LARGE_3}
                                        >
                                            <FrontOnboardingInfoCard
                                                titleText="Congrats! You have completed all the steps."
                                                paragraphs={[
                                                    "For now just sit back, relax, and let us handle the rest.",
                                                    "If we need anything else from you, we will send you an email.",
                                                    "In case you were wondering, you can see all the steps you have completed below."
                                                ]}
                                            />

                                            {steps.map((step, index) => (
                                                <div
                                                    key={index}
                                                    ref={step.ref}
                                                    id={step.value}
                                                >
                                                    <step.ContainerElement
                                                        {...props}
                                                    />
                                                </div>
                                            ))}
                                        </SpacingColumn>
                                }

                                {/* IF DEFERRED - INFO + DEFERRED FORMS */}
                                {(getLatestStep(steps).length === 0 && getDeferredSteps().length > 0) &&
                                    <SpacingColumn
                                        spacing={FrontSpacing.LARGE_3}
                                    >
                                        {/* SELECTED DEFERRED STEP - FORM */}
                                        {steps.map((step, index) => (
                                            step.value === selectedStep &&
                                                <div
                                                    key={index}
                                                    ref={step.ref}
                                                    id={step.value}
                                                >
                                                    <step.ContainerElement
                                                        {...props}
                                                    />
                                                </div>
                                        ))}

                                        {/* INFO CARD WITH BUTTON LINKS TO FORMS */}
                                        <FrontOnboardingInfoCard
                                            iconName="flare"
                                            backgroundColour="blue"
                                            titleText="Nearly done..."
                                            paragraphs={["Click on the sections below to fill out that missing information as soon as you have it:"]}
                                        >
                                            <SpacingColumn
                                                spacing={FrontSpacing.SMALL_2}
                                            >
                                                {getDeferredSteps().map((step, index) => (
                                                    <div key={index}>
                                                        <FrontOnboardingTabButton
                                                            label={step.label}
                                                            onClick={() => onSelectDeferredStep(step.value)}
                                                        />
                                                    </div>
                                                ))}
                                            </SpacingColumn>
                                        </FrontOnboardingInfoCard>
                                    </SpacingColumn>
                                }

                                {/* CASE OVERVIEW - DESKTOP */}
                                <div className="front-onboarding-page__desktop-navigation">
                                    <FrontOnboardingCaseOverview<TStepNames>
                                        selectedTab={selectedStep}
                                        transactionType={getTransactionType()}
                                        currentTabs={getLatestStep(steps)}
                                        deferredTabs={getDeferredSteps()}
                                        completedTabs={getCompletedSteps()}
                                        address={props.state.forms.client_case_page.children.case.address}
                                        onChange={(tab) => onSelectStep(tab)}
                                        quoteDownloadUrl={props.state.forms.client_case_page.children.quote_download_url}
                                        apiUrl={env.REACT_APP_API_URL}
                                        caseUserType={getUserCaseUserType()}
                                        agreeToToeStatus={getAgreeToTOEStatus(props)}
                                        dualRepConsentStatus={getDualRepConsentStatus(props)}
                                        caseUserRequiredToConsentToDualRep={hasToConsentToDualRep()}
                                        switchToRelatedTransaction={props.state.forms.client_case_page.children.switch_to_related_transaction}
                                        onClickAgreeToToeButton={() => props.dispatch({
                                            type: "CLIENT_SUBMIT_AGREE_TO_TOE_STEP_FORM_AS_COMPLETE",
                                            payload: null,
                                        })}
                                        onClickDualRepConsentButton={() => props.dispatch({
                                            type: "CLIENT_SUBMIT_DUAL_REP_CONSENT_STEP_FORM_AS_COMPLETE",
                                            payload: null,
                                        })}
                                    />
                                </div>
                                
                                {/* CASE OVERVIEW - MOBILE */}
                                <div className="front-onboarding-page__mobile-navigation">
                                    <FrontOnboardingCaseOverviewMobile<TStepNames>
                                        selectedTab={selectedStep}
                                        transactionType={getTransactionType()}
                                        currentTabs={getLatestStep(steps)}
                                        deferredTabs={getDeferredSteps()}
                                        completedTabs={getCompletedSteps()}
                                        address={props.state.forms.client_case_page.children.case.address}
                                        onChange={(tab) => onSelectStep(tab)}
                                        quoteDownloadUrl={props.state.forms.client_case_page.children.quote_download_url}
                                        apiUrl={env.REACT_APP_API_URL}
                                        caseUserType={getUserCaseUserType()}
                                        agreeToToeStatus={getAgreeToTOEStatus(props)}
                                        caseUserRequiredToConsentToDualRep={hasToConsentToDualRep()}
                                        dualRepConsentStatus={getDualRepConsentStatus(props)}
                                        switchToRelatedTransaction={props.state.forms.client_case_page.children.switch_to_related_transaction}
                                        onClickAgreeToToeButton={() => props.dispatch({
                                            type: "CLIENT_SUBMIT_AGREE_TO_TOE_STEP_FORM_AS_COMPLETE",
                                            payload: null,
                                        })}
                                        onClickDualRepConsentButton={() => props.dispatch({
                                            type: "CLIENT_SUBMIT_DUAL_REP_CONSENT_STEP_FORM_AS_COMPLETE",
                                            payload: null,
                                        })}
                                    />
                                </div>
                            </div>
                        </SpacingColumn>
                    </div>
                </>
            }

            {/* DO TOU ACCEPT COOKIES PROMPT (because we use fullstory) */}
            <FrontCookieBannerContainer
                dispatch={props.dispatch}
                state={props.state}
            />
        </FrontPage>
    );
};

export default FrontOnboardingPage;
