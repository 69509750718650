import React from "react";
import { Uppercase } from "../../Uppercase/Uppercase";

type TPopoutOptionsProps = {
    options: Array<ICRMPopoutOption>;
};

export interface ICRMPopoutOption {
    label: string;
    onClick: () => void;
    disabled?: boolean;
    disabledExplanationText?: string;
};

export const CRMPopoutOptions = (props: React.PropsWithChildren<TPopoutOptionsProps>): JSX.Element => {
    return (
        <div className="crm-popout-options">
            {props.options.map((option, index) =>
                <div
                    key={index}
                    className={`
                        crm-popout-options__option
                        ${option.disabled ? "crm-popout-options__option--disabled" : ""}
                    `}
                    onClick={() => option.onClick()}
                >
                    <div className={`
                        crm-popout-options__option-text
                        crm-popout-options__option-text--${option.disabled ? "disabled" : "enabled"}
                    `}>
                        <Uppercase>
                            {option.label}
                        </Uppercase>
                    </div>

                    {option.disabled && option.disabledExplanationText &&
                        <div className="crm-popout-options__option-disabled-explanation">
                            {option.disabledExplanationText}
                        </div>
                    }
                </div>
            )}
        </div>
    );
};
