import React from "react";
import { array } from "fp-ts";
import { pipe } from "fp-ts/lib/function";
import { requireExhaustive } from "../../../../shared/src/util";

type TPaddingSizes = "tiny" | "medium" | "large" | "x-large";

type TCRMSpacingRowProps = {
    justifyContent?: "start" | "end" | "center" | "space-around" | "space-between" | "space-evenly";
    alignItems?: "center" | "flex-start" | "flex-end";
    spacing?: TPaddingSizes;
};
/**
 * @deprecated use SpacingColumn
 */
export const CRMSpacingColumn = (props: React.PropsWithChildren<TCRMSpacingRowProps>): JSX.Element => {
    const getSpacingGapMeasurment = (gap: TPaddingSizes): string => {
        switch (gap) {
            case "tiny":
                return "6px";
            case "medium":
                return "16px";
            case "large":
                return "24px";
            case "x-large":
                return "40px";
            default:
                return requireExhaustive(gap);
        }
    };

    const getGridTemplateRowsBasedOnVisibleChildCount = (children: React.ReactNode): string =>
         pipe(
            React.Children.toArray(children),
            array.map(() => "auto"),
            (arr) => arr.join(" "),
        );

    return (
        <div
            className="crm-spacing-column"
            style={{
                // stylelint-disable
                justifyContent: props.justifyContent || "start",
                gridRowGap: getSpacingGapMeasurment(props.spacing || "tiny"),
                gridTemplateRows: getGridTemplateRowsBasedOnVisibleChildCount(props.children),
                alignItems: props.alignItems || "flex-start",
                // stylelint-enable
            }}
        >
            {props.children}
        </div>
    );
};
