import React, { useState } from "react";
import { TClientCaseIdentityVerificationUserForm } from "../../../../../../domain/codecs/form/ClientCaseForm";
import { FrontSpacing } from "../../../../models/FrontSpacing";
import { InlineFlex } from "../../../BuildingBlocks/InlineFlex";
import { SpacingColumn } from "../../../BuildingBlocks/SpacingColumn";
import { WeightBold } from "../../../WeightBold/WeightBold";
import { FrontFormPrimaryButton } from "../../Simple/FrontFormPrimaryButton/FrontFormPrimaryButton";
import { FrontOutsidePopupBasic } from "../FrontOutsidePopupBasic/FrontOutsidePopupBasic";
import FrontParagraphSmall from "../../Simple/FrontParagraphSmall/FrontParagraphSmall";
import FrontTitleMinor from "../../Simple/FrontTitleMinor/FrontTitleMinor";

export const FrontIdentityVerificationRetryPopup = (props: React.PropsWithChildren<{
    form: TClientCaseIdentityVerificationUserForm;
    errorCode: string;
    onBeginPhotoVerification: (form: TClientCaseIdentityVerificationUserForm) => void;
}>): JSX.Element => { 
    const [isOpen, setIsOpen] = useState<boolean>(true);

    const issues: Record<string, {title: JSX.Element, paragraph: JSX.Element}> = {
        server_error: {
            title: <>You didn't do anything wrong; Our photo verification service is experiencing issues.</>,
            paragraph: <FrontParagraphSmall>
                When you're ready to try again just click the button below. If this issue keeps happening let us know and we'll help.
            </FrontParagraphSmall>
        },
        session_expired: {
            title: <>For security reasons we need you to complete the check a little faster.</>,
            paragraph: <FrontParagraphSmall>
                We noticed it took a while to do the photo verification, for our own security purposes we need you to complete the check a little faster. When you're ready to try this again just click the button below.
            </FrontParagraphSmall>
        },
        error_during_step: {
            title: <>We weren't able to complete all photo verification steps with you.</>,
            paragraph: <FrontParagraphSmall>
                It may of just been a small hiccup, you can retry the photo verification by clicking the button below. <WeightBold>If this issue keeps happening you may be able to get it to work by trying on another device,</WeightBold> like a laptop, phone or tablet.
            </FrontParagraphSmall>
        },
        network_error: {
            title: <>You lost internet connection during the photo verification.</>,
            paragraph: <FrontParagraphSmall>
                To be able to complete all steps of the photo verification it's best to have a strong internet connection. We recommend being connected to a Wi-Fi network or a strong 4G signal if you are using mobile data.
            </FrontParagraphSmall>
        },
        camera_unavailable: {
            title: <>Your camera isn't working.</>,
            paragraph: <FrontParagraphSmall>
                We weren't able to connect with your camera to complete the photo verification steps. <WeightBold>You could try completing the photo verification on a different device or browser.</WeightBold>
            </FrontParagraphSmall>
        },
        made_three_attempts: {
            title: <>We weren't able to get a clear picture.</>,
            paragraph: <>
                <FrontParagraphSmall>
                    We weren't able to get a clear picture of your ID document, next time try the following:
                    <ol>
                        <li>Try using a mobile or tablet, these usually will have a better quality camera</li>
                        <li>Place your driving license or open passport on a flat surface</li>
                        <li>Move your phone so that it is as close to the ID as possible without cutting off the edges</li>
                        <li>Hold your phone still for a moment so that it focuses and then take the picture</li>
                    </ol>
                </FrontParagraphSmall>
            </>
        },
        unhandled: {
            title: <>Something went wrong.</>,
            paragraph: <FrontParagraphSmall>
                Something went wrong during your photo verification, <WeightBold>please try this again.</WeightBold> If this issue keeps happening let us know and we'll help.
            </FrontParagraphSmall>
        }
    };

    const getIssue = (): {title: JSX.Element, paragraph: JSX.Element} =>
        props.errorCode === "9100" || props.errorCode === "9200" || props.errorCode === "9810" || props.errorCode === "9836" ? issues.server_error
        : props.errorCode === "9210" ? issues.session_expired
        : props.errorCode === "9300" || props.errorCode === "9400" || props.errorCode === "9801" || props.errorCode === "9821" ? issues.error_during_step
        : props.errorCode === "9800" ? issues.network_error
        : props.errorCode === "9820" || props.errorCode === "9822" ? issues.camera_unavailable
        : props.errorCode === "9835" ? issues.made_three_attempts
        : issues.unhandled;

    return (
        <FrontOutsidePopupBasic
            isOpen={isOpen}
            title="Let's try that again"
            onClose={() => setIsOpen(false)}
        >
            <SpacingColumn spacing={FrontSpacing.MEDIUM_2}>
                <FrontTitleMinor>{getIssue().title}</FrontTitleMinor>
                <SpacingColumn spacing={FrontSpacing.LARGE_1}>
                    <div>{getIssue().paragraph}</div>
                    <InlineFlex>
                        <FrontFormPrimaryButton
                            label="Retry Photo Verification"
                            icon="camera"
                            onClick={() => props.onBeginPhotoVerification(props.form)}
                            isDisabled={props.form.children.create_id_verification_link.status === "submitting"}
                        />
                    </InlineFlex>
                </SpacingColumn>
            </SpacingColumn>
        </FrontOutsidePopupBasic>
    );
};
