import React, { useState } from "react";
import { array, option } from "fp-ts";
import { pipe } from "fp-ts/lib/pipeable";
import { array as extArray } from "../../../../shared/src/utilsByDomain/array";
import { SpacingColumn } from "../../components/BuildingBlocks/SpacingColumn";
import { FrontQuestionFormCard } from "../../components/Front/Complex/FrontQuestionFormCard/FrontQuestionFormCard";
import { TContainerStateProps } from "../../state/TContainerStateProps";
import { TClientCaseCompanyForm } from "../../../../domain/codecs/form/ClientCaseForm";
import { FrontSpacing } from "../../models/FrontSpacing";
import { WeightBold } from "../../components/WeightBold/WeightBold";
import { HorizontalLine } from "../../components/BuildingBlocks/HorizontalLine";
import { FrontColors } from "../../models/FrontColors";
import { FrontFormSectionTitle } from "../../components/Front/Simple/FrontFormSectionTitle/FrontFormSectionTitle";
import { FrontCondensedRadioQuestions } from "../../components/Front/Simple/FrontCondensedRadioQuestions/FrontCondensedRadioQuestions";
import { FrontCondensedInputQuestion } from "../../components/Front/Simple/FrontCondensedInputQuestion/FrontCondensedInputQuestion";
import { FrontOnboardingFormParagraphs } from "../../components/Front/Simple/FrontOnboardingFormParagraphs/FrontOnboardingFormParagraphs";
import { areAllDetailsFilledIn, FrontClientDetailsForm } from "../../components/Front/Complex/FrontClientDetailsForm/FrontClientDetailsForm";
import { onChangeForm } from "../../../../shared/src/codecs/types/form";
import { MediaCompSwitch } from "../../components/BuildingBlocks/MediaCompSwitch";
import { SpacingRow } from "../../components/BuildingBlocks/SpacingRow";
import { FrontFormSecondaryButton } from "../../components/Front/Simple/FrontFormSecondaryButton/FrontFormSecondaryButton";
import { FrontFormPrimaryButton } from "../../components/Front/Simple/FrontFormPrimaryButton/FrontFormPrimaryButton";
import { FrontCondensedDropdownQuestion } from "../../components/Front/Simple/FrontCondensedDropdownQuestion/FrontCondensedDropdownQuestion";
import { TOnboardingTabStatus } from "../../components/Front/Simple/FrontOnboardingTab/FrontOnboardingTab";
import FrontParagraphRegular from "../../components/Front/Simple/FrontParagraphRegular/FrontParagraphRegular";
import { FrontPopupPrompt } from "../../components/Front/Complex/FrontPopupPrompt/FrontPopupPrompt";
import { FrontBulletpoint } from "../../components/Front/Simple/FrontBulletpoint/FrontBulletpoint";
import { MaxWidth } from "../../components/BuildingBlocks/MaxWidth";
import { isFilled } from "../../../../shared/src/util";
import FrontParagraphSmall from "../../components/Front/Simple/FrontParagraphSmall/FrontParagraphSmall";
import { sailLegalBrandConstants } from "../../../../domain/constants";
import { FrontInfoBubble } from "../../components/Front/Simple/FrontInfoBubble/FrontInfoBubble";


const FrontWhoAreTheClientsContainer = (props: TContainerStateProps): JSX.Element => {

    const [isSubmitFormOpen, setIsSubmitFormOpen] = useState(false);

    const [displayValidationErrorsIfPresent, setDisplayValidationErrorsIfPresent] = useState(false);

    const getTransactionType = () => props.state.forms.client_case_page.children.case.transaction_type;

    const getTransactionTypeAsSimpleCopyText = () => {
        switch (props.state.forms.client_case_page.children.case.transaction_type) {
            case "purchase":
                return "buying";
            case "sale":
                return "selling";
            case "transfer":
                return "transferring";
            case "remortgage":
                return "remortgaging";
            default:
                return "transacting";
        }
    }

    const onChangeCompanyForm = onChangeForm(
        props.state.forms.client_case_page.children.confirm_case_users?.company as TClientCaseCompanyForm,
        (payload) => props.dispatch({
            type: "CLIENT_UPDATE_COMPANY",
            payload
        })
    );

    const getIsTransactingAsACompany = () => 
        props.state.forms.client_case_page.children.confirm_case_users?.company.edited.is_transacting_as_company === "unknown" ?
            null :
            props.state.forms.client_case_page.children.confirm_case_users?.company.edited.is_transacting_as_company || null
    ;

    const getPrimaryContact = (): string => {
        if (!props.state.forms.client_case_page.children.confirm_case_users) {
            return "";
        }
        return pipe(
            props.state.forms.client_case_page.children.confirm_case_users.users,
            array.findFirst((user) => user.edited.is_primary_contact),
            option.fold(
                () => "",
                (user) => user.children.user_id,
            )
        );
    }

    const getPrimaryContactDropdown = () => {
        if (!props.state.forms.client_case_page.children.confirm_case_users) {
            return [];
        }

        const blankOption = { label: "Not given", value: "" }; 
        const userOptions = props.state.forms.client_case_page.children.confirm_case_users.users
            .filter((user) =>
                user.edited.user_type === "client"
                || user.edited.user_type === "authorised_third_party"
                || user.edited.user_type === "executor"
                || user.edited.user_type === "personal_representative"
                || user.edited.user_type === "lpa"
            )
            .map((user) => ({
                label: `${user.edited.first_name || "--"} ${user.edited.last_name || "--"}`,
                value: user.children.user_id
            }));
        
        return [blankOption].concat(userOptions);
    }

    const isFormReadyToSubmit = (): boolean => {
        if (props.state.forms.client_case_page.children.confirm_case_users?.company.edited.is_transacting_as_company === "yes") {
            return (
                isFilled(props.state.forms.client_case_page.children.confirm_case_users?.company.edited.company_name)
                && hasPrimaryContactBeenSet()
                && areAllClientDetailsCompleted()
            )
        }
        if (props.state.forms.client_case_page.children.confirm_case_users?.company.edited.is_transacting_as_company === "no") {
            return (
                hasPrimaryContactBeenSet()
                && areAllClientDetailsCompleted()
            );
        }

        return false;
    }

    const areAllClientDetailsCompleted = (): boolean => pipe(
        props.state.forms.client_case_page.children.confirm_case_users?.users || [],
        array.filter((user) => user.edited.user_type === "client" || user.edited.user_type === "deceased"),
        extArray.allMatchPredicate(areAllDetailsFilledIn)
    );

    const hasBeenSubmitted = () => props.state.forms.client_case_page.children.confirm_case_users?.is_step_complete;

    
    const hasPrimaryContactBeenSet = (): boolean => pipe(
        props.state.forms.client_case_page.children.confirm_case_users?.users || [],
        array.findFirst((user) => user.edited.is_primary_contact),
        option.fold(
            () => false,
            () => true,
        ),
    );

    const legalOwnersForIntro =
        getTransactionType() === "sale" ? "current legal owners"
        : getTransactionType() === "remortgage" ? "current and any new owners"
        : "new legal owners";

    const legalOwnersForClientsSection =
        getTransactionType() === "sale" ? "Owners"
        : getTransactionType() === "remortgage" ? "Current and any new owners"
        : "New Owners";

    return (
        <div>
            <FrontQuestionFormCard
                title={getWhoAreTheClientsFormTitle(props)}
            >
                <SpacingColumn spacing={FrontSpacing.LARGE_1}>

                    {/* INTRO & COMPANY DETAILS */}
                    <SpacingColumn spacing={FrontSpacing.MEDIUM_1}>
                        {/* INTRO TEXT */}
                        {!hasBeenSubmitted() &&
                            <MaxWidth width="550px">
                                <FrontOnboardingFormParagraphs
                                    paragraphs={[
                                        <>We need you to tell us <WeightBold>if you are a company</WeightBold>, and answer a <WeightBold>few questions</WeightBold> about the <WeightBold>{legalOwnersForIntro}</WeightBold> of the property.</>
                                    ]}
                                />
                            </MaxWidth>
                        }

                        <SpacingColumn spacing={FrontSpacing.MEDIUM_1}>
                            {/* RADIO - ARE YOU A COMPANY */}
                            <FrontCondensedRadioQuestions
                                value={getIsTransactingAsACompany()}
                                isEditable={!hasBeenSubmitted()}
                                label={`Are you ${getTransactionTypeAsSimpleCopyText()} as a company`}
                                options={[
                                    {
                                        label: "Yes",
                                        value: "yes"
                                    },
                                    {
                                        label: "No",
                                        value: "no"
                                    },
                                ]}
                                onChange={onChangeCompanyForm("is_transacting_as_company")}
                                displayValidationErrorsIfPresent={displayValidationErrorsIfPresent}
                            />

                            {/* (IF COMPANY) INPUT - WHAT IS COMPANY NAME */}
                            {getIsTransactingAsACompany() === "yes" &&
                                <FrontCondensedInputQuestion
                                    value={props.state.forms.client_case_page.children.confirm_case_users?.company.edited.company_name || ""}
                                    isEditable={!hasBeenSubmitted()}
                                    label="What is your company name"
                                    closedLabel="Company name"
                                    wrapInFormRow={true}
                                    onChange={onChangeCompanyForm("company_name")}
                                    displayValidationErrorsIfPresent={displayValidationErrorsIfPresent}
                                />
                            }               
                                      
                        </SpacingColumn>
                    </SpacingColumn>
                    
                    {/* CLIENT DETAILS */}
                    <SpacingColumn spacing={FrontSpacing.MEDIUM_1}>
                        <FrontFormSectionTitle>
                            {legalOwnersForClientsSection}
                        </FrontFormSectionTitle>
                        <SpacingColumn spacing={FrontSpacing.LARGE_1}>
                            {   props.state.forms.client_case_page.children.confirm_case_users &&
                                props.state.forms.client_case_page.children.confirm_case_users.users
                                    // Only show clients and deceased users as editable forms
                                    .filter((user) => user.edited.user_type === "client" || user.edited.user_type === "deceased")
                                    .map((user) => (
                                        <div key={user.original.users__cases_id}>
                                            <SpacingColumn spacing={FrontSpacing.MEDIUM_1}>
                                                <FrontClientDetailsForm
                                                    form={user}
                                                    isEditable={!hasBeenSubmitted()}
                                                    canDelete={user.children.user_id !== props.state.global.user.id}
                                                    companyForm={props.state.forms.client_case_page.children.confirm_case_users?.company}
                                                    transactionType={props.state.forms.client_case_page.children.case.transaction_type}
                                                    displayValidationErrorsIfPresent={displayValidationErrorsIfPresent}
                                                    onChange={(payload) => props.dispatch({
                                                        type: "CLIENT_UPDATE_CLIENT_USER",
                                                        payload
                                                    })}
                                                    onDelete={(payload) => props.dispatch({
                                                        type: "CLIENT_DELETE_CLIENT_USER",
                                                        payload
                                                    })}
                                                />
                                                <HorizontalLine color={FrontColors.NEUTRAL_11} />
                                            </SpacingColumn>
                                        </div>
                                    )
                            )}
                        </SpacingColumn>

                        {/* DROPDOWN - WHO IS THE PRIMARY CONTACT? */}
                        <FrontCondensedDropdownQuestion
                            label="Who is the primary contact?"
                            closedLabel="Primary contact"
                            value={getPrimaryContact()}
                            isEditable={!hasBeenSubmitted()}
                            options={getPrimaryContactDropdown()}
                            wrapInFormRow={true}
                            onChange={(payload) => props.dispatch({
                                type: "CLIENT_SET_PRIMARY_CONTACT",
                                payload
                            })}
                            displayValidationErrorsIfPresent={displayValidationErrorsIfPresent}
                        />   
                    </SpacingColumn>
                    
                    {/* CONTROLS */}
                    {!hasBeenSubmitted() &&
                        <SpacingColumn spacing={FrontSpacing.MEDIUM_1}>
                            <MediaCompSwitch
                                DesktopComponent={SpacingRow}
                                desktopProps={{ spacing: FrontSpacing.SMALL_3}}
                                MobileComponent={SpacingColumn}
                                mobileProps={{ spacing: FrontSpacing.SMALL_3 }}
                                breakpoint="600"
                            >
                                <FrontFormPrimaryButton
                                    label={`Finished? Submit all details`}
                                    icon="lock"
                                    isDisabled={false}
                                    onClick={() =>
                                        isFormReadyToSubmit()
                                            ? setIsSubmitFormOpen(true)
                                            : setDisplayValidationErrorsIfPresent(true)
                                    }
                                />

                                <FrontFormSecondaryButton
                                    icon="plus-thin"
                                    label="Add another person"
                                    onClick={() => props.dispatch({
                                        type: "CLIENT_ADD_CLIENT_USER",
                                        payload: null
                                    })}
                                />
                            </MediaCompSwitch>

                            {displayValidationErrorsIfPresent && !isFormReadyToSubmit() && <FrontInfoBubble color={FrontColors.ERROR_FIREBRICK_22}>
                                <WeightBold>You've missed a few things above,</WeightBold> please take a look and then try again.
                            </FrontInfoBubble>}
                        </SpacingColumn>
                    }

                    {hasBeenSubmitted() &&
                        <SpacingColumn spacing={FrontSpacing.MEDIUM_1}>
                            <FrontFormSectionTitle>
                                Need to change something?
                            </FrontFormSectionTitle>
                            <SpacingColumn spacing={FrontSpacing.SMALL_3}>
                                <FrontParagraphSmall>
                                    If you have noticed that some of the details you have entered are wrong <WeightBold>please call us</WeightBold> on <WeightBold>{sailLegalBrandConstants().legalCSNumber}</WeightBold> and we will fix them.
                                </FrontParagraphSmall>
                            </SpacingColumn>
                        </SpacingColumn>
                    }
                </SpacingColumn>
            </FrontQuestionFormCard>

            <FrontPopupPrompt
                title={`Is everything here?`}
                isOpen={isSubmitFormOpen}
                onCTA={() => props.dispatch({
                    type: "CLIENT_SUBMIT_CLIENTS_FORM_AS_COMPLETE",
                    payload: null
                })}
                ctaText="Yes, that's everything"
                closeText="No"
                onClose={() => setIsSubmitFormOpen(false)}
            >
                <SpacingColumn spacing={FrontSpacing.SMALL_3}>
                    <FrontParagraphRegular>
                        After you submit, you will not be able to edit this form unless you call us. So just one last time ask yourself:
                    </FrontParagraphRegular>
                    <FrontBulletpoint pointAdjust="11px">
                        <FrontParagraphRegular>
                            <WeightBold>Is every owner here?</WeightBold>
                        </FrontParagraphRegular>
                    </FrontBulletpoint>
                    <FrontBulletpoint pointAdjust="11px">
                        <FrontParagraphRegular>
                            <WeightBold>Are their details correct?</WeightBold> 
                        </FrontParagraphRegular>
                    </FrontBulletpoint>
                    <FrontParagraphRegular>
                        If you are happy with everything we can go onto the next step.
                    </FrontParagraphRegular>
                </SpacingColumn>
            </FrontPopupPrompt>
        </div>
    );
};

export const getWhoAreTheClientsFormTitle = (props: TContainerStateProps) => {
    switch (props.state.forms.client_case_page.children.case.transaction_type) {
        case "sale":
        case "remortgage":
            return "Tell us who owns this property";
        case "transfer":
        case "purchase":
            return "Tell us who will own this property";
        default:
            return "Tell us who is transacting";
    }
}

export const getWhoAreTheClientsStatus = (props: TContainerStateProps): TOnboardingTabStatus => {

    if (props.state.forms.client_case_page.children.confirm_case_users?.is_step_complete === true) {
        return "completed";
    }
    
    return "active";
};

export default FrontWhoAreTheClientsContainer;
