import React from "react";
import { IOption } from "./../../hooks/UseDropdown";
import { CRMIcon } from "../CRMIcon/CRMIcon";
import { useDropdown } from "../../hooks/UseDropdown";
import { CRMDropdownOption } from "../Simple/CRMDropdownOption/CRMDropdownOption";
import { Border } from "../BuildingBlocks/Border";
import { CRMColors } from "../../models/CRMColors";

interface ICRMMultiSelectDropdownProps<A extends string, B extends A> {
    value: Array<B>;
    options: Array<IOption<A>>;
    onChange: (value: Array<A>) => void;
    label: string;
}

export const CRMMultiSelectDropdownTextComponent = <A extends string, B extends A>(props: ICRMMultiSelectDropdownProps<A, B>): JSX.Element => {

    const {
        ref,
        isOpen,
        setIsOpen,
        isOptionSelected,
        toggleSelectedValue,
     } = useDropdown<A, IOption<A>>(props.value, props.options);

    return (
        <div
            ref={ref}
            className="crm-multi-select-dropdown-text"
        >
            {/* FACE */}
            <div
                className={`
                    crm-multi-select-dropdown-text__face-wrap
                    crm-multi-select-dropdown-text__face-wrap--${isOpen ? 'open' : 'closed'}
                `}
                onClick={() => setIsOpen(!isOpen)}
            >
                <div className="crm-multi-select-dropdown-text__face">
                    {props.label}
                </div>
                <div
                    className={`
                        crm-multi-select-dropdown-text__face-icon
                        crm-multi-select-dropdown-text__face-icon--${isOpen ? 'open' : 'closed'}
                    `}
                >
                    <CRMIcon iconName="down-arrow" colour="neutral-ink" />
                </div>
            </div>
            {/* OPTIONS */}
            {isOpen &&
                <div className="crm-multi-select-dropdown-text__options">
                    {props.options.map((selectOption, key) => (
                        <div key={key}>
                            <CRMDropdownOption
                                hasCheckbox={true}
                                isSelected={isOptionSelected(selectOption.value)}
                                label={selectOption.label}
                                onClick={() => props.onChange(toggleSelectedValue(selectOption.value))}
                            />
                            <Border type="bottom" definition={`1px solid ${CRMColors.NEUTRAL_4}`} />
                        </div>
                    ))}
                </div>
            }
        </div>
    );
};
