import React from "react";
import { TNumberMode, useNumberInput } from "../../hooks/useNumberInput";

interface ICRMInputCurrencyComponentProps {
    value: number | null;
    mode?: TNumberMode;
    disabled?: boolean;
    currencySymbol?: string;
    displayError?: boolean;
    placeholder?: string;
    onChange: (value: number | null) => void;
    onPressEnterKey?: () => void;
}

export const CRMInputCurrency = (props: ICRMInputCurrencyComponentProps): JSX.Element => {

    const {
        setIsFocused,
        faceText,
        onChange
    } = useNumberInput(
        props.value, 
        props.onChange, 
        props.mode || "pound"
    );

    return (
        <div className="crm-input-currency">
            <div
                className={`
                    crm-input-currency__currencySymbol
                    crm-input-currency__currencySymbol--${props.disabled ? 'disabled' : 'enabled'}
                    crm-input-currency__currencySymbol--${props.displayError ? 'error' : 'no-error'}
                `}
            >
                {props.currencySymbol || "£"}
            </div>
            <input
                className={`
                    crm-input-currency__input
                    crm-input-currency__input--${props.disabled ? 'disabled' : 'enabled'}
                    crm-input-currency__input--${props.displayError ? 'error' : 'no-error'}
                `}
                type="text"
                value={faceText}
                placeholder={props.placeholder}
                disabled={props.disabled}
                onChange={onChange}
                onKeyDown={(event) => {
                    if (event.key === "Enter" && typeof props.onPressEnterKey === "function") {
                        props.onPressEnterKey();
                    }
                }}
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
            />
        </div>
    );
}
