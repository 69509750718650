import React from "react";
import { Relative } from "../../BuildingBlocks/Relative";
import { TRoadblockForm } from "../../../../../domain/codecs/form/RoadblockForm";
import { CRMRoadblockStatusLabelFromForm } from "../CRMRoadblockStatusLabel/CRMRoadblockStatusLabel";
import { Absolute } from "../../BuildingBlocks/Absolute";
import { useHover } from "../../../hooks/useHover";
import { CRMRoadblockStatusLabelPopoverCancelled } from "./CRMRoadblockStatusLabelPopoverCancelled";
import { CRMRoadblockStatusLabelPopoverBlocked } from "./CRMRoadblockStatusLabelPopoverBlocked";
import { CRMRoadblockStatusLabelPopoverResolved } from "./CRMRoadblockStatusLabelPopoverResolved";
import { AnimationPopout } from "../../BuildingBlocks/AnimationPopout";

type TRoadblockStatusLabelPopoverProps = {
    form: TRoadblockForm;
    onClick?: () => void;
};

export const CRMRoadblockStatusLabelPopover = (props: TRoadblockStatusLabelPopoverProps): JSX.Element => {

    const [ref, isHovering] = useHover<HTMLDivElement>({});

    const status = props.form.children.details.status;

    const isSuspended = status === "suspended";
    const isBlocked = status === "blocked";
    const isResolved = status === "resolved";

    return (
        <Relative
            divRef={ref}
            width="fit-content"
        >
            {/* PILL */}
            <CRMRoadblockStatusLabelFromForm
                form={props.form}
                onClick={props.onClick}
            />

            {/* POPOVER */}
            {isHovering &&
                <Absolute
                    right="0"
                    top="26px"
                    width="313px"
                >
                    <AnimationPopout>
                        {/* CANCELLED */}
                        {isSuspended &&
                            <CRMRoadblockStatusLabelPopoverCancelled />
                        }

                        {/* BLOCKED */}
                        {isBlocked &&
                            <CRMRoadblockStatusLabelPopoverBlocked form={props.form} />
                        }

                        {/* RESOLVED */}
                        {isResolved &&
                            <CRMRoadblockStatusLabelPopoverResolved form={props.form} />
                        }
                    </AnimationPopout>
                </Absolute>
            }
        </Relative>
    );
};
