import React, { useEffect } from "react";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { CRMPopoutHeader } from "../../Simple/CRMPopoutHeader/CRMPopoutHeader";
import { SpacingColumn } from "../../BuildingBlocks/SpacingColumn";
import { CRMBottomControlBar } from "../../CRMBottomControlBar/CRMBottomControlBar";
import { Background } from "../../BuildingBlocks/Background";
import { SpacingRow } from "../../BuildingBlocks/SpacingRow";
import { CRMFormButton } from "../../CRMFormButton/CRMFormButton";
import { CRMButtonPrimary } from "../../CRMButtonPrimary/CRMButtonPrimary";
import { TContainerStateProps } from "../../../state/TContainerStateProps";
import { CRMCardOutsidePopupBlank } from "../../CRMCardOutsidePopupBlank/CRMCardOutsidePopupBlank";
import { CRMParagraph } from "../../Simple/CRMParagraph/CRMParagraph";
import { WeightSemiBold } from "../../WeightSemiBold/WeightSemiBold";
import { CRMParagraphBulletPoints } from "../../CRMBulletList/CRMParagraphBulletPoints";
import { requireExhaustive } from "../../../../../shared/src/util";
import { TChaseReasons } from "../../../../../domain/codecs/Cases";
import { CRMButtonSecondary } from "../../CRMButtonSecondary/CRMButtonSecondary";

export const ChaseReasons_displayString = (chaseReason: TChaseReasons) => {
    switch (chaseReason) {
        case "abeyance_awaiting_gop_or_loa":
            return "Abeyance - GOP/LOA awaited";
        case "abeyance_awaiting_mos":
            return "Abeyance - MOS awaited";
        case "abeyance_on_market_awaiting_offer":
            return "Abeyance - On market, offer awaited";
        case "abeyance_property_not_yet_on_market":
            return "Abeyance - Property not on market yet";
        case "abeyance_awaiting_death_certificate":
            return "Abeyance - Death certificate awaited";
        case "awaiting_opening_new_case":
            return "Opening a new case";
        case "other":
            return "Other";
        case "awaiting_enquiries":
            return "Enquiries awaited";
        case "awaiting_replies_to_enquiries":
            return "Enquiry replies awaited";
        case "awaiting_replies_to_enquiries_management_co":
            return "Enquiry replies (management company) awaited";
        case "awaiting_replies_to_enquiries_client":
            return "Enquiry replies (client) awaited";
        case "awaiting_contract_pack":
            return "Contract pack awaited";
        case "awaiting_lpe1":
            return "LPE1 awaited";
        case "awaiting_management_co_to_send_something":
            return "Management company to send something";
        case "abeyance_client_asked_to_pause_work":
            return "Abeyance - Client has asked for work to be paused";
        case "awaiting_redemption_statement":
            return "Redemption statement awaited";
        case "awaiting_client_post":
            return "Post from client awaited";
        case "awaiting_client_doc_signing":
            return "Client to sign documents";
        case "awaiting_money_from_client":
            return "Money from client awaited";
        case "awaiting_client_completing_protocol_forms":
            return "Client to complete protocol forms";
        case "awaiting_client_mortgage_offer":
            return "Client mortgage offer awaited";
        case "awaiting_completion_date_from_other_side":
            return "Completion date (from other side) awaited";
        case "awaiting_other_side_related_transaction_to_catch_up":
            return "Other side related transaction needs to catch up";
        case "awaiting_response_from_client":
            return "Client response awaited";
        case "awaiting_response_from_help_to_buy":
            return "Help to Buy response awaited";
        case "awaiting_response_from_local_authority":
            return "Local authority response awaited";
        case "awaiting_response_from_other_side":
            return "Other side response awaited";
        case "awaiting_searches":
            return "Searches awaited";
        default:
            return requireExhaustive(chaseReason);
    }
};

export const CRMCloseChaseContinueCasePopoutChaserView = (
    props: TContainerStateProps & {
        isOpen: boolean,
        onClose: () => void,
    }): JSX.Element => {
    
    const reasons = props.state.forms.chase_mode_form.children.current_chase_reasons;
    const getAcceptChaseForm = () => props.state.forms.chase_mode_form.children.accept_chase_transition_form;
    const getRejectChaseForm = () => props.state.forms.chase_mode_form.children.reject_from_chase_mode_form;
    const isSubmitting = getAcceptChaseForm().status === "submitting" || getRejectChaseForm().status === "submitting";

    useEffect(
        () => {
            if (getAcceptChaseForm().status === "success") {
                props.onClose();
            }
        },
        [getAcceptChaseForm().status],
    );

    useEffect(
        () => {
            if (getRejectChaseForm().status === "success") {
                props.onClose();
            }
        },
        [getRejectChaseForm().status],
    );

    return (
        <CRMCardOutsidePopupBlank
            isOpen={props.isOpen}
            context="none"
            size="regular"
            borderRadius="5px"
            cardMargin="78px 0px"
            onClose={props.onClose}
        >
            {/* HEADER */}
            <CRMPopoutHeader
                icon="arrow-left"
                borderRadius="5px"
                onClick={props.onClose}
            >
                Approve/Reject Chase Request
            </CRMPopoutHeader>
            
            <Background padding={`${CRMSpacing.LARGE} ${CRMSpacing.LARGE} ${CRMSpacing.LARGE} ${CRMSpacing.LARGE}`} minHeight="100px">
                <SpacingColumn spacing={CRMSpacing.SMALL}>
                    <CRMParagraph>
                        <SpacingColumn spacing={CRMSpacing.SMALL}>
                            <WeightSemiBold>Reasons:</WeightSemiBold>
                            <CRMParagraphBulletPoints
                                bullets={reasons.map((reason) => ChaseReasons_displayString(reason))}
                            />
                        </SpacingColumn>
                    </CRMParagraph>
                </SpacingColumn>
            </Background>


            {/* ACCEPT/REJECT BUTTONS*/} 
            <div style={{overflow: "clip", borderRadius: "5px"}}>
                <CRMBottomControlBar
                    justifyContent="flex-end"
                    edge="border"
                >
                    <SpacingColumn>
                        <Background padding={CRMSpacing.MEDIUM}>
                            <SpacingRow spacing={CRMSpacing.MEDIUM} justifyContent="end">
                                {/* REJECT */}
                                <CRMFormButton
                                    ButtonElement={CRMButtonSecondary}
                                    formStatus={getRejectChaseForm().status}
                                    label="Reject"
                                    disabled={isSubmitting}
                                    onClick={() => props.dispatch({
                                        type: "LEGAL_CASE_REJECT_FROM_CHASE_MODE_FORM_SUBMIT",
                                        payload: undefined,
                                    })}
                                />

                                {/* ACCEPT */}
                                <CRMFormButton
                                    ButtonElement={CRMButtonPrimary}
                                    formStatus={getAcceptChaseForm().status}
                                    label="Accept"
                                    disabled={isSubmitting}
                                    onClick={() => props.dispatch({
                                        type: "LEGAL_CASE_ACCEPT_TO_CHASE_MODE_FORM_SUBMIT",
                                        payload: undefined,
                                    })}
                                />
                            </SpacingRow>
                        </Background>
                    </SpacingColumn>
                </CRMBottomControlBar>
            </div>
        </CRMCardOutsidePopupBlank>
    );
};
