import React from "react";
import { EmailTemplatePadding } from "./EmailTemplatePadding";

type TEmailTemplateBlockProps = {
    padding?: [
        number, 
        number | undefined, 
        number | undefined, 
        number | undefined
    ];
    align?: "left" | "center" | "right",
    valign?: "top" | "middle" | "bottom",
};

export const EmailTemplateBlock = (props: React.PropsWithChildren<TEmailTemplateBlockProps>): JSX.Element => {

    if (!!props.padding) {
        return (
            <table style={{width: "100%"}}>
                <tbody style={{width: "100%"}}>
                    <EmailTemplatePadding
                        padding={props.padding}
                        align={props.align}
                        valign={props.valign}
                    >
                        {props.children}
                    </EmailTemplatePadding>
                </tbody>
            </table>
        )
    }

    return (
        <table
            style={{
                textAlign: props.align,
                verticalAlign: props.valign,
                margin: props.align === "center" ? "0 auto" : "",
                width: "100%"
            }}
        >
            <tbody style={{width: "100%"}}>
                <tr style={{width: "100%"}}>
                    <td
                        style={{
                            textAlign: props.align,
                            verticalAlign: props.valign,
                            margin: props.align === "center" ? "0 auto" : "",
                            width: "100%",
                        }}
                        valign={props.valign}
                    >
                        {props.children}
                    </td>
                </tr>
            </tbody>
        </table>
    );
};
