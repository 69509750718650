import React from "react";
import { useResolutionStatus } from "../../../hooks/useResolutionStatus";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { Padding } from "../../BuildingBlocks/Padding";
import { Relative } from "../../BuildingBlocks/Relative";
import { SpacingColumn } from "../../BuildingBlocks/SpacingColumn";
import { CRMEmptyPlaceholder } from "../../Simple/CRMEmptyPlaceholder/CRMEmptyPlaceholder";
import { CRMTableContentPlaceholder } from "../../Simple/CRMTableContentPlaceholder/CRMTableContentPlaceholder";
import { TClientCompanyPartnershipDeleteForm, TClientCompanyPartnershipForm, TClientCompanyPartnershipPageForm } from "../../../../../domain/codecs/form/ClientCompanyPartnershipForm";
import { CRMCompanyPartnerRow } from "../CRMCompanyPartnerRow/CRMCompanyPartnerRow";
import { CRMBlockPopover } from "../../CRM/CRMBlock/CRMBlockPopover/CRMBlockPopover";
import { CRMConfirmationCountdown } from "../../Complex/CRMConfirmationCountdown/CRMConfirmationCountdown";

type TAction = (emailForm: TClientCompanyPartnershipForm, index: number) => void;

type TResolutionStatus = 
    "none"
    | "archiving-company"
;

export const CRMCompanyPartnersTable = (props: React.PropsWithChildren<{
    form: TClientCompanyPartnershipPageForm;
    onOpen: TAction;
    onEdit: TAction;
    onDelete: (form: TClientCompanyPartnershipDeleteForm) => void;
}>): JSX.Element => {

    const {
        resolutionStatus,
        resolutionData,
        setResolution,
        resetResolution
    } = useResolutionStatus<TResolutionStatus, TClientCompanyPartnershipDeleteForm>({
        defaultStatus: "none"
    })
    
    const isLoading = () => (
        props.form.status === "loading"
        || props.form.status === "submitting"
    );

    const isEmpty = () => props.form.children.client_company_partnership_forms.length === 0;

    const onBeginDelete = (form: TClientCompanyPartnershipDeleteForm) => () =>
        setResolution("archiving-company")(form);

    const onCompleteDelete = () => {
        props.onDelete(resolutionData as TClientCompanyPartnershipDeleteForm);
        resetResolution();
    }

    return (
        <Relative>
            <SpacingColumn spacing={CRMSpacing.LARGE}>
                <SpacingColumn>
                    {/* ROWS */}
                    {(!isLoading() && !isEmpty()) && 
                        props.form.children.client_company_partnership_forms.map((companyPartner, index) => (
                            <CRMCompanyPartnerRow
                                key={index}
                                companyPartner={companyPartner}
                                onClick={() => props.onOpen(companyPartner, index)}
                                onEdit={() => props.onEdit(companyPartner, index)}
                                onDelete={onBeginDelete(companyPartner.children.delete_form)}
                            />       
                        ))
                    }

                    {/* LOADING BAR */}
                    {(isLoading() && !isEmpty()) &&
                        <CRMTableContentPlaceholder
                            rowCount={props.form.children.client_company_partnership_forms.length * 2}
                        />
                    }

                    {/* EMPTY */}
                    {(!isLoading() && isEmpty()) &&
                        <Padding spacing={CRMSpacing.LARGE}>
                            <CRMEmptyPlaceholder
                                iconSize="70px"
                                textGap={CRMSpacing.TINY}
                            >
                                No company's found.
                            </CRMEmptyPlaceholder>
                        </Padding>
                    }
                </SpacingColumn>
            </SpacingColumn>

            {/* CONFIRMATION POPCARD - SINGLE DELETE */}
            {resolutionStatus === "archiving-company" &&
                <CRMBlockPopover>
                    <CRMConfirmationCountdown
                        title={`Deleting company!`}
                        iconName="archive"
                        primaryButtonMode="done"
                        duration={5000}
                        onUndo={resetResolution}
                        onComplete={onCompleteDelete}
                    /> 
                </CRMBlockPopover>
            }
        </Relative>
    );
};
