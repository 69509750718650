import React from "react";
import { TContainerStateProps } from "./../../state/TContainerStateProps";
import { CRMTitleSection } from "../../components/CRMTitleSection/CRMTitleSection";
import { CRMSpacer } from "../../components/CRMSpacer/CRMSpacer";
import { CRMAutosaveIndicatorWrap } from "../../components/CRM/CRMAutosaveIndicatorWrap/CRMAutosaveIndicatorWrap";
import { SpacingColumn } from "../../components/BuildingBlocks/SpacingColumn";
import { CRMSpacing } from "../../models/CRMSpacing";
import { CRMFormButton } from "../../components/CRMFormButton/CRMFormButton";
import { CRMButtonPrimary } from "../../components/CRMButtonPrimary/CRMButtonPrimary";
import { InlineFlex } from "../../components/BuildingBlocks/InlineFlex";
import { CRMCodecEditForm } from "../../components/CRMCodecEditForm/CRMCodecEditForm";
import { CaseContractEsignConfigBooleans, CaseContractEsignExtraDocs, CaseContractTa13ConfigBooleans } from "../../../../domain/codecs/CasesContractBlock";
import { CRMTitleSubSection } from "../../components/CRMTitleSubSection/CRMTitleSubSection";
import { Background } from "../../components/BuildingBlocks/Background";

const CRMLegalCaseDetailsContractDocumentGenerationContainer = (props: TContainerStateProps): JSX.Element => {

    return <CRMAutosaveIndicatorWrap
        status={props.state.forms.contracts_block.children.details.status}
    >
        <CRMTitleSection>
            Document Generators
        </CRMTitleSection>
        <CRMSpacer size="large" />

        <SpacingColumn spacing={CRMSpacing.LARGE}>
            <SpacingColumn spacing={CRMSpacing.MEDIUM}>
                <CRMTitleSubSection>
                    TA13
                </CRMTitleSubSection>

                <CRMCodecEditForm
                    codec={CaseContractTa13ConfigBooleans}
                    model={props.state.forms.contracts_block.children.details.edited}
                    validationErrors={props.state.forms.contracts_block.children.details.validationErrors}
                    columns={1}
                    onChange={(edited) => props.dispatch({
                        type: "LEGAL_CASE_CONTRACTS_BLOCK_CHANGE",
                        payload: {
                            ...props.state.forms.contracts_block.children.details,
                            edited: {
                                ...props.state.forms.contracts_block.children.details.edited,
                                ...edited,
                            }
                        }
                    })}
                    showDeferDateLabels={true}
                />

                <InlineFlex>
                    <CRMFormButton
                        formStatus={props.state.forms.contracts_block.children.create_TA13_form.status}
                        ButtonElement={CRMButtonPrimary}
                        label="Create TA13"
                        onClick={() => props.dispatch({
                            type: "LEGAL_CONTRACT_CREATE_TA13",
                        })}
                        enabledOnUntouched={true}
                    />
                </InlineFlex>
            </SpacingColumn>

            <SpacingColumn spacing={CRMSpacing.MEDIUM}>
                <CRMTitleSubSection>
                    Transfer Disclaimer
                </CRMTitleSubSection>

                <InlineFlex>
                    <CRMFormButton
                        formStatus={props.state.forms.contracts_block.children.create_transfer_disclaimer_form.status}
                        ButtonElement={CRMButtonPrimary}
                        label="Create Transfer Disclaimer"
                        onClick={() => props.dispatch({
                            type: "LEGAL_CONTRACT_CREATE_TRANSFER_DISCLAIMER",
                        })}
                        enabledOnUntouched={true}
                    />
                </InlineFlex>
            </SpacingColumn>

            <SpacingColumn spacing={CRMSpacing.MEDIUM}>
                <CRMTitleSubSection>
                    Esign Cert
                </CRMTitleSubSection>

                <CRMCodecEditForm
                    codec={CaseContractEsignConfigBooleans}
                    model={props.state.forms.contracts_block.children.details.edited}
                    validationErrors={props.state.forms.contracts_block.children.details.validationErrors}
                    columns={1}
                    onChange={(edited) => props.dispatch({
                        type: "LEGAL_CASE_CONTRACTS_BLOCK_CHANGE",
                        payload: {
                            ...props.state.forms.contracts_block.children.details,
                            edited: {
                                ...props.state.forms.contracts_block.children.details.edited,
                                ...edited,
                            }
                        }
                    })}
                    showDeferDateLabels={true}
                />

                <CRMCodecEditForm
                    codec={CaseContractEsignExtraDocs}
                    model={props.state.forms.contracts_block.children.details.edited}
                    validationErrors={props.state.forms.contracts_block.children.details.validationErrors}
                    columns={1}
                    onChange={(edited) => props.dispatch({
                        type: "LEGAL_CASE_CONTRACTS_BLOCK_CHANGE",
                        payload: {
                            ...props.state.forms.contracts_block.children.details,
                            edited: {
                                ...props.state.forms.contracts_block.children.details.edited,
                                ...edited,
                            }
                        }
                    })}
                    showDeferDateLabels={true}
                />

                <InlineFlex>
                    <CRMFormButton
                        formStatus={props.state.forms.contracts_block.children.create_esign_cert_form.status}
                        ButtonElement={CRMButtonPrimary}
                        label="Create Esign Cert"
                        onClick={() => props.dispatch({
                            type: "LEGAL_CONTRACT_CREATE_ESIGN_CERT",
                        })}
                        enabledOnUntouched={true}
                    />
                </InlineFlex>
            </SpacingColumn>
        </SpacingColumn>
    </CRMAutosaveIndicatorWrap>;
}

export default CRMLegalCaseDetailsContractDocumentGenerationContainer;