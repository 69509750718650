import React, { useState } from "react";
import { SpacingColumn } from "../../components/BuildingBlocks/SpacingColumn";
import { FrontQuestionFormCard } from "../../components/Front/Complex/FrontQuestionFormCard/FrontQuestionFormCard";
import FrontParagraphSmall from "../../components/Front/Simple/FrontParagraphSmall/FrontParagraphSmall";
import { TContainerStateProps } from "../../state/TContainerStateProps";
import { FrontSpacing } from "../../models/FrontSpacing";
import { WeightBold } from "../../components/WeightBold/WeightBold";
import { TOnboardingTabStatus } from "../../components/Front/Simple/FrontOnboardingTab/FrontOnboardingTab";
import { ClientCaseWillReferralForm, TClientCaseWillReferralFormUserAgeBracket } from "../../../../domain/codecs/form/ClientCaseForm";
import { WeightSemiBold } from "../../components/WeightSemiBold/WeightSemiBold";
import { WeightMedium } from "../../components/WeightMedium/WeightMedium";
import { FrontCondensedQuestionWrap } from "../../components/Front/Simple/FrontCondensedQuestionWrap/FrontCondensedQuestionWrap";
import { FrontInfoBubble } from "../../components/Front/Simple/FrontInfoBubble/FrontInfoBubble";
import { FrontColors } from "../../models/FrontColors";
import { FrontHelpBubble } from "../../components/Front/Simple/FrontHelpBubble/FrontHelpBubble";
import FrontInputLabelAndErrorWrapComponent from "../../components/Front/Simple/FrontInputLabelAndErrorWrapComponent/FrontInputLabelAndErrorWrapComponent";
import { FrontRadioList } from "../../components/Front/Simple/FrontRadioList/FrontRadioList";
import { MediaCompSwitch } from "../../components/BuildingBlocks/MediaCompSwitch";
import { SpacingRow } from "../../components/BuildingBlocks/SpacingRow";
import { FrontFormPrimaryButton } from "../../components/Front/Simple/FrontFormPrimaryButton/FrontFormPrimaryButton";
import { FrontPopupPrompt } from "../../components/Front/Complex/FrontPopupPrompt/FrontPopupPrompt";
import FrontParagraphRegular from "../../components/Front/Simple/FrontParagraphRegular/FrontParagraphRegular";
import { TTransactionType1, TransactionType1_TransactionType4 } from "../../../../domain/codecs/TransactionType";

const FrontWillReferralContainer = (props: TContainerStateProps): JSX.Element => {
    const form = props.state.forms.client_case_page.children.will_referral?.form || ClientCaseWillReferralForm.newDefault();

    const isStepComplete =
        typeof props.state.forms.client_case_page.children.will_referral !== "undefined"
            ? props.state.forms.client_case_page.children.will_referral.is_step_complete
            : true;

    const sessionUserCaseUserType = props.state.forms.client_case_page.children.session_user_case_user_type.user_type;

    const isClientView =
        !isStepComplete
        && sessionUserCaseUserType === "client";

    const isExecutorView =
        !isStepComplete
        && sessionUserCaseUserType === "executor";

    const isGiftorView =
        !isStepComplete
        && sessionUserCaseUserType === "giftor";

    const isAnyOtherView =
        !isStepComplete
        && !isClientView
        && !isExecutorView
        && !isGiftorView;

    const transactionType =
        props.state.forms.client_case_page.children.case.transaction_type;

    const address =
        props.state.forms.client_case_page.children.case.address;

    const onChange = (value: boolean) =>
        props.dispatch({
            type: "CLIENT_WILL_REFERRAL_CHANGE",
            payload: {
                ...form,
                edited: {
                    ...form.edited,
                    refer_to_sail_wills_for_will: value,
                },
            },
        });

    const onSubmit = (value: boolean) =>
        props.dispatch({
            type: "CLIENT_WILL_REFERRAL_SUBMIT",
            payload: {
                ...form,
                edited: {
                    ...form.edited,
                    refer_to_sail_wills_for_will: value,
                },
            },
        });

    return <>
        {/* CLIENT VIEW */}
        {isClientView &&
            <ClientView
                value={form.edited.refer_to_sail_wills_for_will}
                transactionType={transactionType}
                address={address}
                userAgeBracket={form.children.user_age_bracket}
                onChange={onChange}
                onSubmit={onSubmit}
            />
        }

        {/* EXECUTOR VIEW */}
        {isExecutorView &&
            <ExecutorView
                value={form.edited.refer_to_sail_wills_for_will}
                transactionType={transactionType}
                isIntestate={form.children.is_intestate}
                onChange={onChange}
                onSubmit={onSubmit}
            />
        }

        {/* GIFTOR VIEW */}
        {isGiftorView &&
            <GiftorView
                value={form.edited.refer_to_sail_wills_for_will}
                transactionType={transactionType}
                onChange={onChange}
                onSubmit={onSubmit}
            />
        }

        {/* OTHERS VIEW */}
        {isAnyOtherView &&
            <OthersView
                value={form.edited.refer_to_sail_wills_for_will}
                address={address}
                transactionType={transactionType}
                onChange={onChange}
                onSubmit={onSubmit}
            />
        }

        {/* STEP COMPLETE */}
        {isStepComplete &&
            <FrontQuestionFormCard
                title="Protecting your family's future"
            >
                <FrontParagraphSmall>
                    Thank you, if you've asked us to get in touch we will do so. <WeightSemiBold>If you need to talk to Sail Wills now you can call on 0808 196 7498.</WeightSemiBold>
                </FrontParagraphSmall>
            </FrontQuestionFormCard>
        }
    </>
};

const ClientView = (props: {
    value: boolean | null,
    transactionType: TTransactionType1,
    address: string,
    userAgeBracket: TClientCaseWillReferralFormUserAgeBracket,
    onChange: (value: boolean) => void,
    onSubmit: (value: boolean) => void,
}) =>
    <FrontQuestionFormCard
        title="Protecting your family's future"
    >
        <SpacingColumn spacing={FrontSpacing.MEDIUM_2}>
            <SpacingColumn spacing={FrontSpacing.SMALL_2}>
                <FrontParagraphSmall>
                    With the {TransactionType1_TransactionType4(props.transactionType).toLowerCase()} of {props.address} your circumstances have changed.&nbsp;
                    <WeightSemiBold>You need to make sure everyone knows how to follow your wishes in the event of your death.</WeightSemiBold>
                </FrontParagraphSmall>

                {/* AGE - YOUNG */}
                {props.userAgeBracket === "young" &&
                    <FrontParagraphSmall>
                        We all think of creating a will as something we'll do when we're older. The truth is that's often too late.&nbsp;
                        <WeightSemiBold>Now is the time to create a will.</WeightSemiBold>
                    </FrontParagraphSmall>
                }

                {/* AGE - MID */}
                {props.userAgeBracket === "mid" &&
                    <FrontParagraphSmall>
                        It's not fun to talk about but leaving your family without an updated will could be <WeightMedium>disastrous</WeightMedium> for them.&nbsp;
                        <WeightSemiBold>Now is the time to update your will.</WeightSemiBold>
                    </FrontParagraphSmall>
                }

                {/* AGE - OLDER */}
                {props.userAgeBracket === "older" &&
                    <FrontParagraphSmall>
                        Unfortunately as we get older it becomes more important to update our will often. An outdated will could mean that your wishes are not followed.&nbsp;
                        <WeightSemiBold>Now is the time to update your will.</WeightSemiBold>
                    </FrontParagraphSmall>
                }

                <FrontParagraphSmall>
                    Below are a few questions for us to understand what you will need.
                </FrontParagraphSmall>
            </SpacingColumn>

            {/* QUESTIONS */}
            <Questions
                transactionType={props.transactionType}
                iWantToBeReferredQuestion={props.value}
                isYourWillUpdatedQuestionLabel={`Have you updated your will to reflect this ${TransactionType1_TransactionType4(props.transactionType).toLowerCase()}?`}
                beforeYouGoContent={<SpacingColumn spacing={FrontSpacing.MEDIUM_1}>
                    <SpacingColumn spacing={FrontSpacing.SMALL_2}>
                        <FrontParagraphSmall>
                            <WeightMedium>
                                We understand this is a busy time for you.
                            </WeightMedium>
                        </FrontParagraphSmall>

                        <FrontParagraphSmall>
                            <WeightSemiBold>
                                Skipping this step could put a lot of stress on your family when you die.
                            </WeightSemiBold>
                        </FrontParagraphSmall>
                    </SpacingColumn>

                    <FrontParagraphSmall>
                        Sail Wills are on hand to talk <WeightBold>(for free)</WeightBold> and can help you on what best to do next.
                    </FrontParagraphSmall>
                </SpacingColumn>}
                onChangeIWantToBeReferred={props.onChange}
                onSubmit={props.onSubmit}
            />
        </SpacingColumn>
    </FrontQuestionFormCard>;

const ExecutorView = (props: {
    value: boolean | null,
    transactionType: TTransactionType1,
    isIntestate: boolean,
    onChange: (value: boolean) => void,
    onSubmit: (value: boolean) => void,
}) =>
    <FrontQuestionFormCard
        title="Protecting your family's future"
    >
        <SpacingColumn spacing={FrontSpacing.MEDIUM_2}>
            <SpacingColumn spacing={FrontSpacing.SMALL_2}>
                {/* IS INTESTATE */}
                {props.isIntestate &&
                    <FrontParagraphSmall>
                        We know this is a particularly difficult time for you. Acting as an Executor for a loved one can be a stressful and emotional experience.&nbsp;
                        <WeightSemiBold>We know this is even more difficult when there isn't a will to follow.</WeightSemiBold>&nbsp;
                        <WeightMedium>This is why it's important that you also consider your own will and if you've made your wishes clear.</WeightMedium>
                    </FrontParagraphSmall>
                }

                {/* IS NOT INTESTATE */}
                {!props.isIntestate &&
                    <FrontParagraphSmall>
                        We know this is a particularly difficult time for you. Acting as an Executor for a loved one can be a stressful and emotional experience.&nbsp;
                        <WeightMedium>This is why it's important that you also consider your own will and if you've made your wishes clear.</WeightMedium>
                    </FrontParagraphSmall>
                }

                <FrontParagraphSmall>
                    <WeightSemiBold>
                        Having an up-to-date will means you've made your wishes clear. Everyone can then act for them when you aren't here.
                    </WeightSemiBold>
                </FrontParagraphSmall>

                <FrontParagraphSmall>
                    Below are a few questions for us to understand what you will need.
                </FrontParagraphSmall>
            </SpacingColumn>

            {/* QUESTIONS */}
            <Questions
                transactionType={props.transactionType}
                iWantToBeReferredQuestion={props.value}
                isYourWillUpdatedQuestionLabel={`Have you updated your will to reflect this ${TransactionType1_TransactionType4(props.transactionType).toLowerCase()}?`}
                beforeYouGoContent={<SpacingColumn spacing={FrontSpacing.MEDIUM_1}>
                    <SpacingColumn spacing={FrontSpacing.SMALL_2}>
                        <FrontParagraphSmall>
                            <WeightMedium>
                                We understand this is a busy and difficult time for you.
                            </WeightMedium>
                        </FrontParagraphSmall>

                        <FrontParagraphSmall>
                            <WeightMedium>
                                It's important for us that we give you the best possible advice.
                            </WeightMedium>
                        </FrontParagraphSmall>

                        <FrontParagraphSmall>
                            <WeightSemiBold>
                                Leaving your family without an updated will could put them under a lot of stress.
                            </WeightSemiBold>
                        </FrontParagraphSmall>
                    </SpacingColumn>

                    <FrontParagraphSmall>
                        Sail Wills are on hand to talk <WeightBold>(for free)</WeightBold> and can help you on what best to do next.
                    </FrontParagraphSmall>
                </SpacingColumn>}
                onChangeIWantToBeReferred={props.onChange}
                onSubmit={props.onSubmit}
            />
        </SpacingColumn>
    </FrontQuestionFormCard>;

const GiftorView = (props: {
    value: boolean | null,
    transactionType: TTransactionType1,
    onChange: (value: boolean) => void,
    onSubmit: (value: boolean) => void,
}) =>
    <FrontQuestionFormCard
        title="Protecting your family's future"
    >
        <SpacingColumn spacing={FrontSpacing.MEDIUM_2}>
            <SpacingColumn spacing={FrontSpacing.SMALL_2}>
                <FrontParagraphSmall>
                    With or without a Declaration of Trust you should also take the time to update your will.
                </FrontParagraphSmall>

                <FrontParagraphSmall>
                    <WeightMedium>Updating your will allows you to note the gift you have made.</WeightMedium>&nbsp;
                    Other beneficiaries can then take this gift into account, if you want them to.
                </FrontParagraphSmall>

                <FrontParagraphSmall>
                    <WeightSemiBold>
                        Having an up-to-date will means you've made your wishes clear. Everyone can then act for them when you aren't here.
                    </WeightSemiBold>
                </FrontParagraphSmall>

                <FrontParagraphSmall>
                    Below are a few questions for us to understand what you will need.
                </FrontParagraphSmall>
            </SpacingColumn>

            {/* QUESTIONS */}
            <Questions
                transactionType={props.transactionType}
                iWantToBeReferredQuestion={props.value}
                isYourWillUpdatedQuestionLabel="Have you updated your will to reflect your gift?"
                beforeYouGoContent={<SpacingColumn spacing={FrontSpacing.MEDIUM_1}>
                    <SpacingColumn spacing={FrontSpacing.SMALL_2}>
                        <FrontParagraphSmall>
                            <WeightMedium>
                                It's important that you've updated your will to note your gift.
                            </WeightMedium>
                        </FrontParagraphSmall>

                        <FrontParagraphSmall>
                            <WeightSemiBold>
                                Leaving your family without an updated will could put them under a lot of stress.
                            </WeightSemiBold>
                        </FrontParagraphSmall>
                    </SpacingColumn>

                    <FrontParagraphSmall>
                        Sail Wills are on hand to talk <WeightBold>(for free)</WeightBold> and can help you on what best to do next.
                    </FrontParagraphSmall>
                </SpacingColumn>}
                onChangeIWantToBeReferred={props.onChange}
                onSubmit={props.onSubmit}
            />
        </SpacingColumn>
    </FrontQuestionFormCard>;

const OthersView = (props: {
    value: boolean | null,
    address: string,
    transactionType: TTransactionType1,
    onChange: (value: boolean) => void,
    onSubmit: (value: boolean) => void,
}) =>
    <FrontQuestionFormCard
        title="Protecting your family's future"
    >
        <SpacingColumn spacing={FrontSpacing.MEDIUM_2}>
            <SpacingColumn spacing={FrontSpacing.SMALL_2}>
                <FrontParagraphSmall>
                    If the {TransactionType1_TransactionType4(props.transactionType).toLowerCase()} of {props.address} has changed things for you it's important to update your will.
                </FrontParagraphSmall>

                <FrontParagraphSmall>
                    <WeightSemiBold>
                        Having an up-to-date will means you've made your wishes clear. Everyone can then act for them when you aren't here.
                    </WeightSemiBold>
                </FrontParagraphSmall>

                <FrontParagraphSmall>
                    Below are a few questions for us to understand what you will need.
                </FrontParagraphSmall>
            </SpacingColumn>

            {/* QUESTIONS */}
            <Questions
                transactionType={props.transactionType}
                iWantToBeReferredQuestion={props.value}
                isYourWillUpdatedQuestionLabel="Have you recently updated your will?"
                beforeYouGoContent={<SpacingColumn spacing={FrontSpacing.MEDIUM_1}>
                    <SpacingColumn spacing={FrontSpacing.SMALL_2}>
                        <FrontParagraphSmall>
                            <WeightMedium>
                                It's important that you've got a will that is up-to-date.
                            </WeightMedium>
                        </FrontParagraphSmall>

                        <FrontParagraphSmall>
                            <WeightSemiBold>
                                Leaving your family without an updated will could put them under a lot of stress.
                            </WeightSemiBold>
                        </FrontParagraphSmall>
                    </SpacingColumn>

                    <FrontParagraphSmall>
                        Sail Wills are on hand to talk <WeightBold>(for free)</WeightBold> and can help you on what best to do next.
                    </FrontParagraphSmall>
                </SpacingColumn>}
                onChangeIWantToBeReferred={props.onChange}
                onSubmit={props.onSubmit}
            />
        </SpacingColumn>
    </FrontQuestionFormCard>;

const Questions = (props: {
    transactionType: TTransactionType1,
    iWantToBeReferredQuestion: boolean | null,
    isYourWillUpdatedQuestionLabel: string,
    beforeYouGoContent: JSX.Element,
    onChangeIWantToBeReferred: (value: boolean) => void,
    onSubmit: (value: boolean) => void,
}) => {
    const [doYouHaveAWillQuestion, setDoYouHaveAWillQuestion] = useState<boolean | null>(null);

    const [isYourWillUpdatedQuestion, setIsYourWillUpdatedQuestion] = useState<boolean | null>(null);

    const [doYouHaveAWillQuestionIsOpen, setDoYouHaveAWillQuestionIsOpen] = useState<boolean>(true);

    const [isYourWillUpdatedQuestionIsOpen, setIsYourWillUpdatedQuestionIsOpen] = useState<boolean>(true);

    const [iWantToBeReferredQuestionIsOpen, setIWantToBeReferredQuestionIsOpen] = useState<boolean>(true);

    const [submitPopupIsOpen, setSubmitPopupIsOpen] = useState<boolean>(false);

    const [beforeYouGoPopupIsOpen, setBeforeYouGoPopupIsOpen] = useState<boolean>(false);

    const [displayValidationErrorsIfPresent, setDisplayValidationErrorsIfPresent] = useState<boolean>(false);

    const isFormReadyToSubmit =
        (
            doYouHaveAWillQuestion === false
            || (
                doYouHaveAWillQuestion === true
                && isYourWillUpdatedQuestion !== null
            )
        )
        && props.iWantToBeReferredQuestion !== null;

    const isRecommendedToHaveWillUpdated =
        doYouHaveAWillQuestion === false
        || (
            doYouHaveAWillQuestion === true
            && isYourWillUpdatedQuestion === false
        );

    const onChangeDoYouHaveAWill = (value: boolean) => {
        setDoYouHaveAWillQuestion(value);
        setDoYouHaveAWillQuestionIsOpen(false);
        if (value && isYourWillUpdatedQuestion === null) {
            setIsYourWillUpdatedQuestionIsOpen(true);
        }
        if (!value && props.iWantToBeReferredQuestion === null) {
            setIWantToBeReferredQuestionIsOpen(true);
        }
    };

    const onChangeIsYourWillUpdated = (value: boolean) => {
        setIsYourWillUpdatedQuestion(value);
        setIsYourWillUpdatedQuestionIsOpen(false);
        if (props.iWantToBeReferredQuestion === null) {
            setIWantToBeReferredQuestionIsOpen(true);
        }
    };

    const onChangeIWantToBeReferred = (value: boolean) => {
        setIWantToBeReferredQuestionIsOpen(false);
        props.onChangeIWantToBeReferred(value);
    };

    const submitWithReferral = () =>
        props.onSubmit(true);

    return <>
        <SpacingColumn spacing={FrontSpacing.MEDIUM_2}>
            {/* QUESTIONS */}
            <SpacingColumn spacing={FrontSpacing.MEDIUM_1}>
                {/* DO YOU HAVE A WILL */}
                <FrontCondensedQuestionWrap
                    isOpen={doYouHaveAWillQuestionIsOpen}
                    label="Do you have a will"
                    summary={doYouHaveAWillQuestion ? "Yes" : "No"}
                    setIsOpen={() => setDoYouHaveAWillQuestionIsOpen(true)}
                >
                    <FrontInputLabelAndErrorWrapComponent label={<WeightMedium>Do you have a will?</WeightMedium>}>
                        <FrontRadioList
                            value={doYouHaveAWillQuestion}
                            options={[
                                {
                                    value: true,
                                    label: "Yes",
                                },
                                {
                                    value: false,
                                    label: "No",
                                },
                            ]}
                            onChange={onChangeDoYouHaveAWill}
                        />
                    </FrontInputLabelAndErrorWrapComponent>
                </FrontCondensedQuestionWrap>

                {/* WILL UPDATED QUESTION */}
                {doYouHaveAWillQuestion !== false &&
                    <FrontCondensedQuestionWrap
                        isOpen={isYourWillUpdatedQuestionIsOpen}
                        label="Has your will been updated"
                        summary={isYourWillUpdatedQuestion ? "Yes" : "No"}
                        setIsOpen={() => setIsYourWillUpdatedQuestionIsOpen(true)}
                    >
                        <FrontInputLabelAndErrorWrapComponent label={<WeightMedium>{props.isYourWillUpdatedQuestionLabel}</WeightMedium>}>
                            <FrontRadioList
                                value={isYourWillUpdatedQuestion}
                                options={[
                                    {
                                        value: true,
                                        label: "Yes",
                                    },
                                    {
                                        value: false,
                                        label: "No",
                                    },
                                ]}
                                onChange={onChangeIsYourWillUpdated}
                            />
                        </FrontInputLabelAndErrorWrapComponent>
                    </FrontCondensedQuestionWrap>
                }

                <SpacingColumn spacing={FrontSpacing.MEDIUM_2}>
                    {/* WE NEED TO TALK NOTICE */}
                    {isRecommendedToHaveWillUpdated &&
                        <FrontHelpBubble
                            type="info"
                            label="We need to talk!"
                            paragraph={<SpacingColumn spacing={FrontSpacing.SMALL_2}>
                                <WeightMedium>
                                    {doYouHaveAWillQuestion &&
                                        <>Your current will may no longer be valid.</>
                                    }

                                    {!doYouHaveAWillQuestion &&
                                        <>You should have a will.</>
                                    }
                                </WeightMedium>
                                
                                <WeightSemiBold>
                                    {doYouHaveAWillQuestion &&
                                        <>As part of this {TransactionType1_TransactionType4(props.transactionType).toLowerCase()} you may <WeightBold>need</WeightBold> to have your will updated.</>
                                    }

                                    {!doYouHaveAWillQuestion &&
                                        <>As part of this {TransactionType1_TransactionType4(props.transactionType).toLowerCase()} you may <WeightBold>need</WeightBold> to get a will.</>
                                    }
                                </WeightSemiBold>
                            </SpacingColumn>}
                        />
                    }

                    {/* I WANT TO BE REFERRED QUESTION */}
                    <FrontCondensedQuestionWrap
                        isOpen={iWantToBeReferredQuestionIsOpen}
                        label="I want to discuss this"
                        summary={props.iWantToBeReferredQuestion ? "Yes" : "No"}
                        setIsOpen={() => setIWantToBeReferredQuestionIsOpen(true)}
                    >
                        <FrontInputLabelAndErrorWrapComponent label={<WeightMedium>Are you happy for Sail Wills to get in touch to discuss your will? (this consultation is <WeightBold>free</WeightBold>)</WeightMedium>}>
                            <FrontRadioList
                                value={props.iWantToBeReferredQuestion}
                                options={[
                                    {
                                        value: true,
                                        label: "Yes",
                                    },
                                    {
                                        value: false,
                                        label: "No",
                                    },
                                ]}
                                onChange={onChangeIWantToBeReferred}
                            />
                        </FrontInputLabelAndErrorWrapComponent>
                    </FrontCondensedQuestionWrap>
                </SpacingColumn>
            </SpacingColumn>

            {/* FOOTER */}
            <SpacingColumn spacing={FrontSpacing.MEDIUM_1}>
                {/* CONTROL */}
                <MediaCompSwitch
                    DesktopComponent={SpacingRow}
                    desktopProps={{ spacing: FrontSpacing.SMALL_3}}
                    MobileComponent={SpacingColumn}
                    mobileProps={{ spacing: FrontSpacing.SMALL_3 }}
                    breakpoint="600"
                >
                    <FrontFormPrimaryButton
                        label="Continue"
                        icon="done"
                        isDisabled={false}
                        onClick={() =>
                            isFormReadyToSubmit && props.iWantToBeReferredQuestion ? setSubmitPopupIsOpen(true)
                            : isFormReadyToSubmit && isRecommendedToHaveWillUpdated && !props.iWantToBeReferredQuestion ? setBeforeYouGoPopupIsOpen(true)
                            : isFormReadyToSubmit && !isRecommendedToHaveWillUpdated && !props.iWantToBeReferredQuestion ? props.onSubmit(false)
                            : setDisplayValidationErrorsIfPresent(true)
                        }
                    />
                </MediaCompSwitch>

                {/* ERRORS */}
                {displayValidationErrorsIfPresent && !isFormReadyToSubmit && <FrontInfoBubble color={FrontColors.ERROR_FIREBRICK_22}>
                    <WeightBold>You've missed a few things above,</WeightBold> please take a look and then try again.
                </FrontInfoBubble>}
            </SpacingColumn>
        </SpacingColumn>
        
        {/* SUBMIT POPUP */}
        <FrontPopupPrompt
            isOpen={submitPopupIsOpen}
            title="We'll call you"
            ctaText="Ok"
            onCTA={() => props.onSubmit(true)}
        >
            <SpacingColumn spacing={FrontSpacing.SMALL_3}>
                <FrontParagraphRegular>
                    <WeightBold>Sail Wills will give you a call over the next few days to discuss this with you.</WeightBold>
                </FrontParagraphRegular>
                <FrontParagraphRegular>
                    If you need to call them now you can do so on 0808 196 7498.
                </FrontParagraphRegular>
            </SpacingColumn>
        </FrontPopupPrompt>

        {/* BEFORE YOU GO POPUP */}
        <FrontPopupPrompt
            isOpen={beforeYouGoPopupIsOpen}
            title="Before you go"
            ctaText="I've changed my mind"
            onCTA={submitWithReferral}
            closeText="Skip anyway"
            onClose={() => props.onSubmit(false)}
        >
            {props.beforeYouGoContent}
        </FrontPopupPrompt>
    </>;
}

export const getWillReferralStatus = (props: TContainerStateProps): TOnboardingTabStatus => {
    if (props.state.forms.client_case_page.children.will_referral?.is_step_complete) {
        return "completed";
    }
    
    return "active";
};

export default FrontWillReferralContainer;

