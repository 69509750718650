import React from "react";
// import { TResultGroup } from "../../../hooks/useEmailInput";
import { CRMFontSizes } from "../../../models/CRMFontSizes";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { CRMColors } from "../../../models/CRMColors";
import { Border } from "../../BuildingBlocks/Border";
import { FontQuicksand } from "../../BuildingBlocks/FontQuicksand";
import { FontSize } from "../../BuildingBlocks/FontSize";
import { Padding } from "../../BuildingBlocks/Padding";
import { SimpleGrid } from "../../BuildingBlocks/SimpleGrid";
import { SpacingColumn } from "../../BuildingBlocks/SpacingColumn";
import { TextColor } from "../../BuildingBlocks/TextColor";
import { WordBreak } from "../../BuildingBlocks/WordBreak";
import { WeightSemiBold } from "../../WeightSemiBold/WeightSemiBold";
import { TResultGroup, TRichFilterOption } from "../../../hooks/useRichFilterDropdown";
import { IFuzzySortOption } from "../../../hooks/useFuzzysort";
import { Text } from "../../BuildingBlocks/Text";

export type TDropdownGroupOptionProps<T extends TRichFilterOption> = {
    searchText: string;
    option: Fuzzysort.KeyResult<IFuzzySortOption<string, T>>;
    onClick: (value: string) => void;
}

type TDropdownGroupProps<T extends TRichFilterOption> = {
    searchText: string;
    group: TResultGroup<T>;
    onToggleOptionSelection: (email: string) => void;
    OptionElement: (props: TDropdownGroupOptionProps<T>) => JSX.Element;
};

export const CRMDropdownGroup = <T extends TRichFilterOption>(props: React.PropsWithChildren<TDropdownGroupProps<T>>): JSX.Element => {

    return (
        <SpacingColumn spacing={CRMSpacing.MEDIUM}>
            
            <GroupTitle>
                {props.group.name}
            </GroupTitle>

            {/* OPTIONS */}
            <SimpleGrid
                columnSpacing={CRMSpacing.SMALL}
                rowSpacing={CRMSpacing.MEDIUM}
                templateColumns="1fr 1fr"
            >
                {props.group.options.map((option, optionKey) => (
                    <props.OptionElement 
                        key={optionKey}
                        option={option}
                        searchText={props.searchText}
                        onClick={props.onToggleOptionSelection}
                    />
                ))}
            </SimpleGrid>
        </SpacingColumn>
    );
};


const GroupTitle = (props: React.PropsWithChildren<{}>): JSX.Element => {
    return (
        <Border type="bottom" definition={`1px solid ${CRMColors.NEUTRAL_6}`}>
            <Padding type="bottom" spacing={CRMSpacing.TINY}>
                <FontQuicksand>
                    <Text size={CRMFontSizes.SMALL} weight={700}>
                        {props.children}
                    </Text>
                </FontQuicksand>
            </Padding>
        </Border>
    );
}

export const CRMDropdownGroupOption = (props: React.PropsWithChildren<{ onClick: () => void }>): JSX.Element => {
    return (
        <div
            className="crm-dropdown-group__option"
            onClick={props.onClick} 
        >
            {props.children}
        </div>
    );
}

export const CRMDropdownGroupOptionText = (props: React.PropsWithChildren<{}>): JSX.Element => {
    return (
        <FontQuicksand>
            <Text
                size={13}
                lineHeight={1.4}
                color={CRMColors.NEUTRAL_INK}
                weight={600}
            >
                <WordBreak>
                    {props.children}
                </WordBreak>
            </Text>
        </FontQuicksand>
    );
}
