import React from "react";

interface ILabelWrapForInputs {
    required?: boolean;
    label?: string;
    guidance?: string;
    errorMessage?: string;
}

export class LabelWrapForInputs extends React.Component<React.PropsWithChildren<ILabelWrapForInputs>> {
    public render (): JSX.Element {
        return (
            <div className="labelWrapForInputs">
                {this.props.label ? (
                    <div className={`
                        labelWrapForInputs__label
                        labelWrapForInputs__label--${this.props.required ? 'required' : 'no-required'}
                    `}>
                        {this.props.label}
                    </div>
                ) : <></>}
                {this.props.guidance && <div className="labelWrapForInputs__guidance">{this.props.guidance}</div>}
                {this.props.errorMessage && <div className="labelWrapForInputs__errorMessage">{this.props.errorMessage}</div>}
                <div className="labelWrapForInputs__body">
                    {this.props.children}
                </div>
            </div>
        );
    }
}

export default LabelWrapForInputs;
