import React from "react";
import { FrontFontSizes } from "../../../../models/FrontFontSizes";
import { FrontSpacing } from "../../../../models/FrontSpacing";
import { FontQuicksand } from "../../../BuildingBlocks/FontQuicksand";
import { FontSize } from "../../../BuildingBlocks/FontSize";
import { SpacingRow } from "../../../BuildingBlocks/SpacingRow";
import { FrontIcon } from "../FrontIcon/FrontIcon";

export type TRadioValueType = string | number | boolean;

export interface IFrontRadioProps <A extends TRadioValueType>{
    label: JSX.Element | string;
    value: A;
    isSelected: boolean;
    displayError?: boolean;
    disabled?: boolean;
    onChange: (value: A) => void;
};

export const FrontRadio = <A extends TRadioValueType>(props: React.PropsWithChildren<IFrontRadioProps<A>>): JSX.Element => {

    return (
        <SpacingRow
            spacing={FrontSpacing.SMALL_2}
            onClick={() => {
                if (!props.disabled) {
                    props.onChange(props.value);
                }
            }}
        >
            <FrontIcon
                iconName={props.isSelected ? "radio-selected" : "radio-unselected"}
                colour={
                    props.displayError
                        ? "highlights-error-firebrick-10"
                        : "neutral-ink"
                }
                size="small"
            />

            <FontQuicksand>
                <FontSize size={FrontFontSizes.MINOR}>
                    {props.label}
                </FontSize>
            </FontQuicksand>
        </SpacingRow>
    );
};
