import React from "react";
import * as luxon from "luxon";
import { CRMInlineOptionsComponent } from "../../../CRMInlineOptionsComponent/CRMInlineOptionsComponent";
import { CRMSpacingRow } from "../../../CRMSpacingRow/CRMSpacingRow";
import { CRMIcon } from "../../../CRMIcon/CRMIcon";
import { CRMParagraph } from "../../../Simple/CRMParagraph/CRMParagraph";
import { WeightMedium } from "../../../WeightMedium/WeightMedium";
import { CRMSpacer } from "../../../CRMSpacer/CRMSpacer";
import * as TCaseEnquiryTimeline1ReadOnlyForm from "../../../../models/TCaseEnquiryTimeline1ReadOnlyForm";
import * as CaseEnquiryTimeline1 from "../../../../../../domain/models/CaseEnquiryTimeline1";
import { CRMCardOutsidePopupFormSubmit } from "../../../CRMCardOutsidePopupFormSubmit/CRMCardOutsidePopupFormSubmit";

type TEnquiryNoteProps = {
    timelineFormNoteVariant: TCaseEnquiryTimeline1ReadOnlyForm.T<CaseEnquiryTimeline1.TCaseEnquiryNote5>;
    onFormChange: (value: TCaseEnquiryTimeline1ReadOnlyForm.T<CaseEnquiryTimeline1.TCaseEnquiryNote5>) => void;
    onDelete: () => void;
};

export const CRMCaseEnquiryAttachmentNote = (props: TEnquiryNoteProps): JSX.Element =>
    (
        <div>
            <div className={`crm-case-enquiry-attachment-note`}>
                {/* TITLE */}
                <CRMSpacingRow>
                    <div className="crm-case-enquiry-attachment-note__title">
                        {luxon.DateTime.fromISO(props.timelineFormNoteVariant.view.payload.date).setZone("Europe/London").toFormat("dd/MM/yyyy")}
                    </div>
                    <CRMInlineOptionsComponent
                        iconSize="small"
                        options={[
                            {
                                label: "Delete",
                                onClick: () => props.onFormChange({
                                    ...props.timelineFormNoteVariant,
                                    ui: {
                                        ...props.timelineFormNoteVariant.ui,
                                        isDeleteModalOpen: true,
                                    },
                                }),
                            },
                        ]}
                    />
                </CRMSpacingRow>
                <CRMSpacer size="tiny" />

                {/* SUBJECT */}
                <CRMSpacingRow>
                    <CRMIcon
                        iconName="chat-bubble-outbound"
                        colour="neutral-ink"
                        size="tiny"
                    />
                    <CRMParagraph>
                        <WeightMedium>
                            Note
                        </WeightMedium>
                    </CRMParagraph>
                </CRMSpacingRow>

                {/* MESSAGE */}
                <CRMSpacer size="tiny" />
                <CRMParagraph>
                    {props.timelineFormNoteVariant.view.payload.message}
                </CRMParagraph>
            </div>

            {/* Delete modal */}
            <CRMCardOutsidePopupFormSubmit
                formStatus={props.timelineFormNoteVariant.status}
                validationErrors={props.timelineFormNoteVariant.validationErrors}
                isOpen={props.timelineFormNoteVariant.ui.isDeleteModalOpen}
                title="Delete note"
                context="warning"
                closeText="Cancel"
                ctaText="Yes, delete"
                onClose={() => props.onFormChange({
                    ...props.timelineFormNoteVariant,
                    ui: {
                        ...props.timelineFormNoteVariant.ui,
                        isDeleteModalOpen: false,
                    },
                })}
                onCTA={props.onDelete}
            >
                <CRMParagraph>
                    Are you sure you want to delete this note?
                </CRMParagraph>
            </CRMCardOutsidePopupFormSubmit>
        </div>
    );
