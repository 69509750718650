import React from "react";
import { TContainerStateProps } from "./../../state/TContainerStateProps";
import { CRMTitleSection } from "../../components/CRMTitleSection/CRMTitleSection";
import { CRMSpacer } from "../../components/CRMSpacer/CRMSpacer";
import { CRMAutosaveIndicatorWrap } from "../../components/CRM/CRMAutosaveIndicatorWrap/CRMAutosaveIndicatorWrap";
import { CaseExpectedExchange, CaseExchange, CaseExpectedCompletion, CaseCompletion } from "../../../../domain/codecs/Cases";
import { CRMCodecEditForm } from "../../components/CRMCodecEditForm/CRMCodecEditForm";
import { CRMSpacingColumn } from "../../components/CRMSpacingColumn/CRMSpacingColumn";
import { CRMCaseSignOffAsReadyForExchangeComponent } from "../../components/CRMCaseSignOffAsReadyForExchangeComponent/CRMCaseSignOffAsReadyForExchangeComponent";
import { requireExhaustive } from "../../../../shared/src/util";
import CRMInputLabelAndErrorWrapComponent from "../../components/CRMInputLabelAndErrorWrapComponent/CRMInputLabelAndErrorWrapComponent";
import { CRMRadioListComponent } from "../../components/CRMInputs/CRMRadioListComponent/CRMRadioListComponent";
import { DateTime } from "luxon";
import { CRMCaseRollbackComponent } from "../../components/CRMCaseRollbackComponent/CRMCaseRollbackComponent";

const CRMLegalCaseDetailsExchangeAndCompleteContainer = (props: TContainerStateProps): JSX.Element => {
    const isAdmin = props.state.global.user.user_role === "admin";
    
    const isAuthoriser = props.state.global.user.user_role === "sail_legal_authoriser_staff";

    const formStatus = props.state.forms.case_details_page.data.output.details.status;

    return (
        <CRMAutosaveIndicatorWrap
            status={props.state.forms.case_details_page.data.output.details.status}
        >
            <CRMTitleSection>
                Exchange &amp; Complete
            </CRMTitleSection>
            <CRMSpacer size="large" />
            <CRMSpacingColumn spacing="medium">
                {props.state.forms.case_details_page.data.output.details.children.status === "instructed" &&
                    <CRMCaseSignOffAsReadyForExchangeComponent
                        form={props.state.forms.case_details_page.data.output.details.children.sign_off_as_ready_for_exchange}
                        transactionType={props.state.forms.case_details_page.data.output.details.children.transaction_type}
                        onSignOff={() => props.dispatch({
                            type: "LEGAL_CASE_SIGN_OFF_AS_READY_FOR_EXCHANGE",
                            payload: props.state.forms.case_details_page.data.output.details.children.sign_off_as_ready_for_exchange,
                        })}
                        onCancel={() => props.dispatch({
                            type: "LEGAL_CASE_CANCEL_SIGN_OFF_AS_READY_FOR_EXCHANGE",
                        })}
                    />
                }

                {
                    (isAdmin || isAuthoriser)
                    && props.state.forms.case_details_page.data.output.details.children.status === "ready_for_exchange"
                    && <CRMCaseRollbackComponent
                        rollbackTo="pre_ready_for_exchange"
                        formStatus={formStatus}
                        onYes={() => {
                            props.dispatch({
                                type: "LEGAL_CASE_DETAILS_CHANGE",
                                payload: {
                                    ...props.state.forms.case_details_page.data.output.details,
                                    edited: {
                                        ...props.state.forms.case_details_page.data.output.details.edited,
                                        is_signed_off_by_authoriser_as_ready_for_exchange: null,
                                        signed_off_by_authoriser_as_ready_for_exchange_user: null,
                                    }
                                }
                            });
                        }} />
                }

                {/* CLIENTS HAVE AUTHORISED EXCHANGE & COMPLETE */}
                <CRMInputLabelAndErrorWrapComponent label="Have clients authorised exchange & complete?">
                    <CRMRadioListComponent<boolean, boolean>
                        value={props.state.forms.case_details_page.data.output.details.edited.clients_authorise_exchange_and_complete ? true : false}
                        direction="row"
                        options={[
                            {
                                value: true,
                                text: "Yes",
                            },
                            {
                                value: false,
                                text: "No",
                            },
                        ]}
                        onChange={(value) => props.dispatch({
                            type: "LEGAL_CASE_DETAILS_CHANGE",
                            payload: {
                                ...props.state.forms.case_details_page.data.output.details,
                                edited: {
                                    ...props.state.forms.case_details_page.data.output.details.edited,
                                    clients_authorise_exchange_and_complete: value ? DateTime.utc().toISO() : null,
                                }
                            }
                        })}
                    />
                </CRMInputLabelAndErrorWrapComponent>

                <CRMCodecEditForm
                    codec={CaseExpectedExchange}
                    model={props.state.forms.case_details_page.data.output.details.edited}
                    validationErrors={props.state.forms.case_details_page.data.output.details.validationErrors}
                    columns={1}
                    onChange={(edited) => {
                        props.dispatch({
                            type: "LEGAL_CASE_DETAILS_CHANGE",
                            payload: {
                                ...props.state.forms.case_details_page.data.output.details,
                                edited: {
                                    ...props.state.forms.case_details_page.data.output.details.edited,
                                    ...edited,
                                }
                            }
                        })
                    }}
                    showDeferDateLabels={true}
                />

                {props.state.forms.case_details_page.data.output.details.children.status === "property_report"
                    || props.state.forms.case_details_page.data.output.details.children.status === "quote"
                    || props.state.forms.case_details_page.data.output.details.children.status === "instructed"
                        ? <></>
                    : props.state.forms.case_details_page.data.output.details.children.status === "ready_for_exchange"
                    || props.state.forms.case_details_page.data.output.details.children.status === "exchanged"
                    || props.state.forms.case_details_page.data.output.details.children.status === "completed"
                    || props.state.forms.case_details_page.data.output.details.children.status === "closed_created_in_error"
                    || props.state.forms.case_details_page.data.output.details.children.status === "closed_successfully"
                    || props.state.forms.case_details_page.data.output.details.children.status === "closed_unsuccessfully"
                    || props.state.forms.case_details_page.data.output.details.children.status === "closed_awaiting_opening_new_case"
                        ? <CRMCodecEditForm
                            codec={CaseExchange}
                            model={props.state.forms.case_details_page.data.output.details.edited}
                            validationErrors={props.state.forms.case_details_page.data.output.details.validationErrors}
                            columns={1}
                            onChange={(edited) => {
                                props.dispatch({
                                    type: "LEGAL_CASE_DETAILS_CHANGE",
                                    payload: {
                                        ...props.state.forms.case_details_page.data.output.details,
                                        edited: {
                                            ...props.state.forms.case_details_page.data.output.details.edited,
                                            ...edited,
                                        }
                                    }
                                })
                            }}
                            showDeferDateLabels={true}
                        />
                    : requireExhaustive(props.state.forms.case_details_page.data.output.details.children.status)}

                <CRMCodecEditForm
                    codec={CaseExpectedCompletion}
                    model={props.state.forms.case_details_page.data.output.details.edited}
                    validationErrors={props.state.forms.case_details_page.data.output.details.validationErrors}
                    columns={1}
                    onChange={(edited) => {
                        props.dispatch({
                            type: "LEGAL_CASE_DETAILS_CHANGE",
                            payload: {
                                ...props.state.forms.case_details_page.data.output.details,
                                edited: {
                                    ...props.state.forms.case_details_page.data.output.details.edited,
                                    ...edited,
                                }
                            }
                        })
                    }}
                    showDeferDateLabels={true}
                />

                {props.state.forms.case_details_page.data.output.details.children.status === "property_report"
                    || props.state.forms.case_details_page.data.output.details.children.status === "quote"
                    || props.state.forms.case_details_page.data.output.details.children.status === "instructed"
                        ? <></>
                    : props.state.forms.case_details_page.data.output.details.children.status === "ready_for_exchange"
                    || props.state.forms.case_details_page.data.output.details.children.status === "exchanged"
                    || props.state.forms.case_details_page.data.output.details.children.status === "completed"
                    || props.state.forms.case_details_page.data.output.details.children.status === "closed_created_in_error"
                    || props.state.forms.case_details_page.data.output.details.children.status === "closed_successfully"
                    || props.state.forms.case_details_page.data.output.details.children.status === "closed_unsuccessfully"
                    || props.state.forms.case_details_page.data.output.details.children.status === "closed_awaiting_opening_new_case"
                        ? <CRMCodecEditForm
                            codec={CaseCompletion}
                            model={props.state.forms.case_details_page.data.output.details.edited}
                            validationErrors={props.state.forms.case_details_page.data.output.details.validationErrors}
                            columns={1}
                            onChange={(edited) => {
                                props.dispatch({
                                    type: "LEGAL_CASE_DETAILS_CHANGE",
                                    payload: {
                                        ...props.state.forms.case_details_page.data.output.details,
                                        edited: {
                                            ...props.state.forms.case_details_page.data.output.details.edited,
                                            ...edited,
                                        }
                                    }
                                })
                            }}
                            showDeferDateLabels={true}
                        />
                    : requireExhaustive(props.state.forms.case_details_page.data.output.details.children.status)}
            </CRMSpacingColumn>
        </CRMAutosaveIndicatorWrap>
    );
};

export default CRMLegalCaseDetailsExchangeAndCompleteContainer;