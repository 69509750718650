import React from "react";
import { FrontSpacing } from "../../../../models/FrontSpacing";
import { SpacingColumn } from "../../../BuildingBlocks/SpacingColumn";
import { FrontFormRow } from "../../Simple/FrontFormRow/FrontFormRow";
import FrontInputGeneralComponent from "../../Simple/FrontInputGeneralComponent/FrontInputGeneralComponent";
import FrontInputLabelAndErrorWrapComponent from "../../Simple/FrontInputLabelAndErrorWrapComponent/FrontInputLabelAndErrorWrapComponent";
import { TClientCaseCompanyForm, TClientCaseUserForm } from "../../../../../../domain/codecs/form/ClientCaseForm";
import { onChangeForm } from "../../../../../../shared/src/codecs/types/form";
import { FrontRadioList } from "../../Simple/FrontRadioList/FrontRadioList";
import { FrontOnboardingFormParagraphs } from "../../Simple/FrontOnboardingFormParagraphs/FrontOnboardingFormParagraphs";
import { WeightBold } from "../../../WeightBold/WeightBold";
import { FrontCondensedForm } from "../FrontCondensedForm/FrontCondensedForm";
import { isFilled } from "../../../../../../shared/src/util";
import { WeightMedium } from "../../../WeightMedium/WeightMedium";
import { TClientCaseUser } from "../../../../../../domain/codecs/User";
import { doesErrorCodeExist, doesErrorKeyExist } from "../../../../../../shared/src/codecs/errors";
import { FrontInfoBubble } from "../../Simple/FrontInfoBubble/FrontInfoBubble";
import { FrontColors } from "../../../../models/FrontColors";
import { pipe } from "fp-ts/lib/function";
import { array } from "fp-ts";
import { TTransactionType1 } from "../../../../../../domain/codecs/TransactionType";


type TClientDetailsFormProps = {
    form: TClientCaseUserForm;
    isEditable: boolean;
    companyForm?: TClientCaseCompanyForm;
    canDelete?: boolean;
    transactionType: TTransactionType1;
    displayValidationErrorsIfPresent?: boolean;
    onChange: (form: TClientCaseUserForm) => void;
    onDelete: (form: TClientCaseUserForm) => void;
};

export const FrontClientDetailsForm = (props: React.PropsWithChildren<TClientDetailsFormProps>): JSX.Element => {
    const areAllEditedDetailsFilledIn = (): boolean =>
        isFilled(props.form.edited.first_name) &&
        isFilled(props.form.edited.last_name) &&
        (
            props.form.edited.user_type === "deceased"
            || props.form.edited.ever_made_bankrupt !== "unknown"
        )
    ;

    const canShowIsADirectorField = (): boolean => (
        !!props.companyForm
        && props.companyForm.edited.is_transacting_as_company === "yes"
        && props.companyForm.edited.company_name.length > 0
    );
    
    const onChange = onChangeForm(props.form, props.onChange);

    const hasEmailValidationError = () => doesErrorKeyExist(`edited.email`, props.form.validationErrors);

    const hasInUseEmailValidationError = () => doesErrorCodeExist("InUse", props.form.validationErrors)

    const hasPhoneValidationError = () => doesErrorKeyExist(`edited.phone_number`, props.form.validationErrors);

    return (
        <FrontCondensedForm
            isEditable={props.isEditable}
            canSubmit={areAllEditedDetailsFilledIn()}
            canDelete={props.canDelete}
            form={props.form}
            deletePopupText={
                <FrontOnboardingFormParagraphs
                    paragraphs={[
                        <>Are you sure you want to <WeightBold>delete this person's details?</WeightBold></>,
                        <>If you change your mind later on, you can always add them back in.</>
                    ]}
                />
            }
            formSummary={[
                { 
                    label: "Name", 
                    text: <WeightMedium>{props.form.edited.first_name} {props.form.edited.last_name} {props.form.edited.is_company_director ? "(director)" : ""}</WeightMedium>
                },
                {
                    canShow: isFilled(props.form.edited.email) && props.form.edited.user_type !== "deceased",
                    label: "Email",
                    text: <WeightMedium><div style={{ wordBreak: "break-word" }}>{props.form.edited.email}</div></WeightMedium>
                },
                { 
                    canShow: isFilled(props.form.edited.phone_number) && props.form.edited.user_type !== "deceased",
                    label: "Phone Number",
                    text: <WeightMedium>{props.form.edited.phone_number}</WeightMedium>
                },
                {
                    canShow: isFilled(props.form.edited.national_insurance_number) && props.form.edited.user_type !== "deceased",
                    label: "NI Number",
                    text: <WeightMedium>{props.form.edited.national_insurance_number}</WeightMedium>
                },
                {
                    canShow: props.form.edited.user_type !== "deceased",
                    text: <WeightBold>{props.form.edited.ever_made_bankrupt === "yes" ? "Has been made bankrupt in the past" : "Has never been made bankrupt"}</WeightBold>
                },
                {
                    canShow: props.form.edited.user_type === "deceased",
                    text: <>Deceased</>,
                }
            ]}
            onChange={props.onChange}
            onDelete={props.onDelete}
            deleteLabelText="Delete this person"
        >
            <SpacingColumn spacing={FrontSpacing.MEDIUM_1}>
                {
                    pipe(
                        [
                            // FIRST NAME
                            <FrontInputLabelAndErrorWrapComponent label="First name" errorMessage="A first name is required" displayError={props.form.edited.first_name === "" && props.displayValidationErrorsIfPresent ? true : false}>
                                <FrontInputGeneralComponent
                                    inputType="text"
                                    value={props.form.edited.first_name}
                                    displayError={props.form.edited.first_name === "" && props.displayValidationErrorsIfPresent ? true : false}
                                    onChange={onChange("first_name")}
                                />
                            </FrontInputLabelAndErrorWrapComponent>,
                            // LAST NAME
                            <FrontInputLabelAndErrorWrapComponent label="Last name" errorMessage="A last name is required" displayError={props.form.edited.last_name === "" && props.displayValidationErrorsIfPresent ? true : false}>
                                <FrontInputGeneralComponent
                                    inputType="text"
                                    value={props.form.edited.last_name}
                                    displayError={props.form.edited.last_name === "" && props.displayValidationErrorsIfPresent ? true : false}
                                    onChange={onChange("last_name")}
                                />
                            </FrontInputLabelAndErrorWrapComponent>,
                            ...(props.form.edited.user_type !== "deceased" ? [
                                // EMAIL
                                <FrontInputLabelAndErrorWrapComponent 
                                    label="Email"
                                    displayError={hasEmailValidationError()}
                                    errorMessage={hasInUseEmailValidationError() ? "This email has been saved as belonging to another person, each person should have a unique email." : "This email is not correct"}
                                >
                                    <FrontInputGeneralComponent
                                        inputType="email"
                                        value={props.form.edited.email || ""}
                                        displayError={hasEmailValidationError()}
                                        onChange={(value) => onChange("email")(value || null)}
                                    />
                                </FrontInputLabelAndErrorWrapComponent>,
                                // PHONE NUMBER
                                <FrontInputLabelAndErrorWrapComponent 
                                    label="Phone number"
                                    displayError={hasPhoneValidationError()}
                                    errorMessage="This phone number is not correct (check it doesn't contain spaces)"
                                >
                                    <FrontInputGeneralComponent
                                        inputType="tel"
                                        value={props.form.edited.phone_number || ""}
                                        displayError={hasPhoneValidationError()}
                                        onChange={(value) => onChange("phone_number")(value || null)}
                                    />
                                </FrontInputLabelAndErrorWrapComponent>
                            ] : []),
                            ...(props.transactionType === "sale" ? [
                                <div>
                                    {/* DECEASED? */}
                                    <FrontInputLabelAndErrorWrapComponent label={props.form.edited.first_name ? `Is ${props.form.edited.first_name} deceased?` : "Are they deceased?"}>
                                        <FrontRadioList
                                            value={props.form.edited.user_type === "deceased" ? "deceased" : "client"}
                                            options={[
                                                {
                                                    label: "Yes",
                                                    value: "deceased",
                                                },
                                                {
                                                    label: "No",
                                                    value: "client",
                                                },
                                            ]}
                                            onChange={onChange("user_type")}
                                        />
                                    </FrontInputLabelAndErrorWrapComponent>
                                    <FrontInfoBubble color={FrontColors.PRIMARY_22}>
                                        We're sorry we had to ask but it's vital we understand who all the owners were when the property was bought.
                                    </FrontInfoBubble>
                                </div>
                            ] : []),
                            ...(props.form.edited.user_type !== "deceased" ? [
                                // EVER MADE BANKRUPT?
                                <FrontInputLabelAndErrorWrapComponent label={props.form.edited.first_name ? `Has ${props.form.edited.first_name} ever been made bankrupt?` : "Have they ever been made bankrupt?"} errorMessage={`We need to know if ${props.form.edited.first_name ? `${props.form.edited.first_name} has` : "they have"} ever been made bankrupt`} displayError={props.form.edited.ever_made_bankrupt === "unknown" && props.displayValidationErrorsIfPresent ? true : false}>
                                    <FrontRadioList
                                        value={props.form.edited.ever_made_bankrupt === "unknown" ? null : props.form.edited.ever_made_bankrupt}
                                        options={[
                                            {
                                                label: "Yes",
                                                value: "yes",
                                            },
                                            {
                                                label: "No",
                                                value: "no",
                                            },
                                        ]}
                                        onChange={onChange("ever_made_bankrupt")}
                                    />
                                </FrontInputLabelAndErrorWrapComponent>,
                                // NI NUMBER
                                <FrontInputLabelAndErrorWrapComponent label="National insurance number">
                                    <FrontInputGeneralComponent
                                        inputType="text"
                                        value={props.form.edited.national_insurance_number || ""}
                                        displayError={false}
                                        onChange={onChange("national_insurance_number")}
                                    />
                                </FrontInputLabelAndErrorWrapComponent>
                            ] : []),
                            // COMPANY DIRECTOR?
                            ...(props.form.edited.user_type !== "deceased" && !!props.companyForm && canShowIsADirectorField() ? [
                                <FrontInputLabelAndErrorWrapComponent label={`Is ${props.form.edited.first_name ? `${props.form.edited.first_name} ` : ""}a director of ${props.companyForm.edited.company_name}`}>
                                    <FrontRadioList
                                        value={props.form.edited.is_company_director}
                                        options={[
                                            {
                                                label: "Yes",
                                                value: true,
                                            },
                                            {
                                                label: "No",
                                                value: false,
                                            },
                                        ]}
                                        onChange={onChange("is_company_director")}
                                    />
                                </FrontInputLabelAndErrorWrapComponent>
                            ] : []),
                        ],
                        array.chunksOf(2),
                        array.map((elements) => <FrontFormRow>
                            {elements}
                        </FrontFormRow>)
                    )
                }
            </SpacingColumn>
        </FrontCondensedForm>
    );
};

export const areAllDetailsFilledIn = (form: TClientCaseUserForm): boolean => {
    return areFormDetailsFilledIn(form.edited) && areFormDetailsFilledIn(form.original);
}

const areFormDetailsFilledIn = (details: TClientCaseUser): boolean =>
    isFilled(details.first_name) &&
    isFilled(details.last_name) &&
    (
        details.user_type === "deceased"
        || details.ever_made_bankrupt !== "unknown"
    )
;
