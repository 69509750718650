import React from "react";
import { FrontColors } from "../../../../models/FrontColors";
import { FrontFontSizes } from "../../../../models/FrontFontSizes";
import { FrontSpacing } from "../../../../models/FrontSpacing";
import { FontQuicksand } from "../../../BuildingBlocks/FontQuicksand";
import { FontSize } from "../../../BuildingBlocks/FontSize";
import { SpacingColumn } from "../../../BuildingBlocks/SpacingColumn";
import { TextColor } from "../../../BuildingBlocks/TextColor";
import { Uppercase } from "../../../Uppercase/Uppercase";
import { WeightBold } from "../../../WeightBold/WeightBold";
import { FrontInfoBubble } from "../../Simple/FrontInfoBubble/FrontInfoBubble";
import { TOnboardingTabStatus } from "../../Simple/FrontOnboardingTab/FrontOnboardingTab";
import { TransactionType1_TransactionType4, TTransactionType1 } from "../../../../../../domain/codecs/TransactionType";
import { FrontOnboardingCaseOverviewExplanation } from "../../Simple/FrontOnboardingCaseOverviewExplanation/FrontOnboardingCaseOverviewExplanation";
import { FrontOnboardingCaseOverviewCurrentStep } from "../FrontOnboardingCaseOverviewCurrentStep/FrontOnboardingCaseOverviewCurrentStep";
import { FrontOnboardingCaseOverviewStepsSummarySection } from "../FrontOnboardingCaseOverviewStepsSummarySection/FrontOnboardingCaseOverviewStepsSummarySection";
import { FrontOnboardingCaseOverviewQuoteInfo } from "../../Simple/FrontOnboardingCaseOverviewQuoteInfo/FrontOnboardingCaseOverviewQuoteInfo";
import { TUserCaseUserType } from "../../../../../../domain/codecs/UserCaseUserType";
import { FrontOnboardingCaseDualRepExplanation } from "../FrontOnboardingCaseDualRepExplanation/FrontOnboardingCaseDualRepExplanation";
import { FrontOnboardingCaseTOEExplanation } from "../FrontOnboardingCaseTOEExplanation/FrontOnboardingCaseTOEExplanation";
import { TClientCaseSwitchToRelatedTransaction } from "../../../../../../domain/codecs/form/ClientCaseForm";
import { FrontFormPrimaryButton } from "../../Simple/FrontFormPrimaryButton/FrontFormPrimaryButton";
import { WeightSemiBold } from "../../../WeightSemiBold/WeightSemiBold";

export type TOnboardingCaseOverviewProps<A extends string> = {
    selectedTab?: A;
    transactionType: TTransactionType1;
    currentTabs: Array<TOnboardingCaseOverviewTab<A>>;
    completedTabs: Array<TOnboardingCaseOverviewTab<A>>;
    deferredTabs: Array<TOnboardingCaseOverviewTab<A>>;
    address: string;
    quoteDownloadUrl: string;
    apiUrl: string;
    caseUserType: TUserCaseUserType;
    agreeToToeStatus: TOnboardingTabStatus;
    dualRepConsentStatus: TOnboardingTabStatus;
    caseUserRequiredToConsentToDualRep: boolean;
    switchToRelatedTransaction: TClientCaseSwitchToRelatedTransaction | null;
    onClickAgreeToToeButton: () => void;
    onClickDualRepConsentButton: () => void;
    onChange: (tab: TOnboardingCaseOverviewTab<A>) =>  void;
};

export interface TOnboardingCaseOverviewTab <A extends string>{
    value: A;
    label: string;
    status: TOnboardingTabStatus;
};

export const FrontOnboardingCaseOverview = <A extends string>(props: React.PropsWithChildren<TOnboardingCaseOverviewProps<A>>): JSX.Element => {
    const canSwitchToRelatedTransaction = !!props.switchToRelatedTransaction;

    const onRelatedTransactionSwitch = () =>
        window.location.href = props.switchToRelatedTransaction?.url || "";

    return <SpacingColumn spacing={FrontSpacing.MEDIUM_1}>
        {/* TITLE */}
        {props.agreeToToeStatus === "completed"
            && (!props.caseUserRequiredToConsentToDualRep || props.dualRepConsentStatus === "completed")
            && <FontQuicksand>
                <FontSize size={FrontFontSizes.LARGE}>
                    <TextColor color={FrontColors.NEUTRAL_INK}>
                        <Uppercase>
                            <WeightBold>
                                Case Overview
                            </WeightBold>
                        </Uppercase>
                    </TextColor>
                </FontSize>
            </FontQuicksand>
        }
    

        <SpacingColumn spacing={FrontSpacing.MEDIUM_2}>

            {/* EXPLANATION /AGREE- TERMS OF ENGAGEMENT STEP ONLY*/}
            {props.agreeToToeStatus === "active" &&
                <FrontOnboardingCaseTOEExplanation
                    caseUserType={props.caseUserType}
                    transactionType={props.transactionType}
                    onClick={() => props.onClickAgreeToToeButton()}
                />
            }

            {/* EXPLANATION/CONSENT - DUAL REP STEP ONLY*/}
            {!!props.caseUserRequiredToConsentToDualRep
                && props.agreeToToeStatus === "completed"
                && props.dualRepConsentStatus === "active"
                && <FrontOnboardingCaseDualRepExplanation
                    caseUserType={props.caseUserType}
                    transactionType={props.transactionType}
                    onClick={() => props.onClickDualRepConsentButton()}
                />
            }

            {/* EXPLANATION - ALL OTHER STEPS*/}
            {props.agreeToToeStatus === "completed"
                && (props.dualRepConsentStatus === "completed" || !props.caseUserRequiredToConsentToDualRep)
                && <FrontInfoBubble color={FrontColors.NEUTRAL_18}>
                    <FrontOnboardingCaseOverviewExplanation
                        allStepsAnswered={props.currentTabs.length === 0}
                        anyStepsDeferred={props.deferredTabs.length > 0}
                        transactionType={props.transactionType}
                        sailLegalQuoteFileExists={!!props.quoteDownloadUrl}
                    />
                </FrontInfoBubble>
            }

            {/* RELATED TRANSACTION SWITCH BUTTON */}
            {canSwitchToRelatedTransaction
                && props.agreeToToeStatus === "completed"
                && (props.dualRepConsentStatus === "completed" || !props.caseUserRequiredToConsentToDualRep)
                && <div>
                    <FrontFormPrimaryButton
                        label={<WeightSemiBold>Switch to your <WeightBold>{TransactionType1_TransactionType4(props.switchToRelatedTransaction?.related_transaction_type || "unknown")}</WeightBold></WeightSemiBold>}
                        icon="chevron-right"
                        iconPosition="right"
                        onClick={onRelatedTransactionSwitch}
                    />
                </div>
            }

            {/* TAB SECTION: CURRENT STEP */}
            {props.currentTabs.length 
                && props.agreeToToeStatus === "completed"
                && (props.dualRepConsentStatus === "completed" || !props.caseUserRequiredToConsentToDualRep)
                ? props.currentTabs.map((currentTab) =>
                    <FrontOnboardingCaseOverviewCurrentStep
                        tab={currentTab}
                        isSelected={currentTab.value === props.selectedTab}
                        onClick={props.onChange}
                    />
                )
                : undefined
            }

            {/* TAB SECTION: DEFERRED STEPS */}
            {props.deferredTabs.length > 0 &&
                <FrontOnboardingCaseOverviewStepsSummarySection
                    title={props.currentTabs.length > 0
                        ? "When you know the answers"
                        : "We still need you to:"
                    }
                    tabs={props.deferredTabs}
                    isOpen={props.currentTabs.length === 0}
                    selectedTab={props.selectedTab}
                    onChange={props.onChange}
                />
            }

            {/* TAB SECTION: COMPLETED STEPS */}
            {props.completedTabs.length > 0
                && props.agreeToToeStatus === "completed"
                && (props.dualRepConsentStatus === "completed" || !props.caseUserRequiredToConsentToDualRep)
                && <FrontOnboardingCaseOverviewStepsSummarySection
                    title="Completed Steps"
                    tabs={props.completedTabs}
                    isOpen={props.currentTabs.length === 0 && props.deferredTabs.length === 0}
                    selectedTab={props.selectedTab}
                    onChange={props.onChange}
                />
            }

            {/* SAIL LEGAL QUOTE DOC INFO */}
            {props.quoteDownloadUrl &&
                <FrontInfoBubble color={FrontColors.NEUTRAL_18}>
                    <FrontOnboardingCaseOverviewQuoteInfo
                        quoteDownloadUrl={props.quoteDownloadUrl}
                    />
                </FrontInfoBubble>
            }
        </SpacingColumn>
    </SpacingColumn>;
};
