import React from "react";
import { EditorState, RichUtils } from 'draft-js';
import { CRMIcon, TIcon } from "../CRMIcon/CRMIcon";

type TRichTextAreaButtonProps = {
    title?: string;
    icon: TIcon;
    isEnabled: boolean;
    onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
};

type TTextFormat = "BOLD" | "ITALIC" | "UNDERLINE";
type TBlockFormat = "unordered-list-item" | "ordered-list-item";


export const useRichTextAreaControls = (props: {
    contentState: EditorState;
    onChange: (value: EditorState) => void;
}) => {

    const getCurrentBlockFormat = () => 
        props.contentState
            .getCurrentContent()
            .getBlockForKey(props.contentState.getSelection().getStartKey())
            .getType()
    ;

    const isBlockFormatBeingUsed = (blockFormat: TBlockFormat): boolean => 
        getCurrentBlockFormat() === blockFormat
    ;

    const isTextFormatBeingUsed = (textFormat: TTextFormat) => 
        props.contentState
            .getCurrentInlineStyle()
            .has(textFormat)
    ; 

    const onToggleTextFormat = (textFormat: TTextFormat) => (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        event.preventDefault();
        props.onChange(
            RichUtils.toggleInlineStyle(
                props.contentState, 
                textFormat
            )
        );
    }

    const onToggleBlockFormat = (blockFormat: TBlockFormat) => (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        event.preventDefault();
        props.onChange(RichUtils.toggleBlockType(props.contentState, blockFormat));
    }

    return {
        getCurrentBlockFormat,
        isBlockFormatBeingUsed,
        isTextFormatBeingUsed,
        onToggleTextFormat,
        onToggleBlockFormat
    };
} 

export const CRMRichTextAreaButton = (props: React.PropsWithChildren<TRichTextAreaButtonProps>): JSX.Element => {

    return (
        <div 
            className={`
                crm-rich-text-area__text-control-button
                crm-rich-text-area__text-control-button--${props.isEnabled ? "enabled" : "disabled"}
            `}
            onMouseDown={props.onClick}
            title={props.title}
        >
            <CRMIcon
                iconName={props.icon}
                colour={props.isEnabled ? "neutral-ink" : "neutral-2"}
                size="medium"
            />
        </div>
    );
};


