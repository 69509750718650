import React, { CSSProperties } from "react";
import { AnimationPopout } from "../../BuildingBlocks/AnimationPopout";
import { Background } from "../../BuildingBlocks/Background";
import { CRMColors } from "../../../models/CRMColors";

type TPopoutProps = {
    minWidth?: CSSProperties["minWidth"];
};

export const CRMPopout = (props: React.PropsWithChildren<TPopoutProps>): JSX.Element => {
    return (
        <AnimationPopout minWidth={props.minWidth}>
            <Background
                color={CRMColors.NEUTRAL_PAPER}
                boxShadowDefinition={`1px 2px 4px 0 rgba(0,0,0, 0.2)`}
                borderDefinition={`1px solid ${CRMColors.NEUTRAL_10}`}
                borderRadius="3px"
            >
                <div style={{minWidth: props.minWidth}}>
                    {props.children}
                </div>
            </Background>
        </AnimationPopout>
    );
};
