import React from "react";
import { LabelWrapForInputs } from "../../components/OldFront/Simple/Label/LabelWrapForInputs";
import { FormBody, FormRow } from "../../components/FormTemplateBlocksComponents/FormTemplateBlocks";
import FormButton from "../../components/FormButtonComponent/FormButton";
import { InputTextArea } from "../../components/OldFront/Simple/TextArea/InputTextArea";
import { TParty2AndC5Form } from "./../../models/TFormModels";
import { makeFormUpdateAction } from "../../state/applyActions";
import { AnchorLinkDispatch } from "../AnchorLinkDispatch";
import Heading5 from "../../components/OldFront/Simple/Heading5Component/Heading5";
import { CRMSpacer } from "../../components/CRMSpacer/CRMSpacer";
import { TContainerStateProps } from "../../state/TContainerStateProps";
import { createChangeRouteAction } from "../../state/router/createChangeRouteAction";

class CRMPartyNotes extends React.Component<React.PropsWithChildren<TContainerStateProps>> {
    public render(): JSX.Element {
        const formState = this.props.state.activeData.crm.party.notesForm;
        return (
            <div>
                {/* ENQUIRIES */}
                <Heading5 text="Enquiries" />
                <CRMSpacer size="medium" />
                {this.props.state.activeData.crm.party.enquiryForms.forms.map((enquiry, key) => (
                    <FormRow key={key}>
                        <AnchorLinkDispatch
                            dispatch={this.props.dispatch}
                            action={createChangeRouteAction(
                                "VIEW_CRM_ENQUIRY",
                                {
                                    enquiryId: enquiry.view.id,
                                },
                                {}
                            )}
                            text="Go to party"
                        />
                    </FormRow>
                ))}
                {/* NOTES */}
                <FormBody>
                    <FormRow>
                        <LabelWrapForInputs
                            label="Party Notes"
                        >
                            <InputTextArea
                                value={formState.edit.notes}
                                onChange={(value) => this.props.dispatch(
                                        makeFormUpdateAction<"CRM_PARTY_PARTY_NOTES_CHANGED", TParty2AndC5Form["edit"], "notes">(
                                            "CRM_PARTY_PARTY_NOTES_CHANGED",
                                            "notes",
                                            value
                                        )
                                    )
                                }
                                onPressEnterKey={() => undefined}
                            />
                        </LabelWrapForInputs>
                    </FormRow>
                    {/* BUTTONS */}
                    <FormRow>
                        <FormButton
                            status={formState.status}
                            label="Save notes"
                            type="frog"
                            onClick={() => this.props.dispatch({ type: "CRM_PARTY_NOTES_SUBMIT" }
                            )}
                        />
                    </FormRow>
                </FormBody>
            </div>
        );
    }
}

export default CRMPartyNotes;
