import React, { useState } from "react";
import { CRMDropdownControlledBlock } from "../CRMDropdownControlledBlock/CRMDropdownControlledBlock";
import { ProbateHelplineCallbackForm, TProbateHelplineCallbackForm, TProbateHelplineCallbackMarkCompleteForm, TProbateHelplineCallbackTypeForm, TProbateHelplineCallbackTypeVisible } from "../../../../../domain/codecs/form/ProbateHelplineCallbackForm";
import { CRMProbateHelplineCallbacksNowTable } from "../CRMProbateHelplineCallbacksNowTable/CRMProbateHelplineCallbacksNowTable";
import { CRMProbateHelplineNowSingleView } from "../CRMProbateHelplineNowSingleView/CRMProbateHelplineNowSingleView";
import { pipe } from "fp-ts/lib/function";
import { array, option } from "fp-ts";
import { requireExhaustive } from "../../../../../shared/src/util";
import { CRMProbateHelplineCompletedSingleView } from "../CRMProbateHelplineCompletedSingleView/CRMProbateHelplineCompletedSingleView";
import { CRMProbateHelplineCallbacksCompletedTable } from "../CRMProbateHelplineCallbacksCompletedTable/CRMProbateHelplineCallbacksCompletedTable";
import { CRMBlockScrollCacheView, CRMBlockScrollResetView } from "../../CRM/CRMBlock/CRMBlockViews/CRMBlockViews";

export const CRMProbateHelplineCallbacks = (props: React.PropsWithChildren<{
    nowForm: TProbateHelplineCallbackTypeForm,
    completedForm: TProbateHelplineCallbackTypeForm,
    typeVisible: TProbateHelplineCallbackTypeVisible,
    onChangeTypeVisible: (typeVisible: TProbateHelplineCallbackTypeVisible) => void,
    onCallbackChange: (form: TProbateHelplineCallbackForm) => void,
    onMarkAsComplete: (form: TProbateHelplineCallbackMarkCompleteForm) => void,
    onLoadMore: () => void,
    onSingleViewClose: () => void,
}>): JSX.Element => {
    const [callbackOpen, setCallbackOpen] = useState<[TProbateHelplineCallbackTypeVisible, string] | null>(null);

    const isAnySingleViewOpen = !! callbackOpen;

    const isNowSingleViewOpen =
        callbackOpen
        && callbackOpen[0] === "NOW";

    const isCompletedSingleViewOpen =
        callbackOpen
        && callbackOpen[0] === "COMPLETED";

    const getOpenCallback = () => {
        const callbackOpenOrDefault = callbackOpen || ["NOW", ""];

        return pipe(
            callbackOpenOrDefault[0] === "NOW" ? props.nowForm.children.callbacks
                : callbackOpenOrDefault[0] === "COMPLETED" ? props.completedForm.children.callbacks
                : requireExhaustive(callbackOpenOrDefault[0]),
            array.findFirst(({edited}) => edited.id === callbackOpenOrDefault[1]),
            option.getOrElse(() => ProbateHelplineCallbackForm.newDefault()),
        );
    }

    const onOpenCallback = (form: TProbateHelplineCallbackForm) =>
        setCallbackOpen([props.typeVisible, form.edited.id]);

    const onClose = () => {
        setCallbackOpen(null);
        props.onSingleViewClose();
    };

    const onMarkAsComplete = (form: TProbateHelplineCallbackMarkCompleteForm) => {
        setCallbackOpen(null);
        props.onMarkAsComplete(form);
    }

    return (
        <>
            {/* TABLE VIEW */}
            <CRMBlockScrollCacheView isShowing={!isAnySingleViewOpen}>
                <CRMDropdownControlledBlock
                    menuMode="full_width"
                    sections={[
                        {
                            value: "NOW",
                            label: "Callbacks (Now)",
                            badgeCount: props.nowForm.children.counts.available_count,
                            content: <CRMProbateHelplineCallbacksNowTable
                                form={props.nowForm}
                                onOpenCallback={onOpenCallback}
                                onLoadMore={props.onLoadMore}
                            />
                        },
                        {
                            value: "COMPLETED",
                            label: "Callbacks (Completed)",
                            badgeCount: props.completedForm.children.counts.available_count,
                            content: <CRMProbateHelplineCallbacksCompletedTable
                                form={props.completedForm}
                                onOpenCallback={onOpenCallback}
                                onLoadMore={props.onLoadMore}
                            />
                        },
                    ]}
                    value={props.typeVisible}
                    onValueChange={props.onChangeTypeVisible}
                />
            </CRMBlockScrollCacheView>

            {/* SINGLE VIEW - NOW */}
            <CRMBlockScrollResetView isShowing={isNowSingleViewOpen === true}>
                <CRMProbateHelplineNowSingleView
                    form={getOpenCallback()}
                    onChange={props.onCallbackChange}
                    onMarkAsComplete={onMarkAsComplete}
                    onClose={onClose}
                />
            </CRMBlockScrollResetView>

            {/* SINGLE VIEW - COMPLETED */}
            <CRMBlockScrollResetView isShowing={isCompletedSingleViewOpen === true}>
                <CRMProbateHelplineCompletedSingleView
                    form={getOpenCallback()}
                    onChange={props.onCallbackChange}
                    onClose={onClose}
                />
            </CRMBlockScrollResetView>
        </>
    );
};
