import React, { useState } from "react";
import { CRMColors } from "../../../models/CRMColors";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { BackgroundColour } from "../../BuildingBlocks/BackgroundColour";
import { MinHeight } from "../../BuildingBlocks/MinHeight";
import { Padding } from "../../BuildingBlocks/Padding";
import { Sticky } from "../../BuildingBlocks/Sticky";
import { CRMSingleViewHeader } from "../../Simple/CRMSingleViewHeader/CRMSingleViewHeader";
import { Relative } from "../../BuildingBlocks/Relative";
import { TPixelSize } from "../../../models/StringLiterals";
import { TProbateHelplineCallbackForm } from "../../../../../domain/codecs/form/ProbateHelplineCallbackForm";
import { pipe } from "fp-ts/lib/function";
import { array } from "fp-ts";
import { SpacingColumn } from "../../BuildingBlocks/SpacingColumn";
import { CRMProbateHelplineSingleViewPersonalInformation } from "../CRMProbateHelplineSingleViewPersonalInformation/CRMProbateHelplineSingleViewPersonalInformation";
import { CRMProbateHelplineSingleViewNotesAndDefer } from "../CRMProbateHelplineSingleViewNotesAndDefer/CRMProbateHelplineSingleViewNotesAndDefer";

export const CRMProbateHelplineCompletedSingleView = (props: React.PropsWithChildren<{
    form: TProbateHelplineCallbackForm,
    onChange: (form: TProbateHelplineCallbackForm) => void,
    onClose: () => void;
}>): JSX.Element => {
    const fullName =
        pipe(
            [
                props.form.children.first_name,
                props.form.children.last_name,
            ],
            array.filter((namePart) => namePart !== ""),
            (a) => a.join(" "),
        );

    return (
        <Relative>
            <div>
                <Sticky
                    zIndex={1}
                >
                    <CRMSingleViewHeader 
                        title={fullName}
                        onClick={props.onClose} 
                    />
                </Sticky>
                
                <BackgroundColour colour={CRMColors.NEUTRAL_PAPER}>
                    <MinHeight height={"700px"}>
                        <Padding spacing={CRMSpacing.LARGE}>
                            <SpacingColumn spacing={CRMSpacing.LARGE}>
                                {/* PERSONAL INFORMATION */}
                                <CRMProbateHelplineSingleViewPersonalInformation
                                    form={props.form}
                                />

                                {/* NOTES & DEFER */}
                                <CRMProbateHelplineSingleViewNotesAndDefer
                                    form={props.form}
                                    onChange={props.onChange}
                                />
                            </SpacingColumn>
                        </Padding>
                    </MinHeight>
                </BackgroundColour>
            </div>
        </Relative>
    );
};
