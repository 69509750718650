import * as rxjs from "rxjs";
import * as rxjsOperators from "rxjs/operators";
import { TActionObservable } from "../../applyActions";
import * as FirstPartyFetchResponse from "../../../../../domain/models/FirstPartyFetchResponse";
import * as ListingSuccessResponse2 from "../../../../../domain/models/ListingSuccessResponse2";
import * as EnquirySuccessResponse1 from "../../../../../domain/models/EnquirySuccessResponse1";
import * as ListingFilter1 from "../../../../../domain/models/ListingFilter1";
import * as fetchWrapper from "../../../wrappers/fetch";
import { option } from "fp-ts";
import * as util from "../../../util";
import { defaultTListForm, requestToFormList } from "../../../models/TForm";
import { TListing3ReadOnlyFormList } from "../../../models/TFormModels";
import { TGetState } from "../../TGetState";
import { TSetState } from "../../TSetState";
import { TDispatch } from "../../TDispatch";
import { TActionsDefinitionsList } from "../TAction";
import { reduceDataToStateUpdate } from "../../../functions/lens/reduceDataToStateUpdate";
import { set } from "../../../functions/lens/set";
import { createChangeRouteAction } from "../../router/createChangeRouteAction";

export const actions: TActionsDefinitionsList = [
    {
        type: "CRM_ADD_NEW_ENQUIRY_SEARCH_INPUT_CHANGED" as const,
        run: (
            obs$: TActionObservable<"CRM_ADD_NEW_ENQUIRY_SEARCH_INPUT_CHANGED", string>,
            getState: TGetState,
            setState: TSetState,
        ): void => {
            obs$.pipe(
                rxjsOperators.tap(({payload}) =>
                    setState(({...s}) => {
                        s.activeData.crm.addNewEnquiry.searchInput = payload;
                        if (payload.trim() === "") {
                            s.activeData.crm.addNewEnquiry.searchResponseListings = defaultTListForm({}, {});
                        }
                        return s;
                    }),
                ),
                rxjsOperators.debounceTime(250),
                rxjsOperators.switchMap(({payload}) =>
                    payload.trim() === ""
                        ? rxjs.of(
                            setState(({...s}) => {
                                    s.activeData.crm.addNewEnquiry.searchResponseListings = defaultTListForm({}, {});
                                return s;
                            })
                        )
                        : makeListingsRequest(getState, 200)
                            .pipe(
                                rxjsOperators.tap((res) =>
                                    reduceDataToStateUpdate<FirstPartyFetchResponse.T<ListingSuccessResponse2.T>>(setState)(
                                        set<FirstPartyFetchResponse.T<ListingSuccessResponse2.T>>()(
                                            ["activeData", "crm", "addNewEnquiry", "searchResponseListings"],
                                            (formList, data) => requestToFormList<TListing3ReadOnlyFormList["forms"][number]>(formList, data),
                                        ),
                                    )(res),
                                ),
                            ),
                ),
                rxjsOperators.mapTo(undefined),
            ).subscribe();
        },
    },
    {
        type: "CRM_ADD_NEW_ENQUIRY_CREATE_PARTY_AND_ENQUIRY_FOR_LISTING" as const,
        run: (
            obs$: TActionObservable<"CRM_ADD_NEW_ENQUIRY_CREATE_PARTY_AND_ENQUIRY_FOR_LISTING", string>,
            getState: TGetState,
            setState: TSetState,
            dispatch: TDispatch,
        ): void => {
            obs$.pipe(
                rxjsOperators.mergeMap((action) =>
                    fetchWrapper.json<EnquirySuccessResponse1.T>({
                        requestParams: {
                            url: `${env.REACT_APP_API_URL}/v1/enquiries/new-party`,
                            method: "POST",
                            body: option.some(JSON.stringify({
                                listing_id: action.payload,
                            })),
                        },
                        expectedTypeCodec: EnquirySuccessResponse1.codec,
                        defaultResponse: EnquirySuccessResponse1.newDefault(),
                    })(),
                ),
                rxjsOperators.tap(util.defaultCRMRequestErrorHandler),
                rxjsOperators.tap((response) => {
                    if (response.tag === FirstPartyFetchResponse.constants.STATUS_2XX) {
                        dispatch(createChangeRouteAction("VIEW_CRM_ENQUIRY", { enquiryId: response.response.data.id }, {}));
                    }
                }),
            ).subscribe();
        },
    },
];

const makeListingsRequest = (getState: TGetState, limit: number): rxjs.Observable<FirstPartyFetchResponse.T<ListingSuccessResponse2.T>> =>
    rxjs.from(
        fetchWrapper.json<ListingSuccessResponse2.T>({
            requestParams: {
                url: `${env.REACT_APP_API_URL}/v1/listings?filters=${encodeURIComponent(JSON.stringify(ListingFilter1.createForAddress(getState().activeData.crm.addNewEnquiry.searchInput)))}&offset=0&limit=${limit}`,
                method: "GET",
                body: option.none,
            },
            expectedTypeCodec: ListingSuccessResponse2.codec,
            defaultResponse: ListingSuccessResponse2.newDefault(),
        })(),
    )
    .pipe(rxjsOperators.tap(util.defaultCRMRequestErrorHandler));
