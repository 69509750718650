import React from "react";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { SpacingRow } from "../../BuildingBlocks/SpacingRow";
import { CRMIcon } from "../../CRMIcon/CRMIcon";
import { CRMParagraph } from "../../Simple/CRMParagraph/CRMParagraph";
import { CursorPointer } from "../../CursorPointer/CursorPointer";
import { WeightBold } from "../../WeightBold/WeightBold";

export const CRMSeeMore = (props: React.PropsWithChildren<{
    seeMoreLabel: string,
    isExpanded: boolean,
    onClick: () => void,
}>): JSX.Element =>
    <>
        {/* SEE MORE */}
        {!props.isExpanded && <CursorPointer onClick={props.onClick}>
            <SpacingRow spacing={CRMSpacing.TINY}>
                <CRMParagraph>
                    <WeightBold>
                        {props.seeMoreLabel}
                    </WeightBold>
                </CRMParagraph>
                <CRMIcon
                    iconName="down-arrow"
                    colour="neutral-ink"
                    size="tiny"
                />
            </SpacingRow>
        </CursorPointer>}

        {/* COLLAPSE */}
        {props.isExpanded && <CursorPointer onClick={props.onClick}>
            <SpacingRow spacing={CRMSpacing.TINY}>
                <CRMParagraph>
                    <WeightBold>
                        Collapse
                    </WeightBold>
                </CRMParagraph>
                <CRMIcon
                    iconName="arrow-up"
                    colour="neutral-ink"
                    size="tiny"
                />
            </SpacingRow>
        </CursorPointer>}
    </>;
