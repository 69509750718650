import React from "react";

export default (props: React.SVGAttributes<SVGElement>) => <svg width="88" height="108" viewBox="0 0 88 108" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<path d="M5.36027 95.9298C5.93827 95.9298 6.48793 96.1054 7.00927 96.4568C7.5306 96.7968 7.94993 97.2614 8.26727 97.8508C8.5846 98.4288 8.74327 99.0634 8.74327 99.7548C8.74327 100.435 8.5846 101.069 8.26727 101.659C7.94993 102.248 7.5306 102.718 7.00927 103.07C6.48793 103.41 5.93827 103.58 5.36027 103.58H2.40227V106.793C2.40227 107.099 2.3116 107.348 2.13027 107.541C1.94893 107.733 1.71093 107.83 1.41627 107.83C1.13293 107.83 0.900599 107.733 0.719266 107.541C0.537932 107.337 0.447266 107.087 0.447266 106.793V96.9668C0.447266 96.6721 0.543599 96.4284 0.736266 96.2358C0.940266 96.0318 1.1896 95.9298 1.48427 95.9298H5.36027ZM5.36027 101.642C5.5756 101.642 5.78527 101.551 5.98927 101.37C6.2046 101.188 6.3746 100.956 6.49927 100.673C6.63527 100.378 6.70327 100.072 6.70327 99.7548C6.70327 99.4374 6.63527 99.1371 6.49927 98.8538C6.3746 98.5591 6.2046 98.3268 5.98927 98.1568C5.78527 97.9754 5.5756 97.8848 5.36027 97.8848H2.40227V101.642H5.36027Z" fill="#650F6E"/>
<path d="M21.8934 106.164C22.0408 106.254 22.1541 106.373 22.2334 106.521C22.3241 106.668 22.3694 106.821 22.3694 106.98C22.3694 107.184 22.3014 107.371 22.1654 107.541C21.9954 107.745 21.7348 107.847 21.3834 107.847C21.1114 107.847 20.8621 107.784 20.6354 107.66C19.8194 107.195 19.4114 106.249 19.4114 104.821C19.4114 104.413 19.2754 104.09 19.0034 103.852C18.7428 103.614 18.3631 103.495 17.8644 103.495H14.6854V106.793C14.6854 107.099 14.6004 107.348 14.4304 107.541C14.2718 107.733 14.0564 107.83 13.7844 107.83C13.4558 107.83 13.1668 107.733 12.9174 107.541C12.6794 107.337 12.5604 107.087 12.5604 106.793V96.9668C12.5604 96.6721 12.6568 96.4284 12.8494 96.2358C13.0534 96.0318 13.3028 95.9298 13.5974 95.9298H18.4934C19.0828 95.9298 19.6381 96.0884 20.1594 96.4058C20.6808 96.7231 21.0944 97.1594 21.4004 97.7148C21.7178 98.2701 21.8764 98.8934 21.8764 99.5848C21.8764 100.151 21.7234 100.707 21.4174 101.251C21.1114 101.783 20.7148 102.208 20.2274 102.526C20.9414 103.024 21.3324 103.693 21.4004 104.532C21.4344 104.713 21.4514 104.889 21.4514 105.059C21.4968 105.41 21.5421 105.665 21.5874 105.824C21.6328 105.971 21.7348 106.084 21.8934 106.164ZM18.4084 101.744C18.6124 101.744 18.8108 101.647 19.0034 101.455C19.1961 101.262 19.3548 101.007 19.4794 100.69C19.6041 100.361 19.6664 100.01 19.6664 99.6358C19.6664 99.3184 19.6041 99.0294 19.4794 98.7688C19.3548 98.4968 19.1961 98.2814 19.0034 98.1228C18.8108 97.9641 18.6124 97.8848 18.4084 97.8848H14.6854V101.744H18.4084Z" fill="#650F6E"/>
<path d="M36.8833 101.88C36.8833 103.002 36.634 104.033 36.1353 104.974C35.6366 105.903 34.9453 106.64 34.0613 107.184C33.1886 107.728 32.2026 108 31.1033 108C30.004 108 29.0123 107.728 28.1283 107.184C27.2556 106.64 26.57 105.903 26.0713 104.974C25.584 104.033 25.3403 103.002 25.3403 101.88C25.3403 100.758 25.584 99.7321 26.0713 98.8028C26.57 97.8621 27.2556 97.1198 28.1283 96.5758C29.0123 96.0318 30.004 95.7598 31.1033 95.7598C32.2026 95.7598 33.1886 96.0318 34.0613 96.5758C34.9453 97.1198 35.6366 97.8621 36.1353 98.8028C36.634 99.7321 36.8833 100.758 36.8833 101.88ZM34.6733 101.88C34.6733 101.12 34.5203 100.435 34.2143 99.8228C33.9083 99.1994 33.4833 98.7064 32.9393 98.3438C32.3953 97.9811 31.7833 97.7998 31.1033 97.7998C30.412 97.7998 29.7943 97.9811 29.2503 98.3438C28.7176 98.6951 28.2983 99.1824 27.9923 99.8058C27.6976 100.429 27.5503 101.12 27.5503 101.88C27.5503 102.639 27.6976 103.33 27.9923 103.954C28.2983 104.577 28.7176 105.07 29.2503 105.433C29.7943 105.784 30.412 105.96 31.1033 105.96C31.7833 105.96 32.3953 105.778 32.9393 105.416C33.4833 105.053 33.9083 104.566 34.2143 103.954C34.5203 103.33 34.6733 102.639 34.6733 101.88Z" fill="#650F6E"/>
<path d="M47.8447 101.234C48.4454 101.472 48.9327 101.834 49.3067 102.322C49.6921 102.809 49.8847 103.438 49.8847 104.209C49.8847 105.557 49.4994 106.498 48.7287 107.031C47.9581 107.563 47.0401 107.83 45.9747 107.83H41.7417C41.4471 107.83 41.1977 107.733 40.9937 107.541C40.8011 107.337 40.7047 107.087 40.7047 106.793V96.9668C40.7047 96.6721 40.8011 96.4284 40.9937 96.2358C41.1977 96.0318 41.4471 95.9298 41.7417 95.9298H46.0257C48.1791 95.9298 49.2557 96.9384 49.2557 98.9558C49.2557 99.4658 49.1311 99.9191 48.8817 100.316C48.6437 100.701 48.2981 101.007 47.8447 101.234ZM47.0457 99.3298C47.0457 98.8651 46.9267 98.5194 46.6887 98.2928C46.4621 98.0548 46.1391 97.9358 45.7197 97.9358H42.9147V100.588H45.7707C46.1107 100.588 46.4054 100.474 46.6547 100.248C46.9154 100.021 47.0457 99.7151 47.0457 99.3298ZM45.9747 105.875C46.5074 105.875 46.9211 105.733 47.2157 105.45C47.5217 105.166 47.6747 104.753 47.6747 104.209C47.6747 103.54 47.4991 103.104 47.1477 102.9C46.7964 102.696 46.3657 102.594 45.8557 102.594H42.9147V105.875H45.9747Z" fill="#650F6E"/>
<path d="M63.0474 106.504C63.104 106.651 63.1324 106.787 63.1324 106.912C63.1324 107.206 63.036 107.45 62.8434 107.643C62.6507 107.824 62.4297 107.915 62.1804 107.915C61.9764 107.915 61.7894 107.852 61.6194 107.728C61.4607 107.603 61.336 107.433 61.2454 107.218L60.3614 105.161H55.2954L54.4114 107.235C54.332 107.45 54.2074 107.62 54.0374 107.745C53.8787 107.858 53.703 107.915 53.5104 107.915C53.2157 107.915 52.989 107.835 52.8304 107.677C52.683 107.518 52.6094 107.303 52.6094 107.031C52.6094 106.929 52.6264 106.821 52.6604 106.708L56.8934 96.5418C56.984 96.3151 57.12 96.1394 57.3014 96.0148C57.4827 95.8901 57.6867 95.8391 57.9134 95.8618C58.1174 95.8618 58.3044 95.9241 58.4744 96.0488C58.6557 96.1621 58.786 96.3264 58.8654 96.5418L63.0474 106.504ZM59.5284 103.206L57.8114 99.2278L56.1284 103.206H59.5284Z" fill="#650F6E"/>
<path d="M73.4151 95.9298C73.7211 95.9298 73.9705 96.0204 74.1631 96.2018C74.3558 96.3831 74.4521 96.6211 74.4521 96.9158C74.4521 97.2104 74.3558 97.4484 74.1631 97.6298C73.9705 97.7998 73.7211 97.8848 73.4151 97.8848H70.7461V106.793C70.7461 107.087 70.6384 107.337 70.4231 107.541C70.2078 107.733 69.9415 107.83 69.6241 107.83C69.3068 107.83 69.0461 107.733 68.8421 107.541C68.6381 107.337 68.5361 107.087 68.5361 106.793V97.8848H65.8671C65.5611 97.8848 65.3118 97.7941 65.1191 97.6128C64.9265 97.4314 64.8301 97.1934 64.8301 96.8988C64.8301 96.6154 64.9265 96.3831 65.1191 96.2018C65.3231 96.0204 65.5724 95.9298 65.8671 95.9298H73.4151Z" fill="#9945bc"/>
<path d="M84.8387 105.875C85.1334 105.875 85.3771 105.977 85.5697 106.181C85.7737 106.373 85.8757 106.6 85.8757 106.861C85.8757 107.144 85.7737 107.376 85.5697 107.558C85.3771 107.739 85.1334 107.83 84.8387 107.83H79.1437C78.849 107.83 78.5997 107.733 78.3957 107.541C78.2031 107.337 78.1067 107.087 78.1067 106.793V96.9668C78.1067 96.6721 78.2031 96.4284 78.3957 96.2358C78.5997 96.0318 78.849 95.9298 79.1437 95.9298H84.8387C85.1334 95.9298 85.3771 96.0261 85.5697 96.2188C85.7737 96.4001 85.8757 96.6381 85.8757 96.9328C85.8757 97.2161 85.7794 97.4484 85.5867 97.6298C85.3941 97.7998 85.1447 97.8848 84.8387 97.8848H80.2317V100.775H84.0737C84.3684 100.775 84.6121 100.871 84.8047 101.064C85.0087 101.245 85.1107 101.483 85.1107 101.778C85.1107 102.061 85.0144 102.293 84.8217 102.475C84.6291 102.645 84.3797 102.73 84.0737 102.73H80.2317V105.875H84.8387Z" fill="#650F6E"/>
<path fillRule="evenodd" clipRule="evenodd" d="M87.3317 63.9797C81.5546 55.7549 77.184 47.9122 74.2202 40.4514C69.3558 28.2054 65.9833 14.9983 64.1038 0.830078H63.8661L61.9932 85.9989C66.7272 85.0223 70.9172 84.228 74.5634 83.6158L75.0805 83.5297C79.4454 82.8072 83.0086 82.3534 85.7698 82.1682C86.289 82.1333 86.8099 82.1008 87.3317 82.0712V87.9894H0.172363V0.830078H87.3317V63.9797ZM30.1728 87.8801C37.5361 81.5099 43.6683 79.6766 48.5698 82.3807C53.4712 85.0845 56.0609 86.604 56.3394 86.9389C53.9268 83.2353 52.4892 78.6152 52.0262 73.0785C51.3319 64.7736 51.0719 56.002 52.7159 46.6185C54.3602 37.235 57.231 20.6158 62.9823 9.77097C44.6278 37.5526 40.214 55.647 35.6077 70.0951C32.5369 79.7272 30.7252 85.6554 30.1728 87.8801Z" fill="#9945bc"/>
</svg>
