import React, { useState } from "react";
import { SpacingColumn } from "../../components/BuildingBlocks/SpacingColumn";
import { FrontQuestionFormCard } from "../../components/Front/Complex/FrontQuestionFormCard/FrontQuestionFormCard";
import FrontParagraphSmall from "../../components/Front/Simple/FrontParagraphSmall/FrontParagraphSmall";
import { TContainerStateProps } from "../../state/TContainerStateProps";
import { FrontSpacing } from "../../models/FrontSpacing";
import { WeightBold } from "../../components/WeightBold/WeightBold";
import { MaxWidth } from "../../components/BuildingBlocks/MaxWidth";
import { FrontFormSectionTitle } from "../../components/Front/Simple/FrontFormSectionTitle/FrontFormSectionTitle";
import { FrontFormPrimaryButton } from "../../components/Front/Simple/FrontFormPrimaryButton/FrontFormPrimaryButton";
import { InlineFlex } from "../../components/BuildingBlocks/InlineFlex";
import { TOnboardingTabStatus } from "../../components/Front/Simple/FrontOnboardingTab/FrontOnboardingTab";
import { FrontInfoBubble } from "../../components/Front/Simple/FrontInfoBubble/FrontInfoBubble";
import { FrontColors } from "../../models/FrontColors";
import { FrontPopupPrompt } from "../../components/Front/Complex/FrontPopupPrompt/FrontPopupPrompt";
import FrontParagraphRegular from "../../components/Front/Simple/FrontParagraphRegular/FrontParagraphRegular";
import { fromNumber } from "../../../../domain/models/TCurrency";
import { array } from "fp-ts";
import { ClientCaseUserSaysPaymentPaidForm } from "../../../../domain/codecs/form/ClientCaseForm";
import { pipe } from "fp-ts/lib/function";
import { Eq } from "fp-ts/lib/string";
import { ledgerTypeDictionary } from "../../../../domain/codecs/CaseLedger";
import { FrontPaymentTransactionRow } from "../../components/Front/Simple/FrontPaymentTransactionRow/FrontPaymentTransactionRow";
import { BorderBetween } from "../../components/BuildingBlocks/BorderBetween";

const FrontPaymentContainer = (props: TContainerStateProps): JSX.Element => {
    const [isConfirmPaymentOpen, setIsConfirmPaymentOpen] = useState(false);

    const form = props.state.forms.client_case_page.children.payment
        || {
            details: [],
            totalAmountDuePence: 0,
            paymentReference: "",
            user_says_paid: ClientCaseUserSaysPaymentPaidForm.newDefault(),
        };

    const transactionTypeCopyText =
        props.state.forms.client_case_page.children.case.transaction_type === "unknown" ? 
            "transaction" :
            props.state.forms.client_case_page.children.case.transaction_type;

    const outstandingPaymentReasons =
        pipe(
            form.details,
            array.filter(({status}) => status === "not_paid"),
            array.map(({type}) => type),
            array.map((type) =>
                type === "money_from_client_initial_payment" ? "our upfront costs"
                : type === "money_from_client_deposit" ? "your deposit for exchange of contracts"
                : type === "money_from_client_shortfall" ? `the remaining funds needed to complete your ${transactionTypeCopyText}`
                : ledgerTypeDictionary[type].displayName.toLowerCase()
            ),
            array.uniq(Eq),
        );

    const initialPaymentIsOutstanding =
        pipe(
            form.details,
            array.filter(({status, type}) =>
                status === "not_paid"
                && type === "money_from_client_initial_payment"
            ),
            (a) => a.length > 0,
        );

    const paymentsMade =
        pipe(
            form.details,
            array.filter(({status}) =>
                status === "paid"
                || status === "user_says_paid"
            ),
        );

    const hasPaymentsMade =
        paymentsMade.length > 0;

    const isFullyPaid =
        form.totalAmountDuePence === 0;

    const amountDueString = fromNumber((form.totalAmountDuePence || 0) / 100, 2);

    return (
        <div>
            <FrontQuestionFormCard
                title="Make a payment"
            >
                <MaxWidth width="500px">
                    <SpacingColumn spacing={FrontSpacing.LARGE_1}>
                        {/* IF ALL PAYMENTS HAVE BEEN MADE - COMPLETION TEXT */}
                        {isFullyPaid &&
                            <SpacingColumn spacing={FrontSpacing.MEDIUM_2}>
                                <SpacingColumn spacing={FrontSpacing.SMALL_3}>
                                    <FrontParagraphSmall>
                                        No balance is due from you right now.
                                    </FrontParagraphSmall>
                                </SpacingColumn>
                            </SpacingColumn>
                        }

                        {/* IF PAYMENT IS DUE - HOW TO MAKE A PAYMENT */}
                        {!isFullyPaid &&
                            <>
                                {/* INTRODUCTION */}
                                <SpacingColumn spacing={FrontSpacing.SMALL_3}>
                                    <FrontParagraphSmall>
                                        We need you to make a payment of <WeightBold>{amountDueString}</WeightBold> to us to cover {outstandingPaymentReasons.join(" and ")}.
                                    </FrontParagraphSmall>

                                    {/* INITIAL PAYMENT EXPLANATION */}
                                    {initialPaymentIsOutstanding &&
                                        <FrontParagraphSmall>
                                            If for any reason your {transactionTypeCopyText} is cancelled we will refund the money back to you after we have deducted the costs we have had so far.
                                        </FrontParagraphSmall>
                                    }
                                </SpacingColumn>

                                {/* SECTION: MAKE A BANK TRANSFER */}
                                <SpacingColumn spacing={FrontSpacing.MEDIUM_1}>
                                    <FrontFormSectionTitle>
                                        Depositing your money
                                    </FrontFormSectionTitle>

                                    
                                    <SpacingColumn spacing={FrontSpacing.SMALL_3}>
                                        <FrontParagraphSmall>
                                            Make a <WeightBold>bank transfer</WeightBold> to the account below, and add the <WeightBold>payment reference</WeightBold> we have given you to it.
                                        </FrontParagraphSmall>
                                        {/* BANK DETAILS */}
                                        <div>
                                            <FrontInfoBubble color={FrontColors.PRIMARY_22}>
                                                <SpacingColumn spacing={FrontSpacing.SMALL_2}>
                                                    <FrontParagraphSmall>
                                                        <WeightBold>Account name:</WeightBold> Sail Legal Limited
                                                    </FrontParagraphSmall>
                                                    <FrontParagraphSmall>
                                                        <WeightBold>Account number:</WeightBold> 427 595 89 
                                                    </FrontParagraphSmall>
                                                    <FrontParagraphSmall>
                                                        <WeightBold>Sort code:</WeightBold> 23-05-80
                                                    </FrontParagraphSmall>
                                                    <FrontParagraphSmall>
                                                        <WeightBold>Payment Reference:</WeightBold> {form.paymentReference}
                                                    </FrontParagraphSmall>
                                                </SpacingColumn>
                                            </FrontInfoBubble>
                                        </div>
                                        
                                        <FrontParagraphSmall>
                                            After you have <WeightBold>completed</WeightBold> the bank transfer, let us know by clicking the button below. 
                                        </FrontParagraphSmall>

                                        <InlineFlex>
                                            <FrontFormPrimaryButton
                                                icon="lock"
                                                label={`I have made a payment of ${amountDueString}`}
                                                onClick={() => setIsConfirmPaymentOpen(true)}
                                            />
                                        </InlineFlex>
                                    </SpacingColumn>
                                </SpacingColumn>
                            </>
                        }

                        {/* PAYMENT HISTORY */}
                        {hasPaymentsMade &&
                            <SpacingColumn spacing={FrontSpacing.MEDIUM_1}>
                                <FrontFormSectionTitle>
                                    Payment History
                                </FrontFormSectionTitle>
                                
                                <MaxWidth width="300px">
                                    {paymentsMade.map((item) =>
                                        <BorderBetween
                                            key={item.id}
                                            borderColour={FrontColors.NEUTRAL_11}
                                            spacing={FrontSpacing.MEDIUM_2}
                                        >
                                            <FrontPaymentTransactionRow item={item}/>
                                        </BorderBetween>
                                    )}
                                </MaxWidth>
                            </SpacingColumn>
                        }
                    </SpacingColumn>
                </MaxWidth>
            </FrontQuestionFormCard>

            <FrontPopupPrompt
                title="One last check"
                isOpen={isConfirmPaymentOpen}
                onCTA={() => props.dispatch({
                    type: "CLIENT_CONFIRM_DEPOSIT_TRANSFER",
                    payload: null
                })}
                ctaText="Yes, I have "
                closeText="Actually, no"
                onClose={() => setIsConfirmPaymentOpen(false)}
            >
                <SpacingColumn spacing={FrontSpacing.SMALL_3}>
                    <FrontParagraphRegular>
                       Just making sure you didn't click this button by accident.
                    </FrontParagraphRegular>
                    <FrontParagraphRegular>
                        Have you made your <WeightBold>bank transfer</WeightBold>, and are ready to proceed?
                    </FrontParagraphRegular>
                </SpacingColumn>
            </FrontPopupPrompt>
        </div>
    );
};

export const getPaymentTabStatus = (props: TContainerStateProps): TOnboardingTabStatus =>
    pipe(
        props.state.forms.client_case_page.children.payment?.details || [],
        array.filter(({status}) => status === "not_paid"),
        (a) => a.length > 0
            ? "active"
            : "completed"
    );

export default FrontPaymentContainer;
