import React from "react";
import { toDateFormat1 } from "../../../../../shared/src/dateTime";
import { CRMFontSizes } from "../../../models/CRMFontSizes";
import { CRMColors } from "../../../models/CRMColors";
import { Padding } from "../../BuildingBlocks/Padding";
import { Text } from "../../BuildingBlocks/Text";
import { CRMIcon } from "../../CRMIcon/CRMIcon";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { CRMSpacingRow } from "../../CRMSpacingRow/CRMSpacingRow";
import { WeightBold } from "../../WeightBold/WeightBold";
import { useHover } from "../../../hooks/useHover";
import { CRMTimelineControlsWrapper } from "../../CRMTimelineControlsWrapper/CRMTimelineControlsWrapper";
import { Background } from "../../BuildingBlocks/Background";
import { Relative } from "../../BuildingBlocks/Relative";
import { CRMParagraph } from "../../Simple/CRMParagraph/CRMParagraph";
import { CursorPointer } from "../../CursorPointer/CursorPointer";

type TCRMTimelineTextItemProps = {
    date: string;
    createdByUserName: string;
    text: string;
    isPinned: boolean;
    onClickDelete: () => void;
    onClickPin: () => void;
};

export const CRMTimelineTextItem = (props: TCRMTimelineTextItemProps): JSX.Element => {
    
    const [ref, isHovering] = useHover<HTMLDivElement>({});
    
    return (
        <div ref={ref}>
            <Background
                color={
                    props.isPinned
                    ? CRMColors.PRIMARY_16
                    : undefined
                }
                borderRadius="5px"
                boxShadowDefinition={
                    props.isPinned
                    ? `0px 0px 0px 1px ${CRMColors.PRIMARY_4}`
                    : ''
                }
            >
                <Relative>
                    <Background
                        borderRadius="5px"
                        color={CRMColors.PRIMARY_16}
                        width="fit-content"
                    >
                        <Padding spacing={CRMSpacing.SMALL}>
                            <Text
                                size={CRMFontSizes.SMALL_PLUS}
                                color={CRMColors.NEUTRAL_0}
                            >
                                <CRMSpacingRow spacing="small">
                                    <CRMIcon
                                        size="tiny"
                                        iconName="chat-points"
                                        colour="neutral-ink"
                                    />
                                    {toDateFormat1(props.date)} <WeightBold>{props.createdByUserName}</WeightBold>
                                </CRMSpacingRow>
                            </Text>
                        </Padding>

                        {
                            (isHovering || props.isPinned) &&
                                <CRMTimelineControlsWrapper
                                    top="0px"
                                    right="0px"
                                    bottom="0px"
                                    backgroundColour={
                                        props.isPinned ? CRMColors.PRIMARY_16 : CRMColors.NEUTRAL_PAPER
                                    }
                                >
                                    <CursorPointer onClick={props.onClickPin}>
                                        <CRMIcon
                                            iconName={props.isPinned ? "pinned" : "pin"}
                                            colour="neutral-ink"
                                            size="small"
                                        />
                                    </CursorPointer>
                                    {
                                        isHovering &&
                                            <CursorPointer onClick={props.onClickDelete}>
                                                <CRMIcon
                                                    iconName="delete"
                                                    colour="neutral-ink"
                                                    size="small"
                                                />
                                            </CursorPointer>
                                    }
                                </CRMTimelineControlsWrapper>
                        }
                    </Background>
                </Relative>
                
                <CRMParagraph>
                    <Padding
                        type="custom"
                        spacing={`${CRMSpacing.SMALL} ${CRMSpacing.SMALL} ${props.isPinned ? CRMSpacing.SMALL : "0px" } 32px`}
                    >
                        {props.text}
                    </Padding>
                </CRMParagraph>
            </Background>
        </div>
    );
};
