import { array } from "fp-ts";
import { pipe } from "fp-ts/lib/function";
import React from "react";
import { THexColor } from "../../../models/StringLiterals";
import { Position } from "../../BuildingBlocks/Position";
import { WeightBold } from "../../WeightBold/WeightBold";
import { CRMBadge } from "../CRMBadge/CRMBadge";

export type TCRMNotificationBadgeBadges = {
    backgroundColour: THexColor,
    fontColour: THexColor,
    count: number,
    key: string,
    spreadLabel: string,
};

export const CRMNotificationBadgeGroup = (props: React.PropsWithChildren<{
    badges: Array<TCRMNotificationBadgeBadges>,
    isHovering: boolean,
}>): JSX.Element => {

    const getBadgesToShow = () =>
        pipe(
            props.badges,
            array.filter(({count}) => count > 0),
            array.mapWithIndex((i, badge) => ({
                ...badge,
                positionTopWhenStacked: `${4 * i}px`,
                positionLeftWhenStacked: `${4 * i}px`,
                positionTopWhenSpread: `${25 * i}px`,
                positionLeftWhenSpread: "0px",
            }))
        );

    return (
        <div className="crm-notification-badge-group">
            <Position position="relative">
                {getBadgesToShow().map((badge) => <div
                    key={badge.key}
                    className="crm-notification-badge-group__badge"
                    style={{
                        top: props.isHovering ? badge.positionTopWhenSpread : badge.positionTopWhenStacked,
                        left: props.isHovering ? badge.positionLeftWhenSpread : badge.positionLeftWhenStacked
                    }}
                >
                    <CRMBadge
                        backgroundColour={badge.backgroundColour}
                        fontColour={badge.fontColour}
                    >
                        {props.isHovering && <><WeightBold>{badge.spreadLabel}:</WeightBold>&nbsp;</>}
                        {badge.count}
                    </CRMBadge>
                </div>)}
            </Position>
        </div>
    );
};
