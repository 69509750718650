import React from "react";
import { FrontBuyerReadyWaysToImprove } from "../FrontBuyerReadyWaysToImprove/FrontBuyerReadyWaysToImprove";
import { FrontLegend } from "../FrontLegend/FrontLegend";
import { FrontOutsidePopupBasic } from "../FrontOutsidePopupBasic/FrontOutsidePopupBasic";
import FrontParagraphRegular from "../../../Front/Simple/FrontParagraphRegular/FrontParagraphRegular";
import { FrontSpacer } from "../../../FrontSpacer/FrontSpacer";
import FrontTitleMinor from "../../Simple/FrontTitleMinor/FrontTitleMinor";
import { WeightBold } from "../../../WeightBold/WeightBold";
import { WeightMedium } from "../../../WeightMedium/WeightMedium";
import { boolean } from "fp-ts";
import { pipe } from "fp-ts/lib/function";
import { TOffer1NewDefault } from "../../../../../../domain/codecs/Offer";

export class FrontCompletionTimeModal extends React.Component<{
    isOpen: boolean;
    onClose: () => void;
    thierLatestOffer: TOffer1NewDefault;
}> {
    public getTipsToDisplay = (): FrontBuyerReadyWaysToImprove["props"]["showTipsFor"] =>
        [
            ...pipe(
                this.props.thierLatestOffer.has_provided_conveyancer_details,
                boolean.fold<Array<"solicitor_details">>(
                    () => ["solicitor_details"],
                    () => [],
                )
            ),
            ...pipe(
                    this.props.thierLatestOffer.has_provided_mortgage_broker_details
                    || this.props.thierLatestOffer.payment_method === "cash",
                boolean.fold<Array<"mortgage_broker_details">>(
                    () => ["mortgage_broker_details"],
                    () => [],
                )
            ),
        ];

    public render (): JSX.Element {
        return (
            <FrontOutsidePopupBasic
                title="What is completion time?"
                isOpen={this.props.isOpen}
                onClose={this.props.onClose}
            >
                {/* Description */}
                <FrontParagraphRegular>
                    <WeightMedium>
                        "Completion" is when the process of buying a house is complete, you've got the keys and the property is paid for. It can take months with other agents to get to completion. Our aim is to reduce this as much as possible so we give the seller a clear indication on your position by putting you into a "fast" or "slow" category.&nbsp;
                    </WeightMedium>
                    {this.getTipsToDisplay().length > 0 && <WeightBold>
                        Don't worry if your speed is "Slow" at the moment, everyone starts out that way. Below are some ways to improve this.
                    </WeightBold>}
                </FrontParagraphRegular>

                {/* Legend */}
                <FrontLegend
                    rows={[
                        {
                            key: "Fast",
                            keyColour: "highlights-positive-apple-10",
                            description: "Fast means that you have provided your solicitor details, you aren't in a chain and intend to either pay in cash or your mortgage broker has told us about your circumstances.",
                        },
                        {
                            key: "Slow",
                            keyColour: "highlights-error-firebrick-10",
                            description: "Everyone starts out as \"Slow\" until they start providing us with more information. Below are some ways to improve your completion time.",
                        },
                    ]}
                />

                {this.getTipsToDisplay().length > 0 && <>
                    {/* Why is it important to improve this */}
                    <FrontTitleMinor>
                    Why is it important to improve this?
                    </FrontTitleMinor>
                    <FrontParagraphRegular>
                        The seller takes into account the completion time of every offer that is made. Improving the completion time will make it more likely for your offer to be accepted.
                    </FrontParagraphRegular>

                    {/* How do I improve this */}
                    <FrontTitleMinor>
                        How do I improve my completion time?
                    </FrontTitleMinor>
                    <FrontParagraphRegular>
                        You still need to provide the following, once we’ve received these your time will improve.
                    </FrontParagraphRegular>
                    <FrontBuyerReadyWaysToImprove
                        showTipsFor={this.getTipsToDisplay()}
                    />
                </>}
            </FrontOutsidePopupBasic>
        );
    }
}
