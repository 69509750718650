import React from "react";
import { FontQuicksand } from "../../../BuildingBlocks/FontQuicksand";
import { Uppercase } from "../../../Uppercase/Uppercase";
import { WeightMedium } from "../../../WeightMedium/WeightMedium";
import { FontSize } from "../../../BuildingBlocks/FontSize";
import { FrontFontSizes } from "./../../../../models/FrontFontSizes";

type TFrontFormSectionTitleProps = {

};

export const FrontFormSectionTitle = (props: React.PropsWithChildren<TFrontFormSectionTitleProps>): JSX.Element => {

    return (
        <FontQuicksand>
            <FontSize size={FrontFontSizes.MED}>
                <WeightMedium>
                    <Uppercase>
                        {props.children}
                    </Uppercase>
                </WeightMedium>
            </FontSize>
        </FontQuicksand>
    );
};
