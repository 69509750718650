import React from "react";
import { TCasesCaseMilestone } from "../../../../../../domain/codecs/form/CasesForm";

type TCaseMilestonePillProps = {
    status: TCasesCaseMilestone;
    label: string;
};

export const CRMCaseMilestonePill = (props: React.PropsWithChildren<TCaseMilestonePillProps>): JSX.Element => {

    return (
        <div className={`
            crm-case-milestone-pill 
            crm-case-milestone-pill--${props.status}
        `}>
            {props.label}
        </div>
    );
};
