import React from "react";

export class CRMEndOfBoxIndicator extends React.Component<React.PropsWithChildren<{}>> {
    public render (): JSX.Element {
        return (
            <div className="crm-end-of-box-indicator">
                <div className="crm-end-of-box-indicator__filled-section"></div>
            </div>
        );
    }
}
