import React from "react";
import fuzzysort from "fuzzysort";
import { CRMColors } from "../../../models/CRMColors";
import { THexColor } from "../../../models/StringLiterals";

type TCRMFuzzySortSearchHighlight = {
    text: string;
    searchText: string;
    fontColor?: THexColor;
    backgroundColor?: THexColor;
};

export const CRMFuzzySortSearchHighlight = (props: TCRMFuzzySortSearchHighlight): JSX.Element => {
    
    const singleResult = fuzzysort.single(props.searchText, props.text);

    const createHighlightMarkdown = (singleRes: Fuzzysort.Result): string =>
        fuzzysort.highlight(
            singleRes, 
            `<span style="
                font-weight: 700;
                color: ${props.fontColor || CRMColors.NEUTRAL_INK};
                background-color: ${props.backgroundColor || CRMColors.HIGHLIGHTS_PERTINENT_YELLOW_0}";
            ">`, 
            "</span>"
        ) || ""
    ;
    
    return (
        <div
            dangerouslySetInnerHTML={{
                __html: !!singleResult ?
                    createHighlightMarkdown(singleResult)
                    : props.text 
            }} 
        />
    );
};
