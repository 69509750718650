import { array, identity } from "fp-ts";
import { pipe } from "fp-ts/lib/pipeable";
import React, { useEffect } from "react";
import { updateWhereOr } from "../../../shared/src/utilsByDomain/array";
import { useDropdown } from "./UseDropdown";
import { IFuzzySortOption, useFuzzysort } from "./useFuzzysort";
import { IOption } from "./UseDropdown";


export type TRichFilterOption = 
    Record<string, unknown> 
    & {
        group: string;
    }
;

export type TResultGroup<T extends TRichFilterOption> = { 
    name: string, 
    options: Array<Fuzzysort.KeyResult<IFuzzySortOption<string, T>>>
};

type TRichFilterDropdownStatus = 
    "untouched"
    | "no-options"
    | "no-match"
    | "matching"
;


export type TUseRichFilterDropdownBaseProps<T extends TRichFilterOption> = {
    selected: Array<string>;    
    onChange: (emails: Array<string>) => void;
    options: Array<T>;
}

export type TUseRichFilterDropdownProps<T extends TRichFilterOption> = 
    TUseRichFilterDropdownBaseProps<T>
    & {
        selectionCap?: number;
        convertOptionToFuzzySortOption: (option: T) => IFuzzySortOption<string, T>;
        convertOptionToDropdownOption: (option: T) => IOption<string>;
    }
;

export const useRichFilterDropdown = <T extends TRichFilterOption>(props: TUseRichFilterDropdownProps<T>) => {
    
    const inputRef = React.createRef<HTMLInputElement>();
    const selectionCap = props.selectionCap || Infinity;

    const {
        searchText,
        setSearchText,
        getSearchResults,
        setSearchOptions,
    } = useFuzzysort<string, T>(
        props.options.map(props.convertOptionToFuzzySortOption)
    );

    const {
        isOpen,
        toggleSelectedValue,
        isOptionSelected,
        setIsOpen,
        ref,
    } = useDropdown(
        props.selected,
        props.options.map(props.convertOptionToDropdownOption)
    );

    useEffect(
        () => {
            setSearchOptions(props.options.map(props.convertOptionToFuzzySortOption));
        },
        [props.options]
    );

    const isShowingOnlyOneOption = (): boolean => getSearchResults().length === 1;
    
    const open = () => setIsOpen(true);

    const close = () => setIsOpen(false);

    const onToggleOptionSelection = (value: string) => {
        if (selectionCap === 1) {
            props.onChange([value]);
            close();
        }

        if ((selectionCap > 1 && props.selected.length < selectionCap) || isOptionSelected(value)) {
            props.onChange(toggleSelectedValue(value));
            inputRef.current?.focus();
        }
    };

    const deriveStatus = (): TRichFilterDropdownStatus => {
        const hasQuery = searchText.length > 0;
        const noQuery = !hasQuery;

        const hasResult = getSearchResults().length > 0;
        const noResult = !hasResult;

        if (noQuery && props.options.length === 0) {
            return "no-options";
        }

        if (hasQuery && hasResult) {
            return "matching";
        }
        
        if (hasQuery && noResult) {
            return "no-match";
        }

        return "untouched";
    }

    const onPressSearchEnter = () => {
        if (isShowingOnlyOneOption()) {
            const option = getSearchResults()[0];
            onToggleOptionSelection(option.obj.value);
            setSearchText("");
        }
    }

    const onEscape = () => {
        inputRef.current?.blur();
        setIsOpen(false);
    }

    const onBackspace = () => {
        if (searchText.length === 0 && props.selected.length > 0) {
            onToggleOptionSelection(props.selected[props.selected.length-1]);
        }
    }

    const getSearchResultsByGroup = (): Array<TResultGroup<T>> => pipe(
        getSearchResults().map(identity.flatten),
        array.reduce(
            [] as Array<TResultGroup<T>>,
            (sum, option) => pipe(
                sum,
                updateWhereOr(
                    (group) => group.name === option.obj.meta?.group,
                    (group) => ({
                        ...group,
                        options: group.options.concat(option)
                    }),
                    (arr) => arr.concat({
                        name: option.obj.meta?.group as string,
                        options: [option]
                    })
                ),
            )
        ),
    );

    return {
        searchText,
        setSearchText,
        onToggleOptionSelection,
        getSearchResultsByGroup,
        getSearchResults,
        ref,
        inputRef,
        isOpen,
        open,
        close,
        toggleSelectedValue,
        onPressSearchEnter,
        deriveStatus,
        onBackspace,
        onEscape,
    };
}