import * as rxjs from "rxjs";
import * as rxjsOperators from "rxjs/operators";
import { option, either } from "fp-ts";
import * as TForm from "./../../models/TForm";
import * as request from "./../request";
import { TState, TUnpackArray } from "./lensBaseTypes";
import { reduceDataToStateUpdate } from "./reduceDataToStateUpdate";
import { setWhere } from "./setWhere";
import { findFirstWhere } from "./findFirstWhere";
import { TGetState } from "../../state/TGetState";
import { TSetState } from "../../state/TSetState";
import { pipe } from "fp-ts/lib/function";
import { TValidationError } from "../../../../shared/src/validation/Error";

interface IObservableOfValidatedFormWhere<T, S> {
    // 5 level lens path guard
    <
        K1 extends keyof S,
        K2 extends keyof S[K1],
        K3 extends keyof S[K1][K2],
        K4 extends keyof S[K1][K2][K3],
        K5 extends keyof S[K1][K2][K3][K4]
    >
    (
        path: [K1, K2, K3, K4, K5],
        whereCallback: (value: TUnpackArray<S[K1][K2][K3][K4][K5]>) => boolean,
        validator: (value: unknown) => option.Option<TValidationError>,
    ): rxjs.Observable<either.Either<TValidationError, TForm.TFormV2<TForm.TUnpackViewAndEditFormType<T>, TForm.TUnpackEditFormType<T>, TForm.TUnpackUiType<T>>>>;
    // 4 level lens path guard
    <
        K1 extends keyof S,
        K2 extends keyof S[K1],
        K3 extends keyof S[K1][K2],
        K4 extends keyof S[K1][K2][K3]
    >
    (
        path: [K1, K2, K3, K4],
        whereCallback: (value: TUnpackArray<S[K1][K2][K3][K4]>) => boolean,
        validator: (value: unknown) => option.Option<TValidationError>,
    ): rxjs.Observable<either.Either<TValidationError, TForm.TFormV2<TForm.TUnpackViewAndEditFormType<T>, TForm.TUnpackEditFormType<T>, TForm.TUnpackUiType<T>>>>;
    // 3 level lens path guard
    <
        K1 extends keyof S,
        K2 extends keyof S[K1],
        K3 extends keyof S[K1][K2]
    >
    (
        path: [K1, K2, K3],
        whereCallback: (value: TUnpackArray<S[K1][K2][K3]>) => boolean,
        validator: (value: unknown) => option.Option<TValidationError>,
    ): rxjs.Observable<either.Either<TValidationError, TForm.TFormV2<TForm.TUnpackViewAndEditFormType<T>, TForm.TUnpackEditFormType<T>, TForm.TUnpackUiType<T>>>>;
    // 2 level lens path guard
    <
        K1 extends keyof S,
        K2 extends keyof S[K1]
    >
    (
        path: [K1, K2],
        whereCallback: (value: TUnpackArray<S[K1][K2]>) => boolean,
        validator: (value: unknown) => option.Option<TValidationError>,
    ): rxjs.Observable<either.Either<TValidationError, TForm.TFormV2<TForm.TUnpackViewAndEditFormType<T>, TForm.TUnpackEditFormType<T>, TForm.TUnpackUiType<T>>>>;
    // 1 level lens path guard
    <K1 extends keyof S>
    (
        path: [K1],
        whereCallback: (value: TUnpackArray<S[K1]>) => boolean,
        validator: (value: unknown) => option.Option<TValidationError>,
    ): rxjs.Observable<either.Either<TValidationError, TForm.TFormV2<TForm.TUnpackViewAndEditFormType<T>, TForm.TUnpackEditFormType<T>, TForm.TUnpackUiType<T>>>>;
}

export const ofFormValidatedAndSetToSubmittingWhere = <T>(getState: TGetState, setState: TSetState): IObservableOfValidatedFormWhere<T, TState> => (
    // There is no way to create paramater overloads here so we set to any
    lensPath: any, // eslint-disable-line
    whereCallback: any, // eslint-disable-line
    validator: (value: unknown) => option.Option<TValidationError>,
): rxjs.Observable<either.Either<TValidationError, TForm.TFormV2<TForm.TUnpackViewAndEditFormType<T>, TForm.TUnpackEditFormType<T>, TForm.TUnpackUiType<T>>>> =>
     pipe(
        request.rxjsPayloadFrom<TForm.TFormV2<TForm.TUnpackViewAndEditFormType<T>, TForm.TUnpackEditFormType<T>, TForm.TUnpackUiType<T>>>(findFirstWhere(getState())(
            lensPath,
            whereCallback,
        )),
        rxjsOperators.tap(reduceDataToStateUpdate<TForm.TFormV2<TForm.TUnpackViewAndEditFormType<T>, TForm.TUnpackEditFormType<T>, TForm.TUnpackUiType<T>>>(setState)(
            setWhere<TForm.TFormV2<TForm.TUnpackViewAndEditFormType<T>, TForm.TUnpackEditFormType<T>, TForm.TUnpackUiType<T>>>()(
                lensPath,
                whereCallback,
                /* eslint-disable */
                // The type is always expected to be never for the form, since we have abstraced the lens scoping,
                // which is untrue
                // @ts-ignore
                TForm.setFormToSubmitting<TForm.TFormV2<TForm.TUnpackViewAndEditFormType<T>, TForm.TUnpackEditFormType<T>, TForm.TUnpackUiType<T>>>()
                /* eslint-enable */
            )
        )),
        rxjsOperators.mergeMap((form) =>
            rxjs.of(
                option.fold<TValidationError, either.Either<TValidationError, TForm.TFormV2<TForm.TUnpackViewAndEditFormType<T>, TForm.TUnpackEditFormType<T>, TForm.TUnpackUiType<T>>>>(
                    () => either.right(form),
                    (errors) => either.left(errors)
                )(validator(form.edit))
            )
        )
    );
