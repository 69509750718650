import React from "react";
import { CRMIcon } from "../../CRMIcon/CRMIcon";

export const CRMInputVerificationIndicator = (props: {
    status: "verified"
        | "unverified";
}): JSX.Element =>
    <>
        {/* VERIFIED */}
        {props.status === "verified" &&
            <CRMIcon
                title="This number is verified as belonging to the person."
                iconName="lock"
                size="tiny"
                colour="highlights-positive-green-2"
            />
        }

        {/* UNVERIFIED */}
        {props.status === "unverified" &&
            <CRMIcon
                title="This number HAS NOT been verified as belonging to the person, yet."
                iconName="unlocked"
                size="tiny"
                colour="highlights-instructional-brick-2"
            />
        }
    </>;