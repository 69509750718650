import React from "react";
import { CRMIcon } from "../../CRMIcon/CRMIcon";
import { CRMColors } from "../../../models/CRMColors";
import { SpacingRow } from "../../BuildingBlocks/SpacingRow";
import { CRMParagraph } from "../../Simple/CRMParagraph/CRMParagraph";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { SpacingColumn } from "../../BuildingBlocks/SpacingColumn";
import { Background } from "../../BuildingBlocks/Background";

// [REFACTOR] - Maybe it would be cool to look into that seperate child fields 
// that react can use to place different children in seperate places. 
// Yeah I defo think its a good idea.
type TRoadblockChecklistItemBase = {
    ParagraphElement: JSX.Element | string | null;
    isShowingCheck: boolean;
    isCheckedFinal: boolean;

    isDisabledFirstCheck: boolean;
    isDisabledFinalCheck: boolean;

    onChangeFinalCheck: (value: boolean) => void;
};

export const CRMRoadblockChecklistItemBase = (props: React.PropsWithChildren<TRoadblockChecklistItemBase>): JSX.Element => {
    
    const onChangeFinalCheck = () => props.onChangeFinalCheck(!props.isCheckedFinal);

    return (
        <div 
            className={`
                crm-roadblock-checklist-item-base
                crm-roadblock-checklist-item-base--${props.isDisabledFirstCheck && props.isDisabledFinalCheck ? "disabled" : "enabled"}
            `}
        >
             <SpacingRow 
                spacing={CRMSpacing.TINY}
                childSize="1fr 62px"
                alignItems="flex-start"
            >   
                {/* MAIN BODY */}
                <Background padding={CRMSpacing.SMALL}>
                    <SpacingColumn spacing={CRMSpacing.SMALL}>
                        
                        {/* HEAD */}
                        {props.children}

                        {/* PARAGRAPH */}
                        {props.ParagraphElement !== null &&
                            <Background padding={`0px ${CRMSpacing.TINY}`}>
                                <CRMParagraph>
                                    {props.ParagraphElement}
                                </CRMParagraph>
                            </Background>
                        }

                    </SpacingColumn>
                </Background>
                
                {/* BIG CHECK */}
                {props.isShowingCheck &&
                    <div
                        className={`
                            crm-roadblock-checklist-item-base__final-check
                            crm-roadblock-checklist-item-base__final-check--${props.isDisabledFinalCheck ? "disabled" : "enabled"}
                        `}
                        onClick={onChangeFinalCheck}
                    >
                        <CRMIcon
                            iconName={props.isCheckedFinal ? "checkbox-checked" : "checkbox-unchecked"}
                            colour="hex"
                            hexColour={props.isCheckedFinal ? CRMColors.HIGHLIGHTS_POSITIVE_GREEN_2 : CRMColors.NEUTRAL_0}
                            size={"30px"}
                        />
                    </div>
                }
            </SpacingRow>
        </div>
    );
}
