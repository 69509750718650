import React from "react";
import { TFrontMainColours } from "../../../../models/TFrontMainColours";
import { FrontIcon } from "../../Simple/FrontIcon/FrontIcon";
import { FrontTitlePageSection } from "./../../Simple/FrontPageSectionTitle/FrontTitlePageSection";
import FrontParagraphSmall from "../../Simple/FrontParagraphSmall/FrontParagraphSmall";
import { FrontSpacer } from "../../../FrontSpacer/FrontSpacer";
import { FrontTextColour } from "../../../FrontTextColour/FrontTextColour";
import { TextCenter } from "../../../TextCenter/TextCenter";
import { WeightMedium } from "../../../WeightMedium/WeightMedium";

type TFrontOfferSummaryGauge = {
    label: string;
    colour: TFrontMainColours;
    title: string;
    onClickInfo: () => void;
};

export class FrontOfferSummaryGauge extends React.Component<React.PropsWithChildren<TFrontOfferSummaryGauge>> {
    public render (): JSX.Element {
        return (
            <div className="front-offer-summary-gauge">
                {/* LABEL */}
                <TextCenter>
                    <FrontParagraphSmall>
                        <WeightMedium>
                            <FrontTextColour colour="neutral-10">
                                {this.props.label}
                            </FrontTextColour>
                        </WeightMedium>
                    </FrontParagraphSmall>
                </TextCenter>
                <FrontSpacer size="medium-1" />

                {/* GAUGE */}
                <TextCenter>
                    <FrontTitlePageSection>
                        <FrontTextColour colour={this.props.colour}>
                            {this.props.title}
                        </FrontTextColour>
                    </FrontTitlePageSection>
                </TextCenter>
                <FrontSpacer size="small-2" />

                {/* INFO ICON */}
                <div
                    className="front-offer-summary-gauge__info-icon"
                    onClick={this.props.onClickInfo}
                >
                    <FrontIcon
                        iconName="info"
                        colour="primary-10"
                        size="small"
                    />
                </div>
            </div>
        );
    }
}
