import React from "react";
import { CRMIcon, TIcon, TIconSizes } from "../CRMIcon/CRMIcon";

interface IIconBinary {
    title?: string;
    iconName: TIcon;
    completedState: boolean;
    size?: TIconSizes;
}

export class CRMIconBinary extends React.Component<React.PropsWithChildren<IIconBinary>> {
    public render (): JSX.Element {
        return (
            <div
                title={this.props.title}
                className={`
                    crm-icon-binary 
                    crm-icon-binary--colour-${this.props.completedState === true ? "highlights-positive-green-10" : "highlights-instructional-brick-8"} 
                    crm-icon-binary--${this.props.size || "medium"}
                `}
            >
                <CRMIcon
                    iconName={this.props.iconName}
                    colour="neutral-ink"
                    size={this.props.size}
                />
            </div>
        );
    }
}
