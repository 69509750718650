import React from "react";
import { SpacingColumn } from "../../../BuildingBlocks/SpacingColumn";
import { pipe } from "fp-ts/lib/function";
import { array } from "fp-ts";
import { SpacingRow } from "../../../BuildingBlocks/SpacingRow";
import { HorizontalLine } from "../../../BuildingBlocks/HorizontalLine";
import { FrontColors } from "../../../../models/FrontColors";
import { FontQuicksand } from "../../../BuildingBlocks/FontQuicksand";
import { Text } from "../../../BuildingBlocks/Text";
import { FrontFontSizes } from "../../../../models/FrontFontSizes";
import { WeightMedium } from "../../../WeightMedium/WeightMedium";
import { FrontRadioCard } from "../FrontRadioCard/FrontRadioCard";
import { FrontSpacing } from "../../../../models/FrontSpacing";

export const FrontRadioCardList = <A extends string, B extends A>(props: {
    value: B | null,
    options: Array<{
        value: A,
        label: string,
        children: JSX.Element,
    }>
    onChange: (value: typeof props.options[number]["value"]) => void,
}): JSX.Element =>
    <SpacingColumn spacing="25px">
        {pipe(
            props.options,
            array.map((option) =>
                <FrontRadioCard
                    key={option.value}
                    value={option.value === props.value}
                    label={option.label}
                    onChange={() => props.onChange(option.value)}
                >
                    {option.children}
                </FrontRadioCard>
            ),
            array.intersperse(<Or />),
        )}
    </SpacingColumn>;

const Or = () =>
    <SpacingRow
        justifyContent="start"
        alignItems="center"
        childSize="1fr min-content 1fr"
        spacing={FrontSpacing.SMALL_2}
    >
        {/* LINE */}
        <HorizontalLine color={FrontColors.NEUTRAL_8} />
        
        {/* OR */}
        <FontQuicksand>
            <Text
                size={FrontFontSizes.MED}
                color={FrontColors.NEUTRAL_8}
            >
                <WeightMedium>
                    Or
                </WeightMedium>
            </Text>
        </FontQuicksand>

        {/* LINE */}
        <HorizontalLine color={FrontColors.NEUTRAL_8} />
    </SpacingRow>;
