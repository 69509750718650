import React from "react";
import { CRMCardOutsideSharpCorners } from "../../CRMCardOutsideSharpCorners/CRMCardOutsideSharpCorners";

interface ITableProps<T> {
    data: Array<T>,
    rowElement: (row: T) => JSX.Element
};

export const CRMTable = <T extends unknown>(props: React.PropsWithChildren<ITableProps<T>>): JSX.Element => {

    return (
        <div className={`crm-table`}>
            <CRMCardOutsideSharpCorners shadow={true}>
                {props.data.map((row, index) => (
                    <div
                        key={index}
                        className="crm-table__row"
                    >
                        {props.rowElement(row)}
                    </div>
                ))}
            </CRMCardOutsideSharpCorners>
        </div>
    );
};
