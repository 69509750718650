import React, { useEffect, useState } from "react";
import { TrueRoadblockType, RoadblocksFilters, TRoadblockType, TRoadblocksForm } from "../../../../../domain/codecs/form/RoadblockForm";
import { TStaffUser } from "../../../../../domain/codecs/User";
import { CRMDropdownColourComponent, ICRMDropdownColourOption } from "../../CRMDropdownColourComponent/CRMDropdownColourComponent";
import { IOption } from "../../../hooks/UseDropdown";
import { pipe } from "fp-ts/lib/pipeable";
import { array } from "fp-ts";
import { capitaliseFirst, underscoreCaseToNormalCase } from "../../../../../shared/src/utilsByDomain/string";
import { onChangeForm } from "../../../../../shared/src/codecs/types/form";
import { SpacingRow } from "../../BuildingBlocks/SpacingRow";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { CRMParagraph } from "../../Simple/CRMParagraph/CRMParagraph";
import { WeightBold } from "../../WeightBold/WeightBold";
import { CRMRichFilterDropdown_Pill, TRichFilterDropdown_PillOption } from "../../Complex/CRMRichFilterDropdown_Pill/CRMRichFilterDropdown_Pill";
import { JobTypes, TJobType } from "../../../../../domain/JobType";
import { contains } from "../../../../../shared/src/utilsByDomain/array";
import { TJobsFilter } from "../../../../../domain/codecs/statePartials/JobsPartial";
import { SHJobFilterOptions, SLJobFilterOptions, SPJobFilterOptions, jobTypeToPrettyText } from "./JobsFIlterOptions";
import { RoadblockFilterOptions, RoadblockType } from "./RoadblocksFilterOptoins";
import { getCurrentBrandHostnameAndEnv } from "../../../../../domain/models/Enviroment";
import { TJobsFormIO } from "../../../../../domain/codecs/formIO/JobsIO";

const EnvName = getCurrentBrandHostnameAndEnv("web", window.location.hostname);

const FilterOptions = 
    EnvName === "sailhomes"
        ? SHJobFilterOptions
    : EnvName === "sailprobate"
        ? SPJobFilterOptions
    : [...RoadblockFilterOptions, ...SLJobFilterOptions]

const IsSailLegal = EnvName === "saillegal";


export type TJobsRoadblocksTableFilterProps = {
    form: TRoadblocksForm;
    jobsFilter: TJobsFilter;
    staffUsers: Array<TStaffUser>;
    jobsForm: TJobsFormIO;

    onChangeRoadblocks: (form: TRoadblocksForm) => void;
    onChangeJobs: (filter: TJobsFilter) => void;
};

export const CRMJobsRoadblocksTableFilter = (props: TJobsRoadblocksTableFilterProps) => {

    const [SelectedTypes, setSelectedTypes] = useState<Array<string>>([]);

    useEffect(
        () => {
            let selectedType = props.form.edited.roadblockType || props.jobsFilter.filter_by_type;
            setSelectedTypes(selectedType ? [selectedType] : []);
        },
        [props.form, props.jobsFilter]
    );

    const ActionabilityOptions = pipe(
        RoadblocksFilters.payload[0].payload.actionableStatus.payload,
        array.map((item) => ({
            value: item.payload,
            label: underscoreCaseToNormalCase(item.payload),
        })),
        colorOptions
    );

    const AssignedToOptions = pipe(
        props.staffUsers,
        array.map((user) => ({
            value: user.id,
            label: userToNameCopy(user),
        })),
        array.prepend({
            value: "anyone",
            label: "Anyone",
        }),
        colorOptions
    );

    const CaseHandlerOptions = pipe(
        props.jobsForm.output.filterable_case_users,
        array.map((user) => ({
            value: user.id,
            label: userToNameCopy(user)
        })),
        array.prepend({
            value: "anyone",
            label: "Anyone",
        }),
        colorOptions
    )

    const AssignedToUserId = 
        props.form.edited.assignedToUserId === null
            ? "anyone"
            : props.form.edited.assignedToUserId
    ;
    const HandledByUserId = 
        props.jobsFilter.filter_by_handler === null
            ? "anyone"
            : props.jobsFilter.filter_by_handler
    ;


    const onChangeRoadblocks = onChangeForm(props.form, props.onChangeRoadblocks);
    const onChangeRoadblockActionability = onChangeRoadblocks("actionableStatus");
    const onChangeRoadblockType = onChangeRoadblocks("roadblockType");
    const onChangeRoadblockAssignedTo = onChangeRoadblocks("assignedToUserId");
    const resetRoadblocksType = () => onChangeRoadblockType(null);
    const isARoadblockType = (value: string) => pipe(RoadblockType, contains(value));

    const onChangeJobType = (type: TJobType | null) => props.onChangeJobs({
        ...props.jobsFilter,
        filter_by_type: type
    });
    const onChangeJobAssignedTo = (userID: string | null) => props.onChangeJobs({
        ...props.jobsFilter,
        filter_by_user: userID,
    });
    const onChangeJobHandledBy = (userID: string | null) => props.onChangeJobs({
        ...props.jobsFilter,
        filter_by_handler: userID,
    });
    const resetJobsFilterType = () => onChangeJobType(null);
    const isAJobType = (value: string) => pipe(JobTypes, contains(value));

    const onChangeType = (value: Array<string>) => {
        let selectedType = value[0] || null;

        if (!selectedType) {
            resetJobsFilterType();
            resetRoadblocksType();
        }

        if (!!selectedType && isARoadblockType(selectedType)) {;
            onChangeRoadblockType(selectedType);
            resetJobsFilterType();
        }
        
        if (!!selectedType && isAJobType(selectedType)) {
            onChangeJobType(selectedType as TJobType);
            resetRoadblocksType();
        }
    }
   
    const onChangeAssignedTo = (value: string) => {
        let assignedTo = value === "anyone" ? null : value;
        onChangeRoadblockAssignedTo(assignedTo);
        onChangeJobAssignedTo(assignedTo);
    }
    
    const onChangeHandledBy = (value: string) => {
        let assignedTo = value === "anyone" ? null : value;
        onChangeJobHandledBy(assignedTo);
    }

    return (
        <SpacingRow spacing={CRMSpacing.TINY}>
            
            {/* FILTER - TYPE */}
            {/* <FilterText>Show:</FilterText> */}
            <CRMRichFilterDropdown_Pill
                selected={SelectedTypes}
                options={FilterOptions}
                onChange={onChangeType}
                convertOptionValueToFaceText={(value) => jobTypeToPrettyText(value as TJobType)}
            />

            {/* FILTER - ACTIONABILITY */}
            {/* <FilterText>rows, which are </FilterText> */}
            <FilterText>, </FilterText>
            <CRMDropdownColourComponent
                options={ActionabilityOptions}
                value={props.form.edited.actionableStatus}
                onChange={onChangeRoadblockActionability}
            />

            {/* FILTER - ASSIGNED TO */}
            <FilterText>rows, for:</FilterText>
            <CRMDropdownColourComponent
                options={AssignedToOptions}
                value={AssignedToUserId}
                onChange={onChangeAssignedTo}
            />

            {IsSailLegal &&
                <SpacingRow spacing={CRMSpacing.TINY}>
                    <FilterText>, handled by (jobs only): </FilterText>
                    <CRMDropdownColourComponent
                        options={CaseHandlerOptions}
                        value={HandledByUserId}
                        onChange={onChangeHandledBy}
                    />
                </SpacingRow>
            }
        </SpacingRow>
    );
}

const FilterText = (props: React.PropsWithChildren<{}>) => {
    return (
        <CRMParagraph>
            <WeightBold>{props.children}</WeightBold>
        </CRMParagraph>
    );
}

const colorOptions = <T extends string>(options: Array<IOption<T>>): Array<ICRMDropdownColourOption<T>> => pipe(
    options,
    array.map((option) => ({
        ...option,
        highlightColour: "primary-13",
        fontColour: "neutral-ink"
    }))
);

const userToNameCopy = (user: TStaffUser) => 
    user.first_name && user.last_name
        ? `${user.first_name} ${user.last_name}`
        : user.email 
        ?? "-- --"
;