import React from "react";
import { CRMConfirmationCountdown } from "../CRMConfirmationCountdown/CRMConfirmationCountdown";

type TStatusVariableConfirmationCountdownProps<T extends string> = {
    status: T;
    onUndo: () => void;
    variations: { 
        [key in T]?: [string, () => void]
    }
};

export const CRMStatusVariableConfirmationCountdown = <T extends string>(props: TStatusVariableConfirmationCountdownProps<T>) => {
    
    const getVariation = () =>
        props.variations[props.status] || ["", () => null]
    ;

    const getTitle = () => getVariation()[0];

    const onComplete = () => getVariation()[1]();

    return (
        <CRMConfirmationCountdown
            title={getTitle()}
            iconName="delete"
            primaryButtonMode="done"
            onUndo={props.onUndo}
            onComplete={onComplete}
        />
    );
}
