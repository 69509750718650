import React from "react";
import { Relative } from "../../BuildingBlocks/Relative";
import { CRMIconLabelAndMonoText } from "../../CRMIconLabelAndMonoText/CRMIconLabelAndMonoText";
import { getFormattedDateString } from "../../../../../domain/models/ISODateTime";
import { DateTime } from "luxon";
import { CRMColors } from "../../../models/CRMColors";
import { useOpenClose } from "../../../hooks/UseOpenClose";
import { CursorPointer } from "../../CursorPointer/CursorPointer";
import { Absolute } from "../../BuildingBlocks/Absolute";
import { CRMDeferPopout } from "../../CrmDeferPopout/CRMDeferPopout";
import { AnimationPopout } from "../../BuildingBlocks/AnimationPopout";
import { CRMZIndex } from "../../../models/CRMZIndex";

export const CRMInlineTextCalendarDefer = (props: {
    value: string | null,
    onChange: (value: string | null) => void,
}): JSX.Element => {
    const {
        ref,
        isOpen,
        setIsOpen,
    } = useOpenClose();

    const displayText =
        props.value
            ? getFormattedDateString(DateTime.fromISO(props.value).toJSDate(), "dd/MM/yyyy")
            : "Set date";

    const colour =
        props.value
            ? CRMColors.NEUTRAL_INK
            : CRMColors.NEUTRAL_2;

    const iconName =
        isOpen
            ? "up-arrow"
            : "down-arrow";

    const onChange = (value: string | null) => {
        props.onChange(value);
        setIsOpen(false);
    };

    return <Relative
        divRef={ref}
        width="fit-content"
        zIndex={CRMZIndex.SECOND_Z}
    >
        {/* LABEL */}
        <CursorPointer onClick={() => setIsOpen(true)}>
            <CRMIconLabelAndMonoText
                label=""
                displayText={displayText}
                iconAlignment="end"
                iconTooltipText="Set date"
                colour={colour}
                iconName={iconName}
                iconOnClick={() => setIsOpen(true)}
            />
        </CursorPointer>

        {/* POPUP */}
        {isOpen &&
            <Absolute
                top="20px"
                right="0px"
            >
                <AnimationPopout>
                    <CRMDeferPopout
                        options="only_date"
                        value={props.value}
                        onChange={onChange}
                    />
                </AnimationPopout>
            </Absolute>
        }
    </Relative>
};
