import React, { useState } from "react";
import { CRMButtonTertiary } from "../CRMButtonTertiary/CRMButtonTertiary";
import { CRMCardInside } from "../CRMCardInside/CRMCardInside";
import { CRMPadding } from "../Simple/CRMPadding/CRMPadding";
import { CRMParagraph } from "../Simple/CRMParagraph/CRMParagraph";
import { SpacingRow } from "../BuildingBlocks/SpacingRow";
import { CRMSpacer } from "../CRMSpacer/CRMSpacer";
import { CRMTitleParagraph } from "../CRMTitleParagraph/CRMTitleParagraph";
import { TCaseMemberSuggestedSimilarUserForm } from "../../../../domain/codecs/form/CaseMemberForm";
import { pipe } from "fp-ts/lib/function";
import { array, option } from "fp-ts";
import { CRMCardOutsidePopupFormSubmit } from "../CRMCardOutsidePopupFormSubmit/CRMCardOutsidePopupFormSubmit";
import { SpacingColumn } from "../BuildingBlocks/SpacingColumn";
import { CRMFormErrorComponent } from "../CRMFormErrorComponent/CRMFormErrorComponent";
import { CRMUniversalBulletPoints } from "../CRMUniversalBulletPoints/CRMUniversalBulletPoints";
import { Eq } from "fp-ts/lib/string";
import { TListingSellerSuggestedSimilarUserForm } from "../../../../domain/codecs/form/ListingSellerUserEditForm";
import { CRMSpacing } from "../../models/CRMSpacing";
import { doesErrorCodeExist, doesErrorKeyExist } from "../../../../shared/src/codecs/errors";

export const CRMClientSuggestion = (props: {
    context: "case" | "listing-seller-party";
    form: TCaseMemberSuggestedSimilarUserForm | TListingSellerSuggestedSimilarUserForm
    onUse: (f: TCaseMemberSuggestedSimilarUserForm | TListingSellerSuggestedSimilarUserForm) => void;
}): JSX.Element => {
    const [popupIsOpen, setPopupIsOpen] = useState<boolean>(false);

    const errors = (): Array<string> => []
        pipe(
            props.form.validationErrors,
            array.map(([err,error]) => error),
            array.uniq(Eq),
        );

    return (
        <>
            <CRMCardInside>
                <CRMPadding size="medium">
                    {/* TITLE */}
                    <CRMTitleParagraph>
                        Matches:&nbsp;<u>{pipe(
                            [
                                props.form.children.first_name,
                                props.form.children.last_name,
                            ],
                            array.filter((name) => name ? true : false),
                            (a) => a.join(" "),
                        )}</u>
                    </CRMTitleParagraph>
                    <CRMSpacer size="tiny"/>

                    {/* EMAIL */}
                    <CRMParagraph>
                        <b>{props.form.children.email}</b>
                    </CRMParagraph>
                    <CRMSpacer size="medium"/>

                    {/* BUTTONS */}
                    <SpacingRow justifyContent="end">
                        <CRMButtonTertiary
                            label={`Add this user${
                                props.context === "case" ? " to case"
                                : props.context === "listing-seller-party" ? " to seller party"
                                : ""
                            }`}
                            onClick={() => setPopupIsOpen(true)}
                            tabIndex={-1}
                        />
                    </SpacingRow>
                </CRMPadding>
            </CRMCardInside>

            {/* SWAP USER POPUP */}
            <CRMCardOutsidePopupFormSubmit
                isOpen={popupIsOpen}
                title="Are you sure?"
                context="important"
                onClose={() => setPopupIsOpen(false)}
                closeText="Cancel"
                onCTA={() => props.onUse(props.form)}
                ctaText="Yes"
                formStatus={
                    props.form.status === "notFound" || props.form.status === "twoFactorRequired" || props.form.status === "unauthorised" ? "failure"
                    : props.form.status === "untouched" ? "requiresSubmission"
                    : props.form.status
                }
                validationErrors={[]}
            >
                <SpacingColumn spacing={CRMSpacing.MEDIUM}>
                    <CRMParagraph>
                        Are you sure you want to add this user{props.context === "case" ? " to the case" : props.context === "listing-seller-party" ? " to the seller party" : ""}? The user who is currently selected will also be removed.
                    </CRMParagraph>

                    {props.form.validationErrors.length > 0 && <div>
                        <CRMFormErrorComponent errorMessage="We weren't able to remove the old user and add the new one for the following reasons" />
                        <CRMSpacer size="tiny" />
                        <CRMUniversalBulletPoints
                            size="normal"
                            points={[
                                ...(errors().includes("users__cases") ? [<CRMParagraph>The new user already exists in the case</CRMParagraph>] : []),
                                ...(errors().includes("cases_bank_accounts") ? [<CRMParagraph>The old user can't be removed as their details are being used in a bank account on this case</CRMParagraph>] : []),
                                ...(doesErrorKeyExist("users__parties", props.form.validationErrors) && doesErrorCodeExist("NotUnique", props.form.validationErrors) ? [<CRMParagraph>The user you're trying to add already exists in this seller party</CRMParagraph>] : []),
                                ...(doesErrorKeyExist("users__parties", props.form.validationErrors) && doesErrorCodeExist("InUse", props.form.validationErrors) ? [<CRMParagraph>The old user can't be removed this way as their details are being used in another seller party or buyer enquiry party. Remove this user from the party using the menu button next to their name, then 'Add Person' to add the new user to this party</CRMParagraph>] : []),
                            ]}
                        />
                    </div>}
                </SpacingColumn>
            </CRMCardOutsidePopupFormSubmit>
        </>
    );
}
