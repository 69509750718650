import React, { useState } from "react";
import { SpacingColumn } from "../../components/BuildingBlocks/SpacingColumn";
import { FrontQuestionFormCard } from "../../components/Front/Complex/FrontQuestionFormCard/FrontQuestionFormCard";
import FrontParagraphSmall from "../../components/Front/Simple/FrontParagraphSmall/FrontParagraphSmall";
import { TContainerStateProps } from "../../state/TContainerStateProps";
import { FrontSpacing } from "../../models/FrontSpacing";
import { WeightBold } from "../../components/WeightBold/WeightBold";
import { MaxWidth } from "../../components/BuildingBlocks/MaxWidth";
import { FrontFormSectionTitle } from "../../components/Front/Simple/FrontFormSectionTitle/FrontFormSectionTitle";
import { FrontFormPrimaryButton } from "../../components/Front/Simple/FrontFormPrimaryButton/FrontFormPrimaryButton";
import { TOnboardingTabStatus } from "../../components/Front/Simple/FrontOnboardingTab/FrontOnboardingTab";
import { FrontInfoBubble } from "../../components/Front/Simple/FrontInfoBubble/FrontInfoBubble";
import { FrontColors } from "../../models/FrontColors";
import { FrontPopupPrompt } from "../../components/Front/Complex/FrontPopupPrompt/FrontPopupPrompt";
import FrontParagraphRegular from "../../components/Front/Simple/FrontParagraphRegular/FrontParagraphRegular";
import { FrontHelpBubble } from "../../components/Front/Simple/FrontHelpBubble/FrontHelpBubble";
import { ClientCaseSdltForm } from "../../../../domain/codecs/form/ClientCaseForm";
import { FrontCondensedRadioQuestions } from "../../components/Front/Simple/FrontCondensedRadioQuestions/FrontCondensedRadioQuestions";
import { FrontBulletpoint } from "../../components/Front/Simple/FrontBulletpoint/FrontBulletpoint";
import { WeightMedium } from "../../components/WeightMedium/WeightMedium";
import { FrontCondensedTextareaQuestion } from "../../components/Front/Simple/FrontCondensedTextareaQuestion/FrontCondensedTextareaQuestion";
import { requireExhaustive } from "../../../../shared/src/util";
import { MediaCompSwitch } from "../../components/BuildingBlocks/MediaCompSwitch";
import { SpacingRow } from "../../components/BuildingBlocks/SpacingRow";
import { sailLegalBrandConstants } from "../../../../domain/constants";

export const sdltQuestionLabel_sdlt_transaction_results_in_owning_two_or_more = (transactionTypeDisplayString: string): string => `Will this ${transactionTypeDisplayString} result in you owning (or part owning) two or more dwellings?`;
export const sdltQuestionLabel_sdlt_spouse_or_partner_owning_other_property = (): string => `Will you be living with a spouse or civil partner who owns (or part owns) another dwelling?`;
export const sdltQuestionLabel_sdlt_joint_buyers_owning_other_property = (): string => `After this property transaction is complete will one of the other buyers still own (or part own) another dwelling?`;
export const sdltQuestionLabel_sdlt_child_owns_other_property = (): string => `Do you have a child (under 18) who owns (or part owns) another dwelling?`;
export const sdltQuestionLabel_sdlt_other_properties_value_supporting_notes = (): string => `For the questions you answered yes to above, how much are these dwellings worth?`;
export const sdltQuestionLabel_sdlt_will_be_main_residence = (isTransactingOnOwn: boolean): string =>
    isTransactingOnOwn
        ? "Will you live in the property as your only or main residence?"
        : "Will you all live in the property as your only or main residence?";
export const sdltQuestionLabel_sdlt_transaction_replaces_main_residence = (): string => `Have you just sold (or are selling) a property which you were using as your only or main residence?`;
export const sdltQuestionLabel_sdlt_in_partnership_business_owning_other_property = (): string => `Are you a partner in a partnership business that owns (or part owns) a dwelling?`;
export const sdltQuestionLabel_sdlt_is_shared_ownership = (transactionTypeDisplayString: string): string => `Is your ${transactionTypeDisplayString} part of a "shared ownership" scheme in which a housing association or other such organisation will own a share of the property?`;
export const sdltQuestionLabel_sdlt_all_first_time_buyers = (isTransactingOnOwn: boolean): string =>
    isTransactingOnOwn
        ? `Do you regard yourself as a "first-time buyer"?`
        : `Do you all consider yourselves to be "first-time buyers"?`;
export const sdltQuestionLabel_sdlt_any_other_properties_on_land = (): string => `Does the land (that you will own) of this new property have more than one dwelling?`;
export const sdltQuestionLabel_sdlt_everyone_resides_in_uk = (isTransactingOnOwn: boolean): string =>
    isTransactingOnOwn
        ? "Have you resided in the UK for at least 183 days in the last 12 months?"
        : "Have you all resided in the UK for at least 183 days in the last 12 months?";

const FrontSdltQuestions  = (props: TContainerStateProps): JSX.Element => {
    const [displayValidationErrorsIfPresent, setDisplayValidationErrorsIfPresent] = useState(false);

    const [submitFormOpen, setSubmitFormOpen] = useState(false);
    
    const getClientNames = () => getSdltQuestions(props).children.client_names;

    const isFormReadyToSubmit = (): boolean =>
        getSdltQuestions(props).edited.sdlt_transaction_results_in_owning_two_or_more !== "unknown"
        && getSdltQuestions(props).edited.sdlt_spouse_or_partner_owning_other_property !== "unknown"
        && (
            isTransactingOnOwn()
            || (
                !isTransactingOnOwn()
                && getSdltQuestions(props).edited.sdlt_joint_buyers_owning_other_property !== "unknown"
            )
        )
        && getSdltQuestions(props).edited.sdlt_child_owns_other_property !== "unknown"
        && (
            !shouldShowOtherPropertiesValueQuestion()
            || (
                shouldShowOtherPropertiesValueQuestion()
                && getSdltQuestions(props).edited.sdlt_other_properties_value_supporting_notes
            )
        )
        && getSdltQuestions(props).edited.sdlt_will_be_main_residence !== "unknown"
        && (
            !shouldShowDoesThisReplaceAPropertySoldUsedAsMainResidence()
            || (
                shouldShowDoesThisReplaceAPropertySoldUsedAsMainResidence()
                && getSdltQuestions(props).edited.sdlt_transaction_replaces_main_residence !== "unknown"
            )
        )
        && getSdltQuestions(props).edited.sdlt_in_partnership_business_owning_other_property !== "unknown"
        && getSdltQuestions(props).edited.sdlt_is_shared_ownership !== "unknown"
        && getSdltQuestions(props).edited.sdlt_all_first_time_buyers !== "unknown"
        && getSdltQuestions(props).edited.sdlt_any_other_properties_on_land !== "unknown"
        && getSdltQuestions(props).edited.sdlt_everyone_resides_in_uk !== "unknown"
            ? true
            : false;

    const taxType = getSdltQuestions(props).children.sdlt_or_ltt;

    const getTaxAbbreviation = () =>
        taxType === "sdlt" ? "SDLT"
        : taxType === "ltt" ? "LTT"
        : requireExhaustive(taxType);

    const getTaxShortName = () =>
        taxType === "sdlt" ? "Stamp Duty"
        : taxType === "ltt" ? "Land Transaction Tax"
        : requireExhaustive(taxType);

    const getTaxFullName = () =>
        taxType === "sdlt" ? "Stamp Duty Land Tax"
        : taxType === "ltt" ? "Land Transaction Tax"
        : requireExhaustive(taxType);

    const getTransactionTypeDisplayString = () =>
        props.state.forms.client_case_page.children.case.transaction_type === "purchase" ? "purchase"
        : props.state.forms.client_case_page.children.case.transaction_type === "transfer" ? "transfer of ownership"
        : "transaction";

    const isTransactingOnOwn = (): boolean =>
        getSdltQuestions(props).children.single_client_transacting;

    const shouldShowOtherPropertiesValueQuestion = (): boolean =>
        // Check they qualify to see this question
        (
            getSdltQuestions(props).edited.sdlt_transaction_results_in_owning_two_or_more === "yes"
            || (
                isTransactingOnOwn()
                && getSdltQuestions(props).edited.sdlt_spouse_or_partner_owning_other_property === "yes"
            )
            || (
                !isTransactingOnOwn()
                && getSdltQuestions(props).edited.sdlt_joint_buyers_owning_other_property === "yes"
            )
            || getSdltQuestions(props).edited.sdlt_child_owns_other_property === "yes"
        )
        // Check we don't show this question until the preceding questions have been answered
        && (
            getSdltQuestions(props).edited.sdlt_transaction_results_in_owning_two_or_more !== "unknown"
            && (
                (
                    isTransactingOnOwn()
                    && getSdltQuestions(props).edited.sdlt_spouse_or_partner_owning_other_property !== "unknown"
                )
                || (
                    !isTransactingOnOwn()
                    && getSdltQuestions(props).edited.sdlt_joint_buyers_owning_other_property !== "unknown"
                )
            )
            && getSdltQuestions(props).edited.sdlt_child_owns_other_property !== "unknown"
        );

    const shouldShowDoesThisReplaceAPropertySoldUsedAsMainResidence = (): boolean =>
        getSdltQuestions(props).edited.sdlt_will_be_main_residence === "yes";

    const sdltQuestionsSubmissionStatus = getSdltQuestions(props).children.status;

    const areChangesLocked = (): boolean =>
        sdltQuestionsSubmissionStatus === "client_not_submitted" || sdltQuestionsSubmissionStatus === "needs_resubmission_due_to_client_change" ? false
        : sdltQuestionsSubmissionStatus === "client_submitted" ? true
        : requireExhaustive(sdltQuestionsSubmissionStatus);

    const needsResubmissionDueToClientChange = (): boolean => sdltQuestionsSubmissionStatus === "needs_resubmission_due_to_client_change";

    const questionWillYouOwnTwoOrMoreDwellings = () =>
        <FrontCondensedRadioQuestions
            value={
                getSdltQuestions(props).edited.sdlt_transaction_results_in_owning_two_or_more === "unknown"
                    ? null
                    : getSdltQuestions(props).edited.sdlt_transaction_results_in_owning_two_or_more
            }
            isEditable={true}
            label={sdltQuestionLabel_sdlt_transaction_results_in_owning_two_or_more(getTransactionTypeDisplayString())}
            options={[
                {
                    label: "Yes",
                    value: "yes"
                },
                {
                    label: "No",
                    value: "no"
                },
                {
                    label: "I don't know",
                    value: "dont_know"
                },
            ]}
            onChange={(sdlt_transaction_results_in_owning_two_or_more) =>
                props.dispatch({
                    type: "CLIENT_UPDATE_SDLT_QUESTIONS",
                    payload: {
                        ...getSdltQuestions(props),
                        edited: {
                            ...getSdltQuestions(props).edited,
                            sdlt_transaction_results_in_owning_two_or_more,
                        }
                    }
                })
            }
            displayValidationErrorsIfPresent={displayValidationErrorsIfPresent}
            guidance={<SpacingColumn spacing={FrontSpacing.SMALL_1}>
                <div>Some examples of this include:</div>
                <div style={{marginLeft: FrontSpacing.SMALL_2}}>
                    <FrontBulletpoint>
                        Another property anywhere in the world
                    </FrontBulletpoint>
                    <FrontBulletpoint>
                        A holiday let in Spain
                    </FrontBulletpoint>
                    <FrontBulletpoint>
                        Part owning a property with someone else
                    </FrontBulletpoint>
                    <FrontBulletpoint>
                        A property owned by a trust of which I am a beneficiary
                    </FrontBulletpoint>
                    <FrontBulletpoint>
                        A property inherited
                    </FrontBulletpoint>
                </div>
            </SpacingColumn>}
        />;

    const questionDoesYourLiveInSpouseOrPartnerHaveADwelling = () =>
        <FrontCondensedRadioQuestions
            value={
                getSdltQuestions(props).edited.sdlt_spouse_or_partner_owning_other_property === "unknown"
                    ? null
                    : getSdltQuestions(props).edited.sdlt_spouse_or_partner_owning_other_property
            }
            isEditable={true}
            label={sdltQuestionLabel_sdlt_spouse_or_partner_owning_other_property()}
            options={[
                {
                    label: "Yes",
                    value: "yes"
                },
                {
                    label: "No",
                    value: "no"
                },
                {
                    label: "I don't know",
                    value: "dont_know"
                },
            ]}
            onChange={(sdlt_spouse_or_partner_owning_other_property) =>
                props.dispatch({
                    type: "CLIENT_UPDATE_SDLT_QUESTIONS",
                    payload: {
                        ...getSdltQuestions(props),
                        edited: {
                            ...getSdltQuestions(props).edited,
                            sdlt_spouse_or_partner_owning_other_property,
                        }
                    }
                })
            }
            displayValidationErrorsIfPresent={displayValidationErrorsIfPresent}
        />;

    const questionWillASharedOwnerHaveASecondDwelling = () =>
        <FrontCondensedRadioQuestions
            value={
                getSdltQuestions(props).edited.sdlt_joint_buyers_owning_other_property === "unknown"
                    ? null
                    : getSdltQuestions(props).edited.sdlt_joint_buyers_owning_other_property
            }
            isEditable={true}
            label={sdltQuestionLabel_sdlt_joint_buyers_owning_other_property()}
            options={[
                {
                    label: "Yes",
                    value: "yes"
                },
                {
                    label: "No",
                    value: "no"
                },
                {
                    label: "I don't know",
                    value: "dont_know"
                },
            ]}
            onChange={(sdlt_joint_buyers_owning_other_property) =>
                props.dispatch({
                    type: "CLIENT_UPDATE_SDLT_QUESTIONS",
                    payload: {
                        ...getSdltQuestions(props),
                        edited: {
                            ...getSdltQuestions(props).edited,
                            sdlt_joint_buyers_owning_other_property,
                        }
                    }
                })
            }
            displayValidationErrorsIfPresent={displayValidationErrorsIfPresent}
        />;

    const questionDoesYourChildOwnADwelling = () =>
        <FrontCondensedRadioQuestions
            value={
                getSdltQuestions(props).edited.sdlt_child_owns_other_property === "unknown"
                    ? null
                    : getSdltQuestions(props).edited.sdlt_child_owns_other_property
            }
            isEditable={true}
            label={sdltQuestionLabel_sdlt_child_owns_other_property()}
            options={[
                {
                    label: "Yes",
                    value: "yes"
                },
                {
                    label: "No",
                    value: "no"
                },
                {
                    label: "I don't know",
                    value: "dont_know"
                },
            ]}
            onChange={(sdlt_child_owns_other_property) =>
                props.dispatch({
                    type: "CLIENT_UPDATE_SDLT_QUESTIONS",
                    payload: {
                        ...getSdltQuestions(props),
                        edited: {
                            ...getSdltQuestions(props).edited,
                            sdlt_child_owns_other_property,
                        }
                    }
                })
            }
            displayValidationErrorsIfPresent={displayValidationErrorsIfPresent}
            guidance={<>
                This also includes the child of your spouse or civil partner, if you are living with your spouse or civil partner.
            </>}
        />;

    const questionOtherPropertyValues = () =>
        <FrontCondensedTextareaQuestion
            value={getSdltQuestions(props).edited.sdlt_other_properties_value_supporting_notes}
            isEditable={true}
            label={sdltQuestionLabel_sdlt_other_properties_value_supporting_notes()}
            onChange={(sdlt_other_properties_value_supporting_notes) =>
                props.dispatch({
                    type: "CLIENT_UPDATE_SDLT_QUESTIONS",
                    payload: {
                        ...getSdltQuestions(props),
                        edited: {
                            ...getSdltQuestions(props).edited,
                            sdlt_other_properties_value_supporting_notes,
                        }
                    }
                })
            }
            displayValidationErrorsIfPresent={displayValidationErrorsIfPresent}
            guidance={<SpacingColumn spacing={FrontSpacing.SMALL_1}>
                <div>For each dwelling tell us how much you think the person's share in it is currently worth.</div>
                <div><WeightMedium>Don't worry, this doesn't need to be exactly correct.</WeightMedium> Estimates are fine, you might choose to tell us the last purchase price and how long ago you bought it or you could use a Zoopla estimate. Just tell us how you were able to estimate it.</div>
                <div><WeightBold>If a dwelling is only part owned by a person just tell us how much their share is worth.</WeightBold></div>
            </SpacingColumn>}
        />;

    const questionWillYouLiveInPropertyAsMainResidence = () =>
        <FrontCondensedRadioQuestions
            value={
                getSdltQuestions(props).edited.sdlt_will_be_main_residence === "unknown"
                    ? null
                    : getSdltQuestions(props).edited.sdlt_will_be_main_residence
            }
            isEditable={true}
            label={sdltQuestionLabel_sdlt_will_be_main_residence(isTransactingOnOwn())}
            options={[
                {
                    label: "Yes",
                    value: "yes"
                },
                {
                    label: "No",
                    value: "no"
                },
                {
                    label: "I don't know",
                    value: "dont_know"
                },
            ]}
            onChange={(sdlt_will_be_main_residence) =>
                props.dispatch({
                    type: "CLIENT_UPDATE_SDLT_QUESTIONS",
                    payload: {
                        ...getSdltQuestions(props),
                        edited: {
                            ...getSdltQuestions(props).edited,
                            sdlt_will_be_main_residence,
                        }
                    }
                })
            }
            displayValidationErrorsIfPresent={displayValidationErrorsIfPresent}
        />;

    const questionDoesThisReplaceAPropertySoldUsedAsMainResidence = () =>
        <FrontCondensedRadioQuestions
            value={
                getSdltQuestions(props).edited.sdlt_transaction_replaces_main_residence === "unknown"
                    ? null
                    : getSdltQuestions(props).edited.sdlt_transaction_replaces_main_residence
            }
            isEditable={true}
            label={sdltQuestionLabel_sdlt_transaction_replaces_main_residence()}
            options={[
                {
                    label: "Yes",
                    value: "yes"
                },
                {
                    label: "No",
                    value: "no"
                },
                {
                    label: "I don't know",
                    value: "dont_know"
                },
            ]}
            onChange={(sdlt_transaction_replaces_main_residence) =>
                props.dispatch({
                    type: "CLIENT_UPDATE_SDLT_QUESTIONS",
                    payload: {
                        ...getSdltQuestions(props),
                        edited: {
                            ...getSdltQuestions(props).edited,
                            sdlt_transaction_replaces_main_residence,
                        }
                    }
                })
            }
            displayValidationErrorsIfPresent={displayValidationErrorsIfPresent}
        />;

    const questionAreYouAPartnerInAPartnershipWhichOwnsADwelling = () =>
        <FrontCondensedRadioQuestions
            value={
                getSdltQuestions(props).edited.sdlt_in_partnership_business_owning_other_property === "unknown"
                    ? null
                    : getSdltQuestions(props).edited.sdlt_in_partnership_business_owning_other_property
            }
            isEditable={true}
            label={sdltQuestionLabel_sdlt_in_partnership_business_owning_other_property()}
            options={[
                {
                    label: "Yes",
                    value: "yes"
                },
                {
                    label: "No",
                    value: "no"
                },
                {
                    label: "I don't know",
                    value: "dont_know"
                },
            ]}
            onChange={(sdlt_in_partnership_business_owning_other_property) =>
                props.dispatch({
                    type: "CLIENT_UPDATE_SDLT_QUESTIONS",
                    payload: {
                        ...getSdltQuestions(props),
                        edited: {
                            ...getSdltQuestions(props).edited,
                            sdlt_in_partnership_business_owning_other_property,
                        }
                    }
                })
            }
            displayValidationErrorsIfPresent={displayValidationErrorsIfPresent}
        />;

    const questionSharedOwnership = () =>
        <FrontCondensedRadioQuestions
            value={
                getSdltQuestions(props).edited.sdlt_is_shared_ownership === "unknown"
                    ? null
                    : getSdltQuestions(props).edited.sdlt_is_shared_ownership
            }
            isEditable={true}
            label={sdltQuestionLabel_sdlt_is_shared_ownership(getTransactionTypeDisplayString())}
            options={[
                {
                    label: "Yes",
                    value: "yes"
                },
                {
                    label: "No",
                    value: "no"
                },
                {
                    label: "I don't know",
                    value: "dont_know"
                },
            ]}
            onChange={(sdlt_is_shared_ownership) =>
                props.dispatch({
                    type: "CLIENT_UPDATE_SDLT_QUESTIONS",
                    payload: {
                        ...getSdltQuestions(props),
                        edited: {
                            ...getSdltQuestions(props).edited,
                            sdlt_is_shared_ownership,
                        }
                    }
                })
            }
            displayValidationErrorsIfPresent={displayValidationErrorsIfPresent}
        />;

    const questionFirstTimeBuyer = () =>
        <FrontCondensedRadioQuestions
            value={
                getSdltQuestions(props).edited.sdlt_all_first_time_buyers === "unknown"
                    ? null
                    : getSdltQuestions(props).edited.sdlt_all_first_time_buyers
            }
            isEditable={true}
            label={sdltQuestionLabel_sdlt_all_first_time_buyers(isTransactingOnOwn())}
            options={[
                {
                    label: "Yes",
                    value: "yes"
                },
                {
                    label: "No",
                    value: "no"
                },
                {
                    label: "I don't know",
                    value: "dont_know"
                },
            ]}
            onChange={(sdlt_all_first_time_buyers) =>
                props.dispatch({
                    type: "CLIENT_UPDATE_SDLT_QUESTIONS",
                    payload: {
                        ...getSdltQuestions(props),
                        edited: {
                            ...getSdltQuestions(props).edited,
                            sdlt_all_first_time_buyers,
                        }
                    }
                })
            }
            displayValidationErrorsIfPresent={displayValidationErrorsIfPresent}
            guidance={<SpacingColumn spacing={FrontSpacing.SMALL_1}>
                <div>A first-time buyer is someone who:</div>
                <div style={{marginLeft: FrontSpacing.SMALL_2}}>
                    <FrontBulletpoint>
                        Has never previously owned (or part owned) a dwelling anywhere in the world
                    </FrontBulletpoint>
                    <FrontBulletpoint>
                        Never had a share in a property
                    </FrontBulletpoint>
                    <FrontBulletpoint>
                        Never inherited a property
                    </FrontBulletpoint>
                    <FrontBulletpoint>
                        Never received a property as a gift
                    </FrontBulletpoint>
                </div>
            </SpacingColumn>}
        />;

    const questionMoreThanOneDwellingOnLand = () =>
        <FrontCondensedRadioQuestions
            value={
                getSdltQuestions(props).edited.sdlt_any_other_properties_on_land === "unknown"
                    ? null
                    : getSdltQuestions(props).edited.sdlt_any_other_properties_on_land
            }
            isEditable={true}
            label={sdltQuestionLabel_sdlt_any_other_properties_on_land()}
            options={[
                {
                    label: "Yes",
                    value: "yes"
                },
                {
                    label: "No, just the main property",
                    value: "no"
                },
                {
                    label: "I don't know",
                    value: "dont_know"
                },
            ]}
            onChange={(sdlt_any_other_properties_on_land) =>
                props.dispatch({
                    type: "CLIENT_UPDATE_SDLT_QUESTIONS",
                    payload: {
                        ...getSdltQuestions(props),
                        edited: {
                            ...getSdltQuestions(props).edited,
                            sdlt_any_other_properties_on_land,
                        }
                    }
                })
            }
            displayValidationErrorsIfPresent={displayValidationErrorsIfPresent}
            guidance={<SpacingColumn spacing={FrontSpacing.SMALL_1}>
                <div>This could mean that in addition to the main property on the land there is a separate property with its own sleeping areas, access and bathroom. Some examples could include:</div>
                <div style={{marginLeft: FrontSpacing.SMALL_2}}>
                    <FrontBulletpoint>
                        A Granny annex in the garden
                    </FrontBulletpoint>
                    <FrontBulletpoint>
                        An Additional cottage on the land
                    </FrontBulletpoint>
                    <FrontBulletpoint>
                        A basement flat below the main property
                    </FrontBulletpoint>
                </div>
                <WeightBold>If you will own a single flat (with one dwelling) you should answer "No, just the main property". This question does not include other flats in the block owned by others.</WeightBold>
            </SpacingColumn>}
        />;

    const questionAllResidedInUkFor183Days = () =>
        <FrontCondensedRadioQuestions
            value={
                getSdltQuestions(props).edited.sdlt_everyone_resides_in_uk === "unknown"
                    ? null
                    : getSdltQuestions(props).edited.sdlt_everyone_resides_in_uk
            }
            isEditable={true}
            label={sdltQuestionLabel_sdlt_everyone_resides_in_uk(isTransactingOnOwn())}
            options={[
                {
                    label: "Yes",
                    value: "yes"
                },
                {
                    label: "No",
                    value: "no"
                },
                {
                    label: "I don't know",
                    value: "dont_know"
                },
            ]}
            onChange={(sdlt_everyone_resides_in_uk) =>
                props.dispatch({
                    type: "CLIENT_UPDATE_SDLT_QUESTIONS",
                    payload: {
                        ...getSdltQuestions(props),
                        edited: {
                            ...getSdltQuestions(props).edited,
                            sdlt_everyone_resides_in_uk,
                        }
                    }
                })
            }
            displayValidationErrorsIfPresent={displayValidationErrorsIfPresent}
        />;

    const controlAndErrors = () =>
        <SpacingColumn spacing={FrontSpacing.MEDIUM_1}>
            <MediaCompSwitch
                DesktopComponent={SpacingRow}
                desktopProps={{ spacing: FrontSpacing.SMALL_3}}
                MobileComponent={SpacingColumn}
                mobileProps={{ spacing: FrontSpacing.SMALL_3 }}
                breakpoint="600"
            >
                <FrontFormPrimaryButton
                    label="Continue"
                    icon="done"
                    isDisabled={false}
                    onClick={() =>
                        isFormReadyToSubmit()
                            ? setSubmitFormOpen(true)
                            : setDisplayValidationErrorsIfPresent(true)
                    }
                />
            </MediaCompSwitch>

            {displayValidationErrorsIfPresent && !isFormReadyToSubmit() && <FrontInfoBubble color={FrontColors.ERROR_FIREBRICK_22}>
                <WeightBold>You've missed a few things above,</WeightBold> please take a look and then try again.
            </FrontInfoBubble>}
        </SpacingColumn>

    return (
        <div>
            <FrontQuestionFormCard
                title={getTaxShortName()}
            >
                <MaxWidth width="700px">
                    {/* CHANGES TO ANSWERS LOCKED */}
                    {areChangesLocked() && <SpacingColumn spacing={FrontSpacing.MEDIUM_1}>
                        <FrontFormSectionTitle>
                            You've answered the {getTaxShortName()} questions.
                        </FrontFormSectionTitle>
                        <FrontParagraphSmall>
                            If you need to make any changes to your answers just call us on {sailLegalBrandConstants().legalCSNumber}.
                        </FrontParagraphSmall>
                    </SpacingColumn>}

                    {/* USER CAN ANSWER STAMP DUTY QUESTIONS */}
                    {!areChangesLocked() && <SpacingColumn spacing={FrontSpacing.LARGE_1}>
                        {/* INTRODUCTION */}
                        <SpacingColumn spacing={FrontSpacing.SMALL_3}>
                            {/* NEEDS RESUBMISSION DUE TO CLIENT CHANGE */}
                            {needsResubmissionDueToClientChange() && <FrontHelpBubble
                                type="pending"
                                label="We need you to check if anything has changed"
                                labelIcon="search"
                                paragraph={<SpacingColumn spacing={FrontSpacing.SMALL_3}>
                                    <div>
                                        Although you've already submitted your {getTaxShortName()} answers to us we can now see that the client details have changed.
                                    </div>

                                    <div>
                                        <WeightBold>We need you to take a moment to check that the details we have below are still correct, and then resubmit your answers.</WeightBold>
                                    </div>
                                </SpacingColumn>}
                            />}

                            <FrontParagraphSmall>
                                <WeightBold>{getTaxFullName()} ({getTaxAbbreviation()}) is a tax that some people have to pay when purchasing or becoming an owner of a property.</WeightBold>
                            </FrontParagraphSmall>

                            <FrontParagraphSmall>
                                Not everyone will pay the same {getTaxShortName()} though, by answering the questions below we'll be able to help you understand if you need to pay.
                            </FrontParagraphSmall>

                            <FrontHelpBubble
                                type="blank"
                                label="What is a dwelling?"
                                paragraph={<SpacingColumn spacing={FrontSpacing.SMALL_1}>
                                    <div>A dwelling is a building <WeightMedium>or part of a building</WeightMedium> that is suitable to be lived in independently (it has its own sleeping areas, access and bathroom).</div>
                                    <div>Many people only have a single dwelling on their property but some of the below examples show how a single property can have multiple dwellings:</div>
                                    <div style={{marginLeft: FrontSpacing.SMALL_2}}>
                                        <FrontBulletpoint>
                                            A house with a granny annex in the garden
                                        </FrontBulletpoint>
                                        <FrontBulletpoint>
                                            A farm house with a cottage on the land
                                        </FrontBulletpoint>
                                        <FrontBulletpoint>
                                            A basement flat below a house
                                        </FrontBulletpoint>
                                    </div>
                                </SpacingColumn>}
                            />
                        </SpacingColumn>

                        {/* SECTION: QUESTIONS */}
                        <SpacingColumn spacing={FrontSpacing.MEDIUM_1}>
                            <FrontFormSectionTitle>
                                {getTaxShortName()} Questions
                            </FrontFormSectionTitle>

                            {/* QUESTIONS */}
                            {questionWillYouOwnTwoOrMoreDwellings()}

                            {questionDoesYourLiveInSpouseOrPartnerHaveADwelling()}

                            {!isTransactingOnOwn() && questionWillASharedOwnerHaveASecondDwelling()}

                            {questionDoesYourChildOwnADwelling()}

                            {shouldShowOtherPropertiesValueQuestion() && questionOtherPropertyValues()}

                            {questionWillYouLiveInPropertyAsMainResidence()}

                            {shouldShowDoesThisReplaceAPropertySoldUsedAsMainResidence() && questionDoesThisReplaceAPropertySoldUsedAsMainResidence()}

                            {questionAreYouAPartnerInAPartnershipWhichOwnsADwelling()}

                            {questionSharedOwnership()}

                            {questionFirstTimeBuyer()}

                            {questionMoreThanOneDwellingOnLand()}

                            {questionAllResidedInUkFor183Days()}

                            {/* CONTROL */}
                            {controlAndErrors()}
                            
                        </SpacingColumn>
                    </SpacingColumn>}
                </MaxWidth>
            </FrontQuestionFormCard>

            <FrontPopupPrompt
                title="Before we submit your answers"
                isOpen={submitFormOpen}
                onCTA={() => props.dispatch({
                    type: "CLIENT_SUBMIT_SDLT_QUESTIONS",
                    payload: getSdltQuestions(props),
                })}
                ctaText={`I (${getClientNames()}) agree`}
                closeText="Close"
                onClose={() => setSubmitFormOpen(false)}
            >
                <SpacingColumn spacing={FrontSpacing.SMALL_3}>
                    <FrontParagraphRegular>
                       <WeightBold>Please check the following statement and confirm you, {getClientNames()}, agree to it.</WeightBold>
                    </FrontParagraphRegular>
                    <FrontParagraphRegular>
                        I am aware that my tax affairs are my liability. As to the nature of my {getTaxAbbreviation()} liability I understand this is also the case.
                    </FrontParagraphRegular>
                    <FrontParagraphRegular>
                        I accept that if any circumstances come to light that make me liable to the additional {getTaxAbbreviation()} surcharge the liability rests with me.
                    </FrontParagraphRegular>
                    <FrontParagraphRegular>
                        I further declare that the information given by me is correct and complete to the best of my knowledge and belief and understand that
                        I may have to pay financial penalties and face prosecution if I have given false information which results in an incorrect {getTaxAbbreviation()} submission
                        being made by me or on my behalf.
                    </FrontParagraphRegular>
                </SpacingColumn>
            </FrontPopupPrompt>
        </div>
    );
};

const getSdltQuestions = (props: TContainerStateProps) =>
    props.state.forms.client_case_page.children.sdlt_questions || ClientCaseSdltForm.newDefault();

export const getSdltTabStatus = (props: TContainerStateProps): TOnboardingTabStatus => {
    if (getSdltQuestions(props).children.status === "client_submitted") {
        return "completed";
    }

    return "active";
};

export default FrontSdltQuestions;
