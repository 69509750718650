import React from "react";
import * as TFormStatus from "../../models/TFormStatus";
import * as JsonError1 from "../../../../domain/models/JsonError1";
import * as User8 from "../../../../domain/models/User8";
import * as UserPhoneNumber4 from "../../../../domain/models/UserPhoneNumber4";
import CRMInputGeneralComponent from "../CRMInputs/CRMInputGeneralComponent/CRMInputGeneralComponent";
import CRMInputLabelAndErrorWrapComponent from "../CRMInputLabelAndErrorWrapComponent/CRMInputLabelAndErrorWrapComponent";
import { CRMInputPhoneNumbersComponent_OLDE } from "../CRMInputPhoneNumbersComponent_OLDE/CRMInputPhoneNumbersComponent_OLDE";
import { CRMInputCheckboxComponent } from "../CRMInputCheckboxComponent/CRMInputCheckboxComponent";
import { CRMSpacingColumn } from "../CRMSpacingColumn/CRMSpacingColumn";
import { TUser8Form, TUserPhoneNumber5AndC4FormList } from "../../models/TFormModels";

export type TInputNames = "first-name" | "last-name" | "email";

type TCRMHomeEnquiryPartyMemberForm = {
    personForm: TUser8Form;
    phoneNumbers: TUserPhoneNumber5AndC4FormList["forms"];
    createPhoneNumberStatus: TFormStatus.T;
    showLegalBuyerField?: boolean;
    onChange: (value: User8.T[keyof User8.T], key: keyof User8.T) => void;
    onFocus?: (inputName: TInputNames) => void;
    onBlur?: () => void;
    onAddNewPhoneNumber: () => void;
    onChangePhoneNumber: (value: UserPhoneNumber4.T[keyof UserPhoneNumber4.T], key: keyof UserPhoneNumber4.T, resourceId: string) => void;
    onRemovePhoneNumber: (id: string) => void;
};

export const CRMPartyMemberForm_OLDE = (props: TCRMHomeEnquiryPartyMemberForm): JSX.Element => {

    const callOnFocusIfGiven = (inputName: TInputNames) =>
        () => {
            if (typeof props.onFocus === "function") {
                props.onFocus(inputName);
            }
        };

    return (
        <CRMSpacingColumn spacing="medium">
            {/* FIRST NAME & DISASSOCIATE */}
            <CRMInputLabelAndErrorWrapComponent label="First name">
                <CRMInputGeneralComponent
                    inputType="text"
                    displayError={JsonError1.doTargetKeysHaveInnerErrors(["first_name"], props.personForm.validationErrors)}
                    placeholder="John"
                    value={props.personForm.edit.first_name}
                    onChange={(value) => props.onChange(value, "first_name")}
                    onFocus={callOnFocusIfGiven("first-name")}
                    onBlur={props.onBlur}
                />
            </CRMInputLabelAndErrorWrapComponent>

            {/* LAST NAME */}
            <CRMInputLabelAndErrorWrapComponent label="Last name">
                <CRMInputGeneralComponent
                    inputType="text"
                    displayError={JsonError1.doTargetKeysHaveInnerErrors(["last_name"], props.personForm.validationErrors)}
                    placeholder="Doe"
                    value={props.personForm.edit.last_name}
                    onChange={(value) => props.onChange(value, "last_name")}
                    onFocus={callOnFocusIfGiven("last-name")}
                    onBlur={props.onBlur}
                />
            </CRMInputLabelAndErrorWrapComponent>

            {/* EMAIL */}
            <CRMInputLabelAndErrorWrapComponent label="Email">
                <CRMInputGeneralComponent
                    inputType="email"
                    displayError={JsonError1.doTargetKeysHaveInnerErrors(["email"], props.personForm.validationErrors)}
                    placeholder="john@doe.com"
                    value={props.personForm.edit.email || ""}
                    onChange={(value) => props.onChange(value || null, "email")}
                    onFocus={callOnFocusIfGiven("email")}
                    onBlur={props.onBlur}
                />
            </CRMInputLabelAndErrorWrapComponent>

            {props.showLegalBuyerField &&
                <CRMInputCheckboxComponent
                    label="They are the legal buyer"
                    onClick={()=> props.onChange(!props.personForm.edit.is_legal_buyer, "is_legal_buyer")}
                    checked={props.personForm.edit.is_legal_buyer || false}
                />
            }

            {/* DESCRIPTION OF USER */}
            {!props.personForm.edit.is_legal_buyer && <CRMInputLabelAndErrorWrapComponent
                label="Relationship to buyer"
                guidance="e.g. parent / friend ect."
                labelFontWeight="medium"
            >
                <CRMInputGeneralComponent
                    inputType="text"
                    displayError={JsonError1.doTargetKeysHaveInnerErrors(["description_of_user"], props.personForm.validationErrors)}
                    placeholder=""
                    value={props.personForm.edit.description_of_user}
                    onChange={(value) => props.onChange(value, "description_of_user")}
                />
            </CRMInputLabelAndErrorWrapComponent>}

            {/* PHONE NUMBERS */}
            <CRMInputLabelAndErrorWrapComponent label="Phone number">
                <CRMInputPhoneNumbersComponent_OLDE
                    placeholder="Add phone number"
                    phoneNumbers={props.phoneNumbers}
                    createPhoneNumberStatus={props.createPhoneNumberStatus}
                    onAddNew={props.onAddNewPhoneNumber}
                    onChange={props.onChangePhoneNumber}
                    onRemove={props.onRemovePhoneNumber}
                />
            </CRMInputLabelAndErrorWrapComponent>
        </CRMSpacingColumn>
    );
};
