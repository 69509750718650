import { ReactNode } from "react";

export const reactChildrenToArray = (children: ReactNode): Array<ReactNode> => {
    if (Array.isArray(children)) {
        return children;
    }

    if (!!children) {
        return [children];
    }

    return [];
}