import React from "react";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { SpacingColumn } from "../../BuildingBlocks/SpacingColumn";
import { SpacingRow } from "../../BuildingBlocks/SpacingRow";
import { CRMIcon, TIcon, TIconSizes } from "../../CRMIcon/CRMIcon";
import { TextCenter } from "../../../components/TextCenter/TextCenter";
import { WeightMedium } from "../../../components/WeightMedium/WeightMedium";
import { CRMFontSizes } from "../../../models/CRMFontSizes";
import { CRMColors } from "../../../models/CRMColors";
import { FontQuicksand } from "../../../components/BuildingBlocks/FontQuicksand";
import { FontSize } from "../../../components/BuildingBlocks/FontSize";
import { TextColor } from "../../../components/BuildingBlocks/TextColor";
import { THexColor } from "../../../models/StringLiterals";

type TCRMEmptyPlaceholder = {
    iconSize?: TIconSizes;
    iconColor?: THexColor;
    fontSize?: string | number;
    fontColor?: THexColor;
    iconName?: TIcon;
    textGap?: string;
};

export const CRMEmptyPlaceholder = (props: React.PropsWithChildren<TCRMEmptyPlaceholder>): JSX.Element => {

    return (
        <SpacingColumn spacing={props.textGap || CRMSpacing.LARGE}>
            {/* WIND ICON */}
            <SpacingRow justifyContent="center">
                <CRMIcon
                    iconName={props.iconName || "air"}
                    size={props.iconSize || "300px"}
                    colour="hex"
                    hexColour={props.iconColor || CRMColors.NEUTRAL_6}
                />
            </SpacingRow>
            {/* TEXT */}
            <TextCenter>
                <FontQuicksand>
                    <FontSize size={props.fontSize || CRMFontSizes.MED}>
                        <TextColor color={props.fontColor || CRMColors.NEUTRAL_2}>
                            <WeightMedium>
                                {props.children}
                            </WeightMedium>
                        </TextColor>
                    </FontSize>
                </FontQuicksand>
            </TextCenter>
        </SpacingColumn>
    );
};
