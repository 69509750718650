import React, { useEffect, useState } from "react";
import { array } from "fp-ts";
import { TContainerStateProps } from "../state/TContainerStateProps";
import { pipe } from "fp-ts/lib/function";
import { CRMLink } from "../components/CRMLink/CRMLink";
import { CRMSpacingColumn } from "../components/CRMSpacingColumn/CRMSpacingColumn";
import { arrayOfStringsToPipeString } from "../../../shared/src/util";
import { CRMPageWrap } from "../components/Simple/CRMPageWrap/CRMPageWrap";
import { CRMPageBody } from "../components/Simple/CRMPageBody/CRMPageBody";
import { CRMFilterNav } from "../components/CRMFilterNav/CRMFilterNav";
import { CRMListingPagePrepContainer } from "../containers/CRMListingPagePrepContainer/CRMListingPagePrepContainer";
import { CRMListingPageProgressionContainer } from "../containers/CRMListingPageProgressionContainer/CRMListingPageProgressionContainer";
import { CRMListingFormPrepSections, CRMListingProgressionFormSections, CRMListingFormSections, CRMListingSellerDetailsSections, CRMListingAllProgressionFormSections } from "../models/CRMFormSections";
import { CRMSpacing } from "../models/CRMSpacing";
import { Padding } from "../components/BuildingBlocks/Padding";
import { CRMHomesMenuBarContainer } from "../containers/CRMHomesMenuBarContainer/CRMHomesMenuBarContainer";
import { snakeCaseToCopyText } from "../../../shared/src/util";
import CRMListingPageSellersContainer from "../containers/CRMListingPageSellersContainer/CRMListingPageSellersContainer";
import { getUrlStringForRoute } from "../state/router/getUrlStringsForRoute";
import { paths } from "../state/router/routerPaths";

type TListingTabs = "unset" | "seller_details" | "prep" | "progression" | "retool";

const CRMListingPage = (props: TContainerStateProps): JSX.Element => {

    const [selectedTab, setSelectedTab] = useState<TListingTabs>("unset");

    useEffect(
        () => {
            if (selectedTab === "unset") {
                setSelectedTab(getSelectedTab())
            }
        },
        [props.state.routes.queryParams.VIEW_CRM_LISTING.visible_sections]
    );

    const getSelectedTab = (): TListingTabs => {
        if (isFilteredToSellerDetails()) {
            return "seller_details";
        }
        if (isFilteredToProgression()) {
            return "progression";
        }
        if (isFilteredToPrep()) {
            return "prep";
        }
        if (hasTabSelected()) {
            return props.state.routes.queryParams.VIEW_CRM_LISTING.visible_tab as unknown as TListingTabs;
        }
        return "prep";
    }
    
    const hasAnAcceptedOffer = (): boolean =>
        props.state.forms.listing_page.data.output.read_only.listing_has_offer_accepted;
    
    const acceptedOfferIsMortgage = (): boolean =>
        props.state.forms.listing_page.data.output.read_only.buyer_payment_method === "mortgage";

    const hasTabSelected = (): boolean => (
        props.state.routes.queryParams.VIEW_CRM_LISTING.visible_tab !== undefined && 
        (
            props.state.routes.queryParams.VIEW_CRM_LISTING.visible_tab === "prep" ||
            props.state.routes.queryParams.VIEW_CRM_LISTING.visible_tab === "seller_details" ||
            props.state.routes.queryParams.VIEW_CRM_LISTING.visible_tab === "progression"
        )
    );
    
    const isFilteredToProgression = () =>
        CRMListingFormSections.areSectionsSelected(
            props.state.routes.queryParams.VIEW_CRM_LISTING.visible_sections,
            [
                "progression_buyer_solicitor_kick_off",
                "progression_seller_solicitor_kick_off",
                "progression_mortgage_broker_kick_off",
                "progression_buyer_own_survey",
                "progression_mortgage_application_submitted",
                "progression_mortgage_survey",
                "progression_mortgage_offer_received",
                "progression_buyer_conveyancer_has_everything_required_to_start",
                "progression_buyer_conveyancer_received_contracts",
                "progression_buyer_conveyancer_raised_enquiries",
                "progression_all_enquiries_resolved",
                "progression_exchange_date",
                "progression_completed_date",
                "progression_keys_released_to_buyer",
            ],
        )
    ;

    const isFilteredToSellerDetails = () =>
        CRMListingFormSections.areSectionsSelected(
            props.state.routes.queryParams.VIEW_CRM_LISTING.visible_sections,
            [
                "sellers"
            ],
        )
    ;

    const isFilteredToPrep = () =>
        CRMListingFormSections.areSectionsSelected(
            props.state.routes.queryParams.VIEW_CRM_LISTING.visible_sections,
            [
                "address",
                "specifics",
                "price_and_costs",
                "selling_process",
                "competing_agent",
                "conveyancing",
                "key_management",
            ],
        )
    ;

    const getProgressionTabFilters = (): [string, string][] =>
        pipe(
            acceptedOfferIsMortgage() ?
                CRMListingAllProgressionFormSections.list :
                CRMListingProgressionFormSections.list,
            array.map((key) => [key, snakeCaseToCopyText(key.replace("progression_", ""))])
        )
    ;

    const onSelectTab = (tab: TListingTabs, listingId: string) => {
        if (tab !== "retool") {
            setSelectedTab(tab);
            dispatchSectionFilterUpdate([]);
            props.dispatch({
                type: "LISTING_UPDATE_VISIBLE_TAB",
                payload: tab
            })
        } else {
            window.open(`https://tools.saillegal.co.uk/listings/${listingId}/bounce`, '_blank');
        }
    }

    const dispatchSectionFilterUpdate = (filters: Array<string>) => props.dispatch({
        type: "UPDATE_LISTING_VISIBLE_SECTIONS_FILTER",
        payload: arrayOfStringsToPipeString(filters)
    });

    

    return (
        <CRMPageWrap>
            <CRMHomesMenuBarContainer {...props} />

            {/* LISTING SUB NAV */}
            <CRMFilterNav<TListingTabs>
                selected={selectedTab}
                selectedFilters={CRMListingFormSections.sectionsStringToArray(props.state.routes.queryParams.VIEW_CRM_LISTING.visible_sections)}
                popoutText={`${props.state.forms.listing_page.data.output.read_only.address_short_string} (${props.state.forms.listing_page.data.output.read_only.status})`}
                buttons={[
                    {
                        label: "Seller details",
                        value: "seller_details",
                        filters: pipe(
                            CRMListingSellerDetailsSections.list,
                            array.map((key): [string, string] => [key, snakeCaseToCopyText(key)]),
                        ),
                        isDisabled: false,
                    },
                    {
                        label: "Preparation",
                        value: "prep",
                        filters: pipe(
                            CRMListingFormPrepSections.list,
                            array.map((key): [string, string] => [key, snakeCaseToCopyText(key)]),
                        ),
                        isDisabled: false,
                    },
                    {
                        label: "Progression",
                        value: "progression",
                        filters: getProgressionTabFilters(),
                        isDisabled: !hasAnAcceptedOffer(),
                    },
                    {
                        label: "Retool",
                        value: "retool",
                        filters: [],
                        isDisabled: false,
                    },
                ]}
                onChange={(tab) => onSelectTab(tab, props.state.routes.params.listingId)}
                onChangeFilters={dispatchSectionFilterUpdate}
            >
               
                {/* SUB NAV LINKS */}
                <Padding spacing={CRMSpacing.MEDIUM}>
                    <CRMSpacingColumn spacing="medium">
                        <CRMLink
                            href={
                                getUrlStringForRoute(
                                    paths,
                                    "VIEW_LISTING",
                                    { listingId: props.state.forms.listing_page.data.output.form.edited.id },
                                    {}
                                )
                            }
                            linkStyle="normal"
                        >
                            Public listing
                        </CRMLink>
                        <CRMLink
                            href={
                                getUrlStringForRoute(
                                    paths,
                                    "VIEW_CRM_PARTY",
                                    { partyId: props.state.forms.listing_page.data.output.read_only.sellers_party_id },
                                    {}
                                )
                            }
                            linkStyle="normal"
                        >
                            Seller's party
                        </CRMLink>
                        <CRMLink
                            href={
                                getUrlStringForRoute(
                                    paths,
                                    "VIEW_CRM_LISTING_PERFORMANCE",
                                    { listingId: props.state.forms.listing_page.data.output.form.edited.id },
                                    {}
                                )
                            }
                            linkStyle="normal"
                        >
                            Performance and offers
                        </CRMLink>
                    </CRMSpacingColumn>
                </Padding>
            </CRMFilterNav>
            
            {/* BODY */}
            <CRMPageBody>
                <Padding type="vertical" spacing={CRMSpacing.LARGE}>
                    {selectedTab === "prep" && 
                        <CRMListingPagePrepContainer
                            state={props.state}
                            dispatch={props.dispatch}
                        />
                    }

                    {selectedTab === "seller_details" && 
                        <CRMListingPageSellersContainer
                            state={props.state}
                            dispatch={props.dispatch}
                        />
                    }

                    {selectedTab === "progression" && 
                        <CRMListingPageProgressionContainer
                            state={props.state}
                            dispatch={props.dispatch}
                        />
                    }
                </Padding>
            </CRMPageBody>
        </CRMPageWrap>
    );
}

export default CRMListingPage;