import React from "react";
import * as ListingEnquiriesViewing2 from "../../../../domain/models/ListingEnquiriesViewing2";
import { CRMSpacer } from "../../components/CRMSpacer/CRMSpacer";
import { array } from "fp-ts";
import { pipe } from "fp-ts/lib/function";
import { CRMParagraph } from "../../components/Simple/CRMParagraph/CRMParagraph";
import { CRMPadding } from "../../components/Simple/CRMPadding/CRMPadding";
import CRMUpcomingViewingMiniComponent from "../../components/CRMUpcomingViewingMiniComponent/CRMUpcomingViewingMiniComponent";

class CRMListingPerformancePageUpcomingViewingsContainer extends React.Component<{
    viewings: Array<ListingEnquiriesViewing2.T>;
}> {

    public render (): JSX.Element {
        return (
            <div className="crm-listing-performance-page-upcoming-viewings-container">
                {/* CARD OF EACH UPCOMING VIEWING */}
                {this.props.viewings.length ?
                     pipe(
                        ListingEnquiriesViewing2.sortToSimpleViewingArray(this.props.viewings, "desc", "upcoming"),
                        array.mapWithIndex((i, viewing) =>
                            <div key={i} className="crm-listing-performance-page-upcoming-viewings-container__item">
                                <CRMUpcomingViewingMiniComponent
                                    viewing={viewing}
                                />
                                <CRMSpacer size="medium" />
                            </div>
                        )
                    )
                    // IF THERE ARE NO VIEWINGS
                    : <CRMPadding size="tiny" direction="left">
                        <CRMParagraph>
                            No upcoming viewings
                        </CRMParagraph>
                    </CRMPadding>
                }
            </div>
        );
    }
}

export default CRMListingPerformancePageUpcomingViewingsContainer;
