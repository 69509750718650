import React from "react";
import { CRMColors } from "../../../../models/CRMColors";
import { THexColor } from "../../../../models/StringLiterals";
import { Shape } from "../../../BuildingBlocks/Shape";
import { SpacingRow } from "../../../BuildingBlocks/SpacingRow";
import { CRMIcon, TIcon } from "../../../CRMIcon/CRMIcon";

type TCRMIconCircle = {
    icon: TIcon;
    iconColor?: THexColor;
    circleColor?: THexColor;
}

export const CRMEntityBoxIcon = (props: TCRMIconCircle) => {
    return (
        <Shape 
            width="34px" 
            height="34px"
            color={props.circleColor || CRMColors.NEUTRAL_10}
            borderRadius="50px"
        >
            <SpacingRow
                height="34px"
                justifyContent="center" 
                alignItems="center"
            >
                <CRMIcon 
                    iconName={props.icon}
                    colour="hex"
                    hexColour={props.iconColor || CRMColors.NEUTRAL_INK}
                    size="20px"
                />
            </SpacingRow>
        </Shape>
    )
}
