import React from "react";
import { array } from "fp-ts";
import { pipe } from "fp-ts/lib/pipeable";
import { TCaseAdHocJobNewForm } from "../../../../../domain/codecs/form/CaseAdHocJobForms";
import { TTriageEmailAssignToCaseWithAdhocForm } from "../../../../../domain/codecs/form/TriageForm";
import { TUserAssignableToAdhoc } from "../../../../../domain/codecs/User";
import { onChangeForm } from "../../../../../shared/src/codecs/types/form";
import { isAFunction } from "../../../functions/functions";
import { IOption } from "../../../hooks/UseDropdown";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { SpacingColumn } from "../../BuildingBlocks/SpacingColumn";
import { SpacingRow } from "../../BuildingBlocks/SpacingRow";
import { CRMButtonSecondary } from "../../CRMButtonSecondary/CRMButtonSecondary";
import { CRMButtonTertiary } from "../../CRMButtonTertiary/CRMButtonTertiary";
import { CRMDropdownComponent } from "../../CRMDropdownComponent/CRMDropdownComponent";
import CRMInputLabelAndErrorWrapComponent from "../../CRMInputLabelAndErrorWrapComponent/CRMInputLabelAndErrorWrapComponent";
import CRMTextAreaComponent from "../../CRMTextAreaComponent/CRMTextAreaComponent";


type TCreateAdHocTaskProps = {
    form: TTriageEmailAssignToCaseWithAdhocForm | TCaseAdHocJobNewForm;
    taskButtonLabel: string;
    onChange: (form: TTriageEmailAssignToCaseWithAdhocForm | TCaseAdHocJobNewForm) => void;
    onCancel?: () => void;
    onSubmit: () => void;
};

export const CRMCreateAdHocTaskForm = (props: React.PropsWithChildren<TCreateAdHocTaskProps>): JSX.Element => {

    const onChange = onChangeForm(props.form, props.onChange);

    const assignableUsersToDropdownOptions = (): Array<IOption<string>> =>
        pipe(
            props.form.children.users,
            array.map<TUserAssignableToAdhoc, IOption<string>>((user) => ({
                value: user.id,
                label: user.full_name,
            })),
        );

    const hasSubmissionFailed = () => (
        props.form.status === "failure" || 
        props.form.status === "validationError"
    );

    const canSubmit = (): boolean => (
        !!props.form.edited.user_id &&
        !!props.form.edited.job
    );

    return (
        <SpacingColumn spacing={CRMSpacing.LARGE}>
            <SpacingColumn spacing={CRMSpacing.MEDIUM}>
                {/* SELECT PERSON */}
                <CRMInputLabelAndErrorWrapComponent
                    label="Select person"
                    displayError={hasSubmissionFailed()}
                    errorMessage="Something has gone wrong with your submission, please try again."
                >
                    <CRMDropdownComponent
                        options={assignableUsersToDropdownOptions()}
                        value={props.form.edited.user_id}
                        displayError={false}
                        onChange={onChange("user_id")}
                    />
                </CRMInputLabelAndErrorWrapComponent>
                
                {/* TASK NOTE */}
                <CRMInputLabelAndErrorWrapComponent
                    label="Note"
                >
                    <CRMTextAreaComponent
                        value={props.form.edited.job}
                        placeholder="Enter task note"
                        onChange={onChange("job")}
                    />
                </CRMInputLabelAndErrorWrapComponent>
            </SpacingColumn>

            {/* BUTTONS */}
            <SpacingRow spacing={CRMSpacing.TINY} justifyContent="end">
                {/* BUTTON - CANCEL */}
                {isAFunction<() => void>(props.onCancel) &&
                    <CRMButtonSecondary
                        label="Cancel"
                        onClick={props.onCancel}
                    />
                }
                {/* BUTTON - SUBMIT */}
                <CRMButtonTertiary
                    icon="job"
                    disabled={!canSubmit()}
                    label={props.taskButtonLabel}
                    onClick={props.onSubmit}
                />
            </SpacingRow>
        </SpacingColumn>
    );
};
