import React from "react";
import CRMInputLabelAndErrorWrapComponent from "../CRMInputLabelAndErrorWrapComponent/CRMInputLabelAndErrorWrapComponent";
import { CRMInputCalendarComponent } from "../CRMInputCalendarComponent/CRMInputCalendarComponent";
import { TCRMFormInputWithoutCodec } from "./CRMFormInputTypes";

export const CRMCalendarDateTimeFormInput: TCRMFormInputWithoutCodec<string> = (props) => 
<CRMInputLabelAndErrorWrapComponent label={props.label}>
    <CRMInputCalendarComponent
        dateType={"datetime-local"}
        value={props.model}
        onChange={(value) => props.onChange(value)}
        onPressEnterKey={() => null}
        displayError={props.displayError}
        disabled={props.disabled}
    />
</CRMInputLabelAndErrorWrapComponent>