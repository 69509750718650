import React, { useState } from "react";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { CRMColors } from "../../../models/CRMColors";
import { Padding } from "../../BuildingBlocks/Padding";
import { Relative } from "../../BuildingBlocks/Relative";
import { Shape } from "../../BuildingBlocks/Shape";
import { SpacingRow } from "../../BuildingBlocks/SpacingRow";
import { CRMIcon, TIcon } from "../../CRMIcon/CRMIcon";
import CRMInputGeneralComponent from "../../CRMInputs/CRMInputGeneralComponent/CRMInputGeneralComponent";

type TExpandingSearchFilterProps = {
    firstInputValue: string;
    firstInputPlaceholder: string;
    onChangeFirstInput: (value: string) => void;
    secondInputValue: string;
    secondInputPlaceholder: string;
    onChangeSecondInput: (value: string) => void;
    secondInputIcon: TIcon;
    secondInputIconHint: string;
    onPressEnter: () => void; 
};

export const CRMDualExpandingSearchInputs = (props: React.PropsWithChildren<TExpandingSearchFilterProps>): JSX.Element => {
    
    const [isSearchingWithSecondInput, setIsFilteringByAssignmentType] = useState(false);

    const onFocusAssignmentTypeInput = () => {
        setIsFilteringByAssignmentType(true);
    }
    
    const onBlurAssignmentTypeInput = () => {
        if (props.secondInputValue.length === 0) {
            setIsFilteringByAssignmentType(false);
        }
    }

    return (
        <div 
            style={{
                width: "100%",
                display: "flex"
            }}
        >
            <SpacingRow 
                spacing={CRMSpacing.TINY}
                childSize="1fr auto"
            >
                <Relative>
                    <CRMInputGeneralComponent
                        inputType="text"
                        placeholder={props.firstInputPlaceholder}
                        value={props.firstInputValue}
                        displayError={false}
                        onChange={props.onChangeFirstInput}
                        onPressEnterKey={props.onPressEnter}
                        style={{
                            width: isSearchingWithSecondInput ? "50%" : "100%",
                        }}
                        autoFocus={true}
                    />
                    
                    <div 
                        style={{
                            position: "absolute",
                            right: "0px",
                            width: isSearchingWithSecondInput ? "50%" : "1%",
                            paddingLeft: CRMSpacing.TINY,
                            opacity: isSearchingWithSecondInput ? "1" : "0",
                            transition: "all 300ms",
                        }}
                    >
                        <CRMInputGeneralComponent
                            inputType="text"
                            placeholder={props.secondInputPlaceholder}
                            value={props.secondInputValue}
                            displayError={false}
                            onFocus={onFocusAssignmentTypeInput}
                            onBlur={onBlurAssignmentTypeInput}
                            onChange={props.onChangeSecondInput}
                            onPressEnterKey={props.onPressEnter}
                            style={{
                                cursor: isSearchingWithSecondInput ? "inherit" : "default",
                            }}
                        />
                    </div>
                </Relative>
                
                {!isSearchingWithSecondInput &&
                    <SpacingRow title={props.secondInputIconHint}>
                        {/* RIGHT SEPERATOR */}
                        <Shape
                            height="34px"
                            width="3px"
                            color={CRMColors.NEUTRAL_4}
                        />
                        
                        <Padding type="horizontal" spacing={CRMSpacing.TINY}>
                            <CRMIcon
                                iconName={props.secondInputIcon}
                                colour="neutral-4"
                            />
                        </Padding>
                    </SpacingRow>
                }
            </SpacingRow>
        </div>
    );
};
