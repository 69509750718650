import React from "react";
import { TClientCompanyPartnershipContractForm, TClientCompanyPartnershipContractUploadForm, TClientCompanyPartnershipForm, TClientCompanyPartnershipUserForm, TClientCompanyPartnershipUserPhoneNumberCreateForm, TClientCompanyPartnershipUserPhoneNumberDeleteForm, TClientCompanyPartnershipUserPhoneNumberForm, TClientCompanyPartnershipUserPhoneNumberMakePrimaryForm } from "../../../../../domain/codecs/form/ClientCompanyPartnershipForm";
import { CRMColors } from "../../../models/CRMColors";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { Background } from "../../BuildingBlocks/Background";
import { Padding } from "../../BuildingBlocks/Padding";
import { Relative } from "../../BuildingBlocks/Relative";
import { SpacingRow } from "../../BuildingBlocks/SpacingRow";
import { CRMBottomControlBar } from "../../CRMBottomControlBar/CRMBottomControlBar";
import { CRMButtonPrimary } from "../../CRMButtonPrimary/CRMButtonPrimary";
import { CRMFormButton } from "../../CRMFormButton/CRMFormButton";
import { CRMPopoutHeader } from "../../Simple/CRMPopoutHeader/CRMPopoutHeader";
import { CRMTidyPopout } from "../../Simple/CRMTidyPopout/CRMTidyPopout";
import { CRMCompanyPartnerForm } from "../CRMCompanyPartnerForm/CRMCompanyPartnerForm";

export const CRMCompanyPartnerEditPopout = (props: React.PropsWithChildren<{
    form: TClientCompanyPartnershipForm;
    onChange: (form: TClientCompanyPartnershipForm) => void;
    onSave: (form: TClientCompanyPartnershipForm) => void;
    onAddressAutocomplete: (form: TClientCompanyPartnershipForm) => void;
    onUploadContract: (form: TClientCompanyPartnershipContractUploadForm) => void;
    onDeleteContract: (form: TClientCompanyPartnershipContractForm) => void;
    onChangeUser: (form: TClientCompanyPartnershipUserForm) => void;
    onSaveUser: (form: TClientCompanyPartnershipUserForm) => void;
    onDeleteUser: (form: TClientCompanyPartnershipUserForm) => void;
    onUserPhoneNumberChange: (form: TClientCompanyPartnershipUserPhoneNumberForm) => void;
    onUserNewPhoneNumber: (form: TClientCompanyPartnershipUserPhoneNumberCreateForm) => void;
    onUserPhoneNumberMakePrimary: (form: TClientCompanyPartnershipUserPhoneNumberMakePrimaryForm) => void;
    onUserPhoneNumberDelete: (form: TClientCompanyPartnershipUserPhoneNumberDeleteForm) => void;
    onClose: () => void;
}>): JSX.Element => {

    return (
        <Background color={CRMColors.NEUTRAL_PAPER}>
            <Relative>
                <CRMPopoutHeader
                    icon="arrow-left"
                    onClick={props.onClose}
                >
                    Editing {props.form.edited.name || "Company"}
                </CRMPopoutHeader>

                <Padding spacing={CRMSpacing.LARGE}>
                    <CRMCompanyPartnerForm
                        form={props.form}
                        onChange={props.onChange}
                        onAddressAutocomplete={props.onAddressAutocomplete}
                        onUploadContract={props.onUploadContract}
                        onDeleteContract={props.onDeleteContract}
                        onChangeUser={props.onChangeUser}
                        onSaveUser={props.onSaveUser}
                        onDeleteUser={props.onDeleteUser}
                        onUserPhoneNumberChange={props.onUserPhoneNumberChange}
                        onUserNewPhoneNumber={props.onUserNewPhoneNumber}
                        onUserPhoneNumberMakePrimary={props.onUserPhoneNumberMakePrimary}
                        onUserPhoneNumberDelete={props.onUserPhoneNumberDelete}
                    />
                </Padding>

                <CRMBottomControlBar edge="border">
                    <Padding 
                        type="custom"
                        width="100%" 
                        spacing={`${CRMSpacing.TINY} ${CRMSpacing.MEDIUM}`}
                    >
                        <SpacingRow justifyContent="end">
                            <CRMFormButton
                                formStatus={props.form.status}
                                ButtonElement={CRMButtonPrimary}
                                label="Save"
                                onClick={() => props.onSave(props.form)}
                            />
                        </SpacingRow>
                    </Padding>
                </CRMBottomControlBar>
            </Relative>
        </Background>
    );
};
