import { tap, switchMap, map } from "rxjs/operators";
import { formOperation } from "../../wrappers/formOperation";
import { TActionsDefinitionsList } from "./TAction";
import { action, routeAction } from "./actionFunctions";
import { 
    TriageEmailAssignToCaseSearchForm, 
    TriageForm, 
    TTriageEmailAssignToCaseAndUserForm, 
    TTriageEmailAssignToCaseForm, 
    TTriageEmailAssignToCaseSearchForm, 
    TTriageEmailAssignToUserOnlyForm, 
    TTriageEmailMarkAsIrrelevantForm, 
    TTriageFormCodec, 
    TTriageSimpleEmailForm, 
    TTriageSimpleEmailFormCodec, 
    TTriageFormChildrenCodec, 
    TTriageFormChildren, 
    TTriageBulkEmailMarkAsIrrelevantForm, 
    TTriageEmailMarkAsResolvedForm
} from "../../../../domain/codecs/form/TriageForm";
import { array as extArray } from "../../../../shared/src/utilByDomainGroupExport";
import { forkJoin, Observable, of } from "rxjs";
import { pipe } from "fp-ts/lib/pipeable";
import { TStateCodec } from "../../../../domain/codecs/state";
import { TCodecSetState, TGetCodecState } from "../applyActions";
import { foldFormOnSuccess } from "../../functions/form/foldFormOnSuccess";
import { resetFormChildren } from "../../functions/form/resetFormChildren";
import { TCodecLens, TCodecLensUtils } from "../../../../shared/src/codecs/codecLens";
import { TArrayCodec } from "../../../../shared/src/codecs/types/array";
import { requireExhaustive } from "../../../../shared/src/util";
import { array } from "fp-ts";
import { isAFunction } from "../../functions/functions";

export const createTriageOpenEmail$ = (
    obs$: Observable<TTriageSimpleEmailForm>,
    lens: TCodecLens<TTriageFormCodec, TStateCodec>,
    setState: TCodecSetState,
    getState: TGetCodecState,
) =>
    obs$.pipe(
        tap((emailForm) => {
            setState(
                lens.children.emails
                .where((whereEmail) => whereEmail.original.id === emailForm.original.id)
                .children.detailed_email_form.status
                .set("loading")
            )
        }),
        switchMap((emailForm) => formOperation("GetDetailedEmail", emailForm.children.detailed_email_form)),
        tap((response) => {
            setState(
                lens.children.emails
                .where((whereEmail) => whereEmail.original.id === response.original.id)
                .children.detailed_email_form
                .set(response)
            )
        }),
        tap(() => {
            const currentCount = lens.children.counts.available_count.get()(getState());
            const availableCount = currentCount < 1 ? 0 : currentCount;
            setState(lens.children.counts.available_count.set(availableCount))
        })
    );

type TEmailsMapFunction = (emails: Array<TTriageSimpleEmailForm>) => Array<TTriageSimpleEmailForm>;

export const createTriageLoadMore$ = (
    obs$: Observable<undefined>,
    lens: TCodecLens<TTriageFormCodec, TStateCodec>,
    formOperationName:
        "GetTriageUnassignedSimpleEmails"
        | "GetTriageExpiredSimpleEmails"
        | "GetAssigneeUnresolvedEmails"
        | "GetAssigneeResolvedEmails"
        | "GetConfidentialEmails"
        | "GetCaseInboxEmails"
        | "GetUserUntriagedEmails",
    setState: TCodecSetState,
    getState: TGetCodecState,
    limit?: number,
    emailsMap?: TEmailsMapFunction,
) =>
    obs$.pipe(
        tap(() => {
            const triageForm = lens.get()(getState());
            const newOffset = triageForm.children.emails.length;
            setState(lens.set({
                ...triageForm,
                status: "submitting",
                edited: {
                    ...triageForm.edited,
                    offset: newOffset,
                    limit: limit || 10,
                }
            }));
        }),
        map(() => lens.get()(getState())),
        map(resetFormChildren(TriageForm)),
        switchMap((form) => formOperation(formOperationName, form)),
        tap((response) => {
            const triageForm = lens.get()(getState());
            const newEmails = isAFunction<TEmailsMapFunction>(emailsMap) ? 
                emailsMap(response.children.emails)
                : response.children.emails
            ;

            setState(lens.set({
                ...response,
                children: {
                    ...triageForm.children,
                    emails: triageForm.children.emails.concat(newEmails),
                }
            }));
        }),
    );

export const createTriageSearchCase$ = (
    obs$: Observable<TTriageEmailAssignToCaseSearchForm>,
    lens: TCodecLens<TArrayCodec<TTriageSimpleEmailFormCodec>, TStateCodec>,
    setState: TCodecSetState,
) =>
    obs$.pipe(
        tap((form) =>
            setState(
                lens
                    .where((email) => email.original.id === form.original.email_id)
                    .children.detailed_email_form.children.assign_to_case_search_form.status
                    .set("submitting")
            )
        ),
        map(resetFormChildren(TriageEmailAssignToCaseSearchForm)),
        switchMap((form) => formOperation("GetSearchForTriageEmails", form)),
        tap((response) => 
            setState(
                lens
                    .where((email) => email.original.id === response.original.email_id)
                    .children.detailed_email_form.children.assign_to_case_search_form
                    .set(response)
            )
        ),
    );

export const createTriageAssignToCaseAndMe$ = (
    obs$: Observable<TTriageEmailAssignToCaseAndUserForm>,
    lens: TCodecLens<TTriageFormCodec, TStateCodec>,
    setState: TCodecSetState,
    getState: TGetCodecState,
) =>
    obs$.pipe(
        switchMap((form) => formOperation("AssignTriageEmail", form)),
        tap(foldFormOnSuccess(
            (response) => setState(
                lens.children.emails
                    .where((email) => email.original.id === response.original.email_id)
                    .children.detailed_email_form.children.assign_to_case_search_form.children.results
                    .where((form) => form.id === response.edited.case_id)
                    .assign_to_me_form
                    .set(response)
            ),
            deleteEmailFromList(lens.children, setState, getState),
        )),
    );

export const createTriageAssignToCaseAndStaff$ = (
    obs$: Observable<TTriageEmailAssignToCaseAndUserForm>,
    lens: TCodecLens<TTriageFormCodec, TStateCodec>,
    setState: TCodecSetState,
    getState: TGetCodecState,
) =>
    obs$.pipe(
        switchMap((form) => formOperation("AssignTriageEmail", form)),
        tap(foldFormOnSuccess(
            (response) => setState(
                lens.children.emails
                    .where((email) => email.original.id === response.original.email_id)
                    .children.detailed_email_form.children.assign_to_case_search_form.children.results
                    .where((form) => form.id === response.edited.case_id)
                    .assign_to_other_staff_form
                    .where((form) => form.edited.user_id === response.edited.user_id)
                    .set(response)
            ),
            deleteEmailFromList(lens.children, setState, getState),
        )),
    );

export const createTriageAssignToCaseAndHandler$ = (
    obs$: Observable<TTriageEmailAssignToCaseAndUserForm>,
    lens: TCodecLens<TTriageFormCodec, TStateCodec>,
    setState: TCodecSetState,
    getState: TGetCodecState,
) =>
    obs$.pipe(
        switchMap((form) => formOperation("AssignTriageEmail", form)),
        tap(foldFormOnSuccess(
            (response) => setState(
                lens.children.emails
                    .where((email) => email.original.id === response.original.email_id)
                    .children.detailed_email_form.children.assign_to_case_search_form.children.results
                    .where((form) => form.id === response.edited.case_id)
                    .assign_to_case_handlers_form
                    .where((form) => form.edited.user_id === response.edited.user_id)
                    .set(response)
            ),
            deleteEmailFromList(lens.children, setState, getState),
        )),
    );

export const createTriageAssignToCaseAndResolve$ = (
    obs$: Observable<TTriageEmailAssignToCaseForm>,
    lens: TCodecLens<TTriageFormCodec, TStateCodec>,
    setState: TCodecSetState,
    getState: TGetCodecState,
) =>
    obs$.pipe(
        switchMap((form) => formOperation("AssignAndResolveTriageEmail", form)),
        tap(foldFormOnSuccess(
            (response) => setState(
                lens.children.emails
                    .where((email) => email.original.id === response.original.email_id)
                    .children.detailed_email_form.children.assign_to_case_search_form.children.results
                    .where((form) => form.id === response.edited.case_id)
                    .resolve_form
                    .set(response)
            ),
            deleteEmailFromList(lens.children, setState, getState),
        )),
    );

export const createTriageBulkArchive$ = (
    obs$: Observable<TTriageBulkEmailMarkAsIrrelevantForm>,
    lens: TCodecLens<TTriageFormCodec, TStateCodec>,
    setState: TCodecSetState,
    getState: TGetCodecState,
) =>
    obs$.pipe(
        switchMap((form) => formOperation("SetTriageBulkEmailsAsIrrelevant", form)),
        tap(foldFormOnSuccess(
            (response) => setState(lens.children.bulk_mark_as_irrelevant_form.set(response)),
            deleteEmailsFromList(lens.children, setState, getState),
        )),
    );

export const createTriageArchive$ = (
    obs$: Observable<TTriageEmailMarkAsIrrelevantForm>,
    lens: TCodecLens<TTriageFormCodec, TStateCodec>,
    setState: TCodecSetState,
    getState: TGetCodecState,
) =>
    obs$.pipe(
        switchMap((form) => formOperation("SetTriageEmailAsIrrelevant", form)),
        tap(foldFormOnSuccess(
            (response) => setState(
                lens.children.emails
                    .where((email) => email.original.id === response.original.email_id)
                    .children.detailed_email_form.children.mark_as_irrelevant_form
                    .set(response)
            ),
            deleteEmailFromList(lens.children, setState, getState),
        )),
    );

export const createTriageResolve$ = (
    obs$: Observable<TTriageEmailMarkAsResolvedForm>,
    lens: TCodecLens<TTriageFormCodec, TStateCodec>,
    setState: TCodecSetState,
    getState: TGetCodecState,
) =>
    obs$.pipe(
        switchMap((form) => formOperation("SetTriageEmailAsResolved", form)),
        tap(foldFormOnSuccess(
            (response) => setState(
                lens.children.emails
                    .where((email) => email.original.id === response.original.email_id)
                    .children.detailed_email_form.children.mark_as_resolved_form
                    .set(response)
            ),
            deleteEmailFromList(lens.children, setState, getState),
        )),
    );

export const createTriageAssignToPerson$ = (
    obs$: Observable<TTriageEmailAssignToUserOnlyForm>,
    lens: TCodecLens<TTriageFormCodec, TStateCodec>,
    setState: TCodecSetState,
    getState: TGetCodecState,
) =>
    obs$.pipe(
        switchMap((form) => formOperation("AssignTriageEmailToUserOnly", form)),
        tap(foldFormOnSuccess(
            (response) => setState(
                lens.children.emails
                    .where((email) => email.original.id === response.original.email_id)
                    .children.detailed_email_form.children.assign_to_user_only_forms
                    .where((form) => form.original.user_id === response.original.user_id)
                    .set(response)
            ),
            deleteEmailFromList(lens.children, setState, getState),
        )),
    );

export const triageReload$ = (
    lens: TCodecLens<TTriageFormCodec, TStateCodec>,
    formOperationName:
        "GetTriageUnassignedSimpleEmails"
        | "GetTriageExpiredSimpleEmails"
        | "GetAssigneeUnresolvedEmails"
        | "GetAssigneeResolvedEmails"
        | "GetConfidentialEmails"
        | "GetUserUntriagedEmails",
    setState: TCodecSetState,
    getState: TGetCodecState,
) =>
    of(setState(lens.status.set("submitting")))
        .pipe(
            // We'll reset the offset and set the limit to the number of emails we got before,
            // this is important so that when a user returns to this section they see the same emails they saw last time
            tap(() => (setState(lens.edited.set({
                offset: 0,
                limit: lens.children.emails.get()(getState()).length < 10 ? 10
                    : lens.children.emails.get()(getState()).length,
            })))),
            map(() => lens.get()(getState())),
            map(resetFormChildren(TriageForm)),
            switchMap((form) => formOperation(formOperationName, form)),
            tap((response) => setState(lens.set(response))),
        );

export const actions: TActionsDefinitionsList = [
    routeAction("VIEW_CRM_TRIAGE", (obs$, lens, set, get) => {
        obs$.pipe(
            tap(() => set(lens.triage_page.set({
                expired: {
                    ...TriageForm.newDefault(),
                    edited: {
                        offset: 0,
                        limit: 10,
                    },
                },
                unassigned: {
                    ...TriageForm.newDefault(),
                    edited: {
                        offset: 0,
                        limit: 10,
                    },
                },
                active_type_visible: "UNASSIGNED",
            }))),
            switchMap(() =>
                forkJoin([
                    formOperation("GetTriageUnassignedSimpleEmails", lens.triage_page.unassigned.get()(get())),
                    formOperation("GetTriageExpiredSimpleEmails", lens.triage_page.expired.get()(get()))
                ])
            ),
            tap(([unassigned, expired]) => set(lens.triage_page.set({
                unassigned,
                expired,
                active_type_visible: "UNASSIGNED",
            }))),
        ).subscribe();
    }),

    // ACTIVE TYPE VISIBLE CHANGE
    action("TRIAGE_ACTIVE_TYPE_VISIBLE_CHANGE", (obs$, lens, setState, getState) => {
        obs$.pipe(
            tap((activeType) => setState(lens.triage_page.active_type_visible.set(activeType))),
            switchMap((activeType) =>
                activeType === "UNASSIGNED" ? triageReload$(lens.triage_page.unassigned, "GetTriageUnassignedSimpleEmails", setState, getState)
                : activeType === "EXPIRED" ? triageReload$(lens.triage_page.expired, "GetTriageExpiredSimpleEmails", setState, getState)
                : requireExhaustive(activeType),
            ),
        ).subscribe();
    }),

    // LOAD MORE
    action("TRIAGE_UNASSIGNED_LOAD_MORE", (obs$, lens, setState, getState) => {
        createTriageLoadMore$(obs$, lens.triage_page.unassigned, "GetTriageUnassignedSimpleEmails", setState, getState).subscribe();
    }),
    action("TRIAGE_EXPIRED_LOAD_MORE", (obs$, lens, setState, getState) => {
        createTriageLoadMore$(obs$, lens.triage_page.expired, "GetTriageExpiredSimpleEmails", setState, getState).subscribe();
    }),

    // SEARCH CASE
    action("TRIAGE_UNASSIGNED_SEARCH_CASE", (obs$, lens, setState) => {
        createTriageSearchCase$(obs$, lens.triage_page.unassigned.children.emails, setState).subscribe();
    }),
    action("TRIAGE_EXPIRED_SEARCH_CASE", (obs$, lens, setState) => {
        createTriageSearchCase$(obs$, lens.triage_page.expired.children.emails, setState).subscribe();
    }),

    // ARCHIVE
    action("TRIAGE_UNASSIGNED_ARCHIVE", (obs$, lens, setState, getState) => {
        createTriageArchive$(obs$, lens.triage_page.unassigned, setState, getState).subscribe();
    }),

    // RESOLVE EMAIL
    action("TRIAGE_EXPIRED_RESOLVE", (obs$, lens, setState, getState) => {
        createTriageResolve$(obs$, lens.triage_page.expired, setState, getState).subscribe();
    }),
    // RESOLVE EMAIL - UPDATE CHASE MODE FORM
    action("TRIAGE_EXPIRED_RESOLVE_FORM_UPDATE_CHASE_MODE_FORM", (obs$: Observable<TTriageEmailMarkAsResolvedForm>, lens, setState, getState) => {
        obs$.pipe(
            tap((payload) => setState(lens.triage_page.expired.children.emails
                .where((f) => f.edited.id === payload.edited.email_id)
                .children.detailed_email_form.children.mark_as_resolved_form
                .set({
                    ...payload,
                    status: "requiresSubmission",
                }),
            ))
        ).subscribe()
    }),

    // BULK ARCHIVE
    action("TRIAGE_UNASSIGNED_BULK_ARCHIVE", (obs$, lens, setState, getState) => {
        createTriageBulkArchive$(obs$, lens.triage_page.unassigned, setState, getState).subscribe();
    }),
    action("TRIAGE_EXPIRED_BULK_ARCHIVE", (obs$, lens, setState, getState) => {
        createTriageBulkArchive$(obs$, lens.triage_page.expired, setState, getState).subscribe();
    }),
    
    // OPEN EMAIL
    action("TRIAGE_UNASSIGNED_OPEN_EMAIL", (obs$, lens, setState, getState) => {
        createTriageOpenEmail$(obs$, lens.triage_page.unassigned, setState, getState).subscribe();
    }),
    action("TRIAGE_EXPIRED_OPEN_EMAIL", (obs$, lens, setState, getState) => {
        createTriageOpenEmail$(obs$, lens.triage_page.expired, setState, getState).subscribe();
    }),

    // ASSIGN TO PERSON
    action("TRIAGE_UNASSIGNED_ASSIGN_TO_PERSON", (obs$, lens, setState, getState) => {
        createTriageAssignToPerson$(obs$, lens.triage_page.unassigned, setState, getState).subscribe();
    }),
    action("TRIAGE_EXPIRED_ASSIGN_TO_PERSON", (obs$, lens, setState, getState) => {
        createTriageAssignToPerson$(obs$, lens.triage_page.expired, setState, getState).subscribe();
    }),

    // ASSIGN TO CASE AND ME
    action("TRIAGE_UNASSIGNED_ASSIGN_TO_CASE_AND_ME", (obs$, lens, setState, getState) => {
        createTriageAssignToCaseAndMe$(obs$, lens.triage_page.unassigned, setState, getState).subscribe();
    }),
    action("TRIAGE_EXPIRED_ASSIGN_TO_CASE_AND_ME", (obs$, lens, setState, getState) => {
        createTriageAssignToCaseAndMe$(obs$, lens.triage_page.expired, setState, getState).subscribe();
    }),

    // ASSIGN TO CASE AND STAFF
    action("TRIAGE_UNASSIGNED_ASSIGN_TO_CASE_AND_STAFF", (obs$, lens, setState, getState) => {
        createTriageAssignToCaseAndStaff$(obs$, lens.triage_page.unassigned, setState, getState).subscribe();
    }),
    action("TRIAGE_EXPIRED_ASSIGN_TO_CASE_AND_STAFF", (obs$, lens, setState, getState) => {
        createTriageAssignToCaseAndStaff$(obs$, lens.triage_page.expired, setState, getState).subscribe();
    }),

    // ASSIGN TO CASE AND HANDLER
    action("TRIAGE_UNASSIGNED_ASSIGN_TO_CASE_AND_HANDLER", (obs$, lens, setState, getState) => {
        createTriageAssignToCaseAndHandler$(obs$, lens.triage_page.unassigned, setState, getState).subscribe();
    }),
    action("TRIAGE_EXPIRED_ASSIGN_TO_CASE_AND_HANDLER", (obs$, lens, setState, getState) => {
        createTriageAssignToCaseAndHandler$(obs$, lens.triage_page.expired, setState, getState).subscribe();
    }),

    // ASSIGN TO CASE AND RESOLVE
    action("TRIAGE_UNASSIGNED_ASSIGN_TO_CASE_AND_RESOLVE", (obs$, lens, setState, getState) => {
        createTriageAssignToCaseAndResolve$(obs$, lens.triage_page.unassigned, setState, getState).subscribe();
    }),
    action("TRIAGE_EXPIRED_ASSIGN_TO_CASE_AND_RESOLVE", (obs$, lens, setState, getState) => {
        createTriageAssignToCaseAndResolve$(obs$, lens.triage_page.expired, setState, getState).subscribe();
    }),
];


type TFormsWhichPromptADelete = 
    TTriageEmailMarkAsIrrelevantForm 
    | TTriageEmailAssignToCaseAndUserForm
    | TTriageEmailAssignToCaseForm
    | TTriageEmailAssignToUserOnlyForm
;

type TFormsWhichPromptABulkDelete = 
    TTriageBulkEmailMarkAsIrrelevantForm
;

export const deleteEmailFromList = <T extends TFormsWhichPromptADelete>(
    lens: TCodecLensUtils<TTriageFormChildrenCodec, TStateCodec>,
    setState: TCodecSetState,
    getState: TGetCodecState
) => 
    (form: T): void =>
        setState(
            lens.set(
                getPrunedTriageChildrenEmails(
                    lens.get()(getState()),
                    [form.original.email_id]
                )
            )
        );

const deleteEmailsFromList = <T extends TFormsWhichPromptABulkDelete>(
    lens: TCodecLensUtils<TTriageFormChildrenCodec, TStateCodec>,
    setState: TCodecSetState,
    getState: TGetCodecState
) => 
    (form: T): void =>
        setState(
            lens.set(
                getPrunedTriageChildrenEmails(
                    lens.get()(getState()),
                    form.edited.ids
                )
            )
        );

const getPrunedTriageChildrenEmails = (form: TTriageFormChildren, idsOfEmailsToPrune: Array<string>): TTriageFormChildren => {
    
    const prunedEmails = pipe(
        form.emails,
        array.filter((email) => !extArray.contains(email.original.id)(idsOfEmailsToPrune)),
    );
    const numberOfEmailsDeleted = form.emails.length - prunedEmails.length;

    return {
        ...form,
        emails: prunedEmails,
        counts: {
            ...form.counts,
            available_count: form.counts.available_count - numberOfEmailsDeleted
        }
    };
}
