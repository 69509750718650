import React from "react";
import { TEmailCompositionForm } from "../../../../../../domain/codecs/form/EmailCompositionForm";
import { CRMColors } from "../../../../models/CRMColors";
import { CRMSpacing } from "../../../../models/CRMSpacing";
import { Padding } from "../../../BuildingBlocks/Padding";
import { SpacingRow } from "../../../BuildingBlocks/SpacingRow";
import { CRMButtonFlatColourIcon } from "../../../CRMButtonFlatColourIcon/CRMButtonFlatColourIcon";
import { isEmailViewType, TEmailViewStatus } from "../CRMComposeEmailForm/CRMComposeEmailFormProps";
import { Background } from "../../../BuildingBlocks/Background";

export type TEmailCloseMode = "clear-only" | "close-and-clear";

type TComposeEmailControlsProps = {
    viewStatus: TEmailViewStatus;
    form: TEmailCompositionForm;
    closeMode: TEmailCloseMode;
    isACaseSelected: boolean;
    isSubmittable: boolean;

    onSend: () => void;
    onClose?: () => void;
    onClear?: () => void;
    onClickAttachment: () => void;
    onClickEmailTemplates: () => void;
};

export const CRMComposeEmailControls = (props: React.PropsWithChildren<TComposeEmailControlsProps>): JSX.Element => {

    return (
        <Background
            padding={`${CRMSpacing.TINY} ${CRMSpacing.MEDIUM}`}
            width="100%"
        >
            <SpacingRow
                spacing={CRMSpacing.SMALL}
                alignItems="center"
                childSize={"1fr"}
                justifyContent="space-between"
            >
                {/* COMPOSE EMAIL CONTROLS */}
                <SpacingRow justifyContent="space-between">

                    {/* CLOSE POPUP */}
                    <SpacingRow spacing={CRMSpacing.SMALL}>
                        {props.closeMode === "close-and-clear" &&
                            <CRMButtonFlatColourIcon
                                backgroundColor={CRMColors.NEUTRAL_PAPER}
                                icon={"arrow-left"}
                                iconColor={CRMColors.NEUTRAL_INK}
                                onClick={props.onClose}
                                disabled={props.form.status === "submitting"}
                                title="Back"
                            />
                        }
                        
                        <CRMButtonFlatColourIcon
                            backgroundColor={CRMColors.NEUTRAL_PAPER}
                            icon={"delete-all"}
                            iconColor={CRMColors.NEUTRAL_INK}
                            onClick={props.onClear}
                            disabled={props.form.status === "submitting"}
                            title="Clear All"
                        />
                    </SpacingRow>

                    <SpacingRow spacing={CRMSpacing.SMALL}>
                        {/* USE TEMPLATE */}
                        <CRMButtonFlatColourIcon
                            backgroundColor={CRMColors.PRIMARY_12}
                            iconColor={CRMColors.NEUTRAL_INK}
                            icon="chat-points"
                            onClick={props.onClickEmailTemplates}
                            title="Choose email template"
                        />

                        {/* ATTACH */}
                        {isEmailViewType("case", props.viewStatus) &&
                            <CRMButtonFlatColourIcon
                                backgroundColor={CRMColors.PRIMARY_12}
                                iconColor={CRMColors.NEUTRAL_2}
                                icon="attach"
                                onClick={props.onClickAttachment}
                                disabled={!props.isACaseSelected}
                                title="Select attachments"
                            />
                        }
                        
                        {/* SEND */}
                        <CRMButtonFlatColourIcon
                            backgroundColor={CRMColors.HIGHLIGHTS_POSITIVE_GREEN_0}
                            iconColor={CRMColors.NEUTRAL_12}
                            icon="send-email"
                            isRounded={true}
                            onClick={props.onSend}
                            disabled={!props.isSubmittable}
                            title="Send"
                        />
                    </SpacingRow>
                </SpacingRow>
            </SpacingRow>
        </Background>
    );
};
