import React from "react";
import { THexColor, TPercentageSize, TRGBAColour } from "../../models/StringLiterals";

export const BackgroundLinearGradient: React.FunctionComponent<React.PropsWithChildren<{
    leftColour: THexColor | TRGBAColour;
    leftColourStopsAt: TPercentageSize;
    rightColour: THexColor | TRGBAColour;
}>> = (props): JSX.Element => {
    return (
        <div
            style={{
                background: `linear-gradient(90deg, ${props.leftColour}, ${props.rightColour} ${props.leftColourStopsAt})`
            }}
        >
            {props.children}
        </div>
    );
};
