import React from "react";
import { TCaseDualRepSearchResultsForm } from "../../../../../domain/codecs/form/CaseForm";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { Background } from "../../BuildingBlocks/Background";
import { SpacingColumn } from "../../BuildingBlocks/SpacingColumn";
import { SpacingRow } from "../../BuildingBlocks/SpacingRow";
import { CRMButtonPrimary } from "../../CRMButtonPrimary/CRMButtonPrimary";
import { CRMCardInside } from "../../CRMCardInside/CRMCardInside";
import { CRMFormButton } from "../../CRMFormButton/CRMFormButton";
import { CRMParagraph } from "../../Simple/CRMParagraph/CRMParagraph";
import { WeightBold } from "../../WeightBold/WeightBold";
import { WeightMedium } from "../../WeightMedium/WeightMedium";

export const CRMCaseDualRepSuggestion = (props: React.PropsWithChildren<{
    suggestionForm: TCaseDualRepSearchResultsForm,
    onLinkToSuggestion: (form: TCaseDualRepSearchResultsForm) => void;
}>): JSX.Element =>
    <CRMCardInside>
        <Background padding={CRMSpacing.MEDIUM}>
            <SpacingRow
                justifyContent="space-between"
                spacing={CRMSpacing.MEDIUM}
            >
                {/* KLYANT MATTER ID & ADDRESS */}
                <SpacingColumn spacing={CRMSpacing.TINY}>
                    {props.suggestionForm.children.klyant_matter_id && <CRMParagraph><WeightBold>{props.suggestionForm.children.klyant_matter_id}</WeightBold></CRMParagraph>}
                    
                    {!props.suggestionForm.children.klyant_matter_id && <CRMParagraph><WeightMedium>Matter ID not set.</WeightMedium></CRMParagraph>}

                    <CRMParagraph><WeightMedium>{props.suggestionForm.children.addresses}</WeightMedium></CRMParagraph>
                </SpacingColumn>

                {/* CONTROL */}
                <CRMFormButton
                    formStatus={
                        props.suggestionForm.status === "untouched"
                            ? "requiresSubmission"
                            : props.suggestionForm.status
                    }
                    ButtonElement={CRMButtonPrimary}
                    label="Link"
                    onClick={() => props.onLinkToSuggestion(props.suggestionForm)}
                />
            </SpacingRow>
        </Background>
    </CRMCardInside>
