import { array } from "fp-ts";
import { pipe } from "fp-ts/lib/function";
import React from "react";
import { TCasesRiskAssessment2, casesRiskAssessmentGradeOptions, clientVulnerabilityStatusOptions } from "../../../../domain/codecs/CasesRiskAssessment";
import { TCaseNewRiskAssessmentForm } from "../../../../domain/codecs/form/CaseForm";
import { useOpenClose } from "../../hooks/UseOpenClose";
import { CRMColors } from "../../models/CRMColors";
import { CRMSpacing } from "../../models/CRMSpacing";
import { castNewFormStatusToLegacy } from "../../util";
import { HorizontalLine } from "../BuildingBlocks/HorizontalLine";
import { SpacingColumn } from "../BuildingBlocks/SpacingColumn";
import { CRMButtonTertiary } from "../CRMButtonTertiary/CRMButtonTertiary";
import { CRMDropdownComponent } from "../CRMDropdownComponent/CRMDropdownComponent";
import { CRMFormButton } from "../CRMFormButton/CRMFormButton";
import CRMInputLabelAndErrorWrapComponent from "../CRMInputLabelAndErrorWrapComponent/CRMInputLabelAndErrorWrapComponent";
import { CRMNoticeBoxComponent } from "../CRMNoticeBoxComponent/CRMNoticeBoxComponent";
import CRMTextAreaComponent from "../CRMTextAreaComponent/CRMTextAreaComponent";
import { CRMTitleSubSection } from "../CRMTitleSubSection/CRMTitleSubSection";
import { CRMSeeMore } from "../Simple/CRMSeeMore/CRMSeeMore";
import { CRMRiskManagementAssessment } from "../CRMRiskManagementAssessment/CRMRiskManagementAssessment";

export const CRMRiskManagement = React.memo((props: {
    assessments: Array<TCasesRiskAssessment2>,
    newForm: TCaseNewRiskAssessmentForm,
    newAssessmentRequired: boolean,
    onNewFormChange: (value: TCaseNewRiskAssessmentForm) => void,
    onNewSubmit: () => void,
}): JSX.Element => {
    const {
        isOpen,
        toggleOpen,
    } = useOpenClose({
        defaultValue: false,
    });

    const hasMoreThan2Assessments = () =>
        props.assessments.length > 2;

    const getAssessmentsToShow = () =>
        hasMoreThan2Assessments() && !isOpen
            ? pipe(
                props.assessments,
                array.takeLeft(2),
            )
            : props.assessments;

    const newAssessmentFormCanBeSubmitted = () =>
        props.newForm.edited.grade !== "unknown"
        && props.newForm.edited.explanation !== ""
        && props.newForm.edited.are_any_of_the_clients_vulnerable !== "unknown"
        && (
            props.newForm.edited.are_any_of_the_clients_vulnerable === "no"
            || props.newForm.edited.vulnerability_explanation !== ""
        );

    return (
        <SpacingColumn spacing={CRMSpacing.LARGE}>
            {/* New assessment required notice */}
            {props.newAssessmentRequired && <CRMNoticeBoxComponent>
                Based on the milestones of this case a reassessment needs to be made.
            </CRMNoticeBoxComponent>}

            {/* Assessments */}
            {props.assessments.length > 0 && <SpacingColumn spacing={CRMSpacing.MEDIUM}>
                {pipe(
                    getAssessmentsToShow(),
                    array.map((assessment) =>
                        <CRMRiskManagementAssessment
                            key={assessment.id}
                            assessment={assessment}
                        />
                    ),
                )}

                {/* SEE MORE */}
                {hasMoreThan2Assessments() && <CRMSeeMore
                    seeMoreLabel={`See more (${props.assessments.length - 2})`}
                    isExpanded={isOpen}
                    onClick={toggleOpen}
                />}

                <HorizontalLine color={CRMColors.NEUTRAL_10} />
            </SpacingColumn>}

            {/* New assessment form */}
            <CRMTitleSubSection>New Assessment</CRMTitleSubSection>
            <SpacingColumn spacing={CRMSpacing.MEDIUM}>
                {/* Set grade */}
                <CRMInputLabelAndErrorWrapComponent label="Grade:">
                    <CRMDropdownComponent
                        value={props.newForm.edited.grade}
                        options={casesRiskAssessmentGradeOptions}
                        onChange={(grade) =>
                            props.onNewFormChange({
                                ...props.newForm,
                                edited: {
                                    ...props.newForm.edited,
                                    grade,
                                },
                                status: "requiresSubmission",
                            })
                        }
                        displayError={false}
                    />
                </CRMInputLabelAndErrorWrapComponent>

                {/* Set explanation */}
                <CRMInputLabelAndErrorWrapComponent label="Explanation for grade:">
                    <CRMTextAreaComponent
                        value={props.newForm.edited.explanation}
                        onChange={(explanation) =>
                            props.onNewFormChange({
                                ...props.newForm,
                                edited: {
                                    ...props.newForm.edited,
                                    explanation,
                                },
                                status: "requiresSubmission",
                            })
                        }
                    />
                </CRMInputLabelAndErrorWrapComponent>

                {/* Set grade */}
                <CRMInputLabelAndErrorWrapComponent label="Are any of the clients vulnerable?">
                    <CRMDropdownComponent
                        value={props.newForm.edited.are_any_of_the_clients_vulnerable}
                        options={clientVulnerabilityStatusOptions}
                        onChange={(are_any_of_the_clients_vulnerable) =>
                            props.onNewFormChange({
                                ...props.newForm,
                                edited: {
                                    ...props.newForm.edited,
                                    are_any_of_the_clients_vulnerable,
                                },
                                status: "requiresSubmission",
                            })
                        }
                        displayError={false}
                    />
                </CRMInputLabelAndErrorWrapComponent>

                {/* Set explanation */}
                {
                    props.newForm.edited.are_any_of_the_clients_vulnerable === "yes"
                    && <CRMInputLabelAndErrorWrapComponent label="Explanation of client vulnerability">
                        <CRMTextAreaComponent
                            value={props.newForm.edited.vulnerability_explanation}
                            onChange={(vulnerability_explanation) =>
                                props.onNewFormChange({
                                    ...props.newForm,
                                    edited: {
                                        ...props.newForm.edited,
                                        vulnerability_explanation,
                                    },
                                    status: "requiresSubmission",
                                })
                            }
                        />
                    </CRMInputLabelAndErrorWrapComponent>
                }

                {/* Submit */}
                <div>
                    <CRMFormButton
                        formStatus={castNewFormStatusToLegacy(props.newForm.status)}
                        ButtonElement={CRMButtonTertiary}
                        label="Save assessment"
                        onClick={() => props.onNewSubmit()}
                        disabled={!newAssessmentFormCanBeSubmitted()}
                        fullWidth={false}
                    />
                </div>
            </SpacingColumn>
        </SpacingColumn>
    );
});

