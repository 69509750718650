import * as t from "io-ts";
import * as util from "../../shared/src/util";

export const codec = t.type({
    type: t.literal("FILTER"),
    property: t.string,
    operator: t.union([
        t.literal("EQUALS_STRING"),
        t.literal("BEGINS_WITH"),
        t.literal("NOT_EQUALS_STRING"),
    ]),
    value: t.string,
});

export type T = t.TypeOf<typeof codec>;

export const operatorValues = util.typeValuesFromCodec(codec.props.operator.types as Array<t.LiteralC<T["operator"]>>);
